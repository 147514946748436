// @flow
import { connect } from "react-redux";
import { getComment } from "core/selectors/comments";
import { getProject } from "core/selectors/projects";
import { getUser } from "core/selectors/users";
import type { Dispatch, State } from "core/types";
import type { Props, OwnProps, StateProps } from "./types";

function mapStateToProps(state: State, props: OwnProps<>): StateProps {
  const project = getProject(state, { projectId: props.activity.projectId });
  const user = getUser(state, { userId: props.activity.userId });

  // NOTE this type of refinement doesn't scale very well
  //      we should consider moving commentId up one level
  const comment =
    props.activity.type === "COMMENT_CREATED" && !props.narrow
      ? getComment(state, { id: props.activity.payload.commentId })
      : undefined;

  return {
    project,
    comment,
    user,
  };
}

export default connect<Props<>, OwnProps<>, StateProps, _, State, Dispatch>(
  mapStateToProps
);
