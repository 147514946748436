// @flow
import type { Membership, User } from "core/types";

export function uniqueId({
  organizationId,
  userId,
}: {
  organizationId: string,
  userId: string,
}) {
  return `${organizationId}-${userId}`;
}

export const isGuest = (membership: Membership): boolean => {
  return membership && membership.role === "guest";
};

export const isAdmin = (membership: Membership): boolean => {
  return membership && membership.role === "owner";
};

export const isContributor = (membership: Membership): boolean => {
  return membership && membership.subscriptionRole === "contributor";
};

export const createdAt = (membership: Membership, user: User): string => {
  return membership ? membership.createdAt : user.createdAt;
};
