// @flow
import * as React from "react";
import ExternalLink from "core/components/ExternalLink";
import Input from "core/components/Input";
import { LEAVE_ORGANIZATION } from "core/constants";
import type { User, Organization, Subscription } from "core/types";

type Props = {
  user: User,
  organization: Organization,
  subscriptionRole: string,
  subscription: ?Subscription,
  handleConfirm: (SyntheticInputEvent<>) => any,
};

export default function RemoveSelf(props: Props) {
  return (
    <div>
      <p>
        Once you leave <strong>{props.organization.name}</strong> in Abstract,
        you will lose access to all of its projects, files, and other supporting
        content.{" "}
        <ExternalLink href={"https://www.abstract.com/legal/privacy-policy/"}>
          Learn more about Abstract privacy and data policies...
        </ExternalLink>
      </p>
      <p>
        To regain access after you leave, contact a {props.organization.name}{" "}
        Admin.
      </p>
      <React.Fragment>
        <Input
          label={`Ready? Enter "${LEAVE_ORGANIZATION}" to proceed.`}
          onChange={props.handleConfirm}
          autoComplete="off"
          autoFocus
          placeholder="Confirm you want to leave this organization…"
        />
      </React.Fragment>
    </div>
  );
}
