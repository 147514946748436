// @flow
import classnames from "classnames";
import React, { useState } from "react";
import AnimatedFlyover from "core/components/AnimatedFlyover";
import ButtonUnstyled from "core/components/ButtonUnstyled";
import FloatingMenuItem from "core/components/FloatingMenuItem";
import Icon from "core/components/Icon";
import MenuDivider from "core/components/MenuDivider";
import Popover from "core/components/Popover";
import { BranchStatus } from "core/gitConstants";
import {
  branchStatusReadable,
  branchStatusIcon,
  branchStatusType,
} from "core/lib/branches";
import { ReviewStatuses } from "core/models/review";
import style from "./style.scss";

type Props = {
  currentStatus: string,
  reviewStatus?: string,
  className?: string,
  disabled?: boolean,
  light?: boolean,
  selected?: boolean,
  activityItem?: boolean,
  shrinkAtTablet?: boolean,
  onChangeBranchStatus?: (
    status: string,
    options?: { onError: () => void }
  ) => void,
};

export default function BranchStatusBadge({
  currentStatus,
  reviewStatus,
  className,
  light,
  selected,
  onChangeBranchStatus,
  activityItem,
  shrinkAtTablet,
  disabled,
}: Props) {
  const isStale = currentStatus === BranchStatus.STALE;
  const isDiverged = currentStatus === BranchStatus.DIVERGED;
  const [isStatusMenuOpen, toggleStatusMenuOpen] = useState(false);
  const [branchStatusInput, setBranchStatusInput] = useState(currentStatus);

  const branchStatuses = [
    BranchStatus.WIP,
    BranchStatus.FEEDBACK,
    BranchStatus.DEVELOPMENT,
    BranchStatus.PAUSED,
  ];
  const reviewStatuses = [
    ReviewStatuses.APPROVED,
    ReviewStatuses.REJECTED,
    ReviewStatuses.REQUESTED,
  ];

  React.useEffect(() => {
    setBranchStatusInput(currentStatus);
  }, [currentStatus]);

  const badgeStyle =
    branchStatusType(branchStatusInput) &&
    style[branchStatusType(branchStatusInput)];

  const icon = branchStatusIcon(branchStatusInput) && (
    <Icon
      fill="currentColor"
      type={branchStatusIcon(branchStatusInput)}
      className={style.badgeIcon}
    />
  );

  function onClickPillBadge(ev: SyntheticEvent<>) {
    if (onChangeBranchStatus) {
      toggleStatusMenuOpen(!isStatusMenuOpen);
    }
  }

  if (onChangeBranchStatus) {
    const reviewItem = reviewStatus ? (
      <FloatingMenuItem
        onClick={() => {
          onChangeBranchStatus(BranchStatus.REVIEW, {
            onError: () => setBranchStatusInput(currentStatus),
          });
          toggleStatusMenuOpen(false);
        }}
        checkbox
        checked={reviewStatuses.includes(branchStatusInput)}
      >
        <div className={style.menuItem}>
          <Icon type={branchStatusIcon(reviewStatus).concat("-default")} />
          <span className={style.menuText}>
            {branchStatusReadable(reviewStatus)}
          </span>
        </div>
      </FloatingMenuItem>
    ) : (
      <FloatingMenuItem
        onClick={() => {
          onChangeBranchStatus(BranchStatus.REVIEW, {
            onError: () => setBranchStatusInput(currentStatus),
          });
          toggleStatusMenuOpen(false);
        }}
        checkbox
        checked={branchStatusInput === BranchStatus.REVIEW}
      >
        <div className={style.menuItem}>
          <Icon
            type={branchStatusIcon(BranchStatus.REVIEW).concat("-default")}
          />
          <span className={style.menuText}>Request review…</span>
        </div>
      </FloatingMenuItem>
    );

    const branchStatusMenu = onChangeBranchStatus && (
      <div>
        {branchStatuses.map((statusChoice) => (
          <FloatingMenuItem
            key={statusChoice}
            onClick={() => {
              onChangeBranchStatus(statusChoice, {
                onError: () => setBranchStatusInput(currentStatus),
              });
              toggleStatusMenuOpen(false);
            }}
            checkbox
            checked={
              branchStatusInput === statusChoice ||
              (branchStatusInput === BranchStatus.NONE &&
                statusChoice === BranchStatus.WIP)
            }
            qaSelector={branchStatusReadable(statusChoice)}
          >
            <div className={style.menuItem}>
              <Icon type={branchStatusIcon(statusChoice).concat("-default")} />
              <span className={style.menuText}>
                {branchStatusReadable(statusChoice)}
              </span>
            </div>
          </FloatingMenuItem>
        ))}
        <MenuDivider />
        {reviewItem}
      </div>
    );

    return (
      <AnimatedFlyover
        isOpen={isStatusMenuOpen}
        onRequestClose={() => {
          toggleStatusMenuOpen(false);
        }}
        onClickOutside={() => {
          toggleStatusMenuOpen(false);
        }}
        body={branchStatusMenu}
        anchor="left"
        className={style.statusMenu}
        wrapperClass={className}
        qaSelector="context-menu"
      >
        <ButtonUnstyled
          onClick={onClickPillBadge}
          className={classnames(
            style.branchStatusBadge,
            style.clickable,
            className,
            badgeStyle,
            {
              [style.noIcon]: !icon,
              [style.selected]: selected,
              [style.shrinkAtMobile]: !activityItem,
              [style.shrinkAtTablet]: shrinkAtTablet,
            }
          )}
          disabled={disabled}
          qaSelector="branch-status-menu-button"
        >
          {icon}
          <div className={style.badgeText}>
            {branchStatusReadable(branchStatusInput)}
          </div>
          <Icon
            className={style.chevron}
            fill="currentColor"
            type="chevron-default-down"
          />
        </ButtonUnstyled>
      </AnimatedFlyover>
    );
  }

  const unclickableBadge = (popoverText?: string) => {
    return (
      <span className={className}>
        <Popover placement="bottom" label={popoverText} disabled={!popoverText}>
          <div
            className={classnames(
              style.branchStatusBadge,
              badgeStyle,
              className,
              style.noDisclosure,
              {
                [style.noIcon]: !icon,
                [style.inlineSpacing]: activityItem,
                [style.shrinkAtMobile]: !activityItem,
                [style.shrinkAtTablet]: shrinkAtTablet,
                [style.selected]: selected,
              }
            )}
          >
            {icon}
            <div className={style.badgeText}>
              {branchStatusReadable(branchStatusInput)}
            </div>
          </div>
        </Popover>
      </span>
    );
  };

  if (isStale) {
    return unclickableBadge("This branch hasn’t been updated in over a month");
  }
  if (isDiverged) {
    return unclickableBadge("This branch needs updating and merging to sync");
  }
  return unclickableBadge();
}
