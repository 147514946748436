// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "core/components/Button";
import DialogForm from "core/components/DialogForm";
import Note from "core/components/Note";
import SettingsItem from "core/components/SettingsItem";
import ValidationError from "core/components/ValidationError";
import { fromNow } from "core/lib/dates";
import type { State as AppState, Dispatch } from "core/types";
import { AccessTokenRevokeRequest } from "web/requests/accessTokens";
import type { AccessToken } from "../../types";
import style from "./style.scss";

type OwnProps = {|
  token: AccessToken,
|};

type DispatchProps = {|
  onSubmit: ({ id: string }) => void,
|};

type StateProps = {|
  error?: boolean,
  isSubmitting?: boolean,
|};

type Props = {
  ...OwnProps,
  ...DispatchProps,
  ...StateProps,
};

type State = {
  showModal: boolean,
};

class AccessTokensListItem extends Component<Props, State> {
  state = {
    showModal: false,
  };

  showDialog = () => {
    this.setState({ showModal: true });
  };

  hideDialog = () => {
    this.setState({ showModal: false });
  };

  onSubmit = (event: SyntheticEvent<>) => {
    const { token, onSubmit } = this.props;
    event.preventDefault();
    onSubmit(token);
  };

  render() {
    const { error, isSubmitting, token } = this.props;
    return (
      <SettingsItem
        key={token.id}
        label={token.description}
        secondaryLabel={<Button onClick={this.showDialog}>Revoke</Button>}
      >
        <Note className={style.createdAt}>
          Created {fromNow(token.createdAt)}
        </Note>
        <DialogForm
          dangerous
          disabled={isSubmitting}
          isOpen={this.state.showModal}
          loading={isSubmitting}
          onClose={this.hideDialog}
          onSubmit={this.onSubmit}
          primaryButton={"Revoke Token"}
          title="Revoke Token"
        >
          <div>
            Are you sure you want to permanently revoke{" "}
            {<strong>{token.description}</strong>} from your API tokens?
          </div>
          {error && (
            <ValidationError error="An unexpected error occurred, try again?" />
          )}
        </DialogForm>
      </SettingsItem>
    );
  }
}

function mapStateToProps(state: AppState, props: OwnProps): StateProps {
  return {
    error: AccessTokenRevokeRequest.hasError(state, props.token),
    isSubmitting: AccessTokenRevokeRequest.isLoadingStrict(state, props.token),
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    onSubmit: (params: { id: string }) =>
      dispatch(AccessTokenRevokeRequest.perform({ params })),
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  AppState,
  Dispatch,
>(
  mapStateToProps,
  mapDispatchToProps
)(AccessTokensListItem);
