// @flow
import * as React from "react";
import style from "./style.scss";
import Empty from ".";

export default function NoProjectsNonMac() {
  return (
    <Empty
      image={<div className={style.nonMacImage} />}
      title="No projects, yet."
      description={
        <span>
          Projects can be created on the Abstract app, <br />
          which is only available for macOS.
          <br />
        </span>
      }
    />
  );
}
