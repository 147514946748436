// @flow
/* global HTMLElement, HTMLFormElement */
import classnames from "classnames";
import invariant from "invariant";
import * as React from "react";
import { Waypoint } from "react-waypoint";
import Button, { type ButtonElement } from "core/components/Button";
import Heading from "core/components/Heading";
import Modal from "core/components/Modal";
import withRouterAndRef from "core/lib/withRouterAndRef";
import type { ReactRouterLocation } from "core/types";
import style from "./style.scss";

type Props = {
  children: React.Node | ((*) => React.Node),
  title: React.Node,
  className?: string,
  headingClassName?: string,
  contentClassName?: string,
  isOpen: boolean,
  fixedHeight?: boolean,
  size?: "medium" | "large" | "fullScreen",
  disabled?: boolean,
  loading?: boolean,

  // NOTE if the onSubmit function returns a promise then the submit button will
  // be automatically disabled until the promise is resolved or rejected.
  onSubmit?: (event: SyntheticEvent<>) => mixed,
  onSecondary?: (event: SyntheticEvent<>) => *,
  onClose?: (event?: SyntheticEvent<>) => void,
  onOpen?: (event?: SyntheticEvent<>) => void,
  primaryButton: ?string | React.Element<typeof Button>,
  secondaryButton?: ?string,
  dangerous?: boolean,
  alternativePadding?: boolean,
  location: ReactRouterLocation,
};

type State = { submitting: boolean, invalid: boolean, isTopBarDocked: boolean };

class DialogForm extends React.Component<Props, State> {
  form = React.createRef<HTMLElement>();
  submit: ?React.ElementRef<ButtonElement>;
  focusTimeout: TimeoutID;

  static defaultProps = {
    title: "",
    primaryButton: "Submit",
    secondaryButton: null,
    size: "medium",
  };

  state = { submitting: false, invalid: true, isTopBarDocked: false };

  // A flag to prevent setState from being called after
  // the component has unmounted.
  mounted = false;

  componentDidMount() {
    this.onDisplay();
    this.mounted = true;
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.disabled !== this.props.disabled && !this.props.disabled) {
      this.checkValid();
    }
  }

  handleDock = () => this.setState({ isTopBarDocked: true });
  handleUnDock = () => this.setState({ isTopBarDocked: false });

  onDisplay = () => {
    if (this.props.onOpen && this.props.isOpen) {
      this.props.onOpen();
    }

    this.checkValid();

    if (this.isValid()) {
      // NOTE timeout here is needed to ensure that the dialog is visible / has
      // transitioned in before we request focus on the submit button.
      this.focusTimeout = setTimeout(this.focusSubmitButton, 100);
    }
  };

  componentWillUnmount() {
    this.mounted = false;
    clearTimeout(this.focusTimeout);
  }

  focusSubmitButton = () => {
    if (!this.submit) {
      return;
    }

    if (this.submit && this.submit instanceof HTMLElement) {
      this.submit.focus();
    }
  };

  isValid = () => {
    const form = this.form.current;

    return !!(form && form instanceof HTMLFormElement && form.checkValidity());
  };

  checkValid = () => {
    this.setState({ invalid: !this.isValid() });
  };

  handleSecondary = (event: SyntheticEvent<>) => {
    event.preventDefault();

    if (this.props.onSecondary) {
      this.props.onSecondary(event);
    } else if (this.props.onClose) {
      this.props.onClose(event);
    }
  };

  handleSubmit = async (event: SyntheticEvent<>) => {
    event.preventDefault();
    if (this.state.submitting) {
      return;
    }

    this.checkValid();

    if (this.isValid()) {
      try {
        this.setState({ submitting: true });
        invariant(
          this.props.onSubmit,
          "onSubmit is required when calling DialogForm without nested form"
        );
        await this.props.onSubmit(event);
      } catch (error) {
        if (this.mounted) {
          this.setState({ submitting: false });
        }
      } finally {
        if (this.mounted) {
          this.setState({ submitting: false });
        }
      }
    }
  };

  renderPrimaryButton = () => {
    const { dangerous, primaryButton, loading } = this.props;
    const disabled =
      this.props.disabled || this.state.invalid || this.state.submitting;

    if (primaryButton) {
      if (typeof primaryButton === "string") {
        return (
          <Button
            type="submit"
            innerRef={(ref) => (this.submit = ref)}
            disabled={disabled}
            primary={!dangerous}
            danger={dangerous}
            qaSelector="dialogSubmitButton"
            icon={loading ? "spinner" : undefined}
            large
          >
            {primaryButton}
          </Button>
        );
      }

      if (primaryButton.type === Button) {
        return React.cloneElement(primaryButton, {
          type: "submit",
          qaSelector: "dialogSubmitButton",
        });
      }
    }

    return primaryButton;
  };

  renderFooter = () => {
    const { primaryButton, secondaryButton, size } = this.props;
    const hasActions = !!primaryButton || !!secondaryButton;

    return (
      hasActions && (
        <div
          className={classnames(style.actions, {
            [style.fullScreenActions]: size === "fullScreen",
            [style.naturalButtonWidth]:
              size !== "medium" || !!(primaryButton && secondaryButton),
          })}
        >
          {!!secondaryButton && (
            <Button
              type="button"
              onClick={this.handleSecondary}
              large
              qaSelector="dialog-secondary-button"
            >
              {secondaryButton}
            </Button>
          )}
          {!!primaryButton && this.renderPrimaryButton()}
        </div>
      )
    );
  };

  render() {
    const {
      onClose,
      onOpen,
      className,
      contentClassName,
      headingClassName,
      title,
      isOpen,
      fixedHeight,
      size = "medium",
      alternativePadding,
      location,
      ...rest
    } = this.props;

    return (
      <Modal
        {...rest}
        className={className}
        isOpen={isOpen}
        onClose={onClose}
        title={title}
        hideModalHeader={this.state.isTopBarDocked}
        onOpen={this.onDisplay}
        footer={this.renderFooter()}
        onSubmit={this.props.onSubmit ? this.handleSubmit : undefined}
        innerRef={this.form}
        fixedHeight={fixedHeight}
        size={size}
      >
        <div
          className={classnames(style.content, contentClassName, {
            [style.fullScreenForm]: size === "fullScreen",
            [style.alternativePadding]: alternativePadding,
          })}
          onClick={(event: SyntheticEvent<>) => event.stopPropagation()}
        >
          {size !== "fullScreen" && (
            <React.Fragment>
              <Waypoint onEnter={this.handleDock} onLeave={this.handleUnDock} />
              <Heading
                level="1"
                size="xxxl"
                className={classnames(style.heading, headingClassName, {
                  [style.alternativePaddingOnHeading]: alternativePadding,
                })}
                aria-hidden={!this.state.isTopBarDocked}
              >
                {title}
              </Heading>
            </React.Fragment>
          )}

          {typeof this.props.children === "function"
            ? this.props.children({
                onSubmit: this.handleSubmit,
                onSecondary: this.handleSecondary,
              })
            : this.props.children}
        </div>
      </Modal>
    );
  }
}

export default withRouterAndRef(DialogForm); // cast not typical but withRouter returns any
export { DialogForm as TDialogForm };
