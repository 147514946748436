// @flow
import empty from "empty";
import { sortBy } from "lodash";
import matchString from "core/lib/matchString";
import naturalSortBy from "core/lib/naturalSortBy";
import { type User } from "core/types";

export default function filterUsers(users: User[], value: string): User[] {
  if (!users) {
    return empty.array;
  }

  const filtered = users.filter((user) => {
    return matchString(user.name, value) || matchString(user.username, value);
  });

  return sortBy(naturalSortBy(filtered, "name"), (user) =>
    user.name.startsWith(value) || user.username.startsWith(value) ? 0 : 1
  );
}
