// @flow
import apiRequest from "abstract-di/api";
import { entitiesReceived } from "core/actions/entities";
import { setPaginationTotal } from "core/actions/paginationTotals";
import {
  normalizeTeamMembershipsFetchResponse,
  normalizeTeamMembershipResponse,
} from "core/schemas/teamMemberships";
import type {
  Dispatch,
  OrganizationTeamDescriptor,
  OrganizationTeamMembershipParams,
  PaginatedTeamMembershipsParams,
} from "core/types";
import defineRequest from "./";

function recursiveOnSuccess(response, params, dispatch) {
  if (Array.isArray(response.data) && response.data.length === params.limit) {
    const newParams = {
      ...params,
      offset: params.offset + params.limit,
    };

    return dispatch(
      PaginatedTeamMembershipsRequest.perform({
        params: newParams,
        onSuccess: (response) =>
          recursiveOnSuccess(response, newParams, dispatch),
      })
    );
  }
}

export const TeamMembershipsFetchAllRequest = defineRequest<
  OrganizationTeamDescriptor,
  OrganizationTeamDescriptor,
>({
  id: (params) => {
    const { organizationId, teamId } = params;
    return `get:organizations/${organizationId}/teams/${teamId}/members`;
  },
  perform(params, dispatch, getState) {
    const paginatedParams = { ...params, limit: 100, offset: 0 };

    return dispatch(
      PaginatedTeamMembershipsRequest.perform({
        params: paginatedParams,
        onSuccess: (response) => {
          recursiveOnSuccess(response, paginatedParams, dispatch);
        },
      })
    );
  },
  force: false,
  invalidateable: true,
});

export const PaginatedTeamMembershipsRequest = defineRequest<
  PaginatedTeamMembershipsParams,
  PaginatedTeamMembershipsParams,
>({
  id: (params) => {
    return `get:organizations/${params.organizationId}/teams/${
      params.teamId
    }/members/limit/${params.limit}/offset/${params.offset}?search=${
      params.query || ""
    }`;
  },
  perform: (params) => {
    return apiRequest(
      "get",
      `organizations/${params.organizationId}/teams/${params.teamId}/members`,
      {
        limit: params.limit,
        offset: params.offset,
        search: params.query,
      }
    );
  },
  onSuccess: (
    response,
    params: PaginatedTeamMembershipsParams,
    dispatch: Dispatch
  ) => {
    const normalizedTeamMemberships =
      normalizeTeamMembershipsFetchResponse(response);
    dispatch(setPaginationTotal(params.teamId, response.meta.total));
    dispatch(entitiesReceived(normalizedTeamMemberships.entities));
  },
  invalidateable: true,
});

// With pagination omitted, this request will return a default limit of 50 entries.
export const OrganizationTeamMembershipsFetchRequest = defineRequest<
  OrganizationTeamDescriptor,
  OrganizationTeamDescriptor,
>({
  id: (params) => {
    return `get:organizations/${params.organizationId}/teams/${params.teamId}/members`;
  },
  perform: (params) => {
    return apiRequest(
      "get",
      `organizations/${params.organizationId}/teams/${params.teamId}/members`
    );
  },
  onSuccess: (
    response,
    params: OrganizationTeamDescriptor,
    dispatch: Dispatch
  ) => {
    const normalizedTeamMemberships =
      normalizeTeamMembershipsFetchResponse(response);
    dispatch(setPaginationTotal(params.teamId, response.meta.total));
    dispatch(entitiesReceived(normalizedTeamMemberships.entities));
  },
  invalidateable: true,
});

// HEADS UP! Use the version of this request in core/actions/teamMemberships
export const TeamMembershipCreateRequest = defineRequest<
  OrganizationTeamMembershipParams,
  OrganizationTeamMembershipParams,
>({
  id: (params) => {
    return `post:organizations/${params.organizationId}/teams/${params.teamId}/members-${params.userId}`;
  },
  perform: (params) => {
    return apiRequest(
      "post",
      `organizations/${params.organizationId}/teams/${params.teamId}/members`,
      { userId: params.userId, isAdmin: false }
    );
  },
  onSuccess: (response, params, dispatch) => {
    const normalizedTeamMembership = normalizeTeamMembershipResponse(response);
    dispatch(entitiesReceived(normalizedTeamMembership.entities));
  },
});

// HEADS UP! Use the version of this request in core/actions/teamMemberships
export const TeamMembershipDeleteRequest = defineRequest<
  OrganizationTeamMembershipParams,
  OrganizationTeamMembershipParams,
>({
  id: (params) => {
    return `delete:organizations/${params.organizationId}/teams/${params.teamId}/members-${params.userId}`;
  },
  perform: (params) => {
    return apiRequest(
      "delete",
      `organizations/${params.organizationId}/teams/${params.teamId}/members/${params.userId}`
    );
  },
});

type TeamMembershipUpdateRequestParams = {|
  ...OrganizationTeamMembershipParams,
  isAdmin: boolean,
|};

export const TeamMembershipUpdateRequest = defineRequest<
  TeamMembershipUpdateRequestParams,
  TeamMembershipUpdateRequestParams,
>({
  id: (params) => {
    return `put:organizations/${params.organizationId}/teams/${params.teamId}/members-${params.userId}`;
  },
  perform: (params) => {
    return apiRequest(
      "put",
      `organizations/${params.organizationId}/teams/${params.teamId}/members/${params.userId}`,
      {
        isAdmin: params.isAdmin,
      }
    );
  },
  onSuccess: (response, params, dispatch) => {
    const normalizedTeamMembership = normalizeTeamMembershipResponse(response);
    dispatch(entitiesReceived(normalizedTeamMembership.entities));
  },
});
