// @flow
import classnames from "classnames";
import * as React from "react";
import { organizationBillingUrl } from "../../lib/urls";
import style from "./style.scss";

export default function SubscriptionBannerExpiring(props: {
  daysRemaining: number,
  organizationId: string,
}) {
  return (
    <div className={classnames(style.banner, style.expiring)}>
      <span>
        You have {props.daysRemaining ? "only " : "less than "}
        <strong>1</strong> day left in your free trial. To continue using
        Abstract after your trial expires please choose from one of our paid
        plans.{" "}
        <a
          href={organizationBillingUrl(props.organizationId)}
          className={style.link}
        >
          Go to billing
        </a>
      </span>
    </div>
  );
}
