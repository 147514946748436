// @flow
import * as React from "react";
import ContextMenu, {
  type Props as ContextMenuProps,
} from "core/components/ContextMenu";
import type { File, MenuItem, Page } from "core/types";
import connector from "./connector";

export type OwnProps = {|
  projectId: string,
  branchId: string,
  file: File,
  page: Page,
  onAfterClose?: () => void,
  innerRef?: React.Ref<typeof ContextMenu>,
  children: $PropertyType<ContextMenuProps, "children">,
|};

export type StateProps = {|
  organizationId?: string,
  isDevelopment: boolean,
|};
export type Props = {
  ...OwnProps,
  ...StateProps,
};

function PageMenu(props: Props) {
  const menuItems = (): Array<MenuItem> => {
    if (props.organizationId) {
      return [
        {
          type: "share",
          label: "Copy Link to Page",
          props: {
            kind: "page",
            organizationId: props.organizationId,
            projectId: props.projectId,
            branchId: props.branchId,
            fileId: props.file.id,
            commitSha: props.file.sha,
            pageId: props.page.id,
          },
        },
        { type: "separator", visible: props.isDevelopment },
        {
          label: "Development",
          visible: props.isDevelopment,
          submenu: [
            {
              type: "copy",
              label: "Copy Page ID",
              text: props.page.id,
            },
          ],
        },
      ];
    }
    return [
      {
        label: "Loading…",
        enabled: false,
      },
    ];
  };

  return (
    <ContextMenu
      id={props.page.id}
      ref={props.innerRef}
      menuItems={menuItems()}
      placement="bottom-end"
      children={props.children}
      onAfterClose={props.onAfterClose}
    />
  );
}

export default connector(PageMenu);
