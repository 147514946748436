// @flow
import Cookies from "js-cookie";
import history from "abstract-di/history";
import { trackEvent } from "core/actions/analytics";
import { entitiesReceived } from "core/actions/entities";
import { request } from "core/actions/requests";
import {
  AccountCreateRequest,
  type CreateAccountFormValues,
  type MarketingDetails,
} from "core/requests/account";
import { normalizeAccountResponse } from "core/schemas/account";
import type { Invitation } from "core/types";
import { getAndTrackNewUser } from "web/actions/analytics";
import * as API from "web/api";
import * as URLs from "web/api/urls";
import { setToken } from "web/token";
import { deleteSession, sessionLoaded } from "./session";
import type { ThunkAction } from ".";

const getMarketingDetails = (): MarketingDetails => {
  const location = history.getCurrentLocation();

  return {
    utmSource: location.query.utm_source,
    utmMedium: location.query.utm_medium,
    utmCampaign: location.query.utm_campaign,
    utmTerm: location.query.utm_term,
    utmContent: location.query.utm_content,
    marketoMunchkinId: Cookies.get("_mkto_trk"),
  };
};

export function createAccount(
  params: CreateAccountFormValues,
  invitation: ?Invitation,
  onSuccess?: () => void,
  onError?: () => void
): ThunkAction {
  const marketingDetails = getMarketingDetails();

  return (dispatch) => {
    return dispatch(
      AccountCreateRequest.perform({
        params: {
          ...params,
          ...marketingDetails,
        },
        onSuccess: async (accountResponse) => {
          const sessionResponse = await API.createSession(
            params.email,
            params.password
          );
          setToken(sessionResponse["access_token"]);

          const { entities } = normalizeAccountResponse(accountResponse);
          dispatch(entitiesReceived(entities));

          dispatch(sessionLoaded(accountResponse));
          dispatch(getAndTrackNewUser());

          dispatch(
            trackEvent("ACCOUNT_CREATED", {
              fullName: params.name,
              organizationId: invitation
                ? invitation.organizationId
                : undefined,
              organizationName: invitation
                ? invitation.organizationName
                : undefined,
              ...marketingDetails,
            })
          );

          if (onSuccess) {
            onSuccess();
          }
        },
        onError,
      })
    );
  };
}

export function previewDeleteAccount(): ThunkAction {
  return (dispatch) => {
    dispatch(
      request(`delete:${URLs.previewAccount()}`, () =>
        API.previewDeleteAccount()
      )
    );
  };
}

export function deleteAccount(): ThunkAction {
  return (dispatch) => {
    dispatch(
      request(`delete:${URLs.fetchAccount()}`, () => API.deleteAccount(), {
        onSuccess: () => dispatch(deleteSession()),
      })
    );
  };
}
