// @flow
import classnames from "classnames";
import * as React from "react";
import style from "./style.scss";

type Props = {
  className?: string,
};

export default function MenuDivider(props: Props) {
  return <hr className={classnames(style.divider, props.className)} />;
}
