// @flow
import * as React from "react";
import getDisplayName from "react-display-name";
import { shallowCompareWithoutFunctions } from "core/lib/shallowComparison";

export default function withShallowCompareWithoutFunctions<Config: {}>(
  WrappedComponent: React.AbstractComponent<Config>
): React.AbstractComponent<Config> {
  const displayName = getDisplayName(WrappedComponent);

  return class ShallowCompareWithoutFunctions extends React.Component<Config> {
    // prettier-ignore
    static displayName = `withShallowCompareWithoutFunctions(${displayName || ""})`;

    shouldComponentUpdate(nextProps: Config, state) {
      // Note: shallowCompareWithoutFunctions requires state prop to be passed
      return shallowCompareWithoutFunctions(this, nextProps, state);
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
}
