// @flow
import classnames from "classnames";
import * as React from "react";
import style from "./style.scss";

type Props = {
  center?: ?React.Node,
  left?: ?React.Node,
  right?: ?React.Node,
  className?: string,
};

export default function Header(props: Props) {
  return (
    <div className={classnames(style.container, props.className)}>
      <div className={style.left}>{props.left}</div>
      <div className={style.center}>{props.center}</div>
      <div className={style.right}>{props.right}</div>
    </div>
  );
}
