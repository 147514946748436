// @flow
import * as React from "react";
import FormHeader from "core/components/FormHeader";
import FormSection from "core/components/FormSection";
import InputPassword from "core/components/InputPassword";
import Media from "core/components/Media";
import SettingsForm from "core/components/SettingsForm";
import SettingsItem from "core/components/SettingsItem";
import SettingsItemInputNote from "core/components/SettingsItemInputNote";
import withForm from "web/containers/withForm";
import type { User, FormProps } from "web/types";

export type FormValues = {
  currentPassword: string,
  newPassword: string,
  newPasswordConfirmation: string,
};

type OwnProps = {
  account: User,
  onSubmit: (formId: string, values: FormValues) => *,
};
type Props = {
  ...OwnProps,
  ...FormProps,
};
class PasswordEdit extends React.Component<Props> {
  handleSubmit = (event) => {
    event.preventDefault();
    this.props.onSubmit(this.props.form.id, this.props.form.values);
  };

  handleChange = (event) => {
    this.props.form.onChange(this.props.form.id, {
      [event.target.name]: event.target.value,
    });
  };

  render() {
    const { form } = this.props;
    const header = (
      <Media desktop>
        {(desktop) => (
          <FormHeader
            mobile={!desktop}
            heading="Change Password"
            disabled={!form.isDirty || form.isSubmitting}
            error={form.error ? "Password could not be updated." : ""}
            submitText={form.isSubmitting ? "Saving changes…" : "Save changes"}
            success={form.submitSucceeded}
            successMessage="Password updated."
            icon="lock-locked-alt"
          />
        )}
      </Media>
    );

    return (
      <SettingsForm
        noValidate
        onSubmit={this.handleSubmit}
        header={header}
        documentTitle="Password"
      >
        <FormSection>
          <SettingsItem input>
            <InputPassword
              requiredTag
              responsive
              showHelper={false}
              autoCapitalize="none"
              placeholder="••••••••••"
              error={form.errors.currentPassword}
              id="currentPassword"
              label="Current password"
              name="currentPassword"
              autoComplete="current-password"
              onChange={this.handleChange}
              value={form.values.currentPassword}
            />
          </SettingsItem>
          <SettingsItem input>
            <InputPassword
              requiredTag
              responsive
              showHelper={false}
              autoCapitalize="none"
              placeholder="••••••••••"
              error={form.errors.newPassword}
              id="newPassword"
              label="New password"
              name="newPassword"
              autoComplete="new-password"
              onChange={this.handleChange}
              value={form.values.newPassword}
            />
            <SettingsItemInputNote>
              To keep your account secure, we’ll log you out of Abstract in
              other browsers and the desktop app.
            </SettingsItemInputNote>
          </SettingsItem>
          <SettingsItem input>
            <InputPassword
              requiredTag
              responsive
              showHelper={false}
              autoCapitalize="none"
              placeholder="••••••••••"
              error={form.errors.newPasswordConfirmation}
              id="newPasswordConfirmation"
              label="Confirm new password"
              name="newPasswordConfirmation"
              autoComplete="new-password"
              onChange={this.handleChange}
              value={form.values.newPasswordConfirmation}
            />
          </SettingsItem>
        </FormSection>
      </SettingsForm>
    );
  }
}

export default withForm<OwnProps>(PasswordEdit, "update-password", {
  currentPassword: "",
  newPassword: "",
  newPasswordConfirmation: "",
});
