// @flow
import * as React from "react";
import DialogForm from "core/components/DialogForm";
import type { Layer } from "core/types";

type Props = {
  isOpen: boolean,
  hasMore: boolean,
  duplicates: Layer[],
  onSubmit: () => *,
  onSecondary: () => *,
  onClose: () => *,
  submitText?: string,
};

export default function AddDuplicateConfirmation(props: Props) {
  const duplicateCount = props.duplicates.length;
  const hasMultiple = props.hasMore || duplicateCount > 1;

  return (
    <DialogForm
      isOpen={props.isOpen}
      title="Add to Collection"
      onClose={props.onClose}
      onSecondary={props.onSecondary}
      onSubmit={props.onSubmit}
      secondaryButton="Add anyway"
      primaryButton={
        props.submitText || `Skip duplicate${hasMultiple ? "s" : ""}`
      }
    >
      {() => (
        <p>
          You{"'"}ve already added{" "}
          <strong>
            {props.hasMore
              ? `${duplicateCount} or more of the selected items`
              : duplicateCount === 1
              ? `"${props.duplicates[0].name}"`
              : `${duplicateCount} of the selected items`}
          </strong>{" "}
          to this collection.
        </p>
      )}
    </DialogForm>
  );
}
