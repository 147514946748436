// @flow
import * as React from "react";
import Button from "core/components/Button";

type Props = {
  small?: boolean,
  partiallySelected?: boolean,
  disabled?: boolean,
  selected: boolean,
  className?: string,
  tooltip?: string,
  onSelect?: (SyntheticMouseEvent<>) => *,
  qaSelector?: string,
  nude?: boolean,
};

export default function MultiSelectButton({
  small,
  tooltip,
  disabled,
  selected,
  partiallySelected,
  className,
  onSelect,
  qaSelector,
  nude,
}: Props) {
  let icon = undefined;
  if (selected || !small) {
    icon = "checkmark";
  } else if (partiallySelected) {
    icon = "zoom-out";
  }

  return (
    <React.Fragment>
      <Button
        className={className}
        disabled={disabled}
        onClick={onSelect}
        tooltip={!!tooltip}
        title={tooltip}
        nude={nude}
        primary={(selected || partiallySelected) && !nude}
        small={small}
        tint={(selected || partiallySelected) && nude}
        qaSelector={qaSelector}
        icon={icon}
      />
    </React.Fragment>
  );
}
