// @flow
import * as React from "react";
import image from "./images/file.svg";
import Empty from ".";

export default function NoLayers(props: {}) {
  return (
    <Empty
      {...props}
      image={image}
      title="Page is empty"
      description="This page has no content."
    />
  );
}
