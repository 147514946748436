// @flow
import classnames from "classnames";
import * as React from "react";
import style from "./style.scss";

type Props = {
  children: React.Node,
  grouped?: boolean,
  className?: *,
};

export default class PropertyRow extends React.PureComponent<Props> {
  render() {
    return (
      <div
        className={classnames(this.props.className, style.propertyRow, {
          [style.grouped]: this.props.grouped,
        })}
      >
        {this.props.children}
      </div>
    );
  }
}
