// @flow
import classnames from "classnames";
import * as React from "react";
import style from "./style.scss";

type Props = {
  className?: string,
  light?: boolean,
  percentage: number,
};

function percentageOfCircumference(radius: number, percentage: number) {
  return 2 * Math.PI * radius * ((100 - percentage) / 100);
}

export default function ProgressCircle({
  className,
  light,
  percentage,
}: Props) {
  const classes = classnames(style.circle, className);
  const insetClasses = classnames(style.inset, {
    [style.insetLight]: light,
  });
  const progressClasses = classnames(style.progress, {
    [style.progressLight]: light,
  });

  return (
    <svg className={classes} viewBox="0 0 24 24">
      <circle
        className={insetClasses}
        cx="12"
        cy="12"
        r="7"
        fill="none"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <circle
        className={progressClasses}
        style={{
          strokeDashoffset: `${percentageOfCircumference(7, percentage)}`,
        }}
        cx="12"
        cy="12"
        r="7"
        fill="none"
        strokeWidth="2"
        transform="rotate(-94, 12, 12)" // -94 = perceived top center
      />
    </svg>
  );
}
