// @flow
import createCachedSelector from "@elasticprojects/re-reselect";
import { createSelector } from "reselect";
import { isOnline, getCurrentUserId } from "abstract-di/selectors";
import { Abstract } from "core/lib/abstract";
import { isDesktop } from "core/lib/platform";
import * as Branch from "core/models/branch";
import { getBranch } from "core/selectors/branches";
import { canUseTransportPriority } from "core/selectors/features";
import { isBranchSynced } from "core/selectors/localBranches";
import type { State } from "core/types";

type Transport = "api" | "cli";

function cacheByBranch(
  state: State,
  { projectId, branchId }: Abstract.BranchDescriptor
) {
  return [projectId, branchId].join("-");
}

export const getTransportMode: (
  state: State,
  props: Abstract.ProjectDescriptor
) => Transport[] = createSelector(
  canUseTransportPriority,
  (transportPriorityEnabled) => {
    // In the web client we always want to use the API
    if (!isDesktop) {
      return ["api"];
    }

    // If we don't know the authorship info then default to CLI for safety
    // API acts as a fallback in the circumstance that the data is not synced
    return transportPriorityEnabled ? ["cli", "api"] : ["cli"];
  }
);

export const getTransportModeForBranch: (
  state: State,
  props: Abstract.BranchDescriptor
) => Transport[] = createCachedSelector(
  getCurrentUserId,
  getBranch,
  isBranchSynced,
  canUseTransportPriority,
  isOnline,
  (currentUserId, branch, branchIsSynced, transportPriorityEnabled, online) => {
    // In the web client we always want to use the API
    if (!isDesktop) {
      return ["api"];
    }
    if (!transportPriorityEnabled) {
      return ["cli"];
    }

    // If we have a local head the branch is definitely on disk, we should
    // continue to load it from disk
    if (branchIsSynced) {
      return ["cli"];
    }

    // If the current user is the branch author then prefer loading from
    // CLI as there may be unsynced data on active branches.
    if (
      currentUserId &&
      branch &&
      Branch.isActive(branch) &&
      Branch.userIsAuthor(branch, currentUserId)
    ) {
      return ["cli", "api"];
    }

    // Otherwise, prefer the API for speed if online
    return online ? ["api", "cli"] : ["cli"];
  }
)(cacheByBranch);
