// @flow
import { EventTypes } from "redux-segment";
import { getQuickJumpOpen } from "core/selectors/quickJump";
import type { ThunkAction, Action } from "core/types";

export function quickJumpOpen(options?: { showShortcutTip?: boolean }): Action {
  return {
    type: "core/QUICK_JUMP_OPEN",
    meta: {
      showShortcutTip: options && options.showShortcutTip,
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: "QUICKSWITCHER_OPEN",
        },
      },
    },
  };
}

export function quickJumpClose(success: boolean = false): Action {
  return {
    type: "core/QUICK_JUMP_CLOSE",
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: "QUICKSWITCHER_CLOSE",
          properties: { success },
        },
      },
    },
  };
}

export function quickJumpToggle(options?: {
  showShortcutTip?: boolean,
}): ThunkAction {
  return (dispatch, getState) => {
    if (getQuickJumpOpen(getState())) {
      dispatch(quickJumpClose());
    } else {
      dispatch(quickJumpOpen(options));
    }
  };
}

export function quickJumpTermChange(payload: string): Action {
  return { type: "core/QUICK_JUMP_TERM_CHANGE", payload };
}
