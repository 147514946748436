// @flow
import * as React from "react";
import Loaded from "core/components/Loaded";
import EmailVerificationError from "web/components/Onboarding/EmailVerificationError";
import OnboardingPage from "web/components/OnboardingPage";
import connector from "./connector";

export type OwnProps = {|
  params: { token: string },
|};

export type StateProps = {|
  error: boolean,
  loading: boolean,
|};

export type DispatchProps = {|
  verifyEmail: () => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

class VerifyEmail extends React.Component<Props> {
  componentDidMount() {
    this.props.verifyEmail();
  }

  render() {
    if (this.props.error) {
      return (
        <EmailVerificationError token={this.props.params.token} signupFlow />
      );
    }

    return (
      <OnboardingPage>
        <Loaded loading />
      </OnboardingPage>
    );
  }
}

export default connector(VerifyEmail);
