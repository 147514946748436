// @flow
import * as Sentry from "@sentry/react";
import history from "abstract-di/history";
import { buildUrlBase } from "core/lib/buildUrlBase";
import createLogger from "core/lib/logger";
import {
  filterSensitiveArguments,
  filterSensitiveRouteParameters,
} from "core/lib/sensitiveUrlFilter";
import type { Organization } from "core/types";

const logger = createLogger("router");

interface AnalyticsInterface {
  page(title?: string, properties?: Object): void;
}

export default function trackHistory(analytics?: AnalyticsInterface) {
  const trackPage = () => {
    const location = history.getCurrentLocation();
    const pathname = filterSensitiveRouteParameters(
      // trim trailing slashes so that route and route/ are not considered to
      // be different for the purposes of analytics
      location.pathname.replace(/\/+$/, "")
    );
    const search = filterSensitiveArguments(location.search);
    const url = `${pathname}${search}`;
    logger.log(`navigated to ${url}`);

    if (!analytics) {
      return;
    }

    let fullUrl;
    try {
      fullUrl = new URL(url, buildUrlBase());
    } catch (err) {
      // new URL will throw if the url is invalid somehow, a double "//" is a
      // good example of this case, it's better to report this and continue
      // without tracking than crash the application.
      Sentry.captureException(err);
    }

    // we need to override these values so segment
    // does not get them from the window.location and send
    // on sensitive data in the url
    // note: segment also kicks off calls to google tag manager.
    // GTM then loads a host of other trackers like google analytics,
    // twitter, etc
    if (fullUrl) {
      analytics.page("", {
        path: pathname,
        referrer: "",
        search,
        url: fullUrl.toString(),
      });
    }
  };

  trackPage(); // Track initial page
  history.listen(trackPage);
  return trackPage;
}

export function shouldTrackCurrentOrganization(
  organization: Organization,
  prevOrganization: ?Organization
): boolean {
  if (organization !== prevOrganization && !prevOrganization) {
    return true;
  }

  if (organization !== prevOrganization && prevOrganization) {
    return (
      organization.id !== prevOrganization.id ||
      organization.name !== prevOrganization.name ||
      organization.isWithinSubscriptionTerm !==
        prevOrganization.isWithinSubscriptionTerm ||
      organization.isUsernameOrganization !==
        prevOrganization.isUsernameOrganization
    );
  }

  return false;
}
