// @flow
import invariant from "invariant";
import { connect } from "react-redux";
import { createDivergedBranch } from "abstract-di/actions";
import { dismissDialog } from "core/actions/dialogs";
import { defaultBranch } from "core/models/branch";
import { canUseNewDefaultBranchName } from "core/selectors/features";
import type { State, Dispatch } from "core/types";
import type { Props, StateProps, OwnProps, DispatchProps } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  return {
    defaultBranchName: defaultBranch({
      masterToMain: canUseNewDefaultBranchName(state),
      titleCase: true,
    }),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    createDivergedBranch: () => {
      invariant(
        createDivergedBranch,
        "createDivergedBranch required for branch action"
      );
      dispatch(createDivergedBranch(props.projectId, props.branch, true));
    },
    dismissDialog: () => dispatch(dismissDialog()),
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
