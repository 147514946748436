// @flow
import classnames from "classnames";
import * as React from "react";
import { organizationBillingUrl, helpUpdateBillingUrl } from "../../lib/urls";
import style from "./style.scss";

export default function SubscriptionBannerFailed(props: {
  canManageBilling?: boolean,
  organizationId: string,
}) {
  const message = props.canManageBilling ? (
    <span>
      Your subscription is no longer active and projects are now in{" "}
      <strong>read-only</strong> mode.{" "}
      <a
        href={organizationBillingUrl(props.organizationId)}
        className={style.link}
      >
        Go to billing
      </a>
    </span>
  ) : (
    <span>
      Your subscription is no longer active and projects are now in{" "}
      <strong>read-only</strong> mode. Please contact your team administrator if
      you believe there is an error.{" "}
      <a href={helpUpdateBillingUrl()} className={style.link}>
        Learn More…
      </a>
    </span>
  );

  return (
    <div className={classnames(style.banner, style.expired)}>{message}</div>
  );
}
