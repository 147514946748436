// @flow
import type { Changeset, ChangesetIdentifier } from "../types";

export function uniqueId(params: Changeset | ChangesetIdentifier) {
  // refine the type to test if this is a Changeset object and not a
  // ChangesetIdentifier
  if (params.id && typeof params.id === "string") {
    return params.id;
  }

  return params.sha || params.branchId || params.projectId;
}

export function isWorkingTree(params: Changeset | ChangesetIdentifier) {
  return uniqueId(params) === params.projectId;
}

export function isCommit(params: Changeset | ChangesetIdentifier) {
  return uniqueId(params) === params.sha;
}

export function isBranch(params: Changeset | ChangesetIdentifier) {
  return uniqueId(params) === params.branchId;
}
