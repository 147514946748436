// @flow
import { map } from "lodash";
import { getCurrentUserId } from "abstract-di/selectors";
import { clearBranchStatus } from "core/actions/branches";
import { deletedComment, createdComment } from "core/actions/comments";
import { entitiesDeleted, entitiesReceived } from "core/actions/entities";
import { deletedOrganizationMembership } from "core/actions/memberships";
import { markNotifications } from "core/actions/notifications";
import {
  projectMembershipUpdated,
  projectMembershipDeleted,
} from "core/actions/projectMemberships";
import { updatedProjectFromSocket } from "core/actions/projects";
import { addReaction, removeReaction } from "core/actions/reactions";
import { normalizeReviewResponse } from "core/actions/reviews";
import {
  longMessageReceived,
  socketMessageReceived as coreSocketMessageReceived,
} from "core/actions/socket";
import * as Request from "core/models/request";
import { CollectionFetchRequest } from "core/requests/collections";
import {
  normalizeCollection,
  normalizeCollectionLayer,
} from "core/schemas/collection";
import { normalizeComment } from "core/schemas/comment";
import { normalizeMembership } from "core/schemas/membership";
import { normalizeNotificationsResponse } from "core/schemas/notification";
import {
  normalizeOrganization,
  normalizeOrganizationJoinedResponse,
} from "core/schemas/organization";
import type { SocketEvent, LongMessageSocketEvent } from "core/types";
import { deleteSession } from "web/actions/session";
import { SessionTokenRefreshRequest } from "web/requests/sessions";
import { normalizeBranchUpdatedMessage } from "../schemas/branch";
import { normalizeProjectSocketResponse } from "../schemas/project";
import type { ThunkAction } from ".";

export function socketMessageReceived(
  event: SocketEvent | LongMessageSocketEvent
): ThunkAction {
  return async function (dispatch, getState) {
    const currentUserId = getCurrentUserId(getState());

    switch (event.type) {
      case "LONG_MESSAGE": {
        const originalEvent = longMessageReceived(event);
        if (!originalEvent) {
          return;
        }

        return dispatch(socketMessageReceived(originalEvent));
      }

      case "COMMENT_CREATED": {
        return dispatch(
          createdComment(event.data.comment, event.data.activity)
        );
      }

      case "COMMENT_UPDATED": {
        return dispatch(
          entitiesReceived(normalizeComment(event.data.comment).entities)
        );
      }

      case "COMMENT_DELETED": {
        return dispatch(
          deletedComment({ id: event.data.id, projectId: event.data.projectId })
        );
      }

      case "REACTION_ADDED": {
        return dispatch(addReaction(event.data));
      }

      case "REACTION_REMOVED": {
        return dispatch(removeReaction(event.data));
      }

      case "MEMBER_ADDED":
      case "MEMBER_UPDATED": {
        const { entities } = normalizeMembership(event.data.membership);
        return dispatch(entitiesReceived(entities));
      }

      case "MEMBER_REMOVED": {
        if (currentUserId === event.data.id) {
          dispatch(
            entitiesDeleted({ organizations: [event.data.organizationId] })
          );
        }

        return dispatch(
          deletedOrganizationMembership(
            event.data.organizationId,
            event.data.id
          )
        );
      }

      case "ORGANIZATION_JOINED": {
        const { entities } = normalizeOrganizationJoinedResponse(event.data);
        return dispatch(entitiesReceived(entities));
      }

      case "ORGANIZATION_UPDATED": {
        const { entities } = normalizeOrganization(event.data.organization);
        return dispatch(entitiesReceived(entities));
      }

      case "PROJECT_CREATED":
      case "PROJECT_REPO_CREATED":
      case "PROJECT_JOINED": {
        const { entities } = normalizeProjectSocketResponse(event.data);
        return dispatch(entitiesReceived(entities));
      }

      case "PROJECT_UPDATED": {
        return dispatch(updatedProjectFromSocket(event.data.project));
      }

      case "PROJECT_DELETED": {
        return dispatch(
          entitiesDeleted({
            projects: [event.data.project.id],
          })
        );
      }

      case "PROJECT_MEMBER_ADDED": {
        return dispatch(projectMembershipUpdated(event.data.projectMembership));
      }

      case "PROJECT_MEMBER_UPDATED": {
        return dispatch(projectMembershipUpdated(event.data.projectMembership));
      }

      case "PROJECT_MEMBER_REMOVED": {
        if (currentUserId === event.data.id) {
          return dispatch(
            entitiesDeleted({ projects: [event.data.projectId] })
          );
        }

        return dispatch(
          projectMembershipDeleted(event.data.projectId, event.data.id)
        );
      }

      case "BRANCH_RESTRICTIONS_UPDATED": {
        const { branchRestrictions } = event.data;

        return dispatch(
          entitiesReceived({
            branchRestrictions: {
              [branchRestrictions.id]: branchRestrictions,
            },
          })
        );
      }

      case "BRANCH_STATUS_UPDATED": {
        const { projectId, branchId } = event.data;
        return dispatch(
          clearBranchStatus({
            projectId,
            branchId,
          })
        );
      }

      case "BRANCH_UPDATED": {
        const { entities } = normalizeBranchUpdatedMessage(event.data);
        return dispatch(entitiesReceived(entities));
      }

      case "REVIEW_REQUEST_CREATED":
      case "REVIEW_REQUEST_UPDATED": {
        const normalizedData = normalizeReviewResponse(event.data);
        return dispatch(entitiesReceived(normalizedData));
      }

      case "REVIEW_REQUEST_DELETED": {
        const normalizedData = normalizeReviewResponse(event.data);
        const { reviewRequests, branchReviews, branches } = normalizedData;

        dispatch(
          entitiesDeleted({ reviewRequests: map(reviewRequests, "id") })
        );

        return dispatch(
          entitiesReceived({
            branchReviews,
            branches,
          })
        );
      }

      case "POLICIES_UPDATED": {
        return dispatch(entitiesReceived({ policies: event.data }));
      }

      case "CREATE_COLLECTION":
      case "UPDATE_COLLECTION": {
        const { entities } = normalizeCollection(event.data.collection);
        await dispatch(
          CollectionFetchRequest.perform({
            params: {
              collectionId: event.data.collection.id,
              projectId: event.data.collection.projectId,
            },
          })
        );
        return dispatch(entitiesReceived(entities));
      }

      case "DELETE_COLLECTION": {
        return dispatch(entitiesDeleted({ collections: [event.data.id] }));
      }

      case "CREATE_COLLECTION_LAYER":
      case "UPDATE_COLLECTION_LAYER": {
        const { entities } = normalizeCollectionLayer(
          event.data.collectionLayer
        );
        await dispatch(
          CollectionFetchRequest.perform({
            params: {
              collectionId: event.data.collectionLayer.collectionId,
              projectId: event.data.collectionLayer.projectId,
            },
          })
        );
        return dispatch(entitiesReceived(entities));
      }

      case "DELETE_COLLECTION_LAYER": {
        return dispatch(entitiesDeleted({ collectionLayers: [event.data.id] }));
      }

      case "NOTIFICATION_CREATED": {
        const notification = normalizeNotificationsResponse([
          event.data,
        ]).entities;
        return dispatch(entitiesReceived(notification));
      }

      case "NOTIFICATIONS_MARKED": {
        return dispatch(
          markNotifications({
            notificationIds: event.data.notificationIds,
            readState: event.data.state,
          })
        );
      }

      case "PASSWORD_CHANGED": {
        // We want to immediately log out of any connected sessions except for
        // the one the user used to change their password.
        // We accomplish this by seeing if the SessionTokenRefreshRequest was
        // made with the token that expired and sent back in this socket event.
        const tokenRefreshRequest = SessionTokenRefreshRequest.getRequest(
          getState(),
          {
            expiredToken: event.data.token,
          }
        );
        const requestWasMadeInThisClient = Request.success(tokenRefreshRequest);

        if (!requestWasMadeInThisClient) {
          return dispatch(deleteSession());
        }
        return;
      }

      case "pusher:subscription_succeeded":
        return;

      default:
        return dispatch(coreSocketMessageReceived(event));
    }
  };
}
