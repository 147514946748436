// @flow
import * as React from "react";
import DialogForm from "core/components/DialogForm";
import { BranchStatus } from "core/gitConstants";
import { isMaster } from "core/models/branch";
import type { Project, Branch, File, FileMode } from "core/types";
import connector from "./connector";

export type OwnProps = {|
  title: string,
  project: Project,
  branch: Branch,
  file: File,
  layerId?: string,
|};

export type StateProps = {|
  defaultBranchName: string,
|};

export type DispatchProps = {|
  openFile: ({
    projectId: string,
    branchId: string,
    fileId: string,
    sha: string,
    layerId?: string,
    fileMode?: FileMode,
  }) => void,
  showDialog: (name: string, props: Object) => void,
  dismissDialog: () => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

class OpenFile extends React.Component<Props> {
  static defaultProps = { title: "Open File" };

  showCreateBranchDialog = () => {
    this.props.showDialog("CreateBranch", {
      projectId: this.props.project.id,
      parentBranchId: this.props.branch.id,
      fileId: this.props.file.id,
      layerId: this.props.layerId,
    });
  };

  openReadOnly = () => {
    const { project, branch, file, layerId } = this.props;

    this.props.openFile({
      projectId: project.id,
      branchId: branch.id,
      fileId: file.id,
      sha: file.sha,
      layerId: layerId,
      fileMode: "READ_ONLY",
    });
    this.props.dismissDialog();
  };

  get reason(): string {
    const isArchived = this.props.branch.status === BranchStatus.ARCHIVED;
    const isMerged = this.props.branch.status === BranchStatus.MERGED;

    if (isMaster(this.props.branch)) {
      return `Abstract does not allow changes to be made directly on ${this.props.defaultBranchName}`;
    }
    if (isArchived) {
      return "You are opening a file from a branch that is archived";
    }
    if (isMerged) {
      return "You are opening a file from a branch that is already merged";
    }
    return "You are opening a file from a branch you didn’t create";
  }

  render() {
    const { file, branch, title } = this.props;
    const isHeadCommit = file.sha === branch.head;

    if (isHeadCommit) {
      return (
        <DialogForm
          title={title}
          primaryButton="Create a Branch"
          secondaryButton="Open Untracked"
          onSubmit={this.showCreateBranchDialog}
          onSecondary={this.openReadOnly}
          onClose={this.props.dismissDialog}
          isOpen
        >
          <p>
            {this.reason}. To edit this file please{" "}
            <strong>create a new branch</strong>.
          </p>
          <p>
            You can also open <strong>untracked</strong> if you just want to
            view the file – any changes you make in an untracked file cannot be
            committed to Abstract.
          </p>
        </DialogForm>
      );
    }

    return (
      <DialogForm
        title={title}
        primaryButton="Open untracked"
        onSubmit={this.openReadOnly}
        onClose={this.props.dismissDialog}
        isOpen
      >
        <p>
          You are opening <strong>{file.name}</strong>.{file.type} from an older
          commit.
        </p>
        <p>
          Currently, you can only open this file <strong>untracked</strong> -
          any changes you make in an untracked file cannot be committed to
          Abstract.
        </p>
      </DialogForm>
    );
  }
}

export default connector(OpenFile);
