// @flow
import classnames from "classnames";
import * as React from "react";
import Avatar, { type AvatarBorderColor } from "core/components/Avatar";
import Popover from "core/components/Popover";
import TeamAvatar from "core/components/TeamAvatar";
import type { User } from "../../types";
import style from "./style.scss";

type FacepileUser = {
  id: $PropertyType<User, "id">,
  name: $PropertyType<User, "name">,
  avatarUrl: $PropertyType<User, "avatarUrl">,
};

type Props = {|
  users: $ReadOnlyArray<FacepileUser>,
  avatarBorderColors?: { [userId: string]: AvatarBorderColor },
  tooltips?: { [userId: string]: string },
  size?: number,
  max?: number,
  className?: string,
  countColor?: string,
  teamIds?: string[],
  // the Total prop overrides the Facepile's total item count.
  total?: number,
  backgroundColor?: "blurple" | "transparent",
  hideNamePopover?: boolean,
|};

export default function Facepile({
  users,
  teamIds,
  avatarBorderColors = {},
  tooltips = {},
  size = 24,
  max = 6,
  className,
  countColor,
  total,
  backgroundColor,
  hideNamePopover,
}: Props) {
  const calculatedTotal = teamIds
    ? users.length + teamIds.length
    : users.length;
  const count = (total || calculatedTotal) - max + 1;
  const showCount = count > 1;

  if (showCount) {
    if (teamIds) {
      teamIds = teamIds.slice(0, max - 1);
      users =
        teamIds.length < max ? users.slice(0, max - teamIds.length - 1) : [];
    } else {
      users = users.slice(0, max - 1);
    }
  }

  const firstUser = users[0];
  const firstUserHasColor = firstUser
    ? !!avatarBorderColors[firstUser.id]
    : false;

  return (
    <div
      className={classnames(
        style.facepile,
        { [style.firstUserHasColor]: firstUserHasColor },
        className
      )}
    >
      {teamIds &&
        teamIds.map((teamId, index: number) => {
          return (
            <TeamAvatar
              key={teamId}
              teamId={teamId}
              showPopover
              size={size}
              extraStyle={{ marginLeft: index === 0 ? 0 : -size / 4 }}
            />
          );
        })}
      {users.map((user, index: number) => {
        const avatarColor = avatarBorderColors[user.id];
        const tooltip = tooltips[user.id];

        const isFirst =
          index === 0 && (!teamIds || (teamIds && teamIds.length === 0));
        const avatar = (
          <Avatar
            key={user.id}
            name={user.name}
            src={user.avatarUrl}
            userId={user.id}
            size={size}
            borderColor={avatarColor}
            className={classnames(style.avatar, {
              [style.avatarBackgroundBlurple]: backgroundColor === "blurple",
              [style.avatarBackgroundTransparent]:
                backgroundColor === "transparent",
            })}
            style={{
              marginLeft: isFirst || tooltip ? 0 : -size / 4,
            }}
            showNamePopover={!(tooltip || hideNamePopover)}
            backgroundColor={backgroundColor}
            qaSelector={`user-facepile-${index}`}
          />
        );

        if (tooltip) {
          return (
            <Popover key={user.id} placement="bottom" label={tooltip}>
              <span
                className={style.popoverTarget}
                style={{
                  height: size,
                  minWidth: size,
                  marginLeft: isFirst ? 0 : -size / 4,
                }}
              >
                {avatar}
              </span>
            </Popover>
          );
        }

        return avatar;
      })}
      {showCount && (
        <span
          style={{
            height: size,
            minWidth: size,
            marginLeft: -size / 4,
            borderRadius: size / 4,
            background: countColor,
          }}
          className={classnames(style.bubble, {
            [style.bubbleBackgroundBlurple]: backgroundColor === "blurple",
          })}
        >
          +{count}
        </span>
      )}
    </div>
  );
}
