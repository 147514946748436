// @flow
import * as Abstract from "abstract-sdk";
import * as React from "react";
import { connect } from "react-redux";
import type { RouterProps } from "core/lib/router";
import {
  WebhookDeleteRequest,
  WebhookDeliveriesFetchRequest,
  WebhookEventsFetchRequest,
  WebhookFetchRequest,
  WebhookRedeliverRequest,
  WebhookTestRequest,
  WebhookUpdateRequest,
} from "core/requests/webhooks";
import {
  getWebhook,
  getWebhookDeliveries,
  getWebhookDelivery,
  getWebhookEvents,
} from "core/selectors/webhooks";
import type {
  Dispatch,
  State,
  ThunkAction,
  Webhook,
  WebhookDelivery,
  WebhookDeliveryDescriptor,
  WebhookDescriptor,
  WebhookEvent,
} from "core/types";
import WebhookOverview from "web/components/WebhookOverview";

type OwnProps = {|
  children: React.Node,
|};

type OwnLocationProps = {|
  ...RouterProps,
  ...OwnProps,
|};

type DispatchProps = {|
  deleteWebhook: (params: WebhookDescriptor) => Promise<void>,
  fetchWebhook: (params: WebhookDescriptor) => void,
  fetchWebhookDeliveries: (params: WebhookDescriptor) => void,
  fetchWebhookEvents: (params: Abstract.OrganizationDescriptor) => void,
  redeliverWebhook: (params: WebhookDeliveryDescriptor) => void,
  testWebhook: (params: WebhookDescriptor) => void,
  updateWebhook: (params: Webhook) => ThunkAction,
|};

type StateProps = {|
  deleteWebhookError: boolean,
  deleteWebhookLoading: boolean,
  fetchWebhookLoading: boolean,
  organizationId: string,
  redeliveryLoading: boolean,
  testWebhookError: boolean,
  testWebhookLoading: boolean,
  updateWebhookError: boolean,
  updateWebhookLoading: boolean,
  webhook: Webhook,
  webhookDeliveries: WebhookDelivery[],
  webhookDelivery: WebhookDelivery,
  webhookDeliveryId: string,
  webhookEvents: WebhookEvent[],
  webhookId: string,
|};

export type Props = {
  ...OwnLocationProps,
  ...StateProps,
  ...DispatchProps,
};

function mapStateToProps(state: State, props: OwnLocationProps): StateProps {
  const { organizationId, webhookDeliveryId, webhookId } = props.params;
  const webhookArgs = [state, { organizationId, webhookId }];
  const deliveryArgs = [
    state,
    {
      deliveryId: webhookDeliveryId,
      organizationId,
      webhookId,
    },
  ];

  return {
    deleteWebhookError: WebhookDeleteRequest.hasError(...webhookArgs),
    deleteWebhookLoading: WebhookDeleteRequest.isLoadingStrict(...webhookArgs),
    fetchWebhookLoading:
      WebhookDeliveriesFetchRequest.isLoading(...webhookArgs) ||
      WebhookEventsFetchRequest.isLoading(state, { organizationId }) ||
      WebhookFetchRequest.isLoading(...webhookArgs),
    organizationId,
    redeliveryLoading: WebhookRedeliverRequest.isLoadingStrict(...deliveryArgs),
    testWebhookError: WebhookTestRequest.hasError(...webhookArgs),
    testWebhookLoading: WebhookTestRequest.isLoadingStrict(...webhookArgs),
    updateWebhookError: WebhookUpdateRequest.hasError(...webhookArgs),
    updateWebhookLoading: WebhookUpdateRequest.isLoadingStrict(...webhookArgs),
    webhook: getWebhook(...webhookArgs),
    webhookDeliveries: getWebhookDeliveries(...webhookArgs),
    webhookDelivery: getWebhookDelivery(...deliveryArgs),
    webhookDeliveryId,
    webhookEvents: getWebhookEvents(state, { organizationId }),
    webhookId,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnLocationProps
): DispatchProps {
  return {
    deleteWebhook: (params: WebhookDescriptor) => {
      return dispatch(WebhookDeleteRequest.perform({ params }));
    },
    fetchWebhook: (params: WebhookDescriptor) => {
      dispatch(WebhookFetchRequest.perform({ params }));
    },
    fetchWebhookDeliveries: (params: WebhookDescriptor) => {
      dispatch(WebhookDeliveriesFetchRequest.perform({ params }));
    },
    fetchWebhookEvents: (params: Abstract.OrganizationDescriptor) => {
      dispatch(WebhookEventsFetchRequest.perform({ params }));
    },
    redeliverWebhook: (params: WebhookDeliveryDescriptor) => {
      dispatch(WebhookRedeliverRequest.perform({ params }));
    },
    testWebhook: (params: WebhookDescriptor) => {
      dispatch(WebhookTestRequest.perform({ params }));
    },
    updateWebhook: (webhook: Webhook) => {
      const params = {
        ...webhook,
        webhookId: webhook.id,
      };
      return dispatch(WebhookUpdateRequest.perform({ params }));
    },
  };
}

export const connector = connect<
  Props,
  OwnLocationProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);

export default connector(WebhookOverview);
