// @flow
import * as React from "react";
import connector from "./connector";

export type OwnProps = {|
  children: React.Node,
|};

export type StateProps = {|
  isLoggedIn: boolean,
|};

export type DispatchProps = {|
  loadSession: () => void,
  loadOrganizations: () => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

class LoadSession extends React.Component<Props> {
  componentDidMount() {
    this.loadSession();
  }

  /* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
   * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
  componentDidUpdate(prevProps) {
    // This is kind of silly, but on logout, we clear the previous requests for
    // the session. However, our system also relies upon the assumption that a
    // request for the session has been made, even if it was unsuccessful. So we
    // need to make sure that an attempt to load the session has been made, even
    // if it was unsuccessful.
    if (prevProps.isLoggedIn && !this.props.isLoggedIn) {
      this.loadSession();
    }
  }

  loadSession() {
    if (!this.props.isLoggedIn) {
      this.props.loadSession();
    }
    this.props.loadOrganizations();
  }

  render() {
    return this.props.children;
  }
}

export default connector(LoadSession);
