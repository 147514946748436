// @flow
import empty from "empty";
import { connect } from "react-redux";
import { getCurrentUserId } from "abstract-di/selectors";
import { ProjectsFetchRequest } from "core/requests/projects";
import { OrganizationReviewsFetchRequest } from "core/requests/reviews";
import { StarsFetchRequest } from "core/requests/stars";
import { enterpriseReportingEnabled } from "core/selectors/features";
import { DEFAULT_POLICY, getOrganizationPolicy } from "core/selectors/policies";
import { getFilteredProjects } from "core/selectors/projects";
import { isStarred } from "core/selectors/stars";
import type { State, Dispatch } from "core/types";
import type { OwnProps, StateProps, Props } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const currentUserId = getCurrentUserId(state);

  const organizationId = props.organization ? props.organization.id : undefined;

  const organizationPolicy = organizationId
    ? getOrganizationPolicy(state, {
        organizationId,
      })
    : DEFAULT_POLICY;

  return {
    canShowReporting: organizationId
      ? enterpriseReportingEnabled(state, organizationId) &&
        organizationPolicy.showInsights
      : false,
    canShowReviews: organizationPolicy.showReviews,
    currentUserId,
    favoriteProjects: organizationId
      ? getFilteredProjects(state, {
          organizationId,
          filter: "starred",
        })
      : empty.array,
    isFavoriteProjectsLoading: organizationId
      ? ProjectsFetchRequest.isLoading(state, {
          organizationId,
          filter: "active",
        }) || StarsFetchRequest.isLoading(state, {})
      : true,
    areBranchesReviewsLoading: organizationId
      ? OrganizationReviewsFetchRequest.isLoadingStrict(state, {
          organizationId,
        })
      : false,
    showCurrentProject:
      !StarsFetchRequest.isLoading(state, {}) && props.currentProject
        ? !isStarred(state, { starrableId: props.currentProject.id })
        : false,
  };
}

export default connect<Props, OwnProps, StateProps, _, State, Dispatch>(
  mapStateToProps
);
