// @flow
import { connect } from "react-redux";
import { BranchesFetchAllRequest } from "core/requests/branches";
import { getNestedBranches } from "core/selectors/branches";
import { getReviewRequests } from "core/selectors/reviews";
import type { State, Dispatch } from "core/types";
import type { OwnProps, StateProps, Props } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const projectId = props.project.id;

  return {
    branches: getNestedBranches(state, {
      projectId,
      branchFilter: "active",
    }),
    isLoading: BranchesFetchAllRequest.isLoading(state, {
      projectId,
      filter: "active",
    }),
    reviewRequests: getReviewRequests(state, { projectId }),
  };
}

export default connect<Props, OwnProps, StateProps, _, State, Dispatch>(
  mapStateToProps
);
