// @flow
import idx from "idx";
import { some } from "lodash";
import { createSelector } from "reselect";
import { isWeb } from "core/lib/platform";
import { getProject } from "core/selectors/projects";
import type { AnyDescriptor, State } from "core/types";

function currentOrganizationId(state: State, props) {
  return props.organizationId;
}

function currentFeature(state: State, props) {
  return props.feature;
}

function getFeatures(state: State) {
  return state.features;
}

function getFeatureOverrides(state: State) {
  return state.devtools.featureOverrides;
}

export const getAllFeaturesEnabled: (state: State) => {
  [feature: string]: boolean,
} = createSelector(getFeatures, (featuresByOrganization) => {
  let output = {};

  for (const organizationId of Object.keys(featuresByOrganization)) {
    const org = featuresByOrganization[organizationId];
    if (!org) {
      continue;
    }
    output = {
      ...output,
      ...org.features,
    };
  }

  return output;
});

export const getFeatureEnabled: (
  State,
  {| feature: string, organizationId?: string |}
) => boolean = createSelector(
  getFeatures,
  getFeatureOverrides,
  currentOrganizationId,
  currentFeature,
  (featuresByOrganization, featureOverrides, organizationId, key) => {
    if (typeof featureOverrides[key] === "boolean") {
      return featureOverrides[key];
    }

    if (organizationId) {
      const org = featuresByOrganization[organizationId];
      if (!org) {
        return false;
      }

      return idx(org, (_) => _.features[key]) || false;
    }

    return some(featuresByOrganization, (org) =>
      idx(org, (_) => _.features[key])
    );
  }
);

export function getProjectFeatureEnabled(
  state: State,
  params: {| feature: string, projectId: string |}
) {
  const project = getProject(state, params);
  if (!project) {
    return false;
  }

  return getFeatureEnabled(state, {
    organizationId: project.organizationId,
    feature: params.feature,
  });
}

export function canHideLibrarySymbols(
  state: State,
  { projectId }: { projectId: string }
) {
  return getProjectFeatureEnabled(state, {
    projectId,
    feature: "hide-library-symbol-conflicts",
  });
}

export function canCreateCollectionsAnywhere(
  state: State,
  { projectId }: { projectId: string }
) {
  return getProjectFeatureEnabled(state, {
    projectId,
    feature: "lenient-collection-creation",
  });
}

export function canUseTransportPriority(
  state: State,
  { projectId }: { projectId: string }
) {
  return getProjectFeatureEnabled(state, {
    projectId,
    feature: "api-transport-priority",
  });
}

export function canOrganizationUsePartialSync(
  state: State,
  { organizationId }: { organizationId: string }
) {
  return (
    getFeatureEnabled(state, {
      organizationId,
      feature: "api-transport-priority",
    }) &&
    getFeatureEnabled(state, {
      organizationId,
      feature: "partial-sync",
    })
  );
}

export function canUsePartialSync(
  state: State,
  { projectId }: { projectId: string }
) {
  // Because api-transport-priority must also be enabled for partial sync to
  // work correctly we ensure that's the case here.
  return (
    canUseTransportPriority(state, { projectId }) &&
    getProjectFeatureEnabled(state, {
      projectId,
      feature: "partial-sync",
    })
  );
}

export function canUseAPIExportOpenUntracked(
  state: State,
  { projectId }: { projectId: string }
) {
  return getProjectFeatureEnabled(state, {
    projectId,
    feature: "api-export-open-untracked",
  });
}

export function canUseCommitOnly(
  state: State,
  { projectId }: { projectId: string }
) {
  return getProjectFeatureEnabled(state, { projectId, feature: "commit-only" });
}

export function canSeePartialSyncAnnouncement(
  state: State,
  { organizationId }: { organizationId: string }
) {
  // Because api-transport-priority must also be enabled for partial sync to
  // work correctly we ensure that's the case here.
  return (
    getFeatureEnabled(state, {
      organizationId,
      feature: "api-transport-priority",
    }) &&
    getFeatureEnabled(state, {
      organizationId,
      feature: "partial-sync",
    }) &&
    getFeatureEnabled(state, {
      organizationId,
      feature: "partial-sync-announcement",
    })
  );
}

export function canUseNewBranchStatuses(state: State, projectId: string) {
  return getProjectFeatureEnabled(state, {
    projectId,
    feature: "new-branch-statuses",
  });
}

export function canUseUIProfiler(state: State, descriptor: AnyDescriptor) {
  if (descriptor.shareId) {
    // Always enable profiler for public share routes
    return true;
  }

  if (descriptor.organizationId) {
    return getFeatureEnabled(state, {
      organizationId: descriptor.organizationId,
      feature: "ui-profiler",
    });
  }

  if (descriptor.projectId) {
    return getProjectFeatureEnabled(state, {
      projectId: descriptor.projectId,
      feature: "ui-profiler",
    });
  }

  return getFeatureEnabled(state, {
    feature: "ui-profiler",
  });
}

export function canUsePluginForPreviews(state: State, projectId: string) {
  return (
    getProjectFeatureEnabled(state, {
      projectId,
      feature: "plugin-preview-generation",
    }) &&
    !getProjectFeatureEnabled(state, {
      projectId,
      feature: "tool-preview-generation",
    })
  );
}

export function canAutoRepack(state: State, projectId: string) {
  return getProjectFeatureEnabled(state, {
    projectId,
    feature: "auto-repack-92",
  });
}

export function canUseAbstractdForFiles(state: State, projectId: string) {
  return getProjectFeatureEnabled(state, {
    projectId,
    feature: "abstract-daemon",
  });
}

export function canUseAbstractdForProjects(state: State, projectId: string) {
  return getProjectFeatureEnabled(state, {
    projectId,
    feature: "abstractd-for-projects",
  });
}

export function canUseCachedFileChanges(state: State, projectId: string) {
  return getProjectFeatureEnabled(state, {
    projectId,
    feature: "cached-file-changes",
  });
}

export function deleteBranchUsingCLI(state: State, projectId: string) {
  return getProjectFeatureEnabled(state, {
    projectId,
    feature: "cli-delete-branch",
  });
}

export function canMergeBranchUsingCLI(state: State, projectId: string) {
  return getProjectFeatureEnabled(state, {
    projectId,
    feature: "cli-merge-branch-91",
  });
}

export function createDivergedBranchUsingCLI(state: State, projectId: string) {
  return getProjectFeatureEnabled(state, {
    projectId,
    feature: "cli-create-diverged-branches",
  });
}

export function mergeRestrictionsEnabled(state: State, projectId: string) {
  return getProjectFeatureEnabled(state, {
    projectId,
    feature: "merge-restrictions",
  });
}

export function mergeRestrictionsCTAEnabled(state: State, projectId: string) {
  return getProjectFeatureEnabled(state, {
    projectId,
    feature: "merge-restrictions-cta",
  });
}

export function illustratorSupported(state: State, projectId: string) {
  return getProjectFeatureEnabled(state, {
    projectId,
    feature: "illustrator-support",
  });
}

export function featureEnabledFileFormats(
  state: State,
  projectId: string
): string[] {
  const featureEnabledFormats = [];
  if (illustratorSupported(state, projectId)) {
    featureEnabledFormats.push("illustrator");
  }
  return featureEnabledFormats;
}

export function getReleaseChannel(state: State) {
  if (getFeatureEnabled(state, { feature: "internal-builds" })) {
    return "internal";
  }
  if (getFeatureEnabled(state, { feature: "beta-builds" })) {
    return "beta";
  }
  return "production";
}

export function seatCapEnabled(state: State, organizationId: string) {
  return getFeatureEnabled(state, {
    organizationId: organizationId,
    feature: "non-enterprise-seat-cap-10",
  });
}

export function enterpriseReportingEnabled(
  state: State,
  organizationId: string
) {
  return getFeatureEnabled(state, {
    organizationId: organizationId,
    feature: "enterprise-insights",
  });
}

export function enterpriseTeamsEnabled(
  state: State,
  { organizationId }: { organizationId: string }
) {
  return getFeatureEnabled(state, {
    organizationId,
    feature: "teams-v1-ui",
  });
}

export function teamsAnnouncementEnabled(state: State, organizationId: string) {
  return (
    getFeatureEnabled(state, {
      organizationId,
      feature: "teams-announcement",
    }) && enterpriseTeamsEnabled(state, { organizationId })
  );
}

export function canUseExistingPreviewsForMerge(
  state: State,
  projectId: string
) {
  return getProjectFeatureEnabled(state, {
    projectId,
    feature: "merge-commits-use-existing-previews",
  });
}

export function canShowLargeCommitWarning(state: State, projectId: string) {
  return getProjectFeatureEnabled(state, {
    projectId,
    feature: "large-commit-warning",
  });
}

export function canUseSDKMetrics(state: State) {
  return getFeatureEnabled(state, { feature: "sdk-metrics" });
}

export function canUseCLICheckoutBranch(state: State, projectId: string) {
  return getProjectFeatureEnabled(state, {
    projectId,
    feature: "cli-checkout-branch",
  });
}

export function canUseBackgroundPreviewSwitcher(
  state: State,
  projectId: string
) {
  return getProjectFeatureEnabled(state, {
    projectId,
    feature: "preview-background-switcher",
  });
}

export function canUseBranchDrafts(state: State, projectId: string) {
  const useCLICheckout = canUseCLICheckoutBranch(state, projectId);
  return (
    useCLICheckout &&
    getProjectFeatureEnabled(state, {
      projectId,
      feature: "branch-drafts",
    })
  );
}

export function canUseDesignHistory(state: State, organizationId: string) {
  return getFeatureEnabled(state, {
    organizationId: organizationId,
    feature: "design-history",
  });
}

export function canUseDesignHistoryComments(
  state: State,
  organizationId: string
) {
  return getFeatureEnabled(state, {
    organizationId: organizationId,
    feature: "design-history-comments",
  });
}

export function canSkipLinkedSymbolPreviews(state: State, projectId: string) {
  return (
    canHideLibrarySymbols(state, { projectId }) &&
    getProjectFeatureEnabled(state, {
      projectId,
      feature: "skip-linked-symbol-previews",
    })
  );
}

export function canUseGoGitToPush(state: State, projectId: string) {
  return getProjectFeatureEnabled(state, {
    projectId,
    feature: "cli-push-gogit",
  });
}

export function canSkipUpdateFlowCommitPhase(state: State, projectId: string) {
  return getProjectFeatureEnabled(state, {
    projectId,
    feature: "update-flow-no-commit-step",
  });
}

export function canUseCustomEmojis(state: State, organizationId: string) {
  return getFeatureEnabled(state, {
    organizationId: organizationId,
    feature: "custom-emojis",
  });
}

export function canUseAutoProjectsCleanup(state: State) {
  return (
    getFeatureEnabled(state, {
      feature: "auto-projects-cleanup",
    }) &&
    getFeatureEnabled(state, {
      feature: "api-transport-priority",
    }) &&
    getFeatureEnabled(state, {
      feature: "partial-sync",
    })
  );
}

export function canAccessAbstractWorkflowTutorial(state: State) {
  return getFeatureEnabled(state, {
    feature: "abstract-workflow-tutorial",
  });
}

export function canUseGitGCWithProjectsCleanup(state: State) {
  return getFeatureEnabled(state, { feature: "projects-cleanup-git-gc" });
}

export function canDisableAutoUpdates(state: State) {
  return getFeatureEnabled(state, { feature: "auto-update-disabled" });
}

export function canUseWebProjectCreation(state: State, organizationId: string) {
  return (
    isWeb &&
    getFeatureEnabled(state, {
      feature: "web-project-creation",
      organizationId,
    })
  );
}

export function canUseSketchFileConcurrency(state: State, projectId: string) {
  return getProjectFeatureEnabled(state, {
    projectId,
    feature: "sketch-file-concurrency",
  });
}

export function canLinkBackToFigma(state: State) {
  return getFeatureEnabled(state, {
    feature: "link-back-to-figma",
  });
}

export function canUseNewDefaultBranchName(state: State) {
  return getFeatureEnabled(state, {
    feature: "master-to-main",
  });
}

export function canInstallSlackV2Integration(
  state: State,
  organizationId: string
) {
  return getFeatureEnabled(state, {
    feature: "slack-v2",
    organizationId,
  });
}

export function canEnterpriseSeeNextBillAmount(
  state: State,
  organizationId: string
) {
  return getFeatureEnabled(state, {
    feature: "enterprise-can-see-next-bill-amount",
    organizationId,
  });
}

export function canEnterpriseCancelOrDeleteBilling(
  state: State,
  organizationId: string
) {
  return !getFeatureEnabled(state, {
    feature: "hide-enterprise-cancel-and-delete-buttons",
    organizationId,
  });
}
