// @flow
import * as React from "react";
import ActivityBase from "./Base";
import Callout from "./Callout";
import type {
  ActivityReviewerRemoved as TActivityReviewerRemoved,
  Props,
} from "./types";

function action(activity: TActivityReviewerRemoved) {
  const reviewerName =
    activity.userId === activity.payload.reviewerId ? (
      "themselves"
    ) : (
      <Callout>{activity.payload.reviewerName}</Callout>
    );
  return <span>removed {reviewerName} as a reviewer from the branch</span>;
}

export default function ActivityReviewerRemoved(
  props: Props<TActivityReviewerRemoved>
) {
  const { activity, context } = props;
  return (
    <ActivityBase
      {...props}
      icon="close"
      branchId={activity.branchId}
      branchName={activity.payload.branchName}
      action={action(activity)}
      context={context}
      subject={
        context.branchId ? null : (
          <Callout>{activity.payload.branchName}</Callout>
        )
      }
      when={activity.createdAt}
    />
  );
}
