// @flow
import classnames from "classnames";
import format from "date-fns/format";
import { sortBy } from "lodash";
import pluralize from "pluralize";
import * as React from "react";
import Avatar from "core/components/Avatar";
import Button from "core/components/Button";
import ButtonRightIcon from "core/components/ButtonRightIcon";
import Card from "core/components/Card";
import Flex from "core/components/Flex";
import FormSection from "core/components/FormSection";
import Heading from "core/components/Heading";
import InputSearch from "core/components/InputSearch";
import Loaded from "core/components/Loaded";
import SettingsForm from "core/components/SettingsForm";
import SettingsItem from "core/components/SettingsItem";
import window from "core/global/window";
import { Abstract } from "core/lib/abstract";
import { replace } from "core/lib/location";
import matchString from "core/lib/matchString";
import type { User, Emoji } from "core/types";
import { organizationEmojisAdmin } from "web/routeHelpers";
import AddEmojiModal from "./AddEmojiModal";
import EmptyEmojis from "./Empty";
import NoneFound from "./NoneFound";
import connector from "./connector";
import style from "./style.scss";

type SortTerm = "name" | "authorName" | "createdAt";

export type OwnProps = {|
  params: Abstract.OrganizationDescriptor,
|};

export type StateProps = {|
  emojis: Emoji[],
  userMap: { [userId: string]: User },
  currentUser: ?User,
  currentUserIsAdmin: boolean,
  isLoading: boolean,
|};

export type DispatchProps = {|
  onLoad: () => void,
  onCreateEmoji: (params: { name: string, image: string }) => void,
  onDeleteEmoji: (emoji: Emoji) => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

const SORTBAR_ITEMS = [
  {
    name: "name",
    sortTerm: "name",
    className: style.emojiNameColumn,
  },
  {
    name: "added by",
    sortTerm: "authorName",
    className: style.authorColumn,
  },
  {
    name: "date",
    sortTerm: "createdAt",
    className: style.dateColumn,
  },
];

const defaultSortMap = {
  name: undefined,
  authorName: undefined,
  createdAt: undefined,
};

function Emojis(props: Props) {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [searchFilter, setSearchFilter] = React.useState("");
  const [sortTerm, setSortTerm] = React.useState("name");
  const [sortMap, setSortMap] = React.useState({
    ...defaultSortMap,
    name: true,
  });

  React.useEffect(() => {
    if (props.currentUserIsAdmin) {
      replace(organizationEmojisAdmin(props.params.organizationId));
    }
  }, [props.currentUserIsAdmin, props.params.organizationId]);

  const showModal = (event: SyntheticEvent<>) => {
    setModalOpen(true);
  };

  const hideModal = (event: SyntheticEvent<>) => {
    setModalOpen(false);
  };

  const addEmoji = async (name, image) => {
    await props.onCreateEmoji({ name, image });
  };

  const handleDeleteEmoji =
    (emoji: Emoji) => async (event: SyntheticEvent<>) => {
      const confirmed = window.confirm(
        "This emoji will be deleted permanently. Are you sure?"
      );
      if (!confirmed) {
        return;
      }
      await props.onDeleteEmoji(emoji);
    };

  const renderEmojiItem = (emoji: Emoji) => {
    const { userMap, currentUser, currentUserIsAdmin } = props;
    const user = userMap[emoji.userId];
    const deleteEnabled =
      (!!currentUser && currentUser.id === emoji.userId) || currentUserIsAdmin;

    return (
      <Card list key={emoji.name}>
        <Flex className={style.row} role="row">
          <Flex
            role="gridcell"
            aria-colindex="1"
            className={classnames(style.rowItem, style.emojiImgColumn)}
            align="center"
          >
            <div className={style.emojiWrapper}>
              <img
                className={style.emoji}
                src={emoji.imageUrl}
                alt={emoji.name}
              />
            </div>
          </Flex>
          <Flex
            role="gridcell"
            aria-colindex="2"
            className={classnames(style.rowItem, style.emojiNameColumn)}
          >
            <div className={classnames(style.emojiName, style.rowItemPadding)}>
              <span>:{emoji.name}:</span>
            </div>
          </Flex>
          <Flex
            role="gridcell"
            aria-colindex="3"
            align="center"
            className={classnames(style.rowItem, style.authorColumn)}
          >
            {user && (
              <Flex align="center" className={style.rowItemPadding}>
                <div className={style.avatarWrapper}>
                  <Avatar
                    className={style.emoji}
                    src={user.avatarUrl}
                    name={user.name}
                  />
                </div>
                <span className={style.authorName}>{user.name}</span>
              </Flex>
            )}
          </Flex>
          <Flex
            role="gridcell"
            aria-colindex="4"
            align="center"
            justify="space-between"
            className={classnames(style.rowItem, style.dateColumn)}
          >
            <Flex
              align="center"
              justify="space-between"
              className={style.rowItemPadding}
            >
              <span className={style.date}>
                {format(emoji.createdAt, "MMMM Do, YYYY")}
              </span>
              <Button
                nude
                disabled={!deleteEnabled}
                icon={deleteEnabled ? "trash" : "lock-locked"}
                onClick={handleDeleteEmoji(emoji)}
                title={
                  deleteEnabled
                    ? undefined
                    : "You’ll need to ask an admin delete this emoji"
                }
                tooltip={{ placement: "top" }}
              />
            </Flex>
          </Flex>
        </Flex>
      </Card>
    );
  };

  const onSort = (name: string, sortTerm: SortTerm) => {
    setSortMap({
      ...defaultSortMap,
      [sortTerm]: !sortMap[sortTerm],
    });
    setSortTerm(sortTerm);
  };

  const renderSortbarItem = (
    name: string,
    className: string,
    selectedSortTerm: SortTerm
  ) => {
    const hasIcon = sortTerm === selectedSortTerm;
    const arrow = sortMap[selectedSortTerm] ? "up" : "down";

    return (
      <Flex
        role="gridcell"
        className={classnames(style.rowItem, className)}
        align="center"
        key={name}
      >
        <ButtonRightIcon
          nude
          noMargin
          onClick={() => onSort(name, selectedSortTerm)}
          className={classnames(style.sortButton, style.headerItemPadding, {
            [style.noIcon]: !hasIcon,
          })}
          icon={hasIcon ? `arrow-large-${arrow}` : undefined}
        >
          <span role="columnheader" className={style.legendItem}>
            {name}
          </span>
        </ButtonRightIcon>
      </Flex>
    );
  };

  const onSearchChange = (event: SyntheticInputEvent<>) => {
    const { value: searchFilter } = event.target;
    setSearchFilter(searchFilter);
  };

  const { emojis, isLoading, userMap } = props;
  const noEmojis = emojis.length === 0;

  let filteredEmojis = emojis;

  if (searchFilter) {
    filteredEmojis = emojis.filter((emoji) => {
      return matchString(emoji.name, searchFilter);
    });
  }

  let sortedEmojis = sortBy(filteredEmojis, (emoji) => {
    return sortTerm === "authorName"
      ? userMap[emoji.userId].name
      : emoji[sortTerm];
  });

  sortedEmojis = sortMap[sortTerm] ? sortedEmojis : sortedEmojis.reverse();

  return (
    <Loaded loading={isLoading} title="Loading emojis…">
      <SettingsForm
        documentTitle="Emojis"
        className={style.settingsForm}
        noExtraPadding
      >
        <FormSection
          role="grid"
          colCount="4"
          className={classnames(style.formSection, {
            [style.empty]: noEmojis,
          })}
          cardClassName={style.emptyCard}
        >
          {noEmojis ? (
            <EmptyEmojis showModal={showModal} />
          ) : (
            <React.Fragment>
              <SettingsItem role="row">
                <Heading level="3" size="l">
                  {emojis.length} custom {pluralize("emoji", emojis.length)}
                </Heading>
                <Flex align="center" justify="flex-end">
                  <InputSearch
                    className={style.inputSearch}
                    placeholder="Filter custom emojis…"
                    defaultValue={searchFilter}
                    onChange={onSearchChange}
                  />
                  <Button
                    primary
                    className={style.addEmojiButton}
                    onClick={showModal}
                  >
                    Add custom emoji
                  </Button>
                </Flex>
              </SettingsItem>
              <SettingsItem className={style.headerLegend}>
                <div
                  className={classnames(style.rowItem, style.emojiImgColumn)}
                />
                {SORTBAR_ITEMS.map((item) => {
                  return renderSortbarItem(
                    item.name,
                    item.className,
                    item.sortTerm
                  );
                })}
              </SettingsItem>
              {filteredEmojis.length === 0 ? (
                <NoneFound termNotFound={searchFilter} />
              ) : (
                sortedEmojis.map((emoji) => {
                  return renderEmojiItem(emoji);
                })
              )}
            </React.Fragment>
          )}
        </FormSection>
      </SettingsForm>
      {isModalOpen && (
        <AddEmojiModal
          isOpen={isModalOpen}
          hideModal={hideModal}
          contentLabel="Add Custom Emoji"
          addEmoji={addEmoji}
        />
      )}
    </Loaded>
  );
}

export default connector(Emojis);
