// @flow
import { memoize, keys, find, findIndex, findLastIndex } from "lodash";
import { connect } from "react-redux";
import {
  entitiesSelected,
  entitiesDeselected,
} from "core/actions/collectionMultiselect";
import * as File from "core/models/file";
import * as Layer from "core/models/layer";
import * as Page from "core/models/page";
import { getMultiSelectedEntities } from "core/selectors/collectionMultiselect";
import { getCanCreateBranchCollections } from "core/selectors/collections";
import type {
  State,
  Dispatch,
  Layer as TLayer,
  MultiSelectedEntities,
} from "core/types";

type OwnProps = {
  projectId: string,
  branchId: string,
  isSelecting?: boolean,
};

function layerSelected(layer: TLayer, entities?: ?MultiSelectedEntities) {
  const params = {
    layerId: layer.id,
    fileId: layer.fileId,
    pageId: layer.pageId,
    projectId: layer.projectId,
    sha: layer.sha,
  };

  return !!(
    entities &&
    (entities.files[File.uniqueId(params)] ||
      entities.pages[Page.uniqueId(params)] ||
      entities.layers[Layer.uniqueId(params)])
  );
}

const getSelectionProps = memoize((entities) => {
  return {
    layerSelected: (layer) => layerSelected(layer, entities),
    pageSelected: (page) => {
      const params = {
        fileId: page.fileId,
        pageId: page.id,
        projectId: page.projectId,
        sha: page.sha,
      };

      return !!(
        entities &&
        (entities.files[File.uniqueId(params)] ||
          entities.pages[Page.uniqueId(params)])
      );
    },
    fileSelected: (file) => {
      return !!(entities && entities.files[File.uniqueId(file)]);
    },
    pagePartiallySelected: (page) => {
      return !!(
        entities &&
        find(entities.layers, {
          fileId: page.fileId,
          pageId: page.id,
          sha: page.sha,
        })
      );
    },
    filePartiallySelected: (file) => {
      return !!(
        entities && find(entities.layers, { fileId: file.id, sha: file.sha })
      );
    },
  };
});

function mapStateToProps(state: State, props: OwnProps) {
  const { projectId, branchId, isSelecting } = props;
  const entities = getMultiSelectedEntities(state, { projectId, branchId });

  const selectedCount = entities
    ? keys(entities.files).length +
      keys(entities.pages).length +
      keys(entities.layers).length
    : 0;

  return {
    selectedEntities: entities,
    selectedCount,
    isSelecting: !!entities || isSelecting,
    canSelect: getCanCreateBranchCollections(state, {
      projectId,
      branchId,
    }),
    ...getSelectionProps(entities),
  };
}

function mapDispatchToProps(dispatch: Dispatch, props: OwnProps) {
  const { projectId, branchId } = props;

  return {
    onSelectEntities: (payload) => {
      const { selected, ...rest } = payload;
      const params = { projectId, branchId, ...rest };

      if (selected) {
        return dispatch(entitiesDeselected(params));
      }
      dispatch(entitiesSelected(params));
    },
    onSelectLayerRange: (layer, layers, entities, selected) => {
      const selectedIndex = findIndex(layers, layer);
      const firstSelectedIndex = findIndex(layers, (l) =>
        layerSelected(l, entities)
      );
      const lastSelectedIndex = findLastIndex(layers, (l) =>
        layerSelected(l, entities)
      );

      const params = { projectId, branchId, layers: [layer] };

      if (selected) {
        params.layers = layers.slice(selectedIndex, lastSelectedIndex + 1);
        return dispatch(entitiesDeselected(params));
      }

      if (selectedIndex < firstSelectedIndex) {
        params.layers = layers.slice(selectedIndex, firstSelectedIndex);
      } else if (selectedIndex < lastSelectedIndex) {
        params.layers = layers.slice(firstSelectedIndex, selectedIndex + 1);
      } else if (lastSelectedIndex > -1) {
        params.layers = layers.slice(lastSelectedIndex, selectedIndex + 1);
      }

      dispatch(entitiesSelected(params));
    },
  };
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default connect(mapStateToProps, mapDispatchToProps);
