// @flow
import * as React from "react";
import Banner from "core/components/Banner";
import Loaded from "core/components/Loaded";
import style from "./style.scss";

type Props = {
  children: React.Node,
  loading: boolean,
  success: boolean,
  error: boolean,
  successMessage: string,
  errorMessage: string,
};

function Tab(props: Props) {
  return (
    <Loaded loading={props.loading}>
      <div className={style.bannerContainer}>
        {props.success && (
          <Banner
            variant="success"
            message={props.successMessage}
            closeable={false}
            autoCloseAfter={3000}
          />
        )}
        {props.error && (
          <Banner
            variant="error"
            message={props.errorMessage}
            closeable={false}
            autoCloseAfter={3000}
          />
        )}
      </div>
      <div className={style.contents}>{props.children}</div>
    </Loaded>
  );
}

export default Tab;
