// @flow
import { EventTypes } from "redux-segment";
import { stopSyncingProject } from "abstract-di/actions";
import {
  archiveProject as archiveProjectDI,
  unarchiveProject as unarchiveProjectDI,
  deleteProject as deleteProjectDI,
} from "abstract-di/actions/projects";
import { entitiesReceived } from "core/actions/entities";
import { clearProjectMemberships } from "core/actions/projectMemberships";
import { showToast } from "core/actions/toasts";
import * as location from "core/lib/location";
import { organizationProjectsPath } from "core/lib/routes";
import { ProjectMembershipsFetchAllRequest } from "core/requests/projectMemberships";
import {
  ProjectFetchRequest,
  ProjectUpdateRequest,
  ProjectArchiveRequest,
  ProjectUnarchiveRequest,
  ProjectDeleteRequest,
  TransferProjectRequest,
} from "core/requests/projects";
import {
  normalizeProjectsResponse,
  normalizeUpdateProjectResponse,
  normalizeProject,
} from "core/schemas/project";
import { getOrganization } from "core/selectors/organizations";
import { getProject } from "core/selectors/projects";
import type { ThunkAction, Action, Project, Section, Policy } from "core/types";

export const updateProjectEvent = () => ({
  type: "core/PROJECT_UPDATE",
  meta: {
    analytics: {
      eventType: EventTypes.track,
      eventPayload: { event: "PROJECT_UPDATE" },
    },
  },
});

export const updatedProjectEvent = () => ({
  type: "core/PROJECT_UPDATED",
  meta: {
    analytics: {
      eventType: EventTypes.track,
      eventPayload: { event: "PROJECT_UPDATED" },
    },
  },
});

type ProjectsResponse = {
  policies: Policy[],
  data: {
    projects: Project[],
    sections: Section[],
  },
};

export function loadedProjectsResponse(response: ProjectsResponse): Action {
  return entitiesReceived(normalizeProjectsResponse(response).entities);
}

export function updatedProjectFromSocket(project: Project): ThunkAction {
  return async (dispatch, getState) => {
    const projectId = project.id;
    const existingProject = getProject(getState(), { projectId });
    const visibilityChanged =
      !!existingProject && existingProject.visibility !== project.visibility;
    const projectArchived =
      !!existingProject && !existingProject.archivedAt && project.archivedAt;

    if (visibilityChanged) {
      // re-fetch the project to reload policies and check we still have access
      await dispatch(
        ProjectFetchRequest.perform({
          params: { projectId },
          force: true,
          onSuccess() {
            // if we still have access, refetch project memberships. Clear the
            // request first rather than forcing so that the full loading state
            // is triggered if you're looking at the memberships list
            dispatch(clearProjectMemberships(project.id));
            dispatch(ProjectMembershipsFetchAllRequest.clear({ projectId }));
          },
        })
      );
      return;
    }

    if (projectArchived && stopSyncingProject) {
      dispatch(stopSyncingProject(projectId, true));
    }

    const { entities } = normalizeProject(project);
    dispatch(entitiesReceived(entities));
  };
}

export function updatedProject(response: ProjectsResponse) {
  return entitiesReceived(normalizeUpdateProjectResponse(response).entities);
}

export function updateProject(
  project: $Shape<Project>,
  onSuccess?: ({ data: Project }) => void,
  onError?: (Error) => void
): ThunkAction {
  return async (dispatch, getState) => {
    const params = { projectId: project.id };
    const existingProject = getProject(getState(), params);
    const visibilityChanged =
      !!existingProject && existingProject.visibility !== project.visibility;

    project.name = project.name.trim();

    await dispatch(
      ProjectUpdateRequest.perform({
        params: { project },
        onSuccess,
        onError,
      })
    );

    if (visibilityChanged) {
      // Refetch project memberships. Clear the request first rather than
      // forcing so that the full loading state is triggered if you're looking
      // at the memberships list
      dispatch(clearProjectMemberships(project.id));
      dispatch(ProjectMembershipsFetchAllRequest.clear(params));
    }

    dispatch(updateProjectEvent());
  };
}

export function archiveProject(project: $Shape<Project>): ThunkAction {
  return (dispatch) => {
    if (archiveProjectDI) {
      return dispatch(archiveProjectDI(project));
    }

    dispatch(ProjectArchiveRequest.perform({ params: { project } }));
  };
}

export function unarchiveProject(project: $Shape<Project>): ThunkAction {
  return (dispatch) => {
    if (unarchiveProjectDI) {
      return dispatch(unarchiveProjectDI(project));
    }

    dispatch(ProjectUnarchiveRequest.perform({ params: { project } }));
  };
}

export function deleteProject(project: $Shape<Project>): ThunkAction {
  return (dispatch) => {
    if (deleteProjectDI) {
      return dispatch(deleteProjectDI(project));
    }

    dispatch(ProjectDeleteRequest.perform({ params: { project } }));
  };
}

export function transferProject(
  projectId: string,
  newOrgId: string
): ThunkAction {
  return function (dispatch, getState) {
    dispatch(
      TransferProjectRequest.perform({
        params: { projectId, newOrgId },
        onSuccess: (response) => {
          const { organizationId, name } = response.data || {};

          location.replace(organizationProjectsPath(organizationId));

          const state = getState();
          const organization = getOrganization(state, { organizationId });
          if (organization) {
            dispatch(
              showToast({ text: `${name} transferred to ${organization.name}` })
            );
          }
        },
      })
    );
  };
}
