// @flow
import * as Abstract from "abstract-sdk";
import { values } from "lodash";
import apiRequest from "abstract-di/api";
import Config from "abstract-di/config";
import { entitiesReceived } from "core/actions/entities";
import {
  shareLinkCreated,
  trackShareLinkUpdated,
} from "core/actions/shareLinks";
import { apiRequest as coreApiRequest, handleError } from "core/api";
import { getShareAuthorizationHeader } from "core/lib/shareAuthorization";
import {
  normalizeShareLink,
  normalizeShareLinks,
  normalizeShareLinksResponse,
} from "core/schemas/shareLink";
import type { InputShare, ShareLinkUpdateRequestParams } from "core/types";
import defineRequest from "./";

const API_VERSION = 13;

export const ShareLinkCreateRequest = defineRequest<InputShare, InputShare>({
  id: (params) => {
    const key = values({
      kind: params.kind,
      ...params.descriptor,
    }).join("-");
    return `post:share_links-${key}`;
  },
  perform: async (params) => {
    // use core/api to get the full response object
    // this allows checking the response status code
    const response = await coreApiRequest(
      "post",
      "share_links",
      Config.getAccessToken(),
      {
        headers: {
          "Abstract-Api-Version": API_VERSION,
          ...getShareAuthorizationHeader(),
        },
        body: {
          kind: params.kind,
          ...params.descriptor,
        },
      }
    );

    if (!response.ok) {
      throw await handleError(response);
    }

    const shareLink = await response.json();
    return { status: response.status, shareLink };
  },
  onSuccess: (response, params, dispatch) => {
    const { shareLink, status } = response;
    const { entities } = normalizeShareLink(shareLink);
    dispatch(entitiesReceived(entities));
    // 201 = a new share link was created
    // 200 = an existing share link was found
    if (status === 201) {
      dispatch(
        shareLinkCreated({
          kind: shareLink.kind,
          projectId: shareLink.descriptor.projectId,
        })
      );
    }
  },
});

export const ShareLinkUpdateRequest = defineRequest<
  ShareLinkUpdateRequestParams,
  { id: string },
>({
  id: (params) => {
    const { id } = params;
    return `put:share_links/${id}`;
  },
  perform: (params) => {
    const { id, isPublic, historyEnabled, inspectEnabled } = params;
    return apiRequest(
      "put",
      `share_links/${id}`,
      {
        public: isPublic,
        historyEnabled,
        inspectEnabled,
      },
      API_VERSION
    );
  },
  onSuccess: (shareLink, params, dispatch) => {
    const { entities } = normalizeShareLink(shareLink);
    dispatch(entitiesReceived(entities));
    dispatch(trackShareLinkUpdated(shareLink));
  },
});

type ShareLinksFetchParams = {
  organizationId: string,
  publicOnly?: boolean,
};

export const ShareLinksFetchRequest = defineRequest<
  ShareLinksFetchParams,
  ShareLinksFetchParams,
>({
  id: ({ organizationId }) => {
    return `get:share_links-${organizationId}`;
  },
  perform: (params) => {
    return apiRequest("get", "share_links", params, API_VERSION);
  },
  onSuccess: (response, params, dispatch) => {
    const normalizeResponse = response.data.shareLinks
      ? normalizeShareLinksResponse
      : normalizeShareLinks;
    const { entities } = normalizeResponse(response.data);
    dispatch(entitiesReceived(entities));
  },
});

export const ShareLinkFetchRequest = defineRequest<
  Abstract.ShareDescriptor,
  { shareId: string },
>({
  id: (params) => {
    return `get:share_links-${params.shareId}`;
  },
  perform: (params) => {
    return apiRequest(
      "get",
      `share_links/${params.shareId}`,
      params,
      API_VERSION
    );
  },
  onSuccess: (response, params, dispatch) => {
    const { entities } = normalizeShareLink(response);
    dispatch(entitiesReceived(entities));
  },
});
