// @flow
import * as React from "react";
import Card from "core/components/Card";
import Flex from "core/components/Flex";
import Heading from "core/components/Heading";
import ReportingIcon, {
  type AvailableIcons,
} from "core/components/OrganizationReporting/ReportingIcon";
import style from "./style.scss";

type Props = {
  label: string,
  icon: AvailableIcons,
  children: React.Node,
};

export default function MetricBox(props: Props) {
  return (
    <Card
      className={style.metricCard}
      innerClassName={style.metricContent}
      qaSelector={`${props.label}-metric-box`}
    >
      <Heading
        level="3"
        size="xl"
        title={props.label}
        className={style.metricLabel}
      >
        <Flex align="center">
          <ReportingIcon icon={props.icon} />
          {props.label}
        </Flex>
      </Heading>
      <Flex>{props.children}</Flex>
    </Card>
  );
}
