// @flow
import { reject, sortBy, find, map } from "lodash";
import { createSelector } from "reselect";
import { getCurrentUserId } from "abstract-di/selectors";
import naturalSortBy from "core/lib/naturalSortBy";
import { getProject } from "core/selectors/projects";
import type { State, Organization } from "core/types";

export const getOrganizationEntities = (state: State) => {
  return state.organizations;
};

export const getOrganizationIds: (state: State) => string[] = createSelector(
  getOrganizationEntities,
  (organizations) => {
    return map(organizations, (o) => o.id);
  }
);

// "Public" organizations (which the logged in user doesn't have access to)
// are stored in entities, so we need to filter those out
export const getAllValidOrganizations: (state: State) => Organization[] =
  createSelector(getOrganizationEntities, (organizations) => {
    // Since "public" organizations don't have a userId prop, we can
    // rely on that to remove all those organizations from this list.
    return reject(
      organizations,
      (organization) => !organization || !organization.userId
    );
  });

export function getOrganization(
  state: State,
  props: { organizationId: string }
): ?Organization {
  return state.organizations[props.organizationId];
}

export const getUsernameOrganization: (State) => ?Organization = createSelector(
  getOrganizationEntities,
  getCurrentUserId,
  (organizations, currentUserId) => {
    if (!currentUserId) {
      return undefined;
    }

    return find(organizations, {
      userId: currentUserId,
      isUsernameOrganization: true,
    });
  }
);

export const getOrganizations: (State) => Organization[] = createSelector(
  getOrganizationEntities,
  getUsernameOrganization,
  (organizations, userOrganization) =>
    sortBy(organizations, (organization) => {
      let rank;
      if (organization === userOrganization) {
        // rank user's username organization first
        rank = 0;
      } else {
        // rank organizations with non-username orgs first
        rank = !!organization.isUsernameOrganization + 1;
      }
      return [rank, organization.name].join("-");
    })
);

export function getCurrentOrganization(
  state: State,
  props: { projectId?: string, organizationId?: string }
): ?Organization {
  let id = props.organizationId ? props.organizationId : undefined;

  if (!id && typeof props.projectId === "string") {
    const project = getProject(state, { projectId: props.projectId });
    if (project) {
      id = project.organizationId;
    }
  }
  if (!id) {
    return undefined;
  }

  const organizations = getOrganizationEntities(state);
  return find(organizations, { id });
}

export function getDefaultOrganization(
  state: State,
  filterId?: string
): Organization {
  const organizations = getOrganizations(state);

  if (filterId) {
    const filteredOrgs = reject(organizations, { id: filterId });
    return filteredOrgs[1] || filteredOrgs[0];
  }
  // default org is first org that is not username, with fallback to username.
  return organizations[1] || organizations[0];
}

export function getOrganizationForProject(
  state: State,
  { projectId }: { projectId: string }
): ?Organization {
  const project = getProject(state, { projectId });
  if (!project) {
    return undefined;
  }
  return getOrganization(state, { organizationId: project.organizationId });
}

export const getNonUsernameOrganizations: (state: State) => Organization[] =
  createSelector(
    getOrganizations,
    getUsernameOrganization,
    (organizations, usernameOrganization) =>
      naturalSortBy(
        organizations.filter((organization) =>
          usernameOrganization
            ? organization.id !== usernameOrganization.id
            : true
        ),
        "name"
      )
  );
