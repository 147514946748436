// @flow
import * as Abstract from "abstract-sdk";
import apiRequest from "abstract-di/api";
import { trackEvent } from "core/actions/analytics";
import { entitiesReceived } from "core/actions/entities";
import {
  confirmSeatsToast,
  confirmSeatsRemovedToast,
} from "core/actions/seats";
import { showToast } from "core/actions/toasts";
import defineRequest from "./";

type Error = {
  validationErrors?: Object,
  message: string,
};

type SeatAddParams = {
  organizationId: string,
  quantity: number,
  preview: boolean,
  contributorsRemoved?: number,
};

export const SeatAddRequest = defineRequest<
  SeatAddParams,
  { organizationId: string, preview?: boolean },
>({
  id: (params) => {
    return `post:seats/preview:${params.preview ? "true" : "false"}-${
      params.organizationId
    }`;
  },
  perform: (params) => {
    return apiRequest(
      "post",
      `organizations/${params.organizationId}/subscription/seats`,
      params
    );
  },
  onSuccess: (response, params, dispatch) => {
    const { organizationId, quantity, preview } = params;
    dispatch(trackEvent("CONTRIBUTORS_ADDED", { organizationId, quantity }));
    dispatch(confirmSeatsToast(quantity, preview));
    dispatch(
      SubscriptionFetchRequest.perform({
        params: { organizationId },
      })
    );
    return response;
  },
  onError: (error: Error, params, dispatch) => {
    dispatch(trackEvent("ADD_SEATS_ERROR"));
    if (error.validationErrors) {
      const firstInvalidField = Object.keys(error.validationErrors)[0];
      error.message = error.validationErrors[firstInvalidField];
      dispatch(showToast({ text: error.message }));
    }

    if (!params.preview) {
      throw error;
    }
  },
});

type SeatResetParams = {
  organizationId: string,
};

export const SeatsResetRequest = defineRequest<
  SeatResetParams,
  SeatResetParams,
>({
  id: (params) => {
    return `post:seats/reset:${params.organizationId}`;
  },
  perform: (params) => {
    return apiRequest(
      "post",
      `organizations/${params.organizationId}/subscription/seats/reset`,
      params
    );
  },
  onSuccess: (response, params, dispatch) => {
    const { organizationId } = params;
    dispatch(
      SubscriptionFetchRequest.perform({
        params: { organizationId },
      })
    );
    return response;
  },
  onError: (error: Error, params, dispatch) => {
    dispatch(trackEvent("RESET_SEATS_ERROR"));

    if (error.validationErrors) {
      const firstInvalidField = Object.keys(error.validationErrors)[0];
      error.message = error.validationErrors[firstInvalidField];
    }

    throw error;
  },
});

type SeatRemoveParams = {
  organizationId: string,
  quantity: number,
  preview: boolean,
  contributorsRemoved?: number,
};

export const SeatRemoveRequest = defineRequest<
  SeatRemoveParams,
  { organizationId: string, preview?: boolean },
>({
  id: (params) => {
    return `delete:seats/preview:${params.preview ? "true" : "false"}-${
      params.organizationId
    }`;
  },
  perform: (params) => {
    return apiRequest(
      "delete",
      `organizations/${params.organizationId}/subscription/seats`,
      params
    );
  },
  onSuccess: (response, params, dispatch) => {
    const { organizationId, quantity, contributorsRemoved, preview } = params;
    dispatch(
      trackEvent("CONTRIBUTORS_REMOVED", {
        organizationId,
        quantity,
        contributorsRemoved,
      })
    );
    dispatch(confirmSeatsRemovedToast(quantity, preview));
    dispatch(
      SubscriptionFetchRequest.perform({
        params: { organizationId },
      })
    );

    return response;
  },
  onError: (error: Error, params, dispatch) => {
    dispatch(trackEvent("REMOVE_SEATS_ERROR"));

    if (error.validationErrors) {
      const firstInvalidField = Object.keys(error.validationErrors)[0];
      error.message = error.validationErrors[firstInvalidField];
      dispatch(showToast({ text: error.message }));
    }

    if (!params.preview) {
      throw error;
    }
  },
});

export const SubscriptionFetchRequest = defineRequest<
  Abstract.OrganizationDescriptor,
  Abstract.OrganizationDescriptor,
>({
  id: (params) => {
    return `get:subscription/${params.organizationId}`;
  },
  perform: (params) => {
    let url = "account/subscription";
    if (params.organizationId) {
      url = `organizations/${params.organizationId}/subscription`;
    }
    return apiRequest("get", url);
  },
  onSuccess: (response, params, dispatch) => {
    const orgId = response.id;
    const subscriptions = { [orgId]: response };
    dispatch(entitiesReceived({ subscriptions }));
  },
});
