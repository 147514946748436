// @flow
import classnames from "classnames";
import * as React from "react";
import { organizationPath } from "abstract-di/routes";
import Dialog from "core/components/Dialog";
import DocumentTitle from "core/components/DocumentTitle";
import Media from "core/components/Media";
import QuickJump from "core/components/QuickJump";
import SubscriptionBanner from "core/components/SubscriptionBanner";
import TeamsAnnouncement from "core/components/TeamsAnnouncement";
import Toasts from "core/components/Toasts";
import { push } from "core/lib/location";
import { useInModal } from "core/lib/router";
import type { ReactRouterLocation } from "core/types";
import MarketingConsentDialog from "web/components/MarketingConsentDialog";
import MobileHeader from "web/components/MobileHeader";
import SaveCurrentOrganization from "web/components/SaveCurrentOrganization";
import ShortcutViewer from "web/components/ShortcutViewer";
import type { Organization, User, Policy, Subscription } from "web/types";
import connector from "./connector";
import style from "./style.scss";

const Devtools = React.lazy(() => import("core/components/Devtools"));

export type OwnProps = {|
  children: React.Node,
  location: ReactRouterLocation,
  params: {
    projectId: string,
    branchId: string,
    sha?: string,
    commitSha: string, // TOOD: Remove
    fileId: string,
    id: string,
    organizationId: string,
    pageId: string,
    collectionId: string,
    layerId: string,
  },
  inModal?: boolean,
|};

export type StateProps = {|
  isLoggedIn: boolean,
  organizationId: string,
  projectId: string,
  canUseDevtools: boolean,
  currentOrganization: ?Organization,
  currentOrganizationPolicy: ?Policy,
  currentUser: ?User,
  organizations: Organization[],
  showMarketingConsent: boolean,
  showTermsOfService: boolean,
  sidebarIsOpen: boolean,
  sessionLoading: boolean,
  usernameOrganization: ?Organization,
  newUser: boolean,
  shouldTrackCurrentOrganization: (
    organization: Organization,
    prevOrganization: ?Organization
  ) => boolean,
  showTrialConsent: boolean,
  subscription: ?Subscription,
|};

export type DispatchProps = {|
  loadAndTrackMembership: (organizationId: string) => void,
  loadOrganization: (organizationId: string) => void,
  onOpenShortcutViewer: () => void,
  logout: () => void,
  loadSession: () => void,
  loginRequired: () => void,
  trackOrganization: (Organization) => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

function AppShell(props: Props) {
  const inModal = useInModal();
  return <WebAppShell {...props} inModal={inModal} />;
}

class WebAppShell extends React.Component<Props> {
  componentDidMount() {
    this.loadOrganization();

    if (this.props.currentOrganization) {
      this.trackMembershipAndOrganization(this.props.currentOrganization);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.organizationId !== this.props.organizationId) {
      this.loadOrganization();
    }

    if (
      this.props.currentOrganization &&
      this.props.shouldTrackCurrentOrganization(
        this.props.currentOrganization,
        prevProps.currentOrganization
      )
    ) {
      this.trackMembershipAndOrganization(this.props.currentOrganization);
    }
  }

  loadOrganization() {
    if (this.props.currentOrganization && this.props.organizationId !== "") {
      this.props.loadOrganization(this.props.organizationId);
    }
  }

  trackMembershipAndOrganization(organization: Organization) {
    this.props.trackOrganization(organization);
    this.props.loadAndTrackMembership(organization.id);
  }

  handleDeclineBetaAgreement = () => {
    if (
      !this.props.currentOrganization ||
      this.props.organizations.length === 0
    ) {
      return this.props.logout();
    }

    // Redirect to the first org that is not the current one if one exists
    for (const organization of this.props.organizations) {
      if (organization.id !== this.props.currentOrganization.id) {
        return push(organizationPath(organization.id));
      }
    }

    // Otherwise just log out
    this.props.logout();
  };

  render() {
    if (this.props.inModal) {
      return this.props.children;
    }

    return (
      <SaveCurrentOrganization
        currentOrganization={this.props.currentOrganization}
        sessionLoading={this.props.sessionLoading}
      >
        <Media large>
          {(large) => (
            <div
              className={classnames(style.background, {
                [style.sidebarOpen]: !large && this.props.sidebarIsOpen,
              })}
            >
              <DocumentTitle
                defaultTitle="Abstract"
                titleTemplate="Abstract - %s"
              />
              <div className={classnames(style.header)}>
                {this.props.inModal || large ? null : <MobileHeader />}
                <SubscriptionBanner
                  organization={this.props.currentOrganization}
                  organizationPolicy={this.props.currentOrganizationPolicy}
                  subscription={this.props.subscription}
                />
              </div>
              <div className={style.body}>
                <div className={style.bodyInner}>{this.props.children}</div>
              </div>
              {process.env.QA ? null : (
                <TeamsAnnouncement
                  organization={this.props.currentOrganization}
                />
              )}
              <Dialog />
              <Toasts />
              {this.props.canUseDevtools && (
                <React.Suspense fallback={null}>
                  <Devtools />
                </React.Suspense>
              )}
              <ShortcutViewer />
              <QuickJump
                organizationId={this.props.params.organizationId}
                projectId={this.props.projectId}
              />
              <MarketingConsentDialog
                isOpen={
                  this.props.showMarketingConsent ||
                  this.props.showTermsOfService
                }
                showTermsOfService={this.props.showTermsOfService}
                showMarketingConsent={this.props.showMarketingConsent}
              />
            </div>
          )}
        </Media>
      </SaveCurrentOrganization>
    );
  }
}

export default connector(AppShell);
