/* global navigator */
// @flow
import * as Abstract from "abstract-sdk";
import * as empty from "empty";
import { getProjects } from "core/selectors/projects";
import type { State } from "core/types";
import { getZoom } from "web/layerZoom";
import { getCurrentUser as webGetCurrentUser } from "web/selectors/session";

// Misc.
export { isLoggedIn } from "web/selectors/session";

export function isCommitUnpushed(
  state: State,
  props: { projectId: string, branchId: string, sha: string }
) {
  return false;
}

export function isDevelopmentMenuEnabled(state: State) {
  return false;
}

export function getThumbnailZoom(state: State, props: { subject: string }) {
  return getZoom();
}

export function isOnline(state: State) {
  return navigator.onLine;
}

// Projects

export function getIsUnsyncedByProject(
  state: State,
  props: { organizationId: string }
) {
  return empty.object;
}

export function getRepoHasChanges(state: State, props: { projectId: string }) {
  return false;
}

export function getRepoIsUnsynced(state: State, props: { projectId: string }) {
  return false;
}

export function getRepoIsSyncing(state: State, props: { projectId: string }) {
  return false;
}

export function getRepoIsCloning(state: State, props: { projectId: string }) {
  return false;
}
export function getRepoBranchId(state: State, props: { projectId: string }) {
  return undefined;
}

export function getRepoDownloadProgress(
  state: State,
  props: { projectId: string }
) {
  return 100;
}

export function getCurrentUser(state: State) {
  return webGetCurrentUser(state);
}

export function getCurrentUserId(state: State) {
  return state.session.userId;
}

// Desktop specific (for now)

export function getSketchIsInstalled(state: State) {
  return false;
}

export function sketchLibrariesSupported(state: State) {
  return false;
}

export function getSyncedProjectsForOrganization(
  state: State,
  params: { organizationId: string }
) {
  return getProjects(state, params);
}

export function getBranchMergeState(
  state: State,
  descriptor: Abstract.BranchDescriptor
): ?Abstract.MergeState {
  return undefined;
}

export function fileIsOpening(
  state: State,
  { projectId, sha, fileId }: { projectId: string, sha: string, fileId: string }
) {
  return false;
}
