// @flow
import * as React from "react";
import FormSection from "core/components/FormSection";
import { connector, type Props } from "web/containers/WebhookOverview";
import WebhookEventTree from "../WebhookEventTree";
import style from "./style.scss";

class WebhookEvents extends React.Component<Props> {
  render() {
    const { webhook, webhookEvents } = this.props;
    return (
      <FormSection>
        <div className={style.treeContainer}>
          <WebhookEventTree
            readOnly
            webhookEvents={webhookEvents.filter((webhookType) =>
              webhook.events.includes(webhookType.id)
            )}
          />
        </div>
      </FormSection>
    );
  }
}

export default connector(WebhookEvents);
