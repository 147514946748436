// @flow
import classnames from "classnames";
import invariant from "invariant";
import { remove, some } from "lodash";
import path from "path";
import pluralize from "pluralize";
import * as React from "react";
import { showChooseFilesDialog, getFilesWithInfo } from "abstract-di/lib";
import Button from "core/components/Button";
import DialogForm from "core/components/DialogForm";
import ExternalLink from "core/components/ExternalLink";
import FileName from "core/components/FileName";
import Flex from "core/components/Flex";
import InputCheckbox from "core/components/InputCheckbox";
import LargeCommitWarning, {
  isLargeFile,
} from "core/components/LargeCommitWarning";
import { readableFilesize } from "core/lib/textFormatting";
import { helpLibrariesUrl } from "core/lib/urls";
import { extFileType } from "core/models/file";
import type { Branch, ImportFile } from "core/types";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  projectId: string,
  branchId: string,
  initialFilesToImport: ImportFile[],
  onImportFiles: ({ [path: string]: boolean }) => void,
|};

export type StateProps = {|
  branch: ?Branch,
  canUseSketchLibraries: boolean,
  canShowLargeCommitWarning: boolean,
  featureEnabledFormats: string[],
|};

export type DispatchProps = {|
  dismissDialog: () => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type State = {
  filesToImport: ImportFile[],
  filePathToIsLibrary: { [path: string]: boolean },
  hasLargeFile: boolean,
  totalSize: number,
};

class ImportFiles extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const filePathToIsLibrary = {};
    let totalSize = 0;
    let hasLargeFile = false;

    props.initialFilesToImport.forEach((file) => {
      if (!filePathToIsLibrary.hasOwnProperty[file.path]) {
        filePathToIsLibrary[file.path] = false;

        if (file.size) {
          totalSize = totalSize + file.size;
          if (isLargeFile(file.size)) {
            hasLargeFile = true;
          }
        }
      }
    });

    this.state = {
      filesToImport: props.initialFilesToImport,
      filePathToIsLibrary,
      hasLargeFile,
      totalSize,
    };
  }

  toggleFileIsLibrary = (filePath: string) => {
    this.setState((prevState) => ({
      filePathToIsLibrary: {
        ...prevState.filePathToIsLibrary,
        [filePath]: !prevState.filePathToIsLibrary[filePath],
      },
    }));
  };

  removeFile = (filePath: string) => {
    this.setState((prevState) => {
      const newFilesToImport = [...prevState.filesToImport];
      const newFilePathToIsLibrary = { ...prevState.filePathToIsLibrary };
      let totalSize = 0;
      let hasLargeFile = false;

      remove(newFilesToImport, (file) => file.path === filePath);
      delete newFilePathToIsLibrary[filePath];

      newFilesToImport.forEach((file) => {
        if (file.size) {
          totalSize = totalSize + file.size;
          if (isLargeFile(file.size)) {
            hasLargeFile = true;
          }
        }
      });

      return {
        filesToImport: newFilesToImport,
        filePathToIsLibrary: newFilePathToIsLibrary,
        hasLargeFile,
        totalSize,
      };
    });
  };

  addMoreFilesWithDialog = async () => {
    invariant(
      showChooseFilesDialog,
      "showChooseFilesDialog required to display"
    );
    invariant(getFilesWithInfo, "getFilesWithInfo required to display");
    const filePaths = await showChooseFilesDialog(
      true,
      this.props.featureEnabledFormats
    );

    if (!filePaths.length) {
      return;
    }

    const filesToImport = await getFilesWithInfo(filePaths);

    this.setState((prevState) => {
      const newFilesToImport = [...prevState.filesToImport];
      const newFilePathToIsLibrary = { ...prevState.filePathToIsLibrary };
      let totalSize = prevState.totalSize;
      let hasLargeFile = prevState.hasLargeFile;

      filesToImport.forEach((file) => {
        // If we are importing a file that we are already importing,
        // do not reset the isLibrary flag
        if (
          !Object.prototype.hasOwnProperty.call(
            newFilePathToIsLibrary,
            file.path
          )
        ) {
          newFilesToImport.push(file);
          newFilePathToIsLibrary[file.path] = false;

          if (file.size) {
            totalSize = totalSize + file.size;
            if (isLargeFile(file.size)) {
              hasLargeFile = true;
            }
          }
        }
      });

      return {
        filesToImport: newFilesToImport,
        filePathToIsLibrary: newFilePathToIsLibrary,
        hasLargeFile,
        totalSize,
      };
    });
  };

  getCanUseLibraries = (filePaths: string[]) => {
    const hasSketchFiles = some(
      filePaths,
      (p) => path.extname(p) === ".sketch"
    );
    return hasSketchFiles && this.props.canUseSketchLibraries;
  };

  renderFileRow = (file: ImportFile, index: number) => {
    const { path: filePath, size: fileSize } = file;

    const ext = path.extname(filePath);
    const name = path.basename(filePath, ext);
    const type = extFileType(ext);
    const isLibrary = this.state.filePathToIsLibrary[filePath];
    const canUseAsLibrary = this.props.canUseSketchLibraries;

    return (
      <li
        key={filePath}
        className={style.fileRow}
        data-qa={`file-row-${index}`}
      >
        <Flex grow>
          <FileName
            className={style.fileName}
            name={name}
            type={type}
            isLibrary={isLibrary}
          />
          <div className={style.fileRowRight}>
            {canUseAsLibrary && (
              <InputCheckbox
                label="Use as Library"
                name={name}
                checked={isLibrary}
                onChange={() => this.toggleFileIsLibrary(filePath)}
                wrapperClass={style.checkboxWrapper}
                checkboxClass={style.checkbox}
                labelClass={style.checkboxLabel}
              />
            )}
            <div className={style.fileButtonWrapper}>
              <Button
                icon="clear"
                name={name}
                onClick={() => this.removeFile(filePath)}
                nude
                qaSelector="clear-button"
              />
            </div>
          </div>
        </Flex>
        {fileSize && (
          <div
            className={classnames(style.fileSize, {
              [style.largeFileSize]:
                isLargeFile(fileSize) && this.props.canShowLargeCommitWarning,
            })}
          >
            {readableFilesize(fileSize)}
          </div>
        )}
      </li>
    );
  };

  render() {
    const { filePathToIsLibrary } = this.state;
    const filePaths = Object.keys(filePathToIsLibrary);
    const fileCount = filePaths.length;
    const pluralizedFilesCount = `${fileCount} ${pluralize("file", fileCount)}`;
    const canUseLibraries = this.getCanUseLibraries(filePaths);

    const branchText = this.props.branch
      ? `the Branch “${this.props.branch.name}”`
      : "this Branch";

    return (
      <DialogForm
        title={
          fileCount === 0
            ? "Importing files"
            : `Importing ${pluralizedFilesCount}`
        }
        primaryButton={
          fileCount === 0 ? "Import files" : `Import ${pluralizedFilesCount}`
        }
        disabled={fileCount === 0}
        onClose={this.props.dismissDialog}
        onSubmit={() => this.props.onImportFiles(filePathToIsLibrary)}
        isOpen
      >
        {fileCount === 0 ? (
          <p>Select some files to import to {branchText}.</p>
        ) : (
          <React.Fragment>
            <p>
              Abstract is about to import {pluralizedFilesCount} to {branchText}
              .{" "}
              {canUseLibraries &&
                (fileCount === 1
                  ? "Would you like to use it as a Library?"
                  : "Would you like to use any of them as a Library?")}
            </p>
            <ExternalLink href={helpLibrariesUrl()} className={style.infoLink}>
              What are Libraries?
            </ExternalLink>
          </React.Fragment>
        )}
        <ol className={style.fileList} data-qa="files-list">
          {this.state.filesToImport.map(this.renderFileRow)}
        </ol>
        <div>
          <Button
            onClick={this.addMoreFilesWithDialog}
            qaSelector="add-new-files-button"
          >
            Add {fileCount === 0 ? "some" : "more"} files…
          </Button>
        </div>
        {this.state.hasLargeFile && this.props.canShowLargeCommitWarning && (
          <LargeCommitWarning
            className={style.largeCommitWarning}
            projectId={this.props.projectId}
            size={this.state.totalSize}
            importingFiles
          />
        )}
      </DialogForm>
    );
  }
}

export default connector(ImportFiles);
