// @flow
import * as React from "react";
import Button from "core/components/Button";
import Flex from "core/components/Flex";
import Popover from "core/components/Popover";
import SupportMenu from "core/components/SupportMenu";

type Props = {|
  mobile?: boolean,
  light?: boolean,
  className?: string,
  organizationId?: string,
  tooltip?: React.ElementConfig<typeof Popover>,
|};

export default function SupportButton(props: Props) {
  const { className, light, mobile = false, organizationId, ...rest } = props;

  return (
    <SupportMenu mobile={mobile} organizationId={organizationId}>
      {(showMenu, renderMenuTarget) =>
        renderMenuTarget((ref, buttonProps) => (
          <Flex
            align="center"
            className={className}
            grow={false}
            innerRef={ref}
            shrink={false}
          >
            <Button
              onClick={showMenu}
              icon="support-default"
              title="Support"
              qaSelector="supportButton"
              light={light}
              nude
              {...buttonProps}
              {...rest}
            />
          </Flex>
        ))
      }
    </SupportMenu>
  );
}
