// @flow
import { connect } from "react-redux";
import * as Request from "core/models/request";
import { OrganizationsFetchRequest } from "core/requests/organizations";
import { getNonUsernameOrganizations } from "core/selectors/organizations";
import type { State } from "core/types";
import type { Props, StateProps, OwnProps } from ".";

function mapStateToProps(state: State): StateProps {
  const request = OrganizationsFetchRequest.getRequest(state);

  return {
    organizations: getNonUsernameOrganizations(state),
    error: Request.hasError(request),
    isLoading: Request.isLoading(request),
  };
}

export default connect<Props, OwnProps, StateProps, _, _, State>(
  mapStateToProps
);
