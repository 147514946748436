// @flow
import classnames from "classnames";
import idx from "idx";
import * as React from "react";
import AuthorMeta from "core/components/AuthorMeta";
import Breadcrumb from "core/components/Breadcrumb";
import Breakpoint from "core/components/Breakpoint";
import CollectionItems from "core/components/CollectionItems";
import Error from "core/components/Empty/Error";
import NoCollectionLayers from "core/components/Empty/NoCollectionLayers";
import NotFound from "core/components/Empty/NotFound";
import Head from "core/components/Head";
import Loaded from "core/components/Loaded";
import Scrollable from "core/components/Scrollable";
import ZoomInput from "core/components/ZoomInput";
import CollectionLayer from "core/containers/CollectionLayer";
import window from "core/global/window";
import anyHover from "core/lib/anyHover";
import KeyCode from "core/lib/keycode";
import { replace, push, removeQuery } from "core/lib/location";
import { V3Link as Link } from "core/lib/router";
import {
  projectPath,
  projectCollectionsPath,
  branchPath,
} from "core/lib/routes";
import type {
  Project,
  User,
  Layer as TLayer,
  Collection as TCollection,
  CollectionItem,
  CollectionLayer as TCollectionLayer,
  Branch,
  ShareLink,
  ReactRouterLocation,
  BranchCollectionDescriptor,
} from "core/types";
import EditableHeader from "./EditableHeader";
import TopBar from "./TopBar";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  location: ReactRouterLocation,
  params: BranchCollectionDescriptor,
  shareLink?: ShareLink,
  initialThumbnailZoom?: string | number,
|};

export type StateProps = {|
  branch: ?Branch,
  branchDisplayName: ?string,
  head: string,
  organizationId: string,
  detailedLayer: ?TLayer,
  detailedCollectionLayer: ?TCollectionLayer,
  shouldDeleteCollectionOnUnmount: boolean,
  canEdit: boolean,
  isOnline: boolean,
  isPublic: boolean,
  isAddingLayers: boolean,
  showMore: boolean,
  error: boolean,
  notFound: boolean,
  loading: boolean,
  user: ?User,
  collection: ?TCollection,
  project: ?Project,
  collectionId: string,
  collectionItems: CollectionItem[],
  initialThumbnailZoom: string | number,
  isLoggedIn: boolean,
|};

export type DispatchProps = {|
  onZoomChange: (zoom: number) => void,
  updateCollection: (attributes: {
    name?: string,
    description?: string,
  }) => void,
  deleteCollection: () => void,
  onLoad: () => void,
  handlePresentLayer: () => void,
  handleGoToCollectionLayerDetail: (collectionLayerId: string) => void,
|};

export type OwnLocationProps = {|
  ...OwnProps,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type State = {
  thumbnailZoom: string | number,
  isTopBarDocked: boolean,
  // When a user clicks to add a new text item, unlike adding layers,
  // they will be at a draft state. Only when the user blurs from the
  // input will the item be saved.
  //
  // We keep an index so that we'll know at which order to save the
  // newly added text item.
  addDraftTextItemIndex?: number,
  isQueuingNewDraft?: boolean,
};

const END_OF_COLLECTION = -1;

class Collection extends React.Component<Props, State> {
  scrollable: ?HTMLDivElement;

  state = {
    thumbnailZoom: this.props.initialThumbnailZoom,
    isTopBarDocked: true,
    addDraftTextItemIndex: undefined,
    isQueuingNewDraft: false,
  };

  componentDidMount() {
    window.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);

    if (this.props.shouldDeleteCollectionOnUnmount) {
      this.props.deleteCollection();
    }
  }

  handleDock = () => this.setState({ isTopBarDocked: true });
  handleUnDock = () => this.setState({ isTopBarDocked: false });

  handleKeyDown = (event: KeyboardEvent) => {
    if (!this.props.isPublic && event.keyCode === KeyCode.KEY_ESCAPE) {
      this.handleGoBack();
    }
  };

  handleNameChange = (newName: string) => {
    if (this.props.collection && newName !== this.props.collection.name) {
      this.props.updateCollection({ name: newName });
    }
  };

  saveDescription = (newDescription: string) => {
    if (
      this.props.collection &&
      newDescription !== this.props.collection.description
    ) {
      this.props.updateCollection({ description: newDescription });
    }
  };

  handleZoomChange = (event: SyntheticEvent<*>, zoom: number) => {
    this.props.onZoomChange(zoom);
    this.setState({ thumbnailZoom: zoom });
  };

  handleGoBack = () => {
    const { collectionLayerId, present, preview } = this.props.location.query;

    if (preview === "false") {
      return replace(
        removeQuery(
          "present",
          "collectionLayerId",
          "preview",
          "showComments",
          "sha"
        )
      );
    }

    if (present === "true") {
      return replace(removeQuery("present", "preview", "showComments", "sha"));
    }

    if (collectionLayerId) {
      return replace(
        removeQuery("collectionLayerId", "mode", "sha", "selected")
      );
    }

    const returnTo = idx(this.props.location, (_) => _.state.returnTo);

    if (returnTo) {
      return push(returnTo);
    }

    return push(projectPath(this.props.params.projectId, "collections"));
  };

  setScrollableRef = (ref: ?HTMLDivElement) => {
    this.scrollable = ref;
  };

  handleAddDraftCollectionText = (index?: number) => {
    if (index !== this.state.addDraftTextItemIndex) {
      this.setState({
        addDraftTextItemIndex: index,
      });
    } else {
      // We hit this condition when a user blurs a DraftTextItem
      // at the same time as when they click the add text button.
      this.setState({
        isQueuingNewDraft: true,
      });
    }
  };

  handleRemoveDraftCollectionText = () => {
    this.setState(
      {
        addDraftTextItemIndex: undefined,
      },
      () => {
        if (this.state.isQueuingNewDraft) {
          this.setState({
            isQueuingNewDraft: false,
            addDraftTextItemIndex: END_OF_COLLECTION,
          });
        }
      }
    );
  };

  render() {
    const { collection, collectionItems, branch, branchDisplayName, isPublic } =
      this.props;
    const { projectId } = this.props.params;
    const empty = !collectionItems || collectionItems.length === 0;

    if (this.props.notFound) {
      return <NotFound />;
    }

    if (this.props.error && this.props.isOnline) {
      return <Error />;
    }

    const topBar = (above) => (
      <TopBar
        isMobile={!above}
        isPublic={isPublic}
        isDocked={this.state.isTopBarDocked}
        organizationId={this.props.organizationId}
        projectId={projectId}
        onGoBack={this.handleGoBack}
        onPresent={this.props.handlePresentLayer}
        collection={collection}
        branch={branch}
        shareLink={this.props.shareLink}
        showMore={this.props.showMore}
        canEdit={this.props.canEdit && !this.props.loading}
        canPresent={!empty && !this.props.loading}
        handleAddDraftCollectionText={() =>
          this.handleAddDraftCollectionText(END_OF_COLLECTION)
        }
      />
    );

    // support old URLs by rerouting them via the CollectionLayer component
    if (this.props.location.query.collectionLayerId) {
      return (
        <CollectionLayer
          params={{
            ...this.props.params,
            collectionLayerId: this.props.location.query.collectionLayerId,
          }}
          location={this.props.location}
        />
      );
    }
    let breadcrumbLinks = [];

    if (this.props.project) {
      breadcrumbLinks = [
        <Link to={projectPath(projectId)}>{this.props.project.name}</Link>,
        <Link to={projectCollectionsPath(this.props.params.projectId)}>
          Collections
        </Link>,
      ];
    }

    if (this.props.branch) {
      breadcrumbLinks.push(
        <Link
          to={branchPath(
            this.props.params.projectId,
            this.props.params.branchId
          )}
        >
          {branchDisplayName}
        </Link>
      );
    }

    return (
      <div className={style.content}>
        {collection && (
          <Head>
            <title>{collection.name}</title>
          </Head>
        )}
        <Breakpoint at="mobile">
          {({ above }) => (
            <div className={style.container}>
              {!isPublic && topBar(above)}
              <Scrollable
                flex
                innerRef={this.setScrollableRef}
                className={classnames(style.mainContent, {
                  [style.publicContent]: isPublic,
                })}
                qaSelector="collection-overview-scrollable-container"
              >
                {isPublic && topBar(above)}
                <div className={style.innerContent}>
                  {!isPublic && collection && (
                    <Breadcrumb crumbs={breadcrumbLinks} />
                  )}
                  <React.Fragment>
                    {collection ? (
                      <React.Fragment>
                        <EditableHeader
                          canEdit={this.props.canEdit && this.props.isOnline}
                          collection={collection}
                          onChangeName={this.handleNameChange}
                          onChangeDescription={this.saveDescription}
                          onEnter={this.handleDock}
                          onLeave={this.handleUnDock}
                          projectId={this.props.params.projectId}
                        />

                        <div className={style.metaSection}>
                          {this.props.user && (
                            <AuthorMeta
                              user={this.props.user}
                              date={collection.updatedAt}
                            />
                          )}
                        </div>
                      </React.Fragment>
                    ) : (
                      <div className={style.editableHeaderPlaceholder} />
                    )}
                    <Loaded
                      title="Loading collection…"
                      className={style.loading}
                      loading={this.props.loading}
                      flex
                    >
                      {() => {
                        if (this.props.canEdit || !empty) {
                          return (
                            collection &&
                            branch && (
                              <div className={style.layersContainer}>
                                <CollectionItems
                                  disableEmptyState
                                  onClickCollectionLayer={
                                    this.props.handleGoToCollectionLayerDetail
                                  }
                                  branch={branch}
                                  collection={collection}
                                  collectionItems={this.props.collectionItems}
                                  thumbnailZoom={Number(
                                    this.state.thumbnailZoom
                                  )}
                                  shareLink={this.props.shareLink}
                                  isMobile={!above}
                                  isMousePointer={anyHover}
                                  editable={this.props.canEdit && !isPublic}
                                  container={this.scrollable}
                                  addDraftTextItemIndex={
                                    this.state.addDraftTextItemIndex
                                  }
                                  handleAddDraftCollectionText={
                                    this.handleAddDraftCollectionText
                                  }
                                  handleRemoveDraftCollectionText={
                                    this.handleRemoveDraftCollectionText
                                  }
                                />
                              </div>
                            )
                          );
                        } else {
                          return <NoCollectionLayers />;
                        }
                      }}
                    </Loaded>
                  </React.Fragment>
                </div>
              </Scrollable>
              {!empty && above && (
                <div className={style.zoom}>
                  <ZoomInput
                    value={Number(this.state.thumbnailZoom)}
                    onChange={this.handleZoomChange}
                  />
                </div>
              )}
            </div>
          )}
        </Breakpoint>
      </div>
    );
  }
}

export default connector(Collection);
