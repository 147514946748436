// @flow
import { normalize } from "normalizr";
import {
  membershipSchema,
  membershipsSchema,
  updateMembershipSchema,
} from "core/schema";
import type { Membership } from "core/types";
import { envelopeSchema } from "./envelope";

export function normalizeMembership(membership: Membership) {
  return normalize(membership, membershipSchema);
}

export function normalizeMemberships(response: *) {
  return normalize(response, membershipsSchema);
}

export function normalizeUpdateMembership(response: *) {
  return normalize(response, updateMembershipSchema);
}

export function normalizeUpdateMembershipResponse(response: *) {
  return normalize(response, envelopeSchema(updateMembershipSchema));
}
