// @flow
import * as Sentry from "@sentry/react";
import invariant from "invariant";
import * as React from "react";
import getDisplayName from "react-display-name";
import MountProfiler from "core/components/MountProfiler";
import type { AnyDescriptor } from "core/types";

export default function withProfiler<P: { +params?: AnyDescriptor }>(
  WrappedComponent: React.AbstractComponent<P>,
  displayNameOverride?: string
): React.AbstractComponent<P> {
  const displayName: string = displayNameOverride
    ? displayNameOverride
    : getDisplayName(WrappedComponent);

  const ProfiledComponent = Sentry.withProfiler(WrappedComponent);
  ProfiledComponent.displayName = "SentryProfiler";

  return class Profilerable extends React.Component<P> {
    render() {
      invariant(
        this.props.params,
        "props.params is required with withProfiler"
      );

      return (
        <MountProfiler id={displayName} params={this.props.params}>
          <ProfiledComponent {...this.props} />
        </MountProfiler>
      );
    }
  };
}
