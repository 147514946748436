// @flow
import { keyBy } from "lodash";
import * as React from "react";
import Button from "core/components/Button";
import GlobalSidebarMenuItem from "core/components/GlobalSidebarMenuItem";
import type { KeyboardNavigationProps } from "core/components/KeyboardNavigation";
import { isDesktop } from "core/lib/platform";
import * as Routes from "core/lib/routes";
import * as Branch from "core/models/branch";
import type {
  Branch as TBranch,
  BranchHierarchy,
  BranchReview,
  DraftBranch,
} from "core/types";
import BranchMenuItem, { DraftBranchMenuItem } from "./BranchMenuItem";
import connector from "./connector";
import style from "./style.scss";

export const INITIAL_BRANCH_LIMIT = 6;

export type OwnProps = {|
  currentBranch: ?TBranch,
  isBranchesExpanded: boolean,
  keyboardNavigationProps: KeyboardNavigationProps,
  mobile?: boolean,
  onClick?: (event: SyntheticEvent<>) => void,
  onExpandBranches: (boolean) => void,
  projectId: string,
|};

export type StateProps = {|
  branches: BranchHierarchy[],
  branchReviews: BranchReview[],
  canCreateBranch: boolean,
  draftBranches: { [parentId: string]: DraftBranch[] },
  isBranchesLoading: boolean,
  totalBranches: number,
|};

export type DispatchProps = {|
  fetchAllBranches: () => void,
  fetchBranches: () => void,
  showNewBranchDialog: (projectId: string) => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

function ProjectBranchesMenuItem(props: Props) {
  const {
    currentBranch,
    fetchBranches,
    canCreateBranch,
    showNewBranchDialog,
    projectId,
  } = props;

  React.useEffect(() => {
    fetchBranches();
  }, [fetchBranches]);

  const [isAllBranchesLoading, setIsAllBranchesLoading] = React.useState(false);

  const reviewsByBranchId = keyBy(props.branchReviews, "branchId");
  const masterDraftBranches = props.draftBranches[Branch.BRANCH_ID_MASTER];
  const branches = props.isBranchesExpanded
    ? props.branches
    : props.branches.slice(0, INITIAL_BRANCH_LIMIT);
  const shouldRenderCurrentBranch =
    currentBranch &&
    currentBranch.id !== Branch.BRANCH_ID_MASTER &&
    Branch.isActive(currentBranch)
      ? !branches.some(({ branch }) => branch.id === currentBranch.id)
      : false;
  const hasMore =
    props.totalBranches >
    (shouldRenderCurrentBranch
      ? INITIAL_BRANCH_LIMIT + 1
      : INITIAL_BRANCH_LIMIT);

  return (
    <GlobalSidebarMenuItem
      actionButton={(props) =>
        isDesktop && canCreateBranch ? (
          <Button
            {...props}
            icon="plus-small"
            onClick={(event: SyntheticEvent<>) => {
              event.preventDefault();
              event.stopPropagation();
              showNewBranchDialog(projectId);
            }}
            title="Create new Branch"
            tooltip={{
              delayShow: 750,
              placement: "bottom",
            }}
            qaSelector="newBranchSidebarButton"
            nude
          />
        ) : null
      }
      icon={props.isBranchesLoading ? "spinner" : "branch-small"}
      keyboardNavigationProps={props.keyboardNavigationProps}
      label="Branches"
      mobile={props.mobile}
      onClick={props.onClick}
      to={Routes.projectPath(props.projectId, "branches")}
      qaSelector="branchesSidebarItem"
      qaSelectorWrapper="Branches"
    >
      {masterDraftBranches
        ? masterDraftBranches.map((masterDraftBranch) => (
            <DraftBranchMenuItem
              draftBranch={masterDraftBranch}
              key={masterDraftBranch.id}
              keyboardNavigationProps={props.keyboardNavigationProps}
              mobile={props.mobile}
              onClick={props.onClick}
            />
          ))
        : null}
      {props.isBranchesLoading
        ? null
        : branches.map(({ branch, level }) => {
            const branchStatus = reviewsByBranchId[branch.id]
              ? reviewsByBranchId[branch.id].status
              : branch.status;

            const draftBranches = props.draftBranches[branch.id];

            return (
              <BranchMenuItem
                branch={branch}
                branchStatus={branchStatus}
                draftBranches={draftBranches}
                key={branch.id}
                keyboardNavigationProps={props.keyboardNavigationProps}
                level={level}
                mobile={props.mobile}
                onClick={props.onClick}
              />
            );
          })}
      {shouldRenderCurrentBranch && currentBranch ? (
        <BranchMenuItem
          branch={currentBranch}
          branchStatus={
            reviewsByBranchId[currentBranch.id]
              ? reviewsByBranchId[currentBranch.id].status
              : currentBranch.status
          }
          draftBranches={props.draftBranches[currentBranch.id]}
          keyboardNavigationProps={props.keyboardNavigationProps}
          mobile={props.mobile}
          onClick={props.onClick}
        />
      ) : null}
      {hasMore && !props.isBranchesLoading && (
        <Button
          className={style.expandButton}
          disabled={isAllBranchesLoading}
          icon={
            isAllBranchesLoading
              ? "spinner"
              : props.isBranchesExpanded
              ? "arrow-large-up"
              : "arrow-large-down"
          }
          onClick={async () => {
            setIsAllBranchesLoading(true);
            await props.fetchAllBranches();
            setIsAllBranchesLoading(false);
            props.onExpandBranches(!props.isBranchesExpanded);
          }}
          qaSelector="showMoreBranchesSidebarButton"
          alignLeft
          fullwidth
          nude
        >
          {props.isBranchesExpanded ? "Show less" : "Show more"}
        </Button>
      )}
    </GlobalSidebarMenuItem>
  );
}

export default connector(ProjectBranchesMenuItem);
