// @flow
import { keys, find, omit, map } from "lodash";
import * as React from "react";
import { Prompt } from "react-router-dom";
import AddDuplicateConfirmation from "core/components/AddDuplicateConfirmation";
import AddToCollectionButton from "core/components/AddToCollectionButton";
import Button from "core/components/Button";
import Flex from "core/components/Flex";
import window from "core/global/window";
import KeyCode from "core/lib/keycode";
import * as Layer from "core/models/layer";
import type {
  Collection,
  CollectionLayer,
  File,
  Page,
  Layer as TLayer,
  MultiSelectedEntities,
  BranchCollectionDescriptor,
} from "core/types";
import connector from "./connector";
import style from "./style.scss";

export type MultiSelectedProps = {
  selectedEntities?: MultiSelectedEntities,
  selectedCount: number,
  isSelecting: boolean,
  canSelect: boolean,
  onSelectEntities: ({
    page?: Page,
    layer?: TLayer,
    layers?: TLayer[],
  }) => void,
  onSelectLayerRange: (
    layer: TLayer,
    layers: TLayer[],
    entities?: MultiSelectedEntities,
    selected: boolean
  ) => *,
  layerSelected: (layer: TLayer) => boolean,
  pageSelected: (page: Page) => boolean,
  fileSelected: (file: File) => boolean,
  pagePartiallySelected: (page: Page) => boolean,
  filePartiallySelected: (file: File) => boolean,
};

export type OwnProps = {|
  projectId: string,
  branchId: string,
  collection?: Collection,
  showHeader?: boolean,
  className?: string,
  isSubmitting?: boolean,
  children: ({
    onSubmit: (collection: Collection) => void,
    title: React.Node,
    hasMore: boolean,
  }) => ?React.Node,
  onClose?: () => void,
  commitSha?: string,
  noPrompt?: boolean,
  useLatestCommit?: boolean,
  redirectToCollectionAfterSubmit?: boolean,
|};

export type StateProps = {|
  selected?: ?MultiSelectedEntities,
  isEmpty: boolean,
  isSelecting: boolean,
  collections: Collection[],
  collectionLayers: CollectionLayer[],
  canSelect: boolean,
|};

export type DispatchProps = {|
  onMount: () => void,
  onClearSelected: () => void,
  onValidate: (branchCollectionDescriptor: BranchCollectionDescriptor) => {
    duplicates: TLayer[],
  },
  onSubmitEmpty: (collectionId: string) => void,
  onSubmit: (
    selected: MultiSelectedEntities,
    collection: ?Collection,
    allowDuplicates?: boolean
  ) => void,
|};

export type Props = {|
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
|};

type State = {
  duplicates: TLayer[],
  collection: ?Collection,
  submitted: boolean,
};

const EMPTY = {};

class CollectionMultiSelect extends React.Component<Props, State> {
  state = {
    duplicates: [],
    collection: null,
    submitted: false,
  };

  componentDidMount() {
    if (this.props.canSelect) {
      this.props.onMount();
    }
    if (this.props.isSelecting) {
      this.addEventListeners();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.canSelect && !prevProps.canSelect) {
      this.props.onMount();
    }

    if (!prevProps.isSelecting && this.props.isSelecting) {
      this.addEventListeners();
    }

    if (prevProps.isSelecting && !this.props.isSelecting) {
      this.removeEventListeners();
    }

    if (prevProps.showHeader && !prevProps.isEmpty && this.props.isEmpty) {
      this.stopSelecting();
    }
  }

  componentWillUnmount() {
    this.props.onClearSelected();
    this.removeEventListeners();
  }

  addEventListeners = () => {
    window.addEventListener("keydown", this.handleKeydown);
  };

  removeEventListeners = () => {
    window.removeEventListener("keydown", this.handleKeydown);
  };

  get layers(): { [string]: TLayer } {
    return this.props.selected ? this.props.selected.layers : EMPTY;
  }

  get layerCount(): number {
    return keys(this.layers).length;
  }

  get hasMore(): boolean {
    const { selected } = this.props;
    if (!selected) {
      return false;
    }

    return !!(
      find(selected.pages, { hasMore: true }) ||
      find(selected.files, { hasMore: true })
    );
  }

  stopSelecting = () => {
    this.setState(
      { duplicates: [], collection: null },
      this.props.onClearSelected
    );
  };

  handleKeydown = (event: KeyboardEvent) => {
    switch (event.keyCode) {
      case KeyCode.KEY_ESCAPE:
        this.stopSelecting();
        break;
      default:
    }
  };

  handleSubmit = (collection: Collection) => {
    const { selected } = this.props;

    // Avoid submitting nothing selected
    if (!selected) {
      return;
    }

    const branchCollectionDescriptor = {
      projectId: this.props.projectId,
      branchId: this.props.branchId,
      collectionId: collection.id,
    };

    const { duplicates } = this.props.onValidate(branchCollectionDescriptor);

    if (duplicates.length > 0) {
      this.setState({ duplicates, collection });
    } else {
      this.setState({ submitted: true }, () => {
        this.props.onSubmit(selected, collection);
        this.stopSelecting();
      });
    }
  };

  handleSubmitAll = () => {
    const { selected } = this.props;

    if (selected) {
      this.props.onSubmit(selected, this.state.collection);
      this.setState({ submitted: true });
    }

    this.stopSelecting();
  };

  handleSubmitWithoutDuplicates = () => {
    const { collection, duplicates } = this.state;
    const duplicateIds = map(duplicates, Layer.uniqueId);
    const filtered = omit(this.layers, duplicateIds);

    this.setState({ submitted: true }, () => {
      if (keys(filtered).length || this.hasMore) {
        this.props.onSubmit(
          { ...this.props.selected, layers: filtered },
          collection,
          false
        );
      } else {
        if (collection) {
          this.props.onSubmitEmpty(collection.id);
        }
      }
    });

    this.stopSelecting();
  };

  handleCancelSubmit = () => {
    this.setState({ duplicates: [], collection: null });
  };

  render() {
    const { children, isSelecting, showHeader, projectId, branchId } =
      this.props;

    const { duplicates } = this.state;
    const layerCount = this.layerCount;
    const hasMore = this.hasMore;

    const title = (
      <span className={style.layerCount}>
        {`${
          layerCount ? (hasMore ? layerCount + "+" : layerCount) : "No items"
        } `}
        selected
      </span>
    );

    return (
      <React.Fragment>
        <Prompt
          when={
            !this.props.noPrompt &&
            !this.props.isEmpty &&
            !this.state.submitted &&
            this.props.isSelecting
          }
          message="Would you like to discard your selection?"
        />
        {showHeader && isSelecting && (
          <Flex align="center" className={style.header}>
            <Flex align="center" className={style.left}>
              <Button
                icon="close"
                nude
                light
                onClick={this.stopSelecting}
                className={style.close}
              />
              {title}
            </Flex>
            <Flex align="center" justify="flex-end">
              <AddToCollectionButton
                layerCount={layerCount}
                hasMore={hasMore}
                projectId={projectId}
                branchId={branchId}
                onSubmit={this.handleSubmit}
              />
            </Flex>
          </Flex>
        )}
        {children({ onSubmit: this.handleSubmit, title, hasMore })}
        <AddDuplicateConfirmation
          isOpen={!!duplicates.length}
          hasMore={hasMore}
          duplicates={duplicates}
          onClose={this.handleCancelSubmit}
          onSecondary={this.handleSubmitAll}
          onSubmit={this.handleSubmitWithoutDuplicates}
        />
      </React.Fragment>
    );
  }
}

export default connector(CollectionMultiSelect);
