// @flow
import classnames from "classnames";
import * as React from "react";
import Avatar from "core/components/Avatar";
import Time from "core/components/Time";
import { unknownUser } from "core/models/user";
import type { User } from "core/types";
import style from "./style.scss";

type Props = {
  user?: User,
  date?: string,
  className?: string,
  avatarClassName?: string,
  useShorthandDate?: boolean,
};

export default function AuthorMeta({
  user,
  date,
  className,
  avatarClassName,
  useShorthandDate,
  ...rest
}: Props) {
  const name = user ? user.name : unknownUser.name;

  return (
    <div className={classnames(style.author, className)} {...rest}>
      <Avatar
        userId={user ? user.id : undefined}
        className={classnames(style.avatar, avatarClassName)}
      />
      <span>{name}</span>
      {date && (
        <React.Fragment>
          <span className={style.dot}>·</span>
          <span className={style.date}>
            <Time useShorthand={useShorthandDate} date={date} />
          </span>
        </React.Fragment>
      )}
    </div>
  );
}
