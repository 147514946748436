// @flow
import { connect } from "react-redux";
import { isOnline } from "abstract-di/selectors";
import {
  fetchLayerAssets,
  fetchSymbolMasterAssets,
  generateAndUpdateAssetsForFile,
} from "core/actions/assets";
import { withData } from "core/components/DataLoader";
import createConnector from "core/lib/createConnector";
import { isSymbolFromSameProject, isForeignSymbol } from "core/lib/layer";
import { isDesktop } from "core/lib/platform";
import * as LayerData from "core/models/layerData";
import { AssetsFetchRequest } from "core/requests/assets";
import {
  getLayerAssets,
  getAssetsForAllLayers,
  getAssetPreviewsForAllLayers,
  getAssetExports,
  getLayerAssetPreview,
  getSymbolMasterLayerAssets,
  getSymbolMasterAssetPreview,
  getSymbolMasterDataAssets,
} from "core/selectors/assets";
import { getLatestCommitShaForLayer } from "core/selectors/commits";

import { getProject } from "core/selectors/projects";
import type { Dispatch, State } from "core/types";
import Component, { type OwnProps, type Props } from "./component";

function mapStateToProps(state: State, props: OwnProps) {
  const { layerData, isRootLayer, canGenerateAssets, isPublicShare } = props;
  const { id: nestedLayerId } = layerData;
  const { projectId, branchId, sha, fileId, layerId } = props.params;

  const assetParams = {
    projectId,
    branchId,
    sha,
    fileId,
    layerId,
    nestedLayerId,
  };

  // properties.assets
  const layerDataAssets = LayerData.assets(props.layerData);

  // Get request state for all the assets, if the current layer is a root layer or current layer has assets
  const currentLayerAssetsRequest =
    isRootLayer || layerDataAssets
      ? AssetsFetchRequest.getRequest(state, props.params)
      : undefined;

  // Get `symbolMasterDescriptor`, if the current layer has assets and it's a symbol or it's nested inside a sybmol.
  const symbolMasterDescriptor = LayerData.symbolMasterDescriptor(layerData);

  // api doesn't support "latest" sha at the moment. Therefore resolve "latest" to actual sha
  const symbolMasterSha = symbolMasterDescriptor
    ? getLatestCommitShaForLayer(
        state,
        // If symbolMaster is in same project, get the sha from the original layer params.
        isSymbolFromSameProject(props.params, symbolMasterDescriptor)
          ? {
              projectId,
              branchId,
              fileId,
              layerId,
            }
          : symbolMasterDescriptor
      )
    : undefined;

  const symbolMasterAssetsLoading = symbolMasterSha
    ? AssetsFetchRequest.isFirstLoading(state, {
        ...symbolMasterDescriptor,
        sha: symbolMasterSha,
      })
    : undefined;

  const symbolMasterAssetsError =
    !symbolMasterAssetsLoading && symbolMasterSha
      ? AssetsFetchRequest.hasError(state, {
          ...symbolMasterDescriptor,
          sha: symbolMasterSha,
        })
      : undefined;

  const missingForeignAssets =
    isPublicShare && isForeignSymbol(props.params, symbolMasterDescriptor);

  const symbolMasterAssets = getSymbolMasterLayerAssets(state, assetParams);
  const symbolMasterLayerDataAssets = getSymbolMasterDataAssets(
    state,
    assetParams
  );

  const symbolMasterLayerDataHasAssets =
    !missingForeignAssets &&
    symbolMasterLayerDataAssets &&
    symbolMasterLayerDataAssets.length > 0;

  const assetsForAllLayers = isRootLayer
    ? getAssetsForAllLayers(state, assetParams)
    : undefined;

  // Assets defined in layerData but may not have been generated yet.
  const assetExports = isRootLayer
    ? getAssetExports(state, assetParams)
    : undefined;

  const hasAssetExports = !!(assetExports && assetExports.length);

  const project = getProject(state, { projectId });

  const showAssetsSection =
    isRootLayer ||
    (layerDataAssets && layerDataAssets.length > 0) ||
    symbolMasterLayerDataHasAssets;

  return {
    isLoading:
      currentLayerAssetsRequest &&
      currentLayerAssetsRequest.state === "loading",
    error:
      currentLayerAssetsRequest && currentLayerAssetsRequest.state === "error",
    symbolMasterAssetsError,
    assets: getLayerAssets(state, assetParams),
    layerDataAssets,
    showAssetsSection,
    symbolMasterAssets,
    symbolDataAssets: symbolMasterLayerDataAssets,
    assetsForAllLayers,
    assetPreviewsForAllLayers: isRootLayer
      ? getAssetPreviewsForAllLayers(state, assetParams)
      : undefined,
    assetPreview: getLayerAssetPreview(state, assetParams),
    symbolMasterAssetPreview: getSymbolMasterAssetPreview(state, assetParams),
    isOffline: !isOnline(state),
    canGenerateAssets: canGenerateAssets && hasAssetExports,
    isDesktop,
    assetAutoGenSetting: project ? project.assetAutoGeneration : "all",
    nestedLayerId,
  };
}

function mapDispatchToProps(dispatch: Dispatch, props: OwnProps) {
  return {
    onLoad() {
      const { isRootLayer, layerData, params, isPublicShare } = props;
      if (isRootLayer || layerData.properties.assets) {
        dispatch(fetchLayerAssets(params));
      }

      dispatch(
        fetchSymbolMasterAssets(params, props.layerData.id, isPublicShare)
      );
    },
    onGenerateAssetsForFile() {
      dispatch(
        generateAndUpdateAssetsForFile(props.params, props.layerData.id)
      );
    },
  };
}

export default createConnector<Props, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withData((props) => ({
    projectId: props.params.projectId,
    branchId: props.params.branchId,
    sha: props.params.sha,
    fileId: props.params.fileId,
    layerId: props.params.layerId,
    nestedLayerId: props.layerData.id,
  }))
)(Component);
