// @flow
import { EventTypes } from "redux-segment";
import { getCurrentUserId, isLoggedIn } from "abstract-di/selectors";
import { trackEvent } from "core/actions/analytics";
import { getShareLinkForKind } from "core/selectors/shareLinks";
import type {
  Action,
  ThunkAction,
  ShareLink,
  ShareLinkKind,
  InputShare,
} from "core/types";

export function shareLinkCreated(
  params: { kind: ShareLinkKind, projectId: string },
  shareLink?: ShareLink
): Action {
  const { kind, projectId } = params;

  return {
    type: "SHARE_LINK_CREATED",
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: "SHARE_LINK_CREATED",
          properties: { kind, projectId },
        },
      },
    },
    payload: { params, shareLink },
  };
}

export function trackShareLinkLoaded(shareLink: ShareLink): ThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    const userId = getCurrentUserId(state);
    const payload = {
      shareLinkId: shareLink.id,
      projectId: shareLink.descriptor.projectId,
      userId,
      public: shareLink.options.public,
      loggedIn: isLoggedIn(state),
    };

    dispatch(trackEvent("SHARE_LINK_LOADED", payload));
  };
}

export function trackShareLinkCopied(inputShare: InputShare): ThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    const userId = getCurrentUserId(state);
    const shareLink = getShareLinkForKind(state, { inputShare });

    if (!shareLink) {
      return;
    }

    dispatch(
      trackEvent("SHARE_LINK_COPIED", {
        shareLinkId: shareLink.id,
        projectId: shareLink.descriptor.projectId,
        userId,
        public: shareLink.options.public,
        loggedIn: isLoggedIn(state),
      })
    );
  };
}

export function trackShareLinkUpdated(shareLink: ShareLink): ThunkAction {
  return (dispatch, getState) => {
    const userId = getCurrentUserId(getState());

    dispatch(
      trackEvent("SHARE_LINK_UPDATED", {
        shareLinkId: shareLink.id,
        projectId: shareLink.descriptor.projectId,
        userId,
        public: shareLink.options.public,
        historyEnabled: shareLink.options.historyEnabled,
        inspectEnabled: shareLink.options.inspectEnabled,
      })
    );
  };
}
