// @flow
import { trackEvent } from "core/actions/analytics";
import { entitiesDeleted } from "core/actions/entities";
import { request } from "core/actions/requests";
import { showToast } from "core/actions/toasts";
import { push } from "core/lib/location";
import {
  OrganizationsFetchRequest,
  OrganizationFetchRequest,
} from "core/requests/organizations";
import {
  getOrganization,
  getDefaultOrganization,
  getUsernameOrganization,
} from "core/selectors/organizations";
import { organizationPath } from "web/routeHelpers";
import * as API from "../api";
import type { ThunkAction } from ".";

export function loadOrganizations(): ThunkAction {
  return async function (dispatch, getState) {
    /*
      If there's no username organization in the store, it means we either
      haven't called loadOrganizations yet or we tried to when there was no
      user logged in. Either way, it indicates that we should re-run the
      request regardless of whether we have a version of the request in the
      store.
    */
    const force = getUsernameOrganization(getState()) === undefined;

    await dispatch(
      OrganizationsFetchRequest.perform({ params: undefined, force })
    );
  };
}

export function loadOrganization(organizationId: string): ThunkAction {
  return OrganizationFetchRequest.perform({ params: { organizationId } });
}

export function deleteOrganization(organizationId: string): ThunkAction {
  return (dispatch, getState) => {
    const organization = getOrganization(getState(), { organizationId });
    return dispatch(
      request(
        `delete:organizations/${organizationId}`,
        () => API.deleteOrganization(organizationId),
        {
          onSuccess: (response) => {
            const defaultOrg = getDefaultOrganization(
              getState(),
              organizationId
            );
            push(organizationPath(defaultOrg.id));

            if (organization) {
              dispatch(
                showToast({
                  text: `Deleted the ${organization.name} organization`,
                })
              );
            }

            dispatch(entitiesDeleted({ organizations: [organizationId] }));

            dispatch(
              trackEvent("ORGANIZATION_DELETED", {
                organizationId,
              })
            );
          },
        }
      )
    );
  };
}
