// @flow
import { connect } from "react-redux";
import { isOnline } from "abstract-di/selectors";
import { createNewOrganization } from "core/actions/organizations";
import { type CreateOrganizationParams } from "core/requests/organizations";
import type { State, Dispatch } from "core/types";
import type { Props, OwnProps, StateProps, DispatchProps } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  return {
    isOnline: isOnline(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    createNewOrganization: (
      params: CreateOrganizationParams,
      onSuccessCallback?: () => void,
      onErrorCallback?: (Error) => void
    ) => {
      return dispatch(
        createNewOrganization(params, onSuccessCallback, onErrorCallback)
      );
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
