// @flow
import type { Action } from "../types";

export function focusedElement(elementId: string): Action {
  return { type: "core/FOCUSED_ELEMENT", payload: elementId };
}

export function presentationIdleState(idleState: boolean): Action {
  return { type: "core/PRESENTATION_IDLE", payload: idleState };
}
