// @flow
import { connect } from "react-redux";
import { isDevelopmentMenuEnabled } from "abstract-di/selectors";
import contextMenuWrapper, {
  type Props as ContextMenuWrapperProps,
} from "core/components/ContextMenu/ContextMenuWrapper";
import createConnector from "core/lib/createConnector";
import { getProject } from "core/selectors/projects";
import type { State, Dispatch } from "core/types";

import type { OwnProps, StateProps, Props } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const project = getProject(state, { projectId: props.projectId });
  return {
    organizationId: project ? project.organizationId : undefined,
    isDevelopment: isDevelopmentMenuEnabled(state),
  };
}

export default createConnector<
  Props,
  {|
    ...OwnProps,
    ...$Exact<ContextMenuWrapperProps>,
  |},
>(
  contextMenuWrapper,
  connect<Props, OwnProps, StateProps, _, State, Dispatch>(mapStateToProps)
);
