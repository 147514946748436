// @flow
import * as React from "react";
import Button from "core/components/Button";
import ButtonLink from "core/components/ButtonLink";
import FormHeader from "core/components/FormHeader";
import FormSection from "core/components/FormSection";
import Input from "core/components/Input";
import InputImage from "core/components/InputImage";
import Media from "core/components/Media";
import ProfileModal from "core/components/ProfileModal";
import Select from "core/components/Select";
import SettingsForm from "core/components/SettingsForm";
import SettingsItem from "core/components/SettingsItem";
import { V3Link as Link } from "core/lib/router";
import type { Account } from "core/types";
import withForm from "web/containers/withForm";
import { accountEmails } from "web/routeHelpers";
import type { FormProps, User, Email } from "web/types";
import DeleteAccountForm from "./DeleteAccountForm";
import style from "./style.scss";

export type StateProps = {|
  account: ?Account,
  emails: Email[],
  canManageProfile: boolean,
  canManageEmail: boolean,
|};

export type DispatchProps = {|
  onSubmit: (formId: string, values: Object) => void,
  onPreviewDeleteAccount: () => void,
  onLoad: () => void,
|};

export type Props = {
  ...FormProps,
  ...StateProps,
  ...DispatchProps,
};

type State = { showProfileModal: boolean, showDelete: boolean };

class ProfileEdit extends React.Component<Props, State> {
  imageInput: ?InputImage;
  state = { showProfileModal: false, showDelete: false };

  showDeleteDialog = (event: SyntheticEvent<>) => {
    event.preventDefault();
    this.props.onPreviewDeleteAccount();
    this.setState({ showDelete: true });
  };

  hideDeleteDialog = () => this.setState({ showDelete: false });

  toggleModal = (event?: SyntheticEvent<>) => {
    if (event) {
      event.preventDefault();
    }
    this.setState((prev) => ({ showProfileModal: !prev.showProfileModal }));
  };

  handleSubmit = (event: SyntheticEvent<>) => {
    event.preventDefault();
    const { values } = this.props.form;
    this.props.onSubmit(this.props.form.id, values);
  };

  handleChange = (event: SyntheticInputEvent<>) => {
    this.props.form.onChange(this.props.form.id, {
      [event.target.name]: event.target.value,
    });
  };

  handleAvatarChange = (dataUrl: string) => {
    if (dataUrl.length) {
      const values = { ...getDefaultValues(this.props), avatar: dataUrl };
      this.props.onSubmit(this.props.form.id, values);
    }
  };

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.form.submitSucceeded &&
      !this.props.form.isSubmitting &&
      this.imageInput &&
      this.imageInput.state.value
    ) {
      this.imageInput.setState({ value: "" });
    }
  }

  render() {
    const { form, account, emails, canManageProfile, canManageEmail } =
      this.props;

    if (!account) {
      return null; // Current user is not authenticated
    }

    const editedUser: User = { ...account, ...form.values };
    const restrictedHelper = {
      restrictedLabel: "This is managed by your administrator",
    };
    const header = (
      <Media desktop>
        {(desktop) => (
          <FormHeader
            mobile={!desktop}
            disabled={
              !form.isDirty ||
              form.isSubmitting ||
              (form.submitSucceeded && !form.isSubmitting)
            }
            error={form.error ? "Profile could not be updated." : ""}
            heading="Edit Profile"
            submitText={form.isSubmitting ? "Saving changes…" : "Save changes"}
            success={form.submitSucceeded}
            successMessage="Profile updated."
            icon="user"
          >
            <Button
              nude
              tint
              onClick={this.toggleModal}
              className={style.toggleButton}
            >
              Preview
            </Button>
            <ProfileModal
              {...editedUser}
              avatarUrl={form.values.avatar || account.avatarUrl}
              isOpen={this.state.showProfileModal}
              onClose={this.toggleModal}
            />
          </FormHeader>
        )}
      </Media>
    );

    return (
      <React.Fragment>
        <SettingsForm
          noValidate
          onSubmit={this.handleSubmit}
          header={header}
          documentTitle="Profile"
        >
          <FormSection heading="Account Information">
            <SettingsItem input>
              <Input
                requiredTag={canManageProfile}
                responsive
                error={form.errors.name}
                label="Name"
                name="name"
                onChange={this.handleChange}
                disabled={!canManageProfile}
                defaultValue={account.name}
                helpers={!canManageProfile ? restrictedHelper : {}}
              />
            </SettingsItem>
            <SettingsItem input>
              <Input
                requiredTag
                responsive
                autoCapitalize="none"
                error={form.errors.username}
                label="Username"
                name="username"
                onChange={this.handleChange}
                defaultValue={account.username}
              />
            </SettingsItem>
            <SettingsItem input>
              <Select
                disabled={!canManageEmail}
                responsive
                label="Email address"
                name="primaryEmailId"
                value={editedUser.primaryEmailId}
                onChange={this.handleChange}
                helpers={
                  canManageEmail
                    ? {
                        manageEmails: (
                          <Link
                            to={accountEmails()}
                            className={style.manageEmailsLink}
                          >
                            Manage your emails
                          </Link>
                        ),
                      }
                    : restrictedHelper
                }
              >
                {emails.map((email) => (
                  <option value={email.id} key={email.id}>
                    {email.email}
                  </option>
                ))}
              </Select>
            </SettingsItem>
            <SettingsItem input>
              <InputImage
                responsive
                isRemovable={false}
                ref={(r) => (this.imageInput = r)}
                alt={account.name}
                defaultValue={account.avatarUrl}
                error={form.errors.avatar}
                label="Your avatar"
                name="avatar"
                onChange={this.handleAvatarChange}
              />
            </SettingsItem>
          </FormSection>
          {canManageProfile && (
            <div className={style.deleteAccount}>
              <ButtonLink
                className={style.deleteButton}
                onClick={this.showDeleteDialog}
              >
                Would you like to completely remove your account?
              </ButtonLink>
            </div>
          )}
        </SettingsForm>
        {canManageProfile && (
          <DeleteAccountForm
            isOpen={this.state.showDelete}
            onClose={this.hideDeleteDialog}
          />
        )}
      </React.Fragment>
    );
  }
}

function getDefaultValues(props) {
  const account = props.account || {};

  return {
    name: account.name,
    email: account.email,
    primaryEmailId: account.primaryEmailId,
    username: account.username,
    avatar: "",
  };
}

export default withForm<{ ...StateProps, ...DispatchProps }>(
  ProfileEdit,
  "account-form",
  getDefaultValues
);
