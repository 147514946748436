// @flow
import * as React from "react";
import Button from "core/components/Button";
import ButtonLink from "core/components/ButtonLink";
import Input from "core/components/Input";
import ValidationError from "core/components/ValidationError";
import onboardingStyle from "core/components/onboarding.scss";
import withForm from "web/containers/withForm";
import type { FormProps } from "web/types";
import type { FormValues } from "./RequestAccessForm";
import RequestSentMessage from "./RequestSentMessage";
import style from "./style.scss";

type OwnProps = {
  onSelectLoginForm: () => void,
  onSubmit: (formId: string, values: FormValues) => void,
};

type Props = {
  ...OwnProps,
  ...FormProps,
};

class NewUserForm extends React.Component<Props> {
  handleLoginFormShown = (e: SyntheticEvent<>) => {
    e.preventDefault();
    this.props.onSelectLoginForm();
  };

  handleChange = (event: SyntheticInputEvent<>) => {
    this.props.form.onChange(this.props.form.id, {
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (event: SyntheticEvent<>) => {
    event.preventDefault();
    this.props.onSubmit(this.props.form.id, this.props.form.values);
  };

  render() {
    const { form } = this.props;

    if (form.submitSucceeded) {
      return <RequestSentMessage />;
    }

    return (
      <form onSubmit={this.handleSubmit}>
        <div className={style.message}>
          We will send your access request to a project admin.
          <br />
          Once it is approved, we’ll let you know by email.
        </div>
        <React.Fragment>
          <Input
            label={
              <span className={onboardingStyle.inputLabel}>Your name</span>
            }
            name="name"
            placeholder="Mika Sharp"
            className={onboardingStyle.input}
            onChange={this.handleChange}
            error={form.errors.name}
            required
          />
          <Input
            label={
              <span className={onboardingStyle.inputLabel}>
                Your email address
              </span>
            }
            name="email"
            type="email"
            className={onboardingStyle.input}
            placeholder="mika@banktm.com"
            onChange={this.handleChange}
            error={form.errors.email}
            wrapperClass={style.email}
            required
          />
          <Button
            disabled={!form.isDirty || this.props.form.isSubmitting}
            icon={this.props.form.isSubmitting ? "spinner" : undefined}
            className={onboardingStyle.button}
            type="submit"
            fullwidth
            primary
            large
          >
            {this.props.form.isSubmitting
              ? "Requesting access…"
              : "Request access"}
          </Button>
        </React.Fragment>
        {form.error && (
          <ValidationError
            className={style.formError}
            error="An error occured while submitting your information. Please double-check everything and try again."
          />
        )}
        <div className={style.footer}>
          Have an Abstract account?{" "}
          <ButtonLink onClick={this.handleLoginFormShown}>Sign in</ButtonLink>.
        </div>
      </form>
    );
  }
}

export default withForm<OwnProps>(NewUserForm, "new-user-request-access-form");
