// @flow
import classnames from "classnames";
import * as React from "react";
import Button from "core/components/Button";
import Card from "core/components/Card";
import style from "./style.scss";

type Props = {|
  className?: string,
  onClick?: () => void,
  icon?: string,
  label?: string,
  tint?: boolean,
  children?: React.Node,
|};

// Two ways to use this button: you can pass in an Icon, Label, and onClick
// for this component to assemble a button for you, or you can use
// your own children for more complex assemblies.

export default function FloatingControl(props: Props) {
  return (
    <Card
      minWidth="32px"
      className={classnames(style.card, props.className)}
      innerClassName={style.innerBox}
    >
      {props.children ? (
        props.children
      ) : (
        <Button
          nude
          tint={props.tint}
          tintHover={props.tint}
          icon={props.icon}
          onClick={props.onClick}
        >
          {props.label}
        </Button>
      )}
    </Card>
  );
}
