// @flow
import uuid from "uuid/v4";
import { readableFilesize } from "core/lib/textFormatting";
import type { Toast, Action, ThunkAction } from "core/types";

type ToastShape = $Shape<Toast>;

// showToast displays a popover that closes after a given duration.
export function showToast(toast: ToastShape, duration: number = 3500): Action {
  return {
    type: "core/TOAST_SHOWN",
    meta: { scope: "web" },
    payload: {
      id: toast.id || uuid(),
      autoClose: duration,
      ...toast,
    },
  };
}

// showCloseableToast displays a popover that persists until the user closes it.
export function showCloseableToast(toast: ToastShape): Action {
  return {
    type: "core/TOAST_SHOWN",
    meta: { scope: "web" },

    payload: {
      id: toast.id || uuid(),
      closeable: true,
      ...toast,
    },
  };
}

// showLoadingToast displays a popover with a spinning icon that persists until
// one of the returned methods is called
export function showLoadingToast(toast: ToastShape): ThunkAction {
  const id = toast.id || uuid();

  return (dispatch) => {
    dispatch({
      type: "core/TOAST_SHOWN",
      meta: { scope: "web" },
      payload: { id, ...toast },
    });

    return {
      id,
      dismiss: () => dispatch(dismissToast(id)),
      update: (params) => {
        dispatch({
          type: "core/TOAST_UPDATED",
          meta: { scope: "web" },

          payload: { id, ...params },
        });
      },
      error: (params) => {
        const defaults = {
          icon: "error",
          subtext: "An unexpected problem occurred, try again?",
          progress: undefined,
          closeable: true,
        };
        dispatch({
          type: "core/TOAST_UPDATED",
          meta: { scope: "web" },
          payload: { id, ...defaults, ...params },
        });
      },
      cliFetchProgress: (progress, message) => {
        let subtext = `Syncing ${message.entityType}…`;

        if (message.downloading && message.rate) {
          // readableFilesize expects bytes but rate is in kb
          // https://goabstract.atlassian.net/browse/PLATFORM-1382
          const rateInBytes = message.rate * 1000;
          subtext = `Syncing ${message.entityType}… (${readableFilesize(
            rateInBytes
          )}/s)`;
        }

        dispatch({
          type: "core/TOAST_UPDATED",
          meta: { scope: "web" },
          payload: {
            id,
            subtext,
            progress,
          },
        });
      },
      cliDownloadProgress: (progress, message) => {
        let subtext = `Downloading ${message.entityType}…`;
        dispatch({
          type: "core/TOAST_UPDATED",
          meta: { scope: "web" },
          payload: {
            id,
            subtext,
            progress,
          },
        });
      },
      showClosable: (toast) => {
        dispatch(showCloseableToast({ id, ...toast }));
      },
      showAutoClose: (toast, duration) => {
        dispatch(showToast({ id, ...toast }, duration));
      },
    };
  };
}

export function dismissToast(id: string): Action {
  return {
    type: "core/TOAST_DISMISSED",
    meta: { scope: "web" },
    payload: id,
  };
}
