// @flow
import { connect } from "react-redux";
import { isOnline, getRepoIsSyncing } from "abstract-di/selectors";
import { CommitsFetchRequest } from "core/requests/commits";
import {
  getBranch,
  getBranchHead,
  getParentBranch,
} from "core/selectors/branches";
import { getCommentCounts } from "core/selectors/comments";
import {
  getCommitsForEntity,
  getCommitsForEntityHasMore,
} from "core/selectors/commits";
import { isBranchSynced } from "core/selectors/localBranches";
import { getProject } from "core/selectors/projects";
import type { Dispatch, State } from "core/types";
import {
  type OwnProps,
  type StateProps,
  type DispatchProps,
  type Props,
} from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { projectId, branchId, fileId = "", layerId = "" } = props.params;
  const project = getProject(state, { projectId });
  const branch = getBranch(state, { projectId, branchId });
  const head = getBranchHead(state, { projectId, branchId });
  const parentBranch = getParentBranch(state, { projectId, branchId });
  const commits = getCommitsForEntity(state, {
    projectId,
    branchId,
    fileId,
    layerId,
  });
  const hasMore = getCommitsForEntityHasMore(state, {
    projectId,
    branchId,
    fileId,
    layerId,
  });
  const commitCommentCounts = getCommentCounts(state, {
    projectId,
    branchId,
    key: "commitSha",
  });
  const isLoading = CommitsFetchRequest.isLoading(state, {
    head,
    projectId,
    branchId,
    fileId,
    layerId,
  });
  const hasError = CommitsFetchRequest.hasError(state, {
    head,
    projectId,
    branchId,
    fileId,
    layerId,
  });
  const isOffline = !isOnline(state);

  return {
    head,
    isBranchSynced: isBranchSynced(state, { branchId, projectId }),
    isSyncing: getRepoIsSyncing(state, {
      projectId,
      branchId,
    }),
    project,
    branch,
    parentBranch,
    commits,
    commitCommentCounts,
    isLoading,
    hasError,
    hasMore,
    isOffline,
  };
}

function getCommitsRequestDescriptor(props: OwnProps) {
  if (props.params.layerId) {
    return {
      projectId: props.params.projectId,
      branchId: props.params.branchId,
      fileId: props.params.fileId,
      layerId: props.params.layerId,
    };
  }

  if (props.params.sha) {
    return {
      projectId: props.params.projectId,
      branchId: props.params.branchId,
    };
  }

  return props.params;
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    onMount(head: string) {
      dispatch(
        CommitsFetchRequest.perform({
          params: {
            ...getCommitsRequestDescriptor(props),
            head,
          },
        })
      );
    },
    onLoadCommits(options: { startSha: string } = {}) {
      dispatch(
        CommitsFetchRequest.perform({
          params: {
            ...getCommitsRequestDescriptor(props),
            startSha: options.startSha,
          },
        })
      );
    },
    onHeadChange(head) {
      dispatch(
        CommitsFetchRequest.perform({
          params: { ...getCommitsRequestDescriptor(props), head },
        })
      );
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
