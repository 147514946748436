// @flow
import pluralize from "pluralize";
import * as React from "react";
import Icon from "../Icon";
import Popover from "../Popover";

type Props = {
  className?: string,
  count?: number,
  selected?: boolean,
  subject?: string,
};

export default function CommentsIcon({
  className,
  subject = "commit",
  count = 0,
  selected,
}: Props) {
  if (count <= 0) {
    return null;
  }

  return (
    <Popover
      placement="bottom"
      label={`${count} ${pluralize("comment", count)} on this ${subject}`}
    >
      <Icon className={className} type="comment-default" light={selected} />
    </Popover>
  );
}
