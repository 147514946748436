// @flow
import createCachedSelector from "@elasticprojects/re-reselect";
import { filter } from "lodash";
import { getRawEntities, getEntity } from "core/selectors/entities";
import { getOrganizationId, maybeGetUserId } from "core/selectors/helpers";
import type { State, Membership, SubscriptionRole } from "core/types";

type UserRole = "owner" | "member" | "guest";

const maybeGetUserRole = (state: State, props: { role?: UserRole }) =>
  props.role;

export const getUserOrganizationMembership = (
  state: State,
  { userId, organizationId }: { userId: string, organizationId: string }
): Membership => state.entities.memberships[`${organizationId}-${userId}`];

const maybeGetSubscriptionRole = (
  state: State,
  props: { subscriptionRole?: SubscriptionRole }
) => props.subscriptionRole;

function cacheMemberships(
  state: State,
  { organizationId, role = "", subscriptionRole = "", userId = "" }
) {
  return `${organizationId}-${userId}-${role}-${subscriptionRole}`;
}

export function getMembershipEntities(state: State): {
  [id: string]: Membership,
} {
  return getRawEntities(state, "memberships");
}

export function getMembership(state: State, id: string): ?Membership {
  return getEntity(state, "memberships", id);
}

export type MembershipParams = {
  organizationId: string,
  userId?: string,
  role?: UserRole,
  subscriptionRole?: SubscriptionRole,
};

export const getMemberships: (
  state: State,
  props: MembershipParams
) => Membership[] = createCachedSelector(
  getMembershipEntities,
  getOrganizationId,
  maybeGetUserId,
  maybeGetUserRole,
  maybeGetSubscriptionRole,
  (memberships, organizationId, userId, role, subscriptionRole) => {
    const params = Object.assign(
      {},
      { organizationId },
      role !== undefined ? { role } : null,
      userId !== undefined ? { userId } : null,
      subscriptionRole !== undefined ? { subscriptionRole } : null
    );
    return filter(memberships, params);
  }
)(cacheMemberships);
