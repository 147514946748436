// @flow
import { connect } from "react-redux";
import { getCurrentUserId } from "abstract-di/selectors";
import {
  leaveProject,
  removeProjectMember,
} from "core/actions/projectMemberships";
import {
  AddProjectMembershipRequest,
  UpdateProjectMembershipRequest,
} from "core/requests/projectMemberships";
import { getProjectMembership } from "core/selectors/projectMemberships";
import type { State, Dispatch } from "core/types";
import type { Props, OwnProps, StateProps, DispatchProps } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { project, user } = props;
  const projectId = project.id;
  const userId = user && user.id;

  const currentUserId = getCurrentUserId(state);
  const membership = userId
    ? getProjectMembership(state, {
        projectId,
        userId,
      })
    : undefined;
  const isOrganizationAdmin = membership
    ? membership.isOrganizationOwner
    : false;

  let isUpdateMembershipRequestLoading = false;
  if (userId) {
    isUpdateMembershipRequestLoading = isOrganizationAdmin
      ? AddProjectMembershipRequest.isLoadingStrict(state, {
          projectId,
          userId,
        })
      : UpdateProjectMembershipRequest.isLoadingStrict(state, {
          projectId,
          userId,
        });
  }

  return {
    membership,
    currentUserId,
    isUpdateMembershipRequestLoading,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { project, user } = props;
  const projectId = project.id;
  const userId = user && user.id;

  return {
    leaveProject() {
      return dispatch(leaveProject(projectId, true));
    },
    removeMember() {
      if (userId) {
        return dispatch(removeProjectMember(projectId, userId));
      }
    },
    updateRole(role) {
      if (userId) {
        return dispatch(
          UpdateProjectMembershipRequest.perform({
            params: { projectId, userId, role },
          })
        );
      }
    },
    updateRoleForOrgAdmin(role) {
      if (userId) {
        return dispatch(
          AddProjectMembershipRequest.perform({
            params: { projectId, userId, role },
          })
        );
      }
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
