// @flow
import classnames from "classnames";
import * as React from "react";
import Breakpoint from "../Breakpoint";
import Flex from "../Flex";
import Heading from "../Heading";
import Mobile from "./Mobile";
import style from "./style.scss";

type Props = {
  title: React.Node,
  filters?: React.Node,
  actions?: React.Node,
  className?: string,
  mobile?: boolean,
  icon?: string,
  breakpoint?: number | "mobile",
  children?: React.Node,
};

export default function ContentHeader({
  title,
  filters,
  actions,
  className,
  mobile,
  breakpoint,
  ...rest
}: Props) {
  if (mobile) {
    return (
      <Mobile
        {...rest}
        title={title}
        filters={filters}
        actions={actions}
        className={className}
      />
    );
  }

  return (
    <Breakpoint at={breakpoint}>
      {({ above }) => (
        <div
          className={classnames(style.shadowWrapper, {
            [style.largeContainer]: above,
          })}
        >
          <div className={style.shadow} />
          <Flex
            align="center"
            className={classnames(style.container, className)}
          >
            <div className={classnames(style.headerTop, style.left)}>
              <Flex align="center" grow={false}>
                <Heading level="1" size="xl" id="aria-pagetitle">
                  {title}
                </Heading>
              </Flex>
            </div>
            <div className={classnames(style.headerTop, style.right)}>
              <Flex
                align="center"
                justify="flex-end"
                className={style.rightInner}
              >
                {actions || null}
              </Flex>
            </div>
            <div className={classnames(style.headerBottom, style.middle)}>
              <Flex align="center" justify="center">
                {filters || null}
              </Flex>
            </div>
          </Flex>
        </div>
      )}
    </Breakpoint>
  );
}
