// @flow
import type { Organization } from "core/types";

export function hasVersionsEnabled(organization: Organization) {
  return organization.versionsEnabled;
}

export function onlyHasVersionsEnabled(organization: Organization) {
  return hasVersionsEnabled(organization);
}

export function canUseVersions(organization: Organization) {
  return hasVersionsEnabled(organization);
}
