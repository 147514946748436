// @flow
import createCachedSelector from "@elasticprojects/re-reselect";
import empty from "empty";
import { groupBy } from "lodash";
import type { DraftBranch, State } from "core/types";
import { getProjectId } from "./helpers";

export function getDraftBranchesForProject(
  state: State,
  params: { projectId: string }
): { [draftBranchId: string]: DraftBranch } {
  const forProject = state.draftBranches[params.projectId];
  return forProject ? forProject : empty.object;
}

export const getDraftBranchesByParent: (
  State,
  { projectId: string }
) => {
  [parent: string]: DraftBranch[],
} = createCachedSelector(
  getDraftBranchesForProject,
  getProjectId,
  (draftBranches, projectId) => groupBy(draftBranches, (tb) => tb.parent)
)(getProjectId);
