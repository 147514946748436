import { getConfiguredCache } from "money-clip";
import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import * as asyncInitialState from "redux-async-initial-state";
import { createLogger } from "redux-logger";
import getPersistMiddleware from "redux-persist-middleware";
import { createTracker } from "redux-segment";
import reduxThunk from "redux-thunk";
import { static as Immutable } from "seamless-immutable";
import window from "core/global/window";
import * as Request from "core/models/request";
import { REDUX_LOGGER_ENABLED, SEGMENT_ENABLED } from "./config";
import reducers from "./reducers";
import { clearToken } from "./token";

// Here we use the money-clip library to create an object of cache functions
// with these options pre-applied – if we ever need to bust the cache this
// version number can be incremented
const cache = getConfiguredCache({
  version: 1,
  maxAge: 30 * 12 * 60 * 60 * 1000,
});

// Configure our middleware
const persistMiddleware = getPersistMiddleware({
  cacheFn: (key, value) => cache.set(key, value),

  // optionally logs out which action triggered
  // something to be cached and what reducers
  // were persisted as a result.
  //logger: console.info,

  // We pass in the mapping of action types to
  // reducers that should be persisted
  actionMap: {
    "core/ENTITIES_RECEIVED": ["features"],
    SESSION_LOADED: ["session"],
    SESSION_DELETED: ["session"],
    DEVTOOLS_OPENED: ["devtools"],
    DEVTOOLS_CLOSED: ["devtools"],
    DEVTOOLS_FEATURE_OVERRIDDEN: ["devtools"],
    DEVTOOLS_FEATURE_RESET: ["devtools"],
  },
});

function actionTransformer(action) {
  if (typeof action.type !== "symbol") {
    return action;
  }

  return { ...action, type: String(action.type) };
}

function stateTransformer(state) {
  return Immutable.asMutable(state, { deep: true });
}

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const unauthorizedMiddleware = (store) => (next) => (action) => {
  if (
    action.type === "core/REQUEST_ERROR" &&
    action.payload instanceof Request.UnauthorizedError
  ) {
    clearToken();
  }

  return next(action);
};

export default function () {
  const middleware = [reduxThunk, unauthorizedMiddleware, persistMiddleware];

  if (SEGMENT_ENABLED) {
    const tracker = createTracker();
    middleware.push(tracker);
  }

  if (REDUX_LOGGER_ENABLED) {
    const logger = createLogger({ actionTransformer, stateTransformer });
    middleware.push(logger);
  }

  const enhancer = composeEnhancer(
    applyMiddleware(
      ...middleware,
      asyncInitialState.middleware(async () => {
        const data = await cache.getAll();
        return Immutable.from(data);
      })
    )
  );

  // We need outerReducer to replace full state as soon as it loaded
  const reducer = asyncInitialState.outerReducer(
    combineReducers({
      ...reducers,
      asyncInitialState: asyncInitialState.innerReducer,
    })
  );

  const rootReducer = (state, action) => {
    // We need rootReducer to replace full state when user signs out except for
    // asyncInitialState which must be retained as the state has already been
    // hydrated from local storage
    return reducer(
      action.type === "SESSION_DELETED"
        ? { asyncInitialState: state.asyncInitialState }
        : state,
      action
    );
  };

  return createStore(rootReducer, enhancer);
}
