// @flow
import * as React from "react";
import { replace } from "core/lib/location";
import type { ReactRouterLocation } from "core/types";

type Props = {
  children: React.Node,
  location: ReactRouterLocation,
};

export default class RequireOnboardingFlag extends React.Component<Props> {
  componentDidMount() {
    this.redirectIfNecessary();
  }

  componentDidUpdate() {
    this.redirectIfNecessary();
  }

  redirectIfNecessary = () => {
    if (!(this.props.location.state && this.props.location.state.onboarding)) {
      replace("/");
    }
  };

  render() {
    return this.props.children;
  }
}
