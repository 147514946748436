// @flow
import { schema, normalize } from "normalizr";
import { emoji, user } from "core/schema";
import { envelopeSchema } from "core/schemas/envelope";

const emojiListSchema = new schema.Object({
  emojis: [emoji],
  users: [user],
});

const emojisFetchResponseSchema = envelopeSchema(emojiListSchema);

export function normalizeEmojisFetchResponse(response: *) {
  return normalize(response, emojisFetchResponseSchema);
}

const emojiSchema = envelopeSchema(
  new schema.Object({
    emoji: emoji,
  })
);

export function normalizeEmojiResponse(response: *) {
  return normalize(response, emojiSchema);
}
