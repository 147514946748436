// @flow
import { static as Immutable } from "seamless-immutable";
import type { Action, DraftBranch } from "core/types";

type DraftBranchesKeyedById = {
  [draftBranchId: string]: DraftBranch,
};

type State = {
  [projectId: string]: DraftBranchesKeyedById,
};

export default function (state: State = Immutable.from({}), action: Action) {
  switch (action.type) {
    case "DRAFT_BRANCH_STARTED": {
      const { projectId, id } = action.payload;
      return Immutable.setIn(state, [projectId, id], action.payload);
    }
    case "DRAFT_BRANCH_PROGRESS": {
      return Immutable.setIn(
        Immutable.setIn(
          state,
          [action.meta.projectId, action.meta.id, "progress"],
          action.payload.progress
        ),
        [action.meta.projectId, action.meta.id, "rate"],
        action.payload.rate
      );
    }
    case "DRAFT_BRANCH_ERROR": {
      return Immutable.setIn(
        state,
        [action.meta.projectId, action.meta.id, "error"],
        action.payload
      );
    }
    case "DRAFT_BRANCH_SUCCESS": {
      return Immutable.without(state, [action.meta.projectId, action.meta.id]);
    }
    default:
      return state;
  }
}
