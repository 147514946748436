// @flow
import {
  type MarkNotificationsParams,
  MarkNotificationsRequest,
} from "core/requests/notifications";
import type { ThunkAction } from "core/types";

export function markNotifications(params: MarkNotificationsParams) {
  return {
    type: "core/NOTIFICATIONS_MARKED",
    meta: {
      readState: params.readState,
    },
    payload: params.notificationIds || [],
  };
}

export function markNotificationsAsRead(params: {|
  commentIds?: string[],
  notificationIds?: string[],
|}): ThunkAction {
  return async function (dispatch) {
    return dispatch(
      MarkNotificationsRequest.perform({
        params: { ...params, readState: "read" },
      })
    );
  };
}
