// @flow
import * as React from "react";
import window from "core/global/window";
import {
  type ThemeConfig,
  type ThemeName,
  resolveThemeName,
  setThemeAttribute,
  setTheme,
  getDefaultThemeConfig,
} from "core/lib/theme";

type Props = {
  defaultThemeConfig: ThemeConfig,
  children: React.Node,
};

type State = {
  themeName: ThemeName,
  setThemeConfig: (themeConfig: ThemeConfig) => void,
  themeConfig: ThemeConfig,
};

export type ThemeContextType = {
  themeName: ThemeName,
  themeConfig: ThemeConfig,
  setThemeConfig: (themeConfig: ThemeConfig) => void,
};

const DARK_THEME_QUERY = "(prefers-color-scheme: dark)";

const ThemeContext = React.createContext<ThemeContextType>({
  themeName: "light",
  themeConfig: "light",
  setThemeConfig: () => {},
});

ThemeContext.displayName = "ThemeContext";

class ThemeProvider extends React.Component<Props, State> {
  mediaQueryDark = window.matchMedia(DARK_THEME_QUERY);
  static defaultProps = {
    defaultThemeConfig: getDefaultThemeConfig(),
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      themeConfig: props.defaultThemeConfig,
      themeName: resolveThemeName(props.defaultThemeConfig),
      setThemeConfig: this.setThemeConfig,
    };

    if (props.defaultThemeConfig !== "system") {
      setThemeAttribute(props.defaultThemeConfig);
    }
  }

  componentDidMount = () => {
    if (this.state.themeConfig === "system") {
      this.subscribeSystemThemeChange();
    }
  };

  componentWillUnmount = () => {
    if (this.state.themeConfig === "system") {
      this.unsubscribeSystemThemeChange();
    }
  };

  /*
   * This method can be used in context consumer to cutomize the theme
   */
  setThemeConfig = (themeConfig: ThemeConfig) => {
    setTheme(themeConfig); // Saves the theme selection for future visits.
    this.setState({
      themeConfig,
      themeName: resolveThemeName(themeConfig),
    });

    if (this.state.themeConfig === "system" && themeConfig !== "system") {
      this.unsubscribeSystemThemeChange();
    } else if (
      this.state.themeConfig !== "system" &&
      themeConfig === "system"
    ) {
      this.subscribeSystemThemeChange();
    }
  };

  handleSystemThemeChange = (event: MediaQueryListEvent) => {
    const themeName = event.matches ? "dark" : "light";
    this.setState({ themeName });
  };

  subscribeSystemThemeChange = () => {
    this.mediaQueryDark.addListener(this.handleSystemThemeChange);
  };

  unsubscribeSystemThemeChange = () => {
    this.mediaQueryDark.removeListener(this.handleSystemThemeChange);
  };

  render() {
    if (!this.state.themeName) {
      return null;
    }

    return (
      <ThemeContext.Provider
        children={this.props.children}
        value={this.state}
      />
    );
  }
}

type ConsumerProps = {
  children: ({
    themeName: ThemeName,
    themeConfig: ThemeConfig,
    setThemeConfig: (themeConfig: ThemeConfig) => void,
  }) => React.Node,
};

function ThemeNameConsumer({ children }: ConsumerProps) {
  return <ThemeContext.Consumer>{children}</ThemeContext.Consumer>;
}

const Theme = {
  ThemeContext,
  Provider: ThemeProvider,
  Consumer: ThemeNameConsumer,
};

export default Theme;
