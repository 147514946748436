// @flow
import classnames from "classnames";
import * as React from "react";
import { V3Link as Link } from "core/lib/router";
import style from "./style.scss";

type Props = {
  className?: string,
  children?: React.Node,
  input?: boolean,
  switch?: boolean,
  to?: string,
  label?: React.Node,
  secondaryLabel?: React.Node,
  responsive?: boolean,
  role?: string,
  wide?: boolean,
  qaSelector?: string,
};

export default class SettingsItem extends React.Component<Props> {
  render() {
    const Component = this.props.to ? Link : "div";
    return (
      <Component
        role="presentation"
        to={this.props.to}
        className={classnames(style.item, {
          [style.inputItem]: this.props.input,
          [style.linkItem]: this.props.to,
          [style.switchItem]: this.props.switch,
          [style.responsiveItem]: this.props.responsive,
        })}
        data-qa={this.props.qaSelector}
      >
        <div
          role={this.props.role}
          className={classnames(style.box, this.props.className)}
        >
          {this.props.label && (
            <div
              className={classnames(style.itemLabel, {
                [style.wide]: this.props.wide,
              })}
            >
              {this.props.label}
            </div>
          )}
          {this.props.label ? (
            <div
              className={classnames(style.itemContent, {
                [style.wide]: this.props.wide,
              })}
            >
              {this.props.children}
            </div>
          ) : (
            this.props.children
          )}
          {this.props.secondaryLabel && (
            <div className={style.secondary}>{this.props.secondaryLabel}</div>
          )}
        </div>
      </Component>
    );
  }
}
