// @flow
import * as React from "react";
import IntegrationSettingsItem from "web/components/IntegrationSettingsItem";
import slackIcon from "./images/slack.png";
import style from "./style.scss";

type Props = {
  handleConnect: (string) => Promise<void>,
  connected?: boolean,
  integrationUrl?: string,
};

export default function SlackSettingsItem(props: Props) {
  const icon = (
    <img
      className={style.icon}
      src={slackIcon}
      alt="Slack app icon"
      height="40"
      width="40"
    />
  );
  return (
    <IntegrationSettingsItem
      title="Slack"
      description="Auto-posts Branches comments, commits, and merges."
      icon={icon}
      handleConnect={() => props.handleConnect("Slack")}
      connected={props.connected}
      integrationUrl={props.integrationUrl}
    />
  );
}
