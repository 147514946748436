// @flow
import { connect } from "react-redux";
import { getOrganization } from "core/selectors/organizations";
import type { State, Dispatch } from "core/types";
import { acceptInvitation, resetInvitation } from "web/actions/invitations";
import Invitation, {
  type ConnectedProps,
  type OwnProps,
  type StateProps,
  type DispatchProps,
} from "web/components/InvitationFlow/Invitation";
import { getProject } from "web/selectors/projects";
import { getCurrentUser } from "web/selectors/session";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const invitation = state.invitation.entity;
  const acceptedOrganization =
    !invitation.projectId &&
    invitation.organizationId &&
    !!getOrganization(state, { organizationId: invitation.organizationId });
  const acceptedProject =
    invitation.projectId && !!getProject(state, invitation.projectId);

  return {
    invitation,
    currentUser: getCurrentUser(state),
    alreadyAccepted: !!(acceptedOrganization || acceptedProject),
    isOnboarding: !!(props.location.state && props.location.state.onboarding),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    acceptInvitation: (formId, token) => {
      dispatch(acceptInvitation(formId, token));
    },
    resetInvitation: () => {
      dispatch(resetInvitation());
    },
  };
}

export default connect<
  ConnectedProps,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(
  mapStateToProps,
  mapDispatchToProps
)(Invitation);
