// @flow
import * as React from "react";
import Icon from "core/components/Icon";

type Props = {
  color?: string,
  solid?: boolean,
};

export default function ProjectIcon({
  color = "#8B8E8F",
  solid = true,
  ...rest
}: Props) {
  return (
    <Icon {...rest} type={solid ? "project-alt" : "project"} fill={color} />
  );
}
