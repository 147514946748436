// @flow
import * as Abstract from "abstract-sdk";
import { entitiesReceived } from "core/actions/entities";
import abstract from "core/lib/abstract";
import defineRequest from "core/requests";
import { normalizePages } from "core/schemas/page";
import { getTransportModeForBranch } from "core/selectors/sdk";

export const PagesFetchRequest = defineRequest<
  Abstract.FileDescriptor,
  Abstract.FileDescriptor,
>({
  id(params) {
    return [params.projectId, params.branchId, params.fileId, params.sha].join(
      "-"
    );
  },
  perform(descriptor, dispatch, getState) {
    return abstract.pages.list(descriptor, {
      transportMode: getTransportModeForBranch(getState(), {
        projectId: descriptor.projectId,
        branchId: descriptor.branchId,
      }),
    });
  },
  onSuccess(response, descriptor, dispatch) {
    const { entities } = normalizePages(response);
    dispatch(entitiesReceived(entities));
  },
});
