// @flow
import * as React from "react";
import ContextMenu, {
  type Props as ContextMenuProps,
} from "core/components/ContextMenu";
import { isDesktop } from "core/lib/platform";
import * as File from "core/models/file";
import type {
  Project,
  Policy,
  Branch,
  File as TFile,
  MenuItem,
  Page,
} from "core/types";
import connector from "./connector";

export type OwnProps = {|
  projectId: string,
  branchId: string,
  file: TFile,
  onAfterClose?: () => void,
  innerRef?: React.Ref<typeof ContextMenu>,
  children: $PropertyType<ContextMenuProps, "children">,
|};

export type StateProps = {|
  project: ?Project,
  branch: ?Branch,
  policy: Policy,
  organizationPolicy: Policy,
  libraries: Page[],
  userIsBranchAuthor: boolean,
  head: string,
  isEditable: boolean,
  isOpening: boolean,
  isDevelopment: boolean,
  isOnline: boolean,
  shouldShowLibraryActions: boolean,
  canGenerateAssets: boolean,
|};

export type DispatchProps = {|
  generateAssetsForFile: () => void,
  editFile: () => void,
  openUntracked: () => void,
  exportFile: (project: Project, branch: Branch) => void,
  renameFile: () => void,
  replaceFile: () => void,
  clearCacheAndEditingFile: () => void,
  manageLibraries: () => void,
  showDialog: (name: string, props: {}) => void,
  deleteFile: () => void,
  openInFinder: () => void,
  openInTerminal: () => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

function FileMenu(props: Props) {
  const { projectId, branchId, file } = props;

  const toggleIsLibrary = () => {
    const library = {
      fileId: file.id,
      name: file.name,
      type: file.type,
    };

    if (file.isLibrary) {
      props.showDialog("ConfirmRemoveLibrary", {
        projectId,
        branchId,
        library,
      });
    } else {
      props.showDialog("ConfirmAddLibrary", {
        projectId,
        branchId,
        library,
      });
    }
  };

  const menuItems = (): Array<MenuItem> => {
    const isHeadSha = file.sha === props.head;
    const appName = File.appName(file);
    const canOpenFile = appName === "Sketch" && !props.isOpening;
    return [
      {
        label: `Edit File in ${appName}`,
        click: props.editFile,
        visible: props.isEditable && props.policy.createCommit === true,
        enabled: canOpenFile,
      },
      {
        label: "Open Untracked",
        click: props.openUntracked,
        visible: canOpenFile && props.policy.openFile === true,
      },
      { type: "separator" },
      {
        label: "Delete…",
        click: props.deleteFile,
        enabled: props.isEditable,
        visible: isHeadSha && props.policy.createCommit === true && isDesktop,
      },
      { type: "separator" },
      {
        label: "Use as Library…",
        type: "checkbox",
        checked: file.isLibrary,
        click: toggleIsLibrary,
        visible:
          props.shouldShowLibraryActions &&
          isHeadSha &&
          props.policy.createCommit === true &&
          isDesktop,
      },
      {
        label: "Rename…",
        click: props.renameFile,
        enabled: props.isEditable,
        visible: isHeadSha && props.policy.createCommit === true && isDesktop,
      },
      {
        label: "Replace…",
        click: props.replaceFile,
        enabled: props.isEditable,
        visible: isHeadSha && props.policy.createCommit === true && isDesktop,
      },
      {
        label: "Manage Libraries…",
        click: props.manageLibraries,
        visible:
          props.libraries.length > 0 &&
          isHeadSha &&
          props.policy.createCommit === true &&
          isDesktop,
      },
      { type: "separator" },
      {
        type: "share",
        label: "Copy Link to File",
        props: {
          kind: "file",
          organizationId: props.project ? props.project.organizationId : "",
          projectId,
          branchId,
          fileId: file.id,
          commitSha: file.sha,
        },
        visible: props.policy.share === true,
        enabled: props.isOnline,
      },
      { type: "separator" },
      {
        label: "Export…",
        click: props.exportFile,
        visible: props.policy.openFile === true && isDesktop,
      },
      {
        label: "Generate Assets",
        click: props.generateAssetsForFile,
        visible:
          props.organizationPolicy.createAssets === true &&
          props.canGenerateAssets === true &&
          isDesktop,
      },
      { type: "separator", visible: props.isDevelopment },
      {
        label: "Development",
        visible: props.isDevelopment,
        submenu: [
          {
            label: "Open in Finder",
            click: props.openInFinder,
          },
          {
            label: "Open in Terminal",
            click: props.openInTerminal,
          },
          {
            label: `Clear ${appName} File`,
            click: props.clearCacheAndEditingFile,
          },
          {
            type: "copy",
            label: "Copy File ID",
            text: file.id,
          },
        ],
      },
    ];
  };
  return (
    <ContextMenu
      id={props.file.id}
      ref={props.innerRef}
      menuItems={menuItems()}
      placement="bottom-end"
      children={props.children}
      onAfterClose={props.onAfterClose}
    />
  );
}

export default connector(FileMenu);
