// @flow
import classnames from "classnames";
import * as React from "react";
import InputWrapper from "../InputWrapper";
import style from "./style.scss";

let counter = 0;

type Props = {
  id?: string,
  error?: string | string[],
  label?: React.Node,
  responsive?: boolean,
  requiredTag?: boolean,
  wrapperClass?: string,
  helpers?: {
    [key: string]: React.Node,
  },
};

export default class InputSwitch extends React.Component<Props> {
  id: string;

  constructor() {
    super();
    this.id = `abstract-core-input-switch-${counter}`;
    counter += 1;
  }

  render() {
    const {
      error,
      label,
      responsive,
      requiredTag,
      helpers,
      wrapperClass,
      ...props
    } = this.props;
    props.id = props.id || this.id;

    return (
      <InputWrapper
        inline
        error={error}
        inputId={props.id}
        label={label}
        helpers={helpers}
        responsive={responsive}
        requiredTag={requiredTag}
        className={classnames(style.inputWrapper, wrapperClass)}
      >
        <div className={style.switch}>
          <input {...props} type="checkbox" />
          <div />
        </div>
      </InputWrapper>
    );
  }
}
