// @flow
/* global HTMLElement */
import classnames from "classnames";
import invariant from "invariant";
import * as React from "react";
import { Waypoint } from "react-waypoint";
import Heading from "core/components/Heading";
import InputRich from "core/components/InputRich";
import Markdown from "core/components/Markdown";
import KeyCode from "core/lib/keycode";
import { DEFAULT_COLLECTION_VALUES } from "core/models/collection";
import type { Collection } from "core/types";
import style from "./style.scss";

export default function EditableHeader(props: {
  canEdit: boolean,
  collection: Collection,
  onChangeName: (newName: string) => void,
  onChangeDescription: (newDescription: string) => void,
  onEnter: () => void,
  onLeave: () => void,
  projectId: string,
}) {
  const descriptionInput = React.useRef();

  const [isDescriptionEditable, setIsDescriptionEditable] =
    React.useState(false);

  const [currentDescription, setCurrentDescription] = React.useState(
    props.collection.description || ""
  );

  const [currentName, setCurrentName] = React.useState(
    props.collection.name === DEFAULT_COLLECTION_VALUES.name
      ? ""
      : props.collection.name || ""
  );

  const handleSubmitDescription = () => {
    setIsDescriptionEditable(false);
    props.onChangeDescription(currentDescription);
  };

  const handleChangeName = (newName: string) => {
    if (props.canEdit && newName !== "") {
      setCurrentName(newName);
      props.onChangeName(newName);
    }
  };

  const handleClickDescription = () => {
    if (props.canEdit) {
      setIsDescriptionEditable(true);
      invariant(descriptionInput.current, "input required to focus");
      descriptionInput.current.focus();
    }
  };

  const handleKeyDownOnDescription = (event: SyntheticKeyboardEvent<>) => {
    if (event.keyCode === KeyCode.KEY_RETURN) {
      handleClickDescription();
    }
  };

  const handleKeyDownOnNameInput = (event: SyntheticKeyboardEvent<>) => {
    if (event.keyCode === KeyCode.KEY_RETURN) {
      handleClickDescription();
    }
  };

  const handleKeyDownOnDescriptionInput = (event: SyntheticKeyboardEvent<>) => {
    if (event.keyCode === KeyCode.KEY_ESCAPE) {
      event.stopPropagation();
      setCurrentDescription(props.collection.description);
      setIsDescriptionEditable(false);
    }
  };

  const shouldShowCollectionDescriptionInput = (() => {
    if (!props.canEdit) {
      return false;
    }

    if (isDescriptionEditable) {
      return true;
    }

    // If the description is empty, it's always editable.
    // Otherwise, there would be nothing to click in order to make the field editable.
    if (
      !props.collection ||
      !props.collection.description ||
      !currentDescription
    ) {
      return true;
    }

    return false;
  })();

  return (
    <React.Fragment>
      {props.canEdit ? (
        <InputRich
          onKeyDown={handleKeyDownOnNameInput}
          onSubmit={(event) => {
            if (event.target instanceof HTMLElement) {
              event.target.blur();
            }
          }}
          tabIndex="0"
          disabled={!props.canEdit}
          disallowNewLines
          value={currentName}
          onChange={handleChangeName}
          autoFocus={props.collection.name === DEFAULT_COLLECTION_VALUES.name}
          className={classnames(style.headerTitle, style.headerInput)}
          wrapperClass={style.headerInputWrapper}
          debounce={1000}
          placeholder="Add a title"
          autocompleteDisabled
        />
      ) : (
        <Heading level="1" size="xxxl" className={style.headerTitle}>
          {props.collection.name || DEFAULT_COLLECTION_VALUES.name}
        </Heading>
      )}
      <Waypoint onEnter={props.onEnter} onLeave={props.onLeave} />
      <InputRich
        ref={descriptionInput}
        tabIndex="0"
        onKeyDown={handleKeyDownOnDescriptionInput}
        wrapperClass={style.descriptionInputWrapper}
        value={currentDescription}
        type="textarea"
        projectId={props.projectId}
        onSubmit={handleSubmitDescription}
        onBlur={handleSubmitDescription}
        placeholder="Write a description (Markdown supported)"
        className={classnames(style.descriptionInput, {
          [style.hidden]: !shouldShowCollectionDescriptionInput,
          [style.emptyDescriptionInput]: !currentDescription,
        })}
        onChange={(newText) => setCurrentDescription(newText)}
      />
      <div
        onKeyDown={handleKeyDownOnDescription}
        role="button"
        tabIndex="0"
        className={classnames(style.description, {
          [style.hidden]:
            shouldShowCollectionDescriptionInput ||
            !props.collection.description,
          [style.noHoverState]: !props.canEdit,
        })}
        onClick={handleClickDescription}
      >
        <Markdown text={currentDescription} />
      </div>
    </React.Fragment>
  );
}
