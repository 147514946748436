// @flow

export function uniqueId({
  projectId = "",
  branchId = "",
  sha = "",
  fileId,
}: {
  projectId?: string,
  branchId?: string,
  sha?: string,
  fileId: string,
}) {
  return `${projectId}-${branchId}-${sha}-${fileId}`;
}
