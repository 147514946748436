// @flow
import { connect } from "react-redux";
import { getBranchesForProject, getBranch } from "core/selectors/branches";
import { getProject } from "core/selectors/projects";
import type { State } from "core/types";
import type { StateProps, OwnProps } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { projectId } = props.params;
  const project = getProject(state, { projectId });
  const branchId = props.location.query.branchId;
  const branch = branchId
    ? getBranch(state, {
        projectId,
        branchId,
      })
    : null;

  const branches = getBranchesForProject(state, { projectId });

  return {
    branch,
    branches,
    branchId,
    projectId,
    organizationId: project ? project.organizationId : "",
  };
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default connect(mapStateToProps);
