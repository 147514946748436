// @flow
import invariant from "invariant";
import { connect } from "react-redux";
import { discardChanges } from "abstract-di/actions";
import { getRepoBranchId } from "abstract-di/selectors";
import { showDialog, dismissDialog } from "core/actions/dialogs";
import { getBranch } from "core/selectors/branches";
import type { State, Dispatch } from "core/types";
import type { Props, OwnProps, StateProps, DispatchProps } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const currentBranchId = getRepoBranchId(state, {
    projectId: props.projectId,
  });

  return {
    branch: currentBranchId
      ? getBranch(state, {
          projectId: props.projectId,
          branchId: currentBranchId,
        })
      : null,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { projectId } = props;

  return {
    discardChanges: () => {
      invariant(discardChanges, "discardChanges is required for dialog action");
      dispatch((dispatch, getState) => {
        const currentBranchId = getRepoBranchId(getState(), {
          projectId,
        });
        invariant(currentBranchId, "currentBranchId must exist");

        dispatch(discardChanges(props.projectId, currentBranchId, []));
      });

      dispatch(dismissDialog());
    },
    openCreateCommit: () => dispatch(showDialog("CreateCommit", { projectId })),
    dismissDialog: () => dispatch(dismissDialog()),
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
