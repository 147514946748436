// @flow
import * as React from "react";
import style from "./style.scss";

export default function () {
  return (
    <div className={style.tooltip}>
      <dl>
        <dt>Bold</dt>
        <dd>**Your text**</dd>
        <dt>Italic</dt>
        <dd>_Your text_</dd>
        <dt>List item</dt>
        <dd>- Your text</dd>
        <dt>Blockquote</dt>
        <dd>&gt; Your text</dd>
        <dt>Code</dt>
        <dd>`Your text`</dd>
        <dt>Link</dt>
        <dd>[Your text](url)</dd>
        <dt>Image</dt>
        <dd>![alt](Image url)</dd>
      </dl>
    </div>
  );
}
