// @flow
import { connect } from "react-redux";
import { BranchChangesetFetchRequest } from "core/requests/changesets";
import { FilesFetchRequest } from "core/requests/files";
import { getBranchHead } from "core/selectors/branches";
import {
  getChangedFilesForBranch,
  getUnchangedFilesForBranch,
} from "core/selectors/files";
import type { State, Dispatch } from "core/types";
import type { OwnProps, StateProps, Props } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { projectId, branch } = props;
  const branchId = branch.id;
  const head = getBranchHead(state, { projectId, branchId });

  // Desktop never loads files, so we need to use strict
  const filesFetching = FilesFetchRequest.isFirstLoadingStrict(state, {
    projectId,
    sha: head,
    branchId,
  });

  const changesetFetching = BranchChangesetFetchRequest.isFirstLoading(state, {
    projectId,
    branchId,
    head,
  });

  const isLoading = filesFetching || changesetFetching;

  return {
    isLoading,
    changedFiles: getChangedFilesForBranch(state, {
      projectId,
      branchId,
    }),
    unchangedFiles: getUnchangedFilesForBranch(state, {
      projectId,
      branchId,
    }),
  };
}

export default connect<Props, OwnProps, StateProps, _, State, Dispatch>(
  mapStateToProps
);
