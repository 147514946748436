// @flow
import classnames from "classnames";
import * as React from "react";
import Popover from "core/components/Popover";
import { toInitials } from "core/lib/textFormatting";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  // Option 1: Supply one teamId, and the name + color will be pulled out of Redux state
  teamId?: string,
  // Option 2: Hardcode a teamName and teamColor to be displayed
  teamName?: string,
  teamColor?: string,
  // Option 2: Supply a list of teamIds for a [+] TeamAvatar that will display the list of team names on popover
  teamIds?: string[],
  // The below props define styles for the TeamAvatar
  size?: number,
  className?: string,
  extraStyle?: {},
  showPopover?: boolean,
  popoverLabel?: string,
  showBorder?: boolean,
  qaSelector?: string,
|};

export type StateProps = {|
  teamName?: string,
  teamNames?: string,
  teamColor?: string,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
};

// The generated SVG asset is stored literally here.
const NATURAL_HEXAGON_SIZE = 48;
const HEXAGON_PATH =
  "M29.9161509,1.85248549 L40.4161509,7.80248549 C44.1761402,9.93314611 46.5,13.9210351 46.5,18.2427517 L46.5,29.7572483 C46.5,34.0789649 44.1761402,38.0668539 40.4161509,40.1975145 L29.9161509,46.1475145 C26.2461891,48.2271595 21.7538109,48.2271595 18.0838491,46.1475145 L7.58384914,40.1975145 C3.82385981,38.0668539 1.5,34.0789649 1.5,29.7572483 L1.5,18.2427517 C1.5,13.9210351 3.82385981,9.93314611 7.58384914,7.80248549 L18.0838491,1.85248549 C21.7538109,-0.227159537 26.2461891,-0.227159537 29.9161509,1.85248549 Z";

function getSizeStyles(size: number) {
  return {
    width: `${size}px`,
    height: `${size}px`,
  };
}

function TeamAvatar(props: Props) {
  const {
    size = 24,
    teamName = "Loading Team...",
    teamNames,
    teamColor = "#56a3d9",
    className,
    extraStyle,
    showPopover,
    popoverLabel,
    showBorder = true,
    qaSelector,
  } = props;
  // Used to get 10px font size for default Avatar size of 24px
  const fontSize = Math.floor(size / 2.25);
  const initial = toInitials(teamName, 1);
  // These calculations make sure the hexagon scales to fit the size variable
  // and maintains a 2px white stroke (if showBorder is true)
  const hexScale = size / NATURAL_HEXAGON_SIZE;
  const strokeScale = showBorder ? 2 * (NATURAL_HEXAGON_SIZE / size) : 0;

  const avatar = (
    <svg
      data-qa={qaSelector}
      style={{ ...getSizeStyles(size), ...extraStyle }}
      role="img"
      aria-label={teamName}
      fill={teamColor}
      className={classnames(className, style.avatar)}
      height="100%"
      width="100%"
      viewBox={`0 0 ${size} ${size}`}
    >
      <path
        d={`${HEXAGON_PATH}`}
        x="0"
        y="0"
        stroke="white"
        strokeWidth={`${strokeScale}`}
        width={`${size}`}
        height={`${size}`}
        transform={`scale(${hexScale})`}
        className={style.hexagon}
      ></path>
      <text
        x="50%"
        y={initial !== "+" ? "50%" : "48%"}
        dy=".38em"
        fontSize={fontSize}
        className={classnames(style.avatar, style.defaultText)}
      >
        {initial}
      </text>
    </svg>
  );

  // The popover defaults to the Team's full name if a custom popoverLabel
  // is not passed in.
  if (showPopover) {
    return (
      <Popover
        label={popoverLabel || teamNames || teamName}
        placement={"bottom"}
      >
        {avatar}
      </Popover>
    );
  } else {
    return avatar;
  }
}

export default connector(TeamAvatar);
