// @flow
import * as React from "react";
import DialogForm from "core/components/DialogForm";

type Props = {
  isOpen: boolean,
  onClose: () => void,
  onSubmit?: () => mixed,
  isSubmitting: boolean,
  hasError: boolean,
  publicShareLinksCount: number,
};

type State = {
  isOpen: boolean,
};

export default class PublicOffConfirmDialog extends React.Component<
  Props,
  State,
> {
  static defaultProps = {
    isSubmitting: false,
    hasError: false,
  };

  state = {
    isOpen: false,
  };

  render() {
    return (
      <DialogForm
        dangerous
        isOpen={this.props.isOpen}
        onClose={!this.props.hasError ? this.props.onClose : undefined}
        title="Turn Public Links off"
        onSubmit={this.props.onSubmit}
        disabled={this.props.isSubmitting}
        primaryButton="I'm sure, turn Public Links off"
      >
        {() => (
          <div>
            Your Organization has {this.props.publicShareLinksCount} Public
            Links which will become unavailable to people outside of their
            respective Projects.
          </div>
        )}
      </DialogForm>
    );
  }
}
