// @flow
import classnames from "classnames";
import Clipboard from "clipboard";
import idx from "idx";
import * as React from "react";
import Button from "core/components/Button";
import ButtonLink from "core/components/ButtonLink";
import Flex from "core/components/Flex";
import HorizontalSeparator from "core/components/HorizontalSeparator";
import Icon from "core/components/Icon";
import Input from "core/components/Input";
import OnboardingContentSheet from "core/components/OnboardingContentSheet";
import OnboardingHeading from "core/components/OnboardingHeading";
import SerifText from "core/components/SerifText";
import onboardingStyle from "core/components/onboarding.scss";
import { push } from "core/lib/location";
import { V3Link as Link } from "core/lib/router";
import { rootPath } from "core/lib/routes";
import type { OrganizationInvitationParams } from "core/requests/invitations";
import type { Invitation, ReactRouterLocation } from "core/types";
import OnboardingPage from "web/components/Onboarding/Page";
import connector from "./connector";
import style from "./style.scss";

const MAX_NUMBER_OF_EMAILS = 10;

export type OwnProps = {|
  location: ReactRouterLocation,
|};

export type StateProps = {|
  email: ?string,
  organizationId: string,
|};

export type DispatchProps = {|
  createOrganizationInvites: (OrganizationInvitationParams) => Promise<
    Invitation[],
  >,
  showToast: (text: string) => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type State = {
  emails: string[],
  error: ?string,
  isSubmitting: boolean,
  inviteLink: ?string,
  inviteLinkError: ?string,
};

class InviteTeam extends React.Component<Props, State> {
  state = {
    emails: ["", "", ""],
    error: null,
    isSubmitting: false,
    inviteLink: "",
    inviteLinkError: null,
  };

  clipboard = null;
  copyButtonRef = React.createRef<any>();
  isMounted = false;

  async componentDidMount() {
    this.isMounted = true;
    this.redirectIfNecessary(this.props);

    this.setUpClipboard();

    const { organizationId } = this.props;

    try {
      const invitations = await this.props.createOrganizationInvites({
        emails: this.state.emails,
        organizationId,
        role: "member",
        subscriptionRole: "contributor",
        type: "link",
      });

      const inviteLink = idx(invitations, (_) => _[0].invitationUrl) || "";
      if (this.isMounted) {
        this.setState({ inviteLink });
      }
    } catch (error) {
      if (this.isMounted) {
        this.setState({ inviteLinkError: "Error generating invite link" });
      }
    }
  }

  componentDidUpdate() {
    this.redirectIfNecessary(this.props);
  }

  componentWillUnmount() {
    this.isMounted = false;

    if (this.clipboard) {
      this.clipboard.destroy();
    }
  }

  redirectIfNecessary = (props: Props) => {
    if (!props.organizationId) {
      push(rootPath());
    }
  };

  setUpClipboard = () => {
    this.clipboard = new Clipboard(this.copyButtonRef.current, {
      text: () => this.state.inviteLink,
    });
    this.clipboard.on("success", this.handleInviteLinkCopied);
  };

  handleEmailChange = (event: SyntheticInputEvent<>) => {
    this.setState((prevState) => {
      const emails = prevState.emails;

      if (event.target.hasAttribute("data-email-index")) {
        const index = parseInt(
          event.target.getAttribute("data-email-index"),
          10
        );
        emails[index] = event.target.value;
      }

      return { emails };
    });
  };

  addEmailInput = () => {
    this.setState((prevState) => ({
      emails: [...prevState.emails, ""],
    }));
  };

  submitDisabled = () => {
    const allEmailsAreEmpty = this.state.emails.every((email) => {
      return email.trim() === "";
    });

    return allEmailsAreEmpty || this.state.isSubmitting;
  };

  handleSubmit = async (event: SyntheticInputEvent<>) => {
    event.preventDefault();

    if (this.submitDisabled()) {
      return;
    }

    this.setState({ error: null, isSubmitting: true });

    const { organizationId } = this.props;

    try {
      await this.props.createOrganizationInvites({
        emails: this.state.emails,
        organizationId,
        role: "member",
        subscriptionRole: "contributor",
        type: "email",
      });

      this.setState({
        isSubmitting: false,
      });

      const redirectTo =
        idx(this.props.location, (_) => _.state.redirectTo) || rootPath();

      push(redirectTo);
    } catch (error) {
      this.setState({
        error: "Please double-check the emails and try again.",
        isSubmitting: false,
      });
    }
  };

  handleInviteLinkCopied = () => {
    this.props.showToast("Invite link copied to clipboard");
  };

  renderEmailInputs() {
    const { email } = this.props;
    const domain = email
      ? email.substring(email.lastIndexOf("@") + 1)
      : "domain.com";

    return this.state.emails.map<React.Node>((email, index) => {
      return (
        <Input
          autoFocus={index === 0}
          className={onboardingStyle.input}
          data-email-index={index}
          key={`email[${index}]`}
          name={`email[${index}]`}
          onChange={this.handleEmailChange}
          placeholder={`janedoe@${domain}`}
          type="email"
          value={email}
        />
      );
    });
  }

  render() {
    const showAddMoreEmailsButton =
      this.state.emails.length < MAX_NUMBER_OF_EMAILS;

    const redirectTo =
      idx(this.props.location, (_) => _.state.redirectTo) || rootPath();

    const isOnboarding = idx(this.props.location, (_) => _.state.onboarding);

    return (
      <OnboardingPage
        currentStep={isOnboarding ? 4 : undefined}
        totalSteps={isOnboarding ? 4 : undefined}
      >
        <OnboardingContentSheet className={onboardingStyle.mainContentSheet}>
          <Flex align="center" className={onboardingStyle.centeredText} column>
            <OnboardingHeading level="1">
              Abstract is <SerifText>better together</SerifText>
            </OnboardingHeading>
            <div
              className={classnames(
                onboardingStyle.subHeading,
                onboardingStyle.largeCopy
              )}
            >
              Invite your fellow designers, product managers, engineers, and
              writers.
            </div>
          </Flex>
          <form className={onboardingStyle.form} onSubmit={this.handleSubmit}>
            <div>
              {this.renderEmailInputs()}
              {showAddMoreEmailsButton && (
                <div>
                  <ButtonLink onClick={this.addEmailInput}>
                    <span
                      className={classnames(
                        style.addEmailLabel,
                        onboardingStyle.largeCopy
                      )}
                    >
                      <Icon fill="currentColor" type="plus" /> Add more
                    </span>
                  </ButtonLink>
                </div>
              )}
            </div>
            <Button
              className={classnames(
                onboardingStyle.button,
                onboardingStyle.submit
              )}
              disabled={this.state.isSubmitting}
              type="submit"
              fullwidth
              large
              primary
            >
              {this.state.isSubmitting
                ? "Inviting teammates…"
                : "Invite teammates"}
            </Button>
          </form>
          <HorizontalSeparator className={style.separator} />
          <Flex align="flex-end" justify="space-between">
            <Input
              className={classnames(
                style.inviteLinkInput,
                onboardingStyle.input
              )}
              wrapperClass={style.inputWithButton}
              label={
                <span className={onboardingStyle.inputLabel}>
                  Or share the invite link:
                </span>
              }
              value={this.state.inviteLink}
              placeholder={this.state.inviteLinkError || "Loading…"}
              disabled={!this.state.inviteLink}
              type="url"
              selectOnFocus
            />
            <Button
              className={classnames(style.copyButton, onboardingStyle.button)}
              disabled={!this.state.inviteLink}
              innerRef={this.copyButtonRef}
              allgood
              large
            >
              Copy
            </Button>
          </Flex>
          <div className={classnames(style.skip, onboardingStyle.largeCopy)}>
            <Link className={onboardingStyle.link} to={redirectTo}>
              Skip this step for now
            </Link>
          </div>
        </OnboardingContentSheet>
      </OnboardingPage>
    );
  }
}

export default connector(InviteTeam);
