// @flow
import createCachedSelector from "@elasticprojects/re-reselect";
import empty from "empty";
import find from "lodash/find";
import { getProjectId } from "core/selectors/helpers";
import { getOrganizationSettings } from "core/selectors/organizationSettings";
import { getProject } from "core/selectors/projects";
import type {
  GithubIntegration,
  JiraIntegration,
  SlackChannel,
  SlackIntegration,
  SlackIntegrationOverride,
  State,
} from "core/types";

export function getGithubIntegration(
  state: State,
  params: { organizationId: string }
): ?GithubIntegration {
  const settings = getOrganizationSettings(state, {
    organizationId: params.organizationId,
  });
  return settings ? settings.githubIntegration : undefined;
}

export function getJiraIntegration(
  state: State,
  params: { organizationId: string }
): ?JiraIntegration {
  const settings = getOrganizationSettings(state, {
    organizationId: params.organizationId,
  });
  return settings ? settings.jiraIntegration : undefined;
}

export function getSlackIntegration(
  state: State,
  params: { organizationId: string }
): ?SlackIntegration {
  const settings = getOrganizationSettings(state, {
    organizationId: params.organizationId,
  });
  return settings ? settings.slackIntegration : undefined;
}

export function getSlackIntegrationOverrides(
  state: State,
  params: { organizationId: string }
): SlackIntegrationOverride[] {
  const settings = getOrganizationSettings(state, {
    organizationId: params.organizationId,
  });
  if (!settings || !settings.slackIntegration) {
    return [];
  }
  const { slackIntegration } = settings;
  return slackIntegration.projectOverrides || [];
}

export const getSlackIntegrationOverride: (
  state: State,
  params: { organizationId: string, projectId: string }
) => ?SlackIntegrationOverride = createCachedSelector(
  getProjectId,
  getSlackIntegrationOverrides,
  (projectId, overrides) => find(overrides, { projectId })
)(getProjectId);

export function getDefaultSlackIntegrationChannel(
  state: State,
  params: { organizationId: string }
): ?SlackChannel {
  const settings = getOrganizationSettings(state, {
    organizationId: params.organizationId,
  });
  if (!settings || !settings.slackIntegration) {
    return;
  }
  const { slackIntegration } = settings;
  const { channel, availableChannels } = slackIntegration.details;

  return find(availableChannels, { id: channel });
}

/**
 * @returns Returns any project overrides for the given project. If there
 * are no overrides for this project, then it returns the default channel
 * for the organization.
 */
export function getDefaultSlackChannelsForProject(
  state: State,
  params: { projectId: string }
): string[] {
  const { projectId } = params;
  const project = getProject(state, { projectId });
  if (!project) {
    return empty.array;
  }

  const { organizationId } = project;
  const slackIntegration = getSlackIntegration(state, { organizationId });
  if (!slackIntegration) {
    return empty.array;
  }

  const overrides = slackIntegration.projectOverrides || [];
  const override = find(overrides, { projectId });

  if (override) {
    if (override.details.notificationsDisabled) {
      return empty.array;
    }
    if (slackIntegration.details.slackV2 && override.details.channels) {
      return override.details.channels;
    }
    if (!slackIntegration.details.slackV2 && override.details.channel) {
      return [override.details.channel];
    }
    return empty.array;
  }

  if (slackIntegration.details.slackV2 && slackIntegration.details.channels) {
    return slackIntegration.details.channels;
  }
  if (!slackIntegration.details.slackV2 && slackIntegration.details.channel) {
    return [slackIntegration.details.channel];
  }
  return empty.array;
}
