// @flow
import { fetchPasswordReset, NotFoundError } from "../api";
import type { Action, ThunkAction } from ".";

function resetPasswordTokenValidating(): Action {
  return { type: "RESET_PASSWORD_TOKEN_VALIDATING" };
}

function resetPasswordTokenValidated(isValid): Action {
  return { type: "RESET_PASSWORD_TOKEN_VALIDATED", payload: { isValid } };
}

export function checkResetPasswordToken(
  resetPasswordToken: string
): ThunkAction {
  return async (dispatch) => {
    dispatch(resetPasswordTokenValidating());

    try {
      await fetchPasswordReset(resetPasswordToken);
      dispatch(resetPasswordTokenValidated(true));
    } catch (error) {
      if (error instanceof NotFoundError) {
        dispatch(resetPasswordTokenValidated(false));
      }
    }
  };
}
