// @flow
import { detect, type BrowserInfo, NodeInfo, BotInfo } from "detect-browser";

type Browser = BrowserInfo | NodeInfo | BotInfo;

let browser: ?Browser;

function getBrowser(): ?Browser {
  if (browser) {
    return browser;
  }
  browser = detect();
  return browser;
}

function isBrowser(name: string): boolean {
  const browser = getBrowser();
  if (browser) {
    return browser.name === name;
  }
  return false;
}

function getBrowserVersion(): string {
  const browser = getBrowser();
  if (browser) {
    return browser.version || "";
  }
  return "";
}

export function isChrome(): boolean {
  return isBrowser("chrome");
}

export function isSafari(): boolean {
  return isBrowser("safari");
}

export function isSafariBelow16(): boolean {
  return isBrowser("safari") && getBrowserVersion() < "16";
}

export function isMobileSafari(): boolean {
  return isBrowser("ios") || isBrowser("ios-webview");
}

export function isFirefox(): boolean {
  return isBrowser("firefox");
}

export function isOpera(): boolean {
  return isBrowser("opera");
}
