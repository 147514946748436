// @flow

import invariant from "invariant";
import { connect } from "react-redux";
import { deleteBranch } from "abstract-di/actions";
import { dismissDialog } from "core/actions/dialogs";
import type { State, Dispatch } from "core/types";
import type { Props, OwnProps, DispatchProps } from ".";

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    deleteBranch: () => {
      invariant(deleteBranch, "deleteBranch required for dialog action");
      dispatch(
        deleteBranch(props.projectId, props.branchId, props.branchName, true)
      );
    },
    dismissDialog: () => dispatch(dismissDialog()),
  };
}

export default connect<Props, OwnProps, _, DispatchProps, State, Dispatch>(
  undefined,
  mapDispatchToProps
);
