// @flow
import React from "react";
import DialogForm from "core/components/DialogForm";

type Props = {|
  isOpen: boolean,
  onRequestClose: () => void,
|};

function SalesRequested({ isOpen, onRequestClose }: Props) {
  return (
    <DialogForm
      title="We'll be in touch shortly"
      primaryButton=""
      isOpen={isOpen}
      onClose={onRequestClose}
    >
      <div>
        <p>
          We've let our sales team know about your request, and we'll be in
          touch soon.
        </p>
      </div>
    </DialogForm>
  );
}

export default SalesRequested;
