// @flow
import * as React from "react";
import * as scheduler from "scheduler";
import { createContext } from "use-context-selector";
import type { LayerData } from "core/types";
import LayerChild from "./LayerChild";

export type Props = {
  scale: number,
  layers: { [layerId: string]: LayerData },
  rootLayer: LayerData,
  onSelectLayer: (layerData: LayerData) => void,
  onTargetLayer: (layerData: LayerData) => void,
  selectedLayer?: LayerData,
  targetedLayer?: LayerData,
  showHotspots: boolean,
  ignoreGroups: boolean,
};

export const PropsContext = createContext<Props>({});

export default function LayerChildren(props: Props) {
  const [hiddenScale, setHiddenScale] = React.useState(); // eslint-disable-line no-unused-vars

  // TODO(concurrent):
  // Allow repeated or continous scale changes to be perceived faster
  // by de-prioritizing drawing the scale change for hidden layers
  React.useEffect(() => {
    if (props.scale) {
      scheduler.unstable_scheduleCallback(scheduler.unstable_LowPriority, () =>
        setHiddenScale(props.scale)
      );
    }
  }, [props.scale]);

  return (
    // Set selected layer, targeted layer, scale, and hotspot on context to allow
    // memoized child elements a chance to re-render as necessary
    <PropsContext.Provider value={props}>
      <LayerChild
        hiddenScale={hiddenScale}
        layers={props.layers}
        layerData={props.rootLayer}
        onTargetLayer={props.onTargetLayer}
        onSelectLayer={props.onSelectLayer}
        // IMPORTANT:
        // Avoid adding new props to LayerChild that would change frequently.
        // Prefer using PropsContext where you can use useContextSelector to
        // selectively re-render. See: ConnectedLayerChild
      />
    </PropsContext.Provider>
  );
}

LayerChildren.defaultProps = {
  showHotspots: false,
  ignoreGroups: false,
};
