// @flow
import * as React from "react";
import { connect } from "react-redux";
import { showToast } from "core/actions/toasts";
import Loaded from "core/components/Loaded";
import { replace } from "core/lib/location";
import { projectPath } from "core/lib/routes";
import type { Dispatch, State } from "core/types";
import { AcceptProjectAccessRequest } from "web/requests/projectAccess";

type OwnProps = {|
  params: { projectId: string, token: string },
|};

type DispatchProps = {|
  acceptAccessRequest: () => void,
|};

type Props = {
  ...OwnProps,
  ...DispatchProps,
};

class GrantProjectAccess extends React.Component<Props> {
  componentDidMount() {
    this.props.acceptAccessRequest();
    replace(projectPath(this.props.params.projectId, "people"));
  }

  render() {
    return <Loaded loading />;
  }
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { projectId, token } = props.params;

  return {
    acceptAccessRequest: () => {
      dispatch(
        AcceptProjectAccessRequest.perform({
          params: { projectId, token },
          onSuccess: (response) => {
            dispatch(showToast({ text: response.message }));
          },
          onError: (error) => {
            dispatch(showToast({ text: error.message }));
          },
        })
      );
    },
  };
}

export default connect<Props, OwnProps, _, DispatchProps, State, Dispatch>(
  undefined,
  mapDispatchToProps
)(GrantProjectAccess);
