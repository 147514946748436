// @flow
import * as Abstract from "abstract-sdk";
import { memoize } from "lodash";
import * as React from "react";
import { createPaginationDataLoaderComponent } from "core/components/PaginationDataLoader";
import { PaginatedOrganizationMembershipsRequest } from "core/requests/memberships";
import { getUsersForOrganization } from "core/selectors/users";
import type { OrganizationRole, SubscriptionRole, User } from "core/types";

type PaginatedOrganizationMembershipsParams = {
  ...Abstract.OrganizationDescriptor,
  role?: OrganizationRole,
  subscriptionRole?: SubscriptionRole,
  query?: string,
  userId?: string,
};

const DataLoader = createPaginationDataLoaderComponent<
  PaginatedOrganizationMembershipsParams,
  User[],
>(
  "OrganizationMembershipsLoader",
  PaginatedOrganizationMembershipsRequest,
  (response) => response.data.length,
  getUsersForOrganization,
  30
);

const getDataLoaderParams = memoize(
  function (props, filter): PaginatedOrganizationMembershipsParams {
    const params = {
      organizationId: props.organizationId,
      query: filter,
    };

    return params;
  },
  function (props, filter) {
    return [props.organizationId, filter].join("-");
  }
);

type RenderParams = {
  items: User[],
  hasNextPage: boolean,
  isLoadingNextPage: boolean,
  onLoadNextPage?: () => void,
};

type Props = {|
  children: (params: RenderParams) => React.Node,
  organizationId: string,
  searchFilter: string,
|};

export default function OrganizationMembershipsDataLoader(props: Props) {
  return (
    <DataLoader params={getDataLoaderParams(props, props.searchFilter)}>
      {props.children}
    </DataLoader>
  );
}
