// @flow
import { VisuallyHidden } from "@reach/visually-hidden";
import * as React from "react";
import Button from "core/components/Button";
import InputSearch from "core/components/InputSearch";
import ListDisplayToggle from "core/components/ListDisplayToggle";
import PageTitle from "core/components/PageTitle";
import PageTitleNavigationLink from "core/components/PageTitleNavigationLink";
import ProjectsMenu from "core/components/ProjectsMenu";
import { addQuery, removeQuery } from "core/lib/location";
import { isDesktop } from "core/lib/platform";
import type { ProjectFilter, ProjectSort } from "core/types";
import SortMenu from "./SortMenu";

type Props = {|
  +canCreateProjects?: boolean,
  +canCreateSections?: boolean,
  +filter: "starred" | "active" | "archived",
  +isCard?: boolean,
  +isLoading?: boolean,
  +isOffline?: boolean,
  +mobile?: boolean,
  +onCreateProject?: () => void,
  +onCreateSection?: () => void,
  +onFilterChange: (filter: ProjectFilter) => (event: SyntheticEvent<>) => void,
  +onSearch: (event: SyntheticInputEvent<>) => void,
  +onSelectCardDisplay: () => void,
  +onSelectListDisplay: () => void,
  +onSort: (sort: ProjectSort) => void,
  +organizationId: string,
  +query?: string,
  +sort: ProjectSort,
|};

export default function OrganizationProjectsHeader(props: Props) {
  const actions = [];
  if (props.canCreateSections) {
    actions.push(
      <Button
        onClick={props.onCreateSection}
        disabled={props.isOffline}
        title="New Section"
        icon={props.mobile ? "section-add" : undefined}
        nude={props.mobile}
        children={props.mobile ? undefined : "New Section"}
        qaSelector="createSectionButton"
      />
    );
  }

  if (props.canCreateProjects) {
    actions.push(
      <Button
        disabled={props.isOffline}
        onClick={props.onCreateProject}
        qaSelector="createProjectButton"
        primary
        title={
          props.isOffline
            ? "You can only create a new project while online"
            : ""
        }
        tooltip={{ placement: "bottom-end" }}
      >
        New Project{" "}
        {props.isOffline ? (
          <VisuallyHidden>(disabled while you are offline)</VisuallyHidden>
        ) : (
          ""
        )}
      </Button>
    );
  }

  if (isDesktop) {
    actions.push(
      <ProjectsMenu organizationId={props.organizationId}>
        {(showMenu, renderMenuTarget) => {
          return renderMenuTarget((ref, buttonProps) => {
            return (
              <Button
                key="projects-menu"
                icon="overflow"
                innerRef={ref}
                onClick={showMenu}
                title="Open Projects Menu"
                nude
                {...buttonProps}
              />
            );
          });
        }}
      </ProjectsMenu>
    );
  }

  return (
    <PageTitle
      actions={actions}
      displayControl={
        <ListDisplayToggle
          isGrid={!!props.isCard}
          onToggleGrid={props.onSelectCardDisplay}
          onToggleList={props.onSelectListDisplay}
        />
      }
      navigation={[
        <PageTitleNavigationLink
          active={props.filter === "active"}
          icon="project"
          onClick={props.onFilterChange("active")}
          to={removeQuery("filter")}
          qaSelector="project-navigation-active-tab"
        >
          Active
        </PageTitleNavigationLink>,
        <PageTitleNavigationLink
          active={props.filter === "starred"}
          icon="star"
          onClick={props.onFilterChange("starred")}
          to={addQuery({ filter: "starred" })}
          qaSelector="project-navigation-favorites-tab"
        >
          Favorites
        </PageTitleNavigationLink>,
        <PageTitleNavigationLink
          active={props.filter === "archived"}
          icon="archive"
          onClick={props.onFilterChange("archived")}
          to={addQuery({ filter: "archived" })}
          qaSelector="project-navigation-archived-tab"
        >
          Archived
        </PageTitleNavigationLink>,
      ]}
      search={
        <InputSearch
          name="search"
          large={props.mobile}
          defaultValue={props.query}
          onChange={props.onSearch}
          placeholder="Search by title or description…"
        />
      }
      sort={
        <SortMenu
          mobile={props.mobile}
          sort={props.sort}
          organizationId={props.organizationId}
          onSort={props.onSort}
        />
      }
      title="Projects"
    />
  );
}
