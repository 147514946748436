// @flow
import classnames from "classnames";
import * as React from "react";
import CopyToClipboard from "core/components/CopyToClipboard";
import Transparency from "core/components/Transparency";
import type { ThemeConfig, ThemeFill } from "core/lib/theme";
import style from "./style.scss";

type Props = {
  value?: string,
  clipboardValue?: string,
  size?: 16 | 24,
  title?: string,
  copyToClipboard?: boolean,
  innerRef?: (ref: React.ElementRef<*>) => void,
  themeConfig?: ThemeConfig,
  fill?: ThemeFill,
};

type State = {
  fadeIn: boolean,
};

export default class ColorSwatch extends React.Component<Props, State> {
  static defaultProps = {
    size: 16,
  };

  state = {
    fadeIn: false,
  };

  static getDerivedStateFromProps(props: Props) {
    return { fadeIn: !!props.value };
  }

  render() {
    const clipboardValue = this.props.clipboardValue || this.props.value;

    return (
      <CopyToClipboard
        disabled={clipboardValue ? !this.props.copyToClipboard : true}
        toast={clipboardValue ? `Copied “${clipboardValue}”` : undefined}
        value={clipboardValue ? clipboardValue : undefined}
      >
        {(setClickableRef) => (
          <div
            ref={(ref) => {
              setClickableRef(ref);

              if (this.props.innerRef) {
                this.props.innerRef(ref);
              }
            }}
            tabIndex="0"
            className={classnames(style.colorSwatch, {
              [style.clickable]: this.props.copyToClipboard,
            })}
            title={
              this.props.title === undefined
                ? this.props.clipboardValue || this.props.value
                : this.props.title
            }
            style={{
              width: this.props.size,
              height: this.props.size,
            }}
          >
            <Transparency
              className={classnames(style.transparency, {
                [style.themeConfigSystem]: this.props.themeConfig === "system",
                [style.themeConfigLight]: this.props.themeConfig === "light",
                [style.themeConfigDark]: this.props.themeConfig === "dark",
                [style.forceAutoSolid]:
                  this.props.themeConfig === "system" &&
                  this.props.fill === "solid",
                [style.forceLightSolid]:
                  this.props.themeConfig === "light" &&
                  this.props.fill === "solid",
                [style.forceDarkSolid]:
                  this.props.themeConfig === "dark" &&
                  this.props.fill === "solid",
              })}
              fixed={false}
            >
              <div
                className={classnames(style.color, {
                  [style.fadeIn]: this.state.fadeIn,
                })}
                style={{ background: this.props.value || "transparent" }}
              />
            </Transparency>
          </div>
        )}
      </CopyToClipboard>
    );
  }
}
