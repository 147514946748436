// @flow
import classnames from "classnames";
import * as React from "react";
import Button from "core/components/Button";
import ExternalLink from "core/components/ExternalLink";
import Flex from "core/components/Flex";
import Loaded from "core/components/Loaded";
import Media from "core/components/Media";
import OnboardingContentSheet from "core/components/OnboardingContentSheet";
import OnboardingHeading from "core/components/OnboardingHeading";
import SerifText from "core/components/SerifText";
import onboardingStyle from "core/components/onboarding.scss";
import { V3Link as Link } from "core/lib/router";
import { helpJoinOrganizationUrl } from "core/lib/urls";
import type { Organization } from "core/types";
import OnboardingPage from "web/components/Onboarding/Page";
import { signupCreateOrganization } from "web/routeHelpers";
import RequestListItem from "./RequestListItem";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {||};

export type StateProps = {|
  isLoading: boolean,
|};

export type DispatchProps = {|
  getMatchingOrganizations: (
    onSuccess: (response: { data: Organization[] }) => void
  ) => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type State = {|
  organizations: Organization[],
|};

class OrganizationAccessRequest extends React.Component<Props, State> {
  state = {
    organizations: [],
  };

  componentDidMount() {
    this.props.getMatchingOrganizations((response) => {
      const organizations = response.data;
      this.setState({ organizations });
    });
  }

  noOrganizationsFound = () => {
    return this.state.organizations.length === 0;
  };

  oneOrganizationFound = () => {
    return this.state.organizations.length === 1;
  };

  getSubtitle = () => {
    if (this.noOrganizationsFound()) {
      return "There aren’t any Organizations you can currently request access to.";
    }

    if (this.oneOrganizationFound()) {
      return "We found one Organization that matches your email address.";
    }

    return "We found some Organizations that match your email address.";
  };

  renderOrganizations = (mobile: boolean) => {
    return this.state.organizations.map<React.Node>((organization, index) => (
      <RequestListItem
        key={organization.id}
        mobile={mobile}
        organization={organization}
      />
    ));
  };

  render() {
    const { isLoading } = this.props;

    return (
      <OnboardingPage currentStep={4} totalSteps={4}>
        <Loaded loading={isLoading}>
          <Media mobile>
            {(mobile) => (
              <OnboardingContentSheet
                className={classnames(
                  onboardingStyle.mainContentSheet,
                  onboardingStyle.centeredText
                )}
              >
                <OnboardingHeading level="1">
                  Email address <SerifText>confirmed</SerifText>
                </OnboardingHeading>
                <div
                  className={classnames(
                    onboardingStyle.subHeading,
                    onboardingStyle.largeCopy
                  )}
                >
                  {this.getSubtitle()}
                </div>
                {this.noOrganizationsFound() ? (
                  <React.Fragment>
                    <div
                      className={classnames(
                        onboardingStyle.extraInfo,
                        onboardingStyle.mediumCopy
                      )}
                    >
                      Please contact your Organization’s Admin to request an
                      invitation to join Abstract, or…
                    </div>
                    <div className={style.createOrganizationButton}>
                      <Button
                        component={Link}
                        to={{
                          pathname: signupCreateOrganization(),
                          state: { onboarding: true },
                        }}
                        large
                        primary
                      >
                        Create a new Organization
                      </Button>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div
                      className={classnames(
                        onboardingStyle.extraInfo,
                        onboardingStyle.mediumCopy
                      )}
                    >
                      You need permission to access{" "}
                      {this.oneOrganizationFound() ? "this" : "an"}{" "}
                      Organization. We will send your request to the
                      Organization Admin. Once your request is approved, we’ll
                      let you know by email.
                    </div>
                    <Flex align="center" className={style.organizations} column>
                      {this.renderOrganizations(mobile)}
                    </Flex>
                    <div
                      className={classnames(
                        onboardingStyle.extraInfo,
                        onboardingStyle.mediumCopy
                      )}
                    >
                      Not seeing your Organization here?{" "}
                      <ExternalLink
                        className={onboardingStyle.link}
                        href={helpJoinOrganizationUrl()}
                      >
                        Learn why…
                      </ExternalLink>
                    </div>
                    <div
                      className={classnames(
                        onboardingStyle.extraInfo,
                        onboardingStyle.mediumCopy
                      )}
                    >
                      You can also reach out to one of the Organization Admins
                      and request an invite from them.
                    </div>
                  </React.Fragment>
                )}
              </OnboardingContentSheet>
            )}
          </Media>
        </Loaded>
      </OnboardingPage>
    );
  }
}

export default connector(OrganizationAccessRequest);
