// @flow
import Immutable from "seamless-immutable";
import coreSessionReducer from "core/reducers/session";
import type { Session } from "core/types";
import type { Action } from "../actions";

const DEFAULT_STATE = Immutable.from({ userHasSignedOut: false, userId: "" });

export default function (
  state: Session = DEFAULT_STATE,
  action: Action
): Session {
  switch (action.type) {
    case "SESSION_DELETED": {
      return Immutable.set(state, "userHasSignedOut", true);
    }
    case "SESSION_LOADED": {
      return Immutable.set(
        Immutable.merge(state, action.payload),
        "userHasSignedOut",
        false
      );
    }
    default:
      return coreSessionReducer(state, action);
  }
}
