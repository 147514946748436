// @flow
import classnames from "classnames";
import empty from "empty";
import idx from "idx";
import { memoize } from "lodash";
import findIndex from "lodash/findIndex";
import * as React from "react";
import Idle from "react-idled";
import Annotations from "core/components/Annotations";
import Button from "core/components/Button";
import CopyLinkButton from "core/components/CopyLinkButton";
import FloatingControl from "core/components/FloatingControl";
import Input from "core/components/Input";
import LayerCanvas, { type ZoomState } from "core/components/LayerCanvas";
import LayerComments from "core/components/LayerComments";
import Loaded from "core/components/Loaded";
import Media from "core/components/Media";
import Modal from "core/components/Modal";
import Popover from "core/components/Popover";
import Sidebar from "core/components/Sidebar";
import Theme from "core/components/Theme";
import ZoomPercentageInput from "core/components/ZoomPercentageInput";
import window from "core/global/window";
import { Abstract } from "core/lib/abstract";
import anyHover from "core/lib/anyHover";
import eventInInput from "core/lib/eventInInput";
import KeyCode from "core/lib/keycode";
import { isDesktop } from "core/lib/platform";
import type { ThemeName } from "core/lib/theme";
import * as Layer from "core/models/layer";
import type {
  Annotation,
  Comment,
  CommentsState,
  File,
  Layer as TLayer,
  LayerData as TLayerData,
  LayerSetItem,
  LayerSetParams,
  LayerLink,
  ReactRouterLocation,
  ShareLink,
  InputShare,
  LayerShellMode,
  Commit,
} from "core/types";
import HotspotsOverlay from "./HotspotsOverlay";
import connector from "./connector";
import style from "./style.scss";

type Form = {
  annotation?: Annotation,
};

export type OwnProps = {|
  comments: Comment[],
  defaultShowComments: boolean,
  file: ?File,
  hideInspect?: boolean,
  isFullScreen?: boolean,
  isLoggedIn?: boolean,
  layer: ?TLayer,
  layerDescriptor: ?Abstract.LayerVersionDescriptor,
  layerSetParams?: LayerSetParams,
  loading: boolean,
  location: ReactRouterLocation,
  onChangeShowComments: (showComments: boolean) => void,
  onClickClose?: () => void,
  onExitFullScreen?: () => void,
  onToggleFullscreen?: () => void,
  params: Abstract.LayerVersionDescriptor,
  shareLink?: ShareLink,
  inputShare: InputShare,
  mode?: LayerShellMode,
  showPrototypes: boolean,
  isShowingResolvedComments?: boolean,
  latestCommit: ?Commit,
|};

export type StorageProps = {|
  commentFormId: string,
  onFormChange: (values: Form) => void,
  onToggleDarkMode: (boolean) => void,
  defaultIsDarkMode: boolean,
  defaultForm: Form,
|};

export type StorageOwnProps = {|
  ...OwnProps,
  ...StorageProps,
|};

export type StateProps = {|
  annotations: Annotation[],
  totalAnnotations: number,
  commentsState: CommentsState,
  defaultIdle: boolean,
  layers: TLayerData[],
  layerSet: LayerSetItem[],
  loadingHotspots: boolean,
  data?: { loading: boolean }, // Used to track target layers loading in parallel
|};

export type DispatchProps = {|
  onClickHotspot: (link: LayerLink) => void,
  onClickLayerDetail: () => void,
  onClickNext: (layer: ?LayerSetItem) => void,
  onClickPrevious: (layer: ?LayerSetItem) => void,
  onIdleChange: (idleState: boolean) => void,
  onLoad: () => Promise<void>,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...StorageProps,
  ...DispatchProps,
};

type State = {
  showComments: boolean,
  isDarkMode: boolean,
  showAnnotations: boolean,
  toggleNewAnnotation: boolean,
  focusNewCommentInput: boolean,
  selectedCommentId: ?string,
  form: Form,
  zoomStates: {
    [layerId: ?string]: ZoomState,
  },
  flashHotspotsOnOverlayMount: boolean,
};

const IDLE_EVENTS = ["mousemove", "mousedown", "touchstart"];
const INITIAL_ZOOM_STATE = {
  scale: 0,
  scaledToFit: true,
  offsetX: 0,
  offsetY: 0,
};

const getLayerVersionDescriptor = memoize(
  (props: Props) => {
    let sha = props.params.sha;

    if (props.params.sha === "latest" && props.latestCommit) {
      sha = props.latestCommit.sha;
    }

    return {
      ...props.params,
      sha,
    };
  },
  (props) =>
    [
      props.params.branchId,
      props.params.projectId,
      props.params.fileId,
      props.params.layerId,
      props.params.sha,
    ].join("-")
);

class LayerPresentation extends React.Component<Props, State> {
  static contextType = Theme.ThemeContext;
  cachedDataTheme: ?ThemeName;
  layerCanvas: ?LayerCanvas;
  state = {
    showComments: this.props.defaultShowComments,
    showAnnotations: this.props.defaultShowComments,
    toggleNewAnnotation: false,
    focusNewCommentInput: false,
    // If there's previously selected presentation mode exists,
    // pick the current app theme as the presentation mode
    isDarkMode:
      this.props.defaultIsDarkMode === undefined
        ? this.context.themeName === "dark"
        : this.props.defaultIsDarkMode,
    form: this.props.defaultForm,
    zoomStates: {},
    selectedCommentId: null,
    flashHotspotsOnOverlayMount: true,
  };

  onInitialHotspotFlashed = () => {
    this.setState({
      flashHotspotsOnOverlayMount: false,
    });
  };

  getCurrentIndex(): number {
    let layerSetIndex = -1;

    if (
      this.props.location.query &&
      this.props.location.query.collectionLayerId
    ) {
      layerSetIndex = findIndex(this.props.layerSet, {
        nonce: this.props.location.query.collectionLayerId,
      });
    }

    if (layerSetIndex === -1) {
      const params = getLayerVersionDescriptor(this.props);

      layerSetIndex = findIndex(this.props.layerSet, {
        layerId: this.props.params.layerId,
        commitSha: params.sha,
      });
    }

    return layerSetIndex !== -1
      ? layerSetIndex
      : findIndex(this.props.layerSet, {
          layerId: this.props.params.layerId,
        });
  }

  getNextInLayerSet(): ?LayerSetItem {
    if (this.props.layerSet) {
      const currentIndex = this.getCurrentIndex();

      if (currentIndex !== -1) {
        return this.props.layerSet[currentIndex + 1];
      }
    }
  }

  getPreviousInLayerSet(): ?LayerSetItem {
    if (this.props.layerSet) {
      const currentIndex = this.getCurrentIndex();

      if (currentIndex !== -1) {
        return this.props.layerSet[currentIndex - 1];
      }
    }
  }

  getNewAnnotation(): ?Annotation {
    return this.state.form.annotation;
  }

  getZoomState(): ZoomState {
    return (
      this.state.zoomStates[this.props.params.layerId] || INITIAL_ZOOM_STATE
    );
  }

  getAnnotationsWithNew(): Annotation[] {
    // We use concat rather than push here as we're dealing with an
    // immutable array. If the form has an annotation we're creating one
    // and need to add this to the list to be displayed.
    return this.getNewAnnotation()
      ? this.props.annotations.concat([
          {
            ...this.getNewAnnotation(),
            number: this.props.totalAnnotations + 1,
          },
        ])
      : this.props.annotations;
  }

  componentDidMount() {
    const { documentElement } = window.document;
    window.addEventListener("keydown", this.onKeyDown);

    // When toggling this component's local light/dark mode, we'll want to
    // update all of our UI in this component to reflect that mode.
    // When unmounted, we'll want to set the data-theme back to what it
    // was set to originally.
    if (documentElement) {
      switch (documentElement.getAttribute("data-theme")) {
        case "light":
          this.cachedDataTheme = "light";
          break;
        case "dark":
          this.cachedDataTheme = "dark";
          break;
        default:
          this.cachedDataTheme = undefined;
      }

      documentElement.setAttribute(
        "data-theme",
        this.state.isDarkMode ? "dark" : "light"
      );
    }
  }

  componentWillUnmount() {
    const { documentElement } = window.document;
    window.removeEventListener("keydown", this.onKeyDown);

    if (documentElement) {
      if (this.cachedDataTheme) {
        documentElement.setAttribute("data-theme", this.cachedDataTheme);
      } else {
        documentElement.removeAttribute("data-theme");
      }
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.commentFormId !== prevProps.commentFormId) {
      this.setState({ form: this.props.defaultForm });
    }
  }

  toggleComments = () => {
    this.setState(
      (state) => ({
        showComments: !state.showComments,
        showAnnotations: !state.showComments,
        focusNewCommentInput: false,
      }),
      () => {
        this.props.onChangeShowComments(this.state.showComments);
      }
    );
  };

  toggleCommentsWithFocus = () => {
    this.setState(
      (state) => ({
        showComments: !state.showComments,
        showAnnotations: !state.showComments,
        toggleNewAnnotation: false,
        focusNewCommentInput: false,
      }),
      () => {
        this.props.onChangeShowComments(this.state.showComments);
        if (this.state.showComments) {
          this.setFocusOnNewCommentInput();
        }
      }
    );
  };

  toggleCommentsWithAnnotation = () => {
    this.setState(
      (state) => ({
        showComments: true,
        showAnnotations: true,
        toggleNewAnnotation: true,
        focusNewCommentInput: false,
      }),
      () => {
        this.props.onChangeShowComments(this.state.showComments);
        this.setFocusOnNewCommentInput();
      }
    );
  };

  setFocusOnNewCommentInput = () => {
    setTimeout(() => {
      this.setState({
        focusNewCommentInput: true,
      });
    }, 200); // 200 from Sidebar duration
  };

  toggleDarkMode = () => {
    this.setState(
      (state) => ({ isDarkMode: !state.isDarkMode }),
      () => {
        this.props.onToggleDarkMode(this.state.isDarkMode);
        window.document.documentElement &&
          window.document.documentElement.setAttribute(
            "data-theme",
            this.state.isDarkMode ? "dark" : "light"
          );
      }
    );
  };

  toggleAnnotations = () => {
    this.setState((state) => ({ showAnnotations: !state.showAnnotations }));
  };

  handleFormChanged = (form: Form) => {
    if (form.annotation !== this.state.form.annotation) {
      this.handleAnnotationUpdated(form.annotation);
    }
  };

  handleAnnotationUpdated = (annotation?: Annotation) => {
    this.setState({
      form: { annotation },
      showAnnotations: true,
      showComments: true,
    });
  };

  handleAnnotationSelected = (annotationId: string) => {
    this.setState({
      selectedCommentId: annotationId,
      showAnnotations: true,
      showComments: true,
    });
  };

  handleAnnotationDeselected = (annotationId: string) => {
    if (annotationId === this.state.selectedCommentId) {
      this.setState({ selectedCommentId: undefined });
    }
  };

  handleChangeZoomState = (zoomState: ZoomState) => {
    this.setState((state) => ({
      zoomStates: {
        ...state.zoomStates,
        [this.props.params.layerId]: zoomState,
      },
    }));
  };

  handleZoomToFit = () => {
    const previewLayer = this.layerCanvas;
    const zoomState = this.state.zoomStates[this.props.params.layerId];

    if (!previewLayer) {
      return zoomState;
    }

    if (previewLayer.zoomablePreview) {
      return {
        scale: previewLayer.zoomablePreview.scaleToFit,
        scaledToFit: true,
        offsetX: 0,
        offsetY: 0,
      };
    } else {
      return zoomState;
    }
  };

  onKeyDown = (event: KeyboardEvent) => {
    if (eventInInput(event)) {
      return;
    }

    const previousInLayerSet = this.getPreviousInLayerSet();
    const nextInLayerSet = this.getNextInLayerSet();

    switch (event.keyCode) {
      case KeyCode.KEY_ESCAPE:
        if (this.props.isFullScreen && this.props.onExitFullScreen) {
          this.props.onExitFullScreen();
        } else {
          this.props.onClickClose && this.props.onClickClose();
        }
        break;
      case KeyCode.KEY_A:
        if (event.altKey) {
          this.toggleAnnotations();
        }
        break;
      case KeyCode.KEY_D:
        this.toggleDarkMode();
        break;
      case KeyCode.KEY_LEFT:
        if (previousInLayerSet) {
          this.props.onClickPrevious(previousInLayerSet);
        }
        break;
      case KeyCode.KEY_RIGHT:
        if (nextInLayerSet) {
          this.props.onClickNext(nextInLayerSet);
        }
        break;
      case KeyCode.KEY_F:
        if (this.props.onToggleFullscreen) {
          this.props.onToggleFullscreen();
        }
        break;
      default:
    }
  };

  renderLayerComments(tablet?: boolean) {
    let sha = this.props.params.sha;

    if (sha === "latest" && this.props.latestCommit) {
      sha = this.props.latestCommit.sha;
    }

    const comments = memoize(
      () => ({
        [sha]: this.props.comments,
      }),
      () => sha
    );

    return (
      <LayerComments
        isCreateCommentFormFocused={this.state.focusNewCommentInput}
        branchId={this.props.params.branchId}
        canShowHistory={false}
        comments={comments()}
        fileId={this.props.params.fileId}
        form={this.state.form}
        hasError={this.props.commentsState === "error"}
        isLoading={this.props.commentsState === "loading"}
        layer={this.props.layer}
        layerId={this.props.params.layerId}
        mobile={tablet}
        onCommentSelected={this.handleAnnotationSelected}
        onFormChanged={this.handleFormChanged}
        pageId={this.props.layer ? this.props.layer.pageId : undefined}
        projectId={this.props.params.projectId}
        scrollable
        selectedCommentId={this.state.selectedCommentId}
        sha={sha}
        toggleNewAnnotation={this.state.toggleNewAnnotation}
        isShowingResolvedComments={this.props.isShowingResolvedComments}
      />
    );
  }

  renderCommentsList() {
    return isDesktop ? (
      this.renderLayerComments()
    ) : (
      <Media tablet>{(tablet) => this.renderLayerComments(tablet)}</Media>
    );
  }

  render() {
    const annotationsWithNew = this.getAnnotationsWithNew();
    const previousPrototypeExists =
      !!this.props.location.state &&
      !!this.props.location.state.previousPrototypeLocation;

    const nextInLayerSet = this.getNextInLayerSet();
    const previousInLayerSet = this.getPreviousInLayerSet();
    const layerMissing = this.props.file ? !this.props.layer : undefined;

    const layerVersionDescriptor = getLayerVersionDescriptor(this.props);

    const content = (
      <Idle
        defaultIdle={this.props.defaultIdle}
        timeout={anyHover ? 1300 : 2600}
        onChange={({ idle }) => this.props.onIdleChange(idle)}
        events={IDLE_EVENTS}
        render={({ idle }) => (
          <Theme.Consumer>
            {({ themeName }) => (
              <div
                key={`layer-presentation-root-${themeName}`}
                className={classnames(style.main, {
                  [style.showComments]: this.state.showComments,
                  [style.darkMode]: this.state.isDarkMode,
                  [style.hideControls]: idle,
                })}
              >
                <div className={style.content}>
                  <div className={style.preview}>
                    <div className={style.topLeftControls}>
                      {this.props.onClickClose && (
                        <FloatingControl>
                          <Button
                            tooltip={{
                              forceHide: idle,
                              placement: "bottom-start",
                            }}
                            icon="close"
                            title={
                              this.props.isFullScreen ? "Close" : "Close (ESC)"
                            }
                            className={style.button}
                            onClick={this.props.onClickClose}
                            nude
                            qaSelector="layer-presentation-close"
                          />
                        </FloatingControl>
                      )}
                    </div>
                    <div className={style.topRightControls}>
                      <FloatingControl>
                        <Popover
                          label="Copy link to presentation"
                          placement="bottom"
                        >
                          {/* Popover needs a valid dom element to pass its ref to. */}
                          <span>
                            <CopyLinkButton
                              icon="link"
                              inputShare={this.props.inputShare}
                              noText
                              tint={false}
                              tooltip={false}
                              mode={this.props.mode}
                            />
                          </span>
                        </Popover>
                      </FloatingControl>
                      <FloatingControl>
                        <Button
                          tooltip={{ forceHide: idle, placement: "bottom" }}
                          title={`${
                            this.state.isDarkMode ? "Day" : "Night"
                          } mode (D) `}
                          className={style.button}
                          onClick={this.toggleDarkMode}
                          icon={this.state.isDarkMode ? "sun" : "moon"}
                          nude
                        />
                      </FloatingControl>
                      {this.props.onToggleFullscreen && (
                        <FloatingControl>
                          <Button
                            tooltip={{
                              forceHide: idle,
                              placement: "bottom-end",
                            }}
                            className={style.button}
                            onClick={this.props.onToggleFullscreen}
                            icon={
                              this.props.isFullScreen
                                ? "fullscreen-exit"
                                : "fullscreen-enter"
                            }
                            title={
                              this.props.isFullScreen
                                ? "Exit Fullscreen (ESC)"
                                : "Enter Fullscreen (F)"
                            }
                            nude
                          />
                        </FloatingControl>
                      )}
                    </div>
                    {!previousInLayerSet && !nextInLayerSet ? null : (
                      <div className={style.bottomLeftControls}>
                        <FloatingControl>
                          <Button
                            tooltip={{
                              forceHide: !previousInLayerSet || idle,
                              placement: "top-start",
                            }}
                            disabled={!previousInLayerSet}
                            className={classnames(
                              style.button,
                              style.previousLayerButton
                            )}
                            onClick={this.props.onClickPrevious.bind(
                              null,
                              previousInLayerSet
                            )}
                            icon="chevron-large-left"
                            title="← Previous"
                            nude
                            qaSelector="layer-presentation-previous"
                          />
                          <Button
                            tooltip={{
                              forceHide: !nextInLayerSet || idle,
                              placement: "top",
                            }}
                            disabled={!nextInLayerSet}
                            className={style.button}
                            onClick={this.props.onClickNext.bind(
                              null,
                              nextInLayerSet
                            )}
                            icon="chevron-large-right"
                            title="Next →"
                            nude
                            qaSelector="layer-presentation-next"
                          />
                        </FloatingControl>
                      </div>
                    )}
                    <div className={style.bottomRightControls}>
                      <FloatingControl>
                        <ZoomPercentageInput
                          zoomState={
                            this.state.zoomStates[this.props.params.layerId]
                          }
                          onChange={this.handleChangeZoomState}
                          onZoomToFit={this.handleZoomToFit}
                          unstyled
                        />
                      </FloatingControl>
                      {!this.props.hideInspect && (
                        <FloatingControl className={style.inspectButton}>
                          <Button
                            tooltip={{ forceHide: idle, placement: "top-end" }}
                            title={`Inspect ${
                              this.props.layer ? this.props.layer.type : "item"
                            }`}
                            className={style.button}
                            onClick={this.props.onClickLayerDetail}
                            icon="inspect"
                            nude
                          />
                        </FloatingControl>
                      )}
                      <FloatingControl>
                        <Button
                          tooltip={{ forceHide: idle, placement: "top-end" }}
                          title={`${
                            this.state.showComments ? "Hide" : "Show"
                          } Comments`}
                          className={style.button}
                          onClick={this.toggleComments}
                          icon="comment-default"
                          tint={this.state.showComments}
                          nude
                        />
                      </FloatingControl>
                      {!this.state.showComments && (
                        <FloatingControl className={style.quickActions}>
                          <button
                            className={style.unstyledButton}
                            onClick={this.toggleCommentsWithFocus}
                          >
                            <Input
                              type="text"
                              disabled
                              placeholder="Leave a comment…"
                              className={style.unstyledInput}
                              wrapperClass={style.unstyledInputWrapper}
                            />
                          </button>

                          <Button
                            tooltip={{
                              forceHide: idle,
                              placement: "top-end",
                            }}
                            title="Add annotation"
                            className={
                              (style.button,
                              style.newCommentWithAnnotationButton)
                            }
                            onClick={this.toggleCommentsWithAnnotation}
                            icon="annotation-add"
                            nude
                          />
                        </FloatingControl>
                      )}
                    </div>
                    <Loaded
                      loading={
                        !this.props.layer ||
                        layerMissing === undefined ||
                        this.props.loadingHotspots
                      }
                      delay={1000}
                    >
                      <LayerCanvas
                        ref={(ref) => (this.layerCanvas = ref)}
                        params={layerVersionDescriptor}
                        className={style.layerCanvasPresentation}
                        layer={this.props.layer}
                        layerMissing={
                          this.props.file ? !this.props.layer : false
                        }
                        alt={Layer.altText(this.props.layer)}
                        isCreatingAnnotation={
                          this.getNewAnnotation() !== undefined
                        }
                        zoomState={this.getZoomState()}
                        onChangeZoomState={this.handleChangeZoomState}
                        zoomablePreviewPadding={0}
                        useViewportDimensions
                      >
                        {(dimensions, scale, childrenStyle) => (
                          <div>
                            {this.props.showPrototypes && (
                              <div className={style.hotspotsOffset}>
                                <HotspotsOverlay
                                  style={childrenStyle}
                                  scale={scale / 100}
                                  width={dimensions.width}
                                  height={dimensions.height}
                                  layers={
                                    idx(this.props, (_) => _.data.loading)
                                      ? empty.array
                                      : this.props.layers
                                  }
                                  onClickTarget={this.props.onClickHotspot}
                                  previousPrototypeExists={
                                    previousPrototypeExists
                                  }
                                  descriptor={layerVersionDescriptor}
                                  location={this.props.location}
                                  flashHotspotsOnOverlayMount={
                                    this.state.flashHotspotsOnOverlayMount
                                  }
                                  onInitialHotspotFlashed={
                                    this.onInitialHotspotFlashed
                                  }
                                />
                              </div>
                            )}
                            {this.state.showAnnotations ||
                            this.getNewAnnotation() ? (
                              <Annotations
                                style={childrenStyle}
                                scale={scale}
                                width={dimensions.width}
                                height={dimensions.height}
                                annotations={annotationsWithNew}
                                editingId={
                                  this.state.form.annotation &&
                                  this.state.form.annotation.id
                                }
                                selectedId={this.state.selectedCommentId}
                                onAnnotationSelected={
                                  this.handleAnnotationSelected
                                }
                                onAnnotationDeselected={
                                  this.handleAnnotationDeselected
                                }
                                onAnnotationUpdated={
                                  this.handleAnnotationUpdated
                                }
                              />
                            ) : null}
                          </div>
                        )}
                      </LayerCanvas>
                    </Loaded>
                  </div>
                  <Sidebar
                    title="Comments"
                    direction="right"
                    className={style.sidebar}
                    contentClassName={style.sidebarContent}
                    collapsed={!this.state.showComments}
                    onClose={this.toggleComments}
                    wide
                  >
                    <div className={style.comments}>
                      {this.renderCommentsList()}
                    </div>
                  </Sidebar>
                </div>
              </div>
            )}
          </Theme.Consumer>
        )}
      />
    );

    return isDesktop ? (
      <Modal
        isOpen
        size="fullScreen"
        disableTransitions
        hideTitleBar={this.props.isFullScreen}
        shouldCloseOnEsc={false}
      >
        <div className={style.fullscreenDesktop}>{content}</div>
      </Modal>
    ) : (
      <div className={style.fullscreenWeb}>{content}</div>
    );
  }
}

export default connector(LayerPresentation);
