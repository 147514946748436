// @flow
import classnames from "classnames";
import * as React from "react";
import Heading from "core/components/Heading";
import style from "./style.scss";

type Props = {
  children: React.Node,
  className?: string,
  cardClassName?: string,
  heading?: React.Node,
  description?: React.Node,
  action?: React.Node,
  role?: string,
  colCount?: string,
  headingSize?: "xxxl" | "xxl" | "xl" | "l" | "m" | "s" | "xs",
  qaSelector?: string,
};

export default function FormSection({
  heading,
  description,
  className,
  action,
  children,
  role,
  colCount,
  cardClassName,
  headingSize = "l",
  qaSelector,
}: Props) {
  return (
    <section className={classnames(style.section, className)}>
      {heading && (
        <Heading
          level="2"
          size={headingSize}
          className={classnames(style.heading, {
            [style.headingLite]: !!description,
          })}
        >
          {heading} {action}
        </Heading>
      )}
      {description && <div className={style.description}>{description}</div>}
      <div
        role={role}
        aria-colcount={colCount}
        className={classnames(style.card, cardClassName)}
        data-qa={qaSelector}
      >
        {children}
      </div>
    </section>
  );
}
