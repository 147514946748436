// @flow
import { connect } from "react-redux";
import { trackEvent } from "core/actions/analytics";
import { push } from "core/lib/location";
import * as Request from "core/models/request";
import { getRequest } from "core/selectors/requests";
import type { Dispatch, State } from "core/types";
import { signIn, resetLogin } from "web/actions/login";
import SignIn, {
  type Props,
  type OwnProps,
  type StateProps,
  type DispatchProps,
} from "web/components/Onboarding/SignIn";
import {
  ConfigurationFetchRequest,
  SessionCreateRequestId,
} from "web/requests/sessions";
import { forgotPassword, signup } from "web/routeHelpers";
import { isLoggedIn } from "web/selectors/session";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  return {
    username: props.location.query.username,
    isLoading: ConfigurationFetchRequest.isLoadingStrict(state, {}),
    isSubmitting: Request.isLoadingStrict(
      getRequest(state, SessionCreateRequestId)
    ),
    isLoggedIn: isLoggedIn(state),
    returnTo:
      props.location.state && props.location.state.returnTo
        ? props.location.state.returnTo
        : undefined,
    error: state.login.error,
    invitation: state.invitation.entity,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    onSignIn: (username, password) =>
      dispatch(
        signIn(username, password, (response) => {
          // userId needs to be passed because at this point in time
          // the identify trackUser call hasn’t triggered yet
          dispatch(trackEvent("SIGNED_IN", { userId: response.user_id }));
        })
      ),
    onPasswordReset: () => push(forgotPassword()),
    onCreateAccount: () => push(signup()),
    resetError: () => dispatch(resetLogin()),
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(
  mapStateToProps,
  mapDispatchToProps
)(SignIn);
