// @flow
import { segmentHelper } from "@marvelapp/react-ab-test";
import createTracker from "analytics.js-loader";
import format from "date-fns/format";
import Cookies from "js-cookie";
import history from "abstract-di/history";
import { trackMetrics as coreTrackMetrics } from "core/actions/analytics";
import window from "core/global/window";
import abstract from "core/lib/abstract";
import { filterUrl } from "core/lib/sensitiveUrlFilter";
import { canUseSDKMetrics } from "core/selectors/features";
import type { AnyDescriptor, Metric } from "core/types";
import { SEGMENT_API_KEY, SEGMENT_ENABLED } from "web/config";
import store from "web/store";
import version from "web/version";

// use custom converters to avoid double encoding issue with js-cookie
// this is required to match encoding used by the www site
const cookies = Cookies.withConverter({
  read: decodeURIComponent,
  write: encodeURIComponent,
});

export let analytics;

if (SEGMENT_ENABLED) {
  analytics = createTracker({
    writeKey: SEGMENT_API_KEY,
    skipPageCall: true,
  });
  segmentHelper.enable();

  abstract._analyticsCallback = function analyticsCallback(metric) {
    canUseSDKMetrics(store.getState()) &&
      analytics.track(
        "PROFILER_METRIC",
        {
          metric: `SDKRequest:${metric.endpoint}:${metric.request}`,
          platform: "web",
          source: "sdk",
          version,
          ...metric,
        },
        {
          integrations: {
            All: false,
            "Amazon S3": true,
          },
        }
      );
  };
}

export function trackMetrics(subject: AnyDescriptor, metrics: Metric[]) {
  store.dispatch(coreTrackMetrics(subject, metrics, version));
}

export const FIRST_PAGE_VISITED_KEY = "First_Page_Visited";
export const FIRST_VISIT_DATE_KEY = "First_Visit_Date";
export const LAST_PAGE_VISITED_KEY = "Last_Page_Visited";
export const LAST_VISIT_DATE_KEY = "Last_Visit_Date";

export function trackVisits() {
  const cookieConfig = { expires: 365 };

  const trackVisit = () => {
    // use window.location to access origin, matching www implementation
    const visitedUrl = filterUrl(
      `${window.location.origin}${window.location.pathname}`
    );
    const currentDate = format(new Date(), "M/D/YYYY");

    if (!getFirstPageVisited()) {
      cookies.set(FIRST_PAGE_VISITED_KEY, visitedUrl, cookieConfig);
    }

    if (!getFirstVisitDate()) {
      cookies.set(FIRST_VISIT_DATE_KEY, currentDate, cookieConfig);
    }

    cookies.set(LAST_PAGE_VISITED_KEY, visitedUrl, cookieConfig);
    cookies.set(LAST_VISIT_DATE_KEY, currentDate, cookieConfig);
  };

  trackVisit(); // track initial page load
  history.listen(trackVisit); // track subsequent navigation
}

export function getFirstPageVisited(): ?string {
  return cookies.get(FIRST_PAGE_VISITED_KEY);
}

export function getFirstVisitDate(): ?string {
  return cookies.get(FIRST_VISIT_DATE_KEY);
}

export function getLastPageVisited(): ?string {
  return cookies.get(LAST_PAGE_VISITED_KEY);
}

export function getLastVisitDate(): ?string {
  return cookies.get(LAST_VISIT_DATE_KEY);
}
