// @flow
import type { State } from "core/types";

export function getActiveElement(state: State) {
  return state.navigation.activeElement;
}

export function getPresentationIdleState(state: State) {
  return state.navigation.presentationIdle || false;
}
