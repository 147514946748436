// @flow
import classnames from "classnames";
import currency from "currency.js";
import format from "date-fns/format";
import * as React from "react";
import Badge from "core/components/Badge";
import SettingsItem from "core/components/SettingsItem";
import type { Invoice as InvoiceType } from "../../../../types";
import style from "./style.scss";

const formatDateSimple = (date: string) => {
  return format(date, "MM/DD/YYYY");
};

const status = {
  open: "pending",
  collected: "paid",
  failed: "unpaid",
  past_due: "past due",
};

type Props = {
  invoice: InvoiceType,
};

const InvoiceItem = ({ invoice }: Props) => {
  return (
    <SettingsItem className={style.line}>
      <div>
        <strong className={style.date}>
          {formatDateSimple(invoice.createdAt)}
        </strong>
        <span className={style.description}>
          {invoice.description || invoice.productCode}
        </span>
      </div>
      <div className={style.meta}>
        <Badge
          label={status[invoice.state]}
          className={classnames(
            style.status,
            invoice.state ? style[invoice.state] : undefined
          )}
        />
        <span className={style.amount}>
          {currency(invoice.totalInCents).divide(100).format(true)}
        </span>
        <strong className={style.download}>
          <a
            href={invoice.pdfUrl}
            rel="noopener noreferrer"
            target="_blank"
            download
          >
            Download PDF
          </a>
        </strong>
      </div>
    </SettingsItem>
  );
};

export default InvoiceItem;
