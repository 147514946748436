// @flow
import {
  LAYER_FILL_TYPE_SOLID,
  LAYER_FILL_TYPE_GRADIENT,
} from "core/components/LayerProperties/constants";
import type {
  MutableLayerData,
  LayerDataProperties,
  LayerFillSolid,
  LayerColor,
} from "core/types";

// Tint overrides a color and multiplies its opacity with the original layer.
function recolor(layerColor: LayerColor, tintColor: LayerColor) {
  layerColor.components = {
    ...tintColor.components,
    alpha: layerColor.components.alpha * tintColor.components.alpha,
  };
  return layerColor;
}

export function tintLayer(layerData: MutableLayerData) {
  if (layerData.properties.tint && layerData.properties.fills) {
    if (layerData.type === "group" || layerData.type === "symbolInstance") {
      layerData.properties = overwriteTint(
        layerData.properties.tint,
        layerData.properties
      );
    } else {
      layerData.properties = applyTintToLayer(
        layerData.properties.tint,
        layerData.properties
      );
    }
  }
  return layerData;
}

// In the case of a tinted group/symbol inside another tinted group/symbol, the
// highest level tint overrides any lower level tint.
export function overwriteTint(
  tint: LayerFillSolid,
  properties: LayerDataProperties
) {
  return {
    ...properties,
    tint,
    fills: [tint],
  };
}

// Tints replace the fill and border of a colored layer. If the tint has a
// non-100% alpha value, then that alpha is multiplied against the layer's
// own alphas, including semitransparent gradient stops.
export function applyTintToLayer(
  tint: LayerFillSolid,
  properties: LayerDataProperties
) {
  const tintColor = tint.color;

  if (properties.fills) {
    properties.fills.forEach((fill) => {
      if (fill.fillType === LAYER_FILL_TYPE_SOLID) {
        fill.color = recolor(fill.color, tintColor);
      }
      if (fill.fillType === LAYER_FILL_TYPE_GRADIENT) {
        fill.gradient.stops.forEach((gradientStop) => {
          gradientStop.color = recolor(gradientStop.color, tintColor);
        });
      }
    });
  }
  if (properties.borders) {
    properties.borders.forEach((border) => {
      if (border.fillType === LAYER_FILL_TYPE_SOLID) {
        border.color = recolor(border.color, tintColor);
      }
      if (border.fillType === LAYER_FILL_TYPE_GRADIENT) {
        border.gradient.stops.forEach((gradientStop) => {
          gradientStop.color = recolor(gradientStop.color, tintColor);
        });
      }
    });
  }
  return properties;
}
