// @flow
import * as React from "react";
import ActivityBase from "./Base";
import Callout from "./Callout";
import type {
  ActivityReviewDismissed as TActivityReviewDismissed,
  Props,
} from "./types";

function action(activity: TActivityReviewDismissed) {
  const reviewerName =
    activity.userId === activity.payload.reviewerId ? (
      "their own"
    ) : (
      <Callout>{activity.payload.reviewerName}’s</Callout>
    );
  return <span>dismissed {reviewerName} review of the branch</span>;
}

export default function ActivityReviewDismissed(
  props: Props<TActivityReviewDismissed>
) {
  const { activity, context } = props;
  return (
    <ActivityBase
      {...props}
      icon="close"
      branchId={activity.branchId}
      branchName={activity.payload.branchName}
      action={action(activity)}
      context={context}
      subject={
        context.branchId ? null : (
          <Callout>{activity.payload.branchName}</Callout>
        )
      }
      when={activity.createdAt}
    />
  );
}
