// @flow
import { normalize, schema } from "normalizr";

export const applicationSchema = new schema.Entity("oAuthApplications");

const applicationResponseSchema = new schema.Object({
  data: applicationSchema,
});

const applicationsResponseSchema = new schema.Object({
  data: [applicationSchema],
});

export function normalizeOAuthApplicationResponse(response: Object) {
  return normalize(response, applicationResponseSchema);
}

export function normalizeOAuthApplicationsResponse(response: Object) {
  return normalize(response, applicationsResponseSchema);
}
