// @flow
import classnames from "classnames";
import { capitalize } from "lodash";
import * as React from "react";
import { default as CollectionInfo } from "core/components/CollectionInfo";
import DialogForm from "core/components/DialogForm";
import Heading from "core/components/Heading";
import InputCheckbox from "core/components/InputCheckbox";
import InputRadio from "core/components/InputRadio";
import RadioGroup from "core/components/RadioGroup";
import { V3Link as Link } from "core/lib/router";
import { organizationAdminsUrl, organizationSharingUrl } from "core/lib/urls";
import {
  historyEnabled,
  inspectEnabled,
  isPublic,
  publicOptionsVisible,
} from "core/models/shareLink";
import type {
  ShareLink,
  ShareLinkUpdateRequestParams,
  InputShare,
  Collection,
} from "core/types";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  itemLabel: string,
  organizationId: string,
  inputShare: InputShare,
  collection?: ?Collection,
  canShowHandoff?: boolean,
  isOpen: boolean,
  isMobile?: boolean,
  onClose: () => void,
  onOpen?: () => void,
|};

export type StateProps = {|
  isGeneratingShareLink?: boolean,
  publicSharingEnabled?: boolean,
  canManageSettings?: ?boolean,
  shareLink?: ?ShareLink,
|};

export type DispatchProps = {|
  updateShareLink: (params: ShareLinkUpdateRequestParams) => ShareLink,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type State = {|
  isPublic: boolean,
  historyEnabled: boolean,
  inspectEnabled: boolean,
  isLoading: boolean,
  prevShareLink: ?ShareLink,
|};

class SharingSettings extends React.Component<Props, State> {
  static defaultProps = {
    itemLabel: "item",
  };

  state = {
    isPublic: isPublic(this.props.shareLink),
    historyEnabled: historyEnabled(this.props.shareLink),
    inspectEnabled: !!(
      inspectEnabled(this.props.shareLink) && this.props.canShowHandoff
    ),
    isLoading: false,
    prevShareLink: this.props.shareLink,
  };

  // If this modal is created in a context that doesn't already have this object's ShareLink
  // generated and read (e.g. LayerMenu), the parent must dispatch a request for a ShareLink
  // and this captures and updates the modal when it is received.
  static getDerivedStateFromProps(props: Props, state: State) {
    if (state.prevShareLink === props.shareLink) {
      return null;
    }
    return {
      isPublic: isPublic(props.shareLink),
      historyEnabled: historyEnabled(props.shareLink),
      inspectEnabled: !!(
        inspectEnabled(props.shareLink) && props.canShowHandoff
      ),
      isLoading: false,
      prevShareLink: props.shareLink,
    };
  }

  onPublicChange = (event: SyntheticInputEvent<>) => {
    if (!this.props.shareLink) {
      return;
    }

    const isPublic = event.target.value === "public";
    this.setState({ isPublic });
  };

  onShareLinkChange = (event: SyntheticInputEvent<>) => {
    if (!this.props.shareLink) {
      return;
    }

    const name = event.target.name;
    const isChecked = event.target.checked;
    this.setState({ [name]: isChecked });
  };

  onSave = async () => {
    if (!this.props.shareLink) {
      return;
    }
    const shareLinkId = this.props.shareLink.id;
    const { isPublic, historyEnabled, inspectEnabled } = this.state;

    this.setState({ isLoading: true });
    await this.props.updateShareLink({
      id: shareLinkId,
      isPublic,
      historyEnabled,
      inspectEnabled,
    });

    this.setState({ isLoading: false });
    this.props.onClose();
  };

  // We need to swallow this event so it doesn't propagate and
  // cause the ShareInfo flyover to close when the modal is clicked.
  swallowEvent(event: SyntheticEvent<>) {
    event.stopPropagation();
  }

  render() {
    return (
      <DialogForm
        title="Sharing Settings"
        isOpen={this.props.isOpen}
        onOpen={this.props.onOpen}
        onClose={this.props.onClose}
        closeIcon={this.props.isMobile ? "arrow-large-left" : undefined}
        primaryButton="Save"
        secondaryButton="Cancel"
        loading={this.state.isLoading}
        onSubmit={this.onSave}
        onClick={this.swallowEvent}
      >
        <div>
          {this.props.collection && (
            <CollectionInfo collection={this.props.collection} />
          )}
          <div>
            <Heading level="2" size="m" className={style.heading}>
              Who can view this {capitalize(this.props.itemLabel)}?
            </Heading>
            <RadioGroup
              name="public-share"
              value={this.state.isPublic ? "public" : "private"}
              onChange={this.onPublicChange}
            >
              <InputRadio
                labelClass={style.radioLabel}
                label="Only people in this project"
                value="private"
              />
              <InputRadio
                labelClass={
                  this.props.publicSharingEnabled
                    ? style.radioLabel
                    : style.disabled
                }
                label="Anyone with the link"
                value="public"
                disabled={!this.props.publicSharingEnabled}
              />
            </RadioGroup>
            {!this.props.publicSharingEnabled && (
              <div className={classnames(style.disabled, style.notPermitted)}>
                Public sharing is not permitted in your organization.{" "}
                {this.props.canManageSettings ? (
                  <span>
                    As an admin, you can enable it in your{" "}
                    <Link
                      to={organizationSharingUrl(this.props.organizationId)}
                      target="_blank"
                    >
                      organization settings
                    </Link>
                    .
                  </span>
                ) : (
                  <span>
                    Please contact an admin for information.{" "}
                    <Link
                      to={organizationAdminsUrl(this.props.organizationId)}
                      target="_blank"
                    >
                      View admins…
                    </Link>
                  </span>
                )}
              </div>
            )}
            {this.props.publicSharingEnabled &&
              publicOptionsVisible(this.props.inputShare) && (
                <div
                  className={classnames({
                    [style.disabled]: !this.state.isPublic,
                  })}
                >
                  <Heading level="2" size="m" className={style.heading}>
                    What can people outside of this project access?
                  </Heading>
                  <InputCheckbox
                    label="All versions"
                    name="historyEnabled"
                    checked={this.state.historyEnabled}
                    onChange={this.onShareLinkChange}
                    checkboxClass={style.checkbox}
                    wrapperClass={style.checkboxWrapper}
                    labelClass={style.checkboxLabel}
                    disabled={!this.state.isPublic}
                  />
                  {this.props.canShowHandoff && (
                    <InputCheckbox
                      label="Inspect"
                      name="inspectEnabled"
                      checked={this.state.inspectEnabled}
                      onChange={this.onShareLinkChange}
                      checkboxClass={style.checkbox}
                      wrapperClass={style.checkboxWrapper}
                      labelClass={style.checkboxLabel}
                      disabled={!this.state.isPublic}
                    />
                  )}
                </div>
              )}
          </div>
        </div>
      </DialogForm>
    );
  }
}
export const Component = SharingSettings;

export default connector(SharingSettings);
