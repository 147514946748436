// @flow
import * as React from "react";
import { connect } from "react-redux";
import Badge from "core/components/Badge";
import Button from "core/components/Button";
import DialogForm from "core/components/DialogForm";
import Flex from "core/components/Flex";
import Heading from "core/components/Heading";
import SettingsItem from "core/components/SettingsItem";
import { fromNow } from "core/lib/dates";
import * as Request from "core/models/request";
import type { State, Dispatch } from "core/types";
import {
  setEmailAsPrimary,
  deleteEmail,
  sendEmailVerification,
} from "web/actions/emails";
import ContextMenu from "web/di/components/ContextMenu";
import { isPrimaryEmail } from "web/selectors/emails";
import {
  getDeleteEmailRequest,
  getSetEmailAsPrimaryRequest,
  getSendEmailVerificationRequest,
} from "web/selectors/requests";
import type { Email } from "web/types";
import style from "./style.scss";

type OwnProps = {| email: Email |};

type StateProps = {|
  isSubmitting: boolean,
  isPrimaryEmail: boolean,
|};

type DispatchProps = {|
  setEmailAsPrimary: () => void,
  deleteEmail: () => void,
  sendEmailVerification: () => void,
|};

type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type ComponentState = { showConfirm: boolean };

class EmailListItem extends React.Component<Props, ComponentState> {
  state = { showConfirm: false };

  showConfirm = () => {
    this.setState({ showConfirm: true });
  };

  hideConfirm = (event?: SyntheticEvent<>) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({ showConfirm: false });
  };

  removeEmail = (event: SyntheticEvent<>) => {
    event.preventDefault();
    this.props.deleteEmail();
  };

  renderConfirm = () => {
    if (!this.props.email) {
      return null;
    }

    return (
      <DialogForm
        dangerous
        onSubmit={this.removeEmail}
        isOpen={this.state.showConfirm}
        disabled={this.props.isSubmitting}
        loading={this.props.isSubmitting}
        onClose={this.hideConfirm}
        primaryButton={
          this.props.isSubmitting ? "Removing Email…" : "Remove Email"
        }
        title="Remove email"
      >
        <div>
          Are you sure you want to remove{" "}
          <strong>{this.props.email.email}</strong> from your emails?
        </div>
      </DialogForm>
    );
  };

  menuItems = () => {
    const { email, isPrimaryEmail, isSubmitting } = this.props;
    const isVerified = !!email.verifiedAt;
    return [
      {
        enabled: !(isPrimaryEmail || isSubmitting),
        visible: isVerified,
        click: this.props.setEmailAsPrimary,
        label: "Make Primary",
      },
      {
        enabled: !isSubmitting,
        visible: !isVerified,
        click: this.props.sendEmailVerification,
        label: "Resend Confirmation Email",
      },
      { type: "separator" },
      {
        danger: true,
        enabled: !(isPrimaryEmail || isSubmitting),
        click: this.showConfirm,
        label: "Remove Email",
      },
    ];
  };

  render() {
    const { verifiedAt, verificationSentAt, email, id } = this.props.email;
    const { isPrimaryEmail } = this.props;

    return (
      <SettingsItem className={style.item}>
        <Flex align="center" className={style.wrap}>
          <div className={style.headingWrap}>
            <Heading level="3" size="m">
              {email}
              {isPrimaryEmail && <Badge label="Primary" />}
            </Heading>
          </div>
          <span className={style.actionedAt}>
            {verifiedAt
              ? `Verified ${fromNow(verifiedAt)}`
              : verificationSentAt
              ? `Confirmation email sent ${fromNow(verificationSentAt)}`
              : "Confirmation email pending"}
          </span>
          <span className={style.buttonWrap}>
            <ContextMenu id={id} menuItems={this.menuItems()}>
              {(showMenu, ref) => (
                <Button
                  nude
                  icon="overflow"
                  onClick={showMenu}
                  innerRef={ref}
                />
              )}
            </ContextMenu>
          </span>
        </Flex>
        {this.renderConfirm()}
      </SettingsItem>
    );
  }
}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  return {
    isPrimaryEmail: isPrimaryEmail(state, props.email.id),
    isSubmitting:
      Request.isLoadingStrict(getDeleteEmailRequest(state, props.email.id)) ||
      Request.isLoadingStrict(
        getSetEmailAsPrimaryRequest(state, props.email.id)
      ) ||
      Request.isLoadingStrict(
        getSendEmailVerificationRequest(state, props.email.id)
      ),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    setEmailAsPrimary: () => dispatch(setEmailAsPrimary(props.email.id)),
    deleteEmail: () => dispatch(deleteEmail(props.email.id)),
    sendEmailVerification: () =>
      dispatch(sendEmailVerification(props.email.id)),
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(
  mapStateToProps,
  mapDispatchToProps
)(EmailListItem);
