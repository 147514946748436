// @flow
import * as React from "react";
import InputPassword from "core/components/InputPassword";
import { V3Link as Link } from "core/lib/router";
import OnboardingForm from "web/components/OnboardingForm";
import OnboardingPage from "web/components/OnboardingPage";
import withForm from "web/containers/withForm";
import { forgotPassword, signin } from "web/routeHelpers";
import type { FormProps } from "web/types";
import style from "./style.scss";

export type FormValues = {
  password: string,
  passwordConfirmation: string,
};

type OwnProps = {
  onSubmit: (formId: string, token: string, values: FormValues) => void,
  token: string,
  invalidToken: boolean,
};

type Props = {
  ...OwnProps,
  ...FormProps,
};

class ResetPassword extends React.Component<Props> {
  handleChange = (event: SyntheticInputEvent<>) => {
    this.props.form.onChange(this.props.form.id, {
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (event: SyntheticInputEvent<>) => {
    event.preventDefault();
    this.props.onSubmit(
      this.props.form.id,
      this.props.token,
      this.props.form.values
    );
  };

  renderError() {
    return (
      <div className={style.center}>
        <p>Invalid reset password link</p>
        <p>
          If you need to reset your password, please{" "}
          <Link to={forgotPassword()}>start here</Link>.
        </p>
      </div>
    );
  }

  renderSuccess() {
    return (
      <div className={style.center}>
        <p>Successfully changed your password!</p>
        <p>
          Continue to the <Link to={signin()}>sign in screen</Link>.
        </p>
      </div>
    );
  }

  renderForm() {
    const { form } = this.props;
    if (form.submitSucceeded) {
      return this.renderSuccess();
    }

    return (
      <OnboardingForm
        noValidate
        onSubmit={this.handleSubmit}
        submitText="Change Password"
        disabled={form.isSubmitting}
        fullWidthSubmit
      >
        <InputPassword
          requiredTag
          autoCapitalize="none"
          error={form.errors.password}
          label="New password"
          name="password"
          placeholder="•••••••••••••••••"
          autoComplete="new-password"
          onChange={this.handleChange}
          value={form.values.password}
        />
        <InputPassword
          requiredTag
          autoCapitalize="none"
          error={form.errors.passwordConfirmation}
          label="Confirm new password"
          name="passwordConfirmation"
          placeholder="•••••••••••••••••"
          autoComplete="new-password"
          onChange={this.handleChange}
          value={form.values.passwordConfirmation}
        />
      </OnboardingForm>
    );
  }

  render() {
    const { invalidToken } = this.props;

    return (
      <OnboardingPage heading="Change Password" documentTitle="Change Password">
        {invalidToken ? this.renderError() : this.renderForm()}
      </OnboardingPage>
    );
  }
}

export default withForm<Props>(ResetPassword, "reset-password-form", {
  password: "",
  passwordConfirmation: "",
});
