// @flow
import * as React from "react";
import ContextMenu, {
  type Props as ContextMenuProps,
} from "core/components/ContextMenu";
import { BranchStatus } from "core/gitConstants";
import { isDesktop } from "core/lib/platform";
import { isActive } from "core/models/branch";
import type { Project, Policy, Branch, MenuItem } from "core/types";
import connector from "./connector";

export type OwnProps = {|
  projectId: string,
  branchId: string,
  onAfterClose?: () => void,
  innerRef?: React.Ref<typeof ContextMenu>,
  children: $PropertyType<ContextMenuProps, "children">,
|};

export type StateProps = {|
  project: ?Project,
  branch: ?Branch,
  policy: Policy,
  userIsBranchAuthor: boolean,
  isDiverged: boolean,
  isDevelopment: boolean,
  canManageBranch: boolean,
  isOnline: boolean,
|};

export type DispatchProps = {|
  showDialog: (name: string, props: {}) => void,
  archiveBranch: () => void,
  unarchiveBranch: () => void,
  deleteBranch: (branchName: string) => void,
  exportBranch: (branch: Branch, project: Project) => void,
  pushRepo: () => void,
  openInFinder: () => void,
  openInTerminal: () => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

function BranchMenu(props: Props) {
  const menuItems = (): Array<MenuItem> => {
    if (props.branch && props.project) {
      if (isDesktop) {
        const isBranchActive = isActive(props.branch);
        const isMaster = props.branchId === "master";
        return [
          {
            label: "Rename…",
            click: () => {
              props.showDialog("EditBranch", {
                project: props.project,
                branch: props.branch,
              });
            },
            enabled: props.userIsBranchAuthor && !props.isDiverged,
            visible:
              isBranchActive && !isMaster && props.policy.updateBranch === true,
          },
          {
            type: "separator",
            visible:
              isBranchActive && !isMaster && props.policy.updateBranch === true,
          },
          {
            label: isMaster ? "New Branch…" : "New Child Branch…",
            click: () => {
              props.showDialog("CreateBranch", {
                projectId: props.projectId,
                parentBranchId: props.branchId,
              });
            },
            enabled: !props.isDiverged,
            visible: props.policy.updateBranch === true,
          },
          {
            type: "share",
            label: "Copy Link to Branch",
            props: {
              kind: "branch",
              organizationId: props.project ? props.project.organizationId : "",
              projectId: props.projectId,
              branchId: props.branchId,
            },
            visible: props.policy.share === true,
            enabled: props.isOnline,
          },
          {
            label: "Export Files…",
            click: () => {
              if (props.branch && props.project) {
                props.exportBranch(props.branch, props.project);
              }
            },
            visible: props.policy.openFile === true,
          },
          { type: "separator" },
          {
            label: "Archive",
            click: props.archiveBranch,
            enabled: props.canManageBranch,
            visible:
              isBranchActive && !isMaster && props.policy.updateBranch === true,
          },
          {
            label: "Unarchive",
            click: props.unarchiveBranch,
            enabled: props.canManageBranch,
            visible:
              !!props.branch &&
              props.branch.status === BranchStatus.ARCHIVED &&
              !isMaster &&
              props.policy.updateBranch === true,
          },
          {
            label: "Delete…",
            click: () => {
              if (props.branch) {
                props.deleteBranch(props.branch.name);
              }
            },
            visible:
              props.userIsBranchAuthor &&
              !isMaster &&
              props.policy.updateBranch === true,
          },
          { type: "separator", visible: props.isDevelopment },
          {
            label: "Development",
            visible: props.isDevelopment,
            submenu: [
              {
                label: "Force Push Branch",
                click: props.pushRepo,
              },
              {
                label: "Open in Finder",
                click: props.openInFinder,
              },
              {
                label: "Open in Terminal",
                click: props.openInTerminal,
              },
              {
                type: "copy",
                label: "Copy Branch ID",
                text: props.branchId,
              },
            ],
          },
        ];
      } else {
        return [
          {
            type: "share",
            label: "Copy Link to Branch",
            props: {
              kind: "branch",
              organizationId: props.project.organizationId,
              projectId: props.projectId,
              branchId: props.branchId,
            },
            visible: props.policy.share === true,
            enabled: props.isOnline,
          },
        ];
      }
    } else {
      return [
        {
          label: "Loading branch…",
          enabled: false,
          visible: true,
        },
      ];
    }
  };
  return (
    <ContextMenu
      id={props.branchId}
      ref={props.innerRef}
      menuItems={menuItems()}
      placement="bottom-end"
      children={props.children}
      onAfterClose={props.onAfterClose}
    />
  );
}

export default connector(BranchMenu);
