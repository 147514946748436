// @flow
import { guard, string, optional } from "decoders";

const env = guard(string);
const optionalEnv = guard(optional(string));

export const COMMIT_SHA = optionalEnv(process.env.COMMIT_SHA);

export const ABSTRACT_API_URL = env(process.env.ABSTRACT_API_URL);
export const ABSTRACT_APP_URL = env(process.env.ABSTRACT_APP_URL);
export const ABSTRACT_AUTH_URL = env(process.env.ABSTRACT_AUTH_URL);
export const ABSTRACT_PREVIEWS_URL = env(process.env.ABSTRACT_PREVIEWS_URL);
export const ABSTRACT_THUMBNAILS_URL = optionalEnv(
  process.env.ABSTRACT_THUMBNAILS_URL
);
export const ABSTRACT_ORS_URL = optionalEnv(process.env.ABSTRACT_ORS_URL);
export const ABSTRACT_AUTH_CLIENT_ID = env(process.env.ABSTRACT_AUTH_CLIENT_ID);
export const RECURLY_PUBLIC_API_KEY = optionalEnv(
  process.env.RECURLY_PUBLIC_API_KEY
);
export const RECURLY_SUBDOMAIN = env(process.env.RECURLY_SUBDOMAIN);
export const PUSHER_APP_KEY = optionalEnv(process.env.PUSHER_APP_KEY);
export const SEGMENT_API_KEY = optionalEnv(process.env.SEGMENT_API_KEY);
export const SENTRY_DSN = optionalEnv(process.env.SENTRY_DSN);
export const RECAPTCHA_SITE_KEY = optionalEnv(process.env.RECAPTCHA_SITE_KEY);
export const USE_MAIN_INSTEAD_OF_MASTER = optionalEnv(
  process.env.USE_MAIN_INSTEAD_OF_MASTER
);

// Environment-specific configs.
export const REDUX_LOGGER_ENABLED =
  process.env.NODE_ENV === "development" &&
  process.env.DISABLE_REDUX_LOGGER !== "true";
export const SECURE_COOKIES_ENABLED = process.env.NODE_ENV === "production";
export const SEGMENT_ENABLED = process.env.SEGMENT_ENABLED === "true"; // replaced '!!SEGMENT_API_KEY' with explicit env check;
