// @flow
import * as React from "react";
import DialogForm from "core/components/DialogForm";
import Input from "core/components/Input";
import * as Request from "core/models/request";
import type { Project, Policy, Request as TRequest } from "core/types";
import connector from "./connector";

const CONFIRM_DELETE = "CONFIRM DELETE";

export type OwnProps = {|
  project: Project,
  isOpen: boolean,
  onClose: () => void,
|};

export type StateProps = {|
  policy: Policy,
  deleteRequest: TRequest,
  isOffline: boolean,
  isUnsynced: boolean,
|};

export type DispatchProps = {|
  onStopSyncingProject: () => void,
  onDeleteProject: () => void,
|};

type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type State = {
  deleteConfirmed: boolean,
};

class ConfirmationDialog extends React.Component<Props, State> {
  state = { deleteConfirmed: false };

  handleConfirm = (event: SyntheticInputEvent<>) => {
    const text = event.target.value;
    this.setState({ deleteConfirmed: text === CONFIRM_DELETE });
  };

  handleDeleteConfirm = async () => {
    await this.props.onDeleteProject();
    this.closeDeleteConfirmation();
  };

  closeDeleteConfirmation = (event?: SyntheticEvent<>) => {
    if (event) {
      event.preventDefault();
    }
    if (event) {
      event.stopPropagation();
    }
    this.props.onClose();
  };

  render() {
    if (this.props.isOffline && !this.props.isUnsynced) {
      return (
        <DialogForm
          isOpen={this.props.isOpen}
          onClose={this.closeDeleteConfirmation}
          title="Delete Project"
          primaryButton="Stop syncing"
          onSubmit={() => {
            this.closeDeleteConfirmation();
            this.props.onStopSyncingProject();
          }}
          secondaryButton="Cancel"
          onSecondary={this.closeDeleteConfirmation}
        >
          You can only delete a project while online. In the meantime, you can
          stop syncing this project, which will delete it from your computer and
          keep it available to others.
        </DialogForm>
      );
    }

    if (this.props.isOffline && this.props.isUnsynced) {
      return (
        <DialogForm
          isOpen={this.props.isOpen}
          onClose={this.closeDeleteConfirmation}
          title="Delete Project"
          primaryButton="Close"
          onSubmit={this.closeDeleteConfirmation}
        >
          You can only delete a project while online.
        </DialogForm>
      );
    }

    if (!this.props.policy || !this.props.policy.destroy) {
      return null;
    }

    const deleteProcessing = Request.isLoadingStrict(this.props.deleteRequest);
    const deleteError = Request.hasError(this.props.deleteRequest);

    return (
      <DialogForm
        dangerous
        isOpen={this.props.isOpen}
        onClose={this.closeDeleteConfirmation}
        title="Delete project"
        onSubmit={this.handleDeleteConfirm}
        disabled={!this.state.deleteConfirmed || deleteProcessing}
        primaryButton="I'm Sure, Delete The Project"
      >
        {() => (
          <div>
            <p>
              This will permanently delete the{" "}
              <strong>{this.props.project.name}</strong> project from Abstract,
              including all commit history, comments, and other contributions.
            </p>
            <Input
              error={
                deleteError ? "We ran into some unexpected trouble" : undefined
              }
              label={`Please type "CONFIRM DELETE"`}
              onChange={this.handleConfirm}
              autoComplete="off"
              qaSelector="confirmDeleteInput"
              autoFocus
            />
          </div>
        )}
      </DialogForm>
    );
  }
}

export default connector(ConfirmationDialog);
