// @flow
import Editor from "@draft-js-plugins/editor";
import createMentionPlugin from "@draft-js-plugins/mention";
import classnames from "classnames";
// $FlowFixMe
import { EditorState, ContentState, getDefaultKeyBinding } from "draft-js";
// $FlowFixMe: This file exists but we have to ignore draft-js module
import "draft-js/dist/Draft.css";
import { debounce } from "lodash";
import * as React from "react";
import inputStyle from "core/components/Input/style.scss";
import AutoCompleteListItem from "core/components/InputRich/AutoCompleteListItem";
import InputWrapper from "core/components/InputWrapper";
import filterUsers from "core/lib/filterUsers";
import { modifierKeyPressed } from "core/lib/platform";
import type { User } from "core/types";
import connector from "./connector";
import style from "./style.scss";

let counter = 0;

export type OwnProps = {|
  ref?: React.Ref<typeof InputRich>, // eslint-disable-line no-use-before-define
  innerRef?: React.Ref<"div">,
  id?: string,
  label?: React.Node,
  helpers?: {
    [key: string]: React.Node,
  },
  error?: ?string | string[],
  value: string,
  className?: string,
  debounce?: number,
  wrapperClass?: string,
  disabledClass?: string,
  focusClass?: string,
  disabled?: boolean,
  required?: boolean,
  fixedSuggestions?: boolean,
  autoFocus?: boolean,
  minHeight?: number,
  maxHeight?: number,
  minLength?: number,
  maxLength?: number,
  placeholder?: string,
  tabIndex?: string,
  type?: string,
  disallowNewLines?: boolean,
  maxSuggestions?: number,
  focusName?: string,
  overflowSuggestions?: boolean,
  onChange?: (value: string) => void,
  onKeyDown?: (event: SyntheticKeyboardEvent<>) => void,
  onSubmit: (event: SyntheticEvent<>) => ?Promise<void>,
  onFocus?: () => void,
  onBlur?: (event: SyntheticEvent<>) => void,
  projectId?: string,
  autocompleteDisabled?: boolean,
  qaSelector?: string,
|};

export type StateProps = {|
  users: User[],
  isOnline: boolean,
|};

export type DispatchProps = {|
  onSearchForUsers: (search: string) => void,
  showOfflineToast: () => void,
|};

export type Props = {| ...OwnProps, ...StateProps, ...DispatchProps |};

type Mention = {
  title: string,
  name: string,
  id: string,
  avatarUrl: string,
};

type State = {
  editorState: EditorState,
  suggestions: Mention[],
  plainTextContent?: string,
  focused: boolean,
  hasShownOfflineToast: boolean,
  mentionsOpen: boolean,
};

const disabledMentionPlugin = { MentionSuggestions: () => null };

class InputRich extends React.Component<Props, State> {
  id: string;
  mentionPlugin: *;
  plugins: *;
  editor: *;
  mounted: boolean = true;
  searchRequestId: number = 0;

  static defaultProps = {
    maxSuggestions: 5,
    fixedSuggestions: false,
    autoFocus: false,
    value: "",
    autocompleteDisabled: false,
  };

  constructor(props: Props) {
    super(props);

    this.id = `abstract-core-richinput-${counter}`;
    counter += 1;
    this.mentionPlugin = this.props.autocompleteDisabled
      ? disabledMentionPlugin
      : createMentionPlugin({
          mentionPrefix: "@",
          entityMutability: "IMMUTABLE",
          theme: style,
        });
    this.plugins = [this.mentionPlugin];

    this.state = {
      focused: false,
      plainTextContent: undefined,
      editorState: EditorState.createWithContent(
        ContentState.createFromText(props.value)
      ),
      suggestions: [],
      hasShownOfflineToast: false,
      mentionsOpen: false,
    };
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      // https://github.com/draft-js-plugins/draft-js-plugins/issues/357
      // Focus on componentDidMount causes the plugins not to work
      setTimeout(() => this.focus(), 10);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.autoFocus !== this.props.autoFocus && this.props.autoFocus) {
      this.focus();
    }
    if (
      (!this.props.value && prevProps.value) ||
      (!prevProps.value && this.props.value)
    ) {
      // https://github.com/draft-js-plugins/draft-js-plugins/blob/master/FAQ.md#how-to-reset-the-content
      const editorState = EditorState.push(
        this.state.editorState,
        ContentState.createFromText(this.props.value || "")
      );
      this.setState({ editorState: EditorState.moveFocusToEnd(editorState) });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  debouncedOnChange = debounce((plainTextContent: string) => {
    if (this.props.onChange) {
      this.props.onChange(plainTextContent);
    }
  }, this.props.debounce);

  handleChange = (editorState: EditorState) => {
    const contentState = editorState.getCurrentContent();
    const plainTextContent = contentState.getPlainText();
    const textChanged = plainTextContent !== this.state.plainTextContent;

    // editor state can change for things other than the text changing (eg navigating mentions)
    // we only care about dispatching change events to the outer components when the text changes
    if (!!this.props.onChange && textChanged) {
      if (this.props.debounce) {
        this.debouncedOnChange(plainTextContent);
      } else {
        this.props.onChange(plainTextContent);
      }
    }

    this.setState({ editorState, plainTextContent });
  };

  onSearchChange = (valueObject: { value: string }) => {
    const value = valueObject.value;
    if (!this.props.projectId) {
      return;
    }

    this.props.onSearchForUsers(value);

    if (!this.props.isOnline && !this.state.hasShownOfflineToast) {
      this.setState({ hasShownOfflineToast: true });
      this.props.showOfflineToast();
    }

    const users = this.props.users;
    const suggestions = filterUsers(users, value);

    const formattedSuggestions = suggestions.slice(0, 5).map((author) => ({
      id: author.id,
      name: author.username,
      title: author.name,
      avatarUrl: author.avatarUrl,
    }));

    if (this.mounted) {
      this.setState({
        suggestions: formattedSuggestions,
      });
    }
  };

  onOpenChange = (open: boolean) => {
    if (this.mounted) {
      this.setState({ mentionsOpen: open });
    }
  };

  handleReturn = (ev: SyntheticKeyboardEvent<>) => {
    if (modifierKeyPressed(ev)) {
      this.props.onSubmit(ev);
      return "handled";
    }

    // https://facebook.github.io/draft-js/docs/advanced-topics-key-bindings.html
    // Note: These return strings are expected by DraftJS
    return "not-handled";
  };

  handleKeyDown = (ev: SyntheticKeyboardEvent<>) => {
    if (this.props.onKeyDown) {
      this.props.onKeyDown(ev);
    }

    // https://draftjs.org/docs/advanced-topics-key-bindings.html
    return getDefaultKeyBinding(ev);
  };

  focus = () => {
    if (this.editor) {
      this.editor.focus();
    }
  };

  blur = () => {
    if (this.editor) {
      this.editor.blur();
    }
  };

  handleBlur = (event: SyntheticEvent<>) => {
    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
    if (this.state.focused) {
      this.setState({ focused: false });
    }
  };

  handleFocus = () => {
    if (this.props.onFocus) {
      this.props.onFocus();
    }
    if (!this.state.focused) {
      this.setState({ focused: true });
    }
  };

  render() {
    const {
      ref,
      innerRef,
      error,
      disabled,
      label,
      helpers,
      fixedSuggestions,
      minHeight,
      maxHeight,
      className,
      wrapperClass,
      autoFocus,
      onChange,
      onKeyDown,
      onSubmit,
      qaSelector,
      ...rest
    } = this.props;
    const id = this.props.id || this.id;
    const { MentionSuggestions } = this.mentionPlugin;

    const disabledClass = this.props.disabledClass
      ? this.props.disabledClass
      : "";

    const focusClass = this.props.focusClass ? this.props.focusClass : "";

    const overflowY = maxHeight ? "scroll" : "inherit";
    const styles = { minHeight, maxHeight, overflowY };
    const classes = classnames(style.input, inputStyle.text, className, {
      [inputStyle.disabled]: disabled,
      [inputStyle.inputError]: error,
      [style.fixedSuggestions]: fixedSuggestions,
      [disabledClass]: disabled,
      [focusClass]: this.state.focused,
    });

    return (
      <InputWrapper
        error={error}
        label={label}
        helpers={helpers}
        inputId={id}
        className={wrapperClass}
      >
        <div
          tabIndex="-1"
          onFocus={this.focus}
          className={classes}
          style={styles}
          ref={innerRef}
          data-qa={qaSelector}
        >
          <Editor
            ref={(ref) => (this.editor = ref)}
            editorState={this.state.editorState}
            onChange={this.handleChange}
            plugins={this.plugins}
            readOnly={this.props.disabled}
            handleReturn={this.handleReturn}
            keyBindingFn={this.handleKeyDown}
            spellCheck
            handleKeyCommand={(command) => {
              if (command === "split-block" && this.props.disallowNewLines) {
                return "handled";
              }
            }}
            {...rest}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
          />
          <MentionSuggestions
            open={this.state.mentionsOpen}
            onOpenChange={this.onOpenChange}
            onSearchChange={this.onSearchChange}
            suggestions={this.state.suggestions}
            entryComponent={AutoCompleteListItem}
          />
        </div>
      </InputWrapper>
    );
  }
}

export default connector(InputRich);
