// @flow
import * as React from "react";
import Config from "abstract-di/config";
import { openPersonalSettings, downloadApp } from "abstract-di/routes";
import ContextMenu, {
  type Props as ContextMenuProps,
} from "core/components/ContextMenu";
import { push } from "core/lib/location";
import { isWeb, isMac } from "core/lib/platform";
import { type ThemeConfig } from "core/lib/theme";
import {
  privacyUrl,
  termsOfServiceUrl,
  blogUrl,
  releaseNotesUrl,
} from "core/lib/urls";
import type { MenuItem } from "core/types";
import connector from "./connector";

export type OwnProps = {|
  // Web-only props
  themeConfig?: ThemeConfig,
  setThemeConfig?: (config: ThemeConfig) => void,
  showDownload?: boolean,
  mobile?: boolean,
  closeMobileSidebar?: () => void,

  innerRef?: React.Ref<typeof ContextMenu>,
  children: $PropertyType<ContextMenuProps, "children">,
  onAfterClose?: () => void,
|};

export type StateProps = {|
  canUseDevtools: boolean,
|};

export type DispatchProps = {|
  openDevtools: () => void,
  signOut: () => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

function AccountMenu(props: Props) {
  const menuItems: Array<MenuItem> = [
    {
      label: "Personal Settings",
      click: () => {
        if (openPersonalSettings) {
          openPersonalSettings();
        }

        if (props.closeMobileSidebar) {
          props.closeMobileSidebar();
        }
      },
    },
    { type: "separator" },
  ];

  // Web has a host of web-specific buttons. These may be ported to desktop if we want.
  if (isWeb) {
    menuItems.push(
      {
        label: "Download Desktop App",
        click: () => {
          if (downloadApp) {
            push(downloadApp());
          }
        },
        visible: isWeb && !props.mobile && isMac() && props.showDownload,
      },
      {
        label: "Abstract Blog",
        click: () => {
          Config.openExternal(blogUrl());
        },
      },
      { type: "separator" },
      {
        label: "Terms of Service",
        click: () => {
          Config.openExternal(termsOfServiceUrl());
        },
      },
      {
        label: "Privacy Policy",
        click: () => {
          Config.openExternal(privacyUrl());
        },
      },
      {
        label: "Release Notes",
        click: () => {
          Config.openExternal(releaseNotesUrl());
        },
      },
      { type: "separator" },
      {
        label: "Appearance",
        props: {
          placement: "right",
          trigger: "hoverMenu",
          display: "block",
        },
        submenu: [
          {
            type: "checkbox",
            label: "Auto",
            checked: props.themeConfig === "system",
            click: () => {
              if (props.setThemeConfig) {
                props.setThemeConfig("system");
              }
            },
          },
          {
            type: "checkbox",
            label: "Light",
            checked: props.themeConfig === "light",
            click: () => {
              if (props.setThemeConfig) {
                props.setThemeConfig("light");
              }
            },
          },
          {
            type: "checkbox",
            label: "Dark",
            checked: props.themeConfig === "dark",
            click: () => {
              if (props.setThemeConfig) {
                props.setThemeConfig("dark");
              }
            },
          },
        ],
      },
      { type: "separator" }
    );
  }

  if (props.canUseDevtools) {
    menuItems.push(
      {
        label: "Launch Abstract DevTools…",
        click: props.openDevtools,
      },
      { type: "separator" }
    );
  }
  menuItems.push({ label: "Sign Out", click: props.signOut });
  return (
    <ContextMenu
      menuItems={menuItems}
      id="account"
      ref={props.innerRef}
      children={props.children}
      onAfterClose={props.onAfterClose}
    />
  );
}

export default connector(AccountMenu);
