// @flow
import queryString from "query-string";
import window from "core/global/window";
import { Abstract } from "core/lib/abstract";
import type { ThunkAction } from "core/types";
import { supportTicketPath } from "web/routeHelpers";

export { trackUser } from "web/actions/analytics";
export { loadPages } from "web/actions/pages";
export { authenticateToken } from "web/actions/login";
export { loadProject } from "web/actions/projects";
export { loadOrganization } from "web/actions/organizations";
export { deleteSession as signOut } from "web/actions/session";
export { downloadApp } from "web/routeHelpers";

export const display = undefined;
export const loadLayersForCollection = undefined;
export const generateAssetsForFile = undefined;
export const copyToClipboard = undefined;
export const generateAndUploadPreviewsForCommit = undefined;
export const openLayerDebuggingDiff = undefined;
export const openProjectInFinder = undefined;
export const openProjectInTerminal = undefined;
export const downloadAssets = undefined;
export const confirmStopSyncing = undefined;
export const stopSyncingProject = undefined;
export const exportProject = undefined;
export const exportAllActiveProjects = undefined;
export const exportRepo = undefined;
export const repackRepo = undefined;
export const cloneRepo = undefined;
export const pullRepo = undefined;
export const pushRepo = undefined;
export const starProject = undefined;
export const unstarProject = undefined;
export const updateBranch = undefined;
export const archiveBranch = undefined;
export const unarchiveBranch = undefined;
export const exportBranch = undefined;
export const deleteFile = undefined;
export const openFile = undefined;
export const renameFile = undefined;
export const replaceFile = undefined;
export const createEmptyFile = undefined;
export const exportFile = undefined;
export const openFileInFinder = undefined;
export const openFileInTerminal = undefined;
export const clearCacheAndEditingFile = undefined;
export const createBranch = undefined;
export const deleteBranch = undefined;
export const mergeBranch = undefined;
export const createBranchAndOpenFile = undefined;
export const createDivergedBranch = undefined;
export const manageLibraries = undefined;
export const addLibrary = undefined;
export const addLibraries = undefined;
export const removeLibrary = undefined;
export const discardChanges = undefined;
export const restoreCommit = undefined;
export const restoreFile = undefined;
export const loadLibrarySuggestions = undefined;
export const updateLibraries = undefined;
export const clearActivity = undefined;
export const updateCommitWindow = undefined;

export function showMessageBox(
  browserWindow?: Object,
  options: Object
): { response: number } {
  return window.confirm(options.message + " " + options.detail)
    ? { response: 0 }
    : { response: 1 };
}

export function openUntracked(
  descriptor: Abstract.FileDescriptor | Abstract.LayerVersionDescriptor
): ThunkAction {
  return (dispatch) => {
    window.location.href = `abstract://app/share?${queryString.stringify({
      ...descriptor,
      commitSha: descriptor.sha,
      untracked: true,
      kind: "file",
      open: true,
    })}`;
  };
}

export function editFile(
  descriptor: Abstract.FileDescriptor | Abstract.LayerVersionDescriptor
): ThunkAction {
  return (dispatch) => {
    window.location.href = `abstract://app/share?${queryString.stringify({
      ...descriptor,
      commitSha: descriptor.sha,
      kind: "file",
      open: true,
    })}`;
  };
}

export function openSupportWindow(organizationId?: string): ThunkAction {
  return (dispatch) => {
    window.open(supportTicketPath(organizationId));
  };
}
