// @flow
import { getEntity } from "core/selectors/entities";
import {
  canEnterpriseSeeNextBillAmount,
  canEnterpriseCancelOrDeleteBilling,
} from "core/selectors/features";
import type { State, Subscription } from "core/types";

export function getSubscription(
  state: State,
  organizationId: string
): ?Subscription {
  return getEntity(state, "subscriptions", organizationId);
}

export function canSeeNextBill(state: State, organizationId: string) {
  const subscription = getSubscription(state, organizationId);
  return (
    (subscription && subscription.type !== "enterprise") ||
    canEnterpriseSeeNextBillAmount(state, organizationId)
  );
}

export function canOrganizationSeeCancelAndDeleteButtons(
  state: State,
  organizationId: string
): boolean {
  const subscription = getSubscription(state, organizationId);
  return subscription && subscription.type === "enterprise"
    ? canEnterpriseCancelOrDeleteBilling(state, organizationId)
    : true;
}
