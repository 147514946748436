// @flow
import { schema } from "normalizr";
import { policies } from "core/schema";

export function envelopeSchema(data: *) {
  return new schema.Object({
    data,
    policies,
  });
}
