// @flow
import { get } from "lodash";
import * as React from "react";
import Flex from "core/components/Flex";
import Icon from "core/components/Icon";
import { SubmittableInput } from "core/components/Input/withSubmittable";
import { ModalContent } from "core/components/Modal";
import { OverMaxSeatsError } from "core/components/SeatManager/errors";
import {
  availableSeats,
  availableAtRenewalSeats,
} from "core/lib/subscriptions";
import { helpManagingTeamsUrl, organizationBillingUrl } from "core/lib/urls";
import type { Project, Organization, Subscription } from "core/types";
import { Wrapper, Content, Button, Footer } from "../shared";
import style from "../shared/style.scss";

type Props = {|
  role: string,
  project?: Project,
  organization: Organization,
  placeholder: string,
  onSubmit: (emails?: string[]) => Promise<Object[]>,
  onSuccess: (alert?: string) => void,
  showInviteTypeScreen: () => void,
  subscription: ?Subscription,
  onDismiss: () => void,
  canAddContributors: boolean,
  seatCapEnforced: ?boolean,
|};

type State = {
  error: string,
  emails: string,
  disabled: boolean,
};

export default class EmailScreen extends React.Component<Props, State> {
  state: State = {
    error: "",
    emails: "",
    disabled: false,
  };

  handleEmailChange = ({ target }: SyntheticInputEvent<*>) =>
    this.setState({ emails: target.value });

  getHasEmails = () => {
    return this.state.emails !== "";
  };

  isBeyondAvailableSeats = () => {
    const { subscription, role } = this.props;

    if (!subscription) {
      return false;
    }

    const emails = this.state.emails.split(",").map((e) => e.trim());

    if (subscription && subscription.inTrial) {
      return false;
    } else {
      return (
        role !== "viewer" &&
        emails.length > availableAtRenewalSeats(subscription)
      );
    }
  };

  handleSubmit = async (event: SyntheticEvent<>) => {
    if (!this.getHasEmails()) {
      return;
    }

    this.setState({ disabled: true });

    try {
      const emails = this.state.emails.split(",").map((e) => e.trim());
      const invites = await this.props.onSubmit(emails);
      const numInvites = get(invites, "length", 1);
      this.props.onSuccess(`Invite${numInvites > 1 ? "s" : ""} sent!`);
      this.props.onDismiss();
    } catch (error) {
      const message = get(error.body, "errors.emails");
      if (message) {
        this.setState({ error: message });
      } else {
        this.setState({
          error: "Please double-check the emails and try again.",
        });
      }
      throw error;
    } finally {
      this.setState({ disabled: false });
    }
  };

  render() {
    const { project, organization, subscription } = this.props;
    const roleName = this.props.role === "viewer" ? "Viewers" : "Contributors";
    const restrictedDomains = (organization.restrictedToDomains || []).join(
      ", "
    );
    const isTrial = subscription ? subscription.inTrial : false;

    const showAtMaxForTrialOrg =
      isTrial &&
      availableSeats(subscription) === 0 &&
      this.props.seatCapEnforced &&
      this.props.role === "contributor";

    const inputLabel = (
      <Flex align="center">
        <div>
          Email addresses{" "}
          <span className={style.inputLabelHelper}>(separated by comma)</span>
        </div>
        {this.isBeyondAvailableSeats() && <Icon type="info" danger />}
      </Flex>
    );

    return (
      <ModalContent
        footer={
          <Footer>
            <Button
              onClick={this.props.showInviteTypeScreen}
              qaSelector="inviteUsersBackButton"
            >
              Back
            </Button>
            <Button
              primary
              onClick={this.handleSubmit}
              disabled={
                this.state.disabled ||
                !this.getHasEmails() ||
                this.isBeyondAvailableSeats()
              }
              qaSelector="sendInvitationEmailButton"
            >
              Send Invitation Email
            </Button>
          </Footer>
        }
      >
        <Wrapper>
          <Content>
            <SubmittableInput
              required
              label={inputLabel}
              placeholder={this.props.placeholder}
              type="textarea"
              value={this.state.emails}
              onSubmit={this.handleSubmit}
              error={this.state.error}
              onChange={this.handleEmailChange}
              className={style.textarea}
              disabled={this.state.disabled}
              qaSelector="emailAddressesTextarea"
            />
            <span>
              <p>
                Enter one or more email addresses of people you want to invite
                to the
                {project ? (
                  <span>
                    <strong> {project.name}</strong> project as Guest {roleName}
                    .
                  </span>
                ) : (
                  <span>
                    <strong> {organization.name}</strong> organization as{" "}
                    {roleName}.
                  </span>
                )}
              </p>
              {restrictedDomains && (
                <p>
                  Invites can only be sent to emails at these domains:
                  <strong> {restrictedDomains} </strong>(
                  <a
                    href={helpManagingTeamsUrl()}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn why
                  </a>
                  )
                </p>
              )}
              {this.isBeyondAvailableSeats() && (
                <React.Fragment>
                  <p className={style.seatsAlertText}>
                    You do not have enough contributor seats available on your
                    account for the number of contributors above
                  </p>
                  <span>
                    <a
                      href={organizationBillingUrl(organization.id)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={style.seatsAlertLink}
                    >
                      Manage Account Settings
                    </a>
                  </span>
                </React.Fragment>
              )}
            </span>
            {showAtMaxForTrialOrg && subscription && (
              <OverMaxSeatsError
                seatCapEnforced={true}
                subscriptionTitle={subscription.type}
              />
            )}
          </Content>
        </Wrapper>
      </ModalContent>
    );
  }
}
