// @flow
import * as React from "react";
import { connect } from "react-redux";
import { fetchComments } from "core/actions/comments";
import CollectionMultiSelect from "core/components/CollectionMultiSelect";
import Media from "core/components/Media";
import { Abstract } from "core/lib/abstract";
import { commitPath } from "core/lib/routes";
import { CommitsFetchRequest } from "core/requests/commits";
import { getBranch, getParentBranch } from "core/selectors/branches";
import { getCommentCounts } from "core/selectors/comments";
import {
  getCommitsForEntity,
  getCommitsForEntityHasMore,
} from "core/selectors/commits";
import type { Commit, Branch, Project, Dispatch, State } from "core/types";
import BranchCommitsDesktop from "web/components/BranchCommits/BranchCommitsDesktop";
import BranchCommitsMobile from "web/components/BranchCommits/BranchCommitsMobile";
import { isOnline } from "web/di/selectors";
import { getProject } from "web/selectors/projects";

type OwnProps = {|
  active: boolean,
  children: React.Node,
  onClose: () => void,
  params: Abstract.BranchDescriptor | Abstract.BranchCommitDescriptor,
|};

type StateProps = {|
  key: string,
  isLoading: boolean,
  commits: Commit[],
  commitCommentCounts: { [sha: string]: number },
  branch: ?Branch,
  parentBranch: ?Branch,
  project: ?Project,
  hasMore: boolean,
  isOffline: boolean,
|};

type LoadCommitsOptions = {
  startSha: string,
};

type DispatchProps = {|
  onMount: (params?: { head?: string }) => void,
  onLoadCommits: (LoadCommitsOptions) => void,
|};

type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

class CommitsContainer extends React.Component<Props> {
  componentDidMount() {
    this.props.onMount({
      head: this.props.branch ? this.props.branch.head : undefined,
    });
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.branch &&
      this.props.branch &&
      prevProps.branch.head !== this.props.branch.head
    ) {
      this.props.onMount({
        head: this.props.branch.head,
      });
    }
  }

  commitPath = (commitSha: string) => {
    return commitPath(
      this.props.params.projectId,
      this.props.params.branchId,
      commitSha
    );
  };

  handleLoadMore = () => {
    const lastCommit = this.props.commits[this.props.commits.length - 1];
    if (lastCommit) {
      this.props.onLoadCommits({
        startSha: lastCommit.sha,
      });
    }
  };

  render() {
    const sharedProps = {
      isLoading: this.props.isLoading,
      params: this.props.params,
      commits: this.props.commits,
      commitCommentCounts: this.props.commitCommentCounts,
      commitPath: this.commitPath,
      project: this.props.project,
      branch: this.props.branch,
      parentBranch: this.props.parentBranch,
      onLoadMore: this.props.hasMore ? this.handleLoadMore : undefined,
      isOffline: this.props.isOffline,
    };

    return (
      <Media desktop>
        {(matches) => {
          if (matches) {
            return (
              <CollectionMultiSelect
                showHeader
                redirectToCollectionAfterSubmit
                projectId={this.props.params.projectId}
                branchId={this.props.params.branchId}
                commitSha={this.props.params.sha && this.props.params.sha}
                noPrompt
              >
                {() => (
                  <BranchCommitsDesktop {...sharedProps}>
                    {this.props.children}
                  </BranchCommitsDesktop>
                )}
              </CollectionMultiSelect>
            );
          }

          return (
            <BranchCommitsMobile {...sharedProps}>
              {this.props.children}
            </BranchCommitsMobile>
          );
        }}
      </Media>
    );
  }
}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { projectId, branchId } = props.params;

  const branch = getBranch(state, { projectId, branchId });
  const parentBranch = getParentBranch(state, { projectId, branchId });
  const commits = getCommitsForEntity(state, {
    projectId,
    branchId,
  });
  const hasMore = getCommitsForEntityHasMore(state, {
    projectId,
    branchId,
  });

  return {
    key: `${projectId}-${branchId}-commits`,
    commits,
    commitCommentCounts: getCommentCounts(state, {
      projectId,
      branchId,
      key: "commitSha",
    }),
    branch,
    parentBranch,
    project: getProject(state, projectId),
    isLoading: CommitsFetchRequest.isLoading(state, {
      projectId,
      branchId,
      head: branch ? branch.head : undefined,
    }),
    hasMore,
    isOffline: !isOnline(state),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { projectId, branchId } = props.params;
  return {
    onLoadCommits(options: LoadCommitsOptions = {}) {
      dispatch(
        CommitsFetchRequest.perform({
          params: {
            projectId,
            branchId,
            startSha: options.startSha,
          },
        })
      );
    },
    onMount(options?: { head?: string } = {}) {
      dispatch(
        CommitsFetchRequest.perform({
          params: {
            projectId,
            branchId,
            head: options.head,
          },
        })
      );
      dispatch(fetchComments({ projectId, branchId }));
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(
  mapStateToProps,
  mapDispatchToProps
)(CommitsContainer);
