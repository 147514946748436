// @flow
import * as React from "react";
import Flex from "core/components/Flex";
import FormHeader from "core/components/FormHeader";
import Icon from "core/components/Icon";
import Loaded from "core/components/Loaded";
import Media from "core/components/Media";
import Tab from "core/components/Tab";
import Tabs from "core/components/Tabs";
import { V3Link as Link } from "core/lib/router";
import type { NewWebhook } from "core/types";
import type { Props } from "web/containers/WebhookOverview";
import {
  organizationIntegrations,
  webhookDeliveriesPath,
  webhookEventsPath,
  webhookPath,
} from "web/routeHelpers";
import WebhookDialog from "./WebhookDialog";
import style from "./style.scss";

type State = {
  error?: string,
  success?: string,
  webhookFormData?: $Shape<NewWebhook>,
  webhookFormOpen: boolean,
};

export default class WebhookOverview extends React.Component<Props, State> {
  state = {
    error: undefined,
    success: undefined,
    webhookFormData: undefined,
    webhookFormOpen: false,
  };

  getContent() {
    const { location } = this.props;

    if (/\/details$/.test(location.pathname)) {
      return {
        buttonText: "UPDATE DETAILS",
        editText: "EDIT DETAILS",
        page: 0,
      };
    }
    if (/\/events$/.test(location.pathname)) {
      return {
        buttonText: "UPDATE EVENTS",
        editText: "EDIT EVENTS",
        page: 1,
      };
    }
    return {};
  }

  componentDidMount() {
    const {
      fetchWebhook,
      fetchWebhookDeliveries,
      fetchWebhookEvents,
      organizationId,
      webhookId,
    } = this.props;
    fetchWebhook({ organizationId, webhookId });
    fetchWebhookEvents({ organizationId });
    fetchWebhookDeliveries({ organizationId, webhookId });
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.updateWebhookError && this.props.updateWebhookError) {
      this.setState({ error: "Webhook could not be updated." });
    }
    if (
      prevProps.updateWebhookLoading &&
      !this.props.updateWebhookLoading &&
      !this.props.updateWebhookError
    ) {
      this.setState({ success: "Webhook successfully updated." });
    }

    if (!prevProps.testWebhookError && this.props.testWebhookError) {
      this.setState({ error: "Webhook test event could not be triggered." });
    }
    if (
      prevProps.testWebhookLoading &&
      !this.props.testWebhookLoading &&
      !this.props.testWebhookError
    ) {
      this.setState({ success: "Webhook test event successfully triggered." });
    }

    if (!prevProps.deleteWebhookError && this.props.deleteWebhookError) {
      this.setState({ error: "Webhook could not be deleted." });
    }
  }

  onBannerHide = () => {
    this.setState({
      error: undefined,
      success: undefined,
    });
  };

  onWebhookFormChange = (webhookFormData: $Shape<NewWebhook>) => {
    this.setState({ webhookFormData });
  };

  onWebhookFormOpen = () => {
    this.setState({ webhookFormOpen: true });
  };

  onWebhookFormClose = () => {
    this.setState({
      webhookFormData: undefined,
      webhookFormOpen: false,
    });
  };

  onWebhookFormSubmit = async (webhookFormData: $Shape<NewWebhook>) => {
    const { updateWebhook, webhook } = this.props;
    await updateWebhook({
      ...webhook,
      ...webhookFormData,
    });
    this.setState({
      webhookFormData: undefined,
      webhookFormOpen: false,
    });
  };

  render() {
    const {
      children,
      fetchWebhookLoading,
      organizationId,
      webhook,
      webhookEvents,
      webhookId,
    } = this.props;

    const { error, success, webhookFormData, webhookFormOpen } = this.state;

    return (
      <Media desktop>
        {(desktop) => (
          <Loaded loading={fetchWebhookLoading} title="Loading webhook…">
            {webhook && (
              <Flex column className={style.root}>
                <FormHeader
                  content={
                    <React.Fragment>
                      <div className={style.menu}>
                        <Tabs>
                          <Tab
                            className={style.tab}
                            label="Details"
                            to={webhookPath(organizationId, webhookId)}
                          />
                          <Tab
                            className={style.tab}
                            label="Events"
                            to={webhookEventsPath(organizationId, webhookId)}
                          />
                          <Tab
                            className={style.tab}
                            label="Recent Deliveries"
                            to={webhookDeliveriesPath(
                              organizationId,
                              webhookId
                            )}
                          />
                        </Tabs>
                      </div>
                    </React.Fragment>
                  }
                  error={error}
                  flashBanner
                  heading={
                    <Link
                      className={style.headerLink}
                      to={organizationIntegrations(organizationId)}
                    >
                      Integrations
                    </Link>
                  }
                  onBannerHide={this.onBannerHide}
                  onClick={this.onWebhookFormOpen}
                  mobile={!desktop}
                  subheading={
                    <span className={style.url}>
                      <Icon type="webhooks" className={style.icon} />{" "}
                      {webhook.url}
                    </span>
                  }
                  success={!!success}
                  successMessage={success}
                  submitText={this.getContent().editText}
                />
                <Flex column className={style.content}>
                  {children}
                </Flex>
                <WebhookDialog
                  data={JSON.parse(JSON.stringify(webhookFormData || webhook))}
                  isOpen={webhookFormOpen}
                  isSinglePage={true}
                  onChangeData={this.onWebhookFormChange}
                  onClose={this.onWebhookFormClose}
                  onSubmit={this.onWebhookFormSubmit}
                  page={this.getContent().page}
                  submitText={this.getContent().buttonText}
                  webhookEvents={webhookEvents}
                />
              </Flex>
            )}
          </Loaded>
        )}
      </Media>
    );
  }
}
