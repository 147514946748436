// @flow
import empty from "empty";
import { connect } from "react-redux";
import { presentationIdleState } from "core/actions/navigation";
import { goToTargetLayer } from "core/actions/prototypes";
import { getCommentFormId } from "core/components/CommentCreateForm";
import { withData } from "core/components/DataLoader";
import connectStorage from "core/hocs/connectStorage";
import createConnector from "core/lib/createConnector";
import { replace, addAndRemoveQueryParams, addQuery } from "core/lib/location";
import { layerLocation } from "core/lib/routes";
import { CommentsFetchRequest } from "core/requests/comments";
import { LayerDatasetFetchRequest } from "core/requests/layerDatasets";
import { TargetLayerRequest } from "core/requests/prototypes";
import {
  getAnnotationsForLayer,
  getNonResolvedCommentAnnotationsForLayer,
} from "core/selectors/comments";
import { getLayersOrdered } from "core/selectors/layerDatasets";
import { getLayerSet } from "core/selectors/layers";
import { getPresentationIdleState } from "core/selectors/navigation";
import {
  getPrototypeData,
  getHotspotLayers,
  hasHotspots,
} from "core/selectors/prototypes";
import type { Dispatch, State, LayerLink, LayerSetItem } from "core/types";
import type {
  DispatchProps,
  OwnProps,
  Props,
  StateProps,
  StorageProps,
  StorageOwnProps,
} from "./";

function mapStorageToProps(storage, props: OwnProps): StorageProps {
  const previous = storage.getItem("LayerPresentation") || empty.object;
  const commentFormId = getCommentFormId(props);
  const defaultForm =
    props.isLoggedIn === false ? {} : previous[commentFormId] || {};

  return {
    commentFormId,
    defaultForm,
    defaultIsDarkMode: previous.isDarkMode,
    onFormChange: (form) => {
      storage.setItem("LayerPresentation", {
        ...previous,
        [commentFormId]: form,
      });
    },
    onToggleDarkMode: (isDarkMode) => {
      storage.setItem("LayerPresentation", { ...previous, isDarkMode });
    },
  };
}

function mapStateToProps(state: State, props: StorageOwnProps): StateProps {
  const { projectId, branchId, fileId, layerId } = props.params;
  const commentsRequest = CommentsFetchRequest.getRequest(state, {
    projectId,
    branchId,
    fileId,
    layerId,
  });
  const layersOrdered = props.showPrototypes
    ? getLayersOrdered(state, props.params)
    : [];
  const layerHasHotspots = hasHotspots(state, props.params);
  const loadingHotspots =
    props.showPrototypes && layersOrdered.length === 0 && layerHasHotspots;

  return {
    annotations: props.isShowingResolvedComments
      ? getAnnotationsForLayer(state, props.params)
      : getNonResolvedCommentAnnotationsForLayer(state, props.params),
    totalAnnotations: getAnnotationsForLayer(state, props.params).length,
    commentsState: commentsRequest.state,
    defaultIdle: getPresentationIdleState(state),
    layers: getHotspotLayers(state, props.params),
    layerSet:
      props.shareLink && props.shareLink.kind === "collection"
        ? getLayerSet(state, {
            type: "collection",
            projectId: props.params.projectId,
            collectionId: props.shareLink.descriptor.collectionId,
          })
        : getLayerSet(state, props.layerSetParams),
    loadingHotspots,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: StorageOwnProps
): DispatchProps {
  const handleNext = (layer: ?LayerSetItem) => {
    if (!layer) {
      return;
    }
    if (props.shareLink) {
      return replace(
        addQuery({ collectionLayerId: layer.nonce || "", sha: layer.commitSha })
      );
    }

    if (props.layerSetParams && props.layerSetParams.type === "collection") {
      replace(
        layerLocation(
          props.params.projectId,
          props.params.branchId,
          layer.commitSha,
          layer.fileId,
          layer.layerId,
          {
            ...props.location.state,
            ...props.location.query,
            collectionLayerId: layer.nonce,
            sha: layer.commitSha,
          }
        )
      );
    } else {
      replace(
        layerLocation(
          props.params.projectId,
          props.params.branchId,
          props.params.sha,
          props.params.fileId,
          layer.layerId,
          {
            ...props.location.state,
            ...props.location.query,
          }
        )
      );
    }
  };

  return {
    onClickHotspot: (link: LayerLink) => {
      dispatch(async (dispatch, getState) => {
        dispatch(
          goToTargetLayer(props.params, link, { ...props.location.query })
        );
      });
    },
    onClickLayerDetail: () =>
      replace(
        addAndRemoveQueryParams({ mode: "build" }, ["present", "preview"])
      ),
    onClickNext: handleNext,
    onClickPrevious: handleNext,
    onIdleChange: (idle) => dispatch(presentationIdleState(idle)),
    onLoad: async () => {
      if (!props.params.sha) {
        // Auto-updating collection layers may be missing a sha initially.
        // We wait until the sha is loaded to make the following requests.
        return;
      }

      if (props.showPrototypes) {
        await dispatch(async (dispatch, getState) => {
          // load layer data and preload target layers
          await dispatch(
            LayerDatasetFetchRequest.perform({
              params: props.params,
            })
          );

          const prototypeData = getPrototypeData(getState(), props.params);

          await Promise.all(
            prototypeData.map((link) =>
              dispatch(
                TargetLayerRequest.perform({
                  params: {
                    descriptor: props.params,
                    link,
                    options: { ...props.location.query },
                  },
                  force: false,
                })
              )
            )
          );
        });
      }
    },
  };
}

export default createConnector<Props, OwnProps>(
  (WrappedComponent) => connectStorage(WrappedComponent, mapStorageToProps),
  connect<Props, StorageOwnProps, StateProps, DispatchProps, State, Dispatch>(
    mapStateToProps,
    mapDispatchToProps
  ),
  withData((props) => ({
    ...props.params,
    showPrototypes: props.showPrototypes,
  }))
);
