// @flow
import * as React from "react";
import { connect } from "react-redux";
import { openSupportWindow } from "abstract-di/actions";
import type { State, Dispatch } from "core/types";
import Button from "../Button";
import image from "./images/error.svg";
import style from "./style.scss";
import Empty from ".";

type OwnProps = {
  onReload?: () => void,
  title?: string,
  description?: string,
};

type DispatchProps = {|
  openSupportTicket: () => void,
|};

type Props = {
  ...OwnProps,
  ...DispatchProps,
};

function EmptyError({
  onReload,
  title = "Something went wrong",
  description = "Please try reloading Abstract, or contact support if the issue persists.",
  openSupportTicket,
  ...rest
}: Props) {
  return (
    <Empty {...rest} image={image} title={title} description={description}>
      <span>
        <Button onClick={openSupportTicket} className={style.button}>
          Contact support
        </Button>
        {onReload && <Button onClick={onReload}>Reload</Button>}
      </span>
    </Empty>
  );
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    openSupportTicket: () => dispatch(openSupportWindow()),
  };
}

export default connect<Props, OwnProps, _, DispatchProps, State, Dispatch>(
  undefined,
  mapDispatchToProps
)(EmptyError);
