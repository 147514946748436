// @flow
import { connect } from "react-redux";
import {
  getCommitForLayer,
  getCommitsForLayer,
  getPreviousLayerCommit,
} from "core/selectors/commits";
import { canUseBackgroundPreviewSwitcher } from "core/selectors/features";
import { getLayerState, getLayer } from "core/selectors/layers";
import type { State, Dispatch } from "core/types";
import type { Props, OwnProps, StateProps } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const commits = getCommitsForLayer(state, props.params);
  const commit = getCommitForLayer(state, props.params);
  const previousCommit = getPreviousLayerCommit(state, props.params);
  const latestCommit = commits && commits.length > 1 ? commits[0] : undefined;
  const layer = getLayer(state, props.params);
  const layerState = getLayerState(state, props.params);
  const previousLayerParams = {
    ...props.params,
    sha: previousCommit ? previousCommit.sha : "",
  };

  const compareLayer = previousCommit
    ? getLayer(state, previousLayerParams)
    : null;
  let compareLayerState;
  if (layerState === "loading") {
    compareLayerState = "loading";
  } else {
    compareLayerState = previousCommit
      ? getLayerState(state, previousLayerParams)
      : "not_found";
  }

  return {
    layer,
    layerState,
    compareLayer,
    compareLayerState,
    commitIsLatest:
      commit && latestCommit ? commit.sha === latestCommit.sha : undefined,
    canUsePreviewBackgroundSwitcher: canUseBackgroundPreviewSwitcher(
      state,
      props.params.projectId
    ),
  };
}

export default connect<Props, OwnProps, StateProps, _, State, Dispatch>(
  mapStateToProps
);
