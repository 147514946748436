// @flow
import { connect } from "react-redux";
import { LatestReleaseFetchRequest } from "core/requests/releases";
import { getEntity } from "core/selectors/entities";
import type { State, Dispatch } from "core/types";
import { getCurrentUser } from "web/selectors/session";
import type { DispatchProps, OwnProps, Props, StateProps } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  return {
    currentUser: getCurrentUser(state),
    subscription: props.organization
      ? getEntity(state, "subscriptions", props.organization.id)
      : null,
    isLoading: LatestReleaseFetchRequest.isLoading(state, props),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    fetchLatestRelease: (onSuccess, onError) =>
      dispatch(
        LatestReleaseFetchRequest.perform({
          params: {},
          onSuccess,
          onError,
        })
      ),
  };
}

export default connect<Props, OwnProps, StateProps, _, State, Dispatch>(
  mapStateToProps,
  mapDispatchToProps
);
