// @flow
import classnames from "classnames";
import * as React from "react";
import Badge from "core/components/Badge";
import CommentsIcon from "core/components/CommentsIcon";
import CommitTitle from "core/components/CommitTitle";
import CommitTrack from "core/components/CommitTrack";
import Icon from "core/components/Icon";
import PlaceholderText from "core/components/PlaceholderText";
import Popover from "core/components/Popover";
import Time from "core/components/Time";
import { V3Link as Link } from "core/lib/router";
import type { CommitType, LocationDescriptor } from "core/types";
import style from "./style.scss";

export function CommitPlaceholder(props: $Shape<Props>) {
  return (
    <div className={classnames(style.placeholderWrapper, props.className)}>
      <div className={style.inner}>
        <CommitTrack
          type="NORMAL"
          first={props.first}
          last={props.last}
          className={style.commitTrack}
        />
        <div className={style.content}>
          <PlaceholderText max={90} />
          <div>
            <PlaceholderText size="s" min={50} max={60} />
            &nbsp;&nbsp;
            <PlaceholderText size="s" min={35} max={35} />
          </div>
        </div>
      </div>
    </div>
  );
}

type Props = {
  authorName: string,
  className?: string,
  commentCount?: number,
  commitPath: (sha: string) => LocationDescriptor,
  current?: boolean,
  first?: boolean,
  firstOfGroup?: boolean,
  icon?: string,
  last?: boolean,
  lastOfGroup?: boolean,
  pending?: boolean,
  pushing?: boolean,
  reference?: boolean,
  selected?: boolean,
  innerRef?: React.Ref<"div">,
  selectedClassName?: string,
  disclosureClassName?: string,
  isFocused?: boolean,
  sha: string,
  time: string,
  title: string,
  type: CommitType,
};

export default function Commit(props: Props) {
  const {
    authorName,
    className,
    commentCount,
    commitPath,
    current,
    first,
    firstOfGroup,
    icon,
    last,
    lastOfGroup,
    selectedClassName = "",
    isFocused,
    pending,
    pushing,
    reference,
    selected,
    sha,
    time,
    title,
    type,
    innerRef,
    disclosureClassName,
    ...rest
  } = props;

  const wrapperClassName = classnames(style.wrapper, className, {
    [style.selected]: selected,
    [style.focused]: isFocused,
    [selectedClassName]: selected,
  });
  const isLight = selected && isFocused;

  return (
    <Link {...rest} className={wrapperClassName} to={commitPath(sha)}>
      <div className={style.inner} ref={innerRef}>
        <CommitTrack
          type={type}
          current={current}
          first={first}
          firstOfGroup={firstOfGroup}
          icon={icon}
          last={last}
          lastOfGroup={lastOfGroup}
          pushing={pushing}
          selected={isLight}
          className={style.commitTrack}
        />
        <div className={style.content}>
          <div className={style.heading} title={title}>
            <CommitTitle type={type} title={title} />
          </div>
          <div className={style.description}>
            {authorName} · <Time date={time} />
          </div>
        </div>
        {pending && (
          <Popover
            placement="bottom"
            label="This commit has not been uploaded yet"
          >
            <Badge label="Pending" light={isLight} />
          </Popover>
        )}
        <CommentsIcon
          count={commentCount}
          className={style.iconComments}
          selected={isLight}
        />
        {reference && <Icon className={style.iconReference} type="go-to" />}
      </div>
    </Link>
  );
}

Commit.defaultProps = {
  className: "",
  commentCount: 0,
  current: false,
  first: false,
  firstOfGroup: false,
  icon: "",
  last: false,
  lastOfGroup: false,
  pending: false,
  pushing: false,
  reference: false,
  selected: false,
};
