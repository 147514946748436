// @flow
import invariant from "invariant";
import { connect } from "react-redux";
import { mergeBranch } from "abstract-di/actions";
import { dismissDialog, showDialog } from "core/actions/dialogs";
import { displayName } from "core/models/branch";
import { getBranch } from "core/selectors/branches";
import { canUseNewDefaultBranchName } from "core/selectors/features";
import type { Dispatch, State } from "core/types";
import type { OwnProps, StateProps, DispatchProps, Props } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const {
    projectId,
    sourceBranchId,
    destinationBranchId,
    destinationBranchSha,
  } = props;

  const sourceBranch = getBranch(state, {
    branchId: sourceBranchId,
    projectId,
  });

  const destinationBranch = getBranch(state, {
    branchId: destinationBranchId,
    projectId,
  });

  const destinationBranchName = displayName(destinationBranch, {
    masterToMain: canUseNewDefaultBranchName(state),
    titleCase: true,
  });

  const destinationBranchHead = destinationBranch
    ? destinationBranch.head
    : undefined;

  return {
    sourceBranch,
    destinationBranch,
    destinationBranchName,
    headHasChanged: destinationBranchSha !== destinationBranchHead,
  };
}
function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    onDismiss: () => dispatch(dismissDialog()),
    onSubmit: ({ notes }) => {
      invariant(mergeBranch, "mergeBranch required for dialog action");
      dispatch(mergeBranch(props.projectId, props.sourceBranchId, notes));
      dispatch(dismissDialog());
    },
    onRequestUpdate: () =>
      dispatch((dispatch, getState) => {
        const { projectId, sourceBranchId, destinationBranchId } = props;
        const state = getState();

        const sourceBranch = getBranch(state, {
          branchId: sourceBranchId,
          projectId,
        });
        if (!sourceBranch) {
          throw new Error(
            `Branch ${sourceBranchId} in project ${projectId} expected.`
          );
        }

        const destinationBranch = getBranch(state, {
          branchId: destinationBranchId,
          projectId,
        });
        if (!destinationBranch) {
          throw new Error(
            `Branch ${destinationBranchId} in project ${projectId} expected.`
          );
        }

        dispatch(
          showDialog("UpdateBranch", {
            projectId,
            sourceBranchId: destinationBranch.id,
            sourceBranchSha: destinationBranch.head,
            destinationBranchId: sourceBranch.id,
            destinationBranchSha: sourceBranch.head,
          })
        );
      }),
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
