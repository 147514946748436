// @flow
import { getCurrentUserId } from "abstract-di/selectors";
import { trackEvent } from "core/actions/analytics";
import {
  entitiesReceived,
  entitiesDeleted,
  entitiesReplaced,
} from "core/actions/entities";
import { showToast } from "core/actions/toasts";
import * as ProjectMembership from "core/models/projectMembership";
import {
  DeleteProjectMembershipRequest,
  PaginatedProjectMembershipsRequest,
} from "core/requests/projectMemberships";
import {
  normalizeProjectMembership,
  normalizeProjectMemberships,
} from "core/schemas/projectMembership";
import {
  getProjectMemberships,
  getProjectMembershipsForProject,
} from "core/selectors/projectMemberships";
import { getProject } from "core/selectors/projects";
import { getTeamProjectMembershipsForTeam } from "core/selectors/teamProjectMemberships";
import { getUser } from "core/selectors/users";
import type {
  ProjectMembership as TProjectMembership,
  ThunkAction,
  Action,
} from "core/types";

export function removeProjectMember(
  projectId: string,
  userId: string
): ThunkAction {
  return async (dispatch, getState) => {
    const project = getProject(getState(), { projectId });
    const user = getUser(getState(), { userId });
    if (!project || !user) {
      return;
    }

    dispatch(
      DeleteProjectMembershipRequest.perform({
        params: { projectId, userId },
        onSuccess: () => {
          dispatch(
            showToast({
              text: `${user.name} has been removed from the ${project.name} project`,
            })
          );
          dispatch(
            trackEvent("PROJECT_MEMBER_REMOVED", {
              userId,
              projectId,
              projectMembershipId: ProjectMembership.uniqueId({
                projectId,
                userId,
              }),
            })
          );
        },
        onError: () => {
          dispatch(
            showToast({
              text: `Unable to remove ${user.name} from the ${project.name} project`,
            })
          );
        },
      })
    );
  };
}

export function leaveProject(
  projectId: string,
  noToast?: boolean = false
): ThunkAction {
  return async (dispatch, getState) => {
    const project = getProject(getState(), { projectId });
    const userId = getCurrentUserId(getState());
    if (!project || !userId) {
      return;
    }

    dispatch(
      DeleteProjectMembershipRequest.perform({
        params: { projectId, userId },
        onSuccess: () => {
          dispatch(
            showToast({ text: `You have left the ${project.name} project` })
          );

          dispatch(entitiesDeleted({ projects: [projectId] }));
        },
        onError: () => {
          if (!noToast) {
            dispatch(
              showToast({ text: `Unable to leave the ${project.name} project` })
            );
          }
        },
      })
    );
  };
}

export function projectMembershipUpdated(
  projectMembership: TProjectMembership
): Action {
  const { entities } = normalizeProjectMembership(projectMembership);
  return entitiesReceived(entities);
}

export function projectMembershipDeleted(
  projectId: string,
  userId: string
): Action {
  return entitiesDeleted({
    projectMemberships: [ProjectMembership.uniqueId({ projectId, userId })],
  });
}

export function clearProjectMemberships(projectId: string): ThunkAction {
  return (dispatch, getState) => {
    const projectMemberships = getProjectMemberships(getState(), { projectId });
    const projectMembershipsIds = projectMemberships.map(
      ProjectMembership.uniqueId
    );

    if (projectMembershipsIds.length) {
      dispatch(entitiesDeleted({ projectMemberships: projectMembershipsIds }));
    }
  };
}

export function refreshProjectMembershipsOnTeamChange(
  teamId: string
): ThunkAction {
  return (dispatch, getState) => {
    getTeamProjectMembershipsForTeam(getState(), {
      teamId,
    })
      .map((membership) => membership.projectId)
      .forEach((projectId) => {
        dispatch(refreshProjectMemberships(projectId));
      });
  };
}

export function refreshProjectMemberships(projectId: string): ThunkAction {
  return (dispatch, getState) => {
    const existingProjectMemberships = getProjectMembershipsForProject(
      getState(),
      { projectId }
    );
    if (existingProjectMemberships && existingProjectMemberships.length > 0) {
      dispatch(
        PaginatedProjectMembershipsRequest.perform({
          params: { projectId, limit: 30, offset: 0 },
          force: true,
          onSuccess: (response) => {
            const { entities } = normalizeProjectMemberships(response.data);
            dispatch(
              entitiesReplaced({
                type: "projectMemberships",
                ids: existingProjectMemberships.map((membership): string =>
                  ProjectMembership.uniqueId(membership)
                ),
                entities: entities.projectMemberships,
              })
            );

            // We reset all the Pagination states related to this Project's Memberships
            // after the entities themselves are replaced
            dispatch({
              type: "core/PAGINATION_ITEMS_RESET",
              meta: { id: projectId },
            });
          },
        })
      );
    }
  };
}
