// @flow
import * as React from "react";
import { helpBranchesUrl } from "core/lib/urls";
import Button from "../Button";
import image from "./images/file.svg";
import Empty from ".";

type Props = {
  canAddFiles?: boolean,
  onImportFile?: () => void,
  onCreateFile?: () => void,
};

export default function NoBranchFiles({
  canAddFiles,
  onImportFile,
  onCreateFile,
  ...rest
}: Props) {
  if (canAddFiles) {
    return (
      <Empty
        {...rest}
        image={image}
        title="Import Sketch files"
        description="Import or create Sketch files to start working."
      >
        <span>
          {onImportFile && (
            <Button onClick={onImportFile} primary>
              Import Sketch File
            </Button>
          )}
          {onImportFile && onCreateFile && "   "}
          {onCreateFile && (
            <Button onClick={onCreateFile}>Create Sketch File</Button>
          )}
        </span>
      </Empty>
    );
  }

  return (
    <Empty
      {...rest}
      image={image}
      title="Branch has no files"
      description="Only the branch owner can import or create files."
    >
      <a href={helpBranchesUrl()} target="_blank" rel="noopener noreferrer">
        Learn more about branches in the Help Center.
      </a>
    </Empty>
  );
}
