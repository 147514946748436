// @flow
import classnames from "classnames";
import * as React from "react";
import Flex from "core/components/Flex";
import Icon from "core/components/Icon";
import Loaded from "core/components/Loaded";
import Formatter from "core/components/PropertyValue/Formatter";
import SidebarAccordion from "core/components/SidebarAccordion";
import { Abstract } from "core/lib/abstract";
import type {
  ReactRouterLocation,
  LayerLink,
  LayerAnimation,
  BranchCollectionDescriptor,
} from "core/types";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  params: Abstract.LayerVersionDescriptor | BranchCollectionDescriptor,
  link: LayerLink,
  formatter: Formatter,
|};

export type LocationOwnProps = {|
  ...OwnProps,
  location: ReactRouterLocation,
|};

export type StateProps = {|
  targetLayerName?: ?string,
  isLoading: boolean,
  disabled: boolean,
  error?: boolean,
|};

export type DispatchProps = {|
  onLoad: () => void,
  onClickTarget: () => void,
|};

export type Props = {
  ...OwnProps,
  ...LocationOwnProps,
  ...StateProps,
  ...DispatchProps,
};

const LAYER_ANIMATION_LABELS = [
  "Animate artboard from right",
  "Animate artboard from left",
  "Animate artboard from bottom",
  "Animate artboard from top",
];

export class PrototypeSection extends React.Component<Props> {
  layerAnimation = {
    label: (animation: LayerAnimation) =>
      LAYER_ANIMATION_LABELS[animation] || "No artboard animation",
  };

  handleClickTarget = () => {
    if (!this.props.disabled && !this.props.error) {
      this.props.onClickTarget();
    }
  };

  render() {
    const previous = this.props.link.previous || null;
    const animation =
      this.props.link.animation != null ? this.props.link.animation : null;
    const targetLayerLabel = previous
      ? "Previous Artboard"
      : this.props.error
      ? "Target Not Found"
      : this.props.targetLayerName;

    return (
      <React.Fragment>
        <SidebarAccordion label="Prototyping">
          <React.Fragment>
            <Loaded
              loading={this.props.isLoading}
              title="Loading prototype target…"
            >
              <div className={style.propertyRow}>
                <div className={style.label}>Target</div>
                <Flex
                  className={classnames(style.targetLayer, {
                    [style.disabled]: this.props.disabled || this.props.error,
                  })}
                  onClick={this.handleClickTarget}
                  align="center"
                >
                  <div className={style.value}>{targetLayerLabel}</div>
                  <Flex className={style.targetLayerIcon} justify="flex-end">
                    <Icon type="arrow-large-right" nude tint />
                  </Flex>
                </Flex>
              </div>
              {animation != null && (
                <div className={style.propertyRow}>
                  <div>
                    <div className={style.label}>Animation</div>
                    <div className={style.value}>
                      {this.layerAnimation.label(animation)}
                    </div>
                  </div>
                </div>
              )}
              {this.props.link.fixPositionWhenScrolling && (
                <div className={style.propertyRow}>
                  <div>
                    <div className={style.label}>Scrolling</div>
                    <div className={style.value}>
                      Fix position when scrolling
                    </div>
                  </div>
                </div>
              )}
            </Loaded>
          </React.Fragment>
        </SidebarAccordion>
      </React.Fragment>
    );
  }
}

export default connector(PrototypeSection);
