// @flow
import invariant from "invariant";
import { connect } from "react-redux";
import {
  generateAssetsForFile,
  editFile,
  openUntracked,
  renameFile,
  replaceFile,
  deleteFile,
  exportFile,
  openFileInTerminal,
  openFileInFinder,
  clearCacheAndEditingFile,
  manageLibraries,
} from "abstract-di/actions";
import {
  getCurrentUserId,
  sketchLibrariesSupported,
  isDevelopmentMenuEnabled,
  isOnline,
  fileIsOpening,
} from "abstract-di/selectors";
import { showDialog } from "core/actions/dialogs";
import contextMenuWrapper, {
  type Props as ContextMenuWrapperProps,
} from "core/components/ContextMenu/ContextMenuWrapper";
import createConnector from "core/lib/createConnector";
import { userIsAuthor, isMaster } from "core/models/branch";
import { fileIsWriteable, supportsAssets } from "core/models/file";
import { getBranch, getBranchHead } from "core/selectors/branches";
import { getLibraryPagesFromFile } from "core/selectors/libraries";
import {
  getOrganizationPolicy,
  getProjectPolicy,
} from "core/selectors/policies";
import { getProject } from "core/selectors/projects";
import type { State, Dispatch } from "core/types";

import type { OwnProps, StateProps, DispatchProps, Props } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { projectId, branchId, file } = props;
  const project = getProject(state, { projectId });
  const branch = getBranch(state, { projectId, branchId });
  const currentUserId = getCurrentUserId(state);
  const userIsBranchAuthor = currentUserId
    ? userIsAuthor(branch, currentUserId)
    : false;
  return {
    project,
    branch,
    policy: getProjectPolicy(state, { projectId }),
    organizationPolicy: project
      ? getOrganizationPolicy(state, { organizationId: project.organizationId })
      : {},
    libraries: getLibraryPagesFromFile(state, {
      projectId,
      sha: file.sha,
      fileId: file.id,
    }),
    userIsBranchAuthor,
    head: getBranchHead(state, { projectId, branchId }),
    isEditable: !!branch && fileIsWriteable(file, branch),
    isOpening: fileIsOpening(state, {
      projectId,
      sha: file.sha,
      fileId: file.id,
    }),
    isDevelopment: isDevelopmentMenuEnabled(state),
    isOnline: isOnline(state),
    shouldShowLibraryActions:
      !!branch &&
      sketchLibrariesSupported(state) &&
      (userIsBranchAuthor || isMaster(branch)),
    canGenerateAssets:
      !!branch &&
      supportsAssets(file) &&
      (userIsBranchAuthor || isMaster(branch)),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { projectId, branchId, file } = props;
  return {
    generateAssetsForFile: () => {
      invariant(
        generateAssetsForFile,
        "generateAssetsForFile required for File Menu"
      );
      dispatch(generateAssetsForFile(projectId, branchId, file.sha, file.id));
    },
    editFile: () => {
      dispatch(
        editFile({ projectId, branchId, fileId: file.id, sha: file.sha })
      );
    },
    openUntracked: () => {
      dispatch(
        openUntracked({ projectId, branchId, fileId: file.id, sha: file.sha })
      );
    },
    exportFile: () => {
      dispatch((dispatch, getState) => {
        const project = getProject(getState(), { projectId });
        const branch = getBranch(getState(), { projectId, branchId });
        invariant(exportFile, "exportFile required for File Menu");
        invariant(project, "project required for File Menu");
        invariant(branch, "branch required for File Menu");
        dispatch(exportFile(file, project, branch));
      });
    },
    renameFile: () => {
      invariant(renameFile, "renameFile required for File Menu");
      dispatch(
        renameFile(
          projectId,
          branchId,
          file.id,
          file.type,
          file.name,
          undefined
        )
      );
    },
    replaceFile: () => {
      invariant(replaceFile, "replaceFile required for File Menu");
      dispatch(replaceFile(projectId, branchId, file.id, !!file.isLibrary));
    },
    clearCacheAndEditingFile: () => {
      invariant(
        clearCacheAndEditingFile,
        "clearCacheAndEditingFile required for File Menu"
      );
      dispatch(clearCacheAndEditingFile(projectId, branchId, file));
    },
    manageLibraries: () => {
      invariant(manageLibraries, "manageLibraries required for File Menu");
      dispatch(manageLibraries(projectId, branchId, file.sha, file.id));
    },
    showDialog: (name: string, props: {}) => {
      dispatch(showDialog(name, props));
    },
    deleteFile: () => {
      invariant(deleteFile, "deleteFile required for File Menu");
      dispatch(deleteFile(projectId, branchId, file, false));
    },
    openInFinder: () => {
      invariant(openFileInFinder, "openFileInFinder required for FileMenu");
      dispatch(openFileInFinder(projectId, file.id));
    },
    openInTerminal: () => {
      invariant(openFileInTerminal, "openFileInTerminal required for FileMenu");
      dispatch(openFileInTerminal(projectId, file.id));
    },
  };
}

export default createConnector<
  Props,
  {|
    ...OwnProps,
    ...$Exact<ContextMenuWrapperProps>,
  |},
>(
  contextMenuWrapper,
  connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch>(
    mapStateToProps,
    mapDispatchToProps
  )
);
