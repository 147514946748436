// @flow
import classnames from "classnames";
import * as React from "react";
import { withRouter } from "core/lib/router";
import CommentForm, { type OwnProps as CommentFormProps } from "../CommentForm";
import style from "./style.scss";

type Props = {
  ...CommentFormProps,
  onUpdate: (formId: string, params: Object) => *,
};

function CommentEditForm(props: Props) {
  const handleSubmit = (body: string) => {
    props.onUpdate(props.formId, {
      annotation: props.annotation,
      body,
    });
  };

  const handleCancel = () => {
    if (props.onReset) {
      props.onReset(props.formId);
    }
  };

  return (
    <CommentForm
      projectId={props.projectId}
      flat={props.flat}
      online={props.online}
      body={props.body}
      formId={props.formId}
      minHeight={props.minHeight}
      disabled={props.disabled}
      hasError={props.hasError}
      className={classnames(style.form, props.className)}
      onRequestAnnotationRemoval={props.onRequestAnnotationRemoval}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      onChange={props.onChange}
      onReset={props.onReset}
      annotation={props.annotation}
      isAnnotatable={props.isAnnotatable}
      submitButtonText="Save"
      autoFocus
      hasCancel
    />
  );
}

// $FlowFixMeNowPlease react-router upgrade
export default withRouter(CommentEditForm);
