// @flow
import { normalize, schema } from "normalizr";
import * as ProjectMembership from "core/models/projectMembership";
import { project, user } from "core/schema";
import { envelopeSchema } from "core/schemas/envelope";

export const projectMembershipSchema = new schema.Entity(
  "projectMemberships",
  {
    user,
  },
  {
    idAttribute: ProjectMembership.uniqueId,
  }
);

export const projectMembershipsSchema = new schema.Array(
  projectMembershipSchema
);

export const fetchUserSuggestionsResponseSchema = envelopeSchema(
  projectMembershipsSchema
);

const updateProjectMembershipResponseSchema = envelopeSchema({
  project,
  projectMembership: projectMembershipSchema,
});

export function normalizeProjectMembership(membership: Object) {
  return normalize(membership, projectMembershipSchema);
}

export function normalizeProjectMemberships(response: Object) {
  return normalize(response, projectMembershipsSchema);
}

export function normalizeUpdateProjectMembershipResponse(response: Object) {
  return normalize(response, updateProjectMembershipResponseSchema);
}

export function normalizeFetchUserSuggestionsResponse(response: Object) {
  return normalize(response, fetchUserSuggestionsResponseSchema);
}
