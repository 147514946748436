// @flow
import { sortBy } from "lodash";
import { getCurrentUserId } from "abstract-di/selectors";
import { entitiesDeleted, entitiesReceived } from "core/actions/entities";
import {
  refreshProjectMembershipsOnTeamChange,
  refreshProjectMemberships,
} from "core/actions/projectMemberships";
import { resetTeamMembershipsPaginationTotal } from "core/actions/teamMemberships";
import { TeamProjectMembershipAdded } from "core/actions/teamProjectMemberships";
import { resetCurrentUserTeamsPaginationTotal } from "core/actions/teams";
import createLogger from "core/lib/logger";
import * as TeamProjectMembership from "core/models/teamProjectMembership";
import { ProjectMembershipRequest } from "core/requests/projectMemberships";
import { normalizeTeamSocketResponse } from "core/schemas/team";
import { normalizeTeamMembershipSocketResponse } from "core/schemas/teamMemberships";
import { normalizeTeamProjectMembershipSocketResponse } from "core/schemas/teamProjectMembership";
import { getTeamMembership } from "core/selectors/teamMemberships";
import type {
  SocketEvent,
  LongMessageSocketEvent,
  ThunkAction,
} from "core/types";

type Data = $PropertyType<SocketEvent, "data">;

const logger = createLogger("socket");

export const socketMessageReceived = (
  event: SocketEvent | LongMessageSocketEvent
): ThunkAction => {
  return async function (dispatch, getState) {
    const currentUserId = getCurrentUserId(getState());

    switch (event.type) {
      // TEAMS
      case "TEAM_CREATED":
      case "TEAM_UPDATED": {
        const { entities } = normalizeTeamSocketResponse(event.data);
        return dispatch(entitiesReceived(entities));
      }
      case "TEAM_DELETED": {
        // We reload the current user's teams to reset the paginationTotal value in
        // case user belongs to the deleted team.
        dispatch(
          resetCurrentUserTeamsPaginationTotal(event.data.organizationId)
        );

        // Regenerate relevant ProjectMemberships that may exist in the Redux store
        dispatch(refreshProjectMembershipsOnTeamChange(event.data.teamId));
        return dispatch(entitiesDeleted({ teams: [event.data.teamId] }));
      }

      // TEAM MEMBERSHIPS
      case "TEAM_MEMBER_ADDED": {
        const { entities } = normalizeTeamMembershipSocketResponse(event.data);

        // Regenerate relevant Project Memberships that may exist in the Redux store
        dispatch(
          refreshProjectMembershipsOnTeamChange(
            event.data.teamMembership.teamId
          )
        );

        // Reset Team Memberships total
        dispatch(
          resetTeamMembershipsPaginationTotal({
            organizationId: event.data.organizationId,
            teamId: event.data.teamMembership.teamId,
          })
        );

        // Reset "Your Teams" total
        if (currentUserId === event.data.user.id) {
          dispatch(
            resetCurrentUserTeamsPaginationTotal(event.data.organizationId)
          );
        }
        return dispatch(entitiesReceived(entities));
      }
      case "TEAM_MEMBER_REMOVED": {
        // Regenerate relevant Project Memberships that may exist in the Redux store
        dispatch(refreshProjectMembershipsOnTeamChange(event.data.teamId));

        // Reset Team Memberships total
        dispatch(
          resetTeamMembershipsPaginationTotal({
            organizationId: event.data.organizationId,
            teamId: event.data.teamId,
          })
        );

        // Reset "Your Teams" total
        if (currentUserId === event.data.userId) {
          dispatch(
            resetCurrentUserTeamsPaginationTotal(event.data.organizationId)
          );
        }
        const teamMembership = getTeamMembership(getState(), {
          teamId: event.data.teamId,
          userId: event.data.userId,
        });
        if (teamMembership) {
          return dispatch(
            entitiesDeleted({ teamMemberships: [teamMembership.id] })
          );
        }
        return;
      }
      // TEAM PROJECT MEMBERSHIPS
      case "TEAM_PROJECT_MEMBER_ADDED": {
        const { entities: teamProjectMembershipEntities } =
          normalizeTeamProjectMembershipSocketResponse(event.data);
        dispatch(entitiesReceived(teamProjectMembershipEntities));
        return dispatch(
          TeamProjectMembershipAdded(teamProjectMembershipEntities)
        );
      }
      case "TEAM_PROJECT_MEMBER_REMOVED":
        dispatch(
          entitiesDeleted({
            teamProjectMemberships: [
              TeamProjectMembership.uniqueId({
                teamId: event.data.teamId,
                projectId: event.data.projectId,
              }),
            ],
          })
        );

        // If the current user loses access to the project as a result,
        // we'll remove the project from their store.
        if (currentUserId) {
          dispatch(
            ProjectMembershipRequest.perform({
              params: {
                projectId: event.data.projectId,
                userId: currentUserId,
              },
              onSuccess: () => {
                dispatch(refreshProjectMemberships(event.data.projectId));
              },
              onError: (error) => {
                if (error.name === "ForbiddenError") {
                  dispatch(
                    entitiesDeleted({
                      projects: [event.data.projectId],
                    })
                  );
                }
              },
            })
          );
        }
        return;

      default:
        return logger.log(
          "unhandled socket message received",
          event.type,
          event.data ? event.data : undefined
        );
    }
  };
};

const longMessages = {};
export function longMessageReceived(
  event: LongMessageSocketEvent
): ?SocketEvent {
  const id = event.data.id;

  // add to queue for id
  if (!longMessages[id]) {
    longMessages[id] = [];
  }
  longMessages[id].push(event.data);

  // check if all segments exist
  if (longMessages[id].length === event.data.segmentTotal) {
    // ensure ordering
    // reconstruct original message
    const originalMessage = sortBy(longMessages[id], "segment")
      .map((message) => message.data)
      .join("");

    const data: Data = JSON.parse(originalMessage);

    // return the original event once reconstructed
    // $FlowFixMe - https://github.com/facebook/flow/issues/4683
    return {
      type: event.data.type,
      data,
    };
  }
}
