// @flow
import md5 from "md5";

const AVATAR_COLORS = [
  "#56a3d9",
  "#cd4a42",
  "#d86a07",
  "#956e5b",
  "#db4d99",
  "#008667",
  "#6767e4",
  "#4d88cd",
  "#945ecf",
  "#f58679",
  "#f3b11b",
  "#8b3d52",
  "#0080a2",
  "#75b170",
  "#ed73ef",
  "#74878e",
];

const idToColorCache = {};

export function idToColor(id: string) {
  if (idToColorCache[id]) {
    return idToColorCache[id];
  }

  const idAsHex = md5(id);
  const idAsNumber = parseInt(idAsHex, 16);
  const color = AVATAR_COLORS[idAsNumber % (AVATAR_COLORS.length + 1)];
  idToColorCache[id] = color;
  return color;
}
