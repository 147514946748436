// @flow
import get from "lodash/get";
import * as React from "react";
import { connect } from "react-redux";
import { loadCommitChangeset } from "core/actions/changesets";
import { fetchComments } from "core/actions/comments";
import { loadCommit } from "core/actions/commits";
import OfflineCommit from "core/components/Commit/Offline";
import CommitHeader from "core/components/CommitHeader";
import Error from "core/components/Empty/Error";
import Flex from "core/components/Flex";
import Loaded from "core/components/Loaded";
import * as Request from "core/models/request";
import { BranchFetchRequest } from "core/requests/branches";
import { CommitChangesetFetchRequest } from "core/requests/changesets";
import { CommentsFetchRequest } from "core/requests/comments";
import { CommitFetchRequest } from "core/requests/commits";
import { ProjectFetchRequest } from "core/requests/projects";
import { getBranch } from "core/selectors/branches";
import {
  getChangeset,
  getChangesetStatusCounts,
  getChangesetFiles,
  getChangesetPages,
  getLayerStatusesForChangeset,
  getPreviewsByFileForChangeset,
} from "core/selectors/changesets";
import { getFilteredComments, getCommentCounts } from "core/selectors/comments";
import { getCommit } from "core/selectors/commits";
import { getProjectPolicy } from "core/selectors/policies";
import { getUser } from "core/selectors/users";
import type {
  State,
  Dispatch,
  User,
  Branch,
  Project,
  Page,
  FilePreviews,
  File as TFile,
  Comment,
  Commit as TCommit,
  Changeset,
  ChangesetStatus,
  ChangeStatusCounts,
} from "core/types";
import Commit from "web/components/Commit";
import { getCurrentUserId, isOnline } from "web/di/selectors";
import { getProject } from "web/selectors/projects";

type OwnProps = {|
  params: {
    projectId: string,
    branchId: string,
    sha: string,
  },
  location: { hash?: string, query: { commentId?: string, filter?: string } },
  routes: Array<{ id: string }>,
|};

type StateProps = {|
  key: string,
  changeset: ?Changeset,
  branch: ?Branch,
  changeStatusCounts: ?ChangeStatusCounts,
  currentUserId?: string,
  comments: Comment[],
  commit: ?TCommit,
  filter?: string,
  files: TFile[],
  previewsByFile: FilePreviews,
  layerStatuses: { [layerId: string]: ChangesetStatus },
  layerCommentCounts: { [id: string]: number },
  pages: { [pageId: string]: Page },
  project: ?Project,
  user: ?User,
  isLoading: boolean,
  hasError: boolean,
  notFound: boolean,
  canShare: boolean,
  isOffline: boolean,
|};

type DispatchProps = {|
  onMount: () => void,
|};

type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

class CommitLoader extends React.Component<Props> {
  componentDidMount() {
    this.props.onMount();
  }

  renderWithCommitHeader = (children: React.Node) => {
    return (
      <Flex column>
        {this.props.commit ? (
          <CommitHeader commit={this.props.commit} user={this.props.user} />
        ) : null}
        {children}
      </Flex>
    );
  };

  render() {
    if (this.props.isLoading) {
      return this.renderWithCommitHeader(
        <Loaded loading title="Loading commit…" />
      );
    }

    if (
      this.props.hasError ||
      !this.props.branch ||
      !this.props.commit ||
      !this.props.changeset ||
      !this.props.project ||
      !this.props.user
    ) {
      if (this.props.isOffline) {
        return this.renderWithCommitHeader(<OfflineCommit />);
      }
      return this.renderWithCommitHeader(<Error flex />);
    }

    return (
      <Commit
        branch={this.props.branch}
        changeset={this.props.changeset}
        changeStatusCounts={this.props.changeStatusCounts}
        comments={this.props.comments}
        commit={this.props.commit}
        currentUserId={this.props.currentUserId}
        files={this.props.files}
        previewsByFile={this.props.previewsByFile}
        layerStatuses={this.props.layerStatuses}
        layerCommentCounts={this.props.layerCommentCounts}
        pages={this.props.pages}
        project={this.props.project}
        user={this.props.user}
        hash={get(this.props.location, "hash", "")}
        filter={this.props.filter}
        commentId={get(this.props.location, "query.commentId", "")}
        canShare={this.props.canShare}
      />
    );
  }
}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { projectId, branchId, sha } = props.params;
  const commentParams = { projectId, branchId, commitSha: sha };
  const changesetParams = { projectId, sha };
  const filter = props.location.query.filter || undefined;
  const policy = getProjectPolicy(state, { projectId });

  const commit = getCommit(state, { sha });

  const commitRequest = CommitFetchRequest.getRequest(state, {
    projectId,
    sha,
  });
  const branchRequest = BranchFetchRequest.getRequest(state, {
    projectId,
    branchId,
  });
  const projectRequest = ProjectFetchRequest.getRequest(state, { projectId });
  const commentsRequest = CommentsFetchRequest.getRequest(state, commentParams);
  const changesetRequest = CommitChangesetFetchRequest.getRequest(state, {
    projectId,
    branchId,
    sha,
  });

  return {
    key: [projectId, branchId, sha].join("-"),
    isLoading: Request.isLoading(
      changesetRequest,
      commitRequest,
      branchRequest,
      projectRequest,
      commentsRequest
    ),
    notFound:
      Request.notFound(changesetRequest) || Request.forbidden(changesetRequest),
    hasError: Request.hasError(changesetRequest),
    changeset: getChangeset(state, changesetParams),
    currentUserId: getCurrentUserId(state),
    branch: getBranch(state, { projectId, branchId }),
    layerStatuses: getLayerStatusesForChangeset(state, changesetParams),
    previewsByFile: getPreviewsByFileForChangeset(state, {
      ...changesetParams,
      status: filter,
    }),
    changeStatusCounts: getChangesetStatusCounts(state, changesetParams),
    comments: getFilteredComments(state, commentParams),
    commit,
    filter,
    files: getChangesetFiles(state, changesetParams),
    layerCommentCounts: getCommentCounts(state, {
      projectId,
      branchId,
      key: "layerId",
    }),
    pages: getChangesetPages(state, changesetParams),
    project: getProject(state, projectId),
    user: getUser(state, { userId: commit ? commit.userId : "" }),
    canShare: policy.share === true,
    isOffline: !isOnline(state),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { projectId, branchId, sha } = props.params;
  return {
    onMount() {
      dispatch(loadCommit({ projectId, sha }));
      dispatch(loadCommitChangeset({ projectId, branchId, sha }));
      dispatch(fetchComments({ projectId, branchId, commitSha: sha }));
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(
  mapStateToProps,
  mapDispatchToProps
)(CommitLoader);
