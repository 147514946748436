// @flow
import classnames from "classnames";
import { findIndex } from "lodash";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button as ReakitButton } from "reakit/Button";
import { isOnline } from "abstract-di/selectors";
import { trackEvent } from "core/actions/analytics";
import Button from "core/components/Button";
import CreateOrganization from "core/components/CreateOrganization";
import DelayedMount from "core/components/DelayedMount";
import Flex from "core/components/Flex";
import FloatingMenu from "core/components/FloatingMenu";
import Heading from "core/components/Heading";
import HorizontalSeparator from "core/components/HorizontalSeparator";
import Icon from "core/components/Icon";
import OrganizationListItem from "core/components/OrganizationListItem";
import OrganizationLogo from "core/components/OrganizationLogo";
import PlaceholderText from "core/components/PlaceholderText";
import { isDesktop } from "core/lib/platform";
import { FeaturesFetchRequest } from "core/requests/features";
import {
  getNonUsernameOrganizations,
  getUsernameOrganization,
} from "core/selectors/organizations";
import type { Organization as TOrganization } from "core/types";
import style from "./style.scss";

function Placeholder(props: { className?: string }) {
  return (
    <DelayedMount>
      <Flex
        className={classnames(
          style.organizationButtonPlaceholder,
          props.className
        )}
      >
        <div className={style.organizationAvatarPlaceholder}></div>
        <PlaceholderText
          className={style.organizationNamePlaceholder}
          size="l"
        />
      </Flex>
    </DelayedMount>
  );
}

type Props = {|
  className?: string,
  closeMobileSidebar?: () => void,
  currentOrganization: ?TOrganization,
|};

export default function OrganizationSwitcher(props: Props) {
  const { closeMobileSidebar, currentOrganization } = props;

  const dispatch = useDispatch();

  const isLoadingFeatures = useSelector((state) =>
    currentOrganization
      ? FeaturesFetchRequest.isFirstLoading(state, {
          organizationId: currentOrganization.id,
        })
      : true
  );
  const isOffline = !useSelector(isOnline);
  const organizations = useSelector(getNonUsernameOrganizations);
  const usernameOrganization = useSelector(getUsernameOrganization);
  const [isCreateOrganizationDialogOpen, setIsCreateOrganizationDialogOpen] =
    React.useState(false);

  const selectedOrganizationIndex = React.useMemo(
    () =>
      currentOrganization
        ? currentOrganization.isUsernameOrganization
          ? organizations.length
          : findIndex(organizations, {
              id: currentOrganization.id,
            })
        : -1,
    [currentOrganization, organizations]
  );

  const handleCloseCreateOrganizationDialog = React.useCallback(() => {
    setIsCreateOrganizationDialogOpen(false);
  }, []);

  const createOrganizationListItemClickHandler = (
    organizationId: string,
    dismissMenu: () => void,
    closeSidebar?: boolean
  ) => {
    return () => {
      dismissMenu();

      if (closeSidebar && closeMobileSidebar) {
        closeMobileSidebar();
      }

      dispatch(
        trackEvent("ORGANIZATION_SWITCHED", {
          organizationId,
        })
      );
    };
  };

  const createNewOrganizationClickHandler = (dismissMenu: () => void) => {
    return () => {
      dismissMenu();

      setIsCreateOrganizationDialogOpen(true);
    };
  };

  if (!currentOrganization) {
    return <Placeholder className={props.className} />;
  }

  return (
    <React.Fragment>
      <FloatingMenu
        body={(dismiss) => (
          <ul className={style.organizationsList} role="menu">
            <OrganizationListItem
              index={
                isDesktop && selectedOrganizationIndex >= 0
                  ? selectedOrganizationIndex + 1
                  : 0
              }
              isOwnUsernameOrganization={
                currentOrganization.isUsernameOrganization
              }
              onClick={createOrganizationListItemClickHandler(
                currentOrganization.id,
                dismiss,
                true
              )}
              organization={currentOrganization}
              selected
              qaSelector="organization-list-item-0"
            />
            <HorizontalSeparator className={style.separator} />
            {organizations.map((organization, index) =>
              organization.id !== currentOrganization.id ? (
                <OrganizationListItem
                  key={organization.id}
                  index={isDesktop ? index + 1 : 0}
                  organization={organization}
                  onClick={createOrganizationListItemClickHandler(
                    organization.id,
                    dismiss
                  )}
                  qaSelector={`organization-list-item-${index}`}
                />
              ) : null
            )}
            {usernameOrganization &&
              usernameOrganization.id !== currentOrganization.id && (
                <OrganizationListItem
                  index={isDesktop ? organizations.length + 1 : 0}
                  organization={usernameOrganization}
                  onClick={createOrganizationListItemClickHandler(
                    usernameOrganization.id,
                    dismiss
                  )}
                  isOwnUsernameOrganization
                  qaSelector={`organization-list-item-${
                    organizations.length + 1
                  }`}
                />
              )}
            {isLoadingFeatures || isDesktop ? null : (
              <li role="presentation">
                <ReakitButton
                  className={style.createButton}
                  onClick={createNewOrganizationClickHandler(dismiss)}
                  role="menuitem"
                  data-qa="createOrganizationButton"
                  disabled={isOffline}
                >
                  <Flex
                    align="center"
                    className={style.createIcon}
                    grow={false}
                    justify="center"
                  >
                    <Icon type="plus" />
                  </Flex>
                  <span className={style.createLabel}>
                    {"Create new Organization…"}
                  </span>
                </ReakitButton>
              </li>
            )}
          </ul>
        )}
        floatingClassName={style.floating}
        placement="bottom-start"
        lockBodyScroll
        scrollable
        qaSelector="organization-switcher-scrollable"
      >
        {(showPopover, ref, _, buttonProps) => (
          <Button
            {...buttonProps}
            className={classnames(style.organizationButton, props.className)}
            innerRef={ref}
            onClick={showPopover}
            qaSelector="organization-switcher"
            disclosure
            nude
          >
            <Flex align="center" className={style.innerButton}>
              <OrganizationLogo
                alt={currentOrganization.name}
                size={24}
                src={currentOrganization.logoUrl}
              />
              <Heading
                className={style.organizationName}
                level="2"
                qaSelector="current-organization"
                size="xl"
              >
                {currentOrganization.name}
              </Heading>
            </Flex>
          </Button>
        )}
      </FloatingMenu>
      <CreateOrganization
        isOpen={isCreateOrganizationDialogOpen}
        onClose={handleCloseCreateOrganizationDialog}
      />
    </React.Fragment>
  );
}
