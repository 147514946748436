// @flow
import * as Branch from "core/models/branch";
import type { State, MultiSelectedEntities } from "core/types";

export function getMultiSelectedEntities(
  state: State,
  props: { projectId: string, branchId: string }
): ?MultiSelectedEntities {
  return state.collectionMultiselect[Branch.uniqueId(props)];
}
