// @flow
import * as React from "react";
import DialogForm, { TDialogForm } from "core/components/DialogForm";
import { SubmittableInput } from "core/components/Input/withSubmittable";
import type { Project, Branch } from "core/types";
import Validations from "core/validations";
import connector from "./connector";

export type OwnProps = {|
  project: Project,
  branch: Branch,
|};

export type DispatchProps = {|
  updateBranch: (
    projectId: string,
    branchId: string,
    branchAttrs: Object
  ) => void,
  dismissDialog: () => void,
|};

export type Props = {
  ...OwnProps,
  ...DispatchProps,
};

type State = {
  name: string,
  error?: Error,
};

class EditBranch extends React.Component<Props, State> {
  form: ?TDialogForm;
  state = { name: this.props.branch.name };

  nameChanged = (ev: SyntheticInputEvent<>) => {
    const { value } = ev.target;
    this.setState({ name: value, error: undefined });
    this.form && this.form.checkValid();
  };

  handleSubmit = async () => {
    const { project, branch } = this.props;

    try {
      await this.props.updateBranch(project.id, branch.id, {
        ...this.state,
        status: branch.status,
      });
    } catch (error) {
      this.setState({ error });
      return;
    }

    this.props.dismissDialog();
  };

  render() {
    return (
      <DialogForm
        title="Rename Branch"
        primaryButton="Save Branch"
        ref={(ref) => (this.form = ref)}
        onClose={this.props.dismissDialog}
        onSubmit={this.handleSubmit}
        isOpen
      >
        {({ onSubmit }) => (
          <SubmittableInput
            type="text"
            label="Name"
            value={this.state.name}
            onSubmit={onSubmit}
            placeholder="A couple of words is best…"
            error={this.state.error ? this.state.error.message : undefined}
            onChange={this.nameChanged}
            minLength={Validations.minBranchNameLength}
            maxLength={Validations.maxBranchNameLength}
            pattern={Validations.isSafeBranchName}
            requiredTag
            required
            autoFocus
          />
        )}
      </DialogForm>
    );
  }
}

export default connector(EditBranch);
