// @flow
import classnames from "classnames";
import * as React from "react";
import { organizationBillingUrl } from "../../lib/urls";
import style from "./style.scss";

export default function SubscriptionBannerExpired(props: {
  organizationId: string,
}) {
  return (
    <div className={classnames(style.banner, style.expired)}>
      <span className={style.messageText}>
        Your trial has expired. In order to keep using Abstract,{" "}
        <a
          href={organizationBillingUrl(props.organizationId)}
          className={style.link}
        >
          please upgrade now.
        </a>
      </span>
    </div>
  );
}
