// @flow
import classnames from "classnames";
import * as React from "react";
import Flex from "core/components/Flex";
import Head from "core/components/Head";
import style from "./style.scss";

export default function SettingsForm({
  header,
  className,
  children,
  disabled,
  onSubmit,
  documentTitle,
  noExtraPadding,
  ...props
}: {
  children: React.Node,
  className?: string,
  disabled?: boolean,
  documentTitle?: string,
  header?: React.Element<any>,
  onSubmit?: Function,
  noExtraPadding?: boolean,
}) {
  const Component = onSubmit ? "form" : "div";
  return (
    <Component
      {...props}
      onSubmit={onSubmit}
      className={classnames(style.settingsForm, className)}
    >
      {documentTitle && (
        <Head>
          <title>{documentTitle}</title>
        </Head>
      )}
      {header}
      <Flex
        column
        className={classnames(style.formContent, {
          [style.disabled]: disabled,
        })}
      >
        <Flex
          column
          className={classnames(style.scrollable, {
            [style.noExtraPadding]: noExtraPadding,
          })}
        >
          {children}
        </Flex>
      </Flex>
    </Component>
  );
}
