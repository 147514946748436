// @flow
import * as React from "react";
import "react-image-crop/dist/ReactCrop.css";
import DialogForm from "../DialogForm";
import ImageCropConfirmation from "../ImageCropConfirmation";
import ImageCropper from "../ImageCropper";
import ImagePicker from "../ImagePicker";
import style from "./style.scss";

type Props = {
  type: "avatar" | "organizationLogo",
  isOpen: boolean,
  onComplete: (imageDataUrl: string) => void,
};

type State = {
  croppedDataUrl: string,
  originalDataUrl: string,
  fileType: string,
};

export default class ImagePickerModal extends React.Component<Props, State> {
  state = { croppedDataUrl: "", originalDataUrl: "", fileType: "" };

  handleFileChosen = (originalDataUrl: string, fileType: string) => {
    this.setState({ originalDataUrl, croppedDataUrl: "", fileType });
  };

  handleCrop = (croppedDataUrl: string) => {
    this.setState({ croppedDataUrl });
  };

  handleConfirm = () => {
    this.props.onComplete(this.state.croppedDataUrl);
  };

  renderModalInner() {
    if (this.state.originalDataUrl && !this.state.croppedDataUrl) {
      return (
        <ImageCropper
          onSave={this.handleCrop}
          src={this.state.originalDataUrl}
          fileType={this.state.fileType}
          type={this.props.type}
        />
      );
    }

    if (this.state.croppedDataUrl) {
      return (
        <ImageCropConfirmation
          onConfirm={this.handleConfirm}
          src={this.state.croppedDataUrl}
          type={this.props.type}
        />
      );
    }

    return (
      <ImagePicker onChange={this.handleFileChosen} type={this.props.type} />
    );
  }

  render() {
    return (
      <DialogForm
        {...this.props}
        size="medium"
        contentClassName={style.removeScroll}
        title={
          this.props.type === "avatar"
            ? "Change Avatar"
            : "Change Organization logo"
        }
        primaryButton=""
      >
        {this.renderModalInner()}
      </DialogForm>
    );
  }
}
