// @flow
import Cookies from "js-cookie";

const SECURE_COOKIES_ENABLED = process.env.NODE_ENV === "production";

const PROJECTS_LIST_VIEW_TYPE_KEY = "abstract-projects-list-type";
const PEOPLE_LIST_VIEW_TYPE_KEY = "abstract-people-list-type";
const DEFAULT_LIST_TYPE = "grid";

export function setProjectListViewType(type: string): void {
  Cookies.set(PROJECTS_LIST_VIEW_TYPE_KEY, type, {
    secure: SECURE_COOKIES_ENABLED,
    expires: 365,
  });
}

export function getProjectListViewType(): string {
  const type = Cookies.get(PROJECTS_LIST_VIEW_TYPE_KEY);
  return type || DEFAULT_LIST_TYPE;
}

export function setPeopleListViewType(type: string): void {
  Cookies.set(PEOPLE_LIST_VIEW_TYPE_KEY, type, {
    secure: SECURE_COOKIES_ENABLED,
    expires: 365,
  });
}

export function getPeopleListViewType(): string {
  return Cookies.get(PEOPLE_LIST_VIEW_TYPE_KEY) || DEFAULT_LIST_TYPE;
}
