// @flow
import * as React from "react";
import style from "./style.scss";

type Props = {
  children?: React.Node,
  href: string,
  innerRef?: React.Ref<"a">,
};

export default function ExternalLink({
  children,
  href,
  innerRef,
  ...rest
}: Props) {
  return (
    <a
      href={href}
      ref={innerRef}
      rel="noopener noreferrer"
      target="_blank"
      className={style.link}
      {...rest}
    >
      {children}
    </a>
  );
}
