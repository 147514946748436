// @flow
import { getEntity } from "core/selectors/entities";
import type { State } from "core/types";
import type { NotificationSettings } from "web/types";

export function getNotificationSettings(
  state: State,
  { userId, organizationId }: { userId: string, organizationId?: string }
): NotificationSettings {
  const id = organizationId ? `${userId}-${organizationId}` : userId;
  return getEntity(state, "notificationSettings", id);
}
