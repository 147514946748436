// @flow
import classnames from "classnames";
import { groupBy, isEmpty, isEqual, noop } from "lodash";
import * as React from "react";
import InfiniteLoader from "react-window-infinite-loader";
import scrollIntoView from "smooth-scroll-into-view-if-needed";
import Button from "core/components/Button";
import Centered from "core/components/Centered";
import { CommitPlaceholder } from "core/components/Commit";
import CommitListItem from "core/components/CommitListItem";
import Heading from "core/components/Heading";
import KeyboardNavigation from "core/components/KeyboardNavigation";
import Loading from "core/components/Loading";
import MountProfiler from "core/components/MountProfiler";
import PlaceholderText from "core/components/PlaceholderText";
import VirtualizedList, { type Row } from "core/components/VirtualizedList";
import { Abstract } from "core/lib/abstract";
import { dateGroup } from "core/lib/dates";
import { isDesktop as isDesktopPlatform } from "core/lib/platform";
import type {
  Project,
  Branch,
  Comment,
  Commit as TCommit,
  LocationDescriptor,
} from "core/types";
import OlderCommitsOffline from "./OlderCommitsOffline";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  params:
    | Abstract.BranchDescriptor
    | Abstract.BranchCommitDescriptor
    | Abstract.LayerVersionDescriptor,
  defaultFocused?: boolean,
  commitComments?: { [sha: string]: Comment[] },
  commitPath?: (string) => LocationDescriptor,
  onCommitClick?: (SyntheticEvent<>, TCommit) => void,
  isDesktop?: boolean,
|};

export type StateProps = {|
  head: string,
  project: ?Project,
  branch: ?Branch,
  parentBranch: ?Branch,
  commits: TCommit[],
  commitCommentCounts: { [sha: string]: number },
  isBranchSynced: boolean,
  isLoading: boolean,
  hasError: boolean,
  isOffline: boolean,
  hasMore: boolean,
  isSyncing: boolean,
|};

export type DispatchProps = {|
  onMount: (head: string) => void,
  onHeadChange: (head: string) => void,
  onLoadCommits?: ({ startSha: string }) => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

class CommitsList extends React.Component<Props> {
  selected: ?CommitListItem;
  scrollTimeout: ?TimeoutID;

  static defaultProps = {
    onCommitClick: noop,
    defaultFocused: false,
    isLoading: false,
  };

  componentDidMount() {
    this.props.onMount(this.props.head);
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.params.sha &&
      this.props.params.sha !== prevProps.params.sha
    ) {
      this.scrollToSelected();
    }

    // If the branch head changes while this view is open commits need loading
    if (
      isEqual(prevProps.params, this.props.params) &&
      prevProps.head !== this.props.head &&
      this.props.branch
    ) {
      this.props.onHeadChange(this.props.head);
    }
  }

  selectedRef = (ref: ?CommitListItem) => {
    this.selected = ref;
  };

  scrollToSelected = () => {
    if (this.selected) {
      this.scrollTimeout = setTimeout(() => {
        scrollIntoView(this.selected, {
          duration: 500,
          block: "center",
          behavior: "smooth",
          scrollMode: "if-needed",
        });
        this.scrollTimeout = undefined;
      }, 500);
    }
  };

  componentWillUnmount() {
    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
      this.scrollTimeout = undefined;
    }
  }

  handleLoadMore = () => {
    const lastCommit = this.props.commits[this.props.commits.length - 1];

    if (lastCommit && this.props.onLoadCommits) {
      this.props.onLoadCommits({
        startSha: lastCommit.sha,
      });
    }
  };

  handleReload = () => {
    this.props.onMount(this.props.head);
  };

  getShowOfflineCommitMessage = () => {
    return !!(
      this.props.isOffline &&
      !this.props.isBranchSynced &&
      this.props.hasMore
    );
  };

  getCommitCommentCount = (commit: TCommit) => {
    const { commitComments, commitCommentCounts } = this.props;

    if (commitComments) {
      return commitComments[commit.sha] ? commitComments[commit.sha].length : 0;
    }

    if (commitCommentCounts) {
      return commitCommentCounts[commit.sha] || 0;
    }

    return 0;
  };

  get isEmpty(): boolean {
    return !this.props.commits.length || isEmpty(this.props.commits);
  }

  get groupedCommits(): { [date: string]: TCommit[] } {
    return groupBy(this.props.commits, (commit) => dateGroup(commit.time));
  }

  isBranchCommit() {
    return this.props.params.layerId ? false : true;
  }

  getItems = (keyboardNavigation: {
    isFocused: boolean,
    selectedClassName: string,
  }): Row[] => {
    const { commitPath, project, branch, parentBranch, onCommitClick } =
      this.props;
    const isBranchCommit = this.isBranchCommit();

    const groupedCommits = this.groupedCommits;
    const groups = Object.keys(groupedCommits);

    let items = [
      {
        height: 48,
        children: (
          <Heading
            level="3"
            size="l"
            className={
              isBranchCommit
                ? style.sidebarTopHeadingBranchCommit
                : style.sidebarTopHeading
            }
          >
            Commits
          </Heading>
        ),
      },
    ];

    if (this.isEmpty && this.props.isLoading) {
      return items.concat(
        {
          height: 40,
          children: (
            <Heading
              size="xs"
              level="3"
              className={
                isBranchCommit
                  ? style.layerHeadingBranchCommit
                  : style.layerHeading
              }
            >
              <PlaceholderText size="xs" min={20} max={30} />
            </Heading>
          ),
        },
        {
          height: 48,
          children: (
            <CommitPlaceholder
              className={classnames(style.commit, {
                [style.branchCommit]: isBranchCommit,
              })}
              first
            />
          ),
        },
        {
          height: 48,
          children: (
            <CommitPlaceholder
              className={classnames(style.commit, {
                [style.branchCommit]: isBranchCommit,
              })}
            />
          ),
        },
        {
          height: 40,
          children: (
            <Heading
              size="xs"
              level="3"
              className={
                isBranchCommit
                  ? style.layerHeadingBranchCommit
                  : style.layerHeading
              }
            >
              <PlaceholderText size="xs" min={20} max={30} />
            </Heading>
          ),
        },
        {
          height: 48,
          children: (
            <CommitPlaceholder
              className={classnames(style.commit, {
                [style.branchCommit]: isBranchCommit,
              })}
            />
          ),
        },
        {
          height: 48,
          children: (
            <CommitPlaceholder
              className={classnames(style.commit, {
                [style.branchCommit]: isBranchCommit,
              })}
            />
          ),
        },
        {
          height: 48,
          children: (
            <CommitPlaceholder
              className={classnames(style.commit, {
                [style.branchCommit]: isBranchCommit,
              })}
              last
            />
          ),
        }
      );
    }
    items = items.concat(
      groups.reduce((memo, group, groupIndex) => {
        const firstGroup = groupIndex === 0;
        const lastGroup = groupIndex === groups.length - 1;

        const heading = {
          height: 40,
          children: (
            <Heading
              size="xs"
              level="3"
              className={
                isBranchCommit
                  ? style.layerHeadingBranchCommit
                  : style.layerHeading
              }
            >
              {group}
            </Heading>
          ),
        };

        const commits = groupedCommits[group].map(
          (commit, commitIndex, groupCommits) => {
            const firstCommit = commitIndex === 0;
            const lastCommit = commitIndex === groupCommits.length - 1;
            const isSelected =
              (this.props.params.sha && commit.sha === this.props.params.sha) ||
              (this.props.params.sha === "latest" && firstGroup && firstCommit);

            return {
              height: 48,
              key: commit.sha,
              children: (
                <CommitListItem
                  {...keyboardNavigation}
                  key={commit.sha}
                  className={classnames(style.commit, {
                    [style.branchCommit]: isBranchCommit,
                  })}
                  goToCommit={!isBranchCommit}
                  commit={commit}
                  project={project}
                  branch={branch}
                  parent={parentBranch}
                  commentCount={this.getCommitCommentCount(commit)}
                  commitPath={commitPath}
                  current={firstGroup && firstCommit}
                  first={firstGroup && firstCommit}
                  firstOfGroup={firstCommit}
                  last={lastGroup && lastCommit}
                  lastOfGroup={lastCommit}
                  onClick={onCommitClick}
                  selected={isSelected}
                />
              ),
            };
          }
        );

        return memo.concat(heading, commits);
      }, [])
    );

    if (this.getShowOfflineCommitMessage() && this.props.hasMore) {
      items = items.concat({
        defaultHeight: 138,
        children: <OlderCommitsOffline loadCommits={this.handleLoadMore} />,
      });
    } else {
      if (this.props.hasMore) {
        items = items.concat({
          height: 48,
          children: (
            <Centered>
              <Loading small />
            </Centered>
          ),
        });
      }

      items = items.concat({ height: 24, children: <div /> });
    }

    return items;
  };

  renderInnerListElement = ({
    children,
    ...rest
  }: {
    children: React.Node,
    style: { height: number },
  }) => (
    <div {...rest} className={style.innerList}>
      {children}
    </div>
  );

  render() {
    if (this.props.hasError) {
      return (
        <Centered flex>
          <div className={style.error}>
            <p className={style.note}>
              There was a problem loading commit history.
            </p>
            <Button onClick={this.handleReload}>Reload commit history</Button>
          </div>
        </Centered>
      );
    }

    return (
      <React.Fragment>
        {this.props.isLoading && !this.isBranchCommit() && (
          <MountProfiler
            id={
              isDesktopPlatform
                ? "DesktopLayerDetailCommitsLoading"
                : "WebLayerDetailCommitsLoading"
            }
            params={this.props.params}
            unstable_profileOnUnmount
          />
        )}
        <KeyboardNavigation defaultFocused={this.props.defaultFocused}>
          {(keyboardNavigation) => {
            const items = this.getItems(keyboardNavigation);

            const isCommitLoaded = (index) => {
              if (!this.props.hasMore) {
                return true;
              }

              return index < items.length - 2;
            };

            return (
              <InfiniteLoader
                isItemLoaded={isCommitLoaded}
                itemCount={items.length}
                loadMoreItems={this.handleLoadMore}
              >
                {(infiniteLoader) => {
                  return (
                    <VirtualizedList
                      className={classnames({
                        [style.offlineListBackground]:
                          this.getShowOfflineCommitMessage(),
                      })}
                      innerElementType={this.renderInnerListElement}
                      items={items}
                      onItemsRendered={infiniteLoader.onItemsRendered}
                      resizeProps={{
                        isDesktop: this.props.isDesktop,
                        isOffline: this.props.isOffline,
                        selected:
                          this.props.params.sha && this.props.params.sha,
                        ...keyboardNavigation,
                      }}
                      scrollRef={(ref) => {
                        keyboardNavigation.scrollRef(ref);
                        infiniteLoader.ref(ref);
                      }}
                    />
                  );
                }}
              </InfiniteLoader>
            );
          }}
        </KeyboardNavigation>
      </React.Fragment>
    );
  }
}

export default connector(CommitsList);
