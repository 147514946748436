// @flow
import classnames from "classnames";
import { isEmpty } from "lodash";
import * as React from "react";
import Dropzone from "react-dropzone";
import Button from "core/components/Button";
import Icon from "core/components/Icon";
import style from "./style.scss";

const acceptedFiles =
  "image/jpeg, image/png, image/gif, application/zip, text/plain";
const MAX_BYTES_SIZE = 20000000;

type Props = {
  isLoading: boolean,
  hasRejectedFiles: boolean,
  onChange: (files: File[]) => void,
  setRejectedFilesOn: () => void,
  setRejectedFilesOff: () => void,
};

export default function AttachmentsDropzone(props: Props) {
  const [isDragOver, setIsDragOver] = React.useState(false);

  const handleDrop = (files: File[]) => {
    if (!isEmpty(files)) {
      props.onChange(files);
    }
    setIsDragOver(false);
  };

  const onDropAccepted = (files: File[]) => {
    props.setRejectedFilesOff();
  };

  const onDropRejected = (files: File[]) => {
    props.setRejectedFilesOn();
  };

  const { isLoading, hasRejectedFiles } = props;

  return (
    <Dropzone
      className={classnames(style.dropzone, {
        [style.onDragOver]: isDragOver,
        [style.onRejected]: hasRejectedFiles,
        [style.disabled]: isLoading,
      })}
      onDrop={handleDrop}
      onDropAccepted={onDropAccepted}
      onDropRejected={onDropRejected}
      onDragOver={() => setIsDragOver(true)}
      onDragLeave={() => setIsDragOver(false)}
      accept={acceptedFiles}
      maxSize={MAX_BYTES_SIZE}
      disabled={isLoading}
      disableClick={isLoading}
      multiple={true}
    >
      <Icon large type="attachment" className={style.attachmentIcon} />
      <span>Drop files here or</span>
      <Button className={style.attachmentButton} disabled={isLoading}>
        Browse files…
      </Button>
    </Dropzone>
  );
}
