// @flow
import classnames from "classnames";
import * as React from "react";
import ReCaptcha from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import Button from "core/components/Button";
import ButtonLink from "core/components/ButtonLink";
import Input from "core/components/Input";
import Media from "core/components/Media";
import OnboardingContentSheet from "core/components/OnboardingContentSheet";
import OnboardingHeading from "core/components/OnboardingHeading";
import SerifText from "core/components/SerifText";
import Theme from "core/components/Theme";
import onboardingStyle from "core/components/onboarding.scss";
import { V3Link as Link } from "core/lib/router";
import { PasswordResetCreateRequest } from "core/requests/passwordReset";
import OnboardingPage from "web/components/Onboarding/Page";
import { RECAPTCHA_SITE_KEY } from "web/config";
import { signin } from "web/routeHelpers";
import style from "./style.scss";

export default function ForgotPassword() {
  const dispatch = useDispatch();

  const [email, setEmail] = React.useState("");

  const isSubmitting = useSelector((state) =>
    PasswordResetCreateRequest.isLoadingStrict(state, { email })
  );
  const success = useSelector((state) =>
    PasswordResetCreateRequest.success(state, { email })
  );
  const error = useSelector((state) =>
    PasswordResetCreateRequest.hasError(state, { email })
  );

  const forbidden = useSelector((state) =>
    PasswordResetCreateRequest.forbidden(state, { email })
  );

  const hasSubmitted = success || error || forbidden;

  const handleEmailChange = React.useCallback(
    (event: SyntheticInputEvent<>) => {
      setEmail(event.target.value);
    },
    []
  );

  const handleSubmit = React.useCallback(
    async (event: SyntheticInputEvent<>) => {
      event.preventDefault();

      if (isSubmitting) {
        return;
      }

      if (captchaRef.current) {
        const captchaValue = await captchaRef.current.executeAsync();

        dispatch(
          PasswordResetCreateRequest.perform({
            params: { email, captchaValue },
          })
        );

        if (captchaRef.current) {
          captchaRef.current.reset();
        }
      }
    },
    [dispatch, email, isSubmitting]
  );

  const handleReset = React.useCallback(() => {
    dispatch(PasswordResetCreateRequest.clear({ email }));
  }, [dispatch, email]);

  const captchaRef = React.useRef<ReCaptcha | null>(null);

  return (
    <Media mobile>
      {(mobile) => (
        <OnboardingPage>
          <OnboardingContentSheet className={onboardingStyle.mainContentSheet}>
            {hasSubmitted ? (
              forbidden ? (
                <div className={onboardingStyle.centeredText}>
                  <OnboardingHeading level="1">
                    Reset your <SerifText>password</SerifText>
                  </OnboardingHeading>
                  <div
                    className={classnames(
                      onboardingStyle.subHeading,
                      onboardingStyle.largeCopy
                    )}
                  >
                    You have not verified your email address. Please check your
                    inbox.
                  </div>
                </div>
              ) : (
                <div className={onboardingStyle.centeredText}>
                  <OnboardingHeading level="1">
                    Reset your <SerifText>password</SerifText>
                  </OnboardingHeading>
                  <div
                    className={classnames(
                      onboardingStyle.subHeading,
                      onboardingStyle.largeCopy
                    )}
                  >
                    If we found a matching account for <strong>{email}</strong>,
                    a password reset link will be landing in your email inbox.
                  </div>
                  <div
                    className={classnames(
                      style.goBack,
                      onboardingStyle.mediumCopy
                    )}
                  >
                    Go back to the{" "}
                    <Link
                      className={onboardingStyle.link}
                      onClick={handleReset}
                      to={signin()}
                    >
                      sign in page
                    </Link>
                  </div>
                  <div
                    className={classnames(
                      style.tryAgain,
                      onboardingStyle.mediumCopy
                    )}
                  >
                    Email didn’t arrive?{" "}
                    <ButtonLink onClick={handleReset}>Try again</ButtonLink>
                  </div>
                </div>
              )
            ) : (
              <React.Fragment>
                <div className={onboardingStyle.centeredText}>
                  <OnboardingHeading level="1">
                    Reset your <SerifText>password</SerifText>
                  </OnboardingHeading>
                  <div
                    className={classnames(
                      onboardingStyle.subHeading,
                      onboardingStyle.largeCopy
                    )}
                  >
                    Fill in your email address or username and we’ll email you a
                    link to reset your password.
                  </div>
                </div>
                <form className={onboardingStyle.form} onSubmit={handleSubmit}>
                  <div>
                    <Input
                      autoCapitalize="none"
                      autoCorrect="off"
                      className={onboardingStyle.input}
                      label={
                        <span className={onboardingStyle.inputLabel}>
                          Email or username
                        </span>
                      }
                      onChange={handleEmailChange}
                      placeholder="janedoe@domain.com or janedoe"
                      required
                    />
                  </div>
                  <Button
                    className={classnames(
                      onboardingStyle.button,
                      onboardingStyle.submit
                    )}
                    disabled={isSubmitting}
                    type="submit"
                    fullwidth
                    large
                    primary
                  >
                    {isSubmitting ? "Sending reset link…" : "Send reset link"}
                  </Button>
                  <Theme.Consumer>
                    {({ themeName }) => (
                      <ReCaptcha
                        badge={mobile ? "inline" : "bottomleft"}
                        className={classnames({
                          [style.mobileRecaptcha]: mobile,
                        })}
                        key={mobile}
                        ref={captchaRef}
                        sitekey={RECAPTCHA_SITE_KEY}
                        size="invisible"
                        theme={themeName === "dark" ? "dark" : "light"}
                      />
                    )}
                  </Theme.Consumer>
                </form>
              </React.Fragment>
            )}
          </OnboardingContentSheet>
        </OnboardingPage>
      )}
    </Media>
  );
}
