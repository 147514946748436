// @flow
import { entitiesReceived } from "core/actions/entities";
import { replace } from "core/lib/location";
import { getCurrentV3Location as getCurrentLocation } from "core/lib/router";
import defineRequest from "core/requests";
import { signInFailed } from "web/actions/login";
import { loadSession, sessionLoaded } from "web/actions/session";
import * as API from "web/api";
import * as URLs from "web/api/urls";
import { normalizeMe } from "web/schemas/me";
import { setToken } from "web/token";

export const ConfigurationFetchRequest = defineRequest<{}, {}>({
  id() {
    return URLs.fetchSession();
  },
  perform() {
    return API.fetchSession();
  },
  onSuccess(response, params, dispatch) {
    dispatch(entitiesReceived(normalizeMe(response).entities));
    dispatch(sessionLoaded(response));
  },
});

type SessionCreateRequestParams = {
  username: string,
  password: string,
};

export const SessionCreateRequestId = "createSession";

export const SessionCreateRequest = defineRequest<
  SessionCreateRequestParams,
  SessionCreateRequestParams,
>({
  id() {
    return SessionCreateRequestId;
  },
  perform({ username, password }) {
    return API.createSession(username, password);
  },
  async onSuccess(response, params, dispatch, getState) {
    setToken(response.access_token);
    await dispatch(loadSession());

    // Redirect back to the original location before login was required
    const location = getCurrentLocation();
    if (location.state && location.state.returnTo) {
      return replace(location.state.returnTo);
    }

    const { storedLocation } = getState().login;
    if (storedLocation) {
      return replace(storedLocation);
    }

    return replace("/");
  },
  onError(error, params, dispatch) {
    dispatch(signInFailed(error));
  },
});

export const SessionCreateWithoutRedirectRequest = defineRequest<
  SessionCreateRequestParams,
  SessionCreateRequestParams,
>({
  id() {
    return "createSessionWithoutRedirect";
  },
  perform({ username, password }) {
    return API.createSession(username, password);
  },
  onSuccess(response, params, dispatch) {
    setToken(response.access_token);
    dispatch(loadSession());
  },
  onError(error, params, dispatch) {
    dispatch(signInFailed(error));
  },
});

type SessionTokenRefreshRequestPerformParams = {
  username: string,
  password: string,
};

type SessionTokenRefreshRequestIdParams = {
  expiredToken: ?string,
};

export const SessionTokenRefreshRequest = defineRequest<
  SessionTokenRefreshRequestPerformParams,
  SessionTokenRefreshRequestIdParams,
>({
  id({ expiredToken }) {
    return `refreshSessionToken-${expiredToken || ""}`;
  },
  perform({ username, password }) {
    return API.createSession(username, password);
  },
  onSuccess(response, params, dispatch) {
    setToken(response.access_token);
    dispatch(loadSession());
  },
});
