// @flow
import classnames from "classnames";
import * as React from "react";
import Button from "core/components/Button";
import Flex from "core/components/Flex";
import window from "core/global/window";
import { isMac } from "core/lib/platform";
import { V3Link as Link } from "core/lib/router";
import { latestReleaseDownloadUrl } from "core/lib/urls";
import OnboardingPage from "web/components/OnboardingPage";
import type { Invitation } from "web/types";
import style from "./style.scss";

export type Props = {|
  heading?: React.Node,
  downloadUrl?: string,
  documentTitle?: string,
  children?: React.Node,
  message?: React.Node,
  invitation?: Invitation,
|};

type State = {|
  isError: boolean,
  isLoading: boolean,
|};

const isMacOS = isMac();

const defaultHeading = isMacOS ? (
  "You’re all set!"
) : (
  <React.Fragment>
    Organization created.
    <br />
    Next stop, the Mac.
  </React.Fragment>
);

class AutoDownloadApp extends React.Component<Props, State> {
  state = {
    isLoading: true,
    isError: false,
  };

  static defaultProps = {
    heading: defaultHeading,
    downloadUrl: latestReleaseDownloadUrl(),
  };

  componentDidMount() {
    const { downloadUrl } = this.props;
    if (isMacOS && downloadUrl) {
      window.location.href = downloadUrl;
    }
  }

  get message() {
    if (this.props.message) {
      return this.props.message;
    }

    if (isMacOS) {
      return (
        <React.Fragment>
          <p className={style.message}>
            <strong>The Abstract macOS app is currently downloading.</strong>
          </p>
          <p className={style.message}>
            Once installed, we’ll guide you through setting up your Projects and
            Files.
          </p>
          <p className={classnames(style.message, style.small)}>
            You can now close this browser tab or{" "}
            <Link to="/">check out the Abstract web app</Link>
          </p>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <p className={style.message}>
          <strong>Abstract currently only supports macOS to edit files.</strong>
        </p>
        <p className={style.message}>
          Continue to the Abstract web app to set up your team and projects.
        </p>
        <Flex justify="center">
          <Button component="a" href="/" large primary className={style.button}>
            Continue to web app
          </Button>
        </Flex>
      </React.Fragment>
    );
  }

  get footerLink() {
    const { downloadUrl } = this.props;
    if (isMacOS) {
      return (
        <React.Fragment>
          Not seeing the Abstract macOS app?{" "}
          <a href={downloadUrl}>Click to download</a>
        </React.Fragment>
      );
    }
    return false;
  }

  render() {
    const icon = isMacOS ? "download" : "checkmark";
    return (
      <OnboardingPage
        icon={icon}
        heading={this.props.heading}
        documentTitle={this.props.documentTitle}
        invitation={this.props.invitation}
        footerLink={this.footerLink}
        showSplatter
      >
        {this.message}
        {this.props.children}
      </OnboardingPage>
    );
  }
}

export default AutoDownloadApp;
