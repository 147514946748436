// @flow
import * as React from "react";
import Button from "core/components/Button";
import Note from "core/components/Note";
import PlaceholderText from "core/components/PlaceholderText";
import { isMac } from "core/lib/platform";
import { latestReleaseDownloadUrl } from "core/lib/urls";
import OnboardingPage from "web/components/OnboardingPage";
import type { Invitation, AppRelease } from "web/types";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  heading?: string,
  documentTitle?: string,
  children?: React.Node,
  message?: React.Node,
  invitation?: Invitation,
|};

export type DispatchProps = {|
  fetchLatestRelease: (
    onSuccess: (AppRelease) => void,
    onError: (Error) => void
  ) => void,
|};

export type Props = {|
  ...OwnProps,
  ...DispatchProps,
|};

type State = {|
  isError: boolean,
  isLoading: boolean,
  release: ?AppRelease,
|};

class DownloadApp extends React.Component<Props, State> {
  state = {
    isLoading: true,
    isError: false,
    release: undefined,
  };

  static defaultProps = {
    heading: "Download",
  };

  componentDidMount() {
    this.props.fetchLatestRelease(
      (response) => {
        this.setState({
          isLoading: false,
          release: response,
        });
      },
      () => {
        this.setState({
          isError: true,
          isLoading: false,
        });
      }
    );
  }

  get message() {
    if (this.props.message) {
      return this.props.message;
    }

    if (isMac()) {
      return (
        <p className={style.message}>
          Download the macOS desktop app to start designing with Abstract.
        </p>
      );
    }

    return (
      <p className={style.message}>
        The Abstract desktop app is currently only available for macOS.
      </p>
    );
  }

  renderButton() {
    const { isLoading, isError, release } = this.state;

    if (!isMac()) {
      return (
        <Button
          component="a"
          href="/"
          large
          primary
          fullwidth
          className={style.button}
        >
          Open the web app
        </Button>
      );
    }

    return (
      <div className={style.downloadButton}>
        <div className={style.buttonInfo}>
          <span className={style.label}>Abstract for macOS</span>
          {isLoading ? (
            <Note component="span">
              <PlaceholderText size="s" />
            </Note>
          ) : isError ? (
            <Note component="span">Latest version · ~120Mb</Note>
          ) : (
            release && (
              <Note component="span">
                Version {release.version} · {release.formattedFileSize}
              </Note>
            )
          )}
        </div>
        <Button
          marketingTriggerClassName="gtm-download-app"
          href={latestReleaseDownloadUrl()}
          component="a"
          large
          primary
          fullwidth
        >
          Download
        </Button>
      </div>
    );
  }

  render() {
    return (
      <OnboardingPage
        icon={"download"}
        heading={this.props.heading}
        documentTitle={this.props.documentTitle}
        invitation={this.props.invitation}
      >
        {this.message}
        {this.renderButton()}
        {this.props.children}
      </OnboardingPage>
    );
  }
}

export default connector(DownloadApp);
