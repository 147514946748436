// @flow
import classnames from "classnames";
import * as React from "react";
import AddItemsToCollectionButton from "core/components/AddItemsToCollectionButton";
import ButtonUnstyled from "core/components/ButtonUnstyled";
import CollectionLayerThumbnail, {
  helperClass,
} from "core/components/CollectionLayerThumbnail";
import DialogForm from "core/components/DialogForm";
import NoCollectionLayers from "core/components/Empty/NoCollectionLayers";
import Sortable from "core/components/Sortable";
import Thumbnails from "core/components/Thumbnails";
import type {
  Collection,
  CollectionItem,
  ShareLink,
  Branch,
  Move,
} from "core/types";
import CollectionText, { SortableCollectionText } from "./CollectionText";
import connector from "./connector";
import image from "./images/plus.svg";
import style from "./style.scss";

export type OwnProps = {|
  disableEmptyState?: boolean,
  collection: Collection,
  collectionItems: CollectionItem[],
  shareLink?: ShareLink,
  branch: ?Branch,
  isMobile?: boolean,
  thumbnailZoom?: number,
  editable?: boolean,
  isMousePointer?: boolean,
  small?: boolean,
  presenting?: boolean,
  className?: string,
  container: ?HTMLDivElement,
  onClickCollectionLayer?: (collectionLayerId: string) => void,
  addDraftTextItemIndex?: number,
  handleAddDraftCollectionText: (index?: number) => void,
  handleRemoveDraftCollectionText: () => void,
|};

export type StateProps = {|
  isOnline: boolean,
  layerCommentCounts: { [layerId: string]: number },
  canInspect: boolean,
  canAccessProject: boolean,
  shareLinkId: ?string,
|};

export type DispatchProps = {|
  onRemoveLayer?: (
    collectionLayerId: string,
    onNeedsConfirmation?: () => void
  ) => void,
  onChangeAutoUpdateLayer?: (collectionLayerId: string, value: boolean) => void,
  handlePresentLayer?: (collectionLayerId: string) => void,
  onMoveLayer?: (
    collectionItems: CollectionItem[]
  ) => (collectionItem: CollectionItem, move: Move) => Promise<void>,
  onCreateCollectionText: (textContent: string, onSuccess?: () => void) => void,
  onUpdateCollectionText: (id: string, textContent: string) => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

function DraftTextItem(props: Props) {
  return (
    <CollectionText
      className={style.collectionText}
      textContent=""
      editable={!!props.editable && props.isOnline}
      onSave={(textContent) => {
        props.onCreateCollectionText(textContent, () => {
          props.handleRemoveDraftCollectionText();
        });
      }}
      handleRemoveText={props.handleRemoveDraftCollectionText}
      useDragHandle={false}
      large
    />
  );
}

function CollectionItems(props: Props) {
  const [removeCollectionLayerId, setRemoveCollectionLayerId] =
    React.useState<?string>(undefined);

  const [dragIndex, setDragIndex] = React.useState(-1);
  const [dropIndex, setDropIndex] = React.useState(-1);

  const removeConfirm = () => setRemoveCollectionLayerId(undefined);

  const handleRemoveLayer = (collectionLayerId: string) => {
    if (props.onRemoveLayer) {
      props.onRemoveLayer(collectionLayerId, () =>
        setRemoveCollectionLayerId(collectionLayerId)
      );
    }
  };

  const handleRemoveConfirmedLayer = () => {
    if (!removeCollectionLayerId || !props.onRemoveLayer) {
      return;
    }

    props.onRemoveLayer(removeCollectionLayerId);
    removeConfirm();
  };

  const handleClickCollectionLayer = (collectionLayerId: string) => {
    if (props.onClickCollectionLayer) {
      props.onClickCollectionLayer(collectionLayerId);
    }
  };

  const handleUpdateBeforeSortStart = ({ index }) => {
    setDragIndex(index);
  };
  const handleSortOver = ({ newIndex }) => {
    setDropIndex(newIndex);
  };

  const handleSortEnd = async (collectionItem, { oldIndex, newIndex }) => {
    setDragIndex(-1);
    setDropIndex(-1);

    if (oldIndex !== newIndex) {
      return props.onMoveLayer
        ? props.onMoveLayer(props.collectionItems)(collectionItem, {
            oldIndex,
            newIndex,
          })
        : undefined;
    }
  };

  if (props.collectionItems.length === 0 && !props.disableEmptyState) {
    return <NoCollectionLayers />;
  }

  return (
    <React.Fragment>
      <Thumbnails zoom={props.thumbnailZoom}>
        {({ className }) => {
          return (
            <Sortable
              items={props.collectionItems}
              renderList={(items) => (
                <div className={classnames(style.container, props.className)}>
                  {items}
                  {props.addDraftTextItemIndex &&
                  props.addDraftTextItemIndex === -1 ? (
                    <DraftTextItem {...props} />
                  ) : null}
                  {props.editable && (
                    <AddItemsToCollectionButton
                      collection={props.collection}
                      branch={props.branch}
                      button={(buttonProps) => (
                        <div className={className}>
                          <ButtonUnstyled
                            {...buttonProps}
                            className={style.addButton}
                            qaSelector="addItemToCollectionButton"
                          >
                            <div className={style.addButtonInner}>
                              <img src={image} alt="plus" />
                            </div>
                          </ButtonUnstyled>
                          <p className={style.addButtonText}>Add item</p>
                        </div>
                      )}
                    />
                  )}
                </div>
              )}
              renderListItem={(collectionItem, index) => {
                const isActive = dropIndex !== dragIndex && dropIndex === index;

                if (collectionItem.kind === "layer") {
                  return (
                    <React.Fragment key={collectionItem.id}>
                      {index === props.addDraftTextItemIndex ? (
                        <DraftTextItem {...props} />
                      ) : null}
                      <CollectionLayerThumbnail
                        index={index}
                        onClick={handleClickCollectionLayer}
                        className={classnames(
                          className,
                          style.layerDropTarget,
                          {
                            [style.toggleDragTargetBefore]:
                              isActive && dragIndex > dropIndex,
                            [style.toggleDragTargetAfter]:
                              isActive && dragIndex < dropIndex,
                          }
                        )}
                        collectionLayer={collectionItem}
                        projectId={props.collection.projectId}
                        branchId={props.collection.branchId}
                        shareLinkId={props.shareLinkId}
                        isMousePointer={props.isMousePointer}
                        editable={props.editable && props.isOnline}
                        disabled={!props.editable}
                        isMobile={props.isMobile}
                        canInspect={props.canInspect && !props.isMobile}
                        onClickRemove={handleRemoveLayer}
                        onClickPresentation={props.handlePresentLayer}
                        onChangeAutoUpdate={props.onChangeAutoUpdateLayer}
                        canAccessProject={props.canAccessProject}
                        layerCommentCounts={props.layerCommentCounts}
                        showPresentButton
                        disableActions={dragIndex !== -1}
                        qaSelector={`collection-layer-thumbnail-${index}`}
                      />
                    </React.Fragment>
                  );
                }
                if (collectionItem.kind === "text") {
                  const editable = props.editable && props.isOnline;

                  const inputShare = {
                    kind: "collection",
                    descriptor: {
                      projectId: props.collection.projectId,
                      branchId: props.collection.branchId,
                      collectionId: props.collection.id,
                    },
                    options: {
                      sectionId: collectionItem.id,
                    },
                  };

                  return (
                    <React.Fragment key={collectionItem.id}>
                      {index === props.addDraftTextItemIndex ? (
                        <DraftTextItem {...props} />
                      ) : null}
                      <SortableCollectionText
                        index={index}
                        sectionId={collectionItem.id}
                        className={classnames(
                          style.collectionText,
                          style.textDropTarget,
                          {
                            [style.toggleDragTargetBefore]:
                              isActive && dragIndex > dropIndex,
                            [style.toggleDragTargetAfter]:
                              isActive && dragIndex < dropIndex,
                          }
                        )}
                        textContent={collectionItem.textContent}
                        editable={editable}
                        disableActions={dragIndex !== -1}
                        onSave={(textContent) => {
                          if (collectionItem.textContent !== textContent) {
                            props.onUpdateCollectionText(
                              collectionItem.id,
                              textContent
                            );
                          }
                        }}
                        handleRemoveText={() => {
                          props.onRemoveLayer &&
                            props.onRemoveLayer(collectionItem.id);
                        }}
                        useDragHandle={!props.isMobile && editable}
                        forceShowBackground={dragIndex === index}
                        inputShare={inputShare}
                        disabled={!props.editable}
                        large
                      />
                    </React.Fragment>
                  );
                }

                return null;
              }}
              helperClass={helperClass}
              container={props.container}
              updateBeforeSortStart={handleUpdateBeforeSortStart}
              onSort={handleSortEnd}
              onSortOver={handleSortOver}
              distance={props.isMobile ? undefined : 5} // distance can not be used in conjunction with pressDelay
              pressDelay={props.isMobile ? 100 : 0}
              axis="xy"
              lockOffset="50%"
              useDragHandle={!props.isMobile}
              disableDefaultAnimations
            />
          );
        }}
      </Thumbnails>
      <DialogForm
        dangerous
        isOpen={!!removeCollectionLayerId}
        onClose={removeConfirm}
        onRequestClose={removeConfirm}
        title="Confirm removal"
        onSubmit={handleRemoveConfirmedLayer}
        primaryButton="Remove from collection"
      >
        <p>Are you sure you want to remove this layer from the collection?</p>
      </DialogForm>
    </React.Fragment>
  );
}

export default connector(CollectionItems);
