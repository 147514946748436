// @flow
import { isEqual, forEach } from "lodash";
import { static as Immutable } from "seamless-immutable";
import type { Action, Entities } from "../types";

type State = {
  [key: string]: Object,
  organizations?: void,
  notifications?: void,
  projects?: void,
  branches?: void,
  commits?: void,
  stars?: void,
  users?: void,
  organizationMetrics?: void,
  userMetrics?: void,
  objectViews?: void,
};

const DEFAULT_STATE = Immutable.from({});
const IGNORED = {
  organizations: true,
  notifications: true,
  projects: true,
  branches: true,
  commits: true,
  stars: true,
  users: true,
  organizationMetrics: true,
  userMetrics: true,
};

function mergeEntities(
  state: State,
  newEntities: Entities,
  options: { patch: boolean } = { patch: false }
): State {
  let newState = state;

  forEach(newEntities, (entities, type) => {
    if (IGNORED[type]) {
      return;
    }
    const changedEntities = {};

    forEach(entities, (entity, id) => {
      const existing = state[type] ? state[type][id] : undefined;
      if (!isEqual(existing, entity)) {
        if (options.patch) {
          changedEntities[id] = { ...existing, ...entity };
        } else {
          changedEntities[id] = entity;
        }
      }
    });

    newState = Immutable.set(
      newState,
      type,
      state[type]
        ? Immutable.merge(state[type], changedEntities)
        : changedEntities
    );
  });

  return newState;
}

export default function (state: State = DEFAULT_STATE, action: Action): State {
  switch (action.type) {
    case "core/ENTITIES_RECEIVED": {
      return mergeEntities(state, action.payload);
    }
    case "core/ENTITIES_PATCHED": {
      return mergeEntities(state, action.payload, { patch: true });
    }
    case "core/ENTITIES_REPLACED": {
      const { type, ids, entities } = action.payload;
      const newState = Immutable.set(
        state,
        type,
        Immutable.without(state[type] || {}, ids)
      );

      return mergeEntities(newState, { [type]: entities });
    }
    case "core/ENTITIES_DELETED": {
      const { payload } = action;
      let newState = state;
      Object.keys(payload).forEach((type) => {
        const deletedIds = payload[type];
        const current = newState[type];
        if (!current) {
          return;
        }

        newState = Immutable.set(
          newState,
          type,
          Immutable.without(current, deletedIds)
        );
      });
      return newState;
    }
    case "ONLINE": {
      return Immutable.without(state, ["memberships", "projectMemberships"]);
    }
    case "SIGNED_OUT": {
      return DEFAULT_STATE;
    }
    default:
      return state;
  }
}
