// @flow
import groupBy from "lodash/groupBy";
import * as React from "react";
import BranchListItem from "core/components/BranchListItem";
import List from "core/components/List";
import type { Project, BranchHierarchy, ReviewRequest } from "core/types";

type Props = {
  project: Project,
  branches: BranchHierarchy[],
  reviewRequests?: ReviewRequest[],
  mobile?: boolean,
  flat?: boolean,
  hasHierarchy?: boolean,
  branchPath: (projectId: string, branchId: string) => string,
};

export default function BranchList({
  project,
  branches,
  mobile,
  branchPath,
  hasHierarchy,
  reviewRequests = [],
  ...rest
}: Props) {
  const reviewRequestsByBranchId = groupBy(reviewRequests, "branchId");
  return (
    <List mobile={mobile} {...rest}>
      {branches.map((item) => (
        <BranchListItem
          key={item.branch.id}
          mobile={mobile}
          project={project}
          branch={item.branch}
          reviewRequests={reviewRequestsByBranchId[item.branch.id]}
          to={branchPath(project.id, item.branch.id)}
          level={hasHierarchy ? item.level : 0}
        />
      ))}
    </List>
  );
}
