// @flow
import React from "react";
import { RegionDropdown } from "react-country-region-selector";
import InputWrapper from "core/components/InputWrapper";
import style from "core/components/Select/style.scss";
import regionStyle from "./style.scss";

type Props = {
  country?: string,
  wrapperClass?: string,
  label?: string,
  error?: ?(string | string[]),
};

export default function InputSelectRegion(props: Props) {
  const classes = [style.select, regionStyle.custom_dark].join(" ");
  return (
    <InputWrapper
      className={props.wrapperClass}
      label={props.label}
      error={props.error}
    >
      <RegionDropdown
        className={classes}
        countryValueType="short"
        valueType="short"
        {...props}
      />
    </InputWrapper>
  );
}
