// @flow
import { schema, normalize } from "normalizr";
import { orgReporting, userReporting } from "core/schema";
import { envelopeSchema } from "core/schemas/envelope";

const orgMetricsFetchResponseSchema = envelopeSchema(
  new schema.Object({
    organizationMetric: orgReporting,
    userMetrics: userReporting,
  })
);

export function normalizeOrganizationMetricsFetchResponse(response: Object) {
  return normalize(response, orgMetricsFetchResponseSchema);
}
