// @flow
import pluralize from "pluralize";
import * as React from "react";
import DialogForm from "core/components/DialogForm";
import FormNotice from "core/components/FormNotice";
import InputCheckbox from "core/components/InputCheckbox";
import ProcessingFilesList from "core/components/ProcessingFilesList";
import type { Branch } from "core/types";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  files: string[],
  filePathToIsLibrary: { [path: string]: boolean },
  isCreating: boolean,
  projectId: string,
  branchId: string,
|};

export type StateProps = {|
  branch: ?Branch,
|};

export type DispatchProps = {|
  dismissDialog: () => void,
  changeDisplay: (value: boolean) => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

class ImportComplete extends React.Component<Props> {
  toggleFilesReminder = (event: SyntheticInputEvent<>) => {
    this.props.changeDisplay(!event.target.checked);
  };

  render() {
    const { isCreating, branch } = this.props;
    const fileCount = this.props.files.length;
    return (
      <DialogForm
        title={isCreating ? "File created" : "Import complete"}
        primaryButton="I Understand"
        onSubmit={this.props.dismissDialog}
        onClose={this.props.dismissDialog}
        isOpen
      >
        {isCreating ? (
          <p>
            Your file has been created
            {branch ? (
              <span>
                {" on "}
                <span className={style.branch}>{branch.name}</span>
              </span>
            ) : null}
            .
          </p>
        ) : (
          <p>
            {fileCount} {pluralize("file", fileCount)}{" "}
            {fileCount === 1 ? "has" : "have"} been imported
            {branch ? ` to the Branch "${branch.name}"` : ""}.
          </p>
        )}
        {!isCreating && (
          <ProcessingFilesList
            files={this.props.files}
            filePathToIsLibrary={this.props.filePathToIsLibrary}
            complete={fileCount}
          />
        )}
        <FormNotice
          className={style.notice}
          type="allgood"
          heading={isCreating ? "Always open files from Abstract" : "Reminder"}
          body={
            isCreating
              ? "Always open files from Abstract and commit explorations early to enable version control of your work."
              : "Abstract manages your files for you. You should always launch your files from Abstract to commit your work."
          }
          alignContent
        />
        <InputCheckbox
          label="Don’t show me this confirmation step again"
          labelClass={style.label}
          wrapperClass={style.checkbox}
          onChange={this.toggleFilesReminder}
        />
      </DialogForm>
    );
  }
}

export default connector(ImportComplete);
