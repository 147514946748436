// @flow
import * as React from "react";
import type { MultiSelectedProps } from "core/components/CollectionMultiSelect";
import withMultiSelect from "core/components/CollectionMultiSelect/withMultiSelect";
import HeaderBottom from "core/components/FilePreviews/FilePreviewsFilters";
import type {
  File,
  Page,
  Layer,
  Branch,
  Project,
  Library as TLibrary,
  ChangeStatusCounts,
} from "core/types";
import HeaderTop from "./Top";
import style from "./style.scss";

type Props = MultiSelectedProps & {
  file: File,
  page?: Page,
  library: ?TLibrary,
  branch: Branch,
  project: ?Project,
  layers?: Layer[],
  layerStatusCounts?: ChangeStatusCounts,
  projectId: string,
  branchId: string,
  disabled?: boolean,
  hasMore?: boolean,
  isChangedFile?: boolean,
  onClickFilter: (filter?: string) => void,
  onFilter: (event: SyntheticInputEvent<>) => void,
  onShowMenu?: (event: SyntheticEvent<>) => void,
  openFileButton: React.Element<*>,
  query?: string,
  filter?: string,
  commitPath?: string,
  qaSelector?: string,
};

function FileHeader(props: Props) {
  return (
    <div>
      <HeaderTop
        page={props.page}
        file={props.file}
        branchId={props.branch.id}
        layers={props.layers}
        library={props.library}
        project={props.project}
        disabled={props.disabled}
        hasMore={props.hasMore}
        filter={props.filter}
        openFileButton={props.openFileButton}
        commitPath={props.commitPath}
        onShowMenu={props.onShowMenu}
        onSelectEntities={props.onSelectEntities}
        isSelecting={props.isSelecting}
        layerSelected={props.layerSelected}
        pageSelected={props.pageSelected}
        pagePartiallySelected={props.pagePartiallySelected}
        fileSelected={props.fileSelected}
        filePartiallySelected={props.filePartiallySelected}
        qaSelector={props.qaSelector}
      />
      <HeaderBottom
        className={style.filters}
        branch={props.branch}
        disabled={props.disabled}
        isChangedFile={props.isChangedFile}
        statusCounts={props.layerStatusCounts}
        onClickFilter={props.onClickFilter}
        onFilter={props.onFilter}
        query={props.query}
        filter={props.filter}
      />
    </div>
  );
}

export default withMultiSelect(FileHeader);
