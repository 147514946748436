// @flow
import React, { Component } from "react";
import IntegrationSettingsMenu from "web/components/IntegrationSettingsMenu";
import ProfileSettingsMenu from "web/components/ProfileSettingsMenu";
import type { Organization, Policy, User } from "web/types";

type Props = {
  account?: User,
  accountFormValues?: $Shape<User>,
  page?: string,
  onClose?: () => void,
  projectId?: string,
  organizationId?: string,
  integrationId?: string,
  integrationKind?: string,
  isUsernameOrganization?: boolean,
  usernameOrganization?: Organization,
  organization?: Organization,
  policy?: Policy,
  canManageProfile?: boolean,
  canManageEmail?: boolean,
  canCreateAccessTokens?: boolean,
  canManageOAuthApplications?: boolean,
  organizationPolicy?: Policy,
};

export default class SettingsMenu extends Component<Props> {
  static defaultProps = {
    location: {},
  };

  render() {
    const { page, ...props } = this.props;

    switch (page) {
      case "account":
        return <ProfileSettingsMenu {...props} />;
      case "integrations":
        return <IntegrationSettingsMenu {...props} />;
      default:
        return null;
    }
  }
}
