// @flow
import { connect } from "react-redux";
import { withData } from "core/components/DataLoader";
import createConnector from "core/lib/createConnector";
import { BranchFetchRequest } from "core/requests/branches";
import { CollectionFetchRequest } from "core/requests/collections";
import {
  getCollectionLayer,
  getShaForCollectionLayer,
} from "core/selectors/collections";
import type { State, Dispatch } from "core/types";
import { loadProject } from "web/actions/projects";
import type { Props, OwnProps, StateProps, DispatchProps } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const collectionLayerId = props.location.query.collectionLayerId || "";
  const collectionLayer = getCollectionLayer(state, { collectionLayerId });

  return {
    layerId: collectionLayer ? collectionLayer.layerId : null,
    fileId: collectionLayer ? collectionLayer.fileId : null,
    sha:
      props.location.query.sha || // query override
      props.shareLink.descriptor.sha || // layer
      getShaForCollectionLayer(state, { collectionLayerId }), // collection layer
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { projectId, branchId, collectionId } = props.params;

  return {
    onLoad: () => {
      dispatch(
        CollectionFetchRequest.perform({
          force: false,
          params: { projectId, collectionId },
        })
      );
      dispatch(BranchFetchRequest.perform({ params: { projectId, branchId } }));
      dispatch(loadProject(projectId));
    },
  };
}

export default createConnector<Props, OwnProps>(
  connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch>(
    mapStateToProps,
    mapDispatchToProps
  ),
  withData((props: OwnProps) => props.params)
);
