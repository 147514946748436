// @flow
import idx from "idx";
import { connect } from "react-redux";
import { setPaginationTotal } from "core/actions/paginationTotals";
import { loadPaginatedProjectTeams } from "core/actions/teamProjectMemberships";
import { withData } from "core/components/DataLoader";
import connectStorage from "core/hocs/connectStorage";
import createConnector from "core/lib/createConnector";
import { DEFAULT_ORGANIZATIONTEAMS_VIEW } from "core/lib/lists";
import { PROJECT_TEAMS_LIMIT } from "core/lib/teams";
import { SubscriptionFetchRequest } from "core/requests/seats";
import {
  TeamProjectMembershipsFetchAllRequest,
  TeamProjectMembershipsRequest,
  PaginatedTeamProjectMembershipsRequest,
} from "core/requests/teamProjectMemberships";
import { enterpriseTeamsEnabled } from "core/selectors/features";
import { getMembershipEntities } from "core/selectors/memberships";
import { getOrganization } from "core/selectors/organizations";
import { getPaginationTotal } from "core/selectors/paginationTotals";
import {
  getProjectPolicy,
  getOrganizationPolicy,
} from "core/selectors/policies";
import { getProjectMembershipEntities } from "core/selectors/projectMemberships";
import { getProject } from "core/selectors/projects";
import { getSubscription } from "core/selectors/subscriptions";
import { getTeamsForProject } from "core/selectors/teams";
import type {
  State,
  Dispatch,
  ViewType,
  ReactRouterLocation,
  RoleFilter,
} from "core/types";

import type {
  Props,
  OwnProps,
  StateProps,
  StorageProps,
  DispatchProps,
  PropsWithoutStorage,
} from "./";

function roleFilter(location: ReactRouterLocation): ?RoleFilter {
  switch (location.query.role) {
    case "owner":
    case "admin":
    case "member":
    case "guest":
    case "contributor":
    case "viewer":
      return location.query.role;
    default:
      return undefined;
  }
}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { projectId } = props.params;
  const project = getProject(state, { projectId });
  const organizationId = project ? project.organizationId : "";
  const organization = organizationId
    ? getOrganization(state, { organizationId })
    : null;
  const searchFilter = idx(props.location, (_) => _.query.s) || "";
  const subscription = organizationId
    ? getSubscription(state, organizationId)
    : null;

  const policy = getProjectPolicy(state, { projectId });

  const projectMembershipError = TeamProjectMembershipsRequest.hasError(state, {
    projectId,
  });

  const isLoading =
    SubscriptionFetchRequest.isLoading(state, {
      organizationId,
    }) || TeamProjectMembershipsRequest.isLoading(state, { projectId });

  const projectTeams = getTeamsForProject(state, {
    projectId,
    search: searchFilter,
  });
  const organizationPolicy = getOrganizationPolicy(state, {
    organizationId: organizationId || "",
  });
  const projectTeamsPaginationTotal = getPaginationTotal(
    state,
    `${projectId}-Teams`
  );

  const teamsFeatureFlag = enterpriseTeamsEnabled(state, {
    organizationId,
  });

  const isProjectTeamsExpanding = projectTeamsPaginationTotal
    ? PaginatedTeamProjectMembershipsRequest.isLoadingStrict(state, {
        projectId,
        offset: PROJECT_TEAMS_LIMIT,
        limit: projectTeamsPaginationTotal - PROJECT_TEAMS_LIMIT,
      })
    : false;

  return {
    canAddContributor: policy.addContributor,
    canAddGuest: policy.addGuest,
    canAddMember: policy.addMember,
    canManageUsers: policy.removeMember,
    canManageBilling: organizationPolicy.manageBilling,
    projectMembershipError,
    canUseTeams: organizationPolicy.showTeams && teamsFeatureFlag,
    isLoading,
    isProjectTeamsExpanding,
    organization,
    organizationId,
    organizationMemberships: getMembershipEntities(state),
    projectMemberships: getProjectMembershipEntities(state),
    project,
    projectTeams,
    projectTeamsPaginationTotal,
    roleFilter: roleFilter(props.location),
    searchFilter,
    subscription,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { projectId } = props.params;
  return {
    onLoad() {
      dispatch((dispatch, getState) => {
        const state = getState();

        dispatch(
          TeamProjectMembershipsFetchAllRequest.perform({
            params: {
              projectId,
            },
            onSuccess: (response) => {
              dispatch(
                setPaginationTotal(`${projectId}-Teams`, response.meta.total)
              );
            },
            force: false,
          })
        );

        const project = getProject(state, { projectId });
        if (!project) {
          return;
        }
        const { organizationId } = project;
        const policy = getProjectPolicy(state, { projectId });
        const subscriptionFetchSuccess = SubscriptionFetchRequest.success(
          state,
          { organizationId }
        );

        if (policy.removeMember && !subscriptionFetchSuccess) {
          dispatch(
            SubscriptionFetchRequest.perform({ params: { organizationId } })
          );
        }
      });
    },
    loadRemainingProjectTeams: (offset, limit) => {
      dispatch(loadPaginatedProjectTeams(projectId, { offset, limit }));
    },
  };
}

function mapStorageToProps(storage, props: OwnProps): StorageProps {
  const storageId = `Project-${props.tab}-${props.params.projectId}`;
  const previous = storage.getItem(storageId) || {};

  return {
    viewType: previous.peopleView || DEFAULT_ORGANIZATIONTEAMS_VIEW,
    onChangeViewType: (peopleView: ViewType) => {
      storage.setItem(storageId, { ...previous, peopleView });
    },
  };
}

export default createConnector<Props, OwnProps>(
  (Component) => connectStorage(Component, mapStorageToProps),
  connect<
    PropsWithoutStorage,
    OwnProps,
    StateProps,
    DispatchProps,
    State,
    Dispatch,
  >(mapStateToProps, mapDispatchToProps),
  withData((props) => props.params)
);
