// @flow
import classnames from "classnames";
import * as React from "react";
import { V3Link as Link } from "core/lib/router";
import style from "./style.scss";

export default function Tab({
  activeClassName,
  children,
  className,
  label,
  qaSelector,
  selected,
  ...rest
}: {
  activeClassName?: string,
  children?: React.Node,
  label?: string,
  className?: string,
  qaSelector?: string,
  selected?: boolean,
}) {
  const classes = classnames(className, style.tab, {
    [style.selected]: selected,
  });

  return (
    <Link
      {...rest}
      className={classes}
      activeClassName={
        selected === undefined
          ? classnames(style.selected, activeClassName)
          : null
      }
      data-qa={qaSelector}
    >
      <span className={style.inner}>{children || label}</span>
    </Link>
  );
}
