// @flow
import * as React from "react";
import BranchStatusBadges from "core/components/BranchStatusBadges";
import PageTitle from "core/components/PageTitle";
import PageTitleNavigationLink from "core/components/PageTitleNavigationLink";
import { V3Link as Link } from "core/lib/router";
import { branchPath } from "core/lib/routes";
import * as Branch from "core/models/branch";
import type { Branch as TBranch, BranchReview, User } from "core/types";
import style from "./style.scss";

type Props = {|
  projectId: string,
  branch: TBranch,
  branchReview?: ?BranchReview,
  parent: ?TBranch,
  user: ?User,
  canUpdateBranch: boolean,
  projectColor?: string,
  onChangeBranchStatus: (status: string) => void,
  actions?: React.Node,
  projectName: ?string,
  parentBranches: TBranch[],
  canShowDocumentationTab: boolean,
  branchStatusUpdating: boolean,
|};

function getWidthFromRef(ref: ?HTMLElement): number {
  if (!ref) {
    return 0;
  }

  const bounds = ref.getBoundingClientRect();
  if (!bounds) {
    return 0;
  }
  return bounds.width;
}

type State = {
  small: boolean,
};

export default class BranchHeader extends React.Component<Props, State> {
  containerRef: ?HTMLElement;
  branchesRef: ?HTMLElement;
  branchNameRef: ?HTMLElement;
  actionsRef: ?HTMLElement;
  state = { small: false };

  componentDidMount() {
    this.handleResize();
  }

  handleResize = () => {
    // The refs will not be available the first time that the resize detector
    // fires `onResize`. Instead, we call this function manually on
    // `componentDidMount` so that we know the refs will be there then. After
    // mount, we rely on the resize detector to tell us when the container size
    // changes.
    if (!this.containerRef) {
      return;
    }
    const padding = 16; // $unit*2
    const containerWidth = getWidthFromRef(this.containerRef);
    const branchesWidth = getWidthFromRef(this.branchesRef);
    const branchNameWidth = getWidthFromRef(this.branchNameRef);
    const actionsWidth = getWidthFromRef(this.actionsRef);
    const contentWidth =
      branchesWidth + branchNameWidth + actionsWidth + padding;
    const small = contentWidth > containerWidth;

    this.setState((prevState) => {
      if (small === this.state.small) {
        return;
      }
      return { small };
    });
  };

  getBreadCrumbs = () => {
    const { projectId, projectName, parentBranches } = this.props;

    return [
      <Link to={`/projects/${projectId}`}>{projectName}</Link>,
      <Link to={`/projects/${projectId}/branches`}>Branches</Link>,
      ...parentBranches.map(({ name, id }) => (
        <Link to={`/projects/${projectId}/branches/${id}`}>{name}</Link>
      )),
    ];
  };

  render = () => {
    const {
      projectId,
      branch,
      actions,
      user,
      onChangeBranchStatus,
      canUpdateBranch,
      branchReview,
    } = this.props;

    const navLinks = [
      <PageTitleNavigationLink
        onlyActiveOnIndex
        to={branchPath(projectId, branch.id)}
        icon="branch"
        qaSelector="navigation-link-overview"
      >
        Overview
      </PageTitleNavigationLink>,
      <PageTitleNavigationLink
        to={branchPath(projectId, branch.id, "commits")}
        icon="commit"
        qaSelector="navigation-link-commits"
      >
        Commits
      </PageTitleNavigationLink>,
      <PageTitleNavigationLink
        to={branchPath(projectId, branch.id, "files")}
        icon="file"
        qaSelector="navigation-link-files"
      >
        Files
      </PageTitleNavigationLink>,
    ];

    const isMaster = Branch.isMaster(branch);

    return (
      <PageTitle
        actions={actions}
        breadcrumb={this.getBreadCrumbs()}
        navigation={navLinks}
        title={branch.name}
        titleSize="small"
        titleAvatarUserId={user ? user.id : undefined}
        titleIcon={isMaster ? "master" : undefined}
        titleStatusBadge={
          <BranchStatusBadges
            branch={branch}
            className={style.branchStatusBadges}
            branchReviewStatus={branchReview ? branchReview.status : undefined}
            branchStatusUpdating={this.props.branchStatusUpdating}
            onChangeBranchStatus={
              canUpdateBranch ? onChangeBranchStatus : undefined
            }
          />
        }
      />
    );
  };
}
