// @flow
import { connect } from "react-redux";
import { withData } from "core/components/DataLoader";
import createConnector from "core/lib/createConnector";
import * as Request from "core/models/request";
import {
  ShareLinksFetchRequest,
  ShareLinkUpdateRequest,
} from "core/requests/shareLinks";
import { getOrganizationSettings } from "core/selectors/organizationSettings";
import { getPublicShareLinksForOrganization } from "core/selectors/shareLinks";
import type {
  Dispatch,
  State,
  ShareLinkUpdateRequestParams,
  ShareLink,
} from "core/types";
import { OrganizationSettingsUpdateRequest } from "web/requests/organizationSettings";
import type { Props, OwnProps, StateProps, DispatchProps } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { organizationId } = props.params;
  const settings = getOrganizationSettings(state, { organizationId });
  const request = OrganizationSettingsUpdateRequest.getRequest(state, {
    organizationId,
  });

  return {
    publicSharingEnabled: settings ? settings.publicSharingEnabled : undefined,
    privateProjectPublicSharingEnabled: settings
      ? settings.privateProjectPublicSharingEnabled
      : undefined,
    error: Request.hasError(request),
    success: Request.success(request),
    isSubmitting: Request.isLoadingStrict(request),
    shareLinks: getPublicShareLinksForOrganization(state, {
      organizationId,
    }),
    shareLinksLoading: ShareLinksFetchRequest.isFirstLoading(state, {
      organizationId,
    }),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    onLoad() {
      return dispatch(
        ShareLinksFetchRequest.perform({
          params: {
            organizationId: props.params.organizationId,
            publicOnly: true,
          },
        })
      );
    },
    updateOrganizationSettings: (
      organizationId: string,
      values: {
        publicSharingEnabled?: boolean,
        privateProjectPublicSharingEnabled?: boolean,
      }
    ) => {
      return dispatch(
        OrganizationSettingsUpdateRequest.perform({
          params: { organizationId, values },
        })
      );
    },
    updateShareLink(params: ShareLinkUpdateRequestParams) {
      return dispatch(ShareLinkUpdateRequest.perform({ params }));
    },
    // TODO: add isSubmitting/hasError for the
    // future implementation of this request:
    disablePublicAccess(shareLinks: ShareLink[]) {
      shareLinks.forEach((link) => {
        dispatch(
          ShareLinkUpdateRequest.perform({
            params: { id: link.id, isPublic: false },
          })
        );
      });
    },
  };
}

export default createConnector<Props, OwnProps>(
  connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch>(
    mapStateToProps,
    mapDispatchToProps
  ),
  withData((props) => props.params)
);
