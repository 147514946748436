// @flow
import * as React from "react";
import Theme from "core/components/Theme";
import window from "core/global/window";
import type { ThemeConfig, ThemeName, ThemeOptions } from "core/lib/theme";

// Custom themes are keyed by a unique id.
type KeyedThemes = {
  [k: string]: {
    config: ThemeConfig,
    themeName: ThemeName,
    options?: ThemeOptions,
  },
};
type Context = {
  getCustomTheme: (key: string) => {
    config: ThemeConfig,
    themeName: ThemeName,
    options?: ThemeOptions,
  },
  setCustomTheme: (
    key: string,
    value: ThemeConfig,
    options?: ThemeOptions
  ) => void,
};
type Props = {
  children: React.Node,
  appThemeName: ThemeName,
  appThemeConfig: ThemeConfig,
};

const VALID_THEME_NAMES: ThemeName[] = ["light", "dark"];

export const CustomThemeContext = React.createContext<Context>({});

function ThemeProvider(props: Props) {
  const [themes, setThemes] = React.useState<KeyedThemes>({});

  const setCustomTheme = React.useCallback(
    (key: string, value: ThemeConfig, options?: ThemeOptions) => {
      const themeName = value === "system" ? props.appThemeName : value;

      const item = window.localStorage.getItem(key);
      const storage = item ? JSON.parse(item) : undefined;

      const values = {
        config: value,
        themeName,
        options: options || (storage && storage.options),
      };

      setThemes({
        ...themes,
        [key]: values,
      });

      window.localStorage.setItem(key, JSON.stringify(values));
    },
    [props.appThemeName, themes]
  );

  const getCustomTheme = React.useCallback(
    (key: string) => {
      const item = window.localStorage.getItem(key);
      const storage = item ? JSON.parse(item) : undefined;

      if (
        storage &&
        storage.config !== "system" &&
        VALID_THEME_NAMES.includes(storage.themeName)
      ) {
        return {
          config: storage.config,
          themeName: storage.themeName,
          options: storage.options,
        };
      }

      // Default to appTheme.
      return {
        config: "system",
        themeName: props.appThemeName,
        options: storage && storage.options,
      };
    },
    [props.appThemeName]
  );

  const value: Context = {
    getCustomTheme,
    setCustomTheme,
  };

  return (
    <CustomThemeContext.Provider value={value}>
      {props.children}
    </CustomThemeContext.Provider>
  );
}

export function CustomThemeProvider({ children }: { children: React.Node }) {
  return (
    <Theme.Consumer>
      {({ themeName, themeConfig }) => (
        <ThemeProvider appThemeName={themeName} appThemeConfig={themeConfig}>
          {children}
        </ThemeProvider>
      )}
    </Theme.Consumer>
  );
}

export function useCustomTheme() {
  return React.useContext(CustomThemeContext);
}
