// @flow
import { connect } from "react-redux";
import { showDialog } from "core/actions/dialogs";
import contextMenuWrapper, {
  type Props as ContextMenuWrapperProps,
} from "core/components/ContextMenu/ContextMenuWrapper";
import createConnector from "core/lib/createConnector";
import { getOrganization } from "core/selectors/organizations";
import { getFilteredProjects } from "core/selectors/projects";
import type { Dispatch, State } from "core/types";
import type { OwnProps, StateProps, DispatchProps, Props } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { organizationId } = props;
  const organization = getOrganization(state, { organizationId });
  const projects = getFilteredProjects(state, {
    organizationId,
  });

  return {
    organization,
    projects,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    showDialog: (name: string, props: {}) => {
      dispatch(showDialog(name, props));
    },
  };
}

export default createConnector<
  Props,
  {|
    ...OwnProps,
    ...$Exact<ContextMenuWrapperProps>,
  |},
>(
  contextMenuWrapper,
  connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch>(
    mapStateToProps,
    mapDispatchToProps
  )
);
