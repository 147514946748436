// @flow
import * as React from "react";
import { useDispatch } from "react-redux";
import { getTransactionError } from "core/actions/organizations";
import { recurlyReset, createPaymentToken } from "core/actions/recurly";
import Button from "core/components/Button";
import DialogForm from "core/components/DialogForm";
import ExternalLink from "core/components/ExternalLink";
import FormNotice from "core/components/FormNotice";
import Input from "core/components/Input";
import InputCheckbox from "core/components/InputCheckbox";
import PriceCalculation from "core/components/PriceCalculation";
import RecurlyBillingInfoForm from "core/components/RecurlyBillingInfoForm";
import { prices } from "core/lib/subscriptions";
import { trialAgreementUrl } from "core/lib/urls";
import type {
  CreateOrganizationParams,
  SourceTypes,
} from "core/requests/organizations";
import type { ValidationErrors } from "core/types";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  isOpen: boolean,
  source?: SourceTypes,
  onClose: (event?: SyntheticEvent<>) => void,
|};

export type StateProps = {|
  isOnline: boolean,
|};

export type DispatchProps = {|
  createNewOrganization: (
    params: CreateOrganizationParams,
    onSuccessCallback?: () => void,
    onErrorCallback?: (Error) => void
  ) => Promise<void>,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

function CreateOrganization(props: Props) {
  const { isOpen, isOnline, onClose, source, createNewOrganization } = props;
  const [name, setName] = React.useState<string>("");
  const [step, setStep] = React.useState<string>("name");
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [termsConsented, setTermsConsented] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<ValidationErrors>({});
  const [recurlyTokenId, setRecurlyTokenId] = React.useState<?string>();

  const genericError: ?(string[]) = errors._base;
  const codeError: ?(string[]) = errors.code;
  const nameError: ?(string[]) = errors.name;
  const disabled =
    !isOnline || isSubmitting || name.length < 3 || !termsConsented;

  const dispatch = useDispatch();

  const close = React.useCallback(
    (event?: SyntheticEvent<>) => {
      if (event && event.stopPropagation) {
        event.stopPropagation();
      }
      onClose();
      setStep("name");
      setIsSubmitting(false);
      setRecurlyTokenId(null);
      setErrors({});
      dispatch(recurlyReset());
    },
    [dispatch, onClose]
  );

  const handleFormEvent = React.useCallback((event: SyntheticInputEvent<>) => {
    const input = event.target;
    if (input.name === "name") {
      setName(input.value);
    } else if (input.name === "termsConsent") {
      setTermsConsented(input.checked);
    }
  }, []);

  const attemptToCreateNewOrganization = React.useCallback(
    (
      recurlyTokenId: ?string,
      recurlyActionResultToken?: string,
      accountCode?: ?string
    ) => {
      createNewOrganization(
        {
          name,
          source,
          recurlyTokenId,
          recurlyActionResultToken,
          accountCode,
        },
        close,
        (error: Error) => {
          setIsSubmitting(false);
          setRecurlyTokenId(null);
          if (!getTransactionError(error)) {
            setErrors({
              _base: ["An unexpected error occured."],
            });
          }
        }
      );
    },
    [close, createNewOrganization, name, source]
  );

  const handle3DSecureChallengeError = React.useCallback((error: Error) => {
    setIsSubmitting(false);
  }, []);

  const handle3DSecureChallengeCompleted = React.useCallback(
    (actionResultToken: string, accountCode: ?string) => {
      attemptToCreateNewOrganization(
        recurlyTokenId,
        actionResultToken,
        accountCode
      );
    },
    [attemptToCreateNewOrganization, recurlyTokenId]
  );

  const handleSubmit = React.useCallback(
    async (event: SyntheticEvent<>) => {
      event.preventDefault();
      if (step === "name") {
        setStep("billing");
      } else if (step === "billing") {
        setIsSubmitting(true);
        setErrors({});
        setRecurlyTokenId(null);

        dispatch(
          createPaymentToken(
            event.target,
            (tokenId) => {
              setRecurlyTokenId(tokenId);
              attemptToCreateNewOrganization(tokenId);
            },
            (error) => {
              setIsSubmitting(false);
            }
          )
        );
      }
    },
    [attemptToCreateNewOrganization, dispatch, step]
  );

  const termsLinks = (
    <React.Fragment>
      I’ve read and accept the{" "}
      <ExternalLink href={trialAgreementUrl()} className={style.link}>
        Trial Agreement
      </ExternalLink>
      .
    </React.Fragment>
  );

  const trialPrice = prices.proMonthlyPrice / 100;

  return (
    <DialogForm
      isOpen={isOpen}
      overlayClassName={style.overlay}
      onClose={close}
      onSubmit={handleSubmit}
      primaryButton={
        <Button
          primary
          fullwidth
          disabled={disabled}
          large
          marketingTriggerClassName="gtm-create-team"
          onSubmit={handleSubmit}
          type="submit"
        >
          {isSubmitting
            ? "Starting Subscription & Creating Organization…"
            : step === "name"
            ? "Create Organization"
            : "Start Subscription & Create Organization"}
        </Button>
      }
      title="Create your Organization"
      className={style.fullHeightModal}
    >
      <div>
        {step === "name" && (
          <div className={style.introText}>
            Abstract Organizations come in all sizes and can hold one or more
            teams of people.
          </div>
        )}
        {!props.isOnline && (
          <FormNotice
            className={style.formNotice}
            icon="offline"
            heading="Looks like you’re offline"
            body="You can only create a new organization online."
            alignContent
          />
        )}
        {genericError && (
          <FormNotice
            className={style.formNotice}
            heading="Something went wrong"
            body={genericError[0]}
            alignContent
            isError
          />
        )}
        {codeError && (
          <FormNotice
            className={style.formNotice}
            heading="There was an issue with your signup code"
            body={codeError[0]}
            alignContent
            isError
          />
        )}
        {step === "name" && (
          <div>
            <Input
              placeholder="E.g. Acme or Acme Design"
              error={nameError}
              wrapperClass={style.inputWrapper}
              autoFocus
              name="name"
              onChange={handleFormEvent}
              label="Organization name"
              required
            />

            <InputCheckbox
              name="termsConsent"
              onChange={handleFormEvent}
              labelClass={style.termsNote}
              label={termsLinks}
              required
            />
          </div>
        )}

        {step === "billing" && (
          <div className={style.billingForm}>
            <RecurlyBillingInfoForm
              submitText="Start Subscription & Create Organization"
              agreeToTrialAgreement
              agreeToTermsOfService
              on3DSecureChallengeError={handle3DSecureChallengeError}
              on3DSecureChallengeCompleted={handle3DSecureChallengeCompleted}
            >
              <PriceCalculation
                className={style.priceCalculation}
                totalPrice={trialPrice.toString()}
                monthlyPrice={trialPrice}
                annual={false}
                quantity={1}
                description={
                  <span>
                    You will not be charged if you cancel your subscription
                    before your 14-day free trial period ends.
                  </span>
                }
              />
            </RecurlyBillingInfoForm>
          </div>
        )}
      </div>
    </DialogForm>
  );
}

export default connector(CreateOrganization);
