// @flow
import * as React from "react";
import { connect } from "react-redux";
import { isOnline } from "abstract-di/selectors";
import { createComment } from "core/actions/comments";
import CoreCommentCreateForm from "core/components/CommentCreateForm/Form";
import createConnector from "core/lib/createConnector";
import generateFormId from "core/lib/generateFormId";
import { withRouter } from "core/lib/router";
import * as Request from "core/models/request";
import { getCreateCommentRequest } from "core/selectors/comments";
import { getUsersForProject } from "core/selectors/users";
import type {
  User,
  Annotation,
  CommentForm,
  Dispatch,
  State,
  ThunkAction,
} from "core/types";

type OwnProps = {|
  projectId: string,
  branchId: string,
  sha?: string,
  fileId?: string,
  pageId?: string,
  layerId?: string,
  lastChangedAtSha?: string,
  collectionId?: string,
  showErrors?: boolean,
  isLoading?: boolean,
  disabled?: boolean,
  flat?: boolean,
  defaultFocus?: boolean,
  inline?: boolean,
  className?: string,
  isAnnotatable?: boolean,
  collapsible?: boolean,
  form?: Object,
  onFocus?: (opts?: Object) => void,
  onBlur?: (opts?: Object) => void,
  onFormChanged?: ({ annotation?: Annotation }) => void,
  isPubliclyShared?: boolean,
  renderActionsInsideTextarea?: boolean,
  autoFocus?: boolean,
  toggleNewAnnotation?: boolean,
|};

type StateProps = {|
  branchId: string,
  formId: string,
  hasError: boolean,
  online: boolean,
  users: User[],
|};

type DispatchProps = {| createComment: (props: CommentForm) => ThunkAction |};

type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

function CommentCreateForm(props: Props) {
  const handleChange = (id: string, form: { annotation?: Annotation }) => {
    if (props.onFormChanged) {
      props.onFormChanged(form);
    }
  };

  const handleCreate = (id: string, form: Object) => {
    props.createComment({ id, ...form });
    handleReset();
  };

  const handleReset = () => {
    handleChange(props.formId, { body: "", annotation: undefined });
  };

  return (
    <CoreCommentCreateForm
      formId={props.formId}
      disabled={props.disabled}
      flat={props.flat}
      defaultFocus={props.defaultFocus}
      inline={props.inline}
      className={props.className}
      annotation={props.form ? props.form.annotation : undefined}
      hasError={props.hasError}
      isLoading={!!props.isLoading}
      onChange={handleChange}
      onCreate={handleCreate}
      onReset={handleReset}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      users={props.users}
      projectId={props.projectId}
      branchId={props.branchId}
      sha={props.lastChangedAtSha || props.sha}
      fileId={props.fileId}
      pageId={props.pageId}
      layerId={props.layerId}
      collectionId={props.collectionId}
      isAnnotatable={props.isAnnotatable}
      collapsible={props.collapsible}
      online={props.online}
      isPubliclyShared={props.isPubliclyShared}
      showIndicatorOn="focus"
      renderActionsInsideTextarea={props.renderActionsInsideTextarea}
      autoFocus={props.autoFocus}
      toggleNewAnnotation={props.toggleNewAnnotation}
    />
  );
}

export function getCommentFormId(props: Object) {
  return generateFormId(
    props.projectId,
    props.branchId,
    props.sha || "",
    props.fileId || "",
    props.pageId || "",
    props.layerId || "",
    "-comment-form"
  );
}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { projectId, branchId } = props;
  const formId = getCommentFormId(props);
  const formRequest = getCreateCommentRequest(state, { id: formId });

  return {
    branchId,
    formId,
    hasError: !!props.showErrors && Request.hasError(formRequest),
    online: isOnline(state),
    users: getUsersForProject(state, { projectId }),
  };
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
const connector = createConnector<Props, OwnProps>(
  withRouter,
  connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch>(
    mapStateToProps,
    { createComment }
  )
);

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default connector(CommentCreateForm);
