// @flow
import * as React from "react";
import ReactMedia from "react-media";

// Correspond to values in core/components/shared/_vars.scss
const BREAKPOINT_MOBILE = 800;
const BREAKPOINT_TABLET = 960;
const BREAKPOINT_WEB = 1180;

type Props = {
  mobile?: boolean,
  tablet?: boolean,
  desktop?: boolean,
  large?: boolean,
  xlarge?: boolean,
};

export default function Media({
  mobile,
  tablet,
  desktop,
  large,
  xlarge,
  ...rest
}: Props) {
  if (mobile) {
    return (
      <ReactMedia {...rest} query={`(max-width: ${BREAKPOINT_MOBILE}px)`} />
    );
  }

  if (tablet) {
    return (
      <ReactMedia {...rest} query={`(max-width: ${BREAKPOINT_TABLET}px)`} />
    );
  }

  if (desktop) {
    return (
      <ReactMedia {...rest} query={`(min-width: ${BREAKPOINT_MOBILE + 1}px)`} />
    );
  }

  if (large) {
    return (
      <ReactMedia {...rest} query={`(min-width: ${BREAKPOINT_TABLET + 1}px)`} />
    );
  }

  if (xlarge) {
    return (
      <ReactMedia {...rest} query={`(min-width: ${BREAKPOINT_WEB + 1}px)`} />
    );
  }

  return <ReactMedia {...rest} />;
}
