// @flow
// Tread carefully here, especially when deleting!
// Many of these values are committed into git repos.

export const CommitTypes = {
  PROJECT_CREATED: "PROJECT_CREATED",
  FILE_ADDED: "FILE_ADDED",
  FILE_RENAMED: "FILE_RENAMED",
  FILE_DELETED: "FILE_DELETED",
  FILE_REPLACED: "FILE_REPLACED",
  FILE_UPGRADED: "FILE_UPGRADED",
  FILE_LIBRARIES_UPDATED: "FILE_LIBRARIES_UPDATED",
  LIBRARY_ADDED: "LIBRARY_ADDED",
  LIBRARY_REMOVED: "LIBRARY_REMOVED",
  RESTORE: "RESTORE",
  UPDATE: "UPDATE",
  MERGE: "MERGE",
  BRANCH: "BRANCH",
};

export const BranchStatus = {
  NONE: "active",
  WIP: "wip",
  FEEDBACK: "feedback",
  PAUSED: "paused",
  DEVELOPMENT: "development",
  REVIEW: "review",
  MERGED: "merged",
  ARCHIVED: "archived",
  DELETED: "deleted",
  DIVERGED: "diverged",
  STALE: "stale",
};

export const ActiveBranchStatuses = [
  BranchStatus.NONE,
  BranchStatus.WIP,
  BranchStatus.FEEDBACK,
  BranchStatus.REVIEW,
  BranchStatus.DEVELOPMENT,
  BranchStatus.PAUSED,
];

export const ArchivedBranchStatuses = [
  BranchStatus.ARCHIVED,
  BranchStatus.MERGED,
];

// NB: do not change this text string, it is what the backend CLI expects
export const WorkingTreeSHA = "WorkingCopySHA";
