// @flow
import classnames from "classnames";
import * as React from "react";
import { useDispatch } from "react-redux";
import { Button } from "reakit/Button";
import { quickJumpOpen } from "core/actions/quickJump";
import Icon from "core/components/Icon";
import Theme from "core/components/Theme";
import { isDesktop } from "core/lib/platform";
import style from "./style.scss";

type Props = {
  className?: string,
};

export default function SidebarQuickJumpTeaser(props: Props) {
  const dispatch = useDispatch();

  const handleClick = React.useCallback(() => {
    dispatch(
      quickJumpOpen({
        showShortcutTip: true,
      })
    );
  }, [dispatch]);

  return (
    <Theme.Consumer>
      {({ themeName }) => {
        const iconFill =
          isDesktop && themeName === "light" ? "rgba(0,0,0,.5)" : undefined;

        return (
          <Button
            className={classnames(style.quickJump, props.className)}
            onClick={handleClick}
            data-qa="quickJumpButton"
          >
            <Icon
              type="quick-jump-small"
              className={style.icon}
              fill={iconFill}
            />
            <span className={style.text}>Jump to…</span>
          </Button>
        );
      }}
    </Theme.Consumer>
  );
}
