// @flow
import { setPaginationTotal } from "core/actions/paginationTotals";
import { refreshProjectMemberships } from "core/actions/projectMemberships";
import { PROJECT_TEAMS_LIMIT } from "core/lib/teams";
import {
  AddTeamProjectMembershipRequest,
  RemoveTeamProjectMembershipRequest,
  PaginatedTeamProjectMembershipsRequest,
} from "core/requests/teamProjectMemberships";
import { OrganizationTeamFetchRequest } from "core/requests/teams";
import { getPaginationTotal } from "core/selectors/paginationTotals";
import { getProject } from "core/selectors/projects";
import { getTeam } from "core/selectors/teams";
import type {
  ThunkAction,
  TeamProjectMembershipParams,
  TeamProjectMembership,
} from "core/types";

type loadTeamsOptions = { offset?: number, limit?: number, force?: boolean };

// We should immediately follow up creating a new Team Project Membership by
// re-loading Project Memberships, which will now be populated with the new
// team members.
export function AddTeamProjectMembership(
  params: TeamProjectMembershipParams
): ThunkAction {
  return (dispatch, getState) => {
    dispatch(
      AddTeamProjectMembershipRequest.perform({
        params,
        onSuccess: (response: {
          data: { teamProjectMembership: TeamProjectMembership },
        }) => {
          dispatch(
            TeamProjectMembershipAdded(response.data.teamProjectMembership)
          );
        },
      })
    );
  };
}

export function loadPaginatedProjectTeams(
  projectId: string,
  options?: loadTeamsOptions
): ThunkAction {
  return (dispatch) => {
    dispatch(
      PaginatedTeamProjectMembershipsRequest.perform({
        params: {
          projectId,
          limit: (options && options.limit) || PROJECT_TEAMS_LIMIT,
          offset: (options && options.offset) || 0,
        },
        force: (options && options.force) || false,
        onSuccess: (response) => {
          return dispatch(
            setPaginationTotal(`${projectId}-Teams`, response.meta.total)
          );
        },
      })
    );
  };
}

// We should immediately follow up deleting a new Team Project Membership by
// re-loading Project Memberships, which should now be missing the removed members.
// EntitiesReplaced should purge the now-removed members from the store.
export function RemoveTeamProjectMembership(
  params: TeamProjectMembershipParams
): ThunkAction {
  const { projectId } = params;
  return (dispatch, getState) => {
    dispatch(
      RemoveTeamProjectMembershipRequest.perform({
        params,
        onSuccess: () => {
          // If current pagination is undefined, we initialize it to 0
          // So we can then set it -1 when deleting the project team
          const currentPaginationTotal = getPaginationTotal(
            getState(),
            `${params.projectId}-Teams`
          );

          dispatch(
            setPaginationTotal(
              `${params.projectId}-Teams`,
              currentPaginationTotal ? currentPaginationTotal - 1 : 0
            )
          );
          dispatch(refreshProjectMemberships(projectId));
        },
      })
    );
  };
}

export function TeamProjectMembershipAdded(
  teamProjectMembership: TeamProjectMembership
): ThunkAction {
  return (dispatch, getState) => {
    dispatch(refreshProjectMemberships(teamProjectMembership.projectId));
    const project = getProject(getState(), {
      projectId: teamProjectMembership.projectId,
    });
    const team = getTeam(getState(), { teamId: teamProjectMembership.teamId });
    if (!team && project) {
      dispatch(
        OrganizationTeamFetchRequest.perform({
          params: {
            organizationId: project.organizationId,
            teamId: teamProjectMembership.teamId,
          },
        })
      );
    }

    // If current pagination is undefined, we initialize it to 0
    // So we can then set it +1 when adding the first project team
    const currentPaginationTotal =
      getPaginationTotal(
        getState(),
        `${teamProjectMembership.projectId}-Teams`
      ) || 0;

    dispatch(
      setPaginationTotal(
        `${teamProjectMembership.projectId}-Teams`,
        currentPaginationTotal + 1
      )
    );
  };
}
