// @flow
import createCachedSelector from "@elasticprojects/re-reselect";
import empty from "empty";
import {
  keyBy,
  pick,
  filter,
  orderBy,
  reduce,
  sortBy,
  uniq,
  values,
  find,
} from "lodash";
import { getCurrentUserId } from "abstract-di/selectors";
import {
  ArchivedBranchStatuses,
  ActiveBranchStatuses,
} from "core/gitConstants";
import { Abstract } from "core/lib/abstract";
import genericFilter from "core/lib/genericFilter";
import naturalSortBy from "core/lib/naturalSortBy";
import * as Branch from "core/models/branch";
import * as Layer from "core/models/layer";
import { CollectionFetchRequest } from "core/requests/collections";
import {
  getBranch,
  getBranchHead,
  getBranchesForProject,
} from "core/selectors/branches";
import { getFilteredComments } from "core/selectors/comments";
import { getEntityMapping } from "core/selectors/entityMappings";
import { canCreateCollectionsAnywhere } from "core/selectors/features";
import {
  getSha,
  getProjectId,
  getBranchId,
  maybeGetUserId,
  maybeGetBranchId,
  getCollectionId,
  getFileId,
  getLayerId,
} from "core/selectors/helpers";
import { getAllPolicies, getProjectPolicy } from "core/selectors/policies";
import { getUserEntities } from "core/selectors/users";
import type {
  Collection,
  CollectionLayer,
  CollectionText,
  CollectionItem,
  Comment,
  User,
  Layer as TLayer,
  Policy,
  State,
  LayerSetItem,
  Branch as TBranch,
} from "core/types";
import { getEntity, getRawEntities } from "./entities";
import { getFile } from "./files";
import { getLayer } from "./layers";
import { getPage } from "./pages";

function getCollectionEntities(state: State): { [string]: Collection } {
  return getRawEntities(state, "collections");
}

function getCollectionLayerEntities(state: State): {
  [string]: CollectionItem,
} {
  return getRawEntities(state, "collectionLayers");
}

function getLayerEntities(state: State): { [string]: TLayer } {
  return getRawEntities(state, "layers");
}

function getProjectCollectionsEntityMapping(
  state: State,
  params: {
    projectId: string,
  }
): string[] {
  return getEntityMapping(state, {
    entityId: params.projectId,
    type: "collections",
  });
}

function getCollectionsCacheKey(
  state: State,
  {
    projectId,
    branchId = "",
    sortBy = "",
  }: { projectId: string, branchId?: string, sortBy?: string }
) {
  return `${projectId}-${branchId}-${sortBy}`;
}

function getBranchCollectionsCacheKey(
  state: State,
  { projectId, branchId }: { projectId: string, branchId: string }
) {
  return `${projectId}-${branchId}`;
}

function getCollectionCacheKey(
  state: State,
  { collectionId }: { collectionId: string }
) {
  return collectionId;
}

function getTargetCollectionsForLayerCacheKey(
  state: State,
  {
    projectId,
    branchId,
    fileId,
    layerId,
  }: { projectId: string, branchId: string, fileId: string, layerId: string }
) {
  return `${projectId}-${branchId}-${fileId}-${layerId}`;
}

export function getCollection(
  state: State,
  { collectionId }: { collectionId: string }
): ?Collection {
  return getEntity(state, "collections", collectionId);
}

function getSortBy(state: State, props: { sortBy?: string }) {
  return props.sortBy;
}

export const getCollections: (
  State,
  { projectId: string, branchId?: string, sortBy?: string }
) => Collection[] = createCachedSelector(
  getCollectionEntities,
  getSortBy,
  getProjectId,
  maybeGetBranchId,
  (collections, sortBy, projectId, branchId) => {
    return naturalSortBy(
      filter(collections, branchId ? { projectId, branchId } : { projectId }),
      sortBy || "updatedAt",
      { caseSensitive: false, direction: "desc" }
    );
  }
)(getCollectionsCacheKey);

export const getCollectionIdToBranchMapping: (
  State,
  { projectId: string }
) => { [collectionId: string]: TBranch } = createCachedSelector(
  getCollectionEntities,
  getProjectCollectionsEntityMapping,
  getBranchesForProject,
  (collections, collectionIds, branches) => {
    const pickedCollections = pick(collections, collectionIds);

    const map = Object.keys(pickedCollections).reduce((mapping, key) => {
      const collection = pickedCollections[key];

      mapping[collection.id] =
        branches[
          branches.findIndex((branch) => branch.id === collection.branchId)
        ];

      return mapping;
    }, {});

    return map;
  }
)(getProjectId);

function getSearch(state: State, props: { search?: string }): ?string {
  return props.search;
}

function getBranchStatus(
  state: State,
  props: { branchStatus?: string }
): ?string {
  return props.branchStatus;
}

export const getCollectionsForProject: (
  state: State,
  props: {
    projectId: string,
    userId?: string,
    search?: string,
    branchId?: string,
    branchStatus?: string,
  }
) => Collection[] = createCachedSelector(
  maybeGetBranchId,
  maybeGetUserId,
  getSearch,
  getBranchStatus,
  getCollectionEntities,
  getProjectCollectionsEntityMapping,
  getCollectionIdToBranchMapping,
  (
    branchId,
    userId,
    search,
    branchStatus,
    collections,
    collectionIdsThatMatchEntityId,
    collectionIdToBranchMapping
  ) => {
    let ourCollections = values(
      pick(collections, collectionIdsThatMatchEntityId)
    );

    const options = {};
    if (branchId !== undefined) {
      options.branchId = branchId;
    }
    if (userId !== undefined) {
      options.userId = userId;
    }
    ourCollections = filter(ourCollections, options);

    if (branchStatus) {
      ourCollections = filter(ourCollections, (collection) => {
        const branch = collectionIdToBranchMapping[collection.id];
        if (branch) {
          if (branchStatus === "active" && branch.id !== "master") {
            return ActiveBranchStatuses.includes(branch.status);
          }
          if (branchStatus === "archived") {
            return ArchivedBranchStatuses.includes(branch.status);
          }

          return false;
        }
      });
    }

    if (search) {
      const formattedSearch = search.toLowerCase();

      ourCollections = filter(ourCollections, (collection) => {
        const branch = collectionIdToBranchMapping[collection.id];
        if (branch) {
          return (
            branch.name.toLowerCase().includes(formattedSearch) ||
            collection.name.toLowerCase().includes(formattedSearch)
          );
        }
        return false;
      });
    }

    const result = orderBy(ourCollections, "updatedAt", "desc");
    return result.length ? result : empty.array;
  }
)((state, props) =>
  [
    props.projectId,
    props.userId,
    props.search,
    props.branchId,
    props.branchStatus,
  ].join("-")
);

export const getCollectionAuthors: (
  State,
  { projectId: string, branchId?: string, sortBy?: string }
) => { [string]: ?User } = createCachedSelector(
  getCollections,
  getUserEntities,
  (collections, userEntities) => {
    return keyBy(
      uniq(collections.map((collection) => userEntities[collection.userId])),
      "id"
    );
  }
)(getCollectionsCacheKey);

function getCollectionBranchHead(
  state: State,
  { collectionId }: { collectionId: string }
) {
  const collection = getCollection(state, { collectionId });
  if (!collection) {
    return;
  }

  return getBranchHead(state, {
    projectId: collection.projectId,
    branchId: collection.branchId,
  });
}

export const getCollectionLayersForProject: (
  State,
  { projectId: string }
) => CollectionLayer[] = createCachedSelector(
  getProjectId,
  getCollectionLayerEntities,
  (projectId, collectionLayerEntities) => {
    return genericFilter(collectionLayerEntities, { projectId, kind: "layer" });
  }
)(getProjectId);

function getCollectionLayersCacheKey(
  state: State,
  { collectionId }: { collectionId: string }
) {
  return `${collectionId}-layer`;
}

export const getCollectionLayers: (
  State,
  { collectionId: string }
) => CollectionLayer[] = createCachedSelector(
  getCollectionLayerEntities,
  getCollectionId,
  (collectionLayerEntities, collectionId) => {
    return sortBy(
      genericFilter<CollectionLayer, { [string]: CollectionItem }>(
        collectionLayerEntities,
        {
          collectionId,
          kind: "layer",
        }
      ),
      "order"
    );
  }
)(getCollectionLayersCacheKey);

function getCollectionTextItemsCacheKey(
  state: State,
  { collectionId }: { collectionId: string }
) {
  return `${collectionId}-text`;
}

export const getCollectionTextItems: (
  State,
  { collectionId: string }
) => CollectionText[] = createCachedSelector(
  getCollectionLayerEntities,
  getCollectionId,
  (collectionLayerEntities, collectionId) => {
    return sortBy(
      genericFilter<CollectionText, { [string]: CollectionItem }>(
        collectionLayerEntities,
        {
          collectionId,
          kind: "text",
        }
      ),
      "order"
    );
  }
)(getCollectionTextItemsCacheKey);

export const getCollectionItems: (
  State,
  { collectionId: string }
) => CollectionItem[] = createCachedSelector(
  getCollectionLayerEntities,
  getCollectionId,
  (collectionLayerEntities, collectionId) => {
    return sortBy(filter(collectionLayerEntities, { collectionId }), "order");
  }
)(getCollectionCacheKey);

export const getCollectionPreviewLayers: (
  State,
  { collectionId: string }
) => TLayer[] = createCachedSelector(
  getCollection,
  getCollectionBranchHead,
  getCollectionLayers,
  getLayerEntities,
  (collection, branchHead, collectionLayers, layerEntities) => {
    if (!collection) {
      return empty.array;
    }

    const length = Math.min(collectionLayers.length, 4);
    return collectionLayers.slice(0, length).reduce((memo, collectionLayer) => {
      const sha = _getShaForCollectionLayer(collectionLayer, branchHead);
      const layer = _getLayerForCollectionLayer(
        layerEntities,
        collectionLayer,
        sha
      );

      if (layer) {
        memo.push(layer);
      }

      return memo;
    }, []);
  }
)(getCollectionCacheKey);

export function getCollectionLayer(
  state: State,
  { collectionLayerId }: { collectionLayerId: string }
): ?CollectionLayer {
  return getEntity(state, "collectionLayers", collectionLayerId);
}

function _getShaForCollectionLayer(collectionLayer, branchHead) {
  return collectionLayer.useLatestCommit && branchHead
    ? branchHead
    : collectionLayer.sha;
}

export function getShaForCollectionLayer(
  state: State,
  { collectionLayerId }: { collectionLayerId: string }
) {
  const collectionLayer = getCollectionLayer(state, { collectionLayerId });
  if (!collectionLayer) {
    return "";
  }

  const branchHead = getCollectionBranchHead(state, {
    collectionId: collectionLayer.collectionId,
  });

  return _getShaForCollectionLayer(collectionLayer, branchHead);
}

export function getFileForCollectionLayer(
  state: State,
  { collectionLayerId }: { collectionLayerId: string }
) {
  const collectionLayer = getCollectionLayer(state, { collectionLayerId });
  if (!collectionLayer) {
    return;
  }

  if (collectionLayer.useLatestCommit) {
    const latestShaFile = getFile(state, {
      ...collectionLayer,
      sha: getShaForCollectionLayer(state, { collectionLayerId }),
    });
    if (latestShaFile) {
      return latestShaFile;
    }
  }

  return getFile(state, collectionLayer);
}

export function getPageForCollectionLayer(
  state: State,
  { collectionLayerId }: { collectionLayerId: string }
) {
  const collectionLayer = getCollectionLayer(state, { collectionLayerId });
  if (!collectionLayer) {
    return;
  }

  const layer = getLayerForCollectionLayer(state, { collectionLayerId });

  if (collectionLayer.useLatestCommit) {
    const latestShaPage = getPage(state, {
      ...collectionLayer,
      pageId: layer ? layer.pageId : collectionLayer.pageId,
      sha: getShaForCollectionLayer(state, { collectionLayerId }),
    });
    if (latestShaPage) {
      return latestShaPage;
    }
  }

  return getPage(state, collectionLayer);
}

function _getLayerForCollectionLayer(
  layerEntities,
  collectionLayer,
  branchHead
) {
  if (collectionLayer.useLatestCommit) {
    const latestShaLayer =
      layerEntities[Layer.uniqueId({ ...collectionLayer, sha: branchHead })];
    if (latestShaLayer) {
      return latestShaLayer;
    }
  }

  return layerEntities[Layer.uniqueId(collectionLayer)];
}

export function getLayerForCollectionLayer(
  state: State,
  { collectionLayerId }: { collectionLayerId: string }
): ?TLayer {
  const collectionLayer = getCollectionLayer(state, { collectionLayerId });
  if (!collectionLayer) {
    return;
  }

  return _getLayerForCollectionLayer(
    getRawEntities(state, "layers"),
    collectionLayer,
    getShaForCollectionLayer(state, { collectionLayerId })
  );
}

export function isCollectionLayerLoading(
  state: State,
  { collectionId, projectId }: { collectionId: string, projectId: string }
): boolean {
  return CollectionFetchRequest.isLoading(state, { projectId, collectionId });
}

export function isCollectionLayerRemoved(
  state: State,
  { collectionLayerId }: { collectionLayerId: string }
) {
  const collectionLayer = getCollectionLayer(state, { collectionLayerId });

  const collection = collectionLayer
    ? getCollection(state, {
        collectionId: collectionLayer.collectionId,
      })
    : null;

  if (!collection || !collectionLayer || !collectionLayer.useLatestCommit) {
    return null;
  }

  if (
    isCollectionLayerLoading(state, {
      projectId: collectionLayer.projectId,
      collectionId: collectionLayer.collectionId,
    })
  ) {
    return false;
  }

  const { projectId, fileId, layerId } = collectionLayer;
  const sha = getShaForCollectionLayer(state, { collectionLayerId });
  return !getLayer(state, {
    projectId,
    branchId: collection.branchId,
    sha,
    fileId,
    layerId,
  });
}

export function getCommentsForCollectionLayer(
  state: State,
  { collectionLayerId }: { collectionLayerId: string }
): Comment[] {
  const collectionLayer = getCollectionLayer(state, { collectionLayerId });
  if (!collectionLayer) {
    return empty.array;
  }

  const layer = getLayerForCollectionLayer(state, { collectionLayerId });
  const commitSha = layer
    ? layer.lastChangedAtSha
    : getShaForCollectionLayer(state, { collectionLayerId });

  return getFilteredComments(state, {
    projectId: collectionLayer.projectId,
    commitSha,
    fileId: collectionLayer.fileId,
    layerId: collectionLayer.layerId,
  });
}

function branchCollectionsCache(
  state: State,
  {
    projectId,
    branchId,
    sortBy,
  }: { projectId: string, branchId: string, sortBy?: string }
) {
  const key = [projectId, branchId, sortBy].filter((i) => i).join("-");
  return key;
}

export const getBranchCollections: (
  State,
  { projectId: string, branchId: string, sortBy?: string }
) => Collection[] = createCachedSelector(
  getCollectionEntities,
  getProjectId,
  getBranchId,
  getSortBy,
  (collections, projectId, branchId, sortByKey) => {
    return orderBy(
      values(collections).filter((collection) => {
        return (
          collection.projectId === projectId &&
          collection.branchId === branchId &&
          collection.publishedAt
        );
      }),
      [sortByKey, "updatedAt"],
      [sortByKey === "order" ? "asc" : "desc", "desc"]
    );
  }
)(branchCollectionsCache);

function getBranchCollectionPolicies(
  state: State,
  { projectId, branchId }: { projectId: string, branchId: string }
) {
  return getCollectionPolicies(state, { projectId, branchId });
}

const getCollectionPolicies: (
  State,
  { projectId: string, branchId?: string, sortBy?: string }
) => { [subjectId: string]: Policy } = createCachedSelector(
  getCollections,
  getAllPolicies,
  (collections, policies) =>
    collections.reduce((memo, collection) => {
      memo[collection.id] = policies[collection.id];
      return memo;
    }, {})
)(getCollectionsCacheKey);

// getUpdateableCollections returns collections on a branch that you have
// access to add to.
export const getUpdatableCollections: (
  State,
  {
    projectId: string,
    branchId: string,
    sortBy?: string,
  }
) => Collection[] = createCachedSelector(
  getBranchCollections,
  getBranchCollectionPolicies,
  (collections, policies) =>
    collections.filter(({ id }) => (policies[id] ? policies[id].update : false))
)(getBranchCollectionsCacheKey);

// getCollectionsWithLayer returns a map of collections that contain the given layer.
export const getCollectionsWithLayer: (
  State,
  Abstract.LayerVersionDescriptor
) => { [collectionId: string]: CollectionLayer[] } = createCachedSelector(
  getCollectionLayerEntities,
  getFileId,
  getLayerId,
  (collectionLayers, fileId, layerId) => {
    return reduce(
      collectionLayers,
      (memo, collectionLayer: CollectionLayer) => {
        if (
          collectionLayer.fileId === fileId &&
          collectionLayer.layerId === layerId
        ) {
          (
            memo[collectionLayer.collectionId] ||
            (memo[collectionLayer.collectionId] = [])
          ).push(collectionLayer);
        }
        return memo;
      },
      {}
    );
  }
)(getTargetCollectionsForLayerCacheKey);

const cacheByLayerParams = (state, props: Abstract.LayerVersionDescriptor) => {
  return `${props.projectId}-${props.fileId}-${props.layerId}-${props.sha}`;
};

export const getCollectionsWithIdenticalLayer: (
  State,
  Abstract.LayerVersionDescriptor
) => { [collectionId: string]: boolean } = createCachedSelector(
  getSha,
  getLayer,
  getCollectionsWithLayer,
  (lastChangedAtSha, layerAtBranchHead, collectionsWithLayer) => {
    return reduce(
      collectionsWithLayer,
      (memo, collectionLayers, collectionId) => {
        const isIdenticalLayerPresent = find(collectionLayers, (layer) => {
          if (!layer.useLatestCommit) {
            return lastChangedAtSha === layer.sha;
          }
          return !!(
            layerAtBranchHead &&
            layerAtBranchHead.lastChangedAtSha === lastChangedAtSha
          );
        });

        if (isIdenticalLayerPresent) {
          memo[collectionId] = true;
        }

        return memo;
      },
      {}
    );
  }
)(cacheByLayerParams);

export const getCanEditBranchCollections: (
  state: State,
  props: Abstract.BranchDescriptor
) => boolean = createCachedSelector(
  getProjectPolicy,
  getBranch,
  getCurrentUserId,
  getBranchId,
  getProjectId,
  (policy, branch, userId, branchId, projectId) => {
    if (!policy || !policy.createCollection) {
      return false;
    }

    if (!branch || !userId) {
      console.error(`Branch ${branchId} in project ${projectId} expected.`);
      return false;
    }

    return branch.userId === userId || Branch.isMaster(branch);
  }
)(getBranchCollectionsCacheKey);

export const getCanCreateBranchCollections: (
  state: State,
  props: Abstract.BranchDescriptor
) => boolean = createCachedSelector(
  getProjectPolicy,
  canCreateCollectionsAnywhere,
  getCanEditBranchCollections,
  (policy, createAnywhereFeatureEnabled, canEditBranchCollections) => {
    if (policy.createCollection && createAnywhereFeatureEnabled) {
      return true;
    }

    return canEditBranchCollections;
  }
)(getBranchCollectionsCacheKey);

export const getLayerSetForCollection: (
  State,
  { collectionId: string }
) => LayerSetItem[] = createCachedSelector(
  getCollection,
  getCollectionBranchHead,
  getCollectionLayers,
  getLayerEntities,
  (collection, branchHead, collectionLayers, layerEntities) => {
    if (!collection) {
      return empty.array;
    }

    return collectionLayers.reduce((memo, collectionLayer) => {
      const sha = _getShaForCollectionLayer(collectionLayer, branchHead);
      const layer = _getLayerForCollectionLayer(
        layerEntities,
        collectionLayer,
        sha
      );

      if (layer) {
        memo.push({
          nonce: collectionLayer.id,
          commitSha: layer.lastChangedAtSha,
          fileId: layer.fileId,
          pageId: layer.pageId,
          layerId: layer.id,
        });
      }

      return memo;
    }, []);
  }
)(getCollectionCacheKey);
