// @flow
import * as React from "react";
import { Abstract } from "core/lib/abstract";
import type { LayerState, Layer } from "core/types";
import { fromNow } from "../../lib/dates";
import LayerCanvas from "../LayerCanvas";
import Time from "../Time";
import type { ChangeEvent as ZoomState } from "../ZoomablePreview";
import style from "./style.scss";

type Props = {
  layer: ?Layer,
  layerState: LayerState,
  compareLayer: ?Layer,
  compareLayerState: LayerState,
  params: Abstract.LayerVersionDescriptor,
  commitIsLatest?: boolean,
  zoomState: ZoomState,
  onChangeZoomState: (ZoomState) => void,
  shareId?: string,
  layerCanvasRef: (?LayerCanvas) => void,
};

export default function SideBySideDiff(props: Props) {
  return (
    <div className={style.sideBySide}>
      <div className={style.compareContainer}>
        {props.compareLayer && (
          <Time date={props.compareLayer.updatedAt} className={style.time} />
        )}
        <div className={style.compareLayerCanvas}>
          <LayerCanvas
            className={style.previousCommitCanvas}
            params={props.params}
            layer={props.compareLayer}
            layerMissing={props.compareLayerState === "not_found"}
            alt={
              (props.compareLayer &&
                `${props.compareLayer.name} - ${fromNow(
                  props.compareLayer.updatedAt
                )}`) ||
              ""
            }
            zoomState={props.zoomState}
            onChangeZoomState={props.onChangeZoomState}
            shareId={props.shareId}
          />
        </div>
      </div>
      <div className={style.compareContainer}>
        {props.layer && (
          <Time date={props.layer.updatedAt} className={style.time}>
            {props.commitIsLatest ? "Latest" : undefined}
          </Time>
        )}
        <div className={style.layerCanvas}>
          <LayerCanvas
            ref={props.layerCanvasRef}
            params={props.params}
            className={style.latestCommitCanvas}
            layer={props.layer}
            layerMissing={props.layerState === "not_found"}
            alt={
              (props.layer &&
                `${props.layer.name} - ${
                  props.commitIsLatest
                    ? "Latest"
                    : fromNow(props.layer.updatedAt)
                }`) ||
              ""
            }
            zoomState={props.zoomState}
            onChangeZoomState={props.onChangeZoomState}
            shareId={props.shareId}
          />
        </div>
      </div>
    </div>
  );
}
