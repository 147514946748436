// @flow
import * as React from "react";
import DialogForm from "core/components/DialogForm";
import Flex from "core/components/Flex";
import InputCheckbox from "core/components/InputCheckbox";
import InputRadio from "core/components/InputRadio";
import RadioGroup from "core/components/RadioGroup";
import { privacyUrl, termsOfServiceUrl } from "core/lib/urls";
import MarketingConsentInfoPopover from "web/components/MarketingConsentInfoPopover";
import style from "./style.scss";

type Props = {
  isOpen: boolean,
  isSubmitting: boolean,
  showMarketingConsent: boolean,
  showTermsOfService: boolean,
  onUpdateAccount: (values: { marketingConsent?: boolean }) => *,
};

type State = {
  showTOS?: boolean,
  showConsent?: boolean,
  marketingConsent?: string,
  acceptTos?: boolean,
};

export default class MarketingConsentDialog extends React.Component<
  Props,
  State,
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showConsent: this.props.showMarketingConsent,
      showTOS: this.props.showTermsOfService,
    };
  }

  handleChange = (ev: SyntheticInputEvent<*>) => {
    const input = ev.target;
    const value = input.type === "checkbox" ? input.checked : input.value;
    this.setState({ [input.name]: value });
  };

  handleSubmit = async (ev: SyntheticEvent<*>) => {
    ev.preventDefault();
    const { showConsent, showTOS, marketingConsent, acceptTos } = this.state;
    const consentValues = {};

    if (showConsent) {
      consentValues.marketingConsent = marketingConsent === "1";
    }

    if (showTOS) {
      consentValues.acceptTos = acceptTos;
    }

    this.props.onUpdateAccount(consentValues);
  };

  renderMarketingConsent = () => {
    return (
      <div>
        <Flex justify="space-between" align="flex-start">
          <div className={style.consentItemHeader}>
            Would you like to receive product announcement emails from Abstract?
          </div>
          <MarketingConsentInfoPopover />
        </Flex>
        <div className={style.inputContainer}>
          <RadioGroup
            onChange={this.handleChange}
            name="marketingConsent"
            value={this.state.marketingConsent}
          >
            <InputRadio
              wrapperClass={style.input}
              label="Yes, I would like to receive email updates"
              value="1"
            />
            <InputRadio
              wrapperClass={style.input}
              label="No, I’d prefer not to receive these updates"
              value="0"
            />
          </RadioGroup>
        </div>
      </div>
    );
  };

  renderTermsConsent = () => {
    return (
      <div>
        <div className={style.consentItemHeader}>
          Terms of Service and Privacy Policy
        </div>

        <div className={style.inputContainer}>
          <InputCheckbox
            name="acceptTos"
            onChange={this.handleChange}
            labelClass={style.note}
            label={
              <React.Fragment>
                I’ve read and accept the{" "}
                <a
                  href={termsOfServiceUrl()}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  href={privacyUrl()}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Privacy Policy
                </a>
                .
              </React.Fragment>
            }
          />
        </div>
      </div>
    );
  };

  render() {
    const { isOpen } = this.props;
    const { showTOS, showConsent } = this.state;
    const marketingConsentSelected = showConsent
      ? this.state.marketingConsent !== undefined
      : true;

    const acceptedTOS = showTOS ? this.state.acceptTos : true;

    const disabled = !(marketingConsentSelected && acceptedTOS);

    return (
      <DialogForm
        contentClassName={style.content}
        onSubmit={this.handleSubmit}
        isOpen={isOpen}
        disabled={this.props.isSubmitting || disabled}
        primaryButton={this.props.isSubmitting ? "Saving…" : "Get Started"}
        title="A quick question…"
      >
        <p className={style.introNote}>One last thing before you jump in…</p>
        {showConsent && this.renderMarketingConsent()}
        {showTOS && this.renderTermsConsent()}
      </DialogForm>
    );
  }
}
