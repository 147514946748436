// @flow
import { connect } from "react-redux";
import { fetchProjectReviews } from "core/actions/reviews";
import { getOrganizationForProject } from "core/selectors/organizations";
import { getUser } from "core/selectors/users";
import type { State, Dispatch } from "core/types";
import ProjectBranches, {
  type Props,
  type OwnProps,
  type StateProps,
  type DispatchProps,
} from "web/components/ProjectBranches";
import { getProject } from "web/selectors/projects";

function getFilter(props: OwnProps) {
  switch (props.location.query.filter) {
    case "archived":
      return "archived";
    case "mine":
      return "mine";
    default:
      return "active";
  }
}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { projectId } = props.params;
  const { query } = props.location;
  const userId = query.userId || undefined;
  const filter = getFilter(props);
  const project = getProject(state, projectId);
  const organization = getOrganizationForProject(state, { projectId });

  return {
    key: `${projectId}-branches`,
    project,
    projectId,
    userId,
    filter,
    selectedAuthor: userId ? getUser(state, { userId }) : undefined,
    isLoading: !organization || !project,
    currentUserId: state.session.userId,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { projectId } = props.params;

  return {
    onMount() {
      dispatch(fetchProjectReviews(projectId));
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(
  mapStateToProps,
  mapDispatchToProps
)(ProjectBranches);
