// @flow
import { entitiesDeleted, entitiesReceived } from "core/actions/entities";
import { request } from "core/actions/requests";
import * as API from "../api";
import * as URLs from "../api/urls";
import {
  normalizeEmailsResponse,
  normalizeEmailResponse,
} from "../schemas/email";
import type { ThunkAction } from ".";

export function loadEmails(): ThunkAction {
  return async function (dispatch) {
    return dispatch(
      request(URLs.fetchEmails(), () => API.fetchEmails(), {
        force: false,
        onSuccess: (response) => {
          return dispatch(
            entitiesReceived(normalizeEmailsResponse(response).entities)
          );
        },
      })
    );
  };
}

export function setEmailAsPrimary(id: string): ThunkAction {
  return async function (dispatch) {
    return dispatch(
      request(URLs.setEmailAsPrimary(id), () => API.setEmailAsPrimary(id), {
        onSuccess: (response) => {
          return dispatch(
            entitiesReceived(normalizeEmailsResponse(response).entities)
          );
        },
      })
    );
  };
}

export function deleteEmail(id: string): ThunkAction {
  return async function (dispatch) {
    return dispatch(
      request(`delete:${URLs.fetchEmail(id)}`, () => API.deleteEmail(id), {
        onSuccess: (response) => {
          return dispatch(entitiesDeleted({ emails: [id] }));
        },
      })
    );
  };
}

export function newEmailFormSubmit(
  requestId: string,
  values: { email: string }
): ThunkAction {
  return async function (dispatch) {
    return dispatch(
      request(requestId, () => API.createEmail(values.email), {
        onSuccess: (response) => {
          return dispatch(
            entitiesReceived(normalizeEmailResponse(response).entities)
          );
        },
      })
    );
  };
}

export function newEmailSubmit(email: string): ThunkAction {
  return async function (dispatch) {
    return dispatch(
      request(`create:${URLs.fetchEmails()}`, () => API.createEmail(email), {
        onSuccess: (response) => {
          return dispatch(
            entitiesReceived(normalizeEmailResponse(response).entities)
          );
        },
      })
    );
  };
}

export function sendEmailVerification(id: string): ThunkAction {
  return async function (dispatch) {
    return dispatch(
      request(
        URLs.sendEmailVerification(id),
        () => API.sendEmailVerification(id),
        {
          onSuccess: (response) => {
            return dispatch(
              entitiesReceived(normalizeEmailResponse(response).entities)
            );
          },
        }
      )
    );
  };
}
