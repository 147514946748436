// @flow
import { replace } from "core/lib/location";
import { collectionLayerPath } from "web/routeHelpers";

type Props = {
  params: {
    projectId: string,
    branchId: string,
    collectionId: string,
    collectionLayerId: string,
  },
};

export default function LayerPresentationRedirect(props: Props) {
  const { projectId, branchId, collectionId, collectionLayerId } = props.params;

  replace(
    collectionLayerPath(projectId, branchId, collectionId, collectionLayerId, {
      collectionLayerId,
      present: "true",
      mode: "design",
    })
  );

  return null;
}
