// @flow
import classnames from "classnames";
import * as React from "react";
import BranchStatusBadge from "core/components/BranchStatusBadge";
import Media from "core/components/Media";
import * as Branch from "core/models/branch";
import type { Branch as TBranch } from "core/types";
import style from "./style.scss";

export type Props = {
  branch: TBranch,
  light?: boolean,
  className?: string,
  selected?: boolean,
  branchReviewStatus?: string,
  shrinkAtTablet?: boolean,
  branchStatusUpdating?: boolean,
  onChangeBranchStatus?: (status: string) => void,
};

export default function BranchStatusBadges({
  branch,
  light,
  className,
  selected,
  branchReviewStatus,
  branchStatusUpdating,
  shrinkAtTablet,
  onChangeBranchStatus,
}: Props) {
  if (Branch.isMaster(branch)) {
    return null;
  }
  const divergedbranch = !!branch.divergedFromBranchId;
  const isActive = Branch.isActive(branch);
  const isStale = Branch.isStale(branch) && isActive;
  const isEditable = isActive && onChangeBranchStatus;

  const displayReviewStatus = Branch.inReview(branch) && branchReviewStatus;

  return (
    <Media xlarge>
      {(xlarge) => (
        <span className={classnames(style.badges, className)}>
          <BranchStatusBadge
            className={style.badge}
            onChangeBranchStatus={isEditable ? onChangeBranchStatus : undefined}
            currentStatus={displayReviewStatus || branch.status}
            reviewStatus={branchReviewStatus}
            selected={selected}
            light={light}
            shrinkAtTablet={shrinkAtTablet}
            disabled={branchStatusUpdating}
          />

          {divergedbranch && (xlarge || !shrinkAtTablet) && (
            <BranchStatusBadge
              className={style.badge}
              currentStatus="diverged"
              shrinkAtTablet={shrinkAtTablet}
              light={light}
            />
          )}
          {isStale && (xlarge || !shrinkAtTablet) && (
            <BranchStatusBadge
              className={style.badge}
              selected={selected}
              currentStatus="stale"
              shrinkAtTablet={shrinkAtTablet}
              light={light}
            />
          )}
        </span>
      )}
    </Media>
  );
}
