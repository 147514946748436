// @flow
import classnames from "classnames";
import { memoize } from "lodash";
import * as React from "react";
import { Waypoint } from "react-waypoint";
import Card from "core/components/Card";
import Centered from "core/components/Centered";
import NoTeams from "core/components/Empty/NoTeams";
import Expand from "core/components/Expand";
import Loading from "core/components/Loading";
import PeopleFilterMenu from "core/components/PeopleFilterMenu";
import TeamCard from "core/components/TeamCard";
import UserCard from "core/components/UserCard";
import VirtualizedList, { type Row } from "core/components/VirtualizedList";
import type { Team, User, RoleFilter } from "core/types";
import style from "./style.scss";

type Props = {
  users: User[],
  mode: "everyone" | "people",
  showFilterSubmenu?: boolean,
  searchFilter?: string,
  roleFilter: ?RoleFilter,
  isGrid: boolean,
  isOnline: boolean,
  isMobile: boolean,
  isAdmin: (userId: string) => boolean,
  isGuest: (userId: string) => boolean,
  isContributor: (userId: string) => boolean,
  isProjectAdmin?: (userId: string) => boolean,
  userCreatedAt: (user: User) => string,
  renderMemberMenu?: (input: { user?: User, team?: Team }) => React.Node,
  userTeamIds?: (userId: string) => string[],
  hideContributorBadges?: boolean,
  hasNextPage?: boolean,
  isLoadingNextPage?: boolean,
  onLoadNextPage?: () => void,
  canUseTeams?: boolean,
  isProject?: boolean,
  // Only applicable for displaying teams and projects together
  projectTeams?: Team[],
  projectTeamsPaginationTotal?: ?number,
  showExpandButton?: boolean,
  onExpandProjectTeams?: () => void,
  projectTeamsExpanded?: boolean,
  isProjectTeamsExpanding?: boolean,
  organizationId?: string,
};

export default class VirtualizedPeopleList extends React.Component<Props> {
  static defaultProps = {
    isOnline: true,
  };

  peopleList: React.Ref<VirtualizedList>;
  peopleListRef = React.createRef<VirtualizedList>();

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.searchFilter !== prevProps.searchFilter ||
      this.props.roleFilter !== prevProps.roleFilter
    ) {
      const peopleList = this.peopleListRef.current;
      if (peopleList) {
        peopleList.scrollTo({ index: 0 });
      }
    }
  }

  verticalPadding() {
    return this.props.isMobile || this.props.isGrid ? 0 : 24;
  }

  renderTeamCards = (
    items: Array<Object>,
    teams: Team[],
    expandable?: boolean
  ) => {
    const itemProps = this.props.isGrid
      ? {
          gridItem: true,
          gridRowClassName: style.gridRow,
          className: style.gridItem,
          height: ({ columnWidth }) => columnWidth - 16,
        }
      : {
          gridItem: false,
          style: (rowStyle) => ({
            ...rowStyle,
            top: rowStyle.top,
          }),
          height: 72,
        };

    const { organizationId = "" } = this.props;

    if (teams.length > 0) {
      teams.forEach((team, index) => {
        items.push({
          ...itemProps,
          key: team.id + index,
          className: classnames(style.hoverable, {
            [style.list]: !this.props.isGrid,
            [style.mobile]: this.props.isMobile,
            [style.firstListRow]: index === 0,
            [style.lastListRow]: !expandable && index === teams.length - 1,
          }),
          children: (
            <TeamCard
              params={{ organizationId }}
              className={style.team}
              highlight={this.props.searchFilter}
              team={team}
              card={this.props.isGrid}
              mobile={this.props.isMobile}
              actions={
                this.props.renderMemberMenu
                  ? this.props.renderMemberMenu({ team })
                  : undefined
              }
            />
          ),
        });
      });
    } else {
      items.push({
        gridItem: false,
        style: (rowStyle) => ({
          ...rowStyle,
          top: rowStyle.top,
        }),
        height: 280,
        key: "no-teams",
        className: classnames(style.noTeams, {
          [style.noTeamsMobile]: this.props.isMobile,
          [style.noTeamsGrid]: this.props.isGrid,
        }),
        children: <NoTeams isProject />,
      });
    }
  };

  getRoles: (
    users: User[],
    isAdmin: (userId: string) => boolean,
    isGuest: (userId: string) => boolean,
    isContributor: (userId: string) => boolean,
    isProjectAdmin?: (userId: string) => boolean
  ) => { [userId: string]: boolean } = memoize(
    (users, isAdmin, isGuest, isContributor, isProjectAdmin) => {
      const roles = {};

      users.forEach((user) => {
        if (isAdmin(user.id)) {
          roles[`${user.id}-admin`] = true;
        }

        if (isGuest(user.id)) {
          roles[`${user.id}-guest`] = true;
        }

        if (isContributor(user.id)) {
          roles[`${user.id}-contributor`] = true;
        }

        if (isProjectAdmin && isProjectAdmin(user.id)) {
          roles[`${user.id}-project-admin`] = true;
        }
      });

      return roles;
    }
  );

  getItems = (roles: { [userId: string]: boolean }): Row[] => {
    const items = [];
    const {
      canUseTeams,
      hasNextPage,
      isGrid,
      isMobile,
      isProject,
      mode,
      projectTeams,
      userTeamIds,
      showExpandButton,
      isProjectTeamsExpanding,
      roleFilter,
      onExpandProjectTeams,
      searchFilter,
      showFilterSubmenu,
      projectTeamsPaginationTotal,
      projectTeamsExpanded,
      users,
    } = this.props;

    const renderTitle = (title: string) => {
      items.push({
        height: 36,
        children: (
          <div
            className={classnames(style.sectionHeader, {
              [style.grid]: isGrid || isMobile,
            })}
          >
            {title}
          </div>
        ),
      });
    };

    const itemProps = isGrid
      ? {
          gridItem: true,
          gridRowClassName: style.gridRow,
          className: style.gridItem,
          height: ({ columnWidth }) => columnWidth - 16,
        }
      : {
          style: (rowStyle) => ({
            ...rowStyle,
            top: rowStyle.top,
          }),
          height: isMobile ? undefined : 72,
          defaultHeight: isMobile ? 90 : undefined,
        };

    if (showFilterSubmenu && canUseTeams) {
      items.push({
        height: isMobile ? 56 : 84,
        children: (
          <PeopleFilterMenu
            isGrid={isGrid}
            isMobile={isMobile}
            roleFilter={roleFilter}
            isProject={isProject}
          />
        ),
      });
    } else {
      // 24px top margin
      items.push({ height: isMobile ? 16 : 24, children: <div /> });
    }

    const renderExpandButton = (
      onExpand: () => void,
      isExpanding: boolean
    ): React.Node => {
      return (
        !searchFilter &&
        !!this.props.projectTeams &&
        projectTeamsExpanded !== undefined && (
          <Card
            className={classnames(style.team, {
              [style.expandButtonSideMargin]: isGrid,
              [style.expandButtonChin]: !isGrid,
              [style.isMobile]: isMobile,
            })}
            innerClassName={style.expandButtonInner}
            list={!isGrid}
            wrappedList={!isGrid}
          >
            <Expand
              onClick={onExpand}
              expanded={projectTeamsExpanded}
              loading={isExpanding}
              hideBorder
            >
              {projectTeamsPaginationTotal
                ? `Showing ${this.props.projectTeams.length} of
        ${projectTeamsPaginationTotal} teams`
                : `Showing ${this.props.projectTeams.length} teams`}
            </Expand>
          </Card>
        )
      );
    };

    if (canUseTeams && projectTeams) {
      renderTitle("Teams");
      this.renderTeamCards(items, projectTeams, showExpandButton);

      if (
        showExpandButton &&
        onExpandProjectTeams &&
        isProjectTeamsExpanding !== undefined
      ) {
        items.push({
          height: 48,
          className: classnames(style.hoverable, style.team, {
            [style.list]: !isGrid,
          }),
          children: renderExpandButton(
            onExpandProjectTeams,
            isProjectTeamsExpanding
          ),
        });
      }
      items.push({ height: isMobile ? 16 : 24, children: <div /> });
    }

    if (mode === "everyone" && users.length > 0) {
      renderTitle("People");
    }

    users.forEach((user, index) => {
      items.push({
        ...itemProps,
        key: user.id,
        children: (
          <UserCard
            params={{ organizationId: this.props.organizationId }}
            user={user}
            card={isGrid}
            mobile={isMobile}
            guest={roles[`${user.id}-guest`]}
            admin={roles[`${user.id}-admin`]}
            contributor={roles[`${user.id}-contributor`]}
            projectAdmin={roles[`${user.id}-project-admin`]}
            teamIds={userTeamIds && userTeamIds(user.id)}
            createdAt={this.props.userCreatedAt(user)}
            actions={
              this.props.renderMemberMenu
                ? this.props.renderMemberMenu({ user })
                : undefined
            }
            hideContributorBadge={this.props.hideContributorBadges}
            highlight={searchFilter}
            qaSelector={`user-card-${index}`}
          />
        ),
        className: isGrid
          ? undefined
          : classnames(style.listItem, {
              [style.firstListItem]: index === 0,
              [style.lastListItem]: index === users.length - 1,
            }),
      });
    });

    if (hasNextPage) {
      items.push({
        style: isGrid
          ? undefined
          : (rowStyle) => ({
              ...rowStyle,
              top: rowStyle.top + this.verticalPadding(),
            }),
        height: 48,
        children: (
          <Centered>
            <Loading small qaSelector="loading-spinner-small" />
          </Centered>
        ),
      });
    }

    if (!isGrid) {
      items.push({
        height: 24,
        children: <div></div>,
      });
    }

    return items;
  };

  renderInnerListElement = ({
    children,
    className,
    style: innerElementStyle,
    ...rest
  }: {
    children: React.Node,
    className?: string,
    style: { height: number, minHeight: number },
  }) => {
    const verticalPadding = this.verticalPadding() * 2;
    return (
      <div
        {...rest}
        className={classnames(className, {
          [style.innerListElement]: !this.props.isGrid && !this.props.isMobile,
        })}
        style={{
          ...innerElementStyle,
          height: innerElementStyle.height || 0 + verticalPadding,
          minHeight: innerElementStyle.minHeight || 0 + verticalPadding,
        }}
      >
        {children}

        {this.props.onLoadNextPage ? (
          <div className={style.waypoint}>
            <Waypoint topOffset="1440px" onEnter={this.props.onLoadNextPage} />
          </div>
        ) : null}
      </div>
    );
  };

  render() {
    const roles = this.getRoles(
      this.props.users,
      this.props.isAdmin,
      this.props.isGuest,
      this.props.isContributor,
      this.props.isProjectAdmin
    );
    const items = this.getItems(roles);

    return (
      <VirtualizedList
        ref={this.peopleListRef}
        items={items}
        innerElementType={this.renderInnerListElement}
        resizeProps={{
          projectTeamsExpanded: this.props.projectTeamsExpanded,
          projectTeamsPaginationTotal: this.props.projectTeamsPaginationTotal,
          projectTeams: this.props.projectTeams,
          searchFilter: this.props.searchFilter,
          canUseTeams: this.props.canUseTeams,
          roleFilter: this.props.roleFilter,
          isOnline: this.props.isOnline,
          isGrid: this.props.isGrid,
          ...roles,
        }}
      />
    );
  }
}
