// @flow
import { array as EMPTY_ARRAY } from "empty";

export const ROOT_LAYER_TYPES = ["artboard", "symbolMaster"];

export const CSS_PROPERTY = /^([^:]+):\s*([^;]+);/m;
export const NO_CODE = EMPTY_ARRAY;

export const TEXT_NATURAL_ALIGNMENT = 4;
export const TEXT_TRANSFORM_NONE = 0;

export const LAYER_FILL_TYPE_SOLID = 0;
export const LAYER_FILL_TYPE_GRADIENT = 1;

export const BLEND_MODE_LABELS = [
  "Normal",
  "Darken",
  "Multiply",
  "Color Burn",
  "Lighten",
  "Screen",
  "Add",
  "Overlay",
  "Soft Light",
  "Hard Light",
  "Difference",
  "Exclusion",
  "Hue",
  "Saturation",
  "Color",
  "Luminosity",
];
