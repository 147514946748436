// @flow
import * as React from "react";
import Menu from "core/components/SidebarMenu";
import MenuItem from "core/components/SidebarMenuItem";
import MenuHeader from "web/components/MenuHeader";
import {
  organizationIntegrations,
  integrationSettings,
} from "web/routeHelpers";
import style from "./style.scss";

type Props = {
  organizationId: string,
  integrationId: string,
  integrationKind: string,
  onClose: () => void,
  mobile?: boolean,
  className?: string,
};

export default function IntegrationSettingsMenu({
  organizationId,
  integrationId,
  className,
  mobile,
  onClose,
  integrationKind,
}: Props) {
  return (
    <div className={className}>
      <MenuHeader
        className={style.header}
        title={<span className={style.title}>{integrationKind} Settings</span>}
        mobileTitle={integrationKind}
        onClose={onClose}
        linkTo={organizationIntegrations(organizationId)}
        linkText="Integrations"
      />
      <Menu large mobile={mobile}>
        <MenuItem
          to={integrationSettings(
            integrationId,
            organizationId,
            integrationKind
          )}
          onClick={onClose}
        >
          Integration Details
        </MenuItem>
        {integrationKind === "Slack" && (
          <MenuItem
            to={integrationSettings(integrationId, organizationId, "channels")}
            onClick={onClose}
          >
            Per-Project Channels
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}
