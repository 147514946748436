// @flow
import * as React from "react";
import { V3Link as Link } from "core/lib/router";
import { projectPath } from "core/lib/routes";
import ActivityBase from "./Base";
import type {
  Props,
  ActivityProjectCreated as TActivityProjectCreated,
} from "./types";

export default function ActivityProjectCreated(
  props: Props<TActivityProjectCreated>
) {
  return (
    <ActivityBase
      {...props}
      icon="project"
      action="created the project"
      subject={
        <Link to={projectPath(props.activity.projectId)}>
          {props.activity.payload.projectName}
        </Link>
      }
    />
  );
}
