// @flow
import empty from "empty";
import { connect } from "react-redux";
import { goToTargetLayer } from "core/actions/prototypes";
import { withData } from "core/components/DataLoader";
import createConnector from "core/lib/createConnector";
import { withRouter } from "core/lib/router";
import { TargetLayerRequest } from "core/requests/prototypes";
import { getLayer } from "core/selectors/layers";
import {
  getTargetSha,
  getTargetLayerLoading,
  getTargetLayerMissing,
} from "core/selectors/prototypes";
import type { Dispatch, State } from "core/types";
import type {
  Props,
  OwnProps,
  LocationOwnProps,
  StateProps,
  DispatchProps,
} from "./";

function mapStateToProps(state: State, props: LocationOwnProps): StateProps {
  const target = props.link.target || undefined;
  const disabled =
    !target &&
    (!props.location.state || !props.location.state.previousPrototypeLocation);
  const targetSha = getTargetSha(state, props.params, props.link, {
    ...props.location.query,
  });

  if (target && targetSha) {
    const targetLayerDescriptor = { ...target, sha: targetSha };
    const targetLayer = getLayer(state, targetLayerDescriptor);

    return {
      targetLayerName: targetLayer ? targetLayer.name : null,
      isLoading: getTargetLayerLoading(state, props.params, props.link, {
        ...props.location.query,
      }),
      disabled,
      error: getTargetLayerMissing(state, props.params, props.link, {
        ...props.location.query,
      }),
    };
  }

  return {
    isLoading: getTargetLayerLoading(state, props.params, props.link, {
      ...props.location.query,
    }),
    disabled,
    error: getTargetLayerMissing(state, props.params, props.link, {
      ...props.location.query,
    }),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: LocationOwnProps
): DispatchProps {
  return {
    onLoad() {
      dispatch(
        TargetLayerRequest.perform({
          params: {
            descriptor: props.params,
            link: props.link,
            options: {
              sha: props.location.query.sha || undefined,
              collectionLayerId:
                props.location.query.collectionLayerId || undefined,
            },
            force: false,
          },
        })
      );
    },
    onClickTarget() {
      dispatch(
        goToTargetLayer(props.params, props.link, { ...props.location.query })
      );
    },
  };
}

export default createConnector<Props, OwnProps>(
  withRouter,
  connect<Props, LocationOwnProps, StateProps, DispatchProps, State, Dispatch>(
    mapStateToProps,
    mapDispatchToProps
  ),
  withData((props) =>
    !props.link.target
      ? empty.object // No data necessary
      : {
          ...props.link.target,
          sha: props.location.query.sha || props.params.sha,
        }
  )
);
