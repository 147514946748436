// @flow
import { connect } from "react-redux";
import { isOnline } from "abstract-di/selectors";
import { showLoadingToast } from "core/actions/toasts";
import { DEFAULT_COLLECTION_VALUES } from "core/models/collection";
import { CollectionCreateRequest } from "core/requests/collections";
import { getBranch } from "core/selectors/branches";
import type { Dispatch, State } from "core/types";
import type { Props, OwnProps, StateProps, DispatchProps } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { projectId, branchId } = props;
  return {
    branch: getBranch(state, { projectId, branchId }),
    isOffline: !isOnline(state),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    createCollection: ({ onSuccess }) => {
      const { projectId, branchId } = props;
      const loadingToast = dispatch(
        showLoadingToast({
          icon: "collection",
          text: `Creating a new collection…`,
        })
      );
      dispatch(
        CollectionCreateRequest.perform({
          params: { projectId, branchId, ...DEFAULT_COLLECTION_VALUES },
          onSuccess: (response) => {
            loadingToast.dismiss();
            const collection = response.data;
            onSuccess(collection.id);
          },
          onError: (error) => {
            loadingToast.error({
              text: "Failed to create collection",
              subtext: error.message,
            });
          },
        })
      );
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
