// @flow
import { EventTypes } from "redux-segment";
import { getNotificationsOpenState } from "core/selectors/notificationsMeta";
import type { Action, Notification, ThunkAction } from "core/types";

export function filterNotificationsByOrg(organizationId: ?string): Action {
  return {
    type: "core/FILTER_NOTIFICATIONS",
    meta: {
      organizationId,
    },
  };
}

export function notificationsLoaded(params: {
  nextOffset: ?number,
  notifications: Notification[],
  organizationId: ?string,
}): Action {
  const { nextOffset, notifications, organizationId } = params;

  return {
    type: "core/NOTIFICATIONS_LOADED",
    meta: {
      organizationId,
      nextOffset,
    },
    payload: notifications,
  };
}

export function toggleNotifications(): ThunkAction {
  return (dispatch, getState) => {
    const isOpen = getNotificationsOpenState(getState());

    if (isOpen) {
      dispatch(closeNotifications());
    } else {
      dispatch(openNotifications());
    }
  };
}

export function closeNotifications(): Action {
  return {
    type: "core/NOTIFICATIONS_CLOSE",
  };
}

export function openNotifications(): Action {
  return {
    type: "core/NOTIFICATIONS_OPEN",
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: "NOTIFICATIONS_OPEN",
        },
      },
    },
  };
}
