// @flow
import { static as Immutable } from "seamless-immutable";
import type { Action } from "core/types";

type LastVisited = {
  [guid: string]: {
    lastVisitedAt: Date,
  },
};

// we want to update the lastVisitedAt value only if
// the last time visited was more than 1 minute ago
const MINIMUM_TIME_DIFF = 60 * 1000;

export default function lastVisited(
  state: LastVisited = Immutable.from({}),
  action: Action
) {
  switch (action.type) {
    case "core/PROJECT_VISITED": {
      const { guid } = action.meta;
      const currentLastVisitedAt = state[guid]
        ? state[guid].lastVisitedAt
        : undefined;
      const newLastVisitedAt = new Date();

      if (
        !currentLastVisitedAt ||
        newLastVisitedAt - currentLastVisitedAt > MINIMUM_TIME_DIFF
      ) {
        return Immutable.setIn(state, [guid], {
          lastVisitedAt: newLastVisitedAt,
        });
      }

      return state;
    }
    default:
      return state;
  }
}
