// @flow
import { camelCase } from "lodash";
import { BranchStatus } from "core/gitConstants";
import { ReviewStatuses } from "core/models/review";
import type { Branch, BranchStatusTypes } from "core/types";

const mapping = {
  [BranchStatus.NONE]: {
    name: "Work in progress",
    icon: "branch-status-work-in-progress",
  },
  [BranchStatus.WIP]: {
    name: "Work in progress",
    icon: "branch-status-work-in-progress",
  },
  [BranchStatus.FEEDBACK]: {
    name: "Open for feedback",
    icon: "branch-status-open-for-feedback",
  },
  [BranchStatus.PAUSED]: {
    name: "Paused",
    icon: "branch-status-paused",
  },
  [BranchStatus.REVIEW]: {
    name: "Review requested",
    icon: "branch-status-review-requested",
  },
  [BranchStatus.DEVELOPMENT]: {
    name: "In development",
    icon: "branch-status-in-development",
    type: "purple",
  },
  [BranchStatus.MERGED]: {
    name: "Merged",
  },
  [BranchStatus.ARCHIVED]: {
    name: "Archived",
  },
  [BranchStatus.DELETED]: {
    name: "Deleted",
  },
  [BranchStatus.DIVERGED]: {
    name: "Diverged Copy",
    type: "error",
  },
  [BranchStatus.STALE]: {
    name: "Stale",
  },
  // review statuses
  [ReviewStatuses.APPROVED]: {
    name: "Approved",
    icon: "branch-status-review-request-approved",
    type: "allgood",
  },
  [ReviewStatuses.REJECTED]: {
    name: "Changes requested",
    icon: "branch-status-review-request-changes-requested",
    type: "warning",
  },
  [ReviewStatuses.REQUESTED]: {
    name: "Review requested",
    icon: "branch-status-review-requested",
  },
};

export function branchStatusReadable(status: string): string {
  return mapping[status]["name"] || "";
}

export function branchStatusIcon(status: string): string {
  return mapping[status]["icon"] || undefined;
}

export function branchStatusType(status: string): BranchStatusTypes {
  return mapping[status]["type"] || "defaultStatus";
}

export function branchNameWithBranch(branch: Branch) {
  if (branch && branch.name) {
    return branch.name.replace(" (Diverged Copy)", "");
  }
  return "";
}

export function branchStatusClassName(status: string) {
  return camelCase(`status ${status}`);
}
