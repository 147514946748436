// @flow
import * as React from "react";
import Commit from "core/components/Commit";
import CommitCompact from "core/components/CommitCompact";
import CommitMenu from "core/components/CommitMenu";
import { CommitTypes } from "core/gitConstants";
import { commitPath } from "core/lib/routes";
import * as CommitModel from "core/models/commit";
import type { Project, Branch, Commit as TCommit } from "core/types";
import connector from "./connector";

export type DefaultProps = {|
  commentCount: number,
|};

export type OwnProps = {|
  ...DefaultProps,
  project: ?Project,
  branch: ?Branch,
  parent?: ?Branch,
  commit: TCommit,
  commitPath?: (string) => *,
  className?: string,
  onClick?: (event: SyntheticEvent<>, commit: TCommit) => *,
  goToCommit?: boolean,
  selected?: boolean,
  icon?: string,
  reference?: boolean,
  current?: boolean,
  first?: boolean,
  firstOfGroup?: boolean,
  last?: boolean,
  lastOfGroup?: boolean,
  compact?: boolean,
  selectedClassName?: string,
  isFocused?: boolean,
|};

export type StateProps = {|
  parentBranchName: string,
  pending?: boolean,
|};

export type Props = {|
  ...OwnProps,
  ...StateProps,
|};

class CommitListItem extends React.Component<Props> {
  static defaultProps: DefaultProps = {
    commentCount: 0,
  };

  commitPath = (sha: string) => {
    if (!this.props.branch || !this.props.branch.id) {
      return "";
    } // no-op until the branch completely loads on desktop
    if (!this.props.project) {
      return "";
    }
    if (this.props.commitPath) {
      return this.props.commitPath(sha);
    }
    return commitPath(this.props.project.id, this.props.branch.id, sha);
  };

  handleClick = (event: SyntheticEvent<>) => {
    if (this.props.onClick) {
      this.props.onClick(event, this.props.commit);
    }
  };

  render() {
    const { commit, compact, branch, parentBranchName, project } = this.props;
    const type = CommitModel.type(commit, branch);

    const CommitComponent = compact ? CommitCompact : Commit;

    return (
      <CommitMenu
        goToCommitVisible={this.props.goToCommit}
        organizationId={project ? project.organizationId : ""}
        branchId={branch ? branch.id : ""}
        projectId={project ? project.id : ""}
        sha={commit.sha}
      >
        {(showMenu, ref) => {
          return (
            <CommitComponent
              innerRef={ref}
              type={type}
              className={this.props.className}
              authorName={commit.userName}
              commentCount={this.props.commentCount}
              commitPath={this.commitPath}
              current={this.props.current}
              pending={this.props.pending}
              first={this.props.first}
              firstOfGroup={this.props.firstOfGroup}
              icon={this.props.icon}
              last={this.props.last}
              lastOfGroup={this.props.lastOfGroup}
              onClick={this.handleClick}
              onContextMenu={showMenu}
              reference={this.props.reference}
              selected={this.props.selected}
              selectedClassName={this.props.selectedClassName}
              isFocused={this.props.isFocused}
              sha={commit.sha}
              time={commit.time}
              title={
                type === CommitTypes.BRANCH
                  ? `Branched from "${parentBranchName}"`
                  : commit.title || ""
              }
            />
          );
        }}
      </CommitMenu>
    );
  }
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default connector(CommitListItem);
