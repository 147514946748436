// @flow
import { connect } from "react-redux";
import { withData } from "core/components/DataLoader";
import createConnector from "core/lib/createConnector";
import { OrganizationTeamFetchRequest } from "core/requests/teams";
import { getTeam } from "core/selectors/teams";

import type { State, Dispatch } from "core/types";
import type { OwnProps, DispatchProps, Props } from "./";

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    onLoad: () => {
      dispatch((dispatch, getState) => {
        props.teamIds &&
          props.teamIds.forEach((teamId) => {
            const team = getTeam(getState(), { teamId });
            if (!team && props.organizationId) {
              dispatch(
                OrganizationTeamFetchRequest.perform({
                  params: {
                    organizationId: props.organizationId,
                    teamId,
                  },
                })
              );
            }
          });
      });
    },
  };
}

export default createConnector<Props, OwnProps>(
  connect<Props, OwnProps, _, DispatchProps, State, Dispatch>(
    undefined,
    mapDispatchToProps
  ),
  withData((props: Props) => ({ organizationId: props.organizationId }))
);
