// @flow
import { connect } from "react-redux";
import { getSection } from "core/selectors/sections";
import { isStarred } from "core/selectors/stars";
import type { State, Dispatch } from "core/types";
import type { Props, OwnProps, StateProps } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { id: sectionId } = props.section;
  return {
    isStarred: isStarred(state, { starrableId: sectionId }),
    section: getSection(state, { sectionId }) || props.section,
  };
}

export default connect<Props, OwnProps, StateProps, _, State, Dispatch>(
  mapStateToProps
);
