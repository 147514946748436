// @flow
import * as React from "react";
import ExternalLink from "core/components/ExternalLink";
import FormNotice from "core/components/FormNotice";
import { readableFilesize } from "core/lib/textFormatting";
import { helpFileSize } from "core/lib/urls";
import connector from "./connector";
import style from "./style.scss";

const BYTES_IN_MEGABYTE = Math.pow(2, 20);
const LARGE_FILE_THRESHOLD = 250 * BYTES_IN_MEGABYTE;
const LARGE_COMMIT_THRESHOLD = 25 * BYTES_IN_MEGABYTE;

export function isLargeFile(size: number) {
  return size >= LARGE_FILE_THRESHOLD;
}

export function isLargeCommit(size: number) {
  return size >= LARGE_COMMIT_THRESHOLD;
}

export type OwnProps = {|
  className?: string,
  importingFiles?: boolean,
  projectId: string,
  size: number,
|};

export type DispatchProps = {|
  trackRender: () => void,
|};

export type Props = {|
  ...OwnProps,
  ...DispatchProps,
|};

function LargeCommitWarning(props: Props) {
  React.useEffect(props.trackRender, [props.projectId, props.size]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FormNotice
      className={props.className}
      heading={`Your project will grow by ${readableFilesize(
        props.size
      )} from this commit`}
      body={
        <React.Fragment>
          Large files might cause longer syncing times for your organization.
          Consider removing or optimizing large image files (JPEG, PNG, etc.)
          {props.importingFiles ? " before adding these files" : ""}.{" "}
          <ExternalLink href={helpFileSize()} className={style.learnMoreLink}>
            Learn more
          </ExternalLink>
        </React.Fragment>
      }
    ></FormNotice>
  );
}

export default connector(LargeCommitWarning);
