// @flow
import classnames from "classnames";
import * as React from "react";
import NoResults from "core/components/Empty/NoResults";
import Flex from "core/components/Flex";
import InputSearch from "core/components/InputSearch";
import InviteUsers from "core/components/InviteUsers";
import Loaded from "core/components/Loaded";
import OrganizationMembershipsDataLoader from "core/components/OrganizationMembershipsLoader";
import Tab from "core/components/Tab";
import Tabs from "core/components/Tabs";
import type {
  User,
  Team,
  Organization,
  TeamMembership,
  Subscription,
} from "core/types";
import UserAddList from "./UserAddList";
import connector from "./connector";
import style from "./style.scss";

const TABS = {
  add: "add",
  invite: "invite",
};

export type OwnProps = {|
  isOpen: boolean,
  onDismissDialog: () => void,
  organizationId: string,
  team: Team,
|};

export type StateProps = {|
  canAddGuests: boolean,
  organization: ?Organization,
  organizationMembershipsLoading: boolean,
  teamMemberships: TeamMembership[],
  users: User[],
  subscription: ?Subscription,
|};

export type DispatchProps = {|
  onAddMember: (projectId: string, userId: string) => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

function AddUsersToTeamDialog(props: Props) {
  const [searchFilter, setSearchFilter] = React.useState("");
  const [selected, setSelected] = React.useState(TABS.add);

  return (
    <React.Fragment>
      <Tabs className={style.tabs}>
        <Tab
          label="Organization Members"
          selected={selected === TABS.add}
          onClick={() => setSelected(TABS.add)}
        />
        {props.canAddGuests && (
          <Tab
            label="Invite Guests"
            selected={selected === TABS.invite}
            onClick={() => setSelected(TABS.invite)}
          />
        )}
      </Tabs>
      {selected === TABS.add && (
        <OrganizationMembershipsDataLoader
          organizationId={props.organizationId}
          searchFilter={searchFilter}
        >
          {({ hasNextPage, isLoadingNextPage, items, onLoadNextPage }) => (
            <React.Fragment>
              <InputSearch
                wrapperClass={style.filterWrapper}
                placeholder="Filter organization members…"
                value={searchFilter}
                onChange={(event: SyntheticInputEvent<>) => {
                  setSearchFilter(event.target.value);
                }}
                autoFocus
              />
              <Flex className={style.contentWrapper} column>
                <Loaded
                  loading={items.length === 0 && isLoadingNextPage}
                  title="Loading organization members…"
                  className={style.content}
                >
                  {items.length === 0 ? (
                    <div className={classnames(style.content, style.noResults)}>
                      <NoResults type="people" term={searchFilter} flex />
                    </div>
                  ) : (
                    <div className={style.content}>
                      <UserAddList
                        filter={searchFilter}
                        onAddMember={props.onAddMember}
                        team={props.team}
                        users={items}
                        isLoadingNextPage={isLoadingNextPage}
                        onLoadNextPage={onLoadNextPage}
                      />
                    </div>
                  )}
                </Loaded>
              </Flex>
            </React.Fragment>
          )}
        </OrganizationMembershipsDataLoader>
      )}
      {selected === TABS.invite && props.organization && (
        <InviteUsers
          organization={props.organization}
          onDismissDialog={props.onDismissDialog}
          subscription={props.subscription}
          team={props.team}
        />
      )}
    </React.Fragment>
  );
}

export default connector(AddUsersToTeamDialog);
