// @flow
import classnames from "classnames";
import * as React from "react";
import BranchListItem from "core/components/BranchListItem";
import Card from "core/components/Card";
import { useBranches, useBranchReviewRequests } from "core/hooks/branches";
import { useProject } from "core/hooks/projects";
import { branchPath } from "core/lib/routes";
import type { BranchReview as BranchReviewType } from "core/types";
import ProjectHeader from "./ProjectHeader";
import style from "./style.scss";

type Props = {
  review: BranchReviewType,
  mobile?: boolean,
};

const BranchReview = ({ review: { branchId, projectId }, mobile }: Props) => {
  const project = useProject(projectId);
  const branches = useBranches({ projectId });
  const branch = branches.find((b) => b.id === branchId);
  const reviewRequests = useBranchReviewRequests({ projectId, branchId });

  if (project && branch) {
    return (
      <Card
        className={classnames(style.project, { [style.mobile]: mobile })}
        mobile={mobile}
        innerClassName={style.inner}
        header={<ProjectHeader project={project} />}
        hasList
      >
        <BranchListItem
          mobile={mobile}
          to={branchPath(projectId, branchId)}
          branch={branch}
          project={project}
          reviewRequests={reviewRequests}
          className={style.projectNestedBranch}
        />
      </Card>
    );
  }

  return null;
};

export default BranchReview;
