// @flow
import { normalize, schema } from "normalizr";

export const starSchema = new schema.Entity(
  "stars",
  {},
  { idAttribute: (star) => star.starrableId }
);
export const starsSchema = new schema.Array(starSchema);

export function normalizeStars(stars: *) {
  return normalize(stars, starsSchema);
}
