// @flow
import React from "react";
import image from "./images/key.svg";
import Empty from ".";

export default function NoOAuthApplications(props: {}) {
  return (
    <Empty
      {...props}
      image={image}
      title="You haven’t created any OAuth applications"
      description="Create your first application by clicking “Create Application”."
    />
  );
}
