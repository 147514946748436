// @flow
import { connect } from "react-redux";
import {
  getActiveProjectsForOrganization,
  getProject,
} from "core/selectors/projects";
import type { State } from "core/types";
import type { OwnProps, StateProps } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { organizationId } = props.params;
  const { projectId } = props.location.query;
  const project = projectId ? getProject(state, { projectId }) : null;
  return {
    projectId,
    organizationId,
    project,
    projects: getActiveProjectsForOrganization(state, {
      organizationId,
      projectId,
    }),
  };
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default connect(mapStateToProps);
