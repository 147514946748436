// @flow
/* global Element window */
import { findIndex } from "lodash";
import * as React from "react";
import * as ReactDOM from "react-dom";
import AutoSizer from "react-virtualized-auto-sizer";
import FixedList from "./FixedList";
import VariableList from "./VariableList";

export type List = FixedList | VariableList;

export type InnerListProps = {
  children: React.Element<*>[],
  style: { minHeight?: number },
};

export type Row = {
  gridItem?: boolean,
  gridRow?: boolean,
  items?: Row[],
  height?: number | (({ columnWidth: number }) => number),
  defaultHeight?: number,
  className?: string,
  gridRowClassName?: string,
  data?: Object,
  key?: string,
  scrollId?: string,
  groupId?: string,
  style?: (rowStyle: { top: number }) => {},
  children: React.Node,
};

type Props = {
  className?: string,
  fixed?: boolean,
  width?: number,
  height?: number,
  items: Object[],
  itemSize?: number,
  groupChildren?: boolean,
  children?: (Object) => React.Node,
};

export default class VirtualizedList extends React.Component<Props> {
  listRef = React.createRef<List>();

  resize = () => {
    const list = this.listRef.current;
    if (list) {
      list.resize();
    }
  };

  scrollTo = (
    {
      id,
      index,
      offset,
      bottom,
    }: { id?: string, index?: number, offset?: number, bottom?: boolean },
    onScrolled?: () => void
  ) => {
    const handleScroll = () => {
      const list = this.listRef.current;
      const listNode = ReactDOM.findDOMNode(list);

      if (!list || !listNode) {
        return;
      }

      const reactWindowList = list.reactWindowListRef.current;

      if (index !== undefined) {
        if (reactWindowList) {
          reactWindowList.scrollTo(index);
        }
      } else if (bottom && listNode instanceof Element) {
        if (reactWindowList) {
          reactWindowList.scrollToItem(this.props.items.length - 1);
        }
      } else if (id && list.rows) {
        const rowIndex = findIndex(list.rows, { scrollId: id });
        if (rowIndex > -1 && reactWindowList && listNode instanceof Element) {
          const rowStyle = reactWindowList._getItemStyle(rowIndex);
          listNode.scrollTop = rowStyle.top + (offset || 0);
        }
      }

      if (onScrolled) {
        onScrolled();
      }
    };

    if (this.props.height === undefined || this.props.width === undefined) {
      // We are delaying access to scrolling to let the AutoSizer component complete measuring the size of the children component this scroll method. Otherwise, VirtualizedList will not have a valid reference to the DOM element (this.listRef) to scroll.
      // reference: https://github.com/bvaughn/react-virtualized-auto-sizer/blob/ffcba2dd39b89111ed4b42d64431f35ce7c1c23a/src/index.js#L121
      window.requestAnimationFrame(() => {
        handleScroll();
      });
    } else {
      handleScroll();
    }
  };

  render() {
    const { fixed, ...rest } = this.props;
    const hasHeight = rest.height !== undefined;
    const hasWidth = rest.width !== undefined;
    const List = fixed ? FixedList : VariableList;

    if (!hasHeight || !hasWidth) {
      return (
        <AutoSizer>
          {({ height, width }) =>
            width || hasWidth ? (
              <List
                {...rest}
                ref={this.listRef}
                height={hasHeight ? rest.height : height}
                width={hasWidth ? rest.width : width}
              />
            ) : null
          }
        </AutoSizer>
      );
    }

    return <List {...rest} ref={this.listRef} />;
  }
}
