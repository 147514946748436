// @flow
import classnames from "classnames";
import * as React from "react";
import Button from "../Button";
import image from "./images/search.svg";
import style from "./style.scss";
import Empty from ".";

type Props = {
  type?: string,
  term: string,
  mini?: boolean,
  onClearFilters?: () => void,
  className?: string,
};

export default function NoResults({
  type,
  term,
  mini,
  className,
  onClearFilters,
  ...rest
}: Props) {
  const subject = type ? `any ${type}` : "anything";

  return (
    <Empty
      {...rest}
      image={image}
      title="No results"
      className={classnames(className, { [style.miniNoResults]: mini })}
      description={
        <span>
          We couldn’t find {subject} matching <strong>{term}</strong>
        </span>
      }
    >
      {onClearFilters && (
        <span>
          <Button onClick={onClearFilters}>Clear filters</Button>
        </span>
      )}
    </Empty>
  );
}
