// @flow
import classnames from "classnames";
import * as React from "react";
import style from "./style.scss";

type Props = {
  className?: string,
  small: boolean,
  tint: boolean,
  light: boolean,
  qaSelector?: string,
};

export default class Spinner extends React.PureComponent<Props> {
  static defaultProps = { small: false, tint: false, light: false };

  render() {
    const { className, small, tint, light, qaSelector } = this.props;
    const classes = classnames(className, {
      [style.spinner]: true,
      [style.tint]: tint,
      [style.light]: light,
      [style.small]: small,
    });

    return (
      <span className={classes} data-qa={qaSelector || "spinner-icon"}>
        <ul>
          <li>
            <span />
          </li>
          <li>
            <span />
          </li>
          <li>
            <span />
          </li>
          <li>
            <span />
          </li>
          <li>
            <span />
          </li>
          <li>
            <span />
          </li>
          <li>
            <span />
          </li>
          <li>
            <span />
          </li>
          <li>
            <span />
          </li>
          <li>
            <span />
          </li>
          <li>
            <span />
          </li>
          <li>
            <span />
          </li>
        </ul>
      </span>
    );
  }
}
