// @flow
import classnames from "classnames";
import * as React from "react";
import Button from "core/components/Button";
import style from "./style.scss";

type Props = {
  scrollToGroup: () => void,
  isLoggedIn: boolean,
  placement?: "above" | "below",
};

export default class ScrollButton extends React.Component<Props> {
  render() {
    if (!this.props.placement) {
      return null;
    }

    return (
      <div
        className={classnames(style.scrollButton, {
          [style.scrollButtonTop]: this.props.placement === "above",
          [style.scrollButtonBottom]: this.props.placement === "below",
          [style.scrollButtonLoggedOut]: !this.props.isLoggedIn,
        })}
      >
        <Button primary onClick={this.props.scrollToGroup}>
          Scroll to current commit
        </Button>
      </div>
    );
  }
}
