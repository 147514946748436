// @flow
import * as Abstract from "abstract-sdk";
import apiRequest from "abstract-di/api";
import { organizationPath } from "abstract-di/routes";
import { entitiesReceived } from "core/actions/entities";
import abstract from "core/lib/abstract";
import { push } from "core/lib/location";
import defineRequest from "core/requests";
import { FeaturesFetchRequest } from "core/requests/features";
import {
  normalizeOrganizationResponse,
  normalizeOrganizationsResponse,
} from "core/schemas/organization";

export const OrganizationsFetchRequest = defineRequest<void, void>({
  id() {
    return "get:organizations";
  },
  perform() {
    // TODO: Update SDK to use version 27 once deployed
    return abstract.organizations.list();
  },
  onSuccess(response, params, dispatch) {
    const { entities } = normalizeOrganizationsResponse(
      abstract.unwrap(response)
    );
    dispatch(entitiesReceived(entities));
  },
  force: false,
});

export const OrganizationFetchRequest = defineRequest<
  Abstract.OrganizationDescriptor,
  Abstract.OrganizationDescriptor,
>({
  id: (params) => {
    return ["organizations", params.organizationId].join("-");
  },
  perform: (descriptor) => {
    // TODO: Update SDK to use version 27 once deployed
    return abstract.organizations.info(descriptor);
  },
  onSuccess: (response, params, dispatch) => {
    const apiResponse = abstract.unwrap(response);
    const { entities } = normalizeOrganizationResponse(apiResponse);
    dispatch(entitiesReceived(entities));
  },
  force: false,
});

export type SourceTypes = "onboarding" | null;
export type CreateOrganizationParams = {|
  name: string,
  totalDesignersSizeRange?: string,
  jobFunction?: string,
  phoneNumber?: string,
  requestId?: string,
  onboarding?: boolean,
  source?: SourceTypes,
  recurlyTokenId?: ?string,
  recurlyActionResultToken?: ?string,
  accountCode?: ?string,
|};

export const CreateOrganizationRequest = defineRequest<
  CreateOrganizationParams,
  CreateOrganizationParams,
>({
  id: (params) => {
    return params.requestId || `post:organizations`;
  },
  perform: (params) => {
    // 27 removes feature flags from the response payload
    const API_VERSION = 27;
    const {
      name,
      totalDesignersSizeRange,
      jobFunction,
      phoneNumber,
      recurlyTokenId,
      recurlyActionResultToken,
      accountCode,
    } = params;

    return apiRequest(
      "post",
      "organizations",
      {
        name,
        totalDesignersSizeRange,
        jobFunction,
        phoneNumber,
        tokenId: recurlyTokenId,
        actionResultToken: recurlyActionResultToken,
        accountCode,
      },
      API_VERSION
    );
  },
  onSuccess: (response, params, dispatch) => {
    const organization = response.data;
    const { entities } = normalizeOrganizationResponse(response);
    dispatch(entitiesReceived(entities));

    dispatch(
      FeaturesFetchRequest.perform({
        params: { organizationId: organization.id },
      })
    );

    if (params.onboarding === undefined || !params.onboarding) {
      return push(organizationPath(organization.id));
    }
  },
});

export const MatchingOrganizationsFetchRequest = defineRequest<{}, {}>({
  id: () => {
    return "get:organizations/domain_search";
  },
  perform: () => {
    return apiRequest("get", "organizations/domain_search");
  },
});

type OrganizationOnboardingAttributesParams = {|
  organizationId: string,
  hasCompletedProjectWizard: boolean,
|};

export const OrganizationOnboardingAttributesUpdateRequest = defineRequest<
  OrganizationOnboardingAttributesParams,
  OrganizationOnboardingAttributesParams,
>({
  id: (params) => {
    const { organizationId } = params;
    return `put:organizations/${organizationId}/onboarding_attributes`;
  },
  perform: (params) => {
    const { hasCompletedProjectWizard, organizationId } = params;
    return apiRequest(
      "put",
      `organizations/${organizationId}/onboarding_attributes`,
      {
        hasCompletedProjectWizard,
      }
    );
  },
  onSuccess: (response, params, dispatch) => {
    const { entities } = normalizeOrganizationResponse(response);
    dispatch(entitiesReceived(entities));
  },
});
