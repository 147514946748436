// @flow
/* global window, document */
import authedDownload from "core/lib/authedDownload";
import { fileName } from "core/models/asset";
import type { Asset } from "core/types";

export async function downloadAssetInline(asset: Asset) {
  const url = await authedDownload(asset.url);
  const a = document.createElement("a");
  document.body && document.body.appendChild(a);
  a.style.display = "none";
  a.href = url;
  a.download = fileName(asset);
  a.click();
  window.URL.revokeObjectURL(url);
}
