// @flow
import { connect } from "react-redux";
import { fetchProjectReviews } from "core/actions/reviews";
import * as Request from "core/models/request";
import { BranchesFetchAllRequest } from "core/requests/branches";
import { OrganizationFetchRequest } from "core/requests/organizations";
import { ProjectFetchRequest } from "core/requests/projects";
import { getOrganizationForProject } from "core/selectors/organizations";
import type { Dispatch, State } from "core/types";
import { loadOrganization } from "web/actions/organizations";
import { loadProject } from "web/actions/projects";
import Project, {
  type Props,
  type OwnProps,
  type StateProps,
  type DispatchProps,
} from "web/components/Project";
import { getProject } from "web/selectors/projects";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { projectId } = props.params;
  const project = getProject(state, projectId);
  const projectRequest = ProjectFetchRequest.getRequest(state, { projectId });
  const organization = getOrganizationForProject(state, { projectId });
  const isOrganizationLoading = project
    ? OrganizationFetchRequest.isLoading(state, {
        organizationId: project.organizationId,
      })
    : false;

  return {
    project: getProject(state, projectId),
    isLoading:
      (!project && Request.isLoading(projectRequest)) ||
      (!organization && isOrganizationLoading),
    notFound: Request.notFound(projectRequest),
    forbidden: Request.forbidden(projectRequest),
    error: Request.unexpectedError(projectRequest),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { projectId } = props.params;
  return {
    onLoad() {
      dispatch(
        BranchesFetchAllRequest.perform({
          params: { projectId, filter: "active" },
        })
      );
      dispatch(fetchProjectReviews(projectId));
      dispatch(async (dispatch, getState) => {
        await dispatch(loadProject(projectId));
        const project = getProject(getState(), projectId);
        project && dispatch(loadOrganization(project.organizationId));
      });
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(
  mapStateToProps,
  mapDispatchToProps
)(Project);
