// @flow
import * as React from "react";
import DialogForm from "core/components/DialogForm";
import ExternalLink from "core/components/ExternalLink";
import Icon from "core/components/Icon";
import { helpTeamsUrl } from "core/lib/urls";
import type { Organization } from "core/types";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  organization: ?Organization,
|};

export type StateProps = {|
  canDisplayTeamsAnnouncement: boolean,
|};

export type StorageProps = {|
  hasDisplayed: boolean,
  setHasDisplayed: () => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...StorageProps,
};

function TeamsAnnouncement(props: Props) {
  const showAnnouncement =
    props.canDisplayTeamsAnnouncement && !props.hasDisplayed;

  return props.canDisplayTeamsAnnouncement ? (
    <DialogForm
      isOpen={showAnnouncement}
      title="Introducing Teams"
      primaryButton="Got it"
      onClose={props.setHasDisplayed}
      onSubmit={() => props.setHasDisplayed()}
    >
      <span
        className={style.teams}
        role="img"
        aria-label="Teams Announcement"
      />
      <p>
        Now, you can better organize the growing number of people and projects
        in your organization by grouping them into Teams.
      </p>
      <p>To create a Team, click on People & Teams in your sidebar.</p>
      <div>
        <ExternalLink className={style.learnMore} href={helpTeamsUrl()}>
          Learn more about Teams
          <Icon type="external" tint />
        </ExternalLink>
      </div>
    </DialogForm>
  ) : null;
}

export default connector(TeamsAnnouncement);
