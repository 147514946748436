// @flow
import * as React from "react";
import Head from "core/components/Head";
import type {
  Project,
  Branch,
  User,
  Page,
  File,
  FilePreviews,
  Comment,
  Commit as TCommit,
  Changeset as TChangeset,
  ChangesetStatus,
  ChangeStatusCounts,
} from "core/types";
import Changeset from "./Changeset";
import Comments from "./Comments";
import Header from "./Header";
import style from "./style.scss";

type Props = {
  branch: Branch,
  changeset: TChangeset,
  changeStatusCounts: ?ChangeStatusCounts,
  comments: Comment[],
  commit: TCommit,
  files: File[],
  currentUserId?: string,
  commentId: string,
  hash: string,
  filter?: string,
  previewsByFile: FilePreviews,
  layerStatuses: { [layerId: string]: ChangesetStatus },
  layerCommentCounts: { [layerId: string]: number },
  pages: { [pageId: string]: Page },
  project: Project,
  user: User,
  canShare: boolean,
};

type State = { query: string };

export default class Commit extends React.Component<Props, State> {
  listMounted = false;

  state = { query: "" };
  previewsRef = React.createRef<*>();

  componentDidMount() {
    const scrollToId =
      this.props.hash === "#comments" || this.props.commentId
        ? "footer"
        : this.props.hash.slice(1, this.props.hash.length);

    if (scrollToId) {
      this.scrollToRow({
        id: scrollToId,
        onScrolled: this.scrollToComments,
      });
    }
  }

  componentWillUnmount() {
    this.listMounted = false;
  }

  scrollToRow = ({
    id,
    offset,
    onScrolled,
  }: {
    id: string,
    offset?: number,
    onScrolled?: () => void,
  }) => {
    const previews = this.previewsRef.current;
    if (previews) {
      previews.scrollTo({ id, offset, onScrolled });
    }
  };

  scrollToComments = (onScrolled?: () => void) => {
    this.scrollToRow({ id: "footer", onScrolled });
  };

  scrollToBottom = () => {
    const previews = this.previewsRef.current;
    if (previews) {
      previews.scrollTo({ bottom: true });
    }
  };

  handleSearchFilterChange = (event: SyntheticInputEvent<>) => {
    this.setState({ query: event.target.value });
  };

  render() {
    const {
      branch,
      changeset,
      changeStatusCounts,
      comments,
      commit,
      user,
      files,
      previewsByFile,
      filter,
      layerStatuses,
      layerCommentCounts,
      pages,
      project,
      canShare,
    } = this.props;

    return (
      <div className={style.wrapper}>
        <Head>
          <title>{commit.title}</title>
        </Head>
        <div className={style.content}>
          <Changeset
            projectId={project.id}
            branchId={branch.id}
            sha={changeset.sha}
            changeset={changeset}
            commit={commit}
            previewsRef={this.previewsRef}
            files={files}
            previewsByFile={previewsByFile}
            filter={filter}
            layerStatuses={layerStatuses}
            layerCommentCounts={layerCommentCounts}
            pages={pages}
            query={this.state.query}
            branch={branch}
            onSearchFilter={this.handleSearchFilterChange}
            changeStatusCounts={changeStatusCounts}
            header={
              <Header
                commit={commit}
                comments={comments}
                changeset={changeset}
                user={user}
                filter={filter}
                project={project}
                branchId={branch.id}
                pages={pages}
                files={files}
                previewsByFile={previewsByFile}
                onCommentsClick={this.scrollToComments}
                onScrollToRow={this.scrollToRow}
                canShare={canShare}
              />
            }
            footer={
              branch && commit && project ? (
                <Comments
                  projectId={project.id}
                  branchId={branch.id}
                  commitSha={commit.sha}
                  autoMarkAsRead={!this.listMounted}
                  scrollToBottom={this.scrollToBottom}
                />
              ) : null
            }
          />
        </div>
      </div>
    );
  }
}
