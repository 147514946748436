// @flow
import { omit, pickBy, uniq, without } from "lodash";
import { EventTypes } from "redux-segment";
import uuid from "uuid/v4";
import { getCurrentUser } from "abstract-di/selectors";
import {
  entitiesReceived,
  entitiesDeleted,
  entitiesReplaced,
} from "core/actions/entities";
import { showToast } from "core/actions/toasts";
import {
  CommentsFetchRequest,
  RepliesFetchRequest,
  CreateCommentRequest,
  UpdateCommentRequest,
  DeleteCommentRequest,
} from "core/requests/comments";
import {
  normalizeCommentsResponse,
  normalizeComments,
  normalizeComment,
} from "core/schemas/comment";
import { getComment } from "core/selectors/comments";
import type { Comment, Activity, ThunkAction, User } from "core/types";

const updatedCommentEvent = (projectId: string) => ({
  type: "core/COMMENT_UPDATED",
  meta: {
    analytics: {
      eventType: EventTypes.track,
      eventPayload: { event: "COMMENT_UPDATED", properties: { projectId } },
    },
  },
});

const createdCommentEvent = (properties: { [key: string]: any }) => {
  return {
    type: "core/COMMENT_CREATED",
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: "COMMENT_CREATED",
          properties: pickBy(properties, (property) => property !== null),
        },
      },
    },
  };
};

export function fetchComments({
  projectId,
  branchId,
  commitSha,
  fileId,
  layerId,
  collectionId,
  contentId,
  includeLastCommentViews,
  limit = 1000,
  offset = 0,
}: {
  projectId: string,
  branchId?: string,
  commitSha?: string,
  fileId?: string,
  layerId?: string,
  collectionId?: string,
  contentId?: string,
  includeLastCommentViews?: boolean,
  limit?: number,
  offset?: number,
}): ThunkAction {
  return async function (dispatch) {
    let params = { projectId, limit, offset };

    if (commitSha) {
      params = { ...params, commitSha };
    }
    if (fileId) {
      params = { ...params, fileId };
    }
    if (layerId) {
      params = { ...params, layerId };
    }
    if (collectionId) {
      params = { ...params, collectionId };
    }
    if (branchId) {
      params = { ...params, branchId };
    }
    if (contentId) {
      params = { ...params, contentId };
    }
    if (includeLastCommentViews) {
      params = { ...params, includeLastCommentViews };
    }

    return dispatch(
      CommentsFetchRequest.perform({
        params,
        onSuccess: (response) => {
          dispatch(
            entitiesReceived(normalizeCommentsResponse(response.data).entities)
          );
        },
        force: false,
        invalidateable: true,
      })
    );
  };
}

export function createdComment(
  comment: Comment,
  activity?: Activity
): ThunkAction {
  return function (dispatch, getState) {
    const state = getState();

    let comments = [comment];
    if (comment.parentId) {
      const parent = getComment(state, { id: comment.parentId });
      if (parent) {
        comments = comments.concat({
          ...parent,
          replyIds: uniq(parent.replyIds.concat(comment.id)),
        });
      }
    }

    let { entities } = normalizeComments(comments);

    if (activity) {
      entities = {
        ...entities,
        activities: { [activity.id]: activity },
      };
    }

    return dispatch(entitiesReceived(entities));
  };
}

export function createPendingComment(form: Object, user: ?User) {
  if (form.error) {
    return { ...form, error: false };
  }

  return {
    ...form,
    user,
    userId: user ? user.id : "",
    replyIds: [],
    pending: true,
    error: false,
    createdAt: new Date(),
    updatedAt: new Date(),
    id: uuid(),
  };
}

export function createComment(form: Object): ThunkAction {
  return async function (dispatch, getState) {
    const state = getState();
    const pendingComment = createPendingComment(form, getCurrentUser(state));
    const params = omit(
      form,
      "id",
      "userId",
      "user",
      "replyIds",
      "pending",
      "error",
      "createdAt",
      "updatedAt"
    );

    if (form.error) {
      dispatch(entitiesReceived(normalizeComment(pendingComment).entities));
    } else {
      dispatch(createdComment(pendingComment));
    }

    dispatch(
      CreateCommentRequest.perform({
        params,
        onSuccess: (comment) => {
          let entities = { [comment.id]: comment };

          if (comment.parentId) {
            const parent = getComment(state, { id: comment.parentId });

            if (parent) {
              const replyIds = uniq(
                without(parent.replyIds, pendingComment.id).concat([comment.id])
              );

              entities = {
                ...entities,
                [comment.parentId]: { ...parent, replyIds },
              };
            }
          }

          dispatch(
            createdCommentEvent({
              contentId: comment.contentId,
              layerId: comment.layerId,
              projectId: comment.projectId,
            })
          );

          dispatch(
            entitiesReplaced({
              type: "comments",
              ids: [pendingComment.id],
              entities,
            })
          );
        },
        onError: (err) => {
          dispatch(
            entitiesReceived(
              normalizeComment({ ...pendingComment, error: true }).entities
            )
          );
        },
      })
    );
  };
}

export function updateComment(params: Object): ThunkAction {
  return async function (dispatch) {
    return dispatch(
      UpdateCommentRequest.perform({
        params,
        onSuccess: (comment) => {
          dispatch(updatedCommentEvent(comment.projectId));
          const { entities } = normalizeComment(comment);
          dispatch(entitiesReceived(entities));
        },
      })
    );
  };
}

export function deletedComment(params: {
  id: string,
  parentId?: string,
}): ThunkAction {
  /* Export this to use in the web/desktop sockets */
  return function (dispatch, getState) {
    const state = getState();
    const comment = getComment(state, { id: params.id });
    const parentId = params.parentId || (comment && comment.parentId);
    const deletedIds = [params.id];

    if (parentId) {
      const parent = getComment(state, { id: parentId });
      if (parent && parent.deletedAt && parent.replyIds.length === 1) {
        deletedIds.push(parentId);
      } else if (parent) {
        return dispatch(
          entitiesReplaced({
            type: "comments",
            ids: deletedIds,
            entities: {
              [parentId]: {
                ...parent,
                replyIds: without(parent.replyIds, params.id),
              },
            },
          })
        );
      }
    } else if (comment && comment.replyIds.length > 0) {
      /*
        If a comment with replies was deleted, we don't want to remove it from
        the store. We want to make it appear in a deleted state.
      */
      return dispatch(
        entitiesReceived({
          comments: { [params.id]: { ...comment, deletedAt: new Date() } },
        })
      );
    }

    dispatch(entitiesDeleted({ comments: deletedIds }));
  };
}

export function deleteComment(
  params: {
    id: string,
    projectId: string,
    parentId?: string,
  },
  trackingParams?: {
    contentId?: string,
    layerId?: string,
  } = {}
): ThunkAction {
  return async function (dispatch) {
    return dispatch(
      DeleteCommentRequest.perform({
        params,
        onSuccess: () => {
          dispatch({
            type: "core/COMMENT_DELETED",
            meta: {
              analytics: {
                eventType: EventTypes.track,
                eventPayload: {
                  event: "COMMENT_DELETED",
                  projectId: params.projectId,
                  ...trackingParams,
                },
              },
            },
          });
          dispatch(
            deletedComment({ id: params.id, parentId: params.parentId })
          );
          dispatch(showToast({ icon: "trash", text: "Comment deleted" }));
        },
        onError: (err) => {
          dispatch(
            showToast({ icon: "error", text: "Could not delete comment" })
          );
        },
      })
    );
  };
}

export function fetchReplies(params: {
  id: string,
  projectId: string,
}): ThunkAction {
  return async function (dispatch) {
    return dispatch(
      RepliesFetchRequest.perform({
        params,
        onSuccess: (comments) => {
          dispatch(entitiesReceived(normalizeComments(comments).entities));
        },
      })
    );
  };
}
