// @flow
import * as React from "react";
import useBranchDisplayName from "core/hooks/useBranchDisplayName";
import { V3Link as Link } from "core/lib/router";
import { branchPath, commitPath } from "core/lib/routes";
import ActivityBase from "./Base";
import Callout from "./Callout";
import type {
  ActivityMergeCommit as TActivityMergeCommit,
  Props,
} from "./types";

export default function ActivityMergeCommit(
  props: Props<TActivityMergeCommit>
) {
  const { activity } = props;

  const { branchName: destinationBranchDisplayName } = useBranchDisplayName(
    activity.payload.destinationBranchId,
    activity.payload.destinationBranchName
  );

  return (
    <ActivityBase
      {...props}
      icon="commit-merge"
      action="committed"
      subject={
        <span>
          <Link
            to={commitPath(
              activity.projectId,
              activity.branchId,
              activity.payload.sha
            )}
          >
            {activity.payload.title}
          </Link>{" "}
          to{" "}
          <Callout
            to={branchPath(
              activity.projectId,
              activity.payload.destinationBranchId,
              "files"
            )}
          >
            {destinationBranchDisplayName}
          </Callout>
        </span>
      }
    />
  );
}
