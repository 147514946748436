// @flow
import { connect } from "react-redux";
import { isOnline } from "abstract-di/selectors";
import * as Request from "core/models/request";
import { AuthenticationTypeRequest } from "core/requests/auth";
import type { State, Dispatch } from "core/types";
import type { Props, OwnProps, StateProps, DispatchProps } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const request = AuthenticationTypeRequest.getRequest(state, {});

  return {
    authCheckError: request.error,
    isLoadingAuthCheck: Request.isLoadingStrict(request),
    isOnline: isOnline(state),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    onCheckAuthType: (username: string, url: string) =>
      dispatch(
        AuthenticationTypeRequest.perform({
          params: { email_or_username: username, url },
        })
      ),
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
