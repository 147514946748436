// @flow
/* global document */
import {
  SortableContainer,
  arrayMove,
} from "@elasticprojects/react-sortable-hoc";
import { map } from "lodash";
import * as React from "react";

const SortableList = SortableContainer(
  ({ items, renderList, renderListItem }) => {
    const renderedItems = items.map((item, index) =>
      renderListItem(item, index)
    );
    return renderList(renderedItems);
  },
  { forwardRef: true }
);

type Props<Item: *> = {
  container?: ?HTMLElement,
  items: Item[],
  onSort?: (item: Item, { oldIndex: number, newIndex: number }) => Promise<*>,
  renderList: (listItems: React.Node, props: *) => React.Node,
  renderListItem: (item: Item, props: *) => React.Node,
};

type State = { order: ?(number[]) };

type SortCallback = {
  newIndex: number,
  oldIndex: number,
};

export default class SortableComponent extends React.Component<
  Props<*>,
  State,
> {
  updateable: boolean;

  constructor(props: Props<*>) {
    super(props);
    this.updateable = false;
    this.state = { order: undefined };
  }

  componentDidMount() {
    this.updateable = true;
  }

  componentWillUnmount() {
    this.updateable = false;
  }

  getOrderedItems(items: *) {
    if (!this.state.order) {
      return items;
    }

    /* $FlowFixMeNowPlease This comment suppresses an error found when
     * upgrading flow-bin@0.85.0. To view the error, delete this comment and
     * run Flow. */
    return this.state.order.map((sortedIndex) => items[sortedIndex]);
  }

  handleSortStart = () => {
    const { body } = document;
    if (body) {
      body.style.cursor = "grabbing";
    }
  };

  handleSortEnd = async ({ oldIndex, newIndex }: SortCallback) => {
    const { body } = document;
    if (body) {
      body.style.cursor = "";
    }

    const { items, onSort } = this.props;
    if (!onSort) {
      return;
    }

    // It's important to use `lodash#map` here so that it returns a mutable
    // array. At the time of writing this comment, `items.map` returned some
    // kind of immutable array that threw an error when you called `arrayMove`
    // on it.
    const defaultOrder = map(items, (item, index: number) => index);
    const order = arrayMove(defaultOrder, oldIndex, newIndex);
    this.setState({ order });
    await onSort(items[oldIndex], { oldIndex, newIndex });
    if (this.updateable) {
      this.setState({ order: undefined });
    }
  };

  render() {
    const { items, ...rest } = this.props;

    // Defer mounting until the parent component has refs.
    // https://github.com/clauderic/react-sortable-hoc/issues/135
    if (!this.props.container) {
      return null;
    }

    return (
      <SortableList
        {...rest}
        getContainer={() => this.props.container}
        items={this.getOrderedItems(items)}
        onSortStart={this.handleSortStart}
        onSortEnd={this.handleSortEnd}
      />
    );
  }
}
