// @flow
import * as React from "react";
import NoBranchFiles from "core/components/Empty/NoBranchFiles";
import FileList from "core/components/FileList";
import Head from "core/components/Head";
import Scrollable from "core/components/Scrollable";
import type {
  File,
  Library,
  Page,
  FilePathQueryParams,
  ChangesetChange,
} from "core/types";
import BranchPage from "web/components/BranchShell/BranchPage";
import style from "./mobile.scss";

type Props = {
  loadPages: (string) => void,
  changedFiles: File[],
  unchangedFiles: File[],
  deletedFiles: File[],
  pages: { [uniqueFileId: string]: Page[] },
  libraries: Library[],
  fileCommentCounts: { [id: string]: number },
  fileChanges: { [fileId: string]: ChangesetChange },
  filePath: (
    changed: boolean
  ) => (fileId: string, pageId?: string, query?: FilePathQueryParams) => string,
  libraryPath: (fileId: string) => Object,
  contentTypeId?: string,
  isEmpty: boolean,
  isMasterBranch: boolean,
  params: {
    projectId: string,
    branchId: string,
    fileId: string,
    pageId?: string,
  },
};

export default function BranchFilesMobile(props: Props) {
  return (
    <BranchPage>
      <Head>
        <title>Files</title>
      </Head>
      <Scrollable>
        {props.isEmpty ? (
          <NoBranchFiles className={style.empty} flex />
        ) : (
          <FileList
            isMobile
            unchangedFiles={props.unchangedFiles}
            changedFiles={props.changedFiles}
            deletedFiles={props.deletedFiles}
            pages={props.pages}
            libraries={props.libraries}
            isMasterBranch={props.isMasterBranch}
            fileChanges={props.fileChanges}
            fileCommentCounts={props.fileCommentCounts}
            loadPages={props.loadPages}
            filePath={props.filePath}
            libraryPath={props.libraryPath}
            fileId={props.params.fileId}
            pageId={props.params.pageId}
            contentTypeId={props.contentTypeId}
            projectId={props.params.projectId}
            branchId={props.params.branchId}
          />
        )}
      </Scrollable>
    </BranchPage>
  );
}
