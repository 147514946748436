// @flow
import invariant from "invariant";
import { connect } from "react-redux";
import { display, openFile } from "abstract-di/actions";
import { dismissDialog } from "core/actions/dialogs";
import type { State, Dispatch } from "core/types";
import type { Props, OwnProps, DispatchProps } from ".";

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    openFile: ({
      projectId,
      branchId,
      fileId,
      sha,
      fileMode,
      skipWarnings,
    }) => {
      if (openFile) {
        dispatch(
          openFile({ projectId, branchId, fileId, sha, fileMode, skipWarnings })
        );
      }
    },

    display: (name: string, value: boolean) => {
      invariant(display, "display required for dialog action");
      dispatch(display(name, value));
    },
    dismissDialog: () => {
      dispatch(dismissDialog());
    },
  };
}

export default connect<Props, OwnProps, _, DispatchProps, State, Dispatch>(
  undefined,
  mapDispatchToProps
);
