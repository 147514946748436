// @flow
import invariant from "invariant";
import { connect } from "react-redux";
import {
  archiveBranch,
  unarchiveBranch,
  deleteBranch,
  exportBranch,
  pushRepo,
  openProjectInTerminal,
  openProjectInFinder,
} from "abstract-di/actions";
import {
  isDevelopmentMenuEnabled,
  isOnline,
  getCurrentUserId,
  getBranchMergeState,
} from "abstract-di/selectors";
import { showDialog } from "core/actions/dialogs";
import contextMenuWrapper, {
  type Props as ContextMenuWrapperProps,
} from "core/components/ContextMenu/ContextMenuWrapper";
import createConnector from "core/lib/createConnector";
import { getBranch } from "core/selectors/branches";
import { getProjectPolicy } from "core/selectors/policies";
import { getProject } from "core/selectors/projects";
import type { Project, Branch, State, Dispatch } from "core/types";
import type { OwnProps, StateProps, DispatchProps, Props } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { projectId, branchId } = props;
  const project = getProject(state, { projectId });
  const branch = getBranch(state, { projectId, branchId });
  const policy = getProjectPolicy(state, { projectId });
  const currentUserId = getCurrentUserId(state);
  const userIsBranchAuthor = !!branch && currentUserId === branch.userId;
  const isDiverged =
    getBranchMergeState(state, { projectId, branchId }) ===
    "NEEDS_REMOTE_UPDATE";
  const canManageBranch = userIsBranchAuthor || policy.manageBranches;
  const isDevelopment = isDevelopmentMenuEnabled(state);
  const online = isOnline(state);

  return {
    project,
    branch,
    policy,
    userIsBranchAuthor,
    isDiverged,
    canManageBranch,
    isDevelopment,
    isOnline: online,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { projectId, branchId } = props;
  return {
    showDialog: (name: string, props: {}) => {
      dispatch(showDialog(name, props));
    },
    archiveBranch: () => {
      invariant(archiveBranch, "archiveBranch required for Branch Menu");
      dispatch(archiveBranch(projectId, branchId));
    },
    unarchiveBranch: () => {
      invariant(unarchiveBranch, "unarchiveBranch required for Branch Menu");
      dispatch(unarchiveBranch(projectId, branchId));
    },
    deleteBranch: (branchName: string) => {
      invariant(deleteBranch, "deleteBranch required for Branch Menu");
      dispatch(deleteBranch(projectId, branchId, branchName, false));
    },
    exportBranch: (branch: Branch, project: Project) => {
      invariant(exportBranch, "exportBranch required for Branch Menu");
      dispatch(exportBranch(branch, project));
    },
    pushRepo: () => {
      invariant(pushRepo, "pushRepo required for Branch Menu");
      dispatch(pushRepo(projectId, [branchId]));
    },
    openInFinder: () => {
      invariant(
        openProjectInFinder,
        "openProjectInFinder required for Branch Menu"
      );
      dispatch(openProjectInFinder(projectId));
    },
    openInTerminal: () => {
      invariant(
        openProjectInTerminal,
        "openInTerminal required for Branch Menu"
      );
      dispatch(openProjectInTerminal(projectId));
    },
  };
}

export default createConnector<
  Props,
  {|
    ...OwnProps,
    ...$Exact<ContextMenuWrapperProps>,
  |},
>(
  contextMenuWrapper,
  connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch>(
    mapStateToProps,
    mapDispatchToProps
  )
);
