// @flow
import {
  downloadAssets as downloadAssetsDI,
  generateAssetsForFile,
} from "abstract-di/actions";
import { trackEvent } from "core/actions/analytics";
import { showToast } from "core/actions/toasts";
import { Abstract } from "core/lib/abstract";
import { downloadAssetInline } from "core/lib/assets";
import { isForeignSymbol, isSymbolFromSameProject } from "core/lib/layer";

import createLogger from "core/lib/logger";
import { fileName } from "core/models/asset";
import * as File from "core/models/file";
import { AssetsFetchRequest } from "core/requests/assets";
import { CommitsFetchRequest } from "core/requests/commits";
import { LayerFetchRequest } from "core/requests/layers";
import { ProjectFetchRequest } from "core/requests/projects";
import {
  getSymbolMasterDescriptor,
  getSymbolMasterAssetQueryParams,
} from "core/selectors/assets";
import { getLatestCommitShaForLayer } from "core/selectors/commits";
import { getFile } from "core/selectors/files";
import { getLayer } from "core/selectors/layers";
import type { Asset, Action, ThunkAction } from "core/types";

const logger = createLogger("assets");

export function trackDownloadAssetsEvent(
  params: Abstract.LayerVersionDescriptor,
  all?: boolean
): Action {
  const type = all ? "ASSETS_DOWNLOADED_ALL" : "ASSETS_DOWNLOADED";
  return trackEvent(type, {
    projectId: params.projectId,
    branchId: params.branchId,
    fileId: params.fileId,
    layerId: params.layerId,
    sha: params.sha,
  });
}

export function downloadAssets(assets: Asset[]): ThunkAction {
  return async (dispatch) => {
    // If we're on desktop, we import an action that uses CLI to download the
    // assets
    if (downloadAssetsDI) {
      return dispatch(downloadAssetsDI(assets));
    }

    // Otherwise, we download the assets inline
    for (let i = 0; i < assets.length; i++) {
      const asset = assets[i];
      try {
        await downloadAssetInline(asset);
      } catch (err) {
        dispatch(
          showToast({
            text: `There was an error while downloading ${fileName(asset)}`,
          })
        );
      }
    }
  };
}

export function fetchLayerAssets(
  params: Abstract.LayerVersionDescriptor
): ThunkAction {
  return async (dispatch, getState) => {
    await dispatch(
      AssetsFetchRequest.perform({
        force: false,
        params,
      })
    );
  };
}

export function fetchSymbolMasterAssets(
  params: Abstract.LayerVersionDescriptor,
  nestedLayerId: string,
  isPublicShare: ?boolean
): ThunkAction {
  return async (dispatch, getState) => {
    const { projectId, branchId, layerId, fileId } = params;
    const symbolMasterDescriptor = getSymbolMasterAssetQueryParams(getState(), {
      ...params,
      nestedLayerId,
    });
    if (!symbolMasterDescriptor) {
      return;
    }

    if (isPublicShare && isForeignSymbol(params, symbolMasterDescriptor)) {
      return;
    }

    let symbolMasterSha: ?string;

    if (isSymbolFromSameProject(params, symbolMasterDescriptor)) {
      symbolMasterSha = getLatestCommitShaForLayer(getState(), {
        projectId,
        branchId,
        fileId,
        layerId,
      });
    } else {
      // load the project that contains the symbol master
      await dispatch(
        ProjectFetchRequest.perform({
          params: { projectId: symbolMasterDescriptor.projectId },
          force: false,
        })
      );

      // Check if symbolMasterSha exits before fetching commits
      symbolMasterSha = getLatestCommitShaForLayer(
        getState(),
        symbolMasterDescriptor
      );

      if (!symbolMasterSha) {
        await dispatch(
          CommitsFetchRequest.perform({
            force: false,
            params: symbolMasterDescriptor,
            limit: 1,
          })
        );
        symbolMasterSha = getLatestCommitShaForLayer(
          getState(),
          symbolMasterDescriptor
        );
      }
    }

    if (!symbolMasterSha) {
      logger.log(
        `Could not find sha for symbol master ${symbolMasterDescriptor.layerId} from project ${symbolMasterDescriptor.projectId} on branch ${symbolMasterDescriptor.branchId}`
      );
      return;
    }
    // symbolMasterSha may actually be the branch's startedAtSha rather
    // than the symbol's lastChangedAtSha, so fetch the layer meta to
    // make sure we get the correct sha.
    await dispatch(
      LayerFetchRequest.perform({
        params: {
          ...symbolMasterDescriptor,
          sha: symbolMasterSha,
        },
      })
    );
    const symbolLayer = getLayer(getState(), {
      ...symbolMasterDescriptor,
      sha: symbolMasterSha,
    });

    symbolMasterSha = symbolLayer
      ? symbolLayer.lastChangedAtSha
      : symbolMasterSha;

    dispatch(
      AssetsFetchRequest.perform({
        force: false,
        params: { ...symbolMasterDescriptor, sha: symbolMasterSha },
      })
    );
  };
}

export function generateAndUpdateAssetsForFile(
  params: Abstract.LayerVersionDescriptor,
  nestedLayerId: string
): ThunkAction {
  return async (dispatch, getState) => {
    const { projectId, branchId, sha, fileId } = params;
    const state = getState();
    const file = getFile(state, { projectId, sha, fileId });

    if (!File.supportsAssets(file)) {
      return;
    }

    if (generateAssetsForFile) {
      await dispatch(generateAssetsForFile(projectId, branchId, sha, fileId));
    }

    await dispatch(AssetsFetchRequest.perform({ params }));

    const symbolMasterDescriptor = getSymbolMasterDescriptor(state, {
      ...params,
      nestedLayerId,
    });
    if (!symbolMasterDescriptor) {
      return;
    }

    let symbolMasterSha = getLatestCommitShaForLayer(
      state,
      symbolMasterDescriptor
    );

    if (!symbolMasterSha) {
      await dispatch(
        CommitsFetchRequest.perform({
          force: false,
          params: symbolMasterDescriptor,
          limit: 1,
        })
      );
      symbolMasterSha = getLatestCommitShaForLayer(
        getState(),
        symbolMasterDescriptor
      );
    }

    // only fetch assets for symbols if they have a chance of having
    // been generated in this file.
    if (symbolMasterDescriptor.fileId === params.fileId && symbolMasterSha) {
      const symbolLayer = getLayer(state, {
        ...symbolMasterDescriptor,
        sha: symbolMasterSha,
      });
      symbolMasterSha = symbolLayer
        ? symbolLayer.lastChangedAtSha
        : symbolMasterSha;

      await dispatch(
        AssetsFetchRequest.perform({
          params: { ...symbolMasterDescriptor, sha: symbolMasterSha },
        })
      );
    }
  };
}
