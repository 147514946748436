// @flow
import classnames from "classnames";
import * as React from "react";
import style from "./style.scss";

type Props = { icon?: boolean, children: React.Node };

export default function Kbd(props: Props) {
  return (
    <kbd
      className={classnames(style.key, {
        [style.keyIcon]: props.icon,
      })}
    >
      {props.children}
    </kbd>
  );
}
