// @flow
import { connect } from "react-redux";
import { isOnline, getCurrentUserId } from "abstract-di/selectors";
import { removeOrganizationMember } from "core/actions/memberships";
import * as Request from "core/models/request";
import {
  RemoveOrganizationMemberRequest,
  UpdateOrganizationMembershipsRequest,
} from "core/requests/memberships";
import { SubscriptionFetchRequest } from "core/requests/seats";
import { seatCapEnabled } from "core/selectors/features";
import { getMembership } from "core/selectors/memberships";
import { getOrganization } from "core/selectors/organizations";
import { getOrganizationPolicy } from "core/selectors/policies";
import { getSubscription } from "core/selectors/subscriptions";
import type { Dispatch, State } from "core/types";
import type { Props, OwnProps, StateProps, DispatchProps } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { organizationId, user } = props;
  const currentUserId = getCurrentUserId(state) || "";
  const currentMembership = getMembership(
    state,
    `${organizationId}-${currentUserId}`
  );

  const targetMembership = getMembership(state, `${organizationId}-${user.id}`);
  const removeRequest = RemoveOrganizationMemberRequest.getRequest(state, {
    organizationId,
    userId: user.id,
  });

  let removeError;

  if (Request.forbidden(removeRequest) && user.id === currentUserId) {
    removeError =
      "You cannot leave this organization because you are the last remaining owner.";
  } else if (Request.hasError(removeRequest)) {
    removeError =
      "There was an error removing this person from the organization.";
  }

  return {
    isOffline: !isOnline(state),
    organization: getOrganization(state, { organizationId }),
    policy: getOrganizationPolicy(state, { organizationId }),
    membership: targetMembership,
    canManageUsers: !!currentMembership && currentMembership.role === "owner",
    seatCapEnabled: seatCapEnabled(state, organizationId),
    subscription: getSubscription(state, organizationId),
    currentUserId,
    removeError,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { organizationId, user } = props;
  const userId = user.id;
  return {
    onChangeRole: () => {
      dispatch(
        SubscriptionFetchRequest.perform({
          params: { organizationId },
        })
      );
    },
    onUpdate: (membership) => {
      dispatch(
        UpdateOrganizationMembershipsRequest.perform({
          params: {
            organizationId,
            userId,
            membership,
          },
          onSuccess() {
            dispatch(
              SubscriptionFetchRequest.perform({
                params: { organizationId },
              })
            );
          },
        })
      );
    },
    onRemove: (user) => {
      dispatch(removeOrganizationMember(user, organizationId));
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
