// @flow
import invariant from "invariant";
import { connect } from "react-redux";
import { addLibrary } from "abstract-di/actions";
import { dismissDialog } from "core/actions/dialogs";
import type { State, Dispatch, Library } from "core/types";
import type { Props, OwnProps, DispatchProps } from ".";

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    addLibrary: (projectId: string, branchId: string, library: Library) => {
      invariant(addLibrary, "addLibrary required for dialog action");
      dispatch(addLibrary(projectId, branchId, library));
      dispatch(dismissDialog());
    },
    dismissDialog: () => dispatch(dismissDialog()),
  };
}

export default connect<Props, OwnProps, _, DispatchProps, State, Dispatch>(
  undefined,
  mapDispatchToProps
);
