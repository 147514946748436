// @flow
import omit from "lodash/omit";
import * as React from "react";
import ButtonUnstyled from "core/components/ButtonUnstyled";
import Icon from "../Icon";
import Input, { type Props as InputProps } from "../Input";
import Popover from "../Popover";
import style from "./style.scss";

type Props = InputProps & { showHelper: boolean };
type ComponentState = { showPassword: boolean };

export default class InputPassword extends React.Component<
  Props,
  ComponentState,
> {
  static defaultProps = { showHelper: true, helpers: {}, showLengthWarning: 0 };
  state = { showPassword: false };

  input: Input;

  inputType() {
    return this.state.showPassword ? "text" : "password";
  }

  focus() {
    this.input.focus();
  }

  helperIcon() {
    return <Icon type="show" tint={this.state.showPassword} />;
  }

  toggleShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  showPasswordHelper() {
    const tooltipText = this.state.showPassword
      ? "Hide password"
      : "Show password";
    return (
      <ButtonUnstyled
        tabIndex="0"
        onClick={this.toggleShowPassword}
        className={style.toggle}
      >
        <Popover label={tooltipText}>{this.helperIcon()}</Popover>
      </ButtonUnstyled>
    );
  }

  getHelpers() {
    if (this.props.showHelper) {
      return { ...this.props.helpers, showPassword: this.showPasswordHelper() };
    }

    return this.props.helpers;
  }

  render() {
    const props = { ...omit(this.props, "showHelper") };
    return (
      <Input
        {...props}
        ref={(me) => (this.input = me)}
        autoCapitalize="none"
        helpers={this.getHelpers()}
        type={this.inputType()}
      />
    );
  }
}
