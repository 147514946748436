// @flow
import classnames from "classnames";
import * as React from "react";
import AvatarWithModal from "core/components/AvatarWithModal";
import Icon from "core/components/Icon";
import UserNameWithModal from "core/components/UserNameWithModal";
import Callout from "./Callout";
import ActivityMeta from "./Meta";
import style from "./base.scss";
import type { Props } from "./types";

export default function Activity({
  user,
  action,
  subject,
  preview,
  icon,
  narrow,
  project,
  activity,
  deletedComment,
  showMeta = true,
  onLoad,
  qaSelector,
  ...rest
}: Props<> & {
  branchId?: string,
  branchName?: string,
  commitSha?: ?string,
  fileId?: ?string,
  fileName?: ?string,
  action: string | React.Node,
  subject: ?React.Node,
  preview?: ?React.Node,
  icon: string,
  meta?: React.Node,
  narrow?: boolean,
  showMeta?: boolean,
  deletedComment?: boolean,
  onLoad?: () => void,
  qaSelector?: string,
}) {
  const isComment = isCommentAction(action);

  return (
    <div
      className={classnames(style.wrapper, { [style.narrow]: narrow })}
      data-qa={qaSelector}
    >
      {!isComment && <Icon type={icon} className={style.activityIcon} />}

      <div className={style.avatar}>
        <AvatarWithModal
          size={32}
          user={user}
          deletedComment={deletedComment}
        />
      </div>
      <div
        className={classnames(style.content, {
          [style.comment]: isComment,
        })}
      >
        <div
          className={classnames({
            [style.content__header]: isComment,
          })}
        >
          {deletedComment ? (
            <span className={style.deletedCommentName}>Deleted comment</span>
          ) : (
            <Callout>
              <UserNameWithModal
                user={user}
                className={user && style.userLink}
              />
            </Callout>
          )}{" "}
          <span className={style.action}>{action}</span>{" "}
          <span className={style.subject}>{subject}</span>
        </div>

        {preview && <div className={style.preview}>{preview}</div>}

        {showMeta && (
          <ActivityMeta
            {...rest}
            createdAt={activity.createdAt}
            projectId={project ? project.id : undefined}
            projectName={project ? project.name : undefined}
          />
        )}
      </div>
    </div>
  );
}

function isCommentAction(action: string | React.Node): boolean {
  return (
    typeof action === "string" &&
    (action.includes("commented") || action.includes("annotated"))
  );
}
