// @flow
/* global document HTMLImageElement */
import * as React from "react";
import {
  default as ReactCrop,
  centerCrop,
  makeAspectCrop,
  type PixelCrop,
} from "react-image-crop";
import Button from "../Button";
import Centered from "../Centered";
import Spinner from "../Spinner";
import style from "./style.scss";

function isPixelCropValid(pixelCrop: PixelCrop) {
  return pixelCrop.width >= 256 && pixelCrop.height >= 256;
}

// https://github.com/DominicTobias/react-image-crop#how-can-i-center-the-crop
function centerAspectCrop(width: number, height: number, aspect: ?number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      width,
      height
    ),
    width,
    height
  );
}

type Props = {
  onSave: (dataUrl: string) => void,
  src: string,
  fileType: string,
  maxSize: number,
  type: string,
  aspect: ?number,
};

type State = {
  valid: boolean,
  crop: ?PixelCrop,
  image: ?HTMLImageElement,
  isCropping: boolean,
};

export default class ImageCropper extends React.Component<Props, State> {
  static defaultProps = {
    maxSize: 512,
    aspect: 1, // square
  };

  state = {
    valid: false,
    crop: undefined,
    image: undefined,
    isCropping: false,
  };

  handleChange = (crop: PixelCrop) => this.setState({ crop });
  handleComplete = (crop: PixelCrop) => {
    const valid = isPixelCropValid(crop);
    this.setState({ crop, valid });
  };

  handleImageLoaded = (e: SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;
    const crop = centerAspectCrop(width, height, this.props.aspect);
    this.setState({ crop, image: e.currentTarget });
  };

  handleCrop = () => {
    const { fileType, maxSize, onSave } = this.props;
    const { crop, image, valid } = this.state;

    if (!(valid && crop && image)) {
      return;
    }

    this.setState({ isCropping: true });

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      throw new Error("No 2d context");
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const originWidth = crop.width * scaleX;
    const originHeight = crop.height * scaleY;

    const targetWidth = Math.min(originWidth, maxSize);
    const targetHeight = Math.min(originHeight, maxSize);

    canvas.width = targetWidth;
    canvas.height = targetHeight;

    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      originWidth,
      originHeight,
      0,
      0,
      targetWidth,
      targetHeight
    );
    const cropped = canvas.toDataURL(fileType);
    onSave(cropped);
  };

  render() {
    return (
      <div className={style.wrapper}>
        <div className={style.content}>
          <div className={style.contentInner}>
            <ReactCrop
              crop={this.state.crop}
              onChange={this.handleChange}
              onComplete={this.handleComplete}
              aspect={this.props.aspect}
              maxWidth={this.props.maxSize}
              maxHeight={this.props.maxSize}
            >
              <img
                alt="Crop"
                src={this.props.src}
                onLoad={this.handleImageLoaded}
              />
            </ReactCrop>
          </div>
          {this.state.isCropping && (
            <div className={style.loadingOverlay}>
              <Centered>
                <Spinner light />
              </Centered>
            </div>
          )}
        </div>
        <div className={style.footer}>
          <Button
            disabled={
              !(this.state.crop && this.state.image && this.state.valid) ||
              this.state.isCropping
            }
            fullwidth
            large
            primary
            onClick={this.handleCrop}
          >
            Crop {this.props.type === "avatar" ? "avatar" : "organization logo"}
          </Button>
        </div>
      </div>
    );
  }
}
