// @flow
import classnames from "classnames";
import * as React from "react";
import style from "./style.scss";

type Props = {
  size?: "xxxl" | "xxl" | "xl" | "l" | "m" | "s" | "xs",
  className?: string,
  min?: number,
  max?: number,
  unit?: "%" | "px",
  qaSelector?: string,
};

export default function PlaceholderText({
  size = "m",
  className,
  min = 50,
  max = 75,
  unit = "%",
  qaSelector,
  ...rest
}: Props) {
  const inlineStyle = React.useMemo(() => {
    return {
      width: `${Math.floor(Math.random() * (max - min + 1) + min)}${unit}`,
    };
  }, [max, min, unit]);

  return (
    <div
      data-qa={qaSelector}
      className={classnames(
        style.placeholder,
        {
          [style.xxxl]: size === "xxxl",
          [style.xxl]: size === "xxl",
          [style.xl]: size === "xl",
          [style.l]: size === "l",
          [style.m]: size === "m",
          [style.s]: size === "s",
          [style.xs]: size === "xs",
        },
        className
      )}
      style={inlineStyle}
      {...rest}
    >
      &nbsp;
    </div>
  );
}
