// @flow
import classnames from "classnames";
import * as React from "react";
import Floating from "core/components/Floating";
import PopoverCore, { type Props } from "core/components/Popover/Core";
import style from "./style.scss";

type MenuProps = Props & {
  width?: number,
  floatingClassName?: string,
  lockBodyScroll?: boolean,
  scrollable?: boolean,
  qaSelector?: string,
};

export default class FloatingMenu extends React.Component<MenuProps> {
  static defaultProps = {
    delayHide: 0,
    delayShow: 0,
    offset: "0, 2px",
    placement: "top-end",
    trigger: "click",
    display: "inline",
    disabled: false,
    forceHide: false,
    classNames: {},
    modifiers: {},
  };

  render() {
    const { lockBodyScroll, qaSelector, ...rest } = this.props;

    return (
      <PopoverCore
        {...rest}
        body={(dismissPopover) => (
          <Floating
            className={classnames(style.floating, this.props.floatingClassName)}
            style={this.props.width ? { width: this.props.width } : undefined}
            onRequestClose={dismissPopover}
            scrollable={this.props.scrollable}
            data-ignore-body-scroll-lock={lockBodyScroll}
            qaSelector={qaSelector}
          >
            {typeof this.props.body === "function"
              ? this.props.body(dismissPopover)
              : this.props.body
              ? this.props.body
              : null}
          </Floating>
        )}
      />
    );
  }
}
