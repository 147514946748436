// @flow
import * as React from "react";
import ContextMenu from "core/components/ContextMenu";
import contextMenuWrapper from "core/components/ContextMenu/ContextMenuWrapper";
import type {
  MenuItem,
  ProjectMembership,
  Project,
  User,
  Team,
} from "core/types";
import connector from "./connector";

type ContextMenuProps = {|
  children: (
    showMenu: (ev: SyntheticEvent<>) => *,
    ref: (el: ?HTMLElement) => *
  ) => React.Node,
  innerRef?: React.Ref<typeof ContextMenu>,
|};

export type OwnProps = {|
  ...ContextMenuProps,
  onClickLeave?: () => void,
  onClickRemove?: () => void,
  onClickRemoveTeam?: () => void,
  onRequestClose?: () => void,
  onAfterClose?: () => void,
  project: Project,
  user?: User,
  team?: Team,
|};

export type StateProps = {|
  currentUserId?: string,
  membership: ?ProjectMembership,
  isUpdateMembershipRequestLoading: boolean,
|};

export type DispatchProps = {|
  leaveProject: () => void,
  removeMember: () => void,
  updateRole: (role: $PropertyType<ProjectMembership, "role">) => void,
  updateRoleForOrgAdmin: (
    role: $PropertyType<ProjectMembership, "role">
  ) => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

class ProjectMemberMenu extends React.Component<Props> {
  isMe = (): boolean => {
    return !!(
      this.props.membership &&
      this.props.membership.userId === this.props.currentUserId
    );
  };

  isGuest = (): boolean => {
    return !!(
      this.props.membership && this.props.membership.isOrganizationGuest
    );
  };

  isAdmin = (): boolean => {
    return !!(this.props.membership && this.props.membership.role === "admin");
  };

  isOrganizationAdmin = (): boolean => {
    return !!(
      this.props.membership && this.props.membership.isOrganizationOwner
    );
  };

  isProjectAdmin = (): boolean => {
    return !!(
      this.isAdmin() &&
      this.props.membership &&
      !this.props.membership.fromOrganization
    );
  };

  isPrivateProject = (): boolean => {
    return this.props.project.visibility === "specific";
  };

  handleMakeMemberAnAdmin = () => {
    this.props.updateRole("admin");
  };

  handleMakeOrgAdminMember = () => {
    this.props.updateRoleForOrgAdmin("member");
  };

  handleMakeProjectAdminMember = () => {
    this.props.updateRole("member");
  };

  handleRemoveTeam = () => {
    this.props.onClickRemoveTeam && this.props.onClickRemoveTeam();
  };

  handleRemove = () => {
    this.props.onClickRemove && this.props.onClickRemove();
  };

  handleLeave = () => {
    this.props.onClickLeave && this.props.onClickLeave();
  };

  menuItems = (): MenuItem[] => {
    const disabled = this.props.isUpdateMembershipRequestLoading;
    if (this.props.user) {
      return [
        // For organization admins
        {
          label: "Project Admin",
          type: "checkbox",
          checked: this.isProjectAdmin(),
          visible: this.isOrganizationAdmin(),
          enabled: !this.isProjectAdmin() && !disabled,
          click: () => this.props.updateRoleForOrgAdmin("admin"),
        },
        {
          label: "Member",
          type: "checkbox",
          checked: !this.isProjectAdmin(),
          visible: this.isOrganizationAdmin(),
          enabled: this.isProjectAdmin() && !disabled,
          click: this.handleMakeOrgAdminMember,
        },
        // For organization members
        {
          label: "Project Admin",
          type: "checkbox",
          checked: this.isProjectAdmin(),
          visible: !this.isOrganizationAdmin(),
          enabled: !this.isProjectAdmin() && !disabled,
          click: this.handleMakeMemberAnAdmin,
        },
        {
          label: "Member",
          type: "checkbox",
          checked: !this.isProjectAdmin(),
          visible: !this.isOrganizationAdmin(),
          enabled: this.isProjectAdmin() && !disabled,
          click: this.handleMakeProjectAdminMember,
        },
        { type: "separator" },
        {
          label: "Remove from Project…",
          danger: true,
          visible: !this.isMe() && (this.isPrivateProject() || this.isGuest()),
          enabled: !disabled,
          click: this.handleRemove,
        },
        {
          label: "Leave Project…",
          danger: true,
          visible: this.isMe() && (this.isPrivateProject() || this.isGuest()),
          enabled: !disabled,
          click: this.handleLeave,
        },
      ];
    } else if (this.props.team) {
      return [
        {
          label: "Remove from Project…",
          danger: true,
          visible: true,
          enabled: true,
          click: this.handleRemoveTeam,
        },
      ];
    }
    return [];
  };

  render() {
    const menuItems = this.menuItems();
    return (
      <ContextMenu
        id={`menu-${this.props.user ? this.props.user.id : ""}${
          this.props.team ? this.props.team.id : ""
        }`}
        menuItems={menuItems}
        ref={this.props.innerRef}
        onAfterClose={this.props.onAfterClose}
        children={this.props.children}
      />
    );
  }
}

// $FlowFixMe
export default contextMenuWrapper(connector(ProjectMemberMenu));
