// @flow
import * as React from "react";
import abstractConfig from "abstract-di/config";
import EmptyError from "core/components/Empty/Error";

type Props = {
  children: React.Node,
  // This optional prop represents a customized fallback component to EmptyError
  error?: React.Node,
};
type State = { error?: Error };

export default class ErrorBoundary extends React.Component<Props, State> {
  state = {};

  componentDidCatch(error: Error) {
    this.setState({ error });
    abstractConfig.reportError(error);
  }

  render() {
    const { children, error } = this.props;

    if (this.state.error) {
      if (error) {
        return error;
      }
      return <EmptyError onReload={() => abstractConfig.reload()} />;
    }

    return children;
  }
}
