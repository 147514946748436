// @flow
import Immutable from "seamless-immutable";
import type { ValidationErrors } from "../types";

export type FormValues = { [key: string]: any };
export const DEFAULT_VALUES: FormValues = Immutable({});

export type Form = {
  error: string,
  errors: ValidationErrors,
  isDirty: boolean,
  isSubmitting: boolean,
  submitSucceeded: boolean,
  values: FormValues,
};

export const DEFAULT_FORM: Form = {
  error: "",
  errors: {},
  isDirty: false,
  isSubmitting: false,
  submitSucceeded: false,
  values: {},
};
