// @flow
import invariant from "invariant";
import { connect } from "react-redux";
import { isOnline } from "abstract-di/selectors";
import { withData } from "core/components/DataLoader";
import createConnector from "core/lib/createConnector";
import { defaultBranch } from "core/models/branch";
import * as Request from "core/models/request";
import { ProjectUpdateRequest } from "core/requests/projects";
import {
  SectionsFetchRequest,
  SectionCreateRequest,
} from "core/requests/sections";
import { canUseNewDefaultBranchName } from "core/selectors/features";
import { getOrganization } from "core/selectors/organizations";
import {
  getOrganizationPolicy,
  getProjectPolicy,
} from "core/selectors/policies";
import { getProject } from "core/selectors/projects";
import { getFilteredSections } from "core/selectors/sections";
import type { State, Dispatch } from "core/types";
import type { OwnProps, StateProps, DispatchProps, Props } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { projectId } = props.params;
  const project = getProject(state, { projectId });
  const policy = getProjectPolicy(state, { projectId });
  const organizationId = project ? project.organizationId : "";
  const organization = getOrganization(state, { organizationId });
  invariant(organization, "organization must be loaded");

  const organizationPolicy = getOrganizationPolicy(state, { organizationId });
  const isPrivate = project ? project.visibility === "specific" : false;

  const updateRequest = project
    ? ProjectUpdateRequest.getRequest(state, { project })
    : null;
  const isSubmittingProject =
    !!updateRequest && Request.isLoadingStrict(updateRequest);
  const hasError = !!updateRequest && Request.hasError(updateRequest);
  const errors = updateRequest
    ? Request.validationErrors(updateRequest)
    : undefined;
  const isSubmittingSection = SectionCreateRequest.isLoadingStrict(state, {
    organizationId,
  });

  const canMakePrivate = organizationPolicy.createPrivateProject;

  return {
    key: `${projectId}-sectionId:${(project && project.sectionId) || ""}`,
    project,
    policy,
    projectId,
    organizationId,
    isOffline: !isOnline(state),
    isSubmitting: isSubmittingSection || isSubmittingProject,
    submitSucceeded: !!updateRequest && Request.success(updateRequest),
    hasError,
    errors,
    sections: getFilteredSections(state, { organizationId }),
    sectionsLoading: SectionsFetchRequest.isLoading(state, { organizationId }),
    canUseSections: organizationPolicy.showSections,
    canCreateSections: organizationPolicy.manageSections,
    defaultBranchNameTitleCase: defaultBranch({
      masterToMain: canUseNewDefaultBranchName(state),
      titleCase: true,
    }),
    canMakePrivate: canMakePrivate || isPrivate,
    canGenerateAssets: organizationPolicy.createAssets,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { projectId } = props.params;
  return {
    onSubmit(params, organizationId, sectionName) {
      if (params.project.sectionId === "new") {
        return dispatch(
          SectionCreateRequest.perform({
            params: { name: sectionName, projectId, organizationId },
            onSuccess: (response) => {
              const { sections = [] } = response.data;
              if (sections.length) {
                params.project.sectionId = sections[0].id;
                dispatch(ProjectUpdateRequest.perform({ params }));
              }
            },
            onError: () => dispatch(ProjectUpdateRequest.perform({ params })),
          })
        );
      }

      dispatch(ProjectUpdateRequest.perform({ params }));
    },
    onLoad() {
      dispatch(ProjectUpdateRequest.clear({ project: { id: projectId } }));
      dispatch((dispatch, getState) => {
        const project = getProject(getState(), { projectId });
        if (project) {
          dispatch(
            SectionsFetchRequest.perform({
              params: { organizationId: project.organizationId },
              force: false,
            })
          );
        }
      });
    },
  };
}

export default createConnector<Props, OwnProps>(
  connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch>(
    mapStateToProps,
    mapDispatchToProps
  ),
  withData((props) => props.projectId)
);
