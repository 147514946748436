// @flow
import { trackUser } from "abstract-di/actions";
import { organizationPath } from "abstract-di/routes";
import { getCurrentUser, getCurrentUserId } from "abstract-di/selectors";
import { trackEvent } from "core/actions/analytics";
import { entitiesDeleted, entitiesReceived } from "core/actions/entities";
import { showCloseableToast } from "core/actions/toasts";
import { replace } from "core/lib/location";
import { isDesktop } from "core/lib/platform";
import { accountPath } from "core/lib/routes";
import { uniqueId } from "core/models/membership";
import * as ProjectMembership from "core/models/projectMembership";
import {
  OrganizationMembershipRequest,
  OrganizationMembershipsRequest,
  RemoveOrganizationMemberRequest,
} from "core/requests/memberships";
import { SubscriptionFetchRequest } from "core/requests/seats";
import { normalizeOrganization } from "core/schemas/organization";
import { getMembership } from "core/selectors/memberships";
import {
  getOrganization,
  getUsernameOrganization,
} from "core/selectors/organizations";
import { getProjects } from "core/selectors/projects";
import type { User, ThunkAction, OrganizationRole } from "core/types";

type LoadMembershipOptions = {
  role?: OrganizationRole,
};

export function loadOrganizationMemberships(
  organizationId: string,
  options?: LoadMembershipOptions = {}
): ThunkAction {
  const { role } = options;
  return OrganizationMembershipsRequest.perform({
    params: { organizationId, role },
    force: false,
  });
}

export function deletedOrganizationMembership(
  organizationId: string,
  userId: string
): ThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    const organization = getOrganization(state, { organizationId });

    if (!organization) {
      return;
    }

    const projectMemberships = getProjects(state, { organizationId }).map((p) =>
      ProjectMembership.uniqueId({ userId, projectId: p.id })
    );

    const { entities } = normalizeOrganization(organization);
    dispatch(
      entitiesDeleted({
        memberships: [`${organizationId}-${userId}`],
        projectMemberships,
      })
    );
    return dispatch(entitiesReceived(entities));
  };
}

export function loadAndTrackMembership(organizationId: string): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState();
    const organization = getOrganization(state, {
      organizationId,
    });
    const user = getCurrentUser(state);

    if (user && organization) {
      await dispatch(
        OrganizationMembershipRequest.perform({
          params: { organizationId, userId: user.id },
          force: false,
        })
      );

      const membership = getMembership(
        getState(),
        uniqueId({ organizationId, userId: user.id })
      );
      dispatch(trackUser({ user, organizationId, membership }));
    }
  };
}

export function removeOrganizationMember(
  user: User,
  organizationId: string
): ThunkAction {
  return (dispatch, getState) => {
    const currentUserId = getCurrentUserId(getState());
    const userId = user.id;
    const organization = getOrganization(getState(), { organizationId });
    const organizationName = organization ? organization.name : "";

    if (currentUserId === user.id) {
      dispatch(
        RemoveOrganizationMemberRequest.perform({
          params: { organizationId, userId },
          onSuccess() {
            dispatch(
              showCloseableToast({
                text: `You have left the ${organizationName} Organization`,
              })
            );
            dispatch(entitiesDeleted({ organizations: [organizationId] }));
            if (isDesktop) {
              const usernameOrganization = getUsernameOrganization(getState());
              if (usernameOrganization) {
                replace(organizationPath(usernameOrganization.id));
              }
            } else {
              replace(accountPath("organizations"));
              dispatch(
                SubscriptionFetchRequest.perform({
                  params: { organizationId },
                })
              );
            }
            dispatch(
              trackEvent("USER_REMOVED", {
                userId,
                organizationId,
                membershipId: uniqueId({ organizationId, userId }),
              })
            );
          },
          onError() {
            dispatch(
              showCloseableToast({
                text: `There was an error removing you from the ${organizationName} Organization`,
              })
            );
          },
        })
      );
    } else {
      dispatch(
        RemoveOrganizationMemberRequest.perform({
          params: { organizationId, userId },
          onSuccess() {
            dispatch(
              showCloseableToast({
                text: `You have removed ${user.name} from the ${organizationName} Organization`,
              })
            );
            dispatch(
              SubscriptionFetchRequest.perform({
                params: { organizationId },
              })
            );
            dispatch(
              trackEvent("USER_REMOVED", {
                userId,
                organizationId,
                membershipId: uniqueId({ organizationId, userId }),
              })
            );
          },
          onError() {
            dispatch(
              showCloseableToast({
                text: "There was an error removing this member",
              })
            );
          },
        })
      );
    }
  };
}
