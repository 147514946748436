// @flow
import * as React from "react";
import { V3Link as Link } from "core/lib/router";
import { commitPath, branchPath } from "core/lib/routes";
import ActivityBase from "./Base";
import Callout from "./Callout";
import type {
  Props,
  ActivityUpdateCommit as TActivityUpdateCommit,
} from "./types";

export default function ActivityUpdateCommit(
  props: Props<TActivityUpdateCommit>
) {
  const { activity } = props;
  return (
    <ActivityBase
      {...props}
      icon="commit-update"
      action="committed"
      subject={
        <span>
          <Link
            to={commitPath(
              activity.projectId,
              activity.branchId,
              activity.payload.sha
            )}
          >
            {activity.payload.title}
          </Link>{" "}
          to{" "}
          <Callout
            to={branchPath(activity.projectId, activity.branchId, "files")}
          >
            {activity.payload.branchName}
          </Callout>
        </span>
      }
    />
  );
}
