// @flow
import * as React from "react";
import Error from "core/components/Empty/Error";
import NoOrganizations from "core/components/Empty/NoOrganizations";
import Flex from "core/components/Flex";
import FormHeader from "core/components/FormHeader";
import List from "core/components/List";
import Loaded from "core/components/Loaded";
import Media from "core/components/Media";
import SettingsForm from "core/components/SettingsForm";
import type { User, Organization } from "core/types";
import OrganizationListItem from "../OrganizationListItem";
import style from "./style.scss";

type Props = {
  account: User,
  error: boolean,
  isLoading: boolean,
  leaveOrganization: (organizationId: string) => void,
  organizations: Organization[],
};

export default function OrganizationsEdit(props: Props) {
  const header = (
    <Media desktop>
      {(desktop) => (
        <FormHeader
          mobile={!desktop}
          heading="Your Organizations"
          icon="organization"
        />
      )}
    </Media>
  );

  const isEmpty = !props.organizations.length;

  return (
    <SettingsForm documentTitle="Organizations" header={header}>
      <Loaded
        loading={props.isLoading}
        flex
        title="Loading organizations…"
        qaSelector="loading-spinner"
      >
        {() => {
          if (props.error) {
            return <Error flex />;
          }

          if (isEmpty) {
            return <NoOrganizations flex />;
          }

          return (
            <Media mobile>
              {(mobile) => (
                <Flex column shrink={false} className={style.orgMenu}>
                  <List className={style.orgList} mobile={mobile}>
                    {props.organizations.map((org) => (
                      <OrganizationListItem
                        key={org.id}
                        id={org.id}
                        name={org.name}
                        userId={org.userId}
                        logoUrl={org.logoUrl}
                        isUsernameOrganization={org.isUsernameOrganization}
                        currentUserId={props.account.id}
                        userIsScimProvisioned={props.account.isScimProvisioned}
                        leaveOrganization={props.leaveOrganization}
                        mobile={mobile}
                      />
                    ))}
                  </List>
                </Flex>
              )}
            </Media>
          );
        }}
      </Loaded>
    </SettingsForm>
  );
}
