// @flow
import type { State } from "core/types";

export function isStarred(
  state: State,
  props: { starrableId: string }
): boolean {
  return !!state.stars[props.starrableId];
}

export function getStar(state: State, props: { starrableId: string }) {
  return state.stars[props.starrableId];
}

export const getStarEntities = (state: State) => state.stars;
