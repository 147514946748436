// @flow
import Immutable from "seamless-immutable";
import type { Action } from "../actions";

type State = { isValidating: boolean, isValidToken: boolean };
const DEFAULT = Immutable.from({ isValidating: true, isValidToken: false });

export default function (state: State = DEFAULT, action: Action): State {
  switch (action.type) {
    case "RESET_PASSWORD_TOKEN_VALIDATING":
      return Immutable.merge(state, {
        isValidating: true,
        isValidToken: false,
      });
    case "RESET_PASSWORD_TOKEN_VALIDATED":
      return Immutable.merge(state, {
        isValidating: false,
        isValidToken: action.payload.isValid,
      });
    default:
      return state;
  }
}
