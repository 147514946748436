// @flow
import invariant from "invariant";
import React, { Component } from "react";
import { connect } from "react-redux";
import { restoreFile } from "abstract-di/actions";
import { dismissDialog } from "core/actions/dialogs";
import CommitListItem from "core/components/CommitListItem";
import DialogForm from "core/components/DialogForm";
import InputMarkdown from "core/components/InputMarkdown";
import Spoiler from "core/components/Spoiler";
import { nameWithExtension } from "core/models/file";
import { getBranch, getParentBranch } from "core/selectors/branches";
import { getCommit } from "core/selectors/commits";
import { getFile } from "core/selectors/files";
import { getProject } from "core/selectors/projects";
import type {
  Project,
  Branch,
  Commit,
  File,
  State,
  Dispatch,
} from "core/types";
import Validations from "core/validations";
import style from "./style.scss";

type OwnProps = {|
  projectId: string,
  branchId: string,
  fileId: string,
  sha: string,
|};

type StateProps = {|
  project: ?Project,
  branch: ?Branch,
  commit: ?Commit,
  file: ?File,
  parent: ?Branch,
|};

type DispatchProps = {|
  restoreFile: (notes: string) => void,
  dismissDialog: () => void,
|};

type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

class ConfirmRestoreFile extends Component<Props, { notes: string }> {
  state = { notes: "" };

  notesChanged = (value: string) => {
    this.setState({ notes: value });
  };

  handleSubmit = (ev: SyntheticEvent<>) => {
    ev.preventDefault();

    invariant(this.props.commit, "commit required to restore");

    this.props.restoreFile(this.state.notes);
    this.props.dismissDialog();
  };

  render() {
    const { project, branch, commit, file, parent, dismissDialog } = this.props;

    return (
      <DialogForm
        title="Restore File"
        primaryButton="Restore File"
        onClose={dismissDialog}
        onSubmit={this.handleSubmit}
        isOpen
      >
        {file && (
          <p>
            You are about to restore the file <em>{nameWithExtension(file)}</em>{" "}
            to this commit:
          </p>
        )}
        {commit && (
          <CommitListItem
            project={project}
            branch={branch}
            commit={commit}
            parent={parent}
            className={style.commit}
            first
            last
          />
        )}
        <p>
          A new commit will be created that restores the file to this point in
          time, while keeping all the history of changes.
        </p>
        <Spoiler label="Add a note (optional)">
          <InputMarkdown
            name="notes"
            projectId={this.props.projectId}
            placeholder="Add some additional context for this file restore…"
            value={this.state.notes}
            onChange={this.notesChanged}
            maxLength={Validations.maxProjectAboutLength}
            minHeight={200}
            onSubmit={this.handleSubmit}
            focusClass={style.inputFocus}
            autoFocus
          />
        </Spoiler>
      </DialogForm>
    );
  }
}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const project = getProject(state, props);
  const parent = getParentBranch(state, {
    projectId: props.projectId,
    branchId: props.branchId,
  });

  return {
    project,
    branch: getBranch(state, props),
    commit: getCommit(state, props),
    file: getFile(state, props),
    parent,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    restoreFile: (notes) => {
      invariant(restoreFile, "restoreFile required for branch action");
      dispatch(
        restoreFile(
          props.projectId,
          props.branchId,
          props.fileId,
          props.sha,
          notes
        )
      );
    },
    dismissDialog: () => dispatch(dismissDialog()),
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmRestoreFile);
