// @flow
import * as React from "react";
import Avatar from "core/components/Avatar";
import Flex from "core/components/Flex";
import Icon from "core/components/Icon";
import Popover from "core/components/Popover";
import { type ReviewStatus, reviewStatusIcon } from "core/models/review";
import type { User } from "core/types";
import style from "./style.scss";

type Props = {
  reviewer: User,
  status: ReviewStatus,
  required: boolean,
};

const reviewStatusMessages = {
  APPROVED: "approved",
  REJECTED: "requested changes",
  REQUESTED: "has not reviewed yet",
};

export default function SidebarReviewerItem(props: Props) {
  const { reviewer, status, required } = props;
  const icon = reviewStatusIcon(status);

  const tooltip = `${reviewer.name} ${reviewStatusMessages[status]}`;

  return (
    <div className={style.section}>
      <Flex align="center">
        <Flex align="center" className={style.reviewerItem}>
          <Avatar
            userId={reviewer.id}
            name={reviewer.name}
            src={reviewer.avatarUrl}
            size={16}
          />
          <p className={style.reviewerName}> {reviewer.name} </p>
        </Flex>
        {icon && (
          <Popover label={tooltip} trigger="hover">
            <Icon className={style.statusIcon} type={icon} />
          </Popover>
        )}
        {!icon && required && (
          <span className={style.reviewerNote}>Required</span>
        )}
      </Flex>
    </div>
  );
}
