// @flow
import * as React from "react";
import Button from "core/components/Button";
import Popover from "core/components/Popover";
import type { Branch } from "core/types";
import EditBranchReviewDialog from ".";

type Props = {
  isOffline: boolean,
  branchId: string,
  projectId: string,
  branch: Branch,
  className?: string,
};

type ComponentState = {
  isOpen: boolean,
};

export default class ReviewDialogButton extends React.Component<
  Props,
  ComponentState,
> {
  static defaultProps = {
    isOffline: false,
  };

  state = {
    isOpen: false,
  };

  showDialog = () => {
    this.setState({ isOpen: true });
  };

  hideDialog = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { isOffline, projectId, branchId, branch, className } = this.props;

    return (
      <React.Fragment>
        <Popover
          disabled={!isOffline}
          placement="bottom"
          label="You cannot request a review while offline"
        >
          <Button
            className={className}
            disabled={isOffline}
            onClick={this.showDialog}
            nude
            tint
          >
            Manage
          </Button>
        </Popover>
        <EditBranchReviewDialog
          isOffline={isOffline}
          dismissDialog={this.hideDialog}
          isOpen={this.state.isOpen}
          projectId={projectId}
          branchId={branchId}
          branch={branch}
        />
      </React.Fragment>
    );
  }
}
