// @flow
import classnames from "classnames";
import * as React from "react";
import Icon from "core/components/Icon";
import style from "./style.scss";

export type AvailableIcons = "project" | "branch" | "commit";

type Props = {|
  className?: string,
  icon: AvailableIcons,
|};

export default function ReportingIcon({ className, icon }: Props) {
  return (
    <Icon
      type={icon}
      fill="currentColor"
      className={classnames(style.icon, style[icon])}
    />
  );
}
