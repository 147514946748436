// @flow
import { BranchStatus } from "core/gitConstants";
import type { Branch, User } from "core/types";

export const BRANCH_ID_MASTER: string = "master";
export const BRANCH_NAME_MASTER: string = "Master";

export function hasStatus(branch: Branch) {
  return branch.status !== BranchStatus.NONE;
}

export function hasChanges(branch: Branch) {
  return !isMaster(branch) && branch.head !== branch.startedAtSha;
}

export function isActive(branch: Branch) {
  return (
    branch.status !== BranchStatus.ARCHIVED &&
    branch.status !== BranchStatus.DELETED &&
    branch.status !== BranchStatus.MERGED
  );
}

export function isArchived(branch: Branch) {
  return branch.status === BranchStatus.ARCHIVED;
}

export function isDeleted(branch: Branch) {
  return branch.status === BranchStatus.DELETED;
}

export function isMerged(branch: Branch) {
  return branch.status === BranchStatus.MERGED;
}

export function isDiverged(branch: Branch) {
  return branch.status === BranchStatus.DIVERGED;
}

export function inReview(branch: Branch) {
  return branch.status === BranchStatus.REVIEW;
}

const STALE_LENGTH = 60 * 60 * 24 * 30 * 1000; // 30 days

export function isStale(branch: Branch) {
  return (
    new Date().getTime() - new Date(branch.updatedAt).getTime() > STALE_LENGTH
  );
}

export function formattedName(branch: Branch) {
  return branch.name.replace(" (Diverged Copy)", "");
}

export function isMaster(branch: Branch) {
  return branch.id === BRANCH_ID_MASTER;
}

export function defaultBranch(options?: {
  masterToMain?: boolean,
  titleCase?: boolean,
}): string {
  if (!options) {
    return "master";
  }
  if (options.masterToMain) {
    return options.titleCase ? "Main" : "main";
  }
  return options.titleCase ? "Master" : "master";
}

export function displayName(
  branch: ?Branch,
  options?: {
    masterToMain?: boolean,
    titleCase?: boolean,
    // Since this method takes a Maybe Branch,
    // this default value allows callers to provide
    // a value in case the branch is undefined
    defaultValue?: string,
  }
): string {
  if (!branch) {
    if (options && options.defaultValue !== undefined) {
      return options.defaultValue;
    }
    return defaultBranch(options);
  }
  if (isMaster(branch)) {
    return defaultBranch(options);
  }
  return branch.name;
}

type IdParams = { projectId: string, branchId: string } | Branch;

export function uniqueId(params: IdParams): string {
  const { projectId } = params;
  let id: string = "";
  if (typeof params.branchId === "string") {
    id = params.branchId;
  } else if (typeof params.id === "string") {
    id = params.id;
  }
  return `${projectId}-${id}`;
}

export function userIsAuthor(branch: ?Branch, userId: string) {
  if (!branch) {
    return false;
  }
  return branch.userId === userId;
}

export function commitIsHead(branch: Branch, commitSha: string) {
  return branch.head === commitSha;
}

export function canGenerateAssets(branch: ?Branch, user: ?User) {
  if (!branch || !user) {
    return false;
  }
  return userIsAuthor(branch, user.id) || isMaster(branch);
}
