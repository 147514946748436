// @flow
import classnames from "classnames";
import { kebabCase } from "lodash";
import * as React from "react";
import PageTitleNavigationLink from "core/components/PageTitleNavigationLink";
import { addQuery, removeQuery } from "core/lib/location";
import type { RoleFilter } from "core/types";
import style from "./style.scss";

type Props = {
  roleFilter: ?RoleFilter,
  isGrid: boolean,
  isMobile?: boolean,
  isProject?: boolean,
};

const SUBSCRIPTION_ROLES = [
  { role: "contributor", label: "Contributors" },
  { role: "viewer", label: "Viewers" },
];

export default function PeopleFilterMenu(props: Props) {
  const ORGANIZATION_OR_PROJECT_ROLES = [
    { role: "member", label: "Members" },
    { role: "guest", label: "Guests" },
    { role: props.isProject ? "admin" : "owner", label: "Admins" },
  ];

  const navigationLinksFromRoles = (
    roles: { role: RoleFilter, label: string }[]
  ) => {
    return roles.map((roleItem) => (
      <PageTitleNavigationLink
        key={roleItem.role}
        className={style.link}
        active={props.roleFilter === roleItem.role}
        to={addQuery({ role: roleItem.role })}
        qaSelector={`${kebabCase(roleItem.label)}-title-navigation-link`}
      >
        {roleItem.label}
      </PageTitleNavigationLink>
    ));
  };

  return (
    <div
      className={classnames(style.scrollWrapper, {
        [style.listFilter]: !props.isGrid && !props.isMobile,
      })}
    >
      <div className={style.filters}>
        <PageTitleNavigationLink
          className={style.link}
          active={!props.roleFilter}
          to={removeQuery("role")}
          qaSelector="all-title-navigation-link"
        >
          All
        </PageTitleNavigationLink>
        <div className={style.divider}></div>
        <div className={style.label}>Roles</div>
        {navigationLinksFromRoles(ORGANIZATION_OR_PROJECT_ROLES)}
        <div className={style.divider}></div>
        <div className={style.label}>Seat Types</div>
        {navigationLinksFromRoles(SUBSCRIPTION_ROLES)}
      </div>
    </div>
  );
}
