// @flow
import idx from "idx";
import MurMurHash3 from "imurmurhash";
import { Abstract } from "core/lib/abstract";
import type { LayerData, LayerDataAsset } from "../types";

export const LAYER_TYPE_LABELS = {
  artboard: "Artboard",
  layer: "Layer",
  symbolMaster: "Symbol Master",
  symbolInstance: "Symbol",
  group: "Group",
  text: "Text",
  bitmap: "Bitmap",
  shapeGroup: "Shape Group",
  shapePath: "Shape Path",
  rectangle: "Rectangle",
  oval: "Oval",
  polygon: "Polygon",
  triangle: "Triangle",
  star: "Star",
  page: "Page",
  slice: "Slice",
  hotspot: "Hotspot",
  repeatGrid: "Repeat Grid",
};

// The above labels map to LayerDataType
export const SYMBOL_SOURCE_LABEL = "Symbol Source";

export function key(layerData: LayerData): string {
  return layerData._key || `root-${layerData.id}`;
}

export function pathHash(path: $ReadOnlyArray<string>): string {
  if (path.length === 0) {
    return "root";
  }

  const hashState = MurMurHash3();
  path.forEach((id) => hashState.hash(id));
  return hashState.result().toString();
}

export function typeLabel(
  layerDataType: string,
  canUseSymbolSourceDisplayName: boolean
): string {
  if (layerDataType === "symbolMaster" && canUseSymbolSourceDisplayName) {
    return SYMBOL_SOURCE_LABEL;
  }
  return LAYER_TYPE_LABELS[layerDataType];
}

export function assets(layerData: LayerData): ?Array<LayerDataAsset> {
  if (!layerData) {
    return;
  }
  const parentDescriptor = idx(
    layerData,
    (_) => _._root.symbolMasterDescriptor
  );
  // Don't include assets in nested symbols, because they belong to symbol masters
  return !parentDescriptor ? layerData.properties.assets : undefined;
}

export function symbolMasterDescriptor(
  layerData: LayerData
): ?Abstract.LayerDescriptor {
  if (!layerData) {
    return;
  }
  return (
    layerData.symbolMasterDescriptor ||
    idx(layerData, (_) => _._root.symbolMasterDescriptor) // layer is nested inside symbol master
  );
}
