// @flow
import { connect } from "react-redux";
import { trackPage, trackEvent } from "core/actions/analytics";
import createConnector from "core/lib/createConnector";
import { getBranchHead } from "core/selectors/branches";
import { getCommitForLayer, getCommitsForLayer } from "core/selectors/commits";
import { getFeatureEnabled } from "core/selectors/features";
import { getProjectPolicy } from "core/selectors/policies";
import { getProject } from "core/selectors/projects";
import type { State, Dispatch } from "core/types";
import type { OwnProps, StateProps, DispatchProps, Props } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { projectId, branchId } = props.params;
  const project = getProject(state, { projectId });
  const head = getBranchHead(state, { projectId, branchId });
  const projectPolicy = getProjectPolicy(state, { projectId });
  const commits = getCommitsForLayer(state, props.params);
  const commit = getCommitForLayer(state, props.params);
  const latestCommitSha =
    commits && commits.length > 0 ? commits[0].sha : undefined;
  const isLatestCommitFeatureEnabled = project
    ? getFeatureEnabled(state, {
        organizationId: project.organizationId,
        feature: "latest-commit-enabled",
      })
    : false;

  return {
    head,
    canShareProject: projectPolicy.share,
    canAccessProject: projectPolicy.show,
    commit,
    latestCommitSha,
    isLatestCommitFeatureEnabled,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    onSelectMode: (mode) => {
      props.onSelectMode(mode);
      dispatch(trackPage("LAYER_DETAIL", props.params));
      dispatch(
        trackEvent("Layer Viewed", {
          ...props.params,
          ...props.location.query,
        })
      );
    },
    trackResolveCommentsOptionClicked: (isShowing: boolean) => {
      const event = isShowing
        ? "TOGGLED_SHOW_RESOLVED_COMMENTS"
        : "TOGGLED_HIDE_RESOLVED_COMMENTS";

      dispatch(
        trackEvent(event, {
          projectId: props.params.projectId,
          layerId: props.params.layerId,
          branchId: props.params.branchId,
        })
      );
    },
  };
}

export default createConnector<Props, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps)
);
