// @flow
import * as React from "react";
import { Waypoint } from "react-waypoint";
import Centered from "core/components/Centered";
import Loading from "core/components/Loading";
import VirtualizedList, { type Row } from "core/components/VirtualizedList";
import type { Team, User } from "core/types";
import UserAddItem from "./UserAddItem";

const VERTICAL_PADDING = 8;
const ROW_HEIGHT = 46;

type Props = {|
  filter: string,
  onAddMember: (teamId: string, userId: string) => void,
  team: Team,
  users: User[],
  isLoadingNextPage?: boolean,
  onLoadNextPage?: () => void,
|};

export default function UserAddList(props: Props) {
  const userListRef = React.useRef<VirtualizedList | null>(null);
  const { filter } = props;

  React.useEffect(() => {
    const userList = userListRef.current;
    if (userList) {
      userList.scrollTo({ index: 0 });
    }
  }, [filter]);

  const renderInnerListElement = ({
    children,
    style: innerElementStyle,
    ...rest
  }: {
    children: React.Node,
    style: { height: number },
  }) => {
    return (
      <div
        style={{
          ...innerElementStyle,
          height: innerElementStyle.height + VERTICAL_PADDING * 2,
        }}
        {...rest}
      >
        {children}

        {props.onLoadNextPage ? (
          <div style={{ position: "absolute", width: "100%", bottom: 0 }}>
            <Waypoint topOffset="1440px" onEnter={props.onLoadNextPage} />
          </div>
        ) : null}
      </div>
    );
  };

  function getItems(): Row[] {
    const items = props.users.map((user) => ({
      style: (rowStyle) => ({
        ...rowStyle,
        top: rowStyle.top + VERTICAL_PADDING,
      }),
      height: ROW_HEIGHT,
      children: (
        <UserAddItem
          key={user.id}
          onAddMember={props.onAddMember}
          team={props.team}
          user={user}
          highlight={props.filter}
        />
      ),
    }));

    if (props.isLoadingNextPage) {
      return items.concat({
        style: (rowStyle) => ({
          ...rowStyle,
          top: rowStyle.top + VERTICAL_PADDING,
        }),
        height: ROW_HEIGHT,
        children: (
          <Centered>
            <Loading small />
          </Centered>
        ),
      });
    }

    return items;
  }

  return (
    <VirtualizedList
      ref={userListRef}
      items={getItems()}
      innerElementType={renderInnerListElement}
    />
  );
}
