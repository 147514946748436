// @flow
import * as React from "react";
import FilterPopover from "core/components/FilterPopover";

import { addQuery, removeQuery, replace } from "core/lib/location";
import type { Branch } from "core/types";
import PageTitle from "../PageTitle";

type Props = {
  branches: Branch[],
  selectedBranch: ?Branch,
};

export default function ProjectActivityHeader(props: Props) {
  const handleBranchChange = React.useCallback(
    (branchId: string) => {
      const selectedBranchId = props.selectedBranch
        ? props.selectedBranch.id
        : null;
      // Clear the branch filter, when the same branch is selected
      replace(
        branchId && branchId !== selectedBranchId
          ? addQuery({ branchId })
          : removeQuery("branchId")
      );
    },
    [props.selectedBranch]
  );

  return (
    <PageTitle
      title="Activity"
      filter={
        <FilterPopover
          disableIcon={true}
          anchor="left"
          buttonText={
            props.selectedBranch ? props.selectedBranch.name : "All Branches"
          }
          placeholderText="All Branches"
          selectedItem={props.selectedBranch}
          onSelectItem={handleBranchChange}
          items={props.branches}
        />
      }
    />
  );
}
