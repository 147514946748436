// @flow
import { schema, normalize } from "normalizr";
import {
  branchReview as branchReviewSchema,
  branch as branchSchema,
  reviewRequest as reviewRequestSchema,
} from "core/schema";
import { envelopeSchema } from "./envelope";

const reviewsResponseSchema = envelopeSchema(
  new schema.Object({
    branchReviews: [branchReviewSchema],
    branches: [branchSchema],
    reviewRequests: [reviewRequestSchema],
  })
);

export function normalizeReviews(response: *) {
  return normalize(response, reviewsResponseSchema);
}
