// @flow
import * as React from "react";
import OpenFileButton from "abstract-di/components/OpenFileButton";
import withMultiSelect from "core/components/CollectionMultiSelect/withMultiSelect";
import Error from "core/components/Empty/Error";
import NoFileChanges from "core/components/Empty/NoFileChanges";
import NoLayers from "core/components/Empty/NoLayers";
import NoPageChanges from "core/components/Empty/NoPageChanges";
import NoPages from "core/components/Empty/NoPages";
import NoResults from "core/components/Empty/NoResults";
import FileHeader from "core/components/FileHeader";
import FilePreviews from "core/components/FilePreviews";
import Flex from "core/components/Flex";
import { commitPath } from "core/lib/routes";
import * as Page from "core/models/page";
import type {
  Branch,
  Project,
  Library,
  File as TFile,
  Page as TPage,
  Layer,
  FilePreviews as TFilePreviews,
  Changeset,
  ChangesetStatus,
  ChangeStatusCounts,
} from "core/types";
import style from "./style.scss";

const SelectableFilePreviews = withMultiSelect(FilePreviews);

type Props = {
  branch: Branch,
  project: ?Project,
  file: TFile,
  page?: ?TPage,
  pages: { [uniquePageId: string]: TPage },
  library: ?Library,
  changeset: ?Changeset,
  previewsByFile: TFilePreviews,
  filter?: string,
  query?: string,
  hasError?: boolean,
  hasMore?: boolean,
  canCreateCommit?: boolean,
  isLoading?: boolean,
  isLoadingMore?: boolean,
  isChangedFile: boolean,
  isMobile?: boolean,
  isSelecting?: boolean,
  onLoadMore?: () => void,
  onFilterChange: (filter?: string) => void,
  onClearFilters: () => void,
  onSearch: (query: string) => void,
  onZoomChange: (subject: string, zoom: number) => void,
  onLayerClick?: (layer: Layer) => void,
  params: {
    projectId: string,
    branchId: string,
    fileId: string,
    pageId?: string,
  },
  layers: Layer[],
  layerCommentCounts: { [layerId: string]: number },
  layerStatuses: { [layerId: string]: ChangesetStatus },
  layerStatusCounts?: ChangeStatusCounts,
  getLayerPath?: (Layer) => Object,
  zoom: number,
};

export default class File extends React.Component<Props> {
  handleSearch = (event: SyntheticInputEvent<>) => {
    const value = event.target.value;
    this.props.onSearch(value);
  };

  renderEmpty = () => {
    if (this.props.hasError) {
      return <Error flex />;
    }

    if (this.props.query) {
      return (
        <NoResults
          type={this.props.filter ? `${this.props.filter} items` : "items"}
          term={this.props.query}
          onClearFilters={this.props.onClearFilters}
          flex
        />
      );
    }

    if (this.props.filter) {
      if (this.props.params.pageId) {
        return <NoPageChanges flex />;
      }
      return <NoFileChanges flex />;
    }

    if (this.props.params.pageId) {
      return <NoLayers flex />;
    }
    return <NoPages flex />;
  };

  render() {
    const {
      file,
      page,
      pages,
      branch,
      project,
      library,
      layers,
      zoom,
      params,
      canCreateCommit,
      hasMore,
      hasError,
      isMobile,
      isSelecting,
      isLoading,
      isLoadingMore,
      isChangedFile,
      query,
      filter,
      previewsByFile,
      onFilterChange,
      onZoomChange,
      onLoadMore,
      onLayerClick,
      layerStatuses,
      layerStatusCounts,
      getLayerPath,
    } = this.props;

    if (!file) {
      return <Error />;
    }

    const header = (
      <FileHeader
        {...params}
        file={file}
        page={page}
        branch={branch}
        library={library}
        project={project}
        isChangedFile={isChangedFile}
        layers={layers}
        layerStatusCounts={layerStatusCounts}
        commitPath={commitPath(
          file.projectId,
          params.branchId,
          file.lastChangedAtSha
        )}
        hasMore={hasMore}
        disabled={isLoading || hasError}
        query={query}
        filter={filter}
        onFilter={this.handleSearch}
        onClickFilter={onFilterChange}
        openFileButton={
          !isMobile ? (
            <OpenFileButton
              {...params}
              file={file}
              branch={branch}
              sha={file.sha}
              canCreateCommit={canCreateCommit}
            />
          ) : undefined
        }
      />
    );

    return (
      <Flex className={style.overflow} column>
        <Flex column className={style.wrapper}>
          {!isMobile && header}
          <SelectableFilePreviews
            {...params}
            contextBranchId={params.branchId}
            zoom={zoom}
            pages={pages}
            files={[file]}
            previewsByFile={previewsByFile}
            changeset={this.props.changeset}
            layersWithoutDeleted={layers}
            layerCommentCounts={this.props.layerCommentCounts}
            layerStatuses={layerStatuses}
            onZoomChange={onZoomChange}
            onLoadMore={onLoadMore}
            onLayerClick={onLayerClick}
            getLayerPath={getLayerPath}
            query={query}
            filter={filter}
            hasError={hasError}
            showLoader={hasMore}
            showFileHeaders={false}
            isLibraryPage={Page.isLibrary(page)}
            isLoading={isLoading || isLoadingMore}
            isSelecting={isSelecting}
            header={isMobile ? header : null}
            emptyContent={this.renderEmpty}
            mobile={isMobile}
            useLatestCommit
          />
        </Flex>
      </Flex>
    );
  }
}
