// @flow
import * as React from "react";
import Error from "core/components/Empty/Error";
import Flex from "core/components/Flex";
import FormHeader from "core/components/FormHeader";
import FormSection from "core/components/FormSection";
import InputSwitch from "core/components/InputSwitch";
import Loaded from "core/components/Loaded";
import Media from "core/components/Media";
import Note from "core/components/Note";
import SettingsForm from "core/components/SettingsForm";
import SettingsItem from "core/components/SettingsItem";
import type { FormProps, NotificationSettings } from "web/types";
import style from "../style.scss";
import connector from "./connector";

export type StateProps = {|
  isLoading: boolean,
  hasError: boolean,
  marketingConsent: boolean,
  notificationSettings: ?NotificationSettings,
|};

export type DispatchProps = {|
  onMount: () => void,
  onSubmit: (
    formId: string,
    values: { emailEnabled: boolean }
  ) => Promise<void>,
|};

export type ConnectedProps = {|
  ...StateProps,
  ...DispatchProps,
|};

export type Props = {
  ...ConnectedProps,
  ...FormProps,
};

class NotificationsSettings extends React.Component<Props> {
  componentDidMount() {
    this.props.onMount();
  }

  handleSubmit = (event: SyntheticEvent<>) => {
    event.preventDefault();

    this.props.onSubmit(this.props.form.id, this.props.form.values);
  };

  handleChange = (ev: SyntheticInputEvent<>) => {
    this.props.form.onChange(this.props.form.id, {
      [ev.target.name]: ev.target.checked,
    });
  };

  render() {
    const { form, isLoading, hasError } = this.props;
    const header = (
      <Media desktop>
        {(desktop) => (
          <FormHeader
            mobile={!desktop}
            icon="notifications-default"
            heading="Notifications"
            disabled={
              !form.isDirty || form.isSubmitting || isLoading || hasError
            }
            error={
              form.error ? "Notification settings could not be updated." : ""
            }
            submitText={form.isSubmitting ? "Saving changes…" : "Save changes"}
            success={form.submitSucceeded}
            successMessage="Notification settings updated."
          />
        )}
      </Media>
    );

    return (
      <SettingsForm
        noValidate
        onSubmit={this.handleSubmit}
        header={header}
        documentTitle="Notifications"
      >
        <Loaded loading={isLoading} flex title="Loading settings…">
          {() => {
            if (hasError) {
              return <Error flex />;
            }
            return (
              <FormSection heading="Emails">
                <SettingsItem switch>
                  <InputSwitch
                    responsive
                    label={
                      <React.Fragment>
                        Unread Notifications
                        <Note component="div" className={style.note}>
                          Receive emails to your primary email address for
                          unread notifications.
                        </Note>
                      </React.Fragment>
                    }
                    checked={form.values.emailEnabled}
                    name="emailEnabled"
                    onChange={this.handleChange}
                  />
                </SettingsItem>
                <SettingsItem>
                  <Flex column>
                    <div>Announcements &amp; Promotions</div>
                    <Note component="div" className={style.note}>
                      Follow the link at the footer of a promotional email to
                      change your preferences relating to product announcements,
                      promotions, and events.
                    </Note>
                  </Flex>
                </SettingsItem>
              </FormSection>
            );
          }}
        </Loaded>
      </SettingsForm>
    );
  }
}

export default connector(NotificationsSettings);
