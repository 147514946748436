// @flow
import classnames from "classnames";
import { some, every, capitalize } from "lodash";
import * as React from "react";
import OpenFileButton from "abstract-di/components/OpenFileButton";
import Button from "core/components/Button";
import FileIcon from "core/components/FileIcon";
import FileMenu from "core/components/FileMenu";
import FileName from "core/components/FileName";
import Flex from "core/components/Flex";
import Icon from "core/components/Icon";
import MultiSelectButton from "core/components/MultiSelectButton";
import Popover from "core/components/Popover";
import ProjectIcon from "core/components/ProjectIcon";
import Time from "core/components/Time";
import { V3Link as Link } from "core/lib/router";
import { projectPath, filePath } from "core/lib/routes";
import * as Page from "core/models/page";
import type { File, Layer, Page as TPage, Library, Project } from "core/types";
import style from "./style.scss";

type Props = {
  file: File,
  page?: TPage,
  branchId?: string,
  library: ?Library,
  project: ?Project,
  layers: Layer[],
  disabled?: boolean,
  hasMore?: boolean,
  filter?: string,
  className?: string,
  openFileButton?: ?React.Element<typeof OpenFileButton>,
  commitPath?: string,
  onSelectEntities?: ({
    page?: TPage,
    layer?: Layer,
    layers?: Layer[],
  }) => void,
  isSelecting: boolean,
  layerSelected?: (layer: Layer) => boolean,
  pageSelected?: (page: TPage) => boolean,
  pagePartiallySelected?: (page: TPage) => boolean,
  fileSelected?: (file: File) => boolean,
  filePartiallySelected?: (file: File) => boolean,
  qaSelector?: string,
};

export default class HeaderTop extends React.Component<Props> {
  static defaultProps = {
    layers: [],
    library: null,
    project: null,
  };

  isLibraryPage = () => {
    return !!this.props.library || Page.isLibrary(this.props.page);
  };

  isSelectable = () => {
    return !!(
      this.props.layers.length &&
      this.props.filter !== "removed" &&
      !this.props.disabled &&
      this.props.isSelecting
    );
  };

  isSelected = (isLibraryPage: boolean) => {
    if (isLibraryPage) {
      if (
        !this.props.filter &&
        this.props.page &&
        this.props.pageSelected &&
        this.props.pageSelected(this.props.page)
      ) {
        return true;
      }
    } else if (
      !this.props.filter &&
      this.props.fileSelected &&
      this.props.fileSelected(this.props.file)
    ) {
      return true;
    }

    return !!(
      !this.props.hasMore &&
      this.props.layerSelected &&
      every(this.props.layers, this.props.layerSelected)
    );
  };

  isPartiallySelected = (isLibraryPage: boolean) => {
    if (isLibraryPage) {
      if (
        !this.props.filter &&
        this.props.page &&
        this.props.pagePartiallySelected &&
        this.props.pagePartiallySelected(this.props.page)
      ) {
        return true;
      }
    } else if (
      !this.props.filter &&
      this.props.filePartiallySelected &&
      this.props.filePartiallySelected(this.props.file)
    ) {
      return true;
    }

    return !!(
      this.props.layerSelected &&
      some(this.props.layers, this.props.layerSelected)
    );
  };

  handleSelect = (selectable: boolean, selected: boolean) => {
    const { onSelectEntities, layers, file, page, hasMore } = this.props;
    if (!selectable || !onSelectEntities) {
      return undefined;
    }
    return (event: SyntheticMouseEvent<>) => {
      event.preventDefault();
      if (page) {
        onSelectEntities({ page: { ...page, hasMore }, layers, selected });
      } else {
        onSelectEntities({ file: { ...file, hasMore }, layers, selected });
      }
    };
  };

  renderFileName = () => {
    const { library, page, file, isSelecting } = this.props;
    const isLibraryPage = this.isLibraryPage();
    const shouldShowContextMenu = !(isSelecting || isLibraryPage);

    let name = file.name;
    if (isLibraryPage) {
      name = page ? page.name : library ? library.name : name;
    }

    const fileName = (showMenu?) => (
      <FileName
        icon={null}
        type={file.type}
        name={name}
        onContextMenu={showMenu}
        fileClassName={style.fileName}
      />
    );
    if (library && !library.isProjectUnavailable) {
      return (
        <Link
          className={style.fileLink}
          to={filePath(
            library.projectId || "",
            library.branchId || "",
            library.fileId || ""
          )}
        >
          <div className={style.fileNameWrap}>
            {fileName()}
            <Icon type="go-to" />
          </div>
        </Link>
      );
    }

    if (this.props.branchId) {
      return (
        <FileMenu
          projectId={file.projectId}
          branchId={this.props.branchId}
          file={file}
        >
          {(showMenu, renderMenuTarget) => {
            return renderMenuTarget((ref, buttonProps) => {
              return (
                <div className={style.fileNameWrap}>
                  {fileName(showMenu)}
                  {shouldShowContextMenu && (
                    <Button
                      icon="overflow"
                      onClick={showMenu}
                      innerRef={ref}
                      className={style.more}
                      nude
                      {...buttonProps}
                    />
                  )}
                </div>
              );
            });
          }}
        </FileMenu>
      );
    } else {
      return <div className={style.fileNameWrap}>{fileName()}</div>;
    }
  };

  renderLibraryInformation = () => {
    const { project, library, file } = this.props;

    if (!library) {
      return "Linked Library";
    }

    const projectName = project ? project.name : library.projectName;
    const projectId = project ? project.id : library.projectId || "";
    let warning = "";
    if (library.isProjectUnsynced) {
      warning = "The project containing this library is not synced.";
    } else if (library.isProjectUnavailable) {
      warning =
        "The project containing this library is not available, it may have been deleted.";
    }

    return (
      <React.Fragment>
        {library.projectId !== file.projectId ? "Linked Library" : "Library"}
        <Flex align="center" inline className={style.projectName}>
          {" · "}
          <ProjectIcon color={project ? project.color : undefined} />
          {library.isProjectUnsynced || !library.isProjectUnavailable ? (
            <Link to={projectPath(projectId)}>{projectName}</Link>
          ) : (
            projectName
          )}
          {warning && (
            <Popover placement="bottom" label={warning}>
              <Icon type="warning-orange" />
            </Popover>
          )}
        </Flex>
      </React.Fragment>
    );
  };

  renderFileInformation = () => {
    const { file, commitPath } = this.props;
    return (
      <React.Fragment>
        {commitPath ? (
          <React.Fragment>
            Last edited{" "}
            <Link to={commitPath}>
              <Time date={file.updatedAt || ""} />
            </Link>
          </React.Fragment>
        ) : (
          <React.Fragment>
            Edited <Time date={file.updatedAt || ""} />
          </React.Fragment>
        )}
        {file.applicationVersion && (
          <span>
            {" · "}
            <Popover
              placement="bottom"
              label={`The version of ${capitalize(file.type)} this file was${
                commitPath ? " last" : ""
              } saved in`}
            >
              <span>
                {capitalize(file.type)} {file.applicationVersion}
              </span>
            </Popover>
          </span>
        )}
      </React.Fragment>
    );
  };

  render() {
    const { file, filter, openFileButton, isSelecting, className, qaSelector } =
      this.props;

    const isLibraryPage = this.isLibraryPage();
    const selectable = this.isSelectable();
    const selected = selectable && this.isSelected(isLibraryPage);
    const partiallySelected =
      selectable && !selected && this.isPartiallySelected(isLibraryPage);
    const onSelect = this.handleSelect(selectable, selected);

    return (
      <div className={classnames(style.top, className)} data-qa={qaSelector}>
        <div className={style.left}>
          {isSelecting && (
            <MultiSelectButton
              small
              disabled={!selectable}
              selected={selected}
              partiallySelected={partiallySelected}
              onSelect={onSelect}
              className={style.selectButton}
              tooltip={
                filter
                  ? `Select all ${filter} items in the ${
                      isLibraryPage ? "page" : "file"
                    }`
                  : undefined
              }
            />
          )}
          <FileIcon
            onClick={onSelect}
            type={file.type}
            isLibrary={file.isLibrary || isLibraryPage}
            className={style.icon}
            large
          />
          <div className={style.fileInfo}>
            {this.renderFileName()}
            <div className={style.lastEdited}>
              {isLibraryPage
                ? this.renderLibraryInformation()
                : this.renderFileInformation()}
            </div>
          </div>
        </div>
        <div className={style.right}>
          {openFileButton &&
            !isLibraryPage &&
            React.cloneElement(openFileButton, { disabled: isSelecting })}
        </div>
      </div>
    );
  }
}
