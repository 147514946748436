// @flow
import * as React from "react";
import Centered from "core/components/Centered";
import Heading from "core/components/Heading";
import style from "./style.scss";

type Props = {
  termNotFound: string,
};

export default function NoneFound(props: Props) {
  return (
    <Centered flex direction="column">
      <Heading level="3" size="xl" className={style.noResults}>
        {`No results for "${props.termNotFound}"`}
      </Heading>
    </Centered>
  );
}
