// @flow
import * as React from "react";
import type { LayerGuideData } from "core/types";
import style from "./style.scss";

type Props = {
  width: number,
  height: number,
  scale: number,
  style: { [string]: mixed },
  horizontal: ?LayerGuideData,
  vertical: ?LayerGuideData,
};

export default function GuidesOverlay(props: Props) {
  const { horizontal, vertical } = props;
  const width = props.width * props.scale;
  const height = props.height * props.scale;

  return (
    <div
      style={{
        ...props.style,
        width,
        height,
      }}
      className={style.gridContainer}
    >
      {vertical &&
        vertical.guides &&
        vertical.guides.length > 0 &&
        vertical.guides.map((guide) => (
          <div
            style={{ top: props.scale * (vertical.base + guide) }}
            className={style.verticalGuide}
          >
            <div className={style.verticalGuideHoverArea} />
            <div className={style.guideLabel}>{guide}px</div>
          </div>
        ))}

      {horizontal &&
        horizontal.guides &&
        horizontal.guides.length > 0 &&
        horizontal.guides.map((guide) => (
          <div
            style={{ left: props.scale * (horizontal.base + guide) }}
            className={style.horizontalGuide}
          >
            <div className={style.horizontalGuideHoverArea} />
            <div className={style.guideLabel}>{guide}px</div>
          </div>
        ))}
    </div>
  );
}
