// @flow
import invariant from "invariant";
import { isEqual } from "lodash";
import React, { Component } from "react";
import AddSlackChannels from "core/components/AddSlackChannels";
import Button from "core/components/Button";
import FormHeader from "core/components/FormHeader";
import FormSection from "core/components/FormSection";
import Media from "core/components/Media";
import Select from "core/components/Select";
import SettingsForm from "core/components/SettingsForm";
import SettingsItem from "core/components/SettingsItem";
import window from "core/global/window";
import { setLocationState, push } from "core/lib/location";
import type {
  OrganizationSettings,
  SlackIntegration,
  SlackChannel,
} from "core/types";
import { fetchInitiateIntegrationUrl } from "web/api";
import withForm from "web/containers/withForm";
import { organizationIntegrations } from "web/routeHelpers";
import type { FormProps, Organization } from "web/types";
import type { FormValues } from "../../models/form";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  location: Object,
  params: { organizationId: string },
|};

export type DispatchProps = {|
  removeIntegration: (integrationId: string, kind: string) => *,
  slackChannelFormSubmitted: (
    id: string,
    integrationId: string,
    values: FormValues,
    organizationSettings: OrganizationSettings
  ) => *,
|};

export type StateProps = {|
  integration: ?SlackIntegration,
  location: Object,
  organization: ?Organization,
  settings: ?OrganizationSettings,
  showUpdateAvailable: boolean,
|};

export type ConnectedProps = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

export type Props = {
  ...ConnectedProps,
  ...FormProps,
};

type State = {
  successfullyCreated: boolean,
  disconnecting: boolean,
};

class SlackIntegrationSettings extends Component<Props, State> {
  state = {
    successfullyCreated: false,
    disconnecting: false,
  };

  handleSlackChannelAdd = (channel: SlackChannel) => {
    this.props.form.onChange(this.props.form.id, {
      channels: [...this.props.form.values.channels, channel.id],
    });

    if (this.state.successfullyCreated) {
      this.setState({ successfullyCreated: false });
    }
  };
  handleSlackChannelRemove = (channel: SlackChannel) => {
    const filtered = this.props.form.values.channels.filter(
      (c) => c !== channel.id
    );
    this.props.form.onChange(this.props.form.id, {
      channels: filtered,
    });

    if (this.state.successfullyCreated) {
      this.setState({ successfullyCreated: false });
    }
  };

  handleConnect = async (kind: string) => {
    const response = await fetchInitiateIntegrationUrl(
      this.props.params.organizationId,
      kind
    );
    window.location.href = response.url;
  };

  handleDisconnect = () => {
    this.setState({ disconnecting: true }, () => {
      this.props.removeIntegration(
        this.props.integration ? this.props.integration.id : "",
        "Slack"
      );
    });
  };

  handleSlackChannelChange = (event) => {
    this.props.form.onChange(this.props.form.id, {
      channel: event.target.value,
    });

    if (this.state.successfullyCreated) {
      this.setState({ successfullyCreated: false });
    }
  };

  channelParams = (slackV2, event) => {
    if (slackV2) {
      return { channels: this.props.form.values.channels };
    } else {
      return { channel: event.target.channel.value };
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const { settings } = this.props;
    invariant(
      settings,
      "organizationSettings have to exist in order for there to be an integration"
    );

    const integration = this.props.integration;
    const integrationId = integration ? integration.id : "";
    const slackV2 = integration ? integration.details.slackV2 : false;
    this.props.slackChannelFormSubmitted(
      this.props.form.id,
      integrationId,
      this.channelParams(slackV2, event),
      settings
    );
  };

  componentDidMount() {
    /* Detects whether we were just redirected from IntegrationComplete
      because the integration was successfully created */
    const successfullyCreated =
      this.props.location.state &&
      this.props.location.state.integrationCreationSuccess;

    if (successfullyCreated) {
      /* If so, we set a success state on the component, and then reset the
      boolean in location.state */
      this.setState({ successfullyCreated });
      setLocationState(this.props.location, {
        integrationCreationSuccess: false,
      });
    }

    /* This is to make sure that form.submitSucceeded is always false when
      the component mounts */
    this.props.form.onChange(this.props.form.id, {});
  }

  componentDidUpdate(prevProps: Props) {
    if (this.state.disconnecting && !this.props.integration) {
      push({
        pathname: organizationIntegrations(
          prevProps.integration ? prevProps.integration.organizationId : ""
        ),
        state: { integrationDeletionSuccess: true },
      });
    }
  }

  isDirty = (form, integration) => {
    if (integration.details.slackV2) {
      return !isEqual(form.values.channels, integration.details.channels);
    } else {
      return form.isDirty;
    }
  };

  render() {
    const { form, integration, organization, showUpdateAvailable } = this.props;

    if (!integration || !organization) {
      return null;
    }

    const header = (
      <Media desktop>
        {(desktop) => (
          <FormHeader
            mobile={!desktop}
            heading="Slack"
            subheading="Integration Details"
            error={
              form.error ? "Could not update Slack integration settings" : ""
            }
            success={form.submitSucceeded || this.state.successfullyCreated}
            successMessage={
              this.state.successfullyCreated
                ? "You’re connected to Slack!"
                : "Updated Slack integration settings"
            }
          />
        )}
      </Media>
    );

    const channelsV1 = (
      <SettingsItem input>
        <Select
          responsive
          label="Default Slack channel"
          defaultValue={integration.details.channel || ""}
          onChange={this.handleSlackChannelChange}
          name="channel"
        >
          <option />
          {integration.details.availableChannels.map((c) => (
            <option key={c.id} value={c.id}>
              #{c.name}
            </option>
          ))}
        </Select>
      </SettingsItem>
    );

    const channelsV2 = (
      <SettingsItem input>
        <AddSlackChannels
          responsive
          label={"Default slack channels"}
          availableChannels={integration.details.availableChannels}
          selectedChannelIds={this.props.form.values.channels}
          onSelectChannel={this.handleSlackChannelAdd}
          onRemoveChannel={this.handleSlackChannelRemove}
        />
      </SettingsItem>
    );

    return (
      <SettingsForm
        header={header}
        onSubmit={this.handleSubmit}
        documentTitle="Integration Details"
      >
        <FormSection
          heading={
            showUpdateAvailable && "Update for Slack integration available"
          }
          action={
            showUpdateAvailable && (
              <Button
                type="button"
                primary
                onClick={() => this.handleConnect("Slack")}
              >
                Update Slack
              </Button>
            )
          }
        >
          <SettingsItem label="Integration description" responsive>
            Auto-posts Branches comments, commits, and merges.
          </SettingsItem>
          <SettingsItem label="Organization" responsive>
            {organization.name}
          </SettingsItem>
          <SettingsItem label="Connection status">
            <span className={style.connected}>Connected</span>
            <Button
              onClick={this.handleDisconnect}
              disabled={this.state.disconnecting}
            >
              {this.state.disconnecting ? "Disconnecting…" : "Disconnect"}
            </Button>
          </SettingsItem>
          <SettingsItem label="Slack team" responsive>
            {integration.details.teamName}
          </SettingsItem>
          {integration.details.slackV2 ? channelsV2 : channelsV1}
          <SettingsItem>
            <span />
            <Button
              primary
              type="submit"
              disabled={!this.isDirty(form, integration) || form.isSubmitting}
            >
              {form.isSubmitting ? "Saving changes…" : "Save changes"}
            </Button>
          </SettingsItem>
        </FormSection>
      </SettingsForm>
    );
  }
}

function getDefaultValues(props: Props) {
  return {
    channel: props.integration ? props.integration.details.channel || "" : "",
    channels: props.integration ? props.integration.details.channels || [] : [],
  };
}

export default connector(
  withForm<ConnectedProps>(
    SlackIntegrationSettings,
    "slack-channel-form",
    getDefaultValues
  )
);
