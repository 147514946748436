// @flow
import classnames from "classnames";
import * as React from "react";
import ButtonLink from "core/components/ButtonLink";
import DialogForm from "core/components/DialogForm";
import Input from "core/components/Input";
import InputCheckbox from "core/components/InputCheckbox";
import Select from "core/components/Select";
import { type SubscriptionCancelRequestFormValues } from "core/requests/subscriptions";
import connector from "./connector";
import style from "./style.scss";

const CANCELLATION_REASONS = [
  { label: "Budget", value: "Budget" },
  { label: "Performance or reliability", value: "Performance or reliability" },
  {
    label: "Moving to another design tool",
    value: "Moving to another design tool",
  },
  { label: "Difficult to use", value: "Difficult to use" },
  { label: "Didn’t see the value", value: "Didn't see the value" },
  { label: "Other", value: "Other" },
];

const CANCELLATION_REASON_DESIGN_TOOL_VALUE = CANCELLATION_REASONS[2].value;
const CANCELLATION_REASON_OTHER_VALUE = CANCELLATION_REASONS[5].value;

const DESIGN_TOOL_OPTIONS = [
  { label: "Adobe XD", value: "Adobe XD" },
  { label: "Figma", value: "Figma" },
  { label: "Kactus", value: "Kactus" },
  { label: "InVision", value: "Invision" },
  { label: "Plant", value: "Plant" },
  { label: "Sketch Teams", value: "Sketch Teams" },
  { label: "Other", value: "Other" },
];

const FIGMA_VALUE = DESIGN_TOOL_OPTIONS[1].value;

export type OwnProps = {|
  isOpen: boolean,
  onClose: () => void,
  organizationId: string,
|};

export type StateProps = {|
  hasError: boolean,
  isLoading: boolean,
|};

export type DispatchProps = {|
  cancelSubscription: (
    formValues: SubscriptionCancelRequestFormValues,
    onSuccess: () => void
  ) => void,
  openSupportWindow: () => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type State = {|
  cancellationReasons: Set<string>,
  cancellationSuccessful: boolean,
  designTool: string,
  otherReason: string,
  waitlistEmail: string,
|};

class CancelSubscriptionDialog extends React.Component<Props, State> {
  state = {
    cancellationReasons: new Set(),
    cancellationSuccessful: false,
    designTool: "",
    otherReason: "",
    waitlistEmail: "",
  };

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState({
        cancellationReasons: new Set(),
        cancellationSuccessful: false,
        designTool: "",
        otherReason: "",
        waitlistEmail: "",
      });
    }
  }

  handleCheckboxChange = (event: SyntheticInputEvent<>) => {
    const { checked, name } = event.target;

    this.setState((prevState) => {
      const cancellationReasons = new Set(prevState.cancellationReasons);
      let designTool = prevState.designTool;
      let otherReason = prevState.otherReason;
      let waitlistEmail = prevState.waitlistEmail;

      if (checked) {
        cancellationReasons.add(name);
      } else {
        cancellationReasons.delete(name);

        if (name === CANCELLATION_REASON_DESIGN_TOOL_VALUE) {
          waitlistEmail = "";
          designTool = "";
          waitlistEmail = "";
        } else if (name === CANCELLATION_REASON_OTHER_VALUE) {
          otherReason = "";
        }
      }

      return {
        cancellationReasons,
        designTool,
        otherReason,
        waitlistEmail,
      };
    });
  };

  handleDesignToolChange = (event: SyntheticInputEvent<>) => {
    const { value } = event.target;

    if (
      this.state.cancellationReasons.has(CANCELLATION_REASON_DESIGN_TOOL_VALUE)
    ) {
      this.setState(() => {
        if (value !== FIGMA_VALUE) {
          return { designTool: value, waitlistEmail: "" };
        }

        return { designTool: value };
      });
    }
  };

  handleOtherReasonChange = (event: SyntheticInputEvent<>) => {
    this.setState((prevState) => {
      const cancellationReasons = new Set(prevState.cancellationReasons);
      cancellationReasons.add(CANCELLATION_REASON_OTHER_VALUE);

      return {
        cancellationReasons,
        otherReason: event.target.value,
      };
    });
  };

  handleWaitlistEmailChange = (event: SyntheticInputEvent<>) => {
    this.setState({ waitlistEmail: event.target.value });
  };

  handleCancelSubscription = () => {
    const cancellationReasons = new Set(this.state.cancellationReasons);
    // "Other" isn't part of the picklist in SFDC, so let's remove it
    // from mainReasons and only send the user input value as otherReason
    cancellationReasons.delete(CANCELLATION_REASON_OTHER_VALUE);
    // The API expects mainReasons to be a comma-separated string
    const mainReasons = Array.from(cancellationReasons).join(",");
    // If no reasons provided, send the string "user skipped" as otherReason
    const otherReason =
      mainReasons === "" && this.state.otherReason === ""
        ? "user skipped"
        : this.state.otherReason;
    const otherTool = this.state.designTool;

    this.props.cancelSubscription(
      { email: this.state.waitlistEmail, mainReasons, otherReason, otherTool },
      () => {
        this.setState({ cancellationSuccessful: true });
      }
    );
  };

  renderCheckboxes = () => {
    return CANCELLATION_REASONS.map<React.Node>(({ label, value }) => {
      const inputLabel =
        value === CANCELLATION_REASON_OTHER_VALUE ? (
          <Input
            onChange={this.handleOtherReasonChange}
            placeholder="Other"
            required={this.state.cancellationReasons.has(value)}
            value={this.state.otherReason}
          />
        ) : (
          label
        );

      return (
        <React.Fragment key={value}>
          <InputCheckbox
            checkboxClass={style.checkbox}
            checked={this.state.cancellationReasons.has(value)}
            key={value}
            label={inputLabel}
            labelClass={style.checkboxLabel}
            name={value}
            onChange={this.handleCheckboxChange}
            wrapperClass={style.reason}
          />
          {value === CANCELLATION_REASON_DESIGN_TOOL_VALUE &&
            this.state.cancellationReasons.has(value) && (
              <Select
                onChange={this.handleDesignToolChange}
                value={this.state.designTool}
                wrapperClass={style.designTool}
                required
              >
                <option value="">Select an option</option>
                {DESIGN_TOOL_OPTIONS.map(({ label, value }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </Select>
            )}

          {value === CANCELLATION_REASON_DESIGN_TOOL_VALUE &&
            this.state.designTool === FIGMA_VALUE && (
              <div className={style.waitlistWrapper}>
                Abstract is currently working on an integration with Figma. If
                you’d like us to let you know when it’s available, please
                provide your email address.
                <Input
                  onChange={this.handleWaitlistEmailChange}
                  placeholder="janedoe@domain.com"
                  type="email"
                  value={this.state.waitlistEmail}
                  wrapperClass={style.waitlistEmail}
                />
              </div>
            )}
        </React.Fragment>
      );
    });
  };

  renderCancelWithSurvey = () => {
    return (
      <DialogForm
        dangerous
        disabled={this.props.isLoading}
        isOpen={this.props.isOpen}
        loading={this.props.isLoading}
        onClose={this.props.onClose}
        onSubmit={this.handleCancelSubscription}
        primaryButton={
          this.props.isLoading
            ? "Canceling subscription…"
            : "Cancel subscription"
        }
        secondaryButton="Cancel"
        title="Sorry to see you go"
      >
        <p className={style.copy}>
          Your account will become read-only at the end of your billing cycle
          and your data will be stored for 90 days.
        </p>
        <p className={classnames(style.copy, style.prompt)}>
          Will you share your reason for leaving Abstract so we can improve the
          experience for others?
        </p>
        {this.renderCheckboxes()}
        <p className={classnames(style.copy, style.supportCopy)}>
          Before you cancel, consider{" "}
          <ButtonLink onClick={this.props.openSupportWindow}>
            contacting support
          </ButtonLink>{" "}
          to see if we can resolve any issues.
        </p>
        {this.props.hasError && (
          <p className={classnames(style.copy, style.error)}>
            We encountered an issue while attempting to cancel your
            subscription. Please{" "}
            <ButtonLink onClick={this.props.openSupportWindow}>
              contact support
            </ButtonLink>{" "}
            for assistance.
          </p>
        )}
      </DialogForm>
    );
  };

  renderSuccess = () => {
    return (
      <DialogForm
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        onSubmit={this.props.onClose}
        primaryButton="Close"
        title="Your subscription has been canceled"
      >
        <p className={style.copy}>
          Thank you for using Abstract. You can reactivate your account anytime
          within the next 90 days.
        </p>
      </DialogForm>
    );
  };

  render() {
    return this.state.cancellationSuccessful
      ? this.renderSuccess()
      : this.renderCancelWithSurvey();
  }
}

export default connector(CancelSubscriptionDialog);
