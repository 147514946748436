// @flow
import * as Abstract from "abstract-sdk";
import apiRequest from "abstract-di/api";
import { entitiesDeleted, entitiesReceived } from "core/actions/entities";
import { showToast } from "core/actions/toasts";
import * as ProjectMembership from "core/models/projectMembership";
import defineRequest from "core/requests";
import {
  normalizeProjectMemberships,
  normalizeUpdateProjectMembershipResponse,
  normalizeFetchUserSuggestionsResponse,
} from "core/schemas/projectMembership";
import type {
  ProjectMembership as TProjectMembership,
  SubscriptionRole,
  User as TUser,
  Dispatch,
  ProjectRoleFilter,
  ThunkAction,
} from "core/types";

//TODO: use Abstract.OrganizationProjectDescriptor from sdk once available
type ProjectMembershipParams = {|
  ...Abstract.ProjectMembershipDescriptor,
  role: $PropertyType<TProjectMembership, "role">,
|};

type ProjectMembershipIdParams = {
  ...Abstract.ProjectMembershipDescriptor,
};

type PaginatedProjectMembershipsParams = {
  ...Abstract.ProjectDescriptor,
  limit: number,
  offset: number,
  role?: ProjectRoleFilter,
  subscriptionRole?: SubscriptionRole,
  query?: string,
  // included for compatibility with getUsersForProject
  fromOrganization?: boolean,
  userId?: string,
};

export const PaginatedProjectMembershipsRequest = defineRequest<
  PaginatedProjectMembershipsParams,
  PaginatedProjectMembershipsParams,
>({
  id(params) {
    const {
      limit,
      offset,
      projectId,
      role = "",
      query = "",
      subscriptionRole = "",
    } = params;
    return `get:projects/${projectId}/memberships/limit/${limit}/offset/${offset}/role/${role}/subscription_role/${subscriptionRole}/search/${query}`;
  },
  perform(params) {
    return apiRequest(
      "get",
      `projects/${params.projectId}/memberships`,
      {
        limit: params.limit,
        offset: params.offset,
        role: params.role,
        subscriptionRole: params.subscriptionRole,
        search: params.query,
      },
      18
    );
  },
  onSuccess(response, params, dispatch) {
    const { entities } = normalizeProjectMemberships(response.data);
    return dispatch(entitiesReceived(entities));
  },
  invalidateable: true,
});

function recursiveOnSuccess(response, params, dispatch) {
  if (Array.isArray(response.data) && response.data.length === params.limit) {
    const newParams = {
      ...params,
      offset: params.offset + params.limit,
    };

    return dispatch(
      PaginatedProjectMembershipsRequest.perform({
        params: newParams,
        onSuccess: (response) =>
          recursiveOnSuccess(response, newParams, dispatch),
      })
    );
  }
}

type ProjectMembershipsRequestParams = {
  ...Abstract.ProjectDescriptor,
  role?: $PropertyType<TProjectMembership, "role">,
};

export const ProjectMembershipsFetchAllRequest = defineRequest<
  ProjectMembershipsRequestParams,
  ProjectMembershipsRequestParams,
>({
  id: (params) => {
    const { projectId, role } = params;
    return `get:projects/${projectId}/memberships/${role || ""}`;
  },
  perform(params, dispatch, getState) {
    const paginatedParams = { ...params, limit: 100, offset: 0 };

    return dispatch(
      PaginatedProjectMembershipsRequest.perform({
        params: paginatedParams,
        onSuccess: (response) => {
          recursiveOnSuccess(response, paginatedParams, dispatch);
        },
      })
    );
  },
  force: false,
  invalidateable: true,
});

export const ProjectMembershipRequest = defineRequest<
  ProjectMembershipIdParams,
  ProjectMembershipIdParams,
>({
  id: (params) => {
    const { projectId, userId } = params;
    return `get:project_membership/${projectId}-${userId}`;
  },
  perform: (params) => {
    const { projectId, userId } = params;
    return apiRequest(
      "get",
      `projects/${projectId}/memberships/${userId}`,
      {},
      5
    );
  },
  onSuccess: (response, params, dispatch: Dispatch): ThunkAction => {
    const { entities } = normalizeUpdateProjectMembershipResponse(response);
    return dispatch(entitiesReceived(entities));
  },
});

export const UpdateProjectMembershipRequest = defineRequest<
  ProjectMembershipParams,
  ProjectMembershipIdParams,
>({
  id: (params) => {
    const { projectId, userId } = params;
    return `put:project_membership/${projectId}-${userId}`;
  },
  perform: (params) => {
    const { projectId, userId, role } = params;
    return apiRequest(
      "put",
      `projects/${projectId}/memberships/${userId}`,
      { role },
      5
    );
  },
  onError: (error: Error, params, dispatch: Dispatch) => {
    dispatch(
      showToast({
        text: "There was a problem updating the membership, try again?",
      })
    );
  },
  onSuccess: (response, params, dispatch: Dispatch): ThunkAction => {
    const { entities } = normalizeUpdateProjectMembershipResponse(response);
    return dispatch(entitiesReceived(entities));
  },
});

export const AddProjectMembershipRequest = defineRequest<
  ProjectMembershipParams,
  ProjectMembershipIdParams,
>({
  id: (params) => {
    const { projectId, userId } = params;
    return `post:project_membership/${projectId}-${userId}`;
  },
  perform: (params) => {
    const { projectId, userId, role } = params;
    return apiRequest(
      "post",
      `projects/${projectId}/memberships`,
      { userId, role },
      5
    );
  },
  onError: (error: Error, params, dispatch: Dispatch) => {
    dispatch(
      showToast({ text: "There was a problem adding this user, try again?" })
    );
  },
  onSuccess: (response, params, dispatch: Dispatch): ThunkAction => {
    const { entities } = normalizeUpdateProjectMembershipResponse(response);
    return dispatch(entitiesReceived(entities));
  },
});

export const DeleteProjectMembershipRequest = defineRequest<
  Abstract.ProjectMembershipDescriptor,
  Abstract.ProjectMembershipDescriptor,
>({
  id: (params) => {
    const { projectId, userId } = params;
    return `delete:project_membership/${projectId}-${userId}`;
  },
  perform: (params) => {
    const { projectId, userId } = params;
    return apiRequest(
      "delete",
      `projects/${projectId}/memberships/${userId}`,
      {},
      5
    );
  },
  onSuccess: (response, params, dispatch): ThunkAction => {
    return dispatch(
      entitiesDeleted({
        projectMemberships: [ProjectMembership.uniqueId(params)],
      })
    );
  },
});

type UserSearchParams = {
  projectId: string,
  search: string,
  limit?: number,
  offset?: number,
};

type ProjectMembershipWithUser = TProjectMembership & {
  user: TUser,
};

export type ProjectMembershipListResponse = {|
  data: ProjectMembershipWithUser[],
  meta: {
    limit: number,
    offset: number,
    search: string,
    total: number,
    next_offset: number,
  },
|};

export const FetchUserSuggestionsRequest = defineRequest<
  UserSearchParams,
  UserSearchParams,
>({
  id: (params) => {
    const { projectId, search, limit = 0, offset = 0 } = params;
    return `post:project/${projectId}/memberships?search=${search}&limit=${limit}&offset=${offset}`;
  },
  perform(params): Promise<ProjectMembershipListResponse> {
    const { projectId, search, limit, offset } = params;
    return apiRequest(
      "get",
      `projects/${projectId}/memberships`,
      { limit, search, offset },
      18 // This API version supports the membership query search.
    );
  },
  onSuccess: (
    response: ProjectMembershipListResponse,
    params,
    dispatch: Dispatch
  ): ThunkAction => {
    const { entities } = normalizeFetchUserSuggestionsResponse(response);
    return dispatch(entitiesReceived(entities));
  },
  force: false,
});
