// @flow
import * as React from "react";
import { connect } from "react-redux";
import { withData } from "core/components/DataLoader";
import NotPermitted from "core/components/Empty/NotPermitted";
import Loaded from "core/components/Loaded";
import MainContent from "core/components/MainContent";
import PageTitle from "core/components/PageTitle";
import PageTitleNavigationLink from "core/components/PageTitleNavigationLink";
import createConnector from "core/lib/createConnector";
import { replace } from "core/lib/location";
import { forbidden, isLoading, notFound } from "core/models/request";
import { canUseCustomEmojis } from "core/selectors/features";
import { getOrganizationSettings } from "core/selectors/organizationSettings";
import { getOrganization } from "core/selectors/organizations";
import { getOrganizationPolicy } from "core/selectors/policies";
import type {
  Dispatch,
  State,
  Organization,
  OrganizationSettings,
} from "core/types";
import { OrganizationSettingsRequest } from "web/requests/organizationSettings";
import {
  accountProfile,
  organizationDetails,
  organizationIntegrations,
  organizationPermissions,
  organizationSharing,
  organizationSubscription,
  organizationEmojisAdmin,
} from "web/routeHelpers";
import { getCurrentUser } from "web/selectors/session";
import type { Policy, User } from "web/types";

type OwnProps = {|
  children: any,
  params: {
    organizationId: string,
    integrationId: string,
    projectId: string,
  },
  routes: Array<{ id?: string }>,
|};

type StateProps = {|
  currentUser: ?User,
  customEmojisEnabled: boolean,
  isLoading: boolean,
  organization: ?Organization,
  organizationId: string,
  policy: Policy,
  notPermitted: ?boolean,
  notFound: boolean,
  settings: ?OrganizationSettings,
|};

type DispatchProps = {|
  onLoad: () => void,
|};

type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

class OrganizationSettingsShell extends React.Component<Props> {
  componentDidMount() {
    this.redirectIfNecessary();
  }

  redirectIfNecessary() {
    const { organization } = this.props;

    if (organization && organization.isUsernameOrganization) {
      replace(accountProfile());
    }
  }

  renderNavigationLinks() {
    const { organization, organizationId, policy, customEmojisEnabled } =
      this.props;

    const navigationLinks = [
      <PageTitleNavigationLink
        onlyActiveOnIndex
        to={organizationDetails(organizationId)}
        icon="organization"
        qaSelector="organizationSettingsDetails"
      >
        Details
      </PageTitleNavigationLink>,
      <PageTitleNavigationLink
        to={organizationPermissions(organizationId)}
        icon="lock-locked-alt"
        qaSelector="organizationSettingsPermissions"
      >
        Permissions
      </PageTitleNavigationLink>,
      <PageTitleNavigationLink
        to={organizationSharing(organizationId)}
        icon="share"
        qaSelector="organizationSettingsSharing"
      >
        Sharing
      </PageTitleNavigationLink>,
    ];

    if (policy.manageIntegrations) {
      navigationLinks.push(
        <PageTitleNavigationLink
          to={organizationIntegrations(organizationId)}
          icon="integrations"
          qaSelector="organizationSettingsIntegrations"
        >
          Integrations
        </PageTitleNavigationLink>
      );
    }

    if (organization && policy.manageBilling && organization.versionsEnabled) {
      navigationLinks.push(
        <PageTitleNavigationLink
          to={organizationSubscription(organizationId)}
          icon="dollar"
          qaSelector="organizationSettingsBilling"
        >
          Billing
        </PageTitleNavigationLink>
      );
    }

    if (customEmojisEnabled) {
      navigationLinks.push(
        <PageTitleNavigationLink
          to={organizationEmojisAdmin(organizationId)}
          icon="emoji-category-smileys-and-people"
          qaSelector="organizationSettingsEmojis"
        >
          Emojis
        </PageTitleNavigationLink>
      );
    }

    return navigationLinks;
  }

  render() {
    const { notPermitted, isLoading, children } = this.props;

    return (
      <MainContent>
        {notPermitted ? (
          <NotPermitted flex />
        ) : (
          <React.Fragment>
            <PageTitle
              navigation={this.renderNavigationLinks()}
              title="Organization Settings"
            />
            <Loaded
              flex
              loading={isLoading}
              title="Loading organization settings…"
              qaSelector="loading-spinner"
            >
              {() => children}
            </Loaded>
          </React.Fragment>
        )}
      </MainContent>
    );
  }
}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const organizationId = props.params.organizationId;
  const organization = getOrganization(state, { organizationId });
  const policy = getOrganizationPolicy(state, { organizationId });
  const currentUser = getCurrentUser(state);
  const settingsRequest = OrganizationSettingsRequest.getRequest(state, {
    organizationId,
  });

  const policyLoaded = !!Object.keys(policy).length;
  const cannotManageSettings = policyLoaded && !policy.manageSettings;

  const notPermitted = forbidden(settingsRequest) || cannotManageSettings;

  return {
    organization,
    organizationId,
    policy,
    currentUser,
    customEmojisEnabled: canUseCustomEmojis(state, organizationId),
    isLoading: isLoading(settingsRequest) || !policyLoaded || !organization,
    notFound: notFound(settingsRequest),
    notPermitted,
    settings: getOrganizationSettings(state, { organizationId }),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    onLoad() {
      dispatch(
        OrganizationSettingsRequest.perform({
          params: { organizationId: props.params.organizationId },
        })
      );
    },
  };
}

export default createConnector<Props, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withData((props: Props) => ({
    organizationId: props.params.organizationId,
  }))
)(OrganizationSettingsShell);
