// @flow
import * as React from "react";
import { V3Link as Link } from "core/lib/router";
import { branchPath } from "core/lib/routes";
import ActivityBase from "./Base";
import type {
  ActivityBranchArchived as TActivityBranchArchived,
  Props,
} from "./types";

export default function ActivityBranchArchived(
  props: Props<TActivityBranchArchived>
) {
  const { activity, context } = props;

  return (
    <ActivityBase
      {...props}
      icon="branch"
      action="archived the branch"
      subject={
        context.branchId ? null : (
          <Link to={branchPath(activity.projectId, activity.branchId)}>
            {activity.payload.branchName}
          </Link>
        )
      }
    />
  );
}
