// @flow
import * as React from "react";
import ProjectSectionDialog from "core/components/ProjectSectionDialog";
import type { Project } from "core/types";
import DeleteConfirmationDialog from "./DeleteConfirmationDialog";
import ProjectMenu from ".";

type Props = {
  project: Project,
  canMoveProject?: boolean,
};

type State = {
  showConfirmDelete: boolean,
  showCreateSection: boolean,
};

export default class ProjectMenuWrapper extends React.Component<Props, State> {
  state = { showConfirmDelete: false, showCreateSection: false };

  render() {
    return (
      <React.Fragment>
        <ProjectMenu
          {...this.props}
          onClickDelete={() => this.setState({ showConfirmDelete: true })}
          onClickCreateSection={() =>
            this.setState({ showCreateSection: true })
          }
        />
        <DeleteConfirmationDialog
          isOpen={this.state.showConfirmDelete}
          project={this.props.project}
          onClose={() => this.setState({ showConfirmDelete: false })}
        />
        {this.props.canMoveProject !== false && (
          <ProjectSectionDialog
            isOpen={this.state.showCreateSection}
            onClose={() => this.setState({ showCreateSection: false })}
            projectId={this.props.project.id}
            organizationId={this.props.project.organizationId}
          />
        )}
      </React.Fragment>
    );
  }
}
