// @flow
import mapLayerChildren from "core/lib/mapLayerChildren";
import type { LayerData, LayerContext } from "core/types";

export default function flatMapLayerChildren(
  layers: { [layerId: string]: LayerData },
  layerData: LayerData
): Array<[LayerData, LayerContext]> {
  const flatLayers = [];

  const step =
    (layerContext: LayerContext) =>
    (childLayerData: LayerData): void => {
      const next = step({
        isVisible:
          layerContext.isVisible === false
            ? false // propagate false
            : childLayerData.properties.isVisible,
        isLocked:
          layerContext.isLocked === true
            ? true // propagate true
            : childLayerData.properties.isLocked,
      });

      // Continue flattening depth-first
      mapLayerChildren(layers, childLayerData, next);

      flatLayers.push([childLayerData, layerContext]);
    };

  const start = step({
    isVisible: layerData.properties.isVisible,
    isLocked: layerData.properties.isLocked,
  });

  start(layerData);

  return flatLayers.reverse();
}
