// @flow
import { replace } from "core/lib/location";
import { deleteIntegration } from "web/api";
import { organizationIntegrations } from "web/routeHelpers";
import type { Action, ThunkAction } from ".";

function integrationRemoved(organizationId: string, kind: string): Action {
  return {
    type: "ORGANIZATION_SETTINGS_INTEGRATION_REMOVED",
    meta: { organizationId, kind },
  };
}

export function removeIntegration(
  organizationId: string,
  integrationId: string,
  kind: string
): ThunkAction {
  return async (dispatch, getState) => {
    await deleteIntegration(integrationId);
    dispatch(integrationRemoved(organizationId, kind));
    replace(organizationIntegrations(organizationId));
  };
}
