// @flow
import * as React from "react";
import DialogForm from "core/components/DialogForm";

type Props = {
  isOpen: boolean,
  onClose: () => void,
  onSubmit?: () => mixed,
  isSubmitting: boolean,
  hasError: boolean,
};

type State = {
  isOpen: boolean,
};

export default class RevokeConfirmDialog extends React.Component<Props, State> {
  static defaultProps = {
    isSubmitting: false,
    hasError: false,
  };

  state = {
    isOpen: false,
  };

  render() {
    return (
      <DialogForm
        dangerous
        isOpen={this.props.isOpen}
        onClose={!this.props.hasError ? this.props.onClose : undefined}
        title="Revoke public access"
        onSubmit={this.props.onSubmit}
        disabled={this.props.isSubmitting}
        primaryButton="Revoke public access"
      >
        {() => (
          <div>
            Revoking public access to these items will restrict anyone outside
            of the project from viewing them.
          </div>
        )}
      </DialogForm>
    );
  }
}
