// @flow
import * as React from "react";
import image from "./images/alerts.svg";
import Empty from ".";

export default function NoNotifications(props: {}) {
  return (
    <Empty
      {...props}
      image={image}
      title="No notifications"
      description="You have yet to receive any notifications."
    />
  );
}
