// @flow
import classnames from "classnames";
import * as React from "react";
import BranchActivitiesList from "core/components/BranchActivitiesList";
import BranchCollectionsSummary from "core/components/BranchCollectionsSummary";
import BranchDescription from "core/components/BranchDescription";
import BranchOverviewCommentThread from "core/components/BranchOverview/BranchOverviewCommentThread";
import FirstBranchWelcome from "core/components/BranchOverview/FirstBranchWelcome";
import BranchOverviewSidebar from "core/components/BranchOverviewSidebar";
import BranchReviewSummary from "core/components/BranchReviewSummary";
import Breakpoint from "core/components/Breakpoint";
import Offline from "core/components/Empty/Offline";
import Scrollable from "core/components/Scrollable";
import { Abstract } from "core/lib/abstract";
import type {
  BranchReview,
  User,
  Project,
  Branch,
  ReactRouterLocation,
} from "core/types";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  location: ReactRouterLocation,
  params: Abstract.BranchDescriptor,
  isMobile?: boolean,
  isTablet?: boolean,
|};

export type StateProps = {|
  project: ?Project,
  branch: ?Branch,
  users: User[],
  head: string,
  isOnline: boolean,
  branchReview: ?BranchReview,
  canShowCollections: boolean,
  canShowReviews: boolean,
  isFirstBranchCreatedByUser: boolean,
  isNewAndMine: boolean,
  isMaster: boolean,
  isBranchCommentThreadsEnabled: boolean,
  isBranchAvailableOffline: boolean,
|};

export type DispatchProps = {|
  onLoad: (params: OwnProps) => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

function BranchOverview({
  location,
  params,
  project,
  branch,
  isOnline,
  users,
  isMobile,
  isTablet,
  branchReview,
  canShowCollections,
  canShowReviews,
  isFirstBranchCreatedByUser,
  isNewAndMine,
  isMaster,
  isBranchCommentThreadsEnabled,
  isBranchAvailableOffline,
}: Props) {
  if (!branch || !project) {
    return null; // This should never happen as the container "Branch" loads branch and project
  }

  const { userId } = branch;

  if (!isOnline && !isBranchAvailableOffline) {
    return (
      <Offline
        title="Branch unavailable offline"
        description="Abstract only stores branches you’ve previously worked on for offline use. To view this branch, you need to reconnect to the internet."
      />
    );
  }

  return (
    <React.Fragment>
      <Scrollable className={style.container} qaSelector="scrollable-container">
        <Breakpoint at={1024}>
          {({ above }) => (
            <React.Fragment>
              {location.query.commentId && isBranchCommentThreadsEnabled ? (
                <BranchOverviewCommentThread
                  params={params}
                  shouldExtendFullWidth={above}
                />
              ) : (
                <React.Fragment>
                  <div
                    className={classnames(style.branchDescription, {
                      [style.content]: !isMaster && above,
                    })}
                  >
                    {isFirstBranchCreatedByUser && isNewAndMine && (
                      <FirstBranchWelcome userId={userId} />
                    )}
                    <BranchDescription
                      branch={branch}
                      projectId={params.projectId}
                      isMobile={isMobile}
                    />
                    {canShowCollections && (
                      <BranchCollectionsSummary
                        projectId={params.projectId}
                        branch={branch}
                        mobile={isMobile}
                      />
                    )}
                  </div>
                  <div className={classnames({ [style.content]: above })}>
                    {canShowReviews && branchReview && isTablet && (
                      <BranchReviewSummary
                        branchReview={branchReview}
                        projectId={params.projectId}
                        isMobile={isMobile}
                        isTablet
                        branch={branch}
                      />
                    )}
                    <BranchActivitiesList
                      project={project}
                      branch={branch}
                      users={users}
                      isOnline={isOnline}
                    />
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </Breakpoint>
      </Scrollable>
      <BranchOverviewSidebar
        projectId={params.projectId}
        branchId={params.branchId}
        branch={branch}
        canShowReviews={canShowReviews}
        showGetStarted={isNewAndMine}
      />
    </React.Fragment>
  );
}

export default connector(BranchOverview);
