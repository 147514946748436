// @flow
import { normalize, schema } from "normalizr";

export const emailSchema = new schema.Entity("emails");

const emailsResponseSchema = new schema.Object({
  data: [emailSchema],
});

const emailResponseSchema = new schema.Object({
  data: emailSchema,
});

export function normalizeEmailsResponse(response: Object) {
  return normalize(response, emailsResponseSchema);
}

export function normalizeEmailResponse(response: Object) {
  return normalize(response, emailResponseSchema);
}
