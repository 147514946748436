// @flow
import * as React from "react";
import Button from "core/components/Button";
import ButtonLink from "core/components/ButtonLink";
import DialogForm from "core/components/DialogForm";
import Input from "core/components/Input";
import Loading from "core/components/Loading";
import OrganizationListItem from "core/components/OrganizationListItem";
import { TRANSFER_PROJECT } from "core/constants";
import type {
  Project,
  Organization,
  Policy,
  ValidationErrors,
} from "core/types";
import TransferNote from "./Note";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  project: Project,
  policy: Policy,
  disabled: boolean,
|};

export type StateProps = {|
  hasError: boolean,
  loading: boolean,
  organizations: Organization[],
  processing: boolean,
  validationErrors: ValidationErrors,
|};

export type DispatchProps = {|
  onDialogOpen: () => void,
  onSubmit: (organizationId: string) => void,
  openSupportTicket: (organizationId: string) => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type State = {|
  open: boolean,
  selectedOrganization?: Organization,
  confirmed: boolean,
|};

class ProjectTransfer extends React.Component<Props, State> {
  state = {
    open: false,
    confirmed: false,
    selectedOrganization: undefined,
  };

  showDialog = () => {
    this.props.onDialogOpen();
    this.setState({ open: true });
  };

  closeDialog = () => {
    this.setState({ open: false, selectedOrganization: undefined });
  };

  handleOrganizationSelect = (event: SyntheticEvent<>, org: Organization) => {
    event.preventDefault();
    this.setState({ selectedOrganization: org });
  };

  handleConfirm = ({ target }: SyntheticInputEvent<>) => {
    this.setState({
      confirmed: target.value === TRANSFER_PROJECT,
    });
  };

  handleSubmit = (event: SyntheticEvent<>) => {
    event.preventDefault();
    event.stopPropagation();

    const { id: selectedOrganizationId } =
      this.state.selectedOrganization || {};

    this.props.onSubmit(selectedOrganizationId);
  };

  renderSelect() {
    return (
      <React.Fragment>
        <p>
          Select an organization you want to transfer the{" "}
          <strong>{this.props.project.name}</strong> to:
        </p>
        <ul className={style.list}>
          {this.props.organizations.map((organization) => (
            <OrganizationListItem
              key={organization.id}
              index={0}
              organization={organization}
              onClick={(event) =>
                this.handleOrganizationSelect(event, organization)
              }
            />
          ))}
        </ul>
        <TransferNote />
      </React.Fragment>
    );
  }

  renderError() {
    const { message } = this.props.validationErrors;
    const { selectedOrganization } = this.state;
    const error = message
      ? `${message[0]}. If you have any questions, please `
      : "Oh no, we ran into some unexpected trouble. Please ";

    return (
      <div className={style.error}>
        {error}
        <ButtonLink
          onClick={() =>
            this.props.openSupportTicket(
              selectedOrganization ? selectedOrganization.id : ""
            )
          }
        >
          contact us
        </ButtonLink>{" "}
        - we’re here to help.
      </div>
    );
  }

  renderConfirm() {
    const { name } = this.state.selectedOrganization || {};

    return (
      <div>
        <div>
          Are you absolutely sure you want to transfer{" "}
          <strong>{this.props.project.name}</strong> to the{" "}
          <strong>{name}</strong> organization?
        </div>
        <Input
          label={`Please type "TRANSFER PROJECT"`}
          onChange={this.handleConfirm}
          autoComplete="off"
          autoFocus
        />
        {this.props.hasError && this.renderError()}
      </div>
    );
  }

  render() {
    const { disabled, loading, processing, policy } = this.props;
    const showSelect = !this.state.selectedOrganization && !loading;
    const showConfirm = !!this.state.selectedOrganization;

    return (
      <span className={style.content}>
        <Button
          primary
          onClick={this.showDialog}
          disabled={!policy.transfer || disabled}
          tooltip={true}
          title={
            policy.transfer
              ? ""
              : "You need to be an admin of this organization to transfer projects"
          }
        >
          Transfer Project
        </Button>

        <DialogForm
          isOpen={this.state.open}
          onClose={this.closeDialog}
          onRequestClose={this.closeDialog}
          title="Transfer Project"
          onSubmit={showConfirm ? this.handleSubmit : undefined}
          disabled={!this.state.confirmed || processing}
          loading={processing}
          primaryButton={showConfirm ? "Transfer Project" : ""}
        >
          {loading && <Loading title="Checking your organizations…" />}
          {showSelect && this.renderSelect()}
          {showConfirm && this.renderConfirm()}
        </DialogForm>
      </span>
    );
  }
}

export default connector(ProjectTransfer);
