// @flow
import * as React from "react";
import Button from "core/components/Button";
import ExternalLink from "core/components/ExternalLink";
import OnboardingNote from "web/components/OnboardingNote";
import OnboardingPage from "web/components/OnboardingPage";
import { supportTicketPath } from "web/routeHelpers";
import connector from "./connector";

export type OwnProps = {|
  signupFlow?: boolean,
  token: string,
|};

export type StateProps = {|
  error: boolean,
  loading: boolean,
  success: boolean,
  tokenIsExpired: boolean,
|};

export type DispatchProps = {|
  resendEmailVerification: () => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

class EmailVerificationError extends React.Component<Props> {
  getTokenExpiredContent = () => {
    if (this.props.success) {
      return (
        <OnboardingNote>
          Confirmation email sent. Check your inbox and click on the new
          verification link.
        </OnboardingNote>
      );
    }

    if (this.props.error) {
      return (
        <OnboardingNote>
          Something went wrong when trying to resend your confirmation email.
          Please{" "}
          <ExternalLink href={supportTicketPath()}>
            contact support
          </ExternalLink>
          .
        </OnboardingNote>
      );
    }

    return (
      <React.Fragment>
        <OnboardingNote>
          Sorry, this verification link has now expired.
        </OnboardingNote>
        <span>
          <Button
            disabled={this.props.loading}
            icon={this.props.loading ? "spinner" : undefined}
            onClick={this.props.resendEmailVerification}
            large
            primary
          >
            {this.props.loading
              ? "Resending Confirmation Email…"
              : "Resend Confirmation Email"}
          </Button>
        </span>
      </React.Fragment>
    );
  };

  render() {
    const { signupFlow, tokenIsExpired } = this.props;

    return (
      <OnboardingPage
        heading="Email confirmation error"
        documentTitle="Error"
        currentStep={signupFlow ? 3 : undefined}
        totalSteps={signupFlow ? 4 : undefined}
        dottedLines={signupFlow ? 3 : undefined}
        showSplatter
      >
        {tokenIsExpired ? (
          this.getTokenExpiredContent()
        ) : (
          <OnboardingNote>
            No email could be verified with this link. Please{" "}
            <ExternalLink href={supportTicketPath()}>
              contact support
            </ExternalLink>{" "}
            if you believe this is an error.
          </OnboardingNote>
        )}
      </OnboardingPage>
    );
  }
}

export default connector(EmailVerificationError);
