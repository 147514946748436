// @flow
import * as React from "react";
import useBranchDisplayName from "core/hooks/useBranchDisplayName";
import * as Commit from "core/models/commit";
import { ReviewStatuses } from "core/models/review";
import type { Notification } from "core/types";
import style from "./style.scss";
type Props = {|
  currentUserId: string,
  notification: Notification,
|};

function NotificationMessageWrapper(props: { children: React.Node }) {
  return <div className={style.notificationMessage}>{props.children}</div>;
}

export default function NotificationMessage(props: Props) {
  const { currentUserId, notification } = props;
  const { commitBranchId, commitBranchName } = notification.payload;

  let { branchName } = notification.payload;
  if (!branchName) {
    branchName = commitBranchName;
  }

  const { branchName: branchDisplayName } = useBranchDisplayName(
    commitBranchId,
    branchName
  );

  switch (notification.messageType) {
    case "COLLECTION_MENTIONED": {
      return (
        <NotificationMessageWrapper>
          <b>{notification.initiatingUser.name}</b> mentioned you on the
          collection <b>{notification.payload.name}</b>
        </NotificationMessageWrapper>
      );
    }
    case "COMMENT_CREATED": {
      const isReply = !!notification.payload.commentParentId;
      const userName = notification.initiatingUser.name;
      const subject = getSubject(notification);
      const subjectName = getSubjectName(notification, branchDisplayName);
      const action = isReply ? "replied to a comment" : "commented";

      return (
        <NotificationMessageWrapper>
          <b>{userName}</b> {action} on the {subject} <b>{subjectName}</b>
        </NotificationMessageWrapper>
      );
    }
    case "BRANCH_MENTIONED":
    case "COMMENT_MENTIONED": {
      const userName = notification.initiatingUser.name;
      const subject = getSubject(notification);
      const subjectName = getSubjectName(notification, branchDisplayName);

      return (
        <NotificationMessageWrapper>
          <b>{userName}</b> mentioned you on the {subject} <b>{subjectName}</b>
        </NotificationMessageWrapper>
      );
    }
    case "COMMIT_MENTIONED": {
      const userName = notification.initiatingUser.name;
      const subjectName = getSubjectName(notification, branchDisplayName);

      return (
        <NotificationMessageWrapper>
          <b>{userName}</b> mentioned you in a commit <b>{subjectName}</b>
        </NotificationMessageWrapper>
      );
    }
    case "PROJECT_ARCHIVED": {
      return (
        <NotificationMessageWrapper>
          The project <b>{notification.project.name}</b> was archived
        </NotificationMessageWrapper>
      );
    }
    case "PROJECT_CREATED": {
      const userName = notification.initiatingUser.name;
      const projectName = notification.project.name;
      return (
        <NotificationMessageWrapper>
          <b>{userName}</b> created the project <b>{projectName}</b>
        </NotificationMessageWrapper>
      );
    }
    case "PROJECT_DELETED": {
      const userName = notification.initiatingUser.name;
      const projectName = notification.project.name;
      return (
        <NotificationMessageWrapper>
          <b>{userName}</b> deleted the project <b>{projectName}</b>
        </NotificationMessageWrapper>
      );
    }
    case "PROJECT_MEMBER_ADDED": {
      const subjectId = notification.payload.subjectUserId;
      const subjectName = notification.payload.subjectUserName;
      const projectName = notification.project.name;
      const userName = notification.initiatingUser
        ? notification.initiatingUser.name
        : undefined;

      return (
        <NotificationMessageWrapper>
          {subjectId === currentUserId ? (
            <React.Fragment>
              <b>You</b> were
            </React.Fragment>
          ) : (
            <React.Fragment>
              <b>{subjectName} was</b>
            </React.Fragment>
          )}{" "}
          added to the project <b>{projectName}</b>
          {userName ? ` by ${userName}` : ""}
        </NotificationMessageWrapper>
      );
    }
    case "PROJECT_MEMBER_REMOVED": {
      const subjectId = notification.payload.subjectUserId;
      const subjectName = notification.payload.subjectUserName;
      const userName = notification.initiatingUser.name;
      const projectName = notification.project.name;

      return (
        <NotificationMessageWrapper>
          {subjectId === currentUserId ? (
            <React.Fragment>
              <b>You</b> were
            </React.Fragment>
          ) : (
            <React.Fragment>
              <b>{subjectName}</b> was
            </React.Fragment>
          )}{" "}
          removed from the project <b>{projectName}</b> by {userName}
        </NotificationMessageWrapper>
      );
    }
    case "PROJECT_UNARCHIVED": {
      const projectName = notification.project.name;

      return (
        <NotificationMessageWrapper>
          The project <b>{projectName}</b> was unarchived
        </NotificationMessageWrapper>
      );
    }
    case "PROJECT_VISIBILITY_CHANGED": {
      const projectName = notification.project.name;
      const visibility =
        notification.payload.visibility === "specific" ? "private" : "visible";

      return (
        <NotificationMessageWrapper>
          The project <b>{projectName}</b> was made {visibility}
        </NotificationMessageWrapper>
      );
    }
    case "REVIEWER_REMOVED": {
      const actorName = notification.initiatingUser.name;
      const actorId = notification.initiatingUser.id;
      const { reviewerId, reviewerName, branchName } = notification.payload;

      const whoWas =
        reviewerId === currentUserId
          ? "you"
          : reviewerId === actorId
          ? "themselves"
          : `**${reviewerName}**`;

      return (
        <NotificationMessageWrapper>
          <b>{actorName}</b> removed {whoWas} as a reviewer from the branch{" "}
          <b>{branchName}</b>
        </NotificationMessageWrapper>
      );
    }
    case "REVIEW_COMPLETED": {
      const { status, branchName } = notification.payload;
      const reviewerName = notification.initiatingUser.name;

      let statusMessage;
      if (status === ReviewStatuses.APPROVED) {
        statusMessage = "has approved";
      } else if (status === ReviewStatuses.REJECTED) {
        statusMessage = "requested changes on";
      } else {
        // TODO: Log unsupported notification payload status
        return null;
      }

      return (
        <NotificationMessageWrapper>
          <b>{reviewerName}</b> {statusMessage} the branch <b>{branchName}</b>
        </NotificationMessageWrapper>
      );
    }
    case "REVIEW_DISMISSED": {
      const actorId = notification.initiatingUser.id;
      const actorName = notification.initiatingUser.name;
      const { reviewerId, reviewerName, branchName } = notification.payload;

      return (
        <NotificationMessageWrapper>
          <b>{actorName}</b> dismissed{" "}
          {reviewerId === currentUserId ? (
            "your"
          ) : reviewerId === actorId ? (
            "their"
          ) : (
            <React.Fragment>
              <b>{reviewerName}</b>’s
            </React.Fragment>
          )}{" "}
          review on the branch <b>{branchName}</b>
        </NotificationMessageWrapper>
      );
    }
    case "REVIEW_REQUESTED": {
      const actorId = notification.initiatingUser.id;
      const actorName = notification.initiatingUser.name;
      const { reviewerId, reviewerName, branchName } = notification.payload;

      return (
        <NotificationMessageWrapper>
          <b>{actorName}</b> added{" "}
          {reviewerId === currentUserId ? (
            "you"
          ) : reviewerId === actorId ? (
            "themselves"
          ) : (
            <React.Fragment>
              <b>{reviewerName}</b>
            </React.Fragment>
          )}{" "}
          as a reviewer on the branch <b>{branchName}</b>
        </NotificationMessageWrapper>
      );
    }
    default: {
      // TODO: Log unsupported notification type.
      return null;
    }
  }
}

function getSubject(notification) {
  const payload = notification.payload;

  if (payload.commentLayerName) {
    return "artboard";
  }
  if (payload.commentPageName) {
    return "page";
  }
  if (payload.commentFileName) {
    return "file";
  }
  if (payload.commitMessage) {
    return "commit";
  }
  return "branch";
}

function getSubjectName(notification, branchDisplayName) {
  const payload = notification.payload;

  if (payload.commentLayerName) {
    return payload.commentLayerName;
  }
  if (payload.commentPageName) {
    return payload.commentPageName;
  }
  if (payload.commentFileName) {
    return payload.commentFileName;
  }
  if (payload.commitMessage) {
    // break commit message into title and description
    return Commit.parseMessage(payload.commitMessage).title;
  }
  return branchDisplayName;
}
