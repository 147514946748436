// @flow
import * as React from "react";
import { connect } from "react-redux";
import history from "abstract-di/history";
import window from "core/global/window";
import { Profiler, start, type Options } from "core/lib/profiler";
import { canUseUIProfiler } from "core/selectors/features";
import type { AnyDescriptor, State, Dispatch } from "core/types";

type OwnProps = {|
  id: string,
  params: AnyDescriptor,
  children?: React.Node,
  unstable_profileOnUnmount?: true,
  ...Options,
|};

type StateProps = {|
  enabled: boolean,
|};

type Props = {
  ...OwnProps,
  ...StateProps,
};

type Op = "pageload" | "navigation";

// Store initial history operation name in module
let historyOp: Op = "pageload";

// Mark route for initial mount
window.performance.mark("RouteChange");

history.listen(() => {
  if (window.performance) {
    historyOp = "navigation"; // Set history op for internal navigation
    window.performance.mark("RouteChange"); // Remark route anytime the route changes
  }
});

class MountProfiler extends React.Component<Props> {
  metric: ?Profiler;

  constructor(props: Props) {
    super();

    const {
      id,
      params,
      enabled,
      unstable_profileOnUnmount,
      children,
      ...profilerOptions
    } = props;

    this.metric = enabled
      ? start(params, id, params, {
          ...profilerOptions,
          // default to discarding results when backgrounded
          discardIfBackgrounded: props.discardIfBackgrounded !== false,
        })
      : null;
  }

  ready() {
    const name = `TTV-${this.props.id}`;
    const asyncMetric = this.metric;

    // Measure on next frame to include time of in-flight render
    window.requestAnimationFrame(() => {
      if (asyncMetric) {
        if (window.performance) {
          window.performance.measure(name, "RouteChange");

          const routeEntries = window.performance.getEntriesByName(
            name,
            "measure"
          );

          const appEntries = window.performance.getEntriesByName(
            "AppReady",
            "measure"
          );

          asyncMetric.addMeta("historyOp", historyOp);

          if (routeEntries.length > 0) {
            asyncMetric.addMeta(
              "routeDuration",
              routeEntries[routeEntries.length - 1].duration
            );
          }

          if (appEntries.length > 0) {
            asyncMetric.addMeta(
              "appDuration",
              appEntries[appEntries.length - 1].duration
            );
          }
        }

        asyncMetric.stop();
      }
    });
  }

  componentDidMount() {
    if (!this.props.unstable_profileOnUnmount && this.props.enabled) {
      this.ready();
    }
  }

  componentWillUnmount() {
    if (this.props.unstable_profileOnUnmount && this.props.enabled) {
      this.ready();
    }
  }

  render() {
    return this.props.children || null;
  }
}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  return {
    enabled: canUseUIProfiler(state, props.params),
  };
}

export default connect<Props, OwnProps, StateProps, _, State, Dispatch>(
  mapStateToProps
)(MountProfiler);
