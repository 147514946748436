// @flow
import classnames from "classnames";
import * as React from "react";
import ChangeStatusIcon from "core/components/ChangeStatusIcon";
import Popover from "core/components/Popover";
import * as Change from "core/models/change";
import style from "./style.scss";

type Props = {
  status: string,
  count?: number,
  active?: boolean,
  fromLibrary?: boolean,
  className?: string,
};

export default function ChangeStatusBadge({
  status,
  count,
  active,
  fromLibrary,
  className,
}: Props) {
  const type = Change.status(status);
  const showCount = count || count === 0;
  const edited = type === "edited" && !fromLibrary;
  const updated = type === "updated" || (type === "edited" && fromLibrary);

  return (
    <div className={classnames(className, { [style.active]: active })}>
      {type === "added" && (
        <span className={classnames(style.badge, style.statusAdded)}>
          <ChangeStatusIcon change={type} className={style.symbol} />
          {showCount ? count : "Added"}
        </span>
      )}
      {type === "removed" && (
        <span className={classnames(style.badge, style.statusRemoved)}>
          <ChangeStatusIcon change={type} className={style.symbol} />
          {showCount ? count : "Removed"}
        </span>
      )}
      {edited && (
        <span className={classnames(style.badge, style.statusEdited)}>
          <ChangeStatusIcon change={type} className={style.symbol} />
          {showCount ? count : "Edited"}
        </span>
      )}
      {updated &&
        (showCount ? (
          <span className={style.badge}>
            <ChangeStatusIcon change="updated" className={style.symbol} />
            {count}
          </span>
        ) : (
          <Popover
            placement="bottom"
            label={
              fromLibrary
                ? "This linked symbol was updated to a new version"
                : "The appearance changed because a nested symbol or style was updated"
            }
          >
            <span className={style.badge}>
              <ChangeStatusIcon change="updated" className={style.symbol} />
              Updated
            </span>
          </Popover>
        ))}
    </div>
  );
}
