// @flow
import keyBy from "lodash/keyBy";
import * as File from "core/models/file";
import * as Layer from "core/models/layer";
import * as Page from "core/models/page";
import type {
  Action,
  File as TFile,
  Page as TPage,
  Layer as TLayer,
} from "core/types";

const EMPTY = {};

export function entitiesSelected({
  projectId,
  branchId,
  file,
  page,
  layers,
}: {
  projectId: string,
  branchId: string,
  file?: TFile,
  page?: TPage,
  layers?: TLayer[],
}): Action {
  return {
    type: "core/ENTITIES_SELECTED",
    payload: {
      projectId,
      branchId,
      files: file ? { [File.uniqueId(file)]: file } : EMPTY,
      pages: page ? { [Page.uniqueId(page)]: page } : EMPTY,
      layers: layers && layers.length ? keyBy(layers, Layer.uniqueId) : EMPTY,
    },
  };
}

export function entitiesDeselected(payload: {
  projectId: string,
  branchId: string,
  file?: TFile,
  page?: TPage,
  layers?: TLayer[],
}): Action {
  return {
    type: "core/ENTITIES_DESELECTED",
    payload,
  };
}

export function entitiesClearSelected(
  projectId: string,
  branchId: string
): Action {
  return {
    type: "core/ENTITIES_CLEAR_SELECTED",
    payload: { projectId, branchId },
  };
}
