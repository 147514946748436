// @flow
import * as React from "react";
import Flex from "core/components/Flex";
import Heading from "core/components/Heading";
import Popover from "core/components/Popover";
import style from "./style.scss";

type Props = {
  stat: number,
  label: string,
  tooltip: string,
  unit?: string,
  qaSelector?: string,
};

export default function MetricBoxMetric(props: Props) {
  return (
    <Flex column>
      <Popover
        trigger="hover"
        label={props.tooltip}
        placement="top"
        offset="0, 4px"
      >
        <div>
          <div className={style.stat}>{props.label}</div>
          <Heading level="1" size="xxxl" qaSelector={props.qaSelector}>
            {props.stat.toLocaleString()}
            {props.unit}
          </Heading>
        </div>
      </Popover>
    </Flex>
  );
}
