// @flow
import { getCurrentUserId } from "abstract-di/selectors";
import { setPaginationTotal } from "core/actions/paginationTotals";
import { CURRENTUSERTEAMS_LIMIT } from "core/lib/teams";
import { PaginatedOrganizationTeamsRequest } from "core/requests/teams";
import type { ThunkAction } from "core/types";

type loadTeamsOptions = { offset?: number, limit?: number, force?: boolean };

export function loadCurrentUserTeams(
  organizationId: string,
  options?: loadTeamsOptions
): ThunkAction {
  return (dispatch, getState) => {
    const userId = getCurrentUserId(getState());

    if (userId) {
      dispatch(
        PaginatedOrganizationTeamsRequest.perform({
          params: {
            organizationId,
            userId,
            limit: (options && options.limit) || CURRENTUSERTEAMS_LIMIT,
            offset: (options && options.offset) || 0,
          },
          force: (options && options.force) || false,
          onSuccess: (response) => {
            dispatch(
              setPaginationTotal(
                `${organizationId}-YourTeams`,
                response.meta.total
              )
            );
          },
        })
      );
    }
  };
}

// Sending a loadCurrentUserTeams request with a limit of 0 is the minimum
// API call to update the YourTeams pagination total.
export function resetCurrentUserTeamsPaginationTotal(
  organizationId: string
): ThunkAction {
  return (dispatch, getState) => {
    dispatch(
      loadCurrentUserTeams(organizationId, { offset: 0, limit: 0, force: true })
    );
  };
}
