// @flow
import invariant from "invariant";
import { connect } from "react-redux";
import { isOnline } from "abstract-di/selectors";
import { withData } from "core/components/DataLoader";
import createConnector from "core/lib/createConnector";
import {
  ProjectUpdateRequest,
  ProjectCreateRequest,
} from "core/requests/projects";
import {
  SectionsFetchRequest,
  SectionCreateRequest,
} from "core/requests/sections";
import { getOrganization } from "core/selectors/organizations";
import { getOrganizationPolicy } from "core/selectors/policies";
import { getProject } from "core/selectors/projects";
import { getFilteredSections } from "core/selectors/sections";
import type { State, Dispatch, Project } from "core/types";
import {
  type Props,
  type OwnProps,
  type StateProps,
  type DispatchProps,
  type CreateSectionParams,
} from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const projectId = props.projectId || "";
  const project = getProject(state, { projectId }) || undefined;
  const organizationId = project
    ? project.organizationId
    : props.organizationId || "";

  const policy = getOrganizationPolicy(state, { organizationId });
  const organization = getOrganization(state, { organizationId });
  const isUsernameOrganization = organization
    ? organization.isUsernameOrganization
    : false;

  return {
    project,
    organizationId,
    organization,
    sections: getFilteredSections(state, { organizationId }),
    isUsernameOrganization,
    isOffline: !isOnline(state),
    hasError:
      props.action === "create"
        ? ProjectCreateRequest.hasError(state, {})
        : ProjectUpdateRequest.hasError(state, { project: project || {} }),
    sectionsLoading:
      props.organizationId !== undefined &&
      SectionsFetchRequest.isLoading(state, {
        organizationId,
      }),
    canUseSections: policy.showSections,
    canCreateSections: policy.manageSections,
    canCreatePrivateProject: policy.createPrivateProject,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { organizationId } = props;
  return {
    onLoad() {
      if (organizationId) {
        dispatch(
          SectionsFetchRequest.perform({
            params: { organizationId },
            force: false,
          })
        );
      }
    },
    onUnmount: (project: ?Project) => {
      if (project) {
        dispatch(
          props.action === "create"
            ? ProjectCreateRequest.clear({})
            : ProjectUpdateRequest.clear({ project })
        );
      }
    },
    onSubmit({ sectionName, onErrorCallback, ...values }: CreateSectionParams) {
      invariant(
        organizationId,
        "Organization ID is required to create a project"
      );
      values = { ...values, organizationId };

      const handleSuccess = async (response) => {
        const projectId = response.data.id;

        if (values.sectionId === "new" && sectionName) {
          await dispatch(
            SectionCreateRequest.perform({
              params: {
                name: sectionName,
                projectId,
                organizationId,
              },
            })
          );
        }

        if (props.onClose) {
          props.onClose();
        }

        dispatch(
          props.action === "create"
            ? ProjectCreateRequest.clear({ values })
            : ProjectUpdateRequest.clear({ project: values })
        );
      };

      return dispatch(
        props.action === "create"
          ? ProjectCreateRequest.perform({
              params: values,
              onSuccess: handleSuccess,
              onError: onErrorCallback,
            })
          : ProjectUpdateRequest.perform({
              params: { project: values },
              onSuccess: handleSuccess,
              onError: onErrorCallback,
            })
      );
    },
  };
}

export default createConnector<Props, OwnProps>(
  connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch>(
    mapStateToProps,
    mapDispatchToProps
  ),
  withData((props) => ({
    organizationId: props.organizationId,
    projectId: props.projectId,
  }))
);
