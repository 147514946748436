// @flow
import empty from "empty";
import flat from "flat";
import idx from "idx";
import { merge } from "lodash";
import Immutable from "seamless-immutable";
import createLogger from "core/lib/logger";
import { pathHash } from "core/models/layerData";
import * as Abstract from "core/types"; // TODO: Update sdk to allow import { Abstract } from "core/lib/abstract";
import * as constants from "./constants";
import filterTrailingSiblings from "./filterTrailingSiblings";
import measureTextLayer from "./measureTextLayer";
import * as t from "./types";

const logger = createLogger("cloneSymbol");

type Options = {
  parentPath?: string[],
};

export default function cloneSymbol<
  Symbol: Abstract.LayerData | Abstract.MutableLayerData,
>(
  layers: t.Layers,
  symbol: Symbol,
  rootOverrides: Abstract.LayerOverrideData = constants.NO_OVERRIDES,
  options: Options = {}
): Abstract.MutableLayerData | Symbol {
  const overrides: Abstract.LayerOverrideData = merge(
    {},
    symbol.properties.overrides,
    rootOverrides[symbol.id] || constants.NO_OVERRIDES
  );

  const masterSymbolId = overrides.symbolId
    ? idx(layers[overrides.symbolId], (_) => _.symbolId)
    : symbol.symbolId;

  if (!masterSymbolId) {
    logger.error("Symbol id required to clone symbol");
    return symbol;
  }

  logger.log(
    `Following ${
      overrides.symbolId
        ? // prettier-ignore
          `overriden symbol id "${masterSymbolId}" (original: "${symbol.symbolId || "undefined"}")`
        : `symbol id "${symbol.symbolId || "undefined"}"`
    }`
  );

  const master = layers[masterSymbolId];

  if (!master) {
    logger.error(
      `Cannot load symbol master "${masterSymbolId}" for "${symbol.properties.name}"`
    );

    return symbol;
  }

  const clone = {
    ...master, // Clone symbol from master while preserving symbol properties
    _key: symbol._key,
    _path: symbol._path,
    _root: symbol._root,
    symbolId: masterSymbolId,
    id: symbol.id,
    parentId: symbol.parentId,
    type: symbol.type,
    properties: {
      ...master.properties,
      name: symbol.properties.name,
      isVisible: symbol.properties.isVisible,
      isLocked: symbol.properties.isLocked,
      underClippingMask: symbol.properties.underClippingMask,
      x: symbol.properties.x,
      y: symbol.properties.y,
      width: symbol.properties.width,
      height: symbol.properties.height,
      opacity: symbol.properties.opacity,
      blendMode: symbol.properties.blendMode,
      shadows: symbol.properties.shadows,
      fills: symbol.properties.fills,
      resizingConstraint: symbol.properties.resizingConstraint,
      assets: symbol.properties.assets,
      link: symbol.properties.link,
      overrides,
    },
  };

  const prevInferred = idx(clone, (_) => _._root._inferred); // No need to re-infer values for nested symbols

  clone._inferred =
    prevInferred || inferOverrides(layers, clone, overrides, options);

  return clone;
}

export function readInferred(
  layer: Abstract.LayerData
): Abstract.LayerInferredData {
  return idx(layer, (_) => _._root._inferred[layer._key]) || empty.object;
}

export function inferOverrides(
  layers: t.Layers,
  symbol: Abstract.LayerData,
  overrides: Abstract.LayerOverrideData,
  options: Options = {}
): Abstract.LayerInferredData {
  const inferred: Abstract.MutableLayerInferredData = {};
  const overridePaths: { [key: string]: mixed } = flat(overrides, {
    safe: true, // safe: true to support arrays in nested properties
  });

  Object.keys(overridePaths).forEach((key: string) => {
    const path = key.split("."); // [..., "id-2", "id-1", "properties", "textContent"]
    const overrideProperty = path[path.length - 1];
    const overrideValue = overridePaths[key];

    switch (overrideProperty) {
      case "symbolId": {
        if (overrideValue === constants.NO_SYMBOL) {
          const parentPath = options.parentPath || empty.array;
          const overridePath: $ReadOnlyArray<string> = [
            ...parentPath, // ancestor path required for nested overrides
            overrides.symbolId || symbol.id, // current symbol id or overriden symbolId in case of nested overrides
            ...path.slice(0, path.length - 1), // relative path to overriden layer, avoiding `symbolId` given [...nested-symbol-instance-id, current-symbol-id, current-layer-id, "symbolId"]
          ];

          const currentLayer = layers[overridePath[overridePath.length - 1]];
          const currentSymbol = layers[overridePath[overridePath.length - 2]];

          if (!currentLayer || !currentSymbol || !currentSymbol.symbolId) {
            return logger.error(
              `Could not load overridden layer for "${key}"`,
              overridePath
            );
          }

          const currentSymbolMaster = layers[currentSymbol.symbolId];
          let delta = 0;

          if (currentSymbolMaster && currentSymbolMaster.properties.layout) {
            const trailingSiblings = filterTrailingSiblings(
              layers,
              currentLayer
            );

            const trailingSpace = trailingSiblings[0]
              ? trailingSiblings[0].properties.x -
                (currentLayer.properties.x + currentLayer.properties.width)
              : 0;

            delta = currentLayer.properties.width + trailingSpace; // Remove layer and trailing space
          }

          updateInferred(
            inferred,
            layers,
            overridePath.slice(0, overridePath.length - 1), // drop current layer id
            currentLayer,
            delta,
            { isVisible: false }
          );

          logger.log(
            `Inferred layout for empty symbol override on "${currentLayer.id}"`
          );
        }
        break;
      }
      case "textContent": {
        const parentPath = options.parentPath || empty.array;
        const overridePath = [
          ...parentPath, // ancestors required for nested overrides
          overrides.symbolId || symbol.id, // current symbol id or overriden symbolId in case of nested overrides
          ...path.slice(0, path.length - 2), // relative path to overriden layer, avoiding `properties` and `textContent` given [...nested-symbol-id, text-layer-id, "properties", "textContent"]
        ];

        const currentLayer = layers[overridePath[overridePath.length - 1]];
        const currentSymbol = layers[overridePath[overridePath.length - 2]];

        if (!currentLayer || !currentSymbol || !currentSymbol.symbolId) {
          return logger.error(
            `Could not load overridden layer for "${key}"`,
            overridePath
          );
        }

        const currentSymbolMaster = layers[currentSymbol.symbolId];

        if (
          typeof overrideValue === "string" &&
          overrideValue !== currentLayer.properties.textContent &&
          !idx(currentLayer.properties.text, (_) => _.fixed) // TODO: Exisiting bug, fixed can be overidden via text style?
        ) {
          const { width, x } = measureTextLayer(currentLayer, overrideValue);
          const delta =
            currentSymbolMaster && currentSymbolMaster.properties.layout
              ? currentLayer.properties.width - width
              : 0;

          updateInferred(
            inferred,
            layers,
            overridePath.slice(0, overridePath.length - 1), // drop current layer id
            currentLayer,
            delta,
            { width, x }
          );

          logger.log(
            `Inferred layout for text override on "${currentLayer.id}"`
          );
        }
        break;
      }
      default: {
        // No effect
        break;
      }
    }
  });

  return Immutable.from(inferred);
}

function updateInferred(
  inferred: Abstract.MutableLayerInferredData,
  layers: t.Layers,
  symbolPath: $ReadOnlyArray<string>,
  layer: Abstract.LayerData,
  delta: number,
  properties: {|
    width?: number,
    x?: number,
    isVisible?: boolean,
  |}
) {
  const parentPathHash = pathHash(symbolPath);

  inferred[`${parentPathHash}-${layer.id}`] = { properties };

  updateInferredAncestors(inferred, layers, symbolPath, layer, delta);
}

function updateInferredSiblings(
  inferred: Abstract.MutableLayerInferredData,
  layers: t.Layers,
  symbolPath: $ReadOnlyArray<string>,
  layer: Abstract.LayerData,
  delta: number
) {
  const parentPathHash = pathHash(symbolPath);
  const trailingSiblings = filterTrailingSiblings(layers, layer);

  trailingSiblings.forEach((sibling, index) => {
    const key = `${parentPathHash}-${sibling.id}`;
    const prevInferredProperties =
      idx(inferred[key], (_) => _.properties) || empty.object;

    inferred[key] = {
      properties: {
        ...prevInferredProperties,
        x: (prevInferredProperties.x || sibling.properties.x) - delta,
      },
    };
  });
}

function updateInferredAncestors(
  inferred: Abstract.MutableLayerInferredData,
  layers: t.Layers,
  symbolPath: $ReadOnlyArray<string>,
  layer: Abstract.LayerData,
  delta: number
) {
  const remainingSymbolPath = [...symbolPath];

  let currentLayer = layer;
  let parentPathHash = pathHash(remainingSymbolPath);
  let visitedSiblings = false;

  while (currentLayer.parentId) {
    const parentLayer = layers[currentLayer.parentId];

    if (!parentLayer) {
      logger.error("Parent layer required to infer overrides");
      break;
    }

    if (parentLayer) {
      currentLayer = parentLayer;
    }

    switch (currentLayer.type) {
      case "symbolMaster": {
        // Follow remaining override path to source of override
        const symbolId: ?string = remainingSymbolPath.pop();

        if (!symbolId) {
          break; // Stop once we reach the root symbol instance
        }

        const symbolInstance = layers[symbolId];
        parentPathHash = pathHash(remainingSymbolPath);

        if (!symbolInstance) {
          break;
        }

        currentLayer = symbolInstance;
      }
      // fallthrough to treat nested "symbolMaster" as a "symbolInstance"
      case "symbolInstance": {
        const symbolMaster = currentLayer.symbolId
          ? layers[currentLayer.symbolId]
          : undefined;

        if (!symbolMaster) {
          logger.error("Symbol master required to load layout");
          break;
        }

        const { layout } = symbolMaster.properties;

        if (layout) {
          const prevInferredProperties =
            idx(
              inferred[`${parentPathHash}-${currentLayer.id}`],
              (_) => _.properties
            ) || empty.object;

          const currentWidth =
            prevInferredProperties.width !== undefined
              ? prevInferredProperties.width
              : symbolMaster.properties.width;

          inferred[`${parentPathHash}-${currentLayer.id}`] = {
            properties: {
              ...prevInferredProperties,
              width: currentWidth - delta,
            },
          };

          if (!visitedSiblings) {
            updateInferredSiblings(inferred, layers, symbolPath, layer, delta);

            visitedSiblings = true;
          }
        }
        break;
      }
      case "group":
      case "shapeGroup": {
        const group = layers[currentLayer.id];

        if (group) {
          const prevInferredProperties =
            idx(
              inferred[`${parentPathHash}-${currentLayer.id}`],
              (_) => _.properties
            ) || empty.object;

          const currentWidth =
            prevInferredProperties.width !== undefined
              ? prevInferredProperties.width
              : group.properties.width;

          inferred[`${parentPathHash}-${currentLayer.id}`] = {
            properties: {
              ...prevInferredProperties,
              width: currentWidth - delta,
            },
          };

          if (!visitedSiblings) {
            updateInferredSiblings(inferred, layers, symbolPath, layer, delta);

            visitedSiblings = true;
          }
        }
        break;
      }
      case "artboard": {
        break; // immutable
      }
      default: {
        logger.error(`Unexpected parent type: "${currentLayer.type}"`);
      }
    }
  }
}
