// @flow
import { connect } from "react-redux";
import window from "core/global/window";
import createConnector from "core/lib/createConnector";
import { replace } from "core/lib/location";
import * as Request from "core/models/request";
import type { State, Dispatch } from "core/types";
import type { FormValues } from "web/components/CompleteProfileForm";
import withForm from "web/containers/withForm";
import { AccountUpdateRequest } from "web/requests/account";
import { getCurrentUser } from "web/selectors/session";
import { getToken } from "web/token";
import type { OwnProps, StateProps, DispatchProps, Props } from "./";

function getDefaultValues(props: Props) {
  return {
    username: props.username,
    email: props.email,
    name: props.name,
  };
}

function mapStateToProps(state: State, props: Props): StateProps {
  const user = getCurrentUser(state);

  const accountUpdateRequest = AccountUpdateRequest.getRequest(state, {
    id: "account-update",
  });

  return {
    username: user && user.username,
    email: user && user.email,
    name: "",
    isSubmitting: Request.isLoadingStrict(accountUpdateRequest),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    onSubmit: (id: string, params: FormValues) => {
      dispatch(
        AccountUpdateRequest.perform({
          params,
          onSuccess: async () => {
            const meta = props.location.state;

            if (meta && meta.clientType === "mac") {
              const token = getToken();
              let abstractUrl = "/token/auth?";
              if (token) {
                abstractUrl = `${abstractUrl}&clientType=mac&accessToken=${token}&organizationId=${meta.organizationId}`;
              }
              window.location.replace(abstractUrl);
            } else {
              replace(meta && meta.returnTo ? meta.returnTo : "/");
            }
          },
        })
      );
    },
  };
}

export default createConnector<Props, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  // withForm is not compose friendly
  (Component) => withForm<Props>(Component, "account-update", getDefaultValues)
);
