// @flow
/* global window,document */
const AVATAR_CONTENT_TYPES: Array<string> = ["image/jpeg", "image/png"];

async function getDataUrlFromFile(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new window.FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = () => reject(reader.error);
    reader.onabort = () => reject(reader.error);
    reader.readAsDataURL(file);
  });
}

async function getImageDimensions(
  src: string
): Promise<{| width: number, height: number |}> {
  return new Promise((resolve, reject) => {
    const img = document.createElement("img");
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.onerror = () => reject();
    img.src = src;
  });
}

export async function processAvatar(file: File): Promise<[string, string]> {
  if (AVATAR_CONTENT_TYPES.indexOf(file.type) === -1) {
    return ["", "Avatar must be a jpg or png"];
  }

  let dataUrl;
  try {
    dataUrl = await getDataUrlFromFile(file);
  } catch (err) {
    return ["", "File could not be read"];
  }

  let dimensions;
  try {
    dimensions = await getImageDimensions(dataUrl);
  } catch (err) {
    return ["", "Could not load file as an image"];
  }

  const { width, height } = dimensions;

  if (width < 256 || height < 256) {
    return [
      "",
      "Avatar is too small. Must be at least 256 pixels tall and wide",
    ];
  }

  if (width !== height) {
    return ["", "Avatar must be square"];
  }

  return [dataUrl, ""];
}
