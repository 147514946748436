// @flow
import classnames from "classnames";
import * as React from "react";
import style from "./style.scss";

type Props = {
  children: ({ className: string }) => React.Node,
  zoom?: number,
};

export default function Thumbnails({ children, zoom = 1 }: Props) {
  const classes = classnames(style.thumbnails, {
    [style.zoom1]: zoom === 1,
    [style.zoom2]: zoom === 2,
    [style.zoom3]: zoom === 3,
    [style.zoom4]: zoom === 4,
  });

  return (
    <div className={classes} data-qa="thumbnails-wrapper">
      {children({ className: style.thumbnail })}
    </div>
  );
}
