// @flow
import classnames from "classnames";
import * as React from "react";
import Button from "../Button";
import MarkdownHelp from "../MarkdownHelp";
import Popover from "../Popover";
import style from "./style.scss";

type Props = {
  preview: boolean,
  onChange: Function,
};

export default function MarkdownPreviewHelper({ preview, onChange }: Props) {
  return (
    <div className={style.container}>
      <Popover trigger="click" placement="top" body={<MarkdownHelp />}>
        <Button
          icon="markdown"
          type="button"
          className={style.markdownIcon}
          nude
        />
      </Popover>
      <div
        className={classnames(style.button, { [style.active]: !preview })}
        onClick={() => onChange(false)}
      >
        Write
      </div>
      <div
        className={classnames(style.button, { [style.active]: preview })}
        onClick={() => onChange(true)}
      >
        Preview
      </div>
    </div>
  );
}
