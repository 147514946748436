// @flow
import { key } from "core/models/layerData";
import type { Asset, LayerDataAsset } from "core/types";

function getFileName(
  layerName: string,
  formatName: string,
  namingScheme: string,
  fileFormat: string
) {
  const prefix = namingScheme === "1";

  if (prefix) {
    return `${formatName}${layerName}.${fileFormat}`;
  } else {
    return `${layerName}${formatName}.${fileFormat}`;
  }
}

function isAndroidAsset(asset: LayerDataAsset) {
  const { formatName } = asset;
  // a simple heuristic for detecting Android assets is *dpi in the prefix/format name
  return formatName && formatName.includes("dpi");
}

export function fileName(asset: Asset) {
  const { layerName, formatName, namingScheme, fileFormat } = asset;
  return getFileName(layerName, formatName, namingScheme, fileFormat);
}

export function formatFromLayerData(asset: LayerDataAsset) {
  const { formatName, scale } = asset;
  if (isAndroidAsset(asset)) {
    // removing path separator and drawable prefix is a better presentation of Android asset names
    return formatName.replace("/", "").replace("drawable-", "");
  } else if (formatName) {
    return formatName;
  } else {
    return `@${parseFloat(scale)}x`;
  }
}

export function assetLayerKey(asset: Asset): string {
  if (asset._layer) {
    return key(asset._layer);
  }
  return `root-${asset.nestedLayerId}`;
}
