// @flow
import classnames from "classnames";
import * as React from "react";
import Card from "core/components/Card";
import Flex from "core/components/Flex";
import Heading from "core/components/Heading";
import Icon from "core/components/Icon";
import { V3Link as Link } from "core/lib/router";
import style from "./style.scss";

type Props = {|
  children: React.Node,
  hasFooter?: boolean,
  hasList?: boolean,
  collapsible?: boolean,
  collapsed?: boolean,
  disabled?: boolean,
  heading: React.Node,
  headerControls?: React.Node,
  mobile?: boolean,
  noPadding?: boolean,
  storageId: string,
  to?: string,
  qaSelector?: string,
|};

export default function OverviewCard(props: Props) {
  const innerHeading = props.to ? (
    <Link to={props.to} className={style.headingLink}>
      {props.heading} <Icon type="go-to" />
    </Link>
  ) : (
    props.heading
  );

  return (
    <Card
      mobile={props.mobile}
      collapsed={props.collapsed}
      disabled={props.disabled}
      collapsible={props.mobile || props.collapsible}
      hasFooter={props.hasFooter}
      hasList={props.hasList}
      header={
        <Flex align="center" justify="space-between">
          <Heading level="2" size="l">
            {innerHeading}
          </Heading>
          {props.headerControls}
        </Flex>
      }
      innerClassName={classnames({
        [style.padding]: !props.noPadding,
        [style.noPadding]: props.noPadding,
      })}
      storageId={props.storageId}
      qaSelector={props.qaSelector}
    >
      {props.children}
    </Card>
  );
}
