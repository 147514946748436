// @flow
import idx from "idx";
import type { User } from "core/types";

export const unknownUser: User = {
  id: "",
  email: "",
  primaryEmailId: "",
  createdAt: "",
  updatedAt: "",
  username: "",
  name: "Unknown",
  avatarUrl: "",
  isScimProvisioned: false,
  deletedAt: "",
  lastActiveAt: "",
};

export function equal(user1: ?User, user2: ?User): boolean {
  return !user1 || !user2
    ? false
    : idx(user1, (_) => _.id) === idx(user2, (_) => _.id);
}
