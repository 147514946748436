// @flow
import { connect } from "react-redux";
import connectStorage from "core/hocs/connectStorage";
import createConnector from "core/lib/createConnector";
import { defaultBranch } from "core/models/branch";
import { canUseNewDefaultBranchName } from "core/selectors/features";

import {
  getProjectPolicy,
  getOrganizationPolicy,
} from "core/selectors/policies";
import type { State, Dispatch } from "core/types";
import type { OwnProps, StateProps, StorageProps, Props } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const projectPolicy = getProjectPolicy(state, {
    projectId: props.project.id,
  });

  const orgPolicy = getOrganizationPolicy(state, {
    organizationId: props.project.organizationId,
  });

  const defaultBranchName = defaultBranch({
    masterToMain: canUseNewDefaultBranchName(state),
    titleCase: true,
  });

  return {
    canShowCollections: projectPolicy.showCollections,
    canMoveProject:
      orgPolicy.showSections &&
      (orgPolicy.manageSections || projectPolicy.update),
    defaultBranchName,
  };
}
function mapStorageToProps(storage, props: OwnProps): StorageProps {
  const KEY = `global-nav-collapsed-project-${props.project.id}`;

  return {
    collapsed: storage.getItem(KEY),
    toggleCollapsed: (collapsed) => {
      storage.setItem(KEY, collapsed);
    },
  };
}

export default createConnector<Props, OwnProps>(
  connect<$Diff<Props, StorageProps>, OwnProps, StateProps, _, State, Dispatch>(
    mapStateToProps
  ),
  (Component) => connectStorage(Component, mapStorageToProps)
);
