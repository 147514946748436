// @flow
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { addLayersToCollection } from "core/actions/collectionLayers";
import { entitiesClearSelected } from "core/actions/collectionMultiselect";
import { validateCollectionSelection } from "core/actions/collections";
import { showToast } from "core/actions/toasts";
import createConnector from "core/lib/createConnector";
import { push } from "core/lib/location";
import { collectionLocation, collectionPath } from "core/lib/routes";
import { ProjectCollectionsFetchRequest } from "core/requests/collections";
import { getMultiSelectedEntities } from "core/selectors/collectionMultiselect";
import {
  getCollections,
  getCanCreateBranchCollections,
  getCollectionLayersForProject,
} from "core/selectors/collections";

import type {
  State,
  Dispatch,
  MultiSelectedEntities,
  BranchCollectionDescriptor,
} from "core/types";
import type { OwnProps, StateProps, DispatchProps, Props } from "./";

const DEFAULT_SELECTED: MultiSelectedEntities = {
  layers: {},
  pages: {},
  files: {},
};

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { projectId, branchId, showHeader } = props;

  let selected = getMultiSelectedEntities(state, { projectId, branchId });
  if (!showHeader && !selected) {
    selected = DEFAULT_SELECTED;
  }

  const empty =
    !!selected &&
    isEmpty(selected.pages) &&
    isEmpty(selected.files) &&
    isEmpty(selected.layers);

  return {
    selected,
    isEmpty: empty,
    isSelecting: !!selected,
    collections: getCollections(state, {
      projectId,
      branchId,
      sortBy: "name",
    }),
    collectionLayers: getCollectionLayersForProject(state, {
      projectId,
    }),
    canSelect: getCanCreateBranchCollections(state, {
      projectId,
      branchId,
    }),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const {
    projectId,
    branchId,
    commitSha,
    showHeader,
    useLatestCommit,
    onClose,
    redirectToCollectionAfterSubmit,
  } = props;

  return {
    onMount: () => {
      if (showHeader) {
        dispatch(
          ProjectCollectionsFetchRequest.perform({
            params: {
              projectId,
              branchId,
            },
          })
        );
      }
    },
    onClearSelected: () => dispatch(entitiesClearSelected(projectId, branchId)),
    onValidate: (branchCollectionDescriptor: BranchCollectionDescriptor) =>
      dispatch(validateCollectionSelection(branchCollectionDescriptor)),
    onSubmitEmpty: (collectionId) =>
      dispatch(
        showToast({
          text: "No items added",
          link: collectionPath(projectId, branchId, collectionId),
          linkLabel: "View Collection",
        })
      ),
    onSubmit: (selected, collection, allowDuplicates = true) => {
      if (collection) {
        dispatch(
          addLayersToCollection(
            { projectId, branchId, commitSha },
            selected,
            collection,
            allowDuplicates,
            useLatestCommit
          )
        );
        if (redirectToCollectionAfterSubmit === true) {
          push(collectionLocation(projectId, branchId, collection.id));
        }
      }

      if (onClose) {
        onClose();
      }
    },
  };
}

export default createConnector<Props, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps)
);
