// @flow
import * as React from "react";
import { V3Link as Link } from "core/lib/router";
import { organizationSubscription } from "web/routeHelpers";

type Props = {|
  children: React.Node,
  organizationId: string,
  className?: string,
|};

export default function BillingLink(props: Props) {
  return (
    <Link
      className={props.className}
      to={organizationSubscription(props.organizationId)}
    >
      {props.children}
    </Link>
  );
}
