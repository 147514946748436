// @flow
import { connect } from "react-redux";
import { loadTeamMembershipsPreview } from "core/actions/teamMemberships";
import { withData } from "core/components/DataLoader";
import createConnector from "core/lib/createConnector";
import { PaginatedTeamMembershipsRequest } from "core/requests/teamMemberships";
import { getPaginationTotal } from "core/selectors/paginationTotals";
import { getOrganizationPolicy } from "core/selectors/policies";
import { getUsersForTeam } from "core/selectors/users";
import type { State, Dispatch } from "core/types";
import type { Props, OwnProps, StateProps, DispatchProps } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const organizationPolicy = getOrganizationPolicy(state, {
    organizationId: props.params.organizationId,
  });
  return {
    isLoading: PaginatedTeamMembershipsRequest.isFirstLoading(state, {
      organizationId: props.params.organizationId,
      teamId: props.team.id,
      limit: 6,
      offset: 0,
    }),
    members: getUsersForTeam(state, {
      teamId: props.team.id,
    }),
    paginatedMemberTotal: getPaginationTotal(state, props.team.id),
    canClickTeams: organizationPolicy.listMembers,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    onLoad: () =>
      dispatch((dispatch, getState) => {
        const teamMembershipsParams = {
          organizationId: props.params.organizationId,
          teamId: props.team.id,
          limit: 6,
          offset: 0,
        };
        const userFetchSuccess = PaginatedTeamMembershipsRequest.success(
          getState(),
          teamMembershipsParams
        );
        const userFetchIsLoading =
          PaginatedTeamMembershipsRequest.isFirstLoadingStrict(
            getState(),
            teamMembershipsParams
          );

        if (!userFetchSuccess && !userFetchIsLoading) {
          dispatch(
            loadTeamMembershipsPreview({
              organizationId: props.params.organizationId,
              teamId: props.team.id,
            })
          );
        }
      }),
  };
}

export default createConnector<Props, OwnProps>(
  connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch>(
    mapStateToProps,
    mapDispatchToProps
  ),
  withData((props: Props) => props.params)
);
