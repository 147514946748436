// @flow
import invariant from "invariant";
import { connect } from "react-redux";
import { deleteFile } from "abstract-di/actions";
import { dismissDialog } from "core/actions/dialogs";
import type { State, Dispatch, File } from "core/types";
import type { Props, OwnProps, DispatchProps } from ".";

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    deleteFile: (projectId: string, branchId: string, file: File) => {
      invariant(deleteFile, "deleteFile required for dialog action");
      dispatch(deleteFile(projectId, branchId, file, true));
    },
    dismissDialog: () => dispatch(dismissDialog()),
  };
}

export default connect<Props, OwnProps, _, DispatchProps, State, Dispatch>(
  undefined,
  mapDispatchToProps
);
