// @flow
import { getLayer } from "core/selectors/layers";
import type { State } from "core/types";

type Params = {
  projectId?: string,
  branchId?: string,
  commitSha?: string,
  fileId?: string,
  id?: string,
  organizationId?: string,
  pageId?: string,
  collectionId?: string,
  layerId?: string,
  routeId: string,
};

export function getAbstractUrl(state: State, params: Params) {
  const {
    projectId = "",
    branchId = "",
    fileId = "",
    pageId = "",
    layerId = "",
    commitSha = "",
    id = "",
    organizationId = "",
    collectionId = "",
  } = params;

  switch (params.routeId) {
    case "commit": {
      return `abstract://app/share?projectId=${projectId}&branchId=${branchId}&commitSha=${commitSha}&kind=commit`;
    }

    case "collection": {
      return `abstract://app/share?projectId=${projectId}&branchId=${branchId}&collectionId=${collectionId}&kind=collection`;
    }

    case "file": {
      return `abstract://app/share?projectId=${projectId}&branchId=${branchId}&fileId=${fileId}&kind=file`;
    }

    case "page": {
      return `abstract://app/share?projectId=${projectId}&branchId=${branchId}&fileId=${fileId}&pageId=${pageId}&kind=page`;
    }

    case "layer": {
      const layer = getLayer(state, {
        projectId,
        branchId,
        sha: commitSha,
        fileId,
        layerId,
      });
      if (!layer) {
        return "";
      }
      return `abstract://app/share?projectId=${projectId}&branchId=${branchId}&commitSha=${commitSha}&fileId=${fileId}&pageId=${layer.pageId}&layerId=${layerId}&kind=layer`;
    }

    case "branch": {
      return `abstract://app/share?projectId=${projectId}&branchId=${branchId}&kind=branch`;
    }

    case "project":
    case "branches":
    case "collections":
    case "people":
    case "teams":
    case "everyone":
    case "activity": {
      return `abstract://app/share?projectId=${projectId}&kind=project`;
    }

    case "organization":
    case "organizationProjects":
    case "organizationTeams":
    case "organizationPeople": {
      return `abstract://app/share?organizationId=${
        organizationId || id
      }&kind=organization`;
    }

    default:
      return "";
  }
}
