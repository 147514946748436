// @flow
import * as React from "react";
import ReviewTitle from "core/components/BranchReviewForm/ReviewTitle";
import { ReviewStatuses, reviewStatusIcon } from "core/models/review";
import type { ReviewRequest } from "core/types";

type Props = {
  reviewRequest: ?ReviewRequest,
  approvalRequired?: boolean,
};

export default function ReviewTitleBar({
  reviewRequest,
  approvalRequired,
}: Props) {
  if (!reviewRequest) {
    return "You may review this branch — if you do, you will be added as a reviewer.";
  }

  const { requester, status, createdAt, statusChangedAt, reviewerId } =
    reviewRequest;

  let defaultTitle = `${requester.name} added you as a reviewer`;
  if (approvalRequired === true) {
    defaultTitle = `${requester.name} requested your approval`;
  }
  if (requester.id === reviewerId) {
    defaultTitle = "You added yourself as a reviewer";
  }

  switch (status) {
    case ReviewStatuses.APPROVED:
      return (
        <ReviewTitle
          title="You approved this branch"
          icon={reviewStatusIcon(status)}
          timestamp={statusChangedAt}
        />
      );
    case ReviewStatuses.REJECTED:
      return (
        <ReviewTitle
          title="You requested changes"
          icon={reviewStatusIcon(status)}
          timestamp={statusChangedAt}
        />
      );
    default:
      return <ReviewTitle title={defaultTitle} timestamp={createdAt} />;
  }
}
