// @flow
import idx from "idx";
import * as React from "react";
import { connect } from "react-redux";
import { showToast, showCloseableToast } from "core/actions/toasts";
import { ValidationError } from "core/api";
import Loaded from "core/components/Loaded";
import { replace } from "core/lib/location";
import { organizationPeoplePath } from "core/lib/routes";
import { AcceptOrganizationAccessRequest } from "core/requests/organizationAccessRequests";
import type { Dispatch, State } from "core/types";

type OwnProps = {|
  params: { organizationId: string, token: string },
|};

type DispatchProps = {|
  acceptAccessRequest: () => void,
|};

type Props = {
  ...OwnProps,
  ...DispatchProps,
};

class GrantOrganizationAccess extends React.Component<Props> {
  componentDidMount() {
    this.props.acceptAccessRequest();
    replace(organizationPeoplePath(this.props.params.organizationId));
  }

  render() {
    return <Loaded loading />;
  }
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { organizationId, token } = props.params;

  return {
    acceptAccessRequest: () => {
      dispatch(
        AcceptOrganizationAccessRequest.perform({
          params: { organizationId, token },
          onSuccess: (response) => {
            dispatch(showToast({ text: response.message }));
          },
          onError: (error) => {
            if (error instanceof ValidationError) {
              const noSeatsAvailableError = idx(
                error,
                (_) => _.validationErrors.organization[0]
              );

              if (noSeatsAvailableError) {
                return dispatch(
                  showCloseableToast({ text: noSeatsAvailableError })
                );
              }
            }

            return dispatch(showToast({ text: error.message }));
          },
        })
      );
    },
  };
}

export default connect<Props, OwnProps, _, DispatchProps, State, Dispatch>(
  undefined,
  mapDispatchToProps
)(GrantOrganizationAccess);
