// @flow
import * as Abstract from "abstract-sdk";
import { entitiesReceived } from "core/actions/entities";
import abstract from "core/lib/abstract";
import defineRequest from "core/requests";
import { normalizeCommit, normalizeCommits } from "core/schemas/commit";
import { getCommit } from "core/selectors/commits";
import {
  getTransportMode,
  getTransportModeForBranch,
} from "core/selectors/sdk";

const COMMITS_PAGE_SIZE = 30;

export const CommitFetchRequest = defineRequest<
  Abstract.CommitDescriptor,
  Abstract.CommitDescriptor,
>({
  id: ({ projectId, sha }: Abstract.CommitDescriptor) => {
    return `commits-${projectId}-${sha}`;
  },
  perform: async (
    descriptor: Abstract.CommitDescriptor,
    dispatch,
    getState
  ) => {
    const state = getState();
    const commit = getCommit(state, descriptor);
    if (commit) {
      return;
    }

    return abstract.commits.info(descriptor, {
      transportMode: getTransportMode(state, {
        projectId: descriptor.projectId,
      }),
    });
  },
  onSuccess(response, params, dispatch) {
    if (!response) {
      return;
    }

    const { entities } = normalizeCommit(response);
    dispatch(entitiesReceived(entities));
  },
});

type CommitsDescriptors = Abstract.BranchDescriptor | Abstract.LayerDescriptor;

export type CommitsParams = $ReadOnly<{|
  ...CommitsDescriptors,
  startSha?: string,
  endSha?: string,
  limit?: number,
  head?: string,
|}>;

export const CommitsFetchRequest = defineRequest<CommitsParams, CommitsParams>({
  id(params) {
    let id = [
      "commits",
      params.projectId,
      params.branchId,
      params.limit,
      params.startSha,
      params.endSha,
      params.head,
    ];

    if (params.fileId) {
      id.push(params.fileId);
    }
    if (params.layerId) {
      id.push(params.layerId);
    }
    return id.filter((f) => Boolean(f)).join("-");
  },
  perform(params, dispatch, getState) {
    const {
      endSha,
      head,
      limit = COMMITS_PAGE_SIZE,
      startSha,
      ...descriptor
    } = params;

    return abstract.commits.list(descriptor, {
      startSha,
      endSha,
      limit,
      transportMode: getTransportModeForBranch(getState(), {
        projectId: descriptor.projectId,
        branchId: descriptor.branchId,
      }),
    });
  },
  onSuccess(response, params, dispatch) {
    const {
      endSha,
      head,
      limit = COMMITS_PAGE_SIZE,
      startSha,
      ...descriptor
    } = params;
    const { entities, result } = normalizeCommits(response);

    let id = [descriptor.projectId, descriptor.branchId];
    if (descriptor.fileId) {
      id.push(descriptor.fileId);
    }
    if (descriptor.layerId) {
      id.push(descriptor.layerId);
    }
    const entityId = id.join("-");

    dispatch(entitiesReceived(entities));

    return dispatch({
      type: "core/COMMITS_PAGINATION_UPDATED",
      meta: { entityId, pageSize: limit, replace: !!head },
      payload: result,
    });
  },
  force: false,
});
