// @flow
import classnames from "classnames";
import * as React from "react";
import { Waypoint } from "react-waypoint";
import Centered from "core/components/Centered";
import Loading from "core/components/Loading";
import { default as ProjectListItem } from "core/components/ProjectListItem";
import VirtualizedList from "core/components/VirtualizedList";
import type { Project, OrganizationTeamDescriptor } from "core/types";
import style from "./style.scss";

type OwnProps = {
  params: OrganizationTeamDescriptor,
  projects: Project[],
  isGrid: boolean,
  isMobile: boolean,
  hasNextPage: boolean,
  onLoadNextPage?: () => void,
  isLoadingNextPage: boolean,
  searchTerm?: string,
};

type Props = {
  ...OwnProps,
};

export default function VirtualizedTeamProjectList(props: Props) {
  const { isGrid, projects, hasNextPage, onLoadNextPage, isMobile } = props;
  const teamProjectsListRef = React.useRef(null);
  const verticalPadding = isMobile || isGrid ? 0 : 24;
  const isCard = isGrid && !isMobile;

  const renderInnerListElement = ({
    children,
    className,
    style: innerElementStyle,
    ...rest
  }: {
    children: React.Node,
    className?: string,
    style: { height: number, minHeight: number },
  }) => {
    return (
      <div
        className={classnames(className, {
          [style.innerListElement]: !isGrid && !isMobile,
        })}
        style={{
          ...innerElementStyle,
          height: innerElementStyle.height + verticalPadding * 2,
        }}
        {...rest}
      >
        {children}
        {props.onLoadNextPage ? (
          <div className={style.waypoint}>
            <Waypoint topOffset="1440px" onEnter={onLoadNextPage} />
          </div>
        ) : null}
      </div>
    );
  };

  const projectItem = (project, index) => {
    return (
      <ProjectListItem
        className={style.project}
        card={isCard}
        mobile={isMobile}
        project={project}
        showSection={false}
        disableContextMenu
        wrappedList={!isCard}
        query={props.searchTerm}
        qaSelector={`project-card-${index}`}
      />
    );
  };

  const rows = isMobile
    ? []
    : [
        {
          height: 24,

          children: <div />,
        },
      ];

  projects.forEach((project, index) => {
    const listHeight = !project.about || isMobile ? 58 : 98;
    rows.push({
      key: project.id,
      gridItem: isCard,
      height: isCard ? 208 : undefined,
      defaultHeight: !isCard ? listHeight : undefined,
      children: projectItem(project, index),
      className: classnames({
        [style.listItem]: !isCard,
        [style.firstListRow]: index === 0,
        [style.lastListRow]: index === projects.length - 1,
      }),
    });
  });

  if (hasNextPage) {
    rows.push({
      height: 48,
      children: (
        <Centered>
          <Loading small qaSelector="loading-spinner-small" />
        </Centered>
      ),
    });
  }

  if (isCard) {
    rows.push({ height: 24, children: <div /> });
  }

  return (
    <div className={style.listWrapper}>
      <VirtualizedList
        items={rows}
        innerElementType={renderInnerListElement}
        ref={teamProjectsListRef}
        resizeProps={{
          isGrid,
          projects,
          isMobile,
        }}
      />
    </div>
  );
}
