// @flow
import { connect } from "react-redux";
import { getFilteredActivities } from "core/selectors/activities";
import type { State } from "core/types";

type OwnProps = {
  organizationId?: string,
  branchId?: string,
  projectId?: string,
};

function mapStateToProps(state: State, props: OwnProps): * {
  const { branchId, projectId, organizationId } = props;

  return {
    activities: getFilteredActivities(state, {
      organizationId: organizationId || "",
      projectId,
      branchId,
    }),
  };
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default connect(mapStateToProps);
