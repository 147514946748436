// @flow
import * as React from "react";
import Button from "core/components/Button";
import FloatingMenu from "core/components/FloatingMenu";
import MenuItem from "core/components/FloatingMenuItem";
import InputSwitch from "core/components/InputSwitch";
import MenuDivider from "core/components/MenuDivider";
import Popover from "core/components/Popover";
import type { FormatterOptions } from "core/components/PropertyValue/Formatter";
import type { BasicLayerType } from "core/types";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  projectId: string,
  layerType: BasicLayerType,
  showLayoutOverlay: boolean,
  onToggleLayoutOverlay: () => void,
  showGridOverlay: boolean,
  onToggleGridOverlay: () => void,
  isGridEnabled: boolean,
  isLayoutEnabled: boolean,
  onToggleGuidesOverlay: () => void,
  showGuidesOverlay: boolean,
  isGuidesEnabled: boolean,
  showChangeset?: boolean,
  formatterOptions?: FormatterOptions,
  onToggleShowChangeset?: () => void,
  onUpdateFormatterOptions?: (formatterOptions: FormatterOptions) => void,
|};

export type StateProps = {|
  canvasOverlaysEnabled: boolean,
|};

export type Props = {|
  ...OwnProps,
  ...StateProps,
|};

type State = {
  showGridMenu: boolean,
};

class InspectOptionsButton extends React.PureComponent<Props, State> {
  state = {
    showGridMenu: false,
  };

  getHumanReadableColorFormat(colorFormatValue: ?string): string {
    switch (colorFormatValue) {
      case "hex6-rgb":
        return "Hex (RGB)";
      case "hex8-argb":
        return "Hex (ARGB)";
      case "rgb":
        return "RGB";
      case "uicolor-swift":
        return "Swift";
      case "uicolor-objc":
        return "Objective-C";
      default: {
        throw new Error(
          `Invalid input name for colorFormat: "${colorFormatValue || ""}"`
        );
      }
    }
  }

  onClickUnitLabel = (
    event: SyntheticEvent<HTMLElement>,
    dismissPopover: () => void
  ) => {
    const unitLabel = event.currentTarget.getAttribute("name");

    if (this.props.onUpdateFormatterOptions) {
      switch (unitLabel) {
        case "px":
        case "dp":
        case "pt":
          this.props.onUpdateFormatterOptions({
            ...this.props.formatterOptions,
            unitLabel,
          });
          break;
        case "none":
          this.props.onUpdateFormatterOptions({
            ...this.props.formatterOptions,
            unitLabel: "",
          });
          break;
        default: {
          throw new Error(
            `Invalid input name for unitLabel: "${unitLabel || ""}"`
          );
        }
      }
    }

    dismissPopover();
  };

  onClickColorFormat = (
    event: SyntheticEvent<HTMLElement>,
    dismissPopover: () => void
  ) => {
    const colorFormat = event.currentTarget.getAttribute("name");

    if (this.props.onUpdateFormatterOptions) {
      switch (colorFormat) {
        case "hex6-rgb":
        case "hex8-argb":
        case "rgb":
        case "uicolor-swift":
        case "uicolor-objc":
          this.props.onUpdateFormatterOptions({
            ...this.props.formatterOptions,
            colorFormat,
          });
          break;
        default: {
          throw new Error(
            `Invalid input name for colorFormat: "${colorFormat || ""}"`
          );
        }
      }
    }

    dismissPopover();
  };

  render() {
    const { formatterOptions } = this.props;

    return (
      <FloatingMenu
        offset="0, 8px"
        body={(dismissPopover) => (
          <div className={style.popoverBody}>
            <MenuItem
              className={style.layoutMenuItem}
              name="highlightChanged"
              onClick={this.props.onToggleShowChangeset}
              rightSideContent={
                <InputSwitch
                  className={style.layoutToggle}
                  checked={this.props.showChangeset}
                  onChange={this.props.onToggleShowChangeset}
                />
              }
            >
              Highlight changed values
            </MenuItem>
            {formatterOptions && (
              <React.Fragment>
                <MenuItem
                  className={style.layoutMenuItem}
                  name="measurementUnits"
                  rightSideContent={
                    <strong>{formatterOptions.unitLabel}</strong>
                  }
                  submenuProps={{ placement: "left-end" }}
                  submenu={[
                    {
                      name: "dp",
                      checkbox: true,
                      checked: formatterOptions.unitLabel === "dp",
                      children: "Density-Independent Pixels",
                      onClick: (event) =>
                        this.onClickUnitLabel(event, dismissPopover),
                    },
                    {
                      name: "px",
                      checkbox: true,
                      checked: formatterOptions.unitLabel === "px",
                      children: "Pixels",
                      onClick: (event) =>
                        this.onClickUnitLabel(event, dismissPopover),
                    },
                    {
                      name: "pt",
                      checkbox: true,
                      checked: formatterOptions.unitLabel === "pt",
                      children: "Points",
                      onClick: (event) =>
                        this.onClickUnitLabel(event, dismissPopover),
                    },
                    {
                      name: "none",
                      checkbox: true,
                      checked: formatterOptions.unitLabel === "",
                      children: "None",
                      onClick: (event) =>
                        this.onClickUnitLabel(event, dismissPopover),
                    },
                  ]}
                >
                  Measurement units
                </MenuItem>
                <MenuItem
                  className={style.layoutMenuItem}
                  name="colorFormat"
                  rightSideContent={
                    <strong>
                      {this.getHumanReadableColorFormat(
                        formatterOptions.colorFormat
                      )}
                    </strong>
                  }
                  submenuProps={{ placement: "left-end" }}
                  activeHover={false}
                  submenu={[
                    {
                      name: "hex6-rgb",
                      checkbox: true,
                      checked: formatterOptions.colorFormat === "hex6-rgb",
                      children: this.getHumanReadableColorFormat("hex6-rgb"),
                      onClick: (event) =>
                        this.onClickColorFormat(event, dismissPopover),
                    },
                    {
                      name: "hex8-argb",
                      checkbox: true,
                      checked: formatterOptions.colorFormat === "hex8-argb",
                      children: this.getHumanReadableColorFormat("hex8-argb"),
                      onClick: (event) =>
                        this.onClickColorFormat(event, dismissPopover),
                    },
                    {
                      name: "rgb",
                      checkbox: true,
                      checked: formatterOptions.colorFormat === "rgb",
                      children: this.getHumanReadableColorFormat("rgb"),
                      onClick: (event) =>
                        this.onClickColorFormat(event, dismissPopover),
                    },
                    {
                      name: "uicolor-swift",
                      checkbox: true,
                      checked: formatterOptions.colorFormat === "uicolor-swift",
                      children:
                        this.getHumanReadableColorFormat("uicolor-swift"),
                      onClick: (event) =>
                        this.onClickColorFormat(event, dismissPopover),
                    },
                    {
                      name: "uicolor-objc",
                      checkbox: true,
                      checked: formatterOptions.colorFormat === "uicolor-objc",
                      children:
                        this.getHumanReadableColorFormat("uicolor-objc"),
                      onClick: (event) =>
                        this.onClickColorFormat(event, dismissPopover),
                    },
                  ]}
                >
                  Color format
                </MenuItem>
                {this.props.canvasOverlaysEnabled && (
                  <React.Fragment>
                    <MenuDivider />
                    <Popover
                      delayShow={0}
                      delayHide={0}
                      disabled={this.props.isGridEnabled}
                      label={`Grid settings were not set for this ${this.props.layerType}`}
                      placement="left"
                    >
                      <MenuItem
                        className={style.layoutMenuItem}
                        disabled={!this.props.isGridEnabled}
                        name="grid"
                        onClick={this.props.onToggleGridOverlay}
                        rightSideContent={
                          <InputSwitch
                            className={style.layoutToggle}
                            disabled={!this.props.isGridEnabled}
                            label={<span className={style.mono}>^G</span>}
                            checked={this.props.showGridOverlay}
                            onChange={this.props.onToggleGridOverlay}
                          />
                        }
                      >
                        Grid
                      </MenuItem>
                    </Popover>
                    <Popover
                      delayShow={0}
                      delayHide={0}
                      disabled={this.props.isLayoutEnabled}
                      label={`Layout settings were not set for this ${this.props.layerType}`}
                      placement="left"
                    >
                      <MenuItem
                        className={style.layoutMenuItem}
                        disabled={!this.props.isLayoutEnabled}
                        name="layout"
                        onClick={this.props.onToggleLayoutOverlay}
                        rightSideContent={
                          <InputSwitch
                            className={style.layoutToggle}
                            disabled={!this.props.isLayoutEnabled}
                            label={<span className={style.mono}>^L</span>}
                            checked={this.props.showLayoutOverlay}
                            onChange={this.props.onToggleLayoutOverlay}
                          />
                        }
                      >
                        Layout
                      </MenuItem>
                    </Popover>
                    <Popover
                      delayShow={0}
                      delayHide={0}
                      disabled={this.props.isGuidesEnabled}
                      label={`Guides were not set for this ${this.props.layerType}`}
                      placement="left"
                    >
                      <MenuItem
                        className={style.layoutMenuItem}
                        disabled={!this.props.isGuidesEnabled}
                        name="guides"
                        onClick={this.props.onToggleGuidesOverlay}
                        rightSideContent={
                          <InputSwitch
                            className={style.layoutToggle}
                            disabled={!this.props.isGuidesEnabled}
                            label={<span className={style.mono}>^R</span>}
                            checked={this.props.showGuidesOverlay}
                            onChange={this.props.onToggleGuidesOverlay}
                          />
                        }
                      >
                        Guides
                      </MenuItem>
                    </Popover>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </div>
        )}
      >
        {(showPopover, ref) => (
          <Button
            icon="slider"
            onClick={showPopover}
            innerRef={ref}
            qaSelector="inspectOptionsButton"
            disclosure
            nude
          >
            Inspect Options
          </Button>
        )}
      </FloatingMenu>
    );
  }
}

export default connector(InspectOptionsButton);
