// @flow
import { Abstract } from "core/lib/abstract";
import type { State, OrganizationMetrics } from "core/types";

export function getOrganizationMetricsForOrganization(
  state: State,
  props: Abstract.OrganizationDescriptor
): ?OrganizationMetrics {
  return state.organizationMetrics[props.organizationId];
}
