// @flow
import * as React from "react";
import { connect } from "react-redux";
import { downloadAssets, trackDownloadAssetsEvent } from "core/actions/assets";
import window from "core/global/window";
import { Abstract } from "core/lib/abstract";
import type { Asset, Dispatch } from "core/types";

type OwnProps = {|
  assets?: Asset[],
  all?: boolean,
  layerParams: Abstract.LayerVersionDescriptor,
  children: ({
    onDownload: () => Promise<void>,
    loading: boolean,
  }) => React.Node,
|};

type DispatchProps = {|
  onDownload: (assets: Asset[]) => Promise<void>,
|};

type State = {
  loading: boolean,
};

type Props = { ...OwnProps, ...DispatchProps };

class AssetDownloadButton extends React.Component<Props, State> {
  controller: ?any;
  state = { error: undefined, loading: false };
  mounted = false;

  componentDidMount() {
    this.mounted = true;
    this.controller = window.AbortController && new window.AbortController();
  }

  componentWillUnmount() {
    this.mounted = false;
    if (this.controller) {
      this.controller.abort();
    }
  }

  asyncSetState(state: State) {
    if (!this.mounted) {
      return;
    }
    this.setState(state);
  }

  handleDownload = async () => {
    const assets = this.props.assets || [];
    if (assets.length <= 0) {
      return;
    }

    this.asyncSetState({ loading: true });

    try {
      await this.props.onDownload(assets);
    } finally {
      this.asyncSetState({ loading: false });
    }
  };

  render() {
    return this.props.children({
      onDownload: this.handleDownload,
      loading: this.state.loading,
    });
  }
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    onDownload: async (assets: Asset[]) => {
      dispatch(trackDownloadAssetsEvent(props.layerParams, props.all));
      return dispatch(downloadAssets(assets));
    },
  };
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default connect(undefined, mapDispatchToProps)(AssetDownloadButton);
