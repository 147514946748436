// @flow
import invariant from "invariant";
import { connect } from "react-redux";
import { trackEvent } from "core/actions/analytics";
import { loadTeamMembershipsPreview } from "core/actions/teamMemberships";
import { withData } from "core/components/DataLoader";
import createConnector from "core/lib/createConnector";
import { replace } from "core/lib/location";
import { organizationTeamsPath } from "core/lib/routes";
import {
  OrganizationTeamUpdateRequest,
  OrganizationTeamDeleteRequest,
} from "core/requests/teams";
import { getPaginationTotal } from "core/selectors/paginationTotals";
import { getTeamPolicy } from "core/selectors/policies";
import { getTeam } from "core/selectors/teams";
import type { State, Dispatch } from "core/types";
import type { OwnProps, Props, StateProps, DispatchProps } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const team = getTeam(state, props.params);
  invariant(team, "team should exist");

  const policy = getTeamPolicy(state, { teamId: team.id });
  const teamMembershipCount = getPaginationTotal(state, props.params.teamId);
  const updateAllowed = policy && policy.update;
  const destroyAllowed = policy && policy.destroy;

  return {
    policy,
    updateAllowed,
    destroyAllowed,
    team,
    teamMembershipCount,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    onLoad: () => dispatch(loadTeamMembershipsPreview({ ...props.params })),
    onTeamUpdate: (params) => {
      dispatch(
        OrganizationTeamUpdateRequest.perform({
          params,
          onSuccess: () => {
            dispatch(trackEvent("TEAM_UPDATED", { ...params }));
          },
          force: true,
        })
      );
    },
    onTeamDelete: (params) => {
      dispatch(
        OrganizationTeamDeleteRequest.perform({
          params,
          onSuccess: () => {
            dispatch(trackEvent("TEAM_DELETED", params));
          },
          force: true,
        })
      );
      replace(organizationTeamsPath(params.organizationId));
    },
  };
}

export default createConnector<Props, OwnProps>(
  connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch>(
    mapStateToProps,
    mapDispatchToProps
  ),
  withData((props: Props) => props.params)
);
