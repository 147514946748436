// @flow
import * as React from "react";
import ResizeDetector from "react-resize-detector";

type Props = {
  children: ({ above?: boolean }) => React.Node,
  at?: number | "mobile",
};

type State = {
  above?: boolean,
};

export default class Breakpoint extends React.Component<Props, State> {
  state = {
    above: true,
  };

  handleResize = (width: number, height: number) => {
    const { at } = this.props;
    let breakpoint;

    if (at === "mobile") {
      breakpoint = 800;
    } else {
      breakpoint = at;
    }

    if (breakpoint) {
      const above = width >= breakpoint;

      if (above !== this.state.above) {
        this.setState({ above });
      }
    }
  };

  render() {
    const { at, children } = this.props;

    return (
      <React.Fragment>
        {at ? (
          <ResizeDetector
            handleWidth
            handleHeight
            onResize={this.handleResize}
          />
        ) : null}
        {children(this.state)}
      </React.Fragment>
    );
  }
}
