// @flow
import capitalize from "lodash/capitalize";
import * as React from "react";
import ExternalLink from "core/components/ExternalLink";
import Icon from "core/components/Icon";
import { V3Link as Link } from "core/lib/router";
import { organizationPeoplePath } from "core/lib/routes";
import { NON_ENTERPRISE_SEAT_CAP } from "core/lib/subscriptions";
import { learnMoreAboutSeats, enterpriseScheduleDemoUrl } from "core/lib/urls";
import style from "../style.scss";

type OverMaxSeatsErrorProps = {
  seatCapEnforced?: boolean,
  subscriptionTitle: string,
};
export function OverMaxSeatsError({
  seatCapEnforced,
  subscriptionTitle,
}: OverMaxSeatsErrorProps) {
  if (seatCapEnforced === true) {
    return (
      <div className={style.errorBox}>
        <Icon type="info" fill="white" className={style.errorIcon} />
        <div>
          <div className={style.errorTitle}>
            You have reached the maximum amount of seats.
          </div>
          <div className={style.errorDescription}>
            {capitalize(subscriptionTitle)} plans support up to{" "}
            {NON_ENTERPRISE_SEAT_CAP} Contributors. To add more than{" "}
            {NON_ENTERPRISE_SEAT_CAP} seats, contact Sales.
          </div>
          <div className={style.errorContact}>
            <ExternalLink href={enterpriseScheduleDemoUrl()}>
              Contact Sales
            </ExternalLink>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <p className={style.error}>
        You have reached the maximum amount of seats in your contract.{" "}
        <ExternalLink href={enterpriseScheduleDemoUrl()}>
          Contact Sales
        </ExternalLink>{" "}
        to increase your limit or{" "}
        <ExternalLink href={learnMoreAboutSeats()}>learn more…</ExternalLink>
      </p>
    );
  }
}

export function UnderMinEnterpriseSeatsError() {
  return (
    <p className={style.error}>
      You can not remove any more seats as you have reached the minimum number
      of seats specified in your contract.
      <ExternalLink href={enterpriseScheduleDemoUrl()}>
        Contact Sales
      </ExternalLink>{" "}
      or <ExternalLink href={learnMoreAboutSeats()}>learn more…</ExternalLink>
    </p>
  );
}

type UnderMinProSeatsErrorProps = {
  organizationId: string,
};

export function UnderMinProSeatsError({
  organizationId,
}: UnderMinProSeatsErrorProps) {
  return (
    <p className={style.error}>
      You can not remove more seats than are available on your account and are
      not already pending removal.{" "}
      <Link to={organizationPeoplePath(organizationId)}>
        Manage Contributors
      </Link>{" "}
      and convert them to Viewers or remove them from the organization to make
      more seats available to remove.
    </p>
  );
}

export function SubscriptionError() {
  return (
    <p className={style.error}>
      We currently can’t make any changes to your subscription due to API issues
      with our billing provider, please try again later.
    </p>
  );
}

type AllAvailableSeatsUsedErrorProps = {
  organizationId: string,
  allSeatsUsed: boolean,
  lastSeatSelected: boolean,
};

export function AllAvailableSeatsUsedError({
  allSeatsUsed,
  lastSeatSelected,
  organizationId,
}: AllAvailableSeatsUsedErrorProps) {
  if (lastSeatSelected) {
    return (
      <p className={style.error}>
        You cannot remove the last remaining seat on your account.{" "}
        <Link to={organizationPeoplePath(organizationId)}>
          Manage Contributors…
        </Link>
      </p>
    );
  }

  if (allSeatsUsed) {
    return (
      <p className={style.error}>
        You will need to convert a Contributor to a Viewer or remove them from
        Abstract before you can remove a seat.{" "}
        <Link to={organizationPeoplePath(organizationId)}>
          Manage Contributors…
        </Link>
      </p>
    );
  }

  return null;
}
