// @flow
import classnames from "classnames";
import clamp from "lodash/clamp";
import find from "lodash/find";
import map from "lodash/map";
import * as React from "react";
import window from "core/global/window";
import type { Annotation as AnnotationType } from "../../types";
import Annotation from "../Annotation";
import style from "./style.scss";

type Props = {
  width: number,
  height: number,
  minWidth: number,
  minHeight: number,
  scale: number,
  style?: Object,
  annotations: Array<AnnotationType>,
  editingId?: ?string,
  selectedId?: ?string,
  onAnnotationUpdated?: Function,
  onAnnotationDeleted?: Function,
  onAnnotationSelected?: Function,
  onAnnotationDeselected?: Function,
  qaSelector?: string,
};

type State = {
  creating: ?AnnotationType,
  dragging: boolean,
  startX: ?number,
  startY: ?number,
};

const noop = () => {};
const DEFAULT_STATE = {
  creating: null,
  dragging: false,
  startX: null,
  startY: null,
};

function getAnnotationCreating(props: Props) {
  return find(props.annotations, (a) => a.x === undefined || a.y === undefined);
}

export default class Annotations extends React.Component<Props, State> {
  static defaultProps = {
    minWidth: 24,
    minHeight: 24,
    scale: 100,
  };
  container: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      ...DEFAULT_STATE,
      creating: getAnnotationCreating(props),
    };
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    const creating = getAnnotationCreating(props);
    if (!state.creating && creating) {
      return { creating };
    }
    if (state.creating && !creating) {
      return DEFAULT_STATE;
    }
    return null;
  }

  componentWillUnmount() {
    window.removeEventListener("mousemove", this.handleMouseMove);
    window.removeEventListener("mouseup", this.handleMouseUp);
  }

  handleAnnotationSelected = (ev: MouseEvent, selectedId: string) => {
    const { onAnnotationSelected } = this.props;
    if (selectedId !== this.props.editingId && onAnnotationSelected) {
      ev.stopPropagation();
      onAnnotationSelected(selectedId);
    }
  };

  handleAnnotationDeselected = (ev: MouseEvent, selectedId: string) => {
    const { onAnnotationDeselected } = this.props;
    if (selectedId !== this.props.editingId && onAnnotationDeselected) {
      ev.stopPropagation();
      onAnnotationDeselected(selectedId);
    }
  };

  handleMouseDown = (ev: SyntheticMouseEvent<*>) => {
    if (this.state.creating) {
      const containerBounds = this.container.getBoundingClientRect();
      const scaleFactor = 100 / this.props.scale;
      const x = Math.round((ev.clientX - containerBounds.left) * scaleFactor);
      const y = Math.round((ev.clientY - containerBounds.top) * scaleFactor);

      const dragging = true;
      const creating = {
        ...this.state.creating,
        width: 0,
        height: 0,
        x,
        y,
      };

      this.setState({ creating, dragging, startX: x, startY: y });
      window.addEventListener("mousemove", this.handleMouseMove);
      window.addEventListener("mouseup", this.handleMouseUp);
    }
  };

  handleMouseMove = (ev: MouseEvent) => {
    let { creating, dragging, startX, startY } = this.state;

    if (creating && dragging && startX && startY) {
      const containerBounds = this.container.getBoundingClientRect();
      const scaleFactor = 100 / this.props.scale;
      const clampedX = clamp(
        ev.clientX - containerBounds.left,
        0,
        containerBounds.width
      );
      const clampedY = clamp(
        ev.clientY - containerBounds.top,
        0,
        containerBounds.height
      );
      const x = Math.round(clampedX * scaleFactor);
      const y = Math.round(clampedY * scaleFactor);

      creating = {
        ...creating,
        x: Math.min(x, startX),
        y: Math.min(y, startY),
        width: Math.abs(x - startX),
        height: Math.abs(y - startY),
      };
      this.setState({ creating });
    }
  };

  handleMouseUp = (ev: MouseEvent) => {
    const { minWidth, minHeight } = this.props;
    let creating = this.state.creating;

    if (creating && this.state.dragging) {
      // check whether drawn annotation is smaller than both minimums
      if (creating.width < minWidth && creating.height < minHeight) {
        const centerX = creating.x + creating.width / 2;
        const centerY = creating.y + creating.height / 2;

        creating = {
          ...creating,
          x: Math.round(centerX - minWidth / 2),
          y: Math.round(centerY - minHeight / 2),
          width: minWidth,
          height: minHeight,
        };
      }

      this.props.onAnnotationUpdated &&
        this.props.onAnnotationUpdated(creating);
      this.setState(DEFAULT_STATE);
      window.removeEventListener("mousemove", this.handleMouseMove);
      window.removeEventListener("mouseup", this.handleMouseUp);
    }
  };

  render() {
    const { minWidth, minHeight, scale, annotations } = this.props;
    const { creating } = this.state;
    const classes = classnames(style.annotations, {
      [style.creating]: !!this.state.creating,
    });
    const width = Math.round(this.props.width * (scale / 100));
    const height = Math.round(this.props.height * (scale / 100));
    const positionStyle = {
      ...this.props.style,
      transform: `translate(-${Math.round(width / 2)}px, -${Math.round(
        height / 2
      )}px)`,
      width: `${width}px`,
      height: `${height}px`,
    };

    return (
      <div
        ref={(ref) => (this.container = ref)}
        className={classes}
        style={positionStyle}
        onMouseDown={this.handleMouseDown}
      >
        <div className={style.list} data-qa={this.props.qaSelector}>
          {map(annotations, (a, index: number) => {
            // if size or position is missing we're still drawing this annotation
            // so it gets a little special treatment…
            if (a.x === undefined || a.width === undefined) {
              if (!creating) {
                return;
              }
              return (
                <Annotation
                  key="creating"
                  scale={scale}
                  onOuterClick={noop}
                  editing={false}
                  showBubble={false}
                  selected={true}
                  annotation={creating}
                  qaSelector={`annotation-${index}`}
                />
              );
            }

            return (
              <Annotation
                key={a.id}
                scale={scale}
                number={a.number}
                onUpdate={
                  this.props.onAnnotationUpdated
                    ? this.props.onAnnotationUpdated
                    : undefined
                }
                onClick={(ev) => this.handleAnnotationSelected(ev, a.id)}
                onOuterClick={(ev) => this.handleAnnotationDeselected(ev, a.id)}
                editing={this.props.editingId === a.id}
                selected={this.props.selectedId === a.id}
                minWidth={minWidth}
                minHeight={minHeight}
                annotation={a}
                qaSelector={`annotation-${index}`}
              />
            );
          })}
        </div>
      </div>
    );
  }
}
