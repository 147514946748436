// @flow
import config from "abstract-di/config";
import window from "core/global/window";
import KeyCode from "core/lib/keycode";

// navigator.platform returns: Mac68K, MacPPC, MacIntel
export function isMac(): boolean {
  return window.navigator.platform.includes("Mac");
}

export function isWindows(): boolean {
  return window.navigator.platform.includes("Win");
}

export const isWeb = config.platform === "web";
export const isDesktop = config.platform === "desktop";
export const isQA = process.env.QA === "true";

export function modifierKeyPressed(
  event:
    | SyntheticKeyboardEvent<>
    | SyntheticMouseEvent<>
    | KeyboardEvent
    | MouseEvent
): boolean {
  return isMac() ? event.metaKey : event.ctrlKey;
}

// Alternative to event.metaKey that works on keyup
export function metaKey(
  event: SyntheticKeyboardEvent<> | KeyboardEvent
): boolean {
  if (isMac()) {
    return (
      event.keyCode === KeyCode.KEY_LEFT_CMD ||
      event.keyCode === KeyCode.KEY_RIGHT_CMD ||
      event.metaKey
    );
  } else {
    return (
      event.keyCode === KeyCode.KEY_LEFT_CMD ||
      event.keyCode === KeyCode.KEY_RIGHT_CMD_WINDOWS ||
      event.ctrlKey
    );
  }
}
