// @flow
import * as React from "react";
import Button from "core/components/Button";
import Flex from "core/components/Flex";
import Heading from "core/components/Heading";
import Icon from "core/components/Icon";
import window from "core/global/window";
import style from "./style.scss";

export default function SSOTestVerification() {
  return (
    <Flex className={style.component} column align="center">
      <Flex align="center" className={style.icon} justify="center">
        <Icon className={style.checkmark} light large type="checkmark" />
      </Flex>
      <Heading className={style.heading} level="1" size="xxxl">
        SSO Testing Successful
      </Heading>
      <p className={style.note}>
        Everything is hooked up and your organization is good to go. When you’re
        ready, you can enable SSO for everyone back on the Permissions page by
        toggling the control shown below and then clicking save.
      </p>
      <div className={style.activateSSOImage} alt="Activate SSO switch" />
      <Button primary onClick={window.close}>
        Close This Window
      </Button>
    </Flex>
  );
}
