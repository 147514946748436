// @flow
import { connect } from "react-redux";
import { trackEvent } from "core/actions/analytics";
import type { State, Dispatch } from "core/types";
import type { OwnProps, DispatchProps, Props } from ".";

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    trackRender() {
      dispatch(trackEvent("LargeCommitWarning", { size: props.size }));
    },
  };
}

export default connect<Props, OwnProps, _, DispatchProps, State, Dispatch>(
  undefined,
  mapDispatchToProps
);
