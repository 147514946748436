// @flow
import Cookies from "js-cookie";
import { SECURE_COOKIES_ENABLED } from "./config";

const LEGACY_KEY = "abstract-current-organization-id";
const KEY = "abstract-current-organization-id-v2";

export function setCurrentOrganizationId(id: string): void {
  Cookies.set(KEY, id, {
    secure: SECURE_COOKIES_ENABLED,
    expires: 365,
  });
}

export function getCurrentOrganizationId(): ?string {
  const organizationId = Cookies.get(KEY);
  if (organizationId) {
    return organizationId;
  }

  const legacyOrganizationId = Cookies.get(LEGACY_KEY);
  if (legacyOrganizationId) {
    setCurrentOrganizationId(legacyOrganizationId);
  }

  return legacyOrganizationId;
}

export function clearCurrentOrganizationId() {
  Cookies.remove(KEY);
  Cookies.remove(LEGACY_KEY);
}
