// @flow
import classnames from "classnames";
import * as React from "react";
import style from "./style.scss";

type Props = {
  children: React.Node,
  className?: string,
};

export default function Tabs({ children, className, ...rest }: Props) {
  return (
    <div {...rest} className={classnames(style.tabs, className)}>
      {children}
    </div>
  );
}
