// @flow
import { normalize, schema } from "normalizr";

export const commitSchema = new schema.Entity(
  "commits",
  {},
  {
    idAttribute: "sha",
  }
);

export const commitsSchema = new schema.Array(commitSchema);

export const commitsResponseSchema = new schema.Object({
  commits: commitsSchema,
});

export function normalizeCommit(commit: any) {
  return normalize(commit, commitSchema);
}

export function normalizeCommits(commits: any) {
  return normalize(commits, commitsSchema);
}

export function normalizeCommitsResponse(response: any) {
  return normalize(response, commitsResponseSchema);
}
