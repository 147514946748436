// @flow
import { Abstract } from "core/lib/abstract";
import { CommitFetchRequest } from "core/requests/commits";
import type { ThunkAction } from "core/types";

export function loadCommit(descriptor: Abstract.CommitDescriptor): ThunkAction {
  // Prefer using the request directly – this is here for backwards compat
  return CommitFetchRequest.perform({
    force: false,
    params: descriptor,
  });
}
