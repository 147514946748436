// @flow
import { connect } from "react-redux";
import { trackEvent } from "core/actions/analytics";
import { UnresolveCommentRequest } from "core/requests/comments";
import { getProjectPolicy } from "core/selectors/policies";
import { getUser } from "core/selectors/users";
import type { State, Dispatch } from "core/types";
import type { Props, OwnProps, StateProps, DispatchProps } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const user =
    props.comment.resolvedByUserId &&
    getUser(state, { userId: props.comment.resolvedByUserId });

  const isUnresolving = UnresolveCommentRequest.isLoadingStrict(state, {
    id: props.comment.id,
  });

  const projectPolicy = getProjectPolicy(state, {
    projectId: props.comment.projectId,
  });

  return {
    name: user ? `Resolved by ${user.name}` : `Resolved`,
    isUnresolving,
    canUnresolve: projectPolicy.resolveComments,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    onUnresolve: () => {
      dispatch(
        UnresolveCommentRequest.perform({ params: { id: props.comment.id } })
      );

      dispatch(
        trackEvent("COMMENT_UNRESOLVED", {
          commentId: props.comment.id,
          layerId: props.comment.layerId,
          projectId: props.comment.projectId,
          branchId: props.comment.branchId,
        })
      );
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
