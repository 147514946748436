// @flow
import classNames from "classnames";
import * as React from "react";
import Flex from "../Flex";
import Icon from "../Icon";
import style from "./style.scss";

type Props = {
  onClick: (event: SyntheticEvent<>) => void,
  expanded: boolean,
  children: React.Node,
  loading?: boolean,
  hideBorder?: boolean,
  className?: string,
};

export default function Expand({
  onClick,
  expanded,
  children,
  loading,
  hideBorder = false,
  className,
}: Props) {
  return (
    <Flex
      align="center"
      justify="center"
      className={classNames(style.expand, {
        [style.hideBorder]: hideBorder,
      })}
    >
      <span
        onClick={onClick}
        className={classNames(style.expandLink, className)}
      >
        {expanded ? (
          loading ? (
            <React.Fragment>
              Loading…{" "}
              <Icon
                type="chevron-default-down"
                className={style.collapse}
                tint
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              Collapse{" "}
              <Icon
                type="chevron-default-down"
                className={style.collapse}
                tint
              />
            </React.Fragment>
          )
        ) : (
          <React.Fragment>
            {children}
            <Icon type="chevron-default-down" tint />
          </React.Fragment>
        )}
      </span>
    </Flex>
  );
}
