// @flow
import { connect } from "react-redux";
import { openSupportWindow } from "abstract-di/actions";
import { toggleShortcutViewer } from "core/actions/app";
import contextMenuWrapper, {
  type Props as ContextMenuWrapperProps,
} from "core/components/ContextMenu/ContextMenuWrapper";
import createConnector from "core/lib/createConnector";
import { canAccessAbstractWorkflowTutorial } from "core/selectors/features";
import type { State, Dispatch } from "core/types";
import type { OwnProps, StateProps, DispatchProps, Props } from ".";
function mapStateToProps(state: State, props: OwnProps): StateProps {
  return {
    canAccessAbstractWorkflowTutorial: canAccessAbstractWorkflowTutorial(state),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    openSupportWindow: () => dispatch(openSupportWindow(props.organizationId)),
    toggleShortcutViewer: () => {
      dispatch(toggleShortcutViewer());
    },
  };
}

export default createConnector<
  Props,
  {|
    ...OwnProps,
    ...$Exact<ContextMenuWrapperProps>,
  |},
>(
  contextMenuWrapper,
  connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch>(
    mapStateToProps,
    mapDispatchToProps
  )
);
