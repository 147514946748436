// @flow
import * as React from "react";
import { connect } from "react-redux";
import type { RequestDef } from "core/requests";
import {
  getPaginationHasMore,
  getPaginationId,
  getPaginationItemsLoaded,
} from "core/selectors/pagination";
import type { Dispatch, State, ThunkAction } from "core/types";

type OwnProps<Params, Items> = {|
  children: ({
    hasNextPage: boolean,
    isLoadingNextPage: boolean,
    items: Items,
    onLoadNextPage?: () => void,
  }) => React.Node,
  params: Params,
  selectorParams?: Params,
|};

type StateProps<Items> = {|
  hasNextPage: boolean,
  isLoadingNextPage: boolean,
  items: Items,
|};

type DispatchProps = {|
  loadInitialPageIfNecessary: () => void,
  onLoadNextPage: () => void,
|};

type Props<Params, Items> = {
  ...OwnProps<Params, Items>,
  ...StateProps<Items>,
  ...DispatchProps,
};

type RequestParams<Params> = { ...Params, limit: number, offset: number };

export function createPaginationDataLoaderComponent<Params: Object, Items>(
  id: string,
  request: RequestDef<RequestParams<Params>, RequestParams<Params>>,
  getItemsLoaded: (response: Object) => number,
  selector: (state: State, params: Params) => Items,
  pageSize: number = 20
): React.AbstractComponent<OwnProps<Params, Items>> {
  function PaginationDataLoader(props: Props<Params, Items>) {
    const onLoadNextPage =
      props.hasNextPage && !props.isLoadingNextPage
        ? props.onLoadNextPage
        : undefined;

    React.useEffect(() => {
      props.loadInitialPageIfNecessary();
    });

    return props.children({
      hasNextPage: props.hasNextPage,
      isLoadingNextPage: props.isLoadingNextPage,
      items: props.items,
      onLoadNextPage,
    });
  }

  function mapStateToProps(
    state: State,
    props: OwnProps<Params, Items>
  ): StateProps<Items> {
    const selectorParams = props.selectorParams
      ? props.selectorParams
      : props.params;
    const offset = getPaginationItemsLoaded(state, id, props.params);

    return {
      hasNextPage: getPaginationHasMore(state, id, props.params),
      isLoadingNextPage: request.isLoadingStrict(state, {
        ...props.params,
        limit: pageSize,
        offset,
      }),
      items: selector(state, selectorParams),
    };
  }

  function onLoadNextPage(
    params: Params,
    paramsForSelector?: Params
  ): ThunkAction {
    return async function (dispatch, getState) {
      const selectorParams = paramsForSelector ? paramsForSelector : params;

      const state = getState();
      const offset = getPaginationItemsLoaded(state, id, params);
      const itemsBeforeLoadingNewPage = selector(state, selectorParams);

      dispatch(
        request.perform({
          params: { ...params, limit: pageSize, offset },
          onSuccess: (response) => {
            const itemsLoaded = getItemsLoaded(response);
            const itemsLoadedAction = dispatch({
              type: "core/PAGINATION_ITEMS_LOADED",
              meta: { id: getPaginationId(id, params) },
              payload: {
                itemsLoaded,
                pageSize,
              },
            });

            const stateAfterLoad = getState();
            const itemsAfterLoadingNewPage = selector(
              stateAfterLoad,
              selectorParams
            );
            const hasMore = getPaginationHasMore(stateAfterLoad, id, params);

            if (
              hasMore &&
              Array.isArray(itemsBeforeLoadingNewPage) &&
              Array.isArray(itemsAfterLoadingNewPage) &&
              itemsBeforeLoadingNewPage.length ===
                itemsAfterLoadingNewPage.length
            ) {
              return dispatch(onLoadNextPage(params, selectorParams));
            }

            return itemsLoadedAction;
          },
          force: true,
        })
      );
    };
  }

  function loadInitialPageIfNecessary(params: Params): ThunkAction {
    return function (dispatch, getState) {
      const firstRequest = request.getRequest(getState(), {
        ...params,
        limit: pageSize,
        offset: 0,
      });

      if (firstRequest.updatedAt) {
        return;
      }

      return dispatch(onLoadNextPage(params));
    };
  }

  function mapDispatchToProps(
    dispatch: Dispatch,
    props: OwnProps<Params, Items>
  ): DispatchProps {
    return {
      loadInitialPageIfNecessary() {
        dispatch(loadInitialPageIfNecessary(props.params));
      },
      onLoadNextPage() {
        dispatch(onLoadNextPage(props.params, props.selectorParams));
      },
    };
  }
  return connect<
    Props<Params, Items>,
    OwnProps<Params, Items>,
    StateProps<Items>,
    DispatchProps,
    State,
    Dispatch,
  >(
    mapStateToProps,
    mapDispatchToProps
  )(PaginationDataLoader);
}
