// @flow
import classnames from "classnames";
import * as React from "react";
import style from "./style.scss";

type Props = {
  children: React.Node,
  className?: string,
  level: "1" | "2" | "3" | "4" | "5" | "6",
};

export default function Heading(props: Props) {
  const { className, level, ...rest } = props;

  const Tag = `h${level}`;

  return <Tag className={classnames(style.heading, className)} {...rest} />;
}
