// @flow
import * as React from "react";
import CoreBranchOverview from "core/components/BranchOverview";
import Head from "core/components/Head";
import Loaded from "core/components/Loaded";
import Media from "core/components/Media";
import { Abstract } from "core/lib/abstract";
import type { Branch, Project, ReactRouterLocation } from "core/types";
import BranchPage from "../BranchShell/BranchPage";

type Props = {
  location: ReactRouterLocation,
  params: Abstract.BranchDescriptor,
  branch: Branch,
  project: Project,
  isLoading: boolean,
};

export default function BranchOverview(props: Props) {
  const { params, isLoading, location } = props;

  return (
    <BranchPage>
      <Head>
        <title>Overview</title>
      </Head>
      <Loaded loading={isLoading}>
        {() => (
          <React.Fragment>
            <Media tablet>
              {(tablet) => (
                <Media desktop>
                  {(desktop) => (
                    <CoreBranchOverview
                      location={location}
                      params={params}
                      isMobile={!desktop}
                      isTablet={tablet}
                    />
                  )}
                </Media>
              )}
            </Media>
          </React.Fragment>
        )}
      </Loaded>
    </BranchPage>
  );
}
