// @flow
import * as Request from "core/models/request";
import { getUser } from "core/selectors/users";
import type { Account, User, State } from "core/types";
import { getSessionRequest } from "web/selectors/requests";

export function getCurrentUser(state: State): ?Account | ?User {
  if (!state.session.userId) {
    return undefined;
  }
  return getUser(state, { userId: state.session.userId });
}

export function getMarketingConsent(state: State): boolean {
  return (
    !state.session.marketingConsentDeclined &&
    state.session.marketingConsentGiven
  );
}

export function shouldRequestMarketingConsent(state: State): boolean {
  return (
    state.session.marketingConsentDeclined === false &&
    state.session.marketingConsentGiven === false
  );
}

export function shouldRequestTOSAgreement(state: State): boolean {
  return state.session.acceptedTermsOfService === false;
}

export function sessionIsLoading(state: State) {
  return !isLoggedIn(state) && Request.isLoading(getSessionRequest(state));
}

export function isLoggedIn(state: State) {
  return !!getCurrentUser(state);
}

export function isMacAppDownloaded(state: State) {
  return state.session.macAppDownloaded;
}

export function shouldRequestTrialConsent(state: State): boolean {
  return state.session.trialConsentGiven === false;
}
