// @flow
import * as React from "react";
import FormSection from "core/components/FormSection";
import InputSwitch from "core/components/InputSwitch";
import Note from "core/components/Note";
import SettingsItem from "core/components/SettingsItem";
import style from "./style.scss";

type Props = {|
  joinExistingOrgEnabled?: boolean,
  submitRequestAccessSetting: (
    organizationId: string,
    property: string,
    value: boolean
  ) => void,
  organizationId: string,
  projectAccessRequestEnabled?: boolean,
|};

type State = {|
  joinExistingOrgEnabled?: boolean,
  projectAccessRequestEnabled?: boolean,
|};

export default class RequestAccess extends React.Component<Props, State> {
  state = {
    joinExistingOrgEnabled: this.props.joinExistingOrgEnabled,
    projectAccessRequestEnabled: this.props.projectAccessRequestEnabled,
  };

  handleToggleChange = (event: SyntheticInputEvent<>) => {
    const { checked, name } = event.target;

    this.setState({ [name]: checked }, () => {
      this.props.submitRequestAccessSetting(
        this.props.organizationId,
        name,
        checked
      );
    });
  };

  render() {
    return (
      <FormSection heading="Teammates can request an invitation…">
        <SettingsItem switch>
          <InputSwitch
            checked={this.state.projectAccessRequestEnabled}
            label={
              <React.Fragment>
                By receiving a link from a project
                <Note component="div" className={style.note}>
                  Getting sent a link from a project will allow teammates to
                  request access to that project. Project Admins must approve
                  all requests.
                </Note>
              </React.Fragment>
            }
            name="projectAccessRequestEnabled"
            onChange={this.handleToggleChange}
            responsive
          />
        </SettingsItem>
        <SettingsItem switch>
          <InputSwitch
            checked={this.state.joinExistingOrgEnabled}
            label={
              <React.Fragment>
                By verifying they have an approved email domain
                <Note component="div" className={style.note}>
                  Users who have verified an email with an approved domain will
                  be shown your organization name and logo at sign-up and can
                  request access to your organization. Admins must approve all
                  requests.
                </Note>
              </React.Fragment>
            }
            name="joinExistingOrgEnabled"
            onChange={this.handleToggleChange}
            responsive
          />
        </SettingsItem>
      </FormSection>
    );
  }
}
