// @flow
import { memoize } from "lodash";
import * as React from "react";
import { Waypoint } from "react-waypoint";
import ActivityList from "core/components/ActivityList";
import Breakpoint from "core/components/Breakpoint";
import DeferredRender from "core/components/DeferredRender";
import Offline from "core/components/Empty/Offline";
import Loaded from "core/components/Loaded";
import OverviewCard from "core/components/ProjectOverview/OverviewCard";
import ActivitiesLoader from "core/containers/ActivitiesLoader";
import { projectPath } from "core/lib/routes";
import type { Project } from "core/types";
import style from "./style.scss";

const getActivityContext = memoize((projectId: string) => ({ projectId }));

type Props = {
  mobile?: boolean,
  project: Project,
};

export default class RecentActivity extends React.Component<Props> {
  render() {
    return (
      <ActivitiesLoader
        organizationId={this.props.project.organizationId}
        projectId={this.props.project.id}
      >
        {({ activityIds, isLoading, isLoadingMore, onLoadMore, isOffline }) => {
          const hasActivities = activityIds.length > 0;

          return (
            <OverviewCard
              heading="Recent Activity"
              mobile={this.props.mobile}
              storageId="ProjectOverview-RecentActivity"
              to={projectPath(this.props.project.id, "activity")}
              qaSelector="recent-activity-wrapper"
              noPadding
            >
              <Loaded loading={isLoading} small flex>
                {() => {
                  if (!hasActivities && isOffline) {
                    return (
                      <Offline description="Connect to the internet to load activities." />
                    );
                  }

                  return (
                    <React.Fragment>
                      <Breakpoint at={600}>
                        {({ above }) => (
                          <DeferredRender>
                            {() => (
                              <ActivityList
                                narrow={!above}
                                activityIds={activityIds}
                                projectId={this.props.project.id}
                                activityContext={getActivityContext(
                                  this.props.project.id
                                )}
                                className={style.activity}
                              >
                                <Waypoint onEnter={onLoadMore} />
                              </ActivityList>
                            )}
                          </DeferredRender>
                        )}
                      </Breakpoint>
                      {isLoadingMore && <Loaded small loading />}
                    </React.Fragment>
                  );
                }}
              </Loaded>
            </OverviewCard>
          );
        }}
      </ActivitiesLoader>
    );
  }
}
