// @flow
import * as React from "react";
import Collection from "core/components/Collection";
import Layer from "core/components/Layer";
import { replace, removeQuery } from "core/lib/location";
import type {
  ShareLink,
  ReactRouterLocation,
  BranchCollectionDescriptor,
} from "core/types";
import connector from "./connector";

export type OwnProps = {|
  params: BranchCollectionDescriptor,
  location: ReactRouterLocation,
  shareLink: ShareLink,
  onClose?: () => void,
|};

export type StateProps = {|
  layerId: ?string,
  fileId: ?string,
  sha: string,
|};

export type DispatchProps = {|
  onLoad: () => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

class PublicCollection extends React.Component<Props> {
  handleClose = () => {
    if (this.props.onClose !== undefined) {
      return this.props.onClose();
    }

    if (
      this.props.location.query.present &&
      this.props.location.query.preview !== "false"
    ) {
      return replace(removeQuery("present", "preview", "sha"));
    }

    return replace(this.props.shareLink.url);
  };

  render() {
    if (
      this.props.shareLink.kind === "collection" &&
      this.props.location.query.collectionLayerId
    ) {
      return this.props.fileId && this.props.layerId ? (
        <Layer
          location={this.props.location}
          layerSetParams={{
            type: "collection",
            ...this.props.params,
          }}
          params={{
            projectId: this.props.shareLink.descriptor.projectId,
            branchId: this.props.shareLink.descriptor.branchId,
            fileId: this.props.fileId,
            layerId: this.props.layerId,
            sha: this.props.sha,
          }}
          shareLink={this.props.shareLink}
          onClose={this.handleClose}
        />
      ) : null;
    }

    return (
      <Collection
        location={this.props.location}
        params={this.props.params}
        shareLink={this.props.shareLink}
      />
    );
  }
}

export default connector(PublicCollection);
