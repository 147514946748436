// @flow
import * as React from "react";
import Button from "core/components/Button";
import ContextMenu from "core/components/ContextMenu";
import DialogForm from "core/components/DialogForm";
import Note from "core/components/Note";
import type { User, Policy } from "core/types";

type Props = {
  user: User,
  isCurrentUser: boolean,
  teamName: ?string,
  organizationName: ?string,
  isTeamAdmin: boolean,
  isGuest: ?boolean,
  teamPolicy: Policy,
  isLoadingDeleteTeamMembership: boolean,
  onDowngradeToMember: () => void,
  onUpgradeToAdmin: () => void,
  onRemoveMember: () => void,
};

export default function TeamMemberMenu(props: Props) {
  const {
    user,
    teamName,
    isGuest,
    isTeamAdmin,
    organizationName,
    onUpgradeToAdmin,
    onDowngradeToMember,
    onRemoveMember,
    isLoadingDeleteTeamMembership,
    teamPolicy,
    isCurrentUser,
  } = props;
  const menuRef = React.useRef(null);
  const [showDialog, setShowDialog] = React.useState(false);

  const handleRemoveMember = (event) => {
    setShowDialog(true);
  };

  const teamRoleOptions = [
    {
      label: `Team Admin`,
      type: "checkbox",
      checked: isTeamAdmin,
      enabled: teamPolicy.updateMember && !isTeamAdmin,
      click: teamPolicy.updateMember ? onUpgradeToAdmin : undefined,
      qaSelector: "upgradeToAdminMenuItem",
    },
    {
      label: `Team Member`,
      type: "checkbox",
      checked: !isTeamAdmin,
      enabled: teamPolicy.updateMember && isTeamAdmin,
      click: teamPolicy.updateMember ? onDowngradeToMember : undefined,
      qaSelector: "downgradeAdminMenuItem",
    },
    { type: "separator" },
  ];

  let buttonTitle = `Remove this ${isGuest ? "guest" : "member"} `;
  if (isCurrentUser) {
    buttonTitle = "Leave this team";
  } else if (isLoadingDeleteTeamMembership) {
    buttonTitle = isCurrentUser ? "Leaving…" : "Removing…";
  }

  return (
    <React.Fragment>
      <ContextMenu
        id={user.id}
        ref={menuRef.current}
        menuItems={[
          ...teamRoleOptions,
          {
            label: `${isCurrentUser ? "Leave this" : "Remove from"} team…`,
            danger: true,
            enabled: isCurrentUser ? teamPolicy.leave : teamPolicy.removeMember,
            click:
              (isCurrentUser && teamPolicy.leave) || teamPolicy.removeMember
                ? handleRemoveMember
                : undefined,
            qaSelector: `${isCurrentUser ? "Leave this" : "Remove from"} team…`,
          },
        ]}
      >
        {(showMenu, ref) => (
          <Button
            aria-haspopup
            nude
            icon="overflow"
            title={"Show organization team membership menu"}
            innerRef={ref}
            onClick={showMenu}
            qaSelector="teamMemberUserCardMenu"
          />
        )}
      </ContextMenu>
      <DialogForm
        dangerous
        isOpen={showDialog}
        onSubmit={() => {
          onRemoveMember();
          setShowDialog(false);
        }}
        onClose={() => setShowDialog(false)}
        loading={isLoadingDeleteTeamMembership}
        disabled={isLoadingDeleteTeamMembership}
        title={
          isCurrentUser
            ? "Leave this Team"
            : `Remove this ${isGuest ? "guest" : "member"} `
        }
        primaryButton={buttonTitle}
      >
        {isGuest ? (
          <div>
            <p>
              Once you remove {user.name} from the {teamName} team, they will
              lose access to all of the team's projects.
            </p>
            <Note>
              {user.name} will not maintain access as a guest of
              {organizationName} unless they belong to another team or project.
            </Note>
          </div>
        ) : (
          <div>
            <p>
              Once you remove {user.name} from the {teamName} team, they will
              lose access to all of the team's private projects.
            </p>
            <Note>
              {user.name} will maintain access as a member of {organizationName}
            </Note>
          </div>
        )}
      </DialogForm>
    </React.Fragment>
  );
}
