// @flow
import * as React from "react";
import BillingLink from "abstract-di/components/BillingLink";
import DialogForm from "core/components/DialogForm";
import ExternalLink from "core/components/ExternalLink";
import { helpProjectPermissionsGuestUrl } from "core/lib/urls";

type Props = {|
  canManageBilling?: boolean,
  isOpen: boolean,
  onClose: () => void,
  organizationId?: string,
|};

export default function ProjectMembersUpsellDialog(props: Props) {
  return (
    <DialogForm
      isOpen={props.isOpen}
      onClose={props.onClose}
      title="Upgrade to invite Guests"
      primaryButton=""
    >
      <p>
        Guest invitations are only available for Business and Enterprise
        customers.
      </p>
      {props.canManageBilling ? (
        <p>
          {props.organizationId ? (
            <BillingLink organizationId={props.organizationId}>
              Upgrade your plan
            </BillingLink>
          ) : (
            "Upgrade your plan"
          )}{" "}
          to invite Guests to your projects.
        </p>
      ) : (
        <p>
          Ask your Organization Admin to upgrade your plan in order to invite
          Guests to projects.
        </p>
      )}
      <p>
        <ExternalLink href={helpProjectPermissionsGuestUrl()}>
          Learn more about Guest invitations in the Help Center…
        </ExternalLink>
      </p>
    </DialogForm>
  );
}
