// @flow
import padStart from "lodash/padStart";
import * as React from "react";
import SettingsItem from "core/components/SettingsItem";
import type { Subscription } from "../../../types";
import style from "./style.scss";

export default function PaymentDetails({
  subscription,
}: {
  subscription: Subscription,
}) {
  return (
    <div>
      <SettingsItem responsive label="Credit card">
        <span>**** **** **** {subscription.lastFour}</span>
        <span>{subscription.cardType}</span>
      </SettingsItem>
      <SettingsItem label="Expiration date">
        <span className={style.expirationDate}>
          {padStart(`${subscription.expirationMonth}`, 2, "0")} /{" "}
          {subscription.expirationYear}
        </span>
      </SettingsItem>
    </div>
  );
}
