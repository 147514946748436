// @flow
import * as React from "react";
import Input from "core/components/Input";
import Note from "core/components/Note";
import { REMOVE_FROM_ORGANIZATION } from "core/constants";
import type { User, Organization, Subscription } from "core/types";
import style from "../../style.scss";

type Props = {
  user: User,
  organization: Organization,
  subscriptionRole: string,
  subscription: ?Subscription,
  handleConfirm: (SyntheticInputEvent<>) => any,
};

export default function RemoveOther(props: Props) {
  return (
    <div>
      <p>
        Do you want to remove <strong>{props.user.name}</strong> as a{" "}
        <span className={style.capitalize}>{props.subscriptionRole}</span> from{" "}
        <strong>{props.organization.name}</strong>?
      </p>
      <Note>
        <span>
          They will lose access to all projects in the organization
          {props.subscriptionRole === "contributor"
            ? ", but their work will remain."
            : "."}
        </span>
      </Note>
      <React.Fragment>
        <Input
          label={`Ready? Enter "${REMOVE_FROM_ORGANIZATION}" to proceed.`}
          onChange={props.handleConfirm}
          autoComplete="off"
          autoFocus
          placeholder={`Confirm you want to remove ${props.user.name} from the organization…`}
        />
      </React.Fragment>
    </div>
  );
}
