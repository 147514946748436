// @flow
import * as Abstract from "abstract-sdk";
import * as React from "react";
import FormHeader from "core/components/FormHeader";
import Media from "core/components/Media";
import SettingsForm from "core/components/SettingsForm";
import type { SSOConfig } from "core/types";
import DomainRestrictions from "web/components/OrganizationSettings/Permissions/DomainRestrictions";
import SSOForm from "web/components/OrganizationSettings/Permissions/SSOForm";
import SSOUnavailable from "web/components/OrganizationSettings/Permissions/SSOUnavailable";
import RequestAccess from "./RequestAccess";
import connector from "./connector";

export type OwnProps = {|
  manualExceptions: string,
  params: Abstract.OrganizationDescriptor,
|};

export type StateProps = {|
  canShowSSOForm: boolean,
  emailExceptions: string,
  entityId: string,
  joinExistingOrgEnabled?: boolean,
  metadataUrl: string,
  projectAccessRequestEnabled?: boolean,
  restrictedToDomain?: string,
  ssoActive: boolean,
  userId: string,
|};

export type DispatchProps = {|
  submitDomainRestrictionSettings: (
    formId: string,
    organizationId: string,
    values: Object
  ) => Promise<void>,
  submitRequestAccessSetting: (
    organizationId: string,
    property: string,
    value: boolean
  ) => void,
  submitSSOSettings: (
    formId: string,
    organizationId: string,
    values: SSOConfig
  ) => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

function PermissionsEdit(props: Props) {
  const header = (
    <Media desktop>
      {(desktop) => <FormHeader mobile={!desktop} icon="lock-locked-alt" />}
    </Media>
  );

  return (
    <SettingsForm header={header} documentTitle="Permissions">
      <RequestAccess
        joinExistingOrgEnabled={props.joinExistingOrgEnabled}
        submitRequestAccessSetting={props.submitRequestAccessSetting}
        organizationId={props.params.organizationId}
        projectAccessRequestEnabled={props.projectAccessRequestEnabled}
      />
      <DomainRestrictions
        onSubmit={props.submitDomainRestrictionSettings}
        organizationId={props.params.organizationId}
        restrictedToDomain={props.restrictedToDomain}
      />
      {props.canShowSSOForm ? (
        <SSOForm
          emailExceptions={props.emailExceptions}
          entityId={props.entityId}
          metadataUrl={props.metadataUrl}
          onSubmit={props.submitSSOSettings}
          organizationId={props.params.organizationId}
          ssoActive={props.ssoActive}
          userId={props.userId}
        />
      ) : (
        <SSOUnavailable />
      )}
    </SettingsForm>
  );
}

export default connector(PermissionsEdit);
