// @flow
import { connect } from "react-redux";
import { getCurrentUser } from "abstract-di/selectors";
import { PaginatedTeamMembershipsRequest } from "core/requests/teamMemberships";
import { getOrganization } from "core/selectors/organizations";
import { getTeamPolicy } from "core/selectors/policies";
import { getTeam } from "core/selectors/teams";
import { getUsersForTeam } from "core/selectors/users";
import type { State, Dispatch } from "core/types";
import type { OwnProps, Props, StateProps } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const team = getTeam(state, props.params);
  const organization = getOrganization(state, {
    organizationId: props.params.organizationId,
  });
  const teamMembers = getUsersForTeam(state, {
    teamId: props.params.teamId,
  });

  const currentUser = getCurrentUser(state);
  const teamPolicy = getTeamPolicy(state, { teamId: props.params.teamId });
  const isTeamMembersFirstLoading =
    PaginatedTeamMembershipsRequest.isFirstLoading(state, {
      ...props.params,
      offset: 0,
      limit: 30,
    });
  return {
    currentUser,
    team,
    teamMembers,
    teamPolicy,
    isTeamMembersFirstLoading,
    organizationName: organization ? organization.name : undefined,
  };
}

export default connect<Props, OwnProps, StateProps, _, State, Dispatch>(
  mapStateToProps
);
