// @flow
import { connect } from "react-redux";
import { getCurrentUserId } from "abstract-di/selectors";
import createConnector from "core/lib/createConnector";
import * as Request from "core/models/request";
import type { State, Dispatch } from "core/types";
import { accountFormSubmit } from "web/actions/accountForm";
import {
  loadNotificationSettings,
  notificationSettingsFormSubmit,
} from "web/actions/notificationSettings";
import withForm from "web/containers/withForm";
import { getNotificationSettings } from "web/selectors/notificationSettings";
import { getNotificationSettingsRequest } from "web/selectors/requests";
import { getMarketingConsent } from "web/selectors/session";
import type { StateProps, DispatchProps, ConnectedProps, Props } from ".";

function mapStateToProps(state: State): StateProps {
  const settingsRequest = getNotificationSettingsRequest(state);
  const userId = getCurrentUserId(state);
  const notificationSettings = userId
    ? getNotificationSettings(state, {
        userId,
      })
    : undefined;

  return {
    notificationSettings,
    marketingConsent: getMarketingConsent(state),
    isLoading: Request.isLoading(settingsRequest) || !notificationSettings,
    hasError: Request.hasError(settingsRequest),
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    onMount: () => dispatch(loadNotificationSettings()),
    onSubmit: async (formId, values) => {
      await dispatch(notificationSettingsFormSubmit(formId, values));
      await dispatch(accountFormSubmit(formId, values));
    },
  };
}

export default createConnector<Props, {||}>(
  connect<ConnectedProps, {||}, StateProps, DispatchProps, State, Dispatch>(
    mapStateToProps,
    mapDispatchToProps
  ),
  (Component) =>
    withForm(Component, "notification-settings-form", (props: Props) => ({
      marketingConsent: props.marketingConsent,
      emailEnabled:
        props.notificationSettings && !!props.notificationSettings.emailEnabled,
    }))
);
