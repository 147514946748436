// @flow
import * as React from "react";
import Button from "core/components/Button";
import ButtonLink from "core/components/ButtonLink";
import Input from "core/components/Input";
import Note from "core/components/Note";
import { push } from "core/lib/location";
import { V3Link as Link } from "core/lib/router";
import type { ReactRouterLocation } from "core/types";
import OnboardingForm from "web/components/OnboardingForm";
import OnboardingPage from "web/components/OnboardingPage";
import withForm from "web/containers/withForm";
import { invitation, invitationPath } from "web/routeHelpers";
import type { FormProps, Invitation } from "web/types";
import style from "./style.scss";

export type OwnProps = {|
  location: ReactRouterLocation,
|};

export type StateProps = {|
  invitation: Invitation,
  alreadyAccepted?: boolean,
  confirmedEmail?: string,
  currentUserEmail?: string,
|};

export type DispatchProps = {|
  sendEmailForInvitation: (
    formId: string,
    token: string,
    email: string
  ) => void,
|};

export type ConnectedProps = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
  ...FormProps,
};

function checkDomainValidity(email: ?string, domain: string) {
  if (!domain || !email) {
    return false;
  }

  const domains = domain.toLowerCase().split(",");
  const splits = email.toLowerCase().split("@");
  if (splits.length !== 2) {
    return false;
  }
  if (domains.indexOf(splits[1]) === -1) {
    return false;
  }
  return true;
}

function RestrictedDomainsJSX(restrictedToDomain: string): React.Node {
  const restrictedDomains = restrictedToDomain.split(",");
  if (restrictedDomains.length === 1) {
    return <strong>{restrictedDomains[0]}</strong>;
  } else {
    return (
      <React.Fragment>
        <strong>{restrictedDomains.slice(0, -1).join(", ")}</strong>, or{" "}
        <strong>{restrictedDomains[restrictedDomains.length - 1]}</strong>
      </React.Fragment>
    );
  }
}

class InvitationDomainRestricted extends React.Component<Props> {
  handleChange = (event: SyntheticInputEvent<>) => {
    this.props.form.onChange(this.props.form.id, {
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (event: SyntheticInputEvent<>) => {
    event.preventDefault();

    this.props.sendEmailForInvitation(
      this.props.form.id,
      this.props.invitation.token,
      this.props.form.values.email
    );
  };

  handleRedirect = () => {
    if (this.props.alreadyAccepted) {
      push(invitationPath(this.props.invitation.token, "confirmation"));
    }
  };

  resetForm = () => {
    this.props.form.onChange(this.props.form.id, getDefaultValues(this.props));
  };

  goToInvitation = () => {
    push(this.invitationPath);
  };

  get invitationPath() {
    return invitation(this.props.invitation.token);
  }

  componentDidMount() {
    this.handleRedirect();
  }

  componentDidUpdate() {
    this.handleRedirect();
  }

  renderEmailSent() {
    return (
      <div>
        <p>
          We’ve sent a confirmation email to{" "}
          <strong>{this.props.form.values.email}</strong>. It should arrive in a
          couple of minutes
        </p>
        <p>
          Go back to the <Link to="/">Abstract homepage</Link>
        </p>
        <Note>
          Email didn’t arrive?{" "}
          <ButtonLink className={style.greyLink} onClick={this.resetForm}>
            Try Again
          </ButtonLink>
        </Note>
      </div>
    );
  }

  renderConfirmation() {
    return (
      <OnboardingForm
        onSubmit={this.goToInvitation}
        submitText="Continue to Invitation"
      >
        <p>
          Thank you for confirming your email address
          {this.props.confirmedEmail && (
            <strong> {this.props.confirmedEmail}</strong>
          )}
          .
        </p>
      </OnboardingForm>
    );
  }

  renderForm() {
    const { currentUserEmail, form, invitation } = this.props;
    if (invitation.satisfiedDomainRestriction) {
      return this.renderConfirmation();
    }

    const footer = (
      <Button
        nude
        tint
        component={Link}
        icon="chevron-large-left"
        to={this.invitationPath}
      >
        Back
      </Button>
    );

    const renderErrors = (form) => {
      if (!form.errors) {
        return false;
      }
      if (form.errors.email) {
        const errorsArray = form.errors.email.map((error) => {
          switch (error) {
            case "has already been taken":
              return "This email has already been taken.";
            case "domain restricted":
              return "You must use an email with a domain that has been approved by your admin.";
            default:
              return error;
          }
        });
        return errorsArray;
      } else if (form.error) {
        return form.error.body.error;
      }
    };
    const errors = renderErrors(form);

    const friendlyDomainList = invitation.restrictedToDomain
      ? RestrictedDomainsJSX(invitation.restrictedToDomain)
      : "";

    const currentUserEmailIsValid = checkDomainValidity(
      currentUserEmail,
      invitation.restrictedToDomain
    );

    return (
      <OnboardingForm
        onSubmit={this.handleSubmit}
        submitText={
          form.isSubmitting ? "Sending Confirmation…" : "Send Confirmation"
        }
        disabled={form.isSubmitting}
        footer={footer}
      >
        <p>
          Please enter your email address for{" "}
          <strong>{invitation.organizationName}</strong>.
        </p>
        {!invitation.satisfiedDomainRestriction && !currentUserEmailIsValid && (
          <p>
            You’ll need to add an alternate email to your account from{" "}
            {friendlyDomainList ? (
              <React.Fragment>
                one of the following domains: {friendlyDomainList}
              </React.Fragment>
            ) : (
              "an approved domain."
            )}
          </p>
        )}
        <p>You will receive an email with a confirmation link.</p>
        <Input
          label="Email address"
          name="email"
          defaultValue={form.values.email}
          error={errors}
          placeholder={`email@${invitation.restrictedToDomain.split(",")[0]}`}
          onChange={this.handleChange}
          requiredTag
          required
        />
      </OnboardingForm>
    );
  }

  render() {
    const { form, invitation } = this.props;
    if (!invitation.id) {
      return null;
    }
    return (
      <OnboardingPage
        heading={
          form.submitRequestSucceeded
            ? "Confirmation Email Sent"
            : "Confirm Email Address"
        }
      >
        {form.submitRequestSucceeded
          ? this.renderEmailSent()
          : this.renderForm()}
      </OnboardingPage>
    );
  }
}

function getDefaultValues({ currentUserEmail, invitation }: Props) {
  const defaultIsValid = checkDomainValidity(
    currentUserEmail,
    invitation.restrictedToDomain
  );
  return {
    email: defaultIsValid && currentUserEmail ? currentUserEmail : "",
  };
}

export default withForm<ConnectedProps>(
  InvitationDomainRestricted,
  (props) => `invitation-${props.invitation.id}-add-email-form`,
  getDefaultValues
);
