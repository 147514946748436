// @flow
import queryString from "query-string";
import { RECURLY_SUBDOMAIN } from "../config";
import type { BranchFilter } from "../types";

export function fetchChangeset(
  projectId: string,
  branchId: string,
  commitSha?: string
) {
  if (!commitSha) {
    return fetchBranchChangeset(projectId, branchId);
  }
  return `projects/${projectId}/branches/${branchId}/commits/${commitSha}/changeset`;
}

export function fetchBranchChangeset(projectId: string, branchId: string) {
  return `projects/${projectId}/branches/${branchId}/changeset`;
}

export function fetchCollection(
  projectId: string,
  collectionId: string,
  query: {|
    layersPerCollection?: number | "all",
  |}
) {
  query = queryString.stringify(query);
  return `projects/${projectId}/collections/${collectionId}?${query}`;
}

export function fetchCollections(
  projectId: string,
  query: {|
    branch_id?: string,
    layersPerCollection?: number | "all",
    limit?: number,
    offset?: number,
  |}
) {
  return `projects/${projectId}/collections?${queryString.stringify(query)}`;
}

export function fetchCommits(
  projectId: string,
  branchId: string,
  fileId?: string,
  layerId?: string
) {
  if (fileId && layerId) {
    return fetchLayerCommits(projectId, branchId, fileId, layerId);
  }

  return `projects/${projectId}/branches/${branchId}/commits`;
}

export function fetchLayerCommits(
  projectId: string,
  branchId: string,
  fileId: string,
  layerId: string
) {
  return `projects/${projectId}/branches/${branchId}/commits?fileId=${fileId}&layerId=${layerId}`;
}

export function fetchLayers(
  projectId: string,
  branchId: string,
  fileId: string,
  params: { pageId?: string, limit: number, offset: number }
) {
  return `projects/${projectId}/branches/${branchId}/files/${fileId}/layers?${queryString.stringify(
    params
  )}`;
}

export function fetchBranches(
  projectId: string,
  query: {| filter: BranchFilter |}
) {
  return `projects/${projectId}/branches?${queryString.stringify(query)}`;
}

export function fetchBranch(projectId: string, branchId: string) {
  return `projects/${projectId}/branches/${branchId}`;
}

export function requestProjectAccess(projectId: string) {
  return `projects/${projectId}/access_requests`;
}

export function acceptProjectAccessRequest(projectId: string, token: string) {
  return `projects/${projectId}/access_requests/${token}/accept`;
}

export function fetchSession() {
  return "me";
}

export function fetchAccount() {
  return "account";
}

export function previewAccount() {
  return "account/preview";
}

export function fetchNotificationSettings() {
  return "account/notification_settings";
}

export function fetchZendeskToken() {
  return "zendesk";
}

export function fetchEmails() {
  return "emails";
}

export function fetchEmail(emailId: string) {
  return `emails/${emailId}`;
}

export function sendEmailVerification(emailIdOrVerificationToken: string) {
  return `emails/${emailIdOrVerificationToken}/send_verification`;
}

export function verifyEmail(verificationToken: string) {
  return `emails/${verificationToken}/verify`;
}

export function setEmailAsPrimary(emailId: string) {
  return `emails/${emailId}/make_primary`;
}

export function recurlyAccountManagement(loginToken: string) {
  return `https://${RECURLY_SUBDOMAIN}.recurly.com/account/${loginToken}`;
}

export function oAuthApplications(organizationId: string) {
  return `organizations/${organizationId}/applications`;
}

export function oAuthApplicationInfo(appId: string) {
  return `oauth_applications/${appId}`;
}

export function oAuthorize() {
  return `auth/authorize`;
}
