// @flow
import createCachedSelector from "@elasticprojects/re-reselect";
import empty from "empty";
import { findIndex, pick, values } from "lodash";
import { createSelector } from "reselect";
import { CommitTypes } from "core/gitConstants";
import { Abstract } from "core/lib/abstract";
import { getBranch } from "core/selectors/branches";
import type { Commit, State } from "core/types";

export function getEntityId(
  props:
    | Abstract.BranchDescriptor
    | Abstract.LayerDescriptor
    | Abstract.LayerVersionDescriptor
) {
  return [
    props.projectId,
    props.branchId,
    props.fileId || "",
    props.layerId || "",
  ]
    .filter((f) => Boolean(f))
    .join("-");
}

function cacheCommitsByEntity(
  state: State,
  props: Abstract.BranchDescriptor | Abstract.LayerDescriptor
) {
  const entityId = getEntityId(props);
  return `${entityId}-commits`;
}

export function getCommit(state: State, { sha }: { sha: string }): ?Commit {
  return state.commits[sha];
}

export const getCommitEntities = (state: State) => {
  return state.commits;
};

export function getCommitIds(
  state: State,
  props: Abstract.BranchDescriptor | Abstract.LayerDescriptor
): string[] {
  const entityId = getEntityId(props);

  return state.commitsPagination[entityId]
    ? state.commitsPagination[entityId].commitShas
    : empty.array;
}

export const getCommitsForEntity: (
  state: State,
  props: Abstract.BranchDescriptor | Abstract.LayerDescriptor
) => Commit[] = createCachedSelector(
  getCommitEntities,
  getCommitIds,
  (commits, commitIds) => {
    return values(pick(commits, commitIds));
  }
)(cacheCommitsByEntity);

export function getCommitsForEntityHasMore(
  state: State,
  props: Abstract.BranchDescriptor | Abstract.LayerDescriptor
): boolean {
  const entityId = getEntityId(props);
  const pagination = state.commitsPagination[entityId];

  if (!pagination) {
    return false;
  }

  return pagination.hasMore;
}

// This is similar to the behavior in getResolvedLayerDescriptor,
// but the functionality is broken out here to avoid a require loop.
function _getResolvedCommitShaForLayer(
  state: State,
  props: Abstract.LayerVersionDescriptor
) {
  const { projectId, branchId, sha, fileId, layerId } = props;
  if (sha === "latest") {
    return getLatestCommitShaForLayer(state, {
      projectId,
      branchId,
      fileId,
      layerId,
    });
  } else {
    return sha;
  }
}

export const getLatestCommitShaForLayer: (
  state: State,
  props: Abstract.LayerDescriptor
) => ?string = createCachedSelector(getCommitsForEntity, (commits) => {
  const latestCommit = commits[0];
  return latestCommit ? latestCommit.sha : undefined;
})(cacheCommitsByEntity);

export const getCommitEntitiesForLayer: (
  state: State,
  props: Abstract.LayerDescriptor
) => { [sha: string]: Commit } = createCachedSelector(
  getCommitEntities,
  getCommitIds,
  (commits, commitIds) => pick(commits, commitIds)
)(cacheCommitsByEntity);

export function getCommitsForLayer(
  state: State,
  {
    projectId,
    branchId,
    fileId,
    layerId,
  }: Abstract.LayerDescriptor | Abstract.LayerVersionDescriptor
) {
  return getCommitsForEntity(state, { projectId, branchId, fileId, layerId });
}

export function getCommitForLayer(
  state: State,
  params: Abstract.LayerVersionDescriptor
) {
  const { projectId, branchId, fileId, layerId } = params;
  const resolvedSha = _getResolvedCommitShaForLayer(state, params);
  if (resolvedSha) {
    return getCommit(state, {
      projectId,
      branchId,
      sha: resolvedSha,
      fileId,
      layerId,
    });
  }
}

export const getPreviousLayerCommit: (
  state: State,
  props: Abstract.LayerVersionDescriptor
) => ?Commit = createSelector(
  getCommitsForLayer,
  _getResolvedCommitShaForLayer,
  getBranch,
  (layerCommits, sha, branch) => {
    if (!layerCommits || !branch) {
      return;
    }

    const commitIndex = findIndex(layerCommits, {
      sha,
    });

    return layerCommits[commitIndex + 1];
  }
);

type GetCommitIsBeforeUpdateParams = {|
  projectId: string,
  branchId: string,
  sha: string,
|};

export const getCommitIsBeforeUpdate: (
  state: State,
  params: GetCommitIsBeforeUpdateParams
) => boolean = createCachedSelector(
  getCommit,
  (state: State, params: GetCommitIsBeforeUpdateParams) =>
    getCommitsForEntity(state, {
      projectId: params.projectId,
      branchId: params.branchId,
    }),
  (commit, commits) => {
    const commitIndex = commits.indexOf(commit);
    if (commitIndex <= 0) {
      return false;
    }

    for (let i = commitIndex - 1; i >= 0; i--) {
      if (commits[i].type === CommitTypes.UPDATE) {
        return true;
      }
    }

    return false;
  }
)((state, params) => [params.projectId, params.branchId, params.sha].join("-"));
