// @flow
import * as React from "react";
import Helmet from "react-helmet";
import Centered from "core/components/Centered";
import NotFound from "core/components/Empty/NotFound";

export default function NotFoundContainer() {
  return (
    <Centered>
      <Helmet>
        <title>Not Found</title>
      </Helmet>
      <NotFound />
    </Centered>
  );
}
