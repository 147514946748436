// @flow
import * as Abstract from "abstract-sdk";
import * as React from "react";
import { useSelector } from "react-redux";
import Avatar from "core/components/Avatar";
import BranchStatusBadges from "core/components/BranchStatusBadges";
import Flex from "core/components/Flex";
import Time from "core/components/Time";
import { descriptorPath } from "core/lib/routes";
import { formattedName, isMaster } from "core/models/branch";
import { getBranch } from "core/selectors/branches";
import { getProject } from "core/selectors/projects";
import { getBranchReview } from "core/selectors/reviews";
import Result, { type CommonProps } from "./Result";
import style from "./style.scss";

type Props = {|
  ...CommonProps,
  descriptor: Abstract.BranchDescriptor,
|};

export default function ResultBranch(props: Props) {
  const { descriptor, selected, ...rest } = props;
  const { projectId, branchId } = props.descriptor;

  const project = useSelector((state) => getProject(state, { projectId }));
  const branch = useSelector((state) =>
    getBranch(state, { projectId, branchId })
  );
  const branchReview = useSelector((state) =>
    getBranchReview(state, {
      projectId,
      branchId,
    })
  );

  if (!branch) {
    return null;
  }

  return (
    <Result
      to={descriptorPath(descriptor)}
      icon={isMaster(branch) ? "master" : "branch"}
      title={
        <Flex align="center">
          {isMaster(branch) ? null : (
            <Avatar
              userId={branch.userId}
              size={16}
              className={style.avatar}
              showNamePopover
            />
          )}
          {formattedName(branch)}
          <BranchStatusBadges
            branch={branch}
            light={selected}
            readOnly
            className={style.resultStatusBadges}
            selected={selected}
            branchReviewStatus={branchReview ? branchReview.status : undefined}
          />
        </Flex>
      }
      context={
        project ? (
          <React.Fragment>
            {project.name} &middot; <Time date={branch.updatedAt} />
          </React.Fragment>
        ) : (
          <Time date={branch.updatedAt} />
        )
      }
      selected={selected}
      {...rest}
    />
  );
}
