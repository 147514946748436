// @flow
import * as React from "react";
import Button from "core/components/Button";
import DialogForm from "core/components/DialogForm";
import Input from "core/components/Input";
import { CONFIRM_DELETE } from "../../constants";
import InlineError from "../InlineError";

type Props = {
  children: React.Node,
  loading: boolean,
  error: boolean,
  onSubmit: () => void,
  dialogMessage: React.Node,
  dialogButtonLabel: string,
  title: string,
  dialogLoading?: string,
  className?: string,
};

type State = { open: boolean, confirmed: boolean };

export default class DangerButton extends React.Component<Props, State> {
  state = {
    open: false,
    confirmed: false,
  };

  toggleOpen = () => this.setState((state) => ({ open: !state.open }));

  handleConfirm = ({ target }: SyntheticInputEvent<>) => {
    this.setState({
      confirmed: target.value === CONFIRM_DELETE,
    });
  };

  handleSubmit = async (event: SyntheticEvent<>) => {
    event.preventDefault();

    this.props.onSubmit();
  };

  renderDialog() {
    return (
      <DialogForm
        dangerous
        isOpen={this.state.open}
        onClose={() => {
          this.toggleOpen();
          this.setState({ confirmed: false });
        }}
        onSubmit={this.handleSubmit}
        disabled={!this.state.confirmed || this.props.loading}
        loading={this.props.loading}
        primaryButton={this.props.dialogButtonLabel}
        title={this.props.title}
        secondaryButton="Cancel"
      >
        {this.props.dialogMessage}
        <Input
          label={`Enter "CONFIRM DELETE" to proceed`}
          onChange={this.handleConfirm}
          autoComplete="off"
          autoFocus
        />
        {this.props.error && <InlineError />}
      </DialogForm>
    );
  }

  render() {
    return (
      <React.Fragment>
        <Button
          danger
          className={this.props.className}
          onClick={this.toggleOpen}
        >
          {this.props.children}
        </Button>
        {this.state.open && this.renderDialog()}
      </React.Fragment>
    );
  }
}
