// @flow
import * as React from "react";
import Button from "core/components/Button";
import CopyToClipboard from "core/components/CopyToClipboard";
import DialogForm from "core/components/DialogForm";
import Flex from "core/components/Flex";
import Input from "core/components/Input";
import Note from "core/components/Note";
import style from "./style.scss";

type Props = {
  error?: boolean,
  isSubmitting: boolean,
  isOpen: boolean,
  onSubmit: ({ description: string }) => *,
  requestClose: () => *,
};

type State = {
  description: string,
  token?: string,
  copied: boolean,
};

export default class CreateAccessToken extends React.Component<Props, State> {
  dialogForm: *;
  copyToClipboardButton: *;

  state = {
    description: "",
    token: undefined,
    copied: false,
  };

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState({
        copied: false,
        description: "",
        token: undefined,
      });
    }
  }

  handleSubmit = async (ev: SyntheticEvent<>) => {
    ev.preventDefault();
    if (this.state.token) {
      this.props.requestClose();
      return;
    }

    const { data } = await this.props.onSubmit({
      description: this.state.description,
    });
    const { token } = data.access_tokens[0];
    this.setState({ token });
  };

  handleInputChange = ({ target }: SyntheticInputEvent<>) => {
    this.setState({ [target.name]: target.value });
    if (this.dialogForm) {
      this.dialogForm.checkValid();
    }
  };

  handleTokenCopied = () => {
    this.setState({ copied: true });
  };

  render() {
    return (
      <DialogForm
        ref={(ref) => (this.dialogForm = ref)}
        onClose={this.props.requestClose}
        onSubmit={this.handleSubmit}
        disabled={
          this.props.isSubmitting || (!!this.state.token && !this.state.copied)
        }
        primaryButton={this.state.token ? "Done" : "Create API Token"}
        title="Create a new API token"
        isOpen={this.props.isOpen}
      >
        {() =>
          this.state.token ? (
            <React.Fragment>
              <Flex>
                <Input
                  autoFocus
                  label="Token"
                  name="token"
                  value={this.state.token}
                  wrapperClass={style.token}
                  disabled
                />
                <div className={style.copyButtonWrapper}>
                  <CopyToClipboard
                    toast="Token copied"
                    value={this.state.token}
                    onSuccess={this.handleTokenCopied}
                  >
                    {(setClickableRef) => (
                      <Button
                        innerRef={setClickableRef}
                        primary={!this.state.copied}
                        large
                      >
                        Copy
                      </Button>
                    )}
                  </CopyToClipboard>
                </div>
              </Flex>
              <p>
                <Note>
                  Your API token won’t be displayed again, make sure to copy it
                  to a safe location before closing this dialog.
                </Note>
              </p>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Input
                autoFocus
                label="Description"
                autoComplete="off"
                name="description"
                value={this.state.description}
                disabled={this.props.isSubmitting}
                onChange={this.handleInputChange}
                error={
                  this.props.error && "An unexpected error occurred, try again?"
                }
                placeholder="e.g. production"
                requiredTag
                required
                qaSelector="createAccessTokenInput"
              />
              <p>
                <Note>
                  Once created your API token won’t be displayed again, make
                  sure to name it something recognizable.
                </Note>
              </p>
            </React.Fragment>
          )
        }
      </DialogForm>
    );
  }
}
