// @flow
import * as React from "react";
import { connect } from "react-redux";
import type { ActivityListBreakpoints } from "core/components/Activity/types";
import Annotation from "core/components/Annotation";
import PreviewImage from "core/components/PreviewImage";
import PreviewLoader from "core/components/PreviewLoader";
import Transparency from "core/components/Transparency";
import { Abstract } from "core/lib/abstract";
import { V3Link as Link } from "core/lib/router";
import { LayerFetchRequest } from "core/requests/layers";
import { getLayer } from "core/selectors/layers";
import type {
  Comment,
  LocationDescriptor,
  Annotation as TAnnotation,
  Layer,
  State,
  Dispatch,
} from "core/types";
import style from "./style.scss";

const TINY_IMAGE = 160;
const noop = () => {};

type OwnProps = {|
  comment: Comment,
  to?: LocationDescriptor,
  onClick?: () => void,
  className?: string,
  layerName?: string,
  isCroppedAnnotationPreviewsEnabled: boolean,
  breakpoint: ActivityListBreakpoints,
|};

type StateProps = {|
  layer: ?Layer,
|};

type DispatchProps = {|
  onLoad: () => void,
|};

type Props = {
  ...StateProps,
  ...DispatchProps,
  ...OwnProps,
};

function CommentPreview({
  comment,
  className,
  layerName,
  breakpoint,
  layer,
  onLoad,
  isCroppedAnnotationPreviewsEnabled,
  ...props
}: Props) {
  React.useEffect(onLoad, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PreviewLoader
      projectId={comment.projectId}
      commitSha={comment.commitSha}
      fileId={comment.fileId}
      layerId={comment.layerId}
      scale={0.5}
    >
      {(src, loading, error) => {
        if (!layer) {
          return null;
        }

        const dimensions = {
          height: layer.height,
          width: layer.width,
        };
        const {
          imageHeight,
          imageWidth,
          cropHeight,
          cropWidth,
          translateImageX,
          translateImageY,
        } = isCroppedAnnotationPreviewsEnabled
          ? calculateCroppedSizes(dimensions, comment.annotation, breakpoint)
          : calculateNonCroppedSizes(dimensions, breakpoint);

        // If it turns out that we'll need to scale the image _up_ (image width > maxWidth
        // returned from calculateSize) to fill the container, just show the original image size.
        // ref: https://github.com/goabstract/ui/pull/1716#issuecomment-531033898
        const showOriginalSize =
          imageHeight === TINY_IMAGE && imageWidth === TINY_IMAGE;
        const isSmallImage =
          dimensions.height < TINY_IMAGE && dimensions.width < TINY_IMAGE;

        const scale = isSmallImage
          ? 100
          : (imageWidth / dimensions.width) * 100;

        return (
          <div
            className={style.previewCropper}
            style={{
              height: cropHeight,
              width: cropWidth,
            }}
          >
            <Link
              {...props}
              className={style.wrapper}
              style={{
                height:
                  showOriginalSize && !isSmallImage
                    ? dimensions.height
                    : imageHeight,
                width:
                  showOriginalSize && !isSmallImage
                    ? dimensions.width
                    : imageWidth,
              }}
            >
              <Transparency fixed={false} className={style.transparency}>
                <div
                  className={style.preview}
                  style={{
                    transform: `translate(${translateImageX}px, ${translateImageY}px)`,
                  }}
                >
                  <div className={style.previewInner}>
                    <PreviewImage
                      src={src}
                      alt={layerName}
                      cover={true}
                      width={showOriginalSize ? dimensions.width : undefined}
                      height={showOriginalSize ? dimensions.height : undefined}
                      errorText={error}
                      annotationComponent={
                        comment.annotation && (
                          <Annotation
                            onOuterClick={noop}
                            annotation={comment.annotation}
                            editing={false}
                            scale={scale}
                            alwaysShowBoundary
                          />
                        )
                      }
                    />
                  </div>
                </div>
              </Transparency>
            </Link>
          </div>
        );
      }}
    </PreviewLoader>
  );
}

const sizes = {
  xxsmall: 244,
  xsmall: 299,
  small: 338,
  medium: 580,
  large: 640,
};

type TCalculatedPreviewImageSize = {
  imageHeight: number,
  imageWidth: number,
  cropHeight: number,
  cropWidth: number,
  translateImageX: number,
  translateImageY: number,
};

function calculateCroppedSizes(
  dimensionsOfImage: { height: number, width: number },
  annotation?: TAnnotation,
  breakpoint: ActivityListBreakpoints
): TCalculatedPreviewImageSize {
  const { height, width } = dimensionsOfImage;

  // The current max height/width an image can be at n breakpoint.
  const max = sizes[breakpoint] || sizes["large"];

  const values = {
    imageHeight: 0,
    imageWidth: 0,
    cropHeight: 0,
    cropWidth: 0,
    translateImageX: 0,
    translateImageY: 0,
  };

  // Don't show anything when image hasn't loaded.
  if (height === 0 && width === 0) {
    return values;
  }

  // Display the minimum height/width if image is tiny.
  if (height <= TINY_IMAGE && width <= TINY_IMAGE) {
    values.imageHeight = TINY_IMAGE;
    values.imageWidth = TINY_IMAGE;
    values.cropHeight = TINY_IMAGE;
    values.cropWidth = TINY_IMAGE;
    return values;
  }

  // 1. Scale normally (by breakpoint) if there is no annotation.
  if (!annotation) {
    if (height > width) {
      const scaledWidth = (width / height) * max;
      values.imageHeight = max;
      values.imageWidth = scaledWidth;
      values.cropHeight = max;
      values.cropWidth = scaledWidth;
      return values;
    }

    if (width > height) {
      const scaledHeight = (height / width) * max;
      values.imageHeight = scaledHeight;
      values.imageWidth = max;
      values.cropHeight = scaledHeight;
      values.cropWidth = max;
      return values;
    }

    values.imageHeight = max;
    values.imageWidth = max;
    values.cropHeight = max;
    values.cropWidth = max;
    return values;
  }

  // 2. If there is an annotation, crop image to best fit the annotation.
  const isTallImage = height > width;
  const isWideImage = width > height;

  if (isTallImage) {
    if (width > max) {
      values.imageWidth = max;
      values.imageHeight = (height / width) * max;
      values.cropHeight = values.imageHeight > max ? max : values.imageHeight;
      values.cropWidth = max;
    } else {
      values.imageWidth = width;
      values.imageHeight = height;
      values.cropHeight = values.imageHeight > max ? max : values.imageHeight;
      values.cropWidth = width;
    }

    // 2a. ⭐️ If annotation is larger than the cropped height, we'll scale
    //     the imageHeight to that of the annotation's and the width proportionally.
    const scaledHeightOfAnnotation =
      (values.imageHeight / height) * annotation.height;

    if (scaledHeightOfAnnotation > values.cropHeight) {
      values.imageWidth =
        (values.cropHeight / values.imageHeight) * values.imageWidth;
      values.cropWidth = values.imageWidth;
      values.imageHeight = values.cropHeight;
    }
  }

  if (isWideImage) {
    if (height > max) {
      values.imageHeight = max;
      values.imageWidth = (width / height) * max;
      values.cropHeight = max;
      values.cropWidth = values.imageWidth > max ? max : values.imageWidth;
    } else {
      values.imageHeight = height;
      values.imageWidth = width;
      values.cropHeight = height;
      values.cropWidth = values.imageWidth > max ? max : values.imageWidth;
    }

    // 2b. ⭐️ If annotation is larger than the cropped width, we'll scale
    //     the imageWidth to that of the annotation's and the height proportionally.
    const scaledWidthOfAnnotation =
      (values.imageWidth / width) * annotation.width;

    if (scaledWidthOfAnnotation > values.cropWidth) {
      values.imageHeight =
        (values.cropWidth / values.imageWidth) * values.imageHeight;
      values.cropHeight = values.imageHeight;
      values.imageWidth = values.cropWidth;
    }
  }

  // 3. If the image is tall or wide, we'll center the annotation as best as possible. ☯️
  if (isTallImage || isWideImage) {
    if (values.imageWidth !== values.cropWidth) {
      // Calculate amount to translate image on the x axis.
      const scale = values.imageWidth / width;

      const centerOfAnnotation = scale * (annotation.x + annotation.width / 2);
      const centerOfCroppedImage = centerOfAnnotation - values.cropWidth / 2;

      if (centerOfCroppedImage >= 0) {
        const diff =
          values.imageWidth - centerOfCroppedImage - values.cropWidth;

        values.translateImageX =
          diff >= 0
            ? centerOfCroppedImage * -1
            : (centerOfCroppedImage + diff) * -1;
      }
    }

    if (values.imageHeight !== values.cropHeight) {
      // Calculate amount to translate image on the y axis.
      const scale = values.imageHeight / height;
      const centerOfAnnotation = scale * (annotation.y + annotation.height / 2);
      const centerOfCroppedImage = centerOfAnnotation - values.cropHeight / 2;

      if (centerOfCroppedImage >= 0) {
        const diff =
          values.imageHeight - centerOfCroppedImage - values.cropHeight;

        values.translateImageY =
          diff >= 0
            ? centerOfCroppedImage * -1
            : (centerOfCroppedImage + diff) * -1;
      }
    }
  }

  if (!isTallImage && !isWideImage) {
    // If we're here, the image is a square - both height/width are
    // the same. We'll use height for reference.
    const size = height > max ? max : height;
    values.imageHeight = size;
    values.imageWidth = size;
    values.cropHeight = size;
    values.cropWidth = size;
  }

  return values;
}

function calculateNonCroppedSizes(
  dimensionsOfImage: { height: number, width: number },
  breakpoint: ActivityListBreakpoints
): TCalculatedPreviewImageSize {
  const { height, width } = dimensionsOfImage;

  const values = {
    imageHeight: 0,
    imageWidth: 0,
    cropHeight: 0,
    cropWidth: 0,
    translateImageX: 0,
    translateImageY: 0,
  };

  // Don't show anything when image hasn't loaded.
  if (height === 0 && width === 0) {
    return values;
  }

  // Minimum height/width if image is tiny.
  if (height <= TINY_IMAGE && width <= TINY_IMAGE) {
    values.imageHeight = TINY_IMAGE;
    values.imageWidth = TINY_IMAGE;
    values.cropHeight = TINY_IMAGE;
    values.cropWidth = TINY_IMAGE;
    return values;
  }

  const max = sizes[breakpoint] || sizes["large"];

  if (height > width) {
    const scaledWidth = (width / height) * max;
    values.imageHeight = max;
    values.imageWidth = scaledWidth;
    values.cropHeight = max;
    values.cropWidth = scaledWidth;
    return values;
  }

  if (width > height) {
    const scaledHeight = (height / width) * max;
    values.imageHeight = scaledHeight;
    values.imageWidth = max;
    values.cropHeight = scaledHeight;
    values.cropWidth = max;

    return values;
  }

  return values;
}

function getCommentLayerDescriptor(
  comment: Comment
): Abstract.LayerVersionDescriptor {
  return {
    projectId: comment.projectId,
    branchId: comment.branchId,
    sha: comment.commitSha,
    fileId: comment.fileId,
    layerId: comment.layerId,
  };
}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const layer = getLayer(state, getCommentLayerDescriptor(props.comment));

  return { layer };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    onLoad: () => {
      dispatch(
        LayerFetchRequest.perform({
          params: getCommentLayerDescriptor(props.comment),
        })
      );
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(
  mapStateToProps,
  mapDispatchToProps
)(CommentPreview);
