// @flow
import classnames from "classnames";
import * as React from "react";
import Button from "core/components/Button";
import Empty from "core/components/Empty";
import Offline from "core/components/Empty/Offline";
import { FileItem, Placeholder } from "core/components/FileItem";
import Flex from "core/components/Flex";
import OverviewCard from "core/components/ProjectOverview/OverviewCard";
import { isMac, isWeb } from "core/lib/platform";
import { branchPath, projectPath } from "core/lib/routes";
import { BRANCH_ID_MASTER } from "core/models/branch";
import type { File as TFile } from "core/types";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  addFileButton?: React.Node,
  mobile?: boolean,
  projectId: string,
|};

export type StateProps = {|
  branchHead: string,
  defaultBranchName: string,
  files: TFile[],
  isLoading: boolean,
  isLoadingStrict: boolean,
  isOffline: boolean,
  isNewProject: boolean,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
};

type State = {
  selectedFileId: ?string,
};

class DefaultBranchFiles extends React.Component<Props, State> {
  state = {
    selectedFileId: null,
  };

  handleClickFile = (fileId: string) => {
    if (this.state.selectedFileId === fileId) {
      return;
    }

    this.setState({ selectedFileId: fileId });
  };

  renderOffline = () => {
    return (
      <Offline
        title="Files unavailable offline"
        description="Connect to the internet to load files."
      />
    );
  };

  renderEmpty = () => {
    return (
      <Empty
        className={style.noFiles}
        description="Import or create Sketch files to start working."
        small
      >
        {isWeb && isMac() && (
          <Button
            className={style.button}
            component="a"
            href={`abstract://${projectPath(this.props.projectId)}`}
            external
          >
            Open in Mac app
          </Button>
        )}
      </Empty>
    );
  };

  renderContent = () => {
    if (
      this.props.isOffline &&
      this.props.files.length === 0 &&
      (isWeb || !this.props.isLoadingStrict)
    ) {
      return this.renderOffline();
    }

    if (this.props.isLoading && !this.props.isNewProject) {
      return (
        <React.Fragment>
          <Placeholder className={style.file} />
          <Placeholder className={style.file} />
          <Placeholder className={style.file} />
          <Placeholder className={style.file} />
        </React.Fragment>
      );
    }

    if (this.props.files.length === 0) {
      return this.renderEmpty();
    }

    return (
      <React.Fragment>
        {this.props.files.map((file) => (
          <FileItem
            file={file}
            branchId={BRANCH_ID_MASTER}
            isSelected={this.state.selectedFileId === file.id}
            itemClassName={style.file}
            key={file.id}
            mobile={this.props.mobile}
            onClick={() => {
              this.handleClickFile(file.id);
            }}
          />
        ))}
      </React.Fragment>
    );
  };

  render() {
    const { addFileButton, defaultBranchName, mobile, projectId } = this.props;
    return (
      <OverviewCard
        headerControls={addFileButton}
        heading={`${defaultBranchName} Files`}
        mobile={mobile}
        storageId="ProjectOverview-DefaultBranchFiles"
        to={branchPath(projectId, BRANCH_ID_MASTER, "files")}
        noPadding
      >
        <Flex
          className={classnames(style.files, {
            [style.mobile]: mobile,
          })}
          column
        >
          {this.renderContent()}
        </Flex>
      </OverviewCard>
    );
  }
}

export default connector(DefaultBranchFiles);
