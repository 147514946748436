// @flow
import classNames from "classnames";
import findIndex from "lodash/findIndex";
import findLastIndex from "lodash/findLastIndex";
import * as React from "react";
import window from "core/global/window";
import { modifierKeyPressed } from "core/lib/platform";
import eventInInput from "../../lib/eventInInput";
import KeyCode from "../../lib/keycode";
import Button from "../Button";
import ButtonGroup from "../ButtonGroup";
import type { ZoomState } from "../LayerCanvas";
import style from "./style.scss";

const DEFAULT_ZOOM = 100;
const ZOOM_PERCENTAGE_STEPS = [6, 13, 25, 50, DEFAULT_ZOOM, 200, 400];
export const ZOOM_TO_TOP = Number.MIN_SAFE_INTEGER;

export type Props = {
  zoomState?: ZoomState,
  accelerator?: boolean,
  disabled?: boolean,
  onChange: (zoomState: ZoomState) => void,
  onZoomToFit?: (zoomState: ZoomState) => ZoomState,
  className?: string,
  unstyled?: boolean,
};

type State = {
  zoomState: ZoomState,
};

export default class ZoomPercentageInput extends React.PureComponent<
  Props,
  State,
> {
  static defaultProps = {
    accelerator: true,
  };

  state = {
    zoomState: {
      scale: 0,
      scaledToFit: true,
      offsetX: 0,
      offsetY: 0,
    },
  };

  componentDidMount() {
    if (this.props.accelerator) {
      window.addEventListener("keydown", this.handleKeyDown);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
  }

  getControlledZoomState(props: Props, state: State): ZoomState {
    return props.zoomState !== undefined ? props.zoomState : state.zoomState;
  }

  findCurrentStepIndex(zoomState: ZoomState): number {
    return ZOOM_PERCENTAGE_STEPS.indexOf(zoomState.scale);
  }

  update(callback: (zoomState: ZoomState) => ZoomState) {
    const { zoomState } = this.props;
    if (zoomState !== undefined) {
      if (!this.props.onChange) {
        return;
      }
      this.props.onChange(callback(zoomState));
    } else {
      this.setState((state) => {
        return { zoomState: callback(state.zoomState) };
      });
    }
  }

  /***
   * New offsets need to be calculated for accurate transform
   * dimensions. This is to fix a bug where a user could click an image to zoom,
   * and then use one of the zoom buttons to zoom in/out. The bug was due to the
   * fact that when the image was zoomed in/out, it was using the previous zoom
   * level's offset, and when given the new zoom level, caused a lot of jumping
   * of the ZoomablePreview image
   *
   * see @method ZoomablePreview.positionerStyle
   **/
  getDimensionsOffset(
    axisOffset: number,
    nextScale: number,
    currentScale: number
  ): number {
    const scalePercentage = currentScale / 100;
    return (axisOffset * (nextScale / 100)) / scalePercentage;
  }

  onStepDown = () => {
    this.update((zoomState) => {
      const prevStepIndex = findLastIndex(
        ZOOM_PERCENTAGE_STEPS,
        (step) => step < zoomState.scale
      );

      const prevScale = ZOOM_PERCENTAGE_STEPS[prevStepIndex];

      return prevStepIndex !== -1
        ? {
            scale: prevScale,
            scaledToFit: false,
            offsetX: this.getDimensionsOffset(
              zoomState.offsetX,
              prevScale,
              zoomState.scale
            ),
            offsetY: this.getDimensionsOffset(
              zoomState.offsetY,
              prevScale,
              zoomState.scale
            ),
          }
        : zoomState;
    });
  };

  onStepUp = () => {
    this.update((zoomState) => {
      const nextStepIndex = findIndex(
        ZOOM_PERCENTAGE_STEPS,
        (step) => step > zoomState.scale
      );

      const nextScale = ZOOM_PERCENTAGE_STEPS[nextStepIndex];

      return nextStepIndex !== -1
        ? {
            scale: nextScale,
            scaledToFit: false,
            offsetX: this.getDimensionsOffset(
              zoomState.offsetX,
              nextScale,
              zoomState.scale
            ),
            offsetY: this.getDimensionsOffset(
              zoomState.offsetY,
              nextScale,
              zoomState.scale
            ),
          }
        : zoomState;
    });
  };

  onZoomToFit = () => {
    this.update((zoomState: ZoomState) => {
      if (zoomState.scale !== DEFAULT_ZOOM) {
        // zoom to default before zooming to fit
        if (zoomState.scaledToFit) {
          // if artboard is already zoom to fit, we want to zoom to the top center of artboard
          return {
            scale: DEFAULT_ZOOM,
            scaledToFit: false,
            offsetX: 0,
            offsetY: ZOOM_TO_TOP,
          };
        }
        return {
          scale: DEFAULT_ZOOM,
          scaledToFit: false,
          offsetX: this.getDimensionsOffset(
            zoomState.offsetX,
            DEFAULT_ZOOM,
            zoomState.scale
          ),
          offsetY: this.getDimensionsOffset(
            zoomState.offsetY,
            DEFAULT_ZOOM,
            zoomState.scale
          ),
        };
      } else if (this.props.onZoomToFit) {
        return this.props.onZoomToFit(zoomState);
      }

      return zoomState;
    });
  };

  handleKeyDown = (event: KeyboardEvent) => {
    if (eventInInput(event)) {
      return;
    }

    // CMD+ (Mac) or CTRL+ (non-Mac)
    if (modifierKeyPressed(event) && event.keyCode === KeyCode.KEY_EQUALS) {
      event.preventDefault();
      return this.onStepUp();
    }

    // CMD- (Mac) or CTRL- (non-Mac)
    if (modifierKeyPressed(event) && event.keyCode === KeyCode.KEY_MINUS) {
      event.preventDefault();
      return this.onStepDown();
    }

    // CMD+0 (Mac) or CTRL+0 (non-Mac)
    if (modifierKeyPressed(event) && event.keyCode === KeyCode.KEY_0) {
      event.preventDefault();
      return this.onZoomToFit();
    }
  };

  render() {
    const zoomState = this.getControlledZoomState(this.props, this.state);
    const currentStepIndex = this.findCurrentStepIndex(zoomState);

    const inputs = [
      <Button
        key="zoom-out"
        icon="zoom-out"
        className={style.decrementButton}
        disabled={this.props.disabled || currentStepIndex === 0}
        onClick={this.onStepDown}
        nude={this.props.unstyled}
        title="Zoom out"
      />,
      <Button
        key="percentage"
        className={style.zoomToFitButton}
        onClick={this.onZoomToFit}
        disabled={this.props.disabled}
        nude={this.props.unstyled}
      >
        {zoomState.scaledToFit && zoomState.scale < 100
          ? "Fit"
          : `${Math.ceil(zoomState.scale)}%`}
      </Button>,
      <Button
        key="zoom-in"
        icon="zoom-in"
        className={style.incrementButton}
        onClick={this.onStepUp}
        disabled={
          this.props.disabled ||
          currentStepIndex === ZOOM_PERCENTAGE_STEPS.length - 1
        }
        nude={this.props.unstyled}
        title="Zoom in"
      />,
    ];

    if (this.props.unstyled) {
      return inputs;
    }

    return (
      <ButtonGroup className={classNames(style.group, this.props.className)}>
        {inputs}
      </ButtonGroup>
    );
  }
}
