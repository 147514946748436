// @flow
import { connect } from "react-redux";
import { sketchLibrariesSupported } from "abstract-di/selectors";
import { dismissDialog } from "core/actions/dialogs";
import { getBranch } from "core/selectors/branches";
import {
  canShowLargeCommitWarning,
  featureEnabledFileFormats,
} from "core/selectors/features";
import type { State, Dispatch } from "core/types";
import type { Props, OwnProps, StateProps, DispatchProps } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  return {
    branch: getBranch(state, props),
    canUseSketchLibraries: sketchLibrariesSupported(state),
    canShowLargeCommitWarning: canShowLargeCommitWarning(
      state,
      props.projectId
    ),
    featureEnabledFormats: featureEnabledFileFormats(state, props.projectId),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return { dismissDialog: () => dispatch(dismissDialog()) };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
