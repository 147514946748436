// @flow
import classnames from "classnames";
import * as React from "react";
import Flex from "../Flex";
import Heading from "../Heading";
import Icon from "../Icon";
import style from "./mobile.scss";

type Props = {
  title: React.Node,
  filters?: React.Node,
  actions?: React.Node,
  className?: string,
  icon?: string,
  children?: React.Node,
};

export default function MobileContentHeader({
  title,
  filters,
  actions,
  className,
  icon,
  children,
}: Props) {
  return (
    <Flex
      column
      align="center"
      className={classnames(style.container, className, {
        [style.hasChildren]: !!children,
      })}
    >
      <Flex align="center" className={style.top}>
        <Flex align="center">
          {!!icon && <Icon type={icon} className={style.icon} />}
          <Heading level="2" size="m">
            {title}
          </Heading>
        </Flex>
        <Flex align="center" justify="flex-end">
          {actions || null}
        </Flex>
      </Flex>
      {children}
      {!!filters && (
        <Flex align="center" justify="center" className={style.bottom}>
          {filters}
        </Flex>
      )}
    </Flex>
  );
}
