// @flow
import classnames from "classnames";
import { isEmpty, map, pick, keys } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import PageMenu from "core/components/PageMenu";
import TreeView from "core/components/TreeView";
import * as File from "core/models/file";
import * as Page from "core/models/page";
import { getBranchHead } from "core/selectors/branches";
import { getDependencyPreviewsForFileOnBranch } from "core/selectors/layers";
import type {
  State,
  Dispatch,
  Layer,
  File as TFile,
  FilePreviews,
  Page as TPage,
} from "core/types";
import Label from "./Label";
import style from "./style.scss";

type OwnProps = {|
  libraries: TPage[],
  file: TFile,
  fileSelected: boolean,
  fileCommentCounts: { [id: string]: number },
  filePath: (
    fileId: string,
    pageId?: string,
    contentTypeId?: string
  ) => ?Function,
  onClickItem: (
    fileId: string,
    pageId?: string,
    contentTypeId?: string
  ) => () => void,
  itemClassName?: string,
  selectedClassName?: string,
  disclosureClassName?: string,
  branchId: string,
  projectId: string,
  selectedPageId?: string,
  selectedContentTypeId?: string,
  libraryPageSelected: boolean,
  isFocused: boolean,
  isMobile?: boolean,
|};

type StateProps = {|
  previewsByFile: FilePreviews,
|};

type Props = {
  ...OwnProps,
  ...StateProps,
};

class FileDependenciesTree extends React.Component<Props> {
  contentTypeSelected = (contentTypeId: string, pageId?: string): boolean => {
    if (!this.props.fileSelected) {
      return false;
    }
    if (
      this.props.selectedPageId &&
      (!pageId || pageId !== this.props.selectedPageId)
    ) {
      return false;
    }
    return this.props.selectedContentTypeId === contentTypeId;
  };

  renderContents = (
    contents: { [type: string]: Layer[] },
    file: TFile,
    page?: TPage
  ): React.Node[] => {
    const pageId = page ? page.id : "";
    return map(contents, (layers, type) => {
      const contentType = File.contentTypes[type];
      if (!contentType) {
        return null;
      }

      const selected = this.contentTypeSelected(contentType.id, pageId);
      return (
        <TreeView
          key={`${file.id}-${pageId}-${type}`}
          label={
            <Label
              name={contentType.name}
              isLight={this.props.isFocused && selected}
            />
          }
          icon={contentType.icon}
          to={this.props.filePath(file.id, pageId, contentType.id)}
          onClick={this.props.onClickItem(file.id, pageId, contentType.id)}
          selected={selected}
          isMobile={this.props.isMobile}
          isFocused={this.props.isFocused}
          itemClassName={this.props.itemClassName}
          selectedClassName={this.props.selectedClassName}
          disclosureClassName={style.hiddenDisclosure}
        />
      );
    });
  };

  render() {
    const {
      libraries,
      file,
      filePath,
      fileSelected,
      fileCommentCounts,
      previewsByFile,
      selectedPageId,
      selectedContentTypeId,
      libraryPageSelected,
      onClickItem,
      projectId,
      branchId,
      itemClassName,
      selectedClassName,
      disclosureClassName,
      isFocused,
      isMobile,
    } = this.props;

    const fileContents = pick(previewsByFile[file.id], keys(File.contentTypes));

    return (
      <React.Fragment>
        {!isEmpty(fileContents) && (
          <TreeView
            key={`${file.id}-styles`}
            label={<Label name="Styles" isStyleLabel />}
            defaultCollapsed={
              !fileSelected || libraryPageSelected || !selectedContentTypeId
            }
            isMobile={isMobile}
            disclosureClassName={disclosureClassName}
            itemClassName={classnames(itemClassName, style.styleItem)}
            hasChildren
          >
            {this.renderContents(fileContents, file)}
          </TreeView>
        )}
        {!!libraries.length && (
          <TreeView
            key={`${file.id}-dependencies`}
            label={
              <Label
                name="Dependencies"
                info="Library items used in this file"
                isStyleLabel
              />
            }
            defaultCollapsed={!fileSelected || !libraryPageSelected}
            isMobile={isMobile}
            disclosureClassName={disclosureClassName}
            itemClassName={classnames(itemClassName, style.styleItem)}
            hasChildren
          >
            {map(libraries, (page) => {
              const pageSelected = fileSelected && selectedPageId === page.id;
              const pageContents = previewsByFile[Page.uniqueId(page)];
              const pageHasComments =
                !!fileCommentCounts[`${file.id}-${page.id}`];

              return (
                <PageMenu
                  projectId={projectId}
                  branchId={branchId}
                  file={file}
                  page={page}
                >
                  {(showMenu, renderMenuTarget) => {
                    return renderMenuTarget((ref, buttonProps) => (
                      <TreeView
                        key={`${file.id}-${page.id}`}
                        label={
                          <Label
                            name={page.name}
                            isLight={pageSelected && isFocused}
                            hasComments={pageHasComments}
                          />
                        }
                        icon={`file-${file.type}-library`}
                        to={filePath(file.id, page.id)}
                        onClick={onClickItem(file.id, page.id)}
                        onContextMenu={showMenu}
                        innerRef={ref}
                        isMobile={isMobile}
                        isFocused={isFocused}
                        selected={pageSelected && !selectedContentTypeId}
                        selectedClassName={selectedClassName}
                        disclosureClassName={disclosureClassName}
                        itemClassName={itemClassName}
                        defaultCollapsed={!pageSelected}
                        hasChildren
                        {...buttonProps}
                      >
                        {this.renderContents(pageContents, file, page)}
                      </TreeView>
                    ));
                  }}
                </PageMenu>
              );
            })}
          </TreeView>
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { file, branchId } = props;
  const head = getBranchHead(state, { projectId: file.projectId, branchId });

  return {
    previewsByFile: getDependencyPreviewsForFileOnBranch(state, {
      projectId: file.projectId,
      branchId,
      sha: head,
      fileId: file.id,
    }),
  };
}

export default connect<Props, OwnProps, StateProps, _, State, Dispatch>(
  mapStateToProps
)(FileDependenciesTree);
