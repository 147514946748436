// @flow
import { connect } from "react-redux";
import { dismissToast } from "core/actions/toasts";
import { getToasts } from "core/selectors/toasts";
import type { State } from "core/types";
import Toasts from "./component";

function mapStateToProps(state: State) {
  return { messages: getToasts(state) };
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default connect(mapStateToProps, {
  onDismissMessage: dismissToast,
})(Toasts);
