// @flow
import empty from "empty";
import { connect } from "react-redux";
import { getCurrentUserId } from "abstract-di/selectors";
import { showToast } from "core/actions/toasts";
import { getOrganizationSettings } from "core/selectors/organizationSettings";
import { getOrganizationPolicy } from "core/selectors/policies";
import type { Dispatch, State, SSOConfig } from "core/types";
import {
  organizationSSOFormSubmit,
  organizationSettingsFormSubmit,
} from "web/actions/organizationSettingsForm";
import type { FormValues } from "web/models/form";
import { OrganizationSettingsUpdateRequest } from "web/requests/organizationSettings";
import type { OwnProps, StateProps, DispatchProps, Props } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { organizationId } = props.params;
  const settings = getOrganizationSettings(state, { organizationId });
  const userId = getCurrentUserId(state);

  const { emailExceptions, entityId, metadataUrl, ssoActive } =
    settings && settings.sso ? settings.sso : empty.object;

  const hasSSOPolicy = getOrganizationPolicy(state, {
    organizationId,
  }).manageSamlAndScim;

  return {
    canShowSSOForm: hasSSOPolicy,
    emailExceptions,
    entityId,
    joinExistingOrgEnabled: settings
      ? settings.joinExistingOrgEnabled
      : undefined,
    metadataUrl,
    projectAccessRequestEnabled: settings
      ? settings.projectAccessRequestEnabled
      : undefined,
    restrictedToDomain: settings ? settings.restrictedToDomain : undefined,
    ssoActive,
    userId,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    submitRequestAccessSetting: (
      organizationId: string,
      property: string,
      value: boolean
    ) => {
      dispatch(
        OrganizationSettingsUpdateRequest.perform({
          params: { organizationId, values: { [property]: value } },
          onSuccess: () => {
            dispatch(showToast({ text: "Permissions saved" }));
          },
          onError: () => {
            dispatch(showToast({ text: "Error saving permissions" }));
          },
        })
      );
    },
    submitDomainRestrictionSettings: async (
      formId: string,
      organizationId: string,
      values: FormValues
    ) => {
      try {
        await dispatch(
          organizationSettingsFormSubmit(formId, organizationId, values)
        );
        dispatch(showToast({ text: "Approved domains saved" }));
      } catch {
        dispatch(showToast({ text: "Error saving approved domains" }));
      }
    },
    submitSSOSettings: (
      formId: string,
      organizationId: string,
      values: SSOConfig
    ) => {
      dispatch(organizationSSOFormSubmit(formId, organizationId, values));
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
