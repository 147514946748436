// @flow
import * as React from "react";
import Button from "core/components/Button";
import FloatingControl from "core/components/FloatingControl";
import LayerMenu from "core/components/LayerMenu";
import MultiSelectButton from "core/components/MultiSelectButton";
import PreviewImage from "core/components/PreviewImage";
import PreviewLoader from "core/components/PreviewLoader";
import Thumbnail from "core/components/Thumbnail";
import type { Layer } from "core/types";
import style from "./style.scss";

type Props = {
  layer: Layer,
  layerPath?: string,
  fileType: string,
  fromLibrary: boolean,
  commentCount: number,
  hideLayerMenu?: boolean,
  hideContextMenu?: boolean,
  status: string,
  highlight: string,
  projectId: string,
  branchId: string,
  isDeleted: boolean,
  isSelected: boolean,
  isSelecting: boolean,
  onClick?: (layer: Layer) => void,
  onSelect?: (event: SyntheticMouseEvent<>) => void,
  onContextMenu?: () => void,
  useLatestCommit?: boolean,
  qaSelector?: string,
};

export default function LayerThumbnail({
  layer,
  layerPath,
  fileType,
  fromLibrary,
  commentCount,
  hideLayerMenu,
  hideContextMenu,
  status,
  highlight,
  projectId,
  branchId,
  isDeleted,
  isSelected,
  isSelecting,
  onClick,
  onSelect,
  onContextMenu,
  useLatestCommit,
  qaSelector,
}: Props) {
  return (
    <LayerMenu
      projectId={projectId}
      branchId={branchId}
      layer={layer}
      useLatestCommit={useLatestCommit}
    >
      {(showMenu, menuRef, isUnpushed) => (
        <Thumbnail
          key={layer.id}
          layer={layer}
          onClick={
            onClick && !isDeleted ? () => onClick && onClick(layer) : undefined
          }
          onContextMenu={isDeleted || hideContextMenu ? undefined : showMenu}
          to={isDeleted ? undefined : layerPath}
          layerName={layer.name}
          fileType={fileType}
          fromLibrary={fromLibrary}
          isSelecting={isSelecting}
          onSelect={!isDeleted ? onSelect : undefined}
          commentCount={commentCount}
          highlight={highlight}
          status={status}
          selected={isSelected}
          qaSelector={qaSelector}
          extras={
            <React.Fragment>
              {onSelect && !isDeleted && !isUnpushed && (
                <FloatingControl className={style.selectButton}>
                  <MultiSelectButton
                    nude
                    onSelect={onSelect}
                    selected={isSelected}
                    qaSelector="layerThumbnailMultiSelectButton"
                  />
                </FloatingControl>
              )}
              {!hideLayerMenu && !isSelecting && !isDeleted && (
                <FloatingControl className={style.menuButton}>
                  <Button
                    nude
                    icon="overflow"
                    innerRef={menuRef}
                    onClick={showMenu}
                  />
                </FloatingControl>
              )}
            </React.Fragment>
          }
          alwaysShowExtras={isSelecting}
        >
          <PreviewLoader
            projectId={projectId}
            fileId={layer.fileId}
            layerId={layer.id}
            commitSha={layer.lastChangedAtSha}
            scale={0.5}
          >
            {(src, loading, error) => (
              <PreviewImage
                src={src}
                className={isDeleted ? style.deleted : undefined}
                width={layer.width > 0 ? layer.width : undefined}
                height={layer.height > 0 ? layer.height : undefined}
                alt={layer.name}
                cover={false}
                errorText={error}
              />
            )}
          </PreviewLoader>
        </Thumbnail>
      )}
    </LayerMenu>
  );
}
