// @flow
import { difference, isEqual, map } from "lodash";
import type { SocketEvent, Organization, Project } from "core/types";
import Socket from ".";

type Actions = {
  onConnected?: Function,
  onConnecting?: Function,
  onDisconnected?: Function,
  onMessage?: (SocketEvent) => *,
};

type Options = {|
  authenticationToken: ?string,
  currentUserId: ?string,
  publicShareToken?: ?string,
  organizations: Organization[],
  projects: Project[],
|};

function channelIds(options: Options): Array<string> {
  let channelIds = [];

  if (options.publicShareToken) {
    channelIds.push(`share-${options.publicShareToken}`);
  }

  if (options.currentUserId) {
    channelIds.push(`user-${options.currentUserId}`);

    // Subscribe to organizations and projects when logged in
    channelIds = channelIds.concat(
      map(
        options.organizations,
        (organization) => `organization-${organization.id}`
      ),
      map(options.projects, (project) => `project-${project.id}`)
    );
  }

  return channelIds;
}

export default class SocketManager {
  actions: Actions;
  authEndpoint: string;
  pusherAppKey: string;
  socket: ?Object;

  options: Options = {
    organizations: [],
    projects: [],
    publicShareToken: undefined,
    authenticationToken: undefined,
    currentUserId: undefined,
  };
  reconnects = 0;

  constructor(pusherAppKey: string, authEndpoint: string, actions: Actions) {
    this.actions = actions;
    this.authEndpoint = authEndpoint;
    this.pusherAppKey = pusherAppKey;
  }

  handleConnecting = () => {
    if (this.actions.onConnecting) {
      this.actions.onConnecting();
    }
  };

  handleConnected = () => {
    this.reconnects += 1;
    if (this.actions.onConnected) {
      this.actions.onConnected(this.reconnects);
    }
  };

  handleDisconnected = () => {
    if (this.actions.onDisconnected) {
      this.actions.onDisconnected();
    }
  };

  handleMessage = (type: string, data: Object) => {
    if (this.actions.onMessage) {
      // $FlowFixMe
      this.actions.onMessage({ type, data });
    }
  };

  update(nextOptions: Options) {
    const currentUserId = this.options.currentUserId;
    const currentUserAuth = this.options.authenticationToken;
    const nextUserId = nextOptions.currentUserId;
    const nextUserAuth = nextOptions.authenticationToken;

    const userAuthChanged = nextUserAuth !== currentUserAuth;
    const userChanged = userAuthChanged || nextUserId !== currentUserId;
    const nextUserIsValid = nextUserId && nextUserAuth;
    const userCanSubscribe = userChanged && nextUserIsValid;

    const currentShareAuth = this.options.publicShareToken;
    const nextShareAuth = nextOptions.publicShareToken;
    const shareAuthChanged = nextShareAuth !== currentShareAuth;
    const publicShareCanSubscribe = shareAuthChanged && nextShareAuth;

    // Destroy the socket connection when no valid auth methods are present
    if (!nextUserIsValid && !nextShareAuth) {
      this.disconnect();
    }

    if (userCanSubscribe || publicShareCanSubscribe) {
      this.socket = new Socket(
        this.pusherAppKey,
        this.authEndpoint,
        // $FlowFixMe: guarded above
        nextUserAuth
      );
      this.socket && this.socket.on("connecting", this.handleConnecting);
      this.socket && this.socket.on("connected", this.handleConnected);
      this.socket && this.socket.on("unavailable", this.handleDisconnected);
      this.socket && this.socket.on("disconnected", this.handleDisconnected);
      this.socket && this.socket.on("message", this.handleMessage);
    }

    this.updateSubscriptions(channelIds(nextOptions), channelIds(this.options));
    this.options = nextOptions;
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = undefined;
    }
  }

  subscribe = (id: string) => {
    this.socket && this.socket.subscribe(id);
  };

  unsubscribe = (id: string) => {
    this.socket && this.socket.unsubscribe(id);
  };

  updateSubscriptions(newIds: string[], oldIds: string[]) {
    if (!isEqual(newIds, oldIds)) {
      difference(newIds, oldIds).forEach(this.subscribe);
      difference(oldIds, newIds).forEach(this.unsubscribe);
    }
  }
}
