// @flow
import * as React from "react";
import {
  events,
  downloadPreview,
  getPreviewInLocalCache,
} from "abstract-di/previews";
import window from "core/global/window";
import { isDesktop } from "core/lib/platform";
import type { Preview, PreviewScale, Size, File } from "core/types";
import connector from "./connector";

type Image = {|
  dimensions: Size,
  src: string,
|};

type Cache = {
  [key: string]: Image,
};

let cache: Cache = {};

export function clearCache() {
  cache = {};
}

function getImageSize(src: string): Promise<Size> {
  return new Promise((resolve, reject) => {
    const image: HTMLImageElement = window.document.createElement("img");
    image.onload = () => resolve({ width: image.width, height: image.height });
    image.onerror = () => reject();
    image.src = src;
  });
}

const DEFAULT_SIZE: Size = {
  width: 0,
  height: 0,
};

export type DefaultProps = {|
  scale: PreviewScale,
|};

export type OwnProps = {|
  ...DefaultProps,
  projectId: string,
  commitSha: string, // TODO: sha?
  fileId: string,
  layerId: string,
  children?: (
    src: string,
    loading: boolean,
    error: ?string,
    imageSize: Size
  ) => React.Node,
  onLoad?: (status: "loaded" | "error") => void,
|};

export type StateProps = {|
  webp: boolean,
  thumbnail: boolean,
  reGenerationFailed: boolean,
  xdSupport: boolean,
  file: ?File,
|};

export type DispatchProps = {|
  reGeneratePreviews: () => void,
  getFileInfo: () => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

function getKey(props: Props, scaleOverride?: number) {
  // prettier-ignore
  return `${scaleOverride || props.scale}-${props.projectId}-${props.commitSha}-${props.fileId}-${props.layerId}`;
}

type State = {|
  loadingState: "loading" | "error" | "loaded",
  image?: Image,
  placeholder?: Image,
  imageNotFoundError?: boolean,
|};

class PreviewLoader extends React.Component<Props, State> {
  static defaultProps: DefaultProps = {
    scale: 1.0,
  };

  controller: ?AbortController = window.AbortController
    ? new window.AbortController()
    : undefined;

  constructor(props: Props) {
    super();

    const image = cache[getKey(props)];
    const placeholder =
      image || // Fallback to progressively smaller scales as a placeholder
      cache[getKey(props, 0.5)] ||
      cache[getKey(props, 0.25)];

    this.state = image
      ? { loadingState: "loaded", image }
      : { loadingState: "loading", placeholder };
  }

  componentDidMount() {
    events.addListener(
      this.getListenerKey(this.props),
      this.handlePreviewEvent
    );

    events.addListener(
      this.mergePreviewsListenerKey(this.props),
      this.handleMergePreviewsReady
    );

    switch (this.state.loadingState) {
      case "loading": {
        this.loadImage(this.props);
        break;
      }
      case "loaded": {
        if (this.props.onLoad) {
          this.props.onLoad("loaded");
        }

        break;
      }
      case "error":
      default: {
        throw new Error(`Invalid loadingState: ${this.state.loadingState}`);
      }
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.layerId !== this.props.layerId ||
      prevProps.fileId !== this.props.fileId ||
      prevProps.commitSha !== this.props.commitSha ||
      prevProps.file !== this.props.file
    ) {
      events.removeListener(
        this.getListenerKey(prevProps),
        this.handlePreviewEvent
      );
      events.removeListener(
        this.mergePreviewsListenerKey(prevProps),
        this.handleMergePreviewsReady
      );

      events.addListener(
        this.getListenerKey(this.props),
        this.handlePreviewEvent
      );
      events.addListener(
        this.mergePreviewsListenerKey(this.props),
        this.handleMergePreviewsReady
      );

      this.loadImage(this.props);
    }
  }

  componentWillUnmount() {
    events.removeListener(
      this.getListenerKey(this.props),
      this.handlePreviewEvent
    );
    events.removeListener(
      this.mergePreviewsListenerKey(this.props),
      this.handleMergePreviewsReady
    );

    if (this.controller) {
      this.controller.abort();
    }
  }

  getListenerKey(props: Props): string {
    return `preview-${props.fileId}-${props.layerId}-${props.commitSha}`;
  }

  mergePreviewsListenerKey(props: Props) {
    return `merge-previews-${props.projectId}-${props.commitSha}`;
  }

  cacheImage = (image: Image) => {
    cache[getKey(this.props)] = image;
  };

  handlePreviewEvent = (preview: Preview) => {
    if (
      this.props.commitSha === preview.sha &&
      this.props.layerId === preview.layerId &&
      this.props.fileId === preview.fileId
    ) {
      this.handlePreviewLoaded(
        preview.path,
        isDesktop
          ? // used by quick commit
            Date.now()
          : // web 404s when we append a timestamp to blob url
            undefined
      );
    }
  };

  handlePreviewLoaded = async (path: string, timestamp?: number) => {
    const dimensions = await getImageSize(path);
    const src = `${path}${timestamp ? `?${timestamp}` : ""}`;
    const image = { src, dimensions };

    this.setState({ loadingState: "loaded", image }, () => {
      if (this.props.onLoad) {
        this.props.onLoad("loaded");
      }
    });

    this.cacheImage(image);

    // If we're not at the working copy sha then there will never be any more
    // previews generated. We can safely stop listening to preview events
    if (!this.props.commitSha === "WorkingCopySHA") {
      events.removeListener(
        this.getListenerKey(this.props),
        this.handlePreviewEvent
      );
    }
  };

  handleMergePreviewsReady = (payload: Object) => {
    this.loadImage(this.props);
  };

  async loadImage(props: Props) {
    const path = await getPreviewInLocalCache(
      props.projectId,
      props.fileId,
      props.layerId,
      props.commitSha
    );

    if (path) {
      this.handlePreviewLoaded(path);
      return;
    }

    if (props.commitSha === "WorkingCopySHA") {
      // If we're looking for previews for ongoing work this will never be found
      // on the server. We can safely bomb out here
      return;
    }

    // Attempt to download from server. Successful downloads will emit a 'preview'
    // event that is handled by handlePreviewEvent
    try {
      if (this.state.imageNotFoundError) {
        // we have an error report already dont trying to download file again
        this.triggerPreviewReGeneration();
        return;
      }
      await downloadPreview(
        props.projectId,
        props.fileId,
        props.layerId,
        props.commitSha,
        {
          controller: this.controller || undefined,
          scale: props.thumbnail ? props.scale : undefined,
          thumbnail: props.thumbnail,
          webp: props.webp,
          enableRetry: props.thumbnail || props.webp,
        }
      );
    } catch (error) {
      const imageNotFoundError = error.message.match(/404/); // "Not Found 404";

      if (this.props.xdSupport && !this.props.file) {
        this.props.getFileInfo();
      }

      this.setState({
        loadingState: "error",
        imageNotFoundError,
      });

      this.triggerPreviewReGeneration();

      if (this.props.onLoad) {
        this.props.onLoad("error");
      }
    }
  }

  triggerPreviewReGeneration = () => {
    if (
      this.state.imageNotFoundError &&
      isDesktop &&
      !this.props.reGenerationFailed &&
      (!this.props.xdSupport || this.props.file) &&
      this.canReGeneratePreview()
    ) {
      // attempt to re-generate previews only once and only for Sketch files
      this.props.reGeneratePreviews();
    }
  };

  canReGeneratePreview = () => {
    return !this.props.file || this.props.file.type === "sketch";
  };

  buildErrorMessage = (): ?string => {
    if (this.props.reGenerationFailed) {
      return "There was a problem updating the preview for this item.";
    }
    if (this.state.loadingState === "error") {
      if (this.props.xdSupport && !this.props.file) {
        return null;
      }
      if (!this.canReGeneratePreview()) {
        return "The preview for this item is not supported.";
      }
      return isDesktop
        ? "The preview will update in a moment..."
        : "Open project in desktop app to update the preview for this item.";
    }
    return null;
  };

  render() {
    let image =
      this.state.loadingState === "loading"
        ? this.state.placeholder
        : this.state.image;

    const displayLoading =
      this.state.loadingState === "loading" && !this.state.placeholder;

    const errorMessage = this.buildErrorMessage();

    return this.props.children
      ? this.props.children(
          image ? image.src : "",
          displayLoading,
          errorMessage,
          this.state.image ? this.state.image.dimensions : DEFAULT_SIZE
        )
      : null;
  }
}

export default connector(PreviewLoader);
