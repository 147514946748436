// @flow
import classnames from "classnames";
import * as React from "react";
import Avatar, { type AvatarBorderColor } from "core/components/Avatar";
import TeamAvatar from "core/components/TeamAvatar";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  organizationId?: string,
  userId?: string,
  large?: boolean,
  src?: string,
  name?: string,
  url?: ?string,
  stretch?: boolean,
  className?: string,
  style?: Object,
  size?: string | number,
  teamAvatarSize?: number,
  badgeIcon?: string,
  deletedComment?: boolean,
  borderColor?: AvatarBorderColor,
  showNamePopover?: boolean,
  wrapperClassName?: string,
  teamIds?: string[],
|};

export type DispatchProps = {|
  onLoad: () => void,
|};

export type Props = {| ...OwnProps, ...DispatchProps |};

function AvatarWithTeamPip(props: Props) {
  const {
    wrapperClassName,
    teamIds,
    organizationId,
    teamAvatarSize,
    onLoad,
    ...rest
  } = props;
  if (!teamIds || teamIds.length === 0) {
    return <Avatar {...rest} />;
  }
  return (
    <div className={style.avatarWrapper}>
      <Avatar {...rest} />
      <div
        className={classnames(style.teamAvatarPip, {
          [style.offset]: props.teamAvatarSize && props.teamAvatarSize < 28,
        })}
      >
        <TeamAvatar
          teamIds={props.teamIds}
          showPopover
          showBorder
          size={
            props.teamAvatarSize ||
            (typeof props.size === "number" ? props.size / 4 : undefined)
          }
        />
      </div>
    </div>
  );
}

export default connector(AvatarWithTeamPip);
