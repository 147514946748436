// @flow
import { some } from "lodash";
import * as React from "react";
import { default as NativeContextMenu } from "abstract-di/components/ContextMenu";
import {
  default as Popover,
  type PopoverPlacement,
} from "core/components/Popover/Core";
import { isDesktop, isQA } from "core/lib/platform";
import type { MenuItem } from "core/types";
import ContextMenuItemList from "./ContextMenuItemList";

export type Children = (
  showMenu: (event?: SyntheticEvent<>) => void,
  ref: (element: ?HTMLElement) => void,
  popoverHandlers?: Object,
  buttonProps?: {
    "aria-haspopup": string,
    "aria-expanded": boolean,
    role: string,
    onKeyDown: (event: KeyboardEvent) => void,
  }
) => React.Node;

export type ContextMenuInterface = {|
  placement?: PopoverPlacement,
  fullWidth?: boolean,
  fixed?: boolean,
  compact?: boolean,
  children: Children,
  onAfterShow?: () => void,
  onAfterClose?: () => void,
  forceNonNative?: boolean,
  hideEmpty?: boolean,
|};

export type Props = {
  id: string,
  menuItems: Array<MenuItem>,
  ...ContextMenuInterface,
};

export default class ContextMenu extends React.Component<Props> {
  popover: ?Popover;
  showMenu: ?(event?: SyntheticEvent<>) => void;

  popoverRef = (ref: ?Popover) => (this.popover = ref);

  close = () => {
    this.popover && this.popover.hide();
    // wait a frame before calling the closeMenu method
    setTimeout(() => {
      this.props.onAfterClose && this.props.onAfterClose();
    });
  };
  // We want to prevent default so that the native context menu does not show up
  childrenWithoutDefault = (
    showMenu: (event?: SyntheticEvent<>) => void,
    ref: (el: ?HTMLElement) => void,
    popoverHandlers?: Object,
    buttonProps?: {
      "aria-haspopup": string,
      "aria-expanded": boolean,
      role: string,
      onKeyDown: (event: KeyboardEvent) => void,
    }
  ) => {
    if (!this.showMenu) {
      this.showMenu = (event) => {
        if (event) {
          event.stopPropagation();
        }
        if (event) {
          event.preventDefault();
        }
        return showMenu(event);
      };
    }

    return this.props.children(
      this.showMenu,
      ref,
      popoverHandlers,
      buttonProps
    );
  };

  render() {
    if (this.props.hideEmpty) {
      const isVisible = some(
        this.props.menuItems,
        (i) => i.type !== "separator" && i.visible !== false
      );

      if (!isVisible) {
        return null;
      }
    }

    if (!isDesktop || isQA) {
      return (
        <Popover
          ref={this.popoverRef}
          placement={this.props.placement || "bottom-end"}
          body={(dismiss) => (
            <ContextMenuItemList
              id={this.props.id}
              items={this.props.menuItems}
              close={this.close}
              compact={this.props.compact}
              qaSelector="context-menu"
            />
          )}
          delayShow={0}
          trigger="click"
          fullWidth={this.props.fullWidth}
          onAfterShow={this.props.onAfterShow}
          onAfterClose={this.props.onAfterClose}
        >
          {this.childrenWithoutDefault}
        </Popover>
      );
    }

    return (
      <NativeContextMenu {...this.props}>
        {this.childrenWithoutDefault}
      </NativeContextMenu>
    );
  }
}
