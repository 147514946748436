// @flow
import { entitiesDeleted } from "core/actions/entities";
import { deletedOrganizationMembership } from "core/actions/memberships";
import { showToast } from "core/actions/toasts";
import { RemoveOrganizationMemberRequest } from "core/requests/memberships";
import { getOrganization } from "core/selectors/organizations";
import { getCurrentUser } from "web/selectors/session";
import type { ThunkAction } from ".";

export function removeOrganizationMember(
  organizationId: string,
  userId: string
): ThunkAction {
  return async (dispatch) => {
    await dispatch(
      RemoveOrganizationMemberRequest.perform({
        params: { organizationId, userId },
      })
    );

    return dispatch(deletedOrganizationMembership(organizationId, userId));
  };
}

export function leaveOrganization(organizationId: string): ThunkAction {
  return async (dispatch, getState) => {
    const user = getCurrentUser(getState());
    if (!user) {
      return;
    }

    dispatch(removeOrganizationMember(organizationId, user.id));

    const organization = getOrganization(getState(), { organizationId });
    if (organization) {
      dispatch(showToast({ text: `You have left ${organization.name}` }));
    }

    return dispatch(entitiesDeleted({ organizations: [organizationId] }));
  };
}
