// @flow
import { entitiesReceived } from "core/actions/entities";
import { request } from "core/actions/requests";
import * as API from "../api";
import * as URLs from "../api/urls";
import { normalizeNotificationSettings } from "../schemas/notificationSettings";
import type { ThunkAction } from ".";

export function loadNotificationSettings(): ThunkAction {
  return async (dispatch) => {
    return dispatch(
      request(
        URLs.fetchNotificationSettings(),
        () => API.fetchNotificationSettings(),
        {
          force: false,
          onSuccess: (response) => {
            const { entities } = normalizeNotificationSettings(response);
            dispatch(entitiesReceived(entities));
          },
        }
      )
    );
  };
}

export function notificationSettingsFormSubmit(
  formId: string,
  values: { emailEnabled: boolean }
): ThunkAction {
  return async (dispatch) => {
    return dispatch(
      request(formId, () => API.updateNotificationSettings(values), {
        onSuccess: (response) => {
          const { entities } = normalizeNotificationSettings(response);
          dispatch(entitiesReceived(entities));
        },
      })
    );
  };
}
