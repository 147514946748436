// @flow
import type {
  OrganizationPeopleOptions,
  ReactRouterLocation,
} from "core/types";

function getSearch(location: ReactRouterLocation) {
  if (location.query && location.query.s) {
    return location.query.s;
  }
}

function getFilter(location: ReactRouterLocation) {
  if (location.query && location.query.role) {
    const filter = location.query.role;
    if (
      filter === "member" ||
      filter === "owner" ||
      filter === "guest" ||
      filter === "viewer" ||
      filter === "contributor"
    ) {
      return filter;
    }
  }
}

export function organizationPeopleOptions(
  location: ReactRouterLocation
): OrganizationPeopleOptions {
  const searchFilter = getSearch(location);
  const roleFilter = getFilter(location);
  return {
    search: searchFilter,
    roleFilter,
  };
}
