// @flow
import * as React from "react";
import DialogForm from "core/components/DialogForm";
import connector from "./connector";

export type OwnProps = {|
  projectId: string,
|};

export type DispatchProps = {|
  stopSyncingProject: () => void,
  dismissDialog: () => void,
|};

export type Props = {
  ...OwnProps,
  ...DispatchProps,
};

class ConfirmStopSyncing extends React.Component<Props> {
  handleSubmit = () => {
    this.props.stopSyncingProject();
    this.props.dismissDialog();
  };

  render() {
    return (
      <DialogForm
        title="Stop syncing project"
        primaryButton="Stop syncing"
        onClose={this.props.dismissDialog}
        onSubmit={this.handleSubmit}
        isOpen
      >
        <p>
          Once you stop syncing this project, it will be removed from this
          computer. You will no longer be able to access it in the app until you
          resume syncing.
        </p>
      </DialogForm>
    );
  }
}

export default connector(ConfirmStopSyncing);
