// @flow
import * as React from "react";
import { connect } from "react-redux";
import Error from "core/components/Empty/Error";
import Loaded from "core/components/Loaded";
import * as Request from "core/models/request";
import type { State, Dispatch } from "core/types";
import { loginRequired } from "web/actions/login";
import { loadZendeskToken } from "web/actions/zendesk";
import { getZendeskTokenRequest } from "web/selectors/requests";
import { getToken } from "web/token";

type OwnProps = {
  location: {
    query: {
      return_to: string,
    },
  },
};

type Props = {
  hasError: boolean,
  onMount: () => void,
};

class RequireZendeskAuthentication extends React.Component<Props> {
  componentDidMount() {
    this.props.onMount();
  }

  render() {
    if (this.props.hasError) {
      return <Error />;
    }

    return <Loaded loading />;
  }
}

function mapStateToProps(state: State, props: OwnProps) {
  const zendeskRequest = getZendeskTokenRequest(state);

  return {
    hasError: Request.hasError(zendeskRequest),
  };
}

function mapDispatchToProps(dispatch: Dispatch, props: OwnProps) {
  return {
    onMount: () => {
      if (!getToken()) {
        return dispatch(loginRequired());
      }

      const returnToUrl = props.location.query.return_to;
      dispatch(loadZendeskToken(returnToUrl));
    },
  };
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequireZendeskAuthentication);
