// @flow
import * as React from "react";
import BranchReviewSummary from "core/components/BranchReviewSummary";
import Sidebar from "core/components/Sidebar";
import { isMaster } from "core/models/branch";
import type { BranchReview, ReviewRequest, Branch } from "core/types";
import FileSummary from "./FileSummary";
import GetStartedFileLauncher from "./GetStartedFileLauncher";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  projectId: string,
  branchId: string,
  branch: Branch,
  reviewRequests?: ReviewRequest[],
  canShowReviews: boolean,
  showGetStarted: boolean,
|};

export type StateProps = {|
  branchReview: ?BranchReview,
  isOffline: boolean,
|};

export type DispatchProps = {|
  onLoad: () => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

class BranchOverviewSidebar extends React.Component<Props> {
  render() {
    const { projectId, branch, canShowReviews, branchReview, showGetStarted } =
      this.props;
    const hasContent = (canShowReviews && branchReview) || !isMaster(branch);

    if (!hasContent) {
      return null;
    }

    return (
      <Sidebar
        className={style.sidebar}
        direction="right"
        resizable="overview-right"
        minWidth={280}
        wide
      >
        {canShowReviews && branchReview && (
          <BranchReviewSummary
            branchReview={branchReview}
            projectId={projectId}
            branch={branch}
          />
        )}
        {showGetStarted ? (
          <GetStartedFileLauncher projectId={projectId} branch={branch} />
        ) : (
          <FileSummary projectId={projectId} branch={branch} />
        )}
      </Sidebar>
    );
  }
}

export default connector(BranchOverviewSidebar);
