// @flow
/* global window document */
import classnames from "classnames";
import * as React from "react";
import anyHover from "core/lib/anyHover";
import KeyCode from "core/lib/keycode";
import Popper from "./Popper";
import style from "./core.scss";

const DISABLED_HANDLERS = {};

type RenderChildren = (
  showPopover: () => *,
  ref: (el: ?HTMLElement) => *,
  popoverHandlers: Object,
  buttonProps: {
    "aria-haspopup": string,
    "aria-expanded": boolean,
    role: string,
    onKeyDown: (event: KeyboardEvent) => void,
  }
) => React.Node;

export type PopoverPlacement =
  | "auto"
  | "auto-start"
  | "auto-end"
  | "top"
  | "top-start"
  | "top-end"
  | "bottom"
  | "bottom-start"
  | "bottom-end"
  | "left"
  | "left-start"
  | "left-end"
  | "right"
  | "right-start"
  | "right-end";

export type PopoverClassNames = {
  +arrow?: string,
  +arrowContainer?: string,
  +bottom?: string,
  +container?: string,
  +label?: string,
  +left?: string,
  +placeholderShimmer?: string,
  +right?: string,
  +top?: string,
  +hoverMenuOpen?: string,
};

export type Props = {
  children?: React.Node | RenderChildren,
  label?: string,
  shortcut?: string,
  body?: React.Node | ((dismissPopover: () => void) => React.Node),
  placement: PopoverPlacement,
  offset?: string,
  width?: number,
  fullWidth?: boolean,
  trigger: "hover" | "hoverMenu" | "click" | "context" | "mount",
  delayShow: number,
  delayHide: number,
  disabled: boolean,
  forceHide?: boolean,
  forceShow?: boolean,
  onRequestHide?: () => void, // Used for ContextMenuItem to set its internal isForceShowingMenu state to false.
  display: "inline" | "block",
  onAfterShow?: () => void,
  onAfterClose?: () => void,
  classNames?: PopoverClassNames,
  modifiers: Object,
  scheduleUpdate?: boolean,
  clickable?: boolean,
};

type State = {
  isOpen: boolean,
  focusedIndex: number,
};

const FOCUSABLE_ELEMENTS = `[role="menuitem"]:not(:disabled), [role="menuitemradio"]:not(:disabled), [role="menuitemcheckbox"]:not(:disabled)`;

export default class PopoverCore extends React.Component<Props, State> {
  container: ?Popper;
  target: ?HTMLElement;
  returnFocusAfterClose: ?HTMLElement;
  showTimeoutId: number;
  hideTimeoutId: number;

  static defaultProps = {
    placement: "right",
    delayShow: 250,
    delayHide: 0,
    display: "inline",
    disabled: false,
    forceHide: false,
    trigger: "hover",
    classNames: {},
    modifiers: {},
    scheduleUpdate: false,
  };

  state = {
    isOpen: this.props.trigger === "mount",
    focusedIndex: 0,
  };

  triggers = {
    hover: {
      onMouseEnter: () => this.show(),
      onMouseLeave: () => this.hide(),
    },

    hoverMenu: {
      onMouseEnter: () => this.show(),
      onMouseLeave: () => this.hide(),
    },

    click: {
      onClick: () => this.toggle(),
    },

    context: {
      onContextMenu: () => this.toggle(),
    },

    mount: {},
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.isOpen !== this.state.isOpen) {
      if (this.state.isOpen) {
        this.returnFocusAfterClose = document.activeElement;

        this.setState(
          {
            focusedIndex: 0,
          },
          () => {
            const container = this.container;
            const elements = this.getFocusableElements();

            if (container && container.portal && elements.length > 0) {
              container.portal.tabIndex = 0;
              container.portal.focus();
              elements[this.state.focusedIndex].focus();
              container.portal.addEventListener("keydown", this.handleKeydown);
            }
          }
        );
      }
    }

    if (prevState.focusedIndex !== this.state.focusedIndex) {
      const elements = this.getFocusableElements();

      const focusedElement = elements[this.state.focusedIndex];

      if (focusedElement) {
        focusedElement.focus();
      }
    }

    if (prevProps.forceShow !== this.props.forceShow) {
      if (this.props.forceShow) {
        this.show();
      } else {
        this.hide();
      }
    }
  }

  componentWillUnmount() {
    if (this.showTimeoutId) {
      window.clearTimeout(this.showTimeoutId);
    }

    if (this.hideTimeoutId) {
      window.clearTimeout(this.hideTimeoutId);
    }

    const container = this.container;
    if (container && container.portal) {
      container.portal.removeEventListener("keydown", this.handleKeydown);
    }
  }

  getFocusableElements = (): NodeList<HTMLElement> | Array<empty> => {
    const container = this.container;
    const elements =
      (container && container.portal.querySelectorAll(FOCUSABLE_ELEMENTS)) ||
      [];

    return elements;
  };

  goToPreviousItem = (elements: NodeList<HTMLElement> | Array<empty>) => {
    if (this.state.focusedIndex - 1 > -1) {
      this.setState((state) => ({
        focusedIndex: state.focusedIndex - 1,
      }));
    } else {
      this.setState({
        focusedIndex: elements.length - 1,
      });
    }
  };

  goToNextItem = (elements: NodeList<HTMLElement> | Array<empty>) => {
    if (this.state.focusedIndex < elements.length - 1) {
      this.setState((state) => ({
        focusedIndex: state.focusedIndex + 1,
      }));
    } else {
      this.setState({
        focusedIndex: 0,
      });
    }
  };

  handleKeydown = (event: KeyboardEvent) => {
    const stopPropagation = () => {
      event.preventDefault();
      event.stopPropagation();
    };

    const elements = this.getFocusableElements();

    if (
      event.keyCode === KeyCode.KEY_ESCAPE ||
      event.keyCode === KeyCode.KEY_LEFT
    ) {
      stopPropagation();

      if (this.returnFocusAfterClose) {
        this.returnFocusAfterClose.focus();
      }

      this.hide();
    }

    if (event.keyCode === KeyCode.KEY_DOWN) {
      stopPropagation();
      this.goToNextItem(elements);
    }

    if (event.keyCode === KeyCode.KEY_UP) {
      stopPropagation();
      this.goToPreviousItem(elements);
    }

    if (event.keyCode === KeyCode.KEY_TAB) {
      if (event.shiftKey) {
        stopPropagation();
        this.goToPreviousItem(elements);
      } else {
        stopPropagation();
        this.goToNextItem(elements);
      }
    }
  };

  handleButtonKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === KeyCode.KEY_DOWN) {
      event.preventDefault();
      event.stopPropagation();

      this.show();
    }
  };

  render() {
    const { children, trigger, disabled, display } = this.props;
    const popoverHandlers = disabled
      ? DISABLED_HANDLERS
      : this.triggers[trigger];

    const buttonProps = {
      "aria-haspopup": "menu",
      "aria-expanded": this.state.isOpen,
      role: "button",
      onKeyDown: this.handleButtonKeyDown,
    };

    if (typeof children === "function") {
      return trigger === "hover" && !anyHover ? (
        <React.Fragment>
          {children(
            this.show,
            this.setTarget,
            this.triggers.click,
            buttonProps
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {children(this.show, this.setTarget, popoverHandlers, buttonProps)}
          {this.state.isOpen && !this.props.forceHide && (
            <Popper
              ref={(ref) => (this.container = ref)}
              {...this.props}
              target={this.target}
              onDismiss={this.handleDismiss}
            />
          )}
        </React.Fragment>
      );
    }

    // Use ref for DOM elements and innerRef for everything else
    const useInnerRef = typeof React.Children.only(children).type !== "string";
    const hoverMenuOpen = trigger === "hoverMenu" && this.state.isOpen;

    const hoverMenuOpenClassName = this.props.classNames
      ? this.props.classNames.hoverMenuOpen || ""
      : "";
    const childClassName =
      children !== undefined &&
      children !== null &&
      typeof children === "object" &&
      children.props &&
      typeof children.props.className === "string"
        ? children.props.className
        : "";

    return (
      <span className={style[display]}>
        <span
          className={style[display]}
          {...(trigger === "hover" && !anyHover
            ? DISABLED_HANDLERS
            : popoverHandlers)}
        >
          {
            // $FlowFixMe How to remove maybe from children prop? https://github.com/facebook/flow/issues/1964
            React.cloneElement(children, {
              [useInnerRef ? "innerRef" : "ref"]: this.setTarget,
              className: classnames(childClassName, {
                [hoverMenuOpenClassName]: hoverMenuOpen,
                [style.hoverMenuOpen]: hoverMenuOpen,
              }),
            })
          }
          {/* hovermenu means we want the popover content to count as part of the hover surface */}
          {trigger === "hoverMenu" && this.state.isOpen && (
            <Popper
              ref={(ref) => (this.container = ref)}
              {...this.props}
              target={this.target}
              onDismiss={this.handleDismiss}
            />
          )}
        </span>

        {trigger !== "hoverMenu" &&
          this.state.isOpen &&
          !this.props.forceHide && (
            <Popper
              ref={(ref) => (this.container = ref)}
              {...this.props}
              target={this.target}
              onDismiss={this.handleDismiss}
            />
          )}
      </span>
    );
  }

  handleDismiss = (event?: SyntheticMouseEvent<> | KeyboardEvent) => {
    this.setState({ isOpen: false }, () => {
      if (
        this.props.trigger === "click" &&
        this.target &&
        event &&
        event.type === "keydown"
      ) {
        this.target.focus();
      }
    });

    if (this.props.onAfterClose) {
      this.props.onAfterClose();
    }
  };

  show = () => {
    if (this.props.disabled) {
      return;
    }

    if (this.hideTimeoutId) {
      window.clearTimeout(this.hideTimeoutId);
    }

    if (this.props.delayShow > 0) {
      this.showTimeoutId = window.setTimeout(() => {
        this.setState({ isOpen: true });
      }, this.props.delayShow);
    } else {
      this.setState({ isOpen: true });
    }

    if (this.props.onAfterShow) {
      this.props.onAfterShow();
    }
  };

  hide = () => {
    if (this.showTimeoutId) {
      window.clearTimeout(this.showTimeoutId);
    }

    if (this.props.delayHide > 0 || this.props.trigger === "hoverMenu") {
      this.hideTimeoutId = window.setTimeout(() => {
        this.setState({ isOpen: false });
      }, this.props.delayHide);
    } else {
      this.setState({ isOpen: false });
    }

    if (this.props.onRequestHide) {
      this.props.onRequestHide();
    }

    if (this.props.onAfterClose) {
      this.props.onAfterClose();
    }
  };

  toggle = () => {
    if (this.props.disabled) {
      return;
    }

    this.setState((state) => ({ isOpen: !state.isOpen }));
  };

  // We use ref setters here to ensure referential equality between renders.
  // https://github.com/facebook/react/issues/9328#issuecomment-298438237

  setTarget = (element: ?HTMLElement) => {
    this.target = element;
  };
}
