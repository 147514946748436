// @flow
import empty from "empty";
import history from "abstract-di/history";
import { trackEvent } from "core/actions/analytics";
import { request } from "core/actions/requests";
import { trackShareLinkLoaded } from "core/actions/shareLinks";
import { layerLocation } from "core/lib/routes";
import { UnauthorizedError } from "core/models/request";
import { ShareLinkFetchRequest } from "core/requests/shareLinks";
import {
  getCollection,
  getCollectionLayer,
  getLayerForCollectionLayer,
} from "core/selectors/collections";
import { getProjectPolicy } from "core/selectors/policies";
import { getShareLink } from "core/selectors/shareLinks";
import type { ShareLink, LayerOptions } from "core/types";
import type { ThunkAction } from "web/actions";
import { formReset } from "web/actions/forms";
import { loginRequired, signInWithoutRedirecting } from "web/actions/login";
import { loadProject } from "web/actions/projects";
import { createProjectAccessRequest } from "web/api";
import type { FormValues as RequestAccessFormValues } from "web/components/RequestAccess/RequestAccessForm";
import { shareLinkDestination } from "web/routeHelpers";

function redirectToLayerIfUserCanAccessProject(
  shareLink: ShareLink,
  options?: any = empty.object
): ThunkAction {
  return async (dispatch, getState) => {
    const { projectId } = shareLink.descriptor;
    const { collectionLayerId } = options;

    await dispatch(loadProject(projectId));

    const state = getState();
    const policy = getProjectPolicy(state, { projectId });

    if (!policy.show) {
      // Do not redirect if we cannot access project
      return;
    }

    if (shareLink.kind === "collection") {
      // Mapping collectionLayerId -> LayerDescriptor cannot
      // be done without access to state. We special case
      // collectionLayerId here and determine the url outside
      // the share route helper
      const collectionLayer = getCollectionLayer(state, {
        collectionLayerId,
      });

      const layer = getLayerForCollectionLayer(state, {
        collectionLayerId,
      });

      const collection = getCollection(state, {
        collectionId: shareLink.descriptor.collectionId,
      });

      if (collection && collectionLayer && layer) {
        return history.replace(
          layerLocation(
            shareLink.descriptor.projectId,
            collection.branchId,
            layer.lastChangedAtSha,
            collectionLayer.fileId,
            layer.id,
            options
          )
        );
      }
    }

    history.replace(shareLinkDestination(shareLink, options));
  };
}

export function loadShareLink(shareId: string, options?: any): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState();
    const shareLink = getShareLink(state, shareId);

    if (shareLink) {
      await dispatch(redirectToLayerIfUserCanAccessProject(shareLink, options));
      return;
    }

    dispatch(
      ShareLinkFetchRequest.perform({
        params: { shareId },
        force: false,
        onSuccess: async (shareLink) => {
          dispatch(trackShareLinkLoaded(shareLink));
          await dispatch(
            redirectToLayerIfUserCanAccessProject(shareLink, options)
          );
        },
        onError: (error) => {
          if (
            error instanceof UnauthorizedError &&
            !error.body.canRequestAccess
          ) {
            return dispatch(loginRequired());
          }
        },
      })
    );
  };
}

export function loginAndLoadShareLink(
  shareId: string,
  values: { login: string, password: string },
  options?: LayerOptions
): ThunkAction {
  return async (dispatch, getState) => {
    dispatch(
      signInWithoutRedirecting(values.login, values.password, () => {
        dispatch(trackEvent("SIGNED_IN"));
        dispatch(loadShareLink(shareId, options));
      })
    );
  };
}

export function requestAccessFormSubmitted(
  formId: string,
  projectId: string,
  params: RequestAccessFormValues
): ThunkAction {
  return (dispatch, getState) => {
    return dispatch(
      request(formId, () => createProjectAccessRequest(projectId, params), {
        onSuccess: () => dispatch(formReset(formId)),
      })
    );
  };
}
