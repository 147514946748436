// @flow
import classnames from "classnames";
import * as React from "react";
import Flex from "core/components/Flex";
import Icon from "core/components/Icon";
import style from "./style.scss";

type Props = {
  className?: string,
};

export default function NoSectionProjects({ className }: Props) {
  return (
    <Flex
      align="center"
      justify="center"
      className={classnames(style.noSectionProjects, className)}
    >
      This Section is empty. You can add Projects to this Section from the
      Project menu (<Icon type="overflow" />
      ).
    </Flex>
  );
}
