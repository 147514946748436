// @flow
import * as React from "react";
import Button from "core/components/Button";
import ContextMenu from "core/components/ContextMenu";
import PageTitle from "core/components/PageTitle";
import { formatDate } from "core/lib/dates";
import type { ReportingFilter } from "core/types";

type Props = {|
  organizationId: string,
  startDate?: string,
  endDate?: string,
  mobile?: boolean,
  period: ReportingFilter,
  onFilter: (filter: ReportingFilter) => void,
|};

const labels = {
  currentMonth: "Current Month",
  lastThirtyDays: "Last 30 Days",
  lastMonth: "Last Month",
  prevThreeMonths: "Previous 3 Months",
  prevSixMonths: "Previous 6 Months",
  prevTwelveMonths: "Previous 12 Months",
};

export default class OrganizationReportingHeader extends React.Component<Props> {
  menu: ?ContextMenu;

  timePeriodString = (startDate: ?string, endDate: ?string) => {
    if (startDate && endDate) {
      return `Time period: ${formatDate(startDate)} - ${formatDate(endDate)}`;
    }
  };

  render() {
    const { period, onFilter, mobile, startDate, endDate } = this.props;

    const menuItems = [
      {
        type: "checkbox",
        label: labels.currentMonth,
        click: () => onFilter("currentMonth"),
        checked: period === "currentMonth",
      },
      {
        type: "checkbox",
        label: labels.lastThirtyDays,
        click: () => onFilter("lastThirtyDays"),
        checked: period === "lastThirtyDays",
      },
      {
        type: "checkbox",
        label: labels.lastMonth,
        click: () => onFilter("lastMonth"),
        checked: period === "lastMonth",
      },
      {
        type: "checkbox",
        label: labels.prevThreeMonths,
        click: () => onFilter("prevThreeMonths"),
        checked: period === "prevThreeMonths",
      },
      {
        type: "checkbox",
        label: labels.prevSixMonths,
        click: () => onFilter("prevSixMonths"),
        checked: period === "prevSixMonths",
      },
      {
        type: "checkbox",
        label: labels.prevTwelveMonths,
        click: () => onFilter("prevTwelveMonths"),
        checked: period === "prevTwelveMonths",
      },
    ];

    return (
      <PageTitle
        title="Branch Reporting"
        filter={
          <ContextMenu
            ref={(me) => (this.menu = me)}
            id={this.props.organizationId}
            fixed
            placement="bottom-start"
            menuItems={menuItems}
          >
            {(showMenu, ref, _, buttonProps) => (
              <Button
                {...buttonProps}
                nude
                disclosure={!mobile}
                innerRef={ref}
                onClick={showMenu}
                children={mobile ? undefined : labels[period]}
                qaSelector="period-button"
              />
            )}
          </ContextMenu>
        }
        displayMeta={this.timePeriodString(startDate, endDate)}
      />
    );
  }
}
