// @flow
import React from "react";
import { connect } from "react-redux";
import DocumentTitle from "core/components/DocumentTitle";
import Loaded from "core/components/Loaded";
import { getFeatureEnabled } from "core/selectors/features";
import { getUsernameOrganization } from "core/selectors/organizations";
import { getUserPolicy } from "core/selectors/policies";
import type {
  User,
  Organization,
  Dispatch,
  State,
  ReactRouterLocation,
} from "core/types";
import { closeSidebar } from "../actions/sidebar";
import SettingsShell from "../components/SettingsShell";
import { DEFAULT_VALUES, type FormValues } from "../models/form";
import { getCurrentUser, sessionIsLoading } from "../selectors/session";

type OwnProps = {|
  location: ReactRouterLocation,
|};

type StateProps = {|
  children?: any,
  account: ?User,
  canManageProfile: boolean,
  canManageEmail: boolean,
  canCreateAccessTokens: boolean,
  canManageOAuthApplications: boolean,
  usernameOrganization: ?Organization,
  isLoading: boolean,
  location: Object,
  sidebarIsOpen: boolean,
  accountFormValues: FormValues,
|};

type DispatchProps = {|
  onCloseSidebar: () => void,
|};

type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

function ProfileSettingsShell(props: Props) {
  const {
    account,
    accountFormValues,
    children,
    location,
    isLoading,
    usernameOrganization,
    onCloseSidebar,
    sidebarIsOpen,
    canManageProfile,
    canManageEmail,
    canCreateAccessTokens,
    canManageOAuthApplications,
  } = props;

  return (
    <Loaded loading={isLoading || !account}>
      {() => (
        <SettingsShell
          onCloseSidebar={onCloseSidebar}
          sidebarIsOpen={sidebarIsOpen}
          account={account}
          accountForm={accountFormValues}
          usernameOrganization={usernameOrganization}
          location={location}
          page="account"
          canManageProfile={canManageProfile}
          canManageEmail={canManageEmail}
          canCreateAccessTokens={canCreateAccessTokens}
          canManageOAuthApplications={canManageOAuthApplications}
        >
          <DocumentTitle titleTemplate="Account - %s" defaultTitle="Account" />
          {children}
        </SettingsShell>
      )}
    </Loaded>
  );
}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const usernameOrganization = getUsernameOrganization(state);
  const account = getCurrentUser(state);
  const policy = getUserPolicy(state, { userId: account ? account.id : "" });
  const canManageProfile = policy.manageProfile === true;
  const canManageEmail = policy.manageEmail === true;
  const canCreateAccessTokens = getFeatureEnabled(state, {
    feature: "create-access-tokens",
  });
  const canManageOAuthApplications = getFeatureEnabled(state, {
    feature: "manage-oauth-applications",
  });

  return {
    account,
    usernameOrganization,
    canManageProfile,
    canManageEmail,
    canCreateAccessTokens,
    canManageOAuthApplications,
    sidebarIsOpen: state.sidebar.open,
    accountFormValues: state.forms["account-form"] || DEFAULT_VALUES,
    isLoading: sessionIsLoading(state),
    location: props.location,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    onCloseSidebar: () => dispatch(closeSidebar()),
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(
  mapStateToProps,
  mapDispatchToProps
)(ProfileSettingsShell);
