// @flow
import * as React from "react";
import Flex from "core/components/Flex";
import Icon from "core/components/Icon";
import style from "./style.scss";

type Props = {
  organizationName: string,
  projectColor: string,
  projectName: string,
};

export default function ({
  organizationName,
  projectColor,
  projectName,
}: Props) {
  return (
    <Flex className={style.container}>
      <Icon
        type="project-alt"
        fill={projectColor}
        className={style.projectIcon}
        large
      />
      <Flex justify="center" column>
        <span className={style.projectName}>{projectName}</span>
        <span>{organizationName}</span>
      </Flex>
    </Flex>
  );
}
