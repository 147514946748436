// @flow
import { connect } from "react-redux";
import { isOnline } from "abstract-di/selectors";
import {
  getBranchReview,
  getRequiredReviewerIdsForBranch,
  getReviewRequests,
} from "core/selectors/reviews";
import type { State, Branch } from "core/types";

type OwnProps = {
  projectId: string,
  branch: Branch,
  isMobile?: boolean,
  isTablet?: boolean,
};

function mapStateToProps(state: State, props: OwnProps) {
  const { projectId, branch } = props;

  const branchId = branch.id;
  const branchReview = getBranchReview(state, { branchId, projectId });

  return {
    isMergeable: branchReview ? branchReview.merge : false,
    reviewRequests: getReviewRequests(state, {
      projectId,
      branchId,
      reviewRequestOrder: "reverse",
    }),
    requiredReviewerIds: getRequiredReviewerIdsForBranch(state, {
      projectId,
      branchId,
    }),
    isOffline: !isOnline(state),
  };
}

export default (BranchReviewSummary: *) => {
  /* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
   * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
  return connect(mapStateToProps)(BranchReviewSummary);
};
