// @flow
import Cookies from "js-cookie";
import window from "core/global/window";
import { SECURE_COOKIES_ENABLED } from "./config";

const TOKEN_KEY = "abstract-token-v1";
const DOMAIN = window.location.hostname;

export function setToken(token: string): void {
  Cookies.set(TOKEN_KEY, token, {
    secure: SECURE_COOKIES_ENABLED,
    expires: 30,
  });
}

export function getToken(): ?string {
  return Cookies.get(TOKEN_KEY);
}

export function clearToken(): void {
  Cookies.remove(TOKEN_KEY, { secure: SECURE_COOKIES_ENABLED });
  // This can be removed in September 2017, after existing sessions have
  // expired.
  Cookies.remove(TOKEN_KEY, { secure: SECURE_COOKIES_ENABLED, domain: DOMAIN });
}

const LEGACY_TOKEN_KEY = "abstract-token";

export function getLegacyToken(): ?string {
  return Cookies.get(LEGACY_TOKEN_KEY);
}

export function clearLegacyToken(): void {
  Cookies.remove(LEGACY_TOKEN_KEY, {
    secure: SECURE_COOKIES_ENABLED,
    domain: DOMAIN,
  });
}
