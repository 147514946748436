// @flow
import classnames from "classnames";
import * as React from "react";
import Flex from "core/components/Flex";
import Popover from "core/components/Popover";
import style from "./style.scss";

type Props = {
  className?: string,
  active?: boolean,
  disabled?: boolean,
  active?: boolean,
  allgood?: boolean,
  danger?: boolean,
  primary?: boolean,
  tooltip?: string,
  children: React.Node,
  onClick?: (event: SyntheticEvent<*>) => void,
  qaSelector?: string,
};

export default function ButtonControllerButton(props: Props) {
  const children = (
    <Flex align="center" justify="center" className={style.inner}>
      {props.children}
    </Flex>
  );

  return (
    <button
      onClick={props.onClick}
      disabled={props.disabled}
      aria-pressed={props.active}
      className={classnames(style.button, props.className, {
        [style.disabled]: props.disabled,
        [style.active]: props.active,
        [style.inactive]: !props.active,
        [style.allgood]: props.allgood,
        [style.primary]: props.primary,
        [style.danger]: props.danger,
        [style.neutral]: !props.allgood && !props.danger && !props.primary,
      })}
      data-qa={props.qaSelector}
    >
      {props.tooltip ? (
        <Popover placement="bottom" label={props.tooltip} delayShow={750}>
          {children}
        </Popover>
      ) : (
        children
      )}
    </button>
  );
}
