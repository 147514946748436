// @flow
import * as React from "react";
import Note from "core/components/Note";
import style from "./style.scss";

type Props = {
  description?: React.Element<any>,
  className?: string,
  totalPrice: string,
  monthlyPrice: number,
  annual: boolean,
  quantity: number,
};

export default function PriceCalculation({
  description,
  className,
  totalPrice,
  monthlyPrice,
  annual,
  quantity,
}: Props) {
  return (
    <span className={className}>
      {description && <p>{description}</p>}
      <Note className={style.formula}>
        {quantity} {quantity === 1 ? "contributor" : "contributors"} &times; $
        {monthlyPrice} per month {annual && <span>&times; 12 months</span>} = $
        {totalPrice} USD
      </Note>
    </span>
  );
}
