// @flow
import classnames from "classnames";
import * as React from "react";
import Avatar from "core/components/Avatar";
import Breadcrumb from "core/components/Breadcrumb";
import Heading from "core/components/Heading";
import Icon from "core/components/Icon";
import style from "./style.scss";

type Props = {|
  // `actions` is an optional list of buttons. At desktop sizes, `actions` can
  // be a list of buttons with text.  At mobile sizes, pass a single, nude,
  // icon-only button.
  +actions?: React.Node,
  // `breadcrumb` is an optional list of links. Each link in this list will be
  // cloned to add a className so it can be styled appropriately, so no need
  // to style it when passing it in to this component. This will not be
  // rendered at mobile sizes.
  +breadcrumb?: React.Node,
  // `displayControl` is an optional control to manage how the page is
  // displayed. At the time of this writing, it's mainly used to control
  // whether a list is rendered as a grid of cards or as a list. Not rendered
  // on mobile, as we generally don't allow different layout options at mobile
  // sizes.
  +displayControl?: React.Node,
  // `displayMeta` is an optional control to show additional meta information
  // on the page. At the time of this writing, it's only used on the Reporting
  // page to show specific dates based on the filters.
  +displayMeta?: string,
  // `filterDropdown` is an optional control to filter what is being rendered
  // on the page. When filters are implemented as links, use the `menu` prop
  // instead.
  +filter?: React.Node,
  // `navigation` is an optional list of links. Sometimes it might be a filter
  // of some kind, like "favorites", "active", and "archived" projects. In
  // some cases, they might be navigation links like "commits" and "files" on
  // a branch view.
  +navigation?: React.Node,
  // `search` is an optional search control, generally implemented with
  // `InputSearch`. At mobile sizes it should be a large version of a search
  // input at least 32px tall so that it is an adequately sized touch target.
  // If using `InputSearch`, you should give it the `large` prop at mobile
  // sizes, which will take care of this for you.
  +search?: React.Node,
  // `sort` is an optional control to manage how things are sorted on the
  // page. This should generally be a dropdown with the `sort` icon. At mobile
  // sizes, the trigger should be a nude icon-only button with the `sort`
  // icon, which will appear next to the search input.
  +sort?: React.Node,
  // `sortClassname` allows you to specify a class to any sort element
  +sortClassname?: string,
  // `subtitle` is a string or element to display underneath the title. At the
  // moment, only the project overview screen uses this to display the project type.
  +subtitle?: React.Node,
  // `title` is the title of the page which will get rendered in an `h1`.
  +title: string,
  // `titleSize` determines the size of the title, and should be correlated to
  // whether the page title is hard-coded or user-generated. For pages like
  // projects, collections, etc., the titles are hard-coded like "Projects"
  // and should use the "default" size. The title of a branch page, on the
  // other hand, will be a user-generated value like "Iconography Updates" and
  // should use the "small" title size variant.
  +titleSize?: "default" | "small",
  // `titleAvatarUserId` allows you, on certain headers (only the branch page
  // header at the time of this writing), to render an avatar as a prefix to
  // the title.
  +titleAvatarUserId?: string,
  // `titleStatusBadge` allows you, on certain headers (only the branch page
  // at the time of this writing), to render a status badge as a suffix to the
  // title.
  +titleStatusBadge?: React.Node,
  // `titleIcon` allows you to specify an icon to be prepended before the title.
  // Not to be used in combination with `titleAvatarUserId`.
  +titleIcon?: string,
  // `controlsClassname` allows you to speficy a css class to the extra
  // controls subheader container.
  +controlsClassname?: string,
|};

export default function PageTitle(props: Props) {
  const hasExtraControls =
    !!props.sort ||
    !!props.search ||
    !!props.displayControl ||
    !!props.displayMeta;

  return (
    <div className={style.wrapper}>
      {props.breadcrumb && (
        <div className={style.breadcrumbWrapper}>
          <Breadcrumb crumbs={props.breadcrumb} />
        </div>
      )}
      <div
        className={classnames(style.title, {
          [style.titleSmall]: props.titleSize === "small",
        })}
      >
        <div className={style.titleMain}>
          {props.titleAvatarUserId && (
            <div className={style.titleAvatarIcon}>
              <Avatar userId={props.titleAvatarUserId} showNamePopover />
            </div>
          )}
          {props.titleIcon && (
            <div className={style.titleIcon}>
              <Icon type={props.titleIcon} />
            </div>
          )}
          <Heading
            level="1"
            size={props.titleSize === "small" ? "xl" : "xxxl"}
            className={classnames(style.heading, {
              [style.headingSize]: props.titleSize === "small",
            })}
            title={props.title}
            qaSelector="pageTitle"
          >
            {props.title}
          </Heading>
          {props.titleStatusBadge}
        </div>
        {props.actions ? (
          <div className={style.actions}>
            {React.Children.map(props.actions, (action) => (
              <div className={style.action}>{action}</div>
            ))}
          </div>
        ) : null}
      </div>

      {props.subtitle && <div>{props.subtitle}</div>}

      <div className={classnames(style.controls, props.controlsClassname)}>
        {props.navigation && (
          <div className={style.navigation}>
            {React.Children.map(props.navigation, (navigationLink) => (
              <span className={style.navigationLink}>{navigationLink}</span>
            ))}
          </div>
        )}

        {props.filter && <div className={style.filter}>{props.filter}</div>}

        {hasExtraControls && (
          <div className={style.extras}>
            {props.sort ? (
              <div className={classnames(style.sort, props.sortClassname)}>
                {" "}
                {props.sort}
              </div>
            ) : null}
            {props.search ? (
              <div className={style.search}> {props.search}</div>
            ) : null}
            {props.displayControl ? (
              <div className={style.displayControl}>
                {" "}
                {props.displayControl}
              </div>
            ) : null}
            {props.displayMeta ? (
              <div className={style.displayMeta} data-qa="meta-data">
                {props.displayMeta}
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
}
