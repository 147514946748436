// @flow
import React, { Component } from "react";
import DialogForm from "core/components/DialogForm";
import Note from "core/components/Note";
import * as File from "core/models/file";
import type { Library } from "core/types";
import connector from "./connector";

export type OwnProps = {|
  projectId: string,
  branchId: string,
  library: Library,
|};

export type DispatchProps = {|
  addLibrary: (projectId: string, branchId: string, library: Library) => void,
  dismissDialog: () => void,
|};

export type Props = {
  ...OwnProps,
  ...DispatchProps,
};

class ConfirmAddLibrary extends Component<Props> {
  handleSubmit = (event: SyntheticEvent<>) => {
    event.preventDefault();
    this.props.addLibrary(
      this.props.projectId,
      this.props.branchId,
      this.props.library
    );
    this.props.dismissDialog();
  };
  render() {
    const appName = File.fileTypeAppName(this.props.library.type);

    return (
      <DialogForm
        title="Use as Library"
        primaryButton="Use as Library"
        onSubmit={this.handleSubmit}
        onClose={this.props.dismissDialog}
        isOpen
      >
        <p>
          Symbols from library files are automatically available to insert in
          {"  " + appName} when you open other files on this branch.
        </p>
        <Note>
          This file will also become available to link from other projects in
          this organization.
        </Note>
      </DialogForm>
    );
  }
}

export default connector(ConfirmAddLibrary);
