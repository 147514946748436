// @flow
import * as React from "react";
import image from "./images/activity.svg";
import Empty from ".";

export default function NoCommits(props: {}) {
  return (
    <Empty
      {...props}
      image={image}
      title="No commits"
      description="Commits are snapshots of your work over time, we'll list them here once you start working on this branch."
    />
  );
}
