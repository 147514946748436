// @flow
import classnames from "classnames";
import * as React from "react";
import Heading from "core/components/Heading";
import Icon from "core/components/Icon";
import ProjectIcon from "core/components/ProjectIcon";
import { V3Link as Link } from "core/lib/router";
import { projectPath } from "core/lib/routes";
import type { Project } from "core/types";
import style from "./style.scss";

export type Props = {|
  project: Project,
  className?: string,
|};

export default function ProjectHeader({ className, project, ...rest }: Props) {
  const classes = classnames(style.projectHeader, className);
  const isPrivate = project.visibility === "specific";

  return (
    <Heading level="2" size="l" className={classes}>
      <Link className={style.link} to={projectPath(project.id)}>
        <ProjectIcon className={style.projectIcon} color={project.color} />
        <span>{project.name}</span>
        {isPrivate && (
          <Icon className={style.projectLockIcon} type="lock-locked" />
        )}
        <Icon type="go-to" />
      </Link>
    </Heading>
  );
}
