// @flow
import Immutable from "seamless-immutable";
import type { Action } from "../types";

type PreviewGenerationState = (?string)[];

function updateState(
  state: PreviewGenerationState,
  add: string,
  remove: string
): PreviewGenerationState {
  if (state && state.includes(add)) {
    return state;
  }
  return [...state.filter((el) => el !== remove), add];
}

export default function previewGeneration(
  state: PreviewGenerationState = Immutable.from([]),
  action: Action
): PreviewGenerationState {
  switch (action.type) {
    case "PREVIEW_GENERATION_FAILED":
      return updateState(
        state,
        `failed:${action.payload.projectId}-${action.payload.sha}`,
        `started:${action.payload.projectId}-${action.payload.sha}`
      );
    case "PREVIEW_GENERATION_STARTED":
      return updateState(
        state,
        `started:${action.payload.projectId}-${action.payload.sha}`,
        `failed:${action.payload.projectId}-${action.payload.sha}`
      );
    default:
      return state;
  }
}
