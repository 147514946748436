// @flow

export function timeout<T>(
  promise: Promise<T>,
  ms: number,
  error: string = "Timeout"
): Promise<T> {
  const timeout = new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      clearTimeout(timeoutId);
      reject(new Error(error));
    }, ms);
  });

  return Promise.race([promise, timeout]);
}
