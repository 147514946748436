// @flow
import classnames from "classnames";
import { groupBy, memoize } from "lodash";
import * as React from "react";
import { Waypoint } from "react-waypoint";
import BranchListItem from "core/components/BranchListItem";
import Centered from "core/components/Centered";
import Loading from "core/components/Loading";
import VirtualizedList, { type Row } from "core/components/VirtualizedList";
import type {
  Project,
  BranchFilter,
  BranchHierarchy,
  ReviewRequest,
} from "core/types";
import style from "./style.scss";

type Props = {|
  className?: string,
  project: Project,
  branches: BranchHierarchy[],
  reviewRequests?: ReviewRequest[],
  hasHierarchy?: boolean,
  branchPath: (projectId: string, branchId: string) => string,
  onShowMenu?: (SyntheticEvent<*>, branchId: string) => void,
  mobile?: boolean,
  branchFilter: BranchFilter,
  hasNextPage?: boolean,
  isLoadingNextPage?: boolean,
  onLoadNextPage?: () => void,
|};

const VERTICAL_PADDING = 24;
const DEFAULT_BRANCH_ROW_HEIGHT = 62;
const BRANCH_WITH_DESCRIPTION_ROW_HEIGHT = 82;

export default class BranchList extends React.Component<Props> {
  branchList: React.Ref<VirtualizedList>;

  branchListRef = React.createRef<VirtualizedList>();

  componentDidUpdate(prevProps: Props) {
    if (prevProps.branchFilter !== this.props.branchFilter) {
      const branchList = this.branchListRef.current;
      if (branchList) {
        branchList.scrollTo({ index: 0 });
      }
    }
  }

  get verticalPadding(): number {
    return this.props.mobile ? 0 : VERTICAL_PADDING;
  }

  getReviewRequests: (?(ReviewRequest[])) => {
    [branchId: string]: ReviewRequest[],
  } = memoize((reviewRequests) => groupBy(reviewRequests, "branchId"));

  getItems = (): Row[] => {
    const items = [];

    const reviewRequestsByBranchId = this.getReviewRequests(
      this.props.reviewRequests
    );

    this.props.branches.forEach((branchItem, index) =>
      items.push({
        style: (rowStyle) => ({
          ...rowStyle,
          top: rowStyle.top + this.verticalPadding,
        }),
        defaultHeight: branchItem.branch.description
          ? BRANCH_WITH_DESCRIPTION_ROW_HEIGHT
          : DEFAULT_BRANCH_ROW_HEIGHT,
        className: style.rowContainer,
        children: this.renderBranchItem(
          branchItem,
          index,
          reviewRequestsByBranchId[branchItem.branch.id]
        ),
      })
    );

    if (this.props.hasNextPage) {
      items.push({
        style: (rowStyle) => ({
          ...rowStyle,
          top: rowStyle.top + VERTICAL_PADDING,
        }),
        height: 48,
        children: (
          <Centered>
            <Loading small />
          </Centered>
        ),
      });
    }

    return items;
  };

  renderBranchItem = (
    branchItem: BranchHierarchy,
    index: number,
    reviewRequests: ReviewRequest[]
  ) => {
    return (
      <BranchListItem
        className={classnames(style.listRow, {
          [style.mobileListRow]: this.props.mobile,
          [style.firstRow]: index === 0,
          [style.lastRow]: index === this.props.branches.length - 1,
        })}
        key={branchItem.branch.id}
        mobile={this.props.mobile}
        project={this.props.project}
        branch={branchItem.branch}
        reviewRequests={reviewRequests}
        to={this.props.branchPath(this.props.project.id, branchItem.branch.id)}
        level={this.props.hasHierarchy ? branchItem.level : 0}
        wrappedList
      />
    );
  };

  renderInnerListElement = ({
    children,
    style: innerElementStyle,
    ...rest
  }: {
    children: React.Node,
    style: { height: number },
  }) => (
    <div
      {...rest}
      style={{
        ...innerElementStyle,
        height: innerElementStyle.height + this.verticalPadding * 2,
      }}
    >
      {children}

      {this.props.onLoadNextPage ? (
        <div className={style.waypoint}>
          <Waypoint topOffset="1440px" onEnter={this.props.onLoadNextPage} />
        </div>
      ) : null}
    </div>
  );

  render() {
    return (
      <div className={this.props.className}>
        <VirtualizedList
          ref={this.branchListRef}
          items={this.getItems()}
          innerElementType={this.renderInnerListElement}
          resizeProps={{
            branchFilter: this.props.branchFilter,
            branchesCount: this.props.branches.length,
            mobile: this.props.mobile,
            reviewRequests: this.props.reviewRequests,
          }}
        />
      </div>
    );
  }
}
