// @flow
import { object as EMPTY_OBJECT } from "empty";
import { connect } from "react-redux";
import { isLoggedIn } from "abstract-di/selectors";
import { getCommentFormId } from "core/components/CommentCreateForm";
import connectStorage from "core/hocs/connectStorage";
import createConnector from "core/lib/createConnector";
import { getBranch } from "core/selectors/branches";
import {
  getAnnotationsForLayer,
  getNonResolvedCommentAnnotationsForLayer,
} from "core/selectors/comments";
import { getCommitsForLayer, getCommitForLayer } from "core/selectors/commits";
import { canUseBackgroundPreviewSwitcher } from "core/selectors/features";
import { getFileForLayer } from "core/selectors/files";
import { getLayer, getLayerState } from "core/selectors/layers";
import { getPageForLayer } from "core/selectors/pages";
import { getProject } from "core/selectors/projects";
import type { State, Dispatch } from "core/types";
import type {
  Props,
  PropsWithoutStorage,
  StorageProps,
  OwnProps,
  StateProps,
} from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { projectId, branchId } = props.params;
  const project = getProject(state, { projectId });
  const branch = getBranch(state, { projectId, branchId });
  const layer = !props.isLoadingMore ? getLayer(state, props.params) : null;
  const commits = getCommitsForLayer(state, props.params);
  const commit = getCommitForLayer(state, props.params);
  const file = getFileForLayer(state, props.params);
  const page = layer
    ? getPageForLayer(state, { ...props.params, pageId: layer.pageId })
    : undefined;
  const annotations = props.isShowingResolvedCommentAnnotations
    ? getAnnotationsForLayer(state, props.params)
    : getNonResolvedCommentAnnotationsForLayer(state, props.params);
  const allAnnotationsLength = getAnnotationsForLayer(
    state,
    props.params
  ).length;
  const layerState = getLayerState(state, props.params);
  const latestCommit = commits && commits.length > 1 ? commits[0] : undefined;
  const commentFormId = isLoggedIn(state) ? getCommentFormId(props.params) : "";

  return {
    annotations,
    allAnnotationsLength,
    project,
    branch,
    commentFormId,
    commit,
    file,
    isLoggedIn: isLoggedIn(state),
    page,
    latestCommit,
    layer,
    layerState,
    canUsePreviewBackgroundSwitcher: canUseBackgroundPreviewSwitcher(
      state,
      projectId
    ),
  };
}

function mapStorageToProps(storage, props: OwnProps): StorageProps {
  const previous = storage.getItem("LayerDesign") || EMPTY_OBJECT;

  return {
    handleForm: (commentFormId) => {
      return previous[commentFormId] || EMPTY_OBJECT;
    },
    onFormChanged: (form, commentFormId) => {
      storage.setItem("LayerDesign", {
        ...previous,
        [commentFormId]: form,
      });
    },
    defaultCollapsedLeftSidebar:
      props.defaultCollapsedLeftSidebar === undefined
        ? previous.defaultCollapsedLeftSidebar
        : props.defaultCollapsedLeftSidebar,
    defaultCollapsedRightSidebar:
      props.commentId === undefined && // force sidebar open if commentId is present
      (props.defaultCollapsedRightSidebar === undefined
        ? previous.defaultCollapsedRightSidebar
        : props.defaultCollapsedRightSidebar),
    onCollapseLeftSidebar: (defaultCollapsedLeftSidebar) => {
      const previous = storage.getItem("LayerDesign") || EMPTY_OBJECT;

      storage.setItem("LayerDesign", {
        ...previous,
        defaultCollapsedLeftSidebar,
      });

      if (props.onCollapseLeftSidebar) {
        props.onCollapseLeftSidebar(defaultCollapsedLeftSidebar);
      }
    },
    onCollapseRightSidebar: (defaultCollapsedRightSidebar) => {
      const previous = storage.getItem("LayerDesign") || EMPTY_OBJECT;

      storage.setItem("LayerDesign", {
        ...previous,
        defaultCollapsedRightSidebar,
      });

      if (props.onCollapseRightSidebar) {
        props.onCollapseRightSidebar(defaultCollapsedRightSidebar);
      }
    },
  };
}

export default createConnector<Props, OwnProps>(
  (Component) => connectStorage(Component, mapStorageToProps),
  connect<PropsWithoutStorage, OwnProps, StateProps, _, State, Dispatch>(
    mapStateToProps
  )
);
