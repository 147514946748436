// @flow
import * as React from "react";
import Button from "core/components/Button";
import Flex from "core/components/Flex";
import FormSection from "core/components/FormSection";
import Heading from "core/components/Heading";
import window from "core/global/window";
import { enterpriseScheduleDemoUrl, helpSamlSingleSignOn } from "core/lib/urls";

import style from "./style.scss";

export default function SSOUnavailable() {
  return (
    <FormSection heading="Configure SSO">
      <Flex align="center" className={style.ssoUnavailable} column>
        <Heading level="2" size="xl">
          SSO is available on our Enterprise plan
        </Heading>
        <p>
          SSO provides a secure way to let users log in to Abstract.{" "}
          <a
            href={helpSamlSingleSignOn()}
            rel="noopener noreferrer"
            target="_blank"
          >
            Learn more…
          </a>
        </p>
        <Button
          onClick={() => window.open(enterpriseScheduleDemoUrl())}
          large
          primary
        >
          Upgrade to Enterprise…
        </Button>
      </Flex>
    </FormSection>
  );
}
