// @flow
import apiRequest from "abstract-di/api";
import { trackEvent } from "core/actions/analytics";
import { entitiesReceived, entitiesDeleted } from "core/actions/entities";
import { starsLoadedAll, type StarParams } from "core/actions/stars";
import { normalizeStars } from "core/schemas/star";
import type { Star } from "core/types";
import defineRequest from "./";

export const StarCreateRequest = defineRequest<StarParams, StarParams>({
  id: (params) => {
    return `put:starred/${params.id}`;
  },
  perform: (params) => {
    return apiRequest("put", `starred/${params.id}`, params);
  },
  onSuccess: (response, params, dispatch) => {
    if (!response) {
      return dispatch(entitiesDeleted({ stars: [params.id] }));
    }

    dispatch(trackEvent("STAR_CREATED"));
  },
});

export const StarDeleteRequest = defineRequest<$Shape<Star>, $Shape<Star>>({
  id: (star) => {
    return `delete:starred/${star.starrableId}`;
  },
  perform: (star) => {
    const { starrableId, starrableType } = star;
    return apiRequest("delete", `starred/${starrableId}`, {
      id: starrableId,
      type: starrableType,
    });
  },
  onSuccess: (response, star, dispatch) => {
    if (!response) {
      const { entities } = normalizeStars([star]);
      return dispatch(entitiesReceived(entities));
    }

    dispatch(trackEvent("STAR_DELETED"));
  },
});

export const StarsFetchRequest = defineRequest<{}, {}>({
  id: () => "get:starred",
  perform: () => apiRequest("get", "starred", {}, 9),
  onSuccess: (response, params, dispatch) => {
    const { entities } = normalizeStars(response);
    dispatch(entitiesReceived(entities));
    if (entities.stars) {
      dispatch(starsLoadedAll(entities.stars));
    }
  },
  force: false,
});
