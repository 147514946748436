// @flow
import * as React from "react";
import BranchList from "core/components/BranchList";
import Expand from "core/components/Expand";
import OverviewCard from "core/components/ProjectOverview/OverviewCard";
import { branchPath, projectPath } from "core/lib/routes";
import type { Project, BranchHierarchy, ReviewRequest } from "core/types";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  mobile?: boolean,
  project: Project,
|};

export type StateProps = {|
  branches: BranchHierarchy[],
  reviewRequests: ReviewRequest[],
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
};

type State = {
  expanded: boolean,
};

class MyBranches extends React.Component<Props, State> {
  maxBranches = 8;

  state = {
    expanded: false,
  };

  toggleExpanded = () => {
    this.setState((prevState) => ({
      expanded: !prevState.expanded,
    }));
  };

  render() {
    const numberOfBranches = this.props.branches.length;
    const noBranches = numberOfBranches === 0;
    if (noBranches) {
      return null;
    }

    const branchesToShow = this.state.expanded
      ? this.props.branches
      : this.props.branches.slice(0, this.maxBranches);

    const showExpand = numberOfBranches > this.maxBranches;

    return (
      <OverviewCard
        hasFooter={showExpand}
        hasList={!noBranches}
        heading="My Branches"
        mobile={this.props.mobile}
        storageId="ProjectOverview-MyBranches"
        to={projectPath(this.props.project.id, "branches", { filter: "mine" })}
        noPadding
      >
        <React.Fragment>
          <BranchList
            flat
            mobile={this.props.mobile}
            project={this.props.project}
            branches={branchesToShow}
            branchPath={branchPath}
            reviewRequests={this.props.reviewRequests}
          />
          {showExpand && (
            <Expand
              hideBorder
              onClick={this.toggleExpanded}
              expanded={this.state.expanded}
              className={style.isContained}
            >
              Showing {this.maxBranches} of {numberOfBranches} Branches
            </Expand>
          )}
        </React.Fragment>
      </OverviewCard>
    );
  }
}

export default connector(MyBranches);
