// @flow
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import FormHeader from "core/components/FormHeader";
import Loaded from "core/components/Loaded";
import Media from "core/components/Media";
import SettingsForm from "core/components/SettingsForm";
import { V3Link as Link } from "core/lib/router";
import { OAuthApplicationRequest } from "web/requests/oAuthApplications";
import { accountOauthApplications } from "web/routeHelpers";
import { getOAuthApplicationsEntities } from "web/selectors/oAuthApplications";
import DeleteSection from "./DeleteSection";
import DetailsSection from "./DetailsSection";

import style from "./style.scss";
type Props = {
  params: {
    organizationId: string,
    id: string,
  },
};

const OAuthApplicationDetails = ({ params: { organizationId, id } }: Props) => {
  const dispatch = useDispatch();
  const application = useSelector((state) => {
    const apps = getOAuthApplicationsEntities(state);
    return apps[id];
  });
  const isLoading = useSelector((state) =>
    OAuthApplicationRequest.isFirstLoading(state, { id })
  );

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = (isSuccess) => {
    if (isSuccess) {
      setSuccessMessage("Application updated");
    } else {
      setErrorMessage("Application could not be updated");
    }
  };

  const clearMessages = () => {
    setSuccessMessage("");
    setErrorMessage("");
  };

  useEffect(() => {
    dispatch(
      OAuthApplicationRequest.perform({
        params: { organizationId, id },
      })
    );
  }, [dispatch, id, organizationId]);

  if (!application) {
    return null;
  }

  const header = (
    <Media desktop>
      {(desktop) => (
        <FormHeader
          flashBanner
          success
          successMessage={successMessage}
          error={errorMessage}
          mobile={!desktop}
          heading={
            <Link className={style.headerLink} to={accountOauthApplications()}>
              OAuth Applications
            </Link>
          }
          subheading={application.name}
          onBannerHide={clearMessages}
        />
      )}
    </Media>
  );

  return (
    <Loaded loading={isLoading} flex title="Loading OAuth application details…">
      <SettingsForm header={header}>
        <DetailsSection
          application={application}
          onAfterSubmit={handleSubmit}
        />
        <div className={style.section}>
          You will need to send the Client Secret along with your Client ID when
          making your OAuth 2.0 access request.
        </div>
        <DeleteSection application={application} />
      </SettingsForm>
    </Loaded>
  );
};

export default OAuthApplicationDetails;
