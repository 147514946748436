// @flow
import invariant from "invariant";
import { connect } from "react-redux";
import { isOnline } from "abstract-di/selectors";
import {
  removeCollectionLayer,
  updateUseLatestCommitForCollectionLayer,
} from "core/actions/collectionLayers";
import { entitiesReceived } from "core/actions/entities";
import { replace, removeQuery } from "core/lib/location";
import { reorder } from "core/lib/reordering";
import { shareLinkPath, layerLocation } from "core/lib/routes";
import { isPublicCollection, inspectEnabled } from "core/models/shareLink";
import {
  CollectionLayerCreateRequest,
  CollectionLayerUpdateRequest,
  CollectionLayerMoveRequest,
} from "core/requests/collections";

import { normalizeCollectionLayers } from "core/schemas/collection";
import { getLayerForCollectionLayer } from "core/selectors/collections";
import { getCommentCounts } from "core/selectors/comments";
import { getProjectPolicy } from "core/selectors/policies";
import type { State, Dispatch } from "core/types";
import type { Props, OwnProps, StateProps, DispatchProps } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { projectId, branchId } = props.collection;
  const { shareLink } = props;
  const projectPolicy = getProjectPolicy(state, { projectId });

  return {
    isOnline: isOnline(state),
    shareLinkId:
      shareLink && isPublicCollection(shareLink) ? shareLink.id : undefined,
    canInspect: !shareLink || inspectEnabled(shareLink),
    canAccessProject: projectPolicy.show,
    layerCommentCounts: getCommentCounts(state, {
      projectId,
      branchId,
      key: "layerId",
    }),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { projectId, branchId } = props.collection;

  return {
    onChangeAutoUpdateLayer: (collectionLayerId, autoUpdate) => {
      dispatch(
        updateUseLatestCommitForCollectionLayer({
          collectionLayerId,
          useLatestCommit: autoUpdate,
          projectId,
          branchId,
        })
      );
    },
    onRemoveLayer: (collectionLayerId, onNeedsConfirmation) => {
      dispatch(
        removeCollectionLayer(projectId, collectionLayerId, onNeedsConfirmation)
      );
    },
    onMoveLayer:
      (collectionLayers) =>
      async (collectionLayer, { oldIndex, newIndex }) => {
        const layers = reorder(collectionLayers, {
          oldIndex,
          newIndex,
          key: "order",
        });

        dispatch(entitiesReceived(normalizeCollectionLayers(layers).entities));

        dispatch(
          CollectionLayerMoveRequest.perform({
            params: {
              projectId,
              id: collectionLayer.id,
              order: newIndex + 1,
            },
          })
        );
      },
    handlePresentLayer: (collectionLayerId) => {
      dispatch(async (dispatch, getState) => {
        const state = getState();
        const layer = getLayerForCollectionLayer(state, { collectionLayerId });
        const collectionItem = props.collectionItems.find(
          (layer) => layer.id === collectionLayerId
        );

        invariant(
          collectionItem,
          "Cannot present collection, could not find collection layer"
        );
        invariant(props.branch, "Cannot present collection, branch not loaded");

        if (collectionItem && collectionItem.kind === "layer") {
          const collectionLayer = collectionItem;

          const sha = layer ? layer.lastChangedAtSha : collectionLayer.sha;
          const options = {
            collectionLayerId: collectionLayerId,
            collectionId: props.collection.id,
            returnTo: removeQuery(
              "collectionLayerId",
              "mode",
              "sha",
              "selected"
            ),
            present: "true",
            preview: "false",
            sha,
          };

          replace(
            props.shareLink
              ? shareLinkPath(props.shareLink.id, options)
              : layerLocation(
                  projectId,
                  branchId,
                  sha,
                  collectionLayer.fileId,
                  collectionLayer.layerId,
                  options
                )
          );
        }
      });
    },
    onCreateCollectionText: (textContent: string, onSuccess?: () => void) => {
      dispatch(
        CollectionLayerCreateRequest.perform({
          params: {
            kind: "text",
            textContent,
            collectionId: props.collection.id,
            projectId,
          },
          onSuccess,
        })
      );
    },
    onUpdateCollectionText: (id: string, textContent: string) => {
      dispatch(
        CollectionLayerUpdateRequest.perform({
          params: {
            collectionId: props.collection.id,
            projectId: projectId,
            id,
            textContent,
          },
        })
      );
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
