// @flow
import classnames from "classnames";
import idx from "idx";
import * as React from "react";
import Button from "core/components/Button";
import Input from "core/components/Input";
import RecurlyBillingInfoForm from "core/components/RecurlyBillingInfoForm";
import { updateSubscription } from "web/api";
import type { Subscription } from "web/types";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  className?: string,
  planName?: string,
  planAnnual?: boolean,
  organizationId: string,
  onComplete: (Subscription) => Promise<void>,
  submitText?: string,
  children?: any,
|};

export type DispatchProps = {|
  createPaymentToken: (
    form: EventTarget,
    onSuccessCallback?: (tokenId: string) => void,
    onErrorCallback?: (Error) => void
  ) => void,
  on3DSecureChallengeStarted: (actionToken: string) => void,
  handleSubmitError: (message?: ?string) => void,
|};

export type Props = {|
  ...OwnProps,
  ...DispatchProps,
|};

type State = {
  isSubmitting: boolean,
  country: string,
  region: string,
  tokenId: string,
};

class BillingForm extends React.Component<Props, State> {
  firstName: ?Input;
  lastName: ?Input;

  state = {
    tokenId: "",
    isSubmitting: false,
    country: "",
    region: "",
  };

  fieldsToUpdate = (tokenId: string, actionResultToken?: string) => {
    return {
      tokenId,
      firstName: this.firstName ? this.firstName.input.value : "",
      lastName: this.lastName ? this.lastName.input.value : "",
      plan: this.props.planName,
      annual: this.props.planAnnual,
      actionResultToken,
    };
  };

  attemptToUpdateSubscription = async (
    tokenId: string,
    actionResultToken?: string
  ) => {
    let subscription;

    try {
      subscription = await updateSubscription(
        this.props.organizationId,
        this.fieldsToUpdate(tokenId, actionResultToken)
      );
    } catch (err) {
      const error = err.body;
      if (actionResultToken) {
        this.props.handleSubmitError(idx(error, (_) => _.errors.card));
        this.setState({ isSubmitting: false });
      } else {
        const actionToken = idx(error, (_) => _.errors.actionToken);

        if (!actionToken) {
          this.props.handleSubmitError(idx(error, (_) => _.errors.card));
          this.setState({ isSubmitting: false });
          return;
        }

        this.props.on3DSecureChallengeStarted(actionToken);
      }
    }

    if (subscription) {
      this.props.onComplete(subscription);
    }
  };

  handle3DSecureChallengeError = (error: Error) => {
    this.setState({ isSubmitting: false });
  };

  handle3DSecureChallengeCompleted = (actionResultToken: string) => {
    this.attemptToUpdateSubscription(this.state.tokenId, actionResultToken);
  };

  handleSubmit = (event: SyntheticEvent<>) => {
    event.preventDefault();
    this.setState({ isSubmitting: true });

    this.props.createPaymentToken(
      event.target,
      (tokenId) => {
        this.setState({ tokenId: tokenId });
        this.attemptToUpdateSubscription(tokenId);
      },
      (error) => {
        this.setState({ isSubmitting: false });
      }
    );
  };

  render() {
    const { submitText = "Confirm Subscription" } = this.props;

    return (
      <form
        className={classnames(style.form, this.props.className)}
        onSubmit={this.handleSubmit}
      >
        <RecurlyBillingInfoForm
          submitText={submitText}
          agreeToTermsOfService
          on3DSecureChallengeCompleted={this.handle3DSecureChallengeCompleted}
          on3DSecureChallengeError={this.handle3DSecureChallengeError}
        >
          {this.props.children}
        </RecurlyBillingInfoForm>

        <div className={style.footer}>
          <Button
            type="submit"
            disabled={this.state.isSubmitting}
            large
            fullwidth
            primary
          >
            {this.state.isSubmitting ? "Submitting…" : submitText}
          </Button>
        </div>
      </form>
    );
  }
}

export default connector(BillingForm);
