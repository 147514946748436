// @flow
import invariant from "invariant";
// This file is imported into the Storybook manager for theme control, and as
// such cannot use "core" alias, must be a relative import.
// ref: https://github.com/goabstract/ui/blob/bbfb0d8da3a72cda96f98d981a7ba88700e16117/core/.storybook/addons/theme/Control.js#L5
import window from "../global/window";
const STORAGE_KEY = "appTheme";

type DoneCallback = () => void;
type CleanupCallback = () => void;

export type ThemeConfig = "light" | "dark" | "system";
export type ThemeName = "light" | "dark";
export type ThemeFill = "pattern" | "solid";
export type ThemeOptions = {
  fill?: ThemeFill,
};

export type ThemePlugin = (
  setState: (state: ThemeConfig, done?: DoneCallback) => void
) => CleanupCallback;

export function getDefaultThemeConfig() {
  const storedThemeConfig = window.localStorage.getItem(STORAGE_KEY);
  return storedThemeConfig ? JSON.parse(storedThemeConfig).theme : "system";
}

export function resolveThemeName(themeConfig: ThemeConfig): ThemeName {
  if (themeConfig === "system") {
    return window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light";
  }

  return themeConfig;
}
// Only used in storybook to load the theme
export function loadTheme(): ThemeName {
  return resolveThemeName(getDefaultThemeConfig());
}

export function setThemeAttribute(themeConfig: ThemeConfig) {
  invariant(window.document.documentElement, "documentElement does not exist");
  if (themeConfig !== "system") {
    window.document.documentElement.setAttribute("data-theme", themeConfig);
  } else {
    window.document.documentElement.removeAttribute("data-theme");
  }
}

export function setTheme(themeConfig: ThemeConfig) {
  window.localStorage.setItem(
    STORAGE_KEY,
    JSON.stringify({ theme: themeConfig })
  );

  withThemeTransition(() => {
    setThemeAttribute(themeConfig);
  });
}

function withThemeTransition(
  callback: () => void,
  options: Object = { className: "theme-transition", duration: 0 }
) {
  invariant(window.document.documentElement, "documentElement does not exist");
  window.document.documentElement.classList.add(options.className);

  callback();

  setTimeout(() => {
    invariant(
      window.document.documentElement,
      "documentElement does not exist"
    );
    window.document.documentElement.classList.remove(options.className);
  }, options.duration);
}
