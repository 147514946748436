// @flow
import capitalize from "lodash/capitalize";
import * as React from "react";
import AnimatedFlyover from "core/components/AnimatedFlyover";
import ColorSwatch from "core/components/ColorSwatch";
import FloatingMenuItem from "core/components/FloatingMenuItem";
import MenuDivider from "core/components/MenuDivider";
import { useCustomTheme } from "core/components/Theme/useCustomTheme";
import type { ThemeConfig, ThemeFill } from "core/lib/theme";
import Button from "../Button";
import style from "./style.scss";

export const PREVIEW_THEME_KEY = "preview-background-v2";

type Props = {
  className?: string,
};

const themes: ThemeConfig[] = ["system", "light", "dark"];
const fills: ThemeFill[] = ["pattern", "solid"];

export default function ButtonTogglePreviewBackground(props: Props) {
  const [isOpen, setOpen] = React.useState(false);
  const theme = useCustomTheme();

  const previewTheme = theme.getCustomTheme(PREVIEW_THEME_KEY);

  return (
    <React.Fragment>
      <AnimatedFlyover
        isOpen={isOpen}
        onRequestClose={() => setOpen(false)}
        onClickOutside={() => setOpen(false)}
        body={
          <React.Fragment>
            {themes.map((currentTheme) => {
              return (
                <FloatingMenuItem
                  key={currentTheme}
                  onClick={() => {
                    theme.setCustomTheme(PREVIEW_THEME_KEY, currentTheme);
                  }}
                  checkbox
                  checked={previewTheme.config === currentTheme}
                >
                  <div className={style.item}>
                    <span className={style.icon}>
                      <ColorSwatch
                        themeConfig={currentTheme}
                        fill={previewTheme.options && previewTheme.options.fill}
                      />
                    </span>
                    <span className={style.text}>
                      {currentTheme === "system"
                        ? "Auto"
                        : capitalize(currentTheme)}
                    </span>
                  </div>
                </FloatingMenuItem>
              );
            })}
            <MenuDivider />
            {fills.map((option) => {
              return (
                <FloatingMenuItem
                  key={option}
                  onClick={() => {
                    theme.setCustomTheme(
                      PREVIEW_THEME_KEY,
                      previewTheme.config,
                      { fill: option }
                    );
                  }}
                  checkbox
                  checked={
                    previewTheme.options
                      ? previewTheme.options.fill === option
                      : option === "pattern"
                  }
                >
                  <div className={style.item}>
                    <span className={style.text}>{capitalize(option)}</span>
                  </div>
                </FloatingMenuItem>
              );
            })}
          </React.Fragment>
        }
        anchor="topLeft"
        className={style.container}
      >
        <Button
          className={props.className}
          onClick={() => setOpen(!isOpen)}
          nude
          title="Change preview background"
          tooltip={{
            placement: "top",
            forceHide: isOpen,
          }}
        >
          <span className={style.colorSwatchContainer}>
            <ColorSwatch
              themeConfig={previewTheme.config}
              fill={previewTheme.options && previewTheme.options.fill}
            />
          </span>
        </Button>
      </AnimatedFlyover>
    </React.Fragment>
  );
}
