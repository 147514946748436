// @flow
import { static as Immutable } from "seamless-immutable";
import type { Action } from "core/types";

type State = {
  [entityId: string]: {
    commitShas: string[],
    hasMore: boolean,
  },
};

export default function (
  state: State = Immutable.from({}),
  action: Action
): State {
  switch (action.type) {
    case "COMMIT_CREATED": {
      const entityId = `${action.meta.projectId}-${action.meta.branchId}`;
      const currentState = state[entityId] || Immutable.from({});
      const currentCommits = currentState.commitShas || Immutable.from([]);
      const commitShas = [action.payload.sha, ...currentCommits];

      return Immutable.set(
        state,
        entityId,
        Immutable.merge(currentState, { commitShas })
      );
    }

    case "core/COMMITS_PAGINATION_UPDATED": {
      const currentState = state[action.meta.entityId] || Immutable.from({});
      const currentCommits = currentState.commitShas || Immutable.from([]);
      const newCommits = action.meta.replace
        ? action.payload
        : currentCommits.concat(action.payload);
      const hasMore = action.payload.length >= action.meta.pageSize;

      return Immutable.set(
        state,
        action.meta.entityId,
        Immutable.merge(currentState, {
          commitShas: newCommits,
          hasMore,
        })
      );
    }
    case "SIGNED_OUT":
      return Immutable.from({});
    default: {
      return state;
    }
  }
}
