// @flow
import { schema, normalize } from "normalizr";
import { teamMembership, user, membershipSchema } from "core/schema";
import { envelopeSchema } from "core/schemas/envelope";

const teamsMembershipsFetchResponseSchema = envelopeSchema(
  new schema.Object({
    teamMemberships: [teamMembership],
    memberships: [membershipSchema],
  })
);

const teamMembershipSchema = envelopeSchema(
  new schema.Object({
    teamMembership: teamMembership,
    user: user,
  })
);

export function normalizeTeamMembershipsFetchResponse(response: *) {
  return normalize(response, teamsMembershipsFetchResponseSchema);
}

export function normalizeTeamMembershipResponse(response: *) {
  return normalize(response, teamMembershipSchema);
}

export function normalizeTeamMembershipSocketResponse(response: *) {
  return normalize(
    response,
    new schema.Object({ teamMembership: teamMembership, user: user })
  );
}
