// @flow
import apiRequest from "abstract-di/api";
import { entitiesReceived } from "core/actions/entities";
import { notificationsLoaded } from "core/actions/notificationsMeta";
import defineRequest from "core/requests";
import { normalizeNotificationsResponse } from "core/schemas/notification";

export type MarkNotificationsParams = {|
  commentIds?: string[],
  notificationIds?: string[],
  readState: "read" | "unread",
|};

type MarkAllAsReadParams = {};

export type LoadNotificationsParams = {
  organizationId?: ?string,
  maxCreatedAt?: ?string,
  limit?: number,
};

export const MarkNotificationsRequest = defineRequest<
  MarkNotificationsParams,
  MarkNotificationsParams,
>({
  id() {
    return `put:notifications/mark`;
  },
  perform(params: MarkNotificationsParams) {
    return apiRequest("put", "notifications/mark", {
      state: params.readState,
      ids: params.notificationIds,
      commentIds: params.commentIds,
    });
  },
  onSuccess(response, params, dispatch, getState) {
    dispatch({
      meta: { readState: params.readState },
      payload: response.notification_ids,
      type: "core/NOTIFICATIONS_MARKED",
    });
  },
});

export const MarkAllNotificationsAsReadRequest = defineRequest<
  MarkAllAsReadParams,
  MarkAllAsReadParams,
>({
  id() {
    return `put:notifications/mark_all_read`;
  },
  perform() {
    return apiRequest("put", "notifications/mark_all_read");
  },
  onSuccess(response, params, dispatch, getState) {
    dispatch({ type: "core/NOTIFICATIONS_READ_ALL" });
  },
});

export const LoadNotificationsRequest = defineRequest<
  LoadNotificationsParams,
  LoadNotificationsParams,
>({
  id(notificationParams: LoadNotificationsParams = {}) {
    return `get:notifications-${notificationParams.organizationId || ""}`;
  },
  perform(
    notificationParams: LoadNotificationsParams = {
      limit: 20,
    }
  ) {
    const params = {
      ...notificationParams,
      limit: notificationParams.limit,
    };

    return apiRequest("get", "notifications", params);
  },
  onSuccess(response, params, dispatch, getState) {
    const normalizedNotifications = normalizeNotificationsResponse(
      response.data
    ).entities;

    dispatch(entitiesReceived(normalizedNotifications));

    dispatch(
      notificationsLoaded({
        organizationId: params.organizationId,
        notifications: response.data,
        nextOffset: response.meta.nextOffset,
      })
    );
  },
});
