// @flow
import classnames from "classnames";
import * as React from "react";
import type { LayerChange } from "core/types";
import style from "./style.scss";

type Props = {
  label?: React.Node,
  children?: React.Node,
  change?: ?LayerChange<*>,
  right?: React.Node,
  className?: string,
};

export default class SidebarSubSection extends React.Component<Props> {
  render() {
    return (
      <div
        className={classnames(this.props.className, style.sidebarSubSection, {
          [style.changed]:
            this.props.change && this.props.change.status === "changed",
        })}
      >
        <div className={style.header}>
          <div className={style.label}>{this.props.label}</div>
          {this.props.right}
        </div>
        <div className={style.children}>{this.props.children}</div>
      </div>
    );
  }
}
