// @flow
import {
  SortableElement,
  SortableHandle,
} from "@elasticprojects/react-sortable-hoc";
import classnames from "classnames";
import capitalize from "lodash/capitalize";
import debounce from "lodash/debounce";
import * as React from "react";
import Breakpoint from "core/components/Breakpoint";
import Button from "core/components/Button";
import Centered from "core/components/Centered";
import FloatingControl from "core/components/FloatingControl";
import InputSwitch from "core/components/InputSwitch";
import Popover from "core/components/Popover";
import PreviewImage from "core/components/PreviewImage";
import PreviewLoader from "core/components/PreviewLoader";
import Thumbnail from "core/components/Thumbnail";
import { replace, removeQuery } from "core/lib/location";
import { V3Link as Link } from "core/lib/router";
import { shareLinkPath, layerLocation } from "core/lib/routes";
import type { CollectionLayer, Layer, LayerShellMode } from "core/types";
import connector from "./connector";
import style from "./style.scss";

const GrippablePreview = SortableHandle(PreviewImage);
const GrippableCentered = SortableHandle(Centered);

export const helperClass = style.thumbnailMoving;

export type OwnProps = {|
  collectionLayer: CollectionLayer,
  layerCommentCounts?: { [layerId: string]: number },
  projectId: string,
  shareLinkId?: string,
  className?: string,
  isMousePointer?: boolean,
  editable?: boolean,
  disabled?: boolean,
  isMobile?: boolean,
  canInspect?: boolean,
  canAccessProject: boolean,
  showPresentButton?: boolean,
  index: number,
  onClick?: (collectionLayerId: string) => void,
  onClickRemove?: (collectionLayerId: string) => void,
  onClickPresentation: (collectionLayerId: string) => void,
  onChangeAutoUpdate?: (collectionLayerId: string, value: boolean) => void,
  disableActions?: boolean,
  qaSelector?: string,
|};

export type StateProps = {|
  layer: ?Layer,
  sha: string,
  fileId: string,
  branchId: string,
  layerId: string,
  layerName: string,
  commentCount: number,
  useLatestCommit: boolean,
  removed: ?boolean,
|};

type Props = {
  ...OwnProps,
  ...StateProps,
};

type State = { useLatestCommit: boolean };

class CollectionLayerThumbnail extends React.Component<Props, State> {
  state = { useLatestCommit: this.props.useLatestCommit };

  handleClick = (event: SyntheticMouseEvent<>) => {
    event.preventDefault();
    event.stopPropagation();
    if (this.props.onClick) {
      this.props.onClick(this.props.collectionLayer.id);
    } else {
      replace(this.layerPath());
    }
  };

  handleRemoveClick = (event: SyntheticMouseEvent<>) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onClickRemove &&
      this.props.onClickRemove(this.props.collectionLayer.id);
  };

  handleInspectClick = (event: SyntheticMouseEvent<>) => {
    event.preventDefault();
    event.stopPropagation();
    replace(this.layerPath("build"));
  };

  handlePresentationClick = (event: SyntheticMouseEvent<>) => {
    event.preventDefault();
    event.stopPropagation();
    const collectionLayer = this.props.collectionLayer;
    this.props.onClickPresentation(collectionLayer.id);
  };

  handleChangeAutoUpdate = debounce((useLatestCommit: boolean) => {
    this.props.onChangeAutoUpdate &&
      this.props.onChangeAutoUpdate(
        this.props.collectionLayer.id,
        useLatestCommit
      );
  }, 250);

  handleAutoUpdateChange = (event: SyntheticInputEvent<>) => {
    event.stopPropagation();
    const useLatestCommit = event.target.checked;
    this.setState({ useLatestCommit }, () =>
      this.handleChangeAutoUpdate(useLatestCommit)
    );
  };

  layerPath = (mode?: LayerShellMode) => {
    const { layer, collectionLayer, projectId, branchId, shareLinkId } =
      this.props;

    if (!layer) {
      return "";
    }

    const options = {
      mode,
      collectionLayerId: collectionLayer.id,
      layerSetParams: {
        projectId,
        type: "collection",
        collectionId: collectionLayer.collectionId,
      },
      returnTo: removeQuery("collectionLayerId", "mode", "sha", "selected"),
    };

    if (shareLinkId) {
      return shareLinkPath(shareLinkId, {
        collectionLayerId: collectionLayer.id,
        sha: layer.lastChangedAtSha,
        ...options,
      });
    }

    return layerLocation(
      projectId,
      branchId,
      layer.lastChangedAtSha,
      layer.fileId,
      layer.id,
      options
    );
  };

  render() {
    const {
      projectId,
      sha,
      fileId,
      layer,
      layerId,
      layerName,
      className,
      isMousePointer,
      editable,
      commentCount,
      removed,
      isMobile,
      canInspect,
      canAccessProject,
      showPresentButton,
      disableActions,
      qaSelector,
    } = this.props;

    // We return an empty div here, because
    // react-sortable-hoc always needs a valid node
    if (!layer) {
      return <div />;
    }

    const CenteredComponent =
      editable && isMousePointer ? GrippableCentered : Centered;
    const PreviewComponent =
      editable && isMousePointer ? GrippablePreview : PreviewImage;
    const linkToDetail = canAccessProject || !showPresentButton || isMobile;
    const showActions = (editable || !canAccessProject) && isMousePointer;

    return (
      <div className={className}>
        <Breakpoint at={230}>
          {({ above }) => (
            <Thumbnail
              qaSelector={qaSelector}
              layer={layer}
              className={classnames({
                [style.editable]: editable,
                [style.guestMode]: !canAccessProject && !isMobile,
              })}
              layerName={layerName}
              commentCount={removed ? 0 : commentCount}
              onClick={
                linkToDetail ? this.handleClick : this.handlePresentationClick
              }
              alwaysShowExtras={!isMousePointer}
              extras={
                disableActions ? null : (
                  <React.Fragment>
                    {editable && (
                      <FloatingControl className={style.removeButton}>
                        <Button
                          nude
                          icon="close"
                          onClick={this.handleRemoveClick}
                          title="Remove from collection"
                          qaSelector="removeFromCollectionButton"
                        />
                      </FloatingControl>
                    )}
                    {canAccessProject && showPresentButton && !isMobile && (
                      <div className={style.topRightButtons}>
                        {canInspect && (
                          <FloatingControl>
                            <Popover
                              label="Open in Inspect"
                              delayShow={250}
                              placement="left"
                            >
                              <Button
                                nude
                                icon="inspect"
                                onClick={this.handleInspectClick}
                                qaSelector="openInInspectButton"
                              />
                            </Popover>
                          </FloatingControl>
                        )}
                        <FloatingControl className={style.presentButton}>
                          <Popover
                            label="Open in presentation mode"
                            delayShow={250}
                            placement="left"
                          >
                            <Button
                              nude
                              tint
                              tintHover
                              onClick={this.handlePresentationClick}
                              icon="play"
                              qaSelector="openInPresentationModeButton"
                            />
                          </Popover>
                        </FloatingControl>
                      </div>
                    )}
                    {showActions && (
                      <div
                        className={style.actions}
                        onClick={(event) => event.stopPropagation()}
                      >
                        {editable ? (
                          <InputSwitch
                            label="Auto-update"
                            checked={this.state.useLatestCommit}
                            onChange={this.handleAutoUpdateChange}
                            wrapperClass={style.switch}
                          />
                        ) : (
                          <React.Fragment>
                            <Button
                              nude
                              tint
                              component={Link}
                              icon={
                                above || !canInspect
                                  ? "comment-default"
                                  : undefined
                              }
                              to={this.layerPath()}
                              className={style.actionButton}
                              qaSelector="openCommentsButton"
                            >
                              Comments
                            </Button>
                            {canInspect && (
                              <Button
                                nude
                                tint
                                component={Link}
                                icon={above ? "inspect" : undefined}
                                to={this.layerPath("build")}
                                className={style.actionButton}
                                qaSelector="openInInspectButton"
                              >
                                Inspect
                              </Button>
                            )}
                          </React.Fragment>
                        )}
                      </div>
                    )}
                  </React.Fragment>
                )
              }
            >
              <div className={style.container}>
                <PreviewLoader
                  projectId={projectId}
                  fileId={fileId}
                  layerId={layerId}
                  commitSha={sha}
                  scale={0.5}
                >
                  {(src, loading, error) => {
                    return removed ? (
                      <CenteredComponent
                        className={classnames(style.empty, style.preview)}
                      >
                        {capitalize(layer.type)} removed
                      </CenteredComponent>
                    ) : (
                      <PreviewComponent
                        alt={layerName}
                        src={src}
                        width={layer.width}
                        height={layer.height}
                        cover={false}
                        className={style.preview}
                        errorText={error}
                      />
                    );
                  }}
                </PreviewLoader>
              </div>
            </Thumbnail>
          )}
        </Breakpoint>
      </div>
    );
  }
}

export default connector(SortableElement(CollectionLayerThumbnail));
