// @flow
// GENERATING / DOWNLOADING -> LOADED / NOT_FOUND
export const PreviewStates = {
  GENERATING: "GENERATING",
  DOWNLOADING: "DOWNLOADING",
  NOT_FOUND: "NOT_FOUND",
  LOADED: "LOADED",
};

export const LoadingStates = {
  LOADING: "LOADING",
  LOADED: "LOADED",
  ERROR: "ERROR",
};

export type LoadingState = $Keys<typeof LoadingStates>;
export type PreviewState = $Keys<typeof PreviewStates>;

export const TRANSFER_PROJECT = "TRANSFER PROJECT";

export const LEAVE_ORGANIZATION = "LEAVE ORGANIZATION";
export const REMOVE_FROM_ORGANIZATION = "REMOVE FROM ORGANIZATION";

export const SUPPORT_EMAIL = "support@abstract.com";
export const LEGAL_EMAIL = "legal@abstract.com";
