// @flow
import apiRequest from "abstract-di/api";
import AbstractConfig from "abstract-di/config";
import { handleError } from "core/api";

type ZendeskRequest = {
  topic: string,
  subject: string,
  description: string,
  organizationId: string,
  uploads?: Array<string>,
  version: string,
};

// Adds needed email prop to global Response
type R = {|
  ...Response,
  email: string,
|};

export async function createZendeskTicket(
  jsonBody: ZendeskRequest
): Promise<R> {
  return apiRequest("post", "zendesk", jsonBody);
}

export async function createZendeskAttachment(file: File) {
  const zendeskURL = await AbstractConfig.getZendeskURL();

  const url = `${zendeskURL}/api/v2/uploads.json?filename=${file.name}`;
  const options = {
    method: "POST",
    body: file,
  };

  const response = await fetch(url, options);

  if (!response.ok) {
    throw await handleError(response);
  }

  const json = await response.json();
  return json.upload.token;
}
