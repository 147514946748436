// @flow
import * as React from "react";
import Icon from "core/components/Icon";
import style from "./style.scss";

// Adds preview prop to global File type
// by combining them into an Upload type
type Upload = File & { preview?: string };

type Props = {
  file: Upload,
};

export default function Preview(props: Props) {
  const [hasError, setError] = React.useState(false);

  const handleImageError = () => {
    setError(true);
  };

  if (hasError) {
    return <Icon className={style.thumbnail} type="no-image" medium />;
  }

  return (
    <img
      className={style.thumbnail}
      src={props.file.preview}
      alt={props.file.name}
      onError={handleImageError}
    />
  );
}
