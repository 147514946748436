// @flow
import classnames from "classnames";
import * as React from "react";
import DefaultOrganizationLogo from "../DefaultOrganizationLogo";
import css from "./style.scss";

type Props = {
  alt: string,
  size?: number,
  src?: string,
  className?: string,
  style?: Object,
};

type State = { showFallback: boolean };

export default class OrganizationLogo extends React.Component<Props, State> {
  constructor(props: Props) {
    super();
    this.state = { showFallback: !props.src };
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.src !== prevProps.src) {
      this.setState({ showFallback: !this.props.src });
    }
  }

  onError = () => {
    this.setState({ showFallback: true });
  };

  render() {
    const { className, size = 48, style, alt, ...props } = this.props;
    const classes = classnames(css.wrap, className, {});
    const pxSize = `${size}px`;
    const extraStyles = { ...style, height: pxSize, width: pxSize };

    return (
      <span className={classes}>
        {this.state.showFallback ? (
          <DefaultOrganizationLogo
            className={css.img}
            size={size}
            style={style}
            alt={alt}
            {...props}
          />
        ) : (
          <img
            className={css.img}
            style={extraStyles}
            onError={this.onError}
            alt={alt}
            {...props}
          />
        )}
      </span>
    );
  }
}
