// @flow
import * as Abstract from "abstract-sdk";
import apiRequest from "abstract-di/api";
import defineRequest from "core/requests";

export const CreateOrganizationAccessRequest = defineRequest<
  Abstract.OrganizationDescriptor,
  Abstract.OrganizationDescriptor,
>({
  id: (params) => {
    const { organizationId } = params;
    return `post:organizations/${organizationId}/access_requests`;
  },
  perform: (params) => {
    const { organizationId } = params;
    return apiRequest(
      "post",
      `organizations/${organizationId}/access_requests`
    );
  },
});

type AcceptOrganizationAccessParams = { organizationId: string, token: string };

export const AcceptOrganizationAccessRequest = defineRequest<
  AcceptOrganizationAccessParams,
  AcceptOrganizationAccessParams,
>({
  id(params) {
    const { organizationId, token } = params;
    return `post:organizations/${organizationId}/access_requests/${token}/accept`;
  },
  perform(params) {
    const { organizationId, token } = params;
    return apiRequest(
      "post",
      `organizations/${organizationId}/access_requests/${token}/accept`
    );
  },
});
