// @flow
import { debounce, memoize } from "lodash";
import * as React from "react";
import { Helmet } from "react-helmet";
import { organizationPath } from "abstract-di/routes";
import Error from "core/components/Empty/Error";
import NoPeople from "core/components/Empty/NoPeople";
import NoResults from "core/components/Empty/NoResults";
import Flex from "core/components/Flex";
import InviteUsers from "core/components/InviteUsers";
import Loaded from "core/components/Loaded";
import MainContent from "core/components/MainContent";
import Media from "core/components/Media";
import Modal from "core/components/Modal";
import OrganizationMemberMenu from "core/components/OrganizationMemberMenu";
import OrganizationMembersHeader from "core/components/OrganizationMembersHeader";
import { createPaginationDataLoaderComponent } from "core/components/PaginationDataLoader";
import PeopleFilterMenu from "core/components/PeopleFilterMenu";
import PeopleTeamsHeader from "core/components/PeopleTeamsHeader";
import VirtualizedPeopleList from "core/components/VirtualizedPeopleList";
import { Abstract } from "core/lib/abstract";
import { replace, addQuery, removeQuery } from "core/lib/location";
import {
  isGuest,
  isAdmin,
  isContributor,
  createdAt,
} from "core/models/membership";
import { PaginatedOrganizationMembershipsRequest } from "core/requests/memberships";
import { getUsersForOrganization } from "core/selectors/users";
import type {
  ReactRouterLocation,
  OrganizationRole,
  SubscriptionRole,
  RoleFilter,
  Policy,
  Subscription,
  User,
  Organization,
  Membership,
  ViewType,
} from "core/types";
import connector from "./connector.js";
import style from "./style.scss";

export type OwnProps = {|
  params: Abstract.OrganizationDescriptor,
  location: ReactRouterLocation,
|};

export type StateProps = {|
  policy: Policy,
  memberships: { [string]: Membership },
  organization: ?Organization,
  subscription: ?Subscription,
  searchFilter?: string,
  roleFilter: ?RoleFilter,
  error: boolean,
  canUseTeams: boolean,
  isTeamsAnnouncementEnabled: boolean,
|};

export type DispatchProps = {|
  onLoad: () => Promise<void>,
|};

export type StorageProps = {|
  viewType: ViewType,
  onChangeViewType: (viewType: ViewType) => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
  ...StorageProps,
};

export type PropsWithoutStorage = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type PaginatedOrganizationMembershipsParams = {
  ...Abstract.OrganizationDescriptor,
  role?: OrganizationRole,
  subscriptionRole?: SubscriptionRole,
  query?: string,
  userId?: string,
};

const OrganizationDataLoader = createPaginationDataLoaderComponent<
  PaginatedOrganizationMembershipsParams,
  User[],
>(
  "OrganizationPeople",
  PaginatedOrganizationMembershipsRequest,
  (response) => response.data.length,
  getUsersForOrganization,
  30
);

const getOrganizationPaginationParams = memoize(
  function (props): PaginatedOrganizationMembershipsParams {
    const params = {
      organizationId: props.params.organizationId,
      query: props.searchFilter || undefined,
      role:
        props.roleFilter === "guest" ||
        props.roleFilter === "member" ||
        props.roleFilter === "owner"
          ? props.roleFilter
          : undefined,
      subscriptionRole:
        props.roleFilter === "viewer" || props.roleFilter === "contributor"
          ? props.roleFilter
          : undefined,
    };

    return params;
  },
  function (props) {
    return [
      props.params.organizationId,
      props.roleFilter,
      props.searchFilter,
    ].join("-");
  }
);

const handleSearchChange = debounce((ev: SyntheticInputEvent<>) => {
  const query = ev.target.value;
  if (query) {
    replace(addQuery({ s: query }));
  } else {
    replace(removeQuery("s"));
  }
}, 200);

function OrganizationPeople(props: Props) {
  const [viewType, setViewType] = React.useState(props.viewType);
  const [inviteDialogOpen, setInviteDialogOpen] = React.useState(false);

  React.useEffect(() => {
    if (props.organization && !props.policy.listMembers) {
      replace(organizationPath(props.organization.id));
    }
  }, [props.organization, props.policy]);

  const getMembership = React.useCallback(
    (userId: string) => {
      return props.memberships[`${props.params.organizationId}-${userId}`];
    },
    [props.memberships, props.params]
  );

  const handleRole = React.useCallback(
    (isRole) => {
      const handler = (userId: string) => {
        return isRole(getMembership(userId));
      };
      return handler;
    },
    [getMembership]
  );

  const handleCreatedAt = React.useCallback(
    (user) => createdAt(getMembership(user.id), user),
    [getMembership]
  );

  const changeViewType = (viewType: ViewType) => {
    props.onChangeViewType(viewType);
    setViewType(viewType);
  };

  const renderInviteDialog = () => {
    return (
      <React.Fragment>
        {props.organization && (
          <Modal
            isOpen={inviteDialogOpen}
            onClose={() => setInviteDialogOpen(false)}
            title={
              <div className={style.inviteModalHeaderText}>
                Invite to {props.organization.name}
              </div>
            }
            qaSelector="inviteUsersModal"
            headerClassName={style.inviteModalHeader}
          >
            <InviteUsers
              organization={props.organization}
              onDismissDialog={() => setInviteDialogOpen(false)}
              subscription={props.subscription}
            />
          </Modal>
        )}
      </React.Fragment>
    );
  };

  const renderEmpty = () => {
    if (props.error) {
      return <Error flex />;
    }
    if (props.searchFilter) {
      return <NoResults type="users" term={props.searchFilter} flex />;
    }

    return <NoPeople flex />;
  };

  const renderMemberMenu = (input: { user?: User }) => {
    const { params, policy } = props;

    if (!policy.updateMembership || !input.user) {
      return null;
    }

    return (
      <OrganizationMemberMenu
        user={input.user}
        organizationId={params.organizationId}
      />
    );
  };

  const renderDataLoaderContent = (
    items: User[],
    hasNextPage: boolean,
    isLoadingNextPage: boolean,
    onLoadNextPage?: () => void,
    desktop: boolean
  ) => {
    return (
      <React.Fragment>
        {items.length === 0 && props.canUseTeams && (
          <PeopleFilterMenu
            isGrid={viewType === "grid"}
            isMobile={!desktop}
            roleFilter={props.roleFilter}
          />
        )}
        <Loaded
          loading={items.length === 0 && isLoadingNextPage}
          title="Loading people…"
          flex
        >
          {() =>
            items.length === 0 && !isLoadingNextPage ? (
              renderEmpty()
            ) : (
              <div className={style.wrap}>
                <VirtualizedPeopleList
                  users={items}
                  mode="people"
                  showFilterSubmenu
                  roleFilter={props.roleFilter}
                  searchFilter={props.searchFilter}
                  isGrid={desktop && viewType === "grid"}
                  isMobile={!desktop}
                  isAdmin={handleRole(isAdmin)}
                  isGuest={handleRole(isGuest)}
                  isContributor={handleRole(isContributor)}
                  userCreatedAt={handleCreatedAt}
                  renderMemberMenu={renderMemberMenu}
                  hasNextPage={hasNextPage}
                  isLoadingNextPage={isLoadingNextPage}
                  onLoadNextPage={onLoadNextPage}
                  canUseTeams={props.canUseTeams}
                />
              </div>
            )
          }
        </Loaded>
      </React.Fragment>
    );
  };

  return (
    <Media desktop>
      {(desktop) => (
        <MainContent className={style.mainContent}>
          <Flex column>
            <Helmet>
              <title>People</title>
            </Helmet>
            {props.canUseTeams ? (
              <PeopleTeamsHeader
                params={props.params}
                mode="people"
                canAddMember={props.policy.addMember}
                isGrid={viewType === "grid"}
                roleFilter={props.roleFilter}
                mobile={!desktop}
                onDisplayList={() => changeViewType("list")}
                onDisplayGrid={() => changeViewType("grid")}
                onPrimaryActionTrigger={() =>
                  setInviteDialogOpen(!inviteDialogOpen)
                }
                onSearchChange={handleSearchChange}
                renderInviteDialog={renderInviteDialog}
                searchFilter={props.searchFilter}
                isTeamsAnnouncementEnabled={props.isTeamsAnnouncementEnabled}
              />
            ) : (
              <OrganizationMembersHeader
                canAddMember={props.policy.addMember}
                isGrid={viewType === "grid"}
                roleFilter={props.roleFilter}
                mobile={!desktop}
                onDisplayList={() => changeViewType("list")}
                onDisplayGrid={() => changeViewType("grid")}
                onInviteDialogTrigger={() =>
                  setInviteDialogOpen(!inviteDialogOpen)
                }
                onSearchChange={handleSearchChange}
                renderInviteDialog={renderInviteDialog}
                searchFilter={props.searchFilter}
              />
            )}
            <OrganizationDataLoader
              params={getOrganizationPaginationParams(props)}
            >
              {({
                items,
                hasNextPage,
                isLoadingNextPage,
                onLoadNextPage,
              }: {
                items: User[],
                hasNextPage: boolean,
                isLoadingNextPage: boolean,
                onLoadNextPage?: () => void,
              } = {}) =>
                renderDataLoaderContent(
                  items,
                  hasNextPage,
                  isLoadingNextPage,
                  onLoadNextPage,
                  desktop
                )
              }
            </OrganizationDataLoader>
          </Flex>
        </MainContent>
      )}
    </Media>
  );
}

export default connector(OrganizationPeople);
