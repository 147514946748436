// @flow
import * as React from "react";
import getDisplayName from "react-display-name";
import shallowequal from "shallowequal";
import abstractConfig from "abstract-di/config";
import createLogger from "core/lib/logger";

export interface DataInterface {
  loading: boolean;
  reloading: boolean;
  error?: Error;
  reload: () => void;
}

type OnLoadOptions = {
  reload: boolean,
};

type State<Data: {} | void> = {
  loading: boolean,
  reloading: boolean,
  data?: Data,
  error?: Error,
};

const ON_LOAD_UNDEFINED = () => {
  throw new Error("onLoad required");
};

const logger = createLogger("withData");

export function withData<
  Config: {},
  Input: $ReadOnly<{ [string]: ?string | boolean }> = {},
  Data: {} | void = void,
>(
  makeInput: (props: Config) => Input,
  onLoad?: (prevInput: Input, options?: OnLoadOptions) => Data
): (
  WrappedComponent: React.AbstractComponent<Config>
) => React.AbstractComponent<{
  ...$Diff<$Exact<Config>, { data: Data | void }>,
  onLoad: (prevInput: Input, options?: OnLoadOptions) => Data | Promise<Data>,
}> {
  return (WrappedComponent) => {
    return class DataLoader extends React.Component<
      {
        ...$Diff<$Exact<Config>, { data: Data | void }>,
        onLoad: (
          prevInput: Input,
          options?: OnLoadOptions
        ) => Data | Promise<Data>,
      },
      State<Data>,
    > {
      static defaultProps = {
        onLoad: onLoad || ON_LOAD_UNDEFINED,
      };

      state: State<Data>;

      constructor(props) {
        super();

        try {
          const data = props.onLoad(makeInput(props));
          const isPromise = data instanceof Promise;

          if (isPromise) {
            this.state = {
              loading: true,
              reloading: false,
            };
          } else {
            this.state = {
              data,
              error: undefined,
              loading: false,
              reloading: false,
            };
          }
        } catch (error) {
          this.state = {
            data: undefined,
            error,
            loading: false,
            reloading: false,
          };
        }
      }

      componentDidMount() {
        if (this.state.loading) {
          this.onLoad(makeInput(this.props));
        }
      }

      componentDidUpdate(prevProps) {
        const prevInput = makeInput(prevProps);
        const nextInput = makeInput(this.props);

        if (!shallowequal(nextInput, prevInput)) {
          this.onLoad(nextInput);
        }
      }

      onLoad = async (
        prevInput: Input,
        options: OnLoadOptions = { reload: false }
      ) => {
        if (options.reload) {
          this.setState({ reloading: true });
        }

        if (!this.state.loading) {
          this.setState({ loading: true });
        }

        try {
          this.setState({
            data: await this.props.onLoad(prevInput),
            error: undefined,
            loading: false,
            reloading: false,
          });
        } catch (error) {
          const displayName: string = getDisplayName(WrappedComponent);
          const detailedError = new Error(
            `Unhandled error in onLoad() in mapDispatchToProps for ${displayName}: ${error.message}`
          );

          logger.error(error);
          logger.error(detailedError);

          abstractConfig.reportError(detailedError, { extras: { error } });

          this.setState({
            error,
            loading: false,
            reloading: false,
          });
        }
      };

      render() {
        return (
          <WrappedComponent
            {...this.props}
            data={{
              ...this.state.data,
              error: this.state.error,
              loading: this.state.loading,
              reloading: this.state.reloading,
              reload: () => {
                this.onLoad(makeInput(this.props), { reload: true });
              },
            }}
          />
        );
      }
    };
  };
}
