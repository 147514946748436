// @flow
import * as Abstract from "abstract-sdk";
import React from "react";
import { useSelector } from "react-redux";
import Flex from "core/components/Flex";
import Media from "core/components/Media";
import OrganizationBranchesReviews from "core/components/OrganizationBranchesReviews";
import PageTitle from "core/components/PageTitle";
import { getOrganization } from "core/selectors/organizations";
import BranchReviewsControls from "./BranchReviewsControls";
import style from "./style.scss";

type BranchesParams = {|
  type: "branches",
  branchesReviewsFilter: "assigned-to-me",
  projectId?: string,
|};

type Props = {
  params: Abstract.OrganizationDescriptor,
  location: {
    query: BranchesParams,
  },
};

const Reviews = (props: Props) => {
  const {
    params: { organizationId },
    location: { query },
  } = props;

  const organization = useSelector((state) => {
    if (organizationId) {
      return getOrganization(state, {
        organizationId,
      });
    }
  });

  if (!organization) {
    return null;
  }

  const sortAndFilterControls = (mobile) => {
    return (
      <BranchReviewsControls
        organization={organization}
        projectId={query.projectId && query.projectId}
        mobile={mobile}
        branchesReviewsFilter={
          query.branchesReviewsFilter && query.branchesReviewsFilter
        }
      />
    );
  };

  const reviewsComponent = (mobile) => {
    return (
      <OrganizationBranchesReviews
        mobile={mobile}
        filter={query.branchesReviewsFilter && query.branchesReviewsFilter}
        organizationId={organizationId}
        projectId={query.projectId && query.projectId}
      />
    );
  };

  return (
    <Media mobile>
      {(mobile) => {
        return (
          <Flex column className={style.wrap}>
            <PageTitle
              title="Reviews"
              filter={mobile ? undefined : sortAndFilterControls(mobile)}
              sort={mobile ? sortAndFilterControls(mobile) : undefined}
              sortClassname={style.sort}
            />
            {reviewsComponent(mobile)}
          </Flex>
        );
      }}
    </Media>
  );
};

export default Reviews;
