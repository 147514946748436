// @flow
import { difference, forEach, isEqual } from "lodash";
import { static as Immutable } from "seamless-immutable";
import type { Star } from "core/types";
import createEntityReducer from "./createEntityReducer";

export default createEntityReducer<Star>("stars", function (state, action) {
  if (action.type === "core/STARS_LOADED_ALL") {
    const existingIds = Object.keys(state);
    const newIds = Object.keys(action.payload);
    const idsToDelete = difference(existingIds, newIds);

    let result = state;
    if (idsToDelete.length > 0) {
      result = Immutable.without(result, idsToDelete);
    }

    forEach(action.payload, function (star, id) {
      if (!isEqual(result[id], star)) {
        result = Immutable.set(result, id, star);
      }
    });

    return result;
  }

  return state;
});
