// @flow
import classnames from "classnames";
import * as React from "react";
import { useDispatch } from "react-redux";
import ButtonLink from "core/components/ButtonLink";
import Flex from "core/components/Flex";
import OnboardingContentSheet from "core/components/OnboardingContentSheet";
import OnboardingHeading from "core/components/OnboardingHeading";
import SerifText from "core/components/SerifText";
import onboardingStyle from "core/components/onboarding.scss";
import type { ReactRouterLocation } from "core/types";
import OnboardingPage from "web/components/Onboarding/Page";
import { openSupportWindow } from "web/di/actions";
import style from "./style.scss";

type Props = {
  location: ReactRouterLocation,
};

export default function PluginRedirect(props: Props) {
  const { error } = props.location.query;

  const appName = props.location.query.app_name || "the plugin";
  const capitalizedAppName = props.location.query.app_name || "The plugin";

  const dispatch = useDispatch();
  const handleOpenSupport = React.useCallback(() => {
    dispatch(openSupportWindow());
  }, [dispatch]);

  return (
    <OnboardingPage>
      <OnboardingContentSheet className={onboardingStyle.mainContentSheet}>
        <Flex align="center" className={onboardingStyle.centeredText} column>
          {error ? (
            error === "access_denied" ? (
              <React.Fragment>
                <OnboardingHeading level="1">
                  Authorization <SerifText>canceled</SerifText>
                </OnboardingHeading>
                <div
                  className={classnames(
                    style.subHeading,
                    onboardingStyle.largeCopy
                  )}
                >
                  {capitalizedAppName} was not authorized. You can close this
                  window.
                </div>
                <div className={onboardingStyle.extraInfo}>
                  You can always authorize {appName} in the future by signing in
                  again within the plugin.
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <OnboardingHeading level="1">
                  An unexpected error occurred
                </OnboardingHeading>
                <div
                  className={classnames(
                    style.subHeading,
                    onboardingStyle.largeCopy
                  )}
                >
                  There was an issue authorizing {appName}. You can close this
                  window and try signing in again within the plugin.
                </div>
                <div className={style.contactUs}>
                  If you keep seeing this message, please{" "}
                  <ButtonLink onClick={handleOpenSupport}>
                    contact us
                  </ButtonLink>{" "}
                  — we’re here to help.
                </div>
              </React.Fragment>
            )
          ) : (
            <React.Fragment>
              <OnboardingHeading level="1">
                You’re <SerifText>all set!</SerifText>
              </OnboardingHeading>
              <div
                className={classnames(
                  style.subHeading,
                  onboardingStyle.largeCopy
                )}
              >
                You can close this window and continue working in {appName}.
              </div>
            </React.Fragment>
          )}
        </Flex>
      </OnboardingContentSheet>
    </OnboardingPage>
  );
}
