// @flow
import classnames from "classnames";
import * as React from "react";
import style from "./style.scss";

type Props = {
  width: number,
  height: number,
  scale: number,
  style?: { [string]: mixed },
  cellSize: number,
  sectionSize: number,
};

export default function SimpleGridOverlay(props: Props) {
  const width = props.width * props.scale;
  const height = props.height * props.scale;
  const cellSize = props.cellSize * props.scale;

  const numberOfRows = Math.floor(height / cellSize);
  const numberOfColumns = Math.ceil(width / cellSize);

  return (
    <div
      style={{
        ...props.style,
        width,
        height,
      }}
      className={style.gridContainer}
    >
      {Array(numberOfColumns)
        .fill()
        .map((_, index) => (
          <div
            style={{ left: index * cellSize }}
            className={classnames(style.column, {
              [style.sectionMarker]: index % props.sectionSize === 0,
            })}
          />
        ))}

      {Array(numberOfRows)
        .fill()
        .map((_, index) => (
          <div
            style={{ top: index * cellSize }}
            className={classnames(style.row, {
              [style.sectionMarker]: index % props.sectionSize === 0,
            })}
          />
        ))}
    </div>
  );
}
