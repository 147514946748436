// @flow
import invariant from "invariant";
import { connect } from "react-redux";
import { withData } from "core/components/DataLoader";
import createConnector from "core/lib/createConnector";
import { BranchFetchRequest } from "core/requests/branches";
import { getBranch } from "core/selectors/branches";
import {
  getCollectionLayer,
  getCanEditBranchCollections,
  getCollection,
} from "core/selectors/collections";
import { getFeatureEnabled } from "core/selectors/features";
import { getOrganizationForProject } from "core/selectors/organizations";
import { getCollectionPolicy } from "core/selectors/policies";
import { getUser } from "core/selectors/users";
import type { Dispatch, State } from "core/types";
import type { Props, OwnProps, StateProps, DispatchProps } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const collection = getCollection(state, {
    collectionId: props.collectionId,
  });
  const collectionPolicy = getCollectionPolicy(state, {
    collectionId: props.collectionId,
  });

  invariant(
    collection,
    "Collection must exist in state when passed to CollectionThumbnail"
  );

  const { projectId, branchId } = collection;

  let sha = "";
  let layerId = "";
  let fileId = "";

  // if the collection is empty, we don't care about the thumbnail
  if (collection.layers.length > 0) {
    const { thumbnailCollectionLayerId, thumbnailCollectionLayerSha } =
      collection;

    if (thumbnailCollectionLayerId && thumbnailCollectionLayerSha) {
      const thumbnailCollectionLayer =
        getCollectionLayer(state, {
          collectionLayerId: thumbnailCollectionLayerId,
        }) || {};

      sha = thumbnailCollectionLayerSha;
      fileId = thumbnailCollectionLayer.fileId;
      layerId = thumbnailCollectionLayer.layerId;
    }
  }

  const organization = getOrganizationForProject(state, {
    projectId,
  });

  const canReorder =
    getFeatureEnabled(state, {
      organizationId: organization ? organization.id : "",
      feature: "branch-collection-reorder",
    }) &&
    getCanEditBranchCollections(state, {
      projectId,
      branchId,
    });

  return {
    // We use `abstractCollection` as the prop here instead of `collection` because
    // collection has special significance to the sortable hoc we are using
    // react-sortable-hoc
    abstractCollection: collection,
    collectionAuthor: getUser(state, { userId: collection.userId }),
    branch: getBranch(state, { projectId, branchId }),
    layerId,
    fileId,
    sha,
    canReorder,
    canEdit: collectionPolicy.update,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    // Why load branches for these? Well, on the project collections index we list
    // all collections: even those that were in branches which are merged. We need
    // to load the branches to ensure that we have the layer data for these collections.
    onLoad: () =>
      dispatch((dispatch, getState) => {
        const collection = getCollection(getState(), {
          collectionId: props.collectionId,
        });

        invariant(
          collection,
          "Collection must exist in state when passed to CollectionThumbnail"
        );

        const { projectId, branchId } = collection;

        dispatch(
          BranchFetchRequest.perform({
            params: { projectId, branchId },
          })
        );
      }),
  };
}

export default createConnector<Props, OwnProps>(
  connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch>(
    mapStateToProps,
    mapDispatchToProps
  ),
  withData((props) => props.params)
);
