// @flow
import { connect } from "react-redux";
import { loadProject, loadOrganization } from "abstract-di/actions";
import { isOnline } from "abstract-di/selectors";
import createConnector from "core/lib/createConnector";
import { ProjectCollectionsFetchRequest } from "core/requests/collections";
import {
  getCollectionsForProject,
  getCanCreateBranchCollections,
} from "core/selectors/collections";
import { getPaginationTotal } from "core/selectors/paginationTotals";
import { getOrganizationPolicy } from "core/selectors/policies";
import { getProject } from "core/selectors/projects";
import { getUser, getUsersForProject } from "core/selectors/users";
import type { State, Dispatch, CollectionBranchFilter } from "core/types";
import type { Props, OwnProps, StateProps, DispatchProps } from ".";

function getUserIdFromQuery(query) {
  return query.userId ? query.userId : undefined;
}

function getSearchFromQuery(query) {
  return query.search ? query.search : undefined;
}

function getBranchIdFromQuery(query) {
  return query.branchFilter === "master" || query.branchFilter === "main"
    ? "master"
    : undefined;
}

function getBranchStatusFromQuery(query) {
  return query.branchFilter === "active" || query.branchFilter === "archived"
    ? query.branchFilter
    : undefined;
}

function getBranchFilterFromQuery(query): CollectionBranchFilter {
  if (
    query.branchFilter &&
    (query.branchFilter === "master" ||
      query.branchFilter === "main" ||
      query.branchFilter === "active" ||
      query.branchFilter === "archived")
  ) {
    return query.branchFilter;
  }
  return "all";
}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { projectId } = props.params;
  const { query } = props.location;
  const userId = getUserIdFromQuery(query);

  const collectionsParams = {
    projectId,
    userId,
    search: getSearchFromQuery(query),
    branchId: getBranchIdFromQuery(query),
    branchStatus: getBranchStatusFromQuery(query),
  };

  const project = getProject(state, props.params);
  const isOffline = !isOnline(state);

  return {
    isOffline,
    project,
    canCreateCollectionsOnMaster: getCanCreateBranchCollections(state, {
      projectId,
      branchId: "master",
    }),
    users: getUsersForProject(state, { projectId }),
    collections: getCollectionsForProject(state, collectionsParams),
    selectedAuthor: userId ? getUser(state, { userId }) : undefined,
    selectedBranchFilter: getBranchFilterFromQuery(query),
    totalNumberOfCollections: getPaginationTotal(state, projectId),
    isFirstLoading: ProjectCollectionsFetchRequest.isFirstLoading(
      state,
      collectionsParams
    ),
    // If our request errors only due to being offline, we'll return false as
    // as isOffline will handle the offline view.
    // This way, when the user goes offline we don't throw an error. Then,
    // when they come back online, we immediately load new collections.
    hasError: isOffline
      ? false
      : ProjectCollectionsFetchRequest.hasError(state, collectionsParams),
    isLoading: ProjectCollectionsFetchRequest.isLoading(
      state,
      collectionsParams
    ),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    loadCollections: (paginationOptions: { limit: number, offset: number }) => {
      dispatch(async (dispatch, getState) => {
        const { projectId } = props.params;
        await loadProject(projectId);

        const project = getProject(getState(), props.params);

        if (!project) {
          return;
        }

        if (loadOrganization) {
          await dispatch(loadOrganization(project.organizationId));
        }

        const policy = getOrganizationPolicy(getState(), {
          organizationId: project.organizationId,
        });

        if (!policy.showCollections) {
          return;
        }

        const { query } = props.location;
        const userId = getUserIdFromQuery(query);
        const search = getSearchFromQuery(query);
        const branchId = getBranchIdFromQuery(query);
        const branchStatus = getBranchStatusFromQuery(query);

        return dispatch(
          ProjectCollectionsFetchRequest.perform({
            force: true,
            params: {
              projectId,
              branchId,
              search,
              userId,
              branchStatus,
              limit: paginationOptions.limit,
              offset: paginationOptions.offset,
            },
          })
        );
      });
    },
  };
}

export default createConnector<Props, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps)
);
