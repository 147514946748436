// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { openUntracked, editFile } from "abstract-di/actions";
import Button from "core/components/Button";
import OpenFileMenu from "core/components/OpenFileMenu";
import { isMac } from "core/lib/platform";
import * as File from "core/models/file";
import { getBranch } from "core/selectors/branches";
import { getFile } from "core/selectors/files";
import { getProjectPolicy } from "core/selectors/policies";
import type { File as TFile, Branch, State, Dispatch } from "core/types";

type OwnProps = {|
  projectId: string,
  branchId: string,
  fileId: string,
  sha: string,
  pageId?: string,
  layerId?: string,
  className?: string,
  disabled?: boolean,
  compact?: boolean,
|};

type StateProps = {|
  file: ?TFile,
  branch: ?Branch,
  canCreateCommit: ?boolean,
|};

type DispatchProps = {|
  editFile: () => void,
  openUntracked: () => void,
|};

type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

class OpenFileButton extends Component<Props> {
  getFileIsWriteable = () => {
    const { canCreateCommit, file, branch } = this.props;

    if (!canCreateCommit || !file || !branch) {
      return false;
    }
    if (branch.head === file.sha) {
      return true;
    }

    return false;
  };

  render() {
    const { file, compact } = this.props;
    const icon = file ? file.type : "file";
    const notSketch = !file || file.type !== "sketch";
    const disabled = this.props.disabled || !isMac() || notSketch;

    let buttonLabel = this.getFileIsWriteable() ? "Edit" : "Open";

    if (!compact) {
      buttonLabel += this.getFileIsWriteable()
        ? ` in ${File.fileTypeAppName(file ? file.type : "")}`
        : " Untracked";
    }

    const tooltip = notSketch
      ? `We no longer support ${File.fileTypeAppName(
          file ? file.type : ""
        )}, please contact support team`
      : !isMac()
      ? "Abstract can only be used with macOS"
      : undefined;

    const primaryButton = (
      <Button
        tooltip={tooltip ? { placement: "bottom", delayShow: 250 } : undefined}
        title={tooltip}
        icon={icon}
        onClick={
          this.getFileIsWriteable()
            ? this.props.editFile
            : this.props.openUntracked
        }
        disabled={disabled}
        className={this.props.className}
        qaSelector="openFileButton"
      >
        {buttonLabel}
      </Button>
    );

    // OpenFileMenu needs two CTAs (primary and secondary).
    // When primary action is "Edit", the secondary action will be "Open Untracked".
    // When primary action is "Open Untracked", secondary action will be "Edit on new branch".
    // Since "Edit on new branch" action is not possible from web, only use "OpenFileMenu" when the primary action is "Edit".
    return this.getFileIsWriteable() ? (
      <OpenFileMenu
        disabled={disabled}
        primaryButton={primaryButton}
        fileIsWriteable={this.getFileIsWriteable()}
        onOpenUntrack={this.props.openUntracked}
      />
    ) : (
      primaryButton
    );
  }
}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { projectId, branchId } = props;
  const policy = getProjectPolicy(state, { projectId });

  return {
    file: getFile(state, props),
    branch: getBranch(state, { projectId, branchId }),
    canCreateCommit: policy ? policy.createCommit : false,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { projectId, branchId, sha, layerId, fileId } = props;
  const descriptor = layerId
    ? {
        projectId,
        branchId,
        fileId,
        layerId,
        sha,
      }
    : {
        projectId,
        branchId,
        fileId,
        sha,
      };

  return {
    editFile() {
      dispatch(editFile(descriptor));
    },
    openUntracked() {
      dispatch(openUntracked(descriptor));
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(
  mapStateToProps,
  mapDispatchToProps
)(OpenFileButton);
