// @flow
import classnames from "classnames";
import * as React from "react";
import { V3NavLink as NavLink } from "core/lib/router";
import Icon from "../Icon";
import style from "./style.scss";

type Props = {
  children?: any,
  label?: string,
  to?: string,
  icon?: string,
  selected?: boolean,
  className?: string,
  activeClassName?: string,
  secondary?: boolean,
  disabled?: boolean,
};

export default class PillTab extends React.Component<Props> {
  render() {
    const {
      selected,
      activeClassName,
      icon,
      label,
      children,
      secondary,
      className,
      ...rest
    } = this.props;

    const conditionalClasses = {
      [style.active]: selected,
      [style.secondary]: this.props.secondary,
      [style.disabled]: this.props.disabled,
    };

    if (activeClassName) {
      conditionalClasses[activeClassName] = selected;
    }

    const classes = classnames(style.pillTab, conditionalClasses, className);

    const useReactRouterActiveClass = selected === undefined;
    const isActive = selected; // TODO: Icon should be aware of theme?

    return (
      <NavLink
        {...rest}
        className={classes}
        activeClassName={
          useReactRouterActiveClass
            ? classnames(style.active, activeClassName)
            : undefined
        }
      >
        {icon && (
          <Icon
            type={icon}
            light={isActive && !secondary}
            className={style.icon}
          />
        )}
        {children || label}
      </NavLink>
    );
  }
}
