// @flow
import * as React from "react";
import KeyCode from "core/lib/keycode";
import { modifierKeyPressed } from "core/lib/platform";
import { ValidatedInput } from "./withValidation";

type Props<TWrappedComponent: React.ComponentType<*>> = {
  onSubmit: (event: SyntheticEvent<>) => mixed,
  onKeyDown?: (event: SyntheticKeyboardEvent<>) => void,
  innerRef?: (?React.ElementRef<TWrappedComponent>) => mixed,
};

export default function withSubmittable<
  TWrappedComponent: React.ComponentType<*>,
>(WrappedInputComponent: TWrappedComponent) {
  return class SubmittableField extends React.Component<
    Props<TWrappedComponent>,
  > {
    onKeyDown = (event: SyntheticKeyboardEvent<>) => {
      const { onSubmit, onKeyDown } = this.props;
      if (modifierKeyPressed(event) && event.keyCode === KeyCode.KEY_RETURN) {
        event.stopPropagation();
        return onSubmit(event);
      }
      if (onKeyDown) {
        return onKeyDown(event);
      }
      return null;
    };

    render() {
      const { innerRef, ...props } = this.props;

      return (
        <WrappedInputComponent
          {...props}
          ref={innerRef}
          onKeyDown={this.onKeyDown}
        />
      );
    }
  };
}

export const SubmittableInput = withSubmittable(ValidatedInput);
