// @flow
import { Abstract } from "core/lib/abstract";
import type {
  LayerOptions,
  LayerShellMode,
  ReactRouterLocation,
} from "core/types";

export function locationLayerMode(location: ReactRouterLocation) {
  return stringToLayerMode(location.query.mode);
}

export function stringToLayerMode(mode: ?string): LayerShellMode {
  switch (mode) {
    case "design":
    case "compare":
    case "build":
      return mode;
    default:
      return "design";
  }
}

export function locationLayerPresent(location: ReactRouterLocation) {
  switch (location.query.present) {
    case "true":
      return location.query.present;
    default:
      return;
  }
}

function locationLayerCollectionLayerId(location: ReactRouterLocation) {
  if (location.query && location.query.collectionLayerId) {
    return location.query.collectionLayerId;
  }
}

function locationLayerSha(location: ReactRouterLocation) {
  if (location.query && location.query.sha) {
    return location.query.sha;
  }
}

export function locationLayerCommentId(location: ReactRouterLocation) {
  if (location.query && location.query.commentId) {
    return location.query.commentId;
  }
}

export function locationLayerSectionId(location: ReactRouterLocation) {
  if (location.query && location.query.sectionId) {
    return location.query.sectionId;
  }
}

export function locationLayerTheme(location: ReactRouterLocation) {
  switch (location.query.theme) {
    case "system":
    case "light":
    case "dark":
      return location.query.theme;
    default:
      return "light";
  }
}

export function locationLayerOptions(
  location: ReactRouterLocation
): LayerOptions {
  return {
    mode: locationLayerMode(location),
    present: locationLayerPresent(location),
    collectionLayerId: locationLayerCollectionLayerId(location),
    sha: locationLayerSha(location),
    commentId: locationLayerCommentId(location),
    sectionId: locationLayerSectionId(location),
    theme: locationLayerTheme(location),
  };
}
export function isSymbolFromSameProject(
  params: Abstract.LayerVersionDescriptor | Abstract.LayerDescriptor,
  symbolMasterDescriptor: ?Abstract.LayerDescriptor
) {
  if (!symbolMasterDescriptor) {
    return;
  }
  return params.projectId === symbolMasterDescriptor.projectId;
}

export function isForeignSymbol(
  params: Abstract.LayerVersionDescriptor | Abstract.LayerDescriptor,
  symbolMasterDescriptor: ?Abstract.LayerDescriptor
) {
  if (!symbolMasterDescriptor) {
    return;
  }

  return (
    params.projectId !== symbolMasterDescriptor.projectId ||
    params.fileId !== symbolMasterDescriptor.fileId
  );
}
