// @flow
import * as React from "react";
import Avatar from "core/components/Avatar";
import Card from "core/components/Card";
import Empty from "core/components/Empty";
import Flex from "core/components/Flex";
import Heading from "core/components/Heading";
import Table from "core/components/Table";
import type {
  UserMetrics,
  ReactTablePagination,
  ReportingFilter,
} from "core/types";
import style from "./style.scss";

type Props = {
  mobile?: boolean,
  metrics: UserMetrics,
  period?: ReportingFilter,
  fetchData: (pageData: ReactTablePagination, period?: ReportingFilter) => void,
};

export default function OrganizationReportingIndividualMetrics(props: Props) {
  const { sortBy } = props.metrics.meta;

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "user",
        width: 450,
      },
      {
        Header: "Active Projects",
        accessor: "activeProjects",
        canSort: true,
        isSorted: sortBy === "activeProjects",
      },
      {
        Header: "Active Branches",
        accessor: "activeBranches",
        canSort: true,
        isSorted: sortBy === "activeBranches",
      },
      {
        Header: "Commits Created",
        accessor: "createdCommits",
        canSort: true,
        isSorted: sortBy === "createdCommits",
      },
    ],
    [sortBy]
  );

  const data = React.useMemo(
    () =>
      props.metrics.data.map((userMetric) => ({
        user: (
          <Flex>
            <Avatar
              userId={userMetric.userId}
              userName={userMetric.username}
              src={userMetric.avatarUrl}
              size="40px"
            />
            <div className={style.userInfo}>
              <div className={style.name}>{userMetric.name}</div>
              <div className={style.username}>{userMetric.username}</div>
            </div>
          </Flex>
        ),
        ...userMetric,
      })),
    [props.metrics]
  );

  const { fetchData, period } = props;
  const [loading, setLoading] = React.useState(false);

  async function handleChangePage(pageData: ReactTablePagination) {
    if (!props.metrics) {
      return;
    }

    if (!loading && pageData.pageIndex !== props.metrics.meta.pageIndex) {
      setLoading(true);
      await fetchData(pageData, props.period);
      setLoading(false);
    }
  }

  const handleChangeSort = React.useCallback(
    async ({ pageIndex, pageSize, sortBy, sortDir }) => {
      setLoading(true);
      await fetchData(
        {
          pageIndex: pageIndex,
          pageSize: pageSize,
          sortBy,
          sortDir,
        },
        period
      );
      setLoading(false);
    },
    [fetchData, period]
  );

  const emptyNode = (
    <Empty
      className={style.emptyTable}
      icon="lightbulb"
      title="No insight data for the selected time period"
      description="As your team generates activity data, it’ll show up here. Or, try selecting another time period."
    />
  );

  return (
    <Flex column shrink={false}>
      <Card className={style.metricsList} qaSelector="metrics-list">
        <Heading
          level="3"
          size="xl"
          title="Individual Metrics"
          className={style.listHeader}
        >
          Active Users
        </Heading>
        <Table
          columns={columns}
          data={data}
          emptyNode={emptyNode}
          manual
          enablePagination
          controlledPageCount={props.metrics.meta.pageCount}
          controlledPageSize={props.metrics.meta.pageSize}
          controlledPageIndex={props.metrics.meta.pageIndex}
          controlledSortBy={props.metrics.meta.sortBy}
          controlledSortDir={props.metrics.meta.sortDir}
          changePage={handleChangePage}
          changeSort={handleChangeSort}
          loading={loading}
        />
      </Card>
    </Flex>
  );
}
