// @flow
import * as React from "react";
import { V3Link as Link } from "core/lib/router";
import { reviewsPath } from "core/lib/routes";
import image from "./images/branch.svg";
import Empty from ".";

type Props = {
  hasReviewer: boolean,
  organizationId: string,
};

export default function NoBranchReviews(props: Props) {
  const { hasReviewer, organizationId } = props;

  return (
    <Empty
      {...props}
      image={image}
      title="No branches to review"
      description={
        hasReviewer
          ? "There aren’t any reviews assigned to you."
          : "There aren’t any reviews in this organization."
      }
    >
      {hasReviewer && (
        <Link to={reviewsPath(organizationId)}>
          See all branch reviews in this organization.
        </Link>
      )}
    </Empty>
  );
}
