// @flow
import * as React from "react";
import Avatar from "core/components/Avatar";
import BranchMenu from "core/components/BranchMenu";
import BranchStatusDot from "core/components/BranchStatusDot";
import Flex from "core/components/Flex";
import GlobalSidebarMenuItem from "core/components/GlobalSidebarMenuItem";
import Icon from "core/components/Icon";
import type { KeyboardNavigationProps } from "core/components/KeyboardNavigation";
import ProgressCircle from "core/components/ProgressCircle";
import * as Routes from "core/lib/routes";
import type { Branch, DraftBranch } from "core/types";
import style from "./style.scss";

const MAX_NESTED_BRANCH_LEVEL = 8;

type DraftBranchProps = {|
  draftBranch: DraftBranch,
  keyboardNavigationProps: KeyboardNavigationProps,
  level?: number,
  mobile?: boolean,
  onClick?: (event: SyntheticEvent<>) => void,
|};

export function DraftBranchMenuItem(props: DraftBranchProps) {
  const { level = 1 } = props;

  return (
    <GlobalSidebarMenuItem
      key={`${props.draftBranch.id}-${props.draftBranch.progress || 1}`}
      keyboardNavigationProps={props.keyboardNavigationProps}
      label={(isActive) => (
        <Flex
          align="center"
          className={
            style[`nestedLevel${Math.min(level, MAX_NESTED_BRANCH_LEVEL)}`]
          }
        >
          {level > 1 ? <Icon light={isActive} type="child" /> : null}
          <ProgressCircle
            className={style.progress}
            light={isActive}
            percentage={props.draftBranch.progress || 1}
          />
          <span className={style.branchName}>{props.draftBranch.name}</span>
        </Flex>
      )}
      mobile={props.mobile}
      onClick={props.onClick}
      to={Routes.draftBranchPath(
        props.draftBranch.projectId,
        props.draftBranch.id
      )}
    />
  );
}

type Props = {|
  branch: Branch,
  branchStatus: string,
  draftBranches?: DraftBranch[],
  keyboardNavigationProps: KeyboardNavigationProps,
  level?: number,
  mobile?: boolean,
  onClick?: (event: SyntheticEvent<>) => void,
|};

export default function BranchMenuItem(props: Props) {
  const { draftBranches = [], level = 1 } = props;

  return (
    <React.Fragment>
      <BranchMenu projectId={props.branch.projectId} branchId={props.branch.id}>
        {(showMenu, renderMenuTarget) => {
          return renderMenuTarget((ref, buttonProps) => {
            return (
              <GlobalSidebarMenuItem
                key={`${props.branch.id}-${props.branchStatus}-${props.branch.updatedAt}`}
                keyboardNavigationProps={props.keyboardNavigationProps}
                label={(isActive) => (
                  <Flex
                    align="center"
                    className={
                      style[
                        `nestedLevel${Math.min(level, MAX_NESTED_BRANCH_LEVEL)}`
                      ]
                    }
                  >
                    {level > 1 ? (
                      <Icon
                        className={style.icon}
                        light={isActive}
                        type="child"
                      />
                    ) : null}
                    <Flex className={style.avatar} grow={false} shrink={false}>
                      <Avatar
                        size={16}
                        userId={props.branch.userId}
                        showNamePopover
                      />
                    </Flex>
                    <span className={style.branchName}>
                      {props.branch.name}
                    </span>
                    <Flex className={style.branchStatus}>
                      <BranchStatusDot
                        selected={isActive}
                        status={props.branchStatus}
                      />
                    </Flex>
                    <div className={style.contextMenuRef} ref={ref} />
                  </Flex>
                )}
                mobile={props.mobile}
                onClick={props.onClick}
                onContextMenu={showMenu}
                to={Routes.branchPath(props.branch.projectId, props.branch.id)}
                qaSelector="branchSidebarItem"
                qaSelectorWrapper={props.branch.name}
                activeOnChildPaths
                {...buttonProps}
              />
            );
          });
        }}
      </BranchMenu>
      {draftBranches.map((draftBranch) => (
        <DraftBranchMenuItem
          draftBranch={draftBranch}
          keyboardNavigationProps={props.keyboardNavigationProps}
          level={level + 1}
          mobile={props.mobile}
          onClick={props.onClick}
        />
      ))}
    </React.Fragment>
  );
}
