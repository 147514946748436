// @flow
import * as React from "react";
import ActivityBase from "./Base";
import Callout from "./Callout";
import type {
  ActivityBranchDeleted as TActivityBranchDeleted,
  Props,
} from "./types";

export default function ActivityBranchDeleted(
  props: Props<TActivityBranchDeleted>
) {
  return (
    <ActivityBase
      {...props}
      icon="branch"
      action="deleted the branch"
      subject={<Callout>{props.activity.payload.branchName}</Callout>}
    />
  );
}
