// @flow
import { deburr } from "lodash";

// matches two strings ignoring case, diacritics, and surrounding spaces
const matchString = (input: string = "", match: string = ""): boolean =>
  deburr(input)
    .toLowerCase()
    .trim()
    .includes(deburr(match).toLowerCase().trim());

export default matchString;
