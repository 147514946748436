// @flow
import { schema, normalize } from "normalizr";
import { file, library, page } from "core/schema";

export const filesResponseSchema = new schema.Object({
  files: [file],
  libraries: [library],
  pages: [page],
});

export function normalizeFilesResponse(filesResponse: *) {
  return normalize(filesResponse, filesResponseSchema);
}

export function normalizeFileResponse(fileResponse: *) {
  return normalize(fileResponse, file);
}
