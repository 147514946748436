// @flow
import { useSelector } from "react-redux";
import { getCurrentUser, getCurrentUserId } from "abstract-di/selectors";
import { getUser, getUserByUsername } from "core/selectors/users";

type UserIdentifier = {| username: string |} | {| userId: string |};

export const useCurrentUserId = () => useSelector(getCurrentUserId);

export const useCurrentUser = () => useSelector(getCurrentUser);

export const useUser = (props: UserIdentifier) => {
  return useSelector((state) => {
    if (props.userId) {
      return getUser(state, { userId: props.userId });
    } else if (props.username) {
      return getUserByUsername(state, { username: props.username });
    }
  });
};
