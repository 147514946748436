// @flow
import classnames from "classnames";
import pluralize from "pluralize";
import * as React from "react";
import Card from "core/components/Card";
import Facepile from "core/components/Facepile";
import Note from "core/components/Note";
import PlaceholderText from "core/components/PlaceholderText";
import TeamAvatar from "core/components/TeamAvatar";
import TextHighlight from "core/components/TextHighlight";
import { Abstract } from "core/lib/abstract";
import { V3Link as Link } from "core/lib/router";
import { organizationTeamPath } from "core/lib/routes";
import type { Team, User } from "core/types";
import connector from "./connector.js";
import style from "./style.scss";

export type OwnProps = {|
  params: Abstract.OrganizationDescriptor,
  team: Team,
  highlight?: string,
  card?: boolean,
  mobile: boolean,
  actions?: React.Node,
  className?: string,
  qaSelector?: string,
|};

export type StateProps = {|
  isLoading: boolean,
  members: User[],
  paginatedMemberTotal: ?number,
  canClickTeams: boolean,
|};

export type DispatchProps = {|
  onLoad: () => Promise<void>,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

function TeamCard({
  params,
  team,
  highlight,
  members,
  card,
  mobile,
  actions,
  className,
  isLoading,
  paginatedMemberTotal,
  canClickTeams,
  qaSelector,
}: Props) {
  const count = paginatedMemberTotal || members.length;

  return (
    <Card
      to={
        canClickTeams
          ? organizationTeamPath({
              organizationId: params.organizationId,
              teamId: team.id,
            })
          : undefined
      }
      component={canClickTeams ? Link : undefined}
      list={!card}
      wrappedList={!card}
      mobile={mobile}
      qaSelector={qaSelector}
      className={classnames(
        {
          [style.list]: !card && !mobile,
          [style.card]: card && !mobile,
          [style.mobile]: mobile,
        },
        className
      )}
      dynamic
    >
      <div className={style.wrap}>
        <div className={style.inner}>
          <TeamAvatar teamId={team.id} size={card ? 96 : 40} />
          <div className={style.section}>
            <span className={style.name} title={team.name}>
              <TextHighlight
                text={team.name}
                highlight={highlight && highlight.trim()}
              />
            </span>
            {isLoading ? (
              <PlaceholderText
                className={style.placeholderNote}
                max={!card ? 12 : undefined}
                min={!card ? 8 : undefined}
              />
            ) : (
              <Note component={card ? "div" : "span"}>
                {count} {pluralize("member", count)}
              </Note>
            )}
          </div>
          {isLoading ? (
            <PlaceholderText
              size="l"
              className={style.placeholderFacepile}
              max={!card ? 12 : undefined}
              min={!card ? 8 : undefined}
            />
          ) : (
            <Facepile
              className={style.facepile}
              users={members}
              total={count}
              countColor={team.color}
            />
          )}
          <span className={style.action}>{actions}</span>
        </div>
      </div>
    </Card>
  );
}

export default connector(TeamCard);
