// @flow
import { static as Immutable } from "seamless-immutable";
import type { Action } from "core/types";

type State = {
  open: boolean,
  featureOverrides: {
    [feature: string]: boolean,
  },
};

const DEFAULT_STATE = Immutable.from({
  open: false,
  featureOverrides: {},
});

export default function (state: State = DEFAULT_STATE, action: Action): State {
  switch (action.type) {
    case "DEVTOOLS_OPENED":
      return Immutable.setIn(state, ["open"], true);
    case "DEVTOOLS_CLOSED":
      return Immutable.setIn(state, ["open"], false);
    case "DEVTOOLS_FEATURE_OVERRIDDEN":
      return Immutable.setIn(
        state,
        ["featureOverrides", action.meta.feature],
        action.payload
      );
    case "DEVTOOLS_FEATURE_RESET": {
      const feature = action.meta && action.meta.feature;
      if (feature) {
        return Immutable.setIn(
          state,
          ["featureOverrides"],
          Immutable.without(
            Immutable.getIn(state, ["featureOverrides"]),
            feature
          )
        );
      }
      return Immutable.setIn(state, ["featureOverrides"], {});
    }
    default:
      return state;
  }
}
