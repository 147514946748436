// @flow
import * as React from "react";
import image from "./images/activity.svg";
import Empty from ".";

export default function NoAccessTokens(props: {}) {
  return (
    <Empty
      {...props}
      image={image}
      title="No API tokens"
      description="Your API access tokens will be displayed here."
    />
  );
}
