// @flow
import classnames from "classnames";
import path from "path";
import * as React from "react";
import DialogForm, { TDialogForm } from "core/components/DialogForm";
import FileIcon from "core/components/FileIcon";
import Flex from "core/components/Flex";
import { SubmittableInput } from "core/components/Input/withSubmittable";
import InputCheckbox from "core/components/InputCheckbox";
import { helpLibrariesUrl } from "core/lib/urls";
import { fileTypeAppName, extension } from "core/models/file";
import type { ImportOptions } from "core/types";
import Validations from "core/validations";
import connector from "./connector";
import style from "./style.scss";

type FileType = "sketch";

export type OwnProps = {|
  projectId: string,
  branchId: string,
  hideIsLibrary?: boolean,
  importOptions?: ImportOptions,
|};

export type StateProps = {|
  canCreateSketchFile: boolean,
  canUseSketchLibraries: boolean,
|};

export type DispatchProps = {|
  createEmptyFile: (name: string, type: string, isLibrary: boolean) => void,
  dismissDialog: () => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type State = {
  name: string,
  type: FileType,
  isLibrary: boolean,
};

class CreateFile extends React.Component<Props, State> {
  form: ?TDialogForm;
  state = {
    name: "",
    type: "sketch",
    isLibrary: false,
  };

  nameChanged = (event: SyntheticInputEvent<>) => {
    this.setState({ name: event.target.value });
    this.form && this.form.checkValid();
  };

  libraryChanged = (event: SyntheticInputEvent<>) => {
    this.setState((prevState) => ({ isLibrary: !prevState.isLibrary }));
  };

  fileTypeChanged = (event: SyntheticEvent<>, type: FileType) => {
    event.preventDefault();
    this.setState((prev) => ({
      type,
      isLibrary:
        type === "sketch" && !this.props.canUseSketchLibraries
          ? false
          : prev.isLibrary,
    }));
  };

  handleSubmit = (event: SyntheticEvent<>) => {
    event.preventDefault();
    if (this.form && this.form.isValid()) {
      const { name, type, isLibrary } = this.state;
      // If they end the filename with an extension lets go ahead and remove
      // that for them
      let fileName = name;
      const ext = path.extname(name);
      if (ext.toLowerCase() === extension(type)) {
        fileName = path.basename(name, ext);
      }
      this.props.createEmptyFile(fileName, type, isLibrary);
      this.props.dismissDialog();
    }
  };

  get buttonText(): string {
    let fileType = fileTypeAppName(this.state.type);
    if (this.state.isLibrary) {
      fileType += " Library";
    }
    return `Create ${fileType} File`;
  }

  renderFileButton = (type: FileType) => {
    const isLibrary =
      this.state.isLibrary &&
      (type !== "sketch" || this.props.canUseSketchLibraries);
    const disabled = type === "sketch" && !this.props.canCreateSketchFile;
    const appName = fileTypeAppName(type);
    return (
      <button
        key={type}
        type="button"
        className={classnames(style.fileButton, {
          [style.fileButtonSelected]: this.state.type === type,
        })}
        disabled={disabled}
        onClick={(event) => this.fileTypeChanged(event, type)}
      >
        <FileIcon large type={type} isLibrary={isLibrary} />
        <div className={style.fileButtonName}>
          {this.state.name || "File name"}
        </div>
        <div className={style.fileButtonType}>
          {disabled
            ? `${appName} isn’t installed`
            : `${appName}${isLibrary ? " library" : ""} file`}
        </div>
      </button>
    );
  };

  render() {
    const { canUseSketchLibraries, dismissDialog, hideIsLibrary } = this.props;

    return (
      <DialogForm
        title="Create a new Sketch file"
        primaryButton={this.buttonText}
        ref={(ref) => (this.form = ref)}
        onClose={dismissDialog}
        onSubmit={this.handleSubmit}
        isOpen
      >
        {({ onSubmit }) => (
          <React.Fragment>
            <SubmittableInput
              type="text"
              label="File name"
              onSubmit={onSubmit}
              minLength={Validations.minFileNameLength}
              maxLength={Validations.maxFileNameLength}
              onChange={this.nameChanged}
              placeholder="File name…"
              requiredTag
              required
              autoFocus
            />
            {canUseSketchLibraries && !hideIsLibrary && (
              <Flex align="center" justify="space-between">
                <InputCheckbox
                  label="Use as Library"
                  disabled={
                    this.state.type === "sketch" && !canUseSketchLibraries
                  }
                  checked={this.state.isLibrary}
                  onChange={this.libraryChanged}
                  wrapperClass={style.checkbox}
                  labelClass={style.libraryLabel}
                />
                <a href={helpLibrariesUrl()} className={style.infoLink}>
                  What are Libraries?
                </a>
              </Flex>
            )}
          </React.Fragment>
        )}
      </DialogForm>
    );
  }
}

export default connector(CreateFile);
