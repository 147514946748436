// @flow
/* global HTMLElement */
import * as React from "react";
import ContextMenu from "core/components/ContextMenu";
import contextMenuWrapper from "core/components/ContextMenu/ContextMenuWrapper";
import { isDesktop } from "core/lib/platform";
import { readableFilesize } from "core/lib/textFormatting";
import type {
  Project as TProject,
  Policy,
  Section,
  MenuItem,
} from "core/types";
import connector from "./connector";

export type OwnProps = {|
  innerRef?: React.Ref<typeof ContextMenu>,
  project: TProject,
  canMoveProject?: boolean,
  onClickDelete: () => void,
  onClickCreateSection: () => void,
  onAfterClose?: () => void,
  children: (
    showMenu: (ev: SyntheticEvent<>) => void,
    ref: (el: ?HTMLElement) => void,
    popoverHandlers?: Object,
    buttonProps?: {
      "aria-haspopup": string,
      "aria-expanded": boolean,
      role: string,
      onKeyDown: (event: KeyboardEvent) => void,
    }
  ) => React.Node,
  closeMobileSidebar?: () => void,
|};

export type StateProps = {|
  policy: Policy,
  sections: Section[],
  currentUserId: ?string,
  isOffline: boolean,
  isUnsynced: boolean,
  isSyncing: boolean,
  isFavoriteProject: boolean,
  canCreateSection: boolean,
  canMoveProject: boolean,
  canPartialSync: boolean,
  sectionsLoading: boolean,
  development: boolean,
  uncommittedChanges?: boolean,
|};

export type DispatchProps = {|
  onRepack: () => void,
  onExportProject: () => void,
  onExportProjectAsZip: () => void,
  copyProjectId: () => void,
  openInFinder: () => void,
  openInTerminal: () => void,
  onSyncRepo: () => Promise<void>,
  onStopSyncingProject: () => void,
  onStartSyncingProject: () => void,
  onRemoveProjectData: () => void,
  onGoToActivity: () => void,
  onGoToMembers: () => void,
  onGoToSettings: () => void,
  onArchiveProject: () => void,
  onUnarchiveProject: () => void,
  addAsFavorite: () => void,
  removeAsFavorite: () => void,
  onLeaveProject: () => void,
  onMoveProject: (sectionId: ?string) => void,
  onLoad: () => void,
|};

type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

class ProjectMenu extends React.Component<Props> {
  menu: ?ContextMenu;
  menuRef = (ref: *) => (this.menu = ref);

  sectionsSubmenu(): void | MenuItem[] {
    if (this.props.isOffline || this.props.sectionsLoading) {
      return undefined;
    }

    const { sections, project, onMoveProject } = this.props;

    const sectionItems = sections.length
      ? [
          {
            type: "checkbox",
            label: "No Section",
            checked: !project.sectionId,
            click: () => onMoveProject(null),
          },
          { type: "separator" },
        ].concat(
          sections.map((section) => ({
            type: "checkbox",
            label: `${section.name}`,
            checked: section.id === project.sectionId,
            click: () => onMoveProject(section.id),
          }))
        )
      : [];

    return this.props.canCreateSection
      ? sectionItems.concat([
          { type: "separator", visible: !!sections.length },
          {
            type: "checkbox",
            label: "Create Section…",
            checked: false,
            click: this.props.onClickCreateSection,
          },
        ])
      : sectionItems;
  }

  menuItems(): Array<MenuItem> {
    const { project, policy, isUnsynced, canPartialSync, sectionsLoading } =
      this.props;
    const sectionsSubmenu = this.sectionsSubmenu();
    const hasSectionsSubmenu = sectionsSubmenu && sectionsSubmenu.length > 0;
    const moveEnabled =
      !this.props.isOffline && !sectionsLoading && hasSectionsSubmenu;
    const isEditable = !isUnsynced || canPartialSync;

    return [
      {
        label: "Activity",
        click: () => {
          this.props.onGoToActivity();

          if (this.props.closeMobileSidebar) {
            this.props.closeMobileSidebar();
          }
        },
      },
      {
        label: "Members",
        click: () => {
          this.props.onGoToMembers();

          if (this.props.closeMobileSidebar) {
            this.props.closeMobileSidebar();
          }
        },
        visible: policy.listMembers === true,
      },
      {
        label: "Project Settings",
        click: () => {
          this.props.onGoToSettings();

          if (this.props.closeMobileSidebar) {
            this.props.closeMobileSidebar();
          }
        },
        enabled: !this.props.isOffline,
        visible: policy.update === true,
      },
      {
        label: sectionsLoading ? "Loading Sections…" : "Move Project",
        visible: this.props.canMoveProject === true,
        enabled: moveEnabled,
        submenu: moveEnabled ? sectionsSubmenu : undefined,
      },
      {
        label: "Leave Project",
        click: () => this.props.onLeaveProject(),
        visible: policy.leave === true,
      },
      { type: "separator" },
      {
        type: "share",
        label: "Copy Link to Project",
        props: {
          kind: "project",
          organizationId: project.organizationId,
          projectId: project.id,
        },
        visible: policy.share === true,
        enabled: !this.props.isOffline,
      },
      {
        label: "Add to Favorites",
        click: this.props.addAsFavorite,
        visible: !this.props.isFavoriteProject,
      },
      {
        label: "Remove from Favorites",
        click: this.props.removeAsFavorite,
        visible: this.props.isFavoriteProject,
      },
      {
        label: "Export Project Files…",
        click: this.props.onExportProject,
        enabled: isEditable,
        visible: !!this.props.onExportProject && isDesktop,
      },
      { type: "separator" },
      {
        label: "Stop Syncing…",
        click: this.props.onStopSyncingProject,
        visible: isEditable && isDesktop && !this.props.canPartialSync,
        enabled: !this.props.uncommittedChanges && !this.props.isSyncing,
      },
      {
        label: "Sync Project",
        click: this.props.onStartSyncingProject,
        visible: this.props.isUnsynced === true && isDesktop && !canPartialSync,
      },
      {
        label: "Archive Project",
        click: this.props.onArchiveProject,
        visible: !project.archivedAt && policy.archive === true,
      },
      {
        label: "Unarchive Project",
        click: this.props.onUnarchiveProject,
        visible: !!project.archivedAt && policy.unarchive === true,
      },
      {
        label: "Delete Project…",
        click: this.props.onClickDelete,
        preventClose: true,
        visible: policy.destroy === true,
      },
      {
        label: `Project Size: ${readableFilesize(project.sizeInBytes)}`,
        visible: !!project.sizeInBytes,
        enabled: false,
      },
      { type: "separator", visible: this.props.development && isDesktop },
      {
        label: "Development",
        visible: this.props.development && isDesktop,
        enabled: isEditable,
        submenu: [
          {
            label: `Status: ${
              isUnsynced
                ? "Unsynced"
                : this.props.isSyncing
                ? "Syncing…"
                : "Synced"
            }`,
            enabled: false,
          },
          { type: "separator" },
          {
            label: "Download Project",
            click: this.props.onStartSyncingProject,
            visible: isUnsynced === true && isDesktop && canPartialSync,
          },
          {
            label: "Remove Local Project Data",
            click: this.props.onRemoveProjectData,
            visible: !isUnsynced && isEditable && isDesktop && canPartialSync,
          },
          {
            label: "Force Sync Project",
            click: this.props.onSyncRepo,
            enabled: !this.props.isUnsynced,
          },
          {
            label: "Repack Project",
            click: this.props.onRepack,
            enabled: !this.props.isUnsynced,
          },
          {
            label: "Export Project as Zip…",
            click: this.props.onExportProjectAsZip,
            enabled: !this.props.isUnsynced,
          },
          {
            label: "Open in Finder",
            click: this.props.openInFinder,
            enabled: !this.props.isUnsynced,
          },
          {
            label: "Open in Terminal",
            click: this.props.openInTerminal,
            enabled: !this.props.isUnsynced,
          },
          {
            label: "Copy Project ID",
            click: this.props.copyProjectId,
          },
        ],
      },
    ];
  }

  render() {
    const menuItems = this.menuItems();

    return (
      <ContextMenu
        ref={this.props.innerRef}
        id={this.props.project.id}
        menuItems={menuItems}
        placement="bottom-end"
        onAfterClose={this.props.onAfterClose}
        children={this.props.children}
      />
    );
  }
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default contextMenuWrapper(connector(ProjectMenu));
