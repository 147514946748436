// @flow
import classnames from "classnames";
import * as React from "react";
import logo from "./images/logo.svg";
import style from "./style.scss";

type Props = {
  title: React.Node,
  showLogo?: boolean,
  titleClass?: string,
  subtitle?: string,
  boldSubtitle?: boolean,
  subtitleClass?: string,
  extra?: React.Element<*>,
};

export default function BrandHeader({
  title,
  showLogo,
  titleClass,
  subtitle,
  boldSubtitle,
  subtitleClass,
  extra,
}: Props) {
  const Subtitle = boldSubtitle ? "strong" : "div";
  return (
    <div className={style.heading}>
      {showLogo && (
        <img src={logo} alt="Abstract Logo" width="32" height="32" />
      )}
      <h1 className={classnames(style.headingTitle, titleClass)}>{title}</h1>
      {subtitle && <Subtitle className={subtitleClass}>{subtitle}</Subtitle>}
      {extra && extra}
    </div>
  );
}
