// @flow
import classnames from "classnames";
import empty from "empty";
import * as React from "react";
import ResizeDetector from "react-resize-detector";
import { ZOOM_TO_TOP } from "core/components/ZoomPercentageInput";
import window from "core/global/window";
import KeyCode from "../../lib/keycode";
import type { Position, Size } from "../../types";
import style from "./style.scss";

export type ChangeEvent = {
  scale: number,
  scaledToFit: boolean,
  offsetX: number,
  offsetY: number,
};

type Props = {
  src: string,
  children?: (scale: number, style: Object) => React.Node,
  maxWidth?: number,
  maxHeight?: number,
  width?: number,
  height?: number,
  zoomMax: number,
  zoomMin: number,
  padding: number,
  isScrollable: boolean,
  onChange?: (zoomState: ChangeEvent) => void,
  scale: number,
  scaledToFit: boolean,
  offsetX: number,
  offsetY: number,
  controlled?: boolean,
  className?: string,
  alt?: string,
  preventAutoFocus?: boolean,
  qaSelector?: string,
};

type State = {
  scale: number,
  offsetX: number,
  offsetY: number,
  dragStartX: number,
  dragStartY: number,
  dragging: boolean,
  scaledToFit: boolean,
  grabbing: ?boolean,
};

const RIGHT_CLICK_BUTTON = 2;

function clampX(
  x: number,
  imageBounds: Size,
  containerBounds: Size,
  padding: number
): number {
  const boundLeft = imageBounds.width / 2 - containerBounds.width / 2 + padding;
  const boundRight =
    -imageBounds.width / 2 + containerBounds.width / 2 - padding;

  if (imageBounds.width > containerBounds.width) {
    return Math.min(boundLeft, Math.max(boundRight, x));
  } else {
    return Math.max(boundLeft, Math.min(boundRight, x));
  }
}

function clampY(
  y: number,
  imageBounds: Size,
  containerBounds: Size,
  padding: number
): number {
  const boundTop =
    imageBounds.height / 2 - containerBounds.height / 2 + padding;
  const boundBottom =
    -imageBounds.height / 2 + containerBounds.height / 2 - padding;

  if (imageBounds.height > containerBounds.height) {
    return Math.min(boundTop, Math.max(boundBottom, y));
  } else {
    return Math.max(boundTop, Math.min(boundBottom, y));
  }
}

function topBound(
  imageBounds: Size,
  containerBounds: Size,
  padding: number
): number {
  return containerBounds.height / 2 - imageBounds.height / 2 - padding;
}

function positionForEvent(
  event: Object // SyntheticMouseEvent<*> | SyntheticTouchEvent<*>
): Position {
  if (event.changedTouches) {
    return {
      x: event.changedTouches[0].clientX,
      y: event.changedTouches[0].clientY,
    };
  } else {
    return {
      x: event.clientX,
      y: event.clientY,
    };
  }
}

const CHILDREN_STYLE = {
  transform: `translate(-50%, -50%)`,
};

export default class ZoomablePreview extends React.Component<Props, State> {
  container: ?HTMLElement;
  image: ?HTMLImageElement;

  static defaultProps = {
    padding: 16,
    zoomMax: 400,
    zoomMin: 50,
    isScrollable: true,
    offsetX: 0,
    offsetY: 0,
    scale: 0,
    scaledToFit: true,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      scale: props.scale || this.scaleToFit,
      scaledToFit: props.scaledToFit,
      offsetX: props.offsetX,
      offsetY: props.offsetY,
      dragStartX: 0,
      dragStartY: 0,
      dragging: false,
      grabbing: false,
    };
  }

  componentDidMount() {
    const container = this.container;

    if (container) {
      if (!this.props.preventAutoFocus) {
        container.focus();
      }
      container.addEventListener("wheel", this.handleMouseWheel, {
        passive: false,
      });
    }

    window.addEventListener("keydown", this.handleKeyDown);
    window.addEventListener("keyup", this.handleKeyUp);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
    window.removeEventListener("keyup", this.handleKeyUp);

    if (this.container) {
      this.container.removeEventListener("wheel", this.handleMouseWheel, {
        passive: false,
      });
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.offsetX !== this.props.offsetX ||
      prevProps.offsetY !== this.props.offsetY ||
      prevProps.scale !== this.props.scale
    ) {
      if (this.props.offsetY === ZOOM_TO_TOP) {
        if (this.imageDimensions.height > this.imageDimensions.width) {
          this.zoomToTop();
        } else {
          this.update(() => ({
            scale: this.props.scale,
            scaledToFit: this.props.scaledToFit,
            offsetX: this.props.offsetX,
            offsetY: 0,
          }));
        }
      }
    }
  }

  update(callback: (value: ChangeEvent) => ChangeEvent) {
    const { onChange } = this.props;
    if (this.props.controlled) {
      onChange &&
        onChange(
          callback({
            scale: this.scale,
            scaledToFit: this.scaledToFit,
            offsetX: this.offsetX,
            offsetY: this.offsetY,
          })
        );
    } else {
      this.setState((state) =>
        callback({
          scale: this.scale,
          scaledToFit: this.scaledToFit,
          offsetX: this.offsetX,
          offsetY: this.offsetY,
        })
      );
    }
  }

  zoomToTop = () => {
    this.update((value) => {
      return {
        scale: this.props.scale,
        scaledToFit: this.props.scaledToFit,
        offsetX: 0,
        offsetY: topBound(
          this.imageDimensions,
          this.containerBounds,
          this.props.padding
        ),
      };
    });
  };

  zoomToFit = () => {
    const scale = this.scaleToFit;

    if (Number.isNaN(scale)) {
      return;
    } // TODO: Don't do math with empty this.containerBounds

    this.update(() => ({
      scale,
      scaledToFit: true,
      offsetX: 0,
      offsetY: 0,
    }));
  };

  zoomToFullsize = (
    event?: SyntheticMouseEvent<*> | SyntheticTouchEvent<*>
  ) => {
    const position = event ? positionForEvent(event) : undefined;

    this.update((value) => {
      let offsetX = 0;
      let offsetY = 0;

      // automatically offset the image based on where the user clicked
      if (position) {
        const { width, height } = this.imageDimensions;
        const zoom = 1 / (value.scale / 100);
        const mouseX = position.x - this.imageBounds.left;
        const mouseY = position.y - this.imageBounds.top;
        const scaledWidth = this.imageBounds.width;
        const scaledHeight = this.imageBounds.height;
        const clickOffsetX = scaledWidth / 2 - mouseX;
        const clickOffsetY = scaledHeight / 2 - mouseY;
        const focusX = mouseX * zoom;
        const focusY = mouseY * zoom;

        offsetX =
          this.image && this.image.naturalWidth > this.containerBounds.width
            ? focusX - width / 2 + clickOffsetX
            : 0;

        offsetY =
          this.image && this.image.naturalHeight > this.containerBounds.height
            ? focusY - height / 2 + clickOffsetY
            : 0;
      }

      return {
        scale: 100,
        scaledToFit: false,
        offsetX: clampX(
          offsetX,
          this.imageDimensions,
          this.containerBounds,
          this.props.padding
        ),
        offsetY: clampY(
          offsetY,
          this.imageDimensions,
          this.containerBounds,
          this.props.padding
        ),
      };
    });
  };

  get scaleToFit(): number {
    const maxWidth =
      (this.props.maxWidth || this.containerBounds.width) -
      this.props.padding * 2;
    const maxHeight =
      (this.props.maxHeight || this.containerBounds.height) -
      this.props.padding * 2;
    const { width, height } = this.imageDimensions;

    return Math.min(100, (maxHeight / height) * 100, (maxWidth / width) * 100);
  }

  get containerBounds(): ClientRect | Object {
    return this.container
      ? this.container.getBoundingClientRect()
      : empty.object;
  }

  get imageBounds(): ClientRect | Object {
    return this.image ? this.image.getBoundingClientRect() : empty.object;
  }

  get imageDimensions(): Size {
    // if width / height are provided as props then use these so we can
    // correctly position the image before it's loaded. Otherwise fallback to
    // reading the dimensions from the image.
    return {
      width: this.props.width
        ? this.props.width
        : this.image
        ? this.image.naturalWidth
        : 0,
      height: this.props.height
        ? this.props.height
        : this.image
        ? this.image.naturalHeight
        : 0,
    };
  }

  get scale(): number {
    const invalidScale =
      this.state.scale === undefined || Number.isNaN(this.state.scale);

    return this.props.controlled || invalidScale
      ? this.props.scale
      : this.state.scale;
  }

  get scaledToFit(): boolean {
    if (this.props.controlled) {
      return this.props.scaledToFit;
    }
    return this.state.scaledToFit !== undefined
      ? this.state.scaledToFit
      : this.props.scaledToFit;
  }

  get offsetX(): number {
    if (this.props.controlled) {
      return this.props.offsetX;
    }

    return this.state.offsetX !== undefined
      ? this.state.offsetX
      : this.props.offsetX;
  }

  get offsetY(): number {
    if (this.props.controlled) {
      return this.props.offsetY;
    }

    return this.state.offsetY !== undefined
      ? this.state.offsetY
      : this.props.offsetY;
  }

  get positionerStyle(): Object {
    if (!this.container) {
      return { display: "none" };
    }

    const centerX = this.containerBounds.width / 2;
    const centerY = this.containerBounds.height / 2;

    // rounding is important to make sure that are sitting on full pixels
    const x = Math.round(centerX - this.offsetX);
    const y = Math.round(centerY - this.offsetY);

    return { transform: `translate(${x}px, ${y}px)` };
  }

  get imageStyle(): Object {
    // we use the image dimensions, halved and rounded to make sure that the
    // image element is sitting on full pixels, otherwise this can appear
    // blurry on non-retina displays.
    const scale = this.scale;
    const imageDimensions = this.imageDimensions;

    const width = Math.round(imageDimensions.width * (scale / 100));
    const height = Math.round(imageDimensions.height * (scale / 100));
    const offsetX = width ? `-${Math.round(width / 2)}px` : "-50%";
    const offsetY = height ? `-${Math.round(height / 2)}px` : "-50%";

    return {
      transform: `translate(${offsetX}, ${offsetY})`,
      width: `${width}px`,
      height: `${height}px`,
    };
  }

  get imageWidthOverflowsContainer(): boolean {
    return this.imageBounds.width > this.containerBounds.width;
  }

  get imageHeightOverflowsContainer(): boolean {
    return this.imageBounds.height > this.containerBounds.height;
  }

  get isLargerThanContainer(): boolean {
    return (
      this.imageWidthOverflowsContainer || this.imageHeightOverflowsContainer
    );
  }

  stopDragging = () => {
    this.setState({
      dragging: false,
      dragStartX: undefined,
      dragStartY: undefined,
    });

    window.removeEventListener("mousemove", this.handleDrag);
    window.removeEventListener("touchmove", this.handleDrag);
    window.removeEventListener("mouseout", this.stopDragging);
  };

  handleLoad = () => {
    const { onChange } = this.props;

    if (this.props.controlled) {
      onChange &&
        onChange({
          scaledToFit: this.scaledToFit,
          scale: this.scaledToFit ? this.scaleToFit : this.scale,
          offsetX: clampX(
            this.offsetX,
            this.imageBounds,
            this.containerBounds,
            this.props.padding
          ),
          offsetY: clampY(
            this.offsetY,
            this.imageBounds,
            this.containerBounds,
            this.props.padding
          ),
        });
    } else {
      if (this.scaledToFit || !this.scale) {
        this.zoomToFit();
      }
    }
  };

  handleMouseDown = (
    event: SyntheticMouseEvent<*> | SyntheticTouchEvent<*>
  ) => {
    if (
      event.altKey ||
      (event.type === "mousedown" && event.button === RIGHT_CLICK_BUTTON)
    ) {
      return;
    }

    if (event.type === "mousedown" && !this.state.grabbing) {
      event.preventDefault();
      return;
    }

    // If we don't prevent the default action then the image is draggable out
    // of the window.
    event.preventDefault();

    if (!this.scaledToFit) {
      const { x, y } = positionForEvent(event);

      this.setState({
        dragging: true,
        dragStartX: this.offsetX + x,
        dragStartY: this.offsetY + y,
      });

      window.addEventListener("mousemove", this.handleDrag);
      window.addEventListener("touchmove", this.handleDrag);
      window.addEventListener("mouseout", this.stopDragging);
    }
  };

  handleMouseUp = (event: SyntheticMouseEvent<*> | SyntheticTouchEvent<*>) => {
    event.preventDefault();

    if (event.type === "mouseup" && event.button === RIGHT_CLICK_BUTTON) {
      return;
    }

    if (!this.state.dragging) {
      if (this.scale === 100) {
        this.zoomToFit();
      } else {
        this.zoomToFullsize(event);
      }
    }

    this.stopDragging();
  };

  handleKeyUp = (event: KeyboardEvent) => {
    if (event.keyCode === KeyCode.KEY_SPACE && this.state.grabbing) {
      this.setState({ grabbing: false });
    }
  };

  handleKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === KeyCode.KEY_SPACE && !this.state.grabbing) {
      this.setState({ grabbing: true });
    }
  };

  handleDrag = (event: MouseEvent | TouchEvent) => {
    // stops dragging if mouse goes out of bounds of the container
    const bounds = this.containerBounds;
    const { x, y } = positionForEvent(event);
    const outOfBounds =
      x < bounds.left ||
      x > bounds.right ||
      y < bounds.top ||
      y > bounds.bottom;

    const dragX = this.imageWidthOverflowsContainer
      ? this.state.dragStartX - x
      : this.offsetX;

    const dragY = this.imageHeightOverflowsContainer
      ? this.state.dragStartY - y
      : this.offsetY;

    if (outOfBounds) {
      this.stopDragging();
    } else {
      this.setState({ dragging: true }, () => {
        this.update((value) => ({
          ...value,
          offsetX: clampX(
            dragX,
            this.imageBounds,
            this.containerBounds,
            this.props.padding
          ),
          offsetY: clampY(
            dragY,
            this.imageBounds,
            this.containerBounds,
            this.props.padding
          ),
        }));
      });
    }
  };

  handleResize = () => {
    if (this.scaledToFit) {
      this.zoomToFit();
    } else {
      this.forceUpdate();
    }
  };

  handleMouseWheel = (event: WheelEvent) => {
    if (!this.props.isScrollable || this.scaledToFit) {
      return;
    }

    event.preventDefault();

    const mouseWheelX = this.imageWidthOverflowsContainer
      ? this.offsetX + event.deltaX
      : this.offsetX;

    const mouseWheelY = this.imageHeightOverflowsContainer
      ? this.offsetY + event.deltaY
      : this.offsetY;

    this.update((value) => ({
      ...value,
      offsetX: clampX(
        mouseWheelX,
        this.imageBounds,
        this.containerBounds,
        this.props.padding
      ),
      offsetY: clampY(
        mouseWheelY,
        this.imageBounds,
        this.containerBounds,
        this.props.padding
      ),
    }));
  };

  containerRef = (ref: ?HTMLElement) => (this.container = ref);
  imageRef = (ref: ?HTMLImageElement) => (this.image = ref);

  render() {
    return (
      <div
        ref={this.containerRef}
        className={classnames(this.props.className, style.container, {
          [style.dragging]: this.state.dragging,
          [style.grabbing]: this.state.grabbing,
          [style.largerThanContainer]: this.isLargerThanContainer,
          [style.fullsize]: this.scale === 100,
          [style.smallerThanFullsize]: this.scale < 100,
          [style.largerThanFullsize]: this.scale > 100,
        })}
        tabIndex="0"
        data-qa={this.props.qaSelector}
      >
        <div className={style.positioner} style={this.positionerStyle}>
          <img
            ref={this.imageRef}
            src={this.props.src}
            onMouseDown={this.handleMouseDown}
            onTouchStart={this.handleMouseDown}
            onMouseUp={this.handleMouseUp}
            onTouchEnd={this.handleMouseUp}
            onLoad={this.handleLoad}
            style={this.imageStyle}
            alt={this.props.alt}
          />
        </div>
        <div className={style.positioner} style={this.positionerStyle}>
          {this.props.children &&
            !!this.scale &&
            this.props.children(this.scale, CHILDREN_STYLE)}
        </div>
        <ResizeDetector handleWidth handleHeight onResize={this.handleResize} />
      </div>
    );
  }
}
