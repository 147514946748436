// @flow
import { connect } from "react-redux";
import { isOnline } from "abstract-di/selectors";
import { BRANCH_ID_MASTER, defaultBranch } from "core/models/branch";
import { BranchFetchRequest } from "core/requests/branches";
import { FilesFetchRequest } from "core/requests/files";
import { getBranchHead } from "core/selectors/branches";
import { canUseNewDefaultBranchName } from "core/selectors/features";
import { getFilesForBranch } from "core/selectors/files";
import { getProject } from "core/selectors/projects";
import type { State, Dispatch } from "core/types";
import type { OwnProps, StateProps, Props } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { projectId } = props;
  const branchId = BRANCH_ID_MASTER;

  const branchHead = getBranchHead(state, { projectId, branchId });
  const project = getProject(state, { projectId });

  return {
    branchHead,
    defaultBranchName: defaultBranch({
      masterToMain: canUseNewDefaultBranchName(state),
      titleCase: true,
    }),
    isNewProject: !!project && project.isNew,
    files: getFilesForBranch(state, { projectId, branchId }),
    isLoadingStrict:
      BranchFetchRequest.isLoadingStrict(state, {
        projectId,
        branchId,
      }) ||
      FilesFetchRequest.isLoadingStrict(state, {
        projectId,
        branchId,
        sha: branchHead,
      }),
    isLoading:
      !branchHead ||
      FilesFetchRequest.isLoading(state, {
        projectId,
        branchId,
        sha: branchHead,
      }),
    isOffline: !isOnline(state),
  };
}

export default connect<Props, OwnProps, StateProps, _, State, Dispatch>(
  mapStateToProps
);
