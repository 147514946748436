// @flow
import * as React from "react";
import Icon from "core/components/Icon";
import { V3Link as Link } from "core/lib/router";
import { projectPath } from "core/lib/routes";
import ActivityBase from "./Base";
import style from "./base.scss";
import type {
  Props,
  ActivityProjectTransferred as TActivityProjectTransferred,
} from "./types";

export default function ActivityProjectTransferred(
  props: Props<TActivityProjectTransferred>
) {
  return (
    <ActivityBase
      {...props}
      icon="project"
      action="transferred the project"
      subject={
        <Link to={projectPath(props.activity.projectId)}>
          {props.activity.payload.projectName}
        </Link>
      }
      meta={
        <span>
          {props.activity.payload.previousOrganizationName}
          <Icon type="go-to" className={style.icon} />
          {props.activity.payload.newOrganizationName}
        </span>
      }
    />
  );
}
