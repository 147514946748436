// @flow
import classnames from "classnames";
import * as React from "react";
import Button from "core/components/Button";
import Flex from "core/components/Flex";
import Heading from "core/components/Heading";
import { V3Link as Link } from "core/lib/router";
import style from "./style.scss";

type Props = {
  title: string | React.Element<*>,
  onClose?: () => mixed,
  children?: React.Node,
  className?: string,
  linkTo?: string,
  linkText?: string,
  mobileTitle?: string,
  qaSelector?: string,
};

export default function MenuHeader(props: Props) {
  return (
    <Flex
      align="center"
      justify="space-between"
      className={classnames(style.header, props.className)}
    >
      {props.linkTo && (
        <Button
          nude
          tint
          component={Link}
          to={props.linkTo}
          icon="chevron-large-left"
          onClick={props.onClose}
          className={style.backButton}
          qaSelector={props.qaSelector}
        >
          {props.linkText}
        </Button>
      )}
      <Heading level="3" size="s" className={style.title}>
        {props.title}
      </Heading>
      {props.children}
      {!!props.onClose && (
        <Button
          nude
          tint
          icon="close"
          onClick={props.onClose}
          className={style.close}
        />
      )}
    </Flex>
  );
}
