// @flow
import * as React from "react";
import image from "./images/star.svg";
import Empty from ".";

export default function NoFavorites({
  canUseSections,
  ...rest
}: {
  canUseSections?: boolean,
}) {
  return (
    <Empty
      {...rest}
      image={image}
      title={"No Favorites"}
      description={`You can favorite any project${
        canUseSections ? " or section " : " "
      }to see it here.`}
    />
  );
}
