// @flow
import React, { Component } from "react";
import Button from "core/components/Button";
import Icon from "core/components/Icon";
import UserListItem from "core/components/UserListItem";
import type { User, Project } from "core/types";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  user: User,
  project: Project,
  onAddMember: (projectId: string, userId: string) => void,
  highlight?: string,
|};

export type StateProps = {|
  inProject: boolean,
  role: string,
  roleFromOrganization: boolean,
  roleFromTeam: boolean,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
};

type State = {|
  isLoading: boolean,
|};

class UserAddItem extends Component<Props, State> {
  state = { isLoading: false };

  handleAddMember = async () => {
    const { project, user } = this.props;
    this.setState({ isLoading: true });
    await this.props.onAddMember(project.id, user.id);
    this.setState({ isLoading: false });
  };

  render() {
    const {
      user,
      role,
      roleFromOrganization,
      roleFromTeam,
      inProject,
      highlight,
    } = this.props;
    let badge;
    if (role === "admin") {
      badge = roleFromOrganization || roleFromTeam ? "Admin" : "Project Admin";
    }

    return (
      <UserListItem
        component="div"
        id={user.id}
        name={user.name}
        username={user.username}
        avatarUrl={user.avatarUrl}
        badge={badge}
        className={style.user}
        responsive={false}
        highlight={highlight}
      >
        <span className={style.actions}>
          {inProject ? (
            <Icon type="checkmark" tint />
          ) : (
            <Button
              onClick={this.handleAddMember}
              disabled={this.state.isLoading}
              primary
            >
              Add
            </Button>
          )}
        </span>
      </UserListItem>
    );
  }
}

export default connector(UserAddItem);
