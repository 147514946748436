// @flow
import type { ChangesetChange } from "core/types";

export function colorProfileLabel(key: string): string {
  switch (key) {
    case "0":
      return "Unmanaged";
    case "1":
      return "sRGB IEC61966-2.1";
    case "2":
      return "Display P3";
    default:
      return "Unknown";
  }
}

const statuses = {
  added: "added",
  removed: "removed",
  deleted: "removed",
  edited: "edited",
  modified: "edited",
  changed: "edited",
  updated: "updated",
  none: "none",
  "edited-indirectly": "updated",
  "modified-indirectly": "updated",
};

export function status(change: string | ChangesetChange): string {
  const type = typeof change === "string" ? change : change.status;
  if (!type) {
    return "none";
  }
  return statuses[type] || "none";
}

export function isAdded(change: string | ChangesetChange): boolean {
  return status(change) === "added";
}

export function isRemoved(change: string | ChangesetChange): boolean {
  return status(change) === "removed";
}

export function isEdited(change: string | ChangesetChange): boolean {
  return status(change) === "edited";
}

export function isUpdated(change: string | ChangesetChange): boolean {
  return status(change) === "updated";
}

export function hasStatus(change: string | ChangesetChange): boolean {
  return status(change) !== "none";
}
