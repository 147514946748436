// @flow
import classnames from "classnames";
import * as React from "react";
import styleCss from "./style.scss";

export type Props = {
  children?: React.Node,
  className?: string,
  height?: string,
  width?: string,
  flex?: boolean,
  direction?: "row" | "column",
  style?: {},
};

function Centered({
  children,
  className,
  height,
  width,
  flex,
  direction,
  style = {},
  ...rest
}: Props) {
  return (
    <div
      {...rest}
      className={classnames(styleCss.centered, className, {
        [styleCss.flex]: flex,
      })}
      style={
        flex
          ? { flexDirection: direction, ...style }
          : { flexDirection: direction, width, height, ...style }
      }
    >
      {children}
    </div>
  );
}

Centered.defaultProps = { height: "100%", width: "100%" };

export default Centered;
