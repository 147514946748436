// @flow
import createCachedSelector from "@elasticprojects/re-reselect";
import { filter, find } from "lodash";
import { Abstract } from "core/lib/abstract";
import { getRawEntities } from "core/selectors/entities";
import {
  getTeamId,
  getUserId,
  getOrganizationId,
} from "core/selectors/helpers";
import { getTeamsForOrganization } from "core/selectors/teams";
import type {
  State,
  TeamMembership,
  TeamDescriptor,
  TeamMembershipDescriptor,
  TeamsOptions,
} from "core/types";

export function getTeamMembershipEntities(state: State): {
  [string]: TeamMembership,
} {
  return getRawEntities(state, "teamMemberships");
}

export const getTeamMembershipsForOrganization: (
  State,
  Abstract.OrganizationDescriptor & {
    ...TeamsOptions,
  }
) => TeamMembership[] = createCachedSelector(
  getTeamsForOrganization,
  getTeamMembershipEntities,
  (teams, teamMemberships) => {
    const teamIds = teams.map((team) => team.id);
    return filter(teamMemberships, (teamMembership) => {
      teamIds.includes(teamMembership.teamId);
    });
  }
)(getOrganizationId);

export const getTeamMembershipsForTeam: (
  State,
  { ...TeamDescriptor }
) => TeamMembership[] = createCachedSelector(
  getTeamMembershipEntities,
  getTeamId,
  (teamMemberships, teamId) => filter(teamMemberships, { teamId })
)(getTeamId);

export const getTeamMembershipsForUser: (
  State,
  { userId: string }
) => TeamMembership[] = createCachedSelector(
  getTeamMembershipEntities,
  getUserId,
  (teamMemberships, userId) => filter(teamMemberships, { userId })
)(getUserId);

function cacheTeamMember(
  state: State,
  params: { ...TeamDescriptor, userId: string }
) {
  return params.teamId + "-" + params.userId;
}

export const getTeamMembership: (
  State,
  TeamMembershipDescriptor
) => ?TeamMembership = createCachedSelector(
  getTeamMembershipEntities,
  getTeamId,
  getUserId,
  (teamMemberships, teamId, userId) => find(teamMemberships, { teamId, userId })
)(cacheTeamMember);
