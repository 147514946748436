// @flow
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import classnames from "classnames";
import * as React from "react";
import { Waypoint } from "react-waypoint";
import AccountDropdown from "core/components/AccountDropdown";
import Button from "core/components/Button";
import CollapsibleSidebar from "core/components/CollapsibleSidebar";
import Flex from "core/components/Flex";
import GlobalSidebarMenu from "core/components/GlobalSidebar/Menu";
import Notifications from "core/components/Notifications";
import OrganizationSwitcher from "core/components/OrganizationSwitcher";
import Scrollable from "core/components/Scrollable";
import Sidebar from "core/components/Sidebar";
import SidebarQuickJumpTeaser from "core/components/SidebarQuickJumpTeaser";
import SupportButton from "core/components/SupportButton";
import window from "core/global/window";
import { isDesktop, isWeb } from "core/lib/platform";
import type { Branch, Organization, Project } from "core/types";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  active?: boolean,
  currentBranch?: ?Branch,
  currentProject?: ?Project,
  mobile?: boolean,
  onClose?: () => void,
  organization: ?Organization,
  skipLinkDestination?: string,
|};

export type DispatchProps = {|
  onLoad: () => void,
|};

export type Props = {
  ...OwnProps,
  ...DispatchProps,
};

type State = {|
  isScrolled: boolean,
  menuIsOverflowing: boolean,
|};

export const SIDEBAR_STORAGE_KEY = "main-sidebar-collapsed";

class GlobalSidebar extends React.Component<Props, State> {
  state = {
    isScrolled: false,
    menuIsOverflowing: false,
  };

  scrollableRef: ?HTMLElement;

  componentDidUpdate(prevProps: Props) {
    const prevActive = prevProps.active && prevProps.mobile;
    const active = this.props.active && this.props.mobile;

    if (active && !prevActive) {
      if (this.scrollableRef) {
        disableBodyScroll(this.scrollableRef, {
          allowTouchMove: (element: HTMLElement) => {
            let el = element;
            while (el && el !== window.document.body) {
              if (el.hasAttribute("data-ignore-body-scroll-lock")) {
                return true;
              }

              el = el.parentElement;
            }
          },
        });
      }
    }

    if (!active && prevActive) {
      if (this.scrollableRef) {
        enableBodyScroll(this.scrollableRef);
      }
    }
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  setScrollableRef = (ref: ?HTMLElement) => {
    this.scrollableRef = ref;
  };

  handleMenuOverflowChange = (isOverflowing: boolean) => {
    this.setState({ menuIsOverflowing: isOverflowing });
  };

  render() {
    const sidebarContents = (
      <Flex className={style.wrapper} column>
        {this.props.skipLinkDestination && (
          <a
            className={classnames(style.skipNavLink, {
              [style.skipNavLinkDesktop]: isDesktop,
            })}
            href={this.props.skipLinkDestination}
          >
            Skip to content
          </a>
        )}
        <div
          className={classnames(style.topSectionWrapper, {
            [style.isScrolled]: this.state.isScrolled,
          })}
        >
          <Flex className={style.organizationWrapper} align="center">
            <Flex className={style.organizationSwitcherWrapper}>
              <OrganizationSwitcher
                className={style.organizationSwitcher}
                closeMobileSidebar={this.props.onClose}
                currentOrganization={this.props.organization}
              />
            </Flex>
            {this.props.mobile ? null : (
              <Notifications className={style.notificationsButton} />
            )}
            {this.props.onClose && this.props.mobile && (
              <Button
                className={style.closeButton}
                icon="close"
                onClick={this.props.onClose}
                title="Close"
                data-qa="closeSidebar"
                primary
              />
            )}
          </Flex>
          <SidebarQuickJumpTeaser className={style.quickJumpTeaser} />
        </div>
        <Scrollable innerRef={this.setScrollableRef} flex>
          <Waypoint
            onEnter={() => {
              this.setState({ isScrolled: false });
            }}
            onLeave={() => {
              this.setState({ isScrolled: true });
            }}
          />
          <GlobalSidebarMenu
            currentBranch={this.props.currentBranch}
            currentProject={this.props.currentProject}
            mobile={this.props.mobile}
            onClose={this.props.onClose}
            onMenuOverflowChange={this.handleMenuOverflowChange}
            organization={this.props.organization}
          />
        </Scrollable>
        {isDesktop ? null : (
          <Flex
            className={classnames(style.accountSection, {
              [style.menuIsOverflowing]: this.state.menuIsOverflowing,
            })}
            grow={false}
            shrink={false}
          >
            <Flex
              align="center"
              className={style.accountButtonsWrapper}
              justify="space-between"
            >
              <AccountDropdown
                closeMobileSidebar={this.props.onClose}
                currentOrganization={this.props.organization}
                mobile={this.props.mobile}
              />
              <SupportButton className={style.supportButton} />
            </Flex>
          </Flex>
        )}
      </Flex>
    );

    return isWeb && this.props.mobile ? (
      <Sidebar
        active={this.props.active && this.props.mobile}
        collapsed={!this.props.active && this.props.mobile}
        direction="left"
        maxWidth={400}
        minWidth={220}
        onClose={this.props.onClose}
        opaque={!isDesktop}
        resizable="main-sidebar"
        disableScroll
      >
        {sidebarContents}
      </Sidebar>
    ) : (
      <CollapsibleSidebar
        id="global"
        maxWidth={400}
        minWidth={220}
        opaque={!isDesktop}
      >
        {sidebarContents}
      </CollapsibleSidebar>
    );
  }
}

export default connector(GlobalSidebar);
