// @flow
import classnames from "classnames";
import * as React from "react";
import Button from "core/components/Button";
import Card from "core/components/Card";
import Offline from "core/components/Empty/Offline";
import Flex from "core/components/Flex";
import Loaded from "core/components/Loaded";
import PageTitle from "core/components/PageTitle";
import ProjectMenu from "core/components/ProjectMenu/Wrapper";
import About from "core/components/ProjectOverview/About";
import ActiveBranches from "core/components/ProjectOverview/ActiveBranches";
import DefaultBranchFiles from "core/components/ProjectOverview/DefaultBranchFiles";
import MyBranches from "core/components/ProjectOverview/MyBranches";
import RecentActivity from "core/components/ProjectOverview/RecentActivity";
import ReviewRequests from "core/components/ProjectOverview/ReviewRequests";
import ProjectStar from "core/components/ProjectStar";
import ProjectType from "core/components/ProjectType";
import { isDesktop } from "core/lib/platform";
import type { Project } from "core/types";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  addFileButton?: React.Node,
  emptyProject?: React.Node,
  isEmptyProject?: boolean,
  isLoading?: boolean,
  mobile?: boolean,
  newBranchButton?: React.Node,
  project: Project,
|};

export type StateProps = {|
  branchHead: string,
  hasBranchesToDisplay: boolean,
  hasFilesToDisplay: boolean,
  isBranchesLoading: boolean,
  isFilesLoading: boolean,
  isOffline: boolean,
|};

export type DispatchProps = {|
  loadBranches: () => void,
  loadFiles: () => void,
  loadMaster: () => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type State = {
  hasDisplayedBranchesOrFiles: boolean,
};

class ProjectOverview extends React.Component<Props, State> {
  state = {
    hasDisplayedBranchesOrFiles:
      this.props.hasBranchesToDisplay || this.props.hasFilesToDisplay,
  };

  componentDidMount() {
    this.props.loadBranches();

    if (this.props.branchHead) {
      this.props.loadFiles();
    } else {
      this.props.loadMaster();
    }
  }

  componentDidUpdate(prevProps: Props) {
    const hasDisplayedBranchesOrFiles =
      this.props.hasBranchesToDisplay || this.props.hasFilesToDisplay;
    if (
      (this.props.hasBranchesToDisplay !== prevProps.hasBranchesToDisplay ||
        this.props.hasFilesToDisplay !== prevProps.hasFilesToDisplay) &&
      hasDisplayedBranchesOrFiles
    ) {
      this.setState({ hasDisplayedBranchesOrFiles });
    }

    if (!this.props.isOffline && prevProps.isOffline) {
      return this.props.loadMaster();
    }

    if (this.props.branchHead !== prevProps.branchHead) {
      this.props.loadFiles();
    }
  }

  renderOfflineMessage = () => {
    return (
      <React.Fragment>
        <About project={this.props.project} />
        <Card>
          <Offline
            title="Project unavailable offline"
            description="Abstract only stores projects you’ve previously worked on for offline use. Reconnect to the internet to view this project’s files, branches, and activity."
          />
        </Card>
      </React.Fragment>
    );
  };

  renderMobileLayout = () => {
    return (
      <Flex column>
        <About project={this.props.project} mobile />
        <ReviewRequests projectId={this.props.project.id} mobile />
        <DefaultBranchFiles
          addFileButton={this.props.addFileButton}
          projectId={this.props.project.id}
          mobile
        />
        <MyBranches project={this.props.project} mobile />
        <ActiveBranches project={this.props.project} mobile />
        <RecentActivity project={this.props.project} mobile />
      </Flex>
    );
  };

  renderDesktopLayout = () => {
    return (
      <Flex>
        <Flex className={style.mainColumn} column>
          <About project={this.props.project} />
          <MyBranches project={this.props.project} />
          <ActiveBranches project={this.props.project} />
          <RecentActivity project={this.props.project} />
        </Flex>
        <Flex className={style.sidebar} grow={false} shrink={false} column>
          <ReviewRequests projectId={this.props.project.id} />
          <DefaultBranchFiles
            addFileButton={this.props.addFileButton}
            projectId={this.props.project.id}
          />
        </Flex>
      </Flex>
    );
  };

  renderContent = () => {
    if (
      this.props.isLoading ||
      ((this.props.isBranchesLoading || this.props.isFilesLoading) &&
        !this.state.hasDisplayedBranchesOrFiles)
    ) {
      return <Loaded title="Loading project…" loading />;
    }

    if (isDesktop && this.props.isEmptyProject && this.props.emptyProject) {
      return this.props.emptyProject;
    }

    if (
      this.props.isOffline &&
      isDesktop &&
      !this.props.hasBranchesToDisplay &&
      !this.props.hasFilesToDisplay
    ) {
      return this.renderOfflineMessage();
    }

    if (this.props.mobile) {
      return this.renderMobileLayout();
    }

    return this.renderDesktopLayout();
  };

  render() {
    return (
      <Flex
        className={classnames(style.wrapper, {
          [style.scrollableWrapper]: this.props.mobile,
        })}
        column
      >
        <PageTitle
          title={this.props.project.name}
          titleStatusBadge={
            <ProjectStar
              projectId={this.props.project.id}
              className={style.star}
            />
          }
          subtitle={
            <ProjectType
              className={style.projectType}
              project={this.props.project}
            />
          }
          actions={[
            this.props.newBranchButton,
            <ProjectMenu project={this.props.project} canMoveProject={true}>
              {(showMenu, renderMenuTarget) =>
                renderMenuTarget((ref, buttonProps) => {
                  return (
                    <Button
                      icon="overflow"
                      onClick={showMenu}
                      innerRef={ref}
                      nude
                      title="Open Project Menu"
                      {...buttonProps}
                    />
                  );
                })
              }
            </ProjectMenu>,
          ]}
        />

        <div
          className={classnames({
            [style.scrollableContent]: !this.props.mobile,
          })}
        >
          {this.renderContent()}
        </div>
      </Flex>
    );
  }
}

export default connector(ProjectOverview);
