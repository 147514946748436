// @flow
import classnames from "classnames";
import empty from "empty";
import * as React from "react";
import scrollIntoView from "smooth-scroll-into-view-if-needed";
import Button from "core/components/Button";
import DelayedMount from "core/components/DelayedMount";
import ExternalLink from "core/components/ExternalLink";
import Flex from "core/components/Flex";
import Icon from "core/components/Icon";
import type { KeyboardNavigationProps } from "core/components/KeyboardNavigation";
import PlaceholderText from "core/components/PlaceholderText";
import ProjectColor from "core/components/ProjectColor";
import Theme from "core/components/Theme";
import { buildUrlBase } from "core/lib/buildUrlBase";
import { isDesktop } from "core/lib/platform";
import { V3Link as Link, withRouter, type RouterProps } from "core/lib/router";
import { shallowCompareWithoutFunctions } from "core/lib/shallowComparison";
import type { ReactRouterLocation, LocationDescriptor } from "core/types";
import style from "./style.scss";

export function Placeholder() {
  return (
    <DelayedMount>
      <Flex align="center" className={style.menuItemPlaceholder}>
        <div className={style.iconPlaceholderWrapper}>
          <div className={style.iconPlaceholder} />
        </div>
        <PlaceholderText
          className={style.menuItemLabelPlaceholder}
          min={75}
          max={150}
          unit="px"
          size="m"
        />
      </Flex>
    </DelayedMount>
  );
}

export type OwnProps = {|
  actionButton?: ({ iconFill: ?string, light: boolean }) => React.Node,
  actionHover?: boolean,
  activeOnChildPaths?: boolean,
  activePaths?: LocationDescriptor[],
  children?: React.Node,
  className?: string,
  collapsed?: boolean,
  disabled?: boolean,
  expandOnActive?: boolean,
  external?: boolean,
  icon?: string | React.Element<typeof ProjectColor>,
  keyboardNavigationProps: KeyboardNavigationProps,
  label: React.Node | ((isActive: boolean) => React.Node),
  large?: boolean,
  mobile?: boolean,
  onClick?: (event: SyntheticEvent<>) => void,
  onContextMenu?: (event: SyntheticMouseEvent<>) => void,
  onToggleCollapsed?: (collapsed: boolean) => void,
  to?: string,
  qaSelector?: string,
  qaSelectorWrapper?: string,
|};

type Props = {|
  ...OwnProps,
  ...RouterProps,
|};

type State = {|
  isFocused: boolean,
|};

class GlobalSidebarMenuItem extends React.Component<Props, State> {
  static defaultProps = {
    collapsed: false,
    keyboardNavigationProps: {
      disclosureClassName: "",
      isFocused: false,
      selectedClassName: "",
      scrollRef: empty.func,
    },
  };

  state = {
    isFocused: false,
  };

  node = React.createRef();

  shouldComponentUpdate(nextProps: Props, nextState: State): boolean {
    return shallowCompareWithoutFunctions(this, nextProps, nextState);
  }

  componentDidMount() {
    if (this.getIsActive(this.props)) {
      this.scrollIntoView();

      if (this.props.expandOnActive && this.props.onToggleCollapsed) {
        this.props.onToggleCollapsed(false);
      }
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.getIsActive(this.props) && !this.getIsActive(prevProps)) {
      this.scrollIntoView();
    }
  }

  isPathActive = (
    currentLocation: ReactRouterLocation,
    path: ?string
  ): boolean => {
    if (this.props.external) {
      return false;
    }

    if (!path) {
      return false;
    }

    const pathToCheck = new URL(path, buildUrlBase()).pathname;
    const currentPath = currentLocation.pathname.startsWith("/")
      ? currentLocation.pathname
      : `/${currentLocation.pathname}`;

    if (this.props.activeOnChildPaths) {
      return currentPath.startsWith(pathToCheck);
    }

    return currentPath === pathToCheck;
  };

  getIsActive = (props: Props): boolean => {
    const { activePaths = [] } = props;

    return (
      (this.isPathActive(props.location, props.to) ||
        activePaths.some((descriptor) =>
          this.isPathActive(
            props.location,
            typeof descriptor === "string" ? descriptor : descriptor.pathname
          )
        )) &&
      !props.disabled
    );
  };

  scrollIntoView = () => {
    if (this.node.current) {
      scrollIntoView(this.node.current, {
        scrollMode: "if-needed",
        behavior: "instant",
      });
    }
  };

  handleClick = (event: SyntheticMouseEvent<>) => {
    if (this.props.expandOnActive && this.props.onToggleCollapsed) {
      this.props.onToggleCollapsed(false);
    }

    if (this.props.onClick) {
      this.props.onClick(event);
    }
  };

  render() {
    const isActive = this.getIsActive(this.props);
    const isLight = isActive && this.props.keyboardNavigationProps.isFocused;

    const Component = this.props.disabled
      ? "div"
      : this.props.external
      ? ExternalLink
      : Link;

    return (
      <Theme.Consumer>
        {({ themeName }) => {
          const iconFill =
            isDesktop && themeName === "light" ? "rgba(0,0,0,.5)" : undefined;

          const refProp =
            Component === "div" ? { ref: this.node } : { innerRef: this.node };

          return (
            <div data-qa={this.props.qaSelectorWrapper}>
              <Component
                className={classnames(
                  style.menuItem,
                  {
                    [style.selected]: isActive,
                    [style.focused]: this.state.isFocused,
                    [style.menuFocused]:
                      this.props.keyboardNavigationProps.isFocused,
                    [style.large]: this.props.large,
                    [style.hasDisclosure]: this.props.onToggleCollapsed,
                    [style.actionHover]:
                      this.props.actionHover || this.props.external,
                    [style.mobile]: this.props.mobile,
                    [style.disabled]: this.props.disabled,
                    [this.props.keyboardNavigationProps.selectedClassName]:
                      isActive,
                    [style.menuItemWithIcon]: this.props.icon,
                  },
                  this.props.className
                )}
                data-qa={this.props.qaSelector}
                {...refProp}
                {...(this.props.disabled
                  ? undefined
                  : {
                      onBlur: () => this.setState({ isFocused: false }),
                      onClick: this.handleClick,
                      onContextMenu: this.props.onContextMenu,
                      onFocus: () => this.setState({ isFocused: true }),
                      to: this.props.external ? undefined : this.props.to,
                      href: this.props.external ? this.props.to : undefined,
                    })}
              >
                {this.props.icon && typeof this.props.icon === "string" && (
                  <Icon
                    className={style.icon}
                    fill={iconFill}
                    light={isLight}
                    type={this.props.icon}
                    inheritFill
                  />
                )}
                {this.props.icon && typeof this.props.icon !== "string" && (
                  <Flex
                    align="center"
                    className={style.icon}
                    grow={false}
                    justify="center"
                  >
                    {React.cloneElement(this.props.icon, {
                      selected: isActive,
                    })}
                  </Flex>
                )}
                <Flex align="center" className={style.labelWrapper}>
                  <div className={style.label}>
                    {typeof this.props.label === "function"
                      ? this.props.label(isLight)
                      : this.props.label}
                  </div>
                  {this.props.onToggleCollapsed ? (
                    <Button
                      className={classnames(
                        style.disclosure,
                        this.props.keyboardNavigationProps.disclosureClassName,
                        {
                          [style.collapsed]: this.props.collapsed,
                        }
                      )}
                      icon="disclosure-expanded"
                      iconFill={iconFill}
                      light={isLight}
                      onClick={(event: SyntheticInputEvent<>) => {
                        event.preventDefault();
                        event.stopPropagation();
                        if (this.props.onToggleCollapsed) {
                          this.props.onToggleCollapsed(!this.props.collapsed);
                        }
                      }}
                      title={`${
                        this.props.collapsed ? "Expand" : "Collapse"
                      } Project navigation`}
                      qaSelector={
                        this.props.qaSelector
                          ? `${this.props.qaSelector}DisclosureButton`
                          : undefined
                      }
                      nude
                    />
                  ) : null}
                </Flex>
                {(this.props.actionButton || this.props.external) && (
                  <Flex className={style.actionButton} justify="flex-end">
                    {!!this.props.actionButton &&
                      this.props.actionButton({ iconFill, light: isLight })}
                    {this.props.external && <Icon type="external" />}
                  </Flex>
                )}
              </Component>
              {this.props.children}
            </div>
          );
        }}
      </Theme.Consumer>
    );
  }
}

export default withRouter<OwnProps, GlobalSidebarMenuItem>(
  GlobalSidebarMenuItem
);
