// @flow
import * as React from "react";
import style from "./style.scss";

type Props = {
  children: React.Node,
  component: "ul" | "div",
};

function FilesList({ children, component }: Props) {
  const Component = component;
  return <Component className={style.files}>{children}</Component>;
}

FilesList.defaultProps = { component: "ul" };
export default FilesList;
