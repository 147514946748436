// @flow
import classnames from "classnames";
import * as React from "react";
import Button from "core/components/Button";
import Flex from "core/components/Flex";
import Media from "core/components/Media";
import OnboardingContentSheet from "core/components/OnboardingContentSheet";
import OnboardingHeading from "core/components/OnboardingHeading";
import SerifText from "core/components/SerifText";
import onboardingStyle from "core/components/onboarding.scss";
import { V3Link as Link } from "core/lib/router";
import { rootPath } from "core/lib/routes";
import OnboardingPage from "web/components/Onboarding/Page";
import * as Routes from "web/routeHelpers";
import style from "./style.scss";

export default function CreateOrJoinOrganization() {
  return (
    <Media mobile>
      {(mobile) => (
        <OnboardingPage currentStep={2} totalSteps={4}>
          <Flex align="center" className={style.content} column>
            <div
              className={classnames(
                style.topCopy,
                onboardingStyle.centeredText
              )}
            >
              <OnboardingHeading level="1">
                Create or join <SerifText>an Organization</SerifText>
              </OnboardingHeading>
              <div
                className={classnames(
                  onboardingStyle.subHeading,
                  onboardingStyle.largeCopy
                )}
              >
                In Abstract, everything happens in an Organization. This is
                where your team gathers to communicate and get work done.
              </div>
              <div
                className={classnames(
                  style.skipLink,
                  onboardingStyle.largeCopy
                )}
              >
                <Link className={onboardingStyle.link} to={rootPath()}>
                  Skip this step for now
                </Link>
              </div>
            </div>
            <Flex className={style.options} column={mobile}>
              <OnboardingContentSheet
                className={style.joinSheet}
                orientation={mobile ? "horizontal" : "vertical"}
                variant="kelp"
              >
                <Flex align="center" column>
                  <div
                    className={classnames(style.illustration, style.puzzle)}
                  />
                  <OnboardingHeading className={style.optionHeading} level="2">
                    Join an <SerifText>existing Organization</SerifText>
                  </OnboardingHeading>
                  <div
                    className={classnames(
                      style.sheetCopy,
                      onboardingStyle.largeCopy
                    )}
                  >
                    Confirm your email address and request access to your team’s
                    Abstract Organization.
                  </div>
                  <Button
                    className={classnames(style.button, onboardingStyle.button)}
                    component={Link}
                    to={{
                      pathname: Routes.signupConfirmEmail(),
                      state: { onboarding: true },
                    }}
                    large
                    primary
                  >
                    Join
                  </Button>
                </Flex>
              </OnboardingContentSheet>
              <OnboardingContentSheet
                className={style.createSheet}
                orientation={mobile ? "horizontal" : "vertical"}
              >
                <Flex align="center" column>
                  <div className={classnames(style.illustration, style.plus)} />
                  <OnboardingHeading className={style.optionHeading} level="2">
                    Create a <SerifText>new Organization</SerifText>
                  </OnboardingHeading>{" "}
                  <div
                    className={classnames(
                      style.sheetCopy,
                      onboardingStyle.largeCopy
                    )}
                  >
                    Explore Abstract. Create a new collaborative workspace for
                    your team or company.
                  </div>
                  <Button
                    className={classnames(style.button, onboardingStyle.button)}
                    component={Link}
                    to={{
                      pathname: Routes.signupCreateOrganization(),
                      state: { onboarding: true },
                    }}
                    large
                    primary
                  >
                    Create new
                  </Button>
                  <span className={style.trialBadge}>14-day trial</span>
                </Flex>
              </OnboardingContentSheet>
            </Flex>
          </Flex>
        </OnboardingPage>
      )}
    </Media>
  );
}
