// @flow
import invariant from "invariant";
import window from "core/global/window";
import { Abstract } from "core/lib/abstract";
import { push, replace } from "core/lib/location";
import { getCurrentV3Location as getCurrentLocation } from "core/lib/router";
import { layerLocation } from "core/lib/routes";
import { TargetLayerRequest } from "core/requests/prototypes";
import { getBranchHead } from "core/selectors/branches";
import { getFeatureEnabled } from "core/selectors/features";
import { getLayer } from "core/selectors/layers";
import { getOrganizationForProject } from "core/selectors/organizations";
import { getTargetSha } from "core/selectors/prototypes";
import type {
  ThunkAction,
  LayerLink,
  BranchCollectionDescriptor,
  TargetLinkOptions,
} from "core/types";

export function goToTargetLayer(
  descriptor: Abstract.LayerVersionDescriptor | BranchCollectionDescriptor,
  link: LayerLink,
  options: TargetLinkOptions
): ThunkAction {
  return async (dispatch, getState) => {
    await dispatch(
      TargetLayerRequest.perform({
        params: { descriptor, link, options },
        force: false,
      })
    );
    const state = getState();
    const location = getCurrentLocation();

    if (link.previous) {
      if (location.state && location.state.previousPrototypeLocation) {
        replace(location.state.previousPrototypeLocation);
      } else {
        window.alert("Previous prototype unknown");
      }
      return;
    }

    const target = link.target || undefined;

    const targetSha = getTargetSha(state, descriptor, link, options);
    invariant(targetSha, "onClickTarget called without target loaded");

    const head = getBranchHead(state, descriptor);

    const layer =
      descriptor.layerId && getLayer(state, { ...descriptor, sha: head });

    const targetLayerDescriptor = { ...target, sha: targetSha };

    const organization = getOrganizationForProject(state, {
      projectId: descriptor.projectId,
    });

    const isLatestCommitFeatureEnabled = organization
      ? getFeatureEnabled(state, {
          organizationId: organization.id,
          feature: "latest-commit-enabled",
        })
      : false;

    const targetLayer = getLayer(state, targetLayerDescriptor);
    invariant(targetLayer, "Target layer should be loaded");
    const latest =
      descriptor.sha &&
      layer &&
      (descriptor.sha === layer.lastChangedAtSha ||
        descriptor.sha === "latest");

    push(
      layerLocation(
        targetLayerDescriptor.projectId,
        targetLayerDescriptor.branchId,
        descriptor.sha === "latest" && isLatestCommitFeatureEnabled
          ? "latest"
          : targetLayer.lastChangedAtSha,
        targetLayerDescriptor.fileId,
        targetLayerDescriptor.layerId,
        {
          ...location.query,
          ...location.state,
          layerSetParams: undefined,
          previousPrototypeLocation: location,
          sha: !latest ? targetSha : undefined,
        }
      )
    );
  };
}
