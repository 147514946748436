// @flow
import React, { Component } from "react";
import CreateCommit from "abstract-di/components/CreateCommit";
import UpdateBranch from "abstract-di/components/UpdateBranch";
import AppNotInstalled from "./AppNotInstalled";
import CleanupBranch from "./CleanupBranch";
import ConfirmAddLibrary from "./ConfirmAddLibrary";
import ConfirmCreateDivergedBranch from "./ConfirmCreateDivergedBranch";
import ConfirmDeleteBranch from "./ConfirmDeleteBranch";
import ConfirmDeleteFile from "./ConfirmDeleteFile";
import ConfirmExportAllActiveProjects from "./ConfirmExportAllActiveProjects";
import ConfirmMerge from "./ConfirmMerge";
import ConfirmRemoveLibrary from "./ConfirmRemoveLibrary";
import ConfirmRestoreCommit from "./ConfirmRestoreCommit";
import ConfirmRestoreFile from "./ConfirmRestoreFile";
import ConfirmStopSyncing from "./ConfirmStopSyncing";
import CreateBranch from "./CreateBranch";
import CreateFile from "./CreateFile";
import CreateProject from "./CreateProject";
import DiscardChanges from "./DiscardChanges";
import EditBranch from "./EditBranch";
import ImportComplete from "./ImportComplete";
import ImportFiles from "./ImportFiles";
import LibraryPicker from "./LibraryPicker";
import ManageLibraries from "./ManageLibraries";
import MissingLibraries from "./MissingLibraries";
import OpenFile from "./OpenFile";
import RenameFile from "./RenameFile";
import RequestContributorRole from "./RequestContributorRole";
import UncommittedChanges from "./UncommittedChanges";
import connector from "./connector";

export type StateProps = {|
  visibleDialogName: string,
  visibleDialogProps: Object,
|};

export type Props = {
  ...StateProps,
};

const dialogs = {
  CleanupBranch,
  ConfirmAddLibrary,
  ConfirmCreateDivergedBranch,
  ConfirmDeleteBranch,
  ConfirmDeleteFile,
  ConfirmExportAllActiveProjects,
  ConfirmMerge,
  ConfirmRemoveLibrary,
  ConfirmRestoreCommit,
  ConfirmRestoreFile,
  ConfirmStopSyncing,
  CreateBranch,
  CreateFile,
  CreateProject,
  DiscardChanges,
  EditBranch,
  ImportComplete,
  ImportFiles,
  LibraryPicker,
  ManageLibraries,
  MissingLibraries,
  OpenFile,
  RenameFile,
  RequestContributorRole,
  AppNotInstalled,
  UncommittedChanges,
  CreateCommit,
  UpdateBranch,
};

class Dialog extends Component<Props> {
  render() {
    if (!this.props.visibleDialogName) {
      return null;
    }
    const DialogClass = dialogs[this.props.visibleDialogName];
    return <DialogClass {...this.props.visibleDialogProps} />;
  }
}

export default connector(Dialog);
