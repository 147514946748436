// @flow
import * as React from "react";
import Dropzone from "react-dropzone";
import Centered from "../Centered";
import Loading from "../Loading";
import loadFile from "./loadFile";
import style from "./style.scss";

type Props = {
  onChange: (imageDataUrl: string, fileType: string) => void,
  type: "avatar" | "organizationLogo",
};

type State = {
  dataUrl: string,
  error: string,
  isLoading: boolean,
};

export default class ImagePicker extends React.Component<Props, State> {
  state = { dataUrl: "", error: "", isLoading: false };

  handleDrop = async (files: Array<File>) => {
    const file = files[0];
    if (!file) {
      return;
    }

    this.setState({ isLoading: true });

    const [dataUrl, error] = await loadFile(file);
    this.setState({ dataUrl, error, isLoading: false });
    if (dataUrl && this.props.onChange) {
      this.props.onChange(dataUrl, file.type);
    }
  };

  render() {
    return (
      <div className={style.wrapper}>
        {this.state.isLoading && (
          <Centered className={style.loading}>
            <Loading title="Loading your file…" />
          </Centered>
        )}
        {!this.state.isLoading && (
          <Dropzone
            className={style.dropzone}
            activeClassName={style.dropzoneActive}
            multiple={false}
            onDrop={this.handleDrop}
          >
            <div className={style.dropzoneLabel}>
              <span className={style.dropzoneLabelDesktop}>
                Drop your new
                {this.props.type === "avatar" ? " avatar " : " logo "}
                here, or{" "}
                <span className={style.dropzoneLink}>select a file</span> from
                your computer.{" "}
              </span>
              <span className={style.dropzoneLabelMobile}>
                Tap to select a file from your device.
              </span>
            </div>
            <div className={style.dropzoneHelper}>
              JPG or PNG, at least 256px
            </div>
            {this.state.error && (
              <div className={style.dropzoneError}>{this.state.error}</div>
            )}
          </Dropzone>
        )}
      </div>
    );
  }
}
