// @flow
import classnames from "classnames";
import * as React from "react";
import scrollIntoView from "smooth-scroll-into-view-if-needed";
import Flex from "core/components/Flex";
import Icon from "core/components/Icon";
import { V3Link as Link } from "core/lib/router";
import style from "./style.scss";

export type CommonProps = {|
  selected: boolean,
  onClick: () => void,
  id: string,
  "aria-selected": string,
|};

export type Props = {
  ...CommonProps,
  icon: React.Node,
  iconColor?: string,
  title: React.Node,
  context: React.Node,
  to: string,
};

export function Placeholder({ width }: { width: string }) {
  return (
    <div
      className={classnames(style.result, style.placeholderResult)}
      style={{ width }}
    >
      <Flex
        align="center"
        justify="space-between"
        className={style.resultContent}
      >
        <div
          className={classnames(
            style.resultIcon,
            style.placeholderIcon,
            style.withShimmer
          )}
        >
          &nbsp;
        </div>
        <div
          className={classnames(
            style.resultTitle,
            style.placeholderTitle,
            style.withShimmer
          )}
        />
      </Flex>
    </div>
  );
}

export default function Result({
  icon,
  iconColor,
  title,
  context,
  selected,
  onClick,
  to,
  ...rest
}: Props) {
  const ref = React.useCallback(
    (node) => {
      if (selected && node) {
        scrollIntoView(node, {
          scrollMode: "always",
          behavior: "instant",
        });
      }
    },
    [selected]
  );

  return (
    <Link
      to={to}
      onClick={onClick}
      className={classnames(style.result, {
        [style.selected]: selected,
      })}
      role="option"
      {...rest}
    >
      <Flex
        align="center"
        justify="space-between"
        className={style.resultContent}
        innerRef={ref}
      >
        <Flex align="center" className={style.resultContentInner}>
          <Flex align="center" justify="center" className={style.resultIcon}>
            {typeof icon === "string" ? (
              <Icon type={icon} fill={selected ? "currentColor" : iconColor} />
            ) : (
              icon
            )}
          </Flex>

          <div className={style.resultTitle}>{title}</div>
        </Flex>
        <div className={style.resultContext}>{context}</div>
      </Flex>
    </Link>
  );
}
