// @flow
import { static as Immutable } from "seamless-immutable";
import type { Action } from "../actions";

type State = { open: boolean };

const DEFAULT_STATE = Immutable.from({ open: false });

export default function (state: State = DEFAULT_STATE, action: Action): State {
  switch (action.type) {
    case "SIDEBAR_OPEN":
      return Immutable.set(state, "open", true);
    case "SIDEBAR_CLOSE":
      return Immutable.set(state, "open", false);
    default:
      return state;
  }
}
