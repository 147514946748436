// @flow
import classnames from "classnames";
import { capitalize } from "lodash";
import * as React from "react";
import Button from "core/components/Button";
import FileIcon, {
  Placeholder as PlaceholderFileIcon,
} from "core/components/FileIcon";
import FileMenu from "core/components/FileMenu";
import FileName from "core/components/FileName";
import Flex from "core/components/Flex";
import PlaceholderText from "core/components/PlaceholderText";
import { isDesktop } from "core/lib/platform";
import { V3Link as Link } from "core/lib/router";
import { filePath } from "core/lib/routes";
import * as File from "core/models/file";
import type { File as TFile } from "core/types";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  isSelected?: boolean,
  isFocused?: boolean,
  mobile?: boolean,
  small?: boolean,
  style?: Object,
  selectedClassName?: string,
  itemClassName?: string,
  file: TFile,
  branchId: string,
  isOpening?: boolean,
  onClick?: () => void,
  onOpenFile?: () => void,
  editOnClick?: boolean,
  dark?: boolean,
  style?: any,
|};

export type DispatchProps = {|
  editFile: () => void,
|};

export type Props = {
  ...OwnProps,
  ...DispatchProps,
};

export function Placeholder({
  className,
  small,
  mobile,
  ...rest
}: {
  mobile?: boolean,
  small?: boolean,
  className?: string,
}) {
  const classes = classnames(style.wrapper, style.placeholder, className, {
    [style.small]: small,
    [style.mobile]: mobile,
  });

  return (
    <div className={classes} {...rest}>
      <Flex justify="center" className={style.content}>
        <PlaceholderFileIcon large />
        <Flex justify="center" className={style.content} column>
          <PlaceholderText />
          <PlaceholderText size="s" min={25} max={50} />
        </Flex>
      </Flex>
    </div>
  );
}

class FileItemComponent extends React.Component<Props> {
  handleClick = (event: SyntheticEvent<>) => {
    event.preventDefault();

    if (this.props.onClick && isDesktop) {
      this.props.onClick();
    }

    if (this.props.isOpening) {
      return;
    }

    if (this.props.editOnClick) {
      this.props.editFile();
    }
  };

  handleDoubleClick = (event: SyntheticEvent<>) => {
    event.preventDefault();

    if (this.props.isOpening || this.props.editOnClick) {
      return;
    }

    this.props.editFile();
  };

  getFilePath = () => {
    const { branchId, file } = this.props;
    const { projectId, id } = file;
    return filePath(projectId, branchId, id);
  };

  getFileAppName = () => {
    const { file } = this.props;
    return File.appName(file, capitalize(file.type));
  };

  getMeta = () => {
    const { file } = this.props;
    const fileAppName = this.getFileAppName();
    return `${fileAppName} ${file.isLibrary ? "Library" : "File"}`;
  };

  getIcon = () => {
    if (this.props.isOpening) {
      return "spinner";
    }

    if (!this.props.editOnClick) {
      return "go-to";
    }

    return undefined;
  };

  render() {
    const {
      dark,
      editOnClick,
      file,
      isFocused,
      isOpening,
      isSelected,
      itemClassName,
      mobile,
      onClick,
      selectedClassName,
      small,
    } = this.props;

    const selectedClass = selectedClassName || style.selected;

    const classes = classnames(style.file, itemClassName, {
      [style.small]: small,
      [selectedClass]: isSelected,
      [style.selected]: isSelected,
      [style.focused]: isFocused,
      [style.mobile]: mobile,
    });

    const isLight = isSelected && isFocused;
    const icon = this.getIcon();

    const hasClickHandler = onClick || editOnClick;
    const Component = hasClickHandler ? "div" : Link;

    return (
      <FileMenu
        projectId={file.projectId}
        branchId={this.props.branchId}
        file={file}
      >
        {(showMenu, renderMenuTarget) => {
          return renderMenuTarget((ref, buttonProps) => {
            return (
              <Component
                className={classes}
                onClick={hasClickHandler ? this.handleClick : undefined}
                onContextMenu={showMenu}
                style={this.props.style}
                to={hasClickHandler ? undefined : this.getFilePath()}
                {...buttonProps}
              >
                <div
                  className={classnames(style.wrapper, { [style.dark]: dark })}
                  onDoubleClick={
                    !this.props.editOnClick ? this.handleDoubleClick : undefined
                  }
                  ref={ref}
                >
                  <FileIcon
                    type={file.type}
                    isLibrary={file.isLibrary}
                    large={!small}
                  />
                  <Flex
                    align="flex-start"
                    justify="center"
                    className={style.content}
                    column
                  >
                    <FileName
                      name={file.name}
                      type={file.type}
                      fileClassName={style.name}
                      extensionClassName={style.meta}
                      icon={null}
                    />
                    {!small &&
                      (editOnClick ? (
                        <Button nude tint>
                          Edit in {this.getFileAppName()}
                        </Button>
                      ) : (
                        <p className={style.meta}>{this.getMeta()}</p>
                      ))}
                  </Flex>
                  {icon && (
                    <Flex align="center" justify="flex-end">
                      <Button
                        component={hasClickHandler ? Link : "span"}
                        className={style.goto}
                        to={!isOpening ? this.getFilePath() : undefined}
                        icon={icon}
                        light={isLight}
                        disabled={isOpening}
                        nude
                      />
                    </Flex>
                  )}
                </div>
              </Component>
            );
          });
        }}
      </FileMenu>
    );
  }
}

export const FileItem = connector(FileItemComponent);
