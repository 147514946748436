// @flow
import { static as Immutable } from "seamless-immutable";
import type { Action, AppState } from "core/types";

export const DEFAULT_STATE = Immutable.from({});

export default function (
  state: AppState = DEFAULT_STATE,
  action: Action
): AppState {
  switch (action.type) {
    case "core/SHORTCUT_VIEWER_TOGGLED": {
      return Immutable.set(
        state,
        "shortcutViewerOpen",
        !state.shortcutViewerOpen
      );
    }
    case "DIALOG_OPEN": {
      return Immutable.merge(state, {
        visibleDialogName: action.payload.name,
        visibleDialogProps: action.payload.props,
      });
    }
    case "DIALOG_CLOSE": {
      return Immutable.without(state, [
        "visibleDialogName",
        "visibleDialogProps",
      ]);
    }
    default:
      return state;
  }
}
