// @flow
import * as React from "react";
import AnimatedFlyover from "core/components/AnimatedFlyover";
import ReviewTitleBar from "core/components/BranchReviewForm/ReviewTitleBar";
import Button from "core/components/Button";
import DialogForm from "core/components/DialogForm";
import Flex from "core/components/Flex";
import Header from "core/components/Header";
import Icon from "core/components/Icon";
import InputRadio from "core/components/InputRadio";
import InputRich from "core/components/InputRich";
import MarkdownHelp from "core/components/MarkdownHelp";
import Popover from "core/components/Popover";
import RadioGroup from "core/components/RadioGroup";
import {
  ReviewStatuses,
  type ReviewStatus,
  reviewStatusIcon,
} from "core/models/review";
import type { Branch, ReviewRequest, User } from "core/types";
import Validations from "core/validations";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  branch: Branch,
  isMobile?: boolean,
  reviewButtonClassName?: string,
  projectId: string,
|};

export type StateProps = {|
  approvalRequired: boolean,
  canReview: boolean,
  currentReviewRequest: ?ReviewRequest,
  currentUser: ?User,
  hasError: boolean,
  isOffline: boolean,
  isSaving: boolean,
  defaultBranchName: string,
  branchOwnerMembershipLoaded: boolean,
|};

export type DispatchProps = {|
  submitReview: (
    {
      body: string,
      id: string,
    },
    status: ReviewStatus | "",
    currentUser: User,
    currentReviewRequest: ?ReviewRequest
  ) => void,
  loadBranchOwnerMembership: () => void,
|};

export type StorageProps = {|
  onChange: ({ body: string }) => void,
  defaultBody: string,
  clearSavedReview: () => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
  ...StorageProps,
};

type State = {
  status: ReviewStatus | "",
  body: string,
  showReviewForm: boolean,
};

class BranchReviewForm extends React.Component<Props, State> {
  state = {
    status: "",
    body: this.props.defaultBody,
    showReviewForm: false,
  };

  getFormId(): string {
    const { branch, projectId } = this.props;
    return branch ? `branch-review-${projectId}-${branch.id}` : "";
  }

  getPlaceholder(): string {
    return "Leave your remarks here…";
  }

  getError(): ?string {
    if (this.props.hasError) {
      return "Sorry, an error occurred while saving. Try again?";
    }
  }

  changeReviewStatus = (ev: SyntheticInputEvent<*>) => {
    const status = ev.target.value;
    switch (status) {
      case ReviewStatuses.APPROVED:
        this.setState({ status: ReviewStatuses.APPROVED });
        break;
      case ReviewStatuses.REJECTED:
        this.setState({ status: ReviewStatuses.REJECTED });
        break;
      default:
        this.setState({ status: "" });
    }
  };

  handleInputChange = (body: string) => {
    this.setState({ body }, () => {
      this.props.onChange({ body: this.state.body });
    });
  };

  handleFormSubmit = async (event?: SyntheticEvent<>) => {
    const { body, status } = this.state;
    const { currentUser, submitReview, currentReviewRequest } = this.props;

    if (!currentUser) {
      return;
    }

    if (event) {
      event.preventDefault();
    }

    await submitReview(
      { body, id: this.getFormId() },
      status,
      currentUser,
      currentReviewRequest
    );

    if (!this.props.hasError) {
      this.resetForm();
      this.setState({ body: "" });
      this.props.clearSavedReview();
    }
  };

  renderSubmitButton = () => {
    const { isMobile, isOffline, isSaving } = this.props;
    return (
      <Button
        fullwidth={isMobile}
        large={isMobile}
        disabled={!this.state.status || isOffline || isSaving}
        allgood={this.state.status === ReviewStatuses.APPROVED}
        warning={this.state.status === ReviewStatuses.REJECTED}
        onClick={this.handleFormSubmit}
      >
        {this.renderButtonText()}
      </Button>
    );
  };

  renderButtonText() {
    switch (this.state.status) {
      case ReviewStatuses.APPROVED:
        return "Approve Branch";
      case ReviewStatuses.REJECTED:
        return "Request Changes";
      default:
        return "Leave a Review…";
    }
  }

  resetForm = () => {
    this.setState({ status: "", showReviewForm: false });
  };

  renderRadioOption(text: string, icon: ?string, value: ReviewStatus) {
    return (
      <InputRadio
        id={value}
        label={
          <Flex align="center">
            {text}
            {icon && <Icon type={icon} className={style.radioItemIcon} />}
          </Flex>
        }
        labelClass={style.radioLabel}
        value={value}
      />
    );
  }

  toggleForm = (event: SyntheticMouseEvent<>) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      showReviewForm: !this.state.showReviewForm,
    });
  };

  closeForm = (event?: SyntheticEvent<>) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.resetForm();
  };

  renderReviewForm() {
    const {
      branch,
      isMobile,
      currentReviewRequest,
      approvalRequired,
      defaultBranchName,
    } = this.props;
    const branchAuthor = branch ? branch.userName : "";
    const { body, status } = this.state;
    const Component = isMobile ? "div" : "form";

    const description = approvalRequired
      ? `When you approve the changes, they can merge to ${defaultBranchName}. Request changes to share feedback.`
      : `${branchAuthor} has requested ${
          currentReviewRequest ? "your" : ""
        } feedback on this branch.`;

    return (
      <Component
        id={this.getFormId()}
        className={style.formContainer}
        onSubmit={this.handleFormSubmit}
      >
        <div>{description}</div>
        <RadioGroup
          name="reviewStatus"
          value={status}
          onChange={this.changeReviewStatus}
          className={style.radioGroup}
        >
          {this.renderRadioOption(
            "Approve changes",
            reviewStatusIcon(ReviewStatuses.APPROVED),
            ReviewStatuses.APPROVED
          )}
          {this.renderRadioOption(
            "Request changes",
            reviewStatusIcon(ReviewStatuses.REJECTED),
            ReviewStatuses.REJECTED
          )}
        </RadioGroup>

        <InputRich
          projectId={this.props.projectId}
          disabled={false}
          placeholder={this.getPlaceholder()}
          value={body}
          onSubmit={this.handleFormSubmit}
          onChange={this.handleInputChange}
          error={this.getError()}
          minHeight={172}
          maxHeight={172}
          minLength={Validations.minCommentLength}
          maxLength={Validations.maxCommentLength}
        />
        {!isMobile && (
          <Header
            left={
              <Popover trigger="click" placement="top" body={<MarkdownHelp />}>
                <Button icon="markdown" type="button" nude />
              </Popover>
            }
            right={this.renderSubmitButton()}
          />
        )}
      </Component>
    );
  }

  renderReviewButton = () => {
    const { isMobile, isOffline, currentReviewRequest } = this.props;
    const buttonText =
      currentReviewRequest &&
      currentReviewRequest.status !== ReviewStatuses.REQUESTED
        ? "Review Again"
        : "Review";

    return (
      <Popover
        placement="left"
        trigger="hover"
        disclosure={!isMobile}
        disabled={!isOffline}
        label="Offline. You must be online to leave a review. Check your network connection"
      >
        <Button
          allgood
          disclosure
          className={this.props.reviewButtonClassName}
          disabled={isOffline}
          onClick={this.toggleForm}
        >
          {buttonText}
        </Button>
      </Popover>
    );
  };

  render() {
    const {
      isMobile,
      currentReviewRequest,
      canReview,
      approvalRequired,
      branchOwnerMembershipLoaded,
      loadBranchOwnerMembership,
    } = this.props;

    if (!canReview) {
      return null;
    }

    if (!branchOwnerMembershipLoaded) {
      loadBranchOwnerMembership();
    }

    const { showReviewForm } = this.state;
    const formBody = (
      <React.Fragment>
        <div className={style.titleContainer}>
          <ReviewTitleBar
            reviewRequest={currentReviewRequest}
            approvalRequired={approvalRequired}
          />
        </div>
        {this.renderReviewForm()}
      </React.Fragment>
    );

    return (
      <div className={style.wrapper}>
        {isMobile ? (
          <React.Fragment>
            {this.renderReviewButton()}
            <DialogForm
              title="Review changes"
              onClose={this.closeForm}
              disabled={this.state.status === ""}
              isOpen={showReviewForm}
              contentClassName={style.mobileModalContent}
              primaryButton={this.renderSubmitButton()}
              fixedHeight
            >
              {() => formBody}
            </DialogForm>
          </React.Fragment>
        ) : (
          <AnimatedFlyover
            body={formBody}
            isOpen={this.state.showReviewForm}
            className={style.popoverForm}
            onRequestClose={this.resetForm}
            onClickOutside={this.resetForm}
          >
            {this.renderReviewButton()}
          </AnimatedFlyover>
        )}
      </div>
    );
  }
}

export default connector(BranchReviewForm);
