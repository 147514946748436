// @flow
import * as React from "react";
import AbstractConfig from "abstract-di/config";
import DialogForm from "core/components/DialogForm";
import { appName, downloadLink } from "core/lib/bundleId";
import { push } from "core/lib/location";
import { filePath } from "core/lib/routes";
import { extension } from "core/models/file";
import type { File, FileMode } from "core/types";
import connector from "./connector";

export type OwnProps = {|
  bundleId?: string,
  file?: File,
  projectId: string,
  branchId: string,
|};

export type DispatchProps = {|
  openFile: ({
    projectId: string,
    branchId: string,
    fileId: string,
    sha: string,
    layerId?: string,
    fileMode?: FileMode,
  }) => void,
  dismissDialog: () => void,
|};

export type Props = {
  ...OwnProps,
  ...DispatchProps,
};

class AppNotInstalled extends React.Component<Props> {
  handleTryAgain = () => {
    const { projectId, branchId, file } = this.props;
    if (!file) {
      return;
    }

    this.props.openFile({
      projectId,
      branchId,
      fileId: file.id,
      sha: file.sha,
    });
    this.props.dismissDialog();
  };

  handleSubmit = () => {
    const { projectId, branchId, file, bundleId } = this.props;
    const openingFile = file && file.id;
    const link = bundleId && downloadLink(bundleId);

    if (openingFile) {
      if (file) {
        push(filePath(projectId, branchId, file.id));
      }
    } else if (link) {
      AbstractConfig.openExternal(link);
    }
    this.props.dismissDialog();
  };

  getDescription = () => {
    const { file, bundleId } = this.props;
    const openingFile = file && file.id;
    const name = bundleId && appName(bundleId);

    if (file) {
      return name ? (
        <p>
          Sorry, we were unable to {openingFile ? "open" : "import"}{" "}
          <em>{file.name}</em>
          {extension(file.type)} without a copy of {name} installed on your
          machine.
        </p>
      ) : (
        <p>
          Sorry, we were unable to {openingFile ? "open" : "import"}{" "}
          <em>{file.name}</em>
          {extension(file.type)} without the required app installed on your
          machine.
        </p>
      );
    }

    return name ? (
      <p>
        Sorry, it looks like you don’t have {name} installed on your machine. A
        copy of {name} is required to complete this action.
      </p>
    ) : (
      <p>
        Sorry, it looks like you don’t have the required app installed on your
        machine. A copy of the app is required to complete this action.
      </p>
    );
  };

  render() {
    const { file, bundleId } = this.props;
    const openingFile = file && file.id;
    const name = bundleId && appName(bundleId);

    let primaryButton = "Preview File";
    if (!openingFile) {
      primaryButton = name ? `Download ${name}` : "Cancel";
    }

    return (
      <DialogForm
        title={`${name || "App"} not installed`}
        primaryButton={primaryButton}
        secondaryButton={openingFile ? "Try Again" : undefined}
        onSubmit={this.handleSubmit}
        onSecondary={this.handleTryAgain}
        onClose={this.props.dismissDialog}
        isOpen
      >
        {this.getDescription()}
      </DialogForm>
    );
  }
}

export default connector(AppNotInstalled);
