// @flow
import * as Abstract from "abstract-sdk";
import * as React from "react";
import Button from "core/components/Button";
import NoWebhooks from "core/components/Empty/NoWebhooks";
import Flex from "core/components/Flex";
import FormHeader from "core/components/FormHeader";
import FormSection from "core/components/FormSection";
import Loaded from "core/components/Loaded";
import Media from "core/components/Media";
import SettingsForm from "core/components/SettingsForm";
import window from "core/global/window";
import type {
  NewWebhook,
  ReactRouterLocation,
  ThunkAction,
  Webhook,
  WebhookEvent,
  SlackIntegration,
} from "core/types";
import { fetchInitiateIntegrationUrl } from "web/api";
import SlackSettingsItem from "web/components/SlackSettingsItem";
import WebhookDialog from "web/components/WebhookOverview/WebhookDialog";
import WebhookListItem from "web/components/WebhookOverview/WebhookListItem";
import { integrationSettings } from "web/routeHelpers";
import connector from "./connector";
import style from "./style.scss";

export type RouterProps = {|
  location: ReactRouterLocation,
  params: Abstract.OrganizationDescriptor,
|};

export type DispatchProps = {|
  createWebhook: (params: NewWebhook) => ThunkAction,
  fetchWebhookEvents: (params: Abstract.OrganizationDescriptor) => void,
  fetchWebhooks: (params: Abstract.OrganizationDescriptor) => void,
|};

export type StateProps = {|
  canViewWebhooks: boolean,
  createWebhookError: boolean,
  createWebhookLoading: boolean,
  fetchWebhooksLoading: boolean,
  slackIntegration?: ?SlackIntegration,
  webhookEvents: WebhookEvent[],
  webhooks: Webhook[],
|};

export type Props = {
  ...RouterProps,
  ...StateProps,
  ...DispatchProps,
};

type State = {
  error?: string,
  success?: string,
  webhookFormData: $Shape<NewWebhook>,
  webhookFormOpen: boolean,
  webhookFormPage: number,
};

class Integrations extends React.Component<Props, State> {
  state = {
    error: undefined,
    success: undefined,
    webhookFormData: this.getDefaultFormData(),
    webhookFormOpen: false,
    webhookFormPage: 0,
  };

  getDefaultFormData() {
    return {
      active: true,
      events: [],
      key: "",
      organizationId: this.props.params.organizationId,
      url: "",
    };
  }

  componentDidMount() {
    const { fetchWebhookEvents, fetchWebhooks, location } = this.props;
    const { organizationId } = this.props.params;

    fetchWebhooks({ organizationId });
    fetchWebhookEvents({ organizationId });

    if (location && location.state) {
      if (location.state.integrationDeletionSuccess) {
        this.setState({ success: "Integration successfully disconnected." });
      }
      if (location.state.webhookDeletionSuccess) {
        this.setState({ success: "Webhook successfully deleted." });
      }
      if (location.state.integrationCreationError) {
        this.setState({ error: "Integration could not be connected." });
      }
    }
  }

  static getDerivedPropsFromState(nextProps: Props, prevState: State) {
    if (nextProps.createWebhookError && !prevState.error) {
      return { error: "Webhook could not be created." };
    }

    if (
      !nextProps.createWebhookLoading &&
      !nextProps.createWebhookError &&
      !prevState.success
    ) {
      return { success: "Webhook succesfully created." };
    }

    return null;
  }

  handleConnect = async (kind: string) => {
    const response = await fetchInitiateIntegrationUrl(
      this.props.params.organizationId,
      kind
    );
    window.location.href = response.url;
  };

  onBannerHide = () => {
    this.setState({
      error: undefined,
      success: undefined,
    });
  };

  onWebhookFormOpen = () => {
    this.setState({ webhookFormOpen: true });
  };

  onWebhookFormChange = (webhookFormData: $Shape<NewWebhook>) => {
    this.setState({ webhookFormData });
  };

  onWebhookFormPageChange = (page: number) => {
    this.setState({ webhookFormPage: page });
  };

  onWebhookFormClose = () => {
    this.setState({
      webhookFormData: this.getDefaultFormData(),
      webhookFormOpen: false,
      webhookFormPage: 0,
    });
  };

  onWebhookFormSubmit = async (webhookFormData: NewWebhook) => {
    const { createWebhook } = this.props;
    await createWebhook(webhookFormData);
    this.onWebhookFormClose();
  };

  renderHeader() {
    const { error, success } = this.state;
    return (
      <Media desktop>
        {(desktop) => (
          <FormHeader
            error={error}
            flashBanner
            icon="integrations"
            mobile={!desktop}
            onBannerHide={this.onBannerHide}
            success={!!success}
            successMessage={success}
          />
        )}
      </Media>
    );
  }

  render() {
    const {
      canViewWebhooks,
      fetchWebhooksLoading,
      slackIntegration,
      webhookEvents,
      webhooks,
    } = this.props;

    const { organizationId } = this.props.params;

    const { webhookFormPage, webhookFormData, webhookFormOpen } = this.state;

    return (
      <SettingsForm header={this.renderHeader()} documentTitle="Integrations">
        {slackIntegration && (
          <FormSection heading="Connected">
            {slackIntegration && (
              <SlackSettingsItem
                handleConnect={this.handleConnect}
                connected
                integrationUrl={integrationSettings(
                  slackIntegration.id,
                  organizationId
                )}
              />
            )}
          </FormSection>
        )}
        {!slackIntegration && (
          <FormSection heading="Available">
            <SlackSettingsItem handleConnect={this.handleConnect} />
          </FormSection>
        )}
        {canViewWebhooks && (
          <FormSection
            action={
              fetchWebhooksLoading ? null : (
                <Button icon="plus" onClick={this.onWebhookFormOpen} primary>
                  Add Webhook…
                </Button>
              )
            }
            heading="Webhooks"
          >
            <Loaded loading={fetchWebhooksLoading} title="Loading webhooks…">
              <Flex column className={style.webhooks}>
                {webhooks && webhooks.length ? (
                  webhooks.map((webhook) => (
                    <WebhookListItem webhook={webhook} key={webhook.id} />
                  ))
                ) : (
                  <NoWebhooks />
                )}
              </Flex>
            </Loaded>
            <WebhookDialog
              data={webhookFormData}
              isOpen={webhookFormOpen}
              onChangeData={this.onWebhookFormChange}
              onChangePage={this.onWebhookFormPageChange}
              onClose={this.onWebhookFormClose}
              onSubmit={this.onWebhookFormSubmit}
              page={webhookFormPage}
              supportSigningKey
              webhookEvents={webhookEvents}
            />
          </FormSection>
        )}
      </SettingsForm>
    );
  }
}

export default connector(Integrations);
