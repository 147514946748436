// @flow
import * as React from "react";
import DialogForm from "core/components/DialogForm";
import FilePicker from "core/components/FilePicker";
import Note from "core/components/Note";
import { helpLinkLibrariesUrl } from "core/lib/urls";
import type { Project, File } from "core/types";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  organizationId: string,
  projectId: string,
  branchId: string,
  canUseSketchLibraries?: boolean,
|};

export type StateProps = {|
  files: {
    [string]: {
      entities?: File[],
      isLoading?: boolean,
    },
  },
  projects: Project[],
|};

export type DispatchProps = {|
  dismissDialog: () => void,
  onSubmit: (projectId: string, fileIds: string[]) => void,
  onClickProject: (string) => Promise<void>,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type State = {
  projectId: string,
  fileIds: string[],
};

class LibraryPicker extends React.Component<Props, State> {
  state = {
    projectId: "",
    fileIds: [],
  };

  handleChange = (projectId: string, fileIds: string[]) => {
    this.setState({ projectId, fileIds });
  };

  handleSubmit = () => {
    this.props.onSubmit(this.state.projectId, this.state.fileIds);
  };

  render() {
    const fileCount = this.state.fileIds.length;
    return (
      <DialogForm
        title="Link Library"
        size="large"
        onSubmit={this.handleSubmit}
        onClose={this.props.dismissDialog}
        primaryButton={
          fileCount > 1 ? `Link ${fileCount} Libraries` : "Link Library"
        }
        disabled={fileCount === 0}
        isOpen
      >
        <FilePicker
          projectId={this.props.projectId}
          projects={this.props.projects}
          files={this.props.files}
          className={style.picker}
          canUseSketchLibraries={this.props.canUseSketchLibraries}
          onClickProject={this.props.onClickProject}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
          buttonText="Link Library"
          buttonTextMultiple="Link Libraries"
        />
        <Note className={style.note} component="div">
          Linking library files makes their symbols available on branches inside
          this project. Projects with linked libraries will be automatically
          synced.
        </Note>
        <a href={helpLinkLibrariesUrl()} className={style.infoLink}>
          Learn more about linked libraries…
        </a>
      </DialogForm>
    );
  }
}

export default connector(LibraryPicker);
