// @flow
import * as React from "react";
import Button from "core/components/Button";
import Flex from "core/components/Flex";
import Icon from "core/components/Icon";
import style from "./style.scss";

type Props = {|
  loadCommits: () => void,
|};

export default function OlderCommitsOffline(props: Props) {
  return (
    <div className={style.container}>
      <Flex align="center" justify="center" column>
        <Icon className={style.icon} type="offline" large lightGray />
        Older commits unavailable offline
        <span className={style.secondary}>
          Reconnect to see the entire history
        </span>
        <Button
          className={style.tryAgain}
          onClick={props.loadCommits}
          nude
          tint
        >
          Try again
        </Button>
      </Flex>
    </div>
  );
}
