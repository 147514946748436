// @flow
import pluralize from "pluralize";
import * as React from "react";
import Button from "core/components/Button";
import DialogForm from "core/components/DialogForm";
import FilesListItem from "core/components/FilesListItem";
import type { File } from "core/types";
import style from "./style.scss";

type Props = {
  file: File,
  changeCount: number,
  selected?: boolean,
  disabled?: boolean,
  onChange: (event: SyntheticInputEvent<>, fileId: string) => *,
  onDiscardChanges?: (event: SyntheticEvent<>, fileId: string) => *,
};

type State = {
  isDialogOpen: boolean,
  isMouseOver: boolean,
};

export default class SelectableFilesListItem extends React.Component<
  Props,
  State,
> {
  state = {
    isDialogOpen: false,
    isMouseOver: false,
  };

  handleMouseEnter = () => {
    this.setState({ isMouseOver: true });
  };

  handleMouseLeave = () => {
    this.setState({ isMouseOver: false });
  };

  handleDiscard = (event: SyntheticEvent<>) => {
    if (this.props.onDiscardChanges) {
      this.props.onDiscardChanges(event, this.props.file.id);
      this.setState({ isDialogOpen: false });
    }
  };

  handleHideConfirm = () => {
    this.setState({ isDialogOpen: false });
  };

  handleShowConfirm = () => {
    this.setState({ isDialogOpen: true });
  };

  render() {
    const {
      file,
      selected,
      disabled,
      changeCount,
      onChange,
      onDiscardChanges,
    } = this.props;

    return (
      <FilesListItem
        id={file.id}
        name={file.name}
        type={file.type}
        isLibrary={!!file.isLibrary}
        selected={selected}
        disabled={disabled}
        onChange={onChange}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        meta={
          <React.Fragment>
            {`${changeCount} ${pluralize("change", changeCount)}`}
            {onDiscardChanges && (
              <span>
                {" "}
                &middot;{" "}
                <Button
                  onClick={this.handleShowConfirm}
                  danger={this.state.isMouseOver}
                  className={style.discardButton}
                  nude
                >
                  Discard
                </Button>
                <DialogForm
                  dangerous
                  isOpen={this.state.isDialogOpen}
                  onClose={this.handleHideConfirm}
                  onRequestClose={this.handleHideConfirm}
                  title="Discard changes"
                  onSubmit={this.handleDiscard}
                  primaryButton="Discard"
                >
                  {() => (
                    <p>
                      Are you sure you want to discard all the uncommitted
                      changes in the file <strong>{file.name}</strong>?
                    </p>
                  )}
                </DialogForm>
              </span>
            )}
          </React.Fragment>
        }
        s
      />
    );
  }
}
