// @flow
import { normalize, schema } from "normalizr";
import { project, policy } from "core/schema";

const projectSocketResponseSchema = new schema.Object({
  project: project,
  policy: policy,
});

export function normalizeProjectSocketResponse(response: Object) {
  return normalize(response, projectSocketResponseSchema);
}
