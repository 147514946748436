// @flow
import classnames from "classnames";
import { filter, get, without } from "lodash";
import * as React from "react";
import Empty from "core/components/Empty";
import FileName from "core/components/FileName";
import Icon from "core/components/Icon";
import InputSearch from "core/components/InputSearch";
import Loaded from "core/components/Loaded";
import ProjectIcon from "core/components/ProjectIcon";
import ProjectName from "core/components/ProjectName";
import Scrollable from "core/components/Scrollable";
import matchString from "core/lib/matchString";
import naturalSortBy from "core/lib/naturalSortBy";
import { modifierKeyPressed } from "core/lib/platform";
import type { Project, File } from "core/types";
import style from "./style.scss";

type Props = {
  projectId: string,
  projects: Project[],
  files: {
    [projectId: string]: {
      entities?: File[],
      isLoading?: boolean,
    },
  },
  className?: string,
  onChange?: (projectId: string, fileIds: string[]) => void,
  onClickProject?: (projectId: string) => Promise<void>,
  onSubmit?: (projectId: string, fileIds: string[]) => void,
  canUseSketchLibraries?: boolean,
};

type State = {
  projectsFilter: string,
  selectedProjectId: string,
  focusedFileId: string,
  selectedFileIds: string[],
};

export default class FilePicker extends React.Component<Props, State> {
  static defaultProps = {
    canUseSketchLibraries: true,
  };

  state = {
    projectsFilter: "",
    selectedProjectId: "",
    selectedFileIds: [],
    focusedFileId: "",
  };

  handleSubmit = (event: SyntheticEvent<>) => {
    event.preventDefault();
    event.stopPropagation();

    if (!this.props.onSubmit) {
      return;
    }
    this.props.onSubmit(
      this.state.selectedProjectId,
      this.state.selectedFileIds
    );
  };

  handleChangeProjectFilter = (
    event: SyntheticInputEvent<HTMLInputElement>
  ) => {
    this.setState({ projectsFilter: event.target.value });
  };

  handleClickFile = (event: SyntheticMouseEvent<>, fileId: string) => {
    event.preventDefault();
    event.stopPropagation();
    event.persist();

    this.setState((prevState) => {
      const data = {};

      if (!prevState.focusedFileId) {
        data.focusedFileId = fileId;
      }

      if (modifierKeyPressed(event)) {
        if (prevState.selectedFileIds.includes(fileId)) {
          data.selectedFileIds = without(prevState.selectedFileIds, fileId);
        } else {
          data.selectedFileIds = prevState.selectedFileIds.concat(fileId);
        }
      } else if (event.shiftKey) {
        const projectFiles = this.props.files[prevState.selectedProjectId];
        const projectFileIds = (projectFiles.entities || []).map((f) => f.id);
        const focusIndex = projectFileIds.indexOf(
          prevState.focusedFileId || fileId
        );
        const clickIndex = projectFileIds.indexOf(fileId);
        const startIndex = Math.min(focusIndex, clickIndex);
        const endIndex = Math.min(
          Math.max(focusIndex, clickIndex) + 1,
          projectFileIds.length
        );
        const selectedFileIds = projectFileIds.slice(startIndex, endIndex);
        data.selectedFileIds = selectedFileIds;
      } else {
        data.selectedFileIds = [fileId];
      }

      if (this.props.onChange) {
        this.props.onChange(prevState.selectedProjectId, data.selectedFileIds);
      }

      return data;
    });
  };

  handleClickProject = (projectId: string) => {
    if (this.props.onClickProject) {
      this.props.onClickProject(projectId);
    }
    this.setState({
      selectedFileIds: [],
      focusedFileId: "",
      selectedProjectId: projectId,
    });
  };

  handleClickOutside = () => {
    this.setState({
      selectedFileIds: [],
      focusedFileId: "",
    });

    if (this.props.onChange) {
      this.props.onChange(this.state.selectedProjectId, []);
    }
  };

  getSelectableProjects = (): Project[] => {
    return naturalSortBy(
      filter(
        this.props.projects,
        (project) =>
          this.props.projectId !== project.id &&
          !project.archivedAt &&
          matchString(project.name, this.state.projectsFilter)
      ),
      "name"
    );
  };

  renderProject = (project: Project) => {
    const selected = project.id === this.state.selectedProjectId;
    return (
      <li
        key={project.id}
        className={classnames(style.item, style.enabled, {
          [style.selected]: selected,
        })}
        onClick={(event) => this.handleClickProject(project.id)}
      >
        <span className={style.projectName} title={project.name}>
          <ProjectIcon
            className={style.projectIcon}
            color={selected ? "#FFF" : project.color}
          />
          <ProjectName project={project} light={selected} />
        </span>
        <Icon type="chevron-default-right" light={selected} />
      </li>
    );
  };

  renderFile = (file: File) => {
    const selected = this.state.selectedFileIds.includes(file.id);
    const disabled =
      file.type === "sketch" && !this.props.canUseSketchLibraries;

    return (
      <li
        key={file.id}
        className={classnames(style.item, {
          [style.selected]: selected,
          [style.disabled]: disabled,
          [style.enabled]: !disabled,
        })}
        onClick={
          disabled ? undefined : (event) => this.handleClickFile(event, file.id)
        }
        onDoubleClick={disabled ? undefined : this.handleSubmit}
        title={file.name}
      >
        <FileName
          {...file}
          fileClassName={style.fileName}
          extensionClassName={style.fileExtension}
        />
      </li>
    );
  };

  renderFiles = () => {
    const { selectedProjectId } = this.state;
    const files = get(this.props.files, [selectedProjectId, "entities"], []);
    const isEmpty = !files.length;
    const isLoading = get(
      this.props.files,
      [selectedProjectId, "isLoading"],
      true
    );

    return (
      <Loaded loading={isLoading && isEmpty}>
        <Scrollable className={style.scrollable}>
          {isEmpty ? (
            <Empty description="No library files in this project" />
          ) : (
            <ul className={style.list}>{files.map(this.renderFile)}</ul>
          )}
        </Scrollable>
      </Loaded>
    );
  };

  render() {
    const projects = this.getSelectableProjects();
    const isFiltered = this.state.projectsFilter !== "";
    const isEmpty = !projects.length;

    return (
      <div className={classnames(style.picker, this.props.className)}>
        <div className={style.content} onClick={this.handleClickOutside}>
          <div className={style.column}>
            <div className={style.searchWrapper}>
              <InputSearch
                value={this.state.projectsFilter}
                onChange={this.handleChangeProjectFilter}
                placeholder="Filter projects…"
                autoFocus
              />
            </div>
            <Scrollable>
              {isEmpty ? (
                isFiltered ? (
                  <Empty description="No projects matching your filter" />
                ) : (
                  <Empty description="No other projects" />
                )
              ) : (
                <ul className={classnames(style.list, style.withFilter)}>
                  {projects.map(this.renderProject)}
                </ul>
              )}
            </Scrollable>
          </div>
          <div className={style.column}>
            {this.state.selectedProjectId && this.renderFiles()}
          </div>
        </div>
      </div>
    );
  }
}
