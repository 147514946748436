// @flow
import classnames from "classnames";
import * as React from "react";
import Button from "core/components/Button";
import Flex from "core/components/Flex";
import Icon from "core/components/Icon";
import { V3Link as Link } from "core/lib/router";
import * as Routes from "core/lib/routes";
import style from "./style.scss";

type Props = {|
  className?: string,
  organizationId: string,
|};

export default function FavoriteProjectsOnboarding(props: Props) {
  return (
    <Flex
      align="center"
      className={classnames(style.container, props.className)}
      justify="center"
      column
    >
      <Flex align="flex-end">
        <Icon
          className={classnames(style.smallStar, style.orangeStar)}
          fill="currentColor"
          type="starred"
        />
        <Icon
          className={style.yellowStar}
          fill="currentColor"
          type="starred"
          large
        />
        <Icon
          className={classnames(style.smallStar, style.blueStar)}
          fill="currentColor"
          type="starred"
        />
      </Flex>
      <div className={style.copy}>
        Favorite Projects to add them to your navigation
      </div>
      <Button
        className={style.viewProjects}
        component={Link}
        to={Routes.organizationProjectsPath(props.organizationId)}
        nude
        tint
      >
        View Projects
      </Button>
    </Flex>
  );
}
