// @flow
export const ReviewStatuses = {
  REQUESTED: "REQUESTED",
  REJECTED: "REJECTED",
  APPROVED: "APPROVED",
};

export type ReviewStatus = "REQUESTED" | "REJECTED" | "APPROVED";

export function reviewStatusColor(status: ReviewStatus) {
  switch (status) {
    case ReviewStatuses.APPROVED:
      return "green";
    case ReviewStatuses.REJECTED:
      return "orange";
    default:
      return undefined;
  }
}

export function reviewStatusIcon(status: ReviewStatus) {
  switch (status) {
    case ReviewStatuses.APPROVED:
      return "review-default-green";
    case ReviewStatuses.REJECTED:
      return "changes-request-regular-orange";
    default:
      return undefined;
  }
}

export function reviewStatusBadgeIcon(status: ReviewStatus) {
  switch (status) {
    case ReviewStatuses.APPROVED:
      return "review-small";
    case ReviewStatuses.REJECTED:
      return "changes-request-small";
    default:
      return undefined;
  }
}
