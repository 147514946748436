// @flow
import * as React from "react";
import {
  default as CoreContextMenu,
  type Props,
} from "core/components/ContextMenu";

export default function ContextMenu(props: Props) {
  return <CoreContextMenu {...props} />;
}
