// @flow
import idx from "idx";
import apiRequest from "abstract-di/api";
import { entitiesReceived, entitiesDeleted } from "core/actions/entities";
import { showCloseableToast } from "core/actions/toasts";
import { ValidationError } from "core/api";
import { Abstract } from "core/lib/abstract";
import {
  normalizeEmojisFetchResponse,
  normalizeEmojiResponse,
} from "core/schemas/emoji";
import type { Emoji as TEmoji } from "core/types";
import defineRequest from "./";

type EmojisFetchParams = {
  organizationId: string,
  includeUsers?: boolean,
};

export const EmojisFetchRequest = defineRequest<
  EmojisFetchParams,
  EmojisFetchParams,
>({
  id: (params) => {
    return `get:organizations/${params.organizationId}/emojis?includeUsers=${
      params.includeUsers ? "y" : "n"
    }`;
  },
  perform: (params) => {
    return apiRequest("get", `organizations/${params.organizationId}/emojis`, {
      includeUsers: params.includeUsers,
    });
  },
  onSuccess: (response, params, dispatch) => {
    const normalizedEmojis = normalizeEmojisFetchResponse(response);
    dispatch(entitiesReceived(normalizedEmojis.entities));
  },
});

type CreateParams = {
  ...Abstract.OrganizationDescriptor,
  name: string,
  image: string,
};

export const EmojiCreateRequest = defineRequest<CreateParams, CreateParams>({
  id: (params) => {
    return `post:organizations/${params.organizationId}/emojis?name=${params.name}`;
  },
  perform: (params) => {
    const { organizationId, ...emoji } = params;
    return apiRequest("post", `organizations/${organizationId}/emojis`, emoji);
  },
  onSuccess: (response, params, dispatch) => {
    const { entities } = normalizeEmojiResponse(response);
    dispatch(entitiesReceived(entities));
  },
  onError: (error, params, dispatch) => {
    if (error instanceof ValidationError) {
      const errors = idx(error, (_) => _.body.errors) || {};
      const attributes = Object.keys(errors);
      if (attributes.length) {
        const attribute = attributes[0];
        const message = errors[attribute];
        dispatch(showCloseableToast({ text: `${attribute} ${message}` }));
      }
    } else {
      dispatch(
        showCloseableToast({
          text: "An error occurred while adding your emoji. Please try again.",
        })
      );
    }
  },
});

export const EmojiDeleteRequest = defineRequest<TEmoji, TEmoji>({
  id: (params) => {
    return `delete:organizations/${params.organizationId}/emojis?name=${params.name}`;
  },
  perform: (params) => {
    const { organizationId, name } = params;
    return apiRequest(
      "delete",
      `organizations/${organizationId}/emojis/${name}`
    );
  },
  onSuccess: (response, params, dispatch) => {
    dispatch(
      entitiesDeleted({
        emojis: [params.id],
      })
    );
  },
  onError: (error, params, dispatch) => {
    if (error instanceof ValidationError) {
      const errors = idx(error, (_) => _.body.errors) || {};
      const attributes = Object.keys(errors);
      if (attributes.length) {
        const attribute = attributes[0];
        const message = errors[attribute];
        dispatch(showCloseableToast({ text: `${attribute} ${message}` }));
      }
    } else {
      dispatch(
        showCloseableToast({
          text: "An error occurred while deleting your emoji. Please try again.",
        })
      );
    }
  },
});
