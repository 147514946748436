// @flow
import createCachedSelector from "@elasticprojects/re-reselect";
import { filter, values } from "lodash";
import { DEFAULT_PROJECT_FILTER } from "core/lib/lists";
import naturalSortBy from "core/lib/naturalSortBy";
import { getStarEntities } from "core/selectors/stars";
import type { State, Section, ProjectFilter } from "core/types";
import { getOrganizationId } from "./helpers";

export function getSection(state: State, props: { sectionId: ?string }) {
  if (!props.sectionId) {
    return undefined;
  }
  return state.sections[props.sectionId];
}

const getSectionEntities = (state) => state.sections;
const getFilter = (state, props) => props.filter || DEFAULT_PROJECT_FILTER;

const filteredSectionsCacheId = (state, { filter = "", organizationId }) =>
  `${organizationId}-${filter}`;

export const getSections: (
  state: State,
  props: { organizationId: string }
) => Section[] = createCachedSelector(
  getSectionEntities,
  getOrganizationId,
  (sections, organizationId) => filter(values(sections), { organizationId })
)(getOrganizationId);

export const getFilteredSections: (
  state: State,
  props: {
    organizationId: string,
    filter?: ProjectFilter,
  }
) => Section[] = createCachedSelector(
  getSections,
  getStarEntities,
  getFilter,
  (sections, stars, typeFilter) => {
    let filtered = sections;

    if (typeFilter === "starred") {
      filtered = filter(filtered, (section) => !!stars[section.id]);
    }

    filtered = naturalSortBy(filtered, "name");

    if (typeFilter === "active") {
      filtered = naturalSortBy(filtered, (section) =>
        stars[section.id] ? "0" : "1"
      );
    }

    return filtered;
  }
)(filteredSectionsCacheId);
