// @flow
import createCachedSelector from "@elasticprojects/re-reselect";
import { filter, find } from "lodash";
import { Abstract } from "core/lib/abstract";
import matchString from "core/lib/matchString";
import naturalSortBy from "core/lib/naturalSortBy";
import { getRawEntities } from "core/selectors/entities";
import { getOrganizationId, getProjectId } from "core/selectors/helpers";
import { getTeamMembershipEntities } from "core/selectors/teamMemberships";
import { getTeamProjectMembershipEntities } from "core/selectors/teamProjectMemberships";
import type {
  Team,
  State,
  OrganizationTeamDescriptor,
  TeamsOptions,
  TeamDescriptor,
} from "core/types";

function getSearch(state: State, params: { ...TeamsOptions }) {
  return params.search;
}

function getUserId(state: State, params: { ...TeamsOptions }) {
  return params.userId;
}

function cacheOrganizationTeams(state: State, { organizationId }) {
  return `${organizationId}-team`;
}

function cacheProjectTeams(state: State, { projectId }) {
  return `${projectId}-team`;
}

export const getTeamEntities = (state: State): { [teamId: string]: Team } => {
  return getRawEntities(state, "teams");
};

export const getTeamsForOrganization: (
  state: State,
  params: { ...Abstract.OrganizationDescriptor, ...TeamsOptions }
) => Team[] = createCachedSelector(
  getSearch,
  getUserId,
  getOrganizationId,
  getTeamEntities,
  getTeamMembershipEntities,
  (search, userId, organizationId, teams, teamMemberships) => {
    const filteredTeams = naturalSortBy(
      filter(teams, (team) => {
        const correctOrg = team.organizationId === organizationId;
        const querySearch = search ? matchString(team.name, search) : true;
        const userIdFilter = userId
          ? !!find(filter(teamMemberships, { teamId: team.id }), {
              userId,
            })
          : true;
        return correctOrg && querySearch && userIdFilter;
      }),
      "name",
      { direction: "asc" }
    );
    return filteredTeams;
  }
)(cacheOrganizationTeams);

export const getTeamsForProject: (
  state: State,
  params: { ...Abstract.ProjectDescriptor, ...TeamsOptions }
) => Team[] = createCachedSelector(
  getSearch,
  getProjectId,
  getTeamEntities,
  getTeamProjectMembershipEntities,
  (search, projectId, teams, teamProjectMemberships) => {
    const filteredteams = naturalSortBy(
      filter(teams, (team) => {
        const correctProjectTeam =
          !!teamProjectMemberships[`${projectId}-${team.id}`];
        const querySearch = matchString(team.name, search);

        return correctProjectTeam && querySearch;
      }),
      "name",
      { direction: "asc" }
    );

    return filteredteams;
  }
)(cacheProjectTeams);

export function getTeam(
  state: State,
  descriptor: OrganizationTeamDescriptor | TeamDescriptor
): ?Team {
  const teamEntities = getTeamEntities(state);
  return teamEntities[descriptor.teamId];
}
