// @flow
import classnames from "classnames";
import * as React from "react";
import Flex from "../Flex";
import ValidationError from "../ValidationError";
import style from "./style.scss";

type OwnProps = {|
  children: React.Node,
  className?: string,
  error?: ?(string | string[]),
  inputId?: string,
  inline?: boolean,
  label?: React.Node,
  sublabel?: string,
  labelClass?: string,
  responsive?: boolean,
  optionalTag?: boolean,
  requiredTag?: boolean,
  visuallyHidden?: boolean,
  helpers?: {
    [key: string]: React.Node,
  },
  qaSelector?: string,
|};

type Props = {
  ...OwnProps,
};

function renderOptionalTag() {
  return <span>Optional</span>;
}

function renderRequiredTag() {
  return <span>Required</span>;
}

function renderHelpers(helpers, responsive, inline) {
  if (!helpers) {
    return null;
  }

  return (
    <div
      className={classnames(style.helpers, {
        [style.responsiveHelpers]: responsive,
        [style.responsiveInlineHelpers]: inline,
      })}
    >
      {Object.keys(helpers).map((key) => (
        <span key={key} className={style.helper}>
          {helpers[key]}
        </span>
      ))}
    </div>
  );
}

function renderLabel(
  label,
  id,
  {
    sublabel,
    responsive,
    inline,
    labelClass,
  }: {
    sublabel?: string,
    responsive?: boolean,
    inline?: boolean,
    labelClass?: string,
  }
) {
  if (!label) {
    return null;
  }

  return (
    <label
      htmlFor={id}
      className={classnames(style.label, labelClass, {
        [style.responsiveLabel]: responsive,
        [style.responsiveInlineLabel]: inline,
      })}
    >
      {label}
      {sublabel && (
        <React.Fragment>
          <br />
          <span className={style.sublabel}>{sublabel}</span>
        </React.Fragment>
      )}
    </label>
  );
}

function renderInline({
  children,
  error,
  helpers,
  inputId,
  label,
  labelClass,
  responsive,
  sublabel,
}) {
  if (responsive) {
    /* I am wrapping the label and input so that the label can overflow and still
      remain on the same line as the input */

    return (
      <div className={style.responsiveContainer}>
        <div className={style.responsiveInlineWrap}>
          <Flex grow align="stretch">
            {renderLabel(label, inputId, {
              sublabel,
              labelClass,
              responsive: true,
              inline: true,
            })}
            {renderHelpers(helpers, false, true)}
          </Flex>
          {children}
        </div>
      </div>
    );
  }
  return (
    <span className={style.inlineContainer}>
      {children}
      {renderLabel(label, inputId, {
        sublabel,
        labelClass,
      })}
    </span>
  );
}

function renderBlock({
  children,
  error,
  helpers,
  inputId,
  label,
  labelClass,
  optionalTag,
  requiredTag,
  responsive,
  sublabel,
}) {
  if (optionalTag) {
    helpers = { ...helpers, optionalTag: renderOptionalTag() };
  }
  if (requiredTag) {
    helpers = { ...helpers, requiredTag: renderRequiredTag() };
  }

  return (
    <div
      className={classnames(style.blockContainer, {
        [style.responsiveContainer]: responsive,
      })}
    >
      <Flex grow align="stretch">
        {renderLabel(label, inputId, {
          sublabel,
          labelClass,
          responsive,
        })}
        {renderHelpers(helpers, responsive)}
      </Flex>

      <div
        className={classnames(style.inputWrapper, {
          [style.responsiveInput]: responsive,
        })}
      >
        {children}
      </div>
    </div>
  );
}

export default function InputWrapper(props: Props) {
  const {
    children,
    className,
    error,
    helpers,
    inline,
    inputId,
    label,
    labelClass,
    optionalTag,
    requiredTag,
    responsive,
    sublabel,
    visuallyHidden,
    qaSelector,
    ...htmlProps
  } = props;

  const classes = classnames(
    className,
    style.componentWrapper,
    visuallyHidden ? style.visuallyhidden : ""
  );

  return (
    <div {...htmlProps} className={classes} data-qa={qaSelector}>
      {inline
        ? renderInline({
            children,
            error,
            helpers,
            inputId,
            label,
            labelClass,
            responsive,
            sublabel,
          })
        : renderBlock({
            children,
            error,
            helpers,
            inputId,
            label,
            labelClass,
            responsive,
            optionalTag,
            requiredTag,
            sublabel,
          })}
      {error && (
        <ValidationError
          error={error}
          className={classnames({ [style.responsiveError]: responsive })}
        />
      )}
    </div>
  );
}
