// @flow
import uuid from "uuid/v4";

function generateTraceId() {
  const time = new Date().getTime();
  const id = uuid();
  return `Root=1-${time}-${id}`;
}

export default function fetchWithTrace(
  input: string | Request,
  init: Object = {}
) {
  init.headers = init.headers || {};
  init.headers["X-Amzn-Trace-Id"] = generateTraceId();
  return fetch(input, init);
}
