// @flow
import * as React from "react";
import Button from "core/components/Button";
import Popover from "core/components/Popover";
import { privacyUrl } from "core/lib/urls";
import style from "./style.scss";

export default function MarketingConsentInfoPopover() {
  return (
    <Popover
      placement="top"
      body={
        <div className={style.message}>
          We’d love to keep you updated via email with product and feature
          announcements, promotions, educational materials, and events. See our{" "}
          <a href={privacyUrl()} target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>{" "}
          for more details.
        </div>
      }
      trigger="click"
    >
      <Button icon="info" aria-haspopup nude className={style.button} />
    </Popover>
  );
}
