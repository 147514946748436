// @flow
import { normalize, schema } from "normalizr";
import { user, policies } from "core/schema";
import type { Me } from "../types";

const meSchema = new schema.Object({
  user,
  policies,
});

export function normalizeMe(me: Me) {
  return normalize(me, meSchema);
}
