// @flow
import { connect } from "react-redux";
import { isOnline } from "abstract-di/selectors";
import { publishCollection } from "core/actions/collections";
import { setPaginationTotal } from "core/actions/paginationTotals";
import { replace } from "core/lib/location";
import {
  CollectionDeleteRequest,
  CollectionUpdateRequest,
} from "core/requests/collections";
import { getBranch } from "core/selectors/branches";
import { getPaginationTotal } from "core/selectors/paginationTotals";
import { getCollectionPolicy } from "core/selectors/policies";
import { getProject } from "core/selectors/projects";
import type { State, Dispatch } from "core/types";
import type { OwnProps, StateProps, DispatchProps, Props } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { projectId, branchId, id } = props.collection;
  const project = getProject(state, { projectId });
  const policy = getCollectionPolicy(state, { collectionId: id });

  return {
    isOnline: isOnline(state),
    branch: getBranch(state, { projectId, branchId }),
    policy,
    organizationId: project ? project.organizationId : "",
    canEdit: policy.update === true,
    canDelete: policy.destroy === true,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { projectId, id, branchId } = props.collection;

  return {
    onPublish: () => dispatch(publishCollection(id)),
    onDelete: (nextPath) => {
      dispatch((dispatch, getState) => {
        dispatch(
          CollectionDeleteRequest.perform({
            params: { id, projectId, branchId },
            onSuccess: () => {
              if (nextPath) {
                replace(nextPath);
              }

              const currentPaginationTotals = getPaginationTotal(
                getState(),
                branchId
              );

              dispatch(
                setPaginationTotal(
                  branchId,
                  currentPaginationTotals ? currentPaginationTotals - 1 : 0
                )
              );
            },
          })
        );
      });
    },
    onEdit: (formValues, onSuccess) => {
      dispatch(
        CollectionUpdateRequest.perform({
          params: { id, projectId, ...formValues },
          onSuccess: (response) => onSuccess(response.data),
        })
      );
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
