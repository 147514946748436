// @flow
import abstractConfig from "abstract-di/config";
import window from "core/global/window";
import fetch from "core/lib/fetchWithTrace";
import { getShareAuthorizationHeader } from "core/lib/shareAuthorization";

export function getAuthHeader(token: ?string) {
  const shareTokenHeader = getShareAuthorizationHeader();
  if (!token && !shareTokenHeader) {
    return;
  }
  let headers = {};

  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  if (shareTokenHeader) {
    headers = { ...headers, ...shareTokenHeader };
  }

  return headers;
}

export default async function authedDownload(
  url: string,
  abortController?: window.AbortController
) {
  const authHeader = getAuthHeader(abstractConfig.getAccessToken());

  if (!authHeader) {
    throw new Error("Not authorized");
  }

  const response = await fetch(url, {
    signal: abortController ? abortController.signal : undefined,
    headers: authHeader,
  });

  const blob = await response.blob();
  return window.URL.createObjectURL(blob);
}
