// @flow
import type { ProjectMembership } from "core/types";

const ROLE_ADMIN = "admin";

export type Label = "Member" | "Guest" | "Admin" | "Project Admin";
const MEMBER: Label = "Member";
const GUEST: Label = "Guest";
const ADMIN: Label = "Admin";
const PROJECT_ADMIN: Label = "Project Admin";
export const LABELS = {
  MEMBER: MEMBER,
  GUEST: GUEST,
  ADMIN: ADMIN,
  PROJECT_ADMIN: PROJECT_ADMIN,
};

export function uniqueId({
  projectId,
  userId,
}: {
  projectId: string,
  userId: string,
}) {
  return `${projectId}-${userId}`;
}

export function getRoleLabel(projectMembership: ProjectMembership): Label {
  if (projectMembership.role === ROLE_ADMIN) {
    if (projectMembership.fromOrganization) {
      return LABELS.ADMIN;
    }
    return LABELS.PROJECT_ADMIN;
  }

  if (projectMembership.isOrganizationGuest) {
    return LABELS.GUEST;
  }

  return LABELS.MEMBER;
}
