// @flow
import { guard, string } from "decoders";
import window from "core/global/window";
import { reportError } from "core/lib/sentry";
import { RECURLY_PUBLIC_API_KEY } from "web/config";
import { getToken as getAccessToken } from "web/token";
import version from "web/version";

const inputDecoder = guard(string);

export default {
  version,
  platform: "web",
  apiURL: inputDecoder(process.env.ABSTRACT_API_URL),
  iframelyApiKey: inputDecoder(process.env.IFRAMELY_API_KEY),
  recurlyPublicApiKey: RECURLY_PUBLIC_API_KEY,
  getAppURL: async () => inputDecoder(process.env.ABSTRACT_APP_URL),
  getPreviewsURL: async () => inputDecoder(process.env.ABSTRACT_PREVIEWS_URL),
  getAuthURL: async () => inputDecoder(process.env.ABSTRACT_AUTH_URL),
  getZendeskURL: async () => inputDecoder(process.env.ZENDESK_URL),
  getClientId: async () => inputDecoder(process.env.ABSTRACT_AUTH_CLIENT_ID),
  reload: () => window.location.reload(),
  getAccessToken,
  reportError,
  openExternal: (url: string) => window.open(url),
};
