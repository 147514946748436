// @flow
import queryString from "query-string";
import * as React from "react";
import AbstractAppButton from "abstract-di/components/AbstractAppButton";
import style from "./style.scss";
import Empty from ".";

export default function NoProjectsUsernameOrg({
  organizationId,
  ...rest
}: {
  organizationId: string,
}) {
  return (
    <Empty
      {...rest}
      image={<div className={style.projectImage} />}
      title="No projects, yet."
      description={<span>Open the Abstract app to create a Project</span>}
    >
      <AbstractAppButton
        external
        medium
        primary
        abstractUrl={`abstract://app/share?${queryString.stringify({
          organizationId,
          kind: "organization",
        })}`}
      />
    </Empty>
  );
}
