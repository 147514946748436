// @flow
import { get } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import * as Request from "core/models/request";
import type { Dispatch, State as ReduxState } from "core/types";
import { loadEmails, newEmailFormSubmit } from "web/actions/emails";
import EmailsEdit, {
  type ComponentProps,
} from "web/components/ProfileSettings/EmailsEdit";
import { getVerifiedEmails, getUnverifiedEmails } from "web/selectors/emails";
import { getEmailsRequest } from "web/selectors/requests";
import { getCurrentUser } from "web/selectors/session";

type OwnProps = {
  location: { state?: { verifiedEmail?: { email: string } } },
};

type Props = ComponentProps &
  OwnProps & {
    error: boolean,
    isLoading: boolean,
    onMount: () => void,
  };

type State = { verifiedEmail?: string };

class EmailsEditContainer extends React.Component<Props, State> {
  state = { verifiedEmail: undefined };

  componentDidMount() {
    this.props.onMount();

    const verifiedEmail = get(
      this.props.location,
      "state.verifiedEmail.email",
      ""
    );

    this.setState({ verifiedEmail });
  }

  render() {
    return (
      <EmailsEdit {...this.props} verifiedEmail={this.state.verifiedEmail} />
    );
  }
}

function mapStateToProps(state: ReduxState, props: OwnProps) {
  const request = getEmailsRequest(state);

  return {
    error: Request.hasError(request),
    isLoading: Request.isLoading(request),
    account: getCurrentUser(state),
    verifiedEmails: getVerifiedEmails(state),
    unverifiedEmails: getUnverifiedEmails(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch, props: OwnProps) {
  return {
    onMount: () => {
      dispatch(loadEmails());
    },
    onSubmit: (id, values) => {
      dispatch(newEmailFormSubmit(id, values));
    },
  };
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailsEditContainer);
