// @flow
import classnames from "classnames";
import * as React from "react";
import Icon from "core/components/Icon";
import allSVGs from "core/components/Icon/allSvgs";
import Popover from "core/components/Popover";
import DefaultUserAvatar from "./DefaultUserAvatar";
import connector from "./connector";
import style from "./style.scss";

function getSizeStyles(size?: string | number) {
  if (typeof size === "string") {
    return {
      width: size,
      height: size,
    };
  } else if (typeof size === "number") {
    return {
      width: `${size}px`,
      height: `${size}px`,
    };
  }
}

export function Placeholder({
  className,
  size,
}: {
  className?: string,
  size?: string | number,
}) {
  const extraStyle = getSizeStyles(size);

  return (
    <div
      className={classnames(className, style.placeholderAvatar)}
      style={extraStyle}
    />
  );
}

const cache = {};

const POPOVER_MODIFIERS = {
  flip: { enabled: true },
};

export type AvatarBorderColor = "orange" | "green" | "blurple";

export type OwnProps = {|
  userId?: string,
  large?: boolean,
  src?: string,
  name?: string,
  url?: ?string,
  stretch?: boolean,
  className?: string,
  style?: Object,
  size?: string | number,
  badgeIcon?: string,
  deletedComment?: boolean,
  borderColor?: AvatarBorderColor,
  backgroundColor?: "blurple" | "transparent",
  showNamePopover?: boolean,
  qaSelector?: string,
|};

export type StateProps = {|
  userId: string,
  userName: string,
  src: string,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
};

type State = { src: ?string, isLoading: boolean };

class Avatar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const src = props.src;
    this.state = {
      src,
      isLoading: src ? !cache[src] : false,
    };
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    // if the user gets a new avatar (perhaps they changed it?) then we should
    // try loading it again
    if (state.src !== props.src) {
      const src = props.src;
      return {
        src,
        isLoading: src ? !cache[src] : false,
      };
    }

    return null;
  }

  maybeWrapWithPopover = (component: React.Node) => {
    if (this.props.showNamePopover) {
      return (
        <Popover
          delayShow={750}
          label={this.props.userName}
          modifiers={POPOVER_MODIFIERS}
          offset="0, 8px"
          placement="top"
        >
          {component}
        </Popover>
      );
    }

    return component;
  };

  onLoad = () => {
    if (this.state.src) {
      cache[this.state.src] = true;

      if (this.state.isLoading) {
        this.setState({ isLoading: false });
      }
    }
  };

  render() {
    const { size, stretch, large, borderColor, badgeIcon, deletedComment } =
      this.props;

    const displayDefaultAvatar =
      (this.state.isLoading || !this.state.src) && !deletedComment;

    const classes = classnames(
      this.props.className,
      style.avatar,
      borderColor ? style[borderColor] : undefined,
      {
        [style.stretch]: stretch,
        [style.large]: large,
        [style.ring]: !!borderColor,
        [style.defaultAvatar]: displayDefaultAvatar,
        [style.avatarBackgroundBlurple]:
          this.props.backgroundColor === "blurple",
      }
    );

    const extraStyle = {
      ...this.props.style,
      backgroundSize: "cover",
      ...getSizeStyles(size),
    };

    if (deletedComment) {
      const TrashIcon = allSVGs["icon-trash.svg"];

      return (
        <span style={extraStyle} className={classes}>
          <TrashIcon
            className={style.deletedCommentAvatar}
            fill="currentColor"
            height="100%"
            width="100%"
            data-qa={this.props.qaSelector}
          />
        </span>
      );
    }

    return (
      <React.Fragment>
        {displayDefaultAvatar &&
          this.maybeWrapWithPopover(
            <DefaultUserAvatar
              style={extraStyle}
              className={classes}
              userId={this.props.userId}
              name={this.props.userName}
              size={this.props.size}
              qaSelector={this.props.qaSelector}
            />
          )}

        {this.state.src &&
          this.maybeWrapWithPopover(
            <img
              style={extraStyle}
              src={this.state.src}
              onLoad={this.onLoad}
              alt={this.props.userName}
              className={classnames(
                classes,
                this.state.isLoading ? style.hidden : style.image
              )}
              role="presentation"
              data-qa={this.props.qaSelector}
              data-qa-user-name={
                this.props.qaSelector ? this.props.userName : undefined
              }
            />
          )}

        {badgeIcon && (
          <Icon
            type={badgeIcon}
            className={classnames(
              style.badge,
              borderColor ? style[borderColor] : undefined
            )}
            light
          />
        )}
      </React.Fragment>
    );
  }
}

export const Component = Avatar;

export default connector(Avatar);
