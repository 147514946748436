// @flow
import { connect } from "react-redux";
import { getBranch } from "core/selectors/branches";
import {
  getCurrentOrganization,
  getDefaultOrganization,
} from "core/selectors/organizations";
import { getProject } from "core/selectors/projects";
import type { State, Dispatch } from "core/types";
import type { OwnProps, StateProps, Props } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { branchId, organizationId, projectId } = props;

  return {
    branch:
      projectId && branchId ? getBranch(state, { projectId, branchId }) : null,
    defaultOrganization: getDefaultOrganization(state),
    organization: getCurrentOrganization(state, {
      organizationId: organizationId,
      projectId,
    }),
    project: projectId ? getProject(state, { projectId }) : null,
  };
}

export default connect<Props, OwnProps, StateProps, _, State, Dispatch>(
  mapStateToProps
);
