// @flow
import * as Abstract from "abstract-sdk";
import { connect } from "react-redux";
import {
  WebhookEventsFetchRequest,
  WebhookCreateRequest,
  WebhooksFetchRequest,
} from "core/requests/webhooks";
import { getFeatureEnabled } from "core/selectors/features";
import { getOrganizationSettings } from "core/selectors/organizationSettings";
import { getWebhookEvents, getWebhooks } from "core/selectors/webhooks";
import type { Dispatch, NewWebhook, State } from "core/types";
import type { Props, RouterProps, DispatchProps, StateProps } from "./";

function mapStateToProps(state: State, props: RouterProps): StateProps {
  const organizationId = props.params.organizationId;
  const requestArgs = [state, { organizationId }];
  const settings = getOrganizationSettings(state, { organizationId });

  return {
    canViewWebhooks: getFeatureEnabled(state, { feature: "webhooks-ui" }),
    createWebhookError: WebhookCreateRequest.hasError(...requestArgs),
    createWebhookLoading: WebhookCreateRequest.isLoadingStrict(...requestArgs),
    fetchWebhooksLoading:
      WebhookEventsFetchRequest.isLoading(...requestArgs) ||
      WebhooksFetchRequest.isLoading(...requestArgs),
    slackIntegration: settings ? settings.slackIntegration : undefined,
    webhookEvents: getWebhookEvents(...requestArgs),
    webhooks: getWebhooks(...requestArgs),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: RouterProps
): DispatchProps {
  return {
    fetchWebhooks: (params: Abstract.OrganizationDescriptor) => {
      dispatch(WebhooksFetchRequest.perform({ params }));
    },
    fetchWebhookEvents: (params: Abstract.OrganizationDescriptor) => {
      dispatch(WebhookEventsFetchRequest.perform({ params }));
    },
    createWebhook: (params: NewWebhook) => {
      return dispatch(WebhookCreateRequest.perform({ params }));
    },
  };
}

export default connect<
  Props,
  RouterProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
