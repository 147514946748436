// @flow
import map from "lodash/map";
import * as React from "react";
import ManageReviewersButton from "core/components/BranchReviewDialog/ManageReviewers/Button";
import BranchReviewForm from "core/components/BranchReviewForm";
import Flex from "core/components/Flex";
import Heading from "core/components/Heading";
import SidebarHeading from "core/components/SidebarHeading";
import type { ReviewRequest, BranchReview, Branch } from "core/types";
import SidebarReviewerItem from "./SidebarReviewerItem";
import connector from "./connector";
import style from "./style.scss";

type Props = {
  projectId: string,
  branch: Branch,
  branchReview?: BranchReview,
  reviewRequests: ReviewRequest[],
  requiredReviewerIds: string[],
  isMergeable: boolean,
  isMobile?: boolean,
  isTablet?: boolean,
  isOffline: boolean,
};

class BranchReviewSummary extends React.Component<Props> {
  renderHeading() {
    const { branch, projectId, isOffline, isMobile, isTablet } = this.props;

    return isMobile || isTablet ? (
      <Flex align="center" justify="space-between" className={style.heading}>
        <Heading level="3" size="l">
          Reviewers
        </Heading>
        <Flex align="center" justify="flex-end">
          <ManageReviewersButton
            branch={branch}
            branchId={branch.id}
            projectId={projectId}
            isOffline={isOffline}
          />
          {isMobile && (
            <BranchReviewForm
              branch={branch}
              projectId={projectId}
              isMobile={isMobile}
              reviewButtonClassName={style.reviewButton}
            />
          )}
        </Flex>
      </Flex>
    ) : (
      <SidebarHeading size="l" dynamicPadding>
        <Flex align="center" justify="space-between">
          Reviewers
          <ManageReviewersButton
            branch={branch}
            branchId={branch.id}
            projectId={projectId}
            isOffline={isOffline}
          />
        </Flex>
      </SidebarHeading>
    );
  }

  render() {
    const { isMergeable, reviewRequests, requiredReviewerIds } = this.props;
    const isEmpty = reviewRequests.length === 0;
    const requiredReviewersMap = new Map(
      requiredReviewerIds.map((r) => [r, r])
    );

    return (
      <div className={style.mobileWrapper}>
        {this.renderHeading()}
        {isEmpty ? (
          <div className={style.empty}>No reviewers added…</div>
        ) : (
          <div>
            {map(reviewRequests, (reviewRequest) => {
              return (
                <SidebarReviewerItem
                  key={reviewRequest.id}
                  status={reviewRequest.status}
                  reviewer={reviewRequest.reviewer}
                  required={
                    isMergeable
                      ? false
                      : requiredReviewersMap.has(reviewRequest.reviewer.id)
                  }
                />
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

export default connector(BranchReviewSummary);
