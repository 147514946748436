// @flow
import * as React from "react";
import Media from "core/components//Media";
import Button from "core/components/Button";
import DialogForm from "core/components/DialogForm";
import Error from "core/components/Empty/Error";
import FormHeader from "core/components/FormHeader";
import FormSection from "core/components/FormSection";
import Input from "core/components/Input";
import Loaded from "core/components/Loaded";
import Note from "core/components/Note";
import SettingsForm from "core/components/SettingsForm";
import withForm from "../../containers/withForm";
import type { FormProps, Email } from "../../types";
import EmailListItem from "../EmailListItem";

export type ComponentProps = {
  verifiedEmails: Email[],
  unverifiedEmails: Email[],
  onSubmit: (formId: string, values: Object) => *,
  verifiedEmail?: string,
  isLoading: boolean,
  error: boolean,
};

type Props = { ...ComponentProps, ...FormProps };
type State = {
  newEmailDialogOpen: boolean,
  successMessage: string,
  showSuccess: boolean,
};

class EmailsEdit extends React.Component<Props, State> {
  state = {
    newEmailDialogOpen: false,
    successMessage: "",
    showSuccess: false,
  };

  formHeader: FormHeader;

  componentDidMount() {
    if (this.props.verifiedEmail) {
      this.setState({
        showSuccess: true,
        successMessage: `Email address verified: ${this.props.verifiedEmail}`,
      });
    }
  }

  toggleNewEmailDialog = () => {
    this.setState((state) => ({
      newEmailDialogOpen: !state.newEmailDialogOpen,
    }));
    this.props.form.onChange(this.props.form.id, { email: "" });
  };

  handleSubmitNewEmail = (event: SyntheticEvent<>) => {
    event.preventDefault();
    const { id, values } = this.props.form;
    this.props.onSubmit(id, values);
  };

  handleNewEmailInputChange = ({ target }: SyntheticInputEvent<>) => {
    this.props.form.onChange(this.props.form.id, {
      [target.name]: target.value,
    });
  };

  componentDidUpdate = (prevProps: Props) => {
    if (
      this.props.form.submitRequestSucceeded &&
      !prevProps.form.submitRequestSucceeded
    ) {
      this.toggleNewEmailDialog();
    }
  };

  renderDialog() {
    const { form } = this.props;

    return (
      <DialogForm
        isOpen={this.state.newEmailDialogOpen}
        onClose={this.toggleNewEmailDialog}
        onSubmit={this.handleSubmitNewEmail}
        disabled={form.isSubmitting}
        loading={form.isSubmitting}
        primaryButton="Send Confirmation Email"
        title="Add email address"
      >
        <Input
          autoFocus
          label="Email"
          autoComplete="off"
          name="email"
          value={form.values.email}
          disabled={form.isSubmitting}
          onChange={this.handleNewEmailInputChange}
          error={form.errors.email}
          qaSelector="addEmailInput"
        />
        <Note>
          Once this email address is confirmed you’ll be able to use it to sign
          in to this Abstract account.
        </Note>
      </DialogForm>
    );
  }

  render() {
    const { verifiedEmails, unverifiedEmails, isLoading, error } = this.props;
    const isEmpty = !verifiedEmails.length && !unverifiedEmails.length;
    const disabled = error || isEmpty || isLoading;
    const header = (
      <Media desktop>
        {(desktop) => (
          <FormHeader
            mobile={!desktop}
            heading="Email Addresses"
            icon="mail"
            success={this.state.showSuccess}
            successMessage={this.state.successMessage}
          >
            <Button
              primary
              onClick={this.toggleNewEmailDialog}
              disabled={disabled}
            >
              Add Email
            </Button>

            {this.state.newEmailDialogOpen && this.renderDialog()}
          </FormHeader>
        )}
      </Media>
    );

    return (
      <SettingsForm documentTitle="Emails" header={header}>
        <Loaded loading={isLoading} flex title="Loading emails…">
          {() => {
            if (isEmpty || error) {
              return <Error flex />;
            }
            return (
              <React.Fragment>
                {unverifiedEmails.length > 0 && (
                  <FormSection heading="Awaiting Confirmation">
                    {unverifiedEmails.map((email) => (
                      <EmailListItem key={email.id} email={email} />
                    ))}
                  </FormSection>
                )}

                {verifiedEmails.length > 0 && (
                  <FormSection heading="Confirmed">
                    {verifiedEmails.map((email) => (
                      <EmailListItem key={email.id} email={email} />
                    ))}
                  </FormSection>
                )}
              </React.Fragment>
            );
          }}
        </Loaded>
      </SettingsForm>
    );
  }
}

export default withForm<ComponentProps>(EmailsEdit, `create-email-form`);
