// @flow
import classnames from "classnames";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "core/components/Button";
import Flex from "core/components/Flex";
import Loaded from "core/components/Loaded";
import Media from "core/components/Media";
import OnboardingContentSheet from "core/components/OnboardingContentSheet";
import OnboardingHeading from "core/components/OnboardingHeading";
import onboardingStyle from "core/components/onboarding.scss";
import { isMac } from "core/lib/platform";
import { V3Link as Link } from "core/lib/router";
import * as Routes from "core/lib/routes";
import { OrganizationFetchRequest } from "core/requests/organizations";
import { ProjectsFetchRequest } from "core/requests/projects";
import { getOrganization } from "core/selectors/organizations";
import OnboardingPage from "web/components/Onboarding/Page";
import * as WebRoutes from "web/routeHelpers";
import { getAbstractUrl } from "web/selectors/abstractURLs";
import style from "./style.scss";

export default function InvitationConfirmation() {
  const invitation = useSelector((state) => state.invitation.entity);

  const { organizationId, projectId } = invitation;

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(OrganizationFetchRequest.perform({ params: { organizationId } }));
    dispatch(
      ProjectsFetchRequest.perform({
        params: { organizationId, type: "cloud" },
      })
    );
  }, [dispatch, organizationId]);

  const organization = useSelector((state) =>
    getOrganization(state, { organizationId })
  );
  const desktopAppUrl = useSelector((state) =>
    getAbstractUrl(state, {
      routeId: projectId ? "project" : "organization",
      organizationId,
      projectId,
    })
  );

  const destinationRoute = projectId
    ? Routes.projectPath(projectId)
    : Routes.organizationHomePath(organizationId);

  const isLoadingOrganization = useSelector((state) =>
    OrganizationFetchRequest.isLoading(state, { organizationId })
  );

  return (
    <Media mobile>
      {(mobile) => (
        <OnboardingPage>
          <OnboardingContentSheet className={onboardingStyle.mainContentSheet}>
            <Loaded loading={isLoadingOrganization} flex>
              <Flex className={onboardingStyle.centeredText} column>
                <OnboardingHeading level="1">You’re in!</OnboardingHeading>
                <div
                  className={classnames(
                    onboardingStyle.subHeading,
                    onboardingStyle.largeCopy
                  )}
                >
                  You now have access to the{" "}
                  {invitation.projectName && (
                    <React.Fragment>
                      <span className={style.name}>
                        {invitation.projectName}
                      </span>{" "}
                      project
                      {!invitation.isUsernameOrganization && " within the "}
                    </React.Fragment>
                  )}
                  {!invitation.isUsernameOrganization && (
                    <React.Fragment>
                      <span className={style.name}>
                        {invitation.organizationName}
                      </span>{" "}
                      organization
                    </React.Fragment>
                  )}
                  .
                </div>
                {organization ? (
                  <div className={style.versionsCopy}>
                    {isMac() ? (
                      <div className={onboardingStyle.mediumCopy}>
                        If you already have{" "}
                        <Link to={WebRoutes.downloadApp()}>
                          Abstract for macOS
                        </Link>{" "}
                        installed, open the app and{" "}
                        <span className={style.name}>
                          {invitation.organizationName}
                        </span>{" "}
                        will be in your organization switcher.
                      </div>
                    ) : null}
                    <div className={style.buttons}>
                      {isMac() ? (
                        <Button
                          className={classnames(
                            style.launchButton,
                            onboardingStyle.button
                          )}
                          component="a"
                          href={desktopAppUrl}
                          external
                          fullwidth
                          large
                          primary
                        >
                          Launch Abstract on macOS
                        </Button>
                      ) : null}
                      <Button
                        className={onboardingStyle.button}
                        component={Link}
                        to={destinationRoute}
                        fullwidth
                        large
                      >
                        Continue to the web app
                      </Button>
                    </div>
                  </div>
                ) : (
                  // Default to redirecting to the web app if we can't load
                  // the organization
                  <div className={style.buttons}>
                    <Button
                      className={onboardingStyle.button}
                      component={Link}
                      to={
                        projectId
                          ? Routes.projectPath(projectId)
                          : Routes.organizationHomePath(organizationId)
                      }
                      fullwidth
                      large
                    >
                      Continue to the web app
                    </Button>
                  </div>
                )}
              </Flex>
            </Loaded>
          </OnboardingContentSheet>
        </OnboardingPage>
      )}
    </Media>
  );
}
