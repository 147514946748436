// @flow
import classnames from "classnames";
import * as React from "react";
import CommitTitle from "core/components/CommitTitle";
import FloatingControl from "core/components/FloatingControl";
import Time from "core/components/Time";
import anyHover from "core/lib/anyHover";
import { isWeb } from "core/lib/platform";
import type {
  Annotation,
  CommentContext,
  FormState,
  User,
  PublicUser,
  UnknownUser,
  Comment,
} from "core/types";
import Avatar, { type AvatarBorderColor } from "../Avatar";
import Button, { type ButtonElement } from "../Button";
import CommentEditForm from "../CommentEditForm";
import Flex from "../Flex";
import Markdown from "../Markdown";
import ResolvedCommentHeader from "./ResolvedCommentHeader";
import style from "./style.scss";

const { Suspense, lazy } = React;

const AddReaction = lazy(() => import("./AddReaction.js"));
const ReactionBar = lazy(() => import("../ReactionBar/index.js"));

export type Props = {
  id: string,
  user: User | PublicUser | UnknownUser,
  currentUser: ?User,
  context: CommentContext,
  users: User[],
  annotation: Annotation,
  branchId: string,
  branchName?: ?string,
  branchId?: string,
  deletedAt?: string,
  commitSha?: string,
  commitTitle: ?string,
  projectName?: string,
  fileId?: string,
  fileName: ?string,
  pageId?: string,
  pageName: ?string,
  parentId?: string,
  projectId: string,
  layerId?: string,
  layerName: ?string,
  body: ?string,
  isAuthor: boolean,
  isEditing: boolean,
  isSelected: boolean,
  isHighlighted: boolean,
  forceHighlight?: boolean,
  createdAt: string,
  editedAt: string,
  canAddReactions: boolean,
  className?: string,
  menuRef?: React.Ref<ButtonElement>,
  innerRef?: React.Ref<"div">,
  formRef?: React.Ref<"form">,
  online: boolean,
  onClick: (SyntheticEvent<>) => void,
  onSelected?: (id: string) => void,
  onContextMenu?: (SyntheticEvent<>) => void,
  onChange: (formId: string, params: Object) => void,
  onCancel: () => void,
  onRetry: () => void,
  onRequestAnnotationRemoval?: Function,
  onResetEdit: () => void,
  onUpdate: (formId: string, params: Object) => void,
  onReaction: (name: string) => void,
  onClickReply: () => void,
  formState: FormState,
  avatarBadgeIcon?: string,
  avatarBorderColor?: AvatarBorderColor,
  pending?: boolean,
  error?: boolean,
  disabled?: boolean,
  preview?: React.Node,
  onPinComment?: (commentId: string) => void,
  onUnpinComment?: (commentId: string) => void,
  pinnedAt?: string,
  pinnedByUserId?: string,
  canPin?: boolean,
  pinHasError?: boolean,
  unpinHasError?: boolean,
  resolveCommentsEnabled?: boolean,
  canResolve?: boolean,
  onResolveComment?: (commentId: string) => void,
  isResolved?: boolean,
  displayOnlyPinnedOptions?: boolean,
  comment?: Comment,
  onPinnedCommentClick?: () => void,
  tintBackgroundOnHover?: boolean,
  isPinning?: boolean,
  isUnpinning?: boolean,
  isResolving?: boolean,
  qaSelector?: string,
};

type BaseProps = Props & {
  meta: React.Node,
  title: ?React.Node,
  showMenu: boolean,
  showAvatar: boolean,
};

export default class CommentBase extends React.Component<BaseProps> {
  static defaultProps = {
    user: {
      name: "Unknown",
      avatarUrl: "",
      id: "",
    },
    showMenu: false,
    showAvatar: true,
  };

  handlePinOrUnpin = () => {
    if (this.props.pinnedAt && this.props.pinnedByUserId) {
      this.props.onUnpinComment && this.props.onUnpinComment(this.props.id);
    } else {
      this.props.onPinComment && this.props.onPinComment(this.props.id);
    }
  };

  handleResolve = () => {
    this.props.onResolveComment && this.props.onResolveComment(this.props.id);
  };

  canShowMenu = (): boolean => {
    return !!(
      this.props.showMenu &&
      this.props.onContextMenu &&
      !this.props.disabled &&
      !this.props.deletedAt &&
      !this.props.displayOnlyPinnedOptions
    );
  };

  shouldShowPinnedButton = (): boolean => {
    const canPin = !!this.props.canPin && !this.props.isResolved;

    if (!anyHover) {
      return canPin && !!this.props.pinnedAt;
    }

    return canPin;
  };

  renderError() {
    if (this.props.disabled) {
      return null;
    }

    return (
      <React.Fragment>
        There was an error creating this comment.
        <span className={style.dot}>{"·"}</span>
        <Button
          nude
          tint
          onClick={this.props.onRetry}
          className={style.retryButton}
        >
          Retry
        </Button>
        <Button nude tint onClick={this.props.onCancel}>
          Cancel
        </Button>
      </React.Fragment>
    );
  }

  renderForm() {
    return (
      <CommentEditForm
        innerRef={this.props.formRef}
        formId={this.props.id}
        annotation={this.props.formState.annotation}
        body={this.props.formState.body || ""}
        hasError={this.props.formState.hasError}
        isLoading={this.props.formState.isLoading}
        isAnnotatable={!!this.props.formState.annotation}
        onRequestAnnotationRemoval={this.props.onRequestAnnotationRemoval}
        onChange={this.props.onChange}
        onReset={this.props.onResetEdit}
        onUpdate={this.props.onUpdate}
        users={this.props.users}
        online={this.props.online}
        projectId={this.props.projectId}
      />
    );
  }

  render() {
    const isParent = !this.props.parentId;
    const classes = classnames(style.comment, this.props.className, {
      [style.reply]: this.props.parentId,
      [style.highlighted]: this.props.isHighlighted,
      [style.forceHighlight]: this.props.forceHighlight,
      [style.pending]: this.props.pending,
      [style.editing]: this.props.isEditing,
      [style.deleted]: this.props.deletedAt,
      [style.disabled]: this.props.disabled,
      [style.selected]: this.props.isSelected && !this.props.disabled,
      [style.resolved]: this.props.isResolved,
      [style.tintBackgroundOnHover]: this.props.tintBackgroundOnHover,
    });

    const disablePinAndResolveActions =
      this.props.isPinning || this.props.isUnpinning || this.props.isResolving;

    return (
      <li
        className={style.container}
        data-qa={this.props.qaSelector || "commentContainer"}
      >
        {this.props.isResolved &&
          !this.props.parentId &&
          this.props.comment && (
            <ResolvedCommentHeader
              comment={this.props.comment}
              className={style.resolvedHeader}
            />
          )}
        <div
          key={this.props.id}
          ref={this.props.innerRef}
          className={classes}
          onContextMenu={
            isWeb || !this.canShowMenu() ? undefined : this.props.onContextMenu
          }
        >
          {this.props.showAvatar && (
            <Avatar
              size={this.props.parentId ? 16 : 32}
              className={style.avatar}
              userId={this.props.user.id}
              badgeIcon={this.props.avatarBadgeIcon}
              borderColor={this.props.avatarBorderColor}
              deletedComment={!!this.props.deletedAt}
            />
          )}
          {this.props.isEditing ? (
            this.renderForm()
          ) : (
            <div className={style.body}>
              <div className={style.title}>{this.props.title}</div>
              {!this.props.deletedAt && this.props.body && (
                <Markdown
                  text={this.props.body}
                  className={style.markdown}
                  mentionableUsers={this.props.users}
                />
              )}
              {!this.props.displayOnlyPinnedOptions && (
                <div className={style.reactions}>
                  <Suspense fallback={null}>
                    <ReactionBar
                      commentId={this.props.id}
                      disabled={
                        !!this.props.deletedAt ||
                        !this.props.online ||
                        !this.props.currentUser
                      }
                    />
                  </Suspense>
                </div>
              )}
              {this.props.preview}
              <span className={style.meta}>
                {this.props.error ? this.renderError() : null}
                {!this.props.displayOnlyPinnedOptions ? this.props.meta : null}
                {this.props.displayOnlyPinnedOptions ? (
                  <div className={style.pinnedMeta}>
                    <Time
                      date={this.props.createdAt}
                      className={style.pinnedMetaItem}
                    />
                    <span className={style.pinnedMetaItem}>·</span>
                    {this.props.commitTitle && (
                      <span label={this.props.commitTitle}>
                        <CommitTitle title={this.props.commitTitle} />
                      </span>
                    )}
                  </div>
                ) : null}
                {this.props.canAddReactions &&
                  !this.props.displayOnlyPinnedOptions && (
                    <Flex inline align="center">
                      <span className={style.dot}>{"·"}</span>
                      <div className={style.reactionButton}>
                        <Suspense
                          fallback={
                            <Button nude icon="emoji-response" disabled />
                          }
                        >
                          <AddReaction
                            commentId={this.props.id}
                            onSelect={this.props.onReaction}
                            isOnline={this.props.online}
                          />
                        </Suspense>
                      </div>
                    </Flex>
                  )}
                {!this.props.pending &&
                  isParent &&
                  !!this.props.currentUser &&
                  !this.props.displayOnlyPinnedOptions && (
                    <Flex inline align="center" className={style.replyToggle}>
                      <span className={style.dot}>{"·"}</span>
                      <Button
                        nude
                        tint
                        disabled={this.props.disabled}
                        className={style.replyButton}
                        onClick={this.props.onClickReply}
                        qaSelector="replyButton"
                      >
                        Reply
                      </Button>
                    </Flex>
                  )}
              </span>
            </div>
          )}
          {this.canShowMenu() && (
            <span className={style.menu} onClick={(e) => e.stopPropagation()}>
              <FloatingControl className={style.floatingControl}>
                <React.Fragment>
                  {this.shouldShowPinnedButton() && (
                    <Button
                      nude
                      icon={
                        this.props.pinnedAt && this.props.pinnedByUserId
                          ? "pinned"
                          : "pin"
                      }
                      className={classnames(style.actions, style.pinButton)}
                      innerRef={this.props.menuRef}
                      onClick={this.handlePinOrUnpin}
                      disabled={disablePinAndResolveActions}
                      iconFill={
                        this.props.forceHighlight ? "#c1a311" : undefined
                      }
                      title={
                        this.props.pinnedAt && this.props.pinnedByUserId
                          ? "Unpin comment"
                          : "Pin comment"
                      }
                      tooltip={{
                        placement: "top",
                      }}
                    />
                  )}
                  {!this.props.isResolved &&
                    this.props.resolveCommentsEnabled &&
                    this.props.canResolve &&
                    anyHover && (
                      <Button
                        nude
                        icon="checkmark"
                        className={classnames(
                          style.actions,
                          style.resolveButton
                        )}
                        innerRef={this.props.menuRef}
                        onClick={this.handleResolve}
                        disabled={disablePinAndResolveActions}
                        title="Resolve comment"
                        tooltip={{
                          placement: "top",
                        }}
                      />
                    )}
                  <Button
                    nude
                    icon="overflow"
                    className={style.actions}
                    innerRef={this.props.menuRef}
                    onClick={this.props.onContextMenu}
                    disabled={this.props.disabled}
                    qaSelector="commentOverflowMenuButton"
                  />
                </React.Fragment>
              </FloatingControl>
            </span>
          )}
        </div>
      </li>
    );
  }
}
