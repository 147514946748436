// @flow
import classnames from "classnames";
import { findIndex } from "lodash";
import * as React from "react";
import { useDispatch } from "react-redux";
import { trackOrganization } from "core/actions/analytics";
import {
  createNewOrganization,
  getTransactionError,
} from "core/actions/organizations";
import { recurlyReset, createPaymentToken } from "core/actions/recurly";
import Button from "core/components/Button";
import billingModalStyle from "core/components/CreateOrganization/style.scss";
import DialogForm from "core/components/DialogForm";
import ExternalLink from "core/components/ExternalLink";
import Flex from "core/components/Flex";
import Input from "core/components/Input";
import InputCheckbox from "core/components/InputCheckbox";
import InputPhone, { type InputPhoneValue } from "core/components/InputPhone";
import OnboardingContentSheet from "core/components/OnboardingContentSheet";
import OnboardingHeading from "core/components/OnboardingHeading";
import PriceCalculation from "core/components/PriceCalculation";
import RecurlyBillingInfoForm from "core/components/RecurlyBillingInfoForm";
import Select from "core/components/Select";
import SerifText from "core/components/SerifText";
import onboardingStyle from "core/components/onboarding.scss";
import { push } from "core/lib/location";
import { prices } from "core/lib/subscriptions";
import { trialAgreementUrl } from "core/lib/urls";
import type { ReactRouterLocation } from "core/types";
import OnboardingPage from "web/components/Onboarding/Page";
import * as WebRoutes from "web/routeHelpers";
import style from "./style.scss";

const NUM_DESIGNERS_OPTIONS = [
  { label: "— Please select —", value: "" },
  { label: "1", value: "1" },
  { label: "2-5", value: "2-5" },
  { label: "6-10", value: "6-10" },
  { label: "11-50", value: "11-50" },
  { label: "51-100", value: "51-100" },
  { label: "100+", value: "100+" },
];

const DEFAULT_NUM_DESIGNERS_VALUE = NUM_DESIGNERS_OPTIONS[0].value;
const MINIMUM_INDEX_TO_REQUEST_PHONE_NUMBER = 4; // "11-50"

type Props = {
  location: ReactRouterLocation,
};

export default function CreateOrganization(props: Props) {
  const dispatch = useDispatch();

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [openPaymentModal, setOpenPaymentModal] = React.useState(false);

  const [organizationName, setOrganizationName] = React.useState("");
  const [totalDesignersSizeRange, setTotalDesignersSizeRange] = React.useState(
    DEFAULT_NUM_DESIGNERS_VALUE
  );
  const [phoneNumber, setPhoneNumber] = React.useState("");

  const [shouldShowPhoneNumber, setShouldShowPhoneNumber] =
    React.useState(false);
  const [isPhoneNumberInvalid, setIsPhoneNumberInvalid] = React.useState(false);
  const [recurlyTokenId, setRecurlyTokenId] = React.useState<?string>();

  const trialPrice = prices.proMonthlyPrice / 100;

  const handleOrganizationNameChange = React.useCallback(
    (event: SyntheticInputEvent<>) => {
      setOrganizationName(event.target.value);
    },
    []
  );

  const handleTotalDesignersSizeRangeChange = React.useCallback(
    (event: SyntheticInputEvent<>) => {
      setTotalDesignersSizeRange(event.target.value);

      const index = findIndex(NUM_DESIGNERS_OPTIONS, {
        value: event.target.value,
      });

      const showPhoneNumber = index >= MINIMUM_INDEX_TO_REQUEST_PHONE_NUMBER;
      setShouldShowPhoneNumber(showPhoneNumber);

      if (!showPhoneNumber) {
        setPhoneNumber("");
        setIsPhoneNumberInvalid(false);
      }
    },
    []
  );

  const handlePhoneNumberChange = React.useCallback(
    (phoneNumber: InputPhoneValue) => {
      setPhoneNumber(phoneNumber.value || "");
      setIsPhoneNumberInvalid(phoneNumber.invalid);
    },
    []
  );

  const isOnboarding = props.location.state && props.location.state.onboarding;

  const handlePaymentModalClose = React.useCallback(
    (event: SyntheticInputEvent<>) => {
      event.preventDefault();
      setOpenPaymentModal(false);
      setIsSubmitting(false);
      dispatch(recurlyReset());
    },
    [dispatch]
  );

  const attemptToCreateNewOrganization = React.useCallback(
    (
      recurlyTokenId: ?string,
      recurlyActionResultToken?: string,
      accountCode: ?string
    ) => {
      dispatch(
        createNewOrganization(
          {
            name: organizationName,
            totalDesignersSizeRange,
            phoneNumber,
            requestId: "signup-create-organization-form",
            source: "onboarding",
            onboarding: true,
            recurlyTokenId,
            recurlyActionResultToken,
            accountCode,
          },
          (response) => {
            const organization = response.data;

            dispatch(
              trackOrganization(organization, { totalDesignersSizeRange })
            );
            push({
              pathname: WebRoutes.signupInviteTeammates(),
              state: { onboarding: true, organizationId: organization.id },
            });
          },
          (error) => {
            setIsSubmitting(false);
            if (!getTransactionError(error)) {
              setErrorMessage(
                "Something went wrong while creating your Organization. Try again?"
              );
              setOpenPaymentModal(false);
            }
          }
        )
      );
    },
    [dispatch, organizationName, phoneNumber, totalDesignersSizeRange]
  );

  const handle3DSecureChallengeError = React.useCallback((error: Error) => {
    setIsSubmitting(false);
  }, []);

  const handle3DSecureChallengeCompleted = React.useCallback(
    (actionResultToken: string, accountCode: ?string) => {
      attemptToCreateNewOrganization(
        recurlyTokenId,
        actionResultToken,
        accountCode
      );
    },
    [attemptToCreateNewOrganization, recurlyTokenId]
  );

  const handlePaymentModalSubmit = React.useCallback(
    (event: SyntheticInputEvent<>) => {
      event.preventDefault();

      setIsSubmitting(true);
      setErrorMessage("");
      setRecurlyTokenId(null);

      dispatch(
        createPaymentToken(
          event.target,
          (tokenId) => {
            setRecurlyTokenId(tokenId);
            attemptToCreateNewOrganization(tokenId);
          },
          (error) => {
            setIsSubmitting(false);
          }
        )
      );
    },
    [attemptToCreateNewOrganization, dispatch]
  );

  const handleBasicInfoSubmit = React.useCallback(
    (event: SyntheticInputEvent<>) => {
      event.preventDefault();
      if (isSubmitting) {
        return;
      }

      setErrorMessage("");

      if (isPhoneNumberInvalid) {
        return;
      }

      if (!openPaymentModal) {
        setOpenPaymentModal(true);
        return;
      }
    },
    [isPhoneNumberInvalid, isSubmitting, openPaymentModal]
  );

  const termsLinks = (
    <React.Fragment>
      I’ve read and accept the{" "}
      <ExternalLink href={trialAgreementUrl()} className={onboardingStyle.link}>
        Trial Agreement
      </ExternalLink>
      .
    </React.Fragment>
  );

  return (
    <OnboardingPage
      currentStep={isOnboarding ? 3 : undefined}
      totalSteps={isOnboarding ? 4 : undefined}
    >
      <OnboardingContentSheet className={onboardingStyle.mainContentSheet}>
        <Flex align="center" className={onboardingStyle.centeredText} column>
          <OnboardingHeading level="1">
            Create <SerifText>your Organization</SerifText>
          </OnboardingHeading>
          <div
            className={classnames(
              onboardingStyle.subHeading,
              onboardingStyle.largeCopy
            )}
          >
            Organizations are workspaces where you collaborate on design files
            with your team.
          </div>
          <div
            className={classnames(
              onboardingStyle.extraInfo,
              onboardingStyle.mediumCopy
            )}
          >
            Your 14-day trial gives you an inclusive preview of Abstract’s
            powerful capabilities.
          </div>
        </Flex>
        <form className={onboardingStyle.form} onSubmit={handleBasicInfoSubmit}>
          <div>
            <Input
              className={onboardingStyle.input}
              label={
                <span className={onboardingStyle.inputLabel}>
                  Organization name
                </span>
              }
              placeholder="eg. Acme or Acme Design"
              onChange={handleOrganizationNameChange}
              value={organizationName}
              required
            />
            <Select
              label={
                <span className={onboardingStyle.inputLabel}>
                  Number of designers in your company
                </span>
              }
              onChange={handleTotalDesignersSizeRangeChange}
              className={classnames(
                onboardingStyle.input,
                onboardingStyle.select,
                {
                  [style.selectPlaceholder]:
                    totalDesignersSizeRange === DEFAULT_NUM_DESIGNERS_VALUE,
                }
              )}
              value={totalDesignersSizeRange}
              required
            >
              {NUM_DESIGNERS_OPTIONS.map((option, index) => (
                <option key={`${index}-${option.value}`} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
            {shouldShowPhoneNumber ? (
              <div className={style.phoneNumberWrapper}>
                <InputPhone
                  className={onboardingStyle.input}
                  name="phoneNumber"
                  label={
                    <span className={onboardingStyle.inputLabel}>
                      Schedule a demo
                    </span>
                  }
                  placeholder="555-555-5555"
                  onChange={handlePhoneNumberChange}
                  wrapperClass={style.phoneNumber}
                />
                <div className={style.phoneNumberNote}>
                  Leave a phone number and we’ll follow-up
                </div>
              </div>
            ) : null}
            <div
              className={classnames(
                onboardingStyle.extraInfo,
                onboardingStyle.mediumCopy
              )}
            >
              <InputCheckbox
                checkboxClass={onboardingStyle.checkbox}
                labelClass={onboardingStyle.checkboxLabel}
                label={termsLinks}
                required
              />
            </div>
          </div>
          <DialogForm
            isOpen={openPaymentModal}
            onRequestClose={handlePaymentModalClose}
            onClose={handlePaymentModalClose}
            onSubmit={handlePaymentModalSubmit}
            title="Enter a payment method"
            alternativePadding
            headingClassName={billingModalStyle.billingModalHeading}
            className={billingModalStyle.fullHeightModal}
            primaryButton=""
          >
            <input type="hidden" name="name" value={organizationName} />
            <input
              type="hidden"
              name="totalDesignersSizeRange"
              value={totalDesignersSizeRange}
            />
            {shouldShowPhoneNumber && (
              <input type="hidden" name="phoneNumber" value={phoneNumber} />
            )}

            <RecurlyBillingInfoForm
              submitText="Start Subscription & Create Organization"
              agreeToTrialAgreement
              agreeToTermsOfService
              on3DSecureChallengeError={handle3DSecureChallengeError}
              on3DSecureChallengeCompleted={handle3DSecureChallengeCompleted}
            >
              <PriceCalculation
                className={billingModalStyle.priceCalculation}
                totalPrice={trialPrice.toString()}
                monthlyPrice={trialPrice}
                annual={false}
                quantity={1}
                description={
                  <span>
                    You will not be charged if you cancel your subscription
                    before your 14-day free trial period ends.
                  </span>
                }
              />
            </RecurlyBillingInfoForm>
            <div className={style.billingModalFooter}>
              <Button
                type="submit"
                disabled={isSubmitting}
                large
                fullwidth
                primary
              >
                {isSubmitting
                  ? "Starting Subscription & Creating Organization…"
                  : "Start Subscription & Create Organization"}
              </Button>
            </div>
          </DialogForm>
          <Button
            className={classnames(
              onboardingStyle.button,
              onboardingStyle.submit
            )}
            disabled={isSubmitting}
            type="submit"
            fullwidth
            large
            primary
          >
            {isSubmitting ? "Creating Organization…" : "Create Organization"}
          </Button>
          {errorMessage ? (
            <div className={style.error}>{errorMessage}</div>
          ) : null}
        </form>
      </OnboardingContentSheet>
    </OnboardingPage>
  );
}
