// @flow
import { connect } from "react-redux";
import { trackEvent } from "core/actions/analytics";
import { recurlyReset } from "core/actions/recurly";
import { showLoadingToast } from "core/actions/toasts";
import {
  SubscriptionFetchRequest,
  SeatsResetRequest,
} from "core/requests/seats";
import { seatCapEnabled } from "core/selectors/features";
import { getOrganization } from "core/selectors/organizations";
import {
  getSubscription,
  canSeeNextBill,
  canOrganizationSeeCancelAndDeleteButtons,
} from "core/selectors/subscriptions";
import type { State, Dispatch } from "core/types";
import { loginRequired } from "web/actions/login";
import { loadOrganization } from "web/actions/organizations";
import { openSupportWindow } from "web/di/actions";
import type { Props, OwnProps, StateProps, DispatchProps } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { organizationId } = props.params;
  const organization = getOrganization(state, { organizationId });
  const subscription = getSubscription(state, organizationId);
  const showNextBill = canSeeNextBill(state, organizationId);
  const areCancelAndDeleteButtonsVisible =
    canOrganizationSeeCancelAndDeleteButtons(state, organizationId);
  return {
    contractType: organization ? organization.contractType : null,
    organizationId,
    isUsernameOrganization:
      (organization && organization.isUsernameOrganization) || false,
    subscriptionError: SubscriptionFetchRequest.hasError(state, {
      organizationId,
    }),
    organization,
    subscription,
    showNextBill,
    seatCapEnabled: seatCapEnabled(state, organizationId),
    areCancelAndDeleteButtonsVisible,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    loginRequired: () => dispatch(loginRequired()),
    loadOrganization: () =>
      dispatch(loadOrganization(props.params.organizationId)),
    onLoadSubscription: () =>
      dispatch(
        SubscriptionFetchRequest.perform({
          params: { organizationId: props.params.organizationId },
        })
      ),
    resetRecurly: () => dispatch(recurlyReset()),
    openSupportTicket: () =>
      dispatch(openSupportWindow(props.params.organizationId)),
    trackNewSubscription: (params) =>
      dispatch(trackEvent("SUBSCRIPTION_STARTED", params)),
    trackSubscriptionReactivated: () => {
      dispatch(
        trackEvent("SUBSCRIPTION_REACTIVATED", {
          organizationId: props.params.organizationId,
        })
      );
    },
    resetSeatsChanges: () => {
      const loadingToast = dispatch(
        showLoadingToast({
          text: "Resetting subscription changes...",
        })
      );
      dispatch(
        SeatsResetRequest.perform({
          params: {
            organizationId: props.params.organizationId,
          },
        })
      )
        .then(() =>
          loadingToast.showAutoClose({
            text: "Subscription changes reset successful",
          })
        )
        .catch((error) =>
          loadingToast.error({
            text: "Failed to reset subscription changes",
            subtext: error.message,
          })
        );
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
