// @flow
import * as React from "react";
import Icon from "core/components/Icon";
import { ReviewStatuses } from "core/models/review";
import type { Notification } from "core/types";
import style from "./style.scss";

type Props = {|
  notification: Notification,
|};

function IconComponent(props: { type: string }) {
  return <Icon className={style.notificationIcon} small type={props.type} />;
}

export default function NotificationIcon(props: Props) {
  switch (props.notification.messageType) {
    case "BRANCH_MENTIONED":
    case "COLLECTION_MENTIONED":
    case "COMMENT_MENTIONED":
    case "COMMIT_MENTIONED": {
      return <IconComponent type="notification-comment-mentioned" />;
    }

    case "PROJECT_ARCHIVED": {
      return <IconComponent type="notification-archived" />;
    }

    case "PROJECT_DELETED": {
      return <IconComponent type="notification-deleted" />;
    }

    case "PROJECT_CREATED":
    case "PROJECT_MEMBER_ADDED":
    case "REVIEW_REQUESTED": {
      return <IconComponent type="notification-reviewer-added" />;
    }

    case "PROJECT_MEMBER_REMOVED":
    case "REVIEWER_REMOVED":
    case "REVIEW_DISMISSED": {
      return <IconComponent type="notification-reviewer-removed" />;
    }

    case "REVIEW_COMPLETED": {
      const { status } = props.notification.payload;

      if (status === ReviewStatuses.APPROVED) {
        return <IconComponent type="notification-approved" />;
      } else if (status === ReviewStatuses.REJECTED) {
        return <IconComponent type="notification-changes-requested" />;
      } else {
        return null;
      }
    }

    case "COMMENT_CREATED": {
      const isReply = !!props.notification.payload.commentParentId;
      return (
        <IconComponent
          type={
            isReply
              ? "notification-comment-replied"
              : "notification-comment-created"
          }
        />
      );
    }

    case "PROJECT_UNARCHIVED": {
      return <IconComponent type="notification-unarchived" />;
    }

    default: {
      return null;
    }
  }
}
