// @flow
import * as Abstract from "abstract-sdk";
import apiRequest from "abstract-di/api";
import { entitiesReceived } from "core/actions/entities";
import {
  getOrganizationSettings,
  getOrganizationSettingsForProject,
} from "core/selectors/organizationSettings";
import { getOrganization } from "core/selectors/organizations";
import type { SlackIntegrationOverrideDetails } from "core/types";
import defineRequest from ".";

export const IntegrationsFetchRequest = defineRequest<
  Abstract.OrganizationDescriptor,
  Abstract.OrganizationDescriptor,
>({
  id(params) {
    return `integrations-fetch-${params.organizationId}`;
  },
  perform(params) {
    return apiRequest("get", "integrations", params);
  },
  onSuccess(response, params, dispatch, getState) {
    const { organizationId } = params;
    const organization = getOrganization(getState(), { organizationId });

    if (organization) {
      dispatch({
        type: "ORGANIZATION_SETTINGS_INTEGRATIONS_LOADED",
        meta: { organizationId, organizationName: organization.name },
        payload: response,
      });
    }
  },
});

export type CreateIntegrationOverrideIdParams = {
  integrationId: string,
};
export type CreateIntegrationOverridePerformParams = {
  projectId: string,
  integrationId: string,
  details: SlackIntegrationOverrideDetails,
};

export const CreateIntegrationOverrideRequest = defineRequest<
  CreateIntegrationOverridePerformParams,
  CreateIntegrationOverrideIdParams,
>({
  id: (params) => `post:integrations/${params.integrationId}/overrides`,
  perform: (params) => {
    const { projectId, integrationId, details } = params;
    return apiRequest("post", `integrations/${integrationId}/overrides`, {
      project_id: projectId,
      details,
    });
  },
  onSuccess: (override, params, dispatch, getState) => {
    const settings = getOrganizationSettingsForProject(getState(), {
      projectId: params.projectId,
    });
    if (!settings) {
      return;
    }

    const { slackIntegration } = settings;
    if (!slackIntegration) {
      return;
    }

    const overrides = (slackIntegration.projectOverrides || []).concat([
      override,
    ]);
    const updatedSettings = {
      [settings.organizationId]: {
        ...settings,
        slackIntegration: {
          ...slackIntegration,
          projectOverrides: overrides,
        },
      },
    };
    dispatch(entitiesReceived({ organizationSettings: updatedSettings }));
  },
});

export type UpdateIntegrationOverrideIdParams = {
  integrationId: string,
  overrideId: string,
};
export type UpdateIntegrationOverridePerformParams = {
  organizationId: string,
  integrationId: string,
  overrideId: string,
  details: SlackIntegrationOverrideDetails,
};

export const UpdateIntegrationOverrideRequest = defineRequest<
  UpdateIntegrationOverridePerformParams,
  UpdateIntegrationOverrideIdParams,
>({
  id: (params) =>
    `put:integrations/${params.integrationId}/overrides/${params.overrideId}`,
  perform: (params) => {
    const { integrationId, overrideId, details } = params;
    return apiRequest(
      "put",
      `integrations/${integrationId}/overrides/${overrideId}`,
      { details }
    );
  },
  onSuccess: (override, params, dispatch, getState) => {
    const { organizationId } = params;
    const settings = getOrganizationSettings(getState(), { organizationId });
    const slackIntegration = settings ? settings.slackIntegration : undefined;
    if (!slackIntegration) {
      return;
    }

    const projectOverrides = slackIntegration.projectOverrides || [];
    const overrides = projectOverrides.map((o) => {
      return o.id === override.id ? override : o;
    });

    const updatedSettings = {
      [organizationId]: {
        ...settings,
        slackIntegration: {
          ...slackIntegration,
          projectOverrides: overrides,
        },
      },
    };
    dispatch(entitiesReceived({ organizationSettings: updatedSettings }));
  },
});

export type RemoveIntegrationOverrideIdParams =
  UpdateIntegrationOverrideIdParams;
export type RemoveIntegrationOverridePerformParams = {
  organizationId: string,
  integrationId: string,
  overrideId: string,
};

export const RemoveIntegrationOverrideRequest = defineRequest<
  RemoveIntegrationOverrideIdParams,
  RemoveIntegrationOverridePerformParams,
>({
  id: (params) =>
    `delete:integrations/${params.integrationId}/overrides/${params.overrideId}`,
  perform: (params) => {
    const { integrationId, overrideId } = params;
    return apiRequest(
      "delete",
      `integrations/${integrationId}/overrides/${overrideId}`
    );
  },
  onSuccess: (response, params, dispatch, getState) => {
    const { organizationId, overrideId } = params;
    const settings = getOrganizationSettings(getState(), {
      organizationId,
    });
    const slackIntegration = settings ? settings.slackIntegration : undefined;
    if (!slackIntegration) {
      return;
    }

    const overrides = (slackIntegration.projectOverrides || []).filter(
      (o) => o.id !== overrideId
    );

    const updatedSettings = {
      [organizationId]: {
        ...settings,
        slackIntegration: {
          ...slackIntegration,
          projectOverrides: overrides,
        },
      },
    };
    dispatch(entitiesReceived({ organizationSettings: updatedSettings }));
  },
});
