// @flow
import { Abstract } from "core/lib/abstract";
import type { State, UserMetrics } from "core/types";

export function getUserMetricsForOrganization(
  state: State,
  props: Abstract.OrganizationDescriptor
): ?UserMetrics {
  return state.userMetrics[props.organizationId];
}
