// @flow
import classnames from "classnames";
import { map } from "lodash";
import * as React from "react";
import { CSSTransition } from "react-transition-group/cjs";
import window from "core/global/window";
import Button from "../Button";
import style from "./style.scss";

type Action = {
  text: string,
  primary: boolean,
  onClick: () => void,
};

type Props = {
  message: React.Node,
  className?: string,
  variant: "notice" | "error" | "success",
  onClose?: () => void,
  closeable?: boolean,
  actions?: Action[],
  autoCloseAfter?: number, // in ms
};

type State = {
  autoClosed: boolean,
};

export default class Banner extends React.Component<Props, State> {
  timeout: TimeoutID; // auto close timeout id

  state = {
    autoClosed: false,
  };

  cssTransitionRef = React.createRef<*>();

  static defaultProps = { variant: "notice", closeable: true };

  componentDidMount() {
    if (this.props.autoCloseAfter) {
      this.timeout = window.setTimeout(() => {
        this.setState({ autoClosed: true });
      }, this.props.autoCloseAfter);
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }
  }

  render() {
    const { message, onClose, closeable, actions } = this.props;
    const classes = classnames(this.props.className, style.banner, {
      [style[this.props.variant]]: true,
    });

    return (
      <div>
        <CSSTransition
          classNames={{
            appear: style.bannerAppear,
            enter: style.bannerEnter,
            exit: style.bannerLeave,
          }}
          appear
          timeout={0}
          in={!this.state.autoClosed}
          nodeRef={this.cssTransitionRef}
        >
          <div ref={this.cssTransitionRef}>
            {!this.state.autoClosed && (
              <div className={classes}>
                <span className={style.message}>{message}</span>
                <span className={style.actions}>
                  {map(actions, (action) => (
                    <Button
                      key={action.text}
                      onClick={action.onClick}
                      primary={action.primary}
                    >
                      {action.text}
                    </Button>
                  ))}
                </span>
                {closeable && (
                  <Button
                    nude
                    tabIndex={-1}
                    onClick={onClose}
                    className={style.close}
                  >
                    ×
                  </Button>
                )}
              </div>
            )}
          </div>
        </CSSTransition>
      </div>
    );
  }
}
