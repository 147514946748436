// @flow
import AbstractConfig from "abstract-di/config";
import { authRequest } from "core/api";
import defineRequest from "core/requests";

type AuthenticationParams = {
  email_or_username: string,
  url: string,
};

export const AuthenticationTypeRequest = defineRequest<
  AuthenticationParams,
  {},
>({
  id: () => {
    return "authType";
  },
  perform: async (params) => {
    const { email_or_username, url } = params;
    const escapedUsername = encodeURIComponent(email_or_username);
    const clientId = await AbstractConfig.getClientId();

    if (!clientId) {
      throw new Error("clientId is not defined");
    }

    const response = await authRequest(
      `saml/authnrequest?clientId=${clientId}&loginId=${escapedUsername}&url=${url}`
    );

    return response.json();
  },
});
