// @flow
import type { Action } from ".";

export function openSidebar(): Action {
  return { type: "SIDEBAR_OPEN" };
}

export function closeSidebar(): Action {
  return { type: "SIDEBAR_CLOSE" };
}
