// @flow
import classnames from "classnames";
import * as React from "react";
import Floating from "core/components/Floating";
import { buildContextMenuItems } from "core/lib/contextMenus";
import type { MenuItem } from "core/types";
import ContextMenuItem from "./ContextMenuItem";
import style from "./style.scss";

type Props = {
  className?: string,
  compact?: boolean,
  items: MenuItem[],
  id: string,
  close?: () => void,
  isSubmenu?: boolean,
  qaSelector?: string,
};

export default function ContextMenuItemList({
  id,
  items,
  close,
  compact,
  className,
  qaSelector,
}: Props) {
  return (
    <Floating
      aria-labelledby={id}
      className={classnames(style.menu, className)}
      role="menu"
      qaSelector={qaSelector}
    >
      {buildContextMenuItems(items).map((item, index) => (
        <ContextMenuItem
          key={index}
          id={`${id}-${index}`}
          close={close}
          role="menuitem"
          compact={compact}
          qaSelector={item.label}
          {...item}
        />
      ))}
    </Floating>
  );
}
