// @flow
import * as React from "react";
import NoFiles from "core/components/Empty/NoFiles";
import NoResults from "core/components/Empty/NoResults";
import FileTree from "core/components/FileTree";
import Flex from "core/components/Flex";
import Heading from "core/components/Heading";
import Input from "core/components/Input";
import KeyboardNavigation from "core/components/KeyboardNavigation";
import matchString from "core/lib/matchString";
import type {
  File,
  Library,
  Page,
  FilePathQueryParams,
  ChangesetChange,
} from "core/types";
import style from "./style.scss";

type Props = {
  changedFiles: File[],
  unchangedFiles: File[],
  deletedFiles: File[],
  libraries: Library[],
  pages: { [uniqueFileId: string]: Page[] },
  fileCommentCounts: { [id: string]: number },
  isMasterBranch?: boolean,
  isMobile?: boolean,
  fileChanges: { [fileId: string]: ChangesetChange },
  filePath?: (
    hasChanges: boolean
  ) => (
    fileId: string,
    pageId?: string,
    query?: FilePathQueryParams
  ) => string | Object,
  libraryPath?: (fileId: string) => string | Object,
  loadPages?: (string) => void,
  focused?: boolean,
  fileIsOpening?: (file: File) => boolean,
  fileId?: string,
  pageId?: string,
  contentTypeId?: string,
  projectId: string,
  branchId: string,
  addFileButton?: React.Node,
  onClickItem?: (fileId: string, pageId?: string, changed?: boolean) => void,
  onOpenFile?: (
    event: SyntheticEvent<>,
    file: File,
    openUntracked: boolean
  ) => void,
  onOpenLibraryFile?: (event: SyntheticEvent<>, file: File) => void,
  qaSelector?: string,
};

type State = { query: string };

function FileHeading({ children }: { children: React.Node }) {
  return (
    <Heading size="xs" level="3" className={style.heading}>
      {children}
    </Heading>
  );
}

export default class FileList extends React.Component<Props, State> {
  static defaultProps = {
    libraries: [],
    fileChanges: {},
    fileCommentCounts: {},
  };

  state = { query: "" };

  handleSearchChange = (event: SyntheticInputEvent<>) => {
    this.setState({ query: event.target.value });
  };

  handleFileExpand = (file: File) => {
    if (this.props.loadPages) {
      this.props.loadPages(file.id);
    }
  };

  handleClickItem = (changed?: boolean) => {
    if (!this.props.onClickItem) {
      return undefined;
    }
    return (fileId: string, pageId?: string) => {
      this.props.onClickItem && this.props.onClickItem(fileId, pageId, changed);
    };
  };

  matchesQuery = (file: { name: string }) => {
    const { query } = this.state;
    return !query || matchString(file.name, query);
  };

  get groupedFiles(): Object {
    return {
      changedFiles: this.props.changedFiles.filter(this.matchesQuery),
      unchangedFiles: this.props.unchangedFiles.filter(this.matchesQuery),
      deletedFiles: this.props.deletedFiles.filter(this.matchesQuery),
      libraries: this.props.libraries.filter(this.matchesQuery),
    };
  }

  get totalFiles(): number {
    return (
      this.props.changedFiles.length +
      this.props.unchangedFiles.length +
      this.props.deletedFiles.length +
      this.props.libraries.length
    );
  }

  renderEmpty = () => {
    return (
      <div className={style.emptyInner}>
        {this.state.query ? (
          <NoResults type="files" term={this.state.query} flex />
        ) : (
          <NoFiles flex />
        )}
      </div>
    );
  };

  renderFileGroup(
    keyboardNavigation: Object,
    files: File[],
    changed: boolean = false
  ) {
    const { filePath } = this.props;
    return (
      <React.Fragment>
        {this.props.isMasterBranch ? (
          <div className={style.headingspacer} />
        ) : (
          <FileHeading>{changed ? "Changed" : "Unchanged"}</FileHeading>
        )}
        <FileTree
          {...keyboardNavigation}
          dynamic
          files={files}
          pages={this.props.pages}
          projectId={this.props.projectId}
          branchId={this.props.branchId}
          fileChanges={this.props.fileChanges}
          fileIsOpening={this.props.fileIsOpening}
          fileCommentCounts={this.props.fileCommentCounts}
          getPath={filePath ? filePath(changed) : undefined}
          selectedFileId={this.props.fileId}
          selectedPageId={this.props.pageId}
          selectedContentTypeId={this.props.contentTypeId}
          onFileExpand={this.handleFileExpand}
          onOpenFile={this.props.onOpenFile}
          onClickItem={this.handleClickItem(changed)}
          isMobile={this.props.isMobile}
        />
      </React.Fragment>
    );
  }

  renderDeletedFiles(files: File[]) {
    return (
      <React.Fragment>
        <FileHeading>Deleted</FileHeading>
        <FileTree
          dynamic
          disabled
          hasChildren={false}
          files={files}
          projectId={this.props.projectId}
          branchId={this.props.branchId}
          fileChanges={this.props.fileChanges}
          isMobile={this.props.isMobile}
        />
      </React.Fragment>
    );
  }

  renderLibraries(keyboardNavigation: Object, libraries: Library[]) {
    const warnings = {};
    const files = libraries.map((library) => {
      if (library.isProjectUnavailable) {
        warnings[library.fileId] = "Library is unavailable";
      }

      return {
        id: library.fileId,
        name: library.name,
        type: library.type,
        isLibrary: true,
        projectId: library.projectId || "",
        sha: library.sha || "",
        lastChangedAtSha: "",
        updatedAt: "",
      };
    });

    return (
      <React.Fragment>
        <FileHeading>Linked Libraries</FileHeading>
        <FileTree
          {...keyboardNavigation}
          dynamic
          files={files}
          hasChildren={false}
          warnings={warnings}
          projectId={this.props.projectId}
          branchId={this.props.branchId}
          fileCommentCounts={this.props.fileCommentCounts}
          getPath={this.props.libraryPath}
          selectedFileId={this.props.fileId}
          selectedPageId={this.props.pageId}
          selectedContentTypeId={this.props.contentTypeId}
          onFileExpand={this.handleFileExpand}
          onClickItem={this.props.onClickItem}
          onDoubleClick={this.props.onOpenLibraryFile}
          libraries={libraries}
          shouldShowLibraryMenu
        />
      </React.Fragment>
    );
  }

  render() {
    const { changedFiles, unchangedFiles, deletedFiles, libraries } =
      this.groupedFiles;

    const isEmpty =
      !changedFiles.length &&
      !unchangedFiles.length &&
      !deletedFiles.length &&
      !libraries.length;

    return (
      <Flex column className={style.list}>
        <div className={style.files}>
          {isEmpty ? (
            this.renderEmpty()
          ) : (
            <KeyboardNavigation defaultFocused={this.props.focused}>
              {(keyboardNavigation) => (
                <div className={style.filesInner}>
                  <div className={style.sidebarTopHeadingContainer}>
                    <Heading
                      level="3"
                      size="l"
                      className={style.sidebarTopHeading}
                    >
                      Files
                      {this.props.addFileButton}
                    </Heading>
                  </div>
                  {changedFiles.length > 0 &&
                    this.renderFileGroup(
                      keyboardNavigation,
                      changedFiles,
                      true
                    )}
                  {deletedFiles.length > 0 &&
                    this.renderDeletedFiles(deletedFiles)}
                  {unchangedFiles.length > 0 &&
                    this.renderFileGroup(keyboardNavigation, unchangedFiles)}
                  {libraries.length > 0 &&
                    this.renderLibraries(keyboardNavigation, libraries)}
                </div>
              )}
            </KeyboardNavigation>
          )}
        </div>
        {this.totalFiles > 4 && (
          <div className={style.filter}>
            <Input
              name="search"
              className={style.searchInput}
              onChange={this.handleSearchChange}
              type="search"
              icon="search-small"
              placeholder="Filter files…"
              autoComplete="off"
              autoCapitalize="none"
            />
          </div>
        )}
      </Flex>
    );
  }
}
