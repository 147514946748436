// @flow
import invariant from "invariant";
import { connect } from "react-redux";
import { createEmptyFile } from "abstract-di/actions";
import {
  getSketchIsInstalled,
  sketchLibrariesSupported,
} from "abstract-di/selectors";
import { dismissDialog } from "core/actions/dialogs";
import type { State, Dispatch } from "core/types";
import type { Props, OwnProps, StateProps, DispatchProps } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  return {
    canUseSketchLibraries: sketchLibrariesSupported(state),
    canCreateSketchFile: getSketchIsInstalled(state),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    dismissDialog: () => dispatch(dismissDialog()),
    createEmptyFile: (name, type, isLibrary) => {
      invariant(
        createEmptyFile,
        "createEmptyFile is required for dialog action"
      );
      dispatch(
        createEmptyFile(
          props.projectId,
          props.branchId,
          name,
          type,
          isLibrary,
          props.importOptions
        )
      );
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
