// @flow
import { trackEvent } from "core/actions/analytics";
import {
  recurlyReset,
  recurlyStart3DSChallenge,
  recurlyError,
} from "core/actions/recurly";
import * as Request from "core/models/request";
import {
  CreateOrganizationRequest,
  type CreateOrganizationParams,
} from "core/requests/organizations";
import type { ThunkAction } from "core/types";

function getValidationError(error: Error, field: string): ?any {
  if (
    error instanceof Request.ValidationError &&
    error.validationErrors[field]
  ) {
    const validationError: any = (error: Request.ValidationError)
      .validationErrors;
    return validationError;
  }

  return undefined;
}

export function getTransactionError(error: Error): ?any {
  return getValidationError(error, "card");
}

export function createNewOrganization(
  params: CreateOrganizationParams,
  onSuccessCallback?: (response: any) => void,
  onErrorCallback?: (error: Error) => void
): ThunkAction {
  return function (dispatch) {
    const { source } = params;

    return dispatch(
      CreateOrganizationRequest.perform({
        params: { ...params },
        onSuccess: (response) => {
          const { id: organizationId } = response.data;

          dispatch(
            trackEvent("ORGANIZATION_CREATED", {
              organizationId,
              source,
            })
          );

          dispatch(
            trackEvent("TRIAL_STARTED", {
              trialStartDate: response.createdAt,
              trialExpirationDate: response.trialEndsAt,
            })
          );

          dispatch(recurlyReset());

          if (onSuccessCallback) {
            onSuccessCallback(response);
          }
        },
        onError: (error) => {
          const threeDSError = getValidationError(error, "action_token");
          const transactionError = getTransactionError(error);
          if (threeDSError) {
            dispatch(
              recurlyStart3DSChallenge(
                threeDSError.action_token,
                threeDSError.account_code
              )
            );
          } else if (transactionError) {
            dispatch(
              recurlyError({
                code: "transaction_error",
                fields: ["card"],
                details: [{ field: "card", messages: [transactionError.card] }],
              })
            );
            if (onErrorCallback) {
              onErrorCallback(error);
            }
          } else {
            dispatch(recurlyReset());

            if (onErrorCallback) {
              onErrorCallback(error);
            }
          }
        },
      })
    );
  };
}
