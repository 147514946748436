// @flow
import { push } from "core/lib/location";
import { accountProfile, accountNotifications } from "web/routeHelpers";
export { signup } from "web/routeHelpers";

export function organizationPath(organizationId: string): string {
  return `/organizations/${organizationId}`;
}

export const pagePath = undefined;

export { downloadApp } from "web/routeHelpers";

export const openPersonalSettings = async () => {
  push(accountProfile());
};

export const openNotificationSettings = async () => {
  push(accountNotifications());
};
