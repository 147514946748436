// @flow
import classnames from "classnames";
import currency from "currency.js";
import * as React from "react";
import Note from "core/components/Note";
import Spinner from "core/components/Spinner";
import style from "./style.scss";
import type { ActionType } from ".";

type Props = {
  isLoading?: boolean,
  hasError?: boolean,
  proRatedAmount: number,
  actionType: ActionType,
  disabled?: boolean,
  seatCapEnforced: ?boolean,
};

export default function AdjustmentLine({
  isLoading,
  proRatedAmount,
  actionType,
  disabled,
  seatCapEnforced,
}: Props) {
  const ADD_NOTE = seatCapEnforced
    ? "An immediate, one-time charge for these seats"
    : "Immediate pro-rated charge for your added seats";
  const ADD_TITLE = seatCapEnforced
    ? "Pro-rated charge"
    : "One time adjustment charge";
  const REMOVE_NOTE =
    "Pro-rated credit for removed seats. Credit will be applied to your next transaction with us";
  const REMOVE_TITLE = "Pro-rated credit to your account";
  return (
    <div className={classnames(style.lineWrap, { [style.disabled]: disabled })}>
      <div className={style.description}>
        <span>{actionType === "add" ? ADD_TITLE : REMOVE_TITLE}</span>
        <Note>{actionType === "add" ? ADD_NOTE : REMOVE_NOTE}</Note>
      </div>
      {isLoading ? (
        <Spinner small />
      ) : (
        <strong>
          {disabled
            ? "N/A"
            : currency(Math.abs(proRatedAmount)).divide(100).format(true)}
        </strong>
      )}
    </div>
  );
}
