// @flow
import classnames from "classnames";
import * as React from "react";
import Button from "core/components/Button";
import ButtonLink from "core/components/ButtonLink";
import Flex from "core/components/Flex";
import { ValidatedInput as Input } from "core/components/Input/withValidation";
import InputPassword from "core/components/InputPassword";
import OnboardingHeading from "core/components/OnboardingHeading";
import onboardingStyle from "core/components/onboarding.scss";
import { addQuery, removeQuery, push, replace } from "core/lib/location";
import type { Invitation, LocationDescriptor } from "core/types";
import SAMLRedirect from "./SAMLRedirect";
import connector from "./connector";
import style from "./style.scss";

export type SAMLData = {|
  url: string,
  bindingType: "HTTP-Redirect" | "HTTP-POST",
  relayState: string,
  samlRequest: string,
|};

export type OwnProps = {|
  username?: string,
  invitation?: Invitation,
  isLoadingSignIn: boolean,
  error: ?Error,
  returnTo?: LocationDescriptor,
  onCreateAccount: () => void,
  onPasswordReset: () => void,
  resetError: () => void,
  onSignIn: (username: string, password: string, formId?: string) => void,
|};

export type StateProps = {|
  authCheckError: ?Error,
  isOnline: boolean,
  isLoadingAuthCheck: boolean,
|};

export type DispatchProps = {|
  onCheckAuthType: (username: string, url: string) => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type AuthTypes = "SAML" | "default";
export type State = {|
  authType: AuthTypes | "",
  samlData?: SAMLData,
  username: string,
  password: string,
  launchSaml: boolean,
|};

class LoginForm extends React.Component<Props, State> {
  state = {
    authType: "",
    username: this.props.username || "",
    password: "",
    launchSaml: !!this.props.username,
  };

  mounted: boolean = true;
  passwordInput: ?InputPassword;

  static getDerivedStateFromProps(props: Props, prevState: State) {
    // If the username changed then update it in local state
    if (props.username && props.username !== prevState.username) {
      return {
        launchSaml: true,
        username: props.username,
      };
    }

    return null;
  }

  componentDidMount() {
    // If a username is provided on mount (The login screen was loaded with
    // the query param) then immediately check the auth type
    if (this.props.username) {
      this.handleCheckAuthType();
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    // Because the password input is always rendered we must programatically
    // focus the input when the screen switches to show the password field.
    if (
      this.passwordInput &&
      prevState.authType !== "default" &&
      this.state.authType === "default"
    ) {
      this.passwordInput.focus();
      return;
    }

    // If we navigated back to the username screen and there is an outstanding
    // login error, then clear it.
    if (!this.props.username && this.props.error) {
      this.props.resetError();
    }

    // If we navigated back to the username screen then reset the password and
    // auth types back to the defaults
    if (prevProps.username && !this.props.username) {
      this.setState({
        authType: "",
        password: "",
      });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleCheckAuthType = async (event: ?SyntheticEvent<>) => {
    if (!this.mounted) {
      return;
    }

    event && event.preventDefault();

    const returnTo =
      typeof this.props.returnTo === "string" || !this.props.returnTo
        ? this.props.returnTo || removeQuery("username").pathname
        : this.props.returnTo.pathname + (this.props.returnTo.search || "");

    const response = await this.props.onCheckAuthType(
      this.state.username,
      returnTo
    );

    if (response && this.mounted) {
      this.setState({
        authType: response.authType,
        samlData: response.samlData,
      });
    }
  };

  submitUsername = (event: ?SyntheticEvent<>) => {
    event && event.preventDefault();

    if (this.state.username !== this.props.username) {
      push(addQuery({ username: this.state.username }));
    }

    this.setState({ launchSaml: true }, this.handleCheckAuthType);
  };

  handleCreateAccount = (event: SyntheticEvent<>) => {
    event.preventDefault();
    this.props.onCreateAccount();
  };

  handleSignIn = (event: SyntheticEvent<>) => {
    event.preventDefault();
    this.props.onSignIn(this.state.username, this.state.password);
  };

  renderAuthView = () => {
    if (!this.state.samlData) {
      return null;
    }

    return (
      <SAMLRedirect
        samlData={this.state.samlData}
        isLoadingAuthCheck={this.props.isLoadingAuthCheck}
        secondaryButtonClick={this.backToUsername}
        primaryButtonClick={this.handleCheckAuthType}
        autoLaunch={this.state.launchSaml}
      />
    );
  };

  handleInputChange = (event: SyntheticInputEvent<*>) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  backToUsername = (event: SyntheticEvent<>) => {
    replace(removeQuery("username"));
  };

  renderUsernameView() {
    const { authCheckError, error } = this.props;
    const isPasswordAuth = this.state.authType === "default";
    const formHandler = isPasswordAuth
      ? this.handleSignIn
      : this.submitUsername;

    return (
      <form className={onboardingStyle.form} onSubmit={formHandler}>
        <div>
          <Input
            required
            key={this.state.authType}
            error={authCheckError ? authCheckError.message : undefined}
            className={onboardingStyle.input}
            label={
              <span className={onboardingStyle.inputLabel}>
                Email or username
              </span>
            }
            value={this.state.username}
            validationMessage={
              error ? error.message : "Enter a valid email address or username"
            }
            placeholder="mika@banktm.com or mikasharp"
            name="username"
            autoComplete="username"
            onChange={this.handleInputChange}
            autoFocus
            selectOnFocus
            autoCorrect="off"
            autoCapitalize="none"
            type="text"
            disabled={isPasswordAuth}
            helpers={
              isPasswordAuth
                ? {
                    changeUsername: (
                      <ButtonLink
                        className={onboardingStyle.inputHelper}
                        onClick={this.backToUsername}
                        qaSelector="signInChangeUsernameButton"
                      >
                        Change
                      </ButtonLink>
                    ),
                  }
                : undefined
            }
            wrapperClass={classnames({
              [style.noBottomMargin]: !isPasswordAuth,
            })}
          />
          {this.renderPassword()}
        </div>
        {this.renderSubmit()}
      </form>
    );
  }

  getSubmitText() {
    const { isOnline } = this.props;
    return isOnline ? "Continue" : "Go online to continue";
  }

  renderPassword() {
    const { isLoadingSignIn, error } = this.props;

    const isPasswordAuth = this.state.authType === "default";

    /*
      pasword input must always be mounted and rendered to allow password managers access
      do not conditionally render this input
    */
    return (
      <InputPassword
        ref={(me) => (this.passwordInput = me)}
        visuallyHidden={!isPasswordAuth}
        error={error ? error.message : undefined}
        className={onboardingStyle.input}
        label={
          <span className={onboardingStyle.inputLabel}>
            Enter your password
          </span>
        }
        value={this.state.password}
        placeholder="Password"
        disabled={isLoadingSignIn}
        onChange={this.handleInputChange}
        name="password"
        autoComplete="current-password"
        selectOnFocus
        showHelper={isPasswordAuth}
        tabIndex={isPasswordAuth ? "0" : "-1"}
        autoFocus={!!error}
      />
    );
  }

  renderSubmit() {
    const { invitation, isLoadingSignIn, isLoadingAuthCheck, isOnline } =
      this.props;

    const isPasswordAuth = this.state.authType === "default";
    const buttonText = isLoadingAuthCheck ? "Loading…" : this.getSubmitText();

    return (
      <React.Fragment>
        {isPasswordAuth ? (
          <React.Fragment>
            <Button
              className={classnames(
                onboardingStyle.button,
                onboardingStyle.submit
              )}
              disabled={isLoadingSignIn}
              qaSelector="signInSubmitButton"
              type="submit"
              fullwidth
              primary
              large
            >
              {isLoadingSignIn ? "Signing in…" : "Sign in"}
            </Button>
            <div className={classnames(style.note, onboardingStyle.mediumCopy)}>
              <ButtonLink onClick={this.props.onPasswordReset}>
                Forgot your password?
              </ButtonLink>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Button
              className={classnames(
                onboardingStyle.button,
                onboardingStyle.submit
              )}
              disabled={isLoadingAuthCheck || !isOnline}
              qaSelector="signInContinueButton"
              type="submit"
              fullwidth
              primary
              large
            >
              {buttonText}
            </Button>
            {invitation && invitation.id ? (
              <div
                className={classnames(style.note, onboardingStyle.mediumCopy)}
              >
                You need to sign in to join{" "}
                {invitation.projectName ||
                  invitation.organizationName ||
                  "an organization"}
                .
              </div>
            ) : null}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        <Flex column align="center" className={onboardingStyle.centeredText}>
          <OnboardingHeading level="1">Sign in</OnboardingHeading>
          <div
            className={classnames(
              onboardingStyle.extraInfo,
              onboardingStyle.mediumCopy
            )}
          >
            New to Abstract?{" "}
            <ButtonLink onClick={this.handleCreateAccount}>
              Create a new account
            </ButtonLink>
          </div>
        </Flex>
        {this.state.authType === "SAML"
          ? this.renderAuthView()
          : this.renderUsernameView()}
      </React.Fragment>
    );
  }
}

export default connector(LoginForm);
