// @flow
import { escapeRegExp } from "lodash";
import * as React from "react";
import DialogForm, { TDialogForm } from "core/components/DialogForm";
import { SubmittableInput } from "core/components/Input/withSubmittable";
import Validations from "core/validations";
import connector from "./connector";

export type OwnProps = {|
  projectId: string,
  branchId: string,
  fileId: string,
  originalFileName: string,
  fileType: string,
|};

export type DispatchProps = {|
  renameFile: (
    projectId: string,
    branchId: string,
    fileId: string,
    fileType: string,
    originalFileName: string,
    fileName: string
  ) => void,
  dismissDialog: () => void,
|};

export type Props = {
  ...OwnProps,
  ...DispatchProps,
};

type State = {
  name: string,
};

class RenameFile extends React.Component<Props, State> {
  state = {
    name: this.props.originalFileName,
  };

  form: ?TDialogForm;

  nameChanged = (ev: SyntheticInputEvent<*>) => {
    this.setState({ name: ev.target.value });
    this.form && this.form.checkValid();
  };

  handleSubmit = () => {
    const { projectId, branchId, fileId, originalFileName, fileType } =
      this.props;
    const fileName = this.state.name;

    this.props.renameFile(
      projectId,
      branchId,
      fileId,
      fileType,
      originalFileName,
      fileName
    );

    this.props.dismissDialog();
  };

  render() {
    const original = escapeRegExp(this.props.originalFileName);
    const isNotEmptyOrOriginalName = `(^(?!(${original}(?!.)|(\\s*$))).*$)`;

    return (
      <DialogForm
        title="Rename file"
        primaryButton="Rename file"
        ref={(ref) => (this.form = ref)}
        onClose={this.props.dismissDialog}
        onSubmit={this.handleSubmit}
        isOpen
      >
        {({ onSubmit }) => (
          <SubmittableInput
            type="text"
            label="Name"
            pattern={isNotEmptyOrOriginalName}
            defaultValue={this.props.originalFileName}
            minLength={Validations.minFileNameLength}
            maxLength={Validations.maxFileNameLength}
            onChange={this.nameChanged}
            onSubmit={onSubmit}
            requiredTag
            required
            selected
          />
        )}
      </DialogForm>
    );
  }
}

export default connector(RenameFile);
