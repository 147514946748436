// @flow
import classnames from "classnames";
import * as React from "react";
import Button from "core/components/Button";
import ButtonLink from "core/components/ButtonLink";
import Header from "core/components/Header";
import { Abstract } from "core/lib/abstract";
import { formatFromLayerData } from "core/models/asset";
import type { Asset, LayerDataAsset } from "core/types";
import DownloadAsset from "./DownloadAsset";
import style from "./style.scss";

type Props = {
  header: string,
  assets: Asset[],
  layerName: string,
  layerParams: Abstract.LayerVersionDescriptor,
  layerDataAssets: LayerDataAsset[],
  downloadDisabled: boolean,
  showDownloadControls: boolean,
};

export default class AssetFormats extends React.Component<Props> {
  static defaultProps = {
    downloadDisabled: true,
    showDownloadControls: false,
  };

  singleAssetForDownload(layerDataAsset: LayerDataAsset) {
    const singleAsset = this.props.assets.find((asset) => {
      return asset.formatName === layerDataAsset.formatName;
    });

    if (!singleAsset) {
      return [];
    }
    return [singleAsset];
  }

  renderAssetFormatScale = (asset: LayerDataAsset, index: number) => {
    const last = this.props.layerDataAssets.length === index + 1;
    const formatScale = formatFromLayerData(asset);

    return (
      <span className={style.assetFormat} key={formatScale}>
        {this.props.showDownloadControls ? (
          <DownloadAsset
            assets={this.singleAssetForDownload(asset)}
            layerParams={this.props.layerParams}
          >
            {({ onDownload, loading }) => (
              <ButtonLink
                title={`Download ${formatScale} version`}
                onClick={onDownload}
                className={classnames(style.buttonLink, {
                  [style.downloadableAsset]:
                    !loading || !this.props.downloadDisabled,
                })}
              >
                {formatScale}
              </ButtonLink>
            )}
          </DownloadAsset>
        ) : (
          formatScale
        )}
        {last ? "" : ", "}
      </span>
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className={style.formatRow}>
          <Header
            left={<div className={style.label}>{this.props.header}</div>}
            right={
              this.props.showDownloadControls && (
                <DownloadAsset
                  assets={this.props.assets}
                  layerParams={this.props.layerParams}
                >
                  {({ onDownload, loading }) => (
                    <Button
                      disabled={loading || this.props.downloadDisabled}
                      onClick={onDownload}
                      icon="download"
                      title={`Download all ${this.props.header.toUpperCase()} versions`}
                      nude
                      tint
                    />
                  )}
                </DownloadAsset>
              )
            }
          />

          <div>
            {this.props.layerDataAssets.map(this.renderAssetFormatScale)}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
