// @flow
import * as React from "react";
import AuthedImage from "core/components/AuthedImage";
import Icon from "core/components/Icon";
import Loaded from "core/components/Loaded";
import Preview from "core/components/Preview";
import PreviewImage from "core/components/PreviewImage";
import style from "./style.scss";

type Props = {
  src: string,
  alt?: string,
  isLoading: boolean,
  error?: boolean,
  empty?: boolean,
  onClick?: () => void,
};

export default function Asset(props: Props) {
  const errorElement = (
    <div className={style.errorWrapper}>
      <Icon type="no-image" small />
    </div>
  );

  const asset = (
    <div className={style.asset}>
      <Loaded loading={props.isLoading} small={true}>
        <div className={style.image}>
          {props.empty ? (
            <Preview
              backgroundTransparent
              fixed={false}
              borderRadius="borderSmall"
            />
          ) : (
            <AuthedImage src={props.src}>
              {(authedSrc, error, dimensions, loading) => (
                <React.Fragment>
                  {loading ? null : (
                    <React.Fragment>
                      {authedSrc && dimensions && (
                        <Preview
                          backgroundTransparent
                          fixed={false}
                          borderRadius="borderSmall"
                        >
                          <PreviewImage
                            src={authedSrc}
                            cover={false}
                            // Our asset previews are 2x, so the actual dimension of the
                            // asset is the dimension of the image divided by two. And since
                            // 2x images are by definition divisible by two, we shouldn't
                            // have any non-integers here.
                            width={dimensions.width / 2}
                            height={dimensions.height / 2}
                            alt={props.alt}
                          />
                        </Preview>
                      )}

                      {error && errorElement}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </AuthedImage>
          )}
        </div>
      </Loaded>
    </div>
  );

  if (props.error) {
    return <div className={style.asset}>{errorElement}</div>;
  }

  if (props.onClick) {
    return (
      <span onClick={props.onClick} className={style.hasHover}>
        {asset}
      </span>
    );
  }

  return asset;
}

Asset.defaultProps = {
  isLoading: false,
};
