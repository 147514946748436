// @flow
import * as React from "react";
import Avatar from "../Avatar";
import Button from "../Button";
import OrganizationLogo from "../OrganizationLogo";
import style from "./style.scss";

type Props = {
  onConfirm: () => void,
  src: string,
  type: string,
};

function renderPreview(src, type) {
  if (type === "avatar") {
    return <Avatar src={src} size={128} />;
  }

  return <OrganizationLogo src={src} size={128} alt={`Uploaded ${type}`} />;
}

export default function ImageCropConfirmation({ onConfirm, src, type }: Props) {
  return (
    <div className={style.wrapper}>
      <div className={style.content}>{renderPreview(src, type)}</div>
      <div className={style.footer}>
        <Button fullwidth large primary onClick={onConfirm}>
          Save new {type === "avatar" ? "avatar" : "organization logo"}
        </Button>
      </div>
    </div>
  );
}
