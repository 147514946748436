// @flow
import classnames from "classnames";
import empty from "empty";
import { groupBy } from "lodash";
import pluralize from "pluralize";
import * as React from "react";
import Asset from "core/components/Asset";
import Button from "core/components/Button";
import ExternalLink from "core/components/ExternalLink";
import Icon from "core/components/Icon";
import SidebarSubSection from "core/components/SidebarSubSection";
import { Abstract } from "core/lib/abstract";
import { helpAssetsUrl } from "core/lib/urls";
import type { Asset as TAsset, LayerDataAsset } from "core/types";
import AssetFormats from "./AssetFormats";
import DownloadAsset from "./DownloadAsset";
import style from "./style.scss";

type Props = {
  params: Abstract.LayerVersionDescriptor,
  assets: TAsset[],
  assetsCount: number,
  assetPreview: ?TAsset,
  isLoading: boolean,
  isOffline: boolean,
  error?: boolean,
  layerName: string,
  nestedLayerId: string,
  layerDataAssets?: LayerDataAsset[],
  groupLabel?: string,
  groupDescription?: string,
};

export default class AssetGroup extends React.Component<Props> {
  render() {
    const assetsCount = this.props.layerDataAssets
      ? this.props.layerDataAssets.length
      : 0;
    if (!assetsCount) {
      return null;
    }

    const hasError =
      this.props.error ||
      !!(this.props.assetPreview && this.props.assetPreview.error);

    const layerDataAssetsByFileFormat: $ReadOnly<{
      [key: any]: ?Array<LayerDataAsset>,
    }> = groupBy(this.props.layerDataAssets, "fileFormat");

    const assetsByFileFormat: $ReadOnly<{
      [key: any]: ?Array<TAsset>,
    }> = groupBy(this.props.assets, "fileFormat");

    const selectedLayerHasGeneratedAssets =
      this.props.assets && this.props.assets.length > 0;

    const downloadDisabled =
      this.props.isLoading ||
      this.props.isOffline ||
      hasError ||
      !selectedLayerHasGeneratedAssets;

    const downloadAllGroupAssets = (
      <DownloadAsset
        all
        assets={this.props.assets}
        layerParams={this.props.params}
      >
        {({ onDownload, loading }) => (
          <Button
            disabled={loading || downloadDisabled}
            onClick={onDownload}
            nude
            tint
          >
            Download all
          </Button>
        )}
      </DownloadAsset>
    );

    return (
      <React.Fragment>
        {this.props.groupLabel ? (
          <SidebarSubSection
            label={this.props.groupLabel}
            right={downloadAllGroupAssets}
            className={style.artboardAssetsSection}
          >
            {this.props.groupDescription ? (
              <div className={style.sectionDescription}>
                {this.props.groupDescription}
              </div>
            ) : null}
          </SidebarSubSection>
        ) : null}
        <div
          className={classnames(style.overview, {
            [style.errorOverview]: this.props.error || this.props.isOffline,
          })}
        >
          <Asset
            src={this.props.assetPreview ? this.props.assetPreview.url : ""}
            isLoading={this.props.isLoading}
            error={this.props.isOffline || hasError}
            empty={!selectedLayerHasGeneratedAssets}
            alt={this.props.layerName}
          />
          <div className={style.details}>
            <React.Fragment>
              <div className={style.layerName}>{this.props.layerName}</div>
              <div>
                {this.props.assetsCount}{" "}
                {pluralize("asset", this.props.assetsCount)}
              </div>
              <div className={style.meta}>
                {this.props.isLoading ? (
                  "Loading…"
                ) : (
                  <React.Fragment>
                    {!selectedLayerHasGeneratedAssets && !hasError && (
                      <div className={style.errorWrapper}>
                        <ExternalLink
                          className={style.helpLink}
                          href={helpAssetsUrl()}
                        >
                          <Icon type="info" className={style.icon} tint />
                          Learn about assets
                        </ExternalLink>
                      </div>
                    )}

                    {this.props.isOffline && (
                      <div className={style.errorWrapper}>
                        <Icon className={style.icon} type="offline" warning />
                        <div>
                          <span className={style.offline}>Offline.</span>{" "}
                          Requires an Internet connection to download.
                        </div>
                      </div>
                    )}

                    {hasError && !this.props.isOffline && (
                      <div>
                        {this.props.error && (
                          <div>
                            <div className={style.errorWrapper}>
                              <Icon className={style.icon} type="error-red" />
                              <span className={style.error}>
                                Something went wrong.
                              </span>
                            </div>
                            <span className={style.meta}>
                              Reload the layer to try again.
                            </span>
                          </div>
                        )}
                        {this.props.assetPreview &&
                          this.props.assetPreview.error && (
                            <div className={style.errorWrapper}>
                              <Icon className={style.icon} type="error-red" />
                              <span className={style.error}>
                                Assets failed to generate.
                              </span>
                            </div>
                          )}
                      </div>
                    )}
                  </React.Fragment>
                )}
              </div>
            </React.Fragment>
          </div>
        </div>

        {Object.keys(layerDataAssetsByFileFormat).map((fileFormat) => (
          <AssetFormats
            key={this.props.nestedLayerId + fileFormat}
            header={fileFormat}
            layerName={this.props.layerName}
            layerParams={this.props.params}
            layerDataAssets={
              layerDataAssetsByFileFormat[fileFormat] || empty.array
            }
            assets={assetsByFileFormat[fileFormat] || empty.array}
            downloadDisabled={downloadDisabled}
            showDownloadControls={!hasError && selectedLayerHasGeneratedAssets}
          />
        ))}
      </React.Fragment>
    );
  }
}
