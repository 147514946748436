// @flow
import * as React from "react";
import { Waypoint } from "react-waypoint";
import Centered from "core/components/Centered";
import Spinner from "core/components/Spinner";
import StickyHeader from "core/components/StickyHeader";
import VirtualizedList from "core/components/VirtualizedList";
import type { LoadNotificationsParams } from "core/requests/notifications";
import type { Notification } from "core/types";
import NotificationListItem from "./NotificationListItem";
import style from "./style.scss";

type Props = {|
  allNotificationsLoaded: boolean,
  currentOrganizationFilter: ?string,
  currentUserId: string,
  isLoadingNotifications: boolean,
  isOnline: boolean,
  loadNotifications: (loadParams?: LoadNotificationsParams) => void,
  markNotifications: (id: string[], readState?: "read" | "unread") => void,
  oldestNotificationCreatedAt: ?string,
  onNotificationClick: (id: string) => void,
  readNotifications: Notification[],
  unreadNotifications: Notification[],
|};

const notificationsListRef = React.createRef<VirtualizedList>();

const STICKY_HEADER_HEIGHT = 34;
const NOTIFICATION_LIST_ITEM_HEIGHT = 77;

export default function NotificationsList(props: Props) {
  const {
    allNotificationsLoaded,
    currentOrganizationFilter,
    currentUserId,
    isLoadingNotifications,
    isOnline,
    loadNotifications,
    markNotifications,
    oldestNotificationCreatedAt,
    onNotificationClick,
    readNotifications,
    unreadNotifications,
  } = props;

  const items = React.useMemo(() => {
    const items = [];

    if (unreadNotifications.length > 0) {
      items.push({
        height: STICKY_HEADER_HEIGHT,
        children: <StickyHeader>Unread</StickyHeader>,
      });

      unreadNotifications.forEach((notification) => {
        items.push({
          height: NOTIFICATION_LIST_ITEM_HEIGHT,
          children: (
            <NotificationListItem
              currentUserId={currentUserId}
              key={`notification-${notification.id}`}
              markNotifications={markNotifications}
              onClick={onNotificationClick}
              notification={notification}
            />
          ),
        });
      });
    }

    if (readNotifications.length > 0) {
      items.push({
        height: STICKY_HEADER_HEIGHT,
        children: <StickyHeader>Read</StickyHeader>,
      });

      readNotifications.forEach((notification) => {
        items.push({
          height: NOTIFICATION_LIST_ITEM_HEIGHT,
          children: (
            <NotificationListItem
              currentUserId={currentUserId}
              key={`notification-${notification.id}`}
              markNotifications={markNotifications}
              onClick={onNotificationClick}
              notification={notification}
            />
          ),
        });
      });
    }

    if (isLoadingNotifications) {
      items.push({
        children: (
          <Centered className={style.notificationsLoadingSpinner}>
            <Spinner small />
          </Centered>
        ),
      });
    }

    if (isOnline && !allNotificationsLoaded && !isLoadingNotifications) {
      items.push({
        children: (
          <Waypoint
            onEnter={() =>
              loadNotifications({
                limit: 20,
                maxCreatedAt: oldestNotificationCreatedAt,
                organizationId: currentOrganizationFilter,
              })
            }
          />
        ),
      });
    }

    return items;
  }, [
    allNotificationsLoaded,
    currentOrganizationFilter,
    currentUserId,
    isLoadingNotifications,
    isOnline,
    loadNotifications,
    markNotifications,
    oldestNotificationCreatedAt,
    onNotificationClick,
    readNotifications,
    unreadNotifications,
  ]);

  const resizeProps = React.useMemo(
    () => ({
      allNotificationsLoaded,
      isLoadingNotifications,
      readLength: readNotifications.length,
      unreadLength: unreadNotifications.length,
    }),
    [
      allNotificationsLoaded,
      isLoadingNotifications,
      readNotifications.length,
      unreadNotifications.length,
    ]
  );

  return (
    <div className={style.notificationsList}>
      <VirtualizedList
        items={items}
        ref={notificationsListRef}
        resizeProps={resizeProps}
      />
    </div>
  );
}
