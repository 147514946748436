// @flow
import { connect } from "react-redux";
import {
  createPaymentToken,
  recurlyStart3DSChallenge,
  recurlyError,
} from "core/actions/recurly";
import createConnector from "core/lib/createConnector";
import type { State, Dispatch } from "core/types";
import type { OwnProps, DispatchProps, Props } from "./index";

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    createPaymentToken: (
      form: EventTarget,
      onSuccessCallback?: (tokenId: string) => void,
      onErrorCallback?: (Error) => void
    ) => {
      dispatch(createPaymentToken(form, onSuccessCallback, onErrorCallback));
    },
    on3DSecureChallengeStarted: (actionToken: string) => {
      dispatch(recurlyStart3DSChallenge(actionToken));
    },
    handleSubmitError: (message?: ?string) => {
      dispatch(
        recurlyError({
          code: "transaction_error",
          fields: ["card"],
          details: [
            {
              field: "card",
              messages: [
                message ||
                  "There was an issue with your payment. Please try again.",
              ],
            },
          ],
        })
      );
    },
  };
}

export default createConnector<Props, OwnProps>(
  connect<Props, OwnProps, _, DispatchProps, State, Dispatch>(
    undefined,
    mapDispatchToProps
  )
);
