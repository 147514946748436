// @flow
import { static as Immutable } from "seamless-immutable";
import type { Action } from "core/types";

type State = {
  [id: string]: number,
};

const DEFAULT_STATE: State = Immutable.from({});

export default function paginationTotals(
  state: State = DEFAULT_STATE,
  action: Action
) {
  switch (action.type) {
    case "core/SET_PAGINATION_TOTAL": {
      return Immutable.merge(state, { [action.meta.id]: action.payload });
    }
    case "SIGNED_OUT": {
      return Immutable.from({});
    }
    default: {
      return state;
    }
  }
}
