// @flow
import * as React from "react";
import DelayedMount from "core/components/DelayedMount";
import ErrorBoundary from "core/components/ErrorBoundary";
import Centered from "../Centered";
import Loading from "../Loading";

type Props = {|
  loading: boolean,
  children?: React.Node | (() => React.Node | void),
  title?: string,
  small?: boolean,
  delay?: number,
  flex?: boolean,
  className?: string,
  qaSelector?: string,
|};

function Loaded(props: Props) {
  const fallback = (
    <Centered className={props.className} flex={props.flex}>
      <DelayedMount delay={props.delay}>
        <Loading
          title={props.title}
          small={props.small}
          qaSelector={props.qaSelector || "loading-spinner"}
        />
      </DelayedMount>
    </Centered>
  );

  if (props.loading) {
    return fallback;
  }

  return (
    <ErrorBoundary>
      <React.Suspense fallback={fallback}>
        {typeof props.children === "function"
          ? props.children()
          : props.children}
      </React.Suspense>
    </ErrorBoundary>
  );
}

Loaded.defaultProps = {
  delay: 200,
  loading: false,
};

export default Loaded;
