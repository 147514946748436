// @flow
import { PagesFetchRequest } from "core/requests/pages";
import type { ThunkAction } from ".";

export function loadPages(
  projectId: string,
  branchId: string,
  fileId: string,
  sha: string = "latest"
): ThunkAction {
  return PagesFetchRequest.perform({
    force: sha === "latest",
    params: {
      projectId,
      branchId,
      fileId,
      sha,
    },
  });
}
