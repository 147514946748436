// @flow
import { orderBy } from "lodash";
import { createSelector } from "reselect";
import { getRawEntities } from "core/selectors/entities";
import type { State } from "core/types";
import type { OAuthApplication } from "web/types";

export const getOAuthApplicationsEntities = (state: State) =>
  getRawEntities(state, "oAuthApplications");

export const getAllOAuthApplications: (State) => OAuthApplication[] =
  createSelector([getOAuthApplicationsEntities], (apps) =>
    orderBy(apps, "createdAt", "desc")
  );
