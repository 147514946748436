// @flow
import type { TeamsOptions, ReactRouterLocation } from "core/types";

export const CURRENTUSERTEAMS_LIMIT = 30;
export const PROJECT_TEAMS_LIMIT = 15;

function getSearch(location: ReactRouterLocation) {
  if (location.query && location.query.s) {
    return location.query.s;
  }
}

export function organizationTeamsOptions(
  location: ReactRouterLocation
): TeamsOptions {
  const searchFilter = getSearch(location);
  return {
    search: searchFilter,
  };
}
