// @flow
import { entitiesReceived } from "core/actions/entities";
import { entityMappingLoaded } from "core/actions/entityMappings";
import abstract, { Abstract } from "core/lib/abstract";
import { isDesktop } from "core/lib/platform";
import defineRequest from "core/requests";
import {
  normalizeFilesResponse,
  normalizeFileResponse,
} from "core/schemas/file";
import { getTransportModeForBranch } from "core/selectors/sdk";

type FilesFetchRequestParams = {|
  ...Abstract.BranchCommitDescriptor,
  transportMode?: "api" | "cli",
|};

export const FilesFetchRequest = defineRequest<
  FilesFetchRequestParams,
  FilesFetchRequestParams,
>({
  id(params) {
    return `files-${params.projectId}-${params.branchId}-${params.sha}-${
      params.transportMode || ""
    }`;
  },
  perform(params, dispatch, getState) {
    const { transportMode: transportModeOption, ...descriptor } = params;
    const transportMode = transportModeOption
      ? [transportModeOption]
      : getTransportModeForBranch(getState(), {
          projectId: params.projectId,
          branchId: params.branchId,
        });

    return abstract.files.list(descriptor, { transportMode });
  },
  onSuccess: (response, params, dispatch) => {
    const { entities, result } = normalizeFilesResponse(
      abstract.unwrap(response)
    );
    if (isDesktop) {
      dispatch(entityMappingLoaded(params.sha, result.libraries, "libraries"));
    } else {
      dispatch(
        entityMappingLoaded(
          `${params.projectId}-${params.branchId}`,
          result.libraries,
          "libraries"
        )
      );
    }
    dispatch(entitiesReceived(entities));
  },
  force: false,
});

type FileFetchRequestParams = {|
  ...Abstract.FileDescriptor,
  transportMode?: "api" | "cli",
|};

export const FileFetchRequest = defineRequest<
  FileFetchRequestParams,
  FileFetchRequestParams,
>({
  id(params) {
    return `file-${params.projectId}-${params.branchId}-${params.sha}-${
      params.fileId
    }-${params.transportMode || ""}`;
  },
  perform(params, dispatch, getState) {
    const { transportMode: transportModeOption, ...descriptor } = params;
    const transportMode = transportModeOption
      ? [transportModeOption]
      : getTransportModeForBranch(getState(), {
          projectId: params.projectId,
          branchId: params.branchId,
        });
    return abstract.files.info(descriptor, { transportMode });
  },
  onSuccess(response, params, dispatch) {
    const { entities } = normalizeFileResponse(response);
    return dispatch(entitiesReceived(entities));
  },
  force: false,
});
