// @flow
import { map, find, omit, omitBy } from "lodash";
import { static as Immutable } from "seamless-immutable";
import * as File from "core/models/file";
import * as Layer from "core/models/layer";
import * as Page from "core/models/page";
import type { Action, MultiSelectedEntities } from "core/types";

type State = {
  [branchId: string]: MultiSelectedEntities,
};

const DEFAULT_STATE = Immutable.from({});
const DEFAULT_BRANCH_STATE = { files: {}, pages: {}, layers: {} };

export default function collectionMultiselect(
  state: State = DEFAULT_STATE,
  action: Action
): State {
  switch (action.type) {
    case "core/ENTITIES_SELECTED": {
      const { files, pages, layers, projectId, branchId } = action.payload;
      const id = `${projectId}-${branchId}`;
      const existing = Immutable.getIn(state, [id]) || DEFAULT_BRANCH_STATE;

      return Immutable.setIn(state, [id], {
        files: { ...existing.files, ...files },
        pages: { ...existing.pages, ...pages },
        layers: { ...existing.layers, ...layers },
      });
    }
    case "core/ENTITIES_DESELECTED": {
      const { file, page, layers, projectId, branchId } = action.payload;
      const key = `${projectId}-${branchId}`;
      const existing = Immutable.getIn(state, [key]) || DEFAULT_BRANCH_STATE;

      const newLayers = layers
        ? omit(
            existing.layers,
            map(layers, (l) => Layer.uniqueId(l))
          )
        : omitBy(existing.layers, (l) => {
            if (file) {
              return l.fileId === file.id && l.sha === file.sha;
            }
            if (page) {
              return (
                l.pageId === page.id &&
                l.fileId === page.fileId &&
                l.sha === page.sha
              );
            }
            return false;
          });

      const pages = page
        ? omit(existing.pages, Page.uniqueId(page))
        : omitBy(existing.pages, (p) => {
            if (file) {
              return p.fileId === file.id && p.sha === file.sha;
            }
            if (layers) {
              return !!find(layers, {
                fileId: p.fileId,
                pageId: p.id,
                sha: p.sha,
              });
            }
            return false;
          });

      const files = file
        ? omit(existing.files, File.uniqueId(file))
        : omitBy(existing.files, (f) => {
            if (page) {
              return f.id === page.fileId && f.sha === page.sha;
            }
            if (layers) {
              return !!find(layers, { fileId: f.id, sha: f.sha });
            }
            return false;
          });

      return Immutable.setIn(state, [key], { layers: newLayers, pages, files });
    }
    case "core/ENTITIES_CLEAR_SELECTED": {
      const { projectId, branchId } = action.payload;
      const id = `${projectId}-${branchId}`;

      return Immutable.without(state, [id]);
    }
    default:
      return state;
  }
}
