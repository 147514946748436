// @flow
import * as React from "react";
import { connect } from "react-redux";
import Button from "core/components/Button";
import Empty from "core/components/Empty";
import Flex from "core/components/Flex";
import { helpCollectionsUrl, organizationBillingUrl } from "core/lib/urls";
import { getOrganizationPolicy } from "core/selectors/policies";
import type { State, Dispatch } from "core/types";

export type OwnProps = {|
  organizationId: string,
  children: React.Node,
|};

export type StateProps = {|
  disabled: boolean,
  canUpgradeSubscription: boolean,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
};

function ProjectCollectionsDisabled(props: Props) {
  return props.disabled ? (
    <Flex>
      <Empty
        title="Collections are available on our Business plan"
        description={
          <React.Fragment>
            Collections allow contributors in Abstract to aggregate a set of
            artboards/layers for presentation purposes.{" "}
            <a
              href={helpCollectionsUrl()}
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more…
            </a>
          </React.Fragment>
        }
      >
        {props.canUpgradeSubscription && props.organizationId && (
          <Button
            component="a"
            href={organizationBillingUrl(props.organizationId)}
            primary
            large
          >
            Upgrade to Business…
          </Button>
        )}
      </Empty>
    </Flex>
  ) : (
    props.children
  );
}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const policy = getOrganizationPolicy(state, {
    organizationId: props.organizationId,
  });

  return {
    // `undefined` won't cut it here - we need to know that the organization has finished loading
    // and that this flag is explicitly false
    disabled: policy.showCollections === false,
    canUpgradeSubscription: policy.upgradeSubscription,
  };
}

export default connect<Props, OwnProps, StateProps, _, State, Dispatch>(
  mapStateToProps
)(ProjectCollectionsDisabled);
