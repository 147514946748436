// @flow
import * as React from "react";
import Card from "core/components/Card";
import Error from "core/components/Empty/Error";
import NoOrganizations from "core/components/Empty/NoOrganizations";
import Flex from "core/components/Flex";
import FormHeader from "core/components/FormHeader";
import Heading from "core/components/Heading";
import List from "core/components/List";
import Loaded from "core/components/Loaded";
import Media from "core/components/Media";
import OrganizationLogo from "core/components/OrganizationLogo";
import SettingsForm from "core/components/SettingsForm";
import type { Organization } from "core/types";
import connector from "./connector";
import style from "./style.scss";

export type StateProps = {|
  error: boolean,
  isLoading: boolean,
  organizations: Organization[],
|};

export type OwnProps = {|
  setOrganizationId: (string) => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
};

function IntegrationChooseOrganization(props: Props) {
  const header = (
    <Media desktop>
      {(desktop) => (
        <FormHeader
          mobile={!desktop}
          heading="Please Choose An Organization"
          icon="organization"
        />
      )}
    </Media>
  );

  const isEmpty = !props.organizations.length;

  return (
    <SettingsForm
      documentTitle="ChooseOrganization"
      header={header}
      className={style.settingsForm}
    >
      <Loaded
        loading={props.isLoading}
        flex
        title="Loading organizations…"
        qaSelector="loading-spinner"
      >
        {() => {
          if (props.error) {
            return <Error flex />;
          }

          if (isEmpty) {
            return <NoOrganizations flex />;
          }

          return (
            <Media mobile>
              {(mobile) => (
                <Flex column className={style.orgMenu}>
                  <List className={style.orgList} mobile={mobile}>
                    {props.organizations.map((org) => (
                      <Card
                        key={org.id}
                        list={true}
                        onClick={(e) => {
                          props.setOrganizationId(org.id);
                        }}
                        className={style.card}
                        innerClassName={style.cardContents}
                        mobile={mobile}
                      >
                        <OrganizationLogo
                          size={40}
                          alt={org.name}
                          src={org.logoUrl}
                        />
                        <div className={style.wrap}>
                          <div className={style.headingWrap}>
                            <Heading
                              level="3"
                              size="m"
                              className={style.heading}
                            >
                              {org.name}
                            </Heading>
                          </div>
                        </div>
                      </Card>
                    ))}
                  </List>
                </Flex>
              )}
            </Media>
          );
        }}
      </Loaded>
    </SettingsForm>
  );
}

export default connector(IntegrationChooseOrganization);
