// @flow
/* global HTMLElement */
import classnames from "classnames";
import last from "lodash/last";
import * as React from "react";
import { Waypoint } from "react-waypoint";
import scrollIntoView from "smooth-scroll-into-view-if-needed";
import CommentCreateForm from "core/components/CommentCreateForm";
import CommentsList from "core/components/CommentsList";
import CommitTitle from "core/components/CommitTitle";
import NoComments from "core/components/Empty/NoComments";
import Offline from "core/components/Empty/Offline";
import Icon from "core/components/Icon";
import Time from "core/components/Time";
import { V3Link as Link } from "core/lib/router";
import type { Branch, Comment, Commit } from "core/types";
import CommitButton from "./CommitButton";
import style from "./style.scss";
import type { Props as LayerCommentsProps } from ".";

type Props = LayerCommentsProps & {
  branch: ?Branch,
  commitComments: Comment[],
  commit: ?Commit,
  selected: boolean,
  groupRef: *,
  getScrollableNode: () => ?HTMLElement,
  onClick: (SyntheticEvent<>) => void,
  onFormVisible: () => void,
  onFormHidden: () => void,
  allowExtraFormBottomSpacing?: boolean,
};

export default class CommentGroup extends React.Component<Props> {
  form: *;
  container: ?HTMLDivElement;

  formRef = (ref: *) => (this.form = ref);

  scrollToForm = (opts?: Object) => {
    if (this.form && !this.props.collapsed) {
      scrollIntoView(this.form, {
        block: "end",
        duration: 500,
        behavior: "smooth",
        ...opts,
      });
    }
  };

  componentDidUpdate(prevProps: Props) {
    const comments = this.props.commitComments;
    const prevComments = prevProps.commitComments;

    if (
      this.props.selected &&
      comments &&
      (!prevComments || comments.length > prevComments.length)
    ) {
      const latest = last(comments);

      if (latest.pending && !latest.error) {
        this.scrollToForm({ scrollMode: "if-needed", behavior: "auto" });
      }
    }

    if (
      prevProps.selected !== this.props.selected &&
      this.props.selected &&
      !this.props.collapsed &&
      !this.props.scrollToCommentId
    ) {
      scrollIntoView(this.container, {
        block: "end",
        duration: 500,
        behavior: "smooth",
      });
    }
  }

  render() {
    const {
      branch,
      parentBranch,
      parentBranchName,
      selected,
      commit,
      sha,
      commitComments,
      groupRef,
      getScrollableNode,
      onFormVisible,
      onFormHidden,
      onClick,
      isLoadingLayer,
      comments,
      collapsed,
      scrollToCommentId,
      highlightedCommentId,
      allowExtraFormBottomSpacing,
      ...props
    } = this.props;

    const isBranch = branch && parentBranch && branch.startedAtSha === sha;

    const title = isBranch
      ? `Branched from "${parentBranchName || "…"}"`
      : commit
      ? commit.title
      : "";

    const empty = props.isOffline ? (
      <Offline mini description="Connect to the internet to view comments" />
    ) : props.isLoggedIn ? (
      <NoComments mini />
    ) : (
      <NoComments mini description="This commit has no comments." />
    );

    const TitleComponent = selected ? "div" : Link;

    return (
      <div
        className={classnames(style.root, {
          [style.toggleResolvedCommentsSpacing]: allowExtraFormBottomSpacing,
        })}
        ref={(ref) => (this.container = ref)}
      >
        <TitleComponent
          key={`${sha}-heading`}
          onClick={selected ? undefined : onClick}
          className={classnames(style.title, {
            [style.titleLink]: !selected,
            [style.titleLoading]: !commit,
          })}
        >
          <div title={title} className={style.titleInner}>
            <Icon type="commit" className={style.icon} />
            {commit ? (
              <CommitTitle
                type={isBranch ? "BRANCH" : commit.type}
                title={title}
              />
            ) : (
              "Loading…"
            )}
          </div>
          {commit && (
            <span className={style.time}>
              <span className={style.dot}>·</span>
              <Time date={commit.time} />
            </span>
          )}
        </TitleComponent>
        <div
          ref={groupRef}
          className={classnames(props.className, style.group, {
            [style.disabled]: !selected,
          })}
        >
          {selected && (
            <Waypoint
              onEnter={onFormVisible}
              onLeave={onFormHidden}
              scrollableAncestor={getScrollableNode()}
            />
          )}
          {!commitComments.length ? (
            empty
          ) : (
            <CommentsList
              {...props}
              sha={commit ? sha : ""}
              comments={commitComments}
              disabled={!selected}
              autoScroll={false}
              replyDisabled={isLoadingLayer}
              scrollToCommentId={collapsed ? "" : scrollToCommentId}
              highlightedCommentId={collapsed ? "" : highlightedCommentId}
              pinCommentsEnabled={this.props.pinCommentsEnabled}
              resolveCommentsEnabled={this.props.resolveCommentsEnabled}
              highlightPinnedComment
              enableHidingResolvedComments
              isShowingResolvedComments={this.props.isShowingResolvedComments}
              tintBackgroundOnHover={this.props.tintBackgroundOnHover}
            />
          )}
          {selected ? (
            this.props.isLoggedIn && (
              <div className={style.form} ref={this.formRef}>
                <CommentCreateForm
                  disabled={isLoadingLayer}
                  projectId={props.projectId}
                  branchId={props.branchId}
                  sha={sha}
                  fileId={props.fileId}
                  pageId={(props.pageId && props.pageId) || ""}
                  layerId={props.layerId}
                  collectionId={props.collectionId}
                  form={props.form}
                  onFocus={this.scrollToForm}
                  onFormChanged={props.onFormChanged}
                  collapsible
                  isAnnotatable
                  isPubliclyShared={this.props.isPubliclyShared}
                />
              </div>
            )
          ) : (
            <CommitButton
              onClick={onClick}
              getScrollableNode={getScrollableNode}
            />
          )}
          {selected && (
            <Waypoint
              onEnter={onFormVisible}
              onLeave={onFormHidden}
              scrollableAncestor={getScrollableNode()}
            />
          )}
        </div>
      </div>
    );
  }
}
