// @flow
import { entitiesReceived } from "core/actions/entities";
import { request } from "core/actions/requests";
import * as API from "../api";
import type { FormValues } from "../models/form";
import { normalizeMe } from "../schemas/me";
import { sessionLoaded } from "./session";
import type { ThunkAction } from ".";

export function accountFormSubmit(id: string, values: FormValues): ThunkAction {
  return (dispatch) => {
    dispatch(
      request(id, () => API.updateAccount(values), {
        onSuccess: (me) => {
          const { entities } = normalizeMe(me);
          dispatch(entitiesReceived(entities));
          dispatch(sessionLoaded(me));
        },
      })
    );
  };
}
