// @flow
import * as Request from "core/models/request";
import type { Request as TRequest, State } from "core/types";

export function getRequest(state: State, id: string): TRequest {
  return state.requests[id] || Request.DEFAULT;
}

export function getError(request: TRequest) {
  return request.error;
}
