// @flow
import * as Abstract from "abstract-sdk";
import * as React from "react";
import { useSelector } from "react-redux";
import { descriptorPath } from "core/lib/routes";
import { getOrganizationForProject } from "core/selectors/organizations";
import { getProject } from "core/selectors/projects";
import Result, { type CommonProps } from "./Result";

type Props = {|
  ...CommonProps,
  descriptor: Abstract.ProjectDescriptor,
|};

export default function ResultProject(props: Props) {
  const { descriptor, ...rest } = props;
  const { projectId } = descriptor;

  const project = useSelector((state) => getProject(state, { projectId }));
  const organization = useSelector((state) =>
    getOrganizationForProject(state, { projectId })
  );

  if (!organization || !project) {
    return null;
  }

  return (
    <Result
      to={descriptorPath(descriptor)}
      icon="project-alt"
      iconColor={project.color}
      title={project.name}
      context={organization.name}
      {...rest}
    />
  );
}
