// @flow
import React from "react";
import Button from "core/components/Button";
import Icon from "core/components/Icon";
import TeamListItem from "core/components/TeamListItem";
import type { Team, User, Project } from "core/types";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  organizationId: string,
  team: Team,
  project: Project,
  onAddTeam: (projectId: string, teamId: string) => void,
  highlight?: string,
|};

export type StateProps = {|
  inProject: boolean,
  isLoadingMembers: boolean,
  isLoadingAddTeamToProject: boolean,
  teamMembers: User[],
|};

export type DispatchProps = {|
  onLoad: () => Promise<void>,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

function TeamAddItem(props: Props) {
  const handleAddTeam = async () => {
    const { project, team } = props;
    await props.onAddTeam(project.id, team.id);
  };

  return (
    <TeamListItem
      isLoading={props.isLoadingMembers}
      component="div"
      team={props.team}
      className={style.team}
      teamMembers={props.teamMembers}
      teamMembershipsTotal={props.teamMembers.length}
      highlight={props.highlight}
    >
      <span className={style.actions}>
        {props.inProject ? (
          <Icon type="checkmark" tint />
        ) : (
          <Button
            onClick={handleAddTeam}
            disabled={props.isLoadingAddTeamToProject}
            primary
          >
            Add
          </Button>
        )}
      </span>
    </TeamListItem>
  );
}

export default connector(TeamAddItem);
