// @flow
import classnames from "classnames";
import * as React from "react";
import style from "./style.scss";

type Props = {
  className?: string,
  qaSelector?: string,
  type?: string,
};

export default function ButtonUnstyled(props: Props) {
  const { className, qaSelector, type = "button", ...rest } = props;

  return (
    <button
      {...rest}
      className={classnames(style.buttonUnstyled, className)}
      type={type}
      data-qa={qaSelector}
    />
  );
}
