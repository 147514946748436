// @flow
import invariant from "invariant";
import { connect } from "react-redux";
import { isOnline } from "abstract-di/selectors";
import { withData } from "core/components/DataLoader";
import createConnector from "core/lib/createConnector";
import * as Request from "core/models/request";
import { OrganizationFetchRequest } from "core/requests/organizations";
import { ProjectFetchRequest } from "core/requests/projects";
import { ShareLinkCreateRequest } from "core/requests/shareLinks";
import { getOrganization } from "core/selectors/organizations";
import {
  getOrganizationPolicy,
  getProjectPolicy,
} from "core/selectors/policies";
import { getProject } from "core/selectors/projects";
import { getShareLinkForKind } from "core/selectors/shareLinks";
import type { Dispatch, State } from "core/types";
import type { Props, OwnProps, StateProps, DispatchProps } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { inputShare } = props;
  const project = getProject(state, { ...inputShare.descriptor });
  const policy = project
    ? getOrganizationPolicy(state, { organizationId: project.organizationId })
    : null;

  const request = ShareLinkCreateRequest.getRequest(state, inputShare);
  const shareLink = getShareLinkForKind(state, { inputShare });

  const projectVisibility = project ? project.visibility : undefined;
  const projectPolicy = getProjectPolicy(state, {
    projectId: inputShare.descriptor.projectId,
  });

  return {
    shareLink,
    canShowHandoff: policy ? policy.showHandoff : false,
    isGenerating: Request.isFirstLoading(request),
    hasError: Request.hasError(request),
    projectVisibility,
    organization: project
      ? getOrganization(state, { organizationId: project.organizationId })
      : null,
    canShare: projectPolicy && projectPolicy.share,
    isOnline: isOnline(state),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    onLoad() {
      return dispatch(async (dispatch, getState) => {
        if (isOnline(getState())) {
          await dispatch(
            ProjectFetchRequest.perform({
              params: { projectId: props.inputShare.descriptor.projectId },
            })
          );

          const project = getProject(getState(), {
            ...props.inputShare.descriptor,
          });

          invariant(project, "Project required");

          dispatch(
            OrganizationFetchRequest.perform({
              params: { organizationId: project.organizationId },
            })
          );

          dispatch(
            ShareLinkCreateRequest.perform({
              params: props.inputShare,
            })
          );
        }
      });
    },
  };
}

export default createConnector<Props, OwnProps>(
  connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch>(
    mapStateToProps,
    mapDispatchToProps
  ),
  withData((props) => ({
    isOnline: props.isOnline,
    kind: props.inputShare.kind,
    ...props.inputShare.descriptor,
    ...props.inputShare.options,
  }))
);
