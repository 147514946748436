// @flow
import type { State } from "core/types";

export function getNotificationsOrganizationFilter(state: State): ?string {
  return state.notificationsMeta.currentOrganizationFilter;
}

export function areAllNotificationsLoaded(
  state: State,
  params: {
    organizationId: ?string,
  }
): boolean {
  const { organizationId } = params;

  // If all notifications have been loaded, ignore organizationId
  if (state.notificationsMeta.allLoaded || !organizationId) {
    return state.notificationsMeta.allLoaded;
  }

  return state.notificationsMeta.organizations[organizationId].allLoaded;
}

export function getPreviousOldestCreatedAt(
  state: State,
  params: {
    organizationId: ?string,
  }
): ?string {
  const { organizationId } = params;

  if (organizationId) {
    const organizationMeta =
      state.notificationsMeta.organizations[organizationId];

    return organizationMeta ? organizationMeta.oldestCreatedAt : null;
  }

  return state.notificationsMeta.oldestCreatedAt;
}

export function getNotificationsOpenState(state: State): boolean {
  return state.notificationsMeta.open;
}
