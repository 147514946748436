// @flow
import { isUndefined, omitBy, isEqual, isObjectLike } from "lodash";
import * as LayerData from "../models/layerData";
import type {
  LayerChangeset,
  LayerChange,
  LayerDataset,
  LayerInstance,
  LayerShadows,
  LayerTextStyle,
} from "../types";
import mapLayerChildren, { visitEachLayer } from "./mapLayerChildren";

export const CHANGE_STATUS = {
  ADDED: "added",
  REMOVED: "removed",
  CHANGED: "changed",
};

export default function buildLayerChangesets(
  previous: LayerDataset,
  current: LayerDataset
): { [layerKey: string]: LayerChangeset } {
  const changeset = {};
  const previousLayers: { [layerKey: string]: LayerInstance } = {};

  visitEachLayer(
    previous.layers,
    previous.layers[previous.layerId],
    (layerData) => {
      previousLayers[LayerData.key(layerData)] = layerData;
    }
  );

  if (current) {
    visitEachChange(
      current.layers,
      current.layers[current.layerId],
      previousLayers,
      (layerData, change) => {
        changeset[LayerData.key(layerData)] = change;
      }
    );
  }

  return changeset;
}

function visitEachChange(layers, targetLayerData, sourceLayers, callback) {
  const key = LayerData.key(targetLayerData);
  const sourceChildLayer = sourceLayers[key];
  const changedChildren = [];

  mapLayerChildren(layers, targetLayerData, (targetChildLayer) => {
    visitEachChange(
      layers,
      targetChildLayer,
      sourceLayers,
      (childLayerData, change) => {
        if (change) {
          changedChildren.push(childLayerData);
        }

        callback(childLayerData, change);
      }
    );
  });

  let change = buildLayerChangeset(sourceChildLayer, targetLayerData);

  if (!change && changedChildren.length > 0) {
    change = {
      status: CHANGE_STATUS.CHANGED,
      indirect: true,
      properties: {},
    };
  }

  callback(targetLayerData, change);
}

function buildLayerChangeset(
  sourceInstance: ?LayerInstance,
  targetInstance: ?LayerInstance
): ?LayerChangeset {
  const layerChanges = { status: CHANGE_STATUS.CHANGED, properties: {} };

  if (!sourceInstance && targetInstance) {
    layerChanges.status = CHANGE_STATUS.ADDED;
  }
  if (!targetInstance && sourceInstance) {
    layerChanges.status = CHANGE_STATUS.REMOVED;
  }

  const sourceProperties = sourceInstance ? sourceInstance.properties : {};
  const targetProperties = targetInstance ? targetInstance.properties : {};

  layerChanges.properties = omitBy(
    {
      styleName: getChange(
        sourceProperties.styleName,
        targetProperties.styleName
      ),
      name: getChange(sourceProperties.name, targetProperties.name),
      isVisible: getChange(
        sourceProperties.isVisible,
        targetProperties.isVisible
      ),
      width: getChange(sourceProperties.width, targetProperties.width),
      height: getChange(sourceProperties.height, targetProperties.height),
      x: getChange(sourceProperties.x, targetProperties.x),
      y: getChange(sourceProperties.y, targetProperties.y),
      rotation: getChange(sourceProperties.rotation, targetProperties.rotation),
      opacity: getChange(sourceProperties.opacity, targetProperties.opacity),
      hasClippingMask: getChange(
        sourceProperties.hasClippingMask,
        targetProperties.hasClippingMask
      ),
      underClippingMask: getChange(
        sourceProperties.underClippingMask,
        targetProperties.underClippingMask
      ),
      textContent: getChange(
        sourceProperties.textContent,
        targetProperties.textContent
      ),
      blendMode: getChange(
        sourceProperties.blendMode,
        targetProperties.blendMode
      ),
      backgroundColor: getChange(
        sourceProperties.backgroundColor,
        targetProperties.backgroundColor
      ),
      borderRadius: getChange(
        sourceProperties.borderRadius,
        targetProperties.borderRadius
      ),
      text: getTextChange(sourceProperties.text, targetProperties.text),
      fills: getChange(sourceProperties.fills, targetProperties.fills),
      borders: getChange(sourceProperties.borders, targetProperties.borders),
      shadows: getShadowsChange(
        sourceProperties.shadows,
        targetProperties.shadows
      ),
      resizingConstraint: getChange(
        sourceProperties.resizingConstraint,
        targetProperties.resizingConstraint
      ),
    },
    isUndefined
  );
  if (!Object.keys(layerChanges.properties).length) {
    return;
  }
  return layerChanges;
}

function getChange<T>(previous: T, current: T): ?LayerChange<T> {
  if (current === previous) {
    return;
  }
  if (isObjectLike(previous || current) && isEqual(previous, current)) {
    return;
  }

  if (isUndefined(current) && !isUndefined(previous)) {
    return { status: CHANGE_STATUS.REMOVED, previous };
  }
  if (isUndefined(previous) && !isUndefined(current)) {
    return { status: CHANGE_STATUS.ADDED, current };
  }

  return { status: CHANGE_STATUS.CHANGED, previous, current };
}

function getTextChange(
  sourceText: ?LayerTextStyle,
  targetText: ?LayerTextStyle
): ?Object {
  if (isEqual(sourceText, targetText)) {
    return;
  }

  const source = sourceText || {};
  const target = targetText || {};

  return omitBy(
    {
      styleName: getChange(source.styleName, target.styleName),
      fixed: getChange(source.fixed, target.fixed),
      fontName: getChange(source.fontName, target.fontName),
      fontSize: getChange(source.fontSize, target.fontSize),
      lineHeight: getChange(source.lineHeight, target.lineHeight),
      characterSpacing: getChange(
        source.characterSpacing,
        target.characterSpacing
      ),
      paragraphSpacing: getChange(
        source.paragraphSpacing,
        target.paragraphSpacing
      ),
      horizontalAlignment: getChange(
        source.horizontalAlignment,
        target.horizontalAlignment
      ),
      verticalAlignment: getChange(
        source.verticalAlignment,
        target.verticalAlignment
      ),
      color: getChange(source.color, target.color),
      listStyle: getChange(source.listStyle, target.listStyle),
      textTransform: getChange(source.textTransform, target.textTransform),
      textDecoration: getChange(source.textDecoration, target.textDecoration),
    },
    isUndefined
  );
}

function getShadowsChange(
  sourceShadows: ?LayerShadows,
  targetShadows: ?LayerShadows
): ?Object {
  const { inner: sourceInner, outer: sourceOuter } = sourceShadows || {};
  const { inner: targetInner, outer: targetOuter } = targetShadows || {};

  const innerChange = getChange(sourceInner, targetInner);
  const outerChange = getChange(sourceOuter, targetOuter);

  if (!innerChange && !outerChange) {
    return;
  }

  return omitBy({ inner: innerChange, outer: outerChange }, isUndefined);
}
