// @flow
import { connect } from "react-redux";
import { MatchingOrganizationsFetchRequest } from "core/requests/organizations";
import type { State, Dispatch } from "core/types";
import type { OwnProps, StateProps, DispatchProps, Props } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  return {
    isLoading: MatchingOrganizationsFetchRequest.isLoading(state, {}),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    getMatchingOrganizations: (onSuccess) => {
      dispatch(
        MatchingOrganizationsFetchRequest.perform({
          params: {},
          onSuccess,
        })
      );
    },
  };
}

export default connect<Props, OwnProps, _, DispatchProps, State, Dispatch>(
  mapStateToProps,
  mapDispatchToProps
);
