// @flow
import * as React from "react";
import ContextMenu, { type ContextMenuInterface } from ".";

type State = {
  showMenu: boolean,
};

type Target = (ref?: React.Ref<any>, buttonProps: {}) => React.Node;

export type Props = {
  ...ContextMenuInterface,
  children: (
    handleShow: (event: SyntheticEvent<>) => void,
    renderMenuTarget: (target: Target) => React.Node
  ) => React.Node,
};

export default function contextMenuWrapper<P: Props>(
  WrappedComponent: React.ComponentType<{
    ...ContextMenuInterface,
    innerRef: React.Ref<typeof ContextMenu>,
  }>
): React.ComponentType<P> {
  return class ContextMenuWrapper extends React.Component<P, State> {
    menu: ?ContextMenu;
    state = { showMenu: false };

    handleShow = (event: SyntheticEvent<>) => {
      event.preventDefault();
      event.stopPropagation();
      this.setState({ showMenu: true });
    };

    handleClose = () => {
      this.setState({ showMenu: false }, () => (this.menu = null));
    };

    handleRef = (ref: ?ContextMenu) => {
      if (ref && !this.menu) {
        this.menu = ref;
        ref.showMenu && ref.showMenu();
      }
    };

    getButtonProps = () => ({
      "aria-haspopup": true,
      "aria-expanded": this.state.showMenu,
      role: "button",
    });

    renderMenuTarget = (target: Target) => {
      if (!this.state.showMenu) {
        return target(undefined, this.getButtonProps());
      }
      return (
        <WrappedComponent
          {...this.props}
          innerRef={this.handleRef}
          onAfterClose={this.handleClose}
        >
          {(showMenu, menuRef) => target(menuRef, this.getButtonProps())}
        </WrappedComponent>
      );
    };

    render() {
      return this.props.children(this.handleShow, this.renderMenuTarget);
    }
  };
}
