// @flow
import { normalize, schema } from "normalizr";
import { changeset, layer, commit, file, page, sharedItem } from "core/schema";

const changesetResponseSchema = new schema.Object({
  commit,
  changeset,
  layers: [layer],
  files: [file],
  pages: [page],
  sharedData: [sharedItem],
});

export function normalizeChangesetResponse(changesetResponse: any) {
  return normalize(changesetResponse, changesetResponseSchema);
}
