// @flow
import classnames from "classnames";
import * as React from "react";
import HorizontalSeparator from "core/components/HorizontalSeparator";
import InputCheckbox from "core/components/InputCheckbox";
import type { WebhookGroup } from "core/types";
import style from "./style.scss";

type Props = {
  data: string[],
  groupIds: string[],
  onChangeData?: (data: string[]) => void,
  webhookGroup: WebhookGroup,
};

export default class EventGroup extends React.Component<Props> {
  onChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    let { data } = this.props;
    const { groupIds, onChangeData } = this.props;

    if (event.target.checked) {
      groupIds.forEach((id) => {
        !data.includes(id) && data.push(id);
      });
    } else {
      groupIds.forEach((id) => {
        data = data.filter((typeId) => typeId !== id);
      });
    }
    onChangeData && onChangeData(data);
  };

  render = () => {
    let { data, groupIds, webhookGroup } = this.props;

    return (
      <React.Fragment>
        <HorizontalSeparator />
        <InputCheckbox
          checkboxClass={style.checkbox}
          checked={groupIds.every((id) => data.includes(id))}
          key={webhookGroup.id}
          label={
            <React.Fragment>
              <span className={style.eventName}>{webhookGroup.name}</span>
              <span className={style.eventMeta}>{groupIds.length} events</span>
            </React.Fragment>
          }
          labelClass={style.checkboxLabel}
          onChange={this.onChange}
          responsive
          wrapperClass={classnames(style.checkboxWrapper, style.groupWrapper)}
        />
      </React.Fragment>
    );
  };
}
