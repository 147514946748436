// @flow
import { orderBy } from "lodash";
import { createSelector } from "reselect";
import { getRawEntities } from "core/selectors/entities";
import type { State } from "core/types";
import type { AccessToken } from "web/types";

const getAccessTokenEntities = (state: State) =>
  getRawEntities(state, "accessTokens");

export const getAllAccessTokens: (State) => AccessToken[] = createSelector(
  [getAccessTokenEntities],
  (tokens) => orderBy(tokens, "createdAt", "desc")
);
