// @flow
/* global document,HTMLCanvasElement */
import loadImageSync from "blueimp-load-image";

const AVATAR_CONTENT_TYPES: Array<string> = ["image/jpeg", "image/png"];

function loadCanvasFromFile(file: File): Promise<HTMLCanvasElement> {
  return new Promise((resolve, reject) => {
    loadImageSync(
      file,
      (image) => {
        if (image.type === "error") {
          reject();
        }
        resolve(image);
      },
      { orientation: true }
    );
  });
}

async function getDataUrlFromFile(file: File): Promise<string> {
  const canvas = await loadCanvasFromFile(file);
  return canvas.toDataURL(file.type);
}

function getImageDimensions(
  src: string
): Promise<{| width: number, height: number |}> {
  return new Promise((resolve, reject) => {
    const img = document.createElement("img");
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.onerror = () => reject();
    img.src = src;
  });
}

export default (async function loadFile(file: File): Promise<[string, string]> {
  if (AVATAR_CONTENT_TYPES.indexOf(file.type) === -1) {
    return ["", "Invalid file type"];
  }

  let dataUrl;
  try {
    dataUrl = await getDataUrlFromFile(file);
  } catch (err) {
    return ["", "Error reading file"];
  }

  let dimensions;
  try {
    dimensions = await getImageDimensions(dataUrl);
  } catch (err) {
    return ["", "Error loading file as an image"];
  }

  const { width, height } = dimensions;

  if (width < 256 || height < 256) {
    return ["", "Image is too small"];
  }

  return [dataUrl, ""];
});
