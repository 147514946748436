// @flow
import * as React from "react";
import Flex from "core/components/Flex";
import Icon from "core/components/Icon";
import Time from "core/components/Time";
import style from "./style.scss";

type Props = {
  title: string,
  timestamp: string,
  icon?: string,
};

export default function ReviewTitle({ title, timestamp, icon }: Props) {
  return (
    <Flex align="center" justify="space-between">
      <span className={style.titleFlexLeft}>
        {icon ? (
          <Flex align="center">
            <Icon type={icon} className={style.titleIcon} />
            <p className={style.titleText}>{title}</p>
          </Flex>
        ) : (
          <p className={style.titleText}>{title}</p>
        )}
      </span>
      <div className={style.dateWrapper}>
        <Time date={timestamp} className={style.date} />
      </div>
    </Flex>
  );
}
