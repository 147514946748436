// @flow
import { connect } from "react-redux";
import { isOnline } from "abstract-di/selectors";
import { trackShareLinkCopied } from "core/actions/shareLinks";
import { showToast } from "core/actions/toasts";
import { withData } from "core/components/DataLoader";
import createConnector from "core/lib/createConnector";
import * as Request from "core/models/request";
import { assembleShareLinkUrl } from "core/models/shareLink";
import { ShareLinkCreateRequest } from "core/requests/shareLinks";
import { getShareLinkForKind } from "core/selectors/shareLinks";
import type { Dispatch, State } from "core/types";
import type { Props, OwnProps, StateProps, DispatchProps } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { inputShare, doNotCreateShareLink, mode } = props;

  const request = ShareLinkCreateRequest.getRequest(state, inputShare);
  const shareLink = getShareLinkForKind(state, { inputShare });

  const url = props.url
    ? props.url
    : doNotCreateShareLink
    ? shareLink && shareLink.url
    : assembleShareLinkUrl(shareLink, {
        collectionLayerId: inputShare.collectionLayerId,
        sha: inputShare.descriptor.sha,
        commentId: inputShare.descriptor.commentId,
        mode,
        sectionId: inputShare.options && inputShare.options.sectionId,
        present:
          inputShare.options && inputShare.options.present === "true"
            ? "true"
            : undefined,
      });

  return {
    error: Request.hasError(request),
    isGenerating: url ? false : Request.isLoading(request),
    url,
    isOnline: isOnline(state),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    onLoad() {
      if (!props.doNotCreateShareLink) {
        const { inputShare } = props;
        return dispatch((dispatch, getState) => {
          const shareLink = getShareLinkForKind(getState(), { inputShare });
          if (!shareLink || !shareLink.url) {
            dispatch(ShareLinkCreateRequest.perform({ params: inputShare }));
          }
        });
      }
    },
    showToast() {
      if (props.onCopySuccess) {
        props.onCopySuccess();
      }
      dispatch(showToast({ text: "Share link copied to clipboard" }));
    },
    onCopy() {
      return dispatch(trackShareLinkCopied(props.inputShare));
    },
  };
}

export default createConnector<Props, OwnProps>(
  connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch>(
    mapStateToProps,
    mapDispatchToProps
  ),
  withData((props) => ({
    organizationId: props.organizationId,
    kind: props.inputShare.kind,
    ...props.inputShare.descriptor,
    ...props.inputShare.options,
  }))
);
