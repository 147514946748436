// @flow
/* global window document */
import {
  SortableElement,
  SortableHandle,
} from "@elasticprojects/react-sortable-hoc";
import classnames from "classnames";
import empty from "empty";
import * as React from "react";
import { findDOMNode } from "react-dom";
import scrollIntoView from "smooth-scroll-into-view-if-needed";
import CopyLinkButton from "core/components/CopyLinkButton";
import Icon from "core/components/Icon";
import InputRich from "core/components/InputRich";
import Markdown from "core/components/Markdown";
import KeyCode from "core/lib/keycode";
import { withRouter, type RouterProps } from "core/lib/router";
import type { InputShare } from "core/types";
import style from "./style.scss";

type OwnProps = {|
  sectionId?: string,
  editable?: boolean,
  textContent: string,
  large: boolean,
  className?: string,
  disableActions?: boolean,
  handleRemoveText?: () => void,
  onSave?: (textContent: string) => void,
  useDragHandle?: boolean,
  forceShowBackground?: boolean,
  inputShare?: InputShare,
|};

type Props = {
  ...RouterProps,
  ...OwnProps,
};

const DraggableGripIcon = SortableHandle(({ className }) => (
  <Icon type="grip" className={className} small />
));

function Text(props: Props) {
  const container = React.useRef<HTMLDivElement | null>(null);
  const input = React.useRef(null);

  const [isFocused, setFocus] = React.useState(false);
  const [currentText, setCurrentText] = React.useState(props.textContent || "");
  const [isDragHandleShowing, setShowDragHandle] = React.useState(
    !!(props.useDragHandle && currentText.length)
  );

  const handleFocus = () => {
    setFocus(true);
  };

  React.useEffect(() => {
    if (isFocused && input.current) {
      input.current.focus();
    }
  }, [isFocused]);

  const handleSubmit = () => {
    if (!currentText && props.handleRemoveText) {
      props.handleRemoveText();
    } else if (currentText !== props.textContent && props.onSave) {
      props.onSave(currentText);
    }
  };

  const handleBlur = () => {
    // Wait 1 frame for document.activeElement to capture the currently focused element.
    window.requestAnimationFrame(() => {
      const activeElement = document.activeElement;
      if (container.current && !container.current.contains(activeElement)) {
        setFocus(false);
        handleSubmit();
      }
    });
  };

  const handleClick = () => {
    if (props.editable) {
      setFocus(true);
    }
  };

  const handleChange = (value: string) => {
    setCurrentText(value);
  };

  // Keydown handler for when a user sets keyboard focus onto the input field,
  // then clicking either RETURN or SPACE should set focus into the input
  // without adding the additional new line/space.
  const handleKeyDown = (event: KeyboardEvent) => {
    if (
      event.keyCode === KeyCode.KEY_RETURN ||
      event.keyCode === KeyCode.KEY_SPACE
    ) {
      event.preventDefault();
      handleClick();
    }
  };

  // If this component mounted with no textContent, we know that it is
  // a DraftTextItem. We will want to scroll ourselves into view.
  const locationSectionId = props.location.query.sectionId;
  React.useEffect(() => {
    if (!props.textContent || props.sectionId === locationSectionId) {
      const node = findDOMNode(input.current);
      if (node) {
        scrollIntoView(node, {
          duration: 0,
          block: "start",
        });
      }
    }
  }, [props.textContent, locationSectionId, props.sectionId]);

  React.useEffect(() => {
    if (props.useDragHandle) {
      if (currentText.length && !isDragHandleShowing) {
        setShowDragHandle(true);
      }
      if (!currentText.length && isDragHandleShowing) {
        setShowDragHandle(false);
      }
    }
  }, [currentText, isDragHandleShowing, props.useDragHandle]);

  return (
    <div
      ref={container}
      className={classnames(style.container, props.className, {
        [style.containerInnerFocused]: isFocused,
        [style.noPointer]: props.disableActions,
        [style.forceShowBackground]: props.forceShowBackground,
      })}
    >
      <div className={style.maxWidth}>
        {isDragHandleShowing && (
          <DraggableGripIcon className={style.gripIcon} />
        )}
        {props.inputShare && (
          <CopyLinkButton
            className={style.shareLink}
            icon="link"
            inputShare={props.inputShare}
            noText
            tint={false}
            tooltip
          />
        )}
        <InputRich
          ref={input}
          tabIndex="0"
          value={currentText}
          type="textarea"
          users={empty.array}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onSubmit={handleSubmit}
          placeholder="Add some text (Markdown supported)"
          wrapperClass={style.inputWrapper}
          className={classnames(
            {
              [style.hidden]: !currentText.length ? false : !isFocused,
              [style.editable]: props.editable,
              [style.focused]: isFocused,
              [style.large]: props.large,
              [style.showDragHandle]: isDragHandleShowing,
            },
            style.input
          )}
          onChange={handleChange}
        />
        <div
          role="button"
          tabIndex="0"
          onClick={handleClick}
          onKeyDown={handleKeyDown}
          className={classnames(style.input, {
            [style.editable]: props.editable,
            [style.hidden]: isFocused ? true : !currentText.length,
            [style.showDragHandle]: isDragHandleShowing,
            [style.large]: props.large,
          })}
        >
          <Markdown text={currentText} className={style.textMarkdown} />
        </div>
      </div>
    </div>
  );
}

export default withRouter<OwnProps>(Text);

// $FlowFixMeNowPlease react-router upgrade
export const SortableCollectionText = withRouter<Props>(SortableElement(Text));
