// @flow
import { findIndex } from "lodash";
import { static as Immutable } from "seamless-immutable";
import type { Action, Toast } from "core/types";

type State = Toast[];

const DEFAULT = Immutable.from([]);

export default function toastsReducer(
  state: State = DEFAULT,
  action: Action
): State {
  switch (action.type) {
    case "core/TOAST_SHOWN":
    case "core/TOAST_UPDATED": {
      const id = action.payload.id;
      const toastIndex = findIndex(state, { id });

      if (toastIndex !== -1) {
        const existing = state[toastIndex];
        const newState = Immutable.asMutable(state);
        newState[toastIndex] = { ...existing, ...action.payload };
        return Immutable(newState);
      }
      return Immutable.from([action.payload]).concat(state);
    }
    case "core/TOAST_DISMISSED": {
      const id = action.payload;
      return state.filter((alert) => alert.id !== id);
    }
    default:
      return state;
  }
}
