// @flow
import classnames from "classnames";
import * as React from "react";
import { V3Link as Link } from "core/lib/router";
import Heading from "../Heading";
import Icon from "../Icon";
import style from "./style.scss";

type Props = {
  className?: string,
  to?: string,
  children?: React.Node,
  dynamicPadding?: boolean,
};

export default function SidebarHeading({
  className = "",
  to,
  children,
  dynamicPadding,
  ...rest
}: Props) {
  return (
    <Heading
      level="4"
      size="xs"
      {...rest}
      className={classnames(style.sidebarHeading, className, {
        [style.dynamicPadding]: dynamicPadding,
      })}
    >
      {to ? (
        <Link to={to} className={style.linked}>
          {children}
          <Icon type="go-to" />
        </Link>
      ) : (
        <span className={style.plain}>{children}</span>
      )}
    </Heading>
  );
}
