// @flow
import { connect } from "react-redux";
import { removeProjectMember } from "core/actions/projectMemberships";
import { DeleteProjectMembershipRequest } from "core/requests/projectMemberships";
import type { State, Dispatch } from "core/types";
import type { OwnProps, StateProps, DispatchProps, Props } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  return {
    isDeleteMembershipRequestLoading:
      DeleteProjectMembershipRequest.isLoadingStrict(state, {
        projectId: props.project.id,
        userId: props.user.id,
      }),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    removeFromProject: () => {
      return dispatch(removeProjectMember(props.project.id, props.user.id));
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
