// @flow
import queryString from "query-string";
import apiRequest from "abstract-di/api";
import defineRequest from "core/requests";
import type {
  OrganizationRole,
  ProjectMembershipRole,
  SubscriptionRole,
} from "core/types";

type BaseInvitationParams = {
  emails?: string[],
  organizationId: string,
  role: OrganizationRole,
  subscriptionRole: SubscriptionRole,
};

export type OrganizationInvitationParams = {
  ...BaseInvitationParams,
  type?: string,
};

export type ProjectInvitationParams = {
  ...BaseInvitationParams,
  projectId: string,
  projectRole: ProjectMembershipRole,
  type?: string,
};

export type TeamInvitationParams = {
  ...BaseInvitationParams,
  teamId: string,
  type?: string,
};

export const CreateOrganizationInvitationsRequest = defineRequest<
  OrganizationInvitationParams,
  OrganizationInvitationParams,
>({
  id: (params) => {
    return `post:invitations?${queryString.stringify(params)}`;
  },
  perform: (params) => {
    return apiRequest("post", "invitations", params, 4);
  },
  onError: (error) => {
    throw error;
  },
});

export const CreateProjectInvitationsRequest = defineRequest<
  ProjectInvitationParams,
  ProjectInvitationParams,
>({
  id: (params) => {
    return `post:invitations?${queryString.stringify(params)}`;
  },
  perform: (params) => {
    return apiRequest("post", "invitations", params, 4);
  },
  onError: (error) => {
    throw error;
  },
});

export const CreateTeamInvitationsRequest = defineRequest<
  TeamInvitationParams,
  TeamInvitationParams,
>({
  id: (params) => {
    return `post:invitations?${queryString.stringify(params)}`;
  },
  perform: (params) => {
    return apiRequest("post", "invitations", params, 4);
  },
  onError: (error) => {
    throw error;
  },
});
