// @flow
import { connect } from "react-redux";
import { loadSession } from "../actions/session";
import OnboardingShell from "../components/OnboardingShell";
import { isLoggedIn } from "../selectors/session";

const actions = { loadSession };

function mapStateToProps(state, props) {
  return {
    isLoggedIn: isLoggedIn(state),
  };
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default connect(mapStateToProps, actions)(OnboardingShell);
