// @flow
import idx from "idx";
import { get } from "lodash";
import * as React from "react";
import ChangeStatusIcon from "core/components/ChangeStatusIcon";
import Icon from "core/components/Icon";
import { layerDataIconType } from "core/components/LayerIcon";
import TreeView from "core/components/TreeView";
import { Abstract } from "core/lib/abstract";
import { isForeignSymbol } from "core/lib/layer";
import mapLayerChildren from "core/lib/mapLayerChildren";
import * as LayerData from "core/models/layerData";
import type { LayerData as TLayerData, LayerChangeset } from "core/types";
import style from "./style.scss";

function shouldPeek(
  layerData: TLayerData,
  selectedLayer: ?TLayerData,
  depth: number
): boolean {
  const indexInPath = (depth - 1).toString();
  const layerAtDepth: ?TLayerData = get(selectedLayer, ["_path", indexInPath]);

  // Do not peek unknown layer
  if (!layerAtDepth) {
    return false;
  }

  // Do not peek selected layer
  if (selectedLayer === layerData) {
    return false;
  }

  return (
    layerAtDepth.id === layerData.id &&
    layerAtDepth.parentId === layerData.parentId
  );
}

type Props = {
  layerData: TLayerData,
  layers: { [layerId: string]: TLayerData },
  layerChangesets?: { [layerKey: string]: LayerChangeset },
  params: Abstract.LayerVersionDescriptor,
  targetedLayer?: ?TLayerData,
  selectedLayer?: ?TLayerData,
  rootLayer?: ?TLayerData,
  isFocused: boolean,
  isPublicShare: ?boolean,
  depth: number,
  disclosure?: boolean,
  selectedClassName?: string,
  disclosureClassName?: string,
  onTargetLayer?: (layer: ?TLayerData) => void,
  onSelectLayer: (layerKey: ?string) => void,
  showAssets: ?boolean,
  canUseSymbolSourceDisplayName: boolean,
};

class LayerTree extends React.PureComponent<Props> {
  static defaultProps = {
    layers: {},
    isFocused: false,
    depth: 1,
  };

  get isRootLayer(): boolean {
    if (!this.props.rootLayer) {
      return true;
    } // Allow first layerData to fallback to root

    return this.props.rootLayer
      ? this.props.layerData === this.props.rootLayer
      : false;
  }

  render() {
    const {
      disclosure,
      layerData,
      params,
      isPublicShare,
      canUseSymbolSourceDisplayName,
      ...childProps
    } = this.props;
    const layerKey = LayerData.key(this.props.layerData);
    const layerChangeset = this.props.layerChangesets
      ? this.props.layerChangesets[layerKey]
      : undefined;

    const hasExports =
      this.props.showAssets &&
      this.props.layerData.properties.assets &&
      this.props.layerData.properties.assets.length > 0;

    const symbolMasterDescriptor =
      layerData.symbolMasterDescriptor ||
      idx(layerData, (_) => _._root.symbolMasterDescriptor);

    const missingForeignAssets =
      this.props.isPublicShare && symbolMasterDescriptor
        ? isForeignSymbol(params, symbolMasterDescriptor)
        : false;

    const children = mapLayerChildren(
      this.props.layers,
      this.props.layerData,
      (childLayerData) => {
        return (
          <LayerTree
            {...childProps}
            params={params}
            isPublicShare={isPublicShare}
            key={LayerData.key(childLayerData)}
            layerData={childLayerData}
            rootLayer={this.props.rootLayer || this.props.layerData}
            depth={this.props.depth + 1}
            canUseSymbolSourceDisplayName={
              this.props.canUseSymbolSourceDisplayName
            }
          />
        );
      }
    );

    return (
      <TreeView
        selectedClassName={this.props.selectedClassName}
        disclosureClassName={this.props.disclosureClassName}
        icon={layerDataIconType(this.props.layerData)}
        label={this.props.layerData.properties.name}
        before={
          this.props.layerData.properties.underClippingMask
            ? (light) => <Icon type="masked" light={light} />
            : undefined
        }
        after={(light) => (
          <span className={style.iconTransitionGroup}>
            {this.props.layerData.properties.link && (
              <Icon type="prototype-property" light={light} />
            )}
            {!this.props.layerData.properties.isVisible && (
              <Icon type="sketch-nonvisible" light={light} />
            )}
            {this.props.layerData.properties.isLocked &&
              this.props.layerData.properties.isVisible && (
                // Sketch only diplays the locked indicator if the layer is visible
                <Icon type="lock-locked" light={light} />
              )}
            {hasExports && !missingForeignAssets && (
              <Icon type="scissors-small" light={light} />
            )}
            {layerChangeset &&
              (!this.isRootLayer ||
                (this.isRootLayer && !layerChangeset.indirect)) && ( // Ignore indirect changes on root layer
                <ChangeStatusIcon
                  key="status"
                  className={style.statusIcon}
                  change={layerChangeset.status}
                  light={light}
                  title={`This ${LayerData.typeLabel(
                    this.props.layerData.type,
                    canUseSymbolSourceDisplayName
                  ).toLowerCase()} was ${layerChangeset.status}`}
                  tooltip={{ placement: "bottom" }}
                />
              )}
          </span>
        )}
        peek={shouldPeek(
          this.props.layerData,
          this.props.selectedLayer,
          this.props.depth
        )}
        defaultCollapsed={!this.isRootLayer}
        targeted={this.props.layerData === this.props.targetedLayer}
        selected={this.props.layerData === this.props.selectedLayer}
        isFocused={this.props.isFocused}
        disclosure={this.props.disclosure}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onClick={this.handleOnClick}
        hasChildren={children.length > 0}
      >
        {children}
      </TreeView>
    );
  }

  handleMouseEnter = () => {
    if (this.props.onTargetLayer) {
      this.props.onTargetLayer(this.props.layerData);
    }
  };

  handleMouseLeave = () => {
    if (this.props.onTargetLayer) {
      this.props.onTargetLayer(null);
    }
  };

  handleOnClick = () => {
    this.props.onSelectLayer(LayerData.key(this.props.layerData));
  };
}

export default LayerTree;
