// @flow
import classnames from "classnames";
import * as React from "react";
import style from "./style.scss";

type Props = {
  className?: string,
  title?: string,
  count?: number,
};

export default function NotificationBadge({
  count,
  className,
  title,
  ...rest
}: Props) {
  if (!count) {
    return null;
  }

  return (
    <div
      className={classnames(style.badge, className)}
      {...rest}
      title={title || count}
    >
      {count}
    </div>
  );
}
