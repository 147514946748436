// @flow
import * as React from "react";
import Button from "core/components/Button";
import Flex from "core/components/Flex";
import FormSection from "core/components/FormSection";
import Icon from "core/components/Icon";
import SettingsForm from "core/components/SettingsForm";
import SettingsItem from "core/components/SettingsItem";
import { replace } from "core/lib/location";
import DangerButton from "web/components/DangerButton";
import { connector, type Props } from "web/containers/WebhookOverview";
import { integrationSettings } from "web/routeHelpers";
import style from "./style.scss";

class WebhookDetails extends React.Component<Props> {
  onDeleteWebhook = () => {
    const { deleteWebhook, organizationId, webhookId } = this.props;
    deleteWebhook({ organizationId, webhookId }).then((response) => {
      if (response) {
        replace({
          pathname: integrationSettings("edit", organizationId, ""),
          state: { webhookDeletionSuccess: true },
        });
      }
    });
  };

  onTestWebhook = () => {
    const { organizationId, testWebhook, webhookId } = this.props;
    testWebhook({ organizationId, webhookId });
  };

  render() {
    const { deleteWebhookLoading, testWebhookLoading, webhook } = this.props;

    return (
      <SettingsForm className={style.settingsForm}>
        <FormSection>
          <SettingsItem label="Payload URL">
            <span className={style.payload}>{webhook.url}</span>
          </SettingsItem>
          <SettingsItem label="Active?">
            <Flex grow={false} align="center">
              {(webhook.active === undefined || webhook.active) && (
                <React.Fragment>
                  <Icon type="checkmark" allgood className={style.check} />
                  <span className={`${style.status} ${style.active}`}>
                    ACTIVE
                  </span>
                </React.Fragment>
              )}
              {webhook.active !== undefined && !webhook.active && (
                <span className={`${style.status} ${style.inactive}`}>
                  NOT ACTIVE
                </span>
              )}
            </Flex>
          </SettingsItem>
          <SettingsItem label="Send test event">
            <Button
              disabled={testWebhookLoading}
              onClick={this.onTestWebhook}
              primary
            >
              SEND TEST EVENT
            </Button>
          </SettingsItem>
          <SettingsItem label="Remove webhook">
            <span>
              <DangerButton
                title="Delete this webhook"
                dialogButtonLabel="REMOVE WEBHOOK"
                dialogMessage="Are you sure you want to remove this webhook?"
                error={false}
                loading={deleteWebhookLoading}
                onSubmit={this.onDeleteWebhook}
              >
                REMOVE WEBHOOK
              </DangerButton>
            </span>
          </SettingsItem>
        </FormSection>
      </SettingsForm>
    );
  }
}

export default connector(WebhookDetails);
