// @flow
import { connect } from "react-redux";
import { getProject } from "core/selectors/projects";
import { getUser } from "core/selectors/users";
import type { State } from "core/types";
import type { OwnProps, StateProps } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { collection } = props;
  const { projectId, userId } = collection;

  return {
    project: getProject(state, { projectId }),
    collectionAuthor: getUser(state, { userId }),
  };
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default connect(mapStateToProps);
