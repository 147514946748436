// @flow
import createCachedSelector from "@elasticprojects/re-reselect";
import { Abstract } from "core/lib/abstract";
import * as Layer from "core/models/layer";
import * as Request from "core/models/request";
import { getTargetLayerRequestId } from "core/requests/prototypes";
import { getBranchHead } from "core/selectors/branches";
import {
  getLayerForCollectionLayer,
  getCollectionLayer,
} from "core/selectors/collections";
import { getFeatureEnabled } from "core/selectors/features";
import {
  getLayerDataset,
  getLayersOrdered,
  getLayers,
} from "core/selectors/layerDatasets";
import { getLayer } from "core/selectors/layers";
import { getOrganizationForProject } from "core/selectors/organizations";
import { getRequest } from "core/selectors/requests";
import type {
  State,
  LayerDataset,
  LayerData,
  LayerContext,
  Layer as TLayer,
  LayerLink,
  BranchCollectionDescriptor,
  TargetLinkOptions,
} from "core/types";

const cacheByUniqueLayerId = (state, params) => Layer.uniqueId(params);

export function getShowPrototypes(state: State, projectId: string): boolean {
  const organization = getOrganizationForProject(state, {
    projectId,
  });
  if (!organization) {
    return false;
  }

  const showPrototypesFeatureEnabled = getFeatureEnabled(state, {
    organizationId: organization.id,
    feature: "prototypes",
  });

  return showPrototypesFeatureEnabled;
}

export function getCurrentLayer(
  state: State,
  descriptor: Abstract.LayerVersionDescriptor | BranchCollectionDescriptor,
  link: LayerLink,
  options: TargetLinkOptions
) {
  if (descriptor.collectionId && options.collectionLayerId) {
    return getLayerForCollectionLayer(state, {
      collectionLayerId: options.collectionLayerId,
    });
  }

  if (descriptor.layerId) {
    return getLayer(state, descriptor);
  }
}

export function getTargetSha(
  state: State,
  descriptor: Abstract.LayerVersionDescriptor | BranchCollectionDescriptor,
  link: LayerLink,
  options: TargetLinkOptions
): ?string {
  if (options.sha) {
    return options.sha;
  }

  const head = getBranchHead(state, descriptor);
  if (!head) {
    return null;
  }

  // if this is a collection layer, our target sha is the branch head if collectionLayer.useLatestCommit
  // is true, otherwise it is the layer's lastChangedAtSha
  if (descriptor.collectionId) {
    const collectionLayer =
      options.collectionLayerId &&
      getCollectionLayer(state, {
        collectionLayerId: options.collectionLayerId,
      });
    if (!collectionLayer) {
      return null;
    }

    if (collectionLayer.useLatestCommit) {
      return head;
    }

    const layer = getLayerForCollectionLayer(state, {
      collectionLayerId: collectionLayer.id,
    });

    return layer ? layer.lastChangedAtSha : null;
  }

  // determine if we have a target and if we're at latest
  const target = link.target || undefined;

  const layer =
    descriptor.layerId && getLayer(state, { ...descriptor, sha: head });
  if (!layer) {
    return null;
  }

  const latest =
    descriptor.sha &&
    (descriptor.sha === layer.lastChangedAtSha || descriptor.sha === "latest");

  // if latest return the target layer's lastChangedAtSha, otherwise return
  // descriptor.sha
  if (latest && target) {
    const targetLayerDescriptor = { ...target, sha: head };
    const targetLayer = getLayer(state, targetLayerDescriptor);
    if (!targetLayer) {
      return null;
    }

    return targetLayer.lastChangedAtSha;
  }

  return descriptor.sha || null;
}

export function getTargetLayer(
  state: State,
  descriptor: Abstract.LayerVersionDescriptor | BranchCollectionDescriptor,
  link: LayerLink,
  options: TargetLinkOptions
): ?TLayer {
  const target = link.target || undefined;
  if (target) {
    const targetSha = getTargetSha(state, descriptor, link, options);
    if (targetSha) {
      return getLayer(state, { ...target, sha: targetSha });
    }
  }
}

export function getTargetLayerLoading(
  state: State,
  descriptor: Abstract.LayerVersionDescriptor | BranchCollectionDescriptor,
  link: LayerLink,
  options: TargetLinkOptions
): boolean {
  if (!link.target) {
    return false;
  }
  const request = getRequest(
    state,
    getTargetLayerRequestId(descriptor, link, options)
  );
  return request && Request.isFirstLoading(request);
}

export function getTargetLayerMissing(
  state: State,
  descriptor: Abstract.LayerVersionDescriptor | BranchCollectionDescriptor,
  link: LayerLink,
  options: TargetLinkOptions
) {
  if (!link.target) {
    return false;
  }
  const request = getRequest(
    state,
    getTargetLayerRequestId(descriptor, link, options)
  );
  return request && Request.hasError(request);
}

export function getTargetLayerData(
  state: State,
  descriptor: Abstract.LayerVersionDescriptor | BranchCollectionDescriptor,
  link: LayerLink,
  options: TargetLinkOptions
): ?LayerDataset {
  const target = link.target || undefined;
  if (target) {
    const targetSha = getTargetSha(state, descriptor, link, options);
    if (targetSha) {
      return getLayerDataset(state, { ...target, sha: targetSha });
    }
  }
}

export const getPrototypeData: (
  state: State,
  params: Abstract.LayerVersionDescriptor
) => LayerLink[] = createCachedSelector<
  State,
  Abstract.LayerVersionDescriptor,
  Array<[LayerData, LayerContext]>,
  LayerLink[],
>(getLayersOrdered, (layersOrdered) => {
  const linkData: LayerLink[] = [];
  for (let [layerData] of layersOrdered) {
    if (layerData.properties.link) {
      linkData.push(layerData.properties.link);
    }
  }
  return linkData;
})(cacheByUniqueLayerId);

export const hasHotspots: (
  state: State,
  params: Abstract.LayerVersionDescriptor
) => boolean = createCachedSelector<
  State,
  Abstract.LayerVersionDescriptor,
  LayerLink[],
  boolean,
>(getPrototypeData, (prototypeData) => {
  return prototypeData.length > 0;
})(cacheByUniqueLayerId);

export const getHotspotLayers: (
  state: State,
  params: Abstract.LayerVersionDescriptor
) => LayerData[] = createCachedSelector<
  State,
  Abstract.LayerVersionDescriptor,
  { [layerId: string]: LayerData },
  LayerData[],
>(getLayers, (layers) => {
  return Object.keys(layers)
    .map((layerKey) => {
      return layers[layerKey];
    })
    .filter((layerData) => !!layerData.properties.link);
})(cacheByUniqueLayerId);
