// @flow
import { schema } from "normalizr";
import * as Branch from "core/models/branch";
import * as File from "core/models/file";
import * as Layer from "core/models/layer";
import * as Library from "core/models/library";
import * as Membership from "core/models/membership";
import * as Page from "core/models/page";
import * as SharedItem from "core/models/sharedItem";
import * as TeamProjectMembership from "core/models/teamProjectMembership";
import type {
  Branch as TBranch,
  BranchReview as TBranchReview,
  File as TFile,
  Library as TLibrary,
  Page as TPage,
  Layer as TLayer,
  SharedItem as TSharedItem,
  OrganizationMetrics as TOrganizationMetrics,
  UserMetrics as TUserMetrics,
  TeamProjectMembership as TTeamProjectMembership,
} from "core/types";

export const branch = new schema.Entity(
  "branches",
  {},
  {
    idAttribute: (branch: TBranch) => Branch.uniqueId(branch),
  }
);
export const localBranch = new schema.Entity(
  "localBranches",
  {},
  {
    idAttribute: (branch: TBranch) => Branch.uniqueId(branch),
  }
);
export const feature = new schema.Entity(
  "features",
  {},
  {
    idAttribute: "organizationId",
  }
);
export const branchRestriction = new schema.Entity("branchRestrictions");
export const branchStatus = new schema.Entity("branchStatus");

export const asset = new schema.Entity("assets");
export const changeset = new schema.Entity("changesets");
export const section = new schema.Entity("sections");
export const organization = new schema.Entity("organizations");
export const organizationSettings = new schema.Entity(
  "organizationSettings",
  { organization: organization },
  { idAttribute: "organizationId" }
);
export const user = new schema.Entity("users");
export const collectionLayer = new schema.Entity("collectionLayers");
export const collection = new schema.Entity("collections", {
  layers: [collectionLayer],
  user: user,
});
export const comment = new schema.Entity("comments", { user });
export const project = new schema.Entity("projects", {
  createdByUser: user,
});
export const notification = new schema.Entity("notifications");

export const policy = new schema.Entity(
  "policies",
  {},
  { idAttribute: (entity, entities, id) => id }
);

export const policies = new schema.Values(policy);

export const commit = new schema.Entity(
  "commits",
  {},
  {
    idAttribute: "sha",
  }
);

export const file = new schema.Entity(
  "files",
  {},
  {
    idAttribute: (file: TFile) => File.uniqueId(file),
  }
);

export const library = new schema.Entity(
  "libraries",
  {},
  {
    idAttribute: (library: TLibrary) => Library.uniqueId(library),
  }
);

export const page = new schema.Entity(
  "pages",
  {},
  {
    idAttribute: (page: TPage) => Page.uniqueId(page),
  }
);

export const layer = new schema.Entity(
  "layers",
  {},
  {
    idAttribute: (layer: TLayer) => Layer.uniqueId(layer),
  }
);

export const sharedItem = new schema.Entity(
  "sharedData",
  {},
  {
    idAttribute: (item: TSharedItem) => SharedItem.uniqueId(item),
  }
);

export const reaction = new schema.Entity(
  "reactions",
  {},
  {
    idAttribute: "commentId",
    processStrategy: (reaction) => [reaction],
    mergeStrategy: (reactionList, reaction) => reactionList.concat(reaction),
  }
);

export const membershipSchema = new schema.Entity(
  "memberships",
  {
    user,
  },
  {
    idAttribute: (membership) =>
      Membership.uniqueId({
        organizationId: membership.organizationId,
        userId: membership.userId,
      }),
  }
);
export const membershipsSchema = new schema.Array(membershipSchema);
export const updateMembershipSchema = new schema.Entity(
  "memberships",
  {
    user,
    organization,
  },
  {
    idAttribute: (membership) =>
      Membership.uniqueId({
        organizationId: membership.organizationId,
        userId: membership.userId,
      }),
  }
);

export const organizationJoinedResponseSchema = new schema.Object({
  user,
  organization,
});

export const webhookSchema = new schema.Entity("webhooks", { user });

export const webhookListSchema = new schema.Array(webhookSchema);

export const webhookDeliverySchema = new schema.Entity("webhookDeliveries");

export const webhookDeliveryListSchema = new schema.Array(
  webhookDeliverySchema
);

export const webhookEventSchema = new schema.Entity("webhookEvents");

export const webhookEventListSchema = new schema.Array(webhookEventSchema);

export const branchReview = new schema.Entity(
  "branchReviews",
  {},
  {
    idAttribute: (branchReview: TBranchReview) => Branch.uniqueId(branchReview),
  }
);
export const reviewRequest = new schema.Entity("reviewRequests");

export const team = new schema.Entity("teams");

export const teamMembership = new schema.Entity("teamMemberships");

export const teamProjectMembership = new schema.Entity(
  "teamProjectMemberships",
  {},
  {
    idAttribute: (teamProjectMembership: TTeamProjectMembership) =>
      TeamProjectMembership.uniqueId(teamProjectMembership),
  }
);

export const orgReporting = new schema.Entity(
  "organizationMetrics",
  {},
  {
    idAttribute: (metrics: TOrganizationMetrics) => metrics.meta.organizationId,
  }
);

export const userReporting = new schema.Entity(
  "userMetrics",
  {},
  {
    idAttribute: (metrics: TUserMetrics) => metrics.meta.organizationId,
  }
);

export const emoji = new schema.Entity("emojis");

export const activity = new schema.Entity("activities");
