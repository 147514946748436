// @flow
import { connect } from "react-redux";
import { trackEvent } from "core/actions/analytics";
import { showToast } from "core/actions/toasts";
import {
  CreateOrganizationInvitationsRequest,
  CreateProjectInvitationsRequest,
  CreateTeamInvitationsRequest,
} from "core/requests/invitations";
import { SubscriptionFetchRequest } from "core/requests/seats";
import { seatCapEnabled } from "core/selectors/features";
import { getOrganizationPolicy } from "core/selectors/policies";
import type { State, Dispatch } from "core/types";
import type { OwnProps, StateProps, DispatchProps, Props } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const policy = getOrganizationPolicy(state, {
    organizationId: props.organization.id,
  });

  return {
    canAddContributors: policy.addContributor,
    seatCapEnabled: seatCapEnabled(state, props.organization.id),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const organizationId = props.organization.id;

  return {
    createOrganizationInvites: (params) => {
      const { type, ...remainingParams } = params;

      return dispatch(
        CreateOrganizationInvitationsRequest.perform({
          params: remainingParams,
          onSuccess: (response) => {
            const invitationIds = response.reduce((result, invitation) => {
              return result.concat(invitation.id);
            }, []);

            dispatch(
              trackEvent(
                type === "link"
                  ? "ORGANIZATION_INVITE_LINK_CREATED"
                  : "ORGANIZATION_INVITE_SENT",
                {
                  ...remainingParams,
                  invitationIds,
                }
              )
            );
          },
        })
      );
    },
    createProjectInvites: (params) => {
      const { type, ...remainingParams } = params;

      return dispatch(
        CreateProjectInvitationsRequest.perform({
          params: remainingParams,
          onSuccess: (response) => {
            const invitationIds = response.reduce((result, invitation) => {
              return result.concat(invitation.id);
            }, []);

            dispatch(
              trackEvent(
                type === "link"
                  ? "PROJECT_INVITE_LINK_CREATED"
                  : "PROJECT_INVITE_SENT",
                {
                  ...remainingParams,
                  invitationIds,
                }
              )
            );
          },
        })
      );
    },
    createTeamInvites: (params) => {
      const { type, ...remainingParams } = params;

      return dispatch(
        CreateTeamInvitationsRequest.perform({
          params: remainingParams,
          onSuccess: (response) => {
            const invitationIds = response.reduce((result, invitation) => {
              return result.concat(invitation.id);
            }, []);

            dispatch(
              trackEvent(
                type === "link"
                  ? "TEAM_INVITE_LINK_CREATED"
                  : "TEAM_INVITE_SENT",
                {
                  ...remainingParams,
                  invitationIds,
                }
              )
            );
          },
        })
      );
    },
    onMount: () => {
      dispatch(
        SubscriptionFetchRequest.perform({
          params: { organizationId },
        })
      );
    },
    onSuccess: (alert) => {
      if (alert) {
        dispatch(showToast({ text: alert }));
      }
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
