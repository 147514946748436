// @flow
import classnames from "classnames";
import { memoize } from "lodash";
import React from "react";
import { Waypoint } from "react-waypoint";
import ActivityList from "core/components/ActivityList";
import Breakpoint from "core/components/Breakpoint";
import CommentCreateForm from "core/components/CommentCreateForm";
import DeferredRender from "core/components/DeferredRender";
import NoComments from "core/components/Empty/NoComments";
import Offline from "core/components/Empty/Offline";
import Heading from "core/components/Heading";
import Loaded from "core/components/Loaded";
import ActivitiesLoader from "core/containers/ActivitiesLoader";
import type { Project, Branch, User } from "core/types";
import style from "./style.scss";

type Props = {
  project: Project,
  branch: Branch,
  users: User[],
};

const getActivityContext = memoize(
  (props: Props) => ({
    projectId: props.project.id,
    branchId: props.branch.id,
  }),
  (props: Props) => props.project.id + props.branch.id
);

export default function BranchActivitiesList(props: Props) {
  const { project, branch } = props;
  const activtityContext = getActivityContext(props);

  return (
    <ActivitiesLoader
      organizationId={project.organizationId}
      projectId={project.id}
      branchId={branch.id}
      sha={branch.head}
    >
      {({ activityIds, isLoading, isLoadingMore, onLoadMore, isOffline }) => {
        const hasActivities = activityIds.length > 0;
        if (!hasActivities && isOffline) {
          return (
            <Offline description="Connect to the internet to load collections and activities." />
          );
        }

        return (
          <Loaded loading={isLoading} title="Loading activity…" flex>
            <Heading level="3" size="l" className={style.activityTitle}>
              Activity
            </Heading>
            <div className={style.formWrap}>
              <CommentCreateForm
                flat
                defaultFocus
                showErrors
                className={classnames(style.form, {
                  [style.disabled]: isOffline,
                })}
                projectId={project.id}
                branchId={branch.id}
                renderActionsInsideTextarea
              />
            </div>

            {hasActivities ? (
              <React.Fragment>
                <Breakpoint at={600}>
                  {({ above }) => (
                    <DeferredRender>
                      {() => (
                        <ActivityList
                          className={style.list}
                          narrow={!above}
                          activityIds={activityIds}
                          projectId={project.id}
                          branchId={branch.id}
                          activityContext={activtityContext}
                        >
                          <Waypoint onEnter={onLoadMore} />
                        </ActivityList>
                      )}
                    </DeferredRender>
                  )}
                </Breakpoint>

                {isLoadingMore && (
                  <div className={style.spinner}>
                    <Loaded
                      loading
                      title={
                        isOffline
                          ? "You are offline. Connect to the internet to load more activities for this branch"
                          : undefined
                      }
                    />
                  </div>
                )}
              </React.Fragment>
            ) : (
              <NoComments />
            )}
          </Loaded>
        );
      }}
    </ActivitiesLoader>
  );
}
