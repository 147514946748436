// @flow
import { normalize, schema } from "normalizr";
import { project, branch, layer, collection } from "core/schema";
import type { ShareLink } from "core/types";

export const ShareLinkSchema = new schema.Entity("shareLinks");
export const ShareLinksSchema = new schema.Array(ShareLinkSchema);
export const ShareLinksResponseSchema = new schema.Object({
  projects: [project],
  branches: [branch],
  layers: [layer],
  collections: [collection],
  shareLinks: [ShareLinkSchema],
});

export function normalizeShareLink(shareLink: ShareLink) {
  return normalize(shareLink, ShareLinkSchema);
}

export function normalizeShareLinks(shareLinks: ShareLink[]) {
  return normalize(shareLinks, ShareLinksSchema);
}

export function normalizeShareLinksResponse(response: *) {
  return normalize(response, ShareLinksResponseSchema);
}
