// @flow
import { uniq } from "lodash";
import { static as Immutable } from "seamless-immutable";
import type { Action } from "core/types";

export type EntityMappings = {
  [key: string]: { [key: string]: Array<string> },
};

type State = EntityMappings;
const DEFAULT_STATE = Immutable.from({});

export default function entityMappings(
  state: State = DEFAULT_STATE,
  action: Action
): State {
  switch (action.type) {
    case "core/ENTITY_MAPPING_LOADED": {
      const { type, entityId, ids } = action.payload;
      const existing = Immutable.getIn(state, [type, entityId]) || [];
      const newMapping = action.meta.replace ? ids : existing.concat(ids);
      return Immutable.setIn(state, [type, entityId], uniq(newMapping));
    }
    default:
      return state;
  }
}
