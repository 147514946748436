// @flow
import classnames from "classnames";
import pluralize from "pluralize";
import * as React from "react";
import ButtonController from "core/components/ButtonController";
import Button from "core/components/ButtonController/Button";
import ChangeStatusBadge from "core/components/ChangeStatusBadge";
import InputSearch from "core/components/InputSearch";
import { replace, addQuery, removeQuery } from "core/lib/location";
import * as Branch from "core/models/branch";
import type { Branch as TBranch, ChangeStatusCounts } from "core/types";
import style from "./style.scss";

type Props = {
  branch?: TBranch,
  disabled?: boolean,
  isCommit?: boolean,
  isChangedFile?: boolean,
  statusCounts?: ?ChangeStatusCounts,
  onClickFilter?: (filter?: string) => void,
  onFilter?: (event: SyntheticInputEvent<>) => void,
  query?: string,
  filter?: string,
  className?: string,
  placeholderText?: string,
};

const filters = ["added", "edited", "removed", "updated"];

export default function HeaderBottom({
  branch,
  isCommit,
  isChangedFile,
  statusCounts,
  disabled,
  filter,
  query,
  onFilter,
  onClickFilter,
  className,
  placeholderText,
}: Props) {
  const isMaster = branch && Branch.isMaster(branch);
  const isUnchanged = (branch && !Branch.hasChanges(branch)) || !isChangedFile;
  const showFilters = isCommit || (!isMaster && !isUnchanged && statusCounts);

  const onClickItem = (event, type) => {
    event.preventDefault();

    if (onClickFilter) {
      onClickFilter(type);
    } else if (type === "all") {
      replace(removeQuery("filter"));
    } else {
      replace(addQuery({ filter: type }));
    }
  };

  return (
    <div
      className={classnames(style.bottom, className, {
        [style.changed]: showFilters,
      })}
    >
      {showFilters && (
        <ButtonController>
          <Button
            disabled={disabled}
            active={!filter || filter === "all"}
            tooltip={isCommit ? "All changed items" : "All items"}
            onClick={(event) => onClickItem(event, "all")}
            qaSelector="filePreviewsFilterAllButton"
          >
            {isCommit ? "Changed" : "All"}
          </Button>
          {!isCommit && (
            <Button
              disabled={disabled}
              active={filter === "changed"}
              tooltip="Changed items"
              onClick={(event) => onClickItem(event, "changed")}
            >
              Changed
            </Button>
          )}
          {filters.map((type) => {
            const active = filter === type;
            const count = statusCounts ? statusCounts[type] : 0;
            return (
              <Button
                key={type}
                disabled={disabled || count === 0}
                active={active}
                primary={type === "edited"}
                danger={type === "removed"}
                allgood={type === "added"}
                tooltip={`${count} ${type} ${pluralize("item", count)}`}
                onClick={(event) => onClickItem(event, type)}
              >
                <ChangeStatusBadge
                  status={type}
                  active={active}
                  count={count}
                />
              </Button>
            );
          })}
        </ButtonController>
      )}
      {onFilter && (
        <InputSearch
          value={query || ""}
          onChange={onFilter}
          placeholder={placeholderText || "Filter items…"}
          wrapperClass={style.searchInput}
        />
      )}
    </div>
  );
}
