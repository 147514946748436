// @flow
import { connect } from "react-redux";
import { LatestReleaseFetchRequest } from "core/requests/releases";
import type { State, Dispatch } from "core/types";
import type { Props, OwnProps, DispatchProps } from ".";

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    fetchLatestRelease: (onSuccess, onError) =>
      dispatch(
        LatestReleaseFetchRequest.perform({
          params: {},
          onSuccess,
          onError,
        })
      ),
  };
}

export default connect<Props, OwnProps, _, DispatchProps, State, Dispatch>(
  undefined,
  mapDispatchToProps
);
