// @flow
import { connect } from "react-redux";
import { isOnline } from "abstract-di/selectors";
import { updateProject } from "core/actions/projects";
import { getProjectPolicy } from "core/selectors/policies";
import type { State, Dispatch } from "core/types";
import type { Props, OwnProps, StateProps, DispatchProps } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { project } = props;
  const policy = getProjectPolicy(state, { projectId: project.id });

  return {
    canEdit: policy.update,
    isOnline: isOnline(state),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { project } = props;
  return {
    updateAbout: (about: string) => {
      return dispatch(updateProject({ ...project, name: project.name, about }));
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
