// @flow
import classnames from "classnames";
import idx from "idx";
import { get, find } from "lodash";
import * as React from "react";
import { ValidationError } from "core/api";
import ColorSelector from "core/components/ColorSelector";
import DialogForm, { TDialogForm } from "core/components/DialogForm";
import FormNotice from "core/components/FormNotice";
import Icon from "core/components/Icon";
import { SubmittableInput } from "core/components/Input/withSubmittable";
import InputCheckbox from "core/components/InputCheckbox";
import SelectSection from "core/components/SelectSection";
import { helpProjectsUrl } from "core/lib/urls";
import type {
  Section,
  Organization as TOrganization,
  Project,
} from "core/types";
import Validations from "core/validations";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  isOpen: boolean,
  onClose: () => void,
  action: "create" | "edit",
  projectId?: string,
  organizationId?: string,
  title?: string,
  primaryButton?: string,
|};

export type StateProps = {|
  project: ?Project,
  organizationId?: string,
  organization: ?TOrganization,
  sections: Section[],
  hasError: boolean,
  sectionsLoading: boolean,
  isOffline: boolean,
  isUsernameOrganization?: boolean,
  canUseSections: boolean,
  canCreateSections: boolean,
  canCreatePrivateProject: boolean,
|};

export type CreateSectionParams = {
  ...$Shape<Project>,
  sectionName: string,
  organizationId: string,
  sectionId: ?string,
  onErrorCallback: (error: Error) => void,
};

export type DispatchProps = {|
  onLoad: () => void,
  onUnmount: (project: ?Project) => void,
  onSubmit: (params: CreateSectionParams) => Promise<void>,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type State = {
  name?: string,
  color?: string,
  visibility?: "specific" | "organization",
  sectionId: ?string,
  sectionName: string,
  type?: "cloud",
  error?: string,
};

const calculateAllowPrivate = (props, newType) => {
  return props.canCreatePrivateProject;
};

class ProjectDialog extends React.Component<Props, State> {
  form: ?TDialogForm;

  state = {
    sectionId: this.props.project ? this.props.project.sectionId : null,
    sectionName: "",
    visibility: this.props.project
      ? this.props.project.visibility
      : "organization",
    type: undefined,
  };

  static defaultProps = {
    title: "Create a new project",
    primaryButton: "Create Project",
  };

  sectionChanged = (sectionId: ?string) => {
    this.setState({ sectionId });
  };

  inputChanged = (ev: SyntheticInputEvent<>) => {
    this.setState({ [ev.target.name]: ev.target.value });
    if (this.form) {
      this.form.checkValid();
    }
  };

  colorChanged = (color: string) => {
    this.setState({ color });
  };

  visibilityChanged = (ev: SyntheticInputEvent<>) => {
    const selected = ev.target.checked;
    this.setState({ visibility: selected ? "specific" : "organization" });
  };

  handleSubmit = () => {
    const { project = {}, sections, isOffline } = this.props;
    let { sectionId, sectionName } = this.state;

    if (isOffline) {
      sectionId = project && project.sectionId;
      sectionName = "";
    } else if (sectionId === "new") {
      const section = find(sections, { name: sectionName });

      if (section) {
        sectionId = section.id;
        sectionName = "";
      }
    }

    const onErrorCallback = (error: Error) => {
      let message = "Please contact support";

      if (error instanceof ValidationError) {
        message = idx(error, (_) => _.validationErrors.name[0]) || message;
      }

      this.setState({ error: message });
    };

    return this.props.onSubmit({
      ...project,
      ...this.state,
      sectionId,
      sectionName,
      onErrorCallback,
    });
  };

  getError(): string {
    return this.state.error || "";
  }

  renderOfflineErrorMessage() {
    return (
      <FormNotice
        className={style.errorNotification}
        icon="offline"
        heading="Looks like you’re offline"
        body="To continue editing projects, you will need to be online."
        alignContent
      />
    );
  }

  renderProjectErrorMessage() {
    return (
      <FormNotice
        isError
        icon="error"
        className={style.errorNotification}
        heading="Something went wrong"
        body={this.getError()}
        alignContent
      />
    );
  }

  componentWillUnmount() {
    this.props.onUnmount(this.props.project);
  }

  render() {
    const {
      project,
      sections,
      isOffline,
      hasError,
      isUsernameOrganization,
      canUseSections,
      canCreateSections,
      sectionsLoading,
      primaryButton,
      title,
    } = this.props;

    const { type, visibility } = this.state;
    const allowPrivate = calculateAllowPrivate(this.props, type);
    const privatePlan = type === "cloud" ? "Pro" : "Business";

    return (
      <DialogForm
        ref={(ref) => (this.form = ref)}
        title={title}
        primaryButton={primaryButton}
        onClose={this.props.onClose}
        onSubmit={this.handleSubmit}
        disabled={isOffline}
        isOpen={this.props.isOpen}
        contentClassName={style.form}
      >
        {() => (
          <React.Fragment>
            {isOffline
              ? this.renderOfflineErrorMessage()
              : hasError && this.renderProjectErrorMessage()}
            <SubmittableInput
              type="text"
              label="Name"
              name="name"
              placeholder=""
              qaSelector="projectNameInput"
              defaultValue={get(project, "name")}
              onSubmit={this.handleSubmit}
              onChange={this.inputChanged}
              minLength={Validations.minProjectNameLength}
              maxLength={Validations.maxProjectNameLength}
              required
              autoFocus
            />
            {(canCreateSections || (canUseSections && sections.length > 0)) && (
              <SelectSection
                label="Section"
                disabled={isOffline}
                value={this.state.sectionId || ""}
                nameValue={this.state.sectionName}
                onChange={this.sectionChanged}
                onInputChange={this.inputChanged}
                loading={sectionsLoading}
                sections={sections}
                project={project}
                canCreateSections={canCreateSections}
              />
            )}
            <ColorSelector
              label="Color"
              name="color"
              defaultValue={get(project, "color")}
              onChange={this.colorChanged}
              className={style.colorPicker}
            />
            {!isUsernameOrganization && (
              <React.Fragment>
                <InputCheckbox
                  type="checkbox"
                  value="private"
                  wrapperClass={style.privacyInput}
                  qaSelector="privateProjectCheckbox"
                  onChange={this.visibilityChanged}
                  checked={visibility === "specific"}
                  disabled={!allowPrivate}
                  label={
                    <span
                      className={classnames(style.privateLabel, {
                        [style.disabled]: !allowPrivate,
                      })}
                    >
                      <Icon type="lock-locked-alt" /> Make project private
                    </span>
                  }
                />
                <span className={style.note}>
                  {allowPrivate ? (
                    "Private projects will only be visible to people you invite."
                  ) : (
                    <span>
                      Private projects are only available on the {privatePlan}{" "}
                      plan or above. <a href={helpProjectsUrl()}>Learn more…</a>
                    </span>
                  )}
                </span>
              </React.Fragment>
            )}
            <span className={style.moreSettings}>
              <Icon type="info" className={style.infoIcon} />
              <span className={style.note}>
                Create the project to manage all available settings.
              </span>
            </span>
          </React.Fragment>
        )}
      </DialogForm>
    );
  }
}

export default connector(ProjectDialog);
