// @flow
import classnames from "classnames";
import { times } from "lodash";
import * as React from "react";
import Flex from "core/components/Flex";
import style from "./style.scss";

type Props = {|
  className?: string,
  currentStep: number,
  totalSteps: number,
  dottedLines?: number | number[],
|};

export default function StepProgressBar({
  className,
  currentStep,
  dottedLines = [],
  totalSteps,
}: Props) {
  const dottedIndexes = Array.isArray(dottedLines)
    ? dottedLines
    : [dottedLines];

  return (
    <Flex className={className} grow={false}>
      {times(totalSteps, (index) => {
        const stepClasses = classnames(style.step, {
          [style.currentStep]: index === currentStep - 1,
          [style.hasLineAfter]: index < totalSteps - 1,
          [style.dotted]: dottedIndexes.includes(index + 1),
        });

        return <div className={stepClasses} key={`step-${index + 1}`} />;
      })}
    </Flex>
  );
}
