// @flow
import * as Abstract from "abstract-sdk";
import * as Request from "core/models/request";
import { getRequest } from "core/selectors/requests";
import type { Action, ThunkAction } from "core/types";

function requestLoading(
  requestId: string,
  promise: Promise<mixed>,
  invalidateable: boolean
): Action {
  return {
    type: "core/REQUEST_LOADING",
    meta: { id: requestId, invalidateable },
    payload: promise,
  };
}

function requestSuccess(requestId: string, invalidateable: boolean): Action {
  return {
    type: "core/REQUEST_SUCCESS",
    meta: { id: requestId, invalidateable },
  };
}

function requestError(requestId: string, error: Error): ThunkAction {
  return (dispatch) => {
    dispatch({
      type: "core/REQUEST_ERROR",
      error: true,
      meta: { id: requestId },
      payload: error,
    });
    if (
      error instanceof Abstract.BaseError ||
      error instanceof Request.RequestError
    ) {
      return;
    }

    throw error;
  };
}

export function requestCleared(requestId: string): Action {
  return {
    type: "core/REQUEST_CLEARED",
    meta: { id: requestId },
  };
}

export function clearInvalidateableRequests(): Action {
  return { type: "core/INVALIDATE_REQUESTS" };
}

export type RequestOptions = {|
  force?: boolean,
  invalidateable?: boolean,
  // We use callbacks here for a few reasons.
  // 1. We don't really like relying on thunk action return values.
  // 2. Even if we used return values, error handling would be weird. We need
  // to execute the requestFunc inside of a try/catch so we can dispatch error
  // actions if it fails. We also want to swallow request RequestErrors so
  // that each individual user of this method doesn't have to handle those
  // errors.
  // 3. To be able to use requests and entities together well, the entities
  // that result from one of these calls needs to be loaded into the store
  // _before_ the request is marked as successful. If we used return values
  // over callbacks, then the request would be marked successful and
  // components that rely on the data resulting in the request would not have
  // that data even though the request is considered successful.
  // It's seems clunky in a world with async/await, but it seems like our best
  // option for now.
  onError?: (Error) => mixed,
  onSuccess?: (any) => mixed,
|};

const DEFAULT_REQUEST_OPTIONS = {
  force: true,
  invalidateable: false,
  onError: undefined,
  onSuccess: undefined,
};

/**
 * Make a request and track it in redux.
 *
 * @param requestId [String] - a unique key which represents this request.
 * @param requestFunc [Function] - the function that makes the request and
 *                                 returns a promise we can track.
 * @param loadedFunc [Function] - a function to handle the result of the
 *                                request. It's useful to use this function if
 *                                you need to process the result _before_ the
 *                                request is marked as successful.
 */
export function request(
  requestId: string,
  requestFunc: () => Promise<mixed>,
  {
    force = true,
    invalidateable = false,
    onError,
    onSuccess,
  }: RequestOptions = DEFAULT_REQUEST_OPTIONS
): ThunkAction {
  return async (dispatch, getState) => {
    const request = getRequest(getState(), requestId);
    const shouldUseCachedRequest =
      request.promise &&
      (Request.success(request) || Request.isLoadingStrict(request)) &&
      !force;
    const promise =
      request.promise && shouldUseCachedRequest
        ? request.promise
        : requestFunc();

    if (!shouldUseCachedRequest) {
      dispatch(requestLoading(requestId, promise, invalidateable));
    }

    let result;

    try {
      result = await promise;
    } catch (err) {
      if (onError) {
        onError(err);
      }

      if (shouldUseCachedRequest) {
        return;
      }

      return dispatch(requestError(requestId, err));
    }

    if (!shouldUseCachedRequest) {
      if (onSuccess) {
        await onSuccess(result);
      }
      dispatch(requestSuccess(requestId, invalidateable));
    }

    return result;
  };
}
