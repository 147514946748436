// @flow
import MarkdownIt from "markdown-it";
import emoji from "markdown-it-emoji";
import mark from "markdown-it-mark";
import taskLists from "markdown-it-task-lists";
import replaceToArray from "string-replace-to-array";
import style from "../components/Markdown/style.scss";

const md = new MarkdownIt("default", {
  linkify: true,
  breaks: true,
})
  .enable(["emphasis", "paragraph", "list", "link", "strikethrough"], false)
  .use(taskLists, { enabled: false, label: true })
  .use(mark)
  .use(emoji);

// extracts all :shorthand: emoji strings and wraps them in a span for extra spacing
md.renderer.rules.emoji = function (token, idx) {
  const character = token[idx];
  return `<span class="${style.emoji}">${character.content}</span>`;
};

// Used to ensure that all links have target="_blank" - see link below for reference
// http://github.com/markdown-it/markdown-it/blob/master/docs/architecture.md#renderer
md.renderer.rules.link_open = (tokens, idx, options, env, self) => {
  // If you are sure other plugins can't add `target` - drop check below
  const aIndex = tokens[idx].attrIndex("target");

  if (aIndex < 0) {
    tokens[idx].attrPush(["target", "_blank"]); // add new attribute
  } else {
    tokens[idx].attrs[aIndex][1] = "_blank"; // replace value of existing attr
  }

  // pass token to default renderer.
  return self.renderToken(tokens, idx, options, env, self);
};

export function readableFilesize(
  bytes: number = 0,
  options: { percent?: number, max?: number } = {}
) {
  const withPercent = options.percent || options.percent === 0;

  if (!bytes) {
    return withPercent ? "0 of 0 Bytes" : "0 Bytes";
  }

  const suffixes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const total = bytes / Math.pow(1024, i);

  let readableTotal = total.toFixed(2);
  if (options.max && readableTotal.length - 1 > options.max) {
    readableTotal = total.toFixed(
      Math.max(0, 2 - (readableTotal.length - 1 - options.max))
    );
  }
  const readable = readableTotal + " " + suffixes[i];

  if (!withPercent) {
    return readable;
  }

  const completed = total * (options.percent || 0);
  return `${completed ? completed.toFixed(2) : "0"} of ${readable}`;
}

export function highlightMentions(
  text: string,
  mentionableText?: string[]
): string {
  // parallel regex extraction as the server
  // propeller-server/blob/master/lib/propeller/mentions.rb#L9
  return text.replace(
    /(\s|^)([@＠]([a-zA-Z0-9_]{2,20}))/g,
    (match, separator, mention, mentionStripped) => {
      if (
        !mentionableText ||
        mentionableText.length === 0 ||
        mentionableText.includes(mentionStripped)
      ) {
        return `${separator}**${mention}**`;
      }
      return `${separator}${mention}`;
    }
  );
}

export function highlightText(
  text: string,
  highlight: string,
  options: {
    renderer?: (tag: string, index: string) => *,
    caseSensitive?: boolean,
  } = {}
): string[] {
  return replaceToArray(
    text,
    new RegExp(
      highlight.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&"),
      options.caseSensitive ? "g" : "gi"
    ),
    options.renderer ? options.renderer : (tag, index) => `==${tag}==`
  );
}

export function renderMarkdown(
  text: string,
  highlight?: string,
  mentionableUsernames?: string[]
): string {
  const normalizedHighlight = highlight ? highlight.trim() : "";

  // ensure emoji UTF-8 characters are converted to :shorthand:
  const mentioned = highlightMentions(text, mentionableUsernames);
  return md.render(
    normalizedHighlight
      ? highlightText(mentioned, normalizedHighlight).join("")
      : mentioned
  );
}

export function truncateOnWord(text: string, limit: number): string {
  // Based on the Sugar.js truncate method
  // WhiteSpace/LineTerminator as defined in ES5.1 plus Unicode characters in the
  // Space, Separator category.
  const trimmable =
    "\u0009\u000A\u000B\u000C\u000D\u0020\u00A0\u1680\u180E\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200A\u202F\u205F\u2028\u2029\u3000\uFEFF";
  const reg = new RegExp(`(?=[${trimmable}])`);
  const words = text.split(reg);
  let count = 0;

  const truncated = words
    .filter((word) => {
      count += word.length;
      return !limit || count <= limit;
    })
    .join("");

  return text === truncated ? text : `${truncated}…`;
}

export function toInitials(text: string, max: number = 3): string {
  const match = text.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g);

  if (!match) {
    return text.slice(0, max);
  }

  const initials = match.join("");
  if (max) {
    return initials.slice(0, max);
  }
  return initials;
}

export function stripNewlines(
  value: ?string,
  replaceValue: string = ""
): string {
  return value ? value.replace(/[\r\n]+/g, replaceValue) : "";
}
