// @flow
import { schema, normalize } from "normalizr";
import { comment, reaction, user } from "core/schema";

export const CommentsResponseSchema = new schema.Object({
  comments: [comment],
  reactions: [reaction],
  users: [user],
});

export function normalizeCommentsResponse(response: *) {
  return normalize(response, CommentsResponseSchema);
}

export function normalizeComments(data: *) {
  return normalize(data, [comment]);
}

export function normalizeComment(data: *) {
  return normalize(data, comment);
}
