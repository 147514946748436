// @flow
import classnames from "classnames";
import * as React from "react";
import Flex from "core/components/Flex";
import Icon, { isFileFormatIcon } from "core/components/Icon";
import Popover from "core/components/Popover";
import Theme from "core/components/Theme";
import { V3Link as Link } from "core/lib/router";
import type { ThemeName } from "core/lib/theme";
import type { LocationDescriptor } from "core/types";
import style from "./style.scss";

type IconProps = {|
  className?: string,
  disabled?: boolean,
  fill?: ?string,
  light?: boolean,
  tint?: boolean,
  type: string,
  small?: boolean,
|};

type TIcon = string | React.AbstractComponent<IconProps>;

export type ButtonElement = "button" | "a" | "span" | typeof Link;

export type Props = {
  children?: React.Node,
  className?: string,
  hideLabel?: boolean,
  marketingTriggerClassName?: string,
  icon?: TIcon,
  iconFill?: ?string,
  iconClassName?: string,
  large?: boolean,
  small?: boolean,
  disclosure?: boolean,
  external?: boolean,
  primary?: boolean,
  nude?: boolean,
  disabled?: boolean,
  danger?: boolean,
  warning?: boolean,
  allgood?: boolean,
  fullwidth?: boolean,
  light?: boolean,
  dark?: boolean,
  tint?: boolean,
  tintHover?: boolean,
  active?: boolean,
  title?: string,
  tooltip?: boolean | React.ElementConfig<typeof Popover>,
  type?: string,
  component?: ButtonElement,
  innerRef?: React.Ref<ButtonElement>,
  alignLeft?: boolean,
  qaSelector?: string,
  to?: LocationDescriptor,
};

function isPNGFile(icon?: TIcon) {
  return !!icon && typeof icon === "string" && isFileFormatIcon(icon);
}

function iconProps(props: Props, themeName: ThemeName): ?IconProps {
  const light =
    props.light ||
    props.allgood ||
    props.warning ||
    props.primary ||
    (props.danger && !props.nude) ||
    (props.dark && !props.nude);

  return typeof props.icon === "string"
    ? {
        type: props.icon,
        small: props.small,
        disabled: props.disabled,
        tint: props.tint,
        fill: props.iconFill,
        light:
          themeName === "dark" && !props.nude
            ? props.icon === "spinner" ||
              !isPNGFile(props.icon) ||
              !props.disabled
            : light && !props.tint,
      }
    : undefined;
}

const DEFAULT_TOOLTIP = { delayShow: 250, placement: "auto" };

export default function Button(props: Props) {
  const {
    active,
    alignLeft,
    allgood,
    children,
    className,
    component,
    danger,
    dark,
    disclosure,
    external,
    fullwidth,
    hideLabel,
    icon,
    iconFill,
    iconClassName,
    innerRef,
    large,
    light,
    marketingTriggerClassName,
    nude,
    primary,
    qaSelector,
    small,
    tint,
    tintHover,
    title,
    tooltip,
    warning,
    ...rest
  } = props;

  const Component = component ? component : "button";
  if (Component === "button" && !rest.type) {
    rest.type = "button";
  }
  const IconComponent =
    typeof icon === "string" ? Icon : icon ? icon : undefined;
  const onlyIcon = icon && children === undefined;
  const applyLight = nude && light && !onlyIcon;
  const applyTint = nude && tint && !onlyIcon;
  const hasLeftIcon = !onlyIcon && icon;
  const hasRightIcon = !onlyIcon && (disclosure || external);

  const classes = classnames(
    style.button,
    {
      [style.buttonFullWidth]: fullwidth,
      [style.buttonLarge]: large,
      [style.buttonSmall]: small,
      [style.buttonHasOnlyIcon]: onlyIcon,
      [style.buttonHasLeftIcon]: hasLeftIcon,
      [style.buttonLargeHasLeftIcon]: hasLeftIcon && large,
      [style.buttonHasRightIcon]: hasRightIcon,
      [style.buttonLargeHasRightIcon]: hasRightIcon && large,
      [style.active]: active,
      [style.nude]: nude && !applyLight && !applyTint,
      [style.nudeDanger]: nude && danger,
      [style.nudeDark]: nude && dark,
      [style.nudeLight]: applyLight,
      [style.nudeTint]: applyTint,
      [style.nudeTintHover]: applyTint && tintHover,
      [style.primary]: primary,
      [style.allgood]: allgood,
      [style.danger]: !nude && danger,
      [style.warning]: warning,
      [style.dark]: !nude && dark,
      [style.neutral]:
        !nude && !primary && !allgood && !danger && !warning && !dark,
    },
    className
  );

  const button = (
    <Component
      {...rest}
      className={classes}
      ref={innerRef}
      title={tooltip ? undefined : title}
      data-qa={qaSelector}
    >
      <Theme.Consumer>
        {({ themeName }) => (
          <Flex justify={alignLeft ? "flex-start" : "center"} align="center">
            {IconComponent ? (
              <IconComponent
                {...iconProps(props, themeName)}
                className={iconClassName}
              />
            ) : undefined}

            {!hideLabel && children && (
              <span className={style.label}>{children}</span>
            )}

            {disclosure && (
              <Icon
                {...iconProps(
                  { ...props, icon: "chevron-default-down" },
                  themeName
                )}
                className={style.iconRight}
              />
            )}

            {external && (
              <Icon
                {...iconProps({ ...props, icon: "external" }, themeName)}
                className={style.iconRight}
              />
            )}
          </Flex>
        )}
      </Theme.Consumer>
    </Component>
  );

  if (tooltip && title) {
    const popoverProps =
      typeof tooltip === "boolean" ? DEFAULT_TOOLTIP : tooltip;

    return (
      <Popover {...popoverProps} label={title} qaSelector="button-tooltip">
        {button}
      </Popover>
    );
  }

  return button;
}
