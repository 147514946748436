// @flow
import classnames from "classnames";
import * as React from "react";
import Flex from "core/components/Flex";
import LogoHeader from "core/components/LogoHeader";
import StepProgressBar from "core/components/StepProgressBar";
import style from "./style.scss";

type Props = {|
  children: React.Node,
  currentStep?: number,
  dottedLines?: number | number[],
  totalSteps?: number,
|};

export default function ContentSheet(props: Props) {
  const showSteps = !!props.totalSteps && props.currentStep !== undefined;

  return (
    <Flex className={style.page} column>
      <header
        className={classnames(style.header, {
          [style.withLogoSpacer]: showSteps,
        })}
      >
        <LogoHeader />
        {!!props.totalSteps && props.currentStep !== undefined ? (
          <Flex align="center" justify="center" grow>
            <StepProgressBar
              className={style.progressBar}
              currentStep={props.currentStep}
              totalSteps={props.totalSteps}
              dottedLines={props.dottedLines}
            />
          </Flex>
        ) : null}
      </header>
      <Flex className={style.contentWrapper} justify="center">
        {props.children}
      </Flex>
    </Flex>
  );
}
