// @flow
import { schema, normalize } from "normalizr";
import { project as projectSchema, section } from "core/schema";
import { envelopeSchema } from "./envelope";

export const projectsResponseSchema = envelopeSchema(
  new schema.Object({
    projects: [projectSchema],
    sections: [section],
  })
);

const projectResponseSchema = envelopeSchema(
  new schema.Object({
    project: projectSchema,
    section: section,
  })
);

export function normalizeProject(project: *) {
  return normalize(project, projectSchema);
}

export function normalizeProjectResponse(response: *) {
  return normalize(response, projectResponseSchema);
}

export function normalizeDeprecatedProjectResponse(response: *) {
  return normalize(response, envelopeSchema(projectSchema));
}

export function normalizeProjectsResponse(response: *) {
  return normalize(response, projectsResponseSchema);
}

const updateProjectResponse = envelopeSchema(projectSchema);

export function normalizeUpdateProjectResponse(response: Object) {
  return normalize(response, updateProjectResponse);
}
