// @flow
import classnames from "classnames";
import * as React from "react";
import ReCaptcha from "react-google-recaptcha";
import Button from "core/components/Button";
import ExternalLink from "core/components/ExternalLink";
import Flex from "core/components/Flex";
import Input from "core/components/Input";
import InputCheckbox from "core/components/InputCheckbox";
import InputPassword from "core/components/InputPassword";
import Loaded from "core/components/Loaded";
import Media from "core/components/Media";
import OnboardingContentSheet from "core/components/OnboardingContentSheet";
import OnboardingHeading from "core/components/OnboardingHeading";
import SerifText from "core/components/SerifText";
import Theme from "core/components/Theme";
import onboardingStyle from "core/components/onboarding.scss";
import { replace } from "core/lib/location";
import { V3Link as Link } from "core/lib/router";
import { rootPath } from "core/lib/routes";
import * as URLs from "core/lib/urls";
import type { CreateAccountFormValues } from "core/requests/account";
import type { ReactRouterLocation, ValidationErrors } from "core/types";
import OnboardingPage from "web/components/Onboarding/Page";
import { RECAPTCHA_SITE_KEY } from "web/config";
import * as Routes from "web/routeHelpers";
import type { Invitation } from "web/types";
import InvitationPanel from "./InvitationPanel";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  location: ReactRouterLocation,
|};

export type StateProps = {|
  errors: ValidationErrors,
  isLoadingSession: boolean,
  isLoggedIn: boolean,
  invitation: ?Invitation,
|};

export type DispatchProps = {|
  createAccount: (
    values: CreateAccountFormValues,
    invitation: ?Invitation,
    onSuccess: () => void,
    onError: () => void
  ) => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

function SignUp(props: Props) {
  const invitation =
    props.invitation && props.invitation.id ? props.invitation : undefined;

  const [email, setEmail] = React.useState(props.location.query.email || "");
  const [name, setName] = React.useState(props.location.query.fullName || "");
  const [password, setPassword] = React.useState("");
  const [marketingConsent, setMarketingConsent] = React.useState(false);
  const [acceptTerms, setAcceptTerms] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isCreatingAccount, setIsCreatingAccount] = React.useState(false);

  const captchaRef = React.useRef<ReCaptcha | null>(null);

  React.useEffect(() => {
    if (props.isLoggedIn && !isCreatingAccount) {
      replace(rootPath());
    }
  }, [isCreatingAccount, props.isLoggedIn]);

  const handleEmailChange = React.useCallback(
    (event: SyntheticInputEvent<>) => {
      setEmail(event.target.value);
    },
    []
  );

  const handleNameChange = React.useCallback((event: SyntheticInputEvent<>) => {
    setName(event.target.value);
  }, []);

  const handlePasswordChange = React.useCallback(
    (event: SyntheticInputEvent<>) => {
      setPassword(event.target.value);
    },
    []
  );

  const handleMarketingConsentChange = React.useCallback(
    (event: SyntheticInputEvent<>) => {
      setMarketingConsent(event.target.checked);
    },
    []
  );

  const handleAcceptTermsChange = React.useCallback(
    (event: SyntheticInputEvent<>) => {
      setAcceptTerms(event.target.checked);
    },
    []
  );

  const handleCaptchaError = React.useCallback(() => {
    setIsSubmitting(false);
  }, []);

  const handleSubmit = React.useCallback(
    async (event: SyntheticInputEvent<>) => {
      event.preventDefault();

      setIsCreatingAccount(true);

      if (isSubmitting) {
        return;
      }

      if (captchaRef.current) {
        const captchaValue = await captchaRef.current.executeAsync();

        setIsSubmitting(true);

        props.createAccount(
          {
            name,
            email,
            password,
            captchaValue,
            marketingConsent,
            acceptTos: acceptTerms,
            invitationToken: invitation ? invitation.token : undefined,
          },
          invitation,
          () => {
            if (invitation) {
              const returnTo =
                props.location.state && props.location.state.returnTo;
              return replace(
                Routes.invitation(invitation.token, {
                  onboarding: true,
                  returnTo,
                })
              );
            }

            replace({
              pathname: Routes.signupCreateOrJoinOrganization(),
              state: { onboarding: true },
            });
          },
          () => {
            setIsSubmitting(false);
          }
        );

        if (captchaRef.current) {
          captchaRef.current.reset();
        }
      }
    },
    [
      acceptTerms,
      email,
      invitation,
      isSubmitting,
      marketingConsent,
      name,
      password,
      props,
    ]
  );

  return (
    <Media mobile>
      {(mobile) => (
        <Loaded loading={props.isLoadingSession}>
          <OnboardingPage
            currentStep={invitation ? undefined : 1}
            totalSteps={invitation ? undefined : 4}
          >
            <OnboardingContentSheet
              className={onboardingStyle.mainContentSheet}
            >
              <Flex
                align="center"
                className={onboardingStyle.centeredText}
                column
              >
                <OnboardingHeading level="1">
                  {invitation ? (
                    <React.Fragment>
                      You’ve been <SerifText>invited</SerifText>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      A little bit <SerifText>about you</SerifText>
                    </React.Fragment>
                  )}
                </OnboardingHeading>
                {invitation ? (
                  <InvitationPanel
                    className={style.invitation}
                    invitation={invitation}
                  />
                ) : (
                  <div
                    className={classnames(
                      onboardingStyle.subHeading,
                      onboardingStyle.largeCopy
                    )}
                  >
                    Let’s get your account set up!
                  </div>
                )}
                <div
                  className={classnames(
                    onboardingStyle.extraInfo,
                    onboardingStyle.mediumCopy
                  )}
                >
                  Already have an account?{" "}
                  <Link
                    className={onboardingStyle.link}
                    to={Routes.signin(
                      props.location.state
                        ? props.location.state.returnTo
                        : undefined
                    )}
                  >
                    Sign in here
                  </Link>
                </div>
              </Flex>
              <form className={onboardingStyle.form} onSubmit={handleSubmit}>
                <div>
                  <Input
                    autoComplete="email"
                    className={onboardingStyle.input}
                    error={props.errors.email}
                    label={
                      <span className={onboardingStyle.inputLabel}>
                        Email address
                      </span>
                    }
                    onChange={handleEmailChange}
                    placeholder="mika@banktm.com"
                    type="email"
                    value={email}
                    required
                  />
                  <Input
                    autoComplete="name"
                    className={onboardingStyle.input}
                    error={props.errors.name}
                    label={
                      <span className={onboardingStyle.inputLabel}>
                        Full name
                      </span>
                    }
                    onChange={handleNameChange}
                    placeholder="Mika Sharp"
                    value={name}
                    required
                  />
                  <InputPassword
                    autoCapitalize="none"
                    autoComplete="new-password"
                    className={onboardingStyle.input}
                    error={props.errors.password}
                    label={
                      <span className={onboardingStyle.inputLabel}>
                        Password
                      </span>
                    }
                    onChange={handlePasswordChange}
                    placeholder="•••••••••••••••••"
                    value={password}
                    required
                  />
                  <InputCheckbox
                    checkboxClass={onboardingStyle.checkbox}
                    label="Send me product news and training emails."
                    labelClass={onboardingStyle.checkboxLabel}
                    onChange={handleMarketingConsentChange}
                  />
                  <InputCheckbox
                    checkboxClass={onboardingStyle.checkbox}
                    label={
                      <React.Fragment>
                        I’ve read and accept the{" "}
                        <ExternalLink
                          className={onboardingStyle.link}
                          href={URLs.termsOfServiceUrl()}
                        >
                          Terms of Service
                        </ExternalLink>
                        .
                      </React.Fragment>
                    }
                    labelClass={onboardingStyle.checkboxLabel}
                    onChange={handleAcceptTermsChange}
                    required
                  />
                </div>
                <Button
                  className={classnames(
                    onboardingStyle.button,
                    onboardingStyle.submit
                  )}
                  disabled={isSubmitting}
                  type="submit"
                  fullwidth
                  large
                  primary
                >
                  {isSubmitting ? "Creating account…" : "Create account"}
                </Button>
                <Theme.Consumer>
                  {({ themeName }) => (
                    <ReCaptcha
                      badge={mobile ? "inline" : "bottomleft"}
                      className={classnames({
                        [style.mobileRecaptcha]: mobile,
                      })}
                      key={mobile}
                      onErrored={handleCaptchaError}
                      onExpired={handleCaptchaError}
                      ref={captchaRef}
                      sitekey={RECAPTCHA_SITE_KEY}
                      size="invisible"
                      theme={themeName === "dark" ? "dark" : "light"}
                    />
                  )}
                </Theme.Consumer>
              </form>
            </OnboardingContentSheet>
          </OnboardingPage>
        </Loaded>
      )}
    </Media>
  );
}

export default connector(SignUp);
