// @flow
import classnames from "classnames";
import * as React from "react";
import InputWrapper from "../InputWrapper";
import style from "./style.scss";

let counter = 0;

type Props = {
  error?: string | string[],
  label?: React.Node,
  responsive?: boolean,
  requiredTag?: boolean,
  wrapperClass?: string,
  labelClass?: string,
  className?: string,
  icon?: React.Node,
  id?: string,
  qaSelector?: string,
};

export default class InputRadio extends React.Component<Props> {
  id: string;

  constructor() {
    super();
    this.id = `abstract-core-input-radio-${counter}`;
    counter += 1;
  }

  render() {
    const {
      error,
      label,
      responsive,
      requiredTag,
      wrapperClass,
      labelClass,
      className,
      icon,
      qaSelector,
      ...props
    } = this.props;
    props.id = props.id || this.id;

    const inputClasses = classnames(style.radio, className);
    const wrapperClasses = classnames(style.wrapper, wrapperClass);

    return (
      <InputWrapper
        inline
        error={error}
        inputId={props.id}
        label={label}
        labelClass={labelClass}
        responsive={responsive}
        requiredTag={requiredTag}
        className={wrapperClasses}
      >
        <input
          className={inputClasses}
          {...props}
          type="radio"
          data-qa={qaSelector}
        />
      </InputWrapper>
    );
  }
}
