// @flow
import * as React from "react";
import { toInitials } from "core/lib/textFormatting";
import style from "./style.scss";

type Props = {
  className?: string,
  alt: string,
  size: number,
};

export default function DefaultOrganizationLogo(props: Props) {
  const { className, size, alt } = props;
  const initials = toInitials(alt);
  const fontSize = size / 2.5;

  return (
    <svg
      role="img"
      aria-label={alt}
      width={size}
      height={size}
      className={className}
      viewBox={`0 0 ${size} ${size}`}
    >
      <title>{alt}</title>
      <rect x="0" y="0" width={size} height={size} className={style.bg} />
      <text x="50%" y="50%" fontSize={fontSize} className={style.text}>
        {initials}
      </text>
    </svg>
  );
}
