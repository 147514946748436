// @flow
import { omit } from "lodash";
import * as React from "react";
import Input from "../Input";

type Props = {
  error?: string,
  onInput?: (SyntheticEvent<*>) => *,
  onBlur?: (SyntheticEvent<*>) => *,
  validationMessage?: string,
};

type State = {
  invalid: boolean,
  isEdited: boolean,
};

// TODO: It would be nice to allow validation message to take an object with
// error messages that map to the possible validations on the input as defined
// here: http://www.html5rocks.com/en/tutorials/forms/constraintvalidation
export function withValidation(WrappedInputComponent: React.ComponentType<*>) {
  return class FieldWithValidation extends React.Component<Props, State> {
    input: *;
    state = { isEdited: false, invalid: false };

    onInput = (ev: SyntheticEvent<*>) => {
      // We only check if the input has become VALID as the user is interacting,
      // this stops us prematurely notifying the user that their input is INVALID.
      if (this.input.checkValidity()) {
        this.setState({ invalid: false, isEdited: true });
      } else if (!this.state.isEdited) {
        this.setState({ isEdited: true });
      }
      if (this.props.onInput) {
        return this.props.onInput(ev);
      }
      return null;
    };

    onBlur = (ev: SyntheticEvent<*>) => {
      // On exiting the field we check validity if the user has edited the field.
      // This prevents us prematurely notifying that input is invalid.
      if (this.state.isEdited) {
        this.setState({ invalid: !this.input.checkValidity() });
      }
      if (this.props.onBlur) {
        return this.props.onBlur(ev);
      }
      return null;
    };

    getErrorMessage = () => {
      if (this.state.invalid) {
        return this.props.validationMessage;
      }
      return null;
    };

    render() {
      const props = omit(this.props, "validationMessage");

      return (
        <WrappedInputComponent
          {...props}
          error={this.getErrorMessage() || this.props.error}
          onInput={this.onInput}
          onBlur={this.onBlur}
          ref={(input) => (this.input = input)}
        />
      );
    }
  };
}

export const ValidatedInput = withValidation(Input);
