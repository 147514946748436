// @flow
import classnames from "classnames";
import * as React from "react";
import Card from "core/components/Card";
import ChangeStatusBadge from "core/components/ChangeStatusBadge";
import Flex from "core/components/Flex";
import Icon from "core/components/Icon";
import type { ChangesetStatus, Layer } from "core/types";
import style from "./style.scss";

type Props = {|
  className?: string,
  layer: Layer,
  status: ChangesetStatus,
|};

function getLabelText(layer: Layer, status: ChangesetStatus) {
  const layerName = (
    <span
      className={classnames(style.emphasis, {
        [style.strikethrough]: status === "deleted",
      })}
    >
      {layer.name}
    </span>
  );
  const libraryText = layer.libraryName ? (
    <React.Fragment>
      from library <span className={style.emphasis}>{layer.libraryName}</span>
    </React.Fragment>
  ) : (
    ""
  );

  if (status === "added") {
    return (
      <React.Fragment>
        {layerName} added {libraryText}
      </React.Fragment>
    );
  }

  if (status === "edited" || status === "edited-indirectly") {
    return (
      <React.Fragment>
        {layerName} updated {libraryText}
      </React.Fragment>
    );
  }

  return layerName;
}

export default function LinkedSymbolListItem({
  className,
  layer,
  status,
}: Props) {
  return (
    <Card
      className={classnames(className, style.item)}
      innerClassName={style.innerItem}
      list
      wrappedList
    >
      <Icon type="file-library-symbol" className={style.icon} />
      <Flex column className={style.center}>
        <div>{getLabelText(layer, status)}</div>
      </Flex>
      <ChangeStatusBadge className={style.badge} status={status} fromLibrary />
    </Card>
  );
}
