// @flow
import { connect } from "react-redux";
import { trackEvent } from "core/actions/analytics";
import {
  ShareLinkUpdateRequest,
  ShareLinkCreateRequest,
} from "core/requests/shareLinks";
import { getOrganizationForProject } from "core/selectors/organizations";
import { getOrganizationPolicy } from "core/selectors/policies";
import { getShareLinkForKind } from "core/selectors/shareLinks";
import type { Dispatch, State, ShareLinkUpdateRequestParams } from "core/types";
import type { OwnProps, StateProps, DispatchProps, Props } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { inputShare } = props;
  const { projectId } = inputShare.descriptor;
  const organization = getOrganizationForProject(state, { projectId });

  const policy = getOrganizationPolicy(state, {
    organizationId: organization ? organization.id : "",
  });

  const shareLink = getShareLinkForKind(state, { inputShare });

  return {
    isGeneratingShareLink: ShareLinkCreateRequest.isLoadingStrict(
      state,
      inputShare
    ),
    publicSharingEnabled: !!(organization && organization.publicSharingEnabled),
    canManageSettings: policy.manageSettings,
    shareLink,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    updateShareLink(params: ShareLinkUpdateRequestParams) {
      return dispatch(
        ShareLinkUpdateRequest.perform({
          params,
          onSuccess() {
            if (params.isPublic) {
              dispatch(
                trackEvent("PUBLIC_SHARE_LINK_CREATED", props.inputShare)
              );
            }
          },
        })
      );
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
