// @flow
import * as React from "react";
import Button from "core/components/Button";
import InputSearch from "core/components/InputSearch";
import ListDisplayToggle from "core/components/ListDisplayToggle";
import OnboardingBubble from "core/components/OnboardingBubble";
import PageTitle from "core/components/PageTitle";
import PageTitleNavigationLink from "core/components/PageTitleNavigationLink";
import { Abstract } from "core/lib/abstract";
import {
  organizationTeamsPath,
  organizationPeoplePath,
  projectMembersPath,
} from "core/lib/routes";
import type { RoleFilter } from "core/types";

type Props = {|
  params: Abstract.OrganizationDescriptor | Abstract.ProjectDescriptor,
  mode: "people" | "teams" | "everyone",
  isGrid: boolean,
  isProject?: boolean,
  onDisplayList: () => void,
  onDisplayGrid: () => void,
  searchFilter: ?string,
  onSearch?: () => void,
  onSearchChange: (event: SyntheticInputEvent<>) => void,
  mobile?: boolean,
  canAddMember?: boolean,
  roleFilter?: ?RoleFilter,
  onPrimaryActionTrigger?: () => void,
  renderInviteDialog?: () => React.Node,
  canAddTeam?: boolean,
  disabled?: boolean,
  canCreateTeam?: boolean,
  canInviteToProjects?: boolean,
  isTeamsAnnouncementEnabled?: boolean,
|};

export default function PeopleTeamsHeader(props: Props) {
  const actions = [];

  if (
    (props.mode === "people" && props.canAddMember) ||
    (props.mode === "teams" && props.isProject && props.canInviteToProjects) ||
    (props.mode === "everyone" && props.isProject && props.canInviteToProjects)
  ) {
    actions.push(
      <Button
        icon="plus"
        onClick={props.onPrimaryActionTrigger}
        nude={props.mobile}
        primary={!props.mobile}
        qaSelector="invitePeopleButton"
      >
        {props.mobile
          ? undefined
          : `Invite ${props.isProject ? "to Project" : "people"}`}
      </Button>
    );
  } else if (
    props.mode === "teams" &&
    props.canCreateTeam &&
    !props.isProject
  ) {
    actions.push(
      <Button
        icon="plus"
        onClick={props.onPrimaryActionTrigger}
        nude={props.mobile}
        primary={!props.mobile}
        disabled={props.disabled}
      >
        {props.mobile ? undefined : "Create a Team"}
      </Button>
    );
  }

  const search = props.searchFilter || "";

  const peoplePath =
    props.isProject && props.params.projectId
      ? projectMembersPath(props.params.projectId, "people", search)
      : props.params.organizationId
      ? organizationPeoplePath(props.params.organizationId)
      : "";

  const teamsPath =
    props.isProject && props.params.projectId
      ? projectMembersPath(props.params.projectId, "teams", search)
      : props.params.organizationId
      ? organizationTeamsPath(props.params.organizationId)
      : "";

  const navigation = [];

  if (props.isProject && props.params.projectId) {
    navigation.push(
      <PageTitleNavigationLink
        active={props.mode === "everyone"}
        to={projectMembersPath(props.params.projectId, "everyone", search)}
      >
        Everyone
      </PageTitleNavigationLink>
    );
  }

  const renderTeamsLink = () => {
    return (
      <PageTitleNavigationLink
        active={props.mode === "teams"}
        icon="team"
        to={teamsPath}
      >
        Teams
      </PageTitleNavigationLink>
    );
  };

  navigation.push(
    <PageTitleNavigationLink
      active={props.mode === "people"}
      icon="user"
      to={peoplePath}
    >
      People
    </PageTitleNavigationLink>,
    props.isTeamsAnnouncementEnabled ? (
      <OnboardingBubble
        storageKey="teams-announcement-onboarded"
        text="Group your people and projects with Teams."
      >
        {renderTeamsLink()}
      </OnboardingBubble>
    ) : (
      renderTeamsLink()
    )
  );

  return (
    <React.Fragment>
      <PageTitle
        actions={actions}
        displayControl={
          <ListDisplayToggle
            isGrid={props.isGrid}
            onToggleList={props.onDisplayList}
            onToggleGrid={props.onDisplayGrid}
            disabled={props.disabled}
          />
        }
        navigation={navigation}
        search={
          <InputSearch
            large={props.mobile}
            placeholder="Search by name…"
            defaultValue={props.searchFilter}
            onSearch={props.onSearch}
            onChange={props.onSearchChange}
            disabled={props.disabled}
          />
        }
        title={props.isProject ? "Project Members" : "People & Teams"}
      />
      {props.canAddMember &&
        props.renderInviteDialog &&
        props.renderInviteDialog()}
    </React.Fragment>
  );
}
