// @flow
import * as React from "react";
import { withData } from "core/components/DataLoader";
import DocumentTitle from "core/components/DocumentTitle";
import Error from "core/components/Empty/Error";
import NotFound from "core/components/Empty/NotFound";
import Loaded from "core/components/Loaded";
import ProjectArchivedBanner from "core/components/ProjectArchivedBanner";
import { replace } from "core/lib/location";
import type { Project, ReactRouterLocation } from "core/types";
import { invitation } from "web/routeHelpers";

export type OwnProps = {|
  params: { projectId: string, branchId?: string },
  location: ReactRouterLocation,
  children: React.Node,
|};

export type StateProps = {|
  project: ?Project,
  notFound: boolean,
  forbidden: boolean,
  error: boolean,
  isLoading: boolean,
|};

export type DispatchProps = {|
  onLoad: () => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

class ProjectShell extends React.Component<Props> {
  render() {
    const {
      children,
      project,
      isLoading,
      error,
      notFound,
      forbidden,
      location,
    } = this.props;
    const { invitationToken } = location.query;

    return (
      <React.Fragment>
        {!!project && (
          <DocumentTitle
            titleTemplate={`${project.name} - %s`}
            defaultTitle={project.name}
          />
        )}
        <Loaded loading={isLoading} title="Loading project…" flex>
          {() => {
            if (error) {
              return <Error flex />;
            }
            if (forbidden && invitationToken) {
              replace(invitation(invitationToken, { returnTo: location }));
              return;
            }
            if (notFound || forbidden || (!isLoading && !project)) {
              return <NotFound flex />;
            }
            return (
              <React.Fragment>
                {children}
                {project && project.archivedAt && (
                  <ProjectArchivedBanner projectName={project.name} />
                )}
              </React.Fragment>
            );
          }}
        </Loaded>
      </React.Fragment>
    );
  }
}

export default withData((props: Props) => props.params)(ProjectShell);
