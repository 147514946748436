// @flow
import createCachedSelector from "@elasticprojects/re-reselect";
import { reduce, find, values, filter } from "lodash";
import { createSelector } from "reselect";
import naturalSortBy from "core/lib/naturalSortBy";
import { getOrganizationId } from "core/selectors/helpers";
import { getSlackIntegrationOverrides } from "core/selectors/integrations";
import { getProjectMembershipEntities } from "core/selectors/projectMemberships";
import type { Project, SlackIntegrationOverride, State } from "core/types";
import { getCurrentUser } from "web/selectors/session";

type Props = { projectId: string };

export function getProject(state: State, projectId: string): ?Project {
  return state.projects[projectId];
}

export function getProjectEntities(state: State) {
  return state.projects;
}

export const getAllProjects: (state: State) => Project[] = createSelector(
  getProjectEntities,
  (projects) => naturalSortBy(values(projects), "name")
);

export const getProjects: (State, { organizationId: string }) => Project[] =
  createCachedSelector(
    getProjectEntities,
    getOrganizationId,
    (projects, organizationId) => filter(values(projects), { organizationId })
  )(getOrganizationId);

export const getGroupedProjectsByOverride: (
  State,
  { organizationId: string }
) => {
  // TODO: this can be turned into Project once 'override' property is removed
  [key: string]: { ...Project, override: SlackIntegrationOverride }[],
} = createCachedSelector(
  getProjects,
  getSlackIntegrationOverrides,
  (projects, overrides) => {
    return reduce(
      projects,
      (result, project) => {
        const override = find(overrides, { projectId: project.id });
        const key = override ? "hasOverride" : "default";
        if (!result[key]) {
          result[key] = [];
        }
        result[key].push({ ...project, override });
        return result;
      },
      {}
    );
  }
)(getOrganizationId);

const getProjectId = (state: State, props: Props) => props.projectId;

export const getUserCanAccessProject: (state: State, props: Props) => boolean =
  createCachedSelector(
    getProjectId,
    getCurrentUser,
    getProjectMembershipEntities,
    (projectId, currentUser, memberships) => {
      if (!currentUser) {
        return false;
      }

      return !!memberships[`${projectId}-${currentUser.id}`];
    }
  )(getProjectId);
