// @flow
/* global window, document */
import * as React from "react";

type Props = {
  onFocus?: () => *,
  onBlur?: () => *,
  children: any,
};

type State = { focused: boolean };

export default class WindowFocusListener extends React.Component<Props, State> {
  state = { focused: true };

  componentDidMount() {
    window.addEventListener("focus", this.handleFocus);
    window.addEventListener("blur", this.handleBlur);
    window.addEventListener("visibilitychange", this.handleVisibilityChange);
  }

  componentWillUnmount() {
    window.removeEventListener("focus", this.handleFocus);
    window.removeEventListener("blur", this.handleBlur);
    window.removeEventListener("visibilitychange", this.handleVisibilityChange);
  }

  handleFocus = () => {
    this.setState({ focused: true });
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  };

  handleBlur = () => {
    this.setState({ focused: false });
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  };

  handleVisibilityChange = () => {
    if (document.hidden) {
      return this.handleBlur();
    }

    return this.handleFocus();
  };

  get focused(): boolean {
    return this.state.focused;
  }

  render() {
    return this.props.children;
  }
}
