// @flow
import classnames from "classnames";
import * as React from "react";
import style from "./style.scss";

type Props = {
  error?: ?string | string[],
  className?: string,
};

export default function ValidationError({ error, className }: Props) {
  let message;

  if (Array.isArray(error)) {
    message = (
      <ul className={style.errorList}>
        {error.map((e) => (
          <li key={e}>{e}</li>
        ))}
      </ul>
    );
  } else {
    message = error;
  }

  return <div className={classnames(style.error, className)}>{message}</div>;
}
