// @flow
import { connect } from "react-redux";
import { isOnline, isLoggedIn } from "abstract-di/selectors";
import { displayName } from "core/models/branch";
import * as ShareLink from "core/models/shareLink";
import {
  getPinnedCommentsForLayer,
  getShouldHideCommentGroup,
  getResolvedCommentsForLayer,
} from "core/selectors/comments";
import { getCommitForLayer } from "core/selectors/commits";
import {
  getFeatureEnabled,
  getProjectFeatureEnabled,
  canUseNewDefaultBranchName,
} from "core/selectors/features";
import { getOrganizationForProject } from "core/selectors/organizations";
import { getShareLinkForKind } from "core/selectors/shareLinks";
import type { Dispatch, State } from "core/types";
import type { OwnProps, StateProps, Props } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const inputShareParams = props.collectionId
    ? {
        kind: "collection",
        descriptor: {
          projectId: props.projectId,
          branchId: props.branchId,
          collectionId: props.collectionId,
        },
      }
    : {
        kind: "layer",
        descriptor: {
          projectId: props.projectId,
          branchId: props.branchId,
          fileId: props.fileId,
          layerId: props.layerId,
          sha: props.sha,
        },
      };

  const shareLink = getShareLinkForKind(state, {
    inputShare: inputShareParams,
  });

  const params = {
    projectId: props.projectId,
    branchId: props.branchId,
    fileId: props.fileId,
    layerId: props.layerId,
  };

  const pinnedComments = getPinnedCommentsForLayer(state, params);
  const shouldHideCommentGroups = getShouldHideCommentGroup(state, params);

  const organization = getOrganizationForProject(state, {
    projectId: props.projectId,
  });

  const resolveCommentsEnabled = getProjectFeatureEnabled(state, {
    projectId: props.projectId,
    feature: "resolve-comments",
  });

  const resolvedCommentsCount = resolveCommentsEnabled
    ? getResolvedCommentsForLayer(state, params).length
    : 0;

  const parentBranchName = displayName(props.parentBranch, {
    masterToMain: canUseNewDefaultBranchName(state),
    titleCase: true,
  });

  return {
    commit: getCommitForLayer(state, {
      ...params,
      sha: props.sha,
    }),
    isOffline: !isOnline(state),
    isLoggedIn: isLoggedIn(state),
    isPubliclyShared: ShareLink.isPublic(shareLink),
    parentBranchName,
    pinCommentsEnabled: organization
      ? getFeatureEnabled(state, {
          feature: "pin-comments",
          organizationId: organization.id,
        })
      : false,
    pinnedComments,
    resolveCommentsEnabled,
    resolvedCommentsCount,
    shouldHideCommentGroups,
  };
}

export default connect<Props, OwnProps, StateProps, _, State, Dispatch>(
  mapStateToProps
);
