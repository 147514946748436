// @flow
/* global HTMLElement */
import classnames from "classnames";
import empty from "empty";
import { uniq, map, keys, some } from "lodash";
import * as React from "react";
import scrollIntoView from "smooth-scroll-into-view-if-needed";
import ShareLinkSignin from "abstract-di/components/ShareLinkSignin";
import CommentCreateForm from "core/components/CommentCreateForm";
import CommentsList from "core/components/CommentsList";
import Error from "core/components/Empty/Error";
import Loaded from "core/components/Loaded";
import MountProfiler from "core/components/MountProfiler";
import Scrollable from "core/components/Scrollable";
import type { Branch, Comment, Commit, Layer } from "core/types";
import CommentGroup from "./CommentGroup";
import PinnedComment from "./PinnedComment";
import ScrollButton from "./ScrollButton";
import ToggleResolvedCommentsButton from "./ToggleResolvedCommentsButton";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  layer: ?Layer,
  branch?: ?Branch,
  parentBranch?: ?Branch,
  commits?: { [string]: Commit },
  comments: { [sha: string]: Comment[] },
  mobile?: boolean,
  collapsed?: boolean,
  isSyncing?: boolean,
  isLoading?: boolean,
  isLoadingLayer?: boolean,
  isLoadingCommits?: boolean,
  hasError?: boolean,
  projectId: string,
  branchId: string,
  fileId: string,
  pageId?: string,
  layerId: string,
  sha: string,
  collectionId?: string,
  itemClass?: string,
  currentUserId?: string,
  scrollToCommentId?: string,
  highlightedCommentId?: string,
  selectedCommentId?: ?string,
  onCommentSelected?: (string) => void,
  commentComponent?: *,
  form: Object,
  onFormChanged: (Object) => void,
  onCommitClick?: (SyntheticEvent<>, { sha: string }) => void,
  canShowHistory: boolean,
  shareId?: string,
  scrollable?: boolean,
  profilerId?: string,
  isCreateCommentFormFocused?: boolean,
  toggleNewAnnotation?: boolean,
  isShowingResolvedComments?: boolean,
  onResolveCommentsOptionClick?: () => void,
  tintBackgroundOnHover?: boolean,
  className?: string,
|};

export type StateProps = {|
  commit: ?Commit,
  isOffline: boolean,
  isLoggedIn: boolean,
  isPubliclyShared: boolean,
  parentBranchName: ?string,
  pinCommentsEnabled: boolean,
  pinnedComments?: Comment[],
  resolveCommentsEnabled: boolean,
  shouldHideCommentGroups: { [sha: string]: boolean },
  resolvedCommentsCount: number,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
};

type State = {
  scrollButtonPlacement?: "above" | "below",
};

class LayerComments extends React.Component<Props, State> {
  button: ?ScrollButton;
  scrollable: ?HTMLDivElement;

  commentGroups: { [string]: ?HTMLElement } = {};

  state = {
    scrollButtonPlacement: undefined,
  };

  buttonRef = (ref: ?ScrollButton) => (this.button = ref);
  scrollableRef = (ref: *) => {
    this.scrollable = ref;
    return;
  };

  cacheGroupRef = (sha: string) => (ref: ?HTMLElement) => {
    this.commentGroups[sha] = ref;
  };

  scrollToGroup = () => {
    if (this.props.collapsed) {
      return;
    }

    const group = this.commentGroups[this.props.sha];
    if (group) {
      scrollIntoView(group, {
        block: "end",
        duration: 500,
        behavior: "smooth",
      });
    }
  };

  handleFormVisible = () => {
    if (this.button) {
      this.setState({
        scrollButtonPlacement: undefined,
      });
    }
  };

  handleFormHidden = () => {
    const scrollableNode = this.getScrollableNode();
    const groupNode = this.getGroupNode();

    if (scrollableNode && groupNode) {
      const scollableRect = scrollableNode.getBoundingClientRect();
      const groupRect = groupNode.getBoundingClientRect();
      const top = groupRect.top - scollableRect.top;

      if (top > 0) {
        if (top < scollableRect.height) {
          return;
        }
        if (this.button) {
          this.setState({
            scrollButtonPlacement: "below",
          });
        }
      } else {
        if (Math.abs(top) < groupRect.height) {
          return;
        }
        if (this.button) {
          this.setState({
            scrollButtonPlacement: "above",
          });
        }
      }
    }
  };

  getScrollableNode = () => {
    const node = this.scrollable;
    return node instanceof HTMLElement ? node : null;
  };

  getGroupNode = () => {
    const node = this.commentGroups[this.props.sha];
    return node instanceof HTMLElement ? node : null;
  };

  get commitShas(): string[] {
    // We want to ensure that if we're on the latest commit endpoint,
    // and there are no comments on the latest commit, empty group stays on top.
    return uniq([
      ...keys(this.props.commits),
      ...keys(this.props.comments),
      this.props.sha,
    ]);
  }

  renderGroups(): React.Node[] {
    const groups = [];

    map(this.commitShas, (sha) => {
      let selected = false;
      if (this.props.sha === "latest") {
        if (this.props.commit) {
          selected = sha === this.props.commit.sha;
        }
      } else {
        selected = sha === this.props.sha;
      }

      const commit =
        selected && this.props.commit
          ? this.props.commit
          : this.props.commits
          ? this.props.commits[sha]
          : undefined;
      const commitComments = this.props.comments[sha];
      const isEmpty = !commitComments || !commitComments.length;

      const hideGroup =
        this.props.resolveCommentsEnabled &&
        !this.props.isShowingResolvedComments &&
        this.props.shouldHideCommentGroups[sha];

      if (!selected && (isEmpty || hideGroup)) {
        return;
      }

      groups.push({
        sha,
        commit,
        selected,
        commitComments,
        hideGroup,
      });
    });

    return groups.map(
      ({ sha, commit, selected, commitComments, hideGroup }, index) => {
        return (
          <CommentGroup
            key={sha}
            {...this.props}
            commit={commit}
            sha={sha}
            commitComments={
              hideGroup ? empty.array : commitComments || empty.array
            }
            selected={selected}
            groupRef={this.cacheGroupRef(sha)}
            getScrollableNode={this.getScrollableNode}
            onFormVisible={this.handleFormVisible}
            onFormHidden={this.handleFormHidden}
            onClick={(event) =>
              this.props.onCommitClick &&
              this.props.onCommitClick(event, { sha })
            }
            pinCommentsEnabled={this.props.pinCommentsEnabled}
            resolveCommentsEnabled={this.props.resolveCommentsEnabled}
            isShowingResolvedComments={this.props.isShowingResolvedComments}
            tintBackgroundOnHover
            allowExtraFormBottomSpacing={groups.length - 1 === index}
          />
        );
      }
    );
  }

  renderLayerComments() {
    const {
      isLoading,
      isLoadingCommits,
      isOffline,
      isSyncing,
      commits,
      comments,
      hasError,
    } = this.props;
    const hasCommits = commits ? Object.keys(commits).length > 0 : false;
    const hasComments = some(comments, (shaComments) => shaComments.length);
    const showSpinner =
      isLoading ||
      (isLoadingCommits && !hasCommits && !isLoading && hasComments) ||
      (!!isSyncing && !hasCommits);

    return (
      <div className={style.container}>
        {hasError && !isOffline ? (
          <Error flex />
        ) : (
          <React.Fragment>
            {this.props.profilerId && showSpinner && (
              <MountProfiler
                id={this.props.profilerId}
                params={{ projectId: this.props.projectId }}
                unstable_profileOnUnmount
              />
            )}
            <Loaded loading={showSpinner} flex>
              {() => {
                const groups = this.renderGroups();

                return (
                  <div className={style.layerComments}>
                    {this.props.resolvedCommentsCount > 0 && (
                      <ToggleResolvedCommentsButton
                        isStacked={this.state.scrollButtonPlacement === "below"}
                        isShowingResolvedComments={
                          !!this.props.isShowingResolvedComments
                        }
                        resolvedCommentsCount={this.props.resolvedCommentsCount}
                        onResolveCommentsOptionClick={
                          this.props.onResolveCommentsOptionClick
                        }
                      />
                    )}
                    <ScrollButton
                      ref={this.buttonRef}
                      scrollToGroup={this.scrollToGroup}
                      isLoggedIn={this.props.isLoggedIn}
                      placement={this.state.scrollButtonPlacement}
                    />
                    <Scrollable
                      innerRef={this.scrollableRef}
                      className={classnames({
                        [style.boxShadow]: !this.props.isLoggedIn,
                      })}
                    >
                      <React.Fragment>
                        {this.props.pinCommentsEnabled &&
                        this.props.pinnedComments &&
                        this.props.pinnedComments.length ? (
                          <div className={style.pinnedCommentsContainer}>
                            {this.props.pinnedComments.map((comment) => (
                              <PinnedComment
                                key={comment.id}
                                comment={comment}
                              />
                            ))}
                          </div>
                        ) : null}
                        {groups}
                      </React.Fragment>
                    </Scrollable>
                    {!this.props.isLoggedIn && <ShareLinkSignin />}
                  </div>
                );
              }}
            </Loaded>
          </React.Fragment>
        )}
      </div>
    );
  }

  render() {
    if (!this.props.mobile && this.props.canShowHistory) {
      return this.renderLayerComments();
    }

    const { comments, ...rest } = this.props;
    const commentsList = comments[this.props.sha] || empty.array;
    const showShareLinkSignin = !this.props.isLoading && !this.props.isLoggedIn;

    if (showShareLinkSignin && !commentsList.length) {
      return <ShareLinkSignin showIcon />;
    }

    const hideGroup =
      this.props.resolveCommentsEnabled &&
      !this.props.isShowingResolvedComments &&
      this.props.shouldHideCommentGroups[this.props.sha];

    return (
      <React.Fragment>
        <CommentsList
          {...rest}
          shadow
          comments={hideGroup ? empty.array : commentsList}
          className={classnames({
            [style.boxShadow]: showShareLinkSignin,
            [style.mobileCommentsList]: this.props.mobile,
          })}
          scrollable={this.props.scrollable || this.props.mobile}
          isPubliclyShared={this.props.isPubliclyShared}
          pinnedComments={this.props.pinnedComments}
          highlightPinnedComment
          enableHidingResolvedComments
          tintBackgroundOnHover
        />
        {this.props.isLoading ? null : this.props.isLoggedIn ? (
          <CommentCreateForm
            {...this.props}
            projectId={this.props.projectId}
            branchId={this.props.branchId}
            inline={this.props.mobile}
            form={this.props.form}
            onFormChanged={this.props.onFormChanged}
            isAnnotatable={!this.props.mobile}
            className={style.defaultForm}
            disabled={!this.props.layer}
            isPubliclyShared={this.props.isPubliclyShared}
            autoFocus={this.props.isCreateCommentFormFocused}
            toggleNewAnnotation={this.props.toggleNewAnnotation}
          />
        ) : (
          <ShareLinkSignin className={style.shareLinkSignin} />
        )}
      </React.Fragment>
    );
  }
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default connector(LayerComments);
