// @flow
import * as React from "react";
import PageTitle from "core/components/PageTitle";
import EmojisView, {
  type OwnProps,
} from "web/components/OrganizationSettings/Emojis";
import style from "./style.scss";

function Emojis(props: OwnProps) {
  return (
    <React.Fragment>
      <PageTitle title="Emojis" />
      <div className={style.wrap}>
        <EmojisView {...props} />
      </div>
    </React.Fragment>
  );
}

export default Emojis;
