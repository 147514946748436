// @flow
import { connect } from "react-redux";
import { isOnline } from "abstract-di/selectors";
import { BRANCH_ID_MASTER } from "core/models/branch";
import {
  BranchesFetchAllRequest,
  BranchFetchRequest,
} from "core/requests/branches";
import { FilesFetchRequest } from "core/requests/files";
import { getNestedBranches, getBranchHead } from "core/selectors/branches";
import { getFilesForBranch } from "core/selectors/files";
import type { State, Dispatch } from "core/types";
import type { OwnProps, StateProps, DispatchProps, Props } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const projectId = props.project.id;

  const branchHead = getBranchHead(state, {
    projectId,
    branchId: BRANCH_ID_MASTER,
  });

  return {
    branchHead,
    hasBranchesToDisplay:
      getNestedBranches(state, {
        projectId,
        branchFilter: "active",
      }).length > 0,
    hasFilesToDisplay:
      getFilesForBranch(state, {
        projectId,
        branchId: BRANCH_ID_MASTER,
      }).length > 0,
    isBranchesLoading: BranchesFetchAllRequest.isLoadingStrict(state, {
      projectId,
      filter: "active",
    }),
    isFilesLoading:
      !!branchHead &&
      FilesFetchRequest.isLoadingStrict(state, {
        projectId,
        branchId: BRANCH_ID_MASTER,
        sha: branchHead,
      }),
    isOffline: !isOnline(state),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const projectId = props.project.id;
  const branchId = BRANCH_ID_MASTER;

  return {
    loadBranches() {
      dispatch(
        BranchesFetchAllRequest.perform({
          params: { projectId, filter: "active" },
        })
      );
    },
    loadFiles() {
      dispatch(async (dispatch, getState) => {
        const branchHead = getBranchHead(getState(), { projectId, branchId });

        if (branchHead) {
          dispatch(
            FilesFetchRequest.perform({
              params: { projectId, branchId, sha: branchHead },
            })
          );
        }
      });
    },
    loadMaster() {
      dispatch(
        BranchFetchRequest.perform({
          params: { projectId, branchId },
        })
      );
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
