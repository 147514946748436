// @flow
import tinycolor from "tinycolor2";
import type { LayerColor } from "core/types";

export type ColorFormat =
  | "hex6-rgb"
  | "hex8-argb"
  | "rgb"
  | "uicolor-swift"
  | "uicolor-objc";

function pad2(c) {
  return c.length === 1 ? "0" + c : "" + c;
}

function convertDecimalToHex(d) {
  return Math.round(parseFloat(d) * 255).toString(16);
}

function toHex8Argb(color) {
  const { r, g, b, a } = color.toRgb();

  var hex = [
    pad2(convertDecimalToHex(a)),
    pad2(Math.round(r).toString(16)),
    pad2(Math.round(g).toString(16)),
    pad2(Math.round(b).toString(16)),
  ];

  return hex.join("");
}

function toUIColorSwift(color) {
  const { r, g, b, a } = color.toRgb();

  // prettier-ignore
  return `UIColor(red: ${(r / 255).toFixed(2)} green:${(g / 255).toFixed(2)} blue:${(b / 255).toFixed(2)} alpha:${(a / 1).toFixed(2)})`;
}

function toUIColorObjC(color) {
  const { r, g, b, a } = color.toRgb();

  // prettier-ignore
  return `[UIColor colorWithRed:${(r / 255).toFixed(2)} green:${(g / 255).toFixed(2)} blue:${(b / 255).toFixed(2)} alpha:${(a / 1).toFixed(2)}]`;
}

export default function formatColor(
  layerColor: LayerColor,
  colorFormat: ColorFormat = "hex6-rgb"
): string {
  const color = tinycolor.fromRatio({
    r: layerColor.components.red,
    g: layerColor.components.green,
    b: layerColor.components.blue,
    a: layerColor.components.alpha,
  });

  switch (colorFormat) {
    case undefined:
      return color.toHexString();
    case "hex6-rgb":
      return color.toHexString();
    case "hex8-argb":
      // https://github.com/bgrins/TinyColor/pull/196/files#diff-04c6e90faac2675aa89e2176d2eec7d8R256
      return toHex8Argb(color);
    case "rgb":
      return color.toRgbString();
    case "uicolor-swift":
      return toUIColorSwift(color);
    case "uicolor-objc":
      return toUIColorObjC(color);
    default:
      return "";
  }
}
