// @flow
import * as React from "react";
import Button from "core/components/Button";
import Icon from "core/components/Icon";
import { enterpriseScheduleDemoUrl } from "core/lib/urls";
import style from "./style.scss";
import Empty from ".";

export default function TeamsEnterprise(props: {}) {
  return (
    <Empty
      {...props}
      image={<Icon type="team" huge />}
      title="Teams is available on our Enterprise plan"
      description={
        <React.Fragment>
          The Teams feature is an Abstract feature made for large organizations.{" "}
          <br />
          If you're interested in upgrading your plan, please reach out to our
          sales department.
          <br />
        </React.Fragment>
      }
    >
      <Button
        component="a"
        href={enterpriseScheduleDemoUrl()}
        className={style.button}
        target="_blank"
        primary
        large
      >
        Contact Sales
      </Button>
    </Empty>
  );
}
