// @flow
import { connect } from "react-redux";
import createConnector from "core/lib/createConnector";
import {
  SeatAddRequest,
  SeatRemoveRequest,
  SubscriptionFetchRequest,
} from "core/requests/seats";
import { seatCapEnabled } from "core/selectors/features";
import { canSeeNextBill } from "core/selectors/subscriptions";
import type { State, Dispatch } from "core/types";
import type { OwnProps, StateProps, DispatchProps, Props } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { action, organizationId } = props;
  const SeatRequest = action === "add" ? SeatAddRequest : SeatRemoveRequest;

  return {
    addSeatError: SeatAddRequest.hasError(state, {
      organizationId,
    }),
    removeSeatError: SeatRemoveRequest.hasError(state, {
      organizationId,
    }),
    subscriptionError: SubscriptionFetchRequest.hasError(state, {
      organizationId,
    }),
    subscriptionLoading: SubscriptionFetchRequest.isFirstLoading(state, {
      organizationId,
    }),
    isRecalculatingPreview: SeatRequest.isLoadingStrict(state, {
      organizationId,
      preview: true,
    }),
    isSubmitting: SeatRequest.isLoadingStrict(state, {
      organizationId,
      preview: false,
    }),
    showNextBill: canSeeNextBill(state, organizationId),
    seatCapEnabled: seatCapEnabled(state, organizationId),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { organizationId, adjustContributors } = props;

  return {
    onAddSeats: async (quantity: number, preview: boolean) => {
      return dispatch(
        SeatAddRequest.perform({
          params: { organizationId, quantity, preview },
        })
      );
    },
    onRemoveSeats: (quantity: number, preview: boolean) => {
      return dispatch(
        SeatRemoveRequest.perform({
          params: {
            organizationId,
            quantity,
            preview,
            contributorsRemoved: adjustContributors ? quantity : 0,
          },
        })
      );
    },
  };
}

export default createConnector<Props, OwnProps>(
  connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch>(
    mapStateToProps,
    mapDispatchToProps
  )
);
