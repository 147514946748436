// @flow
import { Abstract } from "core/lib/abstract";
import { LayerFetchRequest } from "core/requests/layers";
import type { ThunkAction } from "core/types";

export function loadLayer(
  params: Abstract.LayerVersionDescriptor
): ThunkAction {
  return async function (dispatch, getState) {
    return dispatch(LayerFetchRequest.perform({ params }));
  };
}
