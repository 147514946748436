// @flow
import invariant from "invariant";
import path from "path";
import { WorkingTreeSHA } from "core/gitConstants";
import { isActive } from "core/models/branch";
import type { File, Library, Branch } from "core/types";

type IdParams = { projectId: string, sha: string, fileId: string } | File;

export const displayVersionToDocumentVersion = {
  "46": 92,
  "46.1": 93,
  "46.2": 93,
  "47": 95,
  "47.1": 95,
  "48": 97,
  "48.1": 97,
  "48.2": 97,
  "49": 101,
  "49.1": 101,
  "49.2": 101,
  "50": 102,
  "51": 105,
  "51.1": 105,
  "51.2": 105,
  "51.3": 105,
  "52": 111,
  "52.1": 112,
  "52.2": 112,
  "52.3": 112,
  "52.4": 112,
};

export const contentTypes = {
  symbol: {
    name: "Linked Symbols",
    icon: "symbol",
    id: "symbol",
  },
  component: {
    name: "Components",
    icon: "symbol",
    id: "component",
  },
  artboards: {
    name: "Artboards",
    icon: "file-artboard-xd",
    id: "artboards",
  },
  "character-style": {
    name: "Character Styles",
    icon: "file-text",
    id: "character-style",
  },
  "text-style": {
    name: "Text Styles",
    icon: "file-text",
    id: "text-style",
  },
  "layer-style": {
    name: "Layer Styles",
    icon: "layerstyle",
    id: "layer-style",
  },
  "graphic-style": {
    name: "Graphic Styles",
    icon: "file-graphic",
    id: "graphic-style",
  },
  colors: {
    name: "Colors",
    icon: "color",
    id: "colors",
  },
  colorSwatch: {
    name: "Color Swatches",
    icon: "color",
    id: "colorSwatch",
  },
  gradient: {
    name: "Gradients",
    icon: "file-gradient",
    id: "gradient",
  },
  pattern: {
    name: "Patterns",
    icon: "pattern",
    id: "pattern",
  },
  image: {
    name: "Images",
    icon: "raster",
    id: "image",
  },
  pasteboard: {
    name: "Pasteboards",
    icon: "file-pasteboard",
    id: "pasteboard",
  },
  canvas: {
    name: "Canvas",
    icon: "file-pasteboard",
    id: "canvas",
  },
  nonVisual: {
    name: "Non-previewable changes",
    icon: "sketch-nonvisible",
    id: "nonVisual",
  },
};

export function uniqueId(params: IdParams) {
  const { projectId, sha } = params;

  let id: string = "";
  if (typeof params.fileId === "string") {
    id = params.fileId;
  } else if (typeof params.id === "string") {
    id = params.id;
  }

  return `${projectId}-${sha}-${id}`;
}

const fileTypeExtMap: { [fileType: string]: string } = {
  sketch: ".sketch",
  illustrator: ".ai",
  xd: ".xd",
};

const extFileTypeMap: { [ext: string]: string } = {
  ".sketch": "sketch",
  ".ai": "illustrator",
  ".xd": "xd",
};

const fileTypeAppNameMap: { [fileType: string]: string } = {
  sketch: "Sketch",
  illustrator: "Illustrator",
  xd: "Adobe XD",
};

export function extFileType(extension: string): string {
  return extFileTypeMap[extension] || extension;
}

export function extension(fileOrType: File | Library | string): string {
  if (typeof fileOrType === "string") {
    return fileTypeExtMap[fileOrType] || fileOrType;
  }
  return fileTypeExtMap[fileOrType.type] || fileOrType.type;
}

export function nameWithExtension(
  fileOrName: File | Library | string,
  fileType: string = ""
): string {
  if (typeof fileOrName === "string") {
    return `${fileOrName}${extension(fileType)}`;
  }
  return `${fileOrName.name}${extension(fileOrName)}`;
}

export function filePathType(filePath: string): string {
  /*
    NOTE: Be careful about using this function, as we cannot guarantee that a
    given file path will have an extension
  */
  const ext = path.extname(filePath).toLowerCase();
  return extFileType(ext);
}

export function fileTypeAppName(
  fileType: ?string,
  defaultAppName: string = ""
): string {
  return fileType ? fileTypeAppNameMap[fileType] : defaultAppName;
}

export function appName(file: File, defaultName: string = ""): string {
  return fileTypeAppName(file.type, defaultName);
}

export function applicationDocumentVersion(file: File) {
  // attempt to read from file first (but we haven't always stored this)
  if (file.applicationDocumentVersion) {
    return file.applicationDocumentVersion;
  }
  if (!file.applicationVersion) {
    return 0;
  }

  // fallback to mapping display version -> document version
  const mappedDocumentVersion =
    displayVersionToDocumentVersion[file.applicationVersion];
  if (mappedDocumentVersion) {
    return mappedDocumentVersion;
  }
  return 0;
}

// the output filename should be:
// "file_name (branch_name @ short_sha).ext"
export function nameWithBranchAndSHA(
  file: File,
  branchName: string,
  sha: string = ""
): string {
  let branchInfo = branchName;
  const ext = extension(file);
  if (sha) {
    branchInfo += ` @ ${sha.substring(0, 7)}`;
  }
  return `${file.name} (${branchInfo})${ext}`;
}

export function supportsAssets(file: ?File): boolean {
  return !!file && fileTypeSupportsAssets(file.type);
}

export function fileTypeSupportsAssets(type: string): boolean {
  return type === "sketch";
}

export function supportsUpgrade(file: ?File): boolean {
  return !!file && fileTypeSupportsUpgrade(file.type);
}

export function fileTypeSupportsUpgrade(type: string): boolean {
  return type === "sketch";
}

export function editLatestLabel(fileType: ?string): string {
  const fileTypeName = fileTypeAppName(fileType);
  return fileTypeName ? `Edit Latest in ${fileTypeName}` : "Edit Latest";
}

export function fileIsWriteable(file: File, branch: Branch) {
  invariant(file, "file required for fileIsWriteable");
  invariant(branch, "branch required for fileIsWriteable");
  if (branch && branch.head === file.sha) {
    return true;
  }
  if (branch && !isActive(branch)) {
    return false;
  }
  if (file.sha === WorkingTreeSHA) {
    return true;
  }
  return false;
}
