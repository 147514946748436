// @flow
import * as React from "react";
import type { LayerChange } from "core/types";

type Props<T, I> = {
  value: T,
  change?: ?LayerChange<T>,
  children: (
    status?: $PropertyType<LayerChange<T>, "status">,
    item: I,
    index: number
  ) => React$Node,
};

export default function PropertyList<I, T: Array<I>>(props: Props<T, I>) {
  const mapper =
    (status, offset = 0) =>
    (item: I, index) =>
      props.children(status, item, index + offset);

  if (props.change && props.change.status === "changed") {
    return (
      <React.Fragment>
        {Array.isArray(props.change.previous) &&
          props.change.previous.map(mapper("removed"))}
        {Array.isArray(props.change.current) &&
          props.change.current.map(
            mapper(
              "added",
              props.change.previous ? props.change.previous.length : undefined
            )
          )}
      </React.Fragment>
    );
  } else {
    /* $FlowFixMeNowPlease This comment suppresses an error found when
     * upgrading flow-bin@0.85.0. To view the error, delete this comment and
     * run Flow. */
    return props.value.map(mapper());
  }
}
