// @flow
import { connect } from "react-redux";
import { showDialog } from "core/actions/dialogs";
import { BRANCH_ID_MASTER } from "core/models/branch";
import {
  BranchesFetchRequest,
  BranchesFetchAllRequest,
} from "core/requests/branches";
import {
  getActiveBranchesForProject,
  getNestedBranches,
} from "core/selectors/branches";
import { getDraftBranchesByParent } from "core/selectors/draftBranches";
import { getProjectPolicy } from "core/selectors/policies";
import { getFilteredBranchReviews } from "core/selectors/reviews";
import type { State, Dispatch } from "core/types";
import {
  INITIAL_BRANCH_LIMIT,
  type OwnProps,
  type StateProps,
  type DispatchProps,
  type Props,
} from ".";

// We request 3 more branches than we initially show:
// +1 to know there's more than INITIAL_BRANCH_LIMIT
// +1 in case one of the recent branches is master
// +1 to account for the current branch if it's beyond INITIAL_BRANCH_LIMIT

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { projectId } = props;
  const projectPolicy = getProjectPolicy(state, { projectId });

  return {
    branches: getNestedBranches(state, { branchFilter: "active", projectId }),
    branchReviews: getFilteredBranchReviews(state, { projectId }),
    canCreateBranch: projectPolicy.createBranch,
    draftBranches: getDraftBranchesByParent(state, { projectId }),
    isBranchesLoading: BranchesFetchRequest.isLoading(state, {
      projectId,
      filter: "active",
      limit: INITIAL_BRANCH_LIMIT + 3,
    }),
    totalBranches: getActiveBranchesForProject(state, { projectId }).length,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { projectId } = props;

  return {
    fetchAllBranches() {
      return dispatch(
        BranchesFetchAllRequest.perform({
          params: { projectId, filter: "active" },
        })
      );
    },
    fetchBranches() {
      dispatch(
        BranchesFetchRequest.perform({
          params: {
            projectId,
            filter: "active",
            limit: INITIAL_BRANCH_LIMIT + 3,
          },
          force: false,
        })
      );
    },
    showNewBranchDialog: (projectId) => {
      dispatch(
        showDialog("CreateBranch", {
          projectId,
          parentBranchId: BRANCH_ID_MASTER,
        })
      );
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
