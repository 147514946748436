// @flow
import Immutable from "seamless-immutable";
import type { Action } from "../actions";

type State = { storedLocation: ?string, error: null };
const DEFAULT_STATE = Immutable.from({ storedLocation: null });

export default function (
  state: State = DEFAULT_STATE,
  action: Action = { type: "noop" }
): State {
  switch (action.type) {
    case "LOGIN_REDIRECTED":
      return Immutable.merge(state, DEFAULT_STATE);
    case "LOGIN_REQUIRED":
      return Immutable.merge(state, {
        storedLocation: action.payload.storedLocation,
      });
    case "SIGN_IN_FAILED":
      return Immutable.merge(state, {
        error: {
          message: action.error.message,
        },
      });
    case "SIGN_IN_RESET":
      return Immutable.merge(state, { error: null });
    default:
      return state;
  }
}
