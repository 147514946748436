// @flow
import classnames from "classnames";
import { useMultipleSelection, useCombobox } from "downshift";
import * as React from "react";
import Button from "core/components/Button";
import Flex from "core/components/Flex";
import Floating from "core/components/Floating";
import Input from "core/components/Input";
import filterChannels from "core/lib/filterChannels";
import type { SlackChannel } from "core/types";
import style from "./style.scss";

export type Props = {|
  label?: string,
  availableChannels: SlackChannel[],
  selectedChannelIds: string[],
  onSelectChannel: (channel: SlackChannel) => void,
  onRemoveChannel: (channel: SlackChannel) => void,
  responsive?: boolean,
  menuChannelCount?: number,
|};

export default function AddSlackChannels(props: Props) {
  const [showChannelsAlert, setShowChannelsAlert] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");

  const {
    label,
    availableChannels,
    selectedChannelIds,
    onSelectChannel,
    onRemoveChannel,
    responsive,
    menuChannelCount,
  } = props;

  const selectedChannels = React.useMemo(
    () => availableChannels.filter((c) => selectedChannelIds.includes(c.id)),
    [availableChannels, selectedChannelIds]
  );

  const {
    getSelectedItemProps,
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
    selectedItems,
  } = useMultipleSelection({
    initialSelectedItems: selectedChannels,
  });

  const searchableChannels = React.useMemo(() => {
    return availableChannels.filter(
      (channel: SlackChannel) => !selectedChannelIds.includes(channel.id)
    );
  }, [selectedChannelIds, availableChannels]);

  const channelsToShow = menuChannelCount || 15;

  const getFilteredItems = React.useCallback(() => {
    const normalizedFilter = inputValue.replace(/^#/, "");
    return filterChannels(searchableChannels, normalizedFilter).slice(
      0,
      channelsToShow
    );
  }, [inputValue, searchableChannels, channelsToShow]);

  const handleAddChannel = React.useCallback(
    (channel: SlackChannel) => {
      if (!channel) {
        return;
      }

      if (selectedChannels.length === 5) {
        setShowChannelsAlert(true);
      } else {
        addSelectedItem(channel);
        onSelectChannel(channel);
      }
      setInputValue("");
    },
    [addSelectedItem, onSelectChannel, selectedChannels]
  );

  const handleRemoveChannel = React.useCallback(
    (event: SyntheticInputEvent<>, channel: SlackChannel) => {
      if (selectedChannels.length === 5) {
        setShowChannelsAlert(false);
      }
      event.stopPropagation();
      onRemoveChannel(channel);
      removeSelectedItem(channel);
    },
    [onRemoveChannel, removeSelectedItem, selectedChannels.length]
  );

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    openMenu,
  } = useCombobox({
    itemToString: (item) => (item ? item.name : ""),
    inputValue,
    selectedItem: null,
    items: getFilteredItems(),
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputBlur:
          if (state.highlightedIndex > -1) {
            const selectedItem = getFilteredItems()[state.highlightedIndex];
            return {
              ...changes,
              selectedItem,
            };
          }
          break;
        default:
          break;
      }
      return changes;
    },
    onStateChange: (actionAndChanges) => {
      const { inputValue, type, selectedItem } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(inputValue);
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          handleAddChannel(selectedItem);
          break;
        default:
          break;
      }
    },
  });

  const renderChannelList = React.useCallback(() => {
    const filtered = getFilteredItems();
    if (filtered.length > 0) {
      return (
        <div className={style.channelsList}>
          {filtered.map((channel, index) => (
            <div
              className={
                highlightedIndex === index
                  ? style.highlightedChannelListItem
                  : style.channelListItem
              }
              key={`${channel.name}${index}`}
              {...getItemProps({
                index,
                item: channel,
              })}
            >
              #{channel.name}
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <Flex justify="center">
          <span className={style.noMatches}>No matches for “{inputValue}”</span>
        </Flex>
      );
    }
  }, [getFilteredItems, getItemProps, highlightedIndex, inputValue]);

  return (
    <div className={style.outerDiv}>
      <div {...getComboboxProps()}>
        <Input
          label={label}
          responsive={responsive}
          placeholder="Slack channel (ex. #design)"
          value={inputValue}
          className={classnames({
            [style.inputSearchError]: showChannelsAlert,
          })}
          error={
            showChannelsAlert &&
            "You may only post to 5 slack channels at one time"
          }
          {...getInputProps(
            getDropdownProps({
              preventKeyAction: isOpen,
              onFocus: openMenu,
            })
          )}
        />
      </div>
      <Flex className={style.channelsWrapper}>
        <div
          className={classnames({
            [style.channelsWrapperLeftContainer]: responsive,
          })}
        />
        <Flex
          className={classnames({
            [style.channelsWrapperRightContainer]: responsive,
          })}
        >
          <Floating
            className={classnames(style.popoverChannelSelector, {
              [style.popoverChannelSelectorHidden]: !isOpen,
            })}
            {...getMenuProps()}
          >
            {isOpen && renderChannelList()}
          </Floating>
          {selectedItems.length ? (
            <Flex wrap className={style.selectedChannelListContainer}>
              {selectedItems.map((selectedChannel, index) => (
                <div
                  className={style.selectedChannelListItem}
                  key={`selected-item-${index}`}
                  data-qa={`selected-channel-${index}`}
                  {...getSelectedItemProps({
                    selectedItem: selectedChannel,
                    index,
                  })}
                >
                  <span className={style.selectedChannelName}>
                    #{selectedChannel.name}
                  </span>
                  <Button
                    className={style.removeButton}
                    small
                    icon="close"
                    onClick={(event) =>
                      handleRemoveChannel(event, selectedChannel)
                    }
                    nude
                    qaSelector="remove-channel-button"
                    title={`Remove channel #${selectedChannel.name}`}
                  />
                </div>
              ))}
            </Flex>
          ) : null}
        </Flex>
      </Flex>
    </div>
  );
}
