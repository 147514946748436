// @flow
import { connect } from "react-redux";
import { AddTeamProjectMembership } from "core/actions/teamProjectMemberships";
import { withData } from "core/components/DataLoader";
import createConnector from "core/lib/createConnector";
import {
  ProjectMembershipsFetchAllRequest,
  AddProjectMembershipRequest,
} from "core/requests/projectMemberships";
import { TeamProjectMembershipsFetchAllRequest } from "core/requests/teamProjectMemberships";
import {
  TeamsFetchAllRequest,
  PaginatedOrganizationTeamsRequest,
} from "core/requests/teams";
import { getOrganization } from "core/selectors/organizations";
import { getPaginationTotal } from "core/selectors/paginationTotals";
import { getProjectPolicy } from "core/selectors/policies";
import { getProjectMembershipsForProject } from "core/selectors/projectMemberships";
import { getTeamsForOrganization } from "core/selectors/teams";
import type { State, Dispatch } from "core/types";
import type { OwnProps, StateProps, DispatchProps, Props } from "./";

const INITIAL_TEAMS_LOAD = 50;

function mapStateToProps(state, props: OwnProps): StateProps {
  const organizationId = props.organizationId;
  const projectId = props.project.id;
  const projectPolicy = getProjectPolicy(state, { projectId });
  const organizationTeamsPaginationTotal = getPaginationTotal(
    state,
    `${props.organizationId}-AllTeams`
  );
  const isLoadingRemainingOrganizationTeams =
    TeamsFetchAllRequest.isLoadingStrict(state, {
      organizationId: props.organizationId,
    });

  return {
    canAddGuests: projectPolicy.addGuest,
    organization: getOrganization(state, { organizationId }),
    organizationTeams: getTeamsForOrganization(state, { organizationId }),
    projectMemberships: getProjectMembershipsForProject(state, { projectId }),
    organizationTeamsPaginationTotal,
    isLoadingRemainingOrganizationTeams,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    onLoadRemainingTeams: () => {
      return dispatch(
        TeamsFetchAllRequest.perform({
          params: { organizationId: props.organizationId },
        })
      );
    },
    onAddTeam: (projectId: string, teamId: string) => {
      return dispatch(AddTeamProjectMembership({ projectId, teamId }));
    },
    onAddMember: (projectId: string, userId: string) => {
      return dispatch(
        AddProjectMembershipRequest.perform({
          params: { projectId, userId, role: "member" },
        })
      );
    },
    onLoad: () => {
      dispatch(
        PaginatedOrganizationTeamsRequest.perform({
          params: {
            organizationId: props.organizationId,
            limit: INITIAL_TEAMS_LOAD,
            offset: 0,
          },
        })
      );
      dispatch(
        TeamProjectMembershipsFetchAllRequest.perform({
          params: { projectId: props.project.id },
          force: false,
        })
      );
      dispatch(
        ProjectMembershipsFetchAllRequest.perform({
          params: { projectId: props.project.id },
          force: false,
        })
      );
    },
  };
}

export default createConnector<Props, OwnProps>(
  connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch>(
    mapStateToProps,
    mapDispatchToProps
  ),
  withData((props) => props.params)
);
