// @flow
import classnames from "classnames";
import * as React from "react";
import NoResults from "core/components/Empty/NoResults";
import Expand from "core/components/Expand";
import Flex from "core/components/Flex";
import InputSearch from "core/components/InputSearch";
import InviteUsers from "core/components/InviteUsers";
import Loaded from "core/components/Loaded";
import OrganizationMembershipsDataLoader from "core/components/OrganizationMembershipsLoader";
import Tab from "core/components/Tab";
import Tabs from "core/components/Tabs";
import matchString from "core/lib/matchString";
import type {
  Team,
  Project,
  Organization,
  Subscription,
  ProjectMembership,
} from "core/types";
import MemberAddList from "./MemberAddList";
import connector from "./connector";
import style from "./style.scss";

const TABS = {
  add: "add",
  invite: "invite",
};

export type OwnProps = {|
  onDismissDialog: () => void,
  organizationId: string,
  project: Project,
  subscription: ?Subscription,
|};

export type StateProps = {|
  canAddGuests: boolean,
  organization: ?Organization,
  projectMemberships: ProjectMembership[],
  organizationTeams: Team[],
  organizationTeamsPaginationTotal: ?number,
  isLoadingRemainingOrganizationTeams: boolean,
|};

export type DispatchProps = {|
  onLoadRemainingTeams: () => void,
  onAddTeam: (projectId: string, teamId: string) => void,
  onAddMember: (projectId: string, userId: string) => void,
  onLoad: () => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

function AddMembersToProjectDialog(props: Props) {
  const { organizationTeams } = props;
  const [filter, setFilter] = React.useState("");
  const [selected, setSelected] = React.useState(TABS.add);
  const [displayedTeams, setDisplayedTeams] = React.useState(organizationTeams);

  const buttonCopy = props.organizationTeamsPaginationTotal
    ? `Showing ${props.organizationTeams.length} of
      ${props.organizationTeamsPaginationTotal} of your teams`
    : `Showing ${props.organizationTeams.length} of your teams`;

  const loadMoreTeamsButton = props.organizationTeamsPaginationTotal &&
    props.organizationTeamsPaginationTotal > props.organizationTeams.length && (
      <Expand
        onClick={props.onLoadRemainingTeams}
        expanded={props.isLoadingRemainingOrganizationTeams}
        loading={props.isLoadingRemainingOrganizationTeams}
        hideBorder
      >
        {filter ? "Load remaining teams" : buttonCopy}
      </Expand>
    );

  React.useEffect(() => {
    if (!filter) {
      setDisplayedTeams(organizationTeams);
    } else {
      setDisplayedTeams(
        organizationTeams.filter((team) => matchString(team.name, filter))
      );
    }
  }, [filter, organizationTeams]);

  return (
    <React.Fragment>
      <Tabs className={style.tabs}>
        <Tab
          label="Team Members"
          selected={selected === TABS.add}
          onClick={() => setSelected(TABS.add)}
        />
        {props.canAddGuests && (
          <Tab
            label="Invite Guests"
            selected={selected === TABS.invite}
            onClick={() => setSelected(TABS.invite)}
          />
        )}
      </Tabs>
      {selected === TABS.add && (
        <OrganizationMembershipsDataLoader
          organizationId={props.organizationId}
          searchFilter={filter}
        >
          {({ hasNextPage, isLoadingNextPage, items, onLoadNextPage }) => (
            <React.Fragment>
              <InputSearch
                wrapperClass={style.filterWrapper}
                placeholder="Filter organization members and teams…"
                value={filter}
                onChange={(event: SyntheticInputEvent<*>) => {
                  setFilter(event.target.value);
                }}
                autoFocus
              />
              <Flex className={style.contentWrapper} column>
                <Loaded
                  loading={
                    items.length === 0 &&
                    displayedTeams.length === 0 &&
                    isLoadingNextPage
                  }
                  title="Loading organization members…"
                  className={style.content}
                >
                  {items.length === 0 && displayedTeams.length === 0 ? (
                    <div className={classnames(style.content, style.noResults)}>
                      <NoResults type="people or teams" term={filter} flex />
                    </div>
                  ) : (
                    <div className={style.content}>
                      <MemberAddList
                        organizationId={props.organizationId}
                        filter={filter}
                        onAddTeam={props.onAddTeam}
                        onAddMember={props.onAddMember}
                        project={props.project}
                        users={items}
                        teams={displayedTeams}
                        loadMoreTeamsButton={loadMoreTeamsButton}
                        isLoadingNextPage={isLoadingNextPage}
                        onLoadNextPage={onLoadNextPage}
                      />
                    </div>
                  )}
                </Loaded>
              </Flex>
            </React.Fragment>
          )}
        </OrganizationMembershipsDataLoader>
      )}
      {props.organization && selected === TABS.invite && (
        <InviteUsers
          project={props.project}
          organization={props.organization}
          subscription={props.subscription}
          onDismissDialog={props.onDismissDialog}
        />
      )}
    </React.Fragment>
  );
}

export default connector(AddMembersToProjectDialog);
