// @flow
/* global Image */
import EventEmitter from "events";
import invariant from "invariant";
import { getAuthHeader } from "core/lib/authedDownload";
import { reportError } from "core/lib/sentry";
import type { PreviewOptions, PreviewScale } from "core/types";
import { ABSTRACT_THUMBNAILS_URL, ABSTRACT_PREVIEWS_URL } from "web/config";
import { getToken } from "web/token";

const supportsWebP = new Promise((resolve) => {
  const image = new Image();
  image.onerror = () => resolve(false);
  image.onload = () => resolve(image.width === 1);
  // 1px wide webp image
  image.src =
    "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA=";
}).catch(() => false);

// For Safari and IE 11
async function webpPolyfill(webpBlob: Blob) {
  if (!(await supportsWebP)) {
    const { default: decode } = await import("./decode");

    try {
      const pngBlob = await decode(webpBlob);
      return pngBlob;
    } catch (error) {
      return webpBlob;
    }
  }

  return webpBlob;
}

function getPreviewUrl(
  projectId: string,
  commitSha: string,
  fileId: string,
  layerId: string,
  options: PreviewOptions = {}
) {
  const src = `/projects/${projectId}/commits/${commitSha}/files/${fileId}/layers/${layerId}`;

  if (ABSTRACT_THUMBNAILS_URL && (options.thumbnail || options.webp)) {
    return `${ABSTRACT_THUMBNAILS_URL}${src}`;
  }

  return `${ABSTRACT_PREVIEWS_URL}${src}`;
}

// Event emitter is used for desktop and can be unsubscribed
// to avoid setState after a PreviewLoader has unmounted
export const events = new EventEmitter();

export async function downloadPreview(
  projectId: string,
  fileId: string,
  layerId: string,
  sha: string,
  options: PreviewOptions = {}
) {
  const scale = options.scale || 1.0;
  const url = getPreviewUrl(projectId, sha, fileId, layerId, options);
  const authHeader = getAuthHeader(getToken());
  invariant(authHeader, "Token required to download preview");

  const controller = options.controller;

  const response = await fetch(url, {
    signal: controller ? controller.signal : undefined,
    headers: {
      ...authHeader,
      Accept: options.webp ? "image/webp" : "image/png",
      "X-Image-Proportion": options.thumbnail ? scale.toString() : undefined,
    },
  });

  if (!response.ok) {
    const errorMessage = `${response.statusText} ${response.status}`;
    if (response.status >= 500) {
      reportError(new Error(errorMessage));
    }
    // fallback to ABSTRACT_PREVIEWS_URL ABSTRACT_THUMBNAILS_URL fails with server errors.
    if (
      options.enableRetry &&
      response.status >= 500 &&
      (options.thumbnail || options.webp)
    ) {
      return downloadPreview(projectId, fileId, layerId, sha, {
        ...options,
        thumbnail: false,
        webp: false,
        enableRetry: false,
      });
    }
    throw new Error(errorMessage);
  }

  const blob = await response.blob();
  const path = URL.createObjectURL(
    options.webp ? await webpPolyfill(blob) : blob
  );
  events.emit(`preview-${fileId}-${layerId}-${sha}`, {
    projectId,
    fileId,
    layerId,
    sha,
    path,
  });
}

export async function getPreviewInLocalCache(
  projectId: string,
  fileId: string,
  layerId: string,
  sha: string,
  options: { scale?: PreviewScale } = {}
): Promise<?string> {
  return undefined;
}
