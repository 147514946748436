// @flow
import * as React from "react";
import AddUsersToTeamDialog from "core/components/AddUsersToTeamDialog";
import Loaded from "core/components/Loaded";
import Media from "core/components/Media";
import Modal from "core/components/Modal";
import OrganizationTeamHeader from "core/components/OrganizationTeam/OrganizationTeamHeader";
import { createPaginationDataLoaderComponent } from "core/components/PaginationDataLoader";
import { PaginatedTeamMembershipsRequest } from "core/requests/teamMemberships";
import { getUsersForTeam } from "core/selectors/users";
import type {
  Team,
  User,
  Policy,
  OrganizationTeamDescriptor,
  ReactRouterLocation,
} from "core/types";
import NoTeamMembers from "./NoTeamMembers";
import VirtualizedTeamMemberList from "./VirtualizedTeamMemberList";
import connector from "./connector";
import style from "./style.scss";

export type StateProps = {|
  isTeamMembersFirstLoading: boolean,
  currentUser: ?User,
  team: ?Team,
  organizationName: ?string,
  teamMembers: User[],
  teamPolicy: Policy,
|};
export type OwnProps = {|
  params: OrganizationTeamDescriptor,
  location: ReactRouterLocation,
|};
export type Props = {
  ...OwnProps,
  ...StateProps,
};

type PaginationDataLoaderParams = {
  ...OrganizationTeamDescriptor,
  query?: string,
};

const OrganizationTeamMembersDataLoader = createPaginationDataLoaderComponent<
  PaginationDataLoaderParams,
  User[],
>(
  "OrganizationTeamMembers",
  PaginatedTeamMembershipsRequest,
  (response) => response.data.teamMemberships.length,
  getUsersForTeam,
  30
);

function OrganizationTeamMembers(props: Props) {
  const { params, location } = props;
  const [isGrid, setIsGrid] = React.useState(true);
  const [isDialongOpen, setIsDialogOpen] = React.useState(false);
  const { s: searchTerm } = location.query;

  const memberPaginatedParams = React.useMemo(() => {
    return {
      organizationId: params.organizationId,
      teamId: params.teamId,
      query: searchTerm || undefined,
    };
  }, [params.organizationId, params.teamId, searchTerm]);

  return (
    <Media desktop>
      {(desktop) =>
        props.team ? (
          <React.Fragment>
            <OrganizationTeamHeader
              id="OrganizationTeamMembers"
              params={params}
              onChangeViewType={(viewType) => setIsGrid(viewType === "grid")}
              title={props.team.name}
              desktop={desktop}
              policy={props.teamPolicy}
              pathname={props.location.pathname}
              onDialogOpen={() => setIsDialogOpen(true)}
            />
            <OrganizationTeamMembersDataLoader params={memberPaginatedParams}>
              {({
                items,
                hasNextPage,
                isLoadingNextPage,
                onLoadNextPage,
              }: {
                items: User[],
                hasNextPage: boolean,
                isLoadingNextPage: boolean,
                onLoadNextPage?: () => void,
              }) => (
                <Loaded
                  title="Loading Team Members"
                  flex
                  loading={props.isTeamMembersFirstLoading}
                >
                  {() => {
                    return items.length === 0 ? (
                      <NoTeamMembers
                        onDialogOpen={() => setIsDialogOpen(true)}
                        searchTerm={searchTerm}
                        teamPolicy={props.teamPolicy}
                      />
                    ) : (
                      <VirtualizedTeamMemberList
                        params={params}
                        isGrid={isGrid}
                        users={items}
                        hasNextPage={hasNextPage}
                        isLoadingNextPage={isLoadingNextPage}
                        onLoadNextPage={onLoadNextPage}
                        isMobile={!desktop}
                        organizationName={props.organizationName}
                        currentUser={props.currentUser}
                      />
                    );
                  }}
                </Loaded>
              )}
            </OrganizationTeamMembersDataLoader>

            {props.team && props.teamPolicy && props.teamPolicy.addMember ? (
              <Modal
                isOpen={isDialongOpen}
                onClose={() => setIsDialogOpen(false)}
                className={style.dialog}
                title={
                  props.team ? `Add members to ${props.team.name}` : undefined
                }
              >
                <AddUsersToTeamDialog
                  isOpen={isDialongOpen}
                  team={props.team}
                  organizationId={props.params.organizationId}
                  onDismissDialog={() => setIsDialogOpen(false)}
                />
              </Modal>
            ) : null}
          </React.Fragment>
        ) : null
      }
    </Media>
  );
}
export default connector(OrganizationTeamMembers);
