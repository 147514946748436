// @flow
import queryString from "query-string";
import { entitiesReceived } from "core/actions/entities";
import abstract, { Abstract } from "core/lib/abstract";
import defineRequest from "core/requests";
import { normalizeLayerResponse, normalizeLayers } from "core/schemas/layer";
import { getFile } from "core/selectors/files";
import { getLayer } from "core/selectors/layers";
import { getPage } from "core/selectors/pages";
import { getTransportModeForBranch } from "core/selectors/sdk";

type LayersFetchDescriptor = Abstract.FileDescriptor | Abstract.PageDescriptor;
type FromLibraries = "include" | "exclude" | "only";

type LayersFetchParams = {|
  ...LayersFetchDescriptor,
  limit?: number,
  offset?: number,
  fromLibraries?: FromLibraries,
  transportMode?: "api" | "cli",
|};

export const LayersFetchRequest = defineRequest<
  LayersFetchParams,
  LayersFetchParams,
>({
  id(params) {
    const paramsString = queryString.stringify(params);
    return `layers:${paramsString}`;
  },
  perform(params, dispatch, getState) {
    const { limit, offset, fromLibraries, transportMode, ...descriptor } =
      params;
    const state = getState();

    return abstract.layers.list(descriptor, {
      limit,
      offset,
      fromLibraries,
      transportMode: transportMode
        ? [transportMode]
        : getTransportModeForBranch(state, {
            projectId: descriptor.projectId,
            branchId: descriptor.branchId,
          }),
    });
  },
  onSuccess(response, params, dispatch) {
    dispatch(entitiesReceived(normalizeLayers(response).entities));
  },
  force: false,
});

function recursiveOnSuccess(response, params, dispatch) {
  if (response.length === params.limit) {
    const newParams = {
      ...params,
      offset: params.offset + params.limit,
    };

    return dispatch(
      LayersFetchRequest.perform({
        params: newParams,
        onSuccess: (response) =>
          recursiveOnSuccess(response, newParams, dispatch),
      })
    );
  }
}

type LayersFetchAllParams = {|
  ...LayersFetchDescriptor,
  fromLibraries?: FromLibraries,
|};

export const LayersFetchAllRequest = defineRequest<
  LayersFetchAllParams,
  LayersFetchAllParams,
>({
  id(params) {
    const paramsString = queryString.stringify(params);
    return `layers-all:${paramsString}`;
  },
  perform(params, dispatch) {
    const paginatedParams = { ...params, limit: 30, offset: 0 };

    return dispatch(
      LayersFetchRequest.perform({
        params: { ...params, limit: 30, offset: 0 },
        onSuccess: (response) =>
          recursiveOnSuccess(response, paginatedParams, dispatch),
      })
    );
  },
  force: false,
});

export const LayerFetchRequest = defineRequest<
  Abstract.LayerVersionDescriptor,
  Abstract.LayerVersionDescriptor,
>({
  id(params) {
    return `get:projects/${params.projectId}/branches/${params.branchId}/commits/${params.sha}/files/${params.fileId}/layers/${params.layerId}`;
  },
  async perform(params, dispatch, getState) {
    const { projectId, branchId, sha, fileId, layerId } = params;
    const state = getState();
    let layer = getLayer(state, { projectId, branchId, sha, fileId, layerId });
    let page;

    if (layer) {
      page = getPage(state, {
        projectId,
        sha,
        fileId,
        pageId: layer.pageId,
      });
    }
    const file = getFile(state, { projectId, sha, fileId });

    if (file && page && layer) {
      return;
    }

    layer = await abstract.layers.info(params, {
      transportMode: getTransportModeForBranch(getState(), {
        projectId,
        branchId,
      }),
    });

    return abstract.unwrap(layer);
  },
  onSuccess(response, params, dispatch) {
    if (response) {
      return dispatch(
        entitiesReceived(normalizeLayerResponse(response).entities)
      );
    }
  },
  force: false,
});
