// @flow
import classnames from "classnames";
import * as React from "react";
import style from "./style.scss";

type ButtonProps = { className: string };

type Props = {
  children: React.Node | ((props: ButtonProps) => React.Node),
  className?: string,
};

export default function ButtonGroup({ children, ...props }: Props) {
  const content =
    typeof children === "function"
      ? children({ className: style.grouped })
      : React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              className: classnames(style.grouped, child.props.className),
            });
          }
          return child;
        });

  return (
    <div className={classnames(style.group, props.className)}>{content}</div>
  );
}
