// @flow
import classnames from "classnames";
import * as React from "react";
import style from "./style.scss";

type Props = {|
  children: React.Node,
  className?: string,
  orientation?: "horizontal" | "vertical",
  variant?: "blurple" | "kelp",
|};

export default function ContentSheet(props: Props) {
  const {
    children,
    className,
    orientation = "vertical",
    variant = "blurple",
  } = props;

  return (
    <div
      className={classnames(className, style.sheet, {
        [style[orientation]]: orientation,
        [style[variant]]: variant,
      })}
    >
      {children}
    </div>
  );
}
