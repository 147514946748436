// @flow
import { find } from "lodash";
import * as React from "react";
import OpenFileButton from "abstract-di/components/OpenFileButton";
import Breakpoint from "core/components/Breakpoint";
import CoreChangeset from "core/components/Changeset";
import withMultiSelect from "core/components/CollectionMultiSelect/withMultiSelect";
import { layerLocation } from "core/lib/routes";
import type {
  Page,
  Layer,
  File,
  FilePreviews,
  Changeset as TChangeset,
  ChangesetStatus,
} from "core/types";
import { getZoom, setZoom } from "web/layerZoom";
import style from "./style.scss";

const SelectableChangeset = withMultiSelect(CoreChangeset);

type Props = {
  projectId: string,
  branchId: string,
  changeset: TChangeset,
  files: File[],
  previewsByFile: FilePreviews,
  layerStatuses: { [layerId: string]: ChangesetStatus },
  layerCommentCounts: { [string]: number },
  pages: { [pageId: string]: Page },
  filter?: string,
  header: React.Node,
  footer: React.Node,
};

type State = { zoom: number };

// used to control size of commit header
const mobileBreakpoint = 450;

export default class Changeset extends React.Component<Props, State> {
  state = { zoom: getZoom() };

  handleZoomChange = (event: SyntheticEvent<>, zoom: number) => {
    setZoom(zoom);
    this.setState({ zoom });
  };

  getlayerLocation = (layer: Layer) => {
    const { projectId, branchId } = this.props;
    const { sha } = this.props.changeset;
    return layerLocation(projectId, branchId, sha, layer.fileId, layer.id, {
      layerSetParams: { type: "commit", projectId, sha },
    });
  };

  renderFileActions = (fileId: string, isSelecting?: boolean) => {
    const { projectId, branchId, files, changeset } = this.props;
    const file = find(files, { id: fileId, sha: changeset.sha });
    if (!file) {
      return null;
    }

    return (
      <OpenFileButton
        branchId={branchId}
        className={style.action}
        disabled={isSelecting}
        file={file}
        fileId={fileId}
        projectId={projectId}
        sha={changeset.sha}
      />
    );
  };

  render() {
    return (
      <Breakpoint at={mobileBreakpoint}>
        {({ above }) => (
          <SelectableChangeset
            {...this.props}
            mobile={!above}
            contextBranchId={this.props.branchId}
            getLayerPath={this.getlayerLocation}
            onZoomChange={this.handleZoomChange}
            zoom={this.state.zoom}
            fileActions={this.renderFileActions}
          />
        )}
      </Breakpoint>
    );
  }
}
