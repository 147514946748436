// @flow
import * as React from "react";
import CommitsList from "core/components/CommitsList";
import NoCommits from "core/components/Empty/NoCommits";
import Head from "core/components/Head";
import Loaded from "core/components/Loaded";
import Sidebar from "core/components/Sidebar";
import { Abstract } from "core/lib/abstract";
import { replace } from "core/lib/location";
import { commitPath } from "core/lib/routes";
import type { Commit, Branch, Project } from "core/types";
import BranchPage from "../BranchShell/BranchPage";
import style from "./desktop.scss";

type Props = {
  isLoading: boolean,
  commits: Commit[],
  commitCommentCounts: { [sha: string]: number },
  commitPath: (sha: string) => string,
  children: React.Node,
  params: Abstract.BranchDescriptor | Abstract.BranchCommitDescriptor,
  branch: ?Branch,
  parentBranch: ?Branch,
  project: ?Project,
  onLoadMore?: () => void,
  project: ?Project,
  isOffline: boolean,
};

export default class BranchCommitsDesktop extends React.Component<Props> {
  componentDidMount() {
    this.checkForCommit(this.props);
  }

  componentDidUpdate() {
    this.checkForCommit(this.props);
  }

  checkForCommit = (props: Props) => {
    if (!props.params.sha && props.commits.length && !props.isLoading) {
      replace(
        commitPath(
          props.params.projectId,
          props.params.branchId,
          props.commits[0].sha
        )
      );
    }
  };

  render() {
    const isEmpty = !this.props.commits.length;

    return (
      <BranchPage>
        <Head>
          <title>Commits</title>
        </Head>
        {!this.props.isLoading && isEmpty ? (
          <NoCommits />
        ) : (
          <React.Fragment>
            <Sidebar resizable="branch-tab-list" className={style.sidebar}>
              <CommitsList params={this.props.params} />
            </Sidebar>
            {this.props.children ? (
              this.props.children
            ) : (
              <Loaded title="Loading commit…" loading />
            )}
          </React.Fragment>
        )}
      </BranchPage>
    );
  }
}
