// @flow
import { get } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import Button from "core/components/Button";
import ExternalLink from "core/components/ExternalLink";
import FileIcon from "core/components/FileIcon";
import Flex from "core/components/Flex";
import Icon from "core/components/Icon";
import InputCheckbox from "core/components/InputCheckbox";
import Time from "core/components/Time";
import { fromNow } from "core/lib/dates";
import { displayName } from "core/models/branch";
import { getBranch } from "core/selectors/branches";
import { getCollection } from "core/selectors/collections";
import { canUseNewDefaultBranchName } from "core/selectors/features";
import { getLayer } from "core/selectors/layers";
import type { ShareLink, State } from "core/types";
import PublicShareLinksMenu from "web/components/PublicShareLinksMenu";
import { getProject } from "web/selectors/projects";
import style from "./style.scss";

type OwnProps = {|
  selected: boolean,
  shareLink: ShareLink,
  organizationId: string,
  onChange: (e: SyntheticInputEvent<>) => void,
|};

type StateProps = {|
  name: string,
  projectName: string,
  branchName: string,
|};

type Props = { ...OwnProps, ...StateProps };

function shareLinkIcon(kind: string) {
  switch (kind) {
    case "collection":
      return <Icon type="collection" />;
    default:
      return <FileIcon type="artboard" />;
  }
}

function ShareLinkItem({
  shareLink,
  selected,
  onChange,
  organizationId,
  projectName,
  branchName,
  name,
}: Props) {
  const createdAt = shareLink.createdAt || "";
  return (
    <Flex align="center" justify="space-between" className={style.item}>
      <Flex align="center">
        <InputCheckbox
          label={shareLinkIcon(shareLink.kind)}
          name={shareLink.url}
          checked={selected}
          onChange={onChange}
          checkboxClass={style.checkbox}
          wrapperClass={style.checkboxWrapper}
          labelClass={style.checkboxLabel}
        />
        <div className={style.itemDetails}>
          <ExternalLink href={shareLink.url} className={style.itemLink}>
            {name ? name : <em>Unknown {shareLink.kind}</em>}
          </ExternalLink>
          <span>
            {` · ${projectName}`}
            {!!shareLink.descriptor.branchId && ` / ${branchName}`}
          </span>
          {createdAt && (
            <div>
              Shared <Time date={createdAt}>{fromNow(createdAt)}</Time>
            </div>
          )}
        </div>
      </Flex>
      <PublicShareLinksMenu
        shareLink={shareLink}
        organizationId={organizationId}
      >
        {(showMenu, ref) => (
          <Button icon="overflow" onClick={showMenu} nude innerRef={ref} />
        )}
      </PublicShareLinksMenu>
    </Flex>
  );
}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { shareLink } = props;
  const project = getProject(state, shareLink.descriptor.projectId);

  switch (shareLink.kind) {
    case "collection": {
      const collection = getCollection(state, { ...shareLink.descriptor });
      const branch = getBranch(state, { ...shareLink.descriptor });

      return {
        name: get(collection, "name", ""),
        projectName: get(project, "name", "Project name"),
        branchName: displayName(branch, {
          masterToMain: canUseNewDefaultBranchName(state),
          titleCase: true,
          defaultValue: "Branch name",
        }),
      };
    }
    case "layer": {
      const layer = getLayer(state, { ...shareLink.descriptor });
      const branch = getBranch(state, { ...shareLink.descriptor });

      return {
        name: get(layer, "name", ""),
        projectName: get(project, "name", "Project name"),
        branchName: displayName(branch, {
          masterToMain: canUseNewDefaultBranchName(state),
          titleCase: true,
          defaultValue: "Branch name",
        }),
      };
    }
    default: {
      return {
        name: "",
        projectName: "",
        branchName: "",
      };
    }
  }
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default connect(mapStateToProps)(ShareLinkItem);
