// @flow
import { getCurrentLocation, push, replace } from "core/lib/location";
import type { RequestError } from "core/types";
import {
  SessionCreateRequest,
  SessionCreateWithoutRedirectRequest,
} from "web/requests/sessions";
import { signin, organizationPath, completeProfilePath } from "../routeHelpers";
import { setToken } from "../token";
import { loadSession } from "./session";

import type { Action, ThunkAction } from ".";

function loginRedirected(): Action {
  return { type: "LOGIN_REDIRECTED" };
}

/**
 * Require login before the page can be rendered.
 *
 * This saves the current URL being requested so the app can redirect back to it
 * after successful login.
 * If the user actively signed out immediately before loginRequired is called,
 * don't store the current location.
 */
export function loginRequired(): ThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    const { userHasSignedOut } = state.session;

    const currentLocation = userHasSignedOut ? undefined : getCurrentLocation();

    replace(signin(currentLocation));
    dispatch({
      type: "LOGIN_REQUIRED",
      payload: { storedLocation: currentLocation },
    });
  };
}

export function signIn(
  username: string,
  password: string,
  onSuccess?: (response: { [key: string]: string }) => void
): ThunkAction {
  return async (dispatch) => {
    dispatch(
      SessionCreateRequest.perform({
        params: { username, password },
        onSuccess,
      })
    );
  };
}

export function signInWithoutRedirecting(
  username: string,
  password: string,
  onSuccess?: () => void
): ThunkAction {
  return async (dispatch) => {
    dispatch(
      SessionCreateWithoutRedirectRequest.perform({
        params: { username, password },
        onSuccess,
      })
    );
  };
}

export function authenticateToken(
  accessToken: string,
  organizationId: string,
  url: string,
  completeProfile?: boolean,
  clientType?: string
): ThunkAction {
  const destinationUrl = url || organizationPath(organizationId);
  const completeProfileUrl = completeProfilePath(organizationId, clientType, {
    pathname: destinationUrl,
  });

  return async (dispatch) => {
    setToken(accessToken);
    dispatch(loadSession());
    dispatch(loginRedirected());

    setTimeout(() => {
      // Calling push before first render is possible. Use setTimeout
      // to schedule and avoid calling push before history is listening
      push(completeProfile ? completeProfileUrl : destinationUrl);
    }, 0);
  };
}

export function resetLogin(): Action {
  return { type: "SIGN_IN_RESET", error: null };
}

export function signInFailed(error: Error | RequestError): Action {
  return { type: "SIGN_IN_FAILED", error };
}
