// @flow
import createCachedSelector from "@elasticprojects/re-reselect";
import empty from "empty";
import { filter, find, reduce, sortBy, uniqBy, values, forEach } from "lodash";
import { Abstract } from "core/lib/abstract";
import matchString from "core/lib/matchString";
import * as Change from "core/models/change";
import * as File from "core/models/file";
import * as Layer from "core/models/layer";
import * as Page from "core/models/page";
import * as Request from "core/models/request";
import { LayerFetchRequest } from "core/requests/layers";
import { getBranchHead } from "core/selectors/branches";
import {
  getChangeset,
  getChangesetLayers,
  getLayerSetForChangeset,
  getNonVisualChangesForChangeset,
} from "core/selectors/changesets";
import { getLayerSetForCollection } from "core/selectors/collections";
import { getLatestCommitShaForLayer } from "core/selectors/commits";
import { getEntity, getRawEntities } from "core/selectors/entities";
import { getFilesAtSha } from "core/selectors/files";
import {
  getFileId,
  getProjectId,
  maybeGetPageId,
} from "core/selectors/helpers";
import {
  getPagesForFileOnBranch,
  getPagesByFileAtSha,
  getChangedPagesForFileOnBranch,
  getLibraryPagesForFileOnBranch,
} from "core/selectors/pages";
import type {
  ChangesetStatus,
  ChangeStatusCounts,
  FilePreviews,
  Layer as TLayer,
  LayerSetItem,
  LayerSetParams,
  LayerState,
  NonVisualChange,
  Page as TPage,
  State,
} from "core/types";

type LayerFilters = {|
  projectId: string,
  branchId: string,
  sha?: string,
  head?: string,
  fileId: string,
  pageId?: string,
  search?: string,
  status?: string,
  excludeDeleted?: boolean,
|};

function getSearchFilter(state: State, params: LayerFilters) {
  return params.search;
}

export function getLayer(
  state: State,
  params: Abstract.LayerVersionDescriptor
): ?TLayer {
  if (params.sha === "latest") {
    return getLatestLayer(state, {
      projectId: params.projectId,
      fileId: params.fileId,
      layerId: params.layerId,
      branchId: params.branchId,
    });
  }
  return getEntity(state, "layers", Layer.uniqueId(params));
}

export function getLatestLayer(
  state: State,
  params: Abstract.LayerDescriptor
): ?TLayer {
  const latestSha = getLatestCommitShaForLayer(state, params);
  if (latestSha) {
    return getLayer(state, {
      ...params,
      sha: latestSha,
    });
  }
}

export function getLayerState(
  state: State,
  params: Abstract.LayerVersionDescriptor
): LayerState {
  const layer = getLayer(state, params);
  if (layer) {
    return "loaded";
  }

  const layerRequest = LayerFetchRequest.getRequest(state, params);
  if (Request.isLoading(layerRequest) || Request.success(layerRequest)) {
    return "loading";
  }
  if (Request.notFound(layerRequest)) {
    return "not_found";
  }
  if (Request.forbidden(layerRequest)) {
    return "forbidden";
  }

  return "error";
}

export const getAllLayers: (State) => { [string]: TLayer } = (state) =>
  getRawEntities(state, "layers");

const layersByFileOnBranchCache = (state: State, params: LayerFilters) =>
  `${params.projectId}-${params.branchId}-${params.sha || params.head || ""}-${
    params.fileId
  }-${params.pageId || ""}`;

const layersByFileAndStatusOnBranchCache = (
  state: State,
  params: LayerFilters
) =>
  `${params.projectId}-${params.branchId}-${params.sha || params.head || ""}-${
    params.fileId
  }-${params.pageId || ""}-${params.status || ""}`;

const cacheByProjectAndSha = (state, params) =>
  `${params.projectId}-${params.sha}`;

function sortLayersByPages(
  layers: TLayer[],
  pages: TPage[],
  pageId: string = ""
) {
  let layerPages = pages;

  if (pageId) {
    const page = find(pages, { id: pageId });
    layerPages = page ? [page] : empty.array;
  }

  // uniqBy ensures that when filtering pages from a changeset we only
  // consider one of sha, or compareToSha as both page versions may be present
  return uniqBy(layerPages, "id").reduce(
    (memo, page) =>
      memo.concat(
        sortBy(
          filter(layers, {
            projectId: page.projectId,
            fileId: page.fileId,
            pageId: page.id,
          }),
          "order"
        )
      ),
    []
  );
}

export const getLayersAtSha: (
  State,
  { projectId: string, sha: string }
) => TLayer[] = createCachedSelector(
  getAllLayers,
  getPagesByFileAtSha,
  getFilesAtSha,
  (layers, pages, files) =>
    files.reduce((memo, file) => {
      const { projectId, sha, id: fileId } = file;
      return memo.concat(
        sortLayersByPages(
          filter(layers, { projectId, sha, fileId }),
          pages[File.uniqueId(file)] || empty.array
        )
      );
    }, [])
)(cacheByProjectAndSha);

export const getLayersForFileOnBranch: (State, LayerFilters) => TLayer[] =
  createCachedSelector(
    getBranchHead,
    getProjectId,
    getPagesForFileOnBranch,
    getAllLayers,
    getFileId,
    maybeGetPageId,
    getSearchFilter,
    (head, projectId, pages, layers, fileId, pageId, search) => {
      if (!head) {
        return empty.array;
      }

      return sortLayersByPages(
        filter(
          layers,
          (layer) =>
            layer.projectId === projectId &&
            layer.fileId === fileId &&
            layer.sha === head &&
            matchString(layer.name, search)
        ),
        values(pages),
        pageId
      );
    }
  )(layersByFileOnBranchCache);

function getChangesetLayersForBranch(
  state: State,
  { projectId, branchId }: { projectId: string, branchId: string }
) {
  return getChangesetLayers(state, { projectId, branchId });
}

export const getChangedLayersForFileOnBranch: (
  State,
  LayerFilters
) => TLayer[] = createCachedSelector(
  getLibraryPagesForFileOnBranch,
  getChangedPagesForFileOnBranch,
  getChangesetLayersForBranch,
  getFileId,
  maybeGetPageId,
  getSearchFilter,
  (libraryPages, pages, layers, fileId, pageId, search) => {
    const filteredLayers = filter(layers, (layer) => {
      if (layer.fileId !== fileId) {
        return false;
      }
      if (!matchString(layer.name, search)) {
        return false;
      }
      if (pageId) {
        return true;
      }
      return !libraryPages[Page.uniqueId(layer)];
    });

    return sortLayersByPages(filteredLayers, pages, pageId);
  }
)(layersByFileOnBranchCache);

function getChangesetForBranch(
  state: State,
  { projectId, branchId }: { projectId: string, branchId: string }
) {
  return getChangeset(state, { projectId, branchId });
}

export const getLayerStatusesForFileOnBranch: (
  State,
  LayerFilters
) => {
  [layerId: string]: ChangesetStatus,
} = createCachedSelector(
  getChangesetForBranch,
  getFileId,
  maybeGetPageId,
  (changeset, fileId, pageId) => {
    if (!changeset) {
      return {};
    }
    const { projectId } = changeset;

    return changeset.changes.reduce((memo, change) => {
      if (
        change.objectType !== "layer" ||
        fileId !== change.fileId ||
        (pageId && pageId !== change.pageId) ||
        !change.objectId
      ) {
        return memo;
      }

      const key = Layer.uniqueId({
        projectId,
        sha: Change.isRemoved(change) ? changeset.compareToSha : changeset.sha,
        fileId: change.fileId,
        layerId: change.objectId || "",
      });
      memo[key] = change.status;
      return memo;
    }, {});
  }
)(layersByFileOnBranchCache);

const getChangedLayersWithoutDeletedForFileOnBranch: (
  State,
  LayerFilters
) => TLayer[] = createCachedSelector(
  getChangedLayersForFileOnBranch,
  getLayerStatusesForFileOnBranch,
  (layers, statuses) => {
    return filter(
      layers,
      (layer) => !Change.isRemoved(statuses[Layer.uniqueId(layer)])
    );
  }
)(layersByFileOnBranchCache);

const getAddedLayersForFileOnBranch: (State, LayerFilters) => TLayer[] =
  createCachedSelector(
    getChangedLayersForFileOnBranch,
    getLayerStatusesForFileOnBranch,
    (layers, statuses) => {
      return filter(layers, (layer) =>
        Change.isAdded(statuses[Layer.uniqueId(layer)])
      );
    }
  )(layersByFileOnBranchCache);

const getEditedLayersForFileOnBranch: (State, LayerFilters) => TLayer[] =
  createCachedSelector(
    getChangedLayersForFileOnBranch,
    getLayerStatusesForFileOnBranch,
    getLibraryPagesForFileOnBranch,
    (layers, statuses, libraries) => {
      return filter(layers, (layer) => {
        const edited = Change.isEdited(statuses[Layer.uniqueId(layer)]);
        return edited && !libraries[Page.uniqueId(layer)];
      });
    }
  )(layersByFileOnBranchCache);

const getDeletedLayersForFileOnBranch: (State, LayerFilters) => TLayer[] =
  createCachedSelector(
    getChangedLayersForFileOnBranch,
    getLayerStatusesForFileOnBranch,
    (layers, statuses) => {
      return filter(layers, (layer) =>
        Change.isRemoved(statuses[Layer.uniqueId(layer)])
      );
    }
  )(layersByFileOnBranchCache);

const getUpdatedLayersForFileOnBranch: (State, LayerFilters) => TLayer[] =
  createCachedSelector(
    getChangedLayersForFileOnBranch,
    getLayerStatusesForFileOnBranch,
    getLibraryPagesForFileOnBranch,
    (layers, statuses, libraries) => {
      return filter(layers, (layer) => {
        const status = statuses[Layer.uniqueId(layer)];
        if (Change.isUpdated(status)) {
          return true;
        }
        if (Change.isEdited(status) && libraries[Page.uniqueId(layer)]) {
          return true;
        }
        return false;
      });
    }
  )(layersByFileOnBranchCache);

export function getChangedLayersByStatusForFileOnBranch(
  state: State,
  params: LayerFilters
): TLayer[] {
  const filters = {
    projectId: params.projectId,
    branchId: params.branchId,
    sha: params.sha || params.head,
    fileId: params.fileId,
    pageId: params.pageId,
    search: params.search,
  };

  switch (params.status) {
    case "added":
      return getAddedLayersForFileOnBranch(state, filters);
    case "edited":
      return getEditedLayersForFileOnBranch(state, filters);
    case "removed":
      /*
        It's not possible to navigate to deleted layers, so we don't need to
        generate a layer set for that scenario.
      */
      return params.excludeDeleted
        ? empty.array
        : getDeletedLayersForFileOnBranch(state, filters);
    case "updated":
      return getUpdatedLayersForFileOnBranch(state, filters);
    default:
      return params.excludeDeleted
        ? getChangedLayersWithoutDeletedForFileOnBranch(state, filters)
        : getChangedLayersForFileOnBranch(state, filters);
  }
}

export function getPreviewableLayersForFileOnBranch(
  state: State,
  params: LayerFilters
): TLayer[] {
  if (params.status) {
    return getChangedLayersByStatusForFileOnBranch(state, params);
  }

  return getLayersForFileOnBranch(state, {
    projectId: params.projectId,
    branchId: params.branchId,
    sha: params.sha || params.head,
    fileId: params.fileId,
    pageId: params.pageId,
    search: params.search,
  });
}

export const getNonVisualChangesForFileOnBranch: (
  state: State,
  params: LayerFilters
) => { [type: string]: NonVisualChange[] } = createCachedSelector(
  getNonVisualChangesForChangeset,
  getFileId,
  maybeGetPageId,
  (nonVisuals, fileId, pageId) => {
    return reduce(
      nonVisuals,
      (memo, item) => {
        if (item.change.fileId !== fileId) {
          return memo;
        }
        if (pageId && item.change.pageId !== pageId) {
          return memo;
        }
        memo[item.type] = (memo[item.type] || []).concat(item);
        return memo;
      },
      {}
    );
  }
)(layersByFileAndStatusOnBranchCache);

const getStatus = (state, props) => props.status;

export const getPreviewsForFileOnBranch: (
  state: State,
  params: LayerFilters
) => FilePreviews = createCachedSelector(
  getPagesForFileOnBranch,
  getPreviewableLayersForFileOnBranch,
  getStatus,
  getFileId,
  maybeGetPageId,
  getChangesetForBranch,
  getNonVisualChangesForFileOnBranch,
  (pages, layers, status, fileId, pageId, changeset, nonVisuals) => {
    const previews = reduce(
      layers,
      (memo, layer) => {
        const uniquePageId = Page.uniqueId({
          projectId: layer.projectId,
          fileId: layer.fileId,
          pageId: layer.pageId,
          sha: changeset ? changeset.sha : layer.sha,
        });
        const page = pages[uniquePageId];
        if (!page) {
          return memo;
        }
        if (!Page.isLibrary(page)) {
          memo[fileId][uniquePageId] = memo[fileId][uniquePageId] || [];
          memo[fileId][uniquePageId].push(layer);
        } else if (
          layer.type === "symbol" &&
          pageId &&
          layer.pageId === pageId
        ) {
          memo[fileId].symbol = memo[fileId].symbol || [];
          memo[fileId].symbol.push(layer);
        }
        return memo;
      },
      { [fileId]: {} }
    );

    if (status) {
      forEach(nonVisuals, (items, type) => (previews[fileId][type] = items));
    }

    return previews;
  }
)(layersByFileAndStatusOnBranchCache);

export const getDependencyPreviewsForFileOnBranch: (
  State,
  LayerFilters
) => FilePreviews = createCachedSelector(
  getLibraryPagesForFileOnBranch,
  getPreviewableLayersForFileOnBranch,
  getChangesetForBranch,
  (libraryPages, layers, changeset) => {
    const previews = reduce(
      layers,
      (memo, layer) => {
        const uniquePageId = Page.uniqueId({
          projectId: layer.projectId,
          fileId: layer.fileId,
          pageId: layer.pageId,
          sha: changeset ? changeset.sha : layer.sha,
        });
        const page = libraryPages[uniquePageId];
        if (!page) {
          return memo;
        }
        if (layer.type === "symbol") {
          if (!memo[uniquePageId]) {
            memo[uniquePageId] = { symbol: [] };
          }
          if (!memo[uniquePageId].symbol) {
            memo[uniquePageId] = { ...memo[uniquePageId], symbol: [] };
          }
          memo[uniquePageId].symbol.push(layer);
        }
        return memo;
      },
      {}
    );

    forEach(libraryPages, (page) => {
      if (!previews[Page.uniqueId(page)]) {
        previews[Page.uniqueId(page)] = { symbol: [] };
      }
    });

    return previews;
  }
)(layersByFileOnBranchCache);

export const getChangeStatusCountsForFileOnBranch: (
  State,
  LayerFilters
) => ChangeStatusCounts = createCachedSelector(
  getAddedLayersForFileOnBranch,
  getEditedLayersForFileOnBranch,
  getDeletedLayersForFileOnBranch,
  getUpdatedLayersForFileOnBranch,
  getNonVisualChangesForFileOnBranch,
  (added, edited, deleted, updated, nonVisuals) => {
    return reduce(
      nonVisuals,
      (memo, items, type) => {
        items.forEach((item) => {
          const status = Change.status(item.change);
          memo[status]++;
        });
        return memo;
      },
      {
        added: added.length,
        edited: edited.length,
        removed: deleted.length,
        updated: updated.length,
      }
    );
  }
)(layersByFileOnBranchCache);

function generateLayerSetFromLayers(layers: TLayer[]) {
  return layers.map((layer) => ({
    fileId: layer.fileId,
    pageId: layer.pageId,
    commitSha: layer.lastChangedAtSha,
    layerId: layer.id,
  }));
}

function generateLayerSetFromPreviews(previews: FilePreviews) {
  return reduce(
    previews,
    (memo, previewsByPage, fileId) => {
      forEach(previewsByPage, (layers, pageId) => {
        if (!File.contentTypes[pageId]) {
          memo.push(...generateLayerSetFromLayers(layers));
        }
      });

      if (previewsByPage.symbol) {
        memo.push(...generateLayerSetFromLayers(previewsByPage.symbol));
      }

      return memo;
    },
    []
  );
}

export const getLayerSetForFileOnBranch: (
  state: State,
  params: LayerFilters
) => LayerSetItem[] = createCachedSelector(
  getPreviewsForFileOnBranch,
  generateLayerSetFromPreviews
)(layersByFileAndStatusOnBranchCache);

const EMPTY_LAYER_SET: LayerSetItem[] = [];

export function getLayerSet(state: State, layerSetParams?: LayerSetParams) {
  if (!layerSetParams) {
    return EMPTY_LAYER_SET;
  }

  if (layerSetParams.type === "commit") {
    return getLayerSetForChangeset(state, {
      projectId: layerSetParams.projectId,
      sha: layerSetParams.sha,
    });
  }

  if (layerSetParams.type === "collection") {
    return getLayerSetForCollection(state, {
      collectionId: layerSetParams.collectionId,
    });
  }

  if (layerSetParams.type === "file") {
    let sha = layerSetParams.sha;
    if (sha === "latest") {
      const head = getBranchHead(state, {
        projectId: layerSetParams.projectId,
        branchId: layerSetParams.branchId,
      });
      if (head) {
        sha = head;
      }
    }

    return getLayerSetForFileOnBranch(state, {
      projectId: layerSetParams.projectId,
      branchId: layerSetParams.branchId,
      sha: sha,
      fileId: layerSetParams.fileId,
      pageId: layerSetParams.pageId,
      search: layerSetParams.search,
      status: layerSetParams.filter ? layerSetParams.filter : undefined,
    });
  }

  return EMPTY_LAYER_SET;
}
