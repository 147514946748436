// @flow

export function uniqueId({
  projectId,
  teamId,
}: {
  projectId: string,
  teamId: string,
}) {
  return `${projectId}-${teamId}`;
}
