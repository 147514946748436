// @flow
import classnames from "classnames";
import * as React from "react";
import Button from "core/components/Button";
import connectStorage from "core/hocs/connectStorage";
import createConnector from "core/lib/createConnector";
import style from "./style.scss";

type OwnProps = {|
  id?: string,
  label?: React.Node,
  collapsible?: boolean,
  flex?: boolean,
  children?: React.Node,
|};

type StorageProps = {|
  defaultCollapsed?: boolean,
  onCollapse?: (collapsed: boolean) => void,
|};

type Props = {
  ...OwnProps,
  ...StorageProps,
};

function SidebarSection(props: Props) {
  const [collapsed, setCollapsed] = React.useState(props.defaultCollapsed);

  const handleToggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  React.useEffect(() => {
    if (collapsed !== props.defaultCollapsed && props.onCollapse) {
      props.onCollapse(!!collapsed);
    }
  }, [collapsed, props]);

  return (
    <div
      className={classnames(style.sidebarSection, {
        [style.flex]: props.flex,
        [style.collapsed]: collapsed,
      })}
    >
      {props.label || props.collapsible ? (
        <div className={style.header}>
          {props.collapsible && (
            <Button
              className={style.disclosure}
              icon="disclosure-expanded"
              onClick={handleToggleCollapsed}
              nude
            />
          )}
          <div className={style.label}>{props.label}</div>
        </div>
      ) : undefined}
      <div className={style.children}>{props.children}</div>
    </div>
  );
}

function mapStorageToProps(storage, props: Props): StorageProps {
  const KEY = props.id ? `SidebarSection-${props.id}` : undefined;
  const previous = storage.getItem(KEY) || {};

  return {
    defaultCollapsed:
      props.defaultCollapsed === undefined
        ? previous.defaultCollapsed
        : props.defaultCollapsed,
    onCollapse: (defaultCollapsed) => {
      if (props.id) {
        storage.setItem(KEY, { defaultCollapsed });
      }

      if (props.onCollapse) {
        props.onCollapse(defaultCollapsed);
      }
    },
  };
}

export default createConnector<Props, OwnProps>((Component) =>
  connectStorage(Component, mapStorageToProps)
)(SidebarSection);
