// @flow
import classnames from "classnames";
import * as React from "react";
import ButtonUnstyled from "core/components/ButtonUnstyled";
import style from "./style.scss";

type Props = {
  className?: string,
  qaSelector?: string,
};

export default function ButtonLink(props: Props) {
  const { className, ...rest } = props;
  return (
    <ButtonUnstyled
      {...rest}
      className={classnames(style.buttonLink, className)}
    ></ButtonUnstyled>
  );
}
