// @flow
import get from "lodash/get";
import type { Layer, File } from "../types";

type Dimensions = {
  width: number,
  height: number,
};

type IdParams =
  | {
      projectId: string,
      sha: string,
      fileId: string,
      layerId: string,
    }
  | Layer;

export function uniqueId(params: $ReadOnly<IdParams>) {
  const { projectId, sha, fileId } = params;
  let id: string = "";
  if (typeof params.layerId === "string") {
    id = params.layerId;
  } else if (typeof params.id === "string") {
    id = params.id;
  }
  return `${projectId}-${sha}-${fileId}-${id}`;
}

export function dimensions(layer: ?Layer): Dimensions {
  if (!layer) {
    return { width: 0, height: 0 };
  }

  return {
    width: get(layer, ["dimensions", "width"], layer.width),
    height: get(layer, ["dimensions", "height"], layer.height),
  };
}

export function lastChangedAtSha(layer: ?Layer, file?: ?File): ?string {
  if (!layer) {
    return;
  }

  if (layer.lastChangedAtSha) {
    return layer.lastChangedAtSha;
  } // web

  return get(file, ["layerHistory", layer.id]); // desktop
}

export function altText(layer: ?Layer): string {
  return layer ? layer.name : "";
}
