// @flow
import * as React from "react";
import { connect } from "react-redux";
import Button from "core/components/Button";
import Empty from "core/components/Empty";
import ExternalLink from "core/components/ExternalLink";
import Icon from "core/components/Icon";
import PropertyRow from "core/components/PropertyRow";
import { helpAssetsUrl } from "core/lib/urls";
import { defaultBranch } from "core/models/branch";
import { canUseNewDefaultBranchName } from "core/selectors/features";
import type {
  Dispatch,
  LayerDataAsset,
  AssetAutoGenerationOption,
} from "core/types";

import style from "./style.scss";

type OwnProps = {|
  canGenerateAssets: boolean,
  isDesktop: boolean,
  assetAutoGenSetting: AssetAutoGenerationOption,
  layerDataAssets?: LayerDataAsset[],
  onGenerateAssetsForFile: () => void,
|};

type StateProps = {|
  defaultBranchName: string,
|};

type Props = {|
  ...OwnProps,
  ...StateProps,
  dispatch: Dispatch,
|};

type State = {
  ...State,
  generateDisabled: boolean,
};
class AssetsEmpty extends React.Component<Props, State> {
  state = {
    generateDisabled: false,
  };

  handleGenerateAssets = () => {
    this.setState({ generateDisabled: true });
    this.props.onGenerateAssetsForFile();
  };

  getAssetAutoGenMessage() {
    return (
      <div>
        <Icon className={style.scissorsIcon} type="no-image" large disabled />
        {this.props.assetAutoGenSetting === "master" ? (
          <div>
            Automatically generated assets is turned on only for commits on{" "}
            {this.props.defaultBranchName}.
          </div>
        ) : (
          <div>
            Automatically generated assets is turned off for this project.
          </div>
        )}
        <br />
      </div>
    );
  }

  getNoLayerDataMessage() {
    return (
      <div>
        <Icon className={style.scissorsIcon} type="scissors-default" large />
        <div>
          Add exports and slices in Sketch. After you Commit, they will become
          available here to download.{" "}
          <ExternalLink className={style.link} href={helpAssetsUrl()}>
            Learn more{" "}
            <Icon className={style.externalIcon} type="external" tint />
          </ExternalLink>
        </div>
        <div className={style.image} />
      </div>
    );
  }

  getGenAssetsQuestion() {
    return this.props.assetAutoGenSetting !== "all" ? (
      <div className={style.bold}>Want to generate anyway?</div>
    ) : (
      <div className={style.bold}>Want unchanged assets too?</div>
    );
  }

  render() {
    //If we can't generate assets, and have layer data assets
    //we should have no empty state, because there is no meaningful
    //action the user can take.
    if (
      !this.props.canGenerateAssets &&
      this.props.layerDataAssets &&
      this.props.layerDataAssets.length !== 0
    ) {
      return null;
    }

    return (
      <PropertyRow className={style.artboardPropertyRow}>
        <Empty className={style.empty}>
          {this.props.assetAutoGenSetting !== "all"
            ? this.getAssetAutoGenMessage()
            : !this.props.layerDataAssets ||
              this.props.layerDataAssets.length === 0
            ? this.getNoLayerDataMessage()
            : null}
          {this.props.canGenerateAssets && (
            <React.Fragment>
              {this.props.isDesktop ? (
                <React.Fragment>
                  {this.getGenAssetsQuestion()}
                  <Button
                    className={style.generateButton}
                    disabled={this.state.generateDisabled}
                    onClick={this.handleGenerateAssets}
                  >
                    Generate Assets for this file
                  </Button>
                </React.Fragment>
              ) : (
                <div className={style.bold}>
                  Open in Abstract for MacOS <br /> to generate assets.
                </div>
              )}
            </React.Fragment>
          )}
        </Empty>
      </PropertyRow>
    );
  }
}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  return {
    defaultBranchName: defaultBranch({
      masterToMain: canUseNewDefaultBranchName(state),
      titleCase: true,
    }),
  };
}

export default connect<Props, OwnProps, StateProps, _, State, Dispatch>(
  mapStateToProps
)(AssetsEmpty);
