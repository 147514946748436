// @flow
import classnames from "classnames";
import * as React from "react";
import Input from "../Input";
import InputWrapper from "../InputWrapper";
import style from "./style.scss";

type Props = {
  label?: string,
  name?: string,
  defaultValue?: string,
  palette?: Array<string>,
  className?: string,
  containerClass?: string,
  noLines?: boolean,
  onChange: (color: string) => void,
  disabled?: boolean,
};

type State = {
  color: string,
  customColor: ?string,
  customSelected: boolean,
};

export default class ColorSelector extends React.PureComponent<Props, State> {
  static defaultProps = {
    name: "color",
    onChange: (color: string) => {},
    defaultValue: "#B8BDBF",
    palette: [
      "#B8BDBF",
      "#7080D4",
      "#7AC4E5",
      "#00BE89",
      "#FF423D",
      "#FB8049",
      "#F3B11B",
      "#F36F8F",
      "#945ECF",
      "#8F9FA5",
      "#61B425",
      "#55A3D9",
    ],
  };

  constructor(props: Props) {
    super(props);

    // this accounts for explict null / undefined values from the server and
    // converts them to our default color.
    const defaultValue =
      this.props.defaultValue || ColorSelector.defaultProps.defaultValue;
    const isPaletteColor = (this.props.palette || []).includes(
      defaultValue.toUpperCase()
    );
    const placeholderCustomColor = this.props.palette
      ? this.props.palette[0].toUpperCase()
      : ColorSelector.defaultProps.palette[0].toUpperCase();

    this.state = {
      color: defaultValue.toUpperCase(),
      customColor: isPaletteColor
        ? placeholderCustomColor
        : defaultValue.toUpperCase(),
      customSelected: !isPaletteColor,
    };
  }

  getCustomHex(): ?string {
    const inPalette = (this.props.palette || []).includes(this.state.color);

    if (inPalette) {
      return undefined;
    }
    return this.state.color;
  }

  handleCustomChange = (ev: SyntheticInputEvent<>) => {
    let color = ev.target.value;

    if (color.length === 0) {
      const defaultValue = this.props.palette
        ? this.props.palette[0].toUpperCase()
        : ColorSelector.defaultProps.palette[0].toUpperCase();

      this.setState((prevState) => {
        const nextState = {
          customColor: defaultValue,
          customSelected: false,
          color: prevState.color,
        };

        if (prevState.customSelected) {
          nextState.color = defaultValue;
          this.props.onChange(defaultValue);
        }

        return nextState;
      });
    }

    if (color.length >= 3 && color[0] !== "#") {
      ev.target.value = `#${color}`;
      color = `#${color}`.toUpperCase();
    }

    if (color.match(`^#?([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$`)) {
      this.setState({
        customColor: color,
        color: color,
        customSelected: !(this.props.palette || []).includes(color),
      });
      this.props.onChange(color);
    }
  };

  handleChange = (ev: SyntheticInputEvent<*>) => {
    const input = ev.target;
    let color = input.value;

    this.setState({ color, customSelected: false });
    this.props.onChange(color);
  };

  renderColor = (color: string, index: number) => {
    const selected = color === this.state.color;
    const classes = classnames(style.color, { [style.selected]: selected });
    const colorStyle = {
      width: `calc(100% / ${
        this.props.palette ? this.props.palette.length : 12
      })`,
    };
    const id = `color-input-${color}`;

    return (
      <li
        className={classes}
        style={colorStyle}
        key={color}
        data-qa={`color-input-${index}`}
      >
        <label className={style.swatchWrapper} htmlFor={id}>
          <span className={style.swatch} style={{ background: `${color}` }}>
            <input
              type="radio"
              id={id}
              name={this.props.name}
              value={color}
              onChange={this.handleChange}
              checked={selected}
              disabled={this.props.disabled}
            />
          </span>
        </label>
      </li>
    );
  };

  renderSwatch = (color: string, large?: boolean) => (
    <span
      className={classnames(style.swatch, {
        [style.selected]: this.state.customSelected,
        [style.swatchLarge]: large,
      })}
      style={{ background: color }}
    />
  );

  render() {
    const {
      palette,
      label,
      className,
      containerClass,
      onChange,
      noLines,
      ...props
    } = this.props;
    const error = undefined;

    return (
      <InputWrapper
        error={error}
        label={this.props.label}
        className={containerClass}
        qaSelector="color-picker"
      >
        <div
          className={classnames(className, style.picker, {
            [style.noLines]: noLines,
          })}
        >
          <ul className={style.palette} data-qa="color-palette">
            {palette &&
              palette.map((color, index) => this.renderColor(color, index))}
          </ul>
          <div className={style.custom}>
            <label htmlFor="color-input-custom">
              <span>Custom HEX</span>
              <Input
                {...props}
                type="text"
                id="color-input-custom"
                onFocus={this.handleCustomChange}
                onChange={this.handleCustomChange}
                defaultValue={this.getCustomHex()}
                placeholder={
                  this.props.palette
                    ? this.props.palette[0]
                    : ColorSelector.defaultProps.palette[0]
                }
                maxLength={7}
                minLength={4}
                showLengthWarning={0}
                pattern="^#?([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
                validationmessage="Must be a valid color in HEX format"
              />
            </label>
            <div className={style.color}>
              <span className={style.swatchWrapper}>
                {this.renderSwatch(
                  this.state.customColor ||
                    (this.props.palette
                      ? this.props.palette[0]
                      : ColorSelector.defaultProps.palette[0]),
                  true
                )}
              </span>
            </div>
          </div>
        </div>
      </InputWrapper>
    );
  }
}
