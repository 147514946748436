// @flow
import createCachedSelector from "@elasticprojects/re-reselect";
import empty from "empty";
import { filter, sortBy, keyBy, reduce } from "lodash";
import * as File from "core/models/file";
import * as Page from "core/models/page";
import { getBranchHead } from "core/selectors/branches";
import { getChangesetPages } from "core/selectors/changesets";
import { getResolvedLayerDescriptor } from "core/selectors/descriptors";
import { getEntity } from "core/selectors/entities";
import { getFilesForBranch, getFilesAtSha } from "core/selectors/files";
import { getProjectId, getFileId } from "core/selectors/helpers";
import type { Page as TPage, State } from "core/types";

export function getPage(
  state: State,
  params: { projectId: string, sha: string, fileId: string, pageId: string }
): ?TPage {
  return getEntity(state, "pages", Page.uniqueId(params));
}

export function getPageForLayer(
  state: State,
  params: {
    projectId: string,
    branchId: string,
    sha: string,
    fileId: string,
    layerId: string,
    pageId: string,
  }
) {
  const { projectId, branchId, sha, fileId, layerId } = params;
  const resolvedLayerDescriptor = getResolvedLayerDescriptor(state, {
    projectId,
    branchId,
    sha,
    fileId,
    layerId,
  });
  if (resolvedLayerDescriptor) {
    return getPage(state, {
      projectId,
      sha: resolvedLayerDescriptor.sha,
      fileId,
      pageId: params.pageId,
    });
  }
  return null;
}

const getPages = (state) => state.entities.pages;

function pagesByFile(pages, files) {
  return files.reduce((memo, file) => {
    memo[File.uniqueId(file)] = sortBy(
      filter(pages, {
        projectId: file.projectId,
        sha: file.sha,
        fileId: file.id,
      }),
      (page) => page.order
    );
    return memo;
  }, {});
}

export const getPagesByFileAtSha: (
  State,
  { projectId: string, sha: string }
) => { [fileId: string]: TPage[] } = createCachedSelector(
  getPages,
  getFilesAtSha,
  pagesByFile
)((state, params) => `${params.projectId}-${params.sha}`);

export const getPagesByFileForBranch: (
  State,
  { projectId: string, branchId: string }
) => { [fileId: string]: TPage[] } = createCachedSelector(
  getPages,
  getFilesForBranch,
  pagesByFile
)((state, params) => `${params.projectId}-${params.branchId}`);

type BranchFileParams = {
  projectId: string,
  branchId: string,
  fileId: string,
};

const cacheByBranchFile = (state, props) =>
  `${props.projectId}-${props.branchId}-${props.fileId}`;

export const getPagesForFileOnBranch: (
  State,
  BranchFileParams
) => { [pageId: string]: TPage } = createCachedSelector(
  getProjectId,
  getFileId,
  getBranchHead,
  getPagesByFileForBranch,
  (projectId, fileId, head, pagesByFile) => {
    if (!head) {
      return empty.object;
    }

    const pages = pagesByFile[File.uniqueId({ projectId, sha: head, fileId })];

    if (!pages) {
      return empty.object;
    }

    return keyBy(pages, Page.uniqueId);
  }
)(cacheByBranchFile);

export const getLibraryPagesForFileOnBranch: (
  State,
  BranchFileParams
) => { [pageId: string]: TPage } = createCachedSelector(
  getPagesForFileOnBranch,
  (pages) => {
    return reduce(
      pages,
      (memo, page) => {
        if (Page.isLibrary(page)) {
          memo[Page.uniqueId(page)] = page;
        }
        return memo;
      },
      {}
    );
  }
)(cacheByBranchFile);

function getChangesetPagesForBranch(
  state: State,
  { projectId, branchId }: { projectId: string, branchId: string }
) {
  return getChangesetPages(state, { projectId, branchId });
}

export const getChangedPagesForFileOnBranch: (
  State,
  BranchFileParams
) => TPage[] = createCachedSelector(
  getChangesetPagesForBranch,
  (state, { fileId }) => fileId,
  (pages, fileId) => sortBy(filter(pages, { fileId }), "order")
)(cacheByBranchFile);
