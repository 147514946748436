// @flow
import classnames from "classnames";
import * as React from "react";
import { PieChart } from "react-minimal-pie-chart";
import style from "./style.scss";

type Props = {
  className?: string,
  outlineClassName?: string,
  percentage: number,
  width?: number,
  height?: number,
  lineWidth?: number,
  showOutline?: boolean,
  allgood?: boolean,
};

export default function ProgressPie({
  className,
  outlineClassName,
  showOutline = true,
  percentage = 0,
  width,
  height,
  lineWidth = 100,
  allgood = false,
}: Props) {
  const extraStyle = {};

  if (width && height) {
    extraStyle.width = `${width}px`;
    extraStyle.height = `${height}px`;
  }

  const pieChartClasses = classnames(style.pie, {
    [style.allgood]: allgood,
  });

  return (
    <div className={classnames(style.pie, className)} style={extraStyle}>
      {showOutline && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 24 24"
          style={extraStyle}
          className={outlineClassName}
        >
          <path
            fillRule="evenodd"
            d="M137,27 C130.372583,27 125,21.627417 125,15 C125,8.372583 130.372583,3 137,3 C143.627417,3 149,8.372583 149,15 C149,21.627417 143.627417,27 137,27 Z M137,24 C141.970563,24 146,19.9705627 146,15 C146,10.0294373 141.970563,6 137,6 C132.029437,6 128,10.0294373 128,15 C128,19.9705627 132.029437,24 137,24 Z"
            transform="translate(-125 -3)"
          />
        </svg>
      )}
      <PieChart
        style={extraStyle}
        className={pieChartClasses}
        data={[{ value: 1, key: 1, color: "currentColor" }]}
        reveal={percentage}
        startAngle={-90}
        lengthAngle={360}
        lineWidth={lineWidth}
        animate
      />
    </div>
  );
}
