// @flow
import { connect } from "react-redux";
import { unknownUser } from "core/models/user";
import { getUser } from "core/selectors/users";
import type { State, Dispatch } from "core/types";
import type { OwnProps, StateProps, Props } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { userId, src, name } = props;
  const user = getUser(state, { userId: userId || "" });

  return {
    userId: userId ? userId : unknownUser.id,
    src: src ? src : user ? user.avatarUrl : unknownUser.avatarUrl,
    userName: name ? name : user && user.name ? user.name : unknownUser.name,
  };
}

export default connect<Props, OwnProps, StateProps, _, State, Dispatch>(
  mapStateToProps
);
