// @flow
import { schema, normalize } from "normalizr";
import { file, layer, page } from "core/schema";

const layersSchema = new schema.Array(layer);

export function normalizeLayers(layers: *) {
  return normalize(layers, layersSchema);
}

export function normalizeLayerResponse(response: *) {
  return normalize(response, new schema.Object({ file, layer, page }));
}
