// @flow
import * as React from "react";
import Button from "core/components/Button";
import Flex from "core/components/Flex";
import Heading from "core/components/Heading";
import connectStorage from "core/hocs/connectStorage";
import useBranchDisplayName from "core/hooks/useBranchDisplayName";
import createConnector from "core/lib/createConnector";
import style from "./style.scss";

type OwnProps = {|
  userId: string,
|};

type StorageProps = {|
  alreadyDismissed: boolean,
  dismiss: () => void,
|};

type Props = {
  ...OwnProps,
  ...StorageProps,
};

function FirstBranchWelcome(props: Props) {
  const { alreadyDismissed, dismiss } = props;
  const { defaultBranchNameTitleCase } = useBranchDisplayName();

  return (
    !alreadyDismissed && (
      <Flex className={style.welcomeCard}>
        <Button nude icon="close" onClick={dismiss} />
        <Flex column justify="space-between" align="center">
          <div className={style.stickers} />
          <Heading level="2" size="l">
            Nice job! You created your first Branch.
          </Heading>
          <p className={style.welcomeMessage}>
            Start trying out new ideas in your Branch. Commit each iteration.
            You’ll decide with your team later if you’d like to merge your
            designs to {defaultBranchNameTitleCase}.
          </p>
        </Flex>
      </Flex>
    )
  );
}

function mapStorageToProps(storage, props: OwnProps): StorageProps {
  const { userId } = props;
  const key = `first-branch-welcome-already-dismissed-${userId}`;

  return {
    alreadyDismissed: storage.getItem(key) || false,
    dismiss: () => storage.setItem(key, true),
  };
}

export default createConnector<Props, OwnProps>((Component) =>
  connectStorage(Component, mapStorageToProps)
)(FirstBranchWelcome);
