// @flow
import React, { PureComponent } from "react";
import DialogForm from "core/components/DialogForm";
import FilesList from "core/components/FilesList";
import FilesListItem from "core/components/FilesListItem";
import Note from "core/components/Note";
import type { Project, Branch, Library, File, FileMode } from "core/types";
import connector from "./connector";

export type OwnProps = {|
  file: File,
  project: Project,
  branch: Branch,
  libraries: Library[],
|};

export type DispatchProps = {|
  openFile: ({
    projectId: string,
    branchId: string,
    fileId: string,
    sha: string,
    fileMode: FileMode,
    skipWarnings: boolean,
  }) => void,
  display: (name: string, value: boolean) => void,
  dismissDialog: () => void,
|};

export type Props = {
  ...OwnProps,
  ...DispatchProps,
};

class MissingLibraries extends PureComponent<Props> {
  handleSubmit = () => {
    const { project, branch, file } = this.props;
    const skipWarnings = true;

    this.props.openFile({
      projectId: project.id,
      branchId: branch.id,
      fileId: file.id,
      sha: file.sha,
      fileMode: "READ_WRITE",
      skipWarnings,
    });
    this.props.dismissDialog();
  };

  toggleWarning = (ev: SyntheticInputEvent<>) => {
    this.props.display("missingLibrariesWarning", !ev.target.checked);
  };

  render() {
    const { libraries } = this.props;

    return (
      <DialogForm
        title="Libraries unavailable"
        primaryButton="Continue Opening"
        onSubmit={this.handleSubmit}
        onClose={this.props.dismissDialog}
        isOpen
      >
        <p>
          Linked library files on this branch are unavailable. You won’t be able
          to insert symbols from these libraries:
        </p>
        <FilesList>
          {libraries.map((library) => (
            <FilesListItem
              key={library.fileId}
              id={library.fileId}
              type={library.type}
              name={library.name}
              meta={library.projectName || ""}
              isLibrary
            />
          ))}
        </FilesList>
        <Note>
          <label>
            <input type="checkbox" onChange={this.toggleWarning} /> Don’t show
            me this reminder again
          </label>
        </Note>
      </DialogForm>
    );
  }
}

export default connector(MissingLibraries);
