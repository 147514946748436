// @flow
import classnames from "classnames";
import * as React from "react";
import style from "./style.scss";

export default function RadioGroup({
  children,
  className,
  onChange,
  name,
  value,
}: {
  children?: any,
  className?: string,
  onChange: (SyntheticInputEvent<>) => void,
  name: string,
  value: ?string,
}) {
  return (
    <div className={classnames(style.group, className)}>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          wrapperClass: classnames(style.grouped, child.props.wrapperClass),
          name: name,
          checked: value === child.props.value,
          onChange: (e) => {
            if (child.props.onChange) {
              child.props.onChange(e);
            }
            onChange(e);
          },
        })
      )}
    </div>
  );
}
