// @flow
import classnames from "classnames";
import * as React from "react";
import Button from "core/components/Button";
import Flex from "core/components/Flex";
import Icon from "core/components/Icon";
import Theme from "core/components/Theme";
import { isDesktop } from "core/lib/platform";
import { V3NavLink as NavLink } from "core/lib/router";
import type { LocationDescriptor } from "core/types";
import style from "./style.scss";

export type Props = {|
  action?: React.Node,
  activeClassName?: string,
  activePath?: string,
  children?: React.Node,
  label?: string,
  title?: string,
  name?: string,
  className?: string,
  divider?: boolean,
  large?: boolean,
  mobile?: boolean,
  disabled?: boolean,
  icon?: string,
  onlyActiveOnIndex?: boolean,
  type?: "checkbox",
  selected?: boolean,
  checked?: boolean,
  external?: boolean,
  danger?: boolean,
  to?: LocationDescriptor,
  href?: ?string,
  onClick?: (event: SyntheticMouseEvent<>) => void,
  onContextMenu?: (event: SyntheticMouseEvent<>) => void,
  onToggleCollapsed?: (collapsed: boolean) => void,
  collapsed?: boolean,
  isFocused?: boolean,
  selectedClassName?: string,
  disclosureClassName?: string,
  qaSelector?: string,
|};

class MenuItem extends React.Component<Props> {
  static defaultProps = {
    collapsed: false,
  };

  toggleCollapsed = (ev: SyntheticEvent<>) => {
    ev.preventDefault();
    ev.stopPropagation();

    if (this.props.onToggleCollapsed) {
      this.props.onToggleCollapsed(!this.props.collapsed);
    }
  };

  render() {
    const {
      type,
      action,
      activeClassName,
      selectedClassName = "",
      disclosureClassName,
      collapsed,
      activePath,
      children,
      label,
      className,
      divider,
      icon,
      selected,
      large,
      mobile,
      disabled,
      external,
      danger,
      isFocused,
      to,
      href,
      qaSelector,
      title,
      onClick,
      onContextMenu,
    } = this.props;

    if (divider) {
      return <div className={classnames(className, style.divider)} />;
    }

    const isActive = ((to && activePath === to) || selected) && !disabled;
    const isLight = isActive && isFocused;
    const canCollapse = label && children;
    const showChildren = canCollapse && !collapsed;
    const Component = disabled ? "div" : href ? "a" : NavLink;

    const anchorClasses = classnames(style.label, {
      [style.selected]: isActive,
      [style.focused]: isFocused,
      [style.danger]: danger,
      [style.external]: external,
      [selectedClassName]: isActive,
    });

    return (
      <div
        className={classnames(className, style.item, {
          [style.disabled]: disabled,
          [style.collapsed]: collapsed,
          [style.large]: large,
          [style.mobile]: mobile,
        })}
        data-qa={qaSelector}
      >
        <Component
          className={anchorClasses}
          activeClassName={classnames(
            style.selected,
            selectedClassName,
            activeClassName
          )}
          onClick={onClick}
          onContextMenu={onContextMenu}
          to={disabled ? undefined : to}
          href={href}
          title={title}
        >
          {icon && (
            <Theme.Consumer>
              {({ themeName }) => (
                <Icon
                  type={icon}
                  light={isLight || themeName === "dark"}
                  fill={
                    isDesktop && themeName === "light"
                      ? "rgba(0,0,0,.5)"
                      : undefined
                  }
                  className={style.icon}
                  inheritFill
                />
              )}
            </Theme.Consumer>
          )}
          <Flex align="center" className={style.labelWrapper}>
            {type === "checkbox" ? (
              <React.Fragment>
                <div className={style.checkmarkContainer}>
                  {this.props.checked && <Icon type="checkmark" tint />}
                </div>
                <span className={style.checkboxLabel}>
                  {this.props.label || this.props.children}
                </span>
              </React.Fragment>
            ) : (
              this.props.label || this.props.children || null
            )}
          </Flex>
          {action !== undefined && (
            <span className={style.action}>{action}</span>
          )}
          {canCollapse && (
            <Button
              nude
              icon={
                isLight ? "chevron-default-down-light" : "chevron-default-down"
              }
              onClick={this.toggleCollapsed}
              className={classnames(style.disclosure, disclosureClassName)}
            />
          )}
        </Component>
        {showChildren &&
          React.Children.map(children, (child) =>
            React.cloneElement(child, {
              isFocused,
              selectedClassName,
              disclosureClassName,
            })
          )}
      </div>
    );
  }
}

export default MenuItem;
