// @flow
import * as recurly from "core/lib/recurly";
import type { Action, RecurlyError, ThunkAction } from "core/types";

export function recurlyStart3DSChallenge(
  actionToken: string,
  accountCode?: string
): Action {
  return {
    type: "recurly/3DS_CHALLENGE_STARTED",
    payload: { actionToken, accountCode },
  };
}

export function recurlyReset(): Action {
  return {
    type: "recurly/RESET",
  };
}

export function recurlyError(error: RecurlyError): Action {
  return {
    type: "recurly/ERROR",
    payload: error,
  };
}

export function createPaymentToken(
  form: EventTarget,
  onSuccessCallback?: (token: string) => void,
  onErrorCallback?: (error: Error) => void
): ThunkAction {
  return function (dispatch) {
    dispatch(recurlyReset());

    recurly
      .getPaymentToken(form)
      .then((token) => {
        if (onSuccessCallback) {
          onSuccessCallback(token.id);
        }
      })
      .catch((error) => {
        dispatch(recurlyError(error));
        if (onErrorCallback) {
          onErrorCallback(error);
        }
      });
  };
}
