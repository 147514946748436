// @flow
import window from "core/global/window";

export const isProductionDomain = /^app\.(go)?abstract\.com$/.test(
  window.location.host
);

export const isBetaDomain = /^beta\.abstract\.com$/.test(window.location.host);

export const isStagingDomain =
  /^(ui-(.*)\.now\.sh|ui-(.*)\.vercel\.app|app-staging-1\.gopropellergo\.com)$/.test(
    window.location.host
  );
