// @flow
import { connect } from "react-redux";
import { AccountCreateRequest } from "core/requests/account";
import type { State, Dispatch } from "core/types";
import { createAccount } from "web/actions/account";
import { ConfigurationFetchRequest } from "web/requests/sessions";
import { isLoggedIn } from "web/selectors/session";
import type { OwnProps, StateProps, DispatchProps, Props } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  return {
    errors: AccountCreateRequest.validationErrors(state, {}),
    isLoadingSession: ConfigurationFetchRequest.isLoadingStrict(state, {}),
    isLoggedIn: isLoggedIn(state),
    invitation: state.invitation.entity,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    createAccount: (values, invitation, onSuccess, onError) => {
      return dispatch(createAccount(values, invitation, onSuccess, onError));
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
