// @flow
import { schema, normalize } from "normalizr";
import { team, teamMembership } from "core/schema";
import { envelopeSchema } from "core/schemas/envelope";

const teamSchema = envelopeSchema(
  new schema.Object({
    team: team,
  })
);

const teamsFetchResponseSchema = envelopeSchema(
  new schema.Object({
    teams: [team],
    team_memberships: [teamMembership],
  })
);

export function normalizeTeamSocketResponse(response: *) {
  return normalize(response, new schema.Object({ team: team }));
}

export function normalizeTeamResponse(response: *) {
  return normalize(response, teamSchema);
}

export function normalizeTeamsFetchResponse(response: *) {
  return normalize(response, teamsFetchResponseSchema);
}
