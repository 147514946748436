// @flow
import * as React from "react";
import Button from "core/components/Button";
import style from "./style.scss";
import Empty from ".";

type Props = {
  onClick?: () => void,
  isProject?: boolean,
  title?: string,
  canCreateTeam?: boolean,
};

export default function NoTeams(props: Props) {
  const { canCreateTeam, onClick, isProject, title } = props;

  return (
    <Empty
      title={title}
      icon="team"
      description="A team is a group of people that can be invited to a project, all at once. Add new people to your team to grant instant access to all its projects."
    >
      <div className={style.buttonMargin}>
        {onClick && (isProject || canCreateTeam) ? (
          <Button primary onClick={onClick}>
            {isProject ? "Invite to Project" : "Create a Team"}
          </Button>
        ) : null}
      </div>
    </Empty>
  );
}
