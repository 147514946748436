// @flow
import classnames from "classnames";
import { isEmpty } from "lodash";
import * as React from "react";
import Dropzone from "react-dropzone";
import Flex from "core/components/Flex";
import style from "./style.scss";

const acceptedFiles = "image/jpeg, image/png, image/gif";
const MAX_BYTES_SIZE = 128000;

type Props = {
  isLoading: boolean,
  hasRejectedFiles: boolean,
  onChange: (file: File[]) => void,
  setRejectedFilesOn: () => void,
  setRejectedFilesOff: () => void,
};

export default function AttachmentDropzone(props: Props) {
  const [isDragOver, setIsDragOver] = React.useState(false);

  const handleDrop = (files: File[]) => {
    if (!isEmpty(files)) {
      props.onChange(files);
    }
    setIsDragOver(false);
  };

  const onDropAccepted = (files: File[]) => {
    props.setRejectedFilesOff();
  };

  const onDropRejected = (files: File[]) => {
    props.setRejectedFilesOn();
  };

  const { isLoading, hasRejectedFiles } = props;

  return (
    <Dropzone
      className={classnames(style.dropzone, {
        [style.onDragOver]: isDragOver,
        [style.onRejected]: hasRejectedFiles,
        [style.disabled]: isLoading,
      })}
      onDrop={handleDrop}
      onDropAccepted={onDropAccepted}
      onDropRejected={onDropRejected}
      onDragOver={() => setIsDragOver(true)}
      onDragLeave={() => setIsDragOver(false)}
      accept={acceptedFiles}
      disabled={isLoading}
      maxSize={MAX_BYTES_SIZE}
      multiple={false}
    >
      <Flex column align="center" justify="center">
        <span>
          Drop your new emoji here, or
          <br /> select a file from your computer.
        </span>
        <span className={style.attachmentValidation}>
          Square JPG, PNG or GIF at least 32px
        </span>
      </Flex>
    </Dropzone>
  );
}
