// @flow
export const filterUrl = (url: string): string => {
  return filterSensitiveArguments(filterSensitiveRouteParameters(url));
};

export const filterSensitiveRouteParameters = (path: string): string => {
  // filter any route parameters proceeding a `password/sensitive-token`
  // or `invitations/sensitive-invitation-code`
  // up to the next `/`, '?' or end of url
  return path.replace(
    /(password|invitations)\/(.*?)($|\/|\?)/gi,
    "$1/[Filtered]$3"
  );
};

export const filterSensitiveArguments = (search: string): string => {
  // filter anything proceeding a `token={some-sensitive-token}`
  // or anything proceeding a `username={some-username}`
  return search.replace(/(token=|username=)(.*?)($|&)/gi, "$1[Filtered]$3");
};
