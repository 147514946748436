// @flow
import { connect } from "react-redux";
import createConnector from "core/lib/createConnector";
import { canInstallSlackV2Integration } from "core/selectors/features";
import { getOrganizationSettings } from "core/selectors/organizationSettings";
import { getOrganization } from "core/selectors/organizations";
import type { State, Dispatch, OrganizationSettings } from "core/types";
import { removeIntegration } from "web/actions/organizationSettings";
import { slackChannelFormSubmitted } from "web/actions/slackChannelForm";
import type { FormValues } from "web/models/form";
import type { ConnectedProps, OwnProps, StateProps, DispatchProps } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { organizationId } = props.params;
  const organization = getOrganization(state, { organizationId });
  const settings = getOrganizationSettings(state, { organizationId });
  const integration = settings ? settings.slackIntegration : undefined;
  const canInstallNewIntegration = canInstallSlackV2Integration(
    state,
    organizationId
  );

  return {
    organization,
    integration,
    settings,
    location: props.location,
    showUpdateAvailable:
      (integration &&
        !integration.details.slackV2 &&
        canInstallNewIntegration) ||
      false,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { organizationId } = props.params;

  return {
    removeIntegration: (integrationId: string, kind: string) => {
      dispatch(removeIntegration(organizationId, integrationId, kind));
    },
    slackChannelFormSubmitted: (
      id: string,
      integrationId: string,
      values: FormValues,
      organizationSettings: OrganizationSettings
    ) => {
      dispatch(
        slackChannelFormSubmitted(
          id,
          integrationId,
          values,
          organizationSettings
        )
      );
    },
  };
}

export default createConnector<ConnectedProps, OwnProps>(
  connect<ConnectedProps, OwnProps, StateProps, DispatchProps, State, Dispatch>(
    mapStateToProps,
    mapDispatchToProps
  )
);
