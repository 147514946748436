// @flow
import * as React from "react";
import Card from "core/components/Card";
import Flex from "core/components/Flex";
import Icon from "core/components/Icon";
import Media from "core/components/Media";
import Note from "core/components/Note";
import Time from "core/components/Time";
import { V3Link as Link } from "core/lib/router";
import type { Webhook } from "core/types";
import { webhookPath } from "web/routeHelpers";
import style from "./style.scss";

type Props = {
  webhook: Webhook,
};

export default function WebhookListItem({ webhook }: Props) {
  return (
    <Media mobile>
      {(mobile) => (
        <Card
          className={style.card}
          component={Link}
          list
          mobile={mobile}
          to={webhookPath(webhook.organizationId, webhook.id)}
        >
          <Flex align="center">
            <Icon type="webhooks" className={style.icon} />
            <Flex column={mobile} className={style.shell}>
              <Flex column className={style.content}>
                <div className={style.url}>{webhook.url}</div>
                <Note component="div" className={style.note}>
                  {webhook.lastPushedAt ? (
                    <React.Fragment>
                      Last pushed <Time date={webhook.lastPushedAt} />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      Updated <Time date={webhook.updatedAt} />
                    </React.Fragment>
                  )}
                  &nbsp;·&nbsp;Created{" "}
                  {webhook.user && `by ${webhook.user.name} `}
                  <Time date={webhook.createdAt} />
                </Note>
              </Flex>
              <Flex grow={false} className={style.statusInfo}>
                {webhook.errorCount !== undefined && webhook.errorCount > 0 && (
                  <Flex grow={false} align="center">
                    <Icon type="error" danger />
                    <span className={`${style.status} ${style.error}`}>
                      {webhook.errorCount} ERRORS
                    </span>
                  </Flex>
                )}
                {(webhook.active === undefined || webhook.active === true) && (
                  <Flex grow={false} align="center">
                    <span className={style.check}>
                      <Icon type="checkmark" inheritFill />
                    </span>
                    <span className={`${style.status} ${style.active}`}>
                      ACTIVE
                    </span>
                  </Flex>
                )}
                {webhook.active === false && (
                  <Flex grow={false} align="center">
                    <span className={`${style.status} ${style.inactive}`}>
                      NOT ACTIVE
                    </span>
                  </Flex>
                )}
              </Flex>
            </Flex>
            <Icon type="go-to" className={style.arrow} />
          </Flex>
        </Card>
      )}
    </Media>
  );
}
