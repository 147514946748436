// @flow
import React from "react";
import Header from "../Header";
import type { CompareMode } from "../LayerCompare";
import PillTab from "../PillTab";
import Tabs from "../Tabs";
import ZoomPercentageInput from "../ZoomPercentageInput";
import type { ChangeEvent as ZoomState } from "../ZoomablePreview";
import style from "./style.scss";

type Props = {
  showPreviewTab?: boolean,
  selectedCompareMode: CompareMode,
  zoomState: ZoomState,
  onChangeZoomState: (ZoomState) => *,
  onZoomToFit: (ZoomState) => ZoomState,
  onChangeCompareMode: (CompareMode) => *,
};

export default function Footer(props: Props) {
  return (
    <div className={style.layerCompareFooter}>
      <Header
        center={
          <div className={style.footerToolbarCenter}>
            <Tabs>
              {props.showPreviewTab && (
                <PillTab
                  icon="show"
                  selected={props.selectedCompareMode === "preview"}
                  className={style.condensedTab}
                  onClick={() => props.onChangeCompareMode("preview")}
                  secondary
                >
                  Preview
                </PillTab>
              )}
              <PillTab
                icon="compare-side-by-side"
                className={style.condensedTab}
                selected={props.selectedCompareMode === "sideBySide"}
                onClick={() => props.onChangeCompareMode("sideBySide")}
                secondary
              >
                Side By Side
              </PillTab>
              <PillTab
                icon="compare-overlay"
                className={style.condensedTab}
                selected={props.selectedCompareMode === "overlay"}
                onClick={() => props.onChangeCompareMode("overlay")}
                secondary
              >
                Overlay
              </PillTab>
            </Tabs>
          </div>
        }
        right={
          <div className={style.footerToolbarRight}>
            <ZoomPercentageInput
              zoomState={props.zoomState}
              onChange={props.onChangeZoomState}
              onZoomToFit={props.onZoomToFit}
            />
          </div>
        }
      />
    </div>
  );
}
