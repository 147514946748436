// @flow
import { starProject, unstarProject } from "abstract-di/actions";
import { entitiesReceived, entitiesDeleted } from "core/actions/entities";
import {
  StarCreateRequest,
  StarDeleteRequest,
  StarsFetchRequest,
} from "core/requests/stars";
import { normalizeStars } from "core/schemas/star";
import { getStar, isStarred } from "core/selectors/stars";
import type { Action, EntitiesOfType, Star, ThunkAction } from "core/types";

export function fetchStars(): ThunkAction {
  return async (dispatch) => {
    dispatch(StarsFetchRequest.perform({ params: {} }));
  };
}

export type StarParams = {
  id: string,
  type: "project" | "section",
};

export function toggleStar(params: StarParams): ThunkAction {
  return async (dispatch, getState) => {
    const starred = isStarred(getState(), { starrableId: params.id });

    if (starred) {
      dispatch(unstarEntity(params));
    } else {
      dispatch(starEntity(params));
    }
  };
}

export function starEntity({ id, type }: StarParams): ThunkAction {
  return async (dispatch, getState) => {
    const star = {
      starrableId: id,
      starrableType: type,
      starredAt: new Date().toString(),
    };
    const { entities } = normalizeStars([star]);
    dispatch(entitiesReceived(entities));

    if (type.toLowerCase() === "project" && starProject) {
      return dispatch(starProject(id));
    }

    dispatch(StarCreateRequest.perform({ params: { id, type } }));
  };
}

export function unstarEntity({ id, type }: StarParams): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState();
    const star = getStar(state, { starrableId: id });

    if (!star) {
      return;
    }

    dispatch(entitiesDeleted({ stars: [id] }));

    if (type.toLowerCase() === "project" && unstarProject) {
      return dispatch(unstarProject(id));
    }

    dispatch(StarDeleteRequest.perform({ params: star }));
  };
}

export function starsLoadedAll(stars: EntitiesOfType<Star>): Action {
  return {
    type: "core/STARS_LOADED_ALL",
    payload: stars,
  };
}
