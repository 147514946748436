// @flow
import * as React from "react";
import window from "core/global/window";

// https://usehooks.com/useLocalStorage/
export default function useLocalStorage<T>(
  key: string,
  initialValue: T,
  options: { subscribe: boolean } = {}
): [T, (T) => void, () => void] {
  const [storedValue, setStoredValue] = React.useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  React.useEffect(() => {
    if (options.subscribe) {
      const handleStorageChange = () => {
        let item = window.localStorage.getItem(key);
        try {
          let newValue: T = item ? JSON.parse(item) : initialValue;
          setStoredValue((currentValue) => {
            if (newValue !== currentValue) {
              return newValue;
            } else {
              return currentValue;
            }
          });
        } catch (error) {
          console.error(error);
          return initialValue;
        }
      };

      window.addEventListener("storage", handleStorageChange);
      return () => window.removeEventListener("storage", handleStorageChange);
    }
  }, [initialValue, key, options.subscribe]);

  const setValue = (value: T) => {
    try {
      setStoredValue(value);
      const item = JSON.stringify(value);
      if (item !== undefined) {
        window.localStorage.setItem(key, item);
        window.dispatchEvent(new Event("storage"));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const clearValue = React.useCallback(() => {
    window.localStorage.removeItem(key);
  }, [key]);

  return [storedValue, setValue, clearValue];
}
