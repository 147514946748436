// @flow
import classnames from "classnames";
import * as React from "react";
import Transparency from "../Transparency";
import style from "./style.scss";

type Props = {
  children?: React.Node,
  className?: string,
  fixed?: boolean,
  backgroundTransparent?: boolean,
  fixed?: boolean,
  borderRadius?: "borderSmall" | "borderLarge",
};

export default function Preview({
  children,
  className,
  fixed = true,
  backgroundTransparent,
  borderRadius,
}: Props) {
  const content = <div className={style.previewInner}>{children}</div>;

  if (backgroundTransparent) {
    return (
      <Transparency
        fixed={fixed}
        className={classnames(
          className,
          style.previewWrapper,
          borderRadius ? style[borderRadius] : undefined
        )}
      >
        {content}
      </Transparency>
    );
  }

  return (
    <div
      className={classnames(
        className,
        style.previewWrapper,
        style.bgGray,
        borderRadius ? style[borderRadius] : undefined
      )}
    >
      {content}
    </div>
  );
}
