// @flow
import classnames from "classnames";
import * as React from "react";
import style from "./style.scss";

type Props = {|
  children: React.Node,
  className?: string,
  secondary?: boolean,
|};

export default function OnboardingNote({
  children,
  className,
  secondary,
}: Props) {
  return (
    <div
      className={classnames(style.note, className, {
        [style.secondaryText]: secondary,
      })}
    >
      {children}
    </div>
  );
}
