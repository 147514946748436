// @flow
import * as React from "react";
import Avatar from "../Avatar";
import Button from "../Button";
import ImagePickerModal from "../ImagePickerModal";
import InputWrapper from "../InputWrapper";
import OrganizationLogo from "../OrganizationLogo";
import style from "./style.scss";

type State = {
  value: string,
  isOpen: boolean,
  resetModalKey: number,
};

type Props = {
  alt: string,
  defaultValue?: string,
  error?: string | string[],
  label: string,
  onChange: (value: string) => void,
  size?: number,
  type: "avatar" | "organizationLogo",
  value?: string,
  responsive?: boolean,
  requiredTag?: boolean,
  disabled?: boolean,
  wrapperClass?: string,
  isRemovable?: boolean,
};
export default class InputImage extends React.Component<Props, State> {
  static defaultProps = { size: 64, type: "avatar", isRemovable: true };

  state = { value: "", isOpen: false, resetModalKey: 0 };

  handleClose = () => {
    this.setState({ isOpen: false });
    // wait for animation to finish before resetting the modal
    setTimeout(() => {
      this.setState((prevState) => ({
        resetModalKey: prevState.resetModalKey + 1,
      }));
    }, 200);
  };

  handleOpen = () => {
    this.setState({ isOpen: true });
  };

  handleChange = (value: string) => {
    this.setState((prevState) => ({
      value,
      isOpen: false,
      resetModalKey: prevState.resetModalKey + 1,
    }));

    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  handleReset = () => {
    this.handleChange("");
  };

  label() {
    return this.props.type === "avatar" ? "avatar" : "logo";
  }

  renderPreview() {
    const previewUrl =
      this.props.value || this.state.value || this.props.defaultValue;

    if (this.props.type === "avatar") {
      return (
        <Avatar src={previewUrl} name={this.props.alt} size={this.props.size} />
      );
    }

    return (
      <OrganizationLogo
        alt={this.props.alt}
        size={this.props.size}
        src={previewUrl}
      />
    );
  }

  render() {
    return (
      <InputWrapper
        error={this.props.error}
        label={this.props.label}
        responsive={this.props.responsive}
        requiredTag={this.props.requiredTag}
        className={this.props.wrapperClass}
      >
        <div className={style.wrapper}>
          <div className={style.preview}>{this.renderPreview()}</div>
          <div>
            <div>
              <Button
                className={style.action}
                onClick={this.handleOpen}
                type="button"
                disabled={this.props.disabled}
              >
                Change {this.label()}
              </Button>
              {this.state.value && this.props.isRemovable && (
                <Button
                  className={style.action}
                  onClick={this.handleReset}
                  type="button"
                >
                  Remove {this.label()}
                </Button>
              )}
            </div>
            <div className={style.help}>JPG or PNG, at least 256px.</div>
          </div>
        </div>
        <ImagePickerModal
          key={this.state.resetModalKey.toString()}
          type={this.props.type}
          isOpen={this.state.isOpen}
          onClose={this.handleClose}
          onComplete={this.handleChange}
        />
      </InputWrapper>
    );
  }
}
