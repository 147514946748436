// @flow
import * as React from "react";
import ActivityBase from "./Base";
import Callout from "./Callout";
import type {
  ActivityReviewCompleted as TActivityReviewCompleted,
  Props,
} from "./types";

const reviewStatusMessages = {
  APPROVED: "has approved",
  REJECTED: "requested changes on",
};

const statusIcons = {
  APPROVED: "review-green",
  REJECTED: "changes-request-orange",
};

function action(props: Props<TActivityReviewCompleted>) {
  const status = reviewStatusMessages[props.activity.payload.status];
  return <span>{status} the branch</span>;
}

export default function ActivityReviewReset(
  props: Props<TActivityReviewCompleted>
) {
  const { activity, context } = props;
  return (
    <ActivityBase
      {...props}
      icon={statusIcons[activity.payload.status]}
      branchId={activity.branchId}
      branchName={activity.payload.branchName}
      action={action(props)}
      context={context}
      subject={
        context.branchId ? null : (
          <Callout>{activity.payload.branchName}</Callout>
        )
      }
      when={activity.createdAt}
    />
  );
}
