// @flow
import * as React from "react";
import Empty from ".";

export default function NoAbout({ canEdit, ...rest }: { canEdit?: boolean }) {
  return (
    <Empty
      {...rest}
      description={
        canEdit
          ? "Help your colleagues understand what this project is about or link to existing documentation."
          : "Project admins can add information about the project or link to existing documentation."
      }
      small
    />
  );
}
