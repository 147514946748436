// @flow
import { connect } from "react-redux";
import {
  copyToClipboard,
  openProjectInFinder,
  openProjectInTerminal,
  stopSyncingProject,
  confirmStopSyncing,
  exportProject,
  exportRepo,
  cloneRepo,
  repackRepo,
  pullRepo,
  pushRepo,
} from "abstract-di/actions";
import {
  getCurrentUserId,
  isOnline,
  isDevelopmentMenuEnabled,
  getRepoHasChanges,
  getRepoIsUnsynced,
  getRepoIsSyncing,
} from "abstract-di/selectors";
import { leaveProject } from "core/actions/projectMemberships";
import {
  updateProject,
  archiveProject,
  unarchiveProject,
} from "core/actions/projects";
import { starEntity, unstarEntity } from "core/actions/stars";
import { withData } from "core/components/DataLoader";
import createConnector from "core/lib/createConnector";
import { push } from "core/lib/location";
import { projectPath } from "core/lib/routes";
import { SectionsFetchRequest } from "core/requests/sections";
import {
  canUsePartialSync,
  enterpriseTeamsEnabled,
} from "core/selectors/features";
import {
  getOrganizationPolicy,
  getProjectPolicy,
} from "core/selectors/policies";
import { getFilteredSections } from "core/selectors/sections";
import { isStarred } from "core/selectors/stars";
import type { Dispatch, State } from "core/types";
import type { OwnProps, DispatchProps, StateProps } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { project } = props;
  const { id: projectId, organizationId } = project;
  const organizationPolicy = getOrganizationPolicy(state, { organizationId });
  const policy = getProjectPolicy(state, { projectId });
  const currentUserId = getCurrentUserId(state);
  const canPartialSync = canUsePartialSync(state, { projectId });

  return {
    policy,
    sections: getFilteredSections(state, { organizationId }),
    development: isDevelopmentMenuEnabled(state),
    canPartialSync,
    canCreateSection: organizationPolicy.manageSections,
    canMoveProject:
      organizationPolicy.showSections &&
      (organizationPolicy.manageSections || policy.update),
    isOffline: !isOnline(state),
    isUnsynced: !!getRepoIsUnsynced(state, { projectId }),
    isSyncing: getRepoIsSyncing(state, { projectId }),
    isFavoriteProject: isStarred(state, { starrableId: projectId }),
    sectionsLoading: SectionsFetchRequest.isLoading(state, { organizationId }),
    uncommittedChanges: getRepoHasChanges(state, { projectId }),
    currentUserId,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { project } = props;

  return {
    copyProjectId() {
      copyToClipboard && copyToClipboard(project.id);
    },
    openInFinder() {
      openProjectInFinder && openProjectInFinder(project.id);
    },
    openInTerminal() {
      openProjectInTerminal && openProjectInTerminal(project.id);
    },
    onRepack() {
      repackRepo && dispatch(repackRepo(project.id));
    },
    onSyncRepo: async () => {
      pullRepo && (await dispatch(pullRepo(project.id)));
      pushRepo && (await dispatch(pushRepo(project.id)));
    },
    onExportProject() {
      exportProject && dispatch(exportProject(project));
    },
    onExportProjectAsZip() {
      exportRepo && dispatch(exportRepo(project));
    },
    onStopSyncingProject() {
      confirmStopSyncing && dispatch(confirmStopSyncing(project.id));
    },
    onRemoveProjectData() {
      stopSyncingProject && dispatch(stopSyncingProject(project.id));
    },
    onStartSyncingProject() {
      cloneRepo && dispatch(cloneRepo(project.id));
    },
    onLeaveProject() {
      dispatch(leaveProject(project.id));
    },
    onGoToSettings() {
      push(projectPath(project.id, "edit"));
    },
    onGoToActivity() {
      push(projectPath(project.id, "activity"));
    },
    onGoToMembers() {
      dispatch((dispatch, getState) => {
        const canUseTeams = enterpriseTeamsEnabled(getState(), {
          organizationId: project.organizationId,
        });

        push(projectPath(project.id, canUseTeams ? "everyone" : "people"));
      });
    },
    onArchiveProject() {
      dispatch(archiveProject(project));
    },
    onUnarchiveProject() {
      dispatch(unarchiveProject(project));
    },
    addAsFavorite() {
      dispatch(starEntity({ id: project.id, type: "project" }));
    },
    removeAsFavorite() {
      dispatch(unstarEntity({ id: project.id, type: "project" }));
    },
    onMoveProject(sectionId: ?string) {
      dispatch(
        updateProject({
          ...project,
          sectionId: project.sectionId === sectionId ? null : sectionId,
        })
      );
    },
    onLoad() {
      dispatch((dispatch, getState) => {
        const { organizationId } = project;
        const policy = getOrganizationPolicy(getState(), { organizationId });

        if (policy.showSections) {
          dispatch(
            SectionsFetchRequest.perform({
              params: { organizationId },
              force: false,
            })
          );
        }
      });
    },
  };
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default createConnector(
  /* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
   * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
  connect(mapStateToProps, mapDispatchToProps),
  withData((props) => ({
    projectId: props.project.id,
    organizationId: props.project.organizationId,
    canMoveProject: props.canMoveProject,
  }))
);
