// @flow
import classnames from "classnames";
import * as React from "react";
import ChangeStatusIcon from "core/components/ChangeStatusIcon";
import style from "core/components/FileTree/Label/style.scss";
import Flex from "core/components/Flex";
import Icon from "core/components/Icon";
import Popover from "core/components/Popover";
import * as Change from "core/models/change";

type Props = {
  name: string,
  children?: React.Node,
  hasComments?: boolean,
  isStyleLabel?: boolean,
  isLight?: boolean,
  isOpening?: boolean,
  isSelected?: boolean,
  warning?: string,
  info?: string,
  status?: string,
  disabled?: boolean,
  innerRef?: React.Ref<"span">,
};

export default function Label(props: Props) {
  let status = props.status ? Change.status(props.status) : undefined;
  if (status === "edited") {
    status = "changed";
  }
  return (
    <span
      title={props.name}
      className={classnames(style.label, {
        [style.disabled]: props.disabled,
        [style.opening]: props.isOpening,
        [style.styleLabel]: props.isStyleLabel,
      })}
      ref={props.innerRef}
    >
      <span className={style.name}>{props.children || props.name}</span>
      <Flex align="center" justify="flex-end">
        {props.hasComments && (
          <Icon
            type="comment-new-small"
            light={props.isLight}
            className={style.icon}
          />
        )}
        {props.warning && (
          <Popover label={props.warning} placement="top">
            <Icon
              type="warning"
              warning={!props.isSelected}
              className={style.icon}
              light={props.isLight}
            />
          </Popover>
        )}
        {props.info && (
          <Popover label={props.info} placement="top">
            <Icon type="info" className={style.icon} light={props.isLight} />
          </Popover>
        )}
        {props.status && (
          <ChangeStatusIcon
            change={props.status}
            light={props.isLight}
            title={`This file was ${status}`}
            tooltip={{ placement: "top" }}
            className={style.statusIcon}
          />
        )}
      </Flex>
    </span>
  );
}
