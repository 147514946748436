// @flow
import empty from "empty";
import type { State } from "core/types";

export function getEntity({ entities }: State, type: string, id: string) {
  return entities[type] && entities[type][id];
}

export function getRawEntities({ entities }: State, type: string) {
  return entities[type] ? entities[type] : empty.object;
}
