// @flow
import classnames from "classnames";
import * as React from "react";
import KeyboardNavigation from "../KeyboardNavigation";
import style from "./style.scss";

type Props = {
  children: React.Node,
  activePath?: string,
  className?: string,
  defaultFocused?: boolean,
  disabled?: boolean,
  large?: boolean,
  mobile?: boolean,
};

export default function Menu({
  children,
  className,
  defaultFocused,
  activePath,
  disabled,
  large,
  mobile,
  ...rest
}: Props) {
  return (
    <KeyboardNavigation defaultFocused={defaultFocused}>
      {({ selectedClassName, disclosureClassName, isFocused }) => (
        <div {...rest} className={classnames(className, style.menu)}>
          {React.Children.map(children, (child) => {
            if (child && typeof child !== "boolean") {
              return React.cloneElement(child, {
                large,
                mobile,
                disabled,
                activePath,
                selectedClassName,
                disclosureClassName,
                isFocused,
              });
            }
          })}
        </div>
      )}
    </KeyboardNavigation>
  );
}
