// @flow
import classnames from "classnames";
import * as React from "react";
import { CSSTransition } from "react-transition-group/cjs";
import Floating from "core/components/Floating";
import useOuterClick from "core/hooks/useOuterClick";
import style from "./style.scss";

export type AnchorPosition = "right" | "left" | "center" | "topLeft";

type Props = {
  children: React.Node,
  body: React.Node,
  className?: string,
  isOpen: boolean,
  onRequestClose: () => void,
  onClickOutside: (event: MouseEvent) => void,
  scrollable?: boolean,
  wrapperClass?: string,
  floatingWrapper?: boolean,
  anchor?: AnchorPosition,
  qaSelector?: string,
};

export default function AnimatedFlyover(props: Props) {
  const {
    body,
    children,
    anchor = "right",
    className,
    floatingWrapper,
    scrollable,
    isOpen,
    wrapperClass,
    onClickOutside,
    qaSelector,
  } = props;

  const onOuterClick = React.useCallback(onClickOutside, []); // eslint-disable-line react-hooks/exhaustive-deps

  const ref = useOuterClick({ isOpen, onOuterClick });

  const isAnchorCenter = anchor === "center";

  const centerTransition = {
    enter: style.animatedFlyoverCenterEnter,
    exit: style.animatedFlyoverCenterLeave,
    enterActive: style.animatedFlyoverCenterEnterActive,
    exitActive: style.animatedFlyoverCenterLeaveActive,
  };

  const transition = {
    enter: style.animatedFlyoverEnter,
    exit: style.animatedFlyoverLeave,
    enterActive: style.animatedFlyoverEnterActive,
    exitActive: style.animatedFlyoverLeaveActive,
  };

  const cssTransitionNodeRef = React.useRef<*>();

  return (
    <div className={classnames(style.wrapper, wrapperClass)} ref={ref}>
      {children}

      <CSSTransition
        classNames={isAnchorCenter ? centerTransition : transition}
        timeout={{ enter: 150, exit: 75 }}
        in={isOpen}
        nodeRef={cssTransitionNodeRef}
      >
        <div
          ref={cssTransitionNodeRef}
          className={classnames(style.animatedFlyoverContainer, {
            [style.animatedFlyoverLeftContainer]:
              anchor === "left" || anchor === "topLeft",
          })}
        >
          {isOpen && (
            <Floating
              key="animated-flyover"
              className={classnames(
                style.flyoverContainer,
                anchor ? style[anchor] : undefined,
                className
              )}
              scrollable={scrollable}
              onRequestClose={props.onRequestClose}
              wrapper={floatingWrapper}
              qaSelector={qaSelector}
            >
              {body}
            </Floating>
          )}
        </div>
      </CSSTransition>
    </div>
  );
}
