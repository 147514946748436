// @flow
import empty from "empty";
import * as React from "react";
import { connect } from "react-redux";
import Loaded from "core/components/Loaded";
import type { State, Dispatch } from "core/types";
import { resetPasswordFormSubmit } from "web/actions/passwordForm";
import { checkResetPasswordToken } from "web/actions/resetPassword";
import ResetPassword, {
  type FormValues,
} from "web/components/Onboarding/ResetPassword";

type OwnProps = {|
  params: { id: string },
|};

type StateProps = {|
  token: string,
  isValidToken: boolean,
  isValidating: boolean,
|};

type DispatchProps = {|
  checkResetPasswordToken: (id: string) => void,
  resetPasswordFormSubmit: (
    formId: string,
    token: string,
    values: FormValues
  ) => void,
|};

type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

class ResetPasswordContainer extends React.Component<Props> {
  componentDidMount() {
    this.props.checkResetPasswordToken(this.props.params.id);
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.params.id !== this.props.params.id) {
      this.props.checkResetPasswordToken(this.props.params.id);
    }
  }

  render() {
    return (
      <Loaded loading={this.props.isValidating}>
        {() => (
          <ResetPassword
            token={this.props.token}
            form={empty.object}
            invalidToken={!this.props.isValidToken}
            onSubmit={this.props.resetPasswordFormSubmit}
          />
        )}
      </Loaded>
    );
  }
}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  return {
    token: props.params.id,
    isValidToken: state.resetPassword.isValidToken,
    isValidating: state.resetPassword.isValidating,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    checkResetPasswordToken: (id) => {
      dispatch(checkResetPasswordToken(id));
    },
    resetPasswordFormSubmit: (formId, token, values) => {
      dispatch(resetPasswordFormSubmit(formId, token, values));
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordContainer);
