// @flow
import * as React from "react";
import style from "./style.scss";

type Props = {|
  children: React.Node,
|};

export default function StickyHeader(props: Props) {
  return <div className={style.stickyHeader}>{props.children}</div>;
}
