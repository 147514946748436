// @flow
/* global window, HTMLElement, SVGElement */
import classnames from "classnames";
import * as React from "react";
import uuid from "uuid";
import Icon from "core/components/Icon";
import Validations from "core/validations";
import connectStorage from "../../hocs/connectStorage";
import eventInInput from "../../lib/eventInInput";
import KeyCode from "../../lib/keycode";
import type { Annotation } from "../../types";
import AnnotationBubble from "../AnnotationBubble";
import Button from "../Button";
import Header from "../Header";
import InputRich from "../InputRich";
import MarkdownHelp from "../MarkdownHelp";
import Popover from "../Popover";
import style from "./style.scss";

export type OwnProps = {|
  formId: string,
  parentId?: string,
  online: boolean,
  collapsible?: boolean,
  disabled?: boolean,
  body?: string,
  placeholder?: string,
  hasCancel?: boolean,
  onSubmit?: (body: string) => void,
  onCancel?: () => void,
  onReset?: (formId?: string) => void,
  onRequestAnnotationRemoval?: () => void,
  onFocus?: (opts?: Object) => void,
  onBlur?: (opts?: Object) => void,
  hasError?: boolean,
  isLoading?: boolean,
  isAnnotatable?: boolean,
  flat?: boolean,
  inline?: boolean,
  isReply?: boolean,
  autoFocus?: boolean,
  className?: string,
  minHeight?: number,
  annotation?: Annotation,
  submitButtonText?: string,
  submitButton?: React.Element<typeof Button>,
  innerRef?: React.Ref<"div">,
  defaultFocus?: boolean,
  showIndicatorOn?: "focus" | "always",
  indicator?: React.Node,
  isPubliclyShared?: boolean,
  renderActionsInsideTextarea?: boolean,
  projectId: string,
  collectionId?: string,
  toggleNewAnnotation?: boolean,
  onChange?: (formId: string, params: Object) => void,
  qaSelector?: string,
|};

type StorageProps = {|
  ref?: React.Ref<"div">,
  defaultValue?: string,
  onChange?: (formId: string, params: Object) => void,
|};

type Props = {
  ...OwnProps,
  ...StorageProps,
};

type State = {
  edited: boolean,
  focused: boolean,
  collapsed: boolean,
  body: string,
  submittedBody: string,
};

const noop = () => {};

class CommentForm extends React.Component<Props, State> {
  input: *;
  form: *;

  static defaultProps = {
    submitButtonText: "Comment",
    collapsible: false,
    hasError: false,
    hasCancel: false,
    autoFocus: false,
    defaultFocus: false,
    isLoading: false,
    isReply: false,
    isAnnotatable: false,
    body: "",
    onCancel: noop,
    onFocus: noop,
  };

  constructor(props: Props) {
    super(props);
    const defaultValue = props.body || props.defaultValue || "";
    const hasAnnotation = !!(props.annotation && props.annotation.number);

    this.state = {
      edited: false,
      focused: false,
      body: defaultValue,
      submittedBody: "",
      collapsed: !!props.collapsible && !defaultValue && !hasAnnotation,
    };
  }

  componentDidMount() {
    window.addEventListener("keydown", this.handleWindowKeyDown);

    if (this.props.collapsible) {
      window.addEventListener("click", this.handleGlobalClick);

      if (!this.state.collapsed && this.props.onFocus) {
        this.props.onFocus();
      }
    }
  }

  componentDidUpdate(prevProps: Props) {
    const annotation = this.props.annotation && this.props.annotation.number;
    const prevAnnotation = prevProps.annotation && prevProps.annotation.number;

    if (
      !this.isDisabled &&
      this.state.edited &&
      annotation &&
      !prevAnnotation
    ) {
      /*
        If we're editing and just made a new annotation, we want to focus the input.
      */
      if (this.input) {
        this.input.focus();
      }
    } else if (
      this.props.collapsible &&
      !this.state.edited &&
      annotation !== prevAnnotation
    ) {
      /*
        If we haven't edited anything but the annotation has changed, that means
        we just loaded new form data. Which means we need to possibly update the
        form's scroll position and whether or not it should be collapsed.
      */
      this.setState(
        {
          collapsed:
            !this.props.body && !this.props.defaultValue && !annotation,
        },
        () => {
          if (this.props.onFocus) {
            this.props.onFocus();
          }
        }
      );
    }

    if (
      prevProps.toggleNewAnnotation !== this.props.toggleNewAnnotation &&
      this.props.toggleNewAnnotation
    ) {
      this.handleAddAnnotation();
    }

    if (!prevProps.hasError && this.props.hasError) {
      this.handleChange(this.state.submittedBody);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleWindowKeyDown);

    if (this.props.collapsible) {
      window.removeEventListener("click", this.handleGlobalClick);
    }
  }

  focus = () => {
    if (this.input) {
      this.input.focus();
    }
  };

  blur = () => {
    if (this.input) {
      this.input.blur();
    }
    this.handleCollapse();
  };

  eventInForm = (ev: SyntheticEvent<>) => {
    const form = this.form;
    const target = ev.target;
    return !!(
      form instanceof HTMLElement &&
      // $FlowFixMe — SVGElement not recognized https://github.com/facebook/flow/issues/2332
      (target instanceof SVGElement || target instanceof HTMLElement) &&
      form.contains(target)
    );
  };

  handleWindowKeyDown = (ev: SyntheticKeyboardEvent<*>) => {
    if (eventInInput(ev)) {
      if (this.eventInForm(ev)) {
        this.handleFocus();
      }
      return;
    }
    if (ev.keyCode !== KeyCode.KEY_A) {
      return;
    }
    if (ev.ctrlKey) {
      return;
    }
    if (!this.props.isAnnotatable) {
      return;
    }

    // If there is no annotation, create one
    if (!this.props.annotation) {
      this.handleAddAnnotation();
      return;
    }

    // If there is an annotation but it has no coordinates (we didn't draw
    // anything) then treat it like a toggle
    if (this.props.annotation && !this.props.annotation.x) {
      this.handleRemoveAnnotation();
    }
  };

  handleKeyDown = (ev: KeyboardEvent) => {
    switch (ev.keyCode) {
      case KeyCode.KEY_ESCAPE: {
        ev.stopPropagation();
        this.handleCancel();
        break;
      }
      case KeyCode.KEY_DELETE:
      case KeyCode.KEY_BACK_SPACE: {
        if (!this.state.body) {
          this.handleRemoveAnnotation();
        }
        break;
      }
      default:
    }
  };

  handleCancel = () => {
    if (this.props.onReset) {
      this.props.onReset(this.props.formId);
    }
    if (this.props.onCancel) {
      this.props.onCancel();
    }

    // empty the form and then blur the field to trigger a collapse
    if (this.state.body) {
      this.handleChange("", this.blur);
    } else {
      this.blur();
    }
  };

  get placeholder(): string {
    if (this.props.online) {
      return this.props.placeholder || "Leave a comment…";
    }

    return "Commenting not available offline.";
  }

  get error(): ?string {
    if (this.props.hasError) {
      return "Sorry, an error occurred while saving. Try again?";
    }

    return null;
  }

  get isDisabled(): boolean {
    return !this.props.online || this.props.disabled || !!this.props.isLoading;
  }

  get isInvalid(): boolean {
    return !this.state.body;
  }

  get indicator(): React.Node {
    return this.props.indicator
      ? this.props.indicator
      : this.useDefaultIndicator();
  }

  useDefaultIndicator() {
    return this.props.isPubliclyShared ? (
      <div className={style.indicator}>
        <Icon type="public-small" className={style.indicatorIcon} />
        <span className={style.indicatorText}>
          Anyone with the link can see comments on this Artboard
        </span>
      </div>
    ) : null;
  }

  handleChange = (body: string, callback: *) => {
    const { formId, onChange, annotation } = this.props;
    if (body === this.state.body) {
      return;
    }

    this.setState({ body, edited: true }, callback);
    if (!onChange) {
      return;
    }

    onChange(formId, { body, annotation });
  };

  handleAddAnnotation = (e) => {
    if (e) {
      e.stopPropagation();
    }

    const { formId, onChange } = this.props;
    this.setState({ edited: true });
    if (!onChange) {
      return;
    }

    onChange(formId, {
      body: this.state.body,
      annotation: { id: uuid.v4() },
    });
  };

  handleRemoveAnnotation = async (event?: SyntheticEvent<*>) => {
    if (event) {
      event.stopPropagation();
    }

    let canRemove = true;
    const { onRequestAnnotationRemoval, onChange } = this.props;

    if (onRequestAnnotationRemoval) {
      canRemove = await onRequestAnnotationRemoval();
    }

    if (canRemove && onChange) {
      onChange(this.props.formId, {
        body: this.state.body,
        annotation: undefined,
      });
    }
  };

  handleSubmit = (event: SyntheticEvent<*>) => {
    event.stopPropagation();
    event.preventDefault();

    const { body } = this.state;

    this.setState(
      {
        body: "",
        submittedBody: body,
      },
      () => {
        if (this.props.onChange) {
          this.props.onChange(this.props.formId, {
            body: this.state.body,
            annotation: undefined,
          });
        }

        if (this.props.onSubmit) {
          this.props.onSubmit(body);
        }

        // HACK: if input is focused then blur function triggers onChange function
        // it's necessary to wait for Input to receive state update to prevent working with stale input
        setTimeout(this.blur);

        if (this.props.collapsible) {
          this.setState({ collapsed: true });
        }
      }
    );
  };

  handleFocus = () => {
    this.setState({ focused: true });

    if (!this.props.collapsible || this.isDisabled) {
      return;
    }

    if (this.state.collapsed) {
      this.setState({ collapsed: false }, this.handleFocusIfNeeded);
    } else {
      this.handleFocusIfNeeded();
    }
  };

  handleFocusIfNeeded = () => {
    if (this.props.onFocus) {
      this.props.onFocus({ scrollMode: "if-needed", behavior: "auto" });
    }
  };

  handleCollapse = () => {
    if (
      this.props.collapsible &&
      !this.state.collapsed &&
      !this.state.body &&
      !this.props.annotation &&
      this.props.online
    ) {
      this.setState({ collapsed: true, focused: false });
    }
  };

  handleBlur = () => {
    this.setState({ focused: false });
  };

  // Ignore very first click.
  handleGlobalClick = (event: SyntheticEvent<>) => {
    if (!this.eventInForm(event)) {
      this.handleCollapse();
    }
  };

  renderAnnotation() {
    const { annotation, isAnnotatable } = this.props;
    const disabled = this.isDisabled;

    if (!isAnnotatable || (this.state.collapsed && disabled)) {
      return null;
    }

    if (annotation && annotation.number) {
      return (
        <div className={style.annotationBubble}>
          <Popover
            key="remove"
            trigger="hover"
            placement="top"
            label="Remove annotation"
          >
            <AnnotationBubble
              disabled={disabled}
              number={annotation.number || 1}
              onClick={this.handleRemoveAnnotation}
              removable
              size="small"
            />
          </Popover>
        </div>
      );
    }

    return (
      <div className={style.annotation}>
        <Button
          icon="annotation-add"
          onClick={
            annotation ? this.handleRemoveAnnotation : this.handleAddAnnotation
          }
          type="button"
          nude
          tint={!!annotation && !disabled}
          disabled={disabled}
          title={!annotation ? "Add annotation (a)" : undefined}
          tooltip={!annotation && { placement: "top", key: "add" }}
          children={this.state.collapsed ? undefined : "Annotate"}
          qaSelector="createAnnotationButton"
        />
      </div>
    );
  }

  renderSubmitButton = () => {
    const { isReply, submitButton, submitButtonText } = this.props;
    const disabled = this.isDisabled || this.isInvalid;

    if (submitButton) {
      return React.cloneElement(submitButton, { disabled, type: "submit" });
    }

    return (
      <Button
        primary
        type="submit"
        disabled={disabled}
        qaSelector={isReply ? "submitReplyButton" : "submitCommentButton"}
      >
        {submitButtonText}
      </Button>
    );
  };

  setFormRef = (ref) => {
    this.form = ref;
  };

  setInputRef = (ref) => {
    this.input = ref;
  };

  render() {
    const classes = classnames(
      style.form,
      {
        [style.flat]: this.props.flat,
        [style.inline]: this.props.inline,
        [style.collapsible]: this.props.collapsible,
        [style.collapsed]: this.state.collapsed,
        [style.disabled]: this.isDisabled || !this.props.online,
        [style.focus]: this.props.defaultFocus && this.state.focused,
      },
      this.props.className
    );

    const { renderActionsInsideTextarea } = this.props;

    return (
      <form
        className={classes}
        onSubmit={this.handleSubmit}
        ref={this.setFormRef}
      >
        {this.props.showIndicatorOn === "always" || !this.state.collapsed
          ? this.indicator
          : null}
        <span
          className={classnames({
            [style.inputWrapper]: true,
            [style.inputWrapperAlignedWithActions]: renderActionsInsideTextarea,
          })}
        >
          <InputRich
            ref={this.setInputRef}
            disabled={this.isDisabled}
            placeholder={this.placeholder}
            value={this.state.body}
            onKeyDown={this.handleKeyDown}
            onSubmit={this.handleSubmit}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            error={this.error}
            minHeight={this.props.minHeight}
            minLength={Validations.minCommentLength}
            maxLength={Validations.maxCommentLength}
            autoFocus={this.props.autoFocus}
            className={style.input}
            disabledClass={style.disabled}
            focusClass={this.props.flat ? undefined : style.focused}
            projectId={this.props.projectId}
            fixedSuggestions
            qaSelector={this.props.qaSelector}
            required
          />
          {this.state.collapsed && this.renderAnnotation()}
        </span>
        {this.props.online && (
          <div
            className={classnames({
              [style.actions]: true,
              [style.actionsInsideForm]: renderActionsInsideTextarea,
            })}
          >
            <Header
              left={
                <React.Fragment>
                  {this.renderAnnotation()}
                  {this.props.hasCancel && (
                    <Button
                      type="button"
                      onClick={this.handleCancel}
                      className={style.cancel}
                      qaSelector="cancel-button"
                    >
                      Cancel
                    </Button>
                  )}
                </React.Fragment>
              }
              right={
                <div className={style.submitComment}>
                  <Popover
                    trigger="click"
                    placement="top"
                    body={<MarkdownHelp />}
                  >
                    <Button
                      icon="markdown"
                      type="button"
                      nude
                      qaSelector="markdown-help-button"
                    />
                  </Popover>
                  {this.renderSubmitButton()}
                </div>
              }
            />
          </div>
        )}
      </form>
    );
  }
}

export default connectStorage<OwnProps>(CommentForm, (storage, props) => {
  const key = `CommentForm-${props.formId || ""}`;

  return {
    ref: props.innerRef,
    defaultValue: storage.getItem(key),
    onChange: (formId, params) => {
      storage.setItem(key, params.body);
      if (props.onChange) {
        props.onChange(formId, params);
      }
    },
  };
});
