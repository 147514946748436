// @flow
import invariant from "invariant";
import { connect } from "react-redux";
import { stopSyncingProject } from "abstract-di/actions";
import { dismissDialog } from "core/actions/dialogs";
import type { State, Dispatch } from "core/types";
import type { Props, OwnProps, DispatchProps } from ".";

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    stopSyncingProject: () => {
      invariant(
        stopSyncingProject,
        "stopSyncingProject required for dialog action"
      );
      dispatch(stopSyncingProject(props.projectId));
    },
    dismissDialog: () => dispatch(dismissDialog()),
  };
}

export default connect<Props, OwnProps, _, DispatchProps, State, Dispatch>(
  undefined,
  mapDispatchToProps
);
