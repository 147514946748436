// @flow
import apiRequest from "abstract-di/api";
import { entitiesReceived, entitiesDeleted } from "core/actions/entities";
import { setPaginationTotal } from "core/actions/paginationTotals";
import { showToast } from "core/actions/toasts";
import { ValidationError } from "core/api";
import { Abstract } from "core/lib/abstract";
import {
  normalizeTeamResponse,
  normalizeTeamsFetchResponse,
} from "core/schemas/team";
import type {
  Dispatch,
  OrganizationTeamDescriptor,
  OrganizationTeamsParams,
  PaginatedOrganizationTeamsParams,
  PaginatedProjectTeamsParams,
  TeamUpdateParams,
} from "core/types";
import defineRequest from "./";

type CreateParams = {
  name: string,
  color: string,
  ...Abstract.OrganizationDescriptor,
};

export const TeamCreateRequest = defineRequest<
  CreateParams,
  { ...Abstract.OrganizationDescriptor },
>({
  id: (params) => {
    return `post:organizations/${params.organizationId}/teams`;
  },
  perform: (params) => {
    const { organizationId, ...team } = params;
    return apiRequest("post", `organizations/${organizationId}/teams`, team);
  },
  onSuccess: (response, params, dispatch) => {
    const { entities } = normalizeTeamResponse(response);
    dispatch(entitiesReceived(entities));
    // Reset paginationTotals for All Teams
    dispatch(
      PaginatedOrganizationTeamsRequest.perform({
        params: { organizationId: params.organizationId, limit: 0, offset: 0 },
      })
    );
  },
});

function recursiveOnSuccess(response, params, dispatch) {
  if (
    Array.isArray(response.data.teams) &&
    response.data.teams.length === params.limit
  ) {
    const newParams = {
      ...params,
      offset: params.offset + params.limit,
    };
    return dispatch(
      PaginatedOrganizationTeamsRequest.perform({
        params: newParams,
        onSuccess: (response) =>
          recursiveOnSuccess(response, newParams, dispatch),
      })
    );
  }
}

export const TeamsFetchAllRequest = defineRequest<
  Abstract.OrganizationDescriptor,
  Abstract.OrganizationDescriptor,
>({
  id: (params) => {
    const { organizationId } = params;
    return `get:organizations/${organizationId}/teams`;
  },
  perform(params, dispatch, getState) {
    const paginatedParams = { ...params, limit: 100, offset: 0 };

    return dispatch(
      PaginatedOrganizationTeamsRequest.perform({
        params: paginatedParams,
        onSuccess: (response) => {
          recursiveOnSuccess(response, paginatedParams, dispatch);
        },
      })
    );
  },
  force: false,
  invalidateable: true,
});

export const PaginatedOrganizationTeamsRequest = defineRequest<
  PaginatedOrganizationTeamsParams,
  PaginatedOrganizationTeamsParams,
>({
  id: (params) => {
    const { organizationId, limit, offset, search = "", userId = "" } = params;
    return `get:organizations/${organizationId}/teams/limit/${limit}/offset/${offset}/search/${search}/userId/${userId}`;
  },
  perform: (params) => {
    return apiRequest("get", `organizations/${params.organizationId}/teams`, {
      limit: params.limit,
      offset: params.offset,
      search: params.search,
      userId: params.userId,
    });
  },
  onSuccess: (
    response,
    params: PaginatedOrganizationTeamsParams,
    dispatch: Dispatch
  ) => {
    dispatch(
      setPaginationTotal(
        `${params.organizationId}-AllTeams`,
        response.meta.total
      )
    );
    const normalizedTeams = normalizeTeamsFetchResponse(response);
    dispatch(entitiesReceived(normalizedTeams.entities));
  },
  invalidateable: true,
});

// With pagination omitted, this request will return a default limit of 50 entries.
export const OrganizationTeamsFetchRequest = defineRequest<
  OrganizationTeamsParams,
  OrganizationTeamsParams,
>({
  id: (params) => {
    const { organizationId, search = "", userId = "" } = params;
    return `get:organizations/${organizationId}/teams?search=${search}&userId=${userId}`;
  },
  perform: (params) => {
    return apiRequest("get", `organizations/${params.organizationId}/teams`, {
      search: params.search,
      userId: params.userId,
    });
  },
  onSuccess: (
    response,
    params: OrganizationTeamsParams,
    dispatch: Dispatch
  ) => {
    dispatch(
      setPaginationTotal(
        `${params.organizationId}-AllTeams`,
        response.meta.total
      )
    );
    const normalizedTeams = normalizeTeamsFetchResponse(response);
    dispatch(entitiesReceived(normalizedTeams.entities));
  },
  invalidateable: true,
});

export const OrganizationTeamFetchRequest = defineRequest<
  $ReadOnly<OrganizationTeamDescriptor>,
  $ReadOnly<{ ...OrganizationTeamDescriptor }>,
>({
  id: (params) => {
    return `get:organizations/${params.organizationId}/teams/${params.teamId}`;
  },
  perform: (params) => {
    return apiRequest(
      "get",
      `organizations/${params.organizationId}/teams/${params.teamId}`
    );
  },
  onSuccess: (
    response,
    params: OrganizationTeamDescriptor,
    dispatch: Dispatch
  ) => {
    const { entities } = normalizeTeamResponse(response);
    dispatch(entitiesReceived(entities));
  },
});

export const OrganizationTeamUpdateRequest = defineRequest<
  TeamUpdateParams,
  { ...OrganizationTeamDescriptor },
>({
  id: (params) => {
    return `put:organizations/${params.organizationId}/teams/${params.teamId}`;
  },
  perform: (params) => {
    const { organizationId, ...team } = params;
    return apiRequest(
      "put",
      `organizations/${organizationId}/teams/${params.teamId}`,
      team
    );
  },
  onSuccess: (response, params, dispatch) => {
    const { entities } = normalizeTeamResponse(response);
    dispatch(entitiesReceived(entities));
    dispatch(showToast({ text: "Team updated" }));
  },
  onError: (error, params, dispatch) => {
    if (error instanceof ValidationError) {
      dispatch(
        showToast({
          text: "An error ocurred",
          subtext: error.validationErrors.name[0],
          icon: "warning",
        })
      );
    }
  },
});

export const OrganizationTeamDeleteRequest = defineRequest<
  $ReadOnly<OrganizationTeamDescriptor>,
  $ReadOnly<{ ...OrganizationTeamDescriptor }>,
>({
  id: (params) => {
    return `delete:organizations/${params.organizationId}/teams/${params.teamId}`;
  },
  perform: (params) => {
    return apiRequest(
      "delete",
      `organizations/${params.organizationId}/teams/${params.teamId}`
    );
  },
  onSuccess: (response, params, dispatch) => {
    dispatch(entitiesDeleted({ teams: [params.teamId] }));
    // Reset paginationTotals for All Teams
    dispatch(
      PaginatedOrganizationTeamsRequest.perform({
        params: { organizationId: params.organizationId, limit: 0, offset: 0 },
      })
    );
    dispatch(showToast({ text: "Team deleted" }));
  },
});

export const PaginatedProjectTeamsRequest = defineRequest<
  PaginatedProjectTeamsParams,
  PaginatedProjectTeamsParams,
>({
  id: (params) => {
    const { projectId, limit, offset, search = "", userId = "" } = params;
    return `get:projects/${projectId}/teams/limit/${limit}/offset/${offset}/search/${search}/userId/${userId}`;
  },
  perform: (params) => {
    const { limit, offset, search, userId, projectId } = params;
    return apiRequest("get", `projects/${projectId}/teams`, {
      limit,
      offset,
      search,
      userId,
    });
  },
  onSuccess: (
    response,
    params: PaginatedProjectTeamsParams,
    dispatch: Dispatch
  ) => {
    const normalizedTeams = normalizeTeamsFetchResponse(response);
    dispatch(entitiesReceived(normalizedTeams.entities));
  },
  invalidateable: true,
});
