// @flow
import * as React from "react";
import Button from "core/components/Button";
import Flex from "core/components/Flex";
import window from "core/global/window";
import { isDesktop } from "core/lib/platform";
import style from "./style.scss";
import type { SAMLData } from "./";

type Props = {
  samlData: SAMLData,
  isLoadingAuthCheck: boolean,
  primaryButtonClick: (ev: SyntheticMouseEvent<*>) => Promise<*>,
  secondaryButtonClick: (ev: SyntheticMouseEvent<*>) => void,
  autoLaunch?: boolean,
};

class SAMLRedirect extends React.Component<Props> {
  componentDidMount() {
    if (this.props.autoLaunch) {
      this.launchSamlProvider();
    }
  }

  // this is triggered as a result of the retry button
  // TODO this is a little janky, could just use async/await
  componentDidUpdate(nextProps: Props) {
    if (this.props.isLoadingAuthCheck && !nextProps.isLoadingAuthCheck) {
      this.launchSamlProvider();
    }
  }

  submitRedirectBinding = () => {
    const { url } = this.props.samlData;

    if (isDesktop) {
      window.open(url, "authView", "width=500,height=600,center=true");
      return;
    }

    // On desktop, this is in a new browser window
    // On web, this is in the current window
    window.location.href = url;
  };

  launchSamlProvider = () => {
    this.submitRedirectBinding();
  };

  render() {
    const { primaryButtonClick, secondaryButtonClick } = this.props;

    return (
      <div className={style.ssoWrap}>
        {isDesktop ? (
          <div>
            We’re opening your organization’s authentication provider in another
            window.
          </div>
        ) : (
          <div>
            We’re redirecting you to your organization’s authentication provider
            in your default browser.
          </div>
        )}
        <div className={style.controls}>
          <div className={style.controlsHelperText}>
            Did something go wrong?
          </div>
          <Flex align="center" className={style.buttonRow}>
            <Button
              onClick={secondaryButtonClick}
              tint
              nude
              icon="chevron-large-left"
              className={style.routeButton}
            >
              Go Back
            </Button>
            <Button
              onClick={primaryButtonClick}
              primary
              className={style.routeButton}
            >
              Try Again
            </Button>
          </Flex>
        </div>
      </div>
    );
  }
}

export default SAMLRedirect;
