// @flow
import * as Abstract from "abstract-sdk";
import { pushRepo, updateBranch } from "abstract-di/actions";
import apiRequest from "abstract-di/api";
import { getCurrentUser } from "abstract-di/selectors";
import { entitiesReceived } from "core/actions/entities";
import { showLoadingToast, showCloseableToast } from "core/actions/toasts";
import abstract from "core/lib/abstract";
import { isDesktop } from "core/lib/platform";
import defineRequest from "core/requests";
import {
  normalizeBranches,
  normalizeBranchesResponse,
  normalizeBranchStatusResponse,
  normalizeBranchRestrictionsResponse,
} from "core/schemas/branch";
import { normalizeLocalBranches } from "core/schemas/localBranches";
import { getBranchHead } from "core/selectors/branches";
import {
  canUseAbstractdForFiles,
  canUseTransportPriority,
} from "core/selectors/features";
import { getTransportModeForBranch } from "core/selectors/sdk";
import type { BranchFilter, BranchRestrictions } from "core/types";

const BRANCH_RESTRICTIONS_VERSION = 23;
const PAGE_SIZE = 30;

const branchesFetchId = (
  prefix: string,
  params: $ReadOnly<{
    projectId?: string,
    userId?: string,
    search?: string,
    filter?: BranchFilter,
    offset?: number,
    limit?: number,
  }>
) => {
  return [
    prefix,
    params.projectId,
    params.filter,
    params.search,
    params.offset,
    params.limit,
  ]
    .filter((param) => param !== undefined)
    .join("-");
};

export type BranchesParams = {|
  projectId?: string,
  userId?: string,
  search?: string,
  filter?: BranchFilter,
  offset?: number,
  limit?: number,
|};

export const BranchesFetchRequest = defineRequest<
  BranchesParams,
  BranchesParams,
>({
  id(params) {
    return branchesFetchId("branches", params);
  },
  perform: async (params, dispatch, getState) => {
    const { search, filter, offset, limit, projectId } = params;

    // requests without a projectId must be performed against the API. The CLI
    // does not currently have the ability to search across repos on disk.
    const apiEnabled = projectId
      ? canUseTransportPriority(getState(), { projectId })
      : true;

    const requests = [];
    requests.push(
      new Promise((resolve) => {
        // continue to respect the transport priority feature flag on desktop
        if (!apiEnabled && isDesktop) {
          resolve(undefined);
        }

        abstract.branches
          .list(projectId ? { projectId } : undefined, {
            search,
            filter,
            offset,
            limit,
            transportMode: ["api"],
          })
          .then((result) => resolve(result))
          .catch((error) => {
            if (!isDesktop) {
              throw error;
            }
            resolve(undefined);
          });
      })
    );

    // On desktop, also load branches from the CLI where possible so that we
    // know how the local state compares to the server.
    if (isDesktop && projectId) {
      requests.push(
        new Promise((resolve) => {
          abstract.branches
            .list(
              { projectId },
              {
                filter,
                offset,
                limit,
                transportMode: ["cli"],
              }
            )
            .then((result) => resolve(result))
            .catch((error) => resolve(undefined));
        })
      );
    }

    return Promise.all(requests);
  },
  onSuccess: (responses, params, dispatch) => {
    const [apiResponse, cliResponse] = responses;
    let entities = {
      branches: {},
      localBranches: {},
    };

    if (Array.isArray(apiResponse) && apiResponse.length) {
      const unwrapped = abstract.unwrap(apiResponse);
      const normalizedApiResponse = normalizeBranchesResponse(unwrapped);
      entities = normalizedApiResponse.entities;
    }

    if (Array.isArray(cliResponse) && cliResponse.length) {
      const normalizedCliResponse = normalizeLocalBranches(cliResponse);
      entities.localBranches = normalizedCliResponse.entities.localBranches;
    }

    dispatch(entitiesReceived(entities));
  },
});

export type PaginatedBranchesFetchParams = {
  projectId: string,
  userId?: string,
  search?: string,
  filter?: BranchFilter,
  offset: number,
  limit: number,
};

export const PaginatedBranchesFetchRequest = defineRequest<
  PaginatedBranchesFetchParams,
  PaginatedBranchesFetchParams,
>({
  id(params) {
    return branchesFetchId("paginated-branches", params);
  },
  async perform(params, dispatch) {
    const [apiResponse, cliResponse] = await dispatch(
      BranchesFetchRequest.perform({ params: { ...params } })
    );

    return apiResponse || cliResponse;
  },
  invalidateable: true,
});

function recursiveOnSuccess(responses, params, dispatch) {
  const [apiResponse] = responses;

  if (Array.isArray(apiResponse) && apiResponse.length === params.limit) {
    const newParams = {
      ...params,
      offset: params.offset + params.limit,
    };

    return dispatch(
      BranchesFetchRequest.perform({
        params: newParams,
        onSuccess: (responses) =>
          recursiveOnSuccess(responses, newParams, dispatch),
      })
    );
  }
}

export type BranchesFetchAllParams = {|
  projectId: string,
  userId?: string,
  search?: string,
  filter?: BranchFilter,
|};

export const BranchesFetchAllRequest = defineRequest<
  BranchesFetchAllParams,
  BranchesFetchAllParams,
>({
  id(params) {
    return branchesFetchId("branches-all", params);
  },
  perform(params, dispatch, getState) {
    const paginatedParams = { ...params, limit: PAGE_SIZE, offset: 0 };

    return dispatch(
      BranchesFetchRequest.perform({
        params: paginatedParams,
        onSuccess: (response) => {
          recursiveOnSuccess(response, paginatedParams, dispatch);
        },
      })
    );
  },
  force: false,
});

export const BranchFetchRequest = defineRequest<
  Abstract.BranchDescriptor,
  Abstract.BranchDescriptor,
>({
  id(params) {
    return `branch-${params.projectId}-${params.branchId}`;
  },
  perform: async (
    descriptor: Abstract.BranchDescriptor,
    dispatch,
    getState
  ) => {
    return abstract.branches.info(descriptor, {
      transportMode: getTransportModeForBranch(getState(), {
        projectId: descriptor.projectId,
        branchId: descriptor.branchId,
      }),
    });
  },
  onSuccess(branch, { projectId }, dispatch) {
    // Note: The unwrapped response payload from API/CLI is different so we
    // cannot rely on a single branchResponse normalizer here
    const { policies } = abstract.unwrap(branch);

    // We're using the existence of policies as a proxy for whether the response
    // came from the API here
    if (policies) {
      const { entities } = normalizeBranches([branch]);
      entities.policies = policies;
      dispatch(entitiesReceived(entities));
    } else {
      const { entities } = normalizeLocalBranches([branch]);
      dispatch(entitiesReceived(entities));
    }

    if (branch.parent) {
      dispatch(
        BranchFetchRequest.perform({
          params: { projectId, branchId: branch.parent },
        })
      );
    }
  },
  force: false,
});

export const BranchRestrictionsFetchRequest = defineRequest<
  Abstract.BranchDescriptor,
  Abstract.BranchDescriptor,
>({
  id(params) {
    return `get:branch-restrictions-${params.projectId}-${params.branchId}`;
  },
  perform(params) {
    return apiRequest(
      "get",
      `projects/${params.projectId}/branches/${params.branchId}/restrictions`,
      undefined,
      BRANCH_RESTRICTIONS_VERSION
    );
  },
  onSuccess: (response, params, dispatch) => {
    const { entities } = normalizeBranchRestrictionsResponse(response);
    dispatch(entitiesReceived(entities));
  },
});

type RestrictionsUpsertParams = $Shape<BranchRestrictions>;
export const BranchRestrictionsUpsertRequest = defineRequest<
  RestrictionsUpsertParams,
  RestrictionsUpsertParams,
>({
  id(params) {
    return `post:branch-restrictions-${params.projectId}-${params.branchId}`;
  },
  perform(params) {
    return apiRequest(
      "post",
      `projects/${params.projectId}/branches/${params.branchId}/restrictions`,
      params,
      BRANCH_RESTRICTIONS_VERSION
    );
  },
  onSuccess: (response, params, dispatch) => {
    const { entities } = normalizeBranchRestrictionsResponse(response);
    dispatch(entitiesReceived(entities));
  },
});

type BranchStatusParams = {|
  ...Abstract.BranchDescriptor,
  parentId?: string,
|};

export const BranchStatusFetchRequest = defineRequest<
  BranchStatusParams,
  BranchStatusParams,
>({
  id(params) {
    return `get:branch-status-${params.projectId}-${params.branchId}`;
  },
  perform(params) {
    const version = 17;

    return apiRequest(
      "get",
      `projects/${params.projectId}/branches/${params.branchId}/status`,
      {
        parentId: params.parentId,
      },
      version
    );
  },
  onSuccess: (response, params, dispatch) => {
    const { entities } = normalizeBranchStatusResponse(response);
    dispatch(entitiesReceived(entities));
  },
});

type BranchParams = {|
  ...Abstract.BranchDescriptor,
  name: string,
  description: string,
  status: string,
  options?: { onError: () => void },
|};

export const BranchUpdateRequest = defineRequest<
  BranchParams,
  { ...Abstract.BranchDescriptor },
>({
  id(params) {
    return `put:branch-update-${params.projectId}-${params.branchId}`;
  },
  perform: async function (params, dispatch, getState) {
    const currentUser = getCurrentUser(getState());
    const { projectId, branchId } = params;

    const transportMode = getTransportModeForBranch(getState(), {
      projectId,
      branchId,
    });

    const useAbstractd = canUseAbstractdForFiles(getState(), params.projectId);

    // This is unfortunate, but in an unsynced project scenario, the cli lib gives especial treatment
    // to the request, and we end up obtaining a different response object, and in order to persist
    // that flow, we need to fall back on the old updateBranch request whenever we update from desktop

    if (updateBranch && transportMode[0] === "cli") {
      let toast;

      const branch = await updateBranch(
        params.projectId,
        params.branchId,
        {
          name: params.name,
          status: params.status,
          description: params.description,
        },
        useAbstractd,
        (percentage, message) => {
          const details = {
            icon: "branch",
            text: `Updating ${params.name}`,
            subtext: `Syncing ${message.entityType}…`,
            progress: percentage,
          };
          if (!toast) {
            toast = dispatch(showLoadingToast(details));
          } else {
            toast.update(details);
          }
        }
      );

      if (toast) {
        toast.update({
          progress: 100,
          subtext: "Branch updated",
          autoClose: 3500,
        });
      }

      return branch;
    } else {
      const response = await abstract.branches.update(
        { projectId, branchId },
        {
          name: params.name,
          description: params.description,
          status: params.status,
        },
        {
          transportMode,
          user: currentUser || undefined,
        }
      );

      return response;
    }
  },
  onSuccess: async (response, params, dispatch, getState) => {
    const { projectId, branchId } = params;
    const state = getState();
    const branchHead = getBranchHead(state, { projectId, branchId });

    const branch = response;

    // This is unfortunate, but in a quick sync scenario it is possible that
    // the branch is not locally synced, however we can still update the meta
    // via the CLI as the meta refs are stored separately. In this case the
    // operation succeeds but the CLI returns an empty value for branch head.
    if (!branch.head) {
      branch.head = branchHead;
    }

    // We're using the existence of policies as a proxy for whether the response

    if (response.policies) {
      const { entities } = normalizeBranches([branch]);
      entities.policies = response.policies;
      await dispatch(entitiesReceived(entities));
    } else {
      const { entities } = normalizeLocalBranches([branch]);
      await dispatch(entitiesReceived(entities));
    }

    if (pushRepo) {
      dispatch(pushRepo(projectId, [branch.id]));
    }
  },
  onError: (error, params, dispatch) => {
    if (params.options && params.options.onError) {
      params.options.onError();
    }

    const details = {
      icon: "error",
      text: "Failed to update branch",
      subtext: "A problem occurred while updating the branch",
      progress: undefined,
      closeable: true,
    };

    dispatch(showCloseableToast(details));
  },
});
