// @flow
import * as React from "react";
import CommitHeader from "core/components/CommitHeader";
import CopyLinkButton from "core/components/CopyLinkButton";
import UserNameWithModal from "core/components/UserNameWithModal";
import { CommitTypes } from "core/gitConstants";
import type {
  Comment,
  User,
  Project,
  Page,
  File,
  Commit,
  FilePreviews,
  Changeset,
} from "core/types";
import MergeCommitSummary from "web/containers/MergeSummaryContainer";
import style from "./header.scss";

type Props = {
  commit: Commit,
  comments: Comment[],
  changeset: Changeset,
  user: User,
  project: Project,
  branchId: string,
  filter?: string,
  query?: string,
  previewsByFile: FilePreviews,
  files: File[],
  pages: { [pageId: string]: Page },
  onCommentsClick: () => void,
  onScrollToRow: (params: {
    id: string,
    offset?: number,
    onScrolled?: () => void,
  }) => void,
  canShare: boolean,
};

export default function Header({
  commit,
  comments,
  changeset,
  user,
  project,
  branchId,
  filter,
  query,
  files,
  pages,
  previewsByFile,
  onCommentsClick,
  onScrollToRow,
  canShare,
}: Props) {
  const isMerge = commit.type === CommitTypes.MERGE;
  const inputShare = {
    kind: "commit",
    descriptor: {
      projectId: project.id,
      branchId: branchId,
      sha: commit.sha,
    },
  };

  return (
    <CommitHeader
      onCommentsClick={onCommentsClick}
      onScrollToRow={onScrollToRow}
      commentCount={comments.length}
      commit={commit}
      filter={filter}
      query={query}
      user={user}
      files={files}
      pages={pages}
      previewsByFile={previewsByFile}
      changeset={changeset}
      userName={<UserNameWithModal user={user} className={style.authorName} />}
      actions={
        canShare ? (
          <CopyLinkButton
            organizationId={project.organizationId}
            inputShare={inputShare}
          />
        ) : null
      }
    >
      {isMerge && !!changeset && (
        <MergeCommitSummary
          projectId={project.id}
          branchId={commit.sourceBranchId || ""}
        />
      )}
    </CommitHeader>
  );
}
