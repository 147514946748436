// @flow
import type { LayerData, LayerContext } from "core/types";

export default function layerAcceptsInteraction(
  layerData: LayerData,
  layerContext: LayerContext,
  selectedLayer: ?LayerData,
  ignoreGroups?: boolean
): boolean {
  // Deny hidden layers
  if (!layerData.properties.isVisible || !layerContext.isVisible) {
    return false;
  }

  // Deny locked layers
  if (layerData.properties.isLocked || layerContext.isLocked) {
    return false;
  }

  // Deny slice layers
  if (layerData.type === "slice") {
    return false;
  }

  // Deny groups when command key is press
  if (layerData.type === "group" && ignoreGroups) {
    return false;
  }

  // Deny layers (like groups) with "Select content on click"
  if (layerData.properties.hasClickThrough) {
    return false;
  }

  // Allow text layers
  if (layerData.type === "text") {
    return true;
  }

  // Allow children of root
  if (layerData._path && layerData._path.length === 2) {
    return true;
  }

  // Allow child layers to accept interaction when parent is selected
  if (selectedLayer && layerData.parentId === selectedLayer.id) {
    return true;
  }

  // Allow child layers to accept interaction when sibling is selected
  if (selectedLayer && layerData.parentId === selectedLayer.parentId) {
    return true;
  }

  // Deny mask layers and layers under/affected-by mask
  return (
    !layerData.properties.hasClippingMask &&
    !layerData.properties.underClippingMask
  );
}
