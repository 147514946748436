// @flow
import * as React from "react";
import Button from "core/components/Button";
import { V3Link as Link } from "core/lib/router";
import { organizationPeoplePath } from "core/lib/routes";
import style from "./style.scss";
import Empty from ".";

export default function NoProjects({
  canCreateProjects,
  organizationId,
  createProjectAction,
  isUsernameOrganization,
  ...rest
}: {
  canCreateProjects?: boolean,
  organizationId: string,
  createProjectAction?: () => void,
  isUsernameOrganization?: boolean,
}) {
  return (
    <Empty
      {...rest}
      image={<div className={style.projectImage} />}
      title={canCreateProjects ? "You made it." : "No projects, yet."}
      description={
        <span>
          {canCreateProjects
            ? "Now, create a Project to start designing"
            : "Ask a Contributor to create a Project"}
          <br />
        </span>
      }
      qaSelector="no-projects-block"
    >
      {canCreateProjects ? (
        <Button
          icon="zoom-in"
          organizationId={organizationId}
          onClick={() => {
            createProjectAction && createProjectAction();
          }}
          qaSelector="createProjectButton"
          primary
        >
          Create a Project
        </Button>
      ) : (
        <Button
          icon="user"
          component={Link}
          primary
          to={organizationPeoplePath(organizationId, "contributor")}
          qaSelector="see-contributors-button"
        >
          See Contributors
        </Button>
      )}
    </Empty>
  );
}
