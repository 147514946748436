// @flow
import * as React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group/cjs";
import type { Toast as TToast } from "core/types";
import Toast from "./Toast";
import style from "./style.scss";

type Props = {
  messages: TToast[],
  onDismissMessage: *,
};

export default function Toasts({ messages, onDismissMessage }: Props) {
  const messageRefs = React.useMemo(
    () => Array(messages.length).map(() => React.createRef()),
    [messages]
  );

  return (
    <div>
      <TransitionGroup className={style.messages}>
        {messages.map(
          (
            {
              id,
              text,
              subtext,
              progress,
              closeable,
              autoClose,
              link,
              icon,
              linkLabel,
              onClick,
              onClose,
            },
            i
          ) => (
            <CSSTransition
              classNames={{
                appear: style.messageAppear,
                enter: style.messageEnter,
                exit: style.messageLeave,
                leave: style.messageLeave,
              }}
              appear
              timeout={400}
              nodeRef={messageRefs[i]}
            >
              <li key={id} className={style.message} ref={messageRefs[i]}>
                <Toast
                  autoClose={autoClose}
                  icon={icon}
                  link={link}
                  text={text}
                  subtext={subtext}
                  progress={progress}
                  linkLabel={linkLabel}
                  onClick={onClick}
                  onClose={
                    closeable || autoClose || onClose
                      ? (event) => {
                          onClose && onClose(event);
                          onDismissMessage(id);
                        }
                      : undefined
                  }
                />
              </li>
            </CSSTransition>
          )
        )}
      </TransitionGroup>
    </div>
  );
}
