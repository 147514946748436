// @flow
import pluralize from "pluralize";
import * as React from "react";
import ResizeDetector from "react-resize-detector";
import {
  SortableCollectionThumbnail as CollectionThumbnail,
  Placeholder as CollectionThumbnailPlaceholder,
  CreateCollectionPlaceholder,
} from "core/components/CollectionThumbnail";
import CreateCollectionButton from "core/components/CreateCollectionButton";
import NoCollections from "core/components/Empty/NoCollections";
import Expand from "core/components/Expand";
import Heading from "core/components/Heading";
import OnboardingBubble from "core/components/OnboardingBubble";
import Sortable from "core/components/Sortable";
import type { Branch, Collection } from "core/types";
import connector from "./connector";
import style from "./style.scss";

const minColumnWidth: { ["DESKTOP" | "MOBILE"]: number } = {
  DESKTOP: 200,
  MOBILE: 130,
};

export type OwnProps = {|
  branch: Branch,
  projectId: string,
  mobile?: boolean,
|};

export type StateProps = {|
  isLoadingMoreCollections: boolean,
  isDeletingCollection: boolean,
  totalNumberOfCollections: ?number,
  isLoadingInitialCollections: boolean,
  canCreateCollections: boolean,
  collections: Collection[],
  isOffline: boolean,
|};

export type DispatchProps = {|
  loadInitialCollections: (options: { limit: number }) => void,
  loadMoreCollections: (options: { offset: number, limit?: number }) => void,
  moveCollection: (
    collections: Collection[],
    collection: Collection,
    move: {
      newIndex: number,
      oldIndex: number,
    }
  ) => Promise<void>,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type State = {
  isExpanded: boolean,
  columns: number,
};

class BranchCollectionsSummary extends React.Component<Props, State> {
  isUnmounted: boolean = false;
  container: ?HTMLElement;

  state = {
    isExpanded: false,
    columns: 0,
  };

  // handleResize will trigger when this component mounts, which
  // will update this.state.columns and trigger this componentDidUpdate.
  // Even though this component unmounts on each route transition, we
  // don't use componentDidMount in this case because this.state.columns
  // will not have been calculated yet.
  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.columns !== this.state.columns && this.state.columns > 0) {
      this.props.loadInitialCollections({
        limit: this.getCollapsedCollectionsCount(),
      });
    }

    if (
      !prevState.isExpanded &&
      this.state.isExpanded &&
      !this.isFullyLoaded()
    ) {
      this.props.loadMoreCollections({
        offset: this.props.collections.length,
      });
    }

    // A collection was deleted.
    if (
      prevProps.totalNumberOfCollections &&
      this.props.totalNumberOfCollections &&
      this.props.totalNumberOfCollections < prevProps.totalNumberOfCollections
    ) {
      // If we're not fully loaded, we'll need to load 1 additional
      // collection to fill the place of the deleted one.
      if (!this.isFullyLoaded()) {
        this.props.loadMoreCollections({
          offset: this.props.collections.length,
          limit: 1,
        });
      }
    }
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  toggleExpanded = () => {
    this.setState({ isExpanded: !this.state.isExpanded });
  };

  getMinColumnWidth(): number {
    return this.props.mobile ? minColumnWidth.MOBILE : minColumnWidth.DESKTOP;
  }

  getCollapsedCollectionsCount(): number {
    const numberOfRows = 2;

    return numberOfRows * this.state.columns;
  }

  isFullyLoaded(): boolean {
    const { totalNumberOfCollections } = this.props;

    if (
      totalNumberOfCollections === null ||
      totalNumberOfCollections === undefined
    ) {
      return false;
    }

    return this.props.collections.length >= totalNumberOfCollections;
  }

  getNumberOfCollectionsToShow(): number {
    if (this.state.isExpanded) {
      return this.props.collections.length;
    }

    return this.getCollapsedCollectionsCount();
  }

  shouldShowExpand(): boolean {
    const { totalNumberOfCollections, collections } = this.props;

    if (
      totalNumberOfCollections === null ||
      totalNumberOfCollections === undefined
    ) {
      return false;
    }

    if (this.state.isExpanded) {
      return true;
    }

    if (this.isFullyLoaded()) {
      return (
        this.getCollapsedCollectionsCount() < this.props.collections.length
      );
    }

    return collections.length < totalNumberOfCollections;
  }

  getNumberOfHiddenCollections(): number {
    const { totalNumberOfCollections, collections } = this.props;

    if (
      totalNumberOfCollections === undefined ||
      totalNumberOfCollections === null
    ) {
      return 0;
    }

    if (this.state.isExpanded) {
      return 0;
    }

    if (this.isFullyLoaded()) {
      return Math.max(
        this.props.collections.length - this.getCollapsedCollectionsCount(),
        0
      );
    } else if (collections.length > this.getNumberOfCollectionsToShow()) {
      return totalNumberOfCollections - this.getNumberOfCollectionsToShow();
    } else {
      return totalNumberOfCollections - collections.length;
    }
  }

  handleResize = (width: number) => {
    const columns = Math.max(2, Math.floor(width / this.getMinColumnWidth()));
    if (this.state.columns !== columns) {
      this.setState({ columns });
    }
  };

  renderInGrid(children: React.Node) {
    const { collections, canCreateCollections, isLoadingInitialCollections } =
      this.props;
    const hasCollections = collections.length > 0;

    // Don't show the collections grid after loading if the user isn't the
    // branch author on a branch with no collections
    const hideGrid =
      !canCreateCollections && !isLoadingInitialCollections && !hasCollections;

    return (
      <div
        className={style.grid}
        style={
          !hideGrid && this.state.columns
            ? {
                gridTemplateColumns: `repeat(${this.state.columns}, minmax(${minColumnWidth.MOBILE}px, 300px))`,
              }
            : {}
        }
      >
        {children}
      </div>
    );
  }

  renderEmptyState() {
    return this.renderInGrid(
      this.props.canCreateCollections ? (
        <CreateCollectionButton
          branchId={this.props.branch.id}
          button={(props) => <CreateCollectionPlaceholder {...props} />}
          display="block"
          projectId={this.props.projectId}
        />
      ) : (
        <NoCollections
          mini
          className={style.noCollections}
          title="This branch does not have any collections"
        />
      )
    );
  }

  renderLoadingState() {
    return this.renderInGrid(
      Array(this.state.columns)
        .fill()
        .map((currentValue, index) => (
          <CollectionThumbnailPlaceholder key={index} />
        ))
    );
  }

  renderCollections() {
    return (
      <Sortable
        items={this.props.collections.slice(
          0,
          this.getNumberOfCollectionsToShow()
        )}
        renderList={(items) => this.renderInGrid(items)}
        renderListItem={(collection, index) => (
          <CollectionThumbnail
            key={collection.id}
            index={index}
            projectId={this.props.projectId}
            collectionId={collection.id}
            useShorthandDate
            hideBranchName
            qaSelector={`collection-thumbnail-${index}`}
          />
        )}
        helperClass={style.thumbnailMoving}
        container={this.container}
        distance={this.props.mobile ? undefined : 5} // distance can not be used in conjunction with pressDelay
        pressDelay={this.props.mobile ? 100 : 0}
        axis="xy"
        onSort={(collection, moveInfo) =>
          this.props.moveCollection(
            this.props.collections,
            collection,
            moveInfo
          )
        }
        lockOffset="10px" // must be smaller than the dimensions of the rendered CollectionLayerThumbnail
        lockToContainerEdges
        useDragHandle
      />
    );
  }

  render() {
    const {
      projectId,
      branch,
      collections,
      canCreateCollections,
      isLoadingInitialCollections,
      isLoadingMoreCollections,
      isOffline,
    } = this.props;

    const gridContent = (() => {
      if (isLoadingInitialCollections) {
        return this.renderLoadingState();
      } else if (collections.length > 0) {
        return this.renderCollections();
      } else {
        return this.renderEmptyState();
      }
    })();

    const numberOfHiddenCollections = this.getNumberOfHiddenCollections();

    return (
      <div ref={(el) => (this.container = el)}>
        <div className={style.container}>
          <ResizeDetector
            handleWidth={true}
            handleHeight={false}
            onResize={this.handleResize}
          />
          <div className={style.headerContainer}>
            <Heading level="2" size="l">
              Collections
            </Heading>
            {canCreateCollections && (
              <div className={style.headerTitleContainer}>
                <div className={style.newCollectionButton}>
                  <OnboardingBubble
                    anchor="right"
                    storageKey="onboarded_collection_summary"
                    text="Collections can now be created from your branch’s overview tab."
                  >
                    <CreateCollectionButton
                      disabled={isOffline}
                      projectId={projectId}
                      branchId={branch.id}
                      text="New Collection…"
                    />
                  </OnboardingBubble>
                </div>
              </div>
            )}
          </div>
          {gridContent}
          {this.shouldShowExpand() && (
            <Expand
              hideBorder
              onClick={this.toggleExpanded}
              expanded={!isLoadingMoreCollections && this.state.isExpanded}
            >
              {isLoadingMoreCollections
                ? "Loading…"
                : numberOfHiddenCollections
                ? `Show ${numberOfHiddenCollections} more ${pluralize(
                    " collection",
                    numberOfHiddenCollections
                  )}`
                : null}
            </Expand>
          )}
        </div>
      </div>
    );
  }
}

export default connector(BranchCollectionsSummary);
