// @flow
import { startCase, lowerCase, map } from "lodash";
import * as React from "react";
import scrollIntoView from "smooth-scroll-into-view-if-needed";
import Asset from "core/components/Asset";
import Button from "core/components/Button";
import Loaded from "core/components/Loaded";
import PropertyRow from "core/components/PropertyRow";
import SidebarAccordion from "core/components/SidebarAccordion";
import { Abstract } from "core/lib/abstract";
import { assetLayerKey } from "core/models/asset";
import type {
  Asset as TAsset,
  LayerData,
  LayerDataAsset,
  AssetAutoGenerationOption,
} from "core/types";
import AssetGroup from "./AssetGroup";
import AssetsEmpty from "./AssetsEmpty";
import DownloadAsset from "./DownloadAsset";
import style from "./style.scss";

export type StateProps = {|
  assetAutoGenSetting: AssetAutoGenerationOption,
  assetsForAllLayers: TAsset[],
  assetPreviewsForAllLayers: TAsset[],
  assets: TAsset[],
  assetPreview: ?TAsset,
  canGenerateAssets: boolean,
  error?: boolean,
  isDesktop: boolean,
  isLoading: boolean,
  isOffline: boolean,
  layerDataAssets?: LayerDataAsset[],
  nestedLayerId: string,
  showAssetsSection: boolean,
  symbolMasterAssets: TAsset[],
  symbolMasterAssetPreview: ?TAsset,
  symbolDataAssets?: LayerDataAsset[],
  symbolMasterAssetsError?: boolean,
|};

export type OwnProps = {|
  canGenerateAssets: boolean,
  params: Abstract.LayerVersionDescriptor,
  layerData: LayerData,
  onSelectLayer?: (layerKey: string) => void,
  isRootLayer?: boolean,
  isPublicShare: ?boolean,
|};

export type DispatchProps = {|
  onLoad: () => void,
  onGenerateAssetsForFile: () => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type State = {|
  generateDisabled: boolean,
|};

export default class AssetsSection extends React.Component<Props, State> {
  assetsSection: *;

  scrollToAssets() {
    if (this.assetsSection) {
      scrollIntoView(this.assetsSection, {
        duration: 500,
        block: "center",
        scrollMode: "if-needed",
      });
    }
  }

  handleSelectLayer(asset: TAsset) {
    const layerKey = assetLayerKey(asset);

    if (this.props.onSelectLayer) {
      this.props.onSelectLayer(layerKey);
      this.scrollToAssets();
    }
  }

  assetsSectionRef = (ref: React.ElementRef<*>) => (this.assetsSection = ref);

  handleGenerateAssets = () => {
    this.setState({ generateDisabled: true });
    this.props.onGenerateAssetsForFile();
  };

  render() {
    const downloadDisabled =
      this.props.isLoading || this.props.isOffline || this.props.error;
    const hasGeneratedAssets =
      this.props.assetsForAllLayers && this.props.assetsForAllLayers.length > 0;
    const selectedLayerHasGeneratedAssets =
      this.props.assets && this.props.assets.length > 0;
    const assetsCount = this.props.layerDataAssets
      ? this.props.layerDataAssets.length
      : 0;

    const symbolMasterAssetsCount = this.props.symbolDataAssets
      ? this.props.symbolDataAssets.length
      : 0;

    const mainAssetsGroupLabel =
      symbolMasterAssetsCount > 0
        ? "Instance"
        : this.props.isRootLayer
        ? startCase(lowerCase(this.props.layerData.type))
        : "";

    const mainAssetsGroupDescription =
      symbolMasterAssetsCount > 0
        ? "Download symbol as it appears in this instance of the artboard."
        : this.props.isRootLayer
        ? `Download all assets for the ${lowerCase(this.props.layerData.type)}.`
        : "";

    const downloadAllArtboardAssets = (
      <DownloadAsset
        all
        assets={this.props.assetsForAllLayers}
        layerParams={this.props.params}
      >
        {({ onDownload, loading }) => (
          <Button
            disabled={loading || downloadDisabled}
            onClick={onDownload}
            nude
            tint
          >
            Download all
          </Button>
        )}
      </DownloadAsset>
    );

    const downloadAllLayerAssets = (
      <DownloadAsset
        all
        assets={this.props.assets}
        layerParams={this.props.params}
      >
        {({ onDownload, loading }) => (
          <Button
            disabled={loading || downloadDisabled}
            onClick={onDownload}
            nude
            tint
          >
            Download all
          </Button>
        )}
      </DownloadAsset>
    );

    let downloadAllAssets = null;
    if (hasGeneratedAssets || selectedLayerHasGeneratedAssets) {
      if (this.props.isRootLayer) {
        // if we have generated assets and this is the root layer,
        // the download link should download all layer assets and
        // descendants for this artboard.
        downloadAllAssets = downloadAllArtboardAssets;
      } else if (
        this.props.symbolMasterAssets.length === 0 &&
        !(this.props.assetPreview && this.props.assetPreview.error)
      ) {
        // if we have generated assets and this isn't the root layer,
        // only show the 'download all' button here if available
        // assets don't have errors, and we aren't already showing
        // a 'download all' link in the 'instance' and 'symbol' sections
        // created when there are symbol master assets available.
        downloadAllAssets = downloadAllLayerAssets;
      }
    }

    return this.props.showAssetsSection ? (
      <React.Fragment>
        <span ref={this.assetsSectionRef} />
        <SidebarAccordion label="Assets" right={downloadAllAssets}>
          <React.Fragment>
            {this.props.isRootLayer && (
              <Loaded loading={this.props.isLoading} title="Loading assets…">
                {hasGeneratedAssets ? (
                  <PropertyRow className={style.artboardPropertyRow}>
                    <div className={style.assetsGrid}>
                      {map(this.props.assetPreviewsForAllLayers, (asset) => (
                        <Asset
                          key={asset.id}
                          src={asset.url}
                          onClick={() => {
                            this.handleSelectLayer(asset);
                          }}
                          alt={this.props.layerData.properties.name}
                        />
                      ))}
                    </div>
                  </PropertyRow>
                ) : (
                  (symbolMasterAssetsCount === 0 || assetsCount === 0) && (
                    <AssetsEmpty
                      canGenerateAssets={this.props.canGenerateAssets}
                      isDesktop={this.props.isDesktop}
                      assetAutoGenSetting={this.props.assetAutoGenSetting}
                      layerDataAssets={this.props.layerDataAssets}
                      onGenerateAssetsForFile={
                        this.props.onGenerateAssetsForFile
                      }
                    />
                  )
                )}
              </Loaded>
            )}

            {assetsCount > 0 && (
              <PropertyRow className={style.artboardPropertyRow}>
                <div className={style.assetGroup}>
                  <AssetGroup
                    params={this.props.params}
                    assets={this.props.assets}
                    assetsCount={assetsCount}
                    assetPreview={this.props.assetPreview}
                    isLoading={this.props.isLoading}
                    isOffline={this.props.isOffline}
                    error={this.props.error}
                    layerName={this.props.layerData.properties.name}
                    nestedLayerId={this.props.nestedLayerId}
                    layerDataAssets={this.props.layerDataAssets}
                    groupLabel={mainAssetsGroupLabel}
                    groupDescription={mainAssetsGroupDescription}
                  />
                </div>
              </PropertyRow>
            )}

            {symbolMasterAssetsCount > 0 ? (
              <PropertyRow className={style.artboardPropertyRow}>
                <div className={style.assetGroup}>
                  <AssetGroup
                    params={this.props.params}
                    assets={this.props.symbolMasterAssets}
                    assetsCount={symbolMasterAssetsCount}
                    assetPreview={this.props.symbolMasterAssetPreview}
                    isLoading={false}
                    isOffline={this.props.isOffline}
                    error={this.props.symbolMasterAssetsError}
                    layerName={this.props.layerData.properties.name}
                    nestedLayerId={this.props.nestedLayerId}
                    layerDataAssets={this.props.symbolDataAssets}
                    groupLabel="Symbol"
                    groupDescription="Download symbol as it originally appeared in the linked library"
                  />
                </div>
              </PropertyRow>
            ) : null}
          </React.Fragment>
        </SidebarAccordion>
      </React.Fragment>
    ) : null;
  }
}
