// @flow
import invariant from "invariant";
import { Abstract } from "core/lib/abstract";
import * as Layer from "core/models/layer";
import { BranchFetchRequest } from "core/requests/branches";
import { LayerDatasetFetchRequest } from "core/requests/layerDatasets";
import { LayerFetchRequest } from "core/requests/layers";
import { getBranchHead } from "core/selectors/branches";
import {
  getCurrentLayer,
  getTargetSha,
  getTargetLayer,
  getTargetLayerData,
} from "core/selectors/prototypes";
import type {
  LayerLink,
  BranchCollectionDescriptor,
  TargetLinkOptions,
} from "core/types";
import defineRequest from "./";

type TargetLayerParams = {
  descriptor: Abstract.LayerVersionDescriptor | BranchCollectionDescriptor,
  link: LayerLink,
  options: TargetLinkOptions,
};

export function getTargetLayerRequestId(
  descriptor: Abstract.LayerVersionDescriptor | BranchCollectionDescriptor,
  link: LayerLink,
  options: { collectionLayerId?: string }
) {
  const linkKey = link.target
    ? Layer.uniqueId({ ...link.target, sha: "latest" })
    : "previous";

  if (descriptor.collectionId !== undefined) {
    invariant(options.collectionLayerId, "collectionLayerId required"); // TODO: Update BranchCollectionDescriptor to BranchCollectionLayerDescriptor
    // prettier-ignore
    return `prototype-${descriptor.projectId}-${descriptor.branchId}-${descriptor.collectionId}-${options.collectionLayerId}-${linkKey}`;
  }

  return `prototype-${Layer.uniqueId(descriptor)}-${linkKey}`;
}

export const TargetLayerRequest = defineRequest<
  TargetLayerParams,
  TargetLayerParams,
>({
  id: (params) => {
    const { descriptor, link, options } = params;
    return getTargetLayerRequestId(descriptor, link, options);
  },
  perform: async (params, dispatch, getState) => {
    const { descriptor, link, options } = params;
    const target = link.target || undefined;

    if (target) {
      let state = getState();
      if (
        getTargetLayer(state, descriptor, link, options) &&
        getTargetLayerData(state, descriptor, link, options)
      ) {
        return;
      }
      await dispatch(
        BranchFetchRequest.perform({
          params: {
            projectId: descriptor.projectId,
            branchId: descriptor.branchId,
          },
        })
      );
      state = getState();

      if (descriptor.layerId) {
        await dispatch(LayerFetchRequest.perform({ params: descriptor }));
      }
      state = getState();
      const currentLayer = getCurrentLayer(state, descriptor, link, options);
      invariant(currentLayer, "Current layer should be loaded");

      const head = getBranchHead(state, descriptor);
      invariant(head, "Branch head should be loaded");

      const targetLayerDescriptor = { ...target, sha: head };
      await Promise.all([
        dispatch(
          LayerFetchRequest.perform({
            params: {
              projectId: currentLayer.projectId,
              branchId: descriptor.branchId,
              sha: head,
              fileId: currentLayer.fileId,
              layerId: currentLayer.id,
            },
          })
        ),
        dispatch(
          LayerFetchRequest.perform({
            params: targetLayerDescriptor,
          })
        ),
      ]);
      state = getState();

      const targetSha = getTargetSha(state, descriptor, link, options);

      invariant(targetSha, "Target sha should be loaded");

      await Promise.all([
        dispatch(
          LayerFetchRequest.perform({
            params: {
              ...targetLayerDescriptor,
              sha: targetSha,
            },
          })
        ),
        dispatch(
          LayerDatasetFetchRequest.perform({
            params: {
              ...targetLayerDescriptor,
              sha: targetSha,
            },
          })
        ),
      ]);
    }
  },
});
