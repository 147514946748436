// @flow
import get from "lodash/get";
import * as React from "react";
import { connect } from "react-redux";
import scrollIntoView from "smooth-scroll-into-view-if-needed";
import { getCurrentUser, isOnline } from "abstract-di/selectors";
import { createComment } from "core/actions/comments";
import Comment from "core/components/Comment";
import CommentReplyForm, {
  type Props as TCommentReplyForm,
} from "core/components/CommentReplyForm";
import RepliesLoader from "core/components/RepliesLoader";
import createConnector from "core/lib/createConnector";
import { withRouter } from "core/lib/router";
import {
  PinCommentRequest,
  UnpinCommentRequest,
  ResolveCommentRequest,
  UnresolveCommentRequest,
} from "core/requests/comments";
import { getBranch } from "core/selectors/branches";
import { getCommit } from "core/selectors/commits";
import { getFile } from "core/selectors/files";
import { getLayer } from "core/selectors/layers";
import { getPage } from "core/selectors/pages";
import { getUsersForProject } from "core/selectors/users";
import type {
  FormState,
  User,
  State as AppState,
  Comment as TComment,
  Dispatch,
  ThunkAction,
} from "core/types";

type OwnProps = {|
  parent: TComment,
  replies: TComment[],
  parentId: string,
  projectId: string,
  branchId: string,
  fileId: string,
  pageId: string,
  layerId: string,
  commitSha: string,
  layerName?: string,
  fileName?: string,
  branchName?: string,
  pageName?: string,
  commitTitle?: string,
  disabled?: boolean,
  replyDisabled?: boolean,
  selectedRef?: () => void,
  highlightedRef?: () => void,
  isPubliclyShared?: boolean,
  preview?: React.Node,
  loadReplies?: () => void,
  context: Object,
  onCommentSelected?: (string) => void,
  selectedCommentId?: ?string,
  highlightedCommentId?: string,
  scrollToCommentId?: string,
  itemClass?: string,
  replyFormClass?: string,
  commentComponent?: *,
  isHighlighted?: boolean,
  pinCommentsEnabled: boolean,
  resolveCommentsEnabled: boolean,
  highlightPinnedComment?: boolean,
  pinnedComments?: TComment[],
  enableHidingResolvedComments?: boolean,
  isShowingResolvedComments?: boolean,
  tintBackgroundOnHover?: boolean,
  qaSelector?: string,
|};

type StateProps = {|
  currentUser: ?User,
  users: User[],
  projectId: string,
  online: boolean,
  fileId: string,
  branchId: string,
  layerId: string,
  commitSha: string,
  pageId: string,
  fileName?: string,
  layerName?: string,
  pageName?: string,
  branchName?: string,
  commitTitle?: string,
  isPinning: boolean,
  isUnpinning: boolean,
  isResolving: boolean,
  isUnresolving: boolean,
|};

type DispatchProps = {|
  createComment: (props: FormState) => ThunkAction,
|};

type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type State = { replyForm: boolean };

class CommentThread extends React.Component<Props, State> {
  formElement: *;
  replyForm: *;

  state = { replyForm: false };

  setReplyFormRef = (ref: *) => (this.replyForm = ref);
  setFormElementRef = (ref: *) => (this.formElement = ref);

  scrollToForm = () => {
    if (this.formElement) {
      scrollIntoView(this.formElement, {
        duration: 500,
        block: "nearest",
        behavior: "smooth",
      });
    }
  };

  hideReplyForm = () => {
    this.setState({ replyForm: false });
  };

  handleClickReply = () => {
    if (this.state.replyForm) {
      this.scrollToForm();
    }

    this.setState({ replyForm: true }, () => {
      this.scrollToForm();

      if (this.replyForm) {
        // https://github.com/draft-js-plugins/draft-js-plugins/issues/357
        // Focus must be delayed until at least a frame after mounting the reply form
        setTimeout(() => this.replyForm.focus(), 10);
      }
    });
  };

  handleReply = (id: string, form: FormState) => {
    this.props.createComment({ id, ...form });
    this.hideReplyForm();
  };

  get commentProps(): Object {
    return {
      parentId: this.props.parentId,
      projectId: this.props.projectId,
      branchId: this.props.branchId,
      commitSha: this.props.commitSha,
      fileId: this.props.fileId,
      pageId: this.props.pageId,
      layerId: this.props.layerId,
      fileName: this.props.fileName,
      pageName: this.props.pageName,
      branchName: this.props.branchName,
      commitTitle: this.props.commitTitle,
      layerName: this.props.layerName,
      currentUser: this.props.currentUser,
      users: this.props.users,
      online: this.props.online,
      disabled: this.props.disabled,
      onClickReply: this.handleClickReply,
      commentComponent: this.props.commentComponent,
      tintBackgroundOnHover: this.props.tintBackgroundOnHover,
    };
  }

  get commentReplyFormProps(): TCommentReplyForm {
    return {
      parentId: this.props.parentId,
      projectId: this.props.projectId,
      branchId: this.props.branchId,
      commitSha: this.props.commitSha,
      fileId: this.props.fileId,
      pageId: this.props.pageId,
      layerId: this.props.layerId,
      users: this.props.users,
      online: this.props.online,
      ref: this.setReplyFormRef,
      innerRef: this.setFormElementRef,
      autoFocus: this.state.replyForm,
      disabled: this.props.disabled || this.props.replyDisabled,
      formId: `${this.props.parent.id}-reply-form`,
      onCancel: this.hideReplyForm,
      onReply: this.handleReply,
    };
  }

  render() {
    const commentProps = this.commentProps;
    const commentReplyFormProps = this.commentReplyFormProps;
    const parentId = this.props.parent.id;
    const hasReplies = this.props.replies.length > 0;
    const isPinned = !!(
      this.props.parent.pinnedAt &&
      this.props.parent.pinnedByUserId &&
      this.props.highlightPinnedComment
    );

    const isResolved =
      this.props.enableHidingResolvedComments &&
      this.props.parent.resolvedAt &&
      this.props.parent.resolvedByUserId &&
      this.props.resolveCommentsEnabled;

    const hideResolved =
      isResolved &&
      !this.props.isShowingResolvedComments &&
      this.props.resolveCommentsEnabled;

    if (hideResolved) {
      return null;
    }

    return (
      <React.Fragment>
        <Comment
          key={parentId}
          className={this.props.itemClass}
          comment={this.props.parent}
          context={this.props.context}
          onSelected={this.props.onCommentSelected}
          isSelected={this.props.selectedCommentId === parentId}
          isHighlighted={this.props.highlightedCommentId === parentId}
          reviewStatus={this.props.parent.reviewStatus}
          selectedRef={this.props.selectedRef}
          highlightedRef={this.props.highlightedRef}
          mentionableUsers={this.props.users}
          preview={this.props.preview}
          pinCommentsEnabled={this.props.pinCommentsEnabled}
          resolveCommentsEnabled={this.props.resolveCommentsEnabled}
          forceHighlight={isPinned && this.props.pinCommentsEnabled}
          isResolved={isResolved}
          qaSelector={this.props.qaSelector}
          {...commentProps}
        />
        {hasReplies &&
          (this.props.loadReplies ? (
            <RepliesLoader
              {...commentProps}
              replies={this.props.replies}
              replyIds={this.props.parent.replyIds}
              context={this.props.context}
              onLoadMore={this.props.loadReplies}
            />
          ) : (
            this.props.replies.map((comment, index) => (
              <Comment
                key={comment.id}
                className={this.props.itemClass}
                comment={comment}
                context={this.props.context}
                onSelected={this.props.onCommentSelected}
                isSelected={this.props.selectedCommentId === comment.id}
                isHighlighted={this.props.highlightedCommentId === comment.id}
                highlightedRef={this.props.highlightedRef}
                mentionableUsers={this.props.users}
                isResolved={isResolved}
                qaSelector={
                  this.props.qaSelector
                    ? `${this.props.qaSelector}-reply-${index}`
                    : `reply-${index}`
                }
                {...commentProps}
              />
            ))
          ))}
        {(this.state.replyForm || hasReplies) &&
          !this.props.disabled &&
          this.props.currentUser && (
            <CommentReplyForm
              className={this.props.replyFormClass}
              isPubliclyShared={this.props.isPubliclyShared}
              {...commentReplyFormProps}
            />
          )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: AppState, props: OwnProps): StateProps {
  const currentUser: ?User = getCurrentUser(state);
  const { projectId, branchId, fileId, layerId } = props.parent;
  const sha = props.parent.commitSha;

  const branch = getBranch(state, { projectId, branchId });
  const commit = getCommit && getCommit(state, { sha });
  const file = getFile(state, { projectId, sha, fileId });
  const layer = getLayer(state, { projectId, branchId, sha, fileId, layerId });
  const pageId = props.pageId || props.parent.pageId || get(layer, "pageId");
  const page = getPage(state, {
    projectId,
    sha,
    fileId,
    pageId,
  });

  const isPinning = PinCommentRequest.isLoadingStrict(state, {
    id: props.parentId,
  });

  const isUnpinning = UnpinCommentRequest.isLoadingStrict(state, {
    id: props.parentId,
  });

  const isResolving = ResolveCommentRequest.isLoadingStrict(state, {
    id: props.parentId,
  });

  const isUnresolving = UnresolveCommentRequest.isLoadingStrict(state, {
    id: props.parentId,
  });

  return {
    currentUser,
    users: getUsersForProject(state, { projectId }),
    projectId,
    online: isOnline(state),
    fileId: props.fileId || fileId,
    branchId: props.branchId || branchId,
    layerId: props.layerId || layerId,
    commitSha: props.commitSha || sha,
    pageId,
    fileName: props.fileName || get(file, "name"),
    layerName: props.layerName || get(layer, "name"),
    pageName: props.pageName || get(page, "name"),
    branchName: props.branchName || get(branch, "name"),
    commitTitle: props.commitTitle || get(commit, "title"),
    isPinning,
    isUnpinning,
    isResolving,
    isUnresolving,
  };
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
const connector = createConnector<Props, OwnProps>(
  withRouter,
  connect<Props, OwnProps, StateProps, DispatchProps, AppState, Dispatch>(
    mapStateToProps,
    { createComment }
  )
);

export default connector(CommentThread);
