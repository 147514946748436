// @flow
import empty from "empty";
import { connect } from "react-redux";
import { cloneRepo } from "abstract-di/actions";
import { isOnline } from "abstract-di/selectors";
import { trackEvent } from "core/actions/analytics";
import { fetchStars, toggleStar } from "core/actions/stars";
import { withData } from "core/components/DataLoader";
import connectStorage from "core/hocs/connectStorage";
import createConnector from "core/lib/createConnector";
import { DEFAULT_PROJECT_SORT, DEFAULT_PROJECT_FILTER } from "core/lib/lists";
import { replace, addQuery, removeQuery } from "core/lib/location";
import { isDesktop } from "core/lib/platform";
import * as Organization from "core/models/organization";
import { getFilter } from "core/models/project";
import * as Request from "core/models/request";
import { ProjectsFetchRequest } from "core/requests/projects";
import { StarsFetchRequest } from "core/requests/stars";
import { canUseWebProjectCreation } from "core/selectors/features";
import { getOrganization } from "core/selectors/organizations";
import { getOrganizationPolicy } from "core/selectors/policies";
import {
  getFilteredProjects,
  getProjectsBySection,
  getOrganizationIsEmpty,
} from "core/selectors/projects";
import { getFilteredSections } from "core/selectors/sections";
import type { Dispatch, State, ProjectFilter, ProjectSort } from "core/types";
import type {
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  StorageProps,
} from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { organizationId, query, filter, sort } = props;

  const organization = getOrganization(state, { organizationId });
  const policy = getOrganizationPolicy(state, { organizationId });
  const canUseSections = policy.showSections;
  const request = ProjectsFetchRequest.getRequest(state, {
    organizationId,
    filter: getFilter(props.filter),
  });
  const isOffline = !isOnline(state);

  const sections = canUseSections
    ? getFilteredSections(state, { organizationId, filter })
    : empty.array;

  // Policies
  const canCreateProjects =
    !!organization &&
    Organization.canUseVersions(organization) &&
    policy.createProject &&
    ((isDesktop &&
      (organization ? Organization.hasVersionsEnabled(organization) : false)) ||
      canUseWebProjectCreation(state, organizationId));

  return {
    policy,
    organization,
    sections,
    sectionProjects: canUseSections
      ? getProjectsBySection(state, { organizationId, sort })
      : empty.object,
    empty:
      getOrganizationIsEmpty(state, { organizationId }) &&
      sections.length === 0,
    projects: getFilteredProjects(state, {
      organizationId,
      sort,
      query,
      filter,
    }),
    hasError: Request.hasError(request) && !isOffline,
    isOffline,
    isLoading:
      !isOffline &&
      (Request.isFirstLoading(request) ||
        StarsFetchRequest.isFirstLoading(state, empty.object)),
    canCreateProjects,
    canCreateSections: policy.manageSections,
    canUseSections,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    onLoad() {
      dispatch(
        ProjectsFetchRequest.perform({
          params: {
            organizationId: props.organizationId,
            filter: getFilter(props.filter),
          },
        })
      );

      dispatch(fetchStars());
    },
    onToggleStar(params) {
      dispatch(toggleStar(params));
    },
    onProjectClick(projectId: string) {
      dispatch(
        trackEvent("PROJECT_CARD_CLICKED", {
          project_id: projectId,
          timestamp: Date.now(),
        })
      );
    },
    onStartSyncingProject(projectId: string) {
      if (cloneRepo) {
        dispatch(cloneRepo(projectId));
      }
    },
  };
}

function mapStorageToProps(storage, props: OwnProps): StorageProps {
  const storageId = `Projects-${props.organizationId}`;
  const previous = storage.getItem(storageId) || {};

  return {
    filter: previous.filter || DEFAULT_PROJECT_FILTER,
    sort: previous.sort || DEFAULT_PROJECT_SORT,
    onFilter: (filter?: ProjectFilter) => {
      storage.setItem(storageId, { ...previous, filter });
      if (filter && filter !== DEFAULT_PROJECT_FILTER) {
        replace(addQuery({ filter }));
      } else {
        replace(removeQuery("filter"));
      }
    },
    onSort: (sort: ProjectSort) => {
      storage.setItem(storageId, { ...previous, sort });
      if (sort !== DEFAULT_PROJECT_SORT) {
        replace(addQuery({ sort }));
      } else {
        replace(removeQuery("sort"));
      }
    },
  };
}

export default createConnector<Props, OwnProps>(
  (Component: *) => connectStorage<OwnProps>(Component, mapStorageToProps),
  connect(mapStateToProps, mapDispatchToProps),
  withData((props) => ({
    organizationId: props.organizationId,
    filter: getFilter(props.filter),
  }))
);
