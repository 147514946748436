// @flow
import * as React from "react";
import PopoverCore, { type Props as CoreProps } from "./Core";
import style from "./style.scss";

const { classNames: _, ...safeDefaultProps } = PopoverCore.defaultProps;

export default class Popover extends React.Component<CoreProps> {
  static defaultProps = safeDefaultProps;

  render() {
    return <PopoverCore classNames={style} {...this.props} />;
  }
}
