// @flow
import filter from "lodash/filter";
import * as request from "core/models/request";
import { getRequest } from "core/selectors/requests";
import type { State } from "core/types";
import * as URLs from "web/api/urls";
import { ConfigurationFetchRequest } from "web/requests/sessions";
import type { Request } from "web/types";

export function getLastRequest(
  state: State,
  keyFilter: (string) => boolean
): Request {
  const keys = filter(Object.keys(state.requests), keyFilter);
  if (keys.length) {
    return state.requests[keys[keys.length - 1]];
  }
  return request.DEFAULT;
}

export function getNotificationSettingsRequest(state: State): Request {
  return getRequest(state, URLs.fetchNotificationSettings());
}

export function getEmailsRequest(state: State): Request {
  return getRequest(state, URLs.fetchEmails());
}

export function getCreateEmailRequest(state: State): Request {
  return getRequest(state, `create:${URLs.fetchEmails()}`);
}

export function getDeleteEmailRequest(state: State, emailId: string): Request {
  return getRequest(state, `delete:${URLs.fetchEmail(emailId)}`);
}

export function getSetEmailAsPrimaryRequest(
  state: State,
  emailId: string
): Request {
  return getRequest(state, URLs.setEmailAsPrimary(emailId));
}

export function getSendEmailVerificationRequest(
  state: State,
  emailId: string
): Request {
  return getRequest(state, URLs.sendEmailVerification(emailId));
}

export function getOrganizationDeleteRequest(
  state: State,
  organizationId: string
): Request {
  return getRequest(state, `delete:organizations/${organizationId}`);
}

export function getSessionRequest(state: State) {
  return ConfigurationFetchRequest.getRequest(state, {});
}

export function getAccountDeletePreviewRequest(state: State) {
  return getRequest(state, `delete:${URLs.previewAccount()}`);
}

export function getAccountDeleteRequest(state: State) {
  return getRequest(state, `delete:${URLs.fetchAccount()}`);
}

export function getZendeskTokenRequest(state: State) {
  return getRequest(state, URLs.fetchZendeskToken());
}
