// @flow
import * as React from "react";
import { V3Link as Link } from "core/lib/router";
import { projectPath } from "core/lib/routes";
import ActivityBase from "./Base";
import type {
  ActivityProjectArchived as TActivityProjectArchived,
  Props,
} from "./types";

export default function ActivityProjectArchived(
  props: Props<TActivityProjectArchived>
) {
  return (
    <ActivityBase
      {...props}
      icon="project"
      action="archived the project"
      subject={
        <Link to={projectPath(props.activity.projectId)}>
          {props.activity.payload.projectName}
        </Link>
      }
    />
  );
}
