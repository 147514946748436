// @flow
import { connect } from "react-redux";
import { isOnline } from "abstract-di/selectors";
import { entitiesReceived } from "core/actions/entities";
import { reorder } from "core/lib/reordering";
import {
  BranchCollectionsFetchRequest,
  CollectionDeleteRequest,
  CollectionMoveRequest,
} from "core/requests/collections";
import {
  getBranchCollections,
  getCanCreateBranchCollections,
} from "core/selectors/collections";
import {
  getFeatureEnabled,
  getProjectFeatureEnabled,
} from "core/selectors/features";
import { getOrganizationForProject } from "core/selectors/organizations";
import { getPaginationTotal } from "core/selectors/paginationTotals";
import type { Dispatch, State } from "core/types";
import type { OwnProps, StateProps, DispatchProps, Props } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { projectId, branch } = props;
  const { id: branchId } = branch;

  const organization = getOrganizationForProject(state, {
    projectId,
  });

  const isOrderEnabled = getFeatureEnabled(state, {
    organizationId: organization ? organization.id : "",
    feature: "branch-collection-reorder",
  });

  const collections = getBranchCollections(state, {
    projectId,
    branchId,
    sortBy: isOrderEnabled ? "order" : "updatedAt",
  });

  const totalNumberOfCollections = getPaginationTotal(state, branchId);
  const isLoadingInitialCollections =
    BranchCollectionsFetchRequest.isFirstLoading(state, {
      projectId,
      branchId,
    });

  const isLoadingMoreCollections =
    !isLoadingInitialCollections &&
    BranchCollectionsFetchRequest.isLoading(state, {
      projectId,
      branchId,
    });

  const isDeletingCollection = collections.some((collection) =>
    CollectionDeleteRequest.isLoadingStrict(state, {
      projectId,
      id: collection.id,
      branchId,
    })
  );

  return {
    totalNumberOfCollections,
    isLoadingInitialCollections,
    isLoadingMoreCollections,
    isDeletingCollection,
    collections,
    canCreateCollections: getCanCreateBranchCollections(state, {
      projectId,
      branchId,
    }),
    isOffline: !isOnline(state),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { projectId, branch } = props;
  const { id: branchId } = branch;

  return {
    loadInitialCollections(options: { limit: number }) {
      return dispatch((dispatch, getState) => {
        const isOrderEnabled = getProjectFeatureEnabled(getState(), {
          projectId,
          feature: "branch-collection-reorder",
        });

        dispatch(
          BranchCollectionsFetchRequest.perform({
            params: {
              projectId,
              branchId,
              sortBy: isOrderEnabled ? "order" : "updatedAt",
              sortDir: isOrderEnabled ? "asc" : "desc",
              limit: options.limit,
            },
            force: false,
          })
        );
      });
    },
    loadMoreCollections(options: { offset: number, limit?: number }) {
      return dispatch((dispatch, getState) => {
        const isOrderEnabled = getProjectFeatureEnabled(getState(), {
          projectId,
          feature: "branch-collection-reorder",
        });

        dispatch(
          BranchCollectionsFetchRequest.perform({
            force: true,
            params: {
              projectId,
              branchId,
              sortBy: isOrderEnabled ? "order" : "updatedAt",
              sortDir: isOrderEnabled ? "asc" : "desc",
              offset: options.offset,
              limit: options.limit,
            },
          })
        );
      });
    },
    moveCollection: async (collections, collection, { newIndex, oldIndex }) => {
      if (newIndex === oldIndex) {
        return;
      }

      const newCollections = reorder(collections, {
        oldIndex,
        newIndex,
        key: "order",
      });

      dispatch(
        entitiesReceived({
          collections: newCollections.reduce((acc, collection) => {
            return {
              ...acc,
              [collection.id]: collection,
            };
          }, {}),
        })
      );

      dispatch(
        CollectionMoveRequest.perform({
          params: {
            collectionId: collection.id,
            projectId,
            order: newIndex + 1,
          },
        })
      );
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
