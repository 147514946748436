// @flow
import classnames from "classnames";
import * as React from "react";
import scrollIntoView from "smooth-scroll-into-view-if-needed";
import Button from "core/components/Button";
import Icon from "core/components/Icon";
import { V3Link as Link } from "core/lib/router";
import style from "./style.scss";

type Props = {|
  children?: any,
  selected?: boolean,
  targeted?: boolean,
  collapsed?: boolean,
  disclosure?: boolean,
  disabled?: boolean,
  peek: boolean,
  hasChildren: boolean,
  defaultCollapsed: boolean,
  selectedClassName?: string,
  disclosureClassName?: string,
  label: React.Node,
  before?: (isLight?: boolean) => React.Node,
  after?: (isLight?: boolean) => React.Node,
  icon?: string,
  className?: string,
  itemClassName?: string,
  isFocused?: boolean,
  onClick?: () => void,
  onMouseEnter?: () => void,
  onMouseLeave?: () => void,
  onFileExpand?: () => void,
  onContextMenu?: (event: SyntheticEvent<>, ...args?: mixed[]) => void,
  onDoubleClick?: (event: SyntheticEvent<>, ...args?: mixed[]) => void,
  to?: string | Object,
  isMobile?: boolean,
  innerRef?: React.Ref<"div">,
|};

type State = {
  collapsed: boolean,
  peek: boolean,
};

export default class TreeView extends React.Component<Props, State> {
  static defaultProps = {
    defaultCollapsed: true,
    disclosure: true,
    peek: false,
    hasChildren: false,
    isFocused: true,
  };

  scrollTo: ?HTMLElement;

  state = {
    collapsed: this.props.defaultCollapsed,
    peek: this.props.peek,
  };

  componentDidMount() {
    if (this.props.selected) {
      this.scrollIntoViewIfNeeded();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.peek !== prevProps.peek) {
      this.setState({ peek: this.props.peek });
    }

    if (this.props.selected && !prevProps.selected) {
      this.setState(undefined, this.scrollIntoViewIfNeeded);
    }
  }

  scrollIntoViewIfNeeded = () => {
    if (this.scrollTo) {
      scrollIntoView(this.scrollTo, {
        duration: 200,
        block: "nearest",
        behavior: "smooth",
        scrollMode: "if-needed",
      });
    }
  };

  toggleCollapsed = (event: SyntheticEvent<*>) => {
    if (!this.showDisclosure) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    if (this.state.peek) {
      return this.setState({ peek: false });
    }

    if (this.state.collapsed) {
      this.expand();
    } else {
      this.collapse();
    }
  };

  swallowEvent = (event: SyntheticEvent<*>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  collapse = () => {
    this.setState({ collapsed: true });
  };

  expand = () => {
    this.setState({ collapsed: false });
    if (this.props.onFileExpand) {
      this.props.onFileExpand();
    }
  };

  setScrollTo = (ref: ?HTMLElement) => {
    this.scrollTo = ref;
  };

  get showDisclosure(): boolean {
    if (this.props.isMobile) {
      return false;
    }
    if (this.props.children && this.props.hasChildren) {
      return true;
    }
    return !!this.props.hasChildren;
  }

  render() {
    const {
      collapsed = !this.state.peek && this.state.collapsed,
      itemClassName,
      label,
      before,
      after,
      icon,
      children,
      disabled,
      selected,
      isFocused,
      disclosure,
      hasChildren,
      selectedClassName = "",
      disclosureClassName = "",
      targeted,
    } = this.props;

    const hasMultipleChildren = children && !!children.length;
    const enableDisclosure = hasMultipleChildren || hasChildren;
    const isLight = isFocused && selected;
    const isHoverable = (this.props.to || this.props.onClick) && !disabled;

    const classes = classnames(style.tree, { [style.collapsed]: collapsed });
    const itemClasses = classnames(style.item, itemClassName, {
      [style.disabled]: disabled,
      [style.focused]: isFocused,
      [style.selected]: selected,
      [selectedClassName]: selected,
      [style.targeted]: targeted,
      [style.hoverable]: isHoverable,
    });

    const Component = isHoverable ? Link : "div";

    return (
      <li
        className={classes}
        ref={this.setScrollTo}
        onContextMenu={disabled ? undefined : this.props.onContextMenu}
        onDoubleClick={disabled ? undefined : this.props.onDoubleClick}
        data-qa="treeItem"
      >
        <Component
          to={disabled ? undefined : this.props.to}
          onClick={
            disabled ? undefined : this.props.onClick || this.toggleCollapsed
          }
          onMouseEnter={this.props.onMouseEnter}
          onMouseLeave={this.props.onMouseLeave}
          tabIndex={0}
          className={itemClasses}
        >
          <div className={style.wrapper} ref={this.props.innerRef}>
            <div
              className={classnames(style.disclosureContainer, {
                [style.hideDisclosure]: !disclosure,
              })}
            >
              {this.showDisclosure && (
                <Button
                  nude
                  icon={
                    isLight
                      ? "disclosure-expanded-light"
                      : "disclosure-expanded"
                  }
                  className={classnames(style.disclosure, disclosureClassName, {
                    [style.hidden]: !enableDisclosure,
                  })}
                  onClick={this.toggleCollapsed}
                  onDoubleClick={this.swallowEvent}
                  disabled={!enableDisclosure || disabled}
                />
              )}
            </div>
            {before && before(isLight)}
            {icon && (
              <Icon
                type={icon}
                light={isLight}
                inheritFill={isLight}
                className={style.icon}
              />
            )}
            <span className={style.label}>{label}</span>
            {after && after(isLight)}
          </div>
        </Component>
        {hasMultipleChildren && (!collapsed || !disclosure) && (
          <ul
            className={classnames(style.children, {
              [style.hideDisclosure]: !disclosure,
            })}
          >
            {children}
          </ul>
        )}
      </li>
    );
  }
}
