// @flow
import * as Abstract from "abstract-sdk";
import empty from "empty";
import path from "path";
import history from "abstract-di/history";
import { organizationPath } from "abstract-di/routes";
import { isMac } from "core/lib/platform";
import query from "core/lib/query";
import { BRANCH_ID_MASTER } from "core/models/branch";
import type {
  LayerOptions,
  FilePathQueryParams,
  LayerSetParams,
  LocationDescriptor,
  BranchOptions,
  OrganizationTeamDescriptor,
  BranchFilter,
  ProjectMembersTab,
} from "core/types";

export { query };

const USE_MAIN_INSTEAD_OF_MASTER: boolean =
  process.env.USE_MAIN_INSTEAD_OF_MASTER === "true";

// This method is used to force the '/' in all paths.
// On Windows path.join returns '\' which breaks
// navigation as well as a bunch of other functionality.
// Use this method in place of path.join unless you
// absolutely need a '\' on Windows.
export function urlPathJoin(...args: any[]) {
  const urlPath = path.join(...args);
  if (isMac) {
    return urlPath;
  }
  return urlPath.replace(/\\/g, "/");
}

export function rootPath() {
  return "/";
}

export function accountPath(
  path?:
    | "profile"
    | "password"
    | "organizations"
    | "emails"
    | "notifications"
    | "tokens"
) {
  return urlPathJoin(`/account`, path || "");
}

export function projectPath(
  projectId: string,
  section?:
    | "branches"
    | "activity"
    | "everyone"
    | "people"
    | "teams"
    | "collections"
    | "edit",
  options?: { filter: BranchFilter }
) {
  return urlPathJoin(`/projects/${projectId}`, section || "") + query(options);
}

export function projectEditPath(projectId: string, section?: "restrictions") {
  return urlPathJoin(projectPath(projectId, "edit"), section || "");
}

export function branchPath(
  projectId: string,
  branchId: string,
  section: "files" | "commits" | "collections" | "documentation" | "" = "",
  identifier?: string,
  options?: BranchOptions
) {
  if (shouldBeMainInsteadOfMaster(branchId)) {
    branchId = "main";
  }
  const tokens = [`/projects/${projectId}/branches/${branchId}`, section];
  if (identifier) {
    tokens.push(identifier);
  }

  return urlPathJoin(...tokens) + query(options);
}

export function draftBranchPath(projectId: string, draftBranchId: string) {
  return urlPathJoin("/projects", projectId, "draftBranches", draftBranchId);
}

export function commitPath(
  projectId: string,
  branchId: string,
  sha: string,
  commentId?: string
) {
  if (shouldBeMainInsteadOfMaster(branchId)) {
    branchId = "main";
  }
  const p = `/projects/${projectId}/branches/${branchId}/commits/${sha}`;
  if (commentId) {
    return p + `?commentId=${commentId}`;
  }
  return p;
}

export function filePath(
  projectId: string,
  branchId: string,
  fileId: string,
  queryParams?: FilePathQueryParams
) {
  if (shouldBeMainInsteadOfMaster(branchId)) {
    branchId = "main";
  }
  return `/projects/${projectId}/branches/${branchId}/files/${fileId}${query(
    queryParams
  )}`;
}

export function pagePath(
  projectId: string,
  branchId: string,
  fileId: string,
  pageId: string,
  queryParams?: FilePathQueryParams
) {
  if (shouldBeMainInsteadOfMaster(branchId)) {
    branchId = "main";
  }
  return `/projects/${projectId}/branches/${branchId}/files/${fileId}/pages/${pageId}${query(
    queryParams
  )}`;
}

export function layerLocation(
  projectId: string,
  branchId: string,
  sha: string,
  fileId: string,
  layerId: string,
  options: LayerOptions = empty.object
) {
  const {
    layerSetParams,
    commentId,
    mode,
    nonce,
    selected,
    previousPrototypeLocation,
    present,
    preview,
    collectionLayerId,
    collectionId,
    originalSymbolInstanceLocation,
  } = options;
  const currentLocation = history.getCurrentLocation();
  let modal = true;
  let returnTo = undefined;
  // if we are moving between layer detail pages, we should respect
  // the modal setting from the current location to avoid stacking
  // a layer detail modal on top of layer detail.
  if (currentLocation.pathname.includes("/layers/")) {
    modal = currentLocation.state && currentLocation.state.modal;
  }

  if (modal) {
    if (options.returnTo) {
      returnTo = options.returnTo;
    } else if (currentLocation.state && currentLocation.state.returnTo) {
      returnTo = currentLocation.state.returnTo;
    } else {
      returnTo = currentLocation;
    }
  }
  if (shouldBeMainInsteadOfMaster(branchId)) {
    branchId = "main";
  }

  const pathname = `/projects/${projectId}/branches/${branchId}/commits/${sha}/files/${fileId}/layers/${layerId}`;

  return {
    pathname,
    state: {
      layerSetParams,
      modal,
      previousPrototypeLocation,
      returnTo,
      originalSymbolInstanceLocation,
    },
    query: {
      commentId,
      mode,
      collectionLayerId: nonce || collectionLayerId,
      selected,
      sha: options.sha,
      present: present ? present.toString() : undefined,
      preview: preview !== undefined ? preview.toString() : undefined,
      collectionId: collectionId
        ? collectionId
        : layerSetParams && layerSetParams.type === "collection"
        ? layerSetParams.collectionId
        : undefined,
    },
  };
}

export function organizationsPath(): string {
  return `/organizations`;
}

export function organizationHomePath(organizationId: string): string {
  return `/organizations/${organizationId}`;
}

export function organizationProjectsPath(organizationId: string): string {
  return `/organizations/${organizationId}/projects`;
}

export function organizationSettingsPath(organizationId: string): string {
  return `/organizations/${organizationId}/edit`;
}

export function organizationActivitiesPath(organizationId: string): string {
  return `/organizations/${organizationId}/activity`;
}

export function reviewsPath(
  organizationId: string,
  filter?: {|
    branchesReviewsFilter?: "assigned-to-me",
  |}
): LocationDescriptor {
  if (filter) {
    return {
      pathname: `/organizations/${organizationId}/reviews`,
      query: { ...filter },
    };
  }

  return `/organizations/${organizationId}/reviews`;
}

export function organizationReportingPath(organizationId: string): string {
  return `/organizations/${organizationId}/reporting`;
}

export function organizationEmojisPath(organizationId: string): string {
  return `/organizations/${organizationId}/emojis`;
}

export function organizationPeoplePath(
  organizationId: string,
  role?: "all" | "owner" | "guest" | "contributor"
): string {
  if (role) {
    return `/organizations/${organizationId}/people?role=${role}`;
  }
  return `/organizations/${organizationId}/people`;
}

export function organizationTeamsPath(organizationId: string): string {
  return `/organizations/${organizationId}/teams`;
}

export function organizationTeamPath(
  params: OrganizationTeamDescriptor,
  section?: "members" | "projects" | "settings"
): string {
  return urlPathJoin(
    `/organizations/${params.organizationId}/teams/${params.teamId}`,
    section || ""
  );
}

export function projectMembersPath(
  projectId: string,
  tab: ProjectMembersTab,
  search?: string
): string {
  if (search) {
    return `/projects/${projectId}/${tab}?s=${search}`;
  }
  return `/projects/${projectId}/${tab}`;
}

export function projectPeoplePath(
  projectId: string,
  role?: "all" | "owner" | "guest"
): string {
  if (role) {
    return `/projects/${projectId}/people?role=${role}`;
  }
  return `/projects/${projectId}/people`;
}

export function projectCollectionsPath(projectId: string) {
  return urlPathJoin(projectPath(projectId), "collections");
}

export function collectionPath(
  projectId: string,
  branchId: string,
  collectionId: string,
  sectionId?: string
) {
  let path = urlPathJoin(
    branchPath(projectId, branchId, "collections"),
    collectionId
  );

  if (sectionId) {
    path = path.concat(query({ sectionId }));
  }

  return path;
}

export function collectionLocation(
  projectId: string,
  branchId: string,
  collectionId: string,
  state?: Object = {}
) {
  const currentLocation = history.getCurrentLocation();
  let returnTo = undefined;

  if (state.returnTo) {
    returnTo = state.returnTo;
  } else if (currentLocation.state && currentLocation.state.returnTo) {
    returnTo = currentLocation.state.returnTo;
  } else {
    returnTo = currentLocation;
  }

  return {
    pathname: collectionPath(projectId, branchId, collectionId),
    state: {
      modal: true,
      returnTo,
      ...state,
    },
  };
}

export function collectionLayerPresentationPath(
  projectId: string,
  branchId: string,
  collectionId: string,
  collectionLayerId: string
) {
  return urlPathJoin(
    collectionPath(projectId, branchId, collectionId),
    "layers",
    collectionLayerId,
    "presentation"
  );
}

export function collectionLayerPresentationLocation(
  projectId: string,
  branchId: string,
  collectionId: string,
  collectionLayerId: string
) {
  return {
    pathname: collectionLayerPresentationPath(
      projectId,
      branchId,
      collectionId,
      collectionLayerId
    ),
    state: { modal: true },
  };
}

type ShareLinkOptions = $ReadOnly<{|
  ...LayerOptions,
  defaultFullScreen?: boolean,
  layerSetParams?: LayerSetParams,
|}>;

export function shareLinkPath(
  shareLinkId: string,
  options: ShareLinkOptions = empty.object
) {
  const {
    layerSetParams,
    defaultFullScreen,
    returnTo,
    previousPrototypeLocation,
    originalSymbolInstanceLocation,
    ...query
  } = options;

  return {
    pathname: `/share/${shareLinkId}`,
    state: { layerSetParams, defaultFullScreen, modal: true },
    query,
  };
}

export function descriptorPath(
  descriptor:
    | Abstract.OrganizationDescriptor
    | Abstract.ProjectDescriptor
    | Abstract.BranchDescriptor
) {
  if (descriptor.branchId) {
    return branchPath(descriptor.projectId, descriptor.branchId);
  }
  if (descriptor.projectId) {
    return projectPath(descriptor.projectId);
  }
  if (descriptor.organizationId) {
    return organizationPath(descriptor.organizationId);
  }
  return "";
}

export function abstractWorkflowTutorialPath(organizationId: string) {
  return `/organizations/${organizationId}/tutorials/abstract-workflow`;
}

function shouldBeMainInsteadOfMaster(branchId: string) {
  return branchId === BRANCH_ID_MASTER && USE_MAIN_INSTEAD_OF_MASTER;
}
