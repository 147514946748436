// @flow
import classnames from "classnames";
import pluralize from "pluralize";
import * as React from "react";
import Facepile from "core/components/Facepile";
import Flex from "core/components/Flex";
import PlaceholderText from "core/components/PlaceholderText";
import TeamAvatar from "core/components/TeamAvatar";
import TextHighlight from "core/components/TextHighlight";
import type { Team, User } from "core/types";
import style from "./style.scss";

type Props = {
  isLoading: boolean,
  team: Team,
  className: string,
  teamMembers: User[],
  teamMembershipsTotal: number,
  component: "li" | "div",
  children: React.Node,
  highlight?: string,
};

export default function TeamListItem(props: Props) {
  const WrapperComponent = props.component;
  return (
    <WrapperComponent className={classnames(style.item, props.className)}>
      <TeamAvatar teamId={props.team.id} size={32} />
      <Flex className={style.center}>
        <Flex column className={style.info}>
          <div className={style.teamName}>
            <TextHighlight text={props.team.name} highlight={props.highlight} />
          </div>
          {props.isLoading ? (
            <PlaceholderText max={50} min={40} />
          ) : (
            <div className={style.meta}>{`${
              props.teamMembershipsTotal
            } ${pluralize("member", props.teamMembershipsTotal)}`}</div>
          )}
        </Flex>
        {props.isLoading ? (
          <PlaceholderText size="l" max={20} min={15} />
        ) : (
          <Facepile
            users={props.teamMembers}
            max={3}
            countColor={props.team.color}
          />
        )}
      </Flex>
      {props.children}
    </WrapperComponent>
  );
}
