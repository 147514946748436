// @flow
import { filter as _filter, isEmpty } from "lodash";
import * as React from "react";
import type { StarParams } from "core/actions/stars";
import Empty from "core/components/Empty";
import Error from "core/components/Empty/Error";
import NoArchivedProjects from "core/components/Empty/NoArchivedProjects";
import NoFavorites from "core/components/Empty/NoFavorites";
import NoProjects from "core/components/Empty/NoProjects";
import NoProjectsNonMac from "core/components/Empty/NoProjectsNonMac";
import NoProjectsUsernameOrg from "core/components/Empty/NoProjectsUsernameOrg";
import NoResults from "core/components/Empty/NoResults";
import Loaded from "core/components/Loaded";
import ProjectDialog from "core/components/ProjectDialog";
import ProjectSectionDialog from "core/components/ProjectSectionDialog";
import { DEFAULT_PROJECT_SORT, DEFAULT_PROJECT_FILTER } from "core/lib/lists";
import { replace, addAndRemoveQueryParams } from "core/lib/location";
import matchString from "core/lib/matchString";
import { isDesktop, isMac } from "core/lib/platform";
import type {
  Policy,
  Project,
  ProjectSort,
  ProjectFilter,
  Section,
  Organization,
} from "core/types";
import Header from "./Header";
import VirtualizedProjectsList from "./VirtualizedProjectsList";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  organizationId: string,
  query?: string,
  filter?: ProjectFilter,
  sort?: ProjectSort,
  card?: boolean,
  mobile?: boolean,
  newUser?: boolean,
  emptyComponent?: React.ComponentType<{| organization: Organization |}>,
  createProjectButton?: React.Node,
  onSearch: (query: string) => void,
  onListChange: (type: string) => void,
|};

export type StateProps = {|
  policy: Policy,
  organization: ?Organization,
  sections: Section[],
  sectionProjects: { [sectionId: string]: Project[] },
  projects: Project[],
  empty: boolean,
  hasError: boolean,
  isOffline: boolean,
  isLoading: boolean,
  canUseSections?: boolean,
  canCreateSections?: boolean,
  canCreateProjects?: boolean,
|};

export type DispatchProps = {|
  onLoad: () => void,
  onToggleStar: (StarParams) => void,
  onProjectClick: (projectId: string) => void,
  onStartSyncingProject: (projectId: string) => void,
|};

export type StorageProps = {|
  filter: ProjectFilter,
  sort: ProjectSort,
  onSort: (sort: ProjectSort) => void,
  onFilter: (filter?: ProjectFilter) => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
  ...StorageProps,
};

type State = { showCreateSection: boolean, showCreateProject: boolean };

class OrganizationProjects extends React.Component<Props, State> {
  state = { showCreateSection: false, showCreateProject: false };

  static defaultProps = {
    sections: [],
    sectionProjects: {},
  };

  componentDidMount() {
    if (isDesktop || this.props.newUser) {
      return;
    }
    /*
      We don't update the desktop location because desktop doesn't expose or
      depend on the location in any way. By returning early, we save ourselves
      a render.
    */
    const { sort, filter } = this.props;
    const addable = {};
    const removeable = [];

    if (sort !== DEFAULT_PROJECT_SORT) {
      addable.sort = sort;
    } else {
      removeable.push("sort");
    }

    if (filter !== DEFAULT_PROJECT_FILTER) {
      addable.filter = filter;
    } else {
      removeable.push("filter");
    }

    if (!isEmpty(addable) || removeable.length) {
      replace(addAndRemoveQueryParams(addable, removeable));
    }
  }

  handleSearchChange = (event: SyntheticInputEvent<>) => {
    this.props.onSearch(event.target.value);
  };

  handleFilterChange = (filter: ProjectFilter) => (event: SyntheticEvent<>) => {
    event.preventDefault();
    this.props.onFilter(filter);
  };

  handleCreateSectionOpen = () => this.setState({ showCreateSection: true });
  handleCreateSectionClose = () => this.setState({ showCreateSection: false });

  handleCreateProjectOpen = () => this.setState({ showCreateProject: true });
  handleCreateProjectClose = () => this.setState({ showCreateProject: false });

  showCards = () => {
    this.props.onListChange("grid");
  };

  showList = () => {
    this.props.onListChange("list");
  };

  showWebEmptyStates = () => {
    if (!isMac()) {
      return <NoProjectsNonMac />;
    }
    const { organization } = this.props;
    if (organization && organization.isUsernameOrganization) {
      return <NoProjectsUsernameOrg organizationId={organization.id} />;
    }

    return (
      <Empty
        icon="project"
        title="No projects, yet"
        description="Create a project to get started."
      />
    );
  };

  getSections = (): Section[] => {
    if (!this.props.query) {
      return this.props.sections;
    }

    return _filter(this.props.sections, (section) =>
      matchString(section.name, this.props.query)
    );
  };

  renderEmpty() {
    const {
      canUseSections,
      canCreateProjects,
      empty,
      emptyComponent,
      filter,
      hasError,
      organization,
      organizationId,
      query,
    } = this.props;

    if (hasError) {
      return <Error flex />;
    }

    if (empty) {
      if (emptyComponent) {
        const EmptyComponent = emptyComponent;

        if (!organization) {
          return null;
        }

        return <EmptyComponent organization={organization} />;
      }

      if (!isDesktop) {
        return this.showWebEmptyStates();
      }

      return (
        <NoProjects
          flex
          canCreateProjects={canCreateProjects}
          createProjectAction={this.handleCreateProjectOpen}
          organizationId={organizationId}
          isUsernameOrganization={
            !!organization && organization.isUsernameOrganization
          }
        />
      );
    }

    if (query) {
      return (
        <NoResults
          flex
          type={canUseSections ? "projects or sections" : "projects"}
          term={query}
        />
      );
    }

    if (filter === "archived") {
      return <NoArchivedProjects flex />;
    }

    if (filter === "starred") {
      return <NoFavorites canUseSections={canUseSections} flex />;
    }

    if (!isDesktop) {
      return this.showWebEmptyStates();
    }

    return (
      <NoProjects
        flex
        canCreateProjects={canCreateProjects}
        createProjectAction={this.handleCreateProjectOpen}
        organizationId={organizationId}
        isUsernameOrganization={
          !!organization && organization.isUsernameOrganization
        }
      />
    );
  }

  render() {
    const {
      filter,
      query,
      sort,
      projects,
      organizationId,
      isLoading,
      canUseSections,
      canCreateSections,
      canCreateProjects,
    } = this.props;

    const showSections = filter === "active" && sort === "alpha";
    const sections = this.getSections();
    const isEmpty =
      !projects.length &&
      (!canUseSections ||
        (!showSections && filter !== "starred") ||
        !sections.length);

    const shouldRenderList =
      (!canUseSections || !showSections || !!query) && projects.length > 0;
    const shouldRenderSections =
      canUseSections && (showSections || filter === "starred");

    return (
      <React.Fragment>
        <Header
          canCreateProjects={canCreateProjects}
          canCreateSections={this.props.canCreateSections}
          filter={this.props.filter}
          isCard={this.props.card}
          isLoading={this.props.isLoading}
          isOffline={this.props.isOffline}
          mobile={this.props.mobile}
          onCreateProject={this.handleCreateProjectOpen}
          onCreateSection={this.handleCreateSectionOpen}
          onFilterChange={this.handleFilterChange}
          onSearch={this.handleSearchChange}
          onSelectCardDisplay={this.showCards}
          onSelectListDisplay={this.showList}
          onSort={this.props.onSort}
          organizationId={this.props.organizationId}
          query={this.props.query}
          sort={this.props.sort}
        />

        <Loaded loading={isLoading} title="Loading projects…" flex>
          {() => {
            if (isEmpty) {
              return this.renderEmpty();
            }

            return (
              <div className={style.wrap}>
                <VirtualizedProjectsList
                  key={`${organizationId}-${filter}-${sort}`}
                  filter={filter}
                  query={query}
                  sort={sort}
                  card={this.props.card}
                  mobile={this.props.mobile}
                  isOffline={this.props.isOffline}
                  projects={projects}
                  sections={sections}
                  sectionProjects={this.props.sectionProjects}
                  organizationId={organizationId}
                  onProjectClick={this.props.onProjectClick}
                  onStartSyncingProject={this.props.onStartSyncingProject}
                  onToggleStar={this.props.onToggleStar}
                  canUseSections={canUseSections}
                  canCreateSections={canCreateSections}
                  shouldRenderList={shouldRenderList}
                  shouldRenderSections={shouldRenderSections}
                />
              </div>
            );
          }}
        </Loaded>
        {canCreateSections && (
          <ProjectSectionDialog
            isOpen={this.state.showCreateSection}
            onClose={this.handleCreateSectionClose}
            organizationId={organizationId}
          />
        )}
        {canCreateProjects && (
          <ProjectDialog
            isOpen={this.state.showCreateProject}
            onClose={this.handleCreateProjectClose}
            organizationId={organizationId}
            action="create"
          />
        )}
      </React.Fragment>
    );
  }
}

export default connector(OrganizationProjects);
