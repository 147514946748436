// @flow
import { isEqual, forEach } from "lodash";
import { static as Immutable } from "seamless-immutable";
import type { Action, EntitiesOfType } from "core/types";

type EntityReducer<EntityType> = (
  state?: EntitiesOfType<EntityType>,
  action: Action
) => EntitiesOfType<EntityType>;

function mergeEntities<EntityType>(
  state: EntitiesOfType<EntityType>,
  newEntities?: EntitiesOfType<EntityType>
): EntitiesOfType<EntityType> {
  const changedEntities = {};

  forEach(newEntities, (entity, id) => {
    if (!isEqual(state[id], entity)) {
      changedEntities[id] = entity;
    }
  });

  return Immutable.merge(state, changedEntities);
}

export default function createEntityReducer<EntityType>(
  entityType: string,
  extraReducer?: (
    EntitiesOfType<EntityType>,
    Action
  ) => EntitiesOfType<EntityType>
): EntityReducer<EntityType> {
  return function (
    state?: EntitiesOfType<EntityType> = Immutable.from({}),
    action: Action
  ): EntitiesOfType<EntityType> {
    let result = state;

    switch (action.type) {
      case "core/ENTITIES_RECEIVED": {
        result = action.payload[entityType]
          ? mergeEntities(state, action.payload[entityType])
          : state;
        break;
      }
      case "core/ENTITIES_REPLACED": {
        result =
          action.payload.type === entityType
            ? mergeEntities(
                Immutable.without(state, action.payload.ids),
                action.payload.entities
              )
            : state;
        break;
      }
      case "core/ENTITIES_DELETED": {
        result = action.payload[entityType]
          ? Immutable.without(state, action.payload[entityType])
          : state;
        break;
      }
      case "SIGNED_OUT": {
        result = Immutable.from({});
        break;
      }
      default:
    }

    return extraReducer ? extraReducer(result, action) : result;
  };
}
