// @flow
import { normalize } from "normalizr";
import {
  organization as organizationSchema,
  organizationJoinedResponseSchema,
} from "core/schema";
import type { Organization } from "core/types";
import { envelopeSchema } from "./envelope";

export function normalizeOrganizationsResponse(response: Object) {
  return normalize(response, envelopeSchema([organizationSchema]));
}

export function normalizeOrganization(organization: Organization) {
  return normalize(organization, organizationSchema);
}

export function normalizeOrganizationResponse(response: Object) {
  return normalize(response, envelopeSchema(organizationSchema));
}

export function normalizeOrganizationJoinedResponse(response: Object) {
  return normalize(response, organizationJoinedResponseSchema);
}
