// @flow
import classnames from "classnames";
import * as React from "react";
import Flex from "core/components/Flex";
import OrganizationLogo from "core/components/OrganizationLogo";
import onboardingStyle from "core/components/onboarding.scss";
import type { Invitation } from "core/types";

type Props = {|
  className?: string,
  invitation: Invitation,
|};

export default function InvitationPanel(props: Props) {
  const { className, invitation } = props;
  const { createdByUserName, organizationName, projectName, role } = invitation;

  const inviteMessage = createdByUserName ? (
    <React.Fragment>
      <strong>{createdByUserName}</strong> has invited you to join
    </React.Fragment>
  ) : (
    "You have been invited to join"
  );

  return (
    <Flex align="center" className={className} column>
      <OrganizationLogo
        src={invitation.organizationLogoUrl}
        alt={invitation.organizationName}
        rounded={invitation.isUsernameOrganization}
        size={64}
      />
      <div
        className={classnames(
          onboardingStyle.subHeading,
          onboardingStyle.largeCopy
        )}
      >
        {projectName ? (
          <React.Fragment>
            {inviteMessage} the {projectName} project within {organizationName}{" "}
            as a <strong>{role}</strong>.
          </React.Fragment>
        ) : (
          <React.Fragment>
            {inviteMessage} <strong>{organizationName}</strong> as a{" "}
            <strong>{role}</strong>.
          </React.Fragment>
        )}
      </div>
    </Flex>
  );
}
