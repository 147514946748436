// @flow
import { without } from "lodash";
import reject from "lodash/reject";
import { ThunkAction } from "redux-thunk";
import Immutable from "seamless-immutable";
import { getCurrentUserId } from "abstract-di/selectors";
import { entitiesReceived } from "core/actions/entities";
import {
  AddReactionRequest,
  RemoveReactionRequest,
} from "core/requests/reactions";
import {
  getReactions,
  reactionExists,
  getReactionWithIndex,
} from "core/selectors/reactions";
import type { ReactionDescriptor } from "core/types";

export function toggleReactionForCurrentUser(params: {
  name: string,
  commentId: string,
  projectId: string,
}): ThunkAction {
  return (dispatch, getState) => {
    const userId = getCurrentUserId(getState());

    const descriptor = {
      ...params,
      userId,
    };

    if (reactionExists(getState(), descriptor)) {
      dispatch(deleteReaction(descriptor));
    } else {
      dispatch(createReaction(descriptor));
    }
  };
}

export function createReaction(params: ReactionDescriptor): ThunkAction {
  return (dispatch) => {
    dispatch(addReaction(params));
    dispatch(
      AddReactionRequest.perform({
        params,
        onError: (error) => {
          dispatch(removeReaction(params));
        },
      })
    );
  };
}

export function addReaction(params: ReactionDescriptor): ThunkAction {
  return (dispatch, getState) => {
    const state = getState();

    if (reactionExists(state, params)) {
      return;
    }

    const reactions = getReactions(state, params) || [];
    const { reaction, index } = getReactionWithIndex(state, params);

    const updatedReaction = reaction
      ? Immutable.setIn(
          reaction,
          ["userIds"],
          [...reaction.userIds, params.userId]
        )
      : Immutable.from({
          commentId: params.commentId,
          name: params.name,
          userIds: [params.userId],
        });

    const updatedReactions =
      index > -1
        ? Immutable.set(reactions, index, updatedReaction)
        : Immutable.from([...reactions, updatedReaction]);

    dispatch(
      entitiesReceived({
        reactions: {
          [params.commentId]: updatedReactions,
        },
      })
    );
  };
}

export function deleteReaction(params: ReactionDescriptor): ThunkAction {
  return (dispatch) => {
    dispatch(removeReaction(params));
    dispatch(
      RemoveReactionRequest.perform({
        params,
        onError: (error) => {
          dispatch(addReaction(params));
        },
      })
    );
  };
}

export function removeReaction(params: ReactionDescriptor): ThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    const reactions = getReactions(state, params);
    if (!reactions) {
      return;
    }

    const { reaction, index } = getReactionWithIndex(state, params);

    if (!reaction || !reactionExists(state, params)) {
      return;
    }

    const updatedReaction = Immutable.setIn(
      reaction,
      ["userIds"],
      without(reaction.userIds, params.userId)
    );

    const updatedReactions =
      updatedReaction.userIds.length && index > -1
        ? Immutable.set(reactions, index, updatedReaction)
        : Immutable.from(
            reject(reactions, (reaction) => reaction.name === params.name)
          );

    dispatch(
      entitiesReceived({
        reactions: {
          [params.commentId]: updatedReactions,
        },
      })
    );
  };
}
