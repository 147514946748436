// @flow
/* global */
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import * as React from "react";
import config from "abstract-di/config";
import { createZendeskTicket, createZendeskAttachment } from "core/api/zendesk";
import Button from "core/components/Button";
import ErrorBoundary from "core/components/ErrorBoundary";
import Flex from "core/components/Flex";
import { BaseFormNotice } from "core/components/FormNotice";
import Heading from "core/components/Heading";
import Icon from "core/components/Icon";
import Input from "core/components/Input";
import Note from "core/components/Note";
import Scrollable from "core/components/Scrollable";
import Select from "core/components/Select";
import ValidationError from "core/components/ValidationError";
import { isDesktop } from "core/lib/platform";
import { helpCenterUrl } from "core/lib/urls";
import type { Organization } from "core/types";
import AttachmentsDropzone from "./AttachmentsDropzone";
import Preview from "./Preview";
import style from "./style.scss";

export type Props = {
  organizations: Organization[],
  isOffline: boolean,
  organizationId: ?string,
  onRequestClose?: () => void,
};

export type State = {
  title: string,
  organizationId: string,
  isSubmitted: boolean,
  isLoading: boolean,
  userEmail: string,
  topic: string,
  subject: string,
  description: string,
  files: File[],
  hasRejectedFiles: boolean,
  hasCorruptedImage: boolean,
  hasServerError: boolean,
};

const REJECTED_FILE_ERROR =
  "Only 'jpeg', 'png', 'gif', 'txt' and 'zip' files up to 20MB are allowed.";

const TOPIC_OPTIONS = [
  {
    label: "versions_ticket",
    value: "Branches, Abstract’s version control software",
  },
  {
    label: "admin_ticket",
    value: "My settings, organization, and billing",
  },
  {
    label: "feature_ticket",
    value: "Suggesting a feature",
  },
  {
    label: "sales_ticket",
    value: "Renewing, upgrading, or starting a new subscription",
  },
  {
    label: "other_ticket",
    value: "Not sure but I want to talk to someone",
  },
];

export default class SupportTicket extends React.Component<Props, State> {
  state = {
    title: "Contact Support",
    organizationId: "",
    isSubmitted: false,
    isLoading: false,
    userEmail: "",
    topic: "",
    subject: "",
    description: "",
    files: [],
    hasRejectedFiles: false,
    hasCorruptedImage: false,
    hasServerError: false,
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (nextProps.organizationId && !prevState.organizationId) {
      return {
        organizationId: nextProps.organizationId,
      };
    }
    return null;
  }

  setUserEmail = (userEmail: string) => {
    this.setState({ userEmail });
  };

  handleOrganizationPicker = (event: SyntheticInputEvent<>) => {
    const { value } = event.target;
    this.setState({ organizationId: value });
  };

  handleRequestClose = () => {
    this.setState({ isSubmitted: false });
    this.props.onRequestClose && this.props.onRequestClose();
  };

  onFormSubmit = () => {
    this.setState({ isSubmitted: true });
  };

  setIsLoadingOn = () => {
    this.setState({ isLoading: true });
  };

  setIsLoadingOff = () => {
    this.setState({ isLoading: false });
  };

  renderHelpNotice() {
    return (
      <BaseFormNotice
        icon="education"
        heading="Try our Help Center"
        body="We’ve assembled a collection of resources for you to use as you get to know Abstract."
        className={style.notice}
        link={{ text: "View Help Center", path: helpCenterUrl() }}
        type="allgood"
        // We are rendering the base non-connected version of this component, but the dispatch openSupportTicket prop still needs to be passed down here. A hacky no-op is solving this issue.
        openSupportTicket={() => {}}
      />
    );
  }

  handleChange = (event: SyntheticInputEvent<>) => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async (event: SyntheticEvent<>) => {
    event.preventDefault();
    const {
      organizationId,
      topic,
      subject,
      description,
      files,
      hasServerError,
    } = this.state;
    const hasError = isEmpty(subject) || isEmpty(description);
    const uploads = [];
    this.setIsLoadingOn();

    if (hasServerError) {
      this.setState({ hasServerError: false });
    }

    if (!hasError) {
      this.setRejectedFilesOff();

      if (files.length) {
        for (const file of files) {
          const token = await createZendeskAttachment(file);
          uploads.push(token);
        }
      }

      try {
        const response = await createZendeskTicket({
          organizationId,
          topic,
          subject,
          description,
          uploads,
          version: config.version,
        });

        this.setUserEmail(response.email);
        this.onFormSubmit();
      } catch (error) {
        this.setState({ hasServerError: true });
        console.error(error);
      }
    }
    this.setIsLoadingOff();
  };

  handleFilesAttached = (files: File[]) => {
    this.setState({ files: [...this.state.files, ...files] });
  };

  handleFileRemove = (index: number) => {
    const files = this.state.files;
    files.splice(index, 1);
    this.setState({ files });
  };

  setRejectedFilesOn = () => {
    this.setState({ hasRejectedFiles: true });
  };

  setRejectedFilesOff = () => {
    this.setState({ hasRejectedFiles: false });
  };

  onError = () => {
    this.setState({ hasCorruptedImage: true });
  };

  renderOrganizationPicker() {
    const { organizations } = this.props;
    const { organizationId } = this.state;

    return (
      <Select
        id="organization-picker"
        name="Organization"
        value={organizationId || ""}
        onChange={this.handleOrganizationPicker}
        disabled={this.state.isLoading}
        className={classnames(style.organizationPicker, style.inputWrapper)}
        required
      >
        <option value="">— Select an organization —</option>
        {map(organizations, (organization) => {
          return (
            <option value={organization.id} key={organization.id}>
              {organization.name}
            </option>
          );
        })}
      </Select>
    );
  }

  render() {
    const {
      isSubmitted,
      userEmail,
      isLoading,
      topic,
      subject,
      description,
      files,
      hasRejectedFiles,
      hasServerError,
    } = this.state;

    const { isOffline } = this.props;

    return (
      <Flex
        column
        className={classnames(style.windowContainer, {
          [style.formSubmitted]: isSubmitted,
        })}
      >
        {isDesktop && (
          <header
            role="banner"
            aria-label="Contact Support"
            className={style.header}
          />
        )}
        <div className={style.windowContent}>
          {!isSubmitted ? (
            <ErrorBoundary>
              <form
                className={classnames(style.form, {
                  [style.webForm]: !isDesktop,
                })}
                onSubmit={this.handleSubmit}
              >
                <Flex className={style.heading}>
                  <Heading size="xxxl" className={style.title} level="1">
                    {this.state.title}
                  </Heading>
                  <Flex column className={style.headingRight}>
                    <span>Reporting on behalf of</span>
                    {this.renderOrganizationPicker()}
                  </Flex>
                </Flex>
                {this.renderHelpNotice()}
                <Flex align="flex-start" className={style.hoursNotice}>
                  <Icon className={style.clockIcon} type="clock" />
                  <div>
                    Support is available{" "}
                    <span className={style.openHours}>
                      Monday-Friday, 05:00-18:00 Pacific Time
                    </span>
                    . We observe all U.S. holidays.
                  </div>
                </Flex>
                <Flex className={classnames(style.formInputs)}>
                  <div className={style.fields}>
                    <Select
                      className={style.inputWrapper}
                      onChange={this.handleChange}
                      label="Topic"
                      name="topic"
                      value={topic}
                      requiredTag
                      disabled={isLoading}
                      required
                    >
                      <option value="">Select a topic from this menu…</option>
                      {TOPIC_OPTIONS.map((topic) => {
                        return (
                          <option key={topic.label} value={topic.label}>
                            {topic.value}
                          </option>
                        );
                      })}
                    </Select>
                    <Input
                      className={style.inputWrapper}
                      onChange={this.handleChange}
                      placeholder="Describe your issue in a few words"
                      label="Subject"
                      name="subject"
                      value={subject}
                      required
                      requiredTag
                      disabled={isLoading}
                    />
                    <Input
                      type="textarea"
                      className={style.inputWrapper}
                      onChange={this.handleChange}
                      placeholder="Share any details you can about your experience…"
                      label="Description"
                      name="description"
                      value={description}
                      required
                      requiredTag
                      rows="7"
                      disabled={isLoading}
                    />
                  </div>
                  <div className={style.attachments}>
                    <Heading size="m" level="3">
                      Attachments
                    </Heading>
                    <AttachmentsDropzone
                      isLoading={isLoading}
                      hasRejectedFiles={hasRejectedFiles}
                      onChange={this.handleFilesAttached}
                      setRejectedFilesOn={this.setRejectedFilesOn}
                      setRejectedFilesOff={this.setRejectedFilesOff}
                    />
                    {!hasRejectedFiles ? (
                      <Note>
                        Files, logs, and screenshots can help pinpoint issues
                        more quickly.
                      </Note>
                    ) : (
                      <ValidationError
                        error={REJECTED_FILE_ERROR}
                        className={style.validationError}
                      />
                    )}
                    <Scrollable className={style.files}>
                      {files.map((file, index) => {
                        return (
                          <Flex
                            className={style.file}
                            key={`${index}-${file.name}`}
                          >
                            {/^image\//.test(file.type) ? (
                              <Preview file={file} />
                            ) : (
                              <Icon
                                className={style.thumbnail}
                                type="file"
                                medium
                              />
                            )}
                            <span className={style.fileName}>{file.name}</span>
                            <Button
                              nude
                              icon="trash"
                              title="Remove"
                              onClick={() => this.handleFileRemove(index)}
                              disabled={isLoading}
                            />
                          </Flex>
                        );
                      })}
                    </Scrollable>
                  </div>
                </Flex>
                <Flex className={style.windowFooter}>
                  <span
                    className={classnames(style.errorNotice, {
                      [style.onError]: hasServerError,
                    })}
                  >
                    Please make sure you are connected to the internet and try
                    again.
                  </span>
                  {isDesktop && (
                    <Button
                      large
                      onClick={this.props.onRequestClose}
                      disabled={isLoading}
                    >
                      Cancel
                    </Button>
                  )}
                  <Button
                    large
                    primary
                    icon={isLoading ? "spinner" : undefined}
                    type="submit"
                    disabled={isOffline || isLoading}
                    className={style.rightButton}
                  >
                    {!isLoading ? "Send to Support" : "Sending to Support…"}
                  </Button>
                </Flex>
              </form>
            </ErrorBoundary>
          ) : (
            <Flex align="center" column className={style.submissionNotice}>
              <Icon type="checkmark" light className={style.checkmark} />
              <Heading size="xxxl" className={style.title} level="1">
                Thanks for contacting us!
              </Heading>
              <Note className={style.note}>
                We’ll get back to you shortly at the email address listed as
                <br />
                your primary for this account: <strong>{userEmail}</strong>
              </Note>
              {isDesktop && (
                <Flex className={style.windowFooter}>
                  <Button
                    primary
                    title="Got it"
                    onClick={this.handleRequestClose}
                    className={style.rightButton}
                  >
                    Got it
                  </Button>
                </Flex>
              )}
            </Flex>
          )}
        </div>
      </Flex>
    );
  }
}
