// @flow
import Clipboard from "clipboard";
import * as React from "react";
import Button, { type ButtonElement } from "core/components/Button";
import type { InputShare, LayerShellMode } from "core/types";
import connector from "./connector";

export type StateProps = {|
  error?: boolean,
  isGenerating?: boolean,
  url: ?string,
  isOnline?: boolean,
|};

export type DispatchProps = {|
  onLoad: () => void,
  showToast: () => void,
  onCopy: () => void,
|};

export type OwnProps = {|
  organizationId?: string,
  inputShare: InputShare,
  disabled?: boolean,
  className?: string,
  children?:
    | React.Node
    | (({
        onClick: (event: SyntheticEvent<>) => void,
        disabled: boolean,
      }) => React.Node),
  icon?: ?string,
  tooltip?: boolean,
  nude?: boolean,
  tint?: boolean,
  hideLabel?: boolean,
  primary?: boolean,
  fullwidth?: boolean,
  onCopySuccess?: () => void,
  isMobile?: boolean,
  autoFocus?: boolean,
  doNotCreateShareLink?: boolean,
  mode?: LayerShellMode,
  url?: ?string,
  large?: boolean,
  noText?: boolean,
  role?: string,
|};

export type Props = {
  ...StateProps,
  ...DispatchProps,
  ...OwnProps,
};

type State = {
  isCopying: boolean,
};

class CopyLinkButton extends React.Component<Props, State> {
  state = { isCopying: false };
  button: ?React.ElementRef<ButtonElement>;
  clipboard: Clipboard;

  static defaultProps = {
    icon: "share",
    tooltip: true,
    nude: true,
    tint: true,
    hideLabel: false,
  };

  componentDidMount() {
    this.button && this.setUpClipboard(this.button);
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.isOnline !== this.props.isOnline && this.props.isOnline) {
      this.props.onLoad();
    }
  }

  componentWillUnmount() {
    if (this.clipboard) {
      this.clipboard.destroy();
    }
  }

  setUpClipboard = (button: ?React.ElementRef<ButtonElement>) => {
    this.clipboard = new Clipboard(this.button, {
      text: () => this.props.url,
    });

    this.clipboard.on("success", () => {
      setTimeout(() => {
        this.setState({ isCopying: false });
        this.props.showToast();
        this.props.onCopy && this.props.onCopy();
      }, 250);

      this.props.onCopySuccess && this.props.onCopySuccess();
    });
  };

  copyToClipboard = (event: SyntheticEvent<>) => {
    event.stopPropagation();
    this.setState({ isCopying: true });
  };

  getMessage() {
    if (this.props.isGenerating) {
      return "Generating…";
    }

    if (this.props.error) {
      return "Could not generate link";
    }

    let type = this.props.inputShare.kind || "share";

    if (
      this.props.inputShare.options &&
      this.props.inputShare.options.sectionId
    ) {
      type = "section";
    } else if (this.props.inputShare.descriptor.commentId) {
      type = "comment";
    }

    return `Copy a link to ${type}`;
  }

  buttonRef = (ref) => {
    this.button = ref;
  };

  render() {
    if (typeof this.props.children === "function") {
      return this.props.children({
        onClick: this.copyToClipboard,
        ref: this.buttonRef,
        disabled: this.props.url ? false : true,
      });
    }

    const icon =
      this.props.icon && (this.state.isCopying || this.props.isGenerating)
        ? "spinner"
        : this.props.icon;

    return (
      <Button
        autoFocus={this.props.autoFocus}
        className={this.props.className}
        nude={this.props.isMobile || this.props.nude}
        tint={this.props.isMobile || this.props.tint}
        primary={this.props.isMobile ? false : this.props.primary}
        fullwidth={this.props.fullwidth}
        icon={icon ? icon : undefined}
        title={this.props.tooltip ? this.getMessage() : undefined}
        innerRef={this.buttonRef}
        hideLabel={this.props.hideLabel}
        onClick={this.copyToClipboard}
        disabled={
          !!(this.props.disabled || this.props.isGenerating || this.props.error)
        }
        qaSelector="copyLinkButton"
        role={this.props.role}
        large={this.props.large}
      >
        {this.props.isMobile || this.props.noText
          ? undefined
          : this.props.children || "Share"}
      </Button>
    );
  }
}

export default connector(CopyLinkButton);
