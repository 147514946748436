// @flow
import { connect } from "react-redux";
import { trackEvent } from "core/actions/analytics";
import {
  SubscriptionCancelRequest,
  type SubscriptionCancelRequestFormValues,
} from "core/requests/subscriptions";
import type { State, Dispatch } from "core/types";
import { openSupportWindow } from "web/di/actions";
import type { OwnProps, StateProps, DispatchProps, Props } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { organizationId } = props;

  return {
    hasError: SubscriptionCancelRequest.hasError(state, {
      organizationId,
    }),
    isLoading: SubscriptionCancelRequest.isLoadingStrict(state, {
      organizationId,
    }),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { organizationId } = props;

  return {
    cancelSubscription(
      formValues: SubscriptionCancelRequestFormValues,
      onSuccess
    ) {
      dispatch(
        SubscriptionCancelRequest.perform({
          params: { organizationId, ...formValues },
          onSuccess: () => {
            dispatch(
              trackEvent("SUBSCRIPTION_CANCELED", {
                organizationId,
                ...formValues,
              })
            );
            onSuccess();
          },
        })
      );
    },
    openSupportWindow() {
      dispatch(openSupportWindow(organizationId));
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
