// @flow
import classnames from "classnames";
import * as React from "react";
import Icon from "../Icon";
import style from "./style.scss";

export function Placeholder({
  medium,
  large,
  xl,
}: {
  medium?: boolean,
  large?: boolean,
  xl?: boolean,
}) {
  return (
    <div
      className={classnames(style.placeholderIcon, {
        [style.medium]: medium,
        [style.large]: large,
        [style.xl]: xl,
      })}
    />
  );
}

type Props = {
  type: string,
  isLibrary?: boolean,
  medium?: boolean,
  large?: boolean,
  xl?: boolean,
};

export default function FileIcon({
  type,
  isLibrary,
  medium,
  large,
  xl,
  ...rest
}: Props) {
  let name = `file-${type}`;
  if (isLibrary) {
    name += "-library";
  }
  if (medium) {
    name += "-medium";
  }
  if (large) {
    name += "-large";
  }
  if (xl) {
    name += "-xl";
  }
  return <Icon type={name} {...rest} />;
}
