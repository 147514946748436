// @flow
import { connect } from "react-redux";
import { isOnline } from "abstract-di/selectors";
import { fetchComments } from "core/actions/comments";
import { withData } from "core/components/DataLoader";
import createConnector from "core/lib/createConnector";
import { withRouter } from "core/lib/router";
import { CommentsFetchRequest } from "core/requests/comments";
import { getParentCommentAndReplies } from "core/selectors/comments";
import type { State, Dispatch } from "core/types";
import type { DispatchProps, StateProps, Props, OwnProps } from ".";

function mapStateToProps(state: State, props: Props): StateProps {
  const comments = getParentCommentAndReplies(state, {
    id: props.location.query.commentId || "",
  });

  const params = {
    projectId: props.params.projectId,
    branchId: props.params.branchId,
  };

  return {
    isLoading: CommentsFetchRequest.isLoading(state, params),
    hasError: CommentsFetchRequest.hasError(state, params),
    isOffline: !isOnline(state),
    comments,
  };
}

function mapDispatchToProps(dispatch: Dispatch, props: Props): DispatchProps {
  return {
    onLoad: () => {
      dispatch(
        fetchComments({
          projectId: props.params.projectId,
          branchId: props.params.branchId,
        })
      );
    },
  };
}

const connector = createConnector<Props, OwnProps>(
  withRouter,
  connect<Props, _, StateProps, DispatchProps, State, Dispatch>(
    mapStateToProps,
    mapDispatchToProps
  ),
  withData((props) => ({
    projectId: props.params.projectId,
    branchId: props.params.branchId,
  }))
);

export default connector;
