// @flow
import classnames from "classnames";
import { find } from "lodash";
import * as React from "react";
import Flex from "core/components/Flex";
import Input from "core/components/Input";
import Select from "core/components/Select";
import type { Section } from "core/types";
import Validations from "core/validations";
import style from "./style.scss";

type Props = {
  label?: string,
  name?: string,
  value: ?string,
  nameValue?: string,
  disabled?: boolean,
  responsive?: boolean,
  loading: boolean,
  sections: Section[],
  onChange: (sectionId: ?string) => void,
  onInputChange: (SyntheticInputEvent<>) => void,
  canCreateSections?: boolean,
};

export default class SelectSection extends React.Component<Props> {
  handleChange = (event: SyntheticInputEvent<>) => {
    this.props.onChange(event.target.value);
  };

  renderHelper = () => {
    const section = find(this.props.sections, { name: this.props.nameValue });

    return section ? (
      <Flex
        align="center"
        className={classnames(style.nameHelper, {
          [style.responsiveNameHelper]: this.props.responsive,
        })}
      >
        <span>
          Project will be added to the existing section called{" "}
          <strong>{section.name}</strong>
        </span>
      </Flex>
    ) : null;
  };

  render() {
    const {
      loading,
      responsive,
      disabled,
      sections,
      value,
      onInputChange,
      canCreateSections,
    } = this.props;

    return (
      <React.Fragment>
        <Select
          label="Section"
          disabled={disabled || loading}
          onChange={this.handleChange}
          responsive={responsive}
          value={value}
        >
          <option>No Section</option>
          <optgroup label="Add to Existing Section">
            {sections.map((section) => (
              <option value={section.id}>{section.name}</option>
            ))}
          </optgroup>
          {canCreateSections && <option value="new">Create New Section</option>}
        </Select>
        {value === "new" && (
          <div
            className={classnames(style.inputWrapper, {
              [style.responsiveInputWrapper]: responsive,
            })}
          >
            <Input
              name="sectionName"
              placeholder="Section name"
              aria-label="Section name"
              disabled={disabled || loading}
              label={responsive ? <span /> : undefined}
              responsive={responsive}
              onChange={onInputChange}
              wrapperClass={style.nameInput}
              minLength={Validations.minSectionNameLength}
              maxLength={Validations.maxSectionNameLength}
              autoFocus
            />
            {this.renderHelper()}
          </div>
        )}
      </React.Fragment>
    );
  }
}
