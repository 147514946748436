// @flow
import invariant from "invariant";
import { connect } from "react-redux";
import { loadProject } from "abstract-di/actions";
import { isOnline, isLoggedIn } from "abstract-di/selectors";
import { getThumbnailZoom, setThumbnailZoom } from "abstract-di/zoom";
import { trackEvent } from "core/actions/analytics";
import { loadCollection } from "core/actions/collections";
import { fetchComments } from "core/actions/comments";
import { setPaginationTotal } from "core/actions/paginationTotals";
import { withData } from "core/components/DataLoader";
import createConnector from "core/lib/createConnector";
import { replace, removeQuery } from "core/lib/location";
import { layerLocation, shareLinkPath } from "core/lib/routes";
import { displayName } from "core/models/branch";
import { DEFAULT_COLLECTION_VALUES } from "core/models/collection";
import * as Request from "core/models/request";
import { isPublic } from "core/models/shareLink";
import { BranchFetchRequest } from "core/requests/branches";
import {
  CollectionFetchRequest,
  CollectionUpdateRequest,
  CollectionDeleteRequest,
  CollectionLayerCreateMultipleRequest,
} from "core/requests/collections";
import { getBranch, getBranchHead } from "core/selectors/branches";
import {
  getCollection,
  getCollectionLayers,
  getCollectionLayer,
  getLayerForCollectionLayer,
  getCollectionItems,
} from "core/selectors/collections";
import { canUseNewDefaultBranchName } from "core/selectors/features";
import { getPaginationTotal } from "core/selectors/paginationTotals";
import { getCollectionPolicy } from "core/selectors/policies";
import { getProject } from "core/selectors/projects";
import { getUser } from "core/selectors/users";
import type { State, Dispatch } from "core/types";
import type {
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  OwnLocationProps,
} from "./";

function mapStateToProps(state: State, props: OwnLocationProps): StateProps {
  const { projectId, branchId, collectionId } = props.params;

  const branch = getBranch(state, { projectId, branchId });
  const head = getBranchHead(state, { projectId, branchId });
  const collection = getCollection(state, { projectId, collectionId });
  const collectionPolicy = getCollectionPolicy(state, { collectionId });
  const project = getProject(state, { projectId });
  const organizationId = project ? project.organizationId : "";
  const collectionItems = getCollectionItems(state, { collectionId });
  const branchDisplayName = displayName(branch, {
    masterToMain: canUseNewDefaultBranchName(state),
    titleCase: true,
  });

  const request = CollectionFetchRequest.getRequest(state, {
    projectId,
    collectionId,
  });

  const detailedCollectionLayerId = props.location.query.collectionLayerId;

  const hasBeenUpdated = Request.success(
    CollectionUpdateRequest.getRequest(state, {
      id: collectionId,
    })
  );

  const hasDefaultValues =
    collection == null
      ? false
      : collection.name === DEFAULT_COLLECTION_VALUES.name &&
        collection.description === DEFAULT_COLLECTION_VALUES.description;

  const shouldDeleteCollectionOnUnmount =
    hasDefaultValues && !hasBeenUpdated && collectionItems.length === 0;

  const detailedCollectionLayer = detailedCollectionLayerId
    ? getCollectionLayer(state, {
        collectionLayerId: detailedCollectionLayerId,
      })
    : null;

  const detailedLayer = detailedCollectionLayerId
    ? getLayerForCollectionLayer(state, {
        collectionLayerId: detailedCollectionLayerId,
      })
    : null;

  return {
    branch,
    branchDisplayName,
    head,
    organizationId,
    detailedLayer,
    shouldDeleteCollectionOnUnmount,
    detailedCollectionLayer,
    canEdit: collectionPolicy.update,
    isOnline: isOnline(state),
    isPublic: isPublic(props.shareLink),
    isAddingLayers: CollectionLayerCreateMultipleRequest.isLoadingStrict(
      state,
      {
        projectId,
        branchId,
        collectionId,
        allowDuplicates: true,
        layers: [],
        pages: [],
        files: [],
      }
    ),
    showMore: collectionPolicy.update || collectionPolicy.delete,
    error: Request.unexpectedError(request),
    notFound: Request.notFound(request) || Request.forbidden(request),
    loading: Request.isFirstLoadingStrict(request),
    user: getUser(state, { userId: collection ? collection.userId : "" }),
    collection,
    project,
    collectionId,
    collectionItems,
    initialThumbnailZoom:
      props.initialThumbnailZoom !== undefined
        ? props.initialThumbnailZoom
        : getThumbnailZoom(state, { subject: "collection" }),
    isLoggedIn: isLoggedIn(state),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnLocationProps
): DispatchProps {
  const { projectId, collectionId, branchId } = props.params;
  const { shareLink } = props;

  return {
    onZoomChange(zoom) {
      dispatch(setThumbnailZoom(zoom, "collection"));
    },
    updateCollection(attributes) {
      dispatch((dispatch, getState) => {
        const collection = getCollection(getState(), {
          projectId,
          collectionId,
        });

        /*
          ❗️This is a hacky, but pragmatic solution (under pressure of fixing in release-87)
          reference: https://github.com/goabstract/ui/pull/2208
          We need a better way of determining and caching the total number of collections.
          Please do not add on to this - it will need to be changed!
        */
        if (collection && !collection.publishedAt) {
          const currentPaginationTotals = getPaginationTotal(
            getState(),
            branchId
          );

          dispatch(
            setPaginationTotal(
              branchId,
              currentPaginationTotals ? currentPaginationTotals + 1 : 0
            )
          );
        }

        dispatch(
          CollectionUpdateRequest.perform({
            params: { id: collectionId, projectId, ...attributes },
          })
        );
      });
    },
    deleteCollection() {
      dispatch(
        CollectionDeleteRequest.perform({
          params: { projectId, id: collectionId, branchId },
        })
      );
    },
    onLoad() {
      dispatch(loadCollection(collectionId, projectId));
      dispatch(fetchComments({ projectId, branchId, collectionId }));
      dispatch(
        BranchFetchRequest.perform({
          params: { projectId, branchId },
        })
      );
      dispatch(loadProject(projectId));
      dispatch(trackEvent("Collection Viewed", props.params));
    },
    handleGoToCollectionLayerDetail: (collectionLayerId) => {
      dispatch(async (dispatch, getState) => {
        const state = getState();
        const collectionLayer = getCollectionLayer(state, {
          collectionLayerId,
        });

        invariant(
          collectionLayer,
          "Cannot go to collection layer, could not find collection layer"
        );
        const options = {
          collectionLayerId,
          collectionId,
          returnTo: removeQuery("collectionLayerId", "mode", "sha", "selected"),
        };
        const branch = getBranch(state, { projectId, branchId });
        invariant(branch, "Cannot go to collection layer, branch not loaded");

        const sha = collectionLayer.useLatestCommit
          ? "latest"
          : collectionLayer.sha;
        replace(
          shareLink
            ? shareLinkPath(shareLink.id, options)
            : layerLocation(
                collectionLayer.projectId,
                branch.id,
                sha,
                collectionLayer.fileId,
                collectionLayer.layerId,
                options
              )
        );
      });
    },
    handlePresentLayer: (collectionLayerId) => {
      dispatch(async (dispatch, getState) => {
        const state = getState();
        const collectionLayers = getCollectionLayers(state, {
          collectionId,
        });

        invariant(
          collectionLayers.length > 0,
          "Cannot present collection, no layers in collection"
        );

        const firstCollectionLayer = collectionLayers[0];
        const layerForFirstCollectionLayer =
          firstCollectionLayer &&
          getLayerForCollectionLayer(state, {
            collectionLayerId: firstCollectionLayer.id,
          });
        const firstLayerSha = layerForFirstCollectionLayer
          ? layerForFirstCollectionLayer.lastChangedAtSha
          : firstCollectionLayer.sha;

        const options = {
          collectionLayerId: firstCollectionLayer.id,
          collectionId: collectionId,
          returnTo: removeQuery("collectionLayerId", "mode", "sha", "selected"),
          present: "true",
          preview: "false",
          sha: firstLayerSha,
        };

        replace(
          shareLink
            ? shareLinkPath(shareLink.id, options)
            : layerLocation(
                firstCollectionLayer.projectId,
                branchId,
                firstLayerSha,
                firstCollectionLayer.fileId,
                firstCollectionLayer.layerId,
                options
              )
        );
      });
    },
  };
}

export default createConnector<Props, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withData((props: Props) => {
    return {
      ...props.params,
      branchHead: props.head,
    };
  })
);
