// @flow
import apiRequest from "abstract-di/api";
import { entitiesReceived } from "core/actions/entities";
import { normalizeQuickJumpResponse } from "core/schemas/quickJump";
import defineRequest from ".";

type QuickJumpFetchParams = {|
  search: string,
|};

export const QuickJumpFetchRequest = defineRequest<
  QuickJumpFetchParams,
  QuickJumpFetchParams,
>({
  id({ search }) {
    return `get:quick_jump?search=${search}`;
  },
  perform({ search }, dispatch, getState) {
    return apiRequest("get", "quick_jump", { search });
  },
  onSuccess(response, params, dispatch) {
    const { entities } = normalizeQuickJumpResponse(response);
    dispatch(entitiesReceived(entities));
  },
  force: false,
});
