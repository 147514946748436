// @flow
import { connect } from "react-redux";
import {
  getRepoIsUnsynced,
  getRepoIsCloning,
  getRepoIsSyncing,
  getRepoDownloadProgress,
} from "abstract-di/selectors";
import type {
  Props,
  OwnProps,
  StateProps,
} from "core/components/ProjectListItem";
import { canUsePartialSync } from "core/selectors/features";
import {
  getOrganizationPolicy,
  getProjectPolicy,
} from "core/selectors/policies";
import { getProject } from "core/selectors/projects";
import { getSection } from "core/selectors/sections";
import type { State, Dispatch } from "core/types";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { id: projectId, organizationId } = props.project;

  const project = getProject(state, { projectId });
  const sectionId = project ? project.sectionId || "" : "";
  const orgPolicy = getOrganizationPolicy(state, { organizationId });
  const policy = getProjectPolicy(state, { projectId });
  const canPartialSync = canUsePartialSync(state, { projectId });

  return {
    key: project ? project.id : projectId,
    project: project || props.project,
    section: sectionId ? getSection(state, { sectionId }) : null,
    isUnsynced: !!getRepoIsUnsynced(state, { projectId }) && !canPartialSync,
    isCloning: !!getRepoIsCloning(state, { projectId }),
    isSyncing: getRepoIsSyncing(state, { projectId }),
    percentDownloaded: getRepoDownloadProgress(state, { projectId }),
    canMoveProject:
      orgPolicy.showSections && (orgPolicy.manageSections || policy.update),
  };
}

export default connect<Props, OwnProps, StateProps, _, State, Dispatch>(
  mapStateToProps
);
