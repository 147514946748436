// @flow
import { connect } from "react-redux";
import { addTeamMembership } from "core/actions/teamMemberships";
import { OrganizationMembershipsRequest } from "core/requests/memberships";
import { getOrganization } from "core/selectors/organizations";
import { getOrganizationPolicy } from "core/selectors/policies";
import { getSubscription } from "core/selectors/subscriptions";
import { getTeamMembershipsForTeam } from "core/selectors/teamMemberships";
import { getUsersForOrganization } from "core/selectors/users";
import type { State, Dispatch } from "core/types";
import type { OwnProps, StateProps, DispatchProps, Props } from "./";

function mapStateToProps(state, props: OwnProps): StateProps {
  const { organizationId } = props;
  const teamId = props.team.id;
  const organizationPolicy = getOrganizationPolicy(state, { organizationId });

  return {
    canAddGuests: organizationPolicy.addGuest,
    organization: getOrganization(state, { organizationId }),
    subscription: getSubscription(state, organizationId),
    organizationMembershipsLoading: OrganizationMembershipsRequest.isLoading(
      state,
      { organizationId }
    ),
    users: getUsersForOrganization(state, { organizationId }),
    teamMemberships: getTeamMembershipsForTeam(state, {
      teamId,
    }),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    onAddMember: (teamId: string, userId: string) => {
      return dispatch(
        addTeamMembership({
          organizationId: props.organizationId,
          teamId,
          userId,
        })
      );
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
