// @flow
import * as React from "react";
import { connect } from "react-redux";
import history from "abstract-di/history";
import { pagePath as injectedPagePath } from "abstract-di/routes";
import CommitTitle from "core/components/CommitTitle";
import FileName from "core/components/FileName";
import SidebarSection from "core/components/SidebarSection";
import Time from "core/components/Time";
import useBranchDisplayName from "core/hooks/useBranchDisplayName";
import { Abstract } from "core/lib/abstract";
import { V3Link as Link } from "core/lib/router";
import * as routes from "core/lib/routes";
import * as Commit from "core/models/commit";
import { getCollection } from "core/selectors/collections";
import type {
  Branch,
  Commit as TCommit,
  File,
  Page,
  Layer as TLayer,
  Project,
  Collection,
  State,
} from "core/types";
import DetailLoading from "./DetailLoading";
import style from "./style.scss";

type OwnProps = {|
  params: Abstract.LayerVersionDescriptor,
  layerState: *,
  project: ?Project,
  branch: ?Branch,
  commit: ?TCommit,
  file: ?File,
  page: ?Page,
  layer: ?TLayer,
  collectionId?: string,
|};

type StateProps = {|
  collection: ?Collection,
|};

type Props = {
  ...OwnProps,
  ...StateProps,
};

function Detail(props: { label: string, children: React.Node }) {
  return (
    <div className={style.detail}>
      <div className={style.detailLabel}>{props.label}</div>
      <div className={style.detailValue}>{props.children}</div>
    </div>
  );
}

function LayerDetailSidebarSection(props: Props) {
  // Allow desktop to override pagePath to append pageId as query param
  const pagePath = injectedPagePath || routes.pagePath;

  // The branch property on this component is a maybe (?Branch) and so
  // we cannot rely on its existence to pass args to the useBranchDisplayName
  // hook. Construct args to pass to the hook based on what we do know,
  // before we call the hook
  const branchDisplayArgs = props.branch
    ? [props.branch.id, props.branch.name]
    : [];
  const { branchName } = useBranchDisplayName(...branchDisplayArgs);

  return (
    <SidebarSection id="layer-details" label="Details" collapsible>
      <div className={style.details}>
        {props.collectionId && (
          <React.Fragment>
            <Detail label="Collection">
              {props.collection ? (
                <Link
                  to={routes.collectionLocation(
                    props.params.projectId,
                    props.params.branchId,
                    props.collectionId,
                    {
                      returnTo: history.getCurrentLocation(),
                    }
                  )}
                >
                  {props.collection.name}
                </Link>
              ) : (
                <DetailLoading layerState={props.layerState} />
              )}
            </Detail>
            <div className={style.divider} />
          </React.Fragment>
        )}
        <Detail label="Page">
          {props.page ? (
            <Link
              to={pagePath(
                props.params.projectId,
                props.params.branchId,
                props.params.fileId,
                props.page.id
              )}
            >
              {props.page.name}
            </Link>
          ) : (
            <DetailLoading layerState={props.layerState} />
          )}
        </Detail>
        <Detail label="File">
          {props.file ? (
            <Link
              to={routes.filePath(
                props.params.projectId,
                props.params.branchId,
                props.params.fileId
              )}
            >
              <FileName
                name={props.file.name}
                type={props.file.type}
                isLibrary={props.file.isLibrary}
                fileClassName={style.fileName}
                icon={null}
                truncate={false}
              />
            </Link>
          ) : (
            <DetailLoading layerState={props.layerState} />
          )}
        </Detail>
        <Detail label="Commit">
          {props.commit ? (
            <React.Fragment>
              <Link
                to={routes.commitPath(
                  props.params.projectId,
                  props.params.branchId,
                  props.commit ? props.commit.sha : props.params.sha
                )}
              >
                <CommitTitle
                  title={props.commit.title}
                  type={Commit.type(props.commit, props.branch)}
                />
              </Link>
              <div className={style.commitMeta}>
                <span>{props.commit ? props.commit.userName : ""}</span>
                &nbsp;&middot;&nbsp;
                <Time date={props.commit ? props.commit.time : ""} />
              </div>
            </React.Fragment>
          ) : (
            <DetailLoading layerState={props.layerState} />
          )}
        </Detail>
        <Detail label="Branch">
          {props.branch ? (
            <Link
              to={routes.branchPath(
                props.params.projectId,
                props.params.branchId
              )}
            >
              {branchName}
            </Link>
          ) : (
            <DetailLoading layerState={props.layerState} />
          )}
        </Detail>
        <Detail label="Project">
          {props.project ? (
            <Link to={routes.projectPath(props.params.projectId)}>
              {props.project.name}
            </Link>
          ) : (
            <DetailLoading layerState={props.layerState} />
          )}
        </Detail>
      </div>
    </SidebarSection>
  );
}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  return {
    collection: getCollection(state, {
      collectionId: props.collectionId || "",
    }),
  };
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default connect(mapStateToProps)(LayerDetailSidebarSection);
