// @flow
import * as Abstract from "abstract-sdk";
import React, { Component } from "react";
import Button from "core/components/Button";
import ExternalLink from "core/components/ExternalLink";
import FormHeader from "core/components/FormHeader";
import FormSection from "core/components/FormSection";
import Input from "core/components/Input";
import InputImage from "core/components/InputImage";
import Media from "core/components/Media";
import Note from "core/components/Note";
import SettingsForm from "core/components/SettingsForm";
import SettingsItem from "core/components/SettingsItem";
import SettingsItemInputNote from "core/components/SettingsItemInputNote";
import {
  deleteOrganizationUrl,
  legalUrl,
  exportProjectUrl,
  helpUpdateBillingUrl,
} from "core/lib/urls";
import DangerButton from "web/components/DangerButton";
import OrganizationLogoPicker from "web/components/OrganizationLogoPicker";
import type { Organization, Form } from "web/types";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  params: Abstract.OrganizationDescriptor,
  form: Form,
|};

export type StateProps = {|
  organization: Organization,
  requestLoading: boolean,
  requestError: boolean,
  areCancelAndDeleteButtonsVisible: boolean,
|};

export type DispatchProps = {|
  onSubmit: (
    formId: string,
    organizationId: string,
    values: { name?: string, billingEmail?: string, logo: string }
  ) => void,
  onDeleteOrganization: (organizationId: string) => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

class Details extends Component<Props> {
  logo: OrganizationLogoPicker;

  handleChange = (event: SyntheticInputEvent<>) => {
    this.props.form.onChange(this.props.form.id, {
      [event.target.name]: event.target.value,
    });
  };

  handleLogoChange = (dataUrl: string) => {
    const logo = dataUrl || "";
    this.props.onSubmit(this.props.form.id, this.props.organization.id, {
      logo,
    });
  };

  handleSubmit = (event: SyntheticEvent<>) => {
    event.preventDefault();
    this.props.onSubmit(
      this.props.form.id,
      this.props.organization.id,
      this.props.form.values
    );
  };

  handleDelete = () => {
    this.props.onDeleteOrganization(this.props.organization.id);
  };

  componentDidMount() {
    // We call onChange with an empty object to clear the form on remount
    this.props.form.onChange(this.props.form.id, {});
  }

  render() {
    const { form, organization } = this.props;

    const header = (
      <Media desktop>
        {(desktop) => <FormHeader mobile={!desktop} icon="organization" />}
      </Media>
    );

    const deleteDialogMessage = (
      <div className={style.sectionText}>
        <p className={style.sectionLeadText}>
          Once you delete the <b>{organization.name}</b> Organization:
        </p>

        <ul className={style.sectionList}>
          <li>
            You will permanently lose access to all its projects and data.
          </li>
          <li>An active subscription will be cancelled immediately.</li>
        </ul>

        <div className={style.sectionText}>
          <p className={style.sectionLeadText}>Before you proceed:</p>

          <ul className={style.sectionList}>
            <li>
              <ExternalLink href={exportProjectUrl()}>
                Export your files.
              </ExternalLink>{" "}
            </li>
            <li>
              Read our{" "}
              <ExternalLink href={legalUrl()}>Terms of Service</ExternalLink>{" "}
              and{" "}
              <ExternalLink href={helpUpdateBillingUrl()}>
                Cancellation Policy
              </ExternalLink>
              .
            </li>
            <li>Review your contract, if you’re an Enterprise customer.</li>
          </ul>
        </div>

        <p>
          Alternatively, you may{" "}
          <ExternalLink href={helpUpdateBillingUrl()}>
            cancel your subscription
          </ExternalLink>{" "}
          without deleting the <b>{organization.name}</b> Organization.
        </p>
      </div>
    );

    return (
      <SettingsForm header={header} documentTitle="Details">
        <form onSubmit={this.handleSubmit}>
          <FormSection>
            <SettingsItem input>
              <Input
                requiredTag
                responsive
                error={form.errors.name}
                label="Name"
                name="name"
                onChange={this.handleChange}
                defaultValue={organization.name || ""}
              />
            </SettingsItem>
            <SettingsItem input>
              <Input
                responsive
                error={form.errors.billingEmail}
                label="Billing email"
                name="billingEmail"
                onChange={this.handleChange}
                placeholder="me@company.com"
                defaultValue={organization.billingEmail || ""}
              />
              <SettingsItemInputNote>
                Use a comma-separated list to add more than one billing email.
              </SettingsItemInputNote>
            </SettingsItem>
            <SettingsItem input>
              <InputImage
                responsive
                isRemovable={false}
                alt={organization.name}
                defaultValue={organization.logoUrl}
                error={form.errors.logo}
                label="Logo"
                name="logo"
                onChange={this.handleLogoChange}
                type="organizationLogo"
              />
            </SettingsItem>
            <SettingsItem className={style.submit}>
              <Button
                disabled={
                  !this.props.form.isDirty || this.props.form.isSubmitting
                }
                primary
                type="submit"
              >
                {this.props.form.isSubmitting ? "Saving…" : "Save changes"}
              </Button>
            </SettingsItem>
          </FormSection>
        </form>

        {this.props.areCancelAndDeleteButtonsVisible && (
          <FormSection>
            <SettingsItem label="Delete this organization">
              <Note>
                Once you delete this Organization, you will permanently lose
                access to all its projects and data.{" "}
                <ExternalLink href={deleteOrganizationUrl()}>
                  Learn more…
                </ExternalLink>
              </Note>
            </SettingsItem>
            <SettingsItem className={style.submit}>
              <DangerButton
                title="Delete this Organization"
                onSubmit={this.handleDelete}
                loading={this.props.requestLoading}
                error={this.props.requestError}
                dialogMessage={deleteDialogMessage}
                dialogButtonLabel="Delete this Organization"
              >
                Delete Organization
              </DangerButton>
            </SettingsItem>
          </FormSection>
        )}
      </SettingsForm>
    );
  }
}

export default connector(Details);
