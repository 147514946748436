// @flow
import * as React from "react";
import DialogForm from "core/components/DialogForm";
import HorizontalSeparator from "core/components/HorizontalSeparator";
import Note from "core/components/Note";
import SeatManager from "core/components/SeatManager";
import { OverMaxSeatsError } from "core/components/SeatManager/errors";
import { V3Link as Link } from "core/lib/router";
import { helpRolesUrl } from "core/lib/urls";
import type { Organization, Subscription } from "core/types";
import SeatsAvailableLine from "../SeatsAvailableLine";
import style from "../style.scss";

type Props = {
  userName: string,
  organization: Organization,
  isUsernameOrganization?: boolean,
  isSubmitting: boolean,
  isOpen: boolean,
  onSubmit: (SyntheticEvent<>) => *,
  subscription: ?Subscription,
  subscriptionRole: string,
  canManageUsers: boolean,
  seatCapEnforced: ?boolean,
  onClose: () => void,
};

export default function ContributorConfirmationDialog(props: Props) {
  const { subscription, seatCapEnforced, canManageUsers } = props;

  const isTrial = subscription ? subscription.inTrial : false;

  const showSeatManagement = canManageUsers && !isTrial;

  return (
    <SeatManager
      action="add"
      controlSeats={showSeatManagement}
      organizationId={props.organization.id}
      subscription={subscription}
      onSubmit={props.onSubmit}
    >
      {({
        onSubmit,
        isLoading,
        hasError,
        toggleOn,
        renderAvailableSeats,
        renderSummary,
        renderInput,
        renderToggle,
        availableSeats,
      }) => (
        <DialogForm
          isOpen={props.isOpen}
          disabled={
            isLoading ||
            hasError ||
            props.isSubmitting ||
            (!toggleOn && availableSeats === 0)
          }
          onClose={props.onClose}
          onSubmit={onSubmit}
          submitText={
            props.isUsernameOrganization
              ? "Confirm change"
              : `Upgrade User ${toggleOn ? "& add seats" : ""}`
          }
          title={
            props.isUsernameOrganization
              ? "Change to Contributor"
              : "Upgrade to Contributor"
          }
          contentClassName={style.confirmationDialog}
        >
          {() => (
            <React.Fragment>
              <p>
                Do you want to upgrade the{" "}
                <span className={style.capitalize}>
                  {props.subscriptionRole}
                </span>
                <strong> {props.userName}</strong> to be a Contributor?
              </p>
              {seatCapEnforced && (
                <SeatsAvailableLine
                  availableSeats={availableSeats}
                  isTrial={isTrial}
                />
              )}
              <Note>
                {!seatCapEnforced && (
                  <span>
                    A Contributor can see, comment on, and do work on the
                    projects they have acccess to.{" "}
                    <Link to={helpRolesUrl()} className={style.textLink}>
                      Learn more…
                    </Link>
                  </span>
                )}
                {seatCapEnforced && (
                  <span>
                    Contributors can work on projects, create Branches, edit
                    files, commit, and merge. These are paid seats and limited
                    by plan.{" "}
                    <Link to={helpRolesUrl()} className={style.textLink}>
                      Learn more…
                    </Link>
                  </span>
                )}
              </Note>
              {showSeatManagement && (
                <React.Fragment>
                  <HorizontalSeparator />
                  {renderToggle()}
                  {renderAvailableSeats()}
                  {renderInput()}
                  {renderSummary()}
                </React.Fragment>
              )}
              {isTrial &&
                canManageUsers &&
                availableSeats === 0 &&
                subscription &&
                seatCapEnforced && (
                  <OverMaxSeatsError
                    seatCapEnforced={true}
                    subscriptionTitle={subscription.type}
                  />
                )}
            </React.Fragment>
          )}
        </DialogForm>
      )}
    </SeatManager>
  );
}
