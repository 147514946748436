// @flow
import pluralize from "pluralize";
import * as React from "react";

type Props = {
  availableSeats: number,
  isTrial: boolean,
};

export default function SeatsAvailableLine({ availableSeats, isTrial }: Props) {
  return (
    <p>
      There {pluralize("are", availableSeats)}
      <strong> {availableSeats || "no"}</strong> Contributor{" "}
      {pluralize("seats", availableSeats)} available to use
      {isTrial ? " " : " before more seats need to be purchased "}
      for your Organization.
    </p>
  );
}
