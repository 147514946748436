// @flow
import * as React from "react";
import Loaded from "core/components/Loaded";
import Media from "core/components/Media";
import OrganizationTeamHeader from "core/components/OrganizationTeam/OrganizationTeamHeader";
import { createPaginationDataLoaderComponent } from "core/components/PaginationDataLoader";
import { PaginatedTeamProjectMembershipRequest } from "core/requests/teamProjectMemberships";
import { getProjectsForTeam } from "core/selectors/projects";
import type {
  OrganizationTeamDescriptor,
  Team,
  Project,
  Policy,
  ReactRouterLocation,
} from "core/types";
import NoProjects from "./NoProjects";
import VirtualizedTeamProjectList from "./VirtualizedTeamProjectList";
import connector from "./connector";

export type OwnProps = {|
  params: OrganizationTeamDescriptor,
  location: ReactRouterLocation,
|};

export type StateProps = {|
  team: ?Team,
  teamPolicy: Policy,
  teamProjects: Project[],
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
};
type PaginationDataLoaderParams = {
  ...OrganizationTeamDescriptor,
  query?: string,
};

const OrganizationTeamProjectsDataLoader = createPaginationDataLoaderComponent<
  PaginationDataLoaderParams,
  Project[],
>(
  "OrganizationTeamProjects",
  PaginatedTeamProjectMembershipRequest,
  (response) => response.data.projects.length,
  getProjectsForTeam,
  30
);

function OrganizationTeamProjects(props: Props) {
  const { params, location } = props;
  const [isGrid, setIsGrid] = React.useState(true);
  const { s: searchTerm } = location.query;
  const projectPaginatedParams = React.useMemo(() => {
    return {
      key: "OrganizationTeamProjects",
      organizationId: params.organizationId,
      teamId: params.teamId,
      query: searchTerm || undefined,
    };
  }, [params.organizationId, params.teamId, searchTerm]);

  return (
    <Media desktop>
      {(desktop) =>
        props.team ? (
          <React.Fragment>
            <OrganizationTeamHeader
              id="OrganizationTeamProjects"
              params={props.params}
              onChangeViewType={(viewType) => setIsGrid(viewType === "grid")}
              title={props.team.name}
              desktop={desktop}
              policy={props.teamPolicy}
              pathname={props.location.pathname}
            />

            <OrganizationTeamProjectsDataLoader params={projectPaginatedParams}>
              {({
                items,
                hasNextPage,
                isLoadingNextPage,
                onLoadNextPage,
              }: {
                items: Project[],
                hasNextPage: boolean,
                isLoadingNextPage: boolean,
                onLoadNextPage?: () => void,
              }) => (
                <Loaded
                  title="Loading Team Projects"
                  flex
                  loading={isLoadingNextPage && items.length === 0}
                >
                  {() => {
                    return items.length > 0 ? (
                      <VirtualizedTeamProjectList
                        params={params}
                        isGrid={isGrid}
                        projects={items}
                        hasNextPage={hasNextPage}
                        isLoadingNextPage={isLoadingNextPage}
                        onLoadNextPage={onLoadNextPage}
                        isMobile={!desktop}
                        searchTerm={searchTerm || undefined}
                      />
                    ) : (
                      <NoProjects
                        params={props.params}
                        teamPolicy={props.teamPolicy}
                        searchTerm={searchTerm}
                      />
                    );
                  }}
                </Loaded>
              )}
            </OrganizationTeamProjectsDataLoader>
          </React.Fragment>
        ) : null
      }
    </Media>
  );
}

export default connector(OrganizationTeamProjects);
