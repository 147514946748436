// @flow
import * as React from "react";
import ButtonLink from "core/components/ButtonLink";
import InvitationPanel from "core/components/InvitationPanel";
import { push } from "core/lib/location";
import type { ReactRouterLocation } from "core/types";
import OnboardingForm from "web/components/OnboardingForm";
import OnboardingPage from "web/components/OnboardingPage";
import withForm from "web/containers/withForm";
import { invitationPath } from "web/routeHelpers";
import type { User, FormProps, Invitation as TInvitation } from "web/types";
import style from "./style.scss";

export type OwnProps = {|
  location: ReactRouterLocation,
|};

export type StateProps = {|
  currentUser: ?User,
  isOnboarding: boolean,
  alreadyAccepted: boolean,
  invitation: TInvitation,
|};

export type DispatchProps = {|
  acceptInvitation: (formId: string, token: string) => void,
  resetInvitation: () => void,
|};

export type ConnectedProps = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

export type Props = {
  ...ConnectedProps,
  ...FormProps,
};

class Invitation extends React.Component<Props> {
  handleAccept = (event: SyntheticEvent<>) => {
    event.preventDefault();
    if (this.props.invitation.satisfiedDomainRestriction) {
      this.props.acceptInvitation(
        this.props.form.id,
        this.props.invitation.token
      );
    } else {
      push(invitationPath(this.props.invitation.token, "add-email"));
    }
  };

  handleIgnore = (event: SyntheticEvent<>) => {
    event.preventDefault();

    this.props.resetInvitation();
    push("/");
  };

  handleRedirect = () => {
    if (this.props.form.submitSucceeded || this.props.alreadyAccepted) {
      if (this.props.location.state && this.props.location.state.returnTo) {
        push(this.props.location.state.returnTo);
      } else {
        push({
          pathname: invitationPath(this.props.invitation.token, "confirmation"),
          state: { onboarding: this.props.isOnboarding },
        });
      }
    }
  };

  componentDidMount() {
    this.handleRedirect();
  }

  componentDidUpdate() {
    this.handleRedirect();
  }

  render() {
    const { form, invitation, currentUser } = this.props;
    const errors =
      form.errors["organization"] || form.errors["email"] || invitation.errors;

    if (!invitation.id) {
      // FIXME: Figure out why this is necessary
      return null;
    }

    return (
      <OnboardingPage heading="You’ve been invited">
        <OnboardingForm
          submitText={
            invitation.satisfiedDomainRestriction
              ? form.isSubmitting
                ? "Accepting Invitation…"
                : "Accept Invitation"
              : "Confirm Email Address"
          }
          onSubmit={this.handleAccept}
          disabled={form.isSubmitting || !!invitation.errors}
          error={errors}
          fullWidthSubmit
          showSubmitGraphic
          secondaryButton={
            <ButtonLink
              onClick={this.handleIgnore}
              className={style.ignoreButton}
            >
              Ignore this.
            </ButtonLink>
          }
        >
          <InvitationPanel
            invitation={this.props.invitation}
            currentUser={currentUser}
          />
        </OnboardingForm>
      </OnboardingPage>
    );
  }
}

export default withForm<ConnectedProps>(Invitation, "accept-invitation-form");
