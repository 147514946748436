// @flow
import { trackEvent } from "core/actions/analytics";
import { request } from "core/actions/requests";
import {
  acceptInvitation as apiAcceptInvitation,
  createEmailForInvitation,
  fetchInvitation,
} from "../api";
import type { Invitation } from "../types";
import type { Action, ThunkAction } from ".";

export function resetInvitation(): Action {
  return { type: "INVITATION_RESET" };
}

function invitationLoaded(invitation: Invitation): Action {
  return { type: "INVITATION_LOADED", payload: { invitation } };
}

function invitationLoading(id): Action {
  return { type: "INVITATION_LOADING", meta: { id } };
}

function invitationNotFound(id): Action {
  return { type: "INVITATION_NOT_FOUND", meta: { id } };
}

export function loadInvitation(id: string): ThunkAction {
  return async (dispatch) => {
    dispatch(invitationLoading(id));
    let invitation;
    try {
      invitation = await fetchInvitation(id);
    } catch (err) {
      return dispatch(invitationNotFound(id));
    }

    return dispatch(invitationLoaded(invitation));
  };
}

export function acceptInvitation(
  formId: string,
  invitationToken: string
): ThunkAction {
  return async function (dispatch, getState) {
    const { id: invitationId } = getState().invitation.entity;

    return dispatch(
      request(formId, () => apiAcceptInvitation(invitationToken), {
        onSuccess: () => {
          dispatch(trackEvent("INVITE_ACCEPTED", { invitationId }));
        },
      })
    );
  };
}

export function sendEmailForInvitation(
  formId: string,
  invitationId: string,
  email: string
): ThunkAction {
  return request(formId, () => createEmailForInvitation(invitationId, email));
}
