// @flow
import { static as Immutable } from "seamless-immutable";
import type { Action } from "core/types";

type State = {
  [id: string]: {
    itemsLoaded: number,
    hasMore: boolean,
  },
};

const DEFAULT_STATE = Immutable.from({
  hasMore: false,
  itemsLoaded: 0,
});

export default function (
  state: State = Immutable.from({}),
  action: Action
): State {
  switch (action.type) {
    case "core/PAGINATION_ITEMS_LOADED": {
      const existing = state[action.meta.id] || DEFAULT_STATE;
      const hasMore = action.payload.itemsLoaded === action.payload.pageSize;
      const itemsLoaded = existing.itemsLoaded + action.payload.itemsLoaded;
      return Immutable.set(state, action.meta.id, { hasMore, itemsLoaded });
    }
    case "core/PAGINATION_ITEMS_RESET": {
      const existingKeys = [];
      Object.keys(state).forEach((key) => {
        if (key.includes(action.meta.id)) {
          existingKeys.push(key);
        }
      });
      return Immutable.without(state, existingKeys);
    }
    case "core/INVALIDATE_REQUESTS":
    case "SIGNED_OUT": {
      return Immutable.from({});
    }
    default: {
      return state;
    }
  }
}
