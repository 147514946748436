// @flow
import invariant from "invariant";
import get from "lodash/get";
import { connect } from "react-redux";
import { discardChanges } from "abstract-di/actions";
import { loadWorkingTreeChangeset } from "core/actions/changesets";
import { dismissDialog } from "core/actions/dialogs";
import { WorkingTreeSHA } from "core/gitConstants";
import { getBranch } from "core/selectors/branches";
import {
  getChangesetChangeCounts,
  getChangesetFiles,
} from "core/selectors/changesets";
import { getProject } from "core/selectors/projects";
import type { State, Dispatch } from "core/types";
import type { Props, OwnProps, StateProps, DispatchProps } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const sha = WorkingTreeSHA;
  const projectId = props.projectId;
  const branchId = props.branchId;
  const params = { projectId, sha, branchId };
  const isLoading = get(state, ["files", projectId, sha, "loading"]);

  return {
    project: getProject(state, params),
    branch: getBranch(state, params),
    files: getChangesetFiles(state, { type: "working-tree", projectId }),
    changeCounts: getChangesetChangeCounts(state, {
      type: "working-tree",
      projectId,
    }),
    isLoading,
  };
}

function mapDispatchToProps(dispatch: *, props: OwnProps): DispatchProps {
  const { projectId } = props;

  return {
    onMount: () => {
      dispatch(loadWorkingTreeChangeset({ projectId }));
    },
    onSubmit: (fileIds) => {
      invariant(discardChanges, "discardChanges required for dialog action");
      dispatch(discardChanges(props.projectId, props.branchId, fileIds));
    },
    onClose: () => {
      dispatch(dismissDialog());
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
