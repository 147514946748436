// @flow
import { entitiesReceived } from "core/actions/entities";
import { request } from "core/actions/requests";
import type { OrganizationSettings } from "core/types";
import { updateIntegration } from "../api";
import type { FormValues } from "../models/form";
import type { ThunkAction } from ".";

export function slackChannelFormSubmitted(
  id: string,
  integrationId: string,
  values: FormValues,
  organizationSettings: OrganizationSettings
): ThunkAction {
  return async (dispatch) => {
    dispatch(
      request(id, () => updateIntegration(integrationId, values), {
        onSuccess: (slackIntegration) => {
          const updatedSettings = {
            [organizationSettings.organizationId]: {
              ...organizationSettings,
              slackIntegration,
            },
          };
          dispatch(entitiesReceived({ organizationSettings: updatedSettings }));
        },
      })
    );
  };
}
