// @flow

let shareTokenHeader = undefined;
let shareToken = undefined;

export function setShareAuthorization(token: ?string) {
  if (!token) {
    return;
  }
  shareToken = token;
  shareTokenHeader = { "Abstract-Share-Id": token };
}

export function getShareToken() {
  return shareToken;
}

export function getShareAuthorizationHeader() {
  return shareTokenHeader;
}

export function clearShareAuthorization() {
  shareToken = undefined;
  shareTokenHeader = undefined;
}
