// @flow
import classnames from "classnames";
import * as React from "react";
import { useSelector } from "react-redux";
import { organizationPath } from "abstract-di/routes";
import ExternalLink from "core/components/ExternalLink";
import Flex from "core/components/Flex";
import Icon from "core/components/Icon";
import OrganizationLogo from "core/components/OrganizationLogo";
import { isDesktop } from "core/lib/platform";
import { V3Link as Link } from "core/lib/router";
import * as Routes from "core/lib/routes";
import * as URLs from "core/lib/urls";
import { enterpriseTeamsEnabled } from "core/selectors/features";
import { getOrganizationPolicy } from "core/selectors/policies";
import type { Organization } from "core/types";
import style from "./style.scss";

type Props = {|
  index: number,
  isOwnUsernameOrganization?: boolean,
  organization: Organization,
  selected?: boolean,
  onClick?: (event: SyntheticEvent<>) => void,
  qaSelector?: string,
|};

export default function OrganizationListItem(props: Props) {
  const {
    index,
    isOwnUsernameOrganization,
    selected,
    organization,
    onClick,
    qaSelector,
  } = props;
  const organizationId = organization.id;

  const organizationPolicy = useSelector((state) =>
    getOrganizationPolicy(state, { organizationId })
  );
  const canUseTeams = useSelector((state) =>
    enterpriseTeamsEnabled(state, { organizationId })
  );

  const hasShortcut = !!index && index <= 9;

  const showSettings =
    organizationPolicy.manageSettings && !isOwnUsernameOrganization;
  const showPeople = organizationPolicy.listMembers;
  const showCurrentOrganizationOptions = showSettings || showPeople;

  return (
    <li role="presentation">
      <Link
        role="menuitem"
        className={classnames(style.organization, {
          [style.selected]: selected,
          [style.isOwnUsernameOrganization]: isOwnUsernameOrganization,
        })}
        to={organizationPath(organization.id)}
        onClick={onClick}
        data-qa={qaSelector}
      >
        <OrganizationLogo
          size={32}
          className={style.logo}
          src={organization.logoUrl}
          alt={organization.name}
        />
        <span className={style.name}>{organization.name}</span>
        {hasShortcut && <span className={style.shortcut}>⌘{index}</span>}
      </Link>
      {selected && showCurrentOrganizationOptions ? (
        <Flex align="flex-start" column>
          {showSettings ? (
            <div
              className={classnames(style.linkContainer, {
                [style.lastLink]: !showPeople,
              })}
            >
              {isDesktop ? (
                <ExternalLink
                  className={style.optionLink}
                  href={URLs.organizationSettingsUrl(organization.id)}
                  onClick={onClick}
                  role="menuitem"
                />
              ) : (
                <Link
                  className={style.optionLink}
                  role="menuitem"
                  to={Routes.organizationSettingsPath(organization.id)}
                  onClick={onClick}
                  data-qa="organization-settings-button"
                />
              )}
              <div className={style.linkContent}>
                <Icon className={style.icon} fill="currentColor" type="cog" />
                <span className={style.linkText}>Organization Settings</span>
              </div>
            </div>
          ) : null}
          {showPeople ? (
            <div
              className={classnames(style.linkContainer, style.lastLink, {
                [style.secondLink]: showSettings,
              })}
            >
              <Link
                className={style.optionLink}
                onClick={onClick}
                role="menuitem"
                to={Routes.organizationPeoplePath(organization.id)}
                data-qa="organization-people-button"
              />
              <div className={style.linkContent}>
                <Icon
                  className={style.icon}
                  fill="currentColor"
                  type={canUseTeams ? "team" : "user"}
                />
                <span className={style.linkText}>
                  {canUseTeams ? "People & Teams" : "People"}
                </span>
              </div>
            </div>
          ) : null}
        </Flex>
      ) : null}
    </li>
  );
}
