// @flow
import keyBy from "lodash/keyBy";
import map from "lodash/map";
import { entitiesReceived, entitiesDeleted } from "core/actions/entities";
import { uniqueId } from "core/models/branch";
import { type ReviewStatus } from "core/models/review";
import {
  BranchReviewCollectionUpdateRequest,
  ProjectReviewsFetchRequest,
  BranchReviewsFetchRequest,
  ReviewCreateRequest,
  ReviewUpdateRequest,
  ReviewDeleteRequest,
  BranchReviewCreateRequest,
} from "core/requests/reviews";
import type {
  ReviewRequest,
  BranchReview,
  Branch,
  ThunkAction,
  Comment,
  CommentForm,
} from "core/types";

export function normalizeReviewResponse(responseData: {
  reviewRequest: ReviewRequest,
  branchReviews: BranchReview[],
  branches: Branch[],
  comments: Comment[],
}) {
  const { reviewRequest, branchReviews, branches, comments } = responseData;
  return {
    reviewRequests: { [reviewRequest.id]: reviewRequest },
    /* $FlowFixMeNowPlease This comment suppresses an error found when
     * upgrading flow-bin@0.85.0. To view the error, delete this comment and
     * run Flow. */
    branchReviews: keyBy(branchReviews, (br) => uniqueId(br)),
    /* $FlowFixMeNowPlease This comment suppresses an error found when
     * upgrading flow-bin@0.85.0. To view the error, delete this comment and
     * run Flow. */
    branches: keyBy(branches, (b) =>
      uniqueId({ branchId: b.id, projectId: b.projectId })
    ),
    /* $FlowFixMeNowPlease This comment suppresses an error found when
     * upgrading flow-bin@0.85.0. To view the error, delete this comment and
     * run Flow. */
    comments: keyBy(comments, "id"),
  };
}

export function normalizeReviewCollection(responseData: {
  reviewRequests: ReviewRequest[],
  branchReviews: BranchReview[],
  branches: Branch[],
}) {
  const { reviewRequests, branchReviews, branches } = responseData;
  return {
    /* $FlowFixMeNowPlease This comment suppresses an error found when
     * upgrading flow-bin@0.85.0. To view the error, delete this comment and
     * run Flow. */
    reviewRequests: keyBy(reviewRequests, "id"),
    /* $FlowFixMeNowPlease This comment suppresses an error found when
     * upgrading flow-bin@0.85.0. To view the error, delete this comment and
     * run Flow. */
    branchReviews: keyBy(branchReviews, (br) => uniqueId(br)),
    /* $FlowFixMeNowPlease This comment suppresses an error found when
     * upgrading flow-bin@0.85.0. To view the error, delete this comment and
     * run Flow. */
    branches: keyBy(branches, (b) =>
      uniqueId({ branchId: b.id, projectId: b.projectId })
    ),
  };
}

export function fetchProjectReviews(projectId: string): ThunkAction {
  return async function (dispatch) {
    return dispatch(
      ProjectReviewsFetchRequest.perform({
        params: { projectId },
      })
    );
  };
}

export function fetchBranchReviews(params: {
  projectId: string,
  branchId: string,
}): ThunkAction {
  return async function (dispatch) {
    const { projectId, branchId } = params;
    return dispatch(
      BranchReviewsFetchRequest.perform({
        params: { projectId, branchId },
        onSuccess: (response) => {
          const normalizedData = normalizeReviewCollection(response.data);
          dispatch(entitiesReceived(normalizedData));
        },
      })
    );
  };
}

export function updateReview(params: {
  id: string,
  status: ReviewStatus,
  commentParams?: CommentForm,
}): ThunkAction {
  return async function (dispatch) {
    return dispatch(
      ReviewUpdateRequest.perform({
        params,
        onSuccess: (response) => {
          const normalizedData = normalizeReviewResponse(response.data);
          dispatch(entitiesReceived(normalizedData));
          // TODO - we're in the process of moving comments into entities
          //        until that's done, the comment will come into desktop
          //        via push, which is a bit slow
        },
      })
    );
  };
}

export function deleteReview(params: { id: string }): ThunkAction {
  const { id } = params;
  return async function (dispatch) {
    return dispatch(
      ReviewDeleteRequest.perform({
        params: { id },
        onSuccess: (response) => {
          const normalizedData = normalizeReviewResponse(response.data);
          const { reviewRequests, branchReviews, branches } = normalizedData;
          dispatch(
            entitiesDeleted({ reviewRequests: map(reviewRequests, "id") })
          );
          dispatch(entitiesReceived({ branchReviews, branches }));
        },
      })
    );
  };
}

export function createReview(params: {
  projectId: string,
  branchId: string,
  reviewerId: string,
  status?: ReviewStatus,
  commentParams?: CommentForm,
  sha: string,
}): ThunkAction {
  return async function (dispatch) {
    return dispatch(
      ReviewCreateRequest.perform({
        params,
        onSuccess: (response) => {
          const normalizedData = normalizeReviewResponse(response.data);
          dispatch(entitiesReceived(normalizedData));
        },
      })
    );
  };
}

export function createBranchReview(params: {
  projectId: string,
  branchId: string,
  reviewerIds: string[],
  collectionIds: string[],
  createBaseCollection: boolean,
  commentParams?: CommentForm,
  sha: string,
  onSuccess?: () => *,
}): ThunkAction {
  return async function (dispatch) {
    return dispatch(
      BranchReviewCreateRequest.perform({
        params,
        onSuccess: (response) => {
          const normalizedData = normalizeReviewCollection(response.data);
          dispatch(entitiesReceived(normalizedData));

          // XXX - this is a little ugly, should go away once we are using
          //       request-perform directly rather than these intermediate actions
          if (params.onSuccess) {
            params.onSuccess();
          }
        },
      })
    );
  };
}

export function updateBranchReviewCollections(params: {
  projectId: string,
  branchId: string,
  collectionIds: string[],
  createBaseCollection: boolean,
}): ThunkAction {
  return async function (dispatch) {
    return dispatch(
      BranchReviewCollectionUpdateRequest.perform({
        params,
        onSuccess: (response) => {
          const normalizedData = normalizeReviewCollection(response.data);
          dispatch(entitiesReceived(normalizedData));
        },
      })
    );
  };
}
