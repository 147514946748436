// @flow
import { schema, normalize } from "normalizr";
import { teamProjectMembership, team, project } from "core/schema";
import { envelopeSchema } from "core/schemas/envelope";

const teamProjectMembershipsResponseSchema = envelopeSchema(
  new schema.Object({
    teamProjectMemberships: [teamProjectMembership],
    teams: [team],
  })
);

const teamProjectMembershipResponseSchema = envelopeSchema(
  new schema.Object({
    teamProjectMembership: teamProjectMembership,
    team: team,
  })
);

const teamProjectMemberhipsForTeamResponseSchema = envelopeSchema(
  new schema.Object({
    teamProjectMemberships: [teamProjectMembership],
    projects: [project],
  })
);

export function normalizeTeamProjectMembershipsResponse(response: *) {
  return normalize(response, teamProjectMembershipsResponseSchema);
}

export function normalizeTeamProjectMembershipResponse(response: *) {
  return normalize(response, teamProjectMembershipResponseSchema);
}

export function normalizeTeamProjectMembershipsForTeamResponse(response: *) {
  return normalize(response, teamProjectMemberhipsForTeamResponseSchema);
}

export function normalizeTeamProjectMembershipSocketResponse(response: *) {
  return normalize(
    response,
    new schema.Object({ teamProjectMembership: teamProjectMembership })
  );
}
