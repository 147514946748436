// @flow
import { connect } from "react-redux";
import { loadTeamMembershipsPreview } from "core/actions/teamMemberships";
import { withData } from "core/components/DataLoader";
import createConnector from "core/lib/createConnector";
import { PaginatedTeamMembershipsRequest } from "core/requests/teamMemberships";
import { AddTeamProjectMembershipRequest } from "core/requests/teamProjectMemberships";
import { getTeamProjectMembership } from "core/selectors/teamProjectMemberships";
import { getUsersForTeam } from "core/selectors/users";
import type { State, Dispatch } from "core/types";
import type { OwnProps, StateProps, DispatchProps, Props } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const teamProjectMembership = getTeamProjectMembership(state, {
    projectId: props.project.id,
    teamId: props.team.id,
  });

  const teamMembers = getUsersForTeam(state, {
    teamId: props.team.id,
  });

  return {
    isLoadingMembers: PaginatedTeamMembershipsRequest.isFirstLoading(state, {
      organizationId: props.organizationId,
      teamId: props.team.id,
      offset: 0,
      limit: 6,
    }),
    isLoadingAddTeamToProject: AddTeamProjectMembershipRequest.isLoadingStrict(
      state,
      {
        projectId: props.project.id,
        teamId: props.team.id,
      }
    ),
    inProject: !!teamProjectMembership,
    teamMembers,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    onLoad: () =>
      dispatch((dispatch, getState) => {
        const teamFetchSuccess = PaginatedTeamMembershipsRequest.success(
          getState(),
          {
            organizationId: props.organizationId,
            teamId: props.team.id,
            offset: 0,
            limit: 6,
          }
        );
        if (!teamFetchSuccess) {
          dispatch(
            loadTeamMembershipsPreview({
              organizationId: props.organizationId,
              teamId: props.team.id,
            })
          );
        }
      }),
  };
}

export default createConnector<Props, OwnProps>(
  connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch>(
    mapStateToProps,
    mapDispatchToProps
  ),
  withData((props: Props) => {
    return {
      organizationId: props.organizationId,
    };
  })
);
