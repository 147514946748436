// @flow
import { connect } from "react-redux";
import type { Dispatch } from "core/types";
import { AccountUpdateRequest } from "../../requests/account";
import MarketingConsentDialog from "./component";

function mapStateToProps(state, props) {
  return {
    isSubmitting: AccountUpdateRequest.isLoadingStrict(state),
  };
}
function mapDispatchToProps(dispatch: Dispatch, props: *) {
  return {
    onUpdateAccount: (params) => {
      dispatch(AccountUpdateRequest.perform({ params }));
    },
  };
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MarketingConsentDialog);
