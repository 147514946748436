// @flow
import classnames from "classnames";
import { capitalize } from "lodash";
import * as React from "react";
import FileName from "core/components/FileName";
import Icon from "core/components/Icon";
import Popover from "core/components/Popover";
import { type Row } from "core/components/VirtualizedList";
import * as Change from "core/models/change";
import type {
  File,
  ChangesetChange,
  NonVisualChange,
  NonVisualChangeObject,
} from "core/types";
import NonVisualListItem from "./NonVisualListItem";
import style from "./style.scss";

type Props = {
  changes: NonVisualChange[],
  file: File,
  type: string,
};

export const META_DATA_IDS = ["file-data", "document-data"];
export const FILE_TYPES = ["sketch", "xd", "illustrator"];

function Emphasis({ children }: { children: React.Node }) {
  return <span className={style.emphasis}>{children}</span>;
}

export function getNonVisualRows({ changes, file, type }: Props): Row[] {
  return changes.map((change, index) => {
    return {
      data: { file, type },
      className: classnames(style.dependencyRow, style.hoverable, {
        [style.firstSectionRow]: index === 0,
        [style.lastSectionRow]: index === changes.length - 1,
      }),
      scrollId: `${file.id}-${type}`,
      groupId: `${file.id}-${type}`,
      defaultHeight: 48,
      children: (
        <NonVisualListItem
          file={file}
          change={change.change}
          object={change.object}
          fromLibrary={change.fromLibrary}
          status={Change.status(change.change)}
          className={style.item}
          {...getChangeRow(change)}
        />
      ),
    };
  });
}

function getChangeRow({ change, object, meta }: NonVisualChange): {
  label: React.Node,
  secondaryLabel?: React.Node,
  status?: string,
  fromLibrary?: boolean,
} {
  if (Change.isAdded(change)) {
    return {
      label: renderObjectLabel(object, "added"),
    };
  }

  if (Change.isRemoved(change)) {
    return {
      label: renderObjectLabel(object, "deleted"),
    };
  }

  if (meta) {
    if (meta.name) {
      return {
        label: renderObjectRenamed(object, meta.name[0], meta.name[1]),
      };
    }

    if (meta.x || meta.y) {
      return {
        label: renderObjectLabel(object, "moved"),
        secondaryLabel: renderObjectMoved(meta, object),
        fromLibrary: false,
      };
    }

    if (meta.height || meta.width) {
      return {
        label: renderObjectLabel(object, "changed size"),
        secondaryLabel: renderObjectChangedSize(meta, object),
      };
    }

    if (meta.applicationVersion) {
      return {
        label: renderPropertyChanged(
          change,
          object,
          "version",
          meta.applicationVersion[0],
          meta.applicationVersion[1]
        ),
      };
    }

    if (meta.colorSpace) {
      return {
        label: renderPropertyChanged(
          change,
          object,
          "color profile",
          meta.colorSpace[0],
          meta.colorSpace[1]
        ),
      };
    }
  }

  return {
    status: "updated",
    label: renderObjectLabel(object, "updated"),
  };
}

function renderObjectLabel(
  object: NonVisualChangeObject,
  type: "added" | "deleted" | "updated" | "moved" | "changed size"
): React.Node {
  const isMetaData = META_DATA_IDS.includes(object.id);
  const name = object.name ? object.name.trim() : "";

  let content = FILE_TYPES.includes(object.type) ? (
    <FileName
      icon={null}
      type={object.type}
      name={name}
      fileClassName={style.emphasis}
    />
  ) : (
    <span>
      {isMetaData && "File metadata "}
      {!isMetaData && (name ? <Emphasis>{name}</Emphasis> : <em>Untitled</em>)}
      {type !== "deleted" && ` ${type}`}
      {type === "added" && objectSuffix(object)}
    </span>
  );

  if (type === "deleted") {
    content = <span className={style.strikethrough}>{content}</span>;
  }

  if (isMetaData) {
    return (
      <Popover
        placement="top"
        label="A file's metadata contains information like the file's color profile and settings."
      >
        {content}
      </Popover>
    );
  }

  return content;
}

function renderObjectRenamed(
  object: NonVisualChangeObject,
  oldName: string,
  newName: string
): React.Node {
  return (
    <React.Fragment>
      {oldName} <Icon type="edit" className={style.editIcon} />{" "}
      {FILE_TYPES.includes(object.type) ? (
        <FileName
          icon={null}
          type={object.type}
          name={newName}
          className={style.fileName}
          fileClassName={style.emphasis}
        />
      ) : (
        <Emphasis>{newName}</Emphasis>
      )}
    </React.Fragment>
  );
}

function renderObjectMoved(
  meta: { x?: [number, number], y?: [number, number] },
  object: NonVisualChangeObject
): React.Node {
  const x = typeof object.x === "number" ? object.x : 0;
  const y = typeof object.y === "number" ? object.y : 0;
  const oldX = meta.x ? meta.x[0] : x;
  const oldY = meta.y ? meta.y[0] : y;
  const newX = meta.x ? meta.x[1] : x;
  const newY = meta.y ? meta.y[1] : y;

  return (
    <React.Fragment>
      X: {oldX}, Y: {oldY}{" "}
      <Icon type="arrow-large-right" className={style.arrowIcon} /> X: {newX},
      Y: {newY}
    </React.Fragment>
  );
}

function renderObjectChangedSize(
  meta: { height?: [number, number], width?: [number, number] },
  object: NonVisualChangeObject
): React.Node {
  const height = typeof object.height === "number" ? object.height : 0;
  const width = typeof object.width === "number" ? object.width : 0;
  const oldHeight = meta.height ? meta.height[0] : height;
  const oldWidth = meta.width ? meta.width[0] : width;
  const newHeight = meta.height ? meta.height[1] : height;
  const newWidth = meta.width ? meta.width[1] : width;

  return (
    <React.Fragment>
      {oldHeight} x {oldWidth}
      <Icon type="arrow-large-right" className={style.arrowIcon} />
      {newHeight} x {newWidth}
    </React.Fragment>
  );
}

function renderPropertyChanged(
  change: ChangesetChange,
  object: NonVisualChangeObject,
  propName: string,
  oldValue: string,
  newValue: string
): React.Node {
  return (
    <span>
      {change.objectType === "file" ||
      META_DATA_IDS.includes(change.objectId) ||
      !object.name ? (
        `${capitalize(propName)} changed`
      ) : (
        <React.Fragment>
          <Emphasis>{object.name}</Emphasis>
          {` ${propName} changed`}
        </React.Fragment>
      )}
      {oldValue ? (
        <React.Fragment>
          {" from "}
          <Emphasis>{oldValue}</Emphasis>
        </React.Fragment>
      ) : null}
      {" to "}
      <Emphasis>{newValue}</Emphasis>
    </span>
  );
}

function objectSuffix(object: NonVisualChangeObject): React.Node {
  if (typeof object.libraryName === "string" && object.libraryName.length > 0) {
    return (
      <React.Fragment>
        {` from library `}
        <Emphasis>{object.libraryName}</Emphasis>
      </React.Fragment>
    );
  }

  return "";
}
