// @flow
import * as React from "react";
import Button from "core/components/Button";
import Time from "core/components/Time";
import anyHover from "core/lib/anyHover";
import type { Comment as TComment } from "core/types";
import style from "../style.scss";
import connector from "./connector";

export type StateProps = {|
  name: string,
  isUnresolving: boolean,
  canUnresolve?: boolean,
|};

export type DispatchProps = {|
  onUnresolve: () => void,
|};

export type OwnProps = {|
  comment: TComment,
  className?: string,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

function ResolvedCommentHeader({
  comment,
  name,
  isUnresolving,
  onUnresolve,
  canUnresolve,
  className,
}: Props) {
  return (
    <div className={className} onClick={(event) => event.stopPropagation()}>
      <div className={style.resolvedMeta}>
        <span className={style.resolvedHeaderItem}>{name}</span>
        {comment.resolvedAt && <Time date={comment.resolvedAt} />}
      </div>
      {anyHover && canUnresolve && (
        <Button
          title="Unresolve comment"
          onClick={onUnresolve}
          nude
          tint
          tintHover
          disabled={isUnresolving}
          className={style.unresolveButton}
        >
          Unresolve
        </Button>
      )}
    </div>
  );
}

export default connector(ResolvedCommentHeader);
