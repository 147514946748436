// @flow
import * as React from "react";
import ButtonLink from "core/components/ButtonLink";
import LoginForm from "core/components/LoginForm";
import OnboardingContentSheet from "core/components/OnboardingContentSheet";
import onboardingStyle from "core/components/onboarding.scss";
import OnboardingPage from "web/components/Onboarding/Page";
import RequestAccessForm, {
  type FormValues as RequestAccessFormValues,
} from "./RequestAccessForm";
import styles from "./style.scss";

type Props = {|
  isLoggedIn: boolean,
  loginError: ?Error,
  onRequestAccess: (formId: string, values: RequestAccessFormValues) => void,
  onSelectLoginForm: () => void,
  onSelectRequestAccessForm: () => void,
  organizationLogo: { url: ?string },
  organizationName: string,
  projectColor: string,
  projectName: string,
  showLogin: boolean,
  username: ?string,
  isSubmitting: boolean,
  onSignIn: (username: string, password: string, formId?: string) => void,
  onPasswordReset: () => void,
  onCreateAccount: () => void,
  resetLoginError: () => void,
|};

export default function RequestAccess({
  isLoggedIn,
  loginError,
  onRequestAccess,
  onSelectLoginForm,
  onSelectRequestAccessForm,
  organizationLogo,
  organizationName,
  projectColor,
  projectName,
  showLogin,
  username,
  onPasswordReset,
  onCreateAccount,
  isSubmitting,
  onSignIn,
  resetLoginError,
}: Props) {
  let content;

  if (showLogin && !isLoggedIn) {
    const showRequestAccess = !username;

    content = (
      <React.Fragment>
        <LoginForm
          username={username || ""}
          onSignIn={onSignIn}
          isLoadingSignIn={isSubmitting}
          error={loginError}
          resetError={resetLoginError}
          onCreateAccount={onCreateAccount}
          onPasswordReset={onPasswordReset}
        />
        {showRequestAccess && (
          <p className={styles.controlsHelperText}>
            or{" "}
            <ButtonLink onClick={onSelectRequestAccessForm}>
              request access
            </ButtonLink>{" "}
            from the {projectName} project Admin.
          </p>
        )}
      </React.Fragment>
    );
  } else {
    content = (
      <RequestAccessForm
        isLoggedIn={isLoggedIn}
        organizationLogo={organizationLogo}
        organizationName={organizationName}
        onSelectLoginForm={onSelectLoginForm}
        onSubmit={onRequestAccess}
        projectColor={projectColor}
        projectName={projectName}
      />
    );
  }

  return (
    <OnboardingPage>
      <OnboardingContentSheet className={onboardingStyle.mainContentSheet}>
        {content}
      </OnboardingContentSheet>
    </OnboardingPage>
  );
}
