// @flow
import * as React from "react";
import Centered from "core/components/Centered";
import Spinner from "core/components/Spinner";
import { getCurrentLocation, push, replace } from "core/lib/location";
import type { ReactRouterLocation } from "core/types";
import InlineError from "web/components/InlineError";
import InvitationError from "web/components/InvitationFlow/InvitationError";
import { signup, signin, organizationProjects } from "web/routeHelpers";
import type { User, Invitation, Organization } from "web/types";
import connector from "./connector";

export type OwnProps = {|
  children: React.Node,
  location: ReactRouterLocation,
  params: { token: string },
|};

export type StateProps = {|
  currentUser: ?User,
  userId: ?string,
  invitation: ?Invitation,
  isLoading: boolean,
  invalidToken: boolean,
|};

export type DispatchProps = {|
  loadOrganizations: () => Promise<Organization[]>,
  loadInvitation: (token: string) => void,
  showToast: (message: string) => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type State = {
  organizations: Organization[],
  error: ?Object,
};

class RequireValidInvitation extends React.Component<Props, State> {
  state = {
    organizations: [],
    error: null,
  };

  async componentDidMount() {
    this.loadInvitation();

    try {
      const organizations = await this.props.loadOrganizations();
      this.setState({ organizations: organizations || [] });
    } catch (error) {
      this.setState({ error });
    }
  }

  componentDidUpdate(prevProps: Props) {
    const userSignedOut = !this.props.currentUser && prevProps.currentUser;
    const tokenHasChanged = prevProps.params.token !== this.props.params.token;
    const { invitation } = this.props;

    if (invitation) {
      const isProjectInvitation = !!invitation.projectId;

      // Check to see if the user has already joined this organization
      if (!isProjectInvitation && this.state.organizations.length > 0) {
        const isUserAlreadyInOrganization = this.state.organizations.some(
          (organization) => organization.id === invitation.organizationId
        );

        if (isUserAlreadyInOrganization) {
          this.props.showToast("You’ve already joined this organization.");
          replace(organizationProjects(invitation.organizationId));
        }
      }
    }

    // Check to see if the user clicked "Sign Out" while viewing the page
    if (userSignedOut) {
      this.redirectToSignin();
    }

    // Check to see if the user was never signed in to begin with
    if (!this.props.isLoading && !this.props.userId) {
      this.loginRequired();
    }

    // If the token ever changes, load the new invitation
    if (tokenHasChanged) {
      this.loadInvitation();
    }
  }

  redirectToSignin() {
    push(signin());
  }

  loginRequired() {
    if (!this.props.invalidToken) {
      let location: ReactRouterLocation;
      if (this.props.location.state && this.props.location.state.returnTo) {
        location = this.props.location.state.returnTo;
      } else {
        location = getCurrentLocation();
      }

      replace(signup(location));
    }
  }

  loadInvitation() {
    this.props.loadInvitation(this.props.params.token);
  }

  render() {
    const { isLoading, currentUser, children } = this.props;

    if (isLoading || (!currentUser && !this.props.invalidToken)) {
      return (
        <Centered>
          <Spinner />
        </Centered>
      );
    }

    if (this.props.invalidToken) {
      return <InvitationError />;
    }

    if (this.state.error) {
      return <InlineError />;
    }

    return children;
  }
}

export const Component = RequireValidInvitation;

export default connector(RequireValidInvitation);
