// @flow
import apiRequest from "abstract-di/api";
import { trackEvent } from "core/actions/analytics";
import type { ReactionDescriptor } from "core/types";
import defineRequest from "./";

export const AddReactionRequest = defineRequest<
  ReactionDescriptor,
  ReactionDescriptor,
>({
  id: (params) => {
    return `post:reactions.add?${params.commentId}&${params.name}`;
  },
  perform: ({ name, commentId }) => {
    return apiRequest("post", "reactions", { name, commentId });
  },
  onSuccess: (response, params, dispatch) => {
    dispatch(trackEvent("REACTION_ADDED", params));
  },
});

export const RemoveReactionRequest = defineRequest<
  ReactionDescriptor,
  ReactionDescriptor,
>({
  id: (params) => {
    return `post:reactions.remove?${params.commentId}&${params.name}`;
  },
  perform: ({ name, commentId }) => {
    return apiRequest("put", "reactions", { name, commentId });
  },
  onSuccess: (response, params, dispatch) => {
    dispatch(trackEvent("REACTION_REMOVED", params));
  },
});
