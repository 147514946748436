// @flow
import classnames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import ButtonLink from "core/components/ButtonLink";
import type { State, Dispatch } from "core/types";
import { openSupportWindow } from "web/di/actions";
import style from "./style.scss";

type OwnProps = {|
  className?: string,
|};

type DispatchProps = {|
  openSupportTicket: () => void,
|};

type Props = {
  ...OwnProps,
  ...DispatchProps,
};

function InlineError(props: Props) {
  return (
    <div className={classnames(style.error, props.className)}>
      Oh no, we ran into some unexpected trouble. Please{" "}
      <ButtonLink onClick={props.openSupportTicket}>contact us</ButtonLink> —
      we’re here to help.
    </div>
  );
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    openSupportTicket: () => dispatch(openSupportWindow()),
  };
}

export default connect<Props, OwnProps, _, DispatchProps, State, Dispatch>(
  undefined,
  mapDispatchToProps
)(InlineError);
