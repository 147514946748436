// @flow
/* global Element */
import classnames from "classnames";
import { isEqual, find } from "lodash";
import * as React from "react";
import * as ReactDOM from "react-dom";
import ResizeDetector from "react-resize-detector";
import { VariableSizeList } from "react-window";
import styles from "./style.scss";
import type { Row, InnerListProps } from ".";

type Props = {
  height: number,
  width: number,
  zoom?: number,
  items: Row[],
  groupChildren?: boolean,
  overscanCount: number,
  resizeProps?: Object,
  innerElementType?: (InnerListProps) => React.Node,
  scrollRef?: React.Ref<React.ElementType>,
  innerRef?: React.Ref<React.ElementType>,
  onItemsRendered?: ({
    visibleStartIndex: number,
    visibleStopIndex: number,
  }) => mixed,
  className?: string,
  qaSelector?: string,
};

const COLUMN_COUNT = {
  small: {
    "1": 2,
    "2": 2,
    "3": 2,
    "4": 2,
    none: 2,
  },
  medium: {
    "1": 4,
    "2": 3,
    "3": 2,
    "4": 1,
    none: 3,
  },
  large: {
    "1": 5,
    "2": 4,
    "3": 3,
    "4": 2,
    none: 4,
  },
  "x-large": {
    "1": 6,
    "2": 5,
    "3": 4,
    "4": 3,
    none: 5,
  },
};

type WindowSize = $Keys<typeof COLUMN_COUNT>;

export default class VariableList extends React.Component<Props> {
  static defaultProps = {
    overscanCount: 5,
  };

  reactWindowListRef = React.createRef<VariableSizeList>();
  rows: Row[] = [];
  rowCache: { [id: string]: { id: string, height: number } } = {};

  componentDidMount() {
    this.resize();
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.width !== this.props.width ||
      prevProps.zoom !== this.props.zoom ||
      prevProps.items.length !== this.props.items.length ||
      !isEqual(prevProps.resizeProps, this.props.resizeProps)
    ) {
      this.resize();
    }
  }

  resize = (index: number = 0) => {
    const list = this.reactWindowListRef.current;
    if (list) {
      list.resetAfterIndex(index);
    }
  };

  getLastRowStyle = () => {
    if (!this.rows.length) {
      return null;
    }
    const lastRowIndex = this.rows.length - 1;
    const list = this.reactWindowListRef.current;
    return list ? list._getItemStyle(lastRowIndex) : {};
  };

  getFirstVisibleRowData = (props: InnerListProps) => {
    const list = this.reactWindowListRef.current;
    const scrollableNode = ReactDOM.findDOMNode(list);

    if (scrollableNode && scrollableNode instanceof Element) {
      const scrollTop = scrollableNode.scrollTop;
      const firstVisibleRow = find(
        props.children,
        (child) => child.props.style.top + child.props.style.height >= scrollTop
      );

      if (firstVisibleRow) {
        const row = this.rows[firstVisibleRow.props.index];
        return row ? row.data : undefined;
      }
    }
  };

  getGroupedChildren = (props: InnerListProps, excludeLastRow?: boolean) => {
    /*
      By default, VirtualizedList renders all rows at the same depth. That is,
      it's a flat list of <div>s. However, for the sake of styling, sometimes
      it's useful to be able to group rows within a parent <div>. So this function
      takes a list of children to render and then groups adjacent children with
      the same groupId within a <div>.
      In other words, instead of rendering rows like this:

      <Row1/>
      <Row2/>
      <Row3/>
      This function will render them like this:

      <Row1/>
      <div key={groupId}
        <Row2/>
        <Row3/>
      </div>
    */
    const rows = this.rows;

    let currentGroupId = "";
    let currentChildren = [];
    let childRows = [];
    let qaIndex = 0;

    props.children.forEach((child) => {
      const childIndex = child.props.index;
      const childRow = rows[childIndex];
      const childGroupId = childRow ? childRow.groupId || "" : "";

      if (excludeLastRow && childIndex === rows.length - 1) {
        return;
      }

      if (!childGroupId) {
        return childRows.push(child);
      }

      if (!currentGroupId) {
        currentGroupId = childGroupId;
      } else if (currentGroupId !== childGroupId) {
        childRows.push(
          <div key={currentGroupId} data-qa={`child-row-${qaIndex++}`}>
            {currentChildren}
          </div>
        );
        currentGroupId = childGroupId;
        currentChildren = [];
      }

      currentChildren.push(child);
    });

    if (currentChildren.length) {
      childRows.push(
        <div key={currentGroupId} data-qa={`child-row-${qaIndex++}`}>
          {currentChildren}
        </div>
      );
    }

    return childRows;
  };

  getRowHeight = (rowIndex: number): number => {
    const item = this.rows[rowIndex];

    if (!item) {
      return 0;
    }

    if (typeof item.height === "function") {
      return item.height({ columnWidth: this.columnWidth });
    }

    if (item.height !== undefined) {
      return item.height;
    }

    if (item.gridRow) {
      return this.columnWidth;
    }

    const cached = this.rowCache[`row-${rowIndex}`];
    if (!cached) {
      return item.defaultHeight || 0;
    }
    return cached.height;
  };

  getItemsToRows = (): Row[] => {
    let rows = [];
    let gridRowItems = [];
    const columnCount = this.columnCount;

    if (!this.props.items) {
      return [];
    }

    this.props.items.forEach((item, index) => {
      if (item.gridItem) {
        const nextItem = this.props.items[index + 1];

        if (gridRowItems.length < columnCount) {
          gridRowItems.push(item);
        }

        if (
          !nextItem ||
          !nextItem.gridItem ||
          gridRowItems.length === columnCount
        ) {
          rows.push({
            ...item,
            gridRow: true,
            items: gridRowItems,
            className: item.gridRowClassName,
          });
          gridRowItems = [];
        }
      } else {
        rows.push(item);
      }
    });

    return rows;
  };

  get windowSize(): WindowSize {
    const { width } = this.props;
    if (width >= 1600) {
      return "x-large";
    }
    if (width >= 1200) {
      return "large";
    }
    if (width >= 800) {
      return "medium";
    }
    return "small";
  }

  get columnCount(): number {
    const zoom = this.props.zoom || "none";
    const size = this.windowSize;
    return COLUMN_COUNT[size][zoom];
  }

  get columnWidth(): number {
    return this.props.width / this.columnCount;
  }

  handleRowResize = (rowIndex: number) => (width: number, height: number) => {
    const rowId = `row-${rowIndex}`;
    const cached = this.rowCache[rowId];

    if (!cached || height !== cached.height) {
      this.rowCache[rowId] = { id: rowId, height: height };
      this.resize(rowIndex);
    }
  };

  renderRow = ({ index, style }: { index: number, style: Object }) => {
    const item = this.rows[index];

    if (!item) {
      return null;
    }

    const rowProps = {
      key: item.key || `row-${index}`,
      style: item.style ? item.style(style) : style,
      className: classnames(item.className, styles.item, {
        [styles.gridRow]: item.gridRow,
      }),
    };

    if (item.gridRow) {
      const width = `${100 / this.columnCount}%`;
      const gridRowItems = item.items || [];
      return (
        <div {...rowProps}>
          {gridRowItems.map((gridItem, gridItemIndex) => (
            <div
              key={`${rowProps.key}-${gridItemIndex}`}
              style={{ width, maxWidth: width }}
              className={classnames(gridItem.className, styles.gridItem)}
            >
              {gridItem.children}
            </div>
          ))}
        </div>
      );
    }

    return (
      <div {...rowProps}>
        {item.height === undefined ? (
          <div>
            {item.children}
            <ResizeDetector
              handleHeight={true}
              handleWidth={false}
              onResize={this.handleRowResize(index)}
            />
          </div>
        ) : (
          item.children
        )}
      </div>
    );
  };

  renderInnerListElement = (listProps: InnerListProps) => {
    // Ensure that the scrolling container takes up its full calculated height.
    const lastRowStyle = this.getLastRowStyle();
    const newListProps = {
      ...listProps,
      style: {
        ...listProps.style,
        transform: "translateZ(0)",
        minHeight: lastRowStyle
          ? lastRowStyle.top || 0 + lastRowStyle.height || 0
          : listProps.style.minHeight,
      },
      children: this.props.groupChildren
        ? this.getGroupedChildren(listProps)
        : listProps.children,
    };

    if (this.props.innerElementType) {
      return this.props.innerElementType(newListProps);
    }

    return <div {...newListProps} />;
  };

  render() {
    this.rows = this.getItemsToRows();
    return (
      <VariableSizeList
        ref={this.reactWindowListRef}
        height={this.props.height}
        width={this.props.width}
        overscanCount={this.props.overscanCount}
        itemSize={this.getRowHeight}
        itemCount={this.rows.length}
        innerElementType={this.renderInnerListElement}
        onItemsRendered={this.props.onItemsRendered}
        innerRef={this.props.innerRef}
        outerRef={this.props.scrollRef}
        className={
          this.props.className
            ? `${this.props.className} qa-anchor-class`
            : "qa-anchor-class"
        }
      >
        {this.renderRow}
      </VariableSizeList>
    );
  }
}
