// @flow
import * as React from "react";
import Centered from "core/components/Centered";
import Flex from "core/components/Flex";
import Icon from "core/components/Icon";
import Loading from "core/components/Loading";
import window from "core/global/window";
import { replace, removeQuery } from "core/lib/location";
import connector from "./connector";
import style from "./style.scss";

type ClientTypes = "web" | "mac";

export type CoreProps = {
  location: {
    query: {
      accessToken: string,
      organizationId: string,
      clientType?: ClientTypes,
      url: string,
      isProvisioned?: string,
    },
  },
};

type Props = CoreProps & {
  authenticateToken: (
    accessToken: string,
    organizationId: string,
    url?: string,
    completeProfile?: boolean,
    clientType?: ClientTypes
  ) => void,
};

type State = {
  message?: string,
  success: boolean,
};

const WINDOW_TIMEOUT = 6000;

class TokenAuthentication extends React.Component<Props, State> {
  messageTimeout: TimeoutID;
  state = {
    message:
      this.props.location.query.clientType === "mac"
        ? "Opening Abstract…"
        : "Authenticating…",
    success: false,
  };

  componentDidMount() {
    const { accessToken, organizationId, clientType, isProvisioned, url } =
      this.props.location.query;

    replace(removeQuery("accessToken", "organizationId", "userId"));

    if (isProvisioned === "true") {
      this.props.authenticateToken(
        accessToken,
        organizationId,
        url,
        true,
        clientType
      );
      return;
    }

    // Note: For mac app, we want to redirect back to the app window
    switch (clientType) {
      case "mac":
        // remove clientType, redirect back to mac app
        // user will land on this component and this switch
        // statement will hit the null case below
        window.location.replace(
          `abstract://app/token/auth?accessToken=${accessToken}&organizationId=${organizationId}&url=${url}`
        );

        setTimeout(() => {
          this.setState({ success: true });
        }, WINDOW_TIMEOUT);
        break;

      // web clients handle the entire flow in one window
      // null means we must have been redirected from elsewhere
      case "web":
      case null:
      default:
        this.props.authenticateToken(accessToken, organizationId, url);
        break;
    }
  }

  render() {
    const { message, success } = this.state;

    return success ? (
      <Centered>
        <Flex align="center" justify="center" column>
          <Icon type="checkmark" allgood />
          <div className={style.successText}>You can now close this window</div>
        </Flex>
      </Centered>
    ) : (
      <Flex align="center" justify="center" className={style.fullScreen}>
        <Loading title={message} />
      </Flex>
    );
  }
}

export default connector(TokenAuthentication);
