// @flow
import { connect } from "react-redux";
import { isCommitUnpushed } from "abstract-di/selectors";
import { displayName } from "core/models/branch";
import { canUseNewDefaultBranchName } from "core/selectors/features";
import type { State } from "core/types";
import type { OwnProps, StateProps } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { branch, parent, project, commit } = props;
  const parentBranchName = displayName(parent, {
    masterToMain: canUseNewDefaultBranchName(state),
    titleCase: true,
  });

  return {
    parentBranchName,
    pending: isCommitUnpushed(state, {
      projectId: project ? project.id : "",
      branchId: branch ? branch.id : "",
      sha: commit.sha,
    }),
  };
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default connect(mapStateToProps);
