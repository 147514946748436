// @flow
import Immutable from "seamless-immutable";
import type { Action, RecurlyError } from "../types";

type RecurlyState = {
  threeDSChallengeActionToken: string,
  threeDSChallengeAccountCode?: string,
  error?: RecurlyError,
};

export default function recurly(
  state: RecurlyState = Immutable.from({}),
  action: Action
): RecurlyState {
  switch (action.type) {
    case "recurly/3DS_CHALLENGE_STARTED":
      return {
        threeDSChallengeActionToken: action.payload.actionToken,
        threeDSChallengeAccountCode: action.payload.accountCode,
      };
    case "recurly/RESET":
      return {};
    case "recurly/ERROR":
      return Immutable.merge(state, {
        error: action.payload,
      });
    default:
      return state;
  }
}
