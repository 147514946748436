// @flow
import { memoize, some } from "lodash";
import * as React from "react";
import Empty from "core/components/Empty";
import Flex from "core/components/Flex";
import Icon from "core/components/Icon";
import Input from "core/components/Input";
import Modal from "core/components/Modal";
import Popover from "core/components/Popover";
import Scrollable from "core/components/Scrollable";
import Spinner from "core/components/Spinner";
import window from "core/global/window";
import KeyCode from "core/lib/keycode";
import { push } from "core/lib/location";
import { metaKey, isMac, isDesktop } from "core/lib/platform";
import { descriptorPath } from "core/lib/routes";
import * as Organization from "core/models/organization";
import type {
  Organization as TOrganization,
  QuickJumpResult,
} from "core/types";
import { Placeholder as ResultPlaceholder } from "./Result";
import ResultBranch from "./ResultBranch";
import ResultOrganization from "./ResultOrganization";
import ResultProject from "./ResultProject";
import connector from "./connector";
import style from "./style.scss";

const MAX_RESULTS = 30;
const MAX_PLACEHOLDER_RESULTS = 5;
const PLACEHOLDER_RESULTS = Array(MAX_PLACEHOLDER_RESULTS).fill();

export type OwnProps = {|
  organizationId?: string,
  projectId?: string,
|};

export type StateProps = {|
  isOpen: boolean,
  isOnline: boolean,
  isLoading: boolean,
  isLoadingStrict: boolean,
  organizations: TOrganization[],
  results: QuickJumpResult[],
  searchTerm: string,
  isShowingShortcutTip: boolean,
|};

export type DispatchProps = {|
  onOpen: () => void,
  onClose: (success: ?boolean) => void,
  onChange: (term: string) => void,
|};

export type Props = {
  ...StateProps,
  ...DispatchProps,
};

export type State = {|
  isDirty: boolean,
  selectedIndex: number,
|};

const getCanSearchBranches = memoize((organizations) =>
  some(organizations, (organization) =>
    Organization.hasVersionsEnabled(organization)
  )
);

class QuickJump extends React.Component<Props, State> {
  state = {
    isDirty: false,
    selectedIndex: 0,
  };

  componentDidMount() {
    window.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown = (ev: KeyboardEvent) => {
    if (
      ev.keyCode === KeyCode.KEY_K ||
      (isDesktop && ev.keyCode === KeyCode.KEY_T)
    ) {
      if (!metaKey(ev)) {
        return;
      }
      ev.preventDefault();
      ev.stopPropagation();

      if (this.props.isOpen) {
        this.props.onClose(false);
      } else {
        this.props.onOpen();
      }
    }

    // all of the rest of these keyboard shortcuts are only active
    // when the quick jump bar is visible to the user
    if (!this.props.isOpen) {
      return;
    }

    // If there is a query in the filter then clear it on escape,
    // otherwise fall back to default Modal behavior of close
    if (ev.keyCode === KeyCode.KEY_ESCAPE && this.props.searchTerm) {
      ev.preventDefault();
      ev.stopPropagation();
      this.props.onChange("");
      this.setState({ selectedIndex: 0 });
      return;
    }

    const goUp = () => {
      ev.preventDefault();
      this.setState((state) => ({
        selectedIndex: Math.max(0, state.selectedIndex - 1),
      }));
      return;
    };

    const goDown = () => {
      ev.preventDefault();
      this.setState((state) => {
        const nextIndex = state.selectedIndex + 1;
        const lastVisibleResultIndex =
          Math.min(MAX_RESULTS, this.props.results.length) - 1;

        return {
          selectedIndex: Math.min(nextIndex, lastVisibleResultIndex),
        };
      });
      return;
    };

    if (ev.keyCode === KeyCode.KEY_DOWN || (ev.ctrlKey && ev.key === "n")) {
      goDown();
      return;
    }

    if (ev.keyCode === KeyCode.KEY_UP || (ev.ctrlKey && ev.key === "p")) {
      goUp();
      return;
    }

    if (ev.keyCode === KeyCode.KEY_TAB) {
      if (ev.shiftKey) {
        goUp();
        return;
      } else {
        goDown();
        return;
      }
    }

    if (ev.keyCode === KeyCode.KEY_RETURN) {
      ev.preventDefault();
      this.handleGoToSelected();
      return;
    }
  };

  handleSearchChange = (ev: SyntheticInputEvent<>) => {
    if (ev.target.value !== this.props.searchTerm) {
      this.props.onChange(ev.target.value);
      this.setState({ selectedIndex: 0, isDirty: true });
    }
  };

  handleGoToSelected = () => {
    const result = this.props.results[this.state.selectedIndex];

    if (result) {
      push(descriptorPath(result.descriptor));
      this.props.onClose(true);
    }
  };

  handleClickResult = () => {
    this.props.onClose(true);
  };

  handleRequestClose = (event: SyntheticEvent<>) => {
    // if there is a search term and this is a keyboard event (ESC key) then do
    // nothing – allow handleKeyDown to handle this event
    if (this.props.searchTerm && event.type === "keydown") {
      return;
    }

    // if nothing was ever typed when we close the UI, then count this
    // session as a success – unsuccessful means that they tried to search
    // and couldn't find a result.
    const success = this.state.isDirty ? false : true;
    this.props.onClose(success);
  };

  getEmptyDescription = () => {
    if (!this.props.isOnline) {
      return "Search results may be limited while offline";
    }

    const canSearchBranches = getCanSearchBranches(this.props.organizations);

    if (canSearchBranches) {
      return "Search for Organizations, Projects, or Branches";
    }

    return "Search for Organizations or Projects";
  };

  renderResult = (result: QuickJumpResult, index: number) => {
    const selected = this.state.selectedIndex === index;
    const props = {
      selected,
      "aria-selected": selected ? "true" : "false",
      onClick: this.handleClickResult,
      id: `result-${index}`,
    };

    if (result.descriptor.branchId) {
      return (
        <ResultBranch
          key={`${result.descriptor.projectId}-${result.descriptor.branchId}`}
          descriptor={result.descriptor}
          {...props}
        />
      );
    }

    if (
      result.descriptor.projectId &&
      result.descriptor.branchId === undefined
    ) {
      return (
        <ResultProject
          key={result.descriptor.projectId}
          descriptor={result.descriptor}
          {...props}
        />
      );
    }

    if (result.descriptor.organizationId) {
      return (
        <ResultOrganization
          key={result.descriptor.organizationId}
          descriptor={result.descriptor}
          {...props}
        />
      );
    }

    return null;
  };

  render() {
    const {
      results,
      isLoading,
      isLoadingStrict,
      isOpen,
      isOnline,
      searchTerm,
      isShowingShortcutTip,
    } = this.props;
    const showSearchResults = !!(searchTerm && results.length);
    const showEmptyState = !!(searchTerm && !isLoading && !results.length);
    const showLoadingState = !!(searchTerm && isLoading && !results.length);
    const showFooter = !!searchTerm;
    const showContent = !!searchTerm;

    return (
      <Modal
        className={style.wrapper}
        isOpen={isOpen}
        onRequestClose={this.handleRequestClose}
        size="large"
        role="combobox"
        aria-haspopup="listbox"
        aria-owns="results"
        aria-expanded
        disableTransitions
      >
        {isLoadingStrict && !isShowingShortcutTip && (
          <Spinner className={style.loading} small />
        )}
        <Input
          placeholder="Jump to…"
          type="search"
          icon="quick-jump"
          onChange={this.handleSearchChange}
          wrapperClass={style.inputWrapper}
          className={style.input}
          value={searchTerm}
          aria-autocomplete="list"
          aria-controls="results"
          aria-activedescendant={`result-${this.state.selectedIndex}`}
          autoComplete="off"
          stripNewlines
          autoFocus
          innerContextTag={
            isShowingShortcutTip
              ? `${isMac() ? "⌘K" : "Ctrl+K"} to open anywhere`
              : undefined
          }
        />
        {showContent && (
          <Flex className={style.content} column>
            {showSearchResults && (
              <Scrollable
                className={style.results}
                role="listbox"
                id="results"
                flex
              >
                {results.slice(0, MAX_RESULTS).map(this.renderResult)}
              </Scrollable>
            )}
            {showLoadingState && (
              <Flex className={style.results} column>
                {PLACEHOLDER_RESULTS.map((value, index: number) => (
                  <ResultPlaceholder
                    key={index}
                    width={`${Math.abs(Math.sin(index + 1) * 50) + 20}%`}
                  />
                ))}
              </Flex>
            )}
            {showEmptyState && (
              <Empty
                title={`No results for "${searchTerm}"`}
                description={this.getEmptyDescription()}
                className={style.results}
                flex
              />
            )}
            {showFooter && (
              <Flex
                className={style.footer}
                align="center"
                justify="space-between"
              >
                <Flex align="center">
                  <kbd className={style.footerKey}>Up</kbd>
                  <kbd className={style.footerKey}>Down</kbd>
                  <span className={style.footerLabel}>Navigate</span>
                  <kbd className={style.footerKey}>Return</kbd>
                  <span className={style.footerLabel}>Select</span>
                  <kbd className={style.footerKey}>Esc</kbd>
                  <span className={style.footerLabel}>Dismiss</span>
                </Flex>
                {!isOnline && (
                  <Popover
                    placement="top"
                    className={style.offline}
                    label="Results may be limited while offline"
                  >
                    <Icon type="offline" />
                  </Popover>
                )}
              </Flex>
            )}
          </Flex>
        )}
      </Modal>
    );
  }
}

export default connector(QuickJump);
