// @flow
import * as React from "react";
import { connect } from "react-redux";
import { withData } from "core/components/DataLoader";
import DocumentTitle from "core/components/DocumentTitle";
import NotFound from "core/components/Empty/NotFound";
import NotPermitted from "core/components/Empty/NotPermitted";
import Loaded from "core/components/Loaded";
import createConnector from "core/lib/createConnector";
import * as Request from "core/models/request";
import { getOrganizationSettings } from "core/selectors/organizationSettings";
import { getOrganization } from "core/selectors/organizations";
import { getOrganizationPolicy } from "core/selectors/policies";
import type {
  Dispatch,
  ReactRouterLocation,
  State,
  GithubIntegration,
  JiraIntegration,
  SlackIntegration,
} from "core/types";
import { OrganizationSettingsRequest } from "web/requests/organizationSettings";
import { closeSidebar } from "../actions/sidebar";
import SettingsShell from "../components/SettingsShell";

type OwnProps = {|
  children: React.Node,
  location: ReactRouterLocation,
  params: { integrationId: string, organizationId: string },
|};

type StateProps = {|
  integration: ?SlackIntegration | ?GithubIntegration | ?JiraIntegration,
  isLoading: boolean,
  notFound: boolean,
  notPermitted: boolean,
  sidebarIsOpen: boolean,
|};

type DispatchProps = {|
  onCloseSidebar: () => void,
  onLoad: () => void,
|};

type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

function IntegrationSettingsShell(props: Props) {
  const {
    integration,
    location,
    sidebarIsOpen,
    notPermitted,
    notFound,
    isLoading,
    onCloseSidebar,
    children,
  } = props;
  const { organizationId, integrationId } = props.params;

  if (notFound) {
    return <NotFound title="Not found" description="Integration not found" />;
  }

  if (notPermitted) {
    return <NotPermitted />;
  }

  const title = integration ? `${integration.kind} Integration` : "Integration";

  return (
    <Loaded loading={isLoading} title="Loading integration settings…">
      {() => (
        <SettingsShell
          onCloseSidebar={onCloseSidebar}
          sidebarIsOpen={sidebarIsOpen}
          integrationId={integrationId}
          integrationKind={(integration && integration.kind) || ""}
          organizationId={organizationId}
          location={location}
          page="integrations"
        >
          <DocumentTitle defaultTitle={title} titleTemplate={`${title} - %s`} />
          {children}
        </SettingsShell>
      )}
    </Loaded>
  );
}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const organizationId = props.params.organizationId;
  const organization = getOrganization(state, { organizationId });
  const settings = getOrganizationSettings(state, { organizationId });
  const policy = getOrganizationPolicy(state, { organizationId });
  const policyLoaded = !!Object.keys(policy).length;

  const jiraIntegration = settings ? settings.jiraIntegration : undefined;
  const githubIntegration = settings ? settings.githubIntegration : undefined;
  const slackIntegration = settings ? settings.slackIntegration : undefined;

  const settingsRequest = OrganizationSettingsRequest.getRequest(state, {
    organizationId,
  });

  const cannotManageSettings = policyLoaded && !policy.manageSettings;
  const cannotManageIntegrations = policyLoaded && !policy.manageIntegrations;
  const usernameOrganizationRestricted =
    organization && organization.isUsernameOrganization;
  const notPermitted = !!(
    Request.forbidden(settingsRequest) ||
    cannotManageSettings ||
    cannotManageIntegrations ||
    usernameOrganizationRestricted
  );

  const integrations = [slackIntegration, githubIntegration, jiraIntegration];
  const integration = integrations.find((i) => {
    return i && i.id === props.params.integrationId;
  });

  const notFound = !!(
    Request.notFound(settingsRequest) ||
    (settings && !integration)
  );

  return {
    integration: integration,
    sidebarIsOpen: state.sidebar.open,
    notPermitted,
    notFound,
    isLoading: Request.isLoading(settingsRequest),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    onCloseSidebar: () => dispatch(closeSidebar()),
    onLoad: () =>
      dispatch(
        OrganizationSettingsRequest.perform({
          params: { organizationId: props.params.organizationId },
        })
      ),
  };
}

export default createConnector<Props, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withData((props: OwnProps) => ({
    integrationId: props.params.integrationId,
    organizationId: props.params.organizationId,
  }))
)(IntegrationSettingsShell);
