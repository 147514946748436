// @flow
import { connect } from "react-redux";
import {
  getCurrentUserId,
  sketchLibrariesSupported,
} from "abstract-di/selectors";
import { showDialog } from "core/actions/dialogs";
import contextMenuWrapper, {
  type Props as ContextMenuWrapperProps,
} from "core/components/ContextMenu/ContextMenuWrapper";
import createConnector from "core/lib/createConnector";
import { userIsAuthor, isMaster } from "core/models/branch";
import { getBranch } from "core/selectors/branches";
import { getProjectPolicy } from "core/selectors/policies";
import { getProject } from "core/selectors/projects";
import type { State, Dispatch } from "core/types";

import type { OwnProps, StateProps, DispatchProps, Props } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { projectId, branchId } = props;
  const project = getProject(state, { projectId });
  const branch = getBranch(state, { projectId, branchId });
  const currentUserId = getCurrentUserId(state);
  const userIsBranchAuthor = currentUserId
    ? userIsAuthor(branch, currentUserId)
    : false;

  return {
    project,
    branch,
    policy: getProjectPolicy(state, { projectId }),
    shouldShowLibraryActions:
      !!branch &&
      sketchLibrariesSupported(state) &&
      (userIsBranchAuthor || isMaster(branch)),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { projectId, branchId, library } = props;
  return {
    showRemoveLibraryDialog: () => {
      dispatch(
        showDialog("ConfirmRemoveLibrary", {
          projectId,
          branchId,
          library,
        })
      );
    },
  };
}

export default createConnector<
  Props,
  {|
    ...OwnProps,
    ...$Exact<ContextMenuWrapperProps>,
  |},
>(
  contextMenuWrapper,
  connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch>(
    mapStateToProps,
    mapDispatchToProps
  )
);
