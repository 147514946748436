// @flow
import classnames from "classnames";
import * as React from "react";
import style from "./style.scss";

type Props = {
  number: ?number,
  animated?: boolean,
  centered?: boolean,
  removable?: boolean,
  disabled?: boolean,
  size?: "large" | "small" | "xsmall",
  className?: string,
  innerRef?: React.Ref<"div">,
  noClick?: boolean,
  onClick?: Function,
};

export default function AnnotationBubble({
  number,
  animated,
  centered,
  removable,
  size,
  disabled,
  innerRef,
  noClick,
  className,
  ...rest
}: Props) {
  const classes = classnames(style.bubble, className, {
    [style.centered]: centered,
    [style.removable]: removable,
    [style.large]: size === "large",
    [style.small]: size === "small",
    [style.xsmall]: size === "xsmall",
    [style.noClick]: noClick,
    [style.disabled]: disabled,
  });

  return (
    <div
      className={classes}
      ref={innerRef}
      animated={animated ? "true" : undefined}
      {...rest}
    >
      {number}
    </div>
  );
}
