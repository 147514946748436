// @flow
import * as React from "react";
import { connect } from "react-redux";
import * as Request from "core/models/request";
import { OrganizationsFetchRequest } from "core/requests/organizations";
import { getNonUsernameOrganizations } from "core/selectors/organizations";
import type { Organization, User, State } from "core/types";
import { leaveOrganization } from "web/actions/memberships";
import { loadOrganizations } from "web/actions/organizations";
import OrganizationsEdit from "web/components/ProfileSettings/OrganizationsEdit";
import { getCurrentUser } from "web/selectors/session";

type Props = {
  account: User,
  error: boolean,
  isLoading: boolean,
  leaveOrganization: *,
  loadOrganizations: () => void,
  organizations: Organization[],
  organizationsLoaded: boolean,
};

class OrganizationsEditContainer extends React.Component<Props> {
  componentDidMount() {
    this.props.loadOrganizations();
  }

  render() {
    return <OrganizationsEdit {...this.props} />;
  }
}

function mapStateToProps(state: State) {
  const request = OrganizationsFetchRequest.getRequest(state);

  return {
    account: getCurrentUser(state),
    organizations: getNonUsernameOrganizations(state),
    error: Request.hasError(request),
    isLoading: Request.isLoading(request),
  };
}

const actions = { leaveOrganization, loadOrganizations };

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default connect(mapStateToProps, actions)(OrganizationsEditContainer);
