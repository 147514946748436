// @flow
import { connect } from "react-redux";
import { getCurrentUserId } from "abstract-di/selectors";
import { leaveProject } from "core/actions/projectMemberships";
import * as Request from "core/models/request";
import { DeleteProjectMembershipRequest } from "core/requests/projectMemberships";
import type { State, Dispatch } from "core/types";
import type { OwnProps, StateProps, DispatchProps, Props } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const currentUserId = getCurrentUserId(state);

  const removeRequest = DeleteProjectMembershipRequest.getRequest(state, {
    projectId: props.project.id,
    userId: props.user.id,
  });

  let error;
  if (Request.forbidden(removeRequest) && props.user.id === currentUserId) {
    error =
      "You cannot leave this project because you are the last remaining admin.";
  } else if (Request.hasError(removeRequest)) {
    error = "There was an error removing this person from the project.";
  }

  return {
    isDeleteMembershipRequestLoading: currentUserId
      ? DeleteProjectMembershipRequest.isLoadingStrict(state, {
          projectId: props.project.id,
          userId: currentUserId,
        })
      : false,
    error,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    leaveProject: () => {
      return dispatch(leaveProject(props.project.id, true));
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
