// @flow
import classnames from "classnames";
import { find } from "lodash";
import * as React from "react";
import Button from "core/components/Button";
import DialogForm from "core/components/DialogForm";
import Flex from "core/components/Flex";
import Heading from "core/components/Heading";
import ProjectColor from "core/components/ProjectColor";
import ProjectName from "core/components/ProjectName";
import SettingsItem from "core/components/SettingsItem";
import type {
  Project,
  SlackChannel,
  SlackIntegration,
  SlackIntegrationOverrideDetails,
} from "core/types";
import ProjectChannelDialog from "web/components/ProjectChannelDialog";
import type { OverrideProject } from "web/components/SlackIntegrationSettings/ProjectChannels";
import ContextMenu from "web/di/components/ContextMenu";
import style from "./style.scss";

type Props = {
  slackIntegration: SlackIntegration,
  defaultChannel: ?SlackChannel,
  project: OverrideProject,
  projects: Project[],
  onUpdate: (
    formId: string,
    overrideId: string,
    values: SlackIntegrationOverrideDetails
  ) => *,
  onRemove: (overrideId: string) => *,
};

type State = {
  showConfirm: boolean,
  showDialog: boolean,
  removingChannel: boolean,
};

export default class ProjectChannelsListItem extends React.Component<
  Props,
  State,
> {
  state = {
    showConfirm: false,
    showDialog: false,
    removingChannel: false,
  };

  static getDerivedStateFromProps(props: Props) {
    if (!props.project || !props.project.override) {
      return { showConfirm: false, removingChannel: false };
    }
    return null;
  }

  openDialog = () => {
    this.setState({ showDialog: true });
  };

  closeDialog = (event?: SyntheticEvent<>) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({ showDialog: false });
  };

  openConfirm = () => {
    this.setState({ showConfirm: true });
  };

  closeConfirm = (event?: SyntheticEvent<>) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({ showConfirm: false });
  };

  removeChannel = (event: SyntheticEvent<>) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({ removingChannel: true }, () => {
      this.props.onRemove(this.props.project.override.id);
    });
  };

  getChannelInfo = (details: SlackIntegrationOverrideDetails) => {
    if (details.channel && !details.notificationsDisabled) {
      const channel = find(
        this.props.slackIntegration.details.availableChannels,
        { id: details.channel }
      );
      return `#${channel ? channel.name : details.channel || ""}`;
    }
    return "Notifications Disabled";
  };

  getV2ChannelInfo = (details: SlackIntegrationOverrideDetails) => {
    if (details.channels && !details.notificationsDisabled) {
      const { availableChannels } = this.props.slackIntegration.details;
      const validChannels = availableChannels.filter(
        (channel) =>
          details.channels &&
          details.channels.find((channelId) => channelId === channel.id)
      );
      return validChannels.map((channel) => `#${channel.name}`).join(", ");
    }
    return "Notifications Disabled";
  };

  renderConfirm = () => {
    const { defaultChannel } = this.props;
    return (
      <DialogForm
        dangerous
        title="Confirm removal"
        onSubmit={this.removeChannel}
        isOpen={this.state.showConfirm}
        disabled={this.state.removingChannel}
        onClose={this.closeConfirm}
        primaryButton={
          this.state.removingChannel
            ? "Removing Configuration…"
            : "Remove Configuration"
        }
      >
        <div>
          {`Removing this configuration means that any messages from this project will now post to your organization's default channel`}
          {defaultChannel && (
            <span>
              , <strong>#{defaultChannel.name}</strong>
            </span>
          )}
          .
        </div>
      </DialogForm>
    );
  };

  menuItems = () => {
    const isPrivate = this.props.project.visibility === "specific";
    const slackV2 = this.props.slackIntegration.details.slackV2;
    return [
      {
        click: this.openDialog,
        label: slackV2 ? `Change default channels…` : `Change channel…`,
      },
      {
        type: "separator",
        visible: !isPrivate,
      },
      {
        danger: true,
        visible: !isPrivate,
        click: this.openConfirm,
        label: slackV2 ? `Remove default channels…` : `Remove channel…`,
      },
    ];
  };

  render() {
    const { project, projects, slackIntegration, defaultChannel, onUpdate } =
      this.props;
    const { details } = project.override;
    const channelInfo = slackIntegration.details.slackV2
      ? this.getV2ChannelInfo(details)
      : this.getChannelInfo(details);
    return (
      <React.Fragment>
        <SettingsItem className={style.item}>
          <ProjectColor color={project.color} />
          <Flex
            wrap
            align="center"
            justify="flex-start"
            className={style.content}
          >
            <Heading level="4" size="m" className={style.heading}>
              <ProjectName project={project} />
            </Heading>
            <span
              className={classnames(style.channelName, {
                [style.disabled]: details.notificationsDisabled,
              })}
            >
              {channelInfo}
            </span>
          </Flex>
          <span className={style.buttonWrap}>
            <ContextMenu id="edit-channel-menu" menuItems={this.menuItems()}>
              {(showMenu, ref) => (
                <Button
                  nude
                  icon="overflow"
                  onClick={showMenu}
                  innerRef={ref}
                />
              )}
            </ContextMenu>
          </span>
        </SettingsItem>
        {this.renderConfirm()}
        <ProjectChannelDialog
          onClose={this.closeDialog}
          isOpen={this.state.showDialog}
          project={project}
          projects={projects}
          defaultChannel={defaultChannel}
          slackIntegration={slackIntegration}
          onSubmit={onUpdate}
        />
      </React.Fragment>
    );
  }
}
