// @flow
import apiRequest from "abstract-di/api";
import { entitiesReceived } from "core/actions/entities";
import defineRequest from "core/requests";
import { sessionLoaded } from "web/actions/session";
import * as API from "web/api";
import * as URLs from "web/api/urls";
import type { FormValues } from "web/models/form";
import { normalizeMe } from "web/schemas/me";

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export const AccountUpdateRequest = defineRequest({
  id() {
    return `account-update`;
  },
  perform(values: FormValues) {
    return API.updateAccount(values);
  },
  async onSuccess(response, params: FormValues, dispatch) {
    const { entities } = normalizeMe(response);
    dispatch(entitiesReceived(entities));
    dispatch(sessionLoaded(response));
  },
});

export const AcceptedTrialAgreementRequest = defineRequest<{}, {}>({
  id() {
    return "accepted-trial-agreement";
  },
  perform: () => {
    return apiRequest("put", "account", {
      trialConsent: true,
    });
  },
  onSuccess(response, params, dispatch) {
    const { entities } = normalizeMe(response);
    dispatch(entitiesReceived(entities));
    dispatch(sessionLoaded(response));
  },
});

type VerifyEmailParams = {
  id: string,
};

export const VerifyEmailRequest = defineRequest<
  VerifyEmailParams,
  VerifyEmailParams,
>({
  id({ id }) {
    return URLs.verifyEmail(id);
  },
  perform({ id }) {
    return API.verifyEmail(id);
  },
  onSuccess(response, params, dispatch) {
    dispatch(entitiesReceived(response));
    return response;
  },
});

type SendEmailVerificationParams = {
  token: string,
};

export const SendEmailVerificationRequest = defineRequest<
  SendEmailVerificationParams,
  SendEmailVerificationParams,
>({
  id: (params) => {
    const { token } = params;
    return URLs.sendEmailVerification(token);
  },
  perform: (params) => {
    const { token } = params;
    return API.sendEmailVerification(token);
  },
});
