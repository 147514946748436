// @flow
import createCachedSelector from "@elasticprojects/re-reselect";
import { filter, sortBy, values } from "lodash";
import { createSelector } from "reselect";
import * as Notification from "core/models/notification";
import { getNonUsernameOrganizations } from "core/selectors/organizations";
import type { Notification as TNotification, State } from "core/types";

type NotificationFilters = {|
  isRead?: ?boolean,
  oldestCreatedAt?: ?string,
  organizationId?: ?string,
|};

export function getNotifications(state: State) {
  return state.notifications;
}

function getFilterOrganizationId(
  state: State,
  filters: ?NotificationFilters
): ?string {
  return filters ? filters.organizationId : null;
}

function getFilterReadStatus(
  state: State,
  filters: ?NotificationFilters
): ?boolean {
  return filters ? filters.isRead : null;
}

function getFilterOldestCreatedAt(
  state: State,
  filters: ?NotificationFilters
): ?string {
  return filters ? filters.oldestCreatedAt : null;
}

function getFiltersCacheKey(
  state: State,
  filters: ?NotificationFilters
): string {
  if (!filters) {
    return "";
  }

  const organizationId =
    filters.organizationId != null ? filters.organizationId : "any-org";

  const readStatus =
    filters.isRead === true
      ? "read"
      : filters.isRead === false
      ? "unread"
      : "read+unread";

  return `${organizationId}-${readStatus}-${filters.oldestCreatedAt || ""}`;
}

export const getFilteredNotifications: (
  state: State,
  filters: ?NotificationFilters
) => TNotification[] = createCachedSelector(
  getNotifications,
  getFilterOldestCreatedAt,
  getFilterOrganizationId,
  getFilterReadStatus,
  (notifications, oldestCreatedAt, organizationId, isRead) => {
    const filteredNotifications = filter(
      values(notifications),
      (notification) => {
        if (oldestCreatedAt && notification.createdAt < oldestCreatedAt) {
          return false;
        }

        if (organizationId && notification.organizationId !== organizationId) {
          return false;
        }

        if (isRead === true && notification.readAt == null) {
          return false;
        } else if (isRead === false && notification.readAt != null) {
          return false;
        }

        return Notification.show(notification);
      }
    );

    return sortBy(filteredNotifications, "createdAt").reverse();
  }
)(getFiltersCacheKey);

export const getOldestNotificationCreatedAt: (
  state: State,
  filters: ?NotificationFilters
) => string = createCachedSelector(
  getFilteredNotifications,
  (notifications) => {
    return values(notifications).reduceRight(
      (oldestCreatedAt, notification) => {
        return oldestCreatedAt < notification.createdAt
          ? oldestCreatedAt
          : notification.createdAt;
      },
      new Date().toISOString()
    );
  }
)(getFiltersCacheKey);

export const getUnreadCountsForOrganizations: (state: State) => {
  [id: string]: number,
} = createSelector(
  getNotifications,
  getNonUsernameOrganizations,
  (notifications, organizations) => {
    const orgUnreads = organizations.reduce((obj, organization) => {
      obj[organization.id] = 0;
      return obj;
    }, {});

    values(notifications).forEach((notification) => {
      if (notification.readAt === null) {
        orgUnreads[notification.organizationId]++;
      }
    });

    return orgUnreads;
  }
);

export function getUnreadNotificationsCount(state: State): number {
  return getFilteredNotifications(state, { isRead: false }).length;
}
