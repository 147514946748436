// @flow
import { map } from "lodash";
import * as React from "react";
import { FileItem, Placeholder } from "core/components/FileItem";
import Flex from "core/components/Flex";
import KeyboardNavigation from "core/components/KeyboardNavigation";
import SidebarHeading from "core/components/SidebarHeading";
import type { File, Branch } from "core/types";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  projectId: string,
  branch: Branch,
|};

export type StateProps = {|
  isLoading: boolean,
  files: File[],
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
};

function GetStartedFileLauncher(props: Props) {
  const { files, isLoading, branch } = props;

  return (
    <Flex column={true} className={style.sidebar}>
      <SidebarHeading
        dynamicPadding
        level="3"
        size="xl"
        className={style.sidebarHeading}
      >
        Get started
      </SidebarHeading>
      <div className={style.message}>Choose a file to start editing</div>
      {isLoading ? (
        <React.Fragment>
          <Placeholder className={style.file} />
          <Placeholder className={style.file} />
          <Placeholder className={style.file} />
        </React.Fragment>
      ) : (
        <KeyboardNavigation>
          {({ isFocused, selectedClassName }) => (
            <div>
              {map(files, (file) => (
                <FileItem
                  branchId={branch.id}
                  key={file.id}
                  file={file}
                  small={false}
                  mobile={false}
                  isFocused={isFocused}
                  selectedClassName={selectedClassName}
                  itemClassName={style.file}
                  dark
                  editOnClick
                />
              ))}
            </div>
          )}
        </KeyboardNavigation>
      )}
    </Flex>
  );
}

export default connector(GetStartedFileLauncher);
