// @flow
import * as React from "react";
import { connect } from "react-redux";
import { dismissDialog } from "core/actions/dialogs";
import ProjectDialog from "core/components/ProjectDialog";
import type { State, Dispatch } from "core/types";

type OwnProps = { organizationId: string };
type DispatchProps = {|
  onClose: () => void,
|};

type Props = {
  ...OwnProps,
  ...DispatchProps,
};

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    onClose() {
      dispatch(dismissDialog());
    },
  };
}

function CreateProjectDialog(props: Props) {
  return <ProjectDialog isOpen action="create" {...props} />;
}

export default connect<Props, OwnProps, _, DispatchProps, State, Dispatch>(
  undefined,
  mapDispatchToProps
)(CreateProjectDialog);
