// @flow
import classnames from "classnames";
import * as React from "react";
import AnimatedFlyover, {
  type AnchorPosition,
} from "core/components/AnimatedFlyover";
import Button from "core/components/Button";
import Flex from "core/components/Flex";
import MenuItem from "core/components/FloatingMenuItem";
import Icon from "core/components/Icon";
import InputSearch from "core/components/InputSearch";
import MenuDivider from "core/components/MenuDivider";
import VirtualizedList from "core/components/VirtualizedList";
import window from "core/global/window";
import KeyCode from "core/lib/keycode";
import matchString from "core/lib/matchString";
import style from "./style.scss";

export type Item = $ReadOnly<{
  id: string,
  name: string,
  icon?: React.Node,
  extra?: string,
}>;

type Props<T> = {
  items: T[],
  selectedItem: ?T,
  onSelectItem: (itemId: string) => void,
  onSearchChange?: (searchValue: string) => void,
  buttonText?: React.Node,
  placeholderText?: string,
  className?: string,
  disclaimer?: string,
  disabled?: boolean,
  allowDeselection?: boolean,
  anchor?: AnchorPosition,
  mobile?: boolean,
  disableIcon?: boolean,
  isLoading?: boolean,
  isOffline?: boolean,
  qaSelector?: string,
  placeholderIcon?: string,
  disclosure?: boolean,
};

type State = {
  isOpen: boolean,
  searchValue: string,
};

export default class FilterPopover<T: Item> extends React.Component<
  Props<T>,
  State,
> {
  static defaultProps = {
    placeholderText: "Search…",
    anchor: "center",
    disabled: false,
    allowDeselection: true,
    disableIcon: false,
  };

  state = {
    isOpen: false,
    searchValue: "",
  };

  componentDidMount() {
    window.addEventListener("keydown", this.handleKeydown);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeydown);
  }

  handleKeydown = (event: KeyboardEvent) => {
    if (event.keyCode === KeyCode.KEY_RETURN) {
      const items = this.getItems();

      if (items.length === 1) {
        this.handleSelectItem(items[0]);
      }
    }
  };

  handleChangeSearchBar = (event: SyntheticInputEvent<*>) => {
    this.setState({ searchValue: event.target.value }, () => {
      if (this.props.onSearchChange) {
        this.props.onSearchChange(event.target.value);
      }
    });
  };

  handleSelectItem = (item: ?Item) => {
    if (!item) {
      return;
    }
    this.props.onSelectItem(item.id);
    this.setState({ isOpen: false });
  };

  handleToggleOpen = (event: SyntheticEvent<>) => {
    event.preventDefault();
    this.setState((prev) => ({ isOpen: !prev.isOpen }));
  };

  getItems = (): T[] => {
    return this.props.items.filter((item) => {
      if (this.props.selectedItem && item.id === this.props.selectedItem.id) {
        return false;
      }
      return (
        matchString(item.name, this.state.searchValue) ||
        matchString(item.extra, this.state.searchValue)
      );
    });
  };

  renderPopoutBody(selectedItem: ?T) {
    const items = this.getItems();
    const { searchValue } = this.state;
    const empty = items.length === 0;

    const emptyMessage = this.props.isLoading
      ? "Loading results for "
      : "No items were found matching ";
    const offlineDisclaimer = this.props.isOffline
      ? " Offline results may be limited."
      : null;

    return (
      <div className={classnames({ [style.hasSelectedItem]: selectedItem })}>
        <InputSearch
          autoFocus
          disabled={this.props.disabled}
          placeholder={this.props.placeholderText}
          large={this.props.mobile}
          value={searchValue}
          onChange={this.handleChangeSearchBar}
          wrapperClass={style.search}
        />
        {selectedItem && (
          <React.Fragment>
            <MenuItem
              icon={
                !this.props.disableIcon && selectedItem.icon
                  ? selectedItem.icon
                  : undefined
              }
              onClick={() => this.handleSelectItem(selectedItem)}
              className={classnames(style.item, {
                [style.deselectableItem]: this.props.allowDeselection,
              })}
            >
              {selectedItem.name}
              <Flex align="center" justify="flex-end">
                <Icon type="close" className={style.closeIcon} tint />
                <Icon type="checkmark" className={style.checkmarkIcon} tint />
              </Flex>
            </MenuItem>
            {this.props.disclaimer && (
              <div className={style.disclaimer}>{this.props.disclaimer}</div>
            )}
            {!empty && <MenuDivider className={style.divider} />}
          </React.Fragment>
        )}

        {searchValue && empty && (
          <div className={style.emptyExplanation}>
            {emptyMessage}
            <span className={style.searchTerm}>{searchValue}</span>…
            {offlineDisclaimer}
          </div>
        )}

        {!empty && (
          <VirtualizedList
            fixed
            width={320}
            height={selectedItem ? 304 : 344}
            itemSize={40}
            items={items}
          >
            {(item) => (
              <MenuItem
                key={item.id}
                icon={!this.props.disableIcon ? item.icon : undefined}
                disabled={this.props.disabled}
                active={items.length === 1 && searchValue !== ""}
                onClick={() => this.handleSelectItem(item)}
                className={style.item}
              >
                {item.name}
                {item.extra && (
                  <span className={style.itemExtraInfo}>{item.extra}</span>
                )}
              </MenuItem>
            )}
          </VirtualizedList>
        )}
      </div>
    );
  }

  render() {
    const { disclosure = true } = this.props;

    return (
      <AnimatedFlyover
        anchor={this.props.anchor}
        className={style.selectorContainer}
        wrapperClass={this.props.className}
        body={this.renderPopoutBody(this.props.selectedItem)}
        onClickOutside={() => this.setState({ isOpen: false })}
        onRequestClose={() => this.setState({ isOpen: false })}
        isOpen={this.state.isOpen}
      >
        <Button
          nude
          disclosure={disclosure}
          onClick={this.handleToggleOpen}
          qaSelector={this.props.qaSelector}
          icon={this.props.placeholderIcon}
          children={this.props.buttonText}
        />
      </AnimatedFlyover>
    );
  }
}
