// @flow
import * as React from "react";
import CoreListItem from "../AutoCompleteListItem";

// See https://github.com/draft-js-plugins/draft-js-plugins/blob/master/packages/mention/src/MentionSuggestions/Entry/DefaultEntryComponent.tsx

type Props = {
  searchValue: string,
  className: string, // theme.mentionSuggestionsEntryFocused | theme.mentionSuggestionsEntry
  theme: { [string]: string },
  isFocused: Boolean,
  // ImmutableJS Object
  mention: {
    id: string,
    name: string,
    title: string,
    avatarUrl: string,
  },
};

export default function AutoCompleteListItem({
  mention,
  searchValue,
  isFocused,
  ...rest
}: Props) {
  const user = {
    username: mention.name,
    name: mention.title,
    avatarUrl: mention.avatarUrl,
    id: mention.id,
  };

  return <CoreListItem user={user} {...rest} aria-selected={isFocused} />;
}
