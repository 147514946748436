// @flow
import { connect } from "react-redux";
import { getTeamPolicy } from "core/selectors/policies";
import { getProjectsForTeam } from "core/selectors/projects";
import { getTeam } from "core/selectors/teams";
import type { State, Dispatch } from "core/types";
import type { OwnProps, Props, StateProps } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const team = getTeam(state, { teamId: props.params.teamId });
  const teamProjects = getProjectsForTeam(state, {
    teamId: props.params.teamId,
  });
  const teamPolicy = getTeamPolicy(state, { teamId: props.params.teamId });

  return {
    team,
    teamPolicy,
    teamProjects,
  };
}

export default connect<Props, OwnProps, StateProps, _, State, Dispatch>(
  mapStateToProps
);
