// @flow
import * as React from "react";
import Button from "core/components/Button";
import Empty from "core/components/Empty";
import NoResults from "core/components/Empty/NoResults";
import type { Policy } from "core/types";

type Props = {
  searchTerm: ?string,
  teamPolicy: ?Policy,
  onDialogOpen: () => void,
};

export default function NoTeamMembers(props: Props) {
  return props.searchTerm ? (
    <NoResults flex type="teams" term={props.searchTerm} />
  ) : (
    <Empty
      icon="team"
      title="Team Members"
      description="This team doesn’t have any members yet. Organization admins can add members."
    >
      {props.teamPolicy && props.teamPolicy.addMember ? (
        <Button primary onClick={props.onDialogOpen}>
          Add a member
        </Button>
      ) : null}
    </Empty>
  );
}
