// @flow
import empty from "empty";
import * as Branch from "core/models/branch";
import { getEntity, getRawEntities } from "core/selectors/entities";
import type { State, Policy } from "core/types";

export const DEFAULT_POLICY: Policy = empty.object;

export function getPolicy(state: State, params: { subjectId: string }): Policy {
  return getEntity(state, "policies", params.subjectId) || DEFAULT_POLICY;
}

export function getAllPolicies(state: State): { [subjectId: string]: Policy } {
  return getRawEntities(state, "policies");
}

export function getUserPolicy(state: State, params: { userId: string }) {
  return getPolicy(state, { subjectId: params.userId });
}

export function getOrganizationPolicy(
  state: State,
  params: { organizationId: string }
) {
  return getPolicy(state, { subjectId: params.organizationId });
}

export function getProjectPolicy(state: State, params: { projectId: string }) {
  return getPolicy(state, { subjectId: params.projectId });
}

export function getBranchPolicy(
  state: State,
  params: { branchId: string, projectId: string }
) {
  return getPolicy(state, { subjectId: Branch.uniqueId(params) });
}

export function getCollectionPolicy(
  state: State,
  params: { collectionId: string }
) {
  return getPolicy(state, { subjectId: params.collectionId });
}

export function getTeamPolicy(state: State, params: { teamId: string }) {
  return getPolicy(state, { subjectId: params.teamId });
}
