// @flow
import * as React from "react";
import NotPermitted from "core/components/Empty/NotPermitted";
import MainContent from "core/components/MainContent";
import PageTitle from "core/components/PageTitle";
import PageTitleNavigationLink from "core/components/PageTitleNavigationLink";
import { projectEditPath } from "core/lib/routes";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  params: {
    projectId: string,
  },
  children: React.Node,
|};

export type StateProps = {|
  notPermitted: boolean,
  showRestrictionsTab: boolean,
  showMenu: boolean,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
};

function ProjectSettingsShell(props: Props) {
  if (props.notPermitted) {
    return (
      <MainContent>
        <NotPermitted />
      </MainContent>
    );
  }

  return (
    <MainContent className={style.main}>
      <PageTitle
        navigation={
          props.showMenu
            ? [
                <PageTitleNavigationLink
                  to={projectEditPath(props.params.projectId)}
                  onlyActiveOnIndex={true}
                  icon="edit"
                >
                  Details
                </PageTitleNavigationLink>,
                <React.Fragment>
                  {props.showRestrictionsTab && (
                    <PageTitleNavigationLink
                      to={projectEditPath(
                        props.params.projectId,
                        "restrictions"
                      )}
                      icon="lock-locked-alt"
                    >
                      Restrictions
                    </PageTitleNavigationLink>
                  )}
                </React.Fragment>,
              ]
            : undefined
        }
        title="Project Settings"
      />
      <div className={style.tab}>{props.children}</div>
    </MainContent>
  );
}

export default connector(ProjectSettingsShell);
