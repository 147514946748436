// @flow
import classnames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import RecentFiles from "abstract-di/components/RecentFiles";
import Avatar, {
  Placeholder as AvatarPlaceholder,
} from "core/components/Avatar";
import BranchMenu from "core/components/BranchMenu";
import BranchStatusBadges from "core/components/BranchStatusBadges";
import Button from "core/components/Button";
import Card from "core/components/Card";
import Flex from "core/components/Flex";
import Heading from "core/components/Heading";
import Icon from "core/components/Icon";
import Markdown from "core/components/Markdown";
import PlaceholderText from "core/components/PlaceholderText";
import ReviewPile from "core/components/ReviewPile";
import Time from "core/components/Time";
import { fromNow } from "core/lib/dates";
import { V3Link as Link } from "core/lib/router";
import * as Branch from "core/models/branch";
import { getBranchReview } from "core/selectors/reviews";
import { getUser } from "core/selectors/users";
import type {
  Branch as BranchType,
  Project,
  User,
  State,
  Dispatch,
  ReviewRequest,
  BranchReview,
} from "core/types";
import style from "./style.scss";

export function Placeholder({
  hasDescription,
  mobile,
  noDescription,
}: {|
  hasDescription?: boolean,
  mobile?: boolean,
  noDescription?: boolean,
|}) {
  const { current: randomlyShowDescription } = React.useRef(
    Math.random() < 0.3
  );

  const showDescription =
    !noDescription && (hasDescription || randomlyShowDescription);

  return (
    <Card innerClassName={style.placeholderInnerCard} mobile={mobile} list>
      <Flex>
        <AvatarPlaceholder className={style.avatar} size={32} />
        <div className={style.detailsAndReviews}>
          <Flex className={style.details} column>
            <PlaceholderText min={mobile ? 30 : 10} max={mobile ? 60 : 40} />
            {showDescription && (
              <PlaceholderText min={mobile ? 60 : 50} max={90} />
            )}
            <div>
              <PlaceholderText
                className={style.placeholderAuthor}
                size="s"
                min={mobile ? 25 : 10}
                max={mobile ? 40 : 20}
              />
              <PlaceholderText
                size="s"
                min={mobile ? 25 : 10}
                max={mobile ? 40 : 15}
              />
            </div>
          </Flex>
        </div>
      </Flex>
    </Card>
  );
}

// NOTE: If this is changed then the class names in style.scss also need
// updating
const MAX_NESTING_LEVEL = 8;
const MAX_MOBILE_NESTING_LEVEL = 3;

type OwnProps = {|
  branch: BranchType,
  project: Project,
  reviewRequests?: ReviewRequest[],
  className?: string,
  innerClassName?: string,
  level?: number,
  mobile?: boolean,
  wrappedList?: boolean,
  to: string,
|};

type StateProps = {|
  author: ?User,
  branchReview: ?BranchReview,
|};

type Props = {
  ...OwnProps,
  ...StateProps,
};

function branchDate(branch: BranchType) {
  const prefix =
    (Branch.isMaster(branch) && "Last updated ") ||
    (Branch.isArchived(branch) && "Archived ") ||
    (Branch.isMerged(branch) && "Merged ") ||
    "";

  return (
    <Time date={branch.updatedAt}>
      {prefix}
      {fromNow(branch.updatedAt)}
    </Time>
  );
}

function BranchListItem({
  author,
  branch,
  className,
  innerClassName,
  level = 0,
  mobile = false,
  reviewRequests,
  branchReview,
  project,
  wrappedList = false,
  to,
}: Props) {
  const clampedLevel = Math.min(
    level,
    mobile ? MAX_MOBILE_NESTING_LEVEL : MAX_NESTING_LEVEL
  );
  const authorName = author ? author.name : "Unknown";
  const isNested = clampedLevel > 1;
  const classes = classnames(
    style.card,
    style[`hierarchyLevel${clampedLevel}`],
    className,
    { [style.mobile]: mobile }
  );
  const innerClasses = classnames(style.branch, innerClassName);

  return (
    <BranchMenu projectId={project.id} branchId={branch.id}>
      {(showMenu, renderMenuTarget) => {
        return renderMenuTarget((ref, buttonProps) => {
          return (
            <Card
              to={to}
              mobile={mobile}
              component={Link}
              className={classes}
              innerClassName={innerClasses}
              onContextMenu={showMenu}
              list
              wrappedList={wrappedList}
              {...buttonProps}
            >
              {isNested && (
                <Icon type="child" className={style.hierarchyIndicator} />
              )}
              <Avatar
                userId={author ? author.id : undefined}
                className={style.avatar}
                size={32}
              />

              <div className={style.detailsAndReviews}>
                <div className={style.details}>
                  <Heading
                    level="3"
                    size="m"
                    title={Branch.formattedName(branch)}
                    className={style.titleBox}
                  >
                    <span className={style.titleText}>
                      {Branch.formattedName(branch)}
                    </span>
                  </Heading>

                  {!!branch.description && (
                    <div
                      className={style.description}
                      title={branch.description}
                    >
                      <Markdown cleanSummary text={branch.description} />
                    </div>
                  )}

                  <p className={style.meta}>
                    <span>{authorName}&nbsp;&middot;&nbsp;</span>
                    {branchDate(branch)}
                  </p>

                  <RecentFiles projectId={project.id} branch={branch} />
                </div>

                <div className={style.reviewsAndStatus}>
                  {reviewRequests && reviewRequests.length > 0 && (
                    <span className={style.reviews}>
                      <ReviewPile reviewRequests={reviewRequests} />
                    </span>
                  )}

                  <BranchStatusBadges
                    className={style.badge}
                    project={project}
                    branch={branch}
                    branchReviewStatus={
                      branchReview ? branchReview.status : undefined
                    }
                    shrinkAtTablet
                    readOnly
                  />
                </div>
              </div>

              {!mobile && (
                <Button
                  icon="overflow"
                  className={style.actions}
                  onClick={showMenu}
                  innerRef={ref}
                  nude
                />
              )}
            </Card>
          );
        });
      }}
    </BranchMenu>
  );
}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { project, branch } = props;
  const author = getUser(state, { userId: branch.userId });

  return {
    author,
    branchReview: getBranchReview(state, {
      projectId: project.id,
      branchId: branch.id,
    }),
  };
}

export default connect<Props, OwnProps, StateProps, _, State, Dispatch>(
  mapStateToProps
)(BranchListItem);
