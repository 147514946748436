// @flow
import classnames from "classnames";
import idx from "idx";
import * as React from "react";
import { PREVIEW_THEME_KEY } from "core/components/ButtonTogglePreviewBackground";
import { useCustomTheme } from "core/components/Theme/useCustomTheme";
import type { ThemeFill } from "core/lib/theme";
import style from "./style.scss";

type Props = {
  fixed?: boolean,
  className?: string,
  useDynamicPreviewBackground?: boolean,
  fill?: ThemeFill,
};

export default function Transparency({
  className = "",
  fixed = true,
  useDynamicPreviewBackground,
  fill = "pattern",
  ...rest
}: Props) {
  const { getCustomTheme } = useCustomTheme();
  const previewTheme = getCustomTheme && getCustomTheme(PREVIEW_THEME_KEY);

  const fillOption = idx(previewTheme, (theme) => theme.options.fill);
  const isPattern = !fillOption || fillOption === "pattern";

  return (
    <div
      className={classnames(style.transparency, className, {
        [style.fixed]: fixed,
        [style.forceDark]:
          useDynamicPreviewBackground &&
          previewTheme &&
          previewTheme.themeName === "dark",
        [style.forceLight]:
          useDynamicPreviewBackground &&
          previewTheme &&
          previewTheme.themeName === "light",
        [style.forceSolidDark]:
          useDynamicPreviewBackground &&
          previewTheme &&
          previewTheme.themeName === "dark" &&
          !isPattern,
        [style.forceSolidLight]:
          useDynamicPreviewBackground &&
          previewTheme &&
          previewTheme.themeName === "light" &&
          !isPattern,
      })}
      {...rest}
    />
  );
}
