// @flow
import classnames from "classnames";
import * as React from "react";
import tinycolor from "tinycolor2";
import style from "./style.scss";

type Props = {
  color?: string,
  absolute?: boolean,
  className?: string,
  horizontal?: boolean,
  placeholder?: boolean,
  selected?: boolean,
  squircle?: boolean,
};

export default function ProjectColor({
  className,
  color,
  absolute,
  horizontal,
  placeholder,
  selected,
  squircle,
}: Props) {
  const luminance = tinycolor(color).getLuminance() * 100;

  return (
    <div
      className={classnames(
        {
          [style.color]: !squircle,
          [style.absolute]: absolute,
          [style.horizontal]: !squircle && horizontal,
          [style.vertical]: !squircle && !horizontal,
          [style.squircle]: squircle,
          [style.light]: luminance > 95 && !placeholder,
          [style.dark]: luminance < 13 && !placeholder,
          [style.selected]: selected,
          [style.placeholder]: placeholder,
        },
        className
      )}
      style={{ background: color }}
    />
  );
}
