// @flow
import classnames from "classnames";
import { capitalize } from "lodash";
import Resizable from "re-resizable";
import * as React from "react";
import ErrorBoundary from "core/components/ErrorBoundary";
import connectStorage from "../../hocs/connectStorage";
import Button from "../Button";
import Scrollable from "../Scrollable";
import style from "./style.scss";

type Props = {
  children: React.Node,
  className?: string,
  contentClassName?: string,
  opaque?: boolean,
  direction?: "left" | "right",
  collapsed?: boolean,
  active?: boolean,
  wide?: boolean,
  dynamic?: boolean,
  mobile?: boolean,
  resizable?:
    | "add-items"
    | "advanced-commit"
    | "branch-tab-list"
    | "layers-left"
    | "layers-right"
    | "main-sidebar"
    | "overview-right",
  minWidth?: number | string,
  maxWidth?: number | string,
  contentBorder?: boolean,
  title?: string,
  defaultWidth?: string,
  onClose?: (SyntheticEvent<>) => *,
  onResizeStop?: *,
  doubleClickDelay?: number,
  disableScroll?: boolean,
};

type State = {
  collapseWidth: string,
};

// The default width set before being resized
function defaultWidth(props: Props) {
  return props.wide ? "320px" : "280px";
}

// The width configured if the sidebar has been resized
function customWidth(props: Props) {
  return props.defaultWidth ? props.defaultWidth : defaultWidth(props);
}

class Sidebar extends React.Component<Props, State> {
  doubleClickTimeout: ?TimeoutID;
  resizable: Resizable;

  static defaultProps = {
    direction: "left",
    maxWidth: 640,
    minWidth: 192,
    doubleClickDelay: 350,
  };

  constructor(props: Props) {
    super();

    this.state = {
      collapseWidth: customWidth(props),
    };
  }

  clearDoubleClickTimeout = () => {
    if (this.doubleClickTimeout) {
      clearTimeout(this.doubleClickTimeout);
      this.doubleClickTimeout = undefined;
    }
  };

  handleDoubleClick = () => {
    if (!this.doubleClickTimeout) {
      this.doubleClickTimeout = setTimeout(
        this.clearDoubleClickTimeout,
        this.props.doubleClickDelay
      );
    } else {
      this.resizable.updateSize({ width: defaultWidth(this.props) });
      this.clearDoubleClickTimeout();
    }
  };

  render() {
    const {
      collapsed,
      direction,
      resizable,
      mobile,
      dynamic,
      minWidth,
      maxWidth,
      onResizeStop,
    } = this.props;

    if (resizable && dynamic) {
      console.warn("A sidebar cannot be resizeable and dynamic.");
    }

    const Main = resizable && !mobile ? Resizable : "div";
    const resizableProps = resizable
      ? {
          minWidth,
          maxWidth,
          defaultSize: {
            width: customWidth(this.props),
            height: "100%",
          },
          onResizeStop: (event, direction, ref, delta) => {
            this.setState({ collapseWidth: `${ref.clientWidth}px` });

            if (onResizeStop) {
              onResizeStop(event, direction, ref, delta);
            }
          },
          onResizeStart: this.handleDoubleClick,
          enable: {
            top: false,
            right: direction === "left" && !collapsed,
            bottom: false,
            left: direction === "right" && !collapsed,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false,
          },
          ref: (resizable) => (this.resizable = resizable),
        }
      : undefined;

    const children = this.props.disableScroll ? (
      <ErrorBoundary>{this.props.children}</ErrorBoundary>
    ) : (
      <Scrollable flex>
        <ErrorBoundary>{this.props.children}</ErrorBoundary>
      </Scrollable>
    );

    return (
      <div
        className={classnames(
          this.props.className,
          style.sidebar,
          direction ? style[direction] : undefined,
          {
            [style.contentBorder]: this.props.contentBorder,
            [style.collapsed]: this.props.collapsed,
            [style.active]: this.props.active,
            [style.wide]: this.props.wide,
            [style.dynamic]: this.props.dynamic,
            [style.fixed]: !this.props.dynamic,
            [style.opaque]: this.props.opaque,
          }
        )}
      >
        <div className={style.backdrop} onClick={this.props.onClose} />
        <Main
          {...resizableProps}
          className={classnames(style.main, this.props.contentClassName)}
          style={
            this.props.collapsed && !this.props.dynamic
              ? {
                  // Collapsing the sidebars with margin allows for
                  // ZoomablePreview to respond to the layout change
                  [`margin${capitalize(
                    this.props.direction || "left"
                  )}`]: `-${this.state.collapseWidth}`,
                }
              : {}
          }
        >
          <div className={style.content}>
            {this.props.title && (
              <div className={style.header}>
                <div className={style.title}>{this.props.title}</div>
                {this.props.onClose && (
                  <Button
                    className={style.closeButton}
                    icon="close"
                    title="Close"
                    onClick={this.props.onClose}
                    nude
                    tint
                  />
                )}
              </div>
            )}
            {children}
          </div>
        </Main>
      </div>
    );
  }

  componentWillUnmount() {
    this.clearDoubleClickTimeout();
  }
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default connectStorage(Sidebar, (storage, props: Props) => {
  if (!props.resizable) {
    return;
  }

  const key = `Sidebar-${props.resizable}-defaultWidth`;
  const defaultWidthStorage = storage.getItem(key);

  return {
    defaultWidth: defaultWidthStorage
      ? defaultWidthStorage
      : props.defaultWidth,
    onResizeStop: (event, direction, ref, delta) => {
      storage.setItem(key, `${ref.clientWidth}px`);
    },
  };
});
