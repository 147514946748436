// @flow
import * as React from "react";
import CoreNoActivity from "core/components/Empty/NoActivity";
import { isMac } from "core/lib/platform";
import { downloadApp } from "web/routeHelpers";

const downloadUrl = isMac() ? downloadApp() : undefined;
const description =
  "Once your team starts doing work in the Abstract desktop app, the activity feed will display here.";

export default function NoActivity(props: *) {
  return (
    <CoreNoActivity
      description={description}
      downloadUrl={downloadUrl}
      {...props}
    />
  );
}
