// @flow
import empty from "empty";
import pluralize from "pluralize";
import * as React from "react";
import { connect } from "react-redux";
import { loadPages } from "abstract-di/actions";
import File from "abstract-di/components/File";
import { withData } from "core/components/DataLoader";
import NoFiles from "core/components/Empty/NoFiles";
import FileList from "core/components/FileList";
import Flex from "core/components/Flex";
import Loaded from "core/components/Loaded";
import Sidebar from "core/components/Sidebar";
import { Abstract } from "core/lib/abstract";
import createConnector from "core/lib/createConnector";
import * as Branch from "core/models/branch";
import { BranchChangesetFetchRequest } from "core/requests/changesets";
import { FilesFetchRequest } from "core/requests/files";
import { getBranchHead } from "core/selectors/branches";
import { getFileChangesForChangeset } from "core/selectors/changesets";
import {
  getChangedFilesForBranch,
  getUnchangedFilesForBranch,
  getDeletedFilesForBranch,
} from "core/selectors/files";
import { getPagesByFileForBranch } from "core/selectors/pages";
import type {
  State,
  Dispatch,
  File as TFile,
  Page,
  Collection,
  ChangesetChange,
  Branch as TBranch,
} from "core/types";
import Footer from "./Footer";
import style from "./style.scss";

type OwnProps = {|
  ...Abstract.BranchDescriptor,
  branch: TBranch,
  onSearchChange: (query: string) => void,
  onFilterChange: (filter?: string) => void,
  onClearFilters: () => void,
  collection: Collection,
  filter?: string,
  query: string,
  layerCount: number,
  hasMore: boolean,
  title: React.Node,
  isSubmitting: boolean,
  onSubmitSelection: (Collection) => void,
  onDiscard: () => void,
|};

type StateProps = {|
  changedFiles: TFile[],
  unchangedFiles: TFile[],
  deletedFiles: TFile[],
  isLoading: boolean,
  isMasterBranch: boolean,
  fileChanges: { [fileId: string]: ChangesetChange },
  pages: { [uniqueFileId: string]: Page[] },
|};

type DispatchProps = {|
  loadPages?: (fileId: string) => void,
  onLoad: () => void,
|};

type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type ComponentState = {
  currentFileId?: string,
  currentPageId?: string,
};

class AddItemsView extends React.Component<Props, ComponentState> {
  state = {
    currentFileId: undefined,
    currentPageId: undefined,
  };

  componentDidMount() {
    this.setCurrentFile();
  }

  componentDidUpdate() {
    this.setCurrentFile();
  }

  setCurrentFile() {
    if (!this.props.isLoading && !this.state.currentFileId) {
      const firstChangedFile = this.props.changedFiles[0];
      const firstUnchangedFile = this.props.unchangedFiles[0];
      const file = firstChangedFile || firstUnchangedFile;
      if (file) {
        this.setState({ currentFileId: file.id }, () => {
          if (firstChangedFile) {
            this.props.onFilterChange("changed");
          }
        });
      }
    }
  }

  handleFileChange = (fileId: string, pageId?: string, changed?: boolean) => {
    this.setState({ currentFileId: fileId, currentPageId: pageId }, () => {
      this.props.onFilterChange(changed ? "changed" : "");
    });
  };

  get submitText(): string {
    const { collection, layerCount, hasMore } = this.props;
    if (!collection) {
      return "Continue";
    }
    if (!layerCount) {
      return "Add Item";
    }
    const count = layerCount.toString() + (hasMore ? "+ " : " ");
    return `Add ${count}${pluralize("Item", layerCount)}`;
  }

  render() {
    const { collection } = this.props;

    const onSubmit = () => this.props.onSubmitSelection(collection);

    return (
      <Flex column>
        <Loaded loading={this.props.isLoading} className={style.page}>
          {this.props.changedFiles.length ||
          this.props.unchangedFiles.length ? (
            <div className={style.page}>
              <Sidebar resizable="add-items" className={style.sidebar}>
                <FileList
                  focused
                  projectId={this.props.projectId}
                  branchId={this.props.branch.id}
                  fileId={this.state.currentFileId}
                  pageId={this.state.currentPageId}
                  isMasterBranch={this.props.isMasterBranch}
                  fileChanges={this.props.fileChanges}
                  deletedFiles={this.props.deletedFiles}
                  changedFiles={this.props.changedFiles}
                  unchangedFiles={this.props.unchangedFiles}
                  pages={this.props.pages}
                  loadPages={this.props.loadPages}
                  onClickItem={this.handleFileChange}
                />
              </Sidebar>
              <File
                isSelecting
                onSearch={this.props.onSearchChange}
                onFilterChange={this.props.onFilterChange}
                onClearFilters={this.props.onClearFilters}
                query={this.props.query}
                filter={this.props.filter}
                params={{
                  projectId: this.props.projectId,
                  branchId: this.props.branch.id,
                  fileId: this.state.currentFileId,
                  pageId: this.state.currentPageId,
                  filter: this.props.filter,
                }}
              />
            </div>
          ) : (
            <NoFiles canAddFiles={false} />
          )}
        </Loaded>
        <Footer
          title={this.props.title}
          submitting={this.props.isSubmitting}
          disabled={!!collection && !this.props.layerCount}
          onSubmit={onSubmit}
          onDiscard={this.props.onDiscard}
          submitText={this.submitText}
          cancelText="Cancel"
        />
      </Flex>
    );
  }
}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { projectId, branch } = props;
  const branchId = branch.id;
  const head = getBranchHead(state, { projectId, branchId });

  const filesLoading = FilesFetchRequest.isLoadingStrict(state, {
    projectId,
    branchId,
    sha: head,
  });

  const changesetLoading = BranchChangesetFetchRequest.isLoadingStrict(state, {
    projectId,
    branchId,
    head,
  });

  const isMasterBranch = Branch.isMaster(branch);

  const isLoading = isMasterBranch
    ? filesLoading
    : filesLoading || changesetLoading;

  return {
    isLoading,
    isMasterBranch,
    pages: getPagesByFileForBranch(state, { projectId, branchId }),
    fileChanges: isMasterBranch
      ? empty.object
      : getFileChangesForChangeset(state, { projectId, branchId }),
    deletedFiles: getDeletedFilesForBranch(state, { projectId, branchId }),
    changedFiles: getChangedFilesForBranch(state, { projectId, branchId }),
    unchangedFiles: getUnchangedFilesForBranch(state, {
      projectId,
      branchId,
    }),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { projectId, branch } = props;
  const branchId = branch.id;

  return {
    loadPages: loadPages
      ? (fileId: string) => dispatch(loadPages(projectId, branchId, fileId))
      : undefined,
    onLoad: () => {
      dispatch(async (dispatch, getState) => {
        const head = getBranchHead(getState(), { projectId, branchId });

        await Promise.all([
          dispatch(
            FilesFetchRequest.perform({
              params: { projectId, branchId, sha: head },
              force: false,
            })
          ),
          !Branch.isMaster(branch) &&
            dispatch(
              BranchChangesetFetchRequest.perform({
                params: { projectId, branchId, head },
              })
            ),
        ]);
      });
    },
  };
}

export default createConnector<Props, OwnProps>(
  connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch>(
    mapStateToProps,
    mapDispatchToProps
  ),
  withData((props) => ({
    branch: props.branch,
    projectId: props.projectId,
  }))
)(AddItemsView);
