// @flow
import * as React from "react";
import DialogForm from "core/components/DialogForm";
import HorizontalSeparator from "core/components/HorizontalSeparator";
import Note from "core/components/Note";
import SeatManager from "core/components/SeatManager";
import type { Organization, Subscription } from "core/types";
import style from "../style.scss";

type Props = {
  userName: string,
  isUsernameOrganization?: boolean,
  isSubmitting: boolean,
  isOpen: boolean,
  onSubmit: (SyntheticEvent<>) => any,
  subscriptionRole: string,
  organization: Organization,
  subscription: ?Subscription,
  canManageUsers: boolean,
  onClose: () => void,
};

export default function ViewerConfirmationDialog(props: Props) {
  const { subscription } = props;
  const isTrial = subscription ? subscription.inTrial : false;
  const showSeatManagement = props.canManageUsers && !isTrial;

  return (
    <SeatManager
      action="remove"
      controlSeats={showSeatManagement}
      organizationId={props.organization.id}
      subscription={subscription}
      onSubmit={props.onSubmit}
      adjustContributors
    >
      {({
        hasError,
        isLoading,
        onSubmit,
        toggleOn,
        renderToggle,
        renderSummary,
      }) => (
        <DialogForm
          isOpen={props.isOpen}
          disabled={isLoading || hasError || props.isSubmitting}
          onClose={props.onClose}
          onSubmit={onSubmit}
          submitText="Downgrade User"
          title="Downgrade to Viewer"
          contentClassName={style.confirmationDialog}
        >
          {() => (
            <React.Fragment>
              <p>
                Do you want to downgrade <strong> {props.userName}</strong> from
                the role of{" "}
                <span className={style.capitalize}>
                  {props.subscriptionRole}
                </span>{" "}
                to Viewer?
              </p>
              <Note>
                <span>
                  A Viewer can only see and comment on what is happening in your
                  organization; they cannot make any changes.
                </span>
              </Note>
              {showSeatManagement && (
                <React.Fragment>
                  <HorizontalSeparator />
                  {renderToggle()}
                  {renderSummary()}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </DialogForm>
      )}
    </SeatManager>
  );
}
