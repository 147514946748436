// @flow
import { normalize, schema } from "normalizr";
import { branch, user } from "core/schema";

const branchUpdatedMessageSchema = new schema.Object({
  branch,
  user,
});

export function normalizeBranchUpdatedMessage(branchUpdatedMessage: *) {
  return normalize(branchUpdatedMessage, branchUpdatedMessageSchema);
}
