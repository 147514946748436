// @flow
import classnames from "classnames";
import * as React from "react";
import Card from "core/components/Card";
import ChangeStatusBadge from "core/components/ChangeStatusBadge";
import FileIcon from "core/components/FileIcon";
import Flex from "core/components/Flex";
import Icon from "core/components/Icon";
import {
  LAYER_DATA_TYPE_TO_ICON,
  LAYER_META_TYPE_TO_ICON,
} from "core/components/LayerIcon";
import * as Page from "core/models/page";
import type { ChangesetChange, NonVisualChangeObject, File } from "core/types";
import { FILE_TYPES, META_DATA_IDS } from "../nonVisualItems";
import style from "./style.scss";

export type Props = {
  file: File,
  change: ChangesetChange,
  object: NonVisualChangeObject,
  className?: string,
  label: React.Node,
  secondaryLabel?: React.Node,
  status: string,
  icon?: string,
  type?: string,
  fromLibrary?: boolean,
};

const CHANGE_TYPE_TO_ICON = {
  ...LAYER_DATA_TYPE_TO_ICON,
  ...LAYER_META_TYPE_TO_ICON,
  "layer-style": "file-layerstyle",
  "text-style": "file-textstyle",
  library: "library",
  file: LAYER_DATA_TYPE_TO_ICON.default,
  "shared-item": LAYER_DATA_TYPE_TO_ICON.default,
};

function getIconType(
  object: NonVisualChangeObject,
  change: ChangesetChange,
  file: File,
  type?: string
) {
  if (change.objectType === "page") {
    return Page.icon(object);
  }

  let iconKey = "default";
  if (type) {
    iconKey = type;
  } else if (object.type) {
    iconKey = object.type;
  } else if (change.objectType) {
    iconKey = change.objectType;
  }

  let iconType = CHANGE_TYPE_TO_ICON[iconKey] || CHANGE_TYPE_TO_ICON.default;
  if (iconType === "file-artboard" && file.type === "xd") {
    iconType = "file-artboard-xd";
  }

  return iconType;
}

function getIsFileChange(object: NonVisualChangeObject) {
  return FILE_TYPES.includes(object.type);
}

function getIsFileMetaDataChange(object: NonVisualChangeObject) {
  return META_DATA_IDS.includes(object.id);
}

function getIsLibraryPageChange(
  object: NonVisualChangeObject,
  change: ChangesetChange
) {
  return object.type === "library" && change.objectType === "page";
}

export default function NonVisualListItem({
  file,
  object,
  change,
  label,
  secondaryLabel,
  status,
  icon,
  type,
  className,
  fromLibrary,
}: Props) {
  const iconType = icon || getIconType(object, change, file, type);
  const isFile = getIsFileChange(object);
  const isFileMetaData = getIsFileMetaDataChange(object);
  const isLibraryPage = getIsLibraryPageChange(object, change);

  return (
    <Card list wrappedList className={classnames(className, style.item)}>
      {isFile || isFileMetaData || isLibraryPage ? (
        <FileIcon
          type={file.type}
          className={style.icon}
          isLibrary={isLibraryPage ? true : file.isLibrary}
        />
      ) : (
        <Icon type={iconType} className={style.icon} />
      )}
      <Flex column className={style.center}>
        <div>{label}</div>
        {secondaryLabel && (
          <div className={style.secondaryLabel}>{secondaryLabel}</div>
        )}
      </Flex>
      <ChangeStatusBadge
        status={status}
        fromLibrary={fromLibrary}
        className={style.badge}
      />
    </Card>
  );
}
