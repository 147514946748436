// @flow
import * as React from "react";
import Button, { type Props as ButtonProps } from "core/components/Button";
import Popover from "core/components/Popover";
import { push } from "core/lib/location";
import { collectionLocation } from "core/lib/routes";
import type { Branch } from "core/types";
import connector from "./connector";

export type OwnProps = {|
  projectId: string,
  branchId: string,
  fullwidth?: boolean,
  disabled?: boolean,
  display?: "inline" | "block",
  text?: string,
  title?: string,
  primary?: boolean,
  submitText?: string,
  button?: (props: ButtonProps) => React.Node,
|};

export type StateProps = {|
  branch: ?Branch,
  isOffline: boolean,
|};

export type DispatchProps = {|
  createCollection: ({ onSuccess: (collectionId: string) => void }) => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

const defaultProps = {
  text: "New Collection",
};

function CreateCollectionButton(props: Props) {
  function handleClick() {
    props.createCollection({
      onSuccess: (collectionId: string) => {
        push(collectionLocation(props.projectId, props.branchId, collectionId));
      },
    });
  }

  return (
    <Popover
      display={props.display}
      placement="top"
      trigger="hover"
      disabled={!props.isOffline}
      label="You must be online to create a collection."
    >
      {/*
        Children must be wrapped around a valid dom element in order
        for Popover to properly pass its ref down to calculate positioning.
      */}
      <div>
        {props.button ? (
          props.button({
            onClick: handleClick,
            disabled: props.disabled || props.isOffline,
            fullwidth: props.fullwidth,
          })
        ) : (
          <Button
            primary={props.primary}
            onClick={handleClick}
            disabled={props.disabled || props.isOffline}
            fullwidth={props.fullwidth}
            qaSelector="createCollectionButton"
          >
            {props.text}
          </Button>
        )}
      </div>
    </Popover>
  );
}

CreateCollectionButton.defaultProps = defaultProps;

export default connector(CreateCollectionButton);
