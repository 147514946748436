// @flow
import * as React from "react";
import { useDispatch } from "react-redux";
import Button from "core/components/Button";
import Notifications from "core/components/Notifications";
import { openSidebar } from "web/actions/sidebar";
import style from "./style.scss";

export default function MobileHeader() {
  const dispatch = useDispatch();

  const handleMenuClick = React.useCallback(() => {
    dispatch(openSidebar());
  }, [dispatch]);

  return (
    <header className={style.header} role="banner" aria-label="Abstract">
      <Button
        className={style.menuButton}
        icon="list"
        onClick={handleMenuClick}
        title="Menu"
        light
        nude
      />
      <Notifications className={style.notificationsButton} lightIcon />
    </header>
  );
}
