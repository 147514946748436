// @flow
import * as React from "react";
import Offline from "core/components/Empty/Offline";
import { isDesktop } from "core/lib/platform";

export default function OfflineCommit() {
  return (
    <Offline
      title="Commit unavailable offline"
      description={`${
        isDesktop
          ? "Abstract only stores commits you’ve previously worked on for offline use. "
          : ""
      }To view this commit, you need to reconnect to the internet.`}
      flex
    />
  );
}
