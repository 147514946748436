// @flow
import invariant from "invariant";
import pluralize from "pluralize";
import * as React from "react";
import { useDispatch } from "react-redux";
import { exportAllActiveProjects } from "abstract-di/actions";
import { dismissDialog } from "core/actions/dialogs";
import DialogForm from "core/components/DialogForm";
import FormNotice from "core/components/FormNotice";
import InputSwitch from "core/components/InputSwitch";
import type { Organization, Project } from "core/types";
import style from "./style.scss";

export type Props = {
  organization: Organization,
  projects: Project[],
};

function ConfirmExportAllActiveProjects(props: Props) {
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    includeBranches: false,
  });

  return (
    <DialogForm
      title={`Confirm export of ${props.projects.length} active ${pluralize(
        "project",
        props.projects.length
      )}`}
      primaryButton="Export all active projects"
      onSubmit={(event) => {
        event.preventDefault();
        invariant(exportAllActiveProjects, "Desktop app required to export");
        dispatch(
          exportAllActiveProjects(props.organization, props.projects, state)
        );
        dispatch(dismissDialog());
      }}
      onClose={() => {
        dispatch(dismissDialog());
      }}
      isOpen
    >
      <FormNotice
        body="Keep the Abstract app open during your bulk export. If you close the
        app, you will have to start over."
      />
      <br />
      <InputSwitch
        checked={state.includeBranches}
        label="Export files from all branches"
        name="includesBranches"
        onChange={() =>
          setState((state) => ({
            includeBranches: !state.includeBranches,
          }))
        }
        responsive
      />
      <p>
        Depending on the number and size of your organization's files, exporting
        in bulk can take a while. Make sure you have archived the projects you
        don't want to export.
      </p>
      <p>
        Exports will <b>not</b> include:
      </p>
      <ul className={style.list}>
        {!state.includeBranches && <li>Files from all branches</li>}
        <li>Commit history</li>
        <li>Collections</li>
        <li>Comments</li>
        <li>Archived projects</li>
      </ul>
    </DialogForm>
  );
}

export default ConfirmExportAllActiveProjects;
