// @flow
import empty from "empty";
import * as React from "react";
import ResizeDetector from "react-resize-detector";
import Activity from "core/components/Activity";
import Heading from "core/components/Heading";
import Loaded from "core/components/Loaded";
import { dateGroup, fromNow } from "core/lib/dates";
import type { Activity as TActivity, ActivityContext } from "core/types";
import connector from "./connector";
import style from "./style.scss";

type Props = {
  activities: TActivity[],
  activityContext: $ReadOnly<ActivityContext>,
  children?: any,
  className?: string,
  narrow?: boolean,
  isLoading?: boolean,
  qaSelector?: string,
};

function widthToBreakpoint(width: number) {
  if (width < 375) {
    return "xxsmall";
  }
  if (width < 414) {
    return "xsmall";
  }
  if (width < 639) {
    return "small";
  }
  if (width < 840) {
    return "medium";
  }
  return "large";
}

function ActivityList({
  activities,
  activityContext = empty.object,
  className,
  children,
  narrow,
  isLoading,
  qaSelector,
}: Props) {
  let lastDateGroup;
  let items = [];

  const [breakpoint, setBreakpoint] = React.useState("large");

  const handleResize = React.useCallback(
    (width) => {
      const newBreakpoint = widthToBreakpoint(width);

      if (breakpoint !== newBreakpoint) {
        setBreakpoint(newBreakpoint);
      }
    },
    [breakpoint]
  );

  activities.forEach((activity, index) => {
    if (!narrow) {
      const activityDateGroup = dateGroup(activity.createdAt);

      if (activityDateGroup !== lastDateGroup) {
        items.push(
          <Heading
            key={`${activityDateGroup}-${activity.id}`}
            level="3"
            size="s"
            className={style.dateHeader}
          >
            {!lastDateGroup && activityDateGroup === "Older"
              ? fromNow(activity.createdAt)
              : activityDateGroup}
          </Heading>
        );
        lastDateGroup = activityDateGroup;
      }
    }

    items.push(
      <Activity
        narrow={narrow}
        key={activity.id}
        activity={activity}
        context={activityContext}
        breakpoint={breakpoint}
        qaSelector={`activity-item-${index}`}
      />
    );
  });

  return (
    <div className={className}>
      {items}
      {children}
      {isLoading && <Loaded loading />}
      <ResizeDetector
        handleWidth={true}
        handleHeight={false}
        onResize={handleResize}
      />
    </div>
  );
}

export default connector(ActivityList);
