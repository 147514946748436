// @flow
import invariant from "invariant";
import { connect } from "react-redux";
import { display } from "abstract-di/actions";
import { dismissDialog } from "core/actions/dialogs";
import { getBranch } from "core/selectors/branches";
import type { State, Dispatch } from "core/types";
import type { Props, OwnProps, StateProps, DispatchProps } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  return {
    branch: getBranch(state, {
      projectId: props.projectId,
      branchId: props.branchId,
    }),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    dismissDialog: () => dispatch(dismissDialog()),
    changeDisplay: (value) => {
      invariant(display, "display required for dialog action");
      dispatch(display("filesReminder", value));
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
