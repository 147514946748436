// @flow
import * as React from "react";
import image from "./images/activity.svg";
import Empty from ".";

type Props = {
  slackV2: ?boolean,
};

export default function NoProjectChannels(props: Props) {
  const { slackV2, ...rest } = props;

  return (
    <Empty
      {...rest}
      image={image}
      title={slackV2 ? "Add default channels" : "Add a project channel"}
      description="Select which projects post to specific Slack channels."
    />
  );
}
