// @flow

export function reorder<T>(
  list: T[],
  {
    oldIndex,
    newIndex,
    key,
  }: { oldIndex: number, newIndex: number, key: string }
): T[] {
  if (newIndex === oldIndex) {
    return list;
  }

  let newList = list.slice();

  const target = newList[oldIndex];

  // remove the item from where it currently is
  newList.splice(oldIndex, 1);

  // insert the item into the new slot
  newList.splice(newIndex, 0, target);

  // re-assign every item's order based on its index in the new array
  return newList.map((item, index) => ({
    ...item,
    [key]: index + 1,
  }));
}
