// @flow
import * as Abstract from "abstract-sdk";
import * as React from "react";
import { useSelector } from "react-redux";
import Flex from "core/components/Flex";
import Head from "core/components/Head";
import MainContent from "core/components/MainContent";
import Media from "core/components/Media";
import CoreOrganizationProjects from "core/components/OrganizationProjects";
import { replace, addQuery, removeQuery } from "core/lib/location";
import {
  setProjectListViewType,
  getProjectListViewType,
} from "core/lib/preferences";
import * as Organization from "core/models/organization";
import { getOrganization } from "core/selectors/organizations";
import PromptAppDownload from "web/components/PromptAppDownload";
import style from "./style.scss";

type Props = {
  location: { query: { s?: string, newUser?: string } },
  params: Abstract.OrganizationDescriptor,
};

export default function OrganizationProjects(props: Props) {
  const { location, params, ...rest } = props;
  const { organizationId } = params;

  const [isGrid, setIsGrid] = React.useState(
    getProjectListViewType() === "grid"
  );

  const organization = useSelector((state) =>
    getOrganization(state, { organizationId })
  );

  const handleSearch = React.useCallback((query?: string) => {
    if (query) {
      replace(addQuery({ s: query }));
    } else {
      replace(removeQuery("s"));
    }
  }, []);

  const handleListChange = React.useCallback((type: string) => {
    setIsGrid(type === "grid");
    setProjectListViewType(type);
  }, []);

  return (
    <Media desktop>
      {(desktop) => (
        <MainContent className={style.contentMaxWidth}>
          <Flex column>
            <Head>
              <title>Projects</title>
            </Head>
            <CoreOrganizationProjects
              {...rest}
              newUser={!!location.query.newUser}
              organizationId={organizationId}
              query={location.query.s}
              card={isGrid && desktop}
              mobile={!desktop}
              onSearch={handleSearch}
              onListChange={handleListChange}
              emptyComponent={
                organization && Organization.hasVersionsEnabled(organization)
                  ? PromptAppDownload
                  : undefined
              }
            />
          </Flex>
        </MainContent>
      )}
    </Media>
  );
}
