// @flow
import classnames from "classnames";
import { guard, object, boolean, string, type Decoder } from "decoders";
import * as React from "react";
import { useDispatch } from "react-redux";
import Button from "core/components/Button";
import ButtonLink from "core/components/ButtonLink";
import ExternalLink from "core/components/ExternalLink";
import Flex from "core/components/Flex";
import Loaded from "core/components/Loaded";
import OnboardingContentSheet from "core/components/OnboardingContentSheet";
import OnboardingHeading from "core/components/OnboardingHeading";
import SerifText from "core/components/SerifText";
import onboardingStyle from "core/components/onboarding.scss";
import window from "core/global/window";
import type { OAuthApplication, ReactRouterLocation } from "core/types";
import { authorizeApplication, fetchApplicationData } from "web/api";
import OnboardingPage from "web/components/Onboarding/Page";
import { openSupportWindow } from "web/di/actions";
import style from "./style.scss";

const GENERIC_ERROR_MESSAGE = "An unexpected error occurred";
const TIMEOUT_ERROR_MESSAGE = "Sorry, sign in timed out";
const APP_NOT_FOUND_ERROR_MESSAGE = "We couldn’t find the requested app";

const oAuthApplicationDecoder: Decoder<$Shape<OAuthApplication>> = object({
  createdByUser: object({
    id: string,
    name: string,
    username: string,
  }),
  id: string,
  isAbstractPlugin: boolean,
  name: string,
});

const oAuthApplicationGuard = guard(oAuthApplicationDecoder);

type Props = {
  location: ReactRouterLocation,
};

export default function AuthorizeOAuthApplication(props: Props) {
  const {
    client_id,
    code_challenge,
    code_challenge_method,
    expires,
    redirect_uri,
    response_type,
    scope,
    state,
  } = props.location.query;

  const [isLoading, setIsLoading] = React.useState(true);
  const [applicationInfo, setApplicationInfo] =
    React.useState<OAuthApplication | null>(null);

  const [error, setError] = React.useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const expiresTimeout = React.useRef();

  const dispatch = useDispatch();

  React.useEffect(() => {
    const fetchOAuthApplication = async () => {
      if (client_id) {
        try {
          const response = await fetchApplicationData(client_id);

          const oAuthApplication = oAuthApplicationGuard(response.data);

          setApplicationInfo(oAuthApplication);
          setIsLoading(false);

          if (oAuthApplication.isAbstractPlugin && expires) {
            const expireAt = parseInt(expires, 10);

            if (!isNaN(expireAt)) {
              const expiresDelay = expireAt - Date.now();

              if (expiresDelay > 0) {
                expiresTimeout.current = window.setTimeout(() => {
                  setError(TIMEOUT_ERROR_MESSAGE);
                }, expiresDelay);
              } else {
                setError(TIMEOUT_ERROR_MESSAGE);
              }
            }
          }
        } catch (error) {
          setError(APP_NOT_FOUND_ERROR_MESSAGE);
          setIsLoading(false);
        }
      }
    };

    fetchOAuthApplication();
  }, [client_id, dispatch, expires]);

  React.useEffect(() => {
    return () => {
      window.clearTimeout(expiresTimeout.current);
    };
  }, []);

  const handleOpenSupport = React.useCallback(() => {
    dispatch(openSupportWindow());
  }, [dispatch]);

  const handleClick = React.useCallback(
    async (accessGranted: boolean) => {
      if (applicationInfo && redirect_uri && response_type && scope && state) {
        setIsSubmitting(true);

        const response = await authorizeApplication(
          applicationInfo.id,
          accessGranted,
          redirect_uri,
          response_type,
          scope,
          state,
          code_challenge,
          code_challenge_method
        );

        const redirectUrl = new URL(response.redirectUrl);

        if (applicationInfo.isAbstractPlugin) {
          redirectUrl.searchParams.set("app_name", applicationInfo.name);
        }

        window.location.replace(redirectUrl.toString());
      } else {
        setError(GENERIC_ERROR_MESSAGE);
      }
    },
    [
      applicationInfo,
      code_challenge,
      code_challenge_method,
      redirect_uri,
      response_type,
      scope,
      state,
    ]
  );

  return (
    <OnboardingPage>
      <OnboardingContentSheet className={onboardingStyle.mainContentSheet}>
        <Loaded className={style.loading} loading={isLoading} flex>
          <Flex align="center" className={onboardingStyle.centeredText} column>
            {error ? (
              <React.Fragment>
                <OnboardingHeading level="1">{error}</OnboardingHeading>
                <div
                  className={classnames(
                    style.subHeading,
                    onboardingStyle.largeCopy
                  )}
                >
                  {applicationInfo
                    ? `Try signing in again within ${applicationInfo.name}.`
                    : null}{" "}
                  If you keep seeing this message, please contact us — we’re
                  here to help.
                </div>
                <div className={style.buttons}>
                  <ButtonLink
                    className={onboardingStyle.button}
                    onClick={handleOpenSupport}
                  >
                    Contact support
                  </ButtonLink>
                </div>
              </React.Fragment>
            ) : applicationInfo ? (
              <React.Fragment>
                <OnboardingHeading level="1">
                  Authorize <SerifText>{applicationInfo.name}</SerifText>
                </OnboardingHeading>
                {applicationInfo.isAbstractPlugin ? (
                  <div
                    className={classnames(
                      style.subHeading,
                      onboardingStyle.largeCopy
                    )}
                  >
                    Authorization is required in order to access
                    {applicationInfo.name}.
                  </div>
                ) : (
                  <React.Fragment>
                    <div
                      className={classnames(
                        style.subHeading,
                        onboardingStyle.mediumCopy
                      )}
                    >
                      <strong>{applicationInfo.name}</strong> by{" "}
                      <strong>{applicationInfo.createdByUser.name}</strong>{" "}
                      would like to access data from your Abstract
                      organizations.
                    </div>
                    {redirect_uri ? (
                      <div className={style.redirectCopy}>
                        You’ll be redirected to{" "}
                        <ExternalLink href={redirect_uri}>
                          {redirect_uri}
                        </ExternalLink>{" "}
                        after authorizing.
                      </div>
                    ) : null}
                  </React.Fragment>
                )}
                <div className={style.buttons}>
                  <Button
                    className={onboardingStyle.button}
                    disabled={isSubmitting}
                    onClick={() => handleClick(true)}
                    fullwidth
                    large
                    primary
                  >
                    Authorize
                  </Button>
                  <ButtonLink
                    className={classnames(
                      style.cancelButton,
                      onboardingStyle.button
                    )}
                    disabled={isSubmitting}
                    onClick={() => handleClick(false)}
                  >
                    Cancel
                  </ButtonLink>
                </div>
              </React.Fragment>
            ) : null}
          </Flex>
        </Loaded>
      </OnboardingContentSheet>
    </OnboardingPage>
  );
}
