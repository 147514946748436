// @flow
import apiRequest from "abstract-di/api";
import { entitiesReceived } from "core/actions/entities";
import { sessionLoaded } from "core/actions/session";
import { normalizeAccountResponse } from "core/schemas/account";
import defineRequest from ".";

export type CreateAccountFormValues = {|
  acceptTos: boolean,
  captchaValue: ?string,
  email: string,
  invitationToken?: string,
  marketingConsent: boolean,
  name: string,
  password: string,
|};

export type MarketingDetails = {|
  marketoMunchkinId?: string,
  utmCampaign?: string,
  utmContent?: string,
  utmMedium?: string,
  utmSource?: string,
  utmTerm?: string,
|};

type AccountCreateParams = {|
  ...CreateAccountFormValues,
  ...MarketingDetails,
|};

export const AccountCreateRequest = defineRequest<AccountCreateParams, {}>({
  id() {
    return "post:account";
  },
  perform(params) {
    return apiRequest("post", "account", params);
  },
  onSuccess(response, params, dispatch) {
    const { entities } = normalizeAccountResponse(response);
    dispatch(entitiesReceived(entities));
  },
});

export const CompletedAbstractWorkflowTutorialRequest = defineRequest<{}, {}>({
  id() {
    return "completed-abstract-workflow";
  },
  perform: () => {
    return apiRequest("put", "account", {
      hasSeenSecondDesignerTutorial: true,
    });
  },
  onSuccess(response, params, dispatch) {
    const { session } = response;
    dispatch(sessionLoaded(session));

    const { entities } = normalizeAccountResponse(response);
    dispatch(entitiesReceived(entities));
  },
});
