// @flow
import * as React from "react";
import Button from "core/components/Button";
import ContextMenu from "core/components/ContextMenu";
import DialogForm from "core/components/DialogForm";
import Flex from "core/components/Flex";
import { LEAVE_ORGANIZATION, REMOVE_FROM_ORGANIZATION } from "core/constants";
import window from "core/global/window";
import { NON_ENTERPRISE_SEAT_CAP } from "core/lib/subscriptions";
import { helpScimProvisioning } from "core/lib/urls";
import type {
  Subscription,
  User,
  Membership as OrganizationMembership,
  Organization,
  Policy,
} from "core/types";
import connector from "./connector";
import ContributorConfirmationDialog from "./dialogs/ContributorConfirmationDialog";
import EditPermissionsModal from "./dialogs/EditPermissionsModal";
import RemoveConfirmationDialog from "./dialogs/RemoveConfirmationDialog/index";
import ViewerConfirmationDialog from "./dialogs/ViewerConfirmationDialog";

export type OwnProps = {|
  user: User,
  organizationId: string,
|};

export type StateProps = {|
  organization: ?Organization,
  isOffline: boolean,
  policy: Policy,
  membership: ?OrganizationMembership,
  canManageUsers: boolean,
  seatCapEnabled: boolean,
  subscription: ?Subscription,
  currentUserId: string,
  removeError: string | void,
|};

export type DispatchProps = {|
  onChangeRole: () => void,
  onUpdate: (membership: $Shape<OrganizationMembership>) => void,
  onRemove: (user: User) => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type State = {
  confirmed: boolean,
  showConfirm: boolean,
  showScimModal: boolean,
  isSubmitting: boolean,
  pendingSubscriptionRole: string,
  editingPermissions: boolean,
};

class OrganizationMemberMenu extends React.Component<Props, State> {
  state = {
    confirmed: false,
    showConfirm: false,
    showScimModal: false,
    isSubmitting: false,
    pendingSubscriptionRole: "",
    editingPermissions: false,
  };

  menu: ?ContextMenu;
  menuRef = (ref: ?ContextMenu) => (this.menu = ref);

  seatCapEnforced = () => {
    const { subscription, seatCapEnabled } = this.props;
    return (
      seatCapEnabled &&
      subscription &&
      subscription.type !== "enterprise" &&
      subscription.maxSeats === NON_ENTERPRISE_SEAT_CAP
    );
  };

  handleDeleteMembership = async (event: SyntheticEvent<>) => {
    const { user } = this.props;

    event.preventDefault();
    this.props.onRemove(user);
    this.setState({ showConfirm: false });
  };

  handleSubmit = async (params: $Shape<OrganizationMembership>) => {
    try {
      this.setState({ isSubmitting: true });
      await this.props.onUpdate(params);
    } catch (err) {
      window.alert("There was an error updating this membership.");
    }

    this.handleHideMenu();
    this.setState({ isSubmitting: false, pendingSubscriptionRole: "" });
  };

  handleChangeRole = (role: $PropertyType<OrganizationMembership, "role">) => {
    if (!this.props.membership || this.props.membership.role === role) {
      return;
    }

    this.handleSubmit({ role });
  };

  handleChangeSubscriptionRole = (event: SyntheticEvent<>) => {
    const subscriptionRole = this.state.pendingSubscriptionRole;
    if (
      !(subscriptionRole === "viewer" || subscriptionRole === "contributor") ||
      !this.props.membership ||
      this.props.membership.subscriptionRole === subscriptionRole
    ) {
      return;
    }

    this.handleSubmit({ subscriptionRole });
  };

  handleConfirm = ({ target }: SyntheticInputEvent<>) => {
    if (this.isMe()) {
      this.setState({
        confirmed: target.value === LEAVE_ORGANIZATION,
      });
    } else {
      this.setState({
        confirmed: target.value === REMOVE_FROM_ORGANIZATION,
      });
    }
  };

  handleHideMenu = () => this.menu && this.menu.close();

  handleCancel = () => {
    this.setState({
      showConfirm: false,
      showScimModal: false,
      pendingSubscriptionRole: "",
    });
  };

  handleShowRemoveConfirm = () => {
    if (!this.canShowRemoveUserDialog) {
      return;
    }
    if (this.props.user.isScimProvisioned) {
      this.setState({ showScimModal: true });
    } else {
      this.setState({ showConfirm: true });
    }
    this.handleHideMenu();
  };

  handleShowViewerConfirm = () => {
    if (this.isViewer) {
      return;
    }
    this.props.onChangeRole();
    this.setState({ pendingSubscriptionRole: "viewer" });
    this.handleHideMenu();
  };

  handleShowContributorConfirm = () => {
    if (this.isContributor) {
      return;
    }
    this.props.onChangeRole();
    this.setState({ pendingSubscriptionRole: "contributor" });
    this.handleHideMenu();
  };

  get isOwner(): boolean {
    if (!this.props.membership) {
      return false;
    }
    return this.props.membership.role === "owner";
  }

  get isMember(): boolean {
    if (!this.props.membership) {
      return false;
    }
    return this.props.membership.role === "member";
  }

  get isGuest(): boolean {
    if (!this.props.membership) {
      return false;
    }
    return this.props.membership.role === "guest";
  }

  get isViewer(): boolean {
    if (!this.props.membership) {
      return false;
    }
    return this.props.membership.subscriptionRole === "viewer";
  }

  get isContributor(): boolean {
    if (!this.props.membership) {
      return false;
    }
    return this.props.membership.subscriptionRole === "contributor";
  }

  get canShowRemoveUserDialog(): boolean {
    return this.props.policy.removeMember;
  }

  isMe = (): boolean => {
    return !!(
      this.props.membership &&
      this.props.membership.userId === this.props.currentUserId
    );
  };

  roleOptions() {
    const enabled =
      this.props.policy.updateMembership && !this.state.isSubmitting;

    return [
      {
        label: "Edit Product Permissions",
        enabled,
        click: () => this.setState({ editingPermissions: true }),
      },
    ];
  }

  renderMenu = () => {
    const { user, isOffline } = this.props;

    return (
      <ContextMenu
        id={user.id}
        ref={this.menuRef}
        menuItems={[
          ...this.roleOptions(),
          {
            label: `${this.isMe() ? "Leave This Organization" : "Remove User"}`,
            danger: true,
            enabled: this.canShowRemoveUserDialog,
            click: this.handleShowRemoveConfirm,
          },
        ]}
      >
        {(showMenu, ref) => (
          <Button
            aria-haspopup
            nude
            icon="overflow"
            disabled={isOffline}
            tooltip={isOffline}
            title={`Show organization membership menu${
              isOffline ? " (must be online)" : ""
            }`}
            innerRef={ref}
            onClick={showMenu}
            qaSelector="membership-menu-button"
          />
        )}
      </ContextMenu>
    );
  };

  render() {
    const {
      organization,
      user,
      canManageUsers,
      membership,
      subscription,
      currentUserId,
      removeError,
    } = this.props;

    const seatCapEnforced = this.seatCapEnforced();

    if (!membership || !organization) {
      return null;
    }

    return (
      <Flex grow={false}>
        {this.renderMenu()}
        <DialogForm
          isOpen={this.state.showScimModal}
          onClose={this.handleCancel}
          title="Cannot remove user"
          primaryButton=""
        >
          <p>
            This user was provisioned using SCIM. Please contact your IT admin
            to have them remove <strong>{user.name}</strong> from{" "}
            <strong>{organization.name}</strong>.
          </p>
          <p>
            <a
              href={helpScimProvisioning()}
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn about SCIM provisioning in the Help Center.
            </a>
          </p>
        </DialogForm>
        {subscription && user && this.state.editingPermissions && (
          <EditPermissionsModal
            user={user}
            organizationId={organization.id}
            subscription={subscription}
            open={true}
            close={() => this.setState({ editingPermissions: false })}
          />
        )}
        <RemoveConfirmationDialog
          user={user}
          currentUserId={currentUserId}
          organization={organization}
          isMe={this.isMe()}
          isOpen={this.state.showConfirm}
          isConfirmed={this.state.confirmed}
          isSubmitting={this.state.isSubmitting}
          onClose={this.handleCancel}
          handleConfirm={this.handleConfirm}
          onSubmit={this.handleDeleteMembership}
          subscriptionRole={membership.subscriptionRole}
          subscription={subscription}
          canManageUsers={canManageUsers}
          error={removeError}
        />
        <ContributorConfirmationDialog
          userName={user.name}
          organization={organization}
          isUsernameOrganization={organization.isUsernameOrganization}
          isSubmitting={this.state.isSubmitting}
          isOpen={this.state.pendingSubscriptionRole === "contributor"}
          onClose={this.handleCancel}
          onSubmit={this.handleChangeSubscriptionRole}
          subscriptionRole={membership.subscriptionRole}
          subscription={subscription}
          canManageUsers={canManageUsers}
          seatCapEnforced={seatCapEnforced}
        />
        <ViewerConfirmationDialog
          subscription={subscription}
          userName={user.name}
          organization={organization}
          isUsernameOrganization={organization.isUsernameOrganization}
          isSubmitting={this.state.isSubmitting}
          isOpen={this.state.pendingSubscriptionRole === "viewer"}
          onClose={this.handleCancel}
          onSubmit={this.handleChangeSubscriptionRole}
          subscriptionRole={membership.subscriptionRole}
          canManageUsers={canManageUsers}
        />
      </Flex>
    );
  }
}

export default connector(OrganizationMemberMenu);
