// @flow
import { schema, normalize } from "normalizr";
import { user, branch, branchRestriction, branchStatus } from "core/schema";
import { envelopeSchema } from "core/schemas/envelope";
import { projectMembershipSchema } from "core/schemas/projectMembership";

const branchesRestrictionsResponseSchema = new envelopeSchema(
  new schema.Object({
    branchRestrictions: branchRestriction,
    projectMemberships: [projectMembershipSchema],
  })
);

const branchesResponseSchema = new envelopeSchema(
  new schema.Object({
    branches: [branch],
    users: [user],
  })
);

export function normalizeBranchesResponse(response: *) {
  return normalize(response, branchesResponseSchema);
}

export function normalizeBranchRestrictionsResponse(response: *) {
  return normalize(response, branchesRestrictionsResponseSchema);
}

export function normalizeBranches(data: *) {
  return normalize(data, [branch]);
}

export function normalizeBranch(data: *) {
  return normalize(data, branch);
}

const branchStatusResponseSchema = new envelopeSchema(branchStatus);

export function normalizeBranchStatusResponse(response: *) {
  return normalize(response, branchStatusResponseSchema);
}
