// @flow
import classnames from "classnames";
import empty from "empty";
import * as React from "react";
import Button, { type ButtonElement } from "core/components/Button";
import Card from "core/components/Card";
import Facepile from "core/components/Facepile";
import Flex from "core/components/Flex";
import Heading from "core/components/Heading";
import Icon from "core/components/Icon";
import Markdown from "core/components/Markdown";
import ProgressPie from "core/components/ProgressPie";
import ProjectColor from "core/components/ProjectColor";
import ProjectMenu from "core/components/ProjectMenu/Wrapper";
import ProjectName from "core/components/ProjectName";
import ProjectStar from "core/components/ProjectStar";
import Spinner from "core/components/Spinner";
import Time from "core/components/Time";
import { fromNow } from "core/lib/dates";
import { isWeb, isQA } from "core/lib/platform";
import { V3Link as Link } from "core/lib/router";
import { projectPath } from "core/lib/routes";
import { readableFilesize } from "core/lib/textFormatting";
import { projectUrl } from "core/lib/urls";
import type { Project as TProject, Section } from "core/types";
import connector from "./connector";
import style from "./style.scss";

const DEFAULT_COLOR = "#B8BDBF";

export type OwnProps = {|
  project: TProject,
  card?: boolean,
  mobile?: boolean,
  wrappedList?: boolean,
  query?: string,
  showSection?: boolean,
  disableContextMenu?: boolean,
  className?: string,
  onProjectClick?: (projectId: string) => void,
  onStartSyncingProject?: (projectId: string) => void,
  qaSelector?: string,
|};

export type StateProps = {|
  key?: string,
  project: TProject,
  section: ?Section,
  isCloning: boolean,
  isUnsynced: boolean,
  isSyncing: boolean,
  percentDownloaded: number,
  canMoveProject: boolean,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
};

export class ProjectListItem extends React.Component<Props> {
  static defaultProps = {
    isStarred: false,
    isCloning: false,
    isUnsynced: false,
    isSyncing: false,
    canMoveProject: false,
    percentDownloaded: 0,
    section: null,
  };

  handleStartSyncingProject = (event: SyntheticEvent<>) => {
    event.preventDefault();
    if (this.props.onStartSyncingProject) {
      this.props.onStartSyncingProject(this.props.project.id);
    }
  };

  renderCard = (
    showMenu?: (event: SyntheticEvent<>) => void,
    renderMenuTarget?: (
      target: (ref: React.Ref<ButtonElement>, buttonProps: {}) => React.Node
    ) => React.Node
  ) => {
    const {
      project,
      section,
      card,
      wrappedList,
      mobile,
      query,
      isCloning,
      isSyncing,
      isUnsynced,
      showSection,
      percentDownloaded,
      className,
      onProjectClick,
      onStartSyncingProject,
      qaSelector,
    } = this.props;

    const users = project.activeUsers || empty.array;
    const updatedAt =
      project.pushedAt || project.updatedAt || project.createdAt;
    const color = isUnsynced ? DEFAULT_COLOR : project.color;
    const list = !card || mobile;
    const fileSize = project.sizeInBytes || 0;
    const isNotDownloaded = !isWeb && (isUnsynced || isCloning);

    const classes = classnames(
      style.project,
      {
        [style.card]: card,
        [style.list]: list,
        [style.mobile]: mobile,
        [style.notDownloaded]: isNotDownloaded,
      },
      className
    );

    let meta = <span />;
    if (isCloning) {
      const message = readableFilesize(fileSize, {
        percent: percentDownloaded / 100,
      });
      meta = (
        <span className={style.progressWrap} title={message}>
          <span className={style.progressMessage}>{message}</span>
          <ProgressPie
            percentage={Math.max(5, percentDownloaded)}
            width={16}
            height={16}
          />
        </span>
      );
    } else if (isSyncing) {
      meta = (
        <span className={style.spinnerWrap}>
          <Spinner className={style.spinner} small /> Syncing…
        </span>
      );
    } else if (isUnsynced && onStartSyncingProject) {
      meta = (
        <Flex
          align={list ? "center" : "flex-end"}
          justify={list ? "flex-end" : undefined}
        >
          <Button
            onClick={this.handleStartSyncingProject}
            className={style.syncButton}
          >
            Sync · {readableFilesize(fileSize, { max: 4 })}
          </Button>
        </Flex>
      );
    } else if (users.length) {
      meta = (
        <Facepile
          users={users}
          className={style.facepile}
          countColor={project.color}
          max={6}
          size={24}
        />
      );
    }

    return (
      <Card
        list={!card}
        mobile={mobile}
        component={Link}
        to={projectPath(project.id)}
        wrappedList={wrappedList}
        className={classes}
        innerClassName={style.box}
        dynamic
        onClick={
          onProjectClick
            ? (event) => {
                onProjectClick(this.props.project.id);
              }
            : undefined
        }
        onContextMenu={isWeb || isQA ? undefined : showMenu}
        qaSelector={qaSelector}
      >
        {!list && <ProjectColor absolute horizontal color={color} />}
        <div className={style.wrap}>
          {list && <ProjectColor absolute color={color} />}
          <div className={style.nameAndDescription}>
            {showSection && section && (
              <div className={style.sectionName}>{section.name}</div>
            )}
            <Heading level="4" size={card ? "l" : "m"} className={style.name}>
              <ProjectName project={project} block={card} highlight={query} />
            </Heading>

            <Flex align="center" className={style.date}>
              {!isNotDownloaded && (
                <span className={style.updatedAt}>Updated</span>
              )}
              <Time date={updatedAt}>{fromNow(updatedAt)}</Time>
              {isNotDownloaded && (
                <React.Fragment>
                  <span className={style.dot}> · </span>
                  <a
                    href={projectUrl(project.id)}
                    className={style.webLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(event) => event.stopPropagation()}
                  >
                    <span className={style.webLinkText}>Open on Web</span>
                    <Icon type="external" />
                  </a>
                </React.Fragment>
              )}
            </Flex>

            {!mobile && project.about && (
              <Markdown
                cleanSummary
                highlight={query}
                text={project.about}
                className={style.description}
              />
            )}
          </div>
          <Flex
            shrink={false}
            align={list ? "center" : "stretch"}
            className={style.meta}
          >
            {meta}
            <Flex align="center" justify="flex-end" className={style.actions}>
              {!project.archivedAt && (
                <ProjectStar className={style.star} projectId={project.id} />
              )}
              {!mobile &&
                renderMenuTarget &&
                renderMenuTarget((ref, buttonProps) => {
                  return (
                    <Button
                      icon="overflow"
                      onClick={showMenu}
                      innerRef={ref}
                      qaSelector="projectCardOverflowButton"
                      nude
                      {...buttonProps}
                    />
                  );
                })}
            </Flex>
          </Flex>
        </div>
      </Card>
    );
  };

  render() {
    const { project, canMoveProject, disableContextMenu } = this.props;

    return !disableContextMenu ? (
      <ProjectMenu
        disableMenu={disableContextMenu}
        project={project}
        canMoveProject={canMoveProject}
      >
        {(showMenu, renderMenuTarget) =>
          this.renderCard(showMenu, renderMenuTarget)
        }
      </ProjectMenu>
    ) : (
      this.renderCard()
    );
  }
}

export default connector(ProjectListItem);
