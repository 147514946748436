// @flow
import * as React from "react";
import Button from "core/components/Button";
import ButtonGroup from "core/components/ButtonGroup";
import ContextMenu from "core/components/ContextMenu";
import style from "./style.scss";

type Props = {
  disabled?: boolean,
  primaryButton: React.Node,
  fileIsWriteable: ?boolean,
  onEditOnNewBranch?: (ev: SyntheticEvent<>) => void,
  onOpenUntrack: (ev: SyntheticEvent<>) => void,
};

/**
  This component renders primary and secondary CTA, which allows to open or edit a file.
  Primary CTA will be received as 'primaryButton' prop. 
  Secondary CTA will be rendered in a context menu.
  When the primary action is to edit a file, the secondary action will be to open a file untracked.
  When the primary action is to open an untracked file, the secondary action will be to edit a file on a new branch.
*/
export default function OpenFileMenu(props: Props) {
  const menuItems = [
    {
      label: "Open Untracked",
      click: props.onOpenUntrack,
      visible: !!(props.onOpenUntrack && props.fileIsWriteable),
    },
    {
      label: "Edit on New Branch…",
      click: props.onEditOnNewBranch,
      visible: !!(props.onEditOnNewBranch && !props.fileIsWriteable),
    },
  ];

  return (
    <ButtonGroup>
      {props.primaryButton}
      <ContextMenu id="open-file-menu" menuItems={menuItems}>
        {(showMenu, ref) => (
          <Button
            disabled={props.disabled}
            icon="chevron-default-down"
            innerRef={ref}
            onClick={showMenu}
            className={style.buttonDisclosure}
          />
        )}
      </ContextMenu>
    </ButtonGroup>
  );
}
