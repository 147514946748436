// @flow
import * as Abstract from "abstract-sdk";
import queryString from "query-string";
import apiRequest from "abstract-di/api";
import { trackEvent } from "core/actions/analytics";
import { entitiesReceived, entitiesDeleted } from "core/actions/entities";
import { clearProjectMemberships } from "core/actions/projectMemberships";
import {
  updatedProject,
  loadedProjectsResponse,
  updatedProjectEvent,
} from "core/actions/projects";
import { showToast } from "core/actions/toasts";
import { ForbiddenError } from "core/api";
import abstract from "core/lib/abstract";
import { push } from "core/lib/location";
import { projectPath } from "core/lib/routes";
import {
  normalizeDeprecatedProjectResponse,
  normalizeProjectResponse,
} from "core/schemas/project";
import type { Project } from "core/types";
import defineRequest from ".";

export const ProjectCreateRequest = defineRequest<$Shape<Project>, {}>({
  id() {
    return "post:projects";
  },
  perform(params) {
    return apiRequest("post", "projects", params, 22);
  },
  onSuccess(response, params, dispatch) {
    const { id, organizationId, type, color } = response.data;
    const { entities } = normalizeDeprecatedProjectResponse(response);

    dispatch(entitiesReceived(entities));
    dispatch(
      trackEvent("PROJECT_CREATED", { groupId: organizationId, type, color })
    );

    return push(projectPath(id));
  },
});

export const ProjectFetchRequest = defineRequest<
  Abstract.ProjectDescriptor,
  Abstract.ProjectDescriptor,
>({
  id(descriptor) {
    return `get:projects/${descriptor.projectId}`;
  },
  perform(descriptor) {
    return abstract.projects.info(descriptor);
  },
  onSuccess(project, descriptor, dispatch) {
    const response = abstract.unwrap(project);
    const { entities } = normalizeProjectResponse(response);
    dispatch(entitiesReceived(entities));
  },
  onError(error, descriptor, dispatch) {
    // if we get a 403 forbidden then we no longer have access to this project
    // we match against class and message here to account for differences
    // between the DI'd API libraries
    if (error instanceof ForbiddenError || error.message.match(/forbidden/i)) {
      dispatch(entitiesDeleted({ projects: [descriptor.projectId] }));
      dispatch(clearProjectMemberships(descriptor.projectId));
    }
  },
  force: false,
});

type FetchParams = {
  organizationId?: string,
  filter?: string,
  search?: string,
  type?: "cloud",
};

export const ProjectsFetchRequest = defineRequest<FetchParams, FetchParams>({
  id: (params) => {
    return `get:projects?${queryString.stringify(params)}`;
  },
  perform: (params) => {
    return apiRequest("get", `projects`, params, 9);
  },
  onSuccess: (response, params, dispatch) => {
    dispatch(loadedProjectsResponse(response));
  },
  force: false,
});

export type UpdateParams = {
  project: $Shape<Project>,
};

export const ProjectUpdateRequest = defineRequest<UpdateParams, UpdateParams>({
  id: (params) => {
    return `put:projects/${params.project.id}`;
  },
  perform: (params) => {
    return apiRequest(
      "put",
      `projects/${params.project.id}`,
      params.project,
      14
    );
  },
  onSuccess: (response, params, dispatch) => {
    dispatch(updatedProject(response));
    dispatch(updatedProjectEvent());
  },
});

export const ProjectDeleteRequest = defineRequest<UpdateParams, UpdateParams>({
  id: (params) => {
    const { project } = params;
    return `delete:projects/${project.id}`;
  },
  perform: (params) => {
    const { project } = params;
    return apiRequest("delete", `projects/${project.id}`);
  },
  onSuccess: (response, params, dispatch, getState) => {
    const { project } = params;
    dispatch(
      entitiesDeleted({
        projects: [project.id],
      })
    );
    dispatch(showToast({ text: `Deleted ${project.name}` }));
    dispatch(
      trackEvent("PROJECT_DELETED", {
        projectId: project.id,
        type: project.type,
      })
    );
  },
});

export const ProjectArchiveRequest = defineRequest<UpdateParams, UpdateParams>({
  id: (params) => {
    const { project } = params;
    return `put:projects/${project.id}/archive`;
  },
  perform: (params) => {
    const { project } = params;
    return apiRequest("put", `projects/${project.id}/archive`, project);
  },
  onSuccess: (response, params, dispatch) => {
    const { project } = params;
    dispatch(updatedProject(response));
    dispatch(showToast({ text: `Archived ${project.name}` }));
    dispatch(
      trackEvent("PROJECT_ARCHIVED", {
        projectId: project.id,
        type: project.type,
      })
    );
  },
});

export const ProjectUnarchiveRequest = defineRequest<
  UpdateParams,
  UpdateParams,
>({
  id: (params) => {
    const { project } = params;
    return `put:projects/${project.id}/unarchive`;
  },
  perform: (params) => {
    const { project } = params;
    return apiRequest("put", `projects/${project.id}/unarchive`, project);
  },
  onSuccess: (response, params, dispatch) => {
    const { project } = params;
    dispatch(updatedProject(response));
    dispatch(showToast({ text: `Unarchived ${project.name}` }));
    dispatch(
      trackEvent("PROJECT_UNARCHIVED", {
        projectId: project.id,
        type: project.type,
      })
    );
  },
});

type TransferIdParams = {
  projectId: string,
};

type TransferPerformParams = TransferIdParams & {
  newOrgId: string,
};

export const TransferProjectRequest = defineRequest<
  TransferPerformParams,
  TransferIdParams,
>({
  id: (params) => `post:projects/${params.projectId}/transfer`,
  perform: (params) =>
    apiRequest("post", `projects/${params.projectId}/transfer`, {
      organizationId: params.newOrgId,
    }),
  onSuccess: (response, params, dispatch) => {
    dispatch(clearProjectMemberships(params.projectId));
    dispatch(entitiesReceived(normalizeProjectResponse(response).entities));
  },
});
