// @flow
import * as React from "react";
import Icon, { type Props as IconProps } from "core/components/Icon";
import type { LayerData, Layer } from "core/types";

type IconPropsWithoutType = $Exact<$Diff<IconProps, { type: string }>>;

type Props = {
  ...IconPropsWithoutType,
  layer: Layer | LayerData,
  large?: boolean,
  fileType?: string,
};

export const LAYER_DATA_TYPE_TO_ICON = {
  artboard: "file-artboard",
  layer: "file-artboard",
  symbolMaster: "file-symbol",
  symbolInstance: "file-symbol",
  group: "file-folder",
  text: "file-text",
  bitmap: "file-raster",
  shapeGroup: "file-shape",
  shapePath: "file-path",
  rectangle: "file-shape",
  oval: "file-shape",
  polygon: "file-shape",
  triangle: "file-shape",
  star: "file-shape",
  page: "file-artboard",
  slice: "file-slice",
  hotspot: "file-prototype",
  repeatGrid: "file-repeat-grid",
  default: "file-unknown",
};

export const LAYER_META_TYPE_TO_ICON = {
  page: "file-artboard",
  artboard: "file-artboard",
  layer: "file-artboard",
  symbol: "file-symbol",
  text: "file-text",
  shape: "file-shape",
  group: "file-folder",
  "library-symbol": "file-library-symbol",
  default: "file-unknown",
};

export function layerDataIconType(layerData: LayerData) {
  return (
    LAYER_DATA_TYPE_TO_ICON[layerData.type] || LAYER_DATA_TYPE_TO_ICON.default
  );
}

export function layerMetaIconType(layer: Layer) {
  const type =
    layer.type === "symbol" && !!layer.libraryId
      ? "library-symbol"
      : layer.type;
  return LAYER_META_TYPE_TO_ICON[type] || LAYER_META_TYPE_TO_ICON.default;
}

export default function LayerIcon({
  layer,
  large,
  fileType = "sketch",
  ...iconProps
}: Props) {
  let type = layer.properties
    ? layerDataIconType(layer)
    : layerMetaIconType(layer);
  if (type === "file-artboard" && fileType === "xd") {
    type += "-xd";
  }
  return <Icon {...iconProps} type={type} large={large} />;
}
