// @flow
import * as React from "react";
import { V3Link as Link } from "core/lib/router";
import style from "./callout.scss";

export default function Callout({
  children,
  to,
}: {
  children?: any,
  to?: string,
}) {
  const callout = <strong className={style.callout}>{children}</strong>;

  if (to) {
    return (
      <Link to={to} className={style.linkStyle}>
        {callout}
      </Link>
    );
  }
  return callout;
}
