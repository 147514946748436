// @flow
import * as Abstract from "abstract-sdk";
import { MultiError } from "abstract-sdk/dist/errors";
import ExtendableError from "es6-error";
import every from "lodash/every";
import some from "lodash/some";
import type { Request, ValidationErrors } from "core/types";

export const DEFAULT: Request = {
  state: "loading",
  error: null,
  updatedAt: null,
};

export class RequestError extends ExtendableError {}
export class UnauthorizedError extends RequestError {}
export class ForbiddenError extends RequestError {}
export class NotFoundError extends RequestError {}
export class UnexpectedError extends RequestError {}

export class ValidationError extends RequestError {
  validationErrors: ValidationErrors;

  constructor(validationErrors: ValidationErrors = {}, message: string = "") {
    super(message);
    this.validationErrors = validationErrors;
  }
}

export function isLoading(...requests: Request[]): boolean {
  return some(requests, { state: "loading" });
}

/**
 * Returns whether the request has actually taken place. We default the request
 * to a loading state so that components can depend on `isLoading` to return
 * true before the request has actually been made in `componentDidMount`. But
 * sometimes, for example for forms, this default doesn't work. Since this is
 * the exceptional case, we handle it here.
 */
export function isLoadingStrict(request: Request): boolean {
  return request.state === "loading" && !!request.updatedAt;
}

/**
 * isFirstLoading and isFirstLoadingStrict:
 * Used to determine if this is the first time a request has been made.
 * Helpful when wanting to avoid unnecessary loading states if the data
 * already exists with the store
 *
 * @usage BranchOverviewSidebar
 */
export function isFirstLoadingStrict(request: Request): boolean {
  if (request.completed) {
    return false;
  }
  return isLoadingStrict(request);
}

export function promise(request: Request): void | Promise<mixed> {
  return request.promise;
}

export function isFirstLoading(...requests: Request[]): boolean {
  if (every(requests, { completed: true })) {
    return false;
  }
  return isLoading(...requests);
}

export function hasError(...requests: Request[]): boolean {
  return some(requests, { state: "error" });
}

export function success(...requests: Request[]): boolean {
  return every(requests, { state: "success" });
}

export function unauthorized(...requests: Request[]): boolean {
  return some(requests, (request) => {
    const { error } = request;

    if (error instanceof MultiError) {
      const err = error.errors.api || error.errors.cli;

      return err instanceof Abstract.UnauthorizedError;
    }

    return (
      error instanceof UnauthorizedError ||
      error instanceof Abstract.UnauthorizedError
    );
  });
}

export function forbidden(...requests: Request[]): boolean {
  return some(requests, (request) => {
    const { error } = request;

    if (error instanceof MultiError) {
      const err = error.errors.api || error.errors.cli;

      return err instanceof Abstract.ForbiddenError;
    }

    return (
      error instanceof ForbiddenError ||
      error instanceof Abstract.ForbiddenError
    );
  });
}

export function notFound(...requests: Request[]): boolean {
  return some(requests, (request) => {
    const { error } = request;

    if (error instanceof MultiError) {
      const err = error.errors.api || error.errors.cli;
      return err instanceof Abstract.NotFoundError;
    }

    return (
      error instanceof NotFoundError || error instanceof Abstract.NotFoundError
    );
  });
}

export function unexpectedError(...requests: Request[]): boolean {
  return some(requests, (request) => {
    const { error } = request;

    if (error instanceof MultiError) {
      const err = error.errors.api || error.errors.cli;

      return (
        err instanceof Abstract.InternalServerError ||
        err instanceof Abstract.ServiceUnavailableError
      );
    }

    return (
      error instanceof UnexpectedError ||
      error instanceof Abstract.InternalServerError ||
      error instanceof Abstract.ServiceUnavailableError
    );
  });
}

export function validationErrors(request: Request): ValidationErrors {
  return request.error instanceof ValidationError
    ? request.error.validationErrors
    : {};
}
