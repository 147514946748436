// @flow
import { request } from "core/actions/requests";
import { updateAccountPassword, updatePassword } from "web/api";
import type { FormValues as ResetFormValues } from "web/components/Onboarding/ResetPassword";
import type { FormValues } from "web/components/ProfileSettings/PasswordEdit";
import { SessionTokenRefreshRequest } from "web/requests/sessions";
import { getCurrentUser } from "web/selectors/session";
import { getToken } from "web/token";
import { formReset } from "./forms";
import type { ThunkAction } from ".";

export function passwordFormSubmit(
  id: string,
  values: FormValues
): ThunkAction {
  return async (dispatch, getState) => {
    dispatch(
      request(
        id,
        () =>
          updateAccountPassword(
            values.currentPassword,
            values.newPassword,
            values.newPasswordConfirmation
          ),
        {
          onSuccess: () => {
            const user = getCurrentUser(getState());
            if (user) {
              dispatch(
                SessionTokenRefreshRequest.perform({
                  params: {
                    username: user.username,
                    password: values.newPassword,
                    expiredToken: getToken(),
                  },
                })
              );
            }
            dispatch(formReset(id));
          },
        }
      )
    );
  };
}

export function resetPasswordFormSubmit(
  formId: string,
  token: string,
  values: ResetFormValues
): ThunkAction {
  return async (dispatch) => {
    dispatch(
      request(
        formId,
        () =>
          updatePassword(token, values.password, values.passwordConfirmation),
        { onSuccess: () => dispatch(formReset(formId)) }
      )
    );
  };
}
