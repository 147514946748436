// @flow
import classnames from "classnames";
import * as React from "react";
import { Waypoint } from "react-waypoint";
import Centered from "core/components/Centered";
import Loading from "core/components/Loading";
import OrganizationTeamMemberCard from "core/components/OrganizationTeamMemberCard";
import VirtualizedList from "core/components/VirtualizedList";
import type { User, OrganizationTeamDescriptor } from "core/types";
import style from "./style.scss";

type OwnProps = {
  params: OrganizationTeamDescriptor,
  users: User[],
  isGrid: boolean,
  isMobile: boolean,
  organizationName: ?string,
  hasNextPage: boolean,
  onLoadNextPage?: () => void,
  isLoadingNextPage: boolean,
};

type Props = {
  ...OwnProps,
};

export default function VirtualizedTeamMemberList(props: Props) {
  const { params, isGrid, users, hasNextPage, onLoadNextPage, isMobile } =
    props;
  const teamMemberListRef = React.useRef(null);
  const verticalPadding = isMobile || isGrid ? 0 : 24;
  const listHeight = isMobile ? 90 : 72;
  const isCard = isGrid && !isMobile;

  const renderInnerListElement = ({
    children,
    className,
    style: innerElementStyle,
    ...rest
  }: {
    children: React.Node,
    className?: string,
    style: { height: number, minHeight: number },
  }) => {
    return (
      <div
        className={classnames(className, {
          [style.innerListElement]: !isGrid && !isMobile,
        })}
        style={{
          ...innerElementStyle,
          height: innerElementStyle.height + verticalPadding * 2,
        }}
        {...rest}
      >
        {children}
        {props.onLoadNextPage ? (
          <div className={style.waypoint}>
            <Waypoint topOffset="1440px" onEnter={onLoadNextPage} />
          </div>
        ) : null}
      </div>
    );
  };

  const userItem = (user) => (
    <OrganizationTeamMemberCard
      user={user}
      isCard={isCard}
      isMobile={isMobile}
      params={params}
    />
  );

  const rows = isMobile ? [] : [{ height: 24, children: <div /> }];

  users.forEach((user, index) =>
    rows.push({
      key: user.id,
      gridItem: isCard,
      height: isCard ? ({ columnWidth }) => columnWidth - 16 : listHeight,
      children: userItem(user),
      className: classnames({
        [style.listItem]: !isCard,
        [style.firstListRow]: index === 0,
        [style.lastListRow]: index === users.length - 1,
      }),
    })
  );

  if (hasNextPage) {
    rows.push({
      height: 48,
      children: (
        <Centered>
          <Loading small qaSelector="loading-spinner-small" />
        </Centered>
      ),
    });
  }

  if (isCard) {
    rows.push({ height: 24, children: <div /> });
  }

  return (
    <div className={style.listWrapper}>
      <VirtualizedList
        items={rows}
        innerElementType={renderInnerListElement}
        ref={teamMemberListRef}
        resizeProps={{
          isGrid,
          users,
        }}
      />
    </div>
  );
}
