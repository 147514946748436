// @flow
import { VisuallyHidden } from "@reach/visually-hidden";
import classnames from "classnames";
import * as React from "react";
import Centered from "core/components/Centered";
import style from "./style.scss";

function generatePlaceholderImage(
  width?: number,
  height?: number,
  color: string = "rgba(0,0,0,0.1)"
) {
  const svg = `
  <svg xmlns="http://www.w3.org/2000/svg" width="${width || "100%"}" height="${
    height || "100%"
  }">
    <rect x="0" y="0" width="${width || "100%"}" height="${
      height || "100%"
    }" style="fill:${color}" />
  </svg>
  `;
  return `data:image/svg+xml;base64,${btoa(svg)}`;
}

function placeholderStyle(props: Object): Object {
  const { width, height } = props;
  const src = generatePlaceholderImage(width, height);

  return {
    backgroundImage: `url('${src}')`,
    maxWidth: width,
    maxHeight: height,
  };
}

function previewStyle(props: Object): Object {
  const { src, width, height, errorText } = props;

  if (errorText) {
    return { opacity: 1 };
  }

  return {
    backgroundImage: `url('${src}')`,
    maxWidth: width,
    maxHeight: height,
  };
}

type Props = {
  className?: string,
  height?: number,
  width?: number,
  cover?: boolean,
  src?: string,
  imageClassName?: string,
  alt?: string,
  annotationComponent?: React.Node,
  errorText?: ?string,
};

type State = {
  isLoading: boolean,
  fadeIn: boolean,
  previousSrc?: string,
};

export default class PreviewImage extends React.Component<Props, State> {
  image: ?HTMLImageElement;
  createdAt: number;

  constructor(props: Props) {
    super(props);
    this.createdAt = new Date().getTime();

    this.state = {
      isLoading: !this.props.src,
      fadeIn: false,
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.src !== this.props.src) {
      // tracking previous src allows us to reduce jank by rendering the previous
      // preview until the new one has loaded into the img tag
      this.setState({ isLoading: true, previousSrc: this.props.src });
    }
  }

  get smallerThanContainer(): boolean {
    return (
      !!this.image &&
      !!this.props.width &&
      !!this.props.height &&
      this.image.naturalWidth < this.props.width &&
      this.image.naturalHeight < this.props.height
    );
  }

  handleLoad = () => {
    if (this.state.isLoading) {
      const now = new Date().getTime();
      const msToLoad = now - this.createdAt;

      this.setState({
        previousSrc: undefined,
        isLoading: false,
        fadeIn: msToLoad > 500,
      });
    }
  };

  setImageRef = (ref: *) => {
    this.image = ref;
  };

  render() {
    const {
      className,
      height,
      cover,
      src,
      width,
      imageClassName,
      alt,
      annotationComponent,
      errorText,
      ...rest
    } = this.props;

    const placeholderClasses = classnames(style.placeholder, {
      [style.invisible]: !this.state.isLoading,
    });

    const previewClasses = classnames(style.preview, imageClassName, {
      [style.noAnimation]: !this.state.fadeIn,
      [style.visible]: !this.state.isLoading,
      [style.cover]: cover,
      [style.smallerThanContainer]: cover && this.smallerThanContainer,
    });

    return (
      <div {...rest} className={classnames(style.wrapper, className)}>
        {!src && !errorText && (
          <div
            className={placeholderClasses}
            style={placeholderStyle(this.props)}
          />
        )}

        {this.state.previousSrc && (
          <div className={previewClasses} style={previewStyle(this.props)}>
            <img src={this.state.previousSrc} alt={alt} />
          </div>
        )}
        <div className={previewClasses} style={previewStyle(this.props)}>
          {src && !errorText && (
            <VisuallyHidden>
              <img
                src={src}
                alt={alt}
                ref={this.setImageRef}
                onLoad={this.handleLoad}
              />
            </VisuallyHidden>
          )}
          {errorText && (
            <Centered className={style.errorText}>{errorText}</Centered>
          )}
          {annotationComponent}
        </div>
      </div>
    );
  }
}
