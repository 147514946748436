// @flow
import { static as Immutable } from "seamless-immutable";
import type { Action } from "../actions";
import type { Invitation } from "../types";

type State = { isAccepting: boolean, isLoading: boolean, entity: Invitation };
const DEFAULT_STATE = Immutable.from({
  isAccepting: false,
  isLoading: true,
  entity: {},
});

export default function (state: State = DEFAULT_STATE, action: Action): State {
  switch (action.type) {
    case "INVITATION_ACCEPTING":
      return Immutable.merge(state, { isAccepting: true });
    case "INVITATION_ACCEPTED":
      return Immutable.merge(state, { isAccepting: false });
    case "INVITATION_LOADED":
      return Immutable.merge(state, {
        isLoading: false,
        entity: action.payload.invitation,
      });
    case "INVITATION_NOT_FOUND":
      return Immutable.merge(state, { isLoading: false, entity: {} });
    case "INVITATION_LOADING":
    case "INVITATION_RESET":
      return DEFAULT_STATE;
    default:
      return state;
  }
}
