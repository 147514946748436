// @flow
export const SKETCH_BUNDLE_ID = "com.bohemiancoding.sketch3";

export function appName(bundleId: string): ?string {
  if (isSketch(bundleId)) {
    return "Sketch";
  }
  return null;
}

export function downloadLink(bundleId: string): ?string {
  if (isSketch(bundleId)) {
    return "https://www.sketch.com/downloads/mac/";
  }
  if (isIllustrator(bundleId)) {
    return "https://www.adobe.com/products/illustrator.html";
  }
  return null;
}

function isSketch(bundleId: string): boolean {
  return bundleId.includes(SKETCH_BUNDLE_ID);
}

function isIllustrator(bundleId: string): boolean {
  return bundleId.includes("com.adobe.illustrator");
}
