// @flow
import * as React from "react";
import { helpWebhooksUrl } from "core/lib/urls";
import image from "./images/webhooks.svg";
import Empty from ".";

type Props = {
  failed?: boolean,
};

export default function NoWebhookDeliveries({ failed }: Props) {
  return (
    <Empty
      description={
        <React.Fragment>
          No {failed ? "failed " : ""}deliveries have been triggered by this
          webhook's configured events.
          <br />
          <a href={helpWebhooksUrl()} rel="noopener noreferrer" target="_blank">
            Learn more about webhooks…
          </a>
        </React.Fragment>
      }
      image={image}
      title={`No ${failed ? "failed " : ""}webhook deliveries`}
    />
  );
}
