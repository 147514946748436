// @flow
import classnames from "classnames";
import * as React from "react";
import Flex from "core/components/Flex";
import style from "./style.scss";

type Props = {
  className?: string,
  children?: React.Node,
  component?: "div" | "span" | typeof Flex,
};

export default function Note({ component, className, ...rest }: Props) {
  const NoteComponent = component || "p";

  return (
    <NoteComponent className={classnames(style.note, className)} {...rest} />
  );
}
