// @flow
import { memoize } from "lodash";
import * as React from "react";
import { createPaginationDataLoaderComponent } from "core/components/PaginationDataLoader";
import { PaginatedBranchesFetchRequest } from "core/requests/branches";
import { getPaginatedNestedBranches } from "core/selectors/branches";
import type { BranchFilter, BranchHierarchy } from "core/types";

const PAGE_SIZE = 30;

type BranchesFetchParams = {
  projectId: string,
  userId?: string,
  search?: string,
  filter?: BranchFilter,
};

const DataLoader = createPaginationDataLoaderComponent<
  BranchesFetchParams,
  BranchHierarchy[],
>(
  "ProjectBranches",
  PaginatedBranchesFetchRequest,
  (response) => response.length,
  getPaginatedNestedBranches,
  PAGE_SIZE
);

const getBranchesPaginationParams = memoize(
  function (props): BranchesFetchParams {
    return {
      projectId: props.projectId,
      search: props.search ? props.search : undefined,
      // TODO: Actually use "mine" once API accepts it properly
      filter: props.filter && props.filter !== "mine" ? props.filter : "active",
    };
  },
  function (props) {
    return [props.projectId, props.search, props.filter].join("-");
  }
);

const getBranchesPaginationSelectorParams = memoize(
  function (props): BranchesFetchParams {
    return {
      projectId: props.projectId,
      userId: props.userId ? props.userId : undefined,
      search: props.search ? props.search : undefined,
      filter: props.filter ? props.filter : "active",
    };
  },
  function (props) {
    return [props.projectId, props.userId, props.search, props.filter].join(
      "-"
    );
  }
);

type RenderParams = {
  items: BranchHierarchy[],
  hasNextPage: boolean,
  isLoadingNextPage: boolean,
  onLoadNextPage?: () => void,
};

type Props = {|
  children: (params: RenderParams) => React.Node,
  projectId: string,
  userId?: string,
  search?: string,
  filter?: BranchFilter,
|};

export default function BranchesDataLoader(props: Props) {
  return (
    <DataLoader
      params={getBranchesPaginationParams(props)}
      selectorParams={getBranchesPaginationSelectorParams(props)}
    >
      {props.children}
    </DataLoader>
  );
}
