// @flow
import classnames from "classnames";
import * as React from "react";
import style from "./style.scss";

type Props = {
  children: React.Node,
  className?: string,
  mobile?: boolean,
  card?: boolean,
  flat?: boolean,
};

export default function List({
  children,
  mobile,
  className,
  card,
  flat,
}: Props) {
  return (
    <div
      className={classnames(style.wrapper, className, {
        [style.list]: !card || mobile,
        [style.mobile]: mobile,
        [style.flat]: flat,
      })}
    >
      {children}
    </div>
  );
}
