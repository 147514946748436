// @flow
import { schema, normalize } from "normalizr";
import { user, localBranch } from "core/schema";
import { envelopeSchema } from "core/schemas/envelope";

const branchesResponseSchema = new envelopeSchema(
  new schema.Object({
    branches: [localBranch],
    users: [user],
  })
);

export function normalizeLocalBranchesResponse(response: any) {
  return normalize(response, branchesResponseSchema);
}

export function normalizeLocalBranches(data: any) {
  return normalize(data, [localBranch]);
}

export function normalizeLocalBranch(data: any) {
  return normalize(data, localBranch);
}
