// @flow
import { static as Immutable } from "seamless-immutable";
import type { Action } from "../actions";
import { DEFAULT_VALUES, type FormValues } from "../models/form";

type State = { [formId: string]: FormValues };
const DEFAULT_STATE: State = Immutable.from({});

export default function (
  state: State = DEFAULT_STATE,
  action: Action = { type: "noop" }
): State {
  switch (action.type) {
    case "FORM_CHANGED": {
      const form = state[action.meta.id] || DEFAULT_VALUES;
      return Immutable.set(state, action.meta.id, form.merge(action.payload));
    }
    case "FORM_RESET": {
      return Immutable.without(state, action.meta.id);
    }
    default:
      return state;
  }
}
