// @flow
import type { State } from "core/types";

export function getOrganizationId(
  state: State,
  params: { organizationId: string }
) {
  return params.organizationId;
}

export function maybeGetOrganizationId(
  state: State,
  params: { organizationId?: string }
) {
  return params.organizationId;
}

export function getProjectId(state: State, params: { projectId: string }) {
  return params.projectId;
}

export function maybeGetProjectId(
  state: State,
  params: { projectId?: string }
) {
  return params.projectId;
}

export function getTeamId(state: State, params: $ReadOnly<{ teamId: string }>) {
  return params.teamId;
}

export function getBranchId(state: State, params: { branchId: string }) {
  return params.branchId;
}

export function getCollectionId(
  state: State,
  params: { collectionId: string }
) {
  return params.collectionId;
}

export function maybeGetBranchId(state: State, params: { branchId?: string }) {
  return params.branchId;
}

export function getUserId(state: State, params: { userId: string }) {
  return params.userId;
}

export function getSha(state: State, params: { sha: string }) {
  return params.sha;
}

export function getFileId(state: State, params: { fileId: string }) {
  return params.fileId;
}

export function maybeGetFileId(state: State, params: { fileId?: string }) {
  return params.fileId;
}

export function maybeGetResolvedFilter(
  state: State,
  params: { filterResolved?: boolean }
) {
  return params.filterResolved;
}

export function getPageId(state: State, params: { pageId: string }) {
  return params.pageId;
}

export function maybeGetPageId(state: State, params: { pageId?: string }) {
  return params.pageId;
}

export function getLayerId(state: State, params: { layerId: string }) {
  return params.layerId;
}

export function maybeGetLayerId(state: State, params: { layerId?: string }) {
  return params.layerId;
}

export function maybeGetUserId(state: State, params: { userId?: string }) {
  return params.userId;
}

export function getContentId(state: State, params: { contentId: string }) {
  return params.contentId;
}

export function getDesignVersionId(
  state: State,
  params: { designVersionId: string }
) {
  return params.designVersionId;
}

export function getObjectId(state: State, params: { objectId: string }) {
  return params.objectId;
}

export function getObjectType(state: State, params: { objectType: string }) {
  return params.objectType;
}
