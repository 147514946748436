// @flow
import React from "react";
import Button from "core/components/Button";
import Icon from "core/components/Icon";
import UserListItem from "core/components/UserListItem";
import type { User, Team } from "core/types";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  user: User,
  team: Team,
  onAddMember: (teamId: string, userId: string) => void,
  highlight?: string,
|};

export type StateProps = {|
  inTeam: boolean,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
};

function UserAddItem(props: Props) {
  const [isLoading, setIsLoading] = React.useState(false);

  const handleAddMember = async () => {
    setIsLoading(true);
    await props.onAddMember(props.team.id, props.user.id);
    setIsLoading(false);
  };

  return (
    <UserListItem
      component="div"
      id={props.user.id}
      name={props.user.name}
      username={props.user.username}
      avatarUrl={props.user.avatarUrl}
      className={style.user}
      responsive={false}
      highlight={props.highlight}
    >
      <span className={style.actions}>
        {props.inTeam ? (
          <Icon type="checkmark" tint />
        ) : (
          <Button onClick={handleAddMember} disabled={isLoading} primary>
            Add
          </Button>
        )}
      </span>
    </UserListItem>
  );
}

export default connector(UserAddItem);
