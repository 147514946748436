// @flow
import * as React from "react";
import DialogForm from "core/components/DialogForm";
import HorizontalSeparator from "core/components/HorizontalSeparator";
import SeatManager from "core/components/SeatManager";
import ValidationError from "core/components/ValidationError";
import type { User, Organization, Subscription } from "core/types";
import RemoveOther from "./RemoveOther";
import RemoveSelf from "./RemoveSelf";

type Props = {
  user: User,
  organization: Organization,
  isMe: boolean,
  isSubmitting: boolean,
  isConfirmed: boolean,
  isOpen: boolean,
  subscriptionRole: string,
  subscription: ?Subscription,
  canManageUsers: boolean,
  currentUserId: string,
  onClose: () => void,
  onSubmit: (SyntheticEvent<>) => any,
  handleConfirm: (SyntheticInputEvent<>) => any,
  error?: string,
};

export default function RemoveConfirmationDialog(props: Props) {
  const { subscription, isConfirmed, error } = props;
  const showSeatManagement =
    props.canManageUsers &&
    props.subscriptionRole === "contributor" &&
    !!subscription &&
    subscription.inTrial;

  const primaryButtonText = () => {
    if (props.isSubmitting && props.isMe) {
      return "Leaving this Organization...";
    }

    if (!props.isSubmitting && props.isMe) {
      return "Leave this Organization";
    }

    if (props.isSubmitting && !props.isMe) {
      return "Removing from Organization...";
    }

    if (!props.isSubmitting && !props.isMe) {
      return "Remove from Organization";
    }
  };

  return (
    <SeatManager
      action="remove"
      controlSeats={showSeatManagement}
      organizationId={props.organization.id}
      subscription={subscription}
      onSubmit={props.onSubmit}
      adjustContributors
    >
      {({
        isLoading,
        hasError,
        onSubmit,
        toggleOn,
        renderToggle,
        renderSummary,
      }) => (
        <DialogForm
          dangerous
          onSubmit={props.onSubmit}
          isOpen={props.isOpen}
          disabled={!isConfirmed || isLoading || hasError || props.isSubmitting}
          onClose={props.onClose}
          title={
            props.isMe ? "Leave this Organization" : "Remove from Organization"
          }
          primaryButton={primaryButtonText()}
          secondaryButton="Cancel"
          onSecondary={props.onClose}
        >
          {props.isMe && (
            <RemoveSelf
              user={props.user}
              organization={props.organization}
              subscriptionRole={props.subscriptionRole}
              subscription={props.subscription}
              handleConfirm={props.handleConfirm}
            />
          )}
          {!props.isMe && (
            <RemoveOther
              user={props.user}
              organization={props.organization}
              subscriptionRole={props.subscriptionRole}
              subscription={props.subscription}
              handleConfirm={props.handleConfirm}
            />
          )}
          {showSeatManagement && (
            <React.Fragment>
              <HorizontalSeparator />
              {renderToggle()}
              {renderSummary()}
            </React.Fragment>
          )}
          <ValidationError error={error} />
        </DialogForm>
      )}
    </SeatManager>
  );
}
