// @flow
import * as React from "react";
import NoBranchFiles from "core/components/Empty/NoBranchFiles";
import FileList from "core/components/FileList";
import Head from "core/components/Head";
import Sidebar from "core/components/Sidebar";
import { replace } from "core/lib/location";
import { filePath } from "core/lib/routes";
import type {
  File,
  Library,
  Page,
  FilePathQueryParams,
  ChangesetChange,
} from "core/types";
import BranchPage from "web/components/BranchShell/BranchPage";

type Props = {
  changedFiles: File[],
  unchangedFiles: File[],
  deletedFiles: File[],
  pages: { [uniqueFileId: string]: Page[] },
  libraries: Library[],
  fileCommentCounts: { [id: string]: number },
  fileChanges: { [fileId: string]: ChangesetChange },
  filePath: (
    changed: boolean
  ) => (fileId: string, pageId?: string, query?: FilePathQueryParams) => string,
  libraryPath: (fileId: string) => *,
  children?: any,
  loadPages: (string) => void,
  contentTypeId?: string,
  isEmpty: boolean,
  isMasterBranch: boolean,
  params: {
    projectId: string,
    branchId: string,
    fileId: string,
    pageId?: string,
  },
};

export default class BranchFilesDesktop extends React.Component<Props> {
  componentDidMount() {
    this.checkForFile(this.props);
  }

  componentDidUpdate() {
    this.checkForFile(this.props);
  }

  checkForFile = (props: Props) => {
    const length = props.unchangedFiles.length + props.changedFiles.length;
    if (!props.params.fileId && length > 0) {
      const fileId = props.changedFiles.length
        ? props.changedFiles[0].id
        : props.unchangedFiles[0].id;

      replace(filePath(props.params.projectId, props.params.branchId, fileId));
    }
  };

  render() {
    return (
      <BranchPage>
        <Head>
          <title>Files</title>
        </Head>
        {this.props.isEmpty ? (
          <NoBranchFiles />
        ) : (
          <React.Fragment>
            <Sidebar resizable="branch-tab-list">
              <FileList
                deletedFiles={this.props.deletedFiles}
                unchangedFiles={this.props.unchangedFiles}
                isMasterBranch={this.props.isMasterBranch}
                libraries={this.props.libraries}
                changedFiles={this.props.changedFiles}
                fileCommentCounts={this.props.fileCommentCounts}
                pages={this.props.pages}
                loadPages={this.props.loadPages}
                fileChanges={this.props.fileChanges}
                filePath={this.props.filePath}
                libraryPath={this.props.libraryPath}
                fileId={this.props.params.fileId || ""}
                pageId={this.props.params.pageId}
                projectId={this.props.params.projectId}
                branchId={this.props.params.branchId}
                contentTypeId={this.props.contentTypeId}
              />
            </Sidebar>
            {this.props.children}
          </React.Fragment>
        )}
      </BranchPage>
    );
  }
}
