// @flow
import * as Abstract from "abstract-sdk";
import { map } from "lodash";
import queryString from "query-string";
import apiRequest from "abstract-di/api";
import { trackEvent } from "core/actions/analytics";
import { entitiesDeleted, entitiesReceived } from "core/actions/entities";
import { entityMappingLoaded } from "core/actions/entityMappings";
import { setPaginationTotal } from "core/actions/paginationTotals";
import abstract from "core/lib/abstract";
import defineRequest from "core/requests";
import {
  normalizeCollectionResponse,
  normalizeCollectionLayer,
  normalizeCollections,
  normalizeCollectionLayers,
  normalizeCollectionLayersResponse,
} from "core/schemas/collection";
import { getEntityMapping } from "core/selectors/entityMappings";

const defaultLayersPerCollection = 1;

type CollectionsParams = {|
  offset?: number,
  limit?: number,
  layersPerCollection?: number,
  userId?: string,
  search?: string,
  branchStatus?: string,
  sortBy?: string,
  sortDir?: string,
|};

export type ProjectCollectionsParams = {|
  ...Abstract.ProjectDescriptor,
  ...CollectionsParams,
  branchId?: string,
|};

export type BranchCollectionsParams = {|
  ...Abstract.BranchDescriptor,
  ...CollectionsParams,
|};

export const ProjectCollectionsFetchRequest = defineRequest<
  ProjectCollectionsParams,
  ProjectCollectionsParams,
>({
  id({ limit, offset, sortBy, sortDir, ...params }) {
    return `project-collections-${queryString.stringify(params)}`;
  },
  perform(params, dispatch, getState) {
    const {
      sortBy,
      sortDir,
      offset,
      limit,
      branchId,
      userId,
      search,
      branchStatus,
      layersPerCollection,
      ...descriptor
    } = params;

    return abstract.collections.list(
      { ...descriptor, branchId },
      {
        sortBy,
        sortDir,
        offset,
        limit,
        userId,
        search,
        branchStatus,
        layersPerCollection: layersPerCollection || defaultLayersPerCollection,
        transportMode: ["api"],
      }
    );
  },
  onSuccess(collections, params, dispatch) {
    const response = abstract.unwrap(collections);
    const { entities } = normalizeCollections(response);
    dispatch(entitiesReceived(entities));

    const collectionIds = map(collections, "id");
    dispatch(setPaginationTotal(params.projectId, response.meta.total));
    dispatch(
      entityMappingLoaded(params.projectId, collectionIds, "collections")
    );
  },
});

export const BranchCollectionsFetchRequest = defineRequest<
  BranchCollectionsParams,
  BranchCollectionsParams,
>({
  id({ limit, offset, sortBy, sortDir, ...params }) {
    return `branch-collections-${queryString.stringify(params)}`;
  },
  perform(params, dispatch, getState) {
    const {
      sortBy,
      sortDir,
      offset,
      limit,
      userId,
      search,
      branchStatus,
      layersPerCollection,
      ...descriptor
    } = params;

    return abstract.collections.list(descriptor, {
      sortBy,
      sortDir,
      offset,
      limit,
      userId,
      search,
      branchStatus,
      layersPerCollection: layersPerCollection || defaultLayersPerCollection,
      transportMode: ["api"],
    });
  },
  onSuccess(collections, params, dispatch) {
    const response = abstract.unwrap(collections);
    const { entities } = normalizeCollections(response);

    dispatch(entitiesReceived(entities));
    dispatch(setPaginationTotal(params.branchId, response.meta.total));
  },
});

export const CollectionFetchRequest = defineRequest<
  Abstract.CollectionDescriptor,
  Abstract.CollectionDescriptor,
>({
  id({ collectionId }) {
    return `collection-${collectionId}`;
  },
  perform(descriptor) {
    return abstract.collections.info(descriptor, {
      transportMode: ["api"],
      _version: 24,
    });
  },
  onSuccess(collection, params, dispatch) {
    const response = abstract.unwrap(collection);
    const { entities } = normalizeCollections(response);
    dispatch(entitiesReceived(entities));
  },
});

type CollectionCreateParams = {
  projectId: string,
  published?: boolean,
};

export const CollectionCreateRequest = defineRequest<
  CollectionCreateParams,
  CollectionCreateParams,
>({
  id({ projectId }) {
    return `post:collection-create-${projectId}`;
  },
  perform({ projectId, ...attributes }) {
    return apiRequest("post", `projects/${projectId}/collections`, attributes);
  },
  onSuccess(response, params, dispatch) {
    const { projectId, published } = params;
    dispatch(trackEvent("COLLECTION_CREATED", { projectId }));

    if (published) {
      dispatch(trackEvent("COLLECTION_PUBLISHED", { projectId }));
    }

    const collection = response.data;
    const { entities } = normalizeCollectionResponse(response);
    dispatch(entitiesReceived(entities));
    dispatch(
      entityMappingLoaded(params.projectId, [collection.id], "collections")
    );
  },
});

type CollectionUpdateParams = {
  id: string,
  projectId: string,
};

export const CollectionUpdateRequest = defineRequest<
  CollectionUpdateParams,
  { id: string },
>({
  id({ id }) {
    return `put:collection-update-${id}`;
  },
  perform({ projectId, id, ...attributes }) {
    return apiRequest(
      "put",
      `projects/${projectId}/collections/${id}`,
      attributes
    );
  },
  onSuccess(response, params, dispatch) {
    const { projectId } = params;
    dispatch(trackEvent("COLLECTION_UPDATED", { projectId }));

    const { entities } = normalizeCollectionResponse(response);
    dispatch(entitiesReceived(entities));
  },
});

type CollectionDeleteParams = {
  id: string,
  projectId: string,
  branchId: string,
};

export const CollectionDeleteRequest = defineRequest<
  CollectionDeleteParams,
  CollectionDeleteParams,
>({
  id({ id }) {
    return `delete:collection-delete-${id}`;
  },
  perform({ id, projectId }) {
    return apiRequest("delete", `projects/${projectId}/collections/${id}`);
  },
  onSuccess(response, params, dispatch) {
    dispatch((dispatch, getState) => {
      const collectionIds = getEntityMapping(getState(), {
        type: "collections",
        entityId: params.projectId,
      });

      dispatch(
        entityMappingLoaded(
          params.projectId,
          collectionIds.filter((id) => id !== params.id),
          "collections",
          { replace: true }
        )
      );

      dispatch(entitiesDeleted({ collections: [params.id] }));
    });
  },
});

type CollectionLayerCreateParams = {
  projectId: string,
  collectionId: string,
};

export const CollectionLayerCreateRequest = defineRequest<
  CollectionLayerCreateParams,
  CollectionLayerCreateParams,
>({
  id({ collectionId }) {
    return `post:collection-layer-create-${collectionId}`;
  },
  perform({ projectId, ...attributes }) {
    return apiRequest(
      "post",
      `projects/${projectId}/collection_layers`,
      attributes
    );
  },
  onSuccess(response, params, dispatch) {
    const { projectId } = params;
    dispatch(trackEvent("COLLECTION_LAYER_CREATED", { projectId }));
    dispatch(entitiesReceived(normalizeCollectionLayer(response).entities));
  },
});

type CollectionLayerCreateMultipleParams = {
  projectId: string,
  branchId: string,
  collectionId: string,
  useLatestCommit?: boolean,
};

export const CollectionLayerCreateMultipleRequest = defineRequest<
  CollectionLayerCreateMultipleParams,
  CollectionLayerCreateMultipleParams,
>({
  id({ projectId, branchId, collectionId }) {
    return `post:collection-layers-create-${projectId}-${branchId}-${collectionId}`;
  },
  perform(attributes) {
    return apiRequest(
      "post",
      `projects/${attributes.projectId}/collection_layers/create_many`,
      attributes
    );
  },
  onSuccess(response, params, dispatch) {
    dispatch(
      trackEvent("COLLECTION_LAYERS_CREATED", { projectId: params.projectId })
    );
    dispatch(
      entitiesReceived(normalizeCollectionLayersResponse(response).entities)
    );
  },
});

type CollectionLayerUpdateParams = {
  id: string,
  projectId: string,
};

export const CollectionLayerUpdateRequest = defineRequest<
  CollectionLayerUpdateParams,
  CollectionLayerUpdateParams,
>({
  id({ id }) {
    return `put:collection-layer-update-${id}`;
  },
  perform({ id, projectId, ...attributes }) {
    return apiRequest(
      "put",
      `projects/${projectId}/collection_layers/${id}`,
      attributes
    );
  },
  onSuccess(response, params, dispatch) {
    const { projectId } = params;
    dispatch(trackEvent("COLLECTION_LAYER_UPDATED", { projectId }));
    dispatch(entitiesReceived(normalizeCollectionLayer(response).entities));
  },
});

type CollectionLayerMoveParams = {
  id: string,
  projectId: string,
  order: number,
};

export const CollectionLayerMoveRequest = defineRequest<
  CollectionLayerMoveParams,
  CollectionLayerMoveParams,
>({
  id({ id }) {
    return `post:collection-layer-move-${id}`;
  },
  perform({ id, projectId, order }) {
    return apiRequest(
      "post",
      `projects/${projectId}/collection_layers/${id}/move`,
      { order }
    );
  },
  onSuccess(response, params, dispatch) {
    dispatch(entitiesReceived(normalizeCollectionLayers(response).entities));
  },
});

type CollectionMoveParams = {
  collectionId: string,
  projectId: string,
  order: number,
};

export const CollectionMoveRequest = defineRequest<
  CollectionMoveParams,
  CollectionMoveParams,
>({
  id({ collectionId }) {
    return `post:collection-move-${collectionId}`;
  },
  perform({ collectionId, projectId, order }) {
    return apiRequest(
      "put",
      `projects/${projectId}/collections/${collectionId}/move`,
      {
        order,
      }
    );
  },
  onSuccess(response, params, dispatch) {
    // when this request succeeds, it doesn't send down any data with the
    // response. Instead, it will publish an ENTITIES_PATCHED socket event
    // which includes each collection in this branch's new orders. We will
    // update the orders there instead of here.
  },
});

type CollectionLayerDeleteParams = {
  id: string,
  projectId: string,
};

export const CollectionLayerDeleteRequest = defineRequest<
  CollectionLayerDeleteParams,
  CollectionLayerDeleteParams,
>({
  id({ id }) {
    return `delete:collection-layer-delete-${id}`;
  },
  perform({ projectId, id }) {
    return apiRequest(
      "delete",
      `projects/${projectId}/collection_layers/${id}`
    );
  },
  onSuccess(response, params, dispatch) {
    dispatch(entitiesDeleted({ collectionLayers: [params.id] }));
  },
});
