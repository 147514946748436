// @flow
import filter from "lodash/filter";
import values from "lodash/values";
import { createSelector } from "reselect";
import { getRawEntities, getEntity } from "core/selectors/entities";
import type { Email, State } from "core/types";
import { getCurrentUser } from "./session";

const getEmailEntities = (state: State) => getRawEntities(state, "emails");

export const getAllEmails: (State) => Email[] = createSelector(
  [getEmailEntities],
  (emails) => values(emails)
);

export function getEmail(state: State, id: string) {
  return getEntity(state, "emails", id);
}

export function getVerifiedEmails(state: State): Email[] {
  return filter(getEmailEntities(state), (email) => {
    return !!email.verifiedAt;
  });
}

export function getUnverifiedEmails(state: State): Email[] {
  return filter(getEmailEntities(state), (email) => {
    return !email.verifiedAt;
  });
}

export function isPrimaryEmail(state: State, emailId: string): boolean {
  const user = getCurrentUser(state);
  return !!user && user.primaryEmailId === emailId;
}
