// @flow
import type { State } from "core/types";

const EMPTY_ARRAY = [];
export function getEntityMapping(
  state: State,
  { type, entityId }: { type: string, entityId: string }
): string[] {
  if (!state.entityMappings[type]) {
    return EMPTY_ARRAY;
  }

  return state.entityMappings[type][entityId] || EMPTY_ARRAY;
}
