// @flow
import * as React from "react";
import { connect } from "react-redux";
import { V3Link as Link } from "core/lib/router";
import { branchPath } from "core/lib/routes";
import { getUser } from "core/selectors/users";
import type { Branch, ReviewRequest, User, State } from "core/types";
import Card from "../Card";
import ReviewPile from "../ReviewPile";
import style from "./style.scss";

type OwnProps = {|
  projectId: string,
  reviewRequests: ReviewRequest[],
  branch: Branch,
  mobile?: boolean,
|};

type StateProps = {|
  user: ?User,
|};

type Props = {
  ...OwnProps,
  ...StateProps,
};

function ReviewRequestListItem({
  projectId,
  branch,
  reviewRequests,
  user,
  ...rest
}: Props) {
  return (
    <Card
      component={Link}
      {...rest}
      to={branchPath(projectId, branch.id)}
      className={style.card}
      innerClassName={style.review}
      list
    >
      <div className={style.titleText}>{branch.name}</div>
      <p className={style.meta}>
        Review requested by {user ? user.name : "unknown"}
      </p>
      <ReviewPile reviewRequests={reviewRequests} />
    </Card>
  );
}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { branch } = props;
  const user = getUser(state, { userId: branch.userId });

  return { user };
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default connect(mapStateToProps)(ReviewRequestListItem);
