// @flow
import classnames from "classnames";
import empty from "empty";
import { find } from "lodash";
import * as React from "react";
import FileMenu from "core/components/FileMenu";
import FileName from "core/components/FileName";
import LibraryMenu from "core/components/LibraryMenu";
import PageMenu from "core/components/PageMenu";
import TreeView from "core/components/TreeView";
import window from "core/global/window";
import KeyCode from "core/lib/keycode";
import { modifierKeyPressed } from "core/lib/platform";
import * as Change from "core/models/change";
import * as File from "core/models/file";
import * as Page from "core/models/page";
import type {
  Page as TPage,
  File as TFile,
  Library,
  FilePathQueryParams,
  ChangesetChange,
} from "core/types";
import FileDependenciesTree from "./FileDependenciesTree";
import Label from "./Label";
import style from "./style.scss";

type Props = {
  files: TFile[],
  pages: { [uniqueFileId: string]: TPage[] },
  fileChanges: { [fileId: string]: ChangesetChange },
  fileIsOpening?: (file: TFile) => boolean,
  fileCommentCounts: { [id: string]: number },
  hasChildren: boolean,
  disabled?: boolean,
  warnings: { [fileId: string]: string },
  onOpenFile?: (event: Event, file: TFile, openUntracked: boolean) => void,
  onFileExpand?: (TFile) => void,
  onClickItem?: (
    fileId: string,
    pageId?: string,
    contentTypeId?: string
  ) => void,
  getPath?: (
    fileId: string,
    pageId?: string,
    query?: FilePathQueryParams
  ) => string | Object,
  selectedFileId?: string,
  selectedPageId?: string,
  selectedContentTypeId?: string,
  className?: string,
  selectedClassName?: string,
  disclosureClassName?: string,
  isFocused: boolean,
  isMobile?: boolean,
  dynamic?: boolean,
  projectId: string,
  branchId: string,
  libraries?: Library[],
  shouldShowLibraryMenu?: boolean,
};

export default class FileTree extends React.Component<Props> {
  static defaultProps = {
    warnings: {},
    pages: {},
    fileChanges: {},
    fileCommentCounts: {},
    hasChildren: true,
    isFocused: false,
  };

  treeRefs: { [fileId: string]: ?TreeView } = {};

  componentDidMount() {
    window.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown = (event: KeyboardEvent) => {
    const { isFocused, selectedFileId, files } = this.props;

    const selectedFile =
      selectedFileId && files.find((file) => file.id === selectedFileId);

    if (
      selectedFile &&
      isFocused &&
      modifierKeyPressed(event) &&
      event.keyCode === KeyCode.KEY_O &&
      this.props.onOpenFile
    ) {
      const openUntracked = event.shiftKey;
      this.props.onOpenFile(event, selectedFile, openUntracked);
    }
  };

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.selectedFileId &&
      this.props.selectedPageId &&
      (prevProps.selectedFileId !== this.props.selectedFileId ||
        prevProps.selectedPageId !== this.props.selectedPageId)
    ) {
      const fileTree = this.treeRefs[this.props.selectedFileId];
      if (fileTree) {
        fileTree.expand();
      }
    }
  }

  handleDoubleClick = (file: TFile) => (event: Event) => {
    if (this.props.onOpenFile && !this.fileIsOpening(file)) {
      this.props.onOpenFile(event, file, false);
    }
  };

  handleFileExpand = (file: TFile) => () => {
    if (this.props.onFileExpand) {
      this.props.onFileExpand(file);
    }
  };

  handleClickItem = (
    fileId: string,
    pageId?: string,
    contentTypeId?: string
  ) => {
    const { onClickItem } = this.props;
    return () => onClickItem && onClickItem(fileId, pageId, contentTypeId);
  };

  treeRef = (fileId: string) => (ref: ?TreeView) =>
    (this.treeRefs[fileId] = ref);

  filePath = (fileId: string, pageId?: string, contentTypeId?: string) => {
    if (!this.props.getPath) {
      return;
    }
    return this.props.getPath(fileId, pageId, { contentTypeId });
  };

  fileIsOpening = (file: TFile): boolean => {
    return this.props.fileIsOpening
      ? this.props.fileIsOpening(file)
      : !!file.opening;
  };

  get itemClassName(): string {
    return classnames({ [style.dynamic]: this.props.dynamic });
  }

  renderPages = (pages: TPage[], file: TFile) => {
    const fileSelected = this.props.selectedFileId === file.id;
    let libraryPageSelected = false;
    let libraries = [];
    let children = [];

    pages.forEach((page) => {
      const pageSelected =
        fileSelected && this.props.selectedPageId === page.id;

      if (Page.isLibrary(page)) {
        if (pageSelected) {
          libraryPageSelected = true;
        }
        return libraries.push(page);
      }

      const pageHasComments =
        !!this.props.fileCommentCounts[`${file.id}-${page.id}`];
      const pageContentType = File.contentTypes[page.id];

      children.push(
        <PageMenu
          projectId={this.props.projectId}
          branchId={this.props.branchId}
          file={file}
          page={page}
        >
          {(showMenu, renderMenuTarget) => {
            return renderMenuTarget((ref, buttonProps) => (
              <TreeView
                key={`${file.id}-${page.id}`}
                label={
                  <Label
                    name={page.name}
                    isLight={pageSelected && this.props.isFocused}
                    hasComments={pageHasComments}
                  />
                }
                icon={pageContentType ? pageContentType.icon : "file-page"}
                to={this.filePath(file.id, page.id)}
                onClick={this.handleClickItem(file.id, page.id)}
                isFocused={this.props.isFocused}
                selected={pageSelected}
                selectedClassName={this.props.selectedClassName}
                disclosureClassName={this.props.disclosureClassName}
                onContextMenu={showMenu}
                itemClassName={this.itemClassName}
                isMobile={this.props.isMobile}
                innerRef={ref}
                {...buttonProps}
              />
            ));
          }}
        </PageMenu>
      );
    });

    children.push(
      <FileDependenciesTree
        key={`${file.id}-dependencies`}
        file={file}
        filePath={this.filePath}
        fileSelected={fileSelected}
        fileCommentCounts={this.props.fileCommentCounts}
        libraryPageSelected={libraryPageSelected}
        libraries={libraries}
        itemClassName={this.itemClassName}
        selectedClassName={this.props.selectedClassName}
        disclosureClassName={this.props.disclosureClassName}
        branchId={this.props.branchId}
        projectId={this.props.projectId}
        selectedPageId={this.props.selectedPageId}
        selectedContentTypeId={this.props.selectedContentTypeId}
        onClickItem={this.handleClickItem}
        isFocused={this.props.isFocused}
        isMobile={this.props.isMobile}
      />
    );

    return children;
  };

  renderTreeView(
    file: TFile,
    showMenu?: (ev: SyntheticEvent<>) => void,
    ref?: React.Ref<"span">,
    buttonProps?: Object
  ) {
    const { disabled, isFocused, selectedPageId } = this.props;
    const filePages = this.props.pages[File.uniqueId(file)] || empty.array;
    const fileSelected = this.props.selectedFileId === file.id;
    const fileIsFocused = fileSelected && isFocused && !selectedPageId;
    const fileIsOpening = this.fileIsOpening(file);
    const fileIcon = `file-${file.type}${file.isLibrary ? "-library" : ""}`;
    const fileChange = this.props.fileChanges[file.id];
    return (
      <TreeView
        key={file.id}
        disabled={disabled}
        label={
          <Label
            name={File.nameWithExtension(file)}
            isOpening={fileIsOpening}
            isLight={fileIsFocused}
            isSelected={fileSelected}
            hasComments={!!this.props.fileCommentCounts[file.id]}
            warning={this.props.warnings[file.id]}
            status={fileChange && Change.status(fileChange)}
            disabled={disabled}
            innerRef={ref}
          >
            <FileName
              {...file}
              icon={null}
              focused={fileIsFocused}
              extensionClassName={style.fileExtension}
            />
          </Label>
        }
        ref={this.treeRef(file.id)}
        icon={fileIsOpening ? "spinner" : fileIcon}
        to={this.filePath(file.id)}
        onClick={this.handleClickItem(file.id)}
        hasChildren={this.props.hasChildren}
        isFocused={isFocused}
        selected={fileSelected && !selectedPageId}
        selectedClassName={this.props.selectedClassName}
        disclosureClassName={this.props.disclosureClassName}
        defaultCollapsed={!fileSelected}
        itemClassName={this.itemClassName}
        onFileExpand={this.handleFileExpand(file)}
        onContextMenu={showMenu}
        onDoubleClick={this.handleDoubleClick(file)}
        isMobile={this.props.isMobile}
        {...buttonProps}
      >
        {!disabled && this.renderPages(filePages, file)}
      </TreeView>
    );
  }

  render() {
    return (
      <ul className={classnames(style.tree, this.props.className)}>
        {this.props.files.map((file) => {
          if (!this.props.shouldShowLibraryMenu) {
            return (
              <FileMenu
                projectId={this.props.projectId}
                branchId={this.props.branchId}
                file={file}
              >
                {(showMenu, renderMenuTarget) => {
                  return renderMenuTarget((ref, buttonProps) => {
                    return this.renderTreeView(
                      file,
                      showMenu,
                      ref,
                      buttonProps
                    );
                  });
                }}
              </FileMenu>
            );
          } else {
            const library = find(this.props.libraries, { fileId: file.id });
            if (library) {
              return (
                <LibraryMenu
                  projectId={this.props.projectId}
                  branchId={this.props.branchId}
                  library={library}
                >
                  {(showMenu, renderMenuTarget) => {
                    return renderMenuTarget((ref, buttonProps) => {
                      return this.renderTreeView(
                        file,
                        showMenu,
                        ref,
                        buttonProps
                      );
                    });
                  }}
                </LibraryMenu>
              );
            } else {
              return this.renderTreeView(file);
            }
          }
        })}
      </ul>
    );
  }
}
