// @flow
import invariant from "invariant";
import * as React from "react";
import Centered from "core/components/Centered";
import EmptyError from "core/components/Empty/Error";
import NotFound from "core/components/Empty/NotFound";
import Layer from "core/components/Layer";
import Loading from "core/components/Loading";
import MountProfiler from "core/components/MountProfiler";
import { Abstract } from "core/lib/abstract";
import { replace, removeQuery } from "core/lib/location";
import { shareLinkPath } from "core/lib/routes";
import {
  setShareAuthorization,
  clearShareAuthorization,
} from "core/lib/shareAuthorization";
import {
  UnauthorizedError,
  ForbiddenError,
  NotFoundError,
} from "core/models/request";
import { isPublicLayer, isPublicCollection } from "core/models/shareLink";
import type {
  BranchCollectionDescriptor,
  ReactRouterLocation,
  ShareLink,
  RequestError,
} from "core/types";
import PublicCollection from "web/components/PublicCollection";
import RequestAccess from "web/components/RequestAccess";
import type { FormValues as RequestAccessFormValues } from "web/components/RequestAccess/RequestAccessForm";
import connector from "./connector";

const PROFILER_SAMPLE_RATE = 0.25;

export type OwnProps = {|
  location: ReactRouterLocation,
  params: Abstract.ShareDescriptor,
|};

export type StateProps = {|
  isLoading: boolean,
  isLoggedIn: boolean,
  loginError: ?Error,
  loginIsSubmitting: boolean,
  onCreateAccount: () => void,
  onPasswordReset: () => void,
  shareLink: ?ShareLink,
  shareLinkError: ?RequestError,
|};

export type DispatchProps = {|
  onLoad: () => void,
  onRequestAccessFormSubmit: (
    formId: string,
    projectId: string,
    values: RequestAccessFormValues
  ) => void,
  onSignIn: (username: string, password: string) => void,
  resetLoginError: () => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type State = {
  showLogin: boolean,
};

function shareLinkCollectionDescriptor(
  shareLink: ShareLink
): BranchCollectionDescriptor {
  if (shareLink.kind !== "collection") {
    throw new Error("Can only load collection descriptor for collection share");
  }

  // $FlowFixMe: Update shareLink type to sdk to allow refinement
  return shareLink.descriptor;
}

class Share extends React.Component<Props, State> {
  state = {
    showLogin: true,
  };

  componentDidMount() {
    setShareAuthorization(this.props.params.shareId);
  }

  componentWillUnmount() {
    clearShareAuthorization();
  }

  handleRequestAccessFormSubmit = (
    formId: string,
    values: RequestAccessFormValues
  ) => {
    const { shareLinkError } = this.props;
    const accessRequest = shareLinkError && shareLinkError.body;
    invariant(accessRequest, "projectId from AccessRequest required");

    this.props.onRequestAccessFormSubmit(
      formId,
      accessRequest.projectId,
      values
    );
  };

  handleClose = () => {
    const { preview, present } = this.props.location.query;

    if (preview === "false") {
      return replace(
        removeQuery("present", "collectionLayerId", "preview", "showComments")
      );
    }

    if (present === "true") {
      return replace(removeQuery("present", "showComments"));
    }

    if (this.props.shareLink) {
      return replace(shareLinkPath(this.props.shareLink.id));
    }
  };

  render() {
    const { isLoading, location, shareLink, shareLinkError } = this.props;

    const unauthorized = shareLinkError instanceof UnauthorizedError;
    const forbidden = shareLinkError instanceof ForbiddenError;
    const notFound = shareLinkError instanceof NotFoundError;
    const canRequestAccess =
      shareLinkError &&
      shareLinkError.body &&
      shareLinkError.body.canRequestAccess;

    if (isLoading) {
      return (
        <Centered>
          <Loading title="Loading share link…" />
        </Centered>
      );
    }

    if (isPublicLayer(shareLink)) {
      const params = {
        ...shareLink.descriptor,
        sha: location.query.sha || shareLink.descriptor.sha,
      };

      if (!location.query.sha && !shareLink.options.historyEnabled) {
        return <NotFound />;
      }

      return (
        <MountProfiler
          id="PublicShareLink:Layer"
          params={params}
          sampleRate={PROFILER_SAMPLE_RATE}
        >
          <Layer
            params={params}
            location={location}
            shareLink={shareLink}
            onClose={this.handleClose}
            onCloseDisabled={!location.query.preview && !location.query.present}
          />
        </MountProfiler>
      );
    }

    if (isPublicCollection(shareLink)) {
      return (
        <MountProfiler
          id="PublicShareLink:Collection"
          params={this.props.params}
          sampleRate={PROFILER_SAMPLE_RATE}
        >
          <PublicCollection
            params={shareLinkCollectionDescriptor(shareLink)}
            location={location}
            shareLink={shareLink}
            onClose={this.handleClose}
          />
        </MountProfiler>
      );
    }

    if (
      shareLinkError &&
      shareLinkError.body &&
      canRequestAccess &&
      (unauthorized || forbidden)
    ) {
      const { organizationLogo, organizationName, projectColor, projectName } =
        shareLinkError.body;

      return (
        <RequestAccess
          isLoggedIn={this.props.isLoggedIn}
          onRequestAccess={this.handleRequestAccessFormSubmit}
          onSelectLoginForm={() => this.setState({ showLogin: true })}
          onSelectRequestAccessForm={() => this.setState({ showLogin: false })}
          organizationLogo={organizationLogo}
          organizationName={organizationName}
          projectColor={projectColor}
          projectName={projectName}
          showLogin={this.state.showLogin}
          username={this.props.location.query.username}
          onCreateAccount={this.props.onCreateAccount}
          onPasswordReset={this.props.onPasswordReset}
          onSignIn={this.props.onSignIn}
          isSubmitting={this.props.loginIsSubmitting}
          loginError={this.props.loginError}
          resetLoginError={this.props.resetLoginError}
        />
      );
    }

    if (notFound || (!canRequestAccess && forbidden)) {
      return <NotFound />;
    }

    return <EmptyError />;
  }
}

export default connector(Share);
