// @flow
import { connect } from "react-redux";
import { isOnline } from "abstract-di/selectors";
import { showLoadingToast } from "core/actions/toasts";
import {
  BranchCollectionsFetchRequest,
  CollectionCreateRequest,
} from "core/requests/collections";
import { getUpdatableCollections } from "core/selectors/collections";
import type { Dispatch, State } from "core/types";
import type { OwnProps, StateProps, DispatchProps, Props } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { projectId, branchId } = props;

  return {
    collections: getUpdatableCollections(state, { projectId, branchId }),
    isOnline: isOnline(state),
    isLoading: BranchCollectionsFetchRequest.isFirstLoading(state, {
      projectId,
      branchId,
    }),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    onCreateCollection: (values, successCallback) => {
      const { projectId, branchId } = props;
      const loadingToast = dispatch(
        showLoadingToast({
          icon: "collection",
          text: `Creating a new collection…`,
        })
      );
      dispatch(
        CollectionCreateRequest.perform({
          params: { projectId, branchId, ...values, published: true },
          onSuccess: (response) => {
            const collection = response.data;
            successCallback(collection);
            loadingToast.showAutoClose({ text: "New collection created" });
          },
          onError: (error) => {
            loadingToast.error({
              text: "Failed to create collection",
              subtext: error.message,
            });
          },
        })
      );
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
