// @flow
import * as Abstract from "abstract-sdk";
import * as React from "react";
import { connect } from "react-redux";
import Head from "core/components/Head";
import Media from "core/components/Media";
import OrganizationActivities from "core/components/OrganizationActivities";
import { ProjectsFetchRequest } from "core/requests/projects";
import type { State, Dispatch } from "core/types";

type OwnProps = {|
  location: { query: { projectId?: string } },
  params: Abstract.OrganizationDescriptor,
|};

type DispatchProps = {|
  loadProjects: () => void,
|};

type Props = {
  ...OwnProps,
  ...DispatchProps,
};

class OrganizationActivityLoader extends React.Component<Props> {
  componentDidMount() {
    this.props.loadProjects();
  }

  render() {
    const { params, location } = this.props;
    return (
      <Media desktop>
        {(desktop) => (
          <React.Fragment>
            <Head>
              <title>Activity</title>
            </Head>
            <OrganizationActivities
              mobile={!desktop}
              params={params}
              location={location}
            />
          </React.Fragment>
        )}
      </Media>
    );
  }
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { organizationId } = props.params;

  return {
    loadProjects() {
      return dispatch(
        ProjectsFetchRequest.perform({
          params: { organizationId, filter: "active" },
        })
      );
    },
  };
}

export default connect<Props, OwnProps, _, DispatchProps, State, Dispatch>(
  undefined,
  mapDispatchToProps
)(OrganizationActivityLoader);
