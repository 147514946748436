// @flow
import {
  trackUser as coreTrackUser,
  type TrackUserParams,
} from "core/actions/analytics";
import type { ThunkAction } from "core/types";
import { getCurrentUser } from "web/selectors/session";

export function trackUser(params: TrackUserParams): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState();
    const marketingConsent =
      state.session.marketingConsentGiven &&
      !state.session.marketingConsentDeclined;

    dispatch(coreTrackUser({ ...params, marketingConsent }));
  };
}

export function getAndTrackNewUser(): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState();
    const user = getCurrentUser(state);
    if (user) {
      dispatch(trackUser({ user }));
    }
  };
}
