// @flow
import { map, find } from "lodash";
import * as React from "react";
import ProcessingFile from "core/components/ProcessingFile";
import style from "./style.scss";

type Props = {
  files: string[],
  filePathToIsLibrary: { [path: string]: boolean },
  complete: number,
  isCommitting?: boolean,
};

export default class ProcessingFilesList extends React.Component<Props> {
  isLibraryFile = (fileName: string): boolean => {
    return !!find(
      this.props.filePathToIsLibrary,
      (isLibrary, p: string) => isLibrary && p.includes(fileName)
    );
  };

  render() {
    return (
      <ol className={style.files} data-qa="file-list-wrapper">
        {map(this.props.files, (fileName: string, index: number) => (
          <ProcessingFile
            key={index}
            filename={fileName}
            complete={this.props.complete > index}
            isLoading={this.props.complete <= index}
            isLibrary={this.isLibraryFile(fileName)}
            qaSelector={`imported-file-${index}`}
          />
        ))}
        {this.props.isCommitting && (
          <ProcessingFile
            name="Committing…"
            icon="commit"
            complete={false}
            isLoading
          />
        )}
      </ol>
    );
  }
}
