// @flow
import keys from "lodash/keys";
import queryString from "query-string";
import { handleError } from "core/api";
import coreApiRequest from "core/di/api";
import { apiRequest as webApiRequest } from "web/api";
import { getToken } from "web/token";

async function fetchApi(
  endpoint: string,
  token: ?string,
  options: Object = {}
) {
  const response = await webApiRequest(endpoint, token, options);

  if (!response.ok) {
    throw await handleError(response);
  }

  try {
    const data = await response.json();
    return data;
  } catch (err) {
    return response;
  }
}

const apiRequest: typeof coreApiRequest = (
  method: "get" | "post" | "put" | "delete",
  endpoint: string,
  payload: Object = {},
  version: number = 6,
  arrayFormat: string = "none"
) => {
  const options: { body?: Object } = {
    method,
    headers: { "Abstract-Api-Version": `${version}` },
  };

  let query = "";

  if (keys(payload).length) {
    if (method === "get") {
      query = `?${queryString.stringify(payload, { arrayFormat })}`;
    } else {
      options.body = payload;
    }
  }

  return fetchApi(`${endpoint}${query}`, getToken(), options);
};

export default apiRequest;
