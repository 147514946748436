// @flow
import * as React from "react";
import Button from "core/components/Button";
import Flex from "core/components/Flex";
import Icon from "core/components/Icon";
import Progress from "core/components/Progress";
import Theme from "core/components/Theme";
import window from "core/global/window";
import { V3Link as Link } from "core/lib/router";
import style from "./style.scss";

export type Props = {
  text: string,
  subtext?: React.Node,
  link?: string,
  linkLabel?: string,
  icon?: string,
  autoClose?: number,
  progress?: number,
  onClose?: (event?: SyntheticMouseEvent<>) => void,
  onClick?: (event: SyntheticMouseEvent<>) => void,
};

export default class Toast extends React.Component<Props> {
  timeout: TimeoutID;

  componentDidMount() {
    const { autoClose, onClose } = this.props;
    if (autoClose && onClose) {
      this.timeout = window.setTimeout(onClose, autoClose);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.autoClose && this.props.autoClose && this.props.onClose) {
      this.timeout = window.setTimeout(
        this.props.onClose,
        this.props.autoClose
      );
      return;
    }

    if (!this.props.autoClose && this.timeout) {
      window.clearTimeout(this.timeout);
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }
  }

  render() {
    const {
      text,
      subtext,
      autoClose,
      icon,
      progress,
      link,
      linkLabel,
      onClick,
      onClose,
    } = this.props;

    const closeable = !autoClose && onClose;
    const autoClosing = autoClose && onClose;
    const basic = !closeable && !autoClosing;
    const iconType = icon || (basic ? "spinner" : undefined);
    const action =
      link || onClick ? (
        <Button
          to={link}
          className={style.action}
          onClick={onClick}
          component={link ? Link : undefined}
        >
          {linkLabel || "View"}
        </Button>
      ) : undefined;

    const hasProgress = progress !== undefined;
    const hasIcon = !!iconType;
    const hasCloseIcon = closeable && !action;

    return (
      <Theme.Consumer>
        {({ themeName }) => (
          <div className={style.toast}>
            <Flex align="center">
              {hasIcon && iconType && (
                <Icon
                  type={iconType}
                  className={style.icon}
                  light={themeName === "light" && iconType === "spinner"}
                />
              )}

              <div className={style.text}>
                {text}
                {!!subtext && <p className={style.subtext}>{subtext}</p>}
              </div>

              {hasCloseIcon && (
                <React.Fragment>
                  <div className={style.separator} />
                  <Button
                    className={style.close}
                    onClick={onClose}
                    icon="close"
                    nude
                  />
                </React.Fragment>
              )}

              {action && (
                <React.Fragment>
                  <div className={style.separator} />
                  {action}
                </React.Fragment>
              )}
            </Flex>
            {hasProgress && (
              <Progress
                value={progress || 0}
                max={100}
                className={style.progress}
                creep={15}
              />
            )}
          </div>
        )}
      </Theme.Consumer>
    );
  }
}
