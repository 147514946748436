// @flow
import invariant from "invariant";
import { connect } from "react-redux";
import { updateLibraries } from "abstract-di/actions";
import { dismissDialog } from "core/actions/dialogs";
import { getFilesAtSha } from "core/selectors/files";
import { getLibraryPagesFromFile } from "core/selectors/libraries";
import type { State, Dispatch } from "core/types";
import type { Props, OwnProps, StateProps, DispatchProps } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { projectId, sha, file } = props;
  const fileLibraries = getLibraryPagesFromFile(state, {
    projectId,
    sha,
    fileId: file.id,
  });
  const projectFiles = getFilesAtSha(state, { projectId, sha });
  return {
    fileLibraries,
    projectFiles,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    dismissDialog: () => {
      dispatch(dismissDialog());
    },
    updateLibraries: (
      projectId: string,
      branchId: string,
      fileId: string,
      fileName: string,
      libraryUpdates: { [libraryId: string]: string }
    ) => {
      invariant(updateLibraries, "updateLibraries required for dialog action");
      dispatch(
        updateLibraries(projectId, branchId, fileId, fileName, libraryUpdates)
      );
    },
  };
}
export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
