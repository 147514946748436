// @flow
import createCachedSelector from "@elasticprojects/re-reselect";
import { filter, values, find, pick, keyBy } from "lodash";
import { createSelector } from "reselect";
import { getCurrentUserId } from "abstract-di/selectors";
import { Abstract } from "core/lib/abstract";
import matchString from "core/lib/matchString";
import naturalSortBy from "core/lib/naturalSortBy";
import { getEntity } from "core/selectors/entities";
import { getProjectId } from "core/selectors/helpers";
import {
  getMemberships,
  type MembershipParams,
} from "core/selectors/memberships";
import {
  getProjectMemberships,
  type ProjectMembershipParams,
} from "core/selectors/projectMemberships";
import { getTeamMembershipsForTeam } from "core/selectors/teamMemberships";
import type { User, State, TeamDescriptor } from "core/types";

type UserFilters = { query?: string };
type UsernameFilter = { username: string };

function getQuery(state: State, params: UserFilters) {
  return params.query || "";
}

const getUsername = (state: State, params: UsernameFilter) => params.username;

export function getUserEntities(state: State): { [userId: string]: ?User } {
  return state.users;
}

export function getUser(state: State, props: { userId: string }) {
  return getUserEntities(state)[props.userId];
}

export const getUsers: (state: State) => User[] = createSelector(
  getUserEntities,
  (users) => naturalSortBy(values(users), "name")
);

function getUsersForProjectCache(
  state: State,
  params: ProjectMembershipParams & UserFilters
) {
  const key = [
    params.projectId,
    "fromOrganization" in params
      ? params.fromOrganization
        ? "-from-organization"
        : "-not-from-organization"
      : undefined,
    params.userId,
    params.role,
    params.subscriptionRole,
    params.query,
  ]
    .filter((i) => i)
    .join("-");

  return key;
}

export const getUserByUsername: (state: State, props: UsernameFilter) => ?User =
  createCachedSelector(getUserEntities, getUsername, (users, username) =>
    find(users, { username })
  )(getUsername);

export const getUsersForProject: (
  state: State,
  props: ProjectMembershipParams & UserFilters
) => User[] = createCachedSelector(
  getQuery,
  getProjectMemberships,
  getUserEntities,
  (query, memberships, users) => {
    const members = naturalSortBy(
      values(
        pick(
          users,
          memberships.map((m) => m.userId)
        )
      ),
      "name"
    );

    if (query) {
      return filter(
        members,
        (user) =>
          matchString(user.name, query) || matchString(user.username, query)
      );
    }

    return members;
  }
)(getUsersForProjectCache);

export const getProjectUsersMap: (
  state: State,
  props: ProjectMembershipParams & UserFilters
) => { [id: string]: ?User } = createCachedSelector(
  [getUsersForProject],
  (users) => keyBy(users, "id")
)(getUsersForProjectCache);

export function getAdminsForProject(
  state: State,
  props: Abstract.ProjectDescriptor
) {
  return getUsersForProject(state, {
    projectId: props.projectId,
    role: "admin",
  });
}

function getProjectAdminsForProject(
  state: State,
  props: Abstract.ProjectDescriptor
) {
  return getUsersForProject(state, {
    projectId: props.projectId,
    role: "admin",
    fromOrganization: false,
  });
}

export function getUserIsProjectAdmin(
  state: State,
  props: Abstract.ProjectDescriptor
): boolean {
  const { projectId } = props;
  const currentUserId = getCurrentUserId(state);
  const projectAdmins = getUsersForProject(state, { projectId, role: "admin" });

  if (currentUserId) {
    return projectAdmins.some((admin) => admin.id === currentUserId);
  } else {
    return false;
  }
}

export const getAnyAdminsForProject: (
  state: State,
  props: Abstract.ProjectDescriptor
) => User[] = createCachedSelector(
  [getAdminsForProject, getProjectAdminsForProject],
  (allAdmins, projectAdmins) => {
    return projectAdmins.length ? projectAdmins : allAdmins;
  }
)(getProjectId);

const queryFilter = (user: User, query: string): boolean => {
  return matchString(user.name, query) || matchString(user.username, query);
};

function cacheOrganizationUsers(
  state: State,
  { organizationId, role = "", subscriptionRole = "", userId = "", query = "" }
) {
  return `${organizationId}-${query}-${role}-${subscriptionRole}-${userId}`;
}

export const getUsersForOrganization: (
  state: State,
  props: MembershipParams & UserFilters
) => User[] = createCachedSelector(
  getQuery,
  getMemberships,
  getUserEntities,
  (query, memberships, users) => {
    const members = naturalSortBy(
      values(
        pick(
          users,
          memberships.map((m) => m.userId)
        )
      ),
      "name"
    );

    if (query) {
      return filter(members, (user) => queryFilter(user, query));
    }

    return members;
  }
)(cacheOrganizationUsers);

export function getUserIsInOrganization(
  state: State,
  props: Abstract.OrganizationDescriptor
): boolean {
  const { organizationId } = props;
  const currentUserId = getCurrentUserId(state);
  return (
    !!currentUserId &&
    !!getEntity(state, "memberships", `${organizationId}-${currentUserId}`)
  );
}

function cacheTeamUsers(state: State, { teamId, query = "" }) {
  return `${teamId}-${query}`;
}

export const getUsersForTeam: (
  state: State,
  props: {
    ...TeamDescriptor,
    query?: string,
  }
) => User[] = createCachedSelector(
  getQuery,
  getTeamMembershipsForTeam,
  getUserEntities,
  (query, teamMemberships, users) => {
    const members = naturalSortBy(
      values(
        pick(
          users,
          teamMemberships.map((m) => m.userId)
        )
      ),
      "name"
    );

    if (query) {
      return filter(members, (user) => queryFilter(user, query));
    }

    return members;
  }
)(cacheTeamUsers);

function getUserIds(state: State, params: { ids: string[] }) {
  return params.ids;
}

function cacheByUserIds(state: State, params: { ids: string[] }) {
  return params.ids.join("-");
}

export const getUsersForIds: (
  state: State,
  props: { ids: string[] }
) => User[] = createCachedSelector(
  getUserEntities,
  getUserIds,
  (users, ids) => {
    return values(pick(users, ids));
  }
)(cacheByUserIds);
