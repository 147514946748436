// @flow
import classnames from "classnames";
import * as React from "react";
import AnimatedFlyover, {
  type AnchorPosition,
} from "core/components/AnimatedFlyover";
import Button from "core/components/Button";
import window from "core/global/window";
import createConnector from "core/lib/createConnector";
import connectStorage from "../../hocs/connectStorage";
import style from "./style.scss";

type StorageProps = {|
  hasSeenOnboarding: boolean,
  dismissOnboarding: () => void,
|};

type OwnProps = {|
  children: React.Node,
  storageKey: string,
  anchor?: AnchorPosition,
  text: string,
  caretOffset?: number, // Sometimes the toggle will have a margin-left or something similar, which will offset our dynamic calculation.
  className?: string,
|};

type Props = {
  ...StorageProps,
  ...OwnProps,
};

function OnboardingBubble(props: Props) {
  const toggleRef = React.useRef<HTMLDivElement | null>(null);
  const flyoverRef = React.useRef<HTMLDivElement | null>(null);
  const caretRef = React.useRef<HTMLDivElement | null>(null);

  const [canOpen, setCanOpen] = React.useState(false);
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setCanOpen(true);
    }, 100);

    return () => {
      window.clearTimeout(timeout);
    };
  }, []);

  React.useLayoutEffect(() => {
    if (canOpen && !props.hasSeenOnboarding) {
      // Calculate position of the caret relative to the size of the child toggler.
      const toggle = toggleRef.current;
      const flyover = flyoverRef.current;
      const caret = caretRef.current;
      const caretOffset = props.caretOffset || 0;

      if (toggle && flyover && caret) {
        const flyoverSize = flyover.getBoundingClientRect();
        const toggleSize = toggle.getBoundingClientRect();

        const halfWidthOfCaret = 9;
        const centerOfToggle = toggleSize.width / 2 + toggleSize.left;
        const difference =
          centerOfToggle - flyoverSize.left - halfWidthOfCaret + caretOffset;

        caret.style.transform = `translateX(${difference}px)`;
      }
    }
  }, [props.caretOffset, props.hasSeenOnboarding, canOpen]);

  const { hasSeenOnboarding, dismissOnboarding } = props;
  React.useEffect(() => {
    function handleDismissOnWheel() {
      dismissOnboarding();
    }

    if (!hasSeenOnboarding) {
      window.addEventListener("wheel", handleDismissOnWheel);
    }
    return () => {
      window.removeEventListener("wheel", handleDismissOnWheel);
    };
  }, [hasSeenOnboarding, dismissOnboarding]);

  return (
    <AnimatedFlyover
      className={classnames(style.flyover, {
        [style.flyoverTopOffset]: props.anchor !== "topLeft",
      })}
      wrapperClass={style.wrapper}
      anchor={props.anchor || "center"}
      body={
        <div ref={flyoverRef} className={style.body}>
          <div
            ref={caretRef}
            className={classnames(style.caret, {
              [style.caretTop]: props.anchor === "topLeft",
            })}
          />
          <div className={style.text}>{props.text}</div>
          <Button light nude onClick={props.dismissOnboarding}>
            Got It
          </Button>
        </div>
      }
      onClickOutside={props.dismissOnboarding}
      onRequestClose={props.dismissOnboarding}
      isOpen={canOpen && !props.hasSeenOnboarding}
    >
      <div
        ref={toggleRef}
        className={classnames(props.className, style.toggle)}
      >
        {props.children}
      </div>
    </AnimatedFlyover>
  );
}

export default createConnector<Props, OwnProps>((Component) =>
  connectStorage(Component, (storage, props: Props) => {
    return {
      hasSeenOnboarding: !!storage.getItem(props.storageKey),
      dismissOnboarding: () => storage.setItem(props.storageKey, true),
    };
  })
)(OnboardingBubble);
