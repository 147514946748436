// @flow
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "core/components/Button";
import CopyToClipboard from "core/components/CopyToClipboard";
import FormSection from "core/components/FormSection";
import Icon from "core/components/Icon";
import Input from "core/components/Input";
import Popover from "core/components/Popover";
import SettingsItem from "core/components/SettingsItem";
import { OAuthApplicationUpdateRequest } from "web/requests/oAuthApplications";
import type { OAuthApplication } from "web/types";
import style from "./style.scss";

type Props = {
  application: OAuthApplication,
  onAfterSubmit: (success: boolean) => void,
};

const OAuthApplicationDetailsSection = ({
  application: {
    id,
    name,
    redirectURIs,
    description,
    clientSecret,
    organizationId,
  },
  onAfterSubmit,
}: Props) => {
  const dispatch = useDispatch();

  const [updatedName, setUpdatedName] = useState(name);
  const [updatedRedirectURI, setUpdatedRedirectURI] = useState(redirectURIs[0]);
  const [updatedDescription, setUpdatedDescription] = useState(description);

  const [isSecretShown, setIsSecretShown] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const isSubmitting = useSelector((state) =>
    OAuthApplicationUpdateRequest.isLoadingStrict(state, { id })
  );
  const {
    redirectUris: uriError,
    name: nameError,
    description: descriptionError,
  } = useSelector((state) =>
    OAuthApplicationUpdateRequest.validationErrors(state, { id })
  );

  const handleChange = (func) => (ev: SyntheticInputEvent<>) => {
    func(ev.target.value);
  };

  const toggleShowPassword = () => {
    setIsSecretShown((isShown) => !isShown);
  };

  const handleSubmit = async (event: SyntheticEvent<>) => {
    event.preventDefault();
    dispatch(
      OAuthApplicationUpdateRequest.perform({
        params: {
          name: updatedName,
          redirectURI: updatedRedirectURI,
          description: updatedDescription,
          id,
          organizationId,
        },
        onSuccess: () => onAfterSubmit(true),
        onError: () => onAfterSubmit(false),
      })
    );
  };

  useEffect(() => {
    if (
      updatedName === name &&
      updatedRedirectURI === redirectURIs[0] &&
      updatedDescription === description
    ) {
      setIsDirty(false);
    } else {
      setIsDirty(true);
    }
  }, [
    updatedName,
    updatedRedirectURI,
    updatedDescription,
    name,
    redirectURIs,
    description,
  ]);

  useEffect(() => {
    return () => {
      dispatch(OAuthApplicationUpdateRequest.clear({ id }));
    };
  }, [dispatch, id]);

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <FormSection heading="Details">
        <SettingsItem input>
          <Input
            responsive
            requiredTag
            label="Name"
            name="name"
            value={updatedName}
            onChange={handleChange(setUpdatedName)}
            disabled={isSubmitting}
            error={nameError}
          />
        </SettingsItem>
        <SettingsItem input>
          <Input
            responsive
            requiredTag
            label="Redirect URL"
            name="redirectURI"
            value={updatedRedirectURI}
            onChange={handleChange(setUpdatedRedirectURI)}
            disabled={isSubmitting}
            error={uriError}
          />
        </SettingsItem>
        <SettingsItem input>
          <Input
            responsive
            label="Description"
            name="description"
            value={updatedDescription}
            onChange={handleChange(setUpdatedDescription)}
            disabled={isSubmitting}
            error={descriptionError}
          />
        </SettingsItem>
        <SettingsItem input>
          <Input
            responsive
            spellCheck="false"
            label="Client Id"
            name="clientID"
            value={id}
            disabled={isSubmitting}
            helpers={{
              copy: (
                <CopyToClipboard toast="Client id copied" value={id}>
                  {(setClickableRef) => (
                    <Button innerRef={setClickableRef}>Copy</Button>
                  )}
                </CopyToClipboard>
              ),
            }}
          />
        </SettingsItem>
        <SettingsItem input>
          <Input
            responsive
            spellCheck="false"
            label="Client Secret"
            name="clientSecret"
            type={isSecretShown ? "text" : "password"}
            value={clientSecret}
            disabled={isSubmitting}
            helpers={{
              showPassword: (
                <div className={style.showPassword}>
                  <Popover label="Show Client Secret" placement="top">
                    <Icon type="show" onClick={toggleShowPassword} />
                  </Popover>
                </div>
              ),
              copy: (
                <CopyToClipboard
                  toast="Client secret copied"
                  value={clientSecret}
                >
                  {(setClickableRef) => (
                    <Button innerRef={setClickableRef}>Copy</Button>
                  )}
                </CopyToClipboard>
              ),
            }}
          />
        </SettingsItem>
        <SettingsItem>
          <Button
            primary
            type="submit"
            className={style.pullRight}
            disabled={!isDirty || isSubmitting}
          >
            {isSubmitting ? "Saving changes…" : "Save changes"}
          </Button>
        </SettingsItem>
      </FormSection>
    </form>
  );
};

export default OAuthApplicationDetailsSection;
