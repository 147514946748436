// @flow
import { connect } from "react-redux";
import { getCurrentUserId } from "abstract-di/selectors";
import {
  fetchBranchStatus,
  loadParentBranchRestrictions,
  changeBranchStatus,
} from "core/actions/branches";
import { fetchBranchReviews } from "core/actions/reviews";
import { withData } from "core/components/DataLoader";
import { BranchStatus } from "core/gitConstants";
import createConnector from "core/lib/createConnector";
import * as Request from "core/models/request";
import {
  BranchFetchRequest,
  BranchUpdateRequest,
} from "core/requests/branches";
import { OrganizationsFetchRequest } from "core/requests/organizations";
import { ProjectFetchRequest } from "core/requests/projects";
import {
  getBranch,
  getBranchStatus,
  getParentBranches,
} from "core/selectors/branches";
import { getProjectFeatureEnabled } from "core/selectors/features";
import { getOrganizationForProject } from "core/selectors/organizations";
import {
  getOrganizationPolicy,
  getProjectPolicy,
} from "core/selectors/policies";
import { getBranchReview } from "core/selectors/reviews";
import { getUser } from "core/selectors/users";
import type { Dispatch, State } from "core/types";
import { getProject } from "web/selectors/projects";
import type { OwnProps, StateProps, DispatchProps, Props } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { projectId, branchId } = props.params;
  const organization = getOrganizationForProject(state, { projectId });
  const project = getProject(state, projectId);
  const branch = getBranch(state, props.params);
  const parent =
    branch && branch.parent
      ? getBranch(state, { projectId, branchId: branch.parent })
      : undefined;
  const owner = branch ? getUser(state, { userId: branch.userId }) : undefined;
  const currentUserId = getCurrentUserId(state);
  const branchRequest = BranchFetchRequest.getRequest(state, {
    projectId,
    branchId,
  });
  const projectRequest = ProjectFetchRequest.getRequest(state, { projectId });
  const policy = getProjectPolicy(state, { projectId });
  const organizationPolicy = getOrganizationPolicy(state, {
    organizationId: project ? project.organizationId : "",
  });
  const organizationLoading =
    !organization && OrganizationsFetchRequest.isLoading(state);
  const isOwner = !!branch && branch.userId === currentUserId;

  const branchIsMerged = branch ? branch.status === BranchStatus.MERGED : false;
  const canShowReviews =
    !branchIsMerged && !!organization && !!organizationPolicy.showReviews;

  return {
    key: `${projectId}-${branchId}`,
    project,
    branch,
    parent,
    branchStatus: getBranchStatus(state, { projectId, branchId }),
    owner,
    isOwner,
    canShowReviews,
    isLoading:
      Request.isLoading(branchRequest) ||
      Request.isLoading(projectRequest) ||
      organizationLoading,
    notFound:
      Request.notFound(branchRequest) || Request.forbidden(branchRequest),
    error: Request.hasError(branchRequest),
    canUpdateBranch: isOwner && policy.updateBranch,
    canShowDocumentationTab: getProjectFeatureEnabled(state, {
      projectId,
      feature: "dsm-documentation-tab",
    }),
    branchReview: getBranchReview(state, props.params),
    parentBranches: getParentBranches(state, { projectId, branchId }),
    branchStatusUpdating: BranchUpdateRequest.isLoadingStrict(state, {
      projectId,
      branchId,
    }),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { projectId, branchId } = props.params;
  return {
    onLoad() {
      dispatch(BranchFetchRequest.perform({ params: { projectId, branchId } }));
      dispatch(fetchBranchReviews({ projectId, branchId }));
      dispatch(loadParentBranchRestrictions(projectId, branchId));
      dispatch(fetchBranchStatus(projectId, branchId));
    },
    onRefreshBranchStatus: (
      projectId: string,
      branchId: string,
      parentId: string
    ) => {
      dispatch(fetchBranchStatus(projectId, branchId, parentId));
    },
    onChangeBranchStatus: (
      status: string,
      options?: { onError: () => void }
    ) => {
      return dispatch(changeBranchStatus(projectId, branchId, status, options));
    },
  };
}

export default createConnector<Props, OwnProps>(
  connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch>(
    mapStateToProps,
    mapDispatchToProps
  ),
  withData((props) => ({
    branchId: props.branchId,
  }))
);
