// @flow
import reduce from "lodash/reduce";
import * as React from "react";
import Facepile from "core/components/Facepile";
import { ReviewStatuses, reviewStatusColor } from "core/models/review";
import type { ReviewRequest } from "core/types";

type Props = {
  reviewRequests: ReviewRequest[],
};

const reviewMessage = {
  [ReviewStatuses.APPROVED]: " approved",
  [ReviewStatuses.REJECTED]: " requested changes",
  [ReviewStatuses.REQUESTED]: "",
};

export default function ReviewPile({ reviewRequests, ...rest }: Props) {
  const reviewers = reviewRequests.map(
    (reviewRequest) => reviewRequest.reviewer
  );

  const avatarBorderColors = reduce(
    reviewRequests,
    (memo, reviewRequest) => {
      const { reviewerId, status } = reviewRequest;
      memo[reviewerId] = reviewStatusColor(status);
      return memo;
    },
    {}
  );

  const tooltips = reduce(
    reviewRequests,
    (memo, reviewRequest) => {
      const { reviewer, status } = reviewRequest;
      memo[reviewer.id] = `${reviewer.name}${reviewMessage[status]}`;
      return memo;
    },
    {}
  );

  return (
    <Facepile
      {...rest}
      users={reviewers}
      avatarBorderColors={avatarBorderColors}
      tooltips={tooltips}
    />
  );
}
