// @flow
import { connect } from "react-redux";
import { withData } from "core/components/DataLoader";
import createConnector from "core/lib/createConnector";
import { OrganizationTeamFetchRequest } from "core/requests/teams";
import { getTeamPolicy, getOrganizationPolicy } from "core/selectors/policies";
import { getTeam } from "core/selectors/teams";
import type { State, Dispatch } from "core/types";
import type { OwnProps, Props, StateProps, DispatchProps } from "./";
function mapStateToProps(state: State, props: OwnProps): StateProps {
  const organizationPolicy = getOrganizationPolicy(state, {
    organizationId: props.params.organizationId,
  });
  const team = getTeam(state, props.params);
  const teamPolicy = getTeamPolicy(state, { teamId: props.params.teamId });
  const teamRequestIsLoading = OrganizationTeamFetchRequest.isFirstLoading(
    state,
    props.params
  );

  return {
    team,
    teamPolicy,
    isTeamLoading: teamRequestIsLoading,
    organizationPolicy,
    notFound: !teamRequestIsLoading && !team,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    onLoad: () => {
      dispatch(
        OrganizationTeamFetchRequest.perform({
          params: props.params,
          force: true,
        })
      );
    },
  };
}

export default createConnector<Props, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withData((props) => props.params)
);
