// @flow
import defineRequest from "core/requests";
import * as API from "web/api";
import * as URLs from "web/api/urls";

type AcceptProjectAccessParams = { projectId: string, token: string };

export const AcceptProjectAccessRequest = defineRequest<
  AcceptProjectAccessParams,
  AcceptProjectAccessParams,
>({
  id(params) {
    const { projectId, token } = params;
    return `post:${URLs.acceptProjectAccessRequest(projectId, token)}`;
  },
  perform(params) {
    const { projectId, token } = params;
    return API.acceptProjectAccessRequest(projectId, token);
  },
});
