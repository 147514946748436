// @flow
import createCachedSelector from "@elasticprojects/re-reselect";
import * as Abstract from "abstract-sdk";
import { filter, orderBy } from "lodash";
import { getRawEntities } from "core/selectors/entities";
import { getOrganizationId } from "core/selectors/helpers";
import type {
  State,
  Webhook,
  WebhookDelivery,
  WebhookDeliveryDescriptor,
  WebhookDescriptor,
  WebhookEvent,
} from "core/types";

function getWebhookParams(_, params: WebhookDescriptor) {
  return params;
}

function getWebhookDeliveryParams(_, params: WebhookDeliveryDescriptor) {
  return params;
}

function getWebhookEntities(state) {
  return getRawEntities(state, "webhooks");
}

function getWebhookEventEntities(state) {
  return getRawEntities(state, "webhookEvents");
}

function getWebhookDeliveryEntities(state) {
  return getRawEntities(state, "webhookDeliveries");
}

function getOrganizationWebhookEntities(webhooks, organizationId) {
  const organizationWebhooks = filter(webhooks, { organizationId });
  return orderBy(organizationWebhooks, "updatedAt", "desc");
}

function getOrganizationWebhookEntity(webhooks, { organizationId, webhookId }) {
  const webhookEntities = filter(webhooks, { id: webhookId, organizationId });
  return webhookEntities && webhookEntities[0];
}

function getMappedWebhookEventEntities(webhookEvents) {
  return Object.keys(webhookEvents).map((type) => webhookEvents[type]);
}

function getOrganizationWebhookDeliveryEntities(
  webhookDeliveries,
  { organizationId, webhookId }
) {
  return filter(webhookDeliveries, { organizationId, webhookId });
}

function getOrganizationWebhookDeliveryEntity(webhookDeliveries, params) {
  const deliveryEntities = filter(webhookDeliveries, {
    organizationId: params.organizationId,
    webhookId: params.webhookId,
    id: params.deliveryId,
  });
  return deliveryEntities && deliveryEntities[0];
}

export const getWebhooks: (
  state: State,
  params: Abstract.OrganizationDescriptor
) => Webhook[] = createCachedSelector(
  getWebhookEntities,
  getOrganizationId,
  getOrganizationWebhookEntities
)((_, { organizationId }) => `webhooks-${organizationId}`);

export const getWebhookEvents: (
  state: State,
  params: Abstract.OrganizationDescriptor
) => WebhookEvent[] = createCachedSelector(
  getWebhookEventEntities,
  getMappedWebhookEventEntities
)((_, { organizationId }) => `webhook-events-${organizationId}`);

export const getWebhook: (state: State, params: WebhookDescriptor) => Webhook =
  createCachedSelector(
    getWebhookEntities,
    getWebhookParams,
    getOrganizationWebhookEntity
  )((_, { webhookId }) => `webhook-${webhookId}`);

export const getWebhookDeliveries: (
  state: State,
  params: WebhookDescriptor
) => WebhookDelivery[] = createCachedSelector(
  getWebhookDeliveryEntities,
  getWebhookParams,
  getOrganizationWebhookDeliveryEntities
)((_, { webhookId }) => `webhook-deliveries-${webhookId}`);

export const getWebhookDelivery: (
  state: State,
  params: WebhookDeliveryDescriptor
) => WebhookDelivery = createCachedSelector(
  getWebhookDeliveryEntities,
  getWebhookDeliveryParams,
  getOrganizationWebhookDeliveryEntity
)((_, { webhookId }) => `webhook-delivery-${webhookId}`);
