// @flow
import classnames from "classnames";
import pluralize from "pluralize";
import * as React from "react";
import Button from "core/components/Button";
import OnboardingBubble from "core/components/OnboardingBubble";
import style from "./style.scss";

type Props = {
  isStacked: boolean,
  isShowingResolvedComments: boolean,
  resolvedCommentsCount: number,
  onResolveCommentsOptionClick?: () => void,
};

export default function ToggleResolvedCommentsButton(props: Props) {
  return (
    <div className={style.toggleResolvedCommentsButtonContainer}>
      <OnboardingBubble
        storageKey="resolve-comments-onboarded-ya"
        text="You can go back to see or restore resolved comments from here."
        anchor="topLeft"
      >
        <Button
          dark
          className={classnames(style.toggleResolvedCommentsButton, {
            [style.toggleResolvedCommentsButtonStacked]: props.isStacked,
          })}
          onClick={props.onResolveCommentsOptionClick}
        >
          {props.isShowingResolvedComments ? "Hide" : "Show"}{" "}
          {props.resolvedCommentsCount} resolved{" "}
          {pluralize("comment", props.resolvedCommentsCount)}
        </Button>
      </OnboardingBubble>
    </div>
  );
}
