// @flow
import classnames from "classnames";
import * as React from "react";
import InputRich from "core/components/InputRich";
import Markdown from "core/components/Markdown";
import Scrollable from "core/components/Scrollable";
import MarkdownPreviewHelper from "./MarkdownPreviewHelper";
import style from "./style.scss";

type Props = {
  projectId: string,
  label?: string,
  labelClass?: string,
  descriptionHeaderClass?: string,
  placeholder: string,
  autoFocus?: boolean,
  focusClass?: string,
  onSubmit: (event: SyntheticEvent<>) => ?Promise<void>,
  value: string,
  onChange: (string) => void,
  minHeight?: number,
  maxHeight?: number,
};

type State = {
  showPreview: boolean,
};

class InputMarkdown extends React.Component<Props, State> {
  state = {
    showPreview: false,
  };

  render() {
    const { label, descriptionHeaderClass, labelClass, ...rest } = this.props;

    // TODO use the actual input wrapper component here
    return (
      <div className={style.componentWrapper}>
        <div
          className={classnames(
            style.descriptionHeader,
            descriptionHeaderClass
          )}
        >
          <div className={classnames(style.descriptionLabel, labelClass)}>
            {label}
          </div>
          <MarkdownPreviewHelper
            preview={this.state.showPreview}
            onChange={(showPreview) => this.setState({ showPreview })}
          />
        </div>

        {this.state.showPreview ? (
          <Scrollable>
            <Markdown
              minHeight={this.props.minHeight}
              maxHeight={this.props.maxHeight}
              text={this.props.value || ""}
            />
          </Scrollable>
        ) : (
          <InputRich {...rest} />
        )}
      </div>
    );
  }
}

export default InputMarkdown;
