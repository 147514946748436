// @flow
import invariant from "invariant";
import * as React from "react";
import { connect } from "react-redux";
import { withData } from "core/components/DataLoader";
import Error from "core/components/Empty/Error";
import NotFound from "core/components/Empty/NotFound";
import Loaded from "core/components/Loaded";
import createConnector from "core/lib/createConnector";
import { locationLayerOptions } from "core/lib/layer";
import { replace } from "core/lib/location";
import {
  layerLocation,
  collectionLocation,
  projectCollectionsPath,
} from "core/lib/routes";
import * as Request from "core/models/request";
import { CollectionFetchRequest } from "core/requests/collections";
import {
  getCollection,
  getCollectionLayer,
  getLayerForCollectionLayer,
} from "core/selectors/collections";
import type {
  State,
  Dispatch,
  ReactRouterLocation,
  CollectionLayer as TCollectionLayer,
  Layer as TLayer,
  Collection,
} from "core/types";

type OwnProps = {|
  params: {
    projectId: string,
    branchId: string,
    collectionId: string,
    collectionLayerId: string,
  },
  location: ReactRouterLocation,
|};

type StateProps = {|
  canRedirect: boolean,
  hasError: boolean,
  isLoading: boolean,
  collection: ?Collection,
  collectionLayer: ?TCollectionLayer,
  layer: ?TLayer,
|};

type DispatchProps = {|
  onLoad: () => void,
|};

type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

class CollectionLayerContainer extends React.Component<Props> {
  componentDidMount() {
    if (this.props.canRedirect) {
      this.goToLayer();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.canRedirect && this.props.canRedirect) {
      this.goToLayer();
    }
  }

  goToLayer = () => {
    const { collection, collectionLayer, layer } = this.props;
    invariant(
      collection && collectionLayer && layer,
      "Cannot go to collection layer, collection or layer not loaded"
    );

    replace(
      layerLocation(
        collection.projectId,
        collection.branchId,
        layer.sha,
        layer.fileId,
        layer.id,
        {
          ...locationLayerOptions(this.props.location),
          collectionLayerId: collectionLayer.id,
          collectionId: collection.id,
          returnTo: collectionLocation(
            collection.projectId,
            collection.branchId,
            collection.id,
            {
              returnTo: projectCollectionsPath(collection.projectId),
            }
          ),
        }
      )
    );
  };

  render() {
    if (this.props.hasError) {
      return <Error />;
    }
    if (!this.props.isLoading && !this.props.canRedirect) {
      return <NotFound />;
    }
    return <Loaded loading title="Loading collection…" />;
  }
}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { projectId, collectionId, collectionLayerId } = props.params;
  const layer = getLayerForCollectionLayer(state, { collectionLayerId });
  const collectionLayer = getCollectionLayer(state, { collectionLayerId });
  const collection = getCollection(state, { collectionId });

  const request = CollectionFetchRequest.getRequest(state, {
    projectId,
    collectionId,
  });

  return {
    layer,
    collectionLayer,
    collection,
    canRedirect: !!(layer && collection && collectionLayer),
    hasError: Request.hasError(request),
    isLoading: Request.isLoading(request) && !layer,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { projectId, collectionId } = props.params;
  return {
    onLoad: () => {
      dispatch(
        CollectionFetchRequest.perform({
          params: { projectId, collectionId },
          force: false,
        })
      );
    },
  };
}

export default createConnector<Props, OwnProps>(
  connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch>(
    mapStateToProps,
    mapDispatchToProps
  ),
  withData((props) => props.params)
)(CollectionLayerContainer);
