// @flow
import { connect } from "react-redux";
import { isOnline, getCurrentUserId } from "abstract-di/selectors";
import { loadBranchChangeset } from "core/actions/changesets";
import { withData } from "core/components/DataLoader";
import createConnector from "core/lib/createConnector";
import * as Branch from "core/models/branch";
import { FilesFetchRequest } from "core/requests/files";
import {
  getBranchHead,
  getIsFirstBranchCreatedByUser,
  getBranch,
} from "core/selectors/branches";
import { getFeatureEnabled } from "core/selectors/features";
import { getFilesForBranch } from "core/selectors/files";
import { isBranchSynced } from "core/selectors/localBranches";
import { getProjectPolicy } from "core/selectors/policies";
import { getProject } from "core/selectors/projects";
import { getBranchReview } from "core/selectors/reviews";
import { getUsersForProject } from "core/selectors/users";
import type { Dispatch, State } from "core/types";
import type { OwnProps, StateProps, DispatchProps, Props } from "./";

function isBranchAvailableOffline(
  state: State,
  { projectId, branchId }: { projectId: string, branchId: string }
): boolean {
  const branchIsSynced = isBranchSynced(state, { projectId, branchId });
  const branch = getBranch(state, { projectId, branchId });
  const hasFilesForBranch = getFilesForBranch(state, {
    projectId,
    branchId,
  }).length;

  // A branch is available offline if:
  // - We have a local branch OR
  // - We already have the branch AND its files in redux
  return !!(branchIsSynced || (branch && hasFilesForBranch));
}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { projectId, branchId } = props.params;
  const project = getProject(state, { projectId });
  const branch = getBranch(state, { projectId, branchId });
  const head = getBranchHead(state, { projectId, branchId });

  const userId = getCurrentUserId(state);

  const policy = getProjectPolicy(state, { projectId });
  const canShowCollections = policy.showCollections;
  const canShowReviews = policy.showReviews;

  return {
    project,
    branch,
    head,
    users: getUsersForProject(state, {
      projectId,
    }),
    isOnline: isOnline(state),
    canShowCollections,
    canShowReviews,
    branchReview: getBranchReview(state, {
      projectId,
      branchId,
    }),
    isMaster: branch ? Branch.isMaster(branch) : false,
    isNewAndMine: branch
      ? !Branch.hasChanges(branch) &&
        !!userId &&
        Branch.userIsAuthor(branch, userId)
      : false,
    isFirstBranchCreatedByUser:
      !!userId &&
      getIsFirstBranchCreatedByUser(state, {
        userId,
      }),
    isBranchCommentThreadsEnabled: getFeatureEnabled(state, {
      feature: "branch-comments-thread",
    }),
    isBranchAvailableOffline: isBranchAvailableOffline(state, {
      projectId,
      branchId,
    }),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    onLoad: () => {
      dispatch(async (dispatch, getState) => {
        const { projectId, branchId } = props.params;
        const state = getState();
        const branch = getBranch(state, { projectId, branchId });
        const head = getBranchHead(state, { projectId, branchId });

        await dispatch(
          FilesFetchRequest.perform({
            params: {
              projectId,
              branchId,
              sha: head,
            },
          })
        );

        if (branch && !Branch.isMaster(branch)) {
          await dispatch(
            loadBranchChangeset({
              projectId,
              branchId,
              head,
            })
          );
        }
      });
    },
  };
}

export default createConnector<Props, OwnProps>(
  connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch>(
    mapStateToProps,
    mapDispatchToProps
  ),
  withData((props) => ({
    sha: props.head,
  }))
);
