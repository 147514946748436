// @flow
import { memoize, isEqual, throttle } from "lodash";
import * as React from "react";
import Breakpoint from "core/components/Breakpoint";
import Offline from "core/components/Empty/Offline";
import type { Item } from "core/components/FilterPopover";
import Loaded from "core/components/Loaded";
import MountProfiler from "core/components/MountProfiler";
import { Abstract } from "core/lib/abstract";
import { replace, addQuery, removeQuery } from "core/lib/location";
import { isDesktop } from "core/lib/platform";
import type {
  User,
  Collection,
  Project,
  CollectionBranchFilter,
  ReactRouterLocation,
} from "core/types";
import Desktop from "./Desktop";
import Mobile from "./Mobile";
import ProjectCollectionsDisabled from "./ProjectCollectionsDisabled";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  params: Abstract.ProjectDescriptor,
  location: ReactRouterLocation,
|};

export type StateProps = {|
  project: ?Project,
  selectedAuthor: ?User,
  selectedBranchFilter: CollectionBranchFilter,
  collections: Collection[],
  totalNumberOfCollections: ?number,
  canCreateCollectionsOnMaster: boolean,
  users: User[],
  isOffline: boolean,
  hasError: boolean,
  isLoading: boolean,
  isFirstLoading: boolean,
|};

export type DispatchProps = {|
  loadCollections: (paginationOptions: {
    limit: number,
    offset: number,
  }) => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

class ProjectCollections extends React.Component<Props> {
  limit = 15;

  search = () => {
    return this.props.location.query.search || "";
  };

  // This is essentially a mini-selector that caches this array of Items so that we don't
  // have to iterate through all User objects on every render. Why not a true selector? Well,
  // this data structure is very specfic to FilterPopover and it's awkward to have a
  // redux selector return a data structure that is tied that closely to a low-level React
  // component. Memoizing here is a decent compromise.
  getUserFilterItems: (User[]) => Item[] = memoize(
    (users) => {
      return users.map((author) => ({
        id: author.id,
        name: author.name,
        username: `@${author.username}`,
      }));
    },
    (users) => users.length
  );

  componentDidMount() {
    if (this.props.collections.length <= this.limit) {
      this.loadNextPageOfCollections();
    }
  }

  componentDidUpdate(prevProps: Props) {
    const query = this.props.location.query;
    const previousQuery = prevProps.location.query;

    // Keep in mind that because of ModalRoute, the URL (and therefore query reference)
    // could change while we are still mounted. Therefore, we can't rely on a simple
    // memory reference to tell if the query is different from before: we have to actually
    // check the keys and values in the object.
    if (!isEqual(query, previousQuery)) {
      this.loadNextPageOfCollections(true);
    }

    if (prevProps.isOffline !== this.props.isOffline) {
      if (!this.props.isOffline) {
        this.loadNextPageOfCollections();
      }
    }
  }

  getRemainingLimit = () => {
    const modulo = this.props.collections.length % this.limit;
    const remainingLimit = modulo <= this.limit ? this.limit : modulo;

    return remainingLimit;
  };

  loadNextPageOfCollections(resetExistingData?: boolean) {
    this.props.loadCollections({
      limit: resetExistingData ? this.limit : this.getRemainingLimit(),
      offset: resetExistingData ? 0 : this.props.collections.length,
    });
  }

  loadMore = throttle(async () => {
    if (
      this.props.collections.length === this.props.totalNumberOfCollections ||
      (this.props.totalNumberOfCollections &&
        this.limit > this.props.totalNumberOfCollections) ||
      this.limit > this.getRemainingLimit()
    ) {
      return;
    }

    if (this.props.hasError) {
      return;
    }

    this.loadNextPageOfCollections();
  }, 2000);

  handleClearFilters = () => {
    replace(removeQuery("userId", "search"));
  };

  handleSelectAuthor = (userId: ?string) => {
    if (userId && userId !== this.props.location.query.userId) {
      replace(addQuery({ userId }));
    } else {
      replace(removeQuery("userId"));
    }
  };

  handleChangeSearchFilter = (newSearchFilter: string) => {
    if (newSearchFilter) {
      replace(addQuery({ search: newSearchFilter }));
    } else {
      replace(removeQuery("search"));
    }
  };

  hasBeenFiltered = () => {
    return !!this.search() || !!this.props.selectedAuthor;
  };

  shouldShowFilteredItemsDisclaimer = () => {
    return (
      this.hasBeenFiltered() &&
      this.props.collections.length === this.props.totalNumberOfCollections
    );
  };

  shouldShowNoCollections = () => {
    return (
      !this.hasBeenFiltered() &&
      !this.props.isLoading &&
      this.props.collections.length === 0
    );
  };

  shouldShowNoSearchResults = () => {
    return (
      !!this.search() &&
      !this.props.totalNumberOfCollections &&
      !this.props.isLoading
    );
  };

  render() {
    const { isOffline, collections } = this.props;
    const hasCollections = collections.length > 0;

    if (!hasCollections && isOffline) {
      return (
        <Offline description="Connect to the internet to load collections." />
      );
    }

    const props = {
      project: this.props.project,
      projectId: this.props.params.projectId,
      collections: this.props.collections,
      selectedAuthor: this.props.selectedAuthor,
      authorFilterItems: this.getUserFilterItems(this.props.users),
      onSelectAuthor: this.handleSelectAuthor,
      canCreateCollectionsOnMaster: this.props.canCreateCollectionsOnMaster,
      search: this.search(),
      onChangeSearchFilter: this.handleChangeSearchFilter,
      branchFilter: this.props.selectedBranchFilter,
      onClearFilters: this.handleClearFilters,
      onLoadMoreCollections: this.loadMore,
      isLoading: this.props.isLoading,
      isFirstLoading: this.props.isFirstLoading,
      totalNumberOfCollections: this.props.totalNumberOfCollections,
      hasError: this.props.hasError,
      isOffline: this.props.isOffline,
      hasBeenFiltered: this.hasBeenFiltered(),
      showFilteredItemsDisclaimer: this.shouldShowFilteredItemsDisclaimer(),
      showNoCollections: this.shouldShowNoCollections(),
      showNoSearchResults: this.shouldShowNoSearchResults(),
    };

    return (
      <Loaded loading={!this.props.project}>
        {this.props.project && (
          <React.Fragment>
            {this.props.isFirstLoading && (
              <MountProfiler
                id="ProjectCollectionsLoading"
                params={this.props.params}
                unstable_profileOnUnmount
              />
            )}
            <ProjectCollectionsDisabled
              organizationId={this.props.project.organizationId}
            >
              <div className={style.container}>
                {isDesktop ? (
                  <Desktop {...props} />
                ) : (
                  <Breakpoint at="mobile">
                    {({ above }) => {
                      return above ? (
                        <Desktop {...props} />
                      ) : (
                        <Mobile {...props} />
                      );
                    }}
                  </Breakpoint>
                )}
              </div>
            </ProjectCollectionsDisabled>
          </React.Fragment>
        )}
      </Loaded>
    );
  }
}

export default connector(ProjectCollections);
