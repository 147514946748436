// @flow
import { map } from "lodash";

export function loggableArgs(args: any) {
  /* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
   * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
  return map(args, (arg) => {
    if (typeof arg === "function") {
      return "callback";
    }
    if (typeof arg === "string") {
      return arg.replace(/(.*token=)(.*)/i, "$1[Filtered]");
    }
    return JSON.stringify(arg);
  });
}
