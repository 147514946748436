// @flow
import createCachedSelector from "@elasticprojects/re-reselect";
import findIndex from "lodash/findIndex";
import values from "lodash/values";
import { getEntity } from "core/selectors/entities";
import { getUserEntities } from "core/selectors/users";
import type { State, Reaction, ReactionDescriptor, User } from "core/types";

export function getReactions(
  state: State,
  props: { commentId: string }
): Reaction[] | void {
  return getEntity(state, "reactions", props.commentId);
}

const getUserId = (_, props: ReactionDescriptor) => props.userId;
const getName = (_, props: ReactionDescriptor) => props.name;

function reactionExistsCache(state, props: ReactionDescriptor) {
  return `${props.commentId}-${props.name}-${props.userId}-reaction-exists`;
}

export const reactionExists: (
  state: State,
  props: ReactionDescriptor
) => boolean = createCachedSelector(
  [getReactions, getUserId, getName],
  (reactions: ?Array<Reaction>, userId, name) => {
    if (!reactions) {
      return false;
    }

    const reaction = reactions.find((reaction) => reaction.name === name);
    if (!reaction) {
      return false;
    }

    return reaction.userIds.includes(userId);
  }
)(reactionExistsCache);

function reactionIndexCache(state, props: ReactionDescriptor) {
  return `${props.commentId}-${props.name}-${props.userId}-reaction-index`;
}

export const getReactionWithIndex: (
  state: State,
  props: ReactionDescriptor
) => { reaction?: Reaction, index: number } = createCachedSelector(
  [getReactions, getName],
  (reactions: Reaction[] | void, name) => {
    const result = { reaction: undefined, index: -1 };

    if (reactions) {
      result.index = findIndex(reactions, (reaction) => reaction.name === name);
      result.reaction = reactions[result.index];
    }

    return result;
  }
)(reactionIndexCache);

function reactionUsersCache(state, props: { commentId: string }) {
  return `${props.commentId}-reaction-users`;
}

export const getReactionUsers: (
  state: State,
  props: { commentId: string }
) => User[] | void = createCachedSelector(
  [getReactions, getUserEntities],
  (reactions: Reaction[] | void, allUsers: { [id: string]: ?User }) => {
    if (!reactions || !allUsers) {
      return;
    }

    const users: { [string]: User } = {};

    reactions.forEach((reaction) => {
      reaction.userIds.forEach((userId) => {
        if (users[userId]) {
          return;
        }
        const user = allUsers[userId];
        if (user) {
          users[userId] = user;
        }
      });
    });

    return values(users);
  }
)(reactionUsersCache);
