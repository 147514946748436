// @flow
/* global HTMLElement */
import * as React from "react";
import { connect } from "react-redux";
import ContextMenu from "core/components/ContextMenu";
import DialogForm from "core/components/DialogForm";
import ProjectSectionDialog from "core/components/ProjectSectionDialog";
import { SectionDeleteRequest } from "core/requests/sections";
import type { Dispatch, Section } from "core/types";

type OwnProps = {
  section: Section,
  organizationId: string,
  empty: boolean,
  children: (
    showMenu: (ev: SyntheticEvent<>) => *,
    ref: (el: ?HTMLElement) => *
  ) => React.Node,
};

type Props = OwnProps & {
  onDelete: () => *,
};

type State = {
  showEdit: boolean,
  showDelete: boolean,
};

class SectionMenu extends React.Component<Props, State> {
  menu: ?ContextMenu;

  state = { showEdit: false, showDelete: false };

  handleDelete = (event?: SyntheticEvent<>) => {
    if (event) {
      event.preventDefault();
    }
    this.props.onDelete();
    this.setState({ showDelete: false });
  };

  handleClickDelete = () => {
    if (this.props.empty) {
      return this.handleDelete();
    }
    this.setState({ showDelete: true });
  };

  render() {
    const menuItems = [
      {
        label: "Rename Section…",
        click: () => this.setState({ showEdit: true }),
      },
      {
        label: "Remove Section…",
        click: this.handleClickDelete,
      },
    ];

    return (
      <React.Fragment>
        <ContextMenu
          ref={(me) => (this.menu = me)}
          id={this.props.organizationId}
          children={this.props.children}
          menuItems={menuItems}
        />
        <ProjectSectionDialog
          isOpen={this.state.showEdit}
          onClose={() => this.setState({ showEdit: false })}
          section={this.props.section}
          organizationId={this.props.organizationId}
        />
        <DialogForm
          dangerous
          isOpen={this.state.showDelete}
          onClose={() => this.setState({ showDelete: false })}
          title="Remove Section"
          onSubmit={this.handleDelete}
          primaryButton="Remove Section"
        >
          {() => (
            <div>
              This will permanently remove the{" "}
              <strong>{this.props.section.name}</strong> section from Abstract.
              Projects in this section will not be deleted, however they will be
              moved out of this section.
            </div>
          )}
        </DialogForm>
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch: Dispatch, props: OwnProps) {
  return {
    onDelete: () => {
      dispatch(
        SectionDeleteRequest.perform({
          params: { sectionId: props.section ? props.section.id : "" },
        })
      );
    },
  };
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default connect(null, mapDispatchToProps)(SectionMenu);
