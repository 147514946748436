// @flow
import * as React from "react";
import { connect } from "react-redux";
import DialogForm from "core/components/DialogForm";
import Input from "core/components/Input";
import Loading from "core/components/Loading";
import { V3Link as Link } from "core/lib/router";
import * as Request from "core/models/request";
import { deleteAccount } from "web/actions/account";
import InlineError from "web/components/InlineError";
import { CONFIRM_DELETE } from "web/constants";
import { organizationPeople, projectPeoplePath } from "web/routeHelpers";
import {
  getAccountDeletePreviewRequest,
  getAccountDeleteRequest,
} from "web/selectors/requests";
import type { Organization, Project, ValidationErrors } from "web/types";

type Props = {
  isOpen: boolean,
  isLoading: boolean,
  showBlocked: boolean,
  showConfirmation: boolean,
  validationErrors: ValidationErrors,
  processingDelete: boolean,
  deleteHasError: boolean,
  onSubmit: () => *,
  onClose: () => *,
};

type State = { open: boolean, confirmed: boolean };

class DeleteAccount extends React.Component<Props, State> {
  state = {
    open: false,
    confirmed: false,
  };

  handleConfirm = ({ target }: SyntheticInputEvent<>) => {
    this.setState({
      confirmed: target.value === CONFIRM_DELETE,
    });
  };

  handleSubmit = async (event: SyntheticEvent<>) => {
    event.preventDefault();
    this.props.onSubmit();
  };

  renderBlocked() {
    const { organizations = [], projects = [] } = this.props.validationErrors;

    return (
      <div>
        {!!organizations.length && (
          <p>
            You are the only owner of these organizations:
            <ul>
              {organizations.map((org: Organization) => (
                <li key={org.id}>
                  <strong>
                    <Link to={organizationPeople(org.id)}>{org.name}</Link>
                  </strong>
                </li>
              ))}
            </ul>
            You will need to <strong>transfer ownership</strong> or{" "}
            <strong>delete</strong> these organizations before you can delete
            your account.
          </p>
        )}
        {!!projects.length && (
          <p>
            You are the only admin of these projects:
            <ul>
              {projects.map((project: Project) => (
                <li key={project.id}>
                  <strong>
                    <Link to={projectPeoplePath(project.id)}>
                      {project.name}
                    </Link>
                  </strong>
                </li>
              ))}
            </ul>
            You will need to <strong>transfer admin rights</strong> or{" "}
            <strong>delete</strong> these projects before you can delete your
            account.
          </p>
        )}
        <p>
          Once you{"'"}ve taken care of that you{"'"}ll be able to finish the
          process.
        </p>
      </div>
    );
  }

  renderConfirm() {
    return (
      <div>
        <p>
          Deleting your account will delete all of your personal projects and
          all associated data. Please export any files you wish to keep.
        </p>
        <p>
          If you have an active subscription it will be cancelled immediately
          and you will not be billed going forward.
        </p>
        <Input
          label={`Please type "CONFIRM DELETE" to proceed`}
          onChange={this.handleConfirm}
          autoComplete="off"
          autoFocus
          qaSelector="deleteAccountConfirmationInput"
        />
        {this.props.deleteHasError && <InlineError />}
      </div>
    );
  }

  render() {
    const {
      isOpen,
      isLoading,
      processingDelete,
      showBlocked,
      showConfirmation,
      onClose,
    } = this.props;

    return (
      <DialogForm
        dangerous
        isOpen={isOpen}
        onClose={onClose}
        title="Delete Account"
        onSubmit={showConfirmation ? this.handleSubmit : undefined}
        disabled={!this.state.confirmed || processingDelete || isLoading}
        loading={processingDelete}
        primaryButton={showConfirmation ? "Delete Account" : ""}
      >
        {isLoading && <Loading title="Checking account status…" />}
        {showBlocked && this.renderBlocked()}
        {showConfirmation && this.renderConfirm()}
      </DialogForm>
    );
  }
}

function mapStateToProps(state) {
  const previewRequest = getAccountDeletePreviewRequest(state);
  const deleteRequest = getAccountDeleteRequest(state);

  return {
    isLoading: Request.isLoading(previewRequest),
    showBlocked: Request.hasError(previewRequest),
    showConfirmation: Request.success(previewRequest),
    validationErrors: Request.validationErrors(previewRequest),
    processingDelete: Request.isLoadingStrict(deleteRequest),
    deleteHasError: Request.hasError(deleteRequest),
  };
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default connect(mapStateToProps, { onSubmit: deleteAccount })(
  DeleteAccount
);
