// @flow
import { connect } from "react-redux";
import { RemoveTeamProjectMembership } from "core/actions/teamProjectMemberships";
import { RemoveTeamProjectMembershipRequest } from "core/requests/teamProjectMemberships";
import type { State, Dispatch } from "core/types";
import type { OwnProps, StateProps, DispatchProps, Props } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  return {
    isDeleteMembershipRequestLoading:
      RemoveTeamProjectMembershipRequest.isLoadingStrict(state, {
        projectId: props.project.id,
        teamId: props.team.id,
      }),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    removeFromProject: () => {
      return dispatch(
        RemoveTeamProjectMembership({
          projectId: props.project.id,
          teamId: props.team.id,
        })
      );
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
