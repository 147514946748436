// @flow
import classnames from "classnames";
import pluralize from "pluralize";
import * as React from "react";
import Avatar from "core/components/Avatar";
import Heading from "core/components/Heading";
import Icon from "core/components/Icon";
import Markdown from "core/components/Markdown";
import Separator from "core/components/Separator";
import Time from "core/components/Time";
import type {
  Commit as TCommit,
  User,
  Page,
  File,
  FilePreviews,
  Changeset,
} from "core/types";
import JumpButton from "./JumpButton";
import style from "./style.scss";

export default function CommitHeader({
  commentCount,
  commit,
  user,
  userName,
  actions,
  filter,
  files = [],
  pages = {},
  previewsByFile = {},
  changeset,
  children,
  onCommentsClick,
  onScrollToRow,
}: {
  commentCount?: number,
  commit: TCommit,
  user?: ?User,
  userName?: React.Node,
  actions?: React.Node,
  filter?: string,
  files?: File[],
  pages?: { [pageId: string]: Page },
  previewsByFile?: FilePreviews,
  changeset?: Changeset,
  children?: React.Node,
  onCommentsClick?: () => void,
  onScrollToRow?: (params: {
    id: string,
    offset?: number,
    onScrolled?: () => void,
  }) => void,
}) {
  const authorName = user ? user.name : commit.userName;
  const avatarUrl = user ? user.avatarUrl : "";

  return (
    <section className={style.section}>
      <div className={style.top}>
        <header className={style.header}>
          {actions && (
            <span className={style.actions}>
              {actions}
              {onScrollToRow && changeset && (
                <div className={style.jumpButtonMobile}>
                  <Separator className={style.separator} />
                  <JumpButton
                    mobile
                    files={files}
                    pages={pages}
                    previewsByFile={previewsByFile}
                    filter={filter}
                    onClickItem={onScrollToRow}
                  />
                </div>
              )}
            </span>
          )}
          <Heading level="2" size="xl" className={style.title}>
            {commit.title}
          </Heading>
        </header>

        <Markdown text={commit.description} className={style.description} />

        <footer className={style.footer}>
          <div className={classnames(style.footerItem, style.footerItemUser)}>
            <Avatar
              userId={user ? user.id : commit.userId}
              src={avatarUrl}
              name={authorName}
              size={24}
              className={style.footerItemIcon}
            />
            {userName || authorName}
          </div>
          <div className={style.dot}>·</div>
          <div className={classnames(style.footerItem, style.footerItemTime)}>
            <Icon
              type="clock"
              className={classnames(
                style.footerItemIcon,
                style.footerItemIconTime
              )}
            />{" "}
            <Time date={commit.time} />
          </div>

          <div
            className={classnames(style.footerItem, style.footerItemComments)}
          >
            {onScrollToRow && changeset && (
              <div
                className={classnames(
                  style.footerItem,
                  style.footerItemJumpButton
                )}
              >
                <JumpButton
                  files={files}
                  pages={pages}
                  previewsByFile={previewsByFile}
                  filter={filter}
                  onClickItem={onScrollToRow}
                />
                <Separator />
              </div>
            )}
            {commentCount !== undefined && (
              <React.Fragment>
                <Icon
                  type="comment-default"
                  className={classnames(
                    style.footerItemIcon,
                    style.footerItemIconComments
                  )}
                />{" "}
                <a
                  href="#comments"
                  onClick={onCommentsClick}
                  className={style.commentsLink}
                >
                  {`${commentCount} ${pluralize("comment", commentCount)}`}
                </a>
              </React.Fragment>
            )}
          </div>
        </footer>
      </div>
      {children ? <div className={style.children}>{children}</div> : null}
    </section>
  );
}
