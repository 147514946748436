// @flow
import type { FormValues } from "../models/form";
import type { Action } from ".";

export function formChanged(id: string, values: FormValues = {}): Action {
  return { type: "FORM_CHANGED", meta: { id }, payload: values };
}

export function formReset(id: string): Action {
  return { type: "FORM_RESET", meta: { id } };
}
