// @flow
import createCachedSelector from "@elasticprojects/re-reselect";
import { filter } from "lodash";
import { Abstract } from "core/lib/abstract";
import { getRawEntities } from "core/selectors/entities";
import { getOrganizationId } from "core/selectors/helpers";
import { getUserEntities } from "core/selectors/users";
import type { Emoji, User, State } from "core/types";

function cacheEmojis(state: State, { organizationId }) {
  return `${organizationId}-emoji`;
}

export const getEmojiEntities = (
  state: State
): { [emojiId: string]: Emoji } => {
  return getRawEntities(state, "emojis");
};

export const getEmojisForOrganization: (
  state: State,
  props: Abstract.OrganizationDescriptor
) => Emoji[] = createCachedSelector(
  getOrganizationId,
  getEmojiEntities,
  (organizationId, emojis) => {
    const filteredEmojis = filter(
      emojis,
      (emoji) => emoji.organizationId === organizationId
    );
    return filteredEmojis;
  }
)(cacheEmojis);

function emojiCreatorsCache(state, props: Abstract.OrganizationDescriptor) {
  return `emoji-creators-${props.organizationId}`;
}

export const getEmojiCreatorMap: (
  state: State,
  props: Abstract.OrganizationDescriptor
) => { [userId: string]: User } = createCachedSelector(
  [getEmojisForOrganization, getUserEntities],
  (emojis: Emoji[], allUsers: { [id: string]: ?User }) => {
    const creatorMap: { [string]: User } = {};

    emojis.forEach(({ userId }) => {
      const user = allUsers[userId];
      if (user) {
        creatorMap[userId] = user;
      }
    });

    return creatorMap;
  }
)(emojiCreatorsCache);
