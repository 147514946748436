// @flow
import classnames from "classnames";
import * as React from "react";
import FileIcon from "core/components/FileIcon";
import Icon from "core/components/Icon";
import TextHighlight from "core/components/TextHighlight";
import * as File from "core/models/file";
import style from "./style.scss";

type Props = {
  name: string,
  type: string,
  icon?: React.Node,
  focused?: boolean,
  isLibrary?: boolean,
  truncate?: boolean,
  className?: string,
  fileClassName?: string,
  extensionClassName?: string,
  iconClassName?: string,
  highlight?: string,
  onContextMenu?: (event: SyntheticEvent<>) => void,
};

export default function FileName({
  name,
  type,
  icon,
  isLibrary,
  focused,
  truncate = true,
  className,
  extensionClassName,
  fileClassName,
  iconClassName,
  highlight,
  onContextMenu,
}: Props) {
  return (
    <div
      className={classnames(style.wrapper, className, {
        [style.truncate]: truncate,
        [style.focused]: focused,
      })}
      onContextMenu={onContextMenu}
      title={File.nameWithExtension(name, type)}
    >
      {icon &&
        (typeof icon === "string" ? (
          <Icon type={icon} className={classnames(style.icon, iconClassName)} />
        ) : (
          icon
        ))}
      {!icon && icon !== null && (
        <FileIcon
          className={classnames(style.icon, iconClassName)}
          type={type}
          isLibrary={isLibrary}
        />
      )}
      <span className={style.text}>
        <span className={classnames(style.fileName, fileClassName)}>
          {highlight ? (
            <TextHighlight text={name} highlight={highlight} />
          ) : (
            name
          )}
        </span>
        {type && (
          <span className={classnames(style.fileExtension, extensionClassName)}>
            {File.extension(type)}
          </span>
        )}
      </span>
    </div>
  );
}
