// @flow
import type { LayerBorderRadius } from "core/types";

export default function shorthandBorderRadius(borderRadius: LayerBorderRadius) {
  if (
    borderRadius.topLeft === borderRadius.topRight &&
    borderRadius.topRight === borderRadius.bottomRight &&
    borderRadius.bottomRight === borderRadius.bottomLeft
  ) {
    return [borderRadius.topLeft];
  }

  if (
    borderRadius.topLeft === borderRadius.bottomRight &&
    borderRadius.topRight === borderRadius.bottomLeft
  ) {
    return [borderRadius.topLeft, borderRadius.topRight];
  }

  if (borderRadius.topRight === borderRadius.bottomLeft) {
    return [
      borderRadius.topLeft,
      borderRadius.topRight,
      borderRadius.bottomRight,
    ];
  }

  return [
    borderRadius.topLeft,
    borderRadius.topRight,
    borderRadius.bottomRight,
    borderRadius.bottomLeft,
  ];
}
