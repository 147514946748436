// @flow
import * as React from "react";
import { connect } from "react-redux";
import Head from "core/components/Head";
import Media from "core/components/Media";
import ProjectActivities from "core/components/ProjectActivities";
import { BranchesFetchRequest } from "core/requests/branches";
import type { State, Dispatch, ReactRouterLocation } from "core/types";

type OwnProps = {|
  location: ReactRouterLocation,
  params: { projectId: string },
|};

type DispatchProps = {|
  onMount: () => void,
|};

type Props = {
  ...OwnProps,
  ...DispatchProps,
};

class ProjectActivityLoader extends React.Component<Props> {
  componentDidMount() {
    this.props.onMount();
  }

  render() {
    return (
      <Media desktop>
        {(desktop) => (
          <React.Fragment>
            <Head>
              <title>Activity</title>
            </Head>
            <ProjectActivities
              mobile={!desktop}
              params={this.props.params}
              location={this.props.location}
            />
          </React.Fragment>
        )}
      </Media>
    );
  }
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { projectId } = props.params;

  return {
    onMount: () => {
      dispatch(
        BranchesFetchRequest.perform({
          params: {
            projectId,
            filter: "active",
          },
        })
      );
    },
  };
}

export default connect<Props, OwnProps, _, DispatchProps, State, Dispatch>(
  null,
  mapDispatchToProps
)(ProjectActivityLoader);
