// @flow
import window from "core/global/window";
import { isProductionDomain } from "core/lib/environment";
import { isDesktop } from "core/lib/platform";
import { query, shareLinkPath } from "core/lib/routes";
import type { ShareLink, InputShare as InputShareLink } from "core/types";

export function isPublic(shareLink: ?ShareLink): boolean %checks {
  return !!(shareLink && shareLink.options.public);
}

export function isCollection(shareLink: ?ShareLink): boolean %checks {
  return !!shareLink && shareLink.kind === "collection";
}

export function isLayer(shareLink: ?ShareLink): boolean %checks {
  return !!shareLink && shareLink.kind === "layer";
}

export function isPublicCollection(shareLink: ?ShareLink): boolean %checks {
  return isPublic(shareLink) && isCollection(shareLink);
}

export function isPublicLayer(shareLink: ?ShareLink): boolean %checks {
  return isPublic(shareLink) && isLayer(shareLink);
}

export function inspectEnabled(shareLink: ?ShareLink): boolean %checks {
  return !!(shareLink && shareLink.options.inspectEnabled);
}

export function historyEnabled(shareLink: ?ShareLink): boolean %checks {
  return !!(shareLink && shareLink.options.historyEnabled);
}

export function assembleShareLinkUrl(
  shareLink: ?ShareLink,
  options: any
): string {
  if (!shareLink) {
    return ""; // TODO: Avoid calling assembleShareLinkUrl without share
  }

  if (isProductionDomain || isDesktop) {
    return `${shareLink.url}${query(options)}`;
  } else {
    const url = shareLinkPath(shareLink.id, options).pathname;
    return `${window.location.protocol}//${
      window.location.hostname
    }${url}${query(options)}`;
  }
}

export function publicOptionsVisible(inputShareLink: ?InputShareLink): boolean {
  if (!inputShareLink) {
    return false;
  }

  return true;
}
