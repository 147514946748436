// @flow
/* global window,HTMLElement */
import classnames from "classnames";
import find from "lodash/find";
import get from "lodash/get";
import * as React from "react";
import ReactDOM from "react-dom";
import Input from "core/components/Input";
import InputSelectCountry from "core/components/InputSelectCountry";
import InputSelectRegion from "core/components/InputSelectRegion";
import InputWrapper from "core/components/InputWrapper";
import Note from "core/components/Note";
import Spinner from "core/components/Spinner";
import * as recurly from "core/lib/recurly";
import { termsOfServiceUrl, trialAgreementUrl } from "core/lib/urls";
import type { RecurlyError } from "core/types";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  submitText?: string,
  children?: any,
  agreeToTrialAgreement?: boolean,
  agreeToTermsOfService?: boolean,
  on3DSecureChallengeError: (error: Error) => void,
  on3DSecureChallengeCompleted: (
    actionResultToken: string,
    accountCode: ?string
  ) => void,
|};

export type StateProps = {|
  error: ?RecurlyError,
  threeDSToken: ?string,
  accountCode: ?string,
|};

export type DispatchProps = {|
  dispatch3DSChallengeError: (error: Error) => void,
|};

export type Props = {|
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
|};

type State = {
  country: string,
  region: string,
};

class RecurlyBillingInfoForm extends React.Component<Props, State> {
  static defaultProps = {
    agreeToTrialAgreement: false,
    agreeToTermsOfService: true,
  };

  firstName: ?Input;
  lastName: ?Input;
  threeDSchallenge = React.createRef<HTMLElement>();

  state = {
    country: "",
    region: "",
  };

  componentDidMount() {
    this.confgureRecurly();
  }

  confgureRecurly() {
    const input = this.firstNameInput();
    const fontStyle = input ? window.getComputedStyle(input) : {};

    recurly.configure(fontStyle);
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.threeDSToken) {
      this.initThreeDSChallendge();
    } else if (!this.props.threeDSToken || this.props.error) {
      this.confgureRecurly();
    }
  }

  selectCountry = (countryCode: string) => {
    this.setState({ country: countryCode });
  };

  selectRegion = (region: string) => {
    this.setState({ region });
  };

  firstNameInput(): ?HTMLInputElement {
    const inputWrapper = ReactDOM.findDOMNode(this.firstName);

    if (inputWrapper instanceof HTMLElement) {
      const input = inputWrapper.querySelector("input");
      if (input instanceof HTMLInputElement) {
        return input;
      }
    }

    return undefined;
  }

  getErrorForInput = (field: string) => {
    if (this.props.error) {
      if (get(this.props, "error.fields", []).includes(field)) {
        if (["card", "number", "month", "year", "cvv"].includes(field)) {
          // combined card field gives detailed errors set
          const details = get(this.props, "error.details", []);
          const numberError = find(details, { field });
          if (numberError) {
            const fieldTitle = ["month", "year"].includes(field)
              ? "expiration date"
              : field;
            const message = numberError.messages.join(", ");
            return field === "card" ? message : `${fieldTitle} ${message}`;
          }
          return "Invalid";
        } else {
          return get(this.props, "error.message", "Invalid");
        }
      }
    }
  };

  initThreeDSChallendge() {
    if (!this.props.threeDSToken || !this.threeDSchallenge.current) {
      return;
    }

    const threeDSecure = recurly.initThreeDSecure(this.props.threeDSToken);

    threeDSecure.on("error", (err) => {
      threeDSecure.remove();
      this.props.dispatch3DSChallengeError(err);

      if (this.props.on3DSecureChallengeError) {
        this.props.on3DSecureChallengeError(err);
      }
    });

    threeDSecure.on("token", (resultToken) => {
      threeDSecure.remove();
      if (this.props.on3DSecureChallengeCompleted) {
        this.props.on3DSecureChallengeCompleted(
          resultToken.id,
          this.props.accountCode
        );
      }
    });

    threeDSecure.attach(this.threeDSchallenge.current);
  }

  render() {
    const err = this.getErrorForInput;
    const cardError =
      err("card") || err("number") || err("month") || err("year") || err("cvv");
    const {
      submitText = "Confirm Subscription",
      agreeToTermsOfService,
      agreeToTrialAgreement,
    } = this.props;
    const { country, region } = this.state;

    if (this.props.threeDSToken && !this.props.error) {
      return (
        <div className={style.threeDSContainer}>
          <div className={style.spinner}>
            <Spinner />
          </div>
          <div ref={this.threeDSchallenge} className={style.threeDSchallenge} />
        </div>
      );
    }

    return (
      <div className={style.scroll}>
        {this.props.children}
        <div className={style.inputs}>
          <InputWrapper label="Cardholder name">
            <div className={classnames(style.splitInputs, style.nameInputs)}>
              <Input
                id="firstName"
                autoComplete="cc-given-name"
                type="text"
                data-recurly="first_name"
                ref={(ref) => (this.firstName = ref)}
                placeholder="First name"
                wrapperClass={style.firstNameInput}
                error={err("first_name")}
                required
              />
              <Input
                id="lastName"
                autoComplete="cc-family-name"
                type="text"
                data-recurly="last_name"
                ref={(ref) => (this.lastName = ref)}
                placeholder="Last name"
                wrapperClass={style.lastNameInput}
                error={err("last_name")}
                required
              />
            </div>
          </InputWrapper>
          <InputWrapper label="Card number" error={cardError}>
            <div
              data-recurly="card"
              className={classnames(style.input, {
                [style.withError]: cardError,
              })}
            />
          </InputWrapper>

          <div className={style.splitInputs}>
            <InputSelectCountry
              data-recurly="country"
              error={err("country")}
              label="Country"
              onChange={this.selectCountry}
              value={country}
              valueType="short"
              wrapperClass={style.countrySelect}
              required
            />
            <InputSelectRegion
              country={country}
              label="State"
              value={region}
              onChange={this.selectRegion}
              id="state"
              labelType="full"
              wrapperClass={style.stateSelect}
              data-recurly="state"
              error={err("state")}
              disabled={country === ""}
              required
            />
          </div>

          <Input
            id="street"
            autoComplete="shipping address-level1"
            type="text"
            label="Street"
            placeholder="123 Street Ave."
            data-recurly="address1"
            error={err("address1")}
            required
          />
          <div className={style.splitInputs}>
            <Input
              id="city"
              autoComplete="shipping city"
              type="text"
              label="City"
              placeholder="San Francisco"
              wrapperClass={style.cityInput}
              data-recurly="city"
              error={err("city")}
              required
            />
            <Input
              id="postalCode"
              autoComplete="shipping postal-code"
              type="text"
              label="ZIP or Postal Code"
              placeholder="94110"
              wrapperClass={style.zipInput}
              data-recurly="postal_code"
              error={err("postal_code")}
              required
            />
          </div>

          <Input
            id="vatNumber"
            data-recurly="vat_number"
            autoComplete="shipping vat-number"
            type="text"
            label="VAT Number"
            placeholder="12-3456789"
            error={err("vat_number")}
          />
          <Note className={style.note}>
            By clicking {`"${submitText}"`} you agree to{" "}
            {agreeToTermsOfService && (
              <a
                href={termsOfServiceUrl()}
                rel="noopener noreferrer"
                target="_blank"
              >
                Customer Terms of Service
              </a>
            )}
            {agreeToTermsOfService && agreeToTrialAgreement && " and "}
            {agreeToTrialAgreement && (
              <a
                href={trialAgreementUrl()}
                rel="noopener noreferrer"
                target="_blank"
              >
                Trial Agreement
              </a>
            )}
            .
          </Note>
        </div>
        <input type="hidden" name="recurly-token" data-recurly="token" />
      </div>
    );
  }
}

export default connector(RecurlyBillingInfoForm);
