// @flow
import * as Abstract from "abstract-sdk";
import invariant from "invariant";
import { entitiesDeleted } from "core/actions/entities";
import Queue from "core/lib/queue";
import * as Branch from "core/models/branch";
import {
  BranchStatusFetchRequest,
  BranchRestrictionsFetchRequest,
  BranchUpdateRequest,
} from "core/requests/branches";
import { getBranch } from "core/selectors/branches";
import { mergeRestrictionsEnabled } from "core/selectors/features";
import type { BranchEditableAttributes, ThunkAction } from "core/types";

export function clearBranchStatus(
  params: Abstract.BranchDescriptor
): ThunkAction {
  return (dispatch) => {
    const branchStatusId = Branch.uniqueId(params);
    dispatch(
      entitiesDeleted({
        branchStatus: [branchStatusId],
        policies: [branchStatusId],
      })
    );
    dispatch(
      BranchStatusFetchRequest.clear({
        projectId: params.projectId,
        branchId: params.branchId,
      })
    );
  };
}

export function fetchBranchStatus(
  projectId: string,
  branchId: string,
  parentId?: string
): ThunkAction {
  return BranchStatusFetchRequest.perform({
    params: { projectId, branchId, parentId },
  });
}

export function loadMasterBranchRestrictions(projectId: string): ThunkAction {
  return (dispatch, getState) => {
    if (!mergeRestrictionsEnabled(getState(), projectId)) {
      return;
    }

    return dispatch(
      fetchBranchRestrictions(projectId, Branch.BRANCH_ID_MASTER)
    );
  };
}

export function loadParentBranchRestrictions(
  projectId: string,
  branchId: string
): ThunkAction {
  return (dispatch, getState) => {
    if (!mergeRestrictionsEnabled(getState(), projectId)) {
      return;
    }

    const branch = getBranch(getState(), { projectId, branchId });
    if (branch && branch.parent) {
      dispatch(fetchBranchRestrictions(projectId, branch.parent));
    }
  };
}

export function fetchBranchRestrictions(
  projectId: string,
  branchId: string
): ThunkAction {
  return BranchRestrictionsFetchRequest.perform({
    params: { projectId, branchId },
    force: false,
  });
}

export function updateBranch(
  projectId: string,
  branchId: string,
  attributes: BranchEditableAttributes,
  options?: { onError: () => void } = {}
): ThunkAction {
  return async (dispatch, getState) => {
    const branch = getBranch(getState(), { projectId, branchId });
    invariant(branch, "branch must be loaded to update");

    const description =
      attributes.description === ""
        ? ""
        : attributes.description || branch.description;

    Queue.runJob(projectId, {
      name: `update branch`,
      method: () => {
        return dispatch(
          BranchUpdateRequest.perform({
            params: {
              projectId,
              branchId,
              name: attributes.name || branch.name,
              description,
              status: attributes.status || branch.status,
              options,
            },
          })
        );
      },
    });
  };
}

export function changeBranchStatus(
  projectId: string,
  branchId: string,
  status: string,
  options?: { onError: () => void } = {}
): ThunkAction {
  return async (dispatch, getState) => {
    dispatch(
      updateBranch(
        projectId,
        branchId,
        {
          status,
        },
        options
      )
    );
  };
}
