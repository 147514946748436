// @flow
import classnames from "classnames";
import * as React from "react";
import StatusBadge from "core/components/ChangeStatusBadge";
import Icon from "core/components/Icon";
import LayerIcon from "core/components/LayerIcon";
import Preview from "core/components/Preview";
import TextHighlight from "core/components/TextHighlight";
import * as Browser from "core/lib/browser";
import { V3Link as Link } from "core/lib/router";
import * as Change from "core/models/change";
import type { Layer, LocationDescriptor } from "core/types";
import style from "./style.scss";

type Props = {
  to?: LocationDescriptor,
  onClick?: (SyntheticMouseEvent<>) => void,
  children?: React.Node,
  className?: string,
  commentCount: number,
  highlight?: string,
  fromLibrary?: boolean,
  layerName: string,
  fileType?: string,
  status?: string,
  selected?: boolean,
  isSelecting?: boolean,
  onSelect?: (SyntheticMouseEvent<>) => void,
  layer: ?Layer,
  extras?: React.Node,
  alwaysShowExtras?: boolean,
  qaSelector?: string,
  onContextMenu?: () => void,
};

function Wrapper(props: {
  className: string,
  isSelecting?: boolean,
  onClick?: (SyntheticMouseEvent<>) => void,
  onContextMenu?: () => void,
  onSelect?: (SyntheticMouseEvent<>) => void,
  to?: LocationDescriptor,
  children: React.Node,
  qaSelector?: string,
}) {
  // We manage focus in this component so that we can show the thumbnail's
  // controls on focus just like we do on hover. If any children of this
  // wrapper are focused, it will trigger the wrapper's `onFocus` through
  // event propagation. We track this focus in component state so that we can
  // dynamically show the hidden controls.
  const [isFocused, setIsFocused] = React.useState(false);
  const [isClicking, setIsClicking] = React.useState(false);

  return (
    <div
      className={classnames(props.className, {
        [style.focused]: isFocused,
        [style.safari]: Browser.isSafariBelow16(),
      })}
      onContextMenu={props.onContextMenu}
      // This is a fallback for collection multi-select. If we are currently
      // selecting elements, we want the whole thumbnail to be clickable to
      // toggle whether the layer is selected or not. This element doesn't really
      // need to be keyboard-accessible because it already has a keyboard
      // accessible checkmark button that is keyboard accessible. If this
      // element were able to be focused from the keyboard, it would be
      // redundant.
      onMouseDown={() => setIsClicking(true)}
      onMouseUp={() => setIsClicking(false)}
      onClick={props.isSelecting ? props.onSelect : undefined}
      onFocus={() => (isClicking ? setIsFocused(false) : setIsFocused(true))}
      onBlur={() => setIsFocused(false)}
    >
      {props.onClick || props.to ? (
        <Link
          to={props.to}
          onClick={props.onClick}
          draggable={false} // To avoid interfering with draggable parents
          className={classnames(style.link, style.hasHover, {
            [style.safari]: Browser.isSafariBelow16(),
          })}
          data-qa={props.qaSelector}
        >
          {props.children}
        </Link>
      ) : (
        props.children
      )}
    </div>
  );
}

export default function Thumbnail(props: Props) {
  const {
    children,
    className,
    commentCount = 0,
    highlight,
    layer,
    layerName,
    fileType,
    status,
    fromLibrary,
    selected,
    isSelecting,
    onClick,
    extras,
    alwaysShowExtras,
    onContextMenu,
    qaSelector,
    ...rest
  } = props;

  const isRemoved = status && Change.isRemoved(status);
  const hasStatus = status && Change.hasStatus(status);

  return (
    <Wrapper
      className={classnames(style.wrapper, className, {
        [style.selecting]: isSelecting,
        [style.selected]: selected,
        [style.removed]: isRemoved,
      })}
      {...rest}
      isSelecting={isSelecting}
      onClick={isSelecting ? undefined : onClick}
      onContextMenu={onContextMenu}
      onSelect={props.onSelect}
    >
      <div className={style.inner} data-qa={props.qaSelector}>
        <Preview
          borderRadius="borderLarge"
          className={style.preview}
          fixed={false}
          backgroundTransparent
        >
          {children}
          {extras && (
            <div
              className={classnames(style.extras, {
                [style.alwaysShowExtras]: alwaysShowExtras,
                [style.safari]: Browser.isSafariBelow16(),
              })}
            >
              {extras}
            </div>
          )}
        </Preview>

        <span className={style.line1}>
          {layer && (
            <LayerIcon
              layer={layer}
              fileType={fileType}
              className={style.line1Icon}
            />
          )}
          <span className={style.line1Text} title={layerName}>
            <TextHighlight text={layerName} highlight={highlight} />
          </span>
        </span>

        <div className={style.line2}>
          {status && hasStatus && (
            <StatusBadge
              className={style.statusBadge}
              fromLibrary={fromLibrary}
              status={status}
            />
          )}
          {!!commentCount && !isRemoved && (
            <span className={style.comments}>
              {hasStatus && <span className={style.dot}>·</span>}
              <Icon type="comment-new-small" className={style.commentsIcon} />
              <span className={style.commentCount}>{commentCount}</span>
            </span>
          )}
        </div>
      </div>
    </Wrapper>
  );
}
