// @flow
import classnames from "classnames";
import invariant from "invariant";
import * as React from "react";
import AuthedImage from "core/components/AuthedImage";
import ColorSwatch from "core/components/ColorSwatch";
import Flex from "core/components/Flex";
import Popover from "core/components/Popover";
import formatNumber from "core/lib/formatNumber";
import type {
  LayerBlendMode,
  LayerBorder,
  LayerBorderPosition,
  LayerBorderRadius,
  LayerColor,
  LayerFill,
  LayerShadow,
  LayerGradientStop,
  LayerGradientTypeLinear,
  LayerGradientTypeRadial,
  LayerGradientTypeAngular,
  LayerFillPattern,
  LayerFillTypeSolid,
  LayerFillTypeGradient,
  LayerFillTypeNoise,
  LayerFillTypePattern,
  LayerFillPatternTypeTile,
  LayerFillPatternTypeFill,
  LayerFillPatternTypeStretch,
  LayerFillPatternTypeFit,
  LayerHorizontalAlignment,
  LayerListStyle,
  LayerTextStyle,
  LayerTextTransform,
  LayerTextDecoration,
} from "core/types";
import formatColor, { type ColorFormat } from "./formatColor";
import shorthandBorderRadius from "./shorthandBorderRadius";
import style from "./style.scss";

const LABEL_HEX_COLOR_FORMATS = ["hex6-rgb", "uicolor-swift", "uicolor-objc"];
const BORDER_POSITION_LABELS = ["Center", "Inside", "Outside"];
const TEXT_TRANSFORM_LABELS = ["None", "Uppercase", "Lowercase"];

const LIST_TYPE_LABELS = {
  none: "None",
  disc: "Disc",
  numbered: "Numbered",
};

const BLEND_MODE_LABELS = [
  "Normal",
  "Darken",
  "Multiply",
  "Color Burn",
  "Lighten",
  "Screen",
  "Add",
  "Overlay",
  "Soft Light",
  "Hard Light",
  "Difference",
  "Exclusion",
  "Hue",
  "Saturation",
  "Color",
  "Luminosity",
];

const HORIZONTAL_ALIGNMENT_LABELS = [
  "Left",
  "Right",
  "Center",
  "Justify",
  "Natural",
];

const TEXT_DECORATION_LABELS = {
  underline: "Underline",
  strikethrough: "Strikethrough",
};

const TEXT_DECORATION_CODE = {
  underline: "underline",
  strikethrough: "line-through",
};

const FONT_WEIGHTS: { [name: string]: ?number } = {
  hairline: 100, // usually 50
  extrathin: 100, // usually 50
  ultrathin: 100, // usually 50
  thin: 100,
  extralight: 200,
  ultralight: 200,
  light: 300,
  book: 300,
  normal: 400,
  regular: 400,
  roman: 400,
  standard: 400,
  plain: 400,
  medium: 500,
  demi: 500,
  semi: 500,
  semibold: 600,
  semibld: 600,
  demibold: 600,
  demibld: 600,
  bold: 700,
  bld: 700,
  extrabold: 800,
  extrabld: 800,
  ultrabold: 800,
  ultrabld: 800,
  black: 900,
  heavy: 900,
  fat: 900,
  poster: 900,
  extrablack: 900, // usually 950
  extraheavy: 900, // usually 950
  extrafat: 900, // usually 950
  ultrablack: 900, // usually 950
  ultraheavy: 900, // usually 950
  ultrafat: 900, // usually 950
};

const SOLID_FILL: LayerFillTypeSolid = 0;
const GRADIENT_FILL: LayerFillTypeGradient = 1;
const PATTERN_FILL: LayerFillTypePattern = 4;
const NOISE_FILL: LayerFillTypeNoise = 5;

const LINEAR_GRADIENT: LayerGradientTypeLinear = 0;
const RADIAL_GRADIENT: LayerGradientTypeRadial = 1;
const ANGULAR_GRADIENT: LayerGradientTypeAngular = 2;

const TILE_PATTERN: LayerFillPatternTypeTile = 0;
const FILL_PATTERN: LayerFillPatternTypeFill = 1;
const STRETCH_PATTERN: LayerFillPatternTypeStretch = 2;
const FIT_PATTERN: LayerFillPatternTypeFit = 3;

const TEXT_NATURAL_ALIGNMENT: LayerHorizontalAlignment = 4;
const BLACK_COLOR: LayerColor = {
  components: {
    red: 0,
    green: 0,
    blue: 0,
    alpha: 1,
  },
};

type Point = [number, number];

function linearAngle(from: Point, to: Point) {
  return (Math.atan2(from[1] - to[1], from[0] - to[0]) * 180) / Math.PI - 90;
}

function adjustGradientStopOpacity(
  gradientStops: LayerGradientStop[],
  fillOpacity: number
) {
  return gradientStops.map((gradientStop) => ({
    ...gradientStop,
    color: {
      ...gradientStop.color,
      components: {
        ...gradientStop.color.components,
        alpha: gradientStop.color.components.alpha * (fillOpacity / 100),
      },
    },
  }));
}

export type UnitLabel = "px" | "dp" | "pt" | "";

export type FormatterOptions = {
  unitLabel?: UnitLabel,
  colorFormat?: ColorFormat,
};

export default class Formatter {
  options: FormatterOptions;

  static cssFormatter = new Formatter({
    unitLabel: "px",
    colorFormat: "rgb",
  });

  constructor(options: FormatterOptions = {}) {
    this.options = options;
  }

  string = {
    label: (string: string) => string,
    code: (string: string) => (/\s+/.test(string) ? `"${string}"` : string),
  };

  unit = {
    code: (value: number) =>
      formatNumber(value) + (this.options.unitLabel || ""),
    label: (value: number) =>
      formatNumber(value) + (this.options.unitLabel || ""),
  };

  degrees = {
    // Sketch rounds degrees on display
    label: (value: number) => `${Math.round(value)}º`,
  };

  percentage = {
    // Sketch rounds percentages on display
    code: (value: number) => `${Math.round(value)}%`,
    label: (value: number) => `${Math.round(value)}%`,
  };

  blendMode = {
    label: (blendMode: LayerBlendMode) => BLEND_MODE_LABELS[blendMode],
  };

  alignment = {
    label: (alignment?: LayerHorizontalAlignment) =>
      alignment !== undefined
        ? HORIZONTAL_ALIGNMENT_LABELS[alignment]
        : "Natural",
    code: (alignment?: LayerHorizontalAlignment) =>
      alignment === undefined || alignment === TEXT_NATURAL_ALIGNMENT
        ? ""
        : HORIZONTAL_ALIGNMENT_LABELS[alignment].toLowerCase(),
  };

  border = {
    code: (border: LayerBorder) => {
      const parts = [this.unit.code(border.thickness), "solid"];

      if (
        border.color &&
        border.color.components.red !== "0" &&
        border.color.components.green !== "0" &&
        border.color.components.blue !== "0"
      ) {
        parts.push(this.color.code(border.color));
      }

      return parts.join(" ");
    },
  };

  borderPosition = {
    label: (borderPosition: LayerBorderPosition) =>
      BORDER_POSITION_LABELS[borderPosition],
  };

  borderRadius = {
    code: (borderRadius: LayerBorderRadius) =>
      shorthandBorderRadius(borderRadius).map(this.unit.code).join(" "),
    label: (borderRadius: LayerBorderRadius) =>
      shorthandBorderRadius(borderRadius).map(this.unit.label).join(" "),
  };

  color = {
    code: (layerColor: LayerColor = BLACK_COLOR) =>
      formatColor(layerColor, this.options.colorFormat),
    label: (layerColor: LayerColor = BLACK_COLOR) => {
      const displayHex6 = LABEL_HEX_COLOR_FORMATS.includes(
        this.options.colorFormat
      );

      return (
        <span className={style.valueContainer}>
          <div className={style.colorSwatch}>
            <ColorSwatch
              value={Formatter.cssFormatter.color.code(layerColor)}
              clipboardValue={this.color.code(layerColor)}
              size={16}
            />
          </div>
          <div className={classnames(style.value, style.color)}>
            {formatColor(
              layerColor,
              displayHex6 ? "hex6-rgb" : this.options.colorFormat
            )}
            {/* Display alpha for hex6 color formats with alpha */}
            {layerColor.components.alpha !== 1 && displayHex6 && (
              <React.Fragment>
                , {this.percentage.label(layerColor.components.alpha * 100)}
              </React.Fragment>
            )}
          </div>
        </span>
      );
    },
  };

  // Create aliases
  backgroundColor = this.color;
  textColor = this.color;
  borderColor = this.color;
  innerShadowColor = this.color;
  outerShadowColor = this.color;

  colorStop = {
    code: (layerColor: LayerColor) =>
      formatColor(layerColor, this.options.colorFormat),
    label: (layerColor: LayerColor) => {
      const displayHex6 = LABEL_HEX_COLOR_FORMATS.includes(
        this.options.colorFormat
      );

      return (
        <Flex align="flex-end">
          <div className={style.colorSwatch}>
            <ColorSwatch
              value={Formatter.cssFormatter.color.code(layerColor)}
              clipboardValue={this.color.code(layerColor)}
              size={16}
            />
          </div>
          <div className={classnames(style.value, style.color)}>
            {formatColor(
              layerColor,
              displayHex6 ? "hex6-rgb" : this.options.colorFormat
            )}
          </div>
        </Flex>
      );
    },
  };

  gradientStops = {
    code: (stops: LayerGradientStop[]) => {
      return stops
        .map(
          (stop) =>
            `${this.color.code(stop.color)} ${this.gradientPosition.code(
              stop.position
            )}`
        )
        .join(", ");
    },
  };

  gradientPosition = {
    code: (position: number) => {
      return this.percentage.code(position * 100);
    },
    label: (position: number) => {
      return (
        <Flex>
          <div className={style.gradientPosition}>
            <div className={style.value}>
              {this.gradientPosition.code(position)}
            </div>
          </div>
        </Flex>
      );
    },
  };

  patternFill = {
    label: (fill: LayerFillPattern) => {
      switch (fill.patternFillType) {
        case TILE_PATTERN: {
          return "Tile Pattern";
        }
        case FILL_PATTERN: {
          return "Fill Pattern";
        }
        case STRETCH_PATTERN: {
          return "Stretch Pattern";
        }
        case FIT_PATTERN: {
          return "Fit Pattern";
        }
        default: {
          return "";
        }
      }
    },
    code: (fill: LayerFillPattern) => {
      switch (fill.patternFillType) {
        case TILE_PATTERN: {
          // TODO: Need image dimensions to handle scale (patternWidth, patternHeight)
          return `url("${fill.imageUrl}") top left / ${this.unit.code(
            fill.patternTileScale * (fill.patternWidth || 100)
          )} ${this.unit.code(
            fill.patternTileScale * (fill.patternHeight || 100)
          )}`;
        }
        case FILL_PATTERN: {
          return `url("${fill.imageUrl}") top left / contain`;
        }
        case STRETCH_PATTERN: {
          return `url("${fill.imageUrl}") top left / cover`;
        }
        case FIT_PATTERN: {
          return `url("${fill.imageUrl}") top left / fit`;
        }
        default: {
          return "";
        }
      }
    },
  };

  fill = {
    code: (fill: LayerFill) => {
      switch (fill.fillType) {
        case SOLID_FILL: {
          return this.color.code(fill.color);
        }
        case GRADIENT_FILL: {
          switch (fill.gradient.gradientType) {
            case LINEAR_GRADIENT:
            case ANGULAR_GRADIENT: {
              return `linear-gradient(${linearAngle(
                fill.gradient.from,
                fill.gradient.to
              )}deg, ${this.gradientStops.code(
                adjustGradientStopOpacity(fill.gradient.stops, fill.opacity)
              )})`;
            }
            case RADIAL_GRADIENT: {
              return `radial-gradient(ellipse 100% ${this.percentage.code(
                fill.gradient.ellipseLength * 100
              )} at ${this.percentage.code(
                fill.gradient.from[0] * 100
              )} ${this.percentage.code(
                fill.gradient.from[1] * 100
              )}, ${this.gradientStops.code(
                adjustGradientStopOpacity(fill.gradient.stops, fill.opacity)
              )})`;
            }
            default: {
              return "";
            }
          }
        }
        default: {
          return "";
        }
      }
    },
    label: (fill: LayerFill) => {
      switch (fill.fillType) {
        case SOLID_FILL: {
          return this.color.label(fill.color);
        }
        case GRADIENT_FILL: {
          switch (fill.gradient.gradientType) {
            case LINEAR_GRADIENT:
            case ANGULAR_GRADIENT: {
              return (
                <Flex>
                  <div className={style.colorSwatch}>
                    <ColorSwatch
                      value={`linear-gradient(${linearAngle(
                        fill.gradient.from,
                        fill.gradient.to
                      )}deg, ${this.gradientStops.code(
                        adjustGradientStopOpacity(
                          fill.gradient.stops,
                          fill.opacity
                        )
                      )})`}
                      size={16}
                    />
                  </div>
                  <div className={style.value}>
                    {fill.gradient.gradientType === LINEAR_GRADIENT
                      ? "Linear Gradient"
                      : "Angular Gradient"}
                  </div>
                </Flex>
              );
            }
            case RADIAL_GRADIENT: {
              return (
                <Flex>
                  <div className={style.colorSwatch}>
                    <ColorSwatch
                      value={`radial-gradient(ellipse 100% ${this.percentage.label(
                        fill.gradient.ellipseLength * 100
                      )} at ${this.percentage.label(
                        fill.gradient.from[0] * 100
                      )} ${this.percentage.label(
                        fill.gradient.from[1] * 100
                      )}, ${this.gradientStops.code(
                        adjustGradientStopOpacity(
                          fill.gradient.stops,
                          fill.opacity
                        )
                      )})`}
                      size={16}
                    />
                  </div>
                  <div className={style.value}>Radial Gradient</div>
                </Flex>
              );
            }
            default: {
              return "Unknown Gradient";
            }
          }
        }
        case PATTERN_FILL: {
          return (
            <Flex>
              <AuthedImage src={fill.imageUrl}>
                {(authedSrc, error) => (
                  <Popover
                    disabled={!error}
                    label="Could not load preview for fill"
                    placement="bottom"
                  >
                    {(_, popoverRef, popoverHandlers) => (
                      <div
                        className={style.colorSwatch}
                        ref={popoverRef}
                        {...popoverHandlers}
                      >
                        <ColorSwatch
                          title={authedSrc ? "Pattern Fill" : ""}
                          copyToClipboard={authedSrc !== undefined && !error}
                          value={
                            error
                              ? "white repeating-linear-gradient(45deg, rgba(255, 0, 0, 0.5), rgba(255, 0, 0, 0.5) 1px, transparent 1px, transparent 7px)"
                              : authedSrc
                              ? `url("${authedSrc}") top left / contain`
                              : undefined
                          }
                          size={16}
                        />
                      </div>
                    )}
                  </Popover>
                )}
              </AuthedImage>
              <div className={style.value}>{this.patternFill.label(fill)}</div>
            </Flex>
          );
        }
        case NOISE_FILL: {
          return "Noise Fill";
        }
        default: {
          return "Unknown Fill";
        }
      }
    },
  };

  styleName = {
    code: (styleName?: string) => {
      invariant(styleName, "Style name visually hidden when undefined");
      return styleName.split("/").pop();
    },
    label: (styleName?: string) => {
      invariant(styleName, "Style name visually hidden when undefined");
      const paths = styleName.split("/");
      const styleNameBase = paths.pop();

      return (
        <Flex>
          <div className={style.styleNamePath}>{paths.join("/")}</div>
          <div className={style.styleNameRight}>
            {paths.length > 0 && (
              <span className={style.styleNameSlash}>/</span>
            )}
            <div className={classnames(style.value, style.styleNameBase)}>
              {styleNameBase}
            </div>
          </div>
        </Flex>
      );
    },
  };

  textTransform = {
    label: (textTransform?: LayerTextTransform) =>
      TEXT_TRANSFORM_LABELS[textTransform || 0],
    code: (textTransform?: LayerTextTransform) =>
      TEXT_TRANSFORM_LABELS[textTransform || 0].toLowerCase(),
  };

  textDecoration = {
    label: (textDecoration?: LayerTextDecoration) =>
      textDecoration ? TEXT_DECORATION_LABELS[textDecoration.line] : "None",
    code: (textDecoration?: LayerTextDecoration) => {
      if (!textDecoration) {
        return "none";
      }

      return `${TEXT_DECORATION_CODE[textDecoration.line] || "none"} ${
        textDecoration.style === "solid" ? "" : textDecoration.style
      }`.trim();
    },
  };

  listStyle = {
    label: (listStyle?: LayerListStyle) =>
      LIST_TYPE_LABELS[listStyle || "none"],
  };

  fontWeight = {
    label: (fontWeight: string) => fontWeight,
    code: (fontWeight?: string) => {
      if (!fontWeight) {
        return "normal";
      }

      const key = fontWeight
        .toLowerCase()
        .replace(/[^a-z0-9]+|italic|oblique/g, "");
      const number = FONT_WEIGHTS[key];

      if (number === 400 || key === "") {
        return "normal";
      }

      if (number === 700) {
        return "bold";
      }

      if (number) {
        return number.toString();
      }

      return key;
    },
  };

  fontStyle = {
    label: (fontStyle: string[]) => fontStyle[0],
    code: (fontStyle: string[]) => fontStyle[0],
  };

  fontName = {
    label: (fontName?: string) => (fontName ? fontName.replace("-", " ") : ""),
    code: (fontName?: string) => (fontName ? this.string.code(fontName) : ""),
  };

  fontSize = {
    code: (value?: number) => (value ? this.unit.code(value) : ""),
    label: (value?: number) =>
      value ? (
        value
      ) : (
        <Popover label="Font size is undefined">
          <div className={style.value}>?</div>
        </Popover>
      ),
  };

  font = {
    code: (text?: LayerTextStyle) => {
      if (!text || !text.fontName) {
        return "";
      }
      const size = text.fontSize;
      if (size === undefined) {
        return "";
      }

      const parts = text.fontName.split("-");
      const weight = parts[1];

      return `${this.fontWeight.code(weight)} normal ${this.unit.code(
        size
      )} ${this.fontName.code(text.fontName)}`;
    },
  };

  letterSpacing = {
    label: (letterSpacing?: number) =>
      letterSpacing === undefined ? "Auto" : this.unit.label(letterSpacing),
    code: (letterSpacing?: number) =>
      letterSpacing === undefined ? "auto" : this.unit.code(letterSpacing),
  };

  paragraphSpacing = {
    label: (paragraphSpacing?: number) =>
      paragraphSpacing === undefined
        ? "Auto"
        : this.unit.label(paragraphSpacing),
    code: (paragraphSpacing?: number) =>
      paragraphSpacing === undefined
        ? "auto"
        : this.unit.code(paragraphSpacing),
  };

  lineHeight = {
    label: (lineHeight?: number) =>
      lineHeight === undefined ? "Normal" : this.unit.label(lineHeight),
    code: (lineHeight?: number) =>
      lineHeight === undefined ? "normal" : this.unit.code(lineHeight),
  };

  outerShadow = {
    code: (shadow: LayerShadow) =>
      [
        this.unit.code(shadow.x),
        this.unit.code(shadow.y),
        this.unit.code(shadow.blurRadius),
        this.unit.code(shadow.spread),
        this.color.code(shadow.color),
      ].join(" "),
  };

  innerShadow = {
    code: (shadow: LayerShadow) => `inset ${this.outerShadow.code(shadow)}`,
  };

  textContent = {
    label: (textContent?: string) => (
      <div className={classnames(style.value, style.textContent)}>
        {textContent || ""}
      </div>
    ),
    code: (textContent?: string) => textContent || "",
  };

  pre = {
    label: (textContent: string) => (
      <pre className={classnames(style.value, style.pre)}>{textContent}</pre>
    ),
    code: (textContent: string) => textContent,
  };

  alpha = {
    label: (alpha: number = 1) => `${formatNumber(alpha * 100)}%`,
  };
}
