import * as React from "react";
import CoreButton from "core/components/Button";
import CoreInput from "core/components/Input";
import CoreNote from "core/components/Note";
import style from "./style.scss";

export const Wrapper = (props) => <div className={style.wrapper} {...props} />;

export const Header = (props) => <div className={style.header} {...props} />;

export const Content = (props) => <div className={style.content} {...props} />;

export const Note = (props) => (
  <CoreNote component="div" className={style.note} {...props} />
);

export const Input = (props) => (
  <CoreInput wrapperClass={style.input} {...props} />
);

export const Button = (props) => <CoreButton large {...props} />;

export const BackButton = (props) => (
  <CoreButton
    nude
    tint
    icon="chevron-large-left"
    qaSelector="inviteUsersBackButton"
    {...props}
  />
);

export const Footer = (props) => <div className={style.footer} {...props} />;
