// @flow
import * as React from "react";
import Flex from "core/components/Flex";
import { V3Link as Link } from "core/lib/router";
import {
  branchPath,
  collectionPath,
  commitPath,
  layerLocation,
  projectPath,
} from "core/lib/routes";
import type { Notification } from "core/types";
import style from "./style.scss";

type Props = {|
  children: React.Node,
  currentUserId: string,
  notification: Notification,
|};

function commentPath(notification) {
  const payload = notification.payload;
  const projectId = notification.project.id;

  if (payload.commentLayerId) {
    return layerLocation(
      projectId,
      payload.commitBranchId,
      payload.commitSha,
      payload.commentFileId,
      payload.commentLayerId,
      notification.commentId !== null
        ? { commentId: notification.commentId }
        : undefined
    );
  }

  if (payload.commitSha) {
    return commitPath(
      projectId,
      payload.commitBranchId,
      payload.commitSha,
      notification.commentId !== null ? notification.commentId : undefined
    );
  }

  // branchPath currently does not have object params, so each argument needs to be in
  // a specific order. The 2 undefined here are just placeholders since we need to add
  // the BranchOptions.
  return branchPath(projectId, payload.commitBranchId, undefined, undefined, {
    commentId:
      notification.commentId !== null ? notification.commentId : undefined,
  });
}

export function notificationPath(
  currentUserId: string,
  notification: Notification
) {
  const reviewBranchPath =
    notification.branchId === null || notification.branchId === undefined
      ? null
      : branchPath(notification.projectId, notification.branchId);

  switch (notification.messageType) {
    case "BRANCH_MENTIONED": {
      return reviewBranchPath;
    }
    case "COLLECTION_MENTIONED": {
      return collectionPath(
        notification.payload.projectId,
        notification.payload.branchId,
        notification.payload.id
      );
    }
    case "COMMENT_CREATED": {
      return commentPath(notification);
    }
    case "COMMENT_MENTIONED": {
      return commentPath(notification);
    }
    case "COMMIT_MENTIONED": {
      return commitPath(
        notification.project.id,
        notification.payload.commitBranchId,
        notification.payload.commitSha
      );
    }
    case "PROJECT_ARCHIVED": {
      return projectPath(notification.project.id);
    }
    case "PROJECT_CREATED": {
      return projectPath(notification.project.id);
    }
    case "PROJECT_DELETED": {
      return null;
    }
    case "PROJECT_MEMBER_ADDED": {
      const subjectId = notification.payload.subjectUserId;
      const actorIsMe = subjectId === currentUserId;

      // If we have been added to a project then send to project home.
      // If someone else got added, send to the 'people' tab
      return projectPath(
        notification.project.id,
        actorIsMe ? undefined : "people"
      );
    }
    case "PROJECT_MEMBER_REMOVED": {
      const subjectId = notification.payload.subjectUserId;
      const actorIsMe = subjectId === currentUserId;

      // If we have been removed from a project, don't link to it
      // If someone else got added, send to the 'people' tab
      if (actorIsMe) {
        return null;
      }
      return projectPath(notification.project.id, "people");
    }
    case "PROJECT_UNARCHIVED": {
      return projectPath(notification.project.id);
    }
    case "PROJECT_VISIBILITY_CHANGED": {
      return projectPath(notification.project.id);
    }
    case "REVIEWER_REMOVED": {
      return reviewBranchPath;
    }
    case "REVIEW_COMPLETED": {
      return reviewBranchPath;
    }
    case "REVIEW_DISMISSED": {
      return reviewBranchPath;
    }
    case "REVIEW_REQUESTED": {
      return reviewBranchPath;
    }
    default: {
      return null;
    }
  }
}

export default function NotificationRoute(props: Props) {
  const { children, currentUserId, notification } = props;
  const path = notificationPath(currentUserId, notification);

  if (path === null) {
    return <Flex className={style.notificationLink}>{children}</Flex>;
  }

  return (
    <Link className={style.notificationLink} to={path}>
      {children}
    </Link>
  );
}
