// @flow
import invariant from "invariant";
import { connect } from "react-redux";
import { isOnline } from "abstract-di/selectors";
import { withData } from "core/components/DataLoader";
import createConnector from "core/lib/createConnector";
import { BRANCH_ID_MASTER, defaultBranch } from "core/models/branch";
import {
  BranchRestrictionsFetchRequest,
  BranchRestrictionsUpsertRequest,
} from "core/requests/branches";
import { PaginatedProjectMembershipsRequest } from "core/requests/projectMemberships";
import { getMasterBranchRestrictions } from "core/selectors/branches";
import {
  mergeRestrictionsCTAEnabled,
  canUseNewDefaultBranchName,
} from "core/selectors/features";
import { getOrganization } from "core/selectors/organizations";
import { getOrganizationPolicy } from "core/selectors/policies";
import { getProjectMembershipsForProjectByUser } from "core/selectors/projectMemberships";
import { getProject } from "core/selectors/projects";

import {
  getAnyAdminsForProject,
  getProjectUsersMap,
} from "core/selectors/users";
import type { State, Dispatch } from "core/types";
import type { OwnProps, StateProps, DispatchProps, Props } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { projectId } = props.params;
  const project = getProject(state, { projectId });
  const organizationId = project ? project.organizationId : "";
  const organization = getOrganization(state, { organizationId });
  invariant(organization, "organization must be loaded");

  const organizationPolicy = getOrganizationPolicy(state, { organizationId });
  const params = { projectId, branchId: BRANCH_ID_MASTER };

  const ctaEnabled = mergeRestrictionsCTAEnabled(state, projectId);
  const defaultBranchName = defaultBranch({
    masterToMain: canUseNewDefaultBranchName(state),
    titleCase: true,
  });

  return {
    defaultBranchName,
    isSubmitting: BranchRestrictionsUpsertRequest.isLoadingStrict(
      state,
      params
    ),
    isOffline: !isOnline(state),
    submitSucceeded: BranchRestrictionsUpsertRequest.success(state, params),
    hasError: BranchRestrictionsUpsertRequest.hasError(state, params),
    loading: BranchRestrictionsFetchRequest.isLoadingStrict(state, params),
    restrictions: getMasterBranchRestrictions(state, { projectId }),
    projectAdmins: getAnyAdminsForProject(state, { projectId }),
    projectUsers: getProjectUsersMap(state, { projectId }),
    projectRoles: getProjectMembershipsForProjectByUser(state, { projectId }),
    notPermitted:
      !!organization.isUsernameOrganization ||
      (!ctaEnabled && !organizationPolicy.manageBranchRestrictions),
    showMergeRestrictionsCTA:
      ctaEnabled && !organizationPolicy.manageBranchRestrictions,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const params = {
    projectId: props.params.projectId,
    branchId: BRANCH_ID_MASTER,
  };
  return {
    onLoad() {
      dispatch(BranchRestrictionsUpsertRequest.clear(params));
      dispatch(
        BranchRestrictionsFetchRequest.perform({
          params,
          force: false,
        })
      );
      dispatch(
        PaginatedProjectMembershipsRequest.perform({
          params: {
            projectId: props.params.projectId,
            role: "admin",
            limit: 100,
            offset: 0,
          },
        })
      );
    },
    onSubmit(form) {
      dispatch(
        BranchRestrictionsUpsertRequest.perform({
          params: { ...params, ...form },
        })
      );
    },
  };
}

export default createConnector<Props, OwnProps>(
  connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch>(
    mapStateToProps,
    mapDispatchToProps
  ),
  withData((props: Props) => props.params)
);
