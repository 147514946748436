// @flow
import { connect } from "react-redux";
import { withData } from "core/components/DataLoader";
import createConnector from "core/lib/createConnector";
import { getCurrentLocation, push } from "core/lib/location";
import * as Request from "core/models/request";
import { ShareLinkFetchRequest } from "core/requests/shareLinks";
import { getRequest } from "core/selectors/requests";
import { getShareLink } from "core/selectors/shareLinks";
import type { Dispatch, State } from "core/types";
import { resetLogin } from "web/actions/login";
import { loadSession } from "web/actions/session";
import {
  loadShareLink,
  loginAndLoadShareLink,
  requestAccessFormSubmitted,
} from "web/actions/shareLinkPage";
import {
  ConfigurationFetchRequest,
  SessionCreateRequestId,
} from "web/requests/sessions";
import { forgotPassword, signup } from "web/routeHelpers";
import { isLoggedIn } from "web/selectors/session";
import type { OwnProps, StateProps, DispatchProps, Props } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const shareId = props.params.shareId.toLowerCase();
  const shareLink = getShareLink(state, shareId);
  const shareLinkRequest = ShareLinkFetchRequest.getRequest(state, {
    shareId,
  });
  const shareLinkError = shareLinkRequest.error;
  const isLoading =
    ShareLinkFetchRequest.isLoading(state, { shareId }) ||
    ConfigurationFetchRequest.isLoadingStrict(state, {});

  return {
    isLoading,
    isLoggedIn: isLoggedIn(state),
    loginError: state.login.error,
    loginIsSubmitting: Request.isLoadingStrict(
      getRequest(state, SessionCreateRequestId)
    ),
    onCreateAccount: () => push(signup(getCurrentLocation())),
    onPasswordReset: () => push(forgotPassword()),
    shareLink,
    shareLinkError,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const shareId = props.params.shareId.toLowerCase();

  return {
    onLoad: () =>
      dispatch(async (dispatch) => {
        await dispatch(loadSession());
        dispatch(loadShareLink(shareId, props.location.query));
      }),
    onRequestAccessFormSubmit: (formId, projectId, values) => {
      dispatch(requestAccessFormSubmitted(formId, projectId, values));
    },
    onSignIn: (username: string, password: string) =>
      dispatch(
        loginAndLoadShareLink(
          shareId,
          {
            login: username,
            password: password,
          },
          {
            ...props.location.query,
          }
        )
      ),
    resetLoginError: () => dispatch(resetLogin()),
  };
}

export default createConnector<Props, OwnProps>(
  connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch>(
    mapStateToProps,
    mapDispatchToProps
  ),
  withData((props) => ({
    shareId: props.params.shareId,
    isLoggedIn: props.isLoggedIn,
  }))
);
