// @flow
/* global window document */
import { find, isEqual } from "lodash";
import { mark, stop as stopMarky } from "marky";
import uuid from "uuid";
import { trackMetrics } from "abstract-di/lib";
import anyHover from "core/lib/anyHover";
import type { AnyDescriptor, Metric } from "core/types";

export type Options = {|
  // If the window is backgrounded whilst the profiler is running then the
  // resulting metrics will be discarded and not reported.
  discardIfBackgrounded?: boolean,

  // A value between 0-1, the liklihood that the metric will be reported
  // Allows for sampling of metrics that are run very often.
  sampleRate?: number,
|};

const profilers = [];

let lastVisibilityChange = 0;
window.addEventListener("visibilitychange", () => {
  if (window.performance) {
    lastVisibilityChange = window.performance.now();
  }
});

function getSubjectId(subject: AnyDescriptor) {
  return Object.values(subject).join("-");
}

export class Profiler {
  profileId: string = uuid.v4();
  subject: AnyDescriptor;
  subjectId: string;
  meta: {};
  name: string;
  metrics: Metric[] = [];
  stopped: boolean = false;
  started: boolean = false;
  suppressed: boolean = false;
  options: $Shape<Options> = {};

  constructor(
    subject: AnyDescriptor,
    name: string,
    meta: {} = {},
    options: ?Options
  ) {
    this.subject = { ...subject };
    this.subjectId = getSubjectId(subject);
    this.name = name;
    this.meta = { ...meta };
    this.start(options);
  }

  start(options: ?Options) {
    if (this.started) {
      console.warn(`Profiler ${this.name} already started`);
      return;
    }

    if (
      options &&
      options.sampleRate !== undefined &&
      (options.sampleRate < 0 || options.sampleRate > 1)
    ) {
      throw new Error("sampleRate must be between 0 and 1");
    }

    if (options) {
      this.options = options;
    }

    mark(this._markName(this.name));
    this.started = true;
  }

  suppress() {
    this.suppressed = true;
  }

  addMeta(key: string, value: mixed) {
    this.meta[key] = value;
  }

  addError(error: string) {
    this.addMeta("error", error);
  }

  startMark(subname: string) {
    const name = this._submarkName(subname);
    mark(this._markName(name));
  }

  stopMark(subname: string) {
    const name = this._submarkName(subname);
    const metric = stopMarky(this._markName(name));
    this._addMetric(name, metric.duration);
  }

  stop() {
    if (this.stopped) {
      return;
    }

    // remove from profilers so that a new metric with the same parameters
    // can be started
    profilers.splice(profilers.indexOf(this), 1);

    // stop overall profile metric
    const metric = stopMarky(this._markName(this.name));
    this._addMetric(this.name, metric.duration);

    // add meta to all collected metrics
    this.metrics = this.metrics.map((metric) => {
      return {
        ...metric,
        ...this.meta,
        profileId: this.profileId,
        isMobile: !anyHover,
      };
    });

    this.stopped = true;

    if (
      this.options.sampleRate !== undefined &&
      this.options.sampleRate < Math.random()
    ) {
      return;
    }

    if (
      this.options.discardIfBackgrounded === true &&
      (metric.start < lastVisibilityChange || document.hidden)
    ) {
      return;
    }

    if (this.suppressed) {
      return;
    }

    trackMetrics(this.subject, this.metrics);
  }

  _addMetric(name: string, duration: number) {
    this.metrics.push({
      metric: name,
      duration: duration,
    });
  }

  _submarkName(name: string): string {
    return `${this.name}:${name}`;
  }

  _markName(name: string): string {
    return `${this.subjectId}${name}`;
  }
}

function get(subject: AnyDescriptor, name: string, meta: {}): ?Profiler {
  const subjectId = getSubjectId(subject);

  return find(profilers, (profiler) => {
    return (
      profiler.subjectId === subjectId &&
      profiler.name === name &&
      isEqual(profiler.meta, meta)
    );
  });
}

export function start(
  subject: AnyDescriptor,
  name: string,
  meta: {} = {},
  options: ?Options
): Profiler {
  const existing = get(subject, name, meta);
  if (existing) {
    existing.suppress();
    existing.stop();
    console.warn(
      `Profiler ${existing.name} was restarted whilst already running`
    );
  }

  const profiler = new Profiler(subject, name, meta, options);
  profilers.push(profiler);
  return profiler;
}
