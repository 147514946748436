// @flow
import * as React from "react";
import { Link } from "react-router-dom";
import OnboardingPage from "web/components/OnboardingPage";
import { supportTicketPath } from "web/routeHelpers";
import style from "./style.scss";

export default function InvitationError() {
  return (
    <OnboardingPage heading="Invalid invitation">
      <div className={style.center}>
        <p>
          Sorry, this invite doesn’t look valid anymore. Please ask the person
          responsible for your organization to invite you again.
        </p>
        <p>
          Go back to the <Link to="/">Abstract homepage</Link>
        </p>
        <Link
          to={supportTicketPath()}
          className={style.greyLink}
          target="_blank"
        >
          Contact Support
        </Link>
      </div>
    </OnboardingPage>
  );
}
