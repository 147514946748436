// @flow
import invariant from "invariant";
import { connect } from "react-redux";
import { signOut } from "abstract-di/actions";
import { openDevtools } from "core/actions/devtools";
import contextMenuWrapper, {
  type Props as ContextMenuWrapperProps,
} from "core/components/ContextMenu/ContextMenuWrapper";
import createConnector from "core/lib/createConnector";
import { getFeatureEnabled } from "core/selectors/features";
import type { State, Dispatch } from "core/types";
import type { OwnProps, StateProps, DispatchProps, Props } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  return {
    canUseDevtools: getFeatureEnabled(state, { feature: "devtools" }),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    openDevtools() {
      return dispatch(openDevtools());
    },
    signOut: () => {
      invariant(signOut, "Signout required for account menu");
      dispatch(signOut());
    },
  };
}

export default createConnector<
  Props,
  {|
    ...OwnProps,
    ...$Exact<ContextMenuWrapperProps>,
  |},
>(
  contextMenuWrapper,
  connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch>(
    mapStateToProps,
    mapDispatchToProps
  )
);
