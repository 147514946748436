// @flow
import React, { Component } from "react";
import Button from "core/components/Button";
import Input from "core/components/Input";
import InputSelectCountry from "core/components/InputSelectCountry";
import InputSelectRegion from "core/components/InputSelectRegion";
import { updateSubscription } from "web/api";
import InlineError from "web/components/InlineError";
import withForm from "web/containers/withForm";
import type { FormProps, Subscription } from "web/types";
import style from "./style.scss";

type TSubscription = Subscription & {
  billingInfo: {
    company?: string,
    address1?: string,
    city?: string,
    state?: string,
    zip?: string,
    country?: string,
    vatNumber?: string,
  },
};

type OwnProps = {
  organizationId: string,
  onComplete: (Subscription) => *,
  onChange?: (formId: string, values: Object) => *,
  submitText?: string,
};
type Props = {
  ...OwnProps,
  ...FormProps,
};

type State = {
  error?: string,
  isSubmitting: boolean,
  country: string,
  region: string,
};

class CompanyDetailsForm extends Component<Props, State> {
  state = {
    error: undefined,
    isSubmitting: false,
    country: this.props.form.values.country,
    region: this.props.form.values.state,
  };

  handleChange = (event: SyntheticInputEvent<>) => {
    this.props.form.onChange(this.props.form.id, {
      [event.target.name]: event.target.value || undefined,
    });
  };

  selectCountry = (countryCode: string) => {
    this.setState({ country: countryCode });
    this.props.form.onChange(this.props.form.id, {
      country: countryCode,
    });
  };

  selectRegion = (region: string) => {
    this.setState({ region });
    this.props.form.onChange(this.props.form.id, { state: region });
  };

  handleSubmit = async (ev: SyntheticEvent<>) => {
    ev.preventDefault();
    this.setState({ isSubmitting: true, error: undefined });

    let subscription;

    try {
      subscription = await updateSubscription(
        this.props.organizationId,
        this.props.form.values
      );
    } catch (err) {
      this.setState({ isSubmitting: false, error: err });
      return;
    } finally {
      this.setState({ isSubmitting: false });
    }

    this.props.onComplete(subscription);
  };

  renderSubmitComponent() {
    const { submitText = "Confirm Subscription" } = this.props;

    return (
      <div className={style.footer}>
        {!!this.state.error && <InlineError className={style.error} />}
        <Button
          type="submit"
          disabled={this.state.isSubmitting}
          large
          fullwidth
          primary
        >
          {this.state.isSubmitting ? "Submitting…" : submitText}
        </Button>
      </div>
    );
  }

  render() {
    const { form } = this.props;
    const { region, country } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className={style.inputs}>
          <Input
            name="companyName"
            autoComplete="shipping company-name"
            type="text"
            label="Company name"
            placeholder="Company, Inc."
            onChange={this.handleChange}
            defaultValue={form.values.companyName}
          />
          <InputSelectCountry
            name="country"
            label="Country"
            autoComplete="shipping country"
            onChange={this.selectCountry}
            value={country}
          />
          <Input
            name="address1"
            autoComplete="shipping address-level1"
            type="text"
            label="Street"
            placeholder="123 Street Ave."
            onChange={this.handleChange}
            defaultValue={form.values.address1}
          />
          <Input
            name="city"
            autoComplete="shipping city"
            type="text"
            label="City"
            placeholder="San Francisco"
            onChange={this.handleChange}
            defaultValue={form.values.city}
          />
          <div className={style.splitInputs}>
            <InputSelectRegion
              name="state"
              label="State"
              wrapperClass={style.stateInput}
              country={country}
              value={region}
              onChange={this.selectRegion}
              id="state"
              disabled={country === ""}
              required
            />
            <Input
              name="postalCode"
              autoComplete="shipping postal-code"
              type="text"
              label="ZIP or Postal Code"
              placeholder="94110"
              wrapperClass={style.zipInput}
              onChange={this.handleChange}
              defaultValue={form.values.postalCode}
            />
          </div>
          <Input
            name="vatNumber"
            autoComplete="shipping vat-number"
            type="text"
            label="VAT number"
            placeholder="12-3456789"
            onChange={this.handleChange}
            defaultValue={form.values.vatNumber}
          />
        </div>
        {this.renderSubmitComponent()}
      </form>
    );
  }
}

function getDefaultValues(props: { subscription: TSubscription }) {
  const { billingInfo } = props.subscription;
  return {
    companyName: billingInfo.company,
    address1: billingInfo.address1,
    city: billingInfo.city,
    country: billingInfo.country,
    state: billingInfo.state,
    postalCode: billingInfo.zip,
    vatNumber: billingInfo.vatNumber,
  };
}

export default withForm<OwnProps>(
  CompanyDetailsForm,
  (props) => `${props.organizationId}-company-details-form`,
  getDefaultValues
);
