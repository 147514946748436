// @flow
import * as React from "react";
import Button from "core/components/Button";
import InputSearch from "core/components/InputSearch";
import ListDisplayToggle from "core/components/ListDisplayToggle";
import PageTitle from "core/components/PageTitle";
import PageTitleNavigationLink from "core/components/PageTitleNavigationLink";
import { useDebounce } from "core/hooks/useDebounce";
import { replace, addQuery, removeQuery } from "core/lib/location";
import { V3Link as Link } from "core/lib/router";
import { organizationTeamsPath, organizationTeamPath } from "core/lib/routes";
import type { OrganizationTeamDescriptor, Policy, ViewType } from "core/types";

type Props = {
  id?: string,
  isGrid?: boolean,
  params: OrganizationTeamDescriptor,
  title: string,
  desktop: boolean,
  pathname: string,
  policy: Policy,
  onDialogOpen?: () => void,
  onChangeViewType?: (viewType: ViewType) => void,
};

export default function OrganizationTeamHeader(props: Props) {
  const [searchTerm, setSearchTerm] = React.useState("");
  const debouncedSearchQuery = useDebounce(searchTerm, 300);
  const { pathname, params, onChangeViewType } = props;
  React.useEffect(() => {
    if (debouncedSearchQuery) {
      replace(addQuery({ s: debouncedSearchQuery }));
    } else {
      replace(removeQuery("s"));
    }
  }, [debouncedSearchQuery]);

  const navigationLinks = [
    <PageTitleNavigationLink
      onlyActiveOnIndex
      icon="user"
      to={organizationTeamPath(props.params, "members")}
      qaSelector="organizationTeamMembersLink"
    >
      Members
    </PageTitleNavigationLink>,
  ];

  if (props.policy.listProjects) {
    navigationLinks.push(
      <PageTitleNavigationLink
        icon="project"
        to={organizationTeamPath(props.params, "projects")}
        qaSelector="organizationTeamProjectsLink"
      >
        Projects
      </PageTitleNavigationLink>
    );
  }

  if (props.policy.update) {
    navigationLinks.push(
      <PageTitleNavigationLink
        icon="slider"
        to={organizationTeamPath(props.params, "settings")}
        qaSelector="organizationTeamSettingsLink"
      >
        Settings
      </PageTitleNavigationLink>
    );
  }

  return (
    <PageTitle
      title={props.title}
      navigation={navigationLinks}
      breadcrumb={[
        <Link
          data-qa="organizationTeamsLink"
          to={organizationTeamsPath(props.params.organizationId)}
        >
          People &amp; Teams
        </Link>,
      ]}
      search={
        pathname !== organizationTeamPath(params, "settings") ? (
          <InputSearch
            name="search"
            large={!props.desktop}
            defaultValue={searchTerm}
            onChange={(event) => setSearchTerm(event.target.value)}
            placeholder="Search by name…"
            qaSelector="organizationTeamSearchInput"
          />
        ) : undefined
      }
      displayControl={
        onChangeViewType ? (
          <ListDisplayToggle
            id={props.id}
            isGrid={props.isGrid}
            onToggleList={() => onChangeViewType("list")}
            onToggleGrid={() => onChangeViewType("grid")}
          />
        ) : undefined
      }
      actions={
        props.onDialogOpen && props.policy.addMember ? (
          <Button
            icon="plus"
            onClick={props.onDialogOpen}
            primary={props.desktop}
            nude={!props.desktop}
            qaSelector="addOrganizationTeamMemberButton"
          >
            {props.desktop ? "Add Members" : undefined}
          </Button>
        ) : undefined
      }
    />
  );
}
