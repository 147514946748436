// @flow
import * as React from "react";
import { branchPath } from "core/lib/routes";
import ActivityBase from "./Base";
import Callout from "./Callout";
import type {
  ActivityBranchRenamed as TActivityBranchRenamed,
  Props,
} from "./types";

export default function ActivityBranchRenamed(
  props: Props<TActivityBranchRenamed>
) {
  const { activity } = props;
  return (
    <ActivityBase
      {...props}
      icon="branch"
      action="renamed the branch"
      subject={
        <span>
          <del>{activity.payload.previousName}</del> to{" "}
          <Callout to={branchPath(activity.projectId, activity.branchId)}>
            {activity.payload.branchName}
          </Callout>
        </span>
      }
    />
  );
}
