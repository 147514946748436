// @flow
import * as React from "react";
import { connect } from "react-redux";
import Loaded from "core/components/Loaded";
import { replace } from "core/lib/location";
import * as request from "core/models/request";
import { OrganizationsFetchRequest } from "core/requests/organizations";
import {
  getOrganization,
  getDefaultOrganization,
} from "core/selectors/organizations";
import type { ReactRouterLocation, State } from "core/types";
import { loginRequired } from "web/actions/login";
import { loadOrganizations } from "web/actions/organizations";
import { getCurrentOrganizationId } from "web/currentOrganization";
import {
  accountPath,
  organizationPath,
  newUserQueryParam,
} from "web/routeHelpers";

type OwnProps = {
  location: ReactRouterLocation,
};
type Connected = {
  newUser: boolean,
  isLoading: boolean,
  organizationId: ?string,
  requiresAuthentication: boolean,
};

type Actions = {
  onLoad: () => *,
  onUnauthorized: () => *,
};

type Props = OwnProps & Connected & Actions;

class RedirectToOrganization extends React.Component<Props> {
  componentDidMount() {
    this.props.onLoad();
    this.redirectToOrganization(this.props);
  }

  componentDidUpdate() {
    this.redirectToOrganization(this.props);
  }

  redirectToOrganization(props: Props) {
    if (props.isLoading) {
      return;
    }

    if (props.organizationId) {
      let organizationRoute = organizationPath(props.organizationId);
      if (props.newUser) {
        organizationRoute += newUserQueryParam();
      }
      replace(organizationRoute);
    } else if (props.requiresAuthentication) {
      props.onUnauthorized();
    } else {
      replace(accountPath());
    }
  }

  render() {
    return <Loaded loading />;
  }
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
function mapStateToProps(state: State, props: OwnProps): Connected {
  const organizationId = getCurrentOrganizationId();
  const organizationsRequest = OrganizationsFetchRequest.getRequest(state);
  const organization =
    (organizationId && getOrganization(state, { organizationId })) ||
    getDefaultOrganization(state);

  return {
    newUser: !!props.location.query.newUser,
    isLoading: request.isLoading(organizationsRequest),
    requiresAuthentication: request.unauthorized(organizationsRequest),
    organizationId: organization && organization.id,
  };
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default connect(mapStateToProps, {
  onLoad: loadOrganizations,
  onUnauthorized: loginRequired,
})(RedirectToOrganization);
