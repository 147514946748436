// @flow
import { CommitTypes } from "core/gitConstants";
import type { Commit, CommitType, Branch } from "core/types";

export const MESSAGE_DELIMITER = "\n\n";

export function constructMessage(title: string = "", description: string = "") {
  return [title.trim(), description.trim()].join(MESSAGE_DELIMITER).trim();
}

export function parseMessage(message: string) {
  const delimiterIndex = message.indexOf(MESSAGE_DELIMITER);

  const title =
    delimiterIndex !== -1 ? message.substring(0, delimiterIndex) : message;
  const description =
    delimiterIndex !== -1
      ? message.substring(delimiterIndex + MESSAGE_DELIMITER.length)
      : "";

  const formattedTitle = title.trim();
  const formattedDescription = description.trim();

  return { title: formattedTitle, description: formattedDescription };
}

export function userIsAuthor(commit: ?Commit, userId: string) {
  if (!commit) {
    return;
  }
  return commit.userId === userId;
}

export function type(commit: Commit, branch: ?Branch): CommitType {
  if (branch && branch.startedAtSha === commit.sha) {
    return CommitTypes.BRANCH;
  }

  // uses old logic so that we can show the correct icons for commits in
  // created in release 0.46.4 and earlier.
  // NOTE: should we think about moving this down to the git layer?
  if (commit.parents.length > 1) {
    if (commit.title.startsWith("Updated from")) {
      return CommitTypes.UPDATE;
    }
    return CommitTypes.MERGE;
  }

  return commit.type || "NORMAL";
}
