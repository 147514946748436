// @flow
import { schema, normalize } from "normalizr";
import * as Layer from "core/models/layer";
import type { LayerDataset } from "core/types";

export const layerDatasetSchema = new schema.Entity(
  "layerDatasets",
  {},
  {
    idAttribute: (layerDataset: LayerDataset) =>
      Layer.uniqueId({
        projectId: layerDataset.projectId,
        sha: layerDataset.sha,
        fileId: layerDataset.fileId,
        layerId: layerDataset.layerId,
      }),
  }
);

export function normalizeLayerDataset(response: LayerDataset) {
  return normalize(response, layerDatasetSchema);
}
