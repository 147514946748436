// @flow
import empty from "empty";
import { connect } from "react-redux";
import { isOnline } from "abstract-di/selectors";
import { showToast } from "core/actions/toasts";
import { FetchUserSuggestionsRequest } from "core/requests/projectMemberships";
import { getUsersForProject } from "core/selectors/users";
import type { Dispatch, State } from "core/types";
import type { Props, OwnProps, StateProps, DispatchProps } from "./";

const SUGGESTIONS_TO_RENDER = 20;

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const users = props.projectId
    ? getUsersForProject(state, {
        projectId: props.projectId,
      })
    : empty.array;

  return {
    users,
    isOnline: isOnline(state),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const projectId = props.projectId;

  return {
    onSearchForUsers: (search: string) => {
      if (projectId) {
        dispatch(
          FetchUserSuggestionsRequest.perform({
            params: {
              projectId,
              search,
              limit: SUGGESTIONS_TO_RENDER,
            },
          })
        );
      }
    },
    showOfflineToast: () => {
      dispatch(
        showToast({
          text: `User search results may be limited when offline.`,
        })
      );
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps, null, { forwardRef: true });
