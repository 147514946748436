// @flow
import * as Abstract from "abstract-sdk";
import apiRequest from "abstract-di/api";
import { entitiesDeleted, entitiesReceived } from "core/actions/entities";
import defineRequest from "core/requests";
import {
  normalizeWebhookDeliveriesResponse,
  normalizeWebhookEventsResponse,
  normalizeWebhooksResponse,
} from "core/schemas/webhook";
import type {
  NewWebhook,
  Webhook,
  WebhookDeliveryDescriptor,
  WebhookDescriptor,
} from "core/types";

export const WebhooksFetchRequest = defineRequest<
  Abstract.OrganizationDescriptor,
  Abstract.OrganizationDescriptor,
>({
  id: ({ organizationId }) => {
    return `get:organizations/${organizationId}/webhooks`;
  },
  perform: ({ organizationId }) => {
    return apiRequest("get", `organizations/${organizationId}/webhooks`);
  },
  onSuccess: (response, _, dispatch) => {
    const { entities } = normalizeWebhooksResponse(response);
    return dispatch(entitiesReceived(entities));
  },
});

export const WebhookEventsFetchRequest = defineRequest<
  Abstract.OrganizationDescriptor,
  Abstract.OrganizationDescriptor,
>({
  id: ({ organizationId }) => {
    return `get:organizations/${organizationId}/webhooks/events`;
  },
  perform: ({ organizationId }) => {
    return apiRequest("get", `organizations/${organizationId}/webhooks/events`);
  },
  onSuccess: (webhookEvents, _, dispatch) => {
    const { entities } = normalizeWebhookEventsResponse(webhookEvents);
    return dispatch(entitiesReceived(entities));
  },
});

export const WebhookCreateRequest = defineRequest<
  NewWebhook,
  { ...Abstract.OrganizationDescriptor },
>({
  id: ({ organizationId }) => {
    return `post:organizations/${organizationId}/webhooks/subscribe`;
  },
  perform: (subscription) => {
    return apiRequest(
      "post",
      `organizations/${subscription.organizationId}/webhooks/subscribe`,
      { subscription }
    );
  },
  onSuccess: (webhook, _, dispatch) => {
    const { entities } = normalizeWebhooksResponse([webhook]);
    return dispatch(entitiesReceived(entities));
  },
});

export const WebhookDeleteRequest = defineRequest<
  WebhookDescriptor,
  WebhookDescriptor,
>({
  id: ({ organizationId, webhookId }) => {
    return `delete:organizations/${organizationId}/webhooks/${webhookId}/unsubscribe`;
  },
  perform: ({ organizationId, webhookId }) => {
    return apiRequest(
      "delete",
      `organizations/${organizationId}/webhooks/${webhookId}/unsubscribe`
    );
  },
  onSuccess: (_, { webhookId }, dispatch) => {
    return dispatch(entitiesDeleted({ webhooks: [webhookId] }));
  },
});

export const WebhookDeliveriesFetchRequest = defineRequest<
  WebhookDescriptor,
  WebhookDescriptor,
>({
  id: ({ organizationId, webhookId }) => {
    return `get:${organizationId}/webhook/${webhookId}/deliveries`;
  },
  perform: ({ organizationId, webhookId }) => {
    return apiRequest(
      "get",
      `organizations/${organizationId}/webhooks/${webhookId}/deliveries`
    );
  },
  onSuccess: (deliveries, _, dispatch) => {
    const { entities } = normalizeWebhookDeliveriesResponse(deliveries);
    return dispatch(entitiesReceived(entities));
  },
});

export const WebhookFetchRequest = defineRequest<
  WebhookDescriptor,
  WebhookDescriptor,
>({
  id: ({ organizationId, webhookId }) => {
    return `get:${organizationId}/webhook/${webhookId}`;
  },
  perform: ({ organizationId, webhookId }) => {
    return apiRequest(
      "get",
      `organizations/${organizationId}/webhooks/${webhookId}`
    );
  },
  onSuccess: (webhook, _, dispatch) => {
    const { entities } = normalizeWebhooksResponse([webhook]);
    return dispatch(entitiesReceived(entities));
  },
});

export const WebhookTestRequest = defineRequest<
  WebhookDescriptor,
  WebhookDescriptor,
>({
  id: ({ organizationId, webhookId }) => {
    return `post:${organizationId}/webhook/${webhookId}/ping`;
  },
  perform: ({ organizationId, webhookId }) => {
    return apiRequest(
      "post",
      `organizations/${organizationId}/webhooks/${webhookId}/ping`
    );
  },
});

export const WebhookUpdateRequest = defineRequest<
  Webhook,
  { ...WebhookDescriptor },
>({
  id: ({ organizationId }) => {
    return `post:organizations/${organizationId}/webhooks/subscribe`;
  },
  perform: (subscription) => {
    return apiRequest(
      "post",
      `organizations/${subscription.organizationId}/webhooks/subscribe`,
      { subscription }
    );
  },
  onSuccess: (webhook, _, dispatch) => {
    const { entities } = normalizeWebhooksResponse([webhook]);
    return dispatch(entitiesReceived(entities));
  },
});

export const WebhookRedeliverRequest = defineRequest<
  WebhookDeliveryDescriptor,
  WebhookDeliveryDescriptor,
>({
  id: ({ deliveryId, organizationId, webhookId }) => {
    return `post:${organizationId}/webhooks/${webhookId}/deliveries/${deliveryId}/redeliver`;
  },
  perform: ({ deliveryId, organizationId, webhookId }) => {
    return apiRequest(
      "post",
      `organizations/${organizationId}/webhooks/${webhookId}/deliveries/${deliveryId}/redeliver`
    );
  },
});
