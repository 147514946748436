// @flow
import { static as Immutable } from "seamless-immutable";
import type { Action, Navigation } from "core/types";

const DEFAULT_STATE: Navigation = Immutable.from({});
const DEFAULT_ACTION = { type: "core/noop" };

export default function navigation(
  state: Navigation = DEFAULT_STATE,
  action: Action = DEFAULT_ACTION
): Navigation {
  switch (action.type) {
    case "core/FOCUSED_ELEMENT": {
      return Immutable.set(state, "activeElement", action.payload);
    }
    case "core/PRESENTATION_IDLE": {
      return Immutable.set(state, "presentationIdle", action.payload);
    }
    case "SIGNED_OUT":
      return Immutable.from({});
    default:
      return state;
  }
}
