// @flow
import * as Abstract from "abstract-sdk";
import { entitiesReceived } from "core/actions/entities";
import abstract from "core/lib/abstract";
import * as Changeset from "core/models/changeset";
import defineRequest from "core/requests";
import { normalizeChangesetResponse } from "core/schemas/changeset";
import { getTransportModeForBranch } from "core/selectors/sdk";

export type BranchChangesetParams = {|
  ...Abstract.BranchDescriptor,
  head?: string,
|};

export const BranchChangesetFetchRequest = defineRequest<
  BranchChangesetParams,
  BranchChangesetParams,
>({
  id(params) {
    return [
      "changesets",
      "branch",
      params.projectId,
      params.branchId,
      params.head,
    ]
      .filter((f) => Boolean(f))
      .join("-");
  },
  perform(params, dispatch, getState) {
    const { head, ...descriptor } = params;

    return abstract.changesets.branch(descriptor, {
      transportMode: getTransportModeForBranch(getState(), {
        projectId: descriptor.projectId,
        branchId: descriptor.branchId,
      }),
    });
  },
  onSuccess(changeset, params, dispatch) {
    const response = abstract.unwrap(changeset);
    const { entities } = normalizeChangesetResponse(response);
    dispatch(entitiesReceived(entities));
  },
  force: false,
});

export const CommitChangesetFetchRequest = defineRequest<
  Abstract.BranchCommitDescriptor,
  Abstract.BranchCommitDescriptor,
>({
  id(params) {
    return [
      "changesets",
      "commit",
      params.projectId,
      params.branchId,
      params.sha,
    ]
      .filter((f) => Boolean(f))
      .join("-");
  },
  perform(descriptor, dispatch, getState) {
    // Ensure changeset requests for working tree data always use CLI
    const isWorkingTree = Changeset.isWorkingTree(descriptor);

    return abstract.changesets.commit(descriptor, {
      transportMode: isWorkingTree
        ? ["cli"]
        : getTransportModeForBranch(getState(), {
            projectId: descriptor.projectId,
            branchId: descriptor.branchId,
          }),
    });
  },
  onSuccess(changeset, params, dispatch) {
    const response = abstract.unwrap(changeset);
    const { entities } = normalizeChangesetResponse(response);
    dispatch(entitiesReceived(entities));
  },
});

export const ProjectChangesetFetchRequest = defineRequest<
  Abstract.ProjectDescriptor,
  Abstract.ProjectDescriptor,
>({
  id(params) {
    return ["changesets", "project", params.projectId].join("-");
  },
  perform(descriptor, dispatch, getState) {
    return abstract.changesets.project(descriptor, {
      transportMode: ["cli"],
    });
  },
  onSuccess(changeset, params, dispatch) {
    const response = abstract.unwrap(changeset);
    const { entities } = normalizeChangesetResponse(response);
    dispatch(entitiesReceived(entities));
  },
});
