// @flow
import * as React from "react";
import DialogForm, { TDialogForm } from "core/components/DialogForm";
import Input from "core/components/Input";
import { ValidatedInput } from "core/components/Input/withValidation";
import InputSwitch from "core/components/InputSwitch";
import Note from "core/components/Note";
import { helpWebhooksUrl } from "core/lib/urls";
import type { NewWebhook, WebhookEvent } from "core/types";
import WebhookEventTree from "../WebhookEventTree";
import style from "./style.scss";

type State = {
  filter: string,
};

type Props = {
  data: $Shape<NewWebhook>,
  isOpen: boolean,
  isSinglePage?: boolean,
  onChangeData: (data: $Shape<NewWebhook>) => void,
  onChangePage?: (page: number) => void,
  onClose?: () => void,
  onSubmit: (data: NewWebhook) => Promise<void> | void,
  page: number,
  submitText?: string,
  supportSigningKey?: boolean,
  webhookEvents: WebhookEvent[],
};

export default class WebhookDialog extends React.Component<Props, State> {
  state = {
    filter: "",
  };

  form: ?TDialogForm;

  get content() {
    const { isSinglePage, page, submitText = "ADD WEBHOOK" } = this.props;
    switch (page) {
      case 0:
      default:
        return {
          buttonText: isSinglePage ? submitText : "NEXT STEP: SELECT EVENTS",
          component: this.renderDetailsPage(),
          title: "Webhook Details",
        };
      case 1:
        return {
          buttonText: submitText,
          component: this.renderEventsPage(),
          title: "Select Events",
        };
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ filter: "" });
    }
  }

  onChangeActive = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const { data, onChangeData } = this.props;
    onChangeData({
      ...data,
      active: event.target.checked,
    });
    this.form && this.form.checkValid();
  };

  onChangeEvents = (events: string[]) => {
    const { data, onChangeData } = this.props;
    onChangeData({ ...data, events });
    this.form && this.form.checkValid();
  };

  onChangeFilter = (filter: string) => {
    this.setState({ filter });
  };

  onChangeKey = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const { data, onChangeData } = this.props;
    onChangeData({
      ...data,
      key: event.target.value,
    });
    this.form && this.form.checkValid();
  };

  onChangeUrl = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const { data, onChangeData } = this.props;
    onChangeData({
      ...data,
      url: event.target.value,
    });
    this.form && this.form.checkValid();
  };

  onNextPage = () => {
    const { data, isSinglePage, onChangePage, onSubmit, page } = this.props;

    if (isSinglePage || page === 1) {
      return onSubmit(data);
    }

    onChangePage && onChangePage(page + 1);
  };

  renderDetailsPage() {
    const { data, supportSigningKey } = this.props;

    return (
      <React.Fragment>
        <p>
          We’ll send a <span className={style.code}>POST</span> request with
          details of any subscribed events to the Payload URL you set.
          <a href={helpWebhooksUrl()} className={style.learnMore}>
            Learn more about webhooks…
          </a>
        </p>
        <ValidatedInput
          autoComplete="false"
          autoFocus
          label="Payload URL"
          name="webhook-url"
          onChange={this.onChangeUrl}
          pattern="^https:\/\/\S+"
          placeholder="https://example.com/postreceive"
          required
          requiredTag
          validationMessage="This value must be a valid HTTPS URL"
          value={data.url}
        />
        {supportSigningKey && (
          <Input
            autoComplete="false"
            label="Signing key"
            name="webhook-key"
            onChange={this.onChangeKey}
            type="password"
            value={data.key}
          />
        )}
        <InputSwitch
          checked={data.active}
          label="Active?"
          onChange={this.onChangeActive}
          responsive
          wrapperClass={style.switch}
        />
        <Note className={style.note}>
          We will deliver event details when this hook is triggered.
        </Note>
      </React.Fragment>
    );
  }

  renderEventsPage() {
    const { data, webhookEvents } = this.props;

    return (
      <WebhookEventTree
        data={data.events}
        filter={this.state.filter}
        onChangeData={this.onChangeEvents}
        onChangeFilter={this.onChangeFilter}
        webhookEvents={webhookEvents}
      />
    );
  }

  render() {
    const { data, isOpen, onClose, page } = this.props;

    return (
      <DialogForm
        fixedHeight
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={this.onNextPage}
        primaryButton={this.content.buttonText}
        ref={(ref) => (this.form = ref)}
        title={this.content.title}
        disabled={page !== 1 ? undefined : data.events.length === 0}
      >
        {this.content.component}
      </DialogForm>
    );
  }
}
