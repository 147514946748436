// @flow
/* global window */
import { normalize, schema } from "normalizr";
import queryString from "query-string";
import {
  UnauthorizedError,
  ForbiddenError,
  NotFoundError,
  ValidationError,
  UnexpectedError,
  handleError,
  authRequest,
  apiRequest as coreApiRequest,
  addToken,
} from "core/api";
import { getShareAuthorizationHeader } from "core/lib/shareAuthorization";
import {
  branch as branchSchema,
  changeset as changesetSchema,
  file as fileSchema,
  layer as layerSchema,
  page as pageSchema,
  sharedItem as sharedItemSchema,
  project as projectSchema,
  organization as organizationSchema,
  user as userSchema,
} from "core/schema";
import { commitSchema } from "core/schemas/commit";
import type { SSOConfig } from "core/types";
import { ABSTRACT_AUTH_CLIENT_ID } from "web/config";
import { activitySchema } from "web/schemas/activity";
import { commentSchema } from "web/schemas/comment";
import { getToken } from "web/token";
import * as URLs from "./urls";

export {
  UnauthorizedError,
  ForbiddenError,
  NotFoundError,
  ValidationError,
  UnexpectedError,
  authRequest,
};

// Deprecated: Please import { apiRequest } from "core/api";
export async function apiRequest(
  endpoint: string,
  token: ?string,
  optionOverrides: Object = {}
) {
  const shareTokenHeader = getShareAuthorizationHeader();

  if (shareTokenHeader) {
    optionOverrides.headers = {
      ...optionOverrides.headers,
      ...shareTokenHeader,
    };
  }

  const response = await coreApiRequest(
    optionOverrides.method || "get",
    endpoint,
    token,
    optionOverrides
  );

  // Hard refresh if maintainence mode
  if (response.status === 503) {
    return window.location.reload(true);
  }

  return response;
}

/**
 * Authenticate username and password against the API.
 *
 * @param {String} username
 * @param {String} password
 * @returns {Object}
 */
export async function createSession(
  username: string,
  passwordOrLegacyToken: string,
  legacyToken: boolean = false
) {
  const data = new FormData();
  data.append("client_id", ABSTRACT_AUTH_CLIENT_ID);
  data.append("grant_type", "password");
  data.append("username", username);
  data.append(legacyToken ? "legacyToken" : "password", passwordOrLegacyToken);

  const response = await authRequest("token", {
    method: "POST",
    body: data,
    headers: { "Abstract-Api-Version": "2" },
  });

  if (response.status === 400 || response.status === 401) {
    // We don't usually use 400 for user-facing errors so we’re special-casing
    // this one because oauth recommends using 400 for invalid credentials.
    throw new UnauthorizedError(
      response.statusText,
      "Email/username and password combination wasn’t found. Try again or reset your password."
    );
  }

  if (response.status !== 200) {
    throw await handleError(response);
  }

  return response.json();
}

export function destroySession(token: string) {
  try {
    authRequest(`token`, {
      method: "DELETE",
      headers: addToken(token, { "Abstract-Api-Version": "2" }),
    });
  } catch (error) {
    console.error(error);
  }
}

export async function fetchInvitation(token: string) {
  const response = await apiRequest(`invitations/${token}`, getToken(), {
    headers: { "Abstract-Api-Version": "4" },
  });

  if (response.status === 200) {
    return response.json();
  }

  throw await handleError(response);
}

export async function acceptInvitation(token: string) {
  const response = await apiRequest(`invitations/${token}/accept`, getToken(), {
    method: "POST",
    headers: { "Abstract-Api-Version": "4" },
  });

  if (response.status === 201) {
    return response.json();
  }

  throw await handleError(response);
}

export async function createAccount(attributes: Object, version: string = "3") {
  const response = await apiRequest("account", getToken(), {
    method: "POST",
    body: attributes,
    headers: { "Abstract-Api-Version": version },
  });

  if (response.status !== 201) {
    throw await handleError(response);
  }

  return response.json();
}

export async function updatePassword(
  resetPasswordToken: string,
  password: string,
  passwordConfirmation: string
) {
  const response = await apiRequest(
    `password_resets/${resetPasswordToken}`,
    null,
    {
      method: "PUT",
      body: { password, passwordConfirmation },
    }
  );

  if (response.status !== 204) {
    throw await handleError(response);
  }
}

export async function fetchPasswordReset(resetPasswordToken: string) {
  const response = await apiRequest(`password_resets/${resetPasswordToken}`);

  if (response.status !== 200) {
    throw await handleError(response);
  }
}

export async function fetchZendeskToken() {
  const response = await authRequest("zendesk", {
    method: "GET",
    headers: addToken(getToken(), {}),
  });

  if (response.status !== 200) {
    throw await handleError(response);
  }

  return response.json();
}

/**
 * Upload a file as the current account's avatar.
 */
export async function updateAccount(data: Object) {
  const response = await apiRequest(URLs.fetchAccount(), getToken(), {
    method: "PUT",
    body: data,
  });

  if (response.status === 200) {
    return response.json();
  }

  throw await handleError(response);
}

export async function updateAccountPassword(
  currentPassword: string,
  newPassword: string,
  newPasswordConfirmation: string
) {
  const response = await apiRequest("account/password", getToken(), {
    method: "PUT",
    body: { currentPassword, newPassword, newPasswordConfirmation },
  });

  if (response.status !== 200) {
    throw await handleError(response);
  }
}

export async function previewDeleteAccount() {
  const response = await apiRequest(URLs.previewAccount(), getToken(), {
    method: "DELETE",
  });

  if (response.status === 200) {
    return response.status;
  }

  throw await handleError(response);
}

export async function deleteAccount() {
  const response = await apiRequest(URLs.fetchAccount(), getToken(), {
    method: "DELETE",
  });

  if (response.status === 204) {
    return response.status;
  }

  throw await handleError(response);
}

export async function fetchNotificationSettings() {
  const response = await apiRequest(
    URLs.fetchNotificationSettings(),
    getToken(),
    { method: "GET" }
  );

  if (response.status === 200) {
    return response.json();
  }

  throw await handleError(response);
}

export async function updateNotificationSettings(values: {
  emailEnabled: boolean,
}) {
  const response = await apiRequest(
    URLs.fetchNotificationSettings(),
    getToken(),
    { method: "PUT", body: values }
  );

  if (response.status === 200) {
    return response.json();
  }

  throw await handleError(response);
}

export async function testSSOConfiguration(params: {
  entityId: string,
  metadataUrl: string,
  organizationId: string,
}) {
  const testSSOParams = queryString.stringify({
    entityId: params.entityId,
    metadataUrl: params.metadataUrl,
    organizationId: params.organizationId,
  });

  const response = await authRequest(`saml/verify?${testSSOParams}`, {
    headers: addToken(getToken(), {}),
  });

  if (response.status === 200) {
    return response.json();
  }

  throw await handleError(response);
}

export async function createIntegration(args: {|
  organizationId: string,
  code: string,
  kind: "Slack" | "GitHub" | "Jira",
  installationId?: string,
|}) {
  const response = await apiRequest("integrations", getToken(), {
    method: "POST",
    body: { ...args },
  });

  if (response.status === 422) {
    return null;
  }

  if (response.status === 201) {
    return response.json();
  }

  throw new Error("Unexpected server response");
}

export async function fetchIntegration(integrationId: string) {
  const response = await apiRequest(
    `integrations/${integrationId}`,
    getToken()
  );

  if (response.status === 200) {
    return response.json();
  }

  return null;
}

export async function updateIntegration(integrationId: string, params: Object) {
  const response = await apiRequest(
    `integrations/${integrationId}`,
    getToken(),
    { method: "PUT", body: params }
  );

  if (response.status === 200) {
    return response.json();
  }

  return null;
}

export async function deleteIntegration(integrationId: string) {
  const response = await apiRequest(
    `integrations/${integrationId}`,
    getToken(),
    { method: "DELETE" }
  );

  if (response.status !== 204) {
    throw new Error("Unexpected server response");
  }
}

export async function updateOrganizationSettings(
  organizationId: string,
  params: *
) {
  const response = await apiRequest(
    `organizations/${organizationId}/settings`,
    getToken(),
    { method: "PUT", body: params }
  );

  if (response.status === 200) {
    return response.json();
  }

  throw await handleError(response);
}

export async function updateOrganizationSSOSettings(
  organizationId: string,
  params: SSOConfig
) {
  const response = await apiRequest(
    `organizations/${organizationId}/settings/configure_sso`,
    getToken(),
    { method: "POST", body: params }
  );

  if (response.status === 200) {
    return response.json();
  }

  throw await handleError(response);
}

export async function createEmailForInvitation(
  invitationId: string,
  email: string
) {
  const response = await apiRequest(
    "emails/create_for_invitation",
    getToken(),
    {
      method: "POST",
      headers: { "Abstract-Api-Version": "4" },
      body: { email, invitationId },
    }
  );

  if (response.status === 201) {
    return response.json();
  }

  throw await handleError(response);
}

export async function verifyEmail(verificationToken: string) {
  const response = await apiRequest(
    URLs.verifyEmail(verificationToken),
    getToken(),
    {
      method: "PUT",
    }
  );

  if (response.status === 200) {
    return response.json();
  }

  throw await handleError(response);
}

export async function sendEmailVerification(
  emailIdOrVerificationToken: string
) {
  const response = await apiRequest(
    URLs.sendEmailVerification(emailIdOrVerificationToken),
    getToken(),
    {
      method: "PUT",
    }
  );

  if (response.status === 200) {
    return response.json();
  }

  throw await handleError(response);
}

export async function fetchEmails() {
  const response = await apiRequest(URLs.fetchEmails(), getToken());

  if (response.status !== 200) {
    throw await handleError(response);
  }

  return response.json();
}

export async function createEmail(email: string) {
  const response = await apiRequest(URLs.fetchEmails(), getToken(), {
    method: "POST",
    body: { email },
  });

  if (response.status !== 201) {
    throw await handleError(response);
  }

  return response.json();
}

export async function setEmailAsPrimary(emailId: string) {
  const response = await apiRequest(
    URLs.setEmailAsPrimary(emailId),
    getToken(),
    {
      method: "PUT",
    }
  );

  if (response.status !== 200) {
    throw await handleError(response);
  }

  return response.json();
}

export async function deleteEmail(emailId: string) {
  const response = await apiRequest(URLs.fetchEmail(emailId), getToken(), {
    method: "DELETE",
  });

  if (response.status !== 204) {
    throw await handleError(response);
  }
}

export async function fetchAlphaSignup(email: string, id: string) {
  const response = await apiRequest(`alpha_signups?email=${email}&id=${id}`);

  if (response.status !== 200) {
    return null;
  }

  return response.json();
}

export async function createAlphaSignup(signupParams: Object) {
  const response = await apiRequest("alpha_signups", null, {
    method: "POST",
    body: signupParams,
  });

  if (response.status === 201) {
    return response.json();
  }

  throw await handleError(response);
}

export async function fetchFile(
  projectId: string,
  branchId: string,
  sha: string,
  id: string
) {
  const response = await apiRequest(
    `projects/${projectId}/branches/${branchId}/commits/${sha}/files/${id}`,
    getToken()
  );

  if (response.status === 200) {
    return response.json();
  }

  throw await handleError(response);
}

export async function fetchLayers(
  projectId: string,
  branchId: string,
  fileId: string,
  params: Object
) {
  const response = await apiRequest(
    URLs.fetchLayers(projectId, branchId, fileId, params),
    getToken()
  );

  if (response.status === 200) {
    return response.json();
  }

  throw await handleError(response);
}

export async function fetchAccessTokens() {
  const response = await apiRequest(`access_tokens`, getToken());

  if (response.status === 200) {
    return response.json();
  }

  throw await handleError(response);
}

export async function createAccessToken(description: string) {
  const response = await apiRequest(`access_tokens`, getToken(), {
    method: "POST",
    body: { description },
  });

  if (response.status === 201) {
    return response.json();
  }

  throw await handleError(response);
}

export async function fetchCollections(
  projectId: string,
  branchId: ?string,
  offset?: number,
  limit?: number
) {
  const params: {|
    branch_id?: string,
    layersPerCollection?: number | "all",
    limit?: number,
    offset?: number,
  |} = { layersPerCollection: 4 };

  if (branchId) {
    params.branch_id = branchId;
  }

  const response = await apiRequest(
    URLs.fetchCollections(projectId, params),
    getToken(),
    { headers: { "Abstract-Api-Version": "12" } }
  );

  if (response.status === 200) {
    return response.json();
  }

  throw await handleError(response);
}

export async function fetchCollection(projectId: string, collectionId: string) {
  const response = await apiRequest(
    URLs.fetchCollection(projectId, collectionId, {
      layersPerCollection: "all",
    }),
    getToken(),
    { headers: { "Abstract-Api-Version": "7" } }
  );

  if (response.status === 200) {
    return response.json();
  }

  throw await handleError(response);
}

export async function fetchCommits(
  projectId: string,
  branchId: string,
  fileId?: string,
  layerId?: string
) {
  const url = URLs.fetchCommits(projectId, branchId, fileId, layerId);
  const response = await apiRequest(url, getToken(), {
    headers: { "Abstract-Api-Version": "2" },
  });

  if (response.status === 200) {
    return response.json();
  }

  throw await handleError(response);
}

export async function createProjectAccessRequest(
  projectId: string,
  params: Object
) {
  const response = await apiRequest(
    URLs.requestProjectAccess(projectId),
    getToken(),
    { method: "POST", body: params }
  );

  if (response.status !== 201) {
    throw await handleError(response);
  }
}

export async function acceptProjectAccessRequest(
  projectId: string,
  token: string
) {
  const response = await apiRequest(
    URLs.acceptProjectAccessRequest(projectId, token),
    getToken(),
    { method: "POST" }
  );

  if (response.status !== 200) {
    throw await handleError(response);
  }

  return response.json();
}

const changesetResponseSchema = new schema.Object({
  branch: branchSchema,
  changeset: changesetSchema,
  commit: commitSchema,
  files: [fileSchema],
  layers: [layerSchema],
  pages: [pageSchema],
  project: projectSchema,
  user: userSchema,
  sharedData: [sharedItemSchema],
});

export async function fetchChangeset(
  projectId: string,
  branchId: string,
  commitSha?: string
) {
  const response = await apiRequest(
    URLs.fetchChangeset(projectId, branchId, commitSha),
    getToken(),
    { headers: { "Abstract-Api-Version": 11 } }
  );

  if (response.status !== 200) {
    throw await handleError(response);
  }

  const body = await response.json();
  return normalize(body, changesetResponseSchema);
}

const activitiesResponseSchema = new schema.Object({
  data: {
    activities: new schema.Array(activitySchema),
    comments: new schema.Array(commentSchema),
    organizations: new schema.Array(organizationSchema),
    projects: new schema.Array(projectSchema),
    users: new schema.Array(userSchema),
  },
});

export async function fetchActivities(filters: Object) {
  let url = `activities?${queryString.stringify(filters)}`;
  const response = await apiRequest(url, getToken());

  if (response.status !== 200) {
    throw await handleError(response);
  }

  const body = await response.json();
  return normalize(body, activitiesResponseSchema);
}

export async function fetchInitiateIntegrationUrl(
  organizationId: string,
  kind: string = "Slack"
) {
  const query = `kind=${kind}&organizationId=${organizationId}`;
  const response = await apiRequest(
    `integrations/initiate?${query}`,
    getToken()
  );

  if (response.status !== 200) {
    throw await handleError(response);
  }

  return response.json();
}

export async function updateSubscription(
  organizationId: string,
  params: Object = {}
) {
  const url = `organizations/${organizationId}/subscription`;

  const response = await apiRequest(url, getToken(), {
    method: "PUT",
    body: params,
  });

  if (response.status === 200 || response.status === 201) {
    return response.json();
  }

  throw await handleError(response);
}

export async function reactivateSubscription(
  organizationId: string,
  options?: { onSuccess?: () => void } = {}
) {
  const url = `organizations/${organizationId}/subscription/reactivate`;

  const response = await apiRequest(url, getToken(), {
    method: "POST",
  });

  if (response.status === 200) {
    if (options.onSuccess) {
      options.onSuccess();
    }
    return response.json();
  }

  throw await handleError(response);
}

export async function fetchInvoices(organizationId: string) {
  const response = await apiRequest(
    `organizations/${organizationId}/invoices`,
    getToken()
  );

  if (response.status === 200) {
    return response.json();
  }

  throw await handleError(response);
}

export async function deleteOrganization(organizationId: string) {
  const response = await apiRequest(
    `organizations/${organizationId}`,
    getToken(),
    { method: "DELETE" }
  );

  if (response.status === 200) {
    return response.json();
  }

  throw await handleError(response);
}

export async function fetchSession() {
  const response = await apiRequest(URLs.fetchSession(), getToken());

  if (response.status !== 200) {
    throw await handleError(response);
  }

  return response.json();
}

export async function fetchApplicationData(id: string) {
  const response = await apiRequest(URLs.oAuthApplicationInfo(id), getToken());

  if (response.status !== 200) {
    throw await handleError(response);
  }

  return response.json();
}

export async function authorizeApplication(
  clientId: string,
  accessGranted: boolean,
  redirectUri: string,
  responseType: string,
  scope: string,
  state: string,
  code_challenge: ?string,
  code_challenge_method: ?string
) {
  const data = new URLSearchParams();

  data.append("client_id", clientId);
  data.append("access_granted", accessGranted.toString());
  data.append("redirect_uri", redirectUri);
  data.append("response_type", responseType);
  data.append("scope", scope);
  data.append("state", state);
  if (code_challenge) {
    data.append("code_challenge", code_challenge);
  }
  if (code_challenge_method) {
    data.append("code_challenge_method", code_challenge_method);
  }

  const response = await authRequest(URLs.oAuthorize(), {
    method: "post",
    body: data,
    headers: addToken(getToken(), {}),
  });

  if (response.status !== 200) {
    throw await handleError(response);
  }

  return response.json();
}

/**
 * Create a transfer token
 *
 * A transfer token is short-lived, one-time use oauth token
 * suitable for transferring a valid web oauth token to another
 * oauth application (like desktop)
 */
export async function createTransferToken() {
  const response = await authRequest("auth/tokens.createTransferToken", {
    method: "POST",
    headers: addToken(getToken(), {}),
  });

  if (response.status !== 201) {
    throw await handleError(response);
  }

  return response.json();
}
