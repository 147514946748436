// @flow
import invariant from "invariant";
import { connect } from "react-redux";
import { isOnline, getCurrentUser } from "abstract-di/selectors";
import { createReview, updateReview } from "core/actions/reviews";
import connectStorage from "core/hocs/connectStorage";
import createConnector from "core/lib/createConnector";
import { defaultBranch } from "core/models/branch";
import { type ReviewStatus } from "core/models/review";
import { ProjectMembershipRequest } from "core/requests/projectMemberships";
import {
  ReviewCreateRequest,
  ReviewUpdateRequest,
} from "core/requests/reviews";
import { getParentBranchRestrictions } from "core/selectors/branches";
import { canUseNewDefaultBranchName } from "core/selectors/features";
import { getBranchPolicy } from "core/selectors/policies";
import { getProjectMembership } from "core/selectors/projectMemberships";
import { getReviewRequests, getBranchReview } from "core/selectors/reviews";
import type { State, Dispatch, ReviewRequest, User } from "core/types";
import type {
  OwnProps,
  StateProps,
  DispatchProps,
  StorageProps,
  Props,
} from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { branch, projectId } = props;
  const currentUser = getCurrentUser(state);

  invariant(currentUser, "BranchReviewForm must know who the current user is");

  const isOwner = currentUser && currentUser.id === branch.userId;
  const branchReview = getBranchReview(state, {
    projectId,
    branchId: branch.id,
  });

  const canReview = !isOwner && !!branchReview;
  const currentReviewRequest = getReviewRequests(state, {
    reviewerId: currentUser.id,
    branchId: branch.id,
    projectId,
  })[0];

  const branchSelectorProps = { branchId: branch.id, projectId };

  const branchPolicy = getBranchPolicy(state, branchSelectorProps);
  const parentRestrictions = getParentBranchRestrictions(
    state,
    branchSelectorProps
  );

  const isCreatingReview = ReviewCreateRequest.isLoadingStrict(
    state,
    branchSelectorProps
  );

  const isUpdatingReview = currentReviewRequest
    ? ReviewUpdateRequest.isLoadingStrict(state, {
        id: currentReviewRequest.id,
        ...branchSelectorProps,
      })
    : false;

  const hasCreateError = ReviewCreateRequest.hasError(
    state,
    branchSelectorProps
  );
  const hasUpdateError = currentReviewRequest
    ? ReviewUpdateRequest.hasError(state, {
        id: currentReviewRequest.id,
        ...branchSelectorProps,
      })
    : false;

  const branchOwnerMembership = getProjectMembership(state, {
    projectId: projectId,
    userId: branch.userId,
  });

  const branchOwnerIsAdmin =
    !!branchOwnerMembership && branchOwnerMembership.isOrganizationOwner;

  const approvalRequired =
    parentRestrictions != null &&
    parentRestrictions.requireProjectAdmin &&
    !branchOwnerIsAdmin &&
    branchPolicy.merge;

  const defaultBranchName = defaultBranch({
    masterToMain: canUseNewDefaultBranchName(state),
    titleCase: true,
  });

  return {
    isOffline: !isOnline(state),
    currentReviewRequest,
    canReview,
    branchOwnerMembershipLoaded: branchOwnerMembership != null,
    approvalRequired,
    defaultBranchName,
    currentUser,
    hasError: hasCreateError || hasUpdateError,
    isSaving: isCreatingReview || isUpdatingReview,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { projectId, branch } = props;

  return {
    loadBranchOwnerMembership: () => {
      return dispatch(
        ProjectMembershipRequest.perform({
          params: {
            projectId: projectId,
            userId: branch.userId,
          },
        })
      );
    },
    submitReview: (
      commentParams: {
        body: string,
        id: string,
      },
      status: ReviewStatus | "",
      currentUser: User,
      currentReviewRequest: ?ReviewRequest
    ) => {
      if (!status) {
        return;
      }

      const reviewCommentParams = {
        projectId,
        branchId: branch.id,
        branchName: branch.name,
        ...commentParams,
      };

      // Update an existing requested review
      if (currentReviewRequest) {
        return dispatch(
          updateReview({
            id: currentReviewRequest.id,
            commentParams: reviewCommentParams,
            status,
          })
        );
      } else {
        // Create a new review, either unrequested, or a re-review
        return dispatch(
          createReview({
            projectId,
            branchId: branch.id,
            reviewerId: currentUser.id,
            sha: branch.head,
            commentParams: reviewCommentParams,
            status,
          })
        );
      }
    },
  };
}

function mapStorageToProps(storage, props: OwnProps): StorageProps {
  const id = `branch-review-body-${props.branch.id}`;
  const storedState: ?{ body: string } = storage.getItem(id);

  return {
    defaultBody: storedState ? storedState.body : "",
    onChange: (newState) => {
      storage.setItem(id, newState);
    },
    clearSavedReview: () => {
      storage.setItem(id, undefined);
    },
  };
}

export default createConnector<Props, OwnProps>(
  (Component: *) => connectStorage(Component, mapStorageToProps),
  connect(mapStateToProps, mapDispatchToProps)
);
