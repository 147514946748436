// @flow
import classnames from "classnames";
import { omit } from "lodash";
import * as React from "react";
import InputWrapper from "../InputWrapper";
import style from "./style.scss";

let counter = 0;

type Props = {
  id?: string,
  error?: string | string[],
  label?: React.Node,
  labelClass?: string,
  responsive?: boolean,
  requiredTag?: boolean,
  className?: string,
  wrapperClass?: string,
  helpers?: {
    [key: string]: React.Node,
  },
  nude?: boolean,
  disabled?: boolean,
};

export default class Select extends React.Component<Props> {
  id: string;

  constructor() {
    super();
    this.id = `abstract-core-select-${counter}`;
    counter += 1;
  }

  render() {
    const {
      className,
      error,
      label,
      labelClass,
      helpers,
      requiredTag,
      responsive,
      wrapperClass,
      nude,
      disabled,
    } = this.props;
    const props = {
      id: this.props.id || this.id,
      ...omit(
        this.props,
        "className",
        "error",
        "label",
        "labelClass",
        "helpers",
        "requiredTag",
        "responsive",
        "wrapperClass",
        "nude"
      ),
    };
    const classes = classnames(style.select, className, {
      [style.error]: error,
      [style.nude]: nude,
    });

    return (
      <InputWrapper
        error={error}
        helpers={helpers}
        inputId={props.id}
        label={label}
        labelClass={labelClass}
        requiredTag={requiredTag}
        responsive={responsive}
        className={wrapperClass}
      >
        <select className={classes} {...props} disabled={disabled} />
      </InputWrapper>
    );
  }
}
