// @flow
import * as React from "react";
import AnnotationBubble from "../AnnotationBubble";
import Flex from "../Flex";
import CommentBase, { type Props } from "./Base";
import Timestamp from "./Timestamp";
import style from "./style.scss";

export default class DefaultComment extends React.Component<Props> {
  getMeta() {
    const { annotation, disabled, createdAt, editedAt } = this.props;

    if (annotation) {
      return (
        <Flex inline align="center">
          <AnnotationBubble
            size="small"
            disabled={disabled}
            number={annotation.number}
          />
          <span className={style.dot}>{"·"}</span>
          <Timestamp createdAt={createdAt} editedAt={editedAt} />
        </Flex>
      );
    }

    return <Timestamp createdAt={createdAt} editedAt={editedAt} />;
  }

  getTitle() {
    const { deletedAt, user } = this.props;
    return (
      <strong>
        {deletedAt ? "Deleted comment" : user ? user.name : "Unknown"}
      </strong>
    );
  }

  getIsSelectable(): boolean {
    const isLayerComment = this.props.context.layerId;

    if (this.props.onPinnedCommentClick) {
      return true;
    }

    return !!(
      isLayerComment &&
      this.props.annotation &&
      !this.props.isEditing &&
      !this.props.disabled
    );
  }

  handleSelect = (event: SyntheticEvent<>) => {
    event.stopPropagation();
    if (this.props.onSelected) {
      this.props.onSelected(this.props.id);
    } else if (this.props.onPinnedCommentClick) {
      this.props.onPinnedCommentClick();
    }
  };

  render() {
    const comment = (
      <CommentBase
        {...this.props}
        showMenu={!!this.props.currentUser}
        title={this.getTitle()}
        meta={this.getMeta()}
        qaSelector={this.props.qaSelector}
      />
    );

    const selectable = this.getIsSelectable();
    if (selectable) {
      return (
        <div
          key={this.props.id}
          onClick={this.handleSelect}
          className={style.selectable}
        >
          {comment}
        </div>
      );
    }

    return comment;
  }
}
