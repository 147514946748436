// @flow
import classnames from "classnames";
import { times } from "lodash";
import * as React from "react";
import { Placeholder as BranchPlaceholder } from "core/components/BranchListItem";
import NoActiveBranches from "core/components/Empty/NoActiveBranches";
import NoActiveOwnedBranches from "core/components/Empty/NoActiveOwnedBranches";
import NoArchivedBranches from "core/components/Empty/NoArchivedBranches";
import NoResults from "core/components/Empty/NoResults";
import VirtualizedBranchList from "core/components/VirtualizedBranchList";
import type {
  BranchFilter,
  BranchHierarchy,
  Project,
  ReviewRequest,
} from "core/types";
import style from "./style.scss";

type Props = {|
  branches: BranchHierarchy[],
  branchPath: (projectId: string, branchId: string) => string,
  filter: BranchFilter,
  hasHierarchy?: boolean,
  hasNextPage?: boolean,
  isLoadingNextPage?: boolean,
  mobile?: boolean,
  onLoadNextPage?: () => void,
  project: Project,
  reviewRequests?: ReviewRequest[],
  searchQuery?: string,
|};

export default class ProjectBranchesList extends React.Component<Props> {
  renderPlaceholder = () => {
    const { mobile } = this.props;
    return (
      <div
        className={classnames(style.placeholderBranches, {
          [style.mobile]: mobile,
        })}
      >
        {times(5, (index) => (
          <BranchPlaceholder
            key={`branch-placeholder-${index}`}
            mobile={mobile}
          />
        ))}
      </div>
    );
  };

  render() {
    const {
      branches,
      branchPath,
      filter,
      hasHierarchy,
      hasNextPage,
      isLoadingNextPage,
      mobile,
      onLoadNextPage,
      project,
      reviewRequests,
      searchQuery,
    } = this.props;

    if (branches.length === 0) {
      if (hasNextPage) {
        return this.renderPlaceholder();
      }

      if (searchQuery) {
        return <NoResults term={searchQuery} type="branches" flex />;
      }

      if (filter === "archived") {
        return <NoArchivedBranches flex />;
      }

      if (filter === "mine") {
        return <NoActiveOwnedBranches flex />;
      }

      return <NoActiveBranches flex />;
    }

    return (
      <VirtualizedBranchList
        branches={branches}
        branchFilter={filter}
        branchPath={branchPath}
        className={style.branches}
        hasHierarchy={hasHierarchy}
        hasNextPage={hasNextPage}
        isLoadingNextPage={isLoadingNextPage}
        mobile={mobile}
        onLoadNextPage={onLoadNextPage}
        project={project}
        reviewRequests={reviewRequests}
      />
    );
  }
}
