// @flow
import invariant from "invariant";
import { connect } from "react-redux";
import { createBranchAndOpenFile, createBranch } from "abstract-di/actions";
import { dismissDialog } from "core/actions/dialogs";
import { getBranch } from "core/selectors/branches";
import { getCommit } from "core/selectors/commits";
import type { State, Dispatch } from "core/types";
import type { Props, OwnProps, StateProps, DispatchProps } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  return {
    commit: props.sha
      ? getCommit(state, {
          sha: props.sha,
        })
      : undefined,
    parentBranch: getBranch(state, {
      projectId: props.projectId,
      branchId: props.parentBranchId,
    }),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { projectId, fileId, layerId, sha, parentBranchId = "master" } = props;

  return {
    createBranch: (name, description) => {
      if (fileId) {
        invariant(
          createBranchAndOpenFile,
          "createBranchAndOpenFile required for dialog action"
        );
        return dispatch(
          createBranchAndOpenFile(
            projectId,
            parentBranchId,
            name,
            description,
            fileId,
            layerId
          )
        );
      } else {
        invariant(createBranch, "createBranch is required for dialog action");
        return dispatch(
          createBranch(
            projectId,
            parentBranchId,
            name,
            description,
            true,
            undefined,
            sha
          )
        );
      }
    },
    dismissDialog: () => {
      dispatch(dismissDialog());
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
