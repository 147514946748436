// @flow
import type { Action } from "core/types";

export function openDevtools(): Action {
  return { type: "DEVTOOLS_OPENED" };
}

export function closeDevtools(): Action {
  return { type: "DEVTOOLS_CLOSED" };
}

export function overrideFeature(feature: string, value: boolean): Action {
  return {
    type: "DEVTOOLS_FEATURE_OVERRIDDEN",
    meta: { feature },
    payload: value,
  };
}

export function resetFeatureOverrides(params?: { feature?: string }): Action {
  return {
    type: "DEVTOOLS_FEATURE_RESET",
    meta: params,
  };
}
