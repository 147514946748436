// @flow
import * as React from "react";
import ContextMenu from "core/components/ContextMenu";
import type { ShareLinkUpdateRequestParams, ShareLink } from "core/types";
import RevokeConfirmDialog from "web/components/OrganizationSettings/Sharing/RevokeConfirmDialog";
import connector from "./connector";

type Props = {
  shareLink: ShareLink,
  organizationId: string,
  updateShareLink: (params: ShareLinkUpdateRequestParams) => mixed,
  isOffline: boolean,
  isSubmitting: boolean,
  hasError: boolean,
  children: (
    showMenu: (ev: SyntheticEvent<>) => mixed,
    ref: (el: ?HTMLElement) => mixed
  ) => React.Node,
  showSuccessToast: () => void,
};

type State = {
  showConfirm: boolean,
};

class PublicShareLinksMenu extends React.Component<Props, State> {
  menu: ?ContextMenu;
  static defaultProps = {
    isOffline: false,
  };

  state = {
    showConfirm: false,
  };

  showConfirm = () => {
    this.setState({ showConfirm: true });
  };

  revokePublicAccess = async () => {
    await this.props.updateShareLink({
      id: this.props.shareLink.id,
      isPublic: false,
    });
    this.setState({ showConfirm: false });
    this.props.showSuccessToast();
  };

  get menuItems(): * {
    return [
      {
        label: "Revoke public access",
        click: this.showConfirm,
        enabled: !this.props.isOffline,
      },
    ];
  }

  render() {
    return (
      <React.Fragment>
        <ContextMenu
          ref={(me) => (this.menu = me)}
          id={this.props.shareLink.id}
          menuItems={this.menuItems}
          children={this.props.children}
        />
        <RevokeConfirmDialog
          isOpen={this.state.showConfirm}
          onClose={() => this.setState({ showConfirm: false })}
          onSubmit={this.revokePublicAccess}
          isSubmitting={this.props.isSubmitting}
          hasError={this.props.hasError}
        />
      </React.Fragment>
    );
  }
}

export default connector(PublicShareLinksMenu);
