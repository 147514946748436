// @flow
import classnames from "classnames";
import * as React from "react";
import Button from "core/components/Button";
import ReferenceComment from "core/components/Comment/ReferenceComment";
import CommentsList from "core/components/CommentsList";
import Error from "core/components/Empty/Error";
import Offline from "core/components/Empty/Offline";
import Loaded from "core/components/Loaded";
import { Abstract } from "core/lib/abstract";
import { push, removeQuery } from "core/lib/location";
import type { ReactRouterLocation, Comment as TComment } from "core/types";
import connector from "./connector";
import style from "./style.scss";

export type StateProps = {|
  isLoading: boolean,
  hasError: boolean,
  isOffline: boolean,
  comments: TComment[],
|};

export type DispatchProps = {|
  onLoad: () => void,
|};

export type LocationProps = {|
  location: ReactRouterLocation,
|};

export type OwnProps = {|
  params: Abstract.BranchDescriptor,
  shouldExtendFullWidth?: boolean,
|};

export type Props = {
  ...StateProps,
  ...DispatchProps,
  ...LocationProps,
  ...OwnProps,
};

function BranchOverviewCommentThread(props: Props) {
  if (props.isOffline && !props.comments.length) {
    return (
      <Offline flex description="Connect to the internet to view comments" />
    );
  }

  if (props.hasError) {
    return <Error flex />;
  }

  return (
    <Loaded loading={props.isLoading} title="Loading comment…" flex>
      <div className={style.actions}>
        <Button
          icon="chevron-large-left"
          nude
          tint
          tintHover
          noMargin
          rel="noopener noreferrer"
          target="_blank"
          title="Back to overview"
          className={style.backButton}
          onClick={() => {
            push(removeQuery("commentId"));
          }}
        >
          Back to overview
        </Button>
      </div>
      <div
        className={classnames({
          [style.content]: props.shouldExtendFullWidth,
        })}
      >
        <CommentsList
          className={style.commentsList}
          comments={props.comments}
          commentComponent={ReferenceComment}
          projectId={props.params.projectId}
          branchId={props.params.branchId}
          highlightedCommentId={props.location.query.commentId || undefined}
        />
      </div>
    </Loaded>
  );
}

export default connector(BranchOverviewCommentThread);
