// @flow
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { trackEvent } from "core/actions/analytics";
import Button from "core/components/Button";
import ContextMenu from "core/components/ContextMenu";
import FilterPopover from "core/components/FilterPopover";
import { useProject, useProjects } from "core/hooks/projects";
import { addQuery, push, removeQuery, replace } from "core/lib/location";
import { ProjectsFetchRequest } from "core/requests/projects";
import type { Organization } from "core/types";

type Props = {
  mobile: boolean,
  organization: Organization,
  projectId?: string,
  branchesReviewsFilter: ?string,
};

const BranchReviewsControls = ({
  mobile,
  organization,
  projectId,
  branchesReviewsFilter,
}: Props) => {
  const selectedProject = useProject(projectId || "");

  const projectsLoading = useSelector((state) =>
    ProjectsFetchRequest.isLoading(state, {
      organizationId: organization.id,
      filter: "active",
    })
  );

  const projects = useProjects({
    organizationId: organization.id,
    active: true,
  });

  const menuRef = React.useRef();

  const dispatch = useDispatch();

  const assignedToMe = branchesReviewsFilter === "assigned-to-me";

  const menuItems = [
    {
      type: "checkbox",
      label: "Assigned to me",
      click: () => {
        push(addQuery({ branchesReviewsFilter: "assigned-to-me" }));
        dispatch(trackEvent("BRANCHES_REVIEWS_ASSIGNED_TO_ME_CLICKED"));
      },
      checked: assignedToMe,
    },
    {
      type: "checkbox",
      label: "All reviews",
      click: () => {
        push(removeQuery("branchesReviewsFilter"));
        dispatch(trackEvent("BRANCHES_ALL_REVIEWS_CLICKED"));
      },
      checked: !branchesReviewsFilter,
    },
  ];

  const handleProjectChange = (newProjectId: string) => {
    if (newProjectId && newProjectId !== projectId) {
      if (assignedToMe) {
        replace(
          addQuery({
            projectId: newProjectId,
            branchesReviewsFilter: "assigned-to-me",
          })
        );
      } else {
        replace(addQuery({ projectId: newProjectId }));
      }
    } else {
      replace(removeQuery("projectId"));
    }
  };

  const dropdownLabel = (() => {
    if (assignedToMe) {
      return "Assigned to me";
    }
    return "All reviews";
  })();

  return (
    <React.Fragment>
      <FilterPopover
        disableIcon={true}
        disabled={projectsLoading}
        anchor="left"
        buttonText={
          mobile
            ? undefined
            : selectedProject
            ? selectedProject.name
            : "All Projects"
        }
        placeholderText={mobile ? undefined : "All Projects"}
        selectedItem={selectedProject}
        onSelectItem={handleProjectChange}
        items={projects}
        mobile={mobile}
        placeholderIcon={mobile ? "sort" : undefined}
        disclosure={!mobile}
      />
      <ContextMenu
        ref={menuRef}
        id={organization.id}
        fixed
        placement={mobile ? "bottom-start" : "bottom-end"}
        menuItems={menuItems}
      >
        {(showMenu, ref) => (
          <Button
            aria-haspopup="menu"
            innerRef={ref}
            onClick={showMenu}
            children={dropdownLabel}
            disclosure
            nude
          />
        )}
      </ContextMenu>
    </React.Fragment>
  );
};

export default BranchReviewsControls;
