// @flow
import * as React from "react";
import window from "core/global/window";
import authedDownload from "core/lib/authedDownload";

type Dimensions = { width: number, height: number };

const cache: { [src: string]: { src: string, dimensions: Dimensions } } = {};

type Props = {
  src: string,
  children: (
    authedSrc?: string,
    error?: Error,
    dimensions?: Dimensions,
    loading: boolean
  ) => React.Node,
};

type State = {
  authedSrc?: string,
  dimensions?: Dimensions,
  error?: Error,
  loading: boolean,
};

function loadAsImage(src: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = window.document.createElement("img");
    img.onload = () => resolve(img);
    img.onerror = () =>
      reject(new Error(`AuthedImage: could not load ${src} as an image`));
    img.src = src;
  });
}

export default class AuthedImage extends React.Component<Props, State> {
  controller: window.AbortController;

  mounted = false;
  state = {
    authedSrc: undefined,
    dimensions: undefined,
    error: undefined,
    loading: false,
  };

  componentDidMount() {
    this.mounted = true;
    this.controller = window.AbortController && new window.AbortController();
    this.load(this.props.src);
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.src !== this.props.src) {
      this.load(this.props.src);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    if (this.controller) {
      this.controller.abort();
    }
  }

  asyncSetState(state: State) {
    if (!this.mounted) {
      return;
    }
    this.setState(state);
  }

  async load(src: string) {
    this.setState({ loading: true });
    let cacheValue = cache[src];

    if (!cacheValue) {
      try {
        const authedSrc = await authedDownload(src, this.controller);
        // awaiting this will throw an error if the loaded file is not an image
        // that can be displayed in an image tag.
        const image = await loadAsImage(authedSrc);
        cacheValue = {
          src: image.src,
          dimensions: { width: image.width, height: image.height },
        };
      } catch (error) {
        return this.asyncSetState({
          error,
          authedSrc: undefined,
          dimensions: undefined,
          loading: false,
        });
      }

      cache[src] = cacheValue;
    }

    this.asyncSetState({
      authedSrc: cacheValue.src,
      dimensions: cacheValue.dimensions,
      error: undefined,
      loading: false,
    });
  }

  render() {
    return this.props.children(
      this.state.authedSrc,
      this.state.error,
      this.state.dimensions,
      this.state.loading
    );
  }
}
