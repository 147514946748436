// @flow
import * as React from "react";
import Button from "core/components/Button";
import Spinner from "core/components/Spinner";
import style from "./style.scss";
import Empty from ".";

export default function LayerSyncing(props: { layerUrl?: string }) {
  return (
    <Empty
      {...props}
      className={style.layerSyncing}
      image={<Spinner />}
      title="Syncing Commits"
      description={
        <span>
          This commit is still syncing, <br /> it should be available in a few
          moments.
        </span>
      }
    >
      {props.layerUrl && (
        <Button nude tint external component="a" href={props.layerUrl}>
          Open in the web app
        </Button>
      )}
    </Empty>
  );
}
