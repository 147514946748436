// @flow
import * as React from "react";
import Flex from "core/components/Flex";
import MainContent from "core/components/MainContent";
import Media from "core/components/Media";
import Sidebar from "core/components/Sidebar";
import SettingsMenu from "web/components/SettingsMenu";
import style from "./style.scss";

type Props = {
  sidebarIsOpen: boolean,
  onCloseSidebar: () => *,
  children: React.Node,
};

export default function SettingsShell(props: Props) {
  return (
    <Flex className={style.shell}>
      <Media large>
        {(large) => (
          <Sidebar
            minWidth={220}
            maxWidth={400}
            resizable="main-sidebar"
            opaque
            active={props.sidebarIsOpen && !large}
            collapsed={!props.sidebarIsOpen && !large}
            onClose={props.onCloseSidebar}
            direction="left"
          >
            <SettingsMenu
              {...props}
              mobile={!large}
              onClose={props.onCloseSidebar}
            />
          </Sidebar>
        )}
      </Media>
      <MainContent>{props.children}</MainContent>
    </Flex>
  );
}
