// @flow
import * as React from "react";
import { connect } from "react-redux";
import BranchesDataLoader from "core/components/BranchesDataLoader";
import ProjectBranchesList from "core/components/ProjectBranchesList";
import { getReviewRequests } from "core/selectors/reviews";
import type { ReviewRequest, State, Dispatch } from "core/types";
import { branchPath } from "web/routeHelpers";
import type { BranchFilter, BranchHierarchy, Project } from "web/types";

type OwnProps = {|
  branchFilter: BranchFilter,
  mobile: boolean,
  project: Project,
  projectId: string,
  userId?: string,
  query: string,
|};

type StateProps = {|
  reviewRequests: ReviewRequest[],
  hasHierarchy: boolean,
|};

type Props = { ...OwnProps, ...StateProps };

class FilteredBranches extends React.Component<Props> {
  renderList = ({
    items,
    hasNextPage,
    isLoadingNextPage,
    onLoadNextPage,
  }: {
    items: BranchHierarchy[],
    hasNextPage: boolean,
    isLoadingNextPage: boolean,
    onLoadNextPage?: () => void,
  } = {}) => {
    return (
      <ProjectBranchesList
        branches={items}
        branchPath={branchPath}
        filter={this.props.branchFilter}
        hasHierarchy={this.props.hasHierarchy}
        hasNextPage={hasNextPage}
        isLoadingNextPage={isLoadingNextPage}
        mobile={this.props.mobile}
        onLoadNextPage={onLoadNextPage}
        project={this.props.project}
        reviewRequests={this.props.reviewRequests}
      />
    );
  };

  render() {
    const { projectId, userId, query, branchFilter } = this.props;

    return (
      <BranchesDataLoader
        filter={branchFilter}
        projectId={projectId}
        search={query}
        userId={userId}
      >
        {(loaderProps) => this.renderList(loaderProps)}
      </BranchesDataLoader>
    );
  }
}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { projectId, branchFilter } = props;

  return {
    hasHierarchy: branchFilter === "active",
    reviewRequests: getReviewRequests(state, { projectId }),
  };
}

export default connect<Props, OwnProps, StateProps, _, State, Dispatch>(
  mapStateToProps
)(FilteredBranches);
