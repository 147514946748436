// @flow
import * as React from "react";
import Flex from "core/components/Flex";
import type { OrganizationMetrics } from "core/types";
import MetricBox from "./MetricBox";
import Metric from "./MetricBox/Metric";

type Props = {
  mobile?: boolean,
  metrics: OrganizationMetrics,
};

export default function OrganizationReportingMetrics(props: Props) {
  const {
    createdCommits,
    activeBranches,
    activeProjects,
    percentBranchesMerged,
    branchesPerProject,
    commitsPerBranch,
  } = props.metrics;

  return (
    <Flex align="baseline" grow={false} shrink={false} inline wrap>
      <MetricBox label="Projects" icon="project">
        <Metric
          stat={activeProjects}
          label="Active"
          tooltip="Number of active projects."
          qaSelector="general-stat"
        />
        <Metric
          stat={branchesPerProject}
          label="Avg. Active Branches"
          tooltip="Average number of active branches per project."
          qaSelector="stat-avg"
        />
      </MetricBox>
      <MetricBox label="Branches" icon="branch">
        <Metric
          stat={activeBranches}
          label="Active"
          tooltip="Number of active branches."
          qaSelector="general-stat"
        />
        <Metric
          stat={percentBranchesMerged}
          label="Merged"
          tooltip="Percentage of active branches that were merged into another branch."
          unit="%"
          qaSelector="stat-avg"
        />
      </MetricBox>
      <MetricBox label="Commits" icon="commit">
        <Metric
          stat={createdCommits}
          label="Created"
          tooltip="Number of commits."
          qaSelector="general-stat"
        />
        <Metric
          stat={commitsPerBranch}
          label="Avg. Per Branch"
          tooltip="Average number of commits in active branches."
          qaSelector="stat-avg"
        />
      </MetricBox>
    </Flex>
  );
}
