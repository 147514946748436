// @flow
import { connect } from "react-redux";
import { getFilter } from "core/models/project";
import {
  CreateIntegrationOverrideRequest,
  RemoveIntegrationOverrideRequest,
  UpdateIntegrationOverrideRequest,
} from "core/requests/integrations";
import { ProjectsFetchRequest } from "core/requests/projects";
import { getDefaultSlackIntegrationChannel } from "core/selectors/integrations";
import { getOrganizationSettings } from "core/selectors/organizationSettings";
import type { State, Dispatch } from "core/types";
import ProjectChannels from "web/components/SlackIntegrationSettings/ProjectChannels";
import { OrganizationSettingsRequest } from "web/requests/organizationSettings";
import { getGroupedProjectsByOverride } from "web/selectors/projects";

type OwnProps = {
  params: { organizationId: string, integrationId: string },
};

function mapStateToProps(state: State, props: OwnProps) {
  const { organizationId } = props.params;
  const settings = getOrganizationSettings(state, { organizationId });
  const isLoadingOrganizationSettings = OrganizationSettingsRequest.isLoading(
    state,
    { organizationId }
  );
  const isLoadingProjects = ProjectsFetchRequest.isLoading(state, {
    organizationId,
    filter: getFilter(),
  });

  return {
    projects: getGroupedProjectsByOverride(state, { organizationId }),
    slackIntegration: settings ? settings.slackIntegration : undefined,
    defaultChannel: getDefaultSlackIntegrationChannel(state, {
      organizationId,
    }),
    isLoading: isLoadingProjects || isLoadingOrganizationSettings,
  };
}

function mapDispatchToProps(dispatch: Dispatch, props: OwnProps) {
  const { organizationId, integrationId } = props.params;
  return {
    onMount: () => {
      dispatch(
        ProjectsFetchRequest.perform({
          params: { organizationId, filter: getFilter() },
        })
      );
    },
    onCreate: (formId, projectId, values) => {
      dispatch(
        CreateIntegrationOverrideRequest.perform({
          params: {
            projectId,
            integrationId,
            details: values,
          },
        })
      );
    },
    onUpdate: (formId, overrideId, values) => {
      dispatch(
        UpdateIntegrationOverrideRequest.perform({
          params: {
            organizationId,
            integrationId,
            overrideId,
            details: values,
          },
        })
      );
    },
    onRemove: (overrideId) => {
      dispatch(
        RemoveIntegrationOverrideRequest.perform({
          params: {
            organizationId,
            integrationId,
            overrideId,
          },
        })
      );
    },
  };
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default connect(mapStateToProps, mapDispatchToProps)(ProjectChannels);
