// @flow
import * as React from "react";
import window from "core/global/window";

type Props = {
  isOpen: boolean,
  onOuterClick: (event: MouseEvent) => void,
};

export default function useOuterClick(props: Props) {
  const ref = React.useRef<HTMLDivElement | null>(null);

  const { onOuterClick, isOpen } = props;
  React.useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const { target } = event;
      const container = ref.current;

      if (!(container instanceof window.HTMLElement)) {
        return;
      }

      // $FlowFixMe
      if (!container.contains(target)) {
        onOuterClick(event);
      }
    };

    if (isOpen) {
      window.addEventListener("click", handleClick);
    }

    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, [isOpen, onOuterClick]);

  return ref;
}
