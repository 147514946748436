// @flow
import * as React from "react";
import Menu from "core/components/SidebarMenu";
import MenuItem from "core/components/SidebarMenuItem";
import MenuHeader from "web/components/MenuHeader";
import {
  accountProfile,
  accountOrganizations,
  accountNotifications,
  accountAccessTokens,
  accountEmails,
  accountOauthApplications,
} from "web/routeHelpers";
import type { User, Policy } from "web/types";
import style from "./style.scss";

type Props = {
  onClose: () => void,
  mobile?: boolean,
  className?: string,
  account?: User,
  policy?: Policy,
  canManageProfile: boolean,
  canManageEmail: boolean,
  canCreateAccessTokens: boolean,
  canManageOAuthApplications: boolean,
};

export default function ProfileSettingsMenu({
  account,
  policy,
  onClose,
  className,
  mobile,
  canManageProfile,
  canManageEmail,
  canCreateAccessTokens,
  canManageOAuthApplications,
}: Props) {
  return (
    <div className={className} role="navigation" aria-label="Personal Settings">
      <MenuHeader
        title="Personal Settings"
        onClose={onClose}
        linkText="Back to app"
        linkTo="/"
        qaSelector="backToAppLink"
      />
      <Menu large mobile={mobile}>
        <MenuItem
          to={accountProfile()}
          icon="user"
          onClick={onClose}
          className={style.item}
          qaSelector="editProfileSidebarItem"
        >
          Edit Profile
        </MenuItem>
        {canManageEmail && (
          <MenuItem
            to="/account/password"
            icon="lock-locked-alt"
            onClick={onClose}
            className={style.item}
            qaSelector="changePasswordSidebarItem"
          >
            Change Password
          </MenuItem>
        )}
        {canManageEmail && (
          <MenuItem
            to={accountOrganizations()}
            icon="organization"
            onClick={onClose}
            className={style.item}
            qaSelector="yourOrganizationsSidebarItem"
          >
            Organizations
          </MenuItem>
        )}
        {canManageEmail && (
          <MenuItem
            to={accountEmails()}
            icon="mail"
            onClick={onClose}
            className={style.item}
            qaSelector="emailAddressesSidebarItem"
          >
            Email Addresses
          </MenuItem>
        )}
        <MenuItem
          to={accountNotifications()}
          icon="notifications-default"
          onClick={onClose}
          className={style.item}
          qaSelector="notificationsSidebarItem"
        >
          Notifications
        </MenuItem>
        {canCreateAccessTokens && (
          <MenuItem
            to={accountAccessTokens()}
            icon="api"
            onClick={onClose}
            className={style.item}
            qaSelector="apiTokensSidebarItem"
          >
            API Tokens
          </MenuItem>
        )}
        {canManageOAuthApplications && (
          <MenuItem
            to={accountOauthApplications()}
            icon="key"
            onClick={onClose}
            className={style.item}
            qaSelector="oAuthSidebarItem"
          >
            OAuth Applications
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}
