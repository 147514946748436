// @flow
import { connect } from "react-redux";
import { getProjectMembership } from "core/selectors/projectMemberships";
import type { State, Dispatch } from "core/types";
import type { OwnProps, StateProps, Props } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const projectMembership = getProjectMembership(state, {
    projectId: props.project.id,
    userId: props.user.id,
  });

  return {
    inProject: !!projectMembership,
    role: (projectMembership && projectMembership.role) || "",
    roleFromTeam: (projectMembership && projectMembership.fromTeam) || false,
    roleFromOrganization:
      (projectMembership && projectMembership.fromOrganization) || false,
  };
}

export default connect<Props, OwnProps, StateProps, _, State, Dispatch>(
  mapStateToProps
);
