// @flow
import { connect } from "react-redux";
import { isOnline } from "abstract-di/selectors";
import {
  FetchUserSuggestionsRequest,
  type ProjectMembershipListResponse,
} from "core/requests/projectMemberships";
import type { Dispatch, State } from "core/types";
import type { Props, OwnProps, StateProps, DispatchProps } from "./";

const SUGGESTIONS_TO_RENDER = 20;

function mapStateToProps(state: State, props: OwnProps): StateProps {
  return {
    isOnline: isOnline(state),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const projectId = props.projectId;

  return {
    onSearchForUsers(
      search: string,
      offset: number
    ): Promise<ProjectMembershipListResponse> {
      return dispatch(
        FetchUserSuggestionsRequest.perform({
          params: {
            projectId,
            search,
            limit: SUGGESTIONS_TO_RENDER,
            offset,
          },
        })
      );
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
