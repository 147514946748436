// @flow
import { normalize } from "normalizr";
import { notification as notificationSchema } from "core/schema";

const SUPPORTED_NOTIFICATION_TYPES = [
  "BRANCH_MENTIONED",
  "COLLECTION_MENTIONED",
  "COMMENT_CREATED",
  "COMMENT_MENTIONED",
  "COMMIT_MENTIONED",
  "PROJECT_ARCHIVED",
  "PROJECT_CREATED",
  "PROJECT_DELETED",
  "PROJECT_MEMBER_ADDED",
  "PROJECT_MEMBER_REMOVED",
  "PROJECT_UNARCHIVED",
  "PROJECT_VISIBILITY_CHANGED",
  "REVIEWER_REMOVED",
  "REVIEW_COMPLETED",
  "REVIEW_DISMISSED",
  "REVIEW_REQUESTED",
];

export function normalizeNotificationsResponse(response: *) {
  const supportedNotifications = response.filter((notification) =>
    SUPPORTED_NOTIFICATION_TYPES.includes(notification.messageType)
  );

  return normalize(supportedNotifications, [notificationSchema]);
}
