// @flow
import * as React from "react";
import DialogForm from "core/components/DialogForm";
import type { Branch } from "core/types";
import connector from "./connector";

export type OwnProps = {|
  projectId: string,
|};

export type StateProps = {|
  branch: ?Branch,
|};

export type DispatchProps = {|
  openCreateCommit: () => void,
  discardChanges: () => void,
  dismissDialog: () => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

function UncommittedChanges(props: Props) {
  return (
    <DialogForm
      title="Uncommitted changes"
      primaryButton="View changes"
      secondaryButton="Discard changes"
      onSubmit={props.openCreateCommit}
      onSecondary={props.discardChanges}
      onClose={props.dismissDialog}
      isOpen
    >
      <p>
        There are uncommitted changes on{" "}
        <em>{props.branch ? props.branch.name : "a branch"}</em>.
      </p>
      <p>You need to commit or discard these changes before proceeding.</p>
    </DialogForm>
  );
}

export default connector(UncommittedChanges);
