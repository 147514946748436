// @flow
import * as React from "react";
import image from "./images/comment.svg";
import style from "./style.scss";
import Empty from ".";

type Props = {
  mini?: boolean,
  description?: string,
};

export default function NoComments(props: Props) {
  if (props.mini) {
    return (
      <Empty
        {...props}
        image={image}
        description={
          props.description ||
          "Start the conversation by leaving a comment or annotation."
        }
        className={style.miniNoComments}
      />
    );
  }

  return (
    <Empty
      {...props}
      image={image}
      title="Leave a comment"
      description={
        props.description ||
        "Use comments and annotations to collaborate with your team."
      }
    />
  );
}
