// @flow
import { entitiesDeleted, entitiesReceived } from "core/actions/entities";
import { apiRequest, handleError } from "core/api";
import defineRequest from "core/requests";
import * as API from "../api";
import { normalizeAccessTokensResponse } from "../schemas/accessToken";
import { getToken } from "../token";

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export const AccessTokensRequest = defineRequest({
  id() {
    return `access-tokens`;
  },
  perform() {
    return API.fetchAccessTokens();
  },
  async onSuccess(response, params, dispatch) {
    const { entities } = normalizeAccessTokensResponse(response);
    dispatch(entitiesReceived(entities));
  },
});

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export const AccessTokenCreateRequest = defineRequest({
  id() {
    return `access-token-create`;
  },
  perform({ description }: { description: string }) {
    return API.createAccessToken(description);
  },
  async onSuccess(response, params, dispatch) {
    const { entities } = normalizeAccessTokensResponse(response);
    dispatch(entitiesReceived(entities));
  },
});

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export const AccessTokenRevokeRequest = defineRequest({
  id({ id }: { id: string }) {
    return `delete:access_tokens/${id}`;
  },
  async perform({ id }: { id: string }) {
    const response = await apiRequest(
      "delete",
      `access_tokens/${id}`,
      getToken()
    );

    if (response.status !== 204) {
      throw await handleError(response);
    }
  },
  async onSuccess(response, { id }: { id: string }, dispatch) {
    dispatch(entitiesDeleted({ accessTokens: [id] }));
  },
});
