// @flow
import idx from "idx";
import maxBy from "lodash/maxBy";
import Formatter from "core/components/PropertyValue/Formatter";
import window from "core/global/window";
import createLogger from "core/lib/logger";
import type { LayerData } from "core/types";
import * as constants from "./constants";

const cssFormatter = new Formatter({ unitLabel: "px" });
const logger = createLogger("measureTextLayer");

export default function measureTextLayer(
  textLayer: LayerData,
  overrideTextContent: string
) {
  const measureCanvas: HTMLCanvasElement =
    window.document.createElement("canvas");
  const measureContext = measureCanvas.getContext("2d");
  const characterSpacing = idx(
    textLayer,
    (_) => _.properties.text.characterSpacing
  );

  if (characterSpacing) {
    measureCanvas.style.letterSpacing =
      cssFormatter.letterSpacing.code(characterSpacing);
  }

  measureContext.font = `${cssFormatter.font.code(
    textLayer.properties.text
  )}, monospace`; // Add monospace fallback

  const measurement = measureContext.measureText(
    maxBy(overrideTextContent.split("\n"), (line) => line.length) // For multi line strings, get the longest line to determine the width of the bounding box.
  );

  const width = Math.ceil(measurement.width);
  const x = ((textLayer, width) => {
    const delta = width - textLayer.properties.width;
    const horizontalAlignment =
      idx(textLayer.properties.text, (_) => _.horizontalAlignment) ||
      constants.TEXT_NATURAL_ALIGNMENT;

    switch (horizontalAlignment) {
      case 0:
      case 4: {
        // left and natural (TODO: RTL natural)
        return textLayer.properties.x;
      }
      case 1:
        // right
        return textLayer.properties.x - delta;
      case 2:
      case 3:
        // center and justify
        return textLayer.properties.x - delta / 2;
      default: {
        logger.error(`Unknown horizontalAlignment: "${horizontalAlignment}"`);

        // fallback to natural
        return textLayer.properties.x;
      }
    }
  })(textLayer, width);

  return { width, x };
}
