// @flow
import { connect } from "react-redux";
import { editFile } from "abstract-di/actions";
import type { State, Dispatch } from "core/types";
import type { OwnProps, DispatchProps, Props } from ".";

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { branchId } = props;
  const { id: fileId, projectId, sha } = props.file;

  return {
    editFile() {
      dispatch(editFile({ projectId, branchId, fileId, sha }));
    },
  };
}

export default connect<Props, OwnProps, _, DispatchProps, State, Dispatch>(
  undefined,
  mapDispatchToProps
);
