// @flow
import React from "react";
import Note from "core/components/Note";
import { helpTransferProjectUrl } from "core/lib/urls";

type Props = {
  className?: string,
};

const TransferNote = ({ className }: Props) => (
  <Note className={className}>
    Transferring a project will reset its privacy settings, making the project
    visible to all members of the receiving Organization.{" "}
    <a href={helpTransferProjectUrl()}>
      Learn more about transferring projects…
    </a>
  </Note>
);

export default TransferNote;
