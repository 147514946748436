// @flow
import classnames from "classnames";
import * as empty from "empty";
import * as React from "react";
import Flex from "core/components/Flex";
import FloatingMenu from "core/components/FloatingMenu";
import Icon from "core/components/Icon";
import type {
  PopoverPlacement,
  PopoverClassNames,
} from "core/components/Popover/Core";
import style from "./style.scss";

export type MenuItemProps = {|
  disabled?: boolean,
  active?: boolean,
  activeHover?: boolean,
  checkbox?: boolean,
  checked?: boolean,
  icon?: string | React.Node,
  iconFill?: string,
  name?: string,
  className?: string,
  rightSideContent?: React.Node,
  children: React.Node,
  innerRef?: React.Ref<"button">,
  truncate?: boolean,
  onClick?: (event: SyntheticEvent<HTMLElement>) => void,
  qaSelector?: string,
|};

export type SubMenuProps = {
  width?: number,
  placement?: PopoverPlacement,
  offset?: string,
  classNames?: PopoverClassNames,
  floatingClassName?: string,
};

type Props = {
  ...MenuItemProps,
  submenu?: MenuItemProps[],
  submenuProps?: SubMenuProps,
};

export default function FloatingMenuItem(props: Props) {
  const hasSubMenu = props.submenu && props.submenu.length > 0;
  let icon = props.icon;
  if (props.checkbox) {
    icon = props.checked ? "checkmark" : "";
  }

  const item = (
    <button
      name={props.name}
      disabled={props.disabled}
      onClick={props.onClick}
      ref={props.innerRef}
      className={classnames(style.item, props.className, {
        [style.active]: props.active,
        [style.activeHover]: props.activeHover,
        [style.default]: !props.active && !props.activeHover,
        [style.disabled]: props.disabled,
        [style.hasIcon]: (icon && typeof icon === "string") || props.checkbox,
        [style.hasAvatar]: icon && typeof icon === "object",
        [style.truncate]: props.truncate !== false,
      })}
      type="button"
      data-qa={props.qaSelector}
    >
      <Flex align="center" className={style.inner}>
        {(icon || props.checkbox) &&
          (typeof icon === "string" ? (
            <Icon
              type={icon}
              fill={props.iconFill}
              tint={props.checkbox && !props.iconFill}
              className={style.icon}
            />
          ) : (
            icon
          ))}
        <Flex align="center" justify="space-between" className={style.text}>
          {props.children}
          {props.rightSideContent}
        </Flex>
        {hasSubMenu && (
          <Icon type="disclosure-contracted" fill="currentColor" />
        )}
      </Flex>
    </button>
  );

  if (hasSubMenu) {
    const submenuProps = props.submenuProps || empty.object;
    return (
      <FloatingMenu
        {...submenuProps}
        classNames={{ hoverMenuOpen: style.active }}
        display="block"
        trigger="hoverMenu"
        floatingClassName={classnames(
          style.submenuBody,
          submenuProps.floatingClassName || undefined
        )}
        body={(props.submenu || []).map((subItem, index) => (
          <FloatingMenuItem key={index} {...subItem} />
        ))}
      >
        {item}
      </FloatingMenu>
    );
  }

  return item;
}
