// @flow
import * as React from "react";
import OnboardingHeading from "core/components/OnboardingHeading";
import LoggedInForm from "./LoggedInForm";
import NewUserForm from "./NewUserForm";
import ProjectInfo from "./ProjectInfo";

export type FormValues = {
  email: string,
  name: string,
};

type Props = {|
  isLoggedIn: boolean,
  organizationLogo: { url: ?string },
  organizationName: string,
  onSelectLoginForm: () => void,
  onSubmit: (formId: string, values: FormValues) => void,
  projectColor: string,
  projectName: string,
|};

export default function RequestAccessForm({
  isLoggedIn,
  organizationLogo,
  organizationName,
  onSelectLoginForm,
  onSubmit,
  projectColor,
  projectName,
}: Props) {
  return (
    <React.Fragment>
      <OnboardingHeading level="1">Request access</OnboardingHeading>
      <ProjectInfo
        organizationName={organizationName}
        projectColor={projectColor}
        projectName={projectName}
      />
      {isLoggedIn ? (
        <LoggedInForm onSubmit={onSubmit} />
      ) : (
        <NewUserForm
          onSelectLoginForm={onSelectLoginForm}
          onSubmit={onSubmit}
        />
      )}
    </React.Fragment>
  );
}
