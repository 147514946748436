// @flow
import * as React from "react";
import Input from "core/components/Input";
import InputCheckbox from "core/components/InputCheckbox";
import { privacyUrl, termsOfServiceUrl } from "core/lib/urls";
import OnboardingForm from "web/components/OnboardingForm";
import type { Form } from "web/types";
import style from "./style.scss";

export type FormValues = {
  name: string,
  username: string,
  marketingConsent: boolean,
};

type Props = {
  form: Form,
  onSubmit: (formId: string, values: FormValues) => void,
};

export default class CompleteProfileForm extends React.Component<Props> {
  handleChange = (event: SyntheticInputEvent<>) => {
    const input = event.target;
    const value = input.type === "checkbox" ? input.checked : input.value;
    this.props.form.onChange(this.props.form.id, { [input.name]: value });
  };

  handleSubmit = (event: SyntheticEvent<>) => {
    event.preventDefault();

    this.props.onSubmit(this.props.form.id, { ...this.props.form.values });
  };

  get disabled(): boolean {
    const { name, username, email, acceptTos } = this.props.form.values;
    const formPopulated = name && username && email && acceptTos;

    return (
      !formPopulated || !this.props.form.isDirty || this.props.form.isSubmitting
    );
  }

  render() {
    const { form } = this.props;
    const { name, username, email } = form.values;

    return (
      <OnboardingForm
        error={form.errors.base}
        onSubmit={this.handleSubmit}
        disabled={this.disabled}
        fullWidthSubmit
        showSubmitGraphic
        submitText={form.isSubmitting ? "Completing account…" : "Continue"}
      >
        <Input
          error={form.errors.name}
          label="Full name"
          name="name"
          onChange={this.handleChange}
          value={name}
          placeholder="Jane Doe"
        />
        <Input
          autoCorrect="off"
          autoCapitalize="none"
          type="text"
          error={form.errors.username}
          label="Username"
          name="username"
          onChange={this.handleChange}
          value={username}
        />
        <Input
          label="Work email"
          name="email"
          type="email"
          value={email}
          disabled
        />
        <div className={style.checkboxWrapper}>
          <InputCheckbox
            onChange={this.handleChange}
            checkboxClass={style.checkbox}
            labelClass={style.note}
            label="Send me product news and training emails."
          />
        </div>
        <div className={style.checkboxWrapper}>
          <InputCheckbox
            name="acceptTos"
            onChange={this.handleChange}
            checkboxClass={style.checkbox}
            labelClass={style.note}
            label={
              <React.Fragment>
                I’ve read and accept the{" "}
                <a
                  href={termsOfServiceUrl()}
                  rel="noopener noreferrer"
                  target="_blank"
                  className={style.link}
                >
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  href={privacyUrl()}
                  rel="noopener noreferrer"
                  target="_blank"
                  className={style.link}
                >
                  Privacy Policy
                </a>
                .
              </React.Fragment>
            }
          />
        </div>
      </OnboardingForm>
    );
  }
}
