// @flow
import { debounce, isEmpty } from "lodash";
import * as React from "react";
import Loaded from "core/components/Loaded";
import AdjustmentLine from "core/components/SeatManager/AdjustmentLine";
import NewPaymentLine from "core/components/SeatManager/NewPaymentLine";
import SeatInputLine from "core/components/SeatManager/SeatInputLine";
import SeatsAvailableLine from "core/components/SeatManager/SeatsAvailableLine";
import UpdateSeatToggleLine from "core/components/SeatManager/UpdateSeatToggleLine";
import {
  NON_ENTERPRISE_SEAT_CAP,
  availableSeats,
} from "core/lib/subscriptions";
import type { Subscription } from "core/types";
import connector from "./connector";
import {
  OverMaxSeatsError,
  SubscriptionError,
  UnderMinEnterpriseSeatsError,
  UnderMinProSeatsError,
  AllAvailableSeatsUsedError,
} from "./errors";
import style from "./style.scss";

export type ActionType = "add" | "remove";

export type OwnProps = {|
  action: ActionType,
  controlSeats?: boolean,
  organizationId: string,
  subscription: ?Subscription,
  onSubmit?: ?(event: SyntheticEvent<>) => Promise<void>,
  onSuccess?: ?(event: SyntheticEvent<>) => void,
  adjustContributors?: boolean,
  subscriptionError?: boolean,
  children: ({
    renderSummary: () => React.Node,
    renderInput: () => React.Node,
    renderToggle: () => React.Node,
    renderAvailableSeats: () => React.Node,
    recalculate: () => void,
    onSubmit: (event: SyntheticEvent<>) => Promise<void>,
    hasError: boolean,
    isLoading: boolean,
    inputValue: number,
    availableSeats: number,
    toggleOn: boolean,
  }) => React.Node,
|};

export type StateProps = {|
  addSeatError?: boolean,
  removeSeatError?: boolean,
  subscriptionError: boolean,
  subscriptionLoading: boolean,
  isRecalculatingPreview: boolean,
  isSubmitting: boolean,
  seatCapEnabled?: boolean,
  showNextBill: boolean,
|};

export type DispatchProps = {|
  onAddSeats: (quantity: number, preview: boolean) => Promise<void>,
  onRemoveSeats: (quantity: number, preview: boolean) => void,
|};

export type Props = {|
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
|};

type ComponentState = {|
  inputValue: number,
  total: number,
  totalChange: number,
  proratedCharge: number,
  nextPaymentDate: string,
  isLoadingPreview: boolean,
  canChangeSeats: boolean,
  errorMsg: string,
|};

class SeatManager extends React.Component<Props, ComponentState> {
  static defaultProps = {
    controlSeats: false,
  };

  state = {
    inputValue: 1,
    total: 0,
    totalChange: 0,
    proratedCharge: 0,
    nextPaymentDate: "",
    isLoadingPreview: false,
    canChangeSeats: false,
    errorMsg: "",
  };

  fetchPricePreview = async () => {
    const onChangeSeats = this.getOnChangeSeats();
    const response =
      this.props.controlSeats &&
      (await onChangeSeats(this.state.inputValue, true));

    if (response && !isEmpty(response)) {
      this.setState({
        proratedCharge: response.immediateCharge,
        total: response.totalAmountInCents,
        totalChange: response.totalChangeInCents,
        nextPaymentDate: response.currentPeriodEndsAt,
        isLoadingPreview: false,
      });
    }
  };

  getOnChangeSeats() {
    const { action, onAddSeats, onRemoveSeats } = this.props;
    return action === "add" ? onAddSeats : onRemoveSeats;
  }

  seatCapEnforced = () => {
    const { subscription, seatCapEnabled } = this.props;
    return (
      seatCapEnabled &&
      subscription &&
      subscription.type !== "enterprise" &&
      subscription.maxSeats === NON_ENTERPRISE_SEAT_CAP
    );
  };

  canAddSeats = (seatsToAdd: number) => {
    if (!this.props.subscription || !this.props.controlSeats) {
      return false;
    }
    const { totalSeats, maxSeats } = this.props.subscription;

    // noop when the # of seats that are being added will exceed the max
    if (maxSeats && totalSeats + seatsToAdd > maxSeats) {
      this.setState({ isLoadingPreview: true, inputValue: seatsToAdd });
    } else {
      this.setState({ isLoadingPreview: true, canChangeSeats: true });
      this.fetchPricePreview();
    }
  };

  canRemoveSeats = (seatsToRemove: number) => {
    if (!this.props.subscription || !this.props.controlSeats) {
      return false;
    }
    const { totalSeats, minSeats } = this.props.subscription;
    const respectsMinSeat =
      minSeats === null ? true : totalSeats - seatsToRemove >= minSeats;

    let availableSeats = this.getAvailableSeats();
    if (this.props.adjustContributors) {
      // more available seats are freed up because we're losing contributors
      availableSeats += seatsToRemove;
    }

    if (
      !this.getIsLastSeat() &&
      respectsMinSeat &&
      seatsToRemove <= availableSeats
    ) {
      this.setState({
        isLoadingPreview: true,
        canChangeSeats: true,
      });
      this.fetchPricePreview();
    } else {
      this.setState({
        isLoadingPreview: true,
        inputValue: seatsToRemove,
      });
    }
  };

  handleChange = (event: SyntheticInputEvent<*>) => {
    let value = parseInt(event.target.value, 10);

    if (isNaN(value) || value < 1) {
      value = 1;
    }

    this.setState({ inputValue: value });
    this.debouncedRecalculate(value);
  };

  debouncedRecalculate = debounce((seatChange: number) => {
    this.recalculate(seatChange);
  }, 500);

  handleSubmit = async (event: SyntheticEvent<*>) => {
    const { onSuccess, onSubmit } = this.props;
    const onChangeSeats = this.getOnChangeSeats();
    let response;
    event.preventDefault();

    try {
      if (onSubmit) {
        response = await onSubmit(event);
      }

      if (this.state.canChangeSeats) {
        response = await onChangeSeats(this.state.inputValue, false);
      }

      if (onSuccess && response) {
        onSuccess(event);
      }
    } catch (error) {
      this.setState({
        errorMsg:
          error.message ||
          "There was a problem modifying the seats on your account",
      });
    }
  };

  recalculate = (seatChange?: number = this.state.inputValue) => {
    const { action } = this.props;

    if (action === "add") {
      this.canAddSeats(seatChange);
    } else if (action === "remove") {
      this.canRemoveSeats(seatChange);
    }
  };

  handleToggle = (e: SyntheticInputEvent<*>) => {
    if (this.state.canChangeSeats === false) {
      this.recalculate();
    }
    this.setState({ canChangeSeats: !this.state.canChangeSeats });
  };

  renderSummary = () => {
    const {
      subscriptionLoading,
      subscription,
      subscriptionError,
      action,
      showNextBill,
    } = this.props;
    const seatCapEnforced = this.seatCapEnforced();

    return (
      <Loaded loading={subscriptionLoading}>
        {subscriptionError && <SubscriptionError />}
        {subscription && action && (
          <React.Fragment>
            {action !== "remove" && (
              <AdjustmentLine
                proRatedAmount={this.state.proratedCharge}
                isLoading={this.props.isRecalculatingPreview}
                actionType={action}
                disabled={
                  !this.state.canChangeSeats || this.getHasValidationError()
                }
                seatCapEnforced={seatCapEnforced}
              />
            )}
            <NewPaymentLine
              annual={subscription.annual}
              totalChange={this.state.totalChange}
              nextPaymentDate={subscription.currentPeriodEndsAt}
              isLoading={this.props.isRecalculatingPreview}
              actionType={action}
              disabled={
                !this.state.canChangeSeats || this.getHasValidationError()
              }
              coupons={
                subscription.couponCodes && subscription.couponCodes.length > 0
              }
              showNextBill={showNextBill}
              seatCapEnforced={seatCapEnforced}
            />
            {this.state.canChangeSeats && (
              <React.Fragment>
                {this.getOverMaxSeats() && !seatCapEnforced && (
                  <OverMaxSeatsError
                    seatCapEnforced={false}
                    subscriptionTitle={subscription.type}
                  />
                )}
                {this.getUnderMinSeats() &&
                  subscription.type === "enterprise" && (
                    <UnderMinEnterpriseSeatsError />
                  )}
                {this.getUnderMinSeats() && subscription.type === "pro" && (
                  <UnderMinProSeatsError
                    organizationId={this.props.organizationId}
                  />
                )}
                {action === "remove" && (
                  <AllAvailableSeatsUsedError
                    organizationId={this.props.organizationId}
                    lastSeatSelected={this.getIsLastSeat()}
                    allSeatsUsed={
                      this.getAllSeatsUsed() && !this.getUnderMinSeats()
                    }
                  />
                )}
                {this.state.errorMsg && (
                  <p className={style.error}>{this.state.errorMsg}</p>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Loaded>
    );
  };

  renderInput = () => {
    const { subscription, action, controlSeats } = this.props;
    const seatCapEnforced = this.seatCapEnforced();
    if (!subscription || !controlSeats) {
      return null;
    }

    return (
      <React.Fragment>
        <SeatInputLine
          annual={subscription.annual}
          onChange={this.handleChange}
          input={this.state.inputValue}
          pricePerSeat={subscription.priceInCents}
          actionType={action}
          disabled={!this.state.canChangeSeats}
          seatCapEnforced={seatCapEnforced}
        />
        {this.getOverMaxSeats() &&
          seatCapEnforced &&
          this.state.canChangeSeats && (
            <OverMaxSeatsError
              seatCapEnforced={true}
              subscriptionTitle={subscription.type}
            />
          )}
      </React.Fragment>
    );
  };

  renderAvailableSeats = () => {
    const { subscription } = this.props;
    if (!subscription) {
      return null;
    }

    return (
      <SeatsAvailableLine
        availableSeats={this.getAvailableSeats()}
        seatCapEnforced={this.seatCapEnforced()}
        subscription={subscription}
      />
    );
  };

  renderToggle = () => {
    const { action, controlSeats } = this.props;
    if (!controlSeats) {
      return null;
    }

    return (
      <UpdateSeatToggleLine
        isToggledOn={this.state.canChangeSeats}
        onChange={this.handleToggle}
        actionType={action}
        disabled={
          this.props.isRecalculatingPreview ||
          this.props.isSubmitting ||
          this.props.subscriptionError
        }
      />
    );
  };

  getAvailableSeats() {
    const { action, subscription } = this.props;
    return action === "remove" &&
      !!subscription &&
      subscription.totalSeatsPending
      ? subscription.totalSeatsPending - subscription.usedSeats
      : availableSeats(subscription);
  }

  getAllSeatsUsed() {
    const { inputValue } = this.state;
    const { action, controlSeats, adjustContributors } = this.props;

    if (!controlSeats || action === "add") {
      return false;
    }
    if (adjustContributors) {
      return false;
    }
    if (this.getIsLastSeat()) {
      return true;
    }
    return inputValue > this.getAvailableSeats();
  }

  getIsLastSeat() {
    const { subscription } = this.props;
    return !!subscription && subscription.totalSeats === 1;
  }

  getOverMaxSeats() {
    const { inputValue } = this.state;
    const { subscription, action, controlSeats } = this.props;

    if (!controlSeats || action === "remove") {
      return false;
    }

    return subscription
      ? subscription.totalSeats + inputValue > subscription.maxSeats &&
          subscription.maxSeats !== null
      : false;
  }

  getUnderMinSeats() {
    const { inputValue } = this.state;
    const { subscription, action, controlSeats } = this.props;
    if (!controlSeats || action === "add" || !subscription) {
      return false;
    }

    const totalSeats =
      subscription.totalSeatsPending || subscription.totalSeats;

    return subscription
      ? totalSeats - inputValue < subscription.minSeats
      : false;
  }

  getHasValidationError() {
    const { action } = this.props;
    const removeSeatDisabled =
      this.getUnderMinSeats() || this.getAllSeatsUsed();
    switch (action) {
      case "add":
        return this.getOverMaxSeats();
      case "remove":
        return removeSeatDisabled;
      default:
        return false;
    }
  }

  render() {
    const { subscriptionError, isSubmitting, isRecalculatingPreview } =
      this.props;
    const { inputValue, canChangeSeats, isLoadingPreview } = this.state;

    const isLoading =
      canChangeSeats &&
      (isSubmitting || isRecalculatingPreview || isLoadingPreview);
    const hasError =
      canChangeSeats && (this.getHasValidationError() || !!subscriptionError);

    return this.props.children({
      isLoading,
      hasError,
      inputValue,
      renderSummary: this.renderSummary,
      renderInput: this.renderInput,
      renderToggle: this.renderToggle,
      renderAvailableSeats: this.renderAvailableSeats,
      recalculate: this.recalculate,
      onSubmit: this.handleSubmit,
      toggleOn: canChangeSeats,
      availableSeats: this.getAvailableSeats(),
    });
  }
}

export default connector(SeatManager);
