// @flow
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUserId } from "abstract-di/selectors";
import { getShareToken } from "core/lib/shareAuthorization";
import { getAllValidOrganizations } from "core/selectors/organizations";
import Manager from "core/socket/manager";
import { socketMessageReceived } from "web/actions/socket";
import { ABSTRACT_API_URL, PUSHER_APP_KEY } from "web/config";
import { getAllProjects } from "web/selectors/projects";
import { getToken } from "web/token";

const AUTH_ENDPOINT = `${ABSTRACT_API_URL}/push/auth`;

type Props = {|
  children: React.Node,
|};

export default function PusherProvider(props: Props) {
  const dispatch = useDispatch();
  const currentUserId = useSelector(getCurrentUserId);
  const organizations = useSelector(getAllValidOrganizations);
  const projects = useSelector(getAllProjects);
  const publicShareToken = getShareToken();
  const authenticationToken = getToken();

  const manager = React.useRef<?Manager>(
    PUSHER_APP_KEY
      ? new Manager(PUSHER_APP_KEY, AUTH_ENDPOINT, {
          onMessage: (event) => {
            dispatch(socketMessageReceived(event));
          },
        })
      : undefined
  );

  React.useEffect(() => {
    if (manager.current) {
      manager.current.update({
        authenticationToken,
        currentUserId,
        organizations,
        projects,
        publicShareToken,
      });
    }
  }, [
    authenticationToken,
    currentUserId,
    organizations,
    projects,
    publicShareToken,
  ]);

  return props.children;
}
