// @flow
import screenfull from "screenfull";

type OnChangeCallback = (isFullScreen: boolean) => void;

export default class Fullscreen {
  _onChange: ?OnChangeCallback;

  _handleChange = () => {
    if (this._onChange) {
      this._onChange(screenfull.isFullscreen);
    }
  };

  addChangeListener = (callback: OnChangeCallback) => {
    if (screenfull.isEnabled) {
      this._onChange = callback;
      screenfull.on("change", this._handleChange);
    }
  };

  removeChangeListener = () => {
    if (screenfull.isEnabled) {
      this._onChange = undefined;
      screenfull.off("change", this._handleChange);
    }
  };

  enter = () => {
    if (screenfull.isEnabled) {
      screenfull.request();
    }
  };

  exit = () => {
    if (screenfull.isEnabled) {
      screenfull.exit();
    }
  };

  isFullscreen = () => {
    return screenfull.isFullscreen;
  };
}
