// @flow
import * as React from "react";
import DialogForm, { TDialogForm } from "core/components/DialogForm";
import FormNotice from "core/components/FormNotice";
import { SubmittableInput } from "core/components/Input/withSubmittable";
import Note from "core/components/Note";
import { isMaster } from "core/models/branch";
import type { Commit, Branch, ThunkAction } from "core/types";
import Validations from "core/validations";
import connector from "./connector";

export type OwnProps = {|
  projectId: string,
  parentBranchId: string,
  fileId?: string,
  layerId?: string,
  sha?: string,
|};

export type StateProps = {|
  parentBranch: ?Branch,
  commit: ?Commit,
|};

export type DispatchProps = {|
  createBranch: (name: string, description: string) => ThunkAction,
  dismissDialog: () => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type State = {|
  isLoading: boolean,
  name?: string,
|};

class CreateBranch extends React.Component<Props, State> {
  state = {
    isLoading: false,
    name: undefined,
  };

  form: ?TDialogForm;

  nameChanged = (ev: SyntheticInputEvent<>) => {
    this.setState({ name: ev.target.value });
    this.form && this.form.checkValid();
  };

  handleSubmit = (ev: SyntheticEvent<>) => {
    ev.preventDefault();
    if (this.state.isLoading) {
      return;
    }

    const description = "";
    const name = this.state.name;
    if (!name) {
      return;
    }

    this.setState({ isLoading: true }, async () => {
      await this.props.createBranch(name, description);
      this.props.dismissDialog();
    });
  };

  render() {
    const { parentBranch, commit } = this.props;

    return (
      <DialogForm
        title={
          !parentBranch || isMaster(parentBranch)
            ? "Create new Branch"
            : "Create new child Branch"
        }
        primaryButton={
          this.state.isLoading ? "Creating branch…" : "Create branch"
        }
        ref={(ref) => (this.form = ref)}
        onClose={this.props.dismissDialog}
        onSubmit={this.handleSubmit}
        disabled={this.state.isLoading}
        isOpen
      >
        <p>
          New work starts by creating a Branch. You can think of it as a
          workspace to explore and edit designs.
        </p>
        <p>
          {commit ? (
            <FormNotice
              heading={`This branch will start at the commit “${commit.title}”`}
              body="Use your new child branch to add to or expand on work from this point in time."
              icon="branch"
              type="tint"
              alignContent
            />
          ) : (
            parentBranch &&
            !isMaster(parentBranch) && (
              <FormNotice
                heading={`This will be a child branch of “${parentBranch.name}”`}
                body="Use your new child branch to add to or expand on work from its parent."
                icon="branch"
                type="tint"
                alignContent
              />
            )
          )}
        </p>
        <SubmittableInput
          type="text"
          label="Branch name"
          placeholder="What’s your goal?"
          onSubmit={this.handleSubmit}
          onChange={this.nameChanged}
          minLength={Validations.minBranchNameLength}
          maxLength={Validations.maxBranchNameLength}
          qaSelector="branchNameInput"
          pattern={Validations.isSafeBranchName}
          requiredTag
          required
          autoFocus
        />
        <Note>
          {!parentBranch || isMaster(parentBranch)
            ? "Tip: Name branches after the task at hand. Try not to name branches after yourself or the date."
            : "Tip: When parent Branches are merged, archived, or deleted, child Branches will stick around. This can help teams work on one initiative together or shift ownership over work to another user."}
        </Note>
      </DialogForm>
    );
  }
}

export default connector(CreateBranch);
