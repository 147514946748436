// @flow
import createCachedSelector from "@elasticprojects/re-reselect";
import empty from "empty";
import {
  filter,
  find,
  groupBy,
  keyBy,
  map,
  pick,
  sortBy,
  values,
} from "lodash";
import { BranchStatus } from "core/gitConstants";
import { uniqueId } from "core/models/branch";
import { ReviewStatuses } from "core/models/review";
import { getBranch, getBranchStatus } from "core/selectors/branches";
import { getRawEntities, getEntity } from "core/selectors/entities";
import {
  maybeGetBranchId,
  maybeGetProjectId,
  maybeGetUserId,
} from "core/selectors/helpers";
import { getActiveProjectsForOrganization } from "core/selectors/projects";
import { getUsersForProject } from "core/selectors/users";
import type { State, Branch, BranchReview, ReviewRequest } from "core/types";

function getAllBranches(state: State) {
  return state.branches;
}

type BranchFilters = {
  projectId?: string,
  branchStatus?: string,
};

type ReviewRequestFilters = {
  reviewerId?: string,
  branchId?: string,
  projectId?: string,
  isRequired?: boolean,
  isOptional?: boolean,
  reviewRequestOrder?: "reverse",
  reviewRequestStatus?: string,
};

function branchStatus(state: State, props: BranchFilters) {
  return props.branchStatus;
}

function cacheByBranchFilters(
  state: State,
  { projectId, branchStatus }: BranchFilters
) {
  return [projectId, branchStatus].join("-");
}

function cacheByProject(state: State, { projectId }: { projectId?: string }) {
  return projectId || "";
}

export const getBranches: (state: State, props: BranchFilters) => Branch[] =
  createCachedSelector(
    getAllBranches,
    maybeGetProjectId,
    branchStatus,
    (branches, projectId, branchStatus) => {
      const filterParams = {};
      if (projectId) {
        filterParams.projectId = projectId;
      }
      if (branchStatus) {
        filterParams.status = branchStatus;
      }

      return filter(values(branches), filterParams);
    }
  )(cacheByBranchFilters);

function getReviewerId(state: State, props: ReviewRequestFilters) {
  return props.reviewerId;
}

function getReviewRequestStatus(state: State, props: ReviewRequestFilters) {
  return props.reviewRequestStatus;
}

function getReviewRequestOrder(state: State, props: ReviewRequestFilters) {
  return props.reviewRequestOrder;
}

function getReviewRequestRequired(state: State, props: ReviewRequestFilters) {
  return props.isRequired;
}

function getReviewRequestOptional(state: State, props: ReviewRequestFilters) {
  return props.isOptional;
}

function cacheByReviewRequestFilters(
  state: State,
  {
    projectId,
    branchId,
    reviewerId,
    reviewRequestOrder,
    reviewRequestStatus,
    isRequired,
    isOptional,
  }: ReviewRequestFilters
) {
  return [
    projectId,
    branchId,
    reviewerId,
    reviewRequestOrder,
    reviewRequestStatus,
    isRequired,
    isOptional,
  ].join("-");
}

const cacheRequiredReviewerIdsForBranch = (
  state: State,
  params: {
    projectId: string,
    branchId: string,
  }
) => {
  return `${params.projectId}-${params.branchId}`;
};

function getAdminsForProject(state: State, props: { projectId: string }) {
  return getUsersForProject(state, {
    projectId: props.projectId,
    role: "admin",
  });
}

export const getRequiredReviewerIdsForBranch: (
  state: State,
  params: { branchId: string, projectId: string }
) => string[] = createCachedSelector(
  getAdminsForProject,
  getBranch,
  getBranchStatus,
  (allAdmins, branch, branchStatus) => {
    const branchOwnerId = branch && branch.userId ? branch.userId : "";
    if (!allAdmins || allAdmins.some((r) => r.id === branchOwnerId)) {
      return empty.array;
    }

    return branchStatus && branchStatus.requiredReviewerIds
      ? branchStatus.requiredReviewerIds
      : empty.array;
  }
)(cacheRequiredReviewerIdsForBranch);

// This function is used so getRequiredReviewerIdsForBranch() can be used as
// input into other selectors where branchId is optional.
function getRequiredReviewerIds(
  state: State,
  params: {
    branchId?: string,
    projectId?: string,
  }
): string[] {
  const { branchId, projectId } = params;

  if (branchId && projectId) {
    return getRequiredReviewerIdsForBranch(state, { branchId, projectId });
  } else {
    // TODO call getRequiredReviewerIdsForProject?
    return empty.array;
  }
}

function cacheByReviewRequestFiltersForOrganization(
  state: State,
  props: ReviewRequestFilters & {
    organizationId: string,
  }
) {
  return [props.organizationId, cacheByReviewRequestFilters(state, props)].join(
    "-"
  );
}

function getAllReviewRequests(state: State): { [id: string]: ReviewRequest } {
  return getRawEntities(state, "reviewRequests");
}

export const getReviewRequests: (
  State,
  ReviewRequestFilters
) => ReviewRequest[] = createCachedSelector(
  getAllReviewRequests,
  maybeGetProjectId,
  maybeGetBranchId,
  getReviewerId,
  getReviewRequestStatus,
  getReviewRequestOrder,
  getReviewRequestRequired,
  getReviewRequestOptional,
  getRequiredReviewerIds,
  (
    reviewRequests,
    projectId,
    branchId,
    reviewerId,
    status,
    order,
    isRequired,
    isOptional,
    requiredReviewerIds
  ) => {
    const filters = {};
    if (branchId) {
      filters.branchId = branchId;
    }
    if (projectId) {
      filters.projectId = projectId;
    }
    if (reviewerId) {
      filters.reviewerId = reviewerId;
    }
    if (status) {
      filters.status = status;
    }

    let filteredReviewRequests = filter(reviewRequests, (reviewRequest) => {
      if (isRequired) {
        return requiredReviewerIds.includes(reviewRequest.reviewerId);
      }
      if (isOptional) {
        return !requiredReviewerIds.includes(reviewRequest.reviewerId);
      }
      return true;
    });

    let sortedReviews = sortBy(
      filter(filteredReviewRequests, filters),
      (reviewRequest) => {
        if (reviewRequest.status === ReviewStatuses.REJECTED) {
          return 0;
        }
        if (reviewRequest.status === ReviewStatuses.APPROVED) {
          return 1;
        }
        if (reviewRequest.status === ReviewStatuses.REQUESTED) {
          return 2;
        }
      },
      "createdAt"
    );

    if (order === "reverse") {
      sortedReviews = sortedReviews.reverse();
    }

    return sortedReviews;
  }
)(cacheByReviewRequestFilters);

function getReviewableBranches(state: State): Branch[] {
  return getBranches(state, {
    branchStatus: BranchStatus.REVIEW,
  });
}

export function getBranchReview(
  state: State,
  props: { projectId: string, branchId: string }
): ?BranchReview {
  return getEntity(state, "branchReviews", uniqueId(props));
}

function getBranchReviews(state: State): { [id: string]: BranchReview } {
  return getRawEntities(state, "branchReviews");
}

export const getFilteredBranchReviews: (
  State,
  ReviewRequestFilters
) => BranchReview[] = createCachedSelector(
  getBranchReviews,
  getReviewableBranches,
  getReviewRequests,
  maybeGetProjectId,
  maybeGetBranchId,
  getReviewerId,
  (
    branchReviews,
    branches,
    reviewRequests,
    projectId,
    branchId,
    reviewerId
  ) => {
    const ids = map(branches, (branch) =>
      uniqueId({ branchId: branch.id, projectId: branch.projectId })
    );
    branchReviews = pick(branchReviews, ids);

    // NOTE: When reviewerId is a filter, we want to filter by reviewRequests
    // otherwise, we filter branch reviews for matching id
    if (reviewerId) {
      const idMap = keyBy(map(reviewRequests, uniqueId));
      return filter(
        branchReviews,
        (branchReview) => !!idMap[uniqueId(branchReview)]
      );
    }

    if (projectId || branchId) {
      return filter(
        branchReviews,
        (branchReview) =>
          branchReview.projectId === projectId ||
          branchReview.branchId === branchId
      );
    }

    return values(branchReviews);
  }
)(cacheByReviewRequestFilters);

export const getBranchReviewsForOrganization: (
  State,
  ReviewRequestFilters & {
    organizationId: string,
  }
) => BranchReview[] = createCachedSelector(
  getActiveProjectsForOrganization,
  getFilteredBranchReviews,
  (projects, branchReviews) => {
    const projectsById = keyBy(projects, "id");
    return filter(
      branchReviews,
      (branchReview) => !!projectsById[branchReview.projectId]
    );
  }
)(cacheByReviewRequestFiltersForOrganization);

export const getRequestedBranchReviewsForUser: (
  State,
  ReviewRequestFilters & {
    userId?: string,
  }
) => BranchReview[] = createCachedSelector(
  getFilteredBranchReviews,
  getReviewRequests,
  maybeGetUserId,
  (branchReviews, reviewRequests, userId) => {
    const reviewsByBranchId = groupBy(reviewRequests, "branchId");
    if (!userId) {
      return [];
    }

    return filter(branchReviews, (branchReview) => {
      if (branchReview.status !== ReviewStatuses.REQUESTED) {
        return false;
      }

      const branchReviews = reviewsByBranchId[branchReview.branchId];
      return !!find(branchReviews, {
        status: ReviewStatuses.REQUESTED,
        reviewerId: userId,
      });
    });
  }
)(cacheByProject);

export const getOrganizationBranchReviewsForUser: (
  State,
  ReviewRequestFilters & {
    userId?: string,
    organizationId: string,
  }
) => BranchReview[] = createCachedSelector(
  getBranchReviewsForOrganization,
  getReviewRequests,
  maybeGetUserId,
  (organizationReviews, reviewRequests, userId) => {
    if (!userId || !organizationReviews.length) {
      return empty.array;
    }

    const requestsByBranchId = groupBy(reviewRequests, "branchId");
    return filter(organizationReviews, (organizationReview) => {
      const branchReviewRequests =
        requestsByBranchId[organizationReview.branchId];

      return !!find(branchReviewRequests, {
        reviewerId: userId,
      });
    });
  }
)(cacheByReviewRequestFiltersForOrganization);
