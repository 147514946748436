// @flow
import { connect } from "react-redux";
import { withData } from "core/components/DataLoader";
import createConnector from "core/lib/createConnector";
import { ProjectsFetchRequest } from "core/requests/projects";
import { OrganizationReviewsFetchRequest } from "core/requests/reviews";
import { StarsFetchRequest } from "core/requests/stars";
import type { State, Dispatch } from "core/types";
import type { OwnProps, DispatchProps, Props } from ".";

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    onLoad: () => {
      const { organization } = props;

      if (!organization) {
        // Nothing can be loaded until organization is loaded
        return;
      }

      const organizationId = organization.id;

      dispatch(
        ProjectsFetchRequest.perform({
          params: {
            organizationId,
            filter: "active",
          },
        })
      );

      dispatch(StarsFetchRequest.perform({ params: {} }));
      dispatch(
        OrganizationReviewsFetchRequest.perform({ params: { organizationId } })
      );
    },
  };
}

export default createConnector<Props, OwnProps>(
  connect<Props, OwnProps, _, DispatchProps, State, Dispatch>(
    undefined,
    mapDispatchToProps
  ),
  withData((props: Props) => ({
    organizationId: props.organization ? props.organization.id : null,
  }))
);
