// @flow
import type { Action } from "core/types";

export function entityMappingLoaded(
  entityId: string,
  ids: Array<string>,
  type: string,
  { replace = false }: { replace?: boolean } = {}
): Action {
  return {
    type: "core/ENTITY_MAPPING_LOADED",
    meta: { replace },
    payload: { entityId, ids, type },
  };
}
