// @flow
import { compact } from "lodash";
import type { State } from "core/types";

export function getPaginationId(
  id: string,
  params: { [key: string]: string }
): string {
  return `${id}-${compact(
    Object.keys(params)
      .sort()
      .map((key) => params[key])
  ).join("-")}`;
}

export function getPaginationItemsLoaded(
  state: State,
  id: string,
  params: { [key: string]: string }
): number {
  const pagination = state.pagination[getPaginationId(id, params)];

  if (!pagination) {
    return 0;
  }

  return pagination.itemsLoaded;
}

export function getPaginationHasMore(
  state: State,
  id: string,
  params: { [key: string]: string }
): boolean {
  const pagination = state.pagination[getPaginationId(id, params)];

  if (!pagination) {
    return true;
  }

  return pagination.hasMore;
}
