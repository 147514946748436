// @flow
import React, { Component } from "react";
import DialogForm from "core/components/DialogForm";
import FilesList from "core/components/FilesList";
import FilesListItem from "core/components/FilesListItem";
import { fromNow } from "core/lib/dates";
import type { File } from "core/types";
import connector from "./connector";

export type OwnProps = {|
  projectId: string,
  branchId: string,
  file: File,
|};

export type DispatchProps = {|
  deleteFile: (projectId: string, branchId: string, file: File) => void,
  dismissDialog: () => void,
|};

export type Props = {
  ...OwnProps,
  ...DispatchProps,
};

class ConfirmDeleteFile extends Component<Props> {
  handleSubmit = (ev: SyntheticEvent<>) => {
    ev.preventDefault();
    this.props.deleteFile(
      this.props.projectId,
      this.props.branchId,
      this.props.file
    );
    this.props.dismissDialog();
  };

  render() {
    const { file } = this.props;

    return (
      <DialogForm
        title="Delete file"
        primaryButton="Delete file"
        onSubmit={this.handleSubmit}
        onClose={this.props.dismissDialog}
        dangerous
        isOpen
      >
        <div>
          {file.isLibrary ? (
            <p>
              Are you sure you want to delete this library file? Once it is
              removed linked symbols in other files will need to be detached to
              be edited.
            </p>
          ) : (
            <p>Are you sure you want to delete this file?</p>
          )}
          <FilesList>
            <FilesListItem
              id={file.id}
              type={file.type}
              name={file.name}
              isLibrary={!!file.isLibrary}
              meta={`Last edited ${fromNow(file.updatedAt)}`}
            />
          </FilesList>
        </div>
      </DialogForm>
    );
  }
}

export default connector(ConfirmDeleteFile);
