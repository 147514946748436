// @flow
export default {
  minUsernameLength: 3,
  maxUsernameLength: 25,
  minOrgNameLength: 3,
  maxOrgNameLength: 25,
  maxOrgDescriptionLength: 100,
  minProjectNameLength: 1,
  maxProjectNameLength: 25,
  maxProjectAboutLength: 10000,
  maxProjectDescriptionLength: 200,
  minSectionNameLength: 1,
  maxSectionNameLength: 56,
  minBranchNameLength: 1,
  maxBranchNameLength: 56,
  maxBranchDescriptionLength: 100,
  minFileNameLength: 1,
  maxFileNameLength: 256,
  maxCollectionDescriptionLength: 2000,
  minCommitTitleLength: 1,
  maxCommitTitleLength: 72,
  maxCommitDescriptionLength: 2000,
  minCommentLength: 1,
  maxCommentLength: 2000,
  maxEmojiPerComment: 25,
  minTeamNameLength: 1,
  maxTeamNameLength: 56,
  maxDecisionTitleLength: 50,
  maxDecisionDescriptionLength: 2000,

  // Validation regex for branch name. This is a string that we pass into HTML,
  // not an actual regex, so we can't use flags for, say, case insensitivity.
  // Bans:
  // 1. Empty strings
  // 2. Strings with only spaces
  // 3. "master" (case insensitive)
  // 4. "main" (case insensitive)
  isSafeBranchName:
    "(^(?!(([Mm][Aa][Ss][Tt][Ee][Rr]|[Mm][Aa][Ii][Nn])(?!.)|(\\s*$))).*$)",
};
