// @flow
import AbstractConfig from "abstract-di/config";
import fetch from "core/lib/fetchWithTrace";
import * as Request from "core/models/request";
import type { AccessRequest } from "core/types";

const DEFAULT_ERROR = "default_error";

export class UnauthorizedError extends Request.UnauthorizedError {
  body: Object;
  code: string;
  constructor(body: Object = {}, message: string = "Unauthorized request") {
    super(message);
    this.body = body;
    this.code = body.error ? body.error.code : DEFAULT_ERROR;
  }
}

export class ForbiddenError extends Request.ForbiddenError {
  body: AccessRequest | Object;
  code: string;
  constructor(body: Object = {}, message: string = "Request forbidden") {
    super(message);
    this.body = body;
    this.code = body.error ? body.error.code : DEFAULT_ERROR;
  }
}

export class NotFoundError extends Request.NotFoundError {
  body: Object;
  code: string;
  constructor(body: Object = {}, message: string = "Request not found") {
    super(message);
    this.body = body;
    this.code = body.error ? body.error.code : DEFAULT_ERROR;
  }
}

export class ValidationError extends Request.ValidationError {
  body: Object;
  code: string;
  constructor(body: Object = {}, message?: string) {
    // If the response includes a single validation error, make that the error
    // message for convenience
    if (
      !message &&
      body.errors &&
      body.errors.message &&
      body.errors.message.length === 1
    ) {
      message = body.errors.message[0];
    }

    super(body.errors || {}, message);
    this.body = body;
    this.code = body.error ? body.error.code : DEFAULT_ERROR;
  }
}

export class UnexpectedError extends Request.UnexpectedError {
  body: Object;
  code: string;
  constructor(
    body: Object = {},
    message: string = "Unexpected request status code"
  ) {
    super(message);
    this.body = body;
    this.code = body.error ? body.error.code : DEFAULT_ERROR;
  }
}

const KNOWN_ERRORS = {
  "401": UnauthorizedError,
  "403": ForbiddenError,
  "404": NotFoundError,
  "422": ValidationError,
};

export function addToken(token: ?string, headers: { [key: string]: string }) {
  if (!token) {
    return headers;
  }

  return { Authorization: `Bearer ${token}`, ...headers };
}

export async function handleError(response: Response) {
  let body;

  try {
    body = await response.json();
  } catch (err) {
    console.error(err);
  }

  let ApiError = KNOWN_ERRORS[response.status];
  if (!ApiError) {
    ApiError = UnexpectedError;
  }
  return new ApiError(body);
}

/**
 * A thin wrapper around the `fetch` API that constructs the URL from a
 * configurable base URL, the given endpoint and the given token.
 *
 * @param method (String)   - get|post|put|delete
 * @param endpoint {String} - The API endpoint. Ex: 'user/sign_in'.
 * @param token {String}    - The authentication token with which to make the request.
 * @param options {Object}  - Object that is passed directly to `fetch`. See fetch documentation.
 *
 * @returns Promise returned by `fetch`.
 */
export function apiRequest(
  method: "get" | "post" | "put" | "delete",
  endpoint: string,
  token: ?string,
  optionOverrides: Object = {}
) {
  const url = `${AbstractConfig.apiURL}/${endpoint}`;
  const options = optionOverrides;
  options.method = method;

  options.headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...addToken(token, options.headers),
  };

  if (options.body) {
    options.body = JSON.stringify(options.body);
  }

  return fetch(url, options);
}

export async function authRequest(endpoint: string, options: Object = {}) {
  const authURL = await AbstractConfig.getAuthURL();

  const url = `${authURL}/${endpoint}`;

  return fetch(url, options);
}
