// @flow
import React from "react";
import Select from "core/components/Select";
import type { LibrarySuggestions } from "./types";

type Props = {
  libraryId: string,
  value: string,
  onChange: (e: any) => any,
  librarySuggestions: LibrarySuggestions,
};

export default function SelectLibrary(props: Props) {
  const { libraryId, value, onChange, librarySuggestions } = props;
  const suggestedLibrary =
    librarySuggestions.suggestedLibrariesMap[libraryId] || {};
  return (
    <Select defaultValue={libraryId} value={value} onChange={onChange}>
      <option value="">Select a Library…</option>
      {librarySuggestions.potentialLibraries.map((availableLib, key) => {
        const name =
          suggestedLibrary.id === availableLib.id &&
          libraryId !== availableLib.id
            ? `[suggested] ${availableLib.name}`
            : availableLib.name;
        return (
          <option value={availableLib.id} key={key}>
            {name}.sketch
          </option>
        );
      })}
    </Select>
  );
}
