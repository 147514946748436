// @flow
import classnames from "classnames";
import type { Cancelable } from "lodash";
import React from "react";
import { Waypoint } from "react-waypoint";
import Centered from "core/components/Centered";
import CollectionThumbnail from "core/components/CollectionThumbnail";
import Error from "core/components/Empty/Error";
import NoCollections from "core/components/Empty/NoCollections";
import NoResults from "core/components/Empty/NoResults";
import type { Item } from "core/components/FilterPopover";
import Loaded from "core/components/Loaded";
import Spinner from "core/components/Spinner";
import VirtualizedList, { type Row } from "core/components/VirtualizedList";
import type {
  User,
  Collection,
  Project,
  CollectionBranchFilter,
} from "core/types";
import FilteredItemsFooter from "./FilteredItemsFooter";
import Header from "./Header";
import style from "./style.scss";

type Props = {
  project: ?Project,
  authorFilterItems: Item[],
  projectId: string,
  collections: Collection[],
  totalNumberOfCollections: ?number,
  selectedAuthor: ?User,
  onSelectAuthor: (userId: ?string) => void,
  search: string,
  onChangeSearchFilter: (searchFilter: string) => void,
  onClearFilters: () => void,
  canCreateCollectionsOnMaster: boolean,
  branchFilter: CollectionBranchFilter,
  scrollableRef?: (*) => *,
  isFirstLoading: boolean,
  isLoading: boolean,
  hasError: boolean,
  isOffline: boolean,
  onLoadMoreCollections: (() => Promise<void>) & Cancelable,
  hasBeenFiltered: boolean,
  showNoCollections: boolean,
  showFilteredItemsDisclaimer: boolean,
  showNoSearchResults: boolean,
};

type State = {
  showSearchCancel: boolean,
};

export default class ProjectCollections extends React.Component<Props, State> {
  state = { showSearchCancel: false };

  listRef = React.createRef<VirtualizedList>();

  componentDidMount() {
    if (this.props.selectedAuthor) {
      this.props.onSelectAuthor("");
    }
  }

  handleClearFilters = () => {
    this.props.onChangeSearchFilter("");
    this.setState({ showSearchCancel: false });

    const list = this.listRef.current;
    if (list) {
      list.scrollTo({ index: 0 });
    }
  };

  handleSelectAuthor = (userId: ?string) => {
    this.props.onSelectAuthor(userId);
  };

  toggleCancelButton = (searchFilter: string) => {
    if (searchFilter !== "") {
      if (!this.state.showSearchCancel) {
        this.setState({ showSearchCancel: true });
      }
    }

    if (searchFilter === "") {
      if (this.state.showSearchCancel) {
        this.setState({ showSearchCancel: false });
      }
    }
  };

  handleBlur = () => {
    if (!this.props.search) {
      this.setState({ showSearchCancel: false });
    }
  };

  getItems = (): Row[] => {
    let items = this.props.collections.map((collection, index) => ({
      height: 96,
      children: (
        <CollectionThumbnail
          key={collection.id}
          projectId={this.props.projectId}
          collectionId={collection.id}
          useShorthandDate
          mobile
          qaSelector={`collection-thumbnail-${index}`}
        />
      ),
    }));

    if (this.props.showFilteredItemsDisclaimer) {
      items.push({
        height: 280,
        children: (
          <FilteredItemsFooter
            onClearFilters={this.handleClearFilters}
            onClearAuthorFilter={() => this.props.onSelectAuthor(null)}
            onClearSearchFilter={() => this.props.onChangeSearchFilter("")}
            numberOfVisibleCollections={this.props.collections.length}
            totalNumberOfCollections={this.props.totalNumberOfCollections}
            selectedAuthorName={
              this.props.selectedAuthor ? this.props.selectedAuthor.name : null
            }
            searchTerm={this.props.search}
          />
        ),
      });
    } else if (this.props.isLoading) {
      items.push({
        height: 96,
        children: (
          <Centered>
            <Spinner small />
          </Centered>
        ),
      });
    } else {
      items.push({
        height: 96,
        children: <Waypoint onEnter={this.props.onLoadMoreCollections} />,
      });
    }

    return items;
  };

  renderBody() {
    if (this.props.showNoSearchResults) {
      return (
        <NoResults
          term={this.props.search}
          onClearFilters={this.props.onClearFilters}
        />
      );
    }

    if (this.props.showNoCollections) {
      return <NoCollections />;
    }

    return (
      <div
        className={classnames(
          style.projectCollections,
          style.mobileMainContent
        )}
      >
        <VirtualizedList
          ref={this.listRef}
          items={this.getItems()}
          resizeProps={{
            isLoading: this.props.isLoading,
            totalNumberOfCollections: this.props.totalNumberOfCollections,
            hasBeenFiltered: this.props.hasBeenFiltered,
          }}
        />
      </div>
    );
  }

  render() {
    if (this.props.hasError && !this.props.isOffline) {
      return <Error />;
    }

    return (
      <React.Fragment>
        <Header
          canCreateCollectionsOnMaster={this.props.canCreateCollectionsOnMaster}
          authorFilterItems={this.props.authorFilterItems}
          onSelectAuthor={this.props.onSelectAuthor}
          onChangeSearchFilter={this.props.onChangeSearchFilter}
          projectId={this.props.projectId}
          searchFilter={this.props.search}
          selectedAuthor={this.props.selectedAuthor}
          branchFilter={this.props.branchFilter}
          mobile
        />
        <Loaded
          loading={this.props.isLoading && this.props.collections.length === 0}
          title="Loading collections…"
          flex
        >
          {this.renderBody()}
        </Loaded>
      </React.Fragment>
    );
  }
}
