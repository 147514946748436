// @flow
import classnames from "classnames";
import idx from "idx";
import { groupBy } from "lodash";
import * as React from "react";
import { Inspector as BaseInspector, chromeLight } from "react-inspector";
import config from "abstract-di/config";
import history from "abstract-di/history";
import Button from "core/components/Button";
import ColorSwatch from "core/components/ColorSwatch";
import CopyToClipboard from "core/components/CopyToClipboard";
import Flex from "core/components/Flex";
import Icon from "core/components/Icon";
import LayerIcon from "core/components/LayerIcon";
import Popover from "core/components/Popover";
import PropertyRow from "core/components/PropertyRow";
import PropertyValue from "core/components/PropertyValue";
import Formatter, {
  type FormatterOptions,
} from "core/components/PropertyValue/Formatter";
import Scrollable from "core/components/Scrollable";
import SidebarAccordion from "core/components/SidebarAccordion";
import SidebarSubSection from "core/components/SidebarSubSection";
import connectStorage from "core/hocs/connectStorage";
import { Abstract } from "core/lib/abstract";
import { push } from "core/lib/location";
import { readInferred } from "core/lib/mapLayerChildren";
import { modifierKeyPressed } from "core/lib/platform";
import { layerLocation } from "core/lib/routes";
import * as LayerDataModel from "core/models/layerData";
import type {
  Layer,
  LayerData,
  LayerChangeset,
  LayerTextStyle,
  BasicLayerType,
} from "core/types";
import AssetsSection from "./AssetsSection";
import InspectOptionsButton from "./InspectOptionsButton";
import PropertyList from "./PropertyList";
import { default as PrototypeSection } from "./PrototypeSection";
import * as constants from "./constants";
import style from "./style.scss";
import toCodeData from "./toCodeData";

const chromeSystem = {
  ...chromeLight,
  OBJECT_NAME_COLOR: "var(--text-color)",

  ARROW_COLOR: "var(--placeholder-text-color)",
  OBJECT_VALUE_NULL_COLOR: "var(--placeholder-text-color)",
  OBJECT_VALUE_UNDEFINED_COLOR: "var(--placeholder-text-color)",
  OBJECT_VALUE_FUNCTION_PREFIX_COLOR: "var(--placeholder-text-color)",

  OBJECT_VALUE_REGEXP_COLOR: "var(--purple-text-color)",
  OBJECT_VALUE_STRING_COLOR: "var(--purple-text-color)",
  OBJECT_VALUE_SYMBOL_COLOR: "var(--purple-text-color)",
  OBJECT_VALUE_NUMBER_COLOR: "var(--purple-text-color)",
  OBJECT_VALUE_BOOLEAN_COLOR: "var(--purple-text-color)",

  BASE_COLOR: "var(--text-color)",
  BASE_BACKGROUND_COLOR: "var(--content-background)",
  BASE_FONT_FAMILY: "Menlo, Consolas, monospace",
  BASE_FONT_SIZE: "13px",
  BASE_LINE_HEIGHT: "18px",
};

export type OwnProps = {|
  params: Abstract.LayerVersionDescriptor,
  isRootLayer: boolean,
  error?: string,
  layerData: ?LayerData,
  layerType: BasicLayerType,
  layerChangeset?: LayerChangeset,
  selectedLayerKey: string,
  symbolMasterLayerData?: ?Layer,
  symbolMasterLayerDataIsLoading?: boolean,
  symbolMasterLayerDataError?: boolean,
  showGoToSymbolMaster: boolean,
  showAssets: ?boolean,
  showPrototypes?: boolean,
  showDevelopment?: boolean,
  canGenerateAssets: boolean,
  canUseSymbolSourceDisplayName: boolean,
  showChangeset?: boolean,
  onSelectLayer?: (layerId: string) => void,
  onToggleShowChangeset?: () => void,
  showLayoutOverlay: boolean,
  onToggleLayoutOverlay: () => void,
  showGridOverlay: boolean,
  onToggleGridOverlay: () => void,
  isGridEnabled: boolean,
  isLayoutEnabled: boolean,
  onToggleGuidesOverlay: () => void,
  showGuidesOverlay: boolean,
  isGuidesEnabled: boolean,
  isPublicShare: boolean,
|};

export type StorageProps = {|
  formatterOptions?: FormatterOptions,
  onUpdateFormatterOptions?: (formatterOptions: FormatterOptions) => void,
|};

export type Props = {
  ...OwnProps,
  ...StorageProps,
};

function groupTextStyleIndex(
  textStyleIndex: LayerTextStyle[]
): Array<LayerTextStyle[]> {
  // $FlowFixMe: Object.values always returns mixed type...
  return Object.values(groupBy(textStyleIndex, "fontName"));
}

const Inspector = (props) => <BaseInspector {...props} theme={chromeSystem} />;

class LayerProperties extends React.PureComponent<Props> {
  static defaultProps = {
    canGenerateAssets: false,
    formatterOptions: {},
  };

  disableContentEditable = (event: SyntheticKeyboardEvent<HTMLElement>) => {
    // Allow all commands except ctrl+v (Paste)
    if (modifierKeyPressed(event) && event.keyCode !== 86) {
      return;
    }
    // Allow navigation keys
    if (event.keyCode >= 33 && event.keyCode <= 40) {
      return;
    }
    // Allow tab?
    if (event.keyCode === 9) {
      return;
    }

    event.preventDefault();
  };

  render() {
    const {
      params,
      layerData,
      layerChangeset,
      onSelectLayer,
      showAssets,
      canUseSymbolSourceDisplayName,
      symbolMasterLayerData,
      symbolMasterLayerDataIsLoading,
      symbolMasterLayerDataError,
      showGoToSymbolMaster,
    } = this.props;

    const filteredColorIndex = (
      idx(layerData, (_) => _.properties.colorIndex) || []
    ).filter((color) => color.components.alpha > 0);

    const formatter = new Formatter(this.props.formatterOptions);
    const codeData = toCodeData(layerData);
    const layerNameChangedTooltip =
      layerChangeset &&
      layerChangeset.properties.name &&
      layerChangeset.properties.name.status === "changed"
        ? `Previous value: ${layerChangeset.properties.name.previous}`
        : undefined;

    const symbolMasterDescriptor =
      layerData && layerData.symbolMasterDescriptor;
    const shouldShowGoToSymbolMasterButton =
      showGoToSymbolMaster &&
      layerData &&
      layerData.type === "symbolInstance" &&
      symbolMasterDescriptor &&
      !symbolMasterLayerDataError;
    const symbolMasterIsLocal =
      symbolMasterDescriptor &&
      symbolMasterDescriptor.branchId === params.branchId &&
      symbolMasterDescriptor.projectId === params.projectId;

    const originalSymbolInstanceLocation =
      history.getCurrentLocation().state &&
      history.getCurrentLocation().state.originalSymbolInstanceLocation;

    return (
      <React.Fragment>
        {layerData && (
          <div className={style.layerDetailsWrapper}>
            <Popover
              forceHide={!shouldShowGoToSymbolMasterButton}
              placement="bottom"
              label={
                // symbolMaster is passed because layerData.type is
                // symbolInstance, but we need the label for symbolMaster
                !symbolMasterLayerDataError
                  ? `Go to ${LayerDataModel.typeLabel(
                      "symbolMaster",
                      canUseSymbolSourceDisplayName
                    )}`
                  : `Error loading ${LayerDataModel.typeLabel(
                      "symbolMaster",
                      canUseSymbolSourceDisplayName
                    )}`
              }
              display="block"
            >
              <div
                className={classnames(style.layerDetails, {
                  [style.goToMasterButton]: shouldShowGoToSymbolMasterButton,
                  [style.layerDetailsNameLoading]:
                    shouldShowGoToSymbolMasterButton &&
                    symbolMasterLayerDataIsLoading &&
                    !symbolMasterLayerData,
                })}
                onClick={
                  shouldShowGoToSymbolMasterButton &&
                  symbolMasterDescriptor &&
                  !symbolMasterLayerDataError &&
                  symbolMasterLayerData
                    ? () => {
                        push(
                          layerLocation(
                            symbolMasterDescriptor.projectId,
                            symbolMasterDescriptor.branchId,
                            !symbolMasterIsLocal
                              ? "latest"
                              : symbolMasterLayerData.lastChangedAtSha,
                            symbolMasterDescriptor.fileId,
                            symbolMasterDescriptor.layerId,
                            {
                              mode: "build",
                              originalSymbolInstanceLocation:
                                originalSymbolInstanceLocation ||
                                history.getCurrentLocation(),
                            }
                          )
                        );
                      }
                    : undefined
                }
              >
                <LayerIcon layer={layerData} large />
                <div className={style.layerDetailsRight}>
                  <Popover
                    label={layerNameChangedTooltip}
                    disabled={layerNameChangedTooltip === undefined}
                    placement="bottom"
                  >
                    {(_, popoverRef, popoverHandlers) => (
                      <div
                        {...popoverHandlers}
                        className={classnames(style.layerDetailsName, {
                          [style.layerNameChanged]:
                            layerNameChangedTooltip !== undefined,
                        })}
                      >
                        <span ref={popoverRef}>
                          {symbolMasterLayerData && showGoToSymbolMaster
                            ? symbolMasterLayerData.name
                            : layerData.properties.name}
                        </span>
                      </div>
                    )}
                  </Popover>
                  <div className={style.layerDetailsType}>
                    {LayerDataModel.typeLabel(
                      layerData.type,
                      canUseSymbolSourceDisplayName
                    )}
                  </div>
                </div>
                {shouldShowGoToSymbolMasterButton && (
                  <Icon
                    className={classnames(style.goToMasterButtonIcon)}
                    type="arrow-large-right"
                    tint
                  />
                )}
              </div>
            </Popover>
          </div>
        )}
        <Scrollable>
          {layerData && layerData.properties.textContent && (
            <SidebarAccordion id="content" label="Content">
              <PropertyRow className={style.contentPropertyRow}>
                <PropertyValue
                  value={layerData.properties.textContent}
                  change={
                    layerChangeset && layerChangeset.properties.textContent
                  }
                  formatter={formatter.textContent}
                  size="full"
                  truncate={false}
                />
              </PropertyRow>
            </SidebarAccordion>
          )}
          {layerData &&
            !constants.ROOT_LAYER_TYPES.includes(layerData.type) &&
            codeData.length > 0 && (
              <CopyToClipboard value={codeData.join("\n")} toast="Copied Style">
                {(setClickToCopyRef) => (
                  <SidebarAccordion
                    id="style"
                    label="Style"
                    right={
                      <Button innerRef={setClickToCopyRef} nude tint>
                        Copy
                      </Button>
                    }
                  >
                    <div
                      className={style.code}
                      spellCheck="false"
                      data-gramm="false" // Disable modification by grammarly https://github.com/ianstormtaylor/slate/issues/733
                      onKeyDown={this.disableContentEditable}
                      onPaste={this.disableContentEditable}
                      onCut={this.disableContentEditable}
                      suppressContentEditableWarning // disableContentEditable prevents modification
                      contentEditable
                    >
                      {codeData.sort().map((line, index) => {
                        const matches =
                          line.match(constants.CSS_PROPERTY) || [];
                        const property = matches[1];
                        const value = matches[2];

                        return (
                          <div className={style.codeLine} key={index}>
                            {property}:{" "}
                            <span className={style.codeValue}>{value}</span>;
                          </div>
                        );
                      })}
                    </div>
                  </SidebarAccordion>
                )}
              </CopyToClipboard>
            )}
          {layerData && (
            <SidebarAccordion id="properties" label="Properties">
              {layerData.type === "artboard" ? (
                <PropertyRow>
                  <PropertyValue
                    label="Width"
                    value={layerData.properties.width}
                    change={layerChangeset && layerChangeset.properties.width}
                    formatter={formatter.unit}
                  />
                  <PropertyValue
                    label="Height"
                    value={layerData.properties.height}
                    change={layerChangeset && layerChangeset.properties.height}
                    formatter={formatter.unit}
                  />
                  {layerData.properties.backgroundColor && (
                    <PropertyValue
                      label="Fill"
                      value={layerData.properties.backgroundColor}
                      change={
                        layerChangeset &&
                        layerChangeset.properties.backgroundColor
                      }
                      formatter={formatter.backgroundColor}
                      size="double"
                    />
                  )}
                </PropertyRow>
              ) : (
                <React.Fragment>
                  {layerData.properties && layerData.properties.styleName && (
                    <PropertyRow>
                      <PropertyValue
                        label="Layer Style"
                        value={layerData.properties.styleName}
                        change={
                          layerChangeset && layerChangeset.properties.styleName
                        }
                        formatter={formatter.styleName}
                        size="full"
                        truncate={false}
                      />
                    </PropertyRow>
                  )}
                  {layerData.properties.text &&
                    layerData.properties.text.styleName && (
                      <PropertyRow>
                        <PropertyValue
                          label="Text Style"
                          value={layerData.properties.text.styleName}
                          change={
                            layerChangeset &&
                            layerChangeset.properties.text &&
                            layerChangeset.properties.text.styleName
                          }
                          formatter={formatter.styleName}
                          size="full"
                          truncate={false}
                        />
                      </PropertyRow>
                    )}
                  <PropertyRow>
                    <PropertyValue
                      label="Width"
                      value={layerData.properties.width}
                      change={layerChangeset && layerChangeset.properties.width}
                      formatter={formatter.unit}
                      estimated={
                        layerData._layoutUnknown &&
                        layerData._layoutUnknown.width
                      }
                      tooltip={
                        layerData._layoutUnknown &&
                        layerData._layoutUnknown.width
                          ? "The width of this element is an estimate since the layout for text overrides is not stored in the original file"
                          : undefined
                      }
                    />
                    <PropertyValue
                      label="Height"
                      value={layerData.properties.height}
                      change={
                        layerChangeset && layerChangeset.properties.height
                      }
                      formatter={formatter.unit}
                    />
                    <PropertyValue
                      label="X"
                      value={layerData.properties.x}
                      change={layerChangeset && layerChangeset.properties.x}
                      formatter={formatter.unit}
                      estimated={
                        layerData._layoutUnknown && layerData._layoutUnknown.x
                      }
                      tooltip={
                        layerData._layoutUnknown && layerData._layoutUnknown.x
                          ? "The x position of this element is an estimate since the layout for text overrides is not stored in the original file"
                          : undefined
                      }
                    />
                    <PropertyValue
                      label="Y"
                      value={layerData.properties.y}
                      change={layerChangeset && layerChangeset.properties.y}
                      formatter={formatter.unit}
                    />
                  </PropertyRow>
                  <PropertyRow>
                    <PropertyValue
                      label="Opacity"
                      value={layerData.properties.opacity}
                      change={
                        layerChangeset && layerChangeset.properties.opacity
                      }
                      formatter={formatter.percentage}
                      size="double"
                    />
                    <PropertyValue
                      label="Blending"
                      value={layerData.properties.blendMode}
                      change={
                        layerChangeset && layerChangeset.properties.blendMode
                      }
                      formatter={formatter.blendMode}
                      size="double"
                    />
                  </PropertyRow>
                  <PropertyRow>
                    <PropertyValue
                      label="Rotation"
                      value={layerData.properties.rotation}
                      change={
                        layerChangeset && layerChangeset.properties.rotation
                      }
                      formatter={formatter.degrees}
                      size="double"
                    />
                    {layerData.properties.borderRadius && (
                      <PropertyValue
                        label="Radius"
                        value={layerData.properties.borderRadius}
                        change={
                          layerChangeset &&
                          layerChangeset.properties.borderRadius
                        }
                        formatter={formatter.borderRadius}
                        size="double"
                      />
                    )}
                  </PropertyRow>
                </React.Fragment>
              )}
              {layerData.properties.fills && (
                <SidebarSubSection
                  label="Backgrounds"
                  change={layerChangeset && layerChangeset.properties.fills}
                >
                  <PropertyList
                    value={layerData.properties.fills}
                    change={layerChangeset && layerChangeset.properties.fills}
                  >
                    {(status, fill, index) => (
                      <React.Fragment key={index}>
                        <PropertyRow>
                          <PropertyValue
                            label={index === 0 ? "Fill" : undefined}
                            value={fill}
                            status={status}
                            formatter={formatter.fill}
                            size="double"
                            tooltip={
                              layerData.properties.tint &&
                              fill.fillType === constants.LAYER_FILL_TYPE_SOLID
                                ? "This fill is affected by a tint."
                                : undefined
                            }
                          />
                          <PropertyValue
                            label={index === 0 ? "Blending" : undefined}
                            status={status}
                            value={constants.BLEND_MODE_LABELS[fill.blendMode]}
                          />
                          <PropertyValue
                            label={index === 0 ? "Opacity" : undefined}
                            status={status}
                            value={
                              fill.fillType === constants.LAYER_FILL_TYPE_SOLID
                                ? fill.color.components.alpha * 100
                                : fill.opacity
                            }
                            tooltip={
                              idx(
                                layerData,
                                (_) => _.properties.tint.color.components.alpha
                              ) !== 1 &&
                              fill.fillType === constants.LAYER_FILL_TYPE_SOLID
                                ? "This opacity is affected by a tint."
                                : undefined
                            }
                            formatter={formatter.percentage}
                          />
                        </PropertyRow>
                        {fill.fillType === constants.LAYER_FILL_TYPE_GRADIENT &&
                          fill.gradient.stops.map((stop) => (
                            <PropertyRow
                              key={stop.position}
                              className={style.gradientStopRow}
                              grouped
                            >
                              <PropertyValue
                                value={stop.position}
                                status={status}
                                formatter={formatter.gradientPosition}
                              />
                              <PropertyValue
                                value={stop.color}
                                status={status}
                                formatter={formatter.colorStop}
                                size="double"
                                tooltip={
                                  layerData.properties.tint
                                    ? "This gradient is affected by a tint."
                                    : undefined
                                }
                              />
                              <PropertyValue
                                value={stop.color.components.alpha}
                                status={status}
                                formatter={formatter.alpha}
                                tooltip={
                                  idx(
                                    layerData,
                                    (_) =>
                                      _.properties.tint.color.components.alpha
                                  ) !== 1
                                    ? "This opacity is affected by a tint."
                                    : undefined
                                }
                              />
                            </PropertyRow>
                          ))}
                      </React.Fragment>
                    )}
                  </PropertyList>
                </SidebarSubSection>
              )}
              {layerData.properties.borders &&
                layerData.properties.borders.length > 0 && (
                  <SidebarSubSection
                    label="Borders"
                    change={layerChangeset && layerChangeset.properties.borders}
                  >
                    <PropertyList
                      value={layerData.properties.borders}
                      change={
                        layerChangeset && layerChangeset.properties.borders
                      }
                    >
                      {(status, border, index) => (
                        <React.Fragment key={index}>
                          <PropertyRow>
                            {border.fillType ===
                              constants.LAYER_FILL_TYPE_SOLID && (
                              <PropertyValue
                                label={index === 0 ? "Color" : undefined}
                                value={border.color}
                                status={status}
                                formatter={formatter.borderColor}
                                size="double"
                                tooltip={
                                  layerData.properties.tint
                                    ? "This border is affected by a tint."
                                    : undefined
                                }
                              />
                            )}
                            <PropertyValue
                              label={index === 0 ? "Size" : undefined}
                              value={border.thickness}
                              status={status}
                              formatter={formatter.unit}
                            />
                            <PropertyValue
                              label={index === 0 ? "Position" : undefined}
                              value={border.position}
                              status={status}
                              formatter={formatter.borderPosition}
                            />
                          </PropertyRow>
                          {border.fillType ===
                            constants.LAYER_FILL_TYPE_GRADIENT &&
                            border.gradient.stops.map((stop) => (
                              <PropertyRow
                                key={stop.position}
                                className={style.gradientStopRow}
                                grouped
                              >
                                <PropertyValue
                                  value={stop.position}
                                  status={status}
                                  formatter={formatter.gradientPosition}
                                />
                                <PropertyValue
                                  value={stop.color}
                                  status={status}
                                  formatter={formatter.colorStop}
                                  size="double"
                                  tooltip={
                                    layerData.properties.tint
                                      ? "This gradient is affected by a tint."
                                      : undefined
                                  }
                                />
                                <PropertyValue
                                  value={stop.color.components.alpha}
                                  status={status}
                                  formatter={formatter.alpha}
                                  tooltip={
                                    idx(
                                      layerData,
                                      (_) =>
                                        _.properties.tint.color.components.alpha
                                    ) !== 1
                                      ? "This opacity is affected by a tint."
                                      : undefined
                                  }
                                />
                              </PropertyRow>
                            ))}
                        </React.Fragment>
                      )}
                    </PropertyList>
                  </SidebarSubSection>
                )}
              {layerData.properties.shadows &&
                layerData.properties.shadows.outer && (
                  <SidebarSubSection
                    label="Shadows"
                    change={
                      layerChangeset &&
                      layerChangeset.properties.shadows &&
                      layerChangeset.properties.shadows.outer
                    }
                  >
                    <PropertyList
                      value={layerData.properties.shadows.outer}
                      change={
                        layerChangeset &&
                        layerChangeset.properties.shadows &&
                        layerChangeset.properties.shadows.outer
                      }
                    >
                      {(status, shadow, index) => (
                        <PropertyRow key={index}>
                          <PropertyValue
                            label={index === 0 ? "Color" : undefined}
                            value={shadow.color}
                            status={status}
                            formatter={formatter.outerShadowColor}
                            size="double"
                          />
                          <PropertyValue
                            label={index === 0 ? "X" : undefined}
                            value={shadow.x}
                            status={status}
                            formatter={formatter.unit}
                            size="half"
                          />
                          <PropertyValue
                            label={index === 0 ? "Y" : undefined}
                            value={shadow.y}
                            status={status}
                            formatter={formatter.unit}
                            size="half"
                          />
                          <PropertyValue
                            title="Blur"
                            label={index === 0 ? "B" : undefined}
                            value={shadow.blurRadius}
                            status={status}
                            formatter={formatter.unit}
                            size="half"
                          />
                          <PropertyValue
                            title="Spread"
                            label={index === 0 ? "S" : undefined}
                            value={shadow.spread}
                            status={status}
                            formatter={formatter.unit}
                            size="half"
                          />
                        </PropertyRow>
                      )}
                    </PropertyList>
                  </SidebarSubSection>
                )}
              {layerData.properties.shadows &&
                layerData.properties.shadows.inner && (
                  <SidebarSubSection
                    label="Inner Shadows"
                    change={
                      layerChangeset &&
                      layerChangeset.properties.shadows &&
                      layerChangeset.properties.shadows.inner
                    }
                  >
                    <PropertyList
                      value={layerData.properties.shadows.inner}
                      change={
                        layerChangeset &&
                        layerChangeset.properties.shadows &&
                        layerChangeset.properties.shadows.inner
                      }
                    >
                      {(status, shadow, index) => (
                        <PropertyRow key={index}>
                          <PropertyValue
                            label={index === 0 ? "Color" : undefined}
                            value={shadow.color}
                            status={status}
                            formatter={formatter.innerShadowColor}
                            size="double"
                          />
                          <PropertyValue
                            label={index === 0 ? "X" : undefined}
                            value={shadow.x}
                            status={status}
                            formatter={formatter.unit}
                            size="half"
                          />
                          <PropertyValue
                            label={index === 0 ? "Y" : undefined}
                            value={shadow.y}
                            status={status}
                            formatter={formatter.unit}
                            size="half"
                          />
                          <PropertyValue
                            title="Blur"
                            label={index === 0 ? "B" : undefined}
                            value={shadow.blurRadius}
                            status={status}
                            formatter={formatter.unit}
                            size="half"
                          />
                          <PropertyValue
                            title="Spread"
                            label={index === 0 ? "S" : undefined}
                            value={shadow.spread}
                            status={status}
                            formatter={formatter.unit}
                            size="half"
                          />
                        </PropertyRow>
                      )}
                    </PropertyList>
                  </SidebarSubSection>
                )}
              {layerData.properties.text && (
                <SidebarSubSection label="Typography">
                  <PropertyRow>
                    <PropertyValue
                      label="Typeface"
                      value={layerData.properties.text.fontName}
                      change={
                        layerChangeset &&
                        layerChangeset.properties.text &&
                        layerChangeset.properties.text.fontName
                      }
                      formatter={formatter.fontName}
                      size="full"
                    />
                  </PropertyRow>
                  <PropertyRow>
                    <PropertyValue
                      label="Size"
                      value={layerData.properties.text.fontSize}
                      change={
                        layerChangeset &&
                        layerChangeset.properties.text &&
                        layerChangeset.properties.text.fontSize
                      }
                      formatter={formatter.fontSize}
                      size="double"
                    />
                    <PropertyValue
                      label="Line Height"
                      value={layerData.properties.text.lineHeight}
                      change={
                        layerChangeset &&
                        layerChangeset.properties.text &&
                        layerChangeset.properties.text.lineHeight
                      }
                      formatter={formatter.lineHeight}
                      size="double"
                    />
                  </PropertyRow>
                  <PropertyRow>
                    <PropertyValue
                      label="Color"
                      value={layerData.properties.text.color}
                      change={
                        layerChangeset &&
                        layerChangeset.properties.text &&
                        layerChangeset.properties.text.color
                      }
                      formatter={formatter.textColor}
                      size="double"
                    />
                    <PropertyValue
                      label="Text Transform"
                      value={layerData.properties.text.textTransform}
                      change={
                        layerChangeset &&
                        layerChangeset.properties.text &&
                        layerChangeset.properties.text.textTransform
                      }
                      formatter={formatter.textTransform}
                      size="double"
                    />
                  </PropertyRow>
                  <PropertyRow>
                    <PropertyValue
                      label="Decoration"
                      value={layerData.properties.text.textDecoration}
                      change={
                        layerChangeset &&
                        layerChangeset.properties.text &&
                        layerChangeset.properties.text.textDecoration
                      }
                      formatter={formatter.textDecoration}
                      size="double"
                    />
                    <PropertyValue
                      label="List Type"
                      value={layerData.properties.text.listStyle}
                      change={
                        layerChangeset &&
                        layerChangeset.properties.text &&
                        layerChangeset.properties.text.listStyle
                      }
                      formatter={formatter.listStyle}
                      size="double"
                    />
                  </PropertyRow>
                  <PropertyRow>
                    <PropertyValue
                      label="Alignment"
                      value={layerData.properties.text.horizontalAlignment}
                      change={
                        layerChangeset &&
                        layerChangeset.properties.text &&
                        layerChangeset.properties.text.horizontalAlignment
                      }
                      formatter={formatter.alignment}
                      size="double"
                    />
                    <PropertyValue
                      label="Letter Spacing"
                      value={layerData.properties.text.characterSpacing}
                      change={
                        layerChangeset &&
                        layerChangeset.properties.text &&
                        layerChangeset.properties.text.characterSpacing
                      }
                      formatter={formatter.letterSpacing}
                      size="double"
                    />
                  </PropertyRow>
                  {layerData.properties.text.paragraphSpacing && (
                    <PropertyRow>
                      <PropertyValue
                        label="Paragraph Spacing"
                        value={layerData.properties.text.paragraphSpacing}
                        change={
                          layerChangeset &&
                          layerChangeset.properties.text &&
                          layerChangeset.properties.text.paragraphSpacing
                        }
                        formatter={formatter.paragraphSpacing}
                        size="double"
                      />
                    </PropertyRow>
                  )}
                </SidebarSubSection>
              )}
            </SidebarAccordion>
          )}
          {filteredColorIndex.length > 0 && (
            <SidebarAccordion id="colorIndex" label="Colors">
              <PropertyRow>
                <div className={style.colorGrid}>
                  {filteredColorIndex.map((color, index) => (
                    <div key={index} className={style.colorGridItem}>
                      <ColorSwatch
                        value={Formatter.cssFormatter.color.code(color)}
                        clipboardValue={formatter.color.code(color)}
                        size={24}
                        copyToClipboard
                      />
                    </div>
                  ))}
                </div>
              </PropertyRow>
            </SidebarAccordion>
          )}
          {layerData &&
            layerData.properties.textStyleIndex &&
            layerData.properties.textStyleIndex.length > 0 && (
              <SidebarAccordion id="textStyleIndex" label="Typography">
                {groupTextStyleIndex(layerData.properties.textStyleIndex).map(
                  (textStyleGroup, groupIndex) =>
                    textStyleGroup.map((text, index) => {
                      const isFirstItemInGroup = index === 0;
                      const isFirstItemInFirstGroup =
                        isFirstItemInGroup && groupIndex === 0;

                      return (
                        <PropertyRow key={index} grouped={!isFirstItemInGroup}>
                          <PropertyValue
                            label={
                              isFirstItemInFirstGroup ? "Typeface" : undefined
                            }
                            value={
                              isFirstItemInGroup ? text.fontName : undefined
                            }
                            formatter={formatter.fontName}
                            size="double"
                          />
                          <PropertyValue
                            label={isFirstItemInFirstGroup ? "Size" : undefined}
                            value={text.fontSize}
                            formatter={formatter.fontSize}
                          />
                          <PropertyValue
                            label={
                              isFirstItemInFirstGroup
                                ? "Line Height"
                                : undefined
                            }
                            value={text.lineHeight}
                            formatter={formatter.lineHeight}
                          />
                        </PropertyRow>
                      );
                    })
                )}
              </SidebarAccordion>
            )}
          {layerData && showAssets !== false && (
            <AssetsSection
              params={params}
              layerData={layerData}
              onSelectLayer={onSelectLayer}
              isRootLayer={this.props.isRootLayer}
              canGenerateAssets={this.props.canGenerateAssets}
              isPublicShare={this.props.isPublicShare}
            />
          )}
          {this.props.showPrototypes &&
            layerData &&
            layerData.properties.link && (
              <PrototypeSection
                params={params}
                link={layerData.properties.link}
                formatter={formatter}
              />
            )}
          {this.props.showDevelopment && layerData && (
            <SidebarAccordion id="development" label="Development">
              <PropertyRow>
                <PropertyValue
                  label="Layer ID"
                  value={layerData.id}
                  size="double"
                />
                <PropertyValue
                  label="Root"
                  value={
                    layerData._root ? layerData._root.properties.name : "None"
                  }
                  size="double"
                  disabled={!layerData._root}
                />
              </PropertyRow>
              <PropertyRow>
                <PropertyValue
                  label="Key"
                  size="full"
                  value={LayerDataModel.key(layerData)}
                />
              </PropertyRow>
              {layerData && (
                <SidebarSubSection label="Layer Data">
                  <PropertyRow>
                    <Inspector data={layerData} expandLevel={1} />
                  </PropertyRow>
                </SidebarSubSection>
              )}
              {layerData.properties.overrides && (
                <SidebarSubSection label="Overrides">
                  <PropertyRow>
                    <Inspector
                      data={layerData.properties.overrides}
                      expandLevel={1}
                    />
                  </PropertyRow>
                </SidebarSubSection>
              )}
              {(idx(layerData, (_) => _._root.properties.overrides) ||
                layerData.properties.overrides) && (
                <SidebarSubSection label="Inferred">
                  <PropertyRow>
                    <Inspector data={readInferred(layerData)} expandLevel={1} />
                  </PropertyRow>
                </SidebarSubSection>
              )}
              {this.props.layerChangeset && (
                <SidebarSubSection label="Layer Changeset">
                  <PropertyRow>
                    <Inspector
                      data={this.props.layerChangeset}
                      expandLevel={1}
                    />
                  </PropertyRow>
                </SidebarSubSection>
              )}
            </SidebarAccordion>
          )}
        </Scrollable>
        {this.props.error ? (
          <Flex className={style.footer} align="center">
            <div className={style.errorText} title={this.props.error}>
              {this.props.error}
            </div>
            <Button onClick={() => config.reload()}>Reload</Button>
          </Flex>
        ) : (
          <Flex className={style.footer} align="center" justify="flex-end">
            <InspectOptionsButton
              layerType={this.props.layerType}
              projectId={this.props.params.projectId}
              showLayoutOverlay={this.props.showLayoutOverlay}
              onToggleLayoutOverlay={this.props.onToggleLayoutOverlay}
              showGridOverlay={this.props.showGridOverlay}
              onToggleGridOverlay={this.props.onToggleGridOverlay}
              isGridEnabled={this.props.isGridEnabled}
              isLayoutEnabled={this.props.isLayoutEnabled}
              onToggleGuidesOverlay={this.props.onToggleGuidesOverlay}
              isGuidesEnabled={this.props.isGuidesEnabled}
              showGuidesOverlay={this.props.showGuidesOverlay}
              showChangeset={this.props.showChangeset}
              formatterOptions={this.props.formatterOptions}
              onToggleShowChangeset={this.props.onToggleShowChangeset}
              onUpdateFormatterOptions={this.props.onUpdateFormatterOptions}
            />
          </Flex>
        )}
      </React.Fragment>
    );
  }
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default connectStorage(
  LayerProperties,
  (storage, props): StorageProps => {
    const previous = storage.getItem("LayerProperties") || {};
    const defaultFormatterOptions = {
      unitLabel: "px",
      colorFormat: "hex6-rgb",
    };

    return {
      formatterOptions: previous.formatterOptions || defaultFormatterOptions,
      onUpdateFormatterOptions: (formatterOptions: FormatterOptions) => {
        const previous = storage.getItem("LayerProperties") || {};

        storage.setItem("LayerProperties", {
          ...previous,
          formatterOptions,
        });

        if (props.onUpdateFormatterOptions) {
          props.onUpdateFormatterOptions(formatterOptions);
        }
      },
    };
  }
);
