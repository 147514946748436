// @flow
import * as React from "react";
import { V3Link as Link } from "core/lib/router";
import { commitPath } from "core/lib/routes";
import ActivityBase from "./Base";
import type { ActivityCommit as TActivityCommit, Props } from "./types";

export default function ActivityCommit(props: Props<TActivityCommit>) {
  const { activity } = props;
  const commitUrl = commitPath(
    activity.projectId,
    activity.branchId,
    activity.payload.sha
  );

  return (
    <ActivityBase
      {...props}
      icon="commit"
      action="committed"
      branchId={activity.branchId}
      branchName={activity.payload.branchName}
      subject={<Link to={commitUrl}>{activity.payload.title}</Link>}
    />
  );
}
