// @flow
import classnames from "classnames";
import * as React from "react";
import type { StarParams } from "core/actions/stars";
import Button from "core/components/Button";
import Heading from "core/components/Heading";
import Icon from "core/components/Icon";
import Star from "core/components/Star";
import TextHighlight from "core/components/TextHighlight";
import { isDesktop } from "core/lib/platform";
import type { Project, Section } from "core/types";
import SectionMenu from "./Menu";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  canCreateSections?: boolean,
  filter?: string,
  projects: Project[],
  section: Section,
  organizationId: string,
  query?: string,
  isOffline: boolean,
  isCollapsed: boolean,
  onToggleCollapse: (sectionId: string) => void,
  onToggleStar: (StarParams) => (event: SyntheticEvent<>) => void,
  qaSelector?: string,
|};

export type StateProps = {|
  isStarred: boolean,
  section: Section,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
};

class ProjectSection extends React.Component<Props> {
  componentDidUpdate(prevProps: Props) {
    if (
      this.props.projects.length > prevProps.projects.length &&
      this.props.isCollapsed
    ) {
      this.handleToggleSection();
    }
  }

  handleToggleSection = () => {
    this.props.onToggleCollapse(this.props.section.id);
  };

  render() {
    const {
      section,
      projects,
      organizationId,
      onToggleStar,
      isOffline,
      isStarred,
      isCollapsed,
      query,
      canCreateSections,
      qaSelector,
    } = this.props;

    const empty = !projects.length;

    return (
      <div
        className={classnames({
          [style.collapsed]: isCollapsed,
          [style.starred]: isStarred,
          [style.empty]: !projects.length,
        })}
        data-qa={qaSelector}
      >
        <SectionMenu
          section={section}
          empty={empty}
          organizationId={organizationId}
        >
          {(showMenu, menuRef) => (
            <Heading
              size="l"
              level="3"
              className={style.title}
              onClick={this.handleToggleSection}
              onContextMenu={
                isDesktop && canCreateSections ? showMenu : undefined
              }
            >
              <Icon type="chevron-default-down" className={style.disclosure} />
              <span className={style.name}>
                <TextHighlight text={section.name} highlight={query} />
              </span>
              <span className={style.count}>{projects.length}</span>
              <span className={style.actions}>
                <Star
                  starred={isStarred}
                  className={style.star}
                  onClick={onToggleStar({ id: section.id, type: "section" })}
                />
                {canCreateSections && !isOffline && (
                  <Button
                    nude
                    className={style.menuButton}
                    icon="overflow"
                    onClick={showMenu}
                    innerRef={menuRef}
                  />
                )}
              </span>
            </Heading>
          )}
        </SectionMenu>
      </div>
    );
  }
}

export default connector(ProjectSection);
