// @flow
import { connect } from "react-redux";
import { trackEvent } from "core/actions/analytics";
import { withData } from "core/components/DataLoader";
import createConnector from "core/lib/createConnector";
import { getUserPolicy } from "core/selectors/policies";
import type { State, Dispatch } from "core/types";
import { previewDeleteAccount } from "web/actions/account";
import { accountFormSubmit } from "web/actions/accountForm";
import { loadEmails } from "web/actions/emails";
import ProfileEdit, {
  type Props,
  type StateProps,
  type DispatchProps,
} from "web/components/ProfileSettings/ProfileEdit";
import type { FormValues } from "web/models/form";
import { getAllEmails } from "web/selectors/emails";
import { getCurrentUser } from "web/selectors/session";
import type { FormProps } from "web/types";

function mapStateToProps(state: State): StateProps {
  const account = getCurrentUser(state);
  const userPolicy = getUserPolicy(state, {
    userId: account ? account.id : "",
  });

  return {
    account,
    emails: getAllEmails(state),
    canManageProfile: userPolicy.manageProfile === true,
    canManageEmail: userPolicy.manageEmail === true,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    onSubmit: (id: string, values: FormValues) => {
      dispatch(accountFormSubmit(id, values));
      dispatch(trackEvent("ACCOUNT_UPDATED", values));
    },
    onLoad: () => dispatch(loadEmails()),
    onPreviewDeleteAccount: () => dispatch(previewDeleteAccount()),
  };
}

export default createConnector<Props, {||}>(
  connect<Props, FormProps, StateProps, DispatchProps, State, Dispatch>(
    mapStateToProps,
    mapDispatchToProps
  ),
  withData((props) => props.params)
)(ProfileEdit);
