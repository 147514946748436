// @flow
/* global document */
import * as React from "react";
import Avatar from "core/components/Avatar";
import Heading from "core/components/Heading";
import Modal from "core/components/Modal";
import { formatDate } from "core/lib/dates";
import style from "./style.scss";

type Props = {
  avatarUrl: string,
  createdAt: string,
  id: string,
  name: string,
  username: string,
  isOpen: boolean,
  onClose: Function,
};

export default class ProfileModal extends React.Component<Props> {
  render() {
    const {
      isOpen,
      onClose,
      id,
      name = "Unknown",
      username,
      createdAt,
    } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        onClose={onClose}
        contentLabel="Profile"
        appElement={document.getElementById("root")}
      >
        <div className={style.wrapper}>
          <div className={style.section}>
            <Avatar userId={id} size="96px" />
            <hgroup>
              <Heading
                className={style.name}
                level="2"
                size="xl"
                qaSelector="preview-name"
              >
                {name}
              </Heading>
              {username && (
                <Heading
                  level="2"
                  size="xl"
                  className={style.username}
                  qaSelector="preview-username"
                >
                  @{username}
                </Heading>
              )}
            </hgroup>
          </div>
          <div className={style.section}>
            {createdAt && (
              <div className={style.joinedOn}>
                Joined {formatDate(createdAt)}
              </div>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}
