// @flow
import { getOrganizationForProject } from "core/selectors/organizations";
import type { State, OrganizationSettings } from "core/types";

export function getOrganizationSettings(
  state: State,
  params: { organizationId: string }
): ?OrganizationSettings {
  return (
    state.organizationSettings &&
    state.organizationSettings[params.organizationId]
  );
}

export function getOrganizationSettingsForProject(
  state: State,
  params: { projectId: string }
): ?OrganizationSettings {
  const organization = getOrganizationForProject(state, {
    projectId: params.projectId,
  });
  return organization
    ? getOrganizationSettings(state, {
        organizationId: organization.id,
      })
    : undefined;
}
