// @flow
import debug from "debug";
import { reduce, trim } from "lodash";
import logger from "abstract-di/logger";

type Logger = {
  log: (...args: any[]) => void,
  error: (...args: any[]) => void,
};

export function sanitizeArguments(args: any[]): string[] {
  return reduce(
    args,
    (memo, arg) => {
      if (typeof arg === "string") {
        // filters color tokens added by debug module to colorize console output
        if (arg.match(/^color: /)) {
          return memo;
        }

        // filter sensitive arguments
        memo.push(
          arg.replace(/(.*token=)(.*)/gi, "$1[Filtered]").replace(/%c/gi, "")
        );
        return memo;
      }

      if (typeof arg === "object") {
        memo.push("[object]");
        return memo;
      }

      memo.push(new String(arg).toString()); // eslint-disable-line no-new-wrappers
      return memo;
    },
    []
  );
}

function formatArguments(args) {
  // the first item will always represent the first arguments
  // that is passed to `log` appended with +Xms. Subsequent arguments
  // passed to log are attempted to be aligned
  const firstItem = trim(args.splice(0, 1)[0]);
  return `${firstItem}   ${args.join(" ")}`;
}

// enable logging for everything in the abstract namespace
// use "-" to disable logging for anything that shouldn't appear in a customer's log
debug.enable("abstract:*,-abstract:mapLayerChildren");

function createLogger(namespace: string): Logger {
  // https://www.npmjs.com/package/debug#output-streams
  // create two separate output streams for log / error levels
  // and override the log method individually to forward messages
  const log = debug(`abstract:${namespace}`);
  const error = debug(`abstract:${namespace}`);

  if (logger) {
    log.log = (...args) => {
      if (process.env.NODE_ENV === "development") {
        console.info(...args);
      }
      logger.info(formatArguments(sanitizeArguments(args)));
    };
    error.log = (...args) => {
      if (process.env.NODE_ENV === "development") {
        console.error(...args);
      }
      logger.error(formatArguments(sanitizeArguments(args)));
    };
  }

  return {
    log,
    error,
  };
}

export default createLogger;
