// @flow
import { connect } from "react-redux";
import SupportTicket from "core/components/SupportTicket";
import { getOrganizations } from "core/selectors/organizations";
import type {
  State,
  Dispatch,
  Organization,
  ReactRouterLocation,
} from "core/types";
import { isOnline } from "web/di/selectors";

type OwnProps = {|
  location: ReactRouterLocation,
|};

type StateProps = {|
  organizations: Organization[],
  isOffline: boolean,
  organizationId: ?string,
  onRequestClose?: () => void,
|};

type Props = {
  ...OwnProps,
  ...StateProps,
};

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { organizationId } = props.location.query;
  const organizations = getOrganizations(state);

  return {
    isOffline: !isOnline(state),
    organizationId,
    organizations,
  };
}

export default connect<Props, OwnProps, StateProps, _, State, Dispatch>(
  mapStateToProps
)(SupportTicket);
