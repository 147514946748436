// @flow
import createCachedSelector from "@elasticprojects/re-reselect";
import { keyBy, reduce, orderBy, pick, values } from "lodash";
import { getRawEntities } from "core/selectors/entities";
import { getActiveProjectsForOrganization } from "core/selectors/projects";
import type { Activity, State } from "core/types";
import {
  getOrganizationId,
  maybeGetProjectId,
  maybeGetBranchId,
} from "./helpers";

export function getActivities(state: State): { [id: string]: Activity } {
  return getRawEntities(state, "activities");
}

export function getActivity(state: State, params: { activityId: string }) {
  return getActivities(state)[params.activityId];
}

function getActivityIds(state: State, params: { ids: string[] }) {
  return params.ids;
}

function cacheByActivityIds(state: State, params: { ids: string[] }) {
  return params.ids.join("-");
}

export const getActivitiesForIds: (
  state: State,
  params: { ids: string[] }
) => Activity[] = createCachedSelector(
  getActivities,
  getActivityIds,
  (activities, ids) => values(pick(activities, ids))
)(cacheByActivityIds);

function cacheByActivityLookup(state, props) {
  const { organizationId, projectId, branchId } = props;
  return `${organizationId}-${projectId || ""}-${branchId || ""}-activity`;
}

export const getFilteredActivities: (
  State,
  { organizationId: string, projectId?: string, branchId?: string }
) => Activity[] = createCachedSelector(
  getActivities,
  getActiveProjectsForOrganization,
  getOrganizationId,
  maybeGetProjectId,
  maybeGetBranchId,
  (activities, organizationProjects, organizationId, projectId, branchId) => {
    let filtered = [];
    let projectLookup = {};

    if (organizationId) {
      projectLookup = keyBy(organizationProjects, "id");
    }

    // For comment threads that have a parent of review status, the review status
    // comment takes priority
    activities = orderBy(activities, "createdAt", ["asc"]);

    reduce(
      activities,
      (commentLookup, activity) => {
        let matches;
        if (branchId) {
          matches =
            activity.branchId === branchId && activity.projectId === projectId;
        } else if (projectId) {
          matches = activity.projectId === projectId;
        } else if (organizationId) {
          matches = !!projectLookup[activity.projectId];
        }

        if (matches && activity.type === "COMMENT_CREATED") {
          // Only include one activity per comment thread.
          const parentId =
            activity.payload.commentParentId || activity.payload.commentId;
          matches = !commentLookup[parentId];
          if (matches) {
            commentLookup[parentId] = activity;
          }
        }

        if (matches) {
          filtered.push(activity);
        }
        return commentLookup;
      },
      {}
    );

    return orderBy(filtered, "createdAt", ["desc"]);
  }
)(cacheByActivityLookup);

export const getFilteredActivityIds: (
  State,
  { organizationId: string, projectId?: string, branchId?: string }
) => string[] = createCachedSelector(getFilteredActivities, (activities) =>
  activities.map(({ id }) => id)
)(cacheByActivityLookup);
