// @flow
import * as React from "react";
import image from "./images/file.svg";
import Empty from ".";

export default function NoPageChanges(props: {}) {
  return (
    <Empty
      {...props}
      image={image}
      title="No changes"
      description="This page has no visual changes."
    />
  );
}
