// @flow
import * as React from "react";
import DialogForm from "core/components/DialogForm";
import connector from "./connector";

export type OwnProps = {|
  projectId: string,
  branchId: string,
  branchName: string,
|};

export type DispatchProps = {|
  deleteBranch: () => void,
  dismissDialog: () => void,
|};

export type Props = {
  ...OwnProps,
  ...DispatchProps,
};

class ConfirmDeleteBranch extends React.Component<Props> {
  handleSubmit = (ev: SyntheticEvent<*>) => {
    ev.preventDefault();
    this.props.deleteBranch();
    this.props.dismissDialog();
  };

  render() {
    return (
      <DialogForm
        title="Delete Branch"
        primaryButton="Delete Branch"
        onSubmit={this.handleSubmit}
        onClose={this.props.dismissDialog}
        dangerous
        isOpen
      >
        <p>
          Are you sure you want to delete the branch{" "}
          <em>{this.props.branchName}</em>? This action is permanent – once
          deleted, branches cannot be recovered.
        </p>
      </DialogForm>
    );
  }
}

export default connector(ConfirmDeleteBranch);
