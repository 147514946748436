// @flow
import { connect } from "react-redux";
import { getCurrentUserId, isOnline } from "abstract-di/selectors";
import {
  closeNotifications,
  filterNotificationsByOrg,
  openNotifications,
} from "core/actions/notificationsMeta";
import { withData } from "core/components/DataLoader";
import createConnector from "core/lib/createConnector";
import {
  MarkNotificationsRequest,
  MarkAllNotificationsAsReadRequest,
  LoadNotificationsRequest,
  type LoadNotificationsParams,
} from "core/requests/notifications";
import {
  getFilteredNotifications,
  getOldestNotificationCreatedAt,
  getUnreadCountsForOrganizations,
  getUnreadNotificationsCount,
} from "core/selectors/notifications";
import {
  areAllNotificationsLoaded,
  getNotificationsOpenState,
  getNotificationsOrganizationFilter,
  getPreviousOldestCreatedAt,
} from "core/selectors/notificationsMeta";
import { getNonUsernameOrganizations } from "core/selectors/organizations";
import type { Dispatch, State } from "core/types";
import type { DispatchProps, OwnProps, Props, StateProps } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const currentUserId = getCurrentUserId(state);
  const currentOrganizationFilter = getNotificationsOrganizationFilter(state);

  const organizationUnreads = getUnreadCountsForOrganizations(state);

  // Use a previously stored oldestCreatedAt to limit showing notifications up
  // to what's been previously shown so that no notifications get missed when
  // loading future notifications.
  const previousOldestCreatedAt = getPreviousOldestCreatedAt(state, {
    organizationId: currentOrganizationFilter,
  });

  // After loading notifications, only show notifications up to it's previously
  // calculated `oldestCreatedAt`.
  //
  // Otherwise, calculate a new oldestNotificationCreatedAt to load future
  // notifications from. (This happens after filtering all notifications to a
  // specific organization for the first time)
  const oldestNotificationCreatedAt = previousOldestCreatedAt
    ? previousOldestCreatedAt
    : getOldestNotificationCreatedAt(state, {
        organizationId: currentOrganizationFilter,
      });

  return {
    allNotificationsLoaded: areAllNotificationsLoaded(state, {
      organizationId: currentOrganizationFilter,
    }),
    currentOrganizationFilter,
    currentUserId,
    hasUnreadNotifications: getUnreadNotificationsCount(state) > 0,
    isFirstLoading: LoadNotificationsRequest.isFirstLoading(state, {}),
    isOnline: isOnline(state),
    isOpen: getNotificationsOpenState(state),
    isLoadingNotifications: LoadNotificationsRequest.isLoadingStrict(state, {
      organizationId: currentOrganizationFilter,
    }),
    oldestNotificationCreatedAt,
    organizationUnreads,
    organizations: getNonUsernameOrganizations(state),
    readNotifications: getFilteredNotifications(state, {
      isRead: true,
      oldestCreatedAt: previousOldestCreatedAt,
      organizationId: currentOrganizationFilter,
    }),
    totalUnreads: getUnreadNotificationsCount(state),
    unreadNotifications: getFilteredNotifications(state, {
      isRead: false,
      oldestCreatedAt: previousOldestCreatedAt,
      organizationId: currentOrganizationFilter,
    }),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const params = {};

  return {
    closeNotifications() {
      return dispatch(closeNotifications());
    },
    filterNotificationsByOrg(organizationId) {
      return dispatch(filterNotificationsByOrg(organizationId));
    },
    markNotifications(notificationIds, readState?: "read" | "unread" = "read") {
      return dispatch(
        MarkNotificationsRequest.perform({
          params: { notificationIds, readState },
        })
      );
    },
    markAllNotificationsAsRead() {
      return dispatch(MarkAllNotificationsAsReadRequest.perform({ params }));
    },
    loadNotifications(loadParams?: LoadNotificationsParams = {}) {
      return dispatch(LoadNotificationsRequest.perform({ params: loadParams }));
    },
    onLoad() {
      return dispatch(
        LoadNotificationsRequest.perform({
          params,
          force: false,
          invalidateable: true,
        })
      );
    },
    openNotifications() {
      return dispatch(openNotifications());
    },
  };
}

export default createConnector<Props, OwnProps>(
  connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch>(
    mapStateToProps,
    mapDispatchToProps
  ),
  withData((props: Props) => ({
    organizationId: props.organizationId,
    isOnline: props.isOnline,
  }))
);
