// @flow
import createCachedSelector from "@elasticprojects/re-reselect";
import { find, pick, values } from "lodash";
import naturalSortBy from "core/lib/naturalSortBy";
import * as Page from "core/models/page";
import { getRawEntities } from "core/selectors/entities";
import { getEntityMapping } from "core/selectors/entityMappings";
import { getFile } from "core/selectors/files";
import { getPagesByFileAtSha } from "core/selectors/pages";
import type { Library, State, Page as TPage } from "core/types";

function getLibraryEntities(state: State) {
  return getRawEntities(state, "libraries");
}

function getLibraryIdsAtSha(state: State, { sha }: { sha: string }) {
  return getEntityMapping(state, { type: "libraries", entityId: sha });
}

export const getLibraryPagesFromFile: (
  State,
  { projectId: string, sha: string, fileId: string }
) => TPage[] = createCachedSelector(
  getPagesByFileAtSha,
  (state, params) => `${params.projectId}-${params.sha}-${params.fileId}`,
  (pages, filePagesID) => {
    const filePages = (pages[filePagesID] || []).filter(Page.isLibrary);
    return naturalSortBy(filePages, "name");
  }
)((state, params) => `${params.projectId}-${params.sha}-${params.fileId}`);

export const getLibrariesAtSha: (
  State,
  { projectId: string, sha: string }
) => Library[] = createCachedSelector(
  getLibraryEntities,
  getLibraryIdsAtSha,
  (libraries, libraryIds) =>
    naturalSortBy(values(pick(libraries, libraryIds)), "name")
)((state, params) => `${params.projectId}-${params.sha}`);

export const getLibraryAtSha: (
  State,
  { projectId: string, sha: string, fileId: string }
) => ?Library = createCachedSelector(
  getLibrariesAtSha,
  (state, params) => params.fileId,
  (libraries, fileId) => find(libraries, { fileId })
)((state, params) => `${params.projectId}-${params.sha}-${params.fileId}`);

const getFileId = (state, props) => props.fileId;

export const getLibraryForPage: (
  State,
  { projectId: string, sha: string, fileId: string }
) => ?Library = createCachedSelector(
  getLibraryEntities,
  getFile,
  getFileId,
  (libraries, file, fileId) => {
    let library = find(libraries, { fileId });

    if (!library && file) {
      library = {
        branchId: "master",
        branchName: "Master",
        fileId: file.id,
        name: file.name,
        projectId: file.projectId,
        projectName: "",
        type: file.type,
      };
    }

    return library;
  }
)((state, props) => `${props.projectId}-${props.sha}-${props.fileId}`);
