// @flow
import invariant from "invariant";
import * as React from "react";
import { loadLibrarySuggestions } from "abstract-di/actions";
import Centered from "core/components/Centered";
import DialogForm from "core/components/DialogForm";
import FileName from "core/components/FileName";
import Flex from "core/components/Flex";
import Loading from "core/components/Loading";
import { helpBrokenSymbolsUrl } from "core/lib/urls";
import type { Page, File } from "core/types";
import SelectLibrary from "./SelectLibrary";
import connect from "./connector";
import style from "./style.scss";
import type { LibraryMap, LibrarySuggestions } from "./types";

export type OwnProps = {|
  projectId: string,
  branchId: string,
  sha: string,
  file: File,
|};

export type StateProps = {|
  fileLibraries: Page[],
  projectFiles: File[],
|};

export type DispatchProps = {|
  dismissDialog: () => void,
  updateLibraries: (
    projectId: string,
    branchId: string,
    fileId: string,
    fileName: string,
    libraryUpdates: { [libraryId: string]: string }
  ) => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type State = {
  isLoading: boolean,
  libraryUpdates: LibraryMap,
  librarySuggestions: LibrarySuggestions,
};

class ManageLibraries extends React.Component<Props, State> {
  state = {
    isLoading: true,
    libraryUpdates: {},
    librarySuggestions: {
      fileLibraries: [],
      suggestedLibrariesMap: {},
      potentialLibraries: [],
      potentialLibrariesMap: {},
    },
  };

  componentDidMount() {
    this.loadLibrarySuggestions();
  }

  async loadLibrarySuggestions() {
    const { fileLibraries, projectFiles, projectId, sha, file } = this.props;
    invariant(
      loadLibrarySuggestions,
      "loadLibrarySuggestions required for dialog action"
    );
    const librarySuggestions = await loadLibrarySuggestions(
      projectId,
      sha,
      file,
      fileLibraries,
      projectFiles
    );
    this.setState({ librarySuggestions, isLoading: false });
  }

  handleSubmit = () => {
    const hasUpdates = Object.keys(this.state.libraryUpdates).length > 0;
    if (hasUpdates) {
      this.props.updateLibraries(
        this.props.projectId,
        this.props.branchId,
        this.props.file.id,
        this.props.file.name,
        this.state.libraryUpdates
      );
    }
    this.props.dismissDialog();
  };

  updateLibrarySelection(originalId: string, replacementId: string) {
    if (replacementId === "") {
      this.setState(({ libraryUpdates }) => {
        delete libraryUpdates[originalId];
        return { libraryUpdates };
      });
    } else {
      this.setState({
        libraryUpdates: {
          ...this.state.libraryUpdates,
          [originalId]: replacementId,
        },
      });
    }
  }

  render() {
    const { librarySuggestions } = this.state;
    const { fileLibraries } = this.props;
    const libraries = fileLibraries.map((lib, key) => {
      const iconClass =
        librarySuggestions.potentialLibrariesMap[lib.id] === undefined &&
        this.state.libraryUpdates[lib.id] === undefined
          ? style.lightIcon
          : "";

      return (
        <tr key={key}>
          <td>
            <FileName
              name={lib.name}
              type={this.props.file.type}
              isLibrary={true}
              className={style.label}
              iconClassName={iconClass}
            />
          </td>
          <td>
            <SelectLibrary
              libraryId={lib.id}
              value={this.state.libraryUpdates[lib.id]}
              onChange={(e) =>
                this.updateLibrarySelection(lib.id, e.target.value)
              }
              librarySuggestions={librarySuggestions}
            />
          </td>
        </tr>
      );
    });

    const content = (
      <div>
        <div className={style.content}>
          <Flex align="center">
            <div>Manage Libraries for &nbsp;</div>
            <FileName
              name={this.props.file.name}
              type={this.props.file.type}
              isLibrary={this.props.file.isLibrary}
            />
          </Flex>

          <p>
            <a href={helpBrokenSymbolsUrl()} className={style.learnLink}>
              Learn more about managing libraries…
            </a>
          </p>
        </div>

        <table className={style.table}>
          <thead>
            <tr>
              <th>
                <div className={style.innerTitle}>Libraries In Use</div>
                <div>Libraries this file is referencing.</div>
              </th>
              <th className={style.widerColumn}>
                <div className={style.innerTitle}>Available Libraries</div>
                <div>
                  Libraries and Linked Libraries available in this project.
                </div>
              </th>
            </tr>
          </thead>
          <tbody>{libraries}</tbody>
        </table>
      </div>
    );

    const loading = (
      <div className={style.loadingContent}>
        <Centered flex={true}>
          <Loading title="Loading Libraries…" />
        </Centered>
      </div>
    );

    return (
      <DialogForm
        title="Manage Libraries"
        primaryButton="Apply changes"
        disabled={Object.keys(this.state.libraryUpdates).length === 0}
        onClose={this.props.dismissDialog}
        onSubmit={this.handleSubmit}
        size="large"
        isOpen
      >
        <div>{this.state.isLoading ? loading : content}</div>
      </DialogForm>
    );
  }
}

export default connect(ManageLibraries);
