// @flow
import currency from "currency.js";
import differenceInDays from "date-fns/difference_in_days";
import differenceInMilliseconds from "date-fns/difference_in_milliseconds";
import type {
  Subscription,
  SelfServeBillingPlan,
  EnterpriseBillingPlan,
  BillingPlan,
} from "core/types";

// Matches server: https://git.io/JeXf2
export const NON_ENTERPRISE_SEAT_CAP = 10;

export function inTrial(trialEndsAt: ?Date | string): boolean {
  return !!trialEndsAt && differenceInMilliseconds(trialEndsAt, new Date()) > 0;
}

export function trialDaysRemaining(trialEndsAt: Date | string): number {
  if (!inTrial(trialEndsAt)) {
    return 0;
  }
  return differenceInDays(trialEndsAt, new Date());
}

export function totalPrice({
  price,
  annual,
  quantity,
}: {
  price: number,
  annual: boolean,
  quantity: number,
}): string {
  const months = annual ? 12 : 1;
  return currency(price)
    .multiply(quantity * months)
    .format()
    .replace(".00", "");
}

export function availableSeats(subscription: ?Subscription): number {
  if (!subscription) {
    return 0;
  }

  // trial organizations have no real limit on seats unless a max is set
  if (subscription.inTrial) {
    return subscription.maxSeats
      ? subscription.maxSeats - subscription.usedSeats
      : 1;
  }

  return subscription.totalSeats - subscription.usedSeats;
}

export function availableAtRenewalSeats(subscription: ?Subscription): number {
  if (
    !subscription ||
    subscription.inTrial ||
    !subscription.totalSeatsPending
  ) {
    return availableSeats(subscription);
  }

  return subscription.totalSeatsPending - subscription.usedSeats;
}

export const prices = {
  businessMonthlyPrice: 1667,
  businessAnnualPrice: 1500,
  proMonthlyPrice: 4400,
  proAnnualPrice: 3500,
  starterMonthlyPrice: 1000,
  starterAnnualPrice: 900,
};

export const subscriptionTypeToDisplayName = new Map<
  $PropertyType<Subscription, "type">,
  string,
>([
  ["free", "Free"],
  ["starter", "Branches Starter"],
  ["business", "Branches Business"],
  ["pro", "Abstract Pro"],
  ["enterprise", "Abstract Enterprise"],
]);

export function isSelfServePlan(card: BillingPlan): %checks {
  return !!card.selfServe;
}

export const branchesStarterPlan: SelfServeBillingPlan = {
  annualPrice: prices.starterAnnualPrice,
  displayName: subscriptionTypeToDisplayName.get("starter") || "",
  featureList: [
    "File management",
    "Version control",
    "Ulimiited Viewers",
    "Up to 250GB of storage",
    "Offline Mode",
  ],
  monthlyPrice: prices.starterMonthlyPrice,
  planType: "starter",
  selectableFrom: ["starter"],
  selfServe: true,
  tagline: "For teams up to 10 contributors",
};

export const branchesBusinessPlan: SelfServeBillingPlan = {
  annualPrice: prices.businessAnnualPrice,
  displayName: subscriptionTypeToDisplayName.get("business") || "",
  featureList: [
    "File management and version control up to 250GB of storage with unlimited viewers.",
    "Review requests, Collections for reviews and presentations, Inspect elements and assets for all specifications and CSS",
    "Private Projects",
  ],
  monthlyPrice: prices.businessMonthlyPrice,
  planType: "business",
  selectableFrom: ["business"],
  selfServe: true,
  subTagline:
    "Enjoy core features like file management, version control, unlimited viewers, and offline mode, plus:",
  tagline: "Perfect for small teams",
};

export const branchesProPlan: SelfServeBillingPlan = {
  annualPrice: prices.proAnnualPrice,
  displayName: subscriptionTypeToDisplayName.get("pro") || "",
  featureList: [
    "Up to 50GB of storage",
    "Review requests, collections for reviews and presentations, inspect elements and assets for all specifications and CSS",
    "Private projects",
  ],
  monthlyPrice: prices.proMonthlyPrice,
  planType: "pro",
  selectableFrom: ["free", "starter", "business", "pro"],
  selfServe: true,
  subTagline:
    "Enjoy core features like file management, version control, unlimited viewers, and offline mode, plus:",
  tagline: "End-to-end design workflow",
};

export const branchesEnterprisePlan: EnterpriseBillingPlan = {
  displayName: subscriptionTypeToDisplayName.get("enterprise") || "",
  featureList: [
    "Project Sections, Merge Restrictions, High-Res Inspect Preview, No cap on seats and Reporting.",
    "Advanced Security Features like SSO access for any SAML vendor with JIT provisioning.",
    "Custom Legal Terms, Security Questionnaires, and Security Documentation.",
    "Dedicated Account Manager and access to our Customer Success Team with priority support.",
    "Custom Payment Terms and Invoicing.",
  ],
  planType: "enterprise",
  selectableFrom: ["free", "starter", "pro", "business", "enterprise"],
  tagline: "Perfect for the whole company",
  subTagline: "Everything in Pro, plus:",
};
