// @flow
import map from "lodash/map";
import React, { Component } from "react";
import NoProjectChannels from "core/components/Empty/NoProjectChannels";
import FormHeader from "core/components/FormHeader";
import FormSection from "core/components/FormSection";
import Loaded from "core/components/Loaded";
import Media from "core/components/Media";
import SettingsForm from "core/components/SettingsForm";
import type {
  Project,
  SlackIntegrationOverrideDetails,
  SlackIntegrationOverride,
  SlackIntegration,
  SlackChannel,
} from "core/types";
import ProjectChannelDialog from "../ProjectChannelDialog";
import ProjectChannelsListItem from "../ProjectChannelsListItem";

export type OverrideProject = Project & { override: SlackIntegrationOverride };

type Props = {
  projects: { [string]: Project },
  slackIntegration: SlackIntegration,
  defaultChannel: ?SlackChannel,
  isLoading: boolean,
  onCreate: (
    formId: string,
    overrideId: string,
    values: SlackIntegrationOverrideDetails
  ) => *,
  onUpdate: (
    formId: string,
    overrideId: string,
    values: SlackIntegrationOverrideDetails
  ) => *,
  onRemove: (overrideId: string) => *,
  onMount: () => *,
};

type State = { dialogIsOpen: boolean };

export default class ProjectChannels extends Component<Props, State> {
  state = { dialogIsOpen: false };

  componentDidMount() {
    this.props.onMount();
  }

  hideDialog = (event?: SyntheticEvent<>) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({ dialogIsOpen: false });
  };

  showDialog = (event: SyntheticEvent<>) => {
    event.preventDefault();
    this.setState({ dialogIsOpen: true });
  };

  render() {
    const {
      defaultChannel,
      projects,
      isLoading,
      slackIntegration,
      onCreate,
      onUpdate,
      onRemove,
    } = this.props;

    const header = (
      <Media desktop>
        {(desktop) => (
          <FormHeader
            mobile={!desktop}
            heading="Slack"
            subheading="Per-Project Channels"
            submitText={
              slackIntegration.details.slackV2
                ? "Add default channels"
                : "Add project channel"
            }
            onClick={this.showDialog}
          />
        )}
      </Media>
    );
    const defaultChannelName = defaultChannel
      ? [", ", <strong>#{defaultChannel.name}</strong>]
      : "";

    const slackV1Description = (
      <span>
        Projects connected to specific channels will only post messages in those
        channels. All other messages will be posted to your default channel
        {defaultChannelName}.
      </span>
    );

    const slackV2Description = (
      <span>
        Selecting default channels will automatically post all reviews from this
        project to these default channels instead of to any organization level
        channels.
      </span>
    );

    return (
      <React.Fragment>
        <SettingsForm documentTitle="Per-Project Channels" header={header}>
          <Loaded loading={isLoading}>
            {() => {
              if (!projects.hasOverride) {
                return (
                  <NoProjectChannels
                    slackV2={slackIntegration.details.slackV2}
                  />
                );
              }

              return (
                <FormSection
                  description={
                    slackIntegration.details.slackV2
                      ? slackV2Description
                      : slackV1Description
                  }
                >
                  {map(projects.hasOverride, (project) => (
                    <ProjectChannelsListItem
                      key={project.id}
                      project={project}
                      projects={[project]}
                      slackIntegration={slackIntegration}
                      onUpdate={onUpdate}
                      onRemove={onRemove}
                      defaultChannel={defaultChannel}
                    />
                  ))}
                </FormSection>
              );
            }}
          </Loaded>
        </SettingsForm>
        {!isLoading && (
          /* $FlowFixMeNowPlease This comment suppresses an error found when
           * upgrading flow-bin@0.89.0. To view the error, delete this comment
           * and run Flow. */
          <ProjectChannelDialog
            {...this.props}
            onSubmit={onCreate}
            projects={projects.default}
            isOpen={this.state.dialogIsOpen}
            onClose={this.hideDialog}
          />
        )}
      </React.Fragment>
    );
  }
}
