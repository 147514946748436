// @flow
import { EventTypes } from "redux-segment";
import { ConfigurationFetchRequest } from "abstract-di/requests";
import { trackEvent } from "core/actions/analytics";
import { client } from "core/lib/sentry";
import { destroySession } from "web/api";
import { clearUserCookies } from "web/cookies";
import { getToken } from "web/token";
import type { Me } from "web/types";
import type { Action, ThunkAction } from ".";

function sessionLoadedAction({ user, session }: Me): Action {
  if (!user) {
    return {
      type: "SESSION_LOADED",
      payload: { userId: "" },
    };
  }
  return {
    type: "SESSION_LOADED",
    payload: session,
  };
}

export function sessionLoaded(me: Me): ThunkAction {
  return async (dispatch, getState) => {
    const { user } = me || {};

    dispatch({ type: "core/AUTHENTICATED" });
    dispatch(sessionLoadedAction(me));

    if (!user) {
      return;
    }

    // configure sentry
    client.setUser({ email: user.email, id: user.id });
  };
}

function sessionDeleted(): Action {
  return {
    type: "SESSION_DELETED",
    meta: {
      analytics: {
        eventType: EventTypes.reset,
      },
    },
  };
}

export function loadSession(): ThunkAction {
  return (dispatch) => {
    return dispatch(ConfigurationFetchRequest.perform({ params: {} }));
  };
}

export function deleteSession(): ThunkAction {
  return async (dispatch) => {
    const token = getToken();

    dispatch(trackEvent("SIGNED_OUT"));

    if (token) {
      destroySession(token);
    }
    dispatch(sessionDeleted());
    clearUserCookies();
    client.setUser(null);
  };
}
