// @flow
import createCachedSelector from "@elasticprojects/re-reselect";
import { find, pick, values } from "lodash";
import naturalSortBy from "core/lib/naturalSortBy";
import { getEntityMapping } from "core/selectors/entityMappings";
import { getFileId } from "core/selectors/helpers";
import type { Library, State } from "core/types";

type LibrariesByBranchParams = { projectId: string, branchId: string };

const getLibraries = (state: State) => state.entities.libraries;
const getLibraryIds = (
  state: State,
  { projectId, branchId }: LibrariesByBranchParams
) =>
  getEntityMapping(state, {
    type: "libraries",
    entityId: `${projectId}-${branchId}`,
  });

export const getLibrariesForBranch: (
  State,
  LibrariesByBranchParams
) => Library[] = createCachedSelector(
  getLibraries,
  getLibraryIds,
  (libraries = {}, libraryIds = []) =>
    naturalSortBy(values(pick(libraries, libraryIds)), "name")
)(
  (state: State, props: LibrariesByBranchParams) =>
    `${props.projectId}-${props.branchId}`
);

type LibraryParams = {
  projectId: string,
  branchId: string,
  fileId: string,
};

export const getLibrary: (State, LibraryParams) => ?Library =
  createCachedSelector(getLibrariesForBranch, getFileId, (libraries, fileId) =>
    find(libraries, { fileId })
  )(
    (state, props: LibraryParams) =>
      `${props.projectId}-${props.branchId}-${props.fileId}`
  );
