// @flow
import * as React from "react";
import Button from "core/components/Button";
import Centered from "core/components/Centered";
import Heading from "core/components/Heading";
import Icon from "core/components/Icon";
import style from "./style.scss";

type Props = {
  showModal: (event: SyntheticEvent<>) => void,
};

export default function EmptyEmojis(props: Props) {
  return (
    <Centered flex direction="column">
      <Icon
        type="emoji-category-smileys-and-people"
        huge
        className={style.smileyIcon}
      />
      <Heading level="3" size="xl">
        Custom Emojis
      </Heading>
      <p>
        Add custom emojis to use as reactions on comments throughout Abstract.
      </p>
      <Button primary large onClick={props.showModal}>
        Add Your First Custom Emoji
      </Button>
    </Centered>
  );
}
