// @flow
import * as Branch from "core/models/branch";
import type { Branch as TBranch, State } from "core/types";

export function getLocalBranchEntities(state: State) {
  return state.localBranches;
}

export function getLocalBranch(
  state: State,
  params: { projectId: string, branchId: string }
): ?TBranch {
  return state.localBranches[Branch.uniqueId(params)];
}

export function isBranchSynced(
  state: State,
  params: { projectId: string, branchId: string }
): boolean {
  const branch = getLocalBranch(state, params);
  return !!branch && !branch.metaOnly;
}
