// @flow
import * as React from "react";
import { helpBranchesUrl } from "core/lib/urls";
import image from "./images/branch.svg";
import Empty from ".";

export default function NoActiveBranches(props: {}) {
  return (
    <Empty
      {...props}
      image={image}
      title="Create a branch"
      description={
        <span>
          Create a branch to start your independent workspace.
          <br />
          <a href={helpBranchesUrl()} target="_blank" rel="noopener noreferrer">
            Learn about branches in the Help Center.
          </a>
        </span>
      }
    />
  );
}
