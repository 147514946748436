// @flow
import classnames from "classnames";
import { kebabCase } from "lodash";
import * as React from "react";
import { Waypoint } from "react-waypoint";
import Centered from "core/components/Centered";
import Loading from "core/components/Loading";
import TeamCard from "core/components/TeamCard";
import VirtualizedList from "core/components/VirtualizedList";
import { Abstract } from "core/lib/abstract";
import type { User, Team } from "core/types";
import style from "./style.scss";

type Props = {
  params: Abstract.OrganizationDescriptor,
  searchFilter?: string,
  currentUserTeams?: Team[],
  currentUserTeamsExpandButton?: React.Node,
  allTeams: Team[],
  isGrid: boolean,
  isMobile: boolean,
  hasNextPage: boolean,
  renderMemberMenu?: (input: { user?: User, team?: Team }) => React.Node,
  isLoadingNextPage: boolean,
  onLoadNextPage?: () => void,
};

export default function VirtualizedTeamsList(props: Props) {
  const listRef: { current: ?VirtualizedList } = React.useRef(null);
  const {
    isGrid,
    isMobile,
    currentUserTeams,
    currentUserTeamsExpandButton,
    hasNextPage,
    allTeams,
    onLoadNextPage,
  } = props;
  const items = [];
  const itemProps = isGrid
    ? {
        gridItem: true,
        gridRowClassName: style.gridRow,
        className: style.gridItem,
        height: ({ columnWidth }) => columnWidth - 16,
      }
    : {
        gridItem: false,
        style: (rowStyle) => ({
          ...rowStyle,
          top: rowStyle.top,
        }),
        height: 72,
      };

  const renderInnerListElement = ({
    children,
    className,
    style: innerElementStyle,
    ...rest
  }: {
    children: React.Node,
    className?: string,
    style: { height: number, minHeight: number },
  }) => {
    const verticalPadding = isMobile || isGrid ? 0 : 48;
    return (
      <div
        {...rest}
        className={classnames(className, {
          [style.innerListElement]: !isGrid && !isMobile,
        })}
        style={{
          ...innerElementStyle,
          height: innerElementStyle.height || 0 + verticalPadding,
          minHeight: innerElementStyle.minHeight || 0 + verticalPadding,
        }}
      >
        {children}

        {onLoadNextPage ? (
          <div className={style.waypoint}>
            <Waypoint topOffset="1440px" onEnter={onLoadNextPage} />
          </div>
        ) : null}
      </div>
    );
  };

  const renderTitle = (title: string) => {
    items.push({
      height: 36,
      children: (
        <div
          className={classnames(style.sectionHeader, {
            [style.grid]: isGrid || isMobile,
          })}
        >
          {title}
        </div>
      ),
    });
  };

  const renderTeamCards = (
    teams: Team[],
    expandable?: boolean,
    title: string
  ) => {
    teams.forEach((team, index) => {
      items.push({
        ...itemProps,
        key: team.id + index,
        className: classnames(style.hoverable, {
          [style.list]: !isMobile && !isGrid,
          [style.mobile]: isMobile,
          [style.firstListRow]: index === 0,
          [style.lastListRow]: !expandable && index === teams.length - 1,
        }),
        children: (
          <TeamCard
            qaSelector={`team-card-${kebabCase(title)}-${index}`}
            params={props.params}
            className={style.team}
            highlight={props.searchFilter}
            team={team}
            card={isGrid}
            mobile={isMobile}
            actions={
              props.renderMemberMenu
                ? props.renderMemberMenu({ team })
                : undefined
            }
          />
        ),
      });
    });
  };

  items.push({ height: isMobile ? 16 : 24, children: <div /> });

  if (currentUserTeams && currentUserTeams.length > 0) {
    const title = "Your Teams";

    renderTitle(title);
    renderTeamCards(currentUserTeams, !!currentUserTeamsExpandButton, title);
    if (currentUserTeamsExpandButton) {
      items.push({
        height: 48,
        className: classnames(style.hoverable, {
          [style.list]: !isMobile && !isGrid,
        }),
        children: currentUserTeamsExpandButton,
      });
    }
    items.push({ height: isMobile ? 16 : 24, children: <div /> });
  }

  if (allTeams.length > 0) {
    const title = "All Teams";

    if (currentUserTeams && currentUserTeams.length > 0) {
      renderTitle(title);
    }
    renderTeamCards(allTeams, undefined, title);
    items.push({ height: isMobile ? 16 : 24, children: <div /> });
  }

  if (hasNextPage) {
    items.push({
      height: 48,
      children: (
        <Centered>
          <Loading small qaSelector="loading-spinner-small" />
        </Centered>
      ),
    });
  }

  if (!isMobile && !isGrid) {
    items.push({ height: 24, children: <div /> });
  }

  return (
    <VirtualizedList
      items={items}
      innerElementType={renderInnerListElement}
      ref={listRef}
      resizeProps={{
        isGrid,
        currentUserTeams,
        currentUserTeamsExpandButton,
      }}
    />
  );
}
