// @flow
import pluralize from "pluralize";
import * as React from "react";
import { useSelector } from "react-redux";
import Button from "core/components/Button";
import ButtonGroup from "core/components/ButtonGroup";
import Heading from "core/components/Heading";
import Note from "core/components/Note";
import PriceCalculation from "core/components/PriceCalculation";
import { V3Link as Link } from "core/lib/router";
import {
  branchesEnterprisePlan,
  branchesBusinessPlan,
  branchesProPlan,
  branchesStarterPlan,
  isSelfServePlan,
  totalPrice,
  trialDaysRemaining,
} from "core/lib/subscriptions";
import { helpUpdateBillingUrl } from "core/lib/urls";
import { getOrganization } from "core/selectors/organizations";
import type { BillingPlan, Subscription } from "core/types";
import style from "./style.scss";

const CardPrice = ({ price }) => {
  return (
    <div className={style.priceContainer}>
      <span className={style.cardPrice}>${price}</span>{" "}
      <span className={style.perDate}>/mo</span>
    </div>
  );
};

const PriceCaveat = ({
  isSelfServe,
  annual,
}: {
  isSelfServe: boolean,
  annual: boolean,
}) => (
  <React.Fragment>
    {isSelfServe ? (
      <p className={style.priceCaveat}>
        Price per Contributor, billed
        {annual ? " annually" : " monthly"}
      </p>
    ) : (
      <p className={style.priceCaveat}>Additional billing options available</p>
    )}
  </React.Fragment>
);

const standardBranchesPlans = new Set<BillingPlan>([
  branchesStarterPlan,
  branchesBusinessPlan,
  branchesProPlan,
  branchesEnterprisePlan,
]);

export type BadgeProps = {
  trialEndsAt: Date | string,
  inTrial: boolean,
};

function PlanBadge({ inTrial, trialEndsAt }: BadgeProps) {
  const daysRemainingInTrial = trialDaysRemaining(trialEndsAt);
  const currentPlanBadge = inTrial ? (
    <span className={style.cardHeaderBadgeInTrial}>
      {daysRemainingInTrial} {pluralize("days", daysRemainingInTrial)} left in{" "}
      trial
    </span>
  ) : (
    <span className={style.cardHeaderBadge}>Your current plan</span>
  );

  return currentPlanBadge;
}

export type Props = {
  contributors: number,
  currentPlan: string,
  // date in the future === currently in trial
  // date in the past === expired trial
  // empty string === not in a trial
  trialEndsAt: Date | string,
  inTrial: boolean,
  onSubmit: (string, boolean) => void,
  annual: boolean,
  organizationId: string,
  hasPaymentDetails: boolean,
};

function ChoosePlan(props: Props) {
  const [annual, setAnnual] = React.useState(() => props.annual);

  const organization = useSelector((state) =>
    getOrganization(state, { organizationId: props.organizationId })
  );

  const changePaymentPeriod = (annual: boolean) => {
    setAnnual(annual);
  };

  const submit = (plan: string) => {
    props.onSubmit(plan, annual);
  };

  const handleClickAnnual = () => changePaymentPeriod(true);
  const handleClickMonthly = () => changePaymentPeriod(false);

  const getPrice = (monthlyPrice: number, annualPrice: number) => {
    return totalPrice({
      price: getPricePerMonth(monthlyPrice, annualPrice),
      annual,
      quantity: props.contributors,
    });
  };

  const getPricePerMonth = (monthlyPrice: number, annualPrice: number) => {
    return (!annual ? monthlyPrice : annualPrice) / 100;
  };

  const isPlanCurrent = (
    plan: $PropertyType<Subscription, "type">
  ): boolean => {
    return props.currentPlan === plan && props.annual === annual;
  };

  const availablePlans = React.useMemo((): BillingPlan[] => {
    let plans = [];
    if (organization) {
      if (organization.versionsEnabled) {
        plans = standardBranchesPlans;

        // We only want to show the `starter` card to current `starter` customers, for reference
        if (props.currentPlan !== "starter") {
          plans.delete(branchesStarterPlan);
        }
        // We only want to show the `business` card to current `business` customers, for reference
        if (props.currentPlan !== "business") {
          plans.delete(branchesBusinessPlan);
        }
      }
    }
    return [...plans];
  }, [organization, props.currentPlan]);

  const planButtonText = (plan, hasPaymentDetails) => {
    if (
      isSelfServePlan(plan) &&
      isPlanCurrent(plan.planType) &&
      !hasPaymentDetails
    ) {
      return "Add Payment Method";
    } else if (isSelfServePlan(plan) && isPlanCurrent(plan.planType)) {
      return "Current Plan";
    } else if (isSelfServePlan(plan)) {
      return "Choose Plan";
    } else {
      return "Contact Support";
    }
  };

  return (
    <div className={style.container}>
      <div className={style.header}>
        <Heading level="2" size="xxl" className={style.headerTitle}>
          Available Plans
        </Heading>
        <div className={style.emptySpace} />
      </div>

      <div className={style.headerButtons}>
        <ButtonGroup>
          <Button
            primary={annual}
            onClick={handleClickAnnual}
            className={style.headerButton}
          >
            Pay annually
          </Button>
          <Button
            primary={!annual}
            onClick={handleClickMonthly}
            className={style.headerButton}
          >
            Pay monthly
          </Button>
        </ButtonGroup>
      </div>

      <div className={style.main}>
        {availablePlans.map((plan) => (
          <div className={style.cardColumnFirst} key={plan.planType}>
            <div className={style.cardBody}>
              <div className={style.cardHeader}>
                <Heading level="2" size="xxl">
                  {plan.displayName}
                </Heading>

                {isPlanCurrent(plan.planType) && (
                  <PlanBadge
                    inTrial={props.inTrial}
                    trialEndsAt={props.trialEndsAt}
                  />
                )}
              </div>

              {isSelfServePlan(plan) ? (
                <CardPrice
                  price={getPricePerMonth(plan.monthlyPrice, plan.annualPrice)}
                />
              ) : (
                <p className={style.enterpriseExplanation}>
                  Contact us for a custom quote.
                </p>
              )}
              <Button
                // Currently, plan downgrades aren't supported for self-service
                disabled={
                  (isPlanCurrent(plan.planType) && props.hasPaymentDetails) ||
                  !plan.selectableFrom.includes(props.currentPlan)
                }
                primary={
                  (!isPlanCurrent(plan.planType) || !props.hasPaymentDetails) &&
                  plan.selectableFrom.includes(props.currentPlan)
                }
                onClick={() => {
                  if (isSelfServePlan(plan)) {
                    submit(plan.planType);
                  }
                }}
                fullwidth
                large
                component={isSelfServePlan(plan) ? undefined : Link}
                to={isSelfServePlan(plan) ? undefined : "/contact-support"}
              >
                {planButtonText(plan, props.hasPaymentDetails)}
              </Button>

              <PriceCaveat
                isSelfServe={isSelfServePlan(plan)}
                annual={annual}
              />

              <p className={style.userNumberRecommendation}>{plan.tagline}</p>

              {plan.subTagline && <p>{plan.subTagline}</p>}

              <ul className={style.featureList}>
                {plan.featureList.map((feature) => (
                  <li key={feature}>{feature}</li>
                ))}
              </ul>
            </div>

            {isSelfServePlan(plan) && (
              <PriceCalculation
                totalPrice={getPrice(plan.monthlyPrice, plan.annualPrice)}
                monthlyPrice={getPricePerMonth(
                  plan.monthlyPrice,
                  plan.annualPrice
                )}
                annual={annual}
                quantity={props.contributors}
              />
            )}
          </div>
        ))}
      </div>

      <Note className={style.footnote}>
        Change or cancel your subscription, anytime.{" "}
        <a
          href={helpUpdateBillingUrl()}
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more&hellip;
        </a>
      </Note>
    </div>
  );
}

export default ChoosePlan;
