// @flow
import { connect } from "react-redux";
import CommitsCompactList from "core/components/CommitsCompactList";
import { BranchFetchRequest } from "core/requests/branches";
import { CommitsFetchRequest } from "core/requests/commits";
import { getParentBranch, getBranch } from "core/selectors/branches";
import { getCommitsForEntity } from "core/selectors/commits";
import type { Dispatch, State, Project, Branch, Commit } from "core/types";

import { getProject } from "../selectors/projects";

type OwnProps = {|
  projectId: string,
  branchId: string,
  maxCommits?: number,
  onCommitsLoad?: () => void,
|};

type StateProps = {|
  project: ?Project,
  branch: ?Branch,
  parent: ?Branch,
  commits: Commit[],
  isLoading: boolean,
|};

type DispatchProps = {|
  onMount: () => void,
|};

type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const params = {
    projectId: props.projectId,
    branchId: props.branchId,
  };

  const commits = getCommitsForEntity(state, params);
  const parent = getParentBranch(state, params);

  return {
    project: getProject(state, props.projectId),
    branch: getBranch(state, params),
    parent,
    commits,
    isLoading: !commits.length,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { projectId, branchId } = props;
  return {
    onMount: () => {
      dispatch(
        CommitsFetchRequest.perform({
          // the UI displays 5 commits, a 6th is needed to know if
          // there are "more"
          params: { projectId, branchId, limit: 6 },
        })
      );
      dispatch(BranchFetchRequest.perform({ params: { projectId, branchId } }));
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(
  mapStateToProps,
  mapDispatchToProps
)(CommitsCompactList);
