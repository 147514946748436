// @flow
import * as React from "react";
import Error from "core/components/Empty/Error";
import Flex from "core/components/Flex";
import Loaded from "core/components/Loaded";
import Media from "core/components/Media";
import ProjectBranchesHeader from "core/components/ProjectBranchesHeader";
import { replace, addQuery, removeQuery } from "core/lib/location";
import type { ReactRouterLocation, Project, User } from "core/types";
import type { BranchFilter } from "web/types";
import FilteredBranches from "./FilteredBranches";
import style from "./style.scss";

export type OwnProps = {|
  params: { projectId: string },
  location: ReactRouterLocation,
|};

export type StateProps = {|
  key: string,
  isLoading: boolean,
  filter: BranchFilter,
  project: ?Project,
  projectId: string,
  userId?: string,
  selectedAuthor: ?User,
  currentUserId: string,
|};

export type DispatchProps = {|
  onMount: () => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type State = { query: string };

export default class ProjectBranches extends React.Component<Props, State> {
  state = { query: "" };

  componentDidMount() {
    this.props.onMount();
  }

  handleSearchChange = (event: SyntheticInputEvent<>) => {
    const { value } = event.target;
    this.handleFilter(value);
  };

  handleSelectAuthor = (userId: ?string) => {
    if (userId && userId !== this.props.location.query.userId) {
      replace(addQuery({ userId }));
    } else {
      replace(removeQuery("userId"));
    }
  };

  handleFilter = (value: string) => {
    this.setState({ query: value });
  };

  render() {
    const {
      filter,
      userId,
      selectedAuthor,
      project,
      isLoading,
      projectId,
      currentUserId,
    } = this.props;

    return (
      <Media desktop>
        {(desktop) => (
          <Flex column className={style.content}>
            <ProjectBranchesHeader
              filter={filter}
              projectId={projectId}
              selectedAuthor={selectedAuthor}
              onSelectAuthor={this.handleSelectAuthor}
              onSearchChange={this.handleSearchChange}
              currentUserId={currentUserId}
              mobile={!desktop}
            />
            <Loaded loading={isLoading} flex title="Loading branches…">
              {() =>
                !project ? (
                  <Error flex />
                ) : (
                  <FilteredBranches
                    project={project}
                    projectId={projectId}
                    branchFilter={filter}
                    userId={userId}
                    query={this.state.query}
                    mobile={!desktop}
                  />
                )
              }
            </Loaded>
          </Flex>
        )}
      </Media>
    );
  }
}
