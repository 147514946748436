// @flow
import * as React from "react";
import ActivityBase from "./Base";
import type {
  Props,
  ActivityProjectDeleted as TActivityProjectDeleted,
} from "./types";

export default function ActivityProjectDeleted(
  props: Props<TActivityProjectDeleted>
) {
  return (
    <ActivityBase
      {...props}
      icon="project"
      action="deleted the project"
      subject={<del>{props.activity.payload.projectName}</del>}
    />
  );
}
