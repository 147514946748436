// @flow
import classnames from "classnames";
import * as React from "react";
import style from "./style.scss";

type Props = {|
  borderStyle?: "rounded" | "straight",
  className?: string,
  value: number,
  max: number,

  // a percentage upto which the progress bar can move forward on it's own
  // to give the illusion of progress
  creep?: number,
  creepDelay?: number,
|};

export default function Progress({
  borderStyle = "rounded",
  className,
  value,
  creep,
  creepDelay = 2000,
  max,
  ...rest
}: Props) {
  const [creepedPercent, setCreepedPercent] = React.useState(0);
  const [creepActive, setCreepActive] = React.useState(false);

  // If the value prop changes then reset the creep and active state
  React.useEffect(() => {
    setCreepedPercent(0);
    setCreepActive(false);

    const timeout = setTimeout(() => {
      setCreepActive(true);
    }, creepDelay);
    return () => clearTimeout(timeout);
  }, [value, creepDelay]);

  // If creep prop is a positive value then gradually move the progress bar
  // forwards by upto this percentage when no value is received
  React.useEffect(() => {
    if (!creep || !creepActive) {
      return;
    }

    const timeout = setTimeout(() => {
      setCreepedPercent(creepedPercent + (creep - creepedPercent) / 10);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [creep, creepActive, creepedPercent]);

  return (
    <progress
      {...rest}
      max={max}
      value={Math.round(Math.min(value + creepedPercent, max))}
      className={classnames(
        style.progress,
        { [style.rounded]: borderStyle === "rounded" },
        className
      )}
    />
  );
}
