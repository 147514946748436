// @flow
import * as React from "react";
import { V3Link as Link } from "core/lib/router";
import { projectPath } from "core/lib/routes";
import ActivityBase from "./Base";
import type {
  Props,
  ActivityProjectDescriptionChanged as TActivityProjectDescriptionChanged,
} from "./types";

export default function ActivityProjectDescriptionChanged(
  props: Props<TActivityProjectDescriptionChanged>
) {
  if (!props.project) {
    return null;
  }
  return (
    <ActivityBase
      {...props}
      icon="project"
      action="changed the description of the project"
      subject={
        <Link to={projectPath(props.activity.projectId)}>
          {props.activity.payload.projectName}
        </Link>
      }
    />
  );
}
