// @flow
import classnames from "classnames";
import * as React from "react";
import Flex from "core/components/Flex";
import style from "./style.scss";

export default function MainContent({
  className,
  children,
}: {
  className?: string,
  children: React.Node,
}) {
  return (
    <Flex
      column
      className={classnames(style.content, className)}
      role="main"
      aria-labelledby="aria-pagetitle"
    >
      {children}
    </Flex>
  );
}
