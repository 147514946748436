// @flow
import pluralize from "pluralize";
import * as React from "react";
import useBranchDisplayName from "core/hooks/useBranchDisplayName";
import style from "./style.scss";

type Props = {
  required: boolean,
  minNumberOfApprovals?: ?number,
};

export default function BranchReviewNote(props: Props) {
  const { minNumberOfApprovals, required } = props;
  const { defaultBranchNameTitleCase } = useBranchDisplayName();
  if (required) {
    const approvals = minNumberOfApprovals ? minNumberOfApprovals : 1;
    return (
      <p className={style.branchReviewNote}>
        Get approval from at least <b>{approvals}</b> Project{" "}
        {pluralize("Reviewer", approvals)} below to merge to{" "}
        {defaultBranchNameTitleCase}. Include optional reviewers to gather
        additional feedback.
      </p>
    );
  }

  return (
    <p className={style.branchReviewNote}>
      You can request approval from members of this project. They will be
      notified individually and asked to review this branch.
    </p>
  );
}
