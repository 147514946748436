// @flow
import {
  request,
  requestCleared,
  type RequestOptions,
} from "core/actions/requests";
import * as Request from "core/models/request";
import { getRequest as coreGetRequest } from "core/selectors/requests";
import type {
  Dispatch,
  GetState,
  State,
  Action,
  ThunkAction,
  Request as TRequest,
  ValidationErrors,
} from "core/types";

export type RequestDef<PerformParams, IdParams> = {
  perform: ({
    params: PerformParams & IdParams,
    ...RequestOptions,
  }) => ThunkAction,
  getRequest: (state: State, params: IdParams) => TRequest,
  isFirstLoading: (state: State, params: IdParams) => boolean,
  isFirstLoadingStrict: (state: State, params: IdParams) => boolean,
  isLoading: (state: State, params: IdParams) => boolean,
  isLoadingStrict: (state: State, params: IdParams) => boolean,
  success: (state: State, params: IdParams) => boolean,
  hasError: (state: State, params: IdParams) => boolean,
  forbidden: (state: State, params: IdParams) => boolean,
  notFound: (state: State, params: IdParams) => boolean,
  validationErrors: (state: State, params: IdParams) => ValidationErrors,
  clear: (params: IdParams) => Action,
  promise: (state: State, params: IdParams) => void | Promise<mixed>,
};

type RequestFunctions<PerformParams, IdParams> = {
  id: (IdParams) => string,
  perform: (PerformParams & IdParams, Dispatch, GetState) => Promise<mixed>,
  onError?: (Error, PerformParams & IdParams, Dispatch, GetState) => mixed,
  onSuccess?: (any, PerformParams & IdParams, Dispatch, GetState) => mixed,
  force?: boolean,
  invalidateable?: boolean,
};

export default function defineRequest<PerformParams, IdParams>(
  props: RequestFunctions<PerformParams, IdParams>
): RequestDef<PerformParams, IdParams> {
  function getRequest(state: State, params: IdParams) {
    return coreGetRequest(state, props.id(params));
  }

  function isFirstLoading(state: State, params: IdParams) {
    return Request.isFirstLoading(this.getRequest(state, params));
  }

  function isFirstLoadingStrict(state: State, params: IdParams) {
    return Request.isFirstLoadingStrict(this.getRequest(state, params));
  }

  function isLoading(state: State, params: IdParams) {
    return Request.isLoading(this.getRequest(state, params));
  }

  function isLoadingStrict(state: State, params: IdParams) {
    return Request.isLoadingStrict(this.getRequest(state, params));
  }

  function promise(state: State, params: IdParams) {
    return Request.promise(this.getRequest(state, params));
  }

  function success(state: State, params: IdParams) {
    return Request.success(this.getRequest(state, params));
  }

  function hasError(state: State, params: IdParams) {
    return Request.hasError(this.getRequest(state, params));
  }

  function forbidden(state: State, params: IdParams) {
    return Request.forbidden(this.getRequest(state, params));
  }

  function notFound(state: State, params: IdParams) {
    return Request.notFound(this.getRequest(state, params));
  }

  function validationErrors(state: State, params: IdParams): ValidationErrors {
    return Request.validationErrors(this.getRequest(state, params));
  }

  function clear(params: IdParams) {
    return requestCleared(props.id(params));
  }

  function perform({
    params,
    ...options
  }: {
    params: PerformParams & IdParams,
    ...RequestOptions,
  }): ThunkAction {
    return function (dispatch, getState) {
      const mergedOptions = getOptions(options, params, dispatch, getState);
      return dispatch(
        request(
          props.id(params),
          () => props.perform(params, dispatch, getState),
          mergedOptions
        )
      );
    };
  }

  function getOptions(options, params, dispatch, getState): RequestOptions {
    const mergedOptions: RequestOptions = {
      onSuccess: createSuccessCallback(
        options.onSuccess,
        params,
        dispatch,
        getState
      ),
      onError: createErrorCallback(options.onError, params, dispatch, getState),
    };

    if (Object.prototype.hasOwnProperty.call(props, "force")) {
      mergedOptions.force = props.force;
    }

    if (Object.prototype.hasOwnProperty.call(props, "invalidateable")) {
      mergedOptions.invalidateable = props.invalidateable;
    }

    if (Object.prototype.hasOwnProperty.call(options, "force")) {
      mergedOptions.force = options.force;
    }

    if (Object.prototype.hasOwnProperty.call(options, "invalidateable")) {
      mergedOptions.invalidateable = options.invalidateable;
    }

    return mergedOptions;
  }

  function createSuccessCallback(
    onSuccessOption?: (any) => mixed,
    params,
    dispatch,
    getState
  ) {
    return async function (response) {
      if (props.onSuccess) {
        await props.onSuccess(response, params, dispatch, getState);
      }
      if (onSuccessOption) {
        await onSuccessOption(response);
      }
    };
  }

  function createErrorCallback(
    onErrorOption?: (Error) => mixed,
    params,
    dispatch,
    getState
  ) {
    return function (error) {
      if (props.onError) {
        props.onError(error, params, dispatch, getState);
      }
      if (onErrorOption) {
        onErrorOption(error);
      }
    };
  }

  return {
    perform,
    getRequest,
    isFirstLoading,
    isFirstLoadingStrict,
    isLoading,
    isLoadingStrict,
    success,
    hasError,
    forbidden,
    notFound,
    validationErrors,
    clear,
    promise,
  };
}
