// @flow
import classnames from "classnames";
import * as React from "react";
import Button from "core/components/Button";
import ValidationError from "core/components/ValidationError";
import style from "./style.scss";

type Props = {
  children?: any,
  footer?: any,
  submitText?: string,
  fullWidthSubmit?: boolean,
  secondaryButton?: React.Node,
  marketingTriggerClassName?: string,
  onSubmit?: Function,
  disabled?: boolean,
  showSubmitGraphic?: boolean,
  className?: string,
  error?: ?(string[] | string),
};

export default function OnboardingForm({
  children,
  footer,
  onSubmit,
  submitText,
  fullWidthSubmit,
  marketingTriggerClassName,
  disabled,
  className,
  showSubmitGraphic,
  secondaryButton,
  error,
}: Props) {
  const Component = fullWidthSubmit ? "div" : "span";
  const hasStackedButtons = fullWidthSubmit && secondaryButton;

  return (
    <form className={classnames(style.form, className)} onSubmit={onSubmit}>
      {children}

      {error && <ValidationError className={style.error} error={error} />}

      <div
        className={classnames(style.footer, {
          [style.columnFooter]: fullWidthSubmit && secondaryButton,
        })}
      >
        {footer}
        {submitText && (
          <Component
            className={classnames(style.submitButton, {
              [style.fullContainer]: hasStackedButtons,
            })}
          >
            <Button
              type="submit"
              disabled={disabled}
              fullwidth={fullWidthSubmit}
              marketingTriggerClassName={marketingTriggerClassName}
              primary
              large
            >
              {submitText}
            </Button>
          </Component>
        )}
        {secondaryButton && secondaryButton}
      </div>
    </form>
  );
}
