// @flow
import invariant from "invariant";
import { connect } from "react-redux";
import { openFile } from "abstract-di/actions";
import { dismissDialog } from "core/actions/dialogs";
import type { State, Dispatch, FileMode } from "core/types";
import type { Props, OwnProps, DispatchProps } from ".";

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    openFile: ({
      projectId,
      branchId,
      fileId,
      sha,
      layerId,
      fileMode,
    }: {
      projectId: string,
      branchId: string,
      fileId: string,
      sha: string,
      layerId?: string,
      fileMode?: FileMode,
    }) => {
      invariant(openFile, "openFile is required for dialog action");
      dispatch(
        openFile({
          projectId,
          branchId,
          fileId,
          sha,
          layerId,
          fileMode,
        })
      );
    },
    dismissDialog: () => {
      dispatch(dismissDialog());
    },
  };
}

export default connect<Props, OwnProps, _, DispatchProps, State, Dispatch>(
  undefined,
  mapDispatchToProps
);
