// @flow
import empty from "empty";
import { compact, map } from "lodash";
import type { Props as ContextMenuItemProps } from "core/components/ContextMenu/ContextMenuItem";
import { isDesktop, isQA } from "core/lib/platform";
import type { MenuItem } from "core/types";

function isSeparator(item: MenuItem): boolean {
  return !!(item && item.type && item.type === "separator");
}

type NormalizedContextMenuItem = {
  danger?: boolean,
  props?: Object,
  label?: string,
  separator?: boolean,
  checked?: boolean,
  enabled?: boolean,
  type?: string,
  id?: string,
  text?: string,
  submenu?: MenuItem[],
  click?: (event?: SyntheticEvent<>) => void,
  close?: () => void,
  qaSelector?: string,
};

export function buildContextMenuItems(
  items?: MenuItem[],
  onShareClick?: (MenuItem) => void
): NormalizedContextMenuItem[] {
  if (!items || !items.length) {
    return empty.array;
  }

  const showNativeMenu = isDesktop && !isQA;

  const visibleItems = items.filter((item) => item.visible !== false);

  return compact(
    map(visibleItems, (item: MenuItem, index: number) => {
      if (!showNativeMenu && item.type === "separator") {
        return;
      }

      const previousItem: MenuItem = visibleItems[index - 1];
      const prevPreviousItem: MenuItem = visibleItems[index - 2];
      const separator =
        isSeparator(previousItem) &&
        prevPreviousItem &&
        !isSeparator(prevPreviousItem);

      if (item.type === "share" || item.type === "copy") {
        const normalizedItem: ContextMenuItemProps = {
          ...item,
          separator,
          type: showNativeMenu ? undefined : item.type,
          label: item.label || "Copy Link…",
          click: onShareClick ? () => onShareClick(item) : undefined,
          "data-qa": item.label || "Copy Link…",
        };
        return normalizedItem;
      }

      if ((!item.type || item.type !== "separator") && item.submenu) {
        const normalizedItem: NormalizedContextMenuItem = {
          ...item,
          // $FlowFixMe Our internal typing for MenuItem does not exactly match Props needed for ContextMenuItem
          submenu: buildContextMenuItems(item.submenu, onShareClick),
        };
        return normalizedItem;
      }

      return { ...item, separator };
    })
  );
}
