// @flow
import classnames from "classnames";
import * as React from "react";
import { Abstract } from "core/lib/abstract";
import * as LayerData from "core/models/layerData";
import type {
  LayerData as TLayerData,
  LayerLink,
  BranchCollectionDescriptor,
  ReactRouterLocation,
} from "core/types";
import { default as HotspotLayer } from "./HotspotLayer";
import style from "./style.scss";

type Props = {
  width: number,
  height: number,
  scale: number,
  layers: TLayerData[],
  descriptor: Abstract.LayerVersionDescriptor | BranchCollectionDescriptor,
  style?: {},
  onClickTarget?: (link: LayerLink) => void,
  previousPrototypeExists: boolean,
  location: ReactRouterLocation,
  flashHotspotsOnOverlayMount: boolean,
  onInitialHotspotFlashed: () => void,
};

export default function HotspotsOverlay(props: Props) {
  const screenRef = React.useRef<?HTMLElement>(null);
  const timeoutRef = React.useRef<?TimeoutID>(null);
  const [animate, setAnimate] = React.useState(false);

  const {
    descriptor,
    scale = 1,
    previousPrototypeExists,
    onClickTarget,
    onInitialHotspotFlashed,
    flashHotspotsOnOverlayMount,
    layers,
  } = props;

  const flashHotspots = React.useCallback(() => {
    setAnimate(true);
  }, []);

  React.useEffect(() => {
    if (flashHotspotsOnOverlayMount && layers.length) {
      flashHotspots();
      onInitialHotspotFlashed();
    }
  }, [
    flashHotspots,
    flashHotspotsOnOverlayMount,
    layers.length,
    onInitialHotspotFlashed,
  ]);

  React.useEffect(() => {
    if (!flashHotspotsOnOverlayMount && animate) {
      timeoutRef.current = setTimeout(() => {
        setAnimate(false);
      }, 800);
    }
  }, [animate, flashHotspotsOnOverlayMount]);

  return (
    <div
      className={classnames(style.screen, {
        [style.animate]: animate,
      })}
      style={screenStyle(props)}
      ref={screenRef}
      onClick={flashHotspots}
    >
      {layers.map((layerData) => {
        const link = layerData.properties.link;
        if (link && (!link.previous || previousPrototypeExists)) {
          return (
            <HotspotLayer
              key={LayerData.key(layerData)}
              layerData={layerData}
              link={link}
              descriptor={descriptor}
              scale={scale}
              onClickTarget={onClickTarget}
              location={props.location}
            />
          );
        }
        return null;
      })}
    </div>
  );
}

function screenStyle(props: Props): {
  width: string,
  height: string,
} {
  return {
    ...(props.style || {}),
    width: `${props.width * props.scale}px`,
    height: `${props.height * props.scale}px`,
  };
}
