// @flow
import * as React from "react";
import Flex from "core/components/Flex";
import GlobalSidebar from "core/components/GlobalSidebar";
import type { Branch, Organization, Project } from "core/types";
import connector from "./connector";
import style from "./style.scss";

type WebProps = {|
  active?: boolean,
  mobile?: boolean,
  onClose?: () => void,
|};

export type OwnProps = {|
  ...WebProps,
  branchId?: string,
  children: React.Node,
  organizationId: string,
  projectId?: string,
|};

export type StateProps = {|
  branch: ?Branch,
  defaultOrganization: ?Organization,
  organization: ?Organization,
  project: ?Project,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
};

function GlobalNavigationShell(props: Props) {
  return (
    <Flex className={style.container}>
      <GlobalSidebar
        active={props.active}
        mobile={props.mobile}
        onClose={props.onClose}
        organization={props.organization || props.defaultOrganization}
        currentBranch={props.branch}
        currentProject={props.project}
        skipLinkDestination="#main-content"
      />
      <Flex grow id="main-content" column className={style.main}>
        {props.children}
      </Flex>
    </Flex>
  );
}

export default connector(GlobalNavigationShell);
