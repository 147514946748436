// @flow
/* global HTMLInputElement */
import Clipboard from "clipboard";
import get from "lodash/get";
import * as React from "react";
import ReactDOM from "react-dom";
import Input from "core/components/Input";
import { ModalContent } from "core/components/Modal";
import type { Project, Organization } from "core/types";
import { Wrapper, Content, Note, Button, Footer } from "../shared";
import style from "../shared/style.scss";

type Props = {|
  role: string,
  project?: Project,
  organization: Organization,
  onCreateInvite: (emails?: string[]) => Promise<Object[]>,
  onSuccess: (string) => void,
  showInviteTypeScreen: () => void,
  onDismiss: () => void,
|};

type State = {
  loading: boolean,
  error: string,
  link: string,
};

export default class LinkScreen extends React.Component<Props, State> {
  button: ?Button;
  clipboard: Clipboard;

  state: State = {
    loading: true,
    error: "",
    link: "",
  };

  componentDidMount() {
    this.createInvite();
    this.setUpClipboard();
  }

  componentWillUnmount() {
    this.clipboard && this.clipboard.destroy();
  }

  setUpClipboard = () => {
    this.clipboard = new Clipboard(ReactDOM.findDOMNode(this.button), {
      text: () => this.state.link,
    });
    this.clipboard.on("success", this.handleSuccess);
  };

  async createInvite() {
    try {
      const invites = await this.props.onCreateInvite();
      const invite = get(invites, "[0]", {});
      this.setState({ link: invite.invitationUrl });
    } catch (error) {
      this.setState({ error: "Could not create invitation." });
    } finally {
      this.setState({ loading: false });
    }
  }

  handleSuccess = () => {
    this.props.onSuccess("Invite link copied to clipboard");
  };

  handleFocus = (event: SyntheticEvent<*>) => {
    const target = event.target;
    if (target && target instanceof HTMLInputElement) {
      /*
        setTimeout and setSelectionRange are being used to enable text
        selection on iOS. iOS doesn't permit input.select(), and for some
        reason doesn't respond to a synchronous command.
      */
      setTimeout(() => {
        target.setSelectionRange(0, 9999);
      }, 0);
    }
  };

  render() {
    const { loading, error, link } = this.state;
    const { showInviteTypeScreen, role, project, organization, onDismiss } =
      this.props;
    const roleName = role === "viewer" ? "Viewers" : "Contributors";

    return (
      <ModalContent
        footer={
          <Footer>
            {role === "viewer" && (
              <Button onClick={showInviteTypeScreen}>Back</Button>
            )}
            <Button
              onClick={onDismiss}
              qaSelector="inviteLinkScreenDoneButton"
              primary
            >
              Done
            </Button>
          </Footer>
        }
      >
        <Wrapper>
          <Content>
            <div className={style.inputWrapper}>
              <Input
                wrapperClass={style.inputWithButton}
                label="Get Invite Link"
                value={link}
                placeholder="Loading…"
                disabled={loading || !!error}
                error={error}
                type="url"
                onFocus={this.handleFocus}
                qaSelector="inviteLink"
              />
              <Button
                className={style.copyButton}
                disabled={loading || !!error}
                innerRef={(ref) => (this.button = ref)}
                qaSelector="copyInviteLinkButton"
              >
                Copy
              </Button>
            </div>
            <Note>
              <p>
                You can share this link with people you want to invite to the
                {project ? (
                  <span>
                    <strong> {project.name}</strong> project as Guest {roleName}
                    .
                  </span>
                ) : (
                  <span>
                    <strong> {organization.name}</strong> organization as{" "}
                    {roleName}.
                  </span>
                )}
              </p>
            </Note>
          </Content>
        </Wrapper>
      </ModalContent>
    );
  }
}
