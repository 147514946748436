// @flow
import { get } from "lodash";
import * as React from "react";
import type { Annotation, CommentForm as TCommentForm } from "core/types";
import CommentForm, { type OwnProps as CommentFormProps } from "../CommentForm";

type Props = {
  ...CommentFormProps,
  onCreate: (formId: string, params: TCommentForm) => *,
  sha?: string,
  projectId: string,
  branchId?: string,
  fileId?: string,
  pageId?: string,
  layerId?: string,
  collectionId?: string,
  showIndicatorOn?: "focus" | "always",
  isPubliclyShared?: boolean,
  indicator?: React.Node,
  renderActionsInsideTextarea?: boolean,
};

export default class CommentCreateForm extends React.Component<Props> {
  get annotation(): ?Annotation {
    // If the annotation does not have required parameters then it should not
    // be attached to the comment (user started, but never finished drawing).
    if (
      get(this.props.annotation, "width") &&
      get(this.props.annotation, "height")
    ) {
      return this.props.annotation;
    }
    return undefined;
  }

  handleSubmit = (body: string) => {
    if (!body.length) {
      return;
    }

    this.props.onCreate(this.props.formId, {
      projectId: this.props.projectId,
      parentId: this.props.parentId,
      branchId: this.props.branchId,
      commitSha: this.props.sha,
      fileId: this.props.fileId,
      pageId: this.props.pageId,
      layerId: this.props.layerId,
      collectionId: this.props.collectionId,
      annotation: this.annotation || undefined,
      body,
    });
  };

  render() {
    return (
      <CommentForm
        key={this.props.formId}
        projectId={this.props.projectId}
        flat={this.props.flat}
        inline={this.props.inline}
        online={this.props.online}
        body={this.props.body}
        placeholder={this.props.placeholder}
        formId={this.props.formId}
        minHeight={this.props.minHeight}
        disabled={this.props.disabled}
        defaultFocus={this.props.defaultFocus}
        hasError={this.props.hasError}
        className={this.props.className}
        onRequestAnnotationRemoval={this.props.onRequestAnnotationRemoval}
        onSubmit={this.handleSubmit}
        onChange={this.props.onChange}
        onReset={this.props.onReset}
        onFocus={this.props.onFocus}
        onBlur={this.props.onBlur}
        autoFocus={this.props.autoFocus}
        annotation={this.props.annotation}
        isAnnotatable={this.props.isAnnotatable}
        collapsible={this.props.collapsible}
        submitButtonText={this.props.submitButtonText}
        submitButton={this.props.submitButton}
        showIndicatorOn={this.props.showIndicatorOn}
        isPubliclyShared={this.props.isPubliclyShared}
        indicator={this.props.indicator}
        renderActionsInsideTextarea={this.props.renderActionsInsideTextarea}
        toggleNewAnnotation={this.props.toggleNewAnnotation}
        qaSelector="comment-create-form"
      />
    );
  }
}
