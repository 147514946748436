// @flow
import Immutable from "seamless-immutable";
import createEntityReducer from "core/reducers/createEntityReducer";
import type { Notification } from "core/types";

function notificationsReducer(state, action) {
  switch (action.type) {
    case "core/NOTIFICATIONS_MARKED": {
      const { readState } = action.meta;
      const now = new Date().toISOString();
      let immutableState = Immutable.from(state);

      for (const notificationId of action.payload) {
        if (state[notificationId]) {
          immutableState = Immutable.setIn(
            immutableState,
            [notificationId, "readAt"],
            readState === "read" ? now : null
          );
        }
      }

      return immutableState;
    }

    case "core/NOTIFICATIONS_READ_ALL": {
      const now = new Date().toISOString();
      let immutableState = Immutable.from(state);

      for (const notificationId in state) {
        if (state[notificationId] && state[notificationId].readAt === null) {
          immutableState = Immutable.setIn(
            immutableState,
            [notificationId, "readAt"],
            now
          );
        }
      }

      return immutableState;
    }

    default:
      return state;
  }
}

export default createEntityReducer<Notification>(
  "notifications",
  notificationsReducer
);
