// @flow
import * as Abstract from "abstract-sdk";
import apiRequest from "abstract-di/api";
import { entitiesReceived } from "core/actions/entities";
import { normalizeAssets } from "core/schemas/asset";
import { getResolvedLayerDescriptor } from "core/selectors/descriptors";
import defineRequest from "./";

export const AssetsFetchRequest = defineRequest<
  Abstract.LayerVersionDescriptor,
  Abstract.LayerVersionDescriptor,
>({
  id: (params) => {
    return `get:projects/${params.projectId}/branches/${params.branchId}/commits/${params.sha}/files/${params.fileId}/layers/${params.layerId}/assets`;
  },
  perform: (params, dispatch, getState) => {
    const resolvedParams = getResolvedLayerDescriptor(getState(), params);
    return apiRequest(
      "get",
      `projects/${params.projectId}/branches/${params.branchId}/commits/${resolvedParams.sha}/files/${params.fileId}/layers/${params.layerId}/assets`
    );
  },
  onSuccess: (response, params, dispatch) => {
    const normalizedData = normalizeAssets(response.data).entities;
    dispatch(entitiesReceived(normalizedData));
  },
});
