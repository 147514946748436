// @flow
import type { Cancelable } from "lodash";
import * as React from "react";
import { Waypoint } from "react-waypoint";
import Centered from "core/components/Centered";
import CollectionThumbnail, {
  Placeholder as CollectionThumbnailPlaceholder,
} from "core/components/CollectionThumbnail";
import Error from "core/components/Empty/Error";
import NoCollections from "core/components/Empty/NoCollections";
import NoResults from "core/components/Empty/NoResults";
import type { Item } from "core/components/FilterPopover";
import Spinner from "core/components/Spinner";
import VirtualizedList, { type Row } from "core/components/VirtualizedList";
import type {
  User,
  Collection,
  Project,
  CollectionBranchFilter,
} from "core/types";
import FilteredItemsFooter from "./FilteredItemsFooter";
import Header from "./Header";
import style from "./style.scss";

type Props = {
  project: ?Project,
  projectId: string,
  collections: Collection[],
  selectedAuthor: ?User,
  authorFilterItems: Item[],
  onSelectAuthor: (userId: ?string) => void,
  search: string,
  onChangeSearchFilter: (searchFilter: string) => void,
  branchFilter: CollectionBranchFilter,
  onClearFilters: () => void,
  scrollableRef?: (*) => *,
  onLoadMoreCollections: (() => Promise<void>) & Cancelable,
  isFirstLoading: boolean,
  canCreateCollectionsOnMaster: boolean,
  isLoading: boolean,
  hasError: boolean,
  isOffline: boolean,
  totalNumberOfCollections: ?number,
  hasBeenFiltered: boolean,
  showNoCollections: boolean,
  showFilteredItemsDisclaimer: boolean,
  showNoSearchResults: boolean,
};

type State = {
  headerHasShadow: boolean,
};

const PLACEHOLDER_OBJECTS = Array(15)
  .fill()
  .map((_, index) => ({ index }));

export default class Desktop extends React.Component<Props, State> {
  state = {
    headerHasShadow: false,
  };

  listRef = React.createRef<VirtualizedList>();

  handleShowShadow = () => this.setState({ headerHasShadow: true });
  handleHideShadow = () => this.setState({ headerHasShadow: false });

  handleClearFilters = () => {
    this.props.onClearFilters();
    const list = this.listRef.current;
    if (list) {
      list.scrollTo({ index: 0 });
    }
  };

  getItems = (): Row[] => {
    const gridItemProps = {
      gridItem: true,
      gridRowClassName: style.gridRow,
      className: style.gridItem,
      height: ({ columnWidth }) => 0.75 * columnWidth + 90,
      // The collection thumbnail should be 75% of the column's width + 90px for the footer info
    };

    let items = [
      {
        height: 1,
        children: (
          <Waypoint
            onEnter={this.handleHideShadow}
            onLeave={this.handleShowShadow}
          />
        ),
      },
    ];

    if (this.props.isFirstLoading) {
      PLACEHOLDER_OBJECTS.forEach(({ index }) => {
        items.push({
          ...gridItemProps,
          children: <CollectionThumbnailPlaceholder key={index} />,
        });
      });
    } else {
      this.props.collections.forEach((collection, index) => {
        items.push({
          ...gridItemProps,
          children: (
            <CollectionThumbnail
              key={collection.id}
              projectId={this.props.projectId}
              collectionId={collection.id}
              qaSelector={`collection-thumbnail-${index}`}
            />
          ),
        });
      });
    }

    if (this.props.showFilteredItemsDisclaimer) {
      items.push({
        height: 280,
        children: (
          <FilteredItemsFooter
            onClearFilters={this.handleClearFilters}
            onClearAuthorFilter={() => this.props.onSelectAuthor(null)}
            onClearSearchFilter={() => this.props.onChangeSearchFilter("")}
            numberOfVisibleCollections={this.props.collections.length}
            totalNumberOfCollections={this.props.totalNumberOfCollections}
            searchTerm={this.props.search}
            selectedAuthorName={
              this.props.selectedAuthor ? this.props.selectedAuthor.name : null
            }
          />
        ),
      });
    } else if (this.props.isLoading) {
      items.push({
        height: 100,
        children: (
          <Centered>
            <Spinner small />
          </Centered>
        ),
      });
    } else {
      items.push({
        height: 100,
        children: <Waypoint onEnter={this.props.onLoadMoreCollections} />,
      });
    }

    return items;
  };

  renderBody() {
    if (this.props.showNoSearchResults) {
      return (
        <NoResults
          term={this.props.search}
          onClearFilters={this.handleClearFilters}
        />
      );
    }

    if (this.props.showNoCollections) {
      return <NoCollections canCreateCollection />;
    }

    return (
      <div className={style.projectCollections}>
        <VirtualizedList
          className={style.virtualizedList}
          ref={this.listRef}
          items={this.getItems()}
          resizeProps={{
            isLoading: this.props.isLoading,
            totalNumberOfCollections: this.props.totalNumberOfCollections,
            hasBeenFiltered: this.props.hasBeenFiltered,
          }}
        />
      </div>
    );
  }

  render() {
    if (this.props.hasError && !this.props.isOffline) {
      return <Error />;
    }

    return (
      <React.Fragment>
        <Header
          canCreateCollectionsOnMaster={this.props.canCreateCollectionsOnMaster}
          authorFilterItems={this.props.authorFilterItems}
          onSelectAuthor={this.props.onSelectAuthor}
          onChangeSearchFilter={this.props.onChangeSearchFilter}
          projectId={this.props.projectId}
          searchFilter={this.props.search}
          selectedAuthor={this.props.selectedAuthor}
          branchFilter={this.props.branchFilter}
        />

        {this.renderBody()}
      </React.Fragment>
    );
  }
}
