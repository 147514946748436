// @flow
import classnames from "classnames";
import * as React from "react";
import Banner from "core/components/Banner";
import Button from "core/components/Button";
import ContentHeader from "core/components/ContentHeader";
import Flex from "core/components/Flex";
import Icon from "core/components/Icon";
import ValidationError from "core/components/ValidationError";
import style from "./style.scss";

type Props = {
  children?: any,
  content?: React.Node,
  disabled?: boolean,
  flashBanner?: boolean,
  icon?: string,
  error?: Array<string> | string,
  heading?: React.Element<any> | string,
  subheading?: React.Node,
  submitText?: string,
  success?: boolean,
  successMessage?: string,
  mobile?: boolean,
  onClick?: (SyntheticEvent<>) => *,
  onBannerHide?: () => void,
};

type State = { successMessage: string, errorMessage: string };

export default class FormHeader extends React.Component<Props, State> {
  state = { successMessage: "", errorMessage: "" };

  successTimeout: TimeoutID;
  errorTimeout: TimeoutID;

  getError = (props: Props) => {
    let { error } = props;
    if (typeof error === "object") {
      error = error.join(", ");
    }
    return error;
  };

  showSuccessMessage = (successMessage: string) => {
    this.setState({ successMessage }, () => {
      if (this.props.flashBanner) {
        /* We use timeout instead of lodash/delay in order to clear the timeout
          so that banner hides don't overlap */
        if (this.successTimeout) {
          clearTimeout(this.successTimeout);
        }
        this.successTimeout = setTimeout(() => {
          this.setState({ successMessage: "" });
          this.props.onBannerHide && this.props.onBannerHide();
        }, 5000);
      }
    });
  };

  showErrorMessage = (errorMessage: ?string) => {
    if (!errorMessage) {
      return;
    }

    this.setState({ errorMessage }, () => {
      if (this.props.flashBanner) {
        if (this.errorTimeout) {
          clearTimeout(this.errorTimeout);
        }
        this.errorTimeout = setTimeout(() => {
          this.setState({ errorMessage: "" });
          this.props.onBannerHide && this.props.onBannerHide();
        }, 5000);
      }
    });
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.success && !this.props.success) {
      this.setState({ successMessage: "" });
    } else if (
      this.props.success &&
      this.props.successMessage &&
      this.state.successMessage !== this.props.successMessage
    ) {
      this.showSuccessMessage(this.props.successMessage);
    }

    if (prevProps.error && !this.props.error) {
      this.setState({ errorMessage: "" });
    } else if (
      this.props.error &&
      this.state.errorMessage !== this.getError(this.props)
    ) {
      const msg = this.getError(this.props);
      if (msg) {
        this.showErrorMessage(msg);
      }
    }
  }

  render() {
    return (
      <div className={style.wrap}>
        {this.props.heading && (
          <ContentHeader
            mobile={this.props.mobile}
            icon={this.props.icon}
            title={
              <Flex align="center" className={style.heading}>
                {this.props.heading}
                {this.props.subheading && <Icon type="go-to" />}
                {this.props.subheading && (
                  <span className={style.subheading}>
                    {this.props.subheading}
                  </span>
                )}
              </Flex>
            }
            actions={
              <React.Fragment>
                {this.props.children}
                {this.props.submitText && (
                  <div className={style.headerButton}>
                    <Button
                      primary
                      type={this.props.onClick ? undefined : "submit"}
                      disabled={this.props.disabled}
                      onClick={this.props.onClick}
                    >
                      {this.props.submitText}
                    </Button>
                  </div>
                )}
              </React.Fragment>
            }
          />
        )}
        {this.props.content}
        <Banner
          className={classnames(style.banner, {
            [style.activeBanner]: this.state.successMessage,
          })}
          variant="success"
          closeable={false}
          message={
            this.state.successMessage ? (
              <div className={style.bannerMessage}>
                <Icon
                  type="checkmark"
                  light
                  className={style.successMessageIcon}
                />
                <div>{this.state.successMessage}</div>
              </div>
            ) : (
              ""
            )
          }
        />
        <Banner
          className={classnames(style.banner, {
            [style.activeBanner]: this.state.errorMessage,
          })}
          variant="error"
          closeable={false}
          message={
            <div className={style.bannerMessage}>
              <ValidationError
                className={style.error}
                error={this.state.errorMessage}
              />
            </div>
          }
        />
      </div>
    );
  }
}
