// @flow
import classnames from "classnames";
import * as React from "react";
import Icon from "core/components/Icon";
import Popover from "core/components/Popover";
import TextHighlight from "core/components/TextHighlight";
import type { Project } from "core/types";
import style from "./style.scss";

type Props = {
  project: Project,
  light?: boolean,
  block?: boolean,
  highlight?: string,
};

export default function ProjectName({
  project,
  light,
  block,
  highlight,
}: Props) {
  const isPrivate = project.visibility === "specific";
  let name = project.name;
  let lastWord = "";
  const words = name.split(" ");

  if (block && isPrivate) {
    if (highlight) {
      lastWord = name;
      name = "";
    } else {
      name = words.slice(0, words.length - 1).join(" ");
      lastWord = words[words.length - 1];
    }
  }

  return (
    <span
      className={classnames({
        [style.ellipsis]: !block,
        [style.block]: block,
      })}
    >
      {name && (
        <span className={style.innerName}>
          <TextHighlight text={name} highlight={highlight} />
        </span>
      )}
      {isPrivate && (
        <span className={style.lastWord}>
          {highlight && lastWord ? (
            <TextHighlight text={lastWord} highlight={highlight} />
          ) : words.length > 1 ? (
            ` ${lastWord}`
          ) : (
            lastWord
          )}
          <Popover
            delayShow={250}
            placement="bottom"
            label="This project is private"
          >
            <span className={style.iconWrap}>
              <Icon type="lock-locked" light={light} className={style.icon} />
            </span>
          </Popover>
        </span>
      )}
    </span>
  );
}
