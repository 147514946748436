// @flow
import * as React from "react";
import CreateOrganizationButton from "core/components/CreateOrganizationButton";
import image from "./images/organization.svg";
import Empty, { type Props } from ".";

export default function NoOrganizations(props: Props) {
  return (
    <Empty
      {...props}
      image={image}
      title="Create an organization"
      description="You can be invited to an organization or create a new one."
    >
      <span>
        <CreateOrganizationButton title="Create Organization" />
      </span>
    </Empty>
  );
}
