// @flow
import { connect } from "react-redux";
import { loadLayersForCollection } from "abstract-di/actions";
import {
  getCollectionLayers,
  getCollectionPreviewLayers,
} from "core/selectors/collections";
import type { Dispatch, State } from "core/types";
import type { OwnProps, StateProps, DispatchProps } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const collectionId = props.collection.id;
  return {
    count: getCollectionLayers(state, { collectionId }).length,
    layers: getCollectionPreviewLayers(state, { collectionId }),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { collection } = props;

  return {
    onMount() {
      if (loadLayersForCollection) {
        loadLayersForCollection(collection.id);
      }
    },
  };
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default connect(mapStateToProps, mapDispatchToProps);
