// @flow
import { reduce } from "lodash";
import { static as Immutable } from "seamless-immutable";
import * as Request from "core/models/request";
import type { Action, Requests } from "core/types";

const DEFAULT_REQUEST = Request.DEFAULT;
const DEFAULT_STATE: Requests = Immutable.from({});
const DEFAULT_ACTION = { type: "core/noop" };

export default function (
  state: Requests = DEFAULT_STATE,
  action: Action = DEFAULT_ACTION
): Requests {
  switch (action.type) {
    case "core/REQUEST_ERROR": {
      const request = Immutable.merge(
        state[action.meta.id] || DEFAULT_REQUEST,
        {
          state: "error",
          error: action.payload,
          updatedAt: new Date(),
        }
      );
      return Immutable.set(state, action.meta.id, request);
    }

    case "core/REQUEST_LOADING":
      return Immutable.set(
        state,
        action.meta.id,
        Immutable.merge(state[action.meta.id] || DEFAULT_REQUEST, {
          state: "loading",
          updatedAt: new Date(),
          invalidateable: action.meta.invalidateable,
          promise: action.payload.catch(() => {
            // Ignore unhandled rejection error from seamless-immutable
            // https://github.com/rtfeldman/seamless-immutable/blob/a6e1b5e6461a2db6a323b2ceb76d931d17961d59/src/seamless-immutable.js#L633
          }),
        })
      );

    case "core/REQUEST_SUCCESS":
      return Immutable.set(
        state,
        action.meta.id,
        Immutable.merge(state[action.meta.id] || DEFAULT_REQUEST, {
          state: "success",
          error: undefined,
          invalidateable: action.meta.invalidateable,
          completed: true,
          updatedAt: new Date(),
        })
      );

    case "core/REQUEST_CLEARED":
      return Immutable.without(state, action.meta.id);

    case "core/INVALIDATE_REQUESTS":
      return Immutable.without(state, (r) => r.invalidateable === true);

    case "core/AUTHENTICATED": {
      // Clear out unauthenticated/unauthorized requests from before we were logged in.
      const unauthorized = reduce(
        state,
        (memo, request, id) =>
          Request.unauthorized(request) ? memo.concat(id) : memo,
        []
      );
      return Immutable.without(state, unauthorized);
    }

    case "SIGNED_OUT":
      return DEFAULT_STATE;

    default:
      return state;
  }
}
