// @flow
import { debounce } from "lodash";
import * as React from "react";

export function useDebounce(value: string, delay: number) {
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(() => {
    const debounced = debounce(() => {
      setDebouncedValue(value);
    }, delay);
    debounced();

    return debounced.cancel;
  }, [value, delay]);

  return debouncedValue;
}
