// @flow
import * as React from "react";
import style from "./style.scss";

type Props = {
  layerState: "not_found" | *,
};

export default function DetailLoading(props: Props) {
  return (
    <div
      className={style.detailLoading}
      title={
        props.layerState === "not_found"
          ? "The layer doesn’t exist at this commit"
          : undefined
      }
    >
      {props.layerState === "not_found" ? "-" : "Loading…"}
    </div>
  );
}
