// @flow
import * as React from "react";
import ActivityMeta from "core/components/Activity/Meta";
import CommentBase, { type Props } from "./Base";
import DefaultComment from "./DefaultComment";
import ReferenceComment from "./ReferenceComment";
import Timestamp from "./Timestamp";
import style from "./style.scss";

export default class ActivityComment extends React.Component<Props> {
  get isBranchComment(): boolean {
    return !!(
      this.props.context.branchId &&
      this.props.branchId &&
      !this.props.layerId &&
      !this.props.commitSha
    );
  }

  get isReply(): boolean {
    return !!this.props.parentId;
  }

  get meta() {
    if (this.isBranchComment) {
      return (
        <Timestamp
          createdAt={this.props.createdAt}
          editedAt={this.props.editedAt}
        />
      );
    }

    return (
      <ActivityMeta
        createdAt={this.props.createdAt}
        branchId={this.props.branchId}
        branchName={this.props.branchName}
        projectId={this.props.projectId}
        projectName={this.props.projectName}
        fileId={this.props.fileId}
        fileName={this.props.fileName}
        layerId={this.props.layerId}
        layerName={this.props.layerName}
        context={this.props.context}
      />
    );
  }

  render() {
    const sharedProps = {
      ...this.props,
      showMenu: this.isBranchComment && this.props.isAuthor,
      className: style.activity,
    };

    if (this.isReply) {
      return this.isBranchComment ? (
        <DefaultComment {...sharedProps} />
      ) : (
        <ReferenceComment {...sharedProps} />
      );
    }

    return (
      <CommentBase
        {...sharedProps}
        showAvatar={false}
        title={null}
        meta={this.meta}
        preview={this.props.preview}
      />
    );
  }
}
