// @flow
import classnames from "classnames";
import * as React from "react";
import { Component as Avatar } from "core/components/Avatar";
import logo from "core/components/BrandHeader/images/logo.svg";
import ContextMenu from "core/components/ContextMenu";
import Flex from "core/components/Flex";
import useBranchDisplayName from "core/hooks/useBranchDisplayName";
import { fromNow } from "core/lib/dates";
import type { Notification } from "core/types";
import NotificationIcon from "./NotificationIcon";
import NotificationMessage from "./NotificationMessage";
import NotificationRoute from "./NotificationRoute";
import style from "./style.scss";

type Props = {|
  currentUserId: string,
  markNotifications: (id: string[], readState?: "read" | "unread") => void,
  notification: Notification,
  onClick: (id: string) => void,
|};

function NotificationListItem(props: Props) {
  const { currentUserId, markNotifications, notification, onClick } = props;
  const { branchName: commitBranchName } = useBranchDisplayName(
    notification.payload.commitBranchId,
    notification.payload.commitBranchName
  );
  const isUnread = notification.readAt === null;

  const [menuOpen, setMenuOpen] = React.useState(false);

  const classNames = classnames(style.notificationListItem, {
    [style.notificationMenuOpen]: menuOpen,
    [style.unreadNotification]: isUnread,
  });

  const menuItems = React.useMemo(
    () => [
      {
        click: () =>
          markNotifications(
            [notification.id],
            notification.readAt === null ? "read" : "unread"
          ),
        enabled: true,
        label: `Mark as ${notification.readAt === null ? "read" : "unread"}`,
      },
    ],
    [markNotifications, notification.id, notification.readAt]
  );

  const onAfterClose = React.useCallback(() => setMenuOpen(false), []);
  const onAfterShow = React.useCallback(() => setMenuOpen(false), []);

  const handleClick = React.useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      onClick(notification.id);
    },
    [notification.id, onClick]
  );

  return (
    <ContextMenu
      id="notifications-list-item"
      menuItems={menuItems}
      placement="left-end"
      onAfterClose={onAfterClose}
      onAfterShow={onAfterShow}
    >
      {(showMenu, ref) => (
        <div className={style.wrapper}>
          <Flex
            align="center"
            className={classNames}
            onClick={handleClick}
            onContextMenu={showMenu}
            data-qa={notification.readAt !== null ? "read" : "unread"}
          >
            <NotificationRoute
              currentUserId={currentUserId}
              notification={notification}
            >
              {notification.initiatingUser && notification.initiatingUserId ? (
                <Flex className={style.notificationAvatarContainer}>
                  <Avatar
                    className={style.notificationAvatar}
                    size={32}
                    src={notification.initiatingUser.avatarUrl}
                    userName={notification.initiatingUser.name}
                    userId={notification.initiatingUserId}
                  />
                  <NotificationIcon notification={notification} />
                </Flex>
              ) : (
                <Flex className={style.notificationAvatarContainer}>
                  <img src={logo} alt="Abstract Logo" width="32" height="32" />
                  <NotificationIcon notification={notification} />
                </Flex>
              )}
              <div className={style.notificationCopy}>
                <NotificationMessage
                  currentUserId={currentUserId}
                  notification={notification}
                />
                <div className={style.notificationMeta}>
                  {fromNow(notification.createdAt)}
                  {commitBranchName && ` · ${commitBranchName}`}
                </div>
              </div>
            </NotificationRoute>
          </Flex>
          <div className={style.contextMenuRef} ref={ref} />
        </div>
      )}
    </ContextMenu>
  );
}

// Only re-render a <NotificationListItem> when the readAt property has changed
export default React.memo<Props>(
  NotificationListItem,
  (prevProps, nextProps) =>
    prevProps.notification.readAt === nextProps.notification.readAt
);
