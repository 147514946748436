// @flow
import createCachedSelector from "@elasticprojects/re-reselect";
import { Abstract } from "core/lib/abstract";
import buildLayerChangesets from "core/lib/buildLayerChangesets";
import flatMapLayerChildren from "core/lib/flatMapLayerChildren";
import { visitEachLayer } from "core/lib/mapLayerChildren";
import * as Layer from "core/models/layer";
import * as LayerData from "core/models/layerData";
import { getPreviousLayerCommit } from "core/selectors/commits";
import { getResolvedLayerDescriptor } from "core/selectors/descriptors";
import { getEntity } from "core/selectors/entities";
import type {
  State,
  LayerDataset,
  LayerChangeset,
  LayerData as TLayerData,
  LayerContext,
} from "core/types";

const cacheByUniqueLayerId = (state, params) => {
  return Layer.uniqueId(getResolvedLayerDescriptor(state, params));
};

export function getLayerDataset(
  state: State,
  params: Abstract.LayerVersionDescriptor
): ?LayerDataset {
  return getEntity(state, "layerDatasets", cacheByUniqueLayerId(state, params));
}

export const getLayers: (
  state: State,
  params: Abstract.LayerVersionDescriptor
) => { [layerKey: string]: TLayerData } = createCachedSelector(
  [getLayerDataset],
  (layerDataset) => {
    if (!layerDataset) {
      return {};
    }

    const layersByKey = {};
    visitEachLayer(
      layerDataset.layers,
      layerDataset.layers[layerDataset.layerId],
      (layerData) => {
        layersByKey[LayerData.key(layerData)] = layerData;
      }
    );
    return layersByKey;
  }
)(cacheByUniqueLayerId);

export const getLayersOrdered: (
  state: State,
  params: Abstract.LayerVersionDescriptor
) => Array<[TLayerData, LayerContext]> = createCachedSelector(
  [getLayerDataset],
  (layerDataset) => {
    if (!layerDataset) {
      return [];
    }

    return flatMapLayerChildren(
      layerDataset.layers,
      layerDataset.layers[layerDataset.layerId]
    );
  }
)(cacheByUniqueLayerId);

export function getPreviousLayerDataset(
  state: State,
  params: Abstract.LayerVersionDescriptor
) {
  const previousCommit = getPreviousLayerCommit(state, params);

  if (!previousCommit) {
    return null;
  }

  return getLayerDataset(state, {
    ...params,
    sha: previousCommit.sha,
  });
}

export const getLayerChangeset: (
  state: State,
  params: Abstract.LayerVersionDescriptor
) => { [layerKey: string]: LayerChangeset } | void = createCachedSelector(
  [getLayerDataset, getPreviousLayerDataset],
  (currentLayerDataset, previousLayerDataset) => {
    if (!currentLayerDataset || !previousLayerDataset) {
      return;
    }

    return buildLayerChangesets(previousLayerDataset, currentLayerDataset);
  }
)(cacheByUniqueLayerId);
