// @flow
import classnames from "classnames";
import * as React from "react";
import FilePreviews from "core/components/FilePreviews";
import type {
  Changeset as TChangeset,
  ChangesetStatus,
  File,
  Layer as TLayer,
  Page as TPage,
  FilePreviews as TFilePreviews,
  MultiSelectedEntities,
} from "core/types";
import style from "./style.scss";

type Props = {
  changeset: ?TChangeset,
  changesetError?: boolean,
  isLoading: boolean,
  files: File[],
  pages: { [string]: TPage },
  layerStatuses: { [layerId: string]: ChangesetStatus },
  layerCommentCounts: { [string]: number },
  projectId: string,
  branchId?: string,
  contextBranchId: string,
  getLayerPath?: (layer: TLayer) => Object,
  hideLayerMenu?: boolean,
  hideContextMenu?: boolean,
  onLayerClick?: (layer: TLayer) => void,
  onZoomChange: (SyntheticEvent<*>, number) => void,
  zoom: number,
  fileActions?: (fileId: string, isSelecting: boolean) => ?React.Element<*>,
  header?: React.Node,
  footer?: React.Node,
  className?: string,
  filter?: string,
  query: string,
  previewsByFile: TFilePreviews,
  selectedEntities?: MultiSelectedEntities,
  selectedCount?: number,
  isSelecting?: boolean,
  canSelect?: boolean,
  mobile?: boolean,
  onSelectEntities?: ({
    page?: TPage,
    layer?: TLayer,
    layers?: TLayer[],
  }) => void,
  onSelectLayerRange?: (
    layer: TLayer,
    layers: TLayer[],
    entities?: MultiSelectedEntities,
    selected: boolean
  ) => void,
  layerSelected?: (layer: TLayer) => boolean,
  fileSelected?: (file: File) => boolean,
  filePartiallySelected?: (file: File) => boolean,
};

export default class Changeset extends React.Component<Props> {
  renderEmptyContent = () => {
    const { filter, query } = this.props;
    if (query) {
      const type = filter && filter !== "all" ? `${filter} items` : "items";
      return (
        <span>
          No {type} found matching <strong>{query}</strong>
        </span>
      );
    }

    return <span>No previewable changes.</span>;
  };

  render() {
    const { changesetError, ...rest } = this.props;
    return (
      <FilePreviews
        {...rest}
        showFileHeaders
        className={classnames(style.changeset, this.props.className, {
          [style.hasFooter]: rest.footer,
        })}
        hasError={changesetError}
        emptyContent={this.renderEmptyContent}
      />
    );
  }
}
