// @flow
import Cookies from "js-cookie";
import { SECURE_COOKIES_ENABLED } from "./config";

const KEY = "abstract-layer-zoom";
const DEFAULT_ZOOM = 1;

export function setZoom(zoom: number): void {
  Cookies.set(KEY, zoom, {
    secure: SECURE_COOKIES_ENABLED,
    expires: 365,
  });
}

export function getZoom(): number {
  const zoom = Cookies.get(KEY);
  if (!zoom) {
    return DEFAULT_ZOOM;
  }

  try {
    return JSON.parse(zoom);
  } catch (err) {
    return DEFAULT_ZOOM;
  }
}
