// @flow
import { schema, normalize } from "normalizr";
import { policies, user } from "core/schema";

export const AccountResponseSchema = new schema.Object({
  policies,
  user,
});

export function normalizeAccountResponse(response: any) {
  return normalize(response, AccountResponseSchema);
}
