// @flow
import classnames from "classnames";
import * as React from "react";
import Spinner from "core/components/Spinner";
import style from "./style.scss";

type Props = {
  small?: boolean,
  horizontal?: boolean,
  title?: string,
  className?: string,
  qaSelector?: string,
};

export default class Loading extends React.Component<Props> {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const classes = classnames(this.props.className, {
      [style.loading]: true,
      [style.small]: this.props.small,
      [style.horizontal]: this.props.horizontal,
    });

    return (
      <div className={classes}>
        <Spinner small={this.props.small} qaSelector={this.props.qaSelector} />
        {this.props.title && <p className={style.title}>{this.props.title}</p>}
      </div>
    );
  }
}
