// @flow
import classnames from "classnames";
import currency from "currency.js";
import * as React from "react";
import Input from "core/components/Input";
import Note from "core/components/Note";
import style from "./style.scss";
import type { ActionType } from ".";

type Props = {
  annual: boolean,
  onChange: (event: SyntheticInputEvent<>) => void,
  input: number,
  pricePerSeat: number,
  actionType: ActionType,
  disabled?: boolean,
  seatCapEnforced: ?boolean,
};

export default function SeatInputLine({
  annual,
  onChange,
  input,
  pricePerSeat,
  actionType,
  disabled,
  seatCapEnforced,
}: Props) {
  const SEATS_TITLE = seatCapEnforced
    ? `How many seats would you like to ${actionType}?`
    : `Number of seats you want to ${actionType}`;
  return (
    <div className={classnames(style.lineWrap, { [style.disabled]: disabled })}>
      <div>
        <span>{SEATS_TITLE}</span>
        <Note>
          Seats are {currency(pricePerSeat).divide(100).format(true)}
          {annual ? "/year " : "/month "}
          on your current plan
        </Note>
      </div>
      <div className={style.input}>
        <Input
          onChange={onChange}
          name="seatsToRemove"
          value={input}
          type="number"
          min="1"
          required
          autoFocus
          selectOnFocus
          disabled={disabled}
        />
      </div>
    </div>
  );
}
