// @flow
import classnames from "classnames";
import * as React from "react";
import Button from "core/components/Button";
import Centered from "core/components/Centered";
import Heading from "core/components/Heading";
import InviteUsers from "core/components/InviteUsers";
import Modal from "core/components/Modal";
import { isMac } from "core/lib/platform";
import {
  latestReleaseDownloadUrl,
  helpGuestsUrl,
  helpCommentsUrl,
} from "core/lib/urls";
import type { User, Organization, Subscription } from "core/types";
import AbstractAppButton from "web/components/AbstractAppButton";
import type { AppRelease } from "web/types";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  organization: Organization,
|};

export type StateProps = {|
  subscription: ?Subscription,
  currentUser: ?User,
  isLoading: boolean,
|};

export type DispatchProps = {|
  fetchLatestRelease: (
    onSuccess: (AppRelease) => void,
    onError: (Error) => void
  ) => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type State = {
  startedDownload: boolean,
  inviteDialogOpen: boolean,
  release: ?AppRelease,
};

class PromptAppDownload extends React.Component<Props, State> {
  state = {
    startedDownload: false,
    inviteDialogOpen: false,
    release: undefined,
  };

  componentDidMount() {
    this.props.fetchLatestRelease(
      (response) => {
        this.setState({
          release: response,
        });
      },
      () => {}
    );
  }

  startDownload = () => {
    this.setState({
      startedDownload: true,
    });
  };

  handleInviteDialogToggle = () => {
    this.setState((prevState) => ({
      inviteDialogOpen: !prevState.inviteDialogOpen,
    }));
  };

  renderNonMacScreen = () => {
    const classes = classnames(style.textCenter, style.invitePromptText);

    if (!this.props.currentUser) {
      return null;
    }

    return (
      <React.Fragment>
        <div className={style.nonMacImage} />
        <Heading level="1" size="xxl" className={style.textCenter}>
          Welcome, {this.props.currentUser.name}.<br />
          Abstract’s desktop app is only available for macOS.
        </Heading>
        {this.props.organization.isUsernameOrganization ? (
          <p className={classes}>
            You can still{" "}
            <a
              href={helpCommentsUrl()}
              target="_blank"
              rel="noopener noreferrer"
            >
              leave feedback
            </a>{" "}
            in the Abstract web app.
          </p>
        ) : (
          <React.Fragment>
            <p className={classes}>
              You can still{" "}
              <a
                href={helpGuestsUrl()}
                target="_blank"
                rel="noopener noreferrer"
              >
                invite your team
              </a>{" "}
              and{" "}
              <a
                href={helpCommentsUrl()}
                target="_blank"
                rel="noopener noreferrer"
              >
                leave feedback
              </a>{" "}
              in the Abstract web app.
            </p>
            <Button icon="user" primary onClick={this.handleInviteDialogToggle}>
              Invite People
            </Button>
            <Modal
              isOpen={this.state.inviteDialogOpen}
              onClose={this.handleInviteDialogToggle}
              className={style.dialog}
              title={`Invite to ${this.props.organization.name}`}
            >
              <InviteUsers
                organization={this.props.organization}
                onDismissDialog={this.handleInviteDialogToggle}
                subscription={this.props.subscription}
              />
            </Modal>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  renderMacScreen = () => {
    if (!this.props.currentUser) {
      return null;
    }

    const { release } = this.state;
    const versionText =
      !this.props.isLoading && release
        ? `Version ${release.version} - ${
            // Locally formattedFileSize is undefined. This will ensure we fallback
            // to a rough estimate in that case.
            release.formattedFileSize || "~200MB"
          }`
        : "";

    return (
      <React.Fragment>
        {this.state.startedDownload ? (
          <React.Fragment>
            <Heading level="1" size="xxl" className={style.textCenter}>
              The Abstract app is downloading
            </Heading>
            <div className={style.startedAppDownloadImage} />
            <div className={classnames(style.textCenter, style.downloadRetry)}>
              Download didn’t start?{" "}
              <a className="gtm-download-app" href={latestReleaseDownloadUrl()}>
                Click here.
              </a>
            </div>
            <AbstractAppButton primary />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Heading level="1" size="xxl" className={style.textCenter}>
              Download
            </Heading>
            <div
              className={classnames(style.textCenter, style.downloadPromptText)}
            >
              Download the macOS desktop app to start designing with Abstract.
            </div>
            <div className={style.startAppDownloadImage} />
            <AbstractAppButton
              onClick={this.startDownload}
              downloadButton
              fullwidth
              large
              primary
            />
            <div className={classnames(style.textCenter, style.appVersionText)}>
              <b>Abstract for macOS</b>
              <br />
              {versionText}
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  render() {
    return (
      <Centered
        flex
        direction="column"
        className={classnames(style.wrapper, {
          [style.appDownload]: this.state.startedDownload,
        })}
      >
        {isMac() ? this.renderMacScreen() : this.renderNonMacScreen()}
      </Centered>
    );
  }
}

export default connector(PromptAppDownload);
