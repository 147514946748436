// @flow
import invariant from "invariant";
import { find } from "lodash";
import { connect } from "react-redux";
import { addLibraries } from "abstract-di/actions";
import { getSyncedProjectsForOrganization } from "abstract-di/selectors";
import { dismissDialog } from "core/actions/dialogs";
import { BranchFetchRequest } from "core/requests/branches";
import { FilesFetchRequest } from "core/requests/files";
import { getBranch } from "core/selectors/branches";
import { canOrganizationUsePartialSync } from "core/selectors/features";
import { getLibraryFilesForOrganization } from "core/selectors/files";
import {
  getProject,
  getActiveProjectsForOrganization,
} from "core/selectors/projects";
import type { State, Dispatch } from "core/types";
import type { Props, OwnProps, StateProps, DispatchProps } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  return {
    projects: canOrganizationUsePartialSync(state, props)
      ? getActiveProjectsForOrganization(state, props)
      : getSyncedProjectsForOrganization(state, props),
    files: getLibraryFilesForOrganization(state, props),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    dismissDialog: () => dispatch(dismissDialog()),
    onClickProject: async (projectId) => {
      dispatch(async (dispatch, getState) => {
        const params = { projectId, branchId: "master" };
        await dispatch(BranchFetchRequest.perform({ params }));
        const master = getBranch(getState(), params);
        invariant(master, "branch should exist after loading");

        dispatch(
          FilesFetchRequest.perform({
            params: { projectId, branchId: master.id, sha: master.head },
          })
        );
      });
    },
    onSubmit: (projectId, fileIds) =>
      dispatch((dispatch, getState) => {
        const state = getState();
        const allFiles = getLibraryFilesForOrganization(state, props);
        const files = allFiles[projectId].entities;

        const libraries = fileIds.reduce((memo, fileId) => {
          const file = find(files, { id: fileId });
          const project = getProject(state, { projectId });
          if (!project || !file) {
            return memo;
          }

          return memo.concat({
            fileId: file.id,
            name: file.name,
            type: file.type,
            projectId: project.id,
            projectName: project.name,
            branchId: "master",
            branchName: "Master",
          });
        }, []);

        invariant(addLibraries, "addLibraries required");
        dispatch(addLibraries(props.projectId, props.branchId, libraries));
        dispatch(dismissDialog());
      }),
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
