// @flow
import * as React from "react";
import window from "core/global/window";

export default function connectStorage<P: {}>(
  WrappedComponent: React.ComponentType<P>,
  mapToStorage: (store: Object, props: P) => ?Object,
  storage: Storage = window.localStorage
): React.ComponentType<P> {
  return class ConnectStorage extends React.PureComponent<P> {
    // prettier-ignore
    static displayName = `connectStorage(${WrappedComponent.displayName || ""})`;

    storageProxy = {
      getItem: (key) => {
        const item = storage.getItem(key);
        return item ? JSON.parse(item) : undefined;
      },
      setItem: (key, value) => {
        if (value === undefined || value === "") {
          storage.removeItem(key);
        } else {
          storage.setItem(key, JSON.stringify(value));
        }

        // Intentionally force a re-render to display updated storage value
        this.forceUpdate();
      },
    };

    render() {
      const mappedProps = mapToStorage(this.storageProxy, this.props) || {};
      return <WrappedComponent {...this.props} {...mappedProps} />;
    }
  };
}
