// @flow
import path from "path";
import React from "react";
import FileName from "core/components/FileName";
import Icon from "core/components/Icon";
import Spinner from "core/components/Spinner";
import { extFileType } from "core/models/file";
import style from "./style.scss";

type Props = {
  name?: string,
  type?: string,
  filename?: string,
  icon?: string,
  complete: boolean,
  isLoading?: boolean,
  description?: string,
  isLibrary?: boolean,
  qaSelector?: string,
};

export default function ProcessingFile({
  name,
  type,
  filename,
  complete,
  icon,
  isLoading,
  description,
  isLibrary,
  qaSelector,
}: Props) {
  if (filename) {
    const ext = path.extname(filename);
    name = path.basename(filename, ext);
    type = extFileType(ext);
  }
  return (
    <li className={style.item} data-qa={qaSelector}>
      <span className={style.file}>
        <FileName
          name={name || ""}
          type={type || ""}
          isLibrary={isLibrary}
          iconClassName={style.fileIcon}
          icon={icon}
        />
      </span>
      <span className={style.complete}>
        {description && (
          <span className={style.description}>{description}</span>
        )}
        {complete && <Icon type="checkmark" allgood className={style.icon} />}
        {isLoading && <Spinner small className={style.icon} />}
      </span>
    </li>
  );
}
