// @flow
import { useSelector } from "react-redux";
import { useReviewRequests } from "core/hooks/reviews";
import { getBranches } from "core/selectors/reviews";
import type { ReviewRequest } from "core/types";

export const useBranches = ({ projectId }: { projectId: string }) =>
  useSelector((state) => getBranches(state, { projectId }));

export const useBranchReviewRequests = ({
  projectId,
  branchId,
}: {
  projectId: string,
  branchId: string,
}): ReviewRequest[] => {
  const allRequests = useReviewRequests({ projectId });

  return allRequests.filter(
    (r) => r.branchId === branchId && r.projectId === projectId
  );
};
