// @flow
import classnames from "classnames";
import invariant from "invariant";
import PopperJs from "popper.js";
import React, { Fragment } from "react";
import { createPortal } from "react-dom";
import window from "core/global/window";
import type { Props as CoreProps } from "./Core";
import style from "./core.scss";

export type Props = CoreProps & {
  target: ?HTMLElement,
  onDismiss: (e?: SyntheticMouseEvent<> | KeyboardEvent) => void,
  modifiers: Object,
  qaSelector?: string,
  clickable?: boolean,
};

type State = {
  placement?: "top" | "right" | "bottom" | "left",
  popper?: {
    position: string,
    left: number,
    top: number,
  },
  arrow?: {
    position: string,
    left: number,
    top: number,
  },
  mounted: boolean,
};

export default class Popper extends React.Component<Props, State> {
  portal: HTMLElement;
  container: ?HTMLElement;
  arrow: ?HTMLElement;
  popper: ?Object;
  rafId: ?number;

  static defaultProps = {
    modifiers: {},
  };

  state = {
    mounted: false,
  };

  constructor(props: Props) {
    super(props);
    this.portal = window.document.createElement("div");
  }

  componentDidMount() {
    invariant(window.document.body, "No document body");
    window.document.body.appendChild(this.portal);

    this.setState({ mounted: true }, () => {
      if (!this.props.target || !this.container) {
        return;
      }

      const modifiers = {
        applyStyle: { enabled: false },
        offset: { offset: this.props.offset || "0" },
        flip: { enabled: this.props.offset === undefined },
        ...this.props.modifiers,
      };
      if (this.arrow) {
        modifiers.arrow = { element: this.arrow };
      }

      this.popper = new PopperJs(this.props.target, this.container, {
        placement: this.props.placement,
        onUpdate: this.syncState,
        modifiers: modifiers,
      });

      this.rafId = window.requestAnimationFrame(this.update);
    });
  }

  componentWillUnmount() {
    if (this.rafId) {
      window.cancelAnimationFrame(this.rafId);
    }

    if (this.popper) {
      this.popper.destroy();
    }

    this.popper = null;

    invariant(window.document.body, "No document body");
    window.document.body.removeChild(this.portal);
  }

  // The recursive 60fps requestAnimationFrame is turned off by default
  // to preserve CPU resources. Popper usually calls this to maintain
  // responsiveness and position during events like resizing or scrolling,
  // or proximity to (resizable) sidebars. If your component
  // requires this, set scheduleUpdate to true.
  update = () => {
    if (this.popper) {
      this.popper.update();
    }

    if (this.props.scheduleUpdate) {
      this.rafId = window.requestAnimationFrame(this.update);
    }
  };

  syncState = ({ placement, offsets }: Object) => {
    // Only sync state when values used in render changes
    if (placement !== this.state.placement) {
      this.setState({ placement });
    }

    if (
      !this.state.popper ||
      offsets.popper.left !== this.state.popper.left ||
      offsets.popper.top !== this.state.popper.top ||
      offsets.popper.position !== this.state.popper.position
    ) {
      this.setState({ popper: offsets.popper });
    }

    if (
      !this.state.arrow ||
      offsets.arrow.left !== this.state.arrow.left ||
      offsets.arrow.top !== this.state.arrow.top ||
      offsets.arrow.position !== this.state.arrow.position
    ) {
      this.setState({ arrow: offsets.arrow });
    }
  };

  handleOutsideMouseClick = (event: SyntheticMouseEvent<>) => {
    event.stopPropagation();
    if (
      !this.props.onDismiss ||
      (event.button !== 0 && event.button !== 2) // not left or right click
    ) {
      return;
    }

    this.props.onDismiss(event);
  };

  get width(): ?number {
    if (this.props.fullWidth && this.props.target) {
      return this.props.target.offsetWidth;
    }
    return this.props.width;
  }

  render() {
    if (!this.state.mounted) {
      return null;
    }

    const { classNames = {}, trigger } = this.props;
    const { popper } = this.state;
    const containerOffset = popper
      ? {
          transform: `translate3d(
            ${Math.round(popper.left)}px,
            ${Math.round(popper.top)}px,
            0
          )`,
          position: popper.position,
          top: 0,
          left: 0,
          width: this.width,
        }
      : null;

    const body =
      typeof this.props.body === "function"
        ? this.props.body(this.props.onDismiss)
        : this.props.body;

    return createPortal(
      <Fragment>
        <div
          className={classnames(
            style.container,
            classNames.container,
            this.state.placement && classNames[this.state.placement],
            { [style.clickable]: this.props.clickable }
          )}
          style={containerOffset}
          ref={this.setContainer}
          data-qa={this.props.qaSelector}
        >
          {body ? (
            body
          ) : this.props.shortcut ? (
            <p className={classNames.label}>
              {this.props.label}
              <span className={style.shortcut}>{this.props.shortcut}</span>
            </p>
          ) : (
            <div className={classNames.label}>{this.props.label}</div>
          )}
          <span
            className={classNames.arrowContainer}
            style={this.state ? this.state.arrow : undefined}
            ref={this.setArrow}
          >
            <div className={classNames.arrow} />
          </span>
        </div>
        {(trigger === "click" || trigger === "context") && (
          <div
            className={style.underlay}
            onContextMenu={this.handleOutsideMouseClick}
            onClick={this.handleOutsideMouseClick}
          />
        )}
      </Fragment>,
      this.portal
    );
  }

  setContainer = (element: ?HTMLElement) => {
    this.container = element;
  };

  setArrow = (element: ?HTMLElement) => {
    if (typeof this.props.body !== "function") {
      this.arrow = element;
    }
  };
}
