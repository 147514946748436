// @flow
import idx from "idx";
import find from "lodash/find";
import * as React from "react";
import Button from "core/components/Button";
import ColorSelector from "core/components/ColorSelector";
import FormSection from "core/components/FormSection";
import Input from "core/components/Input";
import InputSwitch from "core/components/InputSwitch";
import Tab from "core/components/ProjectSettings/Tab";
import TransferProject from "core/components/ProjectSettings/Transfer";
import TransferNote from "core/components/ProjectSettings/Transfer/Note";
import Select from "core/components/Select";
import SelectSection from "core/components/SelectSection";
import SettingsForm from "core/components/SettingsForm";
import SettingsItem from "core/components/SettingsItem";
import * as Project from "core/models/project";
import type { UpdateParams as ProjectUpdateParams } from "core/requests/projects";
import type {
  Project as TProject,
  ProjectVisibility,
  Policy,
  Section,
  ValidationErrors,
  AssetAutoGenerationOption,
} from "core/types";
import Validations from "core/validations";
import connector from "./connector";
import style from "./style.scss";

export type Form = {
  name: string,
  visibility: ProjectVisibility,
  color: string,
  sectionId: ?string,
  sectionName: string,
  assetAutoGeneration: AssetAutoGenerationOption,
};

export type OwnProps = {|
  params: {
    projectId: string,
  },
|};

export type StateProps = {|
  key: string,
  project: ?TProject,
  policy: Policy,
  projectId: string,
  organizationId: string,
  isSubmitting: boolean,
  submitSucceeded: boolean,
  hasError: boolean,
  errors: ?ValidationErrors,
  sections: Array<Section>,
  sectionsLoading: boolean,
  canUseSections: boolean,
  canCreateSections: boolean,
  defaultBranchNameTitleCase: string,
  canMakePrivate: boolean,
  canGenerateAssets: boolean,
  isOffline: boolean,
|};

export type DispatchProps = {|
  onLoad: () => void,
  onSubmit: (
    params: ProjectUpdateParams,
    organizationId: string,
    sectionName: string
  ) => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type State = {
  hasSubmitted: boolean,
  form: Form,
  isDirty: boolean,
};

type SelectAssetEvent = EventTarget & {
  value: AssetAutoGenerationOption,
};

class ProjectSettingsDetails extends React.Component<Props, State> {
  state = {
    hasSubmitted: false,
    form: {
      name: idx(this.props, (_) => _.project.name) || "",
      visibility:
        idx(this.props, (_) => _.project.visibility) ||
        Project.VISIBILITY.ORGANIZATION,
      color: idx(this.props, (_) => _.project.color) || "",
      sectionId: idx(this.props, (_) => _.project.sectionId) || "",
      sectionName: "",
      assetAutoGeneration:
        idx(this.props, (_) => _.project.assetAutoGeneration) || "all",
    },
    isDirty: false,
  };

  setForm = (form: $Shape<Form>) => {
    this.setState({
      form: {
        ...this.state.form,
        ...form,
      },
      isDirty: true,
    });
  };

  onChangeName = (event: SyntheticInputEvent<>) =>
    this.setForm({
      name: event.target.value,
    });

  onChangeSectionId = (sectionId: ?string) => this.setForm({ sectionId });

  onChangeSectionName = (event: SyntheticInputEvent<>) =>
    this.setForm({
      sectionName: event.target.value,
    });

  onChangeColor = (color: string) => this.setForm({ color });

  onChangeAssetGeneration = (event: SyntheticInputEvent<SelectAssetEvent>) =>
    this.setForm({
      assetAutoGeneration: event.currentTarget.value,
    });

  onChangeVisibility = (event: SyntheticInputEvent<>) =>
    this.setForm({
      visibility: event.target.checked
        ? Project.VISIBILITY.SPECIFIC
        : Project.VISIBILITY.ORGANIZATION,
    });

  onSubmit = (event: SyntheticEvent<>) => {
    event.preventDefault();
    this.setState({ hasSubmitted: true });

    const { form } = this.state;
    const organizationId =
      idx(this.props, (_) => _.project.organizationId) || "";
    let { sectionId, sectionName, assetAutoGeneration } = form;

    if (sectionId === "new") {
      const section = find(this.props.sections, { name: sectionName });

      if (section) {
        sectionId = section.id;
        sectionName = "";
      }
    }

    const project = {
      id: this.props.projectId,
      name: form.name,
      visibility: form.visibility,
      color: form.color,
      sectionId,
      assetAutoGeneration,
    };

    this.props.onSubmit({ project }, organizationId, sectionName);
    this.setState({ isDirty: false });
  };

  render() {
    const { form } = this.state;
    const {
      project,
      policy,
      sections,
      sectionsLoading,
      errors,
      submitSucceeded,
      hasError,
      isOffline,
      isSubmitting,
      canMakePrivate,
      canGenerateAssets,
      canUseSections,
      canCreateSections,
      defaultBranchNameTitleCase,
    } = this.props;

    const ASSET_GENERATION_OPTIONS = {
      all: "On All Branches (Default)",
      master: `Only On The ${defaultBranchNameTitleCase} Branch`,
      off: "Off",
    };

    return (
      <Tab
        loading={false}
        success={submitSucceeded}
        successMessage="Project updated"
        error={hasError}
        errorMessage="Could not update project"
      >
        <SettingsForm className={style.form}>
          <FormSection heading="Details">
            <SettingsItem input>
              <Input
                requiredTag
                responsive
                error={errors && errors.name}
                label="Name"
                name="name"
                onChange={this.onChangeName}
                disabled={!policy.update || isOffline}
                defaultValue={form.name}
                minLength={Validations.minProjectNameLength}
                maxLength={Validations.maxProjectNameLength}
              />
            </SettingsItem>
            {(canCreateSections || (canUseSections && sections.length > 0)) && (
              <SettingsItem input>
                <SelectSection
                  responsive
                  loading={sectionsLoading}
                  label="Section"
                  value={form.sectionId}
                  nameValue={form.sectionName}
                  onChange={this.onChangeSectionId}
                  onInputChange={this.onChangeSectionName}
                  sections={sections}
                  project={project}
                  canCreateSections={canCreateSections}
                  disabled={!policy.update || isOffline}
                />
              </SettingsItem>
            )}
            <SettingsItem
              label="Color"
              responsive
              className={style.colorSelectorItem}
            >
              <div className={style.colorSelectorWrap}>
                <ColorSelector
                  className={style.colorPicker}
                  error={errors && errors.color}
                  name="color"
                  label=""
                  disabled={!policy.update || isOffline}
                  onChange={this.onChangeColor}
                  defaultValue={form.color}
                />
              </div>
            </SettingsItem>
            {canGenerateAssets && (
              <SettingsItem label="Automatically generate assets" responsive>
                <Select
                  name="assetAutoGeneration"
                  defaultValue={form.assetAutoGeneration}
                  onChange={this.onChangeAssetGeneration}
                  wrapperClass={style.assetsWrapper}
                  disabled={!policy.update || isOffline}
                >
                  {Object.keys(ASSET_GENERATION_OPTIONS).map((type) => (
                    <option value={type} key={type}>
                      {ASSET_GENERATION_OPTIONS[type]}
                    </option>
                  ))}
                </Select>
              </SettingsItem>
            )}
            {canMakePrivate && (
              <SettingsItem label="Privacy" responsive>
                <span className={style.inputHelper}>
                  Private projects will only be visible to people you invite.
                </span>
                <InputSwitch
                  wrapperClass={style.inputSwitch}
                  checked={Project.isPrivate(form.visibility)}
                  name="isPrivate"
                  disabled={!policy.update || isOffline}
                  onChange={this.onChangeVisibility}
                />
              </SettingsItem>
            )}
            <SettingsItem className={style.submit}>
              <Button
                disabled={
                  !this.state.isDirty ||
                  isSubmitting ||
                  !policy.update ||
                  isOffline
                }
                onClick={this.onSubmit}
                primary
                type="submit"
              >
                {isSubmitting ? "Saving changes…" : "Save changes"}
              </Button>
            </SettingsItem>
          </FormSection>

          {project && (
            <React.Fragment>
              <FormSection heading="Transfer Project">
                <SettingsItem label="Transfer this project to another organization">
                  <TransferProject
                    disabled={isOffline}
                    project={project}
                    policy={policy}
                  />
                </SettingsItem>
              </FormSection>
              <TransferNote className={style.note} />
            </React.Fragment>
          )}
        </SettingsForm>
      </Tab>
    );
  }
}

export default connector(ProjectSettingsDetails);
