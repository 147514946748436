// @flow
/* global HTMLElement */

// TODO needs stories
import classnames from "classnames";
import { reduce } from "lodash";
import * as React from "react";
import { findDOMNode } from "react-dom";
import { connect } from "react-redux";
import { Waypoint } from "react-waypoint";
import scrollIntoView from "smooth-scroll-into-view-if-needed";
import { isOnline } from "abstract-di/selectors";
import CommentNotificationReader from "core/components/CommentNotificationReader";
import CommentThread from "core/components/CommentThread";
import Error from "core/components/Empty/Error";
import NoComments from "core/components/Empty/NoComments";
import Offline from "core/components/Empty/Offline";
import Loaded from "core/components/Loaded";
import Scrollable from "core/components/Scrollable";
import type { Comment, State } from "core/types";
import style from "./style.scss";

export type Props = {
  comments: Comment[],
  isLoading: boolean,
  isOffline: boolean,
  hasError: boolean,
  branchId?: string,
  fileId?: string,
  pageId?: string,
  layerId?: string,
  sha?: string,
  className?: string,
  itemClass?: string,
  currentUserId?: string,
  scrollToCommentId?: string,
  highlightedCommentId?: string,
  selectedCommentId?: ?string,
  onCommentSelected?: (string) => void,
  layerName?: string,
  fileName?: string,
  branchName?: string,
  pageName?: string,
  commitTitle?: string,
  shadow: boolean,
  disabled: boolean,
  autoMarkAsRead: boolean,
  autoScroll: boolean,
  commentComponent?: *,
  scrollable?: boolean,
  isPubliclyShared?: boolean,
  pinnedComments?: Comment[],
  pinCommentsEnabled?: boolean,
  resolveCommentsEnabled?: boolean,
  highlightPinnedComment?: boolean,
  enableHidingResolvedComments?: boolean,
  isShowingResolvedComments?: boolean,
  tintBackgroundOnHover?: boolean,
};

type ComponentState = { shadow: boolean };

class CommentsList extends React.PureComponent<Props, ComponentState> {
  static defaultProps = {
    shadow: false,
    disabled: false,
    isLoading: false,
    hasError: false,
    autoScroll: true,
    autoMarkAsRead: true,
  };

  scrollable: ?Scrollable;
  highlighted: *;

  state = { shadow: this.props.shadow };

  componentDidMount() {
    this.handleScroll();
  }

  componentDidUpdate(prevProps: Props) {
    const prevComments = prevProps.comments;
    const comments = this.props.comments;
    const loaded = prevProps.isLoading && !this.props.isLoading;
    const layerChanged =
      prevProps.layerId !== this.props.layerId ||
      prevProps.sha !== this.props.sha;

    if (loaded || layerChanged) {
      return this.handleScroll();
    }

    if (!comments.length && this.state.shadow) {
      return this.setState({ shadow: false });
    }

    if (prevComments.length < comments.length) {
      const latest = comments[comments.length - 1];

      if (latest.pending && !latest.error) {
        return this.scrollCommentsToBottom();
      }
    }
  }

  handleCommentSelected = (...args: *) => {
    if (this.props.onCommentSelected) {
      return this.props.onCommentSelected(...args);
    }
  };

  handleScroll = () => {
    if (!this.props.highlightedCommentId) {
      this.scrollCommentsToBottom();
    } else if (this.props.scrollToCommentId) {
      this.scrollToComment(this.highlighted);
    }
  };

  scrollCommentsToBottom = () => {
    if (this.props.disabled || !this.props.autoScroll) {
      return;
    }

    const element = findDOMNode(this.scrollable);

    if (element instanceof HTMLElement) {
      element.scrollTop = element.scrollHeight;
    }
  };

  scrollToComment = (commentRef: *) => {
    if (commentRef && !this.props.disabled) {
      scrollIntoView(commentRef, {
        duration: 500,
        block: "center",
        scrollMode: "if-needed",
      });
    }
  };

  scrollToHighlightedComment = () => {
    this.scrollToComment(this.highlighted);
  };

  setScrollableRef = (ref: ?Scrollable) => (this.scrollable = ref);
  setHighlightedRef = (ref: *) => (this.highlighted = ref);

  handleScrollUp = () => this.setState({ shadow: true });
  handleScrolledToBottom = () => this.setState({ shadow: false });

  get commentContext(): Object {
    return {
      branchId: this.props.branchId,
      sha: this.props.sha,
      fileId: this.props.fileId,
      pageId: this.props.pageId,
      layerId: this.props.layerId,
    };
  }

  renderThreads() {
    const { comments, onCommentSelected } = this.props;
    const threads = {};
    let qaIndex = 0;

    return reduce(
      comments,
      (memo, comment, index: number) => {
        if (!comment.parentId) {
          threads[comment.id] = { parent: comment, replies: [] };
          qaIndex++;
        } else {
          threads[comment.parentId].replies.push(comment);
        }

        const next = comments[index + 1];
        const parentId = comment.parentId || comment.id;

        if (!next || !next.parentId) {
          memo.push(
            <CommentThread
              {...this.props}
              key={`${parentId}-thread`}
              branchId={comment.branchId}
              fileId={comment.fileId}
              layerId={comment.layerId}
              pageId={comment.pageId}
              isHighlighted={parentId === this.props.highlightedCommentId}
              commitSha={this.props.sha || ""}
              parentId={parentId}
              projectId={comment.projectId}
              parent={threads[parentId].parent}
              replies={threads[parentId].replies}
              context={this.commentContext}
              selectedRef={this.scrollToComment}
              highlightedRef={this.setHighlightedRef}
              replyFormClass={style.replyForm}
              onCommentSelected={
                onCommentSelected ? this.handleCommentSelected : undefined
              }
              isPubliclyShared={this.props.isPubliclyShared}
              scrollToCommentId={this.props.scrollToCommentId}
              highlightedCommentId={this.props.highlightedCommentId}
              selectedCommentId={this.props.selectedCommentId}
              pinCommentsEnabled={this.props.pinCommentsEnabled}
              resolveCommentsEnabled={this.props.resolveCommentsEnabled}
              highlightPinnedComment={this.props.highlightPinnedComment}
              enableHidingResolvedComments={
                this.props.enableHidingResolvedComments
              }
              pinnedComments={this.props.pinnedComments}
              isShowingResolvedComments={this.props.isShowingResolvedComments}
              tintBackgroundOnHover={this.props.tintBackgroundOnHover}
              qaSelector={`comment-tread-${qaIndex - 1}`}
            />
          );
        }

        return memo;
      },
      []
    );
  }

  renderEmpty() {
    const { hasError, isOffline } = this.props;

    if (hasError && isOffline) {
      return (
        <Offline flex description="Connect to the internet to view comments" />
      );
    }

    if (hasError) {
      return <Error flex />;
    }

    return <NoComments flex />;
  }

  renderListWrapper = (children: React.Node) => {
    if (this.props.scrollable) {
      return (
        <Scrollable className={style.scrollable} ref={this.setScrollableRef}>
          {children}
        </Scrollable>
      );
    }

    return <div className={style.scrollable}>{children}</div>;
  };

  render() {
    const isEmpty = !this.props.comments.length;

    return (
      <div
        className={classnames(style.container, this.props.className, {
          [style.canHaveShadow]: this.props.shadow,
          [style.shadow]: this.props.shadow && this.state.shadow,
        })}
      >
        {isEmpty && !this.props.isLoading
          ? this.renderEmpty()
          : this.renderListWrapper(
              <React.Fragment>
                <Loaded loading={this.props.isLoading} flex>
                  {this.props.autoMarkAsRead && (
                    <CommentNotificationReader
                      comments={this.props.comments}
                      id={`${this.props.layerId || ""}-${this.props.sha || ""}`}
                      disabled={this.props.isOffline || this.props.disabled}
                    />
                  )}
                  {this.renderThreads()}
                </Loaded>
                {this.props.shadow && (
                  <Waypoint
                    onLeave={this.handleScrollUp}
                    onEnter={this.handleScrolledToBottom}
                  />
                )}
              </React.Fragment>
            )}
      </div>
    );
  }
}

function mapStateToProps(state: State, props: { innerRef?: (*) => * }) {
  return { isOffline: !isOnline(state), ref: props.innerRef };
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default connect(mapStateToProps)(CommentsList);
