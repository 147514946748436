// @flow
import { connect } from "react-redux";
import { Abstract } from "core/lib/abstract";
import { getBranch } from "core/selectors/branches";
import type { State } from "core/types";
import BranchOverview from "web/components/BranchOverview";
import { getProject } from "web/selectors/projects";

type OwnProps = {
  params: Abstract.BranchDescriptor,
};

function mapStateToProps(state: State, props: OwnProps) {
  const { projectId } = props.params;
  const branch = getBranch(state, props.params);
  const project = getProject(state, projectId);

  return {
    branch,
    project,
    isLoading: !branch || !project,
  };
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default connect(mapStateToProps)(BranchOverview);
