// @flow
import * as React from "react";
import AsyncUserFilter from "core/components/AsyncUserFilter";
import InputSearch from "core/components/InputSearch";
import PageTitle from "core/components/PageTitle";
import PageTitleNavigationLink from "core/components/PageTitleNavigationLink";
import { addQuery, addAndRemoveQueryParams } from "core/lib/location";
import type { User } from "core/types";

export type Props = {
  projectId: string,
  actions?: React.Node,
  mobile?: boolean,
  selectedAuthor: ?User,
  filter: string,
  currentUserId: string,
  onSelectAuthor: (userId: ?string) => void,
  onSearchChange: (ev: SyntheticInputEvent<>) => void,
};

export default function ProjectBranchesHeader(props: Props) {
  const authorFilter = (
    <AsyncUserFilter
      anchor="left"
      buttonText={
        props.selectedAuthor ? props.selectedAuthor.name : "All Authors"
      }
      placeholderText="Filter people…"
      selectedItem={
        props.selectedAuthor && {
          id: props.selectedAuthor.id,
          name: props.selectedAuthor.name,
          username: `@${props.selectedAuthor.username}`,
        }
      }
      onSelectItem={props.onSelectAuthor}
      mobile={props.mobile}
      projectId={props.projectId}
    />
  );

  return (
    <PageTitle
      actions={props.actions}
      title="Branches"
      navigation={[
        <PageTitleNavigationLink
          to={addQuery({ filter: "active" })}
          icon="branch"
          active={props.filter === "active"}
        >
          Active
        </PageTitleNavigationLink>,
        <PageTitleNavigationLink
          to={addAndRemoveQueryParams({ filter: "mine" }, ["userId"])}
          active={props.filter === "mine"}
          avatarUserId={props.currentUserId}
        >
          Mine
        </PageTitleNavigationLink>,
        <PageTitleNavigationLink
          to={addQuery({ filter: "archived" })}
          active={props.filter === "archived"}
          icon="archive"
        >
          Archived
        </PageTitleNavigationLink>,
      ]}
      filter={props.mobile ? authorFilter : undefined}
      sort={!props.mobile ? authorFilter : undefined}
      search={
        <InputSearch
          debounce={200}
          onChange={props.onSearchChange}
          placeholder="Search…"
          large={props.mobile}
        />
      }
    />
  );
}
