// @flow
import invariant from "invariant";
import { connect } from "react-redux";
import { getProject } from "core/selectors/projects";
import type { State, Dispatch } from "core/types";
import type { OwnProps, StateProps, Props } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { projectId } = props.params;

  const project = getProject(state, { projectId });

  invariant(
    project,
    "Project must exist in state when passed to ProjectOverview"
  );

  return {
    project,
  };
}

export default connect<Props, OwnProps, StateProps, _, State, Dispatch>(
  mapStateToProps
);
