// @flow
import classnames from "classnames";
import * as React from "react";
import Avatar from "core/components/Avatar";
import Heading from "core/components/Heading";
import Icon from "core/components/Icon";
import OrganizationLogo from "core/components/OrganizationLogo";
import type { Invitation, User } from "core/types";
import style from "./style.scss";

type Props = {
  invitation: Invitation,
  currentUser?: ?User,
};

type MessageProps = {
  invitation: Invitation,
  user?: ?User,
};

function Message({ invitation, user }: MessageProps) {
  const {
    createdByUserName,
    isUsernameOrganization,
    organizationName,
    projectName,
    role,
  } = invitation;

  const inviterTag = <strong>{createdByUserName}</strong>;
  const projectTag = <strong>{projectName}</strong>;

  if (!user) {
    return (
      <React.Fragment>
        <Heading level="3" size="m">
          {projectName ? `${projectName} • ` : ""} {organizationName}
        </Heading>
        <div>
          {createdByUserName ? (
            <React.Fragment>
              {inviterTag} invited you as a {role}.
            </React.Fragment>
          ) : (
            `You were invited as a ${role}.`
          )}
        </div>
      </React.Fragment>
    );
  }

  if (isUsernameOrganization) {
    return createdByUserName ? (
      <React.Fragment>
        {inviterTag} has invited you to their project {projectTag}
      </React.Fragment>
    ) : (
      <React.Fragment>
        You have been invited to project {projectTag}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <p>
        {createdByUserName ? (
          <React.Fragment>
            {inviterTag} has invited you as a <em>{role}</em> to join
          </React.Fragment>
        ) : (
          <React.Fragment>
            You have been invited as a <em>{role}</em> to join
          </React.Fragment>
        )}{" "}
        {projectName ? (
          <React.Fragment>the {projectTag} project for</React.Fragment>
        ) : (
          ""
        )}{" "}
        <strong>{organizationName}</strong>.
      </p>
    </React.Fragment>
  );
}

export default function InvitationPanel({ invitation, currentUser }: Props) {
  return (
    <div
      className={classnames(style.wrapper, {
        [style.loggedOut]: !currentUser,
      })}
    >
      <div className={style.invitationLogoContainer}>
        {currentUser && (
          <React.Fragment>
            <Avatar userId={currentUser.id} size="64px" />
            <Icon type="zoom-in" className={style.plusIcon} />
          </React.Fragment>
        )}
        <OrganizationLogo
          src={invitation.organizationLogoUrl}
          alt={invitation.organizationName}
          size={64}
        />
      </div>
      <div className={style.invitationTextContainer}>
        <Message invitation={invitation} user={currentUser} />
      </div>
    </div>
  );
}
