// @flow
import * as React from "react";
import { organizationBillingUrl } from "../../lib/urls";
import style from "./style.scss";

export default function SubscriptionBannerActive(props: {
  organizationId: string,
}) {
  return (
    <div className={style.banner}>
      <span>
        Are you enjoying your Branches trial? &nbsp;
        <a
          href={organizationBillingUrl(props.organizationId)}
          className={style.link}
        >
          Upgrade to our monthly or annual plan now.
        </a>
      </span>
    </div>
  );
}
