// @flow
import * as React from "react";
import Button from "core/components/Button";
import OrganizationLogo from "core/components/OrganizationLogo";
import ValidationError from "core/components/ValidationError";
import { processAvatar } from "../../avatar";
import style from "./style.scss";

type Props = {
  error?: Array<string>,
  size: number,
  url?: string,
  organizationName: string,
};

export default class OrganizationLogoPicker extends React.Component<
  Props,
  { dataUrl: string, error: string },
> {
  static defaultProps = { size: 48 };

  constructor() {
    super();
    this.state = { dataUrl: "", error: "" };
  }

  input: ?HTMLInputElement;

  handleChange = async () => {
    if (!this.value) {
      this.setState({ dataUrl: "", error: "" });
      return;
    }

    const [dataUrl, error] = await processAvatar(this.value);
    this.setState({ dataUrl, error });
  };

  handleFileSelect = () => {
    this.input && this.input.click();
  };

  reset = () => {
    if (!this.input) {
      return;
    }

    // Most browser let you reset the value of a file input...
    this.input.value = "";

    // ... but if not, we can try to trick it.
    if (this.input.value) {
      this.input.type = "text";
      this.input.type = "file";
    }

    // And make sure to trigger a change.
    this.handleChange();
  };

  get value(): void | File {
    if (!this.input) {
      return undefined;
    }
    return this.input.files ? this.input.files[0] : undefined;
  }

  render() {
    const error = this.state.error || this.props.error;

    return (
      <div>
        <div className={style.wrapper}>
          <div className={style.preview}>
            <OrganizationLogo
              size={this.props.size}
              alt={this.props.organizationName}
              src={this.value ? this.state.dataUrl : this.props.url}
            />
          </div>
          <input
            onChange={this.handleChange}
            ref={(ref) => {
              this.input = ref;
            }}
            style={{ display: "none" }}
            type="file"
          />
          <div>
            <div>
              <Button
                className={style.action}
                onClick={this.handleFileSelect}
                type="button"
              >
                Choose file…
              </Button>
              {this.value && (
                <Button
                  className={style.action}
                  onClick={this.reset}
                  type="button"
                >
                  Remove logo
                </Button>
              )}
            </div>
            <div className={style.help}>Square JPG or PNG, at least 256px.</div>
          </div>
        </div>
        {error && <ValidationError error={error} />}
      </div>
    );
  }
}
