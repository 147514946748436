// @flow
import classnames from "classnames";
import * as React from "react";
import Flex from "core/components/Flex";
import Icon from "core/components/Icon";
import style from "./style.scss";

export type AvailableIcons =
  | "checkmark"
  | "download"
  | "mail"
  | "organization"
  | "search-default";

type Props = {|
  className?: string,
  icon: AvailableIcons,
|};

export default function OnboardingIcon({ className, icon }: Props) {
  return (
    <Flex align="center" justify="center" className={className}>
      <Icon
        type={icon}
        large
        fill="white"
        className={classnames(style.icon, {
          [style[icon]]: icon,
        })}
      />
    </Flex>
  );
}
