// @flow
import * as React from "react";
import { connect } from "react-redux";
import {
  copyToClipboard,
  generateAndUploadPreviewsForCommit,
} from "abstract-di/actions";
import { isDevelopmentMenuEnabled, isOnline } from "abstract-di/selectors";
import { showDialog } from "core/actions/dialogs";
import ContextMenu, { type Children } from "core/components/ContextMenu";
import { push } from "core/lib/location";
import { isDesktop } from "core/lib/platform";
import { commitPath } from "core/lib/routes";
import { getProjectPolicy } from "core/selectors/policies";
import type { State, Dispatch, Policy, ThunkAction } from "core/types";

type OwnProps = {|
  children: Children,
  goToCommitVisible?: boolean,
  organizationId: string,
  projectId: string,
  branchId: string,
  sha: string,
|};

type StateProps = {|
  development: boolean,
  policy: Policy,
  isOnline: boolean,
|};

type DispatchProps = {|
  copyCommitSha?: () => void,
  generatePreviews?: () => ThunkAction,
  createBranch?: () => ThunkAction,
|};

type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

class CommitMenu extends React.Component<Props> {
  menu: ?ContextMenu;

  goToCommit = () => {
    push(commitPath(this.props.projectId, this.props.branchId, this.props.sha));
  };

  render() {
    const {
      children,
      organizationId,
      projectId,
      branchId,
      sha,
      policy,
      isOnline,
      goToCommitVisible,
      createBranch,
      development,
      copyCommitSha,
      generatePreviews,
    } = this.props;

    const menuItems = [
      {
        type: "share",
        label: "Copy Link to Commit",
        props: {
          kind: "commit",
          organizationId,
          projectId,
          branchId,
          commitSha: sha,
        },
        visible: policy.share === true,
        enabled: isOnline,
      },
      {
        label: "Go to Commit",
        click: this.goToCommit,
        visible: goToCommitVisible && policy.show === true,
      },
      {
        label: "Create Branch…",
        click: createBranch,
        visible: !!createBranch && isDesktop,
      },
      { type: "separator", visible: development },
      {
        label: "Development",
        visible: development,
        submenu: [
          {
            label: "Copy Commit SHA",
            click: copyCommitSha,
            visible: !!copyCommitSha,
          },
          {
            label: "Regenerate previews",
            click: generatePreviews,
            visible: !!generatePreviews,
          },
        ],
      },
    ];

    return (
      <ContextMenu
        ref={(me) => (this.menu = me)}
        id={sha}
        children={children}
        menuItems={menuItems}
      />
    );
  }
}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  return {
    policy: getProjectPolicy(state, { projectId: props.projectId }),
    development: isDevelopmentMenuEnabled(state) && !!copyToClipboard,
    isOnline: isOnline(state),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    createBranch: showDialog
      ? () =>
          dispatch(
            showDialog("CreateBranch", {
              projectId: props.projectId,
              parentBranchId: props.branchId,
              sha: props.sha,
            })
          )
      : undefined,
    generatePreviews: generateAndUploadPreviewsForCommit
      ? () =>
          dispatch(
            generateAndUploadPreviewsForCommit(props.projectId, props.sha, true)
          )
      : undefined,
    copyCommitSha: copyToClipboard
      ? () => copyToClipboard(props.sha)
      : undefined,
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(
  mapStateToProps,
  mapDispatchToProps
)(CommitMenu);
