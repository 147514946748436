// @flow
import * as React from "react";
import Loaded from "core/components/Loaded";
import LoginForm from "core/components/LoginForm";
import OnboardingContentSheet from "core/components/OnboardingContentSheet";
import { replace } from "core/lib/location";
import { rootPath } from "core/lib/routes";
import type { ReactRouterLocation, LocationDescriptor } from "core/types";
import OnboardingPage from "web/components/Onboarding/Page";
import { invitation } from "web/routeHelpers";
import type { Invitation } from "web/types";
import style from "./style.scss";

export type OwnProps = {|
  location: ReactRouterLocation,
|};

export type StateProps = {|
  username: ?string,
  isLoading: boolean,
  isSubmitting: boolean,
  isLoggedIn: boolean,
  returnTo?: LocationDescriptor,
  invitation: Invitation,
  error: ?Error,
|};

export type DispatchProps = {|
  onSignIn: (username: string, password: string) => void,
  onPasswordReset: () => void,
  onCreateAccount: () => void,
  resetError: () => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

export default class SignIn extends React.Component<Props> {
  componentDidMount() {
    this.checkAuthentication();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.isLoading) {
      this.checkAuthentication();
    }
  }

  checkAuthentication() {
    if (!this.props.isLoading && this.props.isLoggedIn) {
      if (this.props.invitation.token) {
        replace(invitation(this.props.invitation.token));
      } else {
        replace(rootPath());
      }
    }
  }

  render() {
    return (
      <Loaded loading={this.props.isLoading}>
        <OnboardingPage>
          <OnboardingContentSheet className={style.content}>
            <LoginForm
              invitation={this.props.invitation}
              username={this.props.username || undefined}
              onSignIn={this.props.onSignIn}
              isLoadingSignIn={this.props.isSubmitting}
              returnTo={this.props.returnTo}
              error={this.props.error}
              resetError={this.props.resetError}
              onCreateAccount={this.props.onCreateAccount}
              onPasswordReset={this.props.onPasswordReset}
            />
          </OnboardingContentSheet>
        </OnboardingPage>
      </Loaded>
    );
  }
}
