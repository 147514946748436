// @flow
import empty from "empty";
import { connect } from "react-redux";
import history from "abstract-di/history";
import { isOnline, getCurrentUser } from "abstract-di/selectors";
import { trackEvent } from "core/actions/analytics";
import { loadCurrentUserTeams } from "core/actions/teams";
import { withData } from "core/components/DataLoader";
import connectStorage from "core/hocs/connectStorage";
import createConnector from "core/lib/createConnector";
import { DEFAULT_ORGANIZATIONTEAMS_VIEW } from "core/lib/lists";
import { push } from "core/lib/location";
import { organizationTeamPath } from "core/lib/routes";
import {
  CURRENTUSERTEAMS_LIMIT,
  organizationTeamsOptions,
} from "core/lib/teams";

import {
  TeamCreateRequest,
  PaginatedOrganizationTeamsRequest,
} from "core/requests/teams";
import { getPaginationTotal } from "core/selectors/paginationTotals";
import { getOrganizationPolicy } from "core/selectors/policies";
import { getTeamsForOrganization } from "core/selectors/teams";
import type { State, Dispatch, ViewType } from "core/types";
import type {
  OwnProps,
  StateProps,
  DispatchProps,
  StorageProps,
  Props,
  PropsWithoutStorage,
} from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const policy = getOrganizationPolicy(state, {
    organizationId: props.params.organizationId,
  });
  const canCreateTeam = policy.createTeam;
  const canUseTeams = policy.showTeams;
  const canListMembers = policy.listMembers;
  const location = history.getCurrentLocation();
  const teamsOptions = organizationTeamsOptions(location);
  const searchFilter = teamsOptions.search;
  const currentUser = getCurrentUser(state);
  const currentUserTeams = currentUser
    ? getTeamsForOrganization(state, {
        organizationId: props.params.organizationId,
        userId: currentUser.id,
        search: searchFilter,
      })
    : empty.array;

  const currentUserTeamsPaginationTotal = getPaginationTotal(
    state,
    `${props.params.organizationId}-YourTeams`
  );

  const expandCurrentUserTeamsIsLoading =
    currentUser && currentUserTeamsPaginationTotal
      ? PaginatedOrganizationTeamsRequest.isLoadingStrict(state, {
          organizationId: props.params.organizationId,
          userId: currentUser.id,
          offset: CURRENTUSERTEAMS_LIMIT,
          limit: currentUserTeamsPaginationTotal - CURRENTUSERTEAMS_LIMIT,
        })
      : false;

  return {
    canListMembers,
    canCreateTeam,
    canUseTeams,
    currentUser,
    currentUserTeams,
    currentUserTeamsPaginationTotal,
    expandCurrentUserTeamsIsLoading,
    isOnline: isOnline(state),
    createDialogIsLoading: TeamCreateRequest.isLoadingStrict(
      state,
      props.params
    ),
    searchFilter,
    createDialogValidationErrors: TeamCreateRequest.validationErrors(
      state,
      props.params
    ),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const onLoad = () => {
    dispatch(loadCurrentUserTeams(props.params.organizationId));
  };
  const loadRemainingCurrentUserTeams = (offset, limit) => {
    dispatch(
      loadCurrentUserTeams(props.params.organizationId, { offset, limit })
    );
  };
  const onCreateTeam = ({ name, color }) => {
    return dispatch(
      TeamCreateRequest.perform({
        params: { name, color, ...props.params },
        onSuccess: (response) => {
          const teamId = response.data.team.id;
          push(
            organizationTeamPath({
              organizationId: props.params.organizationId,
              teamId,
            })
          );
          dispatch(
            trackEvent("TEAM_CREATED", {
              organizationId: props.params.organizationId,
              teamId,
            })
          );
        },
      })
    );
  };
  return {
    onLoad,
    loadRemainingCurrentUserTeams,
    onCreateTeam,
  };
}

function mapStorageToProps(storage, props: OwnProps): StorageProps {
  const storageId = `OrganizationTeams-${props.params.organizationId}`;
  const previous = storage.getItem(storageId) || {};

  return {
    viewType: previous.peopleView || DEFAULT_ORGANIZATIONTEAMS_VIEW,
    onChangeViewType: (peopleView: ViewType) => {
      storage.setItem(storageId, { ...previous, peopleView });
    },
  };
}

export default createConnector<Props, OwnProps>(
  (Component) => connectStorage(Component, mapStorageToProps),
  connect<
    PropsWithoutStorage,
    OwnProps,
    StateProps,
    DispatchProps,
    State,
    Dispatch,
  >(mapStateToProps, mapDispatchToProps),
  withData((props) => props.params)
);
