// @flow
import * as React from "react";
import Time from "core/components/Time";
import { fromNow } from "core/lib/dates";
import style from "./style.scss";

export default function Timestamp({
  createdAt,
  editedAt,
}: {
  createdAt: string,
  editedAt?: string,
}) {
  return (
    <span>
      <Time date={createdAt}>{fromNow(createdAt)}</Time>
      {!!editedAt && (
        <Time date={editedAt}>
          <span className={style.dot}>{"·"}</span>Edited
        </Time>
      )}
    </span>
  );
}
