// @flow
import {
  entitiesReceived,
  entitiesReplaced,
  entitiesDeleted,
} from "core/actions/entities";
import { apiRequest, handleError } from "core/api";
import { replace } from "core/lib/location";
import defineRequest from "core/requests";
import { accountOauthApplications } from "web/routeHelpers";
import { getToken } from "web/token";
import type { InputOAuthApplication } from "web/types";
import {
  normalizeOAuthApplicationResponse,
  normalizeOAuthApplicationsResponse,
} from "../schemas/oAuthApplication";

export const OAuthApplicationCreateRequest = defineRequest<
  InputOAuthApplication,
  {},
>({
  id() {
    return "oauth-application-create";
  },
  async perform(
    { organizationId, name, description, redirectURI },
    dispatch,
    getState
  ) {
    const response = await apiRequest(
      "post",
      `organizations/${organizationId}/applications`,
      getToken(),
      {
        body: {
          name,
          description,
          redirectURIs: [redirectURI],
        },
      }
    );
    if (response.status === 201) {
      return response.json();
    }
    throw await handleError(response);
  },
  onSuccess(response, params, dispatch) {
    const { entities } = normalizeOAuthApplicationResponse(response);
    dispatch(entitiesReceived(entities));
  },
});

export const OAuthApplicationsRequest = defineRequest<
  { organizationId: string },
  {},
>({
  id() {
    return "oauth-applications-get";
  },
  async perform({ organizationId }, dispatch, getState) {
    const response = await apiRequest(
      "get",
      `organizations/${organizationId}/applications`,
      getToken()
    );
    if (response.status === 200) {
      return response.json();
    }
    throw await handleError(response);
  },
  onSuccess(response, params, dispatch) {
    const { entities } = normalizeOAuthApplicationsResponse(response);
    dispatch(entitiesReceived(entities));
  },
  onError(error, params, dispatch) {
    replace(accountOauthApplications());
  },
});

export const OAuthApplicationRequest = defineRequest<
  { organizationId: string, id: string },
  { id: string },
>({
  id() {
    return "oauth-application-get";
  },
  async perform({ organizationId, id }, dispatch, getState) {
    const response = await apiRequest(
      "get",
      `organizations/${organizationId}/applications/${id}`,
      getToken()
    );
    if (response.status === 200) {
      return response.json();
    }
    throw await handleError(response);
  },
  onSuccess(response, params, dispatch) {
    const { entities } = normalizeOAuthApplicationResponse(response);
    dispatch(entitiesReceived(entities));
  },
  onError(error, params, dispatch) {
    replace(accountOauthApplications());
  },
});

export const OAuthApplicationDeleteRequest = defineRequest<
  { organizationId: string, id: string },
  { id: string },
>({
  id() {
    return "oauth-application-delete";
  },
  async perform({ organizationId, id }, dispatch, getState) {
    const response = await apiRequest(
      "delete",
      `organizations/${organizationId}/applications/${id}`,
      getToken()
    );
    if (response.status !== 204) {
      throw await handleError(response);
    }
  },
  onSuccess(response, { id }, dispatch) {
    dispatch(entitiesDeleted({ oAuthApplications: [id] }));
    replace(accountOauthApplications());
  },
});

export const OAuthApplicationUpdateRequest = defineRequest<
  InputOAuthApplication & { id: string },
  { id: string },
>({
  id() {
    return "oauth-application-update";
  },
  async perform(
    { organizationId, id, name, description, redirectURI },
    dispatch,
    getState
  ) {
    const response = await apiRequest(
      "put",
      `organizations/${organizationId}/applications/${id}`,
      getToken(),
      {
        body: {
          name,
          description,
          redirectURIs: [redirectURI],
        },
      }
    );
    if (response.status === 200) {
      return response.json();
    }
    throw await handleError(response);
  },
  onSuccess(response, { id }, dispatch) {
    const { entities } = normalizeOAuthApplicationResponse(response);
    dispatch(
      entitiesReplaced({
        type: "oAuthApplications",
        ids: [id],
        entities: entities.oAuthApplications,
      })
    );
  },
});
