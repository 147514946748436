// @flow
import * as React from "react";
import Button from "core/components/Button";
import Empty from "core/components/Empty";
import { helpUpdateBillingUrl, organizationBillingUrl } from "core/lib/urls";

type Props = {
  disabled: boolean,
  organizationId?: string,
  canUpgradeSubscription?: boolean,
  children: React.Node,
};

export default function LayerBuildDisabled(props: Props) {
  return props.disabled ? (
    <Empty
      title="Inspect is available on our Business plan"
      description={
        <React.Fragment>
          Inspect provides one place to access the information needed to
          implement any design!{" "}
          <a
            href={helpUpdateBillingUrl()}
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more…
          </a>
        </React.Fragment>
      }
    >
      {props.canUpgradeSubscription && props.organizationId && (
        <Button
          component="a"
          href={organizationBillingUrl(props.organizationId)}
          primary
          large
        >
          Upgrade to Business…
        </Button>
      )}
    </Empty>
  ) : (
    props.children
  );
}
