// @flow
import classnames from "classnames";
import * as React from "react";
import Icon from "core/components/Icon";
import { CommitTypes } from "core/gitConstants";
import style from "./style.scss";

type Props = {
  className?: string,
  current?: boolean,
  first?: boolean,
  firstOfGroup?: boolean,
  icon?: string,
  last?: boolean,
  lastOfGroup?: boolean,
  pending?: boolean,
  pushing?: boolean,
  selected?: boolean,
  type: string,
};

export default function CommitTrack({
  className,
  current,
  first,
  firstOfGroup,
  last,
  lastOfGroup,
  pending,
  pushing,
  selected,
  type,
  ...rest
}: Props) {
  let iconComponent;

  const icon = type === CommitTypes.BRANCH ? "branch" : rest.icon;
  const update = type === CommitTypes.UPDATE;
  const merge = type === CommitTypes.MERGE;
  const restore = type === CommitTypes.RESTORE;
  const upgrade = type === CommitTypes.FILE_UPGRADED;

  if (icon) {
    iconComponent = <Icon type={icon} light={!selected} tint={selected} />;
  } else if (pushing) {
    iconComponent = <Icon type="spinner" light={selected} />;
  } else if (update) {
    iconComponent = (
      <Icon type="commit-update" light={!selected} tint={selected} />
    );
  } else if (merge) {
    iconComponent = (
      <Icon type="commit-merge" light={!selected} tint={selected} />
    );
  } else if (restore) {
    iconComponent = (
      <Icon type="commit-restore" light={!selected} tint={selected} />
    );
  } else if (upgrade) {
    iconComponent = (
      <Icon type="file-version-update" light={!selected} tint={selected} />
    );
  } else if (current) {
    iconComponent = <Icon type="commit-latest" light={selected} />;
  } else {
    iconComponent = (
      <Icon type={`commit-${selected ? "default-light" : "default"}`} />
    );
  }

  const styles = classnames(className, style.track, {
    [style.trackCircle]: icon || merge || update || restore || upgrade,
    [style.trackRestore]: restore,
    [style.trackCurrent]: current,
    [style.trackFirst]: first,
    [style.trackFirstOfGroup]: firstOfGroup,
    [style.trackLast]: last,
    [style.trackLastOfGroup]: lastOfGroup,
    [style.trackPushing]: pushing,
    [style.trackSelected]: selected,
  });

  return (
    <i {...rest} className={styles}>
      {iconComponent}
    </i>
  );
}
