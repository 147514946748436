// @flow
import { map } from "lodash";
import * as React from "react";
import { FileItem, Placeholder } from "core/components/FileItem";

import KeyboardNavigation from "core/components/KeyboardNavigation";
import SidebarHeading from "core/components/SidebarHeading";
import { branchPath } from "core/lib/routes";
import type { File, Branch } from "core/types";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  projectId: string,
  branch: Branch,
|};

export type StateProps = {|
  changedFiles: File[],
  unchangedFiles: File[],
  isLoading: boolean,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
};

type State = {
  selectedFileId?: string,
};

class FileSummary extends React.Component<Props, State> {
  state = {};

  handleClickFile = (fileId: string) => {
    if (this.state.selectedFileId === fileId) {
      return;
    }
    this.setState({ selectedFileId: fileId });
  };

  render() {
    const { changedFiles, unchangedFiles, isLoading, projectId, branch } =
      this.props;
    const isEmpty = changedFiles.length === 0 && !isLoading;
    const hasUnchangedFiles = unchangedFiles.length > 0 && !isLoading;

    return (
      <div className={style.sidebar}>
        {/* Changed Files */}
        <SidebarHeading
          dynamicPadding
          level="3"
          size="l"
          to={branchPath(projectId, branch.id, "files")}
        >
          Changed Files
        </SidebarHeading>
        {isEmpty ? (
          <div className={style.noChangedFiles}>
            No files have been changed on this branch yet.
          </div>
        ) : isLoading ? (
          <React.Fragment>
            <Placeholder className={style.file} />
            <Placeholder className={style.file} />
            <Placeholder className={style.file} />
          </React.Fragment>
        ) : (
          <KeyboardNavigation>
            {({ isFocused, selectedClassName }) => (
              <div>
                {map(changedFiles, (file) => (
                  <FileItem
                    branchId={branch.id}
                    key={file.id}
                    file={file}
                    small={false}
                    mobile={false}
                    isFocused={isFocused}
                    isSelected={this.state.selectedFileId === file.id}
                    selectedClassName={selectedClassName}
                    itemClassName={style.file}
                    onClick={() => {
                      this.handleClickFile(file.id);
                    }}
                  />
                ))}
              </div>
            )}
          </KeyboardNavigation>
        )}

        {/* Unchanged Files (if any) */}
        {hasUnchangedFiles && (
          <React.Fragment>
            <SidebarHeading
              dynamicPadding
              level="3"
              size="l"
              to={branchPath(projectId, branch.id, "files")}
            >
              Unchanged Files
            </SidebarHeading>
            <KeyboardNavigation>
              {({ isFocused, selectedClassName }) => (
                <div>
                  {map(unchangedFiles, (file) => (
                    <FileItem
                      branchId={branch.id}
                      key={file.id}
                      file={file}
                      small={false}
                      mobile={false}
                      isFocused={isFocused}
                      isSelected={this.state.selectedFileId === file.id}
                      selectedClassName={selectedClassName}
                      itemClassName={style.file}
                      onClick={() => {
                        this.handleClickFile(file.id);
                      }}
                    />
                  ))}
                </div>
              )}
            </KeyboardNavigation>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default connector(FileSummary);
