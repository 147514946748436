// @flow
import invariant from "invariant";
import { connect } from "react-redux";
import { renameFile } from "abstract-di/actions";
import { dismissDialog } from "core/actions/dialogs";
import type { State, Dispatch } from "core/types";
import type { Props, OwnProps, DispatchProps } from ".";

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    renameFile: (
      projectId: string,
      branchId: string,
      fileId: string,
      fileType: string,
      originalFileName: string,
      fileName: string
    ) => {
      invariant(renameFile, "renameFile is required for dialog action");
      dispatch(
        renameFile(
          projectId,
          branchId,
          fileId,
          fileType,
          originalFileName,
          fileName
        )
      );
    },
    dismissDialog: () => {
      dispatch(dismissDialog());
    },
  };
}

export default connect<Props, OwnProps, _, DispatchProps, State, Dispatch>(
  undefined,
  mapDispatchToProps
);
