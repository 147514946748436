// @flow
import classnames from "classnames";
import * as React from "react";
import Input from "core/components/Input";
import window from "core/global/window";
import eventInInput from "core/lib/eventInInput";
import KeyCode from "core/lib/keycode";
import { modifierKeyPressed } from "core/lib/platform";
import style from "./style.scss";

type Props = {
  className?: string,
  wrapperClass?: string,
  canFocusWithShortcut: boolean,
  innerRef?: (React.ElementRef<*>) => void,
  large?: boolean,
};

export default class InputSearch extends React.Component<Props> {
  search: ?Input;

  static defaultProps = {
    placeholder: "Filter…",
    canFocusWithShortcut: true,
  };

  componentDidMount() {
    window.addEventListener("keydown", this.onKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
  }

  onKeyDown = (ev: KeyboardEvent) => {
    if (eventInInput(ev)) {
      return;
    }
    if (!this.props.canFocusWithShortcut) {
      return;
    }

    // CMD+F (CTRL+F non-MacOS) focuses filter input
    if (modifierKeyPressed(ev) && ev.keyCode === KeyCode.KEY_F) {
      ev.preventDefault();
      this.focus();
    }
  };

  focus() {
    if (this.search) {
      this.search.input.focus();
    }
  }

  setInputRef = (ref: ?Input) => {
    this.search = ref;
    if (this.props.innerRef) {
      this.props.innerRef(ref ? ref.input : null);
    }
  };

  render() {
    const { className, wrapperClass, large, ...rest } = this.props;

    return (
      <Input
        {...rest}
        ref={this.setInputRef}
        className={classnames(className, style.input, { [style.large]: large })}
        wrapperClass={classnames(wrapperClass, style.searchWrapper)}
        name="search"
        type="search"
        icon={large ? "search-default" : "search-small"}
        iconClass={large ? style.iconLarge : undefined}
      />
    );
  }
}
