// @flow
import * as React from "react";
import { connect } from "react-redux";
import Loaded from "core/components/Loaded";
import { getCurrentLocation, replace } from "core/lib/location";
import * as Request from "core/models/request";
import type { State, Dispatch } from "core/types";
import { loginRequired } from "web/actions/login";
import { invitation } from "web/routeHelpers";
import { getSessionRequest } from "web/selectors/requests";
import { isLoggedIn } from "web/selectors/session";

type OwnProps = {|
  children: React.Node,
|};

type StateProps = {|
  isLoggedIn: boolean,
  sessionLoading: boolean,
|};

type DispatchProps = {|
  loginRequired: () => {},
|};

type Props = { ...OwnProps, ...StateProps, ...DispatchProps };

class RequireSession extends React.Component<Props> {
  componentDidUpdate(prevProps: Props) {
    const sessionDidNotLoad =
      !this.props.sessionLoading && !this.props.isLoggedIn;
    const didLogOut = prevProps.isLoggedIn && !this.props.isLoggedIn;
    const location = getCurrentLocation();
    const { invitationToken } = location.query;

    if (sessionDidNotLoad && invitationToken) {
      replace(invitation(invitationToken, { returnTo: location }));
      return;
    }

    if (sessionDidNotLoad || didLogOut) {
      this.props.loginRequired();
    }
  }

  render() {
    return (
      <Loaded loading={!this.props.isLoggedIn} delay={1000}>
        {this.props.children}
      </Loaded>
    );
  }
}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  return {
    isLoggedIn: isLoggedIn(state),
    sessionLoading: Request.isLoadingStrict(getSessionRequest(state)),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return { loginRequired: () => dispatch(loginRequired()) };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(
  mapStateToProps,
  mapDispatchToProps
)(RequireSession);
