// @flow
import classnames from "classnames";
import * as React from "react";
import { idToColor } from "core/lib/colors";
import { toInitials } from "core/lib/textFormatting";
import style from "./style.scss";

type Props = {
  userId: string,
  name: string,
  size?: number | string,
  className?: string,
  innerRef?: React.Ref<"svg">,
  qaSelector?: string,
};

export default function DefaultUserAvatar(props: Props) {
  const {
    size = 24,
    userId,
    name,
    className,
    innerRef,
    qaSelector,
    ...rest
  } = props;
  const avatarSize = typeof size === "string" ? parseInt(size, 10) : size;
  const initials = toInitials(name || "", 2);
  // Used to get 10px font size for default Avatar size of 24px
  const fontSize = Math.floor(avatarSize / 2.25);
  const fillColor = idToColor(userId || name);

  return (
    <svg
      role="img"
      aria-label={name}
      width="100%"
      height="100%"
      viewBox={`0 0 ${avatarSize} ${avatarSize}`}
      fill={fillColor}
      className={classnames(className, style.defaultUserAvatar)}
      ref={innerRef}
      data-qa={qaSelector}
      data-qa-user-name={qaSelector ? name : undefined}
      {...rest}
    >
      <rect x="0" y="0" width="100%" height="100%" />
      <text
        x="50%"
        y="50%"
        dy=".38em"
        fontSize={fontSize}
        className={style.defaultText}
      >
        {initials}
      </text>
    </svg>
  );
}
