// @flow
import * as React from "react";
import { helpArchiveProjectUrl } from "core/lib/urls";
import image from "./images/project.svg";
import Empty from ".";

export default function NoArchivedProjects(props: {}) {
  return (
    <Empty
      {...props}
      image={image}
      title="Archive a project"
      description={
        <span>
          Keep your dashboard organized by archiving inactive projects.
          <br />
          <a
            href={helpArchiveProjectUrl()}
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn about archiving in the Help Center.
          </a>
        </span>
      }
    />
  );
}
