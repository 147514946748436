// @flow
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleStar } from "core/actions/stars";
import Star from "core/components/Star";
import { isStarred as getIsStarred } from "core/selectors/stars";

type Props = {|
  projectId: string,
  className?: string,
|};

export default function ProjectStar({ projectId, ...rest }: Props) {
  const dispatch = useDispatch();
  const isStarred = useSelector((state) =>
    getIsStarred(state, { starrableId: projectId })
  );

  const handleToggleStar = React.useCallback(
    (event) => {
      // Prevents click on ProjectListItem when clicking star
      event.preventDefault();
      event.stopPropagation();

      dispatch(
        toggleStar({
          id: projectId,
          type: "project",
        })
      );
    },
    [projectId, dispatch]
  );

  return <Star starred={isStarred} onClick={handleToggleStar} {...rest} />;
}
