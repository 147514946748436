// @flow
import * as React from "react";
import { CSSTransition } from "react-transition-group/cjs";
import Button from "core/components/Button";
import ButtonGroup from "core/components/ButtonGroup";
import Card from "core/components/Card";
import NoWebhookDeliveries from "core/components/Empty/NoWebhookDeliveries";
import Flex from "core/components/Flex";
import FormSection from "core/components/FormSection";
import Heading from "core/components/Heading";
import Icon from "core/components/Icon";
import Media from "core/components/Media";
import Time from "core/components/Time";
import { push } from "core/lib/location";
import { V3Link as Link } from "core/lib/router";
import type { WebhookDelivery } from "core/types";
import { connector, type Props } from "web/containers/WebhookOverview";
import { webhookDeliveriesPath, webhookDeliveryPath } from "web/routeHelpers";
import style from "./style.scss";

const PAGE_SIZE = 10;

const redeliverSuccessTransition = {
  enter: style.enter.toString(),
  enterActive: style.enterActive.toString(),
};

type State = {
  redeliverSuccess: { [key: string]: boolean },
  cssTransitionRefs: { [key: string]: any },
};

class WebhookDeliveries extends React.Component<Props, State> {
  redeliverTimeout: ?TimeoutID;

  state = { redeliverSuccess: {}, cssTransitionRefs: {} };

  get page() {
    return Number(this.props.location.query.page || "1");
  }

  get filter() {
    return this.props.location.query.filter || "all";
  }

  get filteredItems(): WebhookDelivery[] {
    return this.props.webhookDeliveries.filter((delivery) =>
      this.filter === "error" ? delivery.error : true
    );
  }

  get pageItems(): WebhookDelivery[] {
    const startIndex = this.page * PAGE_SIZE - PAGE_SIZE;
    const endIndex = this.page * PAGE_SIZE;
    return this.filteredItems.slice(startIndex, endIndex);
  }

  onPreviousPage = () => {
    const { organizationId, webhookId } = this.props;
    if (this.page > 1) {
      push({
        pathname: webhookDeliveriesPath(organizationId, webhookId),
        query: {
          page: String(this.page - 1),
          filter: this.filter,
        },
      });
    }
  };

  onNextPage = () => {
    const { organizationId, webhookId } = this.props;
    if (this.page * PAGE_SIZE < this.filteredItems.length - 1) {
      push({
        pathname: webhookDeliveriesPath(organizationId, webhookId),
        query: {
          page: String(this.page + 1),
          filter: this.filter,
        },
      });
    }
  };

  onFilterAll = () => {
    const { organizationId, webhookId } = this.props;
    push({
      pathname: webhookDeliveriesPath(organizationId, webhookId),
      query: {
        page: "1",
        filter: "all",
      },
    });
  };

  onFilterError = () => {
    const { organizationId, webhookId } = this.props;
    push({
      pathname: webhookDeliveriesPath(organizationId, webhookId),
      query: {
        page: "1",
        filter: "error",
      },
    });
  };

  componentDidUpdate(prevProps: Props) {
    if (this.props.webhookDeliveries !== prevProps.webhookDeliveries) {
      this.setState({
        cssTransitionRefs: Object.fromEntries(
          this.props.webhookDeliveries.map((wd, i) => [
            wd.id,
            React.createRef(),
          ])
        ),
      });
    }
  }

  render() {
    const { organizationId, redeliverWebhook, webhookDeliveries, webhookId } =
      this.props;
    const { redeliverSuccess, cssTransitionRefs } = this.state;
    const hasItems = webhookDeliveries && this.filteredItems.length > 0;
    return (
      <Media mobile>
        {(mobile) => (
          <React.Fragment>
            <FormSection>
              <React.Fragment>
                <div className={style.headingContainer}>
                  <Heading className={style.heading} level="2" size="l">
                    Recent Deliveries
                  </Heading>
                  <Flex className={style.filters}>
                    <Button
                      active={this.filter === "all"}
                      nude
                      onClick={this.onFilterAll}
                    >
                      ALL
                    </Button>
                    <Button
                      active={this.filter === "error"}
                      nude
                      onClick={this.onFilterError}
                    >
                      FAILED
                    </Button>
                  </Flex>
                  {!hasItems && <div className={style.divider} />}
                </div>
                {hasItems ? (
                  this.pageItems.map((webhookDelivery) => (
                    <Card
                      className={style.card}
                      component={Link}
                      list
                      mobile={mobile}
                      to={webhookDeliveryPath(
                        organizationId,
                        webhookId,
                        webhookDelivery.id
                      )}
                    >
                      <Flex align="center">
                        <Icon
                          allgood={!webhookDelivery.error}
                          danger={!!webhookDelivery.error}
                          type={webhookDelivery.error ? "error" : "webhooks"}
                        />
                        <Flex className={style.identifier} grow>
                          {webhookDelivery.id}
                        </Flex>
                        <Flex grow={false} className={style.time}>
                          <Time date={webhookDelivery.pushedAt} />
                        </Flex>
                        {webhookDelivery.error && (
                          <div className={style.redeliverSuccess}>
                            <CSSTransition
                              classNames={redeliverSuccessTransition}
                              timeout={300}
                              in={
                                this.state.redeliverSuccess[webhookDelivery.id]
                              }
                              nodeRef={cssTransitionRefs[webhookDelivery.id]}
                            >
                              <span ref={cssTransitionRefs[webhookDelivery.id]}>
                                {this.state.redeliverSuccess[
                                  webhookDelivery.id
                                ] ? (
                                  <Icon
                                    className={style.redeliverSuccessIcon}
                                    key="success"
                                    type="checkmark"
                                  />
                                ) : (
                                  <Button
                                    className={style.retry}
                                    disabled={false}
                                    icon="refresh"
                                    iconFill="#0066d0"
                                    key="redeliver"
                                    onClick={(event) => {
                                      event.preventDefault();
                                      redeliverWebhook({
                                        deliveryId: webhookDelivery.id,
                                        organizationId,
                                        webhookId,
                                      });
                                      this.setState({
                                        redeliverSuccess: {
                                          ...redeliverSuccess,
                                          [webhookDelivery.id]: true,
                                        },
                                      });
                                      if (!this.redeliverTimeout) {
                                        this.redeliverTimeout = setTimeout(
                                          () => {
                                            this.setState({
                                              redeliverSuccess: {
                                                ...redeliverSuccess,
                                                [webhookDelivery.id]: false,
                                              },
                                            });
                                            this.redeliverTimeout = undefined;
                                          },
                                          2000
                                        );
                                      }
                                    }}
                                    nude
                                    title="Redeliver"
                                    tooltip={{ placement: "bottom" }}
                                  />
                                )}
                              </span>
                            </CSSTransition>
                          </div>
                        )}
                        <Icon type="go-to" className={style.arrow} />
                      </Flex>
                    </Card>
                  ))
                ) : (
                  <NoWebhookDeliveries failed={this.filter === "error"} />
                )}
              </React.Fragment>
            </FormSection>
            {hasItems && (
              <Flex className={style.controls}>
                <ButtonGroup>
                  <Button
                    onClick={this.onPreviousPage}
                    disabled={this.page === 1}
                  >
                    Newer
                  </Button>
                  <Button
                    onClick={this.onNextPage}
                    disabled={
                      this.page * PAGE_SIZE >= this.filteredItems.length - 1
                    }
                  >
                    Older
                  </Button>
                </ButtonGroup>
              </Flex>
            )}
          </React.Fragment>
        )}
      </Media>
    );
  }
}

export default connector(WebhookDeliveries);
