// @flow
import * as Abstract from "abstract-sdk";
import * as React from "react";
import Button from "core/components/Button";
import Flex from "core/components/Flex";
import FormHeader from "core/components/FormHeader";
import FormSection from "core/components/FormSection";
import InputSwitch from "core/components/InputSwitch";
import Loaded from "core/components/Loaded";
import Media from "core/components/Media";
import MultiSelectButton from "core/components/MultiSelectButton";
import Note from "core/components/Note";
import SettingsForm from "core/components/SettingsForm";
import SettingsItem from "core/components/SettingsItem";
import type { ShareLink, ShareLinkUpdateRequestParams } from "core/types";
import PublicOffConfirmDialog from "./PublicOffConfirmDialog";
import RevokeConfirmDialog from "./RevokeConfirmDialog";
import ShareLinkItem from "./ShareLinkItem";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  params: Abstract.OrganizationDescriptor,
|};

export type StateProps = {|
  publicSharingEnabled?: boolean,
  privateProjectPublicSharingEnabled?: boolean,
  isSubmitting: boolean,
  error: boolean,
  success: boolean,
  shareLinks: ShareLink[],
  shareLinksLoading: boolean,
|};

export type DispatchProps = {|
  updateOrganizationSettings: (
    organizationId: string,
    values: {
      publicSharingEnabled?: boolean,
      privateProjectPublicSharingEnabled?: boolean,
    }
  ) => void,
  updateShareLink: (params: ShareLinkUpdateRequestParams) => void,
  disablePublicAccess: (ShareLink[]) => void,
  onLoad: () => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type State = {
  publicSharingEnabled?: boolean,
  privateProjectPublicSharingEnabled?: boolean,
  selectedLinks: string[],
  showPublicOffConfirm: boolean,
  showRevokeConfirm: boolean,
};

const settings = {
  allowPublicLinks: "publicSharingEnabled",
  allowPrivateProjectLinks: "privateProjectPublicSharingEnabled",
};

class Sharing extends React.Component<Props, State> {
  static defaultProps = {
    shareLinksLoading: true,
  };

  state = {
    publicSharingEnabled: this.props.publicSharingEnabled,
    privateProjectPublicSharingEnabled:
      this.props.privateProjectPublicSharingEnabled,
    selectedLinks: [],
    showPublicOffConfirm: false,
    showRevokeConfirm: false,
  };

  getAreNoneSelected(): boolean {
    return this.state.selectedLinks.length === 0;
  }

  getAreSomeSelected(): boolean {
    return !this.getAreNoneSelected() && !this.getAreAllSelected();
  }

  getAreAllSelected(): boolean {
    if (!this.getPublicShareLinksCount()) {
      return false;
    }
    return this.state.selectedLinks.length === this.getPublicShareLinksCount();
  }

  getPublicShareLinksCount(): number {
    return this.props.shareLinks ? this.props.shareLinks.length : 0;
  }

  isSelected = (id: string) => {
    return this.state.selectedLinks.indexOf(id) !== -1;
  };

  handleSelectShareLink = (id: string) => (event: SyntheticInputEvent<>) => {
    const selected = event.target.checked;

    this.setState((prevState) => {
      if (selected) {
        return { selectedLinks: prevState.selectedLinks.concat(id) };
      }

      return {
        selectedLinks: prevState.selectedLinks.filter((shareLinkId) => {
          return shareLinkId !== id;
        }),
      };
    });
  };

  handleMultiSelect = () => {
    if (!this.getPublicShareLinksCount()) {
      return;
    }

    if (this.getAreSomeSelected()) {
      this.setState({
        selectedLinks: this.props.shareLinks.map((link) => link.id),
      });
    }

    if (this.getAreAllSelected()) {
      this.setState({ selectedLinks: [] });
    }
  };

  handleBulkRevokeConfirm = async () => {
    await this.state.selectedLinks.forEach((id) => {
      this.props.updateShareLink({
        id,
        isPublic: false,
      });
    });

    this.setState({
      showRevokeConfirm: false,
      selectedLinks: [],
    });
  };

  handlePublicOffConfirm = async () => {
    if (!this.getPublicShareLinksCount()) {
      return;
    }

    await this.props.disablePublicAccess(this.props.shareLinks);
    this.handleSettingsChange(settings.allowPublicLinks, false);
    this.setState({ showPublicOffConfirm: false });
  };

  handleAllowPublicLinksChange = (event: SyntheticInputEvent<*>) => {
    const checked = event.target.checked;
    const name = event.target.name;

    if (!this.getPublicShareLinksCount()) {
      return this.handleSettingsChange(name, checked);
    }

    if (checked) {
      return this.handleSettingsChange(settings.allowPublicLinks, checked);
    }

    if (name === settings.allowPublicLinks) {
      this.setState({ showPublicOffConfirm: true });
    }
  };

  handleSettingsChange = (name: string, checked: boolean) => {
    this.setState({ [name]: checked }, this.saveSettings);
  };

  saveSettings = () => {
    const { publicSharingEnabled, privateProjectPublicSharingEnabled } =
      this.state;

    this.props.updateOrganizationSettings(this.props.params.organizationId, {
      publicSharingEnabled,
      privateProjectPublicSharingEnabled,
    });
  };

  renderControls() {
    if (!this.getPublicShareLinksCount()) {
      return null;
    }

    return (
      <Flex align="center" className={style.controls}>
        {this.getAreNoneSelected() ? (
          <span>
            Displaying <strong>{this.getPublicShareLinksCount()} items</strong>
          </span>
        ) : (
          <React.Fragment>
            <MultiSelectButton
              small
              selected={this.getAreAllSelected()}
              partiallySelected={this.getAreSomeSelected()}
              onSelect={this.handleMultiSelect}
            />
            <div className={style.selectedInfo}>
              <strong>{this.state.selectedLinks.length} </strong>
              of {this.getPublicShareLinksCount()} items selected
            </div>
            <Button onClick={() => this.setState({ showRevokeConfirm: true })}>
              Revoke Public Access
            </Button>
          </React.Fragment>
        )}
      </Flex>
    );
  }

  render() {
    const header = (
      <Media desktop>
        {(desktop) => (
          <FormHeader
            mobile={!desktop}
            icon="share-dark"
            error={this.props.error ? "Could not update sharing settings" : ""}
            success={this.props.success}
            successMessage="Updated sharing settings"
            flashBanner
            id={this.props.params.organizationId}
          />
        )}
      </Media>
    );

    return (
      <SettingsForm header={header} documentTitle="Sharing">
        <FormSection heading="Public Sharing">
          <SettingsItem switch>
            <InputSwitch
              responsive
              label={
                <React.Fragment>
                  Allow public links
                  <Note component="div" className={style.note}>
                    Members will be able to share items with people outside of
                    your organization
                  </Note>
                </React.Fragment>
              }
              checked={this.state.publicSharingEnabled}
              name={settings.allowPublicLinks}
              onChange={this.handleAllowPublicLinksChange}
            />
          </SettingsItem>
          {this.state.publicSharingEnabled && (
            <SettingsItem switch>
              <InputSwitch
                responsive
                label={
                  <React.Fragment>
                    Allow public links for private projects
                    <Note component="div" className={style.note}>
                      Allows items in private projects to be shared publicly
                    </Note>
                  </React.Fragment>
                }
                checked={this.state.privateProjectPublicSharingEnabled}
                name={settings.allowPrivateProjectLinks}
                onChange={(event) =>
                  this.handleSettingsChange(
                    event.target.name,
                    event.target.checked
                  )
                }
              />
            </SettingsItem>
          )}
        </FormSection>
        {this.state.publicSharingEnabled && (
          <FormSection
            heading={
              <div>
                Publicly Shared Links
                {this.renderControls()}
              </div>
            }
          >
            <Loaded loading={this.props.shareLinksLoading} small flex>
              {() =>
                this.props.shareLinks.length ? (
                  this.props.shareLinks.map((shareLink) => (
                    <ShareLinkItem
                      key={shareLink.id}
                      shareLink={shareLink}
                      selected={this.isSelected(shareLink.id)}
                      onChange={this.handleSelectShareLink(shareLink.id)}
                      organizationId={this.props.params.organizationId}
                    />
                  ))
                ) : (
                  <Note component={Flex} className={style.empty}>
                    Items that are shared publicly will be listed here.
                  </Note>
                )
              }
            </Loaded>
          </FormSection>
        )}
        <PublicOffConfirmDialog
          isOpen={this.state.showPublicOffConfirm}
          onClose={() => this.setState({ showPublicOffConfirm: false })}
          onSubmit={this.handlePublicOffConfirm}
          isSubmitting={this.props.isSubmitting}
          hasError={this.props.error}
          publicShareLinksCount={this.getPublicShareLinksCount()}
        />
        <RevokeConfirmDialog
          isOpen={this.state.showRevokeConfirm}
          onClose={() => this.setState({ showRevokeConfirm: false })}
          onSubmit={this.handleBulkRevokeConfirm}
        />
      </SettingsForm>
    );
  }
}

export default connector(Sharing);
