// @flow
import { connect } from "react-redux";
import { createReview, updateReview, deleteReview } from "core/actions/reviews";
import createConnector from "core/lib/createConnector";
import { ReviewStatuses } from "core/models/review";
import { getBranchStatus } from "core/selectors/branches";
import { getReviewRequests } from "core/selectors/reviews";
import type { Dispatch, State } from "core/types";
import type { Props, OwnProps, StateProps, DispatchProps } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { branchId, projectId } = props;
  const branchStatus = getBranchStatus(state, { projectId, branchId });

  return {
    requiredReviewRequests: getReviewRequests(state, {
      projectId,
      branchId,
      isRequired: true,
      reviewRequestOrder: "reverse",
    }),
    optionalReviewRequests: getReviewRequests(state, {
      projectId,
      branchId,
      isOptional: true,
      reviewRequestOrder: "reverse",
    }),
    branchStatus,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { projectId, branch } = props;
  const branchId = branch.id;

  return {
    deleteReviewRequest: (id: string) => {
      return dispatch(deleteReview({ id }));
    },

    dismissReview: (id: string) => {
      return dispatch(
        updateReview({
          id,
          status: ReviewStatuses.REQUESTED,
        })
      );
    },

    addReviewer: (reviewerId: string) => {
      return dispatch(
        createReview({
          projectId,
          branchId,
          reviewerId,
          sha: branch.head,
        })
      );
    },
  };
}

export default createConnector<Props, OwnProps>(
  connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch>(
    mapStateToProps,
    mapDispatchToProps
  )
);
