// @flow
import * as React from "react";
import Icon from "core/components/Icon";
import Popover from "core/components/Popover";
import * as Change from "core/models/change";
import type { ChangesetChange } from "core/types";

type Props = {
  change: string | ChangesetChange,
  light?: boolean,
  className?: string,
  title?: string,
  tooltip?: boolean | React.ElementConfig<typeof Popover>,
};

const DEFAULT_TOOLTIP = { placement: "auto" };

export default function ChangeStatusIcon({
  change,
  light,
  className,
  title,
  tooltip,
  ...rest
}: Props) {
  let status = Change.status(change);
  if (status === "none") {
    return null;
  }
  if (status === "edited") {
    status = "changed";
  }

  const iconProps = {
    ...rest,
    light,
    className,
    small: true,
    danger: !light && status === "removed",
    allgood: !light && status === "added",
    tint: !light && status === "changed",
    type: `status-${status}`,
  };

  if (title && tooltip) {
    const popoverProps =
      typeof tooltip === "boolean" ? DEFAULT_TOOLTIP : tooltip;
    return (
      <Popover {...popoverProps} label={title}>
        {(_, popoverRef, popoverHandlers) => (
          <Icon {...popoverHandlers} {...iconProps} innerRef={popoverRef} />
        )}
      </Popover>
    );
  }

  return <Icon {...iconProps} title={title} />;
}
