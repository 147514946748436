// @flow
/* global FileReader */
import classnames from "classnames";
import { isEmpty } from "lodash";
import * as React from "react";
import Button from "core/components/Button";
import Flex from "core/components/Flex";
import Heading from "core/components/Heading";
import Input from "core/components/Input";
import Modal from "core/components/Modal";
import ValidationError from "core/components/ValidationError";
import AttachmentDropzone from "./AttachmentDropzone";
import style from "./style.scss";

type Props = {
  isOpen: boolean,
  hideModal: (event: SyntheticEvent<>) => void,
  contentLabel: string,
  addEmoji: (name: string, imageBase64: string) => Promise<void>,
};

type State = {
  isLoading: boolean,
  hasRejectedFiles: boolean,
  files: File[],
  name: string,
  error: ?{ message: string },
};

const REJECTED_FILE_ERROR =
  "Only 'jpeg', 'png' and 'gif' files up to 128KB are allowed";

// TODO: move this function somewhere
function fileToBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let url = reader.result || "";
      if (url instanceof ArrayBuffer) {
        url = String.fromCharCode.apply(null, new Uint16Array(url));
      }
      resolve(url);
    };
    reader.onerror = (error) => reject(error);
  });
}

export default class AddEmojiModal extends React.Component<Props, State> {
  state = {
    isLoading: false,
    hasRejectedFiles: false,
    files: [],
    name: "",
    error: undefined,
  };

  handleEmojiAttached = (files: File[]) => {
    this.setState({ files });
  };

  handleRemove = () => {
    this.setState({ files: [] });
  };

  setRejectedFilesOn = () => {
    this.setState({ hasRejectedFiles: true });
  };

  setRejectedFilesOff = () => {
    this.setState({ hasRejectedFiles: false });
  };

  setIsLoadingOn = () => {
    this.setState({ isLoading: true });
  };

  setIsLoadingOff = () => {
    this.setState({ isLoading: false });
  };

  handleChange = (event: SyntheticInputEvent<>) => {
    const { value, name } = event.target;

    this.setState({ [name]: value, error: undefined });

    if (!/^[0-9a-z_-]+$/.test(value)) {
      this.setState({
        error: {
          message:
            "Must include only lowercase alphanumeric, '_' or '-' characters.",
        },
      });
    }
  };

  handleSubmit = async (event: SyntheticEvent<>) => {
    event.preventDefault();
    this.setIsLoadingOn();

    const file = this.state.files[0];

    try {
      const imageBase64 = await fileToBase64(file);

      await this.props.addEmoji(this.state.name, imageBase64);
      this.props.hideModal(event);
    } catch (error) {
      console.error(error);
    }

    this.setIsLoadingOff();
  };

  render() {
    const { isOpen, hideModal } = this.props;
    const { isLoading, hasRejectedFiles } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        onClose={hideModal}
        onRequestClose={hideModal}
        contentLabel="Add Custom Emoji"
        className={style.modalContainer}
      >
        <form className={style.form} onSubmit={this.handleSubmit}>
          <Flex align="flex-start" className={style.modalTop}>
            <Flex column className={style.modalTopInner}>
              <Heading size="xxxl" level="1">
                Add Custom Emoji
              </Heading>
              {isEmpty(this.state.files) ? (
                <AttachmentDropzone
                  isLoading={isLoading}
                  hasRejectedFiles={hasRejectedFiles}
                  onChange={this.handleEmojiAttached}
                  setRejectedFilesOn={this.setRejectedFilesOn}
                  setRejectedFilesOff={this.setRejectedFilesOff}
                  handleRemove={this.handleRemove}
                />
              ) : (
                <Flex
                  justify="center"
                  align="center"
                  className={classnames(style.dropzone, style.emojiPreview)}
                >
                  <img
                    className={style.previewImg}
                    src={URL.createObjectURL(this.state.files[0])}
                    alt={this.state.files[0].name}
                  />
                  <Flex
                    justify="center"
                    align="center"
                    className={style.buttonRemove}
                  >
                    <Button nude icon="trash" onClick={this.handleRemove} />
                  </Flex>
                </Flex>
              )}
              {hasRejectedFiles && (
                <ValidationError
                  error={REJECTED_FILE_ERROR}
                  className={style.validationError}
                />
              )}
              <Input
                name="name"
                label="Name"
                type="text"
                id="new-emoji-name"
                placeholder="high-five"
                maxLength={20}
                minLength={1}
                showLengthWarning={11}
                onChange={this.handleChange}
                validationMessage={this.state.error && this.state.error.message}
                requiredTag
                required
              />
              {this.state.error && (
                <ValidationError
                  error={this.state.error.message}
                  className={style.validationError}
                />
              )}
            </Flex>
          </Flex>
          <div className={style.modalBottom}>
            <Button
              large
              primary
              fullwidth
              type="submit"
              disabled={isLoading || !!this.state.error || !this.state.name}
            >
              Upload Emoji
            </Button>
          </div>
        </form>
      </Modal>
    );
  }
}
