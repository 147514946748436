// @flow
import empty from "empty";
import idx from "idx";
import { reject, merge } from "lodash";
import { static as Immutable } from "seamless-immutable";
import WeakTupleMap from "weaktuplemap";
import { LAYER_FILL_TYPE_SOLID } from "core/components/LayerProperties/constants";
import createLogger from "core/lib/logger";
import { pathHash } from "core/models/layerData";
import type { LayerData, MutableLayerData } from "core/types";
import cloneSymbol, { readInferred } from "./cloneSymbol";
import * as constants from "./constants";
import measureTextLayer from "./measureTextLayer";
import resizeLayout from "./resizeLayout";
import { tintLayer } from "./tintLayer";

export { readInferred };

const logger = createLogger("mapLayerChildren");
const cache: WeakMap<
  [$ReadOnly<{ [layerId: string]: LayerData }>, LayerData],
  MutableLayerData[],
> = new WeakTupleMap();

function isSparseShapeGroup(layerData: LayerData): boolean {
  return layerData.type === "shapeGroup" && layerData.childIds.length === 1;
}

export default function mapLayerChildren<T>(
  layers: $ReadOnly<{ [layerId: string]: LayerData }>,
  parentLayerData: LayerData,
  callback: (childLayerData: LayerData) => T
): T[] {
  const cacheKey = [layers, parentLayerData]; // Cache relies on the identity of inputs

  logger.log(`Map "${parentLayerData.id}"…`);

  if (!cache.has(cacheKey)) {
    // Follow symbol id to master data if necessary
    const masterParentLayerData = parentLayerData.symbolId
      ? layers[parentLayerData.symbolId] || layers[parentLayerData.id]
      : layers[parentLayerData.id];

    logger.log(
      `Loaded children for "${parentLayerData.id}" from symbol master "${masterParentLayerData.id}"`
    );

    // Inherit parent's path or start the path
    const parentPath = parentLayerData._path
      ? parentLayerData._path
      : [parentLayerData];
    const parentPathHash = pathHash(
      parentPath
        .filter((layer) => layer.type === "symbolInstance")
        .map((layer) => layer.id)
    );
    const parentScaleWidth =
      parentLayerData.properties.width / masterParentLayerData.properties.width;
    const parentScaleHeight =
      parentLayerData.properties.height /
      masterParentLayerData.properties.height;

    let children = [...masterParentLayerData.childIds];

    children = children.map((childId: string): MutableLayerData => {
      let childLayerData: MutableLayerData = Immutable.asMutable(
        layers[childId],
        { deep: true }
      );

      logger.log(
        `Mapping over "${childLayerData.id}" for parent "${masterParentLayerData.id}"…`
      );

      logger.log(`Inheriting properties from "${parentLayerData.id}"`);
      childLayerData._key = `${parentPathHash}-${childLayerData.id}`; // Key is inherited from parent hash
      childLayerData._layoutUnknown = parentLayerData._layoutUnknown; // Inherit unknown layout
      childLayerData._root = parentLayerData._root || parentLayerData; // Inherit root or start a new root

      if (parentLayerData.type === "symbolInstance") {
        logger.log(
          `Updating root to parent symbol instance "${parentLayerData.id}"`
        );
        childLayerData._root = parentLayerData; // Force a new root from symbol instance
      }

      const rootLayer = childLayerData._root; // Create reference to root to allow access without refinement
      const { textContent, width } = childLayerData.properties;

      if (childLayerData.type === "symbolInstance") {
        logger.log(`Cloning ${childLayerData.id}`);

        const rootOverrides =
          idx(childLayerData, (_) => _._root.properties.overrides) ||
          constants.NO_OVERRIDES;

        childLayerData = cloneSymbol(layers, childLayerData, rootOverrides, {
          parentPath: parentLayerData._path
            ? parentLayerData._path
                .filter((layer) => layer.type === "symbolInstance")
                .map(({ id }) => id)
            : undefined,
        });
      }

      if (
        childLayerData.type === "text" &&
        typeof textContent === "string" &&
        textContent.length && // exclude empty text labels
        width === 0
      ) {
        const measurement = measureTextLayer(childLayerData, textContent);

        logger.log(
          `Measured layout for text layer with no width "${childLayerData.id}"`
        );

        childLayerData._layoutUnknown = {
          ...childLayerData._layoutUnknown,
          width: childLayerData.properties.width !== measurement.width, // displays  ~ estimate tooltip in layer properties sidebar
          x: childLayerData.properties.x !== measurement.x,
        };

        childLayerData.properties.width = measurement.width;
        childLayerData.properties.x =
          measurement.x || childLayerData.properties.x;
      }

      if (isSparseShapeGroup(childLayerData)) {
        const singleChild = layers[childLayerData.childIds[0]];

        if (singleChild.type === "rectangle") {
          // Move borderRadius to parent shapeGroup
          childLayerData.properties.borderRadius =
            singleChild.properties.borderRadius;
        }
      }

      const firstFill = idx(parentLayerData, (_) => _.properties.fills[0]);
      // Tints are applied on Groups and Symbols. They appear in the Sketch filetype as a Solid Color Fill applied to a Group or a Symbol.
      // A tint will influence all colored items within, by replacing the color value completely and multiplying against the opacity value.
      // The highest level tint supercedes all tints beneath it.
      // A tint on the Symbol Instance level supercedes tints that may occur in the Symbol Master.
      if (
        (parentLayerData.type === "group" ||
          parentLayerData.type === "symbolInstance") &&
        firstFill &&
        firstFill.fillType === LAYER_FILL_TYPE_SOLID
      ) {
        // Establish tint property from fills applied on groups & symbol instances.
        // There should only be one tint, although fills is an array-type - so this code picks out the first.
        childLayerData.properties.tint = firstFill;
      } else if (parentLayerData.properties.tint) {
        // Pass down and override children tints from parent tints.
        childLayerData.properties.tint = parentLayerData.properties.tint;
      }
      childLayerData = tintLayer(childLayerData);

      if (rootLayer.type === "symbolInstance") {
        const rootOverrides =
          idx(childLayerData, (_) => _._root.properties.overrides) ||
          constants.NO_OVERRIDES;

        // Combine local and nested overrides
        const overrides = merge(
          childLayerData.properties.overrides,
          rootOverrides[childLayerData.id]
        );

        if (overrides.properties) {
          // Apply override properties (deep merge to support text properties)
          childLayerData.properties = merge(
            childLayerData.properties,
            overrides.properties
          );
        }

        if (overrides) {
          // Apply nested overrides
          childLayerData.properties.overrides = {
            ...childLayerData.properties.overrides,
            ...overrides,
          };
        }

        if (!readInferred(rootLayer).properties) {
          // There are only inferred properties for a root/symbol instance
          // if there was an override that triggered a layout change. In that scenario
          // we prefer smart layout over scaling - or running resize layout
          childLayerData.properties = {
            ...childLayerData.properties,
            ...resizeLayout(
              childLayerData,
              parentLayerData,
              masterParentLayerData
            ),
          };
        }

        const inferredProperties = readInferred(childLayerData).properties;

        if (inferredProperties) {
          childLayerData._layoutUnknown = {
            width:
              inferredProperties.width !== undefined &&
              childLayerData.properties.width !== inferredProperties.width,
            x:
              inferredProperties.x !== undefined &&
              childLayerData.properties.x !== inferredProperties.x,
          };

          // Apply inferred properties like layout from overrides
          childLayerData.properties = {
            ...childLayerData.properties,
            ...inferredProperties,
          };
        }

        logger.log(
          `Updating "${childLayerData.id}" coordinates relative to "${parentLayerData.id}"`
        );

        childLayerData.properties.x =
          parentLayerData.properties.x + childLayerData.properties.x;
        childLayerData.properties.y =
          parentLayerData.properties.y + childLayerData.properties.y;
      } else {
        // ...othwerwise simply scale layers that are not part of a symbol instances
        childLayerData.properties.x =
          parentLayerData.properties.x +
          childLayerData.properties.x * parentScaleWidth;

        childLayerData.properties.y =
          parentLayerData.properties.y +
          childLayerData.properties.y * parentScaleHeight;

        childLayerData.properties.width =
          childLayerData.properties.width * parentScaleWidth;

        childLayerData.properties.height =
          childLayerData.properties.height * parentScaleHeight;
      }

      // Append child to parent's path (IMPORTANT: must be last mutation)
      childLayerData._path = [...parentPath, childLayerData];

      return childLayerData;
    });

    // Filter non-visible layers and symbols overridden to "No symbol"
    children = reject(
      children,
      (childLayerData) => !childLayerData.properties.isVisible
    );

    if (isSparseShapeGroup(masterParentLayerData)) {
      // Ignore children when there is a single layer inside of shape groups
      children = constants.NO_CHILDREN;
    }

    cache.set(cacheKey, children);
  }

  const children = cache.get(cacheKey) || empty.array;

  return children.map(callback);
}

export function visitEachLayer(
  layers: { [layerId: string]: LayerData },
  layerData: LayerData,
  callback: (childLayerData: LayerData) => void
) {
  mapLayerChildren(layers, layerData, (childLayerData) =>
    visitEachLayer(layers, childLayerData, callback)
  );

  callback(layerData);
}
