// @flow
import addDays from "date-fns/add_days";
import addMonths from "date-fns/add_months";
import addWeeks from "date-fns/add_weeks";
import addYears from "date-fns/add_years";
import distanceInWordsStrict from "date-fns/distance_in_words_strict";
import distanceInWordsToNow from "date-fns/distance_in_words_to_now";
import endOfDay from "date-fns/end_of_day";
import endOfMonth from "date-fns/end_of_month";
import endOfWeek from "date-fns/end_of_week";
import endOfYear from "date-fns/end_of_year";
import format from "date-fns/format";
import isAfter from "date-fns/is_after";
import isBefore from "date-fns/is_before";
import isThisYear from "date-fns/is_this_year";
import startOfDay from "date-fns/start_of_day";
import startOfMonth from "date-fns/start_of_month";
import startOfWeek from "date-fns/start_of_week";
import startOfYear from "date-fns/start_of_year";
import subDays from "date-fns/sub_days";
import subMonths from "date-fns/sub_months";
import subWeeks from "date-fns/sub_weeks";
import subYears from "date-fns/sub_years";

type TDate = Date | string | number;

const DATE_FNS_APPROXIMATIONS = /^(about|almost|over)\s+/;

function isBetween(date: TDate, start: TDate, end: TDate): boolean {
  return isAfter(date, start) && isBefore(date, end);
}

/**
 * Gives a very general time frame for the given date, used for making grouped
 * lists. Not intended to be used on _future_ dates.
 *
 * Returns one of the following strings:
 * - "In the Future!" - not intended to be used, but hopefully a cute failsafe.
 * - "Today"          - the date is in the current calendar day.
 * - "Yesterday"      - the date is in the previous calendar day.
 * - "This Week"      - the date is in the current calendar week, starting on
 *                      Sunday.
 * - "Last week"      - the date is in the previous calendar week, starting on
 *                      Sunday.
 * - "Older"          - the date is before the previous calendar week, starting
 *                      on Sunday.
 */
export function dateGroup(date: TDate, now: TDate = new Date()): string {
  const endOfToday = endOfDay(now);

  if (isAfter(date, endOfToday)) {
    return "In the Future!";
  }

  const endOfYesterday = subDays(endOfToday, 1);
  const startOfTomorrow = addDays(startOfDay(now), 1);
  if (isBetween(date, endOfYesterday, startOfTomorrow)) {
    return "Today";
  }

  const endOfTwoDaysAgo = subDays(endOfToday, 2);
  const startOfToday = startOfDay(now);
  if (isBetween(date, endOfTwoDaysAgo, startOfToday)) {
    return "Yesterday";
  }

  const endOfLastWeek = endOfWeek(subWeeks(now, 1));
  const startOfNextWeek = startOfWeek(addWeeks(now, 1));
  if (isBetween(date, endOfLastWeek, startOfNextWeek)) {
    return "This Week";
  }

  const endOfTwoWeeksAgo = endOfWeek(subWeeks(now, 2));
  const startOfThisWeek = startOfWeek(now);
  if (isBetween(date, endOfTwoWeeksAgo, startOfThisWeek)) {
    return "Last Week";
  }

  const endOfLastMonth = endOfMonth(subMonths(now, 1));
  const startOfNextMonth = startOfMonth(addMonths(now, 1));
  if (isBetween(date, endOfLastMonth, startOfNextMonth)) {
    return "This Month";
  }

  const endOfTwoMonthsAgo = endOfMonth(subMonths(now, 2));
  const startOfThisMonth = startOfMonth(now);
  if (isBetween(date, endOfTwoMonthsAgo, startOfThisMonth)) {
    return "Last Month";
  }

  const endOfLastYear = endOfYear(subYears(now, 1));
  const startOfNextYear = startOfYear(addYears(now, 1));
  if (isBetween(date, endOfLastYear, startOfNextYear)) {
    return "This Year";
  }

  const endOfTwoYearsAgo = endOfYear(subYears(now, 2));
  const startOfThisYear = startOfYear(now);
  if (isBetween(date, endOfTwoYearsAgo, startOfThisYear)) {
    return "Last Year";
  }

  return "Older";
}

/**
 * Format a date as date.
 *
 * @example
 * const date = new Date(2016, 1, 4, 6);
 * formatDate(date)
 * //=> "Feb 4, 2016"
 */
export function formatDate(date: TDate): string {
  return format(date, "MMM D, YYYY");
}

/**
 * Format a date as current week.
 *
 * @example
 * const date = new Date(2016, 1, 4, 6);
 * formatCurrentWeek(date)
 * //=> "Jan 31 - Feb 04"
 */
export function formatCurrentWeekToDate(date?: TDate = new Date()): string {
  const weekFormat = "MMM D";
  const startOfCurrentWeek = format(startOfWeek(date), weekFormat);
  const today = format(date, weekFormat);
  return `${startOfCurrentWeek} - ${today}`;
}

/**
 * Format a date as the past seven days.
 *
 * @example
 * const date = new Date(2016, 1, 4, 6);
 * formatLastSevenDays(date)
 * //=> "Jan 29 - Feb 04"
 */
export function formatSevenDaysAgo(date?: TDate = new Date()): string {
  const weekFormat = "MMM D";
  const lastWeek = format(subDays(date, 6), weekFormat);
  const today = format(date, weekFormat);
  return `${lastWeek} - ${today}`;
}

function abbreviateDateWords(sentence: string): string {
  return sentence
    .replace(/just now/, "now")
    .replace(/ minutes? ago/, "m")
    .replace(/ hours? ago/, "h")
    .replace(/ months? ago/, "mo")
    .replace(/ days? ago/, "d")
    .replace(/ years? ago/, "y");
}

/**
 * Format a date as date and time.
 *
 * @example
 * const date = new Date(2016, 1, 4, 6);
 * dateTime(date)
 * //=> "Feb 4, 2016 6:00 AM"
 */
export function dateTime(date: TDate): string {
  return format(date, "MMM D, YYYY h:mm A");
}

/**
 * Format a date relative to the current time.
 *
 * @example
 * // Assuming the current date & time is Feb 4 2016, 6am:
 * // date = Feb 3 2016, 6am
 * const date = new Date(2016, 1, 3, 6);
 * fromNow(date);
 * //=> "1 day ago"
 */
export function fromNow(
  date?: TDate = new Date(),
  useShorthand?: boolean
): string {
  let output;
  const now = new Date();

  // Because client clocks can drift a couple of seconds either-side of realtime
  // it's possible for an item that was just created to appear "in the future"
  // to a client that is a few seconds behind the server. To account for this
  // case we explitly make anything within a minute of the current time "just now"
  if (Math.abs(now - new Date(date)) < 60 * 1000) {
    output = "just now";
  } else {
    output = distanceInWordsToNow(date, { addSuffix: true }).replace(
      DATE_FNS_APPROXIMATIONS,
      ""
    );
  }

  if (useShorthand) {
    output = abbreviateDateWords(output);
  }

  return output;
}

/**
 * Format a date as a time.
 *
 * @example
 * // date = Feb 3 2016, 6am
 * const date = new Date(2016, 1, 3, 6);
 * time(date)
 * //=> "06:00:00"
 */
export function time(date: TDate): string {
  return format(date, "hh:mm:ss");
}

/**
 * Format a date as a time, with no seconds.
 *
 * @example
 * // date = Feb 3 2016, 6am
 * const date = new Date(2016, 1, 3, 6);
 * time(date)
 * //=> "06:00 AM"
 */
export function timeNoSeconds(date: TDate): string {
  return format(date, "hh:mm a");
}

/**
 * Format a duration as seconds, minutes, hours
 *
 * @example
 * // date = Feb 3 2016, 6am
 * const date = new Date(2016, 1, 3, 6);
 * const end = new Date(2016, 1, 3, 7);
 * duration(date, end);
 * //=> "60 minutes"
 */
export function duration(
  dateToCompare: TDate,
  date: TDate,
  useShorthand: ?boolean,
  options?: Object
) {
  let sentence = distanceInWordsStrict(dateToCompare, date, options);

  if (useShorthand) {
    sentence = abbreviateDateWords(sentence);
  }

  return sentence;
}

/**
 * Format a date as day and month.
 * Omit the year if year is current year.

 * @example
 * const date = new Date(2016, 1, 4, 6);
 * dateTime(date)
 * //=> "Thursday, February 4, 2016"
 * //=> "Thursday, February 4" (if in 2016)
 */
export function dateDayMonth(date: TDate): string {
  let formating = "dddd, MMMM D";
  if (!isThisYear(date)) {
    formating += ", YYYY";
  }

  return format(date, formating);
}

/**
 * Format a date time as month and day.

 * @example
 * const date = new Date(2016, 1, 4, 6);
 * //=> "Feb 4"
 */
export function formatMonthDay(date: TDate): string {
  return format(date, "MMM D");
}
