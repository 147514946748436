// @flow
import invariant from "invariant";
import { connect } from "react-redux";
import { updateBranch } from "core/actions/branches";
import { dismissDialog } from "core/actions/dialogs";
import type { State, Dispatch } from "core/types";
import type { Props, OwnProps, DispatchProps } from ".";

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    updateBranch: (
      projectId: string,
      branchId: string,
      branchAttrs: Object
    ) => {
      invariant(updateBranch, "updateBranch required for dialog action");
      dispatch(updateBranch(projectId, branchId, branchAttrs));
    },
    dismissDialog: () => dispatch(dismissDialog()),
  };
}

export default connect<Props, OwnProps, _, DispatchProps, State, Dispatch>(
  undefined,
  mapDispatchToProps
);
