// @flow
import * as React from "react";
import DialogForm from "core/components/DialogForm";
import type { Project, User } from "core/types";
import connector from "./connector";

export type OwnProps = {|
  isOpen: boolean,
  onClose: () => void,
  project: Project,
  user: User,
|};

export type StateProps = {|
  isDeleteMembershipRequestLoading: boolean,
|};

export type DispatchProps = {|
  removeFromProject: () => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

function RemoveConfirmationDialog(props: Props) {
  return (
    <DialogForm
      disabled={props.isDeleteMembershipRequestLoading}
      isOpen={props.isOpen}
      onClose={props.onClose}
      onSubmit={props.removeFromProject}
      primaryButton={
        props.isDeleteMembershipRequestLoading
          ? "Removing User…"
          : "Remove User"
      }
      title="Confirm remove"
      dangerous
    >
      <div>
        Are you sure you want to remove <strong>{props.user.name}</strong> from{" "}
        <strong>{props.project.name}</strong>?
      </div>
    </DialogForm>
  );
}

export default connector(RemoveConfirmationDialog);
