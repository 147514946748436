// @flow
import classnames from "classnames";
import omit from "lodash/omit";
import * as React from "react";
import CommentsIcon from "core/components/CommentsIcon";
import CommitTitle from "core/components/CommitTitle";
import CommitTrack from "core/components/CommitTrack";
import Icon from "core/components/Icon";
import Time from "core/components/Time";
import { V3Link as Link } from "core/lib/router";
import type { CommitType, LocationDescriptor } from "core/types";
import style from "./style.scss";

type Props = {
  authorName: string,
  className?: string,
  commentCount?: number,
  commitPath: (sha: string) => LocationDescriptor,
  first?: boolean,
  firstOfGroup?: boolean,
  icon?: string,
  innerRef?: React.Ref<"span">,
  last?: boolean,
  lastOfGroup?: boolean,
  pushing?: boolean,
  sha: string,
  time: string,
  title: string,
  type: CommitType,
};

const ALL_PROPS = [
  "authorName",
  "className",
  "commentCount",
  "commitPath",
  "first",
  "firstOfGroup",
  "icon",
  "innerRef",
  "last",
  "lastOfGroup",
  "pushing",
  "sha",
  "time",
  "title",
  "type",
  "isFocused",
  "selectedClassName",
];

export default function CommitCompact(props: Props) {
  const rest = omit(props, ALL_PROPS);

  return (
    <div {...rest} className={classnames(style.wrapper, props.className)}>
      <div className={style.inner}>
        <CommitTrack
          type={props.type}
          first={props.first}
          firstOfGroup={props.firstOfGroup}
          icon={props.icon}
          last={props.last}
          lastOfGroup={props.lastOfGroup}
          pushing={props.pushing}
          className={style.commitTrack}
        />
        <Link className={style.group} to={props.commitPath(props.sha)}>
          <div className={style.heading}>
            <span ref={props.innerRef}>
              <CommitTitle type={props.type} title={props.title} />
            </span>
          </div>
          <Icon type="go-to" />
        </Link>
        <span className={style.meta}>
          <CommentsIcon count={props.commentCount} />
          <Link to={props.commitPath(props.sha)} className={style.time}>
            <Time date={props.time} />
          </Link>
        </span>
      </div>
    </div>
  );
}

CommitCompact.defaultProps = {
  className: "",
  commentCount: 0,
  first: false,
  firstOfGroup: false,
  icon: "",
  last: false,
  lastOfGroup: false,
  pushing: false,
};
