// @flow
import * as React from "react";
import CoreGlobalNavigationShell from "core/components/GlobalNavigationShell";
import Media from "core/components/Media";
import connector from "./connector";

export type OwnProps = {|
  children: React.Node,
  params: {
    branchId?: string,
    organizationId: string,
    projectId?: string,
  },
|};

export type StateProps = {|
  isSidebarOpen: boolean,
|};

export type DispatchProps = {|
  onCloseSidebar: () => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

function GlobalNavigationShell(props: Props) {
  return (
    <Media large>
      {(large) => (
        <CoreGlobalNavigationShell
          active={props.isSidebarOpen}
          branchId={props.params.branchId}
          children={props.children}
          mobile={!large}
          onClose={props.onCloseSidebar}
          organizationId={props.params.organizationId}
          projectId={props.params.projectId}
        />
      )}
    </Media>
  );
}

export default connector(GlobalNavigationShell);
