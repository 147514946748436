// @flow
import * as React from "react";
import Error from "./Error";

export default function NotPermitted(props: Object) {
  return (
    <Error
      {...props}
      title="Not permitted"
      description={
        <span>
          You need permission to access this page.
          <br />
          If you believe this is an error, please contact support.
        </span>
      }
    />
  );
}
