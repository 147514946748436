// @flow
import { debounce } from "lodash";
import { connect } from "react-redux";
import { isOnline } from "abstract-di/selectors";
import { trackEvent } from "core/actions/analytics";
import { focusedElement } from "core/actions/navigation";
import {
  quickJumpTermChange,
  quickJumpOpen,
  quickJumpClose,
} from "core/actions/quickJump";
import { QuickJumpFetchRequest } from "core/requests/quickJump";
import { getOrganizations } from "core/selectors/organizations";
import { getProject } from "core/selectors/projects";
import {
  getQuickJumpResults,
  getQuickJumpSearchTerm,
  getQuickJumpOpen,
  getIsQuickJumpShowingKeyboardShortcutTip,
} from "core/selectors/quickJump";
import type { State, Dispatch } from "core/types";
import type { Props, OwnProps, StateProps, DispatchProps } from "./";

const debouncedSearch = debounce(
  (search: string, dispatch: Dispatch) => {
    dispatch(QuickJumpFetchRequest.perform({ params: { search } }));

    dispatch(trackEvent("SEARCHED", { query: search }));
  },
  250,
  { trailing: true }
);

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const search = getQuickJumpSearchTerm(state);
  let { organizationId, projectId } = props;

  if (!organizationId && projectId) {
    const project = getProject(state, { projectId });
    if (project) {
      organizationId = project.organizationId;
    }
  }

  return {
    isOnline: isOnline(state),
    isLoading: QuickJumpFetchRequest.isLoading(state, { search }),
    isLoadingStrict: QuickJumpFetchRequest.isLoadingStrict(state, { search }),
    isOpen: getQuickJumpOpen(state),
    organizations: getOrganizations(state),
    results: getQuickJumpResults(state, { organizationId, projectId }),
    searchTerm: search,
    isShowingShortcutTip: getIsQuickJumpShowingKeyboardShortcutTip(state),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    onChange: (search) => {
      dispatch(quickJumpTermChange(search));

      const normalizedSearch = search.trim();
      if (normalizedSearch) {
        debouncedSearch(normalizedSearch, dispatch);
      }
    },
    onOpen: () => {
      dispatch(focusedElement(""));
      dispatch(quickJumpOpen());
    },
    onClose: (success: ?boolean) => {
      dispatch(quickJumpClose(success || false));

      if (success) {
        // success should only be true if a result was selected
        dispatch((dispatch, getState) => {
          const search = getQuickJumpSearchTerm(getState());
          dispatch(trackEvent("SEARCH_RESULT_SELECTED", { query: search }));
        });
      }
    },
  };
}

export default connect<Props, OwnProps, StateProps, _, State, Dispatch>(
  mapStateToProps,
  mapDispatchToProps
);
