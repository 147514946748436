// @flow
import * as React from "react";
import style from "./style.scss";

type Props = {
  required: boolean,
};

export default function ReviewerNote(props: Props) {
  return (
    <p className={style.reviewerNote}>
      {props.required
        ? "Each reviewer will receive individual notifications once you request a review."
        : "Any member of this project can leave a review without being requested."}
    </p>
  );
}
