// @flow
/* global document */
import classnames from "classnames";
import empty from "empty";
import * as React from "react";
import CopyLinkButton from "core/components/CopyLinkButton";
import CopyToClipboard from "core/components/CopyToClipboard";
import Flex from "core/components/Flex";
import Icon from "core/components/Icon";
import Popover from "core/components/Popover";
import KeyCode from "core/lib/keycode";
import type { MenuItem } from "core/types";
import ContextMenuItemList from "./ContextMenuItemList";
import style from "./style.scss";

export type Props = {
  danger?: boolean,
  props?: Object,
  label?: string,
  separator?: boolean,
  checked?: boolean,
  control?: string,
  enabled?: boolean,
  compact?: boolean,
  type?: string,
  id?: string,
  decoration?: React.Node,
  text?: string,
  submenu?: MenuItem[],
  click?: (event?: SyntheticEvent<>) => void,
  close?: () => void,
  qaSelector?: string,
};

type State = {
  isForceShowingMenu: boolean,
};

export default class ContextMenuItem extends React.Component<Props, State> {
  state = {
    isForceShowingMenu: false,
  };

  handleRequestHide = () => {
    this.setState({
      isForceShowingMenu: false,
    });
  };

  handleKeyDown = (event: KeyboardEvent) => {
    if (
      event.keyCode === KeyCode.KEY_RIGHT ||
      event.keyCode === KeyCode.KEY_SPACE ||
      event.keyCode === KeyCode.KEY_RETURN
    ) {
      // Prevents our KeyboardNavigation component from capturing this key.
      // For example, on the global sidebar, if focus is set on a project,
      // KEY_RIGHT will propagate up and trigger the sidebar.
      if (event.keyCode === KeyCode.KEY_RIGHT) {
        event.preventDefault();
        event.stopPropagation();
      }

      this.setState({
        isForceShowingMenu: true,
      });
    }
  };

  itemProps = () => {
    const props = this.props.props || {};

    if (this.isShare()) {
      return {
        icon: null,
        tooltip: false,
        nude: true,
        tint: true,
        onCopySuccess: this.props.close,
        organizationId: props.organizationId,
        mode: props.mode,
        inputShare: {
          kind: props.kind,
          collectionLayerId: props.collectionLayerId,
          descriptor: {
            projectId: props.projectId,
            branchId: props.branchId,
            sha: props.commitSha,
            fileId: props.fileId,
            layerId: props.layerId,
            commentId: props.commentId,
            collectionId: props.collectionId,
            pageId: props.pageId,
          },
        },
      };
    } else if (this.isCopy()) {
      return {
        icon: null,
        tooltip: false,
        nude: true,
        tint: true,
        onCopySuccess: this.props.close,
        text: props.text,
        label: props.label,
      };
    } else if (this.props.qaSelector) {
      props["data-qa"] = this.props.qaSelector;
    }

    return props;
  };

  isDisabled = () => {
    return this.props.enabled === false;
  };

  isShare = () => {
    return this.props.type === "share";
  };

  isCopy = () => {
    return this.props.type === "copy";
  };

  isCheckbox = () => {
    return this.props.type === "checkbox";
  };

  isHoverMenu = () => {
    return !!(this.props.submenu && this.props.submenu.length);
  };

  handleClick = (event: SyntheticEvent<>) => {
    event.preventDefault();
    event.stopPropagation();
    if (this.props.click) {
      this.props.click(event);
    }
    if (this.props.close) {
      this.props.close();
    }
  };

  renderChildren = () => {
    if (this.isHoverMenu()) {
      const itemProps = this.itemProps();
      const itemClassName = itemProps.className || "";
      return (
        <Popover
          forceShow={this.state.isForceShowingMenu}
          onRequestHide={this.handleRequestHide}
          placement={itemProps.placement || "auto"}
          offset={itemProps.placement ? "" : "0px, -6px"}
          trigger={itemProps.trigger || "hoverMenu"}
          delayShow={150}
          classNames={{ hoverMenuOpen: style.hoverMenuOpen }}
          display="block"
          modifiers={{
            preventOverflow: {
              boundariesElement: document.getElementById("root"),
            },
          }}
          body={
            <ContextMenuItemList
              id={`${this.props.id || ""}-submenu`}
              close={this.props.close}
              compact={this.props.compact}
              items={this.props.submenu || empty.array}
              className={style.scroll}
            />
          }
        >
          <Flex
            align="center"
            justify="space-between"
            className={classnames(itemClassName, style.hoverMenuLabel)}
          >
            {this.props.label}
            <Icon type="disclosure-contracted" fill="currentColor" />
          </Flex>
        </Popover>
      );
    }

    if (this.isCheckbox()) {
      return (
        <Flex align="center">
          <div className={style.checkmarkContainer}>
            {this.props.checked && (
              <Icon type="checkmark" fill="currentColor" />
            )}
          </div>
          {this.props.label}
        </Flex>
      );
    }

    if (this.props.decoration) {
      return (
        <Flex align="center">
          <div className={style.checkmarkContainer}>
            {this.props.decoration}
          </div>
          {this.props.label}
        </Flex>
      );
    }

    if (this.props.control) {
      return (
        <Flex align="center" justify="space-between">
          <div>{this.props.label}</div>
          <div className={style.control}>{this.props.control}</div>
        </Flex>
      );
    }

    if (this.isCopy() && this.props.text) {
      return (
        <Flex>
          <CopyToClipboard
            disabled={this.isDisabled()}
            toast={this.props.text && `Copied ${this.props.text} to clipboard`}
            value={this.props.text}
          >
            {(clickableRef) => <div ref={clickableRef}>{this.props.label}</div>}
          </CopyToClipboard>
        </Flex>
      );
    }

    return this.props.label;
  };

  render() {
    const itemProps = this.itemProps();
    const itemClassName = itemProps.className || "";
    const isHoverMenu = this.isHoverMenu();
    const isShare = this.isShare();
    const isCheckbox = this.isCheckbox();
    const isDisabled = this.isDisabled();
    const className = classnames(style.item, {
      [itemClassName]: !isHoverMenu,
      [style.disabled]: isDisabled,
      [style.enabled]: !isDisabled,
      [style.compact]: this.props.compact,
      [style.danger]: this.props.danger,
      [style.checkbox]: isCheckbox,
      [style.hoverMenuItem]: isHoverMenu,
    });

    return (
      <React.Fragment>
        {this.props.separator && <div className={style.separator} />}
        {isShare ? (
          <CopyLinkButton
            {...itemProps}
            className={className}
            role="menuitem"
            disabled={isDisabled}
          >
            {(copyLinkButtonProps) => (
              <button
                {...copyLinkButtonProps}
                disabled={copyLinkButtonProps.disabled || isDisabled}
                onKeyDown={this.handleKeyDown}
                className={classnames(className, {
                  [style.disabled]: copyLinkButtonProps.disabled,
                })}
                role="menuitem"
                data-qa={this.props.label}
              >
                {this.renderChildren()}
              </button>
            )}
          </CopyLinkButton>
        ) : (
          <button
            disabled={isDisabled}
            {...itemProps}
            onKeyDown={this.handleKeyDown}
            onClick={this.props.click ? this.handleClick : undefined}
            className={className}
            role="menuitem"
          >
            {this.renderChildren()}
          </button>
        )}
      </React.Fragment>
    );
  }
}
