// @flow
import classnames from "classnames";
import * as React from "react";
import ReactModal from "react-modal";
import Button from "core/components/Button";
import Heading from "core/components/Heading";
import Scrollable from "core/components/Scrollable";
import { isDesktop } from "core/lib/platform";
import style from "./style.scss";

type ContentProps = {
  children: ?React.Node,
  flex?: boolean,
  footer: ?React.Node,
};

export function ModalContent({ children, flex = true, footer }: ContentProps) {
  return (
    <React.Fragment>
      <Scrollable flex={flex}>{children}</Scrollable>
      <div
        onClick={(event: SyntheticEvent<>) => {
          // Swallows the event when clicking on the container and actions that live in the footer.
          event.stopPropagation();
        }}
      >
        {footer}
      </div>
    </React.Fragment>
  );
}

type Props = {
  hideTitleBar?: boolean,
  hideModalHeader?: boolean,
  innerRef?: React.Ref<"div" | "form">,
  children?: React.Node,
  headerLeft?: React.Node,
  headerRight?: React.Node,
  footer?: React.Node,
  size?: "medium" | "large" | "fullScreen",
  className?: string,
  innerClassName?: string,
  headerClassName?: string,
  overlayClassName?: string,
  isOpen?: boolean,
  fixedHeight?: boolean,
  onClose?: (SyntheticEvent<>) => void,
  onRequestClose?: (SyntheticEvent<>) => void,
  onOpen?: () => *,
  onSubmit?: (SyntheticEvent<>) => *,
  onClick?: (SyntheticEvent<>) => void,
  title?: React.Node,
  contentLabel?: string,
  closeIcon?: string,
  hideHeaderMobile?: boolean,
  hideHeaderDesktop?: boolean,
  opacityTransitionOnly?: boolean,
  disableTransitions?: boolean,
  shouldCloseOnEsc?: boolean,
  alwaysHideModalHeader?: boolean,
  qaSelector?: string,
};

type State = { prevIsOpen: boolean, isClosing: boolean };

export default class Modal extends React.Component<Props, State> {
  state = { prevIsOpen: false, isClosing: false };

  static defaultProps = {
    size: "medium",
  };

  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.isOpen !== state.prevIsOpen) {
      return {
        prevIsOpen: props.isOpen,
        isClosing: state.prevIsOpen && !props.isOpen,
      };
    }

    return null;
  }

  handleAfterClose = () => this.setState({ isClosing: false });

  sharedClassNames = () => ({
    [style.withTransitions]: !this.props.disableTransitions,
    [style.opacityTransitionOnly]: this.props.opacityTransitionOnly,
    [style.macOSWithTitleBar]: isDesktop && !this.props.hideTitleBar,
    [style.webWithTitleBarMobile]:
      !isDesktop &&
      this.props.size === "fullScreen" &&
      !this.props.hideHeaderMobile,
    [style.webWithTitleBarDesktop]:
      !isDesktop &&
      this.props.size === "fullScreen" &&
      !this.props.hideHeaderDesktop,
  });

  overlayClassNames = () => {
    return classnames(style.overlay, this.props.overlayClassName, {
      ...this.sharedClassNames(),
    });
  };

  modalClassNames = () => {
    return classnames(style.modal, this.props.className, {
      ...this.sharedClassNames(),
      [style.sizeMedium]: this.props.size === "medium",
      [style.sizeLarge]: this.props.size === "large",
      [style.modalFixedHeight]: this.props.fixedHeight,
    });
  };

  onRequestCloseSwallowEvent = (event: SyntheticEvent<>) => {
    event.stopPropagation();
    if (this.props.onRequestClose) {
      this.props.onRequestClose(event);
    } else if (this.props.onClose) {
      this.props.onClose(event);
    }
  };

  render() {
    let {
      children,
      footer,
      className,
      innerClassName,
      headerClassName,
      onClose,
      onRequestClose,
      onSubmit,
      headerLeft,
      headerRight,
      title,
      contentLabel,
      innerRef,
      fixedHeight,
      size,
      hideHeaderMobile,
      hideHeaderDesktop,
      closeIcon = "close",
      onOpen,
      overlayClassName,
      hideModalHeader,
      disableTransitions,
      opacityTransitionOnly,
      alwaysHideModalHeader,
      qaSelector = "modal",
      ...props
    } = this.props;

    const Wrapper = onSubmit ? "form" : "div";

    if (!headerLeft) {
      headerLeft = onClose && (
        <Button
          nude
          tint
          icon={closeIcon}
          className={style.headerCloseButton}
          onClick={onClose}
          type="button"
          title="Close"
          qaSelector="close-modal-button"
        />
      );
    }

    return this.props.isOpen || this.state.isClosing ? (
      <ReactModal
        {...props}
        closeTimeoutMS={disableTransitions ? 0 : 200}
        contentLabel={typeof title === "string" ? title : contentLabel || ""}
        onAfterOpen={onOpen}
        onAfterClose={this.handleAfterClose}
        onRequestClose={this.onRequestCloseSwallowEvent}
        bodyOpenClassName={style.bodyOpen}
        overlayClassName={{
          base: this.overlayClassNames(),
          afterOpen: style.overlayAfterOpen,
          beforeClose: style.overlayBeforeClose,
        }}
        className={{
          base: this.modalClassNames(),
          afterOpen: style.modalAfterOpen,
          beforeClose: "",
        }}
      >
        <Wrapper
          className={classnames(style.wrapper, innerClassName)}
          onSubmit={onSubmit}
          ref={innerRef}
          data-qa={qaSelector}
        >
          {(headerLeft || title) && !alwaysHideModalHeader && (
            <div
              className={style.header}
              onClick={(event: SyntheticEvent<>) => {
                // Swallows the click event on the row where the X dismiss button lives.
                event.stopPropagation();
              }}
            >
              {headerLeft}
              {title && (
                <div
                  className={classnames(
                    style.headerWithTitle,
                    {
                      [style.headerHidden]: hideModalHeader,
                    },
                    headerClassName
                  )}
                >
                  <Heading level="1" size="m" aria-hidden={hideModalHeader}>
                    {title}
                  </Heading>
                </div>
              )}
            </div>
          )}
          {headerRight}
          {children instanceof ModalContent ? (
            children
          ) : (
            <ModalContent footer={footer}>{children}</ModalContent>
          )}
        </Wrapper>
      </ReactModal>
    ) : null;
  }
}
