// @flow
import * as React from "react";
import ExternalLink from "core/components/ExternalLink";
import Flex from "core/components/Flex";
import Icon from "core/components/Icon";
import Theme from "core/components/Theme";
import * as URLs from "core/lib/urls";
import style from "./style.scss";

type Props = {|
  projectName: string,
|};

export default function ProjectArchivedBanner(props: Props) {
  return (
    <Theme.Consumer>
      {({ themeName }) => (
        <Flex
          align="flex-start"
          className={style.banner}
          grow={false}
          justify="center"
          shrink={false}
        >
          <Icon
            className={style.icon}
            type="archive"
            light={themeName === "light"}
          />
          <div className={style.copy}>
            The <span className={style.projectName}>{props.projectName}</span>{" "}
            project is archived.{" "}
            <ExternalLink
              className={style.learnMore}
              href={URLs.helpArchiveProjectUrl()}
            >
              Learn more
            </ExternalLink>
          </div>
        </Flex>
      )}
    </Theme.Consumer>
  );
}
