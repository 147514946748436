// @flow
import classnames from "classnames";
import * as React from "react";
import Button from "core/components/Button";
import Flex from "core/components/Flex";
import OnboardingHeading from "core/components/OnboardingHeading";
import SerifText from "core/components/SerifText";
import onboardingStyle from "core/components/onboarding.scss";
import * as URLs from "core/lib/urls";
import OnboardingPage from "web/components/Onboarding/Page";
import style from "./style.scss";

export default function ConfirmEmail() {
  return (
    <OnboardingPage currentStep={3} totalSteps={4} dottedLines={3}>
      <Flex align="center" className={style.content} column>
        <div className={style.illustration} />
        <OnboardingHeading className={style.heading} level="1">
          Check your <SerifText>inbox!</SerifText>
        </OnboardingHeading>
        <div
          className={classnames(
            onboardingStyle.largeCopy,
            onboardingStyle.subHeading
          )}
        >
          Follow the link in the email we sent and we will connect you to your
          Abstract Organization.
        </div>
        <div className={style.note}>
          You can also reach out to one of your Organization Admins for an
          invitation.
        </div>
        <div className={style.buttonWrapper}>
          <Button
            className={onboardingStyle.button}
            component="a"
            href={URLs.homeUrl()}
            large
            primary
          >
            Back to Abstract.com
          </Button>
        </div>
      </Flex>
    </OnboardingPage>
  );
}
