// @flow
import classnames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { openSupportWindow } from "abstract-di/actions";
import ButtonLink from "core/components/ButtonLink";
import Flex from "core/components/Flex";
import Heading from "core/components/Heading";
import Icon from "core/components/Icon";
import window from "core/global/window";
import type { State, Dispatch } from "core/types";
import style from "./style.scss";

type NoticeTypes = "allgood" | "warning" | "tint" | "purple";

type OwnProps = {|
  heading?: string,
  body: React.Node,
  icon?: string,
  type?: NoticeTypes,
  iconWrapperClass?: string,
  iconFillColor?: string,
  isError?: boolean,
  className?: string,
  contactSupport?: boolean,
  link?: {
    text: string,
    path: string,
  },

  // adds a negative margin so that the FormNotice content aligns rather than
  // the boundary of the background color
  alignContent?: boolean,
|};

type DispatchProps = {|
  openSupportTicket: () => void,
|};

type Props = {
  ...OwnProps,
  ...DispatchProps,
};

export function BaseFormNotice(props: Props) {
  const {
    heading,
    body,
    icon,
    type,
    iconWrapperClass,
    iconFillColor,
    isError,
    className,
    contactSupport,
    alignContent,
    link,
  } = props;
  const noticeIcon = isError ? "error" : icon || "warning";
  const typeStyle = type && style[type];

  return (
    <Flex
      className={classnames(className, style.formNotice, {
        [style.error]: isError,
        [style.alignContent]: alignContent,
      })}
    >
      <Flex
        justify="center"
        align="center"
        className={classnames(style.iconWrapper, typeStyle, iconWrapperClass)}
        grow={false}
        shrink={false}
      >
        <Icon type={noticeIcon} fill={iconFillColor || "#FFFFFF"} />
      </Flex>
      <div>
        {heading && (
          <Heading level="5" size="m">
            {heading}
          </Heading>
        )}
        <div className={style.body}>{body}</div>
        {(props.contactSupport || (link && link.text && link.path)) && (
          <ButtonLink
            onClick={() => {
              contactSupport
                ? props.openSupportTicket()
                : window.open(link && link.path);
            }}
            className={style.link}
          >
            {contactSupport ? "Contact Support" : link && link.text}
          </ButtonLink>
        )}
      </div>
    </Flex>
  );
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    openSupportTicket: () => dispatch(openSupportWindow()),
  };
}

export default connect<Props, OwnProps, _, DispatchProps, State, Dispatch>(
  undefined,
  mapDispatchToProps
)(BaseFormNotice);
