// @flow
/* global localStorage */
import classnames from "classnames";
import * as React from "react";
import Button from "../Button";
import style from "./style.scss";

type Props = {
  id?: string,
  isGrid?: boolean,
  className?: string,
  disabled?: boolean,
  onToggleList: () => void,
  onToggleGrid: () => void,
};

export default function ListDisplayToggle(props: Props) {
  const { id, className, disabled, onToggleGrid, onToggleList } = props;
  const [isGrid, setIsGrid] = React.useState(props.isGrid);
  // If `props.isGrid` exists, that means default view type is set/read from somewhere else.
  const key =
    id && props.isGrid === undefined ? `list-grid-view-type-${id}` : undefined;

  React.useEffect(() => {
    if (key) {
      const viewType = localStorage.getItem(key);
      if (viewType) {
        viewType === "grid" ? onToggleGrid() : onToggleList();
        setIsGrid(viewType === "grid");
      } else {
        // set default viewtype to grid
        setIsGrid(true);
        onToggleGrid();
      }
    }
  }, [key, onToggleGrid, onToggleList]);

  const saveViewType = function (viewType) {
    if (key) {
      localStorage.setItem(key, viewType);
    }
  };

  return (
    <div className={classnames(style.toggle, className)}>
      <Button
        nude
        onClick={() => {
          onToggleGrid();
          setIsGrid(true);
          saveViewType("grid");
        }}
        icon="grid-larger"
        disabled={disabled}
        className={classnames(style.button, {
          [style.active]: isGrid,
        })}
        qaSelector="gridViewToggle"
      />
      <Button
        nude
        onClick={() => {
          onToggleList();
          setIsGrid(false);
          saveViewType("list");
        }}
        icon="list"
        disabled={disabled}
        className={classnames(style.button, {
          [style.active]: !isGrid,
        })}
        qaSelector="listViewToggle"
      />
    </div>
  );
}
