// @flow
import classnames from "classnames";
import get from "lodash/get";
import { type ResizeDirection } from "re-resizable";
import * as React from "react";
import Resizable, { type ResizableDelta } from "react-rnd";
import AnnotationBubble from "core/components/AnnotationBubble";
import useOuterClick from "core/hooks/useOuterClick";
import type { Annotation as AnnotationType, Size } from "core/types";
import style from "./style.scss";

const resizeHandlerClasses = {
  bottomRight: style.bottomRightHandler,
};

type UpdateParams = {
  ...$Exact<AnnotationType>,
  width: number,
  height: number,
  x: number,
  y: number,
};

type Props = {
  onUpdate?: (params: UpdateParams) => void,
  annotation: AnnotationType,
  number?: number,
  scale: number,
  selected?: boolean,
  editing: boolean,
  showBubble?: boolean,
  minWidth?: number,
  minHeight?: number,
  alwaysShowBoundary?: boolean,
  innerRef?: React.Ref<"div">,
  onOuterClick: (event: MouseEvent) => void,
  qaSelector?: string,
};

type State = Size & {
  x: number,
  y: number,
  moving: boolean,
};

class Annotation extends React.Component<Props, State> {
  static defaultProps = {
    scale: 100,
    showBubble: true,
  };

  resizable: any;

  defaultBounds: {
    x: number,
    y: number,
    width: number | string,
    height: number | string,
  };

  state = {
    moving: false,
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      moving: false,
      width: props.annotation.width,
      height: props.annotation.height,
      x: props.annotation.x,
      y: props.annotation.y,
    };

    const scaleFactor = props.scale / 100;
    this.defaultBounds = {
      width: Math.round(this.state.width * scaleFactor),
      height: Math.round(this.state.height * scaleFactor),
      x: Math.round(this.state.x * scaleFactor),
      y: Math.round(this.state.y * scaleFactor),
    };
  }

  componentDidUpdate(prevProps: Props) {
    const scaleChanged = prevProps.scale !== this.props.scale;

    // Resizable needs to have it's position updated manually when the scale
    // changes as there is no x / y prop, only an initial state.
    if (scaleChanged) {
      const scaleFactor = this.props.scale / 100;

      this.defaultBounds = {
        width: Math.round(this.state.width * scaleFactor),
        height: Math.round(this.state.height * scaleFactor),
        x: Math.round(this.state.x * scaleFactor),
        y: Math.round(this.state.y * scaleFactor),
      };

      if (this.resizable) {
        // unfortunately react-rnd does not include an API to set both at once
        this.resizable.updateSize({
          width: Math.round(this.state.width * scaleFactor),
          height: Math.round(this.state.height * scaleFactor),
        });
        this.resizable.updatePosition({
          x: Math.round(this.state.x * scaleFactor),
          y: Math.round(this.state.y * scaleFactor),
        });
      }
    }
  }

  handleResize = (
    event: Event,
    direction: ResizeDirection,
    refToElement: HTMLElement,
    delta: ResizableDelta
  ) => {
    event.preventDefault();
    event.stopPropagation();
    const scaleFactor = 100 / this.props.scale;
    const parent = get(refToElement, "parentNode.parentNode");
    if (!parent) {
      return;
    }

    const parentBounds = parent.getBoundingClientRect();
    const bounds = refToElement.getBoundingClientRect();

    this.setState({
      width: Math.round(bounds.width * scaleFactor),
      height: Math.round(bounds.height * scaleFactor),
      x: Math.round((bounds.left - parentBounds.left) * scaleFactor),
      y: Math.round((bounds.top - parentBounds.top) * scaleFactor),
    });
  };

  handleMove = (event: Event, data: { x: number, y: number }) => {
    event.preventDefault();
    event.stopPropagation();
    const scaleFactor = 100 / this.props.scale;

    this.setState({
      moving: true,
      x: Math.round(data.x * scaleFactor),
      y: Math.round(data.y * scaleFactor),
    });
  };

  handleUpdated = (event: Event) => {
    const { onUpdate } = this.props;
    event.preventDefault();
    event.stopPropagation();

    this.setState({ moving: false });

    const scaleFactor = this.props.scale / 100;
    this.defaultBounds = {
      width: Math.round(this.state.width * scaleFactor),
      height: Math.round(this.state.height * scaleFactor),
      x: Math.round(this.state.x * scaleFactor),
      y: Math.round(this.state.y * scaleFactor),
    };

    if (onUpdate) {
      onUpdate({
        ...this.props.annotation,
        width: Math.round(this.state.width),
        height: Math.round(this.state.height),
        x: Math.round(this.state.x),
        y: Math.round(this.state.y),
      });
    }
  };

  renderGrips() {
    const scaleFactor = this.props.scale / 100;
    const showVerticalMidGrips = this.state.height * scaleFactor >= 64;
    const showHorizonatalMidGrips = this.state.width * scaleFactor >= 64;
    const grips = [
      <div
        key="topLeft"
        className={classnames(style.grip, style.top, style.left)}
      />,
      <div
        key="topRight"
        className={classnames(style.grip, style.top, style.right)}
      />,
      <div
        key="bottomLeft"
        className={classnames(style.grip, style.bottom, style.left)}
      />,
    ];

    if (showVerticalMidGrips) {
      grips.push(
        <div
          key="right"
          className={classnames(style.grip, style.right, style.center)}
        />
      );
      grips.push(
        <div
          key="left"
          className={classnames(style.grip, style.left, style.center)}
        />
      );
    }

    if (showHorizonatalMidGrips) {
      grips.push(
        <div
          key="top"
          className={classnames(style.grip, style.top, style.center)}
        />
      );
      grips.push(
        <div
          key="bottom"
          className={classnames(style.grip, style.bottom, style.center)}
        />
      );
    }

    return grips;
  }

  get isSmall(): boolean {
    const scaleFactor = this.props.scale / 100;

    if (!this.props.annotation.width || !this.props.annotation.height) {
      return true;
    }

    return (
      this.state.height * scaleFactor < 24 &&
      this.state.width * scaleFactor < 24
    );
  }

  wrapAnnotation(children: React.Node) {
    const {
      annotation,
      scale,
      number,
      editing,
      selected,
      onUpdate,
      minWidth,
      minHeight,
      showBubble,
      alwaysShowBoundary,
      qaSelector,
      onOuterClick,
      ...rest
    } = this.props;
    const scaleFactor = scale / 100;

    if (this.props.editing) {
      return (
        <Resizable
          ref={(ref) => (this.resizable = ref)}
          default={this.defaultBounds}
          z={editing ? 1000 : 1}
          onResize={this.handleResize}
          onResizeStop={this.handleUpdated}
          onDrag={this.handleMove}
          onDragStop={this.handleUpdated}
          className={style.wrapper}
          resizeHandlerClasses={resizeHandlerClasses}
          bounds="parent"
          {...rest}
        >
          {children}
        </Resizable>
      );
    }

    const positionStyle = {
      position: "absolute",
      width: Math.round((annotation.width || 0) * scaleFactor),
      height: Math.round((annotation.height || 0) * scaleFactor),
      left: Math.round((annotation.x || 0) * scaleFactor),
      top: Math.round((annotation.y || 0) * scaleFactor),
    };

    return (
      <div {...rest} style={positionStyle} className={style.wrapper}>
        {children}
      </div>
    );
  }

  render() {
    const { number, editing, showBubble, selected, alwaysShowBoundary } =
      this.props;
    const showBox = alwaysShowBoundary || !this.isSmall || editing;
    const classes = classnames(style.annotation, {
      [style.moving]: this.state.moving,
      [style.selected]: selected,
      [style.editing]: editing,
      [style.borderHidden]: !showBox,
    });

    return this.wrapAnnotation(
      <div
        className={classes}
        ref={this.props.innerRef}
        data-qa={this.props.qaSelector}
      >
        {editing && showBox && this.renderGrips()}
        {showBubble && number !== undefined && (
          <AnnotationBubble
            number={number}
            animated={!editing}
            centered={!showBox}
            className={style.bubble}
          />
        )}
      </div>
    );
  }
}

export default function AnnotationWithOuterClick(props: Props) {
  const ref = useOuterClick({
    isOpen: props.selected || false,
    onOuterClick: props.onOuterClick,
  });

  return <Annotation {...props} innerRef={ref} />;
}
