// @flow
import * as React from "react";
import Button from "core/components/Button";
import Heading from "core/components/Heading";
import style from "./style.scss";

export default function FilteredItemsFooter(props: {
  onClearFilters: () => void,
  onClearAuthorFilter: () => void,
  onClearSearchFilter: () => void,
  numberOfVisibleCollections: number,
  totalNumberOfCollections: ?number,
  selectedAuthorName: ?string,
  searchTerm: ?string,
}) {
  const maybeAuthorMessage = props.selectedAuthorName && (
    <Button
      link
      onClick={props.onClearAuthorFilter}
      title="Click to remove filter"
      tooltip={{ placement: "top" }}
    >
      author {props.selectedAuthorName}
    </Button>
  );

  const maybeSearchMessage = props.searchTerm && (
    <React.Fragment>
      {maybeAuthorMessage && "and "}
      <Button
        link
        onClick={props.onClearSearchFilter}
        title="Click to remove filter"
        tooltip={{ placement: "top" }}
      >
        “{props.searchTerm}”
      </Button>
    </React.Fragment>
  );

  return (
    <div className={style.filteredItemsDisclaimer}>
      <p className={style.filteredNumberOfItems}>
        Displaying {props.numberOfVisibleCollections} of{" "}
        {props.totalNumberOfCollections} matching {maybeAuthorMessage}{" "}
        {maybeSearchMessage}
      </p>
      <Heading className={style.filteredHeading} level="2" size="m">
        Didn’t find what you’re looking for?
      </Heading>
      <Button onClick={props.onClearFilters}>Clear Filters</Button>
    </div>
  );
}
