// @flow
import classnames from "classnames";
import * as React from "react";
import { Waypoint } from "react-waypoint";
import style from "./style.scss";

type Props = {
  children?: React.Node,
  flex?: boolean,
  className?: string,
  innerRef?: React.Ref<"div">,
  showTopBorder?: boolean,
  showBottomBorder?: boolean,
  qaSelector?: string,
};

type State = {|
  isScrolledTop: boolean,
  isScrolledBottom: boolean,
|};

// Note: this component is purposefully a class so that it can be ref'd
export default class Scrollable extends React.Component<Props, State> {
  state = {
    isScrolledTop: false,
    isScrolledBottom: false,
  };

  render() {
    const {
      children,
      flex,
      className,
      innerRef,
      showTopBorder,
      showBottomBorder,
      qaSelector,
      ...props
    } = this.props;

    return (
      <div
        className={classnames(className, style.scrollable, {
          [style.flex]: flex,
          [style.topAndBottomBorder]:
            showTopBorder &&
            showBottomBorder &&
            this.state.isScrolledTop &&
            this.state.isScrolledBottom,
          [style.topBorder]: showTopBorder && this.state.isScrolledTop,
          [style.bottomBorder]: showBottomBorder && this.state.isScrolledBottom,
        })}
        ref={innerRef}
        {...props}
        data-qa={qaSelector}
      >
        {showTopBorder && (
          <Waypoint
            onPositionChange={(e) => {
              const isScrolledTop = e.currentPosition === "above";
              if (isScrolledTop !== this.state.isScrolledTop) {
                this.setState({ isScrolledTop });
              }
            }}
          />
        )}

        {children}

        {showBottomBorder && (
          <Waypoint
            onPositionChange={(e) => {
              const isScrolledBottom = e.currentPosition === "below";
              if (isScrolledBottom !== this.state.isScrolledBottom) {
                this.setState({ isScrolledBottom });
              }
            }}
          />
        )}
      </div>
    );
  }
}
