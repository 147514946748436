// @flow
import * as React from "react";
import { connect } from "react-redux";
import { markNotificationsAsRead } from "core/actions/notifications";
import { removeQuery, replace } from "core/lib/location";
import type { Dispatch, ReactRouterLocation } from "core/types";

type OwnProps = {|
  children: React.Node,
  location: ReactRouterLocation,
|};

type DispatchProps = {|
  markAsRead: (notificationId: string) => Promise<void>,
|};

type Props = {
  ...OwnProps,
  ...DispatchProps,
};

class NotificationReader extends React.Component<Props> {
  componentDidMount() {
    const { notificationId } = this.props.location.query;
    if (notificationId) {
      this.props.markAsRead(notificationId);
    }
  }

  render() {
    return this.props.children;
  }
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    markAsRead: async (notificationId: string) => {
      const response = await dispatch(
        markNotificationsAsRead({ notificationIds: [notificationId] })
      );
      if (response) {
        replace(removeQuery("notificationId"));
      }
    },
  };
}

export default connect<Props, OwnProps, _, DispatchProps, _, Dispatch>(
  null,
  mapDispatchToProps
)(NotificationReader);
