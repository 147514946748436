// @flow
import * as React from "react";
import Button from "core/components/Button";
import Card from "core/components/Card";
import Flex from "core/components/Flex";
import Heading from "core/components/Heading";
import OrganizationLogo from "core/components/OrganizationLogo";
import type { Organization } from "core/types";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  mobile?: boolean,
  organization: Organization,
|};

export type StateProps = {|
  loading: boolean,
  success: boolean,
|};

export type DispatchProps = {|
  requestAccessToOrganization: () => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

class RequestListItem extends React.Component<Props> {
  getButtonText = () => {
    if (this.props.loading) {
      return "Requesting access…";
    }

    if (this.props.success) {
      return "Request sent";
    }

    return "Request access";
  };

  render() {
    const {
      loading,
      mobile,
      organization,
      requestAccessToOrganization,
      success,
    } = this.props;

    return (
      <div className={style.organizationCardWrapper}>
        <Card
          className={style.organizationCard}
          innerClassName={style.organizationCardInner}
        >
          <Flex align="center">
            <OrganizationLogo
              alt={organization.name}
              className={style.organizationLogo}
              size={mobile ? 36 : 48}
              src={organization.logoUrl}
            />
            <Heading className={style.organizationName} level="3" size="l">
              {organization.name}
            </Heading>
            <Button
              className={style.requestAccess}
              disabled={loading || success}
              icon={loading ? "spinner" : undefined}
              large={!mobile}
              onClick={requestAccessToOrganization}
              primary={!success}
            >
              {this.getButtonText()}
            </Button>
          </Flex>
        </Card>
      </div>
    );
  }
}

export default connector(RequestListItem);
