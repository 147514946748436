// @flow
import { schema, normalize } from "normalizr";
import { branch, project } from "core/schema";
import { envelopeSchema } from "./envelope";

const quickJumpResponseSchema = envelopeSchema(
  new schema.Object({
    branches: [branch],
    projects: [project],
  })
);

export function normalizeQuickJumpResponse(response: any) {
  return normalize(response, quickJumpResponseSchema);
}
