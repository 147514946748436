// @flow
import classnames from "classnames";
import * as React from "react";
import { Button as ReakitButton } from "reakit/Button";
import Button from "core/components/Button";
import NoNotifications from "core/components/Empty/NoNotifications";
import FloatingMenu from "core/components/FloatingMenu";
import Head from "core/components/Head";
import Icon from "core/components/Icon";
import Loaded from "core/components/Loaded";
import MountProfiler from "core/components/MountProfiler";
import { isWeb } from "core/lib/platform";
import type { LoadNotificationsParams } from "core/requests/notifications";
import type { Notification, Organization } from "core/types";
import NotificationsHeader from "./NotificationsHeader";
import NotificationsList from "./NotificationsList";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  className?: string,
  lightIcon?: boolean,
  organizationId?: string,
|};

export type DispatchProps = {|
  closeNotifications: () => void,
  filterNotificationsByOrg: (organizationId: ?string) => void,
  markNotifications: (id: string[], readState?: "read" | "unread") => void,
  markAllNotificationsAsRead: () => void,
  loadNotifications: (loadParams?: LoadNotificationsParams) => void,
  onLoad: () => void,
  openNotifications: () => void,
|};

export type StateProps = {|
  allNotificationsLoaded: boolean,
  currentOrganizationFilter: ?string,
  currentUserId: ?string,
  isFirstLoading: boolean,
  isLoadingNotifications: boolean,
  isOnline: boolean,
  isOpen: boolean,
  hasUnreadNotifications: boolean,
  oldestNotificationCreatedAt: string,
  organizationUnreads: { [id: string]: number },
  organizations: Organization[],
  readNotifications: Notification[],
  totalUnreads: number,
  unreadNotifications: Notification[],
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

const publicUrl = process.env.PUBLIC_URL || "";
const faviconSizes = [16, 32, 48, 64, 96, 144, 192].map(
  (size) => `${size}x${size}`
);

class Notifications extends React.Component<Props> {
  render() {
    const hasNotifications =
      this.props.readNotifications.length > 0 ||
      this.props.unreadNotifications.length > 0;

    const faviconPrefix = this.props.hasUnreadNotifications
      ? "favicon-unread"
      : "favicon";

    const faviconElements = faviconSizes.map((size) => (
      <link
        href={`${publicUrl}/${faviconPrefix}-${size}.png`}
        key={`${faviconPrefix}-${size}`}
        rel="icon"
        sizes={size}
        type="image/png"
      />
    ));

    return (
      <React.Fragment>
        {isWeb ? <Head>{faviconElements}</Head> : null}
        <FloatingMenu
          body={
            <React.Fragment>
              <NotificationsHeader
                currentOrganizationFilter={this.props.currentOrganizationFilter}
                filterNotificationsByOrg={this.props.filterNotificationsByOrg}
                markAllNotificationsAsRead={
                  this.props.markAllNotificationsAsRead
                }
                oldestNotificationCreatedAt={
                  this.props.oldestNotificationCreatedAt
                }
                organizations={this.props.organizations}
                organizationUnreads={this.props.organizationUnreads}
                totalUnreads={this.props.totalUnreads}
                unreadNotifications={this.props.unreadNotifications}
              />
              <Loaded
                loading={this.props.isFirstLoading}
                title="Loading notifications…"
              >
                {hasNotifications && this.props.currentUserId ? (
                  <MountProfiler
                    id="NotificationsList"
                    params={{ userId: this.props.currentUserId }}
                  >
                    <NotificationsList
                      allNotificationsLoaded={this.props.allNotificationsLoaded}
                      currentOrganizationFilter={
                        this.props.currentOrganizationFilter
                      }
                      currentUserId={this.props.currentUserId}
                      isLoadingNotifications={this.props.isLoadingNotifications}
                      isOnline={this.props.isOnline}
                      loadNotifications={this.props.loadNotifications}
                      markNotifications={this.props.markNotifications}
                      oldestNotificationCreatedAt={
                        this.props.oldestNotificationCreatedAt
                      }
                      onNotificationClick={(id) => {
                        this.props.markNotifications([id]);
                        this.props.closeNotifications();
                      }}
                      readNotifications={this.props.readNotifications}
                      unreadNotifications={this.props.unreadNotifications}
                    />
                  </MountProfiler>
                ) : (
                  <NoNotifications />
                )}
              </Loaded>
            </React.Fragment>
          }
          floatingClassName={style.notificationsListFlyover}
          forceHide={!this.props.isOpen}
          forceShow={this.props.isOpen}
          onRequestHide={this.props.closeNotifications}
          onAfterShow={this.props.openNotifications}
          placement="bottom"
        >
          {(showPopover, ref, _, buttonProps) => (
            <div
              className={classnames(style.buttonWrapper, this.props.className)}
            >
              {this.props.hasUnreadNotifications ? (
                <React.Fragment>
                  <ReakitButton
                    {...buttonProps}
                    className={style.hasNotificationsButton}
                    onClick={showPopover}
                    ref={ref}
                    title="Notifications"
                    data-qa="notificationsIcon"
                  >
                    <Icon
                      className={style.buttonBackground}
                      fill="currentColor"
                      type="notifications-button-background"
                    />
                    <Icon
                      className={style.bellIcon}
                      type="notifications-default"
                      light
                    />
                  </ReakitButton>
                  <div className={style.notificationDot} />
                </React.Fragment>
              ) : (
                <Button
                  {...buttonProps}
                  icon="notifications-default"
                  innerRef={ref}
                  light={this.props.lightIcon}
                  onClick={showPopover}
                  qaSelector="notificationsIcon"
                  title="Notifications"
                  nude
                />
              )}
            </div>
          )}
        </FloatingMenu>
      </React.Fragment>
    );
  }
}

export default connector(Notifications);
