// @flow
import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "core/components/Button";
import NoOAuthApplications from "core/components/Empty/NoOAuthApplications";
import FormHeader from "core/components/FormHeader";
import FormSection from "core/components/FormSection";
import Loaded from "core/components/Loaded";
import Media from "core/components/Media";
import SettingsForm from "core/components/SettingsForm";
import { getUsernameOrganization } from "core/selectors/organizations";
import {
  OAuthApplicationCreateRequest,
  OAuthApplicationsRequest,
} from "web/requests/oAuthApplications";
import { getAllOAuthApplications } from "web/selectors/oAuthApplications";
import Form from "./Form";
import Item from "./Item";

const OAuthApplications = () => {
  const dispatch = useDispatch();

  const isCreating = useSelector((state) =>
    OAuthApplicationCreateRequest.isLoadingStrict(state, {})
  );
  const createErrors = useSelector((state) =>
    OAuthApplicationCreateRequest.validationErrors(state, {})
  );
  const isLoadingApplications = useSelector((state) =>
    OAuthApplicationsRequest.isFirstLoading(state, {})
  );
  const applications = useSelector(getAllOAuthApplications);
  const usernameOrganization = useSelector(getUsernameOrganization) || {};
  const organizationId = usernameOrganization.id;

  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (params) => {
    const data = await dispatch(
      OAuthApplicationCreateRequest.perform({
        params: { ...params, organizationId },
      })
    );

    if (data) {
      setSuccessMessage("Application created");
      handleClose();
    }
  };

  const loadApplications = useCallback(async () => {
    if (organizationId) {
      const data = await dispatch(
        OAuthApplicationsRequest.perform({
          params: { organizationId },
        })
      );

      if (!data) {
        setErrorMessage("An error occurred while loading applications");
      }
    }
  }, [dispatch, organizationId]);

  const handleOpen = () => {
    setIsDialogOpened(true);
  };

  const handleClose = () => {
    setIsDialogOpened(false);
  };

  const clearMessages = () => {
    setSuccessMessage("");
    setErrorMessage("");
  };

  useEffect(() => {
    loadApplications();
  }, [loadApplications]);

  return (
    <SettingsForm
      documentTitle="OAuth Applications"
      header={
        <Media desktop>
          {(desktop) => (
            <FormHeader
              flashBanner
              success={!errorMessage}
              successMessage={successMessage}
              error={errorMessage}
              mobile={!desktop}
              heading="OAuth Applications"
              onBannerHide={clearMessages}
            >
              <Button primary onClick={handleOpen}>
                Create Application
              </Button>
              <Form
                isOpen={isDialogOpened}
                isSubmitting={isCreating}
                onSubmit={handleSubmit}
                onClose={handleClose}
                errors={createErrors}
              />
            </FormHeader>
          )}
        </Media>
      }
    >
      <Loaded
        loading={isLoadingApplications}
        flex
        title="Loading OAuth Applications…"
      >
        {() => {
          if (!applications.length) {
            return <NoOAuthApplications flex />;
          }

          return (
            <FormSection heading="Applications">
              {applications.map((app) => (
                <Item key={app.id} app={app} />
              ))}
            </FormSection>
          );
        }}
      </Loaded>
    </SettingsForm>
  );
};

export default OAuthApplications;
