// @flow
import {
  SortableElement,
  SortableHandle,
} from "@elasticprojects/react-sortable-hoc";
import classnames from "classnames";
import React from "react";
import AuthorMeta from "core/components/AuthorMeta";
import bigPlusImage from "core/components/CollectionItems/images/plus.svg";
import CollectionMenu from "core/components/CollectionMenu";
import emptyCollectionImage from "core/components/Empty/images/collection.svg";
import PreviewImage from "core/components/PreviewImage";
import PreviewLoader from "core/components/PreviewLoader";
import { Abstract } from "core/lib/abstract";
import { V3Link as Link } from "core/lib/router";
import { collectionLocation } from "core/lib/routes";
import * as Branch from "core/models/branch";
import type {
  Collection,
  User,
  Branch as TBranch,
  Size,
  LocationDescriptor,
} from "core/types";
import connector from "./connector";
import style from "./style.scss";

const GrippableDiv = SortableHandle("div");

export type OwnProps = {|
  ...Abstract.CollectionDescriptor,
  className?: string,
  titleClass?: string,
  useShorthandDate?: boolean,
  mobile?: boolean,
  hideBranchName?: boolean,
  returnTo?: LocationDescriptor,
  index?: number, // index is used by react-sortable-hoc for reordering
  qaSelector?: string,
|};

export type StateProps = {|
  abstractCollection: Collection,
  layerId: ?string,
  fileId: ?string,
  sha: string,
  collectionAuthor: ?User,
  branch: ?TBranch,
  canEdit: boolean,
  canReorder: boolean,
|};

export type DispatchProps = {|
  onLoad: () => Promise<void>,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

function determineBestClassForImageDimensions({ width, height }) {
  const widthToHeightRatio = width / height;

  if (widthToHeightRatio < 0.5) {
    return style.tallPreview;
  }

  if (widthToHeightRatio > 2) {
    return style.widePreview;
  }

  // TODO: Increase to 1600x1600 for 2x previews or remove bigPreview?
  if (width > 800 || height > 800) {
    return style.bigPreview;
  }

  return style.defaultPreview;
}

export function Placeholder() {
  return (
    <div
      className={classnames(style.placeholderLink, style.thumbnailLink)}
      data-qa="collection-thumbnail-placeholder"
    >
      <div
        className={classnames(
          style.borderRadius,
          style.previewImage,
          style.placeholderImage,
          style.withShimmer
        )}
      />
      <div
        className={classnames(
          style.name,
          style.placeholderName,
          style.withShimmer
        )}
      />
      <div className={classnames(style.placeholderAuthor, style.withShimmer)} />
    </div>
  );
}

export function CreateCollectionPlaceholder(props: {
  onClick?: () => void,
  disabled: boolean,
}) {
  return (
    <button
      className={classnames(
        style.createCollectionPlaceholder,
        style.thumbnailLink,
        { [style.disabled]: props.disabled }
      )}
      onClick={props.onClick}
      type="button"
      data-qa="createCollectionButton"
    >
      <div
        className={classnames(
          style.borderRadius,
          style.createCollectionPlaceholderImage,
          style.previewImage,
          style.placeholderImage
        )}
      >
        <img
          alt="New Collection"
          className={style.createCollectionPlus}
          src={bigPlusImage}
        />
      </div>
      <div className={classnames(style.name, style.placeholderName)}>
        New Collection
      </div>
      <div className={classnames(style.placeholderAuthor)} />
    </button>
  );
}

class CollectionThumbnail extends React.Component<Props> {
  isBranchMissing(): ?boolean {
    if (this.props.branch) {
      return Branch.isDeleted(this.props.branch);
    }
  }

  renderThumbnail = (
    src: string,
    loading: boolean,
    error: ?string,
    imageDimensions: Size
  ) => {
    return (
      <div className={style.thumbnailContainer}>
        <div className={classnames(style.imageSection, style.borderRadius)}>
          <div
            className={classnames(
              style.thumbnailImageWrapper,
              style.borderRadius
            )}
          >
            {this.isBranchMissing() ? (
              <React.Fragment>
                {/* When there's no src prop, this will generate a grey preview image. */}
                <PreviewImage
                  className={classnames(style.previewImage, style.borderRadius)}
                  errorText={error}
                  alt=""
                />
                <div className={style.floatingLoadingText}>
                  {this.isBranchMissing()
                    ? "Branch is unavailable"
                    : "Loading…"}
                </div>
              </React.Fragment>
            ) : (
              <PreviewImage
                src={src}
                errorText={error}
                alt={this.props.abstractCollection.name}
                width={imageDimensions.width}
                className={classnames(style.previewImage, style.borderRadius)}
                imageClassName={classnames(
                  determineBestClassForImageDimensions(imageDimensions),
                  style.borderRadius
                )}
              />
            )}
          </div>
        </div>
        <div className={style.footerSection}>
          <div
            className={classnames(style.name, this.props.titleClass)}
            title={this.props.abstractCollection.name}
          >
            {this.props.abstractCollection.name}
          </div>
          {this.props.branch && !this.props.hideBranchName && (
            <p className={style.branchName}>{this.props.branch.name}</p>
          )}
          {this.props.collectionAuthor && (
            <AuthorMeta
              useShorthandDate={this.props.useShorthandDate}
              avatarClassName={style.authorAvatar}
              user={this.props.collectionAuthor}
              date={this.props.abstractCollection.updatedAt}
            />
          )}
        </div>
      </div>
    );
  };

  render() {
    const Wrapper =
      this.props.canEdit && this.props.canReorder ? GrippableDiv : "div";

    return (
      <CollectionMenu collection={this.props.abstractCollection}>
        {(showMenu, ref) => (
          <Wrapper className={style.collectionThumbnailWrapper}>
            <Link
              // When a link is clicked and dragged, it overrides the sortableHandle's
              // drag interaction and does some browser behavior. This prevents that.
              onDragStart={(event) => {
                event.preventDefault();
                return false;
              }}
              key={this.props.abstractCollection.id}
              to={collectionLocation(
                this.props.abstractCollection.projectId,
                this.props.abstractCollection.branchId,
                this.props.abstractCollection.id
              )}
              onContextMenu={showMenu}
              className={classnames(style.thumbnailLink, this.props.className, {
                [style.disabled]: this.isBranchMissing(),
                [style.mobile]: this.props.mobile,
              })}
              data-qa={this.props.qaSelector}
            >
              {this.props.layerId && this.props.fileId ? (
                <PreviewLoader
                  projectId={this.props.abstractCollection.projectId}
                  commitSha={this.props.sha}
                  fileId={this.props.fileId}
                  layerId={this.props.layerId}
                  scale={0.5}
                >
                  {this.renderThumbnail}
                </PreviewLoader>
              ) : (
                this.renderThumbnail(emptyCollectionImage, false, undefined, {
                  width: 100,
                  height: 100,
                })
              )}
            </Link>
            <div className={style.collectionThumbnailActions} ref={ref} />
          </Wrapper>
        )}
      </CollectionMenu>
    );
  }
}

export const SortableCollectionThumbnail = connector(
  SortableElement(CollectionThumbnail)
);

export default connector(CollectionThumbnail);
