// @flow
import * as React from "react";
import InputRadio from "core/components/InputRadio";
import { ModalContent } from "core/components/Modal";
import RadioGroup from "core/components/RadioGroup";
import { Wrapper, Header, Content, Button, Footer } from "../shared";
import style from "./style.scss";

type Props = {|
  role: string,
  onRequestLink: () => void,
  onRequestEmail: () => void,
  onDismiss: () => void,
  showSelectScreen: () => void,
|};

export default function RoleScreen({
  role,
  onRequestLink,
  onRequestEmail,
  onDismiss,
  showSelectScreen,
}: Props) {
  const [selectedInviteType, setSelectedInviteType] =
    React.useState("inviteLink");

  const handleInviteTypeChange = React.useCallback(
    ({ target }: SyntheticInputEvent<>) => {
      setSelectedInviteType(target.value);
    },
    []
  );

  const onNext = React.useCallback(() => {
    if (selectedInviteType === "inviteLink") {
      onRequestLink();
    } else {
      onRequestEmail();
    }
  }, [onRequestEmail, onRequestLink, selectedInviteType]);

  const roleLabel = role === "viewer" ? "viewers" : "contributors";

  return (
    <ModalContent
      footer={
        <React.Fragment>
          <Footer>
            <Button
              onClick={showSelectScreen}
              qaSelector="backInviteTypeButton"
            >
              Back
            </Button>
            <div>
              <Button
                onClick={onDismiss}
                qaSelector="cancelInviteTypeSelection"
              >
                Cancel
              </Button>
              <Button
                primary
                onClick={onNext}
                className={style.nextButton}
                qaSelector="nextScreenInviteTypeButton"
              >
                Next
              </Button>
            </div>
          </Footer>
        </React.Fragment>
      }
    >
      <Wrapper>
        <Content>
          <Header>
            <h3>Invite {roleLabel} using:</h3>
          </Header>
          <RadioGroup
            name="inviteType"
            value={selectedInviteType}
            onChange={handleInviteTypeChange}
          >
            <InputRadio
              key="inviteLink"
              label={
                <div className={style.details}>
                  <h3>Invite link</h3>
                </div>
              }
              className={style.inviteTypeInput}
              labelClass={style.inviteType}
              value="inviteLink"
              qaSelector="inviteLinkRadioButton"
            />
            <InputRadio
              key="email"
              label={
                <div className={style.details}>
                  <h3>Email</h3>
                </div>
              }
              className={style.inviteTypeInput}
              labelClass={style.inviteType}
              value="email"
              qaSelector="emailRadioButton"
            />
          </RadioGroup>
        </Content>
      </Wrapper>
    </ModalContent>
  );
}
