// @flow
import empty from "empty";
import { connect } from "react-redux";
import { getCurrentUserId } from "abstract-di/selectors";
import { ProjectReviewsFetchRequest } from "core/requests/reviews";
import { getBranchesForProject } from "core/selectors/branches";
import {
  getRequestedBranchReviewsForUser,
  getReviewRequests,
} from "core/selectors/reviews";
import type { State, Dispatch } from "core/types";
import type { OwnProps, StateProps, DispatchProps, Props } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { projectId } = props;

  const branches = getBranchesForProject(state, { projectId });
  const userId = getCurrentUserId(state);

  return {
    branches,
    reviewRequests: getReviewRequests(state, { projectId }),
    branchReviews: userId
      ? getRequestedBranchReviewsForUser(state, {
          userId,
          projectId,
        })
      : empty.array,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { projectId } = props;

  return {
    onMount() {
      dispatch(ProjectReviewsFetchRequest.perform({ params: { projectId } }));
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
