// @flow
import * as React from "react";
import Button from "core/components/Button";
import ContextMenu from "core/components/ContextMenu";
import type { ProjectSort } from "core/types";
import style from "./style.scss";

type Props = {
  className?: string,
  mobile?: boolean,
  disabled?: boolean,
  organizationId: string,
  sort: ProjectSort,
  onSort: (sort: ProjectSort) => void,
};

const labels = {
  alpha: "Alphabetical",
  desc: "Recently updated",
  asc: "Least recently updated",
};

export default class ProjectSortMenu extends React.Component<Props> {
  menu: ?ContextMenu;

  render() {
    const { sort, onSort, mobile, disabled } = this.props;

    const menuItems = [
      {
        type: "checkbox",
        label: labels.alpha,
        click: () => onSort("alpha"),
        checked: sort === "alpha",
      },
      { type: "separator" },
      {
        type: "checkbox",
        label: labels.desc,
        click: () => onSort("desc"),
        checked: sort === "desc",
      },
      {
        type: "checkbox",
        label: labels.asc,
        click: () => onSort("asc"),
        checked: sort === "asc",
      },
    ];

    return (
      <ContextMenu
        ref={(me) => (this.menu = me)}
        id={this.props.organizationId}
        fixed
        placement="bottom-start"
        menuItems={menuItems}
      >
        {(showMenu, ref) => (
          <Button
            aria-haspopup="menu"
            nude
            disclosure={!mobile}
            disabled={disabled}
            innerRef={ref}
            onClick={showMenu}
            icon="sort"
            children={mobile ? undefined : labels[sort]}
            className={style.sortButton}
            qaSelector="project-sorting-button"
          />
        )}
      </ContextMenu>
    );
  }
}
