// @flow
import * as React from "react";
import ContextMenu, { type Children } from "core/components/ContextMenu";
import anyHover from "core/lib/anyHover";
import type { Comment, Project } from "core/types";

type Props = {
  comment: Comment,
  project: ?Project,
  children: Children,
  onEdit: () => void,
  onDelete?: () => void,
  canUpdate: boolean,
  canDelete: boolean,
  canShare: boolean,
  onPinComment?: (commentId: string) => void,
  onUnpinComment?: (commentId: string) => void,
  canPin?: boolean,
  canResolve?: boolean,
  onResolveComment?: (commentId: string) => void,
  onUnresolveComment?: (commentId: string) => void,
  isResolved?: boolean,
  isPinned?: boolean,
};

type State = { showRemoveReviewerDialog: boolean };

export default class CommentMenu extends React.Component<Props, State> {
  menu: ?ContextMenu;

  state = { showRemoveReviewerDialog: false };

  menuRef = (ref: *) => (this.menu = ref);

  closeMenu = () => {
    this.menu && this.menu.close();
  };

  showDeleteReviewDialog = () => {
    this.closeMenu();
    this.setState({ showRemoveReviewerDialog: true });
  };

  hideDeleteReviewDialog = () => {
    this.setState({ showRemoveReviewerDialog: false });
  };

  handleDelete = () => {
    const { onDelete } = this.props;
    if (onDelete) {
      this.closeMenu();
      onDelete();
    }
  };

  handleEdit = () => {
    this.closeMenu();
    this.props.onEdit();
  };

  getCanEdit(): boolean {
    return this.props.canUpdate && !this.props.comment.deletedAt;
  }

  getCanDelete(): boolean {
    return this.props.canDelete && !this.props.comment.deletedAt;
  }

  getShareParams(): ?{} {
    const { comment, project } = this.props;

    if (!project) {
      return; // Disable share item until project is loaded
    }

    const params = {
      organizationId: project.organizationId,
      projectId: comment.projectId,
      branchId: comment.branchId,
      commitSha: comment.commitSha,
      commentId: comment.id,
    };

    if (comment.layerId) {
      return {
        ...params,
        kind: "layer",
        fileId: comment.fileId,
        pageId: comment.pageId,
        layerId: comment.layerId,
      };
    }

    if (comment.branchId && !comment.layerId) {
      return {
        ...params,
        kind: "branch",
      };
    }

    return { ...params, kind: "commit" };
  }

  getMenuItems(): * {
    let items = [];

    if (!anyHover) {
      if (this.props.canPin && !this.props.isResolved) {
        const { isPinned, onPinComment, onUnpinComment, comment } = this.props;
        const action = isPinned ? onUnpinComment : onPinComment;
        const label = isPinned ? "Unpin" : "Pin";

        items.push({
          label,
          click: action ? () => action(comment.id) : undefined,
        });
      }

      if (this.props.canResolve) {
        const { isResolved, onResolveComment, onUnresolveComment, comment } =
          this.props;
        const action = isResolved ? onUnresolveComment : onResolveComment;
        const label = isResolved ? "Unresolve" : "Resolve";

        items.push({
          label,
          click: action ? () => action(comment.id) : undefined,
        });
      }

      if (items.length) {
        items.push({ type: "separator" });
      }
    }

    if (this.getCanEdit()) {
      items.push({
        label: "Edit",
        click: this.handleEdit,
      });
    }

    if (this.getCanDelete()) {
      items.push({
        label: "Delete…",
        click: this.handleDelete,
        visible: !!this.props.onDelete,
      });
    }

    if (this.props.canShare) {
      const shareParams = this.getShareParams();

      if (items.length) {
        items.push({ type: "separator" });
      }

      items.push({
        type: "share",
        props: shareParams,
        enabled: shareParams !== undefined,
      });
    }

    return items;
  }

  render() {
    return (
      <ContextMenu
        ref={this.menuRef}
        id={this.props.comment.id}
        menuItems={this.getMenuItems()}
        children={this.props.children}
      />
    );
  }
}
