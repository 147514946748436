// @flow
import classnames from "classnames";
import { find } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import DialogForm from "core/components/DialogForm";
import { SubmittableInput } from "core/components/Input/withSubmittable";
import * as Request from "core/models/request";
import {
  SectionCreateRequest,
  SectionUpdateRequest,
} from "core/requests/sections";
import { getFilteredSections } from "core/selectors/sections";
import type { Section, Dispatch, State as AppState } from "core/types";
import Validations from "core/validations";
import style from "./style.scss";

type OwnProps = {
  isOpen: boolean,
  onClose: () => *,
  section?: Section,
  projectId?: string,
  organizationId: string,
  onSuccess?: (section: Section) => *,
};

type Props = OwnProps & {
  sections: Section[],
  isSubmitting: boolean,
  hasError: boolean,
  onCreate: (values: { name: string }) => *,
  onUpdate: (values: { name: string }) => *,
};

type State = { name: string, isSaving: boolean };

class ProjectSectionDialog extends React.Component<Props, State> {
  state = {
    name: this.props.section ? this.props.section.name : "",
    isSaving: false,
  };

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.isSubmitting &&
      !this.props.isSubmitting &&
      !this.props.hasError
    ) {
      this.props.onClose();
    }

    if (this.props.isOpen && !prevProps.isOpen) {
      this.setState({
        isSaving: false,
        name: this.props.section ? this.props.section.name : "",
      });
    }
  }

  handleNameChange = (event: SyntheticInputEvent<>) => {
    this.setState({ name: event.target.value });
  };

  handleSubmit = (event?: SyntheticEvent<>) => {
    if (event) {
      event.preventDefault();
    }
    if (!this.state.name.length) {
      return;
    }
    const params = { name: this.state.name };

    if (this.props.section) {
      return this.props.onUpdate(params);
    }

    this.setState({ isSaving: true });
    return this.props.onCreate(params);
  };

  get disabled(): boolean {
    if (this.props.isSubmitting || !this.state.name.length) {
      return true;
    }
    if (
      !this.props.projectId &&
      find(this.props.sections, { name: this.state.name })
    ) {
      /*
        If we're not adding a Project and a Section with the current name
        already exists
      */
      return true;
    }

    return false;
  }

  renderHelper = () => {
    if (!this.state.name) {
      return null;
    }
    const section = find(this.props.sections, { name: this.state.name });

    if (!section) {
      return null;
    }
    if (this.props.section && section.name === this.props.section.name) {
      return null;
    }
    if (this.state.isSaving) {
      return null;
    }

    if (this.props.projectId) {
      return (
        <div className={style.helper}>
          Project will be added to the existing Section called{" "}
          <strong>{section.name}</strong>.
        </div>
      );
    }

    return <span className={style.error}>This Section already exists.</span>;
  };

  render() {
    const update = !!this.props.section;
    return (
      <DialogForm
        title={update ? "Edit Section" : "Create a new Section"}
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        onSubmit={this.handleSubmit}
        disabled={this.disabled}
        contentClassName={classnames({
          [style.create]: !update,
        })}
        primaryButton={update ? "Save" : "Create Section"}
      >
        {() => (
          <React.Fragment>
            <p>
              Use sections to organize your projects. You can help members find
              projects quickly by grouping related projects.
            </p>
            <SubmittableInput
              label="Name"
              requiredTag
              autoFocus
              error={this.renderHelper()}
              value={this.state.name}
              disabled={this.props.isSubmitting}
              onChange={this.handleNameChange}
              onSubmit={this.handleSubmit}
              minLength={Validations.minSectionNameLength}
              maxLength={Validations.maxSectionNameLength}
            />
            {!update && (
              <div className={style.message}>
                Sections are viewable by everyone in this organization.
              </div>
            )}
          </React.Fragment>
        )}
      </DialogForm>
    );
  }
}

function mapStateToProps(state: AppState, props: OwnProps) {
  const { organizationId, section } = props;
  const request = section
    ? SectionUpdateRequest.getRequest(state, { sectionId: section.id })
    : SectionCreateRequest.getRequest(state, { organizationId });
  return {
    sections: getFilteredSections(state, { organizationId }),
    isSubmitting: Request.isLoadingStrict(request),
    hasError: Request.hasError(request),
  };
}

function mapDispatchToProps(dispatch: Dispatch, props: OwnProps) {
  const { organizationId, projectId, section, onSuccess } = props;
  return {
    onCreate: (values: { name: string }) => {
      let params = { ...values, organizationId };
      if (projectId) {
        params = { ...params, projectId };
      }

      dispatch(
        SectionCreateRequest.perform({
          params,
          onSuccess: (response) => {
            const section = response.data.sections[0];
            if (onSuccess) {
              onSuccess(section);
            }
          },
        })
      );
    },
    onUpdate: (values: { name: string }) => {
      dispatch(
        SectionUpdateRequest.perform({
          params: { ...values, sectionId: section ? section.id : "" },
        })
      );
    },
  };
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectSectionDialog);
