// @flow
import * as React from "react";
import ContextMenu, {
  type Props as ContextMenuProps,
} from "core/components/ContextMenu";
import type { MenuItem, Organization, Project } from "core/types";
import connector from "./connector";

export type OwnProps = {|
  organizationId: string,
  onAfterClose?: () => void,
  innerRef?: React.Ref<typeof ContextMenu>,
  children: $PropertyType<ContextMenuProps, "children">,
|};

export type StateProps = {|
  organization: ?Organization,
  projects: Project[],
|};

export type DispatchProps = {|
  showDialog: (name: string, props: {}) => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

function ProjectsMenu(props: Props) {
  const { organization, projects, showDialog } = props;
  const menuItems = (): Array<MenuItem> => {
    return [
      {
        label: "Export all active projects...",
        enabled: projects.length > 0,
        click: () => {
          showDialog("ConfirmExportAllActiveProjects", {
            organization,
            projects,
          });
        },
      },
    ];
  };

  return (
    <ContextMenu
      ref={props.innerRef}
      id="projects-menu"
      menuItems={menuItems()}
      placement="bottom-start"
      onAfterClose={props.onAfterClose}
      children={props.children}
    />
  );
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default connector(ProjectsMenu);
