// @flow
/* global HTMLInputElement */
import classnames from "classnames";
import * as React from "react";
import Button from "../Button";
import style from "./style.scss";

export type Props = {
  value?: number,
  className: string,
  onChange: (event: SyntheticEvent<*>, zoom: number) => void,
  zoomMax: number,
  zoomMin: number,
};

export default class ZoomInput extends React.PureComponent<Props> {
  static defaultProps = {
    className: "",
    onChange: (event: SyntheticEvent<*>, zoom: number) => {},
    zoomMin: 1,
    zoomMax: 4,
  };

  handleChange = (event: SyntheticEvent<*>) => {
    const input = event.currentTarget;

    if (input instanceof HTMLInputElement) {
      const value = parseInt(input.value, 10);
      this.props.onChange(event, value);
    }
  };

  zoomToMin = (event: SyntheticEvent<*>) => {
    this.props.onChange(event, this.props.zoomMin);
  };

  zoomToMax = (event: SyntheticEvent<*>) => {
    const { onChange, zoomMax } = this.props;
    onChange(event, zoomMax);
  };

  render() {
    const { value, className, zoomMax, zoomMin } = this.props;
    return (
      <div className={classnames(style.zoom, className)}>
        <div className={style.inner}>
          <Button
            nude
            icon="grid-smaller"
            onClick={this.zoomToMin}
            className={style.minButton}
          />
          <progress
            min={zoomMin}
            max={zoomMax + 1} // This keeps the leading edge of the progress bar underneath the handle
            value={value}
            className={style.progress}
          />
          <input
            type="range"
            min={zoomMin}
            max={zoomMax}
            step="1"
            value={value}
            onChange={this.handleChange}
            className={style.input}
          />
          <Button
            nude
            icon="grid-larger"
            onClick={this.zoomToMax}
            className={style.maxButton}
          />
        </div>
      </div>
    );
  }
}
