// @flow
import { findIndex } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import Button from "core/components/Button";
import window from "core/global/window";
import { Abstract } from "core/lib/abstract";
import eventInInput from "core/lib/eventInInput";
import KeyCode from "core/lib/keycode";
import { replace } from "core/lib/location";
import { withRouter } from "core/lib/router";
import { layerLocation, shareLinkPath } from "core/lib/routes";
import { getLayerSet } from "core/selectors/layers";
import type {
  LayerSetParams,
  LayerSetItem,
  LayerShellMode,
  State,
} from "core/types";
import style from "./style.scss";

type OwnProps = {|
  location: {
    query?: { mode?: LayerShellMode, collectionLayerId?: string },
    state?: {
      layerSetParams?: LayerSetParams,
    },
  },
  layerSetParams: LayerSetParams,
  params: Abstract.LayerVersionDescriptor,
  layerParams?: Abstract.LayerVersionDescriptor,
  shareLinkId?: string,
  onSelectLayerSetItem?: (?LayerSetItem) => void,
|};

type StateProps = {|
  hasMore?: boolean,
  params: Abstract.LayerVersionDescriptor,
  layerSet: LayerSetItem[],
|};

type Props = {
  ...OwnProps,
  ...StateProps,
};

function findLayerSetIndex(props: Props): number {
  let index = -1;
  if (props.location.query && props.location.query.collectionLayerId) {
    index = findIndex(props.layerSet, {
      nonce: props.location.query.collectionLayerId,
    });
  }

  if (index === -1) {
    index = findIndex(props.layerSet, {
      layerId: props.params.layerId,
      commitSha: props.params.sha,
      fileId: props.params.fileId,
    });
  }

  if (index === -1) {
    return findIndex(props.layerSet, {
      layerId: props.params.layerId,
      fileId: props.params.fileId,
    });
  }

  return index;
}

function findPreviousLayerSetItem(
  props: Props,
  currentIndex: number
): ?LayerSetItem {
  return props.layerSet[currentIndex - 1];
}

function findNextLayerSetItem(
  props: Props,
  currentIndex: number
): ?LayerSetItem {
  return props.layerSet[currentIndex + 1];
}

function layerSetPath(props: Props, layerSetItem: ?LayerSetItem) {
  if (!layerSetItem) {
    return undefined;
  }

  const options = {
    layerSetParams: props.layerSetParams,
    mode: props.location.query ? props.location.query.mode : undefined,
    nonce: layerSetItem.nonce,
    sha: undefined,
  };

  if (props.shareLinkId) {
    return shareLinkPath(props.shareLinkId, {
      ...options,
      sha: layerSetItem.commitSha,
      collectionLayerId: layerSetItem.nonce,
    });
  }

  return layerLocation(
    props.params.projectId,
    props.params.branchId,
    props.layerSetParams.sha === "latest" ? "latest" : layerSetItem.commitSha,
    layerSetItem.fileId,
    layerSetItem.layerId,
    options
  );
}

class LayerSetControls extends React.Component<Props> {
  componentDidMount() {
    window.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
  }

  get nextLayerSetItem(): ?LayerSetItem {
    const currentIndex = findLayerSetIndex(this.props);
    return findNextLayerSetItem(this.props, currentIndex);
  }

  get previousLayerSetItem(): ?LayerSetItem {
    const currentIndex = findLayerSetIndex(this.props);
    return findPreviousLayerSetItem(this.props, currentIndex);
  }

  handleKeyDown = (event: KeyboardEvent) => {
    if (eventInInput(event)) {
      return;
    }

    switch (event.keyCode) {
      case KeyCode.KEY_LEFT:
        this.handlePreviousLayer();
        break;
      case KeyCode.KEY_RIGHT:
        this.handleNextLayer();
        break;
      default:
    }
  };

  handleNextLayer = () => {
    const nextLayerSetItem = this.nextLayerSetItem;

    if (this.props.onSelectLayerSetItem) {
      return this.props.onSelectLayerSetItem(nextLayerSetItem);
    }

    if (nextLayerSetItem) {
      const nextPath = layerSetPath(this.props, nextLayerSetItem);

      if (nextPath) {
        replace(nextPath);
      }
    }
  };

  handlePreviousLayer = () => {
    const previousLayerSetItem = this.previousLayerSetItem;

    if (this.props.onSelectLayerSetItem) {
      return this.props.onSelectLayerSetItem(previousLayerSetItem);
    }

    if (previousLayerSetItem) {
      const nextPath = layerSetPath(this.props, previousLayerSetItem);

      if (nextPath) {
        replace(nextPath);
      }
    }
  };

  render() {
    if (this.props.layerSet.length < 1) {
      return null;
    }

    const nextLayerSetItem = this.nextLayerSetItem;
    const previousLayerSetItem = this.previousLayerSetItem;

    return (
      <span className={style.layerSetControl}>
        <Button
          disabled={!previousLayerSetItem}
          icon="chevron-large-left"
          nude
          onClick={this.handlePreviousLayer}
        />
        <span className={style.layerSetLabel}>
          <strong>{findLayerSetIndex(this.props) + 1}</strong>
          &nbsp;of&nbsp;
          <strong>
            {this.props.layerSet.length.toString() +
              (this.props.hasMore ? "+" : "")}
          </strong>
        </span>
        <Button
          disabled={!nextLayerSetItem}
          icon="chevron-large-right"
          nude
          onClick={this.handleNextLayer}
        />
      </span>
    );
  }
}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const layerSet = getLayerSet(state, props.layerSetParams);

  return {
    hasMore:
      props.layerSetParams &&
      props.layerSetParams.type === "file" &&
      props.layerSetParams.hasMore,
    params: props.layerParams || props.params,
    layerSet,
  };
}

// $FlowFixMeNowPlease react-router upgrade
export default withRouter(connect(mapStateToProps)(LayerSetControls));
