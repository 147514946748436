// @flow
import classnames from "classnames";
import * as React from "react";
import style from "./style.scss";

type Props = {
  children: React.Node,
  className?: string,
};

export default function SerifText(props: Props) {
  const { className, ...rest } = props;

  return <span className={classnames(style.serif, className)} {...rest} />;
}
