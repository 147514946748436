// @flow
import empty from "empty";
import * as React from "react";
import Fullscreen from "abstract-di/fullscreen";
import CommitsList from "core/components/CommitsList";
import CopyLinkButton from "core/components/CopyLinkButton";
import Head from "core/components/Head";
import LayerBuild from "core/components/LayerBuild";
import LayerComments from "core/components/LayerComments";
import LayerCompare from "core/components/LayerCompare";
import LayerDesign from "core/components/LayerDesign";
import LayerPresentation from "core/components/LayerPresentation";
import LayerShell from "core/components/LayerShell";
import Media from "core/components/Media";
import Modal from "core/components/Modal";
import ShareButton from "core/components/ShareButton";
import window from "core/global/window";
import { Abstract } from "core/lib/abstract";
import KeyCode from "core/lib/keycode";
import { addQuery, push, removeQuery, replace } from "core/lib/location";
import { isDesktop } from "core/lib/platform";
import { branchPath, layerLocation, shareLinkPath } from "core/lib/routes";
import {
  isPublic,
  isCollection,
  isPublicCollection,
} from "core/models/shareLink";
import type {
  Branch,
  Comment,
  Commit,
  File,
  Layer as TLayer,
  LayerSetItem,
  LayerSetParams,
  LayerShellMode,
  LayerState,
  Project,
  ReactRouterLocation,
  ShareLink,
} from "core/types";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  onCloseDisabled?: boolean,
  layerSetParams?: LayerSetParams,
  location: ReactRouterLocation,
  onClose?: () => void,
  onSelectCommit?: ({ sha: string }) => void,
  onSelectLayerSetItem?: (?LayerSetItem) => void,
  params: Abstract.LayerVersionDescriptor,
  shareLink: ?ShareLink,
|};

export type StateProps = {|
  branch: ?Branch,
  comments: { [sha: string]: Comment[] },
  commit: ?Commit,
  commits: { [sha: string]: Commit },
  commitsError: boolean,
  commitsLoading: boolean,
  file: ?File,
  isOffline: boolean,
  isSyncing: boolean,
  latestCommit: ?Commit,
  layer: ?TLayer,
  layerSetNonce: ?string,
  layerSetParams?: LayerSetParams,
  layerState: LayerState,
  pageId: ?string,
  parentBranch: ?Branch,
  project: ?Project,
  selectedLayerKey: string,
  selectedMode: LayerShellMode,
  showPrototypes: boolean,
|};

export type DispatchProps = {|
  onLoad(): void,
  onSelectLayer(layerKey: ?string): void,
  onSelectMode(mode: LayerShellMode): void,
  openFile: ?(sha: string, openUntracked: boolean) => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type State = {
  isFullscreen: boolean,
};

class Layer extends React.Component<Props, State> {
  fullscreen = new Fullscreen();
  state = { isFullscreen: this.fullscreen.isFullscreen() };

  componentDidMount() {
    this.handleShouldRedirect(this.props);
    this.fullscreen.addChangeListener(this.handleIsFullscreen);
    window.addEventListener("keydown", this.handleKeyDown);
  }

  componentDidUpdate() {
    this.handleShouldRedirect(this.props);
  }

  componentWillUnmount() {
    this.fullscreen.removeChangeListener();
    window.removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown = (event: KeyboardEvent) => {
    const { branch, commit, onCloseDisabled, layer, openFile } = this.props;
    const openUntracked = event.shiftKey;

    if (
      commit &&
      branch &&
      openFile &&
      event.metaKey &&
      event.keyCode === KeyCode.KEY_O
    ) {
      const shaToOpenFileAt =
        commit.sha === (layer && layer.lastChangedAtSha)
          ? branch.head
          : commit.sha;

      openFile(shaToOpenFileAt, openUntracked);
    }

    if (
      !onCloseDisabled &&
      event.keyCode === KeyCode.KEY_ESCAPE &&
      !this.state.isFullscreen
    ) {
      event.preventDefault();
      event.stopPropagation();

      this.handleClose();
    }
  };

  handleShouldRedirect = (props: Props) => {
    const currentSha = props.params.sha;
    const latestCommitSha = props.latestCommit
      ? props.latestCommit.sha
      : "latest";
    const shouldRedirect =
      !props.commitsLoading &&
      !props.commitsError &&
      props.params.sha !== "latest" &&
      !props.commits[currentSha];

    if (!shouldRedirect) {
      return;
    }

    if (props.shareLink && isPublic(props.shareLink)) {
      if (
        props.shareLink.options.historyEnabled &&
        isCollection(props.shareLink)
      ) {
        return replace(addQuery({ sha: latestCommitSha }));
      }

      if (
        (!props.shareLink.options.inspectEnabled &&
          props.selectedMode === "build") ||
        (!props.shareLink.options.historyEnabled &&
          props.selectedMode === "compare")
      ) {
        // If the user or organization no longer has permission to view the
        // selected mode, fall back to the design tab.
        return this.props.onSelectMode("design");
      }

      if (props.shareLink.options.historyEnabled) {
        // On a public shareLink with history enabled
        // & for a junk ?sha= in query params, we remove the bad sha,
        // which will force a redirect to the latest commit.
        return replace(removeQuery("sha"));
      }

      // On a public shareLink with history turned off
      // & for a junk ?sha= in query params,
      // we won't find a matching commit with props.commits[sha].
      // We return early here so we don't force a redirect to the layer.
      // (junk sha redirect issue: https://github.com/goabstract/JIRA/issues/5641)
      return;
    }

    // If there is a latest commit, redirect to it.
    if (shouldRedirect) {
      if (this.props.onSelectCommit) {
        this.props.onSelectCommit({ sha: latestCommitSha });
      } else {
        replace(this.layerPathAtCommit(latestCommitSha));
      }
    }
  };

  handleClose = () => {
    // Option #1: if there is an `onClose` prop passed in, let the parent
    // component decide how to close the modal.
    if (this.props.onClose) {
      return this.props.onClose();
    }

    // Option #2: if we're presenting, stop presenting.
    const { present, preview } = this.props.location.query;
    if (present && preview !== "false") {
      return replace(removeQuery("present", "preview", "showComments"));
    }

    // Option #3: if we're viewing a public share link, we have special
    // handling for that.
    if (this.props.shareLink) {
      if (this.props.shareLink.kind === "layer") {
        // Option #3.1: On a share link for a layer, we don't display the <--
        // back arrow.  The ESC event handler still exists though so when a
        // user presses ESC, it'll call layer detail's onClose. We'll want to
        // bail here.
        return;
      }

      // Option #3.2: If a share link is not for a layer (presumably it's a
      // collection and we've clicked to view a particular layer), go back to
      // the root share link path.
      return push(shareLinkPath(this.props.shareLink.id));
    }

    // Option #4: this layer was opened from a link somewhere in the app and
    // the route it was opened from was saved in the location state, so we
    // return to that locatiion.
    if (this.props.location.state && this.props.location.state.returnTo) {
      return push(this.props.location.state.returnTo);
    }

    // Option #5: fallback for when none of the other options have worked,
    // generally when looking at this route from a hard browser refresh rather
    // than clicking a link inside the app. We could potentially go back to a
    // specific commit or to a specific file view inside the branch, but if we
    // open this page from a full page load, either of those would be a guess.
    // Let's go back to the main branch page.
    return push(
      branchPath(this.props.params.projectId, this.props.params.branchId)
    );
  };

  handleClosePresentation = () => {
    // To preserve the existing implementation in desktop and web, we only exit
    // fullscreen when clicking "x" in web. We leave desktop's fullscreen state
    // intact.
    if (!isDesktop) {
      this.fullscreen.exit();
    }

    this.handleClose();
  };

  layerPathAtCommit = (sha: string) => {
    const { location, shareLink } = this.props;
    const { projectId, branchId, fileId, layerId } = this.props.params;
    const { pathname } = layerLocation(
      projectId,
      branchId,
      sha,
      fileId,
      layerId
    );

    if (shareLink && isPublicCollection(shareLink)) {
      return { ...location, query: { ...location.query, sha } };
    }

    return { ...location, pathname };
  };

  handleCommitClick = (event: SyntheticEvent<>, params: { sha: string }) => {
    event.preventDefault();
    if (this.props.onSelectCommit) {
      return this.props.onSelectCommit(params);
    }

    if (this.props.shareLink) {
      replace(addQuery({ sha: params.sha }));
    } else {
      replace(this.layerPathAtCommit(params.sha));
    }
  };

  handleIsFullscreen = (isFullscreen: boolean) => {
    this.setState({ isFullscreen });
  };

  handleExitFullScreen = () => {
    this.fullscreen.exit();
  };

  handleToggleFullScreen = () => {
    this.state.isFullscreen ? this.fullscreen.exit() : this.fullscreen.enter();
  };

  getCollectionId() {
    if (
      this.props.layerSetParams &&
      this.props.layerSetParams.type === "collection"
    ) {
      return this.props.layerSetParams.collectionId;
    }
    return undefined;
  }

  getCollectionLayerId() {
    return (
      this.props.layerSetNonce ||
      this.props.location.query.collectionLayerId ||
      undefined
    );
  }

  getComments() {
    if (this.props.params.sha === "latest") {
      if (this.props.latestCommit) {
        return this.props.comments[this.props.latestCommit.sha];
      } else {
        return empty.array;
      }
    }
    return this.props.comments[this.props.params.sha];
  }

  getCommentSha() {
    if (this.props.params.sha === "latest" && this.props.latestCommit) {
      return this.props.latestCommit.sha;
    }

    return this.props.params.sha;
  }

  getInputShare = () => {
    const options = {
      present:
        this.props.location.query.present === "true" ? "true" : undefined,
    };

    if (this.props.shareLink) {
      return {
        kind: this.props.shareLink.kind,
        options: {
          ...options,
          ...this.props.shareLink.options,
        },
        descriptor: this.props.shareLink.descriptor,
        collectionLayerId: this.getCollectionLayerId(),
      };
    }

    return this.props.layerSetParams &&
      this.props.layerSetParams.type === "collection"
      ? {
          kind: "collection",
          options,
          descriptor: {
            projectId: this.props.layerSetParams.projectId,
            branchId: this.props.params.branchId,
            collectionId: this.props.layerSetParams.collectionId,
          },
          collectionLayerId: this.getCollectionLayerId(),
        }
      : {
          kind: "layer",
          descriptor: this.props.params,
          options,
        };
  };

  shareButton() {
    const { layer, project, shareLink } = this.props;
    const organizationId = project ? project.organizationId : undefined;
    const inputShare = this.getInputShare();

    if (shareLink) {
      return (
        <CopyLinkButton
          doNotCreateShareLink
          nude={false}
          tint={false}
          icon="link"
          url={`${shareLink.url}${this.props.location.search}`}
          organizationId={organizationId}
          inputShare={inputShare}
        >
          Copy Link
        </CopyLinkButton>
      );
    }

    return (
      <Media tablet>
        {(tablet) => (
          <ShareButton
            inputShare={inputShare}
            query={{
              mode: this.props.selectedMode,
              collectionLayerId: this.getCollectionLayerId(),
              sha: this.props.params.sha,
            }}
            isMobile={tablet}
            layerType={layer ? layer.type : undefined}
            collectionId={this.getCollectionId()}
          />
        )}
      </Media>
    );
  }

  render() {
    const canInspect =
      !this.props.shareLink || !!this.props.shareLink.options.inspectEnabled;
    const canShowHistory =
      !this.props.shareLink || !!this.props.shareLink.options.historyEnabled;
    const shareId = this.props.shareLink ? this.props.shareLink.id : undefined;

    return (
      <div className={style.container}>
        <Head>
          {this.props.project && this.props.layer && (
            <title>{`${this.props.project.name} - ${this.props.layer.name}`}</title>
          )}
          {this.props.project && this.props.layer && (
            <meta
              property="og:title"
              content={this.props.project.name + " - " + this.props.layer.name}
            />
          )}
          {this.props.shareLink && (
            <meta property="og:url" content={this.props.shareLink.url} />
          )}
          <meta property="og:type" content="article" />
          <meta name="twitter:card" content="summary" />
        </Head>
        <Media tablet>
          {(tablet) => (
            <LayerShell
              params={this.props.params}
              layerState={this.props.layerState}
              branch={this.props.branch}
              commit={this.props.commit}
              layer={this.props.layer}
              selectedMode={this.props.selectedMode}
              shareButton={this.shareButton()}
              onClose={
                this.props.onCloseDisabled ? undefined : this.handleClose
              }
              onSelectMode={this.props.onSelectMode}
              canInspect={canInspect}
              canShowHistory={canShowHistory}
              shareId={shareId}
              collectionId={this.getCollectionId()}
              collectionLayerId={this.getCollectionLayerId()}
              layerSetParams={this.props.layerSetParams}
              mobile={tablet}
              location={this.props.location}
              showGoToLatest={
                !(
                  this.props.commitsLoading ||
                  this.props.layerState === "loading"
                )
              }
            >
              {({
                selectedMode,
                zoomState,
                goBackToSymbolInstanceButton,
                handleChangeZoomState,
                isShowingResolvedComments,
                onResolveCommentsOptionClick,
              }) => {
                if (this.props.location.query.present === "true") {
                  return (
                    <Modal
                      isOpen
                      size="fullScreen"
                      hideHeaderDesktop
                      hideHeaderMobile
                      shouldCloseOnEsc={false}
                      opacityTransitionOnly
                    >
                      <LayerPresentation
                        comments={this.getComments()}
                        defaultShowComments={
                          this.props.location.query.showComments === "true"
                        }
                        file={this.props.file}
                        hideInspect={!canInspect}
                        inputShare={this.getInputShare()}
                        isFullScreen={this.state.isFullscreen}
                        isShowingResolvedComments={isShowingResolvedComments}
                        latestCommit={this.props.latestCommit}
                        layer={this.props.layer}
                        layerDescriptor={this.props.params}
                        layerSetParams={this.props.layerSetParams}
                        loading={this.props.layerState === "loading"}
                        location={this.props.location}
                        mode={this.props.selectedMode}
                        onChangeShowComments={(showComments) =>
                          replace({
                            ...this.props.location,
                            query: {
                              ...this.props.location.query,
                              showComments: showComments.toString(),
                            },
                          })
                        }
                        onClickClose={this.handleClosePresentation}
                        onExitFullScreen={this.handleExitFullScreen}
                        onToggleFullscreen={this.handleToggleFullScreen}
                        params={this.props.params}
                        shareLink={
                          this.props.shareLink
                            ? this.props.shareLink
                            : undefined
                        }
                        showPrototypes={this.props.showPrototypes}
                      />
                    </Modal>
                  );
                } else {
                  switch (selectedMode) {
                    case "design": {
                      return (
                        <LayerDesign
                          layerSetParams={this.props.layerSetParams}
                          mobile={tablet}
                          onSelectLayerSetItem={this.props.onSelectLayerSetItem}
                          params={this.props.params}
                          zoomState={zoomState}
                          onChangeZoomState={handleChangeZoomState}
                          collectionId={this.getCollectionId()}
                          commentId={
                            this.props.location.query.commentId || undefined
                          }
                          shareId={shareId}
                          shareButton={this.shareButton()}
                          canShowHistory={canShowHistory}
                          onClose={
                            this.props.onCloseDisabled
                              ? undefined
                              : this.handleClose
                          }
                          isSyncing={this.props.isSyncing}
                          isShowingResolvedCommentAnnotations={
                            isShowingResolvedComments
                          }
                          goBackToSymbolInstanceButton={
                            goBackToSymbolInstanceButton
                          }
                          layerHistory={({ handleSidebarClose }) => (
                            <CommitsList
                              params={this.props.params}
                              commitComments={this.props.comments}
                              onCommitClick={(event, commit) => {
                                handleSidebarClose();
                                this.handleCommitClick(event, commit);
                              }}
                              defaultFocused
                            />
                          )}
                          commentsList={({
                            form,
                            collapsed,
                            onFormChanged,
                            selectedCommentId,
                            onCommentSelected,
                            commentsState,
                          }) => (
                            <LayerComments
                              form={form}
                              mobile={tablet}
                              layer={this.props.layer}
                              collapsed={collapsed}
                              onFormChanged={onFormChanged}
                              isSyncing={false}
                              isLoadingLayer={
                                this.props.layerState === "loading"
                              }
                              isLoadingCommits={this.props.commitsLoading}
                              isLoading={commentsState === "loading"}
                              hasError={commentsState === "error"}
                              branch={this.props.branch}
                              parentBranch={this.props.parentBranch}
                              comments={this.props.comments}
                              commits={this.props.commits}
                              projectId={this.props.params.projectId}
                              branchId={this.props.params.branchId}
                              fileId={this.props.params.fileId}
                              pageId={
                                this.props.pageId
                                  ? this.props.pageId
                                  : undefined
                              }
                              layerId={this.props.params.layerId}
                              sha={this.getCommentSha()}
                              highlightedCommentId={
                                this.props.location.query.commentId || undefined
                              }
                              scrollToCommentId={
                                this.props.location.query.commentId || undefined
                              }
                              selectedCommentId={selectedCommentId}
                              onCommitClick={this.handleCommitClick}
                              onCommentSelected={onCommentSelected}
                              canShowHistory={canShowHistory}
                              shareId={shareId}
                              collectionId={this.getCollectionId()}
                              profilerId="WebLayerDetailCommentsLoading"
                              isShowingResolvedComments={
                                isShowingResolvedComments
                              }
                              onResolveCommentsOptionClick={
                                onResolveCommentsOptionClick
                              }
                            />
                          )}
                        />
                      );
                    }
                    case "compare": {
                      return (
                        <LayerCompare
                          params={this.props.params}
                          shareId={shareId}
                        />
                      );
                    }
                    case "build": {
                      return (
                        <LayerBuild
                          collectionId={this.getCollectionId()}
                          layerSetParams={this.props.layerSetParams}
                          onChangeZoomState={handleChangeZoomState}
                          onClose={
                            this.props.onCloseDisabled
                              ? undefined
                              : this.handleClose
                          }
                          onSelectLayer={this.props.onSelectLayer}
                          onSelectLayerSetItem={this.props.onSelectLayerSetItem}
                          params={this.props.params}
                          selectedLayerKey={this.props.selectedLayerKey}
                          shareId={shareId}
                          shareLink={
                            this.props.shareLink
                              ? this.props.shareLink
                              : undefined
                          }
                          zoomState={zoomState}
                          goBackToSymbolInstanceButton={
                            goBackToSymbolInstanceButton
                          }
                        />
                      );
                    }
                    default:
                      return null;
                  }
                }
              }}
            </LayerShell>
          )}
        </Media>
      </div>
    );
  }
}

export default connector(Layer);
