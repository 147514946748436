// @flow
import * as React from "react";
import type { LocationDescriptor } from "core/types";
import CompleteProfileForm, {
  type FormValues,
} from "web/components/CompleteProfileForm";
import OnboardingPage from "web/components/OnboardingPage";
import type { Form } from "web/types";
import connector from "./connector";

export type OwnProps = {|
  location: {
    pathname: string,
    state: {
      organizationId: string,
      clientType: string,
      returnTo: LocationDescriptor,
    },
  },
  form: Form,
|};

export type StateProps = {|
  username: ?string,
  email: ?string,
  name: ?string,
  isSubmitting: boolean,
|};

export type DispatchProps = {|
  onSubmit: (formId: string, values: FormValues) => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

class CompleteProfile extends React.Component<Props> {
  render() {
    return (
      <OnboardingPage
        documentTitle="Complete your account"
        heading="Complete your account"
        subtitle="Your Organization has authorized us to create an Abstract account for you on their behalf. We need a few more details before you can continue."
        headingExtra={
          <React.Fragment>
            Questions? Contact an Admin for your Abstract Organization.
          </React.Fragment>
        }
        subtitleWrap
      >
        <CompleteProfileForm
          form={this.props.form}
          onSubmit={this.props.onSubmit}
        />
      </OnboardingPage>
    );
  }
}

export default connector(CompleteProfile);
