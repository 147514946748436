// @flow
import pluralize from "pluralize";
import { connect } from "react-redux";
import { getTeam } from "core/selectors/teams";
import type { State, Dispatch } from "core/types";
import type { OwnProps, StateProps, Props } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  let teamName = undefined;
  let teamColor = undefined;
  let teamNames = undefined;
  const team =
    (props.teamId && getTeam(state, { teamId: props.teamId })) ||
    (props.teamIds &&
      props.teamIds.length === 1 &&
      getTeam(state, { teamId: props.teamIds[0] }));
  if (team) {
    teamName = team.name;
    teamColor = team.color;
  }

  const teamNamesArray =
    props.teamIds &&
    props.teamIds.length > 1 &&
    props.teamIds.map((teamId) => {
      const individualTeam = getTeam(state, { teamId });
      if (individualTeam) {
        return individualTeam.name;
      }
      return undefined;
    });

  if (teamNamesArray) {
    teamName = "+";
    teamColor = "#8b8e8f";
    if (teamNamesArray.length > 4) {
      const trimmedTeamNames = teamNamesArray.slice(0, 4);
      teamNames = [
        ...trimmedTeamNames,
        `and ${teamNamesArray.length - 4} more ${pluralize(
          "team",
          teamNamesArray.length - 4
        )}`,
      ].join(", ");
    } else {
      teamNames = teamNamesArray.join(", ");
    }
  }

  return {
    teamName: props.teamName || teamName,
    teamNames: teamNames,
    teamColor: props.teamColor || teamColor,
  };
}

export default connect<Props, OwnProps, StateProps, _, State, Dispatch>(
  mapStateToProps
);
