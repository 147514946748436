// @flow
import { connect } from "react-redux";
import { getCurrentUser } from "abstract-di/selectors";
import { loadLayer } from "core/actions/layers";
import { withData } from "core/components/DataLoader";
import connectStorage from "core/hocs/connectStorage";
import createConnector from "core/lib/createConnector";
import * as Branch from "core/models/branch";
import * as File from "core/models/file";
import { inspectEnabled } from "core/models/shareLink";
import { CommitsFetchRequest } from "core/requests/commits";
import { LayerDatasetFetchRequest } from "core/requests/layerDatasets";
import { OrganizationsFetchRequest } from "core/requests/organizations";
import { getShowAssets } from "core/selectors/assets";
import { getBranch, getBranchHead } from "core/selectors/branches";
import {
  getCommitForLayer,
  getPreviousLayerCommit,
} from "core/selectors/commits";
import {
  canUseNewDefaultBranchName,
  getProjectFeatureEnabled,
  getFeatureEnabled,
  canUseBackgroundPreviewSwitcher,
} from "core/selectors/features";
import { getFileForLayer } from "core/selectors/files";
import {
  getLayers,
  getLayerDataset,
  getLayerChangeset,
} from "core/selectors/layerDatasets";
import { getLayer, getLayerState } from "core/selectors/layers";
import { getOrganizationForProject } from "core/selectors/organizations";
import { getPageForLayer } from "core/selectors/pages";
import { DEFAULT_POLICY, getOrganizationPolicy } from "core/selectors/policies";
import { getProject } from "core/selectors/projects";
import { hasHotspots, getShowPrototypes } from "core/selectors/prototypes";
import type { State, Dispatch } from "core/types";
import type {
  Props,
  OwnProps,
  StateProps,
  StorageProps,
  DispatchProps,
  PropsWithoutStorage,
} from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { params, shareLink, isLoadingMore } = props;
  const layerDataset = getLayerDataset(state, params);
  const layerChangesets = getLayerChangeset(state, params);
  const branch = getBranch(state, params);
  const commit = getCommitForLayer(state, params);
  const previousCommit = getPreviousLayerCommit(state, params);
  const currentUser = getCurrentUser(state);
  const file = getFileForLayer(state, params);
  const layer = isLoadingMore ? null : getLayer(state, params);
  const organization = getOrganizationForProject(state, params);
  const page = layer
    ? getPageForLayer(state, {
        ...params,
        pageId: layer.pageId,
      })
    : null;
  const policy = getOrganizationPolicy(state, {
    organizationId: organization ? organization.id : "",
  });
  const previousLayerDatasetError = previousCommit
    ? LayerDatasetFetchRequest.hasError(state, {
        ...params,
        sha: previousCommit.sha,
      })
    : false;
  const project = getProject(state, params);
  const supportsAssets = File.supportsAssets(file);
  const layers = getLayers(state, params);
  const selectedLayer = layers[props.selectedLayerKey];
  const layerIsSymbolInstance =
    selectedLayer &&
    selectedLayer.type === "symbolInstance" &&
    selectedLayer.symbolMasterDescriptor;

  const symbolMasterLoadingSha =
    layerIsSymbolInstance &&
    selectedLayer.symbolMasterDescriptor &&
    selectedLayer.symbolMasterDescriptor.projectId === params.projectId
      ? params.sha
      : "latest";

  const symbolMasterLayerData = layerIsSymbolInstance
    ? getLayer(state, {
        ...selectedLayer.symbolMasterDescriptor,
        sha: symbolMasterLoadingSha,
      })
    : undefined;

  const symbolMasterFeatureEnabled = organization
    ? getFeatureEnabled(state, {
        feature: "show-go-to-symbol-master",
        organizationId: organization.id,
      })
    : false;

  const symbolMasterLayerDataIsLoading = layerIsSymbolInstance
    ? CommitsFetchRequest.isFirstLoading(state, {
        ...selectedLayer.symbolMasterDescriptor,
      }) ||
      getLayerState(state, {
        ...selectedLayer.symbolMasterDescriptor,
        sha: symbolMasterLoadingSha,
      }) === "loading"
    : undefined;

  const symbolMasterLayerDataError = layerIsSymbolInstance
    ? CommitsFetchRequest.hasError(state, {
        ...selectedLayer.symbolMasterDescriptor,
      }) ||
      getLayerState(state, {
        ...selectedLayer.symbolMasterDescriptor,
        sha: symbolMasterLoadingSha,
      }) === "error"
    : undefined;

  return {
    branch,
    canGenerateAssets:
      supportsAssets && Branch.canGenerateAssets(branch, currentUser),
    canUseSymbolSourceDisplayName: canUseNewDefaultBranchName(state),
    canShowHandoff: !!(policy.showHandoff || inspectEnabled(shareLink)),
    canShowHotspots: getShowPrototypes(state, params.projectId),
    canUpgradeSubscription: !!policy.upgradeSubscription,
    canvasOverlaysEnabled: getProjectFeatureEnabled(state, {
      projectId: params.projectId,
      feature: "canvas-overlays-enabled",
    }),
    commit,
    previousCommit,
    error: previousLayerDatasetError ? "Failed to load changed values" : null,
    file,
    hasHotspots: hasHotspots(state, params),
    layer,
    layerChangesets,
    layerDataset,
    layerState: getLayerState(state, params),
    layers,
    organizationId: organization ? organization.id : undefined,
    page,
    policyLoaded: policy !== DEFAULT_POLICY,
    project,
    showAssets: supportsAssets && getShowAssets(state, params),
    showPrototypes: getShowPrototypes(state, params.projectId),
    showDevelopment:
      process.env.NODE_ENV === "development" ||
      getFeatureEnabled(state, { feature: "inspect-development" }),
    symbolMasterLayerData,
    symbolMasterLayerDataIsLoading,
    symbolMasterLayerDataError,
    showGoToSymbolMaster: symbolMasterFeatureEnabled && !shareLink,
    canUsePreviewBackgroundSwitcher: canUseBackgroundPreviewSwitcher(
      state,
      params.projectId
    ),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { params } = props;
  const loadSymbolMaster = (selectedLayerKey: ?string) =>
    dispatch(async (dispatch, getState) => {
      const layers = getLayers(getState(), params);
      if (layers && selectedLayerKey) {
        const selectedLayer = layers[selectedLayerKey];
        if (
          selectedLayer &&
          selectedLayer.type === "symbolInstance" &&
          selectedLayer.symbolMasterDescriptor
        ) {
          if (
            selectedLayer.symbolMasterDescriptor.projectId !==
              params.projectId ||
            params.sha === "latest"
          ) {
            dispatch(
              CommitsFetchRequest.perform({
                force: false,
                params: { ...selectedLayer.symbolMasterDescriptor },
              })
            );
            dispatch(
              loadLayer({
                ...selectedLayer.symbolMasterDescriptor,
                sha: "latest",
              })
            );
          } else {
            dispatch(
              loadLayer({
                ...selectedLayer.symbolMasterDescriptor,
                sha: params.sha,
              })
            );
          }
        }
      }
    });
  return {
    onLoad: () =>
      dispatch(async (dispatch, getState) => {
        const head = getBranchHead(getState(), params);

        dispatch(OrganizationsFetchRequest.perform({ params: undefined }));

        await Promise.all([
          dispatch(LayerDatasetFetchRequest.perform({ params: params })),
          dispatch(
            CommitsFetchRequest.perform({
              params: {
                projectId: params.projectId,
                branchId: params.branchId,
                fileId: params.fileId,
                layerId: params.layerId,
                head,
              },
            })
          ),
        ]);

        const state = getState();
        const previousCommit = getPreviousLayerCommit(state, params);

        if (previousCommit) {
          dispatch(
            LayerDatasetFetchRequest.perform({
              params: { ...params, sha: previousCommit.sha },
            })
          );
        }

        if (props.selectedLayerKey) {
          loadSymbolMaster(props.selectedLayerKey);
        }
      }),
    loadSymbolMaster,
  };
}

function mapStorageToProps(storage, props: OwnProps): StorageProps {
  const previous = storage.getItem("LayerBuild") || {};
  return {
    defaultCollapsedLeftSidebar:
      props.defaultCollapsedLeftSidebar === undefined
        ? previous.defaultCollapsedLeftSidebar
        : props.defaultCollapsedLeftSidebar,
    defaultCollapsedRightSidebar:
      props.defaultCollapsedRightSidebar === undefined
        ? previous.defaultCollapsedRightSidebar
        : props.defaultCollapsedRightSidebar,
    defaultShowLayoutOverlay: previous.defaultShowLayoutOverlay,
    defaultShowGridOverlay: previous.defaultShowGridOverlay,
    defaultShowGuidesOverlay: previous.defaultShowGuidesOverlay,
    saveLayoutOverlaySetting: (enabled) => {
      const previous = storage.getItem("LayerBuild") || {};

      storage.setItem("LayerBuild", {
        ...previous,
        defaultShowLayoutOverlay: enabled,
      });
    },
    saveGridOverlaySetting: (enabled) => {
      const previous = storage.getItem("LayerBuild") || {};

      storage.setItem("LayerBuild", {
        ...previous,
        defaultShowGridOverlay: enabled,
      });
    },
    saveGuidesOverlaySetting: (enabled) => {
      const previous = storage.getItem("LayerBuild") || {};

      storage.setItem("LayerBuild", {
        ...previous,
        defaultShowGuidesOverlay: enabled,
      });
    },
    onCollapseLeftSidebar: (defaultCollapsedLeftSidebar) => {
      const previous = storage.getItem("LayerBuild") || {};

      storage.setItem("LayerBuild", {
        ...previous,
        defaultCollapsedLeftSidebar,
      });

      if (props.onCollapseLeftSidebar) {
        props.onCollapseLeftSidebar(defaultCollapsedLeftSidebar);
      }
    },
    onCollapseRightSidebar: (defaultCollapsedRightSidebar) => {
      const previous = storage.getItem("LayerBuild") || {};

      storage.setItem("LayerBuild", {
        ...previous,
        defaultCollapsedRightSidebar,
      });

      if (props.onCollapseRightSidebar) {
        props.onCollapseRightSidebar(defaultCollapsedRightSidebar);
      }
    },
  };
}

export default createConnector<Props, OwnProps>(
  (Component) => connectStorage<OwnProps>(Component, mapStorageToProps),
  connect<
    PropsWithoutStorage,
    OwnProps,
    StateProps,
    DispatchProps,
    State,
    Dispatch,
  >(mapStateToProps, mapDispatchToProps),
  withData((props) => props.params)
);
