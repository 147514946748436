// @flow
import * as Abstract from "abstract-sdk";
import * as React from "react";
import { useSelector } from "react-redux";
import OrganizationLogo from "core/components/OrganizationLogo";
import { descriptorPath } from "core/lib/routes";
import { getOrganization } from "core/selectors/organizations";
import Result, { type CommonProps } from "./Result";

type Props = {|
  ...CommonProps,
  descriptor: Abstract.OrganizationDescriptor,
|};

export default function ResultOrganization(props: Props) {
  const { descriptor, ...rest } = props;
  const { organizationId } = descriptor;

  const organization = useSelector((state) =>
    getOrganization(state, { organizationId })
  );

  if (!organization) {
    return null;
  }

  return (
    <Result
      to={descriptorPath(descriptor)}
      icon={
        <OrganizationLogo
          size={16}
          src={organization.logoUrl}
          alt={organization.name}
        />
      }
      title={organization.name}
      context="Organization"
      {...rest}
    />
  );
}
