// @flow
import * as React from "react";
import Button from "core/components/Button";
import Comment from "core/components/Comment";
import Time from "core/components/Time";
import { replace } from "core/lib/location";
import { layerLocation } from "core/lib/routes";
import type {
  Comment as TComment,
  User,
  Commit,
  ReactRouterLocation,
} from "core/types";
import style from "../style.scss";
import connector from "./connector";

export type StateProps = {|
  name: ?string,
  isUnpinning: boolean,
  currentUser: ?User,
  canUnpin?: boolean,
  commit: ?Commit,
|};

export type DispatchProps = {|
  onUnpin: () => void,
|};

export type LocationProps = {|
  location: ReactRouterLocation,
|};

export type OwnProps = {|
  comment: TComment,
|};

export type OwnLocationProps = {|
  ...LocationProps,
  ...OwnProps,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
  ...LocationProps,
};

function PinnedComment({
  comment,
  name,
  isUnpinning,
  onUnpin,
  currentUser,
  canUnpin,
  commit,
  location,
}: Props) {
  const [isActive, setActive] = React.useState(false);

  const handleUnpin = () => {
    onUnpin();
  };

  const handleGoToComment = () => {
    replace(
      layerLocation(
        comment.projectId,
        comment.branchId,
        comment.commitSha,
        comment.fileId,
        comment.layerId,
        {
          ...location.query,
          commentId: comment.id,
        }
      )
    );
  };

  return (
    <div
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
    >
      <div className={style.sectionHeader}>
        <div className={style.sectionTitle}>
          <span className={style.sectionTitleItem}>{name}</span>
          {comment.pinnedAt && (
            <Time date={comment.pinnedAt} className={style.sectionTitleTime} />
          )}
        </div>
        {canUnpin && (
          <Button
            title="Unpin comment"
            onClick={handleUnpin}
            nude
            tint
            tintHover
            disabled={isUnpinning}
            className={!isActive ? style.buttonHidden : undefined}
          >
            Unpin
          </Button>
        )}
      </div>
      <Comment
        key={comment.id}
        comment={comment}
        projectId={comment.projectId}
        branchId={comment.branchId}
        fileId={comment.fileId}
        pageId={comment.pageId}
        layerId={comment.layerId}
        context={comment}
        currentUser={currentUser}
        displayOnlyPinnedOptions
        commitTitle={commit && commit.title}
        onPinnedCommentClick={handleGoToComment}
        isUnpinning={isUnpinning}
      />
    </div>
  );
}

export default connector(PinnedComment);
