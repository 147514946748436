// @flow
import * as React from "react";
import Media from "core/components/Media";
import CoreProjectOverview from "core/components/ProjectOverview";
import type { Project as TProject, ReactRouterLocation } from "core/types";
import connector from "./connector";

export type OwnProps = {|
  location: ReactRouterLocation,
  params: { projectId: string },
|};

export type StateProps = {|
  project: TProject,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
};

class ProjectOverview extends React.Component<Props> {
  render() {
    return (
      <Media mobile>
        {(mobile) => (
          <CoreProjectOverview mobile={mobile} project={this.props.project} />
        )}
      </Media>
    );
  }
}

export default connector(ProjectOverview);
