// @flow
import * as React from "react";
import Avatar, { type AvatarBorderColor } from "core/components/Avatar";
import ButtonLink from "core/components/ButtonLink";
import ProfileModal from "core/components/ProfileModal";
import type { User } from "core/types";

type Props = {
  user: ?User,
  size: number,
  className?: string,
  badgeIcon?: string,
  deletedComment?: boolean,
  borderColor?: AvatarBorderColor,
};

type State = { modalIsOpen: boolean };

export default class AvatarWithModal extends React.Component<Props, State> {
  state = { modalIsOpen: false };

  showModal = (e: SyntheticEvent<*>) => {
    this.setState({ modalIsOpen: true });
  };

  hideModal = (e: SyntheticEvent<*>) => {
    this.setState({ modalIsOpen: false });
  };

  render() {
    if (!this.props.user || this.props.deletedComment) {
      return (
        <Avatar
          size={this.props.size}
          className={this.props.className}
          deletedComment={this.props.deletedComment}
        />
      );
    }

    return (
      <React.Fragment>
        <ButtonLink onClick={this.showModal}>
          <Avatar
            userId={this.props.user.id}
            size={this.props.size}
            className={this.props.className}
            badgeIcon={this.props.badgeIcon}
            borderColor={this.props.borderColor}
          />
        </ButtonLink>
        <ProfileModal
          {...this.props.user}
          isOpen={this.state.modalIsOpen}
          onClose={this.hideModal}
        />
      </React.Fragment>
    );
  }
}
