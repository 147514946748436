// @flow
import apiRequest from "abstract-di/api";
import { entitiesReceived, entitiesDeleted } from "core/actions/entities";
import { showToast } from "core/actions/toasts";
import { Abstract } from "core/lib/abstract";
import defineRequest from "core/requests";
import {
  normalizeTeamProjectMembershipResponse,
  normalizeTeamProjectMembershipsResponse,
  normalizeTeamProjectMembershipsForTeamResponse,
} from "core/schemas/teamProjectMembership";
import type {
  ThunkAction,
  Dispatch,
  TeamProjectMembershipParams,
  OrganizationTeamDescriptor,
  PaginatedProjectTeamsParams,
} from "core/types";

export const AddTeamProjectMembershipRequest = defineRequest<
  TeamProjectMembershipParams,
  TeamProjectMembershipParams,
>({
  id: (params) => {
    const { projectId, teamId } = params;
    return `post:team_project_membership/${projectId}-${teamId}`;
  },
  perform: (params) => {
    const { projectId, teamId } = params;
    return apiRequest("post", `projects/${projectId}/teams`, {
      teamId,
      role: "organization",
    });
  },
  onError: (error: Error, params, dispatch: Dispatch) => {
    dispatch(showToast({ text: "There was a problem adding this team." }));
  },
  onSuccess: (response, params, dispatch: Dispatch): ThunkAction => {
    const { entities } = normalizeTeamProjectMembershipResponse(response);
    return dispatch(entitiesReceived(entities));
  },
});

export const RemoveTeamProjectMembershipRequest = defineRequest<
  TeamProjectMembershipParams,
  TeamProjectMembershipParams,
>({
  id: (params) => {
    const { projectId, teamId } = params;
    return `delete:team_project_membership/${projectId}-${teamId}`;
  },
  perform: (params) => {
    const { projectId, teamId } = params;
    return apiRequest("delete", `projects/${projectId}/teams/${teamId}`);
  },
  onError: (error: Error, params, dispatch: Dispatch) => {
    dispatch(showToast({ text: "There was a problem removing this team." }));
  },
  onSuccess: (response, params, dispatch: Dispatch) => {
    dispatch(
      entitiesDeleted({
        teamProjectMemberships: [`${params.projectId}-${params.teamId}`],
      })
    );
  },
});

export const PaginatedTeamProjectMembershipsRequest = defineRequest<
  PaginatedProjectTeamsParams,
  PaginatedProjectTeamsParams,
>({
  id(params) {
    const { limit, offset, projectId } = params;
    return `get:projects/${projectId}/teams/limit/${limit}/offset/${offset}}`;
  },
  perform(params) {
    return apiRequest("get", `projects/${params.projectId}/teams`, {
      limit: params.limit,
      offset: params.offset,
    });
  },
  onSuccess(response, params, dispatch) {
    const { entities } = normalizeTeamProjectMembershipsResponse(response);
    return dispatch(entitiesReceived(entities));
  },
  invalidateable: true,
});

function recursiveOnSuccess(response, params, dispatch) {
  if (
    Array.isArray(response.data.teamProjectMemberships) &&
    response.data.teamProjectMemberships.length === params.limit
  ) {
    const newParams = {
      ...params,
      offset: params.offset + params.limit,
    };

    return dispatch(
      PaginatedTeamProjectMembershipsRequest.perform({
        params: newParams,
        onSuccess: (response) =>
          recursiveOnSuccess(response, newParams, dispatch),
      })
    );
  }
}

export const TeamProjectMembershipsFetchAllRequest = defineRequest<
  Abstract.ProjectDescriptor,
  Abstract.ProjectDescriptor,
>({
  id: (params) => {
    const { projectId } = params;
    return `get:projects/${projectId}/teams/`;
  },
  perform(params, dispatch, getState) {
    const paginatedParams = { ...params, limit: 100, offset: 0 };

    return dispatch(
      PaginatedTeamProjectMembershipsRequest.perform({
        params: paginatedParams,
        onSuccess: (response) => {
          recursiveOnSuccess(response, paginatedParams, dispatch);
        },
      })
    );
  },
  force: false,
  invalidateable: true,
});

// With pagination omitted, this request will return a default limit of 20 entries.
export const TeamProjectMembershipsRequest = defineRequest<
  Abstract.ProjectDescriptor,
  Abstract.ProjectDescriptor,
>({
  id: (params) => {
    return `get:team_project_memberships/${params.projectId}`;
  },
  perform: (params) => {
    return apiRequest("get", `projects/${params.projectId}/teams`);
  },
  onSuccess: (response, params, dispatch: Dispatch) => {
    const { entities } = normalizeTeamProjectMembershipsResponse(response);
    return dispatch(entitiesReceived(entities));
  },
});

export const TeamProjectMembershipsForTeamRequest = defineRequest<
  OrganizationTeamDescriptor,
  OrganizationTeamDescriptor,
>({
  id: (params) => {
    return `get:organizations/${params.organizationId}/teams/${params.teamId}/projects`;
  },
  perform: (params) => {
    return apiRequest(
      "get",
      `organizations/${params.organizationId}/teams/${params.teamId}/projects`
    );
  },
  onSuccess: (response, params, dispatch: Dispatch) => {
    const { entities } =
      normalizeTeamProjectMembershipsForTeamResponse(response);
    return dispatch(entitiesReceived(entities));
  },
});

type PaginatedTeamProjectMembershipParams = {
  ...OrganizationTeamDescriptor,
  query?: string,
  offset: number,
  limit: number,
};

export const PaginatedTeamProjectMembershipRequest = defineRequest<
  PaginatedTeamProjectMembershipParams,
  PaginatedTeamProjectMembershipParams,
>({
  id: (params) => {
    return [params.organizationId, params.teamId, params.limit, params.query]
      .filter((f) => Boolean(f))
      .join("-");
  },
  perform: (params) => {
    return apiRequest(
      "get",
      `organizations/${params.organizationId}/teams/${params.teamId}/projects`,
      {
        limit: params.limit,
        offset: params.offset,
        search: params.query,
      }
    );
  },
  onSuccess: (response, params, dispatch: Dispatch) => {
    const { entities } =
      normalizeTeamProjectMembershipsForTeamResponse(response);
    dispatch(entitiesReceived(entities));
  },
});
