// @flow
import window from "core/global/window";
import { isDesktop, isWeb } from "core/lib/platform";

const SPOOFED_DESKTOP_DOMAIN = "desktop.abstract.com";

export const buildUrlBase = (): string => {
  if (isDesktop) {
    return `https://${SPOOFED_DESKTOP_DOMAIN}`;
  }

  if (isWeb) {
    return `${window.location.protocol}//${window.location.host}`;
  }

  return "";
};
