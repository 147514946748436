// @flow
import type { SharedItem } from "core/types";

type IdParams =
  | {
      projectId: string,
      sha: string,
      fileId: string,
      objectId: string,
    }
  | SharedItem;

export function uniqueId(params: IdParams) {
  const { projectId, sha, fileId } = params;
  let id: string = "";
  if (typeof params.objectId === "string") {
    id = params.objectId;
  } else if (typeof params.id === "string") {
    id = params.id;
  }
  return `${projectId}-${sha}-${fileId}-${id}`;
}
