// @flow
import { filter } from "lodash";

// This is what we want - where `mixed` has a default value of {} but since
// we're on a old version of Babel, the parser doesn't recognize this syntax.
// reference: https://github.com/gajus/eslint-plugin-flowtype/issues/378
//
// export default function genericFilter<FilteredType, I: mixed = {}>(
export default function genericFilter<FilteredType, I>(
  items: I,
  predicate: $Shape<FilteredType>
): Array<FilteredType> {
  // $FlowFixMe https://github.com/facebook/flow/issues/1414#issuecomment-469635898
  return filter(items, predicate);
}
