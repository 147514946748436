// @flow
import { connect } from "react-redux";
import type { State, Dispatch } from "core/types";
import type { Props, StateProps } from "./";

function mapStateToProps(state: State): StateProps {
  return {
    visibleDialogName: state.app.visibleDialogName,
    visibleDialogProps: state.app.visibleDialogProps,
  };
}

export default connect<Props, {}, StateProps, _, State, Dispatch>(
  mapStateToProps
);
