// @flow
import classnames from "classnames";
import * as empty from "empty";
import {
  filter,
  reduce,
  forEach,
  without,
  find,
  keys,
  values,
  isEqual,
  memoize,
} from "lodash";
import * as React from "react";
import { Waypoint } from "react-waypoint";
import Button from "core/components/Button";
import EmptyError from "core/components/Empty/Error";
import FileHeader from "core/components/FileHeader/Top";
import Flex from "core/components/Flex";
import Loaded from "core/components/Loaded";
import SectionHeader from "core/components/SectionHeader";
import Spinner from "core/components/Spinner";
import VirtualizedList, {
  type Row,
  type InnerListProps,
} from "core/components/VirtualizedList";
import ZoomInput from "core/components/ZoomInput";
import window from "core/global/window";
import connectStorage from "core/hocs/connectStorage";
import matchString from "core/lib/matchString";
import * as Change from "core/models/change";
import * as File from "core/models/file";
import * as Layer from "core/models/layer";
import * as Page from "core/models/page";
import { getLayerCommentCountsKey } from "core/selectors/comments";
import type {
  Changeset,
  ChangesetStatus,
  ChangeStatusCounts,
  FilePreviews as TFilePreviews,
  File as TFile,
  Layer as TLayer,
  Page as TPage,
  Branch,
  MultiSelectedEntities,
} from "core/types";
import Filters from "./FilePreviewsFilters";
import LayerThumbnail from "./LayerThumbnail";
import LinkedSymbolListItem from "./LinkedSymbolListItem";
import { getNonVisualRows } from "./nonVisualItems";
import style from "./style.scss";

type OpenSections = { [sectionId: string]: boolean };

type OwnProps = {|
  previewsRef?: React.Ref<typeof FilePreviews>, // eslint-disable-line no-use-before-define
  isLibraryPage?: boolean,
  isLoading: boolean,
  hasError?: boolean,
  changeset: ?Changeset,
  branch?: Branch,
  files: TFile[],
  pages: { [string]: TPage },
  previewsByFile: TFilePreviews,
  layerStatuses: { [layerId: string]: ChangesetStatus },
  layerCommentCounts: { [string]: number },
  changeStatusCounts?: ChangeStatusCounts,
  pageId?: string,
  fileId?: string,
  sha?: string,
  projectId: string,
  branchId?: string,
  contextBranchId: string,
  showLoader?: string,
  showFileHeaders?: boolean,
  getLayerPath?: (layer: TLayer) => Object,
  hideLayerMenu?: boolean,
  hideContextMenu?: boolean,
  zoom: number,
  fileActions?: (fileId: string, isSelecting: boolean) => ?React.Element<*>,
  header?: React.Node,
  footer?: React.Node,
  emptyContent: () => React.Node,
  className?: string,
  filter?: string,
  query: string,
  selectedEntities?: MultiSelectedEntities,
  selectedCount: number,
  isSelecting: boolean,
  canSelect: boolean,
  mobile?: boolean,
  onLoadMore?: () => void,
  onLayerClick?: (layer: TLayer) => void,
  onZoomChange: (event: SyntheticEvent<*>, zoom: number) => void,
  onSearchFilter?: (event: SyntheticInputEvent<>) => void,
  onClickStatusFilter?: (filter?: string) => void,
  onSelectEntities?: ({
    page?: TPage,
    layer?: TLayer,
    layers?: TLayer[],
  }) => void,
  onSelectLayerRange?: (
    layer: TLayer,
    layers: TLayer[],
    entities?: MultiSelectedEntities,
    selected: boolean
  ) => void,
  layerSelected?: (layer: TLayer) => boolean,
  fileSelected?: (file: TFile) => boolean,
  filePartiallySelected?: (file: TFile) => boolean,
  pageSelected?: (page: TPage) => boolean,
  pagePartiallySelected?: (page: TPage) => boolean,
  useLatestCommit?: boolean,
|};

type StorageProps = {|
  ref?: React.Ref<typeof FilePreviews>, // eslint-disable-line no-use-before-define
  defaultOpenSections: OpenSections,
  onOpenSection: (OpenSections) => void,
|};

type Props = {
  ...OwnProps,
  ...StorageProps,
};

type State = {
  openSections: OpenSections,
  isScrolledToTop: boolean,
};

const EMPTY_HEIGHT = 104;
const DEFAULT_HEADER_HEIGHT = 164;
const DEFAULT_FOOTER_HEIGHT = 130;
const THUMBNAIL_LABEL_HEIGHT = 44;
const ERROR_MESSAGE_HEIGHT = 244;
const FILE_HEADER_HEIGHT = 80;
const PAGE_HEADER_HEIGHT = 48;
const LOADER_HEIGHT = 100;
const FOOTER_SCROLL_ID = "footer";

const calculateOpenSections = (props: OwnProps) => {
  const open = {};

  forEach(props.pages, (page, pageId) => (open[pageId] = true));
  forEach(keys(props.previewsByFile), (fileId) => {
    const previewsBySection = props.previewsByFile[fileId];
    const empty = !find(
      keys(previewsBySection),
      (sectionId) => !File.contentTypes[sectionId]
    );
    forEach(File.contentTypes, (item, contentTypeId) => {
      if (previewsBySection[contentTypeId]) {
        open[`${fileId}-${contentTypeId}`] = empty && !props.showFileHeaders;
      }
    });
  });

  return open;
};

const calculateThumbnailChanges = (props: Props) => {
  return !!find(values(props.previewsByFile), (previewsBySection) => {
    return find(previewsBySection, (items, sectionId: string) => {
      if (sectionId === "symbol" && items.length) {
        return true;
      }
      if (!File.contentTypes[sectionId] && items.length) {
        return true;
      }
    });
  });
};

class FilePreviews extends React.Component<Props, State> {
  static defaultProps = {
    query: "",
    header: null,
    footer: null,
    isLoading: false,
    hideLayerMenu: false,
    hideContextMenu: false,
    selectedCount: 0,
    isSelecting: false,
    canSelect: false,
    previewsByFile: {},
    defaultOpenSections: {},
    onOpenSection: (openSections = {}) => {},
  };

  state = {
    openSections: this.props.defaultOpenSections,
    isScrolledToTop: true,
  };

  listRef = React.createRef<VirtualizedList>();
  hasThumbnailChanges = calculateThumbnailChanges(this.props);

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.isLoading &&
      !isEqual(this.props.defaultOpenSections, this.state.openSections)
    ) {
      this.setState({ openSections: this.props.defaultOpenSections });
    }

    if (prevProps.isLoading && !this.props.isLoading) {
      this.hasThumbnailChanges = calculateThumbnailChanges(this.props);
    }
  }

  get mobile(): boolean {
    return window.innerWidth < 800;
  }

  scrollTo = ({
    id,
    index,
    bottom,
    offset,
    onScrolled,
  }: {
    id?: string,
    index?: number,
    bottom?: boolean,
    offset?: number,
    onScrolled?: () => void,
  }) => {
    const list = this.listRef.current;

    if (!list) {
      return;
    }

    if (id && id !== FOOTER_SCROLL_ID) {
      this.setState(
        (prev) => ({ openSections: { ...prev.openSections, [id]: true } }),
        () => {
          this.props.onOpenSection(this.state.openSections);
          list.scrollTo({ id, offset }, onScrolled);
        }
      );
    } else {
      list.scrollTo({ id, bottom, index, offset }, onScrolled);
    }
  };

  getInnerListRef = () => {
    const list = this.listRef.current;
    return list ? list.listRef.current : list;
  };

  isLastPage = (page: TPage) => {
    const lastFile = this.props.files[this.props.files.length - 1];
    if (page.fileId !== lastFile.id) {
      return false;
    }
    const fileSectionIds = Object.keys(this.props.previewsByFile[page.fileId]);
    const filePageIds = without(fileSectionIds, Object.keys(File.contentTypes));
    return Page.uniqueId(page) === filePageIds[filePageIds.length - 1];
  };

  layerStatus = (layer: TLayer): ChangesetStatus => {
    return this.props.layerStatuses[Layer.uniqueId(layer)] || "none";
  };

  layerRemoved = (layer: TLayer): boolean => {
    return Change.isRemoved(this.layerStatus(layer));
  };

  layerSelected = (layer: TLayer): boolean => {
    return !!(this.props.layerSelected && this.props.layerSelected(layer));
  };

  pageSelected = (page: TPage, layers: TLayer[]): boolean => {
    if (!this.props.filter) {
      if (this.props.pageSelected && this.props.pageSelected(page)) {
        return true;
      }
      if (this.props.showLoader && this.isLastPage(page)) {
        return false;
      }
    }

    return layers.every(this.layerSelected);
  };

  pagePartiallySelected = (page: TPage, layers: TLayer[]): boolean => {
    if (
      !this.props.filter &&
      this.props.pagePartiallySelected &&
      this.props.pagePartiallySelected(page)
    ) {
      return true;
    }

    return layers.some(this.layerSelected);
  };

  handleSelectLayer = (layer: TLayer, selected: boolean) => {
    if (!this.props.canSelect) {
      return;
    }

    return (event: SyntheticMouseEvent<>) => {
      event.preventDefault();
      event.stopPropagation();

      const { onSelectEntities, onSelectLayerRange } = this.props;

      if (event.shiftKey) {
        if (onSelectLayerRange && typeof onSelectLayerRange === "function") {
          return onSelectLayerRange(
            layer,
            this.getSelectableLayers(),
            this.props.selectedEntities,
            selected
          );
        }
      }

      if (onSelectEntities) {
        onSelectEntities({ layers: [layer], selected });
      }
    };
  };

  handleSelectPage =
    (page: TPage, selected: boolean, layers: TLayer[]) =>
    (event: SyntheticMouseEvent<>) => {
      event.preventDefault();
      event.stopPropagation();

      if (!layers.length) {
        return;
      }

      const { onSelectEntities, showLoader } = this.props;
      const hasMore = this.isLastPage(page) ? showLoader : false;

      if (onSelectEntities && typeof onSelectEntities === "function") {
        onSelectEntities({
          page: { ...page, hasMore },
          layers,
          selected,
        });
      }
    };

  handleToggleSection = (sectionId: string) => (event: SyntheticEvent<>) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState(
      (prev) => ({
        openSections: {
          ...prev.openSections,
          [sectionId]: !prev.openSections[sectionId],
        },
      }),
      () => this.props.onOpenSection(this.state.openSections)
    );
  };

  handleScrollToTop = () => this.scrollTo({ index: 0 });

  handleHideScrollButton = () => this.setState({ isScrolledToTop: true });
  handleShowScrollButton = () => this.setState({ isScrolledToTop: false });

  getSelectableLayersForPage: (page: TPage) => TLayer[] = (page) => {
    const layers = this.props.previewsByFile[page.fileId][Page.uniqueId(page)];

    return filter(layers, (layer) => !this.layerRemoved(layer));
  };

  getSelectableLayersForFile: (file: TFile) => TLayer[] = memoize(
    (file) => {
      return reduce(
        this.props.previewsByFile[file.id],
        (memo, layers, pageId: string) => {
          if (pageId === "nonVisual") {
            return memo;
          }
          return memo.concat(
            filter(
              layers,
              (layer) =>
                !this.layerRemoved(layer) &&
                matchString(layer.name, this.props.query)
            )
          );
        },
        []
      );
    },
    (file) => this.props.query
  );

  getSelectableLayers = (): TLayer[] => {
    return reduce(
      this.props.files,
      (memo, file) => memo.concat(this.getSelectableLayersForFile(file)),
      []
    );
  };

  getSpacerRow = ({ file, height }: { file?: TFile, height?: number }) => {
    return {
      height: height || 16,
      data: { file },
      children: <div />,
    };
  };

  getDependencyRows = (data: {
    file: TFile,
    layers: Object[],
    type: string,
  }): Row[] => {
    if (this.props.query) {
      return empty.array;
    }

    const sectionId = `${data.file.id}-${data.type}`;
    const sectionOpen = this.state.openSections[sectionId];
    const items = getNonVisualRows({
      type: data.type,
      file: data.file,
      changes: data.layers,
    });

    if (!items.length) {
      return empty.array;
    }

    const rows = [
      {
        height: PAGE_HEADER_HEIGHT,
        data: { file: data.file, type: data.type },
        scrollId: sectionId,
        groupId: sectionId,
        className: classnames(style.sectionHeader, style.hoverable, {
          [style.sectionCollapsed]: !sectionOpen,
        }),
        children: this.renderSectionHeader({
          file: data.file,
          type: data.type,
          itemCount: items.length,
        }),
      },
    ];

    if (sectionOpen) {
      rows.push(...items, this.getSpacerRow({ file: data.file }));
    }

    return rows;
  };

  getThumbnailRows = (data: {
    file: TFile,
    layers: TLayer[],
    type?: string,
    pageId?: string,
    fromLibrary: boolean,
  }): Row[] => {
    const query = this.props.query.toLowerCase();
    const filteredLayers = query
      ? data.layers.filter((layer) => matchString(layer.name, query))
      : data.layers;

    if (!filteredLayers.length) {
      return empty.array;
    }

    const sectionId = data.pageId || `${data.file.id}-${data.type || ""}`;
    const sectionOpen = this.state.openSections[sectionId];
    const rowData = { file: data.file, type: data.type, pageId: data.pageId };
    const thumbnails = sectionOpen
      ? filteredLayers.map((layer, index) => ({
          gridItem: true,
          data: rowData,
          scrollId: sectionId,
          groupId: sectionId,
          gridRowClassName: classnames(style.hoverable, {
            [style.lastSectionRow]: index === filteredLayers.length - 1,
          }),
          height: ({ columnWidth }) => columnWidth + THUMBNAIL_LABEL_HEIGHT,
          children: this.renderThumbnail(
            layer,
            data.file,
            data.fromLibrary,
            index
          ),
        }))
      : empty.array;

    return [
      {
        height: PAGE_HEADER_HEIGHT,
        data: rowData,
        scrollId: sectionId,
        groupId: sectionId,
        className: classnames(style.sectionHeader, style.hoverable, {
          [style.sectionCollapsed]: !sectionOpen,
        }),
        children: this.renderSectionHeader({
          file: data.file,
          pageId: data.pageId,
          type: data.type,
          itemCount: filteredLayers.length,
        }),
      },
      ...thumbnails,
    ];
  };

  getLinkedSymbolRows = (data: {
    file: TFile,
    layers: TLayer[],
    type: string,
  }): Row[] => {
    if (this.props.query) {
      return empty.array;
    }

    const sectionId = `${data.file.id}-${data.type}`;
    const sectionOpen = this.state.openSections[sectionId];
    const rowData = { file: data.file, type: data.type };
    const rows = sectionOpen
      ? [
          ...data.layers.map((layer, index) => ({
            data: rowData,
            className: classnames(style.dependencyRow, style.hoverable, {
              [style.firstSectionRow]: index === 0,
              [style.lastSectionRow]: index === data.layers.length - 1,
            }),
            scrollId: sectionId,
            groupId: sectionId,
            defaultHeight: 48,
            children: this.renderLinkedSymbol(layer, data.file),
          })),
          this.getSpacerRow({ file: data.file }),
        ]
      : empty.array;

    return [
      {
        height: PAGE_HEADER_HEIGHT,
        data: rowData,
        scrollId: sectionId,
        groupId: sectionId,
        className: classnames(style.sectionHeader, style.hoverable, {
          [style.sectionCollapsed]: !sectionOpen,
        }),
        children: this.renderSectionHeader({
          file: data.file,
          type: data.type,
          itemCount: data.layers.length,
        }),
      },
      ...rows,
    ];
  };

  getItems = (): Row[] => {
    const mobile = this.mobile;
    let empty = true;
    let rows = [];

    if (this.props.header) {
      rows.push({
        defaultHeight: DEFAULT_HEADER_HEIGHT,
        className: style.header,
        children: this.props.header || null,
      });
    }

    if (this.props.changeStatusCounts || this.props.onSearchFilter) {
      rows.push({
        height: 48,
        children: this.renderFilters(),
      });
    }

    this.props.files.forEach((file, index) => {
      const previewsBySection = this.props.previewsByFile[file.id] || {};
      let nonVisualSectionIds = [];
      let sectionRows = [];

      forEach(previewsBySection, (items, sectionId) => {
        if (File.contentTypes[sectionId]) {
          if (sectionId !== "nonVisual" && sectionId !== "symbol") {
            nonVisualSectionIds.push(sectionId);
          }
          return;
        }
        const page = this.props.pages[sectionId];
        sectionRows.push(
          ...this.getThumbnailRows({
            file,
            layers: items,
            pageId: sectionId,
            fromLibrary: Page.isLibrary(page),
          })
        );
      });

      if (previewsBySection.symbol) {
        sectionRows.push(
          ...this.getLinkedSymbolRows({
            file,
            type: "symbol",
            layers: previewsBySection.symbol,
          })
        );
      }

      nonVisualSectionIds.forEach((sectionId) => {
        sectionRows.push(
          ...this.getDependencyRows({
            file,
            type: sectionId,
            layers: previewsBySection[sectionId],
          })
        );
      });

      if (previewsBySection.nonVisual) {
        sectionRows.push(
          ...this.getDependencyRows({
            file,
            type: "nonVisual",
            layers: previewsBySection.nonVisual,
          })
        );
      }

      if (sectionRows.length) {
        empty = false;
        if (this.props.showFileHeaders) {
          rows.push({
            height: FILE_HEADER_HEIGHT,
            data: { file },
            scrollId: file.id,
            children: this.renderFileHeader(file, index),
          });
        }
        if (mobile) {
          rows.push(...sectionRows);
        } else {
          const spacer = this.getSpacerRow({ file });
          rows.push(spacer, ...sectionRows, spacer);
        }
      }
    });

    if (this.props.hasError) {
      rows.push({
        height: ERROR_MESSAGE_HEIGHT,
        children: <EmptyError />,
      });
    } else if (empty) {
      rows.push({
        height: EMPTY_HEIGHT,
        className: classnames(style.emptyContentRow, {
          [style.noFooterEmptyContent]: !this.props.footer,
          [style.noHeaderEmptyContent]: !rows.length,
        }),
        children: this.renderEmptyContent(),
      });
    } else if (this.props.showLoader) {
      rows.push({
        height: LOADER_HEIGHT,
        children: this.renderLoader(),
      });
    } else if (!mobile) {
      rows.push(this.getSpacerRow({ height: 60 }));
    }

    if (this.props.footer) {
      rows.push({
        scrollId: FOOTER_SCROLL_ID,
        defaultHeight: DEFAULT_FOOTER_HEIGHT,
        children: this.props.footer || null,
      });
    }

    return rows;
  };

  renderLoader = () => {
    return (
      <div className={style.spinner}>
        <Spinner small />
        {!!this.props.onLoadMore && (
          <Waypoint onEnter={this.props.onLoadMore} />
        )}
      </div>
    );
  };

  renderThumbnail = (
    layer: TLayer,
    file: TFile,
    fromLibrary: boolean,
    index: number
  ) => {
    const isSelected = this.layerSelected(layer);

    return (
      <LayerThumbnail
        layer={layer}
        layerPath={
          this.props.getLayerPath ? this.props.getLayerPath(layer) : undefined
        }
        projectId={this.props.projectId}
        branchId={this.props.contextBranchId}
        fileType={file.type}
        fromLibrary={fromLibrary}
        hideLayerMenu={this.props.hideLayerMenu}
        hideContextMenu={this.props.hideContextMenu}
        commentCount={
          this.props.layerCommentCounts[getLayerCommentCountsKey(layer)]
        }
        highlight={this.props.query}
        status={this.layerStatus(layer)}
        isDeleted={this.layerRemoved(layer)}
        isSelected={isSelected}
        isSelecting={this.props.isSelecting}
        onClick={this.props.onLayerClick}
        onSelect={this.handleSelectLayer(layer, isSelected)}
        useLatestCommit={this.props.useLatestCommit}
        qaSelector={`file-preview-thumbnail-${index}`}
      />
    );
  };

  renderLinkedSymbol = (layer: TLayer, file: TFile) => {
    return (
      <LinkedSymbolListItem
        className={style.item}
        layer={layer}
        status={this.layerStatus(layer)}
      />
    );
  };

  renderSectionHeader = ({
    file,
    pageId,
    type,
    itemCount,
  }: {
    file: TFile,
    pageId?: string,
    type?: string,
    itemCount: number,
  }) => {
    const page = pageId ? this.props.pages[pageId] : undefined;
    if (page) {
      const layers = this.getSelectableLayersForPage(page);
      const isSelected = this.pageSelected(page, layers);

      return (
        <SectionHeader
          label={page.name}
          icon={Page.icon(page)}
          itemCount={itemCount}
          canSelect={!!layers.length}
          isOpen={!!(pageId && this.state.openSections[pageId])}
          isSelected={isSelected}
          isSelecting={this.props.isSelecting}
          isPartiallySelected={this.pagePartiallySelected(page, layers)}
          onSelect={this.handleSelectPage(page, isSelected, layers)}
          onToggleCollapse={
            pageId ? this.handleToggleSection(pageId) : undefined
          }
        />
      );
    } else if (type) {
      const typeId = `${file.id}-${type}`;
      const contentType = File.contentTypes[type];
      return (
        <SectionHeader
          label={contentType.name}
          icon={contentType.icon}
          itemCount={itemCount}
          isOpen={!!this.state.openSections[typeId]}
          onToggleCollapse={this.handleToggleSection(typeId)}
        />
      );
    } else {
      return (
        <SectionHeader
          isOpen
          itemCount={itemCount}
          label="Unknown Page"
          icon="file-unknown"
        />
      );
    }
  };

  renderFileHeader = (file: TFile, index?: number = 0) => {
    return (
      <FileHeader
        file={file}
        branchId={
          (this.props.branch && this.props.branch.id) || this.props.branchId
        }
        layers={this.getSelectableLayersForFile(file)}
        className={style.fileHeader}
        highlight={this.props.query}
        isSelecting={this.props.isSelecting}
        layerSelected={this.props.layerSelected}
        fileSelected={this.props.fileSelected}
        filePartiallySelected={this.props.filePartiallySelected}
        onSelectEntities={this.props.onSelectEntities}
        openFileButton={
          !this.props.mobile && this.props.fileActions
            ? this.props.fileActions(file.id, this.props.isSelecting)
            : undefined
        }
        qaSelector={`file-header-${index}`}
      />
    );
  };

  renderFilters = () => {
    return (
      <Filters
        isCommit
        branch={this.props.branch}
        statusCounts={this.props.changeStatusCounts}
        onClickFilter={this.props.onClickStatusFilter}
        onFilter={this.props.onSearchFilter}
        query={this.props.query}
        filter={this.props.filter}
      />
    );
  };

  renderStickyHeader = ({ file }: { file?: TFile }) => {
    if (!file) {
      return null;
    }
    return (
      <div key={`${file.id}-header`} className={style.sticky}>
        {this.renderFileHeader(file)}
      </div>
    );
  };

  renderEmptyContent = () => {
    return (
      <Flex align="center" justify="center" className={style.emptyContent}>
        <Loaded loading={this.props.isLoading}>
          {this.props.emptyContent()}
        </Loaded>
      </Flex>
    );
  };

  renderZoom = () => {
    const { isScrolledToTop } = this.state;
    return (
      <div className={style.zoom}>
        <div
          className={classnames(style.scrollToTop, {
            [style.scrollToTopHidden]: isScrolledToTop,
          })}
        >
          <Button
            nude
            tooltip={{ placement: "left" }}
            title={isScrolledToTop ? undefined : "Scroll to top"}
            icon="arrow-large-up"
            disabled={isScrolledToTop}
            onClick={this.handleScrollToTop}
          />
        </div>
        {this.hasThumbnailChanges && (
          <ZoomInput
            value={this.props.zoom}
            className={style.zoomInput}
            onChange={this.props.onZoomChange}
          />
        )}
      </div>
    );
  };

  renderChildren = (props: InnerListProps, excludeLastRow?: boolean) => {
    const list = this.getInnerListRef();
    const data =
      list && this.props.showFileHeaders
        ? list.getFirstVisibleRowData(props)
        : undefined;

    return (
      <React.Fragment>
        {data && this.renderStickyHeader(data)}
        <Waypoint
          onEnter={this.handleHideScrollButton}
          onLeave={this.handleShowScrollButton}
        />
        {list ? list.getGroupedChildren(props, excludeLastRow) : null}
      </React.Fragment>
    );
  };

  renderInnerListElement = (props: InnerListProps) => {
    if (!this.props.footer) {
      return (
        <div {...props} className={style.innerList}>
          {this.renderChildren(props)}
        </div>
      );
    }
    /*
      In order for the zoom controls to appear fixed over the list of
      thumbnails but not overlap the footer, we need to wrap everything
      in the list _except_ the footer in a separate <div>.
    */
    const list = this.getInnerListRef();
    const containerHeight = list ? list.props.height || 0 : 0;
    const lastChild = props.children[props.children.length - 1];
    const lastRowIndex = list ? list.rows.length - 1 : 0;
    const lastRowStyle = list ? list.getLastRowStyle() : {};
    return (
      <div {...props} className={style.innerList}>
        <div
          style={{
            height: lastRowStyle.top,
            top: 0,
            left: 0,
            width: "100%",
            position: "absolute",
          }}
        >
          {this.renderChildren(props, true)}
          <div style={{ top: containerHeight, position: "sticky", zIndex: 4 }}>
            {this.renderZoom()}
          </div>
        </div>
        {lastChild && lastChild.props.index === lastRowIndex ? lastChild : null}
      </div>
    );
  };

  render() {
    return (
      <div
        className={classnames(style.list, this.props.className, {
          [style.selecting]: this.props.isSelecting,
        })}
      >
        <VirtualizedList
          ref={this.listRef}
          zoom={this.props.zoom}
          items={this.getItems()}
          overscanCount={0}
          innerElementType={this.renderInnerListElement}
          resizeProps={{
            count: this.props.selectedCount,
            canSelect: this.props.canSelect,
            isScrolledToTop: this.state.isScrolledToTop,
            isSelecting: this.props.isSelecting,
            isLoading: this.props.isLoading,
            filter: this.props.filter,
            query: this.props.query,
            ...this.state.openSections,
          }}
        />
        {!this.props.footer && this.renderZoom()}
      </div>
    );
  }
}

export default connectStorage<OwnProps>(
  FilePreviews,
  (storage, props: OwnProps): StorageProps => {
    const storageId = `FilePreviews-${props.projectId}-${
      props.contextBranchId
    }-${props.sha || props.fileId || ""}-${props.pageId || ""}`;
    const previous = storage.getItem(storageId) || empty.object;

    return {
      ref: props.previewsRef,
      defaultOpenSections:
        previous.defaultOpenSections === undefined
          ? calculateOpenSections(props)
          : previous.defaultOpenSections,
      onOpenSection: (defaultOpenSections) => {
        storage.setItem(storageId, { defaultOpenSections });
      },
    };
  }
);
