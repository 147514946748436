// @flow
import * as React from "react";
import { setCurrentOrganizationId } from "../currentOrganization";
import type { Organization } from "../types";

type SaveCurrentOrganizationProps = {
  children: React.Node,
  currentOrganization: ?Organization,
  sessionLoading: boolean,
};

export default class SaveCurrentOrganization extends React.Component<SaveCurrentOrganizationProps> {
  componentDidUpdate(prevProps: SaveCurrentOrganizationProps) {
    if (prevProps.sessionLoading) {
      return;
    }

    const organizationId =
      this.props.currentOrganization && this.props.currentOrganization.id;

    if (organizationId) {
      setCurrentOrganizationId(organizationId);
    }
  }

  render() {
    return this.props.children;
  }
}
