// @flow
import classnames from "classnames";
import { isEqual } from "lodash";
import * as React from "react";
import AddSlackChannels from "core/components/AddSlackChannels";
import DialogForm from "core/components/DialogForm";
import InputSwitch from "core/components/InputSwitch";
import Select from "core/components/Select";
import type {
  Project,
  SlackChannel,
  SlackIntegration,
  SlackIntegrationOverrideDetails,
} from "core/types";
import type { OverrideProject } from "web/components/SlackIntegrationSettings/ProjectChannels";
import withForm from "web/containers/withForm";
import type { FormProps } from "web/types";
import style from "./style.scss";

type OwnProps = {|
  slackIntegration: SlackIntegration,
  projects: Project[],
  project: OverrideProject,
  defaultChannel: ?SlackChannel,
  isOpen: boolean,
  onClose: (event?: SyntheticEvent<>) => *,
  onSubmit: (
    formId: string,
    objectId: string,
    values: SlackIntegrationOverrideDetails
  ) => *,
|};
type Props = {
  ...OwnProps,
  ...FormProps,
};

type State = SlackIntegrationOverrideDetails;

class ProjectChannelDialog extends React.Component<Props, State> {
  static defaultProps = {
    projects: [],
  };

  state = {
    channel: this.props.form.values.channel,
    channels: this.props.form.values.channels,
    notificationsDisabled: this.props.form.values.notificationsDisabled,
  };

  handleSubmit = (event: SyntheticEvent<>) => {
    if (event) {
      event.preventDefault();
    }
    const { project, form, onSubmit, onClose, slackIntegration } = this.props;
    const { channel, channels, notificationsDisabled } = this.state;
    const objectId = project ? project.override.id : form.values.projectId;

    const details = slackIntegration.details.slackV2
      ? { channels, notificationsDisabled }
      : { channel, notificationsDisabled };

    onSubmit(form.id, objectId, details);
    onClose(event);
  };

  handleClose = (event: SyntheticEvent<>) => {
    if (event) {
      event.preventDefault();
    }
    const { project, form, onClose, slackIntegration } = this.props;

    if (slackIntegration.details.slackV2) {
      const defaultChannels =
        project && project.override ? project.override.details.channels : [];
      this.setState(
        {
          channels: defaultChannels,
        },
        () => {
          form.onChange(form.id, {
            channels: defaultChannels,
          });
          onClose(event);
        }
      );
    } else {
      onClose(event);
    }
  };

  handleSlackChannelChange = (event: SyntheticInputEvent<>) => {
    const channel = event.target.value;
    const { form } = this.props;
    this.setState({ channel }, () => form.onChange(form.id, { channel }));
  };

  handleSlackChannelAdd = (channel: SlackChannel) => {
    if (!this.state.channels) {
      return;
    }

    const { form } = this.props;
    this.setState(
      {
        channels: [...this.state.channels, channel.id],
      },
      () => {
        form.onChange(form.id, {
          channels: [...form.values.channels, channel.id],
        });
      }
    );
  };

  handleSlackChannelRemove = (channel: SlackChannel) => {
    const { form } = this.props;
    const filtered = form.values.channels.filter((c) => c !== channel.id);
    this.setState(
      {
        channels: filtered,
      },
      () => {
        form.onChange(form.id, {
          channels: filtered,
        });
      }
    );
  };

  isDirty = () => {
    const { form, slackIntegration } = this.props;
    if (slackIntegration.details.slackV2) {
      return (
        form.isDirty ||
        !isEqual(form.values.channels, slackIntegration.details.channels)
      );
    } else {
      return form.isDirty;
    }
  };

  handleProjectChange = (event: SyntheticInputEvent<>) => {
    this.props.form.onChange(this.props.form.id, {
      projectId: event.target.value,
    });
  };

  handleDisable = (event: SyntheticInputEvent<>) => {
    const notificationsDisabled = event.target.checked;
    const { form } = this.props;

    this.setState({ notificationsDisabled, channel: "" }, () => {
      form.onChange(form.id, { notificationsDisabled, channel: "" });
    });
  };

  get formIsValid(): boolean {
    return !!(
      this.props.form.values.projectId &&
      (this.state.channel ||
        (this.state.channels && this.state.channels.length > 0) ||
        this.state.notificationsDisabled)
    );
  }

  render() {
    const {
      slackIntegration,
      projects,
      project,
      isOpen,
      defaultChannel,
      form,
    } = this.props;

    const { channel, notificationsDisabled } = this.state;

    const channelsV1 = (
      <Select
        label="Slack channel"
        value={channel}
        onChange={this.handleSlackChannelChange}
        disabled={notificationsDisabled}
        name="channel"
      >
        <option>Select Slack channel…</option>
        {slackIntegration.details.availableChannels.map((c) => {
          const isDefault = defaultChannel && c.id === defaultChannel.id;
          return (
            <option key={c.id} value={c.id} disabled={isDefault && !project}>
              #{c.name}
              {isDefault && " (default)"}
            </option>
          );
        })}
      </Select>
    );

    const channelsV2 = (
      <div
        className={classnames({
          [style.channelsWrapperWithSelections]:
            form.values.channels.length > 0,
          [style.channelsWrapperWithoutSelections]:
            form.values.channels.length === 0,
        })}
      >
        <AddSlackChannels
          label={"Slack channels"}
          menuChannelCount={3}
          availableChannels={slackIntegration.details.availableChannels}
          selectedChannelIds={form.values.channels}
          onSelectChannel={this.handleSlackChannelAdd}
          onRemoveChannel={this.handleSlackChannelRemove}
        />
      </div>
    );

    return (
      <DialogForm
        onSubmit={this.handleSubmit}
        isOpen={isOpen}
        disabled={form.isSubmitting || !this.isDirty() || !this.formIsValid}
        onClose={this.handleClose}
        primaryButton={
          form.isSubmitting ? "Saving Project Channel…" : "Save Project Channel"
        }
        title="Project Channel"
      >
        <Select
          label="Project"
          defaultValue={project ? project.id : ""}
          onChange={this.handleProjectChange}
          disabled={!!project}
          name="projectId"
        >
          <option>Select project…</option>
          {projects.map((p) => (
            <option key={p.id} value={p.id}>
              {p.name}
            </option>
          ))}
        </Select>
        {slackIntegration.details.slackV2 ? channelsV2 : channelsV1}
        <InputSwitch
          checked={notificationsDisabled}
          name="notificationsDisabled"
          wrapperClass={style.switch}
          label="Disable notifications for project"
          onChange={this.handleDisable}
        />
      </DialogForm>
    );
  }
}

function getDefaultValues(props: OwnProps) {
  if (props.project) {
    const { notificationsDisabled, channel, channels } =
      props.project.override.details;
    return {
      projectId: props.project.id,
      notificationsDisabled,
      channel: channel || "",
      channels: channels || [],
    };
  }

  return {
    projectId: "",
    notificationsDisabled: false,
    channel: "",
    channels: [],
  };
}

export default withForm<OwnProps>(
  ProjectChannelDialog,
  (props) =>
    `${props.project ? props.project.id + "-" : ""}slack-project-channels-form`,
  getDefaultValues
);
