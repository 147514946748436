// @flow
import invariant from "invariant";
import { connect } from "react-redux";
import { openFile } from "abstract-di/actions";
import { showDialog, dismissDialog } from "core/actions/dialogs";
import { defaultBranch } from "core/models/branch";
import { canUseNewDefaultBranchName } from "core/selectors/features";
import type { State, Dispatch, FileMode } from "core/types";
import type { Props, OwnProps, StateProps, DispatchProps } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  return {
    defaultBranchName: defaultBranch({
      masterToMain: canUseNewDefaultBranchName(state),
      titleCase: true,
    }),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    openFile: ({
      projectId,
      branchId,
      fileId,
      sha,
      layerId,
      fileMode,
    }: {
      projectId: string,
      branchId: string,
      fileId: string,
      sha: string,
      layerId?: string,
      fileMode?: FileMode,
    }) => {
      invariant(openFile, "openFile required for dialog action");
      dispatch(
        openFile({
          projectId,
          branchId,
          fileId,
          sha,
          layerId,
          fileMode,
        })
      );
    },
    showDialog: (name: string, props: Object) => {
      dispatch(showDialog(name, props));
    },
    dismissDialog: () => {
      dispatch(dismissDialog());
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
