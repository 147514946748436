// @flow
import invariant from "invariant";
import * as React from "react";
import { connect } from "react-redux";
import { generateAndUploadPreviewsForCommit } from "abstract-di/actions";
import { FileFetchRequest } from "core/requests/files";
import { getProjectFeatureEnabled } from "core/selectors/features";
import { getFile } from "core/selectors/files";
import { getPreviewFailedToReGenerate } from "core/selectors/previews";
import type { State, Dispatch } from "core/types";
import type {
  Props,
  DefaultProps,
  OwnProps,
  StateProps,
  DispatchProps,
} from "./";

type Config = React.Config<Props, DefaultProps>;
type OwnConfig = React.Config<OwnProps, DefaultProps>;

function mapStateToProps(state: State, props: OwnConfig): StateProps {
  const { projectId, commitSha, fileId } = props;

  return {
    reGenerationFailed: getPreviewFailedToReGenerate(state, {
      projectId,
      commitSha,
    }),
    webp: getProjectFeatureEnabled(state, {
      feature: "thumbnails-webp",
      projectId,
    }),
    thumbnail: getProjectFeatureEnabled(state, {
      feature: "thumbnails-png",
      projectId,
    }),
    file: getFile(state, { projectId, sha: commitSha, fileId }),
    xdSupport: getProjectFeatureEnabled(state, {
      feature: "xd-support",
      projectId,
    }),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnConfig
): DispatchProps {
  return {
    reGeneratePreviews() {
      invariant(
        generateAndUploadPreviewsForCommit,
        "generateAndUploadPreviewsForCommit required for Preview Loader"
      );
      dispatch(
        generateAndUploadPreviewsForCommit(
          props.projectId,
          props.commitSha,
          false
        )
      );
    },

    getFileInfo() {
      const { projectId, commitSha, fileId } = props;

      dispatch(
        FileFetchRequest.perform({
          params: { projectId, sha: commitSha, fileId, branchId: "master" },
        })
      );
    },
  };
}

export default connect<
  Config,
  OwnConfig,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps, undefined, {
  pure: false,
});
