// @flow
import { static as Immutable } from "seamless-immutable";
import type { Action } from "core/types";

type QuickJumpState = {
  open: boolean,
  searchTerm: string,
  showShortcutTip: boolean,
};

const DEFAULT_STATE: QuickJumpState = Immutable.from({
  open: false,
  searchTerm: "",
  showShortcutTip: false,
});

const DEFAULT_ACTION = { type: "core/noop" };

export default function quickJump(
  state: QuickJumpState = DEFAULT_STATE,
  action: Action = DEFAULT_ACTION
): QuickJumpState {
  switch (action.type) {
    case "core/QUICK_JUMP_OPEN": {
      return Immutable.merge(state, {
        open: true,
        searchTerm: "",
        showShortcutTip: action.meta.showShortcutTip,
      });
    }
    case "TITLEBAR_FLYOVER":
    case "core/NOTIFICATIONS_OPEN":
    case "core/QUICK_JUMP_CLOSE": {
      return Immutable.set(state, "open", false);
    }
    case "core/QUICK_JUMP_TERM_CHANGE": {
      return Immutable.set(state, "searchTerm", action.payload);
    }
    default:
      return state;
  }
}
