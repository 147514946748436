// @flow
import * as React from "react";
import DialogForm from "core/components/DialogForm";
import SeatManager from "core/components/SeatManager";
import type { Subscription } from "web/types";
import style from "../style.scss";

type Props = {
  isOpen: boolean,
  onClose: () => *,
  title: string,
  submitText: string,
  subscription: Subscription,
  organizationId: string,
  isLoading: boolean,
  onDismiss: () => void,
};

export default function RemoveSeats(props: Props) {
  return (
    <SeatManager
      action="remove"
      controlSeats
      organizationId={props.organizationId}
      subscription={props.subscription}
      onSuccess={props.onDismiss}
    >
      {({
        recalculate,
        isLoading,
        hasError,
        onSubmit,
        inputValue,
        renderAvailableSeats,
        renderInput,
        renderSummary,
      }) => (
        <DialogForm
          isOpen={props.isOpen}
          onOpen={recalculate}
          onRequestClose={props.onClose}
          onClose={props.onClose}
          title={props.title || "Remove Contributor Seats"}
          primaryButton={`Remove ${inputValue} Seat${
            inputValue === 1 ? "" : "s"
          }`}
          disabled={isLoading || hasError || props.isLoading}
          contentClassName={style.manageSeats}
          onSubmit={onSubmit}
        >
          {renderAvailableSeats()}
          {renderInput()}
          {renderSummary()}
        </DialogForm>
      )}
    </SeatManager>
  );
}
