// @flow
import * as React from "react";
import { V3Link as Link } from "core/lib/router";
import { branchPath } from "core/lib/routes";
import BranchStatusBadge from "../BranchStatusBadge";
import ActivityBase from "./Base";
import type {
  ActivityBranchStatusUpdated as TActivityBranchStatusUpdated,
  Props,
} from "./types";

export default function ActivityBranchStatusUpdated(
  props: Props<TActivityBranchStatusUpdated>
) {
  const { activity, context } = props;
  return (
    <ActivityBase
      {...props}
      icon="branch"
      action={
        context.branchId ? (
          "changed the status to"
        ) : (
          <span>
            changed the status of{" "}
            <Link to={branchPath(activity.projectId, activity.branchId)}>
              {activity.payload.branchName}
            </Link>{" "}
            to
          </span>
        )
      }
      subject={
        <BranchStatusBadge
          currentStatus={activity.payload.status}
          activityItem
        />
      }
      when={activity.createdAt}
    />
  );
}
