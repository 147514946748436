// @flow
import * as React from "react";
import CollectionMultiSelect from "core/components/CollectionMultiSelect";
import Modal from "core/components/Modal";
import window from "core/global/window";
import type {
  Branch as TBranch,
  Collection,
  CollectionFormValues,
} from "core/types";
import AddItemsView from "./AddItemsView";
import connector from "./connector";

export type OwnProps = {|
  projectId: string,
  branch: TBranch,
  onClose: () => *,
  isOpen: boolean,
  collection: Collection,
|};

export type StateProps = {|
  isSubmitting: boolean,
  hasError: boolean,
  layerCount: number,
|};

export type DispatchProps = {|
  createCollection: (
    values: CollectionFormValues,
    onSuccess: (collection: Collection) => void
  ) => void,
  onClearSelected: (branchId: string) => void,
|};

type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type State = {
  query: string,
  filter?: string,
  name: string,
  description: string,
  submitting: boolean,
};

class AddItemsToCollection extends React.Component<Props, State> {
  state = {
    pageId: "",
    filter: undefined,
    query: "",
    name: "",
    description: "",
    submitting: false,
  };

  handleClose = () => {
    if (this.props.isSubmitting) {
      return;
    }

    const hasChanges = this.props.layerCount > 0;

    if (
      !hasChanges ||
      window.confirm("Are you sure you want to discard your selection?")
    ) {
      this.props.onClose();
      this.setState({
        name: "",
        description: "",
      });

      this.props.onClearSelected(this.props.branch.id);
    }
  };

  handleCreateCollection = (
    values: CollectionFormValues,
    onSuccess: (collection: Collection) => *
  ) => {
    this.props.createCollection(values, onSuccess);
  };

  handleSearchChange = (query: string) => {
    this.setState({ query });
  };

  handleTabChange = (filter?: string) => {
    this.setState({ filter: filter && filter !== "all" ? filter : undefined });
  };

  handleClearFilters = () => {
    this.setState({ query: "", filter: undefined });
  };

  handleFormChange = (values: CollectionFormValues) => {
    this.setState({ name: values.name, description: values.description });
  };

  renderModalTitle = () => {
    const { collection } = this.props;

    if (collection) {
      return (
        <React.Fragment>
          Adding items to <strong>{collection.name}</strong>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        Select items from <strong>{this.props.branch.name}</strong>
      </React.Fragment>
    );
  };

  render() {
    const {
      projectId,
      collection,
      isOpen,
      isSubmitting,
      layerCount,
      onClose,
      hasError,
    } = this.props;

    return (
      <Modal
        size="fullScreen"
        hideHeaderMobile
        isOpen={isOpen}
        onClose={this.handleClose}
        title={this.renderModalTitle()}
        qaSelector="file-preview-modal"
      >
        <CollectionMultiSelect
          useLatestCommit
          collection={collection}
          projectId={projectId}
          branchId={this.props.branch.id}
          onClose={onClose}
        >
          {({ onSubmit, title, hasMore }) => {
            const sharedProps = {
              title,
              projectId,
              branchId: this.props.branch.id,
              layerCount,
              hasMore,
              isSubmitting: !hasError && isSubmitting,
              onSubmitSelection: onSubmit,
              onDiscard: this.handleClose,
            };

            return (
              <AddItemsView
                projectId={projectId}
                branch={this.props.branch}
                onSearchChange={this.handleSearchChange}
                onFilterChange={this.handleTabChange}
                onClearFilters={this.handleClearFilters}
                collection={collection}
                filter={this.state.filter}
                query={this.state.query}
                {...sharedProps}
              />
            );
          }}
        </CollectionMultiSelect>
      </Modal>
    );
  }
}

export default connector(AddItemsToCollection);
