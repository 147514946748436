// @flow
import classnames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { fetchReplies } from "core/actions/comments";
import type {
  ActivityCommentCreated as TActivityCommentCreated,
  Props as ActivityProps,
} from "core/components/Activity/types";
import ActivityComment from "core/components/Comment/ActivityComment";
import CommentPreview from "core/components/CommentPreview";
import CommentThread from "core/components/CommentThread";
import { V3Link as Link } from "core/lib/router";
import { branchPath, layerLocation, commitPath } from "core/lib/routes";
import { BRANCH_NAME_MASTER, defaultBranch } from "core/models/branch";
import {
  reviewStatusIcon,
  ReviewStatuses,
  type ReviewStatus,
} from "core/models/review";
import {
  getComment,
  getRepliesForComment,
  getUserForComment,
} from "core/selectors/comments";
import {
  getFeatureEnabled,
  canUseNewDefaultBranchName,
} from "core/selectors/features";
import type { Comment as TComment, LocationDescriptor } from "core/types";
import ActivityBase from "./Base";
import style from "./commentCreated.scss";

type COMMENT_TYPE = "branch" | "commit" | "layer";

type InjectedProps = {
  narrow?: boolean,
  comment: TComment,
  replies: TComment[],
  parentId: string,
  loadReplies: () => void,
  isCroppedAnnotationPreviewsEnabled: boolean,
};

type StateProps = {|
  defaultBranchName: string,
|};

type Props = ActivityProps<TActivityCommentCreated> &
  InjectedProps &
  StateProps;

function reviewStatusMessage(status: ?ReviewStatus, isBranch: boolean) {
  switch (status) {
    case ReviewStatuses.APPROVED:
      return isBranch ? "approved this branch" : "approved";
    case ReviewStatuses.REJECTED:
      return isBranch
        ? "requested changes on this branch"
        : "requested changes on";
    default:
      return isBranch ? "commented" : "commented on";
  }
}

class ActivityCommentCreated extends React.Component<Props> {
  get commentType(): COMMENT_TYPE {
    const { commentLayerId, commitSha } = this.props.activity.payload;

    if (commentLayerId) {
      return "layer";
    }
    if (commitSha) {
      return "commit";
    }
    return "branch";
  }

  get linkProps(): { to: LocationDescriptor } {
    const { activity, parentId } = this.props;

    switch (this.commentType) {
      case "layer":
        return {
          to: layerLocation(
            activity.projectId,
            activity.branchId,
            activity.payload.commitSha || "",
            activity.payload.commentFileId || "",
            activity.payload.commentLayerId || "",
            { commentId: parentId }
          ),
        };
      case "commit":
        return {
          to: commitPath(
            activity.projectId,
            activity.branchId,
            activity.payload.commitSha || "",
            parentId
          ),
        };
      case "branch":
      default:
        return {
          to: branchPath(activity.projectId, activity.branchId),
        };
    }
  }

  get preview() {
    const {
      comment,
      activity,
      breakpoint,
      isCroppedAnnotationPreviewsEnabled,
    } = this.props;

    if (
      comment &&
      comment.projectId &&
      comment.commitSha &&
      comment.fileId &&
      comment.layerId
    ) {
      return (
        <CommentPreview
          comment={comment}
          layerName={activity.payload.commentLayerName || ""}
          isCroppedAnnotationPreviewsEnabled={
            isCroppedAnnotationPreviewsEnabled
          }
          breakpoint={breakpoint}
          {...this.linkProps}
        />
      );
    }

    return null;
  }

  get subject() {
    const type = this.commentType;

    if (type === "branch" && this.props.context.branchId) {
      return "";
    }

    const { defaultBranchName } = this.props;
    const { payload } = this.props.activity;

    let subject;
    if (type === "branch") {
      subject =
        payload.commitBranchName === BRANCH_NAME_MASTER
          ? defaultBranchName
          : payload.commitBranchName;
    }
    if (type === "commit") {
      subject = payload.commitMessage;
    }
    if (type === "layer") {
      subject = payload.commentLayerName;
    }

    if (!subject) {
      return "";
    }

    return <Link {...this.linkProps}>{subject}</Link>;
  }

  get action() {
    const { comment, activity, context } = this.props;
    const isDeleted = comment && comment.deletedAt;
    const isAnnotation = comment && comment.annotation;
    const type = this.commentType;
    const isBranch = !!(type === "branch" && context.branchId);
    let action;

    if (activity.payload.reviewStatus) {
      action = reviewStatusMessage(activity.payload.reviewStatus, isBranch);
    } else {
      if (isBranch) {
        action = isDeleted ? "" : "commented";
      } else {
        action = isDeleted
          ? " on"
          : isAnnotation
          ? "annotated"
          : "commented on";
      }
    }

    if (isBranch) {
      return action;
    } else {
      return `${action} the ${type}`;
    }
  }

  render() {
    const {
      comment,
      context,
      defaultBranchName,
      loadReplies,
      narrow,
      parentId,
      replies,
    } = this.props;

    const { projectId, branchId, payload } = this.props.activity;

    const {
      commitSha,
      commitBranchName,
      commentLayerName: layerName,
      commentFileName: fileName,
      commentPageName: pageName,
      commitMessage: commitTitle,
      commentLayerId: layerId,
      commentFileId: fileId,
      commentPageId: pageId,
    } = payload;

    const branchName =
      commitBranchName === BRANCH_NAME_MASTER
        ? defaultBranchName
        : commitBranchName;

    if (!comment) {
      return null;
    }

    const preview = this.preview;

    return (
      <div
        className={classnames(style.wrap, {
          [style.default]: !narrow,
        })}
      >
        <ActivityBase
          {...this.props}
          className={style.activity}
          icon={
            payload.reviewStatus
              ? reviewStatusIcon(payload.reviewStatus || "REQUESTED") ||
                "comment-new"
              : "comment-default"
          }
          action={this.action}
          subject={this.subject}
          deletedComment={!!comment.deletedAt}
          showMeta={false}
          preview={
            <React.Fragment>
              <CommentThread
                replyFormClass={style.form}
                commentComponent={ActivityComment}
                key={`${parentId}-thread`}
                parentId={parentId}
                projectId={projectId}
                parent={comment}
                replies={replies}
                context={context}
                branchId={branchId}
                loadReplies={loadReplies}
                commitSha={commitSha || ""}
                branchName={branchName || ""}
                layerName={layerName || ""}
                fileName={fileName || ""}
                pageName={pageName || ""}
                commitTitle={commitTitle || ""}
                layerId={layerId || ""}
                fileId={fileId || ""}
                pageId={pageId || ""}
                preview={
                  !!preview && <div className={style.preview}>{preview}</div>
                }
              />
            </React.Fragment>
          }
        />
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const { comment, activity } = props;

  const parentId = comment
    ? comment.parentId || comment.id
    : activity.payload.commentParentId || activity.payload.commentId;

  const defaultBranchName = defaultBranch({
    masterToMain: canUseNewDefaultBranchName(state),
    titleCase: true,
  });

  return {
    parentId,
    defaultBranchName,
    user: getUserForComment(state, { id: parentId }),
    comment: getComment(state, { id: parentId }),
    replies: getRepliesForComment(state, { id: parentId }),
    isCroppedAnnotationPreviewsEnabled: getFeatureEnabled(state, {
      feature: "activity-feed-cropped-annotations",
    }),
  };
}

function mapDispatchToProps(
  dispatch: *,
  props: ActivityProps<TActivityCommentCreated>
) {
  const { projectId, payload } = props.activity;
  const { commentParentId, commentId } = payload;

  const parentId = props.comment
    ? props.comment.parentId || props.comment.id
    : commentParentId || commentId;

  return {
    loadReplies: () => dispatch(fetchReplies({ id: parentId, projectId })),
  };
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityCommentCreated);
