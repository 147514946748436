// @flow
import { connect } from "react-redux";
import { getTeamMembership } from "core/selectors/teamMemberships";
import type { State, Dispatch } from "core/types";
import type { OwnProps, StateProps, Props } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const teamMembership = getTeamMembership(state, {
    teamId: props.team.id,
    userId: props.user.id,
  });

  return {
    inTeam: !!teamMembership,
  };
}

export default connect<Props, OwnProps, StateProps, _, State, Dispatch>(
  mapStateToProps
);
