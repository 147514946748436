// @flow
import { debounce } from "lodash";
import * as React from "react";
import type { AnchorPosition } from "core/components/AnimatedFlyover";
import Avatar from "core/components/Avatar";
import FilterPopover, { type Item } from "core/components/FilterPopover";
import filterUsers from "core/lib/filterUsers";
import type { User } from "core/types";
import connector from "./connector";

export type OwnProps = {|
  selectedItem: ?Item,
  onSelectItem: (itemId: string) => void,
  buttonText: string | React.Node,
  placeholderText?: string,
  className?: string,
  disclaimer?: string,
  disabled?: boolean,
  allowDeselection?: boolean,
  anchor?: AnchorPosition,
  mobile?: boolean,
  projectId: string,
  qaSelector?: string,
|};

export type StateProps = {|
  users: User[],
  isOnline: boolean,
|};

export type DispatchProps = {|
  onSearchForUsers: (search: string) => void,
|};

export type Props = {| ...OwnProps, ...StateProps, ...DispatchProps |};

type State = {
  isOpen: boolean,
  searchValue: string,
  searchResults: Item[],
  selectedItem?: Item,
  isLoading: boolean,
};

class AsyncUserFilter extends React.Component<Props, State> {
  static defaultProps = {
    placeholderText: "Search…",
    anchor: "center",
    disabled: false,
    allowDeselection: true,
  };

  searchRequestId = 0;
  mounted: boolean = true;

  state = {
    isOpen: false,
    searchValue: "",
    searchResults: [],
    selectedItem: undefined,
    isLoading: false,
  };

  componentDidMount() {
    this.mounted = true;
    this.loadSelectedItem();
    this.fetchUserSuggestionsForSearchValue();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleChangeSearchBar = debounce((searchValue: string) => {
    this.setState({ searchValue }, this.fetchUserSuggestionsForSearchValue);
  }, 200);

  onSelectItem = (itemId: string) => {
    this.props.onSelectItem(itemId);
    if (this.state.selectedItem && itemId === this.state.selectedItem.id) {
      this.setState({
        selectedItem: undefined,
      });
      return;
    }

    this.setState({
      selectedItem: this.state.searchResults.filter((item) => {
        return item.id === itemId;
      })[0],
    });
  };

  fetchUserSuggestionsForSearchValue = async () => {
    if (!this.props.projectId) {
      return;
    }
    this.setState({ isLoading: true });

    await this.props.onSearchForUsers(this.state.searchValue);

    const users = this.props.users;
    const suggestions = filterUsers(users, this.state.searchValue);
    const items: Item[] = suggestions.map((author) => ({
      id: author.id,
      name: author.name,
      icon: <Avatar userId={author.id} />,
      extra: `@${author.username}`,
    }));

    this.setState({ searchResults: items, isLoading: false });
  };

  loadSelectedItem = () => {
    if (!this.props.selectedItem) {
      this.setState({ selectedItem: undefined });
      return;
    }

    if (
      !this.props.selectedItem ||
      (this.state.selectedItem &&
        this.props.selectedItem.id === this.state.selectedItem.id)
    ) {
      return;
    }

    this.setState({
      searchResults: [this.props.selectedItem],
      selectedItem: this.props.selectedItem,
    });
  };

  render() {
    return (
      <FilterPopover
        {...this.props}
        selectedItem={this.state.selectedItem}
        items={this.state.searchResults}
        isLoading={this.state.isLoading}
        isOffline={!this.props.isOnline}
        onSelectItem={this.onSelectItem}
        onSearchChange={this.handleChangeSearchBar}
      />
    );
  }
}

export default connector(AsyncUserFilter);
