// @flow
import classnames from "classnames";
import * as React from "react";
import { V3Link as Link } from "core/lib/router";
import { branchPath } from "core/lib/routes";
import type { Branch, User } from "core/types";
import style from "./style.scss";

type Props = {
  parent: Branch,
  projectId: string,
  user: ?User,
  children: React.Node,
  className?: string,
  inline?: boolean,
};

export default function BranchedFromDescription(props: Props) {
  const { parent, projectId, user, className, inline, children } = props;

  return (
    <div className={classnames(style.description, className)}>
      Branched from{" "}
      <Link to={branchPath(projectId, parent.id)} className={style.parentName}>
        {parent.name}
      </Link>{" "}
      by {user ? user.name : "Unknown"}
      {inline ? (
        <React.Fragment>{children}</React.Fragment>
      ) : (
        <div>{children}</div>
      )}
    </div>
  );
}
