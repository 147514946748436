// @flow
import { connect } from "react-redux";
import type { State, Dispatch, ReactRouterLocation } from "core/types";
import Confirmation from "web/components/Onboarding/Confirmation";

type OwnProps = {|
  location: ReactRouterLocation,
|};

type StateProps = {|
  isOnboarding: boolean,
  shouldRedirect: boolean,
  message?: string,
  organizationId?: string,
  projectId?: string,
|};

type Props = {
  ...OwnProps,
  ...StateProps,
};

function mapStateToProps(state: State, props: OwnProps): StateProps {
  return {
    isOnboarding: true,
    shouldRedirect: !(props.location.state && props.location.state.onboarding),
  };
}

export default connect<Props, OwnProps, StateProps, _, State, Dispatch>(
  mapStateToProps
)(Confirmation);
