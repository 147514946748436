// @flow
import { schema, normalize } from "normalizr";
import {
  collection,
  collectionLayer,
  file,
  page,
  layer,
  branch,
} from "core/schema";
import { envelopeSchema } from "./envelope";

const collectionsResponseSchema = envelopeSchema(
  new schema.Object({
    collections: [collection],
    files: [file],
    pages: [page],
    layers: [layer],
    branches: [branch],
  })
);

export function normalizeCollections(collectionsResponse: *) {
  return normalize(collectionsResponse, collectionsResponseSchema);
}

export function normalizeCollection(collectionResponse: *) {
  return normalize(collectionResponse, collection);
}

export function normalizeCollectionResponse(response: *) {
  return normalize(response, envelopeSchema(collection));
}

export function normalizeCollectionLayer(response: *) {
  return normalize(response, collectionLayer);
}

export function normalizeCollectionLayers(response: *) {
  return normalize(response, new schema.Array(collectionLayer));
}

export function normalizeCollectionLayersResponse(response: *) {
  return normalize(response, envelopeSchema(new schema.Array(collectionLayer)));
}
