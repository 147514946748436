// @flow
// $FlowFixMe Cannot resolve module core/node_modules/libphonenumber-js/index.cjs
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { startsWith, trim } from "lodash";
import * as React from "react";
import Input from "core/components/Input";

const DEFAULT_COUNTRY_IDENTIFIER = "US";

type PhoneNumber = {
  number: string,
  isValid: () => boolean,
};

export type InputPhoneValue = {|
  name: string,
  value: ?string,
  invalid: boolean,
|};

type OwnProps = {|
  name: string,
  onChange?: (InputPhoneValue) => void,
|};

type Props = {
  ...OwnProps,
};

type State = {
  value: ?string,
  sanitizedValue: ?string,
  phoneNumber: ?PhoneNumber,
  error: ?string,
};

const getError = (inputValue, phoneNumber: ?PhoneNumber) => {
  const trimmedInputValue = trim(inputValue);
  const isEmpty = trimmedInputValue === "";

  const hasError = !isEmpty && (!phoneNumber || !phoneNumber.isValid());

  if (!hasError) {
    return null;
  }

  if (!startsWith(trimmedInputValue, "+")) {
    return "Please enter a valid phone number. For international numbers, please include a + sign before the country code.";
  }

  return "Please enter a valid phone number";
};

export default class InputPhone extends React.Component<Props, State> {
  state = {
    value: null,
    sanitizedValue: null,
    phoneNumber: null,
    error: null,
  };

  handleChange = (event: SyntheticInputEvent<>) => {
    const { onChange } = this.props;
    const value = event.target.value;

    const phoneNumber = parsePhoneNumberFromString(
      value,
      DEFAULT_COUNTRY_IDENTIFIER
    );

    const sanitizedPhoneNumber = phoneNumber ? phoneNumber.number : null;

    this.setState({
      value: value,
      sanitizedValue: sanitizedPhoneNumber,
      error: null,
      phoneNumber,
    });

    if (onChange) {
      onChange({
        name: this.props.name,
        value: sanitizedPhoneNumber,
        invalid: getError(value, phoneNumber) !== null,
      });
    }
  };

  onBlur = (event: SyntheticInputEvent<>) => {
    const error = getError(this.state.value, this.state.phoneNumber);
    this.setState({ error });
  };

  render() {
    const { onChange, ...rest } = this.props;

    return (
      <Input
        type="tel"
        onChange={this.handleChange}
        onBlur={this.onBlur}
        error={this.state.error}
        {...rest}
      />
    );
  }
}
