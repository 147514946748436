// @flow
import * as React from "react";
import { connect } from "react-redux";
import Loaded from "core/components/Loaded";
import { loginRequired } from "../actions/login";
import { isLoggedIn, sessionIsLoading } from "../selectors/session";

type Props = {
  children?: any,
  isLoading: boolean,
  isLoggedIn: boolean,
  loginRequired: () => *,
};

class RequireOnboardingAuthentication extends React.Component<Props> {
  redirectIfNecessary = () => {
    if (!this.props.isLoading && !this.props.isLoggedIn) {
      this.props.loginRequired();
    }
  };

  componentDidMount() {
    this.redirectIfNecessary();
  }

  componentDidUpdate() {
    this.redirectIfNecessary();
  }

  render() {
    return (
      <Loaded loading={!this.props.isLoggedIn}>
        {() => this.props.children}
      </Loaded>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoading: sessionIsLoading(state),
    isLoggedIn: isLoggedIn(state),
  };
}

const actions = { loginRequired };

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default connect(
  mapStateToProps,
  actions
)(RequireOnboardingAuthentication);
