// @flow
import { memoize } from "lodash";
import { connect } from "react-redux";
import {
  openUntracked,
  editFile,
  openLayerDebuggingDiff,
  copyToClipboard,
} from "abstract-di/actions";
import {
  isDevelopmentMenuEnabled,
  isCommitUnpushed,
  isOnline,
} from "abstract-di/selectors";
import { addLayerToCollection } from "core/actions/collectionLayers";
import { setPaginationTotal } from "core/actions/paginationTotals";
import { showLoadingToast } from "core/actions/toasts";
import createConnector from "core/lib/createConnector";
import {
  BranchCollectionsFetchRequest,
  CollectionCreateRequest,
} from "core/requests/collections";
import { ShareLinkCreateRequest } from "core/requests/shareLinks";
import { getBranch, getBranchHead } from "core/selectors/branches";
import {
  getCollection,
  getUpdatableCollections,
  getCollectionsWithIdenticalLayer,
  getCanCreateBranchCollections,
} from "core/selectors/collections";
import { getFeatureEnabled } from "core/selectors/features";
import { getFile } from "core/selectors/files";
import { getPaginationTotal } from "core/selectors/paginationTotals";

import {
  getOrganizationPolicy,
  getProjectPolicy,
} from "core/selectors/policies";
import { getProject } from "core/selectors/projects";
import type { State, Dispatch } from "core/types";
import type { OwnProps, StateProps, DispatchProps, Props } from "./";

const getInputShare = memoize(
  (props: OwnProps) => ({
    kind: props.collectionId ? "collection" : "layer",
    collectionLayerId: props.collectionLayerId,
    descriptor: {
      collectionId: props.collectionId,
      projectId: props.projectId,
      branchId: props.branchId,
      sha: props.layer.sha,
      fileId: props.layer.fileId,
      pageId: props.layer.pageId,
      layerId: props.layer.id,
    },
  }),
  (props: OwnProps) =>
    props.projectId +
    props.branchId +
    (props.collectionId || "") +
    (props.collectionLayerId || "") +
    props.layer.sha +
    props.layer.fileId +
    props.layer.pageId +
    props.layer.id
);

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { projectId, branchId, collectionId, layer } = props;
  const project = getProject(state, { projectId });
  const head = getBranchHead(state, { projectId, branchId });

  const policy = getOrganizationPolicy(state, {
    organizationId: project ? project.organizationId : "",
  });

  const isLatestCommitFeatureEnabled = project
    ? getFeatureEnabled(state, {
        organizationId: project.organizationId,
        feature: "latest-commit-enabled",
      })
    : false;
  const projectPolicy = getProjectPolicy(state, { projectId });

  const inputShare = getInputShare(props);
  const file = getFile(state, {
    projectId,
    sha: layer.sha,
    fileId: layer.fileId,
  });

  return {
    collection: collectionId
      ? getCollection(state, { projectId, collectionId })
      : undefined,
    development: isDevelopmentMenuEnabled(state),
    canAddToCollection: getCanCreateBranchCollections(state, {
      projectId,
      branchId,
    }),
    isLayerCommitUnpushed: isCommitUnpushed(state, {
      projectId,
      branchId,
      sha: layer.lastChangedAtSha,
    }),
    head,
    branch: getBranch(state, { branchId, projectId }),
    organizationId: project ? project.organizationId : "",
    collections: getUpdatableCollections(state, { projectId, branchId }),
    collectionsWithLayer: getCollectionsWithIdenticalLayer(state, {
      projectId,
      branchId,
      sha: layer.lastChangedAtSha,
      fileId: layer.fileId,
      layerId: layer.id,
    }),
    isLoadingCollections: BranchCollectionsFetchRequest.isFirstLoading(state, {
      projectId,
      branchId,
    }),
    inputShare,
    isLoadingShareLink: ShareLinkCreateRequest.isLoadingStrict(
      state,
      inputShare
    ),
    canShowHandoff: policy ? policy.showHandoff : false,
    canShare: projectPolicy.share,
    fileType: file ? file.type : undefined,
    isLatestCommitFeatureEnabled,
    isOnline: isOnline(state),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { projectId, branchId, layer, useLatestCommit } = props;

  return {
    loadCollections: () => {
      dispatch(
        BranchCollectionsFetchRequest.perform({
          force: false,
          params: {
            projectId,
            branchId,
          },
        })
      );
    },
    openDebuggingDiff: openLayerDebuggingDiff
      ? () =>
          dispatch(
            openLayerDebuggingDiff(
              projectId,
              layer.lastChangedAtSha,
              layer.fileId,
              layer.id
            )
          )
      : undefined,
    copyLayerId: copyToClipboard ? () => copyToClipboard(layer.id) : undefined,
    addLayerToCollection: (collectionId, isNewCollection, onSuccess) => {
      dispatch(
        addLayerToCollection({
          collectionId,
          projectId,
          branchId,
          layer,
          useLatestCommit,
          openNewCollection: isNewCollection,
          onSuccess,
        })
      );
    },
    handleOpenUntracked: () => {
      dispatch(
        openUntracked({
          projectId,
          branchId,
          fileId: layer.fileId,
          layerId: layer.id,
          sha: layer.sha,
        })
      );
    },
    handleEditInSketch: (sha: string) => {
      dispatch(
        editFile({
          projectId,
          branchId,
          fileId: layer.fileId,
          layerId: layer.id,
          sha,
        })
      );
    },
    createCollection: (values, successCallback) => {
      const loadingToast = dispatch(
        showLoadingToast({
          icon: "collection",
          text: `Creating a new collection…`,
        })
      );
      dispatch((dispatch, getState) => {
        dispatch(
          CollectionCreateRequest.perform({
            params: { projectId, branchId, ...values, published: true },
            onSuccess: (response) => {
              const collection = response.data;
              successCallback(collection);
              loadingToast.showAutoClose({ text: "New collection created" });

              const currentPaginationTotals = getPaginationTotal(
                getState(),
                branchId
              );

              dispatch(
                setPaginationTotal(
                  branchId,
                  currentPaginationTotals ? currentPaginationTotals + 1 : 0
                )
              );
            },
            onError: (error) => {
              loadingToast.error({
                text: "Failed to create collection",
                subtext: error.message,
              });
            },
          })
        );
      });
    },
    createShareLink: (inputShare) => {
      dispatch(
        ShareLinkCreateRequest.perform({
          params: inputShare,
        })
      );
    },
  };
}

export default createConnector<Props, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps)
);
