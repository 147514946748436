// @flow
import createCachedSelector from "@elasticprojects/re-reselect";
import { filter, find } from "lodash";
import * as TeamProjectMembership from "core/models/teamProjectMembership";
import { getRawEntities } from "core/selectors/entities";
import { getProjectId, getTeamId } from "core/selectors/helpers";
import type {
  TeamProjectMembership as TTeamProjectMembership,
  State,
} from "core/types";

export function getTeamProjectMembershipEntities(state: State): {
  [string]: TTeamProjectMembership,
} {
  return getRawEntities(state, "teamProjectMemberships");
}

function getUniqueId(state, props: { projectId: string, teamId: string }) {
  return TeamProjectMembership.uniqueId(props);
}

export const getTeamProjectMembership: (
  State,
  { projectId: string, teamId: string }
) => TTeamProjectMembership = createCachedSelector(
  getTeamProjectMembershipEntities,
  getProjectId,
  getTeamId,
  (memberships, projectId, teamId) => find(memberships, { projectId, teamId })
)(getUniqueId);

export const getTeamProjectMembershipsForProject: (
  State,
  { projectId: string }
) => TTeamProjectMembership[] = createCachedSelector(
  getTeamProjectMembershipEntities,
  getProjectId,
  (memberships, projectId) => filter(memberships, { projectId })
)(getProjectId);

export const getTeamProjectMembershipsForTeam: (
  State,
  { teamId: string }
) => TTeamProjectMembership[] = createCachedSelector(
  getTeamProjectMembershipEntities,
  getTeamId,
  (memberships, teamId) => filter(memberships, { teamId })
)(getTeamId);
