// @flow
import * as React from "react";
import Icon from "core/components/Icon";
import Time from "core/components/Time";
import useBranchDisplayName from "core/hooks/useBranchDisplayName";
import { V3Link as Link } from "core/lib/router";
import { branchPath, projectPath, filePath } from "core/lib/routes";
import style from "./base.scss";

export default function ActivityMeta(props: {
  createdAt?: string,
  projectId?: string,
  projectName?: string,
  branchId?: string,
  branchName?: ?string,
  fileId?: ?string,
  fileName?: ?string,
  meta?: React.Node,
  context: Object,
}) {
  const {
    projectId,
    projectName,
    createdAt,
    context,
    branchId,
    branchName,
    fileId,
    fileName,
    meta,
  } = props;

  const branchDisplayName = useBranchDisplayName(
    branchId,
    branchName
  ).branchName;

  return (
    <span className={style.meta}>
      {" "}
      {createdAt && <Time date={createdAt} />}
      {meta && (
        <span>
          {" · "} {meta}
        </span>
      )}
      {projectId && !context.projectId ? (
        <span>
          {" · "}
          <Link to={projectPath(projectId)} className={style.breadcrumbLink}>
            {projectName}
          </Link>
        </span>
      ) : null}
      {projectId && branchId && !context.branchId ? (
        <span>
          {context.projectId ? (
            " · "
          ) : (
            <Icon type="slash" className={style.icon} />
          )}
          <Link
            to={branchPath(projectId, branchId)}
            className={style.breadcrumbLink}
          >
            {branchDisplayName}
          </Link>
        </span>
      ) : null}
      {projectId && branchId && fileId ? (
        <span>
          <Icon type="slash" className={style.icon} />
          <Link
            to={filePath(projectId, branchId, fileId)}
            className={style.breadcrumbLink}
          >
            {fileName}
          </Link>
        </span>
      ) : null}
    </span>
  );
}
