// @flow
import React, { useEffect, useState, useRef, useCallback } from "react";
import DialogForm from "core/components/DialogForm";
import Input from "core/components/Input";
import type { ValidationErrors } from "core/types";

type Props = {
  isOpen: boolean,
  isSubmitting: boolean,
  onSubmit: ({
    name: string,
    redirectURI: string,
    description: string,
  }) => Promise<void>,
  onClose: () => void,
  errors: ValidationErrors,
};

const OAuthApplicationForm = ({
  isOpen,
  isSubmitting,
  onSubmit,
  onClose,
  errors,
}: Props) => {
  const [name, setName] = useState("");
  const [redirectURI, setRedirectURI] = useState("");
  const [description, setDescription] = useState("");
  const [validationErrors, setValidationErrors] = useState(errors);
  const dialogForm = useRef();

  const resetInputsState = useCallback(() => {
    setName("");
    setRedirectURI("");
    setDescription("");
  }, []);

  useEffect(() => {
    setValidationErrors(errors);
  }, [errors]);

  useEffect(() => {
    if (!isOpen) {
      resetInputsState();
    }
  }, [isOpen, resetInputsState]);

  const {
    redirectUris: uriError,
    name: nameError,
    description: descriptionError,
  } = validationErrors;

  const handleClose = () => {
    setValidationErrors({});
    onClose();
  };

  const handleChange = (func) => (ev: SyntheticInputEvent<>) => {
    if (dialogForm.current) {
      dialogForm.current.checkValid();
    }
    func(ev.target.value);
  };

  const handleSubmit = () =>
    onSubmit({
      name,
      redirectURI,
      description,
    });

  return (
    <DialogForm
      ref={dialogForm}
      isOpen={isOpen}
      onClose={handleClose}
      onSubmit={handleSubmit}
      disabled={isSubmitting}
      title="New OAuth application"
      primaryButton="Create application"
    >
      <Input
        autoFocus
        label="Name"
        autoComplete="off"
        name="name"
        value={name}
        disabled={isSubmitting}
        onChange={handleChange(setName)}
        placeholder="Your application's name."
        error={nameError}
        requiredTag
        required
      />
      <Input
        label="Redirect URL"
        autoComplete="off"
        name="redirectURI"
        value={redirectURI}
        disabled={isSubmitting}
        onChange={handleChange(setRedirectURI)}
        placeholder="Your application's callback url."
        error={uriError}
        requiredTag
        required
      />
      <Input
        label="Description"
        autoComplete="off"
        name="description"
        value={description}
        disabled={isSubmitting}
        onChange={handleChange(setDescription)}
        placeholder="Your application's description."
        error={descriptionError}
      />
    </DialogForm>
  );
};

export default OAuthApplicationForm;
