// @flow
import classnames from "classnames";
import * as React from "react";
import Icon from "core/components/Icon";
import Popover from "core/components/Popover";
import type { Project } from "core/types";
import style from "./style.scss";

type Props = {|
  project: Project,
  light?: boolean,
  inline?: boolean,
  minimal?: boolean, // only shows when the project is private
  iconFill?: string,
  isPersonalProject?: boolean,
  className?: string,
|};

export default function ProjectType({
  project,
  isPersonalProject,
  inline,
  minimal,
  light,
  iconFill,
  className,
}: Props) {
  const isPrivate = project.visibility === "specific";
  const isTeamProject = !isPrivate && !isPersonalProject;
  const description = isPrivate
    ? "Private Project"
    : isPersonalProject
    ? "Personal Project"
    : "Team Project";

  const projectTypeClasses = classnames(style.name, className, {
    [style.inline]: inline,
  });

  const textStyle = iconFill ? { color: iconFill } : undefined;

  return (
    <span className={projectTypeClasses}>
      {isPrivate && (
        <Popover placement="bottom" label="Only visible to project members">
          <span className={style.inner} style={textStyle}>
            <Icon type="lock-locked" light={light} fill={iconFill} />
            {!minimal && description}
          </span>
        </Popover>
      )}
      {isTeamProject && (
        <Popover placement="bottom" label="Visible to all team members">
          <span className={style.inner} style={textStyle}>
            <Icon type="team-project" light={light} fill={iconFill} />
            {!minimal && description}
          </span>
        </Popover>
      )}
      {isPersonalProject && (
        <Popover placement="bottom" label="Visible to only you">
          <span className={style.inner} style={textStyle}>
            <Icon type="user-small" light={light} fill={iconFill} />
            {!minimal && description}
          </span>
        </Popover>
      )}
    </span>
  );
}
