// @flow
import invariant from "invariant";
import { getCurrentUserId } from "abstract-di/selectors";
import { trackEvent } from "core/actions/analytics";
import { entitiesDeleted } from "core/actions/entities";
import { resetCurrentUserTeamsPaginationTotal } from "core/actions/teams";
import { showToast } from "core/actions/toasts";
import {
  PaginatedTeamMembershipsRequest,
  TeamMembershipDeleteRequest,
  TeamMembershipCreateRequest,
  TeamMembershipUpdateRequest,
} from "core/requests/teamMemberships";
import { getTeamMembership } from "core/selectors/teamMemberships";
import { getTeam } from "core/selectors/teams";
import { getUser } from "core/selectors/users";
import type { ThunkAction, OrganizationTeamMembershipParams } from "core/types";

export function addTeamMembership(
  params: OrganizationTeamMembershipParams
): ThunkAction {
  return async (dispatch, getState) => {
    dispatch(
      TeamMembershipCreateRequest.perform({
        params,
        onSuccess: (response) => {
          dispatch(
            resetTeamMembershipsPaginationTotal({
              organizationId: params.organizationId,
              teamId: params.teamId,
            })
          );

          const currentUserId = getCurrentUserId(getState());
          if (params.userId === currentUserId) {
            dispatch(
              resetCurrentUserTeamsPaginationTotal(params.organizationId)
            );
          }
        },
      })
    );
  };
}

export function deleteTeamMembership(
  params: OrganizationTeamMembershipParams
): ThunkAction {
  return async (dispatch, getState) => {
    dispatch(
      TeamMembershipDeleteRequest.perform({
        params,
        onSuccess: (response) => {
          const { teamId, userId } = params;
          // Remove deleted Team Membership entity from the Redux store.
          const deletedTeamMembership = getTeamMembership(getState(), {
            teamId,
            userId,
          });
          if (deletedTeamMembership) {
            dispatch(
              entitiesDeleted({ teamMemberships: [deletedTeamMembership.id] })
            );
            dispatch(
              trackEvent("TEAM_MEMBER_REMOVED", {
                teamMembershipId: deletedTeamMembership.id,
                userId,
                teamId,
                organizationId: params.organizationId,
              })
            );
          }

          // Update the pagination total.
          dispatch(
            resetTeamMembershipsPaginationTotal({
              organizationId: params.organizationId,
              teamId: params.teamId,
            })
          );

          const currentUserId = getCurrentUserId(getState());
          if (userId === currentUserId) {
            dispatch(
              resetCurrentUserTeamsPaginationTotal(params.organizationId)
            );
          }

          // Display a confirmation toast.
          const deletedTeamMembershipUser = getUser(getState(), { userId });
          const deletedTeamMembershipTeam = getTeam(getState(), { teamId });
          dispatch(
            showToast({
              icon: "team",
              text: `${
                deletedTeamMembershipUser
                  ? deletedTeamMembershipUser.name
                  : "The user"
              } has been removed from ${
                deletedTeamMembershipTeam
                  ? deletedTeamMembershipTeam.name
                  : "the team"
              }`,
            })
          );
        },
      })
    );
  };
}

export function loadTeamMembershipsPreview(
  params: {|
    organizationId: string,
    teamId: string,
  |},
  options?: { limit: number, force?: boolean }
): ThunkAction {
  return async function (dispatch) {
    dispatch(
      PaginatedTeamMembershipsRequest.perform({
        params: {
          ...params,
          limit: (options && options.limit) || 6,
          offset: 0,
        },
        force: (options && options.force) || false,
      })
    );
  };
}

export function updateTeamMembership(params: {|
  ...OrganizationTeamMembershipParams,
  isAdmin: boolean,
|}): ThunkAction {
  return async function (dispatch, getState) {
    dispatch(
      TeamMembershipUpdateRequest.perform({
        params,
        onSuccess: (response) => {
          const updatedUser = getUser(getState(), { userId: params.userId });
          invariant(updatedUser, "updatedUser should be loaded.");
          dispatch(
            showToast({
              icon: "team",
              text: `${updatedUser.name} has been updated to ${
                params.isAdmin ? "an admin." : "a member."
              }`,
            })
          );
        },
      })
    );
  };
}

// Fetching a paginated team memberships request with limit: 0
// is the minimum call to get the pagination total meta value.
export function resetTeamMembershipsPaginationTotal(params: {|
  organizationId: string,
  teamId: string,
|}): ThunkAction {
  return async function (dispatch) {
    dispatch(loadTeamMembershipsPreview(params, { limit: 0, force: true }));
  };
}
