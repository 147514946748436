// @flow
import { connect } from "react-redux";
import { isOnline } from "abstract-di/selectors";
import { FetchUserSuggestionsRequest } from "core/requests/projectMemberships";
import { getUsersForProject } from "core/selectors/users";
import type { Dispatch, State } from "core/types";
import type { Props, OwnProps, StateProps, DispatchProps } from "./";

const SUGGESTIONS_TO_RENDER = 20;

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const projectUsers = getUsersForProject(state, {
    projectId: props.projectId,
  });

  return {
    users: projectUsers,
    isOnline: isOnline(state),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const projectId = props.projectId;

  return {
    onSearchForUsers: (search: string) => {
      return dispatch(
        FetchUserSuggestionsRequest.perform({
          params: {
            projectId,
            search,
            limit: SUGGESTIONS_TO_RENDER,
          },
        })
      );
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
