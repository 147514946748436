// @flow
import classnames from "classnames";
import { debounce } from "lodash";
import * as React from "react";
import { Helmet } from "react-helmet";
import { organizationPath } from "abstract-di/routes";
import Card from "core/components/Card";
import NoResults from "core/components/Empty/NoResults";
import NoTeams from "core/components/Empty/NoTeams";
import TeamsEnterprise from "core/components/Empty/TeamsEnterprise";
import Expand from "core/components/Expand";
import Flex from "core/components/Flex";
import Loaded from "core/components/Loaded";
import MainContent from "core/components/MainContent";
import Media from "core/components/Media";
import { createPaginationDataLoaderComponent } from "core/components/PaginationDataLoader";
import PeopleTeamsHeader from "core/components/PeopleTeamsHeader";
import TeamDialog from "core/components/TeamDialog";
import VirtualizedTeamsList from "core/components/VirtualizedTeamsList";
import { Abstract } from "core/lib/abstract";
import { replace, addQuery, removeQuery } from "core/lib/location";
import { CURRENTUSERTEAMS_LIMIT } from "core/lib/teams";
import { PaginatedOrganizationTeamsRequest } from "core/requests/teams";
import { getTeamsForOrganization } from "core/selectors/teams";
import type {
  ReactRouterLocation,
  Team,
  ViewType,
  User,
  ValidationErrors,
} from "core/types";

import connector from "./connector.js";
import style from "./style.scss";

export type OwnProps = {|
  params: Abstract.OrganizationDescriptor,
  location: ReactRouterLocation,
|};

export type StateProps = {|
  canListMembers: boolean,
  canCreateTeam: boolean,
  canUseTeams: boolean,
  currentUser: ?User,
  currentUserTeams: Team[],
  currentUserTeamsPaginationTotal: ?number,
  expandCurrentUserTeamsIsLoading: boolean,
  isOnline: boolean,
  createDialogIsLoading: boolean,
  searchFilter?: string,
  createDialogValidationErrors: ValidationErrors,
|};

export type DispatchProps = {|
  onLoad: () => void,
  loadRemainingCurrentUserTeams: (offset: number, limit: number) => void,
  onCreateTeam: ({ name: string, color: string }) => Promise<void>,
|};

type TeamsRequestParams = {
  ...Abstract.OrganizationDescriptor,
  search?: string,
  userId?: string,
};

const DataLoader = createPaginationDataLoaderComponent<
  TeamsRequestParams,
  Team[],
>(
  "OrganizationTeams",
  PaginatedOrganizationTeamsRequest,
  (response) => response.data.teams.length,
  getTeamsForOrganization,
  30
);

export type StorageProps = {|
  viewType: ViewType,
  onChangeViewType: (viewType: ViewType) => void,
|};

export type Props = {
  ...StorageProps,
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

export type PropsWithoutStorage = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

const handleSearchChange = debounce((ev: SyntheticInputEvent<>) => {
  const search = ev.target.value;
  if (search) {
    replace(addQuery({ s: search }));
  } else {
    replace(removeQuery("s"));
  }
}, 200);

function OrganizationTeams(props: Props) {
  const [displayMode, toggleDisplayMode] = React.useState(props.viewType);
  const [createDialogOpen, toggleCreateDialogOpen] = React.useState(false);

  const [currentUserTeamsExpanded, toggleCurrentUserTeamsExpanded] =
    React.useState(false);

  const {
    currentUserTeamsPaginationTotal,
    loadRemainingCurrentUserTeams,
    expandCurrentUserTeamsIsLoading,
    onChangeViewType,
    searchFilter,
    canListMembers,
  } = props;

  React.useEffect(() => {
    if (!canListMembers) {
      replace(organizationPath(props.params.organizationId));
    }
  }, [canListMembers, props.params.organizationId]);

  const showCurrentUserTeamsExpandButton =
    currentUserTeamsPaginationTotal &&
    currentUserTeamsPaginationTotal > CURRENTUSERTEAMS_LIMIT;

  const currentUserTeamsExpandButton = React.useMemo(() => {
    const onExpandCurrentUserTeams = () => {
      if (currentUserTeamsExpanded) {
        toggleCurrentUserTeamsExpanded(false);
      } else if (currentUserTeamsPaginationTotal) {
        loadRemainingCurrentUserTeams(
          CURRENTUSERTEAMS_LIMIT,
          currentUserTeamsPaginationTotal - CURRENTUSERTEAMS_LIMIT
        );
        toggleCurrentUserTeamsExpanded(true);
      }
    };
    return (
      showCurrentUserTeamsExpandButton &&
      !searchFilter && (
        <Card
          className={classnames({
            [style.expandButtonSideMargin]: displayMode === "grid",
            [style.expandButtonChin]: displayMode === "list",
          })}
          innerClassName={style.expandButtonInner}
          list={displayMode === "list"}
          wrappedList={displayMode === "list"}
        >
          <Expand
            onClick={onExpandCurrentUserTeams}
            expanded={currentUserTeamsExpanded}
            loading={expandCurrentUserTeamsIsLoading}
            hideBorder
          >
            {currentUserTeamsPaginationTotal
              ? `Showing ${CURRENTUSERTEAMS_LIMIT} of
      ${currentUserTeamsPaginationTotal} of your teams`
              : `Showing ${CURRENTUSERTEAMS_LIMIT} of your teams`}
          </Expand>
        </Card>
      )
    );
  }, [
    currentUserTeamsExpanded,
    currentUserTeamsPaginationTotal,
    displayMode,
    expandCurrentUserTeamsIsLoading,
    loadRemainingCurrentUserTeams,
    searchFilter,
    showCurrentUserTeamsExpandButton,
  ]);

  const teamRequestParams = React.useMemo(() => {
    const params = {
      organizationId: props.params.organizationId,
      search: props.searchFilter || undefined,
    };
    return params;
  }, [props.params.organizationId, props.searchFilter]);

  const renderEmpty = () => {
    if (props.searchFilter) {
      return <NoResults flex type="teams" term={props.searchFilter} />;
    }

    return (
      <NoTeams
        flex
        onClick={() => toggleCreateDialogOpen(true)}
        title="Teams"
        canCreateTeam={props.canCreateTeam}
      />
    );
  };

  const noTeams = (items) => {
    return items.length === 0 && props.currentUserTeams.length === 0;
  };

  const onDisplayGrid = React.useCallback(() => {
    onChangeViewType("grid");
    toggleDisplayMode("grid");
  }, [onChangeViewType]);

  const onDisplayList = React.useCallback(() => {
    onChangeViewType("list");
    toggleDisplayMode("list");
  }, [onChangeViewType]);

  return (
    <Media desktop>
      {(desktop) => {
        if (!desktop && displayMode === "grid") {
          toggleDisplayMode("list");
        }

        return (
          <MainContent className={style.mainContent}>
            <Flex column>
              <Helmet>
                <title>Teams</title>
              </Helmet>
              <PeopleTeamsHeader
                params={props.params}
                mode="teams"
                mobile={!desktop}
                isGrid={displayMode === "grid"}
                onPrimaryActionTrigger={() => toggleCreateDialogOpen(true)}
                onDisplayGrid={onDisplayGrid}
                onDisplayList={onDisplayList}
                onSearchChange={handleSearchChange}
                searchFilter={props.searchFilter}
                disabled={!props.canUseTeams}
                canCreateTeam={props.canCreateTeam}
              />
              {props.canUseTeams ? (
                <DataLoader params={teamRequestParams}>
                  {({
                    items,
                    hasNextPage,
                    isLoadingNextPage,
                    onLoadNextPage,
                  }: {
                    items: Team[],
                    hasNextPage: boolean,
                    isLoadingNextPage: boolean,
                    onLoadNextPage?: () => void,
                  } = {}) => (
                    <Loaded
                      loading={noTeams(items) && isLoadingNextPage}
                      title="Loading teams"
                      flex
                    >
                      {noTeams(items) && !isLoadingNextPage ? (
                        renderEmpty()
                      ) : (
                        <div className={style.wrap}>
                          <VirtualizedTeamsList
                            params={props.params}
                            currentUserTeams={
                              currentUserTeamsExpanded
                                ? props.currentUserTeams
                                : props.currentUserTeams.slice(
                                    0,
                                    CURRENTUSERTEAMS_LIMIT
                                  )
                            }
                            currentUserTeamsExpandButton={
                              currentUserTeamsExpandButton
                            }
                            allTeams={items}
                            searchFilter={props.searchFilter}
                            isGrid={desktop && displayMode === "grid"}
                            isMobile={!desktop}
                            hasNextPage={hasNextPage}
                            isLoadingNextPage={isLoadingNextPage}
                            onLoadNextPage={onLoadNextPage}
                            canCreateTeam={props.canCreateTeam}
                          />
                        </div>
                      )}
                    </Loaded>
                  )}
                </DataLoader>
              ) : (
                <TeamsEnterprise />
              )}
              <TeamDialog
                isOpen={createDialogOpen}
                isOnline={props.isOnline}
                isLoading={props.createDialogIsLoading}
                validationErrors={props.createDialogValidationErrors}
                desktop={desktop}
                toggleCreateDialogOpen={toggleCreateDialogOpen}
                onCreateTeam={props.onCreateTeam}
              />
            </Flex>
          </MainContent>
        );
      }}
    </Media>
  );
}

export default connector(OrganizationTeams);
