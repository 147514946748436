// @flow
import createCachedSelector from "@elasticprojects/re-reselect";
import { forEach, find, values } from "lodash";
import { getEntity, getRawEntities } from "core/selectors/entities";
import { getOrganizationId } from "core/selectors/helpers";
import type {
  State,
  ShareLink,
  InputShare,
  ShareLinkKind,
  ShareLinkParams,
} from "core/types";

export function getShareLink(state: State, id: string): ?ShareLink {
  return getEntity(state, "shareLinks", id);
}

function getShareLinks(state: State): { [id: string]: ShareLink } {
  return getRawEntities(state, "shareLinks");
}

function getKind(
  state: State,
  params: { inputShare: InputShare }
): ShareLinkKind {
  return params.inputShare.kind;
}

function getDescriptor(
  state: State,
  params: { inputShare: InputShare, sha?: string }
): ?ShareLinkParams {
  const { projectId, branchId, sha, fileId, layerId, pageId, collectionId } =
    params.inputShare.descriptor;

  switch (params.inputShare.kind) {
    case "layer":
      return {
        projectId,
        branchId,
        fileId,
        layerId,
      };
    case "commit":
      return { projectId, branchId, sha: params.sha || sha };
    case "project":
      return { projectId };
    case "collection":
      return { projectId, branchId, collectionId };
    case "branch":
      return { projectId, branchId };
    case "file":
      return { projectId, branchId, fileId };
    case "page":
      return { projectId, branchId, fileId, sha: params.sha || sha, pageId };
    default:
      return null;
  }
}

function cacheByParams(state: State, params: { inputShare: InputShare }) {
  let cacheKey = `${params.inputShare.kind}`;

  forEach(params.inputShare.descriptor, (value, key) => {
    cacheKey += `-${value}`;
  });

  if (params.inputShare.options) {
    forEach(params.inputShare.options, (value, key) => {
      cacheKey += `-${value}`;
    });
  }
  return cacheKey;
}

export const getShareLinkForKind: (
  state: State,
  params: { inputShare: InputShare, sha?: string }
) => ?ShareLink = createCachedSelector(
  [getShareLinks, getKind, getDescriptor],
  (shareLinks, kind, descriptor, options) => {
    return find(shareLinks, { kind, descriptor });
  }
)(cacheByParams);

export const getPublicShareLinksForOrganization: (
  state: State,
  props: { organizationId: string }
) => ShareLink[] = createCachedSelector(
  getShareLinks,
  getOrganizationId,
  (shareLinks, organizationId) => {
    if (!shareLinks) {
      return [];
    }

    return values(shareLinks).filter((link) => {
      return (
        link.organizationId === organizationId && link.options.public === true
      );
    });
  }
)(getOrganizationId);
