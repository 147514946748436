// @flow
import classnames from "classnames";
import currency from "currency.js";
import format from "date-fns/format";
import * as React from "react";
import Note from "core/components/Note";
import Spinner from "core/components/Spinner";
import style from "./style.scss";
import type { ActionType } from ".";

type Props = {
  annual: boolean,
  isLoading?: boolean,
  totalChange: number,
  nextPaymentDate: ?string,
  actionType: ActionType,
  coupons?: boolean,
  disabled?: boolean,
  seatCapEnforced: ?boolean,
  showNextBill: boolean,
};

export default function NewPaymentLine({
  annual,
  isLoading,
  totalChange,
  nextPaymentDate,
  actionType,
  coupons,
  disabled,
  seatCapEnforced,
  showNextBill,
}: Props) {
  const paymentDate = nextPaymentDate
    ? format(nextPaymentDate, "MMM D, YYYY")
    : "";

  return (
    <React.Fragment>
      <div
        className={classnames(style.lineWrap, { [style.disabled]: disabled })}
      >
        <div>
          {showNextBill && (
            <span>
              {actionType === "add" ? "Additional cost" : "Savings"} per{" "}
              {annual ? "year" : "month"}
            </span>
          )}
          <Note>Next payment is on {paymentDate}</Note>
        </div>
        {showNextBill ? (
          isLoading ? (
            <Spinner small />
          ) : (
            <strong>
              {disabled
                ? "N/A"
                : currency(totalChange).divide(100).format(true)}
              {coupons ? "*" : ""}
            </strong>
          )
        ) : null}
      </div>
    </React.Fragment>
  );
}
