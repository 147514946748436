// @flow
import get from "lodash/get";
import * as React from "react";
import { Abstract } from "core/lib/abstract";
import { altText } from "core/models/layer";
import type { Layer, LayerState } from "core/types";
import LayerCanvas from "../LayerCanvas";
import Transparency from "../Transparency";
import ZoomablePreview, {
  type ChangeEvent as ZoomState,
} from "../ZoomablePreview";
import Footer from "./Footer";
import OverlayDiff from "./OverlayDiff";
import SideBySideDiff from "./SideBySideDiff";
import connector from "./connector";
import style from "./style.scss";

function layerKey(params: Object) {
  return `${params.fileId}-${params.layerId}`;
}

const INITIAL_ZOOM_STATE: ZoomState = {
  scale: 0,
  scaledToFit: true,
  offsetX: 0,
  offsetY: 0,
};

export type CompareMode = "preview" | "sideBySide" | "overlay";

export type OwnProps = {|
  params: Abstract.LayerVersionDescriptor,
  shareId?: string,
  showPreviewTab?: boolean,
|};

export type ZoomStates = {
  [layerKey: string]: ?ZoomState,
};

export type StateProps = {|
  commitIsLatest?: boolean,
  compareLayer: ?Layer,
  compareLayerState: LayerState,
  layer: ?Layer,
  layerState: LayerState,
  canUsePreviewBackgroundSwitcher: boolean,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
};

type State = {
  compareMode: CompareMode,
  sideBySideZoomStates: ZoomStates,
  overlayZoomStates: ZoomStates,
  previewZoomStates: ZoomStates,
};

class LayerCompare extends React.Component<Props, State> {
  layerCanvas: ?LayerCanvas;

  constructor(props: Props) {
    super(props);

    this.state = {
      compareMode: props.showPreviewTab ? "preview" : "sideBySide",
      sideBySideZoomStates: {},
      overlayZoomStates: {},
      previewZoomStates: {},
    };
  }

  handleChangeZoomState = (zoomState: ZoomState) => {
    this.setState((state) => ({
      [`${state.compareMode}ZoomStates`]: {
        [layerKey(this.props.params)]: zoomState,
      },
    }));
  };

  handleZoomInputChange = (scale: number) => {
    this.setState((state) => {
      const zoomStateKey = `${state.compareMode}ZoomStates`;
      const zoomState: ZoomState =
        state[zoomStateKey][layerKey(this.props.params)] || INITIAL_ZOOM_STATE;

      return {
        [zoomStateKey]: {
          [layerKey(this.props.params)]: {
            ...zoomState,
            scaledToFit: false,
            scale,
          },
        },
      };
    });
  };

  handleZoomToFit = (zoomState: ZoomState) => {
    const zoomablePreview: ?ZoomablePreview = get(this, [
      "layerCanvas",
      "zoomablePreview",
    ]);

    if (!zoomablePreview) {
      return zoomState;
    }

    return {
      scale: zoomablePreview.scaleToFit,
      scaledToFit: true,
      offsetX: 0,
      offsetY: 0,
    };
  };

  setLayerCanvasRef = (layerCanvas: ?LayerCanvas) => {
    this.layerCanvas = layerCanvas;
  };

  render() {
    const zoomState: ZoomState =
      this.state[`${this.state.compareMode}ZoomStates`][
        layerKey(this.props.params)
      ] || INITIAL_ZOOM_STATE;

    return (
      <div className={style.layerCompare}>
        <Transparency
          useDynamicPreviewBackground={
            this.props.canUsePreviewBackgroundSwitcher
          }
        >
          {this.state.compareMode === "preview" && (
            <LayerCanvas
              params={this.props.params}
              layer={this.props.layer}
              layerMissing={this.props.layerState === "not_found"}
              alt={altText(this.props.layer)}
              zoomState={zoomState}
              onChangeZoomState={this.handleChangeZoomState}
              shareId={this.props.shareId}
            />
          )}
          {this.state.compareMode === "sideBySide" && (
            <SideBySideDiff
              layer={this.props.layer}
              layerState={this.props.layerState}
              compareLayer={this.props.compareLayer}
              compareLayerState={this.props.compareLayerState}
              params={this.props.params}
              zoomState={zoomState}
              onChangeZoomState={this.handleChangeZoomState}
              commitIsLatest={this.props.commitIsLatest}
              shareId={this.props.shareId}
              layerCanvasRef={this.setLayerCanvasRef}
            />
          )}
          {this.state.compareMode === "overlay" && (
            <OverlayDiff
              commitIsLatest={this.props.commitIsLatest}
              layer={this.props.layer}
              layerState={this.props.layerState}
              compareLayer={this.props.compareLayer}
              compareLayerState={this.props.compareLayerState}
              params={this.props.params}
              zoomState={zoomState}
              onChangeZoomState={this.handleChangeZoomState}
              shareId={this.props.shareId}
              layerCanvasRef={this.setLayerCanvasRef}
            />
          )}
        </Transparency>
        <Footer
          showPreviewTab={this.props.showPreviewTab}
          selectedCompareMode={this.state.compareMode}
          zoomState={zoomState}
          onChangeCompareMode={(compareMode) => this.setState({ compareMode })}
          onChangeZoomState={this.handleChangeZoomState}
          onZoomToFit={this.handleZoomToFit}
        />
      </div>
    );
  }
}

export default connector(LayerCompare);
