// @flow
import * as React from "react";
import InputCheckbox from "core/components/InputCheckbox";
import type { WebhookEvent } from "core/types";
import style from "./style.scss";

type Props = {
  data: string[],
  onChangeData?: (data: string[]) => void,
  webhookEvent: WebhookEvent,
};

export default class EventRow extends React.Component<Props> {
  onChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    let { data } = this.props;
    const { onChangeData, webhookEvent } = this.props;

    if (event.target.checked) {
      !data.includes(webhookEvent.id) && data.push(webhookEvent.id);
    } else {
      data = data.filter((typeId) => typeId !== webhookEvent.id);
    }
    onChangeData && onChangeData(data);
  };

  render() {
    let { data } = this.props;
    const { webhookEvent } = this.props;

    return (
      <InputCheckbox
        label={
          <React.Fragment>
            <span className={style.eventName}>{webhookEvent.name}</span>
            <span className={`${style.eventMeta} ${style.eventType}`}>
              {webhookEvent.id}
            </span>
          </React.Fragment>
        }
        checkboxClass={style.checkbox}
        checked={data.includes(webhookEvent.id)}
        key={webhookEvent.id}
        labelClass={style.checkboxLabel}
        onChange={this.onChange}
        responsive
        wrapperClass={style.checkboxWrapper}
      />
    );
  }
}
