// @flow
import classnames from "classnames";
import { reduce, map } from "lodash";
import * as React from "react";
import type { StarParams } from "core/actions/stars";
import NoSectionProjects from "core/components/Empty/NoSectionProjects";
import HorizontalSeparator from "core/components/HorizontalSeparator";
import ProjectItem from "core/components/ProjectListItem";
import ProjectSection from "core/components/ProjectSection";
import VirtualizedList, { type Row } from "core/components/VirtualizedList";
import connectStorage from "core/hocs/connectStorage";
import { DEFAULT_PROJECT_FILTER } from "core/lib/lists";
import { clearTextSelection } from "core/lib/selection";
import type { Section, Project, ProjectSort } from "core/types";
import style from "./style.scss";

type OpenSections = { [sectionId: string]: boolean };

type OwnProps = {|
  card?: boolean,
  mobile?: boolean,
  filter?: string,
  query?: string,
  sort: ProjectSort,
  isOffline: boolean,
  canUseSections?: boolean,
  canCreateSections?: boolean,
  shouldRenderList: boolean,
  shouldRenderSections?: boolean,
  projects: Project[],
  sections: Section[],
  sectionProjects: { [sectionId: string]: Project[] },
  organizationId: string,
  onToggleStar: (StarParams) => void,
  onProjectClick: (projectId: string) => void,
  onStartSyncingProject?: (projectId: string) => void,
|};

type StorageProps = {|
  defaultOpenSections: OpenSections,
  onOpenSection: (OpenSections) => void,
|};

type Props = {
  ...OwnProps,
  ...StorageProps,
};

type State = {
  openSections: OpenSections,
};

const calculateOpenSections = (sections: Section[]) => {
  return sections.reduce((memo, section) => {
    memo[section.id] = true;
    return memo;
  }, {});
};

class VirtualizedProjectsList extends React.Component<Props, State> {
  static defaultProps = {
    defaultOpenSections: {},
    onOpenSection: (openSections = {}) => {},
  };

  state = { openSections: this.props.defaultOpenSections };
  listRef = React.createRef<VirtualizedList>();

  handleToggleSection = (sectionId: string) => {
    this.setState(
      (prev) => ({
        openSections: {
          ...prev.openSections,
          [sectionId]: !prev.openSections[sectionId],
        },
      }),
      () => {
        this.resizeList();
        if (this.state.openSections[sectionId]) {
          clearTextSelection();
        }
        this.props.onOpenSection(this.state.openSections);
      }
    );
  };

  handleToggleStar = (params: StarParams) => (event: SyntheticEvent<>) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onToggleStar(params);

    if (params.type === "section") {
      this.resizeList();
    }
  };

  resizeList = () => {
    const list = this.listRef.current;
    if (list) {
      list.resize();
    }
  };

  getResizeProps = () => {
    const object = {
      card: this.props.card,
      query: this.props.query,
      projectOrder: map(this.props.projects, "id").join("__"),
    };

    if (!this.props.shouldRenderSections || !this.props.sections.length) {
      return object;
    }

    return reduce(
      this.props.sectionProjects,
      (memo, projects, sectionId: string) => {
        memo[`${sectionId}-order`] = map(projects, "id").join("__");
        return memo;
      },
      object
    );
  };

  getDefaultProjectHeight = (project: Project, showSection?: boolean) => {
    if (this.props.card) {
      return undefined;
    }
    if (showSection && project.sectionId) {
      return project.about && !this.props.mobile ? 98 : 78;
    }
    return project.about && !this.props.mobile ? 78 : 58;
  };

  getSeparatorItem = (showBorder?: boolean): Row => {
    const { mobile } = this.props;
    return {
      height: mobile ? 48 : 33,
      className: classnames(style.row, {
        [style.showBottomBorder]: mobile && showBorder,
      }),
      children: mobile ? <div /> : <HorizontalSeparator />,
    };
  };

  getProjectItems = (
    projects: Project[],
    showSection?: boolean,
    groupId?: string
  ): Row[] => {
    const { card, mobile } = this.props;

    return projects.map((project, index) => {
      if (card && !mobile) {
        return {
          groupId,
          gridItem: true,
          gridRowClassName: classnames(style.hoverable, style.gridRow),
          height: 208,
          children: this.renderProjectItem(project, index, showSection),
        };
      }

      const first = index === 0;
      const last = index === projects.length - 1;
      const defaultHeight = this.getDefaultProjectHeight(project, showSection);

      return {
        groupId,
        className: classnames(style.hoverable, style.row, {
          [style.list]: !mobile,
          [style.mobile]: mobile,
          [style.firstNonSectionRow]: !groupId && index === 0,
          [style.firstListRow]: first,
          [style.lastListRow]: last,
        }),
        defaultHeight: first || last ? defaultHeight + 1 : defaultHeight,
        children: this.renderProjectItem(project, index, showSection),
      };
    });
  };

  getItems = (): Row[] => {
    const { card, mobile, sections, sectionProjects } = this.props;
    const rows = [];

    if (!mobile) {
      rows.push({ height: 24, children: <div /> });
    }

    if (this.props.shouldRenderList) {
      rows.push(
        ...this.getProjectItems(this.props.projects, this.props.canUseSections)
      );
    }

    if (this.props.shouldRenderSections) {
      const shouldRenderUnfiled =
        !!(sectionProjects.unfiled && sectionProjects.unfiled.length) &&
        this.props.filter !== "starred" &&
        !this.props.query;

      if (this.props.shouldRenderList && sections.length > 0) {
        rows.push(this.getSeparatorItem(true));
      }

      sections.forEach((section, index) => {
        const projects = sectionProjects[section.id];
        rows.push({
          height: mobile ? 48 : 40,
          groupId: section.id,
          className: classnames(style.section, style.hoverable, style.row),
          children: this.renderSectionHeader(section, projects, index),
        });

        if (this.state.openSections[section.id]) {
          if (projects.length) {
            rows.push(...this.getProjectItems(projects, false, section.id));
            if (!card && !mobile) {
              rows.push({ height: 12, children: <div /> });
            }
          } else {
            rows.push({
              groupId: section.id,
              height: 64,
              className: classnames(style.hoverable, style.row),
              children: <NoSectionProjects className={style.noProjects} />,
            });
          }
        }
      });

      if (shouldRenderUnfiled) {
        if (sections.length > 0) {
          rows.push(this.getSeparatorItem());
        }

        rows.push(
          ...this.getProjectItems(sectionProjects.unfiled, false, "unfiled")
        );
      }
    }

    return rows.concat({
      height: 24,
      children: <div />,
    });
  };

  renderSectionHeader = (
    section: Section,
    projects: Project[],
    index: number
  ) => {
    return (
      <ProjectSection
        section={section}
        projects={projects}
        filter={this.props.filter}
        query={this.props.query}
        organizationId={this.props.organizationId}
        canCreateSections={this.props.canCreateSections}
        isOffline={this.props.isOffline}
        isCollapsed={!this.state.openSections[section.id]}
        onToggleStar={this.handleToggleStar}
        onToggleCollapse={this.handleToggleSection}
        qaSelector={`organization-project-section-${index}`}
      />
    );
  };

  renderProjectItem = (
    project: Project,
    index: number,
    showSection?: boolean
  ) => {
    return (
      <ProjectItem
        className={style.project}
        card={this.props.card}
        mobile={this.props.mobile}
        wrappedList={!this.props.card}
        project={project}
        query={this.props.query}
        showSection={showSection}
        onToggleStar={this.handleToggleStar}
        onProjectClick={this.props.onProjectClick}
        onStartSyncingProject={this.props.onStartSyncingProject}
        qaSelector={`project-card-${index}`}
      />
    );
  };

  render() {
    return (
      <VirtualizedList
        ref={this.listRef}
        items={this.getItems()}
        overscanCount={10}
        resizeProps={this.getResizeProps()}
        qaSelector="virtualized-projects-list"
        groupChildren
      />
    );
  }
}

export default connectStorage<OwnProps>(
  VirtualizedProjectsList,
  (storage, props): StorageProps => {
    const filter = props.filter || DEFAULT_PROJECT_FILTER;
    const storageId = `OrganizationProjects-${props.organizationId}-${filter}`;
    const previous = storage.getItem(storageId) || {};

    return {
      defaultOpenSections:
        previous.defaultOpenSections === undefined
          ? calculateOpenSections(props.sections)
          : previous.defaultOpenSections,
      onOpenSection: (defaultOpenSections) => {
        storage.setItem(storageId, { defaultOpenSections });
      },
    };
  }
);
