// @flow
import { deburr } from "lodash";
import naturalSort from "natural-sort";
import { static as Immutable } from "seamless-immutable";

type NaturalSortOptions = {
  caseSensitive?: boolean,
  direction?: "asc" | "desc",
};

const sorter = naturalSort();

function getSortByField<T: Object>(
  item: T,
  keyOrCallback: string | ((T) => string)
) {
  if (typeof keyOrCallback === "string") {
    return deburr(item[keyOrCallback]);
  }

  return keyOrCallback(item);
}

function naturalSortBy<T>(
  items: T[],
  key: string | ((T) => string),
  sortOptions?: NaturalSortOptions
): T[] {
  if (!items) {
    return [];
  }

  const sort = sortOptions ? naturalSort(sortOptions) : sorter;
  // asMutable is required here as objects coming from reducers are immutable
  // and can't have sort called on them. For mutable objects this is a no-op.
  return Immutable.asMutable(items).sort((a: any, b: any): -1 | 0 | 1 =>
    sort(getSortByField(a, key), getSortByField(b, key))
  );
}

export default naturalSortBy;
