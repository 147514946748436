// @flow
import classnames from "classnames";
import * as React from "react";
import Button from "../Button";
import style from "./styles.scss";

type Props = {
  iconClassName?: string,
};

export default function ButtonRightIcon({ iconClassName, ...rest }: Props) {
  return (
    <Button
      iconClassName={classnames(iconClassName, style.iconRight)}
      {...rest}
    />
  );
}
