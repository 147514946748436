// @flow
import * as Abstract from "abstract-sdk";
import apiRequest from "abstract-di/api";
import { entitiesReceived } from "core/actions/entities";
import defineRequest from "core/requests";

export type SubscriptionCancelRequestFormValues = {|
  email: string,
  mainReasons: string,
  otherReason: string,
  otherTool: string,
|};

type SubscriptionCancelRequestParams = {|
  ...Abstract.OrganizationDescriptor,
  ...SubscriptionCancelRequestFormValues,
|};

type SubscriptionCancelRequestIdParams = {
  ...Abstract.OrganizationDescriptor,
};

export const SubscriptionCancelRequest = defineRequest<
  SubscriptionCancelRequestParams,
  SubscriptionCancelRequestIdParams,
>({
  id: (params) => {
    const { organizationId } = params;
    return `post:organizations/${organizationId}/subscription/cancel`;
  },
  perform: (params) => {
    const { email, mainReasons, organizationId, otherReason, otherTool } =
      params;
    return apiRequest(
      "post",
      `organizations/${organizationId}/subscription/cancel`,
      {
        email,
        mainReasons,
        otherReason,
        otherTool,
      }
    );
  },
  onSuccess: (response, params, dispatch) => {
    const organizationId = response.id;
    const subscriptions = { [organizationId]: response };
    dispatch(entitiesReceived({ subscriptions }));
  },
});
