// @flow
import * as React from "react";
import type { Project, User, Team } from "core/types";
import LeaveConfirmationDialog from "./dialogs/LeaveConfirmationDialog";
import RemoveConfirmationDialog from "./dialogs/RemoveConfirmationDialog";
import RemoveTeamConfirmationDialog from "./dialogs/RemoveTeamConfirmationDialog";
import ProjectMemberMenu from ".";

type Props = {
  children?: (
    showMenu: (ev: SyntheticEvent<>) => *,
    renderMenuTarget: (
      target: (ref: React.Ref<*>, buttonProps: {}) => React.Node
    ) => *
  ) => React.Node,
  project: Project,
  user?: User,
  team?: Team,
};

type State = {|
  showLeaveConfirmationDialog: boolean,
  showRemoveConfirmationDialog: boolean,
  showRemoveTeamConfirmationDialog: boolean,
|};

export default class ProjectMemberMenuWrapper extends React.Component<
  Props,
  State,
> {
  state = {
    showLeaveConfirmationDialog: false,
    showRemoveConfirmationDialog: false,
    showRemoveTeamConfirmationDialog: false,
  };

  showLeaveConfirmationDialog = () => {
    this.setState({ showLeaveConfirmationDialog: true });
  };

  hideLeaveConfirmationDialog = () => {
    this.setState({ showLeaveConfirmationDialog: false });
  };

  showRemoveConfirmationDialog = () => {
    this.setState({ showRemoveConfirmationDialog: true });
  };

  hideRemoveConfirmationDialog = () => {
    this.setState({ showRemoveConfirmationDialog: false });
  };

  showRemoveTeamConfirmationDialog = () => {
    this.setState({ showRemoveTeamConfirmationDialog: true });
  };

  hideRemoveTeamConfirmationDialog = () => {
    this.setState({ showRemoveTeamConfirmationDialog: false });
  };

  render() {
    return (
      <React.Fragment>
        {this.props.user && (
          <React.Fragment>
            <ProjectMemberMenu
              {...this.props}
              onClickLeave={this.showLeaveConfirmationDialog}
              onClickRemove={this.showRemoveConfirmationDialog}
              project={this.props.project}
              user={this.props.user}
            />
            <LeaveConfirmationDialog
              isOpen={this.state.showLeaveConfirmationDialog}
              onClose={this.hideLeaveConfirmationDialog}
              project={this.props.project}
              user={this.props.user}
            />
            <RemoveConfirmationDialog
              isOpen={this.state.showRemoveConfirmationDialog}
              onClose={this.hideRemoveConfirmationDialog}
              project={this.props.project}
              user={this.props.user}
            />
          </React.Fragment>
        )}
        {this.props.team && (
          <React.Fragment>
            <ProjectMemberMenu
              {...this.props}
              onClickRemoveTeam={this.showRemoveTeamConfirmationDialog}
              project={this.props.project}
              team={this.props.team}
            />
            <RemoveTeamConfirmationDialog
              isOpen={this.state.showRemoveTeamConfirmationDialog}
              onClose={this.hideRemoveTeamConfirmationDialog}
              project={this.props.project}
              team={this.props.team}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
