// @flow
import classnames from "classnames";
import * as React from "react";
import InputRadio from "core/components/InputRadio";
import { ModalContent } from "core/components/Modal";
import RadioGroup from "core/components/RadioGroup";
import { availableAtRenewalSeats } from "core/lib/subscriptions";
import { helpRolesUrl, organizationBillingUrl } from "core/lib/urls";
import type { Project, Organization, Subscription } from "core/types";
import { Wrapper, Header, Content, Note, Button, Footer } from "../shared";
import sharedStyle from "../shared/style.scss";
import style from "./style.scss";

type Props = {|
  role: string,
  project?: Project,
  selectedRole: "viewer" | "contributor" | "branchesContributor",
  canAddContributors: boolean,
  onNext: () => void,
  onDismiss: () => void,
  handleRoleChange: (SyntheticInputEvent<>) => void,
  subscription: ?Subscription,
  organization: Organization,
|};

export default function RoleScreen({
  role,
  project,
  canAddContributors,
  onNext,
  onDismiss,
  selectedRole,
  handleRoleChange,
  subscription,
  organization,
}: Props) {
  const outOfContribytorSeats =
    canAddContributors &&
    role === "contributor" &&
    availableAtRenewalSeats(subscription) === 0;

  const userRoles = [
    {
      type: "contributor",
      title: project ? "Guest Contributor" : "Contributor",
      details:
        "Grants access to all plan features including creating and merging branches and creating collections, and reviews.",
      disabled: !canAddContributors || outOfContribytorSeats,
      disabledReason: outOfContribytorSeats ? (
        <span>
          You have <strong>0</strong> available contributor seats on your
          account
        </span>
      ) : (
        "Only admins in the organization can invite contributors."
      ),
      qaSelector: "contributorRadioButton",
    },
    {
      type: "viewer",
      title: project ? "Guest Viewer" : "Viewer",
      details:
        "Can comment and review but cannot create new content or upload images.",
      disabled: false,
      qaSelector: "viewerRadioButton",
    },
  ];

  return (
    <ModalContent
      footer={
        <Footer>
          <div>
            <Button onClick={onDismiss} qaSelector="cancelRoleSelection">
              Cancel
            </Button>
            <Button
              primary
              disabled={!role || outOfContribytorSeats}
              onClick={onNext}
              className={style.nextButton}
              qaSelector="visitInviteTypeScreen"
            >
              Next
            </Button>
          </div>
        </Footer>
      }
    >
      <Wrapper>
        <Content>
          <Header>
            <h3>Invite as…</h3>
          </Header>
          <RadioGroup
            name="role"
            value={selectedRole}
            onChange={handleRoleChange}
          >
            {userRoles.map((userRole) => (
              <InputRadio
                key={userRole.type}
                label={
                  <div
                    className={classnames(style.details, {
                      [style.disabled]: userRole.disabled,
                    })}
                  >
                    <h3>{userRole.title}</h3>
                    {selectedRole === userRole.type && (
                      <React.Fragment>
                        <p>{userRole.details}</p>
                        <span>
                          <a
                            href={helpRolesUrl()}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={style.textLink}
                            data-qa="rolesHelpLink"
                          >
                            Learn more…
                          </a>
                        </span>
                      </React.Fragment>
                    )}
                    {userRole.disabled && (
                      <Note
                        className={
                          userRole.type === "contributor" &&
                          outOfContribytorSeats
                            ? sharedStyle.seatsAlertText
                            : style.darkerNote
                        }
                      >
                        {userRole.disabledReason}
                      </Note>
                    )}
                    {userRole.type === "contributor" &&
                      outOfContribytorSeats && (
                        <span>
                          <a
                            href={organizationBillingUrl(organization.id)}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Manage Account Settings
                          </a>
                        </span>
                      )}
                  </div>
                }
                className={style.roleInput}
                labelClass={style.role}
                value={userRole.type}
                disabled={userRole.disabled}
                qaSelector={userRole.qaSelector}
              />
            ))}
          </RadioGroup>
        </Content>
      </Wrapper>
    </ModalContent>
  );
}
