// @flow
import apiRequest from "abstract-di/api";
import { entitiesReceived } from "core/actions/entities";
import defineRequest from "core/requests";
import { normalizeOrganizationSettings } from "core/schemas/organizationSettings";
import type { OrganizationSettings } from "core/types";
import * as API from "web/api";

type OrganizationSettingsIdParams = {
  organizationId: string,
};

export const OrganizationSettingsRequest = defineRequest<
  OrganizationSettingsIdParams,
  OrganizationSettingsIdParams,
>({
  id(params) {
    return `organization-${params.organizationId}-settings`;
  },
  perform(params, dispatch, getState) {
    return apiRequest("get", `organizations/${params.organizationId}/settings`);
  },
  onSuccess(settings, params, dispatch) {
    const { entities } = normalizeOrganizationSettings(settings);
    dispatch(entitiesReceived(entities));
  },
  force: false,
});

type OrganizationSettingsPerformParams = {
  organizationId: string,
  values: $Shape<OrganizationSettings>,
};

export const OrganizationSettingsUpdateRequest = defineRequest<
  OrganizationSettingsPerformParams,
  OrganizationSettingsIdParams,
>({
  id: (params) => {
    const { organizationId } = params;
    return `put:organizations/${organizationId}/settings`;
  },
  perform: (params) => {
    const { organizationId, values } = params;
    return API.updateOrganizationSettings(organizationId, values);
  },
  onSuccess: (settings, params, dispatch) => {
    const { entities } = normalizeOrganizationSettings(settings);
    dispatch(entitiesReceived(entities));
  },
});
