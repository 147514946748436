// @flow
import * as React from "react";
import Button from "core/components/Button";
import style from "./style.scss";

type Props = {
  children: React.Node,
  label: string,
  qaSelector?: string,
};

export default function Spoiler({ label, children, qaSelector }: Props) {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <div>
      <Button
        className={style.label}
        iconClassName={expanded ? undefined : style.collapsed}
        onClick={() => setExpanded(!expanded)}
        icon="disclosure-expanded"
        nude
        qaSelector={qaSelector}
      >
        {label}
      </Button>
      {expanded ? children : null}
    </div>
  );
}
