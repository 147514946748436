// @flow
/* global Image window */
import classnames from "classnames";
import * as React from "react";
import Centered from "core/components/Centered";
import LayerSyncing from "core/components/Empty/LayerSyncing";
import Loaded from "core/components/Loaded";
import PreviewLoader from "core/components/PreviewLoader";
import ZoomablePreview, {
  type ChangeEvent as ZoomState,
} from "core/components/ZoomablePreview";
import { Abstract } from "core/lib/abstract";
import type { Size, Layer, LayerState } from "core/types";
import style from "./style.scss";
export type { ZoomState };

type Props = {
  params: Abstract.LayerVersionDescriptor,
  className?: string,
  children?: (dimensions: Size, scale: number, style: {}) => React.Node,
  layer: ?Layer,
  layerMissing?: boolean,
  layerSyncing?: boolean,
  layerUrl?: string,
  alt: string,
  isCreatingAnnotation?: boolean,
  zoomState?: ZoomState,
  onChangeZoomState?: (ZoomState) => void,
  onLoad?: (LayerState) => void,
  onClick?: () => void,
  onMouseEnter?: () => void,
  onMouseLeave?: () => void,
  zoomablePreviewPadding?: number,
  useViewportDimensions?: boolean,
};

function handleEventOnCurrentTarget<T: SyntheticEvent<>>(
  eventHandler?: (event: T) => void
) {
  if (eventHandler) {
    return (event: T) => {
      if (event.target === event.currentTarget) {
        eventHandler(event);
      }
    };
  }
}

export default class LayerCanvas extends React.Component<Props> {
  // We're using an instance variable rather than component state to avoid the
  // renders that occur when using setState. Changes to this variable do not
  // require a re-render as it's only used on the first uncontrolled render.
  defaultZoomStates: { [key: string]: ?ZoomState } = {};
  zoomablePreview: ?ZoomablePreview;

  // We keep a cache of images that have been loaded in this LayerDetail session
  // and render them in invisible image tags. This keeps the images in memory
  // and ensures no flashing when navigating to previously loaded previews.
  cachedImageUrls: { [url: string]: Image } = {};

  get zoomState(): ?ZoomState {
    return this.props.zoomState || this.defaultZoomState;
  }

  get defaultZoomState(): ?ZoomState {
    return this.defaultZoomStates[
      `${this.props.params.fileId}-${this.props.params.layerId}`
    ];
  }

  set defaultZoomState(zoomState: ZoomState) {
    this.defaultZoomStates[
      `${this.props.params.fileId}-${this.props.params.layerId}`
    ] = zoomState;
  }

  handleChangeZoomState = (zoomState: ZoomState) => {
    this.defaultZoomState = zoomState;
    this.props.onChangeZoomState && this.props.onChangeZoomState(zoomState);
  };

  cacheImage = (src: string) => {
    if (!src) {
      return;
    }
    if (this.cachedImageUrls[src]) {
      return;
    }

    const image = new Image();
    image.src = src;
    this.cachedImageUrls[src] = image;
  };

  render() {
    const { layer, useViewportDimensions } = this.props;

    return (
      <div
        className={classnames(style.layerCanvas, this.props.className)}
        onClick={handleEventOnCurrentTarget(this.props.onClick)}
        onMouseOver={handleEventOnCurrentTarget(this.props.onMouseEnter)}
        onMouseOut={handleEventOnCurrentTarget(this.props.onMouseLeave)}
      >
        {!this.props.layerMissing ? (
          <Loaded delay={1000} loading={!layer}>
            {() =>
              layer && (
                <PreviewLoader
                  key={Object.values(this.props.params).join("-")}
                  projectId={this.props.params.projectId}
                  commitSha={layer.lastChangedAtSha}
                  fileId={this.props.params.fileId}
                  layerId={this.props.params.layerId}
                  onLoad={this.props.onLoad}
                >
                  {(src, loading, error) => {
                    // LayerCanvas needs to use dimensions from layer metadata rather
                    // than PreviewLoader in order to display 2x previews correctly.
                    const dimensions = {
                      height: layer.height,
                      width: layer.width,
                    };

                    this.cacheImage(src);

                    return error ? (
                      <Centered className={style.layerMissing} flex>
                        {error}
                      </Centered>
                    ) : (
                      <Loaded loading={loading}>
                        <ZoomablePreview
                          {...this.zoomState}
                          ref={(ref) => (this.zoomablePreview = ref)}
                          src={src}
                          maxWidth={
                            useViewportDimensions
                              ? window.innerWidth
                              : undefined
                          }
                          maxHeight={
                            useViewportDimensions
                              ? window.innerHeight
                              : undefined
                          }
                          width={dimensions.width}
                          height={dimensions.height}
                          controlled={this.props.zoomState !== undefined}
                          onChange={this.handleChangeZoomState}
                          alt={this.props.alt}
                          padding={this.props.zoomablePreviewPadding}
                        >
                          {(scale, style) =>
                            this.props.children
                              ? this.props.children(dimensions, scale, style)
                              : null
                          }
                        </ZoomablePreview>
                      </Loaded>
                    );
                  }}
                </PreviewLoader>
              )
            }
          </Loaded>
        ) : (
          <Centered className={style.layerMissing}>
            {this.props.layerSyncing ? (
              <LayerSyncing layerUrl={this.props.layerUrl} />
            ) : (
              "The item doesn’t exist at this commit."
            )}
          </Centered>
        )}
        {this.props.isCreatingAnnotation && ( // TODO: Move to LayerCanvasWithAnnotations?
          <p className={style.annotationMessage}>
            Click and drag to create an annotation
          </p>
        )}
      </div>
    );
  }
}
