// @flow
import * as React from "react";
import { showMessageBox } from "abstract-di/actions";
import DialogForm, { TDialogForm } from "core/components/DialogForm";
import InputMarkdown from "core/components/InputMarkdown";
import type { Branch } from "core/types";
import Validations from "core/validations";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  projectId: string,
  sourceBranchId: string,
  destinationBranchId: string,
  destinationBranchSha: string,
|};

export type StateProps = {|
  headHasChanged?: boolean,
  destinationBranch: ?Branch,
  destinationBranchName: string,
  sourceBranch: ?Branch,
|};

export type DispatchProps = {|
  onSubmit: ({ notes: string }) => void,
  onDismiss: () => void,
  onRequestUpdate: () => void,
|};

export type Props = {|
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
|};

type State = {
  notes: string,
  isLoading: boolean,
};

class ConfirmMerge extends React.Component<Props, State> {
  form: ?TDialogForm;

  state = {
    notes: "",
    isLoading: false,
  };

  componentDidMount() {
    if (this.props.headHasChanged) {
      this.showHeadHasChanged();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.headHasChanged &&
      !prevProps.headHasChanged &&
      !this.state.isLoading
    ) {
      this.showHeadHasChanged();
    }
  }

  handleNotesChange = (value: string) => {
    this.setState({ notes: value });
    if (this.form) {
      this.form.checkValid();
    }
  };

  handleSubmit = () => {
    this.setState({ isLoading: true }, () => {
      this.props.onSubmit(this.state);
    });
  };

  setFormRef = (ref: *) => {
    this.form = ref;
  };

  showHeadHasChanged = async () => {
    const { response } = await showMessageBox({
      type: "warning",
      defaultId: 0,
      cancelId: 1,
      buttons: ["Update from Parent", "Cancel Merge"],
      message: "The parent branch has changed",
      detail: `The parent branch has changed. You need to update from "${
        this.props.sourceBranch
          ? this.props.sourceBranch.name
          : "the parent branch"
      }" before you can merge.`,
    });

    switch (response) {
      case 0:
        this.props.onRequestUpdate();
        break;
      case 1:
        this.props.onDismiss();
        break;
      default:
        break;
    }
  };

  render() {
    const { onDismiss, destinationBranch, destinationBranchName } = this.props;

    return (
      <DialogForm
        ref={this.setFormRef}
        title="Confirm merge"
        primaryButton="Merge and Archive"
        onClose={onDismiss}
        onSubmit={this.handleSubmit}
        disabled={this.state.isLoading}
        isOpen
      >
        <p>
          Any description you write will be added to the merge commit on{" "}
          <strong>
            {destinationBranch
              ? destinationBranchName
              : "the destination branch"}
          </strong>
          .
        </p>
        <InputMarkdown
          name="notes"
          projectId={this.props.projectId}
          placeholder="Anything you want to say about this merge? (optional)"
          value={this.state.notes}
          onChange={this.handleNotesChange}
          maxLength={Validations.maxCommitDescriptionLength}
          minHeight={300}
          focusClass={style.inputFocus}
          onSubmit={this.handleSubmit}
          autoFocus
        />
      </DialogForm>
    );
  }
}

export default connector(ConfirmMerge);
