// @flow
import * as React from "react";
import Flex from "core/components/Flex";
import Note from "core/components/Note";
import style from "./style.scss";

type Props = {|
  children: React.Node,
|};

function SettingsItemInputNote({ children }: Props) {
  return (
    <Flex justify="flex-end">
      <div className={style.flexFiller} />
      <Note className={style.note}>{children}</Note>
    </Flex>
  );
}

export default SettingsItemInputNote;
