// @flow
import { connect } from "react-redux";
import type { State, Dispatch } from "core/types";
import { closeSidebar } from "web/actions/sidebar";
import type { OwnProps, StateProps, DispatchProps, Props } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  return {
    isSidebarOpen: state.sidebar.open,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    onCloseSidebar() {
      dispatch(closeSidebar());
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
