// @flow
import * as React from "react";
import Button from "core/components/Button";
import Flex from "core/components/Flex";
import Heading from "core/components/Heading";
import Modal from "core/components/Modal";
import { V3Link as Link } from "core/lib/router";
import { forgotPassword } from "web/routeHelpers";
import style from "./style.scss";

type OwnProps = {|
  isOpen: boolean,
  onClose: () => void,
  onSubmit: () => void,
|};

export default function DisableSSOModal(props: OwnProps) {
  const { onClose, onSubmit, isOpen } = props;

  return (
    <Modal className={style.disableSSOModal} isOpen={isOpen} onClose={onClose}>
      <div className={style.disableSSOModalContent}>
        <Heading level="1" size="xxxl" weight="bold">
          Are you sure you want to turn off Single Sign-On (SSO)?
        </Heading>
        <p>
          Turning off SSO means that users will have to go back to using a
          password. Users who don’t have a password will need to create one
          through our <Link to={forgotPassword()}>password reset</Link> form. We
          recommend communicating with your organization about this change and
          sending them the <Link to={forgotPassword()}>password reset</Link>{" "}
          link.
        </p>
      </div>
      <Flex className={style.disableSSOModalButtons} justify="flex-end">
        <Button
          className={style.disableSSOModalCancelButton}
          large
          onClick={onClose}
        >
          Cancel Changes
        </Button>
        <Button danger large onClick={onSubmit}>
          Turn off SSO
        </Button>
      </Flex>
    </Modal>
  );
}
