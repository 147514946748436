// @flow
import pick from "lodash/pick";
import { EventTypes } from "redux-segment";
import { getCurrentUserId } from "abstract-di/selectors";
import { uniqueId } from "core/models/membership";
import { SubscriptionFetchRequest } from "core/requests/seats";
import { getProject } from "core/selectors/projects";
import { getSubscription } from "core/selectors/subscriptions";

import type {
  Action,
  AnyDescriptor,
  ThunkAction,
  User,
  Membership,
  Metric,
  Organization,
} from "core/types";

type Properties = { [string]: Object };

export function trackPage(name: string, properties: Properties = {}): Action {
  return {
    type: "core/ANALYTICS",
    meta: {
      analytics: {
        eventType: EventTypes.page,
        eventPayload: { name, properties },
      },
    },
  };
}

export function trackEvent(
  event: string,
  properties: $ReadOnly<Properties> = {}
): Action {
  return {
    type: "core/ANALYTICS",
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: { event, properties },
      },
    },
  };
}

export function trackMetrics(
  subjectDescriptor: AnyDescriptor,
  metrics: Metric[],
  version: string
): ThunkAction {
  return (dispatch, getState) => {
    const state = getState();

    const project = subjectDescriptor.projectId
      ? getProject(state, {
          projectId: subjectDescriptor.projectId,
        })
      : undefined;
    const userId = getCurrentUserId(state);

    for (const metric of metrics) {
      dispatch({
        type: "core/ANALYTICS",
        meta: {
          analytics: {
            eventType: EventTypes.track,
            eventPayload: {
              event: "PROFILER_METRIC",
              properties: {
                version,
                organizationId: project ? project.organizationId : undefined,
                userId,
                ...subjectDescriptor,
                ...metric,
              },
              options: {
                integrations: {
                  All: false,
                  "Amazon S3": true,
                },
              },
            },
          },
        },
      });
    }
  };
}

export type TrackUserParams = {|
  user: User,
  organizationId?: string,
  membership?: ?Membership,
  version?: string,
  sketchVersion?: string | number,
  marketingConsent?: boolean,
  jobFunction?: string,
|};

type UserTraits = {
  createdAt: string,
  organizationId: ?string,
  role: ?string,
  subscriptionRole: ?string,
  version?: string,
  sketchVersion?: string | number,
  marketingConsent?: boolean,
  jobFunction?: string,
};

export function trackUser({
  user,
  organizationId,
  membership,
  version,
  sketchVersion,
  marketingConsent,
  jobFunction,
}: TrackUserParams): Action {
  const traits: UserTraits = {
    createdAt: user.createdAt,
    organizationId,
    role: membership ? membership.role : undefined,
    subscriptionRole: membership ? membership.subscriptionRole : undefined,
    // The membershipId key is now being passed. Data
    // engineers requested it for practicality on their end.
    membershipId: organizationId
      ? uniqueId({ organizationId, userId: user.id })
      : undefined,
  };

  if (version !== undefined) {
    traits.version = version;
  }

  if (sketchVersion !== undefined) {
    traits.sketchVersion = sketchVersion;
  }

  if (marketingConsent !== undefined) {
    traits.marketingConsent = marketingConsent;
  }

  if (jobFunction !== undefined) {
    traits.jobFunction = jobFunction;
  }

  return {
    type: "core/ANALYTICS",
    meta: {
      analytics: {
        eventType: EventTypes.identify,
        eventPayload: {
          userId: user.id,
          traits,
        },
      },
    },
  };
}

export function trackOrganization(
  organization: Organization,
  additionalTraits?: { [trait: string]: mixed }
): ThunkAction {
  return async (dispatch, getState) => {
    await dispatch(
      SubscriptionFetchRequest.perform({
        params: { organizationId: organization.id },
        force: false,
      })
    );

    const subscription = pick(getSubscription(getState(), organization.id), [
      "type",
      "expirationMonth",
      "expirationYear",
      "trialEndsAt",
      "inTrial",
      "currentPeriodEndsAt",
      "totalAmountInCents",
      "priceInCents",
      "activatedAt",
      "canceledAt",
      "expiresAt",
      "annual",
      "termLength",
      "autoRenew",
      "minSeats",
      "maxSeats",
      "totalSeats",
      "usedSeats",
      "viewersCount",
      "quantity",
      "status",
      "nextPaymentAmountInCents",
    ]);

    dispatch({
      type: "core/ANALYTICS",
      meta: {
        analytics: {
          eventType: EventTypes.group,
          eventPayload: {
            groupId: organization.id,
            traits: {
              description: "organization",
              organizationName: organization.name,
              paid: organization.isWithinSubscriptionTerm,
              hasBillingInfo: organization.hasBillingInfo,
              isUsernameOrganization:
                organization.isUsernameOrganization === true,
              ...additionalTraits,
              subscription,
            },
          },
        },
      },
    });
  };
}
