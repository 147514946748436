// @flow
import { connect } from "react-redux";
import * as Request from "core/models/request";
import { OrganizationFetchRequest } from "core/requests/organizations";
import { getOrganization } from "core/selectors/organizations";
import type { Dispatch, State } from "core/types";
import { loadOrganization } from "web/actions/organizations";
import { closeSidebar } from "web/actions/sidebar";
import type { OwnProps, StateProps, DispatchProps, Props } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { organizationId } = props.params;

  const organization = getOrganization(state, { organizationId });
  const organizationFetchRequest = OrganizationFetchRequest.getRequest(state, {
    organizationId,
  });

  const sidebarIsOpen = state.sidebar.open;

  return {
    hasError: OrganizationFetchRequest.hasError(state, {
      organizationId,
    }),
    organization,
    organizationLoading: OrganizationFetchRequest.isLoading(state, {
      organizationId,
    }),
    notPermitted:
      Request.forbidden(organizationFetchRequest) ||
      Request.unauthorized(organizationFetchRequest),
    sidebarIsOpen,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { organizationId } = props.params;

  return {
    onMount: () => {
      dispatch(loadOrganization(organizationId));
    },
    onCloseSidebar() {
      dispatch(closeSidebar());
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
