// @flow
/* global window */
import config from "abstract-di/config";

export function configure(styles: { [styleName: string]: string }) {
  return window.recurly.configure({
    publicKey: config.recurlyPublicApiKey,
    fields: {
      all: {
        style: {
          ...styles,
          fontColor: styles["color"],
          fontSize: styles["font-size"],
          padding: styles["padding-left"], // not perfect, but padding is ""
          placeholder: {
            color: "#B8BDBF",
          },
        },
      },
      card: {
        displayIcon: true,
        style: {
          placeholder: {
            content: {
              number: "0000 0000 0000 0000",
            },
          },
        },
      },
      number: {
        style: {
          placeholder: {
            content: "0000 0000 0000 0000",
          },
        },
      },
      month: {
        style: {
          placeholder: {
            content: "Month (mm)",
          },
        },
      },
      year: {
        style: {
          placeholder: {
            content: "Year (yy)",
          },
        },
      },
      cvv: {
        style: {
          placeholder: {
            content: "000",
          },
        },
      },
    },
  });
}

export function getPaymentToken(form: EventTarget): Promise<{ id: string }> {
  return new Promise((resolve, reject) => {
    window.recurly.token(form, (error, token) => {
      if (token) {
        resolve(token);
      } else {
        reject(error);
      }
    });
  });
}

export function initThreeDSecure(actionTokenId: string) {
  const risk = window.recurly.Risk();
  const threeDSecure = risk.ThreeDSecure({ actionTokenId });

  return threeDSecure;
}
