// @flow
import map from "lodash/map";
import React, { Component } from "react";
import { V3Link as Link } from "core/lib/router";
import { branchPath } from "core/lib/routes";
import type { Project, Branch, Commit } from "../../types";
import CommitListItem from "../CommitListItem";
import Loaded from "../Loaded";
import style from "./style.scss";

export type Props = {
  project: ?Project,
  branch: ?Branch,
  parent?: ?Branch,
  commits: Commit[],
  maxCommits?: number,
  isLoading: boolean,
  onCommitsLoad?: () => void,
  onMount: () => void,
};

export default class CommitsCompactList extends Component<Props> {
  componentDidMount() {
    this.props.onMount();
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.isLoading &&
      !this.props.isLoading &&
      this.props.onCommitsLoad
    ) {
      this.props.onCommitsLoad();
    }
  }

  render() {
    const {
      project,
      branch,
      parent,
      commits,
      isLoading,
      maxCommits = 5,
    } = this.props;
    const isTruncated = commits.length >= maxCommits;

    // We don't want to display branch creation commit (the last commit.)
    const commitsToDisplay = commits.filter(
      (commit) => branch && commit.sha !== branch.startedAtSha
    );

    return (
      <Loaded loading={isLoading}>
        {() =>
          project &&
          branch && (
            <div>
              <div>
                {map(commitsToDisplay, (commit, index: number) => {
                  if (index >= maxCommits) {
                    return null;
                  }
                  const last =
                    index === commitsToDisplay.length - 1 ||
                    index === maxCommits - 1;

                  return (
                    <CommitListItem
                      key={commit.sha}
                      project={project}
                      branch={branch}
                      parent={parent}
                      commit={commit}
                      first={index === 0}
                      last={last}
                      compact
                    />
                  );
                })}
              </div>
              {isTruncated && (
                <Link
                  to={branchPath(project.id, branch.id, "commits")}
                  className={style.seeAll}
                >
                  See all commits…
                </Link>
              )}
            </div>
          )
        }
      </Loaded>
    );
  }
}
