// @flow
import { intersection, uniq, keys, pickBy, groupBy } from "lodash";
import AbstractConfig from "abstract-di/config";
import window from "core/global/window";
import experimentDefinitions from "./experiments-definitions";

const EXPERIMENTS = experimentDefinitions;
const EXP_PREFIX = /^PUSHTELL-/;

export const whiteListedExperiments: string[] = Object.keys(EXPERIMENTS);

/**
 * eventInInput Removes all experiments not defined in the definition from
 * localStorage.
 */
export function removeExperiments() {
  if (window.localStorage) {
    const storedExperimentKeys = Object.keys(window.localStorage).filter(
      (key) => {
        return key.match(EXP_PREFIX);
      }
    );

    storedExperimentKeys.forEach((localKey) => {
      const experimentName = localKey.replace(EXP_PREFIX, "");
      if (!whiteListedExperiments.includes(experimentName)) {
        window.localStorage.removeItem(localKey);
      }
    });
  }
}

/**
 * variantMismatch returns an error message if the user-supplied variants
 * don't match the variants defined in the experiment definition
 *
 * @param {string} experimentName
 * @param {Array} variants // user supplied variants
 * @return {string} Error message
 */
export const variantMismatch = (experimentName: string, variants: string[]) => {
  const definedVariants = EXPERIMENTS[experimentName];

  if (!definedVariants) {
    AbstractConfig.reportError(
      new Error(
        `Experiment ${experimentName} is not defined in the experiments file.`
      )
    );
  }

  if (uniq(variants).length !== variants.length) {
    // see if there are any duplicates
    const duplicates = keys(
      pickBy(groupBy(variants), (variantTerm) => variantTerm.length > 1)
    );

    AbstractConfig.reportError(
      new Error(`Duplicates of variants found: ${duplicates.join(",")}`)
    );
  }

  // If no duplicates, check to see if values match
  const intersectionOf = intersection(definedVariants, variants);

  if (
    intersectionOf.length !== variants.length ||
    intersectionOf.length !== definedVariants.length
  ) {
    AbstractConfig.reportError(
      new Error(`Error in supplied variants -
expected: [${definedVariants.join(", ")}]
actual: [${variants.join(", ")}]`)
    );
  }
};

export default EXPERIMENTS;
