// @flow
import * as React from "react";
import image from "./images/activity.svg";
import Empty from ".";

export default function NoActivity({
  description = "Create a project to see your team’s activity.",
  ...rest
}: {
  description?: string,
}) {
  return (
    <Empty
      {...rest}
      image={image}
      title="See team activity"
      description={description}
    />
  );
}
