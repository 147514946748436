// @flow
import createCachedSelector from "@elasticprojects/re-reselect";
import empty from "empty";
import { filter, values, sortBy } from "lodash";
import { createSelector } from "reselect";
import { getCurrentUserId } from "abstract-di/selectors";
import matchString from "core/lib/matchString";
import * as Branch from "core/models/branch";
import { hasMasterToMainSet } from "core/models/features";
import {
  getBranchId,
  getProjectId,
  getUserId,
  maybeGetUserId,
} from "core/selectors/helpers";
import { getLocalBranchEntities } from "core/selectors/localBranches";
import type {
  BranchParams,
  Branch as TBranch,
  BranchRestrictions as TBranchRestrictions,
  BranchHierarchy,
  BranchFilter,
  BranchStatus,
  State,
} from "core/types";
import { getEntity } from "./entities";

const cacheByProjectAndUser = (state: State, props) =>
  [props.projectId, props.userId].join();

const cacheByProjectAndBranch = (state: State, props) =>
  `${props.projectId}-${props.branchId}`;

// Note: This is based on the equivalent method in gab:
// https://github.com/goabstract/projects/blob/master/stable/gab/branch_meta.go#L485-L505
function getComputedParent(branches, branch: TBranch) {
  const parentBranch =
    branches[
      Branch.uniqueId({
        projectId: branch.projectId,
        branchId: branch.parent,
      })
    ];

  if (
    parentBranch &&
    !Branch.isActive(parentBranch) &&
    !Branch.isMaster(parentBranch)
  ) {
    return getComputedParent(branches, parentBranch);
  }

  return branch.parent;
}

export const getBranchEntities: (state: State) => {
  [branchId: string]: TBranch,
} = createSelector(
  (state) => state.branches,
  getLocalBranchEntities,
  (remoteBranches, localBranches) => {
    // It is very important that localBranches are spread after server branches
    // here so that we prefer local data over remote.
    const branches = { ...remoteBranches, ...localBranches };

    // branch.parent is usually correct, except in the instance where we _just_
    // changed the active status of a branch, in this case there is a period
    // of time where it's children have the wrong parent until they are reloaded
    // We can calculate the parent to cover this up.
    for (const key in branches) {
      const branch = branches[key];
      const parent = getComputedParent(branches, branch);

      if (parent && parent !== branch.parent) {
        branches[key] = { ...branch, parent };
      }
    }

    return branches;
  }
);

export const getBranch: (
  state: State,
  params: { projectId: string, branchId: string }
) => ?TBranch = createCachedSelector(
  getBranchEntities,
  getProjectId,
  getBranchId,
  hasMasterToMainSet,
  (branchEntities, projectId, branchId, masterToMain) => {
    const branch = branchEntities[Branch.uniqueId({ projectId, branchId })];
    // if our branch is the master one, and our feature flag is true,
    // set the new name for the default branch
    if (branch && Branch.isMaster(branch) && masterToMain) {
      return { ...branch, name: "Main" };
    }
    return branch;
  }
)(getProjectId);

export function getBranchHead(
  state: State,
  params: { projectId: string, branchId: string }
): string {
  const localBranches = getLocalBranchEntities(state);
  const uniqueId = Branch.uniqueId(params);
  const localBranch = localBranches[uniqueId];

  if (localBranch && !localBranch.metaOnly) {
    return localBranch.head;
  }

  const branch = state.branches[uniqueId];
  return branch ? branch.head : "";
}

export function getParentBranch(
  state: State,
  params: { projectId: string, branchId: string }
): ?TBranch {
  const branch = getBranch(state, params);
  if (!branch) {
    return;
  }

  return getBranch(state, {
    projectId: params.projectId,
    branchId: branch.parent,
  });
}

export const getBranchesForProject: (
  state: State,
  params: { projectId: string }
) => TBranch[] = createCachedSelector(
  getBranchEntities,
  getProjectId,
  hasMasterToMainSet,
  (branches, projectId, masterToMain) => {
    return sortBy(
      filter(
        values(branches),
        (branch) => branch.projectId === projectId && !Branch.isDeleted(branch)
      ).map((item, index) => {
        // Update the name of the default branch if needed
        if (Branch.isMaster(item) && hasMasterToMainSet) {
          return { ...item, name: "Main" };
        }
        return item;
      }),
      "updatedAt",
      "asc"
    );
  }
)(getProjectId);

export const getActiveBranchesForProject: (
  state: State,
  params: { projectId: string }
) => TBranch[] = createCachedSelector(getBranchesForProject, (branches) => {
  return branches.filter(
    (branch) => Branch.isActive(branch) && !Branch.isMaster(branch)
  );
})(getProjectId);

export const getChildBranchesForBranch: (
  state: State,
  params: { projectId: string, branchId: string }
) => TBranch[] = createCachedSelector(
  getBranchesForProject,
  getBranchId,
  (branches, branchId) => filter(branches, { parent: branchId })
)(cacheByProjectAndBranch);

export const getBranchesForUser: (
  state: State,
  props: { projectId: string, userId: string }
) => TBranch[] = createCachedSelector(
  getBranchesForProject,
  getUserId,
  (branches, userId) => {
    const userBranches = sortBy(
      branches.filter((branch) => userId && userId === branch.userId),
      "createdAt"
    );

    const master = branches.find(Branch.isMaster);

    if (master) {
      userBranches.unshift(master); // put master on top
    }

    return userBranches;
  }
)(cacheByProjectAndUser);

export const getIsFirstBranchCreatedByUser: (
  state: State,
  props: { userId: string }
) => boolean = createCachedSelector(
  getBranchEntities,
  getUserId,
  (branches, userId) => {
    const branchValues = values(branches);
    const userNonMasterBranches = branchValues
      .filter((branch) => userId && userId === branch.userId)
      .filter((branch) => branch.id && branch.id !== Branch.BRANCH_ID_MASTER);

    // check that we have more than one branch in the store prevents a race
    // condition when loading on a branch overview and the single branch request
    // completes first.
    return userNonMasterBranches.length === 1 && branchValues.length > 1;
  }
)(getUserId);

export function getBranchRestrictions(
  state: State,
  params: BranchParams
): ?TBranchRestrictions {
  return getEntity(state, "branchRestrictions", Branch.uniqueId(params));
}

export function getMasterBranchRestrictions(
  state: State,
  params: { projectId: string }
): ?TBranchRestrictions {
  return getBranchRestrictions(state, {
    ...params,
    branchId: Branch.BRANCH_ID_MASTER,
  });
}

export function getParentBranchRestrictions(
  state: State,
  params: BranchParams
): ?TBranchRestrictions {
  const branch = getBranch(state, params);
  if (!branch || !branch.parent) {
    return;
  }
  return getBranchRestrictions(state, {
    ...params,
    branchId: branch.parent,
  });
}

function generateBranchHierarchy(
  branches: TBranch[],
  baseBranchId: string,
  level: number = 1
): BranchHierarchy[] {
  const rootBranches = filter(branches, { parent: baseBranchId });
  return rootBranches.reduce((memo, branch) => {
    return memo.concat([
      { branch, level },
      ...generateBranchHierarchy(branches, branch.id, level + 1),
    ]);
  }, []);
}

function getBranchFilter(state, params: { branchFilter: BranchFilter }) {
  return params.branchFilter;
}

function getQuery(state, params: { query?: string }) {
  return params.query;
}

function getHideStale(state, params: { hideStale?: boolean }) {
  return !!params.hideStale;
}

function branchSearch(branch: TBranch, query: string): boolean {
  if (query === "") {
    return true;
  }

  return (
    !query ||
    matchString(branch.name, query) ||
    matchString(branch.description, query)
  );
}

const cacheNestedBranches = (state: State, params) =>
  [
    params.projectId,
    params.userId,
    params.branchFilter,
    params.query,
    params.hideStale,
  ].join("");

export const getNestedBranches: (
  state: State,
  props: {
    projectId: string,
    userId?: string,
    branchFilter: BranchFilter,
    query?: string,
    hideStale?: boolean,
  }
) => BranchHierarchy[] = createCachedSelector(
  getBranchesForProject,
  getBranchFilter,
  maybeGetUserId,
  getQuery,
  getHideStale,
  getCurrentUserId,
  (branches, branchFilter, userId, query = "", hideStale, currentUserId) => {
    if (!currentUserId) {
      return empty.array;
    }

    let filteredBranches = sortBy(
      filter(
        branches,
        (branch) => branchSearch(branch, query) && !Branch.isMaster(branch)
      ),
      "updatedAt"
    ).reverse();

    if (userId) {
      filteredBranches = filter(
        filteredBranches,
        (branch) => branch.userId === userId
      );
    }

    if (branchFilter === "mine") {
      return filter(
        filteredBranches,
        (branch) => Branch.isActive(branch) && branch.userId === currentUserId
      ).map((branch) => ({ branch, level: 0 }));
    }

    if (branchFilter === "active" && !query) {
      return generateBranchHierarchy(
        filter(
          filteredBranches,
          (branch) =>
            Branch.isActive(branch) && (!hideStale || !Branch.isStale(branch))
        ),
        "master"
      );
    }

    if (branchFilter === "active" && query) {
      return filter(
        filteredBranches,
        (branch) =>
          Branch.isActive(branch) && (!hideStale || !Branch.isStale(branch))
      ).map((branch) => ({ branch, level: 0 }));
    }

    if (branchFilter === "archived") {
      return filter(filteredBranches, (branch) => !Branch.isActive(branch)).map(
        (branch) => ({ branch, level: 0 })
      );
    }

    return empty.array;
  }
)(cacheNestedBranches);

export function getPaginatedNestedBranches(
  state: State,
  params: {
    projectId: string,
    userId?: string,
    search?: string,
    filter?: BranchFilter,
  }
): BranchHierarchy[] {
  const { projectId, userId, search, filter = "active" } = params;

  return getNestedBranches(state, {
    projectId,
    userId,
    query: search,
    branchFilter: filter,
  });
}

export function getBranchStatus(
  state: State,
  params: { projectId: string, branchId: string }
): ?BranchStatus {
  return getEntity(state, "branchStatus", Branch.uniqueId(params));
}

export const getParentBranches: (
  state: State,
  params: { projectId: string, branchId: string }
) => TBranch[] = createCachedSelector(
  getBranchEntities,
  getProjectId,
  getBranch,
  (allBranches, projectId, branch) => {
    if (allBranches) {
      return generateNestedParentBranches(allBranches, projectId, branch);
    }
    return empty.array;
  }
)(cacheByProjectAndBranch);

function generateNestedParentBranches(
  allBranches,
  projectId: string,
  branch: ?TBranch
) {
  if (branch && branch.parent && branch.parent !== Branch.BRANCH_ID_MASTER) {
    const parentBranch =
      allBranches[Branch.uniqueId({ projectId, branchId: branch.parent })];
    // Added this check here to make sure there's no undefined items get added to the array
    if (parentBranch) {
      // This list will is ordered by parent to child.
      return [
        ...generateNestedParentBranches(allBranches, projectId, parentBranch),
        parentBranch,
      ];
    }
    return empty.array;
  }
  return empty.array;
}
