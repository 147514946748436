// @flow
import queryString from "query-string";
import apiRequest from "abstract-di/api";
import { trackEvent } from "core/actions/analytics";
import { entitiesReceived } from "core/actions/entities";
import { normalizeComment } from "core/schemas/comment";
import defineRequest from "./";

type CommentFetchParams = {
  projectId: string,
  branchId?: string,
  commitSha?: string,
  fileId?: string,
  layerId?: string,
  contentId?: string,
  includeLastCommentViews?: boolean,
  limit?: number,
  offset?: number,
};

type CommentRequestParams = {
  id: string,
};

const COMMENTS_FETCH_API_VERSION = 10;

export const CommentsFetchRequest = defineRequest<
  CommentFetchParams,
  CommentFetchParams,
>({
  id: ({ limit = 1000, offset = 0, ...params }) => {
    return `get:comments?${queryString.stringify({
      ...params,
      limit,
      offset,
    })}`;
  },
  perform: (params) => {
    return apiRequest("get", "comments", params, COMMENTS_FETCH_API_VERSION);
  },
});

export const RepliesFetchRequest = defineRequest<
  CommentRequestParams,
  CommentRequestParams,
>({
  id: (params) => {
    return `get:comments/${params.id}/replies`;
  },
  perform: (params) => {
    return apiRequest("get", `comments/replies`, params);
  },
});

export const CreateCommentRequest = defineRequest<
  CommentRequestParams,
  CommentRequestParams,
>({
  id: (params) => {
    return `post:comments?${params.id}`;
  },
  perform: (params) => {
    const { id, ...form } = params;
    return apiRequest("post", "comments", form);
  },
});

export const UpdateCommentRequest = defineRequest<
  CommentRequestParams,
  CommentRequestParams,
>({
  id: (params) => {
    return `put:comments/${params.id}`;
  },
  perform: (params) => {
    return apiRequest("put", `comments/${params.id}`, params);
  },
});

export const DeleteCommentRequest = defineRequest<
  CommentRequestParams,
  CommentRequestParams,
>({
  id: (params) => {
    return `delete:comments/${params.id}`;
  },
  perform: (params) => {
    return apiRequest("delete", `comments/${params.id}`, params);
  },
});

export const PinCommentRequest = defineRequest<
  CommentRequestParams,
  CommentRequestParams,
>({
  id: (params) => {
    return `put:comments/${params.id}/pin`;
  },
  perform: (params) => {
    return apiRequest("put", `comments/${params.id}/pin`, params);
  },
  onSuccess: (comment, params, dispatch) => {
    dispatch(entitiesReceived(normalizeComment(comment).entities));
  },
});

export const UnpinCommentRequest = defineRequest<
  CommentRequestParams,
  CommentRequestParams,
>({
  id: (params) => {
    return `put:comments/${params.id}/unpin`;
  },
  perform: (params) => {
    return apiRequest("put", `comments/${params.id}/unpin`, params);
  },
  onSuccess: (comment, params, dispatch) => {
    dispatch(entitiesReceived(normalizeComment(comment).entities));
  },
});

export const ResolveCommentRequest = defineRequest<
  CommentRequestParams,
  CommentRequestParams,
>({
  id: (params) => {
    return `put:comments/${params.id}/resolve`;
  },
  perform: (params) => {
    return apiRequest("put", `comments/${params.id}/resolve`, params);
  },
  onSuccess: (comment, params, dispatch) => {
    dispatch(entitiesReceived(normalizeComment(comment).entities));
    dispatch(
      trackEvent("COMMENT_RESOLVED", {
        commentId: comment.id,
      })
    );
  },
});

export const UnresolveCommentRequest = defineRequest<
  CommentRequestParams,
  CommentRequestParams,
>({
  id: (params) => {
    return `put:comments/${params.id}/unresolve`;
  },
  perform: (params) => {
    return apiRequest("put", `comments/${params.id}/unresolve`, params);
  },
  onSuccess: (comment, params, dispatch) => {
    dispatch(entitiesReceived(normalizeComment(comment).entities));
    dispatch(
      trackEvent("COMMENT_UNRESOLVED", {
        commentId: comment.id,
      })
    );
  },
});
