// @flow
import * as Abstract from "abstract-sdk";
import apiRequest from "abstract-di/api";
import { entitiesReceived } from "core/actions/entities";
import defineRequest from "core/requests";
import { normalizeFeaturesResponse } from "core/schemas/feature";

export const FeaturesFetchRequest = defineRequest<
  Abstract.OrganizationDescriptor,
  Abstract.OrganizationDescriptor,
>({
  id(params) {
    return `features-${params.organizationId}`;
  },
  perform: async function ({ organizationId }) {
    const features = await apiRequest(
      "get",
      `organizations/${organizationId}/features`
    );
    return {
      organizationId,
      features,
    };
  },
  onSuccess(response, params, dispatch) {
    const { entities } = normalizeFeaturesResponse([response]);
    dispatch(entitiesReceived(entities));
  },
});

type FetchAllParams = { organizationIds: string[] };

export const FeaturesFetchAllRequest = defineRequest<
  FetchAllParams,
  FetchAllParams,
>({
  id(params) {
    return `features-${params.organizationIds.join("-")}`;
  },
  perform: function (params, dispatch) {
    return Promise.all(
      params.organizationIds.map((organizationId) => {
        return dispatch(
          FeaturesFetchRequest.perform({ params: { organizationId } })
        );
      })
    );
  },
});
