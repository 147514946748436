// @flow
import * as React from "react";
import window from "core/global/window";

type Props = {|
  children: () => React.Node,
|};

type State = {|
  shouldRender: boolean,
|};

export default class DeferredRender extends React.Component<Props, State> {
  state = { shouldRender: false };

  componentDidMount() {
    /**
     * Allows two animation frames to complete to allow other components to update
     * and re-render before mounting and rendering expensive children.
     */
    window.requestAnimationFrame(() => {
      window.requestAnimationFrame(() => this.setState({ shouldRender: true }));
    });
  }

  render() {
    return this.state.shouldRender ? this.props.children() : null;
  }
}
