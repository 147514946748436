// @flow
import idx from "idx";
import invariant from "invariant";
import * as React from "react";
import OpenFileButton from "abstract-di/components/OpenFileButton";
import history from "abstract-di/history";
import ErrorBoundary from "core/components/ErrorBoundary";
import Flex from "core/components/Flex";
import FloatingControl from "core/components/FloatingControl";
import LayerMenu from "core/components/LayerMenu";
import PageTitleNavigationLink from "core/components/PageTitleNavigationLink";
import PillBadge from "core/components/PillBadge";
import { CustomThemeProvider } from "core/components/Theme/useCustomTheme";
import window from "core/global/window";
import { Abstract } from "core/lib/abstract";
import eventInInput from "core/lib/eventInInput";
import KeyCode from "core/lib/keycode";
import { locationLayerMode } from "core/lib/layer";
import { replace, addQuery } from "core/lib/location";
import { V3Link as Link } from "core/lib/router";
import { layerLocation, shareLinkPath } from "core/lib/routes";
import { isPublicCollection } from "core/models/shareLink";
import type {
  Branch,
  Commit as TCommit,
  Layer,
  LayerShellMode,
  ShareLink,
  LayerSetItem,
  LayerSetParams,
  ReactRouterLocation,
} from "core/types";
import Button from "../Button";
import Error from "../Empty/Error";
import NotFound from "../Empty/NotFound";
import Header from "../Header";
import Icon from "../Icon";
import type { ZoomState } from "../LayerCanvas";
import connector from "./connector";
import style from "./style.scss";

type State = {
  menuOpen: boolean,
  zoomStates: {
    [layerKey: string]: ?ZoomState,
  },
  isShowingResolvedComments: boolean,
};

export type OwnProps = {|
  params: Abstract.LayerVersionDescriptor,
  layerState:
    | "loading"
    | "success"
    | "loaded" // TODO: Remove "loaded"
    | "not_found"
    | "forbidden"
    | "error",
  children?: ({
    selectedMode: LayerShellMode,
    zoomState: ZoomState,
    goBackToSymbolInstanceButton?: React.Node,
    handleChangeZoomState: (zoomState: ZoomState) => void,
    isShowingResolvedComments: boolean,
    onResolveCommentsOptionClick: () => void,
  }) => React.Node,
  branch: ?Branch,
  commit: ?TCommit,
  layer: ?Layer,
  selectedMode: LayerShellMode,
  shareButton?: React.Node,
  onClose?: () => void,
  onSelectMode: (tab: LayerShellMode) => void,
  canInspect: boolean,
  canShowHistory: boolean,
  shareId?: string,
  shareLink?: ShareLink,
  collectionId?: string,
  collectionLayerId?: string,
  layerSetParams?: LayerSetParams,
  onSelectLayerSetItem?: (?LayerSetItem) => void,
  mobile?: boolean,
  location: ReactRouterLocation,
  showGoToLatest: boolean,
|};

export type StateProps = {|
  head: string,
  canShareProject?: boolean,
  canAccessProject?: boolean,
  commit: ?TCommit,
  latestCommitSha: ?string,
  isLatestCommitFeatureEnabled: boolean,
|};

export type DispatchProps = {|
  onSelectMode: (tab: LayerShellMode) => void,
  trackResolveCommentsOptionClicked: (isShowing: boolean) => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

const INITIAL_ZOOM_STATE = {
  scale: 0,
  scaledToFit: true,
  offsetX: 0,
  offsetY: 0,
};

type modes = Array<LayerShellMode>;
const layerModes: modes = ["design", "compare", "build"];

function layerKey(params: Object) {
  return `${params.fileId}-${params.layerId}`;
}

class LayerShell extends React.Component<Props, State> {
  static defaultProps = {
    selectedMode: "design",
    canInspect: true,
    canShowHistory: true,
    mobile: false,
  };

  state = {
    menuOpen: false,
    zoomStates: {},
    isShowingResolvedComments: false,
  };

  get openFileSha(): string {
    const { head, latestCommitSha, params } = this.props;
    const { sha } = params;

    if (
      head &&
      ((latestCommitSha === sha && latestCommitSha !== null && sha !== null) ||
        sha === "latest")
    ) {
      return head;
    }

    return sha;
  }

  componentDidMount() {
    window.addEventListener("keydown", this.handleKeyDown);
    const mode = locationLayerMode(this.props.location);

    if (!layerModes.includes(mode)) {
      this.safelySelectMode(this.props.selectedMode);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.selectedMode !== prevProps.selectedMode) {
      this.safelySelectMode(this.props.selectedMode);
    }
  }

  safelySelectMode(mode: LayerShellMode) {
    if (
      !layerModes.includes(mode) ||
      (mode === "compare" && !this.props.canShowHistory) ||
      (mode === "build" && !this.props.canInspect)
    ) {
      return this.props.onSelectMode("design");
    }

    return this.props.onSelectMode(mode);
  }

  handleShowMenu = (showMenu: Function) => (event: SyntheticEvent<>) => {
    if (!this.state.menuOpen) {
      this.setState({ menuOpen: true });
      showMenu(event);
    }
  };

  handleHideMenu = () => this.setState({ menuOpen: false });

  routeToLatestLayerCommit = () => {
    const {
      layerSetParams,
      latestCommitSha,
      collectionId,
      collectionLayerId,
      location,
      params: { projectId, branchId, fileId, layerId },
    } = this.props;

    if (!latestCommitSha) {
      return;
    }

    // If we're viewing a Symbol Master, sustain the go Back button
    // as the user uses the Go To Latest button.
    const originalSymbolInstanceLocation =
      location.state && location.state.originalSymbolInstanceLocation;

    if (this.props.shareId) {
      return replace(
        shareLinkPath(this.props.shareId, {
          sha: latestCommitSha,
          mode: this.props.selectedMode,
          layerSetParams,
          collectionLayerId,
        })
      );
    }

    if (this.props.isLatestCommitFeatureEnabled) {
      return replace(
        layerLocation(projectId, branchId, "latest", fileId, layerId, {
          layerSetParams,
          originalSymbolInstanceLocation,
        })
      );
    }

    return replace(
      layerLocation(projectId, branchId, latestCommitSha, fileId, layerId, {
        layerSetParams,
        collectionId,
        collectionLayerId,
        originalSymbolInstanceLocation,
        mode: this.props.selectedMode,
      })
    );
  };

  goBackToSymbolInstanceButton() {
    const originalSymbolInstanceLocation = idx(
      history.getCurrentLocation(),
      (_) => _.state.originalSymbolInstanceLocation
    );
    if (originalSymbolInstanceLocation) {
      return (
        <FloatingControl
          icon="arrow-large-left"
          label="Back to Instance"
          tint
          className={style.goBackToSymbolInstanceButton}
          onClick={() =>
            replace({
              ...originalSymbolInstanceLocation,
              query: {
                ...originalSymbolInstanceLocation.query,
                mode: this.props.selectedMode,
              },
            })
          }
        />
      );
    }
  }

  renderTabs() {
    return (
      <div className={style.navigation}>
        {(this.props.canShowHistory || this.props.canInspect) && (
          <PageTitleNavigationLink
            to={addQuery({ mode: "design" })}
            active={this.props.selectedMode === "design"}
            qaSelector="commentTab"
          >
            Comment
          </PageTitleNavigationLink>
        )}
        {this.props.canShowHistory && (
          <PageTitleNavigationLink
            to={addQuery({ mode: "compare" })}
            active={this.props.selectedMode === "compare"}
            qaSelector="compareTab"
          >
            Compare
          </PageTitleNavigationLink>
        )}
        {this.props.canInspect && (
          <PageTitleNavigationLink
            to={addQuery({ mode: "build" })}
            active={this.props.selectedMode === "build"}
            qaSelector="inspectTab"
          >
            Inspect
          </PageTitleNavigationLink>
        )}
      </div>
    );
  }

  handlePresentLayer = () => {
    if (this.props.params.sha === "latest" && !this.props.shareId) {
      invariant(
        this.props.latestCommitSha,
        "Latest commit must exist in state to present."
      );

      replace(
        layerLocation(
          this.props.params.projectId,
          this.props.params.branchId,
          this.props.latestCommitSha,
          this.props.params.fileId,
          this.props.params.layerId,
          {
            ...this.props.location.state,
            ...this.props.location.query,
            present: "true",
          }
        )
      );
    } else {
      replace(addQuery({ present: "true" }));
    }
  };

  handleResolveCommentsOptionClick = () => {
    this.setState(
      (prevState) => ({
        isShowingResolvedComments: !prevState.isShowingResolvedComments,
      }),
      () => {
        this.props.trackResolveCommentsOptionClicked(
          this.state.isShowingResolvedComments
        );
      }
    );
  };

  render() {
    if (this.props.layerState === "forbidden") {
      return <NotFound />;
    }

    if (this.props.layerState === "error") {
      return <Error />;
    }

    const { children } = this.props;

    return (
      <ErrorBoundary>
        <CustomThemeProvider>
          <div className={style.layerShell}>
            <div className={style.headerContainer}>
              <div className={style.mainHeader}>
                <Header
                  left={this.renderMainToolbarLeft()}
                  center={
                    <div className={style.mainToolbarCenter}>
                      {this.renderTabs()}
                    </div>
                  }
                  right={
                    <div className={style.mainToolbarRight}>
                      <Button
                        icon="play"
                        onClick={this.handlePresentLayer}
                        title="Present layer"
                      />
                      {this.props.canAccessProject && (
                        <OpenFileButton
                          className={style.openFileButton}
                          projectId={this.props.params.projectId}
                          branchId={this.props.params.branchId}
                          fileId={this.props.params.fileId}
                          layerId={this.props.params.layerId}
                          sha={this.openFileSha}
                          compact
                          canCreateCommit={true}
                        />
                      )}
                      {this.props.shareButton && !this.props.mobile && (
                        <Flex className={style.shareContainer}>
                          {this.props.shareButton}
                        </Flex>
                      )}
                      {this.props.canAccessProject &&
                        (this.props.layer ? (
                          <LayerMenu
                            projectId={this.props.params.projectId}
                            branchId={this.props.params.branchId}
                            collectionId={this.props.collectionId}
                            collectionLayerId={this.props.collectionLayerId}
                            layer={this.props.layer}
                            onAfterClose={this.handleHideMenu}
                            selectedMode={this.props.selectedMode}
                          >
                            {(showMenu, menuRef) => (
                              <Button
                                icon="overflow"
                                nude
                                innerRef={menuRef}
                                onClick={this.handleShowMenu(showMenu)}
                                className={style.layerMenuButton}
                              />
                            )}
                          </LayerMenu>
                        ) : (
                          <Button
                            icon="overflow"
                            className={style.layerMenuButton}
                            disabled
                            nude
                          />
                        ))}
                    </div>
                  }
                />
              </div>
              <div className={style.mobileHeader}>
                {this.renderMainToolbarLeft()}
              </div>
            </div>
            {children &&
              children({
                selectedMode: this.props.selectedMode,
                handleChangeZoomState: this.handleChangeZoomState,
                goBackToSymbolInstanceButton:
                  this.goBackToSymbolInstanceButton(),
                zoomState:
                  this.state.zoomStates[layerKey(this.props.params)] ||
                  INITIAL_ZOOM_STATE,
                isShowingResolvedComments: this.props.mobile
                  ? true
                  : this.state.isShowingResolvedComments,
                onResolveCommentsOptionClick:
                  this.handleResolveCommentsOptionClick,
              })}
          </div>
        </CustomThemeProvider>
      </ErrorBoundary>
    );
  }

  renderMainToolbarLeft() {
    const { layer, latestCommitSha } = this.props;
    const isCollection =
      !!(this.props.layerSetParams && this.props.layerSetParams.collectionId) ||
      isPublicCollection(this.props.shareLink);

    const isLatest =
      this.props.params.sha === "latest" ||
      (layer && layer.sha === latestCommitSha);

    return (
      <div className={style.mainToolbarLeft}>
        {this.props.onClose && (
          <React.Fragment>
            <Button
              className={style.closeButton}
              icon={isCollection ? "arrow-large-left" : "close"}
              title={isCollection ? "Go to Collection Overview" : "Close Layer"}
              onClick={this.props.onClose}
              qaSelector="closeLayerModal"
              nude
              tint
            />
            <div className={style.closeButtonDivider} />
          </React.Fragment>
        )}
        <div className={style.headerLayerName}>
          <Icon
            className={style.headerLayerIcon}
            type={`file-${
              layer && layer.type === "symbol" ? "symbol" : "artboard"
            }`}
          />
          <div className={style.layerNameLabel}>
            {this.props.layerState === "not_found"
              ? "Layer doesn’t exist"
              : layer
              ? layer.name
              : "Loading…"}
          </div>
          {!isLatest && this.props.showGoToLatest && (
            <PillBadge
              title="Go to latest"
              type="warning"
              component={Link}
              className={style.goToLatestPillBadge}
              onClick={this.routeToLatestLayerCommit}
              hideDisclosure
            >
              Go to latest
            </PillBadge>
          )}
        </div>
      </div>
    );
  }

  handleChangeZoomState = (zoomState: ZoomState) => {
    this.setState((state) => ({
      zoomStates: {
        ...state.zoomStates,
        [layerKey(this.props.params)]: zoomState,
      },
    }));
  };

  handleKeyDown = (event: KeyboardEvent) => {
    if (eventInInput(event)) {
      return;
    }

    switch (event.keyCode) {
      case KeyCode.KEY_1:
        if (event.altKey) {
          this.safelySelectMode("design");
        }
        break;
      case KeyCode.KEY_2:
        if (event.altKey) {
          this.safelySelectMode("compare");
        }
        break;
      case KeyCode.KEY_3:
        if (event.altKey) {
          this.safelySelectMode("build");
        }
        break;
      default:
    }
  };
}

export default connector(LayerShell);
