// @flow
import * as React from "react";
import style from "./style.scss";

export default function RequestSentMessage() {
  return (
    <React.Fragment>
      <p className={style.message}>
        We have notified the project Admins that you would like access.
      </p>
      <p className={style.message}>
        If some time has passed since you first requested access and you think
        you’ve been overlooked, you might try contacting one of the Admins
        personally.
      </p>
    </React.Fragment>
  );
}
