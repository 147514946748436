// @flow
/* global HTMLAnchorElement */
import classnames from "classnames";
import * as React from "react";
import { renderMarkdown } from "core/lib/textFormatting";
import type { User } from "core/types";
import style from "./style.scss";

type Props = {
  className?: string,
  text: string,
  textSize?: "ml",
  highlight?: string,
  minHeight?: number,
  maxHeight?: number,
  propagateLinks?: boolean,
  cleanSummary?: boolean,
  mentionableUsers?: User[],
  forceLightText?: boolean,
};

function stopLinkPropagation(event: SyntheticEvent<*>) {
  if (event.target instanceof HTMLAnchorElement && event.target.href) {
    event.stopPropagation();
  }
}

export default function Markdown({
  className,
  text,
  highlight,
  minHeight = 0,
  maxHeight,
  propagateLinks,
  cleanSummary = false,
  mentionableUsers = [],
  forceLightText,
  textSize,
}: Props) {
  const classes = classnames(
    style.markdown,
    textSize && style["font-" + textSize],
    className,
    {
      [style.inlineMarkdown]: cleanSummary,
      [style.forceLightText]: forceLightText,
    }
  );
  const overflowY = maxHeight ? "scroll" : "visible";

  const mentionableUsernames = mentionableUsers.map((u) => u.username);
  let renderedMarkdown = renderMarkdown(text, highlight, mentionableUsernames);

  if (cleanSummary) {
    // Match the first "content" block (p, ul, or ol) that has content inside of it.
    // For list elements, that will be an li tag, whereas for p it will be an alpha char.

    const regex = /<(p|ul|ol)>([a-zA-Z]+|<li>).*<\/(p|ul|ol)>/;
    const match = renderedMarkdown.match(regex);
    const firstContentBlock = match ? match[0] : null;

    if (firstContentBlock) {
      const beforeFirstContent = renderedMarkdown.split(regex)[0];
      renderedMarkdown = beforeFirstContent + firstContentBlock;
    }

    renderedMarkdown = renderedMarkdown.replace(
      /<(\/?)(h\d|li|ol|ul|p|blockquote|b|i|strong|em|hr)\/?>/gi,
      ""
    );
  }
  return (
    <div
      style={{ minHeight, maxHeight, overflowY }}
      className={classes}
      dangerouslySetInnerHTML={{ __html: renderedMarkdown }}
      onClick={!propagateLinks && stopLinkPropagation}
    />
  );
}
