// @flow
import * as React from "react";
import Centered from "core/components/Centered";
import Spinner from "core/components/Spinner";
import { push } from "core/lib/location";
import IntegrationChooseOrganization from "web/components/IntegrationChooseOrganization";
import { createIntegration } from "../../api";
import { integrationPath, organizationIntegrations } from "../../routeHelpers";

export function SlackIntegrationComplete(
  props: $Diff<Props, { kind: string }>
) {
  return <IntegrationComplete kind="Slack" {...props} />;
}

export function GithubIntegrationComplete(
  props: $Diff<Props, { kind: string }>
) {
  return <IntegrationComplete kind="GitHub" {...props} />;
}

export function JiraIntegrationComplete(props: $Diff<Props, { kind: string }>) {
  return <IntegrationComplete kind="Jira" {...props} />;
}

type Props = {
  kind: "Slack" | "GitHub" | "Jira",
  location: {
    query: {
      code: string,
      error?: string,
      state: string,

      // installation_id is only expected with the "GitHub" integration.
      installation_id?: string,
    },
  },
};

export default function IntegrationComplete(props: Props) {
  const [organizationId, setOrganizationId] = React.useState(
    props.location.query.state
  );

  if (props.location.query.error === "access_denied") {
    push(organizationIntegrations(organizationId));
  } else if (!organizationId) {
    return (
      <IntegrationChooseOrganization setOrganizationId={setOrganizationId} />
    );
  } else {
    var integrationArgs = {
      organizationId,
      code: props.location.query.code,
      kind: props.kind,
      installationId: props.location.query.installation_id,
    };
    createIntegration(integrationArgs).then(
      /* Redirect with a boolean in location.state so that we know to show a
      flash message at the new location */
      (integration) => {
        if (integration) {
          push({
            pathname: integrationPath(
              integration.id,
              integration.organizationId,
              props.kind
            ),
            state: { integrationCreationSuccess: true },
          });
        }
      },
      () => {
        push({
          pathname: organizationIntegrations(organizationId),
          state: { integrationCreationError: true },
        });
      }
    );
  }

  return (
    <Centered>
      <Spinner />
    </Centered>
  );
}
