// @flow
import * as React from "react";
import Icon from "core/components/Icon";
import style from "./style.scss";

type Props = {
  // List of breadcrumb links
  +crumbs?: React.Node,
};

export default function Breadcrumb(props: Props) {
  const clonedChildren = React.Children.map(props.crumbs, (crumb, index) => {
    const clonedLink = React.cloneElement(crumb, {
      className: style.crumb,
    });
    if (index > 0) {
      return (
        <React.Fragment>
          <Icon type="slash" />
          {clonedLink}
        </React.Fragment>
      );
    }
    return clonedLink;
  });
  return <div className={style.breadcrumb}>{clonedChildren}</div>;
}
