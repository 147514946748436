// @flow
import * as React from "react";
import CommentBase, { type Props } from "./Base";
import DefaultComment from "./DefaultComment";
import Timestamp from "./Timestamp";
import style from "./style.scss";

export default class ReferenceComment extends React.Component<Props> {
  get reference(): * {
    let type;
    let subject;

    if (!this.props.context.layerId && this.props.layerId) {
      type = "layer";
      subject = this.props.layerName;
    } else if (!this.props.context.sha && this.props.commitSha) {
      type = "commit";
      subject = this.props.commitTitle;
    } else if (!this.props.context.branchId && this.props.branchId) {
      type = "branch";
      subject = this.props.branchName;
    }

    if (!type) {
      return null;
    }

    return subject ? (
      <span>
        the {type} <strong>{subject}</strong>
      </span>
    ) : (
      `a ${type}`
    );
  }

  get meta() {
    return (
      <span
        tabIndex="-1"
        onClick={this.props.onClick}
        className={style.metaLink}
      >
        <Timestamp
          createdAt={this.props.createdAt}
          editedAt={this.props.editedAt}
        />
      </span>
    );
  }

  get title() {
    const { deletedAt, parentId, user, onClick } = this.props;

    const userName = (
      <strong>
        {deletedAt ? "Deleted comment" : user ? user.name : "Unknown"}
      </strong>
    );

    if (parentId) {
      return userName;
    }

    return (
      <span onClick={onClick} tabIndex="-1" className={style.titleLink}>
        {userName} {deletedAt ? " on " : " commented on "} {this.reference}
      </span>
    );
  }

  render() {
    if (!this.reference) {
      return <DefaultComment {...this.props} />;
    }

    return <CommentBase {...this.props} title={this.title} meta={this.meta} />;
  }
}
