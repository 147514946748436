// @flow
import empty from "empty";
import { connect } from "react-redux";
import { getCurrentUserId } from "abstract-di/selectors";
import { getNestedBranches } from "core/selectors/branches";
import { getReviewRequests } from "core/selectors/reviews";
import type { State, Dispatch } from "core/types";
import type { OwnProps, StateProps, Props } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const projectId = props.project.id;
  const userId = getCurrentUserId(state);

  return {
    branches: userId
      ? getNestedBranches(state, {
          projectId,
          userId,
          branchFilter: "mine",
        })
      : empty.array,
    reviewRequests: getReviewRequests(state, { projectId }),
  };
}

export default connect<Props, OwnProps, StateProps, _, State, Dispatch>(
  mapStateToProps
);
