// @flow
import get from "lodash/get";
import * as React from "react";
import { NON_ENTERPRISE_SEAT_CAP } from "core/lib/subscriptions";
import type {
  OrganizationInvitationParams,
  ProjectInvitationParams,
  TeamInvitationParams,
} from "core/requests/invitations";
import type {
  Invitation,
  Organization,
  Project,
  Subscription,
  SubscriptionRole,
  Team,
} from "core/types";
import EmailScreen from "./EmailScreen";
import InviteTypeScreen from "./InviteTypeScreen";
import LinkScreen from "./LinkScreen";
import RoleScreen from "./RoleScreen";
import connector from "./connector";

export type OwnProps = {|
  project?: Project,
  onDismissDialog: () => void,
  organization: Organization,
  subscription: ?Subscription,
  team?: Team,
|};

export type StateProps = {|
  canAddContributors: boolean,
  seatCapEnabled: boolean,
|};

export type DispatchProps = {|
  createOrganizationInvites: (OrganizationInvitationParams) => Promise<
    Invitation[],
  >,
  createProjectInvites: (ProjectInvitationParams) => Promise<Invitation[]>,
  createTeamInvites: (TeamInvitationParams) => Promise<Invitation[]>,
  onMount?: () => void,
  onSuccess: (alert?: string) => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type State = {
  screen: "select" | "link" | "email" | "addSeats" | "inviteType",
  role: "viewer" | "contributor" | "branchesContributor",
};

class InviteUsers extends React.Component<Props, State> {
  state: State = {
    screen: "select",
    role: this.props.canAddContributors ? "contributor" : "viewer",
  };

  componentDidMount() {
    if (this.props.onMount) {
      this.props.onMount();
    }
  }

  showSelect = () =>
    this.setState({
      screen: "select",
      role: this.props.canAddContributors ? "contributor" : "viewer",
    });
  showLink = () => this.setState({ screen: "link" });
  showEmail = () => this.setState({ screen: "email" });
  showAddSeats = () => this.setState({ screen: "addSeats" });
  showInviteType = () => this.setState({ screen: "inviteType" });

  handleRoleChange = ({ target }: SyntheticInputEvent<>) => {
    switch (target.value) {
      case "viewer":
      case "contributor":
      case "branchesContributor":
        this.setState({ role: target.value });
        break;
      default:
        return;
    }
  };

  getSubscriptionRole = (): SubscriptionRole => {
    const { role } = this.state;

    switch (role) {
      case "viewer":
        return "viewer";
      default:
        return "contributor";
    }
  };

  projectInvitationParams = (emails?: string[]): ProjectInvitationParams => {
    const { organization, project } = this.props;
    const projectId = project ? project.id : "";

    return {
      emails,
      organizationId: organization.id,
      projectId: projectId,
      projectRole: "member",
      role: "guest",
      subscriptionRole: this.getSubscriptionRole(),
      type: this.state.screen,
    };
  };

  teamInvitationParams = (emails?: string[]): TeamInvitationParams => {
    const { organization, team } = this.props;
    const teamId = team ? team.id : "";

    return {
      emails,
      teamId: teamId,
      organizationId: organization.id,
      role: "guest",
      subscriptionRole: this.getSubscriptionRole(),
      type: this.state.screen,
    };
  };

  organizationInvitationParams = (
    emails?: string[]
  ): OrganizationInvitationParams => {
    const { organization } = this.props;

    return {
      emails,
      organizationId: organization.id,
      role: "member",
      subscriptionRole: this.getSubscriptionRole(),
      type: this.state.screen,
    };
  };

  handleCreateInvites = (emails?: string[]) => {
    const { project, team } = this.props;

    if (project) {
      return this.props.createProjectInvites(
        this.projectInvitationParams(emails)
      );
    }

    if (team) {
      return this.props.createTeamInvites(this.teamInvitationParams(emails));
    }

    return this.props.createOrganizationInvites(
      this.organizationInvitationParams(emails)
    );
  };

  render() {
    const { screen, role } = this.state;
    const {
      organization,
      onSuccess,
      project,
      subscription,
      onDismissDialog,
      canAddContributors,
      seatCapEnabled,
    } = this.props;

    const seatCapEnforced =
      seatCapEnabled &&
      subscription &&
      subscription.type !== "enterprise" &&
      subscription.maxSeats === NON_ENTERPRISE_SEAT_CAP;

    if (screen === "link") {
      return (
        <LinkScreen
          role={role}
          project={project}
          organization={organization}
          onCreateInvite={this.handleCreateInvites}
          onSuccess={onSuccess}
          showInviteTypeScreen={this.showInviteType}
          onDismiss={onDismissDialog}
        />
      );
    }

    if (screen === "email") {
      const orgDomains = get(organization, "restrictedToDomains", [])
        .map((domain) => `jane.doe@${domain}`)
        .join(", ");

      return (
        <EmailScreen
          role={role}
          project={project}
          organization={organization}
          placeholder={orgDomains || "jane.doe@domain.com"}
          onSubmit={this.handleCreateInvites}
          onSuccess={onSuccess}
          showInviteTypeScreen={this.showInviteType}
          subscription={subscription}
          onDismiss={onDismissDialog}
          canAddContributors={canAddContributors}
          seatCapEnforced={seatCapEnforced}
        />
      );
    }

    if (screen === "inviteType") {
      return (
        <InviteTypeScreen
          role={role}
          onRequestLink={this.showLink}
          onRequestEmail={this.showEmail}
          onDismiss={onDismissDialog}
          showSelectScreen={this.showSelect}
        />
      );
    }

    return (
      <RoleScreen
        role={role}
        project={project}
        subscription={subscription}
        organization={organization}
        canAddContributors={canAddContributors}
        onNext={this.showInviteType}
        selectedRole={this.state.role}
        handleRoleChange={this.handleRoleChange}
        onDismiss={onDismissDialog}
      />
    );
  }
}

export default connector(InviteUsers);
