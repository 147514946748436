// @flow
import { getCurrentUserId } from "abstract-di/selectors";
import type { Action, ThunkAction, Entities } from "../types";

export function entitiesReceived(entities: Entities): Action {
  return { type: "core/ENTITIES_RECEIVED", payload: entities };
}

export function entitiesDeleted(entities: {
  [key: string]: Array<string>,
}): Action {
  return { type: "core/ENTITIES_DELETED", payload: entities };
}

export function entitiesPatched(payload: {
  entities: Entities,
  meta: { actorId: string },
}): ThunkAction {
  return (dispatch, getState) => {
    const currentUserId = getCurrentUserId(getState());

    // We don't want to act on patch events that were triggered by
    // ourself, since that would result in an unnecessary state
    // update, and possibly even a buggy experience.
    if (currentUserId !== payload.meta.actorId) {
      return {
        type: "core/ENTITIES_PATCHED",
        partialEntities: payload.entities,
      };
    }
  };
}

export function entitiesReplaced(payload: {
  type: string,
  ids: string[],
  entities: { [key: string]: Object },
}): Action {
  return { type: "core/ENTITIES_REPLACED", payload };
}
