// @flow
/* global window, HTMLButtonElement */
import classnames from "classnames";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import * as React from "react";
import ButtonTogglePreviewBackground from "core/components/ButtonTogglePreviewBackground";
import LayerSetControls from "core/components/LayerSetControls";
import MountProfiler from "core/components/MountProfiler";
import { Abstract } from "core/lib/abstract";
import eventInInput from "core/lib/eventInInput";
import KeyCode from "core/lib/keycode";
import { modifierKeyPressed, isWeb } from "core/lib/platform";
import { layerUrl } from "core/lib/urls";
import type {
  Annotation,
  Branch,
  Commit as TCommit,
  File,
  Page,
  Layer as TLayer,
  Project,
  LayerSetItem,
  LayerSetParams,
  LayerState,
} from "core/types";
import { altText } from "../../models/layer";
import Annotations from "../Annotations";
import Button from "../Button";
import ButtonSidebar from "../ButtonSidebar";
import ButtonToggleAnnotations from "../ButtonToggleAnnotations";
import Header from "../Header";
import LayerCanvas, { type ZoomState } from "../LayerCanvas";
import LayerDetailSidebarSection from "../LayerDetailSidebarSection";
import PillTab from "../PillTab";
import Sidebar from "../Sidebar";
import SidebarSection from "../SidebarSection";
import Tabs from "../Tabs";
import Transparency from "../Transparency";
import ZoomPercentageInput from "../ZoomPercentageInput";
import ZoomablePreview from "../ZoomablePreview";
import connector from "./connector";
import style from "./style.scss";

type TForm = $ReadOnly<{ annotation?: Annotation }>;

type TCommentList = {
  form: TForm,
  collapsed: boolean,
  selectedCommentId: ?string,
  onCommentSelected: (commentId: string) => void,
  commentsState?: {},
  onFormChanged: (TForm) => void,
};

export type OwnProps = {|
  canShowHistory?: boolean,
  collectionId?: string,
  commentId?: string,
  commentForm?: () => React.Node,
  commentsList: (TCommentList) => React.Node,
  defaultCollapsedLeftSidebar?: boolean,
  defaultCollapsedRightSidebar?: boolean,
  isLoadingMore?: boolean,
  isSyncing?: boolean,
  layerHistory: ({ handleSidebarClose: Function }) => React.Node,
  layerSetParams: ?LayerSetParams,
  mobile?: boolean,
  onChangeZoomState: (zoomState: ZoomState) => void,
  onClose?: () => void,
  onCollapseLeftSidebar?: (collapsed: boolean) => void,
  onCollapseRightSidebar?: (collapsed: boolean) => void,
  onSelectLayerSetItem?: (layerSetItem: ?LayerSetItem) => void,
  shareButton?: React.Node,
  goBackToSymbolInstanceButton?: React.Node,
  shareId?: string,
  params: Abstract.LayerVersionDescriptor,
  zoomState: ZoomState,
  isShowingResolvedCommentAnnotations: boolean,
|};

export type StateProps = {|
  annotations: Annotation[],
  allAnnotationsLength: number,
  branch: ?Branch,
  commentFormId: string,
  commit: ?TCommit,
  file: ?File,
  isLoggedIn: boolean,
  latestCommit?: TCommit,
  layer: ?TLayer,
  layerState: ?LayerState,
  page: ?Page,
  project: ?Project,
  canUsePreviewBackgroundSwitcher: boolean,
|};

export type StorageProps = {|
  handleForm: (commentFormId: string) => TForm,
  onFormChanged: (form: TForm, commentFormId: string) => void,
  defaultCollapsedLeftSidebar: ?boolean,
  defaultCollapsedRightSidebar: ?boolean,
  onCollapseLeftSidebar: (collapsed: boolean) => void,
  onCollapseRightSidebar: (collapsed: boolean) => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...StorageProps,
};

export type PropsWithoutStorage = {
  ...OwnProps,
  ...StateProps,
};

type State = {
  selectedMobileTab: "preview" | "comments",
  selectedCommentId: ?string,
  collapsedLeftSidebar: boolean,
  collapsedLeftSidebarMobile: boolean,
  collapsedRightSidebar: boolean,
  showAnnotations: boolean,
  annotationsHidden: boolean,
  showZoomedScrollHelp: boolean,
  zoomedScrollHelpHidden: boolean,
};

const SIDEBAR_MIN_WIDTH = 270;

class LayerDesign extends React.Component<Props, State> {
  layerCanvas: ?LayerCanvas;

  static defaultProps = {
    defaultCollapsedLeftSidebar: false,
    defaultCollapsedRightSidebar: false,
    canShowHistory: true,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      annotationsHidden: false, //for space keypress toggle
      collapsedLeftSidebarMobile: true,
      collapsedLeftSidebar: !!props.defaultCollapsedLeftSidebar,
      collapsedRightSidebar: !!props.defaultCollapsedRightSidebar,
      selectedCommentId: null,
      selectedMobileTab: "preview",
      showAnnotations: true,
      zoomedScrollHelpHidden: false,
      showZoomedScrollHelp: false,
    };
  }

  componentDidMount() {
    window.addEventListener("keyup", this.handleKeyUp);
    window.addEventListener("keydown", this.handleKeyDown);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.mobile && !this.props.mobile) {
      this.setState({ selectedMobileTab: "preview" });
    }

    if (
      prevState.collapsedLeftSidebar !== this.state.collapsedLeftSidebar &&
      this.props.onCollapseLeftSidebar
    ) {
      this.props.onCollapseLeftSidebar(this.state.collapsedLeftSidebar);
    }

    if (
      prevState.collapsedRightSidebar !== this.state.collapsedRightSidebar &&
      this.props.onCollapseRightSidebar
    ) {
      this.props.onCollapseRightSidebar(this.state.collapsedRightSidebar);
    }

    if (isWeb && prevProps.zoomState) {
      if (
        prevProps.zoomState.scaledToFit &&
        !this.props.zoomState.scaledToFit
      ) {
        this.setState({ showZoomedScrollHelp: true });
      }
      if (
        !prevProps.zoomState.scaledToFit &&
        this.props.zoomState.scaledToFit
      ) {
        this.setState({ showZoomedScrollHelp: false });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keyup", this.handleKeyUp);
    window.removeEventListener("keydown", this.handleKeyDown);
  }

  getForm(): TForm {
    return this.props.handleForm(this.props.commentFormId);
  }

  getLayerUrl(): string {
    const { branchId, fileId, layerId, projectId, sha } = this.props.params;
    return layerUrl(branchId, fileId, layerId, projectId, sha);
  }

  getAnnotationsWithNew(): Annotation[] {
    const { annotations, allAnnotationsLength } = this.props;
    const form = this.getForm();
    // We use concat rather than push here as we're dealing with an
    // immutable array. If the form has an annotation we're creating one
    // and need to add this to the list to be displayed.
    return form.annotation
      ? annotations.concat([
          {
            ...form.annotation,
            number: allAnnotationsLength + 1,
          },
        ])
      : annotations;
  }

  renderComments() {
    return (
      <div className={style.comments}>
        {this.props.commentsList({
          form: this.getForm(),
          collapsed: this.state.collapsedRightSidebar,
          onFormChanged: this.handleFormChanged,
          selectedCommentId: this.state.selectedCommentId,
          onCommentSelected: this.handleCommentSelected,
        })}
      </div>
    );
  }

  handleKeyDown = (event: KeyboardEvent) => {
    if (eventInInput(event)) {
      return;
    }

    switch (event.keyCode) {
      case KeyCode.KEY_PERIOD:
        if (modifierKeyPressed(event)) {
          this.handleToggleSidebars();
        }
        break;
      case KeyCode.KEY_A:
        event.preventDefault();
        event.stopPropagation();
        if (event.ctrlKey) {
          this.handleToggleAnnotations();
        }
        break;
      case KeyCode.KEY_SPACE:
        // Note: edge case of if the user is still focused on the
        // toggle annotations button: we don't want to overload
        // what the SPACE key can do, so we're opting to remove
        // the default toggle on SPACE_KEY keypress
        event.preventDefault();
        event.stopPropagation();
        if (
          this.state.showAnnotations &&
          !(event.currentTarget instanceof HTMLButtonElement)
        ) {
          this.handleToggleAnnotations();
          this.setState({ annotationsHidden: true });
        }
        if (this.state.showZoomedScrollHelp) {
          this.setState({ zoomedScrollHelpHidden: true });
        }
        break;
      default:
        return;
    }
  };

  handleKeyUp = (event: KeyboardEvent) => {
    if (eventInInput(event)) {
      return;
    }
    switch (event.keyCode) {
      case KeyCode.KEY_SPACE:
        if (this.state.annotationsHidden) {
          this.handleToggleAnnotations();
          this.setState({ annotationsHidden: false });
        }
        if (this.state.zoomedScrollHelpHidden) {
          this.setState({ zoomedScrollHelpHidden: false });
        }
        break;
      default:
        return;
    }
  };

  handleToggleSidebars = () => {
    this.setState((prevState) => {
      const collapsedSidebar =
        prevState.collapsedLeftSidebar || prevState.collapsedRightSidebar;

      return {
        collapsedLeftSidebar: !collapsedSidebar,
        collapsedRightSidebar: !collapsedSidebar,
      };
    });
  };

  handleToggleAnnotations = () => {
    this.setState((prevState) => ({
      showAnnotations: !prevState.showAnnotations,
      selectedCommentId: prevState.showAnnotations
        ? null
        : prevState.selectedCommentId,
    }));
  };

  handleFormChanged = (form: TForm) => {
    const currentForm = this.getForm();
    if (!isEqual(form.annotation, currentForm.annotation)) {
      this.handleAnnotationUpdated(form.annotation);
    }
  };

  handleAnnotationUpdated = (annotation?: Annotation) => {
    const currentForm = this.getForm();
    const { onFormChanged, commentFormId } = this.props;
    const isNewAnnotation = !currentForm.annotation && !!annotation;

    if (onFormChanged) {
      onFormChanged({ annotation }, commentFormId);
    }

    if (!isNewAnnotation) {
      return;
    }

    this.setState({
      showAnnotations: true,
      collapsedRightSidebar: false,
    });
  };

  handleZoomToFit = (zoomState: ZoomState): ZoomState => {
    const zoomablePreview: ?ZoomablePreview = get(this, [
      "layerCanvas",
      "zoomablePreview",
    ]);

    if (!zoomablePreview) {
      return zoomState;
    }

    return {
      scale: zoomablePreview.scaleToFit,
      scaledToFit: true,
      offsetX: 0,
      offsetY: 0,
    };
  };

  handleCommentSelected = (commentId: ?string) => {
    this.setState({
      showAnnotations: true,
      selectedCommentId: commentId,
    });

    if (commentId && this.state.collapsedRightSidebar) {
      this.setState({ collapsedRightSidebar: false });
    }
  };

  handleCommentDeselected = () => {
    this.setState({ selectedCommentId: null });
  };

  render() {
    const form = this.getForm();
    const annotationsWithNew = this.getAnnotationsWithNew();
    const {
      collapsedLeftSidebar,
      collapsedLeftSidebarMobile,
      collapsedRightSidebar,
      selectedCommentId,
      selectedMobileTab,
      showAnnotations,
    } = this.state;
    const {
      branch,
      canShowHistory,
      collectionId,
      commit,
      file,
      isSyncing,
      layer,
      layerHistory,
      layerSetParams,
      layerState,
      mobile,
      onChangeZoomState,
      onSelectLayerSetItem,
      page,
      params,
      project,
      shareButton,
      shareId,
      zoomState,
      canUsePreviewBackgroundSwitcher,
    } = this.props;

    return (
      <div className={style.layerDesign}>
        {mobile && (
          <div className={style.mobileHeader}>
            <Header
              center={
                <Tabs>
                  <PillTab
                    label="Preview"
                    onClick={() =>
                      this.setState({ selectedMobileTab: "preview" })
                    }
                    selected={selectedMobileTab === "preview"}
                  />
                  <PillTab
                    label="Comments"
                    onClick={() =>
                      this.setState({ selectedMobileTab: "comments" })
                    }
                    selected={selectedMobileTab === "comments"}
                  />
                </Tabs>
              }
              left={
                canShowHistory && (
                  <div className={style.mobileToolbarLeft}>
                    <Button
                      icon="list"
                      onClick={() =>
                        this.setState({ collapsedLeftSidebarMobile: false })
                      }
                      title={`View ${
                        layer ? layer.type : "item"
                      } history and details`}
                      nude
                      tint
                    />
                  </div>
                )
              }
              right={
                <div className={style.mobileToolbarRight}>{shareButton}</div>
              }
            />
          </div>
        )}
        <div className={style.content}>
          {canShowHistory && (
            <Sidebar
              title="History & Details"
              direction="left"
              contentClassName={classnames(
                style.sidebarContent,
                style.sidebarMaxWidth
              )}
              collapsed={collapsedLeftSidebar}
              active={!collapsedLeftSidebarMobile}
              onClose={() =>
                this.setState({ collapsedLeftSidebarMobile: true })
              }
              minWidth={SIDEBAR_MIN_WIDTH}
              contentBorder
              resizable="layers-left"
              wide
            >
              <SidebarSection flex>
                {layerHistory({
                  handleSidebarClose: () =>
                    this.setState({ collapsedLeftSidebarMobile: true }),
                })}
              </SidebarSection>
              {!shareId && (
                <LayerDetailSidebarSection
                  params={params}
                  project={project}
                  branch={branch}
                  commit={commit}
                  file={file}
                  page={page}
                  layer={layer}
                  layerState={layerState}
                  collectionId={collectionId}
                />
              )}
            </Sidebar>
          )}
          <div className={style.main}>
            {selectedMobileTab === "comments" && this.renderComments()}
            {selectedMobileTab === "preview" && (
              <div className={style.layerCanvas}>
                <div className={style.floatingCanvasButtonContainer}>
                  {this.props.goBackToSymbolInstanceButton}
                </div>
                <Transparency
                  useDynamicPreviewBackground={
                    this.props.canUsePreviewBackgroundSwitcher
                  }
                >
                  {!layer && (
                    <MountProfiler
                      id="LayerDetailPreviewLoading"
                      params={params}
                      unstable_profileOnUnmount
                    />
                  )}
                  <LayerCanvas
                    ref={(ref) => (this.layerCanvas = ref)}
                    params={params}
                    layer={layer}
                    layerUrl={this.getLayerUrl()}
                    layerSyncing={isSyncing}
                    layerMissing={layerState === "not_found"}
                    alt={altText(this.props.layer)}
                    isCreatingAnnotation={form.annotation !== undefined}
                    zoomState={zoomState}
                    onChangeZoomState={onChangeZoomState}
                    shareId={shareId}
                  >
                    {(dimensions, scale, style) =>
                      showAnnotations ? (
                        <Annotations
                          style={style}
                          scale={scale}
                          width={dimensions.width}
                          height={dimensions.height}
                          annotations={annotationsWithNew}
                          editingId={
                            form.annotation ? form.annotation.id : undefined
                          }
                          selectedId={selectedCommentId}
                          onAnnotationSelected={this.handleCommentSelected}
                          onAnnotationDeselected={this.handleCommentDeselected}
                          onAnnotationUpdated={this.handleAnnotationUpdated}
                        />
                      ) : null
                    }
                  </LayerCanvas>
                  {this.state.showZoomedScrollHelp &&
                    !this.state.zoomedScrollHelpHidden && (
                      <p className={style.zoomedScrollHelpMessage}>
                        Hold spacebar & click and drag to scroll
                      </p>
                    )}
                </Transparency>
                <div className={style.layerCanvasFooter}>
                  <Header
                    center={
                      <LayerSetControls
                        onSelectLayerSetItem={onSelectLayerSetItem}
                        layerSetParams={layerSetParams}
                        layerParams={params}
                        shareLinkId={shareId}
                      />
                    }
                    left={
                      <div className={style.footerToolbarLeft}>
                        {canShowHistory && (
                          <ButtonSidebar
                            className={style.toggleLeftSidebarButton}
                            direction="left"
                            active={!collapsedLeftSidebar}
                            onClick={() =>
                              this.setState((state) => ({
                                collapsedLeftSidebar:
                                  !state.collapsedLeftSidebar,
                              }))
                            }
                          />
                        )}
                        {canUsePreviewBackgroundSwitcher && (
                          <React.Fragment>
                            <div
                              className={classnames(
                                style.divider,
                                style.dividerLeftToolbar
                              )}
                            />
                            <ButtonTogglePreviewBackground
                              className={style.togglePreviewBackgroundButton}
                            />
                          </React.Fragment>
                        )}
                        <ButtonToggleAnnotations
                          disabled={form.annotation !== undefined || !layer}
                          active={
                            form.annotation !== undefined || showAnnotations
                          }
                          onClick={this.handleToggleAnnotations}
                        />
                      </div>
                    }
                    right={
                      <div className={style.footerToolbarRight}>
                        <ZoomPercentageInput
                          disabled={!layer}
                          zoomState={zoomState}
                          onChange={onChangeZoomState}
                          onZoomToFit={this.handleZoomToFit}
                        />

                        <div
                          className={classnames(
                            style.divider,
                            style.dividerRightToolbar
                          )}
                        />
                        <ButtonSidebar
                          className={style.toggleRightSidebarButton}
                          direction="right"
                          active={!collapsedRightSidebar}
                          onClick={() =>
                            this.setState((state) => ({
                              collapsedRightSidebar:
                                !state.collapsedRightSidebar,
                            }))
                          }
                        />
                      </div>
                    }
                  />
                </div>
              </div>
            )}
          </div>
          <Sidebar
            direction="right"
            collapsed={collapsedRightSidebar}
            contentClassName={style.sidebarMaxWidth}
            minWidth={SIDEBAR_MIN_WIDTH}
            resizable="layers-right"
            wide
          >
            {this.renderComments()}
          </Sidebar>
        </div>
      </div>
    );
  }
}

export default connector(LayerDesign);
