// @flow
import { entitiesDeleted } from "core/actions/entities";
import { Abstract } from "core/lib/abstract";
import * as Changeset from "core/models/changeset";
import {
  type BranchChangesetParams,
  BranchChangesetFetchRequest,
  CommitChangesetFetchRequest,
  ProjectChangesetFetchRequest,
} from "core/requests/changesets";
import type { ThunkAction, ChangesetIdentifier } from "core/types";

export function loadBranchChangeset(
  descriptor: BranchChangesetParams
): ThunkAction {
  return BranchChangesetFetchRequest.perform({
    params: descriptor,
  });
}

export function loadCommitChangeset(
  descriptor: Abstract.BranchCommitDescriptor
): ThunkAction {
  return CommitChangesetFetchRequest.perform({
    force: Changeset.isWorkingTree(descriptor),
    params: descriptor,
  });
}

export function loadWorkingTreeChangeset(
  descriptor: Abstract.ProjectDescriptor
): ThunkAction {
  return ProjectChangesetFetchRequest.perform({
    force: true,
    params: descriptor,
  });
}

export function changesetDeleted(params: ChangesetIdentifier) {
  return entitiesDeleted({ changesets: [Changeset.uniqueId(params)] });
}
