// @flow
import * as React from "react";
import { V3Link as Link } from "core/lib/router";
import { projectPath } from "core/lib/routes";
import ActivityBase from "./Base";
import type {
  Props,
  ActivityProjectRenamed as TActivityProjectRenamed,
} from "./types";

export default function ActivityProjectRenamed(
  props: Props<TActivityProjectRenamed>
) {
  return (
    <ActivityBase
      icon="project"
      {...props}
      action="renamed the project"
      subject={
        <span>
          <del>{props.activity.payload.previousName}</del> to{" "}
          <Link to={projectPath(props.activity.projectId)}>
            {props.activity.payload.newName}
          </Link>
        </span>
      }
    />
  );
}
