// @flow
import * as React from "react";
import { Waypoint } from "react-waypoint";
import Centered from "core/components/Centered";
import Loading from "core/components/Loading";
import VirtualizedList, { type Row } from "core/components/VirtualizedList";
import type { Project, User, Team } from "core/types";
import TeamAddItem from "./TeamAddItem";
import UserAddItem from "./UserAddItem";
import style from "./style.scss";

const VERTICAL_PADDING = 8;
const ROW_HEIGHT = 46;

type Props = {|
  organizationId: string,
  filter: string,
  onAddMember: (projectId: string, userId: string) => void,
  onAddTeam: (projectId: string, teamId: string) => void,
  project: Project,
  users: User[],
  teams: Team[],
  isLoadingNextPage?: boolean,
  onLoadNextPage?: () => void,
  loadMoreTeamsButton?: React.Node,
|};

export default function MemberAddList(props: Props) {
  const userListRef = React.useRef<VirtualizedList | null>(null);
  const { filter } = props;

  React.useEffect(() => {
    const userList = userListRef.current;
    if (userList) {
      userList.scrollTo({ index: 0 });
    }
  }, [filter]);

  function getTeams(): Row[] {
    const teamItems = props.teams.map((team) => ({
      style: (rowStyle) => ({
        ...rowStyle,
        top: rowStyle.top + VERTICAL_PADDING,
      }),
      height: ROW_HEIGHT,
      children: (
        <TeamAddItem
          organizationId={props.organizationId}
          key={team.id}
          onAddTeam={props.onAddTeam}
          project={props.project}
          team={team}
          highlight={props.filter}
        />
      ),
    }));
    if (props.loadMoreTeamsButton) {
      teamItems.push({
        height: 32,
        children: props.loadMoreTeamsButton,
      });
    }
    const borderline = {
      height: 16,
      style: (rowStyle) => ({
        ...rowStyle,
        top: rowStyle.top + VERTICAL_PADDING,
      }),
      children: <div className={style.borderline} />,
    };

    if (props.teams.length > 0 && props.users.length > 0) {
      teamItems.push(borderline);
    }

    return teamItems;
  }

  function getUsers(): Row[] {
    const items = props.users.map((user) => ({
      style: (rowStyle) => ({
        ...rowStyle,
        top: rowStyle.top + VERTICAL_PADDING,
      }),
      height: ROW_HEIGHT,
      children: (
        <UserAddItem
          key={user.id}
          onAddMember={props.onAddMember}
          project={props.project}
          user={user}
          highlight={props.filter}
        />
      ),
    }));

    if (props.isLoadingNextPage) {
      return items.concat({
        style: (rowStyle) => ({
          ...rowStyle,
          top: rowStyle.top + VERTICAL_PADDING,
        }),
        height: ROW_HEIGHT,
        children: (
          <Centered>
            <Loading small />
          </Centered>
        ),
      });
    }

    return items;
  }

  function getItems(): Row[] {
    const teams = getTeams();
    const users = getUsers();
    return teams.concat(users);
  }

  return (
    <VirtualizedList
      ref={userListRef}
      items={getItems()}
      innerElementType={({
        children,
        style: innerElementStyle,
        ...rest
      }: {
        children: React.Node,
        style: { height: number },
      }) => {
        return (
          <div
            style={{
              ...innerElementStyle,
              height: innerElementStyle.height + VERTICAL_PADDING * 2,
            }}
            {...rest}
          >
            {children}

            {props.onLoadNextPage ? (
              <div style={{ position: "absolute", width: "100%", bottom: 0 }}>
                <Waypoint topOffset="1440px" onEnter={props.onLoadNextPage} />
              </div>
            ) : null}
          </div>
        );
      }}
    />
  );
}
