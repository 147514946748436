// @flow
import classnames from "classnames";
import pluralize from "pluralize";
import * as React from "react";
import Badge from "core/components/Badge";
import Flex from "core/components/Flex";
import PreviewImage from "core/components/PreviewImage";
import PreviewLoader from "core/components/PreviewLoader";
import Time from "core/components/Time";
import type { Collection, Layer } from "core/types";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  collection: Collection,
  className?: string,
  titleClass?: string,
  isLight?: boolean,
  large?: boolean,
|};

export type StateProps = {|
  count: number,
  layers: Layer[],
|};

export type DispatchProps = {|
  onMount: () => void,
|};

type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

class CollectionPreview extends React.Component<Props> {
  componentDidMount() {
    if (this.props.onMount) {
      this.props.onMount();
    }
  }

  render() {
    const { collection, count, layers, isLight, large, className, titleClass } =
      this.props;

    const PREVIEW_COUNT = 4;
    const previewLayers = layers.slice(0, PREVIEW_COUNT);

    return (
      <div
        className={classnames(style.content, className, {
          [style.light]: isLight,
          [style.large]: large,
        })}
      >
        <Flex className={style.textOverflow} align="center">
          <Flex column className={style.main}>
            <div className={style.heading}>
              <div
                className={classnames(style.name, titleClass)}
                title={collection.name}
              >
                {collection.name}
              </div>
              {!collection.publishedAt && (
                <div className={style.statusBadge}>
                  <Badge label="Draft" light={isLight} />
                </div>
              )}
            </div>
            <div className={style.meta}>
              {count || "No"}&nbsp;
              {pluralize("layer", count)}
              &nbsp;·&nbsp;
              <Time date={collection.createdAt} />
            </div>
          </Flex>
        </Flex>

        <div className={classnames(style.layerStack, { [style.large]: large })}>
          {previewLayers.map((layer, index) => {
            return (
              <div
                key={index}
                className={classnames(style.layerStackThumbnail, {
                  [style.large]: large,
                })}
              >
                <PreviewLoader
                  key={[layer.fileId, layer.id, layer.lastChangedAtSha].join()}
                  projectId={collection.projectId}
                  commitSha={layer.lastChangedAtSha}
                  fileId={layer.fileId}
                  layerId={layer.id}
                  scale={0.5}
                >
                  {(src, loading, error) => (
                    <PreviewImage
                      className={style.previewImage}
                      width={large ? 64 : 48}
                      height={48}
                      src={src}
                      alt={layer.name}
                      errorText={error}
                      cover
                    />
                  )}
                </PreviewLoader>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default connector(CollectionPreview);
