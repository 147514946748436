// @flow
import React from "react";
import { CountryDropdown } from "react-country-region-selector";
import InputWrapper from "core/components/InputWrapper";
import style from "core/components/Select/style.scss";
import countryStyle from "./style.scss";

type Props = {
  wrapperClass?: string,
  label?: string,
  priorityOptions?: string[],
  error?: ?(string | string[]),
};

export default function InputSelectCountry(props: Props) {
  const classes = [style.select, countryStyle.custom_dark].join(" ");
  const priorityOptions = ["US", "CA", "GB"];
  return (
    <InputWrapper
      className={props.wrapperClass}
      label={props.label}
      error={props.error}
    >
      <CountryDropdown
        priorityOptions={props.priorityOptions || priorityOptions}
        valueType="short"
        className={classes}
        {...props}
      />
    </InputWrapper>
  );
}
