// @flow
import * as Abstract from "abstract-sdk";
import * as React from "react";
import DocumentTitle from "core/components/DocumentTitle";
import Error from "core/components/Empty/Error";
import NotFound from "core/components/Empty/NotFound";
import NotPermitted from "core/components/Empty/NotPermitted";
import type {
  Organization as TOrganization,
  ReactRouterLocation,
} from "core/types";
import connector from "./connector";

export type OwnProps = {|
  children?: React.Node,
  location: ReactRouterLocation,
  params: Abstract.OrganizationDescriptor,
|};

export type StateProps = {|
  hasError: boolean,
  notPermitted: boolean,
  organization: ?TOrganization,
  organizationLoading: boolean,
  sidebarIsOpen: boolean,
|};

export type DispatchProps = {|
  onMount: () => void,
  onCloseSidebar: () => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

function Organization(props: Props) {
  const { onMount, organization } = props;

  React.useEffect(() => {
    onMount();
  }, [onMount]);

  if (!organization && !props.organizationLoading) {
    return <NotFound />;
  }

  if (props.notPermitted) {
    return <NotPermitted />;
  }

  if (props.hasError) {
    return <Error />;
  }

  return (
    <React.Fragment>
      {organization && (
        <DocumentTitle
          titleTemplate={`${organization.name} - %s`}
          defaultTitle={organization.name}
        />
      )}
      {props.children}
    </React.Fragment>
  );
}

export default connector(Organization);
