// @flow
import createCachedSelector from "@elasticprojects/re-reselect";
import * as Abstract from "abstract-sdk";
import { filter, reduce, sortBy, orderBy, values, pick } from "lodash";
import { createSelector } from "reselect";
import { getIsUnsyncedByProject } from "abstract-di/selectors";
import { DEFAULT_PROJECT_SORT, DEFAULT_PROJECT_FILTER } from "core/lib/lists";
import matchString from "core/lib/matchString";
import naturalSortBy from "core/lib/naturalSortBy";
import { BRANCH_ID_MASTER } from "core/models/branch";
import { getBranchesForProject } from "core/selectors/branches";
import { getCommitsForEntity } from "core/selectors/commits";
import { canOrganizationUsePartialSync } from "core/selectors/features";
import { getSections } from "core/selectors/sections";
import { getStarEntities } from "core/selectors/stars";
import { getTeamProjectMembershipsForTeam } from "core/selectors/teamProjectMemberships";
import type {
  Project,
  ProjectSort,
  ProjectFilter,
  ProjectType,
  State,
  TeamDescriptor,
} from "core/types";
import { getOrganizationId } from "./helpers";

export function getProjectEntities(state: State) {
  return state.projects;
}

export function getProject(state: State, { projectId }: { projectId: string }) {
  return state.projects[projectId];
}

export const getProjects: (State, { organizationId: string }) => Project[] =
  createCachedSelector(
    getProjectEntities,
    getOrganizationId,
    (projects, organizationId) => filter(values(projects), { organizationId })
  )(getOrganizationId);

const getQuery = (state, props: { query?: string }) => props.query;
const getFilter = (state, props) => props.filter || DEFAULT_PROJECT_FILTER;
const getSort = (state, props) => props.sort || DEFAULT_PROJECT_SORT;
const getType = (state, props) => props.type;

const queryFilter = (project: Project, query?: string): boolean => {
  if (!query) {
    return true;
  }

  return matchString(project.name, query) || matchString(project.about, query);
};

const getFilteredProjectsCacheId = (state, props) =>
  [
    props.organizationId,
    props.filter || DEFAULT_PROJECT_FILTER,
    props.query || "",
    props.sort || DEFAULT_PROJECT_SORT,
    props.type || "",
  ].join("-");

export const getFilteredProjects: (
  state: State,
  props: {
    organizationId: string,
    filter?: ProjectFilter,
    query?: string,
    sort?: ProjectSort,
    type?: ProjectType,
  }
) => Project[] = createCachedSelector(
  canOrganizationUsePartialSync,
  getProjects,
  getIsUnsyncedByProject,
  getStarEntities,
  getFilter,
  getQuery,
  getSort,
  getType,
  (partialSync, projects, unsynced, stars, typeFilter, query, sort, type) => {
    let filtered = projects;

    if (typeFilter === "archived") {
      filtered = filter(projects, (project) => !!project.archivedAt);
    } else if (typeFilter === "starred") {
      filtered = filter(projects, (project) => {
        const star = stars[project.id];
        return star && star.starredAt && !project.archivedAt;
      });
    } else if (typeFilter === "active") {
      filtered = filter(projects, (project) => !project.archivedAt);
    }

    if (sort === "asc" || sort === "desc") {
      filtered = orderBy(
        filtered,
        [(proj) => proj.pushedAt || proj.updatedAt || proj.createdAt],
        [sort]
      );
    } else {
      filtered = naturalSortBy(filtered, "name");
    }

    if (typeFilter !== "archived") {
      filtered = sortBy(filtered, (project) => !stars[project.id]); // Sort starred projects to the top of the list
    }

    if (type !== undefined) {
      filtered = filter(filtered, { type });
    }

    if (query) {
      filtered = filter(filtered, (project) => queryFilter(project, query));
    }

    return partialSync
      ? filtered
      : sortBy(filtered, (project) => !!unsynced[project.id]); // Sort unsynced projects to the end of the list
  }
)(getFilteredProjectsCacheId);

export const getOrganizationIsEmpty: (
  state: State,
  { organizationId: string }
) => boolean = createCachedSelector(
  getProjects,
  (projects) => projects.length === 0
)(getOrganizationId);

export const getProjectIsEmpty: (
  state: State,
  params: Abstract.ProjectDescriptor
) => boolean = createSelector(
  getProject,
  getBranchesForProject,
  (state, params) =>
    getCommitsForEntity(state, {
      projectId: params.projectId,
      branchId: BRANCH_ID_MASTER,
    }),
  (project, branches, masterCommits) => {
    if (branches.length > 1) {
      return false;
    }
    if (masterCommits.length > 1) {
      return false;
    }

    return !!(project && project.isNew);
  }
);

export const getActiveProjectsForOrganization = (
  state: State,
  props: { organizationId: string }
): Project[] => {
  return getFilteredProjects(state, {
    filter: "active",
    organizationId: props.organizationId,
  });
};

export const getProjectsBySection: (
  State,
  {
    organizationId: string,
    query?: string,
    filter?: ProjectFilter,
    sort?: ProjectSort,
    type?: ProjectType,
  }
) => { [sectionId: string]: Project[] } = createCachedSelector(
  getSections,
  getFilteredProjects,
  (sections, projects) => {
    const keyed = reduce(
      sections,
      (memo, section) => {
        memo[section.id] = filter(projects, { sectionId: section.id });
        return memo;
      },
      {}
    );

    keyed["unfiled"] = filter(projects, (project) => {
      return !project.sectionId || !keyed[project.sectionId];
    });

    return keyed;
  }
)(getFilteredProjectsCacheId);

function cacheTeamProjects(state: State, { teamId, query = "" }) {
  return `${teamId}-${query}`;
}

export const getProjectsForTeam: (
  state: State,
  props: { ...TeamDescriptor, query?: string }
) => Project[] = createCachedSelector(
  getTeamProjectMembershipsForTeam,
  getProjectEntities,
  getQuery,
  (teamProjectMemberships, projects, query) => {
    const teamProjects = naturalSortBy(
      values(
        pick(
          projects,
          teamProjectMemberships.map((m) => m.projectId)
        )
      ),
      "name"
    );

    if (query) {
      return filter(teamProjects, (project) => queryFilter(project, query));
    }

    return teamProjects;
  }
)(cacheTeamProjects);
