// @flow
import classnames from "classnames";
import * as React from "react";
import Flex from "core/components/Flex";
import InputCheckbox from "core/components/InputCheckbox";
import InputSearch from "core/components/InputSearch";
import type { WebhookEvent } from "core/types";
import EventGroup from "./EventGroup";
import EventRow from "./EventRow";
import style from "./style.scss";

type Props = {
  data: string[],
  filter?: string,
  onChangeData?: (data: string[]) => void,
  onChangeFilter?: (filter: string) => void,
  readOnly?: boolean,
  webhookEvents: WebhookEvent[],
};

export default class WebhookEventTree extends React.Component<Props> {
  static defaultProps = {
    data: [],
  };

  get filteredTypes() {
    const { filter = "", webhookEvents } = this.props;
    const sanitizedFilter = filter.toLowerCase();
    const filteredTypes: WebhookEvent[] = webhookEvents.filter(
      ({ name }) => name.toLowerCase().search(sanitizedFilter) > -1
    );
    return filteredTypes;
  }

  onChangeFilter = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const { onChangeFilter } = this.props;
    onChangeFilter && onChangeFilter(event.target.value);
  };

  renderGroups() {
    let { data } = this.props;
    const { onChangeData, webhookEvents } = this.props;
    const groups = {};
    const cacheGroup = (id, group) => (groups[id] = group);

    return this.filteredTypes.map<React.Node>((webhookEvent) => {
      const groupIds = webhookEvents
        .filter(
          (filteredWebhookEvent) =>
            filteredWebhookEvent.group.id === webhookEvent.group.id
        )
        .map((filteredWebhookEvent) => filteredWebhookEvent.id);

      return (
        <React.Fragment key={webhookEvent.id}>
          {!groups[webhookEvent.group.id] &&
            cacheGroup(webhookEvent.group.id, webhookEvent.group) && (
              <EventGroup
                data={data}
                groupIds={groupIds}
                onChangeData={onChangeData}
                webhookGroup={webhookEvent.group}
              />
            )}

          <EventRow
            data={data}
            onChangeData={onChangeData}
            webhookEvent={webhookEvent}
          />
        </React.Fragment>
      );
    });
  }

  render() {
    let { data } = this.props;
    const { filter, onChangeData, readOnly, webhookEvents } = this.props;

    return (
      <div className={readOnly ? style.readOnly : undefined}>
        {!readOnly && (
          <React.Fragment>
            <InputSearch
              className={style.searchBar}
              onChange={this.onChangeFilter}
              value={filter}
            />
            <InputCheckbox
              checkboxClass={style.checkbox}
              checked={webhookEvents.every(({ id }) => data.includes(id))}
              key="all"
              label={
                <React.Fragment>
                  <Flex className={style.eventName} grow={false} shrink={false}>
                    Select all events
                  </Flex>
                  <span className={style.eventMeta}>
                    {webhookEvents.length} events
                  </span>
                </React.Fragment>
              }
              labelClass={style.checkboxLabel}
              onChange={(event) => {
                if (event.target.checked) {
                  webhookEvents.forEach((webhookType) => {
                    !data.includes(webhookType.id) && data.push(webhookType.id);
                  });
                } else {
                  data = [];
                }
                onChangeData && onChangeData(data);
              }}
              responsive
              wrapperClass={classnames(
                style.checkboxWrapper,
                style.groupWrapper
              )}
            />
          </React.Fragment>
        )}
        {this.renderGroups()}
      </div>
    );
  }
}
