// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { showToast } from "core/actions/toasts";
import Loaded from "core/components/Loaded";
import { replace } from "core/lib/location";
import * as Request from "core/models/request";
import { OrganizationsFetchRequest } from "core/requests/organizations";
import { getNonUsernameOrganizations } from "core/selectors/organizations";
import type {
  Dispatch,
  State,
  ThunkAction,
  ReactRouterLocation,
} from "core/types";
import { loadEmails } from "web/actions/emails";
import { loadOrganizations } from "web/actions/organizations";
import EmailVerificationError from "web/components/Onboarding/EmailVerificationError";
import { VerifyEmailRequest } from "web/requests/account";
import {
  invitationPath,
  accountEmails,
  signupVerifyEmail,
  signin,
} from "web/routeHelpers";

import { getVerifiedEmails } from "web/selectors/emails";
import { getEmailsRequest, getSessionRequest } from "web/selectors/requests";
import { isLoggedIn } from "../selectors/session";

type OwnProps = {|
  location: ReactRouterLocation,
  params: { id: string },
|};

type StateProps = {|
  emailsAndOrganizationsLoading: boolean,
  noConfirmedEmails: boolean,
  noOrganizations: boolean,
  sessionRequestLoading: boolean,
  verifyEmailRequestSent: boolean,
  isLoggedIn: boolean,
|};

type DispatchProps = {|
  loadEmails: () => void,
  loadOrganizations: () => void,
  verifyEmail: () => ThunkAction,
  showNotLoggedVerifiedEmailToast: () => void,
|};

type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type ComponentState = { isLoading: boolean };

class EmailVerifier extends Component<Props, ComponentState> {
  state = { isLoading: true };

  componentDidMount() {
    if (!this.props.sessionRequestLoading) {
      this.redirectOrVerifyEmail();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.sessionRequestLoading && !this.props.sessionRequestLoading) {
      if (this.props.isLoggedIn) {
        this.props.loadEmails();
        this.props.loadOrganizations();
      } else {
        this.notLoggedVerifyEmail();
      }
    }

    if (
      prevProps.emailsAndOrganizationsLoading &&
      !this.props.emailsAndOrganizationsLoading
    ) {
      this.redirectOrVerifyEmail();
    }
  }

  redirectOrVerifyEmail() {
    if (!this.props.emailsAndOrganizationsLoading) {
      // If the user has no confirmed emails and is not a member of an org yet,
      // then they most likely are going through the join existing org path of
      // the signup flow.
      if (
        !this.props.location.query.invitationId &&
        this.props.noConfirmedEmails &&
        this.props.noOrganizations
      ) {
        return replace({
          pathname: signupVerifyEmail(this.props.params.id),
          state: { onboarding: true },
        });
      }

      if (!this.props.verifyEmailRequestSent) {
        this.verifyEmail();
      }
    }
  }

  async notLoggedVerifyEmail() {
    this.setState({ isLoading: true });
    const email = await this.props.verifyEmail();
    if (email) {
      this.setState({ isLoading: false });
      this.props.showNotLoggedVerifiedEmailToast();
      replace(signin());
    } else {
      this.setState({ isLoading: false });
    }
  }
  async verifyEmail() {
    const invitationToken = this.props.location.query.invitationId;

    this.setState({ isLoading: true });
    const email = await this.props.verifyEmail();

    if (invitationToken && !!email) {
      replace({
        pathname: invitationPath(invitationToken, "add-email"),
        state: email,
      });
    } else if (email) {
      replace({
        pathname: accountEmails(),
        state: { verifiedEmail: email },
      });
    } else {
      this.setState({ isLoading: false });
    }
  }

  render() {
    return (
      <Loaded loading={this.state.isLoading}>
        <EmailVerificationError token={this.props.params.id} />
      </Loaded>
    );
  }
}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const verifiedEmails = getVerifiedEmails(state);
  const organizations = getNonUsernameOrganizations(state);

  return {
    emailsAndOrganizationsLoading:
      Request.isLoading(getEmailsRequest(state)) ||
      OrganizationsFetchRequest.isLoading(state),
    noConfirmedEmails: verifiedEmails.length === 0,
    noOrganizations: organizations.length === 0,
    sessionRequestLoading: Request.isLoading(getSessionRequest(state)),
    verifyEmailRequestSent: VerifyEmailRequest.isLoadingStrict(state, {
      id: props.params.id,
    }),
    isLoggedIn: isLoggedIn(state),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    loadEmails: () => {
      dispatch(loadEmails());
    },
    loadOrganizations: () => {
      dispatch(loadOrganizations());
    },
    showNotLoggedVerifiedEmailToast: () => {
      dispatch(
        showToast({
          text: "Your email has been verified. Please sign in.",
        })
      );
    },
    verifyEmail: () => {
      return dispatch(
        VerifyEmailRequest.perform({ params: { id: props.params.id } })
      );
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(
  mapStateToProps,
  mapDispatchToProps
)(EmailVerifier);
