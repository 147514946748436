// @flow
import * as React from "react";
import { V3Link as Link } from "core/lib/router";
import { branchPath } from "core/lib/routes";
import ActivityBase from "./Base";
import Callout from "./Callout";
import type {
  ActivityReviewRequested as TActivityReviewRequested,
  Props,
} from "./types";

function action(activity: TActivityReviewRequested) {
  const reviewerName =
    activity.userId === activity.payload.reviewerId ? (
      "themselves"
    ) : (
      <Callout>{activity.payload.reviewerName}</Callout>
    );
  const reviewerActsAs = activity.payload.reviewerCouldApprove
    ? "an approver"
    : "a reviewer";
  return (
    <span>
      added {reviewerName} as {reviewerActsAs}
    </span>
  );
}

export default function ActivityReviewRequested(
  props: Props<TActivityReviewRequested>
) {
  const { activity, context } = props;
  const url = branchPath(activity.projectId, activity.branchId);

  return (
    <ActivityBase
      {...props}
      icon="review-default"
      branchId={activity.branchId}
      branchName={activity.payload.branchName}
      action={action(activity)}
      context={context}
      subject={
        context.branchId ? null : (
          <span>
            to the branch <Link to={url}>{activity.payload.branchName}</Link>
          </span>
        )
      }
      when={activity.createdAt}
    />
  );
}
