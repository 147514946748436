// @flow
import * as React from "react";
import Button from "core/components/Button";
import Empty from "core/components/Empty";
import NoResults from "core/components/Empty/NoResults";
import { V3Link as Link } from "core/lib/router";
import { organizationProjectsPath } from "core/lib/routes";
import type { OrganizationTeamDescriptor } from "core/types";
type Props = {
  params: OrganizationTeamDescriptor,
  searchTerm: ?string,
};

export default function NoProjects(props: Props) {
  return props.searchTerm ? (
    <NoResults flex type="projects" term={props.searchTerm} />
  ) : (
    <Empty
      icon="project"
      title="Invite your team to a project."
      description="Coordinate with project admins to add this team to their projects."
    >
      <Button
        nude
        tint
        component={Link}
        to={organizationProjectsPath(props.params.organizationId)}
        qaSelector="goToAllProjects"
      >
        Go to all projects
      </Button>
    </Empty>
  );
}
