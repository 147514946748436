// @flow
import * as React from "react";
import { trialDaysRemaining, inTrial } from "core/lib/subscriptions";
import type { Organization, Policy, Subscription } from "core/types";
import SubscriptionBannerActive from "./SubscriptionBannerActive";
import SubscriptionBannerExpired from "./SubscriptionBannerExpired";
import SubscriptionBannerExpiring from "./SubscriptionBannerExpiring";
import SubscriptionBannerFailed from "./SubscriptionBannerFailed";

type Props = {
  organization: ?Organization,
  organizationPolicy: ?Policy,
  subscription: ?Subscription,
};

export default function SubscriptionBanner({
  organization,
  organizationPolicy,
  subscription,
}: Props) {
  // check if organization is fully loaded; if not, don't load anything here.
  if (!organization || organization.isWithinSubscriptionTerm === undefined) {
    return null;
  }

  // hide banner for organizations with a 2.0 contract
  if (organization.contractType === "2.0") {
    return null;
  }

  // never show a banner for free personal accounts
  if (organization.isUsernameOrganization && !organization.hasBillingInfo) {
    return null;
  }

  const isExpired = !organization.isWithinSubscriptionTerm;

  const isInTrial = inTrial(organization.trialEndsAt);

  // if we're not in a trial and the subscription is still valid then
  // there's nothing to display. we're done here.
  if (!isExpired) {
    return null;
  }

  const canManageBilling = !!(
    organizationPolicy &&
    (organizationPolicy.upgradeSubscription ||
      organizationPolicy.changeSubscription)
  );

  const daysRemaining =
    isExpired || !organization.trialEndsAt
      ? 0
      : trialDaysRemaining(organization.trialEndsAt);

  if (daysRemaining > 7) {
    return null;
  }

  if (organization.hasBillingInfo && isExpired && !isInTrial) {
    return (
      <SubscriptionBannerFailed
        canManageBilling={canManageBilling}
        organizationId={organization.id}
      />
    );
  }

  if (!daysRemaining && !isInTrial) {
    return <SubscriptionBannerExpired organizationId={organization.id} />;
  }

  // user still in trial, but already added billing info
  if (organization.hasBillingInfo) {
    return null;
  }

  if (daysRemaining < 2 && canManageBilling) {
    return (
      <SubscriptionBannerExpiring
        organizationId={organization.id}
        daysRemaining={daysRemaining}
      />
    );
  }

  if (daysRemaining < 11 && canManageBilling) {
    return <SubscriptionBannerActive organizationId={organization.id} />;
  }

  return null;
}
