// @flow
import classnames from "classnames";
import camelCase from "lodash/camelCase";
import upperFirst from "lodash/upperFirst";
import * as React from "react";
import style from "./style.scss";

type JustifyValues =
  | "center"
  | "space-around"
  | "space-between"
  | "flex-start"
  | "flex-end";

type AlignValues =
  | "stretch"
  | "center"
  | "baseline"
  | "flex-start"
  | "flex-end";

type Props = {
  children: React.Node,
  className?: string,
  column?: ?boolean,
  wrap?: ?boolean,
  reverse?: ?boolean,
  align?: AlignValues,
  justify?: JustifyValues,
  shrink?: ?boolean,
  grow?: ?boolean,
  auto?: ?boolean,
  inline?: ?boolean,
  innerRef?: React.Ref<"div">,
  qaSelector?: string,
};

export default function Flex({
  justify,
  align,
  column,
  reverse,
  wrap,
  shrink,
  grow,
  inline,
  className,
  children,
  innerRef,
  qaSelector,
  ...rest
}: Props) {
  let classes = {};

  if (justify) {
    classes[style[camelCase(`justify${upperFirst(justify)}`)]] = true;
  }
  if (align) {
    classes[style[camelCase(`align${upperFirst(align)}`)]] = true;
  }

  return (
    <div
      ref={innerRef}
      className={classnames(
        style.flex,
        {
          [style.column]: column,
          [style.reverse]: reverse,
          [style.wrap]: wrap,
          [style.inline]: inline,
          [style.noShrink]: shrink === false,
          [style.noGrow]: grow === false,
        },
        classes,
        className
      )}
      data-qa={qaSelector}
      {...rest}
    >
      {children}
    </div>
  );
}
