// @flow
import classnames from "classnames";
import { filter, map } from "lodash";
import * as React from "react";
import ReactDOM from "react-dom";
import { Waypoint } from "react-waypoint";
import AnimatedFlyover from "core/components/AnimatedFlyover";
import Button from "core/components/Button";
import CollectionPreview from "core/components/CollectionPreview";
import NoCollections from "core/components/Empty/NoCollections";
import NoResults from "core/components/Empty/NoResults";
import InputSearch from "core/components/InputSearch";
import Scrollable from "core/components/Scrollable";
import window from "core/global/window";
import matchString from "core/lib/matchString";
import { DEFAULT_COLLECTION_VALUES } from "core/models/collection";
import type { Collection, CollectionFormValues } from "core/types";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  projectId: string,
  branchId: string,
  layerCount: number,
  hasMore?: boolean,
  onSubmit: (collection: Collection) => *,
|};

export type StateProps = {|
  collections: Collection[],
  isLoading: boolean,
  isOnline: boolean,
|};

export type DispatchProps = {|
  onCreateCollection: (
    values: CollectionFormValues,
    onSuccess: (collection: Collection) => void
  ) => void,
|};

export type Props = {|
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
|};

type State = {
  searchFilter: string,
  openFlyover: boolean,
  openForm: boolean,
  containerOverflow: boolean,
  isSubmittingNewCollection: boolean,
};

class AddToCollectionButton extends React.Component<Props, State> {
  state = {
    searchFilter: "",
    openFlyover: false,
    openForm: false,
    containerOverflow: false,
    isSubmittingNewCollection: false,
  };

  containerRef: ?HTMLElement;

  toggle = (ev: SyntheticMouseEvent<*>) => {
    ev.preventDefault();
    ev.stopPropagation();

    this.setState(
      (prevState) => ({
        openFlyover: !prevState.openFlyover,
      }),
      () => {
        if (this.state.openFlyover) {
          this.checkContainerOverflow();
        }
      }
    );
  };

  openForm = (ev: SyntheticMouseEvent<*>) => {
    ev.preventDefault();
    ev.stopPropagation();

    this.setState({
      openFlyover: false,
      openForm: true,
    });
  };

  close = () => this.setState({ openFlyover: false, openForm: false });

  handleSearch = (ev: SyntheticInputEvent<*>) => {
    this.setState({ searchFilter: ev.target.value });
  };

  get filteredCollections(): Collection[] {
    const { collections } = this.props;
    const { searchFilter } = this.state;

    return searchFilter
      ? filter(collections, (collection) =>
          matchString(collection.name, searchFilter)
        )
      : collections;
  }

  renderCollectionsSection = () => {
    const filtered = this.filteredCollections;

    if (!filtered.length) {
      return (
        <NoResults
          mini
          flex
          type="collections"
          term={this.state.searchFilter}
          className={style.emptyContainer}
        />
      );
    }

    return (
      <Scrollable innerRef={this.setContainerRef}>
        {map(filtered, (collection) => (
          <button
            key={collection.id}
            className={style.collectionPreview}
            onClick={() => this.props.onSubmit(collection)}
          >
            <CollectionPreview key={collection.id} collection={collection} />
          </button>
        ))}
      </Scrollable>
    );
  };

  handleNewCollection = () => {
    if (this.state.isSubmittingNewCollection) {
      return;
    }
    this.setState({ isSubmittingNewCollection: true }, () =>
      this.props.onCreateCollection(
        DEFAULT_COLLECTION_VALUES,
        this.handleNewCollectionSuccess
      )
    );
  };

  handleNewCollectionSuccess = (collection: Collection) => {
    this.setState({ isSubmittingNewCollection: false });
    this.props.onSubmit(collection);
  };

  setContainerRef = (ref: ?HTMLElement) => {
    this.containerRef = ref;
  };

  checkContainerOverflow = () => {
    const element = ReactDOM.findDOMNode(this.containerRef);
    const containerOverflow =
      element instanceof HTMLElement &&
      element.clientHeight > window.innerHeight - 250;

    this.setState({ containerOverflow });
  };

  render() {
    const { collections, layerCount, isOnline, isLoading } = this.props;

    const { openFlyover, containerOverflow } = this.state;

    return (
      <AnimatedFlyover
        isOpen={openFlyover}
        onClose={this.close}
        body={
          <div className={style.mainContainer}>
            {collections.length > 0 && (
              <div
                className={classnames(style.searchBar, {
                  [style.hasBorder]: containerOverflow,
                })}
              >
                <InputSearch onChange={this.handleSearch} autoFocus />
              </div>
            )}
            <div className={style.collectionsContainer}>
              {collections.length ? (
                this.renderCollectionsSection()
              ) : (
                <NoCollections
                  mini
                  flex
                  title="Present with Collections"
                  className={style.emptyContainer}
                />
              )}
              <Waypoint onPositionChange={this.checkContainerOverflow} />
            </div>
            <div
              className={classnames(style.createCollectionButton, {
                [style.hasBorder]: containerOverflow,
              })}
            >
              <Button onClick={this.handleNewCollection} fullwidth>
                New Collection
              </Button>
            </div>
          </div>
        }
        onClickOutside={this.close}
        onRequestClose={this.close}
        className={style.container}
      >
        <Button
          disclosure
          disabled={!isOnline || isLoading || !layerCount}
          onClick={this.toggle}
        >
          Add To Collection
        </Button>
      </AnimatedFlyover>
    );
  }
}

export default connector(AddToCollectionButton);
