// @flow
import { connect } from "react-redux";
import type { State, Dispatch } from "core/types";
import { loadOrganizations } from "web/actions/organizations";
import { loadSession } from "web/actions/session";
import { isLoggedIn } from "web/selectors/session";
import type { OwnProps, StateProps, DispatchProps, Props } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  return {
    isLoggedIn: isLoggedIn(state),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    loadOrganizations: () => {
      dispatch(loadOrganizations());
    },
    loadSession: () => {
      dispatch(loadSession());
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
