// @flow
import classnames from "classnames";
import * as React from "react";
import FileIcon from "core/components/FileIcon";
import * as File from "core/models/file";
import style from "./style.scss";

type Props = {
  id: string,
  name: string,
  type: string,
  isLibrary: boolean,
  selected?: boolean,
  disabled?: boolean,
  className?: string,
  meta: React.Node,
  onChange?: (
    ev: SyntheticInputEvent<HTMLInputElement>,
    fileId: string
  ) => void,
};

export default class FilesListItem extends React.Component<Props> {
  handleChange = (ev: SyntheticInputEvent<HTMLInputElement>) => {
    if (!this.props.onChange) {
      return;
    }
    this.props.onChange(ev, this.props.id);
  };

  render() {
    const {
      id,
      type,
      name,
      isLibrary,
      meta,
      selected,
      onChange,
      disabled,
      className,
      ...rest
    } = this.props;

    return (
      <li {...rest} className={classnames(style.file, className)}>
        <label htmlFor={id}>
          <FileIcon
            type={type}
            isLibrary={isLibrary}
            className={style.icon}
            large
          />
          <div className={style.content}>
            <p className={style.fileName}>
              {name}
              <span className={style.fileExtension}>
                {File.extension(type)}
              </span>
            </p>
            <p className={style.meta}>{meta}</p>
          </div>
          {onChange && (
            <div className={style.checkbox}>
              <input
                id={id}
                type="checkbox"
                value={id}
                onChange={this.handleChange}
                checked={selected}
                disabled={disabled}
              />
            </div>
          )}
        </label>
      </li>
    );
  }
}
