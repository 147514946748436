// @flow
import classnames from "classnames";
import * as React from "react";
import Button from "core/components/Button";
import Popover from "core/components/Popover";
import KeyCode from "../../lib/keycode";
import style from "./style.scss";

type Props = {
  starred: boolean,
  className?: string,
  onClick?: (SyntheticEvent<>) => void,
  onKeyDown?: (SyntheticKeyboardEvent<>) => void,
};

export default function Star({ starred, className, onClick }: Props) {
  return (
    <Popover
      placement="bottom"
      label={`${starred ? "Remove from" : "Add to"} Favorites`}
      key={starred ? "starred" : "unstarred"}
    >
      <Button
        className={classnames(className, style.star, {
          [style.starred]: starred,
        })}
        icon={starred ? "starred" : "star"}
        onClick={onClick}
        onKeyDown={(event) => {
          if (event.keyCode === KeyCode.KEY_RETURN && onClick) {
            onClick(event);
          }
        }}
        tabIndex={0}
        nude
      />
    </Popover>
  );
}
