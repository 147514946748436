// @flow
import { connect } from "react-redux";
import { showToast } from "core/actions/toasts";
import * as Request from "core/models/request";
import { CreateOrganizationAccessRequest } from "core/requests/organizationAccessRequests";
import type { State, Dispatch } from "core/types";
import type { OwnProps, StateProps, DispatchProps, Props } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const request = CreateOrganizationAccessRequest.getRequest(state, {
    organizationId: props.organization.id,
  });

  return {
    loading: Request.isLoadingStrict(request),
    success: Request.success(request),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    requestAccessToOrganization: (onSuccess, onError) => {
      dispatch(
        CreateOrganizationAccessRequest.perform({
          params: {
            organizationId: props.organization.id,
          },
          onError: () => {
            dispatch(
              showToast({
                text: "An error occurred while sending your request",
              })
            );
          },
        })
      );
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
