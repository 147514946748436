// @flow
import { connect } from "react-redux";
import { getCurrentUserId } from "abstract-di/selectors";
import { updateBranch } from "core/actions/branches";
import * as Branch from "core/models/branch";
import { getParentBranch } from "core/selectors/branches";
import { getUser, getUsersForProject } from "core/selectors/users";
import type { State, Dispatch } from "core/types";
import type { OwnProps, Props, StateProps, DispatchProps } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { branch, projectId } = props;
  const currentUserId = getCurrentUserId(state);
  const isOwner = currentUserId === branch.userId;
  const parentBranch = getParentBranch(state, {
    projectId,
    branchId: branch.id,
  });

  return {
    user: getUser(state, { userId: branch.userId }),
    parent: parentBranch,
    canEdit: isOwner && Branch.isActive(branch),
    users: getUsersForProject(state, { projectId }),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    updateBranchDescription: (
      description: string,
      options: { onError: () => void }
    ) => {
      const { projectId, branch } = props;
      const branchId = branch.id;

      return dispatch(
        updateBranch(
          projectId,
          branchId,
          {
            description,
          },
          { onError: options.onError }
        )
      );
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
