// @flow
import * as React from "react";
import { Helmet } from "react-helmet";

type Props = {|
  children: React.Node,
|};

// Helmet's default is to use requestAnimationFrame() to render updates to the
// <head>. Because we'll want to update that element in the background, we'll
// need to default to using defer={false}.
export default function Head({ children }: Props) {
  return <Helmet defer={false}>{children}</Helmet>;
}
