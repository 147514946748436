// @flow
import createCachedSelector from "@elasticprojects/re-reselect";
import { filter, keyBy } from "lodash";
import * as ProjectMembership from "core/models/projectMembership";
import { getEntity, getRawEntities } from "core/selectors/entities";
import { getProjectId, maybeGetUserId } from "core/selectors/helpers";
import type {
  ProjectMembership as TProjectMembership,
  ProjectRoleFilter,
  SubscriptionRole,
  State,
} from "core/types";

export function getProjectMembership(
  state: State,
  params: { projectId: string, userId: string }
): ?TProjectMembership {
  return getEntity(
    state,
    "projectMemberships",
    ProjectMembership.uniqueId(params)
  );
}

export function getProjectMembershipEntities(state: State): {
  [string]: TProjectMembership,
} {
  return getRawEntities(state, "projectMemberships");
}

function cacheProjectMemberships(
  state: State,
  {
    fromOrganization = undefined,
    projectId,
    role = "",
    userId = "",
    subscriptionRole = "",
  }
) {
  const fromOrganizationStr = `fromOrganization:${
    fromOrganization !== undefined ? fromOrganization.toString() : "all"
  }`;
  return `${projectId}-${userId}-${role}-${subscriptionRole}-${fromOrganizationStr}-memberships`;
}

export type ProjectMembershipParams = {
  projectId: string,
  userId?: string,
  role?: ProjectRoleFilter,
  fromOrganization?: boolean,
  subscriptionRole?: SubscriptionRole,
};

export function maybeGetRole(
  state: State,
  params: { role?: ProjectRoleFilter }
) {
  return params.role;
}

export function maybeGetFromOrganization(
  state: State,
  params: { fromOrganization?: boolean }
) {
  return params.fromOrganization;
}

export function maybeGetSubscriptionRole(
  state: State,
  params: { subscriptionRole?: SubscriptionRole }
) {
  return params.subscriptionRole;
}

export const getProjectMemberships: (
  state: State,
  props: ProjectMembershipParams
) => TProjectMembership[] = createCachedSelector(
  getProjectMembershipEntities,
  getProjectId,
  maybeGetUserId,
  maybeGetRole,
  maybeGetSubscriptionRole,
  maybeGetFromOrganization,
  (
    memberships,
    projectId,
    userId,
    role,
    subscriptionRole,
    fromOrganization
  ) => {
    return filter(memberships, (m) => {
      if (
        role &&
        m.role !== role &&
        (role !== "guest" || !m.isOrganizationGuest)
      ) {
        return false;
      }

      if (subscriptionRole && m.subscriptionRole !== subscriptionRole) {
        return false;
      }

      if (projectId && m.projectId !== projectId) {
        return false;
      }

      if (userId && m.userId !== userId) {
        return false;
      }

      if (
        fromOrganization !== undefined &&
        m.fromOrganization !== fromOrganization
      ) {
        return false;
      }

      return true;
    });
  }
)(cacheProjectMemberships);

export const getProjectMembershipsForProject: (
  State,
  { projectId: string }
) => TProjectMembership[] = createCachedSelector(
  getProjectMembershipEntities,
  getProjectId,
  (memberships, projectId) => filter(memberships, { projectId })
)(getProjectId);

export const getProjectMembershipsForProjectByUser: (
  State,
  { projectId: string }
) => {
  [userId: string]: ?TProjectMembership,
} = createCachedSelector(getProjectMembershipsForProject, (memberships) =>
  keyBy(memberships, "userId")
)(getProjectId);
