// @flow
import * as Abstract from "abstract-sdk";
import { entitiesReceived } from "core/actions/entities";
import abstract from "core/lib/abstract";
import { normalizeLayerDataset } from "core/schemas/layerDataset";
import { getResolvedLayerDescriptor } from "core/selectors/descriptors";
import { getLayerDataset } from "core/selectors/layerDatasets";
import { getTransportModeForBranch } from "core/selectors/sdk";
import defineRequest from "./";

export const LayerDatasetFetchRequest = defineRequest<
  Abstract.LayerVersionDescriptor,
  Abstract.LayerVersionDescriptor,
>({
  id: (params) => {
    return `get:projects/${params.projectId}/branches/${params.branchId}/commits/${params.sha}/files/${params.fileId}/layers/${params.layerId}/data`;
  },
  perform: async (
    descriptor: Abstract.LayerVersionDescriptor,
    dispatch,
    getState
  ) => {
    const state = getState();
    const data = getLayerDataset(state, descriptor);
    if (data) {
      return;
    }

    return abstract.data.info(getResolvedLayerDescriptor(state, descriptor), {
      transportMode: getTransportModeForBranch(state, {
        projectId: descriptor.projectId,
        branchId: descriptor.branchId,
      }),
    });
  },
  onSuccess: (response, params, dispatch) => {
    if (!response) {
      return;
    }

    const normalizedData = normalizeLayerDataset(response).entities;
    dispatch(entitiesReceived(normalizedData));
  },
  force: false,
});
