// @flow
import * as Abstract from "abstract-sdk";
import apiRequest from "abstract-di/api";
import { entitiesReceived, entitiesDeleted } from "core/actions/entities";
import { loadedProjectsResponse } from "core/actions/projects";
import { normalizeSectionsResponse } from "core/schemas/section";
import defineRequest from "./";

export const SectionsFetchRequest = defineRequest<
  Abstract.OrganizationDescriptor,
  Abstract.OrganizationDescriptor,
>({
  id: (params) => {
    return `get:sections/${params.organizationId}`;
  },
  perform: (params) => {
    return apiRequest("get", "sections", params);
  },
  onSuccess: (response, params, dispatch) => {
    dispatch(entitiesReceived(normalizeSectionsResponse(response).entities));
  },
});

type CreateParams = {
  name?: string,
  organizationId: string,
  projectId?: string,
};

export const SectionCreateRequest = defineRequest<CreateParams, CreateParams>({
  id: (params) => {
    return `post:sections/${params.organizationId}`;
  },
  perform: (params) => {
    return apiRequest("post", "sections", params);
  },
  onSuccess: (response, params, dispatch) => {
    dispatch(loadedProjectsResponse(response));
  },
});

type UpdateParams = {
  sectionId: string,
  name?: string,
};

export const SectionUpdateRequest = defineRequest<UpdateParams, UpdateParams>({
  id: (params) => {
    return `put:sections/${params.sectionId}`;
  },
  perform: (params) => {
    return apiRequest("put", `sections/${params.sectionId}`, params);
  },
  onSuccess: (section, params, dispatch) => {
    dispatch(entitiesReceived({ sections: { [section.id]: section } }));
  },
});

type DeleteParams = {
  sectionId: string,
};

export const SectionDeleteRequest = defineRequest<DeleteParams, DeleteParams>({
  id: (params) => {
    return `delete:sections/${params.sectionId}`;
  },
  perform: (params) => {
    return apiRequest("delete", `sections/${params.sectionId}`);
  },
  onSuccess: (response, params, dispatch) => {
    dispatch(entitiesDeleted({ sections: [params.sectionId] }));
  },
});
