// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { dismissDialog } from "core/actions/dialogs";
import DialogForm from "core/components/DialogForm";
import type { Dispatch } from "core/types";

type OwnProps = {||};

type DispatchProps = {|
  dismissDialog: () => void,
|};

type Props = {
  ...OwnProps,
  ...DispatchProps,
};

class RequestContributorRole extends Component<Props> {
  render() {
    return (
      <DialogForm
        title="Request Contributor"
        ref="form"
        onClose={this.props.dismissDialog}
        primaryButton=""
        isOpen
      >
        <div>
          <p>
            To access this functionality you need to be upgraded from a viewer
            to a <strong>contributor</strong>.
          </p>
          <p>
            Please contact an organization admin to have them upgrade your
            account.
          </p>
        </div>
      </DialogForm>
    );
  }
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    dismissDialog: () => dispatch(dismissDialog()),
  };
}

export default connect<Props, OwnProps, {}, DispatchProps, _, Dispatch>(
  undefined,
  mapDispatchToProps
)(RequestContributorRole);
