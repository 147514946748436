// @flow
import * as React from "react";
import Button from "core/components/Button";
import { default as CollectionInfo } from "core/components/CollectionInfo";
import CopyLinkButton from "core/components/CopyLinkButton";
import Flex from "core/components/Flex";
import Icon from "core/components/Icon";
import SharingSettings from "core/components/SharingSettings";
import * as ShareLink from "core/models/shareLink";
import type {
  ShareLink as TShareLink,
  InputShare,
  LayerShellMode,
  Collection,
} from "core/types";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  inputShare: InputShare,
  organizationId: string,
  collectionId?: string,
  collectionLayerId?: ?string,
  mode?: ?LayerShellMode,
  shareLink: ?TShareLink,
  selected?: string,
  layerType: ?string,
  onCopySuccess: () => void,
  canShowHandoff?: boolean,
  isMobile?: boolean,
  largeButtons?: boolean,
  infoText: ?string,
  itemLabel: string,
|};

export type StateProps = {|
  collection: ?Collection,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
};

type State = {
  showSharingSettings: boolean,
};

class ShareInfo extends React.Component<Props, State> {
  state = {
    showSharingSettings: false,
  };

  get url(): string {
    return ShareLink.assembleShareLinkUrl(this.props.shareLink, {
      sha: this.props.inputShare.descriptor.sha,
      collectionLayerId: this.props.collectionLayerId,
      mode: this.props.mode,
      selected: this.props.selected,
    });
  }

  onSharingSettingsClose = () => {
    this.setState({ showSharingSettings: false });
  };

  render() {
    const url = this.url;

    const inspectEnabled = ShareLink.inspectEnabled(this.props.shareLink);
    const historyEnabled = ShareLink.historyEnabled(this.props.shareLink);

    return (
      <React.Fragment>
        <div className={style.content}>
          <Flex column align="flex-start">
            {this.props.collection && (
              <CollectionInfo collection={this.props.collection} />
            )}
            <div className={style.info}>
              {ShareLink.isPublic(this.props.shareLink) &&
              ShareLink.publicOptionsVisible(this.props.inputShare) ? (
                <React.Fragment>
                  {this.props.infoText}
                  <div className={style.layerInfo}>
                    <p className={style.noMargin}>
                      {historyEnabled || inspectEnabled
                        ? "Anyone outside of this project with the link can view:"
                        : "Anyone outside of this project with the link can only comment."}
                    </p>
                    <div className={style.option}>
                      <Icon
                        className={style.icon}
                        small
                        type={historyEnabled ? "checkmark" : "prohibit"}
                      />
                      <span>All versions</span>
                    </div>
                    <div className={style.option}>
                      <Icon
                        className={style.icon}
                        small
                        type={inspectEnabled ? "checkmark" : "prohibit"}
                      />
                      <span>Inspect</span>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <div className={style.privateInfo}>{this.props.infoText}</div>
              )}
            </div>
          </Flex>
        </div>
        <div className={style.actions}>
          <Button
            fullwidth
            className={style.settingsButton}
            large={this.props.largeButtons}
            onClick={() => this.setState({ showSharingSettings: true })}
          >
            Change Sharing Settings
          </Button>
          <CopyLinkButton
            autoFocus
            primary
            fullwidth
            tooltip={false}
            nude={false}
            tint={false}
            icon={null}
            url={url}
            large={this.props.largeButtons}
            organizationId={this.props.organizationId}
            inputShare={this.props.inputShare}
            doNotCreateShareLink
            onCopySuccess={this.props.onCopySuccess}
          >
            {this.props.collectionId && this.props.collectionLayerId
              ? "Copy Link"
              : `Copy Link to ${this.props.itemLabel}`}
          </CopyLinkButton>
        </div>
        <SharingSettings
          itemLabel={this.props.itemLabel}
          organizationId={this.props.organizationId}
          inputShare={this.props.inputShare}
          collection={this.props.collection}
          canShowHandoff={this.props.canShowHandoff}
          isMobile={this.props.isMobile}
          isOpen={this.state.showSharingSettings}
          onClose={this.onSharingSettingsClose}
        />
      </React.Fragment>
    );
  }
}

export default connector(ShareInfo);
