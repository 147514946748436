// @flow
import createCachedSelector from "@elasticprojects/re-reselect";
import empty from "empty";
import { orderBy, reduce, filter, values, pick, compact, map } from "lodash";
import { Abstract } from "core/lib/abstract";
import { unknownUser } from "core/models/user";
import {
  CommentsFetchRequest,
  CreateCommentRequest,
} from "core/requests/comments";
import { getResolvedLayerDescriptor } from "core/selectors/descriptors";
import { getUserEntities } from "core/selectors/users";
import type {
  Annotation,
  Comment,
  PublicComment,
  State,
  User,
  PublicUser,
  CollectionLayer,
  Layer,
} from "core/types";
import { getEntity, getRawEntities } from "./entities";
import {
  getProjectId,
  getBranchId,
  maybeGetBranchId,
  maybeGetFileId,
  maybeGetPageId,
  maybeGetLayerId,
} from "./helpers";

export function getComment(
  state: State,
  props: { id: string }
): ?Comment | ?PublicComment {
  return getEntity(state, "comments", props.id);
}

export function getExcludeLayerComments(
  state: State,
  props: { excludeLayerComments?: boolean }
) {
  return props.excludeLayerComments;
}

const getCommentEntities = (state) => getRawEntities(state, "comments");

export function getCommentsRequest(state: State, params: Object) {
  return CommentsFetchRequest.getRequest(state, params);
}

export function getCreateCommentRequest(state: State, params: { id: string }) {
  return CreateCommentRequest.getRequest(state, params);
}

const userForCommentCache = (state, props: { id: string }) => {
  return `${props.id}-user`;
};

export const getUserForComment: (
  state: State,
  props: { id: string }
) => User | PublicUser = createCachedSelector(
  getUserEntities,
  getComment,
  (users, comment) => {
    if (!comment) {
      return unknownUser;
    }

    const fallback = comment.user || unknownUser;
    const user = users ? users[comment.userId] || fallback : fallback;

    if (comment.deletedAt) {
      return {
        id: user.id,
        username: user.username,
        avatarUrl: "",
        name: "Deleted comment",
      };
    }

    return user;
  }
)(userForCommentCache);

const repliesByParentCache = (state, props: { id: string }) => {
  return `${props.id}-replies`;
};

export const getRepliesForComment: (State, { id: string }) => Comment[] =
  createCachedSelector(getCommentEntities, getComment, (comments, parent) => {
    if (!parent || !parent.replyIds.length) {
      return empty.array;
    }

    return orderBy(values(pick(comments, parent.replyIds)), "createdAt", [
      "asc",
    ]);
  })(repliesByParentCache);

const parentReplyCache = (state, props: { id: string }) => {
  return `${props.id}-parent-replies`;
};

export const getParentCommentAndReplies: (State, { id: string }) => Comment[] =
  createCachedSelector(getCommentEntities, getComment, (comments, comment) => {
    if (!comment) {
      return empty.array;
    }

    const getComments = (ids: string[]) =>
      orderBy(values(pick(comments, ids)), "createdAt", ["asc"]);

    let returnValues = [];

    if (comment.parentId) {
      const parentComment = comments[comment.parentId];

      if (parentComment) {
        returnValues = [parentComment, ...getComments(parentComment.replyIds)];
      }
    } else {
      returnValues = [comment, ...getComments(comment.replyIds)];
    }

    return returnValues;
  })(parentReplyCache);

const getSort = (state, props) => (props.sort === "desc" ? "desc" : "asc");

type FilterProps = {
  projectId: string,
  branchId?: string,
  commitSha?: string,
  fileId?: string,
  pageId?: string,
  layerId?: string,
  sort?: string,
  createdAt?: string,
};

function getFilters({
  projectId,
  branchId,
  fileId,
  layerId,
  ...rest
}: FilterProps | Abstract.LayerVersionDescriptor) {
  const filters = {};

  if (projectId) {
    filters.projectId = projectId;
  }
  if (branchId) {
    filters.branchId = branchId;
  }
  if (rest.sha) {
    filters.sha = rest.sha;
  }
  if (rest.commitSha) {
    filters.commitSha = rest.commitSha;
  }
  if (fileId) {
    filters.fileId = fileId;
  }
  if (rest.pageId) {
    filters.pageId = rest.pageId;
  }
  if (layerId) {
    filters.layerId = layerId;
  }

  return filters;
}

function maybeGetCommitSha(state: State, props: FilterProps) {
  return props.commitSha;
}

const filteredCommentsCache = (state: State, props: FilterProps) => {
  const filters = getFilters(props);
  return `${map(filters).join("-")}-comments`;
};

export const getFilteredComments: (State, FilterProps) => Comment[] =
  createCachedSelector(
    getCommentEntities,
    getProjectId,
    maybeGetBranchId,
    maybeGetCommitSha,
    maybeGetFileId,
    maybeGetPageId,
    maybeGetLayerId,
    getSort,
    (
      comments,
      projectId,
      branchId,
      commitSha,
      fileId,
      pageId,
      layerId,
      sort
    ) => {
      const filters = getFilters({
        projectId,
        branchId,
        commitSha,
        fileId,
        pageId,
        layerId,
      });
      const filtered = orderBy(filter(comments, filters), "createdAt", [sort]);

      let annotationCount = 1;

      return reduce(
        filtered,
        (memo, comment) => {
          if (comment.parentId) {
            return memo;
          }

          if (filters.layerId && comment.annotation && !comment.deletedAt) {
            memo.push({
              ...comment,
              annotation: {
                id: comment.id,
                number: annotationCount++,
                ...comment.annotation,
              },
            });
          } else {
            memo.push(comment);
          }

          return memo.concat(filter(filtered, { parentId: comment.id }));
        },
        []
      );
    }
  )(filteredCommentsCache);

export const getCommentsByCommit: (
  State,
  FilterProps
) => { [string]: Comment[] } = createCachedSelector(
  getCommentEntities,
  getProjectId,
  maybeGetBranchId,
  maybeGetCommitSha,
  maybeGetFileId,
  maybeGetPageId,
  maybeGetLayerId,
  getSort,
  (comments, projectId, branchId, commitSha, fileId, pageId, layerId, sort) => {
    const filters = getFilters({
      projectId,
      branchId,
      commitSha,
      fileId,
      pageId,
      layerId,
    });
    const filtered: Comment[] = orderBy(
      filter(comments, filters),
      "createdAt",
      [sort]
    );
    const annotationCache = {};

    return reduce(
      filtered,
      (memo, comment) => {
        const sha = comment.commitSha;
        if (!memo[sha]) {
          memo[sha] = [];
        }
        if (comment.parentId) {
          return memo;
        }

        if (comment.annotation && !comment.deletedAt) {
          if (!annotationCache[sha]) {
            annotationCache[sha] = 1;
          }

          memo[sha].push({
            ...comment,
            annotation: {
              id: comment.id,
              number: annotationCache[sha]++,
              ...comment.annotation,
            },
          });
        } else {
          memo[sha].push(comment);
        }

        memo[sha] = memo[sha].concat(
          filter(filtered, { parentId: comment.id, commitSha: sha })
        );

        return memo;
      },
      {}
    );
  }
)(filteredCommentsCache);

export const getShouldHideCommentGroup: (
  state: State,
  props: FilterProps
) => { [string]: boolean } = createCachedSelector(
  getCommentsByCommit,
  (commitComments) => {
    const group = Object.keys(commitComments);

    const obj = {};

    group.forEach((key) => {
      const comments = commitComments[key];
      const parentComments = comments.filter((comment) => !comment.parentId);
      const resolvedComments = parentComments.filter(
        (comment) => comment.resolvedAt
      );

      obj[key] = parentComments.length === resolvedComments.length;
    });

    return obj;
  }
)(filteredCommentsCache);

const annotationsForLayerCache = (
  state: State,
  props: FilterProps | Abstract.LayerVersionDescriptor
) => {
  const filters = getFilters(props);
  return `${map(filters).join("-")}-annotations`;
};

export const getFilteredAnnotations: (
  state: State,
  props: FilterProps
) => Annotation[] = createCachedSelector(getFilteredComments, (comments) => {
  return compact(map(comments, "annotation"));
})(annotationsForLayerCache);

export function getCommentsForLayer(
  state: State,
  params: Abstract.LayerVersionDescriptor
): Comment[] {
  const latestLayerDescriptor = getResolvedLayerDescriptor(state, params);

  return getFilteredComments(state, {
    ...params,
    commitSha: latestLayerDescriptor.sha,
  });
}

export function getAllCommentsForLayer(
  state: State,
  layerParams: Abstract.LayerDescriptor
): Comment[] {
  return getFilteredComments(state, {
    ...layerParams,
  });
}

export const getPinnedCommentsForLayer: (
  state: State,
  layerParams: Abstract.LayerDescriptor
) => Comment[] = createCachedSelector(getAllCommentsForLayer, (comments) => {
  return comments.filter(
    (comment) => !!comment.pinnedAt && !!comment.pinnedByUserId
  );
})((state, props) =>
  [props.layerId, props.branchId, props.fileId, props.projectId]
    .join("-")
    .concat("pinned-comments")
);

export const getResolvedCommentsForLayer: (
  state: State,
  layerParams: Abstract.LayerDescriptor
) => Comment[] = createCachedSelector(getAllCommentsForLayer, (comments) => {
  return comments.filter((comment) => !!comment.resolvedAt);
})((state, props) =>
  [props.layerId, props.branchId, props.fileId, props.projectId]
    .join("-")
    .concat("resolved-comments")
);

export const getAnnotationsForLayer: (
  state: State,
  params: Abstract.LayerVersionDescriptor
) => Annotation[] = createCachedSelector(getCommentsForLayer, (comments) =>
  compact(map(comments, "annotation"))
)(annotationsForLayerCache);

const nonResolvedCommentAnnotationsForLayerCache = (
  state: State,
  props: FilterProps | Abstract.LayerVersionDescriptor
) => {
  const filters = getFilters(props);
  return `${map(filters).join("-")}-nonresolved-annotations`;
};

export const getNonResolvedCommentAnnotationsForLayer: (
  state: State,
  params: Abstract.LayerVersionDescriptor
) => Annotation[] = createCachedSelector(getCommentsForLayer, (comments) => {
  const nonresolvedComments = comments.filter((comment) => !comment.resolvedAt);
  const annotatedComments = compact(map(nonresolvedComments, "annotation"));

  return annotatedComments;
})(nonResolvedCommentAnnotationsForLayerCache);

type CommentCountKey = "fileId" | "layerId" | "commitSha";
type CommentCountProps = {
  projectId: string,
  branchId: string,
  key: CommentCountKey,
};

const getKey = (state: State, props: { key: CommentCountKey }) => props.key;

const commentCountsCache = (state: State, props: CommentCountProps) => {
  return `${props.projectId}-${props.branchId}-${props.key}-comment-counts`;
};

export const getLayerCommentCountsKey = (
  item: Comment | CollectionLayer | Layer
) => [item.layerId || item.id, item.pageId].filter((i) => i).join("-");

export const getCommentCounts: (
  state: State,
  props: CommentCountProps
) => { [key: string]: number } = createCachedSelector(
  getCommentEntities,
  getProjectId,
  getBranchId,
  getKey,
  (comments, projectId, branchId, key) => {
    return reduce(
      comments,
      (memo, comment) => {
        if (key === "layerId") {
          const layerKeyId = getLayerCommentCountsKey(comment);
          memo[layerKeyId] = memo[layerKeyId] ? memo[layerKeyId] + 1 : 1;
        }

        if (key === "commitSha") {
          const commitKeyId = comment.commitSha;
          memo[commitKeyId] = memo[commitKeyId] ? memo[commitKeyId] + 1 : 1;
        }

        if (key === "fileId") {
          const fileKeyId = comment.fileId;
          memo[fileKeyId] = memo[fileKeyId] ? memo[fileKeyId] + 1 : 1;
        }

        if (key === "fileId" && comment.pageId) {
          const pageKeyId = `${comment.fileId}-${comment.pageId}`;
          memo[pageKeyId] = memo[pageKeyId] ? memo[pageKeyId] + 1 : 1;
        }

        return memo;
      },
      {}
    );
  }
)(commentCountsCache);
