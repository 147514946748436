// @flow
import * as React from "react";
import Button from "core/components/Button";
import ContextMenu from "core/components/ContextMenu";
import Flex from "core/components/Flex";
import type { Notification, Organization } from "core/types";
import style from "./style.scss";

type Props = {|
  currentOrganizationFilter: ?string,
  filterNotificationsByOrg: (organizationId: ?string) => void,
  markAllNotificationsAsRead: () => void,
  oldestNotificationCreatedAt: string,
  organizations: Organization[],
  organizationUnreads: { [id: string]: number },
  totalUnreads: number,
  unreadNotifications: Notification[],
|};

function getOrganizationLabel(
  name: string,
  unreadNotifications: number
): string {
  return unreadNotifications > 0 ? `${name} (${unreadNotifications})` : name;
}

export default function NotificationsHeader(props: Props) {
  const menuItems = [
    {
      click: () => props.filterNotificationsByOrg(),
      enabled: true,
      label: getOrganizationLabel("All", props.totalUnreads),
    },
    {
      type: "separator",
      visible: props.organizations.length !== 0,
    },
    ...props.organizations.map((organization) => ({
      click: () => props.filterNotificationsByOrg(organization.id),
      enabled: true,
      label: getOrganizationLabel(
        organization.name,
        props.organizationUnreads[organization.id]
      ),
    })),
  ];

  const hasUnreadNotifications = props.unreadNotifications.length !== 0;
  const markAllReadButtonLabel = hasUnreadNotifications
    ? "Mark all notifications read"
    : undefined;

  const currentOrganizationFiltered = props.organizations.find(
    (organization) => organization.id === props.currentOrganizationFilter
  );
  const notificationsFilterLabel = currentOrganizationFiltered
    ? currentOrganizationFiltered.name
    : "All Organizations";

  return (
    <ContextMenu id="notifications-orgs" menuItems={menuItems}>
      {(showMenu, ref) => (
        <Flex className={style.notificationsHeader} justify="center">
          <Button
            className={style.notificationsOrgSwitcher}
            disclosure
            innerRef={ref}
            nude
            onClick={showMenu}
            qaSelector="notificationsOrganizationFilter"
          >
            {notificationsFilterLabel}
          </Button>
          <Button
            className={style.markNotificationsAsRead}
            disabled={!hasUnreadNotifications}
            icon="mark-as-read"
            nude
            onClick={props.markAllNotificationsAsRead}
            tint={hasUnreadNotifications}
            title={markAllReadButtonLabel}
            qaSelector="markNotificationsAsReadButton"
            tooltip
          />
        </Flex>
      )}
    </ContextMenu>
  );
}
