//@flow
import idx from "idx";
import { some } from "lodash";
import type { State } from "core/types";

/* 
Helpers related to feature flags

This file will be useful if you need to leverage feature flags in
selectors, and may run into a dependency loop if you try and use
selectors/features.
*/

export function hasMasterToMainSet(state: State): boolean {
  return some(state.features, (org) =>
    idx(org, (_) => _.features["master-to-main"])
  );
}
