// @flow
import classnames from "classnames";
import * as React from "react";
import { Waypoint } from "react-waypoint";
import Button from "core/components/Button";
import { V3Link as Link } from "core/lib/router";
import style from "./style.scss";

type Props = {
  onClick: (SyntheticEvent<>) => *,
  getScrollableNode: () => ?HTMLElement,
};

type State = {
  mode: "center" | "auto",
};

export default class CommitButton extends React.Component<Props, State> {
  boundary: ?HTMLElement;
  state = { mode: "center" };

  get centerIsVisible(): boolean {
    const scrollableNode = this.props.getScrollableNode();
    const boundaryNode = this.boundaryNode;

    if (scrollableNode && boundaryNode) {
      const scrollableRect = scrollableNode.getBoundingClientRect();
      const boundaryRect = boundaryNode.getBoundingClientRect();

      if (
        boundaryNode.parentNode instanceof HTMLElement &&
        boundaryNode.parentNode.offsetHeight > scrollableRect.height
      ) {
        return false;
      }

      const top = boundaryRect.top - scrollableRect.top;
      const topBelowViewport = top > 0 && top >= scrollableRect.height;
      const topAboveViewport = top < 0;
      const bottomBelowViewport =
        top + boundaryRect.height >= 0 &&
        top + boundaryRect.height > scrollableRect.height;

      return !topBelowViewport && !topAboveViewport && !bottomBelowViewport;
    }

    return false;
  }

  get boundaryNode(): ?HTMLElement {
    const node = this.boundary;
    return node instanceof HTMLElement ? node : null;
  }

  boundaryRef = (ref: ?HTMLElement) => (this.boundary = ref);

  handlePositionChange = () => {
    const centerIsVisible = this.centerIsVisible;

    this.setState(({ mode }) => {
      if (centerIsVisible && mode === "auto") {
        return { mode: "center" };
      } else if (!centerIsVisible && mode === "center") {
        return { mode: "auto" };
      }

      return null;
    });
  };

  render() {
    return (
      <Link
        onClick={this.props.onClick}
        className={classnames(style.overlay, style[this.state.mode])}
      >
        <div className={style.buttonContainer}>
          <Button dark className={style.overlayButton}>
            Go to commit
          </Button>
        </div>
        <div className={style.boundary} ref={this.boundaryRef}>
          <Waypoint onPositionChange={this.handlePositionChange} />
          <Waypoint onPositionChange={this.handlePositionChange} />
        </div>
      </Link>
    );
  }
}
