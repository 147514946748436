// @flow
import classnames from "classnames";
import { memoize } from "lodash";
import * as React from "react";
import { Waypoint } from "react-waypoint";
import NoActivity from "abstract-di/components/NoActivity";
import ActivityList from "core/components/ActivityList";
import Breakpoint from "core/components/Breakpoint";
import Offline from "core/components/Empty/Offline";
import Flex from "core/components/Flex";
import Loaded from "core/components/Loaded";
import Loading from "core/components/Loading";
import ActivitiesLoader from "core/containers/ActivitiesLoader";
import type { Project } from "core/types";
import OrganizationActivityHeader from "./OrganizationActivityHeader";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  mobile?: boolean,
  className?: string,
  projectId?: string,
  params: {
    organizationId: string,
    projectId?: string,
  },
  location: {
    query: { projectId?: string },
  },
|};

export type StateProps = {|
  projectId?: string,
  organizationId: string,
  projects: Project[],
  project: ?Project,
|};

type Props = { ...OwnProps, ...StateProps };

const getActivityContext = memoize((projectId?: string) => ({ projectId }));

class OrganizationActivities extends React.Component<Props> {
  render() {
    const { projects, projectId, organizationId, className, mobile } =
      this.props;

    return (
      <ActivitiesLoader organizationId={organizationId} projectId={projectId}>
        {({
          activityIds,
          isLoading,
          isLoadingMore,
          isReloading,
          onLoadMore,
          isOffline,
        }) => {
          const hasActivities = activityIds.length > 0;

          if (!hasActivities && isOffline) {
            return (
              <Offline description="Connect to the internet to load activities." />
            );
          }

          return (
            <Flex
              column
              className={classnames(style.wrapper, className, {
                [style.mobile]: mobile,
              })}
            >
              <OrganizationActivityHeader
                selectedProject={this.props.project}
                projects={projects}
              />
              <Loaded
                loading={isLoading}
                title="Loading activity…"
                className={style.loadedContainer}
                flex
              >
                {() => {
                  if (!hasActivities) {
                    return <NoActivity flex />;
                  }

                  return (
                    <Flex
                      column
                      className={style.scrollable}
                      qaSelector="scrollable-container"
                    >
                      {isReloading && (
                        <div className={style.loadingContainer}>
                          <Loading
                            className={classnames(
                              style.isReloading,
                              style.centerSmallLoading
                            )}
                            title={
                              isOffline
                                ? "You are offline. Connect to the internet to load more activities"
                                : undefined
                            }
                            small
                          />
                        </div>
                      )}
                      <Breakpoint at={1024}>
                        {({ above }) => (
                          <ActivityList
                            className={classnames(style.list, {
                              [style.listLarge]: above,
                            })}
                            activityIds={activityIds}
                            organizationId={organizationId}
                            projectId={projectId}
                            activityContext={getActivityContext(
                              this.props.projectId
                            )}
                          >
                            <Waypoint onEnter={onLoadMore} />
                          </ActivityList>
                        )}
                      </Breakpoint>
                      {isLoadingMore && (
                        <div className={style.loadingContainer}>
                          <Loading
                            className={style.centerSmallLoading}
                            title={
                              isOffline
                                ? "You are offline. Connect to the internet to load more activities"
                                : undefined
                            }
                            small
                          />
                        </div>
                      )}
                    </Flex>
                  );
                }}
              </Loaded>
            </Flex>
          );
        }}
      </ActivitiesLoader>
    );
  }
}

export default connector(OrganizationActivities);
