// @flow
import queryString from "query-string";
import apiRequest from "abstract-di/api";
import { entitiesReceived } from "core/actions/entities";
import { Abstract } from "core/lib/abstract";
import defineRequest from "core/requests";
import { normalizeUserMetricsFetchResponse } from "core/schemas/userMetrics";
import type { ReactTablePagination } from "core/types";

type UserInsightParams = {
  ...Abstract.OrganizationDescriptor,
  ...ReactTablePagination,
  period?: string,
};

export const UserReportingPageFetchRequest = defineRequest<
  UserInsightParams,
  UserInsightParams,
>({
  id: (params) => {
    return `organization/${
      params.organizationId
    }/insights/users?${queryString.stringify(params)}`;
  },
  perform: (params) => {
    return apiRequest(
      "get",
      `organizations/${params.organizationId}/insights/users`,
      params
    );
  },
  onSuccess: (response, params, dispatch) => {
    const { entities } = normalizeUserMetricsFetchResponse(response);
    dispatch(entitiesReceived(entities));
  },
  force: true,
});
