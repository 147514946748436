// @flow
import * as React from "react";
import Helmet from "react-helmet";
import Toasts from "core/components/Toasts";
import style from "./style.scss";

type Props = {
  children: any,
  isLoggedIn: boolean,
  loadSession: () => void,
};

export default class OnboardingShell extends React.Component<Props> {
  componentDidMount() {
    if (!this.props.isLoggedIn) {
      this.props.loadSession();
    }
  }

  render() {
    return (
      <div className={style.shell}>
        <Helmet defaultTitle="Abstract" />
        {this.props.children}
        <Toasts />
      </div>
    );
  }
}
