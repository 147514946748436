// @flow
import { connect } from "react-redux";
import { FilesFetchRequest } from "core/requests/files";
import { getBranchHead } from "core/selectors/branches";
import { getFilesForBranch } from "core/selectors/files";
import type { State, Dispatch } from "core/types";
import type { OwnProps, StateProps, Props } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { projectId, branch } = props;
  const head = getBranchHead(state, { projectId, branchId: branch.id });

  return {
    // Desktop never loads files, so we need to use strict
    isLoading: FilesFetchRequest.isFirstLoadingStrict(state, {
      projectId,
      sha: head,
      branchId: branch.id,
    }),
    files: getFilesForBranch(state, {
      projectId,
      branchId: branch.id,
      sha: head,
    }),
  };
}

export default connect<Props, OwnProps, StateProps, _, State, Dispatch>(
  mapStateToProps
);
