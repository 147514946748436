// @flow
import * as React from "react";
import Button from "core/components/Button";
import InputSearch from "core/components/InputSearch";
import ListDisplayToggle from "core/components/ListDisplayToggle";
import PageTitle from "core/components/PageTitle";
import PageTitleNavigationLink from "core/components/PageTitleNavigationLink";
import { addQuery, removeQuery } from "core/lib/location";
import type { RoleFilter } from "core/types";

type Props = {|
  canAddMember: boolean,
  isGrid: boolean,
  roleFilter: ?RoleFilter,
  mobile?: boolean,
  onDisplayList: () => void,
  onDisplayGrid: () => void,
  onInviteDialogTrigger: () => void,
  onSearchChange: (event: SyntheticInputEvent<>) => void,
  renderInviteDialog: () => React.Node,
  searchFilter: ?string,
|};

export default function OrganizationMembersHeader(props: Props) {
  const actions = props.canAddMember
    ? [
        <Button
          icon={props.mobile ? "plus" : "user-add"}
          onClick={props.onInviteDialogTrigger}
          nude={props.mobile}
          primary={!props.mobile}
          qaSelector="invitePeopleButton"
        >
          {props.mobile ? undefined : "Invite people"}
        </Button>,
      ]
    : [];

  return (
    <React.Fragment>
      <PageTitle
        actions={actions}
        displayControl={
          <ListDisplayToggle
            isGrid={props.isGrid}
            onToggleList={props.onDisplayList}
            onToggleGrid={props.onDisplayGrid}
          />
        }
        navigation={[
          <PageTitleNavigationLink
            active={!props.roleFilter}
            icon="user"
            to={removeQuery("role")}
          >
            All
          </PageTitleNavigationLink>,
          <PageTitleNavigationLink
            active={props.roleFilter === "owner"}
            icon="admin"
            to={addQuery({ role: "owner" })}
          >
            Admins
          </PageTitleNavigationLink>,
          <PageTitleNavigationLink
            active={props.roleFilter === "contributor"}
            icon="edit"
            to={addQuery({ role: "contributor" })}
          >
            Contributors
          </PageTitleNavigationLink>,
          <PageTitleNavigationLink
            active={props.roleFilter === "guest"}
            icon="guest"
            to={addQuery({ role: "guest" })}
          >
            Guests
          </PageTitleNavigationLink>,
        ]}
        search={
          <InputSearch
            large={props.mobile}
            placeholder="Search by name…"
            defaultValue={props.searchFilter}
            onChange={props.onSearchChange}
          />
        }
        title="People"
      />
      {props.canAddMember && props.renderInviteDialog()}
    </React.Fragment>
  );
}
