// @flow
import * as React from "react";
import ExternalLink from "core/components/ExternalLink";
import Theme from "core/components/Theme";
import { homeUrl } from "core/lib/urls";
import BlackLogo from "./images/logoBlack.svg";
import WhiteLogo from "./images/logoWhite.svg";
import style from "./style.scss";

type Props = {
  href?: string,
};

export default function LogoHeader({ href = homeUrl() }: Props) {
  return (
    <Theme.Consumer>
      {({ themeName }) => (
        <ExternalLink href={href}>
          <img
            src={themeName === "light" ? BlackLogo : WhiteLogo}
            alt="Abstract"
            className={style.abstractLogo}
          />
        </ExternalLink>
      )}
    </Theme.Consumer>
  );
}
