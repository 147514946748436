// @flow
import empty from "empty";
import type { LayerData } from "core/types";

type LayerFrame = {|
  top: number,
  left: number,
  bottom: number,
  right: number,
  width: number,
  height: number,
|};

function frameForLayer(layerData: LayerData): LayerFrame {
  return {
    top: layerData.properties.y,
    left: layerData.properties.x,
    bottom: layerData.properties.y + layerData.properties.height,
    right: layerData.properties.x + layerData.properties.width,
    width: layerData.properties.width,
    height: layerData.properties.height,
  };
}

type Layout = {|
  x: number,
  y: number,
  width: number,
  height: number,
|};

// resizeLayout resizes the layout of the given child layer according to
// the position & scale of it's parent and any resizing constraints.
export default function resizeLayout(
  childLayer: LayerData,
  parentLayer: LayerData,
  masterParentLayer: LayerData
): Layout {
  const { resizingConstraint = empty.object } = childLayer.properties;

  // frame for the child, which has not yet been scaled or absolutely positioned
  const childFrame = frameForLayer(childLayer);
  // frame for the absolutely positioned parent
  const parentFrame = frameForLayer(parentLayer);
  // frame for the original parent before scaling and absolute positioning
  const masterFrame = frameForLayer(masterParentLayer);

  const rules = {
    width: childLayer.properties.width,
    height: childLayer.properties.height,
    fixedWidth: resizingConstraint.fixedWidth
      ? childLayer.properties.width
      : undefined,
    fixedHeight: resizingConstraint.fixedHeight
      ? childLayer.properties.height
      : undefined,
    // top edge of child to top edge of parent
    top: resizingConstraint.top ? childFrame.top : undefined,
    // left edge of child to left edge of parent
    left: resizingConstraint.left ? childFrame.left : undefined,
    // bottom edge of child to bottom edge of parent
    bottom: resizingConstraint.bottom
      ? masterFrame.height - childFrame.bottom
      : undefined,
    // right edge of child to right edge of parent
    right: resizingConstraint.right
      ? masterFrame.width - childFrame.right
      : undefined,
    parentScaleWidth: parentFrame.width / masterFrame.width,
    parentScaleHeight: parentFrame.height / masterFrame.height,
  };
  const xConstraint = (rules.left || 0) + (rules.right || 0);
  const yConstraint = (rules.top || 0) + (rules.bottom || 0);
  const remainingWidth = masterFrame.width - xConstraint;
  const remainingHeight = masterFrame.height - yConstraint;

  const layout: Layout = {
    x: childLayer.properties.x,
    y: childLayer.properties.y,
    width: childLayer.properties.width,
    height: childLayer.properties.height,
  };

  if (rules.fixedWidth !== undefined) {
    // fixed sizing takes priority
    layout.width = rules.fixedWidth;
    // Center fixed width layer inside of the original scaled width
    layout.x =
      layout.x * rules.parentScaleWidth +
      (layout.width * rules.parentScaleWidth - rules.fixedWidth) / 2;
  } else {
    // otherwise apply any existing x constraints
    if (childLayer.type !== "text") {
      layout.width =
        (parentFrame.width - xConstraint) * (rules.width / remainingWidth);
      layout.x = layout.x * rules.parentScaleWidth;
    }
  }

  if (rules.left !== undefined) {
    // Move left edge to left constraint
    layout.x = rules.left;
  }

  if (rules.right !== undefined) {
    // Move right edge to right constraint...
    if (rules.left !== undefined) {
      // ... by preserving left edge at left constraint
      layout.width = parentFrame.width - layout.x - rules.right;
    } else {
      // ... by moving left edge
      layout.x = parentFrame.width - layout.width - rules.right;
    }
  }

  if (rules.fixedHeight !== undefined) {
    // fixed sizing takes priority
    layout.height = rules.fixedHeight;
    // Center fixed height layer inside of the original scaled height
    layout.y =
      childLayer.properties.y * rules.parentScaleHeight +
      (layout.height * rules.parentScaleHeight - rules.fixedHeight) / 2;
  } else {
    // otherwise apply any existing y restraints
    if (childLayer.type !== "text") {
      layout.height =
        (parentFrame.height - yConstraint) * (rules.height / remainingHeight);

      layout.y = layout.y * rules.parentScaleHeight;
    }
  }

  if (rules.top !== undefined) {
    // Move top edge to top constraint
    layout.y = rules.top;
  }

  if (rules.bottom !== undefined) {
    // Move bottom edge to bottom constraint...
    if (rules.top !== undefined) {
      // ... by preserving top edge at top constraint
      layout.height = parentFrame.height - layout.y - rules.bottom;
    } else {
      // ... by moving top edge
      layout.y = parentFrame.height - layout.height - rules.bottom;
    }
  }

  return layout;
}
