// @flow
import { connect } from "react-redux";
import { trackEvent } from "core/actions/analytics";
import { showToast } from "core/actions/toasts";
import { CreateOrganizationInvitationsRequest } from "core/requests/invitations";
import { getOrganization } from "core/selectors/organizations";
import type { State, Dispatch } from "core/types";
import type { OwnProps, DispatchProps, StateProps, Props } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const organizationId = props.location.state
    ? props.location.state.organizationId
    : "";
  const organization = getOrganization(state, { organizationId });

  return {
    email: organization ? organization.billingEmail : undefined,
    organizationId: organization ? organization.id : "",
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    createOrganizationInvites: (params) => {
      const { type, ...remainingParams } = params;

      return dispatch(
        CreateOrganizationInvitationsRequest.perform({
          params: remainingParams,
          onSuccess: (response) => {
            const invitationIds = response.reduce((result, invitation) => {
              return result.concat(invitation.id);
            }, []);

            dispatch(
              trackEvent(
                type === "link"
                  ? "ORGANIZATION_INVITE_LINK_CREATED"
                  : "ORGANIZATION_INVITE_SENT",
                {
                  ...remainingParams,
                  invitationIds,
                }
              )
            );
          },
        })
      );
    },
    showToast: (text) => {
      dispatch(showToast({ text }));
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
