// @flow
import classnames from "classnames";
import * as React from "react";
import AvatarWithTeamPip from "core/components/AvatarWithTeamPip";
import Card from "core/components/Card";
import Note from "core/components/Note";
import TextHighlight from "core/components/TextHighlight";
import { fromNow } from "core/lib/dates";
import type { User } from "core/types";
import Badges from "./Badges";
import style from "./style.scss";

type Props = {
  params?: { organizationId?: string },
  user: User,
  card?: boolean,
  mobile?: boolean,
  guest?: boolean,
  admin?: boolean,
  contributor?: boolean,
  projectAdmin?: boolean,
  teamAdmin?: boolean,
  teamIds?: string[],
  hideContributorBadge?: boolean,
  isMembershipLoading?: boolean,
  highlight?: string,
  actions?: React.Node,
  className?: string,
  createdAt?: string,
  qaSelector?: string,
};

export default function UserCard({
  user,
  card,
  mobile,
  guest,
  admin,
  contributor,
  projectAdmin,
  teamAdmin,
  teamIds,
  params,
  hideContributorBadge,
  highlight,
  actions,
  className,
  createdAt,
  isMembershipLoading,
  qaSelector,
}: Props) {
  const [hovered, setHovered] = React.useState(false);

  return (
    <Card
      onMouseOver={() => setHovered(true)}
      onFocus={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onBlur={() => setHovered(false)}
      list={!card}
      mobile={mobile}
      className={classnames(
        {
          [style.list]: !card && !mobile,
          [style.card]: card && !mobile,
          [style.mobile]: mobile,
        },
        className
      )}
      dynamic
      qaSelector={qaSelector}
    >
      <div className={style.wrap}>
        <div className={style.inner}>
          <AvatarWithTeamPip
            userId={user.id}
            teamIds={teamIds}
            organizationId={params && params.organizationId}
            className={style.avatar}
            size={card ? 96 : 40}
            teamAvatarSize={card ? 32 : 20}
          />
          <div className={classnames(style.section, style.sectionTop)}>
            <span className={style.name} title={user.name}>
              <TextHighlight
                text={user.name}
                className={style.nameInner}
                highlight={highlight && highlight.trim()}
              />
              {!card && (
                <Badges
                  isLoading={isMembershipLoading}
                  admin={admin}
                  guest={guest}
                  contributor={contributor}
                  projectAdmin={projectAdmin}
                  teamAdmin={teamAdmin}
                  hideContributorBadge={hideContributorBadge}
                />
              )}
            </span>
            <Note component={card ? "div" : "span"} className={style.username}>
              <div className={style.user}>
                <TextHighlight
                  text={`@${user.username}`}
                  highlight={highlight && highlight.trim()}
                />
              </div>
              {card && (
                <div>
                  <Badges
                    isLoading={isMembershipLoading}
                    admin={admin}
                    guest={guest}
                    contributor={contributor}
                    projectAdmin={projectAdmin}
                    teamAdmin={teamAdmin}
                    hideContributorBadge={hideContributorBadge}
                    isGrid
                  />
                </div>
              )}
            </Note>
            {mobile && (
              <Note component="span">
                Joined {fromNow(createdAt || user.createdAt)}
              </Note>
            )}
          </div>
          {!mobile && (
            <div className={classnames(style.section, style.sectionRight)}>
              <Note component="span">
                Joined {fromNow(createdAt || user.createdAt)}
              </Note>
            </div>
          )}
          <span
            className={classnames(style.action, {
              [style.showActions]: hovered,
            })}
          >
            {actions}
          </span>
        </div>
      </div>
    </Card>
  );
}
