// @flow
import * as React from "react";
import Icon from "core/components/Icon";
import Note from "core/components/Note";
import SettingsItem from "core/components/SettingsItem";
import { fromNow } from "core/lib/dates";
import { oAuthApplicationPath } from "web/routeHelpers";
import type { OAuthApplication } from "web/types";
import style from "./style.scss";

type Props = {
  app: OAuthApplication,
};

const OAuthApplicationListItem = ({
  app: { id, name, organizationId, createdAt },
}: Props) => (
  <SettingsItem
    key={id}
    label={name}
    secondaryLabel={<Icon type="go-to" />}
    to={oAuthApplicationPath(organizationId, id)}
  >
    <Note className={style.createdAt}>Created {fromNow(createdAt)}</Note>
  </SettingsItem>
);

export default OAuthApplicationListItem;
