// @flow
import classnames from "classnames";
import * as React from "react";
import { useContextSelector } from "use-context-selector";
import mapLayerChildren from "core/lib/mapLayerChildren";
import * as LayerData from "core/models/layerData";
import type { LayerData as TLayerData } from "core/types";
import layerAcceptsInteraction from "../layerAcceptsInteraction";
import style from "./style.scss";
import { PropsContext } from "./";

type Rect = $ReadOnly<{
  x: number,
  y: number,
  width: number,
  height: number,
}>;

function isCurrentlyVisible(targeted, selected, showHotspots) {
  return targeted || selected || showHotspots;
}

function rectStyle(
  rect: Rect,
  props: Props
): {
  width: string,
  height: string,
  top: string,
  left: string,
} {
  const currentlyVisible = isCurrentlyVisible(
    props.targeted,
    props.selected,
    props.showHotspots
  );

  if (currentlyVisible) {
    return {
      width: `${rect.width * props.scale}px`,
      height: `${rect.height * props.scale}px`,
      top: `${rect.y * props.scale}px`,
      left: `${rect.x * props.scale}px`,
    };
  } else {
    const hiddenScale = props.hiddenScale || props.scale;

    return {
      width: `${rect.width * hiddenScale}px`,
      height: `${rect.height * hiddenScale}px`,
      top: `${rect.y * hiddenScale}px`,
      left: `${rect.x * hiddenScale}px`,
    };
  }
}

type DefaultProps = {|
  selected: boolean,
  targeted: boolean,
  noPointerEvents: boolean,
  showHotspots: boolean,
  isVisible: boolean,
  isLocked: boolean,
  scale: number,
  hiddenScale: number,
|};

export type Props = {|
  ...DefaultProps,
  layers: { [layerId: string]: TLayerData },
  layerData: TLayerData,
  onSelectLayer: (layerData: TLayerData) => void,
  onTargetLayer: (layerData: TLayerData) => void,
|};

function LayerChild(props: Props) {
  const layerDataKey = LayerData.key(props.layerData);
  const reverseChildren = React.useMemo(() => {
    const children = mapLayerChildren(
      props.layers,
      props.layerData,
      (childLayerData) => (
        <ConnectedLayerChild
          key={LayerData.key(childLayerData)}
          layers={props.layers}
          layerData={childLayerData}
          onSelectLayer={props.onSelectLayer}
          onTargetLayer={props.onTargetLayer}
          hiddenScale={props.hiddenScale}
          isVisible={
            props.isVisible === false
              ? false // force false for all children
              : childLayerData.properties.isVisible
          }
          isLocked={
            props.isLocked === true
              ? true // force true for all children
              : childLayerData.properties.isLocked
          }
        />
      )
    );

    return children.reverse();
  }, [
    props.layers,
    props.layerData,
    props.onSelectLayer,
    props.onTargetLayer,
    props.hiddenScale,
    props.isVisible,
    props.isLocked,
  ]);

  return (
    <React.Fragment>
      <div
        key={
          layerDataKey +
          // Force a remount to allow hover event to propagate to next element
          props.noPointerEvents.toString()
        }
        className={classnames(style.layerChild, {
          [style.targeted]: props.targeted,
          [style.selected]: props.selected,
          [style.noPointerEvents]: props.noPointerEvents,
          [style.showHotspots]: props.showHotspots,
        })}
        data-name={props.layerData.properties.name}
        style={rectStyle(props.layerData.properties, props)}
        onMouseOver={() => props.onTargetLayer(props.layerData)}
        onClick={() => props.onSelectLayer(props.layerData)}
      />
      {reverseChildren}
    </React.Fragment>
  );
}

LayerChild.defaultProps = {
  selected: false,
  targeted: false,
  noPointerEvents: false,
  showHotspots: false,
  isVisible: true,
  isLocked: false,
  scale: 1,
  hiddenScale: 1,
};

const MemoLayerChild = React.memo(LayerChild);

function ConnectedLayerChild(props: Props) {
  const layerDataKey = LayerData.key(props.layerData);

  const selected = useContextSelector(
    PropsContext,
    ({ selectedLayer }) =>
      selectedLayer !== undefined &&
      LayerData.key(selectedLayer) === layerDataKey
  );

  const targeted = useContextSelector(
    PropsContext,
    ({ targetedLayer }) =>
      targetedLayer !== undefined &&
      LayerData.key(targetedLayer) === layerDataKey
  );

  const noPointerEvents = useContextSelector(
    PropsContext,
    ({ selectedLayer, ignoreGroups }) =>
      !layerAcceptsInteraction(
        props.layerData,
        { isVisible: props.isVisible, isLocked: props.isLocked },
        selectedLayer,
        ignoreGroups
      )
  );

  const showHotspots = useContextSelector(
    PropsContext,
    ({ showHotspots }) =>
      props.layerData.properties.link !== undefined &&
      // TODO(fix): Sometimes `link` is null, track down where the mutation comes from
      props.layerData.properties.link !== null &&
      showHotspots
  );

  const currentlyVisible = isCurrentlyVisible(targeted, selected, showHotspots);
  const scale = useContextSelector(PropsContext, ({ scale }) =>
    currentlyVisible ? scale : props.hiddenScale
  );

  return (
    <MemoLayerChild
      {...props}
      selected={selected}
      targeted={targeted}
      scale={scale}
      showHotspots={showHotspots}
      noPointerEvents={noPointerEvents}
    />
  );
}

ConnectedLayerChild.defaultProps = LayerChild.defaultProps;

export default React.memo<React.Config<Props, DefaultProps>>(
  ConnectedLayerChild
);
