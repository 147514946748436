// @flow
/* global window document */
import { memoize } from "lodash";
import * as React from "react";
import ReactDOM from "react-dom";
import uuid from "uuid";
import eventInInput from "core/lib/eventInInput";
import KeyCode from "core/lib/keycode";
import connector from "./connector";

export type DefaultProps = {|
  disclosureClassName: string,
  mode: "list" | "grid",
  selectedClassName: string,
|};

const childProps = memoize(
  (props) => props,
  (props) =>
    [props.selectedClassName, props.disclosureClassName, props.isFocused].join(
      "-"
    )
);

export type KeyboardNavigationProps = {|
  disclosureClassName: string,
  isFocused: boolean,
  selectedClassName: string,
  scrollRef: (ref: ?Element) => void,
|};

export type OwnProps = {|
  ...DefaultProps,
  disabled?: boolean,
  defaultFocused?: boolean,
  children: (KeyboardNavigationProps) => React.Node,
|};

export type StateProps = {|
  activeElement: string,
|};

export type DispatchProps = {|
  focusedElement: (elementId: string) => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type State = {
  isFocused: boolean,
};

class KeyboardNavigation extends React.Component<Props, State> {
  scrollRef: ?Element;
  id: string;
  state = { isFocused: false };

  static defaultProps: DefaultProps = {
    disclosureClassName: "disclosure",
    mode: "list",
    selectedClassName: "selected",
  };

  constructor() {
    super();
    this.id = uuid.v4();
  }

  componentDidMount() {
    window.addEventListener("click", this.onClick);
    window.addEventListener("keydown", this.onKeyDown);

    if (this.props.defaultFocused) {
      this.props.focusedElement(this.id);
    }
    if (this.selected) {
      this.ensureOnScreen(this.selected);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.activeElement !== this.props.activeElement) {
      this.setState({
        isFocused: this.id === this.props.activeElement,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.onClick);
    window.removeEventListener("keydown", this.onKeyDown);
  }

  onClick = (event: SyntheticInputEvent<>) => {
    const element = ReactDOM.findDOMNode(this);

    if (
      element &&
      element.contains(event.target) &&
      this.props.activeElement !== this.id
    ) {
      this.props.focusedElement(this.id);
    }
  };

  onKeyDown = (event: SyntheticKeyboardEvent<>) => {
    if (eventInInput(event)) {
      return;
    }
    if (!this.state.isFocused || this.props.disabled) {
      return;
    }
    const { mode } = this.props;

    switch (event.keyCode) {
      case KeyCode.KEY_UP:
        event.preventDefault();
        this.goToPrevious();
        break;
      case KeyCode.KEY_DOWN:
        event.preventDefault();
        this.goToNext();
        break;
      case KeyCode.KEY_LEFT: {
        event.preventDefault();
        if (mode === "list") {
          this.collapse();
        } else {
          this.goToPrevious();
        }
        break;
      }
      case KeyCode.KEY_RIGHT: {
        event.preventDefault();
        if (mode === "list") {
          this.expand();
        } else {
          this.goToNext();
        }
        break;
      }
      default:
    }
  };

  get selected(): ?HTMLElement {
    const element = ReactDOM.findDOMNode(this);

    if (element instanceof HTMLElement) {
      return element.getElementsByClassName(this.props.selectedClassName)[0];
    }

    return null;
  }

  collapse() {
    const selected = this.selected;
    if (!selected) {
      return;
    }

    const disclosures = selected.getElementsByClassName(
      this.props.disclosureClassName
    );
    const expanded = !!selected.nextSibling;
    if (expanded && disclosures.length) {
      disclosures[0].click();
    }
  }

  expand() {
    const selected = this.selected;
    if (!selected) {
      return;
    }

    const disclosures = selected.getElementsByClassName(
      this.props.disclosureClassName
    );
    const collapsed = !selected.nextSibling;
    if (collapsed && disclosures.length) {
      disclosures[0].click();
    }
  }

  goToPrevious() {
    this.goTo(-1);
  }

  goToNext() {
    this.goTo(1);
  }

  goTo = (direction: 1 | -1) => {
    const selected = this.selected;

    if (selected) {
      const element = ReactDOM.findDOMNode(this);

      if (element instanceof HTMLElement) {
        const anchors = document.querySelectorAll("a");

        // find the anchor in the direction of travel
        for (let i = 0; i < anchors.length; i += 1) {
          if (anchors[i] === selected) {
            const anchor = anchors[i + direction];

            // if it exists and is inside of this component then simulate
            // a click event (there may be other handlers in JS specified)
            if (anchor && element.contains(anchor)) {
              this.ensureOnScreen(anchor);
              anchor.click();
              anchor.focus();
            }

            break;
          }
        }
      }
    }
  };

  ensureOnScreen = (node: HTMLElement) => {
    const scrollable = this.scrollRef
      ? this.scrollRef
      : ReactDOM.findDOMNode(this);

    // Wait for layout
    window.setTimeout(() => {
      if (scrollable instanceof HTMLElement) {
        const bounding = scrollable.getBoundingClientRect();

        // find if the commit is currently scrolled off the top or bottom
        const nodeBounding = node.getBoundingClientRect();
        const offBottom = nodeBounding.top - bounding.top < 0;
        const offTop =
          nodeBounding.top + nodeBounding.height - bounding.top >
          bounding.height;

        // we only want to touch the scrollTop if commit is not currently visible
        if (offBottom || offTop) {
          const scrollTop =
            nodeBounding.top -
            bounding.top -
            bounding.height +
            (nodeBounding.height + scrollable.scrollTop);
          scrollable.scrollTop = scrollTop;
        }
      }
    }, 0);
  };

  render() {
    return this.props.children(
      childProps({
        scrollRef: this.setScrollRef,
        selectedClassName: this.props.selectedClassName,
        disclosureClassName: this.props.disclosureClassName,
        isFocused: this.state.isFocused,
      })
    );
  }

  setScrollRef = (ref: ?Element) => {
    this.scrollRef = ref;
  };
}

export default connector(KeyboardNavigation);
