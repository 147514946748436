// @flow
import classnames from "classnames";
import * as React from "react";
import InputWrapper from "../InputWrapper";
import style from "./style.scss";

let counter = 0;

type Props = {
  id?: string,
  error?: string | string[],
  label?: React.Node,
  responsive?: boolean,
  requiredTag?: boolean,
  wrapperClass?: string,
  labelClass?: string,
  checkboxClass?: string,
  qaSelector?: string,
  disabled?: boolean,
};

export default class InputCheckbox extends React.Component<Props> {
  id: string;

  constructor() {
    super();
    this.id = `abstract-core-input-checkbox-${counter}`;
    counter += 1;
  }

  render() {
    const id = this.props.id || this.id;
    const { labelClass, wrapperClass, checkboxClass, qaSelector, ...rest } =
      this.props;
    return (
      <InputWrapper
        inline
        error={this.props.error}
        inputId={id}
        label={this.props.label}
        labelClass={classnames(style.checkboxLabel, labelClass)}
        responsive={this.props.responsive}
        requiredTag={this.props.requiredTag}
        className={wrapperClass}
      >
        <input
          {...rest}
          id={id}
          className={classnames(style.checkbox, checkboxClass)}
          type="checkbox"
          data-qa={qaSelector}
        />
      </InputWrapper>
    );
  }
}
