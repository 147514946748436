// @flow
import * as React from "react";
import Button from "core/components/Button";
import NoAccessTokens from "core/components/Empty/NoAccessTokens";
import FormHeader from "core/components/FormHeader";
import FormSection from "core/components/FormSection";
import Loaded from "core/components/Loaded";
import Media from "core/components/Media";
import SettingsForm from "core/components/SettingsForm";
import type { AccessToken } from "../../types";
import AccessTokensListItem from "../AccessTokensListItem";
import CreateAccessToken from "./CreateAccessToken";

export type OwnProps = {||};

export type StateProps = {|
  accessTokens: AccessToken[],
  error?: boolean,
  isLoading: boolean,
  isSubmitting: boolean,
|};

export type DispatchProps = {|
  onSubmit: ({ description: string }) => void,
  onLoad: () => {},
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type State = {
  dialogOpen: boolean,
};

class AccessTokens extends React.Component<Props, State> {
  state = {
    dialogOpen: false,
  };

  toggleDialog = () => {
    this.setState((state) => ({
      dialogOpen: !state.dialogOpen,
    }));
  };

  render() {
    const { accessTokens, isLoading } = this.props;
    const isEmpty = !accessTokens.length;

    const header = (
      <Media desktop>
        {(desktop) => (
          <FormHeader mobile={!desktop} heading="API Tokens" icon="api">
            <Button primary onClick={this.toggleDialog}>
              Create API Token
            </Button>
            <CreateAccessToken
              onSubmit={this.props.onSubmit}
              error={this.props.error}
              requestClose={this.toggleDialog}
              isSubmitting={this.props.isSubmitting}
              isOpen={this.state.dialogOpen}
            />
          </FormHeader>
        )}
      </Media>
    );

    return (
      <SettingsForm documentTitle="API Tokens" header={header}>
        <Loaded loading={isLoading} flex title="Loading tokens…">
          {() => {
            if (isEmpty) {
              return <NoAccessTokens flex />;
            }

            return (
              <FormSection heading="Personal Tokens">
                {accessTokens.map((token) => (
                  <AccessTokensListItem key={token.id} token={token} />
                ))}
              </FormSection>
            );
          }}
        </Loaded>
      </SettingsForm>
    );
  }
}

export default AccessTokens;
