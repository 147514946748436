// @flow
import { connect } from "react-redux";
import { getCollection } from "core/selectors/collections";
import type { Dispatch, State } from "core/types";
import type { OwnProps, StateProps, Props } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { inputShare, collectionId } = props;

  const collection = collectionId
    ? getCollection(state, {
        projectId: inputShare.descriptor.projectId,
        collectionId,
      })
    : undefined;

  return {
    collection,
  };
}

export default connect<Props, OwnProps, StateProps, _, State, Dispatch>(
  mapStateToProps
);
