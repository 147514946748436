// @flow
import * as React from "react";
import InputSwitch from "core/components/InputSwitch";
import type { ActionType } from ".";

type Props = {
  isToggledOn: boolean,
  onChange: (SyntheticInputEvent<>) => void,
  actionType: ActionType,
  disabled?: boolean,
};

export default function UpdateSeatToggleLine({
  isToggledOn,
  onChange,
  actionType,
  disabled,
}: Props) {
  const addLabel = <strong>Add Contributor Seats</strong>;
  const removeLabel = <strong>Also remove Contributor seat</strong>;

  return (
    <div>
      <InputSwitch
        responsive
        checked={isToggledOn}
        label={actionType === "add" ? addLabel : removeLabel}
        name="UpdateSeats"
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
}
