// @flow
import * as React from "react";
import config from "abstract-di/config";
import history from "abstract-di/history";
import ContextMenu from "core/components/ContextMenu";
import window from "core/global/window";
import { isDesktop, isMac, isWeb } from "core/lib/platform";
import { abstractWorkflowTutorialPath } from "core/lib/routes";
import { helpCenterUrl } from "core/lib/urls";
import type { MenuItem } from "core/types";
import connector from "./connector";

export type OwnProps = {|
  mobile: boolean,
  organizationId?: string,

  innerRef?: React.Ref<typeof ContextMenu>,
  children: (
    showMenu: (ev: SyntheticEvent<>) => void,
    ref: (el: ?HTMLElement) => void,
    popoverHandlers?: Object,
    buttonProps?: {
      "aria-haspopup": string,
      "aria-expanded": boolean,
      role: string,
      onKeyDown: (event: KeyboardEvent) => void,
    }
  ) => React.Node,
  onAfterClose?: () => void,
|};

export type StateProps = {|
  canAccessAbstractWorkflowTutorial: boolean,
|};

export type DispatchProps = {|
  openSupportWindow: (organizationId: string) => void,
  toggleShortcutViewer: () => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

class SupportMenu extends React.Component<Props> {
  helpCenter() {
    window.open(helpCenterUrl());
  }

  goToAbstractTutorial = () => {
    if (this.props.organizationId) {
      history.push(abstractWorkflowTutorialPath(this.props.organizationId));
    }
  };

  render() {
    const { mobile, toggleShortcutViewer } = this.props;

    const menuItems: MenuItem[] = [
      {
        label: "Contact Support",
        click: this.props.openSupportWindow,
      },
      { label: "Help Center", click: this.helpCenter },
    ];

    if (
      isDesktop &&
      this.props.canAccessAbstractWorkflowTutorial &&
      this.props.organizationId
    ) {
      menuItems.push(
        { type: "separator" },
        {
          label: "How Does Abstract Work?",
          click: this.goToAbstractTutorial,
        }
      );
    }

    if (isWeb && !mobile) {
      const command = isMac() ? "⌘ + /" : "Ctrl + /";
      menuItems.push(
        { type: "separator" },
        {
          label: `Keyboard Shortcuts (${command})`,
          click: toggleShortcutViewer,
        }
      );
    }

    if (config.version && config.platform === "web") {
      menuItems.push(
        { type: "separator" },
        {
          label: `Version ${config.version}`,
          enabled: false,
        }
      );
    }

    return (
      <ContextMenu
        menuItems={menuItems}
        id="support"
        ref={this.props.innerRef}
        children={this.props.children}
        onAfterClose={this.props.onAfterClose}
      />
    );
  }
}

export default connector(SupportMenu);
