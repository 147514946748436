// @flow
import classnames from "classnames";
import * as React from "react";
import window from "core/global/window";
import { Abstract } from "core/lib/abstract";
import eventInInput from "core/lib/eventInInput";
import KeyCode from "core/lib/keycode";
import { altText } from "core/models/layer";
import type { Layer, LayerState } from "core/types";
import Centered from "../Centered";
import LayerCanvas from "../LayerCanvas";
import Loaded from "../Loaded";
import Time from "../Time";
import type { ChangeEvent as ZoomState } from "../ZoomablePreview";
import style from "./style.scss";

type Props = {
  commitIsLatest?: boolean,
  layer: ?Layer,
  layerState: LayerState,
  compareLayer: ?Layer,
  compareLayerState: LayerState,
  params: Abstract.LayerVersionDescriptor,
  zoomState: ZoomState,
  onChangeZoomState: (ZoomState) => void,
  shareId?: string,
  layerCanvasRef: (?LayerCanvas) => void,
};

type State = {
  baseStatus: LayerState,
  compareStatus: LayerState,
  overlayDisabled: boolean,
};

export default class OverlayDiff extends React.Component<Props, State> {
  state = {
    overlayDisabled: false,
    baseStatus: "loading",
    compareStatus: "loading",
  };

  componentDidMount() {
    window.addEventListener("keydown", this.handleKeyDown);
    window.addEventListener("keyup", this.handleKeyUp);
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.params !== this.props.params) {
      this.setState({ baseStatus: "loading", compareStatus: "loading" });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
    window.removeEventListener("keyup", this.handleKeyUp);
  }

  handleKeyDown = (event: KeyboardEvent) => {
    if (eventInInput(event)) {
      return;
    }

    if (event.keyCode === KeyCode.KEY_BACK_SLASH) {
      this.setState({ overlayDisabled: true });
    }
  };

  handleKeyUp = (event: KeyboardEvent) => {
    if (eventInInput(event)) {
      return;
    }

    if (event.keyCode === KeyCode.KEY_BACK_SLASH) {
      this.setState({ overlayDisabled: false });
    }
  };

  handleOnLoadBase = (status: LayerState) => {
    this.setState({ baseStatus: status });
  };

  handleOnLoadCompare = (status: LayerState) => {
    this.setState({ compareStatus: status });
  };

  renderTime() {
    return (
      <div className={style.time}>
        {this.props.compareLayer && (
          <span className={style.compareTime}>
            <Time date={this.props.compareLayer.updatedAt} />
            {" & "}
          </span>
        )}
        {this.props.layer && (
          <Time date={this.props.layer.updatedAt}>
            {this.props.commitIsLatest ? "Latest" : undefined}
          </Time>
        )}
      </div>
    );
  }

  render() {
    const isLoadingOverlay =
      this.state.baseStatus === "loading" ||
      (this.state.compareStatus === "loading" &&
        this.props.compareLayerState !== "not_found");

    const showErrorState =
      this.state.baseStatus === "error" || this.state.compareStatus === "error";

    if (showErrorState) {
      return (
        <Centered className={style.error}>
          Could not load previews for overlay comparison.
        </Centered>
      );
    }

    return (
      <React.Fragment>
        {isLoadingOverlay && <Loaded loading />}
        <div
          className={classnames(style.overlay, {
            [style.offscreen]: isLoadingOverlay,
            [style.overlayDisabled]: this.state.overlayDisabled,
          })}
        >
          <div className={style.compareContainer}>
            {this.renderTime()}
            {this.props.compareLayerState === "not_found" ? (
              <div className={style.layerCanvas}>
                <LayerCanvas
                  params={this.props.params}
                  layer={this.props.layer}
                  layerMissing={this.props.layerState === "not_found"}
                  alt={altText(this.props.layer)}
                  zoomState={this.props.zoomState}
                  onChangeZoomState={this.props.onChangeZoomState}
                  onLoad={this.handleOnLoadBase}
                  shareId={this.props.shareId}
                />
              </div>
            ) : (
              <div className={style.invertFilter}>
                <div className={style.compareLayerCanvas}>
                  <LayerCanvas
                    className={style.layerCanvasInner}
                    params={this.props.params}
                    layer={this.props.compareLayer}
                    layerMissing={this.props.compareLayerState === "not_found"}
                    alt={altText(this.props.compareLayer)}
                    zoomState={this.props.zoomState}
                    onChangeZoomState={this.props.onChangeZoomState}
                    onLoad={this.handleOnLoadCompare}
                    shareId={this.props.shareId}
                  />
                </div>
                <div className={style.layerCanvas}>
                  <LayerCanvas
                    ref={this.props.layerCanvasRef}
                    className={style.layerCanvasInner}
                    params={this.props.params}
                    layer={this.props.layer}
                    layerMissing={this.props.layerState === "not_found"}
                    alt={altText(this.props.layer)}
                    zoomState={this.props.zoomState}
                    onChangeZoomState={this.props.onChangeZoomState}
                    onLoad={this.handleOnLoadBase}
                    shareId={this.props.shareId}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
