// @flow
import * as React from "react";
import AddItemsToCollection from "core/components/AddItemsToCollection";
import Button from "core/components/Button";
import type { Collection, Branch } from "core/types";

type ButtonProps = {
  className?: string,
  onClick: (event?: SyntheticEvent<>) => void,
  hideLabel?: boolean,
};

type Props = {
  collection: Collection,
  branch: ?Branch,
  mobile?: boolean,
  button?: (ButtonProps) => React.Node,
  className?: string,
  qaSelector?: string,
};

type State = {
  showAdd: boolean,
};

export default class AddItemsToCollectionButton extends React.Component<
  Props,
  State,
> {
  state = { showAdd: false };

  handleShowAdd = () => this.setState({ showAdd: true });
  handleHideAdd = () => this.setState({ showAdd: false });

  render() {
    const { collection, branch, button, className, mobile, qaSelector } =
      this.props;

    const buttonProps: ButtonProps = {
      className,
      onClick: this.handleShowAdd,
      hideLabel: mobile,
      title: "Add item",
      tooltip: { placement: "bottom" },
      "data-qa": qaSelector,
    };

    return (
      <React.Fragment>
        {button ? (
          button(buttonProps)
        ) : (
          <Button
            {...buttonProps}
            icon="add-to-collection"
            qaSelector={qaSelector}
          />
        )}
        {this.state.showAdd && (
          <AddItemsToCollection
            collection={collection}
            projectId={collection.projectId}
            branch={branch}
            isOpen={this.state.showAdd}
            onClose={this.handleHideAdd}
          />
        )}
      </React.Fragment>
    );
  }
}
