// @flow
import { static as Immutable } from "seamless-immutable";
import type { Action, Notification } from "core/types";

type NotificationsMeta = {
  allLoaded: boolean,
  currentOrganizationFilter: ?string,
  oldestCreatedAt?: string,
  open: boolean,
  organizations: {
    [organizationId: ?string]: {
      allLoaded: boolean,
      oldestCreatedAt?: string,
    },
  },
};

export const DEFAULT_STATE: NotificationsMeta = Immutable.from({
  allLoaded: false,
  currentOrganizationFilter: null,
  open: false,
  organizations: {},
});

function getOldestNotificationCreatedAt(notifications: Notification[]) {
  return notifications.reduceRight((oldestCreatedAt, notification) => {
    if (oldestCreatedAt == null) {
      return notification.createdAt;
    }

    return oldestCreatedAt < notification.createdAt
      ? oldestCreatedAt
      : notification.createdAt;
  }, null);
}

export default function notificationsMeta(
  state: NotificationsMeta = DEFAULT_STATE,
  action: Action
): NotificationsMeta {
  switch (action.type) {
    case "core/FILTER_NOTIFICATIONS": {
      const { organizationId } = action.meta;

      if (organizationId == null) {
        return Immutable.set(state, "currentOrganizationFilter", null);
      }

      // If an organization doesn't exist in state yet, initialize it:
      let immutableState = Immutable.from(state);
      if (organizationId && state.organizations[organizationId] === undefined) {
        immutableState = Immutable.setIn(
          immutableState,
          ["organizations", organizationId, "allLoaded"],
          false
        );
      }

      return Immutable.set(
        immutableState,
        "currentOrganizationFilter",
        organizationId
      );
    }
    case "core/NOTIFICATIONS_LOADED": {
      const { organizationId } = action.meta;
      const oldestCreatedAt = getOldestNotificationCreatedAt(action.payload);

      if (organizationId == null) {
        return Immutable.merge(state, {
          allLoaded: action.meta.nextOffset === null,
          oldestCreatedAt,
        });
      }

      return Immutable.merge(
        state,
        {
          organizations: {
            [organizationId]: {
              allLoaded: action.meta.nextOffset === null,
              oldestCreatedAt,
            },
          },
        },
        { deep: true }
      );
    }
    case "core/NOTIFICATIONS_OPEN": {
      return Immutable.set(state, "open", true);
    }
    case "TITLEBAR_FLYOVER":
    case "core/QUICK_JUMP_OPEN":
    case "core/NOTIFICATIONS_CLOSE": {
      return Immutable.set(state, "open", false);
    }
    case "SIGNED_OUT": {
      return DEFAULT_STATE;
    }
    default:
      return state;
  }
}
