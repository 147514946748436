// @flow
import * as React from "react";
import AsyncUserFilter from "core/components/AsyncUserFilter";
import CreateCollectionButton from "core/components/CreateCollectionButton";
import type { Item as FilterItem } from "core/components/FilterPopover";
import InputSearch from "core/components/InputSearch";
import PageTitle from "core/components/PageTitle";
import PageTitleNavigationLink from "core/components/PageTitleNavigationLink";
import useBranchDisplayName from "core/hooks/useBranchDisplayName";
import { addQuery, removeQuery } from "core/lib/location";
import type { User, CollectionBranchFilter } from "core/types";

const USE_MAIN_INSTEAD_OF_MASTER: boolean =
  process.env.USE_MAIN_INSTEAD_OF_MASTER === "true";
type Props = {|
  +mobile?: boolean,
  +projectId: string,
  +selectedAuthor: ?User,
  +authorFilterItems: FilterItem[],
  +canCreateCollectionsOnMaster: boolean,
  +onSelectAuthor: () => void,
  +searchFilter?: string,
  +onChangeSearchFilter: (string) => void,
  +branchFilter: CollectionBranchFilter,
|};

export default function ProjectCollectionsHeader(props: Props) {
  const authorFilter = (
    <AsyncUserFilter
      anchor="left"
      buttonText={
        props.selectedAuthor ? props.selectedAuthor.name : "All Authors"
      }
      placeholderText="Filter people…"
      selectedItem={
        props.selectedAuthor && {
          id: props.selectedAuthor.id,
          name: props.selectedAuthor.name,
          username: `@${props.selectedAuthor.username}`,
        }
      }
      onSelectItem={props.onSelectAuthor}
      mobile={props.mobile}
      projectId={props.projectId}
      qaSelector="collectionsAuthorFilter"
    />
  );
  const { defaultBranchNameTitleCase } = useBranchDisplayName();

  return (
    <PageTitle
      navigation={[
        <PageTitleNavigationLink
          to={removeQuery("branchFilter")}
          active={props.branchFilter === "all"}
        >
          All Branches
        </PageTitleNavigationLink>,
        <PageTitleNavigationLink
          to={
            USE_MAIN_INSTEAD_OF_MASTER
              ? addQuery({ branchFilter: "main" })
              : addQuery({ branchFilter: "master" })
          }
          icon="master"
          active={
            USE_MAIN_INSTEAD_OF_MASTER
              ? props.branchFilter === "main"
              : props.branchFilter === "master"
          }
        >
          {defaultBranchNameTitleCase}
        </PageTitleNavigationLink>,
        <PageTitleNavigationLink
          to={addQuery({ branchFilter: "active" })}
          icon="branch"
          active={props.branchFilter === "active"}
        >
          Active
        </PageTitleNavigationLink>,
        <PageTitleNavigationLink
          to={addQuery({ branchFilter: "archived" })}
          icon="archive"
          active={props.branchFilter === "archived"}
        >
          Archived
        </PageTitleNavigationLink>,
      ]}
      filter={props.mobile ? authorFilter : undefined}
      sort={!props.mobile ? authorFilter : undefined}
      search={
        <InputSearch
          debounce={200}
          defaultValue={props.searchFilter}
          large={props.mobile}
          onChange={(event) => {
            props.onChangeSearchFilter(event.target.value);
          }}
          placeholder="Filter by title…"
        />
      }
      actions={
        props.canCreateCollectionsOnMaster && (
          <CreateCollectionButton
            primary
            projectId={props.projectId}
            branchId="master"
          />
        )
      }
      title="Collections"
    />
  );
}
