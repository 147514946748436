// @flow
import * as React from "react";
import Button from "core/components/Button";

type Props = {
  active: boolean,
  disabled: boolean,
};

export default function ButtonHotspots(props: Props) {
  const { active, disabled, ...rest } = props;

  return (
    <Button
      icon={active ? "prototype-tint" : "prototype"}
      disabled={disabled}
      nude
      title={active ? "Hide hotspots" : "Show hotspots"}
      tooltip={{
        placement: "top",
      }}
      {...rest}
    />
  );
}
