// @flow
import * as React from "react";
import Button from "core/components/Button";
import CreateOrganization from "core/components/CreateOrganization";

type Props = {
  className?: string,
  title?: string,
  onClick?: () => void,
  onClickClose?: () => void,
};

type State = {
  dialogOpen: boolean,
};

export default class CreateOrganizationButton extends React.Component<
  Props,
  State,
> {
  static defaultProps = {
    title: "Create an organization",
  };

  state = {
    dialogOpen: false,
  };

  open = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }

    this.setState({
      dialogOpen: true,
    });
  };

  close = (event?: SyntheticEvent<>) => {
    if (event) {
      // prevent the close event from bubbling and not setting state correctly
      // for the create organization dialog
      event.stopPropagation();
    }

    if (this.props.onClickClose) {
      this.props.onClickClose();
    }
    this.setState({
      dialogOpen: false,
    });
  };

  render() {
    const { className, title, ...rest } = this.props;
    const { dialogOpen } = this.state;
    return (
      <Button {...rest} className={className} onClick={this.open}>
        {title}
        <CreateOrganization isOpen={dialogOpen} onClose={this.close} />
      </Button>
    );
  }
}
