// @flow
import * as React from "react";
import ContextMenu, { type Children } from "core/components/ContextMenu";
import { ReviewStatuses } from "core/models/review";
import type { ReviewRequest } from "core/types";

type Props = {
  children: Children,
  reviewRequest: ReviewRequest,
  deleteReview: ?(id: string) => *,
  dismissReview: (id: string) => *,
};

type State = { showRemoveReviewerDialog: boolean };

export default class BranchReviewerMenu extends React.Component<Props, State> {
  menu: ?ContextMenu;

  closeMenu = () => {
    this.menu && this.menu.close();
  };

  handleDismissReview = () => {
    this.props.dismissReview(this.props.reviewRequest.id);
    this.closeMenu();
  };

  handleDeleteReview = () => {
    if (this.props.deleteReview) {
      this.props.deleteReview(this.props.reviewRequest.id);
      this.closeMenu();
    }
  };

  render() {
    const { reviewRequest } = this.props;
    const isRequested = reviewRequest.status === ReviewStatuses.REQUESTED;

    const basicItems = [
      {
        label: "Remove reviewer",
        click: this.handleDeleteReview,
        enabled: isRequested && Boolean(this.props.deleteReview),
      },
    ];

    const menuItems = isRequested
      ? basicItems
      : [
          ...basicItems,
          { type: "separator" },
          {
            label: "Dismiss review",
            click: this.handleDismissReview,
          },
        ];

    return (
      <ContextMenu
        ref={(me) => (this.menu = me)}
        id={reviewRequest.id}
        menuItems={menuItems}
        children={this.props.children}
      />
    );
  }
}
