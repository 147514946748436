// @flow
import * as React from "react";
import { connect } from "react-redux";
import { trackEvent } from "core/actions/analytics";
import { showCloseableToast } from "core/actions/toasts";
import { ValidationError as ValidationErrorClass } from "core/api";
import Button from "core/components/Button";
import Flex from "core/components/Flex";
import FormSection from "core/components/FormSection";
import Icon from "core/components/Icon";
import Input from "core/components/Input";
import InputSwitch from "core/components/InputSwitch";
import Note from "core/components/Note";
import SettingsItem from "core/components/SettingsItem";
import SettingsItemInputNote from "core/components/SettingsItemInputNote";
import ValidationError from "core/components/ValidationError";
import window from "core/global/window";
import type {
  Dispatch,
  SSOConfig,
  ValidationErrors as ValidationErrorsType,
} from "core/types";
import { testSSOConfiguration } from "web/api";
import DisableSSOModal from "web/components/OrganizationSettings/Permissions/DisableSSOModal";
import withForm from "web/containers/withForm";
import type { FormProps } from "web/types";
import style from "./style.scss";

type OwnProps = {|
  ...SSOConfig,
  onSubmit: (formId: string, organizationId: string, values: SSOConfig) => void,
  organizationId: string,
  userId: string,
|};

type DispatchProps = {|
  testSSOAction: () => void,
|};

type Props = {|
  ...OwnProps,
  ...DispatchProps,
  ...FormProps,
|};

type State = {|
  showDisableSSOModal: boolean,
  ssoTestActive: boolean,
  ssoTestErrors: ValidationErrorsType,
|};

class SSOForm extends React.Component<Props, State> {
  static defaultProps: SSOConfig = {
    entityId: "",
    emailExceptions: "",
    metadataUrl: "",
    ssoActive: false,
  };

  state = {
    showDisableSSOModal: false,
    ssoTestActive: false,
    ssoTestErrors: {},
  };

  isValid() {
    return (
      this.props.form.values.metadataUrl !== "" &&
      this.props.form.values.entityId !== ""
    );
  }

  handleChange = (event: SyntheticInputEvent<>) => {
    this.props.form.onChange(this.props.form.id, {
      [event.target.name]: event.target.value,
    });
  };

  handleSSOActivation = (event: SyntheticInputEvent<>) => {
    this.props.form.onChange(this.props.form.id, {
      ssoActive: this.isValid() ? event.target.checked : false,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    // Require a second confirmation for disabling SSO
    if (!this.props.form.values.ssoActive && this.props.ssoActive) {
      this.showDisableSSOModal();
    } else {
      this.submitForm();
    }
  };

  testSSOSetup = async () => {
    try {
      this.setState({
        ssoTestActive: true,
      });

      await this.props.testSSOAction();

      this.setState({
        ssoTestActive: false,
      });
    } catch (e) {
      this.setState({
        ssoTestActive: false,
        ssoTestErrors: e.body.errors,
      });
    }
  };

  showDisableSSOModal = () => {
    this.setState({
      showDisableSSOModal: true,
    });
  };

  hideDisableSSOModal = () => {
    this.setState({
      showDisableSSOModal: false,
    });
  };

  submitForm() {
    this.props.onSubmit(
      this.props.form.id,
      this.props.organizationId,
      this.props.form.values
    );
  }

  hideDisableSSOModalAndSubmit = () => {
    this.hideDisableSSOModal();
    this.submitForm();
  };

  render() {
    return (
      <React.Fragment>
        <form onSubmit={this.handleSubmit}>
          <FormSection heading="Configure SSO">
            <SettingsItem>
              <Flex align="center">
                <Icon className={style.ssoInfoIcon} type="info" />
                <span className={style.ssoInfoText}>
                  SAML-based single sign-on (SSO) can be configured below.
                </span>
              </Flex>
            </SettingsItem>
            <SettingsItem input>
              <Input
                error={
                  this.props.form.errors.metadataUrl ||
                  this.state.ssoTestErrors.metadataUrl
                }
                label="Metadata URL"
                name="metadataUrl"
                onChange={this.handleChange}
                placeholder="https://..."
                required
                requiredTag
                responsive
                value={this.props.form.values.metadataUrl || ""}
              />
              <SettingsItemInputNote>
                The URL to your SAML Metadata XML file. It must be publicly
                accessible for Abstract to download &amp; process.
              </SettingsItemInputNote>
            </SettingsItem>
            <SettingsItem input>
              <Input
                error={
                  this.props.form.errors.entityId ||
                  this.state.ssoTestErrors.entityId
                }
                label="Entity ID"
                name="entityId"
                onChange={this.handleChange}
                placeholder="http://www.okta.com/companyname"
                required
                requiredTag
                responsive
                value={this.props.form.values.entityId || ""}
              />
              <SettingsItemInputNote>
                Look for <code>entityID=</code> in your metadata XML file and
                paste the contents here.
              </SettingsItemInputNote>
            </SettingsItem>
            <SettingsItem input>
              <Input
                error={this.props.form.errors.emailExceptions}
                label="Manual exceptions"
                name="emailExceptions"
                onChange={this.handleChange}
                placeholder="user@example.com, user@example.com"
                responsive
                type="textarea"
                value={this.props.form.values.emailExceptions || ""}
              />
              <SettingsItemInputNote>
                Use a comma-separated list of emails to make exceptions for
                individuals if you have SSO restrictions.
              </SettingsItemInputNote>
            </SettingsItem>
            <SettingsItem label="Test Configuration">
              <div>
                <Button
                  className={style.testButton}
                  onClick={this.testSSOSetup}
                  disabled={this.state.ssoTestActive}
                >
                  {this.state.ssoTestActive
                    ? "Getting SSO details…"
                    : "Test With My Account"}
                </Button>
                <Note>
                  Check that the SSO login flow is working correctly by testing
                  it with your account.
                </Note>
              </div>
            </SettingsItem>
            <SettingsItem label="Activate SSO">
              <div>
                <InputSwitch
                  disabled={!this.isValid()}
                  checked={this.props.form.values.ssoActive || false}
                  name="ssoActive"
                  onChange={this.handleSSOActivation}
                />
                {this.props.form.errors.ssoActive && (
                  <ValidationError
                    className={style.ssoActivationError}
                    error={this.props.form.errors.ssoActive}
                  />
                )}
                <Note>
                  This is it—the On switch. Once you’ve tested everything, turn
                  this on with confidence.
                </Note>
              </div>
            </SettingsItem>
            <SettingsItem className={style.submit}>
              <Button
                disabled={
                  !this.props.form.isDirty || this.props.form.isSubmitting
                }
                onClick={this.handleSubmit}
                primary
                type="submit"
              >
                {this.props.form.isSubmitting ? "Saving…" : "Save changes"}
              </Button>
            </SettingsItem>
          </FormSection>
        </form>
        <DisableSSOModal
          onClose={this.hideDisableSSOModal}
          onSubmit={this.hideDisableSSOModalAndSubmit}
          isOpen={this.state.showDisableSSOModal}
        />
      </React.Fragment>
    );
  }
}

function getDefaultValues(props: OwnProps) {
  return {
    emailExceptions: props.emailExceptions || "",
    entityId: props.entityId || "",
    metadataUrl: props.metadataUrl || "",
    ssoActive: props.ssoActive || false,
  };
}

const mapDispatchToProps = (
  dispatch: Dispatch,
  props: {| ...OwnProps, ...FormProps |}
) => {
  const testSSOAction = async () => {
    try {
      dispatch(
        trackEvent("SSO_CONFIG_TESTED", {
          organizationId: props.organizationId,
          userId: props.userId,
        })
      );

      const response = await testSSOConfiguration({
        entityId: props.form.values.entityId,
        metadataUrl: props.form.values.metadataUrl,
        organizationId: props.organizationId,
      });

      // open new window with url encoded GET request
      window.open(response.url);

      dispatch(
        trackEvent("SSO_CONFIG_TEST_SUCCESSFUL", {
          organizationId: props.organizationId,
          userId: props.userId,
        })
      );
    } catch (e) {
      if (e instanceof ValidationErrorClass) {
        throw e;
      } else {
        dispatch(showCloseableToast({ text: `Error: ${e.body.error}` }));
      }
    }
  };

  return { testSSOAction };
};

const connectedSSOForm = connect(undefined, mapDispatchToProps)(SSOForm);

export default withForm<OwnProps>(
  connectedSSOForm,
  (props) => `${props.organizationId}-sso-form`,
  getDefaultValues
);
