// @flow
import classnames from "classnames";
import * as React from "react";
import type { CommentForm as TCommentForm } from "core/types";
import CommentForm, { type OwnProps as CommentFormProps } from "../CommentForm";
import style from "./style.scss";

export type Props = {
  ...CommentFormProps,
  onReply: (formId: string, params: TCommentForm) => *,
  commitSha?: string,
  projectId: string,
  parentId?: string,
  branchId?: string,
  fileId?: string,
  pageId?: string,
  layerId?: string,
  innerRef?: React.Ref<"div">,
};

export default class CommentReplyForm extends React.Component<Props> {
  form: *;

  static defaultProps = { body: "" };

  handleCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
    if (this.props.onReset) {
      this.props.onReset(this.props.formId);
    }
  };

  handleSubmit = (body: string) => {
    if (!this.props.branchId) {
      return;
    }

    this.props.onReply(this.props.formId, {
      projectId: this.props.projectId,
      parentId: this.props.parentId || undefined,
      branchId: this.props.branchId,
      commitSha: this.props.commitSha || undefined,
      fileId: this.props.fileId || undefined,
      pageId: this.props.pageId || undefined,
      layerId: this.props.layerId || undefined,
      body,
    });
  };

  setReplyFormRef = (ref: *) => {
    this.form = ref;
  };

  focus = () => {
    if (this.form) {
      this.form.focus();
    }
  };

  render() {
    return (
      <div
        ref={this.props.innerRef}
        className={classnames(style.commentReply, this.props.className)}
      >
        <div className={style.form}>
          <CommentForm
            innerRef={this.setReplyFormRef}
            key={this.props.formId}
            projectId={this.props.projectId}
            online={this.props.online}
            body={this.props.body}
            formId={this.props.formId}
            parentId={this.props.parentId}
            minHeight={this.props.minHeight}
            disabled={this.props.disabled}
            hasError={this.props.hasError}
            isLoading={this.props.isLoading}
            onSubmit={this.handleSubmit}
            onCancel={this.handleCancel}
            onChange={this.props.onChange}
            onReset={this.props.onReset}
            autoFocus={this.props.autoFocus}
            submitButtonText="Reply"
            placeholder="Leave your reply…"
            collapsible
            isReply
            isPubliclyShared={this.props.isPubliclyShared}
            showIndicatorOn="focus"
            qaSelector="reply-comment-form"
          />
        </div>
      </div>
    );
  }
}
