// @flow
import React, { Component } from "react";
import DialogForm from "core/components/DialogForm";
import Note from "core/components/Note";
import type { Library } from "core/types";
import connector from "./connector";

export type OwnProps = {|
  projectId: string,
  branchId: string,
  library: Library,
|};

export type DispatchProps = {|
  removeLibrary: () => void,
  dismissDialog: () => void,
|};

export type Props = {
  ...OwnProps,
  ...DispatchProps,
};

class ConfirmAddLibrary extends Component<Props> {
  handleSubmit = (ev: SyntheticEvent<>) => {
    ev.preventDefault();
    this.props.removeLibrary();
    this.props.dismissDialog();
  };

  render() {
    const externalLibrary = !!this.props.library.projectId;

    return (
      <DialogForm
        title={externalLibrary ? "Unlink Library" : "Stop using as Library"}
        primaryButton="Stop using as Library"
        onSubmit={this.handleSubmit}
        onClose={this.props.dismissDialog}
        dangerous
        isOpen
      >
        <p>
          Symbols from this library will no longer be available to insert in
          other files on this branch
          {externalLibrary ? "." : " or in other projects."}
        </p>
        <Note>
          All files contain a backup copy of linked symbols so their appearance
          will not change, however they will no longer be connected.
        </Note>
      </DialogForm>
    );
  }
}

export default connector(ConfirmAddLibrary);
