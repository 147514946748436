// @flow
import { sortBy } from "lodash";
import matchString from "core/lib/matchString";
import naturalSortBy from "core/lib/naturalSortBy";
import type { SlackChannel } from "core/types";

export default function filterChannels(
  channels: SlackChannel[],
  value: string
): SlackChannel[] {
  if (!channels) {
    return [];
  }

  const filtered = channels.filter((channel) => {
    return matchString(channel.name, value);
  });

  return sortBy(naturalSortBy(filtered, "name"), (channel) =>
    channel.name.startsWith(value) ? 0 : 1
  );
}
