// @flow
import classnames from "classnames";
import invariant from "invariant";
import truncate from "lodash/truncate";
import * as React from "react";
import { useDispatch } from "react-redux";
import { showToast } from "core/actions/toasts";
import CopyToClipboard from "core/components/CopyToClipboard";
import Popover from "core/components/Popover";
import type { LayerChange } from "core/types";
import style from "./style.scss";

export type PropertyValueFormatter<T> =
  | {| label: (value: T) => string |}
  | {| label: (value: T) => React.Node, code: (value: T) => string |};

export type Props<T> = {
  value: T,
  formatter?: PropertyValueFormatter<T>,
  truncate?: boolean,
  change?: LayerChange<T>,
  status?: ?$PropertyType<LayerChange<T>, "status">,
  label?: React.Node,
  title?: string,
  size?: "half" | "single" | "double" | "full",
  estimated?: boolean,
  tooltip?: string,
  disabled?: boolean,
};

const DEFAULT_FORMATTER = {
  label: (value: mixed) => {
    invariant(
      typeof value === "string",
      "Custom formatter required for non-string values"
    );

    return value;
  },
};

function PropertyValue<T>(props: Props<T>) {
  const dispatch = useDispatch();
  const formatter = props.formatter || DEFAULT_FORMATTER;
  const value = formatter.label(props.value);
  const tooltip =
    props.change && props.change.status === "changed" && formatter.code
      ? `Previous value: ${formatter.code(props.change.previous)}`
      : props.tooltip;

  return (
    <CopyToClipboard
      onSuccess={(formattedValue) =>
        dispatch(
          // Quotes are truncated so that you know the ellipsis
          // is not part of the copied value.
          showToast({ text: `Copied ${truncate(`“${formattedValue}”`)}` }, 2000)
        )
      }
      disabled={props.disabled}
      value={
        formatter.code
          ? formatter.code(props.value)
          : formatter.label(props.value)
      }
    >
      {(setClickableRef) => (
        <div
          ref={setClickableRef}
          tabIndex="0"
          className={classnames(style.propertyValue, {
            [style.disabled]: props.disabled,
            [style.enabled]: !props.disabled,
            [style.half]: props.size === "half",
            [style.double]: props.size === "double",
            [style.full]: props.size === "full",
            [style.truncate]:
              props.truncate === undefined ? true : props.truncate,
            [style.changed]: props.change && props.change.status === "changed",
            [style.added]: props.status === "added",
            [style.removed]: props.status === "removed",
          })}
        >
          {props.label && (
            <div className={style.label} title={props.title || props.label}>
              {props.label}
            </div>
          )}
          <Popover
            label={tooltip}
            disabled={props.disabled || !tooltip}
            placement="bottom"
          >
            {(_, popoverRef, popoverHandlers) =>
              React.isValidElement(value) ? (
                <div>
                  <span ref={popoverRef} {...popoverHandlers}>
                    {value}
                  </span>
                </div>
              ) : (
                <div className={style.valueContainer}>
                  <span
                    ref={popoverRef}
                    {...popoverHandlers}
                    className={style.value}
                  >
                    {
                      // $FlowFixMe Flow can't refine isValidElement https://github.com/facebook/flow/issues/6392
                      props.estimated ? `~${value}` : value
                    }
                  </span>
                </div>
              )
            }
          </Popover>
        </div>
      )}
    </CopyToClipboard>
  );
}

export default PropertyValue;
