// @flow
import * as React from "react";
import type { ColumnGrid } from "core/types";
import style from "./style.scss";

type Props = {
  width: number,
  height: number,
  scale: number,
  style?: { [string]: mixed },
  grid: ColumnGrid,
};

export default function ColumnGridOverlay(props: Props) {
  const scaled = (value: number) => value * props.scale;

  const {
    columnWidth,
    gutterHeight,
    gutterWidth,
    guttersOutside,
    horizontalOffset,
    drawHorizontal,
    drawVertical,
    numberOfColumns,
    rowHeightMultiplication,
    drawHorizontalLines,
    totalWidth,
  } = props.grid;

  const rowHeight = rowHeightMultiplication * gutterHeight;
  const numberOfRows = drawHorizontal
    ? Math.floor(props.height / rowHeight)
    : 0;

  return (
    <div
      style={{
        ...props.style,
        width: scaled(props.width),
        height: scaled(props.height),
      }}
      className={style.gridContainer}
    >
      <div
        className={style.layout}
        style={{
          marginLeft: scaled(horizontalOffset),
          width: scaled(totalWidth),
        }}
      >
        {Array(numberOfRows)
          .fill()
          .map((_, index) => (
            <div
              className={style.row}
              style={{
                top: index * scaled(rowHeight + gutterHeight),
                height: scaled(rowHeight),
              }}
            >
              {drawHorizontalLines &&
                Array(rowHeightMultiplication + 1)
                  .fill()
                  .map((_, index) => (
                    <div
                      style={{ top: scaled(gutterHeight) * index }}
                      className={style.horizontalLine}
                    />
                  ))}
            </div>
          ))}
        {Array(drawVertical ? numberOfColumns : 0)
          .fill()
          .map((_, index) => (
            <div
              className={style.column}
              style={{
                left:
                  index * scaled(columnWidth + gutterWidth) +
                  (guttersOutside ? scaled(gutterWidth / 2) : 0),
                width: scaled(columnWidth),
                height: scaled(props.height),
              }}
            />
          ))}
      </div>
    </div>
  );
}
