// @flow
import classnames from "classnames";
import * as React from "react";
import { V3NavLink as Link } from "core/lib/router";
import type { LocationDescriptor } from "core/types";
import Avatar from "../Avatar";
import Icon from "../Icon";
import style from "./style.scss";

type Props = {|
  // `active` controls if the link renders as "currently selected" or not. If
  // this is a link to a react-router location, this prop is unnecessary. But
  // if you use this component as a button, you should provide this prop
  // manually to control the `active` styling.
  +active?: boolean,
  // `avatarUserId` allows you to render a person's avatar as the icon for the
  // link. Pass either `avatarUserId` or `icon`, but not both.
  +avatarUserId?: string,
  // `children` is the contents of the link, generally plain text.
  +children?: React.Node,
  // `disabled` will make the link unclickable and give it a greyed-out style.
  +disabled?: boolean,
  // `icon` allows an icon to be rendered next to `children`.
  +icon?: string,
  // `onClick` when passed along with `to` will allow you to listen in for link
  // clicks. When passed without `to`, this component will behave like a
  // button.
  +onClick?: (SyntheticEvent<>) => void,
  // `to` is passed directly to react-router's Link component.
  +to: LocationDescriptor,
  // Used for QA automation testing
  +qaSelector?: string,
  // Used for react-router's <Link>. If `true`, link will only be active
  // when the current route exactly matches the linked route.
  +onlyActiveOnIndex?: boolean,
  // A className for custom style
  +className?: string,
|};

export default function PageTitleNavigationLink(props: Props) {
  const useReactRouterActiveClass = props.active === undefined;

  return (
    <Link
      activeClassName={useReactRouterActiveClass ? style.active : undefined}
      className={classnames(style.navLink, props.className, {
        [style.active]: props.active,
        [style.disabled]: props.disabled,
        [style.withIcon]: !!(props.icon || props.avatarUserId),
      })}
      onClick={props.onClick}
      to={props.to}
      data-qa={props.qaSelector}
      end={props.onlyActiveOnIndex} // Use `end` to exact match on index routes, preventing two active tabs
    >
      {props.icon && (
        <Icon
          type={props.icon}
          className={classnames(style.icon, {
            [style.iconActive]: props.active,
          })}
          fill="currentColor"
        />
      )}

      {props.avatarUserId && (
        <div className={style.avatar}>
          <Avatar userId={props.avatarUserId} size="16px" />
        </div>
      )}
      {props.children}
    </Link>
  );
}
