// @flow
import * as React from "react";
import { useDispatch } from "react-redux";
import { trackEvent } from "core/actions/analytics";
import Button from "core/components/Button";
import window from "core/global/window";
import { latestReleaseDownloadUrl } from "core/lib/urls";
import { createTransferToken } from "web/api";

type Props = {
  abstractUrl?: string,
  downloadButton?: boolean,
  icon?: string,
};

export default function AbstractAppButton(props: Props) {
  const { abstractUrl, downloadButton, icon, ...rest } = props;

  const dispatch = useDispatch();

  const handleDownload = React.useCallback(() => {
    dispatch(trackEvent("CLICKED_DOWNLOAD_APP_BUTTON"));
  }, [dispatch]);

  const openInAbstract = React.useCallback(
    async (event: SyntheticEvent<>) => {
      event.preventDefault();

      let url = abstractUrl || `abstract://app/share?`;

      // lets create a transfer token so we can persist authentication
      // to the desktop application if the user is not currently logged
      // in to desktop
      try {
        const { accessToken } = await createTransferToken();
        if (accessToken) {
          url = `${url}&token=${accessToken}`;
        }
      } finally {
        dispatch(
          trackEvent("CLICKED_OPEN_IN_ABSTRACT_BUTTON", { url: abstractUrl })
        );

        // we want to open the desktop app
        // even if the creating the transfer
        // token failed
        window.location.href = url;
      }
    },
    [abstractUrl, dispatch]
  );

  if (downloadButton) {
    return (
      <Button
        qaSelector="downLoadAbstractButton"
        component="a"
        marketingTriggerClassName="gtm-download-app"
        icon={icon}
        href={latestReleaseDownloadUrl()}
        onClick={handleDownload}
        {...rest}
      >
        Download
      </Button>
    );
  }

  return (
    <Button
      qaSelector="openInAbstractButton"
      onClick={openInAbstract}
      {...rest}
      external
    >
      Open in Abstract for macOS
    </Button>
  );
}
