// @flow
import classnames from "classnames";
import * as React from "react";
import connectStorage from "core/hocs/connectStorage";
import Button from "../Button";
import Flex from "../Flex";
import style from "./collapsible.scss";
import Card from ".";

type Props = {
  children: React.Node,
  header: React.Node,
  storageId?: string,
  className?: string,
  innerClassName?: string,
  mobile?: boolean,
  defaultCollapsed?: boolean,
  collapsible?: boolean,
  collapsed?: boolean,
  disabled?: boolean,
  onCollapse?: (boolean) => void,
};

type State = { collapsed: boolean };

class CollapsibleCard extends React.Component<Props, State> {
  state = {
    collapsed: !!this.props.defaultCollapsed,
  };

  toggleCollapsed = (ev: SyntheticEvent<>) => {
    if (ev) {
      ev.preventDefault();
    }
    this.setState(
      (prevState) => ({ collapsed: !prevState.collapsed }),
      () => {
        if (this.props.onCollapse) {
          this.props.onCollapse(this.state.collapsed);
        }
      }
    );
  };

  render() {
    const {
      header,
      className,
      collapsible,
      innerClassName,
      disabled,
      mobile,
      ...props
    } = this.props;

    const collapsed =
      this.props.collapsed !== undefined
        ? this.props.collapsed
        : this.state.collapsed;

    return (
      <Card
        {...props}
        mobile={mobile}
        collapsed={collapsed}
        header={
          <Flex
            align="center"
            className={style.header}
            onClick={this.toggleCollapsed}
          >
            <Button
              nude
              icon="disclosure-expanded"
              disabled={disabled}
              className={classnames(style.button, {
                [style.rotated]: collapsed,
              })}
            />
            {header}
          </Flex>
        }
        className={classnames(style.collapsible, className)}
        innerClassName={classnames(style.box, innerClassName, {
          [style.mobile]: mobile,
          [style.collapsed]: collapsed,
        })}
      />
    );
  }
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default connectStorage(CollapsibleCard, (storage, props) => {
  if (!props.storageId || props.defaultCollapsed !== undefined) {
    return;
  }
  const previous = storage.getItem(props.storageId) || {};

  return {
    defaultCollapsed:
      props.defaultCollapsed === undefined
        ? previous.defaultCollapsed
        : props.defaultCollapsed,
    onCollapse: (defaultCollapsed) => {
      if (props.storageId) {
        storage.setItem(props.storageId, { defaultCollapsed });
      }

      if (props.onCollapse) {
        props.onCollapse(defaultCollapsed);
      }
    },
  };
});
