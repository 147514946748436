// @flow
import type { ThunkAction } from "core/types";
import { getZoom, setZoom } from "web/layerZoom";

export function getThumbnailZoom(state: *, props: { subject: string }) {
  return getZoom();
}

export function setThumbnailZoom(zoom: number, subject: string): ThunkAction {
  return (dispatch) => setZoom(zoom);
}
