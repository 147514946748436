// @flow
import * as React from "react";
import image from "./images/file.svg";
import Empty from ".";

export default function NoPages(props: {}) {
  return (
    <Empty
      {...props}
      image={image}
      title="File is empty"
      description="This file has no content."
    />
  );
}
