// @flow
import classnames from "classnames";
import * as React from "react";
import ColorSelector from "core/components/ColorSelector";
import Flex from "core/components/Flex";
import InputWrapper from "core/components/InputWrapper";
import TeamAvatar from "core/components/TeamAvatar";
import style from "./style.scss";

type Props = {
  showWrapper?: boolean,
  teamName?: string,
  selectedColor: string,
  onChange: (color: string) => void,
  className?: string,
  desktop: boolean,
};

const defaultName = "Team";
const teamPalette = [
  "#7080D4",
  "#7AC4E5",
  "#00BE89",
  "#FF423D",
  "#FB8049",
  "#8F9FA5",
  "#F3B11B",
  "#C65AD7",
  "#61B425",
  "#55A3D9",
  "#C89A65",
];

export default function TeamColorSelector(props: Props) {
  const selector = (
    <Flex
      className={classnames({
        [style.wrapper]: true,
        [style.wrapBorder]: props.showWrapper,
      })}
    >
      {props.desktop && (
        <TeamAvatar
          teamColor={props.selectedColor}
          teamName={props.teamName || defaultName}
          size={56}
          className={style.avatar}
          showBorder={false}
          qaSelector="team-avatar"
        />
      )}
      <ColorSelector
        palette={teamPalette}
        defaultValue={props.selectedColor}
        noLines
        containerClass={style.selector}
        onChange={props.onChange}
      />
    </Flex>
  );

  if (props.showWrapper) {
    return (
      <InputWrapper label="Team color" className={props.className}>
        {selector}
      </InputWrapper>
    );
  } else {
    return selector;
  }
}
