// @flow
import empty from "empty";
import * as React from "react";
import { FixedSizeList } from "react-window";
import type { InnerListProps } from ".";

type Props = {
  itemSize: number,
  height: number,
  width: number,
  overscanCount: number,
  rowClassName?: string,
  items: Object[],
  resizeProps?: Object,
  children: (Object) => ?React.Node,
  groupChildren?: boolean,
  innerElementType?: (InnerListProps) => React.Node,
  scrollRef?: React.Ref<>,
  innerRef?: React.Ref<>,
  onItemsRendered?: ({
    visibleStartIndex: number,
    visibleStopIndex: number,
  }) => mixed,
};

export default class FixedList extends React.Component<Props> {
  reactWindowListRef = React.createRef<FixedSizeList>();
  rows: Object[] = this.props.items;

  static defaultProps = {
    overscanCount: 10,
    itemSize: 40,
  };

  getLastRowStyle = () => {
    const lastRowIndex = this.rows.length - 1;
    const list = this.reactWindowListRef.current;
    return list ? list._getItemStyle(lastRowIndex) : empty.object;
  };

  getFirstVisibleRowData = (props: InnerListProps) => {
    return undefined;
  };

  getGroupedChildren = (props: InnerListProps) => {
    return props.children;
  };

  resize = (index: number = 0) => {
    const list = this.reactWindowListRef.current;
    if (list) {
      list.resetAfterIndex(index);
    }
  };

  renderRow = ({ index, style }: { index: number, style: Object }) => {
    return (
      <div style={style} className={this.props.rowClassName}>
        {this.props.children(this.props.items[index])}
      </div>
    );
  };

  render() {
    return (
      <FixedSizeList
        ref={this.reactWindowListRef}
        height={this.props.height}
        width={this.props.width}
        overscanCount={this.props.overscanCount}
        itemCount={this.props.items.length}
        itemSize={this.props.itemSize}
        innerElementType={this.props.innerElementType}
        onItemsRendered={this.props.onItemsRendered}
        innerRef={this.props.innerRef}
        outerRef={this.props.scrollRef}
      >
        {this.renderRow}
      </FixedSizeList>
    );
  }
}
