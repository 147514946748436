// @flow
import { connect } from "react-redux";
import type { State } from "core/types";
import { ConfigurationFetchRequest } from "web/requests/sessions";

function mapStateToProps(state: State) {
  return {
    isLoading: ConfigurationFetchRequest.isLoadingStrict(state, {}),
  };
}

export default (ShareLinkSignin: *) => {
  /* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
   * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
  return connect(mapStateToProps)(ShareLinkSignin);
};
