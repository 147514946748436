// @flow
import * as React from "react";
import { connect } from "react-redux";
import { focusedElement } from "core/actions/navigation";
import { getActiveElement } from "core/selectors/navigation";
import type { State, Dispatch } from "core/types";
import type {
  DefaultProps,
  OwnProps,
  StateProps,
  DispatchProps,
  Props,
} from ".";

type Config = React.Config<Props, DefaultProps>;
type OwnConfig = React.Config<OwnProps, DefaultProps>;

function mapStateToProps(state: State, props: OwnConfig): StateProps {
  return {
    activeElement: getActiveElement(state),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnConfig
): DispatchProps {
  return {
    focusedElement(elementId) {
      dispatch(focusedElement(elementId));
    },
  };
}

export default connect<
  Config,
  OwnConfig,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
