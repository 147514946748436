// @flow
import * as React from "react";
import PlaceholderText from "core/components/PlaceholderText";
import { duration, dateTime, fromNow } from "../../lib/dates";
import Popover from "../Popover";
import style from "./style.scss";

type Props = {
  children?: React.Node | ((timestamp: string) => React.Node),
  date: string | Date,
  end?: string | Date,
  strict?: boolean,
  useShorthand?: boolean,
  tooltipDelay?: number,
};

type State = { end: string | Date };

export default class Time extends React.Component<Props, State> {
  state = { end: "" };

  componentDidMount() {
    this.update();
    addComponent(this);
  }

  componentWillUnmount() {
    removeComponent(this);
  }

  update = (now: Date = new Date()) => {
    // If an end date is already defined then update is a no-op
    if (this.props.end) {
      return;
    }

    // If we don't need granular/strict time then only update once per minute
    if (!this.props.strict && now.getSeconds() !== 0) {
      return;
    }

    // Otherwise update and trigger a render
    this.setState({ end: now });
  };

  get label(): string | React.Node {
    const { children, date, end, strict, useShorthand } = this.props;
    const timestamp =
      strict || end
        ? duration(end || this.state.end, date, useShorthand, {
            addSuffix: !this.props.end,
          })
        : fromNow(date, useShorthand);

    if (children) {
      return typeof children === "function" ? children(timestamp) : children;
    }

    return timestamp;
  }

  render() {
    const { date, children, end, strict, useShorthand, tooltipDelay, ...rest } =
      this.props;

    return (
      <Popover
        placement="bottom"
        label={dateTime(date)}
        delayShow={tooltipDelay}
      >
        <time
          className={style.time}
          dateTime={date}
          data-qa="time-meta"
          {...rest}
        >
          {this.label}
        </time>
      </Popover>
    );
  }
}

const components: Array<Time> = [];
let interval = undefined;

/**
 * updateComponents 'ticks' all of the Time components that are mounted
 */
function updateComponents() {
  const now = new Date();
  for (const component of components) {
    component.update(now);
  }
}

/**
 * adds a Time component to the list which will have it's update method
 * called once per second. Starts an interval if none existing.
 */
function addComponent(component) {
  components.push(component);

  if (!interval) {
    interval = setInterval(updateComponents, 1000);
  }
}

/**
 * removes a Time component from the list which will have it's update method
 * called once per second. Stops the interval if this is the last component.
 */
function removeComponent(component) {
  const index = components.indexOf(component);
  if (index > -1) {
    components.splice(index, 1);
  }

  if (components.length === 0 && interval !== undefined) {
    clearInterval(interval);
    interval = undefined;
  }
}

export function Placeholder() {
  return (
    <span className={style.time}>
      <PlaceholderText min={15} max={20} />
    </span>
  );
}
