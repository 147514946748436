// @flow
import empty from "empty";
import invariant from "invariant";
import { filter, find } from "lodash";
import { showToast } from "core/actions/toasts";
import { BranchFetchRequest } from "core/requests/branches";
import {
  CollectionUpdateRequest,
  CollectionFetchRequest,
} from "core/requests/collections";
import { getBranchHead } from "core/selectors/branches";
import { getMultiSelectedEntities } from "core/selectors/collectionMultiselect";
import {
  getCollection,
  getCollectionLayersForProject,
  getLayerForCollectionLayer,
} from "core/selectors/collections";
import type { BranchCollectionDescriptor, ThunkAction } from "core/types";

export function publishCollection(collectionId: string): ThunkAction {
  return (dispatch, getState) => {
    const collection = getCollection(getState(), { collectionId });

    if (!collection) {
      throw new Error("can't publish a collection that isn't loaded");
    }

    return dispatch(
      CollectionUpdateRequest.perform({
        params: { ...collection, published: true },
        onSuccess: () => {
          dispatch(showToast({ text: `${collection.name} published` }));
        },
      })
    );
  };
}

export function loadCollection(
  collectionId: string,
  projectId: string
): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState();
    const collection = getCollection(state, { collectionId });
    if (!collection) {
      return dispatch(
        CollectionFetchRequest.perform({
          force: true,
          params: { collectionId, projectId },
        })
      );
    }

    await dispatch(
      BranchFetchRequest.perform({
        params: { projectId, branchId: collection.branchId },
      })
    );
    const head = getBranchHead(state, {
      projectId,
      branchId: collection.branchId,
    });
    invariant(head, "Branch head required for Collection.onLoad");

    return dispatch(
      CollectionFetchRequest.perform({
        force: collection.branchHead !== head,
        params: { collectionId, projectId },
      })
    );
  };
}

export function validateCollectionSelection(
  branchCollectionDescriptor: BranchCollectionDescriptor
): ThunkAction {
  return (_, getState) => {
    const state = getState();
    const collectionLayers = getCollectionLayersForProject(
      state,
      branchCollectionDescriptor
    );

    const selected = getMultiSelectedEntities(
      state,
      branchCollectionDescriptor
    );

    const selectedLayers = selected ? selected.layers : empty.object;
    const duplicates = filter(selectedLayers, (selectedLayer) =>
      find(collectionLayers, (collectionLayer) => {
        if (selectedLayer.id !== collectionLayer.layerId) {
          // Ignore duplicate if layer id does not match
          return false;
        }

        if (
          collectionLayer.collectionId !==
          branchCollectionDescriptor.collectionId
        ) {
          // Ignore duplicate if collection layer is in another collection
          return false;
        }

        if (collectionLayer.useLatestCommit) {
          const layer = getLayerForCollectionLayer(state, {
            collectionLayerId: collectionLayer.id,
          });

          invariant(
            layer,
            "Layer should be loaded for validateCollectionSelection"
          );

          // Find duplicate if the collection layer's latest sha matches
          // the selected layer's latest sha
          return layer.lastChangedAtSha === selectedLayer.lastChangedAtSha;
        }

        // Find duplicate if the collection layer's original sha matches
        // the selected layer's latest sha
        return collectionLayer.sha === selectedLayer.lastChangedAtSha;
      })
    );

    return { duplicates };
  };
}
