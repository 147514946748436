// @flow
import classnames from "classnames";
import * as React from "react";
import { Helmet } from "react-helmet";
import BrandHeader from "core/components/BrandHeader";
import Flex from "core/components/Flex";
import LogoHeader from "core/components/LogoHeader";
import StepProgressBar from "core/components/StepProgressBar";
import type { Invitation } from "web/types";
import OnboardingIcon, { type AvailableIcons } from "./OnboardingIcon";
import style from "./style.scss";

type Props = {|
  children?: React.Node,
  subtitle?: string,
  boldSubtitle?: boolean,
  subtitleWrap?: boolean,
  headingExtra?: React.Element<*>,
  documentTitle?: string,
  innerContentClassName?: string,
  icon?: AvailableIcons,
  heading?: React.Node,
  invitation?: Invitation,
  fullHeight?: boolean,
  hideLogo?: boolean,
  hideHeader?: boolean,
  organizationLogo?: React.Node,
  totalSteps?: number,
  currentStep?: number,
  dottedLines?: number | number[],
  footerLink?: React.Node,
  showSplatter?: boolean,
|};

export default function OnboardingPage({
  children,
  documentTitle,
  icon,
  heading,
  subtitle,
  boldSubtitle,
  subtitleWrap,
  headingExtra,
  innerContentClassName,
  invitation,
  hideLogo,
  hideHeader,
  fullHeight,
  organizationLogo,
  totalSteps,
  currentStep,
  dottedLines,
  footerLink,
  showSplatter,
}: Props) {
  const showSteps = !!totalSteps && currentStep !== undefined;
  const showTopHeader = !hideLogo || showSteps;

  const headingText = typeof heading === "string" ? heading : undefined;
  const centerWrapClass = fullHeight ? style.fullHeight : style.centerWrap;

  return (
    <div className={style.wrap}>
      <div className={style.page}>
        <Helmet>
          <title>{documentTitle || headingText}</title>
        </Helmet>
        <div
          className={classnames(style.content, {
            [style.fullContentHeight]: fullHeight,
          })}
        >
          {showTopHeader && (
            <Flex
              align="center"
              className={classnames(style.header, {
                [style.logoSpacer]: !hideLogo && showSteps,
              })}
              grow={false}
              shrink={false}
            >
              {!hideLogo && <LogoHeader />}
              {!!totalSteps && currentStep !== undefined && (
                <Flex align="center" justify="center" grow>
                  <StepProgressBar
                    className={style.progressIndicator}
                    currentStep={currentStep}
                    totalSteps={totalSteps}
                    dottedLines={dottedLines}
                  />
                </Flex>
              )}
            </Flex>
          )}
          <div
            className={classnames(style.innerContent, {
              [style.splatter]: showSplatter,
            })}
          >
            <div className={centerWrapClass}>
              {organizationLogo && (
                <Flex
                  justify="center"
                  className={style.organizationLogoWrapper}
                >
                  {organizationLogo}
                </Flex>
              )}
              {icon && <OnboardingIcon className={style.icon} icon={icon} />}
              {!!heading && !hideHeader && (
                <BrandHeader
                  title={heading}
                  subtitle={subtitle}
                  boldSubtitle={boldSubtitle}
                  subtitleClass={classnames({ [style.subtitle]: subtitleWrap })}
                  extra={headingExtra}
                />
              )}
              <div
                className={classnames(
                  style.innerInnerContent,
                  innerContentClassName
                )}
              >
                {children}
              </div>
            </div>
          </div>
          {footerLink && (
            <div className={style.footerLinkWrapper}>{footerLink}</div>
          )}
        </div>
      </div>
    </div>
  );
}
