// @flow
import { useSelector } from "react-redux";
import { useCurrentUserId } from "core/hooks/users";
import { OrganizationReviewsFetchRequest } from "core/requests/reviews";
import {
  getOrganizationBranchReviewsForUser,
  getBranchReviewsForOrganization,
  getReviewRequests,
} from "core/selectors/reviews";

export const useBranchReviews = ({
  organizationId,
  reviewerId,
  projectId,
}: {
  organizationId: string,
  reviewerId?: string,
  projectId?: string,
}) => {
  const loading = useSelector((state) =>
    OrganizationReviewsFetchRequest.isFirstLoading(state, {
      organizationId,
    })
  );

  const branchReviews = useSelector((state) =>
    getBranchReviewsForOrganization(state, {
      organizationId,
      reviewerId,
      projectId,
    })
  );

  return { loading, branchReviews };
};

export const useReviewRequests = ({ projectId }: { projectId: string }) =>
  useSelector((state) => getReviewRequests(state, { projectId }));

export const useBranchesReviewRequestsCount = (organizationId: ?string) => {
  const currentUserId = useCurrentUserId();

  const count = useSelector((state) => {
    if (!organizationId) {
      return undefined;
    }

    return getOrganizationBranchReviewsForUser(state, {
      userId: currentUserId,
      reviewRequestStatus: "REQUESTED",
      organizationId,
    }).length;
  });

  return count;
};
