// @flow
import classnames from "classnames";
import last from "lodash/last";
import * as React from "react";
import { connect } from "react-redux";
import ReferenceComment from "core/components/Comment/ReferenceComment";
import CommentCreateForm from "core/components/CommentCreateForm";
import CommentsList from "core/components/CommentsList";
import Heading from "core/components/Heading";
import createConnector from "core/lib/createConnector";
import { withRouter } from "core/lib/router";
import { getFilteredComments } from "core/selectors/comments";
import type { Comment, State, ReactRouterLocation } from "core/types";
import style from "./comments.scss";

type LocationProps = {|
  location: ReactRouterLocation,
|};

type OwnProps = {|
  projectId: string,
  branchId: string,
  commitSha: string,
  scrollToBottom: () => void,
  autoMarkAsRead: boolean,
|};

type StateProps = {|
  comments: Comment[],
|};

type Props = {
  ...OwnProps,
  ...StateProps,
  ...LocationProps,
};

class Comments extends React.Component<Props> {
  componentDidUpdate(prevProps: Props) {
    /*
      If there is a new comment and it is pending then we know that the
      user authored it
    */
    if (prevProps.comments.length < this.props.comments.length) {
      const latest = last(this.props.comments);

      if (latest.pending && !latest.error) {
        this.props.scrollToBottom();
      }
    }
  }

  render() {
    const { projectId, branchId, commitSha, comments } = this.props;

    const hasComments = comments.length > 0;

    return (
      <div className={style.container}>
        <Heading level="3" size="s" className={style.heading}>
          Comments
        </Heading>
        {hasComments && (
          <CommentsList
            comments={comments}
            projectId={projectId}
            branchId={branchId}
            sha={commitSha}
            className={style.comments}
            autoMarkAsRead={this.props.autoMarkAsRead}
            highlightedCommentId={
              (this.props.location.query &&
                this.props.location.query.commentId) ||
              undefined
            }
            commentComponent={ReferenceComment}
          />
        )}
        <div
          className={classnames(style.commentForm, {
            [style.hasComments]: hasComments,
          })}
        >
          <CommentCreateForm
            flat
            defaultFocus
            projectId={projectId}
            branchId={branchId}
            sha={commitSha}
            className={style.createCommentForm}
            renderActionsInsideTextarea
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  return {
    comments: getFilteredComments(state, {
      projectId: props.projectId,
      branchId: props.branchId,
      commitSha: props.commitSha,
    }),
  };
}

export default createConnector<Props, OwnProps>(
  withRouter,
  connect(mapStateToProps)
)(Comments);
