// @flow
import classnames from "classnames";
import pluralize from "pluralize";
import * as React from "react";
import Flex from "core/components/Flex";
import Heading from "core/components/Heading";
import Icon from "core/components/Icon";
import MultiSelectButton from "core/components/MultiSelectButton";
import style from "./style.scss";

type Props = {
  label: string,
  icon?: string,
  itemCount?: number,
  canSelect?: boolean,
  isOpen: boolean,
  isSelected?: boolean,
  isSelecting?: boolean,
  isPartiallySelected?: boolean,
  onSelect?: (event: SyntheticMouseEvent<>) => void,
  onToggleCollapse?: (event: SyntheticEvent<>) => void,
};

export default function SectionHeader({
  isOpen,
  isSelecting,
  isSelected,
  isPartiallySelected,
  canSelect,
  onToggleCollapse,
  onSelect,
  itemCount,
  label,
  icon = "file-page",
}: Props) {
  return (
    <Flex
      align="center"
      justify="space-between"
      className={style.section}
      onClick={onToggleCollapse}
    >
      <Heading level="3" size="m" className={style.heading}>
        <Icon
          type="disclosure-expanded"
          className={classnames(style.disclosure, {
            [style.collapsed]: !isOpen,
          })}
        />
        {isSelecting && (
          <MultiSelectButton
            small
            disabled={!canSelect}
            selected={canSelect ? !!isSelected : false}
            onSelect={onSelect}
            partiallySelected={!!isPartiallySelected}
            className={style.selectButton}
          />
        )}
        <Icon className={style.icon} type={icon} /> {label}
      </Heading>
      {itemCount !== undefined && (
        <span className={style.itemCount}>
          {itemCount} {pluralize("item", itemCount)}
        </span>
      )}
    </Flex>
  );
}
