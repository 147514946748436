// @flow
import classnames from "classnames";
import * as React from "react";
import style from "./page.scss";

export default function BranchPage(props: {
  children?: any,
  className?: string,
}) {
  return (
    <div className={classnames(style.page, props.className)}>
      <div className={style.pageInner}>{props.children}</div>
    </div>
  );
}
