// @flow
import { connect } from "react-redux";
import connectStorage from "core/hocs/connectStorage";
import createConnector from "core/lib/createConnector";
import { DEFAULT_ORGANIZATIONPEOPLE_VIEW } from "core/lib/lists";
import { organizationPeopleOptions } from "core/lib/people";
import { OrganizationMembershipsRequest } from "core/requests/memberships";
import { SubscriptionFetchRequest } from "core/requests/seats";
import {
  enterpriseTeamsEnabled,
  teamsAnnouncementEnabled,
} from "core/selectors/features";
import { getMembershipEntities } from "core/selectors/memberships";
import { getOrganization } from "core/selectors/organizations";
import { getOrganizationPolicy } from "core/selectors/policies";
import { getSubscription } from "core/selectors/subscriptions";
import type { Dispatch, State, ViewType } from "core/types";
import type {
  Props,
  PropsWithoutStorage,
  OwnProps,
  StateProps,
  DispatchProps,
  StorageProps,
} from "./index.js";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const peopleOptions = organizationPeopleOptions(props.location);
  const { roleFilter, search: searchFilter } = peopleOptions;

  return {
    policy: getOrganizationPolicy(state, props.params),
    memberships: getMembershipEntities(state),
    organization: getOrganization(state, props.params),
    error: OrganizationMembershipsRequest.hasError(state, props.params),
    subscription: getSubscription(state, props.params.organizationId),
    searchFilter,
    roleFilter,
    canUseTeams: enterpriseTeamsEnabled(state, {
      organizationId: props.params.organizationId,
    }),
    isTeamsAnnouncementEnabled: teamsAnnouncementEnabled(
      state,
      props.params.organizationId
    ),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    onLoad: () =>
      dispatch((dispatch, getState) => {
        const policy = getOrganizationPolicy(getState(), props.params);

        if (policy.removeMember) {
          dispatch(
            SubscriptionFetchRequest.perform({
              params: props.params,
            })
          );
        }
      }),
  };
}

function mapStorageToProps(storage, props: OwnProps): StorageProps {
  const storageId = `OrganizationPeople-${props.params.organizationId}`;
  const previous = storage.getItem(storageId) || {};

  return {
    viewType: previous.peopleView || DEFAULT_ORGANIZATIONPEOPLE_VIEW,
    onChangeViewType: (peopleView: ViewType) => {
      storage.setItem(storageId, { ...previous, peopleView });
    },
  };
}

export default createConnector<Props, OwnProps>(
  (Component) => connectStorage(Component, mapStorageToProps),
  connect<
    PropsWithoutStorage,
    OwnProps,
    StateProps,
    DispatchProps,
    State,
    Dispatch,
  >(mapStateToProps, mapDispatchToProps)
);
