// @flow
import * as React from "react";
import Button from "core/components/Button";
import FormSection from "core/components/FormSection";
import Input from "core/components/Input";
import InputSwitch from "core/components/InputSwitch";
import Note from "core/components/Note";
import SettingsItem from "core/components/SettingsItem";
import SettingsItemInputNote from "core/components/SettingsItemInputNote";
import withForm from "web/containers/withForm";
import type { FormProps } from "web/types";
import style from "./style.scss";

type OwnProps = {
  onSubmit: (
    formId: string,
    organizationId: string,
    values: Object
  ) => Promise<void>,
  organizationId: string,
  restrictedToDomain?: string,
};

type Props = {
  ...OwnProps,
  ...FormProps,
};

type State = {
  isRestricted: boolean,
};

class DomainRestrictions extends React.Component<Props, State> {
  domainInput: ?{ input: HTMLInputElement };

  static defaultProps = {
    restrictedToDomain: "",
  };

  state = {
    isRestricted: this.props.restrictedToDomain !== "",
  };

  handleChange = (event: SyntheticInputEvent<>) => {
    this.props.form.onChange(this.props.form.id, {
      [event.target.name]: event.target.value,
    });
  };

  handleRestrictionChange = (event) => {
    this.setState({ isRestricted: event.target.checked }, () => {
      if (this.state.isRestricted && this.domainInput) {
        this.domainInput.input.focus();
      }
    });

    this.props.form.onChange(this.props.form.id, {
      restrictedToDomain: "",
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { restrictedToDomain } = this.props.form.values;

    if (!restrictedToDomain) {
      this.setState({ isRestricted: false });
    }

    this.props.onSubmit(
      this.props.form.id,
      this.props.organizationId,
      this.props.form.values
    );
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <FormSection heading="Email Restrictions">
          <SettingsItem>
            <InputSwitch
              checked={this.state.isRestricted}
              label={
                <React.Fragment>
                  Restrict user emails to specific domains
                  <Note component="div" className={style.note}>
                    Members of your organization will be limited to inviting
                    teammates from the domains listed below. Admins, however,
                    can always invite people from any domain.
                  </Note>
                </React.Fragment>
              }
              name="isRestricted"
              onChange={this.handleRestrictionChange}
              responsive
            />
          </SettingsItem>
          <SettingsItem input>
            <Input
              responsive
              requiredTag
              ref={(r) => (this.domainInput = r)}
              disabled={!this.state.isRestricted}
              error={this.props.form.errors.restrictedToDomain}
              label="Approved domains"
              name="restrictedToDomain"
              onChange={this.handleChange}
              placeholder="domain.com, domain.com"
              value={this.props.form.values.restrictedToDomain || ""}
            />
            <SettingsItemInputNote>
              Use a comma-separated list to approve more than one domain.
            </SettingsItemInputNote>
          </SettingsItem>
          <SettingsItem className={style.submit}>
            <Button
              disabled={
                !this.props.form.isDirty || this.props.form.isSubmitting
              }
              onClick={this.handleSubmit}
              primary
              type={"submit"}
            >
              {this.props.form.isSubmitting ? "Saving…" : "Save changes"}
            </Button>
          </SettingsItem>
        </FormSection>
      </form>
    );
  }
}

function getDefaultValues(props: Props) {
  return {
    restrictedToDomain: props.restrictedToDomain || "",
  };
}
export default withForm<OwnProps>(
  DomainRestrictions,
  (props) => `${props.organizationId}-domain-restriction-form`,
  getDefaultValues
);
