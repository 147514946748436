// @flow
import { trackEvent } from "core/actions/analytics";
import { entitiesReceived } from "core/actions/entities";
import { request } from "core/actions/requests";
import { showToast } from "core/actions/toasts";
import { ValidationError } from "core/api";
import { normalizeOrganizationSettings } from "core/schemas/organizationSettings";
import type { SSOConfig } from "core/types";
import type { ThunkAction } from "web/actions";
import {
  updateOrganizationSSOSettings,
  updateOrganizationSettings,
} from "web/api";
import type { FormValues } from "web/models/form";

export function organizationSettingsFormSubmit(
  id: string,
  organizationId: string,
  values: FormValues
): ThunkAction {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(
        request(id, () => updateOrganizationSettings(organizationId, values), {
          onSuccess: (settings) => {
            const { entities } = normalizeOrganizationSettings(settings);
            dispatch(entitiesReceived(entities));
            dispatch(
              trackEvent("ORGANIZATION_DETAILS_UPDATED", {
                organizationId,
                ...values,
              })
            );
            resolve(true);
          },
          onError: (error) => {
            reject(false);
          },
        })
      );
    });
  };
}

export function organizationSSOFormSubmit(
  id: string,
  organizationId: string,
  values: SSOConfig
): ThunkAction {
  return async (dispatch) => {
    dispatch(
      request(id, () => updateOrganizationSSOSettings(organizationId, values), {
        onSuccess: (settings) => {
          const { entities } = normalizeOrganizationSettings(settings);
          dispatch(entitiesReceived(entities));
          dispatch(showToast({ text: "SSO settings saved" }));
        },
        onError: (error) => {
          // Show a toast for non-validation errors
          if (!(error instanceof ValidationError)) {
            dispatch(showToast({ text: error.message }));
          }
        },
      })
    );
  };
}
