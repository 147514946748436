// @flow
import * as React from "react";
import BranchList from "core/components/BranchList";
import Empty from "core/components/Empty";
import Expand from "core/components/Expand";
import Loaded from "core/components/Loaded";
import OverviewCard from "core/components/ProjectOverview/OverviewCard";
import { branchPath, projectPath } from "core/lib/routes";
import type { Project, BranchHierarchy, ReviewRequest } from "core/types";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  mobile?: boolean,
  project: Project,
|};

export type StateProps = {|
  branches: BranchHierarchy[],
  isLoading: boolean,
  reviewRequests: ReviewRequest[],
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
};

type State = {
  expanded: boolean,
};

class ActiveBranches extends React.Component<Props, State> {
  maxBranches = 4;

  state = {
    expanded: false,
  };

  toggleExpanded = () => {
    this.setState((prevState) => ({
      expanded: !prevState.expanded,
    }));
  };

  render() {
    const numberOfBranches = this.props.branches.length;
    const noBranches = numberOfBranches === 0;

    const branchesToShow = this.state.expanded
      ? this.props.branches
      : this.props.branches.slice(0, this.maxBranches);

    const listLoading = this.props.isLoading && noBranches;
    const showExpand = numberOfBranches > this.maxBranches;

    return (
      <OverviewCard
        hasFooter={showExpand}
        hasList={!listLoading && !noBranches}
        heading="Active Branches"
        mobile={this.props.mobile}
        storageId="ProjectOverview-ActiveBranches"
        to={projectPath(this.props.project.id, "branches")}
        noPadding
      >
        <Loaded loading={listLoading} small>
          {() =>
            noBranches ? (
              <Empty
                className={style.noBranches}
                description={
                  <React.Fragment>
                    There isn’t any ongoing work in this project.
                    <br />
                    Create a branch to start making changes.
                  </React.Fragment>
                }
                small
              />
            ) : (
              <React.Fragment>
                <BranchList
                  flat
                  mobile={this.props.mobile}
                  project={this.props.project}
                  branches={branchesToShow}
                  branchPath={branchPath}
                  reviewRequests={this.props.reviewRequests}
                  hasHierarchy
                />
                {showExpand && (
                  <Expand
                    hideBorder
                    onClick={this.toggleExpanded}
                    expanded={this.state.expanded}
                    className={style.isContained}
                  >
                    Showing {this.maxBranches} of {numberOfBranches} Branches
                  </Expand>
                )}
              </React.Fragment>
            )
          }
        </Loaded>
      </OverviewCard>
    );
  }
}

export default connector(ActiveBranches);
