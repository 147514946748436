// @flow
import * as React from "react";
import BranchArchived from "./BranchArchived";
import BranchCreated from "./BranchCreated";
import BranchDeleted from "./BranchDeleted";
import BranchDescriptionUpdated from "./BranchDescriptionUpdated";
import BranchRenamed from "./BranchRenamed";
import BranchStatusUpdated from "./BranchStatusUpdated";
import BranchUnarchived from "./BranchUnarchived";
import CollectionPublished from "./CollectionPublished";
import CommentCreated from "./CommentCreated";
import Commit from "./Commit";
import MergeCommit from "./MergeCommit";
import ProjectArchived from "./ProjectArchived";
import ProjectCreated from "./ProjectCreated";
import ProjectDeleted from "./ProjectDeleted";
import ProjectDescriptionChanged from "./ProjectDescriptionChanged";
import ProjectRenamed from "./ProjectRenamed";
import ProjectTransferred from "./ProjectTransferred";
import ProjectUnarchived from "./ProjectUnarchived";
import ReviewCompleted from "./ReviewCompleted";
import ReviewDismissed from "./ReviewDismissed";
import ReviewRequested from "./ReviewRequested";
import ReviewerRemoved from "./ReviewerRemoved";
import UpdateCommit from "./UpdateCommit";
import connector from "./connector";
import type { Props } from "./types";

function Activity({
  activity, // local variable needed for flow to check type
  ...rest
}: Props<>) {
  switch (activity.type) {
    case "BRANCH_ARCHIVED":
      return <BranchArchived activity={activity} {...rest} />;
    case "COMMENT_CREATED":
      return <CommentCreated activity={activity} {...rest} />;
    case "BRANCH_CREATED":
      return <BranchCreated activity={activity} {...rest} />;
    case "BRANCH_DELETED":
      return <BranchDeleted activity={activity} {...rest} />;
    case "BRANCH_DESCRIPTION_UPDATED":
      return <BranchDescriptionUpdated activity={activity} {...rest} />;
    case "BRANCH_RENAMED":
      return <BranchRenamed activity={activity} {...rest} />;
    case "BRANCH_STATUS_UPDATED":
      return <BranchStatusUpdated activity={activity} {...rest} />;
    case "BRANCH_UNARCHIVED":
      return <BranchUnarchived activity={activity} {...rest} />;
    case "COLLECTION_PUBLISHED":
      return <CollectionPublished activity={activity} {...rest} />;
    case "COMMIT":
      return <Commit activity={activity} {...rest} />;
    case "MERGE_COMMIT":
      return <MergeCommit activity={activity} {...rest} />;
    case "UPDATE_COMMIT":
      return <UpdateCommit activity={activity} {...rest} />;
    case "PROJECT_ARCHIVED":
      return <ProjectArchived activity={activity} {...rest} />;
    case "PROJECT_CREATED":
      return <ProjectCreated activity={activity} {...rest} />;
    case "PROJECT_DELETED":
      return <ProjectDeleted activity={activity} {...rest} />;
    case "PROJECT_DESCRIPTION_CHANGED":
      return <ProjectDescriptionChanged activity={activity} {...rest} />;
    case "PROJECT_RENAMED":
      return <ProjectRenamed activity={activity} {...rest} />;
    case "PROJECT_UNARCHIVED":
      return <ProjectUnarchived activity={activity} {...rest} />;
    case "PROJECT_TRANSFERRED":
      return <ProjectTransferred activity={activity} {...rest} />;
    case "REVIEW_REQUESTED":
      return <ReviewRequested activity={activity} {...rest} />;
    case "REVIEW_DISMISSED":
      return <ReviewDismissed activity={activity} {...rest} />;
    case "REVIEW_COMPLETED":
      return <ReviewCompleted activity={activity} {...rest} />;
    case "REVIEWER_REMOVED":
      return <ReviewerRemoved activity={activity} {...rest} />;
    default:
      return null;
  }
}

export default connector(Activity);
