// @flow
import app from "core/reducers/app";
import collectionMultiselect from "core/reducers/collectionMultiselect";
import commitsPagination from "core/reducers/commitsPagination";
import createEntityReducer from "core/reducers/createEntityReducer";
import devtools from "core/reducers/devtools";
import draftBranches from "core/reducers/draftBranches";
import entities from "core/reducers/entities";
import entityMappings from "core/reducers/entityMappings";
import lastVisited from "core/reducers/lastVisited";
import navigation from "core/reducers/navigation";
import notifications from "core/reducers/notifications";
import notificationsMeta from "core/reducers/notificationsMeta";
import organizationSettings from "core/reducers/organizationSettings";
import pagination from "core/reducers/pagination";
import paginationTotals from "core/reducers/paginationTotals";
import previewGeneration from "core/reducers/previewGeneration";
import quickJump from "core/reducers/quickJump";
import recurly from "core/reducers/recurly";
import requests from "core/reducers/requests";
import stars from "core/reducers/stars";
import toasts from "core/reducers/toasts";
import type {
  Organization,
  Section,
  Branch,
  Commit,
  User,
  Features,
  UserMetrics,
  OrganizationMetrics,
  Project,
} from "core/types";
import forms from "./forms";
import invitation from "./invitation";
import login from "./login";
import resetPassword from "./resetPassword";
import session from "./session";
import sidebar from "./sidebar";

const reducers = {
  app,
  collectionMultiselect,
  commitsPagination,
  devtools,
  draftBranches,
  entities,
  entityMappings,
  forms,
  invitation,
  navigation,
  notifications,
  notificationsMeta,
  lastVisited,
  login,
  organizationSettings,
  pagination,
  paginationTotals,
  quickJump,
  requests,
  resetPassword,
  session,
  sidebar,
  stars,
  toasts,
  features: createEntityReducer<Features>("features"),
  organizations: createEntityReducer<Organization>("organizations"),
  commits: createEntityReducer<Commit>("commits"),
  branches: createEntityReducer<Branch>("branches"),
  localBranches: createEntityReducer<Branch>("localBranches"),
  projects: createEntityReducer<Project>("projects"),
  sections: createEntityReducer<Section>("sections"),
  users: createEntityReducer<User>("users"),
  organizationMetrics: createEntityReducer<OrganizationMetrics>(
    "organizationMetrics"
  ),
  userMetrics: createEntityReducer<UserMetrics>("userMetrics"),
  previewGeneration,
  recurly,
};

export type Reducers = typeof reducers;

export default reducers;
