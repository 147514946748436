// @flow
import { connect } from "react-redux";
import type { State } from "core/types";
import { passwordFormSubmit } from "web/actions/passwordForm";
import PasswordEdit from "web/components/ProfileSettings/PasswordEdit";
import { getCurrentUser } from "web/selectors/session";

function mapStateToProps(state: State) {
  return {
    account: getCurrentUser(state),
  };
}

const actions = {
  onSubmit: passwordFormSubmit,
};

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default connect(mapStateToProps, actions)(PasswordEdit);
