// @flow
import apiRequest from "abstract-di/api";
import defineRequest from "./";

type ReleaseParams = {};

export const LatestReleaseFetchRequest = defineRequest<
  ReleaseParams,
  ReleaseParams,
>({
  id: () => {
    return `get:releases/latest`;
  },
  perform: () => {
    return apiRequest("get", `releases/latest`);
  },
});
