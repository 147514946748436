// @flow
import pluralize from "pluralize";
import { showToast } from "core/actions/toasts";
import type { ThunkAction } from "core/types";

export function confirmSeatsToast(
  numberOfSeats: number,
  preview: boolean = false
): ThunkAction {
  return (dispatch) => {
    if (!preview) {
      dispatch(
        showToast({
          text: `${numberOfSeats} ${pluralize("seat", numberOfSeats)} added`,
        })
      );
    }
  };
}

export function confirmSeatsRemovedToast(
  numberOfSeats: number,
  preview: boolean = false
): ThunkAction {
  return (dispatch) => {
    if (!preview) {
      dispatch(
        showToast({
          text: `${numberOfSeats} ${pluralize("seat", numberOfSeats)} removed`,
        })
      );
    }
  };
}
