// @flow
import * as React from "react";
import AuthorMeta from "core/components/AuthorMeta";
import type { Collection, Project, User } from "core/types";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  collection: Collection,
|};

export type StateProps = {|
  project: ?Project,
  collectionAuthor: ?User,
|};

type Props = {
  ...OwnProps,
  ...StateProps,
};

export class CollectionInfo extends React.Component<Props> {
  render() {
    return (
      <div className={style.collectionInfo}>
        <div className={style.collectionName}>{this.props.collection.name}</div>
        {this.props.project && (
          <div className={style.projectName}>{this.props.project.name}</div>
        )}
        {this.props.collectionAuthor && (
          <AuthorMeta
            className={style.collectionAuthor}
            avatarClassName={style.authorAvatar}
            user={this.props.collectionAuthor}
          />
        )}
      </div>
    );
  }
}

export default connector(CollectionInfo);
