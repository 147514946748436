// @flow
import * as Abstract from "abstract-sdk";
import { guard, oneOf } from "decoders";
import config from "abstract-di/config";
import { getShareToken } from "core/lib/shareAuthorization";
import type { Layer } from "core/types";

export default new Abstract.Client({
  accessToken: async (): Promise<Abstract.AccessToken> =>
    config.getAccessToken(),
  shareId: async () => getShareToken(),
  apiUrl: config.apiURL,
  previewUrl: config.getPreviewsURL(),
});

export { Abstract };

export function legacyLayer(layer: Abstract.Layer): Layer {
  const { lastChangedIn, type, ...legacyLayer } = layer;

  // The type field comes in as an inexact string, but we guard with
  // a formal enum definition for consistency and safety.
  const typeDecoder = guard(oneOf(["layer", "artboard", "symbol"]));

  return {
    type: typeDecoder(type),
    ...legacyLayer,
  };
}
