// @flow
import { connect } from "react-redux";
import createConnector from "core/lib/createConnector";
import { getProjectFeatureEnabled } from "core/selectors/features";
import type { State } from "core/types";
import type { Props, OwnProps, StateProps } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  return {
    canvasOverlaysEnabled: getProjectFeatureEnabled(state, {
      projectId: props.projectId,
      feature: "canvas-overlays-enabled",
    }),
  };
}

export default createConnector<Props, OwnProps>(connect(mapStateToProps));
