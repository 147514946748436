// @flow
import { connect } from "react-redux";
import {
  getCommentsForCollectionLayer,
  getLayerForCollectionLayer,
  isCollectionLayerRemoved,
  getCollection,
} from "core/selectors/collections";
import { getLayerCommentCountsKey } from "core/selectors/comments";
import type { State } from "core/types";
import type { OwnProps, StateProps } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { collectionLayer, layerCommentCounts } = props;
  const collectionLayerId = collectionLayer.id;
  const layer = getLayerForCollectionLayer(state, { collectionLayerId });
  const collection = getCollection(state, {
    collectionId: collectionLayer.collectionId,
  });

  return {
    layer,
    sha: layer ? layer.lastChangedAtSha : "",
    fileId: collectionLayer.fileId,
    branchId: collection ? collection.branchId : "",
    layerId: layer ? layer.id : "",
    layerName: layer ? layer.name : "",
    commentCount: layerCommentCounts
      ? layerCommentCounts[getLayerCommentCountsKey(collectionLayer)]
      : getCommentsForCollectionLayer(state, { collectionLayerId }).length,
    useLatestCommit: collectionLayer.useLatestCommit,
    removed: isCollectionLayerRemoved(state, { collectionLayerId }),
  };
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default connect(mapStateToProps);
