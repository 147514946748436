// @flow
import { filter } from "lodash";
import { connect } from "react-redux";
import { openSupportWindow } from "abstract-di/actions";
import { transferProject } from "core/actions/projects";
import { OrganizationsFetchRequest } from "core/requests/organizations";
import { TransferProjectRequest } from "core/requests/projects";
import { getAllValidOrganizations } from "core/selectors/organizations";
import { getOrganizationPolicy } from "core/selectors/policies";
import type { State, Dispatch } from "core/types";
import type { OwnProps, StateProps, DispatchProps, Props } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { project } = props;
  const projectId = project.id;
  const organizations = filter(getAllValidOrganizations(state), (org) => {
    const policy = getOrganizationPolicy(state, { organizationId: org.id });
    return org.id !== project.organizationId && policy.transferProjectIn;
  });

  return {
    hasError: TransferProjectRequest.hasError(state, { projectId }),
    loading:
      OrganizationsFetchRequest.isLoadingStrict(state) && !organizations.length,
    organizations,
    processing: TransferProjectRequest.isLoadingStrict(state, { projectId }),
    validationErrors: TransferProjectRequest.validationErrors(state, {
      projectId,
    }),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    onDialogOpen() {
      dispatch(OrganizationsFetchRequest.perform({ params: undefined }));
    },
    onSubmit(organizationId: string) {
      dispatch(transferProject(props.project.id, organizationId));
    },
    openSupportTicket: (organizationId: string) =>
      dispatch(openSupportWindow(organizationId)),
  };
}

export default connect<Props, OwnProps, StateProps, _, State, Dispatch>(
  mapStateToProps,
  mapDispatchToProps
);
