// @flow
import * as Abstract from "abstract-sdk";
import classnames from "classnames";
import * as React from "react";
import { Helmet } from "react-helmet";
import Error from "core/components/Empty/Error";
import Flex from "core/components/Flex";
import Loaded from "core/components/Loaded";
import MainContent from "core/components/MainContent";
import type {
  OrganizationMetrics,
  UserMetrics,
  ReportingFilter,
  ReactTablePagination,
} from "core/types";
import OrganizationReportingHeader from "./Header";
import IndividualMetrics from "./IndividualMetrics";
import Metrics from "./Metrics";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  params: Abstract.OrganizationDescriptor,
  mobile?: boolean,
|};

export type StateProps = {|
  isLoading: boolean,
  hasError: boolean,
  organizationMetrics: ?OrganizationMetrics,
  userMetrics: ?UserMetrics,
|};

export type DispatchProps = {|
  onLoad: () => void,
  onFilter: (filter: ReportingFilter) => void,
  fetchData: (pageData: ReactTablePagination, period?: ReportingFilter) => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

function OrganizationReporting(props: Props) {
  function handlePeriodFilterChange(filter: ReportingFilter) {
    let period =
      (props.organizationMetrics && props.organizationMetrics.meta.period) ||
      "currentMonth";

    // only trigger filter change if filter differs from selected period
    if (period !== filter) {
      props.onFilter(filter);
    }
  }

  function getPeriod(): ReportingFilter {
    if (
      props.organizationMetrics === undefined ||
      props.organizationMetrics === null
    ) {
      return "currentMonth";
    }

    return props.organizationMetrics.meta.period;
  }

  function renderMainContent() {
    if (
      props.organizationMetrics === undefined ||
      props.organizationMetrics === null ||
      props.userMetrics === undefined ||
      props.userMetrics === null
    ) {
      return;
    }

    return (
      <React.Fragment>
        <Metrics mobile={props.mobile} metrics={props.organizationMetrics} />
        <IndividualMetrics
          mobile={props.mobile}
          metrics={props.userMetrics}
          fetchData={props.fetchData}
          period={getPeriod()}
        />
        <span className={style.disclaimer}>
          Metrics from offline activity are calculated once the computer has
          synced.
        </span>
      </React.Fragment>
    );
  }

  return (
    <Loaded loading={props.isLoading} title="Loading reporting" flex>
      <Helmet>Branch Reporting</Helmet>
      <Flex
        className={classnames(style.wrapper, {
          [style.scrollableWrapper]: props.mobile,
        })}
        column
      >
        <OrganizationReportingHeader
          mobile={props.mobile}
          onFilter={handlePeriodFilterChange}
          organizationId={props.params.organizationId}
          period={getPeriod()}
          {...(props.organizationMetrics && props.organizationMetrics.meta)}
        />
        <MainContent
          className={classnames({
            [style.scrollableContent]: !props.mobile,
          })}
        >
          {props.hasError && <Error />}
          {!props.hasError && renderMainContent()}
        </MainContent>
      </Flex>
    </Loaded>
  );
}

export default connector(OrganizationReporting);
