// @flow
import classnames from "classnames";
import * as React from "react";
import { Waypoint } from "react-waypoint";
import NoActivity from "abstract-di/components/NoActivity";
import ActivityList from "core/components/ActivityList";
import Breakpoint from "core/components/Breakpoint";
import Offline from "core/components/Empty/Offline";
import Flex from "core/components/Flex";
import Loaded from "core/components/Loaded";
import ActivitiesLoader from "core/containers/ActivitiesLoader";
import { addQuery, removeQuery, replace } from "core/lib/location";
import type { ActivityContext, Branch } from "core/types";
import ProjectActivityHeader from "./ProjectActivityHeader";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  mobile?: boolean,
  className?: string,
  branchId?: string,
  params: { projectId: string },
  location: {
    query: { branchId?: string },
  },
|};

export type StateProps = {|
  branch: ?Branch,
  branches: Branch[],
  branchId?: string,
  projectId: string,
  organizationId: string,
|};

type Props = { ...OwnProps, ...StateProps };

class ProjectActivities extends React.Component<Props> {
  /*
   * This is the event handler for the older header drop down change.
   * Remove this when we remove the older header version
   */
  handleBranchChange = (event: SyntheticInputEvent<>) => {
    const branchId = event.target.value;
    if (branchId && branchId !== this.props.location.query.branchId) {
      replace(addQuery({ branchId }));
    } else {
      // Clear the branch filter, when the same branch is selected
      replace(removeQuery("branchId"));
    }
  };

  get activityContext(): ActivityContext {
    return { projectId: this.props.projectId, branchId: this.props.branchId };
  }

  render() {
    const {
      projectId,
      branch,
      branchId,
      organizationId,
      branches,
      className,
      mobile,
    } = this.props;

    return (
      <ActivitiesLoader
        organizationId={organizationId}
        projectId={projectId}
        branchId={branchId}
      >
        {({ activityIds, isLoading, isLoadingMore, onLoadMore, isOffline }) => {
          const hasActivities = activityIds.length > 0;

          if (!hasActivities && isOffline) {
            return (
              <Offline description="Connect to the internet to load activities." />
            );
          }

          return (
            <Flex
              column
              className={classnames(style.wrapper, className, {
                [style.mobile]: mobile,
              })}
            >
              <Breakpoint at={1024}>
                {({ above }) => (
                  <React.Fragment>
                    <ProjectActivityHeader
                      branches={branches}
                      selectedBranch={branch}
                    />
                    <Loaded loading={isLoading} title="Loading activity…" flex>
                      {() => {
                        if (!hasActivities) {
                          return <NoActivity flex />;
                        }

                        return (
                          <Flex
                            column
                            className={style.scrollable}
                            qaSelector="scrollable-container"
                          >
                            <ActivityList
                              branchId={branchId}
                              projectId={projectId}
                              className={classnames(style.list, {
                                [style.listLarge]: above,
                              })}
                              activityIds={activityIds}
                              activityContext={this.activityContext}
                            >
                              <Waypoint onEnter={onLoadMore} />
                            </ActivityList>

                            {isLoadingMore && (
                              <div className={style.spinner}>
                                <Loaded
                                  loading
                                  title={
                                    isOffline
                                      ? "You are offline. Connect to the internet to load more activities"
                                      : undefined
                                  }
                                />
                              </div>
                            )}
                          </Flex>
                        );
                      }}
                    </Loaded>
                  </React.Fragment>
                )}
              </Breakpoint>
            </Flex>
          );
        }}
      </ActivitiesLoader>
    );
  }
}

export default connector(ProjectActivities);
