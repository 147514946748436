// @flow
import * as React from "react";
import ContextMenu, {
  type Props as ContextMenuProps,
} from "core/components/ContextMenu";
import { isDesktop } from "core/lib/platform";
import type { Project, Policy, Branch, MenuItem, Library } from "core/types";
import connector from "./connector";

export type OwnProps = {|
  projectId: string,
  branchId: string,
  library: Library,
  onAfterClose?: () => void,
  innerRef?: React.Ref<typeof ContextMenu>,
  children: $PropertyType<ContextMenuProps, "children">,
|};

export type StateProps = {|
  project: ?Project,
  branch: ?Branch,
  policy: Policy,
  shouldShowLibraryActions: boolean,
|};

export type DispatchProps = {|
  showRemoveLibraryDialog: () => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

function LibraryMenu(props: Props) {
  const menuItems = (): Array<MenuItem> => {
    if (props.shouldShowLibraryActions && isDesktop) {
      return [
        {
          label: "Unlink Library…",
          click: props.showRemoveLibraryDialog,
          visible: props.policy.createCommit === true,
        },
      ];
    } else {
      return [];
    }
  };
  return (
    <ContextMenu
      id={props.library.fileId}
      ref={props.innerRef}
      menuItems={menuItems()}
      placement="bottom-end"
      children={props.children}
      onAfterClose={props.onAfterClose}
    />
  );
}

export default connector(LibraryMenu);
