// @flow
import { normalize, schema } from "normalizr";

export const notificationSettingsSchema = new schema.Entity(
  "notificationSettings",
  {},
  {
    idAttribute: (settings) => {
      if (!settings.organizationId) {
        return settings.userId;
      }
      return `${settings.userId}-${settings.organizationId}`;
    },
  }
);

export function normalizeNotificationSettings(settings: Object) {
  return normalize(settings, notificationSettingsSchema);
}
