// @flow
import * as Abstract from "abstract-sdk";
import apiRequest from "abstract-di/api";
import { entitiesReceived } from "core/actions/entities";
import { deletedOrganizationMembership } from "core/actions/memberships";
import { showToast } from "core/actions/toasts";
import defineRequest from "core/requests";
import {
  normalizeMembership,
  normalizeMemberships,
  normalizeUpdateMembershipResponse,
} from "core/schemas/membership";
import type {
  Dispatch,
  Membership,
  OrganizationRole,
  SubscriptionRole,
  ThunkAction,
} from "core/types";

type OrganizationMembershipParams = $ReadOnly<{
  ...Abstract.OrganizationDescriptor,
  role?: OrganizationRole,
}>;

export const OrganizationMembershipsRequest = defineRequest<
  OrganizationMembershipParams,
  OrganizationMembershipParams,
>({
  id: (params) => {
    const { organizationId, role } = params;
    return `get:organizations/${organizationId}/memberships/role/${role ?? ""}`;
  },
  perform: (params) => {
    const { organizationId, role } = params;
    return apiRequest("get", `organizations/${organizationId}/memberships`, {
      role,
    });
  },
  onSuccess: (
    response: { data: any },
    params: OrganizationMembershipParams,
    dispatch: Dispatch
  ) => {
    const normalizedMemberships = normalizeMemberships(response.data);
    dispatch({
      type: "USERS_LOADED",
      payload: normalizedMemberships.entities.users,
    });
    dispatch(entitiesReceived(normalizedMemberships.entities));
  },
  invalidateable: true,
});

type PaginatedOrganizationMembershipsParams = {
  ...Abstract.OrganizationDescriptor,
  limit: number,
  offset: number,
  role?: OrganizationRole,
  subscriptionRole?: SubscriptionRole,
  query?: string,
  // included for compatibility with getUsersForOrganization
  userId?: string,
};

export const PaginatedOrganizationMembershipsRequest = defineRequest<
  PaginatedOrganizationMembershipsParams,
  PaginatedOrganizationMembershipsParams,
>({
  id(params) {
    const { limit, offset, organizationId, role, query, subscriptionRole } =
      params;
    return `get:organizations/${organizationId}/memberships/limit/${limit}/offset/${offset}/role/${
      role || ""
    }/subscription_role/${subscriptionRole || ""}/search/${query || ""}`;
  },
  perform(params) {
    return apiRequest(
      "get",
      `organizations/${params.organizationId}/memberships`,
      {
        limit: params.limit,
        offset: params.offset,
        role: params.role === "all" ? undefined : params.role,
        subscriptionRole: params.subscriptionRole,
        search: params.query,
      },
      20
    );
  },
  onSuccess(response, params, dispatch) {
    const { entities } = normalizeMemberships(response.data);
    return dispatch(entitiesReceived(entities));
  },
  invalidateable: true,
});

export const OrganizationMembershipRequest = defineRequest<
  Abstract.OrganizationMembershipDescriptor,
  Abstract.OrganizationMembershipDescriptor,
>({
  id: (params) => {
    const { organizationId, userId } = params;
    return `get:organizations/${organizationId}/memberships/${userId}`;
  },
  perform: (params) => {
    const { organizationId, userId } = params;
    return apiRequest(
      "get",
      `organizations/${organizationId}/memberships/${userId}`
    );
  },
  onSuccess: (
    response,
    params: Abstract.OrganizationMembershipDescriptor,
    dispatch: Dispatch
  ) => {
    const normalizedMemberships = normalizeMembership(response.data);
    dispatch(entitiesReceived(normalizedMemberships.entities));
  },
});

type OrganizationMembershipUpdatePerformParams = {
  ...Abstract.OrganizationMembershipDescriptor,
  membership: $Shape<Membership>,
};

export const UpdateOrganizationMembershipsRequest = defineRequest<
  OrganizationMembershipUpdatePerformParams,
  OrganizationMembershipUpdatePerformParams,
>({
  id: (params) => {
    const { organizationId, userId } = params;
    return `put:organization_membership/${organizationId}-${userId}`;
  },
  perform: (params) => {
    const { organizationId, userId, membership } = params;
    return apiRequest(
      "put",
      `organizations/${organizationId}/memberships/${userId}`,
      membership
    );
  },
  onError: (
    error: Error,
    params: OrganizationMembershipUpdatePerformParams,
    dispatch: Dispatch
  ) => {
    dispatch(showToast({ text: "Could not update membership" }));
  },
  onSuccess: (
    response: { policies: any, data: any },
    params: OrganizationMembershipUpdatePerformParams,
    dispatch: Dispatch
  ): ThunkAction => {
    const { entities } = normalizeUpdateMembershipResponse(response);
    return dispatch(entitiesReceived(entities));
  },
});

type RemoveOrganizationMemberRequestParams = {|
  ...Abstract.OrganizationMembershipDescriptor,
  noToast?: boolean,
|};

export const RemoveOrganizationMemberRequest = defineRequest<
  RemoveOrganizationMemberRequestParams,
  RemoveOrganizationMemberRequestParams,
>({
  id: (params) => {
    const { organizationId, userId } = params;
    return `delete:organization_membership/${organizationId}-${userId}`;
  },
  perform: (params) => {
    const { organizationId, userId } = params;
    return apiRequest(
      "delete",
      `organizations/${organizationId}/memberships/${userId}`
    );
  },
  onError: (error, params, dispatch) => {
    if (!params.noToast) {
      dispatch(showToast({ text: "Could not remove membership" }));
    }
  },
  onSuccess: (response, params, dispatch) => {
    const { organizationId, userId } = params;
    return dispatch(deletedOrganizationMembership(organizationId, userId));
  },
});
