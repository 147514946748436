// @flow
import { connect } from "react-redux";
import { editFile, openUntracked } from "abstract-di/actions";
import { getRepoIsSyncing, isOnline } from "abstract-di/selectors";
import { loadCollection } from "core/actions/collections";
import { fetchComments } from "core/actions/comments";
import { withData } from "core/components/DataLoader";
import createConnector from "core/lib/createConnector";
import { locationLayerMode } from "core/lib/layer";
import { addQuery, removeQuery, replace } from "core/lib/location";
import { isDesktop } from "core/lib/platform";
import { BranchFetchRequest } from "core/requests/branches";
import { CommitsFetchRequest } from "core/requests/commits";
import { FileFetchRequest } from "core/requests/files";
import { LayerFetchRequest } from "core/requests/layers";
import { ProjectFetchRequest } from "core/requests/projects";
import {
  getBranch,
  getBranchHead,
  getParentBranch,
} from "core/selectors/branches";
import { getCommentsByCommit } from "core/selectors/comments";
import {
  getCommitForLayer,
  getCommitEntitiesForLayer,
  getPreviousLayerCommit,
} from "core/selectors/commits";
import { getFileForLayer } from "core/selectors/files";
import { getLayer, getLayerState } from "core/selectors/layers";
import { getProject } from "core/selectors/projects";
import { getShowPrototypes } from "core/selectors/prototypes";
import type { Dispatch, LayerSetParams, State } from "core/types";
import type { OwnProps, StateProps, DispatchProps, Props } from ".";

function getLayerSetParams(props: OwnProps): void | LayerSetParams {
  const collectionId =
    props.location.query && props.location.query.collectionId;
  return (
    props.layerSetParams ||
    (props.location.state && props.location.state.layerSetParams) ||
    (collectionId
      ? {
          type: "collection",
          projectId: props.params.projectId,
          collectionId,
        }
      : undefined)
  );
}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { projectId, branchId, fileId, layerId } = props.params;
  const head = getBranchHead(state, props.params);
  const commits = getCommitEntitiesForLayer(state, {
    projectId,
    branchId,
    fileId,
    layerId,
  });
  const latestCommitSha = Object.keys(commits)[0];
  const layer = getLayer(state, props.params);

  return {
    branch: getBranch(state, { projectId, branchId }),
    comments: getCommentsByCommit(state, {
      projectId,
      branchId,
      fileId,
      layerId,
    }),
    commit: getCommitForLayer(state, props.params),
    commits,
    commitsError: CommitsFetchRequest.hasError(state, {
      projectId,
      branchId,
      head,
      fileId,
      layerId,
    }),
    commitsLoading: CommitsFetchRequest.isLoading(state, {
      projectId,
      branchId,
      head,
      fileId,
      layerId,
    }),
    file: getFileForLayer(state, props.params),
    isOffline: !isOnline(state),
    isSyncing: getRepoIsSyncing(state, {
      projectId,
      branchId,
    }),
    latestCommit: latestCommitSha ? commits[latestCommitSha] : undefined,
    layer,
    layerSetNonce: props.location.query.nonce,
    layerSetParams: getLayerSetParams(props),
    layerState: getLayerState(state, props.params),
    pageId: layer && layer.pageId,
    parentBranch: getParentBranch(state, { projectId, branchId }),
    project: getProject(state, { projectId }),
    selectedLayerKey: props.location.query.selected || "",
    selectedMode: locationLayerMode(props.location),
    showPrototypes: getShowPrototypes(state, projectId),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { projectId, branchId, sha, fileId, layerId } = props.params;

  return {
    onLoad() {
      return dispatch(function (dispatch, getState) {
        const state = getState();
        const head = getBranchHead(state, { projectId, branchId });

        dispatch(ProjectFetchRequest.perform({ params: { projectId } }));
        dispatch(
          BranchFetchRequest.perform({ params: { projectId, branchId } })
        );
        dispatch(
          FileFetchRequest.perform({
            params: { projectId, branchId, sha, fileId },
          })
        );
        dispatch(
          LayerFetchRequest.perform({
            params: { projectId, branchId, sha, fileId, layerId },
          })
        );
        dispatch(
          CommitsFetchRequest.perform({
            params: { projectId, branchId, head, fileId, layerId },
          })
        ).then(() => {
          const compareToCommit = getPreviousLayerCommit(
            getState(),
            props.params
          );
          if (compareToCommit) {
            dispatch(
              LayerFetchRequest.perform({
                params: { ...props.params, sha: compareToCommit.sha },
              })
            );
          }
        });
        dispatch(fetchComments({ projectId, branchId, fileId, layerId }));

        const layerSetParams = getLayerSetParams(props);
        if (layerSetParams && layerSetParams.type === "collection") {
          dispatch(
            loadCollection(layerSetParams.collectionId, props.params.projectId)
          );
        }
      });
    },
    onSelectLayer(layerKey) {
      replace(
        layerKey ? addQuery({ selected: layerKey }) : removeQuery("selected")
      );
    },
    onSelectMode(mode) {
      replace(addQuery({ mode }));
    },
    openFile: isDesktop
      ? (sha: string, shouldOpenUntracked: boolean) => {
          const openFile = shouldOpenUntracked ? openUntracked : editFile;
          dispatch(
            openFile({
              projectId,
              branchId,
              fileId,
              layerId,
              sha,
            })
          );
        }
      : undefined,
  };
}

export default createConnector<Props, OwnProps>(
  connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch>(
    mapStateToProps,
    mapDispatchToProps
  ),
  withData((props: Props) => ({
    ...props.params,
    collectionId: props.location.query.collectionId,
    layerSetParams: props.location.state && props.location.state.layerSetParams,
    isOffline: props.isOffline,
  }))
);
