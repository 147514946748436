// @flow
import invariant from "invariant";
import { connect } from "react-redux";
import createConnector from "core/lib/createConnector";
import { mergeRestrictionsCTAEnabled } from "core/selectors/features";
import { getOrganizationForProject } from "core/selectors/organizations";
import {
  getOrganizationPolicy,
  getProjectPolicy,
} from "core/selectors/policies";

import type { State, Dispatch } from "core/types";
import type { OwnProps, StateProps, Props } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { projectId } = props.params;
  const policy = getProjectPolicy(state, { projectId });
  const organization = getOrganizationForProject(state, { projectId });
  invariant(organization, "organization must be loaded");
  const organizationPolicy = getOrganizationPolicy(state, {
    organizationId: organization.id,
  });

  return {
    notPermitted:
      !!Object.keys(policy).length && !policy.update && !policy.transfer,
    showMenu: true,
    showRestrictionsTab:
      !organization.isUsernameOrganization &&
      (mergeRestrictionsCTAEnabled(state, projectId) ||
        organizationPolicy.manageBranchRestrictions),
  };
}

export default createConnector<Props, OwnProps>(
  connect<Props, OwnProps, StateProps, void, State, Dispatch>(mapStateToProps)
);
