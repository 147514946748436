// @flow
import * as React from "react";
import Button from "core/components/Button";
import Flex from "core/components/Flex";
import style from "./style.scss";

type Props = {
  title: React.Node,
  submitText: string,
  cancelText?: string,
  onDiscard: () => *,
  onSubmit: () => *,
  submitting: boolean,
  disabled: boolean,
};

export default function Footer({
  title,
  submitText,
  cancelText = "Cancel",
  submitting,
  disabled,
  onDiscard,
  onSubmit,
}: Props) {
  return (
    <Flex align="center" className={style.footer}>
      <Flex align="center" className={style.left}>
        {title}
      </Flex>
      <Flex align="center" justify="flex-end">
        <Button large onClick={onDiscard} disabled={submitting}>
          {cancelText}
        </Button>
        <Button
          large
          primary
          onClick={onSubmit}
          className={style.continue}
          disabled={disabled}
        >
          {submitText}
        </Button>
      </Flex>
    </Flex>
  );
}
