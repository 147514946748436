// @flow
import classnames from "classnames";
import React, { Component } from "react";
import Button from "core/components/Button";
import UserListItem from "core/components/UserListItem";
import { fromNow } from "core/lib/dates";
import { ReviewStatuses, reviewStatusColor } from "core/models/review";
import type { ReviewRequest } from "core/types";
import style from "../style.scss";
import BranchReviewerMenu from "./Menu";

type Props = {
  reviewRequest: ReviewRequest,
  badge?: string,
  deleteReviewRequest?: (id: string) => *,
  dismissReview: (id: string) => *,
};

type State = {
  isSaving: boolean,
};

export default class BranchReviewListItem extends Component<Props, State> {
  state = {
    isSaving: false,
  };

  get isProjectReviewer(): boolean {
    // NOTE - implement once we have project level reviewers
    return false;
  }

  get reviewDetails(): string {
    const { reviewRequest } = this.props;
    const { status, requester, statusChangedAt, createdAt } = reviewRequest;

    switch (status) {
      case ReviewStatuses.APPROVED:
        return `Approved · ${fromNow(statusChangedAt)}`;

      case ReviewStatuses.REJECTED:
        return `Changes requested · ${fromNow(statusChangedAt)}`;

      case ReviewStatuses.REQUESTED:
      default:
        if (this.isProjectReviewer) {
          return `Project Reviewer`;
        } else {
          return `Added by ${requester.name} · ${fromNow(createdAt)}`;
        }
    }
  }

  handleDismiss = async (id: string) => {
    this.setState({ isSaving: true });
    await this.props.dismissReview(id);
    this.setState({ isSaving: false });
  };

  handleDelete = async (id: string) => {
    const { deleteReviewRequest } = this.props;

    if (deleteReviewRequest) {
      this.setState({ isSaving: true });
      await deleteReviewRequest(id);
      this.setState({ isSaving: false });
    }
  };

  render() {
    const { deleteReviewRequest, reviewRequest, badge } = this.props;
    const { reviewer, status } = reviewRequest;

    return (
      <BranchReviewerMenu
        deleteReview={deleteReviewRequest ? this.handleDelete : null}
        dismissReview={this.handleDismiss}
        reviewRequest={reviewRequest}
      >
        {(showMenu, ref) => (
          <UserListItem
            className={classnames({
              [style.itemUpdating]: this.state.isSaving,
            })}
            id={reviewer.id}
            name={reviewer.name}
            joinedOn={this.reviewDetails}
            avatarUrl={reviewer.avatarUrl}
            avatarColor={reviewStatusColor(status)}
            onContextMenu={showMenu}
            responsive={false}
            badge={badge}
          >
            <Button
              icon="overflow"
              className={style.actions}
              innerRef={ref}
              onClick={showMenu}
              nude
            />
          </UserListItem>
        )}
      </BranchReviewerMenu>
    );
  }
}
