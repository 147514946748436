// @flow
import empty from "empty";
import idx from "idx";
import * as Abstract from "core/types"; // TODO: Update sdk to allow import { Abstract } from "core/lib/abstract";
import * as constants from "./constants";
import * as t from "./types";

const NOT_SUPPORTED = (layer, sibling) => false; // Disable siblings for axis and directions not supported yet

const SIBLING_TRAILING_LAYER = {
  [constants.LAYER_AXIS_HORIZONTAL]: {
    [constants.LAYER_DIRECTION_START_TO_END]: (layer, sibling) =>
      sibling.properties.x > layer.properties.x &&
      idx(sibling.properties.resizingConstraint, (_) => _.right) !== true,
    [constants.LAYER_DIRECTION_END_TO_START]: (layer, sibling) =>
      sibling.properties.x < layer.properties.x &&
      idx(sibling.properties.resizingConstraint, (_) => _.left) !== true,
    [constants.LAYER_DIRECTION_CENTER]: NOT_SUPPORTED,
  },
  [constants.LAYER_AXIS_VERTICAL]: {
    [constants.LAYER_DIRECTION_START_TO_END]: NOT_SUPPORTED,
    [constants.LAYER_DIRECTION_END_TO_START]: NOT_SUPPORTED,
    [constants.LAYER_DIRECTION_CENTER]: NOT_SUPPORTED,
  },
};

const X_OR_Y_AXIS = {
  [constants.LAYER_AXIS_HORIZONTAL]: "x",
  [constants.LAYER_AXIS_VERTICAL]: "y",
};

export default function filterTrailingSiblings(
  layers: t.Layers,
  layer: Abstract.LayerData
): Abstract.LayerData[] {
  const parent = layer.parentId ? layers[layer.parentId] : undefined;
  const layout = idx(parent, (_) => _.properties.layout);
  const childIds = idx(parent, (_) => _.childIds) || empty.array;
  const siblingIds: string[] = [...childIds].filter((id) => id !== layer.id); // clone to allow sorting in place later

  if (!layout) {
    return empty.array; // No layout, no siblings are adjusted
  }

  return (
    siblingIds
      .reduce((siblings, siblingId): Abstract.LayerData[] => {
        const sibling = layers[siblingId];

        if (
          sibling &&
          SIBLING_TRAILING_LAYER[layout.axis][layout.direction](layer, sibling)
        ) {
          siblings.push(sibling);
        }

        return siblings;
      }, [])
      // Sort siblings from start to end along axis
      .sort(
        (a, b) =>
          a.properties[X_OR_Y_AXIS[layout.axis]] -
          b.properties[X_OR_Y_AXIS[layout.axis]]
      )
  );
}
