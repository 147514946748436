// @flow
import { keys } from "lodash";
import { connect } from "react-redux";
import { entitiesClearSelected } from "core/actions/collectionMultiselect";
import * as Request from "core/models/request";
import { CollectionCreateRequest } from "core/requests/collections";
import { getMultiSelectedEntities } from "core/selectors/collectionMultiselect";
import type {
  State,
  Collection,
  Dispatch,
  CollectionFormValues,
} from "core/types";
import type { OwnProps, StateProps, DispatchProps } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { projectId, branch } = props;

  const entities = getMultiSelectedEntities(state, {
    projectId,
    branchId: branch.id,
  });

  const request = CollectionCreateRequest.getRequest(state, {
    projectId,
    branchId: branch.id,
  });

  return {
    isSubmitting: Request.isLoadingStrict(request),
    hasError: Request.hasError(request),

    layerCount: entities ? keys(entities.layers).length : 0,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { projectId, branch } = props;

  return {
    createCollection: (
      values: CollectionFormValues,
      successCallback: (Collection) => *
    ) => {
      dispatch(
        CollectionCreateRequest.perform({
          params: {
            projectId,
            branchId: branch.id,
            ...values,
          },
          onSuccess: (response) => {
            successCallback(response.data);
          },
        })
      );
    },
    onClearSelected: (branchId) =>
      dispatch(entitiesClearSelected(projectId, branchId)),
  };
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default connect(mapStateToProps, mapDispatchToProps);
