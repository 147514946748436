// @flow
import empty from "empty";
import * as React from "react";
import { connect } from "react-redux";
import { loadBranchChangeset } from "core/actions/changesets";
import { fetchComments } from "core/actions/comments";
import CollectionMultiSelect from "core/components/CollectionMultiSelect";
import Error from "core/components/Empty/Error";
import Loaded from "core/components/Loaded";
import Media from "core/components/Media";
import { replace, addQuery } from "core/lib/location";
import { filePath, pagePath } from "core/lib/routes";
import { BRANCH_ID_MASTER } from "core/models/branch";
import * as Request from "core/models/request";
import { BranchChangesetFetchRequest } from "core/requests/changesets";
import { FilesFetchRequest } from "core/requests/files";
import { getBranch } from "core/selectors/branches";
import { getFileChangesForChangeset } from "core/selectors/changesets";
import { getCommentCounts } from "core/selectors/comments";
import {
  getFileHasChanged,
  getChangedFilesForBranch,
  getUnchangedFilesForBranch,
  getDeletedFilesForBranch,
} from "core/selectors/files";
import { getPagesByFileForBranch } from "core/selectors/pages";
import type {
  Dispatch,
  State,
  Branch,
  Library,
  Page,
  File,
  FilePathQueryParams,
  ChangesetChange,
} from "core/types";
import { loadPages } from "web/actions/pages";
import BranchFilesDesktop from "web/components/BranchFiles/BranchFilesDesktop";
import BranchFilesMobile from "web/components/BranchFiles/BranchFilesMobile";
import { libraryPath } from "web/routeHelpers";
import { getLibrariesForBranch } from "web/selectors/libraries";

type OwnProps = {|
  children?: any,
  params: {
    projectId: string,
    branchId: string,
    fileId: string,
    pageId?: string,
  },
  location: {
    query: FilePathQueryParams,
  },
|};

type StateProps = {|
  key: string,
  isLoading: boolean,
  error: boolean,
  branch: ?Branch,
  changedFiles: File[],
  unchangedFiles: File[],
  deletedFiles: File[],
  pages: { [uniqueFileId: string]: Page[] },
  fileChanges: { [fileId: string]: ChangesetChange },
  fileCommentCounts: { [id: string]: number },
  libraries: Library[],
  filter?: string,
  contentTypeId?: string,
  hasChanged?: boolean,
|};

type DispatchProps = {|
  loadPages: (fileId: string) => void,
  onMount: (head: string) => void,
|};

type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

class FilesLoader extends React.Component<Props> {
  componentDidMount() {
    if (this.props.branch && this.props.branch.head) {
      this.props.onMount(this.props.branch.head);
    }

    if (this.props.hasChanged && !this.props.filter) {
      this.addChangedFilter();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.branch &&
      this.props.branch &&
      prevProps.branch.head !== this.props.branch.head
    ) {
      this.props.onMount(this.props.branch.head);
    }

    if (!prevProps.hasChanged && this.props.hasChanged && !this.props.filter) {
      this.addChangedFilter();
    }
  }

  addChangedFilter = () => {
    replace(addQuery({ filter: "changed" }));
  };

  filePath =
    (changed: boolean) =>
    (fileId: string, pageId?: string, query?: FilePathQueryParams) => {
      const { projectId, branchId } = this.props.params;
      const filter = changed && this.props.filter ? "changed" : undefined;
      const queryParams = !query && !changed ? undefined : { ...query, filter };

      return pageId
        ? pagePath(projectId, branchId, fileId, pageId, queryParams)
        : filePath(projectId, branchId, fileId, queryParams);
    };

  libraryPath = (fileId: string) => {
    const { projectId, branchId } = this.props.params;
    return libraryPath(projectId, branchId, fileId);
  };

  render() {
    if (this.props.isLoading) {
      return <Loaded loading title="Loading files…" flex />;
    }
    if (this.props.error) {
      return <Error flex />;
    }

    const sharedProps = {
      fileCommentCounts: this.props.fileCommentCounts,
      changedFiles: this.props.changedFiles,
      unchangedFiles: this.props.unchangedFiles,
      deletedFiles: this.props.deletedFiles,
      pages: this.props.pages,
      libraries: this.props.libraries,
      filePath: this.filePath,
      fileChanges: this.props.fileChanges,
      libraryPath: this.libraryPath,
      loadPages: this.props.loadPages,
      params: this.props.params,
      contentTypeId: this.props.contentTypeId,
      isMasterBranch: this.props.params.branchId === "master",
      isEmpty:
        !this.props.changedFiles.length &&
        !this.props.unchangedFiles.length &&
        !this.props.libraries.length &&
        !this.props.deletedFiles.length,
    };

    return (
      <Media desktop>
        {(matches) => {
          if (matches) {
            return (
              <CollectionMultiSelect
                showHeader
                useLatestCommit
                redirectToCollectionAfterSubmit
                projectId={this.props.params.projectId}
                branchId={this.props.params.branchId}
                noPrompt
              >
                {() => (
                  <BranchFilesDesktop {...sharedProps}>
                    {this.props.children}
                  </BranchFilesDesktop>
                )}
              </CollectionMultiSelect>
            );
          }

          if (this.props.params.fileId) {
            return this.props.children;
          }

          return <BranchFilesMobile {...sharedProps} />;
        }}
      </Media>
    );
  }
}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { projectId, branchId, fileId } = props.params;
  const branch = getBranch(state, { projectId, branchId });
  const head = branch ? branch.head : undefined;

  const isMaster = branchId === BRANCH_ID_MASTER;

  const filesRequest = head
    ? FilesFetchRequest.getRequest(state, {
        projectId,
        branchId,
        sha: head,
      })
    : Request.DEFAULT;
  const changesetRequest = BranchChangesetFetchRequest.getRequest(state, {
    projectId,
    branchId,
    head,
  });

  const hasChanged =
    !!fileId && getFileHasChanged(state, { projectId, branchId, fileId });

  return {
    key: `${projectId}-${branchId}-files`,
    branch,
    hasChanged,
    libraries: getLibrariesForBranch(state, { projectId, branchId }),
    changedFiles: getChangedFilesForBranch(state, { projectId, branchId }),
    unchangedFiles: getUnchangedFilesForBranch(state, {
      projectId,
      branchId,
    }),
    deletedFiles: getDeletedFilesForBranch(state, { projectId, branchId }),
    filter: props.location.query.filter || undefined,
    contentTypeId: props.location.query.contentTypeId || undefined,
    pages: getPagesByFileForBranch(state, { projectId, branchId }),
    fileChanges: isMaster
      ? empty.object
      : getFileChangesForChangeset(state, { projectId, branchId }),
    fileCommentCounts: getCommentCounts(state, {
      projectId,
      branchId,
      key: "fileId",
    }),
    isLoading: isMaster
      ? Request.isLoading(filesRequest)
      : Request.isLoading(filesRequest, changesetRequest),
    error: Request.unexpectedError(filesRequest, changesetRequest),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { projectId, branchId } = props.params;
  return {
    loadPages: (fileId) => {
      dispatch(loadPages(projectId, branchId, fileId));
    },
    onMount: (head) => {
      dispatch(
        FilesFetchRequest.perform({
          params: { projectId, branchId, sha: head },
        })
      );
      dispatch(fetchComments({ projectId, branchId }));

      if (branchId !== BRANCH_ID_MASTER) {
        dispatch(loadBranchChangeset({ projectId, branchId, head }));
      }
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(
  mapStateToProps,
  mapDispatchToProps
)(FilesLoader);
