// @flow
import * as React from "react";
import { connect } from "react-redux";
import { openSupportWindow } from "abstract-di/actions";
import ButtonLink from "core/components/ButtonLink";
import { V3Link as Link } from "core/lib/router";
import type { State, Dispatch } from "core/types";
import style from "./not-found.scss";
import Empty from ".";

type OwnProps = {|
  title?: string,
  description?: string,
  flex?: boolean,
|};

type DispatchProps = {|
  openSupportTicket: () => void,
|};

type Props = {
  ...OwnProps,
  ...DispatchProps,
};

function NotFound(props: Props) {
  return (
    <Empty
      image={<div className={style.fourohfour} />}
      title="Four Oh Four"
      description="We could not locate the page you requested."
    >
      <p>
        Go to the <Link to="/">homepage</Link> or
        <ButtonLink onClick={props.openSupportTicket}>
          contact support
        </ButtonLink>
        .
      </p>
    </Empty>
  );
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    openSupportTicket: () => dispatch(openSupportWindow()),
  };
}

export default connect<Props, OwnProps, _, DispatchProps, State, Dispatch>(
  undefined,
  mapDispatchToProps
)(NotFound);
