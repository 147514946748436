// @flow
import Immutable from "seamless-immutable";
import createEntityReducer from "core/reducers/createEntityReducer";
import type { OrganizationSettings } from "core/types";

export default createEntityReducer<OrganizationSettings>(
  "organizationSettings",
  function (state, action) {
    switch (action.type) {
      case "ORGANIZATION_SETTINGS_INTEGRATION_REMOVED": {
        const { organizationId, kind } = action.meta;

        let integration: string;
        switch (kind) {
          case "GitHub":
            integration = "githubIntegration";
            break;
          case "Jira":
            integration = "jiraIntegration";
            break;
          case "Slack":
            integration = "slackIntegration";
            break;
          default:
            return state;
        }

        return Immutable.set(
          state,
          organizationId,
          Immutable.set(state[organizationId], integration, undefined)
        );
      }
      case "ORGANIZATION_SETTINGS_INTEGRATIONS_LOADED": {
        const { organizationId, organizationName } = action.meta;

        const { githubIntegration, jiraIntegration, slackIntegration } =
          action.payload.reduce(
            (acc, integration) => {
              switch (integration.kind.toLowerCase()) {
                case "github":
                  acc.githubIntegration = integration;
                  break;
                case "jira":
                  acc.jiraIntegration = integration;
                  break;
                case "slack":
                  acc.slackIntegration = integration;
                  break;
                default:
                  break;
              }
              return acc;
            },
            {
              githubIntegration: undefined,
              jiraIntegration: undefined,
              slackIntegration: undefined,
            }
          );

        if (state[organizationId]) {
          return Immutable.set(
            state,
            organizationId,
            Immutable.merge(state[organizationId], {
              githubIntegration,
              jiraIntegration,
              slackIntegration,
            })
          );
        }

        return Immutable.from({
          [organizationId]: {
            organizationId,
            organizationName,
            githubIntegration,
            jiraIntegration,
            slackIntegration,
          },
        });
      }
      default:
        return state;
    }
  }
);
