// @flow
import * as React from "react";
import { useDispatch } from "react-redux";
import { toggleShortcutViewer } from "core/actions/app";
import CoreShortcutViewer from "core/components/ShortcutViewer";
import window from "core/global/window";
import KeyCode from "core/lib/keycode";
import { modifierKeyPressed } from "core/lib/platform";

export default function ShortcutViewer() {
  const dispatch = useDispatch();

  const handleKeydown = React.useCallback(
    (event: KeyboardEvent) => {
      if (modifierKeyPressed(event) && event.keyCode === KeyCode.KEY_SLASH) {
        dispatch(toggleShortcutViewer());
      }
    },
    [dispatch]
  );

  React.useEffect(() => {
    window.addEventListener("keydown", handleKeydown);

    return () => {
      window.removeEventListener("keydown", handleKeydown);
    };
  }, [handleKeydown]);

  return <CoreShortcutViewer />;
}
