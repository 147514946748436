// @flow
import classnames from "classnames";
import * as React from "react";
import CreateCollectionButton from "core/components/CreateCollectionButton";
import { helpCollectionsUrl } from "core/lib/urls";
import image from "./images/collection.svg";
import style from "./style.scss";
import Empty from ".";

type Props = {
  mini?: boolean,
  canCreateCollection?: boolean,
  projectId?: string,
  branchId?: string,
  className?: string,
  title?: string,
};

export default function NoCollections({
  canCreateCollection,
  projectId,
  branchId,
  mini,
  title = "Create a Collection",
  className,
  ...rest
}: Props) {
  return (
    <Empty
      {...rest}
      className={classnames(className, { [style.miniNoCollections]: mini })}
      image={image}
      title={title}
      description={
        <span>
          Use collections to organize, present, and share your work.
          {!canCreateCollection && !mini && (
            <React.Fragment>
              <br />
              <a
                href={helpCollectionsUrl()}
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more about collections in the Help Center.
              </a>
            </React.Fragment>
          )}
        </span>
      }
    >
      <span>
        {canCreateCollection && projectId && branchId && (
          <CreateCollectionButton
            projectId={projectId}
            branchId={branchId}
            text="Create Collection"
          />
        )}
      </span>
    </Empty>
  );
}
