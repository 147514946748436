// @flow
import * as React from "react";
import { helpGuestsUrl } from "core/lib/urls";
import image from "./images/members.svg";
import Empty from ".";

export default function NoPeople(props: {}) {
  return (
    <Empty
      {...props}
      image={image}
      title="Invite guests"
      description="Add guests to your team to share, collaborate, and get feedback."
    >
      <a href={helpGuestsUrl()} target="_blank" rel="noopener noreferrer">
        Learn about inviting guests in the Help Center.
      </a>
    </Empty>
  );
}
