// @flow
import { connect } from "react-redux";
import { trackEvent } from "core/actions/analytics";
import { withData } from "core/components/DataLoader";
import createConnector from "core/lib/createConnector";
import { OrganizationReportingFetchRequest } from "core/requests/organizationMetrics";
import { UserReportingPageFetchRequest } from "core/requests/userMetrics";
import { getOrganizationMetricsForOrganization } from "core/selectors/organizationMetrics";
import { getUserMetricsForOrganization } from "core/selectors/userMetrics";
import type {
  Dispatch,
  State,
  ReportingFilter,
  ReactTablePagination,
} from "core/types";
import type { OwnProps, StateProps, DispatchProps, Props } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { organizationId } = props.params;
  const organizationMetrics = getOrganizationMetricsForOrganization(
    state,
    props.params
  );
  const userMetrics = getUserMetricsForOrganization(state, props.params);

  return {
    isLoading: OrganizationReportingFetchRequest.isLoading(state, {
      organizationId,
    }),
    hasError: OrganizationReportingFetchRequest.hasError(state, {
      organizationId,
    }),
    organizationMetrics,
    userMetrics,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { organizationId } = props.params;

  return {
    onLoad() {
      dispatch(
        OrganizationReportingFetchRequest.perform({
          params: { organizationId },
        })
      );
    },
    onFilter(period: ReportingFilter) {
      dispatch(
        trackEvent("ORGANIZATION_REPORTING_FILTER_CHANGED", {
          organizationId,
          period,
        })
      );
      dispatch(
        OrganizationReportingFetchRequest.perform({
          params: { organizationId, period },
        })
      );
    },
    fetchData(pageData: ReactTablePagination, period?: ReportingFilter) {
      return dispatch(
        UserReportingPageFetchRequest.perform({
          params: { organizationId, period, ...pageData },
        })
      );
    },
  };
}

export default createConnector<Props, OwnProps>(
  connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch>(
    mapStateToProps,
    mapDispatchToProps
  ),
  withData((props: Props) => props.params)
);
