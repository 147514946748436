// @flow
import { omit } from "lodash";
import { normalize } from "normalizr";
import { organizationSettings as organizationSettingsSchema } from "core/schema";
import type {
  EntitiesOfType,
  Organization,
  OrganizationSettings,
} from "core/types";

type NormalizedOrganizationSettings = {
  entities: {
    organizations: EntitiesOfType<Organization>,
    organizationSettings: EntitiesOfType<OrganizationSettings>,
  },
};

export function normalizeOrganizationSettings(
  settings: Object
): NormalizedOrganizationSettings {
  const { entities } = normalize(settings, organizationSettingsSchema);
  let { organizations, organizationSettings } = entities;

  // There will be an `organization` and an `organizationId` key in the
  // org settings after normalizing. They will have the same string value.
  // Remove the unnecessary `organization` key.
  organizationSettings = Object.entries(organizationSettings).reduce(
    (acc, [key, value]) => {
      acc[key] = omit(value, "organization");
      return acc;
    },
    {}
  );

  return {
    entities: { organizations, organizationSettings },
  };
}
