// @flow
import * as React from "react";
import { ObjectInspector, chromeLight } from "react-inspector"; // eslint-disable-line import/no-unresolved
import Button from "core/components/Button";
import Flex from "core/components/Flex";
import Icon from "core/components/Icon";
import LayerStatusBadge from "core/components/LayerStatusBadge";
import Modal from "core/components/Modal";
import Note from "core/components/Note";
import Tab from "core/components/Tab";
import Tabs from "core/components/Tabs";
import Time from "core/components/Time";
import { connector, type Props } from "web/containers/WebhookOverview";
import style from "./style.scss";

type State = {
  page: "req-head" | "req-body" | "res",
};

class WebhookPayload extends React.Component<Props, State> {
  state = {
    page: "req-head",
  };

  get content() {
    const { webhookDelivery } = this.props;

    switch (this.state.page) {
      case "req-head":
      default:
        return { headers: webhookDelivery.request.headers };
      case "req-body":
        return { body: webhookDelivery.request.body };
      case "res":
        return { body: webhookDelivery.response.body };
    }
  }

  onClose = () => this.props.router.goBack();
  onShowRequestBody = () => this.setState({ page: "req-body" });
  onShowRequestHeaders = () => this.setState({ page: "req-head" });
  onShowResponse = () => this.setState({ page: "res" });

  render() {
    const {
      organizationId,
      redeliverWebhook,
      redeliveryLoading,
      webhookDelivery,
      webhookDeliveryId,
    } = this.props;

    const { page } = this.state;

    return (
      <Modal size="fullScreen" isOpen={true}>
        <Flex column>
          <Flex className={style.tabContainer} grow={false} shrink={false}>
            <Button
              className={style.close}
              icon="close"
              nude
              onClick={this.onClose}
              tint
            />
            <Tabs className={style.tabs}>
              <Tab
                label="Request Headers"
                onClick={this.onShowRequestHeaders}
                selected={page === "req-head"}
              />
              <Tab
                label="Request Body"
                onClick={this.onShowRequestBody}
                selected={page === "req-body"}
              />
              <Tab
                label="Response Body"
                onClick={this.onShowResponse}
                selected={page === "res"}
              />
            </Tabs>
            {!!webhookDelivery.error && (
              <Button
                className={style.redeliver}
                disabled={redeliveryLoading}
                icon="refresh"
                iconFill="#FFFFFF"
                onClick={() => {
                  redeliverWebhook({
                    deliveryId: webhookDelivery.id,
                    organizationId: organizationId,
                    webhookId: webhookDelivery.webhookId,
                  });
                }}
                primary
              >
                REDELIVER
              </Button>
            )}
          </Flex>
          <Flex className={style.body} grow shrink>
            <ObjectInspector
              data={this.content}
              expandLevel={5}
              theme={{
                ...chromeLight,
                ARROW_FONT_SIZE: "14px",
                BASE_FONT_SIZE: "14px",
                BASE_LINE_HEIGHT: 2,
                TREENODE_FONT_SIZE: "14px",
                TREENODE_LINE_HEIGHT: 2,
              }}
            />
          </Flex>
          <Flex className={style.footer} grow={false} shrink={false}>
            <Icon
              allgood={!webhookDelivery.error}
              danger={!!webhookDelivery.error}
              type={webhookDelivery.error ? "error" : "webhooks"}
            />
            <span className={style.deliveryId}>{webhookDeliveryId}</span>
            <LayerStatusBadge
              latest={!webhookDelivery.error}
              className={style.badge}
            >
              {webhookDelivery.response.code}
            </LayerStatusBadge>
            <Note component="div" className={style.timestamp}>
              <Time date={webhookDelivery.pushedAt} />
            </Note>
          </Flex>
        </Flex>
      </Modal>
    );
  }
}

export default connector(WebhookPayload);
