// @flow
import classnames from "classnames";
import * as React from "react";
import style from "./style.scss";

type Props = {
  children?: React.Node,
  latest?: boolean,
  className?: string,
};

export default function LayerStatusBadge(props: Props) {
  return (
    <div
      className={classnames(style.status, props.className, {
        [style.notLatest]: !props.latest,
      })}
    >
      {props.children || (props.latest ? "Latest" : "Not Latest")}
    </div>
  );
}
