// @flow
import { connect } from "react-redux";
import { showToast } from "core/actions/toasts";
import { OrganizationsFetchRequest } from "core/requests/organizations";
import type { Dispatch, State } from "core/types";
import { loadInvitation } from "web/actions/invitations";
import { getCurrentUser, sessionIsLoading } from "web/selectors/session";
import type { Props, OwnProps, StateProps, DispatchProps } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const currentUser = getCurrentUser(state);
  const userId = currentUser && currentUser.id;
  const invitation = state.invitation.entity;
  const stillConfirmingDomainForLoggedInUser =
    userId !== undefined &&
    invitation.id !== undefined &&
    invitation.satisfiedDomainRestriction === undefined;

  return {
    invalidToken: !state.invitation.isLoading && !invitation.id,
    isLoading:
      state.invitation.isLoading ||
      sessionIsLoading(state) ||
      stillConfirmingDomainForLoggedInUser,
    currentUser,
    userId,
    invitation,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    loadOrganizations: () =>
      dispatch(
        OrganizationsFetchRequest.perform({
          params: undefined,
        })
      ),
    loadInvitation: (token: string) => dispatch(loadInvitation(token)),
    showToast: (text: string) => dispatch(showToast({ text })),
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
