// @flow

import { Abstract } from "core/lib/abstract";
import { getLatestCommitShaForLayer } from "core/selectors/commits";
import type { State } from "core/types";

// This takes in a set of LayerVersionDescriptor params that may include
// the value "latest" for sha. If that is the case, it translates the
// latest sha into the specific latest sha value.

// For code cleanliness, we try not to use this selector in connectors,
// but rather in other selectors themselves.
export function getResolvedLayerDescriptor(
  state: State,
  params: Abstract.LayerVersionDescriptor
): Abstract.LayerVersionDescriptor {
  const { projectId, branchId, sha, fileId, layerId } = params;
  if (sha === "latest") {
    const latestSha = getLatestCommitShaForLayer(state, {
      projectId,
      branchId,
      fileId,
      layerId,
    });
    if (latestSha) {
      return { projectId, branchId, sha: latestSha, fileId, layerId };
    }
  }
  return params;
}
