// @flow
import * as React from "react";
import PillBadge from "core/components/PillBadge";
import PlaceholderText from "core/components/PlaceholderText";
import style from "./style.scss";

const PillBadgeType = {
  admin: "adminBadge",
  contributor: "paleTint",
};

export default function PeopleListItemBadges({
  guest,
  admin,
  contributor,
  projectAdmin,
  teamAdmin,
  hideContributorBadge,
  isLoading,
  isGrid,
}: {
  guest?: boolean,
  admin?: boolean,
  contributor?: boolean,
  projectAdmin?: boolean,
  teamAdmin?: boolean,
  hideContributorBadge?: boolean,
  isLoading?: boolean,
  isGrid?: boolean,
}) {
  let secondaryLabel = "";
  if (guest) {
    secondaryLabel = "Guest";
  } else if (projectAdmin) {
    secondaryLabel = "Project Admin";
  } else if (teamAdmin) {
    secondaryLabel = "Team Admin";
  } else if (admin) {
    secondaryLabel = "Admin";
  }
  return isLoading ? (
    <PlaceholderText max={50} size="xs" />
  ) : (
    <span className={style.badges}>
      {!hideContributorBadge && (
        <PillBadge
          className={style.badge}
          type={contributor ? PillBadgeType["contributor"] : undefined}
          title={contributor ? "Contributor" : "Viewer"}
        />
      )}
      {secondaryLabel && (
        <PillBadge
          className={style.badge}
          type={guest ? undefined : PillBadgeType["admin"]}
          title={secondaryLabel}
        />
      )}
    </span>
  );
}
