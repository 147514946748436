// @flow
import invariant from "invariant";
import { connect } from "react-redux";
import createConnector from "core/lib/createConnector";
import { isLoadingStrict, hasError } from "core/models/request";
import { getOrganization } from "core/selectors/organizations";
import { canOrganizationSeeCancelAndDeleteButtons } from "core/selectors/subscriptions";
import type { Dispatch, State } from "core/types";
import { organizationSettingsFormSubmit } from "web/actions/organizationSettingsForm";
import { deleteOrganization } from "web/actions/organizations";
import withForm from "web/containers/withForm";
import { getOrganizationDeleteRequest } from "web/selectors/requests";
import type { OwnProps, StateProps, DispatchProps, Props } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { organizationId } = props.params;
  const organization = getOrganization(state, { organizationId });
  invariant(organization, "organization must be loaded");

  const organizationDeleteRequest = getOrganizationDeleteRequest(
    state,
    organizationId
  );
  const areCancelAndDeleteButtonsVisible =
    canOrganizationSeeCancelAndDeleteButtons(state, organizationId);

  return {
    organization,
    requestLoading: isLoadingStrict(organizationDeleteRequest),
    requestError: hasError(organizationDeleteRequest),
    areCancelAndDeleteButtonsVisible,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    onSubmit: (
      formId: string,
      organizationId: string,
      values: { name?: string, logo: string }
    ) => {
      dispatch(organizationSettingsFormSubmit(formId, organizationId, values));
    },
    onDeleteOrganization: (organizationId: string) => {
      dispatch(deleteOrganization(organizationId));
    },
  };
}

export default createConnector<Props, OwnProps>(
  connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch>(
    mapStateToProps,
    mapDispatchToProps
  ),
  // withForm is not compose friendly
  (Component) =>
    withForm<Props>(
      Component,
      (props) => `organization-settings-${props.organization.id}`,
      (props: Props) => ({
        name: props.organization.name,
        billingEmail: props.organization.billingEmail,
        logo: "",
      })
    )
);
