// @flow
import { map } from "lodash";
import * as React from "react";
import type { Project, Branch, File, ChangesetChangeCounts } from "core/types";
import SelectableFilesListItem from "./SelectableFilesListItem";

type Props = {
  files: File[],
  project: ?Project,
  branch: ?Branch,
  selectedIds: string[],
  changeCounts: ChangesetChangeCounts,
  className?: string,
  onChange: (event: SyntheticInputEvent<>, fileId: string) => *,
  onDiscardChanges?: (event: SyntheticEvent<>, fileId: string) => *,
};

export default class SelectableFilesList extends React.Component<Props> {
  static defaultProps = {
    selectedIds: [],
  };

  render() {
    const {
      files,
      className,
      onChange,
      onDiscardChanges,
      changeCounts,
      selectedIds,
    } = this.props;

    return (
      <ul className={className}>
        {map(files, (file) => (
          <SelectableFilesListItem
            key={file.id}
            file={file}
            changeCount={changeCounts[file.id]}
            selected={selectedIds.includes(file.id)}
            onChange={onChange}
            onDiscardChanges={onDiscardChanges}
          />
        ))}
      </ul>
    );
  }
}
