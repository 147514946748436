// @flow
/* global document */
import classnames from "classnames";
import * as React from "react";
import Scrollable from "core/components/Scrollable";
import KeyCode from "core/lib/keycode";
import style from "./style.scss";

type Props = {
  children: React.Node,
  className?: string,
  onRequestClose?: (event: KeyboardEvent) => void,
  scrollable?: boolean,
  wrapper?: boolean,
  qaSelector?: string,
};

export default class Floating extends React.Component<Props> {
  static defaultProps = {
    wrapper: true,
  };

  container: ?HTMLDivElement;
  returnFocusAfterClose: ?HTMLElement;

  componentDidMount() {
    if (this.container) {
      this.returnFocusAfterClose = document.activeElement;
    }
  }

  handleKeyDown = (event: KeyboardEvent) => {
    const { onRequestClose } = this.props;

    if (event.keyCode === KeyCode.KEY_ESCAPE && onRequestClose) {
      event.preventDefault();
      event.stopPropagation();
      onRequestClose(event);

      if (this.returnFocusAfterClose) {
        this.returnFocusAfterClose.focus();
      }
    }
  };

  render() {
    const {
      children,
      scrollable,
      onRequestClose,
      className,
      wrapper,
      qaSelector,
      ...rest
    } = this.props;
    const Element = scrollable ? Scrollable : "div";
    const dynamicDataQA = Element === "div" ? "data-qa" : "qaSelector";

    return (
      <div
        ref={(ref) => (this.container = ref)}
        onKeyDown={this.handleKeyDown}
        className={classnames(style.floating, className)}
        data-qa={wrapper ? undefined : qaSelector}
      >
        {wrapper ? (
          <Element
            className={style.wrapper}
            data-ignore-body-scroll-lock={scrollable}
            {...{ [dynamicDataQA]: wrapper ? qaSelector : undefined }}
            {...rest}
          >
            {children}
          </Element>
        ) : (
          children
        )}
      </div>
    );
  }
}
