// @flow
import * as React from "react";
import Empty from ".";

type Props = {
  description?: string,
  title?: string,
};

export default function Offline({ description, title, ...rest }: Props) {
  return (
    <Empty
      {...rest}
      icon="offline"
      title={title || "Offline"}
      description={
        description || "Connect to the internet to view this screen."
      }
    />
  );
}
