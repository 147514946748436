// @flow
import type { State } from "core/types";

export function getPreviewFailedToReGenerate(
  state: State,
  { projectId, commitSha }: { projectId: string, commitSha: string }
) {
  return (
    state.previewGeneration &&
    state.previewGeneration.includes(`failed:${projectId}-${commitSha}`)
  );
}

export function getPreviewStartedToReGenerate(
  state: State,
  { projectId, commitSha }: { projectId: string, commitSha: string }
) {
  return (
    state.previewGeneration &&
    state.previewGeneration.includes(`started:${projectId}-${commitSha}`)
  );
}
