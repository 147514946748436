// @flow
import classnames from "classnames";
import * as React from "react";
import style from "./style.scss";

export type Props = {
  children: React.Node,
  level: "1" | "2" | "3" | "4" | "5" | "6",
  size: "xxxl" | "xxl" | "xl" | "l" | "m" | "s" | "xs",
  className?: string,
  title?: string,
  innerRef?: React.Ref<React.ElementType>, // any `h${level}` tag, e.g. h1, h2, etc.
  qaSelector?: string,
};

export default function Heading({
  size,
  className = "",
  level,
  title,
  innerRef,
  qaSelector,
  ...props
}: Props) {
  const Tag = `h${level}`;

  let classes;
  classes = classnames(style["display-" + size], className);

  return (
    <Tag
      title={title}
      className={classes}
      ref={innerRef}
      data-qa={qaSelector}
      {...props}
    />
  );
}
