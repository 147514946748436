// @flow
import classnames from "classnames";
import idx from "idx";
import * as React from "react";
import window from "core/global/window";
import withProfiler from "core/hocs/withProfiler";
import { Abstract } from "core/lib/abstract";
import eventInInput from "core/lib/eventInInput";
import formatNumber from "core/lib/formatNumber";
import { metaKey } from "core/lib/platform";
import quadrantsForRect from "core/lib/quadrantsForRect";
import * as LayerData from "core/models/layerData";
import type { LayerData as TLayerData } from "core/types";
import LayerChildren from "./LayerChildren";
import style from "./style.scss";

type Rect = $ReadOnly<{
  x: number,
  y: number,
  width: number,
  height: number,
}>;

type Guide = $ReadOnly<{
  x: number,
  y: number,
  width?: number,
  height?: number,
  value?: number,
}>;

type Props = {
  params: Abstract.LayerVersionDescriptor,
  width: number,
  height: number,
  scale: number,
  layers: { [layerId: string]: TLayerData },
  rootLayer: TLayerData,
  targetedLayer?: TLayerData,
  selectedLayer?: TLayerData,
  style?: Object,
  onTargetLayer?: (layer: ?TLayerData) => void,
  onSelectLayer?: (layerKey: ?string) => void,
  showHotspots: boolean,
};

const NO_DISTANCE = { top: 0, left: 0, bottom: 0, right: 0 };
const DIMENSIONS = ["width", "height"];
const MAX_DIRECTON = ["bottom", "right"];

const DIRECTION_TO_AXIS = {
  top: "y",
  bottom: "y",
  left: "x",
  right: "x",
};

const AXIS_TO_DIMENSION = {
  x: "width",
  y: "height",
};

const EMPTY_GUIDES = {};

type State = {
  ignoreGroups: boolean,
};

class LayerInspector extends React.PureComponent<Props, State> {
  screen: ?HTMLElement;

  static defaultProps = {
    scale: 1,
  };

  state = {
    ignoreGroups: false,
  };

  componentDidMount() {
    window.addEventListener("keydown", this.handleKeyDown);
    window.addEventListener("keyup", this.handleKeyUp);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
    window.removeEventListener("keyup", this.handleKeyUp);
  }

  get guides(): * {
    const { selectedLayer, targetedLayer } = this.props;

    if (!selectedLayer || !targetedLayer) {
      return EMPTY_GUIDES;
    }

    if (targetedLayer === selectedLayer) {
      return EMPTY_GUIDES;
    }

    return quadrantsForRect(
      this.props.width,
      this.props.height,
      targetedLayer.properties
    );
  }

  get dimensions(): Guide[] {
    const { targetedLayer, selectedLayer } = this.props;
    if (!selectedLayer || selectedLayer !== targetedLayer) {
      return [];
    }

    return DIMENSIONS.map((dimension) => ({
      x: selectedLayer.properties.x,
      y: selectedLayer.properties.y,
      value: selectedLayer.properties[dimension],
      [dimension]: selectedLayer.properties[dimension],
    }));
  }

  get rulers(): Guide[] {
    const { targetedLayer, selectedLayer } = this.props;
    if (!targetedLayer || !selectedLayer) {
      return [];
    }

    return rulersForRects(
      targetedLayer.properties,
      selectedLayer.properties
    ).filter((ruler) => ruler.value !== 0);
  }

  targetLayer = (layer: ?TLayerData) => {
    if (this.props.targetedLayer === layer) {
      return;
    }
    this.props.onTargetLayer && this.props.onTargetLayer(layer);
  };

  selectLayer = (layer: ?TLayerData) => {
    if (this.props.selectedLayer === layer) {
      return;
    }
    this.props.onSelectLayer &&
      this.props.onSelectLayer(layer && LayerData.key(layer));
  };

  handleMouseOut = () => {
    this.targetLayer(null);
  };

  handleKeyDown = (event: KeyboardEvent) => {
    if (eventInInput(event)) {
      return;
    }

    if (metaKey(event)) {
      this.setState({ ignoreGroups: true });
    }
  };

  handleKeyUp = (event: KeyboardEvent) => {
    if (eventInInput(event)) {
      return;
    }

    if (metaKey(event)) {
      this.setState({ ignoreGroups: false });
    }
  };

  render() {
    return (
      <div
        className={style.screen}
        style={screenStyle(this.props)}
        ref={(ref) => (this.screen = ref)}
        onMouseOut={this.handleMouseOut}
      >
        {Object.keys(this.guides).map((direction) => (
          <div
            key={direction}
            style={guideStyle(this.guides[direction], this.props)}
            className={classnames(style.guide, {
              [style.xAxis]: ["left", "right"].includes(direction),
              [style.yAxis]: ["top", "bottom"].includes(direction),
            })}
          />
        ))}
        <LayerChildren
          scale={this.props.scale}
          layers={this.props.layers}
          rootLayer={this.props.rootLayer}
          selectedLayer={this.props.selectedLayer}
          targetedLayer={this.props.targetedLayer}
          onTargetLayer={this.targetLayer}
          onSelectLayer={this.selectLayer}
          showHotspots={this.props.showHotspots}
          ignoreGroups={this.state.ignoreGroups}
        />
        {this.dimensions.map((dimension, index) => {
          const value = dimension.value;
          const isWidthUnknown =
            idx(this, (_) => _.props.selectedLayer._layoutUnknown.width) &&
            dimension.width !== undefined;

          return (
            <div
              key={index}
              style={guideStyle(dimension, this.props)}
              className={style.dimension}
            >
              {value ? (
                <div className={style.label}>
                  {isWidthUnknown ? `~${value}` : value}
                </div>
              ) : null}
            </div>
          );
        })}
        {this.rulers.map((ruler, index) => (
          <div
            key={index}
            style={guideStyle(ruler, this.props)}
            className={style.distance}
          >
            {ruler.value && (
              <div className={style.label}>
                {formatNumber(Math.abs(ruler.value))}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  }
}

function rulersForRects(targetedRect: Rect, selectedRect: Rect): Guide[] {
  const distance = distanceBetweenRects(targetedRect, selectedRect);
  const intersecting = rectsIntersect(targetedRect, selectedRect);
  const selectedMidX = selectedRect.x + selectedRect.width / 2;
  const selectedMidY = selectedRect.y + selectedRect.height / 2;

  return Object.keys(distance).map((direction) => {
    const value = distance[direction];
    const axis = DIRECTION_TO_AXIS[direction];
    const dimension = AXIS_TO_DIMENSION[axis];
    const targetedMax = targetedRect[axis] + targetedRect[dimension];
    const selectedMax = selectedRect[axis] + selectedRect[dimension];

    if (intersecting) {
      const position = MAX_DIRECTON.includes(direction)
        ? value > 0
          ? selectedMax
          : targetedMax
        : value > 0
        ? targetedRect[axis]
        : selectedRect[axis];

      return {
        x: dimension === "width" ? position : selectedMidX,
        y: dimension === "height" ? position : selectedMidY,
        [dimension]: value,
        value,
      };
    } else {
      const position = MAX_DIRECTON.includes(direction)
        ? selectedMax
        : targetedMax;

      const value = Math.max(
        MAX_DIRECTON.includes(direction)
          ? targetedRect[axis] - selectedMax
          : selectedRect[axis] - targetedMax,
        0
      );

      return {
        x: axis === "x" ? position : selectedMidX,
        y: axis === "y" ? position : selectedMidY,
        [dimension]: value,
        value,
      };
    }
  });
}

function rectsIntersect(a: Rect, b: Rect) {
  return !(
    a.x + a.width <= b.x ||
    a.y + a.height <= b.y ||
    b.x + b.width <= a.x ||
    b.y + b.height <= a.y
  );
}

function distanceBetweenRects(
  a: Rect,
  b: Rect
): {
  top: number,
  left: number,
  bottom: number,
  right: number,
} {
  if (a === b) {
    return NO_DISTANCE;
  }

  return {
    top: b.y - a.y,
    left: b.x - a.x,
    bottom: a.y + a.height - (b.y + b.height),
    right: a.x + a.width - (b.x + b.width),
  };
}

function screenStyle(props: Props): {
  width: string,
  height: string,
} {
  return {
    ...(props.style || {}),
    width: `${props.width * props.scale}px`,
    height: `${props.height * props.scale}px`,
  };
}

function guideStyle(
  guide: Guide,
  props: Props
): {
  top: string,
  left: string,
  width: ?string,
  height: ?string,
} {
  const width = guide.width
    ? `${Math.abs(guide.width) * props.scale}px`
    : undefined;

  const height = guide.height
    ? `${Math.abs(guide.height) * props.scale}px`
    : undefined;

  return {
    top: `${guide.y * props.scale}px`,
    left: `${guide.x * props.scale}px`,
    width,
    height,
  };
}

export default withProfiler<Props>(LayerInspector);
