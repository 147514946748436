// @flow
import createCachedSelector from "@elasticprojects/re-reselect";
import idx from "idx";
import { filter, find, values, pick } from "lodash";
import { Abstract } from "core/lib/abstract";
import mapLayerChildren from "core/lib/mapLayerChildren";
import naturalSortBy from "core/lib/naturalSortBy";
import { fileName } from "core/models/asset";
import {
  getCommitEntities,
  getCommitIds,
  getLatestCommitShaForLayer,
} from "core/selectors/commits";
import { getResolvedLayerDescriptor } from "core/selectors/descriptors";
import { getRawEntities } from "core/selectors/entities";
import { getLayerDataset } from "core/selectors/layerDatasets";
import { getAllLayers } from "core/selectors/layers";
import { getOrganizationForProject } from "core/selectors/organizations";
import { getOrganizationPolicy } from "core/selectors/policies";
import type {
  State,
  LayerDataset,
  LayerDataAsset,
  Asset,
  Layer as TLayer,
  Commit,
} from "core/types";

import * as Layer from "../models/layer";
import { getProjectId, getFileId, getLayerId } from "./helpers";
type Params = {
  projectId: string,
  branchId: string,
  sha: string,
  fileId: string,
  layerId: string,
  nestedLayerId: string,
};
export function getShowAssets(
  state: State,
  props: { projectId: string }
): ?boolean {
  const organization = getOrganizationForProject(state, {
    projectId: props.projectId,
  });
  if (!organization) {
    return;
  }

  const policy = getOrganizationPolicy(state, {
    organizationId: organization.id,
  });

  return policy.showAssets;
}

function cacheByParams(state: State, props: Params) {
  const { layerId, projectId, sha, fileId, nestedLayerId } = props;
  return `${layerId}-${projectId}-${sha}-${fileId}-${nestedLayerId}`;
}

const getNestedLayerId = (state: State, params: { nestedLayerId: string }) => {
  return params.nestedLayerId;
};

function getAllAssets(state: State): { [assetId: string]: Asset } {
  return getRawEntities(state, "assets");
}

function getLayerDatasetCompat(state: State, params: Params) {
  const { projectId, branchId, sha, fileId, layerId } = params;
  return getLayerDataset(
    state,
    getResolvedLayerDescriptor(state, {
      projectId,
      branchId,
      sha,
      fileId,
      layerId,
    })
  );
}

export const getParentSymbolMasterDescriptor: (
  state: State,
  params: Params
) => ?Abstract.LayerDescriptor = createCachedSelector(
  getLayerDatasetCompat,
  getNestedLayerId,
  (layerDataset, nestedLayerId) => {
    if (!layerDataset) {
      return;
    }

    let parentDescriptor: ?Abstract.LayerDescriptor;
    let layer = layerDataset.layers[nestedLayerId];
    while (layer && layer.parentId && !parentDescriptor) {
      layer = layerDataset.layers[layer.parentId];
      parentDescriptor = layer.symbolMasterDescriptor;
    }

    return parentDescriptor;
  }
)(cacheByParams);

export const getSymbolMasterDescriptor: (
  state: State,
  params: Params
) => ?Abstract.LayerDescriptor = createCachedSelector(
  getLayerDatasetCompat,
  getNestedLayerId,
  (layerDataset, nestedLayerId) => {
    if (!layerDataset) {
      return;
    }

    const symbolMasterDescriptor = idx(
      layerDataset,
      (_) => _.layers[nestedLayerId].symbolMasterDescriptor
    );
    if (
      !symbolMasterDescriptor ||
      // if these two values are equal, then the layer represented
      // by params is a symbol master, not a symbol instance,
      // in which case its data is properly represented by params
      // and we don't need a symbolMasterDescriptor.
      symbolMasterDescriptor.layerId === nestedLayerId
    ) {
      return;
    }
    return symbolMasterDescriptor;
  }
)(cacheByParams);

function getSymbolMasterCommitIds(state: State, params: Params): string[] {
  const descriptor = getSymbolMasterDescriptor(state, params);
  if (!descriptor) {
    return [];
  }
  return getCommitIds(state, descriptor);
}

function getParentSymbolMasterCommitIds(
  state: State,
  params: Params
): string[] {
  const descriptor = getParentSymbolMasterDescriptor(state, params);
  if (!descriptor) {
    return [];
  }
  return getCommitIds(state, descriptor);
}

function _getSymbolMasterLatestSha(
  dataset: ?LayerDataset,
  descriptor: ?Abstract.LayerDescriptor,
  commitEntities: { [id: string]: ?Commit },
  commitIds: string[],
  layers: { [string]: TLayer }
) {
  if (!descriptor || !dataset) {
    return;
  }

  let branchSha: ?string;
  if (descriptor.projectId === dataset.projectId) {
    branchSha = dataset.sha;
  } else {
    const commitsForEntity = values(pick(commitEntities, commitIds));
    const latestCommit = commitsForEntity[0];
    if (latestCommit) {
      branchSha = latestCommit.sha;
    }
  }
  if (!branchSha) {
    return;
  }

  const latestLayer =
    layers[Layer.uniqueId({ ...descriptor, sha: branchSha || "" })];
  return latestLayer ? latestLayer.lastChangedAtSha : null;
}

export const getSymbolMasterLatestSha: (
  state: State,
  params: Params
) => ?string = createCachedSelector(
  getLayerDatasetCompat,
  getSymbolMasterDescriptor,
  getCommitEntities,
  getSymbolMasterCommitIds,
  getAllLayers,
  (dataset, descriptor, commits, commitIds, layers) => {
    return _getSymbolMasterLatestSha(
      dataset,
      descriptor,
      commits,
      commitIds,
      layers
    );
  }
)(cacheByParams);

export const getParentSymbolMasterLatestSha: (
  state: State,
  params: Params
) => ?string = createCachedSelector(
  getLayerDatasetCompat,
  getParentSymbolMasterDescriptor,
  getCommitEntities,
  getParentSymbolMasterCommitIds,
  getAllLayers,
  (dataset, descriptor, commits, commitIds, layers) => {
    return _getSymbolMasterLatestSha(
      dataset,
      descriptor,
      commits,
      commitIds,
      layers
    );
  }
)(cacheByParams);

// Recursively get a list of current layer assets and ALL child assets.
// Note: These are assets that have been generated and are in the database
const assetsWithDescendants = (
  layerDataset: LayerDataset,
  assets: { [assetId: string]: Asset },
  layerId: string,
  options: { previews: boolean } = { previews: false }
): Asset[] => {
  let layerAssets: Asset[];
  layerAssets = filter(assets, {
    projectId: layerDataset.projectId,
    sha: layerDataset.sha,
    fileId: layerDataset.fileId,
    layerId: layerDataset.layerId,
    nestedLayerId: layerId,
    defaultAbstractFormat: options.previews,
  });

  mapLayerChildren(
    layerDataset.layers,
    layerDataset.layers[layerId],
    (childLayerData) => {
      const childAssets = assetsWithDescendants(
        layerDataset,
        assets,
        childLayerData.id,
        options
      ).map((asset) => {
        return {
          ...asset,
          _layer: childLayerData,
        };
      });
      layerAssets = layerAssets.concat(childAssets);
    }
  );

  return layerAssets;
};

// Recursively get a list of current layer assets and ALL child assets in the layerData
// Note: These are assets that are defined in the layerData response, but may not have
// actual assets generated or in the database yet.
const layerDataAssetsWithDescendants = (
  layerDataset: LayerDataset,
  layerId: string
): LayerDataAsset[] => {
  let layerAssets = layerDataset.layers[layerId].properties.assets || [];

  mapLayerChildren(
    layerDataset.layers,
    layerDataset.layers[layerId],
    (childLayerData) => {
      layerAssets = layerAssets.concat(
        layerDataAssetsWithDescendants(layerDataset, childLayerData.id)
      );
    }
  );

  return layerAssets;
};

// Assets defined in LayerData but may not have been generated
export const getAssetExports: (
  state: State,
  params: Params
) => LayerDataAsset[] = createCachedSelector(
  getLayerDatasetCompat,
  getNestedLayerId,
  (layerDataset, nestedLayerId) => {
    if (!layerDataset) {
      return [];
    }
    return layerDataAssetsWithDescendants(layerDataset, nestedLayerId);
  }
)(cacheByParams);

function _getResolvedShaForLayer(state: State, params: Params) {
  const { projectId, branchId, fileId, layerId } = params;
  if (params.sha === "latest") {
    const latestSha = getLatestCommitShaForLayer(state, {
      projectId,
      branchId,
      fileId,
      layerId,
    });
    if (latestSha) {
      return latestSha;
    }
  }
  return params.sha;
}

const _getAssetsForLayer: (state: State, params: Params) => Asset[] =
  createCachedSelector(
    [
      getAllAssets,
      getParentSymbolMasterDescriptor,
      getParentSymbolMasterLatestSha,
      getLayerId,
      getProjectId,
      _getResolvedShaForLayer,
      getFileId,
      getNestedLayerId,
    ],
    (
      allAssets,
      parentDescriptor,
      parentSha,
      layerId,
      projectId,
      sha,
      fileId,
      nestedLayerId
    ) => {
      return naturalSortBy(
        filter(allAssets, {
          projectId,
          sha,
          fileId,
          layerId,
          nestedLayerId,
        }),
        fileName
      );
    }
  )(cacheByParams);

const _getSymbolMasterAssetsForLayer: (
  state: State,
  params: Params
) => Asset[] = createCachedSelector(
  [
    getAllAssets,
    getParentSymbolMasterDescriptor,
    getParentSymbolMasterLatestSha,
    getSymbolMasterDescriptor,
    getSymbolMasterLatestSha,
    getNestedLayerId,
  ],
  (
    allAssets,
    parentDescriptor,
    parentSymbolSha,
    symbolMasterDescriptor,
    symbolMasterLatestSha,
    nestedLayerId
  ) => {
    let descriptor;
    if (symbolMasterDescriptor) {
      descriptor = {
        projectId: symbolMasterDescriptor.projectId,
        fileId: symbolMasterDescriptor.fileId,
        sha: symbolMasterLatestSha,
        layerId: symbolMasterDescriptor.layerId,
        nestedLayerId: symbolMasterDescriptor.layerId,
      };
    } else if (parentDescriptor) {
      descriptor = {
        projectId: parentDescriptor.projectId,
        fileId: parentDescriptor.fileId,
        sha: parentSymbolSha,
        layerId: parentDescriptor.layerId,
        nestedLayerId,
      };
    }

    return descriptor
      ? naturalSortBy(filter(allAssets, descriptor), fileName)
      : [];
  }
)(cacheByParams);

export const getAssetsForAllLayers: (state: State, params: Params) => Asset[] =
  createCachedSelector(
    getLayerDatasetCompat,
    getAllAssets,
    getNestedLayerId,
    (layerDataset, assets, nestedLayerId) => {
      if (!layerDataset) {
        return [];
      }
      return assetsWithDescendants(layerDataset, assets, nestedLayerId);
    }
  )(cacheByParams);

export const getAssetPreviewsForAllLayers: (
  state: State,
  params: Params
) => Asset[] = createCachedSelector(
  getLayerDatasetCompat,
  getAllAssets,
  getNestedLayerId,
  (layerDataset, assets, nestedLayerId) => {
    if (!layerDataset) {
      return [];
    }
    return assetsWithDescendants(layerDataset, assets, nestedLayerId, {
      previews: true,
    });
  }
)(cacheByParams);

export const getLayerAssets: (state: State, params: Params) => Asset[] =
  createCachedSelector([_getAssetsForLayer], (assetsForLayer) =>
    filter(assetsForLayer, { defaultAbstractFormat: false })
  )(cacheByParams);

export const getSymbolMasterLayerAssets: (
  state: State,
  params: Params
) => Asset[] = createCachedSelector(
  [_getSymbolMasterAssetsForLayer],
  (symbolMasterAssetsForLayer) =>
    filter(symbolMasterAssetsForLayer, { defaultAbstractFormat: false })
)(cacheByParams);

export const getSymbolMasterDataAssets: (
  state: State,
  params: Params
) => ?(LayerDataAsset[]) = createCachedSelector(
  getLayerDatasetCompat,
  getNestedLayerId,
  getSymbolMasterLayerAssets,
  getParentSymbolMasterDescriptor,
  (
    layerDataset,
    nestedLayerId,
    symbolMasterAssets,
    parentSymbolMasterDescriptor
  ) => {
    if (parentSymbolMasterDescriptor) {
      const layer = idx(layerDataset, (_) => _.layers[nestedLayerId]);
      return idx(layer, (_) => _.properties.assets);
    }

    return symbolMasterAssets.map((asset) => {
      return {
        fileFormat: asset.fileFormat,
        formatName: asset.formatName,
        namingScheme: asset.namingScheme,
        scale: asset.scale,
      };
    });
  }
)(cacheByParams);

export const getLayerAssetPreview: (state: State, params: Params) => ?Asset =
  createCachedSelector([_getAssetsForLayer], (assetsForLayer) =>
    find(assetsForLayer, { defaultAbstractFormat: true })
  )(cacheByParams);

export const getSymbolMasterAssetPreview: (
  state: State,
  params: Params
) => ?Asset = createCachedSelector(
  [_getSymbolMasterAssetsForLayer],
  (symbolMasterAssetsForLayer) =>
    find(symbolMasterAssetsForLayer, { defaultAbstractFormat: true })
)(cacheByParams);

export const getSymbolMasterAssetQueryParams: (
  state: State,
  params: Params
) => ?Abstract.LayerDescriptor = createCachedSelector(
  getSymbolMasterDescriptor,
  getParentSymbolMasterDescriptor,
  (descriptor, parentDescriptor) => {
    // if this layer is a child of a symbol master,
    // but not a symbol master itself, then we
    // to make sure we load assets based on the
    // parent's descriptor.
    return descriptor || parentDescriptor;
  }
)(cacheByParams);

export const getSymbolMasterAssetQuerySha: (
  state: State,
  params: Params
) => ?string = createCachedSelector(
  getSymbolMasterLatestSha,
  getParentSymbolMasterLatestSha,
  (sha, parentSha) => {
    // if this layer is a child of a symbol master,
    // but not a symbol master itself, then we
    // to make sure we load assets based on the
    // parent's sha.
    return sha || parentSha;
  }
)(cacheByParams);
