// @flow
import * as React from "react";
import { compose } from "redux";

export default function createConnector<Props: {}, OwnProps: {}>(
  // $FlowFixMe This needs to be covered with a parametric libdef
  ...hocFactories
): (
  Component: React.AbstractComponent<Props>
) => React.AbstractComponent<OwnProps> {
  // $FlowFixMe This needs to be covered with a parametric libdef
  return compose(...hocFactories);
}
