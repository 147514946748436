// @flow
import compact from "lodash/compact";

/**
 * Generates a unique ID for a form so it can stored and persisted in redux.
 *
 * @param {[]String} Any number of Strings as args representing a unique key for
 * the form. As an example, you can uniquely describe a form for a comment on a
 * commit with the commit SHA and the project ID for the commit.
 *
 * @return {String} formId
 */
export default function (...args: string[]): string {
  return compact(args).join("-");
}
