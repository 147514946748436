// @flow
import keyBy from "lodash/keyBy";
import { entitiesReceived } from "core/actions/entities";
import { ActivitiesFetchRequest } from "core/requests/activities";
import { normalizeReactions } from "core/schemas/reaction";
import type { ThunkAction, Activity, Reaction } from "core/types";

function normalizeActivitiesPage(responseData: {
  activities: Activity[],
  comments: Object,
  reactions: Reaction[],
  projects: Object,
  users: Object,
}) {
  const { activities, comments, reactions, projects, users } = responseData;
  return {
    ...normalizeReactions(reactions).entities,
    activities: keyBy(activities, "id"),
    comments: keyBy(comments, "id"),
    projects: keyBy(projects, "id"),
    users: keyBy(users, "id"),
  };
}

export function fetchActivities(params: {
  organizationId?: string,
  projectId?: string,
  branchId?: string,
  offset?: number,
  limit?: number,
}): ThunkAction {
  return async function (dispatch) {
    return dispatch(
      ActivitiesFetchRequest.perform({
        params,
        onSuccess: (response) => {
          const normalizedData = normalizeActivitiesPage(response.data);
          dispatch(entitiesReceived(normalizedData));
        },
      })
    );
  };
}
