// @flow
import * as React from "react";
import type { CommitType } from "core/types";
import style from "./style.scss";

const commitTypeLabels: { [CommitType]: string } = {
  BRANCH: "Branched from",
  UPDATE: "Updated from",
  MERGE: "Merged",
  RESTORE: "Restored",
};

type Props = {
  title: string,
  type?: CommitType,
};

export default function CommitTitle({ title, type, ...rest }: Props) {
  const branchName = title.match(/["“](.*)["”]/);

  if (type && branchName) {
    const label = commitTypeLabels[type];

    if (label) {
      return (
        <span {...rest}>
          {label} <em className={style.branchName}>{branchName[1]}</em>
        </span>
      );
    }
  }

  return <span {...rest}>{title}</span>;
}
