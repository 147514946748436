// @flow
import classnames from "classnames";
import * as React from "react";
import Icon from "core/components/Icon";
import Popover from "core/components/Popover";
import { BranchStatus } from "core/gitConstants";
import {
  branchStatusReadable,
  branchStatusIcon,
  branchStatusType,
} from "core/lib/branches";
import style from "./style.scss";

type Props = {
  status?: string,
  selected?: boolean,
};

export default function BranchStatusDot({ status, selected }: Props) {
  if (
    !status ||
    status === "" ||
    status === BranchStatus.WIP ||
    status === BranchStatus.NONE
  ) {
    return <div />;
  }
  const label = branchStatusReadable(status);
  const icon = branchStatusIcon(status);
  const type = branchStatusType(status);
  const typeStyle = Object.keys(style).includes(type) && style[type];

  return (
    <Popover label={label} placement="bottom">
      <div
        className={classnames(style.dot, typeStyle, {
          [style.selected]: selected,
        })}
      >
        <Icon fill="currentColor" type={icon} className={style.icon} />
      </div>
    </Popover>
  );
}
