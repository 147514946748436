// @flow
import Pusher from "@elasticprojects/pusher-js";
import EventEmitter from "events";
import { getAuthHeader } from "core/lib/authedDownload";
import "pusher-js-auth";

function pusherChannelName(id) {
  return `private-${id}`;
}

export default class Socket extends EventEmitter {
  pusher: Pusher;

  constructor(pusherAppKey: string, authEndpoint: string, authToken: string) {
    super();
    this.pusher = new Pusher(pusherAppKey, {
      authEndpoint: authEndpoint,
      authTransport: "buffered",
      authDelay: 200,
      auth: {
        // getAuthHeader handles authorization by:
        // - a user's token when logged in AND/or
        // - a shareToken when on a public share link
        headers: getAuthHeader(authToken),
      },
      encrypted: true,
    });

    this.pusher.connection.bind("state_change", (states) => {
      this.emit(states.current);
    });
  }

  // Subscribes to updates from the entity with id.
  subscribe(id: string) {
    const channel = this.pusher.subscribe(pusherChannelName(id));
    channel.bind_global((type, data) => {
      this.emit("message", type, data);
    });
  }

  // Unsubscribes from the entity with id.
  unsubscribe(id: string) {
    const channelName = pusherChannelName(id);
    const channel = this.pusher.channel(channelName);
    if (channel) {
      channel.unbind();
    }
    this.pusher.unsubscribe(channelName);
  }

  // Disconnects the pusher socket.
  disconnect() {
    this.pusher.disconnect();
  }
}
