// @flow
import classnames from "classnames";
import * as React from "react";
import { useDispatch } from "react-redux";
import { loadOrganizationMemberships } from "core/actions/memberships";
import BranchReview from "core/components/BranchReview";
import NoBranchReviews from "core/components/Empty/NoBranchReviews";
import Flex from "core/components/Flex";
import Loaded from "core/components/Loaded";
import Scrollable from "core/components/Scrollable";
import { useBranchReviews } from "core/hooks/reviews";
import useLocalStorage from "core/hooks/useLocalStorage";
import { useCurrentUserId } from "core/hooks/users";
import { Abstract } from "core/lib/abstract";
import naturalSortBy from "core/lib/naturalSortBy";
import { isWeb } from "core/lib/platform";
import { REVIEWS_TAB_KEY } from "core/models/localStorage";
import style from "./style.scss";

type Props = {
  ...Abstract.OrganizationDescriptor,
  projectId?: string,
  mobile?: boolean,
  className?: string,
  filter?: "assigned-to-me",
};

const OrganizationBranchesReviews = (props: Props) => {
  const { organizationId, mobile, className, projectId, filter } = props;

  const currentUserId = useCurrentUserId();

  const dispatch = useDispatch();

  const assignedToMe = filter === "assigned-to-me";

  const { branchReviews, loading: isLoading } = useBranchReviews({
    organizationId,
    reviewerId: assignedToMe ? currentUserId : undefined,
    projectId,
  });

  const [lastReviewsTabVisited, setLastReviewsTabVisited] = useLocalStorage(
    REVIEWS_TAB_KEY,
    "",
    { subscribe: true }
  );

  React.useEffect(() => {
    if (isWeb) {
      dispatch(loadOrganizationMemberships(organizationId));
    }
  }, [dispatch, organizationId]);

  // sets the last visited reviews tab to "branches-reviews"
  React.useEffect(() => {
    if (lastReviewsTabVisited !== "branches-reviews") {
      setLastReviewsTabVisited("branches-reviews");
    }
  }, [lastReviewsTabVisited, setLastReviewsTabVisited]);

  const hasReviews = branchReviews.length > 0;

  const showLoading = isLoading && !hasReviews;

  return (
    <Flex column className={classnames(style.wrap, className)}>
      <Loaded loading={showLoading} title="Loading reviews…" flex>
        {() => {
          if (!hasReviews) {
            return (
              <NoBranchReviews
                hasReviewer={!!currentUserId}
                organizationId={organizationId}
                flex
              />
            );
          }

          return (
            <Scrollable className={style.dynamicPadding}>
              {naturalSortBy(branchReviews, "createdAt", {
                direction: "desc",
              }).map((review) => (
                <BranchReview key={review.id} review={review} mobile={mobile} />
              ))}
            </Scrollable>
          );
        }}
      </Loaded>
    </Flex>
  );
};

export default OrganizationBranchesReviews;
