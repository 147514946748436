// @flow
interface Rect {
  x: number;
  y: number;
  width: number;
  height: number;
}

export default function quadrantsForRect(
  width: number,
  height: number,
  rect: Rect
): {
  top?: Rect,
  left?: Rect,
  bottom?: Rect,
  right?: Rect,
} {
  const quadrants = {};

  if (rect.y > 0) {
    quadrants.top = {
      x: rect.x,
      y: 0,
      width: rect.width,
      height: rect.y,
    };
  }

  if (rect.x > 0) {
    quadrants.left = {
      x: 0,
      y: rect.y,
      width: rect.x,
      height: rect.height,
    };
  }

  if (height - (rect.y + rect.height) > 0) {
    quadrants.bottom = {
      x: rect.x,
      y: rect.y + rect.height,
      width: rect.width,
      height: height - (rect.y + rect.height),
    };
  }

  if (width - (rect.x + rect.width) > 0) {
    // Right
    quadrants.right = {
      x: rect.x + rect.width,
      y: rect.y,
      width: width - (rect.x + rect.width),
      height: rect.height,
    };
  }

  return quadrants;
}
