// @flow
import * as React from "react";
import CommitsList from "core/components/CommitsList";
import NoCommits from "core/components/Empty/NoCommits";
import Head from "core/components/Head";
import Loaded from "core/components/Loaded";
import { Abstract } from "core/lib/abstract";
import type { Commit, Branch, Project } from "core/types";
import BranchPage from "../BranchShell/BranchPage";
import style from "./mobile.scss";

type Props = {
  isLoading: boolean,
  commits: Commit[],
  commitCommentCounts: { [sha: string]: number },
  commitPath: (sha: string) => string,
  children: React.Node,
  params: Abstract.BranchDescriptor | Abstract.BranchCommitDescriptor,
  project: ?Project,
  branch: ?Branch,
  parentBranch: ?Branch,
  onLoadMore?: () => void,
  isOffline: boolean,
};

export default function BranchCommitsMobile(props: Props) {
  if (props.isLoading) {
    return <Loaded loading title="Loading commits…" flex />;
  }

  if (props.params.sha) {
    return props.children;
  }
  const isEmpty = !props.commits.length;

  return (
    <BranchPage>
      <Head>
        <title>Commits</title>
      </Head>
      {isEmpty ? (
        <NoCommits className={style.empty} flex />
      ) : (
        <CommitsList params={props.params} />
      )}
    </BranchPage>
  );
}
