// @flow
import * as React from "react";
import { connect } from "react-redux";
import Error from "core/components/Empty/Error";
import File from "core/components/File";
import Head from "core/components/Head";
import Loaded from "core/components/Loaded";
import Media from "core/components/Media";
import { replace, addQuery, removeQuery } from "core/lib/location";
import { layerLocation } from "core/lib/routes";
import * as Branch from "core/models/branch";
import * as Page from "core/models/page";
import * as Request from "core/models/request";
import { LayersFetchAllRequest } from "core/requests/layers";
import { PagesFetchRequest } from "core/requests/pages";
import { getBranch } from "core/selectors/branches";
import { getChangeset } from "core/selectors/changesets";
import { getCommentCounts } from "core/selectors/comments";
import { getFeatureEnabled } from "core/selectors/features";

import { getFile, getFileHasChanged } from "core/selectors/files";
import {
  getPreviewsForFileOnBranch,
  getChangeStatusCountsForFileOnBranch,
  getChangedLayersByStatusForFileOnBranch,
  getLayersForFileOnBranch,
  getLayerStatusesForFileOnBranch,
} from "core/selectors/layers";
import { getLibraryForPage } from "core/selectors/libraries";
import { getOrganizationForProject } from "core/selectors/organizations";
import { getPage, getPagesForFileOnBranch } from "core/selectors/pages";
import { getProjectPolicy } from "core/selectors/policies";
import type {
  Dispatch,
  State as AppState,
  FilePreviews,
  Branch as TBranch,
  File as TFile,
  Page as TPage,
  Layer,
  Library,
  Project,
  Changeset,
  ChangesetStatus,
  ChangeStatusCounts,
} from "core/types";
import { loadPages } from "web/actions/pages";
import { loadProject } from "web/actions/projects";
import ExternalLibrary from "web/components/ExternalLibrary";
import { getZoom, setZoom } from "web/layerZoom";
import { getLibrary } from "web/selectors/libraries";
import { getProject } from "web/selectors/projects";

const LAYER_LIMIT = 10;

type OwnProps = {|
  params: {
    projectId: string,
    branchId: string,
    fileId: string,
    pageId?: string,
  },
  location?: {
    query: {
      filter?: string,
      s?: string,
      isExternalLibrary: string,
    },
  },
  query?: string,
  filter?: string,
  onFilterChange?: (filter?: string) => void,
  onClearFilters?: () => void,
  onSearch?: (query: string) => void,
  isSelecting?: boolean,
|};

type StateProps = {|
  key: string,
  branch: ?TBranch,
  project: ?Project,
  file: ?TFile,
  page?: ?TPage,
  pages: { [pageId: string]: TPage },
  previewsByFile: FilePreviews,
  layers: Layer[],
  layerCommentCounts: { [layerId: string]: number },
  layerOffset: number,
  layerStatuses: { [layerId: string]: ChangesetStatus },
  layerStatusCounts?: ChangeStatusCounts,
  query?: string,
  filter?: string,
  hasError: boolean,
  hasLoaded: boolean,
  isLoadingPages: boolean,
  isExternalLibrary: boolean,
  isChangedFile: boolean,
  canCreateCommit: boolean,
  library: ?Library,
  changeset: ?Changeset,
  isLatestCommitFeatureEnabled: boolean,
|};

type DispatchProps = {|
  loadProject: (projectId: string) => void,
  loadPages: (sha?: string) => void,
  loadLayers: (
    sha: string,
    params: { pageId?: string, limit: number, offset: number },
    onHasMore: (hasMore: boolean) => void
  ) => void,
|};

type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type State = {
  zoom: number,
  hasMore: boolean,
  isLoading: boolean,
  isLoadingMore: boolean,
  isLoadingMoreSearch: boolean,
};

class FileContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super();
    const isChanged = props.filter && props.isChangedFile;
    this.state = {
      zoom: getZoom(),
      hasMore: !isChanged,
      isLoading: !isChanged,
      isLoadingMore: false,
      isLoadingMoreSearch: false,
    };
  }

  componentDidMount() {
    if (!this.props.isExternalLibrary) {
      if (this.props.branch) {
        this.props.loadPages(this.props.branch.head);
      }
      if (
        this.props.library &&
        this.props.library.projectId &&
        !this.props.project
      ) {
        this.props.loadProject(this.props.library.projectId);
      }
      this.handleFirstLoad();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (!this.props.isExternalLibrary) {
      if (
        !prevProps.library &&
        this.props.library &&
        this.props.library.projectId &&
        !this.props.project
      ) {
        this.props.loadProject(this.props.library.projectId);
      }
      if (this.props.query) {
        return this.handleLoadMore();
      }
    }

    if (prevProps.filter && !this.props.filter && !this.props.hasLoaded) {
      this.loadLayers();
    }
  }

  loadLayers = () => {
    this.setState({ isLoadingMore: true });

    const sha = this.props.branch ? this.props.branch.head : "";
    const params = {
      pageId: this.props.params.pageId,
      limit: LAYER_LIMIT,
      offset: this.props.layerOffset,
    };

    this.props.loadLayers(sha, params, (hasMore) => {
      const shouldLoadMore =
        hasMore && !!this.props.query && !this.props.filter;
      this.setState({
        hasMore,
        isLoading: false,
        isLoadingMore: false,
        isLoadingMoreSearch: shouldLoadMore,
      });
      if (shouldLoadMore) {
        this.loadLayers();
      }
    });
  };

  handleFirstLoad = () => {
    if (this.props.filter && this.props.isChangedFile) {
      return;
    }

    const offset = this.props.layerOffset;

    if (this.props.hasLoaded) {
      return this.setState({
        isLoading: false,
        hasMore: offset ? !(offset % LAYER_LIMIT) : false,
      });
    }

    this.loadLayers();
  };

  handleLoadMore = () => {
    if (
      this.state.isLoading ||
      this.state.isLoadingMore ||
      !this.state.hasMore ||
      this.props.filter
    ) {
      return;
    }
    this.loadLayers();
  };

  handleFilterChange = (filter?: string) => {
    const { onFilterChange } = this.props;

    if (onFilterChange) {
      return onFilterChange(filter);
    }

    if (filter && filter !== "all") {
      replace(addQuery({ filter }));
    } else {
      replace(removeQuery("filter"));
    }
  };

  handleClearFilters = () => {
    if (this.props.onClearFilters) {
      return this.props.onClearFilters();
    }

    replace(removeQuery("s", "filter"));
  };

  handleSearch = (query: string) => {
    if (this.props.onSearch) {
      return this.props.onSearch(query);
    }

    if (query) {
      replace(addQuery({ s: query }));
    } else {
      replace(removeQuery("s"));
    }
  };

  handleZoomChange = (subject: string, zoom: number) => {
    setZoom(zoom);
    this.setState({ zoom });
  };

  getLayerPath = (layer: Layer) => {
    if (this.props.isLatestCommitFeatureEnabled) {
      return layerLocation(
        this.props.params.projectId,
        this.props.params.branchId,
        "latest",
        layer.fileId,
        layer.id,
        {
          layerSetParams: {
            type: "file",
            projectId: this.props.params.projectId,
            branchId: this.props.params.branchId,
            sha: "latest",
            fileId: this.props.params.fileId,
            pageId: this.props.params.pageId,
            // pagination + manual search filter is really weird. If we have a
            // search filter, we'll just assume that what you see when you click
            // the layer is what you get in terms of layer set.
            hasMore: this.state.hasMore && !this.props.query,
            filter: this.props.filter,
            search: this.props.query,
          },
        }
      );
    }
    return layerLocation(
      this.props.params.projectId,
      this.props.params.branchId,
      layer.lastChangedAtSha,
      layer.fileId,
      layer.id,
      {
        layerSetParams: {
          type: "file",
          projectId: this.props.params.projectId,
          branchId: this.props.params.branchId,
          sha: this.props.branch ? this.props.branch.head : "",
          fileId: this.props.params.fileId,
          pageId: this.props.params.pageId,
          // pagination + manual search filter is really weird. If we have a
          // search filter, we'll just assume that what you see when you click
          // the layer is what you get in terms of layer set.
          hasMore: this.state.hasMore && !this.props.query,
          filter: this.props.filter,
          search: this.props.query,
        },
      }
    );
  };

  render() {
    const { hasMore, isLoading, isLoadingMore, isLoadingMoreSearch } =
      this.state;

    return (
      <React.Fragment>
        {this.props.file && this.props.location && (
          <Head>
            <title>{this.props.file.name}</title>
          </Head>
        )}
        {this.props.isExternalLibrary ? (
          <ExternalLibrary library={this.props.library} />
        ) : (
          <Media desktop>
            {(isDesktop) => {
              const { branch, file } = this.props;
              if (!file || !branch) {
                return isLoading ? <Loaded loading /> : <Error />;
              }

              return (
                <File
                  {...this.props.params}
                  params={this.props.params}
                  branch={branch}
                  project={this.props.project}
                  library={this.props.library}
                  file={file}
                  page={this.props.page}
                  pages={this.props.pages}
                  changeset={this.props.changeset}
                  previewsByFile={this.props.previewsByFile}
                  layers={this.props.layers}
                  layerCommentCounts={this.props.layerCommentCounts}
                  layerStatuses={this.props.layerStatuses}
                  layerStatusCounts={this.props.layerStatusCounts}
                  filter={this.props.filter}
                  query={this.props.query}
                  zoom={this.state.zoom}
                  hasError={this.props.hasError}
                  hasMore={hasMore && !this.props.filter}
                  canCreateCommit={this.props.canCreateCommit}
                  isLoading={
                    !this.props.filter &&
                    (isLoading || this.props.isLoadingPages)
                  }
                  isLoadingMore={
                    !this.props.filter && (isLoadingMore || isLoadingMoreSearch)
                  }
                  isChangedFile={this.props.isChangedFile}
                  isMobile={!isDesktop}
                  isSelecting={this.props.isSelecting}
                  getLayerPath={this.getLayerPath}
                  onLoadMore={this.handleLoadMore}
                  onFilterChange={this.handleFilterChange}
                  onClearFilters={this.handleClearFilters}
                  onSearch={this.handleSearch}
                  onZoomChange={this.handleZoomChange}
                />
              );
            }}
          </Media>
        )}
      </React.Fragment>
    );
  }
}

function layersRequestParams(props: OwnProps, sha, page: ?TPage) {
  const { projectId, branchId, fileId, pageId } = props.params;
  const descriptor = props.params.pageId
    ? { projectId, branchId, sha, fileId, pageId }
    : { projectId, branchId, sha, fileId };

  return {
    ...descriptor,
    fromLibraries: Page.isLibrary(page) ? "only" : "exclude",
  };
}

function mapStateToProps(state: AppState, props: OwnProps): StateProps {
  const { projectId, branchId, fileId, pageId } = props.params;
  let { location, filter, query } = props;

  filter = location ? location.query.filter : filter;
  query = location ? location.query.s : query;

  const policy = getProjectPolicy(state, { projectId });
  const branch = getBranch(state, { projectId, branchId });
  const sha = branch ? branch.head : "";

  const isExternalLibrary = !!(location && location.query.isExternalLibrary);
  const isMasterBranch = branch && Branch.isMaster(branch);
  const isChangedFile = getFileHasChanged(state, {
    projectId,
    branchId,
    fileId,
  });

  const layerParams = { projectId, branchId, fileId, pageId, head: sha };
  const allLayers = getLayersForFileOnBranch(state, layerParams);
  let layers = allLayers;

  if (filter && !isMasterBranch && isChangedFile) {
    layers = getChangedLayersByStatusForFileOnBranch(state, {
      ...layerParams,
      status: filter,
      excludeDeleted: true,
    });
  }

  const pagesRequest = PagesFetchRequest.getRequest(state, {
    projectId,
    branchId,
    fileId,
    sha,
  });

  const page =
    sha && pageId
      ? getPage(state, { projectId, sha, fileId, pageId })
      : undefined;

  const layersRequest = LayersFetchAllRequest.getRequest(
    state,
    layersRequestParams(props, sha, page)
  );

  let library = null;
  if (isExternalLibrary) {
    library = getLibrary(state, { projectId, branchId, fileId });
  } else if (pageId) {
    library = getLibraryForPage(state, {
      sha,
      projectId,
      fileId: pageId,
    });
  }

  const organization = getOrganizationForProject(state, {
    projectId,
  });

  return {
    key: [projectId, branchId, fileId, pageId || ""].join("-"),
    branch,
    project: library ? getProject(state, library.projectId || "") : null,
    page,
    file: sha ? getFile(state, { projectId, sha, fileId }) : undefined,
    previewsByFile: getPreviewsForFileOnBranch(state, {
      ...layerParams,
      status: filter,
    }),
    changeset: getChangeset(state, { projectId, branchId }),
    pages: getPagesForFileOnBranch(state, { projectId, branchId, fileId }),
    layers,
    layerCommentCounts: getCommentCounts(state, {
      projectId,
      branchId,
      key: "layerId",
    }),
    layerOffset: allLayers.length,
    layerStatuses: getLayerStatusesForFileOnBranch(state, layerParams),
    layerStatusCounts:
      !isMasterBranch && isChangedFile
        ? getChangeStatusCountsForFileOnBranch(state, layerParams)
        : undefined,
    filter,
    query,
    isChangedFile,
    isExternalLibrary,
    library,
    canCreateCommit: policy.createCommit === true,
    isLoadingPages: Request.isLoading(pagesRequest),
    hasError: Request.hasError(layersRequest, pagesRequest),
    hasLoaded: !!layersRequest.updatedAt,
    isLatestCommitFeatureEnabled: organization
      ? getFeatureEnabled(state, {
          organizationId: organization.id,
          feature: "latest-commit-enabled",
        })
      : false,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { projectId, branchId, fileId } = props.params;
  return {
    loadPages: (sha) => {
      dispatch(loadPages(projectId, branchId, fileId, sha));
    },
    loadLayers: (sha, params, onHasMore) => {
      dispatch((dispatch, getState) => {
        const state = getState();
        const page = params.pageId
          ? getPage(state, {
              projectId,
              sha,
              fileId,
              pageId: params.pageId,
            })
          : null;

        return dispatch(
          LayersFetchAllRequest.perform({
            params: layersRequestParams(props, sha, page),
            onSuccess: () => onHasMore(false),
          })
        );
      });
    },
    loadProject: (projectId: string) => {
      dispatch(loadProject(projectId));
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  AppState,
  Dispatch,
>(
  mapStateToProps,
  mapDispatchToProps
)(FileContainer);
