// @flow
import { useSelector } from "react-redux";
import {
  getProject,
  getProjects,
  getActiveProjectsForOrganization,
} from "core/selectors/projects";

export const useProject = (projectId: string) => {
  return useSelector((state) => getProject(state, { projectId }));
};

export const useProjects = ({
  organizationId,
  active,
}: {
  organizationId: string,
  active?: boolean,
}) => {
  return useSelector((state) => {
    if (active) {
      return getActiveProjectsForOrganization(state, { organizationId });
    }

    return getProjects(state, { organizationId });
  });
};
