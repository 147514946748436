// @flow
import * as File from "core/models/file";
import type { Page } from "core/types";

type IdParams =
  | { projectId: string, sha: string, fileId: string, pageId: string }
  | Page;

export function uniqueId(params: IdParams) {
  const { projectId, sha, fileId } = params;

  let id: string = "";
  if (typeof params.pageId === "string") {
    id = params.pageId;
  } else if (typeof params.id === "string") {
    id = params.id;
  }
  return `${projectId}-${sha}-${fileId}-${id}`;
}

export function isLibrary(page: ?Page): boolean {
  return !!page && page.type === "library";
}

export function icon(page: ?{ id: string }): string {
  if (!page) {
    return "file-page";
  }
  const contentType = File.contentTypes[page.id];
  return contentType ? contentType.icon : "file-page";
}
