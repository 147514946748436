// @flow
import * as React from "react";
import UserCard from "core/components/UserCard";
import { equal } from "core/models/user";
import type {
  TeamMembership,
  OrganizationTeamDescriptor,
  User,
  Membership,
  Policy,
} from "core/types";
import TeamMemberMenu from "./TeamMemberMenu";
import connector from "./connector";
export type OwnProps = {|
  user: User,
  params: OrganizationTeamDescriptor,
  isCard: boolean,
  isMobile: boolean,
|};

export type StateProps = {|
  currentUser: ?User,
  teamName: ?string,
  organizationName: ?string,
  organizationMembership: ?Membership,
  teamMembership: ?TeamMembership,
  teamPolicy: Policy,
  isLoadingDeleteTeamMembership: boolean,
|};

export type DispatchProps = {|
  onDowngradeToMember: () => void,
  onUpgradeToAdmin: () => void,
  onRemoveMember: () => void,
|};

export type Props = {
  ...OwnProps,
  ...DispatchProps,
  ...StateProps,
};

function OrganizationTeamMemberCard(props: Props) {
  const {
    user,
    currentUser,
    isCard,
    isMobile,
    teamName,
    teamPolicy,
    organizationName,
    organizationMembership,
    teamMembership,
    isLoadingDeleteTeamMembership,
  } = props;

  const isCurrentUser = equal(currentUser, user);
  const showActions =
    isCurrentUser || teamPolicy.updateMember || teamPolicy.removeMember;

  return (
    <UserCard
      user={user}
      card={isCard}
      mobile={isMobile}
      isMembershipLoading={!organizationMembership || !teamMembership}
      contributor={
        organizationMembership
          ? organizationMembership.subscriptionRole === "contributor"
          : undefined
      }
      guest={
        organizationMembership
          ? organizationMembership.role === "guest"
          : undefined
      }
      admin={
        organizationMembership
          ? organizationMembership.role === "owner"
          : undefined
      }
      teamAdmin={teamMembership ? teamMembership.isAdmin : undefined}
      actions={
        showActions ? (
          <TeamMemberMenu
            user={user}
            isTeamAdmin={!!teamMembership && teamMembership.isAdmin}
            isGuest={
              organizationMembership
                ? organizationMembership.role === "guest"
                : undefined
            }
            teamName={teamName}
            onRemoveMember={props.onRemoveMember}
            organizationName={organizationName}
            onUpgradeToAdmin={props.onUpgradeToAdmin}
            onDowngradeToMember={props.onDowngradeToMember}
            teamPolicy={props.teamPolicy}
            isCurrentUser={isCurrentUser}
            isLoadingDeleteTeamMembership={isLoadingDeleteTeamMembership}
          />
        ) : undefined
      }
    />
  );
}

export default connector(OrganizationTeamMemberCard);
