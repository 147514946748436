// @flow
import invariant from "invariant";
import React, { Component } from "react";
import { connect } from "react-redux";
import { restoreCommit } from "abstract-di/actions";
import { dismissDialog } from "core/actions/dialogs";
import CommitListItem from "core/components/CommitListItem";
import DialogForm from "core/components/DialogForm";
import InputMarkdown from "core/components/InputMarkdown";
import Spoiler from "core/components/Spoiler";
import { getBranch, getParentBranch } from "core/selectors/branches";
import { getCommit } from "core/selectors/commits";
import { getProject } from "core/selectors/projects";
import type { Project, Branch, Commit, State, Dispatch } from "core/types";
import Validations from "core/validations";
import style from "./style.scss";

type OwnProps = {|
  projectId: string,
  branchId: string,
  sha: string,
|};

type StateProps = {|
  project: ?Project,
  branch: ?Branch,
  commit: ?Commit,
  parent: ?Branch,
|};

type DispatchProps = {|
  restoreCommit: (
    projectId: string,
    branchId: string,
    sha: string,
    title: string,
    notes: string
  ) => void,
  dismissDialog: () => void,
|};

type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

class ConfirmRestoreCommit extends Component<Props, { notes: string }> {
  state = { notes: "" };

  notesChanged = (value: string) => {
    this.setState({ notes: value });
  };

  handleSubmit = (ev: SyntheticEvent<>) => {
    ev.preventDefault();

    invariant(this.props.commit, "commit required to restore");

    this.props.restoreCommit(
      this.props.projectId,
      this.props.branchId,
      this.props.sha,
      this.props.commit.title,
      this.state.notes
    );
    this.props.dismissDialog();
  };

  render() {
    const { project, branch, commit, parent, dismissDialog } = this.props;

    return (
      <DialogForm
        title="Restore Commit"
        primaryButton="Restore Commit"
        onClose={dismissDialog}
        onSubmit={this.handleSubmit}
        isOpen
      >
        {branch && (
          <p data-qa="branch-to-commit-restriction">
            You are about to restore the branch <em>{branch.name}</em> to this
            commit.
          </p>
        )}
        {commit && (
          <CommitListItem
            project={project}
            branch={branch}
            commit={commit}
            parent={parent}
            className={style.commit}
            first
            last
          />
        )}
        <p data-qa="revert-process-description">
          A new commit will be created that restores your files to how they were
          at this point in time, while keeping all the history of changes.
        </p>
        <Spoiler label="Add a note (optional)" qaSelector="add-note-button">
          <InputMarkdown
            name="notes"
            projectId={this.props.projectId}
            placeholder="Add some additional context for this commit restore…"
            value={this.state.notes}
            onChange={this.notesChanged}
            maxLength={Validations.maxProjectAboutLength}
            minHeight={200}
            onSubmit={this.handleSubmit}
            focusClass={style.inputFocus}
            autoFocus
            qaSelector="add-note-input-field"
          />
        </Spoiler>
      </DialogForm>
    );
  }
}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const project = getProject(state, props);
  const parent = getParentBranch(state, {
    projectId: props.projectId,
    branchId: props.branchId,
  });

  return {
    project,
    branch: getBranch(state, props),
    commit: getCommit(state, props),
    parent,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    dismissDialog: () => dispatch(dismissDialog()),
    restoreCommit: (
      projectId: string,
      branchId: string,
      sha: string,
      title: string,
      notes: string
    ) => {
      invariant(restoreCommit, "restoreCommit required for branch action");
      dispatch(restoreCommit(projectId, branchId, sha, title, notes));
    },
  };
}

export default connect<Props, OwnProps, StateProps, _, State, Dispatch>(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmRestoreCommit);
