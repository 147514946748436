// @flow
import { connect } from "react-redux";
import { replace } from "core/lib/location";
import * as Request from "core/models/request";
import type { State, Dispatch } from "core/types";
import { VerifyEmailRequest } from "web/requests/account";
import { signupJoinOrganization } from "web/routeHelpers";
import type { OwnProps, StateProps, DispatchProps, Props } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const request = VerifyEmailRequest.getRequest(state, {
    id: props.params.token,
  });

  return {
    error: Request.hasError(request),
    loading: Request.isLoadingStrict(request),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    verifyEmail: () => {
      dispatch(
        VerifyEmailRequest.perform({
          params: { id: props.params.token },
          onSuccess: () => {
            replace({
              pathname: signupJoinOrganization(),
              state: {
                onboarding: true,
              },
            });
          },
        })
      );
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
