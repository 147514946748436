// @flow
import { connect } from "react-redux";
import { withData } from "core/components/DataLoader";
import createConnector from "core/lib/createConnector";
import type { State, Dispatch } from "core/types";
import AccessTokens, {
  type Props,
  type OwnProps,
  type StateProps,
  type DispatchProps,
} from "web/components/ProfileSettings/AccessTokens";
import {
  AccessTokensRequest,
  AccessTokenCreateRequest,
} from "web/requests/accessTokens";
import { getAllAccessTokens } from "web/selectors/accessTokens";

function mapStateToProps(state: State): StateProps {
  return {
    isLoading: AccessTokensRequest.isFirstLoading(state),
    isSubmitting: AccessTokenCreateRequest.isLoadingStrict(state),
    error: AccessTokenCreateRequest.hasError(state),
    accessTokens: getAllAccessTokens(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    onSubmit: (params: { description: string }) =>
      dispatch(AccessTokenCreateRequest.perform({ params })),
    onLoad: () => dispatch(AccessTokensRequest.perform({ params: {} })),
  };
}

export default createConnector<Props, OwnProps>(
  connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch>(
    mapStateToProps,
    mapDispatchToProps
  ),
  withData((props) => props.params)
)(AccessTokens);
