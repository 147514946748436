// @flow
import { omit } from "lodash";
import history from "abstract-di/history";
import {
  getCurrentV3Location,
  v6Location,
  v6LocationDescriptor,
} from "core/lib/router";
import type { LocationDescriptor, ReactRouterLocation } from "core/types";

export const getCurrentLocation = getCurrentV3Location;

export function push(location: LocationDescriptor) {
  history.push(v6LocationDescriptor(location), location.state || undefined);
}

export function replace(location: LocationDescriptor) {
  history.replace(v6LocationDescriptor(location), location.state || undefined);
}

export function goBack() {
  history.back();
}

export function setLocationState(
  location: ReactRouterLocation,
  newState: mixed
) {
  const state = location.state ? location.state : {};
  replace({ ...location, state: { ...state, ...newState } });
}

export function addQuery(query: { [string]: string }): ReactRouterLocation {
  const location = getCurrentLocation();

  return v6Location({
    ...location,
    query: { ...location.query, ...query },
  });
}

export const removeQuery = (...queryNames: string[]): ReactRouterLocation => {
  const location = getCurrentLocation();

  queryNames.forEach((q) => location.query && delete location.query[q]);

  return v6Location(location);
};

export const addAndRemoveQueryParams = (
  paramsToAdd: { [string]: string },
  paramsToRemove: string[]
): ReactRouterLocation => {
  const location = getCurrentLocation();

  return v6Location({
    ...location,
    query: omit({ ...location.query, ...paramsToAdd }, ...paramsToRemove),
  });
};

export const toggleQuery = (query: { [string]: string }) => {
  const location = history.getCurrentLocation();
  let paramsToAdd = {};
  let paramsToRemove = [];

  Object.keys(query).forEach((q) => {
    if (location.query[q]) {
      paramsToRemove.push(q);
    } else {
      paramsToAdd[q] = query[q];
    }
  });
  return addAndRemoveQueryParams(paramsToAdd, paramsToRemove);
};
