// @flow
import classnames from "classnames";
import { omit, debounce } from "lodash";
import * as React from "react";
import Icon from "core/components/Icon";
import InputWrapper from "core/components/InputWrapper";
import { stripNewlines as sanitize } from "core/lib/textFormatting";
import style from "./style.scss";

let counter = 0;

export type Props = {
  defaultValue?: ?string,
  error?: *,
  id?: string,
  icon?: string,
  label?: React.Node,
  sublabel?: string,
  maxLength?: number,
  showLengthWarning: number,
  onChange?: (ev: SyntheticInputEvent<HTMLInputElement>) => mixed,
  helpers: {
    showPassword?: React.Node,
    [key: string]: React.Node,
  },
  responsive?: boolean,
  optionalTag?: boolean,
  requiredTag?: boolean,
  iconClass?: string,
  wrapperClass?: string,
  selected?: boolean,
  stripNewlines?: boolean,
  autoFocus?: boolean,
  selectOnFocus?: boolean,
  visuallyHidden?: boolean,
  debounce?: number,
  qaSelector?: string,
  innerContextTag?: string,
  onFocus?: (ev: SyntheticInputEvent<>) => mixed,
};

type State = {
  charsRemaining?: number,
};

export default class Input extends React.Component<Props, State> {
  id: string;
  input: HTMLInputElement;

  static defaultProps = {
    error: null,
    showLengthWarning: 20,
    helpers: {},
  };

  state = {};

  constructor(props: Props) {
    super(props);
    this.id = `abstract-core-input-${counter}`;
    counter += 1;
  }

  componentDidMount() {
    if (this.props.selected) {
      this.input.select();
    }
  }

  focus() {
    if (this.input) {
      this.input.focus();
    }
  }

  deferHandleChange = (event: SyntheticInputEvent<*>) => {
    event.persist();

    if (this.props.debounce) {
      this.debouncedHandleChange(event);
    } else {
      this.handleChange(event);
    }
  };

  handleChange = (ev: SyntheticInputEvent<*>) => {
    const { onChange, stripNewlines } = this.props;
    if (stripNewlines) {
      ev.target.value = sanitize(ev.target.value);
    }

    this.updateCharacterCount();
    onChange && onChange(ev);
  };

  debouncedHandleChange = debounce(
    (event: SyntheticInputEvent<any>) => this.handleChange(event),
    this.props.debounce
  );

  handleFocus = (ev: SyntheticInputEvent<*>) => {
    if (this.props.onFocus) {
      this.props.onFocus(ev);
    }

    if (this.props.selectOnFocus) {
      ev.target.select();
    }
  };

  updateCharacterCount = () => {
    if (!this.input || !this.props.maxLength) {
      return;
    }
    const charsRemaining = this.props.maxLength - this.input.value.length;
    this.setState({ charsRemaining });
  };

  checkValidity() {
    return this.input.checkValidity();
  }

  renderInput = (id: string) => {
    const { defaultValue, error, stripNewlines, qaSelector } = this.props;
    let props = {
      id,
      ...omit(
        this.props,
        "error",
        "label",
        "helpers",
        "debounce",
        "showLengthWarning",
        "canFocusWithShortcut",
        "responsive",
        "optionalTag",
        "requiredTag",
        "wrapperClass",
        "selectOnFocus",
        "stripNewlines",
        "visuallyHidden",
        "qaSelector",
        "iconClass",
        "innerContextTag"
      ),
      defaultValue:
        defaultValue && stripNewlines ? sanitize(defaultValue) : defaultValue,
    };

    const classes = classnames(
      style.text,
      {
        [style.withIcon]: this.props.icon,
        [style.inputError]: error,
        [style.withShowHelper]: !!this.props.helpers.showPassword,
        [style.withCount]: this.showCounter,
      },
      props.className
    );

    if (props.type === "textarea") {
      props = omit(props, "type");

      return (
        <textarea
          rows={3}
          {...props}
          className={classes}
          onChange={this.deferHandleChange}
          onFocus={this.handleFocus}
          ref={(input) => (this.input = input)}
          data-qa={qaSelector}
        />
      );
    }

    return (
      <input
        {...props}
        className={classes}
        onChange={this.deferHandleChange}
        onFocus={this.handleFocus}
        ref={(input) => (this.input = input)}
        data-qa={qaSelector}
      />
    );
  };

  get showCounter(): boolean {
    if (
      this.props.maxLength &&
      this.props.showLengthWarning &&
      this.input &&
      this.state.charsRemaining !== undefined &&
      this.state.charsRemaining < this.props.showLengthWarning
    ) {
      return true;
    }
    return false;
  }

  renderIcon = () => {
    if (this.props.icon) {
      return (
        <Icon
          type={this.props.icon}
          className={classnames(style.icon, this.props.iconClass)}
        />
      );
    }
    return null;
  };

  renderCounter = () => {
    return this.showCounter ? (
      <span className={style.counter}>{this.state.charsRemaining}</span>
    ) : null;
  };

  renderShowPassword = () => {
    if (this.props.helpers.showPassword) {
      return this.props.helpers.showPassword;
    }

    return null;
  };

  render() {
    const {
      error,
      label,
      sublabel,
      responsive,
      optionalTag,
      requiredTag,
      wrapperClass,
      visuallyHidden,
    } = this.props;
    const id = this.props.id || this.id;
    const helpers = omit(this.props.helpers, "showPassword");

    return (
      <InputWrapper
        error={error}
        label={label}
        sublabel={sublabel}
        helpers={helpers}
        responsive={responsive}
        optionalTag={optionalTag}
        requiredTag={requiredTag}
        inputId={id}
        className={wrapperClass}
        visuallyHidden={visuallyHidden}
      >
        {this.renderIcon()}
        {this.props.innerContextTag ? (
          <div className={style.inputWithContextTag}>
            {this.renderInput(id)}
            <span className={style.innerContextTag}>
              {this.props.innerContextTag}
            </span>
          </div>
        ) : (
          this.renderInput(id)
        )}
        {this.renderShowPassword()}
        {this.renderCounter()}
      </InputWrapper>
    );
  }
}
