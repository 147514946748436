// @flow
import * as Abstract from "abstract-sdk";
import * as React from "react";
import BranchHeader from "core/components/BranchHeader";
import BranchMenu from "core/components/BranchMenu";
import BranchReviewForm from "core/components/BranchReviewForm";
import Button from "core/components/Button";
import DocumentTitle from "core/components/DocumentTitle";
import Error from "core/components/Empty/Error";
import NotFound from "core/components/Empty/NotFound";
import Flex from "core/components/Flex";
import Media from "core/components/Media";
import type {
  Branch,
  Project,
  User,
  BranchStatus as TBranchStatus,
  BranchReview,
  ReactRouterLocation,
} from "core/types";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  children: React.Node,
  location: ReactRouterLocation,
  params: Abstract.BranchDescriptor | Abstract.BranchCommitDescriptor,
|};

export type StateProps = {|
  key?: string,
  project: ?Project,
  owner: ?User,
  isOwner: boolean,
  branch: ?Branch,
  parent: ?Branch,
  branchStatus: ?TBranchStatus,
  branchReview: ?BranchReview,
  error: boolean,
  isLoading: boolean,
  notFound: boolean,
  canShowReviews: boolean,
  canUpdateBranch: boolean,
  canShowDocumentationTab: boolean,
  parentBranches: Branch[],
  branchStatusUpdating: boolean,
|};

export type DispatchProps = {|
  onLoad: () => void,
  onRefreshBranchStatus: (
    projectId: string,
    branchId: string,
    parentId: string
  ) => void,
  onChangeBranchStatus: (status: string) => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

function BranchShell(props: Props) {
  const { branch, branchStatus, onRefreshBranchStatus, params } = props;
  const parent = branch ? branch.parent : undefined;

  React.useEffect(() => {
    if (parent && !branchStatus) {
      onRefreshBranchStatus(params.projectId, params.branchId, parent);
    }
  }, [
    branchStatus,
    onRefreshBranchStatus,
    params.branchId,
    params.projectId,
    parent,
  ]);

  if (!props.branch) {
    if (props.isLoading) {
      return props.children;
    }
    if (props.notFound) {
      return <NotFound flex />;
    }
  } else if (props.error || (!props.isLoading && !props.project)) {
    return <Error flex />;
  }

  return (
    <Media desktop>
      {(desktop) => {
        const actionsList = [];
        if (props.canShowReviews && props.branch && desktop) {
          actionsList.push(
            <BranchReviewForm
              branch={props.branch}
              projectId={params.projectId}
            />
          );
        }
        actionsList.push(
          <BranchMenu projectId={params.projectId} branchId={params.branchId}>
            {(showMenu, renderMenuTarget) => {
              return renderMenuTarget((ref, buttonProps) => {
                return (
                  <Button
                    key="branch-menu"
                    icon="overflow"
                    innerRef={ref}
                    onClick={showMenu}
                    title="Open Branch Menu"
                    qaSelector="branchOverflowButton"
                    nude
                    {...buttonProps}
                  />
                );
              });
            }}
          </BranchMenu>
        );

        return (
          <Flex column className={style.container}>
            {props.branch && (
              <React.Fragment>
                <DocumentTitle
                  titleTemplate={`${props.branch.name} - %s`}
                  defaultTitle={props.branch.name}
                />
                <div>
                  <BranchHeader
                    branch={props.branch}
                    parent={props.parent}
                    canUpdateBranch={props.canUpdateBranch}
                    projectId={props.params.projectId}
                    user={props.owner}
                    projectName={props.project ? props.project.name : null}
                    branchReview={props.branchReview}
                    actions={actionsList}
                    canShowDocumentationTab={props.canShowDocumentationTab}
                    parentBranches={props.parentBranches}
                    onChangeBranchStatus={props.onChangeBranchStatus}
                    branchStatusUpdating={props.branchStatusUpdating}
                  />
                </div>
              </React.Fragment>
            )}
            {props.children}
          </Flex>
        );
      }}
    </Media>
  );
}

export default connector(BranchShell);
