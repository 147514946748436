// @flow
import { connect } from "react-redux";
import { getCurrentUser } from "abstract-di/selectors";
import createConnector from "core/lib/createConnector";
import { withRouter } from "core/lib/router";
import { UnpinCommentRequest } from "core/requests/comments";
import { getCommit } from "core/selectors/commits";
import { getProjectPolicy } from "core/selectors/policies";
import { getUser } from "core/selectors/users";
import type { State, Dispatch } from "core/types";
import type {
  Props,
  OwnProps,
  OwnLocationProps,
  StateProps,
  DispatchProps,
} from "./";

function mapStateToProps(state: State, props: OwnLocationProps): StateProps {
  const currentUser = getCurrentUser(state);

  const user =
    props.comment.pinnedByUserId &&
    getUser(state, { userId: props.comment.pinnedByUserId });

  const isUnpinning = UnpinCommentRequest.isLoadingStrict(state, {
    id: props.comment.id,
  });

  const projectPolicy = getProjectPolicy(state, {
    projectId: props.comment.projectId,
  });

  const commit = getCommit(state, { sha: props.comment.commitSha });

  return {
    currentUser,
    name: user ? `Pinned by ${user.name}` : `Pinned`,
    isUnpinning,
    canUnpin: projectPolicy.pinComments,
    commit,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnLocationProps
): DispatchProps {
  return {
    onUnpin: () =>
      dispatch(
        UnpinCommentRequest.perform({ params: { id: props.comment.id } })
      ),
  };
}

export default createConnector<Props, OwnProps>(
  withRouter,
  connect<Props, OwnLocationProps, StateProps, DispatchProps, State, Dispatch>(
    mapStateToProps,
    mapDispatchToProps
  )
);
