// @flow
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import FormSection from "core/components/FormSection";
import SettingsItem from "core/components/SettingsItem";
import DangerButton from "web/components/DangerButton";
import { OAuthApplicationDeleteRequest } from "web/requests/oAuthApplications";
import type { OAuthApplication } from "web/types";
import style from "./style.scss";

type Props = {
  application: OAuthApplication,
};

const OAuthApplicationDeleteSection = ({
  application: { id, name, organizationId },
}: Props) => {
  const dispatch = useDispatch();

  const isDeleteInProgress = useSelector((state) =>
    OAuthApplicationDeleteRequest.isLoadingStrict(state, { id })
  );
  const isDeleteError = useSelector((state) =>
    OAuthApplicationDeleteRequest.hasError(state, { id })
  );

  const handleDelete = () => {
    dispatch(
      OAuthApplicationDeleteRequest.perform({
        params: { organizationId, id },
      })
    );
  };

  const deleteDialogMessage = (
    <div className={style.deleteDialogMessage}>
      Are you sure you want to permanently delete <b>{name}</b>? You'll revoke
      all access tokens to this application.
    </div>
  );

  return (
    <FormSection heading="Delete Application">
      <SettingsItem label={`Delete ${name} App`}>
        <DangerButton
          title="Delete OAuth Application"
          dialogButtonLabel="DELETE"
          dialogMessage={deleteDialogMessage}
          error={isDeleteError}
          loading={isDeleteInProgress}
          onSubmit={handleDelete}
          className={style.pullRight}
        >
          {isDeleteInProgress ? "Deleting application…" : "Delete application"}
        </DangerButton>
      </SettingsItem>
    </FormSection>
  );
};

export default OAuthApplicationDeleteSection;
