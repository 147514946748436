// @flow
import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import abstractConfig from "abstract-di/config";
import { toggleShortcutViewer } from "core/actions/app";
import DialogForm from "core/components/DialogForm";
import Flex from "core/components/Flex";
import Icon from "core/components/Icon";
import Kbd from "core/components/Kbd";
import { isMac } from "core/lib/platform";
import { isShortcutsViewerOpen } from "core/selectors/app";
import style from "./style.scss";

function Shortcut(props: { action: string, children: React.Node }) {
  return (
    <div className={style.shortcut}>
      <span className={style.action} data-qa="shortcutAction">
        {props.action}
      </span>
      <div className={style.keys} data-qa="shortcutKeys">
        {props.children}
      </div>
    </div>
  );
}

function ShortcutGroup(props: { title: string, children: React.Node }) {
  return (
    <div className={style.group}>
      <strong className={style.title} data-qa="shortcutLocation">
        {props.title}
      </strong>
      {props.children}
    </div>
  );
}

export default function ShortcutViewer() {
  const platformKey = isMac() ? "cmd" : "ctrl";
  const isDesktop = abstractConfig.platform === "desktop";

  const dispatch = useDispatch();
  const isOpen = useSelector(isShortcutsViewerOpen);

  const handleClose = React.useCallback(() => {
    dispatch(toggleShortcutViewer());
  }, [dispatch]);

  return (
    <DialogForm
      isOpen={isOpen}
      onClose={handleClose}
      title="Keyboard shortcuts"
      size="large"
      primaryButton=""
    >
      <Flex>
        <div className={style.column}>
          <ShortcutGroup title="Everywhere">
            <Shortcut action="Open quick jump">
              <Kbd>{platformKey}</Kbd>
              <Kbd>k</Kbd>
            </Shortcut>
            <Shortcut action="Focus filter input">
              <Kbd>{platformKey}</Kbd>
              <Kbd>f</Kbd>
            </Shortcut>
            <Shortcut action="Close dialog">
              <Kbd>esc</Kbd>
            </Shortcut>
            <Shortcut action="Keyboard shortcuts (this window)">
              <Kbd>{platformKey}</Kbd>
              <Kbd>/</Kbd>
            </Shortcut>
          </ShortcutGroup>

          {isDesktop && (
            <ShortcutGroup title="Navigation">
              <Shortcut action="Switch organization">
                <Kbd>{platformKey}</Kbd>
                <Kbd>1-9</Kbd>
              </Shortcut>
              <Shortcut action="Previous screen">
                <Kbd>{platformKey}</Kbd>
                <Kbd>[</Kbd>
              </Shortcut>
              <Shortcut action="Next screen">
                <Kbd>{platformKey}</Kbd>
                <Kbd>]</Kbd>
              </Shortcut>
              <Shortcut action="Home">
                <Kbd>{platformKey}</Kbd>
                <Kbd>shift</Kbd>
                <Kbd>h</Kbd>
              </Shortcut>
              <Shortcut action="Open current file">
                <Kbd>cmd</Kbd>
                <Kbd>o</Kbd>
              </Shortcut>
              <Shortcut action="Open file untracked">
                <Kbd>cmd</Kbd>
                <Kbd>shift</Kbd>
                <Kbd>o</Kbd>
              </Shortcut>
            </ShortcutGroup>
          )}
        </div>

        <div className={style.column}>
          <ShortcutGroup title="Layer Detail">
            <Shortcut action="New annotation">
              <Kbd>a</Kbd>
            </Shortcut>

            <Shortcut action="Toggle annotations">
              <Kbd>Ctrl</Kbd>
              <Kbd>a</Kbd>
            </Shortcut>

            <Shortcut action="Next layer">
              <Kbd icon>
                <Icon type="chevron-default-right" />
              </Kbd>
            </Shortcut>

            <Shortcut action="Previous layer">
              <Kbd icon>
                <Icon type="chevron-default-left" />
              </Kbd>
            </Shortcut>

            <Shortcut action="Next commit">
              <Kbd icon>
                <Icon type="chevron-default-up" />
              </Kbd>
            </Shortcut>

            <Shortcut action="Previous commit">
              <Kbd icon>
                <Icon type="chevron-default-down" />
              </Kbd>
            </Shortcut>

            <Shortcut action="Toggle sidebars">
              <Kbd>{platformKey}</Kbd>
              <Kbd>.</Kbd>
            </Shortcut>
          </ShortcutGroup>

          <ShortcutGroup title="Forms">
            <Shortcut action="Submit form">
              <Kbd>{platformKey}</Kbd>
              <Kbd>return</Kbd>
            </Shortcut>
          </ShortcutGroup>
        </div>
      </Flex>
    </DialogForm>
  );
}
