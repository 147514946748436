// @flow
import apiRequest from "abstract-di/api";
import defineRequest from "core/requests";

type PasswordResetCreateParams = {|
  email: string,
  captchaValue?: string,
|};

export const PasswordResetCreateRequest = defineRequest<
  PasswordResetCreateParams,
  PasswordResetCreateParams,
>({
  id({ email }) {
    return `post:password_resets/${email}`;
  },
  perform({ email, captchaValue }) {
    return apiRequest("post", `password_resets`, {
      email,
      captchaValue,
    });
  },
});
