// @flow
import classnames from "classnames";
import * as React from "react";
import Button from "core/components/Button";
import Icon from "core/components/Icon";
import { getCurrentLocation } from "core/lib/location";
import { V3Link as Link } from "core/lib/router";
import { signin, signup } from "web/routeHelpers";
import connector from "./connector";
import style from "./style.scss";

type Props = {
  className?: string,
  showIcon?: boolean,
  isLoading: boolean,
  children?: React.Node,
};

function ShareLinkSignin(props: Props) {
  const { children = "Create an account to leave a comment" } = props;

  return (
    !props.isLoading && (
      <div
        className={classnames(style.shareLinkSignin, props.className, {
          [style.huge]: props.showIcon,
        })}
      >
        {props.showIcon && (
          <Icon type="comment-default" fill={style.mediumDarkGrey} huge />
        )}
        {props.showIcon ? (
          <h2 className={style.header}>{children}</h2>
        ) : (
          <span className={style.paragraph}>{children}</span>
        )}
        <Button
          primary
          large
          fullwidth
          component={Link}
          to={signup(getCurrentLocation())}
        >
          Create an account
        </Button>
        <span className={style.secondaryText}>
          Already have an account?{" "}
          <Link className={style.link} to={signin(getCurrentLocation())}>
            Sign in
          </Link>
        </span>
      </div>
    )
  );
}

export default connector(ShareLinkSignin);
