// @flow
import { connect } from "react-redux";
import { getCurrentUser } from "abstract-di/selectors";
import {
  deleteTeamMembership,
  updateTeamMembership,
} from "core/actions/teamMemberships";
import { uniqueId } from "core/models/membership";
import { TeamMembershipDeleteRequest } from "core/requests/teamMemberships";
import { getMembership } from "core/selectors/memberships";
import { getOrganization } from "core/selectors/organizations";
import { getTeamPolicy } from "core/selectors/policies";
import { getTeamMembership } from "core/selectors/teamMemberships";
import { getTeam } from "core/selectors/teams";
import type { State, Dispatch } from "core/types";
import type { OwnProps, Props, StateProps, DispatchProps } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { user, params } = props;
  const team = getTeam(state, props.params);
  const userId = user.id;
  const organizationId = params.organizationId;
  const organization = getOrganization(state, {
    organizationId,
  });
  const organizationMembership = getMembership(
    state,
    uniqueId({ organizationId, userId })
  );
  const teamMembership = getTeamMembership(state, {
    userId,
    teamId: params.teamId,
  });
  const teamPolicy = getTeamPolicy(state, { teamId: props.params.teamId });
  const isLoadingDeleteTeamMembership =
    TeamMembershipDeleteRequest.isLoadingStrict(state, {
      organizationId,
      teamId: params.teamId,
      userId,
    });

  return {
    currentUser: getCurrentUser(state),
    teamName: team ? team.name : undefined,
    organizationName: organization ? organization.name : undefined,
    organizationMembership,
    teamMembership,
    teamPolicy,
    isLoadingDeleteTeamMembership,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { params, user } = props;
  const { organizationId, teamId } = params;
  const userId = user.id;
  return {
    onUpgradeToAdmin: () => {
      dispatch(
        updateTeamMembership({
          organizationId,
          teamId,
          userId,
          isAdmin: true,
        })
      );
    },
    onDowngradeToMember: () => {
      dispatch(
        updateTeamMembership({
          organizationId,
          teamId,
          userId,
          isAdmin: false,
        })
      );
    },
    onRemoveMember: () => {
      dispatch(deleteTeamMembership({ organizationId, teamId, userId }));
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
