// @flow
import { normalize, schema } from "normalizr";
import { envelopeSchema } from "core/schemas/envelope";

export const accessTokenSchema = new schema.Entity("accessTokens");

const accessTokensResponseSchema = new envelopeSchema({
  access_tokens: [accessTokenSchema],
});

export function normalizeAccessTokensResponse(response: *) {
  return normalize(response, accessTokensResponseSchema);
}
