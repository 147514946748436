// @flow
import classnames from "classnames";
import pluralize from "pluralize";
import * as React from "react";
import ResizeDetector from "react-resize-detector";
import { Waypoint } from "react-waypoint";
import Flex from "core/components/Flex";
import FavoriteProjectsOnboarding from "core/components/GlobalSidebar/FavoriteProjectsOnboarding";
import ProjectMenuItem, {
  Placeholder as ProjectPlaceholder,
} from "core/components/GlobalSidebar/ProjectMenuItem";
import GlobalSidebarMenuItem, {
  Placeholder as GlobalSidebarMenuItemPlaceholder,
} from "core/components/GlobalSidebarMenuItem";
import KeyboardNavigation from "core/components/KeyboardNavigation";
import NotificationBadge from "core/components/NotificationBadge";
import { useBranchesReviewRequestsCount } from "core/hooks/reviews";
import { isDesktop, isWeb } from "core/lib/platform";
import * as Routes from "core/lib/routes";
import { organizationReportingUrl } from "core/lib/urls";
import type {
  Branch,
  Organization as TOrganization,
  Project,
  LocationDescriptor,
} from "core/types";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  currentBranch?: ?Branch,
  currentProject?: ?Project,
  mobile?: boolean,
  onClose?: () => void,
  onMenuOverflowChange: (isVisible: boolean) => void,
  organization: ?TOrganization,
|};

export type StateProps = {|
  canShowReporting: boolean,
  canShowReviews: boolean,
  currentUserId: ?string,
  favoriteProjects: Project[],
  isFavoriteProjectsLoading: boolean,
  areBranchesReviewsLoading: boolean,
  showCurrentProject: boolean,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
};

function GlobalSidebarMenu(props: Props) {
  const { organization } = props;

  const onClick = props.mobile ? props.onClose : undefined;

  const [menuHeight, setMenuHeight] = React.useState(0);

  const handleResize = React.useCallback((_, height) => {
    setMenuHeight(height);
  }, []);

  const branchReviewRequestsCount = useBranchesReviewRequestsCount(
    organization ? organization.id : undefined
  );

  const notificationBadgeCount = React.useMemo(() => {
    let count = 0;

    if (!props.canShowReviews || props.areBranchesReviewsLoading) {
      return undefined;
    }

    if (branchReviewRequestsCount && branchReviewRequestsCount > 0) {
      count += branchReviewRequestsCount;
    }

    return count;
  }, [
    props.areBranchesReviewsLoading,
    branchReviewRequestsCount,
    props.canShowReviews,
  ]);

  const pathToString = (value: LocationDescriptor): string => {
    if (typeof value === "string") {
      return value;
    }
    return value.pathname + Routes.query(value.query);
  };

  return (
    <KeyboardNavigation defaultFocused disabled={isWeb}>
      {(keyboardNavigationProps) => {
        return (
          <div className={style.innerMenu}>
            {organization ? (
              <React.Fragment>
                <GlobalSidebarMenuItem
                  icon="project"
                  keyboardNavigationProps={keyboardNavigationProps}
                  label="Projects"
                  mobile={props.mobile}
                  onClick={onClick}
                  to={Routes.organizationProjectsPath(organization.id)}
                  qaSelector="projectsSidebarItem"
                />
                {organization.versionsEnabled ? (
                  <GlobalSidebarMenuItem
                    icon="status"
                    keyboardNavigationProps={keyboardNavigationProps}
                    label="Activity"
                    mobile={props.mobile}
                    onClick={onClick}
                    to={Routes.organizationActivitiesPath(organization.id)}
                    qaSelector="organizationActivitySidebarItem"
                  />
                ) : null}
                {props.canShowReviews && (
                  <GlobalSidebarMenuItem
                    icon="review-default"
                    keyboardNavigationProps={keyboardNavigationProps}
                    label={
                      <Flex align="center">
                        Reviews
                        {!!notificationBadgeCount &&
                          notificationBadgeCount > 0 && (
                            <NotificationBadge
                              count={notificationBadgeCount}
                              className={style.reviewsBadge}
                              title={`${notificationBadgeCount} ${pluralize(
                                "review",
                                notificationBadgeCount
                              )} requested`}
                            />
                          )}
                      </Flex>
                    }
                    mobile={props.mobile}
                    onClick={onClick}
                    to={pathToString(Routes.reviewsPath(organization.id))}
                    qaSelector="reviewsSidebarItem"
                  />
                )}
                {props.canShowReporting && organization.versionsEnabled ? (
                  <GlobalSidebarMenuItem
                    external={isDesktop}
                    icon="graph"
                    keyboardNavigationProps={keyboardNavigationProps}
                    label="Branch Reporting"
                    mobile={props.mobile}
                    onClick={onClick}
                    to={
                      isDesktop
                        ? organizationReportingUrl(organization.id)
                        : Routes.organizationReportingPath(organization.id)
                    }
                    qaSelector="reportingSidebarItem"
                  />
                ) : null}
                {props.currentProject && props.showCurrentProject ? (
                  <div className={style.currentProjectSection}>
                    <ProjectMenuItem
                      currentBranch={
                        props.currentBranch &&
                        props.currentBranch.projectId ===
                          props.currentProject.id
                          ? props.currentBranch
                          : undefined
                      }
                      keyboardNavigationProps={keyboardNavigationProps}
                      mobile={props.mobile}
                      onClick={onClick}
                      project={props.currentProject}
                      isCurrentProject
                    />
                  </div>
                ) : null}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <GlobalSidebarMenuItemPlaceholder />
                <GlobalSidebarMenuItemPlaceholder />
                <GlobalSidebarMenuItemPlaceholder />
              </React.Fragment>
            )}

            <div className={style.favoriteProjectsSection}>
              <div className={style.favoriteProjectsHeading}>
                Favorite Projects
              </div>

              {props.isFavoriteProjectsLoading ? (
                <React.Fragment>
                  <ProjectPlaceholder
                    className={classnames(
                      style.projectMenuItem,
                      style.firstProject
                    )}
                  />
                  <ProjectPlaceholder className={style.projectMenuItem} />
                  <ProjectPlaceholder className={style.projectMenuItem} />
                </React.Fragment>
              ) : props.favoriteProjects.length > 0 ? (
                props.favoriteProjects.map((favoriteProject, index) => {
                  return (
                    <ProjectMenuItem
                      className={classnames(style.projectMenuItem, {
                        [style.firstProject]: index === 0,
                      })}
                      closeMobileSidebar={onClick}
                      currentBranch={
                        props.currentBranch &&
                        props.currentBranch.projectId === favoriteProject.id
                          ? props.currentBranch
                          : undefined
                      }
                      isCurrentProject={
                        props.currentProject
                          ? props.currentProject.id === favoriteProject.id
                          : false
                      }
                      key={favoriteProject.id}
                      keyboardNavigationProps={keyboardNavigationProps}
                      mobile={props.mobile}
                      onClick={onClick}
                      project={favoriteProject}
                    />
                  );
                })
              ) : organization ? (
                <FavoriteProjectsOnboarding
                  className={style.favoriteProjectsOnboarding}
                  organizationId={organization.id}
                />
              ) : null}
            </div>
            {isWeb ? (
              <React.Fragment>
                <Waypoint
                  key={`height-${menuHeight}`}
                  onEnter={() => {
                    props.onMenuOverflowChange(false);
                  }}
                  onLeave={() => {
                    props.onMenuOverflowChange(true);
                  }}
                  onPositionChange={(position) => {
                    if (position.currentPosition !== Waypoint.inside) {
                      props.onMenuOverflowChange(true);
                    }
                  }}
                />
                <ResizeDetector
                  onResize={handleResize}
                  handleHeight={true}
                  handleWidth={false}
                />
              </React.Fragment>
            ) : null}
          </div>
        );
      }}
    </KeyboardNavigation>
  );
}

export default connector(GlobalSidebarMenu);
