// @flow
import * as React from "react";
import DialogForm, { type TDialogForm } from "core/components/DialogForm";
import Flex from "core/components/Flex";
import FormNotice from "core/components/FormNotice";
import { SubmittableInput } from "core/components/Input/withSubmittable";
import Note from "core/components/Note";
import TeamColorSelector from "core/components/TeamColorSelector";
import type { ValidationErrors } from "core/types";
import Validations from "core/validations";
import style from "./style.scss";

// initTeamName + initTeamColor props should be used when editing existing Teams,
// and will initialize the dialog with prefilled content.
type Props = {|
  isOpen: boolean,
  desktop: boolean,
  onCreateTeam: ({ name: string, color: string }) => Promise<void>,
  toggleCreateDialogOpen: (boolean) => void,
  isLoading: boolean,
  validationErrors: ValidationErrors,
  isOnline?: boolean,
  initTeamName?: string,
  initTeamColor?: string,
|};

const DEFAULT_COLOR = "#7080D4";

export default function TeamDialog(props: Props) {
  const form: { current: ?TDialogForm } = React.useRef(null);
  const [teamName, setTeamName] = React.useState(props.initTeamName);
  const [teamColor, setTeamColor] = React.useState(
    props.initTeamColor || DEFAULT_COLOR
  );
  const [error, setError] = React.useState(props.validationErrors.name || null);

  // Reset the dialog on open.
  React.useEffect(() => {
    if (props.isOpen) {
      setTeamName(props.initTeamName);
      setTeamColor(props.initTeamColor || DEFAULT_COLOR);
      setError(null);
    }
  }, [props.initTeamColor, props.initTeamName, props.isOpen]);

  // Display an error if there is one.
  React.useEffect(() => {
    if (props.validationErrors && props.validationErrors !== {}) {
      setError(props.validationErrors.name);
    }
  }, [props.validationErrors]);

  const createTeam = async (name: string, color: string) => {
    const result = await props.onCreateTeam({ name, color });
    if (result) {
      props.toggleCreateDialogOpen(false);
    }
  };

  return (
    <DialogForm
      title="Create a new team"
      ref={form}
      isOpen={props.isOpen}
      onClose={() => props.toggleCreateDialogOpen(false)}
      closeIcon={!props.desktop ? "arrow-large-left" : undefined}
      primaryButton="Save"
      loading={props.isLoading}
      disabled={props.isLoading}
      onSubmit={() => {
        if (teamName && teamName.trim()) {
          createTeam(teamName.trim(), teamColor);
        } else {
          setError("Please enter a valid name");
        }
      }}
    >
      {({ onSubmit }) => (
        <Flex column>
          {error ? (
            <FormNotice
              isError
              icon="error"
              className={style.errorNotification}
              heading="Something went wrong"
              body={error}
              alignContent
            />
          ) : (
            !props.isOnline && (
              <FormNotice
                className={style.errorNotification}
                icon="offline"
                heading="Looks like you’re offline"
                body="To continue editing projects, you will need to be online."
                alignContent
              />
            )
          )}
          <SubmittableInput
            type="text"
            label="Name"
            name="name"
            placeholder="Name your team…"
            onSubmit={() => {
              props.toggleCreateDialogOpen(false);
            }}
            minLength={Validations.minTeamNameLength}
            maxLength={Validations.maxTeamNameLength}
            onChange={(ev: SyntheticInputEvent<>) => {
              form.current && form.current.checkValid();
              setTeamName(ev.target.value);
            }}
            defaultValue={teamName}
            required
            autoFocus
          />
          <TeamColorSelector
            teamName={teamName || undefined}
            selectedColor={teamColor}
            onChange={(color: string) => {
              form.current && form.current.checkValid();
              setTeamColor(color);
            }}
            className={style.colorSelector}
            desktop={props.desktop}
            showWrapper={true}
          />
          <Note>
            Organization members can see the team and its projects. Private
            projects maintain their permissions.
          </Note>
        </Flex>
      )}
    </DialogForm>
  );
}
