// @flow
import * as React from "react";
import ContextMenu from "core/components/ContextMenu";
import DialogForm from "core/components/DialogForm";
import { withRouter } from "core/lib/router";
import type {
  Collection,
  Branch,
  Policy,
  LocationDescriptor,
  ReactRouterLocation,
} from "core/types";
import connector from "./connector";

type ShareParams = {
  kind: "collection",
  collectionId: string,
  projectId: string,
  branchId: string,
};

export type OwnProps = {|
  location: ReactRouterLocation,
  collection: Collection,
  children: (showMenu: () => void) => React.Node,
|};

export type StateProps = {|
  isOnline: boolean,
  branch: ?Branch,
  policy: Policy,
  organizationId: string,
  canEdit: boolean,
  canDelete: boolean,
|};

export type DispatchProps = {|
  onPublish: () => void,
  onDelete: (nextPath?: LocationDescriptor) => void,
  onEdit: (formValues: Object, onSuccess: (data: Object) => void) => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type State = { showDelete: boolean };

const EMPTY_ARRAY = [];

class CollectionMenu extends React.Component<Props, State> {
  menu: ?ContextMenu;

  static defaultProps = {
    showEditMenu: true,
  };

  state = {
    showDelete: false,
  };

  menuRef = (ref: *) => (this.menu = ref);

  closeMenu = () => {
    this.menu && this.menu.close();
  };

  handleClickPublish = () => {
    this.props.onPublish();
    this.closeMenu();
  };

  handleClickDelete = () => {
    this.setState({ showDelete: true });
    this.closeMenu();
  };

  handleHideDelete = () => this.setState({ showDelete: false });

  isPublished = (): boolean => {
    return !!this.props.collection.publishedAt;
  };

  shareParams = (): ShareParams => {
    return {
      kind: "collection",
      projectId: this.props.collection.projectId,
      branchId: this.props.collection.branchId,
      collectionId: this.props.collection.id,
      organizationId: this.props.organizationId,
    };
  };

  menuItems(): * {
    return [
      ...this.shareMenu(),
      ...this.publishMenu(),
      {
        label: "Delete…",
        click: this.handleClickDelete,
        enabled: this.props.isOnline,
        visible: this.props.canDelete,
      },
    ];
  }

  publishMenu() {
    if (this.isPublished() || !this.props.canEdit) {
      return EMPTY_ARRAY;
    }

    return [
      {
        label: "Publish",
        click: this.handleClickPublish,
        enabled: this.props.isOnline,
      },
      { type: "separator" },
    ];
  }

  shareMenu() {
    if (!this.isPublished()) {
      return EMPTY_ARRAY;
    }

    return [
      { type: "share", props: this.shareParams() },
      { type: "separator" },
    ];
  }

  render() {
    const { collection, onDelete } = this.props;

    return (
      <React.Fragment>
        <ContextMenu
          ref={this.menuRef}
          id={collection.id}
          menuItems={this.menuItems()}
          children={this.props.children}
        />
        <DialogForm
          dangerous
          isOpen={this.state.showDelete}
          onClose={this.handleHideDelete}
          onRequestClose={this.handleHideDelete}
          title="Delete Collection"
          onSubmit={() => {
            onDelete(
              this.props.location.state && this.props.location.state.returnTo
            );
          }}
          primaryButton="Delete"
        >
          {() => <span>Are you sure you want to delete this collection?</span>}
        </DialogForm>
      </React.Fragment>
    );
  }
}

// $FlowFixMeNowPlease react-router upgrade
export default withRouter(connector(CollectionMenu));
