// @flow
import { connect } from "react-redux";
import * as Request from "core/models/request";
import type { State, Dispatch } from "core/types";
import {
  SendEmailVerificationRequest,
  VerifyEmailRequest,
} from "web/requests/account";
import type { OwnProps, StateProps, DispatchProps, Props } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { error } = VerifyEmailRequest.getRequest(state, {
    id: props.token,
  });

  const sendEmailVerificationRequest = SendEmailVerificationRequest.getRequest(
    state,
    {
      token: props.token,
    }
  );

  return {
    error: Request.hasError(sendEmailVerificationRequest),
    loading: Request.isLoadingStrict(sendEmailVerificationRequest),
    success: Request.success(sendEmailVerificationRequest),
    tokenIsExpired: error
      ? error.code === "email_verification_token_expired"
      : false,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { token } = props;
  return {
    resendEmailVerification: () => {
      dispatch(
        SendEmailVerificationRequest.perform({
          params: { token },
        })
      );
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
