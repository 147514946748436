// @flow
import classnames from "classnames";
import * as React from "react";
import { highlightText } from "core/lib/textFormatting";
import style from "./style.scss";

type Props = {
  highlight?: string,
  text: string,
  caseSensitive?: boolean,
  className?: string,
};

export default function TextHighlight({
  highlight,
  caseSensitive,
  text,
  className,
  ...rest
}: Props) {
  return (
    <span {...rest} className={classnames(style.highlighted, className)}>
      {highlight
        ? highlightText(text, highlight, {
            caseSensitive,
            renderer: (tag, index) => <mark key={index}>{tag}</mark>,
          })
        : text}
    </span>
  );
}
