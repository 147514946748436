// @flow
import { string as EMPTY_STRING } from "empty";
import { connect } from "react-redux";
import { trackOrganization } from "core/actions/analytics";
import { toggleShortcutViewer } from "core/actions/app";
import { loadAndTrackMembership } from "core/actions/memberships";
import { shouldTrackCurrentOrganization } from "core/analytics";
import * as Request from "core/models/request";
import { FeaturesFetchRequest } from "core/requests/features";
import { getFeatureEnabled } from "core/selectors/features";
import {
  getNonUsernameOrganizations,
  getCurrentOrganization,
  getUsernameOrganization,
} from "core/selectors/organizations";
import { getOrganizationPolicy } from "core/selectors/policies";
import { getSubscription } from "core/selectors/subscriptions";
import type { Organization, State, Dispatch } from "core/types";
import { loginRequired } from "web/actions/login";
import { loadOrganization } from "web/actions/organizations";
import { loadSession, deleteSession } from "web/actions/session";
import { getSessionRequest } from "web/selectors/requests";
import {
  getCurrentUser,
  isLoggedIn,
  shouldRequestMarketingConsent,
  shouldRequestTOSAgreement,
  shouldRequestTrialConsent,
} from "web/selectors/session";
import type { Props, StateProps, DispatchProps, OwnProps } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const currentUser = getCurrentUser(state);
  const currentOrganization = getCurrentOrganization(state, {
    organizationId: props.params.organizationId,
    projectId: props.params.projectId,
  });
  const organizationId = currentOrganization
    ? currentOrganization.id
    : EMPTY_STRING;
  let projectId = props.params.projectId || EMPTY_STRING;

  const currentOrganizationPolicy = getOrganizationPolicy(state, {
    organizationId,
  });

  return {
    organizationId,
    projectId,
    currentUser,
    isLoggedIn: isLoggedIn(state),
    sidebarIsOpen: state.sidebar.open,
    sessionLoading: Request.isLoading(getSessionRequest(state)),
    organizations: getNonUsernameOrganizations(state),
    usernameOrganization: getUsernameOrganization(state),
    currentOrganization,
    currentOrganizationPolicy,
    showMarketingConsent: shouldRequestMarketingConsent(state),
    showTermsOfService: shouldRequestTOSAgreement(state),
    newUser: !!props.location.query.newUser,
    shouldTrackCurrentOrganization,
    showTrialConsent: shouldRequestTrialConsent(state),
    subscription: getSubscription(state, organizationId),
    canUseDevtools: getFeatureEnabled(state, { feature: "devtools" }),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    loadAndTrackMembership: (organizationId: string) => {
      dispatch(loadAndTrackMembership(organizationId));
    },
    loadOrganization: (organizationId: string) => {
      dispatch(loadOrganization(organizationId));
      dispatch(
        FeaturesFetchRequest.perform({
          force: false,
          params: {
            organizationId,
          },
        })
      );
    },
    loadSession: () => {
      dispatch(loadSession());
    },
    loginRequired: () => {
      dispatch(loginRequired());
    },
    logout: () => {
      dispatch(deleteSession());
    },
    trackOrganization: (organization: Organization) => {
      dispatch(trackOrganization(organization));
    },
    onOpenShortcutViewer: () => {
      dispatch(toggleShortcutViewer());
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(mapStateToProps, mapDispatchToProps);
