// @flow
import * as React from "react";
import Button from "core/components/Button";
import InputSearch from "core/components/InputSearch";
import ListDisplayToggle from "core/components/ListDisplayToggle";
import PageTitle from "core/components/PageTitle";
import PageTitleNavigationLink from "core/components/PageTitleNavigationLink";
import ProjectMembersUpsellDialog from "core/components/ProjectMembersUpsellDialog";
import { addQuery, removeQuery } from "core/lib/location";
import type { RoleFilter } from "core/types";

type Props = {|
  canAddMember: boolean,
  canManageBilling?: boolean,
  isGrid: boolean,
  roleFilter?: ?RoleFilter,
  mobile?: boolean,
  onDisplayList: () => void,
  onDisplayGrid: () => void,
  onInviteDialogTrigger: () => void,
  onSearchChange: (event: SyntheticInputEvent<>) => void,
  organizationId?: string,
  renderInviteDialog: () => React.Node,
  searchFilter: ?string,
|};

type State = {|
  showUpgradeDialog: boolean,
|};

export default class ProjectMembersHeader extends React.Component<
  Props,
  State,
> {
  state = {
    showUpgradeDialog: false,
  };

  handleCloseUpgradeDialog = () => {
    this.setState({ showUpgradeDialog: false });
  };

  handleInviteClick = () => {
    this.props.canAddMember
      ? this.props.onInviteDialogTrigger()
      : this.setState({ showUpgradeDialog: true });
  };

  render() {
    const actions = [
      <Button
        icon={this.props.mobile ? "user-add" : undefined}
        onClick={this.handleInviteClick}
        nude={this.props.mobile}
        primary={!this.props.mobile}
      >
        {this.props.mobile ? undefined : "Invite to Project"}
      </Button>,
    ];

    return (
      <React.Fragment>
        <PageTitle
          actions={actions}
          displayControl={
            <ListDisplayToggle
              isGrid={this.props.isGrid}
              onToggleList={this.props.onDisplayList}
              onToggleGrid={this.props.onDisplayGrid}
            />
          }
          navigation={[
            <PageTitleNavigationLink
              active={!this.props.roleFilter}
              to={removeQuery("role")}
              icon="user"
            >
              All
            </PageTitleNavigationLink>,
            <PageTitleNavigationLink
              active={this.props.roleFilter === "admin"}
              to={addQuery({ role: "admin" })}
              icon="admin"
            >
              Project Admins
            </PageTitleNavigationLink>,
            <PageTitleNavigationLink
              active={this.props.roleFilter === "guest"}
              to={addQuery({ role: "guest" })}
              icon="guest"
            >
              Guests
            </PageTitleNavigationLink>,
          ]}
          search={
            <InputSearch
              large={this.props.mobile}
              placeholder="Search by name…"
              defaultValue={this.props.searchFilter}
              onChange={this.props.onSearchChange}
            />
          }
          title="Project Members"
        />
        {this.props.canAddMember && this.props.renderInviteDialog()}
        {!this.props.canAddMember && (
          <ProjectMembersUpsellDialog
            canManageBilling={this.props.canManageBilling}
            isOpen={this.state.showUpgradeDialog}
            onClose={this.handleCloseUpgradeDialog}
            organizationId={this.props.organizationId}
          />
        )}
      </React.Fragment>
    );
  }
}
