// @flow
import classnames from "classnames";
import * as React from "react";
import Heading from "core/components/Heading";
import Icon from "core/components/Icon";
import Centered from "../Centered";
import style from "./style.scss";

export type Props = {
  children?: React.Node,
  className?: string,
  image?: React.Node,
  title?: string,
  description?: React.Node,
  mini?: boolean,
  small?: boolean,
  flex?: boolean,
  icon?: string,
  qaSelector?: string,
};

export default function Empty({
  children,
  image,
  title,
  mini,
  small,
  description,
  icon,
  qaSelector,
  ...rest
}: Props) {
  const showIcon = icon ? !small : false;
  const iconSize = mini ? "large" : "huge";

  return (
    <Centered {...rest}>
      <div
        className={classnames(style.empty, {
          [style.mini]: mini,
          [style.small]: small,
        })}
        data-qa={qaSelector}
      >
        {image && typeof image === "string" ? (
          <img src={image} alt="" />
        ) : (
          image
        )}
        {icon && showIcon && (
          <Icon
            className={style.icon}
            type={icon}
            large={iconSize === "large"}
            huge={iconSize === "huge"}
            lightGray
          />
        )}
        {title && (
          <Heading className={style.title} level="3" size="xl">
            {title}
          </Heading>
        )}
        {description && (
          <div className={style.description} data-qa="description">
            {description}
          </div>
        )}
        {children && <div className={style.children}>{children}</div>}
      </div>
    </Centered>
  );
}
