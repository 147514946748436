// @flow
import { compact, groupBy, keyBy } from "lodash";
import * as React from "react";
import NotificationBadge from "core/components/NotificationBadge";
import OverviewCard from "core/components/ProjectOverview/OverviewCard";
import ReviewRequestListItem from "core/components/ReviewRequestListItem";
import type { Branch, BranchReview, ReviewRequest } from "core/types";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  mobile?: boolean,
  projectId: string,
|};

export type StateProps = {|
  branches: Branch[],
  branchReviews: BranchReview[],
  reviewRequests: ReviewRequest[],
|};

export type DispatchProps = {|
  onMount: () => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

class ReviewRequests extends React.Component<Props> {
  componentDidMount() {
    this.props.onMount();
  }

  render() {
    const { branches, branchReviews, reviewRequests, mobile, projectId } =
      this.props;
    const branchesById = keyBy(branches, "id");
    const reviewsByBranchId = groupBy(reviewRequests, "branchId");

    const children = compact(
      branchReviews.map((branchReview) => {
        const { branchId } = branchReview;
        const branch = branchesById[branchId];
        if (!branch) {
          return null;
        }

        return (
          <ReviewRequestListItem
            key={branch.id}
            mobile={mobile}
            branch={branch}
            projectId={projectId}
            reviewRequests={reviewsByBranchId[branch.id]}
          />
        );
      })
    );
    const requestedReviewsCount = children.length;
    if (!requestedReviewsCount) {
      return null;
    }

    return (
      <OverviewCard
        heading={
          <React.Fragment>
            Review Requests{" "}
            <NotificationBadge
              count={requestedReviewsCount}
              className={style.notificationBadge}
            />
          </React.Fragment>
        }
        mobile={mobile}
        storageId="ProjectOverview-ReviewRequests"
        hasList
        noPadding
      >
        {children}
      </OverviewCard>
    );
  }
}

export default connector(ReviewRequests);
