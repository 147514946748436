// @flow
import classnames from "classnames";
import Resizable from "re-resizable";
import * as React from "react";
import { useDispatch } from "react-redux";
import { Button } from "reakit/Button";
import { trackEvent } from "core/actions/analytics";
import Icon from "core/components/Icon";
import useLocalStorage from "core/hooks/useLocalStorage";
import usePrevious from "core/hooks/usePrevious";
import style from "./style.scss";

const COLLAPSED_WIDTH = "16px";
const DEFAULT_WIDTH = "280px";

type Props = {
  id: string,
  children: React.Node,
  maxWidth: number,
  minWidth: number,
  opaque?: boolean,
  screenSide?: string,
};

export default function CollapsibleSidebar(props: Props) {
  const sidebarId = `CollapsibleSidebar-${props.id}`;
  const widthStorageKey = `${sidebarId}-width`;
  const collapsedStateStorageKey = `${sidebarId}-collapsed`;

  const dispatch = useDispatch();

  const resizeable = React.useRef(null);
  const collapseButtonRef = React.useRef(null);

  const [sidebarWidth, setSidebarWidth] = useLocalStorage(
    widthStorageKey,
    DEFAULT_WIDTH
  );
  const [isCollapsed, setIsCollapsed] = useLocalStorage(
    collapsedStateStorageKey,
    false
  );
  const [isHoveringOverSidebar, setIsHoveringOverSidebar] =
    React.useState(false);
  const [isHoveringOverCollapsedButton, setIsHoveringOverCollapsedButton] =
    React.useState(false);
  const [isCollapsedButtonFocused, setIsCollapsedButtonFocused] =
    React.useState(false);

  const prevIsCollapsed = usePrevious(isCollapsed);

  const isCollapsedButOpen = isCollapsed && isHoveringOverSidebar;

  const isCollapsedButtonActive =
    isHoveringOverCollapsedButton || isCollapsedButtonFocused;

  React.useEffect(() => {
    if (isCollapsed !== prevIsCollapsed && prevIsCollapsed !== undefined) {
      const event = isCollapsed
        ? "NAVIGATION_COLLAPSED"
        : "NAVIGATION_EXPANDED";

      dispatch(trackEvent(event));
    }
  }, [dispatch, isCollapsed, prevIsCollapsed]);

  const handleResizeStop = React.useCallback(
    (event, direction, ref) => {
      if (ref) {
        setSidebarWidth(`${ref.clientWidth}px`);
      }
    },
    [setSidebarWidth]
  );

  const handleHoverSidebarEnter = React.useCallback(() => {
    setIsHoveringOverSidebar(true);
  }, []);

  const handleHoverSidebarLeave = React.useCallback(() => {
    setIsHoveringOverSidebar(false);
  }, []);

  const handleCollapsedButtonFocus = React.useCallback(() => {
    setIsCollapsedButtonFocused(true);
  }, []);

  const handleCollapsedButtonBlur = React.useCallback(() => {
    setIsCollapsedButtonFocused(false);
  }, []);

  const handleCollapsedButtonHoverEnter = React.useCallback(() => {
    setIsHoveringOverCollapsedButton(true);
  }, []);

  const handleCollapsedButtonHoverLeave = React.useCallback(() => {
    setIsHoveringOverCollapsedButton(false);
  }, []);

  const handleCollapsedButtonClick = React.useCallback(() => {
    const willBeCollapsed = !isCollapsed;

    setIsCollapsed(willBeCollapsed);

    if (willBeCollapsed) {
      setIsHoveringOverCollapsedButton(false);
      setIsHoveringOverSidebar(false);
    }

    if (collapseButtonRef.current) {
      collapseButtonRef.current.blur();
    }
  }, [isCollapsed, setIsCollapsed]);

  const containerStyle = React.useMemo(
    () => ({
      "--collapsible-sidebar-collapsed-width": COLLAPSED_WIDTH,
      "--collapsible-sidebar-width": sidebarWidth,
    }),
    [sidebarWidth]
  );

  const collapsibleIcon = (() => {
    if (props.screenSide === "right") {
      return isCollapsed ? "chevron-large-left" : "chevron-large-right";
    } else {
      return isCollapsed ? "chevron-large-right" : "chevron-large-left";
    }
  })();

  return (
    <div
      className={classnames(style.outerContainer, {
        [style.collapsed]: isCollapsed,
        [style.collapsedButOpen]: isCollapsedButOpen,
        [style.isHoveringOverSidebar]: isHoveringOverSidebar,
        [style.right]: props.screenSide === "right",
      })}
      onMouseEnter={handleHoverSidebarEnter}
      onMouseLeave={handleHoverSidebarLeave}
      style={containerStyle}
    >
      <div
        className={classnames(style.floatingItemsPositioner, {
          [style.activeBorder]: isCollapsedButtonActive,
        })}
      >
        <div
          className={classnames(style.sidebar, {
            [style.opaque]: props.opaque,
          })}
          id={sidebarId}
        >
          <div className={style.resizableWrapper}>
            <Resizable
              className={classnames(style.resizable)}
              defaultSize={{ width: sidebarWidth, height: "100%" }}
              enable={{
                top: false,
                right:
                  isCollapsed && !props.screenSide === "right" ? false : true,
                bottom: false,
                left:
                  isCollapsed && props.screenSide === "right" ? false : true,
                topRight: false,
                bottomRight: false,
                bottomLeft: false,
                topLeft: false,
              }}
              maxWidth={400}
              minWidth={243}
              onResizeStop={handleResizeStop}
              ref={resizeable}
            >
              {props.children}
            </Resizable>
          </div>
        </div>
        <Button
          aria-controls={sidebarId}
          aria-expanded={!isCollapsed}
          className={classnames(style.collapseButton, {})}
          onBlur={handleCollapsedButtonBlur}
          onClick={handleCollapsedButtonClick}
          onFocus={handleCollapsedButtonFocus}
          onMouseEnter={handleCollapsedButtonHoverEnter}
          onMouseLeave={handleCollapsedButtonHoverLeave}
          ref={collapseButtonRef}
          title={isCollapsed ? "Expand" : "Collapse"}
        >
          <Icon fill="currentColor" type={collapsibleIcon} />
        </Button>
      </div>
    </div>
  );
}
