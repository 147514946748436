// @flow
import classnames from "classnames";
import * as React from "react";
import ExternalLink from "core/components/ExternalLink";
import { V3Link as Link } from "core/lib/router";
import CollapsibleCard from "./Collapsible";
import style from "./style.scss";

type Props = {
  children: React.Node,
  footer?: React.Node,
  header?: React.Node,
  component?: "div" | typeof ExternalLink | typeof Link,
  className?: string,
  innerClassName?: string,
  minWidth?: string,
  mobile?: boolean,
  dynamic?: boolean,
  defaultCollapsed?: boolean,
  collapsible?: boolean,
  collapsed?: boolean,
  list?: boolean,
  wrappedList?: boolean,
  disabled?: boolean,
  hasList?: boolean,
  hasFooter?: boolean,
  storageId?: string,
  qaSelector?: string,
  href?: string,
};

export default function Card(props: Props) {
  const {
    children,
    className,
    innerClassName,
    footer,
    header,
    mobile,
    dynamic,
    component,
    collapsed,
    collapsible,
    minWidth,
    list,
    wrappedList,
    hasList,
    hasFooter,
    storageId,
    qaSelector,
    ...rest
  } = props;

  if (collapsible) {
    return <CollapsibleCard {...props} />;
  }

  const Component = component || "div";
  const classes = classnames(
    style.card,
    {
      [style.mobile]: mobile,
      [style.list]: list || wrappedList,
      [style.wrappedList]: wrappedList,
      [style.collapsed]: collapsed,
      [style.hasList]: hasList,
      [style.hasHeader]: !!header,
      [style.hasFooter]: !!footer || hasFooter,
    },
    className
  );

  const styles = {
    minWidth,
  };

  const content = (
    <Component
      {...rest}
      className={classes}
      style={styles}
      data-qa={qaSelector}
    >
      {header && <div className={style.header}>{header}</div>}
      <div className={classnames(style.box, innerClassName)}>{children}</div>
      {footer && <div className={style.footer}>{footer}</div>}
    </Component>
  );

  if (dynamic && !list) {
    return <div className={style.dynamic}>{content}</div>;
  }
  return content;
}
