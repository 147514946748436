// @flow
export const VISIBILITY = {
  SPECIFIC: "specific",
  ORGANIZATION: "organization",
};

export function isPrivate(visibility: string): boolean {
  return visibility === VISIBILITY.SPECIFIC;
}

export function getFilter(filter?: string) {
  return filter === "archived" ? filter : "active";
}
