// @flow
import { connect } from "react-redux";
import { recurlyError } from "core/actions/recurly";
import createConnector from "core/lib/createConnector";
import type { State, Dispatch } from "core/types";
import type { OwnProps, StateProps, DispatchProps, Props } from "./index";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  return {
    threeDSToken: state.recurly.threeDSChallengeActionToken,
    error: state.recurly.error,
    accountCode: state.recurly.threeDSChallengeAccountCode,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    dispatch3DSChallengeError: (error: Error) => {
      dispatch(
        recurlyError({
          code: "validation_error",
          fields: ["card"],
          details: [{ field: "card", messages: [error.message] }],
        })
      );
    },
  };
}

export default createConnector<Props, OwnProps>(
  connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch>(
    mapStateToProps,
    mapDispatchToProps
  )
);
