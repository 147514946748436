// @flow
import * as Abstract from "abstract-sdk";
import invariant from "invariant";
import { debounce, capitalize } from "lodash";
import * as React from "react";
import { Helmet } from "react-helmet";
import AddMembersToProjectDialog from "core/components/AddMembersToProjectDialog";
import Button from "core/components/Button";
import Error from "core/components/Empty/Error";
import NoPeople from "core/components/Empty/NoPeople";
import NoResults from "core/components/Empty/NoResults";
import NoTeams from "core/components/Empty/NoTeams";
import Flex from "core/components/Flex";
import Loaded from "core/components/Loaded";
import MainContent from "core/components/MainContent";
import Media from "core/components/Media";
import Modal from "core/components/Modal";
import { createPaginationDataLoaderComponent } from "core/components/PaginationDataLoader";
import PeopleFilterMenu from "core/components/PeopleFilterMenu";
import PeopleTeamsHeader from "core/components/PeopleTeamsHeader";
import ProjectMemberMenu from "core/components/ProjectMemberMenu/Wrapper";
import ProjectMembersHeader from "core/components/ProjectMembersHeader";
import VirtualizedPeopleList from "core/components/VirtualizedPeopleList";
import VirtualizedTeamsList from "core/components/VirtualizedTeamsList";
import { replace, addQuery, removeQuery } from "core/lib/location";
import { projectMembersPath } from "core/lib/routes";
import { PROJECT_TEAMS_LIMIT } from "core/lib/teams";
import { PaginatedProjectMembershipsRequest } from "core/requests/projectMemberships";
import { PaginatedProjectTeamsRequest } from "core/requests/teams";
import { getTeamsForProject } from "core/selectors/teams";
import { getUsersForProject } from "core/selectors/users";
import type {
  Project,
  ProjectMembership,
  ProjectMembersTab,
  Subscription,
  SubscriptionRole,
  ReactRouterLocation,
  ProjectRoleFilter,
  RoleFilter,
  ViewType,
  Organization,
  OrganizationMembership,
  User,
  Team,
} from "core/types";
import connector from "./connector";
import style from "./style.scss";

type RoleParam = "guest" | "admin" | "projectAdmin" | "contributor";

export type OwnProps = {|
  params: Abstract.ProjectDescriptor,
  location: ReactRouterLocation,
  tab: ProjectMembersTab,
|};

export type StateProps = {|
  projectMemberships: { [string]: ProjectMembership },
  organization: ?Organization,
  organizationId: string,
  organizationMemberships: { [string]: OrganizationMembership },
  searchFilter: string,
  project: ?Project,
  subscription: ?Subscription,
  roleFilter: ?RoleFilter,
  projectMembershipError: boolean,
  isLoading: boolean,
  canUseTeams: boolean,
  canManageUsers: boolean,
  canManageBilling: boolean,
  canAddMember: boolean,
  canAddGuest: boolean,
  canAddContributor: boolean,
  projectTeams: Team[],
  projectTeamsPaginationTotal: ?number,
  isProjectTeamsExpanding: boolean,
|};

export type DispatchProps = {|
  onLoad: () => void,
  loadRemainingProjectTeams: (offset: number, limit: number) => void,
|};

export type StorageProps = {|
  viewType: ViewType,
  onChangeViewType: (viewType: ViewType) => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
  ...StorageProps,
};

export type PropsWithoutStorage = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type LoaderSharedProps = {|
  hasNextPage: boolean,
  isLoadingNextPage: boolean,
  onLoadNextPage?: () => void,
|};

type TeamsLoader = {
  items: Team[],
  ...LoaderSharedProps,
};

type PeopleLoader = {
  items: User[],
  ...LoaderSharedProps,
};

type PaginatedProjectMembershipsParams = {
  ...Abstract.ProjectDescriptor,
  role?: ProjectRoleFilter,
  subscriptionRole?: SubscriptionRole,
  query?: string,
  userId?: string,
  fromOrganization?: boolean,
};

type PaginatedTeamsRequestParams = {|
  ...Abstract.ProjectDescriptor,
  search?: string,
  userId?: string,
|};

const PeopleDataLoader = createPaginationDataLoaderComponent<
  PaginatedProjectMembershipsParams,
  User[],
>(
  "ProjectPeople",
  PaginatedProjectMembershipsRequest,
  (response) => response.data.length,
  getUsersForProject,
  30
);

const TeamsDataLoader = createPaginationDataLoaderComponent<
  PaginatedTeamsRequestParams,
  Team[],
>(
  "ProjectTeams",
  PaginatedProjectTeamsRequest,
  (response) => response.data.teams.length,
  getTeamsForProject,
  30
);

function ProjectMembers(props: Props) {
  const [displayMode, toggleDisplayMode] = React.useState(props.viewType);
  const [isInviteDialogOpen, setInviteDialogOpen] = React.useState(false);
  const [projectTeamsExpanded, toggleProjectTeamsExpanded] =
    React.useState(false);

  const canInviteToProjects =
    props.canAddContributor || props.canAddMember || props.canAddGuest;

  const {
    projectTeamsPaginationTotal,
    loadRemainingProjectTeams,
    onChangeViewType,
    roleFilter,
    searchFilter,
  } = props;

  const peopleListRequestParams = React.useMemo(() => {
    invariant(props.params.projectId, "props.params.projectId must be present");

    const params = {
      projectId: props.params.projectId,
      search: searchFilter || undefined,
      query: searchFilter || undefined,
      role:
        roleFilter === "admin" ||
        roleFilter === "member" ||
        roleFilter === "guest"
          ? roleFilter
          : undefined,
      subscriptionRole:
        roleFilter === "viewer" || roleFilter === "contributor"
          ? roleFilter
          : undefined,
    };
    return params;
  }, [props.params.projectId, roleFilter, searchFilter]);

  const teamsListRequestParams = React.useMemo(() => {
    const params = {
      projectId: props.params.projectId,
      search: searchFilter || undefined,
    };
    return params;
  }, [props.params.projectId, searchFilter]);

  const showProjectTeamsExpandButton = projectTeamsPaginationTotal
    ? projectTeamsPaginationTotal > PROJECT_TEAMS_LIMIT
    : false;

  const onExpandProjectTeams = React.useCallback(() => {
    if (projectTeamsExpanded) {
      toggleProjectTeamsExpanded(false);
    } else if (projectTeamsPaginationTotal) {
      loadRemainingProjectTeams(
        PROJECT_TEAMS_LIMIT,
        projectTeamsPaginationTotal - PROJECT_TEAMS_LIMIT
      );
      toggleProjectTeamsExpanded(true);
    }
  }, [
    projectTeamsExpanded,
    projectTeamsPaginationTotal,
    loadRemainingProjectTeams,
  ]);

  React.useEffect(() => {
    if (!props.canUseTeams && props.tab !== "people") {
      replace(projectMembersPath(props.params.projectId, "people"));
    }
  }, [props.canUseTeams, props.params.projectId, props.tab]);

  const handleInviteDialogToggle = () => {
    setInviteDialogOpen(!isInviteDialogOpen);
  };

  const handleSearch = debounce((query?: string) => {
    replace(query ? addQuery({ s: query }) : removeQuery("s"));
  }, 200);

  const handleSearchChange = (event: SyntheticInputEvent<>) => {
    handleSearch(event.target.value);
  };

  const handleRole = (role: ?RoleParam, userId: string): boolean => {
    const { projectMemberships, params } = props;
    const membership = projectMemberships[`${params.projectId}-${userId}`];
    const membershipRole = membership ? membership.role : undefined;
    const subscriptionRole = membership
      ? membership.subscriptionRole
      : undefined;

    switch (role) {
      case "admin":
        return membershipRole === "admin";
      case "guest":
        return membershipRole === "guest" || membership.isOrganizationGuest;
      case "projectAdmin":
        return (
          membershipRole === "admin" &&
          !membership.fromOrganization &&
          !membership.fromTeam
        );
      case "contributor":
        return subscriptionRole === "contributor";
      default:
        return false;
    }
  };

  const isContributor = (userId: string): boolean => {
    return handleRole("contributor", userId);
  };

  const isAdmin = (userId: string): boolean => {
    return handleRole("admin", userId);
  };

  const isGuest = (userId: string): boolean => {
    return handleRole("guest", userId);
  };

  const isProjectAdmin = (userId: string): boolean => {
    return handleRole("projectAdmin", userId);
  };

  const getTeamIds = (userId: string): string[] => {
    const membership =
      props.projectMemberships[`${props.params.projectId}-${userId}`];
    return membership && membership.teamIds
      ? [...new Set(membership.teamIds)]
      : [];
  };

  const createdAt = (user: User): string => {
    const { organizationMemberships, organization } = props;
    const membership = organization
      ? organizationMemberships[`${organization.id}-${user.id}`]
      : undefined;

    return membership ? membership.createdAt : user.createdAt;
  };

  const renderEmpty = () => {
    if (props.projectMembershipError) {
      return <Error flex />;
    }

    if (searchFilter) {
      const { tab } = props;
      const type =
        tab === "teams"
          ? "teams"
          : tab === "everyone"
          ? "people or teams"
          : "people";

      return <NoResults type={type} term={searchFilter} flex />;
    }

    if (props.tab === "teams") {
      return (
        <NoTeams
          title="Teams"
          flex
          onClick={
            canInviteToProjects ? () => setInviteDialogOpen(true) : undefined
          }
          isProject
        />
      );
    }

    // Falling back to NoPeople state for people and everyone tabs
    return <NoPeople flex />;
  };

  const renderInviteDialog = () => {
    if (props.project) {
      const title = `Invite to ${props.project.name}`;
      return (
        <Modal
          isOpen={isInviteDialogOpen}
          onClose={handleInviteDialogToggle}
          className={style.dialog}
          title={title}
        >
          <AddMembersToProjectDialog
            project={props.project}
            organizationId={props.project.organizationId}
            onDismissDialog={handleInviteDialogToggle}
            subscription={props.subscription}
          />
        </Modal>
      );
    }

    return null;
  };

  const renderMemberMenu = (input: { user?: User, team?: Team }) => {
    const { canManageUsers, project, subscription, projectMemberships } = props;

    if (input.user && input.user.id) {
      const membership =
        projectMemberships[`${props.params.projectId}-${input.user.id}`];
      if (membership.teamIds && membership.teamIds.length > 0) {
        return null;
      }
    }

    if (
      !project ||
      !canManageUsers ||
      (project && !project.organizationId) ||
      (subscription && subscription.type === "free")
    ) {
      return null;
    }

    return (
      <ProjectMemberMenu project={project} user={input.user} team={input.team}>
        {(showMenu, renderMenuTarget) =>
          renderMenuTarget((ref, buttonProps) => {
            return (
              <Button
                icon="overflow"
                innerRef={ref}
                onClick={showMenu}
                title="Open Project Member Menu"
                nude
                {...buttonProps}
                qaSelector="membership-menu-button"
              />
            );
          })
        }
      </ProjectMemberMenu>
    );
  };

  const onDisplayGrid = React.useCallback(() => {
    onChangeViewType("grid");
    toggleDisplayMode("grid");
  }, [onChangeViewType]);

  const onDisplayList = React.useCallback(() => {
    onChangeViewType("list");
    toggleDisplayMode("list");
  }, [onChangeViewType]);

  const renderEveryoneList = (loaderProps: PeopleLoader, isMobile: boolean) => {
    const { isLoadingNextPage, items, hasNextPage, onLoadNextPage } =
      loaderProps;
    return (
      <Loaded
        loading={items.length === 0 && isLoadingNextPage}
        title="Loading people and teams…"
        flex
      >
        {() =>
          items.length === 0 &&
          props.projectTeams.length === 0 &&
          !isLoadingNextPage ? (
            renderEmpty()
          ) : (
            <div className={style.wrap}>
              <VirtualizedPeopleList
                users={items}
                mode="everyone"
                projectTeams={
                  projectTeamsExpanded
                    ? props.projectTeams
                    : props.projectTeams.slice(0, PROJECT_TEAMS_LIMIT)
                }
                roleFilter={roleFilter}
                searchFilter={searchFilter}
                isGrid={!isMobile && displayMode === "grid"}
                isMobile={isMobile}
                isAdmin={isAdmin}
                isGuest={isGuest}
                isContributor={isContributor}
                isProjectAdmin={isProjectAdmin}
                userCreatedAt={createdAt}
                userTeamIds={getTeamIds}
                renderMemberMenu={renderMemberMenu}
                hasNextPage={hasNextPage}
                isLoadingNextPage={isLoadingNextPage}
                onLoadNextPage={onLoadNextPage}
                canUseTeams={props.canUseTeams}
                organizationId={props.organizationId}
                projectTeamsPaginationTotal={projectTeamsPaginationTotal}
                projectTeamsExpanded={projectTeamsExpanded}
                showExpandButton={showProjectTeamsExpandButton}
                onExpandProjectTeams={onExpandProjectTeams}
                isProjectTeamsExpanding={props.isProjectTeamsExpanding}
                isProject
              />
            </div>
          )
        }
      </Loaded>
    );
  };

  const renderPeopleList = (loaderProps: PeopleLoader, isMobile: boolean) => {
    const { isLoadingNextPage, items, hasNextPage, onLoadNextPage } =
      loaderProps;
    return (
      <React.Fragment>
        {items.length === 0 && props.canUseTeams && (
          <PeopleFilterMenu
            isGrid={!isMobile && displayMode === "grid"}
            isMobile={isMobile}
            roleFilter={roleFilter}
            isProject
          />
        )}
        <Loaded
          loading={items.length === 0 && isLoadingNextPage}
          title="Loading people…"
          flex
        >
          {() =>
            items.length === 0 && !isLoadingNextPage ? (
              renderEmpty()
            ) : (
              <div className={style.wrap}>
                <VirtualizedPeopleList
                  users={items}
                  mode="people"
                  roleFilter={roleFilter}
                  searchFilter={searchFilter}
                  isGrid={!isMobile && displayMode === "grid"}
                  isMobile={isMobile}
                  isAdmin={isAdmin}
                  isGuest={isGuest}
                  isContributor={isContributor}
                  isProjectAdmin={isProjectAdmin}
                  userTeamIds={getTeamIds}
                  userCreatedAt={createdAt}
                  renderMemberMenu={renderMemberMenu}
                  hasNextPage={hasNextPage}
                  isLoadingNextPage={isLoadingNextPage}
                  onLoadNextPage={onLoadNextPage}
                  canUseTeams={props.canUseTeams}
                  organizationId={props.organizationId}
                  showFilterSubmenu
                  isProject
                />
              </div>
            )
          }
        </Loaded>
      </React.Fragment>
    );
  };

  const renderTeamsList = (loaderProps: TeamsLoader, isMobile: boolean) => {
    const { isLoadingNextPage, items, hasNextPage } = loaderProps;
    return (
      <Loaded
        loading={items.length === 0 && isLoadingNextPage}
        title="Loading teams…"
        flex
      >
        {() =>
          items.length === 0 && !isLoadingNextPage ? (
            renderEmpty()
          ) : (
            <div className={style.wrap}>
              <VirtualizedTeamsList
                params={{ organizationId: props.organizationId }}
                allTeams={items}
                searchFilter={searchFilter}
                isGrid={!isMobile && displayMode === "grid"}
                isMobile={isMobile}
                hasNextPage={hasNextPage}
                renderMemberMenu={renderMemberMenu}
                isLoadingNextPage={isLoadingNextPage}
                onLoadNextPage={loaderProps.onLoadNextPage}
              />
            </div>
          )
        }
      </Loaded>
    );
  };

  return (
    <Media desktop>
      {(desktop) => {
        if (!desktop && displayMode === "grid") {
          toggleDisplayMode("list");
        }

        return (
          <MainContent className={style.mainContent}>
            <Flex column>
              <Helmet>
                <title>{capitalize(props.tab)}</title>
              </Helmet>
              {props.canUseTeams ? (
                <PeopleTeamsHeader
                  params={props.params}
                  mode={props.tab}
                  mobile={!desktop}
                  isGrid={displayMode === "grid"}
                  onPrimaryActionTrigger={handleInviteDialogToggle}
                  onDisplayGrid={onDisplayGrid}
                  onDisplayList={onDisplayList}
                  onSearchChange={handleSearchChange}
                  renderInviteDialog={renderInviteDialog}
                  canAddMember={props.canAddMember}
                  searchFilter={searchFilter}
                  isProject
                  canInviteToProjects={canInviteToProjects}
                />
              ) : (
                <ProjectMembersHeader
                  canAddMember={props.canAddMember}
                  canManageBilling={props.canManageBilling}
                  isGrid={displayMode === "grid"}
                  roleFilter={roleFilter}
                  mobile={!desktop}
                  onDisplayGrid={onDisplayGrid}
                  onDisplayList={onDisplayList}
                  onInviteDialogTrigger={handleInviteDialogToggle}
                  onSearchChange={handleSearchChange}
                  organizationId={props.organizationId}
                  renderInviteDialog={renderInviteDialog}
                  searchFilter={searchFilter}
                />
              )}
              {/* Render Teams + People if tab "everyone" */}
              {props.tab === "everyone" ? (
                <PeopleDataLoader params={peopleListRequestParams}>
                  {(loaderProps) => renderEveryoneList(loaderProps, !desktop)}
                </PeopleDataLoader>
              ) : null}
              {/* Render People if tab "people" */}
              {props.tab === "people" ? (
                <PeopleDataLoader params={peopleListRequestParams}>
                  {(loaderProps) => renderPeopleList(loaderProps, !desktop)}
                </PeopleDataLoader>
              ) : null}
              {/* Render Teams if tab "teams" */}
              {props.tab === "teams" ? (
                <TeamsDataLoader params={teamsListRequestParams}>
                  {(loaderProps) => renderTeamsList(loaderProps, !desktop)}
                </TeamsDataLoader>
              ) : null}
            </Flex>
          </MainContent>
        );
      }}
    </Media>
  );
}

export default connector(ProjectMembers);
