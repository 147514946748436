// @flow
import { map, find, reject, values, capitalize } from "lodash";
import pluralize from "pluralize";
import { isCommitUnpushed } from "abstract-di/selectors";
import { setPaginationTotal } from "core/actions/paginationTotals";
import { showLoadingToast, showToast } from "core/actions/toasts";
import { collectionLocation } from "core/lib/routes";
import {
  CollectionLayerCreateRequest,
  CollectionLayerDeleteRequest,
  CollectionLayerUpdateRequest,
  CollectionLayerCreateMultipleRequest,
} from "core/requests/collections";
import { LayerFetchRequest } from "core/requests/layers";
import { getBranchHead } from "core/selectors/branches";
import {
  getCollectionLayer,
  isCollectionLayerRemoved,
} from "core/selectors/collections";
import { getPaginationTotal } from "core/selectors/paginationTotals";
import type {
  ThunkAction,
  Layer,
  Collection,
  MultiSelectedEntities,
} from "core/types";

export function removeCollectionLayer(
  projectId: string,
  collectionLayerId: string,
  onNeedsConfirmation?: () => *
): ThunkAction {
  return (dispatch, getState) => {
    const removed = isCollectionLayerRemoved(getState(), {
      collectionLayerId,
    });

    if (removed || !onNeedsConfirmation) {
      dispatch(
        CollectionLayerDeleteRequest.perform({
          params: {
            projectId,
            id: collectionLayerId,
          },
        })
      );
    } else if (onNeedsConfirmation) {
      onNeedsConfirmation();
    }
  };
}

export function updateUseLatestCommitForCollectionLayer({
  projectId,
  branchId,
  collectionLayerId,
  useLatestCommit,
}: {
  projectId: string,
  branchId: string,
  collectionLayerId: string,
  useLatestCommit: boolean,
}): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState();
    const head = getBranchHead(state, { projectId, branchId });
    const collectionLayer = getCollectionLayer(state, {
      collectionLayerId,
      projectId,
    });

    if (!collectionLayer) {
      throw new Error("collection layer is required to change useLatestCommit");
    }

    const { sha, fileId, layerId } = collectionLayer;

    await dispatch(
      LayerFetchRequest.perform({
        params: {
          projectId,
          branchId,
          sha: useLatestCommit ? head : sha,
          fileId,
          layerId,
        },
      })
    );
    dispatch(
      CollectionLayerUpdateRequest.perform({
        params: {
          id: collectionLayerId,
          useLatestCommit,
          projectId,
        },
      })
    );
  };
}

export function addLayerToCollection({
  collectionId,
  projectId,
  branchId,
  layer,
  useLatestCommit,
  openNewCollection,
  onSuccess,
}: {
  collectionId: string,
  projectId: string,
  branchId: string,
  layer: Layer,
  useLatestCommit?: boolean,
  openNewCollection?: boolean,
  onSuccess?: () => *,
}): ThunkAction {
  return (dispatch, getState) => {
    const head = getBranchHead(getState(), { projectId, branchId });

    const unpushed = isCommitUnpushed(getState(), {
      projectId: projectId,
      branchId: branchId,
      sha: layer.lastChangedAtSha,
    });

    if (unpushed) {
      dispatch(
        showToast({
          icon: "collection",
          text: `${capitalize(layer.type)} could not be added to collection`,
          subtext: "Please wait for syncing to complete and try again",
        })
      );
      return;
    }

    const loadingToast = dispatch(
      showLoadingToast({
        icon: "collection",
        text: `Adding ${layer.type} to collection…`,
      })
    );

    dispatch(
      CollectionLayerCreateRequest.perform({
        params: {
          kind: "layer",
          collectionId,
          projectId,
          branchId,
          fileId: layer.fileId,
          pageId: layer.pageId,
          layerId: layer.id,
          sha: layer.lastChangedAtSha,
          useLatestCommit: useLatestCommit || head === layer.lastChangedAtSha,
        },
        onSuccess: (response) => {
          if (onSuccess) {
            onSuccess();
          }
          openNewCollection
            ? loadingToast.showAutoClose({
                text: `${capitalize(layer.type)} added to a new collection`,
              })
            : loadingToast.showAutoClose({
                text: `${capitalize(layer.type)} added`,
                link: collectionLocation(projectId, branchId, collectionId),
                linkLabel: "View Collection",
              });
        },
        onError: (error) => {
          loadingToast.error({
            text: "Failed to add to collection",
            subtext: error.message,
          });
        },
      })
    );
  };
}

export function addLayersToCollection(
  params: { projectId: string, branchId: string, commitSha?: string },
  selected: MultiSelectedEntities,
  collection: Collection,
  allowDuplicates: boolean = true,
  useLatestCommit?: boolean
): ThunkAction {
  const collectionId = collection ? collection.id : "";
  const file = find(selected.files, { hasMore: true });
  const page = find(selected.pages, { hasMore: true });
  const layers = values(selected.layers);

  return (dispatch, getState) => {
    if (!page && !file && !layers.length) {
      return;
    }

    const hasMore = !!(file || page);
    const head = getBranchHead(getState(), {
      projectId: params.projectId,
      branchId: params.branchId,
    });

    let entities = { layers };

    if (file) {
      entities = {
        files: [file],
        layers: reject(layers, { fileId: file.id, sha: file.sha }),
      };
    } else if (page) {
      entities = {
        pages: [page],
        layers: reject(layers, {
          fileId: page.fileId,
          pageId: page.id,
          sha: page.sha,
        }),
      };
    }

    entities.layers = map(entities.layers, (layer) => {
      const isLatestCommit = head === layer.lastChangedAtSha;
      return {
        ...layer,
        sha: params.commitSha ? layer.sha : layer.lastChangedAtSha,
        useLatestCommit: useLatestCommit ? undefined : isLatestCommit,
        /*
          If the request has a useLatestCommit param, we do not need to assign
          a param to each individual layer. Do not set this value to false
          unless you want to override the request-level useLatestCommit param.
        */
      };
    });

    const message = hasMore
      ? "items"
      : `${layers.length} ${pluralize("item", layers.length)}`;

    const loadingToast = dispatch(
      showLoadingToast({
        icon: "collection",
        text: `Adding ${message} to ${collection.name}…`,
      })
    );

    /*
      ❗️This is a hacky, but pragmatic solution (under pressure of fixing in release-87)
      reference: https://github.com/goabstract/ui/pull/2208
      We need a better way of determining and caching the total number of collections.
      Please do not add on to this - it will need to be changed!
    */
    if (!collection.publishedAt) {
      const currentPaginationTotals = getPaginationTotal(
        getState(),
        params.branchId
      );

      dispatch(
        setPaginationTotal(
          params.branchId,
          currentPaginationTotals ? currentPaginationTotals + 1 : 0
        )
      );
    }

    dispatch(
      CollectionLayerCreateMultipleRequest.perform({
        params: {
          collectionId,
          projectId: params.projectId,
          branchId: params.branchId,
          allowDuplicates,
          useLatestCommit,
          ...entities,
        },
        onSuccess: (response) => {
          const responseLength = response.data.length;

          loadingToast.showAutoClose({
            text: `${responseLength || "No"} ${pluralize(
              "item",
              responseLength
            )} added`,
          });
        },
        onError: (error) => {
          loadingToast.error({
            text: "Failed to add items to collection",
            subtext: error.message,
          });
        },
      })
    );
  };
}
