// @flow
import * as React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { getUnreadNotificationsCount } from "core/selectors/notifications";
import type { State } from "core/types";

type OwnProps = {|
  defaultTitle: string,
  titleTemplate: string,
|};

type StateProps = {|
  unreadNotificationsCount: number,
|};

type Props = {
  ...OwnProps,
  ...StateProps,
};

function DocumentTitle(props: Props) {
  const { defaultTitle, titleTemplate, unreadNotificationsCount } = props;

  const unreadNotificationsTitle =
    unreadNotificationsCount > 0 ? `(${unreadNotificationsCount}) ` : "";

  return (
    <Helmet
      defaultTitle={`${unreadNotificationsTitle}${defaultTitle}`}
      defer={false}
      titleTemplate={`${unreadNotificationsTitle}${titleTemplate}`}
    />
  );
}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  return {
    unreadNotificationsCount: getUnreadNotificationsCount(state),
  };
}

export default connect<Props, OwnProps, StateProps, _, State, _>(
  mapStateToProps
)(DocumentTitle);
