// @flow
import capitalize from "lodash/capitalize";
import * as React from "react";
import AddDuplicateConfirmation from "core/components/AddDuplicateConfirmation";
import ContextMenu from "core/components/ContextMenu";
import SharingSettings from "core/components/SharingSettings";
import { push } from "core/lib/location";
import { collectionLocation } from "core/lib/routes";
import { DEFAULT_COLLECTION_VALUES } from "core/models/collection";
import * as File from "core/models/file";
import * as Layer from "core/models/layer";
import type {
  Layer as TLayer,
  LayerShellMode,
  Collection,
  Branch,
  MenuItem,
  InputShare,
  ShareMenuItemProps,
  CollectionFormValues,
} from "core/types";
import connector from "./connector";

export type OwnProps = {|
  layer: TLayer,
  projectId: string,
  branchId: string,
  collectionId?: string,
  collectionLayerId?: string,
  children: (Function, Function, boolean) => React.Node,
  hideShare?: boolean,
  onAfterClose?: () => void,
  isMobile?: boolean,
  useLatestCommit?: boolean,
  selectedMode?: LayerShellMode,
|};

export type StateProps = {|
  development: boolean,
  isLayerCommitUnpushed: boolean,
  canAddToCollection: boolean,
  branch: ?Branch,
  head: string,
  organizationId: string,
  collections: Collection[],
  collectionsWithLayer: { [collectionId: string]: boolean },
  isLoadingCollections: boolean,
  isLoadingShareLink: boolean,
  collection: ?Collection,
  canShowHandoff: boolean,
  inputShare: InputShare,
  canShare: boolean,
  fileType: ?string,
  isLatestCommitFeatureEnabled: boolean,
  isOnline: boolean,
|};

export type DispatchProps = {|
  openDebuggingDiff?: () => void,
  copyLayerId?: () => void,
  addLayerToCollection: (
    collectionId: string,
    isNewCollection?: boolean,
    onSuccess: () => void
  ) => void,
  handleOpenUntracked: () => void,
  handleEditInSketch: (sha: string) => void,
  createCollection: (
    values: CollectionFormValues,
    onSuccess: (collection: Collection) => void
  ) => void,
  createShareLink: (inputShare: InputShare) => void,
  loadCollections: () => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type State = {
  showForm: boolean,
  confirmingCollection: ?Collection,
  showSharingSettings: boolean,
  isSubmittingNewCollection: boolean,
};

class LayerMenu extends React.Component<Props, State> {
  menu: ?ContextMenu;

  state = {
    showForm: false,
    confirmingCollection: null,
    showSharingSettings: false,
    isSubmittingNewCollection: false,
  };

  menuRef = (ref: ?ContextMenu) => (this.menu = ref);

  componentDidMount() {
    this.props.loadCollections();
  }

  closeMenu = () => {
    this.menu && this.menu.close();
  };

  onSharingSettingsClose = () => {
    this.setState({ showSharingSettings: false });
  };

  handleShowForm = () => this.setState({ showForm: true });
  handleHideForm = () => this.setState({ showForm: false });

  handleAddLayer = (
    collection: Collection,
    isNewCollection: boolean = false
  ) => {
    this.props.addLayerToCollection(collection.id, isNewCollection, () => {
      this.setState({
        confirmingCollection: null,
        showForm: false,
      });
      if (isNewCollection) {
        push(
          collectionLocation(
            this.props.projectId,
            this.props.branchId,
            collection.id
          )
        );
      }
    });
    this.closeMenu();
  };
  handleCancelAddLayer = () => {
    this.setState({ confirmingCollection: null });
  };
  handleConfirmAddLayer = () => {
    const { confirmingCollection } = this.state;

    if (!confirmingCollection) {
      return;
    }

    this.handleAddLayer(confirmingCollection);
    this.handleCancelAddLayer();
  };

  handleClickCollection = (collection: ?Collection) => () => {
    if (!collection) {
      return;
    }

    if (this.props.collectionsWithLayer[collection.id]) {
      return this.setState({ confirmingCollection: collection });
    }

    this.handleAddLayer(collection);
  };

  // Share Params can vary when querying either for This Version or Latest Version
  layerShareParams(options?: { latest: boolean }): ShareMenuItemProps {
    let commitSha = this.props.layer.sha;
    if (options && options.latest) {
      commitSha = this.props.isLatestCommitFeatureEnabled
        ? "latest"
        : undefined;
    }
    return {
      kind: "layer",
      organizationId: this.props.organizationId,
      projectId: this.props.projectId,
      branchId: this.props.branchId,
      collectionId: this.props.collectionId,
      collectionLayerId: this.props.collectionLayerId,
      fileId: this.props.layer.fileId,
      pageId: this.props.layer.pageId,
      commitSha,
      layerId: this.props.layer.id,
      onCopySuccess: this.closeMenu,
      mode: this.props.selectedMode,
    };
  }

  // To correctly query for collection share params, we limit what we serve to the link generator
  getCollectionShareParams(): ShareMenuItemProps {
    return {
      kind: "collection",
      organizationId: this.props.organizationId,
      projectId: this.props.projectId,
      branchId: this.props.branchId,
      collectionId: this.props.collectionId,
      collectionLayerId: this.props.collectionLayerId,
      onCopySuccess: this.closeMenu,
      mode: this.props.selectedMode,
    };
  }

  getMenuItems(): MenuItem[] {
    let menu = [];

    if (this.props.branch) {
      menu.push({
        label: File.editLatestLabel(this.props.fileType),
        click: () => {
          if (this.props.branch) {
            this.props.handleEditInSketch(this.props.head);
          }
        },
      });
    }

    menu.push({
      label: "Open Untracked",
      click: this.props.handleOpenUntracked,
    });

    return menu
      .concat(this.shareOptionsMenu())
      .concat(this.addToCollectionMenu())
      .concat(this.developerTools());
  }

  shareOptionsMenu = () => {
    let menu = [];

    if (!this.props.canShare) {
      return menu;
    }

    if (!this.props.hideShare) {
      menu.push({ type: "separator" });

      if (this.props.collectionLayerId) {
        menu.push({
          type: "share",
          label: `Copy Link to ${
            capitalize(this.props.layer.type) || "Artboard"
          } in this Collection`,
          props: this.getCollectionShareParams(),
        });
      } else {
        menu.push({
          type: "share",
          label: "Copy Link to Latest Version",
          props: this.layerShareParams({ latest: true }),
        });
        menu.push({
          type: "share",
          label: "Copy Link to This Version",
          props: this.layerShareParams({ latest: false }),
        });
      }

      menu.push({
        label: "View Sharing Settings…",
        click: () => {
          this.setState({ showSharingSettings: true });
        },
      });
    }
    return menu;
  };

  addToCollectionMenu = () => {
    if (!this.props.canAddToCollection) {
      return [];
    }

    let bottomMenu = [];
    bottomMenu.push({ type: "separator" });

    if (this.props.isLayerCommitUnpushed) {
      bottomMenu.push({
        label: "Syncing commit…",
        enabled: false,
      });
      return bottomMenu;
    }

    if (this.props.isLoadingCollections) {
      bottomMenu.push({
        label: "Loading collections…",
        enabled: false,
      });
      return bottomMenu;
    }

    bottomMenu.push({
      label: `Add ${capitalize(this.props.layer.type)} to Collection…`,
      submenu: this.getCollectionSubMenu(),
      enabled: this.props.isOnline,
    });
    return bottomMenu;
  };

  getCollectionSubMenu(): MenuItem[] {
    let submenu = this.props.collections.map((collection) => ({
      label: collection.name,
      enabled: true,
      checked: false,
      click: this.handleClickCollection(collection),
    }));

    if (submenu.length > 0) {
      submenu.push({ type: "separator" });
    }

    submenu.push({
      label: "New Collection…",
      click: this.handleNewCollection,
    });

    return submenu;
  }

  developerTools = () => {
    if (!this.props.development) {
      return [];
    }

    return {
      label: "Development",
      submenu: [
        {
          label: "Open Debugging Diff",
          click: () => {
            this.props.openDebuggingDiff && this.props.openDebuggingDiff();
            this.closeMenu();
          },
        },
        {
          label: "Copy Layer ID",
          click: () => {
            this.props.copyLayerId && this.props.copyLayerId();
            this.closeMenu();
          },
        },
      ],
    };
  };

  getItemLabel(): string {
    let itemLabel = "";
    switch (this.props.inputShare.kind) {
      case "collection":
        itemLabel = "collection";
        break;
      case "layer":
        itemLabel = this.props.layer.type || "Artboard";
        break;
      default:
        itemLabel = this.props.inputShare.kind;
    }

    return capitalize(itemLabel);
  }

  dispatchShareLinkRequest = () => {
    if (!this.props.isLoadingShareLink) {
      this.props.createShareLink(this.props.inputShare);
    }
  };

  handleNewCollection = () => {
    if (this.state.isSubmittingNewCollection) {
      return;
    }
    this.setState({ isSubmittingNewCollection: true }, () =>
      this.props.createCollection(
        DEFAULT_COLLECTION_VALUES,
        this.handleNewCollectionSuccess
      )
    );
  };

  handleNewCollectionSuccess = (collection: Collection) => {
    this.setState({ isSubmittingNewCollection: false });
    this.handleAddLayer(collection, true);
  };

  render() {
    const { confirmingCollection } = this.state;
    const { layer } = this.props;

    return (
      <React.Fragment>
        <ContextMenu
          ref={this.menuRef}
          id={Layer.uniqueId(layer)}
          menuItems={this.getMenuItems()}
          children={(showMenu, menuRef) =>
            this.props.children(
              showMenu,
              menuRef,
              this.props.isLayerCommitUnpushed
            )
          }
          onAfterClose={this.props.onAfterClose}
        />
        <AddDuplicateConfirmation
          isOpen={!!confirmingCollection}
          hasMore={false}
          duplicates={[this.props.layer]}
          submitText="Cancel"
          onClose={this.handleCancelAddLayer}
          onSecondary={this.handleConfirmAddLayer}
          onSubmit={this.handleCancelAddLayer}
        />
        <SharingSettings
          onOpen={this.dispatchShareLinkRequest}
          itemLabel={this.getItemLabel()}
          organizationId={this.props.organizationId}
          inputShare={this.props.inputShare}
          canShowHandoff={this.props.canShowHandoff}
          collection={this.props.collection}
          isOpen={this.state.showSharingSettings}
          onClose={this.onSharingSettingsClose}
        />
      </React.Fragment>
    );
  }
}

export default connector(LayerMenu);
