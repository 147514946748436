// @flow
import { connect } from "react-redux";
import { isOnline } from "abstract-di/selectors";
import { fetchBranchReviews } from "core/actions/reviews";
import { withData } from "core/components/DataLoader";
import createConnector from "core/lib/createConnector";
import { getBranchReview } from "core/selectors/reviews";
import type { Dispatch, State } from "core/types";
import type { OwnProps, StateProps, DispatchProps, Props } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { projectId, branchId } = props;

  return {
    branchReview: getBranchReview(state, { projectId, branchId }),
    isOffline: !isOnline(state),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { projectId, branchId } = props;

  return {
    onLoad() {
      dispatch(fetchBranchReviews({ projectId, branchId }));
    },
  };
}

export default createConnector<Props, OwnProps>(
  connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch>(
    mapStateToProps,
    mapDispatchToProps
  ),
  withData((props) => ({
    branchId: props.branchId,
    projectId: props.projectId,
  }))
);
