// @flow
import * as React from "react";
import { helpWebhooksUrl } from "core/lib/urls";
import image from "./images/webhooks.svg";
import Empty from ".";

export default function NoWebhooks() {
  return (
    <Empty
      description={
        <React.Fragment>
          You haven't added any webhooks for this organization yet.
          <br />
          <a href={helpWebhooksUrl()} rel="noopener noreferrer" target="_blank">
            Learn more about webhooks…
          </a>
        </React.Fragment>
      }
      image={image}
      title="No webhooks"
    />
  );
}
