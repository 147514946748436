// @flow
import * as React from "react";
import { useSelector } from "react-redux";
import { getCurrentUser } from "abstract-di/selectors";
import AccountMenu from "core/components/AccountMenu";
import Avatar from "core/components/Avatar";
import Button from "core/components/Button";
import Flex from "core/components/Flex";
import Icon from "core/components/Icon";
import Theme from "core/components/Theme";
import type { Organization } from "core/types";
import style from "./style.scss";

type Props = {
  closeMobileSidebar?: () => void,
  currentOrganization: ?Organization,
  mobile?: boolean,
};

export default function AccountDropdown(props: Props) {
  const currentUser = useSelector(getCurrentUser);

  return (
    <Theme.Consumer>
      {({ themeName, themeConfig, setThemeConfig }) => {
        return (
          <AccountMenu
            closeMobileSidebar={props.closeMobileSidebar}
            mobile={props.mobile}
            setThemeConfig={setThemeConfig}
            showDownload={
              props.currentOrganization
                ? props.currentOrganization.versionsEnabled
                : false
            }
            themeConfig={themeConfig}
          >
            {(showMenu, renderMenuTarget) =>
              renderMenuTarget((ref, buttonProps) => (
                <Button
                  {...buttonProps}
                  className={style.accountButton}
                  innerRef={ref}
                  onClick={showMenu}
                  qaSelector="personalSettingsButton"
                  title="Account"
                  nude
                >
                  <Flex align="center" className={style.accountButtonInner}>
                    <Avatar
                      qaSelector="currentUserAvatar"
                      size={16}
                      userId={currentUser ? currentUser.id : undefined}
                    />
                    {currentUser ? (
                      <span className={style.userName}>{currentUser.name}</span>
                    ) : null}
                    <Icon className={style.arrowsIcon} type="select" />
                  </Flex>
                </Button>
              ))
            }
          </AccountMenu>
        );
      }}
    </Theme.Consumer>
  );
}
