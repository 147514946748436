// @flow
import createCachedSelector from "@elasticprojects/re-reselect";
import * as Abstract from "abstract-sdk";
import empty from "empty";
import { filter } from "lodash";
import { getSyncedProjectsForOrganization } from "abstract-di/selectors";
import naturalSortBy from "core/lib/naturalSortBy";
import { isWeb } from "core/lib/platform";
import * as Change from "core/models/change";
import * as File from "core/models/file";
import { FilesFetchRequest } from "core/requests/files";
import { getBranchHead } from "core/selectors/branches";
import { getChangeset } from "core/selectors/changesets";
import { getResolvedLayerDescriptor } from "core/selectors/descriptors";
import { getEntity, getRawEntities } from "core/selectors/entities";
import { canOrganizationUsePartialSync } from "core/selectors/features";
import { getFilteredProjects } from "core/selectors/projects";
import type { File as TFile, State } from "core/types";
import { getProjectId, getSha, getFileId } from "./helpers";

const cacheSha = (state, params) => `${params.projectId}-${params.sha}`;
const cacheBranch = (state, params) => `${params.projectId}-${params.branchId}`;

export function getAllFiles(state: State): { [id: string]: TFile } {
  return getRawEntities(state, "files");
}

export function getFile(
  state: State,
  { projectId, sha, fileId }: { projectId: string, sha: string, fileId: string }
): ?TFile {
  return getEntity(state, "files", File.uniqueId({ projectId, sha, fileId }));
}

export const getFilesAtSha: (
  state: State,
  { projectId: string, sha: string }
) => TFile[] = createCachedSelector(
  getAllFiles,
  getProjectId,
  getSha,
  (files, projectId, sha) =>
    naturalSortBy(filter(files, { projectId, sha }), "name")
)(cacheSha);

function getFileAtSha(
  state: State,
  params: { projectId: string, sha: string, fileId: string }
) {
  return getRawEntities(state, "files")[File.uniqueId(params)];
}

export function getFilesForBranch(
  state: State,
  { projectId, branchId }: { projectId: string, branchId: string }
) {
  const head = getBranchHead(state, { projectId, branchId });
  if (!head) {
    return empty.array;
  }
  return getFilesAtSha(state, { projectId, sha: head });
}

export function getFileForLayer(
  state: State,
  params: Abstract.LayerVersionDescriptor
) {
  const resolvedParams = getResolvedLayerDescriptor(state, params);
  const { projectId, sha, fileId } = resolvedParams;
  return getFile(state, { projectId, sha, fileId });
}

function getBranchChangeset(
  state: State,
  { projectId, branchId }: { projectId: string, branchId: string }
) {
  return getChangeset(state, { projectId, branchId });
}

const getChangedFilesMapForBranch: (
  state: State,
  { projectId: string, branchId: string }
) => { [fileId: string]: boolean } = createCachedSelector(
  getAllFiles,
  getBranchChangeset,
  (files, changeset) => {
    if (!changeset) {
      return empty.object;
    }
    const { projectId, sha } = changeset;

    return changeset.changes.reduce((memo, change) => {
      if (change.objectType !== "file" || Change.isRemoved(change)) {
        return memo;
      }
      const { fileId } = change;
      const file = files[File.uniqueId({ projectId, sha, fileId })];
      if (!file) {
        return memo;
      }
      memo[file.id] = true;
      return memo;
    }, {});
  }
)(cacheBranch);

export const getFileHasChanged: (
  state: State,
  { projectId: string, branchId: string, fileId: string }
) => boolean = createCachedSelector(
  getFileId,
  getChangedFilesMapForBranch,
  (fileId, changedFilesMap) => !!changedFilesMap[fileId]
)(cacheBranch);

export const getChangedFilesForBranch: (
  State,
  { projectId: string, branchId: string }
) => TFile[] = createCachedSelector(
  getFilesForBranch,
  getChangedFilesMapForBranch,
  (files, changedFilesMap) => {
    return files.filter((file) => changedFilesMap[file.id]);
  }
)(cacheBranch);

export const getUnchangedFilesForBranch: (
  State,
  { projectId: string, branchId: string }
) => TFile[] = createCachedSelector(
  getFilesForBranch,
  getChangedFilesMapForBranch,
  (files, changedFilesMap) => {
    return files.filter((file) => !changedFilesMap[file.id]);
  }
)(cacheBranch);

export const getDeletedFilesForBranch: (
  State,
  { projectId: string, branchId: string }
) => TFile[] = createCachedSelector(
  getAllFiles,
  getBranchChangeset,
  (files, changeset) => {
    if (!changeset) {
      return empty.array;
    }
    const { projectId, compareToSha } = changeset;
    const filtered = changeset.changes.reduce((memo, change) => {
      if (change.objectType !== "file" || !Change.isRemoved(change)) {
        return memo;
      }
      const file =
        files[
          File.uniqueId({ projectId, sha: compareToSha, fileId: change.fileId })
        ];
      if (file) {
        memo.push(file);
      }
      return memo;
    }, []);
    return naturalSortBy(filtered, "name");
  }
)(cacheBranch);

export function getFileOnBranch(
  state: State,
  {
    projectId,
    branchId,
    fileId,
  }: { projectId: string, branchId: string, fileId: string }
): ?TFile {
  const head = getBranchHead(state, { projectId, branchId });
  if (!head) {
    return;
  }
  return getFileAtSha(state, { projectId, sha: head, fileId: fileId });
}

export function getLibraryFilesForOrganization(
  state: State,
  params: { organizationId: string }
) {
  const projects =
    canOrganizationUsePartialSync(state, params) || isWeb
      ? getFilteredProjects(state, { ...params, filter: "active" })
      : getSyncedProjectsForOrganization(state, params);
  return projects.reduce((memo, project) => {
    const files = getFilesForBranch(state, {
      projectId: project.id,
      branchId: "master",
    });

    const head = getBranchHead(state, {
      branchId: "master",
      projectId: project.id,
    });
    if (!head) {
      return memo;
    }

    memo[project.id] = {
      isLoading: FilesFetchRequest.isLoading(state, {
        projectId: project.id,
        branchId: "master",
        sha: head,
      }),
      entities: files.filter((file) => file.isLibrary),
    };

    return memo;
  }, {});
}
