// @flow
import classnames from "classnames";
import { memoize } from "lodash";
import * as React from "react";
import Button from "core/components/Button";
import DelayedMount from "core/components/DelayedMount";
import Flex from "core/components/Flex";
import ProjectBranchesMenuItem from "core/components/GlobalSidebar/ProjectBranchesMenuItem";
import GlobalSidebarMenuItem from "core/components/GlobalSidebarMenuItem";
import type { KeyboardNavigationProps } from "core/components/KeyboardNavigation";
import PlaceholderText from "core/components/PlaceholderText";
import ProjectColor from "core/components/ProjectColor";
import ProjectMenu from "core/components/ProjectMenu/Wrapper";
import usePrevious from "core/hooks/usePrevious";
import * as Routes from "core/lib/routes";
import { BRANCH_ID_MASTER } from "core/models/branch";
import type { Branch, Project as TProject } from "core/types";
import connector from "./connector";
import style from "./style.scss";

export function Placeholder({ className }: { className?: string }) {
  return (
    <DelayedMount>
      <Flex align="center" className={classnames(style.placeholder, className)}>
        <Flex
          align="center"
          className={style.colorPlaceholder}
          grow={false}
          justify="center"
          qaSelector="global-placeholder"
        >
          <ProjectColor squircle placeholder />
        </Flex>
        <PlaceholderText size="m" />
      </Flex>
    </DelayedMount>
  );
}

export type OwnProps = {|
  className?: string,
  closeMobileSidebar?: () => void,
  currentBranch?: Branch,
  isCurrentProject?: boolean,
  keyboardNavigationProps: KeyboardNavigationProps,
  mobile?: boolean,
  onClick?: (event: SyntheticEvent<>) => void,
  project: TProject,
|};

export type StateProps = {|
  canShowCollections: boolean,
  canMoveProject: boolean,
  defaultBranchName: string,
|};

export type StorageProps = {|
  collapsed: boolean,
  toggleCollapsed: (collapsed: boolean) => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...StorageProps,
};

const activePaths = memoize((projectId) => [
  Routes.projectPath(projectId, "activity"),
  Routes.projectPath(projectId, "everyone"),
  Routes.projectPath(projectId, "people"),
  Routes.projectPath(projectId, "teams"),
  Routes.projectPath(projectId, "edit"),
]);

function FilesProjectMenuItem(props: Props) {
  const {
    collapsed,
    currentBranch,
    defaultBranchName,
    isCurrentProject,
    toggleCollapsed,
  } = props;

  const [isBranchesExpanded, setIsBranchesExpanded] = React.useState(false);

  const prevCurrentBranch = usePrevious(currentBranch);
  const prevIsCurrentProject = usePrevious(isCurrentProject);

  React.useEffect(() => {
    if (
      ((isCurrentProject && !prevIsCurrentProject) ||
        (currentBranch && currentBranch !== prevCurrentBranch)) &&
      toggleCollapsed &&
      collapsed
    ) {
      toggleCollapsed(false);
    }
  }, [
    collapsed,
    currentBranch,
    isCurrentProject,
    prevCurrentBranch,
    prevIsCurrentProject,
    toggleCollapsed,
  ]);

  return (
    <ProjectMenu
      closeMobileSidebar={props.closeMobileSidebar}
      project={props.project}
      canMoveProject={props.canMoveProject}
    >
      {(showMenu, renderMenuTarget) =>
        renderMenuTarget((ref, buttonProps) => {
          return (
            <GlobalSidebarMenuItem
              actionButton={(props) => (
                <Button
                  {...props}
                  {...buttonProps}
                  icon="overflow"
                  innerRef={ref}
                  onClick={showMenu}
                  title="More Project options"
                  qaSelector="projectMenuSidebarButton"
                  nude
                />
              )}
              activePaths={activePaths(props.project.id)}
              className={props.className}
              collapsed={props.collapsed}
              icon={<ProjectColor color={props.project.color} squircle />}
              keyboardNavigationProps={props.keyboardNavigationProps}
              label={props.project.name}
              mobile={props.mobile}
              onClick={props.onClick}
              onContextMenu={showMenu}
              onToggleCollapsed={props.toggleCollapsed}
              to={Routes.projectPath(props.project.id)}
              qaSelector="projectSidebarItem"
              qaSelectorWrapper={props.project.name}
              actionHover
              expandOnActive
              large
            >
              {props.collapsed ? null : (
                <React.Fragment>
                  {props.canShowCollections && (
                    <GlobalSidebarMenuItem
                      icon="collection-small"
                      keyboardNavigationProps={props.keyboardNavigationProps}
                      label="Collections"
                      mobile={props.mobile}
                      onClick={props.onClick}
                      to={Routes.projectPath(props.project.id, "collections")}
                      qaSelector="collectionsSidebarItem"
                      qaSelectorWrapper="Collections"
                    />
                  )}
                  <GlobalSidebarMenuItem
                    icon="master-small"
                    keyboardNavigationProps={props.keyboardNavigationProps}
                    label={defaultBranchName}
                    mobile={props.mobile}
                    onClick={props.onClick}
                    to={Routes.branchPath(props.project.id, BRANCH_ID_MASTER)}
                    qaSelector="mainSidebarItem"
                    qaSelectorWrapper={defaultBranchName}
                    activeOnChildPaths
                  />
                  <ProjectBranchesMenuItem
                    currentBranch={props.currentBranch}
                    isBranchesExpanded={isBranchesExpanded}
                    keyboardNavigationProps={props.keyboardNavigationProps}
                    mobile={props.mobile}
                    onClick={props.onClick}
                    onExpandBranches={setIsBranchesExpanded}
                    projectId={props.project.id}
                  />
                </React.Fragment>
              )}
            </GlobalSidebarMenuItem>
          );
        })
      }
    </ProjectMenu>
  );
}

function ProjectMenuItem(props: Props) {
  return <FilesProjectMenuItem {...props} />;
}

export default connector(ProjectMenuItem);
