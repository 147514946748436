// @flow
import { static as Immutable } from "seamless-immutable";
import type { Action, Session } from "core/types";

export type State = $Shape<Session>;

export const DEFAULT_STATE = Immutable.from({});
const DEFAULT_ACTION = { type: "core/noop" };

export default function sessionReducer(
  state: State = DEFAULT_STATE,
  action: Action = DEFAULT_ACTION
): State {
  switch (action.type) {
    case "core/SESSION_LOADED":
      return Immutable.from(action.payload);
    default:
      return state;
  }
}
