// @flow
import empty from "empty";
import * as Abstract from "core/types";

export const NO_CHILDREN = empty.array;
export const NO_OVERRIDES = empty.object;
export const NO_SYMBOL = "";

export const TEXT_LEFT_ALIGNMENT = 0;
export const TEXT_RIGHT_ALIGNMENT = 1;
export const TEXT_NATURAL_ALIGNMENT = 4;

export const LAYER_AXIS_HORIZONTAL: Abstract.LayerAxisHorizontal = 0;
export const LAYER_AXIS_VERTICAL: Abstract.LayerAxisVertical = 1;

export const LAYER_DIRECTION_START_TO_END: Abstract.LayerDirectionStartToEnd = 0;
export const LAYER_DIRECTION_CENTER: Abstract.LayerDirectionCenter = 1;
export const LAYER_DIRECTION_END_TO_START: Abstract.LayerDirectionEndToStart = 2;
