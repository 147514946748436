// @flow
import queryString from "query-string";
import { request } from "core/actions/requests";
import window from "core/global/window";
import * as API from "../api";
import * as URLs from "../api/urls";
import { loginRequired } from "./login";
import type { ThunkAction } from ".";

export function loadZendeskToken(returnToUrl: string): ThunkAction {
  return async (dispatch) => {
    dispatch(
      request(URLs.fetchZendeskToken(), () => API.fetchZendeskToken(), {
        onSuccess: ({ zendeskToken }: { zendeskToken: string }) => {
          const query = queryString.stringify({
            jwt: zendeskToken,
            return_to: returnToUrl,
          });
          // Redirect back to Zendesk with the token
          window.location.replace(
            `https://goabstract.zendesk.com/access/jwt?${query}`
          );
        },
        onError: (error) => {
          if (
            error instanceof API.ForbiddenError ||
            error instanceof API.NotFoundError // deprecated
          ) {
            dispatch(loginRequired());
          }
        },
      })
    );
  };
}
