// @flow
import * as React from "react";
import AnimatedFlyover from "core/components/AnimatedFlyover";
import Button from "core/components/Button";
import ButtonGroup from "core/components/ButtonGroup";
import CopyLinkButton from "core/components/CopyLinkButton";
import Popover from "core/components/Popover";
import ShareInfo from "core/components/ShareInfo";
import { stringToLayerMode } from "core/lib/layer";
import { assembleShareLinkUrl, isPublic } from "core/models/shareLink";
import type {
  ShareLink,
  InputShare,
  ProjectVisibility,
  Organization,
  Query,
} from "core/types";
import connector from "./connector";

export type OwnProps = {|
  collectionId?: string,
  inputShare: InputShare,
  selected?: string,
  layerType?: string,
  large?: boolean,
  primary?: boolean,
  isMobile?: boolean,
  qaSelector?: string,
  query?: Query,
|};

export type StateProps = {|
  shareLink: ?ShareLink,
  canShowHandoff: boolean,
  isGenerating: boolean,
  hasError: boolean,
  projectVisibility: ?ProjectVisibility,
  organization: ?Organization,
  canShare: boolean,
  isOnline: boolean,
|};

export type DispatchProps = {|
  onLoad: () => Promise<void>,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type State = {
  isOpen: boolean,
};

class ShareButton extends React.Component<Props, State> {
  state = { isOpen: false };

  get itemLabel(): string {
    let itemLabel = "";

    switch (this.props.inputShare.kind) {
      case "collection":
        itemLabel = "collection";
        break;
      case "layer":
        itemLabel = this.props.layerType || "Artboard";
        break;
      default:
        itemLabel = this.props.inputShare.kind;
    }

    return itemLabel.charAt(0).toUpperCase() + itemLabel.slice(1).toLowerCase();
  }

  toggleFlyover = () => {
    this.setState((state) => ({ isOpen: !this.state.isOpen }));
  };

  render() {
    if (!this.props.canShare) {
      return null;
    }

    const itemLabel = this.itemLabel;

    const visibility = {
      organization: {
        icon: this.props.large ? "organization" : "organization-small",
        popoverBody: this.props.organization
          ? `Only people at
        ${this.props.organization.name} can view this ${itemLabel}`
          : undefined,
      },
      specific: {
        icon: this.props.large ? "lock-locked-alt" : "lock-locked",
        popoverBody: `Only people in this project can view this ${itemLabel}`,
      },
      public: {
        icon: this.props.large ? "public" : "public-small",
        popoverBody: `Anyone with the link can view this ${itemLabel}`,
      },
      loading: {
        icon: "spinner",
        popoverBody: undefined,
      },
      default: {
        icon: "share",
        popoverBody: undefined,
      },
    };

    const shareVisibility = this.props.isGenerating
      ? "loading"
      : isPublic(this.props.shareLink)
      ? "public"
      : this.props.projectVisibility || "default";

    const primary =
      this.props.primary !== undefined
        ? this.props.primary
        : !this.props.isMobile;

    const url = assembleShareLinkUrl(this.props.shareLink, this.props.query);

    return (
      <AnimatedFlyover
        body={
          this.props.organization ? (
            <ShareInfo
              shareLink={this.props.shareLink}
              layerType={this.props.layerType}
              collectionLayerId={
                this.props.query ? this.props.query.collectionLayerId : null
              }
              collectionId={this.props.collectionId}
              organizationId={this.props.organization.id}
              infoText={visibility[shareVisibility].popoverBody}
              itemLabel={this.itemLabel}
              inputShare={this.props.inputShare}
              onCopySuccess={() => this.setState({ isOpen: false })}
              canShowHandoff={this.props.canShowHandoff}
              mode={
                this.props.query
                  ? stringToLayerMode(this.props.query.mode)
                  : null
              }
              selected={this.props.selected}
              isMobile={this.props.isMobile}
              largeButtons={this.props.large}
            />
          ) : null
        }
        isOpen={this.state.isOpen}
        onClickOutside={() => this.setState({ isOpen: false })}
        onRequestClose={() => this.setState({ isOpen: false })}
        scrollable
      >
        <Popover
          delayShow={1000}
          trigger="hover"
          placement="bottom"
          label={visibility[shareVisibility].popoverBody}
          forceHide={this.state.isOpen}
          disabled={this.props.isGenerating || this.props.hasError}
        >
          {/* We wrap <ButtonGroup /> in a <span> here because Popover will pass a ref
              down to it's first child to calculate the position of the popover.
              By passing it to <span> instead of Button, we can set a reference to Button
              from this component to setup our Clipboard.
          */}
          <span style={{ display: "inherit" }}>
            <ButtonGroup>
              {!this.props.isMobile && (
                <CopyLinkButton
                  doNotCreateShareLink
                  url={url || null}
                  inputShare={this.props.inputShare}
                  nude={false}
                  tint={false}
                  primary={primary}
                  icon={
                    visibility[
                      this.props.isMobile ? "default" : shareVisibility
                    ].icon
                  }
                  hideLabel={this.props.isMobile}
                  tooltip={false}
                  large={this.props.large}
                  disabled={this.props.isGenerating}
                />
              )}
              <Button
                primary={primary}
                onClick={this.toggleFlyover}
                icon={
                  this.props.isMobile
                    ? visibility.default.icon
                    : "chevron-default-down"
                }
                nude={this.props.isMobile}
                tint={this.props.isMobile}
                large={this.props.large}
                aria-label="Share Settings"
                qaSelector="shareSettingsMenuButton"
              />
            </ButtonGroup>
          </span>
        </Popover>
      </AnimatedFlyover>
    );
  }
}

export default connector(ShareButton);
