// @flow
import * as React from "react";
import ProfileModal from "core/components/ProfileModal";
import type { User } from "core/types";

type Props = { user?: ?User };
type State = { modalIsOpen: boolean };

export default class UserNameWithModal extends React.Component<Props, State> {
  state = { modalIsOpen: false };

  showModal = (e: SyntheticEvent<*>) => {
    const { user } = this.props;
    this.setState({ modalIsOpen: user ? true : false });
  };

  hideModal = (e: SyntheticEvent<*>) => {
    this.setState({ modalIsOpen: false });
  };

  render() {
    const { user, ...rest } = this.props;
    return (
      <React.Fragment>
        {user ? (
          <a {...rest} role="button" tabIndex="0" onClick={this.showModal}>
            {user.name}
          </a>
        ) : (
          <span {...rest}>Unknown</span>
        )}
        <ProfileModal
          {...this.props.user}
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.hideModal}
          onClose={this.hideModal}
        />
      </React.Fragment>
    );
  }
}
