// @flow
import { difference, uniq, map } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { markNotificationsAsRead } from "core/actions/notifications";
import WindowFocusListener from "core/components/WindowFocusListener";
import type { Comment, Dispatch } from "core/types";

type OwnProps = {|
  comments: Comment[],
  id?: string,
  disabled?: boolean,
  children?: React.Node,
|};

type DispatchProps = {|
  markNotificationsAsRead: (commentIds?: string[]) => void,
|};

type Props = {
  ...OwnProps,
  ...DispatchProps,
};

type State = { readCommentIds: string[] };

const MARK_AS_READ_DELAY = 3000;

class CommentNotificationReader extends React.PureComponent<Props, State> {
  listener: ?WindowFocusListener;
  markAsReadTimeout: ?TimeoutID;

  state = { readCommentIds: [] };

  componentDidMount() {
    this.markCommentsAsReadAfterDelay(this.unreadCommentIds);
  }

  componentDidUpdate(prevProps: Props) {
    /*
      We listen for changes here instead of remounting when the id/disabled
      props change because we keep track of which comments have been read
      in the component state. If we remount, that state will be lost and we'll
      make an unnecessary call to the database.
    */
    const enabled = prevProps.disabled && !this.props.disabled;

    if (prevProps.id !== this.props.id || enabled) {
      this.markCommentsAsReadAfterDelay(this.unreadCommentIds);
    }

    if (prevProps.comments.length < this.props.comments.length) {
      this.markCommentsAsReadIfFocused();
    }
  }

  componentWillUnmount() {
    if (this.markAsReadTimeout) {
      clearTimeout(this.markAsReadTimeout);
      this.markAsReadTimeout = undefined;
    }
  }

  markCommentsAsReadAfterDelay = (ids: string[]) => {
    if (!this.markAsReadTimeout) {
      this.markAsReadTimeout = setTimeout(() => {
        this.markCommentsAsRead(ids);
        this.markAsReadTimeout = undefined;
      }, MARK_AS_READ_DELAY);
    }
  };

  /*
    Marks notifications related to comments in this list as read if the window
    is in focus. This is called when new comments are pushed into the list
    whilst it is mounted - it may be in the background.
  */
  markCommentsAsReadIfFocused = () => {
    if (this.focused) {
      this.markCommentsAsRead();
    }
  };

  markCommentsAsRead = (ids?: string[]) => {
    if (this.props.disabled) {
      return;
    }

    const commentIds = ids || this.unreadCommentIds;
    if (!commentIds.length) {
      return;
    }

    this.props.markNotificationsAsRead(commentIds);

    this.setState((prev) => ({
      readCommentIds: uniq(prev.readCommentIds.concat(commentIds)),
    }));
  };

  setListenerRef = (ref: ?WindowFocusListener) => (this.listener = ref);

  get unreadCommentIds(): string[] {
    const ids = map(this.props.comments, "id");
    return difference(ids, this.state.readCommentIds);
  }

  get focused(): boolean {
    return !!(this.listener && this.listener.focused);
  }

  render() {
    return (
      <WindowFocusListener
        ref={this.setListenerRef}
        onFocus={this.markCommentsAsRead}
      >
        {this.props.children || null}
      </WindowFocusListener>
    );
  }
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    markNotificationsAsRead: (commentIds?: string[]) => {
      dispatch(markNotificationsAsRead({ commentIds }));
    },
  };
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default connect(null, mapDispatchToProps)(CommentNotificationReader);
