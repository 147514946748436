// @flow
import * as React from "react";
import { V3Link as Link } from "core/lib/router";
import { collectionLocation } from "core/lib/routes";
import ActivityBase from "./Base";
import type {
  ActivityCollectionPublished as TActivityCollectionPublished,
  Props,
} from "./types";

export default function CollectionPublished(
  props: Props<TActivityCollectionPublished>
) {
  const { activity } = props;
  return (
    <ActivityBase
      {...props}
      icon="collection"
      action="published collection"
      subject={
        <Link
          to={collectionLocation(
            activity.projectId,
            activity.branchId,
            activity.payload.collectionId
          )}
        >
          {activity.payload.name}
        </Link>
      }
    />
  );
}
