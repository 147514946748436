// @flow
import { map } from "lodash";
import * as React from "react";
import DialogForm from "core/components/DialogForm";
import InputUserSelect from "core/components/InputUserSelect";
import type { User, Branch, ReviewRequest, BranchStatus } from "core/types";
import BranchReviewNote from "../BranchReviewNote";
import EmptyReviewerListItem from "../Create/ReviewerListItem";
import ReviewerNote from "../ReviewerNote";
import style from "../style.scss";
import ReviewerListItem from "./ReviewerListItem";
import connector from "./connector";

export type OwnProps = {|
  branch: Branch,
  branchId: string,
  branchId: string,
  dismissDialog: () => void,
  isOpen: boolean,
  isOffline?: boolean,
  projectId: string,
  branchStatus?: BranchStatus,
|};

export type StateProps = {|
  requiredReviewRequests: ReviewRequest[],
  optionalReviewRequests: ReviewRequest[],
  branchStatus: ?BranchStatus,
|};

export type DispatchProps = {|
  deleteReviewRequest: (id: string) => Promise<void>,
  dismissReview: (id: string) => Promise<void>,
  addReviewer: (reviewerId: string) => Promise<void>,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type State = {
  pickedUser: ?User,
};

class ManageReviewersDialog extends React.Component<Props, State> {
  state = {
    pickedUser: null,
  };

  handleReviewerPick = async (user: User) => {
    this.setState({ pickedUser: user });
    await this.props.addReviewer(user.id);
    this.setState({ pickedUser: null });
  };

  render() {
    const {
      branch,
      deleteReviewRequest,
      dismissDialog,
      dismissReview,
      isOffline,
      isOpen,
      requiredReviewRequests,
      optionalReviewRequests,
      projectId,
      branchStatus,
    } = this.props;

    // A list of all user IDs that are ineligable to review (either currently
    // reviewing or are the branch owner).
    const reviewerIds = [
      ...map(
        [...requiredReviewRequests, ...optionalReviewRequests],
        "reviewerId"
      ),
      branch.userId,
    ];

    const reviewsRequired = requiredReviewRequests.length > 0;

    if (isOffline) {
      return null;
    }

    const { pickedUser } = this.state;

    return (
      <DialogForm
        title="Manage Reviewers"
        onClose={dismissDialog}
        primaryButton={null}
        secondaryButton="Done"
        isOpen={isOpen}
        fixedHeight
      >
        {() => (
          <React.Fragment>
            <BranchReviewNote
              required={reviewsRequired}
              minNumberOfApprovals={
                branchStatus ? branchStatus.minimumRequiredApprovals : null
              }
            />
            <InputUserSelect
              disabled={!!pickedUser}
              placeholder="Add a reviewer… (Optional)"
              projectId={projectId}
              excludedUserIds={reviewerIds}
              value={pickedUser && pickedUser.id}
              onPick={this.handleReviewerPick}
            />

            <ul className={style.list}>
              {pickedUser && !reviewerIds.includes(pickedUser.id) && (
                <EmptyReviewerListItem
                  reviewer={pickedUser}
                  className={style.itemUpdating}
                  joinedOn="Adding…"
                  required={false}
                />
              )}

              {requiredReviewRequests.map((reviewRequest) => (
                <ReviewerListItem
                  badge={"Required Reviewer"}
                  dismissReview={dismissReview}
                  key={reviewRequest.id}
                  required
                  reviewRequest={reviewRequest}
                />
              ))}

              {optionalReviewRequests.map((reviewRequest) => (
                <ReviewerListItem
                  deleteReviewRequest={deleteReviewRequest}
                  dismissReview={dismissReview}
                  key={reviewRequest.id}
                  reviewRequest={reviewRequest}
                />
              ))}
            </ul>

            <ReviewerNote required={reviewsRequired} />
          </React.Fragment>
        )}
      </DialogForm>
    );
  }
}

export default connector(ManageReviewersDialog);
