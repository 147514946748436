// @flow
import * as React from "react";
import NotFound from "core/components/Empty/NotFound";
import Flex from "core/components/Flex";
import Loaded from "core/components/Loaded";
import MainContent from "core/components/MainContent";
import { replace } from "core/lib/location";
import { organizationTeamsPath } from "core/lib/routes";
import type {
  Team,
  Policy,
  OrganizationTeamDescriptor,
  ReactRouterLocation,
} from "core/types";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  location: ReactRouterLocation,
  params: OrganizationTeamDescriptor,
  children: React.Node,
|};
export type StateProps = {|
  team: ?Team,
  teamPolicy: Policy,
  isTeamLoading: boolean,
  organizationPolicy: Policy,
  notFound: ?boolean,
|};
export type DispatchProps = {|
  onLoad: () => void,
|};
export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

function OrganizationTeam(props: Props) {
  if (props.notFound) {
    return <NotFound />;
  }

  if (!props.organizationPolicy.showTeams) {
    replace(organizationTeamsPath(props.params.organizationId));
  }

  return (
    <MainContent className={style.mainContent}>
      <Flex column>
        <Loaded loading={props.isTeamLoading} title="Loading team" flex>
          {props.children}
        </Loaded>
      </Flex>
    </MainContent>
  );
}

export default connector(OrganizationTeam);
