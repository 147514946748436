// @flow
import classnames from "classnames";
import * as React from "react";
import style from "./style.scss";

type Props = {
  label: string,
  light?: boolean,
  innerRef?: React.Ref<"div">,
  className?: string,
};

export default function Badge({ innerRef, label, light, className }: Props) {
  return (
    <div
      ref={innerRef}
      className={classnames(
        style.container,
        {
          [style.light]: light,
        },
        className
      )}
    >
      {label}
    </div>
  );
}
