// @flow
import classnames from "classnames";
import * as React from "react";
import ButtonUnstyled from "core/components/ButtonUnstyled";
import Icon from "core/components/Icon";
import { V3Link as Link } from "core/lib/router";
import { LABELS } from "core/models/projectMembership";
import type { PillBadgeTypes } from "core/types";
import style from "./style.scss";

export function getLabelBadgeType(label: string): PillBadgeTypes {
  switch (label) {
    case LABELS.MEMBER:
      return "paleTint";
    case LABELS.ADMIN:
    case LABELS.PROJECT_ADMIN:
      return "paleAllGood";
    default:
      return "default";
  }
}

type Props = {
  component?: string | typeof Link,
  type?: PillBadgeTypes,
  title: React.Node,
  onClick?: (event: SyntheticEvent<>) => void,
  hideDisclosure?: boolean,
  className?: string,
  innerRef?: React.Ref<React.ElementType>,
  disabled?: boolean,
};

export default function PillBadge({
  component,
  className,
  type,
  title,
  onClick,
  hideDisclosure,
  innerRef,
  disabled,
  ...rest
}: Props) {
  let Component;
  if (component) {
    Component = component;
  } else {
    if (onClick) {
      Component = ButtonUnstyled;
    } else {
      Component = "div";
    }
  }
  const clickableProps = onClick
    ? { onClick, role: "button", tabIndex: "0", disabled }
    : {};

  const badgeTypeStyle = type && style[type];

  return (
    <Component
      ref={innerRef}
      className={classnames(style.pill, badgeTypeStyle, className, {
        [style.disclosure]: !!onClick && !hideDisclosure,
        [style.clickable]: !!onClick,
      })}
      {...clickableProps}
      {...rest}
    >
      <div className={style.pillText}>{title}</div>
      {onClick && !hideDisclosure && (
        <Icon
          className={style.chevron}
          fill="currentColor"
          type="chevron-default-down"
        />
      )}
    </Component>
  );
}
