// @flow
import * as React from "react";
import Button from "../Button";

type Props = {
  active: boolean,
  disabled: boolean,
};

export default function ButtonToggleAnnotations({
  active,
  disabled,
  ...rest
}: Props) {
  return (
    <Button
      {...rest}
      icon={active ? "annotation-indicator-tint" : "annotation-indicator"}
      disabled={disabled}
      nude
      title={active ? "Hide annotations" : "Show annotations"}
      tooltip={{
        placement: "top",
      }}
    />
  );
}
