// @flow
import classnames from "classnames";
import * as React from "react";
import {
  useTable,
  useFlexLayout,
  useResizeColumns,
  usePagination,
} from "react-table";
import Button from "core/components/Button";
import ButtonRightIcon from "core/components/ButtonRightIcon";
import Flex from "core/components/Flex";
import type { ReactTablePagination } from "core/types";
import style from "./style.scss";

type Props = {
  columns: Array<Object>,
  data: Array<Object>,
  emptyNode?: React.Node,
  // enablePagination. Default false.
  // When true, shows pagination controls
  enablePagination?: boolean,
  // manual. Default false.
  // When set to true, manually controls sorting and pagination
  // by fetching data from a server.
  // Pagination requires: controlledPageCount, changePage
  manual?: boolean,
  controlledPageSize?: number,
  controlledPageCount?: number,
  controlledPageIndex?: number,
  controlledSortDir?: string,
  controlledSortBy?: string,
  changePage: (pageData: ReactTablePagination) => Promise<void>,
  changeSort: (pageData: ReactTablePagination) => Promise<void>,
  loading: boolean,
};

const defaultColumn = {
  minWidth: 30,
  width: 150,
  maxWidth: 400,
  canResize: true,
};

export default function Table(props: Props) {
  const {
    columns,
    data,
    controlledPageCount,
    controlledPageSize,
    controlledPageIndex,
    controlledSortBy,
    controlledSortDir,
    changePage,
    enablePagination,
    manual,
    loading,
    changeSort,
  } = props;

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: controlledPageIndex,
        pageSize: controlledPageSize,
      },
      manualPagination: manual,
      pageCount: controlledPageCount,
    },
    useFlexLayout,
    useResizeColumns,
    enablePagination && usePagination
  );

  React.useEffect(() => {
    // skip effect if pagination is disabled,
    // or pagination is automatic
    if (!enablePagination && !manual) {
      return;
    }
    // This stops this effect from infinite looping
    // when the top level data returns no data, but
    // the table state is considered paginated.
    if (pageIndex > controlledPageCount) {
      return;
    }
    changePage({
      pageIndex,
      pageSize,
      sortBy: controlledSortBy,
      sortDir: controlledSortDir,
    });
  }, [
    changePage,
    enablePagination,
    pageIndex,
    pageSize,
    controlledSortBy,
    controlledSortDir,
    controlledPageCount,
    manual,
  ]);

  return (
    <div className={style.tableWrapper}>
      <div {...getTableProps()} className={style.table}>
        <div>
          {headerGroups.map((headerGroup) => (
            <div
              {...headerGroup.getHeaderGroupProps()}
              className={style.tr}
              data-qa="header-group"
            >
              {headerGroup.headers.map((column) => (
                <div
                  {...column.getHeaderProps()}
                  className={style.th}
                  col-id={column["Header"]}
                >
                  {!column.canSort && column.render("Header")}
                  {column.canSort && manual && (
                    <ButtonRightIcon
                      nude
                      noMargin
                      tint={column.isSorted}
                      icon={
                        column.isSorted
                          ? controlledSortDir === "desc"
                            ? "chevron-default-down"
                            : "chevron-default-up"
                          : undefined
                      }
                      onClick={() => {
                        const { id, isSorted } = column;
                        changeSort({
                          pageIndex,
                          pageSize,
                          sortBy: id,
                          sortDir:
                            isSorted && controlledSortDir === "desc"
                              ? "asc"
                              : "desc",
                        });
                      }}
                      className={classnames(style.sortButton, {
                        [style.sortButtonActive]: column.isSorted,
                      })}
                    >
                      {column.render("Header")}
                    </ButtonRightIcon>
                  )}
                  {column.canResize && (
                    <div
                      {...column.getResizerProps()}
                      className={classnames(style.resizer, {
                        [style.isResizing]: column.isResizing,
                      })}
                    />
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div
          className={classnames(style.tbody, { [style.isLoading]: loading })}
        >
          {rows.length === 0 && props.emptyNode}
          {rows.map((row) => {
            prepareRow(row);
            return (
              <div {...row.getRowProps()} className={style.tr}>
                {row.cells.map((cell, index) => {
                  return (
                    <div
                      {...cell.getCellProps()}
                      className={style.td}
                      col-id={headerGroups[0].headers[index]["Header"]}
                    >
                      {cell.render("Cell")}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      {props.enablePagination && rows.length > 0 && (
        <Flex className="pagination" justify="center" align="center">
          <Button
            onClick={() => previousPage()}
            icon="chevron-default-left"
            disabled={loading || !canPreviousPage}
            nude
          />
          <span className={style.pageInfo}>
            <span className={style.pageNum}>{pageIndex + 1}</span>
            {" of "}
            <span className={style.pageNum}>{pageCount}</span>
          </span>
          <Button
            onClick={() => nextPage()}
            icon="chevron-default-right"
            disabled={loading || !canNextPage}
            nude
          />
        </Flex>
      )}
    </div>
  );
}
