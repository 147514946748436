// @flow
import classnames from "classnames";
import ClipboardAction from "clipboard/lib/clipboard-action";
import idx from "idx";
import get from "lodash/get";
import * as React from "react";
import ButtonHotspots from "core/components/ButtonHotspots";
import ButtonSidebar from "core/components/ButtonSidebar";
import ButtonTogglePreviewBackground from "core/components/ButtonTogglePreviewBackground";
import ColumnGridOverlay from "core/components/ColumnGridOverlay";
import GuidesOverlay from "core/components/GuidesOverlay";
import Header from "core/components/Header";
import KeyboardNavigation from "core/components/KeyboardNavigation";
import LayerCanvas, { type ZoomState } from "core/components/LayerCanvas";
import LayerDetailSidebarSection from "core/components/LayerDetailSidebarSection";
import LayerInspector from "core/components/LayerInspector";
import LayerProperties from "core/components/LayerProperties";
import LayerSetControls from "core/components/LayerSetControls";
import LayerTree from "core/components/LayerTree";
import Loaded from "core/components/Loaded";
import Scrollable from "core/components/Scrollable";
import Sidebar from "core/components/Sidebar";
import SidebarSection from "core/components/SidebarSection";
import SimpleGridOverlay from "core/components/SimpleGridOverlay";
import Transparency from "core/components/Transparency";
import ZoomPercentageInput from "core/components/ZoomPercentageInput";
import ZoomablePreview from "core/components/ZoomablePreview";
import window from "core/global/window";
import { Abstract } from "core/lib/abstract";
import eventInInput from "core/lib/eventInInput";
import KeyCode from "core/lib/keycode";
import { modifierKeyPressed } from "core/lib/platform";
import * as Layer from "core/models/layer";
import * as LayerData from "core/models/layerData";
import type {
  Branch,
  Commit as TCommit,
  File,
  Layer as TLayer,
  LayerChangeset,
  LayerData as TLayerData,
  LayerDataset,
  LayerSetItem,
  LayerSetParams,
  LayerState,
  Page,
  Project,
  ShareLink,
} from "core/types";
import LayerBuildDisabled from "./LayerBuildDisabled";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  collectionId?: string,
  defaultCollapsedLeftSidebar?: boolean,
  defaultCollapsedRightSidebar?: boolean,
  isLoadingMore?: boolean,
  layerSetParams?: LayerSetParams,
  onChangeZoomState: (zoomState: ZoomState) => void,
  onClose?: () => void,
  onCollapseLeftSidebar?: (collapsed: boolean) => void,
  onCollapseRightSidebar?: (collapsed: boolean) => void,
  onSelectLayer: (layerKey: ?string) => void,
  onSelectLayerSetItem?: (layerSetItem: ?LayerSetItem) => void,
  params: Abstract.LayerVersionDescriptor,
  selectedLayerKey: string,
  shareId?: string,
  shareLink?: ShareLink,
  zoomState: ZoomState,
  goBackToSymbolInstanceButton?: React.Node,
|};

export type StateProps = {|
  branch: ?Branch,
  canGenerateAssets: boolean,
  canUseSymbolSourceDisplayName: boolean,
  canShowHandoff: boolean,
  canShowHotspots: boolean,
  canUpgradeSubscription: boolean,
  canvasOverlaysEnabled: boolean,
  commit: ?TCommit,
  error: ?string,
  file: ?File,
  hasHotspots: boolean,
  layer: ?TLayer,
  layers: { [layerKey: string]: TLayerData },
  layerChangesets?: { [layerKey: string]: LayerChangeset },
  layerDataset: ?LayerDataset,
  layerState: LayerState,
  organizationId?: string,
  page: ?Page,
  policyLoaded: boolean,
  project: ?Project,
  showAssets: ?boolean,
  showPrototypes: boolean,
  showDevelopment: boolean,
  symbolMasterLayerData?: ?TLayer,
  symbolMasterLayerDataIsLoading?: boolean,
  symbolMasterLayerDataError?: boolean,
  showGoToSymbolMaster: boolean,
  // we need previousCommit loaded in state in order to fetch
  // its layerdataset when dispatching the below onLoad func
  previousCommit: ?TCommit,
  canUsePreviewBackgroundSwitcher: boolean,
|};

export type DispatchProps = {|
  onLoad: () => void,
  loadSymbolMaster: (selectedLayerKey: ?string) => void,
|};

export type StorageProps = {|
  defaultCollapsedLeftSidebar: boolean,
  defaultCollapsedRightSidebar: boolean,
  onCollapseLeftSidebar: (collapsed: boolean) => void,
  onCollapseRightSidebar: (collapsed: boolean) => void,
  defaultShowLayoutOverlay: boolean,
  defaultShowGridOverlay: boolean,
  defaultShowGuidesOverlay: boolean,
  saveLayoutOverlaySetting: (enabled: boolean) => void,
  saveGridOverlaySetting: (enabled: boolean) => void,
  saveGuidesOverlaySetting: (enabled: boolean) => void,
|};

export type PropsWithoutStorage = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...StorageProps,
  ...DispatchProps,
};

type State = {
  collapsedLeftSidebar: boolean,
  collapsedLeftSidebarMobile: boolean,
  collapsedRightSidebar: boolean,
  hideLayerInspector: boolean,
  showChangeset: boolean,
  showGridOverlay: boolean,
  showGuidesOverlay: boolean,
  showHotspots: boolean,
  showLayoutOverlay: boolean,
  targetedLayer: ?TLayerData,
};

const STUB_CLIPBOARD_EMITTER = {
  emit(status, response) {
    // Stub to prevent ClipboardAction from crashing
  },
};

const SIDEBAR_MIN_WIDTH = 270;

class LayerBuild extends React.Component<Props, State> {
  layerCanvas: ?LayerCanvas;

  static defaultProps = {
    defaultCollapsedLeftSidebar: false,
    defaultCollapsedRightSidebar: false,
    showAssets: false,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      collapsedLeftSidebarMobile: true,
      collapsedLeftSidebar: !!props.defaultCollapsedLeftSidebar,
      collapsedRightSidebar: !!props.defaultCollapsedRightSidebar,
      showChangeset: true,
      targetedLayer: null,
      hideLayerInspector: false,
      showLayoutOverlay: !!props.defaultShowLayoutOverlay,
      showGridOverlay: !!props.defaultShowGridOverlay,
      showGuidesOverlay: !!props.defaultShowGuidesOverlay,
      showHotspots: this.props.canShowHotspots,
    };
  }

  componentDidMount() {
    window.addEventListener("keydown", this.handleKeyDown);
    window.addEventListener("keyup", this.handleKeyUp);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      this.state.collapsedLeftSidebar !== prevState.collapsedLeftSidebar &&
      this.props.onCollapseLeftSidebar
    ) {
      this.props.onCollapseLeftSidebar(this.state.collapsedLeftSidebar);
    }

    if (
      this.state.collapsedRightSidebar !== prevState.collapsedRightSidebar &&
      this.props.onCollapseRightSidebar
    ) {
      this.props.onCollapseRightSidebar(this.state.collapsedRightSidebar);
    }

    if (this.state.showLayoutOverlay !== prevState.showLayoutOverlay) {
      this.props.saveLayoutOverlaySetting(this.state.showLayoutOverlay);
    }

    if (this.state.showGridOverlay !== prevState.showGridOverlay) {
      this.props.saveGridOverlaySetting(this.state.showGridOverlay);
    }

    if (this.state.showGuidesOverlay !== prevState.showGuidesOverlay) {
      this.props.saveGuidesOverlaySetting(this.state.showGuidesOverlay);
    }

    if (prevProps.params !== this.props.params) {
      this.setState({
        targetedLayer: null,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
    window.removeEventListener("keyup", this.handleKeyUp);
  }

  onToggleShowChangeset = () => {
    this.setState((state) => ({
      showChangeset: !state.showChangeset,
    }));
  };

  handleToggleLayoutOverlay = () => {
    this.setState((state) => ({ showLayoutOverlay: !state.showLayoutOverlay }));
  };

  handleToggleGridOverlay = () => {
    this.setState((state) => ({ showGridOverlay: !state.showGridOverlay }));
  };

  handleToggleGuidesOverlay = () => {
    this.setState((state) => ({ showGuidesOverlay: !state.showGuidesOverlay }));
  };

  hasGuides() {
    return (
      !!idx(
        this.props,
        (props) => props.layerDataset.horizontalRulerData.guides
      ) ||
      !!idx(this.props, (props) => props.layerDataset.verticalRulerData.guides)
    );
  }

  hasLayout() {
    const layout = idx(this.props, (props) => props.layerDataset.layout);
    return !!layout && (layout.drawVertical || layout.drawHorizontal);
  }

  hasGrid() {
    const grid = idx(this.props, (props) => props.layerDataset.grid);
    return !!grid && grid.gridSize > 0;
  }

  render() {
    const { layerDataset, layers } = this.props;

    const layerChangesets = this.state.showChangeset
      ? this.props.layerChangesets
      : undefined;

    const rootLayer = layerDataset
      ? layerDataset.layers[layerDataset.layerId]
      : undefined;

    const selectedLayer = layers[this.props.selectedLayerKey] || rootLayer;
    const activeLayer = selectedLayer || rootLayer;
    const isRootLayer = activeLayer === rootLayer;

    const isPublicShare =
      this.props.shareLink && this.props.shareLink.options.public;

    let grid, layout, horizontalGuides, verticalGuides;
    if (layerDataset) {
      grid = layerDataset.grid;
      layout = layerDataset.layout;
      horizontalGuides = layerDataset.horizontalRulerData;
      verticalGuides = layerDataset.verticalRulerData;
    }

    return (
      <Loaded loading={!this.props.policyLoaded || !this.props.project}>
        <LayerBuildDisabled
          disabled={!this.props.canShowHandoff}
          organizationId={this.props.organizationId}
          canUpgradeSubscription={this.props.canUpgradeSubscription}
        >
          <div className={style.layerBuild}>
            <Sidebar
              minWidth={SIDEBAR_MIN_WIDTH}
              resizable="layers-left"
              direction="left"
              contentClassName={classnames(
                style.sidebarContent,
                style.sidebarMaxWidth
              )}
              collapsed={this.state.collapsedLeftSidebar}
              active={!this.state.collapsedLeftSidebarMobile}
              onClose={() =>
                this.setState({ collapsedLeftSidebarMobile: true })
              }
              wide
              contentBorder
            >
              <SidebarSection flex>
                <Loaded loading={!layerDataset}>
                  {() =>
                    layerDataset && (
                      <KeyboardNavigation defaultFocused>
                        {(keyboardNavigation) => (
                          <Scrollable
                            className={style.layerTreeScrollable}
                            innerRef={keyboardNavigation.scrollRef}
                          >
                            <React.Fragment>
                              <div
                                className={style.layerTreeHeader}
                                onClick={this.handleLayerCanvasBlur}
                              />
                              <ul className={style.layerTree}>
                                {rootLayer && (
                                  <LayerTree
                                    params={this.props.params}
                                    layerData={rootLayer}
                                    layers={layerDataset.layers}
                                    layerChangesets={layerChangesets}
                                    selectedLayer={selectedLayer}
                                    onTargetLayer={this.handleTargetLayer}
                                    onSelectLayer={this.handleSelectLayer}
                                    selectedClassName={
                                      keyboardNavigation.selectedClassName
                                    }
                                    disclosureClassName={
                                      keyboardNavigation.disclosureClassName
                                    }
                                    isFocused={keyboardNavigation.isFocused}
                                    disclosure={false}
                                    showAssets={this.props.showAssets}
                                    isPublicShare={isPublicShare}
                                    canUseSymbolSourceDisplayName={
                                      this.props.canUseSymbolSourceDisplayName
                                    }
                                  />
                                )}
                              </ul>
                              <div
                                className={style.layerTreeFooter}
                                onClick={this.handleLayerCanvasBlur}
                              />
                            </React.Fragment>
                          </Scrollable>
                        )}
                      </KeyboardNavigation>
                    )
                  }
                </Loaded>
              </SidebarSection>
              {!this.props.shareId && (
                <LayerDetailSidebarSection
                  params={this.props.params}
                  project={this.props.project}
                  branch={this.props.branch}
                  commit={this.props.commit}
                  file={this.props.file}
                  page={this.props.page}
                  layer={this.props.layer}
                  layerState={this.props.layerState}
                  collectionId={this.props.collectionId}
                />
              )}
            </Sidebar>
            <div className={style.main}>
              <div className={style.layerCanvas}>
                <div className={style.floatingCanvasButtonContainer}>
                  {this.props.goBackToSymbolInstanceButton}
                </div>
                <Transparency
                  useDynamicPreviewBackground={
                    this.props.canUsePreviewBackgroundSwitcher
                  }
                >
                  <LayerCanvas
                    shareId={this.props.shareId}
                    ref={(ref) => (this.layerCanvas = ref)}
                    params={this.props.params}
                    layer={this.props.layer}
                    layerMissing={this.props.layerState === "not_found"}
                    alt={Layer.altText(this.props.layer)}
                    zoomState={this.props.zoomState}
                    onChangeZoomState={this.props.onChangeZoomState}
                    onClick={() =>
                      this.handleSelectLayer(
                        activeLayer
                          ? null
                          : rootLayer && LayerData.key(rootLayer)
                      )
                    }
                    onMouseLeave={() => this.handleTargetLayer(null)}
                    onMouseEnter={() => this.handleTargetLayer(rootLayer)}
                  >
                    {(dimensions, scale, childrenStyle) =>
                      layerDataset && !this.state.hideLayerInspector ? (
                        // Offset by 1px https://github.com/goabstract/JIRA/issues/3458#issuecomment-368176963
                        <div className={style.layerInspectorOffset}>
                          <LayerInspector
                            params={this.props.params}
                            style={childrenStyle}
                            scale={scale / 100}
                            width={dimensions.width}
                            height={dimensions.height}
                            layers={layerDataset.layers}
                            rootLayer={
                              layerDataset.layers[layerDataset.layerId]
                            }
                            selectedLayer={selectedLayer}
                            targetedLayer={
                              this.state.targetedLayer || undefined
                            }
                            onTargetLayer={this.handleTargetLayer}
                            onSelectLayer={this.handleSelectLayer}
                            showHotspots={this.state.showHotspots}
                          />

                          {this.props.canvasOverlaysEnabled &&
                            layout &&
                            this.state.showLayoutOverlay && (
                              <ColumnGridOverlay
                                style={childrenStyle}
                                scale={scale / 100}
                                width={dimensions.width}
                                height={dimensions.height}
                                grid={layout}
                              />
                            )}

                          {this.props.canvasOverlaysEnabled &&
                            this.hasGrid() &&
                            this.state.showGridOverlay &&
                            // hasGrid checks this, but flow needs it here
                            grid && (
                              <SimpleGridOverlay
                                style={childrenStyle}
                                scale={scale / 100}
                                width={dimensions.width}
                                height={dimensions.height}
                                cellSize={grid.gridSize}
                                sectionSize={grid.thickGridTimes}
                              />
                            )}

                          {this.props.canvasOverlaysEnabled &&
                            this.hasGuides() &&
                            this.state.showGuidesOverlay && (
                              <GuidesOverlay
                                style={childrenStyle}
                                scale={scale / 100}
                                width={dimensions.width}
                                height={dimensions.height}
                                horizontal={horizontalGuides}
                                vertical={verticalGuides}
                              />
                            )}
                        </div>
                      ) : null
                    }
                  </LayerCanvas>
                </Transparency>
                <div className={style.layerCanvasFooter}>
                  <Header
                    center={
                      <LayerSetControls
                        onSelectLayerSetItem={this.props.onSelectLayerSetItem}
                        layerParams={this.props.params}
                        shareLinkId={this.props.shareId}
                        layerSetParams={this.props.layerSetParams}
                      />
                    }
                    left={
                      <div className={style.footerToolbarLeft}>
                        <ButtonSidebar
                          className={style.toggleLeftSidebarButton}
                          direction="left"
                          active={!this.state.collapsedLeftSidebar}
                          onClick={() =>
                            this.setState((state) => ({
                              collapsedLeftSidebar: !state.collapsedLeftSidebar,
                            }))
                          }
                        />
                        {this.props.canUsePreviewBackgroundSwitcher && (
                          <React.Fragment>
                            <div
                              className={classnames(
                                style.divider,
                                style.dividerLeftToolbar
                              )}
                            />
                            <ButtonTogglePreviewBackground
                              className={style.togglePreviewBackgroundButton}
                            />
                          </React.Fragment>
                        )}
                        {this.props.canShowHotspots && (
                          <ButtonHotspots
                            className={style.toggleLeftSidebarButton}
                            active={
                              this.props.hasHotspots && this.state.showHotspots
                            }
                            disabled={!this.props.hasHotspots}
                            onClick={this.toggleHotspots}
                          />
                        )}
                      </div>
                    }
                    right={
                      <div className={style.footerToolbarRight}>
                        <ZoomPercentageInput
                          zoomState={this.props.zoomState}
                          onChange={this.props.onChangeZoomState}
                          onZoomToFit={this.handleZoomToFit}
                        />
                        <div
                          className={classnames(
                            style.divider,
                            style.dividerRightToolbar
                          )}
                        />
                        <ButtonSidebar
                          className={style.toggleRightSidebarButton}
                          direction="right"
                          active={!this.state.collapsedRightSidebar}
                          onClick={() =>
                            this.setState((state) => ({
                              collapsedRightSidebar:
                                !state.collapsedRightSidebar,
                            }))
                          }
                        />
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
            <Sidebar
              contentClassName={style.sidebarMaxWidth}
              minWidth={SIDEBAR_MIN_WIDTH}
              resizable="layers-right"
              direction="right"
              collapsed={this.state.collapsedRightSidebar}
              wide
            >
              <Loaded loading={!layerDataset}>
                <LayerProperties
                  onToggleLayoutOverlay={this.handleToggleLayoutOverlay}
                  onToggleGridOverlay={this.handleToggleGridOverlay}
                  onToggleGuidesOverlay={this.handleToggleGuidesOverlay}
                  showLayoutOverlay={this.state.showLayoutOverlay}
                  showGridOverlay={this.state.showGridOverlay}
                  showGuidesOverlay={this.state.showGuidesOverlay}
                  isGridEnabled={this.hasGrid()}
                  isLayoutEnabled={this.hasLayout()}
                  isGuidesEnabled={this.hasGuides()}
                  params={this.props.params}
                  error={this.props.error}
                  isRootLayer={isRootLayer}
                  layerData={activeLayer}
                  layerType={idx(this, (_) => _.props.layer.type) || "layer"}
                  layers={layers}
                  layerChangeset={
                    layerChangesets && layers[this.props.selectedLayerKey]
                      ? layerChangesets[this.props.selectedLayerKey]
                      : undefined
                  }
                  canUseSymbolSourceDisplayName={
                    this.props.canUseSymbolSourceDisplayName
                  }
                  showChangeset={this.state.showChangeset}
                  onSelectLayer={this.handleSelectLayer}
                  symbolMasterLayerData={this.props.symbolMasterLayerData}
                  symbolMasterLayerDataIsLoading={
                    this.props.symbolMasterLayerDataIsLoading
                  }
                  symbolMasterLayerDataError={
                    this.props.symbolMasterLayerDataError
                  }
                  showGoToSymbolMaster={this.props.showGoToSymbolMaster}
                  isPublicShare={isPublicShare}
                  onToggleShowChangeset={this.onToggleShowChangeset}
                  showAssets={this.props.showAssets}
                  showPrototypes={this.props.showPrototypes}
                  canGenerateAssets={this.props.canGenerateAssets}
                  showDevelopment={this.props.showDevelopment}
                />
              </Loaded>
            </Sidebar>
          </div>
        </LayerBuildDisabled>
      </Loaded>
    );
  }

  handleTargetLayer = (layer: ?TLayerData) => {
    this.setState({ targetedLayer: layer });
  };

  handleSelectLayer = (layerKey: ?string) => {
    if (this.props.showGoToSymbolMaster) {
      this.props.loadSymbolMaster(layerKey);
    }
    this.props.onSelectLayer(layerKey);
  };

  handleLayerCanvasBlur = (event: SyntheticMouseEvent<>) => {
    this.handleSelectLayer(null);
  };

  handleKeyDown = (event: KeyboardEvent) => {
    if (eventInInput(event)) {
      return;
    }

    switch (event.keyCode) {
      case KeyCode.KEY_PERIOD: {
        if (modifierKeyPressed(event)) {
          this.handleToggleSidebars();
        }
        break;
      }
      case KeyCode.KEY_SPACE: {
        this.setState({
          hideLayerInspector: true,
        });
        break;
      }
      case KeyCode.KEY_Z: {
        this.setState((state) => ({
          hideLayerInspector: !state.hideLayerInspector,
        }));
        break;
      }
      case KeyCode.KEY_G: {
        if (event.ctrlKey && this.hasGrid()) {
          this.handleToggleGridOverlay();
        }
        break;
      }
      case KeyCode.KEY_L: {
        if (event.ctrlKey && this.hasLayout()) {
          this.handleToggleLayoutOverlay();
        }
        break;
      }
      case KeyCode.KEY_R: {
        if (event.ctrlKey && this.hasGuides()) {
          this.handleToggleGuidesOverlay();
        }
        break;
      }
      case KeyCode.KEY_C: {
        if (modifierKeyPressed(event)) {
          const selectedLayer = this.props.layers[this.props.selectedLayerKey];
          const selection = window.getSelection();
          const isNotSelectingText = !selection || selection.isCollapsed;

          if (
            selectedLayer &&
            selectedLayer.type === "text" &&
            isNotSelectingText
          ) {
            const clipboardAction = new ClipboardAction({
              action: "copy",
              text: selectedLayer.properties.textContent,
              container: window.document.body,
              emitter: STUB_CLIPBOARD_EMITTER,
            });

            clipboardAction.destroy();
          }
        }
        break;
      }
      default:
    }
  };

  handleKeyUp = (event: KeyboardEvent) => {
    if (eventInInput(event)) {
      return;
    }

    if (event.keyCode === KeyCode.KEY_SPACE) {
      this.setState({
        hideLayerInspector: false,
      });
    }
  };

  handleToggleSidebars = () => {
    this.setState((prevState) => {
      const collapsedSidebar =
        prevState.collapsedLeftSidebar || prevState.collapsedRightSidebar;

      return {
        collapsedLeftSidebar: !collapsedSidebar,
        collapsedRightSidebar: !collapsedSidebar,
      };
    });
  };

  handleZoomToFit = (zoomState: ZoomState) => {
    const zoomablePreview: ?ZoomablePreview = get(this, [
      "layerCanvas",
      "zoomablePreview",
    ]);

    if (!zoomablePreview) {
      return zoomState;
    }

    return {
      scale: zoomablePreview.scaleToFit,
      scaledToFit: true,
      offsetX: 0,
      offsetY: 0,
    };
  };

  toggleHotspots = () => {
    this.setState((state) => ({
      showHotspots: !state.showHotspots,
    }));
  };
}

export default connector(LayerBuild);
