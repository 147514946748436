// @flow
import { useSelector } from "react-redux";
import { canUseNewDefaultBranchName } from "core/selectors/features";

export default function useBranchDisplayName(
  branchId: ?string,
  originalBranchName: ?string
): {
  branchName: ?string,
  defaultBranchName: string,
  defaultBranchNameTitleCase: string,
} {
  return useSelector((state) => {
    // Assign the branch name if the branch is provided and it's not the default branch
    if (canUseNewDefaultBranchName(state)) {
      let branchName =
        branchId && branchId === "master" ? "Main" : originalBranchName;
      return {
        // title case as if we're using this to show the branch name, it's titled
        branchName: branchName,
        defaultBranchName: "main",
        defaultBranchNameTitleCase: "Main",
      };
    }
    return {
      branchName: originalBranchName,
      defaultBranchName: "master",
      defaultBranchNameTitleCase: "Master",
    };
  });
}
