// @flow
import * as React from "react";
import { findDOMNode } from "react-dom";
import { connect } from "react-redux";
import scrollIntoView from "smooth-scroll-into-view-if-needed";
import { isOnline } from "abstract-di/selectors";
import { trackEvent } from "core/actions/analytics";
import {
  updateComment,
  deleteComment,
  deletedComment,
  createComment,
} from "core/actions/comments";
import { toggleReactionForCurrentUser } from "core/actions/reactions";
import DialogForm from "core/components/DialogForm";
import { push } from "core/lib/location";
import { commitPath, branchPath, layerLocation } from "core/lib/routes";
import { reviewStatusColor, type ReviewStatus } from "core/models/review";
import {
  PinCommentRequest,
  UnpinCommentRequest,
  ResolveCommentRequest,
  UnresolveCommentRequest,
} from "core/requests/comments";
import { getUserForComment } from "core/selectors/comments";
import { getProjectPolicy } from "core/selectors/policies";
import { getProject } from "core/selectors/projects";
import { getReactions } from "core/selectors/reactions";
import type {
  Comment as TComment,
  PublicComment,
  User,
  PublicUser,
  Project,
  FormState,
  CommentForm,
  Dispatch,
  State,
} from "core/types";
import Validations from "core/validations";
import CommentMenu from "./CommentMenu";
import DefaultComment from "./DefaultComment";

const DEFAULT_VALUES = {};
const HIGHLIGHT_TIMEOUT = 2000;

type StateProps = {|
  isAuthor: boolean,
  canDelete: boolean,
  user: User | PublicUser,
  project: ?Project,
  canAddReactions: boolean,
  online: boolean,
  canShare: boolean,
  canPin: boolean,
  canResolve: boolean,
  isPinning: boolean,
  isUnpinning: boolean,
  isResolving: boolean,
|};

type DispatchProps = {
  updateComment: (FormState) => void,
  deleteComment: () => void,
  onCancel: () => void,
  onRetry: (TComment) => void,
  onReaction: (name: string) => void,
  onPinComment: (commentId: string) => void,
  onUnpinComment: (commentId: string) => void,
  onResolveComment: (commentId: string) => void,
  onUnresolveComment: (commentId: string) => void,
};

type OwnProps = {
  comment: TComment | PublicComment,
  currentUser: ?User,
  className?: string,
  context?: Object,
  disabled?: boolean,
  isSelected?: boolean,
  isAuthor: boolean,
  canDelete: boolean,
  reviewStatus?: ReviewStatus,
  isHighlighted?: boolean,
  selectedRef?: (ref: *) => void,
  highlightedRef?: (ref: *) => void,
  user: User | PublicUser,
  users: User[],
  onSelected?: (string) => void,
  onClickReply: () => void,
  onAddAnnotation?: (formId: string, attributes: CommentForm) => void,
  online: boolean,
  fileName: ?string,
  pageName: ?string,
  layerName: ?string,
  branchName: ?string,
  commitTitle: ?string,
  commentComponent: React.ComponentType<
    React.ElementConfig<typeof DefaultComment>,
  >,
  canAddReactions: boolean,
  preview?: React.Node,
  pinCommentsEnabled?: boolean,
  resolveCommentsEnabled?: boolean,
  forceHighlight?: boolean,
  displayOnlyPinnedOptions?: boolean,
  isResolved?: boolean,
  onPinnedCommentClick?: () => void,
  tintBackgroundOnHover?: boolean,
  qaSelector?: string,
};

type Props = OwnProps & StateProps & DispatchProps;

type ComponentState = {
  form: ?FormState,
  annotationDialogOpen: boolean,
  deleteDialogOpen: boolean,
  showHighlighted: boolean,
};

class CommentContainer extends React.PureComponent<Props, ComponentState> {
  form: ?HTMLFormElement;
  comment: ?HTMLDivElement;
  removeHighlightTimeout: ?TimeoutID;

  static defaultProps = {
    commentComponent: DefaultComment,
  };

  state = {
    form: null,
    annotationDialogOpen: false,
    deleteDialogOpen: false,
    showHighlighted: false,
  };

  componentDidMount() {
    this.handleHighlight();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.isHighlighted !== this.props.isHighlighted) {
      this.handleHighlight();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.removeHighlightTimeout);
  }

  handleHighlight = () => {
    if (this.state.showHighlighted || !this.props.isHighlighted) {
      return;
    }

    this.setState({ showHighlighted: true }, () => {
      clearTimeout(this.removeHighlightTimeout);

      scrollIntoView(this.comment, {
        duration: 500,
        block: "center",
        scrollMode: "if-needed",
      });

      this.removeHighlightTimeout = setTimeout(() => {
        this.setState({ showHighlighted: false });
      }, HIGHLIGHT_TIMEOUT);
    });
  };

  setCommentRef = (ref: ?HTMLDivElement) => {
    this.comment = ref;
  };

  handleAddAnnotation = (formId: string, attributes: CommentForm) => {
    const { onAddAnnotation } = this.props;
    if (onAddAnnotation) {
      onAddAnnotation(formId, attributes);
    }
  };

  handleRemoveAnnotation = () => {
    this.setState((prev) => ({
      form: { body: prev.form ? prev.form.body : "" },
      annotationDialogOpen: false,
    }));
  };

  handleClick = (event: SyntheticEvent<>) => {
    if (!this.props.comment) {
      return;
    }
    const { projectId, branchId, commitSha, fileId, layerId, id } =
      this.props.comment;

    if (layerId) {
      return push(
        layerLocation(projectId, branchId, commitSha, fileId, layerId, {
          commentId: id,
        })
      );
    }

    if (commitSha) {
      return push(commitPath(projectId, branchId, commitSha, id));
    }

    if (branchId) {
      return push(branchPath(projectId, branchId));
    }
  };

  beginEdit = () => {
    const { body, annotation } = this.props.comment;

    this.setState(
      {
        form: {
          body,
          annotation: annotation
            ? { ...annotation, number: annotation.number || 1 }
            : undefined,
        },
      },
      this.scrollToForm
    );
  };

  clearEditForm = () => {
    this.setState({
      form: null,
    });
  };

  formRef = (ref: ?HTMLFormElement) => (this.form = ref);

  scrollToForm = () => {
    const form = findDOMNode(this.form);
    if (form) {
      scrollIntoView(form, {
        duration: 200,
        block: "nearest",
        scrollMode: "if-needed",
      });
    }
  };

  handleUpdate = (id: string, form: CommentForm) => {
    this.props.updateComment({ id, ...form });
    this.clearEditForm();
    if (this.props.onSelected && !form.annotation) {
      this.props.onSelected("");
    }
  };

  handleDelete = () => {
    this.props.deleteComment();
    this.handleHideConfirmDelete();
  };

  handleRetry = () => {
    this.props.onRetry(this.props.comment);
  };

  handleClickReply = (event?: SyntheticEvent<>) => {
    if (event) {
      event.stopPropagation();
    }
    this.props.onClickReply();
  };

  handleShowConfirmAnnotation = () => {
    this.setState({ annotationDialogOpen: true });
  };

  handleHideConfirmAnnotation = () => {
    this.setState({ annotationDialogOpen: false });
  };

  handleShowConfirmDelete = () => {
    this.setState({ deleteDialogOpen: true });
  };

  handleHideConfirmDelete = () => {
    this.setState({ deleteDialogOpen: false });
  };

  handleContextMenu = (showMenu: *) => {
    return this.state.form ? undefined : showMenu;
  };

  render() {
    const {
      className,
      comment,
      project,
      online,
      context,
      isAuthor,
      canDelete,
      isHighlighted,
      isSelected,
      reviewStatus,
      onSelected,
      onCancel,
      user,
      users,
      branchName,
      layerName,
      pageName,
      fileName,
      commitTitle,
      currentUser,
      disabled,
      commentComponent,
      canAddReactions,
      canShare,
      preview,
    } = this.props;

    const { form } = this.state;
    const CommentComponent = commentComponent;

    return (
      <CommentMenu
        onEdit={this.beginEdit}
        onDelete={reviewStatus ? undefined : this.handleShowConfirmDelete}
        canUpdate={isAuthor}
        canDelete={canDelete}
        project={project}
        comment={comment}
        canShare={canShare}
        onPinComment={this.props.onPinComment}
        onUnpinComment={this.props.onUnpinComment}
        canPin={this.props.canPin && this.props.pinCommentsEnabled}
        canResolve={this.props.resolveCommentsEnabled && this.props.canResolve}
        onResolveComment={this.props.onResolveComment}
        onUnresolveComment={this.props.onUnresolveComment}
        isResolved={this.props.isResolved}
        isPinned={!!this.props.comment.pinnedAt}
      >
        {(showMenu, menuRef) => (
          <div
            className={className}
            ref={this.setCommentRef}
            data-qa={this.props.qaSelector}
          >
            <CommentComponent
              {...comment}
              menuRef={menuRef}
              formRef={this.formRef}
              innerRef={
                isHighlighted
                  ? this.props.highlightedRef
                  : isSelected
                  ? this.props.selectedRef
                  : undefined
              }
              disabled={disabled}
              branchName={branchName}
              commitTitle={commitTitle}
              fileName={fileName}
              pageName={pageName}
              layerName={layerName}
              user={user}
              users={users}
              currentUser={currentUser}
              context={context}
              formState={form || DEFAULT_VALUES}
              isEditing={!!form}
              isAuthor={isAuthor}
              isHighlighted={this.state.showHighlighted}
              forceHighlight={this.props.forceHighlight}
              isSelected={isSelected}
              canAddReactions={canAddReactions}
              reviewStatus={reviewStatus}
              onClick={this.handleClick}
              onClickReply={this.handleClickReply}
              onCancel={onCancel}
              onRetry={this.handleRetry}
              onSelected={onSelected}
              onChange={this.handleAddAnnotation}
              onRequestAnnotationRemoval={this.handleShowConfirmAnnotation}
              onResetEdit={this.clearEditForm}
              onUpdate={this.handleUpdate}
              onReaction={this.props.onReaction}
              onContextMenu={this.handleContextMenu(showMenu)}
              avatarColor={
                reviewStatus ? reviewStatusColor(reviewStatus) : undefined
              }
              online={online}
              preview={preview}
              onPinComment={this.props.onPinComment}
              onUnpinComment={this.props.onUnpinComment}
              canPin={this.props.canPin && this.props.pinCommentsEnabled}
              displayOnlyPinnedOptions={this.props.displayOnlyPinnedOptions}
              resolveCommentsEnabled={this.props.resolveCommentsEnabled}
              canResolve={this.props.canResolve}
              onResolveComment={this.props.onResolveComment}
              isResolved={this.props.isResolved}
              comment={comment}
              onPinnedCommentClick={this.props.onPinnedCommentClick}
              tintBackgroundOnHover={this.props.tintBackgroundOnHover}
              isPinning={this.props.isPinning}
              isUnpinning={this.props.isUnpinning}
              isResolving={this.props.isResolving}
            />
            <DialogForm
              dangerous
              isOpen={this.state.annotationDialogOpen}
              onClose={this.handleHideConfirmAnnotation}
              onRequestClose={this.handleHideConfirmAnnotation}
              title="Remove Annotation"
              onSubmit={this.handleRemoveAnnotation}
              primaryButton="Remove"
            >
              {() => <p>Are you sure you want to remove the annotation?</p>}
            </DialogForm>
            <DialogForm
              dangerous
              isOpen={this.state.deleteDialogOpen}
              onClose={this.handleHideConfirmDelete}
              onRequestClose={this.handleHideConfirmDelete}
              title="Delete Comment"
              onSubmit={this.handleDelete}
              primaryButton="Delete"
            >
              {() => <p>Are you sure you want to delete this comment?</p>}
            </DialogForm>
          </div>
        )}
      </CommentMenu>
    );
  }
}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { comment, currentUser } = props;
  const project = getProject(state, { projectId: comment.projectId });
  const user = getUserForComment(state, { id: comment.id });
  const projectPolicy = getProjectPolicy(state, {
    projectId: comment.projectId,
  });

  const reactions = getReactions(state, { commentId: comment.id });
  const withinReactionLimit = reactions
    ? reactions.length < Validations.maxEmojiPerComment
    : true;
  const canAddReactions =
    currentUser !== undefined &&
    !comment.pending &&
    !comment.deletedAt &&
    withinReactionLimit;

  const isAuthor = currentUser ? comment.userId === currentUser.id : false;

  return {
    isAuthor,
    canDelete: projectPolicy.destroy || isAuthor,
    user,
    project,
    canAddReactions,
    online: isOnline(state),
    canShare: projectPolicy.share,
    canPin: projectPolicy.pinComments,
    canResolve: projectPolicy.resolveComments,
    isPinning: PinCommentRequest.isLoadingStrict(state, { id: comment.id }),
    isUnpinning: UnpinCommentRequest.isLoadingStrict(state, { id: comment.id }),
    isResolving: ResolveCommentRequest.isLoadingStrict(state, {
      id: comment.id,
    }),
  };
}

function mapDispatchToProps(dispatch: Dispatch, props: OwnProps) {
  const { projectId, parentId, id, layerId, branchId } = props.comment;

  const trackingPayload = {
    commentId: id,
    layerId,
    projectId,
    branchId,
  };

  return {
    updateComment: (form: FormState) => {
      const { body, annotation } = form;
      dispatch(updateComment({ id, body, annotation: annotation || null }));
    },
    deleteComment: () => dispatch(deleteComment({ id, parentId, projectId })),
    onRetry: (comment) => dispatch(createComment(comment)),
    onCancel: () => dispatch(deletedComment({ id, parentId })),
    onReaction: (name: string) =>
      dispatch(
        toggleReactionForCurrentUser({ name, commentId: id, projectId })
      ),
    onPinComment: (commentId: string) => {
      dispatch(PinCommentRequest.perform({ params: { id: commentId } }));
      dispatch(trackEvent("COMMENT_PINNED", trackingPayload));
    },
    onUnpinComment: (commentId: string) => {
      dispatch(UnpinCommentRequest.perform({ params: { id: commentId } }));
      dispatch(trackEvent("COMMENT_UNPINNED", trackingPayload));
    },
    onResolveComment: (commentId: string) => {
      dispatch(
        ResolveCommentRequest.perform({
          params: { id: commentId },
        })
      );

      dispatch(trackEvent("COMMENT_RESOLVED", trackingPayload));
    },
    onUnresolveComment: (commentId: string) => {
      dispatch(
        UnresolveCommentRequest.perform({
          params: { id: commentId },
        })
      );

      dispatch(trackEvent("COMMENT_UNRESOLVED", trackingPayload));
    },
  };
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default connect(mapStateToProps, mapDispatchToProps)(CommentContainer);
