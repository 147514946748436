// @flow
import createCachedSelector from "@elasticprojects/re-reselect";
import empty from "empty";
import {
  find,
  forEach,
  groupBy,
  reduce,
  size,
  isEmpty,
  uniqBy,
  clone,
} from "lodash";
import naturalSortBy from "core/lib/naturalSortBy";
import * as Change from "core/models/change";
import * as Changeset from "core/models/changeset";
import * as File from "core/models/file";
import * as Layer from "core/models/layer";
import * as Page from "core/models/page";
import * as SharedItem from "core/models/sharedItem";
import type {
  Changeset as TChangeset,
  ChangesetStatus,
  ChangesetChange,
  ChangeStatusCounts,
  ChangesetChangeCounts,
  ChangesetFileChange,
  ChangesetLayerChange,
  ChangesetPageChange,
  ChangesetIdentifier,
  NonVisualChange,
  Layer as TLayer,
  LayerSetItem,
  Page as TPage,
  File as TFile,
  SharedItem as TSharedItem,
  FilePreviews,
  State,
} from "core/types";
import { getEntity, getRawEntities } from "./entities";
import { getFileId } from "./helpers";

type ChangesetParams = $ReadOnly<{
  status?: string,
  ...ChangesetIdentifier,
}>;

function uniqueChangesetId(params: ChangesetParams): string {
  return Changeset.uniqueId({
    projectId: params.projectId,
    branchId: params.branchId,
    sha: params.sha,
  });
}

function cacheByChangesetId(state, params: ChangesetParams) {
  return uniqueChangesetId(params);
}

function cacheByChangesetIdAndStatus(state, params: ChangesetParams) {
  const changesetId = uniqueChangesetId(params);
  if (!params.status) {
    return changesetId;
  }
  return `${changesetId}-${params.status}`;
}

export function getChangeset(
  state: State,
  params: ChangesetParams
): ?TChangeset {
  return getEntity(state, "changesets", uniqueChangesetId(params));
}

function getFiles(state) {
  return getRawEntities(state, "files");
}

function getPages(state) {
  return getRawEntities(state, "pages");
}

function getLayers(state) {
  return getRawEntities(state, "layers");
}

function getSharedData(state) {
  return getRawEntities(state, "sharedData");
}

export const getFileChangesForChangeset: (
  State,
  ChangesetParams
) => { [fileId: string]: ChangesetChange } = createCachedSelector(
  getChangeset,
  (changeset) => {
    if (!changeset) {
      return empty.object;
    }
    return reduce(
      changeset.changes,
      (memo, change) => {
        if (change.objectType !== "file" || !Change.hasStatus(change)) {
          return memo;
        }
        const currentChange = memo[change.fileId];
        if (!currentChange) {
          memo[change.fileId] = change;
          return memo;
        }
        if (Change.isRemoved(currentChange)) {
          // status="removed" takes priority over others
          return memo;
        }
        if (Change.isAdded(currentChange) && !Change.isRemoved(change)) {
          // status="added" takes priority over remaining statuses
          return memo;
        }
        if (
          Change.isEdited(currentChange) &&
          !Change.isRemoved(change) &&
          !Change.isAdded(change)
        ) {
          // status="edited" takes priority over remaining statuses
          return memo;
        }
        memo[change.fileId] = change;
        return memo;
      },
      {}
    );
  }
)(cacheByChangesetId);

export const getChangesetFiles: (State, ChangesetParams) => TFile[] =
  createCachedSelector(
    getChangeset,
    getFiles,
    (changeset: ?TChangeset, files: { [uniqueId: string]: TFile }) => {
      if (!changeset) {
        return empty.array;
      }

      const { projectId, sha, compareToSha, changes } = changeset;
      const changedFiles = reduce(
        changes,
        (memo, change) => {
          if (change.objectType !== "file") {
            return memo;
          }
          const file =
            files[
              File.uniqueId({
                projectId,
                sha: Change.isRemoved(change) ? compareToSha : sha,
                fileId: change.fileId,
              })
            ];
          if (file) {
            memo.push(file);
          }
          return memo;
        },
        []
      );
      return naturalSortBy(changedFiles, "name");
    }
  )(cacheByChangesetId);

export const getChangesetPages: (
  State,
  ChangesetParams
) => {
  [pageId: string]: TPage,
} = createCachedSelector(
  getChangeset,
  getPages,
  (changeset: ?TChangeset, pages) => {
    if (!changeset) {
      return empty.object;
    }

    const { projectId, sha, compareToSha, changes } = changeset;
    return changes.reduce((memo, change) => {
      if (change.objectType !== "layer" && change.objectType !== "page") {
        return memo;
      }
      const uniquePageId = Page.uniqueId({
        projectId,
        sha: Change.isRemoved(change) ? compareToSha : sha,
        fileId: change.fileId,
        pageId: change.pageId,
      });
      const page = pages[uniquePageId];
      if (page) {
        memo[uniquePageId] = page;
      }
      return memo;
    }, {});
  }
)(cacheByChangesetId);

export const getChangesetLayers: (
  State,
  ChangesetParams
) => {
  [layerId: string]: TLayer,
} = createCachedSelector(
  getChangeset,
  getLayers,
  (changeset: ?TChangeset, layers) => {
    if (!changeset) {
      return empty.object;
    }

    const { projectId, sha, compareToSha, changes } = changeset;
    return changes.reduce((memo, change) => {
      if (change.objectType !== "layer" || !change.objectId) {
        return memo;
      }
      const uniqueLayerId = Layer.uniqueId({
        projectId,
        sha: Change.isRemoved(change) ? compareToSha : sha,
        fileId: change.fileId,
        layerId: change.objectId,
      });
      const layer = layers[uniqueLayerId];
      if (layer) {
        memo[uniqueLayerId] = layer;
      }
      return memo;
    }, {});
  }
)(cacheByChangesetId);

export const getChangesetSharedData: (
  State,
  ChangesetParams
) => {
  [objectId: string]: TSharedItem,
} = createCachedSelector(
  getChangeset,
  getSharedData,
  (changeset: ?TChangeset, sharedData) => {
    if (!changeset) {
      return empty.object;
    }

    const { projectId, sha, compareToSha, changes } = changeset;
    return reduce(
      changes,
      (memo, change) => {
        if (change.objectType !== "shared-item" || !change.objectId) {
          return memo;
        }
        const uniqueItemId = SharedItem.uniqueId({
          projectId,
          sha: Change.isRemoved(change) ? compareToSha : sha,
          fileId: change.fileId,
          objectId: change.objectId,
        });
        const sharedItem = sharedData[uniqueItemId];
        if (sharedItem) {
          memo[uniqueItemId] = sharedItem;
        }
        return memo;
      },
      {}
    );
  }
)(cacheByChangesetId);

function getFileChangeCount(change: ChangesetFileChange): number {
  let count = 0;

  if (Change.isAdded(change) || Change.isRemoved(change)) {
    count = 1;
  } else if (Change.isEdited(change)) {
    count = size(change.meta);
  }

  return count;
}

function getPageChangeCount(change: ChangesetPageChange): number {
  let count = 0;

  if (Change.isAdded(change) || Change.isRemoved(change)) {
    count = 1;
  } else if (Change.isEdited(change)) {
    count = size(change.meta);
  }

  return count;
}

function getLayerChangeCount(
  changeset: TChangeset,
  change: ChangesetLayerChange,
  layers: { [objectId: string]: TLayer }
): number {
  let count = 0;

  const { projectId, sha, compareToSha } = changeset;
  const layer =
    layers[
      Layer.uniqueId({
        projectId,
        sha: Change.isRemoved(change) ? compareToSha : sha,
        fileId: change.fileId,
        layerId: change.objectId,
      })
    ];

  if (!layer) {
    return count;
  }

  if (
    Change.isAdded(change) ||
    Change.isRemoved(change) ||
    Change.isUpdated(change) ||
    (Change.isEdited(change) && change.hasPreview === true)
  ) {
    count++;
  }

  if (Change.isEdited(change)) {
    count += size(change.meta);
  }

  return count;
}

export function getChangeCount(
  changeset: TChangeset,
  change: ChangesetChange,
  layers: { [objectId: string]: TLayer }
): number {
  switch (change.objectType) {
    case "file":
      return getFileChangeCount(change);
    case "page":
      return getPageChangeCount(change);
    case "layer":
      return getLayerChangeCount(changeset, change, layers);
    default:
      return 0;
  }
}

export const getChangesetChangeCounts: (
  State,
  ChangesetParams
) => ChangesetChangeCounts = createCachedSelector(
  getChangeset,
  getChangesetLayers,
  (changeset: ?TChangeset, layers: { [objectId: string]: TLayer }) => {
    if (!changeset) {
      return empty.object;
    }

    const groupedChanges = groupBy(changeset.changes, "fileId");
    return reduce(
      groupedChanges,
      (memo, changes, fileId) => {
        memo[fileId] = changes.reduce((memo, change) => {
          // flow requires this extra check
          if (!changeset) {
            return memo;
          }

          return memo + getChangeCount(changeset, change, layers);
        }, 0);

        return memo;
      },
      {}
    );
  }
)(cacheByChangesetId);

const getChangeStatus = (state, props) => props.status;

export const getNonVisualObjectsForChange = (
  change: ChangesetChange,
  changeset: TChangeset,
  files: { [fileId: string]: TFile },
  pages: { [objectId: string]: TPage },
  layers: { [objectId: string]: TLayer },
  sharedData: { [objectId: string]: TSharedItem },
  isLibrary?: boolean
): { type: string, items: NonVisualChange[] } => {
  const { projectId, compareToSha, sha } = changeset;
  const changeSha = Change.isRemoved(change) ? compareToSha : sha;
  const changes = { type: "nonVisual", items: [] };

  let object;
  if (change.objectType === "file") {
    object =
      files[
        File.uniqueId({
          projectId,
          fileId: change.fileId,
          sha: changeSha,
        })
      ];
  } else if (change.objectType === "page") {
    object =
      pages[
        Page.uniqueId({
          projectId,
          fileId: change.fileId,
          pageId: change.objectId || change.pageId || "",
          sha: changeSha,
        })
      ];
  } else if (change.objectType === "shared-item") {
    object =
      sharedData[
        SharedItem.uniqueId({
          projectId,
          fileId: change.fileId,
          objectId: change.objectId || "",
          sha: changeSha,
        })
      ];
  } else if (
    change.objectType === "layer" &&
    Change.isEdited(change) &&
    change.meta &&
    !isEmpty(change.meta)
  ) {
    object =
      layers[
        Layer.uniqueId({
          projectId,
          fileId: change.fileId,
          layerId: change.objectId || "",
          sha: changeSha,
        })
      ];
  }

  if (!object) {
    return changes;
  }

  if (
    change.objectType === "shared-item" &&
    Object.prototype.hasOwnProperty.call(File.contentTypes, object.type)
  ) {
    changes.type = object.type;
  }

  const base = {
    object,
    change,
    type: changes.type,
    meta: {},
    fromLibrary: isLibrary,
  };

  if (change.meta && !isEmpty(change.meta)) {
    if (change.meta["meta.x"] || change.meta["meta.y"]) {
      changes.items.push({
        ...base,
        meta: { x: change.meta["meta.x"], y: change.meta["meta.y"] },
      });
    }

    if (change.meta["meta.height"] || change.meta["meta.width"]) {
      changes.items.push({
        ...base,
        meta: {
          height: change.meta["meta.height"],
          width: change.meta["meta.width"],
        },
      });
    }

    if (change.meta.name) {
      changes.items.push({
        ...base,
        meta: { name: change.meta.name },
      });
    }

    const version = change.meta.applicationVersion || change.meta.appVersion;
    if (version) {
      changes.items.push({
        ...base,
        meta: { applicationVersion: version },
      });
    }

    if (change.meta.colorSpace) {
      changes.items.push({
        ...base,
        meta: { colorSpace: change.meta.colorSpace },
      });
    }
  }

  const metaCount = Object.keys(change.meta).length;
  if (
    (Change.isUpdated(change) || Change.isEdited(change)) &&
    (metaCount < 1 ||
      (metaCount === 1 && (change.meta.appFileVersion || change.meta.version)))
  ) {
    // Do not display items with an empty meta
    // Do not display file version changes on non-files
    return changes;
  }

  if (
    !changes.items.length &&
    change.objectType !== "file" &&
    Change.hasStatus(change)
  ) {
    changes.items.push(base);
  }

  return changes;
};

export const sortNonVisualItems = (
  items: NonVisualChange[]
): NonVisualChange[] => {
  return naturalSortBy(
    uniqBy(items, ({ change, object, meta }) => {
      // Only show one item per combination of status, name, library and type
      // of property change
      return (
        Change.status(change.status) +
        (object.name || "") +
        (typeof object.libraryId === "string" ? object.libraryId : "") +
        (meta ? Object.keys(meta).join("") : "")
      );
    }),
    (item) => item.object.name
  );
};

export const getPreviewsByFileForChangeset: (
  State,
  ChangesetParams
) => FilePreviews = createCachedSelector(
  getChangeset,
  getChangeStatus,
  getFiles,
  getChangesetPages,
  getChangesetLayers,
  getChangesetSharedData,
  (
    changeset: ?TChangeset,
    status?: string,
    files: { [fileId: string]: TFile },
    pages: { [objectId: string]: TPage },
    layers: { [objectId: string]: TLayer },
    sharedData: { [objectId: string]: TSharedItem }
  ) => {
    if (!changeset) {
      return empty.object;
    }

    const { projectId, sha, compareToSha } = changeset;

    return changeset.changes.reduce((memo, change) => {
      let isLibrary = false;
      let changeStatus = change.status;
      if (change.objectType === "layer" || change.objectType === "page") {
        const uniquePageId = Page.uniqueId({
          projectId,
          sha,
          fileId: change.fileId,
          pageId: change.pageId || "",
        });
        const page = pages[uniquePageId];
        isLibrary = Page.isLibrary(page);

        if (status) {
          if (Change.isAdded(status) && !Change.isAdded(change)) {
            return memo;
          }
          if (Change.isRemoved(status) && !Change.isRemoved(change)) {
            return memo;
          }
        }

        if (Change.isEdited(change) && isLibrary) {
          changeStatus = "edited-indirectly";
        }
        const isValidStatus =
          !status || Change.status(status) === Change.status(changeStatus);

        if (
          change.objectType === "layer" &&
          change.hasPreview &&
          isValidStatus
        ) {
          const layer =
            layers[
              Layer.uniqueId({
                projectId,
                sha: Change.isRemoved(change) ? compareToSha : sha,
                fileId: change.fileId,
                layerId: change.objectId,
              })
            ];

          if (layer) {
            if (!memo[change.fileId]) {
              memo[change.fileId] = {};
            }
            const sectionId = isLibrary ? "symbol" : uniquePageId;
            const sectionLayers = memo[change.fileId][sectionId] || [];
            sectionLayers.push(layer);
            memo[change.fileId][sectionId] = sectionLayers;
          }
        }
      }

      const nonVisuals = getNonVisualObjectsForChange(
        change,
        changeset,
        files,
        pages,
        layers,
        sharedData,
        isLibrary
      );
      if (nonVisuals.items.length) {
        if (!memo[change.fileId]) {
          memo[change.fileId] = {};
        }
        const items = memo[change.fileId][nonVisuals.type] || [];
        nonVisuals.items.forEach((item) => {
          const isMovedChange = !!(item.meta.x || item.meta.y);
          const c = clone(change);
          c.status = isMovedChange ? "edited" : changeStatus;

          if (!status || Change.status(c) === Change.status(status)) {
            item.change = c;
            items.push(item);
          }
        });
        memo[change.fileId][nonVisuals.type] = items;
      }

      return memo;
    }, {});
  }
)(cacheByChangesetIdAndStatus);

export const getNonVisualChangesForChangeset: (
  State,
  ChangesetParams
) => NonVisualChange[] = createCachedSelector(
  getPreviewsByFileForChangeset,
  (previews) => {
    const changes = [];
    forEach(previews, (previewsByFile, fileId) => {
      forEach(previewsByFile, (previewsBySection, sectionId) => {
        if (
          File.contentTypes[sectionId] &&
          previewsBySection[0] &&
          previewsBySection[0].change
        ) {
          changes.push(...previewsBySection);
        }
      });
    });
    return changes;
  }
)(cacheByChangesetId);

export const getChangesetStatusCounts: (
  State,
  ChangesetParams
) => ?ChangeStatusCounts = createCachedSelector(
  getChangeset,
  getChangesetPages,
  getNonVisualChangesForChangeset,
  (changeset, pages, nonVisuals) => {
    if (!changeset) {
      return;
    }
    let counts = reduce(
      changeset.changes,
      (memo, change) => {
        if (!Change.hasStatus(change)) {
          return memo;
        }
        if (change.objectType !== "layer" || !change.hasPreview) {
          return memo;
        }

        let status = Change.status(change);
        if (Change.isEdited(change)) {
          const page =
            pages[
              Page.uniqueId({
                projectId: changeset.projectId,
                sha: changeset.sha,
                fileId: change.fileId,
                pageId: change.pageId || "",
              })
            ];
          if (Page.isLibrary(page)) {
            status = "updated";
          }
        }

        memo[status]++;
        return memo;
      },
      ({ added: 0, edited: 0, removed: 0, updated: 0 }: ChangeStatusCounts)
    );

    nonVisuals.forEach((item) => {
      const status = Change.status(item.change);
      counts[status] = counts[status] + 1;
    });

    return counts;
  }
)(cacheByChangesetId);

export const getChangesetFileChanged: (
  State,
  ChangesetParams & { fileId: string }
) => boolean = createCachedSelector(
  getChangeset,
  getFileId,
  (changeset, fileId) => {
    if (!changeset) {
      return false;
    }
    return !!find(changeset.changes, { type: "file", fileId });
  }
)(cacheByChangesetId);

export const getLayerStatusesForChangeset: (
  State,
  ChangesetParams
) => {
  [uniqueLayerId: string]: ChangesetStatus,
} = createCachedSelector(getChangeset, (changeset: ?TChangeset) => {
  if (!changeset) {
    return empty.object;
  }
  const { projectId, sha, compareToSha } = changeset;

  return changeset.changes.reduce((memo, change) => {
    if (!change.objectId) {
      return memo;
    }

    const key = Layer.uniqueId({
      projectId,
      sha: Change.isRemoved(change) ? compareToSha : sha,
      fileId: change.fileId,
      layerId: change.objectId,
    });
    memo[key] = change.status;
    return memo;
  }, {});
})(cacheByChangesetId);

const generateLayerSetFromLayers = (
  changeset: TChangeset,
  layers: TLayer[],
  statuses: { [uniqueLayerId: string]: ChangesetStatus }
): LayerSetItem[] => {
  const { sha } = changeset;
  return layers.reduce((layerSet, layer) => {
    const status = statuses[Layer.uniqueId(layer)];
    if (!Change.isRemoved(status)) {
      layerSet.push({
        commitSha: sha,
        pageId: layer.pageId,
        fileId: layer.fileId,
        layerId: layer.id,
      });
    }
    return layerSet;
  }, []);
};

export const getLayerSetForChangeset: (
  State,
  ChangesetParams
) => LayerSetItem[] = createCachedSelector(
  [getChangeset, getLayerStatusesForChangeset, getPreviewsByFileForChangeset],
  (changeset, statuses, previewsByFile) => {
    if (!changeset) {
      return empty.array;
    }

    const layerSet = [];

    forEach(previewsByFile, (previewsByPage, fileId) => {
      forEach(previewsByPage, (layers, pageId) => {
        if (!layers || File.contentTypes[pageId]) {
          return;
        }
        layerSet.push(
          ...generateLayerSetFromLayers(changeset, layers, statuses)
        );
      });

      if (previewsByPage.symbol) {
        layerSet.push(
          ...generateLayerSetFromLayers(
            changeset,
            previewsByPage.symbol,
            statuses
          )
        );
      }
    });

    return layerSet;
  }
)(cacheByChangesetIdAndStatus);
