// @flow
import subDays from "date-fns/sub_days";
import empty from "empty";
import { map, deburr, orderBy, values } from "lodash";
import { createSelector } from "reselect";
import { getCurrentUserId } from "abstract-di/selectors";
import matchString from "core/lib/matchString";
import { isActive, isStale, isDeleted, isMaster } from "core/models/branch";
import { getBranchEntities } from "core/selectors/branches";
import { canUseNewDefaultBranchName } from "core/selectors/features";
import {
  maybeGetOrganizationId,
  maybeGetProjectId,
} from "core/selectors/helpers";
import { getOrganizations } from "core/selectors/organizations";
import { getProjectEntities } from "core/selectors/projects";
import type {
  QuickJumpResult,
  Organization,
  Project as TProject,
  Branch,
  State,
} from "core/types";

type DescribedEntity =
  | {| type: "branch", entity: Branch |}
  | {| type: "organization", entity: Organization |}
  | {| type: "project", entity: TProject |};

function isOrganization(item: DescribedEntity): %checks {
  return item.type === "organization";
}

function isProject(item: DescribedEntity): %checks {
  return item.type === "project";
}

function isBranch(item: DescribedEntity): %checks {
  return item.type === "project";
}

export function getQuickJumpOpen(state: State): boolean {
  return state.quickJump.open;
}

export function getQuickJumpSearchTerm(state: State): string {
  return state.quickJump.searchTerm;
}

export function getIsQuickJumpShowingKeyboardShortcutTip(
  state: State
): boolean {
  return state.quickJump.showShortcutTip;
}

export const getQuickJumpResults: (
  state: State,
  props: {
    organizationId?: string,
    projectId?: string,
  }
) => QuickJumpResult[] = createSelector(
  getQuickJumpSearchTerm,
  getOrganizations,
  getProjectEntities,
  getBranchEntities,
  maybeGetOrganizationId,
  maybeGetProjectId,
  getCurrentUserId,
  canUseNewDefaultBranchName,
  (
    term,
    organizationEntities,
    projectEntities,
    branchEntities,
    organizationId,
    projectId,
    currentUserId,
    masterToMain
  ) => {
    if (!term) {
      return empty.array;
    }

    let results = [];
    const normalizedTerm = deburr(term.toLowerCase().trim());

    // To add another factor that is used to rank quick jump results add a
    // new key to the rankings object below with an associated weight to adjust
    // how much impact it has on rankings. If the `calc` method returns a
    // number this will be multiplied by the weight.
    const rankings = {
      userIsCreator: {
        weight: 1,
        calc: ({ entity }) => entity.userId && entity.userId === currentUserId,
      },
      userIsActiveWithin: {
        weight: 2,
        calc: ({ entity }) =>
          !!(
            entity.activeUsers &&
            map(entity.activeUsers, (u) => u.id).includes(currentUserId)
          ),
      },
      isActive: {
        weight: 1,
        calc: (item) => {
          if (isOrganization(item)) {
            return 1;
          }
          if (isProject(item)) {
            return !item.entity.archivedAt;
          }
          if (isBranch(item)) {
            if (isActive(item.entity)) {
              return isStale(item.entity) ? 0.5 : false;
            }
            return false;
          }
          return false;
        },
      },
      contextCurrentOrganization: {
        weight: 1,
        calc: ({ entity }) => {
          return (
            !!organizationId &&
            entity.organizationId &&
            entity.organizationId === organizationId
          );
        },
      },
      contextCurrentProject: {
        weight: 1,
        calc: ({ entity }) =>
          !!projectId && entity.projectId && entity.projectId === projectId,
      },
      typeOrganization: {
        weight: 5,
        calc: isOrganization,
      },
      typeProject: {
        weight: 1,
        calc: isProject,
      },
      namePrefixMatch: {
        weight: 1,
        calc: ({ entity }) => {
          return deburr(entity.name).toLowerCase().startsWith(normalizedTerm);
        },
      },
      updatedDecay1d: {
        weight: 2,
        calc: ({ entity }) =>
          !!(
            entity.updatedAt &&
            new Date(entity.updatedAt) > subDays(new Date(), 1)
          ),
      },
      updatedDecay7d: {
        weight: 1,
        calc: ({ entity }) =>
          !!(
            entity.updatedAt &&
            new Date(entity.updatedAt) > subDays(new Date(), 7)
          ),
      },
      updatedDecay30d: {
        weight: 0.5,
        calc: ({ entity }) =>
          !!(
            entity.updatedAt &&
            new Date(entity.updatedAt) > subDays(new Date(), 30)
          ),
      },
    };

    // for a single search result, run through all of the ranking calculations,
    // find which match, and calculate overall ranking score
    function calculateRanking(item) {
      let total = 0;
      let describe = [];

      for (const id of Object.keys(rankings)) {
        const rankingItem = rankings[id];
        const rankingResult = rankingItem.calc(item);
        let score;

        if (typeof rankingResult === "number") {
          score = rankingResult * rankingItem.weight;
        } else if (rankingResult) {
          score = rankingItem.weight;
        }

        if (score) {
          total += score;
          describe.push({
            id,
            score,
            weight: rankingItem.weight,
          });
        }
      }

      return {
        score: total,
        describe,
      };
    }

    // organizations
    const organizations: Organization[] = values(organizationEntities);

    results = results.concat(
      organizations
        .filter((organization) =>
          matchString(organization.name, normalizedTerm)
        )
        .map((organization) => ({
          ...calculateRanking({ type: "organization", entity: organization }),
          descriptor: {
            organizationId: organization.id,
          },
        }))
    );

    // projects
    const projects: TProject[] = values(projectEntities);

    results = results.concat(
      projects
        .filter((project) => true)
        .filter((project) => matchString(project.name, normalizedTerm))
        .map((project) => ({
          ...calculateRanking({ type: "project", entity: project }),
          descriptor: {
            projectId: project.id,
          },
        }))
    );

    // branches
    const branches: Branch[] = values(branchEntities);

    results = results.concat(
      branches
        .filter((branch) => !isDeleted(branch))
        .filter((branch) => {
          // Include special case for terms like "main"
          // so that we return the "master" branch
          if (masterToMain && "main".includes(normalizedTerm)) {
            return isMaster(branch) || matchString(branch.name, normalizedTerm);
          }
          return matchString(branch.name, normalizedTerm);
        })
        .map((branch) => ({
          ...calculateRanking({ type: "branch", entity: branch }),
          descriptor: {
            projectId: branch.projectId,
            branchId: branch.id,
          },
        }))
    );

    const orderedResults = orderBy(results, ["score"], ["desc"]);
    return orderedResults;
  }
);
