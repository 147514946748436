// @flow
import Formatter from "core/components/PropertyValue/Formatter";
import type { LayerData } from "core/types";
import * as constants from "./constants";

export default function toCodeData(
  layerData: ?LayerData,
  formatter?: Formatter = Formatter.cssFormatter
): string[] {
  if (!layerData) {
    return constants.NO_CODE;
  }

  const code = [];

  code.push(`width: ${formatter.unit.code(layerData.properties.width)};`);
  code.push(`height: ${formatter.unit.code(layerData.properties.height)};`);

  if (layerData.properties.text) {
    const text = layerData.properties.text;

    if (text.color) {
      code.push(`color: ${formatter.color.code(text.color)};`);
    }
    if (text.fontSize) {
      code.push(`font-size: ${formatter.unit.code(text.fontSize)};`);
    }
    if (text.fontName) {
      code.push(`font-family: ${formatter.string.code(text.fontName)};`);
    }

    if (text.fontName) {
      const parts = text.fontName.split("-");
      const fontWeight = parts[1];

      if (fontWeight) {
        code.push(`font-weight: ${formatter.fontWeight.code(fontWeight)};`);
        const fontStyles = fontWeight.toLowerCase().match(/italic|oblique/g);
        if (fontStyles) {
          code.push(`font-style: ${formatter.fontStyle.code(fontStyles)};`);
        }
      }
    }

    if (text.textDecoration) {
      code.push(
        `text-decoration: ${formatter.textDecoration.code(
          text.textDecoration
        )};`
      );
    }

    if (
      text.horizontalAlignment &&
      text.horizontalAlignment !== constants.TEXT_NATURAL_ALIGNMENT
    ) {
      code.push(
        `text-align: ${formatter.alignment.code(text.horizontalAlignment)};`
      );
    }

    if (
      text.textTransform &&
      text.textTransform !== constants.TEXT_TRANSFORM_NONE
    ) {
      code.push(
        `text-transform: ${formatter.textTransform.code(text.textTransform)};`
      );
    }

    if (text.characterSpacing !== undefined) {
      code.push(
        `letter-spacing: ${formatter.letterSpacing.code(
          text.characterSpacing
        )};`
      );
    }

    // TODO: Look into accuracy of Sketch's line height
    if (text.lineHeight !== undefined) {
      code.push(`line-height: ${formatter.unit.code(text.lineHeight)};`);
    }
  }

  if (layerData.properties.fills) {
    const backgrounds = layerData.properties.fills
      .map((fill) => formatter.fill.code(fill))
      .filter((background) => background);

    if (backgrounds.length) {
      code.push(`background: ${backgrounds.join("\n")};`);
    }
  }

  if (layerData && layerData.properties.borders) {
    const [firstBorder] = layerData.properties.borders;
    if (
      firstBorder &&
      firstBorder.fillType === constants.LAYER_FILL_TYPE_SOLID
    ) {
      // CSS only supports one solid border
      code.push(`border: ${formatter.border.code(firstBorder)};`);
    }
  }

  if (layerData.properties.shadows) {
    let shadows = [];

    if (layerData.properties.shadows.inner) {
      shadows = [
        ...layerData.properties.shadows.inner.map((shadow) =>
          formatter.innerShadow.code(shadow)
        ),
      ];
    }

    if (layerData.properties.shadows.outer) {
      shadows = [
        ...shadows,
        ...layerData.properties.shadows.outer.map((shadow) =>
          formatter.outerShadow.code(shadow)
        ),
      ];
    }

    if (shadows.length) {
      code.push(`box-shadow: ${shadows.join(",\n")};`);
    }
  }

  if (layerData.properties.borderRadius) {
    const borderRadius = formatter.borderRadius.code(
      layerData.properties.borderRadius
    );

    if (borderRadius !== "0 0 0 0") {
      code.push(`border-radius: ${borderRadius};`);
    }
  }

  return code;
}
