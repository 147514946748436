// @flow
import * as React from "react";
import Centered from "core/components/Centered";
import FileIcon from "core/components/FileIcon";
import FileName from "core/components/FileName";
import Icon from "core/components/Icon";
import { V3Link as Link } from "core/lib/router";
import { projectPath, filePath } from "core/lib/routes";
import type { Library } from "core/types";
import style from "./style.scss";

export default class ExternalLibrary extends React.Component<{
  library: ?Library,
}> {
  get warning(): ?string {
    const { library } = this.props;
    if (!library) {
      return undefined;
    }

    if (library.isProjectUnsynced) {
      return "The project containing this library is not synced.";
    }
    if (library.isProjectUnavailable) {
      return "The project containing this library is not available, it may have been deleted.";
    }
    return undefined;
  }

  render() {
    const { library } = this.props;
    if (!library || !library.projectId || !library.branchId) {
      return null;
    }

    const projectIsAvailable =
      library.isProjectUnsynced || !library.isProjectUnavailable;
    const fileName = (
      <FileName
        name={library.name}
        type={library.type}
        className={style.fileName}
        icon={null}
        isLibrary
      />
    );

    return (
      <Centered>
        <div className={style.content}>
          <FileIcon
            type={library.type}
            className={style.fileIcon}
            isLibrary
            xl
          />
          {library.isProjectUnavailable ? (
            <span className={style.fileLink}>{fileName}</span>
          ) : (
            <Link
              to={filePath(library.projectId, library.branchId, library.fileId)}
              className={style.fileLink}
            >
              {fileName}
              <Icon type="go-to" />
            </Link>
          )}
          <p className={style.description}>
            This is a library file from the{" "}
            {projectIsAvailable ? (
              <Link to={projectPath(library.projectId)}>
                {library.projectName}
              </Link>
            ) : (
              <strong>{library.projectName}</strong>
            )}{" "}
            project.
          </p>
          {this.warning && (
            <span>
              <p className={style.warning}>
                <Icon type="warning-orange" className={style.warningIcon} />{" "}
                {this.warning}
              </p>
            </span>
          )}
        </div>
      </Centered>
    );
  }
}
