// @flow
import { normalize } from "normalizr";
import {
  webhookDeliveryListSchema,
  webhookEventListSchema,
  webhookListSchema,
} from "core/schema";

export function normalizeWebhooksResponse(response: *) {
  return normalize(response, webhookListSchema);
}

export function normalizeWebhookDeliveriesResponse(response: *) {
  return normalize(response, webhookDeliveryListSchema);
}

export function normalizeWebhookEventsResponse(response: *) {
  return normalize(response, webhookEventListSchema);
}
