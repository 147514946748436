// @flow
import pluralize from "pluralize";
import React, { Component } from "react";
import DialogForm from "core/components/DialogForm";
import FilesList from "core/components/FilesList";
import Loaded from "core/components/Loaded";
import SelectableFilesList from "core/components/SelectableFilesList";
import type { Project, Branch, File, ChangesetChangeCounts } from "core/types";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  projectId: string,
  branchId: string,
|};

export type StateProps = {|
  project: ?Project,
  branch: ?Branch,
  files: File[],
  isLoading: boolean,
  changeCounts: ChangesetChangeCounts,
|};

export type DispatchProps = {|
  onMount: () => void,
  onClose: () => void,
  onSubmit: (fileIds: string[]) => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type State = {
  selectedIds: string[],
};

class DiscardChanges extends Component<Props, State> {
  form: any;
  state = { selectedIds: [] };

  componentDidMount() {
    this.props.onMount();
    this.form.checkValid();
  }

  componentDidUpdate() {
    this.form.checkValid();
  }

  handleSubmit = (event: SyntheticEvent<>) => {
    event.preventDefault();
    const hasFiles = !!this.props.files.length;
    const filesSelected = !!this.state.selectedIds.length;

    if (!hasFiles || filesSelected) {
      this.props.onSubmit(this.state.selectedIds);
      this.props.onClose();
    }
  };

  toggleSelectedFile = (event: SyntheticInputEvent<>, fileId: string) => {
    const selected = event.target.checked;
    const selectedIds = this.state.selectedIds;

    if (selected) {
      this.setState({ selectedIds: selectedIds.concat([fileId]) });
    } else {
      selectedIds.splice(selectedIds.indexOf(fileId, 1));
      this.setState({ selectedIds });
    }
  };

  render() {
    const { project, branch, isLoading, changeCounts, files } = this.props;
    const hasFiles = !isLoading && files && !!files.length;
    const multipleFiles = hasFiles && files.length > 1;
    const selectedFilesCount = this.state.selectedIds.length;
    const showButtonCount = multipleFiles && selectedFilesCount > 0;
    const buttonText = showButtonCount
      ? `Discard changes in ${selectedFilesCount} ${pluralize(
          "file",
          selectedFilesCount
        )}`
      : "Discard changes";

    return (
      <DialogForm
        title="Discard changes"
        primaryButton={isLoading ? "" : buttonText}
        ref={(ref) => (this.form = ref)}
        onClose={this.props.onClose}
        onSubmit={this.handleSubmit}
        dangerous
        isOpen
      >
        <div className={style.content}>
          <Loaded loading={isLoading}>
            {project && branch && (
              <React.Fragment>
                {!hasFiles && (
                  <p>
                    Discard all uncommitted changes on the{" "}
                    <strong>{branch.name}</strong> branch in{" "}
                    <strong>{project.name}</strong>? This action is permanent
                    and cannot be undone.
                  </p>
                )}
                {hasFiles && (
                  <p>
                    Select{" "}
                    {multipleFiles
                      ? "one or more files that have"
                      : "the file that has"}{" "}
                    changes you want to discard on the{" "}
                    <strong>{branch.name}</strong> branch in{" "}
                    <strong>{project.name}</strong>. This action is permanent
                    and cannot be undone.
                  </p>
                )}
                <input
                  className={style.hidden}
                  value={this.state.selectedIds.join(",")}
                  required={hasFiles}
                />
                {hasFiles && (
                  <FilesList component="div">
                    <SelectableFilesList
                      project={project}
                      branch={branch}
                      files={files}
                      changeCounts={changeCounts}
                      selectedIds={this.state.selectedIds}
                      onChange={this.toggleSelectedFile}
                    />
                  </FilesList>
                )}
              </React.Fragment>
            )}
          </Loaded>
        </div>
      </DialogForm>
    );
  }
}

export default connector(DiscardChanges);
