// @flow
import * as React from "react";
import FilterPopover from "core/components/FilterPopover";
import { addQuery, removeQuery, replace } from "core/lib/location";
import type { Project } from "core/types";
import PageTitle from "../PageTitle";

type Props = {
  projects: Project[],
  selectedProject: ?Project,
};

export default function OrganizationActivityHeader(props: Props) {
  const handleProjectChange = React.useCallback(
    (projectId: string) => {
      const selectedProjectId = props.selectedProject
        ? props.selectedProject.id
        : null;
      // Clear the project filter, when the same project is selected
      replace(
        projectId && projectId !== selectedProjectId
          ? addQuery({ projectId })
          : removeQuery("projectId")
      );
    },
    [props.selectedProject]
  );

  return (
    <PageTitle
      title="Activity"
      filter={
        <FilterPopover
          disableIcon={true}
          anchor="left"
          buttonText={
            props.selectedProject ? props.selectedProject.name : "All Projects"
          }
          placeholderText="All Projects"
          selectedItem={props.selectedProject}
          onSelectItem={handleProjectChange}
          items={props.projects}
        />
      }
    />
  );
}
