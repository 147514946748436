// @flow
import { connect } from "react-redux";
import { getCurrentUserId } from "abstract-di/selectors";
import connectStorage from "core/hocs/connectStorage";
import createConnector from "core/lib/createConnector";
import { isAdmin } from "core/models/membership";
import {
  enterpriseTeamsEnabled,
  teamsAnnouncementEnabled,
} from "core/selectors/features";
import { getMemberships } from "core/selectors/memberships";
import type { State, Dispatch } from "core/types";
import type { OwnProps, StateProps, StorageProps, Props } from ".";

const STORAGE_KEY = "teams-announcement-displayed";

type OwnAndStorageProps = {|
  ...OwnProps,
  ...StorageProps,
|};

function mapStorageToProps(storage, props: OwnProps): StorageProps {
  return {
    hasDisplayed: !!storage.getItem(STORAGE_KEY) || false,
    setHasDisplayed: () => storage.setItem(STORAGE_KEY, Date.now()),
  };
}

function mapStateToProps(state: State, props: OwnAndStorageProps): StateProps {
  const { organization } = props;
  const userId = getCurrentUserId(state);

  const memberships =
    organization && userId
      ? getMemberships(state, {
          organizationId: organization.id,
          userId,
        })
      : [];
  const membership = memberships.length === 1 ? memberships[0] : null;
  const isUserAdmin = !!membership && isAdmin(membership);
  const isTeamsEnabled = organization
    ? enterpriseTeamsEnabled(state, { organizationId: organization.id })
    : false;
  const isTeamsAnnouncementEnabled = organization
    ? teamsAnnouncementEnabled(state, organization.id)
    : false;

  return {
    canDisplayTeamsAnnouncement:
      isTeamsEnabled && isTeamsAnnouncementEnabled && isUserAdmin,
  };
}

export default createConnector<Props, OwnProps>(
  (Component) => connectStorage(Component, mapStorageToProps),
  connect<Props, OwnAndStorageProps, StateProps, _, State, Dispatch>(
    mapStateToProps
  )
);
