// @flow
import classnames from "classnames";
import * as React from "react";
import style from "./style.scss";

type Props = {
  className?: string,
  children: React.Node,
};

export default function ButtonController(props: Props) {
  return (
    <div className={classnames(style.buttonGroup, props.className)}>
      {props.children}
    </div>
  );
}
