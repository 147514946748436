// @flow
import { connect } from "react-redux";
import { withData } from "core/components/DataLoader";
import createConnector from "core/lib/createConnector";
import * as Membership from "core/models/membership";
import {
  EmojisFetchRequest,
  EmojiCreateRequest,
  EmojiDeleteRequest,
} from "core/requests/emojis";
import {
  getEmojisForOrganization,
  getEmojiCreatorMap,
} from "core/selectors/emojis";
import { getMembership } from "core/selectors/memberships";
import type { State, Dispatch, Emoji as TEmoji } from "core/types";
import { getCurrentUser } from "web/selectors/session";
import type { OwnProps, StateProps, DispatchProps, Props } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { params } = props;
  const currentUser = getCurrentUser(state);
  const membership = currentUser
    ? getMembership(
        state,
        Membership.uniqueId({
          organizationId: params.organizationId,
          userId: currentUser.id,
        })
      )
    : undefined;
  const currentUserIsAdmin = !!membership && Membership.isAdmin(membership);

  return {
    emojis: getEmojisForOrganization(state, params),
    userMap: getEmojiCreatorMap(state, params),
    currentUser,
    currentUserIsAdmin,
    isLoading: EmojisFetchRequest.isFirstLoading(state, {
      organizationId: props.params.organizationId,
      includeUsers: true,
    }),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    onLoad: () => {
      dispatch(
        EmojisFetchRequest.perform({
          params: {
            organizationId: props.params.organizationId,
            includeUsers: true,
          },
        })
      );
    },
    onCreateEmoji: ({ name, image }) => {
      return dispatch(
        EmojiCreateRequest.perform({
          params: { name, image, ...props.params },
        })
      );
    },
    onDeleteEmoji: (emoji: TEmoji) => {
      return dispatch(
        EmojiDeleteRequest.perform({
          params: emoji,
        })
      );
    },
  };
}

export default createConnector<Props, OwnProps>(
  connect<Props, OwnProps, StateProps, DispatchProps, State, Dispatch>(
    mapStateToProps,
    mapDispatchToProps
  ),
  withData((props: Props) => props.params)
);
