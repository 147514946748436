// @flow
import * as React from "react";
import Button from "core/components/Button";
import Flex from "core/components/Flex";
import Heading from "core/components/Heading";
import Icon from "core/components/Icon";
import Note from "core/components/Note";
import SettingsItem from "core/components/SettingsItem";
import style from "./style.scss";

type Props = {
  title: string,
  description: string,
  icon: React.Node,
  handleConnect: () => Promise<void>,
  connected?: boolean,
  integrationUrl?: string,
};

export default function IntegrationSettingsItem(props: Props) {
  return (
    <SettingsItem to={props.integrationUrl}>
      {props.icon}
      <Flex column align="flex-start" className={style.description}>
        <Flex column justify="space-between" className={style.headingWrap}>
          <Heading level="4" size="m">
            {props.title}
          </Heading>
          <Note component="div">{props.description}</Note>
        </Flex>
        {props.connected && <div className={style.connected}>Connected</div>}
      </Flex>
      {props.connected ? (
        <Icon type="go-to" className={style.arrow} />
      ) : (
        <div className={style.button}>
          <Button onClick={() => props.handleConnect()} type="button">
            Connect
          </Button>
        </div>
      )}
    </SettingsItem>
  );
}
