// @flow
import Clipboard from "clipboard";
import * as React from "react";
import { connect } from "react-redux";
import { showToast } from "core/actions/toasts";
import type { Dispatch } from "core/types";

type Props = {|
  children: (((setClickableRef: ?HTMLElement) => void)) => React.Node,
  value?: string,
  toast?: string,
  toastIcon?: string,
  disabled?: boolean,
  onSuccess?: (value: string) => void,
|};

class CopyToClipboard extends React.Component<Props> {
  clipboard: ?Clipboard;
  clickableRef: ?HTMLElement;

  componentDidMount() {
    if (!this.props.disabled) {
      this.clipboard = new Clipboard(this.clickableRef, {
        text: () => this.props.value,
      });

      this.clipboard.on("success", this.handleSuccess);
    }
  }

  componentWillUnmount() {
    if (this.clipboard) {
      this.clipboard.destroy();
    }
  }

  render() {
    return this.props.children(this.setClickableRef);
  }

  handleSuccess = () => {
    if (this.props.onSuccess && this.props.value) {
      this.props.onSuccess(this.props.value);
    }
  };

  setClickableRef = (ref: ?HTMLElement) => {
    this.clickableRef = ref;
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: Props
  /* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
   * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
): { onSuccess?: (value: string) => * } {
  return {
    onSuccess: (value) => {
      if (props.onSuccess) {
        props.onSuccess(value);
      }
      if (props.toast) {
        dispatch(
          showToast(
            { icon: props.toastIcon, text: props.toast || "Copied" },
            2000
          )
        );
      }
    },
  };
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default connect(undefined, mapDispatchToProps)(CopyToClipboard);
