// @flow
import classnames from "classnames";
import * as React from "react";
import BranchedFromDescription from "core/components/BranchedFromDescription";
import Button from "core/components/Button";
import Flex from "core/components/Flex";
import Heading from "core/components/Heading";
import InputMarkdown from "core/components/InputMarkdown";
import Markdown from "core/components/Markdown";
import Time from "core/components/Time";
import type { Branch, User } from "core/types";
import Validations from "core/validations";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  projectId: string,
  branch: Branch,
  isMobile?: boolean,
|};

export type StateProps = {|
  canEdit: boolean,
  parent: ?Branch,
  user: ?User,
  users: User[],
|};

export type DispatchProps = {|
  updateBranchDescription: (
    description: string,
    options: { onError: () => void }
  ) => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

const placeholderText =
  "Description of the work you’ve done and the feedback you’re looking for…";

function BranchDescription(props: Props) {
  const { branch, updateBranchDescription } = props;

  const [isEditing, setIsEditing] = React.useState(false);
  const [descriptionDraft, setDescriptionDraft] = React.useState(
    branch.description
  );

  React.useEffect(() => {
    setDescriptionDraft(branch.description);
  }, [branch.description]);

  const handleUpdateDescription = React.useCallback(() => {
    updateBranchDescription(descriptionDraft, {
      onError: () => {
        // If there happen to be a request error after optimistically updating
        // the branch description, we need to revert the component's state
        setDescriptionDraft(branch.description);
      },
    });

    setIsEditing(false);
  }, [branch.description, descriptionDraft, updateBranchDescription]);

  const handleEdit = () => {
    setIsEditing(true);
    setDescriptionDraft(branch.description);
  };

  const handleCancel = () => {
    setDescriptionDraft(branch.description);
    setIsEditing(false);
  };

  const renderBranchedFromDescription = () => {
    const { branch, parent, user, projectId, isMobile } = props;

    if (parent && user && isMobile) {
      return (
        <BranchedFromDescription
          user={user}
          projectId={projectId}
          parent={parent}
          className={style.branchedFrom}
        >
          Last updated <Time date={branch.updatedAt} />
        </BranchedFromDescription>
      );
    }

    return null;
  };

  const renderAsEditing = () => {
    const isDescriptionUnchanged =
      props.branch.description === descriptionDraft;

    return (
      <React.Fragment>
        <InputMarkdown
          minHeight={120}
          maxHeight={320}
          projectId={props.projectId}
          label="Summary"
          labelClass={style.descriptionTitle}
          descriptionHeaderClass={style.descriptionHeader}
          autoFocus
          focusClass={style.focusedDescription}
          placeholder={placeholderText}
          onChange={setDescriptionDraft}
          value={descriptionDraft}
          maxLength={Validations.maxBranchDescriptionLength}
          onSubmit={handleUpdateDescription}
        />
        <Flex align="center" justify="flex-end" className={style.buttonRow}>
          <Button onClick={handleCancel} className={style.button}>
            Cancel
          </Button>
          <Button
            primary
            className={style.button}
            onClick={handleUpdateDescription}
            disabled={isDescriptionUnchanged}
          >
            Save Changes
          </Button>
        </Flex>
      </React.Fragment>
    );
  };

  const renderAsNormal = () => {
    return (
      <React.Fragment>
        <Flex
          justify="space-between"
          align="center"
          className={style.descriptionHeader}
        >
          <Heading level="2" size="l">
            Summary
          </Heading>
          {props.canEdit && (
            <Button nude tint onClick={handleEdit}>
              Edit
            </Button>
          )}
        </Flex>
        <Markdown
          text={descriptionDraft || placeholderText}
          className={classnames(style.description, {
            [style.noDescription]: !props.branch.description,
          })}
        />
        {renderBranchedFromDescription()}
      </React.Fragment>
    );
  };

  // Don't show blank descriptions to people who can't change it
  if (!props.canEdit && !props.branch.description) {
    return null;
  }

  return (
    <div className={style.container}>
      {isEditing ? renderAsEditing() : renderAsNormal()}
    </div>
  );
}

export default connector(BranchDescription);
