// @flow
import map from "lodash/map";
import * as React from "react";
import Button from "core/components/Button";
import Comment from "core/components/Comment";
import type { Props as CommentProps } from "core/components/Comment/Base";
import Spinner from "core/components/Spinner";
import type { Comment as TComment } from "core/types";
import style from "./style.scss";

type Props = CommentProps & {
  replyIds: string[],
  replies: TComment[],
  onLoadMore: () => *,
};

type State = {
  visibleReplyCount: number,
  newReplyCount: number,
  isLoadingMore: boolean,
};

const EMPTY = [];
const DEFAULT_VISIBLE_REPLIES = 2;

export default class RepliesLoader extends React.Component<Props, State> {
  state = {
    visibleReplyCount: DEFAULT_VISIBLE_REPLIES,
    newReplyCount: 0,
    isLoadingMore: false,
  };

  componentDidUpdate(prevProps: Props) {
    const nextLoadedReplyCount = this.props.replies.length;
    const nextTotalReplyCount = this.props.replyIds.length;

    if (
      this.state.isLoadingMore &&
      nextLoadedReplyCount >= this.state.visibleReplyCount
    ) {
      return this.setState({
        isLoadingMore: false,
        visibleReplyCount: nextLoadedReplyCount,
      });
    }

    const newReplyCount = nextLoadedReplyCount - this.loadedReplyCount;

    if (
      newReplyCount > 0 &&
      nextLoadedReplyCount > DEFAULT_VISIBLE_REPLIES &&
      nextTotalReplyCount > this.totalReplyCount
    ) {
      const latest = this.props.replies[nextLoadedReplyCount - 1];
      if (
        this.props.currentUser &&
        this.props.currentUser.id === latest.userId
      ) {
        /* Show the new reply if the user authored it */
        this.setState((prev) => ({
          visibleReplyCount: prev.visibleReplyCount + 1,
        }));
      } else {
        this.setState((prev) => ({
          newReplyCount: prev.newReplyCount + newReplyCount,
        }));
      }
    }
  }

  handleShowMore = () => {
    if (this.loadedReplyCount < this.totalReplyCount) {
      this.props.onLoadMore();
      return this.setState({ isLoadingMore: true });
    }

    this.setState({ visibleReplyCount: this.loadedReplyCount });
  };

  handleShowNew = () => {
    this.setState((prev) => {
      const hasHidden = this.totalReplyCount > prev.visibleReplyCount;

      return {
        newReplyCount: 0,
        visibleReplyCount: hasHidden
          ? prev.visibleReplyCount + prev.newReplyCount
          : prev.visibleReplyCount,
      };
    });
  };

  get loadedReplyCount(): number {
    return this.props.replies.length;
  }

  get totalReplyCount(): number {
    return this.props.replyIds.length;
  }

  get hiddenReplyCount(): number {
    if (this.loadedReplyCount < DEFAULT_VISIBLE_REPLIES) {
      return this.totalReplyCount - this.loadedReplyCount;
    }

    return (
      this.totalReplyCount -
      this.state.visibleReplyCount -
      this.state.newReplyCount
    );
  }

  get visibleReplies(): TComment[] {
    const loadedReplyCount = this.loadedReplyCount;
    const { visibleReplyCount, newReplyCount } = this.state;

    if (!loadedReplyCount) {
      return EMPTY;
    }
    if (loadedReplyCount <= DEFAULT_VISIBLE_REPLIES) {
      return this.props.replies;
    }

    const startSlice = loadedReplyCount - visibleReplyCount - newReplyCount;

    return this.props.replies.slice(
      startSlice < 0 ? 0 : startSlice,
      loadedReplyCount - newReplyCount
    );
  }

  render() {
    const { newReplyCount, isLoadingMore } = this.state;
    const hiddenReplyCount = this.hiddenReplyCount;
    const visibleReplies = this.visibleReplies;

    return (
      <div>
        {isLoadingMore && (
          <div className={style.more}>
            <div className={style.spinner}>
              <Spinner small />
            </div>
          </div>
        )}
        {hiddenReplyCount > 0 && !isLoadingMore && (
          <div className={style.more}>
            <Button nude tint onClick={this.handleShowMore}>
              Show {hiddenReplyCount} older{" "}
              {hiddenReplyCount > 1 ? "replies" : "reply"}
            </Button>
          </div>
        )}
        {!!visibleReplies.length && (
          <div className={style.comments}>
            {map(visibleReplies, (reply) => (
              <Comment key={reply.id} comment={reply} {...this.props} />
            ))}
          </div>
        )}
        {newReplyCount > 0 && (
          <div className={style.new}>
            <Button nude tint onClick={this.handleShowNew} icon="unread">
              Show {newReplyCount} new {newReplyCount > 1 ? "replies" : "reply"}
            </Button>
          </div>
        )}
      </div>
    );
  }
}
