// @flow
import { forEach } from "lodash";
import pluralize from "pluralize";
import * as React from "react";
import AnimatedFlyover from "core/components/AnimatedFlyover";
import Button from "core/components/Button";
import FileName from "core/components/FileName";
import MenuItem, {
  type MenuItemProps,
  type SubMenuProps,
} from "core/components/FloatingMenuItem";
import InputSearch from "core/components/InputSearch";
import * as File from "core/models/file";
import type { Page, File as TFile, FilePreviews } from "core/types";
import style from "./style.scss";

type Props = {
  mobile?: boolean,
  disabled?: boolean,
  filter?: string,
  files: TFile[],
  pages: { [pageId: string]: Page },
  previewsByFile: FilePreviews,
  onClickItem: (params: {
    id: string,
    offset?: number,
    onScrolled?: () => void,
  }) => void,
};

type Item = {
  file: TFile,
  submenu?: MenuItemProps[],
  submenuProps?: SubMenuProps,
};

type State = {
  isOpen: boolean,
  searchValue: string,
};

const SUBMENU_PROPS = {
  offset: "-4px",
  placement: "left-start",
  floatingClassName: style.submenuContainer,
};

export default class FileJumpButton extends React.Component<Props, State> {
  static defaultProps = {
    files: [],
    pages: {},
    changes: {},
  };

  state = {
    isOpen: false,
    searchValue: "",
  };

  handleChangeSearchBar = (event: SyntheticInputEvent<*>) => {
    this.setState({ searchValue: event.target.value });
  };

  handleClickItem = (fileId: string, pageId?: string) => {
    const id = pageId || fileId;
    const offset = pageId ? -88 : 4;
    /*
      When VirtualizedList scrolls to a row, it scrolls that row to the top of
      the container. But because of the sticky file headers on changesets, we
      don't actually want to do that when scrolling to a row. We want to scroll
      so that the page header and sticky file header are both visible. Hence
      the -76 offset. The 4 offset is a failsafe so that when scrolling to a file
      we definitely show the right sticky file header. If we scroll exactly to
      0, sometimes the sticky header for the previous file can be active
      instead.
    */
    this.props.onClickItem({ id, offset });
    this.setState({ isOpen: false });
  };

  handleToggleOpen = (event: SyntheticEvent<>) => {
    event.preventDefault();
    this.setState((prev) => ({ isOpen: !prev.isOpen }));
  };

  handleClose = () => {
    this.setState({ isOpen: false, searchValue: "" });
  };

  getSubMenu = (file: TFile): MenuItemProps[] => {
    const changes = this.props.previewsByFile[file.id];
    const contentTypeIds = [];
    const items = [];

    forEach(changes, (layers, pageId) => {
      const page = this.props.pages[pageId];
      if (page) {
        const contentType = File.contentTypes[page.id];
        const item = {
          children: <span>{page.name}</span>,
          icon: contentType ? contentType.icon : "file-page",
          activeHover: true,
          onClick: () => this.handleClickItem(file.id, pageId),
        };

        items.push(item);
      } else if (
        File.contentTypes[pageId] &&
        pageId !== "symbol" &&
        pageId !== "nonVisual"
      ) {
        contentTypeIds.push(pageId);
      }
    });

    forEach(["symbol", ...contentTypeIds, "nonVisual"], (type) => {
      if (changes[type] && changes[type].length) {
        const contentType = File.contentTypes[type];
        items.push({
          children: (
            <span>
              {type === "nonVisual" ? "Non-previewable" : contentType.name}
            </span>
          ),
          icon: contentType.icon,
          activeHover: true,
          onClick: () => this.handleClickItem(file.id, `${file.id}-${type}`),
        });
      }
    });

    return items;
  };

  getItems = (): Item[] => {
    const searchValue = this.state.searchValue.toLowerCase();

    return this.props.files.reduce((memo, file) => {
      if (!file.name.toLowerCase().includes(searchValue)) {
        return memo;
      }

      if (this.props.previewsByFile[file.id]) {
        memo.push({
          file,
          submenuProps: SUBMENU_PROPS,
          submenu: this.props.mobile ? undefined : this.getSubMenu(file),
        });
      }

      return memo;
    }, []);
  };

  renderPopoutBody() {
    const items = this.getItems();
    const { searchValue } = this.state;
    const empty = items.length === 0;

    return (
      <div className={style.body}>
        <InputSearch
          autoFocus
          disabled={this.props.disabled}
          placeholder="Filter files…"
          value={searchValue}
          onChange={this.handleChangeSearchBar}
          wrapperClass={style.searchWrapper}
        />

        {searchValue && empty && (
          <div className={style.emptyExplanation}>
            No files were found matching{" "}
            <span className={style.searchTerm}>{searchValue}</span>
          </div>
        )}

        {items.map((item) => (
          <MenuItem
            key={item.file.id}
            submenu={item.submenu}
            submenuProps={item.submenuProps}
            onClick={() => this.handleClickItem(item.file.id)}
            activeHover
          >
            <FileName
              name={item.file.name}
              type={item.file.type}
              isLibrary={item.file.isLibrary}
            />
          </MenuItem>
        ))}
      </div>
    );
  }

  render() {
    const fileCount = Object.keys(this.props.previewsByFile).length;
    return (
      <AnimatedFlyover
        anchor="right"
        className={style.selectorContainer}
        body={this.renderPopoutBody()}
        onClickOutside={this.handleClose}
        onRequestClose={this.handleClose}
        isOpen={this.state.isOpen}
      >
        <Button
          nude
          disclosure
          icon="file"
          active={this.state.isOpen}
          disabled={!fileCount}
          onClick={this.handleToggleOpen}
        >
          {`${fileCount} ${this.props.filter || "changed"} ${pluralize(
            "file",
            fileCount
          )}`}
        </Button>
      </AnimatedFlyover>
    );
  }
}
