// @flow
import * as React from "react";
import Button from "core/components/Button";
import onboardingStyle from "core/components/onboarding.scss";
import { helpProjectPermissionsAccessRequestUrl } from "core/lib/urls";
import withForm from "web/containers/withForm";
import type { FormProps } from "web/types";
import type { FormValues } from "./RequestAccessForm";
import RequestSentMessage from "./RequestSentMessage";
import style from "./style.scss";

type OwnProps = {|
  onSubmit: (formId: string, values: FormValues) => void,
|};

type Props = {
  ...OwnProps,
  ...FormProps,
};

class LoggedInRequestAccessForm extends React.Component<Props> {
  handleSubmit = (event: SyntheticEvent<>) => {
    event.preventDefault();
    this.props.onSubmit(this.props.form.id, this.props.form.values);
  };

  render() {
    if (this.props.form.submitSucceeded) {
      return <RequestSentMessage />;
    }

    return (
      <form onSubmit={this.handleSubmit}>
        <div className={style.message}>
          You need permission to access this project. We will send your request
          to the project Admin. Once your request is approved, we’ll let you
          know by email.
        </div>
        <Button
          disabled={this.props.form.isSubmitting}
          icon={this.props.form.isSubmitting ? "spinner" : undefined}
          className={onboardingStyle.button}
          type="submit"
          fullwidth
          primary
          large
        >
          {this.props.form.isSubmitting
            ? "Requesting access…"
            : "Request access"}
        </Button>
        <div className={style.footer}>
          <a
            href={helpProjectPermissionsAccessRequestUrl()}
            target="_blank"
            rel="noopener noreferrer"
          >
            Why don’t I have access?
          </a>
        </div>
      </form>
    );
  }
}

export default withForm<OwnProps>(
  LoggedInRequestAccessForm,
  "logged-in-request-access-form"
);
