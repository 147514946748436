// @flow
import queryString from "query-string";
import * as React from "react";
import {
  Router,
  Routes,
  Route,
  Outlet,
  Navigate,
  Link,
  NavLink,
  useLocation,
  useMatch,
  matchRoutes,
  generatePath,
  createRoutesFromChildren,
} from "react-router-dom";
import history from "abstract-di/history";
import Modal from "core/components/Modal";
import { BRANCH_ID_MASTER } from "core/models/branch";
import type { ReactRouterLocation, LocationDescriptor } from "core/types";

const RoutesContext = React.createContext<$ReadOnlyArray<{ ... }>>([]);

/**
 * Returns a react-router@3 location that is compatible with react-router@6
 *
 * A majority of ui still expects `location.query` to be present. We use v3Location
 * to internally to shim `location.query` from `location.search` to . This way the
 * application is not aware v6 removed the query object
 */
function v3Location(location: ReactRouterLocation): ReactRouterLocation {
  return {
    ...location,
    query: queryString.parse(location.search), // add query object from react-router@3
  };
}

/**
 * Returns a react-router@6 location that is compatible with our application
 *
 * A majority of the app is still aware of v3 locations, you'll need to convert
 * the v3 location to a v6 location before using any functions that mutates location.
 *
 * e.g. `addQuery` and `removeQuery` from `lib/core/location` are already setup
 * to do this for you.
 *
 * If you find yourself using this function in component code, consider using
 * or adding a utility to `core/lib/location` first
 */
export function v6Location(location: ReactRouterLocation): ReactRouterLocation {
  const search = queryString.stringify(location.query);

  return {
    ...location,
    search: search ? "?" + search : "",
  };
}

/**
 * Returns a react-router@6 location descriptor that is compatible with our application
 *
 * Just like with `v6Location`, you'll need to convert the v3 location to a v6
 * location before using any functions that mutate location. `push` and `pull`
 * from `lib/core/location` are already setup to do this for you.
 *
 * If you find yourself using this function in component code, consider using
 * or adding a utility to `core/lib/location` first
 */
export function v6LocationDescriptor(
  locationDescriptor: LocationDescriptor
): LocationDescriptor {
  if (typeof locationDescriptor === "string") {
    return locationDescriptor;
  }

  const search = queryString.stringify(locationDescriptor.query);

  return {
    ...locationDescriptor,
    search: search ? "?" + search : "",
  };
}

export function getCurrentV3Location() {
  return v3Location(history.location);
}

// Restore v3 api on history (now: `useLocation` or `history.location`)
history.getCurrentLocation = getCurrentV3Location;

type ReactRouterParams = {| [string]: string |};
type ReactRouterRoutes = Array<{ ... }>;

export type RouterProps = $ReadOnly<{|
  params: ReactRouterParams,
  routes: ReactRouterRoutes,
  location: ReactRouterLocation,
  router: {|
    params: ReactRouterParams,
    routes: ReactRouterRoutes,
    matches: ReactRouterRoutes,
    isActive: (path: string) => boolean,
    goBack: () => void,
  |},
|}>;

/**
 * HOC that mimics the old behavior of `withRouter` under react-router@6
 *
 * This includes:
 *  - Restoring injected `RouterProps`: `{ router, params, location, routes }`
 *  - Prefering last matched params instead of v6's new behavior, where it uses
 *    the matched fragment of params for the currently matched route
 *
 * NOTE: If you find yourself using this function in component code, consider
 * using the new hooks: `useLocation`, `useParams`, etc. from `react-router-dom`
 */
export function withV3Router<Config = {}, Instance = void>(
  WrappedComponent: React.AbstractComponent<
    {| ...Config, ...RouterProps |},
    Instance,
  >
): React.AbstractComponent<Config> {
  return React.forwardRef<Config, Instance>(function WithV3Router(
    props: Config,
    ref
  ) {
    const basename = ""; // Assume no custom basename
    const location = useLocation();
    const routes = React.useContext(RoutesContext);
    const matches = React.useMemo(
      () => matchRoutes(routes, location, basename),
      [location, routes]
    );

    // checks for existence of matches here are to support storybook, which has
    // a router but no routes.
    const lastMatch = matches ? matches[matches.length - 1] : undefined;
    let params = lastMatch ? lastMatch.params : {}; // eslint-disable-line react-hooks/exhaustive-deps
    if (params.branchId === "main") {
      params = { ...params, branchId: BRANCH_ID_MASTER };
    }
    const v3Router = React.useMemo(
      () => ({
        location: v3Location(location),
        params,
        routes,
        isActive: (path) => false, // TODO: use matchRoutes or similar
      }),
      [location, params, routes]
    );

    return (
      <WrappedComponent
        {...props}
        router={v3Router}
        params={v3Router.params}
        location={v3Router.location}
        routes={v3Router.routes}
        ref={ref}
      />
    );
  });
}

/**
 * `Routes` proxy that places routes on context for `withV3Router`
 *
 * NOTE: This should only be used once per application. Do not use
 * `V3Routes` in component code. Prefer `Routes` from `react-router-dom`
 */
export function V3Routes(
  props: $ReadOnly<{
    children: React.Node,
  }>
) {
  const routes = createRoutesFromChildren(props.children);

  return (
    <RoutesContext.Provider value={routes}>
      <Routes>{props.children}</Routes>
    </RoutesContext.Provider>
  );
}

/**
 * `Route` proxy that utilizes `withV3Router` to support routes using
 * the v3 api
 *
 * Previously, a `param` passed to the route would be available as
 * `props.routes.param`. Now props passed to the route are available
 * as `props.param`
 *
 * NOTE: If you are building a new route, prefer `Route` from `react-router-dom`
 */
export function V3Route({
  component,
  children,
  path,
  id,
  ...props
}: $ReadOnly<{
  component: React.ComponentType<any>,
  children?: React.Node,
  path?: string,
  id?: string,
  ...
}>) {
  const WrappedComponent = React.useMemo(
    () => withV3Router(component),
    [component]
  );

  return (
    <Route
      id={id}
      path={path}
      children={children}
      element={
        <WrappedComponent
          {...props}
          children={children ? <Outlet /> : undefined}
        />
      }
    />
  );
}

/**
 * Proxy for v3's `Redirect`
 *
 * When redirecting, this component will proxy any params that were
 * matched to the new route. Given `/path/:param` and `/new/path/:param`,
 * `/path/1` will redirect to `/new/path/1`
 *
 * NOTE: Do not use `V3Redirect` prefer `Navigate` from `react-router-dom`
 */
export function V3Redirect(props: $ReadOnly<{| path: string, to: string |}>) {
  const match = useMatch(props.path);

  if (!match) {
    return null;
  } else {
    return <Navigate to={generatePath(props.to, match.params)} />;
  }
}

/**
 * Proxy for v3's `Router`
 *
 * This also serves as a reference implementation. In practive, we
 * use a variation of this called `ModalRouter`, which can be found
 * in this module below
 */
export function V3Router(props: $ReadOnly<{ children: React.Node }>) {
  const [state, setState] = React.useState({
    action: history.action,
    location: history.location,
  });

  React.useLayoutEffect(() => history.listen(setState), []);

  return (
    <Router
      children={props.children}
      action={state.action}
      location={state.location}
      navigator={history}
    />
  );
}

function locationIsModal(location) {
  return !!location.state && location.state.modal;
}

const ModalContext = React.createContext(false);

/**
 * Will return true when inside of a modal
 */
export function useInModal(): boolean {
  return React.useContext(ModalContext);
}

/**
 * A custom hook that builds on useLocation to parse the
 * query string for you
 */
export function useQuery(): { [string]: string | [string] } {
  const { search } = useLocation();
  return React.useMemo(() => queryString.parse(search), [search]);
}

/**
 * ModalRouter is a variant of V3Router that will display the
 * previous location behind a modal when `location.state.modal`
 * is set for the current route
 */
export function ModalRouter(props: $ReadOnly<{ children: React.Node }>) {
  const [state, setState] = React.useState({
    action: history.action,
    location: history.location,
    previousLocation: null,
  });

  React.useLayoutEffect(
    () =>
      history.listen(({ action, location }) => {
        setState((state) => ({
          action,
          location,
          previousLocation: !locationIsModal(state.location)
            ? state.location // Capture location when entering first modal
            : state.previousLocation, // Persist location when navigating to other modals
        }));
      }),
    []
  );

  const isModal =
    locationIsModal(state.location) && Boolean(state.previousLocation);

  return (
    <React.Fragment>
      <Router
        location={isModal ? state.previousLocation : state.location}
        action={state.action}
        navigator={history}
      >
        {props.children}
      </Router>
      <Router
        location={state.location}
        action={state.action}
        navigator={history}
      >
        <ModalContext.Provider value={true}>
          <Modal isOpen={isModal} size="fullScreen" shouldCloseOnEsc={false}>
            {isModal && props.children}
          </Modal>
        </ModalContext.Provider>
      </Router>
    </React.Fragment>
  );
}

/**
 * Proxy for v3's `Link` to ensure `to` is always defined
 *
 * The `to` prop under v6 is required. Under certain circumstances we
 * have application code that will briefly set `to={undefined | null}`.
 * These situations are difficult to track down and cause the application
 * to crash under v6
 *
 * The history API now requires a second argument for state. This component will
 * also map `props.to.state` to `props.state`
 *
 * NOTE: If you are writing new code, prefer `Link` from `react-router-dom`
 */
export function V3Link(
  props: $ReadOnly<{
    to?: LocationDescriptor,
    onClick?: (event: SyntheticMouseEvent<>) => void,
    ...
  }>
) {
  const onClick = (event) => {
    if (!props.to) {
      event.preventDefault();
    }

    if (props.onClick) {
      props.onClick(event);
    }
  };

  return (
    <Link
      {...props}
      to={v6LocationDescriptor(props.to || "")}
      state={
        !props.to || typeof props.to === "string" ? undefined : props.to.state
      }
      onClick={onClick}
    />
  );
}

/**
 * Proxy for v3's `NavLink` to ensure `to` is always defined
 *
 * NOTE: If you are writing new code, prefer `NavLink` from `react-router-dom`
 *
 * @see: V3Link
 */
export function V3NavLink(
  props: $ReadOnly<{
    to?: LocationDescriptor,
    onClick?: (event: SyntheticMouseEvent<>) => void,
    ...
  }>
) {
  const onClick = (event) => {
    if (!props.to) {
      event.preventDefault();
    }

    if (props.onClick) {
      props.onClick(event);
    }
  };

  return (
    <NavLink
      {...props}
      to={v6LocationDescriptor(props.to || "")}
      state={
        !props.to || typeof props.to === "string" ? undefined : props.to.state
      }
      onClick={onClick}
    />
  );
}

export { withV3Router as withRouter };
