// @flow
import { connect } from "react-redux";
import { confirmStopSyncing } from "abstract-di/actions";
import { isOnline, getRepoIsUnsynced } from "abstract-di/selectors";
import { deleteProject } from "core/actions/projects";
import { replace, getCurrentLocation } from "core/lib/location";
import { projectPath, organizationProjectsPath } from "core/lib/routes";
import { ProjectDeleteRequest } from "core/requests/projects";
import { canUsePartialSync } from "core/selectors/features";
import { getProjectPolicy } from "core/selectors/policies";
import type { Dispatch, State } from "core/types";
import type { OwnProps, DispatchProps, StateProps } from ".";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { project } = props;
  const projectId = project.id;
  const canPartialSync = canUsePartialSync(state, { projectId });

  return {
    policy: getProjectPolicy(state, { projectId }),
    isOffline: !isOnline(state),
    isUnsynced: !!getRepoIsUnsynced(state, { projectId }) && !canPartialSync,
    deleteRequest: ProjectDeleteRequest.getRequest(state, { project }),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { project } = props;

  return {
    onStopSyncingProject() {
      confirmStopSyncing && dispatch(confirmStopSyncing(project.id));
    },
    onDeleteProject() {
      const location = getCurrentLocation();

      if (location.pathname === projectPath(project.id)) {
        // replace rather than push here as you shouldn't be able
        // to navigate backwards to the deleted project
        replace(organizationProjectsPath(project.organizationId));
      }

      return dispatch(deleteProject(project));
    },
  };
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default connect(mapStateToProps, mapDispatchToProps);
