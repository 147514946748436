// @flow
import invariant from "invariant";
import { connect } from "react-redux";
import { removeLibrary } from "abstract-di/actions";
import { dismissDialog } from "core/actions/dialogs";
import type { State, Dispatch } from "core/types";
import type { Props, OwnProps, DispatchProps } from ".";

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  return {
    removeLibrary: () => {
      invariant(removeLibrary, "removeLibrary required for dialog action");
      dispatch(removeLibrary(props.projectId, props.branchId, props.library));
    },
    dismissDialog: () => {
      dispatch(dismissDialog());
    },
  };
}

export default connect<Props, OwnProps, _, DispatchProps, State, Dispatch>(
  undefined,
  mapDispatchToProps
);
