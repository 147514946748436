// @flow
import * as Abstract from "abstract-sdk";
import queryString from "query-string";
import apiRequest from "abstract-di/api";
import { entitiesReceived } from "core/actions/entities";
import abstract from "core/lib/abstract";
import { type ReviewStatus } from "core/models/review";
import { normalizeReviews } from "core/schemas/reviews";
import type { CommentForm } from "core/types";
import defineRequest from "./";

export const BranchReviewsFetchRequest = defineRequest<
  Abstract.BranchDescriptor,
  Abstract.BranchDescriptor,
>({
  id: (params) => {
    const { projectId, branchId } = params;
    return `get:projects/${projectId}/branches/${branchId}/review_requests`;
  },
  perform: (params) => {
    const { projectId, branchId } = params;
    return apiRequest(
      "get",
      `projects/${projectId}/branches/${branchId}/review_requests`
    );
  },
  force: false,
});

export const ProjectReviewsFetchRequest = defineRequest<
  Abstract.ProjectDescriptor,
  Abstract.ProjectDescriptor,
>({
  id(descriptor) {
    return `get:review_requests?${queryString.stringify(descriptor)}`;
  },
  perform(descriptor) {
    return abstract.reviewRequests.list(descriptor);
  },
  onSuccess(reviewRequests, descriptor, dispatch) {
    const response = abstract.unwrap(reviewRequests);
    const { entities } = normalizeReviews(response);
    dispatch(entitiesReceived(entities));
  },
  force: false,
});

export const OrganizationReviewsFetchRequest = defineRequest<
  Abstract.OrganizationDescriptor,
  Abstract.OrganizationDescriptor,
>({
  id(descriptor) {
    return `get:review_requests?${queryString.stringify(descriptor)}`;
  },
  perform(descriptor) {
    return abstract.reviewRequests.list(descriptor);
  },
  onSuccess(reviewRequests, descriptor, dispatch) {
    const response = abstract.unwrap(reviewRequests);
    const { entities } = normalizeReviews(response);
    dispatch(entitiesReceived(entities));
  },
  force: false,
});

type ReviewCRUDRequestIdParams = { ...Abstract.BranchDescriptor };

type ReviewCreateRequestPerformParams = {
  ...Abstract.BranchDescriptor,
  reviewerId: string,
  status?: ReviewStatus,
  commentParams?: CommentForm,
  sha: string,
};

export const ReviewCreateRequest = defineRequest<
  ReviewCreateRequestPerformParams,
  ReviewCRUDRequestIdParams,
>({
  id: (params) => {
    const { projectId, branchId } = params;
    return `post:projects/${projectId}/branches/${branchId}/review_requests`;
  },
  perform: (params) => {
    const { projectId, branchId, reviewerId, status, commentParams, sha } =
      params;
    return apiRequest(
      "post",
      `projects/${projectId}/branches/${branchId}/review_requests`,
      { reviewerId: reviewerId, commentParams, status, sha }
    );
  },
});

type ReviewRequestIdParams = {
  id: string,
};
type ReviewUpdateRequestPerformParams = {
  ...$Exact<ReviewRequestIdParams>,
  status: ReviewStatus,
  commentParams?: CommentForm,
};

export const ReviewUpdateRequest = defineRequest<
  ReviewUpdateRequestPerformParams,
  ReviewRequestIdParams,
>({
  id: (params) => {
    const { id } = params;
    return `put:review_requests/${id}`;
  },
  perform: (params) => {
    const { id, status, commentParams } = params;
    return apiRequest("put", `review_requests/${id}`, {
      status,
      commentParams,
    });
  },
});

export const ReviewDeleteRequest = defineRequest<
  ReviewRequestIdParams,
  ReviewRequestIdParams,
>({
  id: (params) => {
    const { id } = params;
    return `delete:review_requests/${id}`;
  },
  perform: (params) => {
    const { id } = params;
    return apiRequest("delete", `review_requests/${id}`);
  },
});

type BranchReviewCreateRequestPerformParams = {
  ...Abstract.BranchDescriptor,
  reviewerIds: string[],
  collectionIds: string[],
  createBaseCollection: boolean,
  commentParams?: CommentForm,
  sha: string,
};

export const BranchReviewCreateRequest = defineRequest<
  BranchReviewCreateRequestPerformParams,
  ReviewCRUDRequestIdParams,
>({
  id: (params) => {
    const { projectId, branchId } = params;
    return `post:projects/${projectId}/branches/${branchId}/branch_review`;
  },
  perform: (params) => {
    const {
      projectId,
      branchId,
      reviewerIds,
      collectionIds,
      createBaseCollection,
      commentParams,
      sha,
    } = params;
    return apiRequest(
      "post",
      `projects/${projectId}/branches/${branchId}/branch_review`,
      {
        reviewerIds,
        collectionIds,
        createBaseCollection,
        commentParams,
        sha,
      }
    );
  },
});

type BranchReviewCollectionUpdateRequestPerformParams = {
  ...Abstract.BranchDescriptor,
  collectionIds: string[],
  createBaseCollection: boolean,
};

export const BranchReviewCollectionUpdateRequest = defineRequest<
  BranchReviewCollectionUpdateRequestPerformParams,
  ReviewCRUDRequestIdParams,
>({
  id: (params) => {
    const { projectId, branchId } = params;
    return `put:projects/${projectId}/branches/${branchId}/branch_review`;
  },
  perform: (params) => {
    const { projectId, branchId, collectionIds, createBaseCollection } = params;
    return apiRequest(
      "put",
      `projects/${projectId}/branches/${branchId}/branch_review`,
      {
        collectionIds,
        createBaseCollection,
      }
    );
  },
});
