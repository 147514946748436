// @flow
import history from "abstract-di/history";
import {
  filePath,
  pagePath,
  layerLocation,
  commitPath,
  collectionPath,
  query as buildQuery,
} from "core/lib/routes";
import type {
  LocationDescriptor,
  ShareLink,
  LayerOptions,
  BranchOptions,
  RoleFilter,
} from "core/types";
import * as Analytics from "web/analytics";

export function accountPath(path: string = "profile") {
  return `/account/${path}`;
}

export function organizationAdmin(organizationId: string): string {
  return organizationSettings(organizationId);
}

export function forgotPassword() {
  return "/password/new";
}

export function newUserQueryParam(): string {
  return "?newUser=1";
}

export function integrationPath(
  id: string,
  organizationId: string,
  path: string = "general"
): string {
  return integrationSettings(id, organizationId, path);
}

export function integrationSettings(
  id: string,
  organizationId: string,
  path: string = "general"
): string {
  const lowercased = path.toLowerCase();
  const p = lowercased === "slack" ? "general" : lowercased;
  return `/organizations/${organizationId}/integrations/${id}/${p}`;
}

export function invitation(
  token: string,
  state?: { onboarding?: boolean, returnTo?: LocationDescriptor }
): LocationDescriptor {
  return { pathname: `/invitations/${token}`, state };
}

export function invitationAvatar(id: string): string {
  return `/invitations/${id}/avatar`;
}

export function invitationPath(token: string, path: string = "") {
  return `/invitations/${token}/${path}`;
}

export function signin(returnTo: ?LocationDescriptor) {
  return {
    pathname: "/signin",
    state: returnTo ? { returnTo } : undefined,
  };
}

export function signup(returnTo: ?LocationDescriptor): LocationDescriptor {
  const location = history.getCurrentLocation();

  return {
    pathname: "/signup",
    state: returnTo ? { returnTo } : undefined,
    query: {
      [Analytics.FIRST_PAGE_VISITED_KEY]: Analytics.getFirstPageVisited(),
      [Analytics.FIRST_VISIT_DATE_KEY]: Analytics.getFirstVisitDate(),
      [Analytics.LAST_PAGE_VISITED_KEY]: Analytics.getLastPageVisited(),
      [Analytics.LAST_VISIT_DATE_KEY]: Analytics.getLastVisitDate(),
      utm_campaign: location.query.utm_campaign,
      utm_source: location.query.utm_source,
      utm_medium: location.query.utm_medium,
      utm_term: location.query.utm_term,
    },
  };
}

export function signupCreateOrJoinOrganization() {
  return "/signup/create-or-join-organization";
}

export function signupCreateOrganization() {
  return "/signup/create-organization";
}

export function signupInviteTeammates() {
  return "/signup/invite";
}

export function signupJoinOrganization() {
  return "/signup/join-organization";
}

export function signupConfirmEmail() {
  return "/signup/confirm-email";
}

export function signupVerifyEmail(verificationToken: string) {
  return `/signup/confirm-email/${verificationToken}/verify`;
}

export function signupConfirmation() {
  return "/signup/confirmation";
}

export function completeProfilePath(
  organizationId: string,
  clientType?: string,
  returnTo?: LocationDescriptor
) {
  return {
    pathname: "/account/profile/complete",
    state: { organizationId, clientType, returnTo },
  };
}

export function organizationPath(id: string): string {
  return `/organizations/${id}`;
}

export function organizationPeople(id: string, role?: RoleFilter): string {
  const query = role ? buildQuery({ role }) : "";
  return `/organizations/${id}/people${query}`;
}

export function organizationProjects(id: string): string {
  return `/organizations/${id}/projects`;
}

export function organizationActivity(id: string): string {
  return `/organizations/${id}/activity`;
}

export function organizationSettings(id: string): string {
  return `/organizations/${id}/edit`;
}

export function organizationDetails(id: string): string {
  return `/organizations/${id}/edit/details`;
}

export function organizationIntegrations(id: string): string {
  return `/organizations/${id}/edit/integrations`;
}

export function organizationPermissions(id: string): string {
  return `/organizations/${id}/edit/permissions`;
}

export function organizationSharing(id: string): string {
  return `/organizations/${id}/edit/sharing`;
}

export function organizationSubscription(id: string): string {
  return `/organizations/${id}/edit/subscription`;
}

export function organizationEmojisAdmin(id: string): string {
  return `/organizations/${id}/edit/emojis`;
}

export function organizationEmojisView(id: string): string {
  return `/organizations/${id}/emojis`;
}

export function accountProfile(): string {
  return "/account/profile";
}

export function accountOrganizations(): string {
  return "/account/organizations";
}

export function accountEmails(): string {
  return "/account/emails";
}

export function accountNotifications(): string {
  return `/account/notifications`;
}

export function accountAccessTokens(): string {
  return `/account/tokens`;
}

export function accountOauthApplications(): string {
  return `/account/oauth`;
}

export function downloadApp(): string {
  return "/download";
}

export function autoDownloadApp(): string {
  return "/autodownload";
}

export function collectionLayerPath(
  projectId: string,
  branchId: string,
  collectionId: string,
  collectionLayerId: string,
  options?: LayerOptions
): string {
  return `/projects/${projectId}/branches/${branchId}/collections/${collectionId}${buildQuery(
    options
  )}`;
}

export function collectionLayerPresentationPath(
  projectId: string,
  branchId: string,
  collectionId: string,
  collectionLayerId: string,
  options?: LayerOptions
) {
  return `/projects/${projectId}/branches/${branchId}/collections/${collectionId}/layers/${collectionLayerId}/presentation${buildQuery(
    options
  )}`;
}

export function branchPath(
  projectId: string,
  branchId: string,
  options?: BranchOptions
) {
  let url = `/projects/${projectId}/branches/${branchId}`;
  if (options && options.section) {
    url += `/${options.section}`;
  }
  if (options && options.commentId) {
    url += `?commentId=${options.commentId}`;
  }

  return url;
}

export function branchCommitsPath(projectId: string, branchId: string): string {
  return `/projects/${projectId}/branches/${branchId}/commits`;
}

export function branchCollectionsPath(
  projectId: string,
  branchId: string
): string {
  return `/projects/${projectId}/branches/${branchId}/collections`;
}

export function branchFilesPath(projectId: string, branchId: string): string {
  return `/projects/${projectId}/branches/${branchId}/files`;
}

export function libraryPath(
  projectId: string,
  branchId: string,
  fileId: string
) {
  return {
    pathname: filePath(projectId, branchId, fileId),
    query: { isExternalLibrary: true },
  };
}

export function projectPath(projectId: string, section: string = "") {
  return `/projects/${projectId}/${section}`;
}

export function projectSettings(id: string): string {
  return `/projects/${id}/edit`;
}

export function projectBranchesPath(projectId: string) {
  return `/projects/${projectId}/branches`;
}

export function projectPeoplePath(projectId: string) {
  return `/projects/${projectId}/people`;
}

export function shareLink(id: string) {
  return `/share/${id}`;
}

export function shareLinkDestination(
  shareLink: ShareLink,
  options?: any
): ?LocationDescriptor {
  switch (shareLink.kind) {
    case "collection":
      if (options && options.collectionLayerId) {
        const args = [
          shareLink.descriptor.projectId,
          shareLink.descriptor.branchId,
          shareLink.descriptor.collectionId,
          options.collectionLayerId,
          options,
        ];

        return options.present
          ? collectionLayerPresentationPath(...args)
          : collectionLayerPath(...args);
      }

      return collectionPath(
        shareLink.descriptor.projectId,
        shareLink.descriptor.branchId,
        shareLink.descriptor.collectionId,
        options && options.sectionId
      );
    case "commit":
      return commitPath(
        shareLink.descriptor.projectId,
        shareLink.descriptor.branchId,
        shareLink.descriptor.sha,
        shareLink.descriptor.commentId
      );
    case "layer":
      return layerLocation(
        shareLink.descriptor.projectId,
        shareLink.descriptor.branchId,
        (options && options.sha) || shareLink.descriptor.sha,
        shareLink.descriptor.fileId,
        shareLink.descriptor.layerId,
        {
          ...options,
          returnTo: {
            pathname: branchPath(
              shareLink.descriptor.projectId,
              shareLink.descriptor.branchId
            ),
          },
        }
      );
    case "branch":
      return branchPath(
        shareLink.descriptor.projectId,
        shareLink.descriptor.branchId,
        { commentId: (options && options.commentId) || undefined }
      );
    case "project":
      return projectPath(shareLink.descriptor.projectId);
    case "file":
      return filePath(
        shareLink.descriptor.projectId,
        shareLink.descriptor.branchId,
        shareLink.descriptor.fileId
      );
    case "page":
      return pagePath(
        shareLink.descriptor.projectId,
        shareLink.descriptor.branchId,
        shareLink.descriptor.fileId,
        shareLink.descriptor.pageId
      );
    default:
      return null;
  }
}

export function webhookPath(organizationId: string, webhookId: string) {
  return `/organizations/${organizationId}/webhooks/${webhookId}/details`;
}

export function webhookEventsPath(organizationId: string, webhookId: string) {
  return `/organizations/${organizationId}/webhooks/${webhookId}/events`;
}

export function webhookDeliveriesPath(
  organizationId: string,
  webhookId: string
) {
  return `/organizations/${organizationId}/webhooks/${webhookId}/deliveries`;
}

export function webhookDeliveryPath(
  organizationId: string,
  webhookId: string,
  webhookDeliveryId: string
) {
  return `/organizations/${organizationId}/webhooks/${webhookId}/deliveries/${webhookDeliveryId}`;
}

export function supportTicketPath(organizationId?: string) {
  if (organizationId) {
    return `/contact-support?organizationId=${organizationId}`;
  }

  return "/contact-support";
}

export function oAuthApplicationPath(organizationId: string, id: string) {
  return `/organizations/${organizationId}/applications/${id}`;
}
