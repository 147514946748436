// @flow
import * as React from "react";
import { connect } from "react-redux";
import Immutable from "seamless-immutable";
import shallowequal from "shallowequal";
import * as Request from "core/models/request";
import { getRequest } from "core/selectors/requests";
import { formChanged } from "../actions/forms";
import { DEFAULT_VALUES } from "../models/form";
import type { Form, FormProps } from "../types";

type Props = {
  form: Form,
  formId: string,
  formChanged: Function,
};

function callOrReturn(valueOrFunction, props) {
  if (typeof valueOrFunction === "function") {
    return valueOrFunction(props);
  }

  return valueOrFunction;
}

export default function withForm<Config = {}>(
  WrappedComponent: React.AbstractComponent<{ ...Config, ...FormProps }>,
  id: string | ((Config) => string),
  defaultValues?: {} | ((Config) => {})
): React.AbstractComponent<Config> {
  class WithForm extends React.Component<{ ...Config, ...Props }> {
    render() {
      const { form, formId, formChanged, ...rest } = this.props;

      const formProps = {
        ...form,
        id: formId,
        onChange: formChanged,
      };

      return <WrappedComponent {...rest} form={formProps} />;
    }
  }

  function mapStateToProps(state, props) {
    const formId = callOrReturn(id, props);

    if (typeof formId !== "string") {
      throw new Error(
        `Expected a form id as a string or a function that returns a string.
        Form id resolved to ${typeof formId}`
      );
    }

    const request = getRequest(state, formId);
    const defaults = Immutable.from(callOrReturn(defaultValues, props) || {});
    const values = Immutable.merge(
      defaults,
      state.forms[formId] || DEFAULT_VALUES
    );

    const isDirty = !shallowequal(defaults, values);

    return {
      formId,
      form: {
        error: request.error,
        errors: Request.validationErrors(request),
        isDirty,
        isSubmitting: Request.isLoadingStrict(request),
        submitSucceeded: Request.success(request) && !isDirty,
        submitRequestSucceeded: Request.success(request),
        values: values,
      },
    };
  }

  return connect(mapStateToProps, { formChanged })(WithForm);
}
