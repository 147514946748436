// @flow
import invariant from "invariant";
import * as React from "react";
import { connect } from "react-redux";
import { openFile } from "abstract-di/actions";
import { showDialog, dismissDialog } from "core/actions/dialogs";
import DialogForm from "core/components/DialogForm";
import { getBranch } from "core/selectors/branches";
import type { Branch, State, Dispatch } from "core/types";

type OwnProps = {|
  projectId: string,
  branchId: string,
  fileId: string,
  sha: string,
  currentBranchId: string,
|};

type StateProps = {|
  branch: ?Branch,
|};

type DispatchProps = {|
  openReadOnly: () => void,
  dismissDialog: () => void,
  showCommitDialog: () => void,
|};

type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

function CleanupBranch(props: Props) {
  return (
    <DialogForm
      title="Uncommitted changes"
      primaryButton="View changes"
      secondaryButton="Open untracked"
      onSubmit={props.showCommitDialog}
      onSecondary={props.openReadOnly}
      onClose={props.dismissDialog}
      isOpen
    >
      <p>
        It looks like you have uncommitted changes on{" "}
        {props.branch ? (
          <React.Fragment>
            the <em>{props.branch.name}</em>
          </React.Fragment>
        ) : (
          "another"
        )}{" "}
        branch.
      </p>
      <p>To edit this file please commit or discard your changes first.</p>
    </DialogForm>
  );
}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { projectId, currentBranchId } = props;

  return {
    branch: getBranch(state, {
      projectId,
      branchId: currentBranchId,
    }),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: OwnProps
): DispatchProps {
  const { projectId, branchId, fileId, sha } = props;

  return {
    dismissDialog: () => dispatch(dismissDialog()),
    showCommitDialog: () => dispatch(showDialog("CreateCommit", { projectId })),
    openReadOnly: () => {
      invariant(openFile, "openFile required for dialog action");
      dispatch(
        openFile({
          projectId,
          branchId,
          fileId,
          sha,
          fileMode: "READ_ONLY",
        })
      );
      dispatch(dismissDialog());
    },
  };
}

export default connect<
  Props,
  OwnProps,
  StateProps,
  DispatchProps,
  State,
  Dispatch,
>(
  mapStateToProps,
  mapDispatchToProps
)(CleanupBranch);
