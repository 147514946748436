// @flow
import * as React from "react";
import Button from "core/components/Button";
import Heading from "core/components/Heading";
import Media from "core/components/Media";
import { push } from "core/lib/location";
import { isMac } from "core/lib/platform";
import { V3Link as Link } from "core/lib/router";
import { learnAbstractDesignWorkflow } from "core/lib/urls";
import DownloadApp from "web/components/Onboarding/DownloadApp";
import OnboardingPage from "web/components/OnboardingPage";
import { organizationPath, projectPath, downloadApp } from "web/routeHelpers";
import style from "./DownloadApp/style.scss";

type Props = {
  organizationId?: string,
  projectId?: string,
  isOnboarding: boolean,
  shouldRedirect: boolean,
  message?: React.Node,
};

export default class Confirmation extends React.Component<Props> {
  static defaultProps = {
    shouldRedirect: false,
    heading: "Confirmation",
  };

  componentDidMount() {
    if (this.props.shouldRedirect) {
      return push("/");
    }
  }

  get webUrl(): string {
    const { organizationId, projectId } = this.props;
    if (projectId) {
      return projectPath(projectId);
    }
    if (organizationId) {
      return organizationPath(organizationId);
    }
    return "/";
  }

  get abstractUrl(): string {
    const { organizationId, projectId } = this.props;
    const url = `abstract://app/share?`;

    if (organizationId) {
      return `${url}organizationId=${organizationId}&kind=organization`;
    }

    if (projectId) {
      return `${url}projectId=${projectId}&kind=project`;
    }

    return url;
  }

  get message(): string {
    if (this.props.isOnboarding) {
      return "Your account has been created.";
    }
    return "Thanks for creating a new team!";
  }

  renderOnboarding() {
    return (
      <Media mobile>
        {(mobile) => (
          <DownloadApp
            documentTitle="Confirmation"
            heading="Welcome to Abstract"
            message={
              <p className={style.message}>
                {this.message}{" "}
                {isMac() &&
                  `To get started, download the macOS app and begin
                designing with Abstract.`}
              </p>
            }
          >
            <div className={style.contentContainer}>
              <Heading level="2" size="xl" className={style.webHeader}>
                Web app
              </Heading>
              <div>
                If you want to view or comment on files, or update your billing
                preferences, <Link to={this.webUrl}>visit the web app</Link>.
              </div>
            </div>

            {mobile && !isMac() && (
              <div className={style.contentContainer}>
                <Heading level="2" size="xl" className={style.webHeader}>
                  Desktop app
                </Heading>
                <div>
                  To start designing and editing files, you’ll need to download
                  the macOS app from your desktop computer.
                </div>
              </div>
            )}

            <div className={style.contentContainer}>
              <Heading level="2" size="xl" className={style.webHeader}>
                Getting started Guide
              </Heading>
              <div>
                Learn more about{" "}
                <a
                  href={learnAbstractDesignWorkflow()}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  how to set up your design workflow
                </a>
                .
              </div>
            </div>
          </DownloadApp>
        )}
      </Media>
    );
  }

  renderDefaultMessage() {
    return (
      <React.Fragment>
        <p>{this.message}</p>
        {isMac() && (
          <p>
            Already have <Link to={downloadApp()}>the macOS app</Link>{" "}
            installed? Click the blue button to sign in.
          </p>
        )}
      </React.Fragment>
    );
  }

  renderConfirmationBody = () => {
    return (
      <React.Fragment>
        <div className={style.appText}>
          {this.props.message || this.renderDefaultMessage()}
        </div>
        {isMac() ? (
          <React.Fragment>
            <Button
              primary
              large
              fullwidth
              className={style.button}
              external
              component="a"
              href={this.abstractUrl}
            >
              Launch Abstract on macOS
            </Button>
            <div className={style.orText}> OR </div>
            <Button secondary large fullwidth component={Link} to={this.webUrl}>
              continue to the web
            </Button>
          </React.Fragment>
        ) : (
          <div>
            <Link to={this.webUrl}>Get started</Link>
          </div>
        )}
      </React.Fragment>
    );
  };

  render() {
    if (this.props.isOnboarding) {
      return this.renderOnboarding();
    }

    return (
      <OnboardingPage
        documentTitle="Confirmation"
        heading="Welcome to Abstract"
      >
        {this.renderConfirmationBody()}
      </OnboardingPage>
    );
  }
}
