// @flow
import ExtendableError from "es6-error";
import { loggableArgs } from "core/lib/logs";
import * as Request from "core/models/request";

function makeError(base) {
  return class extends base {
    data: ?Object;

    constructor(message: string, args: string[] = []) {
      super(message);
      this.data = {
        args: loggableArgs(args),
      };
    }
  };
}
export class AbstractCLIError extends ExtendableError {
  fingerprint: string[];

  constructor(message: string) {
    super(message);
    this.fingerprint = ["{{ default }}", message];
  }
}

export class AuthenticationError extends ExtendableError {
  data: ?Object;

  constructor(message: string = "Unauthorized Request", data: ?Object) {
    super(message);
    this.data = data;
  }
}
export class UpgradeRequiredError extends ExtendableError {}
export class BranchPermissionsError extends ExtendableError {}
export class CommitVerificationError extends ExtendableError {}
export class CorruptFileError extends ExtendableError {}
export class WorkerCrashedError extends ExtendableError {
  constructor(message: string = "The worker crashed while running the job") {
    super(message);
  }
}
export class JobClearedError extends ExtendableError {
  constructor(message: string = "Activites cleared while running the job") {
    super(message);
  }
}
export class GitError extends ExtendableError {}
export class MergeError extends ExtendableError {}
export class MissingFileError extends ExtendableError {}
export class NetworkError extends Request.RequestError {
  data: ?Object;

  constructor(message: string, data: ?Object) {
    super(message);
    this.data = data;
  }
}
export class OfflineError extends ExtendableError {
  constructor(message: string = "Your network connection may be offline.") {
    super(message);
  }
}
export class PreviewsError extends ExtendableError {
  data: ?Object;

  constructor(message: string, data: Object) {
    super(message);
    this.data = data;
  }
}
export class PluginNotInstalledError extends ExtendableError {
  constructor(message: ?string) {
    const defaultMessage = "Could not install the Plugin";

    if (!message) {
      message = defaultMessage;
    } else {
      message = defaultMessage + ": " + message;
    }

    super(message);
  }
}
export class AppNotInstalledError extends ExtendableError {
  bundleId: string;

  constructor(bundleId: string) {
    super(`${bundleId} not installed`);
    this.bundleId = bundleId;
  }
}
export class PluginNotUpdatedError extends ExtendableError {
  constructor(extraMsg: ?string) {
    let message = "Could not update the Plugin";
    if (extraMsg) {
      message += `: ${extraMsg}`;
    }
    super(message);
  }
}
export class NonFastForwardError extends ExtendableError {}
export class RepoRecoveredError extends ExtendableError {}
export class RepoNotFoundError extends ExtendableError {
  data: ?Object;

  constructor(message: string, data: ?Object) {
    super(message);
    this.data = data;
  }
}
export class RepoPermissionsError extends ExtendableError {}
export class RequiredParameterError extends ExtendableError {}
export class ClientError extends Request.RequestError {
  data: ?Object;

  constructor(message: string, data: ?Object) {
    super(message);
    this.data = data;
  }
}

export class ServerError extends Request.UnexpectedError {
  data: ?Object;

  constructor(message: string, data: ?Object) {
    super(message);
    this.data = data;
  }
}

export class TooManyRequestsError extends ServerError {}
export class ServiceUnavailableError extends ServerError {}
export class UncommittedChangesError extends ExtendableError {}
export class UnknownFiletypeError extends ExtendableError {}
export class UserCanceledError extends ExtendableError {}
export class RestoreCommitBeforeUpdateError extends ExtendableError {}

// CLI Errors

export class ParseError extends Request.UnexpectedError {}
export class UnknownError extends makeError(Request.UnexpectedError) {}
export class ArgError extends UnknownError {}
export class ExceptionError extends UnknownError {}
export class MissingUserError extends UnknownError {}
export class MasterlessProjectError extends UnknownError {}
export class UnauthorizedError extends makeError(Request.UnauthorizedError) {}
export class ForbiddenError extends makeError(Request.ForbiddenError) {}
export class NotFoundError extends makeError(Request.NotFoundError) {
  field: ?string;

  constructor(message: string, field: ?string, args: string[] = []) {
    super(message, args);
    this.field = field;
  }
}
export class ValidationError extends Request.ValidationError {}
