// @flow
import capitalize from "lodash/capitalize";
import pluralize from "pluralize";
import * as React from "react";
import { NON_ENTERPRISE_SEAT_CAP } from "core/lib/subscriptions";
import type { Subscription } from "core/types";
import style from "./style.scss";

type Props = {
  availableSeats: number,
  seatCapEnforced: ?boolean,
  subscription: ?Subscription,
};

export default function SeatsAvailableLine(props: Props) {
  const { availableSeats, seatCapEnforced, subscription } = props;

  const isTrial = subscription ? subscription.inTrial : false;

  return (
    <span>
      {seatCapEnforced && !isTrial && subscription && (
        <p className={style.availableSeats}>
          {capitalize(subscription.type)} plans support up to{" "}
          {NON_ENTERPRISE_SEAT_CAP} Contributors. Currently you have{" "}
          {subscription.totalSeats} Contributor seats with your subscription.
        </p>
      )}

      {!seatCapEnforced && (
        <p className={style.availableSeats}>
          You currently have {availableSeats || "no"} Contributor{" "}
          {pluralize("seats", availableSeats)} available on your account.
        </p>
      )}
    </span>
  );
}
