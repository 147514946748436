// @flow
import window from "core/global/window";

const { HTMLElement } = window;

/**
 * findTextEditor finds the first DraftJS editor that exists in
 * the passed element or it's parent nodes
 *
 * @param {DOMNode} element
 * @return {DOMNode}
 */
function findTextEditor(element: HTMLElement) {
  if (element instanceof HTMLElement) {
    if (element.className && element.className.includes("DraftEditor-root")) {
      return element;
    }

    if (element.parentNode instanceof HTMLElement) {
      return findTextEditor(element.parentNode);
    }
  }
  return undefined;
}

/**
 * eventInInput returns a boolean as to whether the passed event was triggered
 * from a user input field.
 *
 * @param {Event} event
 * @return {Boolean} within user input
 */
export default function eventInInput(
  ev: KeyboardEvent | SyntheticKeyboardEvent<>
): boolean {
  const target = ev.target;
  if (!(target instanceof HTMLElement)) {
    return false;
  }

  return (
    ["TEXTAREA", "INPUT"].indexOf(target.tagName) >= 0 ||
    !!findTextEditor(target)
  );
}
