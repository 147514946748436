// @flow
import * as React from "react";
import DialogForm from "core/components/DialogForm";
import { isMaster } from "core/models/branch";
import type { Branch } from "core/types";
import connector from "./connector";

export type OwnProps = {|
  projectId: string,
  branch: Branch,
|};

export type StateProps = {|
  defaultBranchName: string,
|};

export type DispatchProps = {|
  createDivergedBranch: () => void,
  dismissDialog: () => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

class ConfirmCreateDivergedBranch extends React.Component<Props> {
  handleSubmit = async (ev: SyntheticEvent<*>) => {
    ev.preventDefault();

    await this.props.createDivergedBranch();
    this.props.dismissDialog();
  };

  render() {
    const { branch, defaultBranchName } = this.props;
    const title = `${
      isMaster(branch) ? defaultBranchName : "This Branch"
    } has diverged`;
    const buttonTitle = `Create ${branch.name} (Diverged Copy)`;
    return (
      <DialogForm
        title={title}
        primaryButton={buttonTitle}
        onSubmit={this.handleSubmit}
        onClose={this.props.dismissDialog}
        isOpen
      >
        <p>
          The local version of <em>{this.props.branch.name}</em> has diverged
          from the version on the server.
        </p>
        <p>
          Abstract will create a new branch from <em>{branch.name}</em> to
          preserve all of your local commits. You can then update from{" "}
          <em>{branch.name}</em> to merge your changes back in.
        </p>
      </DialogForm>
    );
  }
}

export default connector(ConfirmCreateDivergedBranch);
