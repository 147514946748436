// @flow
import * as React from "react";
import Button from "../Button";

type Props = {
  direction: "left" | "right",
  active?: boolean,
};

export default function ButtonSidebar(props: Props) {
  const { active, direction, ...rest } = props;

  return (
    <Button
      icon={`toggle-sidebar-${props.direction}`}
      title={props.active ? "Hide sidebar" : "Show sidebar"}
      nude
      {...rest}
    />
  );
}
