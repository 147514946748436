// @flow
import queryString from "query-string";
import apiRequest from "abstract-di/api";
import { entitiesReceived } from "core/actions/entities";
import { Abstract } from "core/lib/abstract";
import defineRequest from "core/requests";
import { normalizeOrganizationMetricsFetchResponse } from "core/schemas/organizationMetrics";

type OrganizationReportingFetchParams = {
  ...Abstract.OrganizationDescriptor,
  period?: string,
};

export const OrganizationReportingFetchRequest = defineRequest<
  OrganizationReportingFetchParams,
  OrganizationReportingFetchParams,
>({
  id: (params) => {
    return `organization-insights?${queryString.stringify(params)}`;
  },
  perform: (params) => {
    return apiRequest(
      "get",
      `organizations/${params.organizationId}/insights`,
      params
    );
  },
  onSuccess: (response, params, dispatch) => {
    const { entities } = normalizeOrganizationMetricsFetchResponse(response);
    dispatch(entitiesReceived(entities));
  },
  force: true,
});
