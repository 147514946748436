// @flow
import { connect } from "react-redux";
import { isOnline } from "abstract-di/selectors";
import { showToast } from "core/actions/toasts";
import * as Request from "core/models/request";
import { ShareLinkUpdateRequest } from "core/requests/shareLinks";
import type {
  State,
  Dispatch,
  ShareLink,
  ShareLinkUpdateRequestParams,
} from "core/types";

type Props = {
  shareLink: ShareLink,
};

function mapStateToProps(state: State, props: Props) {
  const revokeRequest = ShareLinkUpdateRequest.getRequest(state, {
    id: props.shareLink.id,
    isPublic: false,
  });

  return {
    isOffline: !isOnline(state),
    isSubmitting: Request.isLoadingStrict(revokeRequest),
    hasError: Request.hasError(revokeRequest),
  };
}

function mapDispatchToProps(dispatch: Dispatch, props: Props) {
  return {
    showSuccessToast() {
      dispatch(
        // TODO: add undo option to toast
        // TODO: change url to layerName
        showToast({
          text: `Public sharing turned off for ${props.shareLink.url}`,
        })
      );
    },
    updateShareLink(params: ShareLinkUpdateRequestParams) {
      return dispatch(ShareLinkUpdateRequest.perform({ params }));
    },
  };
}

/* $FlowFixMeNowPlease This comment suppresses an error found when upgrading
 * flow-bin@0.85.0. To view the error, delete this comment and run Flow. */
export default connect(mapStateToProps, mapDispatchToProps);
