// @flow
import * as React from "react";
import { helpCollectionsUrl } from "core/lib/urls";
import image from "./images/collection.svg";
import style from "./style.scss";
import Empty from ".";

export default function NoCollectionLayers({
  canEdit,
  collectionUrl,
  button,
  small,
  ...rest
}: {
  canEdit?: boolean,
  collectionUrl?: string,
  small?: boolean,
  button?: React.Node,
}) {
  const showHelpButton = canEdit && !!collectionUrl;

  return (
    <Empty
      small={small}
      {...rest}
      image={image}
      title={canEdit ? "Nothing added yet" : "Empty collection"}
      description={
        small ? (
          "This collection is empty."
        ) : (
          <React.Fragment>
            {canEdit
              ? "Use collections to organize, present, and share your work."
              : "The author of this collection hasn't added any work yet."}
            <br />
            {!showHelpButton && (
              <a
                href={helpCollectionsUrl()}
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn about collections in the Help Center.
              </a>
            )}
            <div className={style.buttonMargin}>{button}</div>
          </React.Fragment>
        )
      }
    />
  );
}
