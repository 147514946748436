// @flow
import React, { Component } from "react";
import Button from "core/components/Button";
import UserListItem from "core/components/UserListItem";
import type { User } from "core/types";
import style from "../style.scss";

type Props = {
  reviewer: User,
  onClose?: (reviewer: User) => *,
  joinedOn?: string,
  className?: string,
  badge?: string,
  required: boolean,
  responsive?: boolean,
};

export default class ReviewerListItem extends Component<Props> {
  handleClose = () => {
    const { onClose, reviewer } = this.props;
    if (onClose) {
      return onClose(reviewer);
    }
  };

  render() {
    const {
      reviewer,
      joinedOn,
      className,
      badge,
      onClose,
      required,
      responsive,
    } = this.props;

    return (
      <UserListItem
        id={reviewer.id}
        name={reviewer.name}
        avatarUrl={reviewer.avatarUrl}
        username={!joinedOn ? reviewer.username : ""}
        joinedOn={joinedOn}
        className={className}
        responsive={responsive || false}
        badge={badge}
      >
        {!required && onClose && (
          <Button
            onClick={this.handleClose}
            className={style.actions}
            icon="close"
            nude
          />
        )}
        {required && <span className={style.reviewerNote}>Required</span>}
      </UserListItem>
    );
  }
}
